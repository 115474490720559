import React from 'react';
import ReactDOM from 'react-dom';
import Marionette from 'Backbone.Marionette';

import vent from 'js/vent';
import app from 'js/app';
import TextManager from 'app/text-manager';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import TaskModel from 'js/models/task';
import { ViewTask } from './task';

import style from './task.css';


const titleByRelatedType = {
    'individuals': 'ID_INDIVIDUAL',
    'opportunities': 'ID_DEAL',
    'organizations': 'ID_ORGANIZATION'
};

const relatedTypes = {
    individuals: 'individual',
    organizations: 'organization',
    opportunities: 'opportunity'
};


class TasksList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tasks: []
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createNewTask() {
        const task = {};

        if (this.props.related) {
            task.related = {
                id: this.props.related.id,
                name: this.props.related.full_name || this.props.related.name,
                type: this.props.related.type
            };
        }

        task.tags = app.user.getIndividualPreloadedTags();

        vent.trigger('quick:add:task', new TaskModel(task));
    }

    fetchData() {
        const relatedType = relatedTypes[this.props.related.type];

        let args = {
            rows: -1,
            related_id: this.props.related.id,
            related_type: relatedType,
            include_related_tasks: this.props.includeRelatedTasks
        };

        if (this.props.completed) {
            args.completed = true;
            args.order_by = 'modified desc';
        } else  {
            args.order_by = 'due_date asc';
        }

        this.setState({
            loading: true
        });

        const self = this;

        $.get(`/tasks?${$.param(args)}`, function(result) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                tasks: result,
                loading: false
            });
        });

        if (!this.props.completed) {
            let countArgs = {
                return_count: true,
                related_type: relatedType,
                related_id: this.props.related.id,
                include_related_tasks: this.props.includeRelatedTasks,
                assigned_to_me: true
            };

            $.ajax({
                type: 'GET',
                url: `/tasks?${$.param(countArgs)}`,
                success: function (count) {
                    self.props.onCountChange(count);
                }
            });
        }
    }

    render() {
        return (
            <div className={style.tList}>
                {this.state.loading &&
                    <div
                        style={{margin: '30px 0 20px 0'}}
                    >
                        <LoadingIndicator/>
                    </div>
                }

                {!this.state.loading && this.state.tasks.length === 0 &&
                    <div className={style.lEmpty}>
                        <div className={`${style.eCheck} icon-checkmark3`}/>
                        <div className={style.eTitle}>{`No ${this.props.completed ? 'Completed ' : ''} Tasks`}</div>
                        {!this.props.completed &&
                            <div className={style.eMessage}>Create a new task via the button above.</div>
                        }
                    </div>
                }

                {!this.state.loading &&
                    <div className={style.lContainer}>
                        {this.state.tasks.map((task, tidx) => {
                            return (
                                <div
                                    key={`task_${task.id}`}
                                    style={{marginTop: tidx > 0 ? '20px' : 0}}
                                >
                                    <ViewTask
                                        task={task}
                                        parent={this.props.parent}
                                        fullView={false}
                                        expanded={tidx === 0 && !this.state.editingTask}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
}


class TasksSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'this',
            completedListVisible: false
        };
    }

    componentDidMount() {
        this.props.parent.listenTo(vent, 'task:updated task:created task:deleted task:notes:updated', this.refresh.bind(this));
    }

    selectTab(tabId) {
        this.setState({
            activeTab: tabId
        });

        this.refresh();
    }

    show() {
        this.refresh();
    }

    refresh() {
        const self = this;

        _.defer(function() {
            self.tasksListComponent.fetchData();
            self.completedTasksListComponent?.fetchData();
        });
    }

    handleTogglerClick() {
        const visible = !this.state.completedListVisible;

        this.setState({
            completedListVisible: visible
        });

        if (visible) {
            const self = this;

            _.defer(function() {
                self.completedTasksListComponent.fetchData();
            });
        }
    }

    render() {
        const textId = titleByRelatedType[this.props.related.type];
        const relatedType = TextManager.parseText('${' + textId + ', capitalize}');

        return (
            <div className={style.tasksSection}>
                <div className={style.tHeader}>
                    <div className={style.hTabControl}>
                        <div
                            className={`
                                ${style.tTab}
                                ${this.state.activeTab === 'this' ? style.tActive : ''}
                            `}
                            onClick={() => this.selectTab.bind(this)('this')}
                        >
                            <div className={style.tTitle}>This {relatedType}</div>
                            <div className={style.tMarker}/>
                        </div>

                        <div
                            className={`
                                ${style.tTab}
                                ${this.state.activeTab === 'related' ? style.tActive : ''}
                            `}
                            onClick={() => this.selectTab.bind(this)('related')}
                        >
                            <div className={style.tTitle}>Related</div>
                            <div className={style.tMarker}/>
                        </div>
                    </div>

                    <div
                        className={style.hButton}
                        onClick={() => this.tasksListComponent.createNewTask()}
                    >
                        New
                    </div>
                </div>

                <div className={style.tContent}>
                    <TasksList
                        ref={(el) => this.tasksListComponent = el}
                        completed={false}
                        includeRelatedTasks={this.state.activeTab === 'related'}
                        related={this.props.related}
                        parent={this.props.parent}
                        onCountChange={this.props.onCountChange}
                    />

                    <div
                        className={style.tToggler}
                        onClick={this.handleTogglerClick.bind(this)}
                    >
                        <div className={style.tDesc}>Completed</div>
                        <div
                            className={`
                                ${style.tIcon}
                                ${this.state.completedListVisible ? 'icon-caret-down' : 'icon-caret-up'}
                            `}
                        />
                        <div
                            className={`
                                ${style.tEye}
                                ${this.state.completedListVisible ? 'icon-visible' : 'icon-hidden'}
                            `}
                        />
                    </div>

                    {this.state.completedListVisible &&
                        <TasksList
                            ref={(el) => this.completedTasksListComponent = el}
                            completed={true}
                            includeRelatedTasks={this.state.activeTab === 'related'}
                            related={this.props.related}
                            parent={this.props.parent}
                        />
                    }
                </div>
            </div>
        );
    }
}

const TasksListMarionette = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    show: function() {
        this.component.show();
    },
    handleCountChange(count) {
        this.trigger('task:count:change', count);
    },
    onShow: function() {
        this.$el.css('height', '100%');
        this.$el.css('overflow', 'hidden');
    },
    onRender: function() {
        ReactDOM.render(
            <TasksSection
                ref={(el) => this.component = el}
                {...this.options}
                parent={this}
                onCountChange={this.handleCountChange.bind(this)}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default TasksListMarionette;
import $ from 'jquery'
import _ from 'underscore'
import React from 'react'
import Leaflet from 'leaflet'

import app from 'js/app'
import vent from 'js/vent'
import Utilities from 'js/utils/utilities'
import GroupElementsCollection from 'js/collections/group_elements'
import {NewSelect} from 'js/react_views/widgets/select'
import {DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON, DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR, DEMOGRAPHIC_MAP_MULTIVALUE_PIN_COLOR, DEMOGRAPHIC_MAP_DEFAULT_CIRCLE_COLOR, DEMOGRAPHIC_MAP_MULTIVALUE_CIRCLE_COLOR} from './consts'

import style from './demographic-map.css'

const NUM_ROWS_PER_FETCH = 50;
const ANCHOR_BY_ID = {
    'top-left': [0, 0],
    'top-center': [16, 0],
    'top-right': [31, 0],
    'middle-left': [0, 16],
    'middle-center': [16, 16],
    'middle-right': [31, 16],
    'bottom-left': [0, 31],
    'bottom-center': [16, 31],
    'bottom-right': [31, 31]
};

let PopupCache = {};


class Popup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: {
                top: 0,
                left: 0,
                opacity: 0
            }
        }

        if ('message' in props.hoverPoint) {
            this.state.firstLine = props.hoverPoint.message || '';
        } else {
            const entity = props.hoverPoint.entities[0];

            switch (props.entityType) {
                case 'individuals': {
                    this.state.firstLine = entity.full_name;
                    this.state.secondLine = entity['organization.name'];

                    if (entity.id in PopupCache) {
                        this.state.thirdLine = _.clone(PopupCache[entity.id]);
                    } else {
                        const self = this;

                        $.get(`/individuals/${entity.id}/opportunities?rows=1&order_by=modified desc`, function(data) {
                            if (data.length > 0) {
                                PopupCache[entity.id] = [data[0].name, data[0].phase_name];

                                if (self.mounted) {
                                    self.setState({
                                        thirdLine: [data[0].name, data[0].phase_name]
                                    });
                                }
                            }
                        });
                    }
                }
                break;

                default:
                    this.state.firstLine = entity.name;
                    break;
            }
        }

        this.mounted = false;
    }

    componentDidMount() {
        const container = $(this.props.container)[0];
        const component = $(this.component)[0];

        let top = this.props.hoverPoint.y - component.clientHeight - 37;
        let left = this.props.hoverPoint.x - (component.clientWidth / 2);

        if (top < container.clientTop) {
            top = this.props.hoverPoint.y + 5;
        }

        if (left < container.clientLeft) {
            left = container.clientLeft + 10;
        } else if ((left + component.clientWidth) > (container.clientLeft + container.clientWidth)) {
            left = (container.clientLeft + container.clientWidth) - component.clientWidth - 10;
        }

        this.setState({
            style: {
                top: top,
                left: left,
                opacity: 1
            }
        });

        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <div
                ref={(el) => this.component = el}
                className={style.dmPopup}
                style={this.state.style}
            >
                <div className={style.dmpFirstLine}>{this.state.firstLine}</div>
                {this.state.secondLine &&
                    <div className={style.dmpSecondLine}>{this.state.secondLine}</div>
                }
                {this.state.thirdLine &&
                    <div className={style.dmpThirdLine}>
                        <div>{this.state.thirdLine[0]}</div>
                        <div style={{marginLeft: '10px', marginRight: '10px'}}>|</div>
                        <div>{this.state.thirdLine[1]}</div>
                    </div>
                }
            </div>
        );
    }
}

class MapKey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            colorKeys: []
        };
    }

    setMarker(marker) {
        let colorKeys = [];

        if (marker.color) {
            colorKeys = [{
                id: 'default',
                name: 'Default',
                color: marker.color
            }];
        } else {
            const ddid = `custom_fields.${marker.dropdownId}`;
            const cfi = this.props.dropdownsInfo[ddid];
            let nameByOptionId = {};

            for (const id in cfi) {
                nameByOptionId[cfi[id]] = id;
            }

            for (const c of marker.colors) {
                colorKeys.push({
                    id: c.id,
                    name: c.id === 'default' ? 'Default' : nameByOptionId[c.id],
                    color: c.color
                });
            }
        }

        this.setState({
            colorKeys: colorKeys
        });
    }

    toggleExpanded() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        return (
            <div className={style.dmMapKey}>
                <div
                    className={style.mpHeader}
                    onClick={this.toggleExpanded.bind(this)}
                >
                    <div>Map Key</div>
                    <div className={style.mphArrow}>{this.state.expanded ? '\u25BC' : '\u25B2'}</div>
                </div>
                {this.state.expanded &&
                    <div className={style.mpColorKeys}>
                        {this.state.colorKeys.map(ck => {
                            return (
                                <div
                                    key={`color_key_${ck.id}`}
                                    className={style.mpColorKey}
                                >
                                    <div
                                        className={style.ckName}
                                        title={ck.name}
                                    >
                                        {ck.name}
                                    </div>
                                    <div
                                        className={style.ckIcon}
                                        style={{fill: ck.color}}
                                        dangerouslySetInnerHTML={{ __html: DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
}

class MarkerSelector extends React.Component {
    constructor(props) {
        super(props);

        this.markers = [];

        for (const mid in this.props.mapConfig.dynamic_pins) {
            this.markers.push({
                id: mid,
                title: this.props.mapConfig.dynamic_pins[mid].name || Utilities.capitalize(mid),
                colors: this.props.mapConfig.dynamic_pins[mid].colors,
                dropdownId: this.props.mapConfig.dynamic_pins[mid].dropdown_id
            });
        }
    }

    render() {
        return (
            <div className={style.dmMarkerSelector}>
                <NewSelect
                    width='180'
                    data={this.markers}
                    value={this.markers.find(m => m.id === 'default')}
                    onSelect={(items) => this.props.onMarkerChange(items[0])}
                />
            </div>
        );
    }
}

class DemographicMap extends React.Component {
    constructor(props) {
        super(props);

        this.mapConfig = JSON.parse(app.user.get('client').preferences.demographic_maps)[this.props.group.element_type];
        this.map = null;
        this.visible = false;
        this.mounted = false;
        this.centerMap = true;
        this.dataDirty = true; // this means do a full fetch of all the entities
        this.collection = new GroupElementsCollection(null, {elementType: this.props.group.element_type});
        this.groupPageToFetch = 0;
        this.fetchingWhat = 'entities';

        let columns = [];

        switch (this.props.group.element_type) {
            case 'individuals':
                columns = ['full_name', 'organization.name'];
                break;

            default:
                columns = ['name'];
                break;
        }

        // get the columns related to the dynamic markers
        this.markerSelectorVisible = false;

        if (this.mapConfig.dynamic_pins) {
            for (const dm in this.mapConfig.dynamic_pins) {
                if (this.mapConfig.dynamic_pins[dm].dropdown_id) {
                    this.markerSelectorVisible = true;
                    break;
                }
            }
        }

        if (this.markerSelectorVisible) {
            let dropdownIds = [];

            for (const mid in this.mapConfig.dynamic_pins) {
                const dm = this.mapConfig.dynamic_pins[mid];

                if (dm.dropdown_id) {
                    dropdownIds.push(dm.dropdown_id);
                }
            }

            for (const ddid of dropdownIds) {
                columns.push(`custom_fields.${ddid}`);
            }

            // ...
            this.dropdownCustomFieldsInfo = {};

            const cfs = app.globalData.customFieldsInfo[this.props.group.element_type];

            for (const cfid in cfs) {
                const cf = cfs[cfid];

                if (cf.type !== 'dropDown') {
                    continue;
                }

                if (dropdownIds.indexOf(cfid) === -1) {
                    continue;
                }

                let cfOptions = {};

                for (const o in cf.options) {
                    cfOptions[cf.options[o]] = o;
                }

                this.dropdownCustomFieldsInfo[`custom_fields.${cfid}`] = cfOptions;
            }
        }

        this.groupFetchOptions = {
            geocoding: true,
            columns: columns
        };

        if (props.group.id) {
            this.groupFetchOptions.group_id = props.group.id;
        }

        if (props.group.filter_id) {
            this.groupFetchOptions.filter_id = props.group.filter_id
        }

        if (props.group.funnelId) {
            this.groupFetchOptions.funnel_id = props.group.funnelId;
        }

        if (props.group.tagId) {
            this.groupFetchOptions.tag_ids = props.group.tagId;
        }

        this.addressesInfo = {};
        this.missingAddresses = [];
        this.totalModelsFetched = 0;

        this.defaultMarker = Leaflet.divIcon({
            className: 'demographic-map-marker',
            html: `${DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}
                   <span class="marker-num-addresses hidden"></span>`,
            iconSize: [32, 32],
            iconAnchor: ANCHOR_BY_ID['bottom-center']
        });

        this.state = {
            hoverPoint: null,
            totalUnknownAddresses: 0,
            totalToFetch: 0,
            totalFetched: 0
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (this.mapKeyComponent) {
            this.mapKeyComponent.setMarker({
                name: 'Default',
                color: this.mapConfig.dynamic_pins?.default?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setDataDirty() {
        this.dataDirty = true;
    }

    setFilter(filter) {
        this.dataDirty = true;
        this.groupFetchOptions.filter_id = filter ? filter.get('id') : null

        if (this.visible) {
            this.fetchData();
        }
    }

    fetchMissingAddresses() {
        if (!this.visible || !this.mounted) {
            return;
        }

        if (this.missingAddresses.length === 0) {
            return;
        }

        const addressesToFetch = this.missingAddresses.slice(0, NUM_ROWS_PER_FETCH);
        const self = this;

        $.post('/geocoding', JSON.stringify({
            addresses: addressesToFetch
        }), function(addresses) {
            if (self.mounted) {
                let addressesToProcess = {};
                let numUnknownAddresses = 0;

                for (const address in addresses) {
                    if (addresses[address]) {
                        addressesToProcess[address] = addresses[address];
                    } else {
                        ++numUnknownAddresses;
                    }
                }

                if (!_.isEmpty(addressesToProcess)) {
                    self.processAddresses(addressesToProcess);
                }

                if (numUnknownAddresses > 0 || _.keys(addressesToProcess).length > 0) {
                    self.setState({
                        totalUnknownAddresses: self.state.totalUnknownAddresses + numUnknownAddresses,
                        totalFetched: self.state.totalFetched + numUnknownAddresses + _.keys(addressesToProcess).length
                    });
                }

                self.missingAddresses = self.missingAddresses.filter(a => !(a in addresses));

                if (self.missingAddresses.length > 0) {
                    _.delay(function() {
                        self.fetchMissingAddresses();
                    }, 300);
                } else {
                    self.fetchingWhat = null;
                }
            }
        });
    }

    fetchNextGroupPage() {
        if (!this.visible || !this.mounted) {
            return;
        }

        const self = this;

        this.collection.fetch({
            start: this.groupPageToFetch * NUM_ROWS_PER_FETCH,
            rows: NUM_ROWS_PER_FETCH,
            data: this.groupFetchOptions,
            success: function(data) {
                if (self.mounted) {
                    let numUnknownAddresses = 0;
                    let numFetched = 0;
                    let addressesToProcess = {};

                    for (const model of data.models) {
                        const address = model.get('geocoding.address');

                        if (address) {
                            const latlng = model.get('geocoding.latlng');

                            if (latlng) {
                                if (!(address in self.addressesInfo)) {
                                    self.addressesInfo[address] = {
                                        entities: [],
                                        marker: null,
                                        latlng: latlng === 'fetching' ? null : latlng
                                    };
                                }

                                self.addressesInfo[address].entities.push(model.toJSON());

                                if (latlng === 'fetching') {
                                    if (self.missingAddresses.indexOf(address) === -1) {
                                        self.missingAddresses.push(address);
                                    } else {
                                        ++numFetched;
                                    }
                                } else {
                                    addressesToProcess[address] = latlng;
                                    ++numFetched;
                                }
                            } else {
                                ++numUnknownAddresses;
                            }
                        } else {
                            ++numUnknownAddresses;
                        }
                    }

                    let newState = {
                        totalUnknownAddresses: self.state.totalUnknownAddresses + numUnknownAddresses,
                        totalFetched: self.state.totalFetched + numUnknownAddresses + numFetched
                    };

                    if (self.state.totalToFetch === 0) {
                        newState.totalToFetch = data.total;
                    }

                    self.setState(newState);
                    self.totalModelsFetched += data.length;

                    if (!_.isEmpty(addressesToProcess)) {
                        self.processAddresses(addressesToProcess);
                    }

                    if (self.totalModelsFetched < data.total) {
                        ++self.groupPageToFetch;
                        self.fetchNextGroupPage();
                    } else if (self.missingAddresses.length > 0) {
                        self.fetchingWhat = 'missingAddresses';
                        self.fetchMissingAddresses();
                    } else {
                        self.fetchingWhat = null;
                    }
                }
            }
        });
    }

    processAddresses(latlngByAddress) {
        let points = [];
        const self = this;
        const defaultMarkerColor = this.mapConfig.dynamic_pins?.default?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR;

        for (const address in latlngByAddress) {
            const addressInfo = this.addressesInfo[address];

            if (addressInfo.marker) {
                this.map.removeLayer(addressInfo.marker);
            }

            const latlng = latlngByAddress[address];
            const latlngArray = latlng.split(',');

            addressInfo.latlng = latlng;
            addressInfo.marker = Leaflet.marker(latlngArray, {icon: this.defaultMarker}).addTo(this.map);

            const icon = $(addressInfo.marker._icon);

            icon.find('svg').css('fill', defaultMarkerColor);

            if (addressInfo.entities.length > 1) {
                const label = icon.find('.marker-num-addresses');
                label.removeClass('hidden');
                label.text(addressInfo.entities.length > 9 ? '+9' : addressInfo.entities.length);
            }

            points.push(latlngArray);

            addressInfo.marker.on('mouseover', function(event) {
                self.setState({
                    hoverPoint: {
                        x: event.containerPoint.x,
                        y: event.containerPoint.y,
                        entities: addressInfo.entities
                    }
                });
            });

            addressInfo.marker.on('mouseout', function() {
                self.setState({
                    hoverPoint: null
                });
            });

            addressInfo.marker.on('click', function() {
                self.props.onShowEntity(addressInfo.entities[0].id);
            });
        }

        if (this.centerMap) {
            this.map.fitBounds(points);
            this.centerMap = false;
        }
    }

    hide() {
        this.visible = false;
    }

    show() {
        this.visible = true;

        if (!this.map) {
            let defaultMapCenter = [51.477928, -0.001545];

            this.map = Leaflet.map(this.mapContainer, {zoomControl: false}).setView(defaultMapCenter, this.mapConfig.initial_zoom || 13);

            Leaflet.control.zoom({
                position: 'bottomright'
            }).addTo(this.map);

            // static pins
            for (const name in this.mapConfig.static_pins || {}) {
                const pinInfo = this.mapConfig.static_pins[name];
                this.addStaticPin(pinInfo);
            }

            Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                maxZoom: 18,
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1,
                accessToken: 'pk.eyJ1IjoiY2JvdGFuYSIsImEiOiJja25vOXk5M2YxY2hoMnBwbmN5eW1ldWd0In0.PLjFP8Yd-Oz9E_VlRGWKHg' // todo: protect url??
            }).addTo(this.map);
        }

        this.map.invalidateSize();
        this.fetchData();
        this.fetchGroupStaticPins();
    }

    addStaticPin(pinInfo) {
        const self = this;

        const manageMarker = function(spMarker, pinInfo) {
            if (pinInfo.hover_msg) {
                spMarker.on('mouseover', function(event) {
                    self.setState({
                        hoverPoint: {
                            x: event.containerPoint.x,
                            y: event.containerPoint.y,
                            message: pinInfo.hover_msg
                        }
                    });
                });

                spMarker.on('mouseout', function() {
                    self.setState({
                        hoverPoint: null
                    });
                });
            }

            if (pinInfo.click_url) {
                spMarker.on('click', function() {
                    window.open(pinInfo.click_url, '_blank');
                });
            }
        }

        if (pinInfo.icon) {
            $.ajax({
                url: pinInfo.icon,
                type: 'GET',
                success: function (data) {
                    let el = $('<div/>');

                    el.append(data);

                    const svgEl = el.find('svg');

                    svgEl.css('width', '32px');
                    svgEl.css('height', '32px');
                    svgEl.find('path').css('fill', pinInfo.color);

                    const spMarker = Leaflet.marker(pinInfo.coords, {icon: Leaflet.divIcon({
                        className: 'demographic-map-marker',
                        html: el[0].outerHTML,
                        iconSize: [32, 32],
                        iconAnchor: ANCHOR_BY_ID['bottom-center'] // ANCHOR_BY_ID[pinInfo.anchor]
                    })}).addTo(self.map);

                    manageMarker(spMarker, pinInfo);
                }
            });
        } else {
            const spMarker = Leaflet.marker(pinInfo.coords, {icon: Leaflet.divIcon({
                className: 'demographic-map-marker',
                html: DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON,
                iconSize: [32, 32],
                iconAnchor: ANCHOR_BY_ID['bottom-center'] // ANCHOR_BY_ID[pinInfo.anchor]
            })}).addTo(this.map);

            $(spMarker._icon).find('svg').css('fill', pinInfo.color);

            manageMarker(spMarker, pinInfo);
        }
    }

    fetchGroupStaticPins() {
        if (!this.props.group.staticPins) {
            return;
        }

        let addresses = [];

        for (const key in this.props.group.staticPins) {
            addresses.push(this.props.group.staticPins[key].address);
        }

        const groupStaticPinIcon = app.user.get('client').preferences?.demographic_map_group_static_pin_icon;
        const self = this;
        let missingAddresses = [];
        let addressesToProcess = {};

        const processAddresses = () => {
            let staticPins = [];

            for (const address in addressesToProcess) {
                let name = '';

                for (const k in this.props.group.staticPins) {
                    if (this.props.group.staticPins[k].address === address) {
                        name = k;
                        break;
                    }
                }

                let pinInfo = {
                    coords: addressesToProcess[address].split(','),
                    hover_msg: name,
                };

                if (groupStaticPinIcon) {
                    pinInfo.icon = groupStaticPinIcon;
                }

                self.addStaticPin(pinInfo);
            }
        }

        const fetchGroupMissingAddresses = () => {
            $.post('/geocoding', JSON.stringify({
                addresses: missingAddresses
            }), function(addresses) {
                if (!self.visible || !self.mounted) {
                    return;
                }

                for (const address in addresses) {
                    addressesToProcess[address] = addresses[address];
                }

                missingAddresses = missingAddresses.filter(a => !(a in addresses));

                if (missingAddresses.length > 0) {
                    _.delay(function() {
                        fetchGroupMissingAddresses();
                    }, 300);
                } else {
                    processAddresses();
                }
            });
        }

        $.post('/geocoding?fetch_missing', JSON.stringify({
            addresses: addresses
        }), function(data) {
            if (!self.visible || !self.mounted) {
                return;
            }

            for (const address in data) {
                if (data[address] === 'fetching') {
                    missingAddresses.push(address);
                } else {
                    addressesToProcess[address] = data[address];
                }
            }

            if (missingAddresses.length > 0) {
                _.delay(function() {
                    fetchGroupMissingAddresses();
                }, 300);
            } else {
                processAddresses();
            }
        });
    }

    fetchData() {
        if (!this.mapConfig.address_builder) {
            return;
        }

        if (this.dataDirty) {
            if (this.map) {
                for (const address in this.addressesInfo) {
                    if (this.addressesInfo[address].marker) {
                        this.map.removeLayer(this.addressesInfo[address].marker);
                    }
                }
            }

            this.dataDirty = false;
            this.totalModelsFetched = 0;
            this.groupPageToFetch = 0;
            this.fetchingWhat = 'entities';
            this.addressesInfo = {};
            this.missingAddresses = [];

            this.setState({
                hoverPoint: null,
                totalUnknownAddresses: 0,
                totalToFetch: 0,
                totalFetched: 0
            });

            this.fetchNextGroupPage();
        } else if (this.fetchingWhat) {
            if (this.fetchingWhat === 'entities') {
                this.fetchNextGroupPage();
            } else if (this.fetchingWhat === 'missingAddresses') {
                this.fetchMissingAddresses();
            }
        }
    }

    handleMarkerChange(marker) {
        if (marker.id === 'default') {
            const defaultColor = this.mapConfig.dynamic_pins?.default?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR;

            for (const address in this.addressesInfo) {
                const icon = $(this.addressesInfo[address].marker._icon);
                icon.find('svg').css('fill', defaultColor);
                icon.find('.marker-num-addresses').css('background', DEMOGRAPHIC_MAP_DEFAULT_CIRCLE_COLOR);
            }

            this.mapKeyComponent.setMarker({
                name: 'Default',
                color: defaultColor
            });
        } else {
            const colorsById = {};

            for (const c of marker.colors) {
                colorsById[c.id] = c.color;
            }

            for (const address in this.addressesInfo) {
                const ai = this.addressesInfo[address];
                const icon = $(ai.marker._icon);

                if (ai.entities.length > 1) {
                    icon.find('svg').css('fill', DEMOGRAPHIC_MAP_MULTIVALUE_PIN_COLOR);
                    icon.find('.marker-num-addresses').css('background', DEMOGRAPHIC_MAP_MULTIVALUE_CIRCLE_COLOR);
                } else {
                    const fieldId = `custom_fields.${marker.dropdownId}`;
                    const cfInfo = this.dropdownCustomFieldsInfo[fieldId];
                    const value = cfInfo[ai.entities[0][fieldId]];
                    const color = colorsById[value || 'default'];

                    icon.find('svg').css('fill', color);
                    icon.find('.marker-num-addresses').css('background', DEMOGRAPHIC_MAP_DEFAULT_CIRCLE_COLOR);
                }
            }

            this.mapKeyComponent.setMarker(marker);
        }
    }

    render() {
        const addressLabel = this.state.totalUnknownAddresses === 1 ? 'address' : 'addresses';

        return (
            <div className={style.demographicMap}>
                <div
                    ref={(el) => this.mapContainer = el}
                    className={style.dmMapContainer}
                />
                {this.state.totalUnknownAddresses > 0 &&
                    <div
                        className={style.dmUnknowAddresses}
                    >
                        +{this.state.totalUnknownAddresses} {addressLabel} unknown
                    </div>
                }
                {this.state.totalToFetch !== this.state.totalFetched &&
                    <div
                        className={style.dmLoaderContainer}
                    >
                        <div
                            className={style.dmLoaderBar}
                            style={{width: `${this.state.totalFetched / this.state.totalToFetch * 100}%`}}
                        />
                    </div>
                }
                {this.state.hoverPoint &&
                    <Popup
                        hoverPoint={this.state.hoverPoint}
                        container={this.mapContainer}
                        entityType={this.props.group.element_type}
                        onShowEntity={this.props.onShowEntity}
                    />
                }
                {(this.state.totalToFetch === this.state.totalFetched) && this.markerSelectorVisible &&
                    <div>
                        <MapKey
                            ref={(el) => this.mapKeyComponent = el}
                            marker={this.state.activeMarker}
                            dropdownsInfo={this.dropdownCustomFieldsInfo}
                        />

                        <MarkerSelector
                            mapConfig={this.mapConfig}
                            onMarkerChange={this.handleMarkerChange.bind(this)}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default DemographicMap;
import _ from 'underscore'
import $ from 'jquery'

export default class CampaignsFetcher {
    constructor(numRows, columns) {
        this.numRows = numRows;
        this.columns = columns;
        this.orderByStr = null;
        this.fetchArgs = null;
        this.fetchIdx = 0;
    }

    searchBy() {
        this.fetchIdx++;
    }

    setSectionId() {
        this.fetchIdx++;
    }

    setColumns(columns) {
        this.columns = columns;
    }

    orderBy(columnId, sortDir) {
        this.orderByStr = `${columnId} ${sortDir}`;
        this.fetchIdx++;
    }

    getOrderBy() {
        const parts = this.orderByStr.split(' ');

        if (parts.length === 2) {
            return {
                columnId: parts[0],
                sortDir: parts[1]
            };
        }

        return null;
    }

    filterBy(filterId) {
        this.filterId = filterId;
        this.fetchIdx++;
    }

    setFetchArgs(fetchArgs) {
        this.fetchArgs = fetchArgs || null;
        this.fetchIdx++;
    }

    getFetchArgs() {
        return this.fetchArgs;
    }

    fetch(page, callback) {
        const args = this.getArgs(page);
        const fetchIdx = this.fetchIdx;
        const self = this;

        $.get(`/campaigns?${$.param(args)}`, function(result, __, request) {
            if (fetchIdx !== self.fetchIdx) {
                return;
            }

            const start = parseInt(request.getResponseHeader('Records-Start'));
            let rows = [];

            for (const row of result) {
                let item = {
                    id: row.id,
                    permissions: row.permissions,
                    index: start + rows.length + 1
                };

                for (const col of self.columns) {
                    switch (col.id) {
                        case 'type':
                            switch (row?.campaign_type) {
                                case 'direct':
                                    item[col.id] = 'icon-direct-email';
                                    break;

                                case 'campaign':
                                    item[col.id] = 'icon-campaign-email';
                                    break;

                                case 'message':
                                    item[col.id] = 'icon-campaign-sms';
                                    break;
                            }
                            break;

                        case 'from':
                            if (row?.from_user) {
                                item[col.id] = row.from_user.name;
                            } else if (row?.from_custom_field) {
                                item[col.id] = `${row.from_custom_field.name} (Custom Field)`;
                            } else {
                                item[col.id] = '';
                            }
                            break;

                        default:
                            let context = row;

                            for (const p of col.fieldPath || [col.id]) {
                                context = context[p];

                                if (!context) {
                                    break;
                                }
                            }

                            if (context === null || _.isUndefined(context)) {
                                item[col.id] = '';
                            } else {
                                item[col.id] = context;
                            }
                            break;
                    }
                }

                rows.push(item);
            }

            callback({
                rows: rows,
                total: parseInt(request.getResponseHeader('Records-Total')),
                start: start,
                numRows: parseInt(request.getResponseHeader('Records-Rows'))
            });
        });
    }

    getArgs(page) {
        let args = {
            start: page * this.numRows,
            rows: this.numRows
        };

        if (this.fetchArgs) {
            _.extend(args, this.fetchArgs);
        }

        if (this.orderByStr) {
            args.order_by = this.orderByStr;
        }

        if (this.filterId) {
            args.filter_id = this.filterId;
        }

        return args;
    }
}
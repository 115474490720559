import _ from 'underscore'
import React from 'react';
import classnames from 'classnames'

import style from './kpi.css';

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: props.values || {}
        };
    }

    setValues(values) {
        this.setState({
            values: values || {}
        });
    }

    render() {
        return (
            <div className={style.KPI}>
                {this.props.sections.map(section => {
                    const value = this.state.values[section.id] || {};

                    const classes = classnames({
                        [style.Section]: true,
                        [style.Negative]: value.delta < 0,
                        [style.Positive]: value.delta > 0
                    });

                    let sectionStyle = {};
                    let textStyle = {};

                    if (section.backgroundColor) {
                        sectionStyle.backgroundColor = section.backgroundColor;
                    }

                    if (section.fontColor) {
                        textStyle.color = section.fontColor;
                    }

                    return (
                        <div
                            key={section.id}
                            className={classes}
                            style={sectionStyle}
                        >
                            <div className={style.Delta}>
                                <div className={style.Content} style={textStyle}>
                                    {value.delta > 0 && <div className='icon-caret-up'/>}
                                    {value.delta < 0 && <div className='icon-caret-down'/>}
                                    {!_.isUndefined(value.delta) &&
                                        <div>{value.delta === 0 ? '=' : `${Math.abs(value.delta)}${section.isPercentage ? '%' : ''}`}</div>
                                    }
                                    {value.fixedDelta &&
                                        <div>{value.fixedDelta}</div>
                                    }
                                </div>
                            </div>
                            {_.isUndefined(value.current)
                                ? <div className={style.Value} style={textStyle}>-</div>
                                : <div className={style.Value} style={textStyle}>{`${value.current}${section.isPercentage ? '%' : ''}`}</div>
                            }
                            <div className={style.Name} style={textStyle}>{section.name}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}
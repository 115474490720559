import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import backboneSelect2 from 'js/widgets/backbone-select2'
import dateFormat from 'js/utils/date-format'
import DateTimePicker from 'js/widgets/date-time-picker'
import app from 'js/app';
import vent from 'js/vent'
import debugTemplate from 'templates/settings/debug.handlebars'


export default Marionette.Layout.extend({
    className: 'debug-settings',
    template: Handlebars.compile(debugTemplate),
    ui: {
        automationSelect: '.automation',
        automationRow: '.automation-row',
        stepSelect: '.step',
        stepRow: '.step-row',
        datetimeRow: '.datetime-row',
        datetimeInput: '.datetime',
        permissionsList: '.users-permissions'
    },
    events: {
        'click .datetime': function() {
            var dateTimePicker = new DateTimePicker({
                altField: this.ui.datetimeInput,
                css: {
                    left: this.ui.datetimeInput.offset().left,
                    top: this.ui.datetimeInput.offset().top - 280
                }
            });

            dateTimePicker.showPicker();
        },
        'change .datetime': function(ev) {
            var stepId = this.ui.stepSelect.select2('val');
            var datetime = ev.target.value;

            if (!stepId || !datetime) {
                return;
            }

            $.ajax({
                type: 'PATCH',
                url: '/automation2_steps/' + stepId + '/entities',
                data: JSON.stringify({
                    type: 'datetime',
                    value: dateFormat.parseDate(datetime)
                }),
                contentType: 'application/json',
                dataType: 'json',
                success: function() {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Date mocked',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
    },
    onRender: function() {
        this.manageDateMocking();
        this.displayUsersPermissions();
    },
    displayUsersPermissions: function() {
        var self = this;

        $.getJSON('/users', function(users) {
            var permissionsByUser = {};
            var userById = {};

            _.forEach(users, function(user) {
                userById[user.id] = user;

                if (user.default_permissions.length === 0) {
                    permissionsByUser[user.id] = ['()'];
                } else {
                    permissionsByUser[user.id] = [];
                    _.forEach(user.default_permissions || [], function(team) {
                        if (team.team) {
                            permissionsByUser[user.id].push(team.team.name);
                        }
                    });
                }
            });

            var content = [];
            _.forEach(permissionsByUser, function(value, key) {
                content.push('<b>' + userById[key].name + ': </b>' + value.join(', '));
            });

            self.ui.permissionsList.html(content.join('<br>'));
        });
    },
    manageDateMocking: function() {
        var self = this;

        $.ajax({
            type: 'GET',
            url: '/automations2',
            contentType: 'application/json',
            dataType: 'json',
            data: {
                rows: -1
            },
            success: function (data) {
                var automations = [];

                _.forEach(data, function(automation) {
                    if (automation.state !== 'off') {
                        automations.push({
                            id: automation.id,
                            name: automation.name
                        });
                    }
                });

                var automationSelect = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.automationSelect,
                    data: automations,
                    text: 'name',
                    options: {
                        allowClear: true,
                        placeholder: 'Search for an automation',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'automation-select-popover popover'
                    }
                });

                self.listenTo(automationSelect, 'change', function(automation) {
                    if (automation) {
                        $.ajax({
                            type: 'GET',
                            url: '/automations2/' + automation.id,
                            contentType: 'application/json',
                            dataType: 'json',
                            success: function (data) {
                                var steps = [];

                                _.forEach(data.steps, function(step) {
                                    if (step.step_type === 'wait') {
                                        steps.push({
                                            id: step.id,
                                            name: step.name
                                        });
                                    }
                                });

                                var stepSelect = new backboneSelect2.SelectView({
                                    view: self,
                                    $el: self.ui.stepSelect,
                                    text: 'name',
                                    data: steps,
                                    options: {
                                        allowClear: true,
                                        placeholder: 'Select a step',
                                        containerCssClass: 'select2-block',
                                        dropdownCssClass: 'automation-select-popover popover'
                                    }
                                });

                                self.listenTo(stepSelect, 'change', function(step) {
                                    self.ui.datetimeRow.toggleClass('hidden', !step);
                                });

                                self.ui.stepRow.toggleClass('hidden', false);
                            }
                        });
                    } else {
                        self.ui.stepRow.toggleClass('hidden', true);
                    }
                });

                self.ui.automationRow.toggleClass('hidden', false);
            }
        });
    },
});

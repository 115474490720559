import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import vent from 'js/vent.js'
import alertTemplate from 'templates/alert.handlebars'


var AlertView = Marionette.Layout.extend({
    template: Handlebars.compile(alertTemplate),
    templateHelpers: function() {
        return {
            type: this.type,
            message: this.message,
            status: this.status,
            statusText: this.statusText
        };
    },
    ui: {
        message: '.message'
    },
    events: {
        'click': function() {
            this.hide(true);
        },
        'mouseover': function() {
            this.stopTimer();
        },
        'mouseout': function() {
            this.startTimer();
        }
    },
    errorMessage: 'There was an error processing this request',
    types: {
        'save': function(data, model) {
            var status = data.status,
                name = data.name,
                error = data.error,
                message,
                classes;

            // hook for backend inconsistency
            if (name === 'Group' && model && model.get('mailing_list') === true) {
                name = 'Mailing List';
            }

            if(!status) {
                message = "Saving " + name + "...";
                classes = 'saving processing';
            } else if (status >= 200 && status < 300) {
                message = name + " saved";
                classes = 'saved success';
            } else {
                message = "Error saving " + name + ": " + error;
                classes = 'save-error error';
            }

            return {
                message: message,
                classes: classes,
                timer: !!status
            };
        },
        'delete': function(data) {
            var status = data.status,
                name = data.name,
                error = data.error,
                message,
                classes;

            if(!status) {
                message = "Deleting " + name + "...";
                classes = 'deleting processing';
            } else if (status >= 200 && status < 300) {
                if (name === 'User') {
                    message = 'Deletion request sent. We will email you when the user is deleted.'
                } else {
                    message = name + " deleted";
                }
                classes = 'deleted success';
            } else {
                message = "Error deleting " + name + ": " + error;
                classes = 'delete-error error';
            }

            return {
                message: message,
                classes: classes,
                timer: !!status
            };
        },
        'load': function(data) {
            var status = data.status,
                name = data.name,
                error = data.error,
                message,
                classes;

            if(!status) {
                message = "Loading " + name + "...";
                classes = 'loaded processing';
            } else if (status >= 200 && status < 300) {
                message = name + " loaded";
                classes = 'loaded success';
            } else {
                message = "Error loading " + name + ": " + error;
                classes = 'load-error error';
            }

            return {
                message: message,
                classes: classes,
                timer: !!status
            };
        },
        'clone': function(data) {
            var status = data.status,
                name = data.name,
                error = data.error,
                message,
                classes;

            if(!status) {
                message = "Cloning " + name + "...";
                classes = 'cloning processing';
            } else if (status >= 200 && status < 300) {
                message = name + " cloned";
                classes = 'cloned success';
            } else {
                message = "Error cloning " + name + ": " + error;
                classes = 'clone-error error';
            }

            return {
                message: message,
                classes: classes,
                timer: !!status
            };
        }
    },
    initialize: function(options) {
        this.data = {
            type: options.type,
            name: options.name || (options.model ? options.model.name : null),
            status: options.status || (options.xhr ? options.xhr.status : null),
            statusText: options.statusText || (options.xhr ? options.xhr.statusText : null),
            error: options.error || this.errorMessage
        };
    },
    onShow: function(){
        var data = this.data,
            type = data.type,
            values;

        if (_.isString(type)) {
            values = this.types[type](data, this.model);
        } else if (_.isFunction(type)) {
            values = type(data, this.model);
        }
        if (!values) {
            return;
        }

        this.ui.message.text(values.message);

        this.$el.addClass(values.classes);

        if (values.timer) {
            this.startTimer(values.timer);
        }
    },
    onClose: function() {
        this.stopTimer();
    },
    startTimer: function(duration) {
        duration = !_.isNumber(duration) || duration <= 0 ? 3000 : duration;

        this.stopTimer();
        this.timer = setInterval(this.hide.bind(this), duration);
    },
    stopTimer: function() {
        clearInterval(this.timer);
    },
    hide: function(now) {
        this.stopTimer();
        vent.trigger("alert:hide", now);
    }
});

export default AlertView;

import React from 'react';
import _ from 'underscore'

import CommunicationModel from 'js/models/communication';
import IndividualModel from 'js/models/contact';
import app from 'js/app'
import vent from 'js/vent'
import Utilities from 'js/utils/utilities';
import htmlSanitizer from 'js/utils/html-sanitizer';
import dateFormat from 'js/utils/date-format';
import CustomFieldsCollection from 'js/collections/custom_fields';

import style from './contact-preview.css';

// columns index
const LoanSourceIdx = 5;
const LastOpportunityIdx = 8;
const ReferredByIdx = 9;
const ContactTypeIdx = 10;
const SourceIdx = 11;

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referredByInfo: null
        };
    }

    componentDidUpdate() {
        this.state.referredByInfo = null;
    }

    loadRequiredCustomFields(callback) {
        this.customFields = {};

        const self = this;
        let customFields = new CustomFieldsCollection();

        customFields.fetch({
            rows: -1,
            filterBy: [{
                attribute: 'view',
                value: 'individuals'
            }],
            success: function(data) {
                for (let cf of data.models) {
                    let cfName = cf.get('name').replace(/ /g, '')
                    cfName = cfName.charAt(0).toLowerCase() + cfName.slice(1);

                    if (['leadSource', 'broker', 'requestReason'].indexOf(cfName) !== -1) {
                        self.customFields[cfName] = cf;
                    }
                }

                callback();
            }
        });
    }

    buildReferredByInfo() {
        const individual = this.props.individual;

        if (!individual) {
            return;
        }

        const self = this;

        const buildInfo = function() {
            let referredByInfo = null;
            const referredBy = individual.get(self.props.columns[ReferredByIdx]);

            if (referredBy) {
                referredByInfo = {
                    name: referredBy.full_name,
                    phone: null,
                    email: null,
                    leadSource: null,
                    broker: null,
                    requestReason: null
                };

                const communications = referredBy.communication || [];

                for (let c of communications) {
                    if (!c.value) {
                        continue;
                    }

                    if (c.medium === 'email') {
                        referredByInfo.email = c.value;
                    } else if (c.medium === 'phone') {
                        referredByInfo.phone = c.value;
                    }

                    if (referredByInfo.email && referredByInfo.phone) {
                        break;
                    }
                }

                if (referredBy.source && referredBy.source.name === 'Broker') {
                    if (self.customFields.leadSource) {
                        const optionId = referredBy.custom_fields[self.customFields.leadSource.get('id')];
                        if (optionId) {
                            const leadSource = self.customFields.leadSource.get('options').find(o => o.id === optionId);
                            if (leadSource) {
                                referredByInfo.leadSource = leadSource.value;
                            }
                        }
                    }

                    if (self.customFields.requestReason) {
                        referredByInfo.requestReason = referredBy.custom_fields[self.customFields.requestReason.get('id')];
                    }

                    if (self.customFields.broker) {
                        const brokerId = referredBy.custom_fields[self.customFields.broker.get('id')];

                        if (brokerId) {
                            let model = new IndividualModel({id: brokerId})
                            model.fetch({
                                success: function(data) {
                                    referredByInfo.broker = data.get('full_name');
                                    self.setState({referredByInfo: referredByInfo});
                                }
                            });
                        }

                    }
                }

                _.defer(function() {
                    self.setState({referredByInfo: referredByInfo});
                });
            }
        }

        if (_.isEmpty(this.customFields)) {
            this.loadRequiredCustomFields(function() {
                buildInfo();
            });
        } else {
            buildInfo();
        }
    }

    openContact() {
        const url = (window.location.origin || (window.location.protocol + '//' + window.location.host));
        window.open(`${url}/#individuals/${this.props.individualId}`, '_blank');
    }

    render() {
        if (!this.props.individual) {
            return null;
        }

        const individual = this.props.individual;
        const individualInfo = [];

        const contactType = individual.get(this.props.columns[ContactTypeIdx]);
        if (contactType) {
            individualInfo.push({
                icon: 'icon-user',
                label: 'Contact Type',
                value: contactType
            });
        }

        const source = individual.get(this.props.columns[SourceIdx]);
        if (source) {
            individualInfo.push({
                icon: 'icon-leads',
                label: 'Source',
                value: source
            });
        }

        individualInfo.push({
            icon: 'icon-plus',
            label: 'Created',
            value: dateFormat.entityInformationFormat(this.props.individual.get('created'))
        });

        const loanInfo = individual.get(this.props.columns[LastOpportunityIdx]);
        if (loanInfo) {
            if (loanInfo.custom_fields && loanInfo.custom_fields.Loan_Type) {
                individualInfo.push({
                    icon: 'icon-note',
                    label: 'Loan Type',
                    value: loanInfo.custom_fields.Loan_Type
                });
            }

            if (loanInfo.phase) {
                individualInfo.push({
                    icon: 'icon-funnel',
                    label: 'Phase',
                    value: loanInfo.phase.name
                });
            }

            if (loanInfo.expected_close_date) {
                individualInfo.push({
                    icon: 'icon-calendar',
                    label: 'Close Date',
                    value: dateFormat.entityInformationFormat(loanInfo.expected_close_date)
                });
            }
        }

        const communicationInfo = [];
        const phones = individual.get('phone');
        const emails = individual.get('email');

        if (phones.length > 0) {
            const model = new CommunicationModel({
                name: phones[0].name,
                value: phones[0].value,
                medium: 'phone'
            });

            communicationInfo.push({
                icon: 'icon-phone',
                model: model,
                onClick: function() {
                    app.followLink(individual, model.valueHref(model));
                }
            });

            // find a phone with sms support
            if (app.user.get('can_send_sms')) {
                for (const phone of phones) {
                    if (Utilities.getNormalizedPhoneNumber(phone.value)) {
                        const smsModel = new CommunicationModel({
                            name: phone.name,
                            value: phone.value,
                            medium: 'phone'
                        });

                        const self = this;

                        communicationInfo.push({
                            icon: 'icon-chat',
                            model: smsModel,
                            onClick: function() {
                                vent.trigger('quick:create:message', self.props.individualId, smsModel.get('value'));
                            }
                        });
                        break;
                    }
                }
            }
        }

        if (emails.length > 0) {
            const model = new CommunicationModel({
                name: emails[0].name,
                value: emails[0].value,
                medium: 'email'
            });

            communicationInfo.push({
                icon: 'icon-email',
                model: model,
                onClick: function() {
                    app.followLink(individual, model.valueHref(model));
                }
            });
        }

        if (!this.state.referredByInfo) {
            this.buildReferredByInfo();
        }

        const photoUrl = individual.get('photo_url');
        let note = '';
        let noteDate = '';

        if (individual.get('pinned_activity_exclude_email').length > 0) {
            note = individual.get('pinned_activity_exclude_email')[0].note;
            noteDate = individual.get('pinned_activity_exclude_email')[0].created;
        } else if (individual.get('last_activity_exclude_email')) {
            note = individual.get('last_activity_exclude_email').note;
            noteDate = individual.get('last_activity_exclude_email').created;
        }

        note = htmlSanitizer.sanitize(note, false, false, true);

        return (
            <div className={style.ContactPreview}>
                <div className={style.Header}>
                    {individual.get('last_name')}, {individual.get('first_name')}
                </div>
                <div className={style.Content}>
                    <div className={style.Section}>
                        {photoUrl &&
                            <div className={style.Photo} onClick={this.openContact.bind(this)} style={{backgroundImage: `url(${photoUrl})`}}/>
                        }
                        {!photoUrl &&
                            <div className={style.NoPhoto} onClick={this.openContact.bind(this)}><i className="icon-user"></i></div>
                        }
                    </div>
                    {individualInfo.length > 0 && <div className={style.Section}>
                        <div className={style.Rows}>
                            {individualInfo.map(info => {return (
                                <div className={style.Row} key={info.icon}>
                                    <div className={style.Icon}><i className={info.icon}></i></div>
                                    <div className={style.Label}>{info.label}:</div>
                                    <div className={style.Value} title={info.value}>{info.value}</div>
                                </div>
                            )})}
                        </div>
                    </div>}
                    {communicationInfo.length > 0 && <div className={style.Section}>
                        <div className={style.Rows}>
                            {communicationInfo.map(info => {return (
                                <div className={style.Row} key={info.icon}>
                                    <div className={style.Icon}><i className={info.icon}></i></div>
                                    <div className={style.Label}>{info.model.get('name')}:</div>
                                    <div className={style.Value} title={info.model.get('value')}>{info.model.get('value')}</div>
                                    <div className={style.Button} onClick={info.onClick}><i className={info.icon}></i></div>
                                </div>
                            )})}
                        </div>
                    </div>}
                    {this.state.referredByInfo && <div className={style.Section}>
                        <div className={style.ReferredBy}>
                            <div className={style.title}>Referred By:</div>
                            <div className={style.Rows}>
                                <div className={style.Row}>
                                    <div className={style.Icon} title="Name"><i className="icon-user"></i></div>
                                    <div className={style.Value}>{this.state.referredByInfo.name}</div>
                                </div>
                                {this.state.referredByInfo.phone && <div className={style.Row}>
                                    <div className={style.Icon} title="Phone"><i className="icon-phone"></i></div>
                                    <div className={style.Value}>{this.state.referredByInfo.phone}</div>
                                </div>}
                                {this.state.referredByInfo.email && <div className={style.Row}>
                                    <div className={style.Icon} title="Email"><i className="icon-email"></i></div>
                                    <div className={style.Value} title={this.state.referredByInfo.email}>{this.state.referredByInfo.email}</div>
                                </div>}
                                {this.state.referredByInfo.leadSource && <div className={style.Row}>
                                    <div className={style.Icon} title="Lead Source"><i className="icon-leads"></i></div>
                                    <div className={style.Value} title={this.state.referredByInfo.leadSource}>{this.state.referredByInfo.leadSource}</div>
                                </div>}
                                {this.state.referredByInfo.broker && <div className={style.Row}>
                                    <div className={style.Icon} title="Broker"><i className="icon-user"></i></div>
                                    <div className={style.Value} title={this.state.referredByInfo.broker}>{this.state.referredByInfo.broker}</div>
                                </div>}
                                {this.state.referredByInfo.requestReason && <div className={style.Row}>
                                    <div className={style.Icon} title="Request Reason"><i className="icon-pencil"></i></div>
                                    <div className={style.Value} title={this.state.referredByInfo.requestReason}>{this.state.referredByInfo.requestReason}</div>
                                </div>}
                            </div>
                        </div>
                    </div>}
                    <div className={`${style.Section} ${style.Column}`}>
                        {noteDate && <div>{`${dateFormat.entityInformationFormat(noteDate)} ${dateFormat.shortFormatTime(noteDate)}:`}</div>}
                        <div className={style.Note}>
                            {note}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
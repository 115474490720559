import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import vent from 'js/vent.js'
import ActivityFeedView from 'js/views/activity/feed.js'
import createIndividualsList from 'js/views/individuals/create-individuals-list.js'
import createDealsList from 'js/views/opportunities/create-deals-list.js'
import RelatedFilesListView from 'js/views/related_files_list.js'
import RelatedFilesCollection from 'js/collections/related_files.js'
import app from 'js/app.js'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import appContent from 'js/views/appcontent.js'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import api from 'js/api.js'
import linkify from 'js/utils/linkify.js'
import security from 'js/utils/security.js'
import MessageBox from 'js/views/message_box.js'
import CommunicationsListView from 'js/views/base/communications-list.js'
import LocationsListView from 'js/views/base/locations-list.js'
import ActivityCreator from 'js/utils/activity_creator.js'
import UserModel from 'js/models/user.js'
import ModalRegion from 'js/views/base/modal-region.js'
import ItemPermissionsView from 'js/views/item_permissions.js'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import OpportunitiesCollection from 'js/collections/opportunities.js'
import CommunicationCollection from 'js/collections/communications.js'
import LocationsCollection from 'js/collections/locations.js'
import IndividualsCollection from 'js/collections/contacts.js'
import CommunicationModel from 'js/models/communication.js'
import ChecklistsCollection from 'js/collections/checklists.js'
import CustomFieldsView from 'js/views/custom_fields.js'
import TasksView from 'app_v2/panels/tasks_list.js'
import ChecklistsView from 'js/react_views/checklists/checklists.js'
import GroupListContainsView from 'js/views/groups/list-contains.js'
import OrganizationContainerView from 'js/react_containers/OrganizationContainerView';
import organizationViewTemplate from 'templates/organizations/detail.handlebars';
import overviewTemplate from 'templates/organizations/detail_overview.handlebars';
import noPermissionViewTemplate from 'templates/organizations/no_permission.handlebars';


var OrganizationView, OverviewView, DetailViewController, NoPermissionView;

DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.changes = {};
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        app.dirtyModelHandler.add(this.model.cid);
        this.changes[key] = value;
    },
    saveEdition: function() {
        var controller = this;

        this.trigger('editing:save:before');

        if (_.keys(this.changes).length) {
            this.model.save(this.changes, {
                extraFields: ['locations'],
                patch: true,
                success: function() {
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('contact:save');
                    app.dirtyModelHandler.remove(controller.model.cid);
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    cancelEdition: function() {
        app.dirtyModelHandler.confirm(this, function() {
            var controller = this;
            this.changes = {};
            this.model.fetch({
                success: function () {
                    controller.trigger('editing:cancel');
                    controller.endEditing();
                }
            });
        });
    },
    destroyModel: function(callback) {
        this.model.destroy({
            wait: true,
            success: callback
        });
    },
    followCommunicationLink: function(communication, link, onSaveCallback) {
        this.followCommsLink(this.model, communication, link, onSaveCallback);
    },
    followCommsLink: function(model, communication, link, onSaveCallback) {
        var activity = ActivityCreator.createAutoCommunication(
            communication.get('medium'),
            communication,
            this.model.get('type'),
            this.model.get('id')
        );

        window.open(link);

        if (activity) {
            activity.save({}, {
                complete: function() {
                    vent.trigger('update:activity');
                    if (onSaveCallback) {
                        onSaveCallback(activity);
                    }
                }
            });
        }
    }
});

var TagItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tag',
    template: Handlebars.compile('{{name}}'),
});

NoPermissionView = Marionette.ItemView.extend({
    className: 'overview content',
    template: Handlebars.compile(noPermissionViewTemplate)
});

OrganizationView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-organizations content-container',
    template: Handlebars.compile(organizationViewTemplate),
    regions: {
        organizationContainerRegion: '.overview-container',
        activityFeedRegion: '.notes-pane',
        taskFeedRegion: '.tasks-pane',
        customFieldsRegion: '.custom-fields-container',
        checklistsRegion: '.checklists-container'
    },
    events: {
        'click .edit.has-permission': 'startEditing',
        'click .tabs a': function(ev) {
            ev.preventDefault();
            this.showTab($(ev.currentTarget));
        },
        'click .close-view': 'closeView',
        'click .save': 'saveItem',
        'click .cancel': 'cancelEdition',
        'resize': 'scrollbar'
    },
    ui: {
        'activity': '.activity-container',
        'activityTabContents': '.activity-container .tab-pane'
    },
    initialize: function() {
        if ( !this.model.id ) {
            this.creatingNewItem = true;
            this.model.set("owner", { id: app.user.get('id'), name: app.user.get('name') });
        }
        this.editing = this.options.editing;

        this.controller = new DetailViewController({
            model: this.model
        });

        this.listenTo(vent, 'update-checklist', function() {
            this.createChecklistsView();
        });

        this.listenTo(vent, 'update-tasks', function() {
            this.createTasksView();
        });

        this.listenTo(this.controller, 'editing:start',
            this.onStartEditing);
        this.listenTo(this.controller, 'editing:end',
            this.onEndEditing);
        this.listenTo(this.model, 'sync', this.onModelSync);
        this.listenTo(this.model, 'destroy remove', this.onModelDestroy);

        this.listenTo(vent, 'contact:updated', function() {
            this.onRender();
        });

        this.initialRightTab = this.options.rightTab;
    },
    updateActivityTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }

        var self = this,
            view = this.activityFeedRegion.currentView;
        if (view) {
            view.removeTinyMCE(view);
            // exit edit mode for list elements not to break TinyMCE
            view.listRegion.currentView && view.listRegion.currentView.children.each(function (item) {
                item.exitEditMode && item.exitEditMode();
            });
        }

        location.append(content);
        _.defer(function () {
            var view = self.activityFeedRegion.currentView;
            view && view.initTinyMCE(view);
        });
    },
    updateTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }
        location.append(content);
    },
    onClose: function() {
        this.controller.close();
        $(window).off('resize', this.onResize);
        this.$el.parent('.region').removeClass('non-condensed-detail-region');
        $('#app-stacks').removeClass('non-condensed-app-stacks');
    },
    onRender: function() {
        var self = this;

        $(window).on('resize', $.proxy(this.onResize, this));

        if (this.model.id) {
            this.model.fetch({
                extraFields: ['locations'],
                success: function() {
                    if (AppConfig.getValue('organizations.groups.recently_viewed.visible', true)) {
                        $.ajax({
                            url: '/users/' + app.user.get('id') + '/recent_organizations/items/' + self.model.get('id'),
                            contentType: 'application/json',
                            processData: false,
                            dataType: 'json',
                            type: 'PUT'
                        });
                    }
                    self.onViewModel();
                },
                error: function(model, response, options) {
                    if (options.xhr.status === 403) {
                        self.showNoPermissions();
                    }
                }
            });
        } else {
            this.organizationContainerRegion.show(new OrganizationContainerView({
                model: this.model
            }));
            this.listenTo(this.organizationContainerRegion.currentView, 'close-view', function() {
                self.trigger('view:close', self);
            });
            this.listenTo(this.organizationContainerRegion.currentView, 'replace-view:edit', function() {
                self.organizationContainerRegion.currentView.setEditing(true, true);
            });
            this.listenTo(this.organizationContainerRegion.currentView, 'show-tab', function(tab) {
                self.showTab(self.$el.find(`a#${tab}`));
            });
        }



        /*
        var view = this;

        $(window).on('resize', { self: this }, this.onResize);

        // New item styling if no model.id is present
        this.$el.toggleClass('is-new', !this.model.id);

        if (this.model.id) {
            this.model.fetch({
                extraFields: ['locations'],
                success: function() {
                    $.ajax({
                        url: '/users/' + app.user.get('id') + '/recent_organizations/items/' + view.model.get('id'),
                        contentType: 'application/json',
                        processData: false,
                        dataType: 'json',
                        type: 'PUT'
                    });
                    view.onViewModel();
                },
                error: function(model, response, options) {
                    if (options.xhr.status === 403) {
                        view.showNoPermissions();
                    }
                }
            });
        }
        else {
            this.organizationContainerRegion.show(new OverviewView({
                parent: this,
                model: this.model,
                controller: this.controller
            }));

            this.initCustomFieldsView();
        }

        if (this.editing) {
            // start editing when views ar shown
            _.defer(function() {
                view.controller.startEditing();
            });
        }

        this.scrollbar();
        */
    },
    onResize: function(e) {
        if (e && e.data && e.data.self) {
            e.data.self.positionPanes();
        }
    },
    positionPanes: function() {
        // there are uncaught resize events coming from different places, so need to make sure we have view open
        if (this.isClosed) {
            return;
        }
        var display = this.ui.activity.css('display') !== 'none';
        if (this.lastDisplay === display) {
            return;
        }
        this.lastDisplay = display;

        this.updateActivityTab($('#notes-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#tasks-pane'), $('.overview.content'), this.ui.activity);

        this.$el.find('.tabs .active').removeClass('active');
        this.$el.find('.detail-nav .tabs .details-tab a').tab('show');
        if (display) {
            if (this.initialRightTab === 'Task') {
                this.$el.find('.activity-container .tabs .tasks-tab a').tab('show');
                delete this.initialRightTab;
            }
            else {
                this.$el.find('.activity-container .tabs .notes-tab a').tab('show');
            }
        }

        this.scrollbar();
    },
    onViewModel: function() {
        if (this.isClosed) {
            return;
        }

        var view = this;

        var OverviewViewLayout = OverviewView;
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            OverviewViewLayout = OrganizationContainerView;
        }
        var overviewView;

        if ( this.organizationContainerRegion ) {
            overviewView = new OverviewViewLayout({
                parent: this,
                model: this.model,
                controller: this.controller
            });
            this.listenTo(overviewView, 'close-view', this.closeView);
            this.listenTo(overviewView, 'replace-view:edit', function() {
                overviewView.setEditing(true, true);
            });
            this.listenTo(overviewView, 'show-tab', function(tab) {
                view.showTab(view.$el.find(`a#${tab}`));
            });

            this.organizationContainerRegion.show(overviewView);
        }

        view.onActivityFeed();

        this.createTasksView();

        this.positionPanes();

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.initCustomFieldsView();
        }
        this.scrollbar();

        if(!this.checklistsRegion.currentView){
            this.createChecklistsView();
        }
    },
    createTasksView: function() {
        const tasksView = new TasksView({
            related: this.model.toJSON()
        });

        this.taskFeedRegion.show(tasksView);

        const self = this;

        this.listenTo(tasksView, 'task:count:change', function(count) {
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }

        /*
        this.taskContainer = new TasksView.SingleTaskListContainer({
            filterModel: new Backbone.Model({
                where: {
                    'related_type': 'organization',
                    'related_id': this.model.get('id')
                }
            }),
            defaultAssigneeSelf: true,
            itemTitle: this.model.get('name'),
            entityType: 'organization',
            displayFilters: true
        });

        var self = this;

        this.taskFeedRegion.show(this.taskContainer);
        this.listenTo(this.taskContainer, 'task:count:change', function(count) {
            // Targets all instances of '.task-counter'
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }
        */
    },
    createChecklistsView: function() {
        const self = this

        app.shortTermCache.get(`/organizations/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            var checklists = [];
            var checklistsCollection = new ChecklistsCollection(data);

            for (const checklist of checklistsCollection.models) {
                const clSettings = checklist.get('checklist_settings') || {};

                if (!clSettings.hidden) {
                    checklists.push(checklist);
                }
            }

            if (checklists.length > 0){
                self.ui.activity.addClass('checklist-content');
                self.checklistsRegion.show(new ChecklistsView({
                    model: self.model,
                    checklists: checklists,
                    entityType: 'organization'
                }));
            }
        });
    },
    showNoPermissions: function() {
        this.organizationContainerRegion.show(new NoPermissionView());
        this.$el.addClass('no-view-permission');
    },
    onActivityFeed: function() {
        if ( this.activityFeedRegion ) {
            this.activityFeedRegion.show(new ActivityFeedView({
                parent: this,
                model: this.model
            }));
        }
        this.positionPanes();
    },
    showTab: function(tab) {
        var tabId = tab.attr('id');

        switch(tabId) {
            case 'notes':
                this.activityFeedRegion.currentView.fetchActivity();
                break;

            case 'tasks':
                this.taskFeedRegion.currentView.show();
                break;
        }

        if (!tab.parent().hasClass('active')) {
            tab.tab('show'); // Bootstrap tabs
            vent.trigger('organizationTabs:change', tabId);
            this.scrollbar();
        }
    },
    onShow: function() {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.$el.parent('.region').addClass('non-condensed-detail-region');
            $('#app-stacks').addClass('non-condensed-app-stacks');
        }
    },
    replaceOverviewViewForEdit: function() {
        var overviewView = new OverviewView({
            controller: this.controller,
            parent: this,
            model: this.model
        });
        this.organizationContainerRegion.show(overviewView);
        // customFieldsRegion is in the child overviewView (i know…)
        // we've recreated the overviewView so need to reset customFieldsRegion
        // so its host element is queried from the DOM again
        this.customFieldsRegion.reset();
        this.initCustomFieldsView();
        this.controller.startEditing();
        this.scrollbar();
    },
    startEditing: function() {
        this.controller.startEditing();
    },
    cancelEdition: function() {
        this.controller.cancelEdition();
    },
    onStartEditing: function() {
        var view = this;
        this.listenTo(vent, 'shortcut:save', function(ev) {
            var target = $(ev.target);

            // if we have a input with focus we made it lost and gain again to force to save the data inside it
            if (target && target.is('input')) {
                target.blur();
                target.focus();
            }

            view.saveItem();
        });

        // ...
        this.$el.addClass('edit-mode');
        this.$el.find('.related-content').hide();
        // Show details pane on edit
        this.$el.find('.details-tab a').tab('show');

        if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
            this.customFieldsRegion.currentView.onStartEditing();
        }

        this.scrollbar();
    },
    onEndEditing: function() {
        this.stopListening(vent, 'shortcut:save');

        // Cancel create new item
        if (!this.model.id) {
            appContent.pop({});
            return;
        }

        var overviewView;

        // Exit edit mode
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            overviewView = new OrganizationContainerView({
                controller: this.controller,
                parent: this,
                model: this.model
            });
            this.listenTo(overviewView, 'close-view', this.closeView);
            this.listenTo(overviewView, 'replace-view:edit', this.replaceOverviewViewForEdit);

            this.organizationContainerRegion.show(overviewView);

            // hack the nanoscroller property off the region's dom element
            // after replacing Marionette OverviewView with OrganizationContainerView
            // this is to allow nanoScroller to initialize itself when OverviewView
            // is shown again which would otherwise be prevented by other views
            // nested inside OverviewView that also use nanoScroller
            delete this.organizationContainerRegion.$el.get(0).nanoscroller;

            this.$el.removeClass('edit-mode');
        }
        else {
            this.$el.removeClass('edit-mode');
            this.$el.find('.related-content').show();

            // Show details pane on edit
            this.$el.find('.details-tab a').tab('show');

            if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
                this.customFieldsRegion.currentView.onEndEditing();
            }

            this.scrollbar();
        }
    },
    saveItem: function() {
        if (this.manageCustomFields()) {
            this.controller.saveEdition();
        }
    },
    /**
     * Checks whether custom fields are changed, are there valid values and marks changes in controller if so.
     * Shows and hides error fields accordingly.
     *
     * @returns {boolean}   whether custom fields are ready to be saved
     */
    manageCustomFields: function() {
        var customFieldsRegion = this.customFieldsRegion && this.customFieldsRegion.currentView;
        var organizationContainerRegion = this.organizationContainerRegion.currentView;
        if (customFieldsRegion) {
            if (customFieldsRegion.valuesAreValid()) {
                customFieldsRegion.showInvalidFieldsError(false);
                if (customFieldsRegion.hasChanges()) {
                    this.controller.addChange('custom_fields', customFieldsRegion.getChanges());
                }
            }
            else {
                organizationContainerRegion.error_messages.remove.call(organizationContainerRegion);
                customFieldsRegion.showInvalidFieldsError(true);

                var changes = _.extend(this.model.attributes, this.controller.changes);
                var errors = this.model.validate(changes);
                organizationContainerRegion.showInvalidFields(errors);

                return false;
            }
        }

        return true;
    },
    onModelSync: function() {
        if ( this.creatingNewItem ) {
            this.creatingNewItem = false;
            this.editing = false;
            this.render();
            vent.trigger('AppContent:contentChange');
        }
    },
    onModelDestroy: function() {
        this.trigger('view:close', this);
    },
    closeView: function(event) {
        if (event) {
            event.preventDefault();
        }
        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('view:close', this);
        });
    },
    getUrl: function() {
        var id = this.model.get('short_id') || this.model.id;

        return 'organizations/' + (id ? id : 'new');
    },
    scrollbar: _.debounce(function() {
        if (!this.closed) {
            this.$el.nanoScroller();

            if (!this.organizationContainerRegion || !this.organizationContainerRegion.$el) {
                // nothing to do
                return;
            }

            if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002') &&
                this.organizationContainerRegion.currentView &&
                this.organizationContainerRegion.currentView.constructor === OrganizationContainerView) {

                // React view in use - don't init nanoScroller
                return;
            }

            var scrolledContainer = this.$el.find('.content-container.overview-container');
            scrolledContainer.nanoScroller();
        }
    }, 500),
    initCustomFieldsView: function() {
        var view = this;
        var customFields = new CustomFieldsCollection();

        customFields.fetch({
            filterBy: [{
                attribute: 'view',
                value: 'organizations'
            }],
            success: function() {
                if (view.isClosed) {
                    return;
                }
                var customFieldsView = new CustomFieldsView();

                customFieldsView.onDataLoaded({
                    model: view.model,
                    collectionDefines: customFields,
                    editing: view.controller.editing
                });

                view.listenTo(customFieldsView, 'editing:valueChanged', view.manageCustomFields);

                view.customFieldsRegion.show(customFieldsView);
                view.scrollbar();
            }
        });
    }
});

/*
 * The main area of the detail page, with the header, communications,
 * locations, comments, related deals...
 */
OverviewView = Marionette.Layout.extend({
    tagName: 'div',
    className: 'overview content',
    template: Handlebars.compile(overviewTemplate),
    regions: {
        communicationsRegion: '.communications-container',
        locationsRegion: '.locations-container',
        relatedContactsRegion: '.related-contacts',
        relatedDealsRegion: '.related-deals',
        relatedFilesRegion: '.related-files',
        relatedGroupsRegion: '.related-groups',
        tagsRegion: '.tags-container .display-field',
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    ui: {
        display_name: '.name',
        display_image_mask: '.profile-image-mask',
        display_logo: '.profile-image',
        display_comments: '.more-info',
        display_owner: '.detail-owner .display-field',
        field_name: '.field-name',
        fieldWebsite: '.field-website',
        field_comments: '.field-more-info',
        edit_owner: '.field-owner',
        editTags: '.field-tags',
        header: '.detail-header',
        tooltips: '[data-toggle=tooltip]',
        error_messages: '.error-message',
        name_warning_message: '.name-warning-message'
    },
    events: {
        'click .delete-button': function() {
            app.dirtyModelHandler.confirm(this, function() {
                var self = this,
                    mbContent = {
                        accept_is_negative: true,
                        message: TextManager.getText('ID_DELETE_ORGANIZATION_WARNING_MESSAGE', [_.escape(this.model.get('name'))]),
                        icon: 'icon-trashcan'
                    };
                MessageBox.showYesNo(mbContent, this, function () {
                    self.controller.destroyModel(function () {
                        vent.trigger('deal:delete');
                        vent.trigger('model:delete');
                    });
                });
            });
        },
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'keyup .field-name': function(ev) {
            this.organizationsSearcher($(ev.target).val().trim());
        },
        'paste .field-name': function(ev) {
            this.organizationsSearcher(ev.originalEvent.clipboardData.getData('text/plain').trim());
        },
        'change .field-name': 'change_name',
        'change .field-website': function() {
            var val = this.ui.fieldWebsite.val(),
                website = this.communicationCollection.find(function(com) {
                    return com.get('medium') === 'website';
                });
            if (website) {
                website.set('value', val);
            }
            else {
                this.communicationCollection.add(new CommunicationModel({
                    medium: 'website',
                    value: val
                }));
            }
            // Mark communication field as dirty
            this.controller.addChange('communication', this.communicationCollection.toJSON());
        },
        'change .field-more-info': 'change_comments',
        'click .edit-permissions.has-permission': 'showACL'
    },
    templateHelpers: function () {
        var owner = this.model.get('owner');
        var website = this.communicationCollection.find(function(com) {
                return com.get('medium') === 'website';
            });

        website = website ? website.get('value') : null;

        return {
            owner: owner ? { name: owner.name } : null,
            primaryLocation: this.model.primaryLocation(),
            primaryWebsite: this.model.primaryWebsite(),
            website: website,
            archiveAddress: 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST')
        };
    },
    initialize: function(options) {
        var view = this;

        this.controller = options.controller;

        this.locationCollection = new LocationsCollection(this.model.get('locations'));

        this.communicationCollection = new CommunicationCollection(this.model.get('communication'));

        this.listenTo(this.model, 'sync', this.onModelSync);

        this.listenTo(this.controller, 'editing:cancel', function() {
            view.error_messages.remove.call(view);
            this.communicationCollection.reset(this.model.get('communication'));
        });

        this.listenTo(this.controller, 'editing:start', function() {
            view.error_messages.unhide.call(view);
            view.ui.field_name.select();
        });

        this.listenTo(this.controller, 'editing:save:after', function() {
            view.error_messages.remove.call(view);
            // reset collection to set id's for newly created locations
            this.locationCollection.reset(this.model.get('locations'));
            this.communicationCollection.reset(this.model.get('communication'));
        });

        this.listenTo(this.controller, 'editing:save:before', function() {
            view.error_messages.remove.call(view);
        });

        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.showInvalidFields(errors);
        });

        this.initOrganizationsSearcher();
    },
    initOrganizationsSearcher: function() {
        var timeout;
        var view = this;

        this.organizationsSearcher = function(term) {
            window.clearTimeout(timeout);

            timeout = window.setTimeout(function() {
                if (term) {
                    var url = '/organizations?search=' + term + '&search_pattern=${term}';
                    $.getJSON(url , function(data) {
                        if (!view.isClosed) {
                            // get the info from the last created organization (not including current org)
                            if (!view.model.isNew()) {
                                var mid = view.model.get('id');

                                data = _.filter(data, function(e) {
                                    return e.id !== mid;
                                });
                            }

                            if (data.length > 0) {
                                data = _.sortBy(data, function(e) {
                                    return e.item.created;
                                });

                                var existingOrg = data[data.length - 1].item;
                                var href = '#organizations/' + existingOrg.id;

                                view.showWarningMessage(view.ui.name_warning_message, '<i class="icon-warning" style="margin-right: 5px;"></i>There is at least <a class="link" href="' + href + '">1 ' + TextManager.getText('ID_ORGANIZATION') + '</a> with this name');
                            }
                            else {
                                view.hideWarningMessage(view.ui.name_warning_message);
                            }
                        }
                    });
                }
                else {
                    view.hideWarningMessage(view.ui.name_warning_message);
                }
            }, 500);
        };
    },
    showInvalidFields: function(errors) {
        if (errors) {
            if (errors.missing_organization_name || errors.organization_name_too_short) {
                this.ui.field_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_organization_name || errors.organization_name_too_short)
                    .addClass('invalid');
            }
        }

        this.$el.find('.validation_error:first').focus();
    },
    showWarningMessage: function(ui, message) {
        ui.html(message);
        ui.show();
    },
    hideWarningMessage: function(ui) {
        ui.hide();
    },
    onRender: function() {
        var view = this;

        this.communicationsRegion.show(
            new CommunicationsListView({
                controller: this.controller,
                collection: this.communicationCollection,
                checkEmailExistenceFor: 'organizations'
            }));

        this.locationsRegion.show(
            new LocationsListView({
                controller: this.controller,
                collection: this.locationCollection
            }));

        if (this.model.get('id')) {
            view.onIndividuals(new (IndividualsCollection.extend({
                urlRoot: function() {
                    return view.model.url() + '/individuals';
                },
                defaultSortOn: [{
                    attribute: 'full_name',
                    order: 'asc'
                }]
            }))());

            var opportunitiesCollection = new (OpportunitiesCollection.extend({
                defaultRows: 20,
                urlRoot: function () {
                    return '/organizations/' + view.model.get('id') + '/opportunities';
                }
            }))();
            opportunitiesCollection.fetch({
                success: function() {
                    view.onDeals(opportunitiesCollection);
                }
            });
        }

        var owner = this.model.get('owner');
        this.ownerSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_owner,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: {
                name: owner.name,
                id: owner.id
            },
            options: {
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.ownerSelect, 'change', this.change_owner);
        // Check for admin permissions
        if ( !security.checkPermission('change_ownership', this.model) ) {
            this.ownerSelect.$el.select2('readonly', true);
            this.ownerSelect.$el.select2('container').tooltip({
                title: "You don't have permission to edit owner",
                container: 'body'
            });
        }

        _.defer(function() {
            view.tagsSelect = new backboneSelect2.TagView({
                view: view,
                $el: view.ui.editTags,
                value: view.model.get('tags'),
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: '+ Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            view.listenTo(view.tagsSelect, 'change', view.changeTags);
        });

        this.initTooltips();
    },
    onModelSync: function() {
        if (this.isClosed) {
            return;
        }

        var name = this.model.get('name'),
            comments = this.model.get('comments'),
            owner = this.model.get('owner');

        var website = this.communicationCollection.find(function(com) {
                return com.get('medium') === 'website';
            });
        website = website ? website.get('value') : null;

        this.setOrganizationLogo(website);

        this.tagsRegion.show(new Marionette.CollectionView({
            tagName: 'ul',
            itemView: TagItemView,
            collection: new Backbone.Collection(this.model.get('tags'))
        }));

        if (this.tagsSelect) {
            this.tagsSelect.setValue(this.model.get('tags'));
        }

        if (this.communicationsRegion) {
            this.ui.display_name.text(name);
            this.ui.fieldWebsite.val(website);
            this.ui.display_comments.html((comments ? linkify(comments) : 'No information to display'));

            this.ui.field_name.val(name);
            this.ui.field_comments.val(comments);

            this.ownerSelect.setValue({ name: owner.name, id: owner.id });
            this.ui.display_owner.text(owner.name);
        }

        this.relatedGroupsRegion.show(new GroupListContainsView({
            elementType: 'organizations',
            itemId: this.model.get('id')
        }));

        var self = this;
        var relatedFiles = new RelatedFilesListView({
            collection: new (RelatedFilesCollection.extend({
                urlRoot: function() {
                    return '/organizations/' + self.model.get('id') + '/related_files';
                }
            })),
            model: this.model,
            elementType: 'organizations'
        });

        this.relatedFilesRegion.show(relatedFiles);

        this.options.parent.scrollbar();
    },
    onIndividuals: function(individuals) {
        var relatedContactsView;

        relatedContactsView = createIndividualsList(
            this.options.parent,
            individuals,
            {
                hasPermissions: security.checkPermission('edit', this.model),
                hideCheckbox: true
            },
            {
                selectAllPage: false,
                addItemOptions: {
                    selectDropdownExtraClasses: 'individual-select-popover',
                    selectFormatResult: function(item, container) {
                        var message = '{{title}}'

                        if (item.item.organization_name) {
                            message += '<div class="organization-name">{{orgName}}</div>';
                            container.addClass('has-organization-name');
                        }

                        return Handlebars.compile(message)({title: item.title, orgName: item.item.organization_name});
                    }
                }
            },
            null,
            this.model
        );

        if ( this.relatedContactsRegion ) {
            this.relatedContactsRegion.show(relatedContactsView);
        }
    },
    onDeals: function(deals) {
        var relatedDealsView;

        relatedDealsView = createDealsList(
            this.options.parent,
            deals,
            {
                hideCheckbox: true
            },
            {
                selectAllPage: false
            },
            null,
            this.model
        );

        if ( this.relatedDealsRegion ) {
            this.relatedDealsRegion.show(relatedDealsView);
            this.options.parent.scrollbar();
        }

        this.$el.trigger('resize');
    },
    change_name: function(ev) {
        this.controller.addChange('name', $(ev.target).val());
    },
    change_comments: function(ev) {
        this.controller.addChange('comments', $(ev.target).val());
    },
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
        this.controller.addChange('owner', {
            id: id,
            name: item && item.name
        });
    },
    changeTags: function(items) {
        var ids = _.map(items, function(item) {
            return {id: item.id};
        });

        this.controller.addChange('tags', ids);
    },
    showACL: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    initTooltips: function() {

        // Header tools
        this.ui.header.find('[data-toggle=tooltip]').tooltip({
            placement: 'bottom',
            container: 'body'
        });

        // All remaining tooltips
        this.ui.tooltips.tooltip({
            container: 'body'
        });
    },
    setOrganizationLogo: function(website) {
        var self = this;

        if (website) {
            // get the domain
            var domain = website;

            // remove protocol
            domain = domain.replace(/(^\w+:|^)\/\//, '');

            // remove www
            var str = 'www.';
            var idx = domain.indexOf(str);

            if (idx === 0) {
                domain = domain.substring(idx + str.length);
            }

            // remove path and params
            _.each(['/','?'], function(s) {
                idx = domain.indexOf(s);

                if (idx !== -1) {
                    domain = domain.substring(0, idx);
                }
            });

            this.ui.display_logo.load(function() {
                self.ui.display_image_mask.addClass('has-photo');
            }).attr('src', 'https://logo.clearbit.com/' + domain + '?size=100');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default OrganizationView;

import BaseModel from 'js/models/base'


var Activity = BaseModel.extend({
    name: 'Activity',
    urlRoot: '/activities',  // impossible to access at root
    apiType: 'salesseek.contacts.models.activity.Activity',
    toString: function() {
        return this.get('note');
    }
});
Activity.fromString = function (string) {
    return new Activity({note: string});
};
export default Activity;

import _ from 'underscore'

import BaseModel from 'js/models/base'


var Bucket = BaseModel.extend({
    name: 'Bucket',
    urlRoot: '/buckets',  // impossible to access at root
    apiType: 'salesseek.opportunities.models.bucket.Bucket',
    toString: function() {
        return this.get('name');
    },
    validate: function(attrs) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name_undefined = 'Name is required';
        } else if (attrs.name.length === 0) {
            errors.name_too_short = 'One character minimum required';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});
Bucket.fromString = function (string) {
    return new Bucket({name: string});
};
export default Bucket;

import $ from 'jquery'
import Backbone from 'backbone'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import app from 'js/app'
import vent from 'js/vent'
import api from 'js/api'
import template from 'templates/2f_auth.handlebars'
import UserModel from 'js/models/user'


var TwoFactorAuthView = BaseView.extend({
    tagName: 'article',
    id: '2f-auth-container',
    className: 'hide',
    template: Handlebars.compile(template),
    ui: {
        failed: '#failed',
        errorMessage: '#failed #error-message',
        pin: '#pin',
        pinRequestedMessage: '.pin-requested-message'
    },
    events: {
        'click #verify-pin': 'verifyPin',
        'click .cancel': 'cancel'
    },
    onRender: function() {
        var self = this;

        $.post('/request_otp', '')
            .done(function(response) {
                if (response.otp_method === 'email') {
                    self.ui.pinRequestedMessage.text('Your PIN has been sent to your registered email address');
                } else {
                    self.ui.pinRequestedMessage.text('Your PIN has been sent to your phone');
                }
            })
            .fail(function(xhr) {
                self.setErrorMessage(JSON.parse(xhr.responseText));
            });
    },
    onShow() {
        this.$el.fadeIn(500);
    },
    verifyPin: function(ev) {
        ev.preventDefault();

        this.ui.failed.fadeOut(300);

        var self = this;
        $.post('/verify_otp', JSON.stringify({ value: this.ui.pin.val() }))
            .done(function(user) {
                app.user = new UserModel(user);
                vent.trigger('login:success', self.options.next);
            })
            .fail(function(xhr) {
                self.setErrorMessage(JSON.parse(xhr.responseText));
            });
    },
    setErrorMessage: function(error) {
        let exception;
        try {
            exception = error.detail.exception;
        } catch (e) {
            console.log(e);
        }

        if (exception === 'InvalidOTPValueError') {
            this.ui.errorMessage.text('The PIN code is invalid');
        } else if (exception === 'OTPLockedOutError') {
            this.ui.errorMessage.text('Sorry, but you have made too many unsuccessful verification attempts. For security reasons, you will not be able to login for 1 hour.');
        } else {
            this.ui.errorMessage.text('Unable to verify PIN code');
        }
        this.ui.failed.fadeIn(300);
    },
    cancel: function(ev) {
        ev.preventDefault();
        vent.trigger('logout');
    },
    getUrl: function () {
        return '/2fauth';
    }
});
export default TwoFactorAuthView;

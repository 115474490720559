import _ from 'underscore'
import app from 'js/app'
import Currency from 'js/utils/currency'
import dateFormat from 'js/utils/date-format'
import pdcrm from 'src/app/custom-app-config/pdcrm'
import security from 'js/utils/security'
import { ExtendedIndividualSelectItem } from 'js/react_views/widgets/select';
import { ExtendedIndividualItem } from 'js/react_views/search/search';
import TextManager from 'app/text-manager';
import MessageBox from 'js/views/message_box';

var uiTabIsEnabled = function(tab) {
    var uiOptions = (app.user.get('preferences') || {}).ui_visibility_options;
    var tag = `${tab}_tab`;

    if (uiOptions && (tag in uiOptions)) {
        return uiOptions[tag];
    }

    return true;
}

var buildConversationId = function(participants) {
    return participants.sort().join('_');
}

// App config based in the server environment and client id
var configInfo = {
    salesseek: {
        default: {
            appTheme: 'salesseekTheme',
            intercomId: 'g9q9c0u7',
            use24hClock: true,
            chromeExtensionUrl: 'https://chrome.google.com/webstore/detail/salesseek-for-chrome/gnhoimmdfeclleccljjflgngepoicidi',
            showDealIdOnBubble: true,
            takeMeHomeUrl: 'http://www.salesseek.net',
            disableTextsSection: true,
            enableContactEmailTemplating: function() {
                return app.user.get('client').feature_tier !== 'starting';
            },
            useDDMMYYYYDateFormat: true,
            disableMarketingSection: true,
            showTagsOnWebLeadForm: true,
            hasInstagramSocial: true,
            enableTextMessaging: function() {
                return !!app?.globalData?.smsInfo;
            },
            customColorKeys: function() {
                const clientPreferences = app.user.get('client').preferences;
                return (clientPreferences && clientPreferences.custom_colorkeys);
            },
            entitiesTableViewShowBulkDeleteButton: function() {
                return true;
            },
            activity: {
                bold_author_name: true,
                bold_target_name: false
            },
            logged_user: {
                is_campaigns_only: function() {
                    var userPrefs = app.user.get('preferences') || {};
                    return userPrefs.is_campaigns_only_user;
                }
            },
            quick_message: {
                check_unsubscription: true
            },
            app_nav_items: {
                search: {
                    visible: function() {
                        return uiTabIsEnabled('search');
                    }
                },
                create_new: {
                    visible: function() {
                        return uiTabIsEnabled('create_new');
                    }
                },
                dashboard: {
                    visible: function() {
                        return uiTabIsEnabled('dashboard');
                    }
                },
                tasks: {
                    visible: function() {
                        return uiTabIsEnabled('tasks');
                    }
                },
                activities: {
                    visible: function() {
                        return uiTabIsEnabled('activities');
                    }
                },
                contacts: {
                    visible: function() {
                        return uiTabIsEnabled('contacts');
                    }
                },
                deals: {
                    visible: function() {
                        return uiTabIsEnabled('deals');
                    }
                },
                messages: {
                    visible: function() {
                        return false;
                    }
                },
                marketing: {
                    visible: function() {
                        return uiTabIsEnabled('marketing');
                    }
                },
                campaigns: {
                    visible: function() {
                        return uiTabIsEnabled('campaigns');
                    }
                },
                automations: {
                    visible: function() {
                        return uiTabIsEnabled('automations');
                    }
                },
                content: {
                    visible: function() {
                        return uiTabIsEnabled('content');
                    }
                },
                texts: {
                    visible: function() {
                        return uiTabIsEnabled('texts');
                    }
                },
                forecasts: {
                    visible: function() {
                        return uiTabIsEnabled('forecasts');
                    }
                },
                social: {
                    visible: function() {
                        return uiTabIsEnabled('social');
                    }
                },
                web: {
                    visible: function() {
                        return uiTabIsEnabled('web');
                    }
                },
                help: {
                    visible: function() {
                        return uiTabIsEnabled('help');
                    }
                },
                appointments: {
                    visible: function() {
                        return false;
                    }
                },
                reports: {
                    visible: function() {
                        return false;
                    }
                },
                deals_section: {
                    visible: function(appConfig, id) {
                        return uiTabIsEnabled(id);
                    }
                },
                portal: {
                    visible: function(appConfig, id) {
                        return false;
                    }
                }
            },
            individuals: {
                social_tab: {
                    visible: true
                },
                overview: {
                    show_tags: true
                },
                edit: {
                    show_tags: true
                },
                groups: {
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                },
            },
            organizations: {
                groups: {
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                },
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.entityInformationFormat(date);
                    }

                    return 'Unknown';
                },
                groups: {
                    recently_viewed: {
                        visible: false
                    },
                },
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.phase_type === 'won';
                }
            },
            custom_fields: {
                overview: {
                    show_false_checkboxes: true // if the checkbox has the value 'false', it is show anyway
                }
            },
            appointments: {
                types_list: [{
                    id: 'viewing',
                    title: 'Viewing'
                }, {
                    id: 'reservation',
                    title: 'Reservation'
                }, {
                    id: 'ft',
                    title: 'FT Appointment'
                }, {
                    id: 'demo',
                    title: 'New Home Demo'
                }, {
                    id: 'meeting',
                    title: 'Meeting'
                }, {
                    id: 'green_blue_card',
                    title: 'Green & Blue Card Appointment'
                }, {
                    id: 'cancellation',
                    title: 'Cancellation Meeting'
                }, {
                    id: 'px_home_change',
                    title: 'PX/Home Change Appointment'
                }]
            },
            quick_add_task: {
                types_list: [{
                    id: 'email_followup',
                    title: 'Email follow-up'
                }, {
                    id: 'call_followup',
                    title: 'Call follow-up',
                }, {
                    id: 'second_followup',
                    title: 'Second follow-up',
                }, {
                    id: 'inactive',
                    title: 'Inactive',
                }, {
                    id: 'weekly_followup',
                    title: 'Weekly follow-up'
                }, {
                    id: 'viewing_followup',
                    title: 'Viewing follow-up',
                }]
            },
            permission_defaults: {
                deals: {
                    enable_inherit_from_funnel: true
                }
            }
        },
        resdiary: {
            disableArchiveBCCLink: true,
            custom_fields: {
                overview: {
                    show_false_checkboxes: false
                }
            },
            deals: {
                expected_close_date_default : function() {
                    var date = new Date();
                    date.setDate(date.getDate() + 90);
                    return date;
                },
                not_allowed_to_delete: function() {
                    const allowed_users = [
                        'f81a6a22-66fa-4e15-9fde-ee0e4a41fd1d', // Claire Anderson
                        '6c0e175c-9b63-40c1-947d-f5b9ced94f11', // Kathryn Breeze
                        'ee704808-ce36-4797-9fa6-f6fbfb80f2d5', // Laura Ewan
                        '6c717b2f-0b9b-4bba-804a-ba1b082bdd6e', // Stefan Overzier
                        'abc580ba-4dce-4374-9590-f4fef6f44f3b'  // SalesSeek Support
                    ];

                    if (allowed_users.includes(app.user.get('id'))) return false;

                    return true;
                }
            }
        },
        languagelink: {
            send_pdf_generation_email: false,
            serviceRequiredCfId: 'b80c629c-8198-4936-b2c1-bc1b8ffd4680',
            dealsListingExtraColumns: ['custom_fields.b80c629c-8198-4936-b2c1-bc1b8ffd4680'],
            individuals: {
                duplicate: {
                    visible: true
                }
            },
            deals: {
                duplicate: {
                    visible: true
                }
            }
        },
        strengthspartnership: {
            enableDownloadFinancialReportOption: function() {
                return app.user.get('is_admin')
            },
            socialMediaLeadsRules: function() {
                return [
                    [{ field: 'individual_source', operator: 'empty', not: true }],
                    [{ field: 'individual_source', operator: 'equal', not: true, values: { id: 'ebda9ba4-2fda-42ff-87f7-b1c1ceb84158', name: 'Social Media' }}],
                    [{ field: 'individual_tags', operator: 'equal', not: true, values: { id: '300873e3-9825-46e3-a166-18982b933ae2', name: 'Social Media' }}]
                ];
            },
            disableDealSecondInfoSection: function(appConfig, dealSection) {
                // the fields in 'second info section' are: currency, value, funnel, phase, close, status, weight
                if (dealSection) {
                    var preferences = app.user.get('client').preferences || {};
                    if (preferences.deals_sections) {
                        var section = JSON.parse(preferences.deals_sections).find(s => s.id === dealSection.id);
                        return section && section.hide_system_fields;
                    }
                }

                return false;
            },
            funnelPopup: 'extended',
            excludeSocialLeads: true,
            disableDealElephants: true,
            disableDealIndividualsTab: function(appConfig, dealSection) {
                // for strenghtspartnership we check the same conditions for disableDealSecondInfoSection and disableDealIndividualsTab
                return appConfig.getValue('disableDealSecondInfoSection', false, dealSection);
            },
            deals: {
                organization_individuals: {
                    visible: function(appConfig, dealType) {
                        if(dealType){
                            return dealType.toLowerCase() !== 'event'
                        }

                        return true
                    }
                }
            }
        },
        pdcrm: {
            useZendesk: true,
            contacts: {
                stakeholders: {
                    visible: true
                }
            },
            activity_filter: {
                secondary: {
                    individuals: {
                        force_record_view: true // every time the activity feed is show, the option "This Ind/Org/Deal" is active
                    },
                    organizations: {
                        force_record_view: true
                    }
                }
            },
            individuals: {
                social_tab: {
                    visible: false
                },
                edit: {
                    checkIfCustomFieldInactiveSwitchedOn: true
                },
                overview: {
                    related_deals: {
                        add_button: {
                            visible: false
                        }
                    }
                },
                new_select_widget: {
                    item_component: function() {
                        return ExtendedIndividualSelectItem
                    },
                    additional_fields: function() {
                        let additionFields = [];
                        const customFields = app.globalData.customFieldsInfo.individuals;

                        for (const cfId in customFields) {
                            if (customFields[cfId].name.toLowerCase() === 'postcode') {
                                additionFields.push(`custom_field.${cfId}`);
                                break;
                            }
                        }

                        return additionFields;
                    }
                },
            },
            deals: {
                organization_individuals: {
                    custom_fetch: function(appConfig, params) {
                        pdcrm.deals.organization_individuals.custom_fetch(params.deal, params.callback);
                    }
                },
            },
            is_pdcrm: true,
            funnel: {
                colorkey_widget: {
                    fixed_value: 'custom_colorkey_phase.name'
                },
                display_bubble: {
                    show_unit_no: true
                }
            },
            funnel_map: {
                is_value_highlighted: function(appConfig, deal) {
                    return deal.get('phase_number') >= 4;
                }
            },
            search: {
                individuals: {
                    item_component: function() {
                        return ExtendedIndividualItem;
                    },
                    additional_fields: function() {
                        let additionFields = [];
                        const customFields = app.globalData.customFieldsInfo.individuals;

                        for (const cfId in customFields) {
                            if (customFields[cfId].name.toLowerCase() === 'postcode') {
                                additionFields.push(`custom_field.${cfId}`);
                                break;
                            }
                        }

                        return additionFields;
                    }
                }
            },
            dashboard: {
                leads: {
                    show_tags: true
                }
            },
            quick_add_task: {
                subject_suggestions: ['Email follow up', 'Call follow up', 'Second follow up', 'Inactive', 'Viewing confirmation', 'Viewing cancellation', 'Weekly follow up']
            },
            campaigns: {
                add_vib_link: true
            },
            permission_defaults: {
                deals: {
                    inherit_from_funnel_title: 'Inherit from Development'
                }
            }
        },
        hillgroup: {
            _inherit_from: 'pdcrm',
            appTheme: 'hillgroupTheme',
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableOrganizationOnDeals: true,
            disableBubbleOnDeals: true,
            disableStatusOnDeals: true,
            disableValueOnDeals: true,
            showYKDealButton: true,
            checkFunnelsPermissions: true,
            useLargeFontOnDealBuckets: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            },
            individualsViewAddDealFilterByPhaseName: function() {
                return 'Available Plots';
            },
            dashboard: {
                sales_manager: {
                    use_hillgroup_dashboard: true
                },
            },
            funnel: {
                colorkey_widget: {
                    extended: true, // this means: selector narrow and legend container wider
                    edit_funnels: {
                        visible: false
                    }
                },
                display_bubble: {
                    check_achieved_price_and_asking_price: true
                },
                phases:{
                    deals_total_value: {
                        visible: false
                    }
                },
                funnels_list: {
                    won: {
                        visible: false
                    },
                    lost: {
                        visible: false
                    }
                }
            },
            app_nav: {
                create_new: {
                    deal: {
                        visible: false
                    }
                }
            },
            deals: {
                custom_order_by: {
                    field: 'Unit NO.',
                    dir: 'asc',
                    is_custom_field: true
                },
                groups: {
                    display_value: false
                }
            },
            individuals: {
                overview: {
                    organization_opportunities_tab: {
                        visible: false,
                    },
                    individual_opportunities_tab: {
                        visible: false
                    },
                    communications: {
                        all_email_button:{
                            visible: true
                        }
                    },
                    related_deals: {
                        filter: function(appConfig, deals) {
                            return deals.filter(deal => deal.phase.phase_type !== 'lost' && deal.phase.phase_type !== 'won' && deal.funnel.name.toLowerCase() !== 'reservations');
                        },
                        value: {
                            visible: false
                        }
                    }
                },
                customValidation: function(appConfig, data) {
                    const attrs = data.attrs;
                    const customFields = app.globalData.customFieldsInfo.individuals;
                    var errors = {};
                    var missing_email = true;
                    var missing_phone = true;

                    if (_.isUndefined(attrs.first_name) || _.isNull(attrs.first_name)) {
                        errors.first_name = 'First name required';
                    } else if (attrs.first_name.length === 0) {
                        errors.first_name = 'One character minimum required';
                    }

                    if ( !_.isUndefined(attrs.communication) && attrs.communication.length > 0) {
                        attrs.communication.forEach(comm => {
                            if (comm.medium === 'phone' && ( !_.isUndefined(comm.value) || !_.isNull(comm.value) ) ) {
                                missing_phone = false;
                            }

                            if (comm.medium === 'email' && ( !_.isUndefined(comm.value) || !_.isNull(comm.value) ) ) {
                                missing_email = false;
                            }
                        });
                    }

                    for (const cfId in customFields) {
                        if (customFields[cfId].name.toLowerCase() === 'max price') {
                            var id = `custom_field.${cfId}`;
                            var value = null;

                            if (id in attrs) {
                                value = attrs[id]
                            }

                            if (_.isNull(value) || _.isUndefined(value) || (value === '')) {
                                errors[cfId] = customFields[cfId].name + ' is required';
                            }

                            break;
                        }
                    }

                    if (missing_email) {
                        errors.communication_emails = 'Email is required';
                    }
                    if (missing_phone) {
                        errors.communication_phones = 'Phone is required';
                    }

                    return errors;
                },
                edit: {
                    create_activity_note_fields: [{field: 'comments'}]
                },
                groups: {
                    order_by: {
                        field: 'name',
                        dir: 'asc'
                    }
                }
            },
            organizations: {
                related_deals: {
                    new_button: {
                        visible: false
                    }
                }
            },
            activity: {
                bold_author_name: false,
                bold_target_name: true,
            },
            group_pages: {
                round_numeric_custom_field_columns: true,
                individuals: {
                    rows: 100,
                    default_columns: ['created', 'user_created', 'tags', 'source.name', 'first_name', 'last_name', 'email', 'phone', 'comments'],
                    default_sort: {field: ['user_created'], direction: false}
                },
                deals: {
                    quota_widget: {
                        visible: false
                    }
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.eeeaaccc-1111-aaaa-1111-11111111aa09']; // custom field "by telephone"
                    }
                }
            },
            search: {
                show_organizations: false,
                show_email_and_phone_info: true,
                show_create_individual_button: true
            },
            on_task_completation: {
                show_activity_note_popup: true,
                show_task_popup: true
            },
            on_phone_call: {
                show_activity_note_popup: true
            },
            on_activity_note_created: {
                show_task_popup: true
            },
            campaigns: {
                draft_list_order: ['name', true],
                enable_import_zip_files: true,
                add_vib_link: true // view in browser link
            },
            leads: {
                created_date: {
                    visible: true
                }
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                phase_visible_in_list: function(appConfig, phaseName) {
                    return ['reservation submitted', 'tentative'].indexOf(phaseName.toLowerCase()) === -1;
                },
                map_phase: function(appConfig, phaseName) {
                  if (['reservation submitted', 'tentative'].indexOf(phaseName.toLowerCase()) !== -1) {
                    return 'Pending';
                  }

                  return phaseName;
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                title: 'Smartie Report',
                filter: {
                    field:  'opportunity_custom',
                    custom: '3f120663-fb5e-486e-8630-9be5c40fc6e3'
                },
                extra_columns: ['custom_fields.3f120663-fb5e-486e-8630-9be5c40fc6e3'],
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function(data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.6135d57e-8885-45d8-a7bc-e3769337560f'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.a520c788-f66d-4e6b-b116-5c9239397935'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            }
        },
        hillplay: {
            _inherit_from: 'hillgroup'
        },
        hilldemo: {
            _inherit_from: 'hillgroup',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.a2c41b49-15e0-4189-aed8-1a2772d2ab27']; // custom field "by telephone"
                    }
                }
            }
        },
        xchange: {
            _inherit_from: 'pdcrm',
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableOrganizationOnDeals: false,
            disableBubbleOnDeals: true,
            disableStatusOnDeals: true,
            disableValueOnDeals: true,
            useLargeFontOnDealBuckets: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            },
            individualsViewAddDealFilterByPhaseName: function() {
                return 'Available';
            },
            funnel: {
                colorkey_widget: {
                    extended: true, // this means: selector narrow and legend container wider
                    edit_funnels: {
                        visible: false
                    }
                },
                display_bubble: {
                    check_achieved_price_and_asking_price: true
                },
                phases:{
                    deals_total_value: {
                        visible: false
                    }
                },
                funnels_list: {
                    won: {
                        visible: false
                    },
                    lost: {
                        visible: false
                    }
                }
            },
            app_nav: {
                create_new: {
                    organization: {
                        visible: false
                    },
                    campaign: {
                        visible: false
                    },
                    group: {
                        visible: false
                    }
                }
            },
            individuals: {
                overview: {
                    organization_opportunities_tab: {
                        visible: false,
                    },
                    individual_opportunities_tab: {
                        visible: false
                    },
                    communications: {
                        all_email_button:{
                            visible: true
                        }
                    },
                    related_deals: {
                        filter: function(appConfig, deals) {
                            return deals.filter(deal => deal.phase.phase_type !== 'lost' && deal.phase.phase_type !== 'won' && deal.funnel.name.toLowerCase() !== 'reservations');
                        },
                        value: {
                            visible: false
                        }
                    }
                },
                customValidation: function(appConfig, data) {
                    const attrs = data.attrs;
                    const customFields = app.globalData.customFieldsInfo.individuals;
                    var errors = {};
                    var missing_email = true;
                    var missing_phone = true;

                    if (_.isUndefined(attrs.first_name) || _.isNull(attrs.first_name)) {
                        errors.first_name = 'First name required';
                    } else if (attrs.first_name.length === 0) {
                        errors.first_name = 'One character minimum required';
                    }

                    if ( !_.isUndefined(attrs.communication) && attrs.communication.length > 0) {
                        attrs.communication.forEach(comm => {
                            if (comm.medium === 'phone' && ( !_.isUndefined(comm.value) || !_.isNull(comm.value) ) ) {
                                missing_phone = false;
                            }

                            if (comm.medium === 'email' && ( !_.isUndefined(comm.value) || !_.isNull(comm.value) ) ) {
                                missing_email = false;
                            }
                        });
                    }

                    for (const cfId in customFields) {
                        if (customFields[cfId].name.toLowerCase() === 'max price') {
                            var id = `custom_field.${cfId}`;
                            var value = null;

                            if (id in attrs) {
                                value = attrs[id]
                            }

                            if (_.isNull(value) || _.isUndefined(value) || (value === '')) {
                                errors[cfId] = customFields[cfId].name + ' is required';
                            }

                            break;
                        }
                    }

                    if (missing_email) {
                        errors.communication_emails = 'Email is required';
                    }
                    if (missing_phone) {
                        errors.communication_phones = 'Phone is required';
                    }

                    return errors;
                },
                edit: {
                    create_activity_note_fields: [{field: 'comments'}]
                },
                groups: {
                    order_by: {
                        field: 'name',
                        dir: 'asc'
                    }
                }
            },
            organizations: {
                related_deals: {
                    new_button: {
                        visible: false
                    }
                }
            },
            activity: {
                bold_author_name: false,
                bold_target_name: true,
            },
            group_pages: {
                round_numeric_custom_field_columns: true,
                individuals: {
                    rows: 100,
                    default_columns: ['created', 'user_created', 'tags', 'source.name', 'first_name', 'last_name', 'email', 'phone', 'comments'],
                    default_sort: {field: ['user_created'], direction: false}
                },
                deals: {
                    quota_widget: {
                        visible: false
                    }
                }
            },
            search: {
                show_organizations: false,
                show_email_and_phone_info: true,
                show_create_individual_button: true
            },
            on_task_completation: {
                show_activity_note_popup: true,
                show_task_popup: true
            },
            on_phone_call: {
                show_activity_note_popup: true
            },
            on_activity_note_created: {
                show_task_popup: true
            },
            campaigns: {
                draft_list_order: ['name', true],
                enable_import_zip_files: true,
                add_vib_link: true // view in browser link
            },
            leads: {
                created_date: {
                    visible: true
                }
            }
        },
        yourkeysdev: {
            _inherit_from: 'pdcrm',
            showYKDealButton: true,
            enableTextMessaging: true,
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableOrganizationOnDeals: true,
            disableBubbleOnDeals: true,
            disableStatusOnDeals: true,
            disableValueOnDeals: true,
            checkFunnelsPermissions: true,
            useLargeFontOnDealBuckets: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            },
            individualsViewAddDealFilterByPhaseName: function() {
                return 'Available Plots';
            },
            dashboard: {
                use_optimised_funnel_query: true,
                sales_manager: {
                    use_hillgroup_dashboard: true
                },
            },
            funnel: {
                colorkey_widget: {
                    extended: true, // this means: selector narrow and legend container wider
                    edit_funnels: {
                        visible: false
                    }
                },
                display_bubble: {
                    check_achieved_price_and_asking_price: true
                },
                phases:{
                    deals_total_value: {
                        visible: false
                    }
                },
                funnels_list: {
                    won: {
                        visible: false
                    },
                    lost: {
                        visible: false
                    }
                }
            },
            app_nav: {
                create_new: {
                    deal: {
                        visible: false
                    }
                }
            },
            deals: {
                new_button: {
                    visible: false
                },
                custom_order_by: {
                    field: 'Unit NO.',
                    dir: 'asc',
                    is_custom_field: true
                },
                groups: {
                    display_value: false
                }
            },
            individuals: {
                overview: {
                    organization_opportunities_tab: {
                        visible: false,
                    },
                    individual_opportunities_tab: {
                        visible: false
                    },
                    job_role: {
                        visible: false
                    },
                    organization: {
                        visible: false
                    },
                    communications: {
                        all_email_button:{
                            visible: true
                        }
                    },
                    related_deals: {
                        value: {
                            visible: false
                        }
                    },
                    social_links: {
                        visible: false
                    },
                    locations: {
                        visible: false
                    }
                },
                groups: {
                    order_by: {
                        field: 'name',
                        dir: 'asc'
                    }
                }
            },
            organizations: {
                related_deals: {
                    new_button: {
                        visible: false
                    }
                }
            },
            activity: {
                bold_author_name: false,
                bold_target_name: true,
            },
            group_pages: {
                individuals: {
                    rows: 100,
                    default_columns: ['created', 'user_created', 'tags', 'source.name', 'first_name', 'last_name', 'email', 'phone', 'comments'],
                    default_sort: {field: ['user_created'], direction: false}
                },
                deals: {
                    quota_widget: {
                        visible: false
                    }
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.5280bd3b-06a2-433a-8526-b63eb1b7b536']; // custom field "by telephone"
                    }
                }
            },
            search: {
                show_organizations: false,
                show_email_and_phone_info: true
            },
            on_task_completation: {
                show_activity_note_popup: true
            },
            on_phone_call: {
                show_activity_note_popup: true
            },
            on_activity_note_created: {
                show_task_popup: true
            },
            campaigns: {
                draft_list_order: ['name', true],
                enable_import_zip_files: true
            }
        },
        yourkeysdevb2b: {
            is_pdcrm: false,
            showYKDealButton: false,
            enableTextMessaging: true,
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: false,
            disableOrganizationOnDeals: false,
            disableBubbleOnDeals: false,
            disableStatusOnDeals: true,
            disableValueOnDeals: false,
            disableCloseDateOnDeals: false,
            checkFunnelsPermissions: true,
            useLargeFontOnDealBuckets: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            },
            leads: {
                created_date: {
                    visible: true
                }
            },
            contacts: {
                stakeholders: {
                    visible: false
                }
            },
            activity_filter: {
                secondary: {
                    individuals: {
                        force_record_view: true // every time the activity feed is show, the option "This Ind/Org/Deal" is active
                    },
                    organizations: {
                        force_record_view: true
                    }
                }
            },
            individuals: {
                social_tab: {
                    visible: false
                },
                edit: {
                    checkIfCustomFieldInactiveSwitchedOn: false
                },
                overview: {
                    organization_opportunities_tab: {
                        visible: true,
                    },
                    individual_opportunities_tab: {
                        visible: true
                    },
                    job_role: {
                        visible: true
                    },
                    organization: {
                        visible: true
                    },
                    communications: {
                        all_email_button: {
                            visible: false
                        }
                    },
                    related_deals: {
                        value: {
                            visible: true
                        }
                    },
                    social_links: {
                        visible: false
                    },
                    locations: {
                        visible: false
                    }
                },
                groups: {
                    order_by: {
                        field: 'name',
                        dir: 'asc'
                    },
                }
            },
            search: {
                show_organizations: true,
                show_email_and_phone_info: true,
                individuals: {
                    show_tags: true
                }
            },
            dashboard: {
                use_optimised_funnel_query: true,
                leads: {
                    show_tags: true
                }
            },
            quick_add_task: {
                subject_suggestions: ['Email follow up', 'Call follow up', 'Second follow up', 'Inactive', 'Viewing confirmation', 'Viewing cancellation', 'Weekly follow up']
            },
            campaigns: {
                draft_list_order: ['name', true],
                enable_import_zip_files: true,
                add_vib_link: true
            },
            funnel: {
                colorkey_widget: {
                    extended: true, // this means: selector narrow and legend container wider
                        edit_funnels: {
                        visible: false
                    }
                },
                phases: {
                    deals_total_value: {
                        visible: true
                    }
                },
                funnels_list: {
                    won: {
                        visible: true
                    },
                    lost: {
                        visible: true
                    }
                }
            },
            app_nav: {
                create_new: {
                    deal: {
                        visible: true
                    }
                }
            },
            deals: {
                new_button: {
                    visible: true
                },
            },
            organizations: {
                related_deals: {
                    new_button: {
                        visible: true
                    }
                },
            },
            activity: {
                bold_author_name: false,
                bold_target_name: true,
            },
            group_pages: {
                individuals: {
                    rows: 100,
                    default_columns: ['created', 'tags', 'source.name', 'first_name', 'last_name', 'email', 'phone', 'comments'],
                    default_sort: { field: ['created'], direction: false }
                },
                deals: {
                    quota_widget: {
                        visible: false
                    }
                }
            },
            communications: {
                email: {
                    label: 'Business'
                }
            },
            on_task_completation: {
                show_activity_note_popup: true
            },
            on_phone_call: {
                show_activity_note_popup: true
            },
            on_activity_note_created: {
                show_task_popup: true
            }
        },
        ssdemo: {
            _inherit_from: 'yourkeysdev',
    
            leads: {
                created_date: {
                    visible: true
                }
            },
    
            individualUnsubscribedAllDefaultValue: true,
            individualUnsubscribedAllMessagesDefaultValue: true,
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableCloseDateOnDeals: true,
            disableOrganizationOnDeals: true,
    
            app_nav: {
                create_new: {
                    appointment: {
                        visible: true
                    },
                    report: {
                        visible: true
                    }
                }
            },
    
            app_nav_items: {
                appointments: {
                    visible: true
                },
                reports: {
                    visible: true
                }
            },
    
            individuals: {
                enable_funnel_field: true,
                preloaded_tags_field: false,
                preloaded_funnels_field: true,
    
                overview: {
                    related_deals: {
                        filter: function(appConfig, deals) {
                            return deals.filter(deal =>
                                deal.phase.phase_type !== 'lost' &&
                                deal.phase.phase_type !== 'won' &&
                                deal.funnel.name.toLowerCase() !== 'reservations');
                        }
                    }
                },
    
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true,
                    favorites: {
                        visible: false
                    },
                    leads: {
                        visible: false
                    },
                    my_individuals: {
                        visible: false
                    },
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                    order_by: false
                },
    
                duplicate: {
                    visible: function() {
                        return app.user.get('is_helper');
                    }
                },
    
                edit: {
                    source: {
                        disabled_with_value: true
                    }
                },
    
                activities: {
                    include_org_related: false
                }
            },
    
            organizations: {
                enable_funnel_field: true,
    
                groups: {
                    show_my_groups: true,
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                    my_organizations: {
                        visible: false
                    }
                }
            },
    
            tasks: {
                enable_funnel_field: true,
                preloaded_tags_field: false,
                preloaded_funnels_field: true,
    
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_regions_adhoc_groups: true,
                    show_my_groups: true,
                    this_week: {
                        visible: false
                    },
                    today: {
                        visible: false
                    },
                    tasks_by_owner: {
                        visible: false
                    }
                }
            },
    
            activities: {
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true
                }
            },
    
            deals: {
                groups: {
                    open_in_table_view: true,
                    show_system_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true
                },
    
                edit: {
                    funnel: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    },
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
    
                overview: {
                    funnel: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    },
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
    
                activities: {
                    include_org_related: false
                }
            },
    
            appointments: {
                show_funnels_tags_adhoc_groups: false,
                show_funnels_adhoc_groups: true,
                show_system_groups: false,
                subject_suggestions: [
                    'Showroom Tour',
                    'Site Visit',
                    'Property Viewing',
                    'General Discussion',
                    'Development Update Meeting'
                ]
            },
    
            search: {
                opportunities: {
                    exclude_reservations_from_results: true
                }
            },
    
            price_lists: {
                visible: true
            }
        },
        ssdev: {
            _inherit_from: 'yourkeysdev'
        },
        ssqa: {
            _inherit_from: 'yourkeysdev',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.2b566ac7-d195-4f38-86c6-42c7771bc3f9']; // custom field "by telephone"
                    }
                }
            }
        },
        ykstaging: {
            _inherit_from: 'yourkeysdev',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.167c15c0-d190-4b83-bae2-8b860e664246']; // custom field "by telephone"
                    }
                }
            }
        },
        ashgrove: {
            _inherit_from: 'yourkeysdev'
        },
        llo: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            }
        },
        landg: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                filter: {
                    field:  'opportunity_custom',
                    custom: '44be4ad2-5cd0-4b1b-8af2-8b13e6fcf440'
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                extra_columns: ['custom_fields.44be4ad2-5cd0-4b1b-8af2-8b13e6fcf440'],
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function(data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.b64faf25-a421-4930-bf5c-a6d0de56d6f2'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.cc3d0d47-f9a7-40de-9ea6-23ae58cf1aa8'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            }
        },
        landgdemo: {
            _inherit_from: 'yourkeysdev',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.e86f4a21-0bf2-4613-bd90-df54844a7735']; // custom field "by telephone"
                    }
                }
            }
        },
        davidsons: {
            _inherit_from: 'yourkeysdev',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.c8ece8ae-f8bf-4e4c-84c0-c153c3982974']; // custom field "by telephone"
                    }
                }
            }
        },
        thislanddemo: {
            _inherit_from: 'yourkeysdev',
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.428d2d66-428d-4530-b217-adeda736c8ec']; // custom field "by telephone"
                    }
                }
            }
        },
        thisland: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                phone: {
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.f07a43f5-b061-410a-a933-bc1c92d36758']; // custom field "by telephone"
                    }
                }
            }
        },
        acorngroup: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                filter: {
                    field:  'opportunity_custom',
                    custom: 'b1e4f6c4-2bf8-45fb-a08d-f9b6f432ef4c'
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                extra_columns: ['custom_fields.b1e4f6c4-2bf8-45fb-a08d-f9b6f432ef4c'],
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.11e11a3c-95a5-4753-b2c3-5db86ed032df']; // custom field "by telephone"
                    }
                }
            }
        },
        acorndemo: {
            _inherit_from: 'acorngroup',
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                filter: {
                    field:  'opportunity_custom',
                    custom: '58c19062-e1b2-4f41-a082-53dc580436b0'
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                extra_columns: ['custom_fields.58c19062-e1b2-4f41-a082-53dc580436b0'],
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.b5459332-ff51-4f6d-8ead-eb77a88c7fb3']; // custom field "by telephone"
                    }
                }
            }
        },
        londonsquare: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                filter: {
                    field:  'opportunity_custom',
                    custom: 'a9cb9cb8-ce07-45f4-9e34-cb065f9deff4'
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                extra_columns: ['custom_fields.a9cb9cb8-ce07-45f4-9e34-cb065f9deff4'],
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.19f2b4bf-c750-4b3f-ba68-cc036c536c70']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function(data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.2b55e825-b27f-43b7-99d5-03641c4f6ee6'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.85f884c6-255a-41fe-93c3-9fb28640f04d'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                overview: {
                    organization_opportunities_tab: {
                        visible: false,
                    },
                    individual_opportunities_tab: {
                        visible: true
                    },
                    job_role: {
                        visible: false
                    },
                    organization: {
                        visible: false
                    },
                    communications: {
                        all_email_button:{
                            visible: true
                        }
                    },
                    related_deals: {
                        add_button: {
                            visible: true
                        },
                        value: {
                            visible: true
                        }
                    },
                    social_links: {
                        visible: false
                    },
                    locations: {
                        visible: false
                    }
                },
                groups: {
                    order_by: {
                        field: 'name',
                        dir: 'asc'
                    }
                },
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            },
            on_activity_note_created: {
                task_popup: {
                    individuals: {
                        cancel_button_text: 'Inactive',
                        show_inactive_reasons_popup: true,
                        cancel_action: function(appConfig, model) {
                            let inactiveCFId = null;
                            const customFields = app.globalData.customFieldsInfo.individuals;

                            for (const cfId in customFields) {
                                if ((cfId in customFields) && (customFields[cfId].name.toLowerCase() === 'inactive')) {
                                    inactiveCFId = cfId;
                                    break;
                                }
                            }

                            if (inactiveCFId) {
                                const cf = `custom_field.${inactiveCFId}`;

                                // set it to true (if it is not already set to true)
                                if (!model.get(cf)) {
                                    model.save({[cf]: true}, {
                                        patch: true,
                                        wait: true
                                    });
                                }
                            }
                        }
                    }
                }
            },
            on_task_completation: {
                show_activity_note_popup: true,
                show_task_popup: true,
                activity_note_popup: {
                    individuals: {
                        cancel_button_text: 'Set as inactive',
                        show_inactive_reasons_popup: true,
                        cancel_action: function(appConfig, modelOrId) {
                            let inactiveCFId = null;
                            const customFields = app.globalData.customFieldsInfo.individuals;

                            for (const cfId in customFields) {
                                if ((cfId in customFields) && (customFields[cfId].name.toLowerCase() === 'inactive')) {
                                    inactiveCFId = cfId;
                                    break;
                                }
                            }

                            if (inactiveCFId) {
                                const cf = `custom_field.${inactiveCFId}`;

                                if (_.isObject(modelOrId)) {
                                    const model = modelOrId;

                                    // set it to true (if it is not already set to true)
                                    if (!model.get(cf)) {
                                        model.save({[cf]: true}, {
                                            patch: true,
                                            wait: true
                                        });
                                    }
                                } else { // it's the entity id
                                    const individualId = modelOrId;

                                    $.ajax({
                                        type: 'PATCH',
                                        url: `/individuals/${individualId}`,
                                        data: JSON.stringify({[cf]: true}),
                                        contentType: 'application/json',
                                        dataType: 'json'
                                    });
                                }
                            }
                        }
                    }
                }
            },
            dashboard: {
                new_flex_dashboard_enabled: true,
                is_enabled: function(appConfig, dashboardId) {
                    return dashboardId === 'team-beta';
                }
            }
        },
        lsqdemo: {
            _inherit_from: 'londonsquare',
            leads: {
                created_date: {
                    visible: true
                }
            }
        },
        pjlivesey: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.b534c363-3680-43ab-9458-03ca54014c2b']; // custom field "by telephone"
                    }
                }
            }, 
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');
    
                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.95785e28-eba1-4dfa-aad2-c493183ba150'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.6a81fcae-9dc6-4c3c-ad5b-c3807ca56d9d'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            }, 
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }             
        },
        ykcrm: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.562f3758-64e4-445e-b4d7-a853145be036']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.6cf5f224-8add-459f-bb06-3a6840fcc09b'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.010e540b-4abd-45c8-b2bc-49aa0d461865'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            }, 
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            }, 
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }    
        },
        democrm: {
            _inherit_from: 'yourkeysdev',
            disableOrganizationOnDeals: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.7938aad4-87d7-47eb-9870-1a5832baa2af']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.9b3fe48d-ec0c-4752-9772-c15924344a11'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.5835d75b-fbf4-4d66-8c81-8cad3bdd89e7'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            }, 
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            }, 
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }    
        },
        durkanhomes: {
            _inherit_from: 'yourkeysdev',
            app_nav_items: {
                messages: {
                    visible: true
                }
            },
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.6775ac4f-df11-4fcd-8a4e-78e89a743df9']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.512cc3e8-1a98-4b3f-8d00-b1341355bb78'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.121dd7ad-4c39-4947-b10a-02f8412057ef'); // area m2
    
                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            dashboard: {
                new_flex_dashboard_enabled: true,
                use_optimised_funnel_query: true,
                is_enabled: function(appConfig, dashboardId) {
                    return dashboardId === 'team-beta';
                },
                on_task_completation: {
                    show_activity_note_popup: true,
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];
    
                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }
    
                        return fields;
                    },
                    source: {
                        disabled_with_value: true
                    }
                },
                customValidation: function(appConfig, data) {
                    const attrs = data.attrs;
                    var errors = {};

                    if (data.isNew && data.isLead && !attrs.source) {
                        errors.source = 'Source required';
                    }

                    return errors;
                }
            },
            deals: {
                new_section_enabled: function(appConfig, data) {
                    return (data?.sectionId === 'issues') && (_.contains(app.user.get('preferences').lab_flags, 'SAL-5647'));
                },
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }
    
                    return 'Unknown';
                },
                aftercare: {
                    visible: true,
                    enable_for: function(appConfig, deal) {
                        if (!deal || !deal.get('funnel')) {
                            return false;
                        }

                        if (deal.get('funnel').name !== 'Aftercare') {
                            return false;
                        }

                        const cfs = app.globalData.customFieldsInfo.deals;
                        let dealTypeCfId = null;
                        let dealTypeCfOptions = null;

                        for (const cfid in cfs) {
                            if (cfs[cfid].type === 'dropDown' && cfs[cfid].name.toLowerCase() === 'deal type') {
                                dealTypeCfId = cfid;
                                dealTypeCfOptions = cfs[cfid].options;
                                break;
                            }
                        }

                        if (!dealTypeCfId) {
                            return false;
                        }

                        const value = dealTypeCfOptions[deal.get(`custom_field.${dealTypeCfId}`)];

                        if (!value) {
                            return false;
                        }

                        return value.toLowerCase() === 'issue';
                    },
                    funnel_name: 'Aftercare',
                    urgency_cf_name: 'issue urgency'
                },
                list: {
                    extra_columns: function(appConfig, section) {
                        let columns = [];

                        if (section && section.id === 'issues') {
                            const cfs = app.globalData.customFieldsInfo.dealsArray;
                            const issueTypeCf = cfs.find(f => f.name.toLowerCase() === 'issue type');
                            const propertyCf = cfs.find(f => f.name.toLowerCase() === 'property');

                            if (issueTypeCf) {
                                columns.push(`custom_fields.${issueTypeCf.id}`);
                            }

                            if (propertyCf) {
                                columns.push(`custom_fields.${propertyCf.id}`);
                            }
                        }

                        return columns;
                    }
                },
                groups: {
                    show_system_adhoc_groups: function(_, sectionId) {
                        return sectionId !== 'issues';
                    }
                },
                group_funnel_table_view: {
                    columns: function(appConfig, data) {
                        if (data.sectionId !== 'issues') {
                            return null;
                        }

                        const issueTypeCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'issue type');
                        const address1Cf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'address 1');
                        const cfColumns = data.columnsCreator.customFields('deals');

                        return [
                            data.columnsCreator.composed(['name', `custom_fields.${address1Cf?.id}`], 'Name', { greedyWidth: true, sortId: 'name', sortable: true, filterable: true, filterType: 'text', filterId: 'opportunity_name' }),
                            cfColumns.find(c => c.id === `custom_fields.${issueTypeCf?.id}`),
                            data.columnsCreator.text('phase.name', 'Phase', { filterable: true, filterId: 'opportunity_phase_id', filterType: 'phase' })
                        ];
                    }
                }
            },
            conversations: {
                visible: true,
                default_category: 'Aftercare',
                on_deal_update: function(appConfig, data) {
                    if (data.section?.id !== 'issues') {
                        return;
                    }

                    // if the constructor has been changed, we need to update the conversation's participants
                    const contractorCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'contractor');

                    if (!contractorCf) {
                        return;
                    }

                    const oldContractorId = data.old[`custom_field.${contractorCf.id}`];
                    const newContractorId = data.new[`custom_field.${contractorCf.id}`];

                    if (oldContractorId === newContractorId) {
                        return;
                    }

                    if (!oldContractorId && !newContractorId) {
                        return;
                    }

                    $.get(`/conversations?related_type=opportunity&related_id=${data.new.id}`, function(result) {
                        for (const conversation of result) {
                            if (conversation.fixed_id.indexOf('contractor') === -1) {
                                continue;
                            }

                            let oldParticipant = conversation.participants.find(p => p.participant_id === oldContractorId);

                            if (oldParticipant) {
                                $.ajax({
                                    type: 'PATCH',
                                    url: `/conversation_participants/${oldParticipant.id}`,
                                    contentType: 'application/json',
                                    dataType: 'json',
                                    data: JSON.stringify({
                                        removed_date: new Date().toISOString(),
                                        status: 'removed'
                                    })
                                });
                            }

                            if (newContractorId) {
                                $.post('/conversation_participants', JSON.stringify({
                                    conversation_id: conversation.id,
                                    participant_type: 'individual',
                                    participant_id: newContractorId,
                                    status: 'active'
                                }));
                            }
                        }
                    });
                },
                enable_for_deal: function(appConfig, params) {
                    const enabled = appConfig.getValue('deals.aftercare.enable_for', false, params.model) && appConfig.getValue('conversations.conversations_list_opportunity', [], params.model).conversations.length > 0;
                    params.callback(enabled);
                },
                enable_for_individual: function(appConfig, params) {
                    // is it a contractor?
                    const icfs = app.globalData.customFieldsInfo.individuals;
                    let contactTypeCf = null;

                    for (const cfid in icfs) {
                        const lcName = icfs[cfid].name.toLowerCase();

                        if (lcName === 'contact type' && icfs[cfid].type === 'dropDown') {
                            contactTypeCf = icfs[cfid];
                            break;
                        }
                    }

                    if (contactTypeCf) {
                        const value = params.model.get(`custom_field.${contactTypeCf.id}`);

                        if (value) {
                            let ddvalue = null;

                            for (const oid in contactTypeCf.options) {
                                if (contactTypeCf.options[oid].toLowerCase() === 'contractor') {
                                    if (oid === value) {
                                        params.callback(true);
                                        return;
                                    }
                                    break;
                                }
                            }
                        }
                    }

                    // do they have to have a deal of type plot?
                    const cfs = app.globalData.customFieldsInfo.deals;
                    let dealTypeCf = null;

                    for (const cfid in cfs) {
                        const lcName = cfs[cfid].name.toLowerCase();

                        if (lcName === 'deal type' && cfs[cfid].type === 'dropDown') {
                            dealTypeCf = cfs[cfid];
                            break;
                        }
                    }

                    if (!dealTypeCf) {
                        params.callback(false);
                        return;
                    }

                    let plotOptionId = null;

                    for (const oid in dealTypeCf.options) {
                        if (dealTypeCf.options[oid].toLowerCase() === 'plot') {
                            plotOptionId = oid;
                            break;
                        }
                    }

                    if (!plotOptionId) {
                        params.callback(false);
                    }

                    $.get(`/individuals/${params.model.get('id')}/opportunities?rows=-1`, function(deals) {
                        let enabled = false;

                        for (const deal of deals) {
                            if (deal[`custom_field.${dealTypeCf.id}`] === plotOptionId) {
                                enabled = true;
                                break;
                            }
                        }

                        params.callback(enabled);
                    });
                },
                conversations_list_individual: function(appConfig, individual) {
                    let isContractor = false;
                    const icfs = app.globalData.customFieldsInfo.individuals;
                    let contactTypeCf = null;

                    for (const cfid in icfs) {
                        const lcName = icfs[cfid].name.toLowerCase();

                        if (lcName === 'contact type' && icfs[cfid].type === 'dropDown') {
                            contactTypeCf = icfs[cfid];
                            break;
                        }
                    }

                    if (contactTypeCf) {
                        const value = individual.get(`custom_field.${contactTypeCf.id}`);

                        if (value) {
                            let ddvalue = null;

                            for (const oid in contactTypeCf.options) {
                                if (contactTypeCf.options[oid].toLowerCase() === 'contractor') {
                                    if (oid === value) {
                                        isContractor = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    return {
                        conversations: [{
                            fixedId: buildConversationId([individual.get('id'), 'crm']),
                            name: individual.get('full_name'),
                            bubbles: [{
                                icon: 'icon-user',
                                background: isContractor ? '#4589C8' : '#FF8900'
                            }],
                            participants: [{
                                id: individual.get('id'),
                                type: 'individual'
                            }]
                        }],
                        fixedConversations: true
                    };
                },
                conversations_list_opportunity: function(appConfig, deal) {
                    const cfs = app.globalData.customFieldsInfo.deals;
                    let purchaserCfId = null;
                    let contractorCfId = null;

                    for (const cfid in cfs) {
                        const lcName = cfs[cfid].name.toLowerCase();

                        if (lcName === 'primary buyer') {
                            purchaserCfId = cfid;
                        } else if (lcName === 'contractor') {
                            contractorCfId = cfid;
                        }

                        if (purchaserCfId && contractorCfId) {
                            break;
                        }
                    }

                    let homeownerId = null;
                    let contractorId = null;

                    if (purchaserCfId) {
                        homeownerId = deal.get(`custom_field.${purchaserCfId}`);
                    }

                    if (contractorCfId) {
                        contractorId = deal.get(`custom_field.${contractorCfId}`);
                    }

                    let conversations = [];

                    if (homeownerId) {
                        conversations.push({
                            fixedId: buildConversationId(['homeowner', 'crm']),
                            name: 'Homeowner',
                            bubbles: [{
                                icon: 'icon-home',
                                background: '#FF8900'
                            }],
                            participants: [{
                                id: homeownerId,
                                type: 'individual'
                            }]
                        });
                    }

                    if (contractorId) {
                        conversations.push({
                            fixedId: buildConversationId(['contractor', 'crm']),
                            name: 'Contractor',
                            bubbles: [{
                                icon: 'icon-tools',
                                background: '#4589C8'
                            }],
                            participants: [{
                                id: contractorId,
                                type: 'individual'
                            }]
                        });
                    }

                    if (homeownerId && contractorId) {
                        conversations.push({
                            fixedId: buildConversationId(['homeowner', 'contractor', 'crm']),
                            name: 'Homeowner + Contractor',
                            bubbles: [{
                                icon: 'icon-home',
                                background: '#FF8900'
                            }, {
                                icon: 'icon-tools',
                                background: '#4589C8'
                            }],
                            participants: [{
                                id: homeownerId,
                                type: 'individual'
                            }, {
                                id: contractorId,
                                type: 'individual'
                            }]
                        });
                    }

                    return {
                        conversations: conversations,
                        fixedConversations: true
                    };
                },
                participant_color_individual: function(appConfig, participant) {
                    if (participant.attributes) { // is it a model?
                        participant = participant.toJSON();
                    }

                    if (participant.type === 'users') {
                        return '#1fddca';
                    }

                    const icfs = app.globalData.customFieldsInfo.individuals;
                    let isContractor = false;
                    let contactTypeCf = null;

                    for (const cfid in icfs) {
                        const lcName = icfs[cfid].name.toLowerCase();

                        if (lcName === 'contact type' && icfs[cfid].type === 'dropDown') {
                            contactTypeCf = icfs[cfid];
                            break;
                        }
                    }

                    if (contactTypeCf) {
                        const value = participant.custom_fields[contactTypeCf.id];

                        if (value) {
                            let ddvalue = null;

                            for (const oid in contactTypeCf.options) {
                                if (contactTypeCf.options[oid].toLowerCase() === 'contractor') {
                                    if (oid === value) {
                                        isContractor = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    return isContractor ? '#4589C8' : '#FF8900';
                },
                participant_color_opportunity: function(appConfig, participant) {
                    if (participant.attributes) { // is it a model?
                        participant = participant.toJSON();
                    }

                    if (participant.type === 'users') {
                        return '#1fddca';
                    }

                    // is it a contractor?
                    const icfs = app.globalData.customFieldsInfo.individuals;
                    let contactTypeCf = null;
                    let isContractor = false;

                    for (const cfid in icfs) {
                        const lcName = icfs[cfid].name.toLowerCase();

                        if (lcName === 'contact type' && icfs[cfid].type === 'dropDown') {
                            contactTypeCf = icfs[cfid];
                            break;
                        }
                    }

                    if (contactTypeCf) {
                        const value = participant.custom_fields[contactTypeCf.id];

                        if (value) {
                            let ddvalue = null;

                            for (const oid in contactTypeCf.options) {
                                if (contactTypeCf.options[oid].toLowerCase() === 'contractor') {
                                    if (oid === value) {
                                        isContractor = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }

                    return isContractor ? '#4589C8' : '#FF8900';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            },
            group_pages: {
                messages: {
                    columns: function() {
                        const propertyCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'property');

                        return [
                            ['tags', 'related.tags', 'Development',  { groupable: true, sortable: true, filterable: true, filterId: 'conversation_related_tags' }],
                            ['opportunity', `related.custom_fields.${propertyCf?.id}`, 'Property', { groupable: true, sortable: true, filterable: true, filterId: 'related_opportunity_custom', filterCustom: propertyCf?.id }]
                        ];
                    },
                    default_columns: function() {
                        const propertyCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'property');

                        return [
                            'last_message.created',
                            'last_message.creator_name',
                            'related.tags',
                            `related.custom_fields.${propertyCf?.id}`,
                            'last_message.message'
                        ];

                        return columns;
                    },
                    filters: function() {
                        const propertyCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'property');

                        return [{
                            id: 'related_opportunity_custom',
                            custom: propertyCf?.id,
                            title: 'Property',
                            operators: 'opportunityOperators'
                        }];
                    },
                    filters_names: {
                        related_tags: 'Development'
                    }
                }
            }
        },
        cnnlls: {
            _inherit_from: 'yourkeysdevb2b',
            showYotiDealButton: true,
            showSupportWidget: false,
            individualUnsubscribedAllDefaultValue: true,
            individualUnsubscribedAllMessagesDefaultValue: true,
            disableCurrencyOnDeals: true,
            linkClickUrlTarget: "_blank", //(_blank, _self, _parent, _top)
            disableDealElephants: true,
            credasCheckJourneyStarted: true,
            enableReportingPeriodGroups: false,
            disableDuplicatesGroup: true,
            communication: {
                phone: {
                    showErrorMessage: false,
                }
            },
            custom_field_groups_map: {
                hidden_in_edit_mode: {
                    compliance_section: function() {
                        let teamsByUserId = {};
                        const allowedTeam = 'Compliance';

                        const getUsersId = (team) => {
                            let usersId = [];

                            for (const child of team.children) {
                                usersId.push(child.user_id);

                                if (child.children.length > 0) {
                                    usersId = usersId.concat(getUsersId(child));
                                }
                            }

                            return usersId;
                        };

                        for (let team of app.globalData.teams) {
                            const usersId = getUsersId(team);

                            for (const userId of usersId) {
                                if (!(userId in teamsByUserId)) {
                                    teamsByUserId[userId] = [];
                                }

                                if (teamsByUserId[userId].indexOf(team.name) === -1) {
                                    teamsByUserId[userId].push(team.name);
                                }
                            }
                        }

                        if (teamsByUserId[app.user.get('id')] &&  teamsByUserId[app.user.get('id')].includes(allowedTeam)) {
                            return false;
                        }

                        return true;
                    },
                },
            },
            individuals: {
                custom_delete_permissions: function(appConfig, params) {
                    if (app.user.get('is_admin')) return true;

                    const individual = params.individual;

                    const credasStatusCf = app.globalData.customFieldsInfo.individualsArray.find(cf => cf.name.toLowerCase() === 'credas status');

                    if ( ! credasStatusCf ) return true;

                    let value = null;

                    /*
                        Group Pages & Entity View return the value of dropdown CF differently
                        entity view: custom_field.${cf.id}: ${option.id}
                        group pages: custom_fields.${cf.id}: ${option.value} // the actual text of the dropdown option
                    */
                    if (params.isGroupPages) {
                        value = individual.get(`custom_fields.${credasStatusCf['id']}`);
                    } else {
                        const credasStatusOptions = credasStatusCf['options'];

                        value = credasStatusOptions[individual.get(`custom_field.${credasStatusCf['id']}`)];
                    }

                    if (!value) {
                        return true;
                    }

                    return ! (value.toLowerCase() === 'complete');
                },
                handle_delete: function(appConfig, params) {
                    const canDelete = appConfig.getValue('individuals.custom_delete_permissions', true, {individual: params.individual, isGroupPages: false});

                    if (canDelete) return true;

                    const contactText = TextManager.parseText('${ID_INDIVIDUAL}');

                    const mbContent = {
                        message: `You are not allowed to delete this ${contactText} as an AML journey has been completed.`,
                        icon: 'icon-blocked',
                    };

                    MessageBox.showOk( mbContent, params.this );

                    return false;
                },
                can_bulk_delete: function (appConfig, params) {
                    const credasStatusCf = app.globalData.customFieldsInfo.individualsArray.find(cf => cf.name.toLowerCase() === 'credas status');

                    if ( ! credasStatusCf ) return true;

                    const column = `custom_fields.${credasStatusCf['id']}`;

                    const containsColumns = _.contains(params.columns, column);

                    if ( ! containsColumns ) {
                        const mbContent = {
                            message: 'Add Credas Status Column.',
                            icon: 'icon-blocked',
                        };

                        MessageBox.showOk( mbContent, params.this );

                        return false;
                    }

                    return true;
                },
                bulk_delete: function(appConfig, individual) {
                    return appConfig.getValue('individuals.custom_delete_permissions', true, {individual: individual, isGroupPages: true});
                },
            },
            organizations: {
                cdd_checker: {
                    visible: function(appConfig, organization) {
                        const cfs = app.globalData.customFieldsInfo.organizationsArray;
                        const cddNumberCf = cfs.find(cf => cf.name.toLowerCase() === 'cdd / cor number*');

                        if (!cddNumberCf) {
                            return false;
                        }

                        return !organization[`custom_field.${cddNumberCf.id}`];
                    }
                }
            },
            deals: {
                expected_close_date_default: function() {
                    return null;
                },
                groups: {
                    open_in_table_view: true
                },
                edit: {
                    show_custom_field_modal_confirmation: true,
                    modal_custom_field_name: 'Non-Prime Private Individual transaction not requiring FCP referral',
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
                overview: {
                    show_send_compliance_email_button: true,
                    funnel: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    },
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
                handle_delete: function(appConfig, params) {
                    const phaseName = params.deal.get('phase').name;

                    const canDelete = appConfig.getValue('deals.custom_delete_permissions', true, phaseName);

                    if (canDelete) return true;

                    const dealText = TextManager.parseText('${ID_DEAL, capitalize}');

                    const mbContent = {
                        message: `${dealText} is in phase ${phaseName}, you cannot delete it.`,
                        icon: 'icon-blocked',
                    };

                    MessageBox.showOk( mbContent, params.this );

                    return false;
                },
                custom_delete_permissions: function(appConfig, dealPhase) {
                    if (app.user.get('is_admin')) return true;

                    const phaseName = dealPhase.toLowerCase();
                    const phasesNotToDelete = [
                        'compliance',
                        'instructed site',
                        'compliance approval',
                        'distribute heads of terms / notification of sale',
                        'completed',
                    ];

                    return !phasesNotToDelete.includes(phaseName);
                },
            },
            bulk_update: {
                opportunities: {
                    phase: {
                        available: function() {
                            return app.user.get('is_helper');
                        }
                    }
                }
            },
            opportunities: {
                can_bulk_delete: function (appConfig, params) {
                    const column = 'phase.name';

                    const result = _.contains(params.columns, column);

                    if ( ! result ) {
                        const mbContent = {
                            message: 'Add phase column.',
                            icon: 'icon-blocked',
                        };

                        MessageBox.showOk( mbContent, params.this );

                        return false;
                    }

                    return true;
                },
                bulk_delete: function(appConfig, deal) {
                    const phaseName = deal.get('phase.name');

                    if (phaseName) {
                        return appConfig.getValue('deals.custom_delete_permissions', true, phaseName);
                    }
                },
            },
            quick_add_task: {
                types_list: [{
                    id: 'email_followup',
                    title: 'Email follow-up'
                }, {
                    id: 'call_followup',
                    title: 'Call follow-up',
                }, {
                    id: 'second_followup',
                    title: 'Second follow-up',
                }, {
                    id: 'inactive',
                    title: 'Inactive',
                }, {
                    id: 'weekly_followup',
                    title: 'Weekly follow-up'
                }, {
                    id: 'viewing_followup',
                    title: 'Viewing follow-up',
                }, {
                    id: 'compliance',
                    title: 'Compliance',
                }],
                subject_suggestions: [
                    'Email follow up',
                    'Call follow up',
                    'Second follow up',
                    'Inactive',
                    'Viewing confirmation',
                    'Viewing cancellation',
                    'Weekly follow up',
                    'Compliance Response FAO: (enter Compliance agent name)',
                ]
            },
        },
        connells: {
            _inherit_from: 'cnnlls',
        },
        connellsdemo: {
            _inherit_from: 'cnnlls',
            app_nav: {
                create_new: {
                    appointment: {
                        visible: true
                    }
                }
            },
            app_nav_items: {
                appointments: {
                    visible: true
                }
            },
            appointments: {
                show_funnels_tags_adhoc_groups: false,
                show_funnels_adhoc_groups: false,
                show_system_groups: true,
                subject_suggestions: [
                    "General Discussion",
                    "Site Visit",
                    "Progress Review Meeting",
                    "Negotiation Meeting",
                    "Compliance Review",
                    "Contract Signing",
                ]
            },
            dashboard: {
                new_flex_dashboard_enabled: true,
                default_dashboard_id: 'sales',
                show_header_funnel_selection: false,
            },
        },
        persimmonhomes: {
            _inherit_from: 'yourkeysdev',
            dealViewDisplayBrandName: true,
            disableTagsOnDeals: true,
            showSupportWidget: false,
            individualUnsubscribedAllDefaultValue: true,
            individualUnsubscribedAllMessagesDefaultValue: true,
            individualsSelectTagsHoverTaskActivitiesAppointments: true,
            use_pdrcm_improved_endpoints: true,
            dashboard_clustering: true,
            entitiesTableViewShowBulkDeleteButton: function() {
                return app.user.get('is_helper');
            },
            leads: {
                created_date: {
                    visible: true
                }
            },
            app_nav: {
                create_new: {
                    organization: {
                        visible: false
                    },
                    deal: {
                        visible: false
                    },
                    campaign: {
                        visible: false
                    },
                    group: {
                        visible: false
                    },
                    appointment: {
                        visible: true
                    },
                    report: {
                        visible: true
                    },
                }
            },
            app_nav_items: {
                appointments: {
                    visible: true
                },
                reports: {
                    visible: true
                },
                messages: {
                    visible: true
                },
                portal: {
                    visible: true
                },
                campaigns: {
                    visible: function() {
                        if (security.checkRbaPermission('campaigns', null, 'create')) {
                            return uiTabIsEnabled('campaigns');
                        }

                        return false;
                    }
                }
            },
            dashboard: {
                new_flex_dashboard_enabled: true,
                use_optimised_funnel_query: true,
                default_dashboard_id: 'persimmon-team-beta',
                is_enabled: function(appConfig, dashboardId) {
                    return dashboardId === 'team-beta';
                },
                is_widget_enabled: function(_, widgetId) {
                    return widgetId !== 'activities_list';
                },
                on_task_completation: {
                    show_activity_note_popup: true,
                },
            },
            funnel: {
                is_enabled: function(appConfig, funnel) {
                    if (funnel.is_archive) {
                        return false;
                    }

                    return app.user.get('is_helper') || !!funnel.region;
                },
                phases: {
                    draggable: function() {
                        return app.user.get('is_helper');
                    }
                }
            },
            individuals: {
                enable_funnel_field: true,
                preloaded_tags_field: false,
                preloaded_funnels_field: true,
                overview: {
                    related_deals: {
                        filter: function(appConfig, deals) {
                            return deals.filter(deal => deal.phase.phase_type !== 'lost' && deal.phase.phase_type !== 'won' && deal.funnel.name.toLowerCase() !== 'reservations');
                        },
                    }
                },
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true,
                    favorites: {
                        visible: false
                    },
                    leads: {
                        visible: false
                    },
                    my_individuals: {
                        visible: false
                    },
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                    order_by: false,
                },
                duplicate: {
                    visible: function() {
                        return app.user.get('is_helper');
                    }
                },
                edit: {
                    source: {
                        disabled_with_value: true
                    }
                },
                customValidation: function(appConfig, data) {
                    const attrs = data.attrs;
                    var errors = {};

                    if (data.isNew && data.isLead && !attrs.source) {
                        errors.source = 'Source required';
                    }

                    return errors;
                },
                activities: {
                    include_org_related: false
                },
            },
            organizations: {
                enable_funnel_field: true,
                groups: {
                    show_my_groups: true,
                    recently_added: {
                        visible: false
                    },
                    recently_viewed: {
                        visible: false
                    },
                    my_organizations: {
                        visible: false
                    }
                }
            },
            tasks: {
                enable_funnel_field: true,
                preloaded_tags_field: false,
                preloaded_funnels_field: true,
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_regions_adhoc_groups: true,
                    show_my_groups: true,
                    this_week: {
                        visible: false
                    },
                    today: {
                        visible: false
                    },
                    tasks_by_owner: {
                        visible: false
                    }
                },
                on_creation: {
                    suggested_tags: {
                        check_condition: function(appConfig, tagIds) {
                            const tags = app.globalData.tags.filter(t => tagIds.indexOf(t.id) !== -1);
                            const tagNames = ['emailed', 'follow up call - no answer', 'follow up call - left message', 'follow up call - answered', 'brochure sent', 'broker referral', 'solicitor comment', 'cancellation', 'general comment', 'internal solicitor comment', 'first visit', 'revisit'];

                            for (const tag of tags) {
                                if (tagNames.indexOf(tag.name.toLowerCase()) !== -1) {
                                    return false;
                                }
                            }

                            return true;
                        },
                        tags_list: function() {
                            const tagNames = ['emailed', 'follow up call - no answer', 'follow up call - left message', 'follow up call - answered', 'brochure sent', 'broker referral', 'solicitor comment', 'cancellation', 'general comment', 'internal solicitor comment', 'first visit', 'revisit'];
                            let tags = [];

                            for (const tag of app.globalData.tags) {
                                if (tagNames.indexOf(tag.name.toLowerCase()) !== -1) {
                                    tags.push({
                                        id: tag.id,
                                        name: tag.name
                                    });
                                }
                            }

                            return tags;
                        }
                    }
                }
            },
            activities: {
                groups: {
                    show_funnels_tags_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true,
                },
                notes: {
                    enable_funnel_field: true,
                    on_creation: {
                        suggested_tags: {
                            check_condition: function(appConfig, tagIds) {
                                const tags = app.globalData.tags.filter(t => tagIds.indexOf(t.id) !== -1);
                                const tagNames = ['emailed', 'follow up call - no answer', 'follow up call - left message', 'follow up call - answered', 'brochure sent', 'broker referral', 'solicitor comment', 'cancellation', 'general comment', 'internal solicitor comment', 'first visit', 'revisit'];

                                for (const tag of tags) {
                                    if (tagNames.indexOf(tag.name.toLowerCase()) !== -1) {
                                        return false;
                                    }
                                }

                                return true;
                            },
                            tags_list: function() {
                                const tagNames = ['emailed', 'follow up call - no answer', 'follow up call - left message', 'follow up call - answered', 'brochure sent', 'broker referral', 'solicitor comment', 'cancellation', 'general comment', 'internal solicitor comment', 'first visit', 'revisit'];
                                let tags = [];

                                for (const tag of app.globalData.tags) {
                                    if (tagNames.indexOf(tag.name.toLowerCase()) !== -1) {
                                        tags.push({
                                            id: tag.id,
                                            name: tag.name
                                        });
                                    }
                                }

                                return tags;
                            }
                        }
                    }
                }
            },
            deals: {
                duplicate: {
                    visible: function() {
                        return app.user.get('is_helper');
                    }
                },
                app_nav_item: {
                    name: 'Plots & Reservations'
                },
                groups: {
                    open_in_table_view: true,
                    show_system_adhoc_groups: false,
                    show_funnels_adhoc_groups: true,
                    show_my_groups: true,
                },
                edit: {
                    funnel: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    },
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
                overview: {
                    funnel: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    },
                    phase: {
                        editable: function() {
                            return app.user.get('is_helper');
                        }
                    }
                },
                choices: {
                    enable_for: function(appConfig, deal) {
                        const cfs = app.globalData.customFieldsInfo.deals;
                        let dealTypeCf = null;

                        for (const cfid in cfs) {
                            const lcName = cfs[cfid].name.toLowerCase();

                            if (lcName === 'deal type' && cfs[cfid].type === 'dropDown') {
                                dealTypeCf = cfs[cfid];
                                break;
                            }
                        }

                        if (!dealTypeCf) {
                            return false;
                        }

                        let plotOptionId = null;

                        for (const oid in dealTypeCf.options) {
                            if (dealTypeCf.options[oid].toLowerCase() === 'plot') {
                                plotOptionId = oid;
                                break;
                            }
                        }

                        if (!plotOptionId) {
                            return false;
                        }

                        return deal.get(`custom_field.${dealTypeCf.id}`) === plotOptionId &&
                               ['Tentative', 'Pending', 'Reserved',
                                'Pending Cancellation', 'Exchanged', 'Completed'].includes(deal.get('phase_name'));
                    },
                    campaigns_id: {
                        selections_poa_requested: 'a6a3929b-417b-4922-8ce0-22d040cf2580',
                        commercial_team_incomplete: 'b959a9c3-df69-4e33-b2a5-b4ae3f5d4227',
                        poas_updated: 'ee68266c-0515-41d4-8673-13c76c4132a3',

                        persimmon: {
                            homeowner_selections_approval: '3dd35764-ef7a-4d2b-8a72-c435f49246e5',
                            approval_cancelled: '904a9046-5c01-45b3-99d5-6d4cd151c936',
                            selections_approved: 'f48b9ea6-b5a3-4de8-8db7-ba3934e9a7a0'
                        },
                        charlesChurch: {
                            homeowner_selections_approval: '55931139-ab92-42db-9b67-ab8bac84722d',
                            approval_cancelled: 'bfe33739-947a-491b-945a-361ff38c56a4',
                            selections_approved: '78979414-4e94-4825-ba90-18e78a437a6b'
                        }
                    }
                },
                activities: {
                    include_org_related: false
                }
            },
            group_pages: {
                individuals: {
                    default_columns: function() {
                        let columns = ['created','source.name','regions','funnels','first_name','last_name','email','phone'];

                        for (const cfName of ['contact type','reservation status','property','customer situation','enquiry type','postcode']) {
                            const cf = app.globalData.customFieldsInfo.individualsArray.find(cf => cf.name.toLowerCase() === cfName);

                            if (cf) {
                                columns.push(`custom_fields.${cf.id}`);
                            }
                        }

                        return columns;
                    },
                    default_sort: {field: ['created'], direction: false}
                },
                appointments: {
                    columns: function() {
                        let columns = [
                            ['text', 'related.region_name', 'Region', {filterable: true, filterId: 'appointment_related_region_id', filterType: 'region'}],
                            ['text', 'related.funnel.name', 'Development'],
                            ['text', 'related.name', 'Related'],
                        ];

                        const plotNoCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'unit no.');

                        if (plotNoCf) {
                            columns.push(['text', `related.custom_fields${plotNoCf.id}`, 'Unit NO.']);
                        }

                        return columns;
                    },
                    default_columns: function() {
                        let columns = ['start_date', 'creator.name', 'status', 'related.name', 'tags'];

                        return [...columns, ...['appointment_type']];
                    }
                },
                messages: {
                    columns: function() {
                        return [
                            ['tags', 'related.tags', 'Development',  { groupable: true, sortable: true, filterable: true, filterId: 'conversation_related_tags' }],
                            ['opportunity', 'related', 'Property', { groupable: true, sortable: true, filterable: true, filterId: 'conversation_related_id' }],
                            ['text', 'related.region_name', 'Region', {groupable: true, filterable: true, filterId: 'conversation_related_region_id', filterType: 'region'}],
                        ];
                    },
                    default_columns: function() {
                        return [
                            'last_message.created',
                            'last_message.creator_name',
                            'related.tags',
                            'related',
                            'related.region_name',
                            'last_message.message'
                        ];

                        return columns;
                    },
                    filters: function() {
                        return [{
                            id: 'conversation_related_id',
                            title: 'Property',
                            operators: 'opportunityOperators'
                        }, {
                            id: 'conversation_related_region_id',
                            title: 'Region',
                            operators: 'regionOperators'
                        }];
                    },
                    filters_names: {
                        related_tags: 'Development'
                    }
                },
                tasks: {
                    default_columns: ['regions', 'funnels', 'created', 'completed', 'due_date', 'complete_date', 'text', 'assignee.name', 'related', 'subject']
                }
            },
            conversations: {
                visible: true,
                default_category: 'General',
                enable_for_deal: function(appConfig, params) {
                    $.get(`/conversations?related_id=${params.model.get('id')}`, function(result) {
                        params.callback(result.length > 0);
                    });
                },
                conversations_list_opportunity: function() {
                    return {};
                }
            },
            appointments: {
                show_funnels_tags_adhoc_groups: false,
                show_funnels_adhoc_groups: true,
                show_system_groups: false,
                subject_suggestions: ['Showroom Tour', 'Site Visit', 'Property Viewing', 'Options & Extras - Consultation', 'Options & Extras - Review', 'General Discussion', 'Development Update Meeting']
            },
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableCloseDateOnDeals: true,
            disableOrganizationOnDeals: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            },
            yourkeys: {
                use_yourkeys_iframe: true,
            },
            app_settings: {
                users: {
                    user_management: {
                        role: {
                            editable: function() {
                                return app.user.get('is_helper');
                            }
                        }
                    }
                }
            },
            activity_filter: {
                secondary: {
                    individuals: {
                        force_record_view: true // every time the activity feed is show, the option "This Ind/Org/Deal" is active
                    },
                    organizations: {
                        force_record_view: true
                    },
                    opportunities: {
                        force_record_view: true
                    }
                }
            },
            task_filter: {
                individuals: {
                    force_record_view: true
                },
                organizations: {
                    force_record_view: true
                },
                opportunities: {
                    force_record_view: true
                }
            },
            campaigns: {
                show_funnels_tags_adhoc_groups: false,
                show_funnels_adhoc_groups: true,
                see: {
                    dynamic_content: {
                        blocks: ['plotOffersBlock'],
                        plot_offer: {
                            custom_folder: function(appConfig, item) {
                                if (!item) {
                                    return null;
                                }

                                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === item.funnel_id);

                                if (!funnel || !funnel.region) {
                                    return null;
                                }

                                let path = `marketing assets/development images/${funnel.region.name.toLowerCase()}/${funnel.name.toLowerCase()}/plots`;

                                const plotOfferHouseType = app.globalData.customFieldsInfo.opportunitiesArray.find(cf => cf.name.toLowerCase() === 'plot offer house type');
                                const houseType = item[`custom_field.${plotOfferHouseType?.id}`];

                                if (houseType) {
                                    path += `/${houseType}`;
                                }

                                return path;
                            },
                            name: function(appConfig, deal) {
                                const customFields = app.globalData.customFieldsInfo.opportunitiesArray;
                                const plotOfferHouseType = customFields.find(cf => cf.name.toLowerCase() === 'plot offer house type');

                                return deal[`custom_field.${plotOfferHouseType?.id}`] || deal.name;
                            },
                            price: function(appConfig, deal) {
                                let price = null;

                                const customFields = app.globalData.customFieldsInfo.opportunitiesArray;
                                const plotOfferAmountCf = customFields.find(cf => cf.name.toLowerCase() === 'plot offer amount');

                                price = deal[`custom_field.${plotOfferAmountCf?.id}`];

                                if (!price) {
                                    const bucketGrossReleasePrice = deal.buckets.find(b => b.name.toLowerCase() === 'gross release price');
                                    price = bucketGrossReleasePrice?.value;
                                }

                                return price || 0;
                            },
                            number: function(appConfig, deal) {
                                const customFields = app.globalData.customFieldsInfo.opportunitiesArray;
                                const unitNo = customFields.find(cf => cf.name.toLowerCase() === 'unit no.');

                                return deal[`custom_field.${unitNo?.id}`] || '-';
                            },
                            url: function(appConfig, deal) {
                                const plotOfferUrlCf = app.globalData.customFieldsInfo.opportunitiesArray.find(cf => cf.name.toLowerCase() === 'plot offer url');

                                let url = deal[`custom_field.${plotOfferUrlCf?.id}`];

                                if (url) {
                                    return url;
                                }

                                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === deal.funnel_id);

                                if (funnel?.integration_data?.brandCode === 'C') {
                                    return 'https://www.charleschurch.com/';
                                }

                                return 'https://www.persimmonhomes.com/';
                            }
                        }
                    },
                    blocks: {
                        image: {
                            custom_folder: function(appConfig, funnelId) {
                                if (!funnelId) {
                                    return null;
                                }

                                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === funnelId);

                                if (!funnel || !funnel.region) {
                                    return null;
                                }

                                return `marketing assets/development images/${funnel.region.name.toLowerCase()}/${funnel.name.toLowerCase()}/website`;
                            }
                        }
                    },
                    lock_controls: {
                        enabled: function(appConfig) {
                            return appConfig.getValue('campaigns.user_type') === 'advance';
                        }
                    },
                    color_picker: {
                        default_colors: function(appConfig, funnelId) {
                            const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === funnelId);

                            switch (funnel?.integration_data?.brandCode) {
                                case 'C':
                                    return ['#ffffff', '#000000', '#cecebf', '#2a4a56', '#53544e', '#cdc9a6', '#0c0d0e', '#60737a'];

                                case 'P':
                                    return ['#ffffff', '#000000', '#007960', '#F4F3ED', '#004447', '#e8e3db', '#ea6346', '#39b38a', '#8098a7', '#444b49'];
                            }

                            return ['#ffffff', '#000000', '#007960', '#F4F3ED', '#004447', '#e8e3db', '#ea6346', '#39b38a', '#8098a7', '#444b49',
                                    '#cecebf', '#2a4a56', '#53544e', '#cdc9a6', '#0c0d0e', '#60737a'];
                        }
                    }
                },
                step_choose: {
                    show_region_and_development: true
                },
                step_preview: {
                    fixed_from: function(appConfig, campaignData) {
                        if (!campaignData.funnel_id) {
                            return null;
                        }

                        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === campaignData.funnel_id);
                        const users = app.globalData.users;
                        let from = null;

                        switch (funnel?.integration_data?.brandCode) {
                            case 'C':
                                from = users.find(u => u.email_address.toLowerCase() === 'noreply@charleschurch.com');
                                break;
                            default:
                                from = users.find(u => u.email_address.toLowerCase() === 'noreply@persimmonhomes.com');
                                break;

                        }

                        return from ? [from] : null;
                    }
                },
                step_launch: {
                    basic_user_launch_delay_hours: 24
                },
                step_approval: {
                    enabled: true
                },
                user_type: function() {
                    if (app.user.get('is_admin') || app.user.get('is_helper')) {
                        return 'advance';
                    }

                    // if (app.user.get('campaign_approver')) {
                    //     return 'approver';
                    // }

                    return 'basic';
                }
            },
            search: {
                opportunities: {
                    exclude_reservations_from_results: true,
                }
            },
            price_lists: {
                visible: true
            },
            on_task_completation: {
                show_activity_note_popup: true,
                show_task_popup: true
            },
            custom_field_groups_map: {
                hidden_in_edit_mode: {
                    roles_allowed_to_declare_reservation: function() {
                        const allowedRoles = ['CRM_IT', 'CRM_Field_Sales_Manager', 'CRM_Sales_Director', 'CRM_Group_Sales_Director'];
                        const userRoles = app.user.get('roles');

                        for (let role of userRoles) {
                            // cover the case of SS Suport - to be able to see the group,
                            // admin role can't be part of allowedRoles - PH have role ${something}_Administrator which includes Admin
                            if (role.system_type && role.system_type.toLowerCase() === "admin") {
                                return false;
                            }

                            for (let allowedRole of allowedRoles) {
                                if (role.name.toLowerCase().includes(allowedRole.toLowerCase())) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    },
                },
            }
        },
        persimmonhomestraining: {
            _inherit_from: 'persimmonhomes',
            deals: {
                choices: {
                    campaigns_id: {
                        selections_poa_requested: '0550acfc-d64b-4a86-b23b-e31fa7b8478a',
                        commercial_team_incomplete: '7ac97a06-f5e9-4a2f-b01a-3599f03509bc',
                        poas_updated: '61dff2e5-7d7b-4610-a8c0-a8d0a89d426b',

                        persimmon: {
                            homeowner_selections_approval: '30f05262-bfd2-4bdd-98c9-011910f41861',
                            approval_cancelled: '6c85384c-f0c4-4bc4-a89d-c87c1ed291b6',
                            selections_approved: 'f48b9ea6-b5a3-4de8-8db7-ba3934e9a7a0'
                        },
                        charlesChurch: {
                            homeowner_selections_approval: '55931139-ab92-42db-9b67-ab8bac84722d',
                            approval_cancelled: 'bfe33739-947a-491b-945a-361ff38c56a4',
                            selections_approved: '78979414-4e94-4825-ba90-18e78a437a6b'
                        }
                    }
                }
            }
        },
        persimmondemo: {
            _inherit_from: 'persimmonhomes'
        },
        persimmondev: {
            _inherit_from: 'persimmonhomes'
        },
        persimmonhomesqa: {
            _inherit_from: 'persimmonhomes',
            deals: {
                choices: {
                    campaigns_id: {
                        selections_poa_requested: 'a6a3929b-417b-4922-8ce0-22d040cf2580',
                        commercial_team_incomplete: 'b959a9c3-df69-4e33-b2a5-b4ae3f5d4227',
                        poas_updated: 'ee68266c-0515-41d4-8673-13c76c4132a3',

                        persimmon: {
                            homeowner_selections_approval: '3dd35764-ef7a-4d2b-8a72-c435f49246e5',
                            approval_cancelled: '904a9046-5c01-45b3-99d5-6d4cd151c936',
                            selections_approved: 'f48b9ea6-b5a3-4de8-8db7-ba3934e9a7a0'
                        },
                        charlesChurch: {
                            homeowner_selections_approval: '55931139-ab92-42db-9b67-ab8bac84722d',
                            approval_cancelled: 'bfe33739-947a-491b-945a-361ff38c56a4',
                            selections_approved: '78979414-4e94-4825-ba90-18e78a437a6b'
                        }
                    }
                }
            }
        },
        persimmonhomesstaging: {
            _inherit_from: 'persimmonhomes',
            deals: {
                choices: {
                    campaigns_id: {
                        selections_poa_requested: 'a6a3929b-417b-4922-8ce0-22d040cf2580',
                        commercial_team_incomplete: 'b959a9c3-df69-4e33-b2a5-b4ae3f5d4227',
                        poas_updated: 'ee68266c-0515-41d4-8673-13c76c4132a3',

                        persimmon: {
                            homeowner_selections_approval: '3dd35764-ef7a-4d2b-8a72-c435f49246e5',
                            approval_cancelled: '904a9046-5c01-45b3-99d5-6d4cd151c936',
                            selections_approved: 'f48b9ea6-b5a3-4de8-8db7-ba3934e9a7a0'
                        },
                        charlesChurch: {
                            homeowner_selections_approval: '55931139-ab92-42db-9b67-ab8bac84722d',
                            approval_cancelled: 'bfe33739-947a-491b-945a-361ff38c56a4',
                            selections_approved: '78979414-4e94-4825-ba90-18e78a437a6b'
                        }
                    }
                }
            }
        },
        sunningdale: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            deals_calendar: {
                phase_is_won: function(appConfig, phase) {
                    return phase.name.toLowerCase() === 'completed';
                },
                filter: {
                    field:  'opportunity_custom',
                    custom: '59d5262e-5d3e-4940-84c6-6894e4d8b68b'
                },
                deal_name_formatter: function(appConfig, dealName) {
                    // get the first alphanumeric string
                    return dealName.match(/[A-Za-z0-9]+/) || dealName;
                },
                extra_columns: ['custom_fields.59d5262e-5d3e-4940-84c6-6894e4d8b68b'],
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.fe2cd05c-15db-4453-b9a4-9cb83a71553f']; // custom field "by telephone"
                    }
                }
            }
        },
        democrm: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.7938aad4-87d7-47eb-9870-1a5832baa2af']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.9b3fe48d-ec0c-4752-9772-c15924344a11'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.5835d75b-fbf4-4d66-8c81-8cad3bdd89e7'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        plumber: {
            _inherit_from: 'durkanhomes',
            disableWeightOnDeals: true,
            disableCurrencyOnDeals: true,
            disableValueOnDeals: true,
            disableOrganizationOnDeals: true,
            disableBubbleOnDeals: true,
            disableStatusOnDeals: true,
            disableValueOnDeals: true,
            useLargeFontOnDealBuckets: true,
            disableForecastOptionFromFunnelColorWidget: true,
            disableForecastAndQuotaWidgetsForTeamAndSalesExec: function() {
                return _.contains(['team', 'sales_executive'], app.user.get('preferences').default_dashboard);
            }
        },
        watkinjones: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.faddb5da-80a9-4ff2-8ebe-c454e95602a4']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.54d31bda-436e-4fa2-a568-e4abc75f7f57'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.13dea8c8-84d4-4c76-9235-c0489fc4e4c2'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        yourkeysqa: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            yourkeys: {
                use_yourkeys_iframe: true,
            },
            app_nav: {
                create_new: {
                    report: {
                        visible: true
                    },
                }
            },
        },
        kitewood: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.d69e4810-2f64-4f4d-96dd-ca9792ec7eec']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.62939060-e87c-4069-899e-d4bbc1835690'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.f67e3c03-2cd6-49b0-b90f-e27a830ae1b1'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        risland: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.4c6ac4b1-ef2b-4ced-a7a2-332f97012228']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.9c5afe90-17df-4b74-9b7b-7fe336b9e1bb'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.e34846a3-8b63-4fa0-a233-0f8ab7c42250'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        hollins: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            dashboard: {
                new_flex_dashboard_enabled: true,
                is_enabled: function(appConfig, dashboardId) {
                    return dashboardId === 'team-beta';
                }
            },
            app_nav: {
                create_new: {
                    organization: {
                        visible: false
                    },
                    deal: {
                        visible: false
                    },
                    campaign: {
                        visible: false
                    },
                    group: {
                        visible: true
                    },
                    appointment: {
                        visible: false
                    },
                    report: {
                        visible: true
                    }
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.050431ae-cfc3-498e-97ed-5974560d0196']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.0e250ea3-a8cc-4449-b74d-d5fd1e6e2f15'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.aa41ff6f-b843-4b41-9596-a35d348c2a22'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        hammondhomes: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.d971b429-ea09-40e9-87b8-e5552d13b2ff']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.40d43250-f965-474f-ae39-e5c2717b7532'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.b727da9e-70e2-435c-a7ac-ca8b5c065d22'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        auxesia: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.8cd41b2d-1c73-464e-a869-879bf76b0ef4'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.767163ce-9df4-46ae-aef8-a9d2337c0d82'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        democrm2: {
            _inherit_from: 'yourkeysdev',
            leads: {
                created_date: {
                    visible: true
                }
            },
            communications: {
                email: {
                    label: 'Personal'
                },
                phone: {
                    label: 'Mobile',
                    grey_out: function(appConfig, entityData) {
                        return !entityData['custom_field.c95a21b8-bd9d-47ce-98a1-ef4b6c32d887']; // custom field "by telephone"
                    }
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.7303a99a-abac-4437-9d0a-c2d30f809ca7'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.95daaa51-e574-4437-85dd-eb9998633692'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        },
        semtaorg: {
            individuals: {
                duplicate: {
                    visible: true
                }
            },
            deals: {
                duplicate: {
                    visible: true
                }
            }
        },
        qualishomes: {
            _inherit_from: 'yourkeysdev',
            useZendesk: true,
            leads: {
                created_date: {
                    visible: true
                }
            },
            funnel_map: {
                point_id_formatter: function(appConfig, deal) {
                    return deal.get('abbreviation').match(/[A-Za-z0-9]+/) || deal.get('name');
                },
                name_formatter: function(appConfig, params) {
                    $.get(`/opportunities/${params.deal.get('id')}/individuals?rows=1&order_by=full_name asc`, function (data) {
                        params.callback(data.length > 0 ? data[0].full_name : '');
                    });
                },
                value_formatter: function(appConfig, deal) {
                    // this returns the text and the style to show
                    const buckets = deal.get('buckets');
                    const bucketAchieved = buckets.find(b => b.name.toLowerCase() === 'achieved price');
                    const phaseIsReservedOrHigher = deal.get('phase_number') >= 4;
                    let style = {};
                    let value = [];

                    if (phaseIsReservedOrHigher) {
                        style.color = 'red';
                    }

                    if (phaseIsReservedOrHigher && bucketAchieved && bucketAchieved.value) {
                        value.push(Currency.format(null, bucketAchieved.value));
                    } else {
                        const bucketAsking = buckets.find(b => b.name.toLowerCase() === 'asking price' || b.name.toLowerCase() === 'gross release price');

                        if (bucketAsking && bucketAsking.value) {
                            value.push(Currency.format(null, bucketAsking.value));
                        } else {
                            value.push(0);
                        }
                    }

                    let area = deal.get('custom_field.c3f57cc8-d14c-4d08-9b7e-69ac224a80f5'); // area ft2

                    if (!area) {
                        area = deal.get('custom_field.f499ba23-b654-412b-aab2-e5aa90cf6e8b'); // area m2

                        if (area) {
                            area *= 10.764; // m2 to ft2
                        }
                    }

                    if (area) {
                        value.push(area);
                    }

                    return [value.map(v => `<div style="white-space: nowrap;">${v}</div>`).join(''), style];
                }
            },
            individuals: {
                edit: {
                    create_activity_note_fields: function(appConfig) {
                        const customFields = app.globalData.customFieldsInfo.individuals;
                        let fields = [];

                        for (const cfId in customFields) {
                            if ((cfId in customFields) && (['viewing time', 'viewing date', 'viewing feedback'].indexOf(customFields[cfId].name.toLowerCase()) !== -1)) {
                                fields.push({
                                    label: `${customFields[cfId].name}: `,
                                    field: `custom_field.${cfId}`
                                });
                            }
                        }

                        return fields;
                    }
                }
            },
            deals: {
                close_date_formatter: function(appConfig, date) {
                    if (date) {
                        return dateFormat.fullMonthYearFormat(date);
                    }

                    return 'Unknown';
                }
            },
            quick_add_task: {
                default_time: '08:00'
            }
        }
    }
}

var AppConfig = {
    init: function() {
        var host = window.location.hostname;
        var ppos = host.indexOf('.');
        // var clientId = host.substring(0, ppos);
        var clientId = "connellsdemo";
        var environment = host.substring(ppos + 1, host.indexOf('.', ppos + 1));

        this.currentConfig = null;
        this.initialized = true;

        if (!(environment in configInfo)) {
            environment = 'salesseek';
        }

        this.currentConfig = configInfo[environment].default || {};

        if (clientId in configInfo[environment]) {
            var config = _.clone(configInfo[environment][clientId]);
            var inheritFrom = config._inherit_from;

            while (inheritFrom) {
                config = this.extend(_.clone(configInfo[environment][inheritFrom]), config);
                delete config._inherit_from;
                inheritFrom = configInfo[environment][inheritFrom]._inherit_from;
            }

            this.currentConfig = this.extend(this.currentConfig, config);
        }
    },
    hasValue: function(key) {
        if (!this.initialized) {
            this.init();
        }

        if (!this.currentConfig) {
            return false;
        }

        const parts = key.split('.');
        let entry = this.currentConfig;

        for (const p of parts) {
            if (!(p in entry)) {
                return false;
            }

            entry = entry[p];
        }

        return true;
    },
    getValue: function(key, defaultValue, contextParam) {
        if (!this.initialized) {
            this.init();
        }

        if (!this.hasValue(key)) {
            return defaultValue;
        }

        const parts = key.split('.');
        let entry = this.currentConfig;

        for (const p of parts) {
            if (p in entry) {
                entry = entry[p];
            } else {
                entry = null;
                break;
            }
        }

        if (entry === null) {
            return defaultValue;
        }

        if (_.isFunction(entry)) {
            return entry(this, contextParam);
        }

        return entry;
    },
    getClientPreferenceValue: function(key) {
        var preferences = app.user.get('client').preferences || {};

        if (_.isObject(preferences) && key in preferences) {
            var value = preferences[key];

            // convert booleans value from string to bool
            switch (value) {
                case 'true': return true;
                case 'false': return false;
                default: return value;
            }
        }

        return this.getValue(key);
    },
    getFeatureLabValue: function(key, featureLabKey) {
        var value = this.getValue(key);

        if (!_.isUndefined(value)) {
            return value;
        }

        return _.contains(app.user.get('preferences').lab_flags, featureLabKey);
    },
    extend: function(destination, source) {
        for (const key in source) {
            const value = source[key];

            if (_.isObject(value) && !_.isFunction(value) && !_.isArray(value)) {
                destination[key] = this.extend(destination[key] || {}, value);
            } else {
                destination[key] = value;
            }
        }

        return destination;
    }
};

export default AppConfig;

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import TextManager from 'app/text-manager'
import Utilities from 'js/utils/utilities'
import CommunicationModel from 'js/models/communication'
import template from 'templates/base/communication-list.handlebars'
import communicationItemTemplate from 'templates/base/communication-item.handlebars'
import socialItemTemplate from 'templates/base/social-item.handlebars'


var CommunicationsListView, CommunicationItemView, SocialItemView,
    MediumListView;

CommunicationItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'communication-item expandable',
    template: Handlebars.compile(communicationItemTemplate),
    ui: {
        value_warning_message: '.value-warning-message',
        value: '.field-communication-value'
    },
    events: {
        'keydown .add-item-link': function(ev) {
            var key = ev.keyCode;

            // If no keycode or tab key
            if (!key || key === 9) {
                return;
            }

            // If key is number or letter or return
            if ((key >=48 && key <= 57) || (key >= 65 && key <= 90) || key === 13) {
                this.addItem();
                // If return key, prevent default
                return (key !== 13);
            }
        },
        'click .add-item-link': 'addItem',
        'click .delete-item': 'deleteItem',
        'change .field-communication-name': 'change_name',
        'change .field-communication-value': 'change_value',
        'change .field-communication-info': 'change_comments',
        'keyup .field-communication-value': function(ev) {
            if (this.searcher) {
                this.searcher($(ev.target).val());
            }
        },
        'paste .field-communication-value': function(ev) {
            if (this.searcher) {
                // if the value is pasted we trim it to avoid copy errors e.g: ' a@b.com'
                ev.preventDefault();
                var value = ev.originalEvent.clipboardData.getData('text/plain').trim();
                this.ui.value.val(value);
                this.ui.value.change();
                this.searcher(value);
            }
        },
        'click a': 'followLink',
        'click .expand': function() {
            this.$el.toggleClass('expanded');
        }
    },
    templateHelpers: function() {
        var medium = this.model.get('medium');
        var displayText = {
            'phone': {
                actionText: 'Call',
                placeholder: 'Phone number'
            },
            'email': {
                actionText: 'Email',
                placeholder: 'Email address'
            },
            'url': {
                actionText: 'Visit',
                placeholder: 'Website URL'
            }
        };

        // Redefine for easier object key reference
        displayText = displayText[medium];

        return {
            actionText: displayText && displayText.actionText || 'Contact',
            valueHref: this.model.valueHref(),
            valuePlaceholder:
                displayText && displayText.placeholder || 'Value'
        };
    },
    initialize: function(options) {
        this.controller = options.controller;

        if (this.options.checkExistenceFor) {
            var timeout;
            var view = this;
            var existenceTexts = {
                'organizations': TextManager.getText('ID_ORGANIZATION'),
                'individuals': TextManager.getText('ID_INDIVIDUAL')
            };

            this.searcher = function(term) {
                window.clearTimeout(timeout);

                timeout = window.setTimeout(function() {
                    if (term) {
                        let url;
                        if (view.options.checkExistenceFor === 'individuals') {
                            url = `/individual_duplicates?type=${duplicate_type}&value=${term}`
                        } else if (view.options.checkExistenceFor === 'organizations') {
                            url = `/organization_duplicates?type=${duplicate_type}&value=${term}`
                        } else if (view.options.checkExistenceFor === 'opportunities') {
                            url = `/opportunity_duplicates?type=${duplicate_type}&value=${term}`
                        } else {
                            return;
                        }
                        if (!view.model.isNew()) {
                            url += `ignore_id=${view.model.get('id')}`;
                        }
                        $.getJSON(url , (data) => {
                            if (!view.isClosed) {
                                let results = data.results;
                                if (results.length > 0) {
                                    results = _.sortBy(results, (e) => e.created);

                                    const existingItem = results[results.length - 1];
                                    const href = '#' + view.options.checkExistenceFor + '/' + existingItem.id;
                                    const medium = view.model.get('medium');
                                    const exists = existenceTexts[view.options.checkExistenceFor] || '';

                                    view.showWarningMessage(view.ui.value_warning_message, `<i class="icon-warning" style="margin-right: 5px;"></i>There is at least <a class="link" href="${href}">1 ${exists}</a> with this ${medium}`);
                                } else if (data.has_duplicates) {
                                    view.showWarningMessage(view.ui.value_warning_message, `<i class="icon-warning" style="margin-right: 5px;"></i>There is at least 1 ${exists} with this ${medium}`);
                                } else {
                                    view.hideWarningMessage(view.ui.value_warning_message);
                                }
                            }
                        });
                    } else {
                        view.hideWarningMessage(view.ui.value_warning_message);
                    }
                }, 500);
            };
        }
    },
    showWarningMessage: function(ui, message) {
        ui.html(message);
        ui.show();
    },
    hideWarningMessage: function(ui) {
        ui.hide();
    },
    onRender: function() {
        // Style as new item if no model.id is present
        this.$el.toggleClass('is-new', !this.model.id);

        this.$el.find('[data-toggle=tooltip]').tooltip();
    },
    addItem: function() {
        this.$el
            .removeClass('is-new')
            .find('.field-communication-value:first')
            .focus()
            .select();
    },
    deleteItem: function(ev) {
        $(ev.currentTarget).tooltip('hide');

        this.$el
            .animate(
                { opacity: 0 },
                { queue: false, duration: 200 }
            )
            .slideUp(200, 'easeOutQuart', function() {
                $(ev.currentTarget).tooltip('destroy'); // Kill it good!
                this.trigger('deleteItem');
            }.bind(this));
    },
    change_name: function(ev) {
        this.model.set('name', $(ev.target).val());
    },
    change_value: function(ev) {
        this.model.set('value', $(ev.target).val());
    },
    change_comments: function(ev) {
        this.model.set('comments', $(ev.target).val());
    },
    followLink: function(e) {
        if (!this.controller.isEditing()) {
            var row = e.currentTarget,
                link = $(row).attr('href');

            e.preventDefault();
            e.stopPropagation();

            this.controller.followCommunicationLink(this.model, link);
        }
    }
});

SocialItemView = CommunicationItemView.extend({
    tagName: 'li',
    className: 'communication-item social-item',
    ui: {
        field: '.field-communication-value'
    },
    events: {
        'keyup .field-communication-value'  : 'onFieldKeyUp',
        'click .clear-field'                : 'clearField',
        'change .field-communication-value' : 'change_value',
        'click a'                           : 'followLink'
    },
    template: Handlebars.compile(socialItemTemplate),
    templateHelpers: function() {
        var isTwitter = this.model.get('name') === 'twitter',
            placeholder, url, displayName;

        switch (this.model.get('name')) {
        case 'twitter':
            placeholder = 'TwitterHandle';
            break;
        case 'facebook':
            placeholder = 'Facebook URL';
            break;
        case 'linkedin':
            placeholder = 'LinkedIn URL';
            break;
        case 'googleplus':
            placeholder = 'Google+ URL';
            displayName = 'Google+';
            break;
        case 'instagram':
            placeholder = 'Instagram URL';
            displayName = 'Instagram'
        default:
            placeholder = 'Social link';
        }

        if (isTwitter) {
            url = 'https://twitter.com/' + this.model.get('value');
        } else {
            url = this.model.get('value');
            // Add http:// if there is no scheme in the url provided
            if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
                url = 'http://' + url;
            }
        }

        return {
            isTwitter: isTwitter,
            placeholder: placeholder,
            url: url,
            displayName: (displayName || this.model.get('name'))
        };
    },
    onFieldKeyUp: function(ev) {
        if ($(ev.currentTarget).val().length > 0) {
            if (!this.$el.hasClass('has-value')) {
                this.$el.addClass('has-value');
            }
        } else {
            this.$el.removeClass('has-value');
        }
    },
    clearField: function() {
        this.ui.field.val('').focus();
        this.$el.removeClass('has-value');
        this.model.set('value', this.ui.field.val());
    },
    change_value: function(ev) {
        var value = $(ev.target).val(),
            type = this.model.get('name');
        if(type === 'twitter' && value.indexOf('@')===0) {
            value=value.substr(1);
            $(ev.target).val(value);
        }
        this.model.set('value', value);
    },
    followLink: function(e) {
        e.preventDefault();
        e.stopPropagation();

        if ( !this.controller.isEditing() ) {
            var row = e.currentTarget,
                link = $(row).attr('href');

            window.open(link);
            return false; // Prevents error (browser crashing) ??
        }
    }
});

MediumListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'communications-list',
    itemViewOptions: function() {
        return {
            controller: this.controller,
            checkExistenceFor: this.options.checkExistenceFor
        };
    },
    initialize: function(options) {
        this.controller = options.controller;
        this.originalCollection = options.originalCollection;
        this.medium = options.medium;
        this.collection = new Backbone.Collection();

        this.updateCollection();

        this.listenTo(this.controller, 'editing:start', this.onEditingStart);
        this.listenTo(this.controller, 'editing:end', this.onEditingEnd);

        this.listenTo(this.collection, 'change', this.onCollectionChange);
        this.listenTo(this, 'itemview:deleteItem', this.deleteItem);

        this.listenTo(this.collection, 'change remove', this.addChange);
    },
    updateCollection: function() {
        var view = this, models;

        models = this.originalCollection.filter(function(d) {
            return d.get('medium') === view.medium;
        });

        this.collection.reset(models);
    },
    onEditingStart: function() {
        var view = this,
            networks = ['linkedin', 'twitter', 'facebook', 'googleplus', 'instagram'];

        if ( this.medium === 'social') {
            _.each(networks, function(network) {
                if (!view.collection.any(function(item) {
                    return item.get('name') === network;
                })) {
                    view.collection.add(new CommunicationModel({
                        medium: 'social',
                        name: network
                    }));
                }
            });
        }
        else {
            // Add an extra empty row to the end for adding purposes
            this.collection.add(new CommunicationModel({
                name: 'Work',
                medium: this.medium
            }));
        }
    },
    addChange: function() {
        var view = this;

        // Remove all medium rows from model
        this.originalCollection.remove(
            this.originalCollection.filter(function(d) {
                return d.get('medium') === view.medium;
            })
        );

        // Add all (valid) view rows to model
        this.originalCollection.add(
            this.collection.filter(function(d) {
                return d.get('value') && d.get('value').length;
            })
        );

        // Mark communication field as dirty
        this.controller.addChange('communication', this.originalCollection.toJSON());
    },
    onEditingEnd: function() {
        // Reflect model status to view (get rid of extra adding rows,
        // and cancel any pending modifications due to a cancel)
        this.updateCollection();
    },
    onCollectionChange: function() {
        // After any change we may need to add a new row to the end
        var last;

        if (this.controller.isEditing()) {
            if ( this.medium !== 'social') {
                last = this.collection.last();
                if (!last || (last.get('value') && last.get('value').length)) {
                    this.collection.add(new CommunicationModel({
                        medium: this.medium
                    }));
                }
            }
        }
    },
    deleteItem: function(itemView) {
        this.collection.remove(itemView.model);
    }
});

var WebsiteView = Marionette.ItemView.extend({
    template: Handlebars.compile('<div class="labeled-field-container editable-field-container ">' +
        '<div class="field-label display-field communication-name ">Website</div>' +
        '<div class="labeled-field communication-value-container">' +
            '<div class="communication-display-value">' +
                '<a href="{{href}}" target="_blank" class="website display-field link communication-value">{{value}}</a>' +
            '</div>' +
        '</div>'),
    templateHelpers: function() {
        var href = this.model.get('value');

        return {
            href: Utilities.httpUrl(href)
        };
    }
});

var OptedInView = Marionette.ItemView.extend({
    template: Handlebars.compile('<label class="labeled-field-container email-opted-in">' +
        '<span class="field-label email-opted-in-name">{{label}}</span>' +
        '<div class="labeled-field email-opted-in-value editable-field-container">' +
            '<div class="email-opted-in-display-value display-only">' +
                '<div class="display-field checkbox-display-value"><i class="{{#if optedIn}}icon-checkmark3{{else}}icon-cross{{/if}}"></i></div>' +
            '</div>' +
            '<div class="email-opted-in-field-value edit-mode-only">' +
                '<input type="checkbox" class="editable-field field-checkbox checkbox-switch-control field-email-opted-in" id="{{id}}" {{#if optedIn}}checked{{/if}}/>' +
                '<label for="{{id}}" class="editable-field checkbox-switch"></label>' +
            '</div>' +
        '</div>' +
        '</label>'),
    templateHelpers: function() {
        return {
            id: this.options.field + '_id',
            label: this.options.label,
            optedIn: !this.model.get(this.options.field)
        };
    },
    events: {
        'change .field-email-opted-in': function (ev) {
            var unsubscribedAll = !$(ev.currentTarget).is(':checked');
            this.model.get(this.options.field, unsubscribedAll);
            this.options.controller.addChange(this.options.field, unsubscribedAll);
        }
    }
});

CommunicationsListView = Marionette.Layout.extend({
    template: Handlebars.compile(template),
    itemView: CommunicationItemView,
    regions: {
        websiteRegion: '.website-container',
        phoneListRegion: '.phone-list-container',
        emailListRegion: '.email-list-container',
        emailOptedInRegion: '.email-opted-in-container',
        messageOptedInRegion: '.message-opted-in-container',
        socialListRegion: '.social-list-container'
    },
    initialize: function(options) {
        this.controller = options.controller;
        this.listenTo(this.collection, 'reset', function() {
            this.showRegions();
        }, this);
    },
    onRender: function() {
        this.showRegions();
    },
    showRegions: function() {
        var website = this.collection.find(function(com) { return com.get('medium') === 'website'; });
        if (website && website.get('value') !== '') {
            this.websiteRegion.show(new WebsiteView({
                model: website
            }));
        }
        else {
            this.websiteRegion.reset();
        }

        if (this.options.entityModel && this.options.entityModel.get('type') === 'individuals') {
            this.emailOptedInRegion.show(new OptedInView({
                model: this.options.entityModel,
                controller: this.controller,
                label: TextManager.getText('ID_EMAIL_OPTED_IN'),
                field: 'unsubscribed_all'
            }));
            this.messageOptedInRegion.show(new OptedInView({
                model: this.options.entityModel,
                controller: this.controller,
                label: TextManager.getText('ID_TEXTING_OPTED_IN'),
                field: 'unsubscribed_all_messages'
            }));
        } else {
            this.emailOptedInRegion.reset();
            this.messageOptedInRegion.reset();
        }

        this.phoneListRegion.show(new MediumListView({
            itemView: CommunicationItemView,
            controller: this.controller,
            originalCollection: this.collection,
            medium: 'phone'
        }));

        this.emailListRegion.show(new MediumListView({
            itemView: CommunicationItemView,
            controller: this.controller,
            originalCollection: this.collection,
            medium: 'email',
            checkExistenceFor: this.options.checkEmailExistenceFor
        }));

        this.socialListRegion.show(new MediumListView({
            itemView: SocialItemView,
            controller: this.controller,
            originalCollection: this.collection,
            medium: 'social'
        }));
    }
});

export default CommunicationsListView;

import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import vent from 'js/vent'
import logoSettingsTemplate from 'templates/settings/advanced_settings_logo.handlebars'
import LibraryBrowser from 'js/react_views/library-browser/browser'

export default Marionette.Layout.extend({
    className: 'edit-logo-settings',
    template: Handlebars.compile(logoSettingsTemplate),
    ui: {
        logo_action: '#logo-action',
        logo: '.logo',
        error_messages: '.error-message',
        upload_image_container: '.upload-image-container',
        logo_delete: '.logo-delete'
    },
    events: {
        'click .save': function() {
            var client = app.user.get('client');

            if (this.image) {
                var logoAction = this.ui.logo_action.val().trim();

                if (logoAction && !this.isValidUrl(logoAction)) {
                    this.ui.logo_action
                        .addClass('validation_error')
                        .nextAll('.error-message')
                        .text('Invalid URL.')
                        .addClass('invalid');
                }
                this.updateClientLogo(client, this.image.id, logoAction);
            } else {
                this.updateClientLogo(client, null, null);
            }
        },
        'click .logo-delete': function() {
            this.image = null;
            this.showImagePreview();
        },
        'click .logo-reload': function() {
            this.showLibraryBrowser(true);
        },
        'click .image': function() {
            this.showLibraryBrowser(true);
        }
    },
    url: '/content_files',
    onRender: function() {
        const view = this,
            client = app.user.get('client'),
            logoData = client.logo,
            logoActionUrl = client.logo_action_url;

        this.error_messages.remove.call(this);

        if (!_.isEmpty(logoData)) {
            this.ui.logo_action.val(logoActionUrl);

            view.image = logoData;
            view.showImagePreview();
        } else {
            this.image = null;
            this.showImagePreview();
        }
    },
    showImagePreview: function() {
        if (this.image) {
            var imgSrc = '';

            if (this.image.ext === '.svg') {
                $.ajax({
                    url: this.image.url,
                    type: 'GET',
                    async: false,
                    success: function (data) {
                        // Remove any characters outside the Latin1 range
                        var decoded =  unescape(encodeURIComponent(data));
                        var base64 = btoa(decoded);
                        imgSrc = `data:image/svg+xml;base64,${base64}`;
                    }
                });

            }
            else {
                imgSrc = this.image.url;
            }

            this.ui.logo.attr('src', imgSrc);
            this.ui.upload_image_container.hide();
            this.ui.logo_delete.show();
        } else {
            this.ui.upload_image_container.show();
            this.ui.logo_delete.hide();
            this.ui.logo.attr('src', null);
        }
    },
    onFileSelected: function(file) {
        this.image = file;
        this.showLibraryBrowser(false);
        this.showImagePreview();
    },
    validateFile: function(file) {
        const fileExtension = file.lastIndexOf('.');
        const ext = (fileExtension !== -1 ? file.substr(fileExtension + 1) : '').toLowerCase();

        if (['jpg', 'jpeg', 'png', 'svg'].indexOf(ext) === -1) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'Supported formats are: JPEG, JPG, SVG and PNG.',
                        classes: 'saved error',
                        timer: 3000
                    };
                }
            });

            return false;
        }

        return true;
    },
    showLibraryBrowser: function(visible) {
        ReactDOM.render(
            <div>
                {visible &&
                    <LibraryBrowser
                        folderId='root'
                        parent={this}
                        onCancel={() => this.showLibraryBrowser(false)}
                        onFileSelected={this.onFileSelected.bind(this)}
                        validateFile={this.validateFile.bind(this)}
                    />
                }
            </div>,
            this.$el.find('.library-browser').get(0)
        );
    },
    updateClientLogo: function(client, logoId, logoActionUrl) {
        var view = this;

        $.ajax({
            type: 'PATCH',
            url: '/clients/' + client.id,
            data: JSON.stringify({
                logo_id: logoId,
                logo_action_url: logoActionUrl,
            }),
            contentType: 'application/json',
            dataType: 'json',
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Changes saved.',
                            classes: 'saved success',
                            timer: 3000
                        };
                    }
                });

                view.trigger('cancel');
            },
            error: function() {
                client.preferences[itemId] = newValue ? 'false' : 'true';
                $(ev.target).prop('checked', !newValue);
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Unable to save changes.',
                            classes: 'saved error',
                            timer: 3000
                        };
                    }
                });
            }
        });
    },
    isValidUrl: function(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;  
        }
      
        return true;
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import ModalRegion from 'js/views/base/modal-region'
import MessageBox from 'js/views/message_box'
import MessageViewer from 'js/views/activity/message-viewer'
import template from 'templates/activity/message-item.handlebars'
import security from 'js/utils/security'
import app from 'js/app'

export default BaseItemView.extend({
    template: Handlebars.compile(template),
    regions: {
        messageViewer: {
            selector: '.message-viewer',
            regionType: ModalRegion.extend()
        }
    },
    ui: {
        activityPinned: '.activity-pinned',
        iconPinned: '.icon-pinned',
        iconUnpinned: '.icon-unpinned'
    },
    events: _.extend({
        'click .view': function(ev) {
            ev.stopPropagation();

            var viewer = new MessageViewer({model: this.model});
            var view = this;

            this.listenTo(viewer, 'message-viewer:close', function() {
                view.messageViewer.reset();
            });

            this.messageViewer.show(viewer);
        },
        'click .delete.has-permission': function() {
            var self = this;
            var mbContent = {
                accept_is_negative: true,
                message: 'Are you sure you want to <strong>permanently</strong> delete the activity?',
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.$el
                    .animate({ opacity: 0 }, { queue: false, duration: 200 })
                    .slideUp(250, 'easeOutQuint', function() {
                        self.trigger('deleteItem');
                    });
            });
        },
        'click .pin.has-permission': function() {
            this.trigger('togglePin');
        }
    }, BaseItemView.prototype.events),
    templateHelpers: function() {
        var createdDate = this.model.get('created');
        var params = this.model.get('params') || {};

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var ownerId = this.model.get('owner_id');

        if (!ownerId) {
            var owner = this.model.get('owner');

            if (owner) {
                ownerId = owner['id'];
            }
        }

        var note = this.model.get('note');
        var maxNoteLen = 200;

        if (note.length > maxNoteLen) {
            var ellipsis = '...';
            note = note.substring(0, maxNoteLen - ellipsis.length) + ellipsis;
        }

        var numberAttachments = null;

        if (params.attachments_ids) {
            var len = params.attachments_ids.length;
            numberAttachments = len + (len === 1 ? ' file' : ' files');
        }

        var author;
        var recipient;

        if (params.from_user) {
            author = params.from_user;
            recipient = this.model.get('individual_name');
        } else {
            author = this.model.get('individual_name');
        }

        var related = this.model.get('related');
        var allowEdit = (app.user.get('id') === ownerId) || app.user.get('is_helper');

        if (!allowEdit && related) {
            allowEdit = security.checkPermission('edit', {
                id: app.user.get('id'),
                permissions: related.permissions
            });
        }

        return {
            author: author,
            recipient: recipient,
            formattedDate: createdDate,
            body: note,
            individualDeleted: this.model.get('individual_deleted'),
            editable: this.options.parentModel,
            allowEdit: allowEdit,
            pinned: this.model.get('pinned'),
            numberAttachments: numberAttachments
        };
    },
    onRender: function () {
        this.updatePinView();
    },
    updatePinView: function() {
        var pinned = this.model.get('pinned');

        this.ui.activityPinned.toggleClass('hide', !pinned);
        this.ui.iconPinned.toggleClass('hide', !pinned);
        this.ui.iconUnpinned.toggleClass('hide', pinned);
    }
});

import BaseCollection from 'js/collections/base'
import LeadModel from 'js/models/lead'


var LeadsCollection = BaseCollection.extend({
    urlRoot: function() {
        return '/pdcrm_dashboard_leads';
    },
    model: LeadModel
});
export default LeadsCollection;

import _ from 'underscore';
import Marionette from 'Backbone.Marionette';

import AppConfig from 'app/app-config'
import IndividualModel from 'js/models/contact';
import OrganizationModel from 'js/models/organization';
import OpportunityModel from 'js/models/opportunity';
import UserModel from 'js/models/user';
import dateFormat from 'js/utils/date-format'
import vent from 'js/vent';
import TextManager from 'app/text-manager'
import CustomFieldsCollection from 'js/collections/custom_fields';

var modelByType = {
    individual: IndividualModel,
    organization: OrganizationModel,
    opportunity: OpportunityModel,
    user: UserModel
};

const BaseContainerView = Marionette.Layout.extend({
    initialize: function() {
        this.relatedData = {};
        this.relatedItemsCache = {
            individual: {},
            organization: {},
            opportunity: {},
            user: {}
        };
    },
    updateRelatedData: function(data) {
        _.extend(this.relatedData, data);
        this.render();
    },
    fetchCustomFields: function(callback) {
        if (this.customFields) {
            callback(this.customFields);
        }

        this.customFields = new CustomFieldsCollection(app.globalData.customFieldsInfo[`${this.containerType}Array`]);
        callback(this.customFields);
    },
    fetchCustomFieldEntities: function(fields, callback) {
        var remainingModels = 0;
        var numModelsFetches = 0;

        _.each(fields, (field) => {
            if (this.relatedItemsCache[field.type][field.value]) {
                return;
            }

            let valueParts = [];
            if (field.params && field.params.multiSelect) {
                valueParts = field.value.split(',');
            }
            else {
                valueParts = [field.value];
            }

            _.each(valueParts, (valueId) => {
                var model = new modelByType[field.type]({ id: valueId });
                var self = this;

                ++remainingModels;
                ++numModelsFetches

                if (field.isUsingDefaultValueMergeTag) {
                    _.defer(function() {
                        model.set('is_using_default_value_merge_tag', true);
                        self.relatedItemsCache[field.type][valueId] = model;

                        --remainingModels;
                        if (remainingModels === 0) {
                            callback();
                        }
                    });
                }
                else {
                    model.fetch({
                        success: function() {
                            self.relatedItemsCache[field.type][valueId] = model;
                        },
                        error: function(_, response) {
                            if (response.status === 404) {
                                model.set('deleted', true);
                            } else {
                                model.set('permission_required', true);
                            }
                            self.relatedItemsCache[field.type][valueId] = model;
                        },
                        complete: function() {
                            --remainingModels;
                            if (remainingModels === 0) {
                                callback();
                            }
                        }
                    });
                }
            });
        });

        if (numModelsFetches === 0) {
            callback();
        }
    },
    fetchTeamsByUser(callback) {
        let teamsByUserId = {};
        const getUsersId = (team) => {
            let usersId = [];

            for (const child of team.children) {
                usersId.push(child.user_id);

                if (child.children.length > 0) {
                    usersId = usersId.concat(getUsersId(child));
                }
            }

            return usersId;
        };

        for (let team of app.globalData.teams) {
            const usersId = getUsersId(team);

            for (const userId of usersId) {
                if (!(userId in teamsByUserId)) {
                    teamsByUserId[userId] = [];
                }

                if (teamsByUserId[userId].indexOf(team.id) === -1) {
                    teamsByUserId[userId].push(team.id);
                }
            }
        }

        callback(teamsByUserId);
    },
    validateCustomFields: function(processedCustomFields, attrs) {
        var errors = {};

        _.forEach(processedCustomFields, function(group) {
            _.forEach(group.fields, function(field) {
                if (field.required) {
                    var value = null;
                    var cfid = `custom_field.${field.id}`

                    if (cfid in attrs) {
                        value = attrs[cfid]
                    }

                    if (_.isNull(value) || _.isUndefined(value) || (value === '')) {
                        errors[field.id] = field.label + ' is required';
                    }
                }
            });
        });

        if (this.containerType === 'individuals' && AppConfig.getValue('individuals.edit.checkIfCustomFieldInactiveSwitchedOn')) {
            const customFields = app.globalData.customFieldsInfo.individuals;

            let inactiveCFId = null;
            let inactiveDetailsCFId = null;
            let inactiveReasonsCFId = null;

            for (const cfId in customFields) {
                if (customFields[cfId].name.toLowerCase() === 'inactive') {
                    inactiveCFId = cfId;
                }
                if (customFields[cfId].name.toLowerCase() === 'inactive details') {
                    inactiveDetailsCFId = cfId;
                }
                if (customFields[cfId].name.toLowerCase() === 'inactive reasons') {
                    inactiveReasonsCFId = cfId;
                }

                if (inactiveCFId && inactiveDetailsCFId && inactiveReasonsCFId) {
                    break;
                }
            }

            if (inactiveCFId && attrs[`custom_field.${inactiveCFId}`]) {
                if (inactiveDetailsCFId && ! (`custom_field.${inactiveDetailsCFId}` in attrs)) {
                    errors[inactiveDetailsCFId] =  'Inactive details is required';
                }

                if (inactiveReasonsCFId && ! (`custom_field.${inactiveReasonsCFId}` in attrs)) {
                    errors[inactiveReasonsCFId] =  'Inactive reasons is required';
                }
            }
        }

        return errors;
    },
    triggerSaveErrorAlert: function(entity, response) {
        var message = 'There was an error processing this request';

        try {
            var errorDetail = JSON.parse(response.responseText).detail;
            if (errorDetail) {
                if (errorDetail.exception === 'OpportunityPhaseGateNotClear') {
                    message = errorDetail.message.replace('opportunity', TextManager.getText('ID_DEAL'))
                } else {
                    message = errorDetail.exception + ' (' + errorDetail.message + ')';
                }
            }
        }
        catch(e) {}

        vent.trigger('alert:show', {
            type: function() {
                return {
                    message: 'Error saving ' + entity + ': ' + message,
                    classes: 'save-error error',
                    timer: 5000
                };
            }
        });
    },
    processCustomFields: function(callback) {
        var view = this;
        var processedCustomFields = null;

        var updateGroupUserSettings = function(id, settings) {
            var preferences = app.user.get('preferences');

            preferences.customFieldGroupMap = preferences.customFieldGroupMap || {};
            preferences.customFieldGroupMap[id] = _.extend({}, preferences.customFieldGroupMap[id], settings);
            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

            var group = _.findWhere(processedCustomFields, {id: id});

            if (group) {
                group = _.extend(group, settings);
                view.updateRelatedData({ processedCustomFields: processedCustomFields });
            }

        };

        var updateGroupUserOrder = function(items) {
            var preferences = app.user.get('preferences');
            preferences.customFieldGroupMap = preferences.customFieldGroupMap || {};
            processedCustomFields = [];
            _.each(items, function(item, ind) {
                preferences.customFieldGroupMap[item.id] = _.extend({}, preferences.customFieldGroupMap[item.id], { ind: ind });
                processedCustomFields.push(item);
            });
            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
            view.updateRelatedData({ processedCustomFields: processedCustomFields });
        };

        var manageGroupVisibility = function(customFieldId, customFieldValue) {
            var client = app.user.get('client');
            var groups = (client.preferences && client.preferences.customFieldGroups &&
                         JSON.parse(client.preferences.customFieldGroups)) || [];
            var userCustomFieldGroupMap = app.user.get('preferences').customFieldGroupMap || {};
            var group = groups.find(g => g.id === this.id);

            if (!group) {
                return;
            }

            if (group.visible_if && group.visible_if.custom_field_id === customFieldId) {
                this.hidden = ((userCustomFieldGroupMap[group.id] && userCustomFieldGroupMap[group.id].state === 'hidden')) ||
                              (group.visible_if.value !== customFieldValue);
            }
        };

        var groupFields = function(fields, teamsByUserId) {
            var customFieldGroups = [];
            var fieldMap = {};
            var fieldInGroup = {};

            _.each(fields, function(field) {
                fieldMap[field.id] = field;
            });

            var client = app.user.get('client');
            var groups = client.preferences && client.preferences.customFieldGroups &&
                         JSON.parse(client.preferences.customFieldGroups);
            var userCustomFieldGroupMap = app.user.get('preferences').customFieldGroupMap || {};

            _.each(groups, function(group) {
                var fields = [];

                _.each(group.fields, function(fieldId) {
                    fieldInGroup[fieldId] = true;
                    if (fieldId in fieldMap) {
                        fields.push(fieldMap[fieldId]);
                    }
                });

                var groupHidden = ((userCustomFieldGroupMap[group.id] && userCustomFieldGroupMap[group.id].state === 'hidden')) ||
                                  (group.visible_if && (!fieldMap[group.visible_if.custom_field_id] || fieldMap[group.visible_if.custom_field_id].value_id !== group.visible_if.value));


                const userTeams = teamsByUserId[app.user.get('id')] || [];
                let hiddenIn = {
                    view: false,
                    edit: false
                };

                for (const str of ['view', 'edit']) {
                    const key = `hidden_in_${str}_mode`;
                    const ginfo = group[key];

                    if (ginfo) {
                        if (_.isObject(ginfo)) {
                            if ('unless_teams_ids' in ginfo) {
                                hiddenIn[str] = true;

                                for (const teamId of ginfo.unless_teams_ids) {
                                    if (userTeams.indexOf(teamId) !== -1) {
                                        hiddenIn[str] = false;
                                        break;
                                    }
                                }
                            } else if ('teams_ids' in ginfo) {
                                for (const teamId of ginfo.teams_ids) {
                                    if (userTeams.length === 0 || userTeams.indexOf(teamId) !== -1) {
                                        hiddenIn[str] = true;
                                        break;
                                    }
                                }
                            } else if ('function' in ginfo) {
                                hiddenIn[str] = AppConfig.getValue(`custom_field_groups_map.${key}.${ginfo.function}`, false);
                            }
                        } else {
                            hiddenIn[str] = true;
                        }
                    }
                }

                customFieldGroups.push({
                    ind: userCustomFieldGroupMap[group.id] && userCustomFieldGroupMap[group.id].ind,
                    id: group.id,
                    name: group.name,
                    fields: fields,
                    update: updateGroupUserSettings,
                    updateOrder: updateGroupUserOrder,
                    manageGroupVisibility: manageGroupVisibility,
                    state: userCustomFieldGroupMap[group.id] && userCustomFieldGroupMap[group.id].state,
                    hidden: groupHidden,
                    hiddenInViewMode: hiddenIn.view,
                    hiddenInEditMode: hiddenIn.edit
                });
            });

            customFieldGroups.push({
                ind: userCustomFieldGroupMap['other'] && userCustomFieldGroupMap['other'].ind,
                id: 'other',
                name: 'Additional Information',
                fields: _.filter(fields, function(field) { return !(field.id in fieldInGroup); }),
                update: updateGroupUserSettings,
                updateOrder: updateGroupUserOrder,
                manageGroupVisibility: manageGroupVisibility,
                state: userCustomFieldGroupMap['other'] && userCustomFieldGroupMap['other'].state
            });

            return _.sortBy(customFieldGroups, 'ind');
        };

        this.fetchCustomFields(function() {
            var fields = [];
            var entityFields = [];
            var modelIsNew = view.model.isNew();

            _.forEach(view.customFields.models, function(field) {
                var id = field.get('id');
                var type = field.get('type');
                var params = field.get('params');
                var out = {
                    id: id,
                    label: field.get('name'),
                    type: type,
                    required: field.get('required'),
                    params: params
                };

                if (type === 'dropDown') {
                    out.options = field.get('optionsArray') || field.get('options');
                }

                var value = null;
                var originalValue = null;

                if (modelIsNew) {
                    var valueDef = field.get('value');
                    var value = null;
                    var isUsingDefaultValue = false;
                    var isUsingDefaultValueMergeTag = false;

                    if (valueDef.default_merge_tag) {
                        value = valueDef.default_merge_tag;
                        isUsingDefaultValueMergeTag = true;
                    } else if (valueDef.default !== null && !_.isUndefined(valueDef.default) && valueDef.default !== "") {
                        value = valueDef.default;
                        isUsingDefaultValue = true;

                        if (field.get('type') === 'date') {
                            value = dateFormat.ISODate(value);
                        }

                        if (field.get('type') === 'checkbox') {
                            value = value === 'true';
                        }
                    }

                    if (value !== null) {
                        originalValue = value;
                        out.isUsingDefaultValue = isUsingDefaultValue;
                        out.isUsingDefaultValueMergeTag = isUsingDefaultValueMergeTag;
                    }
                } else {
                    var cfKey = `custom_field.${id}`;

                    if (cfKey in view.model.attributes) {
                        value = view.model.get(cfKey);
                        originalValue = value;
                    }
                }

                if (!_.isUndefined(value) && value !== null) {
                    if (type === 'dropDown') {
                        if (out.isUsingDefaultValueMergeTag) {
                            var newValue = _.clone(out.options[0]);

                            for (var k in newValue) {
                                newValue[k] = value;
                            }

                            value = newValue;
                        } else {
                            value = _.findWhere(out.options, {id: value});
                        }

                        if (value) {
                            out.value_id = value.id;
                            value = value.value;
                        }
                    }

                    out.value = value;
                    out.originalValue = originalValue;

                    if (type === 'url' && params && params.show_preview && params.preview_height > 0) {
                        // This regexp matches one or more letters, followed by
                        // a colon and two forward slashes, anchored at the beginning
                        // of the string, i.e. a protocol specification,
                        // such as 'http://'
                        if (!value.match(/^[a-zA-Z]+:\/\//)) {
                            // if the protocol is not set, we use https
                            out.params = _.extend(out.params, {
                                previewUrl: 'https://' + value
                            });
                        }
                        else if (value.indexOf('http://') === -1) {
                            // if the protocol is not http, show the iframe with the url as-is
                            // else don't show at all
                            out.params = _.extend(out.params, {
                                previewUrl: value
                            });
                        }
                    }
                    if (_.has(modelByType, out.type)) {
                        entityFields.push(out);
                    }
                }

                fields.push(out);
            });

            view.fetchTeamsByUser(function(teamsByUserId) {
                view.fetchCustomFieldEntities(entityFields, function() {
                    _.each(entityFields, function(field) {
                        if (field.params && field.params.multiSelect) {
                            let valueParts = [];
                            let entityItems = [];
                            valueParts = field.value.split(',');

                            for (const itemId of valueParts) {
                                let entity = view.fetchEntity(field.type, itemId);
                                entityItems.push({
                                    id: itemId,
                                    type: field.type,
                                    title: entity.value,
                                });
                            }

                            field.value = entityItems;
                        } else {
                            let entity = view.fetchEntity(field.type, field.value);

                            for (const [key, value] of Object.entries(entity)) {
                                field[key] = value;
                            }
                        }
                    });

                    processedCustomFields = groupFields(fields, teamsByUserId);
                    view.updateRelatedData({ processedCustomFields: processedCustomFields });

                    if (callback) {
                        callback();
                    }
                });
            });
        });
    },
    fetchEntity(entityType, entityId) {
        let entity = {};

        let model = this.relatedItemsCache[entityType][entityId];

        if (model.get('deleted')) {
            entity.value = null;
            entity.originalValue = null;
        } else {
            const permissionRequired = model.get('permission_required');
            const isUsingDefaultValueMergeTag = model.get('is_using_default_value_merge_tag');

            entity.itemId = entityId;

            if (isUsingDefaultValueMergeTag) {
                entity.value = model.get('id');
            }
            else if (entityType === 'individual') {
                if (permissionRequired) {
                    entity.value = TextManager.parseText('Hidden ${ID_INDIVIDUAL, capitalize}');
                } else {
                    entity.value = model.get('full_name');
                    entity.photoUrl = model.get('photo_url');
                    if (model.get('organization')) {
                        entity.organizationName = model.get('organization').name;
                    }
                }
            }
            else if (_.has(modelByType, entityType)) {
                if (permissionRequired) {
                    switch(entityType) {
                        case 'opportunity':
                            entity.value = TextManager.parseText('Hidden ${ID_DEAL, capitalize}');
                            break;
                        case 'organization':
                            entity.value = TextManager.parseText('Hidden ${ID_ORGANIZATION, capitalize}');
                            break;
                        default:
                            entity.value = 'Hidden';
                            break;
                    }
                } else {
                    entity.value = model.get('name');
                }
            }
        }

        return entity;
    }
});

export default BaseContainerView;
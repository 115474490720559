import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handsontable2 from 'handsontable'

import vent from 'js/vent.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import dateFormat from 'js/utils/date-format.js'
import ForecastOpportunityCollection from 'js/collections/forecast_deals.js'
import BucketModel from 'js/models/bucket.js'
import OpportunityModel from 'js/models/opportunity.js'
import Currency from 'js/utils/currency.js'
import MessageBox from 'js/views/message_box.js'
import createErrorTemplate from 'templates/forecasts/create-error.handlebars'


var statusTitles= {
        'none': TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}'),
        'none_upside': TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}'),
        'committed_downside': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}'),
        'committed': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
    },
    invertStatusTitles = _.invert(statusTitles),
    dealColumnTemplate = Handlebars.compile(
        '<div class="delete-item delete-item-icon" title="Remove Row">' +
            '<div></div>' +
        '</div>' +
        '<span>{{value}}</span>'
    );


export default Marionette.Layout.extend({
    className: 'handsontable-container',
    template: Handlebars.compile('<div id="forecast-table" style="overflow: scroll; height: 100%; width: 100%;"></div>'),
    ui: {
        table: '#forecast-table'
    },
    initialize: function() {
        // render table in cases when parent rendered
        this.listenTo(this.options.parent, 'show', function() {
            this.renderTable({ weighted: this.options.editing ? false : true, editing: this.options.editing });
        }, this);

        // re-render when toggling weighted and unweighted modes
        this.listenTo(this.options.controller, 'weighted', function(weighted) {
           this.renderTable({ weighted: weighted });
        }, this);

        // render editable table view
        this.listenTo(this.options.controller, 'editing:start', function() {
            this.renderTable({ editing: true });
        }, this);

        // when selecting several opportunities from opportunity adding multi select
        this.listenTo(this.options.parent, 'add-deal', function(idList) {
            this.fetchOpportunities(idList);
        }, this);

        // re render table in cases of new forecast when opportunities added automatically by period
        this.listenTo(this.options.controller, 'opportunities-from-period', function() {
            this.renderTable({ editing: true });
        }, this);

        // set conversions for new forecasts
        if (!this.options.model.get('currencies_conversion')) {
            var currencyConversion = _.extend({}, app.user.get('client').currencies) || {};
            currencyConversion.currency = app.user.get('client').default_currency;
            this.options.model.set('currencies_conversion', currencyConversion);
            this.options.controller.addChange('currencies_conversion', currencyConversion);
        }
    },
    onRender: function() {
        this.renderTable({ editing: this.options.editing, weighted: this.options.controller.isWeighted() });
    },
    renderTable: function (options) {
        if (this.handsonTable) {
            this.handsonTable.destroy();
        }
        var readOnly = options.editing ? false : true,
            self = this,
            currencyConversion = this.options.model.get('currencies_conversion'),
            currency = this.options.model.get('currencies_conversion').currency,
            settings = {
                colHeaders: true,
                fixedColumnsLeft: 1,
                columnSorting: true,
                fillHandle: false
            },
            data = [],
            colWidths = [200, 200, 100, 100, 120, 120, 100],
            colHeaders = [TextManager.parseText('${ID_DEAL, capitalize}'), TextManager.parseText('${ID_ORGANIZATION, capitalize}'), "Funnel",
                          TextManager.parseText('${ID_PHASE, capitalize}'), "Close", TextManager.parseText('${ID_STATUS, capitalize}'), "Weight"],
            columns = [
                {
                    data: 'name',
                    readOnly: true,
                    renderer: function(instance, td, row, col, prop, value) {
                        if (options && options.editing) {
                            $(td).html(dealColumnTemplate({ value: value }))
                                .find('.delete-item')
                                .on('click', function() {
                                    var opportunityId = instance.getDataAtRowProp(row, 'opportunityId');
                                    self.removeRow(row, opportunityId);
                                });
                        }
                        else {
                            $(td).text(value);
                        }
                        return td;
                    }
                },
                {
                    data: 'organization',
                    readOnly: true
                },
                {
                    data: 'funnel',
                    readOnly: true
                },
                {
                    data: 'phase',
                    readOnly: true
                },
                {
                    data: 'closed',
                    readOnly: true,
                    type: 'date',
                    dateFormat: 'mm dd yy'
                },
                {
                    data: 'status',
                    type: 'dropdown',
                    source: _.values(statusTitles),
                    readOnly: readOnly
                },
                {
                    data: 'weight',
                    readOnly: readOnly,
                    validator: function(value, callback) {
                        if (value >= 0 && value <= 1) {
                            callback(true);
                        }
                        else {
                            callback(false);
                        }
                    },
                    allowInvalid: false
                }
            ];

        this.currency = currency;
        this.currencyConversion = currencyConversion;

        // create column definition for each bucket
        self.options.buckets.each(function(bucket) {
            colHeaders.push(bucket.get('name'));
            colWidths.push(100);
            columns.push({
                data: bucket.get('id'),
                type: 'numeric',
                readOnly: readOnly,
                renderer: function(instance, td, row, col, prop, value) {
                    value = parseFloat(value) || 0;
                    if (options && options.editing) {
                        currency = instance.getDataAtRowProp(row, 'opportunityCurrency');
                    }
                    else {
                        value = value / instance.getDataAtRowProp(row, 'conversion');
                        if (options && options.weighted) {
                            value = value * instance.getDataAtRowProp(row, 'weight');
                        }
                    }
                    $(td).text(Currency.format(currency, value));
                    return td;
                }
            });
        });

        // format data for use in table
        _.each(this.options.model.get('forecast_opportunities'), function(forecastOpportunity) {
            var total = 0,
                opportunity = forecastOpportunity.opportunity,
                opportunityBuckets = forecastOpportunity.forecast_opportunity_buckets,
                // TODO: forecast opportunity currency should be taken from stored opportunity not from current opportunity
                opportunityCurrency = opportunity.currency,
                org = opportunity.organization,
                item = {
                    'name': opportunity.name,
                    'organization': org ? org.name : opportunity.organization_name || '',
                    'funnel': opportunity.funnel.name,
                    'phase': opportunity.phase.name,
                    'closed': dateFormat.shortFormatWithYear(opportunity.expected_close_date),
                    'status': statusTitles[forecastOpportunity.status],
                    'weight': forecastOpportunity.weight
                };

            // ignore forecast opportunities which doesn't have conversion rates, normally this shouldn't happen
            if (opportunityCurrency === currency || opportunityCurrency in currencyConversion) {
                // following 3 items will not be visible as their columns are not defined
                item.conversion = opportunityCurrency === currency ? 1 : currencyConversion[opportunityCurrency];
                item.opportunityId = opportunity.id;
                item.opportunityCurrency = opportunityCurrency;

                self.options.buckets.each(function (bucket) {
                    var opportunityBucket = _.find(opportunityBuckets, function (b) {
                        return b.bucket_id === bucket.get('id');
                    });
                    var value = opportunityBucket ? opportunityBucket.value : 0;
                    item[bucket.get('id')] = value;
                    total += value;
                });

                total = total / item.conversion;
                item.total = total;
                data.push(item);
            }
        });

        colHeaders.push("Total");
        colWidths.push(100);
        columns.push({
            data: 'total',
            type: 'numeric',
            readOnly: true,
            renderer: function(instance, td, row, col, prop, value) {
                var total = value;

                if (options && options.weighted) {
                    total = total * instance.getDataAtRowProp(row, 'weight');
                }
                $(td).text(Currency.format(currency, total));
                return td;
            }
        });

        settings.data = data;
        settings.colHeaders = colHeaders;
        settings.colWidths = colWidths;
        settings.columns = columns;

        // main function for handling all changes in table
        settings.afterChange = function(changes, source) {
            // we need to handle only edit events
            if (source !== 'edit') {
                return;
            }

            // normally changes will have single value
            _.each(changes, function(change) {
                var rowId = change[0],
                    columnName = change[1],
                    value = change[3],
                    opportunityId = this.getDataAtRowProp(rowId, 'opportunityId'),
                    FCOpportunity = _.find(self.options.model.get('forecast_opportunities'), function(fopp) {
                        return fopp.opportunity_id === opportunityId;
                    }),
                    bucket;

                if (columnName === 'weight') {
                    FCOpportunity.weight = value;
                }
                else if (columnName === 'status') {
                    FCOpportunity.status = invertStatusTitles[value];
                }
                else {
                    bucket = _.find(FCOpportunity.forecast_opportunity_buckets, function (b) {
                        return b.bucket_id === columnName;
                    });
                    bucket.value = value;
                }
            }, this);
            self.options.controller.addChange('forecast_opportunities', self.options.model.get('forecast_opportunities'));
        };

        this.handsonTable = new Handsontable2(this.ui.table[0], settings);
        this.handsonTable.render();
    },
    /**
     * We need to fetch opportunities as we only get ids from multi select when adding deals.
     * @param idList    array of ids
     */
    fetchOpportunities: function(idList) {
        var self = this,
            models = _.map(idList, function(id) { return new OpportunityModel({id: id}); });

        // we ask for all opportunities separately
        // TODO: we need to crate backend endpoint for retrieving opportunities by id list
        $.when.apply(this, _.invoke(models, 'fetch')).done(function() {
            var invalidList = [],
                validModels = [];

            // we can only add these opportunities which currencies are in forecast currency conversion table
            _.each(models, function(model) {
                var currency = model.get('currency');
                if ((currency !== self.currencyConversion.currency) && (!(currency in self.currencyConversion))) {
                    invalidList.push({ deal: model, currency: currency });
                }
                else {
                    validModels.push(model);
                }
            });

            if (invalidList.length > 0) {
                var errorLine3Id = app.user.get('is_admin') ? 'ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_3_ADMIN' : 'ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_3';

                var message = {
                        errorMessageLine1: TextManager.getText('ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_1', ['${ID_DEAL, pluralize=' + invalidList.length + '}']),
                        errorMessageLine2: TextManager.getPluralizedText('ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_2', invalidList.length),
                        errorMessageLine3: TextManager.getPluralizedText(errorLine3Id, invalidList.length),
                        deals: invalidList
                    },
                    template = Handlebars.compile(createErrorTemplate),
                    mbContent = {
                        message: template(message),
                        icon: 'icon-blocked'
                    };

                MessageBox.showOk(mbContent, self);
            }

            self.addRows(validModels);
        });
    },
    /**
     * Extend forecast opportunity list and re render table.
     *
     * @param opportunityModels
     */
    addRows: function(opportunityModels) {
        var FCOpportunities = this.options.model.get('forecast_opportunities');
        this.options.parent.extendFCOpportunities(opportunityModels, FCOpportunities);
        this.options.parent.model.set('forecast_opportunities', FCOpportunities);
        this.options.controller.addChange('forecast_opportunities', FCOpportunities);
        this.renderTable({ editing: true });
    },
    /**
     * Remove a single row both from table and from forecast opportunity list.
     *
     * @param rowId
     * @param opportunityId
     */
    removeRow: function(rowId, opportunityId) {
        var FCOpportunities = this.options.model.get('forecast_opportunities'),
            idx = 0;
        _.find(FCOpportunities, function(FCO, i) { idx = i; return FCO.opportunity_id ===  opportunityId; });
        FCOpportunities.splice(idx, 1);
        this.options.parent.model.set('forecast_opportunities', FCOpportunities);
        this.options.controller.addChange('forecast_opportunities', FCOpportunities);
        this.handsonTable.alter('remove_row', rowId);
    }
});

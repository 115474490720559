import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import AppConfig from 'app/app-config';


export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('conversations');

    let columns = [
        columnsCreator.text('name',    'Name',    { sortable: false, filterable: false, groupable: false }),
        columnsCreator.date('created', 'Created', { sortable: false, sortableAnniversary: false, filterable: false, groupable: false }),
    ];

    return columns;
};

export function getDefaultColumnsIds() {
    return [
        'created',
        'name'
    ];
};
export default function (collection, groupList) {
    const customUserGroups = groupList
        .filter(item => !!item?.id && !['my_groups', 'other_groups'].includes(item?.id))
        .map(item => item.groups)
        .flat();

    const myGroups = collection
        .filter(item => item.get('owner_id') === app.user.get('id') &&
            !customUserGroups.includes(item.get('id')))
        .sort((a, b) => b.get('created')?.getTime() - (a.get('created')?.getTime()))
        .map(item => item.get('id'));

    const otherGroups = collection
        .filter(item => item.get('owner_id') !== app.user.get('id') &&
            !customUserGroups.includes(item.get('id')) &&
            !item.get('is_adhoc'))
        .sort((a, b) => b.get('created')?.getTime() - a.get('created')?.getTime())
        .map(item => item.get('id'));

    groupList = groupList.filter(item => !myGroups.includes(item) && !otherGroups.includes(item));

    const myGroupsId = groupList.findIndex(item => item?.id === 'my_groups');
    if (myGroupsId !== -1) {
        const myGroupsNew = myGroups.filter(item => !groupList[myGroupsId].groups.includes(item));
        groupList[myGroupsId].groups = myGroupsNew.concat(groupList[myGroupsId].groups);
        groupList[myGroupsId].disableDraggable = true;
    } else {
        groupList.splice(0, 0, {
            id: 'my_groups',
            name: 'My Groups',
            open: false,
            groups: myGroups,
            disableDraggable: true,
        });
    }

    const otherGroupsId = groupList.findIndex(item => item?.id === 'other_groups');
    if (otherGroupsId !== -1) {
        const otherGroupsNew = otherGroups.filter(item => !groupList[otherGroupsId].groups.includes(item));
        groupList[otherGroupsId].groups = otherGroupsNew.concat(groupList[otherGroupsId].groups);
        groupList[otherGroupsId].disableDraggable = true;
    } else {
        groupList.splice(1, 0, {
            id: 'other_groups',
            name: 'Other Groups',
            open: false,
            groups: otherGroups,
            disableDraggable: true,
        });
    }

    return groupList;
}
import _ from 'underscore'

import AppConfig from 'app/app-config'
import IndividualListingBaseView from 'app/_components/IOD-listing/IOD/individual-listing/individual-listing-base'
import TableBodyContainerView from 'js/views/base/table-body-container-view'


var BaseOverviewView = IndividualListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            customFields: this.customFields,
            type: this.options.type,
            elementType: this.options.elementType,
            filter: this.filter,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                newButton: true
            },
            defaultColumns: [
                'photo_url',
                'created',
                'source.name',
                'first_name',
                'last_name',
                'organization.name',
                'email',
                'phone',
                'became_lead_date'
            ],
            defaultSort: { direction: false, field: ["became_lead_date"] }
        });
    }
});


export default IndividualListingBaseView.extend({
    OverviewView: OverviewView,
    getUrl: function() {
        return 'leadIndividuals';
    }
});

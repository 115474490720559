import Backbone from 'backbone'
import _ from 'underscore'
import Handlebars from 'handlebars'

import CampaignListingBaseView from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-listing-base'
import IndividualFavoritesModel from 'js/models/individual_favorites'
import CampaignFinder from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-finder'
import CampaignSummarySheet from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-summary'
import ListingCampaignTableView from 'app/_components/IOD-listing/_components/listing-campaign-table'
import CampaignPerformanceView from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-performance'
import ModalRegion from 'js/views/base/modal-region'
import ItemPermissionsView from 'js/views/item_permissions';
import app from 'js/app'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import FilterQuickOptionsCampaigns from 'js/views/filters/quick-options-campaigns'
import CampaignFilterModel from 'js/models/campaign_filter'


var BaseOverviewView = CampaignListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    className: 'sent-campaign-overview',
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: !this.options.isGroup,
            hideFilters: false,
            disableLayoutSelection: true,
            comments: this.options.group ? this.options.group.get('comments') : '',
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    regions: _.extend({
        widgetsRegion: '.widgets-area',
        titleWidgetsRegion: '.title-widgets-area',
        summaryRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
    }, BaseOverviewView.prototype.regions),
    groupType: 'Campaigns',
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new CampaignFilterModel({id: this.options.filterId || this.filter_id});
        this.quickFilter = new FilterQuickOptionsCampaigns();

        var fetchOptions = {
            status: 'sent',
            search_not: 'template'
        };

        if (this.options.group) {
            fetchOptions.group_id = this.options.group.get('id');
        }

        this.fetchOptions = _.extend(fetchOptions, this.fetchOptions);
        this.initialFetchOptions = _.clone(this.fetchOptions);
    },
    onRender: function() {
        BaseOverviewView.prototype.onRender.apply(this, arguments);

        var campaignPerformance = new CampaignPerformanceView({
            parent: this,
            fetchOptions: this.fetchOptions
        });

        this.listenTo(campaignPerformance, 'update-filters', function(filterOptions) {
            this.performanceFilters = _.clone(filterOptions);
            this.fetchOptions = _.extend(_.clone(this.initialFetchOptions), filterOptions);

            if (this.finderFilters) {
                this.fetchOptions = _.extend(this.fetchOptions, this.finderFilters);
            }

            this.fetchCollection();
        });

        this.widgetsRegion.show(campaignPerformance);

        // ...
        var campaignFinder = new CampaignFinder();

        this.listenTo(campaignFinder, 'update-filters', function(filterOptions) {
            this.finderFilters = _.clone(filterOptions);
            this.fetchOptions = _.extend(_.clone(this.initialFetchOptions), filterOptions);

            if (this.performanceFilters) {
                this.fetchOptions = _.extend(this.fetchOptions, this.performanceFilters);
            }

            campaignPerformance.setFetchOptions(this.fetchOptions);
            this.fetchCollection();
        });

        this.titleWidgetsRegion.show(campaignFinder);
    },
    onSelectItem: function(model) {
        this.options.parent.showItem(model, null, true);
    },
    onCampaignPreview: function(model) {
        this.options.parent.showItem(model, null, true);
    },
    onCampaignShowPermissions: function(model) {
        if (this.aclRegion && model.id) {
            var ipv = new ItemPermissionsView({model: model});
            this.aclRegion.show(ipv);
            this.listenTo(ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    onDelete: function(model) {
        this.collection.remove(model);
        model.destroy();
    },
    createTableView: function() {
        if (true) { // _.contains(app.user.get('preferences').lab_flags, 'SAL-3826')) {
            var isGroup = this.options.isGroup;

            return new TableBodyContainerView({
                parent: this,
                collection: this.collection,
                type: 'sent_campaigns',
                elementType: this.options.elementType,
                tableModel: this.model,
                fixedWhenDefaultColumns: true,
                showBarRow: true,
                availableColumns: [
                    'name',
                    'subject',
                    'to_group_sent',
                    'sent',
                    'email_event_stats_send',
                    'email_event_stats_delivered',
                    'email_event_stats_open',
                    'email_event_stats_click',
                    'email_event_stats_unsubscribed',
                    'email_event_stats_spam',
                    'email_event_stats_bounce',
                    'from_user_sent',
                    'email_event_stats_open_rate',
                    'email_event_stats_click_rate',
                    'email_event_stats_delivered_rate',
                    'email_event_stats_hard_bounce',
                    'email_event_stats_soft_bounce',
                    'email_event_stats_unsubscribed_all',
                    'email_event_stats_unsubscribed_list'
                ],
                defaultColumns: [
                    'name',
                    'subject',
                    'to_group_sent',
                    'sent',
                    'tags',
                    'funnels',
                    'email_event_stats_send',
                    'email_event_stats_delivered',
                    'email_event_stats_open',
                    'email_event_stats_click',
                    'email_event_stats_unsubscribed',
                    'email_event_stats_spam',
                    'email_event_stats_bounce',
                ],
                addItemSelectText: 'name',
                addItemSelectPlaceholder: 'Search for a campaign',
                addItemSelectUrl: '/campaigns?status=sent&search_not=template',
                disableRadioSelect: true,
                buttons: {
                    addButton: isGroup
                }
            });
        }
        else {
            return new ListingCampaignTableView({
                parent: this,
                model: this.model,
                collection: this.collection,
                availableColumns: [
                    'menu',
                    'icon',
                    'name',
                    'subject',
                    'sent_to',
                    'date',
                    'sent',
                    'delivered',
                    'opened',
                    'clicked',
                    'unsub',
                    'spam',
                    'bounce',
                    'sent_from',
                    'open_rate',
                    'click_rate',
                    'delivered_rate',
                    'hard_bounce',
                    'soft_bounce',
                    'unsubscribed_all',
                    'unsubscribed_list'
                ],
                defaultColumns: [
                    'menu',
                    'subject',
                    'icon',
                    'name',
                    'sent_to',
                    'date',
                    'sent',
                    'delivered',
                    'opened',
                    'clicked',
                    'unsub',
                    'spam',
                    'bounce'
                ],
                extraRow: 'click-rate-bar',
                fixedHeader: true,
                sortId: 'date'
            });
        }
    },
    delete: function() {
        this.options.parent.trigger('group:delete');
    },
    startEditing: function() {
        this.options.parent.trigger('group:edit');
    },
    permissions: function() {
        this.options.parent.trigger('group:permissions');
    },
    newItem: function() {
        this.options.parent.trigger('group:new-item', 'drafts');
    },
    addItem: function(model) {
        this.options.parent.trigger('group:add-item', model);
    },
    onDuplicateItem: function(model) {
        if (this.options.isGroup) {
            this.options.parent.trigger('group:add-item', model);
        } else {
            this.fetchCollection(true);
        }
    },
    onCampaignShowSummarySheet: function(model) {
        var summaryView = new CampaignSummarySheet({
            campaign: model
        });

        this.summaryRegion.show(summaryView);

        this.listenTo(summaryView, 'close', function() {
            this.summaryRegion.reset();
        });
    },
    getMenuOptions: function() {
        var hasEditPermission = false;

        if (this.options.isGroup) {
            hasEditPermission = security.checkPermission('edit', this.options.group);
        }

        return {
            canEdit: hasEditPermission,
            hasPermissions: hasEditPermission && app.user.get('client').permission_type !== 'rba',
            canDelete: hasEditPermission,
            disableSave: false,
            hasFieldGroup: true,
            canDownloadAsCSV: true,
            canShareGroup: hasEditPermission
        };
    }
});

export default CampaignListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);

        this.model = new Backbone.Model({ name: this.options.name, id: "sent", tags: this.options.tags });
        this.model.local = true;
        this.model.doNotPersistSort = true;
        this.model.doNotPersistColumns = true;
    },
    onRender: function() {
        CampaignListingBaseView.prototype.onRender.apply(this);

        this.$el.find('.detail-name').tooltip();
    },
    getUrl: function() {
        var url = 'sent';

        if (this.options.group) {
            url += `/groups/${this.options.group.get('id')}`;
        }

        if (this.campaignRegion && this.campaignRegion.currentView) {
            url += '/' + this.campaignRegion.currentView.getUrl();
        }
        return url;
    },
    getElementType: function() {
        return 'sent_campaigns';
    }
});

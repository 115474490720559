import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'

import D3RevenueCampaignChart from 'js/d3/revenuecampaignchart'
import revenueCampaignTemplate from 'templates/revenue_campaign.handlebars'


export default Marionette.Layout.extend({
    className: 'revenue-campaign-widget widget at-top',
    template: Handlebars.compile(revenueCampaignTemplate),
    ui: {
        chartContainer: '.chart-container',
        header: '.widget-header',
        footer: '.widget-footer',
        scroll: '.content-container > .content'
    },

    events: {
        'group_highlighted': function(w, g) {
            this.trigger('group_highlighted', this, g);
        },

        'group_selected': function(w, g) {
            this.trigger('group_selected', this, g);
        },

        'click .widget-header .expand': 'toggleExpand',

        'click .widget-header .back': 'toggleExpand',

        'resize': 'scrollbar'
    },

    initialize: function(options) {
        this.chart = new D3RevenueCampaignChart();
        this.groups = options.groups;
        this.data = [];
    },

    onRender: function() {
        this.fetchGroupsData();

        this.ui.scroll
            .scroll(this.scrollEvents.bind(this))
            .scrollLock(); // Prevent scroll propagation

        var view = this;

        // Resize chart on window resize
        this.resizeCallback = function () {
            if (view.data) {
                _.defer(function() {
                    view.renderWidget();
                }.bind(view));
            }
        };
        $(window).on('resize', this.resizeCallback);
    },

    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },

    renderWidget: function() {
        // var itemMinHeight = 30 * (this.expanded ? 2 : 1);

        if (this.isClosed) {
            return;
        }

        if (this.expanding) {
            this.chart.expand(false);
        }
        else {
            this.chart.expand(this.expanded);
        }

        this.chart.width(this.ui.chartContainer.width());
        // this.chart.height((this.groups.length*itemMinHeight) + itemMinHeight);

        this.chart.data(this.data);

        d3.select(this.ui.chartContainer.get(0)).call(this.chart);

        this.scrollbar();
    },

    onGroupsDataChanged: function(newGroups) {
        this.chart.empty();
        this.groups = newGroups;
        this.fetchGroupsData();
    },

    onGroupHighlighted: function(group) {
        if (this.data) {
            this.chart.highlightSingleBar(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    onGroupSelected: function(group) {
        if (this.data) {
            this.chart.selectSingleBar(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    fetchGroupsData: function() {
        if (!this.groups) {
            return;
        }

        var view = this;

        $.post(
            '/widgets/rev_cost_campaign',
            JSON.stringify({
                groups: this.groups
            }),
            function(data) {
                if (!view.isClosed) {
                    view.onGroupSelected(null);
                    view.data = data.data;
                    view.renderWidget();
                }
            }
        );
    },

    toggleExpand: function() {
        // Prevent double expanding - breaks chain of animation
        if (this.expanding) {
            return;
        }

        if (!this.expanded) {
            this.trigger('group_selected', this, null);
        }

        this.expanding = true;
        this.renderWidget();

        // Once all parent containers have animated
        function afterParentExpand() {
            this.expanding = false;
            this.expanded = !this.expanded;
            this.renderWidget();
        }

        this.trigger('expand', this, afterParentExpand.bind(this));
    },

    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            footerHeight = this.ui.footer.is(':visible') ? this.ui.footer.height() : 0,
            availableHeight = (this.$el.height() - this.ui.header.height() - footerHeight),
            innerHeight = container.find('.content-inner:first').height();

        // container.css('maxHeight', height);
        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),

    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

import Backbone from 'backbone'
import _ from 'underscore'
import Handlebars from 'handlebars'

import CampaignListingBaseView from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-listing-base'
import IndividualFavoritesModel from 'js/models/individual_favorites'
import CampaignFinder from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-finder'
import app from 'js/app'
import AppConfig from 'app/app-config'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import security from 'js/utils/security'
import MessageBox from 'js/views/message_box'
import TextManager from 'app/text-manager'
import ModalRegion from 'js/views/base/modal-region';
import ItemPermissionsView from 'js/views/item_permissions';
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import ListingCampaignTableView from 'app/_components/IOD-listing/_components/listing-campaign-table'
import FilterQuickOptionsCampaigns from 'js/views/filters/quick-options-campaigns'
import CampaignFilterModel from 'js/models/campaign_filter'


var BaseOverviewView = CampaignListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: !this.options.isGroup,
            hideFilters: false,
            disableLayoutSelection: true,
            noTitleMenu: false,
            comments: this.options.group ? this.options.group.get('comments') : '',
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    regions: _.extend({
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        titleWidgetsRegion: '.title-widgets-area'
    }, BaseOverviewView.prototype.regions),
    groupType: 'Campaigns',
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new CampaignFilterModel({id: this.options.filterId || this.filter_id});
        this.quickFilter = new FilterQuickOptionsCampaigns();

        this.listenTo(vent, 'campaign:new', function(model) {
            this.collection.add(model, {at: 0});

            if (this.options.group) {
                this.addItem(model);
            }
        });

        this.listenTo(vent, 'campaign:launch', function(model) {
            this.collection.remove(model);

            if (this.options.group) {
                this.addItem(model);
            }
        });

        var fetchOptions = {
            status: 'ready,draft'
        };

        if (this.options.group) {
            fetchOptions.group_id = this.options.group.get('id');
        }

        this.fetchOptions = _.extend(fetchOptions, this.fetchOptions);
        this.initialFetchOptions = _.clone(this.fetchOptions);
    },
    onRender: function() {
        BaseOverviewView.prototype.onRender.apply(this, arguments);

        var campaignFinder = new CampaignFinder();

        this.listenTo(campaignFinder, 'update-filters', function(filterOptions) {
            this.fetchOptions = _.extend(_.clone(this.initialFetchOptions), filterOptions);
            this.fetchCollection();
        });

        this.titleWidgetsRegion.show(campaignFinder);
    },
    createTableView: function() {
        if (true) { // _.contains(app.user.get('preferences').lab_flags, 'SAL-3826')) {
            var isGroup = this.options.isGroup;
            var options = {
                parent: this,
                collection: this.collection,
                type: this.options.type,
                elementType: this.options.elementType,
                tableModel: this.model,
                addItemSelectText: 'name',
                addItemSelectPlaceholder: 'Search for a campaign',
                addItemSelectUrl: '/campaigns?status=ready,draft',
                disableRadioSelect: true,
                buttons: {
                    newButton: true,
                    addButton: isGroup
                }
            };

            const listOrder = AppConfig.getValue('campaigns.draft_list_order');

            if (listOrder) {
                options.defaultSort = {
                    field: listOrder[0],
                    direction: listOrder[1]
                };
            }

            return new TableBodyContainerView(options);
        }
        else {
            var listingCampaignTableView = {
                parent: this,
                model: this.model,
                collection: this.collection,
                fixedHeader: true
            }

            const listOrder = AppConfig.getValue('campaigns.draft_list_order');

            if (listOrder) {
                listingCampaignTableView.sortId = listOrder[0];
                listingCampaignTableView.sortDirection = listOrder[1];
            }

            return new ListingCampaignTableView(listingCampaignTableView);
        }
    },
    delete: function() {
        this.options.parent.trigger('group:delete');
    },
    startEditing: function() {
        this.options.parent.trigger('group:edit');
    },
    permissions: function() {
        this.options.parent.trigger('group:permissions');
    },
    newItem: function() {
        this.options.parent.trigger('group:new-item', 'drafts');
    },
    addItem: function(model) {
        this.options.parent.trigger('group:add-item', model);
    },
    onDuplicateItem: function(model) {
        if (this.options.isGroup) {
            this.options.parent.trigger('group:add-item', model);
        } else {
            this.fetchCollection(true);
        }
    },
    // custom parametrize as "," in "ready,draft" gets encoded otherwise
    customParametrize: function(fetchOptions) {
        let options = _.clone(fetchOptions);

        if (!options.filter_id) {
            delete options.filter_id;
        }

        return _.reduce(options, function(res,el,key) { return res + key + '=' + el + '&'; }, '').slice(0, -1);
    },
    onSelectItem: function(model) {
        this.onCampaignEdit(model);
    },
    onCampaignPreview: function(model) {
        this.options.parent.showItem(model, null, true);
    },
    onCampaignShowPermissions: function(model) {
        if (this.aclRegion && model.id) {
            var ipv = new ItemPermissionsView({model: model});
            this.aclRegion.show(ipv);
            this.listenTo(ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    onCampaignEdit: function(model) {
        if (!security.checkPermission('edit', model)) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: "You don't have permission to edit this " + TextManager.getText('ID_CAMPAIGN'),
            }, this);

            return;
        }

        var self = this,
            content = {
                message: 'It is recommended that you pause the automation linked to this email before making any changes to it. <br>' +
                'Do you still want to open this email for editing?',
                icon: 'icon-warning'
            };

        if (model.get('used_in_live_automations')) {
            MessageBox.showYesNo(
                content,
                this,
                function () { // Yes
                      self.options.parent.showItem(model);
                }
            );
        }
        else {
            self.options.parent.showItem(model);
        }
    },
    onDelete: function(model) {
        this.collection.remove(model);
        model.destroy();
    },
    getMenuOptions: function() {
        var hasEditPermission = false;

        if (this.options.isGroup) {
            hasEditPermission = security.checkPermission('edit', this.options.group);
        }

        return {
            canEdit: hasEditPermission,
            hasPermissions: hasEditPermission && app.user.get('client').permission_type !== 'rba',
            canDelete: hasEditPermission,
            disableSave: false,
            hasFieldGroup: true,
            disableColumnsConfiguration: true,
            canShareGroup: hasEditPermission
        };
    }
});

export default CampaignListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);

        this.model = new Backbone.Model({ name: this.options.name, id: "drafts", tags: this.options.tags });
        this.model.local = true;
        this.model.doNotPersistSort = true;
    },
    onRender: function() {
        CampaignListingBaseView.prototype.onRender.apply(this);

        this.$el.find('.detail-name').tooltip();
    },
    getUrl: function() {
        var url = 'drafts';

        if (this.options.group) {
            url += `/groups/${this.options.group.get('id')}`;
        }

        if (this.campaignRegion && this.campaignRegion.currentView) {
            url += '/' + this.campaignRegion.currentView.getUrl();
        }
        return url;
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'

import BaseSocialItemView from 'js/views/base/social_item.js'
import facebookItemTemplate from 'templates/social/facebook_item.handlebars'


var FacebookItemView = BaseSocialItemView.extend({
    template: Handlebars.compile(facebookItemTemplate),
    className: 'facebook-item',
    // TODO: extend function from
    // BaseSocialItemView.prototype.templateHelpers
    templateHelpers: function () {
        var model = this.model,
            super_helpers = BaseSocialItemView.prototype.templateHelpers;
        return _.extend({
            assignee: model.get('owner') ? model.get('owner').name : '',
            likesLink: function() {
                var likes = model.get('likes');
                if (likes) {
                    if (likes.length > 1) {
                        return likes.length + ' Likes';
                    } else {
                        return likes.length + ' Like';
                    }
                } else {
                    return null;
                }
            },
            commentsLink: function() {
                var comments = model.get('comments');
                if (comments) {
                    if (comments.length > 1) {
                        return comments.length + ' Comments';
                    } else {
                        return comments.length + ' Comment';
                    }
                } else {
                    return null;
                }
            }
        }, super_helpers.apply(this, arguments));
    },
    ui: _.extend({
        likes: '.likes-list',
        comments: '.comments-list',
        likesLink: '.likes-link',
        commentsLink: '.comments-link'
    }, BaseSocialItemView.prototype.ui),
    events: _.extend({
        'click .facebook-post-content p a': 'followLink',
        'click .likes-link': 'toggleLikes',
        'click .comments-link': 'toggleComments'
    }, BaseSocialItemView.prototype.events),
    followLink: function(ev) {
        var target = ev.currentTarget,
            link = $(target).attr('href');
        ev.preventDefault();
        ev.stopPropagation();

        window.location=link;
        return false; // Prevents error (browser crashing) ??
    },
    toggleLikes: function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.ui.comments.slideUp(200);
        this.ui.likes.slideToggle(200);
        this.ui.commentsLink.removeClass('active');
        this.ui.likesLink.toggleClass('active');
    },
    toggleComments: function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.ui.likes.slideUp(200);
        this.ui.comments.slideToggle(200);
        this.ui.likesLink.removeClass('active');
        this.ui.commentsLink.toggleClass('active');
    }
});

export default FacebookItemView;

import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import security from 'js/utils/security'
import AddItemView from 'js/views/base/add-item'
import AssignView from 'js/views/social/assign'
import Utilities from 'js/utils/utilities'
import baseToolbarTemplate from 'templates/base/toolbar.handlebars'


var Tools = Backbone.Model.extend(),
BaseToolbarView = Marionette.Layout.extend({
    className: 'toolbar',
    template: Handlebars.compile(baseToolbarTemplate),
    initialize: function(modelProperties) {
        var view = this;

        this.model = new Tools(modelProperties);

        this.listenTo(
            this.model,
            'change:selectedCount',
            function(model, selectedCount) {
                view.selectedCounter(selectedCount);
            }
        );

        Marionette.Layout.prototype.initialize.apply(this, arguments);
    },
    onRender: function() {
        var $active;

        // May need refactored...
        if (this.model.get('tools').listSorter) {
            $active = this.$el.find(
                    '.list-sorter-' + this.model.get('section')
                );
            $active.addClass('active');
            this.selected = $active.children().data('event');
        }
    },
    regions: {
        subToolsContainer   : '.sub-tools-container'
    },
    ui: {
        hiddenTool          : '.tool-hide',
        tools               : '.tools',
        toolbar             : '.extra-tools',
        listSorter          : '.list-sorter',
        checkbox            : '.master-checkbox',
        toggleCollapse      : '.toggle-collapse',
        expandList          : '.expand-list',
        selectionMessage    : '.selection-message'
    },
    events: {
        'click .add-item'              : 'showAddItemView',
        'click .new-item'              : 'newItem',
        'click .delete-selected'       : 'deleteSelected',
        'click .remove-selected'       : 'removeSelected',
        'click .merge-selected'        : 'mergeSelected',
        'click .edit-selected'         : 'editSelected',
        'click .move-selected'         : 'toggleGroupBrowser',
        'click .custom-action-selected': 'customAction',
        'click .master-checkbox'       : 'selectToggle',
        'click .list-sorter nav'       : 'toggleDropdown',
        'click .list-sorter a'         : 'listSorterSelect',
        'click .toggle-extra-tools'    : 'toggleExtraTools',
        'click .toggle-collapse'       : 'toggleCollapse',
        'click .expand-list'           : 'expandList',
        'click .aggregate-selected'    : 'aggregateSelected',
        'click .assign-selected'       : 'showAssignView',
        'click .select-all-items'      : 'selectAllItems'
    },
    showSubTools: function(popoverView, button) {
        var view = this;

        $(button).parent().addClass('active');
        this.$el.parent().addClass('sub-tools-expanded');
        popoverView.$el.addClass('bottom');

        if (this.subToolsContainer.currentView !== popoverView) {
            this.subToolsContainer.show(popoverView);
        }

        if (popoverView.select) {
            popoverView.select.$el.select2('open');
        }

        $('body').on('mousedown.close-tools', function(ev) {
            if(!$(ev.target).is('.sub-tools-container > *') &&
               !$(ev.target).closest('.sub-tools-container > div').length
            ) {
                view.hideSubTools();
            }
        });
    },
    hideSubTools: function() {
        $('body').off('mousedown.close-tools');
        if (this.isClosed) {
            return;
        }
        this.ui.hiddenTool.removeClass('active');
        this.$el.parent().removeClass('sub-tools-expanded');
        this.subToolsContainer.currentView.$el.find('input').blur();
    },
    toggleSubTools: function(popoverView, button) {
        if (this.$el.parent().hasClass('sub-tools-expanded')) {
            this.hideSubTools();
        } else {
            this.showSubTools(popoverView, button);
        }
    },
    showAddItemView: function(ev) {
        ev.stopPropagation();
        if (this.addItemView) {
            this.toggleSubTools(this.addItemView, ev.currentTarget);
        }
        else {
            var options = this.options.addItemOptions || {};
            options.ItemModel = this.options.collection.model;

            this.addItemView = new AddItemView(options);
            this.listenTo(this.addItemView, 'add-item', function(model) {
                if(security.checkPermission('edit',model))
                {
                    this.trigger('toolbar:add-item', model);
                    this.$el.find('.add-item').parent().removeClass('active');
                }
                else
                {
                    this.addItemView.showPermissionAlert(model);
                }
                this.hideSubTools();
            });
            this.showSubTools(this.addItemView, ev.currentTarget);
        }
    },
    newItem: function(ev) {
        var args = {
            end: true
        };
        ev.preventDefault();
        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('toolbar:new', args);
        });

    },
    deleteSelected: function() {
        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('toolbar:delete', this.selection);

            this.trigger('toolbar:delete-bulk', {
                allItemsSelected: this.allItemsSelected,
                numItemsSelected: this.allItemsSelected ? this.allItemsSelectedCount : this.selection.length,
                selection: this.selection
            });
        });
    },
    removeSelected: function() {
        var self = this;
        this.trigger(
            'toolbar:remove',
            {
                allItemsSelected: this.allItemsSelected,
                numItemsSelected: this.allItemsSelected ? this.allItemsSelectedCount : this.selection.length,
                items: this.selection,
                success: function() {
                    self.toggleTools(0); // zero items selected after removing
                }
            }
        );
    },
    mergeSelected: function() {
        this.trigger('toolbar:merge', {
            elementType: this.options.collection.model.prototype.name.toLowerCase(),
            selection: this.selection
        });
    },
    editSelected: function() {
        this.trigger('toolbar:edit', {
            allItemsSelected: this.allItemsSelected,
            numItemsSelected: this.allItemsSelected ? this.allItemsSelectedCount : this.selection.length,
            selection: this.selection
        });
    },
    toggleGroupBrowser: function() {
        // Load modal of groups list
        // 'Create new group' displays input for new group name
        // Search for group filters list
    },
    customAction: function() {
        var self = this;
        this.trigger(
            'toolbar:customAction',
            {
                items: this.selection,
                success: function() {
                    self.selectToggle(null, true);
                }
            }
        );
    },
    disableSelect: function() {
        var checkbox = this.ui.checkbox;

        if (checkbox.hasClass('all-selected')) {
            checkbox.removeClass('all-selected');
        }
    },
    selectToggle: function(ev, forceUnselect) {
        var checkbox = this.ui.checkbox;
        var unselect = forceUnselect || checkbox.hasClass('all-selected');

        if (ev) {
            ev.preventDefault();
        }

        if (unselect) {
            this.trigger('toolbar:deselectAllPage');
            this.resetAllSelection();
        }
        else {
            this.trigger('toolbar:selectAllPage');
            this.onAllPageSelected();
        }
        checkbox.toggleClass('all-selected', !unselect);
    },
    selectedCounter: function(selected) {
        this.toggleTools(selected);
    },
    toggleTools: function(selected) {
        if (selected >= 1 && this.ui.hiddenTool.hasClass('tool-hide')) {
            this.ui.hiddenTool.removeClass('tool-hide').addClass('tool-show');
        }
        else if (selected === 0) {
            this.ui.hiddenTool.removeClass('tool-show').addClass('tool-hide');
        }
    },
    toggleDropdown: function(ev) {
        var view = this;
        ev.preventDefault();
        ev.stopPropagation();
        function toggleExpand() {
            view.ui.listSorter.toggleClass('expanded');
        }
        if (!this.dropdownExpanded) {
            $(document).on('mousedown.close-dropdown', function() {
                toggleExpand();
                $(this).off('mousedown.close-dropdown');
                view.dropdownExpanded = false;
            });
        } else {
            $(document).off('mousedown.close-dropdown');
        }
        this.dropdownExpanded = !this.dropdownExpanded;
        toggleExpand();
    },
    listSorterSelect: function(ev) {
        var view = this,
            $row = $(ev.currentTarget),
            currentEl = $row.parent(),
            dataEvent = $row.data('event');
        ev.preventDefault();
        function bubbleEvent () {
            view.$el.trigger('toolbar:sort-list', dataEvent);
        }
        if (this.selected === dataEvent) {
            return;
        }
        this.selected = dataEvent;
        this.$el.find('.list-sorter li').not(currentEl)
            .removeClass('active');
        currentEl.addClass('active', bubbleEvent);
    },
    toggleExtraTools: function(ev) {
        var view = this;
        ev.preventDefault();
        ev.stopPropagation();
        function toggleExpand() {
            view.ui.toolbar.toggleClass('expanded');
            $(ev.currentTarget).toggleClass('active');
        }
        if (!this.toolbarExpanded) {
            $(document).on('mousedown.close-toolbar', function() {
                toggleExpand();
                $(this).off('mousedown.close-toolbar');
                view.toolbarExpanded = false;
            });
        } else {
            $(document).off('mousedown.close-toolbar');
        }
        this.toolbarExpanded = !this.toolbarExpanded;
        toggleExpand();
    },
    toggleCollapse: function(ev) {
        $(ev.currentTarget).find('i')
            .toggleClass('icon-angle-left icon-angle-right');
        this.$el.trigger('toolbar:toggle-collapse');
    },
    expandList: function() {
        this.$el.trigger('toolbar:expand-list');
    },
    aggregateSelected: function () {
        var self = this;

        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('toolbar:aggregate', {
                items:this.selection,
                success: function() {
                    self.toggleTools(0);
                }
            });
        });
    },
    setSelection: function(sel) {
        this.resetAllSelection();
        this.selection = sel;
        this.model.set('selectedCount', sel.length);
    },
    resetAllSelection: function() {
        if (!this.options.tools.selectAll) {
            return;
        }

        this.ui.selectionMessage.parent().toggleClass('hide', true);
        this.allItemsSelected = false;
        this.adjustListContainer(0);
    },
    onAllPageSelected: function() {
        if (!this.options.tools.selectAll) {
            return;
        }

        var numSelected = this.selection ? this.selection.length : 0;

        if (numSelected > 0) {
            var totalNumItems = this.options.collection.total || this.options.collection.length;

            if (numSelected === totalNumItems) {
                this.selectAllItems();
            }
            else {
                var adjusted = this.adjustListContainer(2);
                var model = this.options.collection.model;
                var elementType = this.options.collection.model.prototype.name.toLowerCase();
                var elementTypePlural = elementType + 's';

                this.ui.selectionMessage.parent().toggleClass('hide', false);
                this.ui.selectionMessage.html(
                    'The <b>' + numSelected + '</b> ' + (numSelected > 1 ? elementTypePlural : elementType) + ' on this page are selected. ' +
                    (adjusted ? '<br>' : '') +
                    '<span class="select-all-items">Select all <b>' + Utilities.numberWithCommas(totalNumItems) + '</b> ' +
                    (totalNumItems > 1 ? elementTypePlural : elementType) + '</span>'
                );
            }
        }
    },
    selectAllItems: function() {
        if (!this.options.tools.selectAll) {
            return;
        }

        this.allItemsSelected = true;
        this.allItemsSelectedCount = this.options.collection.total || this.options.collection.length;

        if (this.selection.length) {
            this.adjustListContainer(1);

            var elementType = this.options.collection.model.prototype.name.toLowerCase();
            var elementTypePlural = elementType + 's';

            this.ui.selectionMessage.parent().toggleClass('hide', false);
            this.ui.selectionMessage.html('All <b>' + Utilities.numberWithCommas(this.allItemsSelectedCount) + '</b> ' +
                (this.allItemsSelectedCount > 1 ? elementTypePlural : elementType) + ' selected');
        }
    },
    adjustListContainer: function(numSelectionMessageLines) {
        if (!this.options.tools.selectAll) {
            return false;
        }

        if (this.ui.selectionMessage.parent().hasClass('newline')) {
            var listContainer = this.$el.parent().parent().next();
            var newLineActive = numSelectionMessageLines > 0;

            listContainer.removeClass('toolbar-multiline');
            listContainer.removeClass('toolbar-multiline-higher');

            if (newLineActive) {
                listContainer.addClass((numSelectionMessageLines === 1) ? 'toolbar-multiline' : 'toolbar-multiline-higher');
            }

            this.$el.toggleClass('no-bottom-separator', newLineActive);
            return true;
        }

        return false;
    },
    setCollectionCount: function(count) {
        if(count === 0 && this.options.tools.hideIfEmpty) {
            this.$el.addClass('empty-list');
        } else {
            this.$el.removeClass('empty-list');
        }
    },
    showAssignView: function(ev) {
        ev.stopPropagation();
        if (this.assignView) {
            this.toggleSubTools(this.assignView, ev.currentTarget);
        }
        else {
            this.assignView = new AssignView({multiple_assign: true});
            this.listenTo(this.assignView, 'assign-user', function(userModel) {
                this.trigger('toolbar:assign', userModel, this.selection);
                this.$el.find('.add-item').parent().removeClass('active');
            });
            this.listenTo(this.assignView, 'unassign-selected', function() {
                this.trigger('toolbar:unassign', this.selection);
            });
            this.showSubTools(this.assignView, ev.currentTarget);
        }
    }
});

export default BaseToolbarView;

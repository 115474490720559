import _ from 'underscore'

import BaseCollection from 'js/collections/base'
import Forecast from 'js/models/forecast'


var ForecastsCollection = BaseCollection.extend({
    model: Forecast,
    getUniqueName: function(name) {
        // returns name if it is unique, or name [number] if exist duplicates
        var maxNumber = 0;
        var idx = name.lastIndexOf(' ');

        if (idx != -1) {
            var nn = parseInt(name.substring(idx).trim());

            if (!isNaN(nn)) {
                name = name.substring(0, idx);
                maxNumber = nn;
            }
        }

        _.each(this.models, function(model) {
            var fullname = model.get('name');
            var lastIdx = fullname.lastIndexOf(' ');
            var last = (lastIdx != -1) ? fullname.substring(lastIdx).trim() : null;
            var checkName = true;

            if (last) {
                var number = parseInt(last);

                if (!isNaN(number)) {
                    var n = fullname.substring(0, lastIdx);

                    if ((n === name) && (number >= maxNumber)) {
                        maxNumber = number + 1;
                        checkName = false;
                    }
                }
            }

            if (checkName && (fullname === name) && (maxNumber === 0)) {
                maxNumber = 1;
            }
        });

        return name + ((maxNumber === 0) ? '' : (' ' + maxNumber));
    }
});
export default ForecastsCollection;

import _ from 'underscore';
import $ from 'jquery';
import Marionette from 'Backbone.Marionette';
import Handlebars from 'handlebars';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager';
import vent from 'js/vent';
import security from 'js/utils/security';
import IndividualFlexView from 'js/react_views/individual/flex-view';
import IndividualFlexEditView from 'js/react_views/individual/flex-edit';
import TaskModel from 'js/models/task'
import ActivityModel from 'js/models/activity'
import CommunicationModel from 'js/models/communication';
import CommunicationCollection from 'js/collections/communications';
import IndividualsCollection from 'js/collections/contacts';
import OrganizationModel from 'js/models/organization';
import OpportunitiesCollection from 'js/collections/organizations';
import RelatedFilesCollection from 'js/collections/related_files';
import GroupsCollection from 'js/collections/groups';
import UsersCollection from 'js/collections/users.js';
import ChecklistsCollection from 'js/collections/checklists.js';
import MessageBox from 'js/views/message_box';
import ModalRegion from 'js/views/base/modal-region';
import ItemPermissionsView from 'js/views/item_permissions';
import DocusignPrepareView from 'js/views/individuals/docusign-prepare';
import BaseContainerView from 'js/react_containers/BaseContainerView';
import HistoryView from 'js/react_views/history/history';
import AppointmentView from 'js/react_views/appointment/appointment';
import CredasView from 'js/react_views/credas/credas';


const DEALS_PER_PAGE = 20;
const INDIVIDUALS_PER_PAGE = 20;
const FILES_PER_PAGE = 10;

var IndividualContainerView = BaseContainerView.extend({
    regions: {
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        docusignRegion: {
            selector: '.dummy-docusign-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        historyRegion: {
            selector: '.history-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        appointmentRegion: {
            selector: '.appointment-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        credasRegion: {
            selector: '.credas-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
    },
    initialize: function(options) {
        BaseContainerView.prototype.initialize.apply(this, arguments);

        this.containerType = 'individuals';
        this.controller = options.controller;
        this.communicationCollection = new CommunicationCollection(this.model.get('communication'));
        this.conditionalFlags = {};

        var isNew = this.model.isNew();
        var self = this;

        this.editing = isNew;
        this.invalidFieldErrors = null;
        this.individualIsProtected = false;

        this.listenTo(this.model, 'sync', function() {
            this.processCustomFields(function() {
                self.render();
            });
        });
        this.listenTo(this.model, 'change', function() {
            this.render();
        });

        this.listenTo(vent, 'update-related-files', function() {
            this.fetchRelatedFiles();
        });

        this.listenTo(vent, 'update-checklist-progress', function() {
            this.fetchChecklists();
        });

        if (!isNew) {
            this.checkIndividualIsProtected();
            this.fetchOrganizationDeals();
            this.fetchRelatedDeals();
            this.fetchRelatedIndividuals();
            this.fetchRelatedFiles();
            this.fetchGroups();
            this.checkDocusignConnected();
            this.fetchChecklists();
        } else {
            this.processCustomFields(function() {
                self.render();
            });
        }

        this.individuals = {};
        this.organizations = {};
        this.archiveEmailAddress = 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
    },
    fetchChecklists: function() {
        const self = this;

        app.shortTermCache.get(`/individuals/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            if (data.length > 0) {
                self.checklists = new ChecklistsCollection(data).models;
                self.render();
            }
        });
    },
    fetchRelatedFiles: function(start) {
        var files = new (RelatedFilesCollection.extend({
            urlRoot: () => `/individuals/${this.model.get('id')}/related_files`,
            defaultRows: FILES_PER_PAGE
        }))();
        this.updateRelatedData({ files: null });
        files.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                files: {
                    items: files.toJSON(),
                    total: files.total,
                    start: files.start
                }
            });
        });
    },
    handleFilesPaging: function(direction) {
        var files = this.relatedData.files;
        if (!files) {
            return;
        }

        var start = files.start;
        var maxStart = Math.floor(files.total / FILES_PER_PAGE) * FILES_PER_PAGE;
        start = Math.min(Math.max(start + direction * FILES_PER_PAGE, 0), maxStart);
        this.fetchRelatedFiles(start);
    },
    fetchGroups: function() {
        var Groups = new GroupsCollection();
        Groups.fetch({
            rows: -1,
            data: {
                by_static_contained_item: true,
                item_id: this.model.get('id'),
                element_type: 'individuals'
            }
        }).done(() => {
            this.updateRelatedData({ groups: Groups.toJSON() });
        });
    },
    fetchOrganizationDeals: function(start) {
        var orgId = this.model.get('organization_id');
        if (!orgId) {
            return;
        }

        var deals = new (OpportunitiesCollection.extend({
            urlRoot: () => `/organizations/${orgId}/opportunities`,
            defaultRows: DEALS_PER_PAGE
        }))();
        this.updateRelatedData({ organizationDeals: null });
        deals.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                organizationDeals: {
                    items: deals.toJSON(),
                    total: deals.total,
                    start: deals.start
                }
            });
        });
    },
    fetchRelatedDeals: function(start) {
        this.updateRelatedData({ deals: null });

        let url = null;

        if (AppConfig.getValue('use_pdrcm_improved_endpoints')) {
            url = `/pdcrm_individual_opportunities/${this.model.get('id')}`;
        } else {
            const params = {
                start: start || 0,
                rows: DEALS_PER_PAGE,
                order_by: 'full_name asc'
            };

            url = `/individuals/${this.model.get('id')}/opportunities?${$.param(params)}`
        }

        const self = this;

        $.get(url, function(deals) {
            for (let item of deals) {
                item.phase = app.globalData.phasesInfo.phases.find(p => p.id === item.phase_id);
                item.funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === item.funnel_id);
            }

            let filteredItems = AppConfig.getValue('individuals.overview.related_deals.filter', deals, deals);

            self.updateRelatedData({
                deals: {
                    items: filteredItems,
                    total: filteredItems.length,
                    start: 0
                }
            });
        });
    },
    processIndividuals: function(individuals) {
        var plainIndividuals = individuals.toJSON();
        _.each(plainIndividuals, function(individual) {
            var id = individual.id;
            individual.primaryEmail = individuals.get(id).primaryEmail();
            individual.primaryPhone = individuals.get(id).primaryPhone();
        });
        return plainIndividuals;
    },
    fetchRelatedIndividuals: function(start) {
        this.updateRelatedData({ individuals: null });

        let url = null;

        const params = {
            start: start || 0,
            rows: INDIVIDUALS_PER_PAGE,
            order_by: 'full_name asc'
        };

        url = `/individuals/${this.model.get('id')}/related_individuals?${$.param(params)}`

        const self = this;

        $.get(url, function(data) {
            data.map(d => _.extend(d, {type: 'individuals'}));

            self.updateRelatedData({
                individuals: {
                    items: self.processIndividuals(new IndividualsCollection(data)),
                    total: data.length,
                    start: 0
                }
            });
        });
    },
    handleIndividualsAdd: function(items) {
        var view = this,
            ids = _.map(items, (item) => ({ id: item.id }));

        return $.ajax({
            url: '/individuals/' + this.model.get('id') + '/related_individuals',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(ids),
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: TextManager.getText('ID_ENTITY_ADDED_TO_ENTITY', ['${ID_INDIVIDUAL_RELATED, capitalize, pluralize=' + ids.length + '}', '${ID_INDIVIDUAL}']),
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
            },
            complete: function() {
                view.fetchRelatedIndividuals();
            }
        });
    },
    handleIndividualsDelete: function(items) {
        var view = this,
            ids = _.map(items, (item) => ({ id: item.id }));

        return $.ajax({
            url: '/individuals/' + this.model.get('id') + '/related_individuals',
            type: 'DELETE',
            contentType: 'application/json',
            data: JSON.stringify(ids),
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: TextManager.getText('ID_ENTITY_REMOVED_FROM_ENTITY', ['${ID_INDIVIDUAL_RELATED, capitalize, pluralize=' + ids.length + '}', '${ID_INDIVIDUAL}']),
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
            },
            complete: function() {
                view.fetchRelatedIndividuals();
            }
        });
    },
    handleNewIndividual: function() {
        var organization = this.model.get('organization');
        if (organization) {
            organization = new OrganizationModel(organization);
        }
        this.options.parent.trigger('replace-view:individual:new', {
            organization: organization,
            fromIndividualModel: this.model
        });
    },
    handleLinkClick: function(item, uri) {
        var model;
        if (item.type === 'individuals') {
            model = new IndividualModel(item);
        }
        else {
            return;
        }
        app.followLink(model, uri);
    },
    handleDealsPaging: function(dealsKey, direction) {
        var deals = this.relatedData[dealsKey];
        if (!deals) {
            return;
        }

        var start = deals.start;
        var maxStart = Math.floor(deals.total / DEALS_PER_PAGE) * DEALS_PER_PAGE;
        start = Math.min(Math.max(start + direction * DEALS_PER_PAGE, 0), maxStart);
        if (dealsKey === 'deals') {
            this.fetchRelatedDeals(start);
        }
        if (dealsKey === 'organizationDeals') {
            this.fetchOrganizationDeals(start);
        }
    },
    handleIndividualsPaging: function(individualsKey, direction) {
        var individuals = this.relatedData[individualsKey];
        if (!individuals) {
            return;
        }

        var start = individuals.start;
        var maxStart = Math.floor(individuals.total / INDIVIDUALS_PER_PAGE) * INDIVIDUALS_PER_PAGE;
        start = Math.min(Math.max(start + direction * INDIVIDUALS_PER_PAGE, 0), maxStart);
        this.fetchRelatedIndividuals(start);
    },
    handleIndividualNavigate: function(individual) {
        this.options.parent.trigger('replace-view:individual:show', {
            id: individual.id
        });
    },
    handleToggleFavorite: function() {
        var model = this.model,
            model_id = model.get('id'),
            newVal = !model.get('is_favorite');

        // optimistically set new value on the model
        // so the UI feels responsive
        model.set('is_favorite', newVal);

        $.ajax({
            url: app.user.url() + '/favorite_individuals/items/' + model_id,
            type: newVal ? 'PUT' : 'DELETE'
        }).fail(function() {
            // setting favourite status failed, set the previous value back on the model
            model.set('is_favorite', !newVal);
        });
    },
    handleDuplicateClick: function() {
        const duplicate = () => {
            var self = this;

            $.ajax({
                type: 'POST',
                url: `${this.model.url()}?clone`,
                success: function(individual) {
                    self.options.parent.trigger('replace-view:individual:show', {
                        id: individual.id,
                        section: self.options.section
                    });

                    vent.trigger('contact:save');
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.parseText('${ID_INDIVIDUAL, capitalize} has been duplicated'),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        }

        const content = {
            message: `Are you sure you would like to clone ${this.model.get('full_name')}? The process is going to create an identical copy of your original entity.`,
            icon: 'icon-question'
        };

        MessageBox.showYesNo(content, this, function() {
            duplicate();
        });
    },
    handleHistoryClick: function() {
        const historyView = new HistoryView({
            entityType: 'individuals',
            entityId: this.model.get('id'),
            entityName: this.model.get('full_name')
        });

        this.historyRegion.show(historyView);
    },
    showPermissionView: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
                this.render();
            });
        }
    },
    checkDocusignConnected: function() {
        var view = this;

        if (this.conditionalFlags.docusign != undefined) {
            return;
        }

        const data = app.globalData.integrationsInfo.authorized;

        if (_.findWhere(data, {id: 'docusign'})) {
            view.updateConditionalFlags({ docusign: true });
        } else {
            view.updateConditionalFlags({ docusign: false });
        }
    },
    showDocusignView: function() {
        this.docusignRegion.show(new DocusignPrepareView({
            model: this.model
        }));
    },
    handleOrganizationClick: function() {
        this.trigger('go-to-organization');
    },
    handleCommunicationClick: function(commId, link, action, allEmailCommIds) {
        if (allEmailCommIds && allEmailCommIds.length > 0){
            const communications = allEmailCommIds.map(commId => this.communicationCollection.get(commId))
            this.controller.followMultipleCommunicationLink(communications, link);
        }else {
            var communication = this.communicationCollection.get(commId);

            if (action === 'sms') {
                vent.trigger('quick:create:message', this.model.get('id'), link.slice(4)); // 4 = tel:
            } else {
                var self = this;

                this.controller.followCommunicationLink(communication, link, function(activity) {
                    if (AppConfig.getValue('on_phone_call.show_activity_note_popup', false) && communication.get('medium') === 'phone') {
                        var content = {
                            message: 'Would you like to create an activity note?',
                            icon: 'icon-question'
                        };

                        var related = {
                            id: self.model.get('id'),
                            type: 'individuals',
                            related_type: 'individual',
                            name: self.model.get('full_name')
                        };

                        var showTaskPopup = function() {
                            content.message = 'Would you like to create a task?';

                            MessageBox.showYesNo(content, self, function() {
                                vent.trigger('quick:add:task',
                                    new TaskModel({ related: related }),
                                    {
                                        onTaskCreated: function() {
                                            self.trigger('show-tab', 'tasks');
                                        }
                                    }
                                );
                            });
                        }

                        MessageBox.showYesNo(content, self, function() {
                            vent.trigger('quick:add:note', {
                                parentActivity: activity,
                                onNoteCreated: showTaskPopup,
                                onNoteCancelled: showTaskPopup,
                                related: related
                            });
                        }, function() { // no
                            showTaskPopup();
                        });
                    }
                });
            }
        }
    },
    doSearchRequest: _.debounce(function(settings) {
        if (!settings) {
            return;
        }
        $.ajax(settings);
    }, 500),
    handleAutoSuggestSearch: function(urlPrefix, searchText, done) {
        var self = this;

        if (!searchText) {
            // this will cause responses to any outstanding search requests
            // to be ignored
            this.autoSuggestTimestamp = null;
            // call the debounced function with no settings to cancel
            // any previously scheduled request
            this.doSearchRequest();
            return;
        }

        // use a single timestamp for deal, file, group searches as we won't
        // search for all of them at the same time
        this.autoSuggestTimestamp = new Date().getTime();

        this.doSearchRequest({
            url: urlPrefix + searchText,
            // set up context for the response callback,
            // containing the timestamp at the time of making the request
            context: { timeStamp: this.autoSuggestTimestamp },
            success: function(data) {
                if (this.timeStamp !== self.autoSuggestTimestamp) {
                    // another search has been initiated before this one completed,
                    // ignore
                    return;
                }
                if (done) {
                    done(data);
                }
            }
        });
    },
    handleFileAdd: function(items) {
        var view = this;

        $.ajax({
            url: '/individuals/' + this.model.get('id') + '/related_files',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(_.map(items, (item) => ({ id: item.id }))),
            complete: function() {
                view.fetchRelatedFiles();
            }
        });
    },
    handleDnDFileUpload: function(files) {
        var formData = new FormData(),
            totalSize = 0,
            cumulativeSize = [],
            view = this;

        _.each(files, function(f) {
            formData.append('files', f);
            totalSize += f.size;
            cumulativeSize.push(totalSize);
        });

        $.ajax({
            type: 'POST',
            url: '/individuals/' + this.model.get('id') + '/related_files',
            contentType: false,
            processData: false,
            data: formData,
            xhr: function() {
                var xhr = new XMLHttpRequest();

                xhr.upload.addEventListener("progress", (event) => {
                    var numFilesUploaded = 0,
                        percentComplete;

                    if (event.lengthComputable) {
                        percentComplete = event.loaded / event.total * 100;

                        for (var i = cumulativeSize.length - 1; i >= 0; i--) {
                            if (event.loaded >= cumulativeSize[i]) {
                                numFilesUploaded = i + 1;
                                break;
                            }
                        }
                        view.updateRelatedData({ fileUpload: {
                            numFiles: files.length,
                            numFilesUploaded: numFilesUploaded,
                            percentComplete: percentComplete
                        } });
                    }
                }, false);

                return xhr;
            }
        }).done(function() {
            view.fetchRelatedFiles();
            view.updateRelatedData({ fileUpload: null });
        }).fail(function(xhr, textStatus, errorThrown) {
            vent.trigger('alert:show', {
                type: function() {
                    var filesText = files.length > 1 ? 'files' : 'file';
                    return {
                        message: Handlebars.compile('Error uploading {{filesText}}: {{errorMsg}}')({
                            filesText: filesText,
                            errorMsg: errorThrown
                        }),
                        timer: 3000,
                        classes: 'error'
                    };
                }
            });
            view.updateRelatedData({ fileUpload: null });
        });

        this.updateRelatedData({ fileUpload: {
            numFiles: files.length,
            numFilesUploaded: 0,
            percentComplete: 0
        } });
    },
    handleFileDelete: function(file) {
        var mbContent = {
                    accept_is_negative: true,
                    message: Handlebars.compile('Are you sure you would like to remove {{name}}?')({name: file.name}),
                    icon: 'icon-trashcan'
                };
        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                $.ajax({
                    url: '/individuals/' + self.model.get('id') + '/related_files/' + file.id,
                    type: 'DELETE',
                    complete: function() {
                        self.fetchRelatedFiles();
                        vent.trigger('update-checklist');
                    }
                });
            }
        );
    },
    handleDealsAdd: function(items) {
        var view = this;

        return $.ajax({
            url: '/individuals/' + this.model.get('id') + '/opportunities',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(_.map(items, (item) => ({ id: item.id }))),
            complete: function() {
                view.fetchRelatedDeals();
            }
        });
    },
    handleNewDeal: function() {
        var organization = this.model.get('organization');
        organization = organization && new OrganizationModel(organization);

        this.options.parent.trigger('replace-view:deal:new', {
            organization: organization,
            fromIndividualModel: this.model
        });
    },
    handleGroupSearch: function(searchText, done) {
        this.handleAutoSuggestSearch(
            '/groups?element_type=individuals&group_type=static&search=',
            searchText,
            done
        );
    },
    handleGroupAdd: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'PUT',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupRemove: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'DELETE',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupClick: function(item) {
        window.location.href = '/#contacts/group/individuals/' + item.id;
    },
    handleAppointmentClick: function() {
        const appointmentView = new AppointmentView({
            model: this.model,
            relatedData: this.relatedData,
        });

        this.appointmentRegion.show(appointmentView);
    },
    handleCredasAmlUpdate: function() {
        var self = this;

        const refreshIndividualView = function() {
            self.options.parent.trigger('replace-view:individual:show', {
                id: self.model.get('id'),
                section: self.options.section
            });
        };

        const credasView = new CredasView({
            model: this.model,
            parentView: self,
            refreshIndividualView: refreshIndividualView,
        });

        this.credasRegion.show(credasView);
    },
    handleDealsDelete: function(selection) {
        var view = this;

        return $.ajax({
            url: '/individuals/' + this.model.get('id') + '/opportunities',
            type: 'DELETE',
            contentType: 'application/json',
            data: JSON.stringify(_.map(selection, (val, key) => ({ id: key }))),
            complete: function() {
                view.fetchRelatedDeals();
            }
        });
    },
    handleDealNavigate: function(deal) {
        this.options.parent.trigger('replace-view:deal:show', { id: deal.id });
    },
    handleDelete: function() {
        const canDelete = AppConfig.getValue('individuals.handle_delete', true, {
            individual: this.model,
            this: this,
        });

        if ( ! canDelete ) return;

        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({ name: this.model.get('full_name') }),
            icon: 'icon-trashcan'
        };

        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                self.model.destroy();
                vent.trigger('model:delete');
            }
        );
    },
    handleEdit: function() {
        this.trigger('replace-view:edit');
    },
    handleClose: function() {
        this.trigger('close-view');
        vent.trigger('individual:view:closed');
    },
    handleCancel: function(dataChanged) {
        if (!_.isEmpty(dataChanged)) {
            app.dirtyModelHandler.add(this.model.cid);
        }

        app.dirtyModelHandler.confirm(this, function() {
            app.dirtyModelHandler.remove(this.model.cid);
            if (!this.model.get('id')) {
                this.trigger('close-view');
            } else {
                this.invalidFieldErrors = null;
                this.setEditing(false, true);
            }
        });
    },
    handlePreloadedFunnelArray: function(attributes) {
        if ('funnels' in attributes && attributes.funnels.length > 0 && Array.isArray(attributes.funnels[0])) {
            attributes.funnels = attributes.funnels[0]
        }
        return attributes
    },
    handleSave: function(dataChanged) {
        this.invalidFieldErrors = null;
        this.model.attributes = this.handlePreloadedFunnelArray(this.model.attributes)

        var attrs = _.extend(_.clone(this.model.attributes), dataChanged);
        var errors = this.model.validate(attrs) || {};
        _.extend(errors, this.validateCustomFields(this.relatedData.processedCustomFields, attrs));
        _.extend(errors, AppConfig.getValue('individuals.customValidation', null, {
            attrs: attrs,
            isNew: this.model.isNew(),
            isLead: this.options.isLead
        }));

        if (_.isEmpty(errors)) {
            if (_.isEmpty(dataChanged)) {
                this.setEditing(false, true);
            } else {
                var self = this;
                var changes = _.clone(dataChanged);
                this.isSaving = true;

                this.model.save(dataChanged, {
                    patch: true,
                    wait: true,
                    success: function() {
                        // if the permissions has been updated and the current user can't see the individual we go back to "all individuals" group
                        if (!self.model.get('id')) {
                            window.location = '/#contacts/individuals';
                        } else {
                            var onIndividualSaved = function() {
                                app.dirtyModelHandler.remove(self.model.cid);
                                vent.trigger('contact:save');
                                vent.trigger('update:activity');
                                vent.trigger('AppContent:contentChange');
                                self.trigger('contact:save');

                                self.fetchOrganizationDeals();
                                self.fetchRelatedDeals();
                                self.fetchRelatedFiles();
                                self.fetchGroups();
                                self.checkDocusignConnected();
                                self.processCustomFields(function() {
                                    self.isSaving = false;
                                    self.setEditing(false, true);
                                })
                            }

                            const activityNoteFields = AppConfig.getValue('individuals.edit.create_activity_note_fields', []);

                            if (activityNoteFields.length > 0) {
                                let noteContent = [];

                                for (const f of activityNoteFields) {
                                    if (changes[f.field]) {
                                        noteContent.push(`${f.label ? f.label : ''}${changes[f.field]}`);
                                    }
                                }

                                if (noteContent.length > 0) {
                                    var note = new ActivityModel();

                                    note.save({
                                        note: noteContent.join('\n'),
                                        activity_type: 'note',
                                        item_type: 'individuals',
                                        item_id: self.model.get('id')
                                    }, {
                                        alert: false,
                                        success: function() {
                                            onIndividualSaved();
                                        }
                                    });
                                } else {
                                    onIndividualSaved();
                                }
                            } else {
                                onIndividualSaved();
                            }
                        }
                    },
                    error: function(model, response) {
                        self.triggerSaveErrorAlert(TextManager.parseText('${ID_INDIVIDUAL, capitalize}'), response);
                    }
                });
            }
        } else {
            this.invalidFieldErrors = errors;
            this.render();
        }
    },
    updateConditionalFlags: function(data) {
        _.extend(this.conditionalFlags, data);
        this.render();
    },
    setEditing: function(editing, andRender) {
        if (editing !== this.editing) {
            this.editing = editing;

            if (andRender) {
                this.render();
            }
        }
    },
    checkIndividualIsProtected: function() {
        if (!AppConfig.getValue('protectSyncedIndividuals')) {
            return;
        }

        if (app.user.get('is_helper')) {
            return;
        }

        if (this.model.isNew()) {
            return;
        }

        var individualId = this.model.get('id');
        var self = this;

        var doCheck = function(users) {
            var isProtected = false;

            for (var i = 0; i < users.length; ++i) {
                var preferences = users[i].get('preferences');
                if (preferences && preferences.sync_info && preferences.sync_info.target_id === individualId) {
                    isProtected = true;
                    break;
                }
            }

            if (isProtected !== self.individualIsProtected) {
                self.individualIsProtected = isProtected;
                self.render();
            }
        }

        if (this.usersCollection) {
            doCheck(this.usersCollection.models);
        } else {
            this.usersCollection = new UsersCollection();

            this.usersCollection.fetch({
                success: function(data) {
                    doCheck(data.models);
                },
                error: function() {
                    callback(false);
                }
            });
        }
    },
    render: function() {
        if (this.editing && this.isSaving) {
            return;
        }

        var modelData = this.model.toJSON();
        var communication = _.map(modelData.communication, function(item) {
            var model, output;
            if (_.contains(['email', 'phone'], item.medium)) {
                model = new CommunicationModel(item);
                return _.extend({}, item, { href: model.valueHref() });
            }
            if (item.medium != 'social') {
                return item;
            }
            if (item.name === 'linkedin') {
                output = _.clone(item);
                if (output.value.indexOf('linkedin.com') === -1) {
                    output.href = 'https://www.linkedin.com/in/' + output.value;
                } else if (output.value.indexOf('//') === -1) {
                    output.href = 'https://' + output.value;
                }
                return output;
            }
            if (item.name === 'twitter') {
                output = _.clone(item);
                output.href = 'https://twitter.com/' + output.value;
                return output;
            }
            if (item.name === 'facebook' || item.name === 'googleplus' || item.name === 'instagram') {
                output = _.clone(item);
                if (output.value.indexOf('//') === -1) {
                    output.href = 'https://' + output.value;
                }
                else {
                    output.href = output.value;
                }
                return output;
            }
            return null;
        });
        modelData.communication = _.filter(communication, function(comm) {
            return comm !== null;
        });

        var isEditable = security.checkPermission('edit', {
            id: this.model.get('id'),
            permissions: this.model.get('permissions')
        });
        var isPermissionsEnabled = app.user.get('client').feature_tier !== 'starting' && app.user.get('client').permission_type !== 'rba';

        var onEdit = null;
        var onShowPermissionView = null;
        if (isEditable) {
            onEdit = this.handleEdit.bind(this);
            if (isPermissionsEnabled) {
                onShowPermissionView = this.showPermissionView.bind(this);
            }
        }

        var onShowDocusignView = null;
        if (this.conditionalFlags.docusign) {
            onShowDocusignView = this.showDocusignView.bind(this);
        }

        // if it's a new model, we populate the custom fields with the default values and other preloaded fields
        var preloadedFields = {};

        if (this.model.isNew()) {
            if (modelData.organization && modelData.organization.attributes) {
                modelData.organization = modelData.organization.toJSON();
            }

            var customFields = {};

            _.forEach((this.relatedData.processedCustomFields || []), function(group) {
                _.forEach(group.fields, function(field) {
                    if ('originalValue' in field) {
                        customFields[field.id] = field.originalValue;
                    }
                });
            });

            if (!_.isEmpty(customFields)) {
                modelData.custom_fields = customFields;
            }

            modelData.unsubscribed_all = AppConfig.getValue('individualUnsubscribedAllDefaultValue', false);
            modelData.unsubscribed_all_messages = AppConfig.getValue('individualUnsubscribedAllMessagesDefaultValue', false);

            const preloadedTags = app.user.getIndividualPreloadedTags();
            if (preloadedTags.length > 0) {
                modelData.tags = preloadedTags;
                preloadedFields.tags = true;
            } 

            const preloadedFunnels = app.user.getIndividualPreloadedFunnels();
            if (preloadedFunnels.length > 0) {
                modelData.funnels = preloadedFunnels;
                preloadedFields.funnels = true;
            }
        }

        let editLayout = null;
        let viewLayout = null;

        const layoutsStr = (app.user.get('client').preferences || {}).layouts;
        if (layoutsStr) {
            const layouts = JSON.parse(layoutsStr);

            if (layouts && layouts.individuals) {
                editLayout = layouts.individuals.edit;
                viewLayout = layouts.individuals.view;
            }
        }

        ReactDOM.render(
            <div>
                {this.editing ? (
                    <IndividualFlexEditView
                        layout={editLayout}
                        individual={modelData}
                        preloadedFields={preloadedFields}
                        relatedData={this.relatedData}
                        invalidFieldErrors={this.invalidFieldErrors}
                        onToggleFavorite={this.handleToggleFavorite.bind(this)}
                        onCancel={this.handleCancel.bind(this)}
                        onSave={this.handleSave.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        onShowPermissionView={onShowPermissionView}
                    />
                ) : (
                    <IndividualFlexView
                        layout={viewLayout}
                        individual={modelData}
                        relatedData={this.relatedData}
                        isEditable={isEditable}
                        isPermissionsEnabled={isPermissionsEnabled}
                        isProtected={this.individualIsProtected}
                        userId={app.user.get('id')}
                        archiveEmailAddress={this.archiveEmailAddress}
                        onToggleFavorite={this.handleToggleFavorite.bind(this)}
                        onShowPermissionView={onShowPermissionView}
                        onShowDocusignView={onShowDocusignView}
                        onOrganizationClick={this.handleOrganizationClick.bind(this)}
                        onCommunicationClick={this.handleCommunicationClick.bind(this)}
                        onDuplicateClick={this.handleDuplicateClick.bind(this)}
                        onHistoryClick={this.handleHistoryClick.bind(this)}
                        onClickNextFilePage={() => { this.handleFilesPaging(1); }}
                        onClickPrevFilePage={() => { this.handleFilesPaging(-1); }}
                        onFileSelect={this.handleFileAdd.bind(this)}
                        onDnDFileUpload={this.handleDnDFileUpload.bind(this)}
                        onFileDelete={this.handleFileDelete.bind(this)}
                        onDealsAdd={this.handleDealsAdd.bind(this)}
                        onNewDeal={this.handleNewDeal.bind(this)}
                        onDealsDelete={this.handleDealsDelete.bind(this)}
                        onDealNavigate={this.handleDealNavigate.bind(this)}
                        onClickNextDealPage={(dealsKey) => {
                            this.handleDealsPaging(dealsKey, 1);
                        }}
                        onClickPrevDealPage={(dealsKey) => {
                            this.handleDealsPaging(dealsKey, -1);
                        }}
                        onIndividualNavigate={this.handleIndividualNavigate.bind(this)}
                        onLinkClick={this.handleLinkClick.bind(this)}
                        onIndividualsPaging={this.handleIndividualsPaging.bind(this)}
                        onIndividualsAdd={this.handleIndividualsAdd.bind(this)}
                        onIndividualsDelete={this.handleIndividualsDelete.bind(this)}
                        onNewIndividual={this.handleNewIndividual.bind(this)}
                        onGroupSearch={this.handleGroupSearch.bind(this)}
                        onGroupAdd={this.handleGroupAdd.bind(this)}
                        onGroupRemove={this.handleGroupRemove.bind(this)}
                        onGroupClick={this.handleGroupClick.bind(this)}
                        onEdit={onEdit}
                        onClose={this.handleClose.bind(this)}
                        checklists={this.checklists}
                        gotoSection={this.options.gotoSection}
                        onAppointmentClick={this.handleAppointmentClick.bind(this)}
                        handleCredasAmlUpdate={this.handleCredasAmlUpdate.bind(this)}
                    />
                )}
            </div>,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default IndividualContainerView;

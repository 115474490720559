import BaseModel from 'js/models/base'


var SocialItemModel = BaseModel.extend({
    name: 'Social Item',
    urlRoot: '/social',
    apiType: 'salesseek.social.models.social_item.SocialItem',
    toString: function() {
        return this.get('text');
    }
});
export default SocialItemModel;

import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import vent from 'js/vent';
import dateFormat from 'js/utils/date-format';
import { NewSelect } from 'js/react_views/widgets/select'
import htmlSanitizer from 'js/utils/html-sanitizer';
import MessageBox from 'js/views/message_box';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './common.css';


export class Subject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subject: props.value || '',
            focused: false
        };

        this.suggestions = props.suggestions || null;

        if (this.suggestions) {
            this.suggestions = this.suggestions.map((s, sidx) => {
                return {
                    id: `sg_${sidx}`,
                    title: s
                };
            });
        }
    }

    handleSubjectChange(ev) {
        this.setState({
            subject: ev.target.value
        });

        this.props.onChange(ev.target.value);
    }

    handleSuggestionSelect(items) {
        this.setState({
            subject: items[0].title
        });

        this.subjectComponent.focus();
        this.props.onChange(items[0].title);
    }

    render() {
        return (
            <div className={style.cSubject}>
                <input
                    ref={(el) => this.subjectComponent = el}
                    className={`
                        ${style.sInput}
                        ${this.state.focused ? style.cFocused : ''}
                        ${this.suggestions ? style.iHasSuggestions : ''}
                    `}
                    value={this.state.subject}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={() => this.setState({ focused: false })}
                    onChange={this.handleSubjectChange.bind(this)}
                />

                {this.suggestions &&
                    <div
                        className={style.sTitle}
                        onClick={() => this.suggestionsComponent.toggleExpanded(true)}
                    >
                        <span style={{marginRight: 5}}>Subject</span>
                        {'\u25bc'}
                    </div>
                }

                {this.suggestions &&
                    <div className={style.sDropdown}>
                        <NewSelect
                            ref={(el) => this.suggestionsComponent = el}
                            data={this.suggestions}
                            searchViewVisible={false}
                            options={{minimumInputLength: -1}}
                            width={250}
                            boxStyle={{
                                visibility: 'hidden'
                            }}
                            onSelect={this.handleSuggestionSelect.bind(this)}
                        />
                    </div>
                }
            </div>
        );
    }
}

class NewNote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputExpanded: false,
            inputFocused: false
        };
    }

    componentDidMount() {
        const self = this;
        const input = $(this.inputComponent);
        const mainComponent = $(this.mainComponent);

        input.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor',
            statusbar: true,
            resize: false,
            relative_urls:false,
            remove_script_host:false,
            height: 0,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            oninit: function(activeEditor) {
                self.tinyEditor = activeEditor;
                self.tinyContainer = mainComponent.find('.tox-tinymce:first');
                self.tinyContainer.css({
                    'width': '100%',
                    'height': '190px',
                    'margin-left': '15px',
                    'border-radius': '3px',
                    'border': '1px solid #C1C4CD'
                });

                activeEditor.on('keyup', function (ev) {
                    if (self.tinyEditor.getContent().length === 0) {
                        self.tinyContainer.hide();
                        input.show();
                        input.focus();

                        self.setState({
                            inputExpanded: false
                        });
                    }
                });

                activeEditor.on('focus', function() {
                    self.tinyContainer.css('border-color', '#0A97FF');
                });

                activeEditor.on('blur', function() {
                    self.tinyContainer.css('border-color', '#C1C4CD');
                });

                activeEditor.on('paste', function () {
                    _.defer(function() {
                        self.tinyEditor.setContent(htmlSanitizer.sanitize(self.tinyEditor.getContent(), true));
                    });
                });

                mainComponent.find('.tox-statusbar').css('display', 'none');

                self.tinyContainer.hide();
                input.show();
            },
            setup: function(ed) {
                ed.on('keydown', function (ev) {
                    if (ev.keyCode === 13 && ev.shiftKey === true) {
                        ev.preventDefault(); // Prevent creating a line-break on enter keydown
                        self.postNote();
                    }
                });

                ed.on('init', function() {
                    this.getDoc().body.style.fontSize = '14px';
                    this.getDoc().body.style.color = '#666666';
                    this.getDoc().body.style['font-family'] = '"proxima-nova","Helvetica","Arial","sans-serif"';
                });
            }
        });
    }

    handleInputChange(ev) {
        let value = ev.target.value.trim();

        if (value.length === 0) {
            return;
        }

        $(this.inputComponent).hide();
        this.tinyContainer.show();
        this.tinyEditor.execCommand('mceInsertContent', false, value);

        this.setState({
            inputExpanded: true
        });

        ev.target.value = '';
    }

    postNote() {
        const text = htmlSanitizer.sanitize($(this.inputComponent).val().replace(/(\n)/gm,""));

        if (!text) {
            return;
        }

        const self = this;
        let activityType = null;

        switch (this.props.entityType) {
            case 'appointments':
                activityType = 'appointment:note';
                break;

            case 'tasks':
                activityType = 'task:note';
                break;
        }

        $.post('/activities', JSON.stringify({
            activity_type: activityType,
            item_id: this.props.entityId,
            item_type: this.props.entityType,
            note: text
        }), function(result) {
            const input = $(self.inputComponent);

            self.tinyEditor.setContent('');
            self.tinyContainer.hide();

            input.show();
            input.focus();

            self.setState({
                inputExpanded: false
            });

            self.props.onNoteCreated();
        });
    }

    render() {
        const photoUrl = app.user.get('photo_url');

        return (
            <div
                ref={(el) => this.mainComponent = el}
                className={style.cNewNote}
            >
                <div className={style.cContainer}>
                    {!photoUrl &&
                        <div className={`${style.cThumbnail} icon-user`}/>
                    }

                    {photoUrl &&
                        <div
                            className={style.cPhoto}
                            style={{
                                backgroundImage: `url('${photoUrl}')`
                            }}
                        />
                    }

                    <textarea
                        ref={(el) => this.inputComponent = el}
                        className={`
                            ${style.cInput}
                            ${this.state.inputFocused ? style.iFocused : ''}
                        `}
                        placeholder='Add a comment...'
                        onFocus={() => this.setState({ inputFocused: true })}
                        onBlur={() => this.setState({ inputFocused: false })}
                        onChange={this.handleInputChange.bind(this)}
                    />
                </div>

                {this.state.inputExpanded &&
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginTop: '10px'
                        }}
                    >
                        <div
                            className={style.cButton}
                            onClick={this.postNote.bind(this)}
                        >
                            Post
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class Note extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            inputFocused: false
        };
    }

    componentDidMount() {
        const self = this;
        const input = $(this.inputComponent);
        const mainComponent = $(this.mainComponent);

        input.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor',
            statusbar: true,
            resize: false,
            relative_urls: false,
            remove_script_host: false,
            height: 0,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            oninit: function(activeEditor) {
                self.tinyEditor = activeEditor;
                self.tinyContainer = mainComponent.find('.tox-tinymce:first');
                self.tinyContainer.css({
                    'width': '100%',
                    'height': '190px',
                    'border-radius': '3px',
                    'border': '1px solid #C1C4CD'
                });

                activeEditor.setContent(self.props.data.note);

                activeEditor.on('focus', function() {
                    self.tinyContainer.css('border-color', '#0A97FF');
                });

                activeEditor.on('blur', function() {
                    self.tinyContainer.css('border-color', '#C1C4CD');
                });

                activeEditor.on('paste', function () {
                    _.defer(function() {
                        self.tinyEditor.setContent(htmlSanitizer.sanitize(self.tinyEditor.getContent(), true));
                    });
                });

                mainComponent.find('.tox-statusbar').css('display', 'none');
            },
            setup: function(ed) {
                ed.on('keydown', function (ev) {
                    if (ev.keyCode === 13 && ev.shiftKey === true) {
                        ev.preventDefault(); // Prevent creating a line-break on enter keydown
                        self.updateNote();
                    }
                });

                ed.on('init', function() {
                    this.getDoc().body.style.fontSize = '14px';
                    this.getDoc().body.style.color = '#666666';
                    this.getDoc().body.style['font-family'] = '"proxima-nova","Helvetica","Arial","sans-serif"';
                });
            }
        });
    }

    handleNoteDelete() {
        const mbContent = {
            accept_is_negative: true,
            message: 'Are you sure you want to <strong>permanently</strong> delete the note?',
            icon: 'icon-warning'
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            $.ajax({
                type: 'DELETE',
                url: '/activities/' + self.props.data.id,
                success: function () {
                    self.props.onNoteDeleted();
                }
            });
        });
    }

    handleEditCancel() {
        this.setState({
            editing: false
        });
    }

    handleNoteEdit() {
        this.setState({
            editing: true
        });
    }

    updateNote() {
        this.setState({
            editing: false
        });

        const self = this;
        const text = htmlSanitizer.sanitize($(this.inputComponent).val().replace(/(\n)/gm,""));

        $.ajax({
            type: 'PATCH',
            url: '/activities/' + this.props.data.id,
            data: JSON.stringify({
                activity_type: this.props.data.activity_type,
                note: text
            }),
            contentType: 'application/json',
            dataType: 'json',
            success: function() {
                self.props.onNoteUpdated();
            }
        });
    }

    render() {
        const data = this.props.data;
        const created = dateFormat.parseDate(data.created);
        let createdStr = `${dateFormat.shortFormatTime(created)} ${dateFormat.shortFormatMonth(created.getMonth())} ${created.getDate()}, ${created.getFullYear()}`;

        if (data.version > 0) {
            createdStr += ' – Edited';
        }

        return (
            <div
                ref={(el) => this.mainComponent = el}
                className={`
                    ${style.cNote}
                    ${this.state.editing ? style.nEditing : ''}
                `}
            >
                <div
                    style={{display: this.state.editing ? 'block' : 'none'}}
                >
                    <textarea
                        ref={(el) => this.inputComponent = el}
                        className={`
                            ${style.cInput}
                            ${this.state.inputFocused ? style.iFocused : ''}
                        `}
                        onFocus={() => this.setState({ inputFocused: true })}
                        onBlur={() => this.setState({ inputFocused: false })}
                    />

                    <div className={style.nEditOptions}>
                        <div
                            className={style.oCancel}
                            onClick={this.handleEditCancel.bind(this)}
                        >
                            Cancel
                        </div>

                        <div
                            className={style.oSave}
                            onClick={this.updateNote.bind(this)}
                        >
                            Save
                        </div>
                    </div>
                </div>

                {!this.state.editing &&
                    <div style={{display: 'flex'}}>
                        {!data.creator.photo_url &&
                            <div className={`${style.cThumbnail} icon-user`}/>
                        }

                        {data.creator.photo_url &&
                            <div
                                className={style.cPhoto}
                                style={{
                                    backgroundImage: `url('${data.creator.photo_url}')`
                                }}
                            />
                        }

                        <div className={style.nInfo}>
                            <div className={style.iFirstLine}>
                                <div className={style.flName}>{data.creator.name}</div>
                                <div className={style.flCreated}>{createdStr}</div>
                            </div>

                            <p
                                className={style.iSecondLine}
                                dangerouslySetInnerHTML={{ __html: htmlSanitizer.sanitize(data.note, true)}}
                            />
                        </div>

                        <div className={style.nToolbar}>
                            <div
                                className={`${style.tItem} icon-trashcan`}
                                onClick={this.handleNoteDelete.bind(this)}
                            />

                            <div
                                className={`${style.tItem} icon-pencil`}
                                onClick={this.handleNoteEdit.bind(this)}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export class Notes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            loading: false,
            notes: [],
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchNotes(true);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchNotes(onMounted) {
        const self = this;

        const args = {
            rows: -1,
            order_by: 'user_created desc',
            item_type: this.props.entityType,
            item_id: this.props.entityId,
            [this.props.entityType]: true
        };

        this.setState({
            loading: true
        });

        $.get(`/activities/?${$.param(args)}`, function(result) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                loading: false,
                notes: result
            });

            if (!onMounted) {
                vent.trigger(`${self.props.entityType === 'tasks' ? 'task' : 'appointment'}:notes:updated`);
            }

            if (self.props.onNotesUpdated) {
                self.props.onNotesUpdated(result);
            }
        });
    }

    render() {
        return (
            <div className={style.vNotes}>
                <div
                    className={style.nHeader}
                    onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                >
                    <div
                        className={`
                            ${style.nToggler}
                            ${this.state.collapsed ? 'icon-caret-right' : 'icon-caret-down'}
                        `}
                    />

                    <div className={style.nTitle}>
                        Notes
                    </div>

                    <div className={style.nCounter}>
                        {this.state.loading ? '-' : this.state.notes.length}
                    </div>
                </div>

                <div
                    className={style.nContent}
                    style={{
                        display: this.state.collapsed ? 'none' : 'block'
                    }}
                >
                    {this.state.loading &&
                        <div
                            style={{padding: '20px 0'}}
                        >
                            <LoadingIndicator/>
                        </div>
                    }

                    {!this.state.loading &&
                        <div className={style.cNoteList}>
                            {this.state.notes.map(note => {
                                return (
                                    <Note
                                        key={`note_${note.id}`}
                                        data={note}
                                        parent={this.props.parent}
                                        onNoteUpdated={this.fetchNotes.bind(this)}
                                        onNoteDeleted={this.fetchNotes.bind(this)}
                                    />
                                );
                            })}
                        </div>
                    }

                    <NewNote
                        entityType={this.props.entityType}
                        entityId={this.props.entityId}
                        onNoteCreated={this.fetchNotes.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
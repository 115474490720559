import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import { NewSelect } from 'js/react_views/widgets/select';
import dateFormat from 'js/utils/date-format';
import Utilities from 'js/utils/utilities';
import DateTimePicker from 'js/widgets/date-time-picker';
import MessageBox from 'js/views/message_box';
import AppConfig from 'app/app-config';

import style from './campaign_creation.css';


const TIME_ZONE_LIST = [{
    id: 'UTC',
    name: 'Current Timezone',
    time: 'Your Current Timezone',
}, {
    id: 'Etc/GMT+12',
    name: 'Dateline Standard Time',
    time: '(GMT-12:00) International Date Line West'
}, {
    id: 'Pacific/Apia',
    name: 'Samoa Standard Time',
    time: '(GMT-11:00) Midway Island, Samoa'
}, {
    id: 'Pacific/Honolulu',
    name: 'Hawaiian Standard Time',
    time: '(GMT-10:00) Hawaii'
}, {
    id: 'America/Anchorage',
    name: 'Alaskan Standard Time',
    time: '(GMT-09:00/-08:00) Alaska'
}, {
    id: 'America/Los_Angeles',
    name: 'Pacific Standard Time',
    time: '(GMT-08:00/-07:00) Pacific Time (US and Canada); Tijuana'
}, {
    id: 'America/Denver',
    name: 'Mountain Standard Time',
    time: '(GMT-07:00/-06:00) Mountain Time (US and Canada)'
}, {
    id: 'America/Mexico_City',
    name: 'Mexico Standard Time 2',
    time: '(GMT-07:00/-06:00) Chihuahua, La Paz, Mazatlan'
}, {
    id: 'America/Phoenix',
    name: 'U.S. Mountain Standard Time',
    time: '(GMT-07:00) Arizona'
}, {
    id: 'America/Chicago',
    name: 'Central Standard Time',
    time: '(GMT-06:00/-05:00) Central Time (US and Canada)'
}, {
    id: 'America/Regina',
    name: 'Canada Central Standard Time',
    time: '(GMT-06:00/-05:00) Saskatchewan'
}, {
    id: 'America/Cancun',
    name: 'Mexico Standard Time',
    time: '(GMT-06:00/-05:00) Guadalajara, Mexico City, Monterrey'
}, {
    id: 'America/Guatemala',
    name: 'Central America Standard Time',
    time: '(GMT-06:00) Central America'
}, {
    id: 'America/New_York',
    name: 'Eastern Standard Time',
    time: '(GMT-05:00/-04:00) Eastern Time (US and Canada)'
}, {
    id: 'America/Indianapolis',
    name: 'U.S. Eastern Standard Time',
    time: '(GMT-05:00/-04:00) Indiana (East)'
}, {
    id: 'America/Bogota',
    name: 'S.A. Pacific Standard Time',
    time: '(GMT-05:00) Bogota, Lima, Quito'
}, {
    id: 'America/Halifax',
    name: 'Atlantic Standard Time',
    time: '(GMT-04:00/-03:00) Atlantic Time (Canada)'
}, {
    id: 'America/La_Paz',
    name: 'S.A. Western Standard Time',
    time: '(GMT-04:00) Caracas, La Paz'
}, {
    id: 'America/Santiago',
    name: 'Pacific S.A. Standard Time',
    time: '(GMT-04:00) Santiago'
}, {
    id: 'America/St_Johns',
    name: 'Newfoundland and Labrador Standard Time',
    time: '(GMT-03:30/-02:30) Newfoundland and Labrador'
}, {
    id: 'America/Sao_Paulo',
    name: 'E. South America Standard Time',
    time: '(GMT-03:00) Brasilia'
}, {
    id: 'America/Cayenne',
    name: 'S.A. Eastern Standard Time',
    time: '(GMT-03:00) Buenos Aires, Georgetown'
}, {
    id: 'America/Godthab',
    name: 'Greenland Standard Time',
    time: '(GMT-03:00/-02:00) Greenland'
}, {
    id: 'America/Noronha',
    name: 'Mid-Atlantic Standard Time',
    time: '(GMT-02:00) Mid-Atlantic'
}, {
    id: 'Atlantic/Azores',
    name: 'Azores Standard Time',
    time: '(GMT-01:00/-00:00) Azores'
}, {
    id: 'Atlantic/Cape_Verde',
    name: 'Cape Verde Standard Time',
    time: '(GMT-01:00) Cape Verde Islands'
}, {
    id: 'Europe/London',
    name: 'GMT Standard Time',
    time: '(GMT+00:00/+01:00) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London'
}, {
    id: 'Atlantic/Reykjavik',
    name: 'Greenwich Standard Time',
    time: '(GMT) Casablanca, Monrovia'
}, {
    id: 'Europe/Budapest',
    name: 'Central Europe Standard Time',
    time: '(GMT+01:00/+02:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
}, {
    id: 'Europe/Warsaw',
    name: 'Central European Standard Time',
    time: '(GMT+01:00/+02:00) Sarajevo, Skopje, Warsaw, Zagreb'
}, {
    id: 'Europe/Paris',
    name: 'Romance Standard Time',
    time: '(GMT+01:00/+02:00) Brussels, Copenhagen, Madrid, Paris'
}, {
    id: 'Europe/Berlin',
    name: 'W. Europe Standard Time',
    time: '(GMT+01:00/+02:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
}, {
    id: 'Africa/Lagos',
    name: 'W. Central Africa Standard Time',
    time: '(GMT+01:00) West Central Africa'
}, {
    id: 'Europe/Chisinau',
    name: 'E. Europe Standard Time',
    time: '(GMT+02:00/+03:00) Bucharest'
}, {
    id: 'Africa/Cairo',
    name: 'Egypt Standard Time',
    time: '(GMT+02:00) Cairo'
}, {
    id: 'Europe/Kiev',
    name: 'FLE Standard Time',
    time: '(GMT+02:00/+03:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius'
}, {
    id: 'Europe/Bucharest',
    name: 'GTB Standard Time',
    time: '(GMT+02:00/+03:00) Athens, Istanbul, Minsk'
}, {
    id: 'Asia/Jerusalem',
    name: 'Israel Standard Time',
    time: '(GMT+02:00/+03:00) Jerusalem'
}, {
    id: 'Africa/Johannesburg',
    name: 'South Africa Standard Time',
    time: '(GMT+02:00) Harare, Pretoria'
}, {
    id: 'Europe/Moscow',
    name: 'Russian Standard Time',
    time: '(GMT+03:00/+04:00) Moscow, St. Petersburg, Volgograd'
}, {
    id: 'Asia/Riyadh',
    name: 'Arab Standard Time',
    time: '(GMT+03:00) Kuwait, Riyadh'
}, {
    id: 'Africa/Nairobi',
    name: 'E. Africa Standard Time',
    time: '(GMT+03:00) Nairobi'
}, {
    id: 'Asia/Baghdad',
    name: 'Arabic Standard Time',
    time: '(GMT+03:00) Baghdad'
}, {
    id: 'Asia/Tehran',
    name: 'Iran Standard Time',
    time: '(GMT+03:30) Tehran'
}, {
    id: 'Asia/Dubai',
    name: 'Arabian Standard Time',
    time: '(GMT+04:00) Abu Dhabi, Muscat'
}, {
    id: 'Asia/Yerevan',
    name: 'Caucasus Standard Time',
    time: '(GMT+04:00) Baku, Tbilisi, Yerevan'
}, {
    id: 'Asia/Kabul',
    name: 'Afghanistan Standard Time',
    time: '(GMT+04:30) Kabul'
}, {
    id: 'Asia/Yekaterinburg',
    name: 'Ekaterinburg Standard Time',
    time: '(GMT+05:00) Ekaterinburg'
}, {
    id: 'Asia/Tashkent',
    name: 'West Asia Standard Time',
    time: '(GMT+05:00) Islamabad, Karachi, Tashkent'
}, {
    id: 'Asia/Calcutta',
    name: 'India Standard Time',
    time: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi'
}, {
    id: 'Asia/Katmandu',
    name: 'Nepal Standard Time',
    time: '(GMT+05:45) Kathmandu'
}, {
    id: 'Asia/Almaty',
    name: 'Central Asia Standard Time',
    time: '(GMT+06:00) Astana, Dhaka'
}, {
    id: 'Asia/Colombo',
    name: 'Sri Lanka Standard Time',
    time: '(GMT+06:00) Sri Jayawardenepura'
}, {
    id: 'Asia/Novosibirsk',
    name: 'N. Central Asia Standard Time',
    time: '(GMT+06:00) Almaty, Novosibirsk'
}, {
    id: 'Asia/Rangoon',
    name: 'Myanmar Standard Time',
    time: '(GMT+06:30) Yangon Rangoon'
}, {
    id: 'Asia/Bangkok',
    name: 'S.E. Asia Standard Time',
    time: '(GMT+07:00) Bangkok, Hanoi, Jakarta'
}, {
    id: 'Asia/Krasnoyarsk',
    name: 'North Asia Standard Time',
    time: '(GMT+07:00) Krasnoyarsk'
}, {
    id: 'Asia/Shanghai',
    name: 'China Standard Time',
    time: '(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi'
}, {
    id: 'Asia/Singapore',
    name: 'Singapore Standard Time',
    time: '(GMT+08:00) Kuala Lumpur, Singapore'
}, {
    id: 'Asia/Taipei',
    name: 'Taipei Standard Time',
    time: '(GMT+08:00) Taipei'
}, {
    id: 'Australia/Perth',
    name: 'W. Australia Standard Time',
    time: '(GMT+08:00) Perth'
}, {
    id: 'Asia/Irkutsk',
    name: 'North Asia East Standard Time',
    time: '(GMT+08:00) Irkutsk, Ulaanbaatar'
}, {
    id: 'Asia/Seoul',
    name: 'Korea Standard Time',
    time: '(GMT+09:00) Seoul'
}, {
    id: 'Asia/Tokyo',
    name: 'Tokyo Standard Time',
    time: '(GMT+09:00) Osaka, Sapporo, Tokyo'
}, {
    id: 'Asia/Yakutsk',
    name: 'Yakutsk Standard Time',
    time: '(GMT+09:00) Yakutsk'
}, {
    id: 'Australia/Darwin',
    name: 'A.U.S. Central Standard Time',
    time: '(GMT+09:30) Darwin'
}, {
    id: 'Australia/Adelaide',
    name: 'Cen. Australia Standard Time',
    time: '(GMT+09:30) Adelaide'
}, {
    id: 'Australia/Sydney',
    name: 'A.U.S. Eastern Standard Time',
    time: '(GMT+10:00) Canberra, Melbourne, Sydney'
}, {
    id: 'Australia/Brisbane',
    name: 'E. Australia Standard Time',
    time: '(GMT+10:00) Brisbane'
}, {
    id: 'Australia/Hobart',
    name: 'Tasmania Standard Time',
    time: '(GMT+10:00) Hobart'
}, {
    id: 'Asia/Vladivostok',
    name: 'Vladivostok Standard Time',
    time: '(GMT+10:00) Vladivostok'
}, {
    id: 'Pacific/Port_Moresby',
    name: 'West Pacific Standard Time',
    time: '(GMT+10:00) Guam, Port Moresby'
}, {
    id: 'Pacific/Guadalcanal',
    name: 'Central Pacific Standard Time',
    time: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia'
}, {
    id: 'Pacific/Fiji',
    name: 'Fiji Islands Standard Time',
    time: '(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands'
}, {
    id: 'Pacific/Auckland',
    name: 'New Zealand Standard Time',
    time: '(GMT+12:00) Auckland, Wellington'
}, {
    id: 'Pacific/Tongatapu',
    name: 'Tonga Standard Time',
    time: "(GMT+13:00) Nuku'alofa"
}];


class TimeZoneItemView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        return (
            <div
                className={`
                    ${style.oTimeZoneItem}
                    ${this.state.selected ? style.oSelected : ''}
                `}
                onClick={!this.props.disabled && this.props.onClick}
                onMouseEnter={!this.props.disabled && this.props.onMouseEnter}
            >
                <div>{this.props.item.name}</div>
                <div className={style.oTime}>
                    {this.props.item.time}
                </div>
            </div>
        );
    }
}


class LeftSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            to: '',
            numContacts: 0
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.campaignData.to_group?.items?.length > 0) { // it's a single contact
            this.setState({
                numContacts: 1,
                to: this.props.campaignData.to_group.name
            });
        } else {
            const isTextMessage = this.props.campaignData.campaign_type === 'message';

            let params = {
                rows: 0
            };

            if (this.props.campaignData.to_group) {
                params.by_group_id = this.props.campaignData.to_group.id;
            }

            if (isTextMessage) {
                params.has_phone = true;
                params.unsubscribed_all_messages = false;
            } else {
                params.has_email = true;
                params.unsubscribed_all = false;
            }

            const self = this;

            $.get(`/individuals?${$.param(params)}`, function(data, _, request) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    numContacts: parseInt(request.getResponseHeader('Records-Total'))
                });
            });

            $.get(`/groups/${params.by_group_id}`, function(data) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    to: data.name
                });
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getNumContacts() {
        return this.state.numContacts;
    }

    render() {
        const campaignData = this.props.campaignData;
        const isTextMessage = campaignData.campaign_type === 'message';
        let from = '';

        if (campaignData.from_user) {
            const user = app.globalData.users.find(u => u.id === campaignData.from_user.id) || {};
            from = user[isTextMessage ? 'phone_number': 'email_address'] || '';
        } else if (campaignData.from_custom_field) {
            for (const entity of ['individuals', 'organizations', 'deals']) {
                from = app.globalData.customFieldsInfo[entity][campaignData.from_custom_field.id]?.name;

                if (from) {
                    from += ' (Custom Field)';
                    break;
                }
            }
        }

        return (
            <div
                className={`${style.sBlock} ${style.lLeft}`}
                style={{
                    width: 'calc((100% - 40px) / 3)'
                }}
            >
                <div style={{
                    fontSize: '18px',
                    marginBottom: '40px'
                }}>
                    {isTextMessage ? 'Message' : 'Email'} Summary
                </div>

                <div style={{
                    fontSize: '16px',
                    marginBottom: '20px'
                }}>
                    Your are about to send
                </div>

                <div style={{
                    fontSize: '17px',
                    fontWeight: '600',
                    marginBottom: '50px'
                }}>
                    {campaignData.name}
                </div>

                <div style={{
                    fontSize: '16px',
                    marginBottom: '5px'
                }}>
                    To Mailing List or Recipient(s):
                </div>

                <div className={style.lField}>
                    <div
                        className={style.lValue}
                        style={{width: 'calc(100% - 100px)'}}
                        title={this.state.to}
                    >
                        {this.state.to}
                    </div>

                    <div
                        className={`
                            ${style.lNumContacts}
                            ${this.state.numContacts === 0 ? style.ncEmpty : ''}
                        `}
                    >
                        {this.state.numContacts} contact{this.state.numContacts !== 1 ? 's' : ''}
                    </div>
                </div>

                <div style={{
                    fontSize: '16px',
                    marginTop: '20px',
                    marginBottom: '5px'
                }}>
                    From {isTextMessage ? 'Number' : 'Address'}:
                </div>

                <div className={style.lField}>
                    <div className={style.lValue}>
                        {from || ''}
                    </div>
                </div>

                {!isTextMessage &&
                    <div style={{
                        fontSize: '16px',
                        marginTop: '20px',
                        marginBottom: '5px'
                    }}>
                        Subject Line:
                    </div>
                }

                {!isTextMessage &&
                    <div className={style.lField}>
                        <div className={style.lValue}>
                            {campaignData.subject}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class RightSection extends React.Component {
    constructor(props) {
        super(props);

        let sendNow = true;
        this.sendNowLocked = false;

        if (props.campaignData.campaign_schedule) {
            this.scheduleWhen = props.campaignData.campaign_schedule.when;
            this.scheduleTZ = props.campaignData.campaign_schedule.timezone;
            sendNow = false;
        } else {
            let date = new Date();
            if (props.userType === 'advance') {
                date.setDate(date.getDate() + 1);
                date.setHours(12, 0, 0, 0); // default is tomorrow at 12:00
            } else {
                const delayHours = AppConfig.getValue('campaign.step_launch.basic_user_launch_delay_hours', 24);
                date.setTime(date.getTime() + delayHours * 60 * 60 * 1000);
            }

            this.scheduleWhen = this.getDateString(date);
            this.scheduleTZ = null;
        }

        if (props.userType !== 'advance') {
            sendNow = false;
            this.sendNowLocked = true;
        }

        this.scheduleUTCOffset = new Date().getTimezoneOffset();

        this.state = {
            sendNow: sendNow,
            scheduleDate: null,
            scheduleTime: null,
            timeZoneExpanded: false,
            timeZone: this.scheduleTZ ? TIME_ZONE_LIST.find(tz => tz.id === this.scheduleTZ)?.name : TIME_ZONE_LIST[0].name
        };
    }

    getDateString(date) {
        return date.getFullYear() + '-' + Utilities.addLeadingZeros(date.getMonth() + 1, 2) + '-' + Utilities.addLeadingZeros(date.getDate(), 2) +
               `T${Utilities.addLeadingZeros(date.getHours(), 2)}:${Utilities.addLeadingZeros(date.getMinutes(), 2)}`; // here we send local time, thus we configure ISO datetime manually
    }

    componentDidMount() {
        this.calculateDateTime();

        if (this.state.sendNow) {
            this.props.onDataChange('campaign_schedule', null);
        } else {
            this.props.onDataChange('campaign_schedule', {
                when: this.scheduleWhen,
                utc_offset: this.scheduleUTCOffset,
                timezone: this.scheduleTZ
            });
        }
    }

    calculateDateTime() {
        const dateAndTime = this.scheduleWhen.split('T');
        const date = dateFormat.createDateIgnoringTZ(dateAndTime[0]);
        const time = dateAndTime[1].split(':');

        this.setState({
            scheduleDate: dateFormat.fullDayMonthYearFormat(date),
            scheduleTime: `${time[0]}:${time[1]}`
        });
    }

    handleDateClick(component) {
        if (this.state.sendNow) {
            return;
        }

        const el = $(component);
        const dateTimePicker = new DateTimePicker({
            css: {
                left: el.offset().left - 300,
                top: el.offset().top - 50
            },
            date: this.state.scheduleDate,
            time: this.state.scheduleTime
        });

        dateTimePicker.showPicker();

        const self =  this;

        this.props.parent.listenTo(dateTimePicker, 'date-time:change', function(dateTime) {
            let minDate = new Date();

            if (self.props.userType !== 'advance') {
                // the minimun launch step is the one specified in the configuration
                const delayHours = AppConfig.getValue('campaign.step_launch.basic_user_launch_delay_hours', 24);
                minDate.setTime(minDate.getTime() + delayHours * 60 * 60 * 1000);
            }

            if (dateTime.rawDateTime <= minDate) {
                self.scheduleWhen = self.getDateString(minDate);
            } else {
                self.scheduleWhen = self.getDateString(dateTime.rawDateTime);
            }

            self.calculateDateTime();

            self.props.onDataChange('campaign_schedule', {
                when: self.scheduleWhen,
                utc_offset: self.scheduleUTCOffset,
                timezone: self.scheduleTZ
            });
        });
    }

    handleOptionSelect(sendNow) {
        if (sendNow === this.state.sendNow) {
            return;
        }

        if (sendNow && this.sendNowLocked) {
            return;
        }

        this.setState({
            sendNow: sendNow
        });

        if (sendNow) {
            this.props.onDataChange('campaign_schedule', null);
        } else {
            this.props.onDataChange('campaign_schedule', {
                when: this.scheduleWhen,
                utc_offset: this.scheduleUTCOffset,
                timezone: this.scheduleTZ
            });
        }
    }

    handleTimeZoneClick() {
        this.timeZoneSelector.toggleExpanded(true);

        this.setState({
            timeZoneExpanded: true
        });
    }

    handleTimeZoneSelect(items) {
        const item = items[0];

        if (item.id === 'UTC') {
            this.scheduleTZ = null;
            this.scheduleUTCOffset = new Date().getTimezoneOffset();
        } else {
            this.scheduleTZ = item.id;
            this.scheduleUTCOffset = null;
        }

        this.props.onDataChange('campaign_schedule', {
            when: this.scheduleWhen,
            utc_offset: this.scheduleUTCOffset,
            timezone: this.scheduleTZ
        });

        this.setState({
            timeZone: item.name
        });
    }

    handleTimeZoneClose() {
        this.setState({
            timeZoneExpanded: false
        });
    }

    timeZoneSearchFilter(item, searchText) {
        const stl = searchText.toLowerCase();
        return item.name.toLowerCase().indexOf(stl) !== -1 || item.time.toLowerCase().indexOf(stl) !== -1;
    }

    render() {
        return (
            <div
                className={`${style.sBlock} ${style.lRight}`}
                style={{
                    width: 'calc((((100% - 40px) / 3) + 10px) * 2)'
                }}
            >
                <div style={{
                    fontSize: '18px',
                    marginBottom: '90px'
                }}>
                    Schedule your email
                </div>

                <div className={style.rOptions}>
                    <div
                        className={`
                            ${style.sOption}
                            ${this.state.sendNow ? style.oActive : ''}
                            ${this.sendNowLocked ? style.oDisabled : ''}
                        `}
                        onClick={() => this.handleOptionSelect(true)}
                    >
                        <div className={style.oLine}>
                            <div className={`
                                ${style.oIconContainer}
                                icon-checkmark3
                            `}/>

                            <div className={style.oMessageContainer}>
                                <div style={{fontSize: '16px'}}>Send Now</div>
                                <div style={{fontSize: '12px'}}>Everything is ready to go now</div>
                            </div>
                        </div>

                        {this.sendNowLocked &&
                            <div
                                className='icon-locked'
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    fontSize: '18px'
                                }}
                            />
                        }
                    </div>

                    <div
                        className={`
                            ${style.sOption}
                            ${this.state.sendNow ? '' : style.oActive}
                        `}
                        onClick={(ev) => { ev.stopPropagation(); this.handleOptionSelect(false) }}
                    >
                        <div className={style.oLine}>
                            <div className={`
                                ${style.oIconContainer}
                                icon-checkmark3
                            `}/>

                            <div className={style.oMessageContainer}>
                                <div style={{fontSize: '16px'}}>Schedule Send</div>
                                <div style={{fontSize: '12px'}}>Set exact date + time for send</div>
                            </div>
                        </div>

                        <div
                            className={style.oLine}
                            style={{marginTop: '15px'}}
                        >
                            <div
                                ref={(el) => this.dateComponent = el}
                                className={style.oInfo}
                                onClick={() => this.handleDateClick.bind(this)(this.dateComponent)}
                            >
                                {this.state.scheduleDate || ''}
                            </div>
                        </div>

                        <div
                            className={style.oLine}
                            style={{marginTop: '10px'}}
                        >
                            <div
                                ref={(el) => this.timeComponent = el}
                                className={style.oInfo}
                                onClick={() => this.handleDateClick.bind(this)(this.timeComponent)}
                            >
                                {this.state.scheduleTime || ''}
                            </div>

                            <div style={{position: 'relative'}}>
                                <div
                                    className={style.oTimeZone}
                                    onClick={this.handleTimeZoneClick.bind(this)}
                                    title={this.state.timeZone}
                                >
                                    <div className={style.oName}>{this.state.timeZone}</div>

                                    <div className={style.oArrow}/>
                                </div>

                                <div
                                    className={`
                                        ${style.meDropdown}
                                        ${this.state.timeZoneExpanded ? style.meExpanded : ''}
                                    `}
                                    style={{
                                        right: 0,
                                        top: 0,
                                        width: '140px'
                                    }}
                                >
                                    <NewSelect
                                        ref={(el) => this.timeZoneSelector = el}
                                        data={TIME_ZONE_LIST}
                                        width={250}
                                        itemView={TimeZoneItemView}
                                        text='name'
                                        searchFilter={this.timeZoneSearchFilter}
                                        boxStyle={{
                                            visibility: 'hidden'
                                        }}
                                        onSelect={this.handleTimeZoneSelect.bind(this)}
                                        onClose={this.handleTimeZoneClose.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class StepLaunch extends React.Component {
    constructor(props) {
        super(props);
    }

    isReadyToLaunch() {
        if (this.leftSection.getNumContacts() === 0) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'Unable to send - the selected group contains no contacts. Please add recipients or choose another group.'
            }, this.props.parent);

            return false;
        }

        return true;
    }

    getNumContacts() {
        return this.leftSection.getNumContacts();
    }

    render() {
        return (
            <div className={`${style.cStep} ${style.cLaunch}`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <LeftSection
                        ref={(el) => this.leftSection = el}
                        campaignData={this.props.campaignData}
                    />
                    <RightSection
                        parent={this.props.parent}
                        userType={this.props.userType}
                        campaignData={this.props.campaignData}
                        onDataChange={this.props.updateCampaignData}
                    />
                </div>
            </div>
        );
    }
}

export default StepLaunch;
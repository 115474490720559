import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app';
import ClientModel from 'js/models/client.js'
import tasksTemplate from 'templates/settings/tasks.handlebars'


export default Marionette.Layout.extend({
    className: 'tasks-settings',
    template: Handlebars.compile(tasksTemplate),
    ui: {
        spanSelect: '.select',
        dueDataSelectorContainer: '.due-date-selector-container',
        editCustomDays: '.edit-custom-days'
    },
    events: {
        'change .checkbox-switch-control': function(ev) {
            var enabled = $(ev.target).is(':checked');
            this.ui.dueDataSelectorContainer.toggleClass('hide', !enabled);

            if (enabled) {
                this.tasksSettings = {
                    active_span: 'immediately'
                };
                this.spanSelect.setValue(this.tasksSettings.active_span);
                this.ui.editCustomDays.toggleClass('hide', true);
            }
            else {
                this.tasksSettings = {};
            }

            this.saveSettings();
        },
        'change .edit-custom-days': function(ev) {
            var val = parseInt($(ev.target).val());

            if (val >= 0) {
                this.tasksSettings.custom_days = val;
                this.saveSettings();
            }
        },
        'blur .edit-custom-days': function(ev) {
            var hasValidValue = parseInt($(ev.target).val()) >= 0;
            this.ui.editCustomDays.toggleClass('error', !hasValidValue);
        }
    },
    templateHelpers: function() {
        return {
            customDaysValue: this.tasksSettings.custom_days || 1,
            enabled: !_.isEmpty(this.tasksSettings)
        };
    },
    initialize: function(options) {
        this.tasksSettings = {};

        var client = app.user.get('client');

        if (client.preferences && client.preferences.tasks_settings) {
            this.tasksSettings = JSON.parse(client.preferences.tasks_settings);
        }
    },
    onRender: function() {
        this.spanSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.spanSelect,
            text: 'name',
            data: [{
                id: 'immediately',
                name: 'Immediately'
            }, {
                id: '1-day',
                name: '1 day'
            }, {
                id: '1-week',
                name: '1 week'
            }, {
                id: '14-days',
                name: '14 days'
            }, {
                id: '1-month',
                name: '1 month'
            }, {
                id: '1-year',
                name: '1 year'
            }, {
                id: 'custom',
                name: 'Custom days'
            }],
            value: this.tasksSettings.active_span || 'immediately',
            options: {
                placeholder: 'Select span time',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'due-date-select-popover popover'
            }
        });

        this.listenTo(this.spanSelect, 'change', function(item) {
            this.tasksSettings.active_span = item.id;
            this.ui.editCustomDays.toggleClass('hide', item.id !== 'custom');

            if (item.id === 'custom') {
                this.ui.editCustomDays.val(1);
                this.tasksSettings.custom_days = 1;
            }
            else {
                delete this.tasksSettings.custom_days;
            }

            this.saveSettings();
        });

        if (!_.isEmpty(this.tasksSettings)) {
            this.ui.dueDataSelectorContainer.toggleClass('hide', false);
            this.ui.editCustomDays.toggleClass('hide', this.tasksSettings.active_span !== 'custom');
        }
    },
    saveSettings: function() {
        var client = app.user.get('client');
        var preferences = client.preferences || {};
        var clientModel = new ClientModel(client);

        if (_.isEmpty(this.tasksSettings)) {
            delete preferences.tasks_settings;
        }
        else {
            preferences.tasks_settings = JSON.stringify(this.tasksSettings);
        }

        if (_.isEmpty(preferences)) {
            preferences = null;
        }

        client.preferences = preferences;
        clientModel.save({preferences: preferences}, {patch: true});
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import ContentFileModel from 'js/models/content_file'
import TemplateGeneratorView from 'js/views/template_generator'
import ModalRegion from 'js/views/base/modal-region'
import BaseView from 'js/views/base/base'
import ContentFilesCollection from 'js/collections/content_files'
import MessageBox from 'js/views/message_box'
import Utilities from 'js/utils/utilities'
import viewTemplate from 'templates/base/templates-list.handlebars'
import itemTemplate from 'templates/base/templates-list-item.handlebars'


var ItemView = BaseView.extend({
    className: 'row-is-target',
    tagName: 'tr',
    template: Handlebars.compile(itemTemplate),
    ui: {
        downloadForm: '.download-form'
    },
    events: {
        'click #download': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.ui.downloadForm.submit();
        },
        'click': function(ev) {
            ev.preventDefault();
            this.ui.downloadForm.submit();
        },
        'click #delete': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            var view = this;
            var mbContent = {
                accept_is_negative: true,
                message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({name: this.model.get('name')}),
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this,
                function() { // yes
                    view.trigger('delete-template', this);
                }
            );
        }
    },
    templateHelpers: function() {
        var owner = this.model.get('owner');
        var ownerName = owner.name;

        return {
            icon: Utilities.getTypeIcon(this.model.get('ext')).icon,
            file_name: this.model.get('name'),
            author_name: ownerName,
            author_initials: ownerName.split(' ').map(function (s) { return s.charAt(0); }).join(''),
            author_photo: owner.photo_url,
            downloadAction: app.options.apiUrl + '/content_files/' + this.model.get('id') + '?download',
            current_user_class: app.user.get('id') === owner.id ? 'current-user' : ''
        };
    }
});

var TemplatesList = Marionette.CollectionView.extend({
    tagName: 'tbody',
    itemView: ItemView,
    itemViewOptions: function() {
        return {
            deal: this.options.deal
        };
    },
    initialize: function() {
        var that = this;

        this.listenTo(this, 'itemview:delete-template', function(model) {
            that.trigger('delete-template', model.model);
        });
    }
});

export default Marionette.Layout.extend({
    className: 'list-wrapper',
    template: Handlebars.compile(viewTemplate),
    regions: {
        listRegion: '.table-list',
        viewerRegion: {
            selector: '.template-viewer', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        generatorRegion: {
            selector: '.template-generator', // selector it self not used in ModalRegion
            regionType: ModalRegion.extend({backdrop: 'static'})
        }
    },
    ui: {
        createTemplateButton: '.create-new-template'
    },
    events: {
        'click .create-new-template': function(ev) {
            ev.preventDefault();

            if (!$(ev.target).hasClass('disabled-colorized')) {
                var templateGenerator = new TemplateGeneratorView({deal: this.options.deal});

                this.listenTo(templateGenerator, 'template-generator:generated_and_saved', function() {
                    this.generatorRegion.reset();
                    this.collection = new ContentFilesCollection(this.options.deal.get('generated_files'));
                    this.initTemplateList();
                });

                this.listenTo(templateGenerator, 'template-generator:close', function() {
                    this.generatorRegion.reset();
                });

                this.generatorRegion.show(templateGenerator);
            }
        }
    },
    initialize: function(options) {
        this.options = options;
        this.collection = new ContentFilesCollection(this.options.deal.get('generated_files'));
    },
    onRender: function() {
        this.initTemplateList();

        if (this.ui.createTemplateButton.hasClass('disabled-colorized')) {
            this.ui.createTemplateButton.tooltip({
                title: 'Upgrade account to access Document Templates',
                container: 'body',
                placement: 'bottom'
            });
        }
    },
    initTemplateList: function() {
        var listView = new TemplatesList({ collection: this.collection, deal: this.options.deal });
        this.listRegion.show(listView);

        var view = this;

        this.listenTo(listView, 'delete-template', function(modelToDelete) {
            this.options.deal.fetch({
                success: function(model) {
                    var files = [];
                    var gf = model.get('generated_files');

                    for (var i = 0; i < gf.length; ++i) {
                        if (gf[i].id === modelToDelete.get('id')) {
                            continue;
                        }

                        files.push(gf[i].id);
                    }

                    model.save({
                        generated_files: files
                    }, {
                        patch: true,
                        success: function(model) {
                            $.ajax({
                                url: '/content_files/' + modelToDelete.get('id'),
                                type: 'DELETE'
                            });

                            view.collection = new ContentFilesCollection(model.get('generated_files'));
                            view.initTemplateList();
                        }
                    });
                }
            });
        });
    }
});

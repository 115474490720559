import _ from 'underscore'

import NoteActivityItemView from 'js/views/activity/note-item'
import EmailItem from 'js/views/activity/email-item';
import dateFormat from 'js/utils/date-format'

var AutomationDebugActivityItemView = NoteActivityItemView.extend({
    templateHelpers: function() {
        return _.extend(NoteActivityItemView.prototype.templateHelpers.apply(this, arguments), {
            editorDisabled: true
        });
    }
});


var AutomationDebugEmailViewActivityItemView = EmailItem.extend({
    templateHelpers: function() {
        var createdDate = this.model.get('created');
        var params = this.model.get('params') || {};

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var note = this.model.get('note') || '';

        return {
            formattedDate: createdDate,
            body: note,
            createdFromAutomation: true,
            automation_name: params.automation2_name,
            editable: false,
            allowEdit: false,
        }
    },
});

export {
    AutomationDebugActivityItemView,
    AutomationDebugEmailViewActivityItemView,
}

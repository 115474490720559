import _ from 'underscore'

import ActivityModel from 'js/models/activity'
import ActivityItemView from 'js/views/activity/item'
import AutoActivityItemView from 'js/views/activity/auto-item'
import EmailActivityItemView from 'js/views/activity/email-item'
import MessageActivityItemView from 'js/views/activity/message-item'
import MailshotActivityItemView from 'js/views/activity/mailshot-item'
import NoteActivityItemView from 'js/views/activity/note-item'
import TaskActivityItemView from 'js/views/activity/task-item'
import CallActivityItemView from 'js/views/activity/call-item'
import {AutomationDebugActivityItemView, AutomationDebugEmailViewActivityItemView} from 'js/views/activity/automation-debug-item'
import WebActivityItemView from 'js/views/activity/web-item'
import CommunicationErrorActivityItemView from 'js/views/activity/communication-error-item'
import ConversationMessageItemView from 'js/views/activity/conversation-message'

export default {
    createAutoCommunication: function(medium, communication, item_type, item_id) {
        var type = null;

        if (medium === 'phone') {
            type = 'auto:call';
        }

        if (type) {
            if (item_type === 'leadIndividuals') {
                item_type = 'individuals';
            }

            return new ActivityModel({
                activity_type: type,
                communication: communication,
                item_type: item_type,
                item_id: item_id
            });
        }

        return null;
    },

    createNote: function(note, created, target_date, item_type, item_id, activity_type, tags, funnels) {
        return new ActivityModel({
            note: note,
            created: created,
            target_date: target_date,
            activity_type: activity_type,
            item_type: item_type,
            item_id: item_id,
            tags: tags,
            funnels: funnels,
        });
    },

    getActivityItem: function(model) {
        var type = model.get('activity_type');

        if (!type) {
            return ActivityItemView;
        }

        if (type.indexOf('auto:') !== -1) {
            if (_.contains(['auto:message'], type)) {
                return MessageActivityItemView;
            }
            else if (_.contains(['auto:website_register', 'auto:web_tracking'], type)) {
                return WebActivityItemView;
            }

            return AutoActivityItemView;
        }
        else if (type.indexOf('integration:') === 0) {
            return NoteActivityItemView;
        }
        else if ((type === 'archive:email') || (type === 'archive:opportunity')) {
            return EmailActivityItemView;
        }
        else if ((type === 'archive:email:error') || (type === 'archive:message:error')) {
            return CommunicationErrorActivityItemView;
        }
        else if (type === 'archive:message') {
            return MessageActivityItemView;
        }
        else if (['note', 'appointment:note', 'task:note'].indexOf(type) !== -1) {
            return NoteActivityItemView;
        }
        else if (type === 'call') {
            return CallActivityItemView;
        }
        else if (type === 'task:completed') {
            return TaskActivityItemView;
        }
        else if (type === 'automation:debug') {
            var params = model.get('params') || {};

            if (params.created_from_automation === 'true') {
                return AutomationDebugEmailViewActivityItemView;
            }

            return AutomationDebugActivityItemView;
        }
        else if (type === 'conversation:message') {
            return ConversationMessageItemView;
        }
        else if (
            _.contains(
                [
                    'archive:mailshot_sent', 'automation:archive:mailshot_sent',
                    'archive:mailshot_opened', 'automation:archive:mailshot_opened',
                    'archive:mailshot_url_clicked', 'automation:archive:mailshot_url_clicked',
                    'archive:mailshot_soft_bounce', 'automation:archive:mailshot_soft_bounce',
                    'archive:mailshot_hard_bounce', 'automation:archive:mailshot_hard_bounce',
                    'archive:mailshot_spam', 'automation:archive:mailshot_spam'
                ],
                type
            )
        ) {
            return MailshotActivityItemView;
        }

        return ActivityItemView;
    }
};

import React from 'react';
import classnames from 'classnames';
import vent from 'js/vent'
import Utilities from 'js/utils/utilities';
import AppConfig from 'app/app-config';

import { Button } from 'js/react_views/widgets/widgets';
import Checkbox from 'js/react_views/widgets/checkbox';

import style from './related-files-list.css';

class RelatedFilesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: {},
            allFilesSelected: false
        }
    }

    componentWillMount() {
        this.downloadForms = {};
    }

    handleDelete(ev, file) {
        ev.stopPropagation();
        this.props.onDelete(file);
    }

    handleYoti(ev, file) {
        ev.stopPropagation();
        this.props.onYoti(file);
    }

    showFilePreview(file) {
        vent.trigger('file-preview:show', file);
    }

    canYoti(file) {
        if (!['.pdf', '.doc', '.docx'].includes(file.ext)) {
            return false;
        }
        return true;
    }

    onFileSelect = (fileId) => {
        let selected = this.state.selectedFiles;
        const files = this.props.files.filter(file => this.canYoti(file))

        if (fileId in this.state.selectedFiles) {
            delete selected[fileId];
        } else {
            selected[fileId] = true;
        }

        let allFilesSelected = _.every(files, (file) => (file.id in selected));

        this.setState({
            selectedFiles: selected,
            allFilesSelected: allFilesSelected,
        });
    }

    onAllFilesClick = () => {
        const files = this.props.files.filter(file => this.canYoti(file))
        let selected = this.state.selectedFiles;
        let allFilesSelected;

        if (this.state.allFilesSelected) {
            allFilesSelected = false;
            selected = {}
        } else {
            for (let file of files) {
                if (!(file.id in selected)) {
                    selected[file.id] = true;
                }
            }
            allFilesSelected = true;
        }

        this.setState({
            selectedFiles: selected,
            allFilesSelected: allFilesSelected,
        });
    }

    handleMultipleYoti = (ev) => {
        const files = this.props.files

        for (let fileId in this.state.selectedFiles) {
            let file = files.find(item => item.id === fileId)
            if (!['.pdf', '.doc', '.docx'].includes(file.ext)) {
                vent.trigger('alert:show', {
                    type: function () {
                        return {
                            message: `${file.name} does not have a supported file format (.pdf, .doc or .docx).`,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
                return;
            }
        }

        let yotiFiles = files.filter(file => file.id in this.state.selectedFiles)
        this.props.onYoti(yotiFiles);
    }

    render() {
        const { files, userId, onDelete, onYoti } = this.props;
        const showYotiFeatures = onYoti && AppConfig.getValue('showYotiDealButton', false);

        return (
            <div>
                {
                    // Show the checkboxes if YOTI is enabled.
                    (showYotiFeatures || this.props.onYotiReminder) &&
                    <div className={style.RelatedFilesListToolbar}>
                        <div className={style.toolbar}>
                            <ul className={style.toolbarTools}>
                                {
                                    // Show "Select all files" if there are eligible files
                                    (files.filter(file => this.canYoti(file)).length > 0 && !this.props.onYotiReminder) &&
                                    <li className={style.selectAllTool}>
                                        <Checkbox
                                            checked={this.state.allFilesSelected}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.onAllFilesClick();
                                            }} />
                                    </li>
                                }
                                {
                                    (Object.keys(this.state.selectedFiles).length !== 0) &&
                                    <li className={style.sendToYotiTool}>
                                        <Button
                                            onClick={(ev) => {
                                                this.handleMultipleYoti(ev)
                                            }}>
                                            Send to YOTI
                                        </Button>
                                    </li>
                                }
                                {
                                    this.props.onYotiReminder &&
                                    <li className={`${style.addTool} tool-button yoti`}>
                                        <Button
                                            color="blue"
                                            onClick={this.props.onYotiReminder}
                                        >
                                            Send YOTI Reminder
                                        </Button>
                                    </li>
                                }
                                {
                                    this.props.onYotiReminder &&
                                    <li className={`${style.addTool} ${style.yotiArchiveButton} tool-button yoti`}>
                                        <Button
                                            color="blue"
                                            onClick={this.props.archiveYotiEnvelope}
                                        >
                                            Archive Envelope
                                        </Button>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                }
                <ul className={style.RelatedFilesList}>
                    {_.map(files, (file) =>
                        <li
                            key={file.id}
                            className={style.item}
                            onClick={() => this.showFilePreview(file)}
                        >
                            <div
                                className={style.itemInner}>
                                {
                                    showYotiFeatures &&
                                    <div className={`${style.itemCheckbox} ${!this.canYoti(file) && 'invisible'}`}>
                                        <Checkbox
                                            checked={this.state.selectedFiles[file.id]}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.onFileSelect(file.id);
                                            }} />
                                    </div>
                                }
                                <div
                                    className={style.iconBox}
                                    onClick={(ev) => { ev.stopPropagation(); this.downloadForms[file.id].submit(); }}
                                >
                                    <div className={style.iconBoxInner}>
                                        <i
                                            className={
                                                classnames(
                                                    Utilities.getTypeIcon(file.ext).icon,
                                                    style.typeIcon
                                                )
                                            } />
                                        <i
                                            className={
                                                classnames(
                                                    'icon-arrow-down',
                                                    style.downloadIcon
                                                )
                                            } />
                                    </div>
                                </div>
                                <div className={style.fileName}>
                                    <div className={style.fileNameInner}>
                                        {file.name}
                                    </div>
                                </div>
                                {(showYotiFeatures && this.canYoti(file) && (Object.keys(this.state.selectedFiles).length === 0)) &&
                                    <div className={style.deleteButtonBox}>
                                        <div className={style.FileYotiButton}
                                            onClick={(ev) => { this.handleYoti(ev, file) }}>YOTI</div>
                                    </div>
                                }
                                {onDelete &&
                                    <div className={style.deleteButtonBox}>
                                        <div className={style.FileDeleteButton}
                                            onClick={(ev) => { this.handleDelete(ev, file) }}>Delete</div>
                                    </div>
                                }
                                <div className={style.ownerIconBox}>
                                    {file.owner.photo_url ?
                                        <div
                                            className={style.ownerPhoto}
                                            style={{
                                                backgroundImage: `url('${file.owner.photo_url}')`
                                            }} />
                                        :
                                        <div
                                            className={classnames(
                                                style.ownerIcon,
                                                { [style.currentUser]: file.owner.id === userId }
                                            )}>
                                            {file.owner.name.split(' ').map((s) => (s.charAt(0))).join('')}
                                        </div>
                                    }
                                </div>
                            </div>
                            <form
                                ref={(form) => { this.downloadForms[file.id] = form; }}
                                method="POST"
                                action={'/api/content_files/' + file.id + '?download'}
                                style={{
                                    display: 'none'
                                }}></form>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default RelatedFilesList;

import _ from 'underscore'
import TextManager from 'app/text-manager'

import BaseModel from 'js/models/base'

export default BaseModel.extend({
    name: TextManager.parseText('${ID_FUNNEL, capitalize}'),
    urlRoot: '/funnels',
    validate: function(attrs) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name_undefined = 'Name is required';
        } else if (attrs.name.length === 0) {
            errors.name_too_short = 'Please enter a Funnel name';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import app from 'js/app';
import currencyFormat from 'js/utils/currency_format';
import Utilities from 'js/utils/utilities';


const defineFonts = () => {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pdfMake.tableLayouts = {
        plotsTable_persimmon: {
            hLineColor: () => '#3cb28c',
            vLineColor: () => '#3cb28c'
        },
        plotsTable_charlesChurch: {
            hLineColor: () => '#dfdfdf',
            vLineColor: () => '#dfdfdf'
        }
    };
    pdfMake.fonts = {
        ibmPlexSerif: {
            normal: 'https://fonts.cdnfonts.com/s/15353/IBMPlexSerif-Regular.woff',
            bold: 'https://fonts.cdnfonts.com/s/15353/IBMPlexSerif-Bold.woff'
        },
    
        proximaNova: {
            normal: 'https://use.typekit.net/af/3333ef/00000000000000003b9ad1b5/27/l?subset_id=1&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/3333ef/00000000000000003b9ad1b5/27/d?subset_id=1&fvd=n3&v=3',
            bold: 'https://use.typekit.net/af/04b81b/00000000000000003b9ad1bb/27/l?subset_id=1&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/04b81b/00000000000000003b9ad1bb/27/d?subset_id=1&fvd=n6&v=3'
        },
    
        futuraPt: {
            normal: 'https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3',
            bold: 'https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?subset_id=2&fvd=n5&v=3',
        },
    
        Roboto: {
            normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
            bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
            italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
            bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
       }
    };
}


const NUM_SCHEMES_PER_ROW = 6;
const NUM_PLOTS_PER_PAGE = 5;


const getFooterTextDefinition = (salesInfo, style) => {
    const footerText = [];
    const x = 20;
    const incY = 15;
    let y = style === 'charlesChurch' ? 20 : 50;

    const linesValues = [
        ['Sales Advisor', salesInfo.advisorName],
        ['Telephone', salesInfo.phone],
        ['Email', salesInfo.email],
        ['Website', 'www.persimmonhomes.com'],
        ['Opening Times', salesInfo.openingTimes]
    ];

    for (const lv of linesValues) {
        if (!lv[1]) {
            continue;
        }

        footerText.push({
            style: `footerText_${style}`,
            absolutePosition: {x: 20, y: y},
            text: [
                {text: `${lv[0]}: `, bold: true},
                {text: lv[1]}
            ]
        });

        y += incY;
    }

    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();

    const date = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

    footerText.push({
        style: `footerText_${style}`,
        absolutePosition: {x: 20, y: y + 10},
        text: `Prices and availability correct at time of print: ${date}`
    });

    return footerText;
}


const getSchemesRowDefinition = (schemes, style) => {
    const images = [];
    const names = [];

    for (let i = 0; i < Math.min(schemes.length, NUM_SCHEMES_PER_ROW); ++i) {
        const scheme = schemes[i];

        images.push({
            image: `${scheme.id}_${style}`,
            width: 60,
            height: 60,
            alignment: 'center'
        });

        names.push({
            text: scheme.title,
            style: `schemeTitle_${style}`
        });
    }

    return [images, names];
}

const getPlotsDefinition = (plots, style) => {
    const rowsDefinition = [];

    for (const plot of plots) {
        const row = [];
        const favorite = plot.favorite ? 'Favorite' : '';

        for (const field of ['houseType', 'plot', 'description', 'expectedCompletation', 'sqft', 'price']) {
            let value = plot[field];

            if (field === 'price') {
                value = currencyFormat(app.user.get('client').default_currency, value || 0);
            } else if (field === 'sqft') {
                value = Utilities.numberWithCommas(value);
            }

            row.push({
                text: value,
                style: `tableRow${favorite}_${style}`
            });
        }

        rowsDefinition.push(row);
    }

    return rowsDefinition;
}

const getPageDefinition = (pageNumber, salesInfo, developmentName, plotsDefinition, schemesRow1, schemesRow2, style) => {
    const firstElement = {
        text: developmentName,
        style: `title_${style}`
    };

    if (pageNumber > 0) {
        firstElement.pageBreak = 'before';
    }

    let definition = [firstElement, ...[{
        text: salesInfo.address,
        style: `subtitle_${style}`
    }, {
        layout: `plotsTable_${style}`,
        table: {
            headerRows: 1,
            widths: [80, 40, '*', 80, 45, 80],
            body: [
                [{
                    text: 'House Type',
                    style: `tableHeader_${style}`
                }, {
                    text: 'Plot',
                    style: `tableHeader_${style}`
                }, {
                    text: 'Property Description',
                    style: `tableHeader_${style}`
                }, {
                    text: 'Expected Completion',
                    style: `tableHeader_${style}`
                }, {
                    text: 'Sq Ft',
                    style: `tableHeader_${style}`
                }, {
                    text: 'Price',
                    style: `tableHeader_${style}`
                }],
                ...plotsDefinition
            ]
        }
    }]];

    if (schemesRow1.length > 0) {
        definition = [...definition, ...[{
            text: 'Please talk to your sales advisor about schemes to help you move:',
            style: `message_${style}`
        }, {
            layout: 'noBorders',
            table: {
                widths: Array.from({length: schemesRow1.length}, () => 75),
                body: getSchemesRowDefinition(schemesRow1, style)
            }
        }]]

        if (schemesRow2.length > 0) {
            definition.push({
                layout: 'noBorders',
                margin: [0, 10, 0, 0],
                table: {
                    widths: Array.from({length: schemesRow2.length}, () => 75),
                    body: getSchemesRowDefinition(schemesRow2, style)
                }
            });
        }
    }

    return definition;
}

const getTermsAndConditionPageDefinition = (schemesRow1, schemesRow2, style) => {
    const name = style === 'charlesChurch' ? 'Charles Church' : 'Persimmon';
    const message = `Prices are correct at the time of publication of this price list. However prospective purchasers should always ask their relevant Sales Advisor for the price details of any specific property or properties to check whether the relevant prices remain current and applicable as at the date of their enquiry as these may change between publication dates and particular pricings may apply to specific properties. We take great care whilst preparing our sales literature but its accuracy is not guaranteed, and neither is it intended to constitute a contract, part of a contract or a warranty. If you decide to purchase a ${name} property the agreed price particular to the plot you are proposing to purchase will be set out in the reservation agreement that will be entered into between you and ${name}.`
    let definition = [{
        text: 'Terms and Conditions',
        style: `tnctitle_${style}`,
        pageBreak: 'before'
    }, {
        text: message,
        style: `tnc_${style}`,
        lineHeight: 1.2
    }];

    if (schemesRow1.length > 0) {
        definition = [...definition, ...[{
            text: 'Please talk to your sales advisor about schemes to help you move:',
            style: `message_${style}`
        }, {
            layout: 'noBorders',
            table: {
                widths: Array.from({length: schemesRow1.length}, () => 75),
                body: getSchemesRowDefinition(schemesRow1, style)
            }
        }]];

        if (schemesRow2.length > 0) {
            definition.push({
                layout: 'noBorders',
                margin: [0, 10, 0, 0],
                table: {
                    widths: Array.from({length: schemesRow2.length}, () => 75),
                    body: getSchemesRowDefinition(schemesRow2, style)
                }
            });
        }
    }

    return definition;
}


export default (salesInfo, developmentName, schemes, plots, style) => {
    defineFonts()
    let schemesRow1 = [];
    let schemesRow2 = [];

    if (schemes.length > 0) {
        schemesRow1 = schemes.slice(0, Math.min(NUM_SCHEMES_PER_ROW, schemes.length));

        if (schemes.length > NUM_SCHEMES_PER_ROW) {
            schemesRow2 = schemes.slice(NUM_SCHEMES_PER_ROW);
        }
    }

    const plotsDefinition = getPlotsDefinition(plots, style);
    const numPages = Math.ceil(plots.length / NUM_PLOTS_PER_PAGE);
    let contentDefinition = [];

    for (let i = 0; i < numPages; ++i) {
        contentDefinition = [...contentDefinition, ...getPageDefinition(
            i,
            salesInfo,
            developmentName,
            plotsDefinition.slice(i * NUM_PLOTS_PER_PAGE, (i * NUM_PLOTS_PER_PAGE) + NUM_PLOTS_PER_PAGE),
            schemesRow1,
            schemesRow2,
            style
        )];
    }

    contentDefinition = [...contentDefinition, ...getTermsAndConditionPageDefinition(schemesRow1, schemesRow2, style)];

    const docDefinition = {
        pageSize: 'A4',
        pageMargins: [20, 130, 20, 136],
        header: (currentPage, pageCount, pageSize) => {
            return {
                image: `header_${style}`,
                width: pageSize.width
            };
        },
        footer: (currentPage, pageCount, pageSize) => {
            return [{
                image: `footer_${style}`,
                width: pageSize.width
            }, ...getFooterTextDefinition(salesInfo, style)];
        },
        content: contentDefinition,
        styles: {
            title_persimmon: {
                fontSize: 24,
                color: '#00484a',
                margin: [0, 30, 0, 0],
                bold: true,
                font: 'futuraPt'
            },
            title_charlesChurch: {
                fontSize: 24,
                color: '#333333',
                margin: [0, 20, 0, 0],
                bold: true,
                alignment: 'center',
                font: 'ibmPlexSerif'
            },
            subtitle_persimmon: {
                fontSize: 16,
                color: '#ef795f',
                margin: [0, 5, 0, 13],
                font: 'futuraPt'
            },
            subtitle_charlesChurch: {
                fontSize: 16,
                color: '#333333',
                margin: [0, 5, 0, 13],
                alignment: 'center',
                font: 'ibmPlexSerif'
            },
            tableHeader_persimmon: {
                alignment: 'center',
                fontSize: 10,
                fillColor: '#bddfcd',
                color: '#306d6a',
                bold: true,
                font: 'proximaNova'
            },
            tableHeader_charlesChurch: {
                alignment: 'center',
                fontSize: 10,
                fillColor: '#a09961',
                color: '#ffffff',
                bold: true,
                font: 'proximaNova'
            },
            tableRow_persimmon: {
                alignment: 'center',
                fontSize: 10,
                color: '#16585a',
                font: 'proximaNova'
            },
            tableRowFavorite_persimmon: {
                alignment: 'center',
                fontSize: 10,
                color: '#cf6c54',
                bold: true,
                font: 'proximaNova'
            },
            tableRow_charlesChurch: {
                alignment: 'center',
                fontSize: 10,
                color: '#333333',
                font: 'proximaNova'
            },
            tableRowFavorite_charlesChurch: {
                alignment: 'center',
                fontSize: 10,
                color: '#a42f2c',
                bold: true,
                font: 'proximaNova'
            },
            message_persimmon: {
                margin: [0, 40, 0, 10],
                fontSize: 10,
                color: '#1c4749',
                font: 'proximaNova'
            },
            message_charlesChurch: {
                margin: [0, 60, 0, 10],
                fontSize: 10,
                color: '#333333',
                font: 'proximaNova'
            },
            schemeTitle_persimmon: {
                color: '#333333',
                fontSize: 9,
                font: 'proximaNova',
                alignment: 'center'
            },
            schemeTitle_charlesChurch: {
                color: '#333333',
                fontSize: 9,
                font: 'proximaNova',
                alignment: 'center'
            },
            tnctitle_persimmon: {
                color: '#1c4749',
                fontSize: 14,
                font: 'proximaNova',
                margin: [0, 70, 0, 0],
            },
            tnctitle_charlesChurch: {
                color: '#333333',
                fontSize: 14,
                font: 'proximaNova',
                margin: [0, 70, 0, 0],
            },
            tnc_persimmon: {
                color: '#1c4749',
                fontSize: 10,
                font: 'proximaNova',
                margin: [0, 10, 0, 90]
            },
            tnc_charlesChurch: {
                color: '#333333',
                fontSize: 10,
                font: 'proximaNova',
                margin: [0, 10, 0, 90]
            },
            footerText_persimmon: {
                fontSize: 10,
                color: '#ffffff',
                font: 'proximaNova'
            },
            footerText_charlesChurch: {
                fontSize: 10,
                color: '#ffffff',
                font: 'proximaNova'
            }
        },
        images: {
            header_persimmon:           `${app.options.appUrl}/img/price_lists/ph-header.png`,
            footer_persimmon:           `${app.options.appUrl}/img/price_lists/ph-footer.png`,
            partExchange_persimmon:     `${app.options.appUrl}/img/price_lists/ph-1-part-exchange.png`,
            homeChange_persimmon:       `${app.options.appUrl}/img/price_lists/ph-2-home-change.png`,
            bankMumDad_persimmon:       `${app.options.appUrl}/img/price_lists/ph-3-bomd.png`,
            depositBoost_persimmon:     `${app.options.appUrl}/img/price_lists/ph-4-deposit-boost.png`,
            armedForces_persimmon:      `${app.options.appUrl}/img/price_lists/ph-5-key-worker.png`,
            firstHome_persimmon:        `${app.options.appUrl}/img/price_lists/ph-6-first-homes.png`,
            depositUnlock_persimmon:    `${app.options.appUrl}/img/price_lists/ph-7-deposit-unlock.png`,
            staircase_persimmon:        `${app.options.appUrl}/img/price_lists/ph-8-staircase.png`,
            heyloReachFlex_persimmon:   `${app.options.appUrl}/img/price_lists/ph-9-heylo.png`,
            ownNewRate_persimmon:       `${app.options.appUrl}/img/price_lists/ph-10-onrr.png`,
            ownNewDeposit_persimmon:    `${app.options.appUrl}/img/price_lists/ph-11-ondd.png`,

            header_charlesChurch:         `${app.options.appUrl}/img/price_lists/cc-header.png`,
            footer_charlesChurch:         `${app.options.appUrl}/img/price_lists/cc-footer.png`,
            partExchange_charlesChurch:   `${app.options.appUrl}/img/price_lists/cc-1-part-exchnage.png`,
            homeChange_charlesChurch:     `${app.options.appUrl}/img/price_lists/cc-2-home-change.png`,
            bankMumDad_charlesChurch:     `${app.options.appUrl}/img/price_lists/cc-3-bomd.png`,
            depositBoost_charlesChurch:   `${app.options.appUrl}/img/price_lists/cc-4-deposit-boost.png`,
            armedForces_charlesChurch:    `${app.options.appUrl}/img/price_lists/cc-5-key-workers.png`,
            firstHome_charlesChurch:      `${app.options.appUrl}/img/price_lists/cc-6-first-homes.png`,
            depositUnlock_charlesChurch:  `${app.options.appUrl}/img/price_lists/cc-7-deposit-unlock.png`,
            staircase_charlesChurch:      `${app.options.appUrl}/img/price_lists/cc-8-staircase.png`,
            heyloReachFlex_charlesChurch: `${app.options.appUrl}/img/price_lists/cc-9-heylo.png`,
            ownNewRate_charlesChurch:     `${app.options.appUrl}/img/price_lists/cc-10-onrr.png`,
            ownNewDeposit_charlesChurch:  `${app.options.appUrl}/img/price_lists/cc-11-ondd.png`
        }
    };

    pdfMake.createPdf(docDefinition).open();
}
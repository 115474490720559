import _ from 'underscore'
import d3 from 'd3'
import $ from 'jquery'


function filterByMyDataId(id) {
    return function() {
        var d = d3.select(this).property('mydata');

        if (typeof(d) !== "undefined" && d !== null) {
            if ( 'id' in d ) {
                return d.id === id;
            }
        }
        return false;
    };
}

function shortenURL(url) {
    var res = url;

    // Max length of 20
    if ( res.length > 20 ) {
        res = '...' + res.slice(-17);
    }

    return res;
}

function getServerURL(url) {
    var res = url;

    // Remove protocol
    if ( res.indexOf('//') !== -1 ) {
        res = res.split('//', 2)[1];
    }

    // Remove after first '/'
    res = res.split('/', 2)[0];

    return res;
}

function sourceschart() {
    var id = 'sourceschart',
        x = 0,
        y = 0,
        width = 400,
        height = 300,
        margin = {left:30, right:30, top:70, bottom:30},
        data = {urls:[]},
        colors = [];

    function chart(selection) {
        // Init if needed
        var svg = selection.selectAll('svg').filter(filterByMyDataId(id)),
            innerRect = {
                x: x+margin.left,
                y: y+margin.top,
                w: width - margin.left - margin.right,
                h: height - margin.top - margin.bottom
            },
            maxValue,
            area = width * height,
            //diag = Math.sqrt(width*width + height * height),
            planets,
            sumVisitors,
            sumDiags,
            maxVisitors;

        sumVisitors = 0;
        sumDiags = 0;
        maxVisitors = 0;
        _.each(data.urls, function(d) {
            sumVisitors += d.visitors;
            sumDiags += 2*Math.sqrt(d.visitors/Math.PI);
            maxVisitors = Math.max(maxVisitors, d.visitors);
        });

        function calcLayout() {
            var rowHeight = innerRect.h / 3;

            function rowForIndex(i) {
                if (i < 1) { return 0; }
                if (i < 3) { return 1; }
                return 2;
            }

            function orderInRowForIndex(i) {
                if ( i === 0 || i === 1 || i === 3 ) { return 0; }
                return 1;
            }

            function positionsInRow(r) {
                if ( r === 0 ) { return 1; }
                return 2;
            }

            _.each(data.urls, function(d, i) {
                var row = rowForIndex(i),
                    ordInRow = orderInRowForIndex(i),
                    posInRow = positionsInRow(row),
                    hwidth = innerRect.w / posInRow,
                    x = (ordInRow+0.5) * hwidth,
                    y = (row+0.5) * rowHeight;

                d.x = x;
                d.y = y;
            });
        }
        calcLayout();

        if (svg.empty()) {
            // Initialize
            svg = selection.append('svg');
            svg.property('mydata', {id:id});

            svg.append('g').attr('class', 'pages');
        }

        function planetRadius(d) {
            // Diagonal constraint:
            //return 0.5 * (diag / sumVisitors) * Math.sqrt(d.visitors);

            // Area constraint:
            return Math.sqrt( 0.1 * area * d.visitors /
                ( sumVisitors * Math.PI ) );
        }

        function fontSize(d) {
            var r = planetRadius(d);
            return 0.8*r;
        }

        svg.select('g.pages')
            .attr('transform', 'translate(' + innerRect.x + ' ' +
                innerRect.y + ')');

        planets = svg.select('g.pages').selectAll('g')
            .data(data.urls || [], function(d) {
                return d.url;
            });

        planets
            .call(function() {
                this.transition()
                    .attr('opacity', 1)
                    .attr('transform', function(d) {
                        return 'translate(' + d.x + ' ' + d.y + ')';
                    })
                    .select('circle')
                        .attr('r', planetRadius);

                this.select('text.number').transition()
                    .attr('class', 'number ' + data.segmentation)
                    .attr('font-size', fontSize)
                    .text(function(d) { return d.visitors; });

                this.select('text.url').transition()
                    .attr('y', function(d) {
                            return planetRadius(d) + 20;
                        });
            });

        planets.enter().append('g')
            .attr('transform', function(d) {
                    return 'translate(' + d.x + ' ' + d.y + ')';
                })
            .attr('opacity', 0)
            .on('click', function(d) {
                    var surl = getServerURL(d.url);
                    $(this).trigger('pagefilter', {url: d.url, serverURL: surl, shortUrl:shortenURL(surl)});
                })
            .on('mouseover', function() {
                    var planet = d3.select(this).datum(),
                        serverURL = getServerURL(planet.url);

                    if (serverURL.length !== shortenURL(serverURL).length) {
                        $(this).tooltip({
                            html: true,
                            title: serverURL,
                            container: 'body',
                            placement: 'bottom'
                        });
                        $(this).tooltip('show');
                    }
                    //Remove listener so is only trigger once
                    $(this).on('mouseover', null);
                })
            .call(function() {

                    this.transition().delay(200)
                        .attr('opacity', 1);
                    this.append('circle')
                        .attr('r', planetRadius);
                    this.append('text')
                        .attr('class', 'number ' + data.segmentation)
                        .attr('text-anchor', 'middle')
                        .attr('dominant-baseline', 'central')
                        .attr('font-size', fontSize)
                        .text(function(d) { return d.visitors; });
                    this.append('text')
                        .attr('class', 'url')
                        .attr('text-anchor', 'middle')
                        .attr('y', function(d) {
                                return planetRadius(d) + 15;
                            })
                        .text(function(d) {
                                var serverURL = getServerURL(d.url),
                                shortURL = shortenURL(serverURL);

                                return shortURL;
                            });
                });

        planets.exit()
            .transition().duration(300)
                .attr('opacity', 0)
                .remove();
    }

    chart.id = function(_) {
        if (!arguments.length) { return id; }
        id = _;
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        width = _;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;
        return chart;
    };

    chart.colors = function(_) {
        if (!arguments.length) { return colors; }
        colors = _;
        return chart;
    };

    return chart;
}

export default sourceschart;

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'


// Copied from Backbone 1.1.0
// Fixes invalid event not being triggered when isValid is called.
// https://github.com/jashkenas/backbone/commit/75e2e1f24453e4bb4da4cc60317d8d5cfc5752f0
Backbone.Model.prototype.isValid = function(options) {
    return this._validate({}, _.extend(options || {}, { validate: true }));
};

if (!Date.prototype.toISOString) {

    ( function() {

        var pad = function(number) {
            var r = String(number);
            if ( r.length === 1 ) {
                r = '0' + r;
            }
            return r;
        };

        Date.prototype.toISOString = function() {
            var millis = (this.getUTCMilliseconds()/1000).toFixed(3);
            return (
                this.getUTCFullYear() +
                '-' + pad( this.getUTCMonth() + 1 ) +
                '-' + pad( this.getUTCDate() ) +
                'T' + pad( this.getUTCHours() ) +
                ':' + pad( this.getUTCMinutes() ) +
                ':' + pad( this.getUTCSeconds() ) +
                '.' + String(millis).slice( 2, 5 ) +
                'Z');
        };

    }() );
}

//    (function allowLookupByShortId() {
//        Backbone.Store.prototype = _.extend(Backbone.Store.prototype, {
//            /**
//             * Find the attribute that is to be used as the `id` on a given
//             * object
//             * @param type
//             * @param {String|Number|Object|Backbone.RelationalModel} item
//             * @return {String|Number}
//             */
//            resolveShortIdForItem: function (type, item) {
//                var short_id = (
//                        (_.isString( item ) || _.isNumber( item )) ?
//                        item :
//                        null
//                        );
//
//                if ( short_id === null ) {
//                    if ( item instanceof Backbone.RelationalModel ) {
//                        short_id = item.attributes.short_id;
//                    }
//                    else if ( _.isObject( item ) ) {
//                        short_id = item.short_id;
//                    }
//                }
//
//                // Make all falsy values `null` (except for 0, which could be
//                // an id.. see '/issues/179')
//                if ( !short_id && short_id !== 0 ) {
//                    short_id = null;
//                }
//
//                return short_id;
//            },
//            /**
//             *
//             * @param type
//             * @param {String|Number|Object|Backbone.RelationalModel} item
//             */
//            find: function( type, item ) {
//                var id = this.resolveIdForItem( type, item ),
//                short_id = this.resolveShortIdForItem( type, item ),
//                coll = this.getCollection( type ),
//                obj = null;
//
//                // Because the found object could be of any of the type's
//                // superModel types, only return it if it's actually of the
//                // type asked for.
//                if ( coll ) {
//                    if (id) {
//                        obj = coll.get( id );
//                    } else if (short_id) {
//                        obj = coll.where({short_id: short_id})[0];
//                    }
//                    if ( obj instanceof type ) {
//                        return obj;
//                    }
//                }
//
//                return null;
//            }
//        });
//    }());

var originalParseJSON = $.parseJSON;
// patch so we can parse our CSRF-protected JSON
$.parseJSON = function (str) {
    str = str.replace(/^while\(1\);/, '');
    return originalParseJSON(str);
};
$.ajaxSettings.converters['text json'] = $.parseJSON;

(function () {
    var _0x29b0=[
            "\x77\x68\x69\x63\x68",
            "\x6C\x65\x6E\x67\x74\x68",
            "\x3C\x64\x69\x76\x20\x63\x6C\x61\x73\x73\x3D\x22\x6D" +
            "\x6F\x64\x61\x6C\x20\x68\x69\x64\x65\x20\x66\x61\x64" +
            "\x65\x22\x20\x74\x61\x62\x69\x6E\x64\x65\x78\x3D\x22" +
            "\x2D\x31\x22\x20\x72\x6F\x6C\x65\x3D\x22\x64\x69\x61" +
            "\x6C\x6F\x67\x22\x20\x61\x72\x69\x61\x2D\x6C\x61\x62" +
            "\x65\x6C\x6C\x65\x64\x62\x79\x3D\x22\x6D\x79\x4D\x6F" +
            "\x64\x61\x6C\x4C\x61\x62\x65\x6C\x22\x20\x61\x72\x69" +
            "\x61\x2D\x68\x69\x64\x64\x65\x6E\x3D\x22\x74\x72\x75" +
            "\x65\x22\x3E\x3C\x64\x69\x76\x20\x63\x6C\x61\x73\x73" +
            "\x3D\x22\x6D\x6F\x64\x61\x6C\x2D\x62\x6F\x64\x79\x22" +
            "\x3E\x3C\x2F\x64\x69\x76\x3E\x3C\x2F\x64\x69\x76\x3E",
            "\x68\x69\x64\x65",
            "\x61\x70\x70\x65\x6E\x64",
            "\x62\x6F\x64\x79",
            "\x6D\x6F\x64\x61\x6C",
            "\x73\x68\x6F\x77",
            "\x3C\x69\x66\x72\x61\x6D\x65\x20\x77\x69\x64\x74\x68" +
            "\x3D\x22\x35\x33\x30\x22\x20\x68\x65\x69\x67\x68\x74" +
            "\x3D\x22\x33\x39\x30\x22\x20\x73\x72\x63\x3D\x22\x68" +
            "\x74\x74\x70\x3A\x2F\x2F\x77\x77\x77\x2E\x79\x6F\x75" +
            "\x74\x75\x62\x65\x2E\x63\x6F\x6D\x2F\x65\x6D\x62\x65" +
            "\x64\x2F\x38\x6C\x58\x64\x79\x44\x32\x59\x7A\x6C\x73" +
            "\x3F\x61\x75\x74\x6F\x70\x6C\x61\x79\x3D\x31\x26\x63" +
            "\x6F\x6E\x74\x72\x6F\x6C\x73\x3D\x30\x26\x6C\x6F\x6F" +
            "\x70\x3D\x31\x26\x73\x68\x6F\x77\x69\x6E\x66\x6F\x3D" +
            "\x30\x22\x20\x66\x72\x61\x6D\x65\x62\x6F\x72\x64\x65" +
            "\x72\x3D\x22\x30\x22\x20\x61\x6C\x6C\x6F\x77\x66\x75" +
            "\x6C\x6C\x73\x63\x72\x65\x65\x6E\x3E\x3C\x2F\x69\x66" +
            "\x72\x61\x6D\x65\x3E",
            "\x2E\x6D\x6F\x64\x61\x6C\x2D\x62\x6F\x64\x79",
            "\x66\x69\x6E\x64",
            "\x6F\x6E",
            "\x68\x69\x64\x64\x65\x6E",
            "\x72\x65\x6D\x6F\x76\x65",
            "\x69\x66\x72\x61\x6D\x65",
            "\x73\x74\x6F\x70\x49\x6D\x6D\x65\x64\x69\x61\x74\x65" +
            "\x50\x72\x6F\x70\x61\x67\x61\x74\x69\x6F\x6E",
            "\x70\x72\x65\x76\x65\x6E\x74\x44\x65\x66\x61\x75\x6C\x74",
            "\x6B\x65\x79\x64\x6F\x77\x6E"
        ],
        _0x9383x1=[38,38,40,40,37,39,37,39,66,65],
        _0x9383x2=0,
        _0x9383x3;
    $(document)[_0x29b0[17]](function (_0x9383x4){
        if(_0x9383x4[_0x29b0[0]]===_0x9383x1[_0x9383x2]){
            _0x9383x2+=1;
            if(_0x9383x2===_0x9383x1[_0x29b0[1]]){
                if(!_0x9383x3){
                    _0x9383x3=$(_0x29b0[2]);
                    _0x9383x3[_0x29b0[3]]();
                    $(document[_0x29b0[5]])[_0x29b0[4]](_0x9383x3);
                    _0x9383x3[_0x29b0[6]]({show:false});
                    _0x9383x3[_0x29b0[11]](_0x29b0[7],function (){
                        _0x9383x3[_0x29b0[10]](_0x29b0[9])[_0x29b0[4]](
                                $(_0x29b0[8])
                                );
                    } );
                    _0x9383x3[_0x29b0[11]](_0x29b0[12],function (){
                        _0x9383x3[_0x29b0[10]](
                                _0x29b0[14]
                            )[_0x29b0[13]]();
                    });
                }
                _0x9383x3[_0x29b0[6]](_0x29b0[7]);
            }
            if(_0x9383x4[_0x29b0[0]]===66||_0x9383x4[_0x29b0[0]]===65){
                _0x9383x4[_0x29b0[15]]();
                _0x9383x4[_0x29b0[16]]();
            }
        } else {
            _0x9383x2=0;
        }
    });
}());

Marionette.CompositeRegionView = Marionette.CompositeView.extend({
    constructor: function(options) {
        options = options || {};

        this._firstRender = true;
        this._initializeRegions(options);

        Marionette.CompositeView.call(this, options);
    },
    render: function() {
        if (this._firstRender) {
            // if this is the first render, don't do anything to
            // reset the regions
            this._firstRender = false;
        } else if (this.isClosed) {
            // a previously closed layout means we need to
            // completely re-initialize the regions
            this._initializeRegions();
        } else {
            // If this is not the first render call, then we need to
            // re-initializing the `el` for each region
            this._reInitializeRegions();
        }

        return Marionette.CompositeView.prototype.render.apply(this, arguments);
    },

    close: function() {
        if (this.isClosed) { return; }
        this.regionManager.close();
        Marionette.CompositeView.prototype.close.apply(this, arguments);
    },

    addRegion: function(name, definition) {
        var regions = {};
        regions[name] = definition;
        return this.addRegions(regions)[name];
    },

    addRegions: function(regions) {
        this.regions = _.extend(this.regions || {}, regions);
        return this._buildRegions(regions);
    },

    removeRegion: function(name) {
        return this.regionManager.removeRegion(name);
    },

    _buildRegions: function(regions) {
        var that = this;

        var defaults = {
            parentEl: function() { return that.$el; }
        };

        return this.regionManager.addRegions(regions, defaults);
    },

    _initializeRegions: function (options) {
        var regions;
        this._initRegionManager();

        if (_.isFunction(this.regions)) {
            regions = this.regions(options);
        } else {
            regions = this.regions || {};
        }

        this.addRegions(regions);
    },

    _reInitializeRegions: function() {
        this.regionManager.closeRegions();
        this.regionManager.each(function(region) {
            region.reset();
        });
    },

    _initRegionManager: function() {
        this.regionManager = new Marionette.RegionManager();

        this.listenTo(this.regionManager, 'region:add', function(name, region) {
            this[name] = region;
            this.trigger('region:add', name, region);
        });

        this.listenTo(this.regionManager, 'region:remove', function(name, region) {
            delete this[name];
            this.trigger('region:remove', name, region);
        });
    }
});

// patch to select today date when clicking on jquery ui datepicker "Today" button, which originally only gets that date highlighted
$.datepicker._gotoToday = function(id) {
    var date,
        target = $(id),
        inst = this._getInst(target[0]);

    if (this._get(inst, "gotoCurrent") && inst.currentDay) {
        inst.selectedDay = inst.currentDay;
        inst.drawMonth = inst.selectedMonth = inst.currentMonth;
        inst.drawYear = inst.selectedYear = inst.currentYear;
    }
    else {
        date = new Date();
        inst.selectedDay = date.getDate();
        inst.drawMonth = inst.selectedMonth = date.getMonth();
        inst.drawYear = inst.selectedYear = date.getFullYear();
        // the below two lines are new
        this._setDateDatepicker(target, date);
        this._selectDate(id, this._getDateDatepicker(target));
    }
    this._notifyChange(inst);
    this._adjustDate(target);
};

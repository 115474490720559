import React from 'react';
import $ from 'jquery';
import _ from 'underscore';
import vent from 'js/vent'

import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import FlexParser from './flex-parser';
import FlexRenderer from './flex-edit-renderer';
import {Header} from 'js/react_views/common_components/common';


const DEFAULT_LAYOUT = [
    {
        style: {
            marginTop: '33px',
            paddingLeft: '30px',
            paddingRight: '30px'
        },

        elements: [
            'system.photo_url',
            'system.first_name',
            'system.last_name',
            'system.role',
            'system.organization_id',
            'system.tags',
            'system.funnels'
        ]
    },

    'style.vspace:24px',
    'system.communication_phones',
    'system.communication_emails',

    {
        style: {
            marginTop: '34px',
            paddingLeft: '30px',
            paddingRight: '30px'
        },

        elements: [
            'system.unsubscribed_all',
            'system.unsubscribed_all_messages',
            'system.linkedin',
            'system.twitter',
            'system.facebook',
            'system.instagram',
        ]
    },

    'system.locations',

    {
        style: {
            marginTop: '34px',
            paddingLeft: '30px',
            paddingRight: '30px'
        },

        elements: [
            'system.comments',
            'system.source',
            'system.owner',
            'system.enquiry_count',
        ]
    },

    'system.custom_field_groups'
];

class IndividualFlexEditView extends React.Component {
    constructor(props) {
        super(props);

        this.isNew = !props.individual.id;
        this.components = {};
        this.customFieldComponents = {};
        this.socialComponents = {};
        this.firstNameFocused = false;
        this.layout = this.props.layout || DEFAULT_LAYOUT;
    }

    componentDidMount() {
        if (this.components.first_name) {
            const self = this;

            _.defer(function() {
                self.components.first_name.focus(true);
            });
        }
    }

    componentDidUpdate() {
        if (this.isNew && !this.firstNameFocused) {
            this.firstNameFocused = true;

            const self = this;

            _.defer(function() {
                self.components.first_name.focus();
            });
        }

        if (this.invalidFieldErrors && !_.isEmpty(this.invalidFieldErrors)) {
            let firstInvalidField = null;

            for (const components of [this.components, this.customFieldComponents]) {
                for (const componentId in components) {
                    if (this.invalidFieldErrors[componentId]) {
                        firstInvalidField = components[componentId];
                        break;
                    }
                }

                if (firstInvalidField) {
                    break;
                }
            }

            if (firstInvalidField) {
                firstInvalidField.focus();
                return;
            }

            if (this.customFieldsSection || this.groupsSection) {
                let fieldInfo = this.customFieldsSection ? this.customFieldsSection.getFirstInvalidField() : this.groupsSection.getFirstInvalidField();

                if (fieldInfo) {
                    if (fieldInfo.field.isNotEditableField || fieldInfo.group.props.group.hidden) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Error saving the individual. A required custom field is hidden',
                                    timer: 3000,
                                    classes: 'error'
                                };
                            }
                        });
                    } else {
                        if (fieldInfo.group.state.collapsed) {
                            fieldInfo.group.toggle();
                            _.defer(function() {
                                fieldInfo.field.focus();
                            });
                        } else {
                            fieldInfo.field.focus();
                        }
                    }
                }
            }
        }
    }

    getDataChanges(isSaving) {
        let data = {};
        let communication = [];

        for (const key in this.components) {
            const component = this.components[key];

            if (component.hasChanged()) {
                const value = component.getValue();

                switch(key) {
                    case 'organization_id':
                        if (value) {
                            if (value.createNew) {
                                data['organization'] = {
                                    id: value.id,
                                    name: value.name,
                                    tags: value.tags,
                                };
                            } else {
                                data[key] = value;
                            }
                        } else {
                            data['organization'] = null;
                        }
                        break;

                    case 'owner':
                        data['owner'] = {
                            id: value,
                            update_default_permissions: AppConfig.getValue('updateDefaultPermissions'),
                        };
                        break;

                    default:
                        data[key] = value;
                        break;
                }
            }
        }

        // custom fields outside groups
        for (const key in this.customFieldComponents) {
            const component = this.customFieldComponents[key];

            if (component.hasChanged()) {
                data[`custom_field.${key}`] = component.getValue();
            }
        }

        // social fields
        for (const key in this.socialComponents) {
            const component = this.socialComponents[key];
            const value = component.getValue();

            if (value) {
                communication.push(value);
            }
        }

        if (this.phoneCommunicationList) {
            communication = communication.concat(this.phoneCommunicationList.getValue());
        }

        if (this.emailCommunicationList) {
            communication = communication.concat(this.emailCommunicationList.getValue());
        }

        const commToCheckA = _.sortBy(communication, function(item) {
            return item.medium + '_' + item.name + '_' + item.value;
        });

        const commToCheckB = _.sortBy(this.props.individual.communication, function(item) {
            return item.medium + '_' + item.name + '_' + item.value;
        });

        if (!_.isEqual(commToCheckA, commToCheckB)) {
            data.communication = communication;
        }

        if (this.locationsList) {
            const locations = this.locationsList.getValue();
            const locToCheckA = _.sortBy(locations, function(item) {
                return item.name + '_' + item.address;
            });

            const locToCheckB = _.sortBy(this.props.individual.locations, function(item) {
                return item.name + '_' + item.address;
            });

            if (!_.isEqual(locToCheckA, locToCheckB)) {
                data.locations = locations;
            }
        }

        if (this.customFieldsSection) {
            const values = this.customFieldsSection.getValues() || {};

            for (const key in values) {
                data[`custom_field.${key}`] = values[key];
            }
        } else if (this.groupsSection) {
            const values = this.groupsSection.getValues() || {};

            for (const key in values.system) {
                data[key] = values.system[key];
            }

            for (const key in values.cf) {
                data[`custom_field.${key}`] = values.cf[key];
            }
        }

        if ('unsubscribed_all' in data) {
            data.unsubscribed_all = !data.unsubscribed_all;
        } else if (this.isNew && isSaving) {
            data.unsubscribed_all = AppConfig.getValue('individualUnsubscribedAllDefaultValue', false);
        }

        if ('unsubscribed_all_messages' in data) {
            data.unsubscribed_all_messages = !data.unsubscribed_all_messages;
        } else if (this.isNew && isSaving) {
            data.unsubscribed_all_messages = AppConfig.getValue('individualUnsubscribedAllMessagesDefaultValue', false);
        }

        return data;
    }

    render() {
        this.invalidFieldErrors = {};

        if (this.props.invalidFieldErrors) {
            this.invalidFieldErrors = this.props.invalidFieldErrors;
            this.invalidFieldErrors.last_name = this.invalidFieldErrors.missing_last_name || this.invalidFieldErrors.last_name_too_short;
        }

        const flexParser = new FlexParser({
            modelData: this.props.individual,
            customFieldGroups: this.props.relatedData && this.props.relatedData.processedCustomFields
        });

        const parseInfo = flexParser.parse(this.layout);

        const self = this;
        const flexRenderer = new FlexRenderer({
            layout: parseInfo.elements,
            outOfGroupCustomFields: parseInfo.outOfGroupCustomFields,
            modelData: this.props.individual,
            preloadedFields: this.props.preloadedFields,
            invalidFieldErrors: this.invalidFieldErrors,
            customFieldGroups: this.props.relatedData.processedCustomFields,
            onRefCreated: function(type, itemId, el) {
                if (itemId) {
                    switch(type) {
                        case 'system':
                            self.components[itemId] = el;
                            break;

                        case 'cf':
                            self.customFieldComponents[itemId] = el;
                            break;

                        case 'social':
                            self.socialComponents[itemId] = el;
                            break;
                    }
                } else {
                    self[type] = el;
                }
            }
        });

        let fields = flexRenderer.render();

        return (
            <div>
                <Header
                    isNew={this.isNew}
                    entityType={TextManager.parseText('${ID_INDIVIDUAL, capitalize}')}
                    is_favorite={this.props.individual.is_favorite}
                    onToggleFavorite={this.props.onToggleFavorite}
                    onDelete={this.props.onDelete}
                    onCancel={() => this.props.onCancel(this.getDataChanges(false))}
                    onSave={() => this.props.onSave(this.getDataChanges(true))}
                    onShowPermissionView={this.props.onShowPermissionView}
                />
                <div style={{
                    position: 'fixed',
                    width: '32%',
                    height: '100%',
                    overflow: 'hidden',
                    zIndex: 15
                }}>
                    <div style={{
                        height: 'calc(100% - 110px)',
                        overflow: 'auto'
                    }}>
                        {fields}
                    </div>
                </div>
            </div>
        );
    }
}

export default IndividualFlexEditView;

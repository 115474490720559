import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import dateFormat from 'js/utils/date-format'
import Utilities from 'js/utils/utilities'
import MessageBox from 'js/views/message_box';
import $ from 'jquery';
import vent from 'js/vent';
import DropTargetContainer from 'js/react_views/detail_view_components/DropTargetContainer';
import style from './checklists.css';

class TaskItem extends React.Component {
    constructor(props) {
        super(props);
    }
    
    componentWillMount() {
        this.downloadForms = {};
    }

    handleUploadClick() {
        let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        this.fileSelectInput.dispatchEvent(event);
    }

    handleDrop(files, taskId, taskCompleted) {
        this.props.onFileUpload(taskId, taskCompleted, files)
    }

    handleFileSelectDialog(taskId, taskCompleted) {
        this.props.onFileUpload(taskId, taskCompleted, this.fileSelectInput.files)
    }

    handleFileDelete(ev, taskId, taskCompleted, file) {
        ev.stopPropagation();
        this.props.onFileDelete(taskId, taskCompleted, file);
    }

    render() {
        const task = this.props.task;
        const taskCompleted = !!task.completed_date;
        const taskAuthorizationError = !!task.authorizationError;
        const dueDate = task.due_date && dateFormat.parseDate(task.due_date)

        const containerClasses = classnames({
            [style.TaskContainer]: true,
            [style.TaskCompleted]: taskCompleted
        });
        
        const taskStatusClasses = classnames({
            [style.TaskStatus]: true,
            [style.TaskDueDate]: dueDate && dueDate < new Date()
        })

        const taskDueDateClasses = classnames({
            [style.TaskCompletedDate]: true,
            [style.TaskContentDueDate]: dueDate && dueDate < new Date()
        })

        const contentClasses = classnames({
            [style.TaskContent]: true,
            [style.TaskErrorAnimation]: taskAuthorizationError
        });

        task.authorizationError = null;

        const tickIcon = this.props.itemsAreTicked ? 'icon-checkmark3' : '';

        return (
            <div className={containerClasses}>
                <div className={style.TaskEntry}/>
                <div className={style.Task}>
                    <i
                        className={`${taskStatusClasses} ${tickIcon}`}
                        onClick={() => this.props.onTaskStatusChanged(task.id, !taskCompleted)}
                    />
                    <div className={contentClasses}>
                        <div className={style.TaskDescription}>{task.text}</div>
                        {task.completed_date &&
                            <div className={style.TaskCompletedDate}>&ndash; {dateFormat.dueItemFormat(dateFormat.parseDate(task.completed_date))}
                                {task.completed_by && `, completed by ${task.completed_by}`}</div>
                        }
                        {!task.completed_date && task.due_date &&
                            <div className={taskDueDateClasses}>&ndash; {dateFormat.dueItemFormat(dateFormat.parseDate(task.due_date))}</div>
                        }
                        {task.upload_files_available &&
                            <div className={style.TaskFiles}>
                                {_.map(task.files || [], (file) => {
                                    const fileIcon = Utilities.getTypeIcon(file.ext);
                                    const userInitials = file.owner ? file.owner.split(' ').map(function (s) { return s.charAt(0); }).join('') : ''

                                    return (
                                        <div
                                            className={style.TaskFile}
                                            key={`task_file_${file.id}`}
                                            onClick={() => {this.downloadForms[file.id].submit();}}
                                        >
                                            <div className={style.iconBox}>
                                                <div className={style.iconBoxInner}>
                                                    <i
                                                        className={
                                                            classnames(
                                                                fileIcon.icon,
                                                                style.typeIcon
                                                            )
                                                        } />
                                                    <i
                                                        className={
                                                            classnames(
                                                                'icon-arrow-down',
                                                                style.downloadIcon
                                                            )
                                                        } />
                                                </div>
                                            </div>
                                            <span className={style.TaskFileName}>{file.name}</span>
                                            <div className={style.TaskFileDeleteButton}
                                            onClick={(ev) => {this.handleFileDelete(ev, task.id, taskCompleted, file)}}>Delete</div>
                                            <div className={style.TaskFileInitials}>{userInitials}</div>
                                            <form
                                                ref={(form) => { this.downloadForms[file.id] = form; }}
                                                method="POST"
                                                action={'/api/content_files/' + file.id + '?download'}
                                                style={{
                                                    display: 'none'
                                            }}></form>                                            
                                        </div>
                                    )
                                })}
                                {this.props.fileUpload && task.id === this.props.fileUpload.taskId ?
                                <div className={style.TaskFileProgressContainer}>
                                    <div className={style.TaskFileProgressInnerContainer}>
                                        <div className={style.TaskFileProgress} style={{width: this.props.fileUpload.percentComplete + '%',}}></div>
                                    </div>
                                    <span style={{float: 'left'}}>Now uploading…</span>
                                    {this.props.fileUpload.numFilesUploaded} out of {this.props.fileUpload.numFiles} file{this.props.fileUpload.numFiles > 1 && 's'} completed
                                </div>
                                :
                                <div className={style.TaskUploadFileArea}>
                                    <DropTargetContainer
                                            onDrop={(file) => this.handleDrop(file, task.id, taskCompleted)}>
                                    </DropTargetContainer>
                                    <span>
                                        Drag & drop files here</span>


                                    <span style={{margin: '0px 5px', color: 'grey'}}>or</span>
                                    <span
                                        className={style.TaskUploadFileLink}
                                        onClick={this.handleUploadClick.bind(this)}
                                    >
                                        Upload from your Computer
                                    </span>
                                    <input
                                        ref={(input) => {this.fileSelectInput = input}}
                                        onChange={this.handleFileSelectDialog.bind(this, task.id, taskCompleted)}
                                        style={{display: 'none'}} type='file' multiple
                                    />
                                </div>
                            }
                            </div>
                        }
                    </div>
                </div>
                {this.props.exitVisible &&
                    <div className={style.TaskExit}>
                        <div className={style.TaskExitLine}/>
                        <i className={`${style.TaskExitStatus} icon-checkmark3`}/>
                    </div>
                }
            </div>

        );
    }
}


class ChecklistContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completedCollapsed: false,
            nextStepsCollapsed: false
        };
    }

    refresh() {
        this.forceUpdate();
    }

    render() {
        let name = '';
        let completedTasks = [];
        let nextStepsTasks = [];

        if (this.props.checklist) {
            name = this.props.checklist.name;
            completedTasks = this.props.checklist.completed;
            nextStepsTasks = this.props.checklist.next_steps;
        }

        const sections = [{
            name: 'Completed',
            collapseState: 'completedCollapsed',
            tasks: completedTasks
        }, {
            name: 'Next Steps',
            collapseState: 'nextStepsCollapsed',
            tasks: nextStepsTasks
        }];

        const checklistCompleted = this.props.checklist && this.props.checklist.status === 'completed';
        const contentClasses = classnames({
            [style.Content]: true,
            [style.Visible]: this.props.visible,
            [style.ChecklistCompleted]: checklistCompleted,
            [style.ChecklistInProgress]: this.props.checklist && completedTasks.length > 0 && nextStepsTasks.length > 0,
            [style.ChecklistNotStarted]: this.props.checklist && completedTasks.length === 0
        });

        return (
            <div className={contentClasses}>
                <div className={style.Title}>
                    <div className={style.Name}>{name}</div>
                    <i
                        className='icon-arrow-right'
                        onClick={this.props.onContentClosed}
                    />
                </div>
                <div className={style.SectionsList}>
                    {_.map(sections, (section, sectionIndex) => {
                        const listClasses = classnames({
                            [style.TasksList]: true,
                            [style.TaskListCollapsed]: this.state[section.collapseState]
                        });

                        if (section.tasks.length === 0) {
                            return null;
                        }

                        return (
                            <div
                                className={style.Section}
                                key={`section_${sectionIndex}`}
                            >
                                <div
                                    className={style.SectionHeader}
                                    onClick={() => this.setState({ [section.collapseState]: !this.state[section.collapseState] })}
                                >
                                    <div className={style.SectionName}>{section.name}</div>
                                    <i className={this.state[section.collapseState] ? 'icon-caret-right' : 'icon-caret-down'}/>
                                </div>
                                <div className={listClasses}>
                                    {_.map(section.tasks, (task) => {
                                        return (
                                            <TaskItem
                                                key={`task_${task.id}`}
                                                task={task}
                                                fileUpload={this.props.fileUpload}
                                                exitVisible={(sectionIndex === 0 && checklistCompleted) || (sectionIndex === 1 && !checklistCompleted)}
                                                onFileUpload={(taskId, completed, file) => this.props.onFileUpload(this.props.checklist, taskId, completed, file)}
                                                onFileDelete={(taskId, completed, file) => this.props.onFileDelete(this.props.checklist, taskId, completed, file)}
                                                onTaskStatusChanged={(taskId, completed) => this.props.onTaskStatusChanged(this.props.checklist, taskId, completed)}
                                                itemsAreTicked={sectionIndex === 0}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

class ChecklistItem extends React.Component {
    refresh() {
        this.forceUpdate();
    }

    render() {
        const classes = classnames({
            [style.Item]: true,
            [style.ItemCompleted]: this.props.checklist.status === 'completed',
            [style.ItemInProgress]: this.props.checklist.status === 'in_progress'
        });

        return (
            <div
                className={classes}
                onClick={() => this.props.onItemClick(this.props.checklist)}
            >
                <div className={style.Name}>{this.props.checklist.name}</div>
                <div className={style.StatusContainer}>
                    <div className={`${style.Status} icon-checkmark3`}/>
                    {this.props.checklist.status === 'in_progress' && <div className={style.Remaining}>{this.props.checklist.next_steps.length}</div>}
                </div>
            </div>
        );
    }
}

class ReactChecklistsView extends React.Component {
    constructor(props) {
        super(props);

        this.checklistItems = {};

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            activeChecklist: null,
            contentVisible: false,
            fileUpload: null,
        };
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
          this.setState({contentVisible: false})
        }
    }

    refreshChecklistData(checklist){
        this.checklistItems[checklist.id].refresh();
        this.checklistContent.refresh();
    }

    onUploadFileError(files, errorThrown){
        vent.trigger('alert:show', {
            type: function() {
                var filesText = files.length > 1 ? 'files' : 'file';
                return {
                    message: Handlebars.compile('Error uploading {{filesText}}: {{errorMsg}}')({
                        filesText: filesText,
                        errorMsg: errorThrown
                    }),
                    timer: 3000,
                    classes: 'error'
                };
            }
        });
    }

    addOwnerInitialsToFile(files){
        return files.map(file => {file.owner = app.user.get('name'); return file});
    }

    handleFileUpload(checklist, taskId, taskCompleted, files) {
        this.setState({fileUpload: true})
        var self = this;
        var formData = new FormData(),
        totalSize = 0,
        cumulativeSize = [];

        _.each(files, function(f) {
            formData.append('files', f);
            totalSize += f.size;
            cumulativeSize.push(totalSize);
        });

        $.ajax({
            type: 'POST',
            url: `/${self.props.entityType}/${self.props.model.get('id')}/checklist_items/${taskId}/files`,
            contentType: false,
            processData: false,
            data: formData,
            xhr: function() {
                var xhr = new XMLHttpRequest();

                xhr.upload.addEventListener("progress", (event) => {
                    var numFilesUploaded = 0,
                        percentComplete;

                    if (event.lengthComputable) {
                        percentComplete = event.loaded / event.total * 100;

                        for (var i = cumulativeSize.length - 1; i >= 0; i--) {
                            if (event.loaded >= cumulativeSize[i]) {
                                numFilesUploaded = i + 1;
                                break;
                            }
                        }

                        self.setState({fileUpload: {
                            numFiles: files.length,
                            numFilesUploaded: numFilesUploaded,
                            percentComplete: percentComplete,
                            taskId: taskId
                        }})
                    }
                }, false);

                return xhr;
            }
        }).done(function(data) {
            if(taskCompleted){
                const taskIdx = checklist.completed.findIndex(task => task.id === taskId);
                let task = checklist.completed[taskIdx]
                checklist.completed.splice(taskIdx, 1);
                data = self.addOwnerInitialsToFile(data);
                task.files.push(...data)
                checklist.completed.push(task);
                checklist.completed.sort((a, b) => (a.order > b.order) ? 1 : -1)
            }else{
                const taskIdx = checklist.next_steps.findIndex(task => task.id === taskId);
                let task = checklist.next_steps[taskIdx]
                checklist.next_steps.splice(taskIdx, 1);
                data = self.addOwnerInitialsToFile(data);
                task.files.push(...data)
                checklist.next_steps.push(task);
                checklist.next_steps.sort((a, b) => (a.order > b.order) ? 1 : -1)
            }
            vent.trigger('update-related-files');
            self.setState({fileUpload: null})
            self.refreshChecklistData(checklist)
        }).fail(function(xhr, textStatus, errorThrown) {
            self.onUploadFileError(files, errorThrown)
            self.setState({fileUpload: null})
        });
    }

    handleFileDelete(checklist, taskId, taskCompleted, file){
        document.removeEventListener('mousedown', this.handleClickOutside);

        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you would like to remove {{name}}?')({name: file.name}),
            icon: 'icon-trashcan'
        };
        var self = this;

        MessageBox.showYesNo(mbContent, this.props.parent,
            function() { // yes
                $.ajax({
                    url:`/${self.props.entityType}/${self.props.model.get('id')}/checklist_items/${taskId}/files/${file.entity_file_id}`,
                    type: 'DELETE',
                    complete: function() {
                        if(taskCompleted){
                            const taskIdx = checklist.completed.findIndex(task => task.id === taskId);
                            let task = checklist.completed[taskIdx]
                            checklist.completed.splice(taskIdx, 1);
                            task.files = task.files.filter(f => f.entity_file_id !== file.entity_file_id) || []
                            checklist.completed.push(task);
                            checklist.completed.sort((a, b) => (a.order > b.order) ? 1 : -1)
                        }else{
                            const taskIdx = checklist.next_steps.findIndex(task => task.id === taskId);
                            let task = checklist.next_steps[taskIdx]
                            checklist.next_steps.splice(taskIdx, 1);
                            task.files = task.files.filter(f => f.entity_file_id !== file.entity_file_id) || []
                            checklist.next_steps.push(task);
                            checklist.next_steps.sort((a, b) => (a.order > b.order) ? 1 : -1)
                        }
                        vent.trigger('update-related-files');
                        self.refreshChecklistData(checklist)
                        document.addEventListener('mousedown', self.handleClickOutside);
                    }
                });
            }
        );
    }

    onUpdateTaskError(checklist, error){
        let errorMsg = ''
        if(error.status === 400){
            errorMsg = 'You are not authorized to complete this task.'
        }else {
            errorMsg = 'There was an error processing this request.'
        }
        vent.trigger('alert:show', {
            type: function() {
                return {
                    message: errorMsg,
                    classes: 'load-error error',
                    timer: 3000
                };
            }
        });
        checklist.authorizationError = true;
    }

    onTaskStatusChanged(checklist, taskId, completed) {
        if (completed) {
            const taskIdx = checklist.next_steps.findIndex(task => task.id === taskId);

            if (taskIdx !== -1) {
                const task = checklist.next_steps[taskIdx];
                var self = this;
                $.ajax({
                    type: 'PATCH',
                    url: `/${self.props.entityType}/${self.props.model.get('id')}/checklist_items/${task.id}`,
                    data: JSON.stringify({completed}),
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function () {
                        task.completed_date = new Date().toISOString();
                        task.completed_by = app.user.get('name');
                        checklist.next_steps.splice(taskIdx, 1);
                        checklist.completed.push(task);
                        checklist.completed.sort((a, b) => (a.order > b.order) ? 1 : -1)
                        checklist.status = (checklist.next_steps.length === 0) ? 'completed' : 'in_progress';
                        if(checklist.auto_add_tasks){
                            vent.trigger('update-tasks');
                        }
                        vent.trigger('update-checklist-progress');
                        self.refreshChecklistData(checklist);
                    },
                    error: function(error) {
                        self.onUpdateTaskError(checklist.next_steps[taskIdx], error);
                        self.refreshChecklistData(checklist);
                    }
                });
            }
        } else {
            const taskIdx = checklist.completed.findIndex(task => task.id === taskId);

            if (taskIdx !== -1) {
                const task = checklist.completed[taskIdx];
                var self = this;
                $.ajax({
                    type: 'PATCH',
                    url: `/${self.props.entityType}/${self.props.model.get('id')}/checklist_items/${task.id}`,
                    data: JSON.stringify({completed}),
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function () {
                        task.completed_date = null;
                        task.completed_by = null;
                        checklist.completed.splice(taskIdx, 1);
                        checklist.next_steps.push(task);
                        checklist.next_steps.sort((a, b) => (a.order > b.order) ? 1 : -1)
                        checklist.status = (checklist.completed.length === 0) ? 'not_started' : 'in_progress';
                        if(checklist.auto_add_tasks){
                            vent.trigger('update-tasks');
                        }
                        vent.trigger('update-checklist-progress');
                        self.refreshChecklistData(checklist);
                    },
                    error: function(error) {
                        self.onUpdateTaskError(checklist.completed[taskIdx], error);
                        self.refreshChecklistData(checklist);
                    }
                });

            }
        }

    }

    render() {
        const {checklists} = this.props;
        return (
            <div className={style.ChecklistsView} ref={this.setWrapperRef}>
                <div className={style.Column}>
                    <div className={style.Header}>
                        <i className='icon-checklist'></i>
                    </div>
                    <div className={style.ItemsList}>
                        {checklists && _.map(checklists, (checklist) => {
                            checklist = checklist.attributes
                            return (
                                <ChecklistItem
                                    ref={(el) => this.checklistItems[checklist.id] = el}
                                    key={`checklist_${checklist.id}`}
                                    checklist={checklist}
                                    onItemClick={() => this.setState({ contentVisible: true, activeChecklist: checklist })}
                                />
                            )
                        })}
                    </div>
                </div>
                <ChecklistContent
                    ref={(el) => this.checklistContent = el}
                    checklist={this.state.activeChecklist}
                    visible={this.state.contentVisible}
                    onContentClosed={() => this.setState({ contentVisible: false })}
                    onFileUpload={this.handleFileUpload.bind(this)}
                    onFileDelete={this.handleFileDelete.bind(this)}
                    fileUpload={this.state.fileUpload}
                    onTaskStatusChanged={this.onTaskStatusChanged.bind(this)}
                />
            </div>
        );
    }
}

var ChecklistsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <ReactChecklistsView parent={this} model={this.model} entityType={this.model.get('type')} checklists={this.options.checklists}/>,
            this.$el.get(0)
        );
    },
});

export default ChecklistsView;

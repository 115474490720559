import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import UserModel from 'js/models/user'
import TeamModel from 'js/models/team'
import api from 'js/api'
import security from 'js/utils/security'
import vent from 'js/vent'
import itemPermissionsTemplate from 'templates/inner_item_permissions.handlebars'


// ...
var InnerItemPermissionsView = Marionette.Layout.extend({
    className: 'item-permissions-container',
    template: Handlebars.compile(itemPermissionsTemplate),
    ui: {
        deleteButton: '.delete-button',
        permissionsColumns: '.permissions-column'
    },
    events: {
        'click .item': 'selectItem',
        'click .add-item': 'addItem',
        //'blur .field-search': 'onFieldBlur',
        'click .create-item': 'createItem',
        'click .delete-button': 'deleteSelected'
    },
    permittedUsers : { view: [], edit: [], all: [] },

    onRender: function() {
        if ( this.permittedUsers ) {
            this.initializeColumns();
        }

        if ( security.checkPermission( 'change_permissions', this.model ) || this.options.canChangePermissions ) {
            this.$el.addClass( 'has-edit-permission' );
        }
    },

    templateHelpers: function() {
        return {
            columns: [
                {
                    column_class: 'view',
                    column_name: 'view',
                    column_title: 'View',
                    permissions: this.getPermissionsTemplateHelpers( 'view', this.permittedUsers.view )
                },
                {
                    column_class: 'edit',
                    column_name: 'edit',
                    column_title: 'Edit',
                    permissions: this.getPermissionsTemplateHelpers( 'edit', this.permittedUsers.edit )
                },
                {
                    column_class: 'admin',
                    column_name: 'all',
                    column_title: 'All',
                    permissions: this.getPermissionsTemplateHelpers( 'all', this.permittedUsers.all )
                }
            ]
        };
    },

    getPermissionsTemplateHelpers: function( type, permissions )
    {
        var rdo = [];

        function getInitials(name) {
            return name.split(' ').map(function (s) { return s.charAt(0); }).join('');
        }

        for ( var i = 0; i < permissions.length; ++i ) {
            var p = permissions[ i ],
                authorInitials;

            if (!p.isTeam) {
                authorInitials = getInitials(p.name);
            }

            rdo.push( {
                name: p.name,
                isOwner: p.isOwner,
                type: type,
                dbid: p.id,
                isTeam: p.isTeam,
                authorInitials: authorInitials,
                authorPhoto: p.userPhoto
            } );
        }

        return rdo;
    },

    setData: function( acl ) {
        var view = this;

        this.loadUsers( acl, function() {
            view.render();
        } );
    },

    initializeColumns: function() {
        this.selects = {};

        var view = this;
        var items = {
            'view': this.$el.find('input[name=view-search]'),
            'edit': this.$el.find('input[name=edit-search]'),
            'all': this.$el.find('input[name=all-search]')
        };
        _.each(items, function(element, type) {
            var select = new backboneSelect2.SelectView({
                view: view,
                $el: element,
                url: '/teams',
                search: true,
                text: 'title',
                getItem: function(item) {
                    var id = item[this.id],
                        text = item[this.text];

                    if (item.type === 'salesseek.core.models.teams.Team') {
                        text += ' (Team)';
                    }

                    return {
                        id: id,
                        text: text
                    };
                },
                searchFilter: function(item) {
                    var id = item.id;
                    var users = _.flatten(_.values(view.permittedUsers), true);
                    return !_.find(users, function(item) {
                        return item.id === id;
                    });
                },

                options: {
                    placeholder: 'Search for a user',
                    dropdownCssClass: 'deal-select-popover popover select2-drop-wider',
                    formatResult: function(item) {
                        var title = `${_.escape(item.title)}`;
                        return `<span title="${title}">${title}</span>`;
                    },
                }
            });
            element.select2('container').attr('type', type);

            view.selects[type] = select;
        });

        // ...
        this.manageDroppablesAreas();
    },

    manageDroppablesAreas: function() {
        var view = this;

        // ...
        // BUG: This causes the modal to disappear on mouseout
        // this.$el.find('[data-toggle=tooltip]').tooltip({
        //     container: 'body'
        // });

        // DRAG AND DROP
        // Draggable items
        this.$el.find('.item').draggable( {
            handle: '.item-handle',
            revert: 'invalid',
            revertDuration: 150
        } );

        // Column drop areas
        this.ui.permissionsColumns.droppable( {
            accept: function( item ) {
                return ( $( this ).has( item ).length < 1) ;
            },

            drop: function( dropEv, item ) {
                var dropArea = $( dropEv.target );
                var type = dropArea.attr( 'name' );

                $(item.draggable)
                    .addClass('dropped')
                    .fadeOut(300, function() {
                        view.changePermittedUser( item.draggable, type );
                        view.save();
                    });
            },

            hoverClass: 'hover',
            activeClass: 'highlight'
        });

        // Delete drop area
        this.ui.deleteButton.droppable( {
            hoverClass: 'hover',
            activeClass: 'highlight',
            drop: function( dropEv, item ) {
                var dropArea = $( dropEv.target );
                var id = item.draggable.attr( 'dbid' );
                var type = item.draggable.attr( 'type' );

                dropArea.addClass( 'active' );

                $(item.draggable)
                    .addClass('dropped')
                    .fadeOut(300, function() {
                        view.delPermittedUser( type, id );
                        view.save();
                        dropArea.removeClass('active');
                    });
            }
        } );
    },

    getUserInfo: function( id, callback ) {
        var user;

        if ( id.slice( 0, 20 ) === 'salesseek.core.owner' ) {
            user = new UserModel({ id: id.slice(21) });
            if ( !user.get( 'name' ) && !user.get( 'email_address' ) ) {
                user.fetch( { success: function () {
                    callback( user.get( 'name' ) || user.get( 'email_address' ), user.get( 'id' ), true, false, user.get( 'photo_url' ) );
                }, error: function() { callback( id.slice( 20 ), 0 ); } } );
            } else {
                callback( user.get( 'name' ) || user.get( 'email_address' ), user.get( 'id' ), true, false, user.get( 'photo_url' ) );
            }
        } else if ( id.slice( 0, 19 ) === 'salesseek.core.team' ) {
            var team = new TeamModel({ id: id.slice( 20 ) });
            if ( !team.get( 'name' ) ) {
                team.fetch( { success: function() {
                    var teamId = team.get( 'id' )

                    if (team.get( 'team_type' ) === 'user') {
                        user = new UserModel({ id: teamId });

                        if ( !user.get( 'name' ) && !user.get( 'email_address' ) ) {
                            user.fetch( { success: function () {
                                callback( team.get( 'name' ), teamId, false, false, user.get( 'photo_url' ) );
                            }, error: function() { callback( teamId ); } } );
                        } else {
                            callback( team.get( 'name' ), teamId, false, false, user.get( 'photo_url' ) );
                        }
                    } else {
                        callback( team.get( 'name' ), teamId, false, true );
                    }
                }, error: function() { callback( id.slice( 20 ), 0 ); } } );
            } else {
                callback( team.get( 'name' ), team.get( 'id' ), false, true );
            }
        } else {
            user = new UserModel({ id: id });
            if ( !user.get( 'name' ) && !user.get( 'email_address' ) ) {
                user.fetch( { success: function () {
                    callback( user.get( 'name' ) || user.get( 'email_address' ), user.get( 'id' ), false, user.get( 'photo_url' ) );
                }, error: function() { callback( id.slice( 20 ), 0 ); } } );
            } else {
                callback( user.get( 'name' ) || user.get( 'email_address' ), user.get( 'id' ), false, user.get( 'photo_url' ) );
            }
        }
    },

    loadUsers: function( input, callback ) {
        var view = this;
        var namesFetched = 0;

        this.permittedUsers = { view: [], edit: [], all: [] };

        if ( input.length ) {
            // ... pass all permissions by user in differentes entries to a single array ...
            var compactedPermissions = {};

            _.each( input,
                function( i ) {
                    view.getUserInfo( i[ 0 ],
                        function( name, dbid, isOwner, isTeam, userPhoto ) {
                            if ( name ) {
                                if ( compactedPermissions[ dbid ] ) {
                                    compactedPermissions[ dbid ].permissions.push( i[ 1 ] );
                                }
                                else {
                                    compactedPermissions[ dbid ] = {};
                                    compactedPermissions[ dbid ].name = name;
                                    compactedPermissions[ dbid ].permissions = [ i[ 1 ] ];
                                    compactedPermissions[ dbid ].isOwner = isOwner || false;
                                    compactedPermissions[ dbid ].isTeam = isTeam || false;
                                    compactedPermissions[ dbid ].userPhoto = userPhoto;
                                }
                            }

                            ++namesFetched;

                            if ( namesFetched >= input.length ) {
                                for ( var key in compactedPermissions ) {
                                    if ( compactedPermissions.hasOwnProperty( key ) ) {
                                        var entry = compactedPermissions[ key ];

                                        view.addPermittedUser(
                                            view.getSimplifiedPermissionType( entry.permissions ),
                                            entry.name, key, entry.isOwner, entry.isTeam, entry.userPhoto );
                                    }
                                }
                                callback();
                            }
                        } );
                } );
        }
        else {
            callback();
        }
    },

    delPermittedUser: function( type, dbid ) {
        var users = this.permittedUsers[ type ];

        for ( var i = 0; i < users.length; ++i ) {
            var u = users[ i ];

            if ( u.id === dbid ) {
                users.splice( i, 1 );
                break;
            }
        }
    },

    addPermittedUser: function( permission, name, dbid, isOwner, isTeam, userPhoto ) {
        this.permittedUsers[ permission ].push( { name: name, id: dbid, isOwner: isOwner || false,
                                                  isTeam: isTeam || false, userPhoto: userPhoto } );
    },

    changePermittedUser: function( user, to ) {
        var id   = user.attr( 'dbid' );
        var from = user.attr( 'type' );

        for ( var i = 0; i < this.permittedUsers[ from ].length; ++i ) {
            var u = this.permittedUsers[ from ][ i ];

            if ( u.id === id ) {
                this.permittedUsers[ to ].push( u );
                this.permittedUsers[ from ].splice( i, 1 );
                break;
            }
        }
    },

    userHasAnyPermission: function( id ) {
        for ( var key in this.permittedUsers ) {
            if ( this.permittedUsers.hasOwnProperty( key ) ) {
                var users = this.permittedUsers[ key ];

                for ( var i = 0; i < users.length; ++i ) {
                    if ( id === users[ i ].id ) {
                        return true;
                    }
                }
            }
        }

        return false;
    },

    getSimplifiedPermissionType: function( permissions ) {
        var viewValid   = false;
        var editValid   = false;
        var allRequired = 3; // num of permissions required to be considered have all:
                             // change_ownership, change-permissions, delete

        // ... if the user dont have all the permissions required
        // to be in a simplified permission, it go down
        // for example: only have change_permissions and view ==> View;
        // only have change_permissions ==> none
        for ( var i = 0; i < permissions.length; ++i ) {
            var p = permissions[ i ];

            if ( p === 'salesseek.core.all_permissions' ) {
                return 'all';
            }
            else if ( p === 'salesseek.core.edit' ) {
                editValid = true;
            }
            else if ( ( p === 'salesseek.core.view' ) || ( p === 'salesseek.core.view_permissions' ) ) {
                viewValid = true;
            }
            else if ( ( p === 'salesseek.core.change_ownership' ) ||
                      ( p === 'salesseek.core.change_permissions' ) || ( p === 'delete' ) ) {
                --allRequired;
            }
        }

        // ...
        if ( allRequired === 0 ) {
            return 'all';
        }
        else if ( editValid ) {
            return 'edit';
        }
        else if ( viewValid ) {
            return 'view';
        }

        return 'none';
    },

    getExtendedPermissionType: function( p ) {
        if ( p === 'view' ) {
            return ['salesseek.core.view'];
        }
        else if ( p === 'edit' ) {
            return ['salesseek.core.edit'];
        }
        else if ( p === 'all' ) {
            return [ 'salesseek.core.all_permissions' ];
        }
    },

    selectItem: function(ev) {

        if ( !this.$el.hasClass( 'has-edit-permission' ) ) {
            return;
        }

        // ...
        var item = $(ev.currentTarget);
        var selectedLength;

        this.$el.find('.selected').not(item).removeClass('selected');
        item.toggleClass('selected');

        // Show delete button when an item is selected - this will work for multiple selection too
        selectedLength = (this.$el.has('.selected').length > 0);
        this.ui.deleteButton.toggleClass('visible', selectedLength);
    },

    addItem: function(ev) {
        var item = $(ev.currentTarget).closest('tr');

        if (!item.hasClass('focus')) {
            var search = item.find('.field-search');
            item.addClass('focus');
            search.val('');
            search.select2('open');
        }
    },

    onFieldBlur: function(ev) {
        var field = $(ev.currentTarget),
            item = field.closest('tr');

        // Revert to add button if field has no value
        if (field.val().length < 1) {
            item.removeClass('focus');
        }
    },

    createItem: function (ev) {
        var item   = $( ev.currentTarget ).closest( 'tr' );
        var search = item.find( '.field-search' );
        var data   = search.select2('data');

        if ( data ) {
            var id      = data.id;
            var name    = data.title;
            var isTeam  = data.type === 'salesseek.core.models.teams.Team';
            var isOwner = this.model.get( 'owner_id' ) === id;
            var type    = search.select2('container').attr('type');

            if (isTeam) {
                this.addPermittedUser( type, name, id, isOwner, true );
                this.save();
            } else {
                var user = new UserModel({ id: id });
                var self = this;

                var addUser = function() {
                    self.addPermittedUser( type, name, id, isOwner, false, user.get( 'photo_url' ) );
                    self.save();
                }

                if ( !user.get( 'name' ) && !user.get( 'email_address' ) ) {
                    user.fetch( { complete: function () { addUser(); } } );
                } else {
                    addUser();
                }
            }
        }

        // ...
        if ( item.hasClass( 'focus' ) ) {
            item.removeClass( 'focus' );
        }
    },

    deleteSelected: function () {
        var selected = this.$el.find('.selected');
        var id = selected.attr( 'dbid' );
        var type = selected.attr( 'type' );
        var view = this;


        this.ui.deleteButton.removeClass('visible');
        selected
            .animate(
                { opacity: 0 },
                { queue: false, duration: 200 }
            )
            .slideUp(200, function() {
                view.delPermittedUser( type, id );
                view.save();
                selected.remove();
            });
    },

    save: function() {
        var output = [];

        // ...
        output = output.concat( this.getItemsInSaveForm( 'view', output ) );
        output = output.concat( this.getItemsInSaveForm( 'edit', output ) );
        output = output.concat( this.getItemsInSaveForm( 'all', output ) );

        var view = this;

        vent.trigger( 'alert:show', { type: 'save', model: this.model, xhr: {} } );

        // TODO: error handling
        $.ajax( { url: this.model.url() + '/acl', contentType: 'application/json', processData: false,
                  dataType: 'json', type: 'POST', data: JSON.stringify( output ),
                success: function() {
                    vent.trigger( 'alert:hide' );
                    view.trigger( 'fetchData' );
                },
                error: function( xhr ) {
                    vent.trigger( 'alert:show', { type: 'save',
                                   model: this.model, xhr: xhr } );
                    view.trigger( 'fetchData' );
                }
        } );
    },

    reset: function() {
    },

    getItemsInSaveForm: function( simplifiedPermission, prevPermissions ) {
        var rdo   = [];
        var users = this.permittedUsers[ simplifiedPermission ];

        for ( var i = 0; i < users.length; ++i ) {
            var u = users[ i ];
            var n = '';

            // ... id ...
            if ( u.isOwner ) {
                n = 'salesseek.core.owner#'+ u.id;
            }
            else if ( u.name === 'Everyone' ) {
                n = 'salesseek.core.team#' + u.id;
            }
            else if ( u.isTeam ) {
                n = 'salesseek.core.team#' + u.id;
            }
            else {
                n = 'salesseek.core.user#' + u.id;
            }

            // ..
            var extendedPermissions = this.getExtendedPermissionType( simplifiedPermission );

            for ( var p = 0; p < extendedPermissions.length; ++p ) {
                var entry = [ n, extendedPermissions[ p ] ];
                var duplicate = false;

                // ... avoid duplicate entries ...
                for ( var k = 0; k < prevPermissions.length; ++k ) {
                    var prev = prevPermissions[ k ];

                    if ( ( entry[ 0 ] === prev[ 0 ] ) && ( entry[ 1 ] === prev[ 1 ] ) )
                    {
                        duplicate = true;
                        break;
                    }
                }

                // ...
                if ( duplicate ) {
                    continue;
                }

                rdo.push( entry );
            }
        }

        return rdo;
    }
});

export default InnerItemPermissionsView;

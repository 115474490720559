import _ from 'underscore'
import Handlebars from 'handlebars'

import app from 'js/app.js'
import BaseItemView from 'js/views/base/item.js'
import AppConfig from 'app/app-config.js'
import opportunityItemTemplate from 'templates/opportunities/issue-item.handlebars'


var IssueItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(opportunityItemTemplate),
    templateHelpers: function() {
        const cfs = app.globalData.customFieldsInfo.dealsArray;
        const issueTypeCf = cfs.find(f => f.name.toLowerCase() === 'issue type');
        const propertyCf = cfs.find(f => f.name.toLowerCase() === 'property');
        let issueType = '';
        let property = '';

        if (issueTypeCf) {
            const value = this.model.get(`custom_fields.${issueTypeCf.id}`);

            if (value) {
                issueType = ` | ${value}`;
            }
        }

        if (propertyCf) {
            const value = this.model.get(`custom_fields.${propertyCf.id}`);

            if (value) {
                property = value.name;
            }
        }

        let phaseName = this.model.get('phase').name;
        const phaseType = this.model.get('phase').phase_type;

        if (phaseType === 'won') {
            phaseName = 'Completed';
        } else if (phaseType === 'lost') {
            phaseName = 'Cancelled';
        }

        return {
            issueInfo: `${this.model.get('name')}${issueType}`,
            property: property,
            phaseName: phaseName
        };
    },
    events: _.extend({
        'mouseenter': function() {
            this.trigger('mouseenter');
        },
        'mouseleave': function() {
            this.trigger('mouseleave');
        }
    }, BaseItemView.prototype.events),
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        this.confirmOnClick = true;
    }
});

export default IssueItemView;

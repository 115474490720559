import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item.js'
import dateFormat from 'js/utils/date-format.js'
import forecastItemTemplate from 'templates/forecasts/item.handlebars'


var ForecastItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(forecastItemTemplate),
    templateHelpers: function() {
        var publishedDate, isCurrent, owner, period;
        var published = this.model.get('published') !== 'default';

        owner = this.model.get('owner');
        period = this.model.get('period');

        if (published) {
            publishedDate = dateFormat.entityInformationFormat(
                new Date(this.model.get('published_date'))
            );
        }

        return {
            formattedPublishedDate: publishedDate,
            owner_name: owner ? owner['name'] : '',
            period_name: period ? period.name : '',
            published: published
        };
    },
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        this.confirmOnClick = true;
    },
    onRender: function() {
        this.$el.find('.item-status').tooltip({
            container: 'body',
            html: true,
            delay: {
                show: 500
            },
            title: function() {
                var text = 'Current Forecast<p>This will be used as reference in other parts of the app</p>';
                return Handlebars.compile(text);
            }
        });
    }
});

export default ForecastItemView;

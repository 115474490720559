import d3 from 'd3'

var d3Utilities = {
    /**
     * Returns the lenght of the text or 0 if the text is not visible. It's necessary to test the visibility because a bug the way Edge works
     */
    getComputedTextLength: function(element) {
        var text = d3.select(element);

        if (document.body.contains(text.node())) {
            return element.getComputedTextLength();
        }

        return 0;
    },

    cropText: function(element, maxWidth, useEllipsis, textAttribute) {
        var text = d3.select(element);
        var words = textAttribute ? (text.attr(textAttribute) || text.text()) : text.text();
        var wordsArray = words.split('');

        text.text(words);
        text.tw = this.getComputedTextLength(element);

        if (textAttribute && !text.attr(textAttribute)) {
            text.attr(textAttribute, words);
        }

        while (text.tw > maxWidth && wordsArray.length) {
            wordsArray.pop();

            if (useEllipsis) {
                wordsArray.pop();
                wordsArray.pop();
            }

            if (wordsArray.length === 0) {
                words = "";
            } else {
                words = wordsArray.join('') + (useEllipsis ? '...' : '');
            }
            text.text(words);
            text.tw = this.getComputedTextLength(element);
        }
    }
}

export default d3Utilities;
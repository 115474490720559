import React from 'react';
import classnames from 'classnames';

import {Button} from 'js/react_views/widgets/widgets';
import Checkbox from 'js/react_views/widgets/checkbox';

import style from './individual-list.css';

const iconPaths = {
    'facebook': '/img/lead_sources/lead-facebook.png',
    'twitter': '/img/lead_sources/lead-twitter.png',
    'web': '/img/lead_sources/lead-web.png'
};
const defaultIconPath = '/img/lead_sources/lead-default.png';

class IndividualItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = { hover: false };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        // maintain hover state for the special case of modifying a Button prop
        // based on IndividualList item hover state
        this.setState({ hover: true });
    }

    handleMouseLeave() {
        this.setState({ hover: false });
    }

    handleCommButtonClick(ev, item, uri) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onLinkClick(item, uri);
    }

    render() {
        const {
            individual,
            onNavigate,
            individualsSelection,
            onIndividualSelect
        } = this.props;

        const { hover } = this.state;

        const selectionEnabled = onIndividualSelect && _.isObject(individualsSelection);

        let selected = false;
        if (_.isObject(individualsSelection)) {
            selected = individualsSelection[individual.id];
        }

        let sourceIconPath;
        if (individual.source) {
            sourceIconPath = iconPaths[individual.source.system_type] || defaultIconPath;
        }

        const name = individual.full_name || `${individual.first_name} ${individual.last_name}`;

        return (
            <li
                className={style.IndividualItem}
                onClick={(e) => {
                    e.preventDefault();
                    onNavigate(individual);
                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}>

                <div className={style.itemInner}>
                    {selectionEnabled &&
                        <div className={style.itemCheckbox}>
                            <Checkbox
                                checked={selected}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onIndividualSelect(individual);
                                }} />
                        </div>
                    }
                    <div className={style.thumb}>
                        <div className={style.thumbInner}>
                            <div className={style.thumbPlaceholder}>
                                <i className={classnames("icon-user", style.thumbIcon)} />
                            </div>
                            {individual.photo_url &&
                                <div
                                    className={style.thumbImage}
                                    style={{backgroundImage: `url(${individual.photo_url})`}} />
                            }
                            {sourceIconPath &&
                                <img
                                    src={sourceIconPath}
                                    className={style.sourceIcon} />
                            }
                        </div>
                    </div>
                    <div className={style.itemName}>
                        <a
                            className={style.itemNameLink}
                            href={`#individuals/${individual.id}`}
                            onClick={(e) => {e.preventDefault();}}>
                            {name}
                        </a>
                        {!!individual.role &&
                            <small className={style.itemNameSubinfo}>{individual.role}</small>
                        }
                    </div>
                    {!!individual.primaryPhone &&
                        <div className={style.itemBtnCell}>
                            <div className={style.inner}>
                                <Button
                                    size='fill'
                                    deemphasized={!hover}
                                    href={individual.primaryPhone.valueHref}
                                    title={individual.primaryPhone.value}
                                    onClick={(ev) => {
                                        this.handleCommButtonClick(
                                            ev,
                                            individual,
                                            individual.primaryPhone.valueHref)
                                    }}>
                                    <i className={classnames("icon-phone", style.itemBtnIcon)} />
                                </Button>
                            </div>
                        </div>
                    }
                    {!!individual.primaryEmail &&
                        <div className={style.itemBtnCell}>
                            <div className={style.inner}>
                                <Button
                                    size='fill'
                                    deemphasized={!hover}
                                    href={individual.primaryEmail.valueHref}
                                    title={individual.primaryEmail.value}
                                    onClick={(ev) => {
                                        this.handleCommButtonClick(
                                            ev,
                                            individual,
                                            individual.primaryEmail.valueHref)
                                    }}>
                                    <i className={classnames("icon-email", style.itemBtnIcon)} />
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </li>
        );
    }
}

class IndividualList extends React.Component {
    render() {
        const {
            individuals,
            onNavigate,
            individualsSelection,
            onIndividualSelect,
            onLinkClick
        } = this.props;

        return (
            <ul className={style.IndividualList}>
                {_.map(individuals, (individual) => (
                    <IndividualItem
                        key={individual.id}
                        individual={individual}
                        onNavigate={onNavigate}
                        individualsSelection={individualsSelection}
                        onIndividualSelect={onIndividualSelect}
                        onLinkClick={onLinkClick} />
                ))}
            </ul>
        );
    }
}

export default IndividualList;

import _ from 'underscore'

import BaseModel from 'js/models/base'


var LeadSourceModel = BaseModel.extend({
    name: 'Lead Source',
    urlRoot: '/lead_sources',
    validate: function(attrs) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name_undefined = 'Name is required';
        } else if (attrs.name.length === 0) {
            errors.name_too_short = 'One character minimum required';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

export default LeadSourceModel;

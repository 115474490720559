import _ from 'underscore'

import app from 'js/app'
import PaginatedListView from 'js/views/base/paginated_list'
import LeadItemView from 'app/_components/lead-item/lead-item'
import LeadsCollection from 'js/collections/leads'
import ListNavbarView from 'js/views/base/list_navbar'
import BaseToolbarView from 'js/views/base/toolbar'
import AppConfig from 'app/app-config'
import ActivityCreator from 'js/utils/activity_creator'
import vent from 'js/vent'


var NavView;

function createLeadsListView(options) {
    return new PaginatedListView(_.extend({
        id: 'leads-list',
        listItemView: LeadItemView,
        listItemViewOptions: {
            section: 'Leads',
            url: 'leads'
        },
        collection: new LeadsCollection(),
        fetchOptions: {
            rows: 5,
            sortOn: [{
                attribute: 'became_lead_date',
                order: 'desc'
            }],
            data: _.defaults(options.collectionData, {
                exclude_social_leads: AppConfig.getValue('excludeSocialLeads', undefined),
                has_activity: false,
            }),
        }
    }, options));
}

function createLeadsToolbarView(collection) {
    return new BaseToolbarView({
        collection: collection,
        tools: {
            newItem: true
        }
    });
}

NavView = ListNavbarView.extend({
    title: 'Incoming Leads',
    showTabs: false,
    tabs: [
        {
            id: 'leads',
            title: 'Incoming Leads',
            'class': 'list-nav-leads',
            view: createLeadsListView,
            titleToolbarView: createLeadsToolbarView
        }
    ],
    initialize: function() {
        ListNavbarView.prototype.initialize.apply(this, arguments);

        this.listenTo(this, 'showItem', function(model) {
            this.trigger('push-view:individual:show', {model: model});
        });

        // Open a new-form when clicking on the toolbar '+' button
        this.listenTo(this, 'toolbar:new', function() {
            this.trigger('push-view:individual:new', {
                leadSource: { system_type: 'unspecified' }
            });
        });

        this.listenTo(this, 'toolbar:delete', function(sel) {
            this.deleteItems( sel, { url: '/individuals/' } );
        });

        this.listenTo(this, 'followLink', function(model, link) {
            app.followLink(model, link);
        });
    },
    getUrl: function() {
        return 'leads';
    }
});

export default NavView;

import $ from 'jquery'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import template from 'templates/invalid_client.handlebars'


export default BaseView.extend({
    tagName: 'article',
    id: 'invalid-client-container',
    className: 'hide',
    template: Handlebars.compile(template),
    onRender: function() {
        this.$el.fadeIn(400);
    },
    getUrl: function () {
        return '404';
    }
});

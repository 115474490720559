import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import vent from 'js/vent';
import app from 'js/app';
import OrganizationFilterModel from 'js/models/organization_filter';
import {TextField} from 'js/react_views/common_components/common';
import Table from 'app_v2/components/table/table';
import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './cdd_checker.css';


class CDDChecker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phase: 'init',
            error: ''
        };

        const cfs = app.globalData.customFieldsInfo.organizationsArray;
        this.cddNumberCfId = cfs.find(cf => cf.name.toLowerCase() === 'cdd / cor number*').id;
        this.cddExpiryDateCfId = cfs.find(cf => cf.name.toLowerCase() === 'cdd / cor expiry date*').id;
    }

    handleSearch() {
        const value = this.number.getValue() || this.name.getValue();

        if (!value) {
            this.setState({
                error: 'Value is required'
            });

            return;
        }

        this.doSearch(value, !!this.number.getValue());
    }

    doUpdate(organizationId, number, expiryDate) {
        const self = this;
        let data = {
            [`custom_field.${this.cddNumberCfId}`]: number,
        };

        let updatedMessage = number;

        if (expiryDate) {
            data[`custom_field.${this.cddExpiryDateCfId}`] = expiryDate;
            updatedMessage += ` / ${expiryDate}`;
        }

        $.ajax({
            type: 'PATCH',
            url: `/organizations/${organizationId}`,
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            success: function(result) {
                self.setState({
                    phase: 'success',
                    message: `Current organization has been updated to ${updatedMessage}.`,
                    triggerUpdatedEventOnClose: true
                });
            }
        });
    }

    doSearch(value, byNumber) {
        const filter = new OrganizationFilterModel();
        const self = this;
        let rules = [];

        if (byNumber) {
            rules.push([{
                field: 'organization_custom',
                operator: 'equal',
                custom: this.cddNumberCfId,
                values: value
            }]);
        } else {
            rules.push([{
                field: 'organization_name',
                operator: 'contains',
                values: value
            }]);

            rules.push([{
                field: 'organization_custom',
                operator: 'empty',
                custom: this.cddNumberCfId,
                not: true
            }]);
        }

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function(data) {
                const params = $.param({
                    item_type: 'organizations',
                    rows: -1,
                    filter_id: data.id,
                    columns: `name,tags,custom_fields.${self.cddNumberCfId},custom_fields.${self.cddExpiryDateCfId}`
                });

                $.get(`/group_pages?${params}`, function(result) {
                    if (result.rows.length === 0) {
                        self.setState({
                            phase: 'success',
                            message: `There is no organization with ${byNumber ? 'CDD / COR' : 'name'} ${value}`
                        });
                    } else if (result.rows.length === 1 && byNumber) {
                        const row = result.rows[0];
                        const number = row.cells[2];
                        const expiryDate = row.cells[3];

                        self.doUpdate(self.props.id, number, expiryDate);
                    } else {
                        self.setState({
                            phase: 'selection',
                            message: byNumber ? 'Multiple organizations found with the same CDD / COR. Please choose manually.' : 'Please choose manually.',
                            rows: result.rows.map(r => {
                                return {
                                    id: r.id,
                                    name: r.cells[0],
                                    tags: r.cells[1],
                                    number: r.cells[2],
                                    expiryDate: r.cells[3]
                                };
                            })
                        });
                    }
                });
            }
        });

        this.setState({
            phase: 'loading'
        });
    }

    renderInit() {
        const self = this;

        _.defer(function() {
            self.number.focus();
        });

        return (
            <div>
                <TextField
                    ref={(el) => this.number = el}
                    label='CDD / COR Number'
                    placeholder='CDD / COR Value'
                    error={this.state.error}
                    onValueChange={(_, value) => { if (value) this.name.setValue('') }}
                />

                <TextField
                    ref={(el) => this.name = el}
                    label='Organization Name'
                    placeholder='Organization Name'
                    onValueChange={(_, value) => { if (value) this.number.setValue('') }}
                />

                <div className={style.cSearchContainer}>
                    <div
                        className={style.cSearch}
                        onClick={this.handleSearch.bind(this)}
                    >
                        Search
                    </div>
                </div>
            </div>
        );
    }

    handleTableRowClick(rowId) {
        this.setState({
            loading: true
        });

        const row = this.state.rows.find(r => r.id === rowId);

        this.doUpdate(this.props.id, row.number, row.expiryDate);
    }

    renderSelection() {
        const columnsCreator = new ColumnsCreator('appointments');

        const columns = [
            columnsCreator.tags('tags', 'Tags', {minWidth: 150}),
            columnsCreator.text('name', 'Name', {filterable: false, sortable: false, groupable: false}),
            columnsCreator.text('number', 'CDD / COR Number', {filterable: false, sortable: false, groupable: false, minWidth: 100}),
            columnsCreator.text('expiryDate', 'CDD / COR Expiry Date', {filterable: false, sortable: false, groupable: false, minWidth: 100})
        ];

        return (
            <div className={style.cTableContainer}>
                <div className={style.cTableMessage}>{this.state.message}</div>

                <Table
                    columns={columns}
                    rows={this.state.rows}
                    onRowClick={this.handleTableRowClick.bind(this)}
                />
            </div>
        );
    }

    handleClose() {
        if (this.state.triggerUpdatedEventOnClose) {
            vent.trigger('contact:updated');
        }

        this.props.onClose();
    }

    render() {
        return (
            <div
                className={style.cddCheckerView}
            >
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.handleClose.bind(this)}
                    >
                        Close
                    </div>
                    <div className={style.title}>CDD / COR Search</div>
                    <div></div>
                </div>

                <div className={style.content}>
                    {this.state.phase === 'init' &&
                        this.renderInit()
                    }
                    {this.state.phase === 'loading' &&
                        <LoadingIndicator/>
                    }
                    {this.state.phase === 'success' &&
                        <div className={style.cSuccess}>{this.state.message}</div>
                    }
                    {this.state.phase === 'selection' &&
                        this.renderSelection()
                    }
                </div>
            </div>
        );
    }
}

const CDDCheckerView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'cdd-checker-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <CDDChecker
                id={this.options.id}
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default CDDCheckerView;
import React from 'react';

import vent from 'js/vent';
import app from 'js/app';
import TextManager from 'app/text-manager';
import GroupModel from 'js/models/group';
import Search from './search';
import PopoverMenu from 'app_v2/components/popover_menu/menu';
import MessageBox from 'js/views/message_box';
import {CalendarDateSelector} from 'js/react_views/calendar/calendar';

import style from './toolbar.css';

// TODO:
// - add tooltips to the buttons (system tooltip or custom tooltip?)

class Button extends React.Component {
    render() {
        return (
            <div
                className={style.sButton}
                onClick={this.props.onClick}
            >
                {this.props.title &&
                    <div className={style.bTitle}>{this.props.title}</div>
                }

                {this.props.icon &&
                    <div className={`${style.bIcon} ${this.props.icon}`}/>
                }
            </div>
        );
    }
}

class ViewsSelector extends React.Component {
    handleClick(view) {
        if (!view.disabled) {
            this.props.onViewSelected(view.id);
        }
    }

    render() {
        return (
            <div className={style.sSelector}>
                {this.props.views.map(view => {
                    return (
                        <div
                            key={`vs_${view.id}`}
                            className={`
                                ${style.sItem}
                                ${view.icon}
                                ${view.id === this.props.activeId ? style.iActive : ''}
                                ${view.disabled ? style.iDisabled : ''}
                            `}
                            onClick={() => this.handleClick.bind(this)(view)}
                        />
                    );
                })}
            </div>
        );
    }
}

export default class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        let views = [];

        if (props.views) {
            if (props.views.table) {
                views.push({
                    id: 'table',
                    icon: 'icon-list'
                });
            }

            if (props.views.calendar) {
                views.push({
                    id: 'calendar',
                    icon: 'icon-calendar'
                });
            }

            if (props.views.funnel) {
                views.push({
                    id: 'funnel',
                    icon: 'icon-funnel'
                });
            }
        }

        this.state = {
            views: views,
            activeView: props.activeView || views[0].id,
            title: props.title || '',
            searchVisible: !!props.search,
            saveAsVisible: true,
            editColumnsVisible: !!props.editColumns,
            advancedFilterVisible: !!props.advancedFilter,
            reportsDateFilterVisible: !!props.reportsDateFilter,
            calendarSelectorVisible: false,
            groupClosable: false,
            moreOptionsItems: null,
            popoverMenu: {
                visible: false
            }
        };
    }

    setGroupClosable(closable) {
        this.setState({
            groupClosable: closable
        });
    }

    setActiveView(viewId) {
        let newState = {
            activeView: viewId
        };

        switch(this.state.activeView) {
            case 'calendar':
                this.calendarDateSelector.hide();
                break;
        }

        switch(viewId) {
            case 'calendar':
                newState.searchVisible = false;
                newState.saveAsVisible = false;
                newState.editColumnsVisible = false;
                newState.calendarSelectorVisible = true;
                newState.advancedFilterVisible = true;
                this.calendarDateSelector.show();
                break;

            case 'campaignsDashboard':
                newState.searchVisible = false;
                newState.saveAsVisible = false;
                newState.editColumnsVisible = false;
                newState.calendarSelectorVisible = false;
                newState.advancedFilterVisible = false;
                break;

            default:
                newState.searchVisible = this.props.search;
                newState.saveAsVisible = true;
                newState.editColumnsVisible = this.props.editColumns;
                newState.calendarSelectorVisible = false;
                newState.advancedFilterVisible = true;
                break;
        }

        this.setState(newState);
    }

    setReportDateFilter(value) {
        this.setState({
            reportsDateFilterVisible: value,
            advancedFilterVisible: false,
        });
    }

    setMoreOptionsItems(items) {
        this.setState({
            moreOptionsItems: items
        });
    }

    setCalendarDateSpan(date, span) {
        this.calendarDateSelector.setDateSpan(date, span);
    }

    setTitle(title) {
        this.setState({
            title: title
        });
    }

    setButtonsActive(buttonsToSet) {
        const buttons = ['search', 'saveAs', 'editColumns', 'advancedFilter'];
        let newState = {};

        for (const b of buttons) {
            if (b in buttonsToSet) {
                newState[`${b}Visible`] = buttonsToSet[b];
            }
        }

        this.setState(newState);
    }

    disableView(viewId) {
        let view = this.state.views.find(v => v.id === viewId);

        if (view) {
            view.disabled = true;

            this.setState({
                views: this.state.views
            });

            if (this.state.activeView === view.id) {
                this.setActiveView(this.state.views.find(v => !v.disabled && v.id !== this.state.activeView));
            }
        }
    }

    enableView(viewId) {
        let view = this.state.views.find(v => v.id === viewId);

        if (view) {
            view.disabled = false;

            this.setState({
                views: this.state.views
            });
        }
    }

    handleMoreOptionsClick() {
        const bbox = this.moreOptionsButton.getBoundingClientRect();

        this.setState({
            popoverMenu: {
                visible: true,
                top: bbox.top + bbox.height + 2,
                left: bbox.left + (bbox.width / 2),
                items: this.state.moreOptionsItems
            }
        });
    }

    handleSaveAsClick() {
        const bbox = this.saveAsButton.getBoundingClientRect();
        let items = [];

        if (this.props.saveAsGroup) {
            let option = {
                id: 'saveAsGroup',
                title: 'Save as group'
            };

            if (_.isObject(this.props.saveAsGroup)) {
                option.args = {
                    allowedTypes: _.clone(this.props.saveAsGroup)
                };
            }

            items.push(option);
        }

        if (this.props.saveAsCsv) {
            items.push({
                id: 'saveAsCsv',
                title: 'Save as CSV'
            });
        }

        this.setState({
            popoverMenu: {
                visible: true,
                top: bbox.top + bbox.height + 2,
                left: bbox.left + (bbox.width / 2),
                items: items
            }
        });
    }

    handlePopoverMenuClose(itemId) {
        this.setState({
            popoverMenu: {
                visible: false
            }
        });

        if (!itemId) {
            return;
        }

        switch (itemId) {
            case 'saveAsGroup': {
                const option = this.state.popoverMenu.items.find(i => i.id === itemId);

                let args = {
                    saveAs: true
                };

                _.extend(args, option.args || {});

                this.props.onShowPanel('saveEditGroup', args);
            }
            break;

            case 'editGroup': {
                const option = this.state.popoverMenu.items.find(i => i.id === itemId);
                this.props.onShowPanel('saveEditGroup', option.args);
            }
            break;

            case 'permissionsGroup': {
                const option = this.state.popoverMenu.items.find(i => i.id === itemId);

                let args = {
                    generalAccordionExpanded: false,
                    permissionsAccordionExpanded: true
                };

                _.extend(args, option.args || {});

                this.props.onShowPanel('saveEditGroup', args);
            }
            break;

            case 'shareNowGroup': {
                const option = this.state.popoverMenu.items.find(i => i.id === itemId);
                this.shareNowGroup(option.args.groupId);
            }
            break;

            case 'deleteGroup': {
                const option = this.state.popoverMenu.items.find(i => i.id === itemId);
                this.deleteGroup(option.args.groupId, option.args.groupName);
            }
            break;

            case 'saveAsCsv':
                this.props.saveCurrentGroupAsCsv();
                break;
        }
    }

    deleteGroup(groupId, groupName) {
        const preferences = app.user.get('preferences');
        const hiddenNotes = preferences['hidden_notes'] || {};
        let note = '';
        let initMessageActions = null;

        if (!('delete-group' in hiddenNotes)) {
            note = '<div class="note-container">' + TextManager.getText('ID_DELETE_GROUP_WARNING_MESSAGE_NO_LINK', ['${ID_CAMPAIGN, plural}']) +
                   '<div class="hide-note">click here if you don\'t want to see this message again</div></div>';

            initMessageActions = function(container) {
                container.find('.hide-note').click(function(){
                    hiddenNotes['delete-group'] = true;
                    app.user.updatePreference('hidden_notes', hiddenNotes);
                    container.find('.note-container').hide();
                });
            }
        }

        const mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?{{{note}}}')({name: groupName, note: note}),
            initMessageActions: initMessageActions,
            icon: 'icon-trashcan'
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            $.ajax({
                type: 'DELETE',
                url: `/groups/${groupId}`,
                success: function () {
                    self.props.onGroupDelete();
                }
            });
        });
    }

    shareNowGroup(groupId) {
        const self = this;

        MessageBox.showYesNo(
            {
                icon: 'icon-user',
                message: 'Which users would you like to share this group with?',
                accept_button_text: 'Send Now',
                cancel_button_text: 'Cancel'
            },
            this.props.parent,
            function(userIdList) {
                if (userIdList.length > 0) {
                    const data = {
                        receivers: userIdList
                    }

                    $.ajax({
                        type: 'POST',
                        url: `/groups/${groupId}/share`,
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify(data),
                        success: function(response) {
                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'Group successfully shared!',
                                        classes: 'success',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            },
            null,
            null,
            {
                prompt: {
                    type: 'tagSelect'
                }
            }
        );
    }

    render() {
        const saveAsButtonVisible = this.state.saveAsVisible && (this.props.saveAsGroup || this.props.saveAsCsv);
        const moreOptionsButtonVisible = this.state.moreOptionsItems && this.state.moreOptionsItems.length > 0;
        const enabledViews = this.state.views.filter(v => !v.disabled);

        return (
            <div className={style.sToolbar}>
                {this.state.groupClosable &&
                    <div
                        className={`
                            ${style.stActionButton}
                            icon-cross
                        `}
                        style={{marginRight: '15px'}}
                        onClick={() => this.props.onCloseGroup()}
                    />
                }

                {this.state.title &&
                    <div className={style.stTitle}>
                        {this.state.title}
                    </div>
                }

                <div className={style.stRight}>
                    <div className={style.stGroup}>
                        {this.props.calendar &&
                            <CalendarDateSelector
                                ref={(el) => this.calendarDateSelector = el}
                                visible={this.state.calendarSelectorVisible}
                                date={this.props.calendar.date}
                                span={this.props.calendar.span}
                                onDateChange={this.props.onCalendarDateChange}
                                onSpanChange={this.props.onCalendarSpanChange}
                                onDateSpanChange={this.props.onCalendarDateSpanChange}
                            />
                        }

                        {this.state.searchVisible &&
                            <Search
                                onSearch={this.props.onSearch}
                            />
                        }

                        {this.props.groupInsights &&
                            <div className={`${style.stActionButton} icon-pie-chart`}/>
                        }

                        {moreOptionsButtonVisible &&
                            <div
                                ref={(el) => this.moreOptionsButton = el}
                                className={`${style.stActionButton} icon-dotdotdot`}
                                onClick={this.handleMoreOptionsClick.bind(this)}
                            />
                        }

                        {saveAsButtonVisible &&
                            <div
                                ref={(el) => this.saveAsButton = el}
                                className={`${style.stActionButton} icon-floppy-disk`}
                                onClick={this.handleSaveAsClick.bind(this)}
                            />
                        }

                        {this.state.editColumnsVisible &&
                            <div
                                className={`${style.stActionButton} icon-table-columns`}
                                onClick={() => this.props.onShowPanel('configureColumns')}
                            />
                        }
                    </div>

                    {this.state.advancedFilterVisible &&
                        <div className={style.stGroup}>
                            <Button
                                title='Advanced Filter'
                                icon='icon-filter'
                                onClick={() => this.props.onShowPanel('advancedFilter')}
                            />
                        </div>
                    }

                    {this.state.reportsDateFilterVisible &&
                        <div className={style.stGroup}>
                            <Button
                                title='New Report'
                                icon='icon-plus'
                                onClick={() => this.props.onShowPanel('reportsDateFilter')}
                            />
                        </div>
                    }

                    {enabledViews.length > 1 &&
                        <div className={style.stGroup}>
                            <ViewsSelector
                                views={enabledViews}
                                activeId={this.state.activeView}
                                onViewSelected={this.props.onViewSelected}
                            />
                        </div>
                    }
                </div>

                {this.state.popoverMenu.visible &&
                    <PopoverMenu
                        top={this.state.popoverMenu.top}
                        left={this.state.popoverMenu.left}
                        items={this.state.popoverMenu.items}
                        onClose={this.handlePopoverMenuClose.bind(this)}
                    />
                }
            </div>
        );
    }
}
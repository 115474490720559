import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import contactInactiveModaltemplate from 'templates/custom_code/inactive_modal.handlebars'

var contactInactiveModal =  Marionette.ItemView.extend({
    className: 'contact-inactive-reasons edit-form-modal',
    template: Handlebars.compile(contactInactiveModaltemplate),
    ui: {
        inactiveDetails: '#inactive-details',
        inactiveRasons: '#inactive-reasons',
        error_messages: '.error-message',
    },
    events: {
        'click .save': function() {
            var valid = true;

            this.error_messages.remove.call(this);

            var inactiveDetails = this.ui.inactiveDetails.val().trim();

            if (!inactiveDetails) {
                this.ui.inactiveDetails
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('Inactive details is required.')
                    .addClass('invalid');

                valid = false
            }
            if (!this.inactiveRasonsOptionId) {
                this.ui.inactiveRasons
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('Inactive details is required.')
                    .addClass('invalid');

                valid = false
            }

            if (valid) {
                this.save();
            }
        },
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    initialize: function(options) {
        this.model = options.model ? options.model : null;
        this.individual_id = options.individual_id ? options.individual_id : null;
    },
    onRender: function() {
        var self = this;
        const customFields = app.globalData.customFieldsInfo.individuals;
        var options = [];

        this.error_messages.remove.call(this);

        for (const cfId in customFields) {
            if (customFields[cfId].name.toLowerCase() === 'inactive reasons') {
                this.inactiveReasonCFId = cfId;

                for (const [key, value] of Object.entries(customFields[cfId].options)) {
                    options.push({id: key, name: value})
                }
            }
            if (customFields[cfId].name.toLowerCase() === 'inactive') {
                this.inactiveCFId = cfId;
            }
            if (customFields[cfId].name.toLowerCase() === 'inactive details') {
                this.inactiveDetailsCFId = cfId;
            }

            if (this.inactiveCFId && this.inactiveReasonCFId && this.inactiveDetailsCFId) {
                break;
            }
        }

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.inactiveRasons,
            text: 'name',
            data: options,
            options: {
                placeholder: 'Choose an Inactive Reason',
                dropdownCssClass: 'popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        this.ui.inactiveRasons.on('change', function() {
            self.inactiveRasonsOptionId = self.ui.inactiveRasons.select2('val');
        });
    },
    save: function() {
        var self = this
        const cfInactive = `custom_field.${this.inactiveCFId}`;
        const cfInactiveReasons = `custom_field.${this.inactiveReasonCFId}`;
        const cfInactiveDetails = `custom_field.${this.inactiveDetailsCFId}`;

        var data = {
            [cfInactive]: true,
            [cfInactiveReasons]: this.inactiveRasonsOptionId,
            [cfInactiveDetails]: this.ui.inactiveDetails.val().trim()
        };

        if (this.individual_id) {
            $.ajax({
                type: 'PATCH',
                url: `/individuals/${this.individual_id}`,
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
                success: function(data) {
                    self.trigger('cancel');
                }
            });
        } else if (this.model) {
            this.model.save(data, {
                patch: true,
                wait: true,
                success: function(model, attributes, settings) {
                    self.trigger('cancel');
                }
            });
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default contactInactiveModal;

import app from 'js/app';
import TextManager from 'app/text-manager';

// width related fields:
//      - width: fixed width
//      - minWidth: minimum width required
//      - greedyWidth: (true/false) force the others columns to use their minWidth and use all the remaining space

export default class ColumnsCreator {
    constructor(entityType) {
        this.entityTypeSingular = '';

        switch (entityType) {
            case 'individuals':
                this.entityTypeSingular = 'individual';
                break;

            case 'opportunities':
                this.entityTypeSingular = 'opportunity';
                break;

            case 'appointments':
                this.entityTypeSingular = 'appointment';
                break;

            case 'conversations':
                this.entityTypeSingular = 'conversation';
                break;

            case 'campaigns':
                this.entityTypeSingular = 'campaign';
                break;
        }
    }

    schedule(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'schedule',
            sortable: true,
            groupable: true,
            minWidth: 290
        };

        this.configureColumn(column, configuration);

        return column;
    }

    date(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'date',
            filterable: true,
            filterId: `${this.entityTypeSingular}_${id}`,
            sortable: true,
            sortableAnniversary: true,
            groupable: true,
            minWidth: 150
        };

        this.configureColumn(column, configuration);

        return column;
    }

    datetime(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'datetime',
            filterable: true,
            filterId: `${this.entityTypeSingular}_${id}`,
            sortable: true,
            groupable: true,
            minWidth: 150
        };

        this.configureColumn(column, configuration);

        return column;
    }

    text(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'text',
            greedyWidth: true,
            minWidth: 200,
            sortable: true,
            groupable: true
        };

        this.configureColumn(column, configuration);

        return column;
    }

    composed(ids, title, configuration) {
        let column = {
            ids: ids,
            title: title,
            type: 'composed',
            minWidth: 200
        };

        this.configureColumn(column, configuration);

        return column;
    }

    campaignStat(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'campaignStat',
            greedyWidth: true,
            width: 100,
            sortable: true,
            groupable: true
        };

        this.configureColumn(column, configuration);

        return column;
    }

    comment(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'comment',
            greedyWidth: true,
            minWidth: 250
        };

        this.configureColumn(column, configuration);

        return column;
    }

    context(id, menuItems) {
        return {
            id: id,
            type: 'context',
            width: 40,
            menuItems: menuItems,
            fixed: true
        };
    }

    icon(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'icon',
            noBorder: !!configuration?.noBorder,
            width: 40
        };

        this.configureColumn(column, configuration);

        return column;
    }

    image(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'image',
            width: 40,
            fallbackIcon: configuration?.fallbackIcon
        };

        this.configureColumn(column, configuration);

        return column;
    }

    bool(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'bool',
            width: 40
        };

        this.configureColumn(column, configuration);

        return column;
    }

    group(id, title, configuration) {
        let column = this.entity('group', id, title, configuration);

        column.groupEntityType = configuration?.groupEntityType;

        return column;
    }

    user(id, title, configuration) {
        return this.entity('user', id, title, configuration);
    }

    individual(id, title, configuration) {
        return this.entity('individual', id, title, configuration);
    }

    opportunity(id, title, configuration) {
        return this.entity('opportunity', id, title, configuration);
    }

    organization(id, title, configuration) {
        return this.entity('organization', id, title, configuration);
    }

    automations(id, title, configuration) {
        return this.entityList('automations', id, title, _.extend(configuration || {}, { groupable: true }));
    }

    tags(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'tags',
            minWidth: 200
        };

        this.configureColumn(column, _.extend(configuration || {}, { filterType: 'tag' }));

        return column;
    }

    currency(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'currency',
            currency: app.user.get('client').default_currency,
            sortable: true,
            groupable: true,
            minWidth: 120
        };

        this.configureColumn(column, configuration);

        return column;
    }

    activity(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'activity',
            sortable: false,
            groupable: false,
            filterable: false,
            clickable: !!configuration?.clickable,
            minWidth: 250
        };

        this.configureColumn(column, configuration);

        return column;
    }

    locations(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'locations',
            sortable: false,
            groupable: false,
            filterable: false,
            minWidth: 200
        };

        this.configureColumn(column, configuration);

        return column;
    }

    communications(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'communications',
            sortable: false,
            groupable: false,
            filterable: false,
            minWidth: 200
        };

        this.configureColumn(column, configuration);

        return column;
    }

    leadSource(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'leadSource',
            width: 160,
            filterable: true,
            filterId: `${this.entityTypeSingular}_source`,
            sortable: true,
            groupable: true
        };

        this.configureColumn(column, configuration);

        return column;
    }

    forecastStatus(id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: 'forecastStatus',
            width: 160,
            filterable: true,
            filterId: `${this.entityTypeSingular}_status`,
            sortable: true,
            groupable: true
        };

        this.configureColumn(column, configuration);

        return column;
    }

    conversationParticipants(id, title) {
        let column = {
            id: id,
            title: title,
            type: 'conversationParticipants',
            greedyWidth: true,
            minWidth: 200
        };

        this.configureColumn(column, {
            filterable: false,
            sortable: false,
            groupable: false
        });

        return column;
    }

    entity(entityType, id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: entityType,
            greedyWidth: true,
            minWidth: 200
        };

        this.configureColumn(column, configuration);

        return column;
    }

    entityList(entityType, id, title, configuration) {
        let column = {
            id: id,
            title: title,
            type: entityType,
            greedyWidth: true,
            minWidth: 200
        };

        this.configureColumn(column, configuration);

        return column;
    }

    buttons(id, title, buttons, options) {
        let column = {
            id: id,
            title: title,
            type: 'buttons',
            buttons: buttons
        };

        if (options.width) {
            column.width = options.width;
        }

        return column;
    }

    // indirect: means we are creating custom fields for entity of type X to show them in a table for entities of type Y
    customFields(entityType, isIndirect) {
        let singular = '';
        let idPrefix = '';
        let namePrefix = '';

        switch (entityType) {
            case 'organizations':
                singular = 'organization';

                if (isIndirect) {
                    idPrefix = 'organization.';
                    namePrefix = `${TextManager.parseText('${ID_ORGANIZATION, capitalize}') }`;
                }
                break;

            case 'individuals':
                singular = 'individual';

                if (isIndirect) {
                    idPrefix = 'individual.';
                    namePrefix = 'Individual ';
                }
                break;

            case 'opportunities':
                singular = 'opportunity';

                if (isIndirect) {
                    idPrefix = 'opportunity.';
                    namePrefix = `${TextManager.parseText('${ID_DEAL, capitalize}') }`;
                }
                break;
        }

        const cfs = app.globalData.customFieldsInfo[`${entityType}Array`] || [];
        let columns = [];

        for (const cf of cfs) {
            let column = {
                id: `${idPrefix}custom_fields.${cf.id}`,
                title: `${namePrefix}${cf.name}`,
                isCustomField: true,
                type: 'text',
                sortable: true,
                groupable: true,
                minWidth: 200
            };

            if (['entityList', 'list', 'product'].indexOf(cf.type) === -1) {
                column = _.extend(column, {
                    filterable: true,
                    filterId: `${singular}_custom`,
                    filterType: cf.type,
                    filterCustom: cf.id
                });
            }

            switch (cf.type) {
                case 'date':
                    column.type = 'date';
                    column.minWidth = 150;
                    break;

                case 'checkbox':
                    column.type = 'bool';
                    break;

                case 'paragraph':
                case 'url':
                case 'urlImage':
                    column.filterType = 'text';
                    break;

                case 'dropDown':
                    column.filterType = 'dropdown';
                    column.dropdownOptions = [];

                    for (const oid in cf.options) {
                        column.dropdownOptions.push({
                            id: oid,
                            title: cf.options[oid]
                        });
                    }
                    break;

                case 'individual':
                case 'organization':
                case 'opportunity':
                    column.type = cf.type;
                    column.greedyWidth = true;
                    break;
            }

            columns.push(column);
        }

        return columns;
    }

    buckets() {
        let columns = [];

        for (const bucket of app.globalData.buckets) {
            columns.push({
                id: `buckets.${bucket.id}`,
                title: bucket.name,
                type: 'currency',
                currency: app.user.get('client').default_currency,
                sortable: true,
                filterable: false,
                groupable: true,
                minWidth: 160,
                isBucket: true
            });
        }

        return columns;
    }

    configureColumn(column, configuration) {
        configuration = configuration || {};

        for (const field of ['clickable', 'filterable', 'sortable', 'clickable', 'sortableAnniversary', 'groupable', 'greedyWidth', 'fieldPath', 'color', 'valueSuffix', 'customValues', 'style']) {
            if (field in configuration) {
                column[field] = configuration[field];
            }
        }

        if (column.filterable) {
            column.filterId = configuration.filterId || `${this.entityTypeSingular}_${column.id}`;
        }

        if (column.sortable) {
            column.sortId = configuration.sortId || column.id;
        }

        if (configuration.filterCustom) {
            column.filterCustom = configuration.filterCustom;
        }

        if (configuration.minWidth) {
            column.minWidth = configuration.minWidth;
        }

        if (configuration.width) {
            column.width = configuration.width;
        }

        column.filterType = configuration.filterType || column.type;

        if (configuration.parseValue && _.isFunction(configuration.parseValue)) {
            column.parseValue = configuration.parseValue;
        }
    }
}
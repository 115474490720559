import React from 'react';
import ReactDOM from 'react-dom';
import Marionette from 'Backbone.Marionette';

import app from 'js/app';
import Utilities from 'js/utils/utilities';
import AppConfig from 'app/app-config';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import TextManager from 'app/text-manager';
import vent from 'js/vent';
import dateFormat from 'js/utils/date-format';
import MessageBox from 'js/views/message_box';
import StepChoose from './step_choose';
import StepCompose from './step_compose';
import StepPreview from './step_preview';
import StepLaunch from './step_launch';
import StepRecipients from './step_recipients';
import StepApproval from './step_approval';

import style from './campaign_creation.css';

const STEPS = {
    choose: {
        id: 'choose',
        name: 'Choose',
        component: StepChoose,
        title: 'Create a campaign'
    },
    recipients: {
        id: 'recipients',
        name: 'Audience',
        component: StepRecipients
    },
    compose: {
        id: 'compose',
        name: 'Compose',
        component: StepCompose
    },
    preview: {
        id: 'preview',
        name: 'Preview',
        component: StepPreview
    },
    launch: {
        id: 'launch',
        name: 'Launch',
        component: StepLaunch
    },
    approval: {
        id: 'approval',
        name: 'Approval',
        component: StepApproval
    }
};

class RejectPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            reason: ''
        };
    }

    componentDidMount() {
        this.textarea.focus();
    }

    handleRejection() {
        this.textarea.blur();
        this.setState({
            loading: true
        });

        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/campaigns/${this.props.campaignId}?reject`,
            data: JSON.stringify({ reason: this.state.reason }),
            contentType: 'application/json',
            dataType: 'json',
            success: function () {
                self.props.onClose(true);
                vent.trigger('campaign:updated');
            },
            error: function() {
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'Error rejecting the campaign'
                }, self.props.parent, function() {
                    self.props.onClose(false);
                });
            }
        });
    }

    handleReasonChange(ev) {
        this.setState({
            reason: ev.target.value
        });
    }

    render() {
        return (
            <div className={style.cPopoverBackdrop}>
                <div className={style.cRejectPopover}>
                    <div className={style.poHeader}>
                        <div
                            className={style.poButton}
                            onClick={() => this.props.onClose(false)}
                        >
                            Close
                        </div>

                        <div className={style.poTitle}>
                            Reject Email
                        </div>
                    </div>

                    <div style={{padding: '15px'}}>
                        <div className={style.poContent}>
                            <div className={style.poText}>Reason for rejection</div>

                            <textarea
                                ref={(el) => this.textarea = el}
                                className={style.poTextarea}
                                rows='5'
                                value={this.state.reason}
                                onChange={this.handleReasonChange.bind(this)}
                            />

                            <div
                                style={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#E8EBED',
                                    margin: '15px 0'
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: '0 15px'
                                }}
                            >
                                <div
                                    className={style.poButton}
                                    onClick={this.handleRejection.bind(this)}
                                >
                                    Reject Email
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.loading &&
                        <div className={style.poLoading}>
                            <LoadingIndicator/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class CampaignCreationReact extends React.Component {
    constructor(props) {
        super(props);

        this.templateCategories = [{
            id: 'uncategorised',
            title: 'Uncategorised'
        }];

        const clientPreferences = app.user.get('client').preferences || {};
        const clientCampaignCategories = clientPreferences['campaign_template_categories'];

        if (clientCampaignCategories) {
            this.templateCategories = this.templateCategories.concat(JSON.parse(clientCampaignCategories));
        }

        let campaignData = props.campaignData || {};
        let steps = [];

        if (!campaignData.campaign_subtype) {
            campaignData.campaign_subtype = 'campaign';
        }

        if (props.contactEmailTemplating) {
            campaignData = _.clone(props.contactEmailTemplating.template);

            const contact = props.contactEmailTemplating.toContact;

            campaignData.contactEmailTemplating = true;
            campaignData.id = null;
            campaignData.current_page = 0;
            campaignData.last_page = -1;
            campaignData.to_group = {
                id: null,
                name: contact.name,
                items: [contact.id]
            };

            steps = ['compose', 'preview', 'launch'];
        } else if (campaignData.status === 'scheduled') {
            steps = ['launch'];
        } else if (campaignData.campaign_subtype !== 'campaign') {
            steps = ['choose', 'compose', 'preview'];
        } else {
            steps = ['choose', 'compose', 'recipients', 'preview', 'launch'];

            // when a campaign is sent its active step is 'launch'. If the audience group is deleted after the launch and the campaign is duplicated,
            // the audience group is null but the active step is launch, and this step requires the audicence group. So to manage this case, if
            // the campaign last step is bigger that the audience step and there is not audience group, we change the active step to the audiences one.
            if (!campaignData.to_group && campaignData.current_page > 2) {
                campaignData.current_page = 2;
                campaignData.last_page = 2;
            }
        }

        this.userType = AppConfig.getValue('campaigns.user_type', 'advance');

        if (['advance', 'approver'].indexOf(this.userType) !== -1 && campaignData.status === 'waiting_for_approval') {
            steps.push('approval');
            campaignData.current_page = steps.length -1;
        }

        // campaignData is a state and can be update several times in the same frame and as setState last 1 frame until real updates the content
        // we need a temp variable to save all the changes ocurred to avoid losing them
        this.workingCampaignData = campaignData;

        this.state = {
            steps: steps,
            campaignData: campaignData,
            title: '',
            stepIdx: -1,
            maxStepIdx: ('last_page' in campaignData) ? campaignData.last_page : -1,
            numAvailableSteps: 0,
            saveDraftVisible: false,
            nextButtonVisible: false,
            launchButtonText: null,
            rejectButtonVisible: false,
            counter: 0,
            loading: false,
            rejectPopoverVisible: false
        };
    }

    componentDidMount() {
        this.setStep(Utilities.clamp(this.state.campaignData.current_page || 0, 0, this.state.steps.length - 1));
    }

    setStep(stepIdx) {
        const step = STEPS[this.state.steps[stepIdx]];
        const maxStepIdx = Math.max(stepIdx, this.state.maxStepIdx);

        let newState = {
            stepIdx: stepIdx,
            maxStepIdx: maxStepIdx,
            numAvailableSteps: maxStepIdx + 1,
            title: this.state.campaignData.name || step.title || '',
            counter: this.state.counter + 1
        };

        // button visualization
        let showNextButton = stepIdx < this.state.steps.length - 1;
        let showRejectButton = false;
        let launchButtonText = null;

        if (!showNextButton) {
            const campaignTitle = TextManager.parseText('${ID_CAMPAIGN, capitalize}');

            switch (step.id) {
                case 'launch':
                    if (this.state.steps[this.state.steps.length - 1] !== 'approval') {
                        showNextButton = false;

                        if (this.workingCampaignData.status === 'scheduled') {
                            launchButtonText = `Update ${campaignTitle}`;
                        } else {
                            launchButtonText = this.userType === 'advance' ? `Launch ${campaignTitle}` : 'Schedule + Send for Approval';
                        }
                    }
                    break;

                case 'approval':
                    showNextButton = false;
                    showRejectButton = true;
                    launchButtonText = `Approve + ${this.state.campaignData.campaign_schedule ? 'Schedule' : 'Send'}`;
                    break;

                case 'preview':
                    if (this.workingCampaignData.campaign_subtype !== 'campaign') {
                        launchButtonText = `Finalise ${campaignTitle}`;
                    }
                    break;
            }
        }

        newState = _.extend(newState, {
            nextButtonVisible: showNextButton,
            rejectButtonVisible: showRejectButton,
            launchButtonText: launchButtonText
        });

        this.setState(newState);
    }

    showSaveDraftButton(show) {
        if (show) {
            show = !!this.workingCampaignData.name && this.workingCampaignData.status !== 'scheduled' && !this.workingCampaignData.contactEmailTemplating;
        }

        this.setState({
            saveDraftVisible: show
        });
    }

    resetProcess(initialStep) {
        // You have selected a campaign, selected a template, target group, etc... and then you go back to the choose step and change the
        // campaign type. The whole process has to be restored and start again from step 1.
        const step = initialStep || 0;

        this.setState({
            numAvailableSteps: step + 1,
            maxStepIdx: step
        });
    }

    updateCampaignData(key, value, noUpdateDraftButton) {
        this.updateCampaignDataObj({
            [key]: value
        }, noUpdateDraftButton);
    }

    updateCampaignDataObj(data, noUpdateDraftButton) {
        const newData = _.clone(this.workingCampaignData);
        _.extend(newData, data);

        // todo: check only the required fields (the ones saved on saveDraft)
        if (!_.isEqual(newData, this.workingCampaignData)) {
            if (newData.tags) {
                newData.tags = _.uniq(newData.tags, false, (t) => t.id);
            }

            this.workingCampaignData = newData;

            this.setState({
                campaignData: newData
            });

            if (!noUpdateDraftButton) {
                this.showSaveDraftButton(true);
            }
        }
    }

    gotoNextStep() {
        this.showSaveDraftButton(true); // because the current_step is updated
        this.setStep(this.state.stepIdx + 1);
    }

    gotoStep(id) {
        this.showSaveDraftButton(true); // because the current_step is updated
        this.setStep(this.state.steps.findIndex(s => s === id));
    }

    handleShowLoading(show) {
        this.setState({
            loading: show
        });
    }

    handleNextClick() {
        if (this.currentStep.onNext) {
            const passingAgain = (this.state.numAvailableSteps - 1) > this.state.stepIdx; // i've already pass over this step

            this.currentStep.onNext(passingAgain);
        } else {
            this.gotoNextStep();
        }
    }

    handleSaveDraft(urlArgs, callback, silent) {
        const campaignData = this.state.campaignData;

        const saveData = {
            campaign_type: campaignData.campaign_type,
            campaign_subtype: campaignData.campaign_subtype,
            content: campaignData.content || '',
            subject: campaignData.subject || null,
            see_config: campaignData.see_config || null,
            status: campaignData.status || 'draft',
            name: campaignData.name,
            include_signature: campaignData.include_signature,
            track: campaignData.track,
            tags: (campaignData.tags || []).map(tag => {
                return {id: tag.id, name: tag.name}
            }),
            current_page: this.state.stepIdx,
            last_page: this.state.maxStepIdx,
            from_user: campaignData.from_user || null,
            from_custom_field: campaignData.from_custom_field || null,
            cc: campaignData.cc || [],
            bcc: campaignData.bcc || [],
            campaign_schedule: campaignData.campaign_schedule || null,
            to_group: campaignData.to_group || null,
            funnel_id: campaignData.funnel_id || null
        };

        if (!silent) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: `Saving ${TextManager.parseText('${ID_CAMPAIGN, capitalize}')}...`,
                        classes: 'saving processing',
                        timer: 3000
                    };
                }
            });
        }

        const self = this;

        const onSaved = (data) => {
            if (data) { // saving the campaign with the `test` argument doesn't return the campaign data
                self.setState({
                    campaignData: data
                });
                self.workingCampaignData = data;
            }

            self.showSaveDraftButton(false);

            if (!silent) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: `${TextManager.parseText('${ID_CAMPAIGN, capitalize}')} saved`,
                            classes: 'saved success',
                            timer: 3000
                        };
                    }
                });
            }

            if (self.currentStep.onDraftSaved && data) {
                self.currentStep.onDraftSaved(data);
            }

            if (callback) {
                callback();
            }

            vent.trigger('campaign:updated');
        };

        // ...
        const isNew = !this.state.campaignData.id;
        let url = '/campaigns';

        if (!isNew) {
            url += `/${this.state.campaignData.id}`;
        }

        if (urlArgs) {
            url +=`?${urlArgs}`;
        }

        $.ajax({
            type: isNew ? 'POST' : 'PATCH',
            url: url,
            data: JSON.stringify(saveData),
            contentType: 'application/json',
            dataType: 'json',
            success: function (result) {
                onSaved(result);

                if (isNew) {
                    _.defer(function() {
                        vent.trigger('AppContent:contentChange');
                    });
                }
            },
            error: function(result) {
                switch (result?.responseJSON?.detail?.exception) {
                    case 'CampaignScheduledPastError':
                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: "You can't set a date in the past"
                        }, self.props.parent);
                        break;

                    default:
                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: 'Error saving the campaign',
                        }, self.props.parent);
                        break;
                }
            }
        });
    }

    handleBreadCrumbClick(step) {
        const newStepIdx = this.state.steps.findIndex(s => s === step.id);

        if (newStepIdx > this.state.maxStepIdx) {
            return;
        }

        // this function can be used to save something related to the campaign in campaignData. Also it returns true/false if the change of step is allowed
        if (this.currentStep.onBreadCrumbClick) {
            if (this.currentStep.onBreadCrumbClick(newStepIdx === this.state.stepIdx)) {
                this.setStep(newStepIdx);
                this.showSaveDraftButton(true); // because the current_step is updated
            }
        } else if (newStepIdx !== this.state.stepIdx) {
            this.setStep(newStepIdx);
            this.showSaveDraftButton(true); // because the current_step is updated
        }
    }

    handleLaunchClick() {
        if (this.currentStep.isReadyToLaunch && this.currentStep.isReadyToLaunch()) {
            const campaignData = this.state.campaignData;
            let numberOfPeople = 0;

            if (this.currentStep.getNumContacts) {
                numberOfPeople = this.currentStep.getNumContacts();
            }

            let message = `<p>${_.escape(campaignData.name)}</p><p>will be sent to</p>${numberOfPeople} people</p>`;

            if (campaignData.campaign_schedule) {
                const dateAndTime = campaignData.campaign_schedule.when.split('T');
                message += `<p>at ${dateAndTime[1].substring(0, 5)}</p><p>on ${dateFormat.shortFormatWithYear(dateFormat.createDateIgnoringTZ(dateAndTime[0]))}</p>`;
            } else {
                message += '<p>immediately</p>';
            }

            const self = this;

            MessageBox.showYesNo({
                message: message,
                accept_button_text: 'Confirm',
                cancel_button_text: 'Cancel'
            },
            this.props.parent,
            function() {
                if (campaignData.campaign_schedule) {
                    const urlArgs = self.userType === 'advance' ? 'send' : 'send_for_approval';

                    self.handleSaveDraft(urlArgs, function() {
                        self.props.onClose();
                    });

                    return;
                }

                // default
                self.handleSaveDraft('send', function() {
                    self.props.onClose();
                });
            });
        } else if (this.currentStep.isReadyToFinalise && this.currentStep.isReadyToFinalise()) {
            const self = this;

            this.handleSaveDraft('finalise', function() {
                self.props.onClose();
            });
        }
    }

    handleRejectPopoverClose(campaignRejected) {
        if (campaignRejected) {
            this.props.onClose();
        } else {
            this.setState({
                rejectPopoverVisible: false
            });
        }
    }

    handleSendTest(testInfo) {
        this.handleSaveDraft(`test=${testInfo.to}&individual_id=${testInfo.individualId}`);
    }

    handleCloseClick() {
        if (this.state.saveDraftVisible || this.state.campaignData.contactEmailTemplating) {
            const mbContent = {
                accept_is_negative: true,
                message: 'Unsaved data will be lost. Are you sure you want to continue?',
                icon: 'icon-warning'
            };

            const self = this;

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                self.props.onClose();
            });
        } else {
            this.props.onClose();
        }
    }

    renderStep() {
        if (this.state.stepIdx === -1) {
            return null;
        }

        const currentStep = this.state.steps[this.state.stepIdx];
        const Component = STEPS[currentStep].component;
        const self = this;

        return (
            <Component
                ref={(el) => this.currentStep = el}
                key={`component_${this.state.counter}`}
                stepIdx={this.state.stepIdx}
                campaignData={this.state.campaignData}
                showSaveDraftButton={this.showSaveDraftButton.bind(this)}
                resetProcess={this.resetProcess.bind(this)}
                gotoNextStep={this.gotoNextStep.bind(this)}
                gotoStep={this.gotoStep.bind(this)}
                updateCampaignData={this.updateCampaignData.bind(this)}
                updateCampaignDataObj={this.updateCampaignDataObj.bind(this)}
                sendTest={this.handleSendTest.bind(this)}
                showLoading={this.handleShowLoading.bind(this)}
                saveDraft={this.handleSaveDraft.bind(this)}
                parent={this.props.parent}
                userType={this.userType}
                templateCategories={this.templateCategories}
            />
        );
    }

    render() {
        return (
            <div className={style.campaignCreation}>
                <div className={style.cHeader}>
                    {this.state.title}
                </div>

                <div className={style.cContent}>
                    {this.renderStep()}
                </div>

                <div className={style.cFooter}>
                    <div
                        className={style.fClose}
                        onClick={this.handleCloseClick.bind(this)}
                    >
                        Close
                    </div>

                    <div className={style.fBreadcrumbs}>
                        {this.state.steps.map((s, sidx) => {
                            const step = STEPS[s];

                            return (
                                <div
                                    key={`step_${s}`}
                                    className={`
                                        ${style.bCrumb}
                                        ${sidx === this.state.stepIdx ? style.cActive : ''}
                                        ${sidx >= this.state.numAvailableSteps ? style.cDisabled : ''}
                                    `}
                                    onClick={() => this.handleBreadCrumbClick.bind(this)(step)}
                                >
                                    {step.name}
                                </div>
                            );
                        })}
                    </div>

                    <div className={style.fRightActions}>
                        {this.state.saveDraftVisible &&
                            <div
                                className={style.rButton}
                                onClick={() => this.handleSaveDraft.bind(this)()}
                            >
                                Save Draft
                            </div>
                        }

                        {this.state.nextButtonVisible &&
                            <div
                                className={`${style.rButton} ${style.bBlue}`}
                                style={{marginLeft: '20px'}}
                                onClick={this.handleNextClick.bind(this)}
                            >
                                Next
                            </div>
                        }

                        {this.state.rejectButtonVisible &&
                            <div
                                className={`${style.rButton} ${style.bRed}`}
                                style={{marginLeft: '20px'}}
                                onClick={() => this.setState({ rejectPopoverVisible: true })}
                            >
                                Reject
                            </div>
                        }

                        {this.state.launchButtonText &&
                            <div
                                className={`${style.rButton} ${style.bOrange}`}
                                style={{marginLeft: '20px'}}
                                onClick={this.handleLaunchClick.bind(this)}
                            >
                                {this.state.launchButtonText}
                            </div>
                        }
                    </div>
                </div>

                {this.state.loading &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 1
                        }}
                    >
                        <div
                            style={{
                                padding: '30px 60px',
                                background: 'white',
                                borderRadius: '6px'
                            }}
                        >
                            <LoadingIndicator/>
                        </div>
                    </div>
                }

                {this.state.rejectPopoverVisible &&
                    <RejectPopover
                        campaignId={this.state.campaignData.id}
                        parent={this.props.parent}
                        onClose={this.handleRejectPopoverClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

const CampaignCreation = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'new-campaign-creation-modal');
        this.$el.css('height', '100%');
        this.$el.css('width', '100%');

        const modalBackdrop = this.$el.parent().prev('.modal-backdrop');
        const self = this;

        modalBackdrop.css('background', 'none');
        modalBackdrop.on('click', function() {
            self.reactComponent.handleCloseClick();
        });
    },
    handleClose() {
        if (this.options.onCloseGoto) {
            window.location.href = this.options.onCloseGoto;
        } else {
            this.trigger('close');

            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        }
    },
    onRender: function() {
        ReactDOM.render(
            <CampaignCreationReact
                ref={(el) => this.reactComponent = el}
                onClose={this.handleClose.bind(this)}
                campaignData={this.options.campaignData}
                contactEmailTemplating={this.options.contactEmailTemplating}
                parent={this}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default CampaignCreation;
import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette';
import Handlebars from 'handlebars';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import vent from 'js/vent'
import TextManager from 'app/text-manager'
import ModalRegion from 'js/views/base/modal-region';
import MessageBox from 'js/views/message_box'
import FilterAdvancedView from 'js/views/filters/advanced'
import FilterQuickView from 'js/views/filters/quick'
import FilterFields from 'js/views/filters/fields'
import FilterQuickOptionsIndividuals from 'js/views/filters/quick-options-individuals'
import GroupElementsCollection from 'js/collections/group_elements'
import CustomFieldsCollection from 'js/collections/custom_fields'
import filterOperators from 'js/views/filters/operators'
import DateTimePicker from 'js/widgets/date-time-picker'
import Currency from 'js/utils/currency'
import ItemPermissionsView from 'js/views/item_permissions';
import Automation2Model from 'js/models/automation2';

import AutomationReactView from 'js/react_views/automation/automation';

import styles from 'js/react_views/automation/automation.css';

var filterCustomFields = {
    'individual_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_INDIVIDUAL, capitalize}']),
        'view': 'individuals'
    },
    'organization_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_ORGANIZATION, capitalize}']),
        'view': 'organizations'
    },
    'opportunity_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_DEAL, capitalize}']),
        'view': 'deals'
    },
    'user_custom': {
        'name': "User Custom",
        'view': 'users'
    }
};

var AutomationReactContainerView = Marionette.Layout.extend({
    tagName: 'span',
    template: Handlebars.compile("<span class='react-region'></span><span class='popover-region rule-popover popover " + styles.PopoverRegion + "'></span>"),
    ui: {
        react: '.react-region'
    },
    regions: {
        popoverRegion: '.popover-region',
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    initialize: function() {
        this.automation = this.options.automationModel && this.options.automationModel.attributes;

        this.quickFilter = new FilterQuickOptionsIndividuals();
        this.collection = new GroupElementsCollection(null, {elementType: 'individuals'});

        if (!this.automation && this.options.automationIdToLoad) {
            this.loadAutomation(this.options.automationIdToLoad);
        }
    },
    onShow: function() {
        var self = this;

        this.loadCustomFields(function() {
            self.loadAutomations();
        });
    },
    showReactView: function() {
        let reactView = null;
        const self = this;

        _.defer(function() {
            if (reactView) {
                reactView.adjustGraphZoom();
            }
        });

        ReactDOM.render(
            <AutomationReactView
                ref={(el) => reactView = el}
                createAutomation={this.createAutomation.bind(this)}
                createAutomationNode={this.createAutomationNode.bind(this)}
                updateAutomationNode={this.updateAutomationNode.bind(this)}
                removeAutomationNode={this.removeAutomationNode.bind(this)}
                updateAutomation={this.updateAutomation.bind(this)}
                deleteAutomation={this.deleteAutomation.bind(this)}
                showPermissions={this.showPermissions.bind(this)}
                DDSearch={this.DDSearch.bind(this)}
                showFilter={this.showFilter.bind(this)}
                removeFilterRule={this.removeFilterRule.bind(this)}
                showDateTime={this.showDateTime.bind(this)}
                showDate={this.showDate.bind(this)}
                automationList={this.automationList}
                automation={this.automation}
                filterFields={this.filterFields}
                createUpdateFieldsAll={this.createUpdateFieldsAll}
                enableGrid={_.contains(app.user.get('preferences').lab_flags, 'SAL-3844')}
                enableTitles={_.contains(app.user.get('preferences').lab_flags, 'SAL-3844')}
                handleAutomationListItemClick={this.loadAutomation.bind(this)}
            />,
            this.ui.react.get(0)
        );
    },
    buildFilterFields: function() {
        var fields = _.clone(FilterFields());

        _.each(this.customFields, function (collection, id) {
            collection.each(function (model) {
                if (model.get('type') !== 'list') {
                    fields.push({
                        'id': id + '#' + model.get('id'),
                        'name': model.get('name'),
                        'operators': filterOperators.custom[model.get('type')],
                        'group': filterCustomFields[id].name,
                        'value_def': model.get('value')
                    });
                }
            });
        });

        this.filterFields = fields;
    },
    buildCreatedUpdateFields: function() {
        var fields = {
            update_individual: [
                {
                    id: 'first_name',
                    name: 'First Name',
                    type: 'text'
                },
                {
                    id: 'last_name',
                    name: 'Last Name',
                    type: 'text'
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    type: 'text'
                },
                {
                    id: 'photo_url',
                    name: 'Photo Url',
                    type: 'text'
                },
                {
                    id: 'role',
                    name: 'Role',
                    type: 'text'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'source',
                    name: 'Source',
                    type: 'source',
                    hasInnerIdField: true
                },
                {
                    id: 'organization',
                    name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                    type: 'organization',
                    hasInnerIdField: true
                },
                {
                    id: 'unsubscribed_all',
                    name: TextManager.getText('ID_EMAIL_OPTED_IN'),
                    type: 'checkbox'
                },
                {
                    db_id: 'tags',
                    id: 'replace_tags',
                    name: 'Replace Tags',
                    type: 'tags',
                    action: 'replace'
                },
                {
                    db_id: 'tags',
                    id: 'add_tags',
                    name: 'Add Tags',
                    type: 'tags',
                    action: 'modify'
                }
            ],
            create_individual: [
                {
                    id: 'first_name',
                    name: 'First Name',
                    type: 'text'
                },
                {
                    id: 'last_name',
                    name: 'Last Name',
                    type: 'text'
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    type: 'text'
                },
                {
                    id: 'photo_url',
                    name: 'Photo Url',
                    type: 'text'
                },
                {
                    id: 'role',
                    name: 'Role',
                    type: 'text'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'creator',
                    name: 'Creator',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'source',
                    name: 'Source',
                    type: 'source',
                    hasInnerIdField: true
                },
                {
                    id: 'unsubscribed_all',
                    name: TextManager.getText('ID_EMAIL_OPTED_IN'),
                    type: 'checkbox'
                },
                {
                    db_id: 'tags',
                    id: 'set_tags',
                    name: 'Tags',
                    type: 'tags'
                }
            ],
            update_organization: [
                {
                    id: 'name',
                    name: 'Name',
                    type: 'text'
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    type: 'text'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    db_id: 'tags',
                    id: 'replace_tags',
                    name: 'Replace Tags',
                    type: 'tags',
                    action: 'replace'
                },
                {
                    db_id: 'tags',
                    id: 'add_tags',
                    name: 'Add Tags',
                    type: 'tags',
                    action: 'modify'
                }
            ],
            create_organization: [
                {
                    id: 'name',
                    name: 'Name',
                    type: 'text'
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    type: 'text'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'creator',
                    name: 'Creator',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    db_id: 'tags',
                    id: 'set_tags',
                    name: 'Tags',
                    type: 'tags'
                }
            ],
            update_opportunity: [
                {
                    id: 'name',
                    name: 'Name',
                    type: 'text'
                },
                {
                    id: 'abbreviation',
                    name: 'Abbreviation',
                    type: 'text'
                },
                {
                    id: 'currency',
                    name: 'Currency',
                    type: 'currency'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'phase',
                    name: TextManager.parseText('${ID_PHASE, capitalize}'),
                    type: 'phase',
                    hasInnerIdField: true
                },
                {
                    id: 'expected_close_date',
                    name: 'Expected Close Date',
                    type: 'date'
                },
                {
                    db_id: 'tags',
                    id: 'replace_tags',
                    name: 'Replace Tags',
                    type: 'tags',
                    action: 'replace'
                },
                {
                    db_id: 'tags',
                    id: 'add_tags',
                    name: 'Add Tags',
                    type: 'tags',
                    action: 'modify'
                },
                {
                    id: 'status',
                    name: TextManager.parseText('${ID_STATUS, capitalize}'),
                    type: 'dropDown',
                    options: [
                        {
                            id: 'none',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}')
                        },
                        {
                            id: 'none_upside',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}')
                        },
                        {
                            id: 'committed',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
                        },
                        {
                            id: 'committed_downside',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}')
                        }
                    ]
                },
                {
                    id: 'weight',
                    name: 'Weight',
                    type: 'weight'
                }
            ],
            create_opportunity: [
                {
                    id: 'name',
                    name: 'Name',
                    type: 'text'
                },
                {
                    id: 'comments',
                    name: 'Comments',
                    type: 'text'
                },
                {
                    id: 'currency',
                    name: 'Currency',
                    type: 'currency'
                },
                {
                    id: 'owner',
                    name: 'Owner',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'creator',
                    name: 'Creator',
                    type: 'user',
                    hasInnerIdField: true
                },
                {
                    id: 'phase',
                    name: TextManager.parseText('${ID_PHASE, capitalize}'),
                    type: 'phase',
                    hasInnerIdField: true
                },
                {
                    db_id: 'tags',
                    id: 'set_tags',
                    name: 'Tags',
                    type: 'tags'
                },
                {
                    id: 'status',
                    name: TextManager.parseText('${ID_STATUS, capitalize}'),
                    type: 'dropDown',
                    options: [
                        {
                            id: 'none',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}')
                        },
                        {
                            id: 'none_upside',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}')
                        },
                        {
                            id: 'committed',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
                        },
                        {
                            id: 'committed_downside',
                            name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}')
                        }
                    ]
                },
                {
                    id: 'weight',
                    name: 'Weight',
                    type: 'weight'
                }
            ]
        };

        var keyMap = {
            individual_custom: "individual",
            organization_custom: "organization",
            opportunity_custom: "opportunity",
            user_custom: "user"
        };

        _.each(this.customFields, function (collection, id) {
            collection.each(function (model) {
                fields["update_" + keyMap[id]].push({
                    id: model.get('id'),
                    name: model.get('name'),
                    type: model.get('type'),
                    options: model.get('options'),
                    customField: true
                });
                fields["create_" + keyMap[id]].push({
                    id: model.get('id'),
                    name: model.get('name'),
                    type: model.get('type'),
                    options: model.get('options'),
                    customField: true
                });
            });
        });

        this.createUpdateFieldsAll = fields;
    },
    loadCustomFields: function(callback) {
        var view = this;

        function checkReady() {
            var unready = _.filter(view.customFields, function(item) {
                return !item;
            });
            if (unready.length === 0) {
                view.buildFilterFields();
                view.buildCreatedUpdateFields();

                callback();
            }
        }

        this.customFields = {};
        _.each(filterCustomFields, function(item, id) {
            view.customFields[id] = null;

            var collection = new CustomFieldsCollection();
            collection.fetch({
                filterBy: [{
                    attribute: 'view',
                    value: item.view
                }],
                complete: function() {
                    view.customFields[id] = collection;
                    checkReady();
                }
            });
        });
    },
    loadAutomations: function() {
        var self = this;

        $.ajax({
            type: 'GET',
            url: '/automations2',
            contentType: 'application/json',
            dataType: 'json',
            data: {
                rows: -1
            },
            success: function (data) {
                self.automationList = data;
                self.showReactView();
            }
        });
    },
    prepareData() {
        var steps = this.automation.steps;

        // add root nonremovable step
        steps.unshift({
            id: "root",
            next_step_id: this.automation.steps.length ? this.automation.first_step_id : null
        });

        // create map for performance
        var stepMap = this.automation.stepMap = {};
        steps.forEach(step => {
            stepMap[step.id] = step;
        });

        // add leave nodes
        var dynamicCount = 0;
        steps.forEach(step => {
            if (step.step_type === 'branch') {
                if (!step.next_step_id) {
                    const buttonNodeId = 'bn-' + dynamicCount;
                    dynamicCount = dynamicCount + 1;
                    step.next_step_id = buttonNodeId;

                    const newStep = {
                        id: buttonNodeId,
                        buttonNode: true
                    };

                    steps.push(newStep);
                    stepMap[newStep.id] = newStep;
                }
                if (!step.reject_step_id) {
                    const buttonNodeId = 'bn-' + dynamicCount;
                    dynamicCount = dynamicCount + 1;
                    step.reject_step_id = buttonNodeId;

                    const newStep = {
                        id: buttonNodeId,
                        buttonNode: true
                    };

                    steps.push(newStep);
                    stepMap[newStep.id] = newStep;
                }
            }
            else {
                if (!step.next_step_id) {
                    const buttonNodeId = 'bn-' + dynamicCount;
                    dynamicCount = dynamicCount + 1;
                    step.next_step_id = buttonNodeId;

                    const newStep = {
                        id: buttonNodeId,
                        buttonNode: true
                    };

                    steps.push(newStep);
                    stepMap[newStep.id] = newStep;
                }
            }
        });

        // add parent relations
        // clear/initialize parent ids dictionaries for merge steps
        _.each(stepMap, step => {
            if (step.step_type === 'merge') {
                step.parents = [];
            }
        });
        // build relationships into existing node structure
        _.each(stepMap, function(step) {
            const addParent = function(child_step) {
                if (step[child_step] && stepMap[step[child_step]]) {
                    if (stepMap[step[child_step]].step_type === 'merge') {
                        stepMap[step[child_step]].parents.push({ id: step.id, at: child_step });
                    }
                    else {
                        stepMap[step[child_step]].parentId = step.id;
                        stepMap[step[child_step]].parentStep = child_step;
                    }
                }
            };

            addParent('next_step_id');
            addParent('reject_step_id');
        });

        this.showReactView();
        vent.trigger('AppContent:contentChange');
    },
    createAutomation(data, callback) {
        var self = this;

        $.ajax({
            type: 'POST',
            url: '/automations2',
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            error: function(data) {
                callback(JSON.parse(data.responseText));
            },
            success: function (data) {
                self.automation = data;
                self.prepareData();
                self.loadAutomations();
                callback();
            }
        });
    },
    updateAutomation(data) {
        var self = this;

        $.ajax({
            type: 'PATCH',
            url: '/automations2/' + data.id,
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            success: function () {
                self.loadAutomation();
                self.loadAutomations();
            }
        });
    },
    deleteAutomation(id, callback) {
        var self = this;

        MessageBox.showYesNo(
            {
                message: 'Are you sure you want to remove automation?',
                icon: 'icon-trashcan',
                accept_is_negative: true
            },
            this,
            function() { // Yes
                $.ajax({
                    type: 'DELETE',
                    url: '/automations2/' + id,
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function() {
                        callback();
                        self.loadAutomations();
                        self.automation = null;
                    }
                });
            }
        );
    },
    showPermissions(automation) {
        if (this.aclRegion) {
            var ipv = new ItemPermissionsView({model: new Automation2Model(automation)});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    loadAutomation(id) {
        var self = this;

        $.ajax({
            type: 'GET',
            url: '/automations2/' + (id || this.automation.id),
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                self.automation = data;
                self.prepareData();
            }
        });
    },
    preprocessNodeData: function(data) {
        data = _.clone(data || {});

        if (data.action_type === 'create_task') {
            if (data.action_config.assignee_id === 'owner-of-the-trigger-record') {
                delete data.action_config.assignee_id;
            }
        } else if (data.action_type === 'send_campaign') {
            if (data.action_config.recipient_cf_id === 'send-to-this-individual') {
                delete data.action_config.recipient_cf_id;
            }
        } else if (data.action_type === 'update_individual') {
            if (data.action_config.unsubscribed_all) {
                data.action_config.unsubscribed_all.value = !data.action_config.unsubscribed_all.value;
            }
        } else if (data.action_type === 'create_individual') {
            if ('unsubscribed_all' in data.action_config) {
                data.action_config['unsubscribed_all'] = !data.action_config['unsubscribed_all'];
            }
        }

        return data;
    },
    createAutomationNode(step, callback) {
        var self = this;
        var data = this.preprocessNodeData(step);

        $.ajax({
            type: 'POST',
            url: '/automation2_steps',
            data: JSON.stringify($.extend(data, {
                entity_type: this.automation.entity_type,
                event_type: this.automation.event_type,
                event_config: this.automation.event_config,
                parent_automation_id: this.automation.id
            })),
            contentType: 'application/json',
            dataType: 'json',
            error: function(data) {
                callback(JSON.parse(data.responseText));
            },
            success: function () {
                callback();
                self.loadAutomation();
            }
        });
    },
    updateAutomationNode(data, callback) {
        var self = this;

        data = this.preprocessNodeData(data);

        $.ajax({
            type: 'PATCH',
            url: '/automation2_steps/' + data.id,
            data: JSON.stringify($.extend(data, {
                parent_automation_id: this.automation.id
            })),
            contentType: 'application/json',
            dataType: 'json',
            error: function(data) {
                callback(JSON.parse(data.responseText));
            },
            success: function () {
                callback && callback();
                self.loadAutomation();
            }
        });
    },
    removeAutomationNode(id) {
        var self = this;

        $.ajax({
            type: 'DELETE',
            url: '/automation2_steps/' + id,
            data: JSON.stringify({
                entity_migration: 'purge' || 'migrate'
            }),
            contentType: 'application/json',
            dataType: 'json',
            success: function () {
                self.loadAutomation();
            }
        });
    },
    DDSearch(url, queryAttributes, search, done) {
        if (!done) {
            return;
        }
        if (url === "/currencies") {
            done(Currency.getUsedCurrenciesToSelect2Array());
        }
        else {
            let query = "";
            _.each(queryAttributes, function(val, key) {
                if (key) {
                    query += '&' + key + '=' + val;
                }
            });
            $.ajax({
                url: url + '?search=' + search + query,
                success: function (data) {
                    done(data)
                }
            });
        }
    },
    showFilter(target, callback, filter, rule, ind) {
        target = $(target);
        var title, model;

        if (rule) {
            title = 'Edit Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection(rule)
            });
        }
        else {
            title = 'Add Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection()
            });
        }

        var popoverView = new FilterAdvancedView({
            title: title,
            collection: model.get('items'),
            fields: _.clone(this.filterFields)
        });

        this.listenTo(popoverView, 'form-saved', function() {
            var newRule = [];
            model.get('items').each(function(item) {
                newRule.push(item.attributes)
            });
            filter = filter || {};
            filter.rules = filter.rules || [];
            if (rule) {
                filter.rules[ind] = newRule;
            }
            else {
                filter.rules.push(newRule);
            }
            $.ajax({
                type: 'POST',
                url: '/' + this.automation.entity_type + '/filters',
                data: JSON.stringify({ rules: filter.rules }),
                contentType: 'application/json',
                dataType: 'json',
                success: function(filter) {
                    callback(filter);
                }
            })
        });

        this.popoverRegion.show(popoverView);
        this.popoverRegion.$el.css({
            left: target.offset().left - 170,
            top: target.offset().top - 180
        });
        this.popoverRegion.$el.show();
    },
    removeFilterRule(callback, filter, ind) {
        filter = filter || {};
        filter.rules = filter.rules || [];
        filter.rules.splice(ind, 1);

        $.ajax({
            type: 'POST',
            url: '/' + this.automation.entity_type + '/filters',
            data: JSON.stringify({ rules: filter.rules }),
            contentType: 'application/json',
            dataType: 'json',
            success: function(filter) {
                callback(filter);
            }
        })
    },
    showDateTime(target, callback, date, time) {
        target = $(target);
        var dateTimePicker = new DateTimePicker({
            altField: target,
            css: {
                left: target.offset().left - 170,
                top: target.offset().top - 180
            },
            date: date,
            time: time
        });
        dateTimePicker.showPicker();

        this.listenTo(dateTimePicker, 'date-time:change', callback);
    },
    showDate(target, callback) {
        $(target).datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            dateFormat: 'M d, yy',
            parent: this
        });
        $(target).datepicker('show');

        $(target).off('change').on('change', callback);
    },
    createMerge() {

    },
    getUrl: function() {
        var url = ['automations'];

        if (this.automation) {
           url.push(this.automation.id);
        }

        return url.join('/');
    }
});

export default AutomationReactContainerView;

import _ from 'underscore'
import $ from 'jquery'

import dateFormat from 'js/utils/date-format'
import BaseItemView from 'js/views/base/item'
import AssignView from 'js/views/social/assign'


var BaseSocialItemView = BaseItemView.extend({
    templateHelpers: function () {
        var model = this.model;
        return {
            thumb: function() {
                if (model.get('author') && model.get('author')['photo_url']) {
                    return model.get('author')['photo_url'];
                }
                else {
                    return null;
                }
            },
            assignee: function() {
                var assignee, owner = model.get('owner');

                // Bug: owner isn't always fetched by BB relational
                if (owner && owner.id) {
                    if (owner['name']) {
                        assignee = owner['name'];
                    }
                    else {
                        assignee = owner['email_address'];
                    }
                }
                return assignee;
            },
            formattedDate: dateFormat.timelineFormat(
                    new Date(model.get('created'))
                )
        };
    },
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );

        this.confirmOnClick = true;

        this.listenTo(
            this.model,
            'change:is_processed',
            function(model, value){
                this.toggleCompleted(value);
            }
        );
    },
    onBeforeRender: function() {
        if (this.model.get('owner') && this.model.get('owner').id) {
            this.$el.addClass('assigned');
        }
        this.toggleCompleted(this.model.get('is_processed'));
    },
    onRender: function() {
        this.ui.text.find('a').attr('target', '_blank');

        this.$el.find('[data-toggle=tooltip]').tooltip({container: 'body'});

        return BaseItemView.prototype.onRender.apply(this, arguments);
    },
    onClose: function() {
        if (this.assignView) {
            this.assignView.close();
        }
        $('body').off('click.hide-social-tools');
    },
    ui: _.extend({
        assign: '.assign',
        markCompleted: '.mark-completed',
        text: '.post-body p',
        assignee: '.assignee'
    }, BaseItemView.prototype.ui),
    events: _.extend({
        'click .post-header .name': 'showIndividual',
        'click .assign.has-permission': 'toggleAssignView',
        'click .mark-completed': 'setCompleted'
    },
    // we omit click as it breaks everything and should be removed from parent class
    _.omit(BaseItemView.prototype.events, 'click')),
    showIndividual: function(ev) {
        ev.preventDefault();
        var row = this.$el,
            model = this.model.get('author'),
            type = 'individuals';

        this.trigger('viewItem', row, model, type);
    },
    toggleAssignView: function(ev) {
        var assignee = this.model.get('owner');

        $(ev.currentTarget).tooltip('hide');
        // ev.stopPropagation();

        // If assignView has been created, just toggle visibility
        if (!this.assignView) {
            this.assignView = new AssignView({assignee: assignee});
            this.listenTo(this.assignView, 'assign-user', function(user) {
                this.assignItem(user);
            });
            this.listenTo(this.assignView, 'unassign-user', function() {
                this.removeAssignee();
            });

            this.assignView.render();
            this.assignView.$el.addClass('bottom');
            this.$el.find('.assign-wrapper').append(this.assignView.el);
        }

        this.toggleTools();
        // this.assignView.toggleShow();
    },
    assignItem: function(user) {
        var view = this;
        this.model.save({owner_id: user.id, owner: user}, {
            patch: true,
            success: function () {
                view.$el.addClass('assigned').removeClass('assign-view-show');
                view.ui.assign.attr('title', 'Assigned to ' + user.get('name')).tooltip('fixTitle');
                // if we loose permissions disable owner selection and add tooltip
                // if (!security.checkPermission('edit_owner', view.model)) {
                //     view.ui.assign.removeClass('has-permission');
                //     view.ui.assign.tooltip({
                //         container: 'body',
                //         placement: 'left',
                //         title: "You don't have permissions to edit owner"
                //     });
                // }
            }
        });
    },
    removeAssignee: function() {
        var view = this;
        this.model.save({owner_id: null, owner: null}, {
            patch: true,
            success: function() {
                view.$el.removeClass('assigned');
                view.ui.assign.attr('title', 'Assign item');
                if (view.assignView) {
                    view.assignView.onRemoveSuccess();
                }
            }
        });
    },
    setCompleted: function(ev) {
        var newState = !this.model.get('is_processed');
        this.model.save({is_processed: newState}, {patch: true, wait:true});

        // Hide the tooltip to prevent the tooltip from lingering
        if (newState === true) {
            $(ev.currentTarget).tooltip('hide');
        } else {
            $(ev.currentTarget).tooltip({
                container: 'body',
                placement: 'left'
            });
        }
    },
    toggleCompleted: function(completed) {
        this.$el.toggleClass('completed', completed, function() {
            // $(this).slideUp(function() {
            //     view.close();
            // });
        });
    },
    toggleTools: function() {
        var view = this;

        if (!this.$el.hasClass('assign-view-show')) {

            this.$el.addClass('assign-view-show');
            this.assignView.focusInput();

            $('body').on('mousedown.close-assign-popover', function(ev) {
                if(!$.contains( ev.target, $('.assign-wrapper') ) &&
                   !$(ev.target).closest('.assign-wrapper').length
                ) {
                    view.hideTools();
                    $(this).off('mousedown.close-assign-popover');
                }
            });
        } else {
            this.hideTools();
        }
    },
    hideTools: function() {
        this.$el.removeClass('assign-view-show');
    }
});

export default BaseSocialItemView;

import React from 'react';

import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager';
import styles from './search.css';

class CheckBox extends React.Component {
    handleCBClick(ev, select) {
        ev.stopPropagation();
        this.props.handleCBClick(select);
    }

    render() {
        return (
            <div className={styles.checkBox}>
                {this.props.selected ? (
                    <i onClick={(ev) => this.handleCBClick(ev, false)} className={"icon-checkmark mark " + styles.checkedIcon}></i>
                ) : (
                    <i onClick={(ev) => this.handleCBClick(ev, true)} className={"icon-checkmark2 unmark " + styles.icon}></i>
                )}
            </div>
        );
    }
}

class Item extends React.Component {
    getTitleParts() {
        const item = this.props.item;

        // to avoid using dangerouslySetInnerHTML we break the title_highlight field in multiple pieces based in the supported tags
        let titleParts = [];
        const parts = item.title_highlight.split('<br>');

        for (let p = 0; p < parts.length; ++p) {
            if (p > 0) {
                titleParts.push(<br key={`kpb_${p}`}/>);
            }

            const part = parts[p];
            const emStart = part.indexOf('<em>');
            let added = false;

            if (emStart !== -1) {
                const emEnd = part.indexOf('</em>', emStart);

                if (emEnd !== -1) {
                    titleParts.push(
                        <span
                            key={`kp_${p}`}
                        >
                            {part.substring(0, emStart)}
                            <em>{part.substring(emStart + 4, emEnd)}</em>
                            {part.substring(emEnd + 5)}
                        </span>
                    );

                    added = true;
                }
            }

            if (!added) {
                titleParts.push(<span key={`kp_${p}`}>{part}</span>);
            }
        }

        return titleParts;
    }

    render() {
        const iconMap = {
            individuals: 'icon-user',
            organizations: 'icon-home',
            opportunities: 'icon-filter'
        };

        const item = this.props.item;
        const showCommunications = AppConfig.getValue('search.show_email_and_phone_info', false) && item.communications;
        const showTags = AppConfig.getValue(`search.${item.type}.show_tags`, false) && item.tags;
        const titleParts = this.getTitleParts();

        return (
            <div className={styles.Item}
                    style={{ background: item.viewOpen && "rgba(10, 151, 255, .12)" || item.selected && "rgba(10, 151, 255, .06)" }}
                    onClick={() => { this.props.handleItemClick(item.type, item.id); }} >
                    <div className={styles.checkBoxContainer}>
                        <CheckBox selected={item.selected} handleCBClick={(select) => this.props.handleCBClick(item.id, select)}/>
                    </div>
                    <div className={styles.thumbContainer}>
                        <div className={styles.thumbContainerInner}>
                            <div className={styles.thumbBack}>
                                <i className={iconMap[item.type] + " " + styles.thumbBackI} ></i>
                            </div>

                            <div className={styles.thumb} style={{ backgroundImage: item.thumbnail ? "url('" + item.thumbnail + "')" : ""}}>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.searchItemTitle}
                        style={{ color: (item.selected || item.viewOpen) && "#0a97ff" }}
                    >
                        <div>{titleParts}</div>

                        {showTags &&
                            <div
                                className={styles.tagsContainer}
                                title={item.tags.join(', ')}
                            >
                                {item.tags.map((tag, tidx) => {
                                    return (
                                        <div
                                            key={`tag_${tidx}`}
                                            className={styles.tag}
                                        >
                                            {tag}
                                        </div>
                                    );
                                })}
                            </div>
                        }

                        {showCommunications &&
                            <div>({item.communications})</div>
                        }
                    </div>
                    <div style={{ clear: "both" }} />
            </div>
        );
    }
}

export class ExtendedIndividualItem extends Item {
    render() {
        const item = this.props.item;
        const titleParts = this.getTitleParts();
        const communications = (item.communications || '').split(',').map(c => c.trim());
        let email = '';
        let phone = '';

        for (const comm of communications) {
            if (!email && comm.indexOf('@') !== -1) { // todo: maybe not the best way to detect an email
                email = ` (${comm})`;
            } else if (!phone) {
                phone = comm;
            }
        }

        const tags = item.tags || [];
        let extendedInfo = [];

        if (phone) {
            extendedInfo.push(phone);
        }

        if (item.additional_fields) {
            for (const f in item.additional_fields) {
                extendedInfo.push(item.additional_fields[f]);
            }
        }

        const extendedInfoText = extendedInfo.join(' | ');

        return (
            <div
                className={styles.Item}
                style={{ background: item.viewOpen && "rgba(10, 151, 255, .12)" || item.selected && "rgba(10, 151, 255, .06)" }}
                onClick={() => { this.props.handleItemClick(item.type, item.id); }}
            >
                <div className={styles.checkBoxContainer}>
                    <CheckBox selected={item.selected} handleCBClick={(select) => this.props.handleCBClick(item.id, select)}/>
                </div>

                <div className={styles.thumbContainer}>
                    <div className={styles.thumbContainerInner}>
                        <div className={styles.thumbBack}>
                            <i className={'icon-user ' + styles.thumbBackI}></i>
                        </div>

                        <div className={styles.thumb} style={{ backgroundImage: item.thumbnail ? "url('" + item.thumbnail + "')" : ""}}/>
                    </div>
                </div>

                <div
                    className={styles.searchItemTitle}
                    style={{ color: (item.selected || item.viewOpen) && "#0a97ff" }}
                >
                    <div>{titleParts}{email}</div>

                    <div
                        className={styles.tagsContainer}
                        title={tags.join(', ')}
                    >
                        {tags.map((tag, tidx) => {
                            return (
                                <div
                                    key={`tag_${tidx}`}
                                    className={styles.tag}
                                >
                                    {tag}
                                </div>
                            );
                        })}
                    </div>

                    <div className={styles.extendedInfo}>
                        {extendedInfoText}
                    </div>
                </div>
            </div>
        );
    }
}

class ItemList extends React.Component {
    render() {
        if (this.props.browseMode && this.props.browseMode !== this.props.type) {
            return null;
        }

        if (!this.props.items.length) {
            return null;
        }


        let deleteButton, editButton, mergeButton;

        if (this.props.hasSelected) {
            if (this.props.buttons.del) {
                deleteButton = <div onClick={() => this.props.handleBulkDelete(this.props.type)} className={styles.deleteButton}>Delete</div>;
            }
            if (this.props.buttons.edit) {
                editButton = <div onClick={() => this.props.handleBulkEdit(this.props.type)} className={styles.editButton}>Edit</div>;
            }
            if (this.props.buttons.merge) {
                mergeButton = <div onClick={() => this.props.handleMergeItems(this.props.type)} className={styles.mergeButton}>Merge</div>;
            }
        }

        return (
            <div className={styles.ItemList}  style={{ width: this.props.browseMode ? "100%" : "33%" }}>
                <div className={styles.title}>{this.props.title}</div>
                <div className={styles.buttonContainer}>
                    <div className={styles.checkBoxContainer}>
                        <CheckBox selected={this.props.allSelected} handleCBClick={(select) => this.props.handleSelectAllClick(this.props.type)}/>
                    </div>
                    {deleteButton}
                    {editButton}
                    {mergeButton}
                    <div style={{ clear: 'both' }} />
                </div>
                <div className={styles.searchList}>
                    {
                        this.props.items.map((item) => {
                            const ItemComponent = this.props.itemView || Item;
                            return <ItemComponent key={item.id} item={item}
                                         handleItemClick={this.props.handleItemClick}
                                         handleCBClick={(id, select) => this.props.handleCBClick(this.props.type, id, select)} />;
                        })
                    }
                </div>
            </div>
        )
    }
}

class SearchReactView extends React.Component {
    handleSearchChange(ev) {
        this.handleSearch(ev.target.value);
    }

    handleSearchButton(ev) {
        ev.preventDefault();
        this.handleSearch(this.props.searchTerm);
    }

    handleSearch(searchTerm) {
        this.props.handleSearch(searchTerm);
    }

    render() {
        let searchResults;

        const renderOrganizationsSearch = AppConfig.getValue('search.show_organizations', true);
        const IndividualItemComponent = AppConfig.getValue('search.individuals.item_component', Item);

        if (this.props.loading) {
            searchResults = <div className="loader-container">
                <div className="loading-view"><div className="loader"></div></div>
            </div>;
        }
        else {
            if (this.props.hasResults) {
                searchResults = <div className={styles.searchResults}>
                    <ItemList items={this.props.groupedResults['individuals']}
                          browseMode={this.props.browseMode}
                          title={TextManager.parseText('${ID_INDIVIDUAL, capitalize, plural}')} type={"individuals"}
                          itemView={IndividualItemComponent}
                          allSelected={this.props.allSelected["individuals"]}
                          hasSelected={this.props.hasSelected["individuals"]}
                          buttons={{ del: true, edit: true, merge: true }}
                          handleItemClick={this.props.handleItemClick}
                          handleSelectAllClick={this.props.handleSelectAllClick}
                          handleCBClick={this.props.handleCBClick}
                          handleBulkDelete={this.props.handleBulkDelete}
                          handleBulkEdit={this.props.handleBulkEdit}
                          handleMergeItems={this.props.handleMergeItems}
                    />
                    {renderOrganizationsSearch && (
                    <ItemList items={this.props.groupedResults['organizations']}
                          browseMode={this.props.browseMode}
                          title={TextManager.parseText('${ID_ORGANIZATION, capitalize, plural}')} type={"organizations"}
                          allSelected={this.props.allSelected["organizations"]}
                          hasSelected={this.props.hasSelected["organizations"]}
                          buttons={{ del: true, edit: true, merge: true }}
                          handleItemClick={this.props.handleItemClick}
                          handleSelectAllClick={this.props.handleSelectAllClick}
                          handleCBClick={this.props.handleCBClick}
                          handleBulkDelete={this.props.handleBulkDelete}
                          handleBulkEdit={this.props.handleBulkEdit}
                          handleMergeItems={this.props.handleMergeItems}
                    />
                    )}
                    <ItemList items={this.props.groupedResults['opportunities']}
                          browseMode={this.props.browseMode}
                          title={TextManager.parseText('${ID_DEAL, capitalize, plural}')} type={"opportunities"}
                          allSelected={this.props.allSelected["opportunities"]}
                          hasSelected={this.props.hasSelected["opportunities"]}
                          buttons={{ del: true, edit: true }}
                          handleItemClick={this.props.handleItemClick}
                          handleSelectAllClick={this.props.handleSelectAllClick}
                          handleCBClick={this.props.handleCBClick}
                          handleBulkDelete={this.props.handleBulkDelete}
                          handleBulkEdit={this.props.handleBulkEdit}
                          handleMergeItems={this.props.handleMergeItems}
                    />
                </div>;
            }
            else {
                if (AppConfig.getValue('search.show_create_individual_button') && this.props.searchTerm) {
                    searchResults = <div className={styles.noItemContainer}>
                        <div className={styles.noItem}>No items to display</div>
                        <div
                          className={styles.createIndividual}
                          onClick={this.props.handleNewIndividual}
                        >
                          Create New {TextManager.parseText('${ID_INDIVIDUAL, capitalize}')}
                        </div>
                    </div>;
                } else {
                    searchResults = <div className={styles.noItemContainer}>
                        <div className={styles.noItem}>No items to display</div>
                    </div>;
                }
            }
        }

        return (
            <div className={styles.SearchReactView + " " + (this.props.browseMode ? styles.browseMode : "")}>
                <header className={styles.header}>
                    <div className={styles.headerInner}>
                        {this.props.browseMode &&
                            <a className={styles.backToResults} onClick={this.props.handleBackToResults} >Back to results</a>
                        }
                        <h5 className={styles.searchHeading}>Search</h5>
                        <div className={{ clear: "both" }}/>
                    </div>
                </header>
{/*                <div className={styles.searchBox}>
                    <div className={styles.inputContainer}>
                        <input ref={inp => this.searchInp = inp} type="search" placeholder="Type to search…" value={this.props.searchTerm} onChange={this.handleSearchChange.bind(this)}
                               className={styles.input} />
                    </div>
                    <button onClick={this.handleSearchButton.bind(this)} className={styles.search}>
                        <i className={styles.searchIcon}></i>
                    </button>
                </div>
*/}                <div className={styles.searchResultsContainer}>
                    {searchResults}
                </div>
            </div>
        );
    }
}

export default SearchReactView;

import _ from 'underscore'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import vent from 'js/vent'
import PaginatedListView from 'js/views/base/paginated_list'
import BaseToolbarView from 'js/views/base/toolbar'


var ListView = PaginatedListView.extend({
    fetchCollection: function() {
        this.trigger(
            'fetch-collection',
            {},
            this.collection.collectionPage,
            this.length,
            null
        );
    },
    onCollection: function(collection) {
        this.collection = collection;

        PaginatedListView.prototype.onCollection.apply(this, arguments);
    }
});

export default Marionette.Layout.extend({
    tagName: 'article',
    className: 'list-wrapper',
    template: Handlebars.compile([
        '<header class="header list-header">',
        '    <div class="list-toolbar toolbar-container"></div>',
        '</header>',
        '<article class="list-container"></article>'
    ].join('')),
    regions: {
        toolbarRegion: '.list-toolbar',
        listRegion: '.list-container'
    },
    onRender: function() {
        this.showToolbarView();
        this.showListView();
    },
    beforeCollection: function() {
        if (this.listRegion) {
            this.listRegion.currentView.beforeCollection();
        }
        if (this.toolbarRegion) {
            this.toolbarRegion.currentView.disableSelect();
            this.toolbarRegion.currentView.setSelection([]);
        }
    },
    onCollection: function(collection) {
        if (this.listRegion) {
            this.listRegion.currentView.onCollection(collection);
        }
    },
    showToolbarView: function() {
        var toolbarView = this.createToolbarView();

        // Open a new-form when clicking on the toolbar '+' button
        this.listenTo(toolbarView, 'toolbar:new', function() {
            this.trigger('toolbar:new');
        });
        this.listenTo(toolbarView, 'toolbar:add-item', function(model) {
            this.trigger('toolbar:add-item', model);
        });
        this.listenTo(toolbarView, 'toolbar:delete-bulk', function(options) {
            this.trigger('toolbar:delete-bulk', options);
        });
        this.listenTo(toolbarView, 'toolbar:remove', function(data) {
            this.trigger('toolbar:remove', data);
        });

        this.listenTo(toolbarView, 'toolbar:edit', function(options) {
            this.trigger('toolbar:edit', options);
        });

        this.listenTo(toolbarView, 'toolbar:selectAllPage', function() {
            if (this.listRegion) {
                this.listRegion.currentView.selectAllPage();
            }
        });
        this.listenTo(toolbarView, 'toolbar:deselectAllPage', function() {
            if (this.listRegion) {
                this.listRegion.currentView.deselectAllPage();
            }
        });

        this.toolbarRegion.show(toolbarView);
    },
    createToolbarView: function() {
        return new BaseToolbarView({
            collection: this.collection,
            tools: this.options.tools,
            toolLabels: this.options.toolLabels
        });
    },
    showListView: function() {
        var listView = this.createListView();

        this.listenTo(listView, 'selectionChange', function(sel) {
            if (this.toolbarRegion) {
                this.toolbarRegion.currentView.setSelection(sel);
            }
        });
        this.listenTo(listView, 'collectionCountChange', function(count) {
            if ( this.toolbarRegion ) {
                this.toolbarRegion.currentView.setCollectionCount(count);
            }
        });
        this.listenTo(listView, 'item:select', function(model) {
            if (this.toolbarRegion) {
                this.toolbarRegion.currentView.select(model);
            }
        });
        this.listenTo(listView, 'followLink', function(model, link) {
            this.trigger('followLink', model, link);
        });
        this.listenTo(listView, 'showItem', function(model) {
            this.trigger('showItem', model);
        });
        this.listenTo(listView, 'fetch-collection', function(options, page, rows, sortOptions) {
            this.trigger('fetch-collection', options, page, rows, sortOptions);
        });
        this.listenTo(listView, 'highlight', function(model) {
            this.trigger('highlight', model);
        });

        this.listRegion.show(listView);
    },
    createListView: function() {
        return new ListView(this.options.listViewOptions);
    },
    clearSelection: function() {
        this.toolbarRegion.currentView.selectedCounter(0);
    }
});

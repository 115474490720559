import React from 'react';
import ReactDOM from 'react-dom';
import ace from 'ace';

import app from 'js/app';
import SEE from 'js/react_views/see/see';
import Utilities from 'js/utils/utilities';
import segmentsCalculator from 'js/utils/segments_calculator';
import LibraryBrowser from 'js/react_views/library-browser/browser';
import MessageBox from 'js/views/message_box';
import htmlSanitizer from 'js/utils/html-sanitizer';
import { NewSelect } from 'js/react_views/widgets/select';
import MergeTags from './merge_tags';

import style from './campaign_creation.css';


class SEEView extends React.Component {
    constructor(props) {
        super(props);

        this.mergeTags = new MergeTags(this.props.parent);
        this.mergeTagItems = this.mergeTags.getTagItems(true, props.campaignData.funnel_id);
    }

    handleSeeConfigChange(config) {
        const currentConfig = this.props.campaignData.see_config;

        if (currentConfig.funnelId) {
            config.funnelId = currentConfig.funnelId;
        }

        if (currentConfig.folderId) {
            config.folderId = currentConfig.folderId;
        }

        this.props.onDataChange('see_config', config);
    }

    handleContentChange(content) {
        this.props.onDataChange('content', content);
    }

    render() {
        return (
            <SEE
                parent={this.props.parent}
                userType={this.props.userType}
                mergeTagItems={this.mergeTagItems}
                config={this.props.campaignData.see_config}
                funnelId={this.props.campaignData.funnel_id}
                isTemplate={this.props.campaignData.campaign_subtype === 'template'}
                templateCategories={this.props.templateCategories}
                onSeeConfigChange={this.handleSeeConfigChange.bind(this)}
                onContentChange={this.handleContentChange.bind(this)}
            />
        );
    }
}

class HTMLView extends React.Component {
    constructor(props) {
        super(props);

        this.mergeTags = new MergeTags(this.props.parent);
        this.mergeTagItems = this.mergeTags.getTagItems(true, props.campaignData.funnel_id);

        this.state = {
            previewWidth: 450,
            mergeTagSelectorExpanded: false
        };
    }

    componentDidMount() {
        $(window).on('mousemove', this.onMouseMove.bind(this));
        $(window).on('mouseup', this.onMouseUp.bind(this));

        ace.config.setModuleUrl(
            'ace/mode/html_worker',
            './vendor/ace-editor-1.2.3/src-noconflict/worker-html.js'
        );

        this.editor = ace.edit('html-code-editor');
        this.editor.setTheme('ace/theme/clouds');
        this.editor.getSession().setMode('ace/mode/html');
        this.editor.getSession().setUseWrapMode(true);
        this.editor.setValue(this.props.campaignData.content || '');
        this.editor.$blockScrolling = Infinity;

        const self = this;

        this.editor.on('input', function() {
            $(self.iframe).contents().find('html').get(0).innerHTML = self.editor.getValue();
        });
    }

    componentWillUnmount() {
        $(window).off('mousemove', this.onMouseMove);
        $(window).off('mouseup', this.onMouseUp);
    }

    startResizing(ev) {
        this.xPos = ev.pageX;
        this.resizing = true;
    }

    onMouseUp() {
        this.resizing = false;
    }

    onMouseMove(ev) {
        if (!this.resizing) {
            return;
        }

        const diff = ev.pageX - this.xPos;
        this.xPos = ev.pageX;

        this.setState({
            previewWidth: Utilities.clamp(this.state.previewWidth + diff, 250, 750)
        });
    }

    handleMergeTagSelect(items) {
        const self = this;

        this.mergeTags.manageTagInsertion(items[0], function(tag) {
            self.editor.insert(tag);
            self.editor.focus();
        });
    }

    handleMergeTagClose() {
        this.setState({
            mergeTagSelectorExpanded: false
        });
    }

    handleMergeTagButtonClick() {
        this.mergeTagSelector.toggleExpanded(true);

        this.setState({
            mergeTagSelectorExpanded: true
        });
    }

    render() {
        return (
            <div className={style.cHtmlEditionContainer}>
                <div
                    className={`
                        ${style.sBlock}
                        ${style.cPreview}
                    `}
                    style={{
                        width: `${this.state.previewWidth}px`,
                        padding: 0
                    }}
                >
                    <div className={style.pHeader}>
                        This is for preview purposes only. Different email
                        <br/>
                        clients may well display this email differently.
                    </div>

                    <iframe
                        ref={(el) => this.iframe = el}
                        sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation'
                    />
                </div>

                <div className={style.cResizer}>
                    <div
                        className='icon-drag-handle'
                        onMouseDown={this.startResizing.bind(this)}
                    />
                </div>

                <div
                    className={`
                        ${style.sBlock}
                        ${style.cEditorContainer}
                    `}
                    style={{
                        flexGrow: 1,
                        padding: 0
                    }}
                >
                    <div className={style.eHeader}>
                        <div
                            className={style.meMergeTagSelector}
                            onClick={this.handleMergeTagButtonClick.bind(this)}
                        >
                            Use a Merge Tag
                        </div>

                        <div
                            className={`
                                ${style.meDropdown}
                                ${this.state.mergeTagSelectorExpanded ? style.meExpanded : ''}
                            `}
                            style={{
                                top: '30px',
                                right: '25px'
                            }}
                        >
                            <NewSelect
                                ref={(el) => this.mergeTagSelector = el}
                                data={this.mergeTagItems}
                                width={200}
                                text='name'
                                boxStyle={{
                                    visibility: 'hidden'
                                }}
                                onSelect={this.handleMergeTagSelect.bind(this)}
                                onClose={this.handleMergeTagClose.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        id='html-code-editor'
                        className={style.eCodeEditor}
                    />
                </div>
            </div>
        );
    }
}

class WYSIWYGView extends React.Component {
    constructor(props) {
        super(props);

        this.mergeTags = new MergeTags(this.props.parent);
        this.mergeTagItems = this.mergeTags.getTagItems(true, props.campaignData.funnel_id);

        this.signature = htmlSanitizer.sanitize(props.campaignData?.from_user?.email_signature || app.user.get('email_signature') || '');

        const trackThisEmail = 'track' in props.campaignData ? props.campaignData.track : true;
        const includeSignature = 'include_signature' in props.campaignData ? props.campaignData.include_signature : true;

        this.state = {
            mergeTagSelectorExpanded: false,
            editorVisible: false,
            trackThisEmail: trackThisEmail,
            includeSignature: includeSignature
        };
    }

    componentDidMount() {
        this.props.onDataChangeObj({
            include_signature: this.state.includeSignature,
            track: this.state.trackThisEmail
        });

        const self = this;
        const editor = $(this.textArea);

        _.defer(function() {
            editor.tinymce({
                promotion: false,
                branding: false,
                license_key: 'gpl',
                skin: 'oxide',
                plugins: 'link lists',
                paste_as_text: true,
                toolbar: 'bold italic underline bullist numlist link',
                menubar: false,
                statusbar: false,
                resize: false,
                urlconverter_callback: function(url) {
                    if (url === '${unsubscribe-url}') {
                        return url;
                    }

                    const re = new RegExp("^(ftps?|https?)://", "i");

                    if (!re.test(url)) {
                        url = 'http://' + url;
                    }

                    return url;
                },
                extended_valid_elements: 'a[href|target=_blank|title]',
                target_list: false,
                oninit: function() {
                    self.editor = this.activeEditor;
                    self.editor.show();

                    if (self.props.campaignData.content) {
                        self.editor.setContent(self.props.campaignData.content);
                    }

                    const tinyContainer = $(self.content).find('.tox-tinymce:first');

                    tinyContainer.css('height', '100%');
                    tinyContainer.css('border', 'none');

                    _.defer(function() {
                        editor.tinymce().focus();

                        self.setState({
                            editorVisible: true
                        });
                    });

                    self.editor.on('change', function() {
                        self.props.onDataChange('content', self.editor.getContent());
                    });
                }
            });
        });
    }

    handleMergeTagSelect(items) {
        const self = this;

        this.mergeTags.manageTagInsertion(items[0], function(tag) {
            self.editor.execCommand('mceInsertContent', false, tag);
        });
    }

    handleMergeTagClose() {
        this.setState({
            mergeTagSelectorExpanded: false
        });
    }

    handleMergeTagButtonClick() {
        this.mergeTagSelector.toggleExpanded(true);

        this.setState({
            mergeTagSelectorExpanded: true
        });
    }

    handleIncludeSignatureClick(ev) {
        this.setState({
            includeSignature: ev.target.checked
        });

        this.props.onDataChange('include_signature', ev.target.checked);
    }

    handleTrackEmailClick(ev) {
        this.setState({
            trackThisEmail: ev.target.checked
        });

        this.props.onDataChange('track', ev.target.checked);
    }

    render() {
        return (
            <div className={style.cWysiwygEditionContainer}>
                <div className={style.meToolbar}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px'
                        }}
                    >
                      <label htmlFor='includeSignature'>
                        <div className='checkbox-button-container'>
                          <input
                            type='checkbox'
                            name='includeSignature'
                            id='includeSignature'
                            checked={this.state.includeSignature}
                            onChange={this.handleIncludeSignatureClick.bind(this)}
                            style={{display: 'none'}}
                          />
                          <div className='checkbox-button'>
                            <div className='checkbox-button-slider'/>
                          </div>
                        </div>
                      </label>

                      <div>Include sender's signature</div>
                    </div>

                    {this.state.includeSignature &&
                        <div
                            className={style.meEditSignature}
                        >
                            Edit your email signature
                        </div>
                    }

                    <div
                        className={style.meMergeTagSelector}
                        onClick={this.handleMergeTagButtonClick.bind(this)}
                    >
                        Use a Merge Tag
                    </div>

                    <div
                        className={`
                            ${style.meDropdown}
                            ${this.state.mergeTagSelectorExpanded ? style.meExpanded : ''}
                        `}
                    >
                        <NewSelect
                            ref={(el) => this.mergeTagSelector = el}
                            data={this.mergeTagItems}
                            width={200}
                            text='name'
                            boxStyle={{
                                visibility: 'hidden'
                            }}
                            onSelect={this.handleMergeTagSelect.bind(this)}
                            onClose={this.handleMergeTagClose.bind(this)}
                        />
                    </div>
                </div>

                <div
                    ref={(el) => this.content = el}
                    className={style.meContent}
                    style={{
                        visibility: this.state.editorVisible ? 'visible' : 'hidden'
                    }}
                >
                    <textarea
                        ref={(el) => this.textArea = el}
                    />
                </div>

                {this.state.includeSignature && this.state.editorVisible &&
                    <div
                        className={style.meSignature}
                        dangerouslySetInnerHTML={{ __html: this.signature }}
                    />
                }

                <div className={style.meFooter}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '10px'
                        }}
                    >
                      <label htmlFor='trackThisEmail'>
                        <div className='checkbox-button-container'>
                          <input
                            type='checkbox'
                            name='trackThisEmail'
                            id='trackThisEmail'
                            checked={this.state.trackThisEmail}
                            onChange={this.handleTrackEmailClick.bind(this)}
                            style={{display: 'none'}}
                          />
                          <div className='checkbox-button'>
                            <div className='checkbox-button-slider'/>
                          </div>
                        </div>
                      </label>

                      <div>Track this Email</div>
                    </div>

                    <div
                        style={{
                            marginLeft: 'auto',
                            marginRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            color: 'rgba(102, 102, 102, .7)',
                            fontSize: '12px'
                        }}
                    >
                        <div
                            className='icon-info'
                            style={{
                                marginRight: '3px',
                                fontSize: '14px'
                            }}
                        />
                        <div>{
                            this.state.trackThisEmail ?
                                'NOTE: Some email clients may mark tracked mail as spam'
                            :
                                'NOTE: We cannot provide analytics data for this Email'
                        }</div>
                    </div>
                </div>
            </div>
        );
    }
}

class MessageView extends React.Component {
    constructor(props) {
        super(props);

        this.mergeTags = new MergeTags(this.props.parent);
        this.mergeTagItems = this.mergeTags.getTagItems(false, props.campaignData.funnel_id);

        this.maxLength = app.globalData.smsInfo?.messageMaxLength || 160;
        this.maxNumAttachments = 10;

        this.state = {
            numRemainingCharacters: this.maxLength,
            segmentsInfo: '',
            content: this.props.campaignData.content || '',
            attachments: [],
            mergeTagSelectorExpanded: false
        };
    }

    componentDidMount() {
        this.processMessage(this.state.content);

        const self = this;

        _.defer(function() {
            self.textArea.focus();
        });
    }

    processMessage(content) {
        let segCalculator = new segmentsCalculator(content, 'auto');
        let updateTextArea = false;

        if (segCalculator.getEncodingName() === 'UCS-2') {
            for (let i = 0; i < content.length; ++i) {
                const char = Utilities.unicodeToGsm(content.charCodeAt(i));

                if (char) {
                    content = content.substr(0, i) + char + content.substr(i + 1)
                    updateTextArea = true;
                }
            }
        }

        if (updateTextArea) {
            const textArea = $(this.textArea);
            const selectionStart = textArea.prop('selectionStart');
            const selectionEnd = textArea.prop('selectionEnd');

            textArea.prop('selectionStart', selectionStart);
            textArea.prop('selectionEnd', selectionEnd);

            this.setState({
                content: content
            });
        }

        this.props.onDataChange('content', content);

        segCalculator = new segmentsCalculator(content, 'auto');

        const numSegments = segCalculator.numSegments;
        const numCharactersLeft = this.maxLength - content.length;

        this.setState({
            numRemainingCharacters: numCharactersLeft,
            segmentsInfo: `${numSegments} segment${numSegments !== 1 ? 's' : ''} (${segCalculator.getEncodingName()})`
        });
    }

    handleTextAreaChange(ev) {
        const content = ev.target.value;

        this.processMessage(content);

        this.setState({
            content: content
        });
    }

    handleAttachImageClick() {
        if (this.state.attachments.length >= this.maxNumAttachments) {
            return;
        }

        const self = this;

        this.props.showLibraryBrowser(function(image) {
            self.setState({
                attachments: [...self.state.attachments, image]
            });
        }, {
            folderId: 'public',
            ownerIsAll: true,
            validateFile: this.validateFile.bind(this)
        });
    }

    handleFileDeattach(attachmentIdx) {
        let attachments = this.state.attachments;

        attachments.splice(attachmentIdx, 1);

        this.setState({
            attachments: attachments
        });
    }

    validateFile(name, size) {
        // only jpg, gif and png formats are supported. The max size limit is 5Mb
        const dotPos = name.lastIndexOf('.');
        const ext = (dotPos !== -1 ? name.substr(dotPos + 1) : '').toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif'].indexOf(ext) === -1) {
            MessageBox.showOk({
                message: 'Supported formats are: JPEG, JPG, GIF and PNG',
                icon: 'icon-warning'
            }, this.props.parent);

            return false;
        }

        if (size > (5 * 1024 * 1024)) {
            MessageBox.showOk({
                message: 'There is a limit of 5Mb per image',
                icon: 'icon-warning'
            }, this.props.parent);

            return false;
        }

        return true;
    }

    handleMergeTagSelect(items) {
        const self = this;

        this.mergeTags.manageTagInsertion(items[0], function(tag) {
            const textArea = $(self.textArea);
            let cursorPos = textArea.prop('selectionStart');
            const message = textArea.val();
            const textBefore = message.substring(0, cursorPos);
            const textAfter = message.substring(cursorPos, message.length);
            const content = `${textBefore}${tag}${textAfter}`;

            self.setState({
                content: content
            });

            cursorPos += tag.length;
            textArea.prop('selectionStart', cursorPos);
            textArea.prop('selectionEnd', cursorPos);
            textArea.focus();

            self.processMessage(content);
        });
    }

    handleMergeTagClose() {
        this.setState({
            mergeTagSelectorExpanded: false
        });
    }

    handleMergeTagButtonClick() {
        this.mergeTagSelector.toggleExpanded(true);

        this.setState({
            mergeTagSelectorExpanded: true
        });
    }

    render() {
        const attachmentsAllowed = !!app.globalData.smsInfo?.attachmentsAllowed;

        return (
            <div className={style.cMessageEditionContainer}>
                <div className={style.meToolbar}>
                    <div
                        className={style.meMergeTagSelector}
                        onClick={this.handleMergeTagButtonClick.bind(this)}
                    >
                        Use a Merge Tag
                    </div>

                    <div
                        className={`
                            ${style.meDropdown}
                            ${this.state.mergeTagSelectorExpanded ? style.meExpanded : ''}
                        `}
                    >
                        <NewSelect
                            ref={(el) => this.mergeTagSelector = el}
                            data={this.mergeTagItems}
                            width={200}
                            text='name'
                            boxStyle={{
                                visibility: 'hidden'
                            }}
                            onSelect={this.handleMergeTagSelect.bind(this)}
                            onClose={this.handleMergeTagClose.bind(this)}
                        />
                    </div>
                </div>

                <div className={style.meContent}>
                    <textarea
                        ref={(el) => this.textArea = el}
                        maxLength={this.maxLength}
                        value={this.state.content}
                        onChange={this.handleTextAreaChange.bind(this)}
                    />
                </div>

                <div className={style.meFooter}>
                    {attachmentsAllowed &&
                        <div className={style.meAttachmentContent}>
                            <div
                                className={`
                                    ${style.meAttachButton}
                                    ${this.state.attachments.length >= this.maxNumAttachments ? style.meDisabled : ''}
                                `}
                                onClick={this.handleAttachImageClick.bind(this)}
                            >
                                Attach Image
                            </div>

                            <div className={style.meSeparator}/>

                            <div className={style.meAttachmentsContainer}>
                                {this.state.attachments.map((attachment, aidx) => {
                                    return (
                                        <div
                                            key={`attachment_${aidx}`}
                                            className={style.meAttachment}
                                        >
                                            <div
                                                className={style.meAttachmentName}
                                                title={attachment.name}
                                            >
                                                {attachment.name}
                                            </div>

                                            <div
                                                className={style.meDeattachButton}
                                                onClick={() => this.handleFileDeattach.bind(this)(aidx)}
                                            >
                                                ×
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={style.meSeparator}/>
                        </div>
                    }

                    <div className={style.meMessageInfo}>
                        <div
                            style={{
                                marginRight: '10px',
                                marginLeft: '10px'
                            }}
                        >
                            {this.state.numRemainingCharacters} {`character${this.state.numRemainingCharacters !== 1 ? 's' : ''}`} left
                        </div>

                        <div className={style.meSeparator}/>

                        <div
                            style={{margin: '0 10px'}}
                        >
                            {this.state.segmentsInfo}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class StepCompose extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            libraryBrowser: null
        };
    }

    showLibraryBrowser(callback, props) {
        this.setState({
            libraryBrowser: _.extend(props || {}, {
                callback: callback
            })
        });
    }

    hideLibraryBrowser() {
        this.setState({
            libraryBrowser: null
        });
    }

    handleFileSelected(file) {
        this.state.libraryBrowser.callback(file);
        this.hideLibraryBrowser();
    }

    onNext() {
        let warnings = [];

        if (this.props.campaignData.campaign_subtype === 'campaign') {
            // find at least one mailchimp tag
            if (this.props.campaignData.content.match(/\*\|.{1,30}?\|\*/)) {
                warnings.push('The email content contains Mailchimp merge tags.');
            }

            // look for replaceme tag
            if (this.props.campaignData.content.match(/\${REPLACEME}/)) {
                warnings.push('The email content contains ${REPLACEME} merge tag.');
            }

            // is a campaign without unsubscribe url?
            if (this.props.campaignData.campaign_type === 'campaign') {
                if (!this.props.campaignData.content.match(/\${unsubscribe-url}/)) {
                    warnings.push('The email content is missing SalesSeek unsubscribe link.');
                }
            }
        }

        if (warnings.length > 0) {
            const self = this;
            let warningsList = [];

            for (const warning of warnings) {
                warningsList.push(`<li style="margin-top: 10px">${warning}</li>`);
            }

            MessageBox.showYesNo({
                icon: 'icon-warning',
                message: `The email contains these warnings. Are you sure you want to proceed? <br><ul style="margin-top: 10px">${warningsList.join('')}</ul>`
            }, this.props.parent, function() {
                self.props.gotoNextStep();
            });
        } else {
            this.props.gotoNextStep();
        }
    }

    render() {
        let Component = null;

        switch (this.props.campaignData.campaign_type) {
            case 'campaign':
                Component = (this.props.campaignData.see_config === null) ? HTMLView : SEEView;
                break;

            case 'direct':
                Component = WYSIWYGView;
                break;

            case 'message':
                Component = MessageView;
                break;
        }

        return (
            <div className={style.cStep}>
                <Component
                    campaignData={this.props.campaignData}
                    parent={this.props.parent}
                    userType={this.props.userType}
                    templateCategories={this.props.templateCategories}
                    showLibraryBrowser={this.showLibraryBrowser.bind(this)}
                    onDataChange={this.props.updateCampaignData}
                    onDataChangeObj={this.props.updateCampaignDataObj}
                />

                {this.state.libraryBrowser &&
                    <LibraryBrowser
                        {...this.state.libraryBrowser}
                        parent={this.props.parent}
                        onCancel={this.hideLibraryBrowser.bind(this)}
                        onFileSelected={this.handleFileSelected.bind(this)}
                    />
                }
            </div>
        );
    }
}

export default StepCompose;
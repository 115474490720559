import React from 'react';
import Utilities from 'js/utils/utilities';
import app from 'js/app';

import style from './section.css';


const DEFAULT_SIDEBAR_MIN_WIDTH = 220;
const DEFAULT_SIDEBAR_MAX_WIDTH = 500;
const SIDEBAR_COLLAPSED_WIDTH = 75;


class SidebarItem extends React.Component {
    render() {
        return (
            <div
                className={`
                    ${style.sidebarItem}
                    ${this.props.active ? style.active : ''}
                `}
                onClick={() => this.props.onSelected(this.props.data.id)}
            >
                <div className={`${style.icon} ${this.props.data.icon}`}/>
                <div className={style.name}>{this.props.data.title}</div>
            </div>
        );
    }
}


export class SidebarList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemSelected: this.props.itemSelected
        };
    }

    handleItemSelected(itemId) {
        this.setState({
            itemSelected: itemId
        });

        this.props.onItemSelected(itemId);
    }

    render() {
        return (
            <div className={style.sidebarItemsContainer}>
                {this.props.items.map(item => {
                    return (
                        <SidebarItem
                            key={`sidebar_item_${item.id}`}
                            data={item}
                            active={item.id === this.state.itemSelected}
                            onSelected={this.handleItemSelected.bind(this)}
                        />
                    );
                })}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: this.props.width
        };

        this.draggingBorder = false;
        this.collapsed = false;

        document.addEventListener('mousemove', this.onMouseMove.bind(this));
        document.addEventListener('mouseup', this.stopDragging.bind(this));

        this.props.onResize(this.state.width);
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.stopDragging);
    }

    handleToggleCollapse(collapsed) {
        this.collapsed = collapsed;
        this.props.onToggleCollapse(this.collapsed);
    }

    startDragging(ev) {
        this.initialWidth = this.state.width;
        this.screenX = ev.screenX;
        this.draggingBorder = true;
        this.props.onStartDragging();
    }

    stopDragging(ev) {
        if (!this.draggingBorder) {
            return;
        }

        const diff = ev.screenX - this.screenX;
        const newWidth = Utilities.clamp(this.initialWidth + diff, DEFAULT_SIDEBAR_MIN_WIDTH, DEFAULT_SIDEBAR_MAX_WIDTH);

        this.setState({
            width: newWidth
        });

        this.draggingBorder = false;

        let sidebarPrefs = (app.user.get('preferences') || {}).groups_sidebar_width || {};

        sidebarPrefs[`${this.props.id}-sidebar`] = newWidth;
        app.user.updatePreference('groups_sidebar_width', sidebarPrefs);

        this.props.onResize(newWidth);
        this.props.onStopDragging();
    }

    onMouseMove(ev) {
        if (!this.draggingBorder) {
            return;
        }

        const diff = ev.screenX - this.screenX;
        const newWidth = Utilities.clamp(this.initialWidth + diff, DEFAULT_SIDEBAR_MIN_WIDTH, DEFAULT_SIDEBAR_MAX_WIDTH);

        this.setState({
            width: newWidth
        });

        this.props.onResize(newWidth);
    }

    render() {
        return (
            <div
                className={style.sidebar}
                style={{
                    width: `${this.state.width}px`
                }}
                onClick={() => this.collapsed && this.handleToggleCollapse(false)}
            >
                <div className={style.header}>
                    <div className={style.title}>{this.props.title}</div>

                    <div
                        className={`
                            ${style.toggle}
                            icon-arrow-left
                        `}
                        onClick={(ev) => {ev.stopPropagation(); this.handleToggleCollapse(true)}}
                    />
                </div>

                <SidebarList
                    items={this.props.items}
                    itemSelected={this.props.items[0].id}
                    onItemSelected={this.props.onItemSelected}
                />

                <div className={style.footer}/>

                <div
                    className={style.draggableBorder}
                    onMouseDown={this.startDragging.bind(this)}
                    onMouseUp={this.stopDragging.bind(this)}
                />
            </div>
        );
    }
}


export class Section extends React.Component {
    constructor(props) {
        super(props);

        this.sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})[`${this.props.id}-sidebar`] || DEFAULT_SIDEBAR_MIN_WIDTH;

        this.state = {
            contentLeft: this.sidebarWidth,
            collapsed: false
        };
    }

    handleSidebarResize(newWidth) {
        this.setState({
            contentLeft: newWidth
        });
    }

    handleStartDragging() {
        this.setState({
            draggingSidebar: true
        });
    }

    handleStopDragging() {
        this.setState({
            draggingSidebar: false
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.section}
                    ${this.state.draggingSidebar ? style.draggingSidebar : ''}
                    ${this.state.collapsed ? style.collapsed : ''}
                `}
            >
                <Sidebar
                    id={this.props.id}
                    width={this.sidebarWidth}
                    title={this.props.title}
                    items={this.props.items}
                    onStartDragging={this.handleStartDragging.bind(this)}
                    onStopDragging={this.handleStopDragging.bind(this)}
                    onResize={this.handleSidebarResize.bind(this)}
                    onToggleCollapse={(collapsed) => this.setState({ collapsed: collapsed })}
                    onItemSelected={this.props.onItemSelected}
                />

                <div
                    className={style.content}
                    style={{
                        left: this.state.collapsed ? `${SIDEBAR_COLLAPSED_WIDTH}px` : `${this.state.contentLeft}px`
                    }}
                >
                    {this.props.content}
                </div>
            </div>
        );
    }
}
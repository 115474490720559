import React from 'react'
import Marionette from 'Backbone.Marionette'

import vent from 'js/vent'
import DealsCalendarComponent from 'js/react_views/deals-calendar/deals-calendar'

import style from './deals_calendar.css'

export default class DealsCalendar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={style.dealsCalendar}>
                <DealsCalendarComponent
                />
            </div>
        );
    }
}
import _ from 'underscore'
import $ from 'jquery'
import d3 from 'd3'
import topojson from 'topojson'

import countryName from 'js/utils/countryName'


function filterByMyDataId(id) {
    return function() {
        var d = d3.select(this).property('mydata');

        if (typeof(d) !== "undefined" && d !== null) {
            if ( 'id' in d ) {
                return d.id === id;
            }
        }
        return false;
    };
}

function worldchart() {
    var id = 'worldchart',
        x = 0,
        y = 0,
        width = 980,
        height = 705,
        margin = {left:0, right:0, top:0, bottom:0},
        color = d3.rgb(33,212,248),
        data = {};

    function chart(selection) {
        // Init if needed
        var svg = selection.selectAll('svg').filter(filterByMyDataId(id)),
            innerRect = {
                x: x+margin.left,
                y: y+margin.top,
                w: width - margin.left - margin.right,
                h: height - margin.top - margin.bottom
            },
            factor = 1,
            offset = [factor*width/2, factor*height/2],
            projection = d3.geo.mercator()
                .scale(factor*width)
                .translate(offset),
            graticule = d3.geo.graticule(),
            path = d3.geo.path().projection(projection),
            init,
            update;

        init = function(callback) {
            if (svg.empty()) {
                // Initialize
                svg = selection.append('svg');

                svg.property('mydata', {id:id});

                svg.append('path')
                    .datum(graticule)
                    .attr('class', 'graticule')
                    .attr('d', path);

                d3.json('/assets/world-110m.json', function(error, world) {
                    var countries = topojson.feature(world, world.objects.countries).features;

                    svg.selectAll('.country')
                        .data(countries, function(d) {
                            return d.id;
                        })
                        .enter().insert('path', '.graticule')
                            .attr('class', 'country')
                            .attr('d', path)
                            .on('click', function(d) {
                                $(this).trigger('countryfilter', {
                                    code: d.id,
                                    name: countryName(d.id)
                                });
                            });

                    callback();
                });
            }
        };

        update = function() {
            svg.attr('x', innerRect.x)
                .attr('y', innerRect.y)
                .attr('width', innerRect.w)
                .attr('height', innerRect.h);

            d3.json('/assets/world-110m.json', function(error, world) {
                var countries = topojson.feature(world, world.objects.countries).features,
                    countriesSelection, maxValue;

                maxValue = 0;
                _.each(countries, function(c) {
                    var value = 0;

                    if (_.has(data, c.id)) {
                        value = data[c.id];
                    } else {
                        value = 0;
                    }

                    c.value = value;
                    maxValue = Math.max(maxValue, value);
                });

                countriesSelection = svg
                    .selectAll('path.country')
                    .data(countries, function(d) {
                        return d.id;
                    });

                countriesSelection
                    .style('fill', function(d) {
                        if (d.value) {
                            return color.toString();
                        } else {
                            return null;
                        }
                    })
                    .style('opacity', function(d) {
                        if (d.value) {
                            return 0.2 + d.value / maxValue * 0.8;
                        } else {
                            return null;
                        }
                    });
            });
        };

        // Call init, and when it finishes, call update.
        // It is needed to use CPS because in this specific case init is async (does a request for the world json)
        // and it is needed for init to fully finish so that update works fine, hence this CPS calling.
        // However, what is really needed is to move the loading of the json away, and do not reload it once it
        // has been loaded. Maybe the best is that this loading to be done externally to this chart, and the world
        // json would be just configuration data for this chart as the rest of config params.
        init(update);
    }

    chart.id = function(_) {
        if (!arguments.length) { return id; }
        id = _;
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        width = _;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;
        return chart;
    };

    chart.color = function(_) {
        if (!arguments.length) { return color; }
        color = _;
        return chart;
    };

    return chart;
}

export default worldchart;

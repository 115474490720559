import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import TextManager from 'app/text-manager'
import CampaignListView from 'js/views/campaign_list'
import LoadingView from 'js/views/loading'
import PhasesCollection from 'js/collections/phases'
import D3CampaignContributionChart from 'js/d3/campaigncontributionchart'
import campaignContributionTemplate from 'templates/campaign_contribution.handlebars'


export default Marionette.Layout.extend({
    className: 'campaign-contribution-widget widget at-top',
    template: Handlebars.compile(campaignContributionTemplate),
    ui: {
        timespan: '.timespan',
        xAxisMetric: '.xaxis-select',
        switcherRevenuePhase: '.revenue-phase.switch',
        chartContainer: '.chart-container',
        campaignListBg: '.campaign-list-bg',
        header: '.widget-header',
        footer: '.widget-footer',
        scroll: '.content-container > .content'
    },
    regions: {
        campaignListRegion: '.campaign-list-wrapper',
        loaderRegion: '.loader-container'
    },
    events: {
        'change .xaxis-select': function() {
            var sel = this.ui.xAxisMetric.select2('val');
            this.inRevenueMode = (sel === 'revenue');
            this.fetchGroupsData();
        },

        'click .timespan a': function(ev) {
            ev.preventDefault();
            this.ui.timespan.find('.active').not(ev.currentTarget).removeClass('active');
            $(ev.currentTarget).parent('li').addClass('active');
        },

        'click .timespan .month': function() {
            this.timeSpan = 'month';
            this.fetchGroupsData();
        },

        'click .timespan .quarter': function() {
            this.timeSpan = 'quarter';
            this.fetchGroupsData();
        },

        'click .timespan .year': function() {
            this.timeSpan = 'year';
            this.fetchGroupsData();
        },

        'group_selected': function(w, g) {
            this.trigger('group_selected', this, g);
        },

        'click .widget-header .expand': 'toggleExpand',

        'click .widget-header .back': 'toggleExpand',

        'resize': 'scrollbar'
    },

    initialize: function(option) {
        this.chart = new D3CampaignContributionChart();
        this.timeSpan = 'month';
        this.groups = option.groups;
        this.allGroups = option.allGroups;
        this.inRevenueMode = true;
    },

    onRender: function() {
        this.showLoader();

        var view = this;

        // phases
        this.phases = new PhasesCollection();
        this.phases.fetch({
            success: function() {
                view.initPhasesSelectBox(view.phases);
                view.fetchGroupsData();
            }
        });

        this.ui.scroll
            .scroll(this.scrollEvents.bind(this))
            .scrollLock(); // Prevent scroll propagation

        // if (this.expanded) {
        //     this.showCampaignList();
        // }

        var thisView = this;

        // Resize revenue planner on window resize
        this.resizeCallback = function () {
            _.defer(function() {
                thisView.renderWidget();
            }.bind(thisView));
        };

        $(window).on('resize', this.resizeCallback);
    },

    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },

    renderWidget: function() {
        if (this.isClosed || !this.data) {
            return;
        }

        this.chart.expand(this.expanded);
        this.chart.width(this.$el.width());
        this.chart.height(this.$el.height());

        this.chart.data(this.data);

        d3.select(this.ui.chartContainer.get(0)).call(this.chart);

        this.scrollbar();
    },

    onGroupsDataChanged: function(newGroups) {
        this.chart.empty();
        this.groups = newGroups;
        this.fetchGroupsData();
    },

    onGroupHighlighted: function(group) {
        if (this.data) {
            this.chart.highlightPath(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    onGroupSelected: function(group) {
        if (this.data) {
            this.chart.selectPath(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    fetchGroupsData: function() {
        if (!this.groups) {
            return;
        }

        var view = this;
        var data = {
            groups: this.groups,
            time_span: this.timeSpan,
            type: this.inRevenueMode ? 'revenue' : 'reaching_phase'
        };

        if (!this.inRevenueMode) {
            data.reaching_phase_id = this.ui.xAxisMetric.select2('val');
        }

        $.post(
            '/widgets/campaign_contribution',
            JSON.stringify(data),
            function(d) {
                view.hideLoader();
                view.onGroupSelected(null);
                view.data = d;
                view.renderWidget();
            }
        );
    },

    initPhasesSelectBox: function(phases) {
        var default_currency = app.user.get('client').default_currency;
        var fields = [
            {
                name: 'Revenue (' + default_currency + ')',
                description: TextManager.parseText('Show the total revenue generated by each ${ID_CAMPAIGN}'),
                children: [
                    {
                        id: 'revenue',
                        name: 'Revenue'
                    }
                ]
            },
            {
                name: TextManager.getText('ID_NUMBER_OF_DEALS'),
                description: TextManager.getText('ID_NUMBER_OF_DEALS_DESCRIPTION'),
                children: phases.getAsHierarchy()
            }
        ];

        function formatResult(item) {
            return Handlebars.compile(
                item.name +
                '<div class="description">' + (item.description || '') + '</div>'
            );
        }

        // ...
        this.xAxisMetricSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.xAxisMetric,
            text: 'name',
            data: fields,
            value: fields[0].children[0],
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'xaxis-select-popover popover has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },

    toggleExpand: function() {

        // Prevent double expanding - breaks chain of animation
        if (this.expanding) {
            return;
        }

        if (!this.expanded) {
            this.trigger('group_selected', this, null);
        }

        this.expanding = true;
        this.ui.timespan.toggleClass('intercom-margin', !this.expanded);

        // // Once all parent containers have animated
        function afterParentExpand() {
            this.expanding = false;
            this.expanded = !this.expanded;
            this.renderWidget();
            // this.ui.campaignListBg
                // .toggleClass('visible', this.expanded)
                // .fadeToggle(300);
            // this.showCampaignList();
        }

        this.trigger('expand', this, afterParentExpand.bind(this));
    },

    scrollbar: _.debounce(function() {

        // Disabled until we need the scrollbar - i.e. when we have separating streams
        if (this.isClosed || true) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            footerHeight = this.ui.footer.is(':visible') ? this.ui.footer.height() : 0,
            availableHeight = (this.$el.height() - this.ui.header.height() - footerHeight),
            innerHeight = container.find('.content-inner:first').height();

        // container.css('maxHeight', height);
        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),

    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    },

    showLoader: function() {
        this.loaderRegion.show( new LoadingView() );
    },

    hideLoader: function() {
        this.loaderRegion.reset();
    }
});

import $ from 'jquery'
import _ from 'underscore'

import Utilities from 'js/utils/utilities'
import AppConfig from 'app/app-config'

var OPEN_TAG = '${';
var CLOSE_TAG = '}';
var TAG_PARAMETER_EQUAL = '=';
var TAG_PARAMETER_SEPARATOR = ',';

var TextManager = {
    init: function() {
        var host = window.location.hostname;
        var ppos = host.indexOf('.');

        this.clientId = host.substring(0, ppos);
        this.environment = host.substring(ppos + 1, host.indexOf('.', ppos + 1));
        this.host = host.substring(ppos + 1);

        // ...
        var self = this;

        this.texts = {
            ID_SUPPLIER: {
                getText: function(params) {
                    var value = (self.environment === 'intentcrm') ? 'Motivator' : 'SalesSeek';
                    return self.getCustomizedText(value, params);
                }
            },
            ID_HOST: {
                getText: function(params) {
                    return self.getCustomizedText(self.host, params);
                }
            },
            ID_APPOINTMENT: 'appointment',
            ID_APPOINTMENT_RELATED_DEALS_TEXT: {
                getText: function(params) {
                    var value = 'deal';

                    if (AppConfig.getValue('is_pdcrm')) {
                        value = 'plot or reservation';
                    }
                    return self.getCustomizedText(value, params);
                }
            },
            ID_INDIVIDUAL: 'contact',
            ID_INDIVIDUAL_INDEFINITE_ARTICLE: 'a',
            ID_ORGANIZATION: {
                getText: function(params) {
                    var value = 'organization'

                    if (['persimmonhomes', 'persimmondemo'].indexOf(self.clientId) !== -1) {
                        value = 'company'
                    }

                    return self.getCustomizedText(value, params, this);
                },
                plural: function() {
                    var value = 'organizations';

                    if (['persimmonhomes', 'persimmondemo'].indexOf(self.clientId) !== -1) {
                        value = 'companies'
                    }

                    return value;
                }
            },
            ID_ORGANIZATION_INDEFINITE_ARTICLE: {
                getText: function(params) {
                    var value = 'an'

                    if (['persimmonhomes', 'persimmondemo'].indexOf(self.clientId) !== -1) {
                        value = 'a'
                    }

                    return self.getCustomizedText(value, params);
                }
            },
            ID_DEAL: {
                getText: function(params) {
                    var value = '';
                    if (self.environment === 'intentcrm' && self.clientId !== 'fairwaymc') {
                        value = 'loan';
                    } else {
                        value = 'deal';
                    }
                    return self.getCustomizedText(value, params);
                }
            },
            ID_FUNNEL: {
                getText: function(params) {
                    var value = 'funnel';

                    if (AppConfig.getValue('is_pdcrm')) {
                        value = 'development';
                    }
                    return self.getCustomizedText(value, params);
                }
            },
            ID_CLUSTER: {
                getText: function(params) {
                    // customize for other clients if necessary
                    if (self.clientId.indexOf('persimmon') !== -1) {
                        return self.getCustomizedText('parent development', params, this);
                    }

                    return self.getCustomizedText('cluster', params);
                },
                plural: function() {
                    if (self.clientId.indexOf('persimmon') !== -1) {
                        return 'parent developments';
                    }

                    return 'clusters';
                }
            },
            ID_PHASE: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo', 'londonsquare', 'lsqdemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('status', params, this);
                    }

                    if (['persimmonhomes', 'persimmondemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('status', params, this);
                    }

                    return self.getCustomizedText('phase', params, this);
                },
                plural: function() {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo', 'londonsquare', 'lsqdemo'].indexOf(self.clientId) !== -1) {
                        return 'statuses';
                    }

                    return 'phases';
                }
            },
            ID_STATUS: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('forecasting status', params);
                    }
                    return self.getCustomizedText('status', params);
                }
            },
            ID_DEAL_CLOSE_DATE: {
                getText: function(params) {
                    if (self.environment === 'salesseek') {
                        switch (self.clientId) {
                            case 'hillgroup':
                            case 'hillplay':
                            case 'hilldemo':
                                return self.getCustomizedText('client completion', params);

                            case 'londonsquare':
                            case 'lsqdemo':
                                return self.getCustomizedText('build', params);

                            case 'connells':
                            case 'connellsdemo':
                                return self.getCustomizedText('instructed', params);
                        }
                    }

                    return self.getCustomizedText('close', params);
                }
            },
            ID_DEAL_DEALS_INDIVIDUALS: {
                getText: function(params) {
                    if (AppConfig.getValue('is_pdcrm')) {
                        return self.getCustomizedText('Buyers', params);
                    }

                    return self.parseText(self.texts['ID_ENTITY_OWNS_ENTITY'], ['${ID_DEAL, capitalize}', '${ID_INDIVIDUAL, capitalize, plural}']);
                }
            },
            ID_DEAL_ORGANIZATION_INDIVIDUALS: {
                getText: function(params) {
                    if (AppConfig.getValue('is_pdcrm')) {
                        return self.getCustomizedText('Stakeholders', params);
                    }

                    return self.parseText(self.texts['ID_ENTITY_OWNS_ENTITY'], ['${ID_ORGANIZATION, capitalize}', '${ID_INDIVIDUAL, capitalize, plural}']);
                }
            },
            ID_INDIVIDUAL_RELATED: 'related contact',
            ID_INDIVIDUAL_RELATED_INDIVIDUALS: {
                getText: function(params) {
                    return self.getCustomizedText('Related Contacts', params);
                }
            },
            ID_FORECAST: 'forecast',
            ID_SOURCE: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('Source of enquiry', params);
                    }

                    return self.getCustomizedText('Source', params);
                }
            },
            ID_EMAIL_OPTED_IN: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('By email', params);
                    }

                    return self.getCustomizedText('Email opted in', params);
                }
            },
            ID_TEXTING_OPTED_IN: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo'].indexOf(self.clientId) !== -1) {
                        return self.getCustomizedText('By SMS', params);
                    }

                    return self.getCustomizedText('Texting opted in', params);
                }
            },
            ID_CAMPAIGN: {
                getText: function(params) {
                    var value = (self.environment === 'intentcrm') ? 'broadcast' : 'campaign';
                    return self.getCustomizedText(value, params);
                }
            },
            ID_CAMPAIGN_CATEGORY: {
                getText: function(params) {
                    var value = ['persimmondemo', 'persimmonhomes'].indexOf(self.clientId) !== -1 ? 'brand' : 'category';
                    return self.getCustomizedText(value, params);
                }
            },
            ID_LOGIN_PAGE_TITLE: {
                getText: function(params) {
                    var value = 'SalesSeek';

                    if (self.environment === 'intentcrm') {
                        if (_.contains(['fairwaymc', 'fw855'], self.clientId)) {
                            value = 'Motivator Powered by Intent';
                        } else {
                            value = 'Intent';
                        }
                    }
                    return self.getCustomizedText(value, params);
                }
            },
            ID_DEALS_OVER_LEADS: '${ID_DEAL, plural, capitalize} / Leads',
            ID_TEAM_DEALS: 'Team ${ID_DEAL, plural, capitalize}',
            ID_MY_DEALS: 'My ${ID_DEAL, plural, capitalize}',
            ID_LOST_DEALS: 'Lost ${ID_DEAL, plural, capitalize}',
            ID_WON_DEALS: 'Won ${ID_DEAL, plural, capitalize}',
            ID_ALL_DEALS: 'All ${ID_DEAL, plural, capitalize}',
            ID_ACTIVE_DEALS: 'Active ${ID_DEAL, plural, capitalize}',
            ID_EDIT_DEAL_VALUE: 'Edit ${ID_DEAL, capitalize} Value',
            ID_SELECT_A_DEAL: 'Select a ${ID_DEAL}',
            ID_SEARCH_FOR_A_DEAL: 'Search for a ${ID_DEAL}',
            ID_ADD_DEALS: 'Add ${ID_DEAL, plural, capitalize}',
            ID_ADD_DEALS_TO_FORECAST: '${ID_ADD_DEALS} to Forecast',
            ID_DEAL_ID: '${ID_DEAL, capitalize} Id',
            ID_DEAL_ID_DESCRIPTION: 'Unique ${ID_DEAL, capitalize} - OPTIONAL',
            ID_DEAL_NAME: '${ID_DEAL, capitalize} Name',
            ID_DEAL_VALUE: '${ID_DEAL, capitalize} Value',
            ID_DEAL_BUCKET: '${ID_DEAL, capitalize} Bucket',
            ID_DEAL_ACTIVITY: '${ID_DEAL, capitalize} Activity',
            ID_DEAL_WEIGHT: '${ID_DEAL, capitalize} Weight',
            ID_DEAL_WRITE_COMMENTS: 'Write something about this ${ID_DEAL}',
            ID_NUMBER_OF_DEALS: 'Number of ${ID_DEAL, plural}',
            ID_NUMBER_OF_DEALS_DESCRIPTION: 'Show the volume of ${ID_DEAL, plural} that have reached or passed a ${ID_PHASE}:',
            ID_CLOSED_DEALS: 'Closed ${ID_DEAL, plural}',
            ID_CLOSED_DEALS_DESCRIPTION: 'Show the percentage of closed ${ID_DEAL, plural} that are ${:1}.',
            ID_DEALS_IN_FUNNELS: '${ID_DEAL, capitalize, plural} in funnels',
            ID_DEALS_IN_FUNNELS_DESCRIPTION: 'Show the percentage of ${ID_DEAL, plural} that have reached or passed a ${ID_PHASE} in a particular funnel:',
            ID_ONE_DEAL_BUCKET_NEEDED: 'At least one ${ID_DEAL} bucket is needed',
            ID_PHASE_WITH_OPEN_DEALS: 'This ${ID_PHASE} cannot be deleted as it has open ${ID_DEAL, plural}.',
            ID_ONLY_INCLUDE_DEALS_IN_SELECTED_PERIOD: 'Only include ${ID_DEAL, plural} in the selected period',
            ID_SAVE_TO_DEAL: 'Save to ${ID_DEAL, capitalize}',

            ID_SEARCH_FOR_AN_ORGANIZATION: 'Search for an ${ID_ORGANIZATION}',
            ID_SEARCH_FOR_AN_INDIVIDUAL: 'Search for an ${ID_INDIVIDUAL}',
            ID_SEARCH_FOR_A_USER: 'Search for a user',

            ID_DELETE_GROUP_WARNING_MESSAGE: 'Deleting a group will not remove the ${:1} from your ${ID_SUPPLIER} account. Please use ${:2} if you wish to remove ${:1}.',
            ID_DELETE_GROUP_WARNING_MESSAGE_NO_LINK: 'Deleting a group will not remove the ${:1} from your ${ID_SUPPLIER} account.',

            ID_DELETE_ORGANIZATION_WARNING_MESSAGE: 'Are you sure you want to <strong>permanently</strong> delete ${:1}? <div class="alert alert-danger"><i class="icon-warning"></i><p class="text">This will also delete all associated Individuals and ${ID_DEAL, plural, capitalize}.</p></div>',

            ID_ALL_INDIVIDUALS: 'All ${ID_INDIVIDUAL, plural, capitalize}',
            ID_MY_INDIVIDUALS: 'My ${ID_INDIVIDUAL, plural, capitalize}',
            ID_INDIVIDUAL_WRITE_COMMENTS: 'Write something about this ${ID_INDIVIDUAL}',
            ID_SELECT_AN_INDIVIDUAL: 'Select ${ID_INDIVIDUAL_INDEFINITE_ARTICLE} ${ID_INDIVIDUAL}',
            ID_SEARCH_FOR_AN_INDIVIDUAL: 'Search for ${ID_INDIVIDUAL_INDEFINITE_ARTICLE} ${ID_INDIVIDUAL}',
            ID_INDIVIDUAL_WEB_ACTIVITY: '${ID_INDIVIDUAL, capitalize} Web Activity',
            ID_SEND_TO_THIS_INDIVIDUAL: 'Send to this ${ID_INDIVIDUAL}',
            ID_PREVIEW_INDIVIDUAL_EMAIL: 'Preview ${ID_INDIVIDUAL} emails by selecting below',
            ID_PREVIEW_INDIVIDUAL_TEXT_MESSAGE: 'Preview ${ID_INDIVIDUAL} messages by selecting below',
            ID_CHOOSE_INDIVIDUAL_OR_GROUP_OF_INDIVIDUALS: 'Choose a single ${ID_INDIVIDUAL, capitalize} or a group of ${ID_INDIVIDUAL, plural, capitalize}',
            ID_CREATE_A_GROUP_OF_INDIVIDUALS: 'Please create a Group of ${ID_INDIVIDUALS, plural, capitalize} in <a href="/#contacts/group/individuals/new">Contacts</a> first',
            ID_CHOOSE_CAMPAIGN_TYPE: 'Choose ${ID_CAMPAIGN, capitalize} Type',
            ID_SELECT_MAILING_LIST_TO_SEND_CAMPAIGN: 'Please select the Mailing List to send ${ID_CAMPAIGN} to',

            ID_SELECT_A_CAMPAIGN: 'Select a ${ID_CAMPAIGN}',
            ID_CAMPAIGN_GROUP: '${ID_CAMPAIGN, capitalize} group',
            ID_SEND_TO_CAMPAIGN_GROUP: 'Send to everyone in the ${ID_CAMPAIGN} group',
            ID_SEND_A_CAMPAIGN: 'Send a ${ID_CAMPAIGN}',

            ID_ALL_ORGANIZATIONS: 'All ${ID_ORGANIZATION, plural, capitalize}',
            ID_MY_ORGANIZATIONS: 'My ${ID_ORGANIZATION, plural, capitalize}',
            ID_ORGANIZATION_WRITE_COMMENTS: 'Write something about this ${ID_ORGANIZATION}',
            ID_SELECT_AN_ORGANIZATION: 'Select ${ID_ORGANIZATION_INDEFINITE_ARTICLE} ${ID_ORGANIZATION}',
            ID_SEARCH_FOR_AN_ORGANIZATION: 'Search for ${ID_ORGANIZATION_INDEFINITE_ARTICLE} ${ID_ORGANIZATION}',
            ID_DELETE_ORGANIZATION_WARNING_MESSAGE: 'Are you sure you want to <strong>permanently</strong> delete ${:1}? <div class="alert alert-danger"><i class="icon-warning"></i><p class="text">This will also delete all associated ${ID_INDIVIDUAL, plural, capitalize} and ${ID_DEAL, plural, capitalize}.</p></div>',
            ID_APP_SETTINGS_IMPORT_ORGANIZATIONS_UPDATING_POLICY: 'Existing ${ID_ORGANIZATION, plural} are determined by Name',

            ID_SELECT_A_PHASE: 'Select a ${ID_PHASE}',

            ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_1: 'Cannot add ${:1} to Forecast',
            ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_2: {
                getText: function(params) {
                    var pluralize = ('pluralize' in params) && (parseInt(params.pluralize) !== 1);
                    return pluralize ? 'The following deals currencies have no conversion rate:' : "The following deal's currency have no conversion rate:";
                }
            },
            ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_3: {
                getText: function(params) {
                    var pluralize = ('pluralize' in params) && (parseInt(params.pluralize) !== 1);
                    return pluralize ? 'Please ask your administrator to add conversion rates for these currencies.' : 'Please ask your administrator to add a conversion rates for this currency.';
                }
            },
            ID_FORECAST_DEAL_INVALID_CURRENCY_LINE_3_ADMIN: {
                getText: function(params) {
                    var pluralize = ('pluralize' in params) && (parseInt(params.pluralize) !== 1);
                    return pluralize ? 'Please add conversion rates for these currencies in App Settings.' : 'Please add a conversion rate for this currency in App Settings.';
                }
            },
            ID_FORECAST_STATUS_COMMITTED: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('hot', params);
                    }

                    return self.getCustomizedText('committed', params);
                }
            },
            ID_FORECAST_STATUS_COMMITTED_DOWNSIDE: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('warm', params);
                    }

                    return self.getCustomizedText('committed downside', params);
                }
            },
            ID_FORECAST_STATUS_NONE_UPSIDE: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('lukewarm', params);
                    }

                    return self.getCustomizedText('none upside', params);
                }
            },
            ID_FORECAST_STATUS_NONE: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('cold', params);
                    }

                    return self.getCustomizedText('none', params);
                }
            },
            ID_FORECAST_STATUS_COMMITTED_DOWNSIDE_SHORT: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('warm', params);
                    }

                    return self.getCustomizedText('downside', params);
                }
            },
            ID_FORECAST_STATUS_NONE_UPSIDE_SHORT: {
                getText: function(params) {
                    if (self.environment === 'salesseek' && self.clientId === 'twc') {
                        return self.getCustomizedText('lukewarm', params);
                    }

                    return self.getCustomizedText('upside', params);
                }
            },

            ID_FUNNEL_COLOR_BY_DEAL_ACTIVITY: 'Color by time since last activity on ${ID_DEAL}.',
            ID_FUNNEL_COLOR_BY_FORECAST_STATUS: 'Color by ${ID_DEAL} forecast status.',
            ID_FUNNEL_WITH_DEALS: 'This funnel cannot be deleted as it has ${ID_DEAL, plural} in it.',
            ID_REGION_WITH_FUNNELS: 'This region cannot be deleted as it has ${ID_FUNNEL, plural} in it.',

            ID_APP_SETTINGS_REVENUE_BUCKETS_DESCRIPTION: 'Configure revenue buckets for ${ID_DEAL, plural} and forecasts',
            ID_APP_SETTINGS_CURRENCY_DESCRIPTION: 'Set the default currency for all ${ID_DEAL, plural} and forecasts',
            ID_APP_SETTINGS_DATA_IMPORT_DESCRIPTION: 'Import your contacts and ${ID_DEAL, plural} data',
            ID_APP_SETTINGS_IMPORT_ENTITY: 'Import ${:1}',
            ID_APP_SETTINGS_IMPORT_ENTITY_DESCRIPTION: 'Import your ${:1} data',
            ID_APP_SETTINGS_CUSTOM_FIELDS_DESCRIPTION: 'Custom fields for ${:1}',
            ID_APP_SETTINGS_CHECKLISTS_DESCRIPTION: 'Checklists for ${:1}',
            ID_APP_SETTINGS_IMPORT_DEALS_UPDATING_POLICY: 'Existing ${ID_DEAL, plural} are determined by Name and Organization',
            ID_APP_SETTINGS_IMPORT_INDIVIDUALS_UPDATING_POLICY: 'Existing ${ID_INDIVIDUAL, plural} are determined by email',
            ID_APP_SETTINGS_SET_DEFAULT_CURRENCY_FOR_DEALS: 'Set the default currency for all ${ID_DEAL, plural} and forecasts (new and old):',
            ID_APP_SETTINGS_SET_REPORTING_PERIODS: 'Set the reporting period length to either monthly or quarterly - this affects ${ID_DEAL, capitalize, plural}, Forecasts, and Sales Targets.',

            ID_KPI_AVERAGE_COST_PER_WON_DEAL: 'Average cost per won ${ID_DEAL}',
            ID_KPI_WON_PERCENTAGE_DEALS: 'Won percentage of ${ID_DEAL, plural} closed in the selected time period',

            ID_UPGRADE_ACCOUNT_TO_ACCESS_CAMPAIGNS: 'Upgrade account to access ${ID_CAMPAIGN, plural, capitalize}',

            ID_ENTITY_ADD_TO: 'Add to ${:1}',
            ID_ENTITY_ADD_SELECTED_TO: 'Add selected to ${:1}',
            ID_ENTITY_ADDED_TO_ENTITY: '${:1} successfully added to ${:2}',
            ID_ENTITY_REMOVED_FROM_ENTITY: '${:1} successfully removed from ${:2}',
            ID_ENTITY_OWNS_ENTITY: "${:1}'s ${:2}",
            ID_ENTITY_REMOVE_FROM: 'Remove from ${:1}',
            ID_CREATE_ENTITY: 'Create ${:1}',
            ID_UPDATE_ENTITY: 'Update ${:1}',
            ID_NEW_ENTITY: 'New ${:1}',
            ID_ENTITY_CUSTOM: '${:1} Custom',
            ID_ENTITY_LOCATION: '${:1} Location',
            ID_ENTITY_COMMUNICATION: '${:1} Communication',
            ID_ENTITY_ACTIVITY: '${:1} Activity',
            ID_ENTITY_PINNED_ACTIVITY: '${:1} Starred Activity',
            ID_ENTITY_IS_REQUIRED: '${:1} is required',
            ID_ENTITY_CHECKLIST: '${:1} checklist',

            ID_NO_TASKS_MESSAGE: 'Create a new task here or in the tasks panel of ${ID_INDIVIDUAL_INDEFINITE_ARTICLE} ${ID_INDIVIDUAL}, ${ID_ORGANIZATION} or ${ID_DEAL}.',
            ID_JOB_ROLE: {
                getText: function(params) {
                    var value = (self.environment === 'intentcrm') ? 'Job Title' : 'Job Role';
                    return self.getCustomizedText(value, params);
                }
            },
            ID_MORE_INFO: {
                getText: function(params) {
                    var value = (self.environment === 'intentcrm') ? 'Notes' : 'More Info';

                    if (self.environment === 'salesseek' && ['hillgroup', 'hillplay', 'hilldemo'].indexOf(self.clientId) !== -1) {
                        value = 'Key facts';
                    }

                    return self.getCustomizedText(value, params);
                }
            },
            ID_RELATED_FIELD_REQUIRED_ON_NEW_TASK: 'Please select a related entity.',
            ID_SELECT_INDIVIDUAL_OR_DEAL: 'Select ${ID_INDIVIDUAL_INDEFINITE_ARTICLE} ${ID_INDIVIDUAL, capitalize} or ${ID_DEAL, capitalize}'
        }
    },
    getText: function(id, params) {
        // has been initialized?
        if (!this.clientId) {
            this.init();
        }

        if (!(id in this.texts)) {
            return ('Undefined tag Id: ' + id);
        }

        var text = this.texts[id];

        if (_.isObject(text)) {
            text = text.getText ? text.getText() : '';
        }

        return this.parseText(text, params);
    },
    getPluralizedText: function(id, value) {
        return this.parseText('${' + id + ', pluralize=' + value + '}');
    },
    parseText: function(text, params) {
        params = params || [];

        // has been initialized?
        if (!this.clientId) {
            this.init();
        }

        var tags = this.getTags(text);

        if (tags.length === 0) {
            return text;
        }

        for (var i = 0; i < tags.length; ++i) {
            var tag = tags[i];
            var tagText = '';

            if (tag.id[0] === ':') { // its a possitional argument
                var idx = parseInt(tag.id.slice(1)) - 1;

                if (idx < 0 || idx >= params.length) {
                    return 'Invalid parameter position: ' + idx;
                }

                var paramValue = params[idx];
                var paramTag = this.getTags(paramValue);

                if (paramTag.length === 1) {  // it's a tag
                    tagText = this.getTagText(paramTag[0]);
                } else {  // it's a direct value
                    tagText = paramValue;
                }
            } else { // it's a tag
                tagText = this.getTagText(tag);
            }


            text = Utilities.replaceAll(text, tag.content, tagText);
        }

        return text;
    },
    getTagText: function(tag) {
        if (!(tag.id in this.texts)) {
            return 'Undefined tag Id';
        }

        var text = this.texts[tag.id];

        if (_.isObject(text)) {
            if (!text.getText) {
                return ('tag without getText method: ' + tag.id);
            }

            return text.getText(tag.params);
        }

        return this.getCustomizedText(this.parseText(text), tag.params);
    },
    getTags: function(text) {
        var tags = [];
        var initPos = 0;
        var endPos = 0;
        var tagsProcessed = [];

        while (true) {
            initPos = text.indexOf(OPEN_TAG, endPos);

            if (initPos === -1) {
                break;
            }

            endPos = text.indexOf(CLOSE_TAG, initPos);

            if (endPos === -1) {
                break;
            }

            // ...
            var tagContent = text.substring(initPos, endPos + CLOSE_TAG.length);

            if (_.contains(tagsProcessed, tagContent)) {
                continue;
            }

            tags.push(this.parseTag(tagContent));
            tagsProcessed.push(tagContent);
        }

        return tags;
    },
    parseTag: function(tagContent) {
        var tagValue = tagContent.substring(OPEN_TAG.length, tagContent.length - CLOSE_TAG.length);
        var rawParams = tagValue.split(TAG_PARAMETER_SEPARATOR);
        var tagId = rawParams[0].trim();

        return {
            id: tagId,
            content: tagContent,
            params: this.parseTagParameters(rawParams.slice(1))
        };
    },
    parseTagParameters: function(params) {
        var tagParams = {};

        for (var i = 0; i < params.length; ++i) {
            var param = params[i];
            var paramParts = param.split(TAG_PARAMETER_EQUAL);

            if (paramParts.length === 1) {
                tagParams[param.trim()] = true;
            } else {
                tagParams[paramParts[0].trim()] = paramParts[1].trim();
            }
        }

        return tagParams;
    },
    getCustomizedText: function(text, params, object) {
        params = params || {}

        if (_.isEmpty(params)) {
            return text;
        }

        // the parameters are managed in an specific order
        if (params.plural) {
            if (object && object.plural) {
                text = object.plural()
            } else {
                text = text + 's';
            }
        }

        if ('pluralize' in params) {
            if (parseInt(params.pluralize) !== 1) {
                if (object && object.plural) {
                    text = object.plural()
                } else {
                    text = text + 's';
                }
            }
        }

        if (params.capitalize) {
            text = text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        }

        if (params.uppercase) {
            text = text.toUpperCase();
        }

        return text;
    }
};

export default TextManager;

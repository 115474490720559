import $ from 'jquery';
import React from 'react';
import Backbone from 'backbone'

import app from 'js/app';
import vent from 'js/vent';
import ForecastsCollection from 'js/collections/forecasts';
import FinderPanel from 'app_v2/sections/panels/finder';
import AdvancedFilterPanel from 'app_v2/sections/panels/nadvanced_filter';
import ConfigureColumnsPanel from 'app_v2/sections/panels/nconfigure_columns';
import SaveEditGroupPanel from 'app_v2/sections/panels/nsave_edit_group';
import DealEntityPanel from 'app_v2/sections/panels/deal_entity';
import GroupTableView from './views/group_table';
import GroupTableFunnelView from './views/group_table_funnel';
import GroupDemographicMapView from './views/group_demographic_map';
import GroupCalendarView from './views/group_calendar';
import Toolbar from 'app_v2/sections/base/components/ntoolbar';
import ActionsBar from './components/actions_bar';
import FiltersBar from './components/nfilters_bar';
import FilterManager from 'app_v2/sections/base/nfilter_manager';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import {TextField, TagsField} from 'js/react_views/common_components/common';

import style from './section.css';


const VIEW_COMPONENT_BY_VIEW_TYPE = {
    groupTable: GroupTableView,
    groupTableFunnel: GroupTableFunnelView,
    groupDemographicMap: GroupDemographicMapView,
    groupCalendar: GroupCalendarView
};


class RenameGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nameError: ''
        };
    }

    handleSave() {
        const name = this.nameComponent.getValue();

        if (!name) {
            this.setState({
                nameError: 'Name is required'
            });

            return;
        }

        this.setState({
            nameError: ''
        });

        // TODO: to implement
    }

    render() {
        return (
            <div className={style.renameGroupBackdrop}>
                <div className={style.gDialog}>
                    <div className={style.dHeader}>
                        <div
                            className={style.hClose}
                            onClick={this.props.onClose}
                        >
                            Close
                        </div>
                        <div className={style.hTitle}>{this.props.title}</div>
                    </div>

                    <div style={{
                        padding: '20px 40px'
                    }}>
                        <TextField
                            ref={(el) => this.nameComponent = el}
                            label='Name'
                            value={this.props.name || ''}
                            fullInputBox={true}
                            error={this.state.nameError}
                        />

                        <TagsField
                            ref={(el) => this.tagsComponent = el}
                            label='Tags'
                            placeholder='+ Add Tag'
                            fullInputBox={true}
                            dropdownWidth={269}
                        />
                    </div>

                    <div className={style.dFooter}>
                        <div
                            className={style.dButton}
                            onClick={this.handleSave.bind(this)}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeViewId: props.group?.section?.activeView || null,
            filtersBarFilters: null,
            sharedSearchTerm: null,
            finderCollapsed: false,
            barsWidth: '100%',
            renameGroupPopup: null
        };

        if (props.sharedFilters) {
            this.sharedFilterManager = new FilterManager(props.entityType, props.group?.filter);
        }
    }

    componentDidMount() {
        if (this.state.activeViewId) {
            const activeComponent = this.viewsComponents[this.state.activeViewId];

            if (activeComponent?.show) {
                activeComponent.show();
            }
        }
    }

    setFinderCollapsed(collased) {
        this.setState({
            finderCollapsed: collased
        });

        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.onStartResizing) {
            activeComponent.onStartResizing();

            _.delay(function() {
                activeComponent.onEndResizing();
            }, 400);
        }
    }

    setBarsWidth(width) {
        if (width === this.state.barsWidth) {
            return;
        }

        this.setState({
            barsWidth: width
        });
    }

    handleToolbarViewChange(viewId) {
        const prevViewId = viewId;

        if (viewId === this.state.activeViewId) {
            return;
        }

        if (this.viewsComponents[this.state.activeViewId]?.hide()) {
            this.viewsComponents[this.state.activeViewId].hide();
        }

        this.setState({
            activeViewId: viewId
        });

        if (this.viewsComponents[viewId].show) {
            const self = this;

            _.defer(function() {
                self.viewsComponents[viewId]?.show();

                if (self.props.onToolbarViewChanged) {
                    self.props.onToolbarViewChanged(viewId, prevViewId);
                }
            });
        }
    }

    handleRowSelectionChange(rowSelection, allRowsSelected) {
        if (this.actionsBar) {
            this.actionsBar.onRowSelectionChange(rowSelection, allRowsSelected);
        }
    }

    handleActionsBarSelectAll() {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.selectAll) {
            activeComponent.selectAll();
        }
    }

    handleActionsBarNewEntity() {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.showPanel) {
            activeComponent.showPanel('entity', null);
        }
    }

    handleActionsBarAddEntity(entity) {
        if (!entity) {
            return;
        }

        if (this.props.onEntityAdded) {
            this.props.onEntityAdded(entity);
            return;
        }

        const self = this;

        $.ajax({
            url: `/${this.props.group.id}/items/${entity.id}`,
            method: 'PUT',
            success: function() {
                self.setDataDirty();
            }
        });
    }

    handleFilterRuleDelete(filter) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.deleteFilterRule) {
            activeComponent.deleteFilterRule(filter);
        }
    }

    handleAllFiltersRulesDeleted() {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.deleteAllFiltersRules) {
            activeComponent.deleteAllFiltersRules();
        }
    }

    handleCalendarDateChange(date) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent.setCalendarDate) {
            activeComponent.setCalendarDate(date);
        }
    }

    handleCalendarSpanChange(span) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent.setCalendarSpan) {
            activeComponent.setCalendarSpan(span);
        }
    }

    handleCalendarDateSpanChange(date, span) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent.setCalendarDateSpan) {
            activeComponent.setCalendarDateSpan(date, span);
        }
    }

    handleToolbarSearch(term) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.searchBy) {
            activeComponent.searchBy(term);
        }

        if (this.props.sharedSearch) {
            this.setState({
                sharedSearchTerm: term
            });
        }
    }

    handleSelectedColumnsChange(selectedColumns) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.setColumns) {
            activeComponent.setColumns(selectedColumns);
        }
    }

    handleToolbarShowPanel(panelId, showArgs) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.showPanel) {
            activeComponent.showPanel(panelId, showArgs);
        }
    }

    handleAdvancedFilterRulesChange(rules) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.setFilterRules) {
            activeComponent.setFilterRules(rules);
        }
    }

    handleFinderNewGroup(args) {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.showPanel) {
            activeComponent.showPanel('group', args);
        }
    }

    saveCurrentGroupAsCsv() {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.saveCurrentGroupAsCsv) {
            activeComponent.saveCurrentGroupAsCsv();
        }
    }

    saveCurrentGroupAsForecast() {
        const activeComponent = this.viewsComponents[this.state.activeViewId];

        if (activeComponent?.saveCurrentGroupAsForecast) {
            activeComponent.saveCurrentGroupAsForecast();
        }
    }

    showConfigureColumnsPanel(allColumns, selectedColumns) {
        this.configureColumnsPanel.show(allColumns, selectedColumns);
    }

    showAdvancedFilterPanel(entityType, allFilters, filters) {
        this.advancedFilterPanel.show(entityType, allFilters, filters);
    }

    showEntityPanel(entityId) {
        if (this.props.entityType === 'appointments') {
            if (entityId) {
                vent.trigger('quick:edit:appointment', entityId);
            } else {
                vent.trigger('quick:add:appointment');
            }
        } else {
            this.entityPanel.show(entityId);
        }
    }

    showGroupPanel(entityType, showArgs) {
        this.groupPanel.show(entityType, showArgs);
    }

    saveAsCsv(filename, url) {
        const self = this;

        $.get(url, function(result) {
            const blob = new Blob([result], {type: 'text/csv'});
            const a = document.createElement('a');

            a.download = `${filename}.csv`;
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
        });
    }

    saveAsForecast(filename) {
        const forecasts = new ForecastsCollection();
        const self = this;

        forecasts.fetch({
            url: `/users/${app.user.get('id')}/forecasts`,
            data: {
                rows: -1
            },
            success: function() {
                self.setState({
                    renameGroupPopup: {
                        title: 'Save As Forecast',
                        name: forecasts.getUniqueName(filename || ''),
                        placeholder: 'Forecast name'
                    }
                });
            }
        });
    }

    getEntityIdOnPanel() {
        if (!this.entityPanel) {
            return null;
        }

        return this.entityPanel.getEntityId();
    }

    computeFiltersBarFilters(filterManager, columns, filters) {
        let filtersBarFilters = [];

        if (!this.props.group?.protected) {
            const rules = filterManager.getRules();

            if (rules) {
                let columnsAlreadyAdded = {};

                for (const r of _.flatten(rules)) {
                    const column = columns.find(c => {
                        if (c.isCustomField) {
                            return c.filterId === r.field && c.filterCustom === r.custom;
                        }

                        return c.filterId === r.field;
                    });

                    if (column && column.id in columnsAlreadyAdded) {
                        continue;
                    }

                    let title = '';
                    let colId = null;

                    if (column) {
                        title = column.title;
                        colId = column.id;
                    } else {
                        let filter = null;

                        for (const fg in filters) {
                            if (r.custom) { // it's a custom field
                                filter = filters[fg].find(f => f.id === r.field && f.custom === r.custom);
                            } else {
                                filter = filters[fg].find(f => f.id === r.field);
                            }

                            if (filter) {
                                break;
                            }
                        }

                        if (!filter) {
                            continue;
                        }

                        title = filter.title;
                    }

                    filtersBarFilters.push({
                        id: r.field,
                        custom: r.custom,
                        columnId: colId,
                        title: title
                    });

                    columnsAlreadyAdded[colId] = true;
                }
            }
        }

        this.setState({
            filtersBarFilters: filtersBarFilters.length === 0 ? null : filtersBarFilters
        });
    }

    changeDateSpan(date, span) {
        this.toolbar.setCalendarDateSpan(date, span);
    }

    updateGroup(group, fields) {
        if (group.adhoc || group.protected) {
            return;
        }

        fields = fields || {};
        let data = {};

        if (fields.columns) {
            data.columns = fields.columns.map(c => c.id);
        }

        if ((group.groupType === 'smart') && ('filterId' in fields)) {
            data.filter_id = fields.filterId;
        }

        if (fields.orderBy) {
            data.display_options = {
                sort: {
                    direction: fields.orderBy.sortDir === 'asc',
                    field: [fields.orderBy.columnId]
                }
            };
        }

        if (!_.isEmpty(data)) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'Saving group...',
                        timer: 3000,
                        classes: 'saving processing'
                    };
                }
            });

            $.ajax({
                type: 'PATCH',
                url: `/groups/${group.shortId}`,
                data: JSON.stringify(data),
                contentType: 'application/json',
                dataType: 'json',
                success: function () {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Group saved',
                                timer: 3000,
                                classes: 'saved success'
                            };
                        }
                    });
                }
            });
        }
    }

    renderViews() {
        this.viewsComponents = {};

        const group = this.props.group;

        if (!group) {
            return (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <LoadingIndicator/>
                </div>
            );
        }

        const sectionInfo = group.section;
        let components = [];

        for (const vid in sectionInfo.views) {
            const view = sectionInfo.views[vid];
            const Component = VIEW_COMPONENT_BY_VIEW_TYPE[view.view];

            if (!Component) {
                continue;
            }

            components.push(
                <div
                    key={`v_${vid}`}
                    style={{
                        height: '100%',
                        display: vid === this.state.activeViewId ? 'block' : 'none'
                    }}
                >
                    <Component
                        ref={(el) => { if (el) this.viewsComponents[vid] = el }}
                        parent={this.props.parent}
                        entityType={sectionInfo.entityType}
                        sectionId={this.props.sectionId}
                        group={group}
                        sharedFilterManager={this.sharedFilterManager}
                        sharedSearchTerm={this.state.sharedSearchTerm}
                        {...view.content || {}}
                        onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                        onFiltersChange={this.computeFiltersBarFilters.bind(this)}
                        showConfigureColumnsPanel={this.showConfigureColumnsPanel.bind(this)}
                        showAdvancedFilterPanel={this.showAdvancedFilterPanel.bind(this)}
                        showEntityPanel={this.showEntityPanel.bind(this)}
                        showGroupPanel={this.showGroupPanel.bind(this)}
                        saveAsCsv={this.saveAsCsv.bind(this)}
                        saveAsForecast={this.saveAsForecast.bind(this)}
                        setBarsWidth={this.setBarsWidth.bind(this)}
                        updateGroup={this.updateGroup.bind(this)}
                        changeDateSpan={this.changeDateSpan.bind(this)}
                    />
                </div>
            );
        }

        return components;
    }

    setDataDirty() {
        for (const cid in this.viewsComponents) {
            if (this.viewsComponents[cid].setDataDirty) {
                this.viewsComponents[cid].setDataDirty();
            }
        }
    }

    render() {
        const group = this.props.group;
        const sectionInfo = group?.section;
        let toolbarViews = [];
        let toolbarOptions = null;
        let actionsOptions = null;

        if (sectionInfo) {
            for (const view in sectionInfo.views || []) {
                toolbarViews.push({
                    id: view,
                    disabled: sectionInfo.views[view].disabled
                });
            }

            const activeView = sectionInfo.views[this.state.activeViewId];
            toolbarOptions = activeView?.toolbar || null;
            actionsOptions = activeView?.actions || null;
        }

        let viewHeightMod = this.state.filtersBarFilters ? 130 : 82;

        if (!actionsOptions) {
            viewHeightMod -= 48;
        }

        return (
            <div>
                <div
                    className={`
                        ${style.section}
                        ${style.hasFinder}
                        ${this.state.finderCollapsed ? style.finderCollapsed : ''}
                    `}
                >
                    <div
                        style={{
                            width: this.state.barsWidth
                        }}
                    >
                        {(toolbarOptions || toolbarViews.length > 1) &&
                            <Toolbar
                                ref={(el) => this.toolbar = el}
                                parent={this.props.parent}
                                title={group.name}
                                views={toolbarViews}
                                activeView={this.state.activeViewId}
                                {...(toolbarOptions || {})}
                                onViewSelected={this.handleToolbarViewChange.bind(this)}
                                onSearch={this.handleToolbarSearch.bind(this)}
                                onShowPanel={this.handleToolbarShowPanel.bind(this)}
                                onCalendarDateChange={this.handleCalendarDateChange.bind(this)}
                                onCalendarSpanChange={this.handleCalendarSpanChange.bind(this)}
                                onCalendarDateSpanChange={this.handleCalendarDateSpanChange.bind(this)}
                                saveCurrentGroupAsCsv={this.saveCurrentGroupAsCsv.bind(this)}
                                saveCurrentGroupAsForecast={this.saveCurrentGroupAsForecast.bind(this)}
                            />
                        }

                        {this.state.filtersBarFilters &&
                            <FiltersBar
                                filters={this.state.filtersBarFilters}
                                onFilterRuleDeleted={this.handleFilterRuleDelete.bind(this)}
                                onAllFiltersRulesDeleted={this.handleAllFiltersRulesDeleted.bind(this)}
                            />
                        }

                        {actionsOptions &&
                            <ActionsBar
                                ref={(el) => this.actionsBar = el}
                                entityType={this.props.entityType}
                                parent={this.props.parent}
                                group={group}
                                sectionId={this.props.sectionId}
                                {...actionsOptions}
                                onSelectAll={this.handleActionsBarSelectAll.bind(this)}
                                onNewEntity={this.handleActionsBarNewEntity.bind(this)}
                                onAddEntity={this.handleActionsBarAddEntity.bind(this)}
                            />
                        }
                    </div>

                    <div
                        style={{
                            height: `calc(100% - ${viewHeightMod}px)`
                        }}
                    >
                        {this.renderViews()}
                    </div>
                </div>

                <AdvancedFilterPanel
                    ref={(el) => this.advancedFilterPanel = el}
                    onFilterRulesChange={this.handleAdvancedFilterRulesChange.bind(this)}
                />

                {this.props.entityType === 'opportunities' &&
                    <DealEntityPanel
                        ref={(el) => this.entityPanel = el}
                        parent={this.props.parent}
                        noBackdrop={true}
                        width={925}
                    />
                }

                <SaveEditGroupPanel
                    ref={(el) => this.groupPanel = el}
                    onGroupSaved={this.props.onGroupSaved}
                />

                {toolbarOptions?.columnsEditable &&
                    <ConfigureColumnsPanel
                        ref={(el) => this.configureColumnsPanel = el}
                        width={625}
                        onSelectedColumnsChange={this.handleSelectedColumnsChange.bind(this)}
                    />
                }

                {this.state.renameGroupPopup &&
                    <RenameGroup
                        {...this.state.renameGroupPopup}
                        group={group}
                        onClose={() => this.setState({ renameGroupPopup: null })}
                    />
                }
            </div>
        );
    }
}

export default class Section extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group: null
        };

        this.counter = 0;
    }

    handleFinderToggle() {
        const finderCollapsed = !this.finderPanel.isCollapsed();

        this.finderPanel.setCollapsed(finderCollapsed);
        this.contentComponent.setFinderCollapsed(finderCollapsed);
    }

    handleFinderGroupSelect(group) {
        if (!group) {
            return;
        }

        this.props.finder.onGroupSelected(group);
    }

    handleFinderNewGroup(args) {
        this.contentComponent.handleFinderNewGroup(args);
    }

    setDataDirty() {
        this.contentComponent.setDataDirty();
    }

    setGroup(group) {
        this.setState({
            group: group
        });

        this.finderPanel.onGroupSelected();
    }

    getEntityIdOnPanel() {
        return this.contentComponent.getEntityIdOnPanel();
    }

    showEntity(entityId) {
        this.contentComponent.showEntityPanel(entityId);
    }

    collapseFinder() {
        this.finderPanel.setCollapsed(true);
        this.contentComponent.setFinderCollapsed(true);
    }

    render() {
        return (
            <div>
                <FinderPanel
                    ref={(el) => this.finderPanel = el}
                    {...this.props.finder}
                    entityType={this.props.entityType}
                    onExpand={this.handleFinderToggle.bind(this)}
                    onToggle={this.handleFinderToggle.bind(this)}
                    onGroupSelected={this.handleFinderGroupSelect.bind(this)}
                    onNewGroup={this.handleFinderNewGroup.bind(this)}
                />

                <Content
                    key={`section_content_${this.counter++}`}
                    ref={(el) => this.contentComponent = el}
                    {...this.props.content}
                    entityType={this.props.entityType}
                    sectionId={this.props.sectionId}
                    parent={this.props.parent}
                    group={this.state.group}
                />
            </div>
        );
    }
}

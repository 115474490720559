import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import NoCollectionView from 'js/views/base/no_collection'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import OpportunityModel from 'js/models/opportunity'
import UserModel from 'js/models/user'
import Currency from 'js/utils/currency'
import Utilities from 'js/utils/utilities'
import TextManager from 'app/text-manager'
import dateFormat from 'js/utils/date-format'
import textFieldTemplate from 'templates/custom_fields/textField.handlebars'
import numberFieldTemplate from 'templates/custom_fields/numberField.handlebars'
import dateFieldTemplate from 'templates/custom_fields/dateField.handlebars'
import timeFieldTemplate from 'templates/custom_fields/timeField.handlebars'
import urlFieldTemplate from 'templates/custom_fields/urlField.handlebars'
import urlImageFieldTemplate from 'templates/custom_fields/urlImageField.handlebars'
import paragraphFieldTemplate from 'templates/custom_fields/paragraphField.handlebars'
import individualFieldTemplate from 'templates/custom_fields/individualField.handlebars'
import organizationFieldTemplate from 'templates/custom_fields/organizationField.handlebars'
import opportunityFieldTemplate from 'templates/custom_fields/opportunityField.handlebars'
import currencyFieldTemplate from 'templates/custom_fields/currencyField.handlebars'
import dropDownFieldTemplate from 'templates/custom_fields/dropDownField.handlebars'
import checkboxFieldTemplate from 'templates/custom_fields/checkboxField.handlebars'


var CustomFieldItemView = Marionette.ItemView.extend({
    getErrorMsg: function() {
        if (!this.options.defaultValue && this.model.get('required') && !this.model.get('value')) {
            return this.model.get('name') + ' is required';
        }
    },
    showError: function(message) {
        this.ui.field
            .addClass('validation_error')
            .nextAll('.error-message')
            .text(message)
            .addClass('invalid');
    },
    hideError: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
        this.$el.find('.error-message').removeClass('invalid');
    }
});

var ParagraphItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( paragraphFieldTemplate ),
    ui: {
        field: '.field-paragraph'
    },
    events: {
        'change .field-paragraph': function( e ) {
            e.preventDefault();
            this.model.set( 'value', e.target.value );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
        }
    },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.model.get( 'value' ) };
    }
} );

var IndividualItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( individualFieldTemplate ),

    ui: {
        field: '.field-individual'
    },

    initialize: function() {
        this.individualName = '';
        this.shortId = '';
        this.individualPhoto = null;
        this.organizationName = '';

        var value = this.model.get( 'value' );

        if ( value ) {
            var view = this;
            var individual = new IndividualModel({ id: value });

            if ( !individual.get( 'full_name' ) || !individual.get( 'short_id' ) ) {
                individual.fetch( {
                    success: function() {
                        view.individualName = individual.get( 'full_name' );
                        view.shortId = individual.get( 'short_id' );
                        view.individualPhoto = individual.get('photo_url');
                        view.organizationName = individual.get('organization_name');
                        view.render();
                    },
                    error: function() {
                        view.individualName = '';
                        view.render();
                    }
                } );
            }
            else {
                view.individualName = individual.get( 'full_name' );
                view.shortId = individual.get( 'short_id' );
                view.individualPhoto = individual.get('photo_url');
                view.organizationName = individual.get('organization_name');
            }
        }
    },

    onRender: function() {
        var value = this.model.get('value');

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            url: '/individuals',
            search: true,
            text: 'title',

            options: {
                allowClear: true,
                placeholder: TextManager.getText('ID_SEARCH_FOR_AN_INDIVIDUAL'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'individual-select-popover popover select2-drop-wider',
                minimumInputLength: 1,
                formatResult: function(item, container) {
                    var html = _.escape(item.title);

                    if (item.item.organization_name) {
                        html += '<div class="organization-name">' + _.escape(item.item.organization_name) + '</div>';
                        container.addClass('has-organization-name');
                    }

                    return Handlebars.compile(html);
                }
            }
        });
        this.listenTo(this.select, 'change', this.onValueChange);

        this.select.setValue( this.shortId ? {id: value, title: this.individualName} : null );
    },
    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    },

    templateHelpers: function() {
        return {
            name: this.model.get( 'name' ),
            value: this.individualName,
            short_id: this.shortId,
            photo_url: this.individualPhoto,
            organization_name: this.organizationName
        };
    }
} );


var OrganizationItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( organizationFieldTemplate ),

    ui: {
        field: '.field-additional-organization'
    },

    initialize: function() {
        this.organizationName = '';
        this.shortId = '';

        var value = this.model.get( 'value' );

        if ( value ) {
            var view = this;
            var organization = new OrganizationModel({ id: value });

            if ( !organization.get( 'name' ) || !organization.get( 'short_id' ) ) {
                organization.fetch( {
                    success: function() {
                        view.organizationName = organization.get( 'name' );
                        view.shortId = organization.get( 'short_id' );
                        view.render();
                    },
                    error: function() {
                        view.organizationName = '';
                        view.render();
                    }
                } );
            }
            else {
                view.organizationName = organization.get( 'name' );
                view.shortId = organization.get( 'short_id' );
            }
        }
    },

    onRender: function() {
        var value = this.model.get('value');

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            url: '/organizations',
            search: true,
            text: 'title',

            options: {
                allowClear: true,
                placeholder: TextManager.getText('ID_SEARCH_FOR_AN_ORGANIZATION'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'organization-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });
        this.listenTo(this.select, 'change', this.onValueChange);

        this.select.setValue( this.shortId ? {id: value, title: this.organizationName} : null );
    },
    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    },

    saveValue: function( label, value ) {
        // we accept: empty string or individual selected from combobox
        var valid = false;
        var valueToSave = '';

        if ( !label ) {
            valid = true;
        }
        else if ( label && value ) {
            valid = true;
            valueToSave = value;
        }

        if ( valid ) {
            this.model.set( 'value', valueToSave );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: valueToSave } );
        }
    },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.organizationName, short_id: this.shortId };
    }
} );


var OpportunityItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( opportunityFieldTemplate ),

    ui: {
        field: '.field-additional-opportunity'
    },

    initialize: function() {
        this.name = '';
        this.id = '';

        var value = this.model.get( 'value' );

        if ( value ) {
            var view = this;
            var opportunity = new OpportunityModel({ id: value });

            if ( !opportunity.get( 'name' ) || !opportunity.get( 'id' ) ) {
                opportunity.fetch( {
                    success: function() {
                        view.name = opportunity.get( 'name' );
                        view.id = opportunity.get( 'id' );
                        view.render();
                    },
                    error: function() {
                        view.name = '';
                        view.render();
                    }
                } );
            }
            else {
                view.name = opportunity.get( 'name' );
                view.id = opportunity.get( 'id' );
            }
        }
    },

    onRender: function() {
        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            url: '/opportunities',
            search: true,
            text: 'title',

            options: {
                allowClear: true,
                placeholder: TextManager.getText('ID_SEARCH_FOR_A_DEAL'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'opportunity-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });
        this.listenTo(this.select, 'change', this.onValueChange);

        this.select.setValue( this.id ? {id: this.id, title: this.name} : null );
    },
    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    },

    saveValue: function( label, value ) {
        var valid = false;
        var valueToSave = '';

        if ( !label ) {
            valid = true;
        }
        else if ( label && value ) {
            valid = true;
            valueToSave = value;
        }

        if ( valid ) {
            this.model.set( 'value', valueToSave );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: valueToSave } );
        }
    },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.name, id: this.id };
    }
} );


var UserItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( opportunityFieldTemplate ),

    ui: {
        field: '.field-additional-opportunity'
    },

    initialize: function() {
        this.name = '';
        this.id = '';

        var value = this.model.get( 'value' );

        if ( value ) {
            var view = this;
            var user = new UserModel({ id: value });

            if ( !user.get( 'name' ) || !user.get( 'id' ) ) {
                user.fetch( {
                    success: function() {
                        view.name = user.get( 'name' );
                        view.id = user.get( 'id' );
                        view.render();
                    },
                    error: function() {
                        view.name = '';
                        view.render();
                    }
                } );
            }
            else {
                view.name = user.get( 'name' );
                view.id = user.get( 'id' );
            }
        }
    },

    onRender: function() {
        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            url: '/users',
            search: true,
            text: 'title',

            options: {
                allowClear: true,
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'opportunity-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });
        this.listenTo(this.select, 'change', this.onValueChange);

        this.select.setValue( this.id ? {id: this.id, title: this.name} : null );
    },
    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    },

    saveValue: function( label, value ) {
        var valid = false;
        var valueToSave = '';

        if ( !label ) {
            valid = true;
        }
        else if ( label && value ) {
            valid = true;
            valueToSave = value;
        }

        if ( valid ) {
            this.model.set( 'value', valueToSave );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: valueToSave } );
        }
    },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.name, id: this.id };
    }
} );


var TextItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( textFieldTemplate ),
    ui: {
        field: '.field-text'
    },
    events: {
        'change .field-text': function( e ) {
            e.preventDefault();
            this.model.set( 'value', e.target.value );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
        }
    },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.model.get( 'value' ) };
    }
} );


var NumberItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( numberFieldTemplate ),
    ui: {
        field: '.field-number',
        error_message: '.error-message'
    },
    events: {
        'change .field-number': function( e ) {
            e.preventDefault();

            this.isValid = e.target.checkValidity();
            if ( !this.isValid || ( e.target.value !== this.model.get( 'value' ) ) ) {
                this.model.set( 'value', e.target.value );
                this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
            }
        }
    },

    templateHelpers: function() {
        return {
            name: this.model.get( 'name' ),
            value: this.model.get( 'value' ),
            valueWithCommas: Utilities.numberWithCommas( this.model.get( 'value' ) )
        };
    },

    initialize: function() {
        this.isValid = true;
    },

    getErrorMsg: function() {
        var msg = CustomFieldItemView.prototype.getErrorMsg.call(this);
        if (msg) {
            return msg;
        } else if (!this.isValid) {
            return 'Enter a valid number';
        }
    }
} );

var ProductItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( numberFieldTemplate ),
    ui: {
        field: '.field-number',
        error_message: '.error-message'
    },
    events: {
        'change .field-number': function( e ) {
            e.preventDefault();

            this.isValid = e.target.checkValidity();
            if ( !this.isValid || ( e.target.value !== this.model.get( 'value' ) ) ) {
                this.model.set( 'value', e.target.value );
                this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
            }
        }
    },

    templateHelpers: function() {
        return {
            name: this.model.get( 'name' ),
            value: this.model.get( 'value' ),
            valueWithCommas: Utilities.numberWithCommas( this.model.get( 'value' ) )
        };
    },

    initialize: function() {
        this.isValid = true;
    },

    getErrorMsg: function() {
        var msg = CustomFieldItemView.prototype.getErrorMsg.call(this);
        if (msg) {
            return msg;
        } else if (!this.isValid) {
            return 'Enter a valid number';
        }
    }
} );


var CheckboxItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( checkboxFieldTemplate ),
    ui: {
        field: '.field-checkbox'
    },
    events: {
        'change .field-checkbox': function( e ) {
            e.preventDefault();

            var val = e.target.checked.toString();

            this.model.set( 'value', val );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
        }
    },

    templateHelpers: function() {
        return {
            name: this.model.get( 'name' ),
            value: this.model.get( 'value' ) === 'true',
            strValue: this.model.get( 'value' ) === 'true'
        };
    }
} );


var DateItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( dateFieldTemplate ),
    ui: {
        field: '.field-date',
        error_message: '.error-message'
    },
    events: {
        'focus .field-date': function( e ) {
            var view = this;
            var defaultDate = null;
            var value = view.model.get( 'value' );

            if ( value ) {
                var vals = value.split( '/' );
                defaultDate = new Date( vals[ 2 ], vals[ 1 ] - 1, vals[ 0 ] );
            }

            $( e.currentTarget ).datepicker( {
                defaultDate: defaultDate,
                numberOfMonths: 2,
                dateFormat: 'M dd, yy',
                timezone: "+0000",
                showButtonPanel: true,
                changeYear: true
            } );
        },
        'change .field-date': function( e ) {
            e.preventDefault();

            var strDate = e.target.value;
            var value = '';

            this.isValid = true;

            if (strDate !== '')
            {
                var val = dateFormat.parseDate(strDate);

                if (Utilities.dateIsValid(val)) {
                    value = this.getDateString(val);
                    this.ui.field.datepicker('setDate', val);
                }
                else {
                    this.isValid = false;
                }
            }

            if ( !this.isValid || ( value !== this.model.get( 'value' ) ) ) {
                this.model.set( 'value', value );
                this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: value } );
            }
        }
    },
    initialize: function() {
        this.isValid = true;
    },
    getDateString: function(date) {
        var day  = date.getDate();
        var month = date.getMonth() + 1;
        var year =  date.getFullYear();
        var value = month + '/' + day + '/' + year;

        return value;
    },
    getErrorMsg: function() {
        var msg = CustomFieldItemView.prototype.getErrorMsg.call(this);
        if (msg) {
            return msg;
        } else if (!this.isValid) {
            return 'Enter a valid date';
        }
    },
    templateHelpers: function() {
        var value = this.model.get('value');
        return { date: value ? new Date(value) : null };
    }
} );

var TimeItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( timeFieldTemplate ),

    // events: {
    //     'focus .field-time': function( e ) {
    //         var view = this;
    //         var defaultDate = null;
    //         var value = view.model.get( 'value' );

    //         // if ( value ) {
    //         //     var vals = value.split( '/' );
    //         //     defaultDate = new Date( vals[ 2 ], vals[ 1 ] - 1, vals[ 0 ] );
    //         // }

    //         $( e.currentTarget ).timepicker( {
    //             // defaultDate: defaultDate,
    //             // numberOfMonths: 2,
    //             // dateFormat: 'M dd, yy',
    //             // timezone: "+0000",
    //             // onSelect: function( dataText, inst ) {
    //             //     var date = $(this).datepicker('getDate');
    //             //     var day  = date.getDate();
    //             //     var month = date.getMonth() + 1;
    //             //     var year =  date.getFullYear();
    //             //     var value = day + '/' + month + '/' + year;

    //             //     view.model.set( 'value', value );
    //             //     view.trigger( 'editing:valueChanged', { key: view.model.get( 'id' ), value: value } );
    //             // }
    //        } );
    //     }
    // },

    templateHelpers: function() {
        return { name: this.model.get( 'name' ), value: this.model.get( 'value' ) };
    }
} );


var UrlItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( urlFieldTemplate ),
    ui: {
        field: '.field-url'
    },
    events: {
        'change .field-url': function( e ) {
            e.preventDefault();
            this.model.set( 'value', e.target.value );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
        }
    },

    templateHelpers: function() {
        var value = this.model.get( 'value' );
        var params = this.model.get( 'params' ) || {};
        var previewUrl = value;
        var showPreview = params.show_preview && !!previewUrl;

        if (showPreview) {
            // if the protocol is not set, we use https
            if (!previewUrl.match(/^[a-zA-Z]+:\/\//)) {
                previewUrl = 'https://' + previewUrl;
            }
            else {
                // if the protocol is http, the iframe is hidden
                showPreview = previewUrl.indexOf('http://') === -1;
            }
        }

        return {
            name: this.model.get( 'name' ),
            value: value,
            href: Utilities.httpUrl(value),
            previewUrl: previewUrl,
            showPreview: showPreview && params.preview_height > 0,
            previewHeight: params.preview_height
        };
    }
} );

var UrlImageItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( urlImageFieldTemplate ),
    ui: {
        field: '.field-url-image'
    },

    events: {
        'change .field-url-image': function( e ) {
            e.preventDefault();
            this.model.set( 'value', e.target.value );
            this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: e.target.value } );
        }
    },

    templateHelpers: function() {
        var value = this.model.get( 'value' );

        return {
            name: this.model.get( 'name' ),
            value: value,
            href: Utilities.httpUrl(value)
        };
    }
} );

var CurrencyItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( currencyFieldTemplate ),
    templateHelpers: function() {
        return {
            value: Currency.composeCurrencyName(this.model.get('value'))
        };
    },
    ui: {
        field: '.field-currency'
    },

    onRender: function() {
        var values = Currency.getUsedCurrenciesToSelect2Array();
        var active_value = this.model.get('value') || null;

        // we only want the 3 first characters (currency ID)
        if ( active_value ) {
            active_value = active_value.substr( 0, 3 );
        }

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            data: values,
            text: 'text',
            value: active_value,
            options: {
                placeholder: 'Select a currency',
                allowClear: true,
                containerCssClass: 'select2-block',
                dropdownCssClass: 'currency-select-popover popover'
            }
        });
        this.listenTo(this.select, 'change', this.onValueChange);
    },

    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    }
} );

var DropDownItemView = CustomFieldItemView.extend( {
    tagName: 'li',
    template: Handlebars.compile( dropDownFieldTemplate ),

    ui: {
        field: '.field-drop-down'
    },

    initialize: function() {
        var active_value = this.model.get('value') || null;

        if (active_value) {
            this.model.set('value_str', this.getValueName(active_value));
        }
    },

    onRender: function() {
        var options = this.model.get('valueDef').options || [];
        var active_value = this.model.get('value') || null;
        var numeric = [],
            literal = [],
            sorted = [];

        // order values numbers first numerically words afterwards alphabetically
        _.each(options, function(o) {
            if (parseFloat(o.value).toString() === o.value.toString()) {
                o.value = parseFloat(o.value);
                numeric.push(o);
            }
            else {
                literal.push(o);
            }
        });
        sorted = sorted.concat(_.sortBy(numeric, function(o) { return o.value; }));
        _.each(sorted, function(o) { o.value = o.value.toString(); });
        sorted = sorted.concat(_.sortBy(literal, function(o) { return o.value; }));

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            data: sorted,
            text: 'value',
            value: active_value,
            options: {
                placeholder: 'Select a value',
                allowClear: true,
                containerCssClass: 'select2-block',
                dropdownCssClass: 'currency-select-popover popover'
            }
        });

        this.listenTo(this.select, 'change', this.onValueChange);
    },

    onValueChange: function() {
        var val = this.ui.field.select2('val');

        this.model.set( 'value', val );
        this.model.set( 'value_str', this.getValueName(val));
        this.trigger( 'editing:valueChanged', { key: this.model.get( 'id' ), value: val } );
    },

    getValueName: function(value) {
        var options = this.model.get('valueDef').options;

        for (var i = 0; i < options.length; ++i) {
            if (options[i].id === value) {
                return options[i].value;
            }
        }

        return '';
    }
} );

var EntityListItemView = CustomFieldItemView.extend({
    tagName: 'li',
    template: Handlebars.compile('')
});

var items = {
    getItemView: function(type) {
        if ( type === 'text' ) {
            return TextItemView;
        }
        else if ( type === 'individual' ) {
            return IndividualItemView;
        }
        else if ( type === 'organization' ) {
            return OrganizationItemView;
        }
        else if ( type === 'opportunity' ) {
            return OpportunityItemView;
        }
        else if ( type === 'user' ) {
            return UserItemView;
        }
        else if ( type === 'number' ) {
            return NumberItemView;
        }
        else if ( type === 'date' ) {
            return DateItemView;
        }
        else if ( type === 'time' ) {
            return TimeItemView;
        }
        else if ( type === 'url' ) {
            return UrlItemView;
        }
        else if ( type === 'urlImage' ) {
            return UrlImageItemView;
        }
        else if ( type === 'paragraph' ) {
            return ParagraphItemView;
        }
        else if ( type === 'currency' ) {
            return CurrencyItemView;
        }
        else if ( type === 'dropDown' ) {
            return DropDownItemView;
        }
        else if ( type === 'checkbox' ) {
            return CheckboxItemView;
        } else if ( type === 'product' ) {
            return ProductItemView;
        } else if ( type === 'entityList' ) {
            return EntityListItemView;
        }

        // ...
        // Override with correct tag and no content
        return NoCollectionView.extend({ tagName: 'li', template: Handlebars.compile('') });
    }
};

export default items;

import React from 'react'
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'

import guid from 'js/utils/guid'
import app from 'js/app'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import FunnelViewEx from 'js/views/funnelEx'
import FunnelColorKey from './funnel_colorkey'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import MessageBox from 'js/views/message_box'
import IpwTrigger from 'js/ipw_trigger'
import OpportunityModel from 'js/models/opportunity'
import dateFormat from 'js/utils/date-format'


export default class Funnel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            docked: false
        };

        const self = this;

        this.props.widget.addEventListener('funnel:change', function() {
            if (!self.mounted) {
                return;
            }
            self.loadFunnel(self.props.widget.fetcher.activeFunnel.id);
        });

        this.props.widget.addEventListener('cluster:change', function() {
            if (!self.mounted) {
                return;
            } 

            self.fakePhases = []
            
            const { fetcher } = self.props.widget
            const { last_visited_funnel_id } = app.user.get('preferences')

            self.clusterFunnelsIds = fetcher.activeClusterFunnels.map(funnel => funnel.id)
            self.lastVisitedFunnelId = last_visited_funnel_id
            
            //let funnelToLoadId = self.clusterFunnelsIds.filter(id => id == last_visited_funnel_id) || self.clusterFunnelsIds
            self.loadFunnel(self.clusterFunnelsIds)
        });

        this.props.widget.addEventListener('dirty', function() {
            if (!self.mounted) {
                return;
            }

            self.setState({
                docked: self.props.widget.rectState === 'leftDocked'
            });

            _.defer(function() {
                self.funnel.renderFunnel();
            });
        });
    }

    componentDidMount() {
        this.mounted = true;

        const region = new Marionette.Region({
            el: this.element
        });

        const preferences = app.user.get('preferences');
        const initialColorkey = preferences.funnel_color || 'activity';
        const isPdcrm = AppConfig.getValue('is_pdcrm', false);

        this.funnel = new FunnelViewEx({
            dashboardView: isPdcrm ? 'pdcrm' : 'ss',
            triggerAllEvents: true,
            funnelColor: initialColorkey,
            hideWeeks: true
        });

        region.show(this.funnel);

        const self = this;

        if (!isPdcrm) {
            this.funnels = new Backbone.Collection(app.globalData.funnelsInfo.funnels.map(funnel => {return {
                id: funnel.id,
                name: funnel.name
            }})).models;

            this.funnel.setFunnels(this.funnels);
            this.funnel.renderFunnel();
        }

        this.funnel.listenTo(this.funnel, 'show-deal', (dealId) => {
            self.props.widget.triggerEvent('showEntity', {
                type: 'opportunities',
                id: dealId,
                rectState: 'leftDocked',
                widget: self.props.widget
            });
        });

        this.funnel.listenTo(this.funnel, 'change-funnel', (funnelId) => {
            app.user.updatePreference('last_visited_funnel_id', funnelId);

            self.loadFunnel(funnelId);
        });

        this.funnel.listenTo(this.funnel, 'deal-phase-change', (data) => {
            self.handlePhaseChange(data);
        });
        if (!preferences.last_visited_region_funnels){     
            _.defer(function() {
                self.loadFunnel(self.props.widget.fetcher.activeFunnel.id);
            });
        } else {
            _.defer(function() {
                self.loadFunnel(preferences.last_visited_region_funnels.funnels.map(funnel => funnel.id));
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handlePhaseChange(data) {
        const deal = data.deal;
        const phase = data.phase;
        const funnel = data.funnel

        if (!phase && !funnel) {
            return;
        }

        let dealData = {};

        if (phase) {
            dealData.phase_id = phase.realPhaseId ? phase.realPhaseId : phase.id;
        } else {
            dealData.funnel_id = funnel.id;
        }

        if (AppConfig.getValue('showIPWCampaignTriggerMessage') && deal && phase) {
            const currentPhase = deal.get('phase');

            if (currentPhase && (phase.order > currentPhase.order)) {
                const mbContent = {
                    message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                    icon: 'icon-warning'
                };

                const self = this;

                const updateFields = function(phase, data, triggerIpw) {
                    const ipwTrigger = new IpwTrigger();

                    ipwTrigger.getPopulatedIpwCustomFields(phase.name, triggerIpw, null, function(fields) {
                        dealData = _.extend(dealData, fields);
                        self.updateDeal(deal.id, dealData, deal);
                    });
                }

                MessageBox.showYesNo(mbContent, this.funnel,
                    function() { // Yes
                        updateFields(phase, data, true);
                    },
                    function() { // No
                        updateFields(phase, data, false);
                    }, null,
                    {
                        staticRegion: true
                    }
                );
            } else {
                this.updateDeal(data.dealId, dealData);
            }
        } else {
            this.updateDeal(data.dealId, dealData);
        }
    }

    updateDeal(dealId, data) {
        let model = new OpportunityModel({id: dealId});
        const self = this;

        model.save(data, {
            patch: true,
            validate: false,
            ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
            params: {
                check_phase_change_allowed: true,
                check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
            },
            error: function(_, response) {
                let detail = '';

                try {
                    detail = JSON.parse(response.responseText).detail;
                } catch (_) {}

                if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' || detail.exception === 'OpportunityPhaseGateNotClear')) {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            let message = detail.message;

                            if (detail.exception === 'OpportunityPhaseGateNotClear') {
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }

                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            },
            complete: function() {
                // Use fakeDeals if clusterFunnelsIds exists, otherwise use self.deals
                let deals = (self.clusterFunnelsIds && self.clusterFunnelsIds.length > 1) ? new Backbone.Collection(self.fakeDeals).models : self.deals;
            
                // Find index of the deal by ID
                const idx = deals.findIndex(deal => deal.get('id') === dealId);
            
                if (self.clusterFunnelsIds && self.clusterFunnelsIds.length > 1) {
                    let fakeDeal = deals[idx].toJSON();
                    let modelJsonDeal = model.toJSON();
            
                    // Find the next phase by phase name
                    const nextFakePhase = self.fakePhases.find(phase => phase.name === modelJsonDeal.phase_name);
            
                    // Update modelJsonDeal with phase and funnel details
                    modelJsonDeal = {
                        ...modelJsonDeal,
                        real_funnel: fakeDeal.real_funnel,
                        real_phase_id: modelJsonDeal.phase_id,
                        real_funnel_id: fakeDeal.real_funnel_id,
                        phase_id: nextFakePhase.id,
                        phase: nextFakePhase,
                        funnel: fakeDeal.funnel,
                        funnel_id: fakeDeal.funnel.id
                    };
            
                    // Replace the deal in fakeDeals
                    self.fakeDeals[idx] = modelJsonDeal;
                    model = new Backbone.Model(modelJsonDeal);
                }
            
                // Replace the deal with the updated model
                deals.splice(idx, 1, model);
            
                // Update fakeDeals if clusterFunnelsIds exists
                if (self.clusterFunnelsIds && self.clusterFunnelsIds.length > 1) {
                    self.fakeDeals = deals;
                } else {
                    const changedModel = model.toJSON()
                    
                }
            
                // Set the updated deals in the funnel and render it
                self.funnel.setDeals(deals);
                self.funnel.renderFunnel();
            }
            
        });
    }

    loadFunnel(funnelId) {
        if (!this.mounted) {
            return;
        }

        let funnelIds = null
        if (Array.isArray(funnelId)) {
            funnelIds = funnelId
        } else {
            funnelIds = [funnelId]
        }

        this.setState({loading: true});

        const self = this;

        const setFunnelLoad = (funnel, funnelId, deals, phases, periods, funnelType) => {
            self.funnel.setPhases(phases);
            self.funnel.setDeals(deals);
            self.funnel.setPeriods(periods);
            self.funnel.setFunnels(funnel, funnelId);
            self.funnel.setFunnelType(funnelType);
            self.funnel.renderFunnel();
            self.setState({ loading: false });
        };

        self.periods = null;
        self.deals = null;
        
        const checkReady = () => {
            if (!self.periods || !self.phases || !self.deals) return;
        
            const clusterFunnels = _.clone(app.globalData.funnelsInfo.funnels.filter(f => funnelIds.includes(f.id)));
        
            if (self.clusterFunnelsIds && self.clusterFunnelsIds.length > 1) {
                let funnel = _.clone(clusterFunnels[0]);
                funnel.name = clusterFunnels.map(cluster => cluster.name).join('-');
                funnel.id = guid(); // Funnel ID for dashboard component
                let clusterPhases = [];
                let fakeDeals = [];
        
        
                self.phases.forEach((phase) => {
                    let phaseJson = phase.toJSON();
                    let existingPhase = clusterPhases.find(clusterPhase => clusterPhase.name === phaseJson.name);
        
                    const funnelPhaseObject = {
                        funnel_id: phaseJson.funnel_id,
                        phase_id: phaseJson.id
                    };
        
                    if (existingPhase) {
                        existingPhase.relatedPhaseIds.push(funnelPhaseObject);
                    } else {
                        clusterPhases.push({
                            id: guid(),
                            name: phaseJson.name,
                            relatedPhaseIds: [funnelPhaseObject],
                            order: phaseJson.order,
                            funnel: funnel,
                            funnel_id: funnel.id,
                            phase_type: phaseJson.phase_type,
                            hint: phaseJson.hint,
                            abbreviation: phaseJson.abbreviation
                        });
                    }
                });
        
                self.deals.forEach(deal => {
                    let newDeal = deal.toJSON();
                    let relatedPhase = clusterPhases.find(phase =>
                        phase.relatedPhaseIds.some(phaseObj => phaseObj.phase_id === newDeal.phase_id)
                    );
        
                    newDeal.real_funnel = newDeal.phase.funnel;
                    newDeal.real_phase_id = newDeal.phase_id;
                    newDeal.real_funnel_id = newDeal.phase.funnel_id;
                    newDeal.phase_id = relatedPhase?.id;
                    newDeal.phase = relatedPhase;
                    newDeal.funnel = funnel;
                    newDeal.funnel_id = funnel.id;
        
                    fakeDeals.push(newDeal);
                });
        
                self.fakeDeals = fakeDeals;
                self.fakePhases = clusterPhases;
                self.fakeFunnel = funnel;
        
                setFunnelLoad(
                    [new Backbone.Model(funnel)],
                    funnel.id,
                    new Backbone.Collection(fakeDeals).models,
                    new Backbone.Collection(clusterPhases).models,
                    self.periods,
                    self.props.widget.params.view
                );
            } else {
                const funnel = new Backbone.Model(app.globalData.funnelsInfo.funnels.find(f => f.id === funnelId));
        
                setFunnelLoad(
                    self.funnels || [funnel],
                    funnelId,
                    self.deals,
                    self.phases,
                    self.periods,
                    self.props.widget.params.view
                );
            }
        };        
        this.phases = new Backbone.Collection(app.globalData.phasesInfo.phases.filter(p => funnelIds.includes(p.funnel_id))).models;
        this.props.widget.fetcher.get('/periods', {
            start: 0,
            rows: -1
        }, function(data) {
            self.periods = new Backbone.Collection(data).models;
            checkReady();
        });

        // ...
        const funnel = app.globalData.funnelsInfo.funnels.find(f => funnelIds.includes(f.id));
        const customOrderBy = AppConfig.getValue('deals.custom_order_by');
        const useNewEndpoint = AppConfig.getValue('dashboard.use_optimised_funnel_query', false) && AppConfig.getValue('deals.aftercare.funnel_name') !== funnel.name;

        let orderBy = null;

        if (customOrderBy && customOrderBy.is_custom_field) {
            const ccf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === customOrderBy.field.toLowerCase());

            if (ccf) {
                orderBy = `custom_fields.${ccf.id} ${customOrderBy.dir}`;
            }
        }

        if (useNewEndpoint) {
            let args = {};

            if (orderBy) {
                order_by: orderBy
            };

            let fields = [];
            const unitNo = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'unit no.');

            if (unitNo) {
                fields.push(unitNo.id);
            }

            if (fields.length > 0) {
                args.fields = fields.join(',');
            }

            let queryFunnelId = null
            let query = `/pdcrm_funnel_opportunities`
            
            if (funnelIds.length > 1) {
                queryFunnelId = funnelIds.join(',')
                args.funnel_ids = queryFunnelId
            } else {
                query += `/${funnelIds[0]}`
            }

            this.props.widget.fetcher.get(query, args, function(data) {
                for (let deal of data) {
                    deal.value = 0;

                    for (const bucket of deal.buckets || []) {
                        deal.value += bucket.value || 0;
                    }

                    deal.expected_close_date = dateFormat.parseDate(deal.expected_close_date);
                    deal.funnel_id = funnelId;
                    deal.phase = _.clone(app.globalData.phasesInfo.phases.find(p => p.id === deal.phase_id));
                    deal.bubble_representation_value = deal.value;

                    for (const field of fields) {
                        deal[`custom_field.${field}`] = deal[field] || '';
                    }
                }

                self.deals = new Backbone.Collection(data).models;
                checkReady();
            }, {force: true});
        } else {
            this.props.widget.fetcher.get('/opportunities', {
                start: 0,
                rows: -1,
                order_by: orderBy || 'name asc',
                funnel_id: funnelId,
                by_period_id: 'current'
            }, function(data) {
                self.deals = new Backbone.Collection(data).models;
                checkReady();
            }, {force: true});
        }
    }

    handleColorkeyChange(colorkeyId) {
        this.funnel.funnelView.setColorKey(colorkeyId);
        this.funnel.renderFunnel();
    }

    render() {
        let colorKeyStyle = {
            paddingTop: '5px',
            background: 'white'
        };

        if (this.state.docked) {
            colorKeyStyle.paddingRight = '10px';
            colorKeyStyle.paddingLeft = '10px';
        }

        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >   
                <div
                    style={{
                        height: 'calc(100% - 55px)',
                        position: 'relative'
                    }}
                >
                    <div
                        ref={(el) => this.element = el}
                        className='funnel-widget-container inverse'
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: this.state.docked ? '0px' : '6px'
                        }}
                    />
                </div>

                <div style={colorKeyStyle}>
                    <FunnelColorKey
                        onColorkeyChanged={this.handleColorkeyChange.bind(this)}
                    />
                </div>

                {this.state.loading &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            background: 'rgba(0, 0, 0, 0.2)',
                            borderRadius: this.state.docked ? '0px' : '6px'
                        }}
                    >
                        <LoadingIndicator/>
                    </div>
                }
            </div>
        );
    }
}
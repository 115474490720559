import _ from 'underscore';

import operators from 'app_v2/filter_operators';
import app from 'js/app';


const OPERATOR_BY_CF_TYPE = {
    individual: operators.individualOperators,
    organization: operators.organizationOperators,
    opportunity: operators.opportunityOperators,
    user: operators.userOperators,
    text: operators.textOperators,
    checkbox: operators.checkboxOperators,
    paragraph: operators.textOperators,
    url: operators.textOperators,
    urlImage: operators.textOperators,
    number: operators.numberOperators,
    date: operators.dateOperators,
    currency: operators.currencyOperators
};

const getDropdownOperators = (options) => {
    let dropDownOptions = [];

    for (const oid in options) {
        dropDownOptions.push({
            id: oid,
            title: options[oid]
        })
    }

    let ddOperators = [];

    for (let operator of operators.dropdownOperators) {
        let newOperator = _.clone(operator);

        if (newOperator.fields) {
            newOperator.fields = JSON.parse(JSON.stringify(operator.fields)); // deep clone
            newOperator.fields[0].options = dropDownOptions;
        }

        ddOperators.push(newOperator);
    }

    return ddOperators;
}

export const getAvailableFilters = function(baseFilters) {
    let filters = {};

    for (const k in baseFilters) {
        filters[k] = [];

        for (const e of baseFilters[k]) {
            let ce = _.clone(e);

            if (e.isAvailable) {
                if (!e.isAvailable()) {
                    continue;
                }

                delete ce.isAvailable;
            }

            filters[k].push(ce);
        }

        if (filters[k].length === 0) {
            delete filters[k];
        }
    }

    return filters;
}

export const createCustomFieldFilters = function(options) {
    const cfs = app.globalData.customFieldsInfo[`${options.entityType}Array`] || [];
    let filters = [];

    for (const cf of cfs) {
        // some custom fields dont have filters
        if (['entityList', 'list', 'product'].indexOf(cf.type) !== -1) {
            continue;
        }

        let filter = {
            id: options.filterId,
            title: cf.name,
            descTitle: `${options.descPrefix || ''}Custom ${cf.name}`,
            custom: cf.id
        };

        if (cf.type === 'dropDown') {
            filter.operators = getDropdownOperators(cf.options);
        } else {
            filter.operators = OPERATOR_BY_CF_TYPE[cf.type];
        }

        filters.push(filter);
    }

    return filters;
}
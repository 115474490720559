import _ from 'underscore'
import Backbone from 'backbone'

import app from 'js/app'
import api from 'js/api'


var ALL_PERMISSIONS = 'salesseek.core.all_permissions',
    CREATE_PERMISSION = /^salesseek\.core\.create\..*$/,
    security = {
        permissionAliases: {
            'view': 'salesseek.core.view',
            'edit': 'salesseek.core.edit',
            'delete': 'salesseek.core.delete',
            'clone': 'salesseek.core.clone',
            'view_permissions': 'salesseek.core.view_permissions',
            'change_permissions': 'salesseek.core.change_permissions',
            'view_preferences': 'salesseek.core.view_preferences',
            'change_preferences': 'salesseek.core.change_preferences',
            'change_ownership': 'salesseek.core.change_ownership'
        },
        checkPermission:  function (permission, context) {
            if (_.isFunction(permission)) {
                permission = permission.call(context, context);
            }
            if (security.permissionAliases[permission]) {
                permission = security.permissionAliases[permission];
            }
            if (context instanceof Backbone.Collection) {
                context = context.parent;
            }
            if (!context) {
                context = app.state.get('client');
            }
            if (context instanceof Backbone.Model) {
                context = context.toJSON();
            }
            // for new objects all permissions are true
            if (_.isUndefined(context.id) || _.isNull(context.id)) {
                return true;
            }
            var permissions = context.permissions || [],
                isCreate = CREATE_PERMISSION.test(permission),
                hasAll = permissions.indexOf(ALL_PERMISSIONS) !== -1,
                hasPerm = permissions.indexOf(permission) !== -1;
            return (
                // "all permissions" is not valid for creation.
                (isCreate && hasPerm) ||
                (!isCreate && (hasAll || hasPerm))
            );
        },
        addAPIRequestHeaders: function (requestOptions) {
            var headers = api.getRequestHeaders(
                            null,
                            app.user.toJSON()
                            );
            requestOptions.headers = _.extend({}, headers,
                                              requestOptions.headers);
            //if (app.user && app.user.id) {
            //    url = requestOptions.url;
            //
            //    if (url.indexOf('?') === -1) {
            //        url = url + '?auth_token=';
            //    } else {
            //        url = url + '&auth_token=';
            //    }
            //    url = url + app.user.id;
            //    requestOptions.url = url;
            //    console.log(requestOptions.url);
            //}
        },
        checkRbaPermission: function(entityType, entityId, permissionType) {
            const user = app.user;

            if (user.permission_type !== 'rba') {
                return true;
            }

            const roles = app.globalData.loggedUserRoles;

            if (!roles || roles.length === 0) {
                return false;
            }

            for (const role of roles) {
                for (const permission of role.permissions) {
                    const parts = permission.split(':'); // permission = region_id:entity_type:entity_id:permission_type

                    if (parts[1] === entityType && (parts[2] === '*' || parts[2] === entityId) && (parts[3] === '*' || parts[3] === permissionType)) {
                        return true;
                    }
                }
            }

            return false;
        }
    };
export default security;

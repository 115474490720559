import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import vent from 'js/vent'
import leadItemTemplate from 'app/_components/lead-item/lead-item.handlebars'
import draggableLeadItemTemplate from 'app/_components/lead-item/draggable-lead-item.handlebars'
import AppConfig from 'app/app-config'


var IndividualItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(leadItemTemplate),
    templateHelpers: function () {
        var iconClass = this.getIconClass(),
            iconAddresses = {
                'facebook': '/img/lead_sources/lead-facebook.png',
                'twitter': '/img/lead_sources/lead-twitter.png',
                'web': '/img/lead_sources/lead-web.png'
            },
            defaultIconAddress = '/img/lead_sources/lead-default.png',
            source = this.model.get('source') || {};

        var enableCheckbox = true;

        if (AppConfig.getValue('is_pdcrm')) {
            enableCheckbox = app.user.get('is_admin') || app.user.get('is_helper');
        }

        return {
            name: this.model.get('full_name') || `${this.model.get('first_name')} ${this.model.get('last_name')}`,
            isDraggable: this.options.isDraggable,
            primaryPhone: this.model.primaryPhone(),
            primaryEmail: this.model.primaryEmail(),
            getPhoto: this.model.getPhoto(),
            iconClass: iconClass,
            formattedDate: dateFormat.shortSmartFormat(this.model.get('created'))[1],
            formattedDateLead: AppConfig.getValue('leads.created_date.visible') ? dateFormat.timelineFormat(this.model.get('created')) : null,
            sourceIconAddress: iconAddresses[source.system_type] || defaultIconAddress,
            showTags: this.options.showTags && this.model.get('tags').length > 0,
            tags: this.model.get('tags'),
            tagsHoverMessage: this.model.get('tags').map(t => t.name).join(', '),
            enableCheckbox: enableCheckbox,
        };
    },
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        this.confirmOnClick = true;
    },
    onRender: function() {
        var self = this;

        if (this.options.isDraggable) {
            var source = self.model.get('source') || {};

            this.$el.draggable({
                cursor: 'move',
                helper: function() {
                    var data = {
                        photo_url: self.model.get('photo_url'),
                        name: self.model.get('full_name'),
                        organization_name: self.model.get('organization_name'),
                        iconClass: self.getIconClass(),
                        source: source.name
                    };
                    return Handlebars.compile(draggableLeadItemTemplate)(data);
                },
                handle: '.item-handle',
                cursorAt: { left: 0 },
                zIndex: 999,
                scroll: false,
                appendTo: '.funnel-widget-container',
                start: function() {
                    vent.trigger('lead:startDragging', self.model);
                },
                stop: function() {
                    vent.trigger('lead:stopDragging');
                }
            });
        }
    },
    followLink: function(ev) {
        var row = ev.currentTarget,
        link = row.href;

        ev.stopPropagation();
        ev.preventDefault();

        this.trigger('followLink', this.model, link);
    },
    getIconClass: function() {
        var sourceName = (this.model.get('source') || {}).name;

        switch(sourceName) {
            case 'Web':
                return 'globe';
            case 'Facebook':
                return 'facebook';
            case 'Twitter':
                return 'twitter';
        }

        return 'droplet';
    }
});

export default IndividualItemView;

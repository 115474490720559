import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import Utilities from 'js/utils/utilities'
import dateFormat from 'js/utils/date-format'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import OpportunityModel from 'js/models/opportunity'
import LeadSourceModel from 'js/models/lead_source'
import TextManager from 'app/text-manager'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import { renderToString } from 'react-dom/server'


import style from './history.css'

const EVENT_TYPE = {
    'updated': 'Update',
    'created': 'Creation',
    'deleted': 'Deletion'
};

const INDIVIDUALS_VALID_FIELDS = {
    first_name: 'First Name',
    last_name: 'Last Name',
    photo_url: 'Photo',
    role: {
        name: TextManager.getText('ID_JOB_ROLE'),
        getValue: (value) => value
    },
    comments: {
        name: TextManager.getText('ID_MORE_INFO'),
        getValue: (value) => value
    },
    unsubscribed_all: {
        name: TextManager.getText('ID_EMAIL_OPTED_IN'),
        getValue: (value) => _.isBoolean(value) ? !value : null
    },
    unsubscribed_all_messages: {
        name: TextManager.getText('ID_TEXTING_OPTED_IN'),
        getValue: (value) => _.isBoolean(value) ? !value : null
    },
    source_id: {
        name: 'Source',
        getValue: (sourceId) => { return {
            value: sourceId ? '${' + sourceId + '}' : null,
            toFetch: sourceId ? {
                type: 'source',
                id: sourceId
            } : null
        }}
    },
    organization: {
        name: 'Organization',
        getValue: (org) => org?.name
    },
    owner: {
        name: 'Owner',
        getValue: (owner) => owner?.name
    }
};

const ORGANIZATIONS_VALID_FIELDS = {
    name: 'Name',
    comments: {
        name: TextManager.getText('ID_MORE_INFO'),
        getValue: (value) => value
    },
    owner: {
        name: 'Owner',
        getValue: (owner) => owner?.name
    }
};

const OPPORTUNITIES_VALID_FIELDS = {
    name: 'Name',
    currency: 'Currency',
    weight: 'Weight',
    short_id: {
        name: TextManager.getText('ID_DEAL_ID'),
        getValue: (value) => value
    },
    comments: {
        name: TextManager.getText('ID_MORE_INFO'),
        getValue: (value) => value
    },
    organization: {
        name: 'Organization',
        getValue: (org) => org?.name
    },
    owner: {
        name: 'Owner',
        getValue: (owner) => owner?.name
    },
    funnel: {
        name: 'Funnel',
        getValue: (funnel) => funnel?.name
    },
    phase: {
        name: TextManager.parseText('${ID_PHASE, capitalize}'),
        getValue: (phase) => phase?.name
    },
    expected_close_date: {
        name: TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize}'),
        getValue: (date) => date ? dateFormat.entityInformationFormat(date) : ''
    },
    status: {
        name: TextManager.parseText('${ID_STATUS, capitalize}'),
        getValue: (status) => {
            if (!status) {
                return status;
            }

            return {
                none: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}'),
                none_upside: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}'),
                committed_downside: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}'),
                committed: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
            }[status];
        }
    }
};

const VALID_FIELDS = {
    individuals: INDIVIDUALS_VALID_FIELDS,
    organizations: ORGANIZATIONS_VALID_FIELDS,
    opportunities: OPPORTUNITIES_VALID_FIELDS
};

const MODEL_BY_TYPE = {
    individual: IndividualModel,
    organization: OrganizationModel,
    opportunity: OpportunityModel,
    source: LeadSourceModel
};


class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            loading: true
        };

        this.entitiesToFetch = [];

        this.fetchData();
    }

    downloadCSV() {
        if (this.state.rows.length === 0) {
            return;
        }

        const content = [
            'Event,Event Description,Date,Time,User'
        ];

        for (const r of this.state.rows) {
            const date = dateFormat.shortFormatWithYear(r.modified);
            const time = dateFormat.shortFormatTime(r.modified, true);
            let description = $(document.createElement('div')).append(r.description).text();

            for (const tag of ['<b>', '</b>', '\n']) {
                description = Utilities.replaceAll(description, tag, '');
            }

            description = Utilities.replaceAll(description, '\n', ' ');
            description = Utilities.replaceAll(description, '<br>', '; ');

            content.push(`${r.type},"${description}","${date}",${time},${r.user}`);
        }

        let a = document.createElement('a');
        let blob = new Blob([content.join('\n')], {'type': 'application/octet-stream'});

        a.href = window.URL.createObjectURL(blob);
        a.download = `${this.props.entityName} history log ${dateFormat.formatDDMMYYYYDate(new Date())}.csv`;

        a.click();
    }

    fetchData() {
        const self = this;

        $.get(`/${this.props.entityType}/${this.props.entityId}/history`, function(results) {
            let rows = [];
            let creationEntityRow = null;

            for (const row of results) {
                if (row.event === 'created') {
                    creationEntityRow = self.getRowsEvents(row);
                } else {
                    rows = rows.concat(self.getRowsEvents(row));
                }
            }

            rows = _.sortBy(rows, 'modified').reverse();

            // not sure why but the timestamp for the entity creation event is not the older one, so we manually added as last element in the array
            if (creationEntityRow) {
                rows = rows.concat(creationEntityRow);
            }

            if (self.entitiesToFetch.length > 0) {
                let remainingModels = 0;
                let mergeTags = {};

                for (const e of self.entitiesToFetch) {
                    const model = new MODEL_BY_TYPE[e.type]({id: e.id});
                    ++remainingModels;

                    model.fetch({
                        success: function(response) {
                            mergeTags[e.id] = response.get('full_name') || response.get('name');
                        },
                        complete: function() {
                            --remainingModels;

                            if (remainingModels === 0) {
                                for (let r of rows) {
                                    var text = renderToString(r.description[0]);

                                    for (const tid in mergeTags) {
                                         text = Utilities.replaceAll(text, '${' + tid + '}', mergeTags[tid]);
                                    }

                                    r.description = text;
                                }

                                self.setState({
                                    rows: rows,
                                    loading: false
                                });
                            }
                        }
                    });
                }
            } else {
                for (let r of rows) {
                    r.description = renderToString(r.description[0]);
                }

                self.setState({
                    rows: rows,
                    loading: false
                });
            }
        });
    }

    getRowsEvents(row) {
        // a simple 'update' event can contain other creation/deletion events, for instance, if an individual is updated
        // changing its name, adding a email and deleting the value for a custom field we have to generate 3 rows: update, deletion and creation.
        let events = {
            created: [],
            updated: [],
            deleted: []
        };

        const self = this;

        const processFields = (method, fields) => {
            if (!fields) {
                return;
            }

            const fieldRows = method.bind(self)(fields);

            for (const k in fieldRows) {
                if (fieldRows[k].length > 0) {
                    events[k] = events[k].concat(fieldRows[k]);
                }
            }
        }

        processFields(this.processCommunications, row.data.communications);
        processFields(this.processLocations, row.data.locations);
        processFields(this.processCustomFields, row.data.custom_fields2);
        processFields(this.processTags, row.data.tags);
        processFields(this.processBuckets, row.data.buckets);
        processFields(this.processSystemFields, row.data);

        let rows = [];

        for (const k in events) {
            if (events[k].length > 0) {
                rows.push({
                    type: EVENT_TYPE[k],
                    description: this.getEventDescription(k, events[k]),
                    modified: row.modified,
                    user: row.last_modified_by?.name,
                });
            }
        }

        return rows;
    }

    processBuckets(buckets) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        for (const e of buckets) {
            switch (e.event) {
                case 'created':
                    rows.created.push({
                        name: `Bucket ${e.data.name.new}`,
                        value: e.data.value.new
                    });
                    break;

                case 'updated':
                    if (e.data.value.new !== e.data.value.old) {
                        rows.updated.push({
                            name: `Bucket ${e.data.name.new}`,
                            old: e.data.value.old,
                            new: e.data.value.new
                        });
                    }
                    break;
            }
        }

        return rows;
    }

    processTags(tags) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        for (const e of tags) {
            switch (e.event) {
                case 'created':
                    rows.created.push({
                        name: 'Tag',
                        value: e.data.tag_name.new,
                        isAddition: true
                    });
                    break;

                case 'deleted':
                    rows.deleted.push({
                        name: 'Tag',
                        value: e.data.tag_name.old,
                        isAddition: true
                    });
                    break;
            }
        }

        return rows;
    }

    processLocations(locations) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        for (const e of locations) {
            switch (e.event) {
                case 'created':
                    rows.created.push({
                        name: 'Location',
                        value: e.data.address.new
                    });
                    break;

                case 'updated':
                    if (e.data.address?.new !== e.data.address?.old) {
                        rows.updated.push({
                            name: 'Location',
                            new: e.data.address.new,
                            old: e.data.address.old
                        });
                    }

                    if (e.data.comments?.new !== e.data.comments?.old) {
                        rows.updated.push({
                            name: 'Location Comments',
                            new: e.data.comments.new,
                            old: e.data.comments.old
                        });
                    }
                    break;

                case 'deleted':
                    rows.deleted.push({
                        name: 'Location',
                        value: e.data.address.old
                    });
                    break;
            }
        }

        return rows;
    }

    processCommunications(communications) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        const getNameBySocial = (medium, name) => {
            switch (medium) {
                case 'phone':
                    return 'Phone';

                case 'email':
                    return 'Email Address'

                case 'website':
                    return 'Website';

                case 'social':
                    return Utilities.capitalize(name);
            }

            return '';
        };

        for (const e of communications) {
            switch (e.event) {
                case 'created':
                    rows.created.push({
                        name: getNameBySocial(e.data.medium.new, e.data.name.new),
                        value: e.data.value.new
                    });
                    break;

                case 'updated':
                    if (e.data.value.new !== e.data.value.old) {
                        rows.updated.push({
                            name: getNameBySocial(e.data.medium.new, e.data.name.new),
                            new: e.data.value.new,
                            old: e.data.value.old
                        });
                    }

                    if (e.data.comments.new !== e.data.comments.old) {
                        rows.updated.push({
                            name: `${Utilities.capitalize(e.data.medium.new)} Comments`,
                            new: e.data.comments.new,
                            old: e.data.comments.old
                        });
                    }
                    break;

                case 'deleted':
                    rows.deleted.push({
                        name: getNameBySocial(e.data.medium.new, e.data.name.new),
                        value: e.data.value.old
                    });
                    break;
            }
        }

        return rows;
    }

    processCustomFields(customFields) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        const news = customFields.new || {};
        const olds = customFields.old || {};
        const cfInfo = app.globalData.customFieldsInfo;

        const getValue = (cf, val) => {
            switch (cf.type) {
                case 'dropDown':
                    if (cf.options[val]) {
                        return cf.options[val];
                    }
                    break;

                case 'individual':
                case 'organization':
                case 'opportunity':
                    if (val) {
                        if (!this.entitiesToFetch.find(ei => ei.id === val)) {
                            this.entitiesToFetch.push({
                                type: cf.type,
                                id: val
                            });
                        }

                        return '${' + val + '}';
                    }
                    break;
            }

            return val;
        }

        for (const k in news) {
            const cf = cfInfo[this.props.entityType === 'opportunities' ? 'deals' : this.props.entityType][k];

            if (!cf) {
                continue;
            }

            if (!(k in olds)) {
                rows.created.push({
                    name: cf['name'],
                    value: getValue(cf, news[k])
                });
            } else {
                if (news[k] !== olds[k]) {
                    rows.updated.push({
                        name: cf['name'],
                        old: getValue(cf, olds[k]),
                        new: getValue(cf, news[k])
                    });
                }
            }
        }

        for (const k in olds) {
            const cf = cfInfo[this.props.entityType === 'opportunities' ? 'deals' : this.props.entityType][k];

            if (!cf) {
                continue;
            }

            if (!(k in news)) {
                rows.deleted.push({
                    name: cf['name'],
                    value: getValue(cf, olds[k])
                });
            }
        }

        return rows;
    }

    processSystemFields(systemFields) {
        let rows = {
            created: [],
            updated: [],
            deleted: []
        };

        const validFields = VALID_FIELDS[this.props.entityType];

        for (const k in systemFields) {
            if (!(k in validFields)) {
                continue;
            }

            const field = systemFields[k];
            let fieldName;
            let oldValue;
            let newValue;

            if (_.isObject(validFields[k])) {
                fieldName = validFields[k].name;
                oldValue = validFields[k].getValue(field.old);
                newValue = validFields[k].getValue(field.new);

                if (_.isObject(oldValue)) {
                    if (oldValue.toFetch) {
                        if (!this.entitiesToFetch.find(ei => ei.id === oldValue.toFetch.id)) {
                            this.entitiesToFetch.push(oldValue.toFetch);
                        }
                    }

                    oldValue = oldValue.value;
                }

                if (_.isObject(newValue)) {
                    if (newValue.toFetch) {
                        if (!this.entitiesToFetch.find(ei => ei.id === newValue.toFetch.id)) {
                            this.entitiesToFetch.push(newValue.toFetch);
                        }
                    }

                    newValue = newValue.value;
                }
            } else {
                fieldName = validFields[k];
                oldValue = field.old;
                newValue = field.new;
            }

            const isEmpty = (val) => {
                return (val === null) || _.isUndefined(val) || (val === '');
            }

            if (newValue !== oldValue) {
                if (isEmpty(newValue)) {
                    if (oldValue) {
                        rows.deleted.push({
                            name: fieldName,
                            value: oldValue
                        });
                    }
                } else if (isEmpty(oldValue)) {
                    rows.created.push({
                        name: fieldName,
                        value: newValue
                    });
                } else {
                    rows.updated.push({
                        name: fieldName,
                        old: oldValue,
                        new: newValue
                    });
                }
            }
        }

        return rows;
    }

    getEventDescription(eventType, eventData) {
        if (!_.isArray(eventData)) {
            eventData = [eventData];
        }

        let rows = [];

        const getFieldValue = (val) => {
            if (!_.isObject(val)) {
                return String(val);
            }

            return val.name;
        }

        let keyIdx = 0;

        switch (eventType) {
            case 'created':
                for (const r of eventData) {
                    if (rows.length > 0) {
                        rows.push(<br key={`kc_${keyIdx++}`}/>);
                    }

                    if (r.isAddition) {
                        rows.push(<span key={`kc_${keyIdx++}`}>{r.name} '<b>{getFieldValue(r.value)}</b>' was added</span>);
                    } else {
                        rows.push(<span key={`kc_${keyIdx++}`}>{r.name} was updated to '<b>{getFieldValue(r.value)}</b>'</span>);
                    }
                }
                break;

            case 'deleted':
                for (const r of eventData) {
                    if (rows.length > 0) {
                        rows.push(<br key={`kc_${keyIdx++}`}/>);
                    }

                    if (r.isAddition) {
                        rows.push(<span key={`kc_${keyIdx++}`}>{r.name} '<b>{getFieldValue(r.value)}</b>' was deleted</span>);
                    } else {
                        rows.push(<span key={`kc_${keyIdx++}`}>{r.name} value '<b>{getFieldValue(r.value)}</b>' was deleted</span>);
                    }
                }
                break;

            case 'updated':
                for (const r of eventData) {
                    if (rows.length > 0) {
                        rows.push(<br key={`kc_${keyIdx++}`}/>);
                    }

                    rows.push(<span key={`kc_${keyIdx++}`}>{r.name} was updated from '<b>{getFieldValue(r.old)}</b>' to '<b>{getFieldValue(r.new)}</b>'</span>);
                }
                break;
        }

        return rows;
    }

    render() {
        return (
            <div className={style.historyViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                    >
                        Close
                    </div>
                    <div className={style.title}>Change Log (Previous 120 Days)</div>
                    <div
                        className={style.downloadButton}
                        onClick={this.downloadCSV.bind(this)}
                    >
                        Download CSV
                    </div>
                </div>
                <div className={style.content}>
                    <div className={style.tableHeader}>
                        <div
                            className={style.colTitle}
                            style={{width: '100px'}}
                        >
                            Event
                        </div>
                        <div
                            className={style.colTitle}
                            style={{width: '390px'}}
                        >
                            Event Description
                        </div>
                        <div
                            className={style.colTitle}
                            style={{width: '100px'}}
                        >
                            Date + Time
                        </div>
                        <div
                            className={style.colTitle}
                            style={{width: '170px'}}
                        >
                            User
                        </div>
                    </div>
                    {!this.state.loading ?
                        (
                            <div className={style.tableRows}>
                                {this.state.rows.length > 0 ?
                                    (
                                        <div className={style.rows}>
                                            {this.state.rows.map((row, ridx) => {
                                                return (
                                                    <div
                                                        key={`row_${ridx}`}
                                                        className={style.row}
                                                    >
                                                        <div
                                                            className={style.cell}
                                                            style={{width: '100px'}}
                                                        >
                                                            {row.type}
                                                        </div>
                                                        <div
                                                            className={style.cell}
                                                            style={{width: '390px'}}
                                                            dangerouslySetInnerHTML={{__html: row.description}}>
                                                        </div>
                                                        <div
                                                            className={style.cell}
                                                            style={{
                                                                width: '100px',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-start'
                                                            }}
                                                        >
                                                            <div>{dateFormat.shortFormatWithYear(row.modified)},</div>
                                                            <div>{dateFormat.shortFormatTime(row.modified, true)}</div>
                                                        </div>
                                                        <div
                                                            className={style.cell}
                                                            style={{width: '170px'}}
                                                        >
                                                            {row.user}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div className={style.noRowsMessage}>No changes have been made in the last 120 days</div>
                                    )
                                }
                            </div>
                        ) : (
                            <div style={{height: '470px', marginTop: '30px'}}>
                                <LoadingIndicator/>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const HistoryView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'history-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <History
                entityType={this.options.entityType}
                entityId={this.options.entityId}
                entityName={this.options.entityName}
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default HistoryView;
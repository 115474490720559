import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'

import style from './new-app-menu.css'


class Logo extends React.Component {
    constructor(props) {
        super(props);

        const client = app.user.get('client');
        const logoData = client.logo;

        this.state = {
            logoSrc: null,
            logoAction: client.logo_action_url ?? '#dashboard'
        };

        if (!_.isEmpty(logoData)) {
            if (logoData.ext === '.svg') {
                const self = this;

                $.ajax({
                    url: logoData.url,
                    type: 'GET',
                    success: function (data) {
                        self.setState({
                            logoSrc: `data:image/svg+xml;base64,${btoa(data)}`
                        });
                    }
                });
            } else {
                this.state.logoSrc = logoData.url;
            }
        } else if (AppConfig.getValue('appLogo')) {
            this.state.logoSrc = AppConfig.getValue('appLogo');
        }
    }

    render() {
        if (!this.state.logoSrc) {
            return null;
        }

        return (
            <a href={this.state.logoAction}>
                <img
                    className={style.lImage}
                    src={this.state.logoSrc}
                />
            </a>
        );
    }
}


class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.sections = [
            [{
                id: 'create-new',
                icon: 'icon-plus',
                title: 'Create New',
                hidden: () => !AppConfig.getValue('app_nav_items.create_new.visible'),
                hover: () => {
                    let actions = [{
                        id: 'individual',
                        icon: 'icon-user',
                        title: TextManager.parseText('${ID_INDIVIDUAL, capitalize}')
                    }];

                    if (AppConfig.getValue('app_nav.create_new.organization.visible', true)) {
                        actions.push({
                            id: 'organization',
                            icon: 'icon-home',
                            title: TextManager.parseText('${ID_ORGANIZATION, capitalize}')
                        });
                    };

                    if (AppConfig.getValue('app_nav.create_new.deal.visible', true)) {
                        actions.push({
                            id: 'deal',
                            icon: 'icon-filter',
                            title: TextManager.parseText('${ID_DEAL, capitalize}')
                        });
                    }

                    if (!AppConfig.getValue('disableAppNavItemsForNonAdmin') &&
                        app.user.get('client').feature_tier !== 'starting' &&
                        AppConfig.getValue('app_nav.create_new.campaign.visible', true)) {
                        actions.push({
                            id: 'campaign',
                            icon: 'icon-campaign',
                            title: TextManager.parseText('${ID_CAMPAIGN, capitalize}')
                        });
                    }

                    if (AppConfig.getValue('app_nav.create_new.group.visible', true)) {
                        actions.push({
                            id: 'group',
                            icon: 'icon-list',
                            title: 'Group'
                        });
                    }

                    actions = actions.concat([{
                        id: 'task',
                        icon: 'icon-checkmark3',
                        title: 'Task'
                    }, {
                        id: 'note',
                        icon: 'icon-note',
                        title: 'Note'
                    }]);

                    if (AppConfig.getValue('app_nav.create_new.appointment.visible', false)) {
                        actions.push({
                            id: 'appointment',
                            icon: 'icon-calendar',
                            title: 'Appointment'
                        });
                    }

                    if (AppConfig.getValue('app_nav.create_new.report.visible', false)) {
                        actions.push({
                            id: 'report',
                            icon: 'icon-stack',
                            title: 'Reports'
                        });
                    }

                    return (
                        <div className={style.amCreateNewHover}>
                            {actions.map(action =>
                                <div
                                    key={action.id}
                                    className={style.cnItem}
                                    onClick={() => this.onCreateNewItemClick.bind(this)(action.id)}
                                >
                                    <div className={style.iContent}>
                                        <div className={action.icon}/>
                                        <div className={style.iTitle}>{action.title}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                }
            }, {
                id: 'dashboard',
                icon: 'icon-gauge2',
                title: 'Dashboard',
                event: 'dashboard'
            }]
        ];

        let contactsEtAl = [{
            id: 'tasks',
            icon: 'icon-checkmark3',
            title: 'Tasks',
            event: 'tasks:show',
            hidden: () => AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.tasks.visible')
        }, {
            id: 'appointments',
            icon: 'icon-calendar',
            title: 'Appointments',
            event: 'appointments:show',
            hidden: () => !AppConfig.getValue('app_nav_items.appointments.visible')
        }, {
            id: 'activities',
            icon: 'icon-note',
            title: 'Activities',
            event: 'activities:show',
            hidden: () => AppConfig.getValue('disableActivitiesSection') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.activities.visible')
        }, {
            id: 'groups',
            icon: 'icon-list',
            title: 'Contacts',
            event: 'contacts',
            hidden: () => AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.contacts.visible')
        }];

        if (!AppConfig.getValue('logged_user.is_campaigns_only') && AppConfig.getValue('app_nav_items.deals.visible')) {
            const dealsSectionsStr = (app.user.get('client').preferences || {}).deals_sections;

            if (dealsSectionsStr) {
                const dealsSections = JSON.parse(dealsSectionsStr);
                let newItems = [];

                for (const section of dealsSections) {
                    contactsEtAl.push({
                        id: `deals-section-${section.id}`,
                        icon: section.icon || 'icon-funnel-sm',
                        title: section.name,
                        event: 'opportunities:funnel',
                        eventParams: section.id,
                        hidden: () => !AppConfig.getValue('app_nav_items.deals_section.visible', false, section.id)
                    });
                }
            } else {
                contactsEtAl.push({
                    id: 'deals',
                    icon: 'icon-funnel-sm',
                    title: AppConfig.getValue('deals.app_nav_item.name', TextManager.parseText('${ID_DEAL, plural, capitalize}')),
                    event: 'opportunities:funnel'
                });
            }
        }

        contactsEtAl.push({
            id: 'messages',
            icon: 'icon-chat',
            title: 'Messages',
            event: 'messages:show',
            hidden: () => !AppConfig.getValue('conversations.visible') || !AppConfig.getValue('app_nav_items.messages.visible')
        });

        this.sections.push(contactsEtAl);

        this.sections.push([{
            id: 'marketing',
            icon: 'icon-email',
            title: 'Marketing',
            event: 'marketing:show',
            hidden: () => AppConfig.getValue('disableMarketingSection') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.marketing.visible')
        }, {
            id: 'campaigns',
            icon: 'icon-campaign',
            title: TextManager.parseText('${ID_CAMPAIGN, plural, capitalize}'),
            event: 'campaigns:show',
            hidden: () => AppConfig.getValue('disableAppNavItemsForNonAdmin') || !AppConfig.getValue('app_nav_items.campaigns.visible'),
            disabled: () => _.contains(['starting'], app.user.get('client').feature_tier)
        }, {
            id: 'automation',
            icon: 'icon-bolt',
            title: 'Automations',
            event: 'automation:list',
            hidden: () => AppConfig.getValue('disableAppNavItemsForNonAdmin') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.automations.visible')
        }, {
            id: 'content',
            icon: 'icon-folder-open',
            title: 'Content',
            event: 'content:show',
            hidden: () => AppConfig.getValue('disableContentAppNavItemForNonAdmin') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.content.visible'),
            disabled: () => _.contains(['starting', 'growing'], app.user.get('client').feature_tier)
        }, {
            id: 'texts',
            icon: 'icon-chat',
            title: 'Texts',
            event: 'texts:show',
            hidden: () => AppConfig.getValue('disableTextsSection') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.texts.visible'),
            // todo: add badge
        }, {
            id: 'forecasts',
            icon: 'icon-target',
            title: 'Forecasts',
            event: 'forecasts:list',
            hidden: () => AppConfig.getValue('disableAppNavItemsForNonAdmin') || AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.forecasts.visible'),
            disabled: () => _.contains(['starting', 'growing'], app.user.get('client').feature_tier)
        }, {
            id: 'social',
            icon: 'icon-comments',
            title: 'Social',
            event: 'social:feed',
            hidden: () => AppConfig.getValue('disableSocialAndWebSections') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.social.visible'),
        }, {
            id: 'web',
            icon: 'icon-globe',
            title: 'Web',
            event: 'web:show',
            hidden: () => AppConfig.getValue('disableSocialAndWebSections') || AppConfig.getValue('logged_user.is_campaigns_only') || !AppConfig.getValue('app_nav_items.web.visible'),
        }, {
            id: 'reports',
            icon: 'icon-stack',
            title: 'Reports',
            event: 'reports:show',
            hidden: () => !AppConfig.getValue('app_nav_items.reports.visible')
        }, {
            id: 'portal',
            icon: 'icon-location',
            title: 'Portal',
            event: 'portal:show',
            hidden: () => !AppConfig.getValue('app_nav_items.portal.visible')
        }]);

        this.state = {
            selectedItemId: null
        };
    }

    onCreateNewItemClick(itemId) {
        vent.trigger('search:close');

        switch(itemId) {
            case 'individual':
                 app.dirtyModelHandler.confirm(this, function() {
                     vent.trigger('individuals:detail:new', {});
                 });
                 break;

            case 'organization':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('organizations:detail:new', {});
                });
                break;

            case 'deal':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('opportunities:detail:new', {});
                });
                break;

            case 'campaign':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('campaigns:new', {});
                });
                break;

            case 'group':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('groups:detail:new', {});
                });
                break;

            case 'task':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('quick:add:task', null, {
                        gotoRelatedItem: true
                    });
                });
                break;

            case 'note':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('quick:add:note');
                });
                break;

            case 'appointment':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('quick:add:appointment');
                });
                break;

            case 'report':
                app.dirtyModelHandler.confirm(this, function() {
                    vent.trigger('quick:add:report');
                });
                break;
        }
    }

    onItemClick(item) {
        if (!item.event) {
            return;
        }

        if (item.disabled && item.disabled()) {
            return;
        }

        const self = this;

        app.dirtyModelHandler.confirm(this, function() {
            vent.trigger('menu-item:click');
            vent.trigger(item.event, {
                end: true,
                replace: true,
                eventParams: item.eventParams
            });

            self.selectItem(item.id);
        });
    }

    selectItem(itemId) {
        this.setState({
            selectedItemId: itemId
        });
    }

    render() {
        this.components = {};

        return (
            <div className={style.appMenu}>
                <div className={style.amLogo}>
                    <Logo/>
                </div>

                {this.sections.map((section, gidx) => {
                    return (
                        <div
                            key={`section_${gidx}`}
                            className={style.amSection}
                        >
                            {section.map((item, iidx) => {
                                if (item.hidden && item.hidden()) {
                                    return null;
                                }

                                if (item.disabled && item.disabled()) {
                                    return (
                                        <div
                                            key={`item_${iidx}`}
                                            className={`${style.sItemContainer} ${style.cDisabled}`}
                                        >
                                            <div
                                                className={style.icItem}
                                            >
                                                <div className={item.icon}/>
                                            </div>
                                            <div
                                                className={style.icHover}
                                            >
                                                <div>Upgrade account to access {item.title}</div>
                                            </div>
                                        </div>
                                    );
                                }

                                return (
                                    <div
                                        key={`item_${iidx}`}
                                        ref={(el) => {this.components[item.id] = el}}
                                        className={`
                                            ${style.sItemContainer}
                                            ${item.id === this.state.selectedItemId ? style.cSelected : ''}
                                        `}
                                    >
                                        <div
                                            className={style.icItem}
                                            onClick={() => this.onItemClick.bind(this)(item)}
                                        >
                                            <div className={item.icon}/>
                                        </div>
                                        {item.hover ? (
                                            <div className={style.icContentHover}>
                                                {item.hover()}
                                            </div>
                                        ) : (
                                            <div
                                                className={style.icHover}
                                            >
                                                <div>{item.title}</div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}


const NewAppMenuView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        const self = this;

        this.listenTo(vent, 'appMenu:highlightItem', function(itemId) {
            self.component.selectItem(itemId);
        });

        ReactDOM.render(
            <Menu
                ref={(el) => this.component = el}
            />,
            this.$el.get(0)
        );
    }
});

export default NewAppMenuView;

import IndividualModel from 'js/models/contact'


var Lead = IndividualModel.extend({
    name: 'Lead',
    urlRoot: '/leads',  // impossible to access at root
    apiType: 'salesseek.contacts.models.lead.Lead'
});
Lead.fromString = function (string) {
    var parts = string.split(' ', 1),
        first_name = parts[0],
        last_name = parts[1];
    return new Lead({first_name: first_name, last_name: last_name});
};
export default Lead;

import _ from 'underscore'
import $ from 'jquery'
import d3 from 'd3'
import D3Utilities from 'js/d3/utilities'
import D3WidgetsStyle from 'js/d3/widgets_style'


function leadqualitychart() {

    var y_axis_padding = 20;
    var row_height = 24;
    var row_padding = 0.2;
    var text_to_rect_x_offset;

    var x = 0,
        y = 0,
        padding = {top: 10, right: 20, bottom: 10, left: 20},
        width = 300 - padding.left - padding.right,
        height = 380 - padding.top - padding.bottom,
        data = {},
        average = 0,
        initThings = true,
        showFullInfo = false,
        hideLabels = true,
        emptySVG = false;
    var rectHighlighted;
    var tickHighlighted;
    var rectSelected;
    var tickSelected;
    var idSelected;

    var normalizeVal = function(d) {
        var h = Math.max(d.total > 0) ? (d.valid / d.total): 0;
        return h;
    };

    var textEllipsis = function(text, maxWidth) {
        text.each(function() {
            D3Utilities.cropText(this, maxWidth, true, 'data-original-text');
        });
    };

    var getAxisTick = function(svg, id) {
        var ticks = svg.selectAll('.tick');
        var tick = null;

        ticks.each(function(d) {
            if (d === id) {
                tick = d3.select(this);
                return 0;
            }
        });

        return tick;
    };

    var highlightBar = function(svg, id, t, bar) {
        var active = id ? true : false;

        svg.classed('row-highlighted', active);

        if (rectHighlighted) {
            rectHighlighted.classed('highlighted', false);
        }

        rectHighlighted = bar;

        if (rectHighlighted) {
            rectHighlighted.classed('highlighted', true);
        }

        // ...
        if (tickHighlighted) {
            tickHighlighted.classed('highlighted', false);
        }

        tickHighlighted = getAxisTick(svg, id);

        if (tickHighlighted) {
            tickHighlighted.classed('highlighted', true);
        }

        if (t) {
            t.trigger('group_highlighted', id);
        }
    };

    function chart(selection) {

        var barX = 0;

        var update = function() {

            // Update sizing variables
            height = (row_height * (data.length + 1));
            text_to_rect_x_offset = row_height/4;

            var barY = d3.scale.ordinal()
                .domain(data.map(function(d) { return d.group.id; }))
                .rangeRoundBands([0, row_height * data.length], row_padding, 0);

            var barHeight = function() {
                if (data.length > 0) {
                    return barY.rangeBand();
                } else {
                    return height;
                }
            };

            var barWidth = d3.scale.linear()
                .domain([0, 1])
                .range([1, Math.max(width, 1)]);

            var yAxis = d3.svg.axis()
                .scale(barY)
                .orient('left')
                .tickPadding(y_axis_padding)
                .tickSize(0,0) // Remove black 'domain' bar
                .tickFormat(function(d,i) { return data[i].group.name; });

            // ...
            var svg = selection.select('svg');

            if (emptySVG) {
                svg.select('*').remove();
                initThings = true;
                emptySVG = false;
                rectHighlighted = null;
            }

            if (initThings) {
                svg
                    .append('g')
                        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
                        .attr('class', 'barchart')
                        .append('g')
                            .attr('class', 'x-axis')
                            .attr('transform', 'translate(0,' + height + ')')
                            .attr('display', 'none');

                svg.select('g')
                    .append('g')
                        .attr('class', 'y-axis');
            }

            svg
                .classed('expanded', showFullInfo)
                .classed('hide-labels', hideLabels)
                .attr('width', width + padding.left + padding.right)
                .attr('height', height + padding.top + padding.bottom);

            var barchart = svg.select('g.barchart');
            var bars = barchart.selectAll('.info-rect')
                .data(data, function(d) {
                    return d.group.id;
                });

            barchart
                .transition()
                .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')');

            // Apply text ellipsis to long yaxis labels
            svg
                .select('g.y-axis')
                .call(yAxis)
                .selectAll(".tick text")
                .call(textEllipsis, padding.left - y_axis_padding*2);

            bars.enter().append('g')
                .attr('class', 'info-rect row')
                .attr('id', function(d) { return 'p-' + d.group.id; })
                .call(function() {
                    this.append('rect')
                        .attr('class', 'group-rect bar')
                        .attr('x', barX)
                        .attr('y', function(d) { return barY(d.group.id); })
                        .attr('width', 0)
                        .attr('height', barHeight)
                        .attr('style', function(d, i) { return D3WidgetsStyle.groupStyle(i); });

                    this.append('text')
                        .attr('class', 'text-percentage')
                        .attr('text-anchor', 'start')
                        .attr('x', barX + text_to_rect_x_offset)
                        .attr('y', function(d) {
                            return barY(d.group.id) + Math.floor(barHeight()/2);
                        })
                        .attr('y', function(d) { return barY(d.group.id) + Math.ceil(barHeight()/4); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');

                    this.append('text')
                        .attr('class', 'text-values')
                        .attr('text-anchor', 'start')
                        .attr('x', barX + text_to_rect_x_offset)
                        .attr('y', function(d) { return barY(d.group.id) + Math.floor(barHeight() * 0.75); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');

                    // control rect
                    var rowPadding = row_height * row_padding;

                    this.append('rect')
                        .attr('class', 'control-rect')
                        .attr('id', function(d) { return d.group.id; })
                        .attr('x', -padding.left)
                        .attr('y', function(d) { return (barY(d.group.id) - (rowPadding * 0.5)); })
                        .attr('width', width + padding.left)
                        .attr('height', barHeight() + rowPadding)
                        .style('fill-opacity', 0);
                });

            if (initThings) {
                // average rect
                svg.select('g')
                    .append('rect')
                        .attr('class', 'average-rect')
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr('width', 0)
                        .attr('height', height);

                svg.select('g')
                    .append('text')
                        .attr('class', 'average-text')
                        .text('Average')
                        .attr('x', 0)
                        .attr('y', function() { return height - barHeight() + Math.ceil( barHeight() * 0.75 ); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');

                svg.select('g')
                    .append('text')
                        .attr('class', 'average-percentage')
                        .attr('x', 0)
                        .attr('y', function() { return height - Math.ceil(barHeight()*0.75); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');

                initThings = false;
            }

            bars.exit()
                .remove();

            // update bar values
            bars.select('.group-rect')
                .transition()
                .attr('y', function(d) { return barY(d.group.id); })
                .attr('width', function(d) { return barWidth(normalizeVal(d)); })
                .attr('height', barHeight);

            bars.select('.text-percentage')
                .transition()
                .attr('x', function(d) { return barWidth(normalizeVal(d)) + text_to_rect_x_offset; })
                .attr('y', function(d) { return barY(d.group.id) + Math.ceil( barHeight()/4 ); })
                .text(function(d) {
                    if (d.total > 0) {
                        return Math.round( (d.valid / d.total) * 100 ) + '%';
                    }
                    return '0%';
                });

            bars.select('.text-values')
                .transition()
                .attr('x', function(d) { return barWidth(normalizeVal(d)) + text_to_rect_x_offset; })
                .attr('y', function(d) { return barY(d.group.id) + Math.ceil( barHeight() * 0.75 ); })
                .text(function(d) { return d.valid + ' / ' + d.total; });

            // average update
            svg
                .select('.average-rect')
                .transition()
                .attr('width', barWidth(average))
                .attr('height', height);

            svg
                .select('.average-text')
                .transition()
                .attr('x', barWidth(average) + text_to_rect_x_offset)
                .attr('y', function() { return height - barHeight() + Math.ceil( barHeight() * 0.75 ); });

            svg
                .select('.average-percentage')
                .transition()
                .text( Math.round( average * 100 ) + '%')
                .attr('x', barWidth(average) + text_to_rect_x_offset)
                .attr('y', function() { return height - Math.floor(barHeight()*0.75); });

            // control
            bars.select('.control-rect')
                .on('mouseover', function() {
                    if (!showFullInfo) {
                        highlightBar(svg, this.id, $(this), svg.select('#p-' + this.id));
                    }
                })
                .on('click', function() {
                    if (!showFullInfo) {
                        d3.event.stopPropagation();
                        $(this).trigger('group_selected', this.id);
                    }
                })
                .on('mouseout', function() {
                    if (!showFullInfo) {
                        highlightBar(svg, null, $(this));
                    }
                });

            var rowPadding = row_height * row_padding;

            bars.select('control-rect')
                .attr('x', -padding.left)
                .attr('y', function(d) { return (barY(d.group.id) - (rowPadding * 0.5)); })
                .attr('width', width + padding.left)
                .attr('height', barHeight() + rowPadding);

            selection
                .on('click', function() {
                    if (!showFullInfo) {
                        $(this).trigger('group_selected', null);
                    }
                });
        };

        update();
    }

    chart.toggleLabels = function(_) {
        if (!arguments.length || !_) {
            hideLabels = true;
        }
        else {
            hideLabels = false;
            // padding.left = 200;
        }
        return chart;
    };

    chart.expand = function(_) {
        if (!arguments.length || !_) {
            row_height = 24;
            showFullInfo = false;
        }
        else {
            row_height = 48;
            showFullInfo = true;
        }
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        if (showFullInfo) {
            // padding.left = Math.min(200, Math.round(_ * 0.3));
            padding.right = Math.max(60, Math.round(_ * 0.1)) + text_to_rect_x_offset + 20;
        }
        else {
            padding.left = 20;
            padding.right = 20;
        }
        if (!hideLabels) {
            padding.left = Math.min(220, Math.round(_ * 0.5));
        }
        width = _ - padding.left - padding.right;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _ - padding.top - padding.bottom;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;
        var m = 0;

        for (var i = 0; i < data.length; ++i) {
            m += normalizeVal(data[i]);
        }

        if (data.length) {
            average = (m / data.length);
        }
        else {
            average = 0;
        }

        return chart;
    };

    chart.empty = function() {
        emptySVG = true;
    };

    chart.highlightSingleBar = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        // ...
        var svg = selection.select('svg');
        highlightBar(svg, id, null, svg.select('#p-' + id));
    };

    chart.selectSingleBar = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        // ...
        var svg = selection.select('svg');

        if (rectSelected) {
            rectSelected.classed('active', false);
            svg.classed('row-active', false);
            rectSelected = null;
        }

        if (tickSelected) {
            tickSelected.classed('active', false);
            tickSelected = null;
        }

        if (id && (id !== idSelected)) {
            idSelected = id;

            rectSelected = svg.select('#p-' + id);
            rectSelected.classed('active', true);
            svg.classed('row-active', true);

            tickSelected = getAxisTick(svg, id);
            tickSelected.classed('active', true);
        }
        else {
            idSelected = null;
        }
    };

    return chart;
}

export default leadqualitychart;

import $ from 'jquery'
import _ from 'underscore'
import classnames from 'classnames'
import vent from 'js/vent';
import Utilities from 'js/utils/utilities'

import React from 'react';
import dateFormat from 'js/utils/date-format';
import AppConfig from 'app/app-config';
import {NewSelect} from 'js/react_views/widgets/select';
import filterCommon from 'js/views/filters/common'

import styles from './iod-table.css';

class CommunicationCellView extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();
        this.clickCallback(ev, this.props.row);
    }

    render() {
        let row = this.props.row;
        let value = this.props.value || {
                communications: [],
                clickCallback: () => {}
            };

        this.clickCallback = value.clickCallback;

        return (
            <div className={styles.CommunicationCellView}>
                {
                    value.communications.map((communication, idx) => {
                        return <div key={row.id + 'communication' + idx}>
                            <span className={styles.nameContainer}>
                                {communication.name}
                            </span>
                            <span onClick={this.handleClick.bind(this)}>{communication.value}</span>
                        </div>;
                    })
                }
            </div>
        )
    }
}

class LocationCellView extends React.Component {
    render() {
        let row = this.props.row;
        let locations = this.props.value || [];
        let counter = 0;

        return (
            <div className={styles.LocationCellView}>
                {
                    locations.map((location) => {
                        counter = counter + 1;
                        return <div key={row.id + 'location' + counter}>
                            <span className={styles.nameContainer}>
                                {location.name}
                            </span>
                            <span>{location.address}</span>
                        </div>;
                    })
                }
            </div>
        )
    }
}

class TextCellView extends React.Component {
    render() {
        const row = this.props.row;

        if (row[this.props.column.id]) {
            return (
                <div>
                    <div>{row[this.props.column.id]}</div>
                </div>
            )
        }

        return null;
    }
}

class CommentCellView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let value = this.props.value || {
                value: '',
                trimmedValue: ''
            };

        if (Utilities.isHTML(value.value)) {
            value.value = Utilities.getTextFromHTMLContent(value.value)

            value.value = Utilities.replaceAll(value.value, '<br>', '');
            value.value = Utilities.replaceAll(value.value, '↵', '');
        }

        let popUp = null;

        if (this.state.popUp) {
            popUp = <PopoverReactView value={value.value} sanitizedValue={value.sanitizedValue} top={this.state.top}/>;
        }

        return (
            <div className={styles.CommentCellView} onMouseEnter={(ev) => PopoverReactView.prototype.showPopUp.call(this, ev)} onMouseLeave={PopoverReactView.prototype.hidePopUp.bind(this)}>
                {value.trimmedValue}
                {popUp}
            </div>
        )
    }
}

class ActivityCellView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let value = this.props.value || {
                value: '',
                trimmedActivityData: ''
            };

        let popUp = null;

        if (this.state.popUp) {
            popUp = <PopoverReactView value={value.value} sanitizedValue={value.sanitizedValue} top={this.state.top} raw={true}/>;
        }

        return (
            <div className={styles.ActivityCellView} onMouseEnter={(ev) => PopoverReactView.prototype.showPopUp.call(this, ev)} onMouseLeave={PopoverReactView.prototype.hidePopUp.bind(this)}>
                {value.trimmedActivityData}
                {popUp}
            </div>
        )
    }
}

class PhotoUrlCellView extends React.Component {
    render() {
        let row = this.props.row;
        let photoUrl = row.photo_url || '';

        return (
            <div>
                <div className={styles.PhotoUrlCellView}>
                    <div className={styles.iconContainer}>
                        <i className={"icon-user " + styles.userIcon}></i>
                    </div>
                    <div className={styles.thumb} style={{ backgroundImage: 'url(' + photoUrl + ')' }}>
                    </div>
                </div>
            </div>
        )
    }
}

class TagsUrlCellView extends React.Component {
    render() {
        let row = this.props.row;
        let tags = row.tags || [];

        let dataTagTitle = ''
        let tagString = tags.map(tag => tag.name).join(', ')

        if (tagString.length >= 25) {
            dataTagTitle = tagString
            tagString = tagString.substring(0, 25) + '...'
        }

        return (
            <div data-toggle-tooltip="bottom" title={dataTagTitle}>
                {tagString}
            </div>
        )
    }
}

class FunnelsUrlCellView extends React.Component {
    render() {
        let row = this.props.row;
        let funnels = row.funnels || [];

        let dataFunnelTitle = ''
        let funnelString = funnels.map(funnel => {
            let regionString = funnel.region != undefined && funnel.region ? ` (${funnel.region.name})` : ""
            return funnel.name.concat(regionString) 
        }).join(', ')

        if (funnelString.length >= 25) {
            dataFunnelTitle = funnelString
            funnelString = funnelString.substring(0, 25) + '...'
        }



        return (
            <div data-toggle-tooltip="bottom" title={dataFunnelTitle}>
                {funnelString}
            </div>
        )
    }
}

class RegionsUrlCellView extends React.Component {
    render() {
        let row = this.props.row;
        let regions = row.regions || [];

        let dataRegionTitle = ''
        let regionString = regions.map(region => region.name).join(', ')

        if (regionString.length >= 25) {
            dataRegionTitle = regionString
            regionString = regionString.substring(0, 25) + '...'
        }

        return (
            <div data-toggle-tooltip="bottom" title={dataRegionTitle}>
                {regionString}
            </div>
        )
    }
}

class LongStringWithTooltipCellView extends React.Component {
    render() {
        const row = this.props.row;
        const columnId = this.props.column.id;
        const text = row[columnId];

        return (
            <div data-toggle-tooltip="bottom" title={text} className={styles.wrapper}>
                {text}
            </div>
        )
    }
}

class CompletedCellView extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();
        if (this.props.row.completed.clickCallback) {
            this.props.row.completed.clickCallback(this.props.row.id, !this.props.row.completed.completed, this.props.row.related?.related);
        }
    }

    render() {
        let row = this.props.row;

        const css = `
            .style-completed-cell-view {
                height: 18px;
                width: 18px;
                border-radius: 3px;
                cursor: pointer;
                transition: all 100ms ease;
                user-select: none;
                border: 1px solid #ff283a;
                margin-left: 30px;
            }

            .style-completed-cell-view i {
                display: none;
                line-height: 18px;
                text-align: center;
                color: #43d350;
            }

            .style-completed-cell-view:hover {
                border-color: #43d350;
            }

            .style-completed-cell-view:hover i{
                display: block;
            }

            .style-completed-cell-view.completed {
                background-color: #43d350;
                border-color: #43d350;
                opacity: 0.5;
            }

            .style-completed-cell-view.completed i {
                display: block;
                color: #ffffff;
            }

            .style-completed-cell-view.completed:hover {
                opacity: 1;
            }
        `;

        return (
            <div className={"style-completed-cell-view " + (row.completed.completed ? "completed" : "")} onClick={this.handleClick.bind(this)}>
                <style>{css}</style>
                <i className="icon-checkmark3"/>
            </div>
        )
    }
}

class RelatedCellView extends React.Component {
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        if (this.props.row.related.clickCallback) {
            this.props.row.related.clickCallback();
        }
    }

    render() {
        if (!this.props.row.related) {
            return null;
        }

        return (
            <a href={this.props.row.related.url} onClick={this.handleClick.bind(this)}>
                {this.props.row.related.related.name}
            </a>
        )
    }
}

class ActivityTypeCellView extends React.Component {
    render() {
        let value = filterCommon.getActivityTypeValue(this.props.value);

        return (
            <div className={styles.Wrapper}>
                {value ? value.value : this.props.value}
            </div>
        )
    }
}

class OrganizationNextTaskDueDateCellView extends React.Component {
    render() {
        const row = this.props.row;

        if (row['organization.next_direct_task.due_date']) {
            const today = new Date();

            return (
                <div className={styles.DueDateCellView}>
                    <div className={(row['organization.next_direct_task.due_date'] < today) ? styles.overdue : ""}>{dateFormat.entityInformationFormat(row['organization.next_direct_task.due_date'])}</div>
                </div>
            )
        }

        return null;
    }
}

class NextTaskDueDateCellView extends React.Component {
    render() {
        const row = this.props.row;

        if (row['next_direct_task.due_date']) {
            const today = new Date();

            return (
                <div className={styles.DueDateCellView}>
                    <div className={(row['next_direct_task.due_date'] < today) ? styles.overdue : ""}>{dateFormat.entityInformationFormat(row['next_direct_task.due_date'])}</div>
                </div>
            )
        }

        return null;
    }
}

class DueDateCellView extends React.Component {
    render() {
        const row = this.props.row;

        if (row.due_date) {
            const today = new Date();

            return (
                <div className={styles.DueDateCellView}>
                    <div className={(row.due_date < today) ? styles.overdue : ""}>{row.due_date_formatted}</div>
                </div>
            )
        }

        return null;
    }
}

class DateCellView extends React.Component {
    render() {
        const row = this.props.row;
        const formatter = this.props.column.formatter || dateFormat.entityInformationFormat;

        if (row[this.props.column.id]) {
            return (
                <div>
                    <div>{formatter(row[this.props.column.id])}</div>
                </div>
            )
        }

        return null;
    }
}

class CampaignIconView extends React.Component {
    render() {
        let iconClass;
        const row = this.props.row;

        if ((row.status === "ready" || row.status === "draft") && row.used_in_automations) {
            iconClass = 'icon-automated-email';
        }
        else {
            switch (row.campaign_type) {
                case 'direct':
                    iconClass = 'icon-direct-email';
                    break;
                case 'campaign':
                    iconClass = 'icon-campaign-email';
                    break;
                case 'message':
                    iconClass = 'icon-campaign-sms';
                    break;
            }
        }
        return (
            <div><i className={iconClass}></i></div>
        )
    }
}

class CampaignMenuView extends React.Component {
    handleClick(ev) {
        // Allow user to see default browser contextmenu with shift + click
        if (ev.shiftKey) {
            return;
        }

        ev.preventDefault();
        ev.stopPropagation();

        this.props.handleCampaignMenuClick(ev, this.props.row.id);
    }

    render() {
        return (
            <div>
                <div className="menu-container"></div>
                <a className="item-menu-toggle" onClick={this.handleClick.bind(this)}> <i className="icon-dotdotdot"></i> </a>
            </div>
        )
    }
}

class GroupLinkCellView extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();
    }

    render() {
        return (
            this.props.row.to_group &&
                <div className={styles.wrapper}>
                    <a onClick={this.handleClick.bind(this)} href={"/#contacts/group/individuals/" + this.props.row.to_group.id}>
                        {this.props.row.to_group.name}
                    </a>
                </div>
        )
    }
}

class CampaignStatCellView extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();

        if (this.props.column.smartGroupName) {
            if (this.props.row.campaign_type !== 'message' || ['email_event_stats_send', 'email_event_stats_delivered'].indexOf(this.props.column.id) !== -1) {
                this.props.handleCampaignStatClick(this.props.column.smartGroupName, this.props.row.id, this.props.row.name);
            }
        }
    }

    render() {
        return (
            <div onClick={this.handleClick.bind(this)} style={this.props.column.style} className={styles.wrapper}>{this.props.value}</div>
        )
    }
}

class PercentageCellView extends React.Component {
    render() {
        let value = this.props.value;

        return (
            <div className={styles.Wrapper}>
                {value}%
            </div>
        )
    }
}

class WebsiteCellView extends React.Component {
    handleClick(ev) {
        if (this.props.value[0]) {
            ev.stopPropagation();
            window.open(Utilities.httpUrl(this.props.value[0]), '_blank');
        }
    }

    render() {
        let value = this.props.value ? this.props.value[0] : '';

        return (
            <div className={styles.CommunicationCellView}>
                <span onClick={this.handleClick.bind(this)}>{value}</span>
            </div>
        )
    }
}

class UnsubscribedCellView extends React.Component {
    render() {
        let value = this.props.value;
        value = value ? 'No' : 'Yes';

        return (
            <div>
                {value}
            </div>
        )
    }
}

class ReplyButtonCellView extends React.Component {
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        vent.trigger('quick:create:message', this.props.row.related.related.id, this.props.row.params.from_phone)
    }

    render() {
        if (this.props.row.related && this.props.row.related.related && !this.props.row.related.related.unsubscribed_all_messages &&
            this.props.row.params && this.props.row.params.from_phone) {
            return (
                <div className={styles.ReplyButtonCellView} onClick={(ev) => this.handleClick(ev)}>
                    Text
                </div>
            )
        }

        return null
    }
}

class DealtButtonCellView extends React.Component {
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        let params = _.clone(this.props.row.params);
        params.dealt = this.props.row.dealt.dealt === 'true' ? 'false' : 'true';
        this.props.row.dealt.clickCallback(this.props.row.id, params);
    }

    render() {
        const dealt = this.props.row.dealt.dealt === 'true' ? true : false;

        const buttonClasses = classnames({
            'icon-checkmark': dealt,
            'icon-chat': !dealt
        });
        const style = {
            color: dealt ? 'green' : 'red'
        };

        return (
            <div className={styles.DealtButtonCellView} onClick={(ev) => this.handleClick(ev)}>
                <i className={buttonClasses} style={style}/>
            </div>
        )
    }
}

class TimeCellView extends React.Component {
    render() {
        return (
            <div>{dateFormat.shortFormatTime(dateFormat.parseDate(this.props.row.time))}</div>
        )
    }
}

class DynamicDropdownCellView extends React.Component {
    getDropdownData(callback) {
        if (this.props.column.dropdownInfo.data) {
            this.props.column.dropdownInfo.data(this.props.row, callback);
        }
    }

    render() {
        return (
            <div>
                <NewSelect
                    value={null}
                    data={this.getDropdownData.bind(this)}
                    onSelect={(items) => this.props.column.dropdownInfo.onSelect(this.props.row, items)}
                    text={this.props.column.dropdownInfo.text || 'text'}
                />
            </div>
        );
    }
}

class MarketingAssignButtonCellView extends React.Component {
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.handleCellButtonClick(this.props.row, this.props.column.id);
    }

    render() {
        return (
            <div className={styles.MarketingAssignButtonCellView} onClick={(ev) => this.handleClick(ev)}>
                Assign
            </div>
        );
    }
}

class MarketingIgnoreButtonCellView extends React.Component {
    handleClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.handleCellButtonClick(this.props.row, this.props.column.id);
    }

    render() {
        return (
            <div className={styles.MarketingIgnoreButtonCellView} onClick={(ev) => this.handleClick(ev)}>
                Ignore
            </div>
        );
    }
}

const COLUMNS = {
    individuals: {
        photo_url: {
            component: PhotoUrlCellView
        },
        comments: {
            component: CommentCellView
        },
        email: {
            component: CommunicationCellView
        },
        phone: {
            component: CommunicationCellView
        },
        locations: {
            component: LocationCellView
        },
        last_direct_activity: {
            component: ActivityCellView
        },
        pinned_activity: {
            component: ActivityCellView
        },
        unsubscribed_all: {
            component: UnsubscribedCellView
        },
        unsubscribed_all_messages: {
            component: UnsubscribedCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        funnels: {
            component: FunnelsUrlCellView
        },
        regions: {
            component: RegionsUrlCellView
        },
        'next_direct_task.due_date': {
            component: NextTaskDueDateCellView
        },
        'organization.comments': {
            component: CommentCellView
        },
        'organization.locations': {
            component: LocationCellView
        },
        'organization.email': {
            component: CommunicationCellView
        },
        'organization.phone': {
            component: CommunicationCellView
        },
        'organization.last_direct_activity': {
            component: ActivityCellView
        },
        'organization.pinned_activity': {
            component: ActivityCellView
        },
        'organization.website': {
            component: WebsiteCellView
        },
        'organization.next_direct_task.due_date': {
            component: OrganizationNextTaskDueDateCellView
        },
        'marketing.recommended_campaign': {
            component: DynamicDropdownCellView
        },
        'marketing.assign': {
            component: MarketingAssignButtonCellView
        },
        'marketing.ignore': {
            component: MarketingIgnoreButtonCellView
        }
    },
    opportunities: {
        comments: {
            component: CommentCellView
        },
        email: {
            component: CommunicationCellView
        },
        phone: {
            component: CommunicationCellView
        },
        locations: {
            component: LocationCellView
        },
        last_direct_activity: {
            component: ActivityCellView
        },
        pinned_activity: {
            component: ActivityCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        'organization.comments': {
            component: CommentCellView
        },
        'organization.locations': {
            component: LocationCellView
        },
        'organization.email': {
            component: CommunicationCellView
        },
        'organization.phone': {
            component: CommunicationCellView
        },
        'organization.last_direct_activity': {
            component: ActivityCellView
        },
        'organization.pinned_activity': {
            component: ActivityCellView
        },
        'organization.website': {
            component: WebsiteCellView
        }
    },
    organizations: {
        photo_url: {
            component: PhotoUrlCellView
        },
        comments: {
            component: CommentCellView
        },
        email: {
            component: CommunicationCellView
        },
        phone: {
            component: CommunicationCellView
        },
        website: {
            component: WebsiteCellView
        },
        locations: {
            component: LocationCellView
        },
        last_direct_activity: {
            component: ActivityCellView
        },
        pinned_activity: {
            component: ActivityCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        funnels: {
            component: FunnelsUrlCellView
        },
        regions: {
            component: RegionsUrlCellView
        },
    },
    activities: {
        note: {
            component: CommentCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        funnels: {
            component: FunnelsUrlCellView
        },
        regions: {
            component: RegionsUrlCellView
        },
        target_date: {
            component: DueDateCellView
        },
        related: {
            component: RelatedCellView
        },
        type: {
            component: ActivityTypeCellView
        }
    },
    texts: {
        note: {
            component: CommentCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        related: {
            component: RelatedCellView
        },
        reply: {
            component: ReplyButtonCellView
        },
        dealt: {
            component: DealtButtonCellView
        },
        time: {
            component: TimeCellView
        }
    },
    messages: {
        created: {
            component: DateCellView
        },
        from_name: {
            component: TextCellView
        },
        note: {
            component: CommentCellView
        }
    },
    tasks: {
        completed: {
            component: CompletedCellView
        },
        related: {
            component: RelatedCellView
        },
        text: {
            component: CommentCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        funnels: {
            component: FunnelsUrlCellView
        },
        regions: {
            component: RegionsUrlCellView
        },
        due_date: {
            component: DueDateCellView
        }
    },
    campaigns: {
        to_group: {
            component: GroupLinkCellView
        },
        to_group_sent: {
            component: GroupLinkCellView
        },
        email_event_stats_send: {
            component: CampaignStatCellView
        },
        email_event_stats_delivered: {
            component: CampaignStatCellView
        },
        email_event_stats_open: {
            component: CampaignStatCellView
        },
        email_event_stats_click: {
            component: CampaignStatCellView
        },
        email_event_stats_unsubscribed: {
            component: CampaignStatCellView
        },
        email_event_stats_spam: {
            component: CampaignStatCellView
        },
        email_event_stats_bounce: {
            component: CampaignStatCellView
        },
        email_event_stats_open_rate: {
            component: PercentageCellView
        },
        email_event_stats_click_rate: {
            component: PercentageCellView
        },
        email_event_stats_delivered_rate: {
            component: PercentageCellView
        },
        tags: {
            component: TagsUrlCellView
        },
        funnels: {
            component: FunnelsUrlCellView
        },
        regions: {
            component: RegionsUrlCellView
        },
        subject: {
            component: LongStringWithTooltipCellView
        },
    }
};

class CheckBoxCellView extends React.Component {
    render() {
        let value = this.props.value;
        value = value ? 'true' : '';

        return (
            <div>
                {value}
            </div>
        )
    }
}

class NumberCellView extends React.Component {
    render() {
        let value = this.props.value;

        return (
            <div className={styles.NumberCellView}>
                {value}
            </div>
        )
    }
}

const CUSTOM_FIELD_COLUMNS = {
    checkbox: {
        component: CheckBoxCellView
    },
    currency: {},
    date: {},
    dropDown: {},
    individual: {},
    number: {
        component: NumberCellView
    },
    organization: {},
    opportunity: {},
    paragraph: {
        component: CommentCellView
    },
    text: {},
    url: {},
    urlImage: {},
    user: {},
    product: {
        component: NumberCellView
    }
};

class PopoverReactView extends React.Component {
    render() {
        let popoverStyle = {};
        let arrowStyle = {};
        let top;

        if (this.props.top) {
            popoverStyle.bottom = '40px';
            arrowStyle.bottom = '-15px';
            top = 'top';
        }
        else {
            popoverStyle.top = '40px';
            arrowStyle.top = '-10px';
            top = null;
        }

        let inner;
        if (this.props.raw) {
            inner = <div className={styles.popover} dangerouslySetInnerHTML={{__html: this.props.sanitizedValue}} />
        }
        else {
            inner = <div className={styles.popover}> {this.props.value} </div>
        }

        return (
            <div className={styles.PopoverReactView + " popover " + top} style={popoverStyle} onClick={(ev) => this.handleClick(ev)} >
                <div className={styles.arrow + " arrow"} style={arrowStyle}></div>
                {inner}
            </div>
        )
    }

    handleClick(ev) {
        ev.stopPropagation();
    }

    showPopUp(ev) {
        clearTimeout(this.hideTimer);

        let container = $(ev.target).closest('.iod-table-rows-container');
        let top = (container.offset().top + container.height() / 2) < ev.nativeEvent.y;

        this.showTimer = _.delay(() => {
            this.setState({
                popUp: true,
                top: top
            });
        }, 300);
    }

    hidePopUp() {
        clearTimeout(this.showTimer);

        this.hideTimer = _.delay(() => {
            this.setState({
                popUp: false
            });
        }, 300);
    }
}

class CheckBox extends React.Component {
    handleCBClick(ev, select, id) {
        ev.stopPropagation();
        this.props.handleCBClick(select, id);
    }

    render() {
        const row = this.props.row;
        return (
            <div className={styles.CheckBox}>
                {row.selected ? (
                    <i onClick={(ev) => this.handleCBClick(ev, false, row.id)} className={"icon-checkmark mark " + styles.checkIcon + " " + styles.checkedIcon}></i>
                ) : (
                    <i onClick={(ev) => this.handleCBClick(ev, true, row.id)} className={"icon-checkmark2 unmark " + styles.checkIcon}></i>
                )}
            </div>
        );
    }
}

class HeaderCheckBox extends React.Component {
    handleClick(ev, select) {
        ev.stopPropagation();
        this.props.handleClick(select);
    }

    render() {
        return (
            <div className={styles.HeaderCheckBox}>
                {this.props.selectAllChecked ? (
                    <i onClick={(ev) => this.handleClick(ev, false)} className={"icon-checkmark mark " + styles.checkIcon + " " + styles.checkedIcon}></i>
                ) : (
                    <i onClick={(ev) => this.handleClick(ev, true)} className={"icon-checkmark2 unmark " + styles.checkIcon}></i>
                )}
            </div>
        );
    }
}

class Row extends React.Component {
    render() {
        const row = this.props.row;
        const columns = this.props.columns;
        const elementType = this.props.elementType;
        const isChecklistDelegate = this.props.row.delegate_type && this.props.row.delegate_type === 'checklist'

        let radioButton = false;
        if (this.props.showRadioSelect) {
            radioButton = <td className={styles.cellRadioContainer}><CheckBox row={row} handleCBClick={this.props.handleCBClick}/></td>
        }

        if(isChecklistDelegate){
            radioButton = <td className={styles.cellRadioContainerEmpty}></td>
        }

        let menu, icon;
        if (this.props.showCampaignSpecialColumns) {
            menu = <td><CampaignMenuView row={row} handleCampaignMenuClick={this.props.handleCampaignMenuClick}/></td>;
            icon = <td><CampaignIconView row={row}/></td>;
        }

        const automatedEmail = (row.status === "ready" || row.status === "draft") && row.used_in_automations;
        const campaignStats = (row.status === "sent") || (row.status === "automation");
        const truncatedColumns = AppConfig.getValue('truncatedColumns', {});

        const rowClasses = classnames({
            [styles.Row]: true,
            [styles.selected]: row.selected,
            [styles.Active]: this.props.isActive
        });

        return (
            <tbody className={rowClasses}>
                <tr className={styles.rowTR + " " + (automatedEmail ? styles.automated : "") + " " + (this.props.hasBarRow ? styles.hasBarRow : "") }
                    onClick={() => this.props.onRowClick(row.id)}>
                    {radioButton}
                    {menu}
                    {icon}
                    {
                        columns.map((column) => {
                            // custom field columns has type
                            if ('type' in column) {
                                if ('component' in CUSTOM_FIELD_COLUMNS[column.type]) {
                                    const CustomComponent = CUSTOM_FIELD_COLUMNS[column.type].component;
                                    return <td key={row.id+column.id}><CustomComponent value={row[column.id]} /></td>
                                }
                                else {
                                    if (_.isObject(row[column.id])) {
                                        return <td key={row.id+column.id}>{'undefined custom render ' + column.id}</td>;
                                    }
                                    else {
                                        return <td key={row.id+column.id}>{row[column.id]}</td>;
                                    }
                                }
                            }
                            else if (!(column.id in COLUMNS[elementType])) {
                                if (_.isObject(row[column.id])) {
                                    return <td key={row.id+column.id}>{'undefined render ' + column.id}</td>;
                                }
                                else {
                                    let value = row[column.id];
                                    let title = "";

                                    if (column.id in truncatedColumns) {
                                        if (value && (value.length > truncatedColumns[column.id])) {
                                            title = value;
                                            value = `${value.substr(0, truncatedColumns[column.id])}...`;
                                        }
                                    }

                                    return <td key={row.id+column.id} style={column.style}><div className={styles.wrapper} title={title}>{value}</div></td>;
                                }
                            }
                            else if (COLUMNS[elementType][column.id].component) {
                                const Component = COLUMNS[elementType][column.id].component;
                                if (campaignStats) {
                                    return <td key={row.id+column.id}><Component row={row} column={column} handleCampaignStatClick={this.props.handleCampaignStatClick} value={row[column.id]}/></td>;
                                }
                                else {
                                    return <td key={row.id+column.id}><Component row={row} column={column} value={row[column.id]} handleCellButtonClick={this.props.handleCellButtonClick}/></td>;
                                }
                            }
                            else {
                                if (_.isObject(row[column.id])) {
                                    return <td key={row.id+column.id}>{'undefined render ' + column.id}</td>;
                                }
                                else {
                                    return <td key={row.id+column.id}>{row[column.id]}</td>;
                                }
                            }
                        })
                    }
                </tr>
                {this.props.showBarRow && <BarRow row={row} columnCount={columns.length}/>}
            </tbody>
        );
    }
}

class BarRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = { popupX: 0 };
    }

    mouseEnter(ev) {
        // tooltip offset is calculated from tooltip width 130/2
        const tooltipOffset = 65;
        this.setState({ popupX: ev.clientX - ev.currentTarget.getBoundingClientRect().left });
    }

    render() {
        const row = this.props.row;

        if (row.email_event_stats.send === 0) {
            return null;
        }
        const opened = row.email_event_stats.open_rate;
        const clicked = row.email_event_stats.click_rate;
        if (opened === 0) {
            return null;
        }

        let openedBarVal;
        if (opened > 3 && (opened - clicked) > 5) {
            openedBarVal = Math.round(opened) + '%';
        }

        let clickedBarVal;
        if (clicked > 3) {
            clickedBarVal = Math.round(clicked) + '%';
        }

        return (
            <tr className={styles.BarRow}>
                <td colSpan={this.props.columnCount + 2}>
                    <div className={styles.barContainer} onMouseEnter={this.mouseEnter.bind(this)}>
                        <div className={styles.openedBar}
                             style={{ width: opened + '%' }}>
                            <span className={styles.val}>{openedBarVal}</span>
                        </div>
                        {clicked > 0 &&
                            <div className={styles.clickedBar}
                                 style={{ width: 'calc(' + clicked + '% - 6px)' }}>
                                <span className={styles.val}>{clickedBarVal}</span>
                            </div>
                        }
                    </div>
                    <div className={styles.tooltipContainer}>
                        <div className={styles.tooltip} style={{ left: this.state.popupX + 'px' }}>
                            Open Rate: {Math.round(opened)}%<br />
                            Click Rate: {Math.round(clicked)}%
                            <div className={styles.arrow}></div>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }
}

class Rows extends React.Component {
    render() {
        const rows = this.props.rows;
        const columns = this.props.columns;

        return (
            <table className={styles.Rows + " " + (this.props.fixedTable ? styles.fixedTable : "")}>
                <tbody>
                    <HeaderShadowRow
                        showRadioSelect={this.props.showRadioSelect}
                        columns={columns}
                        fixedTable={this.props.fixedTable}
                        showCampaignSpecialColumns={this.props.showCampaignSpecialColumns}/>
                </tbody>
                {
                    rows.map(row => { 
                        return <Row
                        key={row.id}
                        showRadioSelect={this.props.showRadioSelect}
                        showCampaignSpecialColumns={this.props.showCampaignSpecialColumns}
                        elementType={this.props.elementType}
                        columns={columns}
                        row={row}
                        isActive={this.props.activeRowId === row.id}
                        showBarRow={this.props.showBarRow}
                        hasBarRow={this.props.showBarRow && !(row.email_event_stats.send === 0 || row.email_event_stats.open_rate === 0)}
                        onRowClick={this.props.onRowClick}
                        handleCampaignMenuClick={this.props.handleCampaignMenuClick}
                        handleCampaignStatClick={this.props.handleCampaignStatClick}
                        handleCBClick={this.props.handleCBClick}
                        handleCellButtonClick={this.props.handleCellButtonClick}/>
                    })
                }
                <tbody>
                    <TotalsShadowRow columns={columns} totals={this.props.totals}/>
                </tbody>
            </table>
        );
    }
}

class HeaderShadowRow extends React.Component {
    render() {
        const columns = this.props.columns;

        const headerText = (column) => {
            if ('type' in column) {
                return column.name;
            }
            else {
                if (column.headerText) {
                    if (typeof column.headerText === 'function') {
                        return column.headerText();
                    }
                    else {
                        return column.headerText;
                    }
                }
                else {
                    return column.id.replace(/\.|_/g, ' ');
                }
            }
        };

        let radioButton;
        if (this.props.showRadioSelect) {
            radioButton = <td className={styles.cellRadioContainer}></td>
        }

        let menu, icon;
        if (this.props.showCampaignSpecialColumns) {
            menu = <td className={styles.menu}></td>;
            icon = <td className={styles.icon}></td>;
        }

        return (
            <tr className={styles.HeaderShadowRow}>
                {radioButton}
                {menu}
                {icon}
                {
                    columns.map((column) => {
                        return <td key={column.id} className={styles.cellContainer} data-max-width={column.maxWidth} data-min-width={column.minWidth}>
                            <div style={{display: 'inline-block', maxWidth: column.maxWidth + 'px'}}>
                                {headerText(column)}
                            </div>
                            <i className={styles.iconCaret + " icon-caret-down"}></i>
                        </td>;
                    })
                }
            </tr>
        );
    };
}

class HeaderRow extends React.Component {
    constructor(props) {
        super(props);

        if (props.sortParams) {
            this.state = {
                sort: props.sortParams.field,
                directionAsc: props.sortParams.direction
            };
        }
        else {
            this.state = {};
        }
    }

    headerClick(sort) {
        const same = _.isEqual(this.state.sort, sort);
        const directionAsc = same ? !this.state.directionAsc : false;
        this.setState({
            sort: sort,
            directionAsc: directionAsc
        });
        this.props.onHeaderClick(sort, directionAsc ? 'asc' : 'desc');
    }

    render() {
        const columns = this.props.columns;

        const headerText = (column) => {
            if ('type' in column) {
                return column.name;
            }
            else {
                if (column.headerText) {
                    if (typeof column.headerText === 'function') {
                        return column.headerText();
                    }
                    else {
                        return column.headerText;
                    }
                }
                else {
                    return column.id.replace(/\.|_/g, ' ');
                }
            }
        };

        let radioButton = false;
        if (this.props.showRadioSelect) {
            radioButton = <td className={styles.cellRadioContainer}>
                    <HeaderCheckBox selectAllChecked={this.props.selectAllChecked} handleClick={this.props.handleHeaderCBClick}/>
                </td>;
        }

        let menu, icon;
        if (this.props.showCampaignSpecialColumns) {
            menu = <td></td>;
            icon = <td></td>;
        }

        let refs = {};

        return (
            <tr className={styles.HeaderRow}>
                {radioButton}
                {menu}
                {icon}
                {
                    columns.map((column) => {
                        let cellStyle = {};

                        if (!this.props.fixedTable) {
                            cellStyle = {
                                display: 'inline-block',
                                whiteSpace: 'break-spaces',
                                maxWidth: `${column.maxWidth}px`
                            };
                        }
                        const selected = _.isEqual(this.state.sort, column.sort);

                        if (column.sort) {
                            return <td
                                       ref={(el) => refs[column.id] = el}
                                       key={column.id}
                                       style={column.style}
                                       className={styles.cellContainer + " " + styles.hasSort + " " + (selected ? styles.selected : "")}
                                       onClick={this.headerClick.bind(this, column.sort)}
                                       onContextMenu={(ev) => { ev.preventDefault(); this.props.onHeaderContextMenu(refs[column.id], column) }}>

                                <div style={cellStyle}>
                                    {headerText(column)}
                                </div>
                                <i className={styles.iconCaret + " " + (selected && this.state.directionAsc ? 'icon-caret-up' : 'icon-caret-down')}>
                                </i>
                            </td>
                        }
                        else {
                            return (
                                <td key={column.id}>
                                    <div style={cellStyle}>
                                        {headerText(column)}
                                    </div>
                                </td>
                            );
                        }
                    })
                }
            </tr>
        );
    }
}

class Header extends React.Component{
    render() {
        const columns = this.props.columns;
        return (
            <table className={styles.Header + " " + (this.props.fixedTable ? styles.fixedTable : "")}>
                <tbody>
                <HeaderRow
                    showRadioSelect={this.props.showRadioSelect}
                    showCampaignSpecialColumns={this.props.showCampaignSpecialColumns}
                    columns={columns}
                    fixedTable={this.props.fixedTable}
                    selectAllChecked={this.props.selectAllChecked}
                    sortParams={this.props.sortParams}
                    onHeaderClick={this.props.onHeaderClick}
                    onHeaderContextMenu={this.props.onHeaderContextMenu}
                    handleHeaderCBClick={this.props.handleHeaderCBClick}/>
                </tbody>
            </table>
        )
    }
}

class TotalsShadowRow extends React.Component {
    render() {
        const columns = this.props.columns;
        const totals = this.props.totals;

        if (Object.keys(totals).length) {
            return (
                <tr className={styles.TotalsShadowRow}>
                    <td className={styles.cellRadioContainer}></td>
                    {
                        columns.map((column, idx) => {
                            if (idx === 0) {
                                return <td key={column.id}>Grand Totals</td>;
                            }
                            return <td key={column.id}>{totals[column.id]}</td>;
                        })
                    }
                </tr>
            );
        }
        else {
            return null;
        }
    }
}

class TotalsRow extends React.Component {
    render() {
        const columns = this.props.columns;
        const totals = this.props.totals;

        if (Object.keys(totals).length) {
            return (
                <tr className={styles.TotalsRow}>
                    <td className={styles.cellRadioContainer}></td>
                    {
                        columns.map((column, idx) => {
                            if (idx === 0) {
                                return <td key={column.id}>Grand Totals</td>;
                            }
                            return <td key={column.id}>{totals[column.id]}</td>;
                        })
                    }
                </tr>
            );
        }
        else {
            return null;
        }
    }
}

class Totals extends React.Component {
    render() {
        return (
            <table>
                <tbody>
                    <TotalsRow columns={this.props.columns} totals={this.props.totals}/>
                </tbody>
            </table>
        )
    }
}

class Footer extends React.Component {
    render() {
        const pagerValues = this.props.pagerValues;

        let prev = null;
        if (pagerValues.start > 1) {
            prev = <a href="#" onClick={this.props.handlePrevClick.bind(this)}>Prev</a>;
        }

        let next = null;
        if (pagerValues.stop < pagerValues.total) {
            next = <a href="#" onClick={this.props.handleNextClick.bind(this)}>Next</a>;
        }

        return (
            <footer className={styles.Footer}>
                <span>
                    {prev}
                </span>
                <span className={styles.startStopContainer}>
                    <span>
                        <span>{pagerValues.start}</span>–<span>{pagerValues.stop}</span>
                    </span> of
                    <span> {pagerValues.total}</span>
                </span>
                <span>
                    {next}
                </span>
            </footer>
        )
    }
}

class Toolbar extends React.Component {
    render() {
        let deleteButton, editButton, mergeButton, selectAllEl, newButton, addButton, removeFromFavoritesButton, removeFromGroupButton;

        const singularTypeMap = {
            individuals: 'individual',
            organizations: 'organization',
            opportunities: 'deal',
            tasks: 'task',
            activities: 'activity',
            texts: 'text'
        };

        const pluralTypeMap = {
            individuals: 'individuals',
            organizations: 'organizations',
            opportunities: 'deals',
            tasks: 'tasks',
            activities: 'activities',
            texts: 'texts',
            messages: 'messages'
        };

        const buttons = this.props.buttons || {};

        if (this.props.selectionCount) {
            if (buttons.removeFromFavorites) {
                removeFromFavoritesButton = <div onClick={this.props.handleRemoveFromFavorites}
                                    className={styles.button + " " + styles.removeFromButton}>Remove From Favorites</div>;
            }
            if (buttons.removeFromGroup) {
                removeFromGroupButton = <div onClick={this.props.handleRemoveFromGroup}
                                             className={styles.button + " " + styles.removeFromButton}>Remove From Group</div>;
            }
            if (buttons.deleteButton) {
                deleteButton = <div onClick={this.props.handleBulkDelete}
                                    className={styles.button + " " + styles.deleteButton}>Delete</div>;
            }
            if (buttons.editButton) {
                editButton = <div onClick={this.props.handleBulkEdit}
                                  className={styles.button + " " + styles.editButton}>Edit</div>;
            }
            if (buttons.mergeButton) {
                mergeButton = <div onClick={this.props.handleMergeItems}
                                   className={styles.button + " " + styles.mergeButton}>Merge</div>;
            }

            if (this.props.totalCount === 1) {
                selectAllEl = <span className={styles.selectAllMsg}>The <strong>only</strong> {singularTypeMap[this.props.elementType]} selected </span>;
            }
            else {
                if (this.props.allSelected) {
                    selectAllEl = <span className={styles.selectAllMsg}>All <strong>{this.props.totalCount}</strong> {pluralTypeMap[this.props.elementType]} selected </span>;
                }
                else if (this.props.selectAllChecked){
                    selectAllEl = <span className={styles.selectAllMsg}>
                        <span>The <strong>{this.props.selectionCount}</strong> {pluralTypeMap[this.props.elementType]} on this page are selected. </span>
                        <a className={styles.selectAllButton} onClick={this.props.handleAllSelect}>
                            Select all <strong>{this.props.totalCount}</strong> {pluralTypeMap[this.props.elementType]}
                        </a>
                    </span>;

                }
            }
        }

        if (buttons.newButton) {
            newButton = <div onClick={this.props.handleAddNew} className={styles.button + " " + styles.newButton}>New</div>;
        }

        if (buttons.addButton) {
            addButton = <div onClick={(ev) => this.props.handleAddExisting(ev)} className={styles.button + " " + styles.addButton}>Add</div>;
        }

        return (
            <div className={styles.Toolbar}>
                {removeFromFavoritesButton}
                {removeFromGroupButton}
                {deleteButton}
                {editButton}
                {mergeButton}
                {selectAllEl}
                {newButton}
                {addButton}
            </div>
        );
    }
}

class HeaderContextMenuItemButton extends React.Component {
    render() {
        const item = this.props.item;

        return (
            <div
                className={styles.HeaderContextMenuItem}
                onClick={() => this.props.onClick(item)}
            >
                <span>
                    {item.name}
                </span>
            </div>
        );
    }
}

class HeaderContextMenuItemBool extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: this.props.value || false
        };
    }

    onClick(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        const isChecked = !this.state.isChecked;

        this.setState({
            isChecked: isChecked
        });

        this.props.onChange(this.props.item, isChecked);
    }

    render() {
        const item = this.props.item;
        const switchContainerClasses = ['switch-container', styles.HeaderContextMenuItemSwitchContainer].join(' ');

        return (
            <div
                className={styles.HeaderContextMenuItem}
                onClick={this.onClick.bind(this)}
            >
                <span>
                    {item.name}
                </span>
                <div className={switchContainerClasses}>
                    <input
                        ref={(el) => this.checkboxEl = el}
                        type="checkbox"
                        className="checkbox-switch-control"
                        id={item.id}
                        name={item.id}
                        onChange={() => {}} // to avoid the react warning message
                        checked={this.state.isChecked}
                    />
                    <label
                        htmlFor={item.id}
                        className="checkbox-switch"
                        onClick={this.onClick.bind(this)}
                    >
                    </label>
                </div>
            </div>
        );
    }
}

class HeaderContextMenu extends React.Component {
    onItemChange(item, value) {
        if (!this.props.column.modifiers) {
            this.props.column.modifiers = {};
        }

        this.props.onItemSelected(item, value);

        if (item.type !== 'bool') {
            this.props.onClose();
        }
    }

    onItemClick(item) {
        this.props.onItemClick(item);
    }

    render() {
        const onMaskClick = function(ev) {
            ev.stopPropagation();
            this.props.onClose();
        }

        const modifiers = this.props.columnModifiers || {};

        return (
            <div>
                <div className={styles.HeaderContextMenuMask}
                    onClick={onMaskClick.bind(this)}
                >
                    <div className={styles.HeaderContextMenu} style={{ top: this.props.top + 'px', left: this.props.left + 'px' }}>
                        {this.props.items.map(item => {
                            if (item.type === 'bool') {
                                return <HeaderContextMenuItemBool
                                    key={item.id}
                                    item={item}
                                    value={modifiers[item.id]}
                                    onChange={this.onItemChange.bind(this)}
                                />
                            } else {
                                return <HeaderContextMenuItemButton
                                    key={item.id}
                                    item={item}
                                    onClick={this.onItemClick.bind(this)}
                                />
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

class IODTableReactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerContextMenuColumn: null
        };
    }

    componentDidMount() {
        this.props.componentDidMountCallback(this);
    }

    componentWillUnmount() {
        this.props.componentWillUnmountCallback(this);
    }

    componentDidUpdate() {
        this.adjustHeaderWidth();
    }

    onHeaderContextMenu(el, column) {
        if (column.contextMenu) {
            const elBB = el.getBoundingClientRect();

            this.setState({
                headerContextMenuColumn: column,
                headerContextMenuTop: elBB.top + 36,
                headerContextMenuLeft: elBB.left
            });
        }
    }

    onHeaderContextMenuItemSelected(item, value) {
        if (item.type === 'bool') {
            this.props.onHeaderContextMenuItemChange(this.state.headerContextMenuColumn.id, item.id, value);
        }
    }

    onHeaderContextMenuItemClick(item) {
        this.props.onHeaderContextMenuItemClick(this.state.headerContextMenuColumn.id, this.state.headerContextMenuColumn.name || this.state.headerContextMenuColumn.headerText, item.id);
    }

    render() {
        const hasTotals = !!Object.keys(this.props.totals).length;
        let loader = null;
        if (this.props.loader) {
            loader = <div className={styles.loaderContainer + " loader-container"}><div className="loading-view"><div className="loader"></div></div></div>;
        }

        let noItems = null;
        let footer = null;
        if (this.props.rows.length === 0) {
            noItems = <div className={styles.noItemContainer}>
                <div className={styles.noItem}>No items to display</div>
            </div>;
        }
        else {
            footer = <div className={styles.footerContainer}>
                <Footer
                    pagerValues={this.props.pagerValues}
                    handlePrevClick={this.props.handlePrevClick}
                    handleNextClick={this.props.handleNextClick} />
            </div>;
        }

        this.showRadioSelect = _.find(this.props.buttons, b => b) && !this.props.disableRadioSelect;

        return (
            <div className={`${styles.IODTableReactView} ${this.props.previewVisible ? styles.PreviewVisible : ''}`}>
                <div className={styles.toolbarWrapper}>
                    <Toolbar
                        buttons={this.props.buttons}
                        allSelected={this.props.allSelected}
                        selectAllChecked={this.props.selectAllChecked}
                        selectionCount={this.props.selectionCount}
                        totalCount={this.props.totalCount}
                        elementType={this.props.elementType}
                        handleAllSelect={this.props.handleAllSelect}
                        handleBulkDelete={this.props.handleBulkDelete}
                        handleBulkEdit={this.props.handleBulkEdit}
                        handleMergeItems={this.props.handleMergeItems}
                        handleAddNew={this.props.handleAddNew}
                        handleAddExisting={this.props.handleAddExisting}
                        handleRemoveFromFavorites={this.props.handleRemoveFromFavorites}
                        handleRemoveFromGroup={this.props.handleRemoveFromGroup}
                    />
                </div>
                <div ref='headerContainer' className={styles.headerContainer}>
                    <Header
                        fixedTable={this.props.fixedTable}
                        showRadioSelect={this.showRadioSelect}
                        showCampaignSpecialColumns={this.props.showCampaignSpecialColumns}
                        columns={this.props.columns}
                        selectAllChecked={this.props.selectAllChecked}
                        sortParams={this.props.sortParams}
                        onHeaderClick={this.props.onHeaderClick}
                        onHeaderContextMenu={this.onHeaderContextMenu.bind(this)}
                        handleHeaderCBClick={this.props.handleHeaderCBClick}/>
                </div>
                <div ref='tableContainer' className={styles.rowsContainer + " iod-table-rows-container " + (hasTotals ? styles.rowsContainerHasTotals : '')}>
                    <Rows
                        fixedTable={this.props.fixedTable}
                        showRadioSelect={this.showRadioSelect}
                        showCampaignSpecialColumns={this.props.showCampaignSpecialColumns}
                        elementType={this.props.elementType}
                        showBarRow={this.props.showBarRow}
                        columns={this.props.columns}
                        rows={this.props.rows}
                        activeRowId={this.props.activeRowId}
                        totals={this.props.totals}
                        onRowClick={this.props.onRowClick}
                        handleCampaignMenuClick={this.props.handleCampaignMenuClick}
                        handleCampaignStatClick={this.props.handleCampaignStatClick}
                        handleCBClick={this.props.handleCBClick}
                        handleCellButtonClick={this.props.handleCellButtonClick}
                        />
                </div>
                <div ref='totalsContainer' className={styles.totalsContainer + " " + (this.props.firefox ? styles.firefox : '')}>
                    <Totals columns={this.props.columns} totals={this.props.totals} />
                </div>
                {footer}
                {noItems}
                {loader}
                {this.state.headerContextMenuColumn && this.state.headerContextMenuColumn.contextMenu && <HeaderContextMenu
                    top={this.state.headerContextMenuTop}
                    left={this.state.headerContextMenuLeft}
                    column={this.state.headerContextMenuColumn}
                    columnModifiers={this.props.columnsModifiers[this.state.headerContextMenuColumn.id]}
                    items={this.state.headerContextMenuColumn.contextMenu.items}
                    onClose={() => this.setState({ headerContextMenuColumn: null })}
                    onItemSelected={this.onHeaderContextMenuItemSelected.bind(this)}
                    onItemClick={this.onHeaderContextMenuItemClick.bind(this)}
                />}
            </div>
        );
    }

    adjustHeaderWidth() {
        let table = $(this.refs.tableContainer).find('table');
        let shadowHeaderCells = table.find('tr:first').find('td');

        let headerTable = $(this.refs.headerContainer).find('table');
        let headerCells = headerTable.find('tr:first').find('td');
        let totalsTable = $(this.refs.totalsContainer).find('table');
        let totalsCells = totalsTable.find('tr:first').find('td');

        headerTable.outerWidth(table.outerWidth());
        headerTable.css('min-width', table.outerWidth() + 'px'); // min-width required for FF
        totalsTable.outerWidth(table.outerWidth());
        totalsTable.css('min-width', table.outerWidth() + 'px'); // min-width required for FF

        this.simulateMinMaxWidth(table);

        _.each(shadowHeaderCells, (cell, ind) => {
            const ow = $(cell).outerWidth();
            $(headerCells[ind]).outerWidth(ow);
            $(totalsCells[ind]).outerWidth(ow);
        });
    }

    simulateMinMaxWidth(table) {
        const maxCells = table.find('td[data-max-width]');
        maxCells.css('width', '');
        let change = true;
        while (change) {
            change = false;
            maxCells.each((ind, td) => {
                const el = $(td);
                const maxWidth = el.attr('data-max-width');
                const hasWidth = !!el[0].style.width.length;
                if (!hasWidth && maxWidth && el.outerWidth() > maxWidth) {
                    el.css('width', maxWidth + 'px');
                    change = true;
                }
            });
        }
        const minCells = table.find('td[data-min-width]');
        minCells.css('width', '');
        change = true;
        while (change) {
            change = false;
            minCells.each((ind, td) => {
                const el = $(td);
                const minWidth = el.attr('data-min-width');
                const hasWidth = !!el[0].style.width.length;
                if (!hasWidth && minWidth && el.outerWidth() < minWidth) {
                    el.css('width', minWidth + 'px');
                    change = true;
                }
            });
        }
    }
}

export default IODTableReactView;
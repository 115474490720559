import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import noCollectionTemplate from 'templates/base/no_collection.handlebars'
import loadingCollectionTemplate from 'templates/base/loading_collection.handlebars'


var NoCollectionView = Marionette.ItemView.extend({
    loadingTemplate: loadingCollectionTemplate,
    emptyTemplate: noCollectionTemplate,
    tagName: 'tr',
    className: 'empty-collection',
    initialize: function(options) {
        var template = (
                options.loadingItems ?
                this.loadingTemplate :
                this.emptyTemplate),
            compiledTemplate = Handlebars.compile(template);
        this.template = compiledTemplate();
    }
});

export default NoCollectionView;

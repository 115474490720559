import React from 'react';

import style from './table.css';


class HeaderCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            active: false
        };
    }

    getBbox() {
        return this.el.getBoundingClientRect();
    }

    handleFilter() {
        this.setSelected(true);
        this.props.onFilter(this.props.column);
    }

    setSelected(selected) {
        this.setState({
            selected: selected
        });
    }

    setActive(active) {
        this.setState({
            active: active
        });
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        if (this.props.column.color) {
            cellStyle.color = this.props.column.color;
        }

        return (
            <div
                ref={(el) => this.el = el}
                className={`
                    ${style.hCell}
                    ${this.state.selected ? style.cSelected : ''}
                    ${this.state.active ? style.cActive : ''}
                `}
                style={cellStyle}
            >
                <div
                    className={style.cTitle}
                    title={this.props.column.title}
                >
                    {this.props.column.title}
                </div>

                {((this.props.column.filterable && this.props.tableFilterable) || this.props.column.sortable || this.props.column.sortableAnniversary || this.props.column.groupable) &&
                    <div
                        className={`
                            ${style.cFilter}
                            icon-filter
                        `}
                        onClick={this.handleFilter.bind(this)}
                    />
                }
            </div>
        );
    }
}

class HeaderCellSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };
    }

    handleSelect() {
        const selected = !this.state.selected;

        this.setState({
            selected: selected
        });

        if (selected) {
            this.props.onPageSelected();
        } else {
            this.props.onPageUnselected();
        }
    }

    setSelect(selected) {
        this.setState({
            selected: selected
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.hCell}
                    ${style.cSelector}
                    ${this.state.selected ? style.cSelected : ''}
                `}
                style={{width: this.props.column.finalWidth}}
                onClick={this.handleSelect.bind(this)}
            >
                {this.state.selected ? (
                    <div className='icon-checkmark'/>
                ) : (
                    <div className='icon-checkmark2'/>
                )}
            </div>
        );
    }
}

const HEADER_CELL_BY_COLUMN_TYPE = {
    select: HeaderCellSelect
};


const getHeaderCell = function(columnType) {
    return HEADER_CELL_BY_COLUMN_TYPE[columnType] || HeaderCell;
}

export default getHeaderCell;
import React from 'react';

import TextManager from 'app/text-manager'
import PanelBase from './base';
import { TagSelect } from 'js/react_views/widgets/select'
import { DateField } from 'js/react_views/common_components/common'
import { Button } from 'js/react_views/widgets/widgets';
import MessageBox from 'js/views/message_box';
import {
    EntityDate,
    SingleCustomFieldDateFilter,
    BrokerLeadsReportFilter,
    UnActionedTasksReportFilter,
} from 'app_v2/sections/reports/report_date_filters'

import style from './reports_date_filter.css';

export default class ReportsDateFilterPanel extends PanelBase {
    constructor(props) {
        super(props);

        this.customTitle = '';
        this.regionsSelected = [];
        this.funnelsSelected = [];
    }

    show() {
        super.show();
    }

    hide() {
        super.hide();
    }

    componentWillMount() {
        super.componentWillMount();

        this.setTitle('Create New Report');
        this.showCloseButton(true);
    }

    handleCustomTitleChange(ev) {
        let title = '';

        if (ev.target.value) {
            title = ev.target.value;
        }

        this.customTitle = title;
    }

    handleRegionChange(items) {
        let regions = [];

        for (let item of items) {
            regions.push(item.id);
        }

        this.regionsSelected = regions;
    }

    handleFunnelChange(items) {
        let funnels = [];

        for (let item of items) {
            funnels.push(item.id);
        }

        this.funnelsSelected = funnels;
    }

    generateReport() {
        let self = this;
        const reportIdByReportName = {
            'Cancellation': 'cancellation_report',
            'Un-actioned Enquiries': 'unactioned_enquiries_report',
            'Reservation Source Details': 'reservation_source_details_report',
            'Regional Visitors': 'regional_visitors_report',
            'Regional Enquiries': 'regional_enquiries_report',
            'Financial Report': 'primary_brokers_report',
            'Broker Leads': 'broker_leads_report',
            'Un-actioned Tasks': 'unactioned_tasks_report',
            'Leads created last 7 days.': 'ssdemo_individuals_created_last_seven_days',
            'Progressed plots/reservations last 7 days.': 'ssdemo_deals_modified_last_seven_days',
            'Tasks completed last 7 days.': 'ssdemo_tasks_completed_last_seven_days',
            'Tasks due in the next 7 days.': 'ssdemo_tasks_due_in_next_seven_days',
            'All calls and emails in the last week.': 'ssdemo_activities_created_in_past_seven_days'
        }
        const report_id = reportIdByReportName[this.props.activeGroup.name];

        const data = {
            customTitle: this.customTitle,
            regions: this.regionsSelected,
            funnels: this.funnelsSelected,
            dates: this.dateFilter.getDateData(),
            reportName: this.props.activeGroup.name,
        };

        $.ajax({
            type: 'POST',
            url: `/trigger_report/${report_id}`,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
            success: function (data) {
                var mbContent = {
                    message: '<b>We are generating your report.</b> Reports can take from a few minutes to a few hours depending on the size of your report. We will email you as soon as it is ready.',
                    icon: 'icon-warning',
                };

                MessageBox.showOk(mbContent, self.props.parent, function () { // yes
                    self.handleClose();
                });
            },
            error: function (data) {
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'There was an error generating your report!',
                }, self.props.parent);

            },
        });

    }

    getReportDateFilter(reportName) {
        const contactText = TextManager.parseText('${ID_INDIVIDUAL, capitalize}');
        let filter;

        if (reportName === 'Un-actioned Enquiries' || reportName === 'Regional Visitors' || reportName === 'Regional Enquiries' 
                || reportName === 'Leads created last 7 days.') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFieldLabel={`${contactText} Created`}
                dateFieldPlaceholder={`${contactText} Created Date`}
            />;
        } else if (reportName === 'Financial Report') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFieldLabel={'Reservation Created'}
                dateFieldPlaceholder={'Reservation Created Date'}
            />;
        } else if (reportName === 'Cancellation') {
            filter = <SingleCustomFieldDateFilter
                ref={(input) => { this.dateFilter = input; }}
                cfName='Reservation Cancelled Date'
                cfType='opportunity_custom'
                dateFieldLabel='Cancelled'
                dateFieldPlaceholder='Reservation Cancelled Date'
            />;
        } else if (reportName === 'Reservation Source Details') {
            filter = <SingleCustomFieldDateFilter
                ref={(input) => { this.dateFilter = input; }}
                cfName='Reservation Date'
                cfType='opportunity_custom'
                dateFieldLabel='Reservation Created'
                dateFieldPlaceholder='Reservation Created Date'
            />;
        } else if (reportName === 'Broker Leads') {
            filter = <BrokerLeadsReportFilter
                ref={(input) => { this.dateFilter = input; }}
            />;
        } else if (reportName === 'Un-actioned Tasks') {
            filter = <UnActionedTasksReportFilter
                ref={(input) => { this.dateFilter = input; }}
            />;
        } else if (reportName === 'Progressed plots/reservations last 7 days.') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFilterField={'complete_date'}
                dateFieldLabel={'Created'}
                dateFieldPlaceholder={'Created Date'}
            />;
        } else if (reportName === 'Tasks completed last 7 days.') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFilterField={'complete_date'}
                dateFieldLabel={'Task Completed'}
                dateFieldPlaceholder={'Task Completed Date'}
            />;
        } else if (reportName === 'Tasks due in the next 7 days.') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFilterField={'due_date'}
                dateFieldLabel={'Task Due'}
                dateFieldPlaceholder={'Task Due Date'}
            />;
        } else if (reportName === 'All calls and emails in the last week.') {
            filter = <EntityDate
                ref={(input) => { this.dateFilter = input; }}
                dateFilterField={'due_date'}
                dateFieldLabel={'Task Due'}
                dateFieldPlaceholder={'Task Due Date'}
            />;
        }

        return filter;
    }

    getContent() {
        this.customTitle = '';
        this.regionsSelected = [];
        this.funnelsSelected = [];

        const filter = this.getReportDateFilter(this.props.activeGroup.name);

        const descriptionByReport = {
            'Cancellation': 'Get list of all cancellations based on selected regions, developments, period of cancellation.',
            'Un-actioned Enquiries': 'Get a list of unactioned leads based on region, developments or contact created in a period',
            'Reservation Source Details': 'Get insights of reservation sources based on selected regions, developments, reservation created date.',
            'Financial Report': 'Get financial report based on selected region, development(s) and reservation created date.',
            'Broker Leads': 'Get broker report based on region, developments, contact created date, assigned to broker or date of financial qualifications.',
            'Regional Visitors': 'Get a list of regional visitors based on region, developments or contact created in a period.',
            'Regional Enquiries': 'Get a list of regional enquiries based on region, developments or contact created in a period.',
            'Un-actioned Tasks': 'Get a list of unactioned tasks based on region, developments and task created date',
        }
        const descriptionText = descriptionByReport[this.props.activeGroup.name];

        return (
            <div className={style.reportsDateFilter}>
                <div className={style.inputField}>
                    <label htmlFor="custom-title" className={style.inputFieldLabel}>Title</label>
                    <input type="text" className={style.inputFieldText} placeholder="Enter Report Title" name="custom-title" id="custom-title" onChange={this.handleCustomTitleChange.bind(this)} /><br />
                </div>

                {/*
                <div className={style.inputField}>
                    <label className={style.inputFieldLabel}>Region</label>
                    <TagSelect
                        value={this.tags}
                        url='/regions'
                        text='name'
                        width='250'
                        placeholder='Select Region'
                        onChange={this.handleRegionChange.bind(this)}
                    />
                </div>

                <div className={style.inputField}>
                    <label className={style.inputFieldLabel}>Development(s)</label>
                    <TagSelect
                        value={this.tags}
                        url='/funnels'
                        text='name'
                        width='250'
                        placeholder='Add Developments'
                        onChange={this.handleFunnelChange.bind(this)}
                    />
                </div>
                */}

                {filter}

                <div className={style.descriptionContainer}>
                    Description
                    <div className={style.description}>
                        {descriptionText}
                    </div>
                </div>

                <div className={style.buttonField}>
                    <Button onClick={this.generateReport.bind(this)}>
                        <span>Generate Report</span>
                    </Button>
                </div>
            </div>
        )
    }
}
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import AppConfig from 'app/app-config'
import PaginatedListView from 'js/views/base/paginated_list'
import ActivitiesCollection from 'js/collections/activity'
import ListNavbarView from 'js/views/base/list_navbar'
import BaseToolbarView from 'js/views/base/toolbar'
import ActivityFilterView from 'js/views/activity/filter'
import TasksView from 'app/tasks/tasks'
import ActivityCreator from 'js/utils/activity_creator'


var NavView;

function createActivityListView(options) {
    return new PaginatedListView(_.extend({
        id: 'activity-list',
        listGetItemView: function(model){
            return ActivityCreator.getActivityItem(model);
        },
        listItemViewOptions: {
            section: 'Activities',
            url: 'activities'
        },
        collection: new ActivitiesCollection(),
        fetchOptions: {
            sortOn: [{
                attribute: 'user_created',
                order: 'desc'
            }],
            data: {
                tasks: true
            }
        },
        fetchOnShow: false
    }, options));
}

NavView = ListNavbarView.extend({
    title: 'Activity',
    showTabs: true,
    tabs: [
        {
            id: 'activities',
            title: function() {
                const clientPreferences = app.user.get('client').preferences || {};

                if (clientPreferences.dashboard_activities_fetch_last_days && !isNaN(clientPreferences.dashboard_activities_fetch_last_days)) {
                    if(clientPreferences.dashboard_activities_fetch_last_days !== -1){
                        var days = parseInt(clientPreferences.dashboard_activities_fetch_last_days); 
                        return `Activity (${days > 1 ? `${days} days` : `24h` })`
                    }else{
                        return 'Activity'
                    }
                } else {
                    return `Activity (24h)`
                }
            },
            'class': 'list-nav-activity',
            view: createActivityListView,
            listFiltersView: function() {
                return new ActivityFilterView({ mainFilter: true, isDashboard: true });
            },
            filterable: true
        },
        {
            id: 'tasks',
            title: 'My Tasks',
            showBadge: true,
            'class': 'list-nav-tasks',
            view: function() {
                return new TasksView.SingleTaskListContainer({
                    filterModel: new Backbone.Model({
                        where: {
                            assigned_to_me: true
                        }
                    }),
                    defaultAssigneeSelf: true,
                    displayFilters: false
                });
            }
        }
    ],
    initialize: function() {
        ListNavbarView.prototype.initialize.apply(this, arguments);

        if (this.options.preferencesKey) {
            this.initialTabId = app.user.get('preferences')[this.options.preferencesKey] || null;
        }

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            this.initialTabId = 'tasks';
        }

        this.listenTo(this, 'activities:showItem', function(model) {
            var target_type = model.get('target_type'),
                target_id;

            if (target_type === 'individuals') {
                target_id = model.get('individual_id');
                if (model.get('individual_deleted')) {
                    return;
                }
            }
            else if (target_type === 'organizations') {
                target_id = model.get('organization_id');
            }
            else if (target_type === 'opportunities') {
                target_id = model.get('opportunity_id');
            }
            else {
                return;
            }

            this.trigger('selectActivityItem', target_type, target_id);
        });

        this.listenTo(this, 'activities:toolbar:delete', function(sel) {
            this.deleteItems( sel, true );
        });
    },
    onShow: function() {
        var self = this;
        TasksView.get_task_count({ assigned_to_me: true }, function(count){
            self.$el.find('.task-counter').text(count || '');
        });
        ListNavbarView.prototype.onShow.apply(this, arguments);
    }
});

export default NavView;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'

import TableDetailView from 'js/views/base/table_detail'


var COLUMNS = [
    {
        'id': 'name',
        'name': 'NAME',
        'sort': ['_id'],
        'columns': {'name': true},
        'template': Handlebars.compile('{{name}}')
    },
    {
        'id': 'last_seen',
        'name': 'LAST SEEN',
        'sort': ['last_seen'],
        'columns': {'last_seen': true},
        'template': Handlebars.compile('{{formatEntityInformationDate last_seen}}')
    },
    {
        'id': 'visits',
        'name': 'VISITS',
        'sort': ['count'],
        'columns': {'visits': true},
        'template': Handlebars.compile('<div style="text-align: right;">{{count}}</div>')
    }
];

var DEFAULT_COLUMNS = [
    'name',
    'last_seen',
    'visits'
];

export default TableDetailView.extend({
    columns: COLUMNS,
    defaultColumns: DEFAULT_COLUMNS,
    sortId: 'last_seen',
    sortDirection: false
});

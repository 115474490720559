import _ from 'underscore'


/**
 * Accept string with potential URL parts to convert to actual clickable URLs. Escapes string to be safely put
 * in HTML.
 *
 * @param text          string to linkify
 * @returns {*|String}  same string with escaped HTML characters and URL wrapped in <a></a>
 */
function linkify(text, skipEscape) {
    var url,
        urls = [],
        // matches urls starting with http(s), ftp(s) and www.
        // &#x2F; stands for forward slash, which is escaped with underscore escape
        re = new RegExp("\\b((((ftps?|https?):(\\/|&#x2F;){2})|(www\\.))([\\w\\-]+\\.)+([a-z]{2,}))(:\\d*)" +
            "?((\\/|&#x2F;)[(\\/|&#x2F;)\\w\\.\\-\\?&#=%!+]*)?", "g"),
        protocolPattern = /ftps?|https?/,
        originalLength;

    if (!skipEscape ) {
        text = _.escape(text);
    }

    while ((url = re.exec(text)) !== null) {
        urls.push({fullURL: url[0], domain: url[1], index: url.index});
    }

    for (var i = urls.length - 1; i >= 0; i--) {
        originalLength =  urls[i].fullURL.length;
        if (!protocolPattern.exec(urls[i].fullURL)) {
            urls[i].fullURL = 'http://' + urls[i].fullURL;
        }
        text = text.substr(0, urls[i].index) +
            '<a href="' + urls[i].fullURL + '" target="_blank" rel=”nofollow” class="external-link">' +
            urls[i].domain + '</a>' + text.substr(urls[i].index + originalLength);
    }

    return text;
}

export default linkify;

import _ from 'underscore'

import AppConfig from 'app/app-config'
import IndividualListingBaseView from 'app/_components/IOD-listing/IOD/individual-listing/individual-listing-base'
import TableBodyContainerView from 'js/views/base/table-body-container-view'


var BaseOverviewView = IndividualListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    templateHelpers: function() {
        return {
            id: this.parent.getElementType(),
            name: 'My Realtors',
            hideInfo: true,
            disableLayoutSelection: true,
        };
    },
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.fetchOptions = _.extend({
            extended: true,
            filter_id: this.filter && this.filter.get('id')
        }, this.fetchOptions);

        if (options.owner_id) {
            this.fetchOptions.owner_id = options.owner_id;
        }
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            customFields: this.customFields,
            type: this.options.type,
            elementType: this.options.elementType,
            filter: this.filter,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                newButton: true
            },
            defaultColumns: [
                'photo_url',
                'first_name',
                'last_name',
                'organization.name',
                'email',
                'phone'
            ],
        });
    }
});


export default IndividualListingBaseView.extend({
    OverviewView: OverviewView,
    getUrl: function() {
        return 'my-realtors';
    }
});
import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import vent from 'js/vent.js'
import appContent from 'js/views/appcontent.js'
import app from 'js/app.js'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import ActivityFeedView from 'js/views/activity/feed.js'
import IndividualsCollection from 'js/collections/contacts.js'
import createIndividualsList from 'js/views/individuals/create-individuals-list.js'
import Currency from 'js/utils/currency.js'
import api from 'js/api.js'
import linkify from 'js/utils/linkify.js'
import security from 'js/utils/security.js'
import dateFormat from 'js/utils/date-format.js'
import Utilities from 'js/utils/utilities.js'
import MessageBox from 'js/views/message_box.js'
import OrganizationModel from 'js/models/organization.js'
import ActivityCreator from 'js/utils/activity_creator.js'
import UserModel from 'js/models/user.js'
import PhasesCollection from 'js/collections/phases.js'
import FunnelsCollection from 'js/collections/funnels.js'
import BucketsCollection from 'js/collections/buckets.js'
import ModalRegion from 'js/views/base/modal-region.js'
import ItemPermissionsView from 'js/views/item_permissions.js'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import ChecklistsCollection from 'js/collections/checklists.js'
import CustomFieldsView from 'js/views/custom_fields.js'
import TemplatesListView from 'js/views/base/templates-list.js'
import TasksView from 'app_v2/panels/tasks_list.js'
import ChecklistsView from 'js/react_views/checklists/checklists.js'
import AftercareView from 'js/react_views/aftercare/aftercare.js'
import ConversationsView from 'js/react_views/conversations/conversations.js'
import AppointmentsView from 'js/react_views/appointments/appointments.js'
import ChoicesView from 'js/react_views/choices/choices.js'
import GroupListContainsView from 'js/views/groups/list-contains.js'
import RelatedFilesListView from 'js/views/related_files_list.js'
import RelatedFilesCollection from 'js/collections/related_files.js'
import opportunityViewTemplate from 'templates/opportunities/detail.handlebars'
import overviewTemplate from 'templates/opportunities/detail_overview.handlebars'
import bucketItemTemplate from 'templates/opportunities/bucket-item.handlebars'
import docusignTemplate from 'templates/individuals/docusign.handlebars'
import DealContainerView from 'js/react_containers/DealContainerView'


var DetailViewController, OpportunityView, OverviewView, BucketView, BucketListView;

var statusData = [
    { id: 'none', text: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
    { id: 'none_upside', text: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
    { id: 'committed_downside', text: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
    { id: 'committed', text: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
];

DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.changes = {};
        this.redyToMakeDirty = false; // flag to when register view as dirty
        this.onSaveError = options.onSaveError;
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
        this.redyToMakeDirty = true;
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        if (this.redyToMakeDirty) {
            app.dirtyModelHandler.add(this.model.cid);
        }
        this.changes[key] = value;
        if ( !this.editing ) {
            this.saveEdition();
        }
    },
    removeChange: function(key) {
        delete this.changes[key];
    },
    saveEdition: function() {
        var controller = this;

        this.trigger('editing:save:before');

        if (_.keys(this.changes).length || this.model.isNew()) {
            this.model.save(this.changes, {
                extraFields: ['locations'],
                patch: true,
                validate: true,
                ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
                params: {
                    check_phase_change_allowed: true,
                    check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
                },
                success: function() {
                    if ('phase_id' in controller.changes) {
                        vent.trigger('deal:save:phase', controller.model);
                    }
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('deal:save');
                    app.dirtyModelHandler.remove(controller.model.cid);
                }, error: function(model, response, settings) {
                    if (controller.onSaveError) {
                        controller.onSaveError(model, response, settings);
                    }
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    cancelEdition: function() {
        app.dirtyModelHandler.confirm(this, function() {
            var controller = this;
            this.changes = {};
            this.model.fetch({
                success: function () {
                    controller.trigger('editing:cancel');
                    controller.endEditing();
                }
            });
        });
    },
    destroyModel: function() {
        this.model.destroy();
    },
    followCommunicationLink: function(communication, link) {
        this.followCommsLink(this.model, communication, link);
    },
    followCommsLink: function(model, communication, link) {
        var activity = ActivityCreator.createAutoCommunication(
            communication.get('medium'),
            communication,
            this.model.get('type'),
            this.model.get('id')
        );

        window.open(link);

        if (activity) {
            activity.save({}, {
                complete: function() {
                    vent.trigger('update:activity');
                }
            });
        }
    },
    linkOrganizationToContact: function(contactModel) {
        this.listenTo(this, 'editing:save:after', function() {
            contactModel.save(
                { organization_id: this.model.get('organization')['id'] },
                { patch: true }
            );
        });
    }
});

var docusignView = Marionette.Layout.extend({
    template: Handlebars.compile(docusignTemplate),
    templateHelpers: function() {
        return {
            name: this.options.model.get('name'),
            forDeal: true
        };
    },
    ui: {
        loader: '.ds-loader',
        content: '.ds-info',
        footer: '.ds-footer',
        templateSelector: '.ds-template-selector',
        roleSelector: '.ds-role-selector',
        contactSelector: '.ds-contact-selector',
        errorMessage: '.error-message'
    },
    events: {
        'click .ds-cancel': function() {
            this.trigger('close');
        },
        'click .ds-prepare': function() {
            var contact = this.contactSelector.$el.select2('val');

            if (contact) {
                var self = this;

                this.showLoading(true);

                $.ajax({
                    type: 'POST',
                    url: '/integrations/docusign/envelope_url',
                    dataType: 'json',
                    contentType: "application/json;charset=utf-8",
                    data: JSON.stringify({
                        opportunity_id: this.model.get('id'),
                        role_name: this.roleSelector.$el.select2('val'),
                        template_id: this.templateSelector.$el.select2('val'),
                        contact_id: contact,
                        redirect_url: window.location.href
                    }),
                    success: function(data) {
                        window.open(data.url, '_self');
                    },
                    error: function() {
                        self.trigger('close');
                    }
                });
            }
            else {
                this.showError();
            }
        }
    },
    onRender: function() {
        var self = this;

        $.get('/integrations/docusign/envelope_url', function(data) {
            if (data.length === 0) {
                window.open('https://app.docusign.com/templates', '_self');
            }
            else {
                var templates = _.map(data, function(d) {
                    return {
                        id: d.template_id,
                        name: d.template_name,
                        roles: _.map(d.role_names, function(r) { return { id: r }; })
                    };
                });

                // ...
                self.roleSelector = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.roleSelector,
                    text: 'id',
                    data: templates[0].roles,
                    value: templates[0].roles[0],
                    options: {
                        placeholder: 'Select a role',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'role-select-popover popover'
                    }
                });

                self.templateSelector = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.templateSelector,
                    text: 'name',
                    data: templates,
                    value: templates[0],
                    options: {
                        placeholder: 'Select a template',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'template-select-popover popover'
                    }
                });

                self.contactSelector = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.contactSelector,
                    url: '/individuals',
                    search: true,
                    text: 'title',
                    options: {
                        placeholder: 'Search for an individual',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'individual-select-popover popover select2-drop-wider',
                        minimumInputLength: 1
                    }
                });

                self.listenTo(self.templateSelector, 'change', function(item) {
                    self.roleSelector.options.data.results = item.roles;

                    if (item.roles.length > 0) {
                        self.roleSelector.$el.select2('val', item.roles[0].id);
                    }
                });

                self.listenTo(self.contactSelector, 'change', function() {
                    self.hideError();
                });

                self.showLoading(false);
            }
        });
    },
    onShow: function() {
        this.$el.parent().attr('id', 'docusign-region');
    },
    showLoading: function(show) {
        this.ui.loader.toggleClass('hide', !show);
        this.ui.content.toggleClass('hide', show);
        this.ui.footer.toggleClass('hide', show);
    },
    showError: function() {
        this.ui.contactSelector.select2('container').addClass('validation_error');
        this.ui.errorMessage.addClass('invalid');
    },
    hideError: function() {
        this.ui.contactSelector.select2('container').removeClass('validation_error');
        this.ui.errorMessage.removeClass('invalid');
    }
});

var TagItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tag',
    template: Handlebars.compile('{{name}}'),
});

OpportunityView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-deal content-container',
    template: Handlebars.compile(opportunityViewTemplate),
    regions: {
        opportunityContainerRegion: '.overview-container',
        activityFeedRegion: '.notes-pane',
        taskFeedRegion: '.tasks-pane',
        aftercareRegion: '.aftercare-pane',
        conversationsRegion: '.conversations-pane',
        appointmentsRegion: '.appointments-pane',
        choicesRegion: '.choices-pane',
        customFieldsRegion: '.custom-fields-container',
        checklistsRegion: '.checklists-container'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .toggle-elephant': 'toggleElephant',
        'click .organization-link': 'goToOrganization',
        'click .edit.has-permission': 'startEditing',
        'click .cancel': 'cancelEditing',
        'click .save': 'saveEditing',
        'click .tabs a': 'showTab',
        'click .close-view': 'closeView',
        'resize': 'scrollbar'
    },
    ui: {
        'activity': '.activity-container',
        'aftercareTab': '.aftercare-tab',
        'conversationsTab': '.conversations-tab',
        'appointmentsTab': '.appointments-tab',
        'choicesTab': '.choices-tab'
    },
    initialize: function(options) {
        if ( !this.model.id ) {
            this.creatingNewItem = true;
            this.model.set("owner", { id: app.user.get('id'), name: app.user.get('name') });
            if (this.options.fromIndividualModel) {
                this.model.set('individuals', [{ id: this.options.fromIndividualModel.get('id') }]);
            }
        }

        if (options.sectionId) {
            const preferences = app.user.get('client').preferences;

            if (preferences && preferences.deals_sections) {
                const dealsSections = JSON.parse(preferences.deals_sections);
                this.section = dealsSections.find(s => s.id === options.sectionId);
            }
        } else if (options.section) {
            this.section = options.section;
        }

        this.editing = this.options.editing;

        var self = this;
        this.controller = new DetailViewController({
            model: this.model,
            onSaveError: function(model, response, settings) {
                var detail;

                try {
                    detail = JSON.parse(response.responseText).detail;
                }
                catch (e) {
                }

                if (detail.exception === 'OpportunityPhaseChangeNotAllowed' ||
                detail.exception === 'OpportunityPhaseGateNotClear') {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            var message = detail.message
                            if (detail.exception === 'OpportunityPhaseGateNotClear'){
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }
                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }

                if (!self.editing) {
                    var overview = self.opportunityContainerRegion.currentView;
                    overview.ui.edit_phase.select2('val', overview.prevValuePhaseId);
                    self.controller.changes = {};
                }
            }
        });

        this.listenTo(vent, 'update-checklist', function() {
            this.createChecklistsView();
        });

        this.listenTo(vent, 'update-tasks', function() {
            this.createTasksView();
        });

        this.listenTo(this.controller, 'editing:start',
            this.onStartEditing);
        this.listenTo(this.controller, 'editing:end',
            this.onEndEditing);
        this.listenTo(this.model, 'sync', this.onModelSync);
        this.listenTo(this.model, 'destroy', this.onModelDestroy);

        if (AppConfig.getValue('deals.groups.recently_viewed.visible', true) && this.model.get('id')) {
            $.ajax({
                url: '/users/' + app.user.get('id') + '/recent_opportunities/items/' + this.model.get('id'),
                contentType: 'application/json',
                processData: false,
                dataType: 'json',
                type: 'PUT'
            });
        }

        this.initialRightTab = this.options.rightTab;
    },
    toggleElephant: function(ev) {
        $(ev.currentTarget).toggleClass('elephant');
    },
    startEditing: function() {
        this.controller.startEditing();
    },
    saveEditing: function() {
        if (this.manageCustomFields()) {
            this.controller.saveEdition();
        }
    },
    /**
     * Checks whether custom fields are changed, are there valid values and marks changes in controller if so.
     * Shows and hides error fields accordingly.
     *
     * @returns {boolean}   whether custom fields are ready to be saved
     */
    manageCustomFields: function() {
        var customFieldsRegion = this.customFieldsRegion && this.customFieldsRegion.currentView;
        var opportunityContainerRegion = this.opportunityContainerRegion.currentView;
        if (customFieldsRegion) {
            if (customFieldsRegion.valuesAreValid()) {
                customFieldsRegion.showInvalidFieldsError(false);
                if (customFieldsRegion.hasChanges()) {
                    this.controller.addChange('custom_fields', customFieldsRegion.getChanges());
                }
            }
            else {
                opportunityContainerRegion.error_messages.remove.call(opportunityContainerRegion);
                customFieldsRegion.showInvalidFieldsError(true);

                var changes = _.extend(this.model.attributes, this.controller.changes);
                var errors = this.model.validate(changes);
                opportunityContainerRegion.showInvalidFields(errors);

                return false;
            }
        }

        return true;
    },
    cancelEditing: function() {
        this.controller.cancelEdition();
    },
    onStartEditing: function() {
        var view = this;
        this.listenTo(vent, 'shortcut:save', function(ev) {
            var target = $(ev.target);

            // if we have a input with focus we made it lost and gain again to force to save the data inside it
            if (target && target.is('input')) {
                target.blur();
                target.focus();
            }

            view.saveEditing();
        });

        // ...
        this.$el.addClass('edit-mode');
        this.$el.find('.related-content').hide();
        // Show details pane on edit
        this.$el.find('.details-tab a').tab('show');

        if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
            this.customFieldsRegion.currentView.onStartEditing();
        }

        this.scrollbar();
    },
    onEndEditing: function() {
        this.stopListening(vent, 'shortcut:save');

        // Cancel create new item
        if (!this.model.id) {
            appContent.pop({});
            return;
        }

        // Exit edit mode
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.showOverviewView(DealContainerView, {section: this.section});
            this.$el.removeClass('edit-mode');
        }
        else {
            this.$el.removeClass('edit-mode');
            this.$el.find('.related-content').show();

            // Show details pane on edit
            this.$el.find('.details-tab a').tab('show');

            if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
                this.customFieldsRegion.currentView.onEndEditing();
            }

            this.scrollbar();
        }
    },
    onModelSync: function() {
        if ( this.creatingNewItem ) {
            this.creatingNewItem = false;
            this.editing = false;
            this.render();
            vent.trigger('AppContent:contentChange');
        }
    },
    onWindowResize: function() {
        var display = this.ui.activity.css('display') !== 'none';
        if (this.lastDisplay === display) { return; }
        this.lastDisplay = display;

        this.updateActivityTab($('#notes-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#tasks-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#aftercare-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#conversations-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#appointments-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#choices-pane'), $('.overview.content'), this.ui.activity);

        this.$el.find('.tabs .active').removeClass('active');
        this.$el.find('.detail-nav .tabs .details-tab a').tab('show');
        if (display) {
            if (this.initialRightTab === 'Task') {
                this.$el.find('.activity-container .tabs .tasks-tab a').tab('show');
                delete this.initialRightTab;
            }
            else {
                this.$el.find('.activity-container .tabs .notes-tab a').tab('show');
            }
        }
    },
    initActivityViewTinyMCE: function() {
        var activityView = this.activityFeedRegion && this.activityFeedRegion.currentView;

        activityView && activityView.initTinyMCE(activityView);
    },
    removeActivityViewTinyMCE: function() {
        var activityView = this.activityFeedRegion.currentView;

        if (activityView) {
            activityView.removeTinyMCE(activityView);
            // exit edit mode for list elements not to break TinyMCE
            activityView.listRegion.currentView && activityView.listRegion.currentView.children.each(function (item) {
                item.exitEditMode && item.exitEditMode();
            });
        }
    },
    updateActivityTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }

        var self = this;

        this.removeActivityViewTinyMCE();

        location.append(content);

        _.defer(function () {
            self.initActivityViewTinyMCE();
        });
    },
    updateTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }
        location.append(content);
    },
    onShow: function() {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.$el.parent('.region').addClass('non-condensed-detail-region');
            $('#app-stacks').addClass('non-condensed-app-stacks');
        }
    },
    onClose: function() {
        this.controller.close();
        $(window).off('resize', this.onWindowResize);
        this.$el.parent('.region').removeClass('non-condensed-detail-region');
        $('#app-stacks').removeClass('non-condensed-app-stacks');
    },
    onRender: function() {
        var view = this,
            model = this.model;

        // New item styling if no model.id is present
        this.$el.toggleClass('is-new', !model.id);

        $(window).on('resize', $.proxy(this.onWindowResize, this));
        this.manageViewOptions();

        var promises = [];
        var phases = new PhasesCollection(app.globalData.phasesInfo.phases);
        var funnels = new FunnelsCollection(app.globalData.funnelsInfo.funnels);

        $.when(...promises).done(function() {
            phases.defaultSort();

            view.controller.phases = view.buildPhasesDict(phases);
            view.controller.funnels = funnels;

            if (model.id) {
                view.model.fetch({
                    extraFields: ['organization'],
                    success: function() {
                        view.onModel(model);

                        if (view.editing) {
                            view.controller.startEditing();
                        }
                    }
                });
            }
            // when opening form for a new deal
            else {
                var bucketCollection = new BucketsCollection();
                bucketCollection.fetch({
                    sortOn: {
                        attribute: 'name'
                    },
                    success: function(collection) {
                        var buckets = _.map(collection.models, function(bucket) {
                            return {
                                id: bucket.get('id'),
                                name: bucket.get('name'),
                                value: 0
                            };
                        });

                        view.opportunityContainerRegion.show(new DealContainerView({
                            model: view.model,
                            phases: view.controller.phases,
                            funnels: view.controller.funnels,
                            buckets: buckets,
                            initialData: view.options.initialData,
                            section: view.section,
                            parent: view,
                        }));
                        view.listenTo(view.opportunityContainerRegion.currentView, 'close-view', function() {
                            view.trigger('view:close', view);
                        });
                        view.listenTo(view.opportunityContainerRegion.currentView, 'replace-view:edit', function() {
                            view.opportunityContainerRegion.currentView.setEditing(true, true);
                        });
                    }
                });
            }

            view.manageViewOptions();
        });
    },
    buildPhasesDict: function(phases) {
        var pdict = {};
        var wonLostData = [];

        _.each(phases.models, function(model) {
            var phaseData = {
                    id: model.get('id'),
                    text: model.get('name'),
                    default_weight: model.get('default_weight'),
                    order: model.get('order')
                };

            // ...
            var phaseType = model.get('phase_type');

            if ((phaseType === 'won') || (phaseType === 'lost')) {
                wonLostData.push(phaseData);
            }
            else {
                var fid = model.get('funnel_id');

                if (pdict[fid]) {
                    pdict[fid].push(phaseData);
                }
                else {
                    pdict[fid] = [phaseData];
                }
            }
        });

        // ... add won & lost phases to all funnels
        _.each(pdict, function(value, key) {
            pdict[key].push.apply(pdict[key], wonLostData);
        });

        return pdict;
    },
    onOpportunityBucket: function(collection) {
        if (this.isClosed) { return; }

        var buckets = [];
        _.each(collection.models, function(model) {
            buckets.push({
                id: model.get("id"),
                name: model.get("name"),
                value: 0
            });
        });
        this.model.set("buckets", buckets);
    },
    showOverviewView: function(OverviewViewLayout, viewOptions) {
        viewOptions = viewOptions || {};

        var overviewView = new OverviewViewLayout(_.extend({
            funnels: this.controller.funnels,
            phases: this.controller.phases,
            parent: this,
            model: this.model
        }, viewOptions));

        if (OverviewViewLayout === DealContainerView) {
            this.listenTo(overviewView, 'close-view', this.closeView);
            this.listenTo(overviewView, 'replace-view:edit', function() {
                overviewView.setEditing(true, true);
            });
            this.listenTo(overviewView, 'go-to-organization', this.goToOrganization);
        }
        this.opportunityContainerRegion.show(overviewView);
    },
    onModel: function(model) {
        if (this.isClosed) {
            return;
        }

        var view = this;

        var OverviewViewLayout = OverviewView;
        var viewOptions = {};

        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            OverviewViewLayout = DealContainerView;
            viewOptions = {section: this.section};
        }

        if ( this.opportunityContainerRegion ) {
            this.showOverviewView(OverviewViewLayout, viewOptions);
        }

        view.onActivityFeed(model);

        this.createTasksView();
        this.createAftercareView();
        this.createConversationsView();
        this.createAppointmentsView();
        this.createChoicesView();

        this.onWindowResize();

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.initCustomFieldsView();
        }

        this.scrollbar();
        this.manageViewOptions();

        if(!this.checklistsRegion.currentView){
            this.createChecklistsView();
        }

    },
    createAftercareView: function() {
        if (AppConfig.getValue('deals.aftercare.enable_for', false, this.model)) {
            this.ui.aftercareTab.removeClass('hide');

            const aftercareView = new AftercareView({
                model: this.model
            });

            this.aftercareRegion.show(aftercareView);

            const self = this;

            _.defer(function() {
                self.$el.find('#aftercare').click();
            });
        }
    },
    createAppointmentsView: function() {
        if (!AppConfig.getValue('app_nav_items.appointments.visible')) {
            return;
        }

        this.ui.appointmentsTab.removeClass('hide');

        const appointmentsView = new AppointmentsView({
            model: this.model,
            type: 'opportunity',
            badge: this.$el.find('.appointments-badge')
        });

        this.appointmentsRegion.show(appointmentsView);
    },
    createConversationsView: function() {
        const self = this;

        AppConfig.getValue('conversations.enable_for_deal', false, {
            model: this.model,
            callback: function(enabled) {
                if (enabled) {
                    self.ui.conversationsTab.removeClass('hide');

                    const conversationsView = new ConversationsView({
                        model: self.model,
                        type: 'opportunity',
                        badge: self.$el.find('.messages-badge'),
                        conversationId: self.options.conversation_id
                    });

                    self.conversationsRegion.show(conversationsView);

                    if (self.options.conversation_id) {
                        _.defer(function() {
                            self.$el.find('#conversations').click();
                        });
                    }
                }
            }
        });
    },
    createChoicesView: function() {
        if (AppConfig.getValue('deals.choices.enable_for', false, this.model)) {
            this.ui.choicesTab.removeClass('hide');

            const choicesView = new ChoicesView({
                model: this.model,
                show: this.options.show_choices
            });

            this.choicesRegion.show(choicesView);

            if (this.options.show_choices) {
                const self = this;

                _.defer(function() {
                    self.$el.find('#choices').click();
                });
            }
        }
    },
    createTasksView: function() {
        const tasksView = new TasksView({
            related: this.model.toJSON()
        });

        this.taskFeedRegion.show(tasksView);

        const self = this;

        this.listenTo(tasksView, 'task:count:change', function(count) {
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }

        /*
        this.taskContainer = new TasksView.SingleTaskListContainer({
            filterModel: new Backbone.Model({
                where: {
                    'related_type': 'opportunity',
                    'related_id': this.model.get('id')
                }
            }),
            defaultAssigneeSelf: true,
            itemTitle: this.model.get('name'),
            entityType: 'opportunity',
            displayFilters: true
        });

        var self = this;

        this.taskFeedRegion.show(this.taskContainer);
        this.listenTo(this.taskContainer, 'task:count:change', function(count) {
            // Targets all instances of '.task-counter'
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }
        */
    },
    createChecklistsView: function() {
        const self = this

        app.shortTermCache.get(`/opportunities/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            var checklists = [];
            var checklistsCollection = new ChecklistsCollection(data);

            for (const checklist of checklistsCollection.models) {
                const clSettings = checklist.get('checklist_settings') || {};

                if (!clSettings.hidden) {
                    checklists.push(checklist);
                }
            }

            if (checklists.length > 0){
                self.ui.activity.addClass('checklist-content');
                self.checklistsRegion.show(new ChecklistsView({
                    model: self.model,
                    checklists: checklists,
                    entityType: 'opportunity'
                }));
            }
        });
    },
    onModelDestroy: function() {
        this.trigger('view:close', this);
    },
    onActivityFeed: function(model) {
        if ( this.activityFeedRegion ) {
            this.activityFeedRegion.show(new ActivityFeedView({
                parent: this,
                model: model
            }));
        }
    },
    showTab: function(ev) {
        ev.preventDefault();

        var tab = $(ev.currentTarget);
        var tabId = tab.attr('id');

        switch(tabId) {
            case 'notes':
                this.activityFeedRegion.currentView.fetchActivity();
                break;

            case 'tasks':
                this.taskFeedRegion.currentView.show();
                break;

            case 'appointments':
                this.appointmentsRegion.currentView.onActive();
                break;
        }

        if (!tab.parent().hasClass('active')) {
            tab.tab('show'); // Bootstrap tabs
            vent.trigger('opportunityTabs:change', tabId);
            this.scrollbar();
        }
    },
    goToOrganization: function(ev) {
        var orgShortId;

        if (ev) {
            ev.preventDefault();
        }

        orgShortId = this.model.get('organization_short_id') || this.model.get('organization_id');

        this.trigger('replace-view:organization:show', {id: orgShortId});
    },
    closeView: function(event) {
        if (event) {
            event.preventDefault();
        }
        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('view:close', this);
        });
    },
    getUrl: function() {
        var id = this.model.get('short_id') || this.model.id;

        if (this.section) {
            return `deals/section/${this.section.id}/${id ? id : 'new'}`;
        }

        return 'deals/' + (id ? id : 'new');
    },
    manageViewOptions: function() {
        var viewOptions = this.options.viewOptions || {};

        if (viewOptions.condensed && !this.isClosed) {
            this.$el.addClass('condensed');
            this.ui.activity.hide();
            this.lastDisplay = null;
            this.onWindowResize();
            this.scrollbar();
        }
    },
    scrollbar: _.debounce(function() {
        var view = this;
        if (!view.closed) {
            view.$el.nanoScroller();

            if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002') && this.model.id) {
                return;
            }

            if (view.opportunityContainerRegion && view.opportunityContainerRegion.$el) {
                view.$el.find('.content-container').nanoScroller();
            }
        }
    }, 500),
    initCustomFieldsView: function() {
        var view = this;
        var customFields = new CustomFieldsCollection();

        customFields.fetch({
            filterBy: [{
                attribute: 'view',
                value: 'deals'
            }],
            success: function() {
                if (view.isClosed) {
                    return;
                }

                var customFieldsView = new CustomFieldsView();

                customFieldsView.onDataLoaded({
                    model: view.model,
                    collectionDefines: customFields,
                    editing: view.controller.editing
                });

                view.listenTo(customFieldsView, 'editing:valueChanged', view.manageCustomFields);

                view.customFieldsRegion.show(customFieldsView);
                view.scrollbar();
            }
        });
    }
});

/*
 * The main area of the detail page, with the header, communications,
 * locations, comments, related deals...
 */
OverviewView = Marionette.Layout.extend({
    tagName: 'div',
    className: 'overview content',
    template: Handlebars.compile(overviewTemplate),
    ui: {
        favoriteStar: '.toggle-elephant',
        display_name: '.display-field.name',
        display_abbreviation: '.abbreviation',
        display_organization: '.organization-link',
        display_currency: '.deal-currency',
        display_value: '.deal-value',
        display_funnel: '.deal-funnel',
        display_phase: '.deal-phase',
        display_close_date: '.close-date',
        display_status: '.deal-status',
        display_weight: '.deal-weight',
        display_comments: '.more-info',
        display_owner: '.detail-owner .display-field',
        edit_name: '.field-name',
        edit_abbreviation: '.field-abbreviation',
        edit_organization: '.field-organization',
        edit_currency: '.field-currency',
        edit_value: '.field-deal-value',
        edit_value_button: '.edit-value-button',
        edit_funnel: '.field-funnel',
        edit_phase: '.field-phase',
        edit_close_date: '.field-close-date',
        edit_status: '.field-status',
        edit_weight: '.field-weight',
        edit_comments: '.field-more-info',
        error_messages: '.error-message',
        edit_owner: '.field-owner',
        editTags: '.field-tags',
        // comments_container: '.more-info-container',
        header: '.detail-header',
        tooltips: '[data-toggle=tooltip]',
        deal_value_container: '.deal-value-container',
        archive_address: '.archive-address .link',
        docusignContainer: '.docusign-container'
    },
    events: {
        'click .delete-button': function() {
            app.dirtyModelHandler.confirm(this, function() {
                var self = this,
                    mbContent = {
                        accept_is_negative: true,
                        message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({name: this.model.get('name')}),
                        icon: 'icon-trashcan'
                    };
                MessageBox.showYesNo(mbContent, this, function () {
                    self.controller.destroyModel();
                    vent.trigger('deal:delete');
                    vent.trigger('model:delete');
                });
            });
        },
        'click .send-to-docusign': function(ev) {
            ev.preventDefault();

            this.docusignRegion.show(new docusignView({
                model: this.model
            }));
        },
        'click .add-to-elephants': 'addToElephants',
        'click .remove-from-elephants': 'removeFromElephants',
        'change .field-name': 'change_name',
        'change .field-abbreviation': 'changeAbbreviation',
        //'blur .field-organization': 'change_organization',
        'focus .field-close-date': 'showDatePicker',
        'change .field-funnel': 'change_funnel',
        'change .field-phase': 'change_phase',
        'change .field-status': 'change_status',
        'change .field-weight': 'change_weight',
        'change .field-currency': 'change_currency',
        'change .field-close-date': 'change_close_date',
        'change .field-more-info': 'change_comments',
        //'blur .field-owner': 'change_owner',
        'click .edit-permissions.has-permission': 'showACL',
        'focus .field-deal-value': 'onDealValueFocus',
        'click .deal-value-container .done': function() {
            if (this.bucketsValueChanged) {
                this.controller.addChange('buckets', this.model.get('buckets'));
            }

            this.hideBuckets();
            // Focus next edit icon - may need refactoring for better failsafe
            this.ui.deal_value_container.next('.inplace-editable-field-container').find('.edit-icon:first').focus();
        },
        'click .has-permission .inplace-editable-field-container .edit-icon': 'inplaceEdit',
        'keydown .has-permission .inplace-editable-field-container .edit-icon': 'inplaceEdit'
    },
    regions: {
        bucketListRegion: '.bucket-list-container',
        relatedContactsRegion: '.related-contacts',
        templatesRegion: '.templates-container',
        relatedDealsRegion: '.related-deals',
        relatedGroupsRegion: '.related-groups',
        relatedFilesRegion: '.related-files',
        tagsRegion: '.tags-container .display-field',
        docusignRegion: {
            selector: '.docusign-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    templateHelpers: function () {
        var owner = this.model.get('owner'),
            org = this.model.get('organization');

        return {
            owner: owner ? { name: owner['name'] } : null,
            organizationName: this.model.organizationName(),
            organizationId: org ? org['id'] : null
        };
    },
    initialize: function(options) {
        var view = this;
        this.controller = options.controller;

        this.listenTo(this.model, 'sync', this.onModelSync);

        this.listenTo(this.controller, 'editing:cancel', function() {
            // we restore the currency
            if (this.model.get('currency')) {
                view.ui.edit_currency.select2('val', this.model.get('currency'));
                view.controller.trigger('bucketchange', this.model.get('buckets'));
            }

            view.error_messages.remove.call(view);
            view.hideBuckets();
        });

        this.listenTo(this.controller, 'editing:start', function() {
            view.error_messages.unhide.call(view);

            if (view.model.isNew()) {
                view.change_currency();
            }

            view.ui.edit_name.select();
        });

        this.listenTo(this.controller, 'editing:save:after', function() {
            view.error_messages.remove.call(view);
            //Avoid hidding when not in edit mode
            if(this.controller.editing) {
                view.hideBuckets();
            }
        });

        this.listenTo(this.controller, 'editing:save:before', function() {
            view.error_messages.remove.call(view);
        });

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.showInvalidFields(errors);
        });

        this.listenTo(this.model, 'change:buckets', function() {
            this.showBucketsView();
        });
    },
    showInvalidFields: function(errors) {
        if (errors) {
            if (errors.name) {
                this.ui.edit_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.name)
                    .addClass('invalid');
            }
            if (errors.organization) {
                this.ui.edit_organization
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.organization)
                    .addClass('invalid');
            }
            if (errors.abbreviation) {
                this.ui.edit_abbreviation
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.abbreviation)
                    .addClass('invalid');
            }
        }

        this.$el.find('.validation_error:first').focus();
    },
    onRender: function() {
        var view = this,
            organization,
            contactModel = this.options.parent.options.contactModel;

        var preferences = app.user.get('preferences'),
            dashboard = preferences && preferences['default_dashboard'];
        // If the user is a CEO or a manager, apply a class to show tools like toggle-elephant
        if (dashboard === 'ceo' || dashboard === 'sales_manager') {
            view.$el.addClass('manager-user');
        }

        this.previousCloseDate = this.ui.edit_close_date.val();

        organization = this.model.get('organization');

        if (organization) {
            organization = new OrganizationModel(organization);
            this.onIndividuals(new (IndividualsCollection.extend({
                urlRoot: function() {
                    return organization.url() + '/individuals';
                },
                defaultSortOn: [{
                    attribute: 'full_name',
                    order: 'asc'
                }]
            })));
        }
        else if (contactModel) {
            this.listenTo(this.controller, 'editing:start', function() {
                var name = contactModel.get('last_name') + '\'s Organization';
                view.controller.addChange('organization', new OrganizationModel({ name: name }));
                view.controller.linkOrganizationToContact(contactModel);
                view.orgSelect.setValue({ id: null, title: name });
            });
        }

        this.templatesRegion.show(new TemplatesListView({ deal: this.model }));

        this.initTooltips();
        this.initSelect2();

        if (!this.model.isNew()) {
            this.showBucketsView(true);
        }

        this.listenTo(this.controller,'bucketchange', function(buckets) {
            this.updateBucketsValue(buckets);
            this.model.set('buckets', buckets);
            this.bucketsValueChanged = true;
        });
        this.controller.trigger('bucketchange', this.model.get('buckets'));

        // is the user connected to docusign?
        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-3621')) {
            var self = this;
            $.get('/integrations?status=authorized', function(data) {
                if (_.findWhere(data, {id: 'docusign'})) {
                    self.ui.docusignContainer.removeClass('hide');
                }
            });
        }
    },

    showBucketsView: function(reset) {
        this.bucketsValueChanged = false;

        if (!this.bucketListRegion.currentView) {
            this.bucketListRegion.show(new BucketListView({
                collection: new Backbone.Collection(this.model.get('buckets')),
                controller: this.controller
            }));
        }
        else if (reset) {
            this.bucketListRegion.currentView.collection.reset(this.model.get('buckets'));
        }
    },

    updateBucketsValue: function(buckets) {
        var currency = app.user.get('client')['default_currency'];

        if (this.controller.isEditing()) {
            currency = this.ui.edit_currency.select2('val');
        }
        else if (!this.model.isNew()) {
            currency = this.model.get('currency');
        }

        var value = 0;
        var formattedValue = 0;

        _.each(buckets, function(bucket) {
            //The + sign is to convert the string to number
            var bval = +bucket.value;
            value += bval;
            formattedValue = +Currency.format(currency, formattedValue + bval, {excludeSymbols: true});
        });

        if (value) {
            this.ui.display_value.text(Currency.format(currency, formattedValue, {noRounding: true}));
            this.ui.edit_value.text(value)
                .removeClass('no-value');
        } else {
            this.ui.display_value.text(Currency.format(currency, 0));
            this.ui.edit_value.text(TextManager.getText('ID_DEAL_VALUE'))
                .addClass('no-value');
        }
    },
    onModelSync: function() {
        if (this.isClosed) {
            return;
        }

        var isFavorite = this.model.get('is_favorite') || false,
            name = this.model.get('name'),
            abbreviation = this.model.get('abbreviation'),
            org_id = this.model.get('organization_id'),
            org = this.model.get('organization'),
            org_name = org ? org['name'] : null,
            close_date = this.model.get('expected_close_date'),
            funnel_name = this.model.get('funnel').name,
            phase_name = this.model.get('phase_name'),
            currency = this.model.get('currency'),
            funnel_id = this.model.get('funnel_id'),
            phase_id = this.model.get('phase_id'),
            status = this.model.get('status'),
            weight = this.model.get('weight'),
            comments = this.model.get('comments'),
            owner = this.model.get('owner');

        this.showBucketsView(true);

        this.prevValuePhaseId = phase_id;
        this.ui.favoriteStar.toggleClass('elephant', isFavorite);

        this.ui.display_name.text(name);
        this.ui.display_abbreviation.text(abbreviation || TextManager.parseText('${ID_DEAL, capitalize}'));
        this.ui.display_organization.text(org_name);
        this.ui.display_funnel.text(funnel_name);
        this.ui.display_phase.text(phase_name);
        this.ui.display_status.text(_.find(statusData, function(s) { return s.id === status}).text);
        this.ui.display_weight.text(weight);
        this.ui.edit_weight.blur(); // loose focus after hit enter in inplace editing
        this.ui.display_currency.text(Currency.composeCurrencyName(currency));
        this.ui.display_value
            .data('tooltip', false) // Remove previous tooltip
            .tooltip({
                title: Currency.composeCurrencyName(currency)
            });

        if (close_date) {
            this.ui.display_close_date.text( dateFormat.entityInformationFormat(new Date(close_date)));
            this.$el.find('.close-date-container').removeClass('no-value');
        } else {
            this.ui.display_close_date.text('Unknown');
            this.$el.find('.close-date-container').addClass('no-value');
        }
        this.ui.display_comments.html((comments ? linkify(comments) : 'No information to display'));
        this.ui.edit_name.val(name);
        this.ui.edit_abbreviation.val(abbreviation);
        this.ownerSelect.setValue({
            name: owner.name,
            id: owner.id
        });
        this.orgSelect.setValue(org_id ? {id: org_id, title: org_name} : null);
        this.ui.edit_close_date.val($.datepicker.formatDate(
            'M dd, yy', close_date));

        this.ui.edit_funnel.select2('val', funnel_id);
        this.ui.edit_phase.select2('val', phase_id);
        this.ui.edit_status.select2('val', status);
        this.ui.edit_currency.select2('val', currency);

        this.ui.edit_comments.val(comments);
        // this.ui.comments_container.toggleClass(
        //     'no-value', !comments.length);

        this.ui.display_owner.text(this.model.get('owner')['name']);

        var archiveAddress = this.model.get('abbreviation') + '.archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');

        this.ui.archive_address.attr('href', 'mailto:?bcc=' + archiveAddress);
        this.ui.archive_address.text(archiveAddress);

        this.relatedGroupsRegion.show(new GroupListContainsView({
            elementType: 'opportunities',
            itemId: this.model.get('id')
        }));

        var self = this;
        var relatedFiles = new RelatedFilesListView({
            collection: new (RelatedFilesCollection.extend({
                urlRoot: function() {
                    return '/opportunities/' + self.model.get('id') + '/related_files';
                }
            })),
            model: this.model,
            elementType: 'opportunities'
        });

        this.relatedFilesRegion.show(relatedFiles);

        this.tagsRegion.show(new Marionette.CollectionView({
            tagName: 'ul',
            itemView: TagItemView,
            collection: new Backbone.Collection(this.model.get('tags'))
        }));

        if (this.tagsSelect) {
            this.tagsSelect.setValue(this.model.get('tags'));
        }

        this.options.parent.scrollbar();
    },
    inplaceEdit: function(ev) {
        var keycode = (ev.keyCode ? ev.keyCode : ev.which),
            clickOrEnterEvent = (ev.type === 'click' || keycode === 13),
            container = $(ev.currentTarget).closest('.inplace-editable-field-container'),
            field = container.find('.editable-field:first');

        if (!clickOrEnterEvent) {
            return;
        }

        // Check if select2, faux-input (deal value), or regular field
        if (container.has('.select2-container').length) {
            field = container.find('.editable-field:last'); // Original field is last child

            container.addClass('editing');

            field.select2('open');

            field.one('select2-close', function() {
                container.removeClass('editing');
                container.find('.edit-icon').focus(); // Maintain focus position on page
            });
        }
        else if (container.hasClass('deal-value-container')) {
            this.onDealValueFocus();
        }
        else {
            container.addClass('editing');

            field.on('blur.end_editing', function(){
                    container.removeClass('editing');
                    $(this).off('blur.end_editing');
                })
                .focus();
        }
    },
    onDealValueFocus: function(ev) {
        if (ev) {
            ev.stopPropagation();
        }

        if (this.isClosed) {
            return;
        }

        if (this.$el.find('.details-pane').hasClass('has-permission')) {
            this.showBuckets();
        }
    },
    showBuckets: function() {
        var editPermission = this.$el.find('.details-pane').hasClass('has-permission');

        if (editPermission) {
            this.ui.deal_value_container.addClass('edit-mode editing');
            this.ui.edit_value.attr('tabindex','-1');
            this.ui.deal_value_container.find('.bucket-list-container .editable-field:first').focus();
        }

        this.$el.trigger('resize');
    },
    hideBuckets: function(ev) {
        if (ev) {
            ev.preventDefault();
        }

        $('body').off('focusin.close-buckets mousedown.close-buckets');

        if (this.bucketListRegion.currentView) {
            this.ui.edit_value.attr('tabindex', '0');
            this.ui.deal_value_container.removeClass('edit-mode editing');
            this.$el.trigger('resize');
        }
    },
    onIndividuals: function(individuals) {
        // don't create for new items in edit mode
        if (!this.model.get('id')) {
            return;
        }
        var relatedContactsView,
            organization = this.model.get('organization'),
            hasPermissions;

        if (organization) {
            hasPermissions=security.checkPermission('edit', organization);
        }
        else {
            hasPermissions=true;
        }

        relatedContactsView = createIndividualsList(
            this.options.parent,
            individuals,
            {
                hasPermissions: hasPermissions,
                hideCheckbox: false
            },
            {
                addItemOptions: {
                    selectDropdownExtraClasses: 'individual-select-popover',
                    selectFormatResult: function(item, container) {
                        var message = '{{title}}'

                        if (item.item.organization_name) {
                            message += '<div class="organization-name">{{orgName}}</div>';
                            container.addClass('has-organization-name');
                        }

                        return Handlebars.compile(message)({title: item.title, orgName: item.item.organization_name});
                    }
                }
            },
            null,
            new OrganizationModel(organization),
            this.model
        );

        if ( this.relatedContactsRegion ) {
            this.relatedContactsRegion.show(relatedContactsView);
        }
    },
    showDatePicker: function(ev) {
        $(ev.currentTarget).datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            dateFormat: 'M dd, yy', // TODO: i18n
            timezone: "+0000"
        });
    },
    addToElephants: function(ev) {
        var model = this.model,
            user = app.user;

        $(ev.currentTarget).tooltip('hide');

        // TODO: We shouldn't be having this logic on a view, should be
        // on the api/models layer
        $.ajax({
            url: user.url() + '/favorite_opportunities/items/' + model.id,
            dataType: 'json',
            type: 'PUT',
            contentType: "application/json;charset=utf-8",
            data: JSON.stringify({id: model.id}),
            complete: function() {
                model.fetch();
                vent.trigger('deal:elephant:added');
            }
        });
    },
    removeFromElephants: function(ev) {
        var model = this.model,
            user = app.user;

        $(ev.currentTarget).tooltip('hide');

        // TODO: We shouldn't be having this logic on a view, should be
        // on the api/models layer
        $.ajax({
            url: user.url() + '/favorite_opportunities/items/' + model.id,
            contentType: "application/json;charset=utf-8",
            type: 'DELETE',
            data: JSON.stringify({id: model.id}),
            complete: function() {
                model.fetch();
                vent.trigger('deal:elephant:removed');
            }
        });
    },
    change_name: function(ev) {
        this.controller.addChange('name', $(ev.target).val());
    },
    changeAbbreviation: function(ev) {
        this.controller.addChange('abbreviation', $(ev.target).val());
    },
    // Total value is now controlled by buckets. However, in future there may be the possibility of not having
    // buckets - this will require editing total value directly
    // change_value: function(ev) {
    //     this.controller.addChange('value', +($(ev.target).val()));
    // },
    change_organization: function(item) {
        var id = item && item.id,
            name = item && item.title;

        // change organization by id
        if (id) {
            this.controller.removeChange('organization_id');
            this.model.unset('organization_id');
            this.controller.addChange('organization', new OrganizationModel({id: id}));
        }
        // unset organization with organization_id:null
        else if (name === null || name.length === 0) {
            this.controller.removeChange('organization');
            this.model.unset('organization');
            this.controller.addChange('organization_id', null);
        }
        // change organization by creating new organization
        else {
            this.controller.removeChange('organization_id');
            this.model.unset('organization_id');
            this.controller.addChange('organization', new OrganizationModel({name: name}));
        }
    },
    change_phase: function() {
        this.prevValuePhaseId = this.model.get('phase_id');

        // to avoid multiple saves in view mode
        this.controller.changes['phase_id'] = this.ui.edit_phase.select2('val');

        var funnelId = this.ui.edit_funnel.select2('val');
        var phaseId = this.ui.edit_phase.select2('val');
        var phase = _.find(this.controller.phases[funnelId], function(phase) {
            return phase.id === phaseId;
        });
        this.ui.edit_weight.val(phase.default_weight);
        this.change_weight();
    },
    change_status: function() {
        this.controller.addChange('status', this.ui.edit_status.select2('val') );
    },
    change_weight: function() {
        var val = this.ui.edit_weight.val();

        val = parseFloat(val) || 0;

        if (val < 0) {
            val = 0;
        }
        else if (val > 1) {
            val = 1;
        }

        this.controller.addChange('weight', val);
        this.ui.edit_weight.val(val);
    },
    change_funnel: function() {
        var funnelId = this.ui.edit_funnel.select2('val');

        // we have two changes: funnel and phase. On display mode to avoid
        // two saves, we change the funnel_id using the variable instead the function
        this.controller.changes['funnel_id'] = funnelId;
        this.ui.edit_phase.select2({
            dropdownCssClass: 'phase-select-popover popover',
            data: this.controller.phases[funnelId]
        });

        var phaseId = this.controller.phases[funnelId][0].id;
        this.ui.edit_phase.select2('val', phaseId);
        this.change_phase();
    },
    change_currency: function() {
        var currency = this.ui.edit_currency.select2('val');

        this.controller.addChange('currency', currency);
        this.controller.trigger('bucketchange', this.model.get('buckets'));
        this.controller.trigger('currency:change', currency);
    },
    change_close_date: function(ev) {
        var $el = $(ev.currentTarget),
            str = $el.val().trim(),
            dt  = dateFormat.parseDate(str);

        if (Utilities.dateIsValid(dt)) {
            this.controller.addChange('expected_close_date', dateFormat.ISODate(dt));
            this.ui.edit_close_date.datepicker('setDate', dt);
            this.previousCloseDate = str;
        }
        else {
            $el.val(this.previousCloseDate);
        }
    },
    change_comments: function(ev) {
        this.controller.addChange('comments', $(ev.target).val());
    },
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
        this.controller.addChange('owner', {
            id: id,
            name: item && item.name
        });
    },
    changeTags: function(items) {
        var ids = _.map(items, function(item) {
            return {id: item.id};
        });

        this.controller.addChange('tags', ids);
    },
    showACL: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    initTooltips: function() {

        // Header tools
        this.ui.header.find('[data-toggle=tooltip]').tooltip({
            placement: 'bottom',
            container: 'body'
        });

        // All remaining tooltips
        this.ui.tooltips.tooltip({ container: 'body' });
    },
    initSelect2: function() {
        // Init funnel select 2
        var currentFunnel = this.model.get('funnel_id');
        var funnels = this.controller.funnels.models;
        var funnelData = _.map(funnels, function(model) {
                return {
                    id: model.id,
                    text: model.get('name')
                };
            });
        this.ui.edit_funnel.select2({
            dropdownCssClass: 'funnel-select-popover popover',
            data: funnelData
        });
        if (currentFunnel) {
            this.ui.edit_funnel.select2('val', currentFunnel);
        }

        // phase
        var currentPhase = this.model.get('phase_id');
        var phaseData = this.controller.phases[currentFunnel];
        this.ui.edit_phase.select2({
            dropdownCssClass: 'phase-select-popover popover',
            data: phaseData
        });
        if (currentPhase) {
            this.ui.edit_phase.select2('val', currentPhase);
        }

        // status
        var currentStatus = this.model.get('status');
        this.ui.edit_status.select2({
            dropdownCssClass: 'status-select-popover popover',
            data: statusData
        });
        if (currentStatus) {
            this.ui.edit_status.select2('val', currentStatus);
        }

        // currency
        var currentCurrency = this.model.get('currency') || app.user.get('client')['default_currency'];
        var currencyData = Currency.getUsedCurrenciesToSelect2Array(true);

        this.ui.edit_currency.select2({
            dropdownCssClass: 'currency-select-popover popover',
            data: currencyData
        });

        if (currentCurrency) {
            this.ui.edit_currency.select2('val', currentCurrency);
        }

        // ...
        var orgName = this.model.get('organization_name');
        var orgId = this.model.get('organization_id');
        var orgDesc = null;

        if (!orgName) {
            var organization = this.model.get('organization');
            if (organization) {
                orgName = organization.name;
            }
        }

        if (orgName && orgId) {
            orgDesc = {id: orgId, title: orgName};
        }

        this.orgSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_organization,
            url: '/organizations',
            search: true,
            createSearchChoice: true,
            id: 'title',
            text: 'title',
            value: orgDesc,

            options: {
                allowClear: true,
                placeholder: TextManager.getText('ID_SEARCH_FOR_AN_ORGANIZATION'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });
        this.listenTo(this.orgSelect, 'change', this.change_organization);

        var owner = this.model.get('owner');
        this.ownerSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_owner,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: {
                name: owner.name,
                id: owner.id
            },
            options: {
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.ownerSelect, 'change', this.change_owner);

        var self = this;

        _.defer(function() {
            self.tagsSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.editTags,
                value: self.model.get('tags'),
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: '+ Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            self.listenTo(self.tagsSelect, 'change', self.changeTags);
        });

        // Check for admin permissions
        if ( !security.checkPermission('change_ownership', this.model) ) {
            this.ownerSelect.$el.select2('readonly', true);
            this.ownerSelect.$el.select2('container').tooltip({
                title: "You don't have permission to edit owner",
                container: 'body'
            });
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

/*
 * The bucket view is used for displaying each of the bucket values for the deal
 */
BucketView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'bucket',
    template: Handlebars.compile(bucketItemTemplate),
    templateHelpers: function() {
        return {
            currency: this.controller.model.get('currency')
        };
    },
    ui: {
        display_value: '.bucket-value'
    },
    events: {
        'click .editable-field': 'selectValue',
        'change .editable-field': 'changeValue'
    },
    initialize: function(options) {
        var view = this;

        this.controller = options.controller;

        this.listenTo(this.controller, 'currency:change', function(currency) {
            view.showValue(currency);
        });
    },
    onRender: function() {
        this.$el.toggleClass('has-value', this.model.get('value') > 0);
    },
    selectValue: function(ev) {
        $(ev.currentTarget).select();
    },
    changeValue: function(ev) {
        var value = $(ev.currentTarget).val();

        if (value === '') {
            value = 0;
        }
        value = parseFloat(value);
        if (value < 0) {
            value = 0;
        }
        if (!_.isNaN(value)) {
            ev.currentTarget.value = value;

            this.model.set('value', value);
            this.trigger('bucketchange');
        }
        else {
            ev.currentTarget.value = this.model.get('value');
        }

        this.showValue();

        this.$el.toggleClass('has-value', this.model.get('value') > 0);
    },
    showValue: function(cur)
    {
        var currency = cur || this.controller.model.get('currency');
        this.ui.display_value.text(Currency.format(currency, this.model.get('value')));
    }
});

BucketListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'buckets',
    itemView: BucketView,
    itemViewOptions: function() {
        return {
            controller: this.controller
        };
    },
    initialize: function(options) {
        this.controller = options.controller;
        this.listenTo(this, 'itemview:bucketchange', function() {
            this.controller.trigger('bucketchange', this.collection.toJSON());

            if (this.controller.editing) {
                this.controller.addChange('buckets', this.collection);
            }
        });
    }
});

export default OpportunityView;

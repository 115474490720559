import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import ModalRegion from 'js/views/base/modal-region'
import MessageBox from 'js/views/message_box'
import htmlEditorTemplate from 'templates/html_editor.handlebars'
import saveAsTemplate from 'templates/html_editor_save_as.handlebars'
import templateItemView from 'templates/html_editor_template_view.handlebars'
import previewView from 'templates/html_editor_preview_view.handlebars'


var PreviewView = Marionette.Layout.extend({
    className: 'preview-container',
    template: Handlebars.compile(previewView),
    ui: {
        preview: '.preview',
        preview_scroll_mask: '.scroll-dummy'
    },
    events: {
        'click .select-template': function() {
            this.trigger('preview:click');
        }
    },
    onShow: function() {
        var view = this;
        // Link scrolling with mask
        this.ui.preview_scroll_mask.scroll(function(){
            view.ui.preview.contents().scrollTop($(this).scrollTop());
        });
    },
    setContent: function(content) {
        var fontFamily = this.$el.css('fontFamily');
        this.$el.removeClass('preview-selected');
        this.ui.preview.contents().find('body').empty().append($.parseHTML(content, null, false)).css('fontFamily', fontFamily);
        this.ui.preview_scroll_mask.find('.scroll-dummy-inner')
            .css('height', this.ui.preview.contents().find('html')[0].scrollHeight);
    }
});

var HTMLPreviewView = Marionette.Layout.extend({
    className: 'html-preview-container',
    template: Handlebars.compile(
        [
            '<iframe scrolling="no" frameBorder="0" tabindex="-1"/>',
            '<div class="template-preview-mask"></div>',
            '<div class="scroll-dummy"><div class="scroll-dummy-inner"></div></div>',
            '<div class="message">',
            'This is for preview purposes only. Different email clients may well display this email differently.',
            '</div>'
        ].join('')
    ),
    ui: {
        preview: 'iframe',
        preview_scroll_mask: '.scroll-dummy'
    },
    onRender: function() {
        this.ui.preview.on('load', function() {
            this.setContent(this.options.content);
        }.bind(this));
    },
    onShow: function() {
        this.$el.parent().addClass('html-preview-modal');
        var view = this;
        // Link scrolling with mask
        this.ui.preview_scroll_mask.scroll(function(){
            view.ui.preview.contents().scrollTop($(this).scrollTop());
        });
    },
    setContent: function(content) {
        var fontFamily = this.$el.css('fontFamily');
        this.ui.preview.contents().find('body').empty().append($.parseHTML(content, null, false)).css('fontFamily', fontFamily);
        this.ui.preview_scroll_mask.find('.scroll-dummy-inner')
            .css('height', this.ui.preview.contents().find('html')[0].scrollHeight);
    }
});

var SaveAsView = Marionette.ItemView.extend({
    className: 'save-as edit-form-modal',

    template: Handlebars.compile(saveAsTemplate),

    ui: {
        filename: '#filename',
        description: '#description',
        error_messages: '.error-message'
    },

    events: {
        'click .save': function(ev) {
            ev.preventDefault();

            var filename = this.ui.filename.val();

            if (!filename) {
                this.ui.filename
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('A file name is required')
                    .addClass('invalid');
            }
            else {
                this.trigger('save-as:save', filename, this.ui.description.val());
            }
        },
        'click .close': function() {
            this.trigger('save-as:close');
        }
    },

    initialize: function(options) {
        this.options = options;
    },

    onShow: function()
    {
        this.ui.filename.val(this.options.filename);
        this.ui.description.val(this.options.description);
        this.ui.filename.focus();
        this.ui.filename.select();
    }
});

var TemplateModel = Backbone.Model.extend({
});

var TemplateItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'template-item',
    template: Handlebars.compile(templateItemView),

    attributes: {
        tabindex: 0
    },

    ui: {
        thumbFrame: '.thumb-frame'
    },

    events: {
        'click .select-template': function(ev) {
            ev.preventDefault();
            this.$el.addClass('selected');
            this.trigger('select');
        },

        'click': 'focusItem',
        'focus': 'focusItem',

        'keydown': function(ev) {
            if ( !ev.keyCode ) {
                return;
            }

            // Enter
            if (ev.keyCode === 13) {
                this.$el.addClass('selected');
                this.trigger('select');
                return;
            }

            // Arrow up
            if (ev.keyCode === 38) {
                this.trigger('pass-focus');
            }
            // Arrow down
            else if (ev.keyCode === 40) {
                this.trigger('pass-focus', true);
            }
            else {
                return;
            }

            ev.preventDefault();
        }
    },

    onRender: function() {
        var content = this.model.get('content');
        if (content) {
            this.ui.thumbFrame.load(function() {
                $(this).contents().find('body')
                    .empty()
                    .append($.parseHTML(content, null, false))
                    .css('overflow', 'hidden');
            });
        }
    },

    focusItem: function(ev) {
        this.$el.addClass('highlight');
        this.trigger('focus');
    }
});

var TemplatesListView = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'template-list',
    itemView: TemplateItemView,
    onRender: function() {

        this.listenTo(this, 'itemview:focus', function(itemView) {
            this.onFocus(itemView.$el);
        });

        this.listenTo(this, 'itemview:pass-focus', function(itemView, next) {
            var direction = next ? 1 : -1;
            this.setFocus(direction);
        });

        this.listenTo(this, 'itemview:select', function(itemView) {
            this.$el.find('.selected').not(itemView.$el).removeClass('selected');
        });
    },
    setFocus: function(direction) {
        var focusedIndex,
            toIndex,
            nextItem;

        if (this.focusedItem()) {
            focusedIndex = this.collection.indexOf(this.focusedItem().model);
            toIndex = direction === 1 ? focusedIndex + 1 : focusedIndex - 1;
        } else {
            toIndex = direction === 1 ? 0 : this.collection.length - 1;
        }

        nextItem = this.collection.get(toIndex) && this.children.findByModel(this.collection.get(toIndex));

        if (nextItem) {
            nextItem.$el.focus();
            this.onFocus(nextItem.$el);
        }
    },
    onFocus: function(item) {
        this.$el.find('.highlight').not(item).removeClass('highlight');
    },
    focusedItem: function() {
        return this.children.filter(function(itemView) {
            return itemView.$el.hasClass('highlight');
        })[0];
    },
    removeFocus: function() {
        this.onFocus(null);
    }
});

var HTMLEditor = Marionette.Layout.extend({
    id: 'html-editor',
    template: Handlebars.compile(htmlEditorTemplate),
    ui: {
        richArea: '.rich-area',
        HTMLArea: '.html-area',
        plainTextArea: '.plain-text-area',
        editorInfo: '.editor-info',
        header: '.modal-header',
        footer: '.modal-footer',
        title: '.modal-header .title',
        showTemplatesButton: '.show-templates',
        saveas: '.save-as',
        scroll: '.content-container > .content',
        wysiwygButton: '.wysiwyg-button',
        htmlButton: '.html-button',
        plainButton: '.plain-button',
        editorButtons: '.header-tools > button',
        previewButton: '.preview'
    },
    regions: {
        saveAsRegion: {
            selector: '.save-as', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        previewRegion: {
            selector: '.preview-region', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        templatesListRegion: '.templates',
        templatePreview: '.template-preview'
    },
    events: {
        'click .cancel': function(ev) {
            ev.preventDefault();
            this.closeDialog();
        },
        'click .save': function() {
            if (this.getContent(true) === '') {
                var view = this;
                var mbContent = {
                    message: Handlebars.compile([
                            '<h5>Template empty!</h5>',
                            '<p>Are you sure you want to save an empty template?</p>'
                        ].join('')),
                    accept_button_text: 'Save',
                    cancel_button_text: 'Cancel'
                };

                MessageBox.showYesNo(mbContent, this, function() { // yes
                    view.saveTemplate(view.getContent(), view.format);
                });
            }
            else {
                this.saveTemplate(this.getContent(), this.format);
            }
        },
        'click .preview': function() {
            var preview = new HTMLPreviewView({ content: this.getContent({}) });
            this.previewRegion.show(preview);
        },
        'click .save-as': function() {
            if (this.getContent(true) === '') {
                var view = this;
                var mbContent = {
                    message: Handlebars.compile([
                            '<h5>Template empty!</h5>',
                            '<p>Are you sure you want to save an empty template?</p>'
                        ].join('')),
                    accept_button_text: 'Save',
                    cancel_button_text: 'Cancel'
                };

                MessageBox.showYesNo(mbContent, this, function() { // yes
                    view.manageSaveTemplateAsDialog();
                });
            }
            else {
                this.manageSaveTemplateAsDialog();
            }
        },
        'click .show-templates': function(ev) {
            ev.preventDefault();
            this.showTemplateList();
        },
        'click .show-editor': function(ev) {
            ev.preventDefault();
            this.hideTemplateList();
            this.showEditorByFormat(this.format, this.initialContent);
        },
        'click .wysiwyg-button': function() {
            var self = this;
            if (this.format === 'html') {
                this.confirmEditorChange(
                    'Are you sure you want to modify your custom HTML to make the email editable in the visual HTML editor?',
                    function() {
                        self.showEditorByFormat('wysiwyg', self.getContent());
                    }
                );
            }
            else {
                self.showEditorByFormat('wysiwyg', self.getContent());
            }
        },
        'click .html-button': function() {
            this.showEditorByFormat('html', this.getContent());
        },
        'click .plain-button': function() {
            var self = this;
            if (this.format === 'wysiwyg') {
                this.confirmEditorChange('Are you sure you want to remove all formatting?', function() {
                     self.showEditorByFormat('text', self.getContent({ plain: true }));
                });
            }
            else if (this.format === 'html') {
                this.showEditorByFormat('text', self.getContent({}));
            }
        },
        'keydown .templates-container > .content': function(ev) {
            var direction;

            // Return if keydown is on children
            if ( !ev.keyCode || !$(ev.target).is(this.ui.scroll) ) {
                return;
            }

            // Arrow up
            if (ev.keyCode === 38) {
                direction = -1;
            }
            // Arrow down
            else if (ev.keyCode === 40) {
                direction = 1;
            }
            else {
                return;
            }

            this.templatesList.setFocus(direction);

            ev.preventDefault();
        }
    },
    initialize: function() {
        this.initialContent = this.options.content;
        if (this.options.format) {
            this.format = this.options.format;
        }
        else {
            this.format = this.getFormatByExt(this.options.extension);
        }
    },
    /**
     * Used when switching between editors.
     *
     * @param text              string      confirmation message
     * @param successCallback   function    called after clicking on 'Yes'
     */
    confirmEditorChange: function(text, successCallback) {
        MessageBox.showYesNo(
            {
                message: Handlebars.compile('<h5>' + text + '</h5>'),
                accept_is_negative: true,
                accept_button_text: 'Continue',
                cancel_button_text: 'Cancel'
            },
            this,
            function() { // yes
                successCallback();
            }
        );
    },
    /**
     * Initializes TinyMCE. Sets reference to 'this.WYSIWYGEditor'.
     *
     * @param callback      function    called after initialization of TinyMCE
     */
    initializeWHYSIWYGEditor: function(callback) {
        var self = this;
        this.ui.richArea.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'fullpage code link charmap hr anchor pagebreak insertdatetime nonbreaking ' +
                      'visualblocks visualchars preview fullscreen table image',
            menubar: 'edit insert view format table',
            toolbar: 'undo redo | styleselect | bold italic | ' +
                      'alignleft aligncenter alignright alignjustify | ' +
                      'bullist numlist outdent indent | link image | ' +
                      'forecolor backcolor | code',
            height: '100%',
            resize: false,
            branding: false,
            oninit: function() {
                self.WYSIWYGEditor = this;
                self.setHtmlEditorHeight();
                callback();
            }
        });
    },
    saveTemplate: function(content, format) {
        this.trigger('html-editor:save', content, format);

        if ( this.WYSIWYGEditor ) {
            this.WYSIWYGEditor.remove();
        }
    },
    manageSaveTemplateAsDialog: function() {
        var saveas = new SaveAsView({
            filename: this.options.filename,
            description: this.options.description
        });

        var view = this;

        this.listenTo(saveas, 'save-as:save', function(filename, description) {
            view.saveAsRegion.reset();
            view.trigger('html-editor:save-as',
                filename,
                description,
                view.getContent({}),
                view.format
            );

            if ( view.WYSIWYGEditor ) {
                view.WYSIWYGEditor.remove();
            }
        });

        this.listenTo(saveas, 'save-as:close', function() {
            view.saveAsRegion.reset();
        });

        this.saveAsRegion.show(saveas);
    },
    getHtmlEditorHeight: function() {
        var totalHeight = this.$el.find('.modal-body').height(),
            mceBarsHeight = 35 + 34 + 38; // Hard-coded because the below calculation isn't working correctly

        // This is not calculating the correct heights of the toolbars
        // this.$el.find('.mce-toolbar, .mce-statusbar').each(function(){
        //     mceBarsHeight += $(this).height();
        // });

        return Math.floor(totalHeight - mceBarsHeight);
    },
    setHtmlEditorHeight: function() {
        var height = this.getHtmlEditorHeight(),
            areaHeight = this.$el.find('.modal-body').height();
        this.ui.HTMLArea.css('height', areaHeight);
        this.ui.plainTextArea.css('height', areaHeight);
    },
    onShow: function() {
        var view = this;

        if (this.options.startShowingTemplatesList) {
            this.showTemplateList();
        }
        else {
            this.showEditorByFormat(this.format, this.initialContent);
        }

        this.$el.parent().addClass('html-editor-modal');

        // ...
        this.$el.parent().prev('.modal-backdrop').on('mousedown', function(ev) {
            ev.preventDefault();
            view.closeDialog();
        });

        // Bind scrolling events for header/footer shadows
        this.ui.scroll.scroll(this.scrollEvents.bind(this));
        this.scrollbar();

        // Listen for webkit transition end to update scrollbar
        _.defer(function() {
            this.$el.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                _.debounce(function() {
                    this.scrollbar();
                }.bind(this), 100)
            );
        }.bind(this));

        if (!this.options.saveAsEnabled) {
            this.ui.saveas.hide();
        }

        if (!this.options.templatesListEnabled) {
            this.ui.showTemplatesButton.hide();
        }

        if (!this.options.templatesListEnabled || !this.options.startShowingTemplatesList) {
            this.ui.title.text('Editor').show();
        }

        this.resizeCallback = function () {
            view.setHtmlEditorHeight();
        };
        $(window).on('resize', this.resizeCallback);
        this.setHtmlEditorHeight();
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    /**
     * Manage editor dialog closing. Confirmation if changes are made in content.
     */
    closeDialog: function() {
        var self = this,
            content = this.getContent({}),
            closeEditor = function() {
                self.trigger('html-editor:close');

                if (self.WYSIWYGEditor) {
                    self.WYSIWYGEditor.remove();
                }
            };

        if (content !== this.initialContent) {
            MessageBox.showYesNo({
                    message: Handlebars.compile([
                            '<h5>Discard changes?</h5>',
                            '<p>All your changes will be lost.</p>'
                        ].join('')),
                    accept_is_negative: true,
                    accept_button_text: 'Discard',
                    cancel_button_text: 'Cancel'
                },
                this,
                function() { // yes
                    closeEditor();
                }
            );
        }
        else {
            closeEditor();
        }
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = this.$el.height() - this.ui.header.height(),
            innerHeight = container.find('.content-inner:first').height();

        // container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    },
    /**
     * Shows template preview. Initializes view on first call.
     */
    showPreview: function() {
        if (!this.previewView) {
            this.previewView = new PreviewView({
                content: '',
                name: 'Blank document'
            });

            this.listenTo(this.previewView, 'preview:click', function() {
                var id = 0;

                if (this.templatesList.focusedItem()) {
                    id = this.templatesList.focusedItem().model.id;
                    this.useTemplate(this.templatesCollection.get(id));
                }

                this.hideTemplateList();
            });
        }

        this.hideEditors();
        this.templatePreview.show(this.previewView);
    },
    hideEditors: function() {
        this.ui.editorButtons.removeClass('active');
        this.ui.previewButton.hide();

        if (this.WYSIWYGEditor) {
            this.WYSIWYGEditor.activeEditor.hide();
        }
        this.ui.richArea.hide();
        this.ui.HTMLArea.hide();
        this.ui.plainTextArea.hide();
    },
    /**
     * Shows appropriate editor and initializes content. Hides other editors.
     *
     * @param format        string      wysiwyg | html | text
     * @param content       text        content to be put in editor
     */
    showEditorByFormat: function(format, content) {
        var self = this;

        this.format = format;

        this.hideEditors();

        if (format === 'wysiwyg') {
            var showWYSIWYG = function() {
                self.WYSIWYGEditor.activeEditor.show();
                if (content !== undefined) {
                    self.WYSIWYGEditor.activeEditor.setContent(content);
                    self.WYSIWYGEditor.activeEditor.undoManager.add();
                }
                self.ui.richArea.tinymce().focus();
            };

            if (!this.WYSIWYGEditor) {
                this.initializeWHYSIWYGEditor(showWYSIWYG);
            }
            else {
                showWYSIWYG();
            }
            this.ui.wysiwygButton.addClass('active');
            this.ui.previewButton.show();
        }
        else if (format === 'html') {
            this.ui.HTMLArea.show().focus();
            if (content !== undefined) {
                this.ui.HTMLArea.val(content);
            }
            this.ui.htmlButton.addClass('active');
            this.ui.previewButton.show();
        }
        // show plain text
        else {
            this.ui.plainTextArea.show().focus();
            if (content !== undefined) {
                this.ui.plainTextArea.val(content);
            }
            this.ui.plainButton.addClass('active');
        }

        // message info
        if (format === 'text') {
            this.ui.editorInfo.show();
        }
        else {
            this.ui.editorInfo.hide();
        }
    },
    /**
     * Manages template selection and opens appropriate editor.
     * @param template      BBModel     template model
     */
    useTemplate: function(template) {
        var content = '';

        if (template.get('id') === 0) {
            this.format = 'wysiwyg';
            var signature = app.user.get('email_signature');

            if (signature) {
                content = '<br><br>' + signature;
            }
        }
        else {
            this.format = 'html';
            content = template.get('content');
        }

        this.showEditorByFormat(this.format, content);
    },
    /**
     * Shows template list. In first call loads templates from DB and initializes view.
     */
    showTemplateList: function() {
        var self = this;

        var showTemplateList = function() {
            // Init TemplateListView and listeners
            if (!self.templatesList) {
                self.templatesList = new TemplatesListView({ collection: self.templatesCollection });
                self.templatesListRegion.show(self.templatesList);

                self.listenTo(self.templatesList, 'itemview:focus', function(itemView) {
                    var item = self.templatesCollection.get(itemView.model.id);
                    self.templatePreview.currentView.setContent(item.get('content'));
                });

                self.listenTo(self.templatesList, 'itemview:select', function(itemView) {
                    self.useTemplate(self.templatesCollection.get(itemView.model.id));
                    self.hideTemplateList();
                });
            }

            self.ui.editorButtons.hide();

            self.$el.addClass('templates-visible');
            self.ui.title.fadeOut(200, function() {
                $(this).text('Templates').fadeIn(200);
            });

            self.showPreview();

            self.templatePreview.currentView.setContent(self.getContent({}));
            self.templatesList.removeFocus();
            self.ui.scroll.focus();
        };

        // lazy loading for template list
        if (!this.templatesCollection) {
            this.templatesCollection = new Backbone.Collection();
            $.get(
                '/content_files?ext=.html&ext=.txt&search=&rows=-1',
                function(data){
                    // empty template
                    self.templatesCollection.add(new TemplateModel({
                        name: 'Blank template',
                        ext: '.html',
                        icon: 'icon-file',
                        content: '',
                        id: 0
                    }));

                    for (var i = 0; i < data.length; ++i) {
                        var templateModel = new TemplateModel(data[i]);
                        templateModel.id = i + 1;
                        self.templatesCollection.add(templateModel);
                    }
                    showTemplateList();
                }
            );
        }
        else {
            showTemplateList();
        }
    },
    /**
     * Hides template list and preview
     */
    hideTemplateList: function() {
        this.ui.editorButtons.show();
        this.$el.removeClass('templates-visible');
        this.ui.title.fadeOut(200, function() {
            $(this).text('Editor').fadeIn(200);
        });
    },
    /**
     * Simple helper to get format depending on extension.
     *
     * @param ext       string      extension in form: '.html'
     * @returns {*}
     */
    getFormatByExt: function(ext) {
        if (ext === '.html') {
            return 'html';
        }
        return 'text';
    },
    /**
     * Get content depending on current format (this.format).
     *
     * @param options.plain     bool    whether to return only text when in wysiwyg format
     * @returns {*}
     */
    getContent: function(options) {
        var content;

        options = options || {};

        if (this.format === 'wysiwyg') {
            // retrieve just text, without html tags
            if (options.plain) {
                content = this.WYSIWYGEditor.activeEditor.getBody().textContent;
            }
            // retrieve text with html formatting
            else {
                content = this.WYSIWYGEditor.activeEditor.getContent();
            }
        }
        else if (this.format === 'html') {
            content = this.ui.HTMLArea.val();
        }
        else {
            content = this.ui.plainTextArea.val();
        }

        return content;
    }
});

export default HTMLEditor;

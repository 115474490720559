import React from "react";

import LoadingIndicator from "js/react_views/widgets/loading-indicator";

import style from "./kpi_row.css";

export default class KpiRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
      fetchingData: false,
    };

    const self = this;

    this.props.widget.addEventListener("refresh", function () {
      self.fetchData(true);
    });

    _.defer(function () {
      self.fetchData();
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData(force) {
    if (!this.mounted) {
      return;
    }

    const query = this.props.widget.params.query;

    if (!query) {
      return;
    }

    // only displays the loader the first time
    if (this.state.value === null) {
      this.setState({
        fetchingData: true,
      });
    }

    let options = _.clone(query.options || {});
    options.force = force;

    this.props.widget.fetcher.get(
      query.url,
      query.args,
      this.onData.bind(this),
      options
    );
  }

  onData(data) {
    if (!this.mounted) {
      return;
    }

    if (
      this.state.value !== null &&
      this.state.value !== data &&
      this.props.widget.params.on_data_change_event
    ) {
      this.props.widget.triggerEvent(
        this.props.widget.params.on_data_change_event
      );
    }

    this.setState({
      values: this.formatData(data),
      fetchingData: false,
    });
  }

  formatData(data) {
    const objectKeys = data ? Object.keys(data) : [];

    let propertyValue = this.props.widget.params.data_property.value;
    let percentage_format = this.props.widget.params.value_format.percentage;

    return objectKeys.map((e) => {
      let value = propertyValue ? data[e][propertyValue] : data[e];
      let valueSuffix = percentage_format.includes(e) ? "%" : "";

      return {
        caption: e.replace("_", " "),
        value: value + valueSuffix,
      };
    });
  }

  render() {
    const params = this.props.widget.params;
    const renderer = this.props.renderer;

    if (renderer) {
    }

    return (
      <div className={style.kpiRow}>
        <div className={style.title}>
          <div className={style.tContainer}>
            <div className={style.tTitle}>{params.title}</div>
          </div>
        </div>
        <div className={style.content}>
          <div className={style.kpiLine}>
            {this.state.values.map((e) => {
              return <KpiRowItem key={e.caption} caption={e.caption} value={e.value} />;
            })}
          </div>
        </div>
      </div>
    );
  }
}

class KpiRowItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={style.kpiItem}>
        <div className={style.value}>{this.props.value}</div>
        <div className={style.caption}>{this.props.caption}</div>
      </div>
    );
  }
}

import React from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

import app from 'js/app';
import vent from 'js/vent';
import Currency from 'js/utils/currency';
import dateFormat from 'js/utils/date-format';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import Utilities from 'js/utils/utilities'
import CustomFieldsCollection from 'js/collections/custom_fields'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'

import OpportunityModel from 'js/models/opportunity'
import DropTargetContainer from 'js/react_views/detail_view_components/DropTargetContainer';
import Toolbar from './Toolbar';
import Header from './Header';
import Individuals from './Individuals';
import RelatedFiles from 'js/react_views/detail_view_components/related-files.js';
import GroupMembership from 'js/react_views/detail_view_components/group-membership';
import ArchiveAddress from 'js/react_views/detail_view_components/archive-address';
import ContentPane from 'js/react_views/detail_view_components/content-pane';
import ViewPaneNav from 'js/react_views/detail_view_components/ViewPaneNav';
import MoreInfo from 'js/react_views/detail_view_components/MoreInfo';
import {
    FieldContainer,
    TextField,
    CheckboxField,
    IndividualField,
    OrganizationField,
    OpportunityField,
    ImageField,
    UrlField,
    SelectField,
    ProductField,
    EntityListField,
    ChecklistsProgress,
    ProposifyButton,
    GenericButton,
    QuickFieldsView,
    ConnellsSendComplianceEmailButton
} from 'js/react_views/detail_fields/detail_fields';
import TemplatedContent from './TemplatedContent';

import dealValueStyle from './DealValue.css';
import detailsStyle from './Details.css';

import style from './styles.css';

export const getDealSection = (itemName) => {

    let section = null;
    var preferences = app.user.get('client').preferences;

    if(itemName && preferences && preferences.deals_sections){
        const dealsSections = JSON.parse(preferences.deals_sections);
        section = dealsSections.find(s => s.id.includes(itemName.toLowerCase()))
    }

    return section
}

class Details extends React.Component {
    render() {
        return (
            <section className={detailsStyle.Details}>
                {this.props.children}
            </section>
        );
    }
}

const DragHandle = SortableHandle(() => <i className={classnames({ "icon-drag": true, [style.handle]: true })} />);

const SortableItem = SortableElement(({data, deal}) =>
    <div className={classnames({[style.SortableItem]: true, [style.Hidden]: data.hidden})}><CustomFieldGroup key={data.id} data={data} deal={deal}/></div>
);

const SortableList = SortableContainer(({items, deal}) => {
  return (
    <div className={style.SortableContainer}>
      {items.map((value, index) => (
        <SortableItem key={"item-" + index} index={index} data={value} deal={deal}/>
      ))}
    </div>
  );
});

class SortableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const items = arrayMove(prevState.items, oldIndex, newIndex);
            items[0].updateOrder(items);
            return { items: items };
        });
    };

    render() {
        return <SortableList items={this.state.items} deal={this.props.deal} onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis={"y"} />;
    }
}

class CustomFieldGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.data.state !== 'expanded'
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const newState = this.state.collapsed ? "expanded" : "collapsed";
        this.props.data.update(this.props.data.id, { state: newState });
        this.setState((prevState) => {
            return { collapsed: !prevState.collapsed };
        });
    }

    render() {
        if (this.props.data.fields.length === 0) {
            return null;
        }

        let customFields = _.compact(_.map(
            this.props.data.fields,
            (item) => {
                if (!item.value && !item.originalValue && !_.isNumber(item.value) && !_.isBoolean(item.value)) {
                    return (null);
                }

                if (Array.isArray(item.value) && item.value.length === 0) {
                    return (null);
                }

                if (item.type === 'dropDown' && item.params && item.params.multiSelect){
                    let values = [];
                    item.originalValue.split(',').map(valueId => {
                        values.push(item.options.find(opt => opt.id === valueId).value || '');
                    })
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={values.join(', ')} />
                    );
                }

                if (item.type === 'date') {
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={dateFormat.entityInformationFormat(dateFormat.createDateIgnoringTZ(item.value))} />
                    );
                }
                else if (item.type === 'checkbox') {
                    if (item.value || AppConfig.getValue('custom_fields.overview.show_false_checkboxes')) {
                        return <CheckboxField key={item.id} label={item.label} value={item.value} />;
                    }

                    return null;
                }
                else if (item.type === 'individual') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    return (
                        <IndividualField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId}
                            photoUrl={item.photoUrl}
                            organizationName={item.organizationName} />
                    );
                }
                else if (item.type === 'organization') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    return (
                        <OrganizationField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId} />
                    );
                }
                else if (item.type === 'opportunity') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    const fromGroup = item.params && item.params.select_from_group
                    return (
                        <OpportunityField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId}
                            fromGroup={fromGroup} />
                    );
                }
                else if (item.type === 'urlImage') {
                    return (
                        <ImageField
                            key={item.id}
                            label={item.label}
                            url={item.value} />
                    );
                }
                else if (item.type === 'url') {
                    return (
                        <UrlField
                            key={item.id}
                            label={item.label}
                            url={item.value}
                            params={item.params} />
                    );
                }
                else if (item.type === 'paragraph') {
                    return (
                        <TextField key={item.id} label={item.label} isParagraph={true} value={item.value} />
                    );
                }
                else if (item.type === 'product') {
                    return (
                        <ProductField
                            key={item.id}
                            label={item.label}
                            quantity={item.value}
                            unitPrice={Utilities.getCustomFieldProductPrice(item, item.value)}
                            currency={this.props.deal.currency}
                        />
                    );
                }
                else if (item.type === 'list') {
                    return (
                        <EntityListField
                            key={item.id}
                            label={item.label}
                            value={item.value}
                        />
                    );
                }
                return (
                    <TextField key={item.id} label={item.label} value={item.value} />
                );
            }
        ));

        if (customFields.length === 0) {
            return null;
        }

        const toggleClasses = classnames({
            "icon-caret-right": this.state.collapsed,
            "icon-caret-down": !this.state.collapsed,
            [style.toggle]: true
        });

        return (
            <div className={style.GroupContainer}>
                <div className={style.CustomFieldGroup}>
                    <div className={style.header}>
                        <i className={toggleClasses} onClick={this.toggle} />
                        {this.props.data.name}
                        <DragHandle />
                    </div>
                    {
                        !this.state.collapsed &&
                        <div className={style.container}>
                            {customFields}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class DealValue extends React.Component {
    renderDisplayView() {
        const {
            deal,
            onDataChange
        } = this.props;

        const renderValue = !AppConfig.getValue('disableValueOnDeals', false);
        const extraClass = AppConfig.getValue('useLargeFontOnDealBuckets') ? dealValueStyle.useLargeFont : '';

        return (
            <div>
                {renderValue && <TextField
                    label="Value"
                    value={Currency.format(deal.currency, deal.value)}
                    editable={!!onDataChange}
                    onChange={() => { onDataChange('buckets', deal.buckets, true); }}
                    style="large"
                />}
                <div className={dealValueStyle.buckets}>
                    {_.map(
                        _.filter(deal.buckets, (bucket) => (bucket.value !== 0)),
                        (bucket) =>
                            <div className={dealValueStyle.bucketDisplay} key={bucket.id}>
                                <span className={`${dealValueStyle.bucketValue} ${extraClass}`}>
                                    {Currency.format(deal.currency, bucket.value)}
                                </span>
                                <span className={`${dealValueStyle.bucketName} ${extraClass}`}>
                                    {bucket.name}
                                </span>
                            </div>
                    )}
                </div>
            </div>
        );
    }

    getTotalValue() {
        const { processedInputData } = this.props;

        return _.reduce(processedInputData, (memo, bucket) => (memo + bucket.value), 0);
    }

    onValueClick() {
        if (this.props.inputData) {
            const id = this.props.inputData[0].id;

            if (this.refs[id]) {
                this.refs[id].focus();
            }
        }
    }

    renderEditView() {
        const {
            inputData,
            processInput,
            onDataChange,
            onDataSave
        } = this.props;

        return (
            <div className={dealValueStyle.editView}>
                <header className={dealValueStyle.editViewHeader}>
                    <h5 className={dealValueStyle.editViewTitle}>
                        {TextManager.getText("ID_EDIT_DEAL_VALUE")}
                    </h5>
                </header>
                <div className={dealValueStyle.editViewInner}>
                    <FieldContainer label="Value" labelColor="blue">
                        <div className={dealValueStyle.fauxInput} onClick={() => { this.onValueClick(); }}>
                            {this.getTotalValue()}
                        </div>
                    </FieldContainer>
                    {_.map(inputData, (bucket, index) =>
                        <div className={dealValueStyle.bucketField} key={bucket.id}>
                            <div className={dealValueStyle.bucketInputBox}>
                                <input
                                    ref={bucket.id}
                                    type="text"
                                    className={dealValueStyle.bucketInput}
                                    placeholder="Value"
                                    value={bucket.value}
                                    onChange={(event) => {
                                        event.persist();
                                        onDataChange(
                                            'buckets',
                                            _.map(inputData, (inputBucket) => {
                                                const newBucket = _.clone(inputBucket);
                                                if (newBucket.id === bucket.id) {
                                                    newBucket.value = event.target.value;
                                                }
                                                return newBucket;
                                            }),
                                            true)
                                    }}
                                    onBlur={processInput}
                                    autoFocus={index === 0}
                                />
                            </div>
                            <div className={dealValueStyle.bucketName}>
                                {bucket.name}
                            </div>
                        </div>
                    )}
                </div>
                <footer className={dealValueStyle.footer}>
                    <button className={dealValueStyle.done} onClick={() => { onDataSave(); }}>
                        Done
                    </button>
                </footer>
            </div>
        );
    }

    render() {
        const { inputData } = this.props;

        return (
            <div className={dealValueStyle.DealValue}>
                {inputData ?
                    this.renderEditView()
                :
                    this.renderDisplayView()
                }
            </div>
        );
    }
}

class YourKeysDealButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        if (AppConfig.getValue('showYKDealButton')) {
            const self = this;

            if ('yourkeysInfo' in app.globalData) {
                this.state.plotIdCf = app.globalData.yourkeysInfo.plotIdCf;
                this.state.dealTypeCf = app.globalData.yourkeysInfo.dealTypeCf;
                this.state.reservationCf = app.globalData.yourkeysInfo.reservationCf;
                this.state.purchaseReferenceCf = app.globalData.yourkeysInfo.purchaseReferenceCf;

                _.defer(function() {
                    self.checkButtonVisibility();
                });
            } else {
                let yourkeysInfo = {};
                const res = new CustomFieldsCollection(app.globalData.customFieldsInfo.opportunitiesArray);

                for (const cf of res.models) {
                    const lname = cf.get('name').toLowerCase();

                    switch(lname) {
                        case 'plotid':
                            yourkeysInfo.plotIdCf = cf.toJSON();
                            break;

                        case 'deal type':
                            yourkeysInfo.dealTypeCf = cf.toJSON();
                            break;

                        case 'reservation':
                            yourkeysInfo.reservationCf = cf.toJSON();
                            break;

                        case 'purchase reference':
                            yourkeysInfo.purchaseReferenceCf = cf.toJSON();
                            break;
                    }
                }

                app.globalData.yourkeysInfo = yourkeysInfo;

                self.state.plotIdCf = yourkeysInfo.plotIdCf;
                self.state.dealTypeCf = yourkeysInfo.dealTypeCf;
                self.state.reservationCf = yourkeysInfo.reservationCf;
                self.state.purchaseReferenceCf = yourkeysInfo.purchaseReferenceCf;

                _.defer(function() {
                    self.checkButtonVisibility();
                });
            }
        }
    }
}

class YourKeysDealOpenButton extends YourKeysDealButton {
    openYourkeysUrl() {
        const userPreferences = app.user.get('preferences') || {};
        const sellerReference = userPreferences.yourkeys_seller_reference;

        if (!sellerReference) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'Missing seller reference. Please contact support.'
            }, this.props.parent);
        } else {
            const reservationId = this.props.deal.custom_fields[this.state.reservationCf.id];

            if (reservationId) {
                const handler = MessageBox.showNoBtn({
                    message: 'Please do not refresh your page while we are generating the Yourkeys reservation url.'
                }, this.props.parent, { staticRegion: true });

                const opportunity = new OpportunityModel({id: reservationId});
                const self = this;

                opportunity.fetch({
                    success: function(data) {
                        const purchaseRef = data.get('custom_fields')[self.state.purchaseReferenceCf.id];

                        if (!purchaseRef) {
                            handler.reset();

                            MessageBox.showOk({
                                icon: 'icon-warning',
                                message: "The plot's reservation purchase reference is missing. Please contact support."
                            }, self.props.parent);
                        } else {
                            const url = `/yourkeys_passthrough_url?seller_reference=${sellerReference}&purchase_reference=${purchaseRef}`
                            $.get(url, function(responseUrl) {
                                handler.reset();
                                window.open(responseUrl , '_blank');
                            }).
                            fail(function(error) {
                                handler.reset();
                                const errorObj = JSON.parse(error.responseText);
                                MessageBox.showOk({
                                    icon: 'icon-warning',
                                    message: `${errorObj.detail.message} Please contact support`,
                                }, self.props.parent);
                            });
                        }
                    },
                    error: function() {
                        handler.reset();
                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: 'Problem with generating the plot url. Please contact support'
                        }, self.props.parent);
                    }
                });
            }
        }
    }

    checkButtonVisibility() {
        const cfs = this.props.deal.custom_fields;
        const dealTypeCf = this.state.dealTypeCf;
        const userPreferences = app.user.get('preferences') || {};
        const sellerReference = userPreferences.yourkeys_seller_reference;

        if (this.state.reservationCf && sellerReference && cfs[this.state.reservationCf.id] &&
            dealTypeCf && cfs[dealTypeCf.id] && (dealTypeCf.optionsArray.find(op => op.id === cfs[dealTypeCf.id]) || {}).value == 'Plot' &&
            (this.props.deal.phase.phase_type === 'won' || ['Reserved', 'Exchanged', 'Completed', 'Tentative', 'Pending'].indexOf(this.props.deal.phase.name) !== -1)
        ) {
            this.setState({visible: true});
        }
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        const openYourkeysIframe = AppConfig.getValue('yourkeys.use_yourkeys_iframe', false);

        return(
            <div className={style.YourKeysDealButtonContainer}>
                <div className={style.YourKeysDealOpenButton} onClick={openYourkeysIframe ? this.props.onShowIframeDataClick : this.openYourkeysUrl.bind(this)}/>
            </div>
        );
    }
}

class YourKeysDealReserveButton extends YourKeysDealButton {
    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    checkButtonVisibility() {
        const cfs = this.props.deal.custom_fields;
        const dealTypeCf = this.state.dealTypeCf;
        const userPreferences = app.user.get('preferences') || {};
        const sellerReference = userPreferences.yourkeys_seller_reference;

        if (this.state.plotIdCf && sellerReference && cfs[this.state.plotIdCf.id] &&
            dealTypeCf && cfs[dealTypeCf.id] && (dealTypeCf.optionsArray.find(op => op.id === cfs[dealTypeCf.id]) || {}).value == 'Plot' &&
            this.props.deal.phase.name === 'Available Plots'
        ) {
            this.setState({visible: true});
        }
    }

    startProcess() {
        const userPreferences = app.user.get('preferences') || {};
        const sellerReference = userPreferences.yourkeys_seller_reference;
        const primaryBuyer = this.props.relatedData.individuals.items?.[0];

        if (!sellerReference) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'Missing seller reference. Please contact support.'
            }, this.props.parent);
        } else if (!primaryBuyer?.primaryEmail) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'Please provide a valid email address on the primary buyer record.'
            }, this.props.parent, function() {
                window.location.href = `/#individuals/${primaryBuyer.id}`;
            });
        } else {
            const handler = MessageBox.showNoBtn({
                message: 'Please wait while we are creating reservation in Yourkeys.'
            }, this.props.parent, { staticRegion: true });

            const self = this;

            $.post('/yourkeys_reservation', JSON.stringify({
                opportunity_id: this.props.deal.id,
                seller_reference: sellerReference
            }), function(response) {
                self.waitForCompletation(handler, response.id);
            });
        }
    }

    waitForCompletation(handler, id) {
        const self = this;

        setTimeout(function() {
            if (!self.mounted) {
                return;
            }

            $.get(`/yourkeys_reservation/${id}`, function(response) {
                if (response.status === 'finished') {
                    // reload the deal to get the new data
                    const opportunity = new OpportunityModel({id: self.props.deal.id});

                    opportunity.fetch({
                        success: function(dealData) {
                            const reservationId = dealData.get('custom_fields')[self.state.reservationCf.id];
                            const reservation = new OpportunityModel({id: reservationId});

                            reservation.fetch({
                                success: function(reservationData) {
                                    const purchaseRef = reservationData.get('custom_fields')[self.state.purchaseReferenceCf.id];

                                    if (!purchaseRef) {
                                        handler.reset();

                                        MessageBox.showOk({
                                            icon: 'icon-warning',
                                            message: "The plot's reservation purchase reference is missing. Please contact support."
                                        }, self.props.parent);
                                    } else {
                                        const openYourkeysIframe = AppConfig.getValue('yourkeys.use_yourkeys_iframe', false);
                                        
                                        if (openYourkeysIframe) {
                                            self.props.onShowIframeDataClick(null, self.props.onRefreshDeal);
                                        } else {
                                            const userPreferences = app.user.get('preferences') || {};
                                            const url = `/yourkeys_passthrough_url?seller_reference=${userPreferences.yourkeys_seller_reference}&purchase_reference=${purchaseRef}`
                                            
                                            $.get(url, function(responseUrl) {
                                                handler.reset();
                                                window.open(responseUrl , '_blank');
                                                self.props.onRefreshDeal();
                                            }).
                                            fail(function(error) {
                                                handler.reset();
                                                const errorObj = JSON.parse(error.responseText);
                                                MessageBox.showOk({
                                                    icon: 'icon-warning',
                                                    message: `${errorObj.detail.message} Please contact support`,
                                                }, self.props.parent);
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    });
                } else if (response.status === 'error') {
                    handler.reset();
                    MessageBox.showOk({
                        icon: 'icon-warning',
                        message: 'Failed to create reservation in Yourkeys. Please contact Yourkeys support.'
                    }, self.props.parent, function() {
                        self.props.onRefreshDeal();
                    });
                } else {
                    self.waitForCompletation(handler, id);
                }
            });
        }, 2000);
    }

    render() {

        const relatedIndividualsCount = (this.props.relatedData && this.props.relatedData.individuals && this.props.relatedData.individuals.total) || 0

        if (!this.state.visible) {
            return null;
        }

        if(relatedIndividualsCount === 0){
            return null
        }

        return(
            <div className={style.YourKeysDealButtonContainer}>
                <div className={style.YourKeysDealReserveButton} onClick={this.startProcess.bind(this)}/>
            </div>
        );
    }
}

class DealView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'details',
            dealIndividualsSelection: {},
            organizationIndividualsSelection: {},
        };

        if (AppConfig.getValue('showLoansSnapshotSection')) {
            const self = this;

            const processPriorityStatus = function(priorityStatusCf, setState) {
                if (!priorityStatusCf) {
                    return;
                }

                const clientPreferences = app.user.get('client').preferences;
                const snapshotPreferences = (clientPreferences && clientPreferences.loans_snapshot) ? JSON.parse(clientPreferences.loans_snapshot) : {};

                self.statusColor = {
                    go: '#60d937',
                    assign: '#ff9a01',
                    attention: '#ff9a01', // some systems use attention instead assign
                    issue: '#ed230d'
                };

                if (snapshotPreferences.colours) {
                    self.statusColor.go = snapshotPreferences.colours[0];
                    self.statusColor.assign = snapshotPreferences.colours[1];
                    self.statusColor.attention = snapshotPreferences.colours[1];
                    self.statusColor.issue = snapshotPreferences.colours[2];
                }

                if (setState) {
                    self.setState({priorityStatusCf: priorityStatusCf});
                } else {
                    self.state.priorityStatusCf = priorityStatusCf;
                }
            }

            if ('dealPriorityStatusCf' in app.globalData) {
                processPriorityStatus(app.globalData.dealPriorityStatusCf);
            } else {
                let customFields = new CustomFieldsCollection();
                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'deals'
                    }],
                    success: function() {
                        app.globalData.dealPriorityStatusCf = customFields.models.find(cf => cf.get('name').toLowerCase() === 'priority status');
                        processPriorityStatus(app.globalData.dealPriorityStatusCf, true);
                    }
                });
            }
        }

        if (AppConfig.getValue('deals.organization_individuals.visible')) {
            const self = this;

            const processDealType = function(dealTypeCf, setState) {
                if (!dealTypeCf) {
                    return;
                }

                if (setState) {
                    self.setState({dealTypeCf: dealTypeCf});
                } else {
                    self.state.dealTypeCf = dealTypeCf;
                }
            }

            if ('dealTypeCf' in app.globalData) {
                processDealType(app.globalData.dealTypeCf);
            } else {
                let customFields = new CustomFieldsCollection();
                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'deals'
                    }],
                    success: function() {
                        app.globalData.dealTypeCf = customFields.models.find(cf => cf.get('name').toLowerCase() === 'deal type');
                        processDealType(app.globalData.dealTypeCf, true);
                    }
                });
            }
        }

        this.bindMethods();
    }

    bindMethods() {
        this.handleNavTabClick = this.handleNavTabClick.bind(this);
        this.showDealIndividuals = this.showDealIndividuals.bind(this);
        this.showOrganizationIndividuals = this.showOrganizationIndividuals.bind(this);
        this.handleDealIndividualSelect = this.handleDealIndividualSelect.bind(this);
        this.handleOrganizationIndividualSelect = this.handleOrganizationIndividualSelect.bind(this);
        this.handleDealSelectionClear = this.handleDealSelectionClear.bind(this);
        this.handleOrganizationSelectionClear = this.handleOrganizationSelectionClear.bind(this);
        this.handleDealIndividualsToggle = this.handleDealIndividualsToggle.bind(this);
        this.handleOrganizationIndividualsToggle = this.handleOrganizationIndividualsToggle.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleNavTabClick(tab) {
        this.setState({ activeTab: tab });
    }

    getIndividualsTab() {
        const { relatedData } = this.props;
        if (this.state.individualsTab) {
            return this.state.individualsTab;
        }
        // if deal's individuals list has already downloaded but is empty
        // and org's individuals list is not empty, then switch
        // to the org's individuals tab
        if (relatedData.organizationIndividuals &&
            relatedData.organizationIndividuals.items.length &&
            (relatedData.individuals && !relatedData.individuals.items.length)) {
            return 'organization';
        }
        return 'deal';
    }

    showDealIndividuals() {
        this.setState({individualsTab: 'deal'});
    }

    showOrganizationIndividuals() {
        this.setState({individualsTab: 'organization'});
    }

    handleNavigateIndividualPage(direction) {
        const { onIndividualsPaging } = this.props;
        const tab = this.getIndividualsTab();
        const individualsKey = (
            tab === 'deal' ? 'individuals' : 'organizationIndividuals'
        );

        tab === 'deal' && this.handleDealSelectionClear();
        tab === 'organization' && this.handleOrganizationSelectionClear();

        onIndividualsPaging(individualsKey, direction);
    }

    handleIndividualSelect(selectionKey, item) {
        this.setState((prevState, props) => {
            let newSelection;

            if (prevState[selectionKey][item.id]) {
                newSelection = _.clone(prevState[selectionKey]);
                delete newSelection[item.id];
            }
            else {
                newSelection = _.extend(
                    {},
                    prevState[selectionKey],
                    { [item.id]: true }
                );
            }
            return { [selectionKey]: newSelection };
        });
    }

    handleDealIndividualSelect(item) {
        this.handleIndividualSelect('dealIndividualsSelection', item);
    }

    handleOrganizationIndividualSelect(item) {
        this.handleIndividualSelect('organizationIndividualsSelection', item);
    }

    handleDealSelectionClear() {
        this.setState({
            dealIndividualsSelection: {}
        });
    }

    handleOrganizationSelectionClear() {
        this.setState({
            organizationIndividualsSelection: {}
        });
    }

    handleAllIndividualsToggle(selectionKey) {
        this.setState((prevState, props) => {
            const individuals = selectionKey === "dealIndividualsSelection" ?
                    this.props.relatedData.individuals :
                    this.props.relatedData.organizationIndividuals;
            const out = {};

            if (_.size(prevState[selectionKey]) === individuals.items.length) {
                out[selectionKey] = {};
            }
            else {
                out[selectionKey] = _.object(
                    _.map(individuals.items, individual => [individual.id, true])
                );
            }
            return out;
        });
    }

    handleDealIndividualsToggle() {
        this.handleAllIndividualsToggle('dealIndividualsSelection');
    }

    handleOrganizationIndividualsToggle() {
        this.handleAllIndividualsToggle('organizationIndividualsSelection');
    }

    handleDrop(files) {
        this.props.onDnDFileUpload(files);
    }

    renderKeyInfo() {
        const {
            deal,
            isEditable,
            dealStateMap,
            inputData,
            processedInputData,
            processInput,
            onDataChange,
            onCancelDataChange,
            onDataSave
        } = this.props;

        const expectedCloseDate = deal.expected_close_date ? dateFormat.createDateIgnoringTZ(deal.expected_close_date) : null;
        const renderFields = !AppConfig.getValue('disableFieldsOnDeal', false);
        const renderWeight = !AppConfig.getValue('disableWeightOnDeals', false);
        const renderCurrency = !AppConfig.getValue('disableCurrencyOnDeals', false);
        const renderStatus = !AppConfig.getValue('disableStatusOnDeals', false);
        const renderSecondInfoSection = !AppConfig.getValue('disableDealSecondInfoSection', false, this.props.section);
        const renderCloseDate = !AppConfig.getValue('disableCloseDateOnDeals', false);

        return (
            isEditable ?
                <div>
                    {renderFields && renderCurrency && renderSecondInfoSection && <SelectField
                        label="Currency"
                        value={{
                            id: deal.currency,
                            title: Currency.composeCurrencyName(deal.currency)
                        }}
                        data={Currency.getUsedCurrenciesToSelect2Array(true)}
                        width={243}
                        text="text"
                        onSelect={(items) => {
                            onDataChange('currency', items[0].id);
                            onDataSave();
                        }} />
                    }

                    {renderSecondInfoSection && <DealValue
                        deal={deal}
                        inputData={inputData.buckets}
                        processedInputData={processedInputData.buckets}
                        processInput={processInput}
                        onDataChange={onDataChange}
                        onDataSave={onDataSave} />
                    }

                    {renderSecondInfoSection && <SelectField
                        label="Funnel"
                        value={_.findWhere(
                            this.funnels, { id: deal.funnel_id }
                        )}
                        editable={AppConfig.getValue('deals.overview.funnel.editable', true)}
                        data={this.funnels}
                        width={243}
                        onSelect={(items) => {
                            onDataChange('funnel_id', items[0].id);
                            onDataSave();
                            this.phaseSelector.setData(this.phases[items[0].id]);
                        }} />
                    }

                    {renderSecondInfoSection && <SelectField
                        label={TextManager.parseText('${ID_PHASE, capitalize}')}
                        ref={(ref) => this.phaseSelector = ref}
                        value={_.findWhere(
                            this.phases[deal.funnel_id],
                            { id: deal.phase_id }
                        )}
                        editable={AppConfig.getValue('deals.overview.phase.editable', true)}
                        width={243}
                        data={this.phases[deal.funnel_id]}
                        onSelect={(items) => {
                            onDataChange('phase_id', items[0].id);
                            onDataSave();
                        }} />
                    }

                    {renderSecondInfoSection && renderCloseDate && <TextField
                        label={TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize}')}
                        value={AppConfig.getValue('deals.close_date_formatter', 'Unknown', expectedCloseDate)} />
                    }

                    {renderFields && renderStatus && renderSecondInfoSection && <SelectField
                        label={TextManager.parseText('${ID_STATUS, capitalize}')}
                        value={_.findWhere(dealStateMap.statuses, { id: deal.status })}
                        data={dealStateMap.statuses}
                        width={243}
                        onSelect={(items) => {
                            onDataChange('status', items[0].id);
                            onDataSave();
                        }} />
                    }
                    {renderFields && renderWeight && renderSecondInfoSection && <TextField
                        label="Weight"
                        value={deal.weight}
                        editable
                        inputValue={inputData.weight}
                        onChange={(value) => { onDataChange('weight', value, true); }}
                        onCancel={onCancelDataChange}
                        onSave={onDataSave} />
                    }
                </div>
            :
                <div>
                    {renderFields && renderCurrency && renderSecondInfoSection && <TextField
                        label="Currency"
                        value={
                            _.findWhere(
                                dealStateMap.currencies, { id: deal.currency }
                            ).title
                        } />
                    }
                    {renderSecondInfoSection && <DealValue
                        deal={deal} />
                    }
                    {renderSecondInfoSection && <TextField
                        label="Funnel"
                        value={
                            _.findWhere(
                                this.funnels, { id: deal.funnel_id }
                            ).title
                        } />
                    }
                    {renderSecondInfoSection && <TextField
                        label={TextManager.parseText('${ID_PHASE, capitalize}')}
                        value={
                            _.findWhere(
                                this.phases[deal.funnel_id],
                                { id: deal.phase_id }
                            ).title
                        } />
                    }
                    {renderSecondInfoSection && <TextField
                        label={TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize}')}
                        value={deal.expected_close_date ? dateFormat.entityInformationFormat(deal.expected_close_date) : 'Unknown'} />
                    }
                    {renderFields && renderStatus && renderSecondInfoSection && <TextField
                        label={TextManager.parseText('${ID_STATUS, capitalize}')}
                        value={
                            _.findWhere(
                                dealStateMap.statuses, { id: deal.status }
                            ).title
                        } />
                    }
                    {renderFields && renderWeight && renderSecondInfoSection && <TextField
                        label="Weight"
                        value={deal.weight} />
                    }
                </div>
        );
    }

    renderCustomFields() {
        const { relatedData } = this.props;

        let sortableFieldGroups;
        if (relatedData.processedCustomFields) {
            // we only want groups with fields having a valid value
            var groups = _.compact(this.props.relatedData.processedCustomFields.map(group => {
                for (var i = 0; i < group.fields.length; ++i) {
                    if ('value' in group.fields[i]) {
                        if ((group.fields[i].type !== 'checkbox') || group.fields[i].value) {
                            return group;
                        }
                    }
                }
                return null;
            }));

            sortableFieldGroups = <SortableComponent items={groups} deal={this.props.deal} />
        }

        return sortableFieldGroups;
    }

    /**
     * Return true if Proposify integration is enabled, and the currently
     * selected deal/phase match the allowed deal/phase names.
     * 
     * @param  {Object} deal The Deal object.
     * @return {Boolean} true if Proposify button should be shown, false otherwise.
     */
    proposifyIsEnabled(deal) {
        let fixedDate = new Date('2022-01-24');
        let dealCreatedDate = new Date(deal.created);
        if(fixedDate > dealCreatedDate) {
            return false;    
        }
       
        const proposifyConfig = app.globalData.proposifyInfo || null;
        if (!proposifyConfig) {
            return false;
        }

        const enabledFunnels = proposifyConfig['enabled_funnels'] || null;
        if (!enabledFunnels) {
            return false;
        }

        const funnel = deal.funnel.name;
        const phases = enabledFunnels[funnel] || null;
        if (!phases) {
            return false;
        }
        
        const phase = deal.phase.name;
        if (!(phases.includes(phase))) {
            return false;
        }
        
        return true;
    }

    isYotiInProgress (deal) {
        let cfs = app.globalData.customFieldsInfo.opportunities;
        let yotiEnvelopeId = Object.keys(cfs).find(cfId => cfs[cfId].name === 'YOTI Envelope ID')
        let yotiEnvelopeStatus = Object.keys(cfs).find(cfId => cfs[cfId].name === 'YOTI Envelope Status')
        let yotiEnvelopeStatusOptions = cfs[yotiEnvelopeStatus]?.options

        return (
            AppConfig.getValue('showYotiDealButton', false) &&
            deal.custom_fields[yotiEnvelopeId] && 
            deal.custom_fields[yotiEnvelopeStatus] === 
                Object.keys(yotiEnvelopeStatusOptions).find(id => yotiEnvelopeStatusOptions[id] === 'In Progress')
        )
    }

    handleArchiveYotiEnvelope (deal) {
        let self = this.props.parent;

        let message = Handlebars.compile(
            'Are you sure you want to archive the envelope?'
        )();
        let mbContent = {
            message: message,
            icon: 'icon-paperplane',
            accept_button_text: 'Yes',
            cancel_button_text: 'Cancel'
        };


        MessageBox.showYesNo(mbContent, this.props.parent,
            function(value) { // yes
                var handle = MessageBox.showNoBtn({
                    icon: 'icon-paperplane',
                    message: 'Archiving YOTI envolope... ' +
                        "<div class='mb-wait-cont'></div>"
                    },
                    self,
                    {
                        staticRegion: true
                    }
                );

                var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');
                completeMessage.text('Processing...');

                $.ajax({
                    type: 'POST',
                    url: '/archive_yoti_envelope',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify({
                        opportunity_id: deal.id,
                    }),
                    success: function(data) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'YOTI envelope has been archived.',
                                    classes: 'success',
                                    timer: 3000
                                };
                            }
                        });
                    },
                    error: function(data) {
                        var detail = {};
                        try {
                            detail = JSON.parse(data.responseText).detail;
                        } catch (e) {
                            detail.message = 'An unknown exception occured. Please try again.'
                        }

                        vent.trigger('alert:show', {
                            type: function() {
                                var message = detail.message
        
                                return {
                                    message: message,
                                    classes: 'load-error error',
                                    timer: 3000
                                };
                            }
                        });
                    },
                    complete: function() {
                        handle.reset();
                    }
                });
            }, 
            null, 
            null,
            {
                // wait for the callback to finish before closing modal
                closeOnCallbackEnd: true,
                callbackLoadingText: 'Sending...',
                callbackLoadingColor: '#c1c4cd'
            }
        );
    }

    handleSendYotiReminder (deal) {
        var message = Handlebars.compile(
            'Are you sure you want to send a YOTI reminder to contacts who have not yet signed the envelope?'
        )();
        var mbContent = {
            message: message,
            icon: 'icon-paperplane',
            accept_button_text: 'Send',
            cancel_button_text: 'Cancel'
        };

        var self = this.props.parent

        MessageBox.showYesNo(mbContent, this.props.parent,
            function(value) { // yes
                var handle = MessageBox.showNoBtn({
                    icon: 'icon-paperplane',
                    message: 'Sending YOTI reminders... ' +
                        "<div class='mb-wait-cont'></div>"
                    },
                    self,
                    {
                        staticRegion: true
                    }
                );

                var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');
                completeMessage.text('Processing...');

                $.ajax({
                    type: 'POST',
                    url: '/send_yoti_reminder',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify({
                        opportunity_id: deal.id,
                    }),
                    success: function(data) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'YOTI reminder has been sent.',
                                    classes: 'success',
                                    timer: 3000
                                };
                            }
                        });
                    },
                    error: function(data) {
                        var detail = {};
                        try {
                            detail = JSON.parse(data.responseText).detail;
                        } catch (e) {
                            detail.message = 'An unknown exception occured. Please try again.'
                        }

                        vent.trigger('alert:show', {
                            type: function() {
                                var message = detail.message
        
                                return {
                                    message: message,
                                    classes: 'load-error error',
                                    timer: 3000
                                };
                            }
                        });
                    },
                    complete: function() {
                        handle.reset();
                    }
                });
            }, 
            null, 
            null,
            {
                // wait for the callback to finish before closing modal
                closeOnCallbackEnd: true,
                callbackLoadingText: 'Sending...',
                callbackLoadingColor: '#c1c4cd'
            }
        );
    }

    renderDetails() {
        const {
            deal,
            relatedData,
            isEditable,
            userId,
            onIndividualNavigate,
            onLinkClick,
            onIndividualSearch,
            onIndividualsAdd,
            onIndividualsDelete,
            onNewIndividual,
            onClickNextFilePage,
            onClickPrevFilePage,
            onFileSearch,
            onFileAdd,
            onDnDFileUpload,
            onFileDelete,
            onFileYoti,
            onCreateNewDocument,
            onDeleteDocument,
            onGroupSearch,
            onGroupAdd,
            onGroupRemove,
            onGroupClick,
            archiveEmailAddress,
            onShowIframeDataClick,
        } = this.props;

        const {
            dealIndividualsSelection,
            organizationIndividualsSelection
        } = this.state;

        const renderIndividuals = !AppConfig.getValue('disableIndividualsSectionOnDeal', false);
        const disableDealIndividualsTab = AppConfig.getValue('disableDealIndividualsTab', false, this.props.section);

        let dealType = null

        if(this.state.dealTypeCf){
            const dealTypeCf = this.state.dealTypeCf;
            const dealTypeValue = deal.custom_fields[dealTypeCf.get('id')];

            const option = dealTypeCf.get('options').find(o => o.id === dealTypeValue);

            if (option) {
                dealType = option.value;
            }
        }

        const showOrganizationIndividualsTab = AppConfig.getValue('deals.organization_individuals.visible', true, dealType);
        const showProposifyButton = this.proposifyIsEnabled(deal);
        const showConnellsSendComplianceEmailButton = AppConfig.getValue('deals.overview.show_send_compliance_email_button', false);

        return (
            <Details>
                {this.renderKeyInfo()}
                {showConnellsSendComplianceEmailButton && 
                    <ConnellsSendComplianceEmailButton
                        deal={deal}
                        parent={this.props.parent}
                    />
                }
                <YourKeysDealReserveButton
                    deal={deal}
                    relatedData={relatedData}
                    parent={this.props.parent}
                    onRefreshDeal={() => this.props.parent.trigger('replace-view:deal:show', {id: deal.id, section: this.props.section})}
                    onShowIframeDataClick={onShowIframeDataClick}
                />
                <YourKeysDealOpenButton
                    deal={deal}
                    parent={this.props.parent}
                    onShowIframeDataClick={onShowIframeDataClick}
                />
                {this.renderCustomFields()} 
                {showProposifyButton && <ProposifyButton deal={deal} parent={this.props.parent} />}
                {renderIndividuals &&
                    <Individuals
                        disableDealIndividualsTab={disableDealIndividualsTab}
                        showOrganizationIndividualsTab={showOrganizationIndividualsTab}
                        dealIndividuals={relatedData.individuals}
                        organizationIndividuals={relatedData.organizationIndividuals}
                        tab={disableDealIndividualsTab ? 'organization' : this.getIndividualsTab()}
                        onShowDealIndividuals={this.showDealIndividuals}
                        onShowOrganizationIndividuals={this.showOrganizationIndividuals}
                        onClickNext={() => { this.handleNavigateIndividualPage(1); }}
                        onClickPrev={() => { this.handleNavigateIndividualPage(-1); }}
                        onNavigate={onIndividualNavigate}
                        onLinkClick={onLinkClick}
                        dealSelection={dealIndividualsSelection}
                        organizationSelection={organizationIndividualsSelection}
                        onDealIndividualSelect={this.handleDealIndividualSelect}
                        onOrganizationIndividualSelect={this.handleOrganizationIndividualSelect}
                        onDealSelectionClear={this.handleDealSelectionClear}
                        onOrganizationSelectionClear={this.handleOrganizationSelectionClear}
                        onAllDealToggle={this.handleDealIndividualsToggle}
                        onAllOrganizationToggle={this.handleOrganizationIndividualsToggle}
                        onSearch={onIndividualSearch}
                        onAdd={onIndividualsAdd}
                        onDelete={onIndividualsDelete}
                        onNew={onNewIndividual}
                    />
                }
                <RelatedFiles
                    files={relatedData.files}
                    onClickNext={onClickNextFilePage}
                    onClickPrev={onClickPrevFilePage}
                    userId={userId}
                    isEditable={isEditable}
                    onSearch={onFileSearch}
                    onAdd={onFileAdd}
                    onUpload={onDnDFileUpload}
                    onDelete={onFileDelete}
                    onYoti={(!this.isYotiInProgress(deal)) && onFileYoti}
                    onYotiReminder={this.isYotiInProgress(deal) ? () => this.handleSendYotiReminder(deal) : false}
                    archiveYotiEnvelope={() => this.handleArchiveYotiEnvelope(deal)}
                    fileUpload={relatedData.fileUpload} />
                <TemplatedContent
                    documents={relatedData.templatedContent}
                    userId={userId}
                    onCreateNew={onCreateNewDocument}
                    onDelete={onDeleteDocument} 
                    onYoti={(!this.isYotiInProgress(deal)) && onFileYoti} 
                    onYotiReminder={
                        (this.isYotiInProgress(deal) && (relatedData.files && relatedData.files.items.length === 0)) ? 
                        () => this.handleSendYotiReminder(deal) : false
                    }
                    archiveYotiEnvelope={() => this.handleArchiveYotiEnvelope(deal)} />
                <GroupMembership
                    items={relatedData.groups}
                    onSearch={onGroupSearch}
                    onAdd={onGroupAdd}
                    onRemove={onGroupRemove}
                    onGroupClick={onGroupClick} />
                <ArchiveAddress email={archiveEmailAddress} />
            </Details>
        );
    }

    render() {
        const {
            deal,
            isManagerUser,
            onEdit,
            isEditable,
            onShowPermissionView,
            onElephantClick,
            onDuplicateClick,
            onHistoryClick,
            onClose,
            onOrganizationClick,
            checklists
        } = this.props;

        let priorityStatus = null;
        let priorityStatusColor = null;

        if (this.state.priorityStatusCf) {
            const pscf = this.state.priorityStatusCf;
            const cfValue = deal.custom_fields[pscf.get('id')];

            if (cfValue) {
                const option = pscf.get('options').find(o => o.id === cfValue);

                if (option) {
                    priorityStatus = option.value;
                    priorityStatusColor = this.statusColor[priorityStatus.toLowerCase()];
                }
            }
        }

        const checklistsProgressMarginTop = priorityStatus ? 10 : -20;
        this.funnels = [];
        this.phases = _.clone(this.props.dealStateMap.phases);

        if (AppConfig.getValue('checkFunnelsPermissions')) {
            this.funnels = this.props.dealStateMap.funnels.filter(f => security.checkPermission('view', f));

            // we add the current deal's funnel/phase although the user doesn't have permissions to see it
            if (deal && deal.funnel_id) {
                const currentFunnel = this.funnels.find(f => f.id === deal.funnel_id);

                if (!currentFunnel && deal.funnel) {
                    this.funnels.push({
                        id: deal.funnel.id,
                        integration_data: deal.funnel.integration_data,
                        permissions: deal.funnel.permissions,
                        title: deal.funnel.name
                    });

                    this.phases[deal.funnel.id] = [{
                        id: deal.phase.id,
                        title: deal.phase.name,
                        default_weight: deal.phase.default_weight
                    }];
                }
            }
        } else {
            this.funnels = this.props.dealStateMap.funnels;
        }

        return (
            <DropTargetContainer
                onDrop={isEditable && this.handleDrop}>

                <Toolbar
                    ownerName={deal.owner.name}
                    onEdit={onEdit}
                    onShowPermissionView={onShowPermissionView}
                    onElephantClick={onElephantClick}
                    onDuplicateClick={onDuplicateClick}
                    onHistoryClick={onHistoryClick}
                    onClose={onClose}
                    showElephant={isManagerUser && !AppConfig.getValue('disableDealElephants')}
                    isElephant={deal.is_favorite} />
                <ContentPane>
                    <Header
                        name={deal.name}
                        abbreviation={deal.abbreviation}
                        organizationId={deal.organization && deal.organization.id}
                        organizationName={deal.organization && deal.organization.name}
                        onOrganizationClick={onOrganizationClick}
                        tags={deal.tags}
                        funnel={_.findWhere(
                            this.funnels, { id: deal.funnel_id }
                        )}
                    />
                    {priorityStatus &&
                        <div className={style.PriorityStatusContainer}>
                            <div className={style.PriorityStatus} style={{background: priorityStatusColor}}>
                                {priorityStatus}
                            </div>
                        </div>
                    }
                    {checklists && checklists.length > 0 && 
                        <div style={{marginTop: `${checklistsProgressMarginTop}px`}}>
                            <ChecklistsProgress checklists={checklists}/>
                        </div>
                    }
                    <QuickFieldsView
                        entity={deal}
                        entityType='opportunities'
                    />
                </ContentPane>
                <ViewPaneNav
                    activeTab={this.state.activeTab}
                    onTabClick={this.handleNavTabClick} />
                <ContentPane>
                    {this.state.activeTab === 'moreInfo' &&
                        <MoreInfo comments={deal.comments} />
                    }
                    {this.state.activeTab === 'details' &&
                        this.renderDetails()
                    }
                </ContentPane>
            </DropTargetContainer>
        );
    }
}

export default DealView;

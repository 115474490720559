import $ from 'jquery'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import vent from 'js/vent'
import app from 'js/app'
import api from 'js/api'
import resetTemplate from 'templates/reset.handlebars'


var ForcedPasswordChangeView = BaseView.extend({
        tagName: 'article',
        id: 'reset-password-container',
        className: 'hide',
        template: Handlebars.compile(resetTemplate),
        templateHelpers: function () {
            // If user is a new user, set this to true
            var newUser = true;

            return {
                newUser: newUser
            };
        },
        ui: {
            toggleGroups: '.toggle-group',
            resetButton: '#reset',
            passwordInputs: 'input[type=password]',
            passwordInput: '#password',
            passwordConfirmInput: '#password-confirm',
            alerts: '.alert',
            successAlert: '#success',
            failedAlert: '#failed',
            formContainer: '.form-login',
            errorContainer: '.error-container'
        },
        events: {
            'click #reset': 'doAction'
        },
        onRender: function() {
            if (this.options.wantsReset) {
                var self = this;

                api.checkResetTokenIsValid(this.options.userId, this.options.token, function (success, xhr) {
                    if (success) {
                        self.ui.formContainer.show();
                    }
                    else {
                        var detail = JSON.parse(xhr.responseText).detail;
                        var messageElement = self.ui.errorContainer.find('.message');

                        if (detail.exception === 'UserExpiredPasswordResetTokenError') {
                            messageElement.html('This password reset link has expired. Please <a href="#">click here to resubmit password reset</a> request.');

                            messageElement.find('a').on('click', function(ev) {
                                ev.preventDefault();
                                vent.trigger('forgotten');
                            });
                        }
                        else {
                            messageElement.html(detail.message);
                        }

                        self.ui.errorContainer.show();
                    }
                });
            }
            else {
                this.ui.formContainer.show();
            }
        },
        onShow: function() {
            this.$el.fadeIn(500);
        },
        doAction: function(ev) {
            if (ev) {
                ev.preventDefault();
            }

            var password = this.ui.passwordInput.val();
            var confirmation = this.ui.passwordConfirmInput.val();

            if (password !== confirmation || !password) {
                // Remove invalid styling then apply again - for shake animation
                this.ui.passwordInputs.removeClass('validation_error', function() {
                    $(this).addClass('validation_error');
                });
                this.showFailedAlert('Password invalid!', 'Please enter the same password in both fields');

                return;
            }

            // Reset (hide) all alerts
            this.ui.alerts.hide();

            if (this.options.wantsReset) {
                this.reset(password);
            }
            else {
                this.change(password);
            }

        },
        onSuccess: function() {
            var view = this;

            this.ui.toggleGroups.hide();
            this.ui.resetButton.hide();
            this.ui.successAlert.fadeIn(300, function() {
                // Give user time to read alert
                setTimeout(function() {
                    view.continueNavigation();
                }, 2000);
            });

        },
        onError: function(data) {
            var errorTitle = 'Password reset failed!';
            var errorText = 'There was a problem saving your password. Please try again.';

            // Remove invalid styling then apply again - for shake animation
            this.ui.passwordInputs.removeClass('validation_error', function() {
                $(this).addClass('validation_error');
            });

            if (data.status === 400) {
                var response = JSON.parse(data.responseText);

                if (response.detail.exception === 'UserPasswordSameAsPreviousError') {
                    errorText = 'Password cannot be same as previous. Please type another.';
                }
                else if (response.detail.exception === 'UserPasswordTooShort' || response.detail.exception === 'UserPasswordTooWeak') {
                    errorText = 'The provided password does not meet our minimum password requirements. Please visit our <a href="http://support.salesseek.net/en/articles/6544601-minimum-password-requirements" target="_blank"><b><u>"Minimum password requirements"</u></b></a> support article for more info';
                }
            }

            this.showFailedAlert(errorTitle, errorText);
        },
        change: function(password) {
            var view = this;

            this.options.model.set({password: password});
            this.options.model.save({password: password}, {
                patch: true,
                success: function () {
                    view.onSuccess();
                },
                error: function (model, data) {
                    view.onError(data);
                }
            });
        },
        reset: function(password) {
            var view = this;

            api.reset(null, this.options.token, password, function (success, xhr) {
                if (success !== null && success !== undefined) {
                    view.onSuccess();
                }
                else {
                    view.onError(xhr);
                }
            });
        },
        showFailedAlert: function(title, text) {
            this.ui.failedAlert.find('h6').text(title);
            this.ui.failedAlert.find('small').html(text);
            this.ui.failedAlert.fadeIn(300);
        },
        continueNavigation: function () {
            var next = this.options.cameFrom || '';
            vent.trigger('login', next);
        },
        getUrl: function () {
            if (this.options.wantsReset) {
                return (
                    'reset' +
                    '?token=' + encodeURIComponent(this.options.token)
                );
            }
            else {
                var result = 'login';
                if (this.options.cameFrom) {
                    result += (
                        '?came_from=' +
                        encodeURIComponent(this.options.cameFrom)
                        );
                }
                return result;
            }
        }
    });
export default ForcedPasswordChangeView;

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import template from 'templates/campaign_list.handlebars'


var ItemModel = Backbone.Model.extend( {
} );


var ItemCollection = Backbone.Collection.extend( {
    model: ItemModel
} );


var ItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile('<a class="basic-list-item">{{name}}</a>'),

    templateHelpers: function() {
        return {
            name: this.model.get('name')
        };
    }
});

export default Marionette.CompositeView.extend({
    className: 'campaign-list at-top',
    itemView: ItemView,
    itemViewContainer: '.basic-list',
    template: Handlebars.compile(template),

    ui: {
        header: '.widget-header',
        footer: '.widget-footer',
        scroll: '.content-container > .content'
    },

    events: {
        'click .back': function() {
            // Bubble up and unexpand parent widget
            this.trigger('back');
        }
    },

    initialize: function(options) {
        var all = options.allGroups;
        var selected = options.selectedGroups;

        this.collection = new ItemCollection();

        for(var i = 0; i < selected.length; ++i) {
            this.collection.add(new ItemModel({ name: all[selected[i]].name}));
        }
    },

    onRender: function() {
        var view = this;

        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        // Resize revenue planner on window resize
        this.resizeCallback = function () {
            view.scrollbar();
        };
        $(window).on('resize', this.resizeCallback);
    },

    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },

    onShow: function() {
        this.scrollbar();
    },

    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            footerHeight = this.ui.footer.is(':visible') ? this.ui.footer.height() : 0,
            availableHeight = (this.$el.height() - this.ui.header.height() - footerHeight),
            innerHeight = container.find('.content-inner:first').height(),
            height = Math.min(innerHeight, availableHeight);

        container.css('maxHeight', height);
        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),

    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }

});

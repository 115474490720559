import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'

import app from 'js/app'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import TextManager from 'app/text-manager'
import CustomFieldModel from 'js/models/custom_field.js'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import BucketsCollection from 'js/collections/buckets'
import CustomFieldsItemsView from 'js/views/custom_fields_items.js'
import customFieldsTemplate from 'templates/settings/custom_fields.handlebars'
import addCustomFieldTiemplate from 'templates/settings/add_new_custom_field.handlebars'
import customFieldListItemViewTemplate from 'templates/settings/custom_field_list_item_view.handlebars'
import dropdownOptionItemViewTemplate from 'templates/settings/custom_field_dropdown_option_item_view.handlebars'
import productPriceItemViewTemplate from 'templates/settings/custom_field_product_price_item_view.handlebars'
import filterOperators from 'js/views/filters/operators'
import FilterFields from 'js/views/filters/fields'
import FilterAdvancedView from 'js/views/filters/advanced'
import PhaseFilterItemsView from 'js/react_views/phase-filters/phaseFilters.js'
import customFieldFilterTemplate from 'templates/settings/phase_gate_filter.handlebars'


var filterCustomFields = {
    'individual_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_INDIVIDUAL, capitalize}']),
        'view': 'individuals'
    },
    'organization_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_ORGANIZATION, capitalize}']),
        'view': 'organizations'
    },
    'opportunity_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_DEAL, capitalize}']),
        'view': 'deals'
    },
};


// Return the display text for each field type
var getFieldTypeText = function(type) {
    switch(type) {
    case 'checkbox':
        return 'Checkbox';
    case 'currency':
        return 'Currency';
    case 'date':
        return 'Date';
    case 'dropDown':
        return 'Dropdown';
    case 'individual':
        return TextManager.parseText('${ID_INDIVIDUAL, capitalize}');
    case 'number':
        return 'Number';
    case 'organization':
        return TextManager.parseText('${ID_ORGANIZATION, capitalize}');
    case 'opportunity':
        return TextManager.parseText('${ID_DEAL, capitalize}');
    case 'paragraph':
        return 'Paragraph';
    case 'text':
        return 'Text';
    case 'url':
        return 'URL';
    case 'urlImage':
        return 'Image (URL)';
    case 'user':
        return 'User';
    case 'product':
        return 'Product';
    case 'list':
        return 'Entity List';
    default:
        return '';
    }
};

var CustomFieldToCreateModel = Backbone.Model.extend({
});

// ------------------------------------------------
var DropDownOptionModel = Backbone.Model.extend({
});

// ------------------------------------------------
var DropDownOptionCollection = Backbone.Collection.extend({
    model: DropDownOptionModel
});

// ------------------------------------------------
var DropDownOptionItemView = Marionette.Layout.extend({
    tagName: 'fieldset',
    template: Handlebars.compile(dropdownOptionItemViewTemplate),
    templateHelpers: function() {
        return {
            index: this.model.collection.indexOf(this.model) + 1
        };
    },
    events: {
        'click .delete-item': function(ev) {
            var self = this;
            ev.preventDefault();

            this.$el
                .animate(
                    { opacity: 0 },
                    { queue: false, duration: 200 }
                )
                .hide(200, function() {
                    self.options.parent.collection.remove(self.model);
                    self.valuesChanged = true;
                });
        },
        'blur #value': function(ev) {
            ev.preventDefault();
            this.model.set('value', ev.target.value);
            this.valuesChanged = true;
        }
    },

    initialize: function() {
        this.valuesChanged = false;

        // Update label text when items are removed
        this.listenTo(this.model.collection, 'remove', function() {
            this.$el.find('.index').text(this.model.collection.indexOf(this.model) + 1);
        });
    },

    onShow: function() {
        this.$el.show();
    }
});

// ------------------------------------------------
var DropDownOptionsListView = Marionette.CollectionView.extend({
    className: 'custom-fields-list',
    itemView: DropDownOptionItemView,

    initialize: function()
    {
        this.itemViewOptions = {parent: this};
    },
    onBeforeRender: function() {
        // Override method for animating new items
        this.onBeforeItemAdded = function(){};
    },
    onRender: function() {
        // Animate new items on show
        this.onBeforeItemAdded = function(view) {
            view.on('show', function() {
                view.$el
                    .css('opacity', 0)
                    .show(200)
                    .animate(
                        { opacity: 1 },
                        { queue: false, duration: 400 }
                    );
            });
        };
    },
    valuesHaveChanged: function() {
        for(var i = 0; i < this.collection.length; ++i) {
            var v = this.children.findByModel(this.collection.models[i]);

            if (v.valuesChanged) {
                return true;
            }
        }

        return false;
    }
});

// ------------------------------------------------
var ProductPriceItemView = Marionette.Layout.extend({
    tagName: 'fieldset',
    template: Handlebars.compile(productPriceItemViewTemplate),
    events: {
        'click .delete-item': function(ev) {
            ev.preventDefault();
            this.options.parent.collection.remove(this.model);
            this.options.parent.itemRemoved = true;
        },
        'blur .quantity': function(ev) {
            ev.preventDefault();
            this.model.set('minQuantity', ev.target.value);
            this.valuesChanged = true;
        },
        'blur .price': function(ev) {
            ev.preventDefault();
            this.model.set('price', ev.target.value);
            this.valuesChanged = true;
        }
    },

    initialize: function() {
        this.valuesChanged = false;
    },

    onShow: function() {
        this.$el.show();
    }
});

// ------------------------------------------------
var ProductPricesListView = Marionette.CollectionView.extend({
    className: 'custom-fields-list',
    itemView: ProductPriceItemView,

    initialize: function()
    {
        this.itemViewOptions = {parent: this};
        this.itemRemoved = false;
    },
    valuesHaveChanged: function() {
        if (this.itemRemoved) {
            return true;
        }

        for(var i = 0; i < this.collection.length; ++i) {
            var v = this.children.findByModel(this.collection.models[i]);

            if (v.valuesChanged) {
                return true;
            }
        }

        return false;
    }
});

// ------------------------------------------------
var FieldItemView = Marionette.Layout.extend( {
    tagName: 'li',
    template: Handlebars.compile( customFieldListItemViewTemplate ),
    templateHelpers: function() {
        return { type: getFieldTypeText(this.model.get( 'type' )), name: this.model.get( 'name' ) };
    },
    ui: {
        name: '.name',
        tooltips: '[data-toggle=tooltip]'
    },
    attributes: function() {
        return {
            cid: this.model.cid
        };
    },
    events: {
        'click .delete-item': function() {
            var view = this,
                mbContent = {
                    message: Handlebars.compile(
                        'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours. </strong>' +
                        `Are you sure you want to delete this custom field?<strong class="cta">${_.escape(this.model.get('name'))}</strong>` +
                        'Type in the custom field name to proceed.If unsure, please contact support.'
                    ),
                    icon: 'icon-warning',
                    accept_is_negative: true,
                    accept_button_text: 'Delete',
                    cancel_button_text: 'Cancel'
                };

            this.ui.tooltips.tooltip('hide');

            MessageBox.showYesNo(
                mbContent,
                this,
                function(tagName) { // yes
                    if (tagName === view.model.get('name')) {
                        view.$el.fadeOut(400, function() {
                            view.ui.tooltips.tooltip('destroy');
                            view.model.destroy({wait: true});
                        });
                    }
                },
                function() {}, // no
                null,
                { prompt: {
                    type: 'input',
                    conditional_accept_button_shown: view.model.get('name')
                }}
            );
        },
        'click .edit-item': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('edit-custom-field', this.model);
        }
    },
    onRender: function() {
        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });
    },

    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val();
            var attr = {};

            attr[dbField.field] = val;

            self.model.save( attr, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit();
                }
            });
        });

        edit.on('blur', hideEdit);
    }
});

// ------------------------------------------------
var FieldsList = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'custom-fields-list editable-list',
    itemView: FieldItemView,

    initialize: function() {
        this.itemViewOptions = {parent: this};
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: false,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var cid = $(ui.item).attr('cid');
                var model = self.collection.get(cid);
                var child = self.children.findByModel(model);
                var newOrder = self.$el.children().index(child.$el);
                if (model.get('order') !== newOrder) {
                    var attrs = {
                        order: newOrder
                    };

                    model.save(attrs, {
                        patch: true,
                        wait: true,
                        success: function() {
                            self.trigger('change:order');
                        }
                    });
                }
            }
        });
    }
} );

// Custom Field Filter View
var CustomFieldFilterView = Marionette.Layout.extend({
    className: 'show-phase-filter',
    template: Handlebars.compile(customFieldFilterTemplate),
    ui: {
        filterInput: '#filters'
    },
    regions: {
        phaseFilterListReg: '.phase-filter-list-container',
        popoverRegion: '.popover-region',
    },
    events: {
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    templateHelpers: function() {
        return {
            title: this.title,
            phaseName: '',
        };
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onShow: function() {
        var self = this;
        this.loadCustomFields(function() {
            self.phaseFilterListReg.show(
                new PhaseFilterItemsView({
                    filters: self.filters,
                    filterFields: self.filterFields,
                    showFilter: self.showFilter.bind(self),
                    removeFilterRule: self.removeFilterRule.bind(self)
                })
            );
        });
    },
    showFilter(target, callback, filter, rule, ind) {
        target = $(target);
        var title, model;

        var self = this;

        if (rule) {
            title = 'Edit Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection(rule)
            });
        }
        else {
            title = 'Add Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection()
            });
        }

        var popoverView = new FilterAdvancedView({
            title: title,
            collection: model.get('items'),
            fields: _.clone(this.filterFields)
        });

        this.listenTo(popoverView, 'form-saved', function() {
            var newRule = [];
            model.get('items').each(function(item) {
                newRule.push(item.attributes)
            });
            filter = filter || {};
            filter.rules = filter.rules || [];
            if (rule) {
                filter.rules[ind] = newRule;
            }
            else {
                filter.rules.push(newRule);
            }

            this.trigger('edit-custom-field-filter', filter);
            callback(filter);
        });

        this.popoverRegion.show(popoverView);
        this.popoverRegion.$el.css({
            left: target.offset().left - 40,
            top: target.offset().top + 40,
            position: 'fixed'
        });
        this.popoverRegion.$el.show();
    },
    removeFilterRule(callback, filter, ind) {
        var self = this;
        filter = filter || {};
        filter.rules = filter.rules || [];
        filter.rules.splice(ind, 1);

        this.trigger('edit-custom-field-filter', filter);
        callback(filter);
    },
    buildFilterFields: function() {
        var fields = _.clone(FilterFields());

        _.each(this.customFields, function (collection, id) {
            collection.each(function (model) {
                if (model.get('type') !== 'list') {
                    fields.push({
                        'id': id + '#' + model.get('id'),
                        'name': model.get('name'),
                        'operators': filterOperators.custom[model.get('type')],
                        'group': filterCustomFields[id].name,
                        'value_def': model.get('value')
                    });
                }
            });
        });

        this.filterFields = fields;
    },
    loadCustomFields: function(callback) {
        var view = this;

        function checkReady() {
            var unready = _.filter(view.customFields, function(item) {
                return !item;
            });
            if (unready.length === 0) {
                view.buildFilterFields();
                callback();
            }
        }

        this.customFields = {};
        _.each(filterCustomFields, function(item, id) {
            view.customFields[id] = null;

            var collection = new CustomFieldsCollection();
            collection.fetch({
                filterBy: [{
                    attribute: 'view',
                    value: item.view
                }],
                complete: function() {
                    view.customFields[id] = collection;
                    checkReady();
                }
            });
        });
    }
});

// ------------------------------------------------
var NewFieldView = Marionette.Layout.extend( {
    className: 'edit-custom-field edit-form-modal',
    template: Handlebars.compile( addCustomFieldTiemplate ),
    templateHelpers: function() {
        const maxNumQuickFilters = 8;
        var numQuickFiltersAvailable = maxNumQuickFilters - this.options.numQuickFilters;
        let filterCount = 0;

        if (this.editing && (this.cfToEdit.get('params') || {}).custom_field_filter_id) {
            filterCount = this.cfToEdit.get('params')['custom_field_filter']['rules'].length;
        }

        return {
            quickFiltersMessage: `${numQuickFiltersAvailable}/${maxNumQuickFilters} Custom Quick Filter slots are available`,
            noQuickFiltersAvailable: numQuickFiltersAvailable === 0 && !(this.editing && (this.cfToEdit.get('params') || {}).is_quick_filter),
            filterCount: filterCount,
        };
    },
    ui: {
        name: '#field-name',
        formula: '#field-formula',
        type: '.field-type',
        error_messages: '.error-message',
        dropdownContainer: '.dropdown-options-container',
        defaultValueGroup: '.default-value-container',
        defaultValueMergeTagGroup: '.default-value-merge-tag-container',
        defaultValueMergeTag: '#default-value-merge-tag',
        title: '.title',
        required: '#required-checkbox',
        requiredLabel: 'label[for="required-checkbox"]',
        requiredContainer: '#group-required',
        multiselect: '#multiselect-checkbox',
        multiselectLabel: 'label[for="multiselect-checkbox"]',
        lockedMultiselect: '.locked-multi-select',
        isMultiSelectContainer: '#group-multiselect',
        urlOptionsContainer: '.url-options-container',
        urlOptionsPreview: '#preview-widget-checkbox',
        urlOptionsHeightContainer: '.form-group.widget-height',
        urlOptionsHeight: '#preview-widget-height',
        productOptionsContainer: '.product-options-container',
        productOptionsBucket: '.field-product-bucket',
        selectFromGroupContainer: '.select-from-group-container',
        selectFromGroup: '.select-from-group',
        restrictToOrganizationContainer: '.restrict-to-organization-container',
        restrictedToOrganization: '#restrict-to-organization-checkbox',
        quickFilter: '#quickfilter-checkbox',
        quickFilterLabel: 'label[for="quickfilter-checkbox"]',
        quickFilterContainer: '#quick-filter-container',
        counterContainer: '#group-number',
        counterNumber: '#counter-number-checkbox',
        counterNumberLabel: 'label[for="counter-number-checkbox"]',
        fieldFilterContainer: '#field-filter',
    },
    regions: {
        dropdownOptionsList: '.dropdown-options-list',
        productPricesList: '.product-prices-list',
        defaultValue: '.default-value',
        showCustomFieldFilters: {
            selector: '.filter-count',
            regionType: ModalRegion
        },
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
            this.valuesChanged = true;
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': function(ev) {
            ev.preventDefault();
            this.save();
        },
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('cancel');
        },
        'click #add-option': function(ev) {
            ev.preventDefault();
            this.dropDownOptions.add(new DropDownOptionModel());
            this.valuesChanged = true;
        },
        'click #add-product-price': function(ev) {
            ev.preventDefault();
            this.productPrices.add(new Backbone.Model({
                minQuantity: '',
                price: ''
            }));
            this.valuesChanged = true;
        },
        'change .field-type': function(ev) {
            this.valuesChanged = true;
            ev.preventDefault();

            var isIndOrgDeal = ['individual', 'organization', 'opportunity'].indexOf(ev.val) !== -1;

            this.ui.requiredContainer.toggleClass('hide', ev.val === 'list');
            this.ui.quickFilterContainer.toggleClass('hide', ev.val === 'list');
            this.ui.dropdownContainer.toggleClass('hide', ev.val !== 'dropDown');
            this.ui.isMultiSelectContainer.toggleClass('hide', !( ev.val === 'dropDown' || isIndOrgDeal ) || this.editing);
            this.ui.counterContainer.toggleClass('hide', ev.val !== 'number' || this.editing);
            this.ui.selectFromGroupContainer.toggleClass('hide', !isIndOrgDeal);
            this.ui.fieldFilterContainer.toggleClass('hide', !isIndOrgDeal);
            this.ui.restrictToOrganizationContainer.toggleClass('hide', ev.val !== 'individual' || this.fieldsView === 'individuals');

            if (ev.val === "dropDown") {
                this.dropDownOptions = new DropDownOptionCollection();
                this.manageDropDownOptions();

                for( var i = 0; i < 3; ++i) {
                    this.dropDownOptions.add(new DropDownOptionModel());
                }

                this.dropdownOptionsList.show(new DropDownOptionsListView({ collection: this.dropDownOptions }));

                this.listenTo(this.dropDownOptions, 'remove', function() {
                    if (!this.dropDownOptions.length) {
                        this.dropDownOptions.add(new DropDownOptionModel());
                    }
                });
            } else if (ev.val === 'product') {
                this.productPrices = new Backbone.Collection();
                this.productPrices.add(new Backbone.Model({
                    minQuantity: 0,
                    price: '',
                    isFixed: true
                }));

                this.productPricesList.show(new ProductPricesListView({ collection: this.productPrices }));

                new backboneSelect2.SelectView({
                    view: this,
                    $el: this.ui.productOptionsBucket,
                    url: '/buckets',
                    text: 'name',
                    options: {
                        placeholder: 'Select a bucket',
                        dropdownCssClass: 'period-select-popover popover'
                    }
                });
            } else if (isIndOrgDeal) {
                new backboneSelect2.SelectView({
                    view: this,
                    $el: this.ui.selectFromGroup,
                    url: '/groups',
                    params: {
                        element_type: {
                            individual: 'individuals',
                            organization: 'organizations',
                            opportunity: 'opportunities'
                        }[ev.val]
                    },
                    search: true,
                    text: 'title',
                    options: {
                        allowClear: true,
                        placeholder: 'Search for a group',
                        containerCssClass: 'select2-block-from-group',
                        dropdownCssClass: 'custom-field-type-select-popover popover'
                    }
                });
            }

            this.ui.urlOptionsContainer.toggleClass('hide', (ev.val !== 'url'));
            this.ui.urlOptionsHeightContainer.addClass('hide');

            this.ui.productOptionsContainer.toggleClass('hide', (ev.val !== 'product'));
            this.ui.defaultValueMergeTagGroup.toggleClass('hide', (ev.val === 'list'));

            // ... default value
            if (!this.editing && (ev.val !== "dropDown") && (ev.val !== 'list')) {
                var DefaultType = CustomFieldsItemsView.getItemView(ev.val);

                this.defaultCF = new CustomFieldToCreateModel();
                this.defaultCF.set('value', '');

                this.defaultValue.show(new DefaultType({
                    model: this.defaultCF,
                    tagName: 'div',
                    className: 'edit-mode',
                    defaultValue: true
                }));
                this.ui.defaultValueGroup.show();
            }
        },
        'change #required-checkbox': function(ev) {
            this.valuesChanged = true;
        },
        'change #multiselect-checkbox': function(ev) {
            this.valuesChanged = true;
        },
        'change #counter-number-checkbox': function(ev) {
            this.valuesChanged = true;
        },
        'change #preview-widget-checkbox': function(ev) {
            this.valuesChanged = true;
            this.ui.urlOptionsHeightContainer.toggleClass('hide', !$(ev.target).is(':checked'));
        },
        'change #preview-widget-height': function(ev) {
            this.valuesChanged = true;

            var target = $(ev.target);
            var val = parseFloat(target.val());

            if (_.isNaN(val) || val < 0) {
                target.val(0);
            }
        },
        'change #restrict-to-organization-checkbox': function() {
            this.valuesChanged = true;
        },
        'change #quickfilter-checkbox': function(ev) {
            this.valuesChanged = true;
        },
        'click #custom-field-filter': function(ev) {
            let self = this;
            let filters = undefined;

            if (this.editing) {
                if ((this.cfToEdit.get('params') || {}).custom_field_filter_id) {
                    filters = this.cfToEdit.get('params')['custom_field_filter'];
                }
            }

            var filterView = new CustomFieldFilterView({
                title: 'Custom Field Filter',
                filters: filters,
                parent: this
            });

            this.listenTo(filterView, 'cancel', function() {
                this.showCustomFieldFilters.reset();
            });

            this.listenTo(filterView, 'edit-custom-field-filter', function(filter) {
                self.custom_field_filter = filter;
                self.valuesChanged = true;

                const element = document.getElementById("custom-field-filter");

                element.innerHTML = filter.rules.length;
            });

            this.showCustomFieldFilters.show(filterView);
        },
    },

    save: function() {
        var name = this.ui.name.val(),
            type = this.ui.type.select2('val'),
            formula = this.ui.formula.val();

        if (this.editing) {
            type = this.cfToEdit.get('type');
        }

        // ...
        var attrs = {
            name: name,
            type: type,
            view: this.fieldsView,
            value: {},
            formula: formula
        };

        if (type === 'dropDown') {
            var dictRdo = this.createOptionsDict(!this.editing);
            var options = dictRdo[0];
            var newIdx  = dictRdo[1];

            if (newIdx !== -1) {
                attrs.value['default'] = newIdx.toString();
            }

            attrs.value.options = options;

            attrs.params = {
                multiSelect: this.ui.multiselectLabel.is(':visible') && this.ui.multiselect.prop('checked')
            }
        }
        else if (type === 'url') {
            var show_preview = this.ui.urlOptionsPreview.is(':checked');
            attrs.params = {
                show_preview: show_preview,
                preview_height: show_preview ? this.ui.urlOptionsHeight.val() : 0
            };
        } else if (type === 'product') {
            var bucket = this.ui.productOptionsBucket.select2('data');

            if (bucket) {
                attrs.params = {
                    bucket: {
                        id: bucket.id,
                        name: bucket.name
                    }
                };

                var priceModels = this.productPricesList.currentView.children;
                if (priceModels.length === 1) {
                    attrs.params.unit_price = parseFloat(priceModels.findByIndex(0).model.get('price'));
                } else {
                    var unitPrice = [];

                    for (var i = 0; i < this.productPricesList.currentView.children.length; ++i) {
                        var itemView = this.productPricesList.currentView.children.findByIndex(i);
                        unitPrice.push({
                            min_quantity: parseInt(itemView.model.get('minQuantity')),
                            price: parseFloat(itemView.model.get('price'))
                        });
                    }

                    attrs.params.unit_price = unitPrice;
                }
            }
        } else if (['individual', 'organization', 'opportunity'].indexOf(type) !== -1) {
            var item = this.ui.selectFromGroup.select2('data');

            if (item) {
                attrs.params = {
                    select_from_group: {
                        id: item.id,
                        title: item.title
                    }
                }
            } else {
                attrs.params = {
                    select_from_group: null
                }
            }

            attrs.params.multiSelect = this.ui.multiselectLabel.is(':visible') && this.ui.multiselect.prop('checked');

            if (this.custom_field_filter) {
                attrs.params.custom_field_filter = this.custom_field_filter
            }
        } else if (type === 'number') {
            attrs.params = {
                isCounter: this.ui.counterNumberLabel.is(':visible') && this.ui.counterNumber.prop('checked')
            }
        }

        if (this.ui.restrictToOrganizationContainer.is(':visible')) {
            attrs.params = attrs.params || {};
            attrs.params.restricted_to_organization = this.ui.restrictedToOrganization.prop('checked');
        }

        if (this.defaultCF) {
            var val = attrs.value['default'] || this.defaultCF.get('value');

            // On dropdown, creating a new custom field, defaultCF has the idx of the default option.
            // Editing a custom field, defaultCF has the id of the option. The backend expects a idx
            // so we convert id to idx
            if (type === "dropDown" && this.editing && val) {
                if (!isNaN(val)) {
                    attrs.value['default'] = val;
                }
                else {
                    for (var b = 0; b < attrs.value.options.length; ++b) {
                        if ( attrs.value.options[b].id === val) {
                            attrs.value['default'] = b.toString();
                            break;
                        }
                    }
                }
            }
            else {
                attrs.value['default'] = val;
            }
        }

        var defaultValueMergeTag = this.ui.defaultValueMergeTag.val();

        if (defaultValueMergeTag) {
            attrs.value['default_merge_tag'] = defaultValueMergeTag;
        }

        attrs.required = this.ui.requiredLabel.is(':visible') && this.ui.required.prop('checked');

        if (this.ui.quickFilterLabel.is(':visible') && (this.ui.quickFilter.prop('checked') || this.editing)) {
            if (!attrs.params) {
                attrs.params = {};
            }

            attrs.params['is_quick_filter'] = this.ui.quickFilter.prop('checked');
        }


        // ...
        if (!this.dataIsValid(type, name, attrs.value.options)) {
            return;
        }

        // ...
        if (!this.haveChanges()) {
            this.trigger('cancel');
            return;
        }

        // ...
        if (this.editing) {
            this.cfToEdit.save(attrs, {
                patch: true
            });
            this.trigger('update:customField');
        }
        else {
            var field = new CustomFieldModel( attrs );
            field.save();
            this.trigger('new:customField', field);
        }
    },

    haveChanges: function()
    {
        if (this.editing) {
            var valuesChanged = this.valuesChanged;

            if (this.dropdownOptionsList.currentView) {
                valuesChanged = valuesChanged || this.dropdownOptionsList.currentView.valuesHaveChanged();
            }

            if (this.defaultCF) {
                valuesChanged = valuesChanged || (this.defaultCFInitialValue !== this.defaultCF.get('value'));
            }

            if (!valuesChanged) {
                var type = this.cfToEdit.get('type');
                var cf = this.options.customFieldToEdit;

                if (type === 'product') {
                    var params = cf.get('params');
                    valuesChanged = params.bucket.id !== this.ui.productOptionsBucket.select2('val');

                    if (this.productPricesList.currentView) {
                        valuesChanged |= this.productPricesList.currentView.valuesHaveChanged();
                    }
                } else if (['individual', 'organization', 'opportunity'].indexOf(type) !== -1) {
                    var params = cf.get('params') || {};
                    var item = this.ui.selectFromGroup.select2('val');

                    valuesChanged = (params.select_from_group && !item) ||
                                    (!params.select_from_group && item) ||
                                    (params && item && params.select_from_group.id !== item.id);
                }
            }

            return valuesChanged;
        }

        return true;
    },

    dataIsValid: function(type, name, opts)
    {
        this.error_messages.remove.call(this);

        if (!type) {
            this.ui.type.select2('container')
                .children(':first')
                .addClass('validation_error')
                .parent()
                .nextAll('.error-message')
                .text('A field type is required')
                .addClass('invalid');
        }

        if (!name) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text('A field name is required')
                .addClass('invalid');
        }

        // ...
        var somethingIsWrong = (!type || !name);

        // the field doesnt exists
        for ( var i = 0; i < this.existingFields.length; ++i ) {
            if ((this.existingFields.models[ i ] !== this.cfToEdit) &&
                (this.existingFields.models[ i ].get( 'name' ) === name )) {
                this.ui.name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('This field name is already in use')
                    .addClass('invalid');
                somethingIsWrong = true;
            }
        }

        if (this.defaultCF) {
            var message = this.defaultValue.currentView.getErrorMsg();
            if (message) {
                this.defaultValue.currentView.showError(message);
            } else {
                this.defaultValue.currentView.hideError(message);
            }
            somethingIsWrong = somethingIsWrong || !!message;
        }

        if (type === 'dropDown') {
            var textOneOption = 'Please provide at least one option';
            var textProvide = 'Please provide a value or remove this option';

            if (this.editing) { // in editing mode check that all options has a value
                for (var k = 0; k < opts.length; ++k) {
                    var o = opts[k];
                    var itemView = this.dropdownOptionsList.currentView.children.findByIndex(k);
                    var valid = (o.value !== '');
                    var errorMessage = itemView.$el.find('.error-message');

                    errorMessage.text(opts.length === 1 ? textOneOption : textProvide);
                    errorMessage.toggleClass('invalid', !valid);
                    itemView.$el.find('.form-control').toggleClass('validation_error', !valid);

                    somethingIsWrong = somethingIsWrong || !valid;
                }
            }
            else { // new custom field. It should has at least one value
                if (opts.length === 0) {
                    var iv = this.dropdownOptionsList.currentView.children.findByIndex(0);
                    var msg = iv.$el.find('.error-message');

                    msg.text(textOneOption);
                    msg.toggleClass('invalid', true);
                    iv.$el.find('.form-control').toggleClass('validation_error', true);

                    somethingIsWrong = true;
                }
            }
        } else if (type === 'product') {
            for (var i = 0; i < this.productPricesList.currentView.children.length; ++i) {
                var itemView = this.productPricesList.currentView.children.findByIndex(i);
                var valid = (itemView.model.get('minQuantity') !== '') && (itemView.model.get('price') !== '');
                var errorMessage = itemView.$el.find('.error-message');

                errorMessage.toggleClass('invalid', !valid);
                itemView.$el.find('.form-control').toggleClass('validation_error', !valid);

                somethingIsWrong |= !valid;
            }

            var bucket = this.ui.productOptionsBucket.select2('val');
            if (!bucket) {
                this.ui.productOptionsBucket
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('Bucket is required')
                    .addClass('invalid');
            }

            somethingIsWrong |= !bucket;
        }

        return !somethingIsWrong;
    },

    manageDropDownOptions: function() {
        var view = this;

        var manage = function(use_idx, p) {
            var col = p.collection;
            var options = [];

            if (!use_idx) {
                view.dropDownIdxSelection = -1;
            }

            for (var i = 0; i < col.length; ++i) {
                var m = col.models[i];
                var val = m.get('value');

                if (val) {
                    if (!use_idx && (val === view.dropDownTextSelection)) {
                        view.dropDownIdxSelection = i;
                    }

                    options.push({
                        id: i,
                        value: val
                    });
                }
            }

            // ...
            var DefaultType = CustomFieldsItemsView.getItemView('dropDown');

            view.defaultCF = new CustomFieldToCreateModel();
            view.defaultCF.set('valueDef', {options: options});

            if (view.dropDownIdxSelection !== -1) {
                view.dropDownTextSelection = view.dropDownOptions.models[view.dropDownIdxSelection].get('value');
            }
            else {
                view.dropDownTextSelection = '';
            }

            if (view.dropDownIdxSelection !== -1) {
                view.defaultCF.set('value', view.dropDownIdxSelection.toString());
            }
            else {
                view.defaultCF.set('value', '');
            }

            view.defaultValue.show(new DefaultType({
                model: view.defaultCF,
                tagName: 'div',
                className: 'edit-mode'
            }));
            view.ui.defaultValueGroup.show();

            if (view.defaultValue.currentView) {
                view.listenTo(view.defaultValue.currentView, 'editing:valueChanged', function(params) {
                    if (params.value === '') {
                        view.dropDownIdxSelection = -1;
                        view.dropDownTextSelection = '';
                    }
                    else {
                        view.dropDownIdxSelection = parseInt(params.value, 10);
                        view.dropDownTextSelection =
                            view.dropDownOptions.models[view.dropDownIdxSelection].get('value');
                    }
                });
            }
        };

        // ...
        this.listenTo(this.dropDownOptions, 'add remove', function(p) {
            manage(false, p);
        });

        this.listenTo(this.dropDownOptions, 'change', function(p) {
            manage(true, p);
        });
    },

    initialize: function( options ) {
        this.editing = !options.isNew;
        this.existingFields = options.existingFields;
        this.fieldsView = options.fieldsView;
        this.valuesChanged = false;
        this.dropDownIdxSelection = -1;
        this.dropDownTextSelection = '';

        if (this.editing) {
            this.cfToEdit = options.customFieldToEdit;

            if (this.cfToEdit.get('type') !== 'list') {
                this.defaultCF = new CustomFieldToCreateModel();
            }

            if (this.defaultCF) {
                var val = this.cfToEdit.get('value');
                var defaultVal = '';
                var opts = [];

                if (val) {
                    if (this.cfToEdit.get('type') === 'dropDown') {
                        if (val.options) {
                            opts = val.options;
                        }
                    }

                    defaultVal = val['default'];

                    if (val.options)
                    {
                        for (var i = 0; i < val.options.length; ++i) {
                            if (val.options[i].id === defaultVal) {
                                this.dropDownIdxSelection = i;
                                this.dropDownTextSelection = val.options[i].value;
                                break;
                            }
                        }
                    }
                }

                this.defaultCF.set('value', defaultVal);
                this.defaultCF.set('valueDef', {options: opts});

                this.defaultCFInitialValue = defaultVal;
            }
        }
    },

    onRender: function() {
        var field_types = [
                { id: 'checkbox' },
                { id: 'currency' },
                { id: 'date' },
                { id: 'opportunity' },
                { id: 'dropDown' },
                // { id: 'dateTime' },
                { id: 'individual' },
                { id: 'number' },
                { id: 'organization' },
                { id: 'paragraph' },
                { id: 'text' },
                // { id: 'time' },
                { id: 'url' },
                { id: 'urlImage' },
                { id: 'user' },
                { id: 'list' }
            ];

        if (this.fieldsView === 'deals') {
            field_types.push({id: 'product'});
        }

        // Set the display text for each field type
        _.each(field_types, function(option) {
            option.name = getFieldTypeText(option.id);
        });

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.type,
            data: field_types,
            // text: 'name',
            options: {
                placeholder: 'Select a field type',
                dropdownCssClass: 'custom-field-type-select-popover popover'
            }
        });
    },

    onShow: function() {
        if (this.editing) {
            var type = this.cfToEdit.get('type');
            var isIndOrgDeal = ['individual', 'organization', 'opportunity'].indexOf(type) !== -1;

            this.dropDownOptions = new DropDownOptionCollection();

            var value = this.cfToEdit.get('value');
            var params = this.cfToEdit.get('params') || {};

            if (value.options) {
                for(var i = 0; i < value.options.length; ++i) {
                    this.dropDownOptions.add(new DropDownOptionModel(value.options[i]));
                }
            }

            this.listenTo(this.dropDownOptions, 'remove', function() {
                if (!this.dropDownOptions.length) {
                    this.dropDownOptions.add(new DropDownOptionModel());
                }
            });

            var DefaultType = CustomFieldsItemsView.getItemView(this.cfToEdit.get('type'));
            this.defaultValue.show(new DefaultType({
                model: this.defaultCF,
                tagName: 'div',
                className: 'edit-mode'
            }));

            this.manageDropDownOptions();

            // ...
            this.productPrices = new Backbone.Collection();

            if (type === 'product') {
                if (_.isArray(params.unit_price)) {
                    this.productPrices.add(new Backbone.Model({
                        minQuantity: 0,
                        price: params.unit_price[0].price,
                        isFixed: true
                    }));

                    for (var i = 1; i < params.unit_price.length; ++i) {
                        this.productPrices.add(new Backbone.Model({
                            minQuantity: params.unit_price[i].min_quantity,
                            price: params.unit_price[i].price
                        }));
                    }
                } else {
                    this.productPrices.add(new Backbone.Model({
                        minQuantity: 0,
                        price: params.unit_price,
                        isFixed: true
                    }));
                }
            }

            // ...
            this.ui.title.text('Edit Custom Field');
            this.ui.name.focus();
            this.dropdownOptionsList.show(new DropDownOptionsListView({ collection: this.dropDownOptions }));
            this.productPricesList.show(new ProductPricesListView({ collection: this.productPrices }));

            this.$el.find('#group-type').hide();
            this.ui.name.val(this.cfToEdit.get('name'));
            this.ui.formula.val(this.cfToEdit.get('formula'));
            this.ui.defaultValueMergeTag.val(this.cfToEdit.get('default_merge_tag'));

            this.ui.dropdownContainer.toggleClass('hide', type !== 'dropDown');
            this.ui.isMultiSelectContainer.toggleClass('hide', !( type === 'dropDown' || isIndOrgDeal ));
            this.ui.counterContainer.toggleClass('hide', type !== 'number');
            this.ui.fieldFilterContainer.toggleClass('hide', !isIndOrgDeal);
            this.ui.requiredContainer.toggleClass('hide', type === 'list');
            this.ui.quickFilterContainer.toggleClass('hide', type === 'list');
            this.ui.urlOptionsContainer.toggleClass('hide', type !== 'url');
            this.ui.productOptionsContainer.toggleClass('hide', type !== 'product');
            this.ui.defaultValueMergeTagGroup.toggleClass('hide', (type === 'list'));


            this.ui.selectFromGroupContainer.toggleClass('hide', !isIndOrgDeal);
            this.ui.restrictToOrganizationContainer.toggleClass('hide', type !== 'individual' || this.fieldsView === 'individuals');

            if (type === 'url') {
                this.ui.urlOptionsHeightContainer.toggleClass('hide', !params.show_preview);
                this.ui.urlOptionsPreview.prop('checked', params.show_preview);

                if (params.show_preview) {
                    this.ui.urlOptionsHeight.val(params.preview_height);
                }
            } else if (type === 'product') {
                var buckets = new BucketsCollection();
                var self = this;

                this.ui.productOptionsBucket.hide();

                buckets.fetch({
                    rows: -1,
                    success: function(data) {
                        self.ui.productOptionsBucket.show();

                        var bucketsData = data.models.map(b => { return  {id: b.get('id'), name: b.get('name')}});
                        var activeBucket = bucketsData.find(b => b.id === params.bucket.id);

                        new backboneSelect2.SelectView({
                            view: self,
                            $el: self.ui.productOptionsBucket,
                            data: bucketsData,
                            text: 'name',
                            value: activeBucket,
                            options: {
                                placeholder: 'Select a bucket',
                                dropdownCssClass: 'period-select-popover popover'
                            }
                        });
                    }
                });
            } else if (isIndOrgDeal) {
                new backboneSelect2.SelectView({
                    view: this,
                    $el: this.ui.selectFromGroup,
                    url: '/groups',
                    params: {
                        element_type: {
                            individual: 'individuals',
                            organization: 'organizations',
                            opportunity: 'opportunities'
                        }[type]
                    },
                    search: true,
                    text: 'title',
                    value: params.select_from_group,
                    options: {
                        allowClear: true,
                        placeholder: 'Search for a group',
                        containerCssClass: 'select2-block-from-group',
                        dropdownCssClass: 'custom-field-type-select-popover popover'
                    }
                });
            } else if (type === 'list') {
                this.ui.defaultValueGroup.hide();
            }

            this.ui.required.prop('checked', this.cfToEdit.get('required'));
            this.ui.multiselect.prop('checked', (this.cfToEdit.get('params') || {}).multiSelect);
            this.ui.quickFilter.prop('checked', (this.cfToEdit.get('params') || {}).is_quick_filter);
            this.ui.counterNumber.prop('checked', (this.cfToEdit.get('params') || {}).isCounter);

            if (this.editing && ( type === 'dropDown' || isIndOrgDeal ) ) {
                this.ui.multiselect.prop('disabled', true);
                this.ui.lockedMultiselect.removeClass('hide');
            }

            if (this.ui.restrictToOrganizationContainer.is(':visible')) {
                this.ui.restrictedToOrganization.prop('checked', params.restricted_to_organization);
            }
        }
        else {
            this.ui.title.text('Create Custom Field');
            this.ui.type.select2('focus');
            this.ui.dropdownContainer.addClass('hide');
            this.ui.defaultValueGroup.addClass('hide');
            this.ui.defaultValueMergeTagGroup.addClass('hide');
            this.ui.requiredContainer.addClass('hide');
            this.ui.quickFilterContainer.addClass('hide');
            this.ui.isMultiSelectContainer.addClass('hide');
            this.ui.lockedMultiselect.addClass('hide');
            this.ui.counterContainer.addClass('hide');
            this.ui.fieldFilterContainer.addClass('hide');
        }
    },

    createOptionsDict: function(removeEmptyOptions) {
        var rdo = [];
        var defaultIdx = -1;

        if (removeEmptyOptions && this.defaultCF && this.defaultCF.get('value')) {
            defaultIdx = parseInt(this.defaultCF.get('value'), 10);
        }

        if (this.dropDownOptions) {
            for(var i = 0; i < this.dropDownOptions.length; ++i) {
                var m = this.dropDownOptions.models[i];
                var value = m.get('value');

                if (!removeEmptyOptions || value) {
                    rdo.push({
                        'id': m.get('id') || '',
                        'value': value || ''
                    });
                }
            }
        }

        // we look for the new idx (after remove empty values)
        if (defaultIdx !== -1) {
            var valuesDef = this.defaultCF.get('valueDef').options;

            for (var j = 0; j < valuesDef.length; ++j) {
                if (valuesDef[j].id === defaultIdx) {
                    defaultIdx = j;
                    break;
                }
            }
        }

        return [rdo, defaultIdx];
    },

    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
} );

// ------------------------------------------------
export default Marionette.Layout.extend( {
    className: 'custom-fields-settings',
    template: Handlebars.compile( customFieldsTemplate ),
    ui: {
        contentContainer: '.content-container'
    },
    regions: {
        fieldsList: '.custom-fields-list-container',
        addNewField: {
            selector: '.new-custom-field',
            regionType: ModalRegion
        }
    },
    events: {
        'click .new-custom-field': function() {
            this.showCustomFieldView();

        }
    },

    initialize: function( options ) {
        var self = this;

        this.fields = new CustomFieldsCollection();
        this.fields.on("change:order", function() {
            self.fetchFields();
        });

        this.fieldsView = options.fieldsView;
        this.fieldsTitle = options.fieldsTitle;
    },

    onRender: function() {
        this.fetchFields();
    },

    fetchFields: function() {
        var self = this;

        this.fields.fetch( {
            filterBy: [ {
                attribute: 'view',
                value: this.fieldsView
            } ],
            success: function() {
                var list = new FieldsList( { collection: self.fields } );

                self.numQuickFilters = self.fields.models.filter(f => (f.get('params') || {}).is_quick_filter).length;

                self.listenTo(list, 'edit-custom-field', function(cf) {
                    this.showCustomFieldView(cf);
                });

                self.fieldsList.show(list);
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });

        // update cache
        $.get('/custom_fields_quick_filters', function(data) {
            app.globalData.customFieldsQuickFilters = data;
        });
    },

    templateHelpers: function() {
        return { title: this.fieldsTitle };
    },
    showCustomFieldView: function(cf) {
        var newFieldView;

        if (cf) { // we want to edit a custom field
            newFieldView = new NewFieldView({
                isNew: false,
                customFieldToEdit: cf,
                existingFields: this.fields,
                fieldsView: this.fieldsView,
                numQuickFilters: this.numQuickFilters
            });
        }
        else { // new custom field
            newFieldView = new NewFieldView({
                isNew: true,
                existingFields: this.fields,
                fieldsView: this.fieldsView,
                numQuickFilters: this.numQuickFilters
            });
        }

        this.addNewField.show( newFieldView );

        this.listenTo( newFieldView, 'new:customField', function( field ) {
            this.fields.add( field );
            this.addNewField.reset();
            this.$el.find('.content-container').nanoScroller();
        });

        this.listenTo(newFieldView, 'update:customField', function() {
            this.addNewField.reset();
            this.fieldsList.currentView.render();
        });

        this.listenTo( newFieldView, 'cancel', function() {
            this.addNewField.reset();
        } );
    },

    scrollEvents: function() {
        var header = this.$el.find( '.detail-header' );
        var view = this;

        $( this.ui.contentContainer ).find( '.content' ).scroll( function() {
            if( view.ui.contentContainer.find( '.custom-fields-list' ).position().top < -1 ) {
                if( !header.hasClass( 'header-shadow' ) ) {
                    header.addClass('header-shadow');
                }
            }
            else{
                header.removeClass( 'header-shadow' );
            }
        } );
    }
} );

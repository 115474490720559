import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import Currency from 'js/utils/currency.js'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import GroupModel from 'js/models/group.js'
import ClientModel from 'js/models/client.js'
import MailingListsCollection from 'js/collections/mailing_lists.js'
import MailingListModel from 'js/models/mailing_list.js'
import UsersCollection from 'js/collections/users.js'
import vent from 'js/vent.js'
import mailingListsTemplate from 'templates/settings/mailing_lists.handlebars'
import mailingListsListItemViewTemplate from 'templates/settings/mailing_lists_list_item_view.handlebars'
import mailingListsAddNewGroupTemplate from 'templates/settings/mailing_lists_add_new_lead_source.handlebars'


var GroupItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(mailingListsListItemViewTemplate),
    ui: {
        name: '.name',
        description: '.description',
        tooltips: '[data-toggle=tooltip]'
    },
    events: {
        'click .delete-item': function() {
            var view = this,
                mbContent = {
                    message: Handlebars.compile(
                        'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours. </strong>' +
                        `Are you sure you want to delete this mailing list?<strong class="cta">${_.escape(this.model.get('name'))}</strong>` +
                        'Type in the mailing list name to proceed.If unsure, please contact support.')
                    ,
                    icon: 'icon-warning',
                    accept_is_negative: true,
                    accept_button_text: 'Delete',
                    cancel_button_text: 'Cancel'
                };

            MessageBox.showYesNo(
                mbContent,
                this.options.parent,
                function(name) { // yes
                    if (name === view.model.get('name')) {
                        view.$el.fadeOut(400, function() {
                            view.model.destroy({
                                patch: true,
                                success: function() {
                                    view.trigger('remove:mailingList', view.model);
                                }
                            });
                        });
                    }
                },
                function() {}, // no
                null,
                { prompt: {
                    type: 'input',
                    conditional_accept_button_shown: view.model.get('name')
                }}
            );
        }
    },
    onRender: function() {
        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });
    }
});

var GroupList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'group-list editable-list',
    itemView: GroupItemView,
    itemViewOptions: function() {
        return this.options;
    }
});

var NewMailingListView = Marionette.ItemView.extend({
    className: '',
    template: Handlebars.compile(mailingListsAddNewGroupTemplate),
    ui: {
        groupSelector: '#group-selector'
    },
    events: {
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onRender: function() {
        var self = this;

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.groupSelector,
            text: 'name',
            url: '/groups',
            params: {
                rows: -1,
                element_type: 'individuals',
                group_type: 'static'
            },
            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'popover select2-drop-wider'
            }
        });

        this.ui.groupSelector.on('change', function() {
            self.$el.find('.validation_error').removeClass('validation_error');
            self.$el.find('.error-message').empty().removeClass('invalid');
        });
    },
    save: function() {
        var self = this,
            id = this.ui.groupSelector.select2('val');

        if (!id) {
            this.ui.groupSelector.select2('container')
                .children(':first')
                .addClass('validation_error')
                .parent()
                .nextAll('.error-message')
                .text('Group is required')
                .addClass('invalid');

            return;
        }

        var group = new GroupModel({ id: id });

        group.save({
            mailing_list: true
        }, {
            patch: true,
            success: function() {
                self.trigger('new:mailingList', group);
            }
        });
    }
});

export default Marionette.Layout.extend({
    className: 'mailing-lists',
    template: Handlebars.compile(mailingListsTemplate),
    templateHelpers: function() {
        return {
            selectiveUnsubscribing: app.user.get('client').selective_unsubscribing
        };
    },
    regions: {
        groupListRegion: '.group-list-container',
        addNewMailingList: {
            selector: '.add-new-mailing-list-view', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    events: {
        'click .add-new-mailing-list': function() {
            var newMailingListView = new NewMailingListView();
            this.addNewMailingList.show(newMailingListView);
            this.listenTo(newMailingListView, 'new:mailingList', function(group) {
                var mailingList = new MailingListModel(group.attributes);
                this.groups.add(mailingList);
                this.addNewMailingList.reset();
                this.$el.find('.content-container').nanoScroller();
            });
            this.listenTo(newMailingListView, 'cancel', function() {
                this.addNewMailingList.reset();
            });
        },
        'click .selective-unsubscribe': function(ev) {
            var client = new ClientModel(app.user.get('client'));
            var target = $(ev.target);
            app.user.get('client').selective_unsubscribing = target.is(":checked");
            client.save({selective_unsubscribing: target.is(":checked")}, {patch: true});
        }
    },
    initialize: function() {
        this.groups = new MailingListsCollection();
        this.groupList = new GroupList({ collection: this.groups, parent: this });

        this.listenTo(this.groupList, 'itemview:remove:mailingList', function(itemView, model) {
            this.$el.find('.content-container').nanoScroller();
        }, this);
    },
    onRender: function () {
        var self = this;

        this.groups.fetch({
            rows: -1,
            success: function() {
                self.groupListRegion.show(self.groupList);
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });
    },
    scrollEvents: function() {
        var container = this.$el.find('.content-container'),
            header = this.$el.find('.detail-header');

        $(container).find('.content').scroll(function(){
            if(container.find('.group-list-container').position().top < -1) {
                if(!header.hasClass('header-shadow')){
                    header.addClass('header-shadow');
                }
            }else{
                header.removeClass('header-shadow');
            }
        });
    }
});

import React from 'react'

import AppConfig from 'app/app-config'
import MessageBox from 'js/views/message_box'

import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import dateFormat from 'js/utils/date-format'
import vent from 'js/vent'
import TaskModel from 'js/models/task'
import Tooltip from 'js/react_views/tooltip/tooltip'
import Paginator from 'js/react_views/paginator/paginator'

import style from './list_simple.css'


class LeadItem extends React.Component {
    handleClick() {
        this.props.widget.triggerEvent('showEntity', {
            type: 'individuals',
            id: this.props.data.id,
            rectState: 'leftDocked',
            widget: this.props.widget
        });
    }

    render() {
        const data = this.props.data;
        const profileSourceUrl = data.photo_url;
        const tags = data.tags.map(t => t.name).join(', ');
        const funnels = data.funnels.map(f => f.name).join(', ');
        let leadSourceUrl = null;

        if (['facebook', 'twitter', 'web'].indexOf(data.source?.system_type) !== -1) {
            leadSourceUrl = `/img/lead_sources/lead-${data.source.system_type}.png`;
        } else {
            leadSourceUrl = '/img/lead_sources/lead-default.png';
        }

        return (
            <div
                className={style.leadItem}
                onClick={this.handleClick.bind(this)}
            >
                <div className={style.iContainer}>
                    <div className={style.liImageContainer}>
                        {profileSourceUrl ? (
                            <div className={style.icProfileImage} style={{backgroundImage: `url(${profileSourceUrl})`}}/>
                        ) : (
                            <div className='icon-user'/>
                        )}
                    </div>

                    <div className={style.lInfo}>
                        <div className={style.iName}>{data.full_name}</div>
                        {tags.length > 0 &&
                            <div
                                className={style.lTags}
                            >
                                <Tooltip
                                    title={tags}
                                />

                                {data.tags.map(tag => {
                                    return (
                                        <div
                                            key={`lead_tag_${tag.id}`}
                                            className={style.tTag}
                                        >
                                            {tag.name}
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        {funnels.length > 0 &&
                            <div
                                className={style.lTags}
                                style={{ marginTop: tags.length > 0 ? '5px' : '' }}
                            >
                                <Tooltip
                                    title={funnels}
                                />

                                {data.funnels.map(funnel => {
                                    return (
                                        <div
                                            key={`lead_funnel_${funnel.id}`}
                                            className={style.tTag}
                                        >
                                            {funnel.name}
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        <div className={style.iCreated}>Created {dateFormat.timelineFormat(dateFormat.parseDate(data.created))}</div>
                    </div>

                    <img className={style.icLeadSource} src={leadSourceUrl}/>
                </div>
            </div>
        );
    }
}

class TaskItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completed: props.data.completed
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (!this.mounted) {
            return;
        }

        if (this.props.data.completed !== this.state.completed) {
            this.setState({
                completed: this.props.data.completed
            });
        }
    }

    handleRelatedClick() {
        const related = this.props.data.related;

        this.props.widget.triggerEvent('showEntity', {
            type: related.type,
            id: related.id,
            rectState: 'leftDocked',
            widget: this.props.widget
        });
    }

    handleTaskEdit() {
        vent.trigger('quick:edit:task', new TaskModel(this.props.data));
    }

    handleTaskCompleted() {
        const task = this.props.data;
        const completed = !this.state.completed;
        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/tasks/${task.id}`,
            data: JSON.stringify({ completed: completed }),
            dataType: 'json',
            success: function(data) {
                self.setState({
                    completed: completed
                });

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Task saved',
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });

                const related = self.props.data.related;

                if (related && completed) {
                    if (AppConfig.getValue('on_task_completation.show_activity_note_popup', false) ||
                        AppConfig.getValue('on_task_completation.show_task_popup', false)) {
                        vent.trigger('quick:edit:task', new Backbone.Model({ id: task.id }), {
                            showNextSteps: true
                        });
                    }
                }

                /*
                const showActivityNotePopUp = AppConfig.getValue('dashboard.on_task_completation.show_activity_note_popup', false);

                if (related && showActivityNotePopUp && completed) {
                    const entityType = related.type;

                    var showTaskPopup = function() {
                        if (AppConfig.getValue('on_task_completation.show_task_popup', false)) {
                            var content = {
                                message: 'Would you like to create a task?',
                                icon: 'icon-question',
                                cancel_button_text: AppConfig.getValue(`on_task_completation.activity_note_popup.${entityType}.cancel_button_text`, 'No')
                            };

                            MessageBox.showYesNo(content, self, function() {
                                var typeMap = {
                                    'individuals': 'individual',
                                    'opportunities': 'opportunity',
                                    'organizations': 'organization'
                                };

                                var model = new TaskModel({
                                    related: {
                                        id: related.id,
                                        related_type: typeMap[related.type],
                                        name: related.full_name || related.name
                                    },
                                    tags: data.tags
                                });

                                vent.trigger('quick:add:task', model);
                            });
                        }
                    };

                    var content = {
                        message: 'Would you like to create an activity note?',
                        icon: 'icon-question',
                        cancel_button_text: AppConfig.getValue(`on_task_completation.activity_note_popup.${entityType}.cancel_button_text`, 'No')
                    };

                    MessageBox.showYesNo(content, self.props.parent ,function() { // Yes
                        vent.trigger('quick:add:note', {
                            related: related,
                            onNoteCreated: showTaskPopup,
                            onNoteCancelled: showTaskPopup,
                            tags: data.tags
                        });
                    }, function() { // No
                        showTaskPopup();
                    });
                }
                */

                vent.trigger('task:completed:change', {
                    taskId: task.id,
                    completed: completed
                });
            }
        });
    }

    render() {
        const data = this.props.data;
        const relatedName = data.related?.name;
        const now = new Date();
        const dueDate = dateFormat.parseDate(data.due_date);
        const overdue = data.due_date && dueDate < now;
        const completed = this.state.completed;
        const tooltip = data.subject ? `${data.subject}: ${data.text}` : data.text;

        return (
            <div
                className={`
                    ${style.taskItem}
                    ${completed ? style.tiCompleted : ''}
                `}
            >
                <div
                    className={`
                        ${style.tiCheckbox}
                        ${overdue ? style.cbOverdue : ''}
                    `}
                    onClick={this.handleTaskCompleted.bind(this)}
                >
                    <div className={`${style.cbTick} icon-checkmark3`}/>
                </div>

                <div className={style.tiDetails}>
                    <div
                        className={style.dDescription}
                        onClick={this.handleTaskEdit.bind(this)}
                    >
                        <Tooltip
                            title={tooltip}
                        />
                        {data.subject &&
                            <span style={{fontWeight: 600, marginRight: '5px'}}>{data.subject}</span>
                        }
                        <span>{data.text}</span>
                    </div>

                    {relatedName &&
                        <div className={style.dSecondRow}>
                            <div className={style.sArrow}>&#8627;</div>
                            <div
                                className={style.sName}
                                onClick={this.handleRelatedClick.bind(this)}
                            >
                                {relatedName}
                            </div>
                            {dueDate && <div className={style.sDash}>–</div>}
                            {dueDate &&
                                <div
                                    className={`
                                        ${style.sDate}
                                        ${overdue ? style.dOverdue : ''}
                                    `}
                                >
                                    {dateFormat.dueItemFormat(dueDate)}
                                </div>
                            }
                        </div>
                    }

                    {!relatedName && data.due_date &&
                        <div className={style.dSecondRow}>
                            <div className={style.sDash}>–</div>
                            <div
                                className={`
                                    ${style.sDate}
                                    ${overdue ? style.dOverdue : ''}
                                `}
                            >
                                {dateFormat.dueItemFormat(dueDate)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


const ITEM_COMPONENT_BY_TYPE = {
    lead: LeadItem,
    task: TaskItem
}


export default class ListSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            paginationInfo: null,
            fetchingData: false,
            titleVisible: true
        };

        this.fetchStart = 0;
        this.numRowsToFetch = this.props.widget.params.query?.args.rows || 20;

        const self = this;

        this.props.widget.addEventListener('refresh', function() {
            self.fetchData(true);
        });

        this.props.widget.addEventListener('rectState:changed', function(state) {
            self.manageTitleVisibility(state);
        });

        if (props.widget.params.item_type === 'task') {
            this.props.widget.addEventListener('task:completed:change', function(params) {
                if (self.state.fetchingData) {
                    return;
                }

                const items = self.state.items;
                let updated = false;

                for (let item of items) {
                    if (item.id === params.taskId) {
                        if (item.completed !== params.completed) {
                            item.completed = params.completed;
                            updated = true;
                        }
                        break;
                    }
                }

                if (updated) {
                    self.setState({
                        items: items
                    });
                }
            });
        }

        _.defer(function() {
            self.fetchData();
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.manageTitleVisibility(this.props.widget.rectState);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    manageTitleVisibility(rectState) {
        const params = this.props.widget.params;

        if (params.title_visible_on_rect_state) {
            this.setState({
                titleVisible: params.title_visible_on_rect_state.indexOf(rectState) !== -1
            });
        }
    }

    onData(data, paginationInfo) {
        if (!this.mounted) {
            return;
        }

        this.setState({
            items: data,
            paginationInfo: paginationInfo,
            fetchingData: false
        });
    }

    fetchData(force) {
        if (!this.mounted) {
            return;
        }

        // only displays the loader when the widget is empty
        if (this.state.items.length === 0) {
            this.setState({
                fetchingData: true
            });
        }

        const query = this.props.widget.params.query;
        const args = _.extend(query?.args || {},
            {
                start: this.fetchStart
            }
        );

        let options = _.clone(query.options || {});
        options.force = force;

        this.props.widget.fetcher.get(query.url, args, this.onData.bind(this), options);
    }

    render() {
        const params = this.props.widget.params;
        let content = null;

        if (this.state.fetchingData) {
            content = (
                <LoadingIndicator/>
            );
        } else {
            if (this.state.items.length === 0) {
                content = (
                    <div className={style.lNoItems}>No items to display</div>
                );
            } else {
                content = this.state.items.map(item => {
                    const Component = ITEM_COMPONENT_BY_TYPE[params.item_type];

                    return (
                        <Component
                            key={item.id}
                            data={item}
                            widget={this.props.widget}
                            parent={this.props.parent}
                        />
                    );
                })
            }
        }

        const titleVisible = this.props.widget.params.title && this.state.titleVisible;

        return (
            <div className={`
                ${style.listSimple}
                ${this.props.widget.rectState === 'leftDocked' ? style.leftDocked : ''}
            `}>
                <div className={style.title}>
                    <div
                        className={`
                            ${style.tContainer}
                            ${!titleVisible ? style.tNoTitle : ''}
                        `}
                    >
                        {titleVisible &&
                            <div className={style.caption}>
                                <div>{params.title}</div>
                            </div>
                        }

                        {params.button &&
                            <div
                                className={style.button}
                                onClick={() => this.props.widget.triggerEvent(params.button.event, params.button.params)}
                            >
                                {params.button.title}
                            </div>
                        }
                    </div>
                </div>

                <div className={style.content}>
                    <div className={style.cList}>
                        {content}
                    </div>

                    <Paginator
                        numRows={this.numRowsToFetch}
                        paginationInfo={this.state.paginationInfo}
                        onChange={(fetchStart) => {
                            this.fetchStart = fetchStart;
                            this.fetchData();
                        }}
                    />
                </div>
           </div>
        );
    }
}
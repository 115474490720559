import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import Currency from 'js/utils/currency.js'
import PhasesCollection from 'js/collections/phases.js'
import dateFormat from 'js/utils/date-format.js'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import filterRulesValueSelectTemplate from 'templates/groups/smart-rules-value-select.handlebars'
import filterRulesValueNumberTemplate from 'templates/groups/smart-rules-value-number.handlebars'
import filterRulesValueNumberBetweenTemplate from 'templates/groups/smart-rules-value-number-between.handlebars'
import filterRulesValueTextTemplate from 'templates/groups/smart-rules-value-text.handlebars'
import filterRulesValueDateTemplate from 'templates/groups/smart-rules-value-date.handlebars'
import filterRulesValueDateBetweenTemplate from 'templates/groups/smart-rules-value-date-between.handlebars'
import filterRulesValueDateDuringTemplate from 'templates/groups/smart-rules-value-date-during.handlebars'
import filterRulesValueDateRelativeTemplate from 'templates/groups/smart-rules-value-date-relative.handlebars'
import filterRulesValueDateRelativeEveryTemplate from 'templates/groups/smart-rules-value-date-relative-every.handlebars'
import filterRulesValueAutomation2Template from 'templates/groups/smart-rules-value-automation2.handlebars'


var TRIMMED_TASK_TEXT_MAX_LENGTH = 20;

var FilterRuleValueView = Marionette.Layout.extend({
    onRender: function() {
        this.listenTo(this.model, 'validate', function() {
            this.errorMessages_remove();
        });

        this.listenTo(this.model, 'invalid', this.invalid);
    },
    getValues: function() {
        return this.model.get('values');
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    isNumber: function(value) {
        return _.isNumber(value) && _.isFinite(value);
    },
    isDate: function(value) {
        return _.isDate(value) && !_.isNaN(value.getTime());
    },
    validate: function() {
        return true;
    },
    invalid: function() {},
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
    },
    errorMessages_hide: function() {
        this.ui.error_messages.hide();
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    }
});

var FilterRuleValueIndividualView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.title
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/individuals',
            search: true,
            text: 'title',
            value: values && {
                'id': values.id,
                'title': values.name
            },

            options: {
                placeholder: TextManager.getText('ID_SELECT_AN_INDIVIDUAL'),
                dropdownCssClass: 'individual-select-popover popover select2-drop-wider',
                formatResult: function(item, container) {
                    var html = item.title;

                    if (item.item.organization_name) {
                        html += '<div class="organization-name">' + item.item.organization_name + '</div>';
                        container.addClass('has-organization-name');
                    }

                    return Handlebars.compile(html);
                }
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueOrganizationView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.title
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/organizations',
            search: true,
            text: 'title',
            value: values && {
                'id': values.id,
                'title': values.name
            },

            options: {
                placeholder: TextManager.getText('ID_SELECT_AN_ORGANIZATION'),
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueOpportunityView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.title
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/opportunities',
            search: true,
            text: 'title',
            value: values && {
                'id': values.id,
                'title': values.name
            },

            options: {
                placeholder: TextManager.getText('ID_SELECT_A_DEAL'),
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueRelatedView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.title
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        var iconMap = {
            "individuals": "icon-user",
            "opportunities": "icon-filter",
            "organizations": "icon-home"
        };

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/v1/search',
            params: {
                types: 'individuals,organizations,opportunities',
                order_by: 'last_viewed desc'
            },
            search: true,
            text: 'title',
            value: values && {
                'id': values.id,
                'title': values.name
            },

            options: {
                placeholder: 'Select related',
                dropdownCssClass: 'popover select2-drop-wider',
                formatResult: function(item, container) {
                    return '<i class="' + iconMap[item.type] + '"></i> ' + item.title_highlight;
                }
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueTaskView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.text
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/tasks',
            search: true,
            text: 'text',
            value: values && {
                'id': values.id,
                'text': values.name
            },

            options: {
                placeholder: 'Select a task',
                dropdownCssClass: 'popover',
                formatResult: function(item) {
                    var html = item.text;
                    return Handlebars.compile(html);
                },
                formatSelection: function(item) {
                    var value = item.text;
                    if (value.length > TRIMMED_TASK_TEXT_MAX_LENGTH) {
                        value = value.substring(0, TRIMMED_TASK_TEXT_MAX_LENGTH) + ' ...';
                    }
                    return value;
                }
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueOpportunityBucketView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/buckets',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a bucket',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValuePhaseView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var item = this.select.$el.select2('data');
            var values = {
                id: item.id,
                name: (item.funnel ? item.funnel.name + ': ' : '') + item.name,
                funnel_id: item.funnel ? item.funnel.id : null
            };

            this.setValues(values);
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        var phases = new PhasesCollection();
        var self = this;

        phases.fetch({
            success: function() {
                var funnelPhases = phases.getWonLost().concat(phases.getAsHierarchy());

                self.select = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.input,
                    data: funnelPhases,
                    value: values ? {
                        id: values.id,
                        name: values.name,
                        funnel: values.funnel_id ? {
                            id: values.funnel_id
                        } : values.funnel
                    } : null,

                    getItemId: function(item) {
                        var value = item.id;
                        if (item.funnel) {
                            value += ':' + item.funnel.id;
                        }
                        return value;
                    },

                    formatSelection: function(item) {
                        if (item.funnel && item.funnel.name) {
                            return item.funnel.name + ': ' + item.name;
                        } else {
                            return item.name;
                        }
                    },

                    options: {
                        placeholder: TextManager.getText('ID_SELECT_A_PHASE'),
                        dropdownCssClass: 'period-select-popover popover'
                    }
                });
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueStatusView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.select.$el.select2('data'));
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        var statuses = [
            { id: 'none', value: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
            { id: 'none_upside', value: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
            { id: 'committed_downside', value: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
            { id: 'committed', value: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
        ];

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            data: statuses,
            text: 'value',
            value: values,
            options: {
                placeholder: 'Select a status',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.value)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.value);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueTaskTypeView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.select.$el.select2('data'));
        }
    },
    initialize: function() {
        this.taskTypes = AppConfig.getValue('quick_add_task.types_list', []);
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            data: this.taskTypes,
            text: 'title',
            value: values,
            options: {
                placeholder: 'Select a type',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        return this.taskTypes.find(tt => tt.id === values) || null;
    },
    setValues: function(values) {
        this.model.set('values', values?.id || null);
    },
    validate: function() {
        var values = this.model.get('values');
        return values === null;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueFunnelView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/funnels',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a funnel',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueFunnelRegionView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/regions',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a region',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueFunnelClusterView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/clusters',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: `Select a ${TextManager.getText('ID_CLUSTER')}`,
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValuePeriodView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/periods',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a period',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueGroupView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/groups?rows=-1&group_type=static',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a group',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueCampaignView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                campaign: {
                    id: data.id,
                    name: data.name
                }
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/campaigns?status=ready,draft,sent,scheduled&search_not=template',
            search: true,
            text: 'name',
            value: values && values.campaign && {
                'id': values.campaign.id,
                'name': values.campaign.name
            },

            options: {
                placeholder: TextManager.getText('ID_SELECT_A_CAMPAIGN'),
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.campaign && values.campaign.id && values.campaign.name)) {
            values = { campaign: null };
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        return false;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueUserView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.title
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/users',
            search: true,
            text: 'title',
            value: values && {
                'id': values.id,
                'title': values.name
            },

            options: {
                placeholder: 'Select a user',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueLeadsView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/lead_sources',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a lead source',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueTagsView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/tags',
            text: 'name',
            value: values && {
                'id': values.id,
                'name': values.name
            },

            options: {
                placeholder: 'Select a tag',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueNumberView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueNumberTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(parseFloat(this.ui.input.val(), 10));
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        this.ui.input.val(values);

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');

        if (_.isString(values)) {
            values = parseFloat(values, 10);
        }
        if (!this.isNumber(values)) {
            values = 0;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !this.isNumber(values);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.ui.input.addClass('validation_error');
        }
    }
});

var FilterRuleValueBetweeNumberView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueNumberBetweenTemplate),
    ui: {
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)'
    },
    events: {
        'change input': function() {
            this.setValues({
                'start': this.toNumber(this.ui.input1.val()),
                'end': this.toNumber(this.ui.input2.val())
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.ui.input1.val(values.start);
        this.ui.input2.val(values.end);

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');

        var start, end;
        if (_.isObject(values) && values.start && values.end) {
            start = values.start;
            end = values.end;
        }

        start = this.toNumber(start);
        end = this.toNumber(end);

        return {
            'start': start,
            'end': end
        };
    },
    toNumber: function(value) {
        if (_.isString(value)) {
            value = parseFloat(value, 10);
        }
        if (!this.isNumber(value)) {
            value = 0;
        }

        return value;
    },
    validate: function() {
        var errors = {};

        var values = this.model.get('values');

        if (_.isObject(values)) {
            if (!this.isNumber(values.start)) {
                errors.start = true;
            }
            if (!this.isNumber(values.end)) {
                errors.end = true;
            }
        } else {
            errors.start = true;
            errors.end = true;
        }

        return _.isEmpty(errors) ? false : errors;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            if (errors.values.start) {
                this.ui.input1.addClass('validation_error');
            }
            if (errors.values.end) {
                this.ui.input2.addClass('validation_error');
            }
        }
    }
});

var FilterRuleValueTextView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueTextTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.ui.input.val().trim());
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        this.ui.input.val(values);

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');

        if (this.isNumber(values)) {
            values = values.toString();
        }
        if (!_.isString(values)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isString(values) && values.trim().length > 0);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.ui.input.addClass('validation_error');
        }
    }
});

var FilterRuleValueDateView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueDateTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(dateFormat.ISODate(new Date(this.ui.input.val())));
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        // Create datepicker for adding notes
        this.datepicker = this.ui.input.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            dateFormat: 'M dd, yy', // TODO: i18n
            timezone: "+0000"
        });

        this.ui.input.datepicker('setDate', values);

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');

        if (_.isString(values)) {
            values = dateFormat.createDateIgnoringTZ(values);
        }
        if (!this.isDate(values)) {
            values = new Date();
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !this.isDate(new Date(values));
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.ui.input.addClass('validation_error');
        }
    }
});

var FilterRuleValueBetweenDateView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueDateBetweenTemplate),
    ui: {
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)',
        'every': 'input:eq(2)'
    },
    events: {
        'change input': function() {
            this.setDates(
                new Date(this.ui.input1.val()),
                new Date(this.ui.input2.val()),
                this.ui.every.val(),
            );
        }
    },
    everyData: [
        { id: 'never', name: 'date' },
        { id: 'year', name: 'annual anniversary' },
        { id: 'month', name: 'monthly anniversary' },
        { id: 'quarter', name: 'quarterly anniversary' }
    ],
    setDates: function(start, end, every) {
        this.setValues({
            start: dateFormat.ISODate(start),
            end: dateFormat.ISODate(end),
            every: every == 'never' ? null : every
        });
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        // Create datepicker for adding notes
        this.datepicker1 = this.ui.input1.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            dateFormat: 'M dd, yy', // TODO: i18n
            timezone: "+0000"
        });

        this.ui.input1.datepicker('setDate', new Date(values.start));

        // Create datepicker for adding notes
        this.datepicker2 = this.ui.input2.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            dateFormat: 'M dd, yy', // TODO: i18n
            timezone: "+0000"
        });

        this.ui.input2.datepicker('setDate', new Date(values.end));

        this.everySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.every,
            data: this.everyData,
            value: values.every || 'never',

            options: {
                placeholder: 'Select a period',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setDates(values.start, values.end, values.every);
    },
    getValues: function() {
        var values = this.model.get('values');

        var start, end, every;
        if (_.isObject(values) && values.start && values.end) {
            start = values.start;
            end = values.end;
            every = values.every;
        }

        start = this.toDate(start);
        end = this.toDate(end);

        return {
            'start': start,
            'end': end,
            'every': every
        };
    },
    toDate: function(value) {
        if (this.isDate(value)) {
            return value;
        } else if (_.isString(value)) {
            return dateFormat.createDateIgnoringTZ(value);
        } else {
            return new Date();
        }
    },
    validate: function() {
        var errors = {};

        var values = this.model.get('values');

        if (_.isObject(values)) {
            if (!this.isDate(new Date(values.start))) {
                errors.start = true;
            }
            if (!this.isDate(new Date(values.end))) {
                errors.end = true;
            }
        } else {
            errors.start = true;
            errors.end = true;
        }

        return _.isEmpty(errors) ? false : errors;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            if (errors.values.start) {
                this.ui.input1.addClass('validation_error');
            }
            if (errors.values.end) {
                this.ui.input2.addClass('validation_error');
            }
        }
    }
});

var FilterRuleValueRelativeDateEveryView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueDateRelativeEveryTemplate),
    ui: {
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)',
        'every': 'input:eq(2)'
    },
    events: {
        'change input': function() {
            var every = this.ui.every.val();

            this.setValues({
                'every': every == 'never' ? null : every,
                'value': parseInt(this.ui.input1.val(), 10),
                'unit': this.ui.input2.val()
            });
        }
    },
    data: [
        { id: 'h', name: 'hours' },
        { id: 'd', name: 'days' },
        { id: 'w', name: 'weeks' },
        { id: 'm', name: 'months' },
        { id: 'y', name: 'years' }
    ],
    everyData: [
        { id: 'never', name: 'date' },
        { id: 'year', name: 'annual anniversary' },
        { id: 'month', name: 'monthly anniversary' },
        { id: 'quarter', name: 'quarterly anniversary' }
    ],
    initialize: function() {
        if (!this.model.get('values')) {
            this.setValues({
                'every': null,
                'value': 0,
                'unit': 'h'
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.ui.input1.val(values.value);

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input2,
            data: this.data,
            value: values.unit,

            options: {
                placeholder: 'Select a unit',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.everySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.every,
            data: this.everyData,
            value: values.every || 'never',

            options: {
                placeholder: 'Select a period',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        return this.model.get('values');
    },
    validate: function() {
        var errors = {};

        var values = this.model.get('values');

        if (_.isObject(values)) {
            if (!this.isNumber(values.value)) {
                errors.value = true;
            }
            if (!_.find(this.data, function(item) {
                return item.id === values.unit;
            })) {
                errors.unit = true;
            }
        }

        return _.isEmpty(errors) ? false : errors;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            if (errors.values.value) {
                this.ui.input1.addClass('validation_error');
            }
            if (errors.values.unit) {
                this.select.$el.select2('container').addClass('validation_error');
            }
        }
    }
});

var FilterRuleValueRelativeDateView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueDateRelativeTemplate),
    ui: {
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)'
    },
    events: {
        'change input': function() {
            this.setValues({
                'value': parseInt(this.ui.input1.val(), 10),
                'unit': this.ui.input2.val()
            });
        }
    },
    data: [
        { id: 'h', name: 'hours' },
        { id: 'd', name: 'days' },
        { id: 'w', name: 'weeks' },
        { id: 'm', name: 'months' },
        { id: 'y', name: 'years' }
    ],
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.ui.input1.val(values && values.value || 0);

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input2,
            data: this.data,
            value: values && values.unit || 'h',

            options: {
                placeholder: 'Select a unit',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');

        if (!(_.isObject(values) && values.value && values.unit)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var errors = {};

        var values = this.model.get('values');

        if (_.isObject(values)) {
            if (!this.isNumber(values.value)) {
                errors.value = true;
            }
            if (!_.find(this.data, function(item) {
                return item.id === values.unit;
            })) {
                errors.unit = true;
            }
        }

        return _.isEmpty(errors) ? false : errors;
    },
    invalid: function(model, errors) {
        if (errors.values) {
            if (errors.values.value) {
                this.ui.input1.addClass('validation_error');
            }
            if (errors.values.unit) {
                this.select.$el.select2('container').addClass('validation_error');
            }
        }
    }
});

var FilterRuleValueDuringDateView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueDateDuringTemplate),
    ui: {
        'input': 'input:eq(0)',
        'every': 'input:eq(1)'
    },
    events: {
        'change input': function() {
            var every = this.ui.every.val();

            this.setValues({
                value: this.ui.input.val(),
                every: every === 'never' ? null : every
            });
        },
    },
    data: [
        {
            id: 'day',
            text: 'today'
        },
        {
            id: 'week',
            text: 'this week'
        },
        {
            id: 'month',
            text: 'this month'
        },
        {
            id: 'quarter',
            text: 'this quarter'
        },
        {
            id: 'year',
            text: 'this year'
        }
    ],
    everyData: [
        { id: 'never', name: 'date' },
        { id: 'year', name: 'annual anniversary' },
        { id: 'month', name: 'monthly anniversary' },
        { id: 'quarter', name: 'quarterly anniversary' }
    ],
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            text: 'text',
            data: this.data,
            value: values.value,

            options: {
                placeholder: 'Select a value',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.everySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.every,
            data: this.everyData,
            value: values.every || 'never',

            options: {
                placeholder: 'Select a period',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (_.isString(values)) {
            values = {
                value: values,
                every: null
            };
        } else if (_.isObject(values)) {
            values = {
                value: values.value,
                every: values.every
            };
        } else {
            values = {};
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !_.find(this.data, function(item) {
            return item.id === values.value;
        });
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueCheckboxView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.ui.input.val());
        }
    },
    data: null,
    initialize: function() {
        this.data = [
            {
                id: 'true',
                text: 'True'
            },
            {
                id: 'false',
                text: 'False'
            }
        ];
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var value = this.getValues();

        value = (value && _.find(this.data, function(item) {
            return item.id === value;
        }) || this.data[0]).id;

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            text: 'text',
            data: this.data,
            value: value,

            options: {
                placeholder: 'Select a value',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(value);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!_.isString(values)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !_.find(this.data, function(item) {
            return item.id === values;
        });
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueCurrencyView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.ui.input.val());
        }
    },
    data: null,
    initialize: function() {
        this.data = Currency.getUsedCurrenciesToSelect2Array();
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var value = this.getValues();

        value = (value && _.find(this.data, function(item) {
            return item.id === value;
        }) || this.data[0]).id;

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            text: 'text',
            data: this.data,
            value: value,

            options: {
                placeholder: 'Select a currency',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(value);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!_.isString(values)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !_.find(this.data, function(item) {
            return item.id === values;
        });
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueDropdownView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.ui.input.val());
        }
    },
    data: null,
    initialize: function() {
        this.data = this.options.value_def.options;
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var value = this.getValues();

        value = (value && _.find(this.data, function(item) {
            return item.id === value;
        }) || this.data[0]).id;

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            text: 'value',
            data: this.data,
            value: value,

            options: {
                placeholder: 'Select a value',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(value);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!_.isString(values)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !_.find(this.data, function(item) {
            return item.id === values;
        });
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueCommunicationsView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            this.setValues(this.ui.input.val());
        }
    },
    data: [
        { id: 'email' },
        { id: 'phone' }
    ],
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var value = this.getValues();

        value = (value && _.find(this.data, function(item) {
            return item.id === value;
        }) || this.data[0]).id;

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            text: 'id',
            data: this.data,
            value: value,

            options: {
                placeholder: 'Select a communication',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.setValues(value);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!_.isString(values)) {
            values = null;
        }

        return values;
    },
    validate: function() {
        var values = this.model.get('values');
        return !_.find(this.data, function(item) {
            return item.id === values;
        });
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueAutomation2View = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueAutomation2Template),
    ui: {
        'automation': '#automation',
        'step': '#step'
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        var automation = null;
        var step = null;

        if (values) {
            if (values.automation2) {
                automation = {
                    id: values.automation2.id,
                    name: values.automation2.name
                };
            }

            if (values.automation2_step) {
                step = {
                    id: values.automation2_step.id,
                    name: values.automation2_step.name
                }
            }
        }

        var self = this;
        var stepSelect;

        var populateSteps = function(automationId, stepSelected) {
            $.ajax({
                type: 'GET',
                url: '/automations2/' + automationId,
                contentType: 'application/json',
                dataType: 'json',
                success: function (data) {
                    stepSelect = new backboneSelect2.SelectView({
                        view: self,
                        $el: self.ui.step,
                        text: 'name',
                        data: data.steps,
                        value: stepSelected,
                        options: {
                            allowClear: true,
                            placeholder: 'Select a step',
                            containerCssClass: 'select2-block',
                            dropdownCssClass: 'automation-select-popover popover'
                        }
                    });

                    self.listenTo(stepSelect, 'change', function(step) {
                        var values = self.getValues();
                        if (step) {
                            self.setValues(_.extend(values, {
                                automation2_step: {
                                    id: step.id,
                                    name: step.name
                                }
                            }));
                        } else {
                            self.setValues(_.extend(values, {automation2_step: null}));
                        }
                    });
                }
            });
        }

        this.automationSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.automation,
            url: '/automations2',
            search: true,
            text: 'name',
            value: automation,
            options: {
                placeholder: 'Select an automation',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        stepSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.step,
            data: [],
            text: 'name',
            options: {
                placeholder: 'Select a step',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        if (automation) {
            populateSteps(automation.id, step);
        }

        this.listenTo(this.automationSelect, 'change', function(automation) {
            self.setValues({
                automation2: {
                    id: automation.id,
                    name: automation.name
                },
                automation2_step: null
            });
            populateSteps(automation.id, null);
        });
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.automation2 && values.automation2.id && values.automation2.name)) {
            values = {
                automation: null,
                step: null
            };
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.automation2 && values.automation2.id && values.automation2.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.automationSelect.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueChecklistView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueSelectTemplate),
    ui: {
        'input': 'input'
    },
    events: {
        'change input': function() {
            var data = this.select.$el.select2('data');
            this.setValues({
                'id': data.id,
                'name': data.name
            });
        }
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/checklists',
            params: {
                entity_types: this.entity_types,
            },
            search: true,
            text: 'name',
            value: values,
            options: {
                placeholder: 'Select a checklist',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        this.setValues(values);
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.id && values.name)) {
            values = null;
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.id && values.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.select.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueIndividualChecklistView = FilterRuleValueChecklistView.extend({
    entity_types: 'individuals',
});

var FilterRuleValueOrganizationChecklistView = FilterRuleValueChecklistView.extend({
    entity_types: 'organizations',
});

var FilterRuleValueOpportunityChecklistView = FilterRuleValueChecklistView.extend({
    entity_types: 'opportunities',
});

var FilterRuleValueChecklistItemView = FilterRuleValueView.extend({
    template: Handlebars.compile(filterRulesValueAutomation2Template),
    ui: {
        'checklist': '#automation',
        'checklist_item': '#step'
    },
    onRender: function() {
        FilterRuleValueView.prototype.onRender.apply(this, arguments);

        var values = this.getValues();
        var checklist = null;
        var checklist_item = null;

        if (values) {
            if (values.checklist) {
                checklist = {
                    id: values.checklist.id,
                    name: values.checklist.name
                };
            }

            if (values.checklist_item) {
                checklist_item = {
                    id: values.checklist_item.id,
                    name: values.checklist_item.name
                }
            }
        }

        var self = this;
        var checklistItemSelect;

        var populateItems = function(checklistId, checklistItemSelected) {
            $.ajax({
                type: 'GET',
                url: `/checklists/${checklistId}/items`,
                contentType: 'application/json',
                dataType: 'json',
                success: function (data) {
                    checklistItemSelect = new backboneSelect2.SelectView({
                        view: self,
                        $el: self.ui.checklist_item,
                        text: 'name',
                        data: data.map((e) => {
                            return {
                                id: e.id,
                                name: e.text,
                            }
                        }),
                        value: checklistItemSelected,
                        options: {
                            allowClear: true,
                            placeholder: 'Select a checklist item',
                            containerCssClass: 'select2-block',
                            dropdownCssClass: 'checklist-select-popover popover'
                        }
                    });

                    self.listenTo(checklistItemSelect, 'change', function(checklist_item) {
                        var values = self.getValues();
                        if (checklist_item) {
                            self.setValues(_.extend(values, {
                                checklist_item: {
                                    id: checklist_item.id,
                                    name: checklist_item.name
                                }
                            }));
                        } else {
                            self.setValues(_.extend(values, {checklist_item: null}));
                        }
                    });
                }
            });
        }

        this.checklistSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.checklist,
            url: '/checklists',
            params: {
                entity_types: this.entity_types,
            },
            search: true,
            text: 'name',
            value: checklist,
            options: {
                placeholder: 'Select an checklist',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        checklistItemSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.checklist_item,
            data: [],
            text: 'name',
            options: {
                placeholder: 'Select a checklist item',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });

        if (checklist) {
            populateItems(checklist.id, checklist_item);
        }

        this.listenTo(this.checklistSelect, 'change', function(checklist) {
            self.setValues({
                checklist: {
                    id: checklist.id,
                    name: checklist.name
                },
                checklist_item: null
            });
            populateItems(checklist.id, null);
        });
    },
    getValues: function() {
        var values = this.model.get('values');
        if (!(_.isObject(values) && values.checklist && values.checklist.id && values.checklist.name)) {
            values = {
                checklist: null,
                checklist_item: null
            };
        }

        return values;
    },
    setValues: function(values) {
        this.model.set('values', values);
    },
    validate: function() {
        var values = this.model.get('values');
        return !(_.isObject(values) && values.checklist && values.checklist.id && values.checklist.name);
    },
    invalid: function(model, errors) {
        if (errors.values) {
            this.checklistSelect.$el.select2('container').addClass('validation_error');
        }
    }
});

var FilterRuleValueIndividualChecklistItemView = FilterRuleValueChecklistItemView.extend({
    entity_types: 'individuals',
});

var FilterRuleValueOrganizationChecklistItemView = FilterRuleValueChecklistItemView.extend({
    entity_types: 'organizations',
});

var FilterRuleValueOpportunityChecklistItemView = FilterRuleValueChecklistItemView.extend({
    entity_types: 'opportunities',
});

var filter_operators_individual = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueIndividualView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_individual_custom = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueIndividualView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    },
    {
        'id': 'contains',
        'name': 'contains',
        'view': FilterRuleValueIndividualView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_organization = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOrganizationView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_organization_custom = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOrganizationView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    },
    {
        'id': 'contains',
        'name': 'contains',
        'view': FilterRuleValueOrganizationView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_opportunity = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOpportunityView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_opportunity_custom = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOpportunityView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    },
    {
        'id': 'contains',
        'name': 'contains',
        'view': FilterRuleValueOpportunityView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_related = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueRelatedView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_task = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueTaskView,
        'text': function(values) {
            var value = values.name;
            if (value.length > TRIMMED_TASK_TEXT_MAX_LENGTH) {
                value = value.substring(0, TRIMMED_TASK_TEXT_MAX_LENGTH) + ' ...';
            }
            return value;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_opportunity_bucket = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOpportunityBucketView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_phase = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValuePhaseView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_status = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueStatusView,
        'text': function(values) {
            return values.value;
        }
    }
];

var filter_operators_funnel = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueFunnelView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_regions = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueFunnelRegionView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];


var filter_operators_funnel_region = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueFunnelRegionView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_funnel_cluster = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueFunnelClusterView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_period = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValuePeriodView,
        'text': function(values) {
            return values.name;
        }
    }
];

var filter_operators_task_type = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueTaskTypeView,
        'text': function(values) {
            const taskTypes = AppConfig.getValue('quick_add_task.types_list', []);
            return taskTypes.find(tt => tt.id === values)?.title || '';
        }
    }
];

var filter_operators_group = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueGroupView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_user = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueUserView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_lead_source = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueLeadsView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_tag = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueTagsView,
        'text': function(values) {
            return values.name;
        }
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];


var filter_operators_checkbox = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueCheckboxView
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_currency = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueCurrencyView
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_dropdown = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueDropdownView
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    },
    {
        'id': 'contains',
        'name': 'contains',
        'view': FilterRuleValueDropdownView
    }
];

var filter_operators_communication = [
    {
        'id': 'equal',
        'name': 'includes',
        'view': FilterRuleValueCommunicationsView
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_string = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueTextView
    },
    {
        'id': 'contains',
        'name': 'contains',
        'view': FilterRuleValueTextView
    },
    {
        'id': 'starts_with',
        'name': 'begins with',
        'view': FilterRuleValueTextView
    },
    {
        'id': 'ends_with',
        'name': 'ends with',
        'view': FilterRuleValueTextView
    },
    {
        'id': 'empty',
        'name': 'is empty',
        'view': false
    }
];

var filter_operators_number = [
    {
        'id': 'equal',
        'name': 'equals',
        'view': FilterRuleValueNumberView,
        'prefix': 'is'
    },
    {
        'id': 'less_than',
        'name': 'less than',
        'view': FilterRuleValueNumberView,
        'prefix': 'is'
    },
    {
        'id': 'less_than_equal',
        'name': 'less than or equal to',
        'view': FilterRuleValueNumberView,
        'prefix': 'is'
    },
    {
        'id': 'more_than',
        'name': 'more than',
        'view': FilterRuleValueNumberView,
        'prefix': 'is'
    },
    {
        'id': 'more_than_equal',
        'name': 'more than or equal to',
        'view': FilterRuleValueNumberView,
        'prefix': 'is'
    },
    {
        'id': 'between',
        'name': 'between',
        'view': FilterRuleValueBetweeNumberView,
        'text': function(values) {
            return [
                values.start,
                'and',
                values.end
            ].join(' ');
        },
        'prefix': 'is'
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operators_date = [
    {
        'id': 'equal',
        'name': 'exactly',
        'view': FilterRuleValueDateView,
        'text': function(value) {
            return dateFormat.shortFormatWithYearUTC(value);
        },
        'prefix': 'is'
    },
    {
        'id': 'less_than',
        'name': 'before',
        'view': FilterRuleValueDateView,
        'text': function(value) {
            return dateFormat.shortFormatWithYearUTC(value);
        },
        'prefix': 'is'
    },
    {
        'id': 'more_than',
        'name': 'after',
        'view': FilterRuleValueDateView,
        'text': function(value) {
            return dateFormat.shortFormatWithYearUTC(value);
        },
        'prefix': 'is'
    },
    {
        'id': 'between',
        'name': 'between',
        'view': FilterRuleValueBetweenDateView,
        'text': function(values) {
            return [
                dateFormat.shortFormatWithYearUTC(values.start),
                'and',
                dateFormat.shortFormatWithYearUTC(values.end)
            ].join(' ');
        },
        'postFieldText': function(values) {
            if (values.every) {
                return 'or ' + {
                    year: 'annual anniversary',
                    month: 'monthly anniversary',
                    quarter: 'quarterly anniversary'
                }[values.every];
            }
            return '';
        },
        'prefix': 'is',
        'higherControls': true
    },
    {
        'id': 'within_last',
        'name': 'within last',
        'view': FilterRuleValueRelativeDateEveryView,
        'postFieldText': function(values) {
            if (values.every) {
                return 'or ' + {
                    year: 'annual anniversary',
                    month: 'monthly anniversary',
                    quarter: 'quarterly anniversary'
                }[values.every];
            }
            return '';
        },
        'text': function(values) {
            var value = values.value;
            var unit = {
                h: 'hours',
                d: 'days',
                w: 'weeks',
                m: 'months',
                y: 'years'
            }[values.unit];

            return [
                value,
                unit
            ].join(' ');
        },
        'prefix': 'is',
        'higherControls': true
    },
    {
        'id': 'within_next',
        'name': 'within next',
        'view': FilterRuleValueRelativeDateEveryView,
        'postFieldText': function(values) {
            if (values.every) {
                return 'or ' + {
                    year: 'annual anniversary',
                    month: 'monthly anniversary',
                    quarter: 'quarterly anniversary'
                }[values.every];
            }
            return '';
        },
        'text': function(values) {
            var value = values.value;
            var unit = {
                h: 'hours',
                d: 'days',
                w: 'weeks',
                m: 'months',
                y: 'years'
            }[values.unit];

            return [
                value,
                unit
            ].join(' ');
        },
        'prefix': 'is',
        'higherControls': true
    },
    {
        'id': 'during',
        'name': 'during',
        'view': FilterRuleValueDuringDateView,
        'text': function(values) {
            return {
                'day': 'today',
                'week': 'this week',
                'month': 'this month',
                'quarter': 'this quarter',
                'year': 'this year'
            }[values.value];
        },
        'postFieldText': function(values) {
            if (values.every) {
                return 'on ' + {
                    year: 'annual anniversary',
                    month: 'monthly anniversary',
                    quarter: 'quarterly anniversary'
                }[values.every];
            }
            return '';
        },
        'higherControls': true
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operator_campaign = [
    {
        'id': 'any',
        'name': 'in',
        'view': FilterRuleValueCampaignView,
        'text': function(values) {
            return values && values.campaign && values.campaign.name;
        }
    }
];

var filter_operators_automation2 = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueAutomation2View,
        'text': function(values) {
            if (values.automation2_step) {
                return `${values.automation2.name} / ${values.automation2_step.name}`;
            }

            return `${values.automation2.name}`;
        }
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operators_individual_checklist = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueIndividualChecklistView,
        'text': function(values) {
            return `${values.name}`;
        }
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operators_individual_checklist_item = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueIndividualChecklistItemView,
        'text': function(values) {
            return `${values.checklist.name} ${values.checklist_item.name}`;
        }
    }
];

var filter_operators_organization_checklist = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOrganizationChecklistView,
        'text': function(values) {
            return `${values.name}`;
        }
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operators_organization_checklist_item = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOrganizationChecklistItemView,
        'text': function(values) {
            return `${values.checklist.name} ${values.checklist_item.name}`;
        }
    }
];

var filter_operators_opportunity_checklist = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOpportunityChecklistView,
        'text': function(values) {
            return `${values.name}`;
        }
    },
    {
        'id': 'empty',
        'name': 'empty',
        'view': false,
        'prefix': 'is'
    }
];

var filter_operators_opportunity_checklist_item = [
    {
        'id': 'equal',
        'name': 'is',
        'view': FilterRuleValueOpportunityChecklistItemView,
        'text': function(values) {
            return `${values.checklist.name} ${values.checklist_item.name}`;
        }
    }
];

export default {
    string: filter_operators_string,
    number: filter_operators_number,
    date: filter_operators_date,

    user: filter_operators_user,
    individual: filter_operators_individual,
    organization: filter_operators_organization,
    opportunity: filter_operators_opportunity,
    related: filter_operators_related,
    task: filter_operators_task,
    group: filter_operators_group,
    communication: filter_operators_communication,
    lead_source: filter_operators_lead_source,
    tag: filter_operators_tag,
    phase: filter_operators_phase,
    status: filter_operators_status,
    funnel: filter_operators_funnel,
    region: filter_operators_regions,
    funnel_region: filter_operators_funnel_region,
    funnel_cluster: filter_operators_funnel_cluster,
    opportunity_bucket: filter_operators_opportunity_bucket,
    campaign: filter_operator_campaign,
    period: filter_operators_period,
    automation2: filter_operators_automation2,
    individual_checklist: filter_operators_individual_checklist,
    organization_checklist: filter_operators_organization_checklist,
    opportunity_checklist: filter_operators_opportunity_checklist,
    individual_checklist_item: filter_operators_individual_checklist_item,
    organization_checklist_item: filter_operators_organization_checklist_item,
    opportunity_checklist_item: filter_operators_opportunity_checklist_item,
    task_type: filter_operators_task_type,

    custom: {
        checkbox: filter_operators_checkbox,
        currency: filter_operators_currency,
        date: filter_operators_date,
        dropDown: filter_operators_dropdown,
        number: filter_operators_number,
        paragraph: filter_operators_string,
        text: filter_operators_string,
        url: filter_operators_string,
        urlImage: filter_operators_string,
        individual: filter_operators_individual_custom,
        organization: filter_operators_organization_custom,
        opportunity_bucket: filter_operators_opportunity_bucket,
        opportunity: filter_operators_opportunity_custom,
        phase: filter_operators_phase,
        funnel: filter_operators_funnel,
        region: filter_operators_regions,
        funnel_region: filter_operators_funnel_region,
        funnel_cluster: filter_operators_funnel_region,
        user: filter_operators_user,
        product: filter_operators_number
    }
};

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import vent from 'js/vent'
import TextManager from 'app/text-manager'
import MessageBox from 'js/views/message_box'
import CampaignModel from 'js/models/campaign'
import ModalRegion from 'js/views/base/modal-region'
import NoCollectionView from 'js/views/base/no_collection'
import CampaignCreationView from 'app/content/campaigns/_components/campaign-creation/campaign-creation'
import DraftCampaignListingView from 'app/_components/IOD-listing/IOD/campaign-listing/draft-campaign-listing'
import ScheduledCampaignListingView from 'app/_components/IOD-listing/IOD/campaign-listing/scheduled-campaign-listing'
import SentCampaignListingView from 'app/_components/IOD-listing/IOD/campaign-listing/sent-campaign-listing'
import TemplatedCampaignListingView from 'app/_components/IOD-listing/IOD/campaign-listing/templated-emails-campaign-listing'
import AutomatedCampaignListingView from 'app/_components/IOD-listing/IOD/campaign-listing/automated-campaign-listing'
import NewGroup from 'js/react_views/new-group/new-group'
import GroupsClubbing from 'js/views/groups_clubbing'
import ItemPermissionsView from 'js/views/item_permissions';
import GroupsCollection from 'js/collections/groups'
import FilterableCollection from 'js/utils/filterable_collection'
import GroupModel from 'js/models/group'
import campaignsTemplate from 'app/campaigns/campaigns.handlebars'
import groupItemTemplate from 'app/campaigns/group_item.handlebars'
import unverifiedDomainTemplate from 'templates/content/unverified_domain.handlebars'
import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';

var UnverifiedDomainView = Marionette.Layout.extend({
    template: Handlebars.compile(unverifiedDomainTemplate),
    className: 'unverified-container background',
    getUrl: function() {
        return this.options.viewType;
    }
});

var GroupItem = Marionette.ItemView.extend({
    className: 'item',
    template: Handlebars.compile(groupItemTemplate),
    ui: {
        tagsContainer: '.tags-container',
    },
    templateHelpers: function() {
        const owner = this.model.get('owner');

        return {
            is_owner: owner && owner.id === app.user.get('id'),
            is_smart: this.model.get('group_type') === 'smart'
        };
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            this.parent.options.parent.showGroup(this.parent.options.section, this.model);
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.parent.clubbing.initItem(this);

        ReactDOM.render(
            <TagListSidebar
                tags={this.model.get('tags')}
            />,
            this.ui.tagsContainer.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
    }
});

var GroupsList = Marionette.CompositeView.extend({
    template: Handlebars.compile('<div/>'),
    getItemView: function(model) {
        if (model && model.isFolder()) {
            return this.clubbing.SidebarFolderView;
        }

        return GroupItem;
    },
    itemViewOptions: function(model) {
        return {
            model: model,
            parent: this,
            clubbing: this.clubbing,
            section: this.options.section
        };
    },
    initialize: function() {
        this.clubbing = this.options.clubbing;
    }
});

export default Marionette.Layout.extend({
    tagName: 'article',
    className: 'campaigns-section',
    template: Handlebars.compile(campaignsTemplate),
    regions: {
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        mainRegion: '#campaigns-view-pane',
        campaignRegion: {
            selector: '#campaign-creation', // selector it self not used in ModalRegion
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false }) // statics disables native closing of modal
        },
        draftsGroupsContainer: '.drafts-group-list-container',
        scheduledGroupsContainer: '.scheduled-group-list-container',
        sentGroupsContainer: '.sent-group-list-container'
    },
    ui: {
        sidebar: '#campaigns-sidebar',
        newGroupContainer: '#new-group-view-pane',
        draftsToggle: '.toggle-drafts',
        scheduledToggle: '.toggle-scheduled',
        sentToggle: '.toggle-sent',
        regularHeader: '.list-header-nav',
        searchHeader: '.list-header-nav-search',
        searchInput: '.search-input'
    },
    events: {
        'click #campaigns-sidebar .collapse': function(ev) {
            var self = this;

            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            this.$el.one(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                function() {
                    _.defer(function() {
                        vent.trigger('sidebar:collapse');
                    });
                }
            );

            this.ui.sidebar.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');
                self.$el.one(
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                    function() {
                        _.defer(function() {
                            vent.trigger('sidebar:collapse');
                        });
                    }
                );
            });
        },
        'click .toggle-container': function(ev) {
            this.toggleGroupsContainer($(ev.target));
        },
        'click .add-new-group': function(ev) {
            this.newGroup();
        },
        'click .all-drafts': function() {
            this.showGroup('drafts', 'all');
        },
        'click .all-scheduled': function() {
            this.showGroup('scheduled', 'all');
        },
        'click .all-sent': function() {
            this.showGroup('sent', 'all');
        },
        'click .templated-sent': function() {
            this.showGroup('sent', 'templated');
        },
        'click .automated-sent': function() {
            this.showGroup('sent', 'automated');
        },
        'click .search': function() {
            this.ui.regularHeader.addClass('hidden');
            this.ui.searchHeader.removeClass('hidden');
            this.ui.searchInput.val('');
            this.ui.searchInput.focus();
        },
        'click .cancel-search': function() {
            this.ui.regularHeader.removeClass('hidden');
            this.ui.searchHeader.addClass('hidden');
            this.filterableCollectionDrafts.resetFilter();
            this.filterableCollectionScheduled.resetFilter();
            this.filterableCollectionSent.resetFilter();
        },
        'keydown .search-input': function(ev) {
            const self = this;

            _.defer(function() {
                self.filterableCollectionDrafts.filter(ev.target.value);
                self.filterableCollectionScheduled.filter(ev.target.value);
                self.filterableCollectionSent.filter(ev.target.value);
            });
        }
    },
    initialize: function(options) {
        this.filterId = options.filterId;

        this.groupsCollectionDrafts = new GroupsCollection();
        this.groupsCollectionScheduled = new GroupsCollection();
        this.groupsCollectionSent = new GroupsCollection();

        this.clubbingDrafts = this.createClubbing('Drafts');
        this.clubbingScheduled = this.createClubbing('Scheduled');
        this.clubbingSent = this.createClubbing('Sent');

        this.activeGroup = {};
        this.sectionsOpened = {};

        this.fetchGroups();
    },
    onRender: function() {
        if (this.options.new) {
            this.newCampaign(this.options.populateFrom, this.options.onCancelGoto);
        }

        if (this.activeGroup.section) {
            const self = this;

            _.defer(function() {
                self.showGroup(self.activeGroup.section, self.activeGroup.group);
            });
        }

        this.draftsGroupsContainer.show(new GroupsList({
            parent: this,
            collection: this.groupsCollectionDrafts,
            clubbing: this.clubbingDrafts,
            section: 'drafts'
        }));

        if (this.sectionsOpened['drafts']) {
            this.toggleGroupsContainer(this.ui.draftsToggle, true);
        }

        this.scheduledGroupsContainer.show(new GroupsList({
            parent: this,
            collection: this.groupsCollectionScheduled,
            clubbing: this.clubbingScheduled,
            section: 'scheduled'
        }));

        if (this.sectionsOpened['scheduled']) {
            this.toggleGroupsContainer(this.ui.scheduledToggle, true);
        }

        this.sentGroupsContainer.show(new GroupsList({
            parent: this,
            collection: this.groupsCollectionSent,
            clubbing: this.clubbingSent,
            section: 'sent'
        }));

        if (this.sectionsOpened['sent']) {
            this.toggleGroupsContainer(this.ui.sentToggle, true);
        }

        this.filterableCollectionDrafts = new FilterableCollection(this.draftsGroupsContainer.currentView, 'groupsCollectionDrafts');
        this.filterableCollectionScheduled = new FilterableCollection(this.scheduledGroupsContainer.currentView, 'groupsCollectionScheduled');
        this.filterableCollectionSent = new FilterableCollection(this.sentGroupsContainer.currentView, 'groupsCollectionSent');
    },
    newCampaign: function(populateFrom, onCancelGoto) {
        this.campaignRegion.show(new CampaignCreationView({populateFrom: populateFrom, onCancelGoto: onCancelGoto}));
        vent.trigger('AppContent:contentChange');
    },
    closeNewGroupView: function() {
        this.ui.newGroupContainer.toggleClass('hidden', true);
        ReactDOM.unmountComponentAtNode(this.ui.newGroupContainer.get(0));
    },
    showGroupPermissions: function() {
        if (this.aclRegion && this.activeGroup.group) {
            var ipv = new ItemPermissionsView({model: this.activeGroup.group});
            this.aclRegion.show(ipv);
            this.listenTo(ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    deleteGroup: function() {
        const preferences = app.user.get('preferences');
        const hiddenNotes = preferences['hidden_notes'] || {};
        let note = '';
        let initMessageActions = null;

        if (!('delete-group' in hiddenNotes)) {
            note = '<div class="note-container">' + TextManager.getText('ID_DELETE_GROUP_WARNING_MESSAGE_NO_LINK', ['${ID_CAMPAIGN, plural}']) +
                   '<div class="hide-note">click here if you don\'t want to see this message again</div></div>';

            initMessageActions = function(container) {
                container.find('.hide-note').click(function(){
                    hiddenNotes['delete-group'] = true;
                    app.user.updatePreference('hidden_notes', hiddenNotes);
                    container.find('.note-container').hide();
                });
            }
        }

        const mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?{{{note}}}')({name: this.activeGroup.group.get('name'), note: note}),
            initMessageActions: initMessageActions,
            icon: 'icon-trashcan'
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this, function() {
            self.activeGroup.group.destroy({
                success: function() {
                    self.activeGroup = {};
                    self.fetchGroups('all');
                }
            });
        });
    },
    handleNewGroup: function(group) {
        this.closeNewGroupView();
        this.fetchGroups(group.get('id'));
    },
    handleGroupDelete: function() {
        this.closeNewGroupView();
        this.deleteGroup();
    },
    newGroup: function() {
        this.ui.newGroupContainer.toggleClass('hidden', false);

        ReactDOM.render(
            <NewGroup
                elementType='campaigns'
                onCancel={this.closeNewGroupView.bind(this)}
                onSave={this.handleNewGroup.bind(this)}
            />,
            this.ui.newGroupContainer.get(0)
        );
    },
    editGroup: function() {
        this.ui.newGroupContainer.toggleClass('hidden', false);

        ReactDOM.render(
            <NewGroup
                model={this.activeGroup.group.toJSON()}
                elementType='campaigns'
                groupType='static'
                onCancel={this.closeNewGroupView.bind(this)}
                onSave={this.handleNewGroup.bind(this)}
                onDelete={this.handleGroupDelete.bind(this)}
                onPermissions={this.showGroupPermissions.bind(this)}
            />,
            this.ui.newGroupContainer.get(0)
        );
    },
    showGroup: function(section, group) {
        var isSpecialGroup = group && !_.isObject(group);
        var name = '';

        if (isSpecialGroup) {
            if (group === 'all') {
                name = section.charAt(0).toUpperCase() + section.slice(1);
            }
        } else {
            name = group.get('name');
        }

        var options = {
            elementType: 'campaigns',
            name: name,
            group: isSpecialGroup ? null : group,
            filterId: this.filterId,
            isGroup: !isSpecialGroup,
            tags: isSpecialGroup ? [] : group.get('tags')
        };

        this.$el.find('.active').removeClass('active');
        this.toggleGroupsContainer(this.ui[`${section}Toggle`], true);

        var highlightedItem = null;

        if (isSpecialGroup) {
            switch(group) {
                case 'all':
                    highlightedItem = this.$el.find(`#all-${section}`);
                    break;
                case 'templated':
                    highlightedItem = this.$el.find('#all-templated');
                    break;
                case 'automated':
                    highlightedItem = this.$el.find('#all-automated');
                    break;
            }
        } else {
            highlightedItem = this[`${section}GroupsContainer`].currentView.$el.find(`#${group.get('id')}.item`);
        }

        if (highlightedItem) {
            highlightedItem.addClass('active');
        }

        this.filterableCollectionDrafts.findActiveModel();
        this.filterableCollectionScheduled.findActiveModel();
        this.filterableCollectionSent.findActiveModel();

        switch(section) {
            case 'drafts':
                this.showContent(new DraftCampaignListingView(options));
                break;

            case 'scheduled':
                this.showContent(new ScheduledCampaignListingView(options));
                break;

            case 'sent':
                if (isSpecialGroup) {
                    switch(group) {
                        case 'all':
                            this.showContent(new SentCampaignListingView(options));
                            break;
                        case 'automated':
                            this.showContent(new AutomatedCampaignListingView(options));
                            break;
                        case 'templated':
                            this.showContent(new TemplatedCampaignListingView(options));
                            break;
                    }
                } else {
                    this.showContent(new SentCampaignListingView(options));
                    break;
                }
                break;
        }

        this.activeGroup = {
            section: section,
            group: group
        };

        vent.trigger('AppContent:contentChange');
    },
    toggleGroupsContainer: function(container, opened) {
        var arrow = container.find('.toggle');
        var containerId = container.attr('id');
        var list = container.parent().find(`.groups-list-${containerId}`);

        if (arrow.hasClass('icon-caret-right') || opened) {
            if (list.hasClass('hidden')) {
                arrow.removeClass('icon-caret-right').addClass('icon-caret-down');
                list.toggleClass('hidden', false);
            }
            this.sectionsOpened[containerId] = true;
        }
        else if (!list.hasClass('hidden')) {
            arrow.removeClass('icon-caret-down').addClass('icon-caret-right');
            list.toggleClass('hidden', true);
            this.sectionsOpened[containerId] = false;
        }
    },
    createClubbing: function(type) {
        var self = this;

        var onClubChange = function(render, save, noPopulateChanges) {
            var clubbing = self[`clubbing${type}`];
            var campaignGroupsPrefs = clubbing.encodeCollection();

            if (!noPopulateChanges) {
                self.populatingClubbingChanges = true;

                var newGroupsCollection = new GroupsCollection(self.groupsCollection.toJSON());

                for (var t of ['Drafts', 'Scheduled', 'Sent']) {
                    if (t === type) {
                        continue;
                    }

                    self[`groupsCollection${t}`].reset(self[`clubbing${t}`].manageCollection(newGroupsCollection, campaignGroupsPrefs).models);
                }

                self.populatingClubbingChanges = false;
            }

            if (render) {
                // save and restore the scroll position after render
                var scrollPosition = self.$el.find('.sub-section-nav').scrollTop();
                self[`groupsCollection${type}`] = _.clone(clubbing.options.rootCollection);
                self[`filterableCollection${type}`].setInitialCollection(self[`groupsCollection${type}`]);
                self.render();
                self.$el.find('.sub-section-nav').scrollTop(scrollPosition);
                self.$el.find('.groups-container').nanoScroller();
            }

            if (save) {
                app.user.updatePreference('campaigns_groups', campaignGroupsPrefs);
            }
        };

        return new GroupsClubbing({
            view: this,
            itemView: GroupItem,
            elementType: 'campaigns',
            autoActivateDisabled: true,
            onItemMoved: function() {
                onClubChange(true, true);
            },
            onFolderCreated: function() {
                onClubChange(true, false, true);
            },
            onItemInsertedToFolder: function() {
                onClubChange(true, true);
            },
            onFolderNameChanged: function() {
                onClubChange(false, true);
            },
            onFolderStateChanged: function() {
                self.$el.find('.groups-container').nanoScroller();

                if (!self.populatingClubbingChanges) {
                    onClubChange(false, true);
                }
            }
        });
    },
    fetchGroups: function(groupIdToShow) {
        this.groupsCollection = new GroupsCollection();
        const self = this;

        this.groupsCollection.fetch({
            rows: -1,
            data: {
                element_type: 'campaigns'
            },
            success: function(data) {
                const newGroupsCollection = new GroupsCollection(data.toJSON());
                const campaignGroupsPrefs = app.user.get('preferences').campaigns_groups;

                self.groupsCollectionDrafts.reset(self.clubbingDrafts.manageCollection(newGroupsCollection, campaignGroupsPrefs).models);
                self.groupsCollectionScheduled.reset(self.clubbingScheduled.manageCollection(newGroupsCollection, campaignGroupsPrefs).models);
                self.groupsCollectionSent.reset(self.clubbingSent.manageCollection(newGroupsCollection, campaignGroupsPrefs).models);

                self.filterableCollectionDrafts.setInitialCollection(self.groupsCollectionDrafts);
                self.filterableCollectionScheduled.setInitialCollection(self.groupsCollectionScheduled);
                self.filterableCollectionSent.setInitialCollection(self.groupsCollectionSent);

                if (!groupIdToShow) {
                    const groupToShow = data.get(self.options.groupId);

                    if (self.options.groupId && !groupToShow) {
                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: 'You do not have permissions to view this group'
                        }, self);

                        self.showGroup('drafts', 'all');
                    } else {
                        switch(self.options.type) {
                            case 'scheduled':
                                self.showGroup('scheduled', groupToShow || 'all');
                                break;

                            case 'sent':
                                self.showGroup('sent', groupToShow || 'all');
                                break;

                            case 'automated':
                                self.showGroup('sent', 'automated');
                                break;

                            case 'templated':
                                self.showGroup('sent', 'templated');
                                break;

                            default:
                                self.showGroup('drafts', groupToShow || 'all');
                                break;
                        }
                    }

                    if (self.options.id) {
                        _.defer(function() {
                            if (self.options.preview) {
                                self.mainRegion.currentView.showItem(new CampaignModel({id: self.options.id}), null, true);
                            }
                            else {
                                self.mainRegion.currentView.showItem(new CampaignModel({id: self.options.id}));
                            }
                        });
                    }
                } else {
                    let groupToShow = null;

                    switch (self.activeGroup.section) {
                        case 'scheduled':
                            groupToShow = self.groupsCollectionScheduled.models.find(m => m.get('id') === groupIdToShow);
                            break;

                        case 'sent':
                            groupToShow = self.groupsCollectionSent.models.find(m => m.get('id') === groupIdToShow);
                            break;

                        default:
                            groupToShow = self.groupsCollectionDrafts.models.find(m => m.get('id') === groupIdToShow);
                            break;
                    }

                    self.showGroup(self.activeGroup.section || 'drafts', groupToShow);
                }

                self.filterId = null;
            }
        });
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['campaigns-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.ui.sidebar?.width(sidebarWidth);
                self.ui.newGroupContainer?.css('left', `${sidebarWidth}px`);
                self.mainRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    showContent: function(view) {
        var verifiedDomain = app.user.get('client').email_sending_domains.length !== 0;
        if (verifiedDomain) {
            this.closeNewGroupView();
            this.mainRegion.show(view);

            var self = this;

            this.listenTo(view, 'group:new-item', function() {
                self.newCampaign();
            });

            this.listenTo(view, 'group:add-item', function(model) {
                $.ajax({
                    url: `/groups/${self.activeGroup.group.id}/items/${model.get('id')}`,
                    method: 'PUT',
                    success: function() {
                        self.showGroup(self.activeGroup.section, self.activeGroup.group);
                    }
                });
            });

            this.listenTo(view, 'group:edit', this.editGroup);
            this.listenTo(view, 'group:delete', this.deleteGroup);
            this.listenTo(view, 'group:permissions', this.showGroupPermissions);

            this.listenTo(vent, 'group:save', function(groupModel) {
                this.fetchGroups(groupModel.get('id'));
            });
        }
        else {
            this.mainRegion.show(new UnverifiedDomainView({viewType: this.options.type || ''}));
            this.$el.find('li').removeClass('active');
            this.$el.find('li.sent-mail').addClass('active');
            this.ui.sidebar.addClass('disabled');
        }

        this.initSidebarAndContentWidth();
    },
    getUrl: function() {
        var url = 'campaigns';

        if (this.mainRegion && this.mainRegion.currentView) {
            url += '/' + this.mainRegion.currentView.getUrl();
        }
        if (this.campaignRegion && this.campaignRegion.currentView) {
            url += '/' + this.campaignRegion.currentView.getUrl();
        }
        return url;
    },
    getParams: function() {
        if (this.mainRegion && this.mainRegion.currentView && this.mainRegion.currentView.getParams) {
            return this.mainRegion.currentView.getParams();
        }

        return {};
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.newGroupContainer.get(0));
    }
});

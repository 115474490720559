import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import PeriodModel from 'js/models/period.js'
import PeriodsCollection from 'js/collections/periods.js'
import dateFormat from 'js/utils/date-format.js'
import MessageBox from 'js/views/message_box'
import reportingPeriodTemplate from 'templates/settings/reporting_periods.handlebars'
import reportingPeriodListItemViewTemplate from 'templates/settings/reporting_periods_list_item_view.handlebars'


var ListView, reportingPeriodItemView;

reportingPeriodItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(reportingPeriodListItemViewTemplate),
    ui: {
        startDate: '.start-date',
        editStartDate: '.edit-start-date',
        endDate: '.end-date',
        editEndDate: '.edit-end-date'
    },
    templateHelpers: function() {
        var start_date = new Date(this.model.get('start_date'));
        var end_date = new Date(this.model.get('end_date'));

        // convert to UTC
        var start_date = new Date(start_date.getUTCFullYear(), start_date.getUTCMonth(), start_date.getUTCDate());
        var end_date = new Date(end_date.getUTCFullYear(), end_date.getUTCMonth(), end_date.getUTCDate() - 1);

        return {
            start_date: start_date,
            end_date: end_date
        };
    },
    onRender: function() {
        this.ui.editStartDate.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            dateFormat: 'M d, yy'
        });
        this.activateElement(this.ui.startDate, this.ui.editStartDate, {field: 'start_date'});
        this.ui.editEndDate.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            dateFormat: 'M d, yy'
        });
        this.activateElement(this.ui.endDate, this.ui.editEndDate, {field: 'end_date'});
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param text        jquery text field
     * @param edit        jquery input field
     * @param dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus(); // Shows datepicker
        });

        edit.on('change', function() {
            var val = edit.val();
            var model = self.model;

            var onItemUpdated = function() {
                model.trigger('itemUpdated');
            };

            var newDate = new Date(val);

            if (dbField.field === 'end_date') {
                newDate.setDate(newDate.getDate()+1);
            }

            var values = {};
            values[dbField.field] = dateFormat.ISODate(newDate);

            model.save(values, {
                alert: false,
                patch: true,
                success: onItemUpdated,
                error: onItemUpdated
            });
        });

        edit.datepicker('option', 'onClose', hideEdit);
    }
});

ListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'reporting-periods-list editable-list',
    itemView: reportingPeriodItemView,
    itemViewOptions: function() {
        return this.options;
    },
    /**
     * this supports putting itemView in correct position with collection.add(model, { at: index })
     * No idea why this isn't already built in functionality.
     */
    appendHtml: function(collectionView, itemView, index) {
        var childAtIndex = collectionView.$el.children().eq(index);
        if (childAtIndex.length) {
            return childAtIndex.before(itemView.el);
        }
        else {
            return collectionView.$el.append(itemView.el);
        }
    }
});

export default Marionette.Layout.extend({
    className: 'reporting-periods',
    template: Handlebars.compile(reportingPeriodTemplate),
    regions: {
        list: '.reporting-periods-list-container'
    },
    ui: {
        quarterlyToggle: 'input[value="quarterly"]',
        monthlyToggle: 'input[value="monthly"]'
    },
    events: {
        'click input[type="radio"]': function(ev) {
            ev.preventDefault();
            var self = this;

            MessageBox.showYesNo(
                {
                    message: "<p style='margin-bottom: 10px;'>Changing the reporting period length will destroy all existing periods and recreate " +
                    "them with the new length. This will remove the period information from your existing forecasts.</p> " +
                    "<p>Are you sure you want to continue?</p>",
                    icon: 'icon-warning'
                },
                this,
                function() {
                    var periodMode = $(ev.target).val();

                    var callback = function () {
                        self.periodsCollection.trigger('itemUpdated');
                    }.bind(self);

                    $.ajax({
                        method: 'POST',
                        url: '/periods?reset&period_length=' + periodMode,
                        success: callback,
                        error: callback
                    });
                }
            );
        }
    },
    onRender: function() {
        var self = this;
        this.periodsCollection = new PeriodsCollection();

        var doFetch = function() {
            self.periodsCollection.fetch({
                sortOn: [{
                    attribute: 'start_date',
                    order: 'asc'
                }],
                rows: -1,
                success: function() {
                    self.list.show(new ListView({ collection: self.periodsCollection }));
                    if (self.isQuarterPeriod()) {
                        self.ui.quarterlyToggle.prop('checked', true);
                    }
                    else {
                        self.ui.monthlyToggle.prop('checked', true);
                    }

                    self.$el.find('.content-container').nanoScroller();
                }
            });
        };

        this.listenTo(this.periodsCollection, 'itemUpdated', doFetch);

        doFetch();
    },
    isQuarterPeriod: function() {
        var collection = this.periodsCollection;
        var lastEndDate = new Date(collection.models[collection.length - 1].get('end_date'));
        var yearBeforeDate = new Date(lastEndDate.getFullYear() - 1, lastEndDate.getMonth(), lastEndDate.getDate());

        var lastYearCollection = collection.filter(function(model) { return new Date(model.get('end_date')) > yearBeforeDate });

        return lastYearCollection.length <= 8;
    }
});

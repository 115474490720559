import React from 'react'

import app from 'js/app'
import FunnelMap from 'js/react_views/funnel-map/funnel-map'
import FunnelColorKey from './funnel_colorkey'
import funnel from 'js/d3/funnel'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'

export default class SiteMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            funnelId: null,
            deals: [],
            leftDocked: false,
            loading: true
        };

        const preferences = app.user.get('preferences') || {};

        this.funnel = new funnel();
        this.funnel.colorkey(preferences.funnel_color || 'activity');

        const self = this;

        this.props.widget.addEventListener('funnel:change', function() {
            self.loadFunnel(self.props.widget.fetcher.activeFunnel.id);
        });

        this.props.widget.addEventListener('rectState:changed', function(state) {
            self.setState({
                leftDocked: state === 'leftDocked'
            });
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.loadFunnel(this.props.widget.fetcher.activeFunnel.id);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadFunnel(funnelId) {
        if (!this.mounted) {
            return;
        }

        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === funnelId);

        this.setState({
            loading: true
        });

        const self = this;
        let periods = null;
        let deals = null;

        const checkReady = () => {
            if (!periods || !deals) {
                return;
            }

            self.funnel.periods(periods);

            self.setState({
                funnelId: funnelId,
                deals: deals,
                loading: false
            });
        }

        this.props.widget.fetcher.get('/periods', {
            start: 0,
            rows: -1
        }, function(data) {
            periods = new Backbone.Collection(data).models;
            checkReady();
        });

        if (funnel.map_configuration) {
            this.props.widget.fetcher.get('/opportunities', {
                start: 0,
                rows: -1,
                order_by: 'name asc',
                funnel_id: funnelId,
                by_period_id: 'current'
            }, function(data) {
                deals = new Backbone.Collection(data).models;
                checkReady();
            }, {force: true});
        } else {
            deals = [];
            checkReady();
        }
    }

    handleShowDeal(dealId) {
        this.props.widget.triggerEvent('showEntity', {
            type: 'opportunities',
            id: dealId,
            rectState: 'leftDocked',
            widget: this.props.widget
        });
    }

    handleColorkeyChange(colorkeyId) {
        this.funnel.colorkey(colorkeyId);
        this.component.updateDealsColors();
    }

    render() {
        if (!this.state.funnelId) {
            return null;
        }

        let colorKeyStyle = {
            paddingTop: '5px',
            background: 'white'
        };

        if (this.state.leftDocked) {
            colorKeyStyle.paddingRight = '20px';
            colorKeyStyle.paddingLeft = '20px';
        }

        return (
            <div
                style={{
                    height: '100%'
                }}
            >
                <div
                    style={{
                        background: '#494970',
                        borderRadius: this.state.leftDocked ? '0px' : '6px',
                        padding: '20px',
                        height: 'calc(100% - 55px)'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <FunnelMap
                            ref={(el) => this.component = el}
                            key={`site_map_${this.state.funnelId}`}
                            colorScheme={this.funnel.colorScheme}
                            style='solid'
                            funnelId={this.state.funnelId}
                            deals={this.state.deals}
                            showExtraControls={true}
                            onShowDeal={this.handleShowDeal.bind(this)}
                        />
                    </div>
                </div>

                <div style={colorKeyStyle}>
                    <FunnelColorKey
                        onColorkeyChanged={this.handleColorkeyChange.bind(this)}
                    />
                </div>

                {this.state.loading &&
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            background: 'rgba(0, 0, 0, 0.5)',
                            borderRadius: this.state.docked ? '0px' : '6px'
                        }}
                    >
                        <LoadingIndicator/>
                    </div>
                }
            </div>
        );
    }
}
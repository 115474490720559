import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import BucketsCollection from 'js/collections/buckets.js'
import BucketModel from 'js/models/bucket.js'
import bucketTemplate from 'templates/settings/bucket.handlebars'
import bucketListItemViewTemplate from 'templates/settings/bucket_list_item_view.handlebars'
import addNewBucketTemplate from 'templates/settings/bucket_add_new_bucket.handlebars'


var BucketsList, BucketItemView, NewBucketView;

var lengths = [
    { id: 0, title: 'Perpetual' }
];
// add month 1 to 6 and 18
lengths.push({
    id: 1,
    title: '1 month'
});
_.each(_.range(2, 7).concat(9).concat(18), function(i) {
    lengths.push({
        id: i,
        title: i + ' months'
    });
});
// add years 1 to 10 and years 15,20,25,30,35
lengths.push({
    id: 12,
    title: '1 year'
});
_.each(_.range(2, 11).concat(_.range(15, 36, 5)), function(i) {
    lengths.push({
        id: i * 12,
        title: i + ' years'
    });
});

BucketItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(bucketListItemViewTemplate),
    ui: {
        name: '.name',
        editName: '.edit-name',
        mix: '.mix',
        editMix: '.edit-mix',
        asp: '.asp',
        editAsp: '.edit-asp',
        length: '.length',
        editLength: '.edit-length',
        renewal: '.renewal',
        editRenewal: '.edit-renewal',
        start: '.recognition-policy-start',
        editStart: '.edit-recognition-policy-start',
        monthly: '.recognition-policy-monthly',
        editMonthly: '.edit-recognition-policy-monthly',
        end: '.recognition-policy-end',
        editEnd: '.edit-recognition-policy-end',
        tooltips: '[data-toggle=tooltip]',
        deleteItem: '.delete-item'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .delete-item': function() {
            if (!this.ui.deleteItem.hasClass('disabled')) {
                var view = this,
                    mbContent = {
                        message: Handlebars.compile(
                            'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours. </strong>' +
                            `Are you sure you want to delete this bucket?<strong class="cta">${_.escape(this.model.get('name'))}</strong>` +
                            'Type in the bucket name to proceed.If unsure, please contact support.' 
                        ),
                        icon: 'icon-warning',
                        accept_is_negative: true,
                        accept_button_text: 'Delete',
                        cancel_button_text: 'Cancel'
                    };

                this.ui.tooltips.tooltip('hide');

                MessageBox.showYesNo(
                    mbContent,
                    this,
                    function(name) { // yes
                        if (name === view.model.get('name')) {
                            view.$el.fadeOut(400, function() {
                                view.ui.tooltips.tooltip('destroy');
                                view.model.destroy({true: true});
                            });
                        }
                    },
                    function() {}, // no
                    null,
                    { prompt: {
                        type: 'input',
                        conditional_accept_button_shown: view.model.get('name')
                    }}
                );
            }
        }
    },
    onRender: function() {
        this.lengthSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.editLength,
            text: 'title',
            value: this.model.get('length') || 0,
            data: lengths,
            options: {
                placeholder: 'Select length',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'assignee-select-popover popover'
            }
        });

        this.activateElement(this.ui.name, this.ui.editName, {field: 'name'});
        this.activateElement(this.ui.mix, this.ui.editMix, {field: 'mix', parse: parseFloat, percent: true });
        this.activateElement(this.ui.asp, this.ui.editAsp, {field: 'average_selling_price', parse: parseFloat});
        this.activateSelect2(this.ui.length, this.ui.editLength, {field: 'length', parse: parseInt});
        this.activateElement(
            this.ui.renewal, this.ui.editRenewal, {field: 'renewal', parse: parseFloat, percent: true}
        );
        this.activateElement(
            this.ui.start, this.ui.editStart, {field: 'recognition_policy_start', parse: parseFloat, percent: true}
        );
        this.activateElement(
            this.ui.monthly,
            this.ui.editMonthly,
            {field: 'recognition_policy_monthly', parse: parseFloat, percent: true}
        );
        this.activateElement(
            this.ui.end, this.ui.editEnd, {field: 'recognition_policy_end', parse: parseFloat, percent: true}
        );

        this.ui.tooltips.tooltip({
            container: this.$el,
            placement: 'left'
        });

        this.listenTo(this.collection, 'sync add remove', this.manageRemoveButtonStyle);
    },
    manageRemoveButtonStyle: function() {
        if (this.isClosed) {
            return;
        }

        var onlyOneBucket = this.collection.length === 1;

        this.ui.deleteItem.toggleClass('disabled', onlyOneBucket);
        this.ui.deleteItem.attr('data-original-title', onlyOneBucket ? TextManager.getText('ID_ONE_DEAL_BUCKET_NEEDED') : 'Delete Revenue Bucket');
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val(),
                attrs = {},
                model = self.model;

            if (dbField.parse) {
                val = dbField.parse(val);
                if (_.isNaN(val)) {
                    return;
                }
                edit.val(val);
            }

            if (dbField.percent) {
                if (val > 100) {
                    val = 100;
                    edit.val(val);
                }
                else if (val < 0) {
                    val = 0;
                    edit.val(val);
                }
            }

            attrs[dbField.field] = val;
            model.save(attrs, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit();
                }
            });

        });

        edit.on('blur', hideEdit);
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateSelect2: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.select2('open');
        });

        edit.on('change', function() {
            var val = edit.select2('val'),
                attrs = {},
                model = self.model;

            if (dbField.parse) {
                val = dbField.parse(val);
                if (_.isNaN(val)) {
                    return;
                }
                edit.val(val);
            }

            if (dbField.percent) {
                if (val > 100) {
                    val = 100;
                    edit.val(val);
                }
                else if (val < 0) {
                    val = 0;
                    edit.val(val);
                }
            }

            attrs[dbField.field] = val;
            model.save(attrs, {
                patch: true,
                success: function() {
                    text.text(edit.select2('data').title);
                    hideEdit();
                }
            });

        });

        edit.on('select2-blur', hideEdit);

        text.text(edit.select2('data').title);
    }
});

BucketsList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'bucket-list editable-list',
    itemView: BucketItemView,
    itemViewOptions: function() {
        return {
            collection: this.options.collection
        };
    }
});

NewBucketView = Marionette.ItemView.extend({
    className: 'edit-bucket edit-form-modal',
    template: Handlebars.compile(addNewBucketTemplate),
    ui: {
        name: '#bucket-name',
        error_messages: '.error-message'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onShow: function() {
        this.ui.name.focus();
    },
    save: function() {
        var attrs = {
                name: this.ui.name.val(),
                mix: 0,
                average_selling_price: 0,
                length: 0,
                renewal: 0,
                recognition_policy_start: 0,
                recognition_policy_monthly: 0,
                recognition_policy_end: 0
            },
            bucket = new BucketModel(),
            view = this;

        this.error_messages.remove.call(this);

        this.listenTo(bucket, 'invalid', this.invalid);
        bucket.save(attrs, {
            validate: true,
            success: function() {
                view.trigger('new:bucket', [bucket]);
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);
        if (errors.name_undefined || errors.name_too_short) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined || errors.name_too_short)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default Marionette.Layout.extend({
    className: 'buckets-configuration',
    template: Handlebars.compile(bucketTemplate),
    regions: {
        bucketList: '.bucket-list-container',
        addNewBucket: {
            selector: '.add-new-bucket', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    events: {
        'click .add-new-bucket': function(ev) {
            ev.preventDefault();

            var newBucketView = new NewBucketView();
            this.addNewBucket.show(newBucketView);
            this.listenTo(newBucketView, 'new:bucket', function(bucket) {
                this.buckets.add(bucket);
                this.addNewBucket.reset();
                this.$el.find('.content-container').nanoScroller();
            });
            this.listenTo(newBucketView, 'cancel', function() {
                this.addNewBucket.reset();
            });
        }
    },
    initialize: function() {
        var self = this;
        this.buckets = new BucketsCollection();
        this.buckets.on('destroy', function() {
            self.$el.find('.content-container').nanoScroller();
        });
    },
    onRender: function() {
        var self = this;
        this.buckets.fetch({
            sortOn: [{
                attribute: 'name',
                order: 'asc'
            }],
            rows: -1,
            success: function() {
                self.bucketList.show(new BucketsList({ collection: self.buckets }));
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });
    },
    scrollEvents: function() {
        var container = this.$el.find('.content-container'),
            header = this.$el.find('.detail-header');

        $(container).find('.content').scroll(function(){
            if(container.find('.bucket-list').position().top < -1) {
                if(!header.hasClass('header-shadow')){
                    header.addClass('header-shadow');
                }
            }else{
                header.removeClass('header-shadow');
            }
        });
    }
});

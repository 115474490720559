import React from 'react'

import style from './activity_insights.css'


export default class ActivityInsights extends React.Component {
    constructor(props) {
        super(props);

        let title = 'Activity Insights';
        const query = this.props.widget.params.query;

        if (query) {
            title += ' Last ';

            if (query.args.since === 1) {
                title += '24 Hours';
            } else {
                title += `${query.args.since} Days`;
            }
        }

        this.state = {
            data: {},
            title: title
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData() {
        if (!this.mounted) {
            return;
        }

        const query = this.props.widget.params.query;

        if (!query) {
            return;
        }

        let args = _.clone(query.args);

        if (args.since) {
            let date = new Date();
            date.setDate(date.getDate() - args.since);
            args.since = date.toISOString();
        }

        this.props.widget.fetcher.get(query.url, args, this.onData.bind(this));
    }

    onData(data) {
        if (!this.mounted) {
            return;
        }

        this.setState({
            data: data
        });
    }

    render() {
        const params = this.props.widget.params;
        let titleStyle = {};
        let valueStyle = {};

        // todo: add loading indicator

        if (this.props.renderer) {
            const bbox = this.props.renderer.getBoundingClientRect();

            valueStyle = {
                fontSize: Math.max(bbox.height / 6.5, 16)
            };

            titleStyle = {
                fontSize: Math.max(bbox.height / 17.1, 14)
            };
        }

        return (
            <div
                className={style.activityInsights}
            >
                <div className={style.title}>
                    <div className={style.tContainer}>
                        <div>{this.state.title}</div>
                    </div>
                </div>

                <div
                    className={style.content}
                >
                    <div className={style.row}>
                        <div className={style.cell}>
                            <div className={style.value} style={valueStyle}>{this.state.data.calls}</div>
                            <div className={style.description} style={titleStyle}>Phone Calls</div>
                        </div>

                        <div className={style.cell}>
                            <div className={style.value} style={valueStyle}>{this.state.data.emails}</div>
                            <div className={style.description} style={titleStyle}>Emails</div>
                        </div>
                    </div>

                    <div className={style.row}>
                        <div className={style.cell}>
                            <div className={style.value} style={valueStyle}>{this.state.data.tasks}</div>
                            <div className={style.description} style={titleStyle}>Tasks Completed</div>
                        </div>

                        <div className={style.cell}>
                            <div className={style.value} style={valueStyle}>{this.state.data.notes}</div>
                            <div className={style.description} style={titleStyle}>Notes Created</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
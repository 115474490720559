import _ from 'underscore'

import BaseModel from 'js/models/base'


export default BaseModel.extend({
    name: 'Automation',
    urlRoot: '/automations',
    validate: function(attrs, options) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name = "Name is required";
        }

        if (_.isUndefined(attrs.element_type) || _.isNull(attrs.element_type)) {
            errors.element_type = "Element is required";
        }

        if (_.isUndefined(attrs.group) || _.isNull(attrs.group)) {
        	errors.group = "Group is required";
        } else if (attrs.element_type != attrs.group.element_type) {
            errors.group = "Element and Group element must be the same";
        }

        if (!_.isObject(attrs.event) || _.isUndefined(attrs.event.type)) {
        	errors.event_type = "Event is required";
        }

        if (!_.isObject(attrs.action) || _.isUndefined(attrs.action.type)) {
        	errors.action_type = "Action is required";
        }

        if (options && _.isObject(options.validate)) {
        	_.each(options.validate, function(value, key) {
        		var key_error = value(attrs[key], options);
        		if (!_.isUndefined(key_error)) {
        			errors[key] = key_error;
        		}
        	});
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

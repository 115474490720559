import Handlebars from 'handlebars';
import Marionette from 'Backbone.Marionette'

import app from 'js/app';
import CognitoLoginView from './login_cognito';
import SalesSeekLoginView from './login_salesseek';
import loginTemplate from 'templates/login.handlebars'

const LoginView = Marionette.Layout.extend({
    template: Handlebars.compile(loginTemplate),
    regions: {
        container: '.container-region'
    },
    onRender: function() {
        const clientDetails = app.options.clientDetails;
        if (clientDetails?.cognito) {
            this.container.show(new CognitoLoginView({...this.options}));
        } else if (clientDetails) {
            this.container.show(new SalesSeekLoginView({...this.options}));
        } else {
            this.container.hide();
        }
    }
});

export default LoginView;

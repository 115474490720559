import _ from 'underscore'

import BaseModel from 'js/models/base'


var Client = BaseModel.extend({
    name: 'Client',
    urlRoot: '/clients',
    apiType: 'salesseek.core.models.client.Client',
    defaults: _.extend({}, BaseModel.prototype.defaults, {
        name: ''
    })
});
Client.fromString = function (string) {
    return new Client({name: string});
};
export default Client;

import React from 'react';

import app from 'js/app';
import KPIWidget from 'js/react_views/widgets/kpi.js';
import TimeSpanWidget from 'js/react_views/widgets/time-span.js';
import TextManager from 'app/text-manager';

import style from './performance_widget.css'


export default class PerformanceWidget extends React.Component {
    constructor(props) {
        super(props);

        this.sections = [{
            id: 'campaigns_sent',
            name: TextManager.parseText('${ID_CAMPAIGN, plural, capitalize} Sent')
        }, {
            id: 'emails_sent',
            name: 'Emails Sent'
        }, {
            id: 'delivery_rate',
            name: 'Delivery Rate',
            isPercentage: true
        }, {
            id: 'open_rate',
            name: 'Open Rate',
            isPercentage: true
        }, {
            id: 'click_rate',
            name: 'Click Rate',
            isPercentage: true
        }, {
            id: 'unsubscribe_rate',
            name: 'Unsubscribe Rate',
            isPercentage: true
        }];

        this.dateFilter = {
            'filter_by_date_field': 'sent'
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchValues();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchValues() {
        const self = this;

        let args = _.clone(this.dateFilter);

        if (!this.props.group.fixed && this.props.group.shortId) {
            args.group_id = this.props.group.shortId;
        }

        const argNames = ['status', 'search_not', 'search', 'for_automations'];
        const fetchArgs = this.props.group.fetchArgs || {};

        for (const an of argNames) {
            if (an in fetchArgs) {
                args[an] = fetchArgs[an];
            }
        }

        app.shortTermCache.get(`/campaigns_performance`, args, function(result) {
            if (!self.mounted) {
                return;
            }

            self.kpiWidget.setValues(result);
        });
    }

    handleSpanChange(spanId, params) {
        let beginDate = null;
        let endDate = null;

        if (spanId === 'custom') {
            beginDate = params.beginDate;
            endDate = params.endDate;
        } else if (spanId !== 'all') {
            const end = new Date();
            const begin = new Date(end);

            switch(spanId) {
                case 'day':
                    begin.setDate(end.getDate() - 1);
                    break;

                case 'week':
                    begin.setDate(end.getDate() - 7);
                    break;

                case 'month':
                    begin.setMonth(end.getMonth() - 1);
                    break;

                case 'quarter':
                    begin.setMonth(end.getMonth() - 3);
                    break;

                case 'year':
                    begin.setFullYear(end.getFullYear() - 1);
                    break;
            }

            beginDate = begin.toISOString();
            endDate = end.toISOString();
        }

        if (beginDate && endDate) {
            this.dateFilter.filter_by_begin_date = beginDate;
            this.dateFilter.filter_by_end_date = endDate;
        } else {
            delete this.dateFilter.filter_by_begin_date;
            delete this.dateFilter.filter_by_end_date;
        }

        if (this.props.onSpanChanged) {
            this.props.onSpanChanged(_.clone(this.dateFilter));
        }

        this.fetchValues();
    }

    render() {
        return (
            <div className={style.performanceWidget}>
                <div className={style.wHeader}>
                    <div className={style.hTitle}>Campaign Performance</div>
                    <div
                        style={{marginLeft: 'auto'}}
                    >
                        <TimeSpanWidget
                            onSpanChanged={this.handleSpanChange.bind(this)}
                        />
                    </div>
                </div>

                <KPIWidget
                    ref={(el) => this.kpiWidget = el}
                    sections={this.sections}
                />
            </div>
        );
    }
}

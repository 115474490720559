import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import base64 from 'js/utils/base64'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import MessageBox from 'js/views/message_box'
import {NewSelect, IndividualSelectItem} from 'js/react_views/widgets/select'
import DateTimePicker from 'js/widgets/date-time-picker'
import dateFormat from 'js/utils/date-format'
import OpportunityModel from 'js/models/opportunity'
import ActivitiesCollection from 'js/collections/activity'
import RelatedFilesCollection from 'js/collections/related_files.js'
import IndividualFilterModel from 'js/models/individual_filter'

import style from './aftercare.css'

let ACTIVITIES_CACHE = null;

function getCustomFieldId(name) {
    const cfs = app.globalData.customFieldsInfo.deals;

    for (const cfid in cfs) {
        if (cfs[cfid].name.toLowerCase() === name.toLowerCase()) {
            return cfid;
        }
    }

    return null;
}


class DatetimeContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 'Click to select date'
        };
    }

    render() {
        return (
            <div
                ref={(el) => this.element = el}
                className={style.cDatetimeContainer}
                onClick={this.handleClick.bind(this)}
            >
                <div
                    ref={(el) => this.contentEl = el}
                >
                    {this.state.value}
                </div>
            </div>
        );
    }

    handleClick() {
        const el = $(this.element);

        const dateTimePicker = new DateTimePicker({
            altField: $(this.contentEl),
            time: '11:00',
            css: {
                left: el.offset().left - 225,
                top: el.offset().top + el.height() + 5
            }
        });

        dateTimePicker.showPicker();

        this.props.parent.listenTo(dateTimePicker, 'date-time:change', this.handleDatetime.bind(this));
    }

    handleDatetime(datetime) {
        this.setState({
            value: `${dateFormat.dayToShortText(datetime.rawDateTime.getDay())} ${datetime.date} at ${datetime.time}`
        });

        this.props.onChange(datetime);
    }
}


class Action extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            description: '',
            date: '',
            time: '',
            content: null
        };
    }

    render() {
        return (
            <div className={style.action}>
                <div className={style.mLeft}>
                    <div className={style.lIconContainer}>
                        <div className={this.icon}/>
                    </div>
                    <div className={style.lDatetime}>
                        <div>{this.state.date}</div>
                        <div>{this.state.time}</div>
                    </div>
                </div>

                <div className={style.mRight}>
                    <div className={style.rTitle}>{this.title}</div>
                    <div className={style.rDescription}>{this.state.description}</div>
                    <div className={style.rContent}>
                        {this.state.content}
                    </div>
                </div>
            </div>
        );
    }

    getPhaseId(phaseName) {
        const phase = app.globalData.aftercare.phases.user.find(phase => phase.name.toLowerCase() === phaseName.toLowerCase());

        if (phase) {
            return phase.id;
        }

        return null;
    }

    moveToPhaseName(phaseName, callback) {
        const phaseId = this.getPhaseId(phaseName);

        if (phaseId) {
            this.updateDeal({
                phase_id: phaseId
            }, callback);
        }
    }

    moveToLost() {
        const content = {
            message: 'Are you sure you want to close this report?',
            icon: 'icon-warning'
        };

        const self = this;

        MessageBox.showYesNo(content, this.props.parent, function() {
            self.updateDeal({
                phase_id: app.globalData.aftercare.phases.lost.id
            });
        });
    }

    updateDeal(data, callback) {
        // todo: show loading indicator and disable input
        const self = this;

        this.props.model.save(data, {
            patch: true,
            wait: true,
            success: function(data) {
                self.props.onDealUpdated();

                if (callback) {
                    callback(data);
                }
            }
        });
    }

    getActionDatetime(phase, callback) {
        const self = this;

        const doProcess = () => {
            for (const entry of ACTIVITIES_CACHE) {
                if (entry.to === phase.toLowerCase()) {

                    self.setState({
                        date: dateFormat.formatDDMMYYYYDate(entry.created),
                        time: dateFormat.shortFormatTime(entry.created)
                    });

                    if (callback) {
                        callback(dateFormat.parseDate(entry.created));
                    }
                    break;
                }
            }
        }

        // the value is the datetime when the deal entered the phase
        if (ACTIVITIES_CACHE) {
            doProcess();
        } else {
            let collection = new ActivitiesCollection();

            collection.fetch({
                data: {
                    rows: -1,
                    item_id: this.props.model.get('id'),
                    item_type: 'opportunities',
                    deals: true
                },
                success: function(data) {
                    let movementActivities = [];

                    for (const model of data.models) {
                        if (model.get('activity_type') === 'auto:opportunity_movement') {
                            const params = model.get('params');

                            movementActivities.push({
                                from: params.from.toLowerCase(),
                                to: params.to.toLowerCase(),
                                created: model.get('created')
                            });
                        }
                    }

                    ACTIVITIES_CACHE = movementActivities;
                    doProcess();
                }
            });
        }
    }

    twoDigits(value) {
        if (value < 10) {
            return `0${value}`;
        }

        return value;
    }
}


class VerifyIssue extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-plus';
        this.title = 'Verify Issue';

        const created = this.props.model.get('created');

        const urgencyCfName = AppConfig.getValue('deals.aftercare.urgency_cf_name', 'issue urgency').toLowerCase();
        this.urgencyCf = app.globalData.customFieldsInfo.opportunitiesArray.find(c => c.name.toLowerCase() === urgencyCfName);
        this.urgencyOptions = [];
        this.selectedUrgencyId = null;

        if (this.urgencyCf) {
            for (const k in this.urgencyCf.options) {
                this.urgencyOptions.push({
                    id: k,
                    title: this.urgencyCf.options[k]
                });
            }
        }

        this.state = {
            date: dateFormat.formatDDMMYYYYDate(created),
            time: dateFormat.shortFormatTime(created),
            description: 'A new issue has been received. Check the issue report, set a priority and verify the issue for work.',
            content: this.buildContent()
        };

        const plotCfId = getCustomFieldId('property');

        if (plotCfId) {
            const plotId = this.props.model.get(`custom_field.${plotCfId}`);

            if (plotId) {
                const dealModel = new OpportunityModel({id: plotId});
                const self = this;

                dealModel.fetch({
                    success: function(data) {
                        self.setState({
                            description: `A new issue has been received for ${data.get('name')}. Check the issue report and verify the issue for work.`
                        });
                    }
                });
            }
        }
    }

    componentDidMount() {
        this.getActionDatetime('Issue Reported');
    }

    buildContent() {
        return (
            <div>
                {this.urgencyOptions.length > 0 &&
                    <div style={{
                        marginBottom: '15px'
                    }}>
                        <NewSelect
                            data={this.urgencyOptions}
                            placeholder='Set priority'
                            width={250}
                            onSelect={this.handleUrgencySelection.bind(this)}
                        />
                    </div>
                }

                <div className={style.cButtons}>
                    <div
                        className={`
                            ${style.bButton}
                            ${style.bGreen}
                            ${this.selectedUrgencyId === null ? style.bDisabled : ''}
                        `}
                        onClick={this.handleVerify.bind(this)}
                    >
                        Verify
                    </div>

                    <div
                        className={`${style.bButton} ${style.bRed}`}
                        onClick={this.handleClose.bind(this)}
                    >
                        Close Report
                    </div>
                </div>
            </div>
        );
    }

    handleUrgencySelection(items) {
        this.selectedUrgencyId = items[0].id;

        this.setState({
            content: this.buildContent()
        });
    }

    handleVerify() {
        let data = {
            [`custom_field.${this.urgencyCf.id}`]: this.selectedUrgencyId
        };

        const phaseId = this.getPhaseId('issue verified');

        if (phaseId) {
            data.phase_id = phaseId;
        }

        this.updateDeal(data);
    }

    handleClose() {
        this.moveToLost();
    }
}

class AssignContractor extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-tools';
        this.title = 'Assign Contractor';

        this.contractorId = null;
        this.filterId = null;

        this.state = {
            description: 'Use the drop-down menu below to assign this job to a contractor. The contractor will be notified via email.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        this.getActionDatetime('Issue Verified');

        if (!app.globalData.aftercare.contractorFilterId) {
            const cfs = app.globalData.customFieldsInfo.individuals;
            let cfId = null;
            let optionId = null;

            // get the 'contact type' custom field id and the 'contractor' option id to build the filter
            for (const cfid in cfs) {
                if (cfs[cfid].name.toLowerCase() === 'contact type') {
                    cfId = cfid;

                    for (const opid in cfs[cfid].options) {
                        if (cfs[cfid].options[opid].toLowerCase() === 'contractor') {
                            optionId = opid;
                            break;
                        }
                    }
                    break;
                }
            }

            if (optionId) {
                const filterModel = new IndividualFilterModel();
                const self = this;

                filterModel.save({
                    rules: [[{
                        field: 'individual_custom',
                        operator: 'equal',
                        custom: cfId,
                        values: optionId
                    }]]
                }, {
                    alert: false,
                    success: function(data) {
                        app.globalData.aftercare.contractorFilterId = data.id;
                        self.filterId = data.id;

                        self.setState({
                            content: self.buildContent()
                        });
                    }
                });
            }
        } else {
            this.filterId = app.globalData.aftercare.contractorFilterId;

            this.setState({
                content: this.buildContent()
            });
        }
    }

    buildContent() {
        if (!this.filterId) {
            return null;
        }

        return (
            <div>
                <div style={{
                    marginBottom: '15px'
                }}>
                    <NewSelect
                        url='/individuals'
                        placeholder='Search and select a contractor'
                        width={250}
                        onSelect={this.handleContractorSelection.bind(this)}
                        itemView={IndividualSelectItem}
                        options={{
                            search_parameters: {
                                by_filter_id: this.filterId,
                                search_extra: true,
                                search_only_on: 'full_name, organization.name'
                            }
                        }}
                    />
                </div>

                <div className={style.cButtons}>
                    <div
                        className={`
                            ${style.bButton}
                            ${style.bGreen}
                            ${!this.contractorId ? style.bDisabled : ''}
                        `}
                        onClick={this.handleAssignContractor.bind(this)}
                    >
                        Assign Contractor
                    </div>
                </div>
            </div>
        );
    }

    handleContractorSelection(items) {
        this.contractorId = items[0].id;

        this.setState({
            content: this.buildContent()
        });
    }

    handleAssignContractor() {
        let data = {};
        const phaseId = this.getPhaseId('contractor assigned');

        if (phaseId) {
            data.phase_id = phaseId
        };

        const cfId = getCustomFieldId('contractor');

        if (cfId) {
            data[`custom_field.${cfId}`] = this.contractorId;
        }

        if (!_.isEmpty(data)) {
            this.updateDeal(data);
        }
    }
}

class ScheduleJob extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-clock';
        this.title = 'Schedule Job';

        this.datetime = null;

        this.state = {
            description: 'Schedule the job on behalf of your contractor. The homeowner will be notified of this progression.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        this.getActionDatetime('Contractor Assigned');
    }

    buildContent() {
        return (
            <div>
                <div className={style.cCaption}>Date + Time*</div>

                <div style={{
                    marginTop: '10px',
                    marginBottom: '20px'
                }}>
                    <DatetimeContainer
                        parent={this.props.parent}
                        onChange={this.handleDatetimeChange.bind(this)}
                    />
                </div>

                <div className={style.cButtons}>
                    <div
                        className={`
                            ${style.bButton}
                            ${style.bGreen}
                            ${!this.datetime ? style.bDisabled : ''}
                        `}
                        onClick={this.handleSchedule.bind(this)}
                    >
                        Schedule
                    </div>
                </div>
            </div>
        );
    }

    handleSchedule() {
        let data = {
            phase_id: this.getPhaseId('issue scheduled')
        };

        const cfDate = getCustomFieldId('onsite visit date');
        const cfTime = getCustomFieldId('onsite visit time');

        if (cfDate) {
            data[`custom_field.${cfDate}`] = dateFormat.ISODate(this.datetime.rawDateTime);
        }

        if (cfTime) {
            data[`custom_field.${cfTime}`] = this.datetime.time;
        }

        this.updateDeal(data);
    }

    handleDatetimeChange(datetime) {
        this.datetime = datetime;

        this.setState({
            content: this.buildContent()
        });
    }
}

class RescheduleJob extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-alarm';
        this.title = 'Reschedule Job';

        this.state = {
            description: 'The homeowner has declined the proposed job date. Contact the homeowner to reschedule.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        this.getActionDatetime('Attention Required');
    }

    buildContent() {
        return (
            <div>
                <div className={style.cCaption}>Date + Time*</div>

                <div style={{
                    marginTop: '10px',
                    marginBottom: '20px'
                }}>
                    <DatetimeContainer
                        parent={this.props.parent}
                        onChange={this.handleDatetimeChange.bind(this)}
                    />
                </div>

                <div className={style.cButtons}>
                    <div
                        className={`
                            ${style.bButton}
                            ${style.bYellow}
                            ${!this.datetime ? style.bDisabled : ''}
                        `}
                        onClick={this.handleReschedule.bind(this)}
                    >
                        Reschedule
                    </div>
                </div>
            </div>
        );
    }

    handleReschedule() {
        let data = {
            phase_id: this.getPhaseId('issue scheduled')
        };

        const cfDate = getCustomFieldId('onsite visit date');
        const cfTime = getCustomFieldId('onsite visit time');
        const cfReason = getCustomFieldId('attention required reason');

        if (cfDate) {
            data[`custom_field.${cfDate}`] = dateFormat.ISODate(this.datetime.rawDateTime);
        }

        if (cfTime) {
            data[`custom_field.${cfTime}`] = this.datetime.time;
        }

        if (cfReason) {
            data[`custom_field.${cfReason}`] = null;
        }

        this.updateDeal(data);
    }

    handleDatetimeChange(datetime) {
        this.datetime = datetime;

        this.setState({
            content: this.buildContent()
        });
    }
}

class RescheduleJobContractor extends RescheduleJob {
    constructor(props) {
        super(props);

        this.state.description = 'The contractor has declined the proposed job date. Contact the contractor to reschedule.';
    }
}

class ScheduleConfirmed extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-alarm';
        this.title = 'Job schedule - Confirmed';

        let dateMessage = '';
        const cfDate = getCustomFieldId('onsite visit date');
        const cfTime = getCustomFieldId('onsite visit time');

        if (cfDate) {
            let date = this.props.model.get(`custom_field.${cfDate}`);

            if (date) {
                date = dateFormat.parseDate(date);
                dateMessage = `${dateFormat.dayToShortText(date.getDay())} ${dateFormat.shortFormatWithYear(date)}`;
            }
        }

        if (cfTime) {
            const time = this.props.model.get(`custom_field.${cfTime}`);

            if (time) {
                dateMessage += ` at ${time}`;
            }
        }

        this.state = {
            description: 'The requested work has been assigned to the contractor and scheduled for the following date:',
            content: (
                <div>
                    <div className={style.cCaption}>Date + Time</div>

                    <div style={{
                        marginTop: '10px',
                        marginBottom: '20px'
                    }}>
                        <div
                            className={style.cDatetimeContainer}
                            style={{cursor: 'default'}}
                        >
                            {dateMessage}
                        </div>
                    </div>

                    <div className={style.cButtons}>
                        <div
                            className={`${style.bButton} ${style.bYellow}`}
                            onClick={this.handleEditSchedule.bind(this)}
                        >
                            Edit Schedule
                        </div>
                    </div>
                </div>
            )
        };
    }

    componentDidMount() {
        this.getActionDatetime('Issue Scheduled');
    }

    handleEditSchedule() {
        this.moveToPhaseName('Contractor Assigned');
    }
}

class JobCompletion extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-checkmark2';
        this.title = 'Job completion - awaiting contractor';
        this.collapsed =  true;

        this.state = {
            description: 'The job is currently awaiting contractor completion and report.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        this.getActionDatetime('Issue Work In Progress');
    }

    buildContent() {
        return (
            <div>
                <div
                    className={style.cOverride}
                    onClick={this.toggleCollapsion.bind(this)}
                >
                    <div
                        className={`${this.collapsed ? 'icon-caret-right' : 'icon-caret-down'}`}
                        style={{
                            width: '15px',
                        }}
                    />
                    <div>Manual completion override</div>
                </div>

                {!this.collapsed &&
                    <div>
                        <div
                            className={style.rDescription}
                            style={{
                                marginBottom: '15px'
                            }}
                        >
                            Complete the job on behalf of your contractor. The homeowner will be notified of this progression.
                        </div>

                        <div className={style.cButtons}>
                            <div
                                className={`${style.bButton} ${style.bGreen}`}
                                onClick={this.handleCompleted.bind(this)}
                            >
                                Job Completed
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

    toggleCollapsion() {
        this.collapsed = !this.collapsed;

        this.setState({
            content: this.buildContent()
        });
    }

    handleCompleted() {
        const self = this;

        this.moveToPhaseName('homeowner sign-off', function(data) {
            // create the report
            const modified = dateFormat.parseDate(data.get('modified'));
            const date = `${self.twoDigits(modified.getDate())}${self.twoDigits(modified.getMonth() + 1)}${modified.getFullYear()}`;
            const time = `${self.twoDigits(modified.getHours())}${self.twoDigits(modified.getMinutes())}`;

            const filename = `CON-SO-${date}-${time}-REPORT.txt`;

            const report = {
                closed: modified.toISOString(),
                closed_by: app.user.get('name'),
                images: [],
                comments: ''
            };

            $.ajax({
                type: 'POST',
                url: `/opportunities/${data.get('id')}/related_files`,
                contentType: 'application/json',
                data: JSON.stringify({
                    files: [{
                        filename: filename,
                        file: base64.encode(JSON.stringify(report))
                    }]
                }),
                complete: function() {
                    self.props.onDealUpdated();
                }
            });
        });
    }
}

class ViewReport extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-checkmark2';
        this.title = 'Job completed - view sign-off report';

        this.report = null;

        this.state = {
            description: 'The contractor has completed and signed off the work required. Review the report below.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        const self = this;

        // todo: show loading indicator
        this.getActionDatetime('Homeowner Sign-Off', function(modified) {
            const files = new (RelatedFilesCollection.extend({
                urlRoot: () => `/opportunities/${self.props.model.get('id')}/related_files`,
                defaultRows: -1
            }))();

            let reportId = null;

            files.fetch().done(() => {
                // find the report file using the date
                modified.setSeconds(0);
                modified.setMilliseconds(0);

                for (const f of files.models) {
                    const nameParts = f.get('name').split('-');

                    if (nameParts.length === 5 && nameParts[0] === 'CON' && nameParts[1] === 'SO' && nameParts[4] === 'REPORT.txt') {
                        const date = nameParts[2];
                        const time = nameParts[3];
                        const datetime = new Date(Date.UTC(
                            date.substr(4, 4),
                            date.substr(2, 2) - 1,
                            date.substr(0, 2),
                            time.substr(0, 2),
                            time.substr(2, 2),
                            0,
                            0
                        ));

                        const diff = Math.abs(modified - datetime);

                        if (diff <= 90000) { // 1.5 minutes
                            reportId = f.get('id');
                            break;
                        }
                    }
                }

                if (reportId) {
                    $.get(`/content_files/${reportId}?download`, function(data) {
                        self.report = JSON.parse(data);
                        self.setState({
                            content: self.buildContent()
                        });
                    });
                }
            });
        });
    }

    showImage(imageId) {
        vent.trigger('file-preview:show', imageId);
    }

    buildContent() {
        return (
            <div className={style.cReport}>
                <div className={style.rTitle}>Contractor Sign Off Report</div>

                {this.report &&
                    <div className={style.rDescription}>{this.report.closed_by} digitally signed off this job on {dateFormat.entityInformationFormat(this.report.closed)}.</div>
                }

                {this.report &&
                    <div style={{
                        marginTop: '10px'
                    }}>
                        <div className={style.cSubtitle}>Images</div>
                        <div className={style.cImagesContainer}>
                            {this.report.images.length === 0 &&
                                <div className={style.icEmpty}>No Images</div>
                            }

                            {this.report.images.map(imageId => {
                                const url = `${app.options.apiUrl}/content_files/${imageId}?download`;

                                return (
                                    <img
                                        key={`image_${imageId}`}
                                        className={style.icImage}
                                        src={url}
                                        onClick={() => this.showImage(imageId)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                }

                {this.report &&
                    <div style={{
                        marginTop: '10px'
                    }}>
                        <div className={style.cSubtitle}>Comments</div>
                        <div className={style.cComments}>{this.report.comments || 'No Comments'}</div>
                    </div>
                }
            </div>
        );
    }
}

class WorkRejected extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-warning';
        this.title = 'ATTENTION - Completed work rejected';

        this.report = null;

        this.state = {
            description: 'The homeowner is not satisfied with the work completed.',
            content: this.buildContent()
        };
    }

    componentDidMount() {
        const self = this;

        // todo: show loading indicator
        this.getActionDatetime('Attention Required', function(modified) {
            const files = new (RelatedFilesCollection.extend({
                urlRoot: () => `/opportunities/${self.props.model.get('id')}/related_files`,
                defaultRows: -1
            }))();

            let reportId = null;

            files.fetch().done(() => {
                // find the report file using the date
                modified.setSeconds(0);
                modified.setMilliseconds(0);

                for (const f of files.models) {
                    const nameParts = f.get('name').split('-');

                    if (nameParts.length === 5 && nameParts[0] === 'HO' && nameParts[1] === 'MW' && nameParts[4] === 'REPORT.txt') {
                        const date = nameParts[2];
                        const time = nameParts[3];
                        const datetime = new Date(Date.UTC(
                            date.substr(4, 4),
                            date.substr(2, 2) - 1,
                            date.substr(0, 2),
                            time.substr(0, 2),
                            time.substr(2, 2),
                            0,
                            0
                        ));

                        const diff = Math.abs(modified - datetime);

                        if (diff <= 90000) { // 1.5 minutes
                            reportId = f.get('id');
                            break;
                        }
                    }
                }

                if (reportId) {
                    $.get(`/content_files/${reportId}?download`, function(data) {
                        self.report = JSON.parse(data);
                        self.setState({
                            content: self.buildContent()
                        });
                    });
                }
            });
        });
    }

    showImage(imageId) {
        vent.trigger('file-preview:show', imageId);
    }

    buildContent() {
        return (
            <div className={style.cReport}>
                <div className={style.rTitle}>Homeowner Follow Up Report</div>

                {this.report &&
                    <div className={style.rDescription}>Follow up report submitted on {dateFormat.entityInformationFormat(this.report.closed)}.</div>
                }

                {this.report &&
                    <div style={{
                        marginTop: '10px'
                    }}>
                        <div className={style.cSubtitle}>Images</div>
                        <div className={style.cImagesContainer}>
                            {this.report.images.length === 0 &&
                                <div className={style.icEmpty}>No Images</div>
                            }

                            {this.report.images.map(imageId => {
                                const url = `${app.options.apiUrl}/content_files/${imageId}?download`;

                                return (
                                    <img
                                        key={`image_${imageId}`}
                                        className={style.icImage}
                                        src={url}
                                        onClick={() => this.showImage(imageId)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                }

                {this.report &&
                    <div style={{
                        marginTop: '10px'
                    }}>
                        <div className={style.cSubtitle}>Comments</div>
                        <div className={style.cComments}>{this.report.comments || 'No Comments'}</div>
                    </div>
                }
            </div>
        );
    }
}

class AwaitingSignOff extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-checkmark2';
        this.title = 'Job Completed - awaiting Homeowner sign off';

        this.state = {
            description: 'Currently awaiting sign-off from the Homeowner. The Homeowner can respond via the Aftercare portal.'
        };
    }

    componentDidMount() {
        this.getActionDatetime('Homeowner Sign-Off');
    }
}

class HomeownerSignOff extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-checkmark2';
        this.title = 'Homeowner sign off - job complete';

        this.state = {
            description: 'The homeowner is satisfied with the completed work.'
        };
    }

    componentDidMount() {
        this.getActionDatetime('Completed');
    }
}

class JobClosed extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-cross';
        this.title = 'Job closed';

        this.state = {
            description: 'This issue has been marked as completed - no further work is required.'
        };
    }
}

class ProblemWithJob extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-cross';
        this.title = 'The contractor is unable to make the job';

        this.state = {
            description: 'The Contractor has indicated that they are no longer able to make the job. Please contact them directly for more information.'
        };
    }
}

class NoAction extends Action {
    constructor(props) {
        super(props);

        this.icon = 'icon-coffee';
        this.title = "There's nothing for you to do right now";

        this.state = {
            description: "We'll keep you updated."
        };
    }
}

class Aftercare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actions: []
        };

        this.renderCounter = 0;
    }

    componentDidMount() {
        this.calculateActiveActions();
    }

    calculateActiveActions() {
        let newActions = [];

        const phase = this.props.model.get('phase');

        if (phase.phase_type === 'lost' || phase.phase_type === 'won') {
            newActions = [JobClosed];
        } else {
            const currentPhase = app.globalData.aftercare.phases.user.find(p => p.id === phase.id);

            if (currentPhase) {
                switch (currentPhase.name.toLowerCase()) {
                    case 'issue reported':
                        newActions = [VerifyIssue];
                        break;

                    case 'issue verified':
                        newActions = [AssignContractor];
                        break;

                    case 'contractor assigned':
                        newActions = [ScheduleJob];
                        break;

                    case 'issue scheduled':
                        newActions = [ScheduleConfirmed, JobCompletion];
                        break;

                    case 'attention required': {
                        const reasonCfId = getCustomFieldId('attention required reason');

                        if (reasonCfId) {
                            const value = this.props.model.get(`custom_field.${reasonCfId}`);
                            const options  = app.globalData.customFieldsInfo.deals[reasonCfId].options;
                            let option = null;

                            for (const oid in options) {
                                if (oid === value) {
                                    option = options[oid].toLowerCase();
                                    break;
                                }
                            }

                            switch (option) {
                                case 'homeowner rescheduled':
                                    newActions = [RescheduleJob];
                                    break;

                                case 'homeowner requires more work':
                                    newActions = [WorkRejected];
                                    break;

                                case 'contractor rescheduled':
                                    newActions = [RescheduleJobContractor];
                                    break;

                                case 'contractor problem with job':
                                case 'contractor job pushed':
                                    newActions = [ProblemWithJob];
                                    break;
                            }
                        }

                        if (newActions.length === 0) {
                            newActions = [NoAction];
                        }
                    }
                    break;

                    case 'issue work in progress':
                        newActions = [JobCompletion];
                        break;

                    case 'homeowner sign-off':
                        newActions = [ViewReport, AwaitingSignOff];
                        break;

                    case 'completed':
                        newActions = [HomeownerSignOff, ViewReport];
                        break;

                    default:
                        newActions = [NoAction];
                        break;
                }
            }
        }

        this.setState({
            actions: newActions
        });
    }

    render() {
        const actions = this.state.actions;

        return (
            <div className={style.aftercare}>
                {actions.map((Action) => {
                    return (
                        <Action
                            key={`action_${this.renderCounter++}`}
                            model={this.props.model}
                            parent={this.props.parent}
                            onDealUpdated={this.handleDealUpdate.bind(this)}
                        />
                    );
                })}
            </div>
        );
    }

    handleDealUpdate() {
        this.calculateActiveActions();
        // todo: trigger event to update the values in the overview view for the deal
    }
}


const AftercareView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    initialize: function(options) {
        this.model = options.model;
        this.renderCounter = 0;
        ACTIVITIES_CACHE = null;
    },
    onRender: function() {
        const self = this;

        this.listenTo(vent, 'deal:save:phase', function(model) {
            self.model = model;
            self.renderReact();
        });

        this.renderReact();
    },
    renderReact() {
        ReactDOM.render(
            <Aftercare
                key={this.renderCounter++}
                model={this.model}
                parent={this}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default AftercareView;

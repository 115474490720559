import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2'
import columnSelectorTemplate from 'templates/column_selector.handlebars'
import TextManager from 'app/text-manager';


var ItemView = Marionette.ItemView.extend({
    tagName: "li",
    className: 'draggable',
    template: Handlebars.compile([
        '<div class="item-handle"><div></div></div>',
        '<div class="delete-item delete-item-icon"><div></div></div>',
        '<span class="item-title">{{name}}</span>'
    ].join('')),
    events: {
        'click .delete-item': function(ev) {
            var self = this;

            ev.preventDefault();

            this.$el
                .animate(
                    { opacity: 0 },
                    { queue: false, duration: 200 }
                )
                .slideUp(200, function() {
                    self.$el.trigger('resize');
                    self.parent.collection.remove(self.model);
                });
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    }
});

export default Marionette.CompositeView.extend({
    className: 'column-select-modal scrolling-modal-inner at-top',
    template: Handlebars.compile(columnSelectorTemplate),
    itemView: ItemView,
    itemViewOptions: function() {
        return {
            parent: this
        };
    },
    itemViewContainer: '.column-list',
    ui: {
        header: '.modal-header',
        subheader: '.modal-nav',
        footer: '.modal-footer',
        scroll: '.modal-scroll',
        select: '.column-select',
        list: '.column-list'
    },
    events: {
        'click .close': function(ev) {
            ev.preventDefault();

            this.close();
        },
        'click .save': function(ev) {
            ev.preventDefault();

            var collection;

            if (this.pinned.length > 0) {
                collection = new Backbone.Collection(this.collection.toJSON());
                collection.add(this.pinned, { at: 0 });
            }
            else {
                collection = this.collection;
            }

            this.trigger('save', collection);

            this.close();
        },
        'sortstart .column-list': 'onStartDragging',
        'sortupdate .column-list': 'onStopDragging',
        'resize': 'scrollbar',
        'animation:complete': function(ev) {
            this.scrollbar();
            ev.target.scrollIntoView({behavior: 'smooth'});
        }
    },
    initialize: function(options) {
        _.extend(this, options);
        const funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

        var selectedColumns = this.selectedColumns;
        var columns = _.filter(this.columns, function(item) {
            return !item.pinned;
        });

        this.pinned = _.difference(this.columns, columns);

        this.all = _.map(columns, function(item) {
            var name = item.name;
            var id = item.id;

            if (_.isFunction(name)) {
                name = name();
            }

            if (id.includes('funnel')) {
                name += ` (${funnelText})`
            }
            else if (id.includes('phase')) {
                name += ' (Phase)'
            }
            else if (id.includes('buckets')) {
                name += ' (Bucket)'
            }
            else if (id.includes('custom_fields')) {
                name += ' (Custom Field)'
            }

            return {
                id: item.id,
                name: name
            };
        });
        this.added = _.chain(this.all)
            .filter(function(item) {
                return selectedColumns.indexOf(item.id) >= 0;
            })
            .sortBy(function(item) {
                return selectedColumns.indexOf(item.id);
            })
            .value();
        this.available = _.difference(this.all, this.added);

        this.collection = new Backbone.Collection(this.added);
    },
    onRender: function() {
        var view = this;

        // Animate new items on show
        this.onBeforeItemAdded = function(view) {
            view.on('show', function() {
                view.$el
                    .css('opacity', 0)
                    .show(200, function() {
                        // Trigger scroll animation
                        view.$el.trigger('animation:complete');
                    })
                    .animate(
                        { opacity: 1 },
                        {
                            queue: false,
                            duration: 400
                        }
                    );
            });
        };

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.select,

            text: 'name',
            data: this.available,

            options: {
                placeholder: 'Add column',
                containerCssClass: 'select2-plain select2-link',
                dropdownCssClass: 'column-select-popover popover select2-drop-columns-selection'
            }
        });
        this.listenTo(this.select, 'change', function(item) {
            this.collection.add(item);

            this.select.$el.select2('val', null);
        });

        this.listenTo(this.collection, 'add', function(model) {
            var item = _.find(this.available, function(item) {
                return item.id === model.get('id');
            });
            if (item) {
                this.available.splice(this.available.indexOf(item), 1);
            }
        });

        this.listenTo(this.collection, 'remove', function(model) {
            this.available.push(_.find(this.all, function(item) {
                return item.id === model.get('id');
            }));
            this.available.sort(function(a, b) {
                return view.all.indexOf(a) - view.all.indexOf(b);
            });
        });

        this.ui.list.sortable({
            handle: '.item-handle'
        });
    },
    onStartDragging: function(ev, ui) {
        this.index = this.ui.list.children().index(ui.item);
    },
    onStopDragging: function(ev, ui) {
        var oldIndex = this.index;
        var newIndex = this.ui.list.children().index(ui.item);

        var model = this.collection.at(oldIndex);
        this.collection.remove(model, {silent: true});
        this.collection.add(model, {at: newIndex, silent: true});

        this.index = void 0;
    },
    onShow: function() {
        // Currently, the only way to target the parent .modal element - region.$el
        this.$el.parent().addClass('scrolling-modal');

        // Update scrollbar
        this.scrollbar();
        // Bind scrolling for header and footer shadows
        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.floor(this.$el.height() - this.ui.header.height() - this.ui.footer.height()),
            contentHeight = Math.ceil(container.find('.content-inner:first').outerHeight(true));

        // NOTE: Height needs to be hard-coded in CSS until we can update the lists without just rerendering the
        // whole view - which causes flickering before list is given height below...
        // container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

import React from 'react';
import _ from 'underscore';
import $ from 'jquery';

import styles from './automation.css';

import AutomationGraphView from 'js/react_views/automation/automation-graph';
import AutomationEditView from 'js/react_views/automation/automation-edit';
import AutomationDetailView from 'js/react_views/automation/automation-detail';
import AutomationListingView from 'js/react_views/automation/automation-listing';


let dynamicCount = 0;

class AutomationReactView extends React.Component {
    constructor(props) {
        super(props);

        this.state = { editing: false };

        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleCancelClicked = this.handleCancelClicked.bind(this);
        this.handleAutomationListItemClick = this.handleAutomationListItemClick.bind(this);
        this.updateAutomation = this.updateAutomation.bind(this);
        this.deleteAutomation = this.deleteAutomation.bind(this);
    }

    handleEditClicked() {
        this.setState({ editing: true });
    }

    handleCancelClicked() {
        this.setState({ editing: false });
    }

    handleAutomationListItemClick(id) {
        this.setState({ editing: false });
        this.props.handleAutomationListItemClick(id);
    }

    updateAutomation(data) {
        this.setState({ editing: false });
        this.props.updateAutomation(data);
    }

    deleteAutomation() {
        this.props.deleteAutomation(this.props.automation.id, () => { this.setState({ editing: false }); });
    }

    showPermissions() {
        this.props.showPermissions(this.props.automation);
    }

    adjustGraphZoom() {
        if (this.graph) {
            this.graph.adjustZoom();
        }
    }

    render() {
        let centerView;
        if (this.props.automation) {
            if (this.state.editing) {
                centerView = <AutomationEditView
                        automation={this.props.automation}
                        updateAutomation={this.updateAutomation}
                        deleteAutomation={this.deleteAutomation}
                        showPermissions={this.showPermissions.bind(this)}
                        handleCancelClicked={this.handleCancelClicked}
                    />;
            }
            else {
                centerView = <AutomationDetailView
                        automation={this.props.automation}
                        handleEditClicked={this.handleEditClicked}
                    />;
            }
        }
        return (
            <div className={styles.AutomationContainerView}>
                <AutomationListingView
                    automationList={this.props.automationList}
                    handleItemClick={this.handleAutomationListItemClick}
                    createAutomation={this.props.createAutomation}
                    automationId={this.props.automation && this.props.automation.id}
                />
                {
                    centerView
                }
                {
                    this.props.automation &&
                    <AutomationGraphView
                        ref={(el) => {this.graph = el}}
                        automation={this.props.automation}
                        filterFields={this.props.filterFields}
                        createUpdateFieldsAll={this.props.createUpdateFieldsAll}
                        enableGrid={this.props.enableGrid}
                        removeAutomationNode={this.props.removeAutomationNode}
                        createAutomationNode={this.props.createAutomationNode}
                        updateAutomationNode={this.props.updateAutomationNode}
                        showFilter={this.props.showFilter}
                        removeFilterRule={this.props.removeFilterRule}
                        showDateTime={this.props.showDateTime}
                        showDate={this.props.showDate}
                        DDSearch={this.props.DDSearch}
                    />
                }
            </div>
        );
    }
}

export default AutomationReactView;
import _ from 'underscore'

import NoteActivityItemView from 'js/views/activity/note-item'


export default NoteActivityItemView.extend({
    templateHelpers: function() {
        return _.extend(NoteActivityItemView.prototype.templateHelpers.apply(this, arguments), {
            call: true
        });
    }
});

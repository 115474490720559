import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import AppConfig from 'app/app-config'
import Utilities from 'js/utils/utilities'

/**
 * Extension of date picker to support time selecting functionality.
 *
 * addTimeFields true should be passed to date picker settings
 */
var picker;
var datePickerExtensions = {
    _oldGenerateHTML: $.datepicker._generateHTML,
    _generateHTML: function(inst) {
        this.use24hClock = inst.settings.use24hClock;
        var htmlString = this._oldGenerateHTML(inst);

        if (inst.settings.addTimeFields) {
            htmlString = this._addTimeDoneHTML(htmlString);
        }

        return htmlString;
    },
    _addTimeDoneHTML: function(htmlString) {
        var ampmSelector = this.use24hClock ? "" : "<select class='am-pm-selector'><option value='am'>AM</option><option value='pm'>PM</option></select>";
        var timeSelector = $("<div class='time-selector'>Set Time <input class='hours'>:<input class='minutes'>" + ampmSelector + "</div>"),
            // done button is missing for inline date picker
            done = $('<button type="button" class="ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all" data-handler="hide" data-event="click">Done</button>');

        // we need to wrap inside div to have single html object
        htmlString = "<div>" + htmlString + "</div>";

        var html = $(htmlString);

        html.find('button:last-child').after(done, timeSelector);

        return html.html();
    },
    _oldAttachHandlers: $.datepicker._attachHandlers,
    _attachHandlers: function(inst) {
        this._oldAttachHandlers(inst);

        if (inst.settings.addTimeFields) {
            this.use24hClock = inst.settings.use24hClock;
            this._attachTimeDoneHandlers(inst);
        }
    },
    _attachTimeDoneHandlers: function(inst) {
        picker = this;
        var self = this,
            hoursEl = inst.dpDiv.find('.hours'),
            minutesEl = inst.dpDiv.find('.minutes'),
            ampmEl = inst.dpDiv.find('.am-pm-selector');

        var hoursLowLimit = this.use24hClock ? 0 : 1;
        var hoursHighLimit = this.use24hClock ? 23 : 12;

        function updateHours(val) {
            if (val >= hoursLowLimit && val <= hoursHighLimit) {
                hoursEl.val(val);
                self.hours = val;
                self.hours24 = self.use24hClock ? self.hours : Utilities.convert12hTo24hClock(self.hours, self.am);
            }
            else {
                hoursEl.val(self.hours);
            }
        }

        function formatMinutes(val) {
            return val > 9 ? val : '0' + val;
        }

        function updateMinutes(val) {
            if (val >= 0 && val <= 59) {
                minutesEl.val(formatMinutes(val));
                self.minutes = val;
            }
            else {
                minutesEl.val(formatMinutes(self.minutes));
            }
        }

        function updateAMPM(val) {
            ampmEl.val(val);
            self.am = val === 'am';
            self.hours24 = Utilities.convert12hTo24hClock(self.hours, self.am);
        }

        /**
         * Add listeners to Up/Down keys and scroll
         */
        function addListeners(el, callback, attribute) {
            el.change(function () {
                callback(parseInt(el.val()));
            }).keydown(function (e) {
                // up
                if (e.which === 38) {
                    e.preventDefault();
                    callback(self[attribute] + 1);
                }
                // down
                if (e.which === 40) {
                    e.preventDefault();
                    callback(self[attribute] - 1);
                }
            }).on('remove', function () {
                $('body').off('.changeTimeFields');
            });

            $('body').on('mousewheel.changeTimeFields', function (e) {
                if (el.is(':focus')) {
                    // up
                    if (e.originalEvent.wheelDelta > 0) {
                        callback(self[attribute] + 1);
                    }
                    // down
                    else {
                        callback(self[attribute] - 1);
                    }
                }
            });
        }

        if (!this.hours && this.hours !== 0) {
            this.hours = 11;
        }
        if (!this.minutes && this.minutes !== 0) {
            this.minutes = 0;
        }
        if (_.isUndefined(this.am)) {
            this.am = true;
        }

        updateHours(this.hours);
        updateMinutes(this.minutes);
        updateAMPM(this.am ? 'am' : 'pm');

        addListeners(hoursEl, updateHours, 'hours');
        addListeners(minutesEl, updateMinutes, 'minutes');

        if (!this.use24hClock) {
            ampmEl.on('change', function(ev) {
                updateAMPM(ev.target.value);
            });
        }

        inst.dpDiv.find('.ui-datepicker-close').click(function() {
            inst.settings.parent.hidePicker(
                self._formatDate(inst, inst.currentDay, inst.currentMonth, inst.currentYear),
                inst.currentDay,
                inst.currentMonth,
                inst.currentYear,
                self.hours24 + ':' + formatMinutes(self.minutes),
                self.hours24,
                self.minutes
            );
        });

        this.updateHours = updateHours;
        this.updateMinutes = updateMinutes;
        this.updateAMPM = updateAMPM;
    }
};
$.extend($.datepicker, datePickerExtensions);

// singleton
var instance = null;

export default Marionette.ItemView.extend({
    className: "date-time-picker-container",
    template: Handlebars.compile(''),
    initialize: function() {
        this.use24hClock = AppConfig.getClientPreferenceValue('use24hClock');
    },
    onRender: function() {
        var self = this;

        if (instance) {
            this.close();
            return;
        }
        instance = this;

        // adding as inline datepicker so its not closed when clicking on a date in calendar
        this.$el.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            dateFormat: 'M d, yy', // TODO: i18n
            addTimeFields: true,
            use24hClock: this.use24hClock,
            parent: this
        });

        this.setDateTime(this.options.date, this.options.time);

        this.$el.css(this.options.css);
        $('body').append(this.$el);

        _.defer(function() { // don't trigger when opening
            $('body').on('mousedown.outsideDateTimePicker', function (e) {
                // check if not in date picker and element not removed from dom (happens with some datepicker elements)
                if ($(e.target).closest(self.$el).length === 0 && $(e.target).closest($('body')).length !== 0) {
                    self.hidePicker();
                }
            });
        });
    },
    setDateTime: function(date, time) {
        if (date) {
            this.$el.datepicker("setDate", new Date(date));
        }

        if (time) {
            var hoursAndMinutes = this.options.time.split(':');
            picker.use24hClock = this.use24hClock;

            picker.updateMinutes(parseInt(hoursAndMinutes[1]));

            if (this.use24hClock) {
                picker.updateHours(parseInt(hoursAndMinutes[0]));
            } else {
                var hours12 = Utilities.convert24hTo12hClock(hoursAndMinutes[0]);
                picker.updateHours(hours12.hours);
                picker.updateAMPM(hours12.am ? 'am' : 'pm');
            }
        }
    },
    showPicker: function() {
        this.render();

        // the picker should be fully visible
        const self = this;

        _.defer(function() {
            var availableHeight = app.$el[0].clientHeight;
            var myHeight = self.$el[0].clientHeight;
            var myTop = parseInt(self.$el.css('top'));

            if (myTop + myHeight > availableHeight) {
                self.$el.css('top', availableHeight - myHeight);
            }
        });
    },
    hidePicker: function(date, day, month, year, time, hours, minutes) {
        if (date) {
            var rawDateTime = new Date(date);
            rawDateTime.setHours(hours);
            rawDateTime.setMinutes(minutes);

            if (this.options.altField) {
                this.options.altField.rawDateTime = rawDateTime;
                this.options.altField.val(date + ' ' + time);
            }
        }
        else if (!this.options.noClearOnExit && this.options.altField) {
            this.options.altField.val('');
        }

        if (date) {
            this.trigger('date-time:change', {date: date, day: day, month: month, year: year, time: time, rawDateTime: rawDateTime});
        }
        this.options.altField?.trigger('change');
        this.close();
        instance = null;
        $('body').off('.outsideDateTimePicker');
    }
});

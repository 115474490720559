import _ from 'underscore'

import BaseModel from 'js/models/base'
import PhaseModel from 'js/models/phase'
import PhasesCollection from 'js/collections/phases'
import ActivityModel from 'js/models/activity'
import BaseCollection from 'js/collections/base'
import OrganizationModel from 'js/models/organization'
import OpportunitiesCollection from 'js/collections/opportunities'
import UserModel from 'js/models/user'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import AppConfig from 'app/app-config'


/* OpportunityModel and OpportunitiesCollection are co-dependent
 * require them dynamically and set up whichever one loaded first
 * manually.
 */
var abbreviationValidation = /^[\w\-\.]+$/;

var OpportunityModel = BaseModel.extend({
    name: 'Deal',
    apiType: 'salesseek.opportunities.models.opportunity.Opportunity',
    urlRoot: '/opportunities',
    parsers: {
        'expected_close_date': dateFormat.parseDate,
        'phase_last_changed': dateFormat.parseDate
    },
    constructor: function () {
        if ('id' in arguments[0] && arguments[0].id) {
            this.skipDefaults = true;
        }
        BaseModel.prototype.constructor.apply(this, arguments);
    },
    initialize: function() {
        BaseModel.prototype.initialize.apply(this, arguments);
        this.listenTo(this, 'sync', function(model, resp) {
            if (!resp) {
                vent.trigger('opportunity:delete', model);
            }
        });
    },
    validate: function(attrs) {
        var errors = {};

        if (this.isNew()) {
            if (_.isUndefined(attrs.name)) {
                errors.name = 'Deal name required';
            }
        }

        if (!_.isUndefined(attrs.name)) {
            if (_.isNull(attrs.name)) {
                errors.null_attribute = true;
            }
            else if (attrs.name.length === 0) {
                errors.name = 'One character minimum required';
            }
        }

        if (!_.isUndefined(attrs.abbreviation) && !_.isNull(attrs.abbreviation)) {
            if (attrs.abbreviation.length === 0) {
                errors.abbreviation = 'One character minimum required';
            } else if (!abbreviationValidation.test(attrs.abbreviation)) {
                errors.abbreviation = 'Only alphanumeric characters are allowed';
            }
        }

        if (
            (this.isNew() && _.isUndefined(attrs.organization) && _.isUndefined(attrs.organization_id)) ||
            (_.isNull(attrs.organization) && _.isNull(attrs.organization_id))
        ) {
            errors.organization = "Organization is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    defaults: function() {
        //Don't set defaults if init with an id
        if (this.id) {
            return;
        }

        if (this.skipDefaults) {
            return;
        }

        return _.extend({}, BaseModel.prototype.defaults, {
            name: '',
            comments: '',
            value: 0,
            // default close date is 1 month from now
            expected_close_date: AppConfig.getValue('deals.expected_close_date_default', (function() {
                    var today = new Date();
                    return new Date(today.getFullYear(),today.getMonth()+1,0);
                })()
            )
        });
    },
    organizationName: function() {
        // TODO: Temporary helper for organization name in detail view
        var org = this.get('organization');
        return org ? org.name : null;
    },
    toString: function () {
        return this.get('name');
    }
});
OpportunityModel.fromString = function (string) {
    return new OpportunityModel({name: string});
};
OpportunitiesCollection.prototype.model = OpportunityModel;
export default OpportunityModel;

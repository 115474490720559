import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import vent from 'js/vent'
import app from 'js/app'
import security from 'js/utils/security'
import MessageBox from 'js/views/message_box'
import template from 'templates/base/list_navbar.handlebars'


var ListNavbarView;

ListNavbarView = Marionette.Layout.extend({
    tagName: 'article',
    template: Handlebars.compile(template),
    className: 'list-wrapper',
    ui: {
        listRegion: '.list-container'
    },
    regions: {
        listFiltersRegion: '.list-sorter-container',
        listSorterRegion: '.sorter-container',
        toolbarRegion: '.list-toolbar',
        titleToolbarRegion: '.list-toolbar-top',
        listRegion: '.list-container'
    },
    events: {
        'click .list-header .tabs li': 'clickTab',
        'click .expand-list': 'expandList',
        'click .collapse-list': 'collapseList',
        'click .active .toggle-sorter': function() {
            this.listSorterRegion.currentView.show();
        },
        'click .toggle-filters': 'toggleFilters'
    },
    templateHelpers: function() {
        return {
            collectionTotal: 5,
            section: 'Hey',
            title: this.title,
            showTabs: this.showTabs,
            collapse: this.collapse,
            sortable: this.sortable,
            filterable: this.filterable,
            tabs: this.tabs,
            iconClass: this.iconClass
        };
    },
    initialize: function(options) {
        var defaultOptions, extendedOptions, argOptions;

        defaultOptions = {
            parent: null,
            title: null,
            topView: this,
            showTabs: true,
            collapse: false,
            sortable: false,
            filterable: false,
            tabs: [],
            iconClass: null,
            initialTabId: null,
            collectionData: {}
        };

        // Assign options in this order: arg > extended > defaults
        argOptions = _.pick(options, _.keys(defaultOptions));
        extendedOptions = _.pick(this, _.keys(defaultOptions));
        _.defaults(argOptions, extendedOptions, defaultOptions);
        _.extend(this, argOptions);

        if (this.parent) {
            this.listenTo(this.parent, 'item:select', function(model) {
                this.trigger('item:select', model);
            });
        }

        // Tabs need to be cloned to not affect future instances
        this.tabs = _.map(this.tabs, _.clone);
    },
    initTab: function(tab, page) {
        var view = this,
            options = {
                parent: this,
                collectionPage: page,
                collectionData: this.collectionData
            },
            triggerPrefix = this.showTabs ? tab.id + ':' : '';

        tab.view = _.isFunction(tab.view) ? tab.view.call(view, options) : tab.view;
        tab.toolbarView = this.createToolbar(tab, 'toolbarView');
        tab.titleToolbarView = this.createToolbar(tab, 'titleToolbarView');
        tab.listSorterView = _.result(tab, 'listSorterView');
        tab.listFiltersView = _.result(tab, 'listFiltersView');

        // Listen events
        tab.view.on(
            'followLink',
            function(model, link) {
                view.trigger(triggerPrefix + 'followLink', model, link);
            },
            view
        );

        tab.view.on(
            'showItem',
            function(model, edit, preview) {
                view.trigger(triggerPrefix + 'showItem', model, edit, preview);
            },
            view
        );

        tab.view.on(
            'refresh',
            function(model, link) {
                view.trigger(triggerPrefix + 'refresh', model, link);
            },
            view
        );

        tab.view.on('show:related', function(model, type) {
            this.trigger('show:related', model, type);
        }, view);

        var toolbars = [];
        if (tab.toolbarView) {
            toolbars.push(tab.toolbarView);
        }
        if (tab.titleToolbarView) {
            toolbars.push(tab.titleToolbarView);
        }
        _.each(toolbars, function(toolbar) {
            view.listenTo(toolbar, 'toolbar:new', function() {
                view.trigger(triggerPrefix + 'toolbar:new', tab);
            });

            view.listenTo(
                toolbar,
                'toolbar:delete',
                function(sel) {
                    view.trigger(triggerPrefix + 'toolbar:delete', sel);
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:selectAllPage',
                function() {
                    if (this.listRegion) {
                        this.listRegion.currentView.selectAllPage();
                    }
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:deselectAllPage',
                function() {
                    if (this.listRegion) {
                        this.listRegion.currentView.deselectAllPage();
                    }
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:add-item',
                function(model) {
                    if (this.listRegion) {
                        view.trigger(triggerPrefix + 'toolbar:add-item', model);
                    }
                    if (this.toolbarRegion) {
                        this.toolbarRegion.currentView.hideSubTools();
                    }
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:aggregate',
                function(data) {
                    view.trigger(triggerPrefix + 'toolbar:aggregate', data);
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:assign',
                function(user, sel) {
                    view.trigger(triggerPrefix + 'toolbar:assign', user, sel);
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:unassign',
                function(sel) {
                    view.trigger(triggerPrefix + 'toolbar:unassign', sel);
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:remove',
                function(sel) {
                    view.trigger(triggerPrefix + 'toolbar:remove', sel);
                }
            );

            view.listenTo(
                toolbar,
                'toolbar:customAction',
                function(sel) {
                    view.trigger(triggerPrefix + 'toolbar:customAction', sel);
                }
            );
        });

        if (tab.listFiltersView) {
            view.listenTo(
                tab.listFiltersView,
                'filter',
                function(options) {
                    if (tab.view) {
                        tab.view.filterCollection(options);
                        tab.view.fetchCollection();
                    }
                }
            );
        }
    },
    createToolbar: function(tab, toolbar) {
        if (tab[toolbar]) {
            var val = tab[toolbar];

            if (_.isFunction(val)) {
                return val.call(this, tab.view.collection);
            }

            return val;
        }

        return null;
    },
    refresh: function() {
        if (this.listRegion) {
            this.listRegion.currentView.refresh();
        }
    },
    onShow: function() {
        if (this.tabs.length > 0) {
            this.showTab(this.initialTabId || this.tabs[0].id, this.page);
        }

        if (this.sortable) {
            this.$el.addClass('sortable');
        }
    },
    clickTab: function(ev) {
        var tabId = $(ev.currentTarget).attr('tab-id');

        ev.preventDefault();

        // Allow click on active tab to refetch content
        if ($(ev.currentTarget).hasClass('active') && this.listRegion) {
            switch(tabId) {
                case 'activities':
                    this.listRegion.currentView.refresh();
                    break;

                case 'tasks':
                    this.listRegion.currentView.taskListContainer.fetchAll();
                    break;

                case 'leads':
                    this.listRegion.currentView.fetchCollection();
                    break;
            }

            return;
        }

        this.showTab(tabId);
    },
    showTab: function(tabId, page) {
        var tab, currentEl, tabChange, self = this;

        tab = _.find(this.tabs, function(d) {
            return d.id === tabId;
        });

        tabChange = this.currentTab && this.currentTab !== tab;

        //Instantiate this view
        if (tab) {
            tab=_.clone(tab);
            this.initTab(tab, page);
        }

        // Show toolbar
        if ( this.titleToolbarRegion ) {
            if ( tab && tab.titleToolbarView ) {
                this.titleToolbarRegion.show(tab.titleToolbarView);
            } else {
                this.titleToolbarRegion.reset();
            }
        }
        if ( this.toolbarRegion ) {
            if ( tab && tab.toolbarView ) {
                this.toolbarRegion.show(tab.toolbarView);
            } else {
                this.toolbarRegion.reset();
            }
        }

        // Show list-sorter
        if ( this.listSorterRegion ) {
            if ( tab && tab.listSorterView ) {
                this.listSorterRegion.show(tab.listSorterView);
            } else {
                this.listSorterRegion.reset();
            }
        }

        // Show list-filters
        if ( this.listFiltersRegion ) {
            if ( tab && tab.listFiltersView ) {
                this.listFiltersRegion.show(tab.listFiltersView);
                this.ui.listRegion.addClass('has-filter');
            } else {
                this.listFiltersRegion.reset();
                this.ui.listRegion.removeClass('has-filter');
            }
        }

        // Show view
        this.showView(tab);

        _.defer(function() {
            // Highlight selected tab
            currentEl = self.$el.find('[tab-id="' + tabId + '"]');
            self.$el.find('.tabs li').not(currentEl).removeClass('active');
            currentEl.addClass('active');
        });

        // Notify
        if (tabChange) {
            this.onChangeTab(tabId);
        }
    },
    showView: function(tab) {
        this.currentTab = tab;

        if ( this.listRegion ) {
            if ( tab && tab.view ) {
                this.ui.listRegion.toggleClass('no-toolbar', !tab.toolbarView);
                this.listRegion.show(tab.view);

                this.listenTo(tab.view, 'selectionChange', function(sel) {
                    this.onSelectionChange(tab, sel);
                });
                this.listenTo(tab.view, 'collectionCountChange', function(count) {
                    this.onCollectionCountChange(tab, count);
                });
                this.listenTo(tab.view, 'task:count:change', function(count) {
                    this.$el.find('.task-counter').text(count || '');
                }, this);
            } else {
                this.listRegion.reset();
            }
        }
    },
    onSelectionChange: function(tab, sel) {
        if ( tab.toolbarView ) {
            tab.toolbarView.setSelection(sel);
        }
        if ( tab.titleToolbarView ) {
            tab.titleToolbarView.setSelection(sel);
        }
    },
    onCollectionCountChange: function(tab, count) {
        if ( tab.toolbarView ) {
            tab.toolbarView.setCollectionCount(count);
        }
        if ( tab.titleToolbarView ) {
            tab.titleToolbarView.setCollectionCount(count);
        }
    },
    toggleFilters: function() {
        if (this.$el.hasClass('list-filters-visible')) {
            this.$el.removeClass('list-filters-visible');
            this.listFiltersRegion.currentView.hideSelf();
        } else {
            this.$el.addClass('list-filters-visible');
            this.listFiltersRegion.currentView.show();
        }
    },
    expandList: function() {
        vent.trigger('list:expand');
    },
    collapseList: function() {
        vent.trigger('list:toggle-collapse');
    },
    onChangeTab: function(tabId) {
        if (this.options.preferencesKey) {
            this.storeTab(tabId);
        }
    },
    storeTab: function(tabId) {
        app.user.updatePreference(this.options.preferencesKey, tabId);
    },
    deleteItems: function(items, override, warning) {
        // have permission to delete items?
        var collection = this.listRegion.currentView.collection;
        var itemsToDelete  = items.length; // number of items I want to delete
        var permittedItems = 0; // number of items I have permission to delete

        if (warning) {
            warning = ['<div class="alert alert-danger"><i class="icon-warning"></i><p class="text">',
                warning + '</p></div>'].join('');
        }
        else {
            warning = '';
        }

        // ...
        _.each( items, function( item ) {
                        if ( override || security.checkPermission( 'delete', item.model ) ) {
                            ++permittedItems;
                        }
                    } );

        // 3 cases:
        var canDelete = true;
        var mbContent = {
                message: '',
                icon: 'icon-trashcan',
                itemsLength: permittedItems
            };

        if ( permittedItems === 0 ) { // 1. can't delete anything
            canDelete = false;
            mbContent.message = "You don't have permission to delete selected item" +
                (itemsToDelete > 1 ? 's' : '');
            mbContent.icon = 'icon-blocked';
            mbContent.itemsLength = null;
            MessageBox.showOk( mbContent, this );
        }
        else if ( permittedItems !== itemsToDelete ) { // 2. can delete some items
            mbContent.accept_is_negative = true;
            mbContent.message = '<p>You have permission to delete ' + permittedItems +
                ' of ' + itemsToDelete + ' selected items.</p>' +
                '<strong class="cta">Do you want to continue?</strong>';
            mbContent.message += warning;
        }
        else { // 3. can delete all items
            mbContent.accept_is_negative = true;
            mbContent.message = 'Are you sure you want to <strong>permanently</strong> delete ' +
                permittedItems + ' item' + (permittedItems > 1 ? 's' : '') + '?';
            mbContent.message += warning;
        }

        // ...
        if ( canDelete ) {
            MessageBox.showYesNo( mbContent, this,
                function() { // Yes
                    _.each( items, function( item ) {
                        if ( override || security.checkPermission( 'delete', item.model ) ) {
                            collection.remove(item.model);
                        }
                    } );
                    collection.save();
                } );
        }
    },
    getParams: function() {
        var params = {};
        params.page = this.currentTab.view.collection.collectionPage;

        return params;
    }
});

export default ListNavbarView;

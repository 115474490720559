import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import Utilities from 'js/utils/utilities'
import editUserProfileTemplate from 'templates/edit-user-profile.handlebars'


/**
 * Insert yours unreleased lab features here. Format:
 * {
 *    id: feature id i.e. SAL-xxxx
 *    title: feature title
 *    description: feature description
 * }
}
*/
var labUnreleasedFeatures = [
    //{
    //    id: "SAL-2800",
    //    title: "New campaign listing",
    //    description: "This will show campaign section in main menu and will list campaigns in sortable list."
    //},
    {
        id: 'INT-52',
        title: 'SageLive Integration',
        description: 'Allows the user to connect their SageLive account, pulling the Invoices into SalesSeek.'
    },
    {
        id: 'INT-53',
        title: '8X8 Telephony',
        description: 'Allows the 8x8 integration to detect this account has this enabled'
    },
    {
        id: 'SAL-4002',
        title: 'Disable React-based Individual, Organization and Deal Detail Views',
        description: 'Uses the old MarionetteJS detail views'
    },
    {
        id: 'SAL-3826',
        title: 'Campaign Table View',
        description: 'Uses the new, experimental React-based table view for campaigns'
    },
    {
        id: 'INT-72',
        title: 'FreshDesk Integration',
        description: 'Allows the user to connect their FreshDesk account, pulling the tickets into SalesSeek'
    },
    {
        id: 'SAL-3621',
        title: 'Docusign for deal records',
        description: 'Allows the user to create Docusign documents using a deal'
    },
    {
        id: 'SAL-3868',
        title: 'Filter by integrations',
        description: 'Allows the user to filter activities by integrations (only xero filter is available)'
    },
    {
        id: 'SAL-3844',
        title: 'Automations 2 Grid',
        description: 'Enable grid on automation layout'
    },
    {
        id: 'SAL-4026',
        title: 'Disable Automations 2',
        description: 'Disable automations 2 and show automations 1'
    },
    {
        id: 'SAL-4063',
        title: 'Enable groups for campaigns and individual custom fields as sender',
        description: 'Campaigns can be sent to static and smart groups, not only to mailing lists'
    },
    {
        id: 'SAL-4299',
        title: 'Checklists',
        description: 'The user can use checklists'
    },
    {
        id: 'SAL-4382',
        title: 'New template builder (DEPRECATED)',
        description: 'The user can use the new template builder'
    },
    {
        id: 'SAL-4873',
        title: 'SEE (DEPRECATED)',
        description: 'Use SEE as new template builder'
    },
    {
        id: 'SAL-4398',
        title: 'Phase Gates',
        description: 'The user can apply the phase gates'
    },
    {
        id: 'SAL-4851',
        title: 'Phone Number Validator',
        description: 'Validates phone numbers'
    },
    {
        id: 'SAL-4868',
        title: 'Deals calendar',
        description: 'Enable deals calendar view'
    },
    {
        id: 'SAL-5309',
        title: 'Static group from Campaign Insights',
        description: 'Enable the static group creation from campaign stats'
    },
    {
        id: 'SAL-5741',
        title: 'New Campaigns Section',
        description: 'Enable the new campaigns section'
    },
    {
        id: 'SAL-5647',
        title: 'New filters for issues',
        description: 'Enable the new filters for the issues section'
    }
];


// ...
export default Marionette.Layout.extend({
    className: 'user-settings tab-content',
    template: Handlebars.compile(editUserProfileTemplate),
    ui: {
        currentPassword: '#current-password',
        newPassword: '#new-password',
        confirmNewPassword: '#confirm-new-password',
        errorContainer: '.error',
        ical: '#ical-url',
        instantNotifications: '#send-notifications',
        signatureEditorContainer: '.signature-editor',
        featureLabTab: '#feature-lab-tab',
        userName: '#user-name',
        userEmail: '#user-email',
        settings2FA: '.2fa-settings',
        settingsPhoneNumber: '.phone-number-settigs',
        checkboxSms: '#2fa-sms',
        userPhoneNumber: '.user-phone-number'
    },
    events: (function() {
        var attrs = {};
        return {
            'change input[type="text"]': function(ev) {
                var input = $(ev.target);
                input.val($.trim(input.val()));
            },
            'change #user-name': function(ev) {
                attrs.name = $(ev.target).val();
            },
            'change #user-email': function(ev) {
                attrs.email_address = $(ev.target).val();
            },
            'change .user-phone-number': function(ev) {
                attrs.phone_number = Utilities.getNormalizedPhoneNumber($(ev.target).val());
            },
            'change .2fa-sms': function(ev) {
                var bySms = ev.target.checked;
                attrs.otp_method = bySms ? 'sms' : 'email';
                this.ui.settingsPhoneNumber.toggleClass('hidden', !bySms);
            },
            'click #save-profile': function() {
                this.hideErrorMessages();

                if (this.ui.checkboxSms.is(':checked')) {
                    if (!this.ui.userPhoneNumber.val()) {
                        this.showErrorMessage(this.ui.userPhoneNumber, 'Phone number is empty');
                        return;
                    }
                }

                var self = this;

                this.listenTo(app.user, 'invalid', function(model, errors) {
                    if (errors.name_undefined || errors.name_too_short) {
                        self.showErrorMessage(self.ui.userName, errors.name_undefined || errors.name_too_short);
                    }

                    if (errors.email_undefined || errors.email_invalid) {
                        self.showErrorMessage(self.ui.userEmail, errors.email_undefined || errors.email_invalid);
                    }
                });

                var preName = app.user.get('name');
                var preEmail = app.user.get('email');
                var prePhoneNumber = app.user.get('phone_number');

                app.user.save(attrs, {
                    patch: true,
                    alert: false,
                    validate: true,
                    success: function(model, attributes, settings) {
                        vent.trigger('alert:show', { type: 'save', model: model, xhr: settings.xhr });
                        self.trigger('close');
                    },
                    error: function(model, xhr) {
                        model.set('name', preName);
                        model.set('email', preEmail);
                        model.set('phone_number', prePhoneNumber);

                        var detail = JSON.parse(xhr.responseText).detail;
                        var errors = {
                            UserDuplicateNameError: {
                                ui: self.ui.userName,
                                message: 'User with this name already exists'
                            },
                            UserDuplicateEmailError: {
                                ui: self.ui.userEmail,
                                message: 'User with this email address already exists'
                            },
                            InvalidPhoneNumberValueError: {
                                ui: self.ui.userPhoneNumber,
                                message: `${self.ui.userPhoneNumber.val()} is not a valid phone number`
                            }
                        };

                        if (detail.exception in errors) {
                            self.showErrorMessage(errors[detail.exception].ui, errors[detail.exception].message);
                        }
                        else {
                            self.showErrorMessage(self.ui.userName, detail.message);
                        }
                    }
                });
            },
            'click #save-password': function() {
                var self = this;

                this.hideErrorMessages();

                if (this.ui.newPassword.val() !== this.ui.confirmNewPassword.val()) {
                    this.showErrorMessage(this.ui.confirmNewPassword, 'Password doesn\'t match');
                    return;
                }

                $.post('/password', JSON.stringify({
                        'old_password': this.ui.currentPassword.val(),
                        'new_password': this.ui.newPassword.val()
                    })
                )
                .done(function(response) {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Password successfully updated!',
                                classes: 'success',
                                timer: 3000
                            };
                        }
                    });
                })
                .fail(function(response) {
                    self.showErrorMessage(self.ui.currentPassword, 'Invalid password');
                })

            },
            'click #ical-url': function(ev) {
                $(ev.currentTarget).focus().select();
            },
            'click .tabs a': function(ev) {
                ev.preventDefault();

                var target = $(ev.currentTarget);
                this.viewId = target.attr('view');
                target.tab('show');

                _.defer(function() {
                    vent.trigger('AppContent:contentChange');
                });
            },
            'click .close': function() {
                this.trigger('close');
            },
            'click .store-notification': function(ev) {
                var settings = {},
                    target = $(ev.target);
                settings[target.attr('kind')] = target.is(":checked");
                app.user.save({ notification_settings: settings }, { patch: true });
            },
            'click #save-signature': function() {
                var view = this;
                app.user.save({
                    email_signature: this.signatureEditor.getContent()
                }, {
                    patch: true,
                    success: function() {
                        view.trigger('close');
                    }
                });
            },
            'click #feature-lab': function() {
                if (this.ui.featureLabTab.hasClass('active')) {
                    return;
                }

                var tabHeader = this.$el.find( '.tabs .active a');
                var tab = this.$el.find(tabHeader.attr('href'));
                tabHeader.parent().removeClass('active');
                tab.removeClass('active');

                this.ui.featureLabTab.addClass('active');
            },
            'click .store-feature': function(ev) {
                var target = $(ev.target);
                var labFlags = app.user.get('preferences').lab_flags || [];

                // only saves active features
                var activeIds = _.pluck(labUnreleasedFeatures, 'id');

                labFlags = _.filter(labFlags, function(f) {
                    return _.contains(activeIds, f);
                });

                var id = target.attr('id');

                if (target.is(':checked')) {
                    if (!_.contains(labFlags, id)) {
                        labFlags.push(id);
                    }
                }
                else {
                    labFlags = _.without(labFlags, id);
                }

                app.user.updatePreference('lab_flags', labFlags);
            }
        };
    })(),
    templateHelpers: function() {
        var userName, isSalesseekUser;
        var labFeatures = [];
        var email = app.user.get('email_address');
        var externalUser = false;

        if (app.user) {
            userName = app.user.get('name');
            isSalesseekUser = (email.indexOf('@salesseek.net') !== -1) ||
                              (email.indexOf('@salesseek.com') !== -1) ||
                              (email.indexOf('@salesseek.biz') !== -1) ||
                              (email.indexOf('@salesseek.info') !== -1) ||
                              (email.indexOf('@salesseek.co.uk') !== -1) ||
                              (email.indexOf('@aptui.com') !== -1) ||
                              (email.indexOf('@intentcrm.net') !== -1);
            externalUser =  app.user.get('external_user');
        }

        if (isSalesseekUser) {
            var labFlags = app.user.get('preferences').lab_flags || [];

            _.each(labUnreleasedFeatures, function(f) {
                labFeatures.push(_.extend(f, {
                    checked: _.contains(labFlags, f.id) || false
                }));
            });
        }

        return {
            'userName': userName,
            'email': email,
            'isSalesseekUser': isSalesseekUser,
            'labFeatures': labFeatures,
            'externalUser': externalUser,
        };
    },
    initialize: function(options) {
        this.viewId = options.viewId;
        this.signatureEditor = null;
    },
    onRender: function() {
        var self = this;
        this.ui.ical.text(app.options.apiUrl + app.user.get('ical_url'));
        _.each(app.user.get('notification_settings'), function(value, key) {
            self.$el.find("[kind='" + key + "']").prop('checked', value === 'True');
        });

        if (!this.signatureEditor) {
            _.defer(function() {
                self.ui.signatureEditorContainer.tinymce({
                    promotion: false,
                    branding: false,
                    license_key: 'gpl',
                    skin: 'oxide',
                    plugins: 'link lists',
                    menubar: '',
                    toolbar: 'bold italic underline bullist numlist link',
                    height: 200,
                    resize: false,
                    statusbar: false,
                    oninit: function() {
                        self.signatureEditor = this.activeEditor;
                        this.activeEditor.setContent(app.user.get('email_signature') || '');
                    }
                });
            });
        }

        $.get('/clients/1/otp_settings', function(otpSettings) {
            if (otpSettings.otp_enabled) {
                var user = app.user;
                self.ui.settings2FA.removeClass('hidden');
                self.ui.userPhoneNumber.val(user.get('phone_number'));
                if (user.get('otp_method') === 'sms') {
                    self.ui.checkboxSms.prop('checked', true);
                    self.ui.settingsPhoneNumber.removeClass('hidden');
                }
            }
        });
    },
    onShow: function() {
        this.$el.parent().attr('id', 'my-settings-modal');

        if (this.viewId) {
            this.$el.find('.tabs a[view="' + this.viewId + '"]').click();
        }
        vent.trigger('userSettings:on:show');
    },
    onClose: function() {
        vent.trigger('userSettings:on:close');

        if (this.signatureEditor) {
            this.signatureEditor.remove();
        }
    },
    getUrl: function() {
        if (this.viewId) {
            return this.viewId;
        } else {
            return 'user';
        }
    },
    hideErrorMessages: function() {
        this.$el.find('.validation_error').removeClass('validation_error invalid');
        this.$el.find('.error-message').hide();
    },
    showErrorMessage: function(uiElement, message) {
        uiElement.addClass('validation_error');
        uiElement.nextAll('.error-message').text(message).addClass('invalid').show();
    }
});

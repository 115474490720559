import _ from 'underscore'

import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import app from 'js/app'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import GroupElementsCollection from 'js/collections/group_elements'
import OrganizationFilterModel from 'js/models/organization_filter'
import OrganizationItemView from 'js/views/organizations/item'
import FilterQuickOptionsOrganizations from 'js/views/filters/quick-options-organizations'
import NoCollectionView from 'js/views/base/no_collection'
import vent from 'js/vent'
import TableBodyContainerView from 'js/views/base/table-body-container-view'


var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    templateHelpers: function() {
        const showDemographicMapLayout = this.options?.demographicMap?.staticPins;

        if (showDemographicMapLayout) {
            var clientPreferences = app.user.get('client').preferences || {};

            if (!clientPreferences.demographic_maps) {
                showDemographicMapLayout = false;
            } else {
                const mapInfo = JSON.parse(clientPreferences.demographic_maps).organizations;

                if (!mapInfo || !mapInfo.address_builder) {
                    showDemographicMapLayout = false;
                }
            }
        }

        return {
            id: this.parent.getElementType(),
            name: TextManager.parseText('${ID_ORGANIZATION, capitalize, plural}'),
            hideInfo: true,
            disableLayoutSelection: !showDemographicMapLayout,
            enableDemographicMapLayout: showDemographicMapLayout
        };
    },
    groupType: "Organization",
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new OrganizationFilterModel({id: this.filter_id});
        this.collection = new GroupElementsCollection(null, {elementType: 'organizations'});
        this.collection.toBeLoaded = true;
        this.quickFilter = new FilterQuickOptionsOrganizations();

        // this should be here to copy fetchOptions, as it gets stored incorrectly in Class otherwise
        this.fetchOptions = _.extend({}, this.fetchOptions);

        if (options.owner_id) {
            this.fetchOptions.owner_id = options.owner_id;
        }

        if (options.tag_ids) {
            this.fetchOptions.tag_ids = options.tag_ids;
        }

        if (options.funnel_ids) {
            this.fetchOptions.funnel_ids = options.funnel_ids;
        }

        this.listenTo(vent, 'organization:delete', function(model) {
            this.collection.remove(model);
        });
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            customFields: this.customFields,
            type: this.options.type,
            elementType: this.options.elementType,
            filter: this.filter,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                newButton: true
            }
        });
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:organization:show', {model: model});
    },
    newItem: function() {
        this.options.parent.trigger('push-view:organization:new', {});
    }
});

export default IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'organizations';
    },
    getUrl: function() {
        if (this.options.owner_id) {
            return 'myOrganizations';
        }
        else {
            return 'organizations';
        }
    }
});

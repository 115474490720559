import _ from 'underscore'
import d3 from 'd3'
import $ from 'jquery'
import Handlebars from 'handlebars'
import guid from 'js/utils/guid'


import app from 'js/app'
import dateFormat from 'js/utils/date-format'
import AppConfig from 'app/app-config'
import CustomFieldModel from 'js/models/custom_field.js'
import popoverTemplate from 'templates/opportunities/bubble-popover.handlebars'
import issuePopoverTemplate from 'templates/opportunities/issue-bubble-popover.handlebars'
import extendedPopoverTemplate from 'templates/opportunities/bubble-extended-popover.handlebars'
import popoverPhaseTemplate from 'templates/opportunities/phase-popover.handlebars'
import Currency from 'js/utils/currency'
import D3Utilities from 'js/d3/utilities'

var StatusColor = {
    go: '#60d937',
    assign: '#ff9a01',
    attention: '#ff9a01', // some systems use attention instead assign
    issue: '#ed230d'
};

var StatusBarColor = '#0838bf';

function colorScheme(colorkey, deal, extraParam, customColorKeys) {
    switch(colorkey) {
        case 'none':
            return 'none';

        case 'closedate': {
            var daysout = daysDifference(
                    deal.get('expected_close_date'),
                    extraParam
                );

            if (daysout <= 0) {
                return 'close-after';
            }

            return 'close-before';
        }

        case 'activity': {
            var daysout =  deal.get('latest_activity_in_days');

            if (!_.isUndefined(daysout) && !_.isNull(daysout)) {
                if (daysout < 2) {
                    return 'activity-last-day';
                } else if (daysout < 6) {
                    return 'activity-last-5-days';
                } else if (daysout < 15) {
                    return 'activity-last-2-weeks';
                } else if (daysout < 30) {
                    return 'activity-last-month';
                }

                return 'activity-more-1-month';
            }

            return 'activity-more-1-month';
        }

        case 'forecast': {
            var key,
                colorClasses = {
                    'none': 'forecast-none',
                    'none_upside': 'forecast-none-upside',
                    'committed': 'forecast-committed',
                    'committed_downside': 'forecast-committed-downside'
                };

            if (_.size(extraParam)) {
                key = extraParam[deal.get('id')];
            }
            else {
                key = deal.get('status')
            }

            return colorClasses[key] || 'forecast-none';
        }

        case 'goAssignIssue': {
            var status = deal.get('snapshot_funnel_drops_value');
            return status ? `go-assign-issue-${status.toLowerCase()}` : '';
        }

        default:
            if (colorkey.indexOf('custom_colorkey') === 0) {
                var entry = (customColorKeys || []).find(ck => ck.dropdown_id === extraParam);
                var option = null;

                if (entry?.is_system_field) {
                    switch(entry.dropdown_id) {
                        case 'phase.name':
                            option = deal.get('phase').name.toLowerCase();
                            break;
                    }
                } else {
                    option = deal.get('custom_field.' + extraParam);
                    var cfGroupPages = deal.get('custom_fields.' + extraParam);

                    if (cfGroupPages){
                        option = app.globalData.colorkeysInfo && app.globalData.colorkeysInfo[extraParam] && app.globalData.colorkeysInfo[extraParam].find(item => item.value === cfGroupPages).id || null;
                    }
                }

                if (option) {
                    if (entry?.colours_ex) {
                        for (const colorEx of entry.colours_ex) {
                            if (colorEx.option_id.indexOf(option) !== -1) {
                                return `custom-color-${colorEx.id}`;
                            }
                        }
                    }

                    return `custom-color-${option}`;
                }
            }

            return 'none';
    }
}

function filterByMyDataId(id) {
    return function() {
        var d = d3.select(this).property('mydata');

        if (typeof(d) !== "undefined" && d !== null) {
            if ( 'id' in d ) {
                return d.id === id;
            }
        }
        return false;
    };
}

function daysDifference(closedate, periodend) {
    /**
     * Given the close date of a deal and the date of the end of the
     * current quarter, return the difference between the two dates in
     * days. Positive result means closing within the quarter, negative
     * means without.
     */

    if (!_.isDate(closedate)) {
        closedate = new Date(closedate);
    }

    closedate = closedate || periodend;
    return ((periodend.getTime() - closedate.getTime()) /
            (1000 * 60 * 60 * 24));
}

function getPhaseTime(deal, dt) {
    return daysDifference(deal.model.get('phase_last_changed'), dt);
}

function funnel() {
    var clientPreferences = app.user.get('client').preferences || {};
    var customColorKeys = null;

    if (clientPreferences.custom_colorkeys) {
        customColorKeys = JSON.parse(clientPreferences.custom_colorkeys);
    }

    var id = 'funnel',
        x = 0,
        y = 0,
        width = 400,
        height = 300,
        type = 'funnel',
        deals = [],
        dealAbbrvFontSize = 11,
        dealsStatus = {},
        phases = [],
        periods = [],
        funnels = [],
        activeFunnel = null,
        selectedDropable = {id: null},
        current_period_end = null,
        colorkey = 'closedate',
        datetime = new Date(),
        phaseWonId = 0,
        phaseLostId = 0,
        sizefactor = 20,
        highlight = null,
        draggingLead = false,
        dragEnabled = true,
        isDragging = false,
        transitionDuration = 500,
        transitionDurationLong = 1000,
        onTransition = false,
        margin = {left: 14, right: 24, top: 78, bottom: 49},
        tooltipHeight = 120,
        innerRect = null,
        funnelsRect = null,
        containerBoxes = null,
        processedFunnels = null,
        wonPhase = [],
        lostPhase = [],
        svg = null,
        funnelsd3 = null,
        zoomedPhaseId = null,
        parent = null,
        zooming = false,
        mousePosition = [0, 0],
        maxNumberOfFunnelsVisible = 5,
        firstFunnelVisibleIdx = 0,
        funnelsListArrows = [],
        wonLostPhasesVisible = true,
        dealsTotalValueVisible = true,
        dashboardView = null,
        section = null;

        function valueradius(value, maxValue, maxRadius, averageValue) {
        /**
         * Given the value of a deal, return the radius of the circle.
         */

        /*
            - Show me your math!
            - ...oK:

            k * (log(maxValue) * log10E) = maxRadius
            k = maxRadius / [log(maxValue) * log10E]

            radius = k * log(value) * log10E
            radius = maxRadius * log(value) * log10E /
                        [log(maxValue) * log10E]
            radius = maxRadius * log(value) / log(maxValue)
        */
        var sizefactor = Math.max(averageValue / 10, 0.1);
        var radius = maxRadius * Math.log(Math.max(value, sizefactor + 0.1) / sizefactor) /
            Math.log(Math.max(maxValue, sizefactor + 0.1) / sizefactor);
        radius = Math.max(radius, maxRadius/3);

        return radius;
    }

    function calculateDealsValues(deals) {
        // calculate deal with bigger deal value (set min value to 1 to avoid NaN's)
        var maxValue = 1;
        var averageValue = 0;
        var maxZoomedValue = 1;
        var averageZoomedValue = 0;
        var numDealsBigger0 = 0;
        var numDealsZoomedBigger0 = 0;

        _.each(deals, function(d) {
            var bval = d.model.get('bubble_representation_value');

            if (zoomedPhaseId !== d.phase.id) {
                if (bval > maxValue) {
                    maxValue = bval;
                }

                if (bval > 0) {
                    averageValue += bval;
                    ++numDealsBigger0;
                }
            }
            else {
                if (bval > maxZoomedValue) {
                    maxZoomedValue = bval;
                }

                if (bval > 0) {
                    averageZoomedValue += bval;
                    ++numDealsZoomedBigger0;
                }
            }
        });

        return {
            averageValue: averageValue / (numDealsBigger0 || 1),
            averageZoomedValue: averageZoomedValue / (numDealsZoomedBigger0 || 1),
            maxValue: maxValue,
            maxZoomedValue: maxZoomedValue
        };
    }

    function getDealFunnel(dealId) {
        if (!dealId || (deals.length === 0)) {
            return null;
        }

        for (var i = 0; i < deals.length; ++i) {
            var d = deals[i];

            if (d.get('id') === dealId) {
                var funnel = d.get('funnel');
                return funnel ? funnel.id : d.get('funnel_id');
            }
        }

        return null;
    }

    function processPhases() {
        var phasesArray = [];
        _.each(phases, function(d) {
            var funnel = d.get('funnel');
            var fid = funnel ? funnel.id : d.get('funnel_id');
            if (!fid || (fid == activeFunnel || activeFunnel.includes(fid))) {
                var idx = d.get('order');
                var pt = d.get('phase_type');

                if (pt === 'user') {
                    phasesArray[idx] = {
                        id: d.get('id'),
                        abbreviation: d.get('abbreviation'),
                        name: d.get('name'),
                        order: idx,
                        dealsCount: 0,
                        dealsValue: 0,
                        phase_type: d.get('phase_type'),
                        hint: d.get('hint')
                    };
                }
                else if (pt === 'won') {
                    phaseWonId = d.get('id');
                }
                else if (pt === 'lost') {
                    phaseLostId = d.get('id');
                }
            }
        });

        return phasesArray;
    }

    function processFunnels() {
        if (funnels.length < 2) {
            return [];
        }

        var funnelsArray = [];

        for (var i = 0; i < Math.min(maxNumberOfFunnelsVisible, funnels.length); ++i) {
            var funnel = funnels[i + firstFunnelVisibleIdx];

            funnelsArray.push({
                funnel: {
                    id: funnel.get('id'),
                    name: funnel.get('name')
                }
            });
        }

        return funnelsArray;
    }

    function processContainerBoxes(processedPhases) {
        var boxesArray;

        boxesArray = _.map(processedPhases, function(d) {
            return {phase:d};
        });

        return boxesArray;
    }

    function processDeals(phases) {
        var processedDeals,
            temp;

        current_period_end = currentPeriodEnd();

        var hideDealsBubblesWithValueInRange = AppConfig.getValue('hideDealsBubblesWithValueInRange', null);
        temp = _.filter(deals, function(d){
            var funnel = d.get('funnel');
            var fid = funnel ? funnel.id : d.get('funnel_id');
            var value = d.get('value');

            // skip won and lost deals (phase.funnel_id == null) and deals on not active funnel
            return d.get('phase').funnel_id && (fid === activeFunnel || activeFunnel.includes(fid)) &&
                   !(hideDealsBubblesWithValueInRange && (value >= hideDealsBubblesWithValueInRange[0] && value <= hideDealsBubblesWithValueInRange[1]));
        });

        if (AppConfig.getValue('funnel.deals.sort_by_name')) {
            temp = _.sortBy(temp, function(d) {
                return d.get('name').toLowerCase();
            });
        }
        // ...
        processedDeals = _.map(temp, function(d) {
            var phaseObj = d.get('phase'),
                idx = phaseObj ? phaseObj.order : d.get('phase_number'),
                phase = phases[idx],
                deal = {
                    model: d,
                    id: d.get('id'),
                    phase: phase,
                    sequence: phase.dealsCount
                };
            
            phase.dealsCount++;
            phase.dealsValue += d.get('bubble_representation_value');

            // Apply color scheme
            switch(colorkey) {
                case 'forecast':
                    deal.colorclass = colorScheme(colorkey, deal.model, dealsStatus);
                    break;

                case 'closedate':
                    deal.colorclass = colorScheme(colorkey, deal.model, current_period_end);
                    break;

                default:
                    if (colorkey.indexOf('custom_colorkey') === 0) {
                        deal.colorclass = colorScheme(colorkey, deal.model, colorkey.replace("custom_colorkey_", ""), customColorKeys);
                    } else {
                        deal.colorclass = colorScheme(colorkey, deal.model);
                    }
                    break;
            }

            return deal;
        });

        if (AppConfig.getValue('serviceRequiredCfId') && !('dealServiceRequiredCf' in app.globalData)) {
            let customField = new CustomFieldModel({id: AppConfig.getValue('serviceRequiredCfId')});
            customField.fetch({
                success: function() {
                    app.globalData.dealServiceRequiredCf = customField.attributes.options;
                }
            });
        }

        return processedDeals;
    }

    /*
     * Returns current period end date. Logic is based on UTC times.
     *
     * @return  date    current period end date
     */
    function currentPeriodEnd() {
        var today = new Date(),
            ret = null;

        // convert to UTC
        today = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());

        _.find(periods, function(period) {
            var start = new Date(period.get('start_date')),
                end = new Date(period.get('end_date'));

            // convert to UTC
            start = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
            end = new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());

            if (today >= start && today < end) {
                ret = end;
                return true;
            }
        });

        return ret;
    }

    function insideRect(rect, x, y) {
        if ((x < rect.x) || (y < rect.y) || (x > (rect.x + rect.w)) || (y > (rect.y + rect.h))) {
            return false;
        }

        return true;
    }

    function getDropableBox(x, y) {
        // ... phases ...
        if (insideRect(innerRect, x, y)) {
            for (var i = 0; i < containerBoxes.length; ++i) {
                var b = containerBoxes[i];

                if (!insideRect(b, x, y)) {
                    continue;
                }

                return {
                    element: d3.select('#internal-container-' + b.phase.id),
                    id: b.phase.id
                };
            }

            return {id: null};
        }

        // ... funnels; won/lost ...
        if(!insideRect(funnelsRect, x, y)) {
            return {id: null};
        }

        // ... funnels ...
        for (var i = 0; i < processedFunnels.length; ++i) {
            var f = processedFunnels[i];
            if ((f.funnel.id === activeFunnel) || !insideRect(f, x, y)) {
                continue;
            }

            return {
                isFunnel: true,
                element: funnelsd3.select('#internal-funnel-' + f.funnel.id),
                id: f.funnel.id
            };
        }

        // ... won/lost ...
        if (wonPhase.length > 0) {
            if (insideRect(wonPhase[0], x, y)) {
                return {
                    element: d3.select('.won-phase').select('.empty-box'),
                    id: phaseWonId
                };
            }

            if (insideRect(lostPhase[0], x, y)) {
                return {
                    element: d3.select('.lost-phase').select('.empty-box'),
                    id: phaseLostId
                };
            }
        }

        return {id: null};
    }

    function setRollover(phaseId, on) {
        if (zooming || onTransition) {
            return;
        }

        var elem = d3.select('#container-rollover-' + phaseId);

        elem.transition()
            .duration(on ? 0 : transitionDurationLong)
            .attr('opacity', on ? 1 : 0);
    }

    function startDragging() {
        if (isDragging) {
            return;
        }

        isDragging = true;

        var dropable = d3.selectAll('.dropable-style:not(.funnel-box-disabled)');
        dropable.classed('to-drop-enabled', true);
    }

    function stopDragging() {
        if (!isDragging) {
            return;
        }

        if (selectedDropable.id) {
            selectedDropable.element.classed('to-drop', false);
            selectedDropable = {id: null};
        }

        // ...
        var dropable = d3.selectAll('.dropable-style');
        dropable.classed('to-drop-enabled', false);

        isDragging = false;
    }

    function chart(selection) {
        var funnelListWidth = (funnels.length < 2) ? 40 : 80;

        if (dashboardView) {
            if (dashboardView === 'pdcrm') {
                margin.top = 20;
                margin.bottom = 20;
                margin.right = 20;
                margin.left = 20;
                funnelListWidth = 0;
            } else {
                margin.top = 52;
            }
        }

        innerRect = {
                x: x+margin.left,
                y: y+margin.top,
                w: width - margin.left - margin.right - funnelListWidth,
                h: height - margin.top - margin.bottom
            };

        if ((innerRect.w <= 0) || (innerRect.h <= 0)) {
            return;
        }

        // Init if needed
        svg = selection.selectAll('svg').filter(filterByMyDataId(id));

        var processedPhases = processPhases(),
            processedDeals = processDeals(processedPhases),
            scaleLabels = [],
            scaleLabelsContainer = [],
            funnelListMargin = 7,
            newWidthForDeal, numRows, spaceForDeal;

        wonPhase = [];
        lostPhase = [];
        processedFunnels = processFunnels(funnels);
        containerBoxes = processContainerBoxes(processedPhases);
        funnelsRect = {
            x: innerRect.x + innerRect.w + funnelListMargin,
            y: innerRect.y,
            w: funnelListWidth + margin.right - (funnelListMargin * 2),
            h: innerRect.h
        };

        if (AppConfig.getValue('funnelPopup') === 'extended') {
            const clientPreferences = app.user.get('client').preferences;
            const snapshotPreferences = (clientPreferences && clientPreferences.loans_snapshot) ? JSON.parse(clientPreferences.loans_snapshot) : {};

            const colours = snapshotPreferences.colours;
            if (colours){
                StatusColor = colours.reduce((obj, item) => {
                    obj[item["option_id"]] = item["hex"]
                    return obj
                  }, {})
            }

            if (snapshotPreferences.bar_colour) {
                StatusBarColor = snapshotPreferences.bar_colour;
            }
        }

        function cropText(text, maxWidth, useEllipsis) {
            text.each(function() {
                D3Utilities.cropText(this, maxWidth, useEllipsis);
            });
        }

        function layoutFunnelsList() {
            if (funnels.length === 0 || dashboardView === 'pdcrm') {
                return;
            }

            funnelsListArrows = [];

            var spaceBetweenFunnels = 7;
            var singleFunnel = (processedFunnels.length < 2);
            var phasePos = funnelsRect.y;
            var phaseWidth = 48;
            var phaseHeight = 81;
            var iconSize = 16;

            if (!singleFunnel) {
                var arrowHeight = 25;
                var funnelsY = funnelsRect.y;
                var arrowsHeight = 0;

                // extra space for arrows
                var showArrows = funnels.length > maxNumberOfFunnelsVisible;

                if (showArrows) {
                    arrowsHeight = arrowHeight * 2 + spaceBetweenFunnels;
                    funnelsY += arrowHeight + spaceBetweenFunnels;
                }

                if (showArrows) {
                    funnelsListArrows.push({
                        x: funnelsRect.x,
                        y: funnelsRect.y,
                        w: funnelsRect.w,
                        h: arrowHeight,
                        top: true,
                        enabled: firstFunnelVisibleIdx > 0
                    });
                }

                // ...
                var wonLostExtra = 0;

                for (var w of ['won', 'lost']) {
                    if (AppConfig.getValue(`funnel.funnels_list.${w}.visible`, true)) {
                        wonLostExtra += 0.5;
                    }
                }

                var numFunnels = processedFunnels.length + wonLostExtra; // won/lost phases (the height is the half of the funnel)
                var spaceHeight = (spaceBetweenFunnels + wonLostExtra) * numFunnels; // +wonLostExtra = space between won/lost phases
                var funnelHeight = Math.min((funnelsRect.h - spaceHeight - arrowsHeight) / numFunnels, funnelsRect.w - 3);

                _.each(processedFunnels, function(d, i) {
                    d.x = funnelsRect.x;
                    d.y = funnelsY + (i * (funnelHeight + spaceBetweenFunnels));
                    d.w = funnelsRect.w;
                    d.h = Math.min(funnelHeight, funnelsRect.w);
                    d.nx = (d.w / 2); // x position of the funnel name
                    d.ny = 16;
                    d.dx = d.nx; // x position of number of deals text
                    d.dy = d.h - 10;
                    d.is = d.h / 1.8; // icon size
                    d.ix = (d.w / 2) - (d.is / 2); // x position of the icon
                    d.iy = (d.h / 2) + (d.is / 2);
                });

                var lastFunnel = processedFunnels[processedFunnels.length - 1];
                phasePos = lastFunnel.y + lastFunnel.h + spaceBetweenFunnels;
                phaseWidth = funnelsRect.w;
                phaseHeight = Math.min(funnelHeight, funnelsRect.w) / 2;
                iconSize = phaseWidth / 5;

                // ...
                if (showArrows) {
                    funnelsListArrows.push({
                        x: funnelsRect.x,
                        y: phasePos,
                        w: funnelsRect.w,
                        h: arrowHeight,
                        enabled: (firstFunnelVisibleIdx + processedFunnels.length) < funnels.length
                    });
                }

                // extra space for arrows
                if (funnels.length > maxNumberOfFunnelsVisible) {
                    phasePos += arrowHeight + spaceBetweenFunnels;
                }
            }


            if (wonLostPhasesVisible) {
                var halfIconSize = iconSize / 2;
                var halfPhaseWidth = phaseWidth / 2;
                var halfPhaseHeight = phaseHeight / 2;

                wonPhase.push({
                    x: funnelsRect.x,
                    y: phasePos,
                    w: phaseWidth,
                    h: phaseHeight,
                    nx: halfPhaseWidth,
                    ny: halfPhaseHeight + (singleFunnel ? 16 : 6),
                    is: iconSize, // icon size
                    ix: halfPhaseWidth - (singleFunnel ? 0 : (iconSize * 1.5)),
                    iy: iconSize + (singleFunnel ? iconSize : halfIconSize)
                });

                lostPhase.push({
                    x: funnelsRect.x,
                    y: phasePos + wonPhase[0].h + spaceBetweenFunnels,
                    w: phaseWidth,
                    h: phaseHeight,
                    nx: halfPhaseWidth,
                    ny: halfPhaseHeight + (singleFunnel ? 16 : 6),
                    is: iconSize, // icon size
                    ix: halfPhaseWidth - (singleFunnel ? 0 : (iconSize * 1.5)),
                    iy: iconSize + (singleFunnel ? iconSize : halfIconSize)
                });
            }
        }

        function layoutFunnel() {
            var heightForZoomedPhase = 0;
            var heightForPhase = innerRect.h / processedPhases.length;

            // calculate total max number of deals per phase
            var zoomedPhaseMaxCount = 0;
            var normalPhasesMaxCount = _.max(processedPhases, function(p) {
                return (zoomedPhaseId === p.id) ? 0 : p.dealsCount;
            }).dealsCount;

            var dealsValues = calculateDealsValues(processedDeals);

            // Find numRows for which widthForDeal is maximum (for normal and zoomed phase)
            var phaseMargin = 20;
            var phaseYOffset = 20;
            var widthForPhase = innerRect.w;

            function calculatePhaseInfo(maxCount, height) {
                var widthForDeal = 0;
                var newWidthForDeal = 0;
                var numRows;

                for (numRows = 0; newWidthForDeal >= widthForDeal; ) {
                    widthForDeal = newWidthForDeal;
                    numRows++;
                    newWidthForDeal = (widthForPhase - phaseMargin) /
                        Math.floor( (maxCount+numRows-1) / numRows );
                    newWidthForDeal = Math.min(newWidthForDeal,
                        (height - phaseYOffset)/(numRows+1));
                }

                numRows = Math.max(1, numRows-1);

                return {
                    numRows: numRows,
                    spaceForDeal: (widthForPhase - phaseMargin) / Math.floor((maxCount+numRows-1) / numRows),
                    widthForDeal: widthForDeal
                };
            }

            var zoomedPhaseInfo = null;

            // previous calculations for zoomed phase
            if (zoomedPhaseId) {
                if (processedPhases.length > 1) {
                    var prevHeight = heightForPhase;
                    heightForPhase = 40;
                    heightForZoomedPhase = innerRect.h - (heightForPhase * (processedPhases.length - 1));

                    if (heightForZoomedPhase < prevHeight) {
                        heightForZoomedPhase = prevHeight;
                        heightForPhase = prevHeight;
                    }
                }

                zoomedPhaseMaxCount = _.find(processedPhases, function(p) {
                    return (zoomedPhaseId === p.id);
                }).dealsCount;

                zoomedPhaseInfo = calculatePhaseInfo(zoomedPhaseMaxCount, heightForZoomedPhase);
            }

            var normalPhasesInfo = calculatePhaseInfo(normalPhasesMaxCount, heightForPhase);

            // calculate the start Y position for every phase
            var phasesStartY = [];
            var accum = 0;
            _.each(processedPhases, function(d, i) {
                phasesStartY.push(accum);
                accum += d.id === zoomedPhaseId ? heightForZoomedPhase : heightForPhase;
            });

            // calculate the bubbles positions
            _.each(processedDeals, function(d) {
                var isZoomed = (d.phase.id === zoomedPhaseId);
                var phaseInfo = isZoomed ? zoomedPhaseInfo : normalPhasesInfo;
                var numDeals = d.phase.dealsCount;
                var dealsPerRow = Math.ceil(numDeals / phaseInfo.numRows);
                var row = d.sequence % phaseInfo.numRows;
                var posInRow = Math.floor(d.sequence / phaseInfo.numRows);
                var rowOffset = (row % 2) ? 0.55 : 0.45;

                d.x = innerRect.x + (posInRow+0.5) * phaseInfo.spaceForDeal;
                d.y = innerRect.y + phaseYOffset + phasesStartY[d.phase.order] + (row + rowOffset) * phaseInfo.widthForDeal;
                d.y += (((d.phase.id === zoomedPhaseId) ? heightForZoomedPhase : heightForPhase) - phaseYOffset -(phaseInfo.numRows*phaseInfo.widthForDeal))/2;
                d.y += ((posInRow%2)?0.25:-0.25) * phaseInfo.widthForDeal;

                // Center row
                var dealsInRow = dealsPerRow;
                if (row === phaseInfo.numRows) {
                    dealsInRow -= phaseInfo.numRows * dealsPerRow - numDeals;
                }
                d.x += (widthForPhase - (dealsInRow * phaseInfo.spaceForDeal)) / 2;

                var dval = d.model.get('bubble_representation_value');
                var mval = isZoomed ? dealsValues.maxZoomedValue : dealsValues.maxValue;
                var aval = isZoomed ? dealsValues.averageZoomedValue : dealsValues.averageValue;

                d.r = valueradius(dval, mval, phaseInfo.widthForDeal / 2.0, aval);
            });

            // calculate the phase name position
            var accumy = innerRect.y + 20;
            _.each(processedPhases, function(d) {
                d.x = innerRect.x + 10;
                d.x2 = innerRect.w - 20;
                d.y2 = -3;

                if (d.id === zoomedPhaseId) {
                    d.y = accumy;
                    accumy += heightForZoomedPhase;
                }
                else {
                    d.y = accumy + (zoomedPhaseId ? 5 : 0);
                    accumy += heightForPhase;
                }
            });

            // calculate the container box position
            accumy = innerRect.y + 1;
            _.each(containerBoxes, function(d) {
                d.x = innerRect.x;
                d.y = accumy;
                d.w = innerRect.w;

                var h = ((d.phase.id === zoomedPhaseId) ? heightForZoomedPhase : heightForPhase);
                d.h = h - 2;
                accumy += h;
            });
        }

        function layoutSos() {
            var widthForPhase = 0;
            var widthForZoomedPhase = 0;

            if (zoomedPhaseId && (processedPhases.length > 1)) {
                widthForPhase = 40;
                widthForZoomedPhase = (0.93 * innerRect.w) - (widthForPhase * (processedPhases.length - 1));
            }
            else {
                widthForPhase = 0.93 * innerRect.w / processedPhases.length;
            }

            // ...
            function getScale(deal) {
                var phasetime = getPhaseTime(deal, datetime);

                phasetime = phasetime > 0 ? phasetime : 0;

                if ( phasetime < (7*7) ) {
                    return Math.floor(phasetime / 7);
                } else {
                    return 7;
                }
            }

            var dealsValues = calculateDealsValues(processedDeals);

            var scale = ["0w", "1w", "2w", "3w", "4w", "5w", "6w", "7w+"];
            scaleLabels = _.map(scale, function(d, i) {
                return {
                    id: i,
                    text: d
                };
            });

            // calculate the start X position for every phase and init counters
            var counts = [];
            var phasesStartX = [];
            var accum = 0;

            _.each(processedPhases, function(d, i) {
                phasesStartX.push(accum);
                accum += d.id === zoomedPhaseId ? widthForZoomedPhase : widthForPhase;

                // ...
                counts[i] = [];
                _.each(scale, function(d, j) {
                    counts[i][j] = 0;
                });
            });

            // ...
            var zoomedPhaseMaxCount = 0;
            var normalPhasesMaxCount = 0;
            _.each(processedDeals, function(d) {
                var phaseOrder = d.phase.order,
                    scaleOrder = getScale(d);

                d.scaleOrder = scaleOrder;
                d.scaleSequence = counts[phaseOrder][scaleOrder];
                counts[phaseOrder][scaleOrder]++;

                // ...
                var val = counts[phaseOrder][scaleOrder];

                if (zoomedPhaseId === d.phase.id) {
                    if (val > zoomedPhaseMaxCount) {
                        zoomedPhaseMaxCount = val;
                    }
                }
                else if (val > normalPhasesMaxCount) {
                    normalPhasesMaxCount = val;
                }
            });

            // Find numRows for which widthForDeal is maximum
            var phaseMargin = 10;
            var heightForScale = (innerRect.h - 50) / scale.length;

            function calculatePhaseInfo(maxCount, width) {
                var widthForDeal = 0;
                var newWidthForDeal = 0;
                var numRows;

                for (numRows = 0; newWidthForDeal >= widthForDeal; ) {
                    widthForDeal = newWidthForDeal;
                    numRows++;
                    newWidthForDeal = (width - phaseMargin) /
                        Math.floor( (maxCount+numRows-1) / numRows );
                    newWidthForDeal = Math.min(newWidthForDeal,
                        heightForScale/(numRows));
                }

                numRows = Math.max(1, numRows-1);

                return {
                    numRows: numRows,
                    spaceForDeal: (width - phaseMargin) / Math.floor((maxCount + numRows - 1) / numRows),
                    widthForDeal: widthForDeal
                };

            }

            var zoomedPhaseInfo = null;

            // previous calculations for zoomed phase
            if (zoomedPhaseId) {
                if (processedPhases.length > 1) {
                    widthForPhase = 40;
                    widthForZoomedPhase = (0.93 * innerRect.w) - (widthForPhase * (processedPhases.length - 1));
                }

                zoomedPhaseInfo = calculatePhaseInfo(zoomedPhaseMaxCount, widthForZoomedPhase);
            }

            var normalPhasesInfo = calculatePhaseInfo(normalPhasesMaxCount, widthForPhase);

            // ...
            _.each(processedPhases, function(d, i) {
                d.x = innerRect.x + phasesStartX[i] + (0.5 * ((d.id === zoomedPhaseId) ? widthForZoomedPhase : widthForPhase));
                d.y = innerRect.y + innerRect.h - 30;
                d.x2 = 0;
                d.y2 = 20;
                d.w = (d.id === zoomedPhaseId) ? widthForZoomedPhase : widthForPhase;
            });

            _.each(scaleLabels, function(d, i) {
                d.x = innerRect.x + 0.965 * innerRect.w;
                d.y = innerRect.y + (i+0.5) * heightForScale;
            });

            scaleLabelsContainer = [{
                x: (innerRect.x + 0.93 * innerRect.w),
                y: innerRect.y + 1,
                w: innerRect.w * 0.07,
                h: innerRect.h
            }];

            // calculate the bubbles positions
            _.each(processedDeals, function(d) {
                var isZoomed = (d.phase.id === zoomedPhaseId);
                var phaseInfo = isZoomed ? zoomedPhaseInfo : normalPhasesInfo;

                var ppos = d.phase.order,
                    spos = d.scaleOrder,
                    numDeals = counts[ppos][spos],
                    dealsPerRow = Math.ceil(numDeals / phaseInfo.numRows),
                    row = Math.floor(d.scaleSequence / dealsPerRow),
                    posInRow = d.scaleSequence - row * dealsPerRow;

                d.x = innerRect.x + phasesStartX[d.phase.order] +
                      (posInRow+0.5) * phaseInfo.spaceForDeal;

                var rowOffset = 1.0;

                if (isZoomed && (d.scaleOrder === scale.length - 1)) {
                    rowOffset = 0.3;
                }

                d.y = innerRect.y + d.scaleOrder * heightForScale +
                    (row + rowOffset) * phaseInfo.widthForDeal;


                // Center row
                var dealsInRow = dealsPerRow;
                if (row === phaseInfo.numRows) {
                    dealsInRow -= phaseInfo.numRows*dealsPerRow - numDeals;
                }

                d.x += ((isZoomed ? widthForZoomedPhase : widthForPhase)-(dealsInRow*phaseInfo.spaceForDeal))/2;

                var dval = d.model.get('bubble_representation_value');
                var mval = isZoomed ? dealsValues.maxZoomedValue : dealsValues.maxValue;
                var aval = isZoomed ? dealsValues.averageZoomedValue : dealsValues.averageValue;
                d.r = valueradius(dval, mval, phaseInfo.widthForDeal / 2.0, aval);
            });

            // calculate container boxes positions
            var accumx = innerRect.x;
            _.each(containerBoxes, function(d, i) {
                d.x = accumx;
                d.y = innerRect.y + 1;
                d.h = innerRect.h;

                var w = (d.phase.id === zoomedPhaseId) ? widthForZoomedPhase : widthForPhase;
                d.w = w - 2;
                accumx += w;
            });
        }

        function zoomPhase(phaseId) {
            zoomedPhaseId = (phaseId === zoomedPhaseId) ? null : phaseId;
            parent.trigger('funnel:phase:zoom');
        }

        function manageDealName(elem, deal) {
            var textToShow = null;

            const customFields = app.globalData.customFieldsInfo.deals;
            let unitNumberCfId = null;

            for (const cfId in customFields) {
                if (customFields[cfId].name.toLowerCase() === 'unit no.') {
                    unitNumberCfId = cfId;
                    break;
                }
            }

            if (AppConfig.getValue("funnel.display_bubble.show_unit_no", false) && (unitNumberCfId && deal.model.get(`custom_field.${unitNumberCfId}`))) {
                textToShow = deal.model.get(`custom_field.${unitNumberCfId}`);
            }
            else if (!AppConfig.getClientPreferenceValue('showDealIdOnBubble')) {
                textToShow = deal.model.get('name');
            }
            else if (zoomedPhaseId) {
                if (deal.phase.id === zoomedPhaseId) {
                    textToShow = deal.model.get('name');
                }
            }
            else {
                textToShow = deal.model.get('abbreviation').substring(0, 5);
            }

            // ...
            var minRadius = 6.2;

            if (textToShow && (deal.r >= minRadius)) {
                elem.attr('opacity', 1);
                elem.text(textToShow);
                cropText(elem, deal.r * 1.7);
            }
            else {
                elem.text('');
                elem.attr('opacity', 0);
            }
        }

        if (type === 'sos') {
            layoutSos();
        } else {
            layoutFunnel();

            if (type === 'none') {
                processedPhases = [];
                containerBoxes = [];
                processedDeals = [];
            }
        }

        layoutFunnelsList();

        if (svg.empty()) {
            // Initialize
            svg = selection.append('svg');
            svg.property('mydata', {id:id});
        }

        function getServiceRequiredValue(deal){
            if (AppConfig.getValue('serviceRequiredCfId') && ('dealServiceRequiredCf' in app.globalData)){
                var serviceRequiredCf = deal.model.get(`custom_fields.${AppConfig.getValue('serviceRequiredCfId')}`)

                if (!serviceRequiredCf) {
                    serviceRequiredCf = app.globalData.dealServiceRequiredCf.find(option => option.id == deal.model.get(`custom_field.${AppConfig.getValue('serviceRequiredCfId')}`));
                    return serviceRequiredCf ? serviceRequiredCf.value : null
                }

                return serviceRequiredCf

            } else {
                return null
            }
        }

        function getDisplayDealValue(deal) {
            if(AppConfig.getValue("funnel.display_bubble.check_achieved_price_and_asking_price", false) && deal.model.get('buckets')){
                const achievedPriceBucket = deal.model.get('buckets').find(bucket => bucket.name === "Achieved Price");
                if(achievedPriceBucket && achievedPriceBucket.value > 0){
                    return achievedPriceBucket.value;
                }else{
                    const askingPriceBucket = deal.model.get('buckets').find(bucket => bucket.name === "Asking Price");
                    if(askingPriceBucket && askingPriceBucket.value > 0){
                        return askingPriceBucket.value;
                    }
                }
            }

            return deal.model.get('value')
        }

        svg.on('mousemove', function() {
            mousePosition = d3.mouse(this);

            if (isDragging && draggingLead) {
                var mouse = d3.mouse(this);
                var db = getDropableBox(mouse[0], mouse[1]);

                if (db.id !== selectedDropable.id)
                {
                    if (selectedDropable.id) {
                        selectedDropable.element.classed('to-drop', false);
                    }

                    selectedDropable = db;

                    if (selectedDropable.id) {
                        selectedDropable.element.classed('to-drop', true);
                    }
                }
            }
        });

        svg.on('mouseout', function() {
            if (isDragging && draggingLead && selectedDropable.id) {
                selectedDropable.element.classed('to-drop', false);
                selectedDropable = {id: null};
            }

            mousePosition = [0, 0];
        });

        // Draw container boxes
        svg.selectAll('g.container-parent')
            .data(containerBoxes, function(d) {
                return d.phase.id;
            })
            .call(function() {
                var update = function() {
                    this.select('rect.container')
                        .attr('x', function(d) { return d.x; })
                        .attr('y', function(d) { return d.y; })
                        .attr('width', function(d) { return d.w; })
                        .attr('height', function(d) { return d.h; });

                    this.select('rect.empty-box')
                        .attr('x', function(d) { return d.x + 1; })
                        .attr('y', function(d) { return d.y + 1; })
                        .attr('width', function(d) { return Math.max(d.w - 2, 0); })
                        .attr('height', function(d) { return Math.max(d.h - 2, 0); });
                };

                this.transition()
                    .duration(transitionDuration)
                    .call(update);

                this.enter().append('g')
                    .attr('class', 'container-parent')
                    .call(function() {
                        this.append('rect')
                            .attr('class', 'container')
                            .attr('id', function(d) { return 'container-' + d.phase.id; });

                        this.append('rect')
                            .attr('id', function(d) { return 'internal-container-' + d.phase.id; })
                            .attr('class', 'empty-box');
                    })
                    .call(update);

                this.exit().remove();
            })
            .on('click', function(d) {
                zoomPhase(d.phase.id);
            })
            .on('mouseover', function(d) {
                setRollover(d.phase.id, true);
            })
            .on('mouseout', function(d) {
                setRollover(d.phase.id, false);
            });


        // Draw funnels list (arrows, funnels, won & lost phases)
        var drawFunnelsList = function(transitionTime) {
            var arrows = svg.selectAll('g.funnels-list-arrow')
                .data(funnelsListArrows, function(d) {
                    return d.top ? 0 : 1;
                })
                .call(function() {
                    var update = function() {
                        this.attr('class', function(d) {
                            return 'funnels-list-arrow' + (d.enabled ? '' : ' disabled');
                        });

                        this.select('rect')
                            .attr('x', function(d) { return d.x; })
                            .attr('y', function(d) { return d.y; })
                            .attr('width', function(d) { return d.w; })
                            .attr('height', function(d) { return d.h; })
                            .attr('rx', 7)
                            .attr('ry', 7);

                        this.select('text')
                            .attr('dx', function(d) { return d.x + (d.w / 2); })
                            .attr('dy', function(d) { return d.y + (d.h / 2) + 5; })
                            .attr('opacity', function(d) { return d.enabled ? 1 : 0; })
                            .text(function(d) {
                                return d.top ? '\ue652' : '\ue656';
                            });
                    };

                    this.transition()
                        .duration(transitionTime)
                        .call(update);

                    this.enter().append('g')
                        .call(function() {
                            this.append('rect');
                            this.append('text')
                                .attr('class', 'icon')
                                .attr('text-anchor', 'middle');
                        })
                        .on('mouseover', function(d) {
                            d3.select(this).select('.icon').classed('hover', d.enabled);
                        })
                        .on('mouseout', function() {
                            d3.select(this).select('.icon').classed('hover', false);
                        })
                        .on('click', function(d) {
                            if (d.enabled) {
                                firstFunnelVisibleIdx += d.top ? -1 : 1;
                                processedFunnels = processFunnels(funnels);
                                layoutFunnelsList();
                                drawFunnelsList(0);
                            }
                        })
                        .call(update);

                    this.exit().remove();
                });

            // Draw funnels
            var funnelBoxBasicClasses = 'funnel-box dropable-style';

            funnelsd3 = svg.selectAll('g.funnel')
                .data(processedFunnels, function(d) {
                    return d.funnel.id;
                })
                .call(function() {
                    var update;

                    update = function() {
                        this.attr('transform', function(d) {
                                return 'translate(' + d.x + ' ' + d.y + ')';
                            });

                        this.select('rect.funnel-box')
                            .attr('width', function(d) { return Math.max(d.w, 0); })
                            .attr('height', function(d) { return Math.max(d.h, 0); })
                            .attr('class', function(d) {
                                var cls = '';

                                if ( highlight && ( getDealFunnel( highlight ) === d.funnel.id ) ) {
                                    cls = ' funnel-box-highlight';
                                }
                                else if (d.funnel.id === activeFunnel) {
                                    cls = ' funnel-box-disabled';
                                }

                                return funnelBoxBasicClasses + cls;
                            });

                        this.select('rect.empty-box')
                            .attr('width', function(d) { return Math.max(d.w - 2, 0); })
                            .attr('height', function(d) { return Math.max(d.h - 2, 0); });

                        this.select('text.funnel-name')
                            .text(function(d) {
                                var self = d3.select(this);

                                _.defer(function() {
                                    cropText(self, d.w, false);
                                });

                                return d.funnel.name;
                            })
                            .attr('class', function(d) {
                                var cls = '';

                                if ( highlight && ( getDealFunnel( highlight ) === d.funnel.id ) ) {
                                    cls = 'funnel-name-highlight';
                                }
                                else if (d.funnel.id === activeFunnel) {
                                    cls = 'funnel-name-disabled';
                                }

                                return 'funnel-name ' + cls;
                            })

                        this.select('text.funnel-icon')
                            .attr('font-size', function(d) { return d.is; })
                            .attr('dx', function(d) { return d.ix; })
                            .attr('dy', function(d) { return d.iy; })
                            .attr('class', function(d) {
                                var cls = '';

                                if ( highlight && ( getDealFunnel( highlight ) === d.funnel.id ) ) {
                                    cls = 'funnel-icon-highlight';
                                }
                                else if (d.funnel.id === activeFunnel) {
                                    cls = 'funnel-icon-disabled';
                                }

                                return 'funnel-icon ' + cls;
                            });

                        this.select('circle.funnel-circle')
                            .attr('cx', 0)
                            .attr('cy', 0)
                            .attr('r', 0);

                        this.select('line.funnel-hline')
                            .attr('x1', 0)
                            .attr('y1', 0)
                            .attr('x2', 0)
                            .attr('y2', 0);

                        this.select('line.funnel-vline')
                            .attr('x1', 0)
                            .attr('y1', 0)
                            .attr('x2', 0)
                            .attr('y2', 0);
                    };

                    this.transition()
                        .duration(transitionTime)
                        .call(update);

                    this.enter().append('g')
                        .attr('class', 'funnel')
                        .call(function() {
                            this.append('rect')
                                .attr('class', funnelBoxBasicClasses)
                                .attr('id', function(d) { return 'funnel-' + d.funnel.id; })
                                .attr('x', 0)
                                .attr('y', 0)
                                .attr('rx', 7)
                                .attr('ry', 7)
                                .attr('width', function(d) { return Math.max(d.w, 0); })
                                .attr('height', function(d) { return Math.max(d.h, 0); });

                            this.append('rect')
                                .attr('class', 'empty-box')
                                .attr('id', function(d) { return 'internal-funnel-' + d.funnel.id; })
                                .attr('x', 1)
                                .attr('y', 1)
                                .attr('rx', 7)
                                .attr('ry', 7)
                                .attr('width', function(d) { return Math.max(d.w - 2, 0); })
                                .attr('height', function(d) { return Math.max(d.h - 2, 0); });

                            this.append('text')
                                .attr('class', 'funnel-icon')
                                .text('\ue66f');

                            this.append('text')
                                .attr('class', 'funnel-name')
                                .attr('text-anchor', 'middle')
                                .attr('dx', function(d) { return d.nx; })
                                .attr('dy', function(d) { return d.ny; });
                        })
                        .on('mouseover', function(d) {
                            if (!isDragging && (d.funnel.id !== activeFunnel)) {
                                d3.select(this).select('.funnel-box').classed('funnel-box-hover', true);
                                d3.select(this).select('.funnel-name').classed('funnel-name-hover', true);
                                d3.select(this).select('.funnel-icon').classed('funnel-icon-hover', true);
                            }
                        })
                        .on('mouseout', function(d) {
                            if (!isDragging && (d.funnel.id !== activeFunnel)) {
                                d3.select(this).select('.funnel-box').classed('funnel-box-hover', false);
                                d3.select(this).select('.funnel-name').classed('funnel-name-hover', false);
                                d3.select(this).select('.funnel-icon').classed('funnel-icon-hover', false);
                            }
                        })
                        .on('click', function(d) {
                            if (d.funnel.id !== activeFunnel) {
                                zoomedPhaseId = null;
                                $(this).trigger('change-funnel', d.funnel.id);
                            }
                        })

                        .call(update);

                    this.exit().remove();
                });

            // Draw Won/Lost phase
            var drawWonLostPhases = function(type, a) {
                var t = type.toLowerCase();

                svg.selectAll('g.' + t + '-phase')
                    .data(a, function(d) { return t; })
                    .call(function() {
                        var update = function() {
                            this.attr('transform', function(d) {
                                return 'translate(' + d.x + ' ' + d.y + ')';
                            });

                            this.select('rect.' + t + '-phase-box')
                                .attr('width', function(d) { return Math.max(d.w, 0); })
                                .attr('height', function(d) { return Math.max(d.h, 0); });

                            this.select('rect.empty-box')
                                .attr('width', function(d) { return Math.max(d.w - 2, 0); })
                                .attr('height', function(d) { return Math.max(d.h - 2, 0); });

                            this.select('text.' + t + '-phase-name')
                                .attr('dx', function(d) { return d.nx; })
                                .attr('dy', function(d) { return d.ny; })
                                .attr('text-anchor', function() { return (funnels.length < 2) ? 'middle' : 'start'; })
                                .text(type);

                            this.select('text.phase-icon')
                                .attr('font-size', function(d) { return d.is; })
                                .attr('text-anchor', function() { return (funnels.length < 2) ? 'middle' : 'start'; })
                                .attr('dx', function(d) { return d.ix; })
                                .attr('dy', function(d) { return d.iy; });
                        };

                        this.transition()
                            .duration(transitionTime)
                            .call(update);

                        this.enter().append('g')
                            .attr('class', t + '-phase')
                            .call(function() {
                                this.append('rect')
                                    .attr('class', t + '-phase-box dropable-style')
                                    .attr('x', 0)
                                    .attr('y', 0)
                                    .attr('rx', 7)
                                    .attr('ry', 7)
                                    .attr('width', function(d) { return Math.max(d.w, 0); })
                                    .attr('height', function(d) { return Math.max(d.h, 0); });

                                this.append('rect')
                                    .attr('class', 'empty-box')
                                    .attr('x', 1)
                                    .attr('y', 1)
                                    .attr('rx', 7)
                                    .attr('ry', 7)
                                    .attr('width', function(d) { return Math.max(d.w - 2, 0); })
                                    .attr('height', function(d) { return Math.max(d.h - 2, 0); });

                                this.append('text')
                                    .attr('class', 'phase-icon')
                                    .text(function(d) { return t === 'won' ? '\ue672' : '\ue671' });

                                this.append('text')
                                    .attr('class', t + '-phase-name')
                                    .attr('dx', function(d) { return d.nx; })
                                    .attr('dy', function(d) { return d.ny; });
                                })

                            .call(update);

                        this.exit().remove();
                    });
            };

            if (AppConfig.getValue('funnel.funnels_list.won.visible', true)) {
                drawWonLostPhases('Won', wonPhase);
            }

            if (AppConfig.getValue('funnel.funnels_list.lost.visible', true)) {
                drawWonLostPhases('Lost', lostPhase);
            }
        };

        drawFunnelsList(transitionDuration);

        // Draw phase labels
        var funnel = _.findWhere(funnels, {id: activeFunnel}) || funnels[0];

        var showCurrency = funnel.get('bubble_representation') === 'deal_value';

        svg.selectAll('g.phase')
            .data(processedPhases, function(d){
                return guid()
            })
            .call(function() {
                var update;
                var phase = this;

                update = function() {
                    this.attr('transform', function(d) {
                            return 'translate(' + d.x + ' ' + d.y + ')';
                        });
                };

                this.transition()
                    .duration(transitionDuration)
                    .call(update);

                this.enter().append('g')
                    .attr('class', 'phase')
                    .call(function() {
                        this.append('text')
                            .attr('class', function() {
                                return (type === 'sos') ? 'phase-title-label phase-title-label-sos' : 'phase-title-label';
                            })
                            .attr('id', function(d) { return 'phase-title-' + d.id; })
                            .attr('dx', 0)
                            .attr('dy', 0)
                            .text(function(d) { return d.name; })
                            .on('mouseover', function(d) {
                                if (!isDragging && d.hint) {
                                    var html = Handlebars.compile(popoverPhaseTemplate)({
                                        content: d.hint
                                    });
                                    $(this)
                                        .popover({
                                            trigger: 'hover',
                                            animation: false,
                                            container: 'body',
                                            html: true,
                                            content: html,
                                            placement: type === 'sos' ? 'top' : 'bottom'
                                        })
                                        .data('popover')
                                        .tip()
                                        .addClass('bubble-popover-phase');

                                    $(this).popover('show');
                                }

                                setRollover(d.id, true);
                            })
                            .on('mouseout', function(d) {
                                $(this).popover('destroy');
                                setRollover(d.id, false);
                            });

                        this.append('text')
                            .attr('class', function() {
                                return (type === 'sos') ? 'phase-value-label phase-value-label-sos' : 'phase-value-label';
                            })
                            .on('mouseover', function(d) {
                                setRollover(d.id, true);
                            })
                            .on('mouseout', function(d) {
                                setRollover(d.id, false);
                            });
                    })
                    .call(update);

                this.select('text.phase-title-label')
                    .on('click', function(d) {
                        zoomPhase(d.id);
                    })
                    .transition()
                        .duration(transitionDuration)
                        .each('start', function() {
                            d3.select(this).attr('class', (type === 'sos') ? 'phase-title-label phase-title-label-sos' : 'phase-title-label');
                        })
                        .each('end', function(d){
                            var self = d3.select(this);
                            self.text(d.name);

                            if (type === 'sos') {
                                cropText(self, 0.9 * d.w, true);
                            }
                        });


                this.select('text.phase-value-label')
                    .on('click', function(d) {
                        zoomPhase(d.id);
                    })
                    .transition()
                        .duration(transitionDuration)
                        .attr('class', function() { return (type === 'sos') ? 'phase-value-label phase-value-label-sos' : 'phase-value-label' })
                        .attr('x', function(d) { return d.x2; })
                        .attr('y', function(d) { return d.y2; })
                        .each('end', function(d) {
                            var self = d3.select(this);
                            var currency = showCurrency ? app.user.get('client')['default_currency'] : '';
                            var text = d.dealsCount
                            var valueVisible = AppConfig.getValue('funnel.phases.deals_total_value.visible', dealsTotalValueVisible);

                            if (valueVisible) {
                                text += ' = ' + Currency.shortFormat(currency, d.dealsValue);
                            }
                            self.text(text);

                            if (type === 'sos') {
                                cropText(d3.select(this), 0.9 * d.w, true);
                            }
                        });

                this.exit().remove();
            });

        // Draw scale labels

        // container
        svg.selectAll('rect.scale-label-container')
            .data(scaleLabelsContainer, function(d) { return 1; })
            .call(function() {
                this.select('rect.scale-label-container');
                this.attr('x', function(d) { return d.x; });
                this.attr('y', function(d) { return d.y; });
                this.attr('width', function(d) { return d.w; });
                this.attr('height', function(d) { return d.h; });

                this.enter().append('rect')
                    .attr('class', 'scale-label-container')
                    .attr('x', function(d) { return d.x; })
                    .attr('y', function(d) { return d.y; })
                    .attr('width', function(d) { return d.w; })
                    .attr('height', function(d) { return d.h; })
                    .attr('opacity', 0)
                    .transition()
                        .duration(transitionDuration)
                        .attr('opacity', 1);

                this.exit()
                    .transition()
                        .duration(transitionDuration)
                        .attr('opacity', 0)
                        .remove();
            });

        svg.selectAll('text.scale-label')
            .data(scaleLabels, function (d) {
                return d.id;
            })
            .call(function() {
                this.attr('dx', function(d) { return d.x; })
                    .attr('dy', function(d) { return d.y; });

                this.enter().append('text')
                    .attr('class', 'scale-label')
                    .attr('text-anchor', 'middle')
                    .attr('dx', function(d) { return d.x; })
                    .attr('dy', function(d) { return d.y; })
                    .text(function(d) { return d.text; })
                    .attr('opacity', 0)
                    .transition()
                        .duration(transitionDuration)
                        .attr('opacity', 1);

                this.exit()
                    .transition()
                        .duration(transitionDuration)
                        .attr('opacity', 0)
                        .remove();
            });

        // Draw deals
        svg.selectAll('g.deal')
            .data(processedDeals, function(d) {
                return guid()
            })
            .call(function() {
                var updateClass,
                    updateDeal,
                    drag, move, drop, click, mouseover, mouseout, circleClick;

                drag = function() {
                    if (AppConfig.getValue('funnel.phases.draggable', true)) {
                        var datum = d3.select(this).datum();

                        if (!zoomedPhaseId || (zoomedPhaseId === datum.phase.id)) {
                            datum.moved = false;
                            datum.dragX = null;
                            datum.dragY = null;

                            startDragging();
                        }
                    }
                };

                move = function() {
                    if (!dragEnabled || !isDragging) {
                        return;
                    }

                    d3.select(this)
                        .attr('transform', function(d) {
                            var nx = d3.event.x,
                                ny = d3.event.y;

                            d.moved = true;
                            d.dragX = nx;
                            d.dragY = ny;

                            return 'translate(' + nx + ' ' + ny + ')';
                        });

                    // ...
                    var db = getDropableBox(d3.event.x, d3.event.y);

                    if (db.id !== selectedDropable.id)
                    {
                        if (selectedDropable.id) {
                            selectedDropable.element.classed('to-drop', false);
                        }

                        selectedDropable = db;

                        if (selectedDropable.id) {
                            var datum = d3.select(this).datum();

                            if (selectedDropable.isFunnel || (selectedDropable.id !== datum.phase.id)) {
                                selectedDropable.element.classed('to-drop', true);
                            }
                        }
                    }
                };

                drop = function() {
                    if (!isDragging) {
                        return;
                    }

                    var datum = d3.select(this).datum();

                    if (datum.moved) {
                        var backToInitialPosition = true;

                        // is a movement to a funnel or a won/lost phase?
                        if (selectedDropable.id)
                        {
                            if (selectedDropable.isFunnel) {
                                $(this).trigger('deal-phase-change', {
                                    dealId: datum.id,
                                    funnel: {id: selectedDropable.id}
                                });

                                backToInitialPosition = false;
                            }
                            else if (datum.phase.id !== selectedDropable.id) {
                                var phase =  phases.find(phase => phase.get('id') === selectedDropable.id);
                                let dealFunnelId = datum.model?.get('real_funnel_id');
                                let realPhase = phase.get('relatedPhaseIds')?.find(phaseObject => phaseObject.funnel_id == dealFunnelId)
                                $(this).trigger('deal-phase-change', {
                                    dealId: datum.id,
                                    deal: datum.model,
                                    phase: {id: selectedDropable.id, name: phase.get('name'), order: phase.get('order'), realPhaseId: realPhase?.phase_id}
                                });

                                backToInitialPosition = false;
                            }
                        }
                        // If there is move of phase, leave the bubble
                        // where it has been dropped until the new
                        // fetch causes an update of the funnel
                        // else if (phase && (phase.id !== datum.phase.id)) {
                        //     $(this).trigger('deal-phase-change', {
                        //         dealId: datum.id,
                        //         phase: phase
                        //     });
                        // }
                        // If there is no movement of phase, return
                        // the bubble to its original position

                        if (backToInitialPosition) {
                            d3.select(this).transition().attr('transform',
                                function(d) {
                                    return 'translate(' + d.x + ' ' + d.y + ')';
                                });
                        }
                    }

                    stopDragging();
                };

                click = function() {
                    var deal = d3.select(this).datum();

                    if (zoomedPhaseId && (zoomedPhaseId !== deal.phase.id)) {
                        zoomPhase(deal.phase.id);
                    }
                    else {
                        $(this).trigger('show-deal', deal.id);
                    }
                };

                circleClick = function() {
                    $(this).popover('destroy');
                };

                mouseover = function() {
                    var deal = d3.select(this).datum();
                    if (!isDragging && (!zoomedPhaseId || (zoomedPhaseId === deal.phase.id))) {
                            var currency = deal.model.get('currency'),
                            owner = deal.model.get('owner'),
                            date = deal.model.get('expected_close_date'),
                            placement = 'bottom',
                            html,
                            extendedPopup = AppConfig.getValue('funnelPopup') === 'extended' && deal.model.get('snapshot_name'); // to check that the deal has the snapshot data

                            var issuePopup = section && section.id === 'issues';

                            if (issuePopup) {
                                const cfs = app.globalData.customFieldsInfo.dealsArray;
                                const issueTypeCf = cfs.find(f => f.name.toLowerCase() === 'issue type');
                                const propertyCf = cfs.find(f => f.name.toLowerCase() === 'property');
                                let issueType = '';
                                let property = '';

                                if (issueTypeCf) {
                                    const value = deal.model.get(`custom_fields.${issueTypeCf.id}`);

                                    if (value) {
                                        issueType = value;
                                    }
                                }

                                if (propertyCf) {
                                    const value = deal.model.get(`custom_fields.${propertyCf.id}`);

                                    if (value) {
                                        property = value.name;
                                    }
                                }

                                html = Handlebars.compile(issuePopoverTemplate)({
                                    property: property,
                                    issueType: issueType
                                });
                            } else if (extendedPopup) {
                                var statusColor = null;
                                var status = deal.model.get('snapshot_funnel_drops_value');

                                if (status) {
                                    const clientPreferences = app.user.get('client').preferences;
                                    const snapshotPreferences = (clientPreferences && clientPreferences.loans_snapshot) ? JSON.parse(clientPreferences.loans_snapshot) : {};
                                    const colours = snapshotPreferences.colours;
                                    if (colours){
                                        StatusColor = colours.reduce((obj, item) => {
                                            obj[item["option_id"]] = item["hex"]
                                            return obj
                                        }, {})
                                    }
                                    statusColor = StatusColor[colours ? deal.model.get('snapshot_funnel_drops_id') : status.toLowerCase()];
                                }

                                html = Handlebars.compile(extendedPopoverTemplate)({
                                    name: deal.model.get('snapshot_name'),
                                    value: Currency.format(deal.model.get('snapshot_currency'), deal.model.get('snapshot_value')),
                                    close: date ? dateFormat.formatMMDDYYYYDate(date) : null,
                                    status: status,
                                    statusColor: statusColor,
                                    phase: deal.model.get('phase').name,
                                    barColor: StatusBarColor,
                                    barWidth: `${deal.model.get('snapshot_completed_percentage') * 100}%`
                                });
                            } else {
                                var organizationName = null;

                                if (deal.model.organizationName) {
                                    organizationName = deal.model.organizationName();
                                }

                                html = Handlebars.compile(popoverTemplate)({
                                    name: deal.model.get('name'),
                                    organization: organizationName || deal.model.get('organization.name'),
                                    value: Currency.shortFormat(currency, getDisplayDealValue(deal)),
                                    close: (date ? dateFormat.shortFormat(date) : null),
                                    owner: owner ? owner.name : deal.model.get('owner.name'),
                                    serviceReq: getServiceRequiredValue(deal)
                                });
                            }

                        if (type === 'funnel') {
                            placement = deal.phase.order === 0 ? 'bottom' : 'top';
                        }
                        else if ((deal.y + tooltipHeight) >= height) {
                            placement = 'top';
                        }

                        var popover = $(this)
                            .popover({
                                trigger: 'hover',
                                animation: false,
                                container: 'body',
                                html: true,
                                content: html,
                                title: extendedPopup ? '' : deal.model.get('name'),
                                placement: placement
                            })
                            .data('popover')
                            .tip()
                            .addClass(extendedPopup ? 'extended-popover-container' : 'bubble-popover');

                        $(this).popover('show');

                        d3.select(this.parentNode).select('circle.hidden').classed('hover', true);
                        d3.select(this).attr('cursor', 'pointer');
                    }

                    setRollover(deal.phase.id, true);
                };

                mouseout = function(d) {
                    // destroy to recreate with up to date data
                    $(this).popover('destroy');
                    d3.select(this.parentNode).select('circle.hidden').classed('hover', false);
                    d3.select(this).attr('cursor', 'default');
                    setRollover(d.phase.id, false);
                };

                updateClass = function() {
                    this.attr('class', function(d) {
                        var highlightClass = '';

                        if ( d.id === highlight ) {
                            highlightClass = ' highlight';
                        }

                        return 'deal ' + d.colorclass + highlightClass;
                    });
                };

                updateDeal = function() {
                    this.attr('opacity', 1)
                        .attr('transform', function(d) {
                            return 'translate(' + d.x + ' ' + d.y + ')';
                        })
                        .call(function() {
                            this.select('circle.hidden')
                                .attr('r', function(d) {
                                    return d.r + 3;
                                });

                            this.select('circle.visible')
                                .attr('r', function(d) {
                                    return d.r;
                                });

                            this.select('circle.selection-circle')
                                .attr('r', function(d) {
                                    return d.r + 3;
                                });
                        });
                };

                this.call(updateClass)
                    .call(d3.behavior.drag()
                            .on('dragstart', drag)
                            .on('drag', move)
                            .on('dragend', drop))
                    .transition()
                        .duration(transitionDuration)
                        .call(updateDeal);

                this.enter().append('g')
                    .call(function() {
                        this.attr('class', 'deal');

                        this.append('circle')
                            .attr('class', 'hidden');

                        this.append('circle')
                            .attr('class', 'visible')
                            .attr('rel', 'tooltip')
                            .attr('title', function(d) {
                                return d.model.get('name');
                            });

                        this.append('text')
                            .attr('class', 'abbreviation')
                            .attr('text-anchor', 'middle')
                            .attr('dominant-baseline', 'central')
                            .style('font-size', dealAbbrvFontSize + 'px');

                        this.append('circle')
                            .attr('class', 'selection-circle')
                            .on('mouseover', mouseover)
                            .on('mouseout', mouseout)
                            .on('click', circleClick);
                    })
                    .call(updateClass)
                    .call(updateDeal)
                    .call(d3.behavior.drag()
                            .on('dragstart', drag)
                            .on('drag', move)
                            .on('dragend', drop))
                    .on('click', click)
                    .attr('opacity', 0)
                    .transition()
                        .duration(transitionDuration)
                        .attr('opacity', 1);

                this.exit()
                    .call(function(d) {
                        _.delay(function() {
                            d.selectAll('circle.selection-circle').each(function(i) {
                                $(this).popover('destroy');
                            });
                        }, transitionDuration);
                    })
                    .transition(transitionDuration)
                    .attr('opacity', 0)
                    .remove();
            });

        svg.selectAll('g.deal').select('.abbreviation')
            .transition()
                .duration(transitionDuration)
                .each('start', function(d) {
                    if (zooming) {
                        d3.select(this).attr('opacity', 0);
                    }
                })
                .each('end', function(d) {
                    manageDealName(d3.select(this), d);
                });

        // Draw container boxes rollover
        onTransition = true;

        svg.selectAll('g.container-rollover')
            .data(containerBoxes, function(d) {
                return d.phase.id;
            })
            .call(function() {
                var update = function() {
                    this.select('rect.rollover')
                        .attr('x', function(d) { return d.x; })
                        .attr('y', function(d) { return d.y; })
                        .attr('width', function(d) { return d.w; })
                        .attr('height', function(d) { return d.h; })
                        .attr('opacity', 0);
                };

                this.transition()
                    .duration(transitionDuration)
                    .call(update)
                    .each('end', function() {
                        onTransition = false;
                        d3.select(this).select('rect.rollover')
                            .transition()
                                .duration(transitionDuration)
                                .attr('opacity', function(d) {
                                    return insideRect({
                                        x: d.x,
                                        y: d.y,
                                        w: d.w,
                                        h: d.h
                                    }, mousePosition[0], mousePosition[1]) ? 1 : 0;
                                });
                    });

                this.enter().append('g')
                    .attr('class', 'container-rollover')
                    .call(function() {
                        this.append('rect')
                            .attr('id', function(d) { return 'container-rollover-' + d.phase.id; })
                            .attr('class', 'rollover');
                    })
                    .call(update);

                this.exit().remove();
            });

        // ...
        if (zooming) {
            _.delay(function() {
                zooming = false;
            }, transitionDuration);
        }
    }

    chart.id = function(_) {
        if (!arguments.length) { return id; }
        id = _;
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        width = _;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _;
        return chart;
    };

    chart.type = function(_) {
        if (!arguments.length) { return type; }
        type = _;
        return chart;
    };

    chart.parent = function(_) {
        if (!arguments.length) { return parent; }
        parent = _;
        return chart;
    };

    chart.zooming = function(_) {
        if (!arguments.length) { return zooming; }
        zooming = _;
        return chart;
    };

    chart.deals = function(_) {
        if (!arguments.length) { return deals; }
        deals = _;
        zoomedPhaseId = null;
        return chart;
    };

    chart.dealsStatus = function(_) {
        if (!arguments.length) { return dealsStatus; }
        dealsStatus = _;
        return chart;
    };

    chart.phases = function(_) {
        if (!arguments.length) { return phases; }
        phases = _;
        zoomedPhaseId = null;
        return chart;
    };

    chart.periods = function(_) {
        if (!arguments.length) { return periods; }
        periods = _;
        return chart;
    };

    chart.funnels = function(_) {
        if (!arguments.length) { return funnels; }
        funnels = _;
        return chart;
    };

    chart.activeFunnel = function(_) {
        if (!arguments.length) { return activeFunnel; }
        activeFunnel = _;
        return chart;
    };

    chart.datetime = function(_) {
        if (!arguments.length) { return datetime; }
        datetime = _;
        return chart;
    };

    chart.dragEnabled = function(_) {
        if (!arguments.length) { return dragEnabled; }
        dragEnabled = _;
        return chart;
    };

    chart.colorkey = function(_) {
        if (!arguments.length) { return colorkey; }
        colorkey = _;
        return chart;
    };

    chart.wonLostPhasesVisible = function(_) {
        if (!arguments.length) { return wonLostPhasesVisible; }
        wonLostPhasesVisible = _;
        return chart;
    }

    chart.dealsTotalValueVisible = function(_) {
        if (!arguments.length) { return dealsTotalValueVisible; }
        dealsTotalValueVisible = _;
        return chart;
    }

    chart.colorScheme = function(deal) {
        switch(colorkey) {
            case 'custom':
                return colorScheme(colorkey, deal, colorkey.replace("custom_colorkey_", ""), customColorKeys);

            case 'forecast':
                return colorScheme(colorkey, deal, dealsStatus);

            case 'closedate':
                return colorScheme(colorkey, deal, current_period_end || currentPeriodEnd());

            default:
                if (colorkey.indexOf('custom_colorkey') === 0) {
                    return colorScheme(colorkey, deal, colorkey.replace("custom_colorkey_", ""), customColorKeys);
                }

                return colorScheme(colorkey, deal);
        }
    }

    chart.highlight = function(_) {
        if (!arguments.length) { return highlight; }
        highlight = _;
        return chart;
    };

    chart.close = function(selection) {
        var svg = selection.selectAll('svg').filter(filterByMyDataId(id));

        svg.selectAll('circle.selection-circle').each(function() {
            $(this).popover('destroy');
        });
    };

    chart.startDraggingLead = function(lead) {
        draggingLead = lead;
        startDragging();
    };

    chart.stopDraggingLead = function() {
        var sd = selectedDropable;

        stopDragging();

        if (sd.id) {
            var info = {
                lead: draggingLead,
                funnelId: sd.isFunnel ? sd.id : activeFunnel
            };

            if (!sd.isFunnel) {
                info.phaseId = sd.id;
            }

            parent.trigger('create-deal-from-lead', info);
        }

        draggingLead = null;
    };

    chart.dashboardView = function(_) {
        if (!arguments.length) { return dashboardView; }
        dashboardView = _;
        return chart;
    }

    chart.section = function(_) {
        if (!arguments.length) { return section; }
        section = _;
        return chart;
    }

    return chart;
}

export default funnel;

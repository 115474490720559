import Handlebars from 'handlebars'
import Backbone from 'backbone'
import FilterQuickItems from 'js/views/filters/quick-items'
import FilterQuickOptionsBase from 'js/views/filters/quick-options-base'
import app from 'js/app.js'


var FilterModel = FilterQuickOptionsBase.FilterModel,
    FilterCollection = FilterQuickOptionsBase.FilterCollection,
    createGlobalSearchableItems = FilterQuickOptionsBase.createGlobalSearchableItems,
    createSearchableItems = FilterQuickOptionsBase.createSearchableItems,
    createAsyncItems = FilterQuickOptionsBase.createAsyncItems;

export default function() {
    var options = [
        {
            view: FilterQuickItems.ButtonView,
            name: function() {
                return 'Search for "' + this.get('search') + '"';
            },
            nameHtml: true,
            hidden: function() {
                return !(this.get('search') && this.get('search').length);
            },
            icon: 'icon-search',
            rules: function() {
                return [{
                    field: 'task_text',
                    operator: 'contains',
                    values: this.get('search')
                }];
            }
        },
        {
            view: FilterQuickItems.SeperatorView,
            hidden: function() {
                return !this.get('search') || !this.get('search').length;
            }
        },
        createGlobalSearchableItems('task_related_id'),
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'quick filters',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'Assigned To Me',
                    icon: function() {
                        var template = Handlebars.compile(
                            '<div class="item-icon thumb thumb-small"><div class="user-initials">{{initials}}</div></div>'
                        );

                        return template({
                            'initials':  app.user.get('name').split(' ').map(function(s) {
                                return s.charAt(0);
                            }).join('')
                        });
                    },
                    iconHtml: true,
                    rules: [{
                        field: 'task_assigned_to_me'
                    }]
                },
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'Created By Me',
                    icon: 'icon-forward',
                    rules: [{
                        field: 'task_created_by_me'
                    }]
                },
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'Completed',
                    icon: 'icon-checkmark3',
                    rules: [{
                        field: 'task_completed'
                    }]
                },
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'Pending',
                    icon: 'icon-checkmark3',
                    rules: [{
                        field: 'task_completed',
                        not: 'true'
                    }]
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'Filter By',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Creator',
                    icon: 'icon-user',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/users',
                            'name',
                            'icon-user',
                            'task_creator_id'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Assignee',
                    icon: 'icon-user',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/users',
                            'name',
                            'icon-user',
                            'task_assignee_id'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Due date',
                    icon: 'icon-calendar',
                    click: false,
                    items: new FilterCollection([
                        {
                            view: FilterQuickItems.GroupView,
                            inline: true,
                            icon: 'icon-calendar',
                            items: new FilterCollection([
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'Today',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'task_due_date',
                                        operator: 'during',
                                        values: 'day'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This week',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'task_due_date',
                                        operator: 'during',
                                        values: 'week'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This month',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'task_due_date',
                                        operator: 'during',
                                        values: 'month'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This quarter',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'task_due_date',
                                        operator: 'during',
                                        values: 'quarter'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This year',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'task_due_date',
                                        operator: 'during',
                                        values: 'year'
                                    }]
                                }
                            ])
                        },
                        {
                            view: FilterQuickItems.GroupView,
                            name: 'Date range...',
                            icon: 'icon-cog2',
                            hidden: false,
                            click: function(ev, view) {
                                view.root.showAdvanced(new Backbone.Model({
                                    items: new Backbone.Collection([{
                                        field: 'task_due_date',
                                        operator: 'between'
                                    }])
                                }));
                            }
                        }
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Related to',
                    icon: 'icon-arrow-right',
                    click: false,
                    items: new FilterCollection([
                        createGlobalSearchableItems('task_related_id', true)
                    ])
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            name: 'All filters',
            icon: 'icon-filters-add',
            hidden: false,
            click: function(ev, view) {
                view.root.showAdvanced();
            }
        }
    ];

    return new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        items: new FilterCollection(options)
    });
};

import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import AppConfig from 'app/app-config';
import Utilities from 'js/utils/utilities';


export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('appointments');

    let columns = [
        columnsCreator.datetime('start_date', 'Start Date'),
        columnsCreator.date('end_date', 'End Date'),
        columnsCreator.text('creator.name', 'From', { filterable: true, filterId: 'appointment_creator_name' }),
        columnsCreator.text('title', 'Title', { filterable: true, filterId: 'appointment_title' }),
        columnsCreator.text('description', 'Description', { filterable: true, filterId: 'appointment_description' }),
        columnsCreator.text('appointment_type', 'Appointment Type', { filterable: true, filterId: 'appointment_appointment_type', filterType: 'appointmentType' }),
        columnsCreator.text('status', 'Status', {
            filterable: true,
            filterType: 'appointmentStatus',
            parseValue: function(value) {
                return Utilities.capitalize(value || '');
            }
        }),
        columnsCreator.tags('tags', 'Appointment Tags')
    ];

    const clientColumnsDef = AppConfig.getValue('group_pages.appointments.columns', []);

    for (const cd of clientColumnsDef) {
        columns.push(columnsCreator[cd[0]](cd[1], cd[2], cd[3]));
    }

    return columns;
};

export function getDefaultColumnsIds() {
    const clientColumns = AppConfig.getValue('group_pages.appointments.default_columns');

    if (clientColumns) {
        return clientColumns;
    }

    return [
        'start_date',
        'end_date',
        'creator.name',
        'appointment_type',
        'status'
    ];
};
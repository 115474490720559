import React from 'react'

import app from 'js/app'
import Tooltip from 'js/react_views/tooltip/tooltip'

import style from './phases_summary.css'


export default class PhasesSummary extends React.Component {
    constructor(props) {
        super(props);

        const clientPreferences = app.user.get('client').preferences;
        const customColorkeys = clientPreferences?.custom_colorkeys ? JSON.parse(clientPreferences.custom_colorkeys) : [];

        this.colorkey = customColorkeys.find(ck => ck.name === this.props.widget.params.colorkey);

        this.state = {
            phases: [],
            phaseSelected: null
        };

        const self = this;

        this.props.widget.addEventListener('funnel:change', function() {
            if (self.mounted) {
                self.fetchData();
            }
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handlePhaseClick(phase) {
        let phaseId = phase.id;

        if (this.state.phaseSelected && this.state.phaseSelected === phase.id) {
            phaseId = null;
        }

        this.props.widget.triggerEvent('plotCards:setPhase', {phaseId: phaseId});
        this.setState({
            phaseSelected: phaseId
        });
    }

    fetchData() {
        // todo: display the loading indicator?
        const self = this;

        this.props.widget.fetcher.get('/widgets/phases_summary', {
            funnel_id: this.props.widget.fetcher.activeFunnel.id
        }, function(data) {
            self.setState({
                phases: data
            });
        });
    }

    getColorStyle(phaseName) {
        if (!this.colorKey) {
            return {};
        }

        const entry = this.colorkey.colours_ex.find(c => c.name === phaseName);

        if (!entry) {
            return {};
        }

        return {
            background: this.colorkey.colours_ex[phase].hex,
            color: this.colorkey.colours_ex[phase].font_hex || 'black'
        };
    }

    render() {
        return (
            <div className={style.phasesSummary}>
                <div className={style.psTitle}>Current Status of Development:</div>

                <div className={style.psPhases}>
                    {this.state.phases.map((phase, pidx) => {
                        return (
                            <div
                                key={`ps_${pidx}`}
                                className={`
                                    ${style.pPhase}
                                    ${this.state.phaseSelected === phase.id ? style.pSelected : ''}
                                `}
                                style={this.getColorStyle(phase.name)}
                                onClick={() => this.handlePhaseClick.bind(this)(phase)}
                            >
                                <div className={style.pValue}>{phase.total}</div>
                                <div
                                    className={style.pName}
                                >
                                    <Tooltip
                                        title={phase.name}
                                    />
                                    {phase.name}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
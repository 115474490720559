import React from 'react';
import classnames from 'classnames';

import vent from 'js/vent';
import app from 'js/app';
import {NewSelect, TagSelect} from 'js/react_views/widgets/select';
import DateTimePicker from 'js/widgets/date-time-picker';
import dateFormat from 'js/utils/date-format';
import AppConfig from 'app/app-config';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import TextManager from 'app/text-manager';

import style from './quick_add_appointment.css'

const TYPE_MAPPING = {
    individual: 'individual',
    individuals: 'individual',
    user: 'user',
    users: 'user',
    opportunities: 'opportunity',
    organizations: 'organization'
};

const M15_MINUTES = 60000 * 15;


class DatetimeField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datetime: props.value,
            value: `${dateFormat.dayToShortText(props.value.getDay())} ${dateFormat.shortFormatWithYear(props.value)} at ${dateFormat.shortFormatTime(props.value)}`,
        };
    }

    handleDatetime(datetime) {
        this.setDateTime(datetime.rawDateTime);
    }

    handleFocus(ev) {
        const self = this;
        const target = $(ev.currentTarget);
        const offset = target.offset();

        const dateTimePicker = new DateTimePicker({
            altField: target,
            css: {
                left: offset.left,
                top: offset.top + target.height() + 5
            },
            date: this.state.datetime,
            time: dateFormat.shortFormatTime(this.state.datetime)
        });

        dateTimePicker.showPicker();

        this.props.parent.listenTo(dateTimePicker, 'date-time:change', this.handleDatetime.bind(this));
    }

    getDateTime() {
        return this.state.datetime;
    }

    setDateTime(datetime) {
        if (!datetime) {
            return;
        }

        const date = dateFormat.parseDate(datetime);

        this.setState({
            datetime: date,
            value: `${dateFormat.dayToShortText(date.getDay())} ${dateFormat.shortFormatWithYear(date)} at ${dateFormat.shortFormatTime(date)}`
        });

        this.props.onDateChanged(datetime);
    }

    render() {
        const extraClasses = this.props.extraClasses || "";
        const classes = classnames({
            [style.dtInput]: true,
            [extraClasses]: true,
        });

        return (
            <div className={style.datetimeField}>
                <input
                    className={classes}
                    style={this.style}
                    value={this.state.value}
                    onFocus={this.handleFocus.bind(this)}
                    onChange={() => {}} // to remove the warning
                />

                {this.state.value && this.props.allowClear &&
                    <div
                        className={`
                            ${style.dtClear}
                            icon-cross
                        `}
                        onClick={() => this.setState({value: ''})}
                    />
                }

                <div className={style.dtTitle}>{this.props.title}</div>
            </div>
        );
    }
}

export default class QuickAddAppointment extends React.Component {
    constructor(props) {
        super(props);

        this.isNew = !this.props.id;
        this.related = null;
        this.appointmentTypes = AppConfig.getValue('appointments.types_list', []);
        this.activeAppointmentType = this.appointmentTypes[0];
        this.guests = [];
        this.tags = props.tags || [];
        this.funnels = props.funnels || [];

        if (this.isNew) {
            this.guests.push({
                id: app.user.get('id'),
                title: app.user.get('name'),
                type: 'user'
            });
        }

        this.state = {
            subject: '',
            description: '',
            loading: false,
            endDatetimeClasses: '',
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.id) {
            this.setState({
                loading: true
            });

            const self = this;

            $.get(`/appointments/${this.props.id}`, function(result) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    loading: false,
                    subject: result.title || '',
                    description: result.description || ''
                });

                self.startDateComponent.setDateTime(result.start_date);
                self.endDateComponent.setDateTime(result.end_date);

                if (result.appointment_type) {
                    self.appointmentTypeComponent.setValue(self.appointmentTypes.find(at => at.title === result.appointment_type));
                }

                self.guests = result.guests.map(guest => {
                    return {
                        id: guest.guest_id,
                        title: guest.guest_name,
                        type: guest.guest_type,
                        status: guest.status
                    };
                });

                self.tags = result.tags.map(tag => {
                    return {
                        id: tag.id,
                        name: tag.name
                    };
                });

                self.funnels = result.funnels.map(funnel => {
                    return {
                        id: funnel.id,
                        name: funnel.name
                    };
                });

                self.guestsComponent.setValue(self.guests);
                self.tagsComponent.setValue(self.tags);
                self.funnelsComponent.setValue(self.funnels);

                if (result.related) {
                    self.relatedComponent.setValue({
                        id: result.related_id,
                        title: result.related.name
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSave() {
        let data = {
            appointment_type: this.activeAppointmentType.title,
            title: this.state.subject || '',
            description: this.state.description || '',
            start_date: this.startDateComponent.getDateTime().toISOString(),
            end_date: this.endDateComponent.getDateTime().toISOString()
        }

        if (this.related) {
            data.related_id = this.related.id;
            data.related_type = this.related.type;
        }

        if (this.guests) {
            if (this.isNew) {
                data.guests = this.guests.map(guest => {
                    return {
                        guest_type: guest.type,
                        guest_id: guest.id,
                        status: app.user.get('id') === guest.id ? 'accepted' : 'pending'
                    };
                });
            } else {
                data.guests = this.guests.map(guest => {
                    return {
                        guest_type: guest.type,
                        guest_id: guest.id,
                        status: guest.status
                    };
                });
            }
        }

        if (this.tags) {
            data.tags = this.tags.map(tag => {
                return {
                    id: tag.id,
                    name: tag.name
                };
            });
        }

        if (this.funnels) {
            data.funnels = this.funnels.map(funnel => {
                return {
                    id: funnel.id,
                    name: funnel.name
                };
            });
        }

        if (this.isNew) {
            data = _.extend(data, {
                creator_is_not_a_guest: true,
                creator_type: 'user',
                creator_id: app.user.get('id'),
                location: '',
                status: 'confirmed'
            });

            $.post('/appointments', JSON.stringify(data), function(result) {
                vent.trigger('close:quickRegion');
                vent.trigger('appointment:created');
            });
        } else {
            $.ajax({
                type: 'PATCH',
                url: `/appointments/${this.props.id}`,
                data: JSON.stringify(data),
                success: function(result) {
                    vent.trigger('close:quickRegion');
                    vent.trigger('appointment:updated');
                }
            });
        }
    }

    handleClose() {
        vent.trigger('close:quickRegion');
    }

    handleSubjectChange(ev) {
        this.setState({
            subject: ev.target.value
        });
    }

    handleDescriptionChange(ev) {
        this.setState({
            description: ev.target.value
        });
    }

    handleRelatedChange(items) {
        const item = items[0];

        this.related = {
            id: item.id,
            type: TYPE_MAPPING[item.type]
        };
    }

    handleAppointmentTypeChange(items) {
        this.activeAppointmentType = items[0];
    }

    handleTagsChange(items) {
        this.tags = items.map(i => {
            return {
                id: i.id,
                name: i.name
            };
        });
    }

    handleFunnelsChange(items) {
        this.funnels = items.map(i => {
            return {
                id: i.id,
                name: i.name
            };
        });
    }

    handleGuestsChange(items) {
        this.guests = items.map(i => {
            return {
                id: i.id,
                title: i.title,
                type: TYPE_MAPPING[i.type],
                status: i.status || (app.user.get('id') === i.id ? 'accepted' : 'pending')
            };
        });
    }

    handleStartDateChange(datetime) {
        // manage endTime never be before starTime
        const startDate = dateFormat.parseDate(datetime);
        const endDate = this.endDateComponent.getDateTime();

        if (endDate && (endDate.getTime() <= startDate.getTime())) {
            this.endDateComponent.setDateTime(new Date(startDate.getTime() + M15_MINUTES));
        }
    }

    handleEndDateChange(datetime) {
        // manage endTime never be before starTime
        const startDate = this.startDateComponent.getDateTime();
        const endDate = dateFormat.parseDate(datetime);

        if (startDate && (endDate.getTime() <= startDate.getTime())) {
            this.startDateComponent.setDateTime(new Date(endDate.getTime() - M15_MINUTES));
        }

        this.checkEndDateBeforeNow(endDate);
    }

    checkEndDateBeforeNow(endDate) {
        const today = new Date();

        if (endDate.getTime() < today.getTime()) {
            this.setState({
                endDatetimeClasses: style.pastDate,
            });
        } else {
            this.setState({
                endDatetimeClasses: '',
            });
        }
    }

    render() {
        const title = this.isNew ? 'New Appointment' : 'Edit Appointment';
        const buttonTitle = this.isNew ? 'Create' : 'Update';
        const startDate = this.props.startDate ? dateFormat.parseDate(this.props.startDate) : new Date();
        const endDate = new Date(startDate.getTime() + M15_MINUTES);

        return (
            <div className={style.quickAddAppointment}>
                <div className={style.header}>
                    {title}
                </div>

                <div className={style.body}>
                    <div className={style.subject}>
                        <input
                            className={style.sInput}
                            value={this.state.subject}
                            onChange={this.handleSubjectChange.bind(this)}
                        />
                        <div className={style.sTitle}>Subject</div>
                    </div>

                    <textarea
                        className={style.description}
                        placeholder='Description'
                        value={this.state.description}
                        onChange={this.handleDescriptionChange.bind(this)}
                    />

                    <div className={style.dates}>
                        <DatetimeField
                            ref={(el) => this.startDateComponent = el}
                            parent={this.props.parent}
                            title='Start Date'
                            value={startDate}
                            allowClear={false}
                            onDateChanged={this.handleStartDateChange.bind(this)}
                        />

                        <DatetimeField
                            ref={(el) => this.endDateComponent = el}
                            parent={this.props.parent}
                            title='End Date'
                            value={endDate}
                            allowClear={false}
                            onDateChanged={this.handleEndDateChange.bind(this)}
                            extraClasses={this.state.endDatetimeClasses}
                        />
                    </div>

                    <div className={style.container}>
                        <div className={style.cElement}>
                            <NewSelect
                                ref={(el) => this.appointmentTypeComponent = el}
                                data={this.appointmentTypes}
                                value={this.activeAppointmentType}
                                text='title'
                                onSelect={this.handleAppointmentTypeChange.bind(this)}
                            />
                        </div>

                        <div className={style.cElement}>
                            <TagSelect
                                ref={(el) => this.guestsComponent = el}
                                value={this.guests}
                                url='/v1/search'
                                text='title'
                                placeholder='Add Guests'
                                options={{
                                    search_parameters: {
                                        types: 'individuals,users'
                                    }
                                }}
                                onChange={this.handleGuestsChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={style.container}>
                        <div className={style.cElement}>
                            <TagSelect
                                ref={(el) => this.tagsComponent = el}
                                url='/tags'
                                value={this.tags}
                                text='name'
                                placeholder='Add Tags'
                                width={324}
                                onChange={this.handleTagsChange.bind(this)}
                            />
                        </div>

                        <div className={style.cElement}>
                            <NewSelect
                                ref={(el) => this.relatedComponent = el}
                                url='/v1/search'
                                text='title'
                                placeholder='Select a Contact or Deal'
                                width={285}
                                options={{
                                    allowClear: true,
                                    search_parameters: {
                                        types: 'individuals,organizations,opportunities',
                                        order_by: 'last_viewed desc'
                                    },
                                    addTagsHover: AppConfig.getValue('individualsSelectTagsHoverTaskActivitiesAppointments', false),
                                }}
                                onSelect={this.handleRelatedChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className={style.container}>
                    <div className={style.wideElement}>
                            <TagSelect
                                ref={(el) => this.funnelsComponent = el}
                                url='/funnels'
                                value={this.funnels}
                                text='name'
                                placeholder={`Add ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`}
                                width={324}
                                onChange={this.handleFunnelsChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div className={style.footer}>
                    <div
                        className={style.close}
                        onClick={this.handleClose.bind(this)}
                    >
                        Close
                    </div>

                    <div
                        className={style.save}
                        onClick={this.handleSave.bind(this)}
                    >
                        {buttonTitle}
                    </div>
                </div>

                {this.state.loading &&
                    <div className={style.loader}>
                        <LoadingIndicator/>
                    </div>
                }
            </div>
        );
    }
}
import CustomFieldsCollection from 'js/collections/custom_fields'
import dateFormat from 'js/utils/date-format'
import AppConfig from 'app/app-config'
import app from 'js/app'

const CfToPhaseMapping = {
    'Sent to Processing': 'Sent to Processing',
    'Submitted to Underwriting': 'Submitted to UW',
    'Conditional Approval': 'Conditional Approval',
    'Closing Disclosure Sent': 'Closing Disclosure Sent',
    'Clear to Close': 'Cleared to Close',
    'Closing Disclosure Sent': 'Docs to Closing Agent',
    'Docs Signed': 'Docs Signed',
    'Funding': 'Won'
};

export default class {
    constructor() {
        this.customFieldIdByName = null;
        this.isActive = AppConfig.getValue('showIPWCampaignTriggerMessage', false);
    }

    getCfNameByPhaseName(phaseName) {
        for (const cf in CfToPhaseMapping) {
            if (CfToPhaseMapping[cf] === phaseName) {
                return cf;
            }
        }

        return null;
    }

    getPhaseNameByCfName(cfName) {
        return CfToPhaseMapping[cfName] || null;
    }

    getPopulatedIpwCustomFields(phaseName, triggerIpw, date, callback) {
        const self = this;

        const populateFields = function() {
            const cfTriggerIpw = self.customFieldIdByName['Manual Phase Change Trigger IPW?'];
            const cfPhaseChanged = self.customFieldIdByName['Manual Phase Changed'];
            const cfPhaseChangedDate = self.customFieldIdByName['Manual Phase Change Date'];
            let data = {};

            if (cfTriggerIpw) {
                data[`custom_field.${cfTriggerIpw}`] = triggerIpw;
            }

            if (cfPhaseChanged) {
                data[`custom_field.${cfPhaseChanged}`] = phaseName;
            }

            if (cfPhaseChangedDate) {
                data[`custom_field.${cfPhaseChangedDate}`] = dateFormat.ISODate(new Date());
            }

            var cfName = self.getCfNameByPhaseName(phaseName);

            if (cfName) {
                const cfPhaseDate = self.customFieldIdByName[cfName];

                if (cfPhaseDate) {
                    data[`custom_field.${cfPhaseDate}`] = date ? dateFormat.ISODate(new Date(date)) : dateFormat.ISODate(new Date());
                }
            }

            callback(data);
        }

        if (!this.customFieldIdByName) {
            if (app.globalData.ipwTriggercustomFieldIdByName) {
                this.customFieldIdByName = _.clone(app.globalData.ipwTriggercustomFieldIdByName);
                populateFields();
            } else {
                const customFields = new CustomFieldsCollection();

                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'opportunities'
                    }],
                    success: function(data) {
                        const requiredCustomFields = ['Manual Phase Change Trigger IPW?', 'Manual Phase Changed', 'Manual Phase Change Date', 'Sent to Processing',
                                                      'Submitted to Underwriting', 'Conditional Approval', 'Closing Disclosure Sent', 'Clear to Close', 'Closing Disclosure Sent',
                                                      'Docs Signed', 'Funding'];
                        const cfByName = {};

                        for (const rcf of requiredCustomFields) {
                            const cf = data.models.find(cf => cf.get('name') === rcf);

                            if (cf) {
                                cfByName[cf.get('name')] = cf.get('id');
                            }
                        }

                        self.customFieldIdByName = cfByName;
                        app.globalData.ipwTriggercustomFieldIdByName = _.clone(cfByName);
                        populateFields();
                    }
                });
            }
        } else {
            populateFields();
        }
    }
}
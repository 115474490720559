import { ANCHOR_BITFIELD } from './defs'

export default {
    sales: {
        name: 'Sales',
        icon: 'icon-tie',
        available: ['testagn', 'connellsdemo', 'connells'],
        content: {
            size: [1647, 848],
            children: [{
                type: 'kpi_simple',
                position: [20, 20],
                size: [150, 122],
                params: {
                    title: 'Contacts Created',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_created',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}'
                            }]]
                        }
                    },
                    auto_refresh: 0.5, // minutes
                    on_data_change_event: 'leads:new',
                    refresh_on: ['funnel:change'],
                    query: {
                        url: '/widgets/num_new_contacts',
                        args: {
                            since: '${date.today.operator[-30]}'
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [190, 20],
                size: [150, 122],
                params: {
                    title: 'Meetings Booked',
                    icon: 'icon-users',
                    link: {
                        event: 'showAppointmentsSection',
                        params: {
                            appointment_type: 'confirmed',
                            filter_rules: [[{
                                field: 'appointment_start_date',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}'
                            }]]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change'],
                    query: {
                        url: '/appointments',
                        args: {
                            return_count: true,
                            status: 'confirmed',
                            start_after: '${date.today.operator[-30]}',
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [20, 160],
                size: [150, 122],
                params: {
                    title: 'No Leads Unactioned',
                    icon: 'icon-warning',
                    link: {
                        event: 'showDealsSection',
                        params: {
                            deal_type: 'all',
                            filter_rules: [[{
                                field: 'opportunity_created',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}'
                            }],[{
                                field: 'opportunity_owner_id',
                                operator: 'equal',
                                values: '${user.salesseeksupport}'
                            }]]
                        }
                    },
                    auto_refresh: 0.5, // minutes
                    on_data_change_event: 'leads:new',
                    refresh_on: ['funnel:change'],
                    query: {
                        url: '/widgets/num_new_opportunities',
                        args: {
                            since: '${date.today.operator[-30]}',
                            owner_id: '${connells.salesseeksupport.id}'
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [190, 160],
                size: [150, 122],
                params: {
                    title: 'On The Market',
                    icon: 'icon-warning',
                    link: {
                        event: 'showDealsSection',
                        params: {
                            deal_type: 'all',
                            filter_rules: [[{
                                field: 'opportunity_activity_modified',
                                operator: 'within_last',
                                values: {
                                    "every": null,
                                    "value": 14,
                                    "unit": "d"
                                },
                                not: true
                            }],[{
                                custom: '${custom_field.dealsArray[e=>e.name==="Agent On Market With"].id}',
                                field: "opportunity_custom",
                                not: true,
                                operator: "empty"
                            }]
                            // ,[{
                            //     field: 'opportunity_created',
                            //     operator: 'more_than',
                            //     values: '${date.today.operator[-30]}'
                            // }]
                            ]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change'],
                    query: {
                        url: '/widgets/num_new_opportunities',
                        args: {
                            agent_on_market: true
                        }
                    }
                }
            }, {
                type: 'kpi_box',
                position: [20, 300],
                size: [320, 240],
                params: {
                    title: 'Outreach (24hrs)',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: 'individual_tags',
                                operator: 'equal',
                                values: {
                                    id: '${active_funnel.tag_id}',
                                    name: '${active_funnel.name}'
                                }
                            }]]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change'],
                    query: {
                        url: '/widgets/get_outreach_box',
                        args: {
                            since: '${date.today.operator[-1]}'
                        }
                    }
                }
            }, {
                type: 'forecast_stats',
                position: [20, 560],
                size: [670, 269],
                anchor: ANCHOR_BITFIELD.top | ANCHOR_BITFIELD.bottom,
                params: {
                    title: 'Forecast',
                    refresh_on: ['leads:new', 'funnel:change', 'activity:save'],
                    query: {
                        url: '/widgets/forecast_widget'
                    }
                }
            },{
                type: 'kpi_simple',
                position: [370, 20],
                size: [150, 122],
                params: {
                    title: 'Formal Pitch',
                    icon: 'icon-checkmark3',
                    link: {
                        event: 'showAppointmentsSection',
                        params: {
                            appointment_type: 'confirmed',
                            filter_rules: [[{
                                field: 'appointment_start_date',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}'
                            }],[{
                                field: 'appointment_title',
                                operator: 'equal',
                                values: 'negotiation meeting'
                            }]]
                        }
                    },
                    refresh_on: ['task:created', 'task:updated'],
                    query: {
                        url: '/appointments',
                        args: {
                            return_count: true,
                            title: 'negotiation meeting',
                            start_after: '${date.today.operator[-30]}',
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [540, 20],
                size: [150, 122],
                params: {
                    title: 'Leads Received',
                    icon: 'icon-checkmark3',
                    link: {
                        event: 'showDealsSection',
                        params: {
                            deal_type: 'all',
                            filter_rules: [[{
                                field: 'opportunity_created',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}'
                            }]]
                        }
                    },
                    query: {
                        url: '/widgets/num_new_opportunities',
                        args: {
                            since: '${date.today.operator[-30]}',
                        }
                    },
                    refresh_on: ['task:created', 'task:updated']
                }
            }, {
                type: 'kpi_simple',
                position: [370, 160],
                size: [150, 122],
                params: {
                    title: 'In Compliance',
                    icon: 'icon-bolt',
                    refresh_on: ['task:created', 'task:updated'],
                    query: {
                        url: '/widgets/num_new_opportunities',
                        args: {
                            deals_in_compliance: true
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [540, 160],
                size: [150, 122],
                params: {
                    title: 'Unclaimed Glenigan Leads',
                    icon: 'icon-info',
                    link: {
                        event: 'showDealsSection',
                        params: {
                            deal_type: 'all',
                            filter_rules: [[{
                                field: 'opportunity_created',
                                operator: 'more_than',
                                values: '${date.today.operator[-30]}',
                            }],[{
                                field: 'opportunity_owner_id',
                                operator: 'equal',
                                values: '${user.salesseeksupport}'
                            }],[{
                                custom: '${custom_field.dealsArray[e=>e.name==="Source"].id}',
                                field:"opportunity_custom",
                                operator:"equal",
                                values:'${custom_field.dealsArray[e=>e.name==="Source"].optionsArray[e=> e.value==="Glenigan"].id}'
                            }]]
                        }
                    },
                    query: {
                        url: '/widgets/num_new_opportunities',
                        args: {
                            since: '${date.today.operator[-30]}',
                            owner_id: '${connells.salesseeksupport.id}',
                            source_is_glenigan: true
                        }
                    },
                    refresh_on: ['task:created', 'task:updated']
                }
            }, {    
                type: 'kpi_box',
                position: [370, 300],
                size: [320, 240],
                params: {
                    title: 'Appointments (24hrs)',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: 'individual_tags',
                                operator: 'equal',
                                values: {
                                    id: '${active_funnel.tag_id}',
                                    name: '${active_funnel.name}'
                                }
                            }]]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change'],
                    query: {
                        url: '/widgets/appointments_box',
                        args: {
                            since: '${date.today.operator[-1]}'
                        }
                    }
                }
            }, {
                type: 'tab_control',
                position: [720, 20],
                size: [910, 810],
                min_size: [400, 600],
                expandable: true,
                anchor: ANCHOR_BITFIELD.all,
                tabs: [{
                    title: 'Regional Overview',
                    style: {

                    },
                    children: [{
                        type: 'region_list',
                        position: [0, 0],
                        size: [895, 760],
                        anchor: 15,
                        params: {
                            query: {
                                url: '/widgets/league_table'
                            }
                        }
                    }]
                }]
            }]
        }
    },
    'new-team': {
        'name': 'Team',
        'icon': 'icon-users',
        'content': {
            'size': [1300, 748],
            'children': [{
                'type': 'list_simple',
                'position': [960, 20],
                'size': [320, 708],
                'min_size': [320, 300],
                'anchor': 7,
                'params': {
                    'title': 'New Leads',
                    'item_type': 'lead',
                    'button': {
                        'title': 'New Lead',
                        'event': 'showNewLeadView'
                    },
                    'refresh_on': ['leads:new', 'funnel:change', 'activity:save'],
                    'query': {
                        'url': '/leads',
                        'args': {
                            'rows': 20,
                            'order_by': 'became_lead_date desc',
                            'tag_ids': '${active_funnel.tag_id}',
                            'my_team': true,
                            'has_activity': false
                        }
                    }
                }
            }, {
                'type': 'tab_control',
                'position': [20, 20],
                'size': [320, 708],
                'min_size': [320, 300],
                'anchor': 5,
                'tabs': [{
                    'title': 'Activity ${activities.fetch_info}',
                    'children': [{
                        'type': 'activities_list',
                        'position': [0, 0],
                        'size': [320, 668],
                        'anchor': 15
                    }]
                }, {
                    'title': 'Tasks',
                    'children': [{
                        'type': 'list_simple',
                        'position': [0, 0],
                        'size': [320, 668],
                        'anchor': 15,
                        'params': {
                            'title': 'Tasks',
                            'item_type': 'task',
                            'button': {
                                'title': 'New Task',
                                'event': 'showQuickAddTaskView'
                            },
                            'query': {
                                'url': '/tasks',
                                'args': {
                                    'rows': 20,
                                    'assigned_to_me': true,
                                    'order_by': 'due_date asc'
                                }
                            },
                            'refresh_on': ['task:created', 'task:updated']
                        }
                    }]
                }]
            }, {
                'type': 'tab_control',
                'position': [360, 20],
                'size': [580, 708],
                'min_size': [400, 600],
                'anchor': 15,
                'tabs': [{
                    'title': 'Funnel',
                    'children': [{
                        'type': 'funnel',
                        'position': [0, 0],
                        'size': [580, 628],
                        'anchor': 15,
                        'params': {
                            'view': 'funnel'
                        }
                    }, {
                        'type': 'funnel_colorkey',
                        'position': [0, 638],
                        'size': [580, 30],
                        'anchor': 14
                    }]
                }, {
                    'title': 'Momentum',
                    'children': [{
                        'type': 'funnel',
                        'position': [0, 0],
                        'size': [580, 628],
                        'anchor': 15,
                        'params': {
                            'view': 'sos'
                        }
                    }, {
                        'type': 'funnel_colorkey',
                        'position': [0, 638],
                        'size': [580, 30],
                        'anchor': 14
                    }]
                }, {
                    'title': 'Site Map',
                    'available': 'site_maps',
                    'style': {
                        'content_bg_color': '#ffffff80'
                    },
                    'children': [{
                        'type': 'site_map',
                        'position': [10, 0],
                        'size': [580, 628],
                        'anchor': 15
                    }, {
                        'type': 'funnel_colorkey',
                        'position': [0, 638],
                        'size': [580, 30],
                        'anchor': 14
                    }]
                }, {
                    'title': 'Forecast',
                    'available': 'deals_calendar',
                    'children': [{
                        'type': 'deals_calendar',
                        'position': [0, 0],
                        'size': [580, 668],
                        'anchor': 15
                    }]
                }, {
                    'title': 'Task Calendar',
                    'children': [{
                        'type': 'task_calendar',
                        'position': [0, 0],
                        'size': [580, 668],
                        'anchor': 15
                    }]
                }]
            }]
        }
    },
    'team-beta': {
        'name': 'Team Beta',
        'icon': 'icon-users',
        'available': ['testagn', 'londonsquare'],
        'content': {
            'size': [1300, 748],
            'children': [{
                type: 'kpi_simple',
                position: [960, 20],
                anchor: ANCHOR_BITFIELD.right,
                size: [150, 122],
                params: {
                    title: 'New Leads (24h)',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: 'individual_tags',
                                operator: 'equal',
                                values: {
                                    id: '${active_funnel.tag_id}',
                                    name: '${active_funnel.name}'
                                }
                            }], [{
                                field: 'individual_became_lead',
                                operator: 'more_than',
                                values: '${date.today}'
                            }]]
                        }
                    },
                    auto_refresh: 0.5, // minutes
                    on_data_change_event: 'leads:new',
                    refresh_on: ['funnel:change'],
                    query: {
                        url: '/widgets/num_new_leads',
                        args: {
                            tag_ids: '${active_funnel.tag_id}'
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [1130, 20],
                size: [150, 122],
                anchor: ANCHOR_BITFIELD.right,
                params: {
                    title: 'Total Leads',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: 'individual_tags',
                                operator: 'equal',
                                values: {
                                    id: '${active_funnel.tag_id}',
                                    name: '${active_funnel.name}'
                                }
                            }]]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change'],
                    query: {
                        url: '/widgets/num_total_leads',
                        args: {
                            tag_ids: '${active_funnel.tag_id}'
                        }
                    }
                }
            }, {
                type: 'tab_control',
                position: [960, 160],
                size: [320, 568],
                min_size: [320, 300],
                anchor: 7,
                tabs: [{
                    title: 'Leads to Action',
                    children: [{
                        'type': 'list_simple',
                        'position': [0, 0],
                        'size': [320, 518],
                        'anchor': 15,
                        'params': {
                            'title': 'Leads to Action',
                            'title_visible_on_rect_state': ['leftDocked'],
                            'item_type': 'lead',
                            'button': {
                                'title': 'New Lead',
                                'event': 'showNewLeadView',
                                'params': {
                                    'preloadedFields': {
                                        'tags': ['${active_funnel.tag}']
                                    }
                                }
                            },
                            'refresh_on': ['leads:new', 'funnel:change', 'activity:save'],
                            'query': {
                                'url': '/pdcrm_dashboard_leads',
                                'args': {
                                    'rows': 20,
                                    'order_by': 'modified desc',
                                    'tag_ids': '${active_funnel.tag_id}',
                                    'my_team': false,
                                    'has_activity': false
                                }
                            }
                        }
                    }]
                }]
            }, {
                type: 'kpi_simple',
                position: [20, 20],
                size: [150, 122],
                params: {
                    title: 'Formal Pitch',
                    icon: 'icon-checkmark3',
                    link: {
                        event: 'showTasksSection',
                        params: {
                            filter_rules: [[{
                                field: 'task_tags',
                                operator: 'equal',
                                values: '${active_funnel.tag}'
                            }], [{
                                field: 'task_completed',
                                not: true
                            }]]
                        }
                    },
                    refresh_on: ['task:created', 'task:updated', 'funnel:change'],
                    query: {
                        url: '/tasks',
                        args: {
                            return_count: true,
                            tag_ids: '${active_funnel.tag_id}',
                            completed: false
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [190, 20],
                size: [150, 122],
                params: {
                    title: 'Overdue',
                    icon: 'icon-warning',
                    link: {
                        event: 'showTasksSection',
                        params: {
                            filter_rules: [[{
                                field: 'task_tags',
                                operator: 'equal',
                                values: '${active_funnel.tag}'
                            }], [{
                                field: 'task_completed',
                                not: true
                            }], [{
                                field: 'task_due_date',
                                operator: 'less_than',
                                values: '${datetime.today}'
                            }]]
                        }
                    },
                    query: {
                        url: '/tasks',
                        args: {
                            return_count: true,
                            tag_ids: '${active_funnel.tag_id}',
                            overdue: true,
                            completed: false
                        }
                    },
                    refresh_on: ['task:created', 'task:updated', 'funnel:change']
                }
            }, {
                'type': 'tab_control',
                'position': [20, 160],
                'size': [320, 568],
                'min_size': [320, 300],
                'anchor': 5,
                'tabs': [{
                    'title': 'Tasks',
                    'children': [{
                        'type': 'list_simple',
                        'position': [0, 0],
                        'size': [320, 518],
                        'anchor': 15,
                        'params': {
                            'item_type': 'task',
                            'button': {
                                'title': 'New Task',
                                'event': 'showQuickAddTaskView',
                                'params': {
                                    'preloadedFields': {
                                        'tags': ['${active_funnel.tag}']
                                    }
                                }
                            },
                            'query': {
                                'url': '/tasks',
                                'args': {
                                    'rows': 20,
                                    'tag_ids': '${active_funnel.tag_id}',
                                    'order_by': 'due_date asc'
                                }
                            },
                            'refresh_on': ['task:created', 'task:updated', 'funnel:change']
                        }
                    }]
                }, {
                    'available': 'activities_list',
                    'title': 'Activities ${activities.fetch_info}',
                    'children': [{
                        'type': 'activities_list',
                        'position': [0, 0],
                        'size': [320, 518],
                        'anchor': 15
                    }]
                }]
            }, {
                'type': 'tab_control',
                'position': [360, 20],
                'size': [580, 708],
                'min_size': [400, 600],
                'anchor': 15,
                'expandable': true,
                'tabs': [{
                    'title': 'Funnel',
                    'children': [{
                        'type': 'funnel',
                        'position': [20, 10],
                        'size': [540, 638],
                        'anchor': 15,
                        'params': {
                            'view': 'funnel'
                        }
                    }]
                }, {
                    'title': 'Momentum',
                    'children': [{
                        'type': 'funnel',
                        'position': [20, 10],
                        'size': [540, 638],
                        'anchor': 15,
                        'params': {
                            'view': 'sos'
                        }
                    }]
                }, {
                    'title': 'Site Map',
                    'available': 'site_maps',
                    'on_selected': 'fullscreen',
                    'children': [{
                        'type': 'site_map',
                        'position': [20, 10],
                        'size': [540, 638],
                        'anchor': 15
                    }]
                }, {
                    'title': 'My Calendar',
                    'children': [{
                        'type': 'task_calendar',
                        'position': [10, 0],
                        'size': [560, 648],
                        'anchor': 15,
                        'params': {
                            'refresh_on': ['task:created', 'task:updated', 'appointment:created', 'appointment:updated', 'funnel:change'],
                            'entities': ['tasks', 'appointments'],
                            'query': {
                                'args': {
                                    'tag_ids': '${active_funnel.tag_id}',
                                    'status': 'confirmed,pending,completed'
                                }
                            },
                            'creation_params': {
                                'tasks': {
                                    'preloadedFields': {
                                        'tags': ['${active_funnel.tag}']
                                    }
                                },
                                'appointments': {
                                    'preloadedFields': {
                                        'tags': ['${active_funnel.tag}']
                                    }
                                }
                            }
                        }
                    }]
                }]
            }]
        }
    },
    // TEMPORARY solution for persimmon, to add a new widget "development_head_counter" to their dashboard
    'persimmon-team-beta': {
        'name': 'Team Beta',
        'icon': 'icon-users',
        'available': ['persimmondemo', 'persimmonhomes', 'testagn', 'persimmonhomesqa', 'persimmonhomesstaging'],
        'content': {
            'size': [1300, 748],
            'children': [{
                type: 'kpi_simple',
                position: [960, 20],
                anchor: ANCHOR_BITFIELD.right,
                size: [150, 122],
                params: {
                    title: 'New Leads (24h)',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: '${individual_development_filter}',
                                operator: 'equal',
                                values: '${individual_development_filter_value}'
                            }], [{
                                field: 'individual_became_lead',
                                operator: 'more_than',
                                values: '${date.today}'
                            }]]
                        }
                    },
                    auto_refresh: 0.5, // minutes
                    on_data_change_event: 'leads:new',
                    refresh_on: ['funnel:change', 'cluster:change'],
                    query: {
                        url: '/widgets/num_new_leads',
                        args: {
                            funnel_ids: '${active_funnel.funnel_id}'
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [1130, 20],
                size: [150, 122],
                anchor: ANCHOR_BITFIELD.right,
                params: {
                    title: 'Total Leads',
                    icon: 'icon-users',
                    link: {
                        event: 'showContactsSection',
                        params: {
                            contact_type: 'individuals',
                            filter_rules: [[{
                                field: 'individual_source',
                                operator: 'empty',
                                not: true
                            }], [{
                                field: '${individual_development_filter}',
                                operator: 'equal',
                                values: '${individual_development_filter_value}'
                            }]]
                        }
                    },
                    refresh_on: ['leads:new', 'funnel:change', 'cluster:change'],
                    query: {
                        url: '/widgets/num_total_leads',
                        args: {
                            funnel_ids: '${active_funnel.funnel_id}'
                        }
                    }
                }
            }, {
                type: 'development_head_counter',
                position: [960, 160],
                size: [320, 122],
                anchor: ANCHOR_BITFIELD.right,
                params: {
                    title: 'Visitors (Today)',
                    icon: 'icon-users',
                    refresh_on: ['funnel:change', 'cluster:change'],
                    query: {
                        url: '/funnel_stats',
                        args: {
                            funnel_ids: '${active_funnel.funnel_id}',
                        }
                    },
                },
            }, {
                type: 'tab_control',
                position: [960, 300],
                size: [320, 430],
                min_size: [320, 300],
                anchor: 7,
                tabs: [{
                    title: 'Leads to Action',
                    children: [{
                        'type': 'list_simple',
                        'position': [0, 0],
                        'size': [320, 380],
                        'anchor': 15,
                        'params': {
                            'title': 'Leads to Action',
                            'title_visible_on_rect_state': ['leftDocked'],
                            'item_type': 'lead',
                            'button': {
                                'title': 'New Lead',
                                'event': 'showNewLeadView',
                                'params': {
                                    'preloadedFields': {
                                        'funnels': ['${active_funnel.funnel}']
                                    }
                                }
                            },
                            'refresh_on': ['leads:new', 'funnel:change', 'activity:save', 'cluster:change'],
                            'query': {
                                'url': '/pdcrm_dashboard_leads',
                                'args': {
                                    'rows': 20,
                                    'order_by': 'modified desc',
                                    'funnel_ids': '${active_funnel.funnel_id}',
                                    'my_team': false,
                                    'has_activity': false
                                }
                            }
                        }
                    }]
                }]
            }, {
                type: 'kpi_simple',
                position: [20, 20],
                size: [150, 122],
                params: {
                    title: 'Total Tasks',
                    icon: 'icon-checkmark3',
                    link: {
                        event: 'showTasksSection',
                        params: {
                            filter_rules: [[{
                                field: '${task_development_filter}',
                                operator: 'equal',
                                values: '${task_development_filter_value}'
                            }], [{
                                field: 'task_completed',
                                not: true
                            }]]
                        }
                    },
                    refresh_on: ['task:created', 'task:updated', 'funnel:change', 'cluster:change'],
                    query: {
                        url: '/tasks',
                        args: {
                            return_count: true,
                            funnel_ids: '${active_funnel.funnel_id}',
                            completed: false
                        }
                    }
                }
            }, {
                type: 'kpi_simple',
                position: [190, 20],
                size: [150, 122],
                params: {
                    title: 'Overdue',
                    icon: 'icon-warning',
                    link: {
                        event: 'showTasksSection',
                        params: {
                            filter_rules: [[{
                                field: '${task_development_filter}',
                                operator: 'equal',
                                values: '${task_development_filter_value}'
                            }], [{
                                field: 'task_completed',
                                not: true
                            }], [{
                                field: 'task_due_date',
                                operator: 'less_than',
                                values: '${datetime.today}'
                            }]]
                        }
                    },
                    query: {
                        url: '/tasks',
                        args: {
                            return_count: true,
                            funnel_ids: '${active_funnel.funnel_id}',
                            overdue: true,
                            completed: false
                        }
                    },
                    refresh_on: ['task:created', 'task:updated', 'funnel:change', 'cluster:change']
                }
            }, {
                'type': 'tab_control',
                'position': [20, 160],
                'size': [320, 568],
                'min_size': [320, 300],
                'anchor': 5,
                'tabs': [{
                    'title': 'Tasks',
                    'children': [{
                        'type': 'list_simple',
                        'position': [0, 0],
                        'size': [320, 518],
                        'anchor': 15,
                        'params': {
                            'item_type': 'task',
                            'button': {
                                'title': 'New Task',
                                'event': 'showQuickAddTaskView',
                                'params': {
                                    'preloadedFields': {
                                        'funnels': ['${active_funnel.funnel}']
                                    }
                                }
                            },
                            'query': {
                                'url': '/tasks',
                                'args': {
                                    'rows': 20,
                                    'funnel_ids': '${active_funnel.funnel_id}',
                                    'order_by': 'due_date asc'
                                }
                            },
                            'refresh_on': ['task:created', 'task:updated', 'funnel:change', 'cluster:change']
                        }
                    }]
                }, {
                    'available': 'activities_list',
                    'title': 'Activities ${activities.fetch_info}',
                    'children': [{
                        'type': 'activities_list',
                        'position': [0, 0],
                        'size': [320, 518],
                        'anchor': 15
                    }]
                }]
            }, {
                'type': 'tab_control',
                'position': [360, 20],
                'size': [580, 708],
                'min_size': [400, 600],
                'anchor': 15,
                'expandable': true,
                'tabs': [{
                    'title': 'Funnel',
                    'children': [{
                        'type': 'funnel',
                        'position': [20, 10],
                        'size': [540, 638],
                        'anchor': 15,
                        'params': {
                            'view': 'funnel'
                        }
                    }]
                },{
                    'title': 'Site Map',
                    'available': 'site_maps',
                    'on_selected': 'fullscreen',
                    'children': [{
                        'type': 'site_map',
                        'position': [20, 10],
                        'size': [540, 638],
                        'anchor': 15
                    }]
                }, {
                    'title': 'My Calendar',
                    'children': [{
                        'type': 'task_calendar',
                        'position': [10, 0],
                        'size': [560, 648],
                        'anchor': 15,
                        'params': {
                            'refresh_on': ['task:created', 'task:updated', 'appointment:created', 'appointment:updated', 'funnel:change', 'cluster:change'],
                            'entities': ['tasks', 'appointments'],
                            'query': {
                                'args': {
                                    'funnel_ids': '${active_funnel.funnel_id}',
                                    'status': 'confirmed,pending,completed'
                                }
                            },
                            'creation_params': {
                                'tasks': {
                                    'preloadedFields': {
                                        'funnels': ['${active_funnel.funnel}']
                                    }
                                },
                                'appointments': {
                                    'preloadedFields': {
                                        'funnels': ['${active_funnel.funnel}']
                                    }
                                }
                            }
                        }
                    }]
                }]
            }]
        }
    },
};
import security from 'js/utils/security';
import app from 'js/app';

import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import { getAllColumns as individualsGetAllColumns } from 'app_v2/sections/individuals/table_columns';

const getDraftContextMenuItems = (row) => {
    const hasEditPermission = security.checkPermission('edit', new Backbone.Model({id: row.id, permissions: row.permissions}));

    return [{
        id: 'edit',
        title: 'Edit',
        disabled: function() {
            return !hasEditPermission;
        }
    }, {
        id: 'permissions',
        title: 'Set Permissions',
        disabled: function() {
            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
        }
    }, {
        id: 'preview',
        title: 'Preview',
    }, {
        id: 'duplicate',
        title: 'Duplicate'
    }, {
        id: 'delete',
        title: 'Delete',
        confirmation: true,
        confirmationId: 'delete',
        confirmationName: row.name || 'this campaign',
        disabled: function() {
            return !hasEditPermission;
        }
    }];
}

const getScheduledContextMenuItems = (row) => {
    const hasEditPermission = security.checkPermission('edit', new Backbone.Model({id: row.id, permissions: row.permissions}));

    return [{
        id: 'edit',
        title: 'Edit Schedule',
        disabled: function() {
            return !hasEditPermission;
        }
    }, {
        id: 'permissions',
        title: 'Set Permissions',
        disabled: function() {
            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
        }
    }, {
        id: 'cancel',
        title: 'Cancel Campaign',
        confirmation: true,
        confirmationId: 'cancelCampaign',
        confirmationName: row.name || 'this campaign',
        disabled: function() {
            return !hasEditPermission;
        }
    }, {
        id: 'preview',
        title: 'Preview',
    }, {
        id: 'duplicate',
        title: 'Duplicate'
    }];
}

const getInsightsContextMenuItems = (row) => {
    const hasEditPermission = security.checkPermission('edit', new Backbone.Model({id: row.id, permissions: row.permissions}));

    return [{
        id: 'summary',
        title: 'Summary Sheet'
    }, {
        id: 'preview',
        title: 'Preview'
    }, {
        id: 'duplicate',
        title: 'Duplicate'
    }, {
        id: 'permissions',
        title: 'Set Permissions',
        disabled: function() {
            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
        }
    }];
}

export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('campaigns');

    const drafts = [
        columnsCreator.context('context_menu', getDraftContextMenuItems),
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.group('to_group', 'Send To', { groupEntityType: 'individuals', groupable: true, clickable: true, sortable: true, sortId: 'to_group.name' }),
        columnsCreator.text('from', 'Send From', { sortId: 'from.name' }),
        columnsCreator.date('created', 'Created'),
        columnsCreator.date('modified', 'Updated'),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const templates = [
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.text('from', 'Send From', { sortId: 'from.name' }),
        columnsCreator.date('created', 'Created'),
        columnsCreator.date('modified', 'Updated'),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const automations = [
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.automations('automations', 'Automations'),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const system = [
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const approvals = [
        columnsCreator.schedule('campaign_schedule', 'Schedule Date'),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.user('creator', 'Created By'),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const scheduled = [
        columnsCreator.context('context_menu', getScheduledContextMenuItems),
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.group('to_group', 'Send To', { groupEntityType: 'individuals', groupable: true, clickable: true, sortable: true, sortId: 'to_group.name' }),
        columnsCreator.text('from', 'Send From', { sortId: 'from.name' }),
        columnsCreator.date('created', 'Created'),
        columnsCreator.schedule('campaign_schedule', 'Scheduled For', { sortable: true, sortId: 'campaign_schedule.when_utc' }),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const insights = [
        columnsCreator.context('context_menu', getInsightsContextMenuItems),
        columnsCreator.icon('type', 'Type', { noBorder: true, width: 70, filterable: true, filterId: 'campaign_campaign_type', filterType: 'campaignType', groupable: true }),
        columnsCreator.text('name', 'Campaign', { filterable: true, filterId: 'campaign_name' }),
        columnsCreator.text('subject', 'Subject', { filterable: true }),
        columnsCreator.group('to_group', 'Send To', { groupEntityType: 'individuals', groupable: true, clickable: true, sortable: true, sortId: 'to_group.name' }),
        columnsCreator.text('from', 'Send From', { sortId: 'from.name' }),
        columnsCreator.date('sent', 'Sent'),
        columnsCreator.campaignStat('email_event_stats.send', 'Sent', { fieldPath: ['email_event_stats', 'send'] }),
        columnsCreator.campaignStat('email_event_stats.delivered', 'Delivered', { fieldPath: ['email_event_stats', 'delivered'], width: 120 }),
        columnsCreator.campaignStat('email_event_stats.open', 'Opened', { fieldPath: ['email_event_stats', 'open'], color: 'lightgreen' }),
        columnsCreator.campaignStat('email_event_stats.click', 'Clicked', { fieldPath: ['email_event_stats', 'click'], color: '#72e28a' }),
        columnsCreator.campaignStat('email_event_stats.unsubscribed', 'Unsub', { fieldPath: ['email_event_stats', 'unsubscribed'], color: 'brown' }),
        columnsCreator.campaignStat('email_event_stats.spam', 'Spam', { fieldPath: ['email_event_stats', 'spam'], color: '#f28f5a' }),
        columnsCreator.campaignStat('email_event_stats.bounce', 'Bounce', { fieldPath: ['email_event_stats', 'bounce'], color: '#f0d34d' }),
        columnsCreator.campaignStat('email_event_stats.open_rate', 'Open Rate', { fieldPath: ['email_event_stats', 'open_rate'], valueSuffix: '%' }),
        columnsCreator.campaignStat('email_event_stats.click_rate', 'Click Rate', { fieldPath: ['email_event_stats', 'click_rate'], valueSuffix: '%' }),
        columnsCreator.campaignStat('email_event_stats.delivered_rate', 'Delivery Rate', { fieldPath: ['email_event_stats', 'delivered_rate'], valueSuffix: '%' }),
        columnsCreator.campaignStat('email_event_stats.hard_bounce', 'Hard Bounce', { fieldPath: ['email_event_stats', 'hard_bounce'] }),
        columnsCreator.campaignStat('email_event_stats.soft_bounce', 'Soft Bounce', { fieldPath: ['email_event_stats', 'soft_bounce'] }),
        columnsCreator.campaignStat('email_event_stats.unsubscribed_all', 'All Emails Unsubscribed', { fieldPath: ['email_event_stats', 'unsubscribed_all'] }),
        columnsCreator.campaignStat('email_event_stats.unsubscribed_list', 'List Only Unsubscribed', { fieldPath: ['email_event_stats', 'unsubscribed_list'] }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true })
    ];

    const audiences = [
        columnsCreator.text('name', 'Name', { sortable: true, groupable: true, style: 'link' }),
        columnsCreator.user('owner', 'Owner', { sortable: true, groupable: true, sortId: 'owner.name' }),
        columnsCreator.date('created', 'Created', { filterable: false, sortable: true, sortableAnniversary: false, groupable: true })
    ];

    return {
        drafts,
        templates,
        automations,
        system,
        approvals,
        scheduled,
        insights,
        audiences,
        audienceGroup: individualsGetAllColumns()
    };
};

export function getDefaultColumnsIds() {
    const drafts = [
        'context_menu',
        'type',
        'name',
        'to_group',
        'from',
        'created',
        'modified',
        'subject',
        'tags'
    ];

    const templates = [
        'type',
        'name',
        'from',
        'created',
        'modified',
        'subject',
        'tags'
    ];

    const automations = [
        'type',
        'name',
        'automations',
        'tags'
    ];

    const system = [
        'type',
        'name',
        'subject',
        'tags'
    ];

    const approvals = [
        'campaign_schedule',
        'name',
        'subject',
        'creator',
        'tags'
    ];

    const scheduled = [
        'context_menu',
        'type',
        'name',
        'to_group',
        'from',
        'created',
        'campaign_schedule',
        'subject',
        'tags'
    ];

    const insights = [
        'context_menu',
        'type',
        'name',
        'subject',
        'to_group',
        'sent',
        'tags',
        'email_event_stats.send',
        'email_event_stats.delivered',
        'email_event_stats.open',
        'email_event_stats.click',
        'email_event_stats.unsubscribed',
        'email_event_stats.spam',
        'email_event_stats.bounce'
    ];

    const audiences = [
        'name',
        'owner',
        'created'
    ];

    const audienceGroup = [
        'created',
        'first_name',
        'last_name',
        'email',
        'phone',
        'source',
        'tags'
    ];

    return {
        drafts,
        templates,
        automations,
        system,
        approvals,
        scheduled,
        insights,
        audiences,
        audienceGroup
    };
};
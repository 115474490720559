import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import MessageBox from 'js/views/message_box';
import dateFormat from 'js/utils/date-format';

import style from './campaign_creation.css';


class LeftSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            to: '',
            numContacts: 0
        };

        this.testIndividualId = null;
    }

    componentDidMount() {
        this.mounted = true;

        const isTextMessage = this.props.campaignData.campaign_type === 'message';

        let params = {
            rows: 1,
            order_by: 'first_name asc,last_name asc',
            by_group_id: this.props.campaignData.to_group.id
        };

        if (isTextMessage) {
            params.has_phone = true;
            params.unsubscribed_all_messages = false;
        } else {
            params.has_email = true;
            params.unsubscribed_all = false;
        }

        const self = this;

        $.get(`/individuals?${$.param(params)}`, function(data, _, request) {
            if (!self.mounted) {
                return;
            }

            self.testIndividualId = data[0].id;

            self.setState({
                numContacts: parseInt(request.getResponseHeader('Records-Total'))
            });
        });

        $.get(`/groups/${params.by_group_id}`, function(data) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                to: data.name
            });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getNumContacts() {
        return this.state.numContacts;
    }

    getTestIndividualId() {
        return this.testIndividualId;
    }

    render() {
        const cdata = this.props.campaignData;
        const isTextMessage = cdata.campaign_type === 'message';
        let from = '';

        if (cdata.from_user) {
            from = isTextMessage ? cdata.from_user.phone_number : cdata.from_user.email_address;
        } else if (cdata.from_custom_field) {
            from = cdata.from_custom_field.name;
        }

        let scheduleDate = '';
        let scheduleTime = '';

        if (cdata.campaign_schedule) {
            const date = dateFormat.parseDate(cdata.campaign_schedule.when);
            scheduleDate = `${dateFormat.shortFormatDay(date.getDay())}, ${dateFormat.shortFormatWithYear(date)}`;
            scheduleTime = `${dateFormat.shortFormatTime(date)} (${cdata.campaign_schedule?.timezone || 'Current Timezone'})`;
        }

        const modified = dateFormat.parseDate(cdata.modified);

        return (
            <div
                className={`${style.sBlock} ${style.aLeft}`}
                style={{
                    width: 'calc((100% - 40px) / 3)'
                }}
            >
                <div style={{
                    fontSize: '18px',
                    marginBottom: '10px'
                }}>
                    {isTextMessage ? 'Message' : 'Email'} Summary
                </div>

                <div
                    className={style.aRow}
                    style={{fontSize: '15px'}}
                >
                    <div className={style.aTextMuted}>Created by</div>
                    <div style={{marginLeft: '5px'}}>{cdata.creator.name}</div>
                </div>

                <div
                    className={style.aRow}
                    style={{
                        fontSize: '13px',
                        marginBottom: '20px'
                    }}
                >
                    <div className={style.aTextMuted}>Last edited</div>
                    <div style={{marginLeft: '3px'}}>{dateFormat.shortFormatTime(modified)} {dateFormat.shortFormatWithYear(modified)}</div>
                </div>

                <div className={style.aFieldTitle}>From:</div>

                <div className={style.aFieldValue}>
                    <div
                        className={style.aValue}
                        title={from}
                    >
                        {from}
                    </div>
                </div>

                {!isTextMessage &&
                    <div
                        style={{
                            width: '100%',
                            marginTop: '15px'
                        }}
                    >
                        <div className={style.aFieldTitle}>Subject Line:</div>

                        <div className={style.aFieldValue}>
                            <div
                                className={style.aValue}
                                title={cdata.subject || ''}
                            >
                                {cdata.subject || ''}
                            </div>
                        </div>
                    </div>
                }

                <div
                    className={style.aButton}
                    style={{marginTop: '15px'}}
                    onClick={() => this.props.gotoStep('preview')}
                >
                    Edit {isTextMessage ? 'From' : 'Subject + From'}
                </div>

                <div
                    className={style.aFieldTitle}
                    style={{marginTop: '30px'}}
                >
                    To Mailing List or Recipient(s):
                </div>

                <div
                    className={style.aFieldValue}
                >
                    <div
                        className={style.aValue}
                        title={this.state.to}
                    >
                        {this.state.to}
                    </div>

                    <div className={style.aNumContacts}>
                        {this.state.numContacts} contact{this.state.numContacts !== 1 ? 's' : ''}
                    </div>
                </div>

                <div
                    className={style.aButton}
                    style={{marginTop: '15px', marginBottom: '30px'}}
                    onClick={() => this.props.gotoStep('recipients')}
                >
                    Edit Recipients
                </div>

                <div className={style.aFieldTitle}>Scheduled Send Date:</div>
                <div className={style.aFieldValue}>
                    <div
                        className={style.aValue}
                        title={scheduleDate}
                    >
                        {scheduleDate}
                    </div>
                </div>

                <div
                    className={style.aFieldTitle}
                    style={{marginTop: '15px'}}
                >
                    Scheduled Send Time:
                </div>

                <div className={style.aFieldValue}>
                    <div
                        className={style.aValue}
                        title={scheduleTime}
                    >
                        {scheduleTime}
                    </div>
                </div>

                <div
                    className={style.aButton}
                    style={{marginTop: '15px'}}
                    onClick={() => this.props.gotoStep('launch')}
                >
                    Edit Schedule
                </div>
            </div>
        );
    }
}

class MessagePreview extends React.Component {
    render() {
        return (
            <div className={style.pMessage}>
                {this.props.content}
            </div>
        );
    }
}

class HTMLPreview extends React.Component {
    componentDidMount() {
        const self = this;

        _.defer(function() {
            $(self.iframe).contents().find('html').get(0).innerHTML = self.props.content;
        });
    }

    render() {
        return (
            <iframe
                ref={(el) => this.iframe = el}
                style={{
                    width: this.props.mobileView ? '320px' : '100%'
                }}
                sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation'
            />
        );
    }
}

class RightSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileView: false
        };
    }

    handleSendTest() {
        const defUser = [{
            id: app.user.get('id'),
            name: app.user.get('name')
        }];

        const entity = this.props.campaignData.campaign_type === 'message' ? 'message' : 'email';
        const self = this;

        MessageBox.showOk({
            icon: 'icon-user',
            message: 'Which users would you like to send a test version of this ' + entity + ' to?',
            accept_button_text: 'Send Now'
        }, this.props.parent, function(idsList) {
            self.props.sendTest(idsList);
        }, {
            type: 'tagSelect',
            value: defUser,
            idValue: defUser[0].id
        });
    }

    render() {
        const cdata = this.props.campaignData;
        const isTextMessage = cdata.campaign_type === 'message';
        const warningMessage = isTextMessage ? 'This is for preview purposes only.' : 'This is for preview purposes only. Different email clients may display this email differently.';
        const PreviewComponent = isTextMessage ? MessagePreview : HTMLPreview;
        let from = '';

        if (cdata.from_user) {
            from = isTextMessage ? cdata.from_user.phone_number : cdata.from_user.email_address;
        } else if (cdata.from_custom_field) {
            from = cdata.from_custom_field.name;
        }

        return (
            <div
                className={`${style.sBlock} ${style.pRight}`}
                style={{
                    width: 'calc((((100% - 40px) / 3) + 10px) * 2)',
                    padding: 0
                }}
            >
                <div className={style.rHeader}>
                    <div className={style.rInfo}>
                        <div className={style.rRow}>
                            <div className={style.rName}>From:</div>
                            <div className={style.rValue}>{from}</div>
                        </div>

                        {!isTextMessage &&
                            <div className={style.rRow}>
                                <div className={style.rName}>Subject:</div>
                                <div className={style.rValue}>{cdata.subject}</div>
                            </div>
                        }
                    </div>

                    <div className={style.rActions}>
                        <div
                            className={style.rButton}
                            onClick={() => this.props.gotoStep('compose')}
                        >
                            Edit Content
                        </div>

                        <div
                            className={style.rButton}
                            style={{
                                background: '#F5A427',
                                marginLeft: '10px'
                            }}
                            onClick={this.handleSendTest.bind(this)}
                        >
                            Send a Test {isTextMessage ? 'Message' : 'Email'}
                        </div>

                        {!isTextMessage &&
                            <div
                                className={`
                                    ${style.rIcon}
                                    icon-desktop
                                `}
                                style={{
                                    marginLeft: '20px',
                                    marginRight: '10px'
                                }}
                                onClick={() => this.setState({ mobileView: false })}
                            />
                        }

                        {!isTextMessage &&
                            <div
                                className={`
                                    ${style.rIcon}
                                    icon-phone1
                                `}
                                onClick={() => this.setState({ mobileView: true })}
                            />
                        }
                    </div>
                </div>

                <div className={style.rWarning}>
                    {warningMessage}
                </div>

                <div className={style.rPreview}>
                    <PreviewComponent
                        content={cdata.content}
                        mobileView={this.state.mobileView}
                    />
                </div>
            </div>
        );
    }
}

class StepApproval extends React.Component {
    handleTestSend(idsList) {
        if (idsList.length === 0) {
            return;
        }

        const individualId = this.leftSection.getTestIndividualId();

        if (!individualId) {
            return;
        }

        this.props.sendTest({
            to: idsList.join(','),
            individualId: individualId
        });
    }

    isReadyToLaunch() {
        if (this.leftSection.getNumContacts() === 0) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'Unable to send - the selected group contains no contacts. Please add recipients or choose another group.'
            }, this.props.parent);

            return false;
        }

        return true;
    }

    getNumContacts() {
        return this.leftSection.getNumContacts();
    }

    render() {
        return (
            <div className={`${style.cStep} ${style.cApproval}`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <LeftSection
                        ref={(el) => this.leftSection = el}
                        campaignData={this.props.campaignData}
                        parent={this.props.parent}
                        gotoStep={this.props.gotoStep}
                    />

                    <RightSection
                        campaignData={this.props.campaignData}
                        parent={this.props.parent}
                        gotoStep={this.props.gotoStep}
                        sendTest={this.handleTestSend.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default StepApproval;
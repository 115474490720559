import backboneSelect2 from 'js/widgets/backbone-select2';
import docusignTemplate from 'templates/individuals/docusign.handlebars';

var DocusignView = Marionette.Layout.extend({
    template: Handlebars.compile(docusignTemplate),
    templateHelpers: function() {
        return {
            name: this.options.model.get('full_name')
        };
    },
    ui: {
        loader: '.ds-loader',
        content: '.ds-info',
        footer: '.ds-footer',
        templateSelector: '.ds-template-selector',
        roleSelector: '.ds-role-selector'
    },
    events: {
        'click .ds-cancel': function() {
            this.trigger('close');
        },
        'click .ds-prepare': function() {
            this.showLoading(true);

            var self = this;

            $.ajax({
                type: 'POST',
                url: '/integrations/docusign/envelope_url',
                dataType: 'json',
                contentType: "application/json;charset=utf-8",
                data: JSON.stringify({
                    individual_id: this.model.get('id'),
                    role_name: this.roleSelector.$el.select2('val'),
                    template_id: this.templateSelector.$el.select2('val'),
                    redirect_url: window.location.href
                }),
                success: function(data) {
                    window.open(data.url, '_self');
                },
                error: function() {
                    self.trigger('close');
                }
            });
        }
    },
    onRender: function() {
        var self = this;

        $.get('/integrations/docusign/envelope_url', function(data) {
            if (data.length === 0) {
                window.open('https://app.docusign.com/templates', '_self');
            }
            else {
                var templates = _.map(data, function(d) {
                    return {
                        id: d.template_id,
                        name: d.template_name,
                        roles: _.map(d.role_names, function(r) { return { id: r }; })
                    };
                });

                // ...
                self.roleSelector = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.roleSelector,
                    text: 'id',
                    data: templates[0].roles,
                    value: templates[0].roles[0],
                    options: {
                        placeholder: 'Select a role',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'role-select-popover popover'
                    }
                });

                self.templateSelector = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.templateSelector,
                    text: 'name',
                    data: templates,
                    value: templates[0],
                    options: {
                        placeholder: 'Select a template',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'template-select-popover popover'
                    }
                });

                self.listenTo(self.templateSelector, 'change', function(item) {
                    self.roleSelector.options.data.results = item.roles;

                    if (item.roles.length > 0) {
                        self.roleSelector.$el.select2('val', item.roles[0].id);
                    }
                });

                self.showLoading(false);
            }
        });
    },
    onShow: function() {
        this.$el.parent().attr('id', 'docusign-region');
    },
    showLoading: function(show) {
        this.ui.loader.toggleClass('hide', !show);
        this.ui.content.toggleClass('hide', show);
        this.ui.footer.toggleClass('hide', show);
    }
});

export default DocusignView;

import React from 'react';
import classnames from 'classnames';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

import dateFormat from 'js/utils/date-format';

import DropTargetContainer from 'js/react_views/detail_view_components/DropTargetContainer';
import Toolbar from './Toolbar';
import {TagList} from 'js/react_views/detail_view_components/tag-list';
import {
    CommunicationField,
    LocationField,
    TextField,
    CheckboxField,
    IndividualField,
    OrganizationField,
    OpportunityField,
    ImageField,
    UrlField,
    ChecklistsProgress,
    EntityListField,
    QuickFieldsView
} from 'js/react_views/detail_fields/detail_fields';
import Individuals from './Individuals';
import Deals from './Deals';
import RelatedFiles from 'js/react_views/detail_view_components/related-files.js';
import GroupMembership from 'js/react_views/detail_view_components/group-membership';
import ArchiveAddress from 'js/react_views/detail_view_components/archive-address';
import ContentPane from 'js/react_views/detail_view_components/content-pane';
import ViewPaneNav from 'js/react_views/detail_view_components/ViewPaneNav';
import MoreInfo from 'js/react_views/detail_view_components/MoreInfo';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';

import style from './OrganizationView.css';
import headerStyle from './Header.css';
import detailsStyle from './Details.css';

class Header extends React.Component {
    renderLogo() {
        return (
            <div className={headerStyle.logo}>
                <div className={headerStyle.logoPlaceholder}>
                    <i
                        className={classnames(
                            headerStyle.logoPlaceholderIcon,
                            "icon-home"
                        )} />
                </div>
                {this.props.logoUrl &&
                    <div
                        className={headerStyle.logoImage}
                        style={{
                            backgroundImage: 'url(' + this.props.logoUrl + ')'
                        }} />
                }
            </div>
        );
    }

    renderName() {
        return (
            <h3 className={headerStyle.name}>
                {this.props.name}
            </h3>
        );
    }

    renderOrganizationLabel() {
        const iconClasses = classnames(
            headerStyle.organizationIcon,
            'icon-home'
        );

        return (
            <span className={headerStyle.organizationLabel}>
                <i className={iconClasses} />
                {TextManager.parseText("${ID_ORGANIZATION, capitalize}")}
            </span>
        );
    }

    render() {
        const { tags, funnels} = this.props;
        return (
            <section className={headerStyle.Header}>
                {this.renderLogo()}
                {this.renderName()}
                {this.renderOrganizationLabel()}
                <TagList tags={tags} caseValue="tags"/>
                <TagList tags={funnels} caseValue="funnels" />
            </section>
        )
    }
}

class Details extends React.Component {
    render() {
        return (
            <section className={detailsStyle.Details}>
                {this.props.children}
            </section>
        );
    }
}

const DragHandle = SortableHandle(() => <i className={classnames({ "icon-drag": true, [style.handle]: true })} />);

const SortableItem = SortableElement(({data}) =>
    <div className={classnames({[style.SortableItem]: true, [style.Hidden]: data.hidden})}><CustomFieldGroup key={data.id} data={data}/></div>
);

const SortableList = SortableContainer(({items}) => {
  return (
    <div className={style.SortableContainer}>
      {items.map((value, index) => (
        <SortableItem key={"item-" + index} index={index} data={value} />
      ))}
    </div>
  );
});

class SortableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const items = arrayMove(prevState.items, oldIndex, newIndex);
            items[0].updateOrder(items);
            return { items: items };
        });
    };

    render() {
        return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis={"y"} />;
    }
}

class CustomFieldGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.data.state !== 'expanded'
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const newState = this.state.collapsed ? "expanded" : "collapsed";
        this.props.data.update(this.props.data.id, { state: newState });
        this.setState((prevState) => {
            return { collapsed: !prevState.collapsed };
        });
    }

    render() {
        if (this.props.data.fields.length === 0) {
            return null;
        }

        let customFields = _.compact(_.map(
            this.props.data.fields,
            (item) => {
                if (!item.value && !item.originalValue && !_.isNumber(item.value) && !_.isBoolean(item.value)) {
                    return (null);
                }

                if (Array.isArray(item.value) && item.value.length === 0) {
                    return (null);
                }

                if (item.type === 'dropDown' && item.params && item.params.multiSelect){
                    let values = [];
                    item.originalValue.split(',').map(valueId => {
                        values.push(item.options.find(opt => opt.id === valueId).value || '');
                    })
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={values.join(', ')} />
                    );
                }

                if (item.type === 'date') {
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={dateFormat.entityInformationFormat(dateFormat.createDateIgnoringTZ(item.value))} />
                    );
                }
                else if (item.type === 'checkbox') {
                    if (item.value || AppConfig.getValue('custom_fields.overview.show_false_checkboxes')) {
                        return <CheckboxField key={item.id} label={item.label} value={item.value} />;
                    }

                    return null;
                }
                else if (item.type === 'individual') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    return (
                        <IndividualField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId}
                            photoUrl={item.photoUrl}
                            organizationName={item.organizationName} />
                    );
                }
                else if (item.type === 'organization') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    return (
                        <OrganizationField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId} />
                    );
                }
                else if (item.type === 'opportunity') {
                    if (item.params && item.params.multiSelect) {
                        return (
                            <EntityListField
                                key={item.id}
                                label={item.label}
                                value={item.value}
                            />
                        );
                    }

                    return (
                        <OpportunityField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId} />
                    );
                }
                else if (item.type === 'urlImage') {
                    return (
                        <ImageField
                            key={item.id}
                            label={item.label}
                            url={item.value} />
                    );
                }
                else if (item.type === 'url') {
                    return (
                        <UrlField
                            key={item.id}
                            label={item.label}
                            url={item.value}
                            params={item.params} />
                    );
                }
                else if (item.type === 'paragraph') {
                    return (
                        <TextField key={item.id} label={item.label} isParagraph={true} value={item.value} />
                    );
                }
                else if (item.type === 'list') {
                    return (
                        <EntityListField
                            key={item.id}
                            label={item.label}
                            value={item.value}
                        />
                    );
                }
                return (
                    <TextField key={item.id} label={item.label} value={item.value} />
                );
            }
        ));

        if (customFields.length === 0) {
            return null;
        }

        const toggleClasses = classnames({
            "icon-caret-right": this.state.collapsed,
            "icon-caret-down": !this.state.collapsed,
            [style.toggle]: true
        });

        return (
            <div className={style.GroupContainer}>
                <div className={style.CustomFieldGroup}>
                    <div className={style.header}>
                        <i className={toggleClasses} onClick={this.toggle} />
                        {this.props.data.name}
                        <DragHandle />
                    </div>
                    {
                        !this.state.collapsed &&
                        <div className={style.container}>
                            {customFields}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class CDDCheckerButton extends React.Component {
    render() {
        return (
            <div className={style.CDDChecker}>
                <div
                    className={style.cButton}
                    onClick={this.props.onCDDChecker}
                >
                    CDD / COR Check
                </div>
            </div>
        );
    }
}

class OrganizationView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'details',
        };

        this.bindMethods();
    }

    bindMethods() {
        this.handleNavTabClick = this.handleNavTabClick.bind(this);
        this.handleCommunicationClick = this.handleCommunicationClick.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleNavTabClick(tab) {
        this.setState({ activeTab: tab });
    }

    handleCommunicationClick(ev, id, href) {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onCommunicationClick(id, href);
    }

    renderCommunicationItems(medium, singular) {
        const { organization } = this.props;
        let items = _.where(organization.communication, {medium: medium});

        if (singular) {
            items = items.slice(0, 1);
        }

        return _.map(items, (item) =>
            <CommunicationField
                key={item.id}
                medium={item.medium}
                name={item.name}
                value={item.value}
                comments={item.comments}
                href={item.href}
                onClick={
                    medium !== 'social' && medium !== 'website' &&
                    ((ev) => { this.handleCommunicationClick(ev, item.id, item.href) })
                } />
        );
    }

    renderLocations() {
        const { organization } = this.props;

        if (AppConfig.getValue('disableLocationsSectionOnIndividualAndOrganization')) {
            return null;
        }

        return _.map(organization.locations, (item) =>
            <LocationField
                key={item.id}
                label={item.name}
                location={item.address}
                comments={item.comments} />
        );
    }

    renderCustomFields() {
        const { relatedData } = this.props;

        let sortableFieldGroups;
        if (relatedData.processedCustomFields) {
            // we only want groups with fields having a valid value
            var groups = _.compact(this.props.relatedData.processedCustomFields.map(group => {
                for (var i = 0; i < group.fields.length; ++i) {
                    if ('value' in group.fields[i]) {
                        if ((group.fields[i].type !== 'checkbox') || group.fields[i].value) {
                            return group;
                        }
                    }
                }
                return null;
            }));

            sortableFieldGroups = <SortableComponent items={groups} />
        }

        return sortableFieldGroups;
    }

    renderDetails() {
        const {
            relatedData,
            userId,
            isEditable,
            onClickNextIndividualPage,
            onClickPrevIndividualPage,
            onIndividualNavigate,
            onIndividualsAdd,
            onNewIndividual,
            onDealNavigate,
            onClickNextDealPage,
            onClickPrevDealPage,
            onLinkClick,
            onNewDeal,
            onClickNextFilePage,
            onClickPrevFilePage,
            onFileAdd,
            onDnDFileUpload,
            onFileDelete,
            onGroupSearch,
            onGroupAdd,
            onGroupRemove,
            onGroupClick,
            archiveEmailAddress
        } = this.props;

        const showCDDCheckerButton = AppConfig.getValue('organizations.cdd_checker.visible', false, this.props.organization);

        return (
            <Details>
                {this.renderCommunicationItems('website', true)}
                {this.renderCommunicationItems('phone')}
                {this.renderCommunicationItems('email')}
                {this.renderCommunicationItems('social')}
                {this.renderLocations()}
                {this.renderCustomFields()}

                {showCDDCheckerButton &&
                    <CDDCheckerButton
                        onCDDChecker={this.props.onCDDChecker}
                    />
                }

                <Individuals
                    individuals={relatedData.individuals}
                    onClickNext={onClickNextIndividualPage}
                    onClickPrev={onClickPrevIndividualPage}
                    onNavigate={onIndividualNavigate}
                    onAdd={onIndividualsAdd}
                    onLinkClick={onLinkClick}
                    onNew={onNewIndividual} />
                <Deals
                    deals={relatedData.deals}
                    onDealNavigate={onDealNavigate}
                    onNew={onNewDeal}
                    onClickNext={onClickNextDealPage}
                    onClickPrev={onClickPrevDealPage} />
                <RelatedFiles
                    files={relatedData.files}
                    onClickNext={onClickNextFilePage}
                    onClickPrev={onClickPrevFilePage}
                    userId={userId}
                    isEditable={isEditable}
                    onAdd={onFileAdd}
                    onUpload={onDnDFileUpload}
                    onDelete={onFileDelete}
                    fileUpload={relatedData.fileUpload} />
                <GroupMembership
                    items={relatedData.groups}
                    onSearch={onGroupSearch}
                    onAdd={onGroupAdd}
                    onRemove={onGroupRemove}
                    onGroupClick={onGroupClick} />
                <ArchiveAddress email={archiveEmailAddress} />
            </Details>
        );
    }

    handleDrop(files) {
        this.props.onDnDFileUpload(files);
    }

    render() {
        const {
            organization,
            relatedData,
            onEdit,
            onShowPermissionView,
            onHistoryClick,
            onClose,
            isEditable,
            checklists
        } = this.props;

        return (
            <DropTargetContainer
                onDrop={isEditable && this.handleDrop}>

                <Toolbar
                    ownerName={organization.owner.name}
                    onEdit={onEdit}
                    onShowPermissionView={onShowPermissionView}
                    onHistoryClick={onHistoryClick}
                    onClose={onClose} />
                <ContentPane>
                    <Header
                        name={organization.name}
                        logoUrl={relatedData.logoUrl}
                        tags={organization.tags}
                        funnels={organization.funnels}
                    />
                    {checklists && checklists.length > 0 && <div style={{marginTop: '-20px'}}>
                        <ChecklistsProgress checklists={checklists}/>
                    </div>}
                    <QuickFieldsView
                        entity={organization}
                        entityType='organizations'
                    />
                </ContentPane>
                <ViewPaneNav
                    activeTab={this.state.activeTab}
                    onTabClick={this.handleNavTabClick} />
                <ContentPane>
                    {
                        this.state.activeTab === 'moreInfo' &&
                        <MoreInfo comments={organization.comments} />
                    }
                    {
                        this.state.activeTab === 'details' &&
                        this.renderDetails()
                    }
                </ContentPane>
            </DropTargetContainer>
        );
    }
}

export default OrganizationView;

import React from 'react';

import app from 'js/app';
import dateFormat from 'js/utils/date-format';
import TimeZoneSelectView from 'app/widgets/time-zone-selector/time-zone-selector';
import TextManager from 'app/text-manager';
import Utilities from 'js/utils/utilities'
import currencyFormat from 'js/utils/currency_format';
import vent from 'js/vent';
import MessageBox from 'js/views/message_box';

import style from './table.css';


const FORECAST_STATUSES = {
    none: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}'),
    none_upside: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}'),
    committed_downside: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}'),
    committed: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
};

export const getRowCellValue = function(column, value, forGrouping) {
    if (column.parseValue) {
        return column.parseValue(value) || '';
    }

    switch (column.type) {
        case 'date':
            return value ? dateFormat.shortFormatWithYear(dateFormat.parseDate(value)) : '';

        case 'datetime': {
            const date = value ? dateFormat.parseDate(value) : null;
            return date ? `${dateFormat.shortFormatTime(date)} ${dateFormat.shortFormat(date)}` : '';
        }

        case 'schedule': {
            if (!value) {
                return '';
            }

            return `${dateFormat.formatRawScheduledDate(value.when)} (${TimeZoneSelectView.getTZName(value.timezone)})`;
        }

        case 'exText':
            return value || {};

        case 'forecastStatus':
            return FORECAST_STATUSES[value] || '';

        case 'leadSource':
            return value?.name || '';

        case 'opportunity':
        case 'organization':
        case 'user':
        case 'group':
        case 'automation':
            return value?.name || '';

        case 'automations':
            if (forGrouping) {
                return (value || []).map(a => a.name).join(', ');
            }

            return value || [];

        case 'individual':
            return value?.full_name || '';

        case 'tags':
            return (value || []).map(t => t.name).join(', ');

        case 'currency':
            return currencyFormat(column.currency || app.user.get('client').default_currency, parseFloat(value) || 0);

        case 'bool':
            if (_.isBoolean(value)) {
                if (column.customValues) {
                    return column.customValues[value ? 0 : 1];
                }

                return value;
            }

            return false;

        case 'activity':
            return value?.note || '';
    }

    if (value === null || _.isUndefined(value)) {
        return '';
    }

    return value;
}

class RowCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editing: false,
            editingValue: ''
        };
    }

    startEditing() {
        let editingValue = this.props.row[this.props.column.id] || '';

        if (this.props.column.initialEditValue) {
            editingValue = this.props.column.initialEditValue(this.props.row);
        }

        this.setState({
            editing: true,
            editingValue: editingValue
        });

        const self = this;

        _.defer(function() {
            self.inputComponent.focus();
        });
    }

    endEditing(updateValue) {
        if (updateValue) {
            let value = this.state.editingValue;

            if (this.props.column.type === 'currency') {
                value = parseFloat(value) || 0;
            }

            this.props.row[this.props.column.id] = value;

            if (this.props.onCellValueEdited) {
                this.props.onCellValueEdited(this.props.row.id, this.props.column.id, value);
            }
        }

        this.setState({
            editing: false,
            editingValue: ''
        });
    }

    handleInputChange(ev) {
        this.setState({
            editingValue: ev.target.value
        });
    }

    handleKeyDown(ev) {
        if (ev.key === 'Enter') {
            this.endEditing(true);
        } else if (ev.key === 'Escape') {
            this.endEditing(false);
        }
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        if (this.props.column.color) {
            if (_.isFunction(this.props.column.color)) {
                cellStyle.color = this.props.column.color(this.props.row);
            } else {
                cellStyle.color = this.props.column.color;
            }
        }

        if (this.props.column.style) {
            if (_.isFunction(this.props.column.style)) {
                _.extend(cellStyle, this.props.column.style(this.props.row));
            }
        }

        let value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        if (value && this.props.column.valueSuffix) {
            value += this.props.column.valueSuffix;
        }

        if (this.state.editing) {
            return (
                <div
                    className={`
                        ${style.rCell}
                    `}
                    style={{width: this.props.column.finalWidth}}
                >
                    <input
                        ref={(el) => this.inputComponent = el}
                        className={style.cInput}
                        value={this.state.editingValue}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={() => this.endEditing.bind(this)(false)}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    />
                </div>
            );
        }

        let editable = false;

        if ('editable' in this.props.column) {
            if (_.isFunction(this.props.column.editable)) {
                editable = this.props.column.editable(this.props.row);
            } else {
                editable = this.props.column.editable;
            }
        }

        return (
            <div
                className={`
                    ${style.rCell}
                    ${this.props.isSummaryTitle ? style.cSummaryTitle : ''}
                    ${this.props.isSummaryValue ? style.cSummaryValue : ''}
                    ${this.props.column.style === 'link' ? style.cLink : ''}
                `}
                style={cellStyle}
            >
                <div className={style.cContent} title={value}>
                    {value}
                </div>

                {editable &&
                    <div
                        className={`${style.cIconEdit} icon-pencil`}
                        onClick={this.startEditing.bind(this)}
                    />
                }
            </div>
        );
    }
}

class RowCellBool extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: !!this.props.row[this.props.column.id]
        };

        this.editable = false;

        if ('editable' in props.column) {
            if (_.isFunction(props.column.editable)) {
                this.editable = props.column.editable(props.row);
            } else {
                this.editable = props.column.editable;
            }
        }
    }

    toggleValue() {
        if (!this.editable) {
            return;
        }

        const checked = !this.state.checked;

        this.setState({
            checked: checked
        });

        this.props.row[this.props.column.id] = checked;

        if (this.props.onCellValueEdited) {
            this.props.onCellValueEdited(this.props.row.id, this.props.column.id, checked);
        }
    }

    render() {
        let visible = true;

        if ('visible' in this.props.column) {
            if (_.isFunction(this.props.column.visible)) {
                visible = this.props.column.visible(this.props.row);
            } else {
                visible = this.props.column.visible;
            }
        }

        let cellStyle = {
            width: this.props.column.finalWidth,
            justifyContent: 'center'
        };

        if (!visible) {
            cellStyle.visibility = 'hidden';
        }

        const checkStyle = {};

        if (!this.editable) {
            checkStyle.cursor = 'not-allowed';
        }

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div
                    className={`
                        ${style.cCheckbox}
                        ${this.state.checked ? style.cbChecked : ''}
                    `}
                    style={checkStyle}
                    onClick={this.toggleValue.bind(this)}
                >
                    <div className={`${style.cbMark} icon-checkmark3`}/>
                </div>
            </div>
        );
    }
}

class RowCellCampaignStat extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5309')) {
            const handler = MessageBox.showNoBtn({
                message: 'Creating static group...'
            }, this.props.parent, { staticRegion: true });

            const field = this.props.column.id;

            $.post(`/campaigns/${this.props.row.id}/individuals`, JSON.stringify({
                name: `${this.props.row.name || 'Campaign'} ${field} ${dateFormat.formatDDMMYYYYDate(new Date())}`,
                having: field
            }), function(groupId) {
                handler.reset();
                window.location.href = `/#contacts/group/individuals/${groupId}`;
            });
        } else {
            const self = this;
            const dotPosition = this.props.column.id.lastIndexOf('.');
            const filterField = `campaign_stats_${this.props.column.id.substring(dotPosition + 1)}`;

            $.post('/individuals/filters', JSON.stringify({
                rules: [[{
                    field: filterField,
                    operator: 'any',
                    values: {
                        campaign: {
                            id: this.props.row.id,
                            name: this.props.row.name
                        }
                    }
                }]]
            }), function(filter) {
                const columns = ['state', 'open', 'click'].map(c => `campaigns.${self.props.row.id}.${c}`);
                window.location = `#contacts/individuals?filter_id=${filter.id}&extra_columns=${columns.join(',')}&sp=`;
            });
        }
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        if (this.props.column.color) {
            cellStyle.color = this.props.column.color;
        }

        let value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        if (value && this.props.column.valueSuffix) {
            value += this.props.column.valueSuffix;
        }

        return (
            <div
                className={style.rCell}
                style={cellStyle}
                onClick={this.handleClick.bind(this)}
            >
                <div className={style.cContent} title={value}>
                    {value}
                </div>
            </div>
        );
    }
}

class RowCellComment extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        let value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        if (Utilities.isHTML(value)) {
            value = Utilities.getTextFromHTMLContent(value)

            value = Utilities.replaceAll(value, '<br>', '');
            value = Utilities.replaceAll(value, '↵', '');
        }

        return (
            <div
                className={style.rCell}
                style={cellStyle}
                title={value}
            >
                <div className={style.cContent} title={value}>
                    {value}
                </div>
            </div>
        );
    }
}

class RowCellComposed extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        let values = [];

        for (const id of this.props.column.ids) {
            values.push(this.props.row[id] || '');
        }

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cExTextContainer}>
                    <div className={style.etFirstLine} title={values[0]}>{values[0]}</div>

                    {values.map((value, vid) => {
                        if (vid === 0) {
                            return null;
                        }

                        return (
                            <div
                                key={`rccv_${vid}`}
                                className={style.etSecondLine}
                                title={value}
                            >
                                {value}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class RowCellLocations extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]) || [];

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                {value.map((l, idx) => {
                    return (
                        <div
                            key={`loc_${idx}`}
                            className={style.cLocations}
                        >
                            <div className={style.lLocation}>
                                {l.name && <div className={style.lName} title={l.name}>{l.name}</div>}
                                <div className={style.lAddress} title={l.address || ''}>{l.address || ''}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class RowCellCommunications extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]) || [];

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cCommunications}>
                    {value.map((c, idx) => {
                        return (
                            <div
                                key={`comm_${idx}`}
                                className={style.cCommunication}
                            >
                                {c.name && <div className={style.cName} title={c.name}>{c.name}</div>}
                                <div className={style.cValue} title={c.value || ''}>{c.value || ''}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class RowCellSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };
    }

    handleSelect(ev) {
        ev.stopPropagation();

        const selected = !this.state.selected;

        this.setState({
            selected: selected
        });

        if (selected) {
            this.props.onSelected();
        } else {
            this.props.onUnselected();
        }
    }

    setSelect(selected) {
        this.setState({
            selected: selected
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.rCell}
                    ${style.cSelector}
                    ${this.state.selected ? style.cSelected : ''}
                `}
                style={{width: this.props.column.finalWidth}}
                onClick={this.handleSelect.bind(this)}
            >
                {this.state.selected ? (
                    <div className='icon-checkmark'/>
                ) : (
                    <div className='icon-checkmark2'/>
                )}
            </div>
        );
    }
}

class RowCellDate extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cContent} title={value}>
                    {value}
                </div>
            </div>
        );
    }
}

class RowCellDateTime extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cContent} title={value}>
                    {value}
                </div>
            </div>
        );
    }
}

class RowCellEntity extends React.Component {
    handleClick(ev) {
        if (!this.props.column.clickable) {
            return;
        }

        const entity = this.props.row[this.props.column.id];

        if (!entity) {
            return;
        }

        ev.stopPropagation();

        let url = null;

        if (this.props.column.type === 'group') {
            switch (this.props.column.groupEntityType) {
                case 'individuals':
                    url = `/#contacts/group/individuals/${entity.id}`;
                    break;
            }
        }

        if (!url) {
            return;
        }

        window.location.href = url;
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div
                className={`
                    ${style.rCell}
                    ${this.props.column.clickable ? style.cLink : ''}
                `}
                style={cellStyle}
                onClick={this.handleClick.bind(this)}
            >
                <div
                    className={style.cContent}
                    title={value}
                >
                    {value}
                </div>
            </div>
        );
    }
}

class RowCellEntityList extends React.Component {
    handleClick(ev, entity) {
        ev.stopPropagation();

        let url = null;

        switch (this.props.column.type) {
            case 'automations':
                url = `/#automations/${entity.id}`;
                break;
        }

        if (!url) {
            return;
        }

        window.location.href = url;
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div className={style.rCell}>
                <div className={style.cEntityContainer}>
                    {value.map(entity => {
                        return (
                            <div
                                key={`e_${entity.id}`}
                                className={style.ecEntity}
                                onClick={(ev) => this.handleClick.bind(this)(ev, entity)}
                            >
                                {entity.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class RowCellContext extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();
        this.props.onContext(this.props.column, this.buttonComponent.getBoundingClientRect());
    }

    render() {
        const cellStyle = {
            width: this.props.column.finalWidth
        };

        return (
            <div
                ref={(el) => this.buttonComponent = el}
                className={`
                    ${style.rCell}
                    ${style.cIcon}
                `}
                style={cellStyle}
                onClick={this.handleClick.bind(this)}
            >
                <div className={style.cContextMenu}>
                    <div className='icon-dotdotdot'/>
                </div>
            </div>
        );
    }
}

class RowCellIcon extends React.Component {
    render() {
        const cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);
        const showBorder = value && !this.props.column.noBorder;

        return (
            <div
                className={`
                    ${style.rCell}
                    ${style.cIcon}
                `}
                style={cellStyle}
            >
                <div
                    className={`
                        ${style.cIconContainer}
                        ${showBorder ? '' : style.cNoBorder}
                    `}
                >
                    <div className={value}/>
                </div>
            </div>
        );
    }
}

class RowCellImage extends React.Component {
    constructor(props) {
        super(props);
        this.value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);
    }

    handleClick(ev) {
        ev.stopPropagation();

        if (!this.props.column.clickable) {
            return;
        }

        if (!this.value) {
            return;
        }

        const ext = Utilities.getUrlFileExtension(this.value);

        if (!ext) {
            return;
        }

        vent.trigger('file-preview:show', {
            ext: `.${ext}`,
            url: this.value
        });
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        let imageStyle = {};

        if (this.value) {
            imageStyle.backgroundImage = `url(${this.value})`;
        }

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                {this.value &&
                    <div
                        className={`
                            ${style.cImage}
                            ${this.props.column.clickable ? style.iClickable : ''}
                        `}
                        style={imageStyle}
                        onClick={this.handleClick.bind(this)}
                    />
                }

                {!this.value && this.props.column.fallbackIcon &&
                    <div className={style.cIconContainer}>
                        <div className={this.props.column.fallbackIcon}/>
                    </div>
                }
            </div>
        );
    }
}

class RowCellExText extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cExTextContainer}>
                    <div className={style.etFirstLine} title={value.text}>{value.text}</div>
                    <div className={style.etSecondLine} title={value.subtext}>{value.subtext}</div>
                </div>
            </div>
        );
    }
}

class RowCellActions extends React.Component {
    handleClick(ev, action) {
        ev.stopPropagation();

        this.props.onAction(action);
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const actions = this.props.column.actions || {};

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cActionsContainer}>
                    {actions.edit &&
                        <div
                            className={`
                                ${style.aButton}
                                icon-pencil
                            `}
                            onClick={(ev) => { this.handleClick.bind(this)(ev, 'edit') }}
                        />
                    }

                    {actions.delete &&
                        <div
                            className={`
                                ${style.aButton}
                                icon-minus-circle
                            `}
                            onClick={(ev) => { this.handleClick.bind(this)(ev, 'delete') }}
                        />
                    }
                </div>
            </div>
        );
    }
}

class RowCellButtons extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const buttons = this.props.column.buttons || [];

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                <div className={style.cButtonsContainer}>
                    {buttons.map(button => {
                        const buttonStyle = style[button.style] || '';
                        let disabled = false;

                        if (_.isFunction(button.disabled) && button.disabled(this.props.row)) {
                            disabled = true;
                        }

                        return (
                            <div
                                key={`b_${button.id}`}
                                className={`
                                    ${style.bButton}
                                    ${buttonStyle}
                                    ${disabled ? style.disabled : ''}
                                `}
                                onClick={(ev) => { ev.stopPropagation(); if (!disabled) { this.props.onButtonClicked(button.id) }}}
                            >
                                {button.title}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class RowCellConversationParticipants extends React.Component {
    handleClick(participant) {
        if (participant.participant_type === 'individual') {
            window.location = `/#individuals/${participant.participant_id}`;
        }
    }

    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const participants = this.props.row[this.props.column.id].filter(p => p.status === 'active');

        return (
            <div
                className={style.rCell}
                style={cellStyle}
            >
                {participants.map((p, pidx) => {
                    return (
                        <div
                            key={`p_${p.participant_id}`}
                            className={style.cParticipants}
                            onClick={(ev) => { ev.stopPropagation(); this.handleClick.bind(this)(p) }}
                        >
                            <div className={style.pName}>{p.participant_name}</div>

                            {pidx < participants.length - 1 &&
                                <div>,</div>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}

class RowCellTags extends React.Component {
    render() {
        let cellStyle = {
            width: this.props.column.finalWidth
        };

        const value = getRowCellValue(this.props.column, this.props.row[this.props.column.id]);

        return (
            <div
                className={style.rCell}
                style={cellStyle}
                title={value}
            >
                {value}
            </div>
        );
    }
}

const ROW_CELL_BY_COLUMN_TYPE = {
    select: RowCellSelect,
    date: RowCellDate,
    datetime: RowCellDateTime,
    text: RowCell,
    bool: RowCellBool,
    composed: RowCellComposed,
    comment: RowCellComment,
    exText: RowCellExText,
    icon: RowCellIcon,
    image: RowCellImage,
    context: RowCellContext,
    actions: RowCellActions,
    buttons: RowCellButtons,
    group: RowCellEntity,
    automations: RowCellEntityList,
    tags: RowCellTags,
    conversationParticipants: RowCellConversationParticipants,
    locations: RowCellLocations,
    communications: RowCellCommunications,
    campaignStat: RowCellCampaignStat
};


export const getRowCellComponent = function(columnType) {
    return ROW_CELL_BY_COLUMN_TYPE[columnType] || RowCell;
}
import React from 'react';

import PanelBase from './base';
import {
    OpportunityDropdownField, TagDropdownField, DropdownField, RegionDropdownField, FunnelDropdownField, PhaseDropdownField,
    TaskDropdownField, DateField, NumberField, UserDropdownField, TextField, IndividualDropdownField, GroupDropdownField,
    OrganizationDropdownField, PeriodDropdownField, AutomationDropdownField, AutomationStepDropdownField, ChecklistDropdownField,
    ChecklistItemDropdownField, LeadSourceDropdownField, CampaignDropdownField, ClusterDropdownField,
} from 'js/react_views/common_components/common';

import style from './advanced_filter.css';


class EditRule extends React.Component {
    constructor(props) {
        super(props);

        this.fields = [];

        if (_.isObject(props.filters)) {
            let firstGroup = true;

            for (const k in props.filters) {
                if (!firstGroup) {
                    this.fields.push({
                        id: 'separator'
                    });
                }

                this.fields.push({
                    title: k,
                    isSectionTitle: true
                });

                for (const f of props.filters[k]) {
                    this.fields.push({
                        id: f.id,
                        custom: f.custom,
                        title: f.title,
                        operators: f.operators
                    });
                }

                firstGroup = false;
            }
        } else {
            for (const f of props.field) {
                this.fields.push({
                    id: f.id,
                    custom: f.custom,
                    title: f.title,
                    operators: f.operators
                });
            }
        }

        let activeField = null;
        let activeOperator = null;
        let activeValues = {};

        if (props.rule) {
            activeField = this.fields.find(f => {
                if (props.rule.custom) {
                    return f.id === props.rule.field && f.custom === props.rule.custom
                }

                return f.id === props.rule.field
            });

            let operatorId = null;

            if (props.rule.operator) {
                operatorId = `${props.rule.not ? 'not_' : ''}${props.rule.operator}`;
            } else { // it's a bool, a rule without operator
                operatorId = props.rule.not ? 'not_equal' : 'equal';
            }

            activeOperator = activeField.operators.find(o => o.id === operatorId);

            if (activeOperator?.fields) {
                activeValues = activeOperator.parseValues(props.rule.values);
            }
        }

        this.state = {
            activeField: activeField,
            activeOperator: activeOperator,
            activeValues: activeValues,
            errors: {}
        };
    }

    showErrors() {
        let errors = {};
        let focused = false;

        if (!this.state.activeField) {
            errors.field = 'Property required';
            this.fieldComponent.focus();
            focused = true;
        }

        if (this.operatorComponent && !this.state.activeOperator) {
            errors.operator = 'Operator required';

            if (!focused) {
                this.operatorComponent.focus();
            }
        }

        if (this.state.activeOperator?.fields) {
            for (const k in this.operatorFieldsComponents) {
                const fc = this.operatorFieldsComponents[k];
                const value = fc.getValue();

                if (!value && value !== 0) {
                    errors[k] = 'Value required';

                    if (!focused) {
                        fc.focus();
                        focused = true;
                    }
                }
            }
        }

        this.setState({
            errors: errors
        });
    }

    getData() {
        if (!this.state.activeField || !this.state.activeOperator) {
            return null;
        }

        let values = null;

        if (this.state.activeOperator.fields) {
            let v = {};

            for (const k in this.operatorFieldsComponents) {
                const fc = this.operatorFieldsComponents[k];
                const val = fc.getFullValue ? fc.getFullValue() : fc.getValue();

                if (!val && val !== 0) {
                    return null;
                }

                v[k] = val;
            }

            values = this.state.activeOperator.getValues(v);
        } else if (this.state.activeOperator.fixedValue) {
            values = this.state.activeOperator.fixedValue;
        }

        let data = {
            field: this.state.activeField.id,
            multiValues: (this.state.activeOperator.fields || []).length > 1
        };

        data.operator = this.state.activeOperator.not ? this.state.activeOperator.id.replace('not_', '') : this.state.activeOperator.id;

        if (this.state.activeOperator.noOperator) {
            data.noOperator = true;
        }

        if (_.isBoolean(this.state.activeOperator.not)) {
            data.not = this.state.activeOperator.not;
        }

        if (this.state.activeField.custom) {
            data.custom = this.state.activeField.custom;
        }

        if (values || values === 0) {
            data.values = values;
        }

        return data;
    }

    deleteError(id) {
        let errors = this.state.errors;
        delete errors[id];

        this.setState({
            errors: errors
        });
    }

    handleFieldValueChange(fieldId, value) {
        let activeValues = this.state.activeValues;
        const fieldValue = this.state.activeOperator.getValues({
            [fieldId]: value
        });

        activeValues[fieldId] = fieldValue;

        this.setState({
            activeValues: activeValues
        });

        this.deleteError(fieldId);
    }

    getOperatorFields() {
        const isFirstTime = !this.operatorFieldsComponents;
        this.operatorFieldsComponents = {};

        if (!this.state.activeOperator?.fields) {
            return null;
        }

        let fields = [];

        for (const field of this.state.activeOperator.fields) {
            const value = this.state.activeValues[field.id] || null;

            switch (field.type) {
                case 'tasks':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <TaskDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={(id, valueId, value) => this.handleFieldValueChange(field.id, value)}
                            />
                        </div>
                    );
                    break;

                case 'users':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <UserDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'individuals':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <IndividualDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'periods':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <PeriodDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'organizations':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <OrganizationDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'opportunities':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <OpportunityDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'regions':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <RegionDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'clusters':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <ClusterDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'funnels':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <FunnelDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'phases':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <PhaseDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'tags':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <TagDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'groups':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <GroupDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                entityType={field.entityType || this.props.entityType}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'automations': {
                    const populate = (fieldId, automationId, deleteStepValue) => {
                        const component = this.operatorFieldsComponents[fieldId];

                        if (component) {
                            if (deleteStepValue) {
                                component.setValue(null);
                            }

                            $.get(`/automations2/${automationId}`, function(automation) {
                                component.setData(automation.steps);
                            });
                        }
                    }

                    const onValueChange = (_, value) => {
                        this.deleteError(field.id);

                        if (field.populate) {
                            populate(field.populate, value, true);
                        }
                    }

                    if (value && field.populate && isFirstTime) {
                        _.defer(function() {
                            populate(field.populate, value.id, false);
                        });
                    }

                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <AutomationDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={onValueChange}
                            />
                        </div>
                    );
                }
                break;

                case 'automationSteps':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                            style={style}
                        >
                            <AutomationStepDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                data={[]}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'checklists': {
                    const populate = (fieldId, checklistId, deleteStepValue) => {
                        const component = this.operatorFieldsComponents[fieldId];

                        if (component) {
                            if (deleteStepValue) {
                                component.setValue(null);
                            }

                            $.get(`/checklists/${checklistId}/items`, function(items) {
                                component.setData(items);
                            });
                        }
                    }

                    const onValueChange = (_, value) => {
                        this.deleteError(field.id);

                        if (field.populate) {
                            populate(field.populate, value, true);
                        }
                    }

                    if (value && field.populate && isFirstTime) {
                        _.defer(function() {
                            populate(field.populate, value.id, false);
                        });
                    }

                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                            style={style}
                        >
                            <ChecklistDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                entityType={this.props.entityType}
                                error={this.state.errors[field.id]}
                                onValueChange={onValueChange}
                            />
                        </div>
                    );
                }
                break;

                case 'checklistItems':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                            style={style}
                        >
                            <ChecklistItemDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                entityType={this.props.entityType}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'leadSources':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                            style={style}
                        >
                            <LeadSourceDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                entityType={this.props.entityType}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'campaigns':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                            style={style}
                        >
                            <CampaignDropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                dropdownWidth={359}
                                status='ready,draft,sent,scheduled'
                                searchNot='template'
                                entityType={this.props.entityType}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'date':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <DateField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'number':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <NumberField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                labelWidth={0}
                                value={value || 0}
                                fullInputBox={true}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'text':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <TextField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                labelWidth={0}
                                fullInputBox={true}
                                value={value || ''}
                                placeholder={field.placeholder || ''}
                                error={this.state.errors[field.id]}
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;

                case 'dropdown':
                    fields.push(
                        <div
                            key={field.id}
                            className={style.rField}
                        >
                            <DropdownField
                                ref={(el) => {if (el) {this.operatorFieldsComponents[field.id] = el}}}
                                value={value}
                                labelWidth={0}
                                fullInputBox={true}
                                error={this.state.errors[field.id]}
                                options={_.isFunction(field.options) ? field.options() : field.options}
                                text='title'
                                onValueChange={() => this.deleteError(field.id)}
                            />
                        </div>
                    );
                    break;
            }
        }

        return fields;
    }

    handleOperatorSelect(_, itemId) {
        this.deleteError('operator');

        this.setState({
            activeOperator: this.state.activeField.operators.find(o => o.id === itemId),
            activeValues: {}
        });
    }

    handleFieldSelect(_, __, item) {
        const activeField = this.fields.find(f => {
            if (item.custom) {
                return f.id === item.id && f.custom === item.custom
            }

            return f.id === item.id;
        });

        this.setState({
            activeField: activeField,
            activeOperator: activeField.operators.length === 1 ? activeField.operators[0] : null,
            activeValues: {},
            errors: {}
        });
    }

    render() {
        return (
            <div className={style.newRule}>
                <DropdownField
                    ref={(el) => {this.fieldComponent = el}}
                    value={this.state.activeField}
                    placeholder='Select Property'
                    labelWidth={0}
                    fullInputBox={true}
                    dropdownWidth={359}
                    text='title'
                    error={this.state.errors.field}
                    options={this.fields}
                    onValueChange={this.handleFieldSelect.bind(this)}
                />

                {this.state.activeField &&
                    <div className={style.rField}>
                        <DropdownField
                            ref={(el) => this.operatorComponent = el}
                            key={`operators_${this.state.activeField.id}_${this.state.activeField.custom || ''}`}
                            value={this.state.activeOperator}
                            labelWidth={0}
                            fullInputBox={true}
                            dropdownWidth={359}
                            text='title'
                            error={this.state.errors.operator}
                            placeholder='Select an operator'
                            options={this.state.activeField.operators}
                            onValueChange={this.handleOperatorSelect.bind(this)}
                        />
                    </div>
                }

                {this.getOperatorFields()}
            </div>
        );
    }
}

class RuleEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rule: this.props.rule || null
        };
    }

    handleApply() {
        let data = this.component.getData();

        if (!data) {
            this.component.showErrors();
        } else {
            this.props.onSave(data);
        }
    }

    render() {
        const buttonTitle = this.state.rule ? 'Update Filter' : 'Apply Filter';

        return (
            <div className={style.ruleEdit}>
                <EditRule
                    ref={(el) => this.component = el}
                    rule={this.state.rule}
                    filters={this.props.filters}
                    entityType={this.props.entityType}
                />

                <div className={style.rFooter}>
                    <div
                        className={style.fApply}
                        onClick={this.handleApply.bind(this)}
                    >
                        {buttonTitle}
                    </div>
                </div>
            </div>
        );
    }
}

class RuleView extends React.Component {
    constructor(props) {
        super(props);

        this.flattenFilters = [];

        for (const k in props.filters) {
            this.flattenFilters = this.flattenFilters.concat(props.filters[k]);
        }
    }

    render() {
        const self = this;

        const rinfo = this.flattenFilters.find(f => {
            if (self.props.rule.custom) {
                return f.id === self.props.rule.field && f.custom === self.props.rule.custom
            }

            return f.id === self.props.rule.field
        });

        const operatorId = this.props.rule.not ? `not_${this.props.rule.operator}` : this.props.rule.operator;
        const oinfo = rinfo.operators.find(o => o.id === operatorId);
        let valueComponents = [];
        let ruleTitle = rinfo.descTitle || rinfo.title;

        if (!oinfo) { // it's a bool (a rule without operators)
            ruleTitle = this.props.rule.not ? (rinfo.descNotTitle || rinfo.notTitle) : (rinfo.descTitle || rinfo.title);
        } else if (oinfo.fields) {
            let value = null;

            if (oinfo.getValuesPartsContent) {
                value = oinfo.getValuesPartsContent(this.props.rule.values);
            } else {
                value = this.props.rule.values;
            }

            if (_.isArray(value)) {
                for (let i = 0; i < value.length; ++i) {
                    const part = value[i];

                    if (_.isArray(part)) {
                        valueComponents.push(
                            <span key={`vp_${i}`}> <b>{part[0]}</b> </span>
                        );
                    } else {
                        valueComponents.push(
                            <span key={`vp_${i}`}> {part} </span>
                        );
                    }
                }
            } else {
                valueComponents.push(
                    <span key='vp'> <b>{value}</b> </span>
                );
            }
        }

        return (
            <div className={style.ruleView}>
                <div className={style.rvContent}>
                    <div className={style.cRule}>
                        <span> <b>{ruleTitle}</b></span>
                        <span> {oinfo?.title}</span>
                        {valueComponents}
                    </div>
                </div>

                <div className={style.rvActions}>
                    <div
                        className={`
                            ${style.aIcon}
                            icon-pencil
                        `}
                        onClick={this.props.onEdit}
                    />

                    <div
                        className={`
                            ${style.aIcon}
                            icon-cross
                        `}
                        onClick={this.props.onDelete}
                    />
                </div>
            </div>
        );
    }
}

class OrRules extends React.Component {
    render() {
        return (
            <div className={style.orRules}>
                {this.props.rules.map((rule, ridx) => {
                    return (
                        <div
                            key={`r_${ridx}`}
                            className={style.rOrRule}
                        >
                            {ridx > 0 &&
                                <div className={style.rOrSeparator}>
                                    <div className={style.sLine}/>
                                    <div className={style.sText}>Or</div>
                                    <div className={style.sLine}/>
                                </div>
                            }

                            <RuleView
                                rule={rule}
                                filters={this.props.filters}
                                onDelete={() => this.props.onDelete(ridx)}
                                onEdit={() => this.props.onEdit(ridx)}
                            />
                        </div>
                    );
                })}

                <div className={style.rFooter}>
                    <div
                        className={style.fOrAnd}
                        onClick={this.props.onOrRule}
                    >
                        Or
                    </div>
                </div>
            </div>
        );
    }
}

export default class AdvancedFilterPanel extends PanelBase {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
            rules: null,
            editingRule: null,
            entityType: '',
            filters: []
        };
    }

    componentWillMount() {
        super.componentWillMount();

        this.setTitle('All Filters');
        this.showCloseButton(true);
    }

    setEntityType(entityType) {
        this.setState({
            entityType: entityType
        });
    }

    setFilters(filters) {
        this.setState({
            filters: filters
        });
    }

    findFilter(id) {
        for (const gk in this.state.filters) {
            const group = this.state.filters[gk];
            const filter = group.find(f => f.id === id);

            if (filter) {
                return filter;
            }
        }

        return null;
    }

    show(entityType, allFilters, filtersRules) {
        let rules = [[]];
        let editingRule = null;

        if (filtersRules) {
            rules = _.clone(filtersRules);

            // boolean rules (like unsubscribed_all) don't save the operator in the filter, so we have to find it
            for (let andRules of rules) {
                for (let orRule of andRules) {
                    if (!orRule.operator) {
                        orRule.operator = 'equal';
                    }
                }
            }
        } else {
            editingRule = [0, null];
        }

        this.setState({
            counter: this.state.counter + 1,
            rules: rules,
            editingRule: editingRule,
            entityType: entityType,
            filters: allFilters
        });

        super.show();
    }

    hide() {
        if (this.state.editingRule) {
            this.setState({
                editingRule: null
            });
        }

        super.hide();
    }

    handleClearAll() {
        this.setState({
            rules: [[]],
            editingRule: [0, null]
        });

        this.props.onFilterRulesChange(null);
    }

    handleOrRule(andIdx) {
        this.setState({
            editingRule: [andIdx, null]
        });
    }

    handleAndRule() {
        let rules = this.state.rules;

        rules.push([]);

        this.setState({
            editingRule: [rules.length - 1, null, true]
        });
    }

    handleRuleDelete(andIdx, ruleIdx) {
        let rules = this.state.rules;
        rules[andIdx].splice(ruleIdx, 1);

        if (rules[andIdx].length === 0) {
            rules.splice(andIdx, 1);
        }

        if (rules.length === 0) {
            rules.push([]);
        }

        let newState = {
            rules: rules
        };

        if (rules[0].length === 0) {
            newState.editingRule = [0, null];
        }

        this.setState(newState);

        this.props.onFilterRulesChange(rules[0].length === 0 ? null : rules);
    }

    handleRuleEdit(andIdx, ruleIdx) {
        this.setState({
            editingRule: [andIdx, ruleIdx]
        });
    }

    handleRuleSave(data) {
        const rules = this.state.rules;
        const andRules = rules[this.state.editingRule[0]];
        const orRuleIdx = this.state.editingRule[1];

        if (orRuleIdx === null) {
            andRules.push(data);
        } else {
            andRules[orRuleIdx] = data;
        }

        this.setState({
            rules: rules,
            editingRule: null
        });

        this.props.onFilterRulesChange(rules);
    }

    handleBack() {
        const editingRule = this.state.editingRule;

        let newState = {
            editingRule: null
        };

        if (editingRule[2]) { // if true it means we are creating an AND rule and we have to delete it
            let rules = this.state.rules;

            rules.pop();

            if (rules.length === 0) {
                rules.push([]);
            }

            newState.rules = rules;
        }

        this.setState(newState);
    }

    getContent() {
        let title = '';

        if (this.state.editingRule) {
            title = (this.state.editingRule[1] !== null) ? 'Edit Filter' : 'Add Filter';
        } else {
            title = 'Advanced Filters';
        }

        let rules = [];

        if (!this.state.editingRule && this.state.rules) {
            for (let i = 0; i < this.state.rules.length; ++i) {
                const orRules = this.state.rules[i];

                if (orRules.length === 0) {
                    continue;
                }

                rules.push(
                    <div
                        key={`or_${i}`}
                    >
                        {i > 0 &&
                            <div
                                style={{
                                    margin: '10px 0',
                                    fontWeight: '600'
                                }}
                            >
                                And
                            </div>
                        }

                        <OrRules
                            rules={orRules}
                            filters={this.state.filters}
                            onEdit={(ridx) => this.handleRuleEdit.bind(this)(i, ridx)}
                            onDelete={(ridx) => this.handleRuleDelete.bind(this)(i, ridx)}
                            onOrRule={() => this.handleOrRule.bind(this)(i)}
                        />
                    </div>
                );
            }
        }

        if (rules.length > 0) {
            rules.push(
                <div
                    key='andButton'
                    style={{
                        display: 'flex',
                        marginTop: '10px'
                    }}
                >
                    <div
                        className={style.fOrAnd}
                        onClick={this.handleAndRule.bind(this)}
                    >
                        And
                    </div>
                </div>
            );
        }

        const showBack = this.state.editingRule && (this.state.rules[0].length > 0);

        return (
            <div
                key={`advanced_filters_${this.state.counter}`}
                className={style.advancedFilter}
            >
                {showBack &&
                    <div
                        className={style.rBack}
                        onClick={this.handleBack.bind(this)}
                    >
                        {'< Back'}
                    </div>
                }

                <div
                    className={style.title}
                    style={{
                            marginTop: '10px',
                            marginBottom: '10px'
                    }}
                >
                    {title}
                </div>

                {rules}

                {this.state.editingRule &&
                    <RuleEdit
                        ref={(el) => this.ruleEditComponent = el}
                        filters={this.state.filters}
                        rule={this.state.rules[this.state.editingRule[0]][this.state.editingRule[1]]}
                        entityType={this.props.entityType}
                        onSave={this.handleRuleSave.bind(this)}
                    />
                }

                {!this.state.editingRule &&
                    <div className={style.rBottomFooter}>
                        <div
                            className={style.fClearAll}
                            onClick={this.handleClearAll.bind(this)}
                        >
                            Clear all
                        </div>
                    </div>
                }
            </div>
        );
    }
}

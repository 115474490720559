import _ from 'underscore'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import GroupElementsCollection from 'js/collections/group_elements'
import TaskFilterModel from 'js/models/task_filter'
import IndividualItemView from 'js/views/individuals/item'
import FilterQuickOptionsTasks from 'js/views/filters/quick-options-tasks'
import NoCollectionView from 'js/views/base/no_collection'
import vent from 'js/vent'
import app from 'js/app'
import TaskModel from 'js/models/task'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import AppConfig from 'app/app-config'


var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    templateHelpers: function() {
        return {
            id: this.parent.getElementType(),
            name: this.options?.name || 'Tasks',
            hideInfo: true,
            disableLayoutSelection: false,
            enableListLayout: false,
            enableCalendarLayout: true
        };
    },
    tools: {
        newItem: false,
        addItem: false,
        deleteItem: false,
        editItem: false,
        selectAllPage: false,
        editColumns: false,
        mergeItems: false,
        selectAll: false
    },
    groupType: "Task",
    initialize: function(options) {
        var self = this;

        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new TaskFilterModel({id: this.filter_id});
        this.collection = new GroupElementsCollection(null, {elementType: 'tasks'});
        this.collection.toBeLoaded = true;
        this.quickFilter = new FilterQuickOptionsTasks();

        this.fetchOptions = _.extend({
            extended: true,
            filter_id: this.filter && this.filter.get('id')
        }, this.fetchOptions);

        if (options.owner_id) {
            this.fetchOptions.owner_id = options.owner_id;
        }

        this.listenTo(vent, 'task:updated', function() {
            self.fetchCollection();
        });

        this.listenTo(this, 'show:related', function(model, type) {
            self.parent.trigger('show:related', model, type);
        });

        this.listenTo(this, 'sort:change', function(sortField, direction) {
            self.parent.trigger('sort:change', sortField, direction);
        });

        this.listenTo(this, 'page:change', function(page) {
            self.parent.trigger('page:change', page);
        });

        this.listenTo(this, 'filter:update', function(filter) {
            self.parent.trigger('filter:update', filter);
        });

        this.listenTo(this, 'columns:update', function(columns) {
            self.parent.trigger('columns:update', columns);
        });
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            elementType: this.options.elementType,
            filter: this.filter,
            defaultSort: this.options.defaultSort,
            selectedColumns: this.options.selectedColumns,
            type: this.options.type,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: false,
                newButton: true
            }
        });
    },
    onSelectItem: function(model) {
        //this.options.parent.trigger('push-view:individual:show', {model: model});
        model = new TaskModel({ id: model.get('id') });
        model.fetch({
            success: function() {
                var hasDelegateTypeChecklist = model.get('delegate_type') && model.get('delegate_type') === 'checklist'
                if(!hasDelegateTypeChecklist)
                    vent.trigger('quick:edit:task', model);
            }
        });
    },
    newItem: function() {
        app.dirtyModelHandler.confirm(this, function() {
            vent.trigger('quick:add:task');
        });
    }
});

export default IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'tasks';
    },
    onStartSidebarCollapsing() {
        const calendar = this.overviewContainerRegion.currentView?.calendarRegion?.currentView;

        if (calendar) {
            calendar.component.onStartResizing();
        }
    },
    onEndSidebarCollapsing() {
        const calendar = this.overviewContainerRegion.currentView?.calendarRegion?.currentView;

        if (calendar) {
            calendar.component.onEndResizing();
        }
    }
});

import _ from 'underscore'
import React from 'react'

import LoadingIndicator from 'js/react_views/widgets/loading-indicator'

import style from './contact_cards.css'


class ContactCard extends React.Component {
    render() {
        return (
            <div className={style.pContactCard}>
                Contact Data
            </div>
        );
    }
}

class ContactList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            loading: false
        };

        this.numPages = 0;
        this.numCardsPerScreen = 15;
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchData(0);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData(page, calculateNumPages) {
        this.setState({
            loading: true
        });

        const self = this;

        // todo: what should fetch here? all the individuals? the individuals related with the funnel?
        this.props.widget.fetcher.get('/individuals', {
            start: page * this.numCardsPerScreen,
            rows: this.numCardsPerScreen,
            funnel_id: this.props.widget.fetcher.activeFunnel.id
        }, function(data, paginationInfo) {
            if (!self.mounted) {
                return;
            }

            if (!self.numPages || calculateNumPages) {
                self.numPages = Math.ceil(paginationInfo.total / self.numCardsPerScreen);
            }

            self.setState({
                contacts: data,
                loading: false
            });

//            self.props.onFetchingDataEnd(page, self.numPages);
        });
    }

    onPageChanged(page) {
        this.fetchData(page);
    }

    render() {
        if (this.state.loading) {
            return (
                <div className={style.pLoading}>
                    <LoadingIndicator/>
                </div>
            );
        }

        return (
            <div className={`${style.pList} ${style.contacts}`}>
                {this.state.contacts.map(deal => {
                    return (
                        <ContactCard
                            key={`pcard_${deal.id}`}
                            contact={deal}
                            widget={this.props.widget}
                        />
                    );
                })}
            </div>
        );
    }
}

export default class ContactCards extends React.Component {
    render() {
        return (
            <div className={style.contactCards}>
                <ContactList
                    widget={this.props.widget}
                    itemParams={this.props.widget.params.item_params}
                />
            </div>
        );
    }
}
import operators from 'app_v2/filter_operators';
import TextManager from 'app/text-manager'

export default {
    Appointment: [{
    //     id: 'appointment_id',
    //     title: 'Appointment',
    //     operators: operators.appointmentOperators,
    //     notRelatedColumn: true // this filter doesnt have a related column
    // }, {
        id: 'appointment_creator_name',
        title: 'From',
        operators: operators.textOperators
    }, {
        id: 'appointment_title',
        title: 'Title',
        operators: operators.textOperators
    }, {
        id: 'appointment_description',
        title: 'Description',
        operators: operators.textOperators
    }, {
        id: 'appointment_start_date',
        title: 'Start Date',
        operators: operators.dateOperators
    }, {
        id: 'appointment_end_date',
        title: 'End Date',
        operators: operators.dateOperators
    }, {
        id: 'appointment_status',
        title: 'Status',
        operators: operators.appointmentStatusOperators
    }, {
        id: 'appointment_appointment_type',
        title: 'Type',
        operators: operators.appointmentTypeOperators
    }, {
        id: 'appointment_related_region_id',
        title: 'Region',
        operators: operators.regionOperators
    }],
    [TextManager.parseText('${ID_FUNNEL, capitalize}')]: [{
        id: 'funnel_cluster_id',
        title: TextManager.parseText('${ID_CLUSTER, capitalize}'),
        operators: operators.clusterOperators
    }]
};

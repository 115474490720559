import BaseModel from 'js/models/base'


var CustomField = BaseModel.extend( {
    name: 'Custom field',
    urlRoot: '/custom_fields',
    apiType: 'salesseek.core.models.custom_fields.CustomFields',
    toString: function() {
        return this.get( 'name' );
    }
} );

CustomField.fromString = function( name, type, view, value ) {
    return new CustomField( { name: name, type: type, view: view, value: value || {}} );
};

export default CustomField;

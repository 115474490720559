import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import vent from 'js/vent'
import app from 'js/app'
import AppConfig from 'app/app-config';

import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import GroupElementsCollection from 'js/collections/group_elements'
import FilterQuickOptionsTexts from 'js/views/filters/quick-options-texts'
import ActivityFilterModel from 'js/models/activity_filter'

import textsTemplate from 'app/texts/texts.handlebars'
import quickFilterListTemplate from 'app/texts/quick-filter-list.handlebars'
import quickFilterItemTemplate from 'app/texts/quick-filter-item.handlebars'
import textsSectionDisabledTemplate from 'app/texts/section_disabled.handlebars'

var SectionDisabledView = Marionette.Layout.extend({
    template: Handlebars.compile(textsSectionDisabledTemplate)
});

var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    templateHelpers: function() {
        return {
            id: this.parent.getElementType(),
            name: 'Texts',
            hideInfo: true,
            disableLayoutSelection: true
        };
    },
    tools: {
        newItem: false,
        addItem: false,
        deleteItem: false,
        editItem: false,
        selectAllPage: false,
        editColumns: false,
        mergeItems: false,
        selectAll: false
    },
    groupType: "Texts",
    initialize: function(options) {
        IODListingBaseView.prototype.OverviewView.prototype.initialize.apply(this, arguments);

        this.disableSave = true;
        this.collection = new GroupElementsCollection(null, {elementType: 'texts'});
        this.filter = new ActivityFilterModel({id: this.options.filterId});
        this.quickFilter = new FilterQuickOptionsTexts();

        var self = this;

        this.listenTo(this, 'show:related', function(model, type) {
            self.parent.trigger('show:related', model, type);
        });
        this.listenTo(this, 'filter:update', function(filter) {
            self.parent.trigger('filter:update', filter);
        });
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            elementType: 'texts',
            filter: this.filter,
            defaultSort: this.options.defaultSort,
            selectedColumns: this.options.selectedColumns,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: false,
                mergeButton: false,
                newButton: false
            }
        });
    }
});


var TableView = IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'texts';
    }
});


var FilterItem = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(quickFilterItemTemplate),
    events: {
        'click': function(ev) {
            ev.preventDefault();
            this.trigger('select');
        }
    },
    onRender: function() {
        // so far we only have one sub-section, it should be active
        this.$el.addClass('active');
    }
});


var QuickFilterList = Marionette.CompositeView.extend({
    className: 'list-wrapper has-floating-header at-top',
    template: Handlebars.compile(quickFilterListTemplate),
    itemViewContainer: '.quick-filters-list',
    itemView: FilterItem,
    initialize: function() {
        this.collection = new Backbone.Collection([
            { name: "All texts", id: 'all_texts', iconClass: "icon-globe2", order_by: { attribute: 'due_date', order: 'asc' }},
        ]);

        this.listenTo(this, 'itemview:active', function() {
            this.$el.find('li.active').removeClass('active');
        });
    }
});


var TextsView = Marionette.Layout.extend({
    tagName: 'article',
    id: 'texts-section',
    template: Handlebars.compile(textsTemplate),
    ui: {
        sidebar: '#texts-sidebar'
    },
    regions: {
        sidebarRegion: '#texts-sidebar',
        mainRegion: '#texts-view-pane'
    },
    events: {
        'click #texts-sidebar .collapse': function(ev) {
            var self = this;

            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            this.sidebarRegion.$el.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');
            });
        }
    },
    initialize: function(options) {
        this.filterId = options.filterId;
    },
    onRender: function() {
        var quickFilterList = new QuickFilterList();
        this.sidebarRegion.show(quickFilterList);

        var self = this
        this.listenTo(quickFilterList, 'itemview:select', function(filter) {
            if (self.filterId) {
                self.filterId = null;
                vent.trigger('AppContent:contentChange');
            }

            self.showContainer();
        });

        this.listenTo(vent, 'user:updated:has_twilio_number', function() {
            self.showContainer();
        });

        this.showContainer();
        quickFilterList.$el.find('[data-id="texts_table"]').parent().addClass('active');

        this.$el.find('[data-toggle="tooltip"]').tooltip();
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['texts-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.sidebarRegion.$el?.width(sidebarWidth);
                self.mainRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    showContainer: function() {
        if (!app.user.get('has_twilio_number')) {
            this.mainRegion.show(new SectionDisabledView());
            this.$el.find('li').removeClass('active');
            this.ui.sidebar.addClass('disabled');
        } else {
            vent.trigger('texts:badget:sync');

            this.containerView = new TableView({
                elementType: 'texts',
                filterId: this.filterId
            });

            this.mainRegion.show(this.containerView);

            var self = this;

            this.listenTo(this.containerView, 'show:related', function(model, type) {
                self.trigger('push-view:' + type + ':show', model);
            });
            this.listenTo(this.containerView, 'filter:update', function(filter) {
                self.filterId = filter ? filter.get('id') : null;
                vent.trigger('AppContent:contentChange');
            });
        }

        this.initSidebarAndContentWidth();
    },
    getUrl: function() {
        return 'texts';
    },
    getParams: function() {
        if (this.filterId) {
            return {
                filter_id: this.filterId
            };
        }

        return {};
    }
});

export default TextsView;

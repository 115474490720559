import _ from 'underscore'
import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import app from 'js/app'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import autoActivityItemTemplate from 'templates/activity/auto-item.handlebars'


export default BaseItemView.extend({
    className: 'auto-activity',
    template: Handlebars.compile(autoActivityItemTemplate),
    templateHelpers: function() {
        var params = this.model.get('params') || {};
        var createdDate = this.model.get('created');
        var creator = this.model.get('creator');
        var author = creator ? creator.name : '';

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var ownerId = this.model.get('owner_id');

        if (!ownerId) {
            var owner = this.model.get('owner');

            if (owner) {
                ownerId = owner['id'];
            }
        }

        var related = this.model.get('related');
        var allowEdit = (app.user.get('id') === ownerId) || app.user.get('is_helper');

        if (!allowEdit && related) {
            allowEdit = security.checkPermission('edit', {
                id: app.user.get('id'),
                permissions: related.permissions
            });
        }

        var data = {
            formattedDate: createdDate,
            individualDeleted: this.model.get('individual_deleted'),
            editable: this.options.parentModel,
            allowEdit: allowEdit,
            pinned: this.model.get('pinned'),
            moreInfo: params.more_info
        };

        var act = this.model.get('activity_type');

        if (act === 'auto:email') {
            data.author = author;
            data.action = 'emailed';
            data.target_name = this.model.get('individual_name') || this.model.get('organization_name');
            data.icon = 'icon-email';
        }
        else if (act === 'auto:call') {
            data.author = author;
            data.action = 'called';
            data.target_name = this.model.get('individual_name') || this.model.get('organization_name');
            data.icon = 'icon-phone';
        }
        else if (act === 'auto:individual_creation') {
            data.author = author;
            data.action = 'created';
            data.target_name = this.model.get('individual_name');
            data.icon = 'icon-plus';
        }
        else if (act === 'auto:individual_new_lead_email_sent') {
            data.action = this.model.get('note');
            data.icon = 'icon-plus';
        }
        else if (act === 'auto:individual_import') {
            data.author = this.model.get('individual_name');
            data.action = 'was imported';
            data.icon = 'icon-plus';
        }
        else if (act === 'auto:individual_field_update') {
            data.author = params.who;
            data.name = params.field;
            data.from = params.from;
            data.to = params.to;
            data.movement_action = 'changed';
            data.icon = 'icon-pencil';
        }
        else if (act === 'auto:individual_update') {
            var updates = [];

            if (params.fields && params.values) {
                var fields = JSON.parse(params.fields);
                var values = JSON.parse(params.values);

                for (var i = 0; i < fields.length; ++i) {
                    updates.push(`${fields[i]} set to ${values[i]}`)
                }
            }

            data.action = `${updates.join(' and ')} by ${author}`;
            data.icon = 'icon-pencil';
        }
        else if (act === 'auto:individual_merge') {
            var entities = JSON.parse(params.entities);
            var source = '';

            for (var i = 0; i < entities.length - 1; ++i) {
                source += `<b>${entities[i]}</b>`;

                if (i < entities.length - 2) {
                    source += ', ';
                }
            }

            source += ` and <b>${entities[entities.length - 1]}</b>`;

            data.author = params.who;
            data.action = 'merged';
            data.source_list = source;
            data.target_name = this.model.get('individual_name');
            data.icon = 'icon-merge2';
        }
        else if (act === 'auto:new_archive_individual') {
            data.author = this.model.get('individual_name');
            data.action = 'created by Email Archive';
            data.icon = 'icon-email';
        }
        else if (act === 'auto:organization_creation') {
            data.author = author;
            data.action = 'created';
            data.target_name = this.model.get('organization_name');
            data.icon = 'icon-plus';
        }
        else if (act === 'auto:organization_import') {
            data.author = this.model.get('organization_name');
            data.action = 'was imported';
            data.icon = 'icon-plus';
        }
        else if (act === 'auto:organization_merge') {
            var entities = JSON.parse(params.entities);
            var source = '';

            for (var i = 0; i < entities.length - 1; ++i) {
                source += `<b>${entities[i]}</b>`;

                if (i < entities.length - 2) {
                    source += ', ';
                }
            }

            source += ` and <b>${entities[entities.length - 1]}</b>`;

            data.author = params.who;
            data.action = 'merged';
            data.source_list = source;
            data.target_name = this.model.get('organization_name');
            data.icon = 'icon-merge2';
        }
        else if (act === 'auto:organization_opportunity_deletion') {
            data.author = params.who;
            data.action = 'deleted';
            data.target_name = params.name;
            data.end_info = ' from ' + params.organizationName;
            data.icon = 'icon-trashcan';
        }
        else if (act === 'auto:opportunity_creation') {
            data.author = author;
            data.action = 'created';
            data.target_name = this.model.get('opportunity_name');
            data.icon = 'icon-plus';

            if (params && params.funnel) {
                data.end_info = ' in ' + params.funnel;
            }
        }
        else if (act === 'auto:opportunity_movement') {
            var from = '';
            var to = '';

            if (params.from_funnel) {
                from = params.from_funnel + ': ';
            }

            if (params.to_funnel) {
                to = params.to_funnel + ': ';
            }

            data.author = params.who;
            data.target_name = this.model.get('opportunity_name');
            data.from = from + params.from;
            data.to = to + params.to;
            data.movement_action = 'moved';
            data.icon = 'icon-pencil';
        }
        else if (act === 'auto:mailing_list_unsubscribed_all') {
            data.icon = 'icon-mailing-list';
            data.author = params.individual_name;
            data.action = params.unsubscribed_all ? 'unsubscribed from all emails' : 'has resubscribed to emails';

            if (params.unsubscribed_all === false) {
                data.subscriptionList = '';
                _.each(params.mailing_lists, function(list) {
                    if (list.subscribed) {
                        data.subscriptionList += 'subscribed to ';
                    }
                    else {
                        data.subscriptionList += 'unsubscribed from ';
                    }
                    data.subscriptionList += '<a href="#contacts/group/individuals/' + list.id + '" class="mailing-list external-link">' + list.name + '</a><br>';
                });
            }
        }
        else if (act === 'auto:messages_unsubscribed_all') {
            data.icon = 'icon-campaign-sms';
            data.author = params.individual_name;
            data.action = params.unsubscribed_all ? 'unsubscribed from all messages' : 'has resubscribed to messages';
        }
        else if (act === 'auto:mailing_list_subscriptions') {
            data.icon = 'icon-mailing-list';
            data.author = params.individual_name;
            if (params.mailing_lists.length === 1) {
                var list = params.mailing_lists[0];
                if (list.subscribed) {
                    data.action = 'subscribed to';
                }
                else {
                    data.action = 'unsubscribed from';
                }
                data.singleMailingGroup = '<a href="#contacts/group/individuals/' + list.id + '" class="mailing-list external-link">' + list.name + '</a>';
            }
            else {
                data.action = 'has updated Subscription Preferences';
                data.subscriptionList = '';
                _.each(params.mailing_lists, function(list) {
                    if (list.subscribed) {
                        data.subscriptionList += 'subscribed to ';
                    }
                    else {
                        data.subscriptionList += 'unsubscribed from ';
                    }
                    data.subscriptionList += '<a href="#contacts/group/individuals/' + list.id + '" class="mailing-list external-link">' + list.name + '</a><br>';
                });
            }
        }
        else if (act === 'auto:ssa_subscriptions') {
            data.icon = 'icon-mailing-list';
            data.author = params.individual_name;
            data.action = 'unsubscribed from';
            data.singleMailingGroup = params.subscription_name;
        }

        // ...
        return data;
    },
    ui: {
        activityPinned: '.activity-pinned',
        iconPinned: '.icon-pinned',
        iconUnpinned: '.icon-unpinned'
    },
    events: _.extend({
        'click .target-name': function(e) {
            e.preventDefault();

            // This prevents new items from linking to an empty detail view
            if (!this.isNew) {
                this.trigger('showTarget', this.model);
            }
        },
        'click .delete.has-permission': function() {
            var self = this;
            var mbContent = {
                accept_is_negative: true,
                message: 'Are you sure you want to <strong>permanently</strong> delete the activity?',
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.$el
                    .animate({ opacity: 0 }, { queue: false, duration: 200 })
                    .slideUp(250, 'easeOutQuint', function() {
                        self.trigger('deleteItem');
                    });
            });
        },
        'click .pin.has-permission': function() {
            this.trigger('togglePin');
        }
    }, BaseItemView.prototype.events),
    onRender: function () {
        this.updatePinView();
    },
    updatePinView: function() {
        var pinned = this.model.get('pinned');

        this.ui.activityPinned.toggleClass('hide', !pinned);
        this.ui.iconPinned.toggleClass('hide', !pinned);
        this.ui.iconUnpinned.toggleClass('hide', pinned);
    }
});

import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import d3 from 'd3'

import vent from 'js/vent'
import funnel from 'js/d3/funnel'
import emptyTemplate from 'templates/empty.handlebars';


var FunnelView = Marionette.View.extend( {
    className: 'funnel-wrapper',
    template: emptyTemplate,
    events: {
        'funnel:phase:zoom': function() {
            this.renderFunnel(true);
        }
    },

    initialize: function(options) {
        Marionette.View.prototype.initialize.apply( this, arguments );

        this.funnel = funnel();
        this.funnel.section(options.section);

        var view = this;

        $( window ).on( 'resize', function() {
            view.renderFunnel();
        } );

        this.listenTo(vent, 'lead:startDragging', function(lead) {
            view.funnel.startDraggingLead(lead);
        });

        this.listenTo(vent, 'lead:stopDragging', function() {
            view.funnel.stopDraggingLead();
        });
    },

    renderFunnel: function(zooming) {
        if (this.funnel.phases().length === 0) {
            return;
        }

        // ...
        this.funnel.width( this.$el.width() );
        this.funnel.height( this.$el.height() );
        this.funnel.parent( this.$el );
        this.funnel.zooming(zooming);

        d3.select( this.$el.get( 0 ) ).call( this.funnel );
    },

    onRender: function() {
        this.renderFunnel();
    },

    hide: function() {
        this.$el.hide();
    },

    show: function() {
        this.$el.show();
    },

    setType: function( type ) {
        this.funnel.type( type );
    },

    getType: function() {
        return this.funnel.type();
    },

    setDateTime: function( dateTime ) {
        this.funnel.datetime( dateTime );
    },

    setDragEnabled: function( enabled ) {
        this.funnel.dragEnabled( enabled );
    },

    setFunnels: function( funnels, activeFunnel ) {
        this.funnel.funnels( funnels );
        this.funnel.activeFunnel( activeFunnel );
    },

    setDeals: function( deals ) {
        this.funnel.deals( deals );
    },

    setDealsStatus: function( status ) {
        this.funnel.dealsStatus( status );
    },

    setPhases: function( phases ) {
        this.funnel.phases( phases );
    },

    setPeriods: function( periods ) {
        this.funnel.periods( periods );
    },

    setColorKey: function( colorKey ) {
        this.funnel.colorkey( colorKey );
    },

    close: function() {
        this.funnel.close( d3.select( this.$el.get( 0 ) ) );
    },

    highlight: function( id ) {
        this.funnel.highlight( id );
    },

    dashboardView: function( view ) {
        this.funnel.dashboardView( view );
    }
} );

export default FunnelView;

import _ from 'underscore'

import BaseModel from 'js/models/base'


var Location = BaseModel.extend({
    name: 'Location',
    urlRoot: '/locations',
    apiType: 'salesseek.contacts.models.location.Location',
    validate: function(attrs) {
        var errors = {};

        // Address required
        if (_.isUndefined(attrs.address) || attrs.address.length === 0) {
            errors.missing_address = 'Address required for a location';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    toString: function() {
        return this.get('name');
    }
});
Location.fromString = function (string) {
    return new Location({name: string});
};
export default Location;

import $ from 'jquery';
import React from 'react';

import app from 'js/app';
import { NewSelect } from 'js/react_views/widgets/select';
import DateField from 'app_v2/components/date_field/date';
import FiltersOperators from 'app_v2/filter_operators';
import dateFormat from 'js/utils/date-format';

import style from './column_popover.css';

const getPhases = () => {
    const hphases = app.globalData.phasesInfo.hierarchy;

    let phases = [{
        id: hphases[0].id,
        name: hphases[0].name,
        fid: null
    }, {
        id: hphases[1].id,
        name: hphases[1].name,
        fid: null
    }];

    for (let i = 2; i < hphases.length; ++i) {
        const group = hphases[i];

        phases.push({
            id: 'separator'
        });

        phases.push({
            id: group.fid,
            name: group.name,
            isSectionTitle: true
        });

        for (const phase of group.children) {
            phases.push({
                id: phase.id,
                name: phase.name,
                fid: group.fid
            });
        }
    }

    return phases;
};


class SortSection extends React.Component {
    constructor(props) {
        super(props);

        this.groupedByMe = props.groupBy === props.column.id;
    }

    handleUngroupBy() {
        this.props.onGroupBy(!this.groupedByMe);
    }

    render() {
        if (!this.props.column.sortable && !this.props.column.groupable && !this.props.column.sortableAnniversary) {
            return null;
        }

        const isDateColumn = ['date', 'datetime'].indexOf(this.props.column.type) !== -1;

        const sortAscMessage = isDateColumn ? 'Sort by Oldest' : 'Sort A - Z';
        const sortDescMessage = isDateColumn ? 'Sort by Newest' : 'Sort Z - A';
        const groupByMessage = this.groupedByMe ? 'Ungroup' : 'Group by this column';
        // TODO: manage anniversary sort

        return (
            <div className={style.sortSection}>
                {this.props.column.sortable &&
                    <div
                        className={style.ssItem}
                        onClick={() => this.props.onSortDirectionChange('asc')}
                    >
                        {sortAscMessage}
                    </div>
                }

                {this.props.column.sortable &&
                    <div
                        className={style.ssItem}
                        onClick={() => this.props.onSortDirectionChange('desc')}
                    >
                        {sortDescMessage}
                    </div>
                }

                {this.props.column.groupable &&
                    <div
                        className={style.ssItem}
                        onClick={this.handleUngroupBy.bind(this)}
                    >
                        {groupByMessage}
                    </div>
                }
            </div>
        );
    }
}

class FilterByRuleSection extends React.Component {
    constructor(props) {
        super(props);

        this.operators = this.getAvailableOperators(this.props.column.filterType);

        this.ruleValue = null;
        this.initialRule = null;

        let activeOperator = this.operators[0];
        let inputValue = '';

        if (props.rule) {
            let operator = null;

            if (!props.rule.operator) { // boolean rules (like unsubscribed_all) don't save the operator in the filter, so we have to find it
                if (props.column.filterType === 'reverseBool') {
                    operator = this.operators.find(o => o.id === (props.rule.not ? 'not_equal' : 'equal'));
                }
            } else {
                if (props.rule.not) {
                    operator = this.operators.find(o => o.id === `not_${props.rule.operator}`);
                } else {
                    operator = this.operators.find(o => o.id === props.rule.operator);
                }
            }

            if (operator) {
                this.initialRule = props.rule;
                activeOperator = operator;

                if (['text', 'number'].indexOf(activeOperator.type) !== -1) {
                    inputValue = props.rule.values;
                }
            }
        }

        this.state = {
            activeOperator: activeOperator,
            inputValue: inputValue
        };
    }

    handleDropdownChange(items) {
        this.ruleValue = items[0].id;
    }

    handleEntityDropdownChange(items) {
        this.ruleValue = items[0];
    }

    handleDateChange(date) {
        this.ruleValue = date;
    }

    handleTextChange(ev) {
        this.ruleValue = ev.target.value;

        this.setState({
            inputValue: this.ruleValue
        });
    }

    getAvailableOperators(filterType) {
        const operators = FiltersOperators[`${filterType}Operators`] || FiltersOperators.basicOperators;
        let availableOperators = [];

        for (const operator of operators) {
            if (!operator.fields || operator.fields.length === 1) {
                let op = _.clone(operator);

                if (op.fields) {
                    op.type = op.fields[0].type;

                    if (op.type === 'dropdown') {
                        const field = op.fields[0];

                        if (field.options) {
                            if (_.isFunction(field.options)) {
                                op.options = field.options();
                            } else {
                                op.options = field.options;
                            }
                        } else if (this.props.column.dropdownOptions) {
                            op.options = this.props.column.dropdownOptions;
                        } else {
                            op.options = [];
                        }

                        op.plain =  field.plain;
                        op.useGetValues = field.useGetValues;
                    }
                } else {
                    op.type = 'noFields';
                }

                availableOperators.push(op);
            }
        }

        availableOperators.unshift({
            id: 'none',
            title: 'None'
        });

        return availableOperators;
    }

    getOperatorInputComponent() {
        // only set the initial values the first time
        const initialRule = this.initialRule;

        this.initialRule = null;

        switch (this.state.activeOperator.type) {
            case 'dropdown': {
                const inputOptions = _.isFunction(this.state.activeOperator.options) ? this.state.activeOperator.options() : this.state.activeOperator.options;
                let value = null;

                if (initialRule?.values) {
                    if (_.isObject(initialRule.values)) {
                        // some filters use the 'id' field to be defined and others the 'value' field
                        for (const k of _.keys(initialRule.values)) {
                            value = inputOptions.find(o => o.id === initialRule.values[k]);

                            if (value) {
                                break;
                            }
                        }
                    } else {
                        value = inputOptions.find(o => o.id === initialRule.values);
                    }
                }

                value = value || inputOptions[0];

                return (
                    <NewSelect
                        key={`${this.state.activeOperator.id}_${value.id}`}
                        ref={(el) => {this.dropdown = el}}
                        data={inputOptions}
                        value={value}
                        options={{minimumInputLength: -1}}
                        width={180}
                        onSelect={this.handleDropdownChange.bind(this)}
                    />
                );
            }

            case 'tags': {
                const value = initialRule ? initialRule.values : null;

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        url='/tags'
                        value={value}
                        options={{minimumInputLength: 1}}
                        width={180}
                        text='name'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'opportunities': {
                let value = null;

                if (initialRule) {
                    value = {
                        id: initialRule.values.id,
                        title: initialRule.values.name
                    };
                }

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        url='/opportunities'
                        value={value}
                        options={{minimumInputLength: 1}}
                        width={180}
                        text='title'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'organizations': {
                let value = null;

                if (initialRule) {
                    value = {
                        id: initialRule.values.id,
                        title: initialRule.values.name
                    };
                }

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        url='/organizations'
                        value={value}
                        options={{minimumInputLength: 1}}
                        width={180}
                        text='title'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'individuals': {
                let value = null;

                if (initialRule) {
                    value = {
                        id: initialRule.values.id,
                        title: initialRule.values.name
                    };
                }

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        url='/individuals'
                        value={value}
                        options={{minimumInputLength: 1}}
                        width={180}
                        text='title'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'regions': {
                let value = null;

                if (initialRule) {
                    value = {
                        id: initialRule.values.id,
                        name: initialRule.values.name
                    };
                }

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        data={app.globalData.regions}
                        value={value}
                        width={180}
                        text='name'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'date': {
                const value = initialRule ? initialRule.values : null;

                return (
                    <DateField
                        key={`${this.state.activeOperator.id}_${value || 'empty'}`}
                        width={180}
                        height={32}
                        padding={10}
                        value={value}
                        onSelect={this.handleDateChange.bind(this)}
                    />
                );
            }

            case 'text':
                return (
                    <input
                        className={style.fsTextInput}
                        value={this.state.inputValue}
                        onChange={this.handleTextChange.bind(this)}
                    />
                );

            case 'number':
                return (
                    <input
                        type='number'
                        className={style.fsTextInput}
                        value={this.state.inputValue}
                        onChange={this.handleTextChange.bind(this)}
                        step='any'
                    />
                );

            case 'phases': {
                const value = initialRule ? initialRule.values : null;

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        data={getPhases()}
                        value={value}
                        width={180}
                        text='name'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'funnels': {
                const value = initialRule ? initialRule.values : null;

                return (
                    <NewSelect
                        ref={(el) => {this.dropdown = el}}
                        url='/funnels'
                        value={value}
                        width={180}
                        text='name'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }

            case 'leadSources': {
                const value = initialRule ? initialRule.values : null;

                return (
                    <NewSelect
                        ref={(el) => this.dropdown = el}
                        url='/lead_sources'
                        value={value}
                        width={180}
                        text='name'
                        onSelect={this.handleEntityDropdownChange.bind(this)}
                    />
                );
            }
        }

        return null;
    }

    getRuleData() {
        const operator = this.state.activeOperator;
        const operatorId = operator.not ? operator.id.replace('not_', '') : operator.id;

        // no args operators
        if (!operator.fields) {
            let data = {
                field: this.props.column.filterId,
                operator: operatorId
            };

            if (operator.noOperator) {
                data.noOperator = true;
            }

            if (operator.not) {
                data.not = true;
            }

            if (operator.fixedValue) {
                data.values = operator.fixedValue;
            }

            if (this.props.column.filterCustom) {
                data.custom = this.props.column.filterCustom;
            }

            return data;
        }

        // ...
        if (!this.ruleValue) {
            return null;
        }

        let data = {
            field: this.props.column.filterId,
            operator: operatorId
        };

        if (operator.not) {
            data.not = true;
        }

        if (this.props.column.filterCustom) {
            data.custom = this.props.column.filterCustom;
        }

        switch (operator.type) {
            case 'dropdown':
                if (operator.plain) {
                    data.values = this.ruleValue;
                } else if (operator.useGetValues) {
                    data.values = operator.getValues(this.ruleValue);
                } else {
                    data.values = {
                        value: this.ruleValue
                    };
                }
                break;

            case 'tags':
                data.values = {
                    id: this.ruleValue.id,
                    name: this.ruleValue.name
                };
                break;

            case 'opportunities':
            case 'organizations':
            case 'individuals':
                data.values = {
                    id: this.ruleValue.id,
                    name: this.ruleValue.title
                };
                break;

            case 'regions':
                data.values = {
                    id: this.ruleValue.id,
                    name: this.ruleValue.name
                };
                break;

            case 'date':
                data.values = dateFormat.ISODate(this.ruleValue);
                break;

            case 'text':
            case 'number':
                data.values = this.ruleValue;
                break;

            case 'phases':
                data.values = {
                    funnel_id: this.ruleValue.fid,
                    id: this.ruleValue.id,
                    name: this.ruleValue.name
                };
                break;

            case 'funnels':
            case 'leadSources':
                data.values = {
                    id: this.ruleValue.id,
                    name: this.ruleValue.name
                };
                break;

            default:
                return null;
        }

        return data;
    }

    handleOperatorSelect(items) {
        const operator = items[0];

        this.ruleValue = null;

        if (operator.type === 'dropdown') {
            if (_.isFunction(operator.options)) {
                this.ruleValue = operator.options()[0].id;
            } else {
                this.ruleValue = operator.options[0].id;
            }
        }

        this.setState({
            activeOperator: operator,
            inputValue: ''
        });
    }

    render() {
        return (
            <div className={style.filterBySection}>
                <div className={style.fsTitle}>Filter By Rule</div>

                <NewSelect
                    data={this.operators}
                    value={this.state.activeOperator}
                    options={{minimumInputLength: -1}}
                    width={180}
                    onSelect={this.handleOperatorSelect.bind(this)}
                />

                {this.state.activeOperator.fields &&
                    <div style={{marginTop: '10px'}}>
                        {this.getOperatorInputComponent()}
                    </div>
                }

                <div
                    className={style.fsButton}
                    style={{marginTop: '10px'}}
                    onClick={this.props.onShowAdvancedFilter}
                >
                    Add Multiple Rules
                </div>
            </div>
        );
    }
}

class FilterByValueSection extends React.Component {
    render() {
        if (!this.props.column.filterable || !this.props.tableFilterable) {
            return null;
        }

        return (
            <div className={style.filterBySection}>
                <div className={style.fsTitle}>Filter By Value</div>

                <div className={style.fsSearch}>
                    <input
                        className={style.fsTextInput}
                    />

                    <div className={`icon-search ${style.sIcon}`}/>
                </div>
            </div>
        );
    }
}


export default class ColumnPopover extends React.Component {
    constructor(props) {
        super(props);

        this.top = props.position.top;
        this.left = props.position.left;

        this.state = {
            filtersNotAvailable: props.rule?.notAvailable
        };
    }

    handleBackdropClick(ev) {
        ev.stopPropagation();
        this.props.onClose();
    }

    handleSortDirection(dir) {
        this.props.onClose({
            sortDir: dir
        });
    }

    handleGroupBy(grouped) {
        this.props.onClose({
            groupBy: grouped ? this.props.column.id : null
        });
    }

    handleCancel() {
        this.props.onClose();
    }

    handleSave() {
        let rule = null;

        const filterByRule = this.filterByRule.getRuleData();

        // todo: filter by value has more priority than filter by rule
        if (filterByRule && filterByRule.operator !== 'none') {
            rule = filterByRule;
        }

        this.props.onClose({
            rule: rule
        });
    }

    handleClearAllRules() {
        this.props.onClearRules();

        this.setState({
            filtersNotAvailable: false
        });
    }

    handleShowAdvancedFilter() {
        this.props.onClose({
            showAdvancedFilter: true
        });
    }

    render() {
        return (
            <div
                className={style.backdrop}
                onClick={this.handleBackdropClick.bind(this)}
            >
                <div
                    className={style.dialog}
                    style={{
                        top: this.top,
                        left: this.left
                    }}
                    onClick={(ev) => ev.stopPropagation()}
                >
                    <SortSection
                        column={this.props.column}
                        groupBy={this.props.groupBy}
                        onSortDirectionChange={this.handleSortDirection.bind(this)}
                        onGroupBy={this.handleGroupBy.bind(this)}
                    />

                    {this.state.filtersNotAvailable ? (
                        <div className={style.noRulesAvailables}>
                            <div className={style.nMessage}>
                                <div>Multiple filter rules have</div>
                                <div>been applied to this property</div>
                            </div>

                            <div className={style.nButtons}>
                                <div
                                    className={`
                                        ${style.bButton}
                                        ${style.bInverse}
                                    `}
                                    onClick={this.handleClearAllRules.bind(this)}
                                >
                                    Clear All
                                </div>

                                <div
                                    className={style.bButton}
                                    onClick={this.handleShowAdvancedFilter.bind(this)}
                                >
                                    View Rules
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {this.props.column.filterable && this.props.tableFilterable &&
                                <div>
                                    <FilterByRuleSection
                                        ref={(el) => this.filterByRule = el}
                                        column={this.props.column}
                                        rule={this.props.rule}
                                        onShowAdvancedFilter={this.handleShowAdvancedFilter.bind(this)}
                                    />

                                    { /* <FilterByValueSection
                                        column={this.props.column}
                                    /> */ }

                                    <div className={style.footer}>
                                        <div
                                            className={`${style.fButton} ${style.bCancel}`}
                                            onClick={this.handleCancel.bind(this)}
                                        >
                                            Cancel
                                        </div>

                                        <div
                                            className={`${style.fButton} ${style.bSave}`}
                                            onClick={this.handleSave.bind(this)}
                                        >
                                            Save
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

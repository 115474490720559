import BaseModel from 'js/models/base'


var TeamModel = BaseModel.extend({
    name: 'Team',
    urlRoot: '/teams',
    apiType: 'salesseek.opportunities.models.phase.Phase',
    toString: function() {
        return this.get('name');
    }
});
TeamModel.fromString = function (string) {
    return new TeamModel({name: string});
};
export default TeamModel;
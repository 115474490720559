import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import vent from 'js/vent'
import CustomFieldsCollection from 'js/collections/custom_fields'
import PhasesCollection from 'js/collections/phases'
import MessageBox from 'js/views/message_box'
import Currency from 'js/utils/currency'
import DateTimePicker from 'js/widgets/date-time-picker'
import dateFormat from 'js/utils/date-format'
import Utilities from 'js/utils/utilities'
import LeadSourcesCollection from 'js/collections/lead_sources'
import step1Template from 'templates/bulk_edit/bulk_edit_step1.handlebars'
import step1FieldTemplate from 'templates/bulk_edit/bulk_edit_step1_field.handlebars'
import step2Template from 'templates/bulk_edit/bulk_edit_step2.handlebars'
import step3Template from 'templates/bulk_edit/bulk_edit_step3.handlebars'
import step4Template from 'templates/bulk_edit/bulk_edit_step4.handlebars'
import inputFieldTemplate from 'templates/bulk_edit/bulk_edit_input_field.handlebars'
import selectFieldTemplate from 'templates/bulk_edit/bulk_edit_select_field.handlebars'
import checkboxFieldTemplate from 'templates/bulk_edit/bulk_edit_checkbox_field.handlebars'
import mixedCheckboxFieldTemplate from 'templates/bulk_edit/bulk_edit_mixed_checkbox_field.handlebars'
import dateFieldTemplate from 'templates/bulk_edit/bulk_edit_date_field.handlebars'


var fields = {
    individuals: [
        {
            id: 'owner',
            name: 'Owner',
            type: 'user',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'add_to_groups',
            name: 'Add to Static Group',
            type: 'static_group',
            placeholder: 'Add to group',
            noPreviousValue: true,
            multipleSelection: true
        },
        {
            id: 'add_activity_note',
            name: 'Add Activity Note',
            type: 'text',
            placeholder: 'Add a note',
            noPreviousValue: true
        },
        {
            id: 'role',
            name: TextManager.getText('ID_JOB_ROLE'),
            type: 'text'
        },
        {
            id: 'organization',
            name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
            type: 'organization',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'source',
            name: 'Source',
            type: 'source',
            hasInnerIdField: true
        },
        {
            id: 'view_permissions',
            name: 'View Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'edit_permissions',
            name: 'View & Edit Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'all_permissions',
            name: 'View, Edit & All Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'replace_tags',
            name: 'Replace Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_tags',
            name: 'Add Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_funnels',
            name: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
        {
            id: 'replace_funnels',
            name: `Replace ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
        {
            id: 'unsubscribed_all',
            name: 'Unsubscribed',
            type: 'checkbox'
        },
        {
            id: 'unsubscribed_all_messages',
            name: 'Unsubscribed Texts',
            type: 'checkbox',
            isAvailable: function() {
                return AppConfig.getValue('enableTextMessaging', false);
            }
        }
    ],
    organizations: [
        {
            id: 'owner',
            name: 'Owner',
            type: 'user',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'add_to_groups',
            name: 'Add to Static Group',
            type: 'static_group',
            placeholder: 'Add to group',
            noPreviousValue: true,
            multipleSelection: true
        },
        {
            id: 'add_activity_note',
            name: 'Add Activity Note',
            type: 'text',
            placeholder: 'Add a note',
            noPreviousValue: true
        },
        {
            id: 'view_permissions',
            name: 'View Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'edit_permissions',
            name: 'View & Edit Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'all_permissions',
            name: 'View, Edit & All Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'replace_tags',
            name: 'Replace Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_tags',
            name: 'Add Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_funnels',
            name: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
        {
            id: 'replace_funnels',
            name: `Replace ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
    ],
    opportunities: [
        {
            id: 'owner',
            name: 'Owner',
            type: 'user',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'add_to_groups',
            name: 'Add to Static Group',
            type: 'static_group',
            placeholder: 'Add to group',
            noPreviousValue: true,
            multipleSelection: true
        },
        {
            id: 'add_activity_note',
            name: 'Add Activity Note',
            type: 'text',
            placeholder: 'Add a note',
            noPreviousValue: true
        },
        {
            id: 'organization',
            name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
            type: 'organization',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'currency',
            name: 'Currency',
            type: 'currency',
            required: true
        },
        {
            id: 'phase',
            name: TextManager.parseText('Funnel & ${ID_PHASE, capitalize}'),
            type: 'phase',
            required: true,
            hasInnerIdField: true,
            isAvailable: function() {
                return AppConfig.getValue('bulk_update.opportunities.phase.available', true);
            }
        },
        {
            id: 'status',
            name: TextManager.parseText('${ID_STATUS, capitalize}'),
            type: 'status',
            required: true
        },
        {
            id: 'weight',
            name: 'Weight',
            type: 'number',
            sendAsFloat: true,
            required: true
        },
        {
            id: 'expected_close_date',
            name: TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize} Date'),
            type: 'date'
        },
        {
            id: 'view_permissions',
            name: 'View Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'edit_permissions',
            name: 'View & Edit Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'all_permissions',
            name: 'View, Edit & All Permissions',
            type: 'permissions',
            multipleSelection: true,
            noPreviousValue: true,
            placeholder: 'Remove permissions for everybody'
        },
        {
            id: 'replace_tags',
            name: 'Replace Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_tags',
            name: 'Add Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        }
    ],
    tasks: [
        {
            id: 'completed',
            name: 'Completed',
            type: 'checkbox'
        },
        {
            id: 'text',
            name: 'Text',
            type: 'paragraph'
        },
        {
            id: 'assignee',
            name: 'Assignee',
            type: 'user',
            required: true,
            hasInnerIdField: true
        },
        {
            id: 'related',
            name: 'Related',
            type: 'iod'
        },
        {
            id: 'due_date',
            name: 'Due Date',
            type: 'datetime'
        },
        {
            id: 'complete_date',
            name: 'Complete Date',
            type: 'date'
        },
        {
            id: 'replace_tags',
            name: 'Replace Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_tags',
            name: 'Add Tags',
            type: 'tags',
            placeholder: 'Tags',
            multipleSelection: true
        },
        {
            id: 'add_funnels',
            name: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
        {
            id: 'replace_funnels',
            name: `Replace ${TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}`,
            type: 'funnels',
            placeholder: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            multipleSelection: true
        },
    ]
};

var leadSources = null;
var phases = null;
var dealStatusData = [
    { id: 'none', name: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
    { id: 'none_upside', name: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
    { id: 'committed_downside', name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
    { id: 'committed', name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
];

var Step1FieldItemView = Marionette.ItemView.extend({
    template: Handlebars.compile(step1FieldTemplate),
    ui: {
        fieldsSelector: '.be-fields-selector',
        deleteField: '.be-delete-field'
    },
    events: {
        'click .be-delete-field': function() {
            this.trigger('field:delete', this.model);
        }
    },
    onRender: function() {
        var self = this;
        var value = null;

        if (this.model.get('id')) {
            value = this.model.toJSON();
            this.ui.deleteField.removeClass('hide');
        }

        this.fieldsSelector = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.fieldsSelector,
            text: 'name',
            value: value,
            data: this.options.manager.getAvailableFields(),
            options: {
                placeholder: 'Select field here',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'field-select-popover popover'
            }
        });

        this.listenTo(this.fieldsSelector, 'change', function(elem) {
            var addNew = !self.model.get('id');

            self.model.set(elem);
            self.trigger('field:change', this.model, addNew);
            self.ui.deleteField.removeClass('hide');
        });
    },
    updateAvailableResults: function() {
        this.fieldsSelector.options.data.results = this.options.manager.getAvailableFields();
    }
});

var Step1FieldCollectionView = Marionette.CollectionView.extend({
    className: 'content',
    template: Handlebars.compile(''),
    itemView: Step1FieldItemView,
    itemViewOptions: function() {
        return {
            manager: this.options.parent
        };
    },
    updateAvailableResults: function() {
        for (var i = 0; i < this.children.length; ++i) {
            this.children.findByIndex(i).updateAvailableResults();
        }
    }
});

var Step1 = Marionette.Layout.extend({
    template: Handlebars.compile(step1Template),
    templateHelpers: function() {
        return {
            elementIcon: this.options.elementIcon,
            elementsDescription: this.options.elementsDescription
        };
    },
    ui: {
        fieldsContainer: '.be-fields-container'
    },
    regions: {
        fieldsContainer: '.be-fields-container'
    },
    events: {
        'click .be-cancel': function() {
            this.trigger('step:close');
        },
        'click .be-next': function() {
            if (this.options.fieldsCollection.length === 1) {
                this.$el.find('.be-fields-selector').addClass('validation_error');
                return;
            }

            this.trigger('step:next');
        }
    },
    onRender: function() {
        var self = this;
        var fieldsView = new Step1FieldCollectionView({
            collection: this.options.fieldsCollection,
            parent: this
        });

        this.listenTo(fieldsView, 'itemview:field:change', function(childView, model, addNew) {
            if (addNew) {
                self.options.fieldsCollection.add(new Backbone.Model());
                self.ui.fieldsContainer.nanoScroller();
                self.ui.fieldsContainer.nanoScroller({scroll: 'bottom'});
                fieldsView.updateAvailableResults();
                this.$el.find('.validation_error').removeClass('validation_error');
            }
        });

        this.listenTo(fieldsView, 'itemview:field:delete', function(childview, model) {
            self.options.fieldsCollection.remove(model);
            self.ui.fieldsContainer.nanoScroller();
            fieldsView.updateAvailableResults();
        });

        this.fieldsContainer.show(fieldsView);

        _.defer(function() {
            self.ui.fieldsContainer.nanoScroller();
        });
    },
    getAvailableFields: function() {
        var self = this;
        var title = {
            'individuals': TextManager.parseText('${ID_INDIVIDUAL, uppercase}'),
            'organizations': TextManager.parseText('${ID_ORGANIZATION, uppercase}'),
            'opportunities': TextManager.parseText('${ID_DEAL, uppercase}'),
            'tasks': 'TASK'
        };

        var sections = [
            {
                name: title[this.options.elementType] + ' FIELDS',
                children: _.filter(fields[this.options.elementType], function(elem) {
                    // only one tags modifier (add or replace) is permitted
                    if ((elem.id === 'replace_tags') && self.options.fieldsCollection.get('add_tags')) {
                        return false;
                    }

                    if ((elem.id === 'add_tags') && self.options.fieldsCollection.get('replace_tags')) {
                        return false;
                    }

                    if (elem.isAvailable && !elem.isAvailable()) {
                        return false;
                    }

                    return !self.options.fieldsCollection.get(elem.id);
                })
            }
        ];

        if (this.options.elementType !== 'tasks') {
            sections.push({
                name: 'CUSTOM FIELDS',
                children: _.filter(this.options.customFields, function(elem) {
                    return !self.options.fieldsCollection.get(elem.id);
                })
            });
        }

        return sections;
    }
});

var Step2FieldItemView = Marionette.ItemView.extend({
    template: Handlebars.compile(''),
    ui: {
        field: '.be-field',
        error: '.be-field-error'
    },
    initialize: function() {
        var type = this.model.get('type');

        if (['individual', 'organization', 'opportunity', 'user', 'dropDown'].indexOf(type) !== -1) {
            if(this.model.get('params') && this.model.get('params').multiSelect){
                this.model.set('hasInnerIdField', false);
            }else {
                this.model.set('hasInnerIdField', true);
            }
        }
    },
    hasValidValue: function() {
        if (this.model.get('required')) {
            return !!this.model.get('new_value');
        }

        return true;
    },
    showError: function(error) {
        this.ui.field.addClass('validation_error');
        this.ui.error.text(error);
        this.ui.error.removeClass('hide');
        this.model.set('invalid', true);
    },
    hideError: function() {
        this.ui.field.removeClass('validation_error');
        this.ui.error.addClass('hide');
        this.model.set('invalid', false);
    }
});

var InputFieldItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(inputFieldTemplate),
    templateHelpers: function() {
        var fieldType = this.options.fieldType || 'text';
        var type = this.model.get('type');

        var params = {
            fieldName: this.model.get('name'),
            fieldValue: this.model.get('value'),
            fieldType: fieldType,
            isParagraph: fieldType === 'paragraph',
            isNumber: fieldType === 'number' || fieldType === 'product',
            fieldPlaceholder: ''
        };

        if (this.model.get('noPreviousValue')) {
            params.fieldPlaceholder = this.model.get('placeholder');
        }
        else if (!('value' in this.model.attributes)) {
            params.fieldPlaceholder = '[mixed]';
        }
        else {
            params.fieldPlaceholder = this.model.get('placeholder') || this.getPlaceholder(type);
        }

        return params;
    },
    events: {
        'change .be-field': function(ev) {
            ev.preventDefault();
            this.hideError();

            if (this.model.get('required') && (ev.target.value === "")) {
                this.showError('Field is required');
            }
            else {
                this.model.set('new_value', ev.target.value);
            }
        }
    },
    getPlaceholder: function(type) {
        var values = {
            number: 'Enter number',
            paragraph: 'Enter text',
            text: 'Enter text',
            url: 'Enter URL',
            urlImage: 'Image URL',
            product: 'Enter quantity',
        };

        if (type in values) {
            return values[type];
        }

        return '';
    },
    hasValidValue: function() {
        if (this.model.get('required')) {
            if ((this.options.fieldType === 'number') || (this.options.fieldType === 'product')) {
                return !_.isNaN(this.model.get('new_value'));
            }

            return !!this.model.get('new_value');
        }

        return true;
    }
});

var SelectFieldIODItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(selectFieldTemplate),
    templateHelpers: function() {
        return {
            fieldName: this.model.get('name')
        };
    },
    ui: {
        selector: '.be-select-field'
    },
    onRender: function() {
        var typeMap = {
            'individuals': 'individual',
            'opportunities': 'opportunity',
            'organizations': 'organization'
        };

        var iconMap = {
            'individuals': 'icon-user',
            'opportunities': 'icon-filter',
            'organizations': 'icon-home'
        };

        var value = this.model.get('value')

        if (value) {
            value.title = value.full_name || value.name;
        }

        var params = {
            view: this,
            $el: this.ui.selector,
            url: '/v1/search',
            params: {
                types: 'individuals,organizations,opportunities',
                order_by: 'last_viewed desc'
            },
            text: 'title',
            value: value,
            search: true,
            options: {
                allowClear: true,
                placeholder: 'Select an item',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'field-select-popover popover select2-drop-wider',
                formatResult: function(item) {
                    return '<i class="' + iconMap[item.type] + '"></i> ' + item.title_highlight;
                }
            }
        };

        // has mixed value?
        if (!('value' in this.model.attributes)) {
            var mixedOption = {
                id: 'mixed',
                title: '[mixed]'
            };

            params.data = [mixedOption];
            params.value = mixedOption;
            this.model.set('mixed', true);
        }

        var selector = new backboneSelect2.SelectView(params);
        var self = this;

        this.listenTo(selector, 'change', function(item) {
            if (item) {
                if (item === 'mixed') {
                    self.model.unset('new_value');
                    self.model.set('mixed', true);
                }
                else {
                    self.model.set('new_value', {
                        id: item.id,
                        type: typeMap[item.type]
                    });
                    self.model.unset('mixed');
                }
            }
            else {
                self.model.set('new_value', item);
                self.model.unset('mixed');
            }
        });
    }
});

var SelectFieldItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(selectFieldTemplate),
    templateHelpers: function() {
        return {
            fieldName: this.model.get('name')
        };
    },
    ui: {
        selector: '.be-select-field'
    },
    onRender: function() {
        var placeholder = '';
        var type = this.model.get('type');

        if ('noPreviousValue' in this.model.attributes) {
            placeholder = this.model.get('placeholder');
        }
        else {
            placeholder = this.model.get('placeholder') || this.getPlaceholder(type);
        }

        var value = this.model.get('value') || null;
        var multipleSelection = (this.model.get('multipleSelection') || (this.model.get('params') && this.model.get('params').multiSelect))|| false;
        var required = this.model.get('required') || false;
        var params = {
            view: this,
            $el: this.ui.selector,
            text: this.options.selectText || 'name',
            value: value,
            options: {
                multiple: multipleSelection,
                allowClear: !required,
                placeholder: placeholder,
                containerCssClass: 'select2-block ' + (this.options.containerClass || ''),
                dropdownCssClass: 'field-select-popover popover ' + (this.options.dropdownClass || '')
            }
        };

        if (this.options.selectUrl) {
            params.url = this.options.selectUrl;
            params.search = this.options.selectSearch || false;

            if (params.search && this.options.selectMinimumInputLength) {
                params.options.selectMinimumInputLength = this.options.selectMinimumInputLength;
            }
        }
        else if (this.options.selectData) {
            params.data = this.options.selectData;
        }

        // has mixed value?
        if ((!('value' in this.model.attributes)) && !multipleSelection) {
            var mixedOption = this.getMixedOption(params.text);
            params.data = params.data ? params.data.slice() : [];
            params.data.push(mixedOption);
            params.value = mixedOption;
            this.model.set('mixed', true);
        }

        var self = this;

        _.defer(function() {
            if (multipleSelection) {
                self.selector = new backboneSelect2.TagView(params);
            }
            else {
                self.selector = new backboneSelect2.SelectView(params);
            }

            self.listenTo(self.selector, 'change', function(val) {
                if (_.isArray(val)) { // multiple selection
                    if (val.length > 0) {
                        if (type === 'permissions') {
                            var itemPermissions = [];
                            var permValues = {
                                view_permissions: 'salesseek.core.view',
                                edit_permissions: 'salesseek.core.edit',
                                all_permissions: 'salesseek.core.all_permissions'
                            };
                            var permTypes = {
                                'salesseek.core.models.user.User': 'user',
                                'salesseek.core.models.teams.Team': 'team'
                            };

                            _.map(val, function(m) {
                                var permType = 'salesseek.core.' + permTypes[m.type] + '#' + m.id;
                                itemPermissions.push([permType, permValues[self.model.get('id')]]);
                            });

                            self.model.set('new_value', itemPermissions);
                        }
                        else {
                            self.model.set('new_value', _.map(val, function(m) {
                                return { id: m.id }
                            }));
                        }
                    }
                    else {
                        self.model.unset('new_value');
                    }
                }
                else {
                    val = val ? val.id : val;

                    if (val === 'mixed') {
                        self.model.unset('new_value');
                        self.model.set('mixed', true);
                    }
                    else {
                        self.model.set('new_value', val);
                        self.model.unset('mixed');
                    }
                }
            });
        });
    },
    hasValidValue: function() {
        if (this.model.get('required')) {
            return !!this.model.get('new_value') || this.model.get('mixed') || this.model.get('value');
        }

        return true;
    },
    getMixedOption: function(nameKey) {
        var mixedOption = {
            id: 'mixed',
        };

        mixedOption[nameKey] = '[mixed]';

        return mixedOption;
    },
    getPlaceholder: function(type) {
        var values = {
            currency: 'Select a currency',
            dropDown: 'Select a value',
            individual: TextManager.getText('ID_SELECT_AN_INDIVIDUAL'),
            organization: TextManager.getText('ID_SELECT_AN_ORGANIZATION'),
            opportunity: TextManager.getText('ID_SELECT_A_DEAL'),
            source: 'Select a source'
        };

        if (type in values) {
            return values[type];
        }

        return '';
    }
});

var CheckboxFieldItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(checkboxFieldTemplate),
    templateHelpers: function() {
        return {
            fieldName: this.model.get('name'),
            fieldId: this.model.get('id')
        };
    },
    events: {
        'change .checkbox-switch-control': function(ev) {
            this.model.set('new_value', $(ev.target).is(':checked'));
        }
    }
});

var MixedCheckboxFieldItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(mixedCheckboxFieldTemplate),
    templateHelpers: function() {
        return {
            fieldName: this.model.get('name')
        };
    },
    events: {
        'click .be-mixed-checkbox': function(ev) {
            var target = $(ev.target);
            this.$el.find('.be-active').removeClass('be-active');
            target.addClass('be-active');
        },
        'click #off': function() {
            this.model.set('new_value', false);
        },
        'click #on': function() {
            this.model.set('new_value', true);
        },
        'click #mixed': function() {
            this.model.unset('new_value');
        }
    }
});

var DateFieldItemView = Step2FieldItemView.extend({
    template: Handlebars.compile(dateFieldTemplate),
    templateHelpers: function() {
        var fieldPlaceholder = 'Enter date';
        var value = this.model.get('value');

        if (!('value' in this.model.attributes)) {
            fieldPlaceholder = '[mixed]';
        }

        return {
            fieldName: this.model.get('name'),
            fieldPlaceholder: fieldPlaceholder,
            date: value ? new Date(value + "Z") : null,
            displayTime: this.model.get('type') === 'datetime'
        };
    },
    events: {
        'focus .be-date-field': function(ev) {
            var defaultDate = null;

            if (this.model.get('type') === 'datetime') {
                var dateTime = this.getDateTime(this.model.get('value'));

                this.dateTimePicker = new DateTimePicker({
                    altField: $(ev.currentTarget),
                    css: {
                        left: this.ui.field.offset().left,
                        top: this.ui.field.offset().top + 32
                    },
                    date: dateTime.date,
                    time: dateTime.time,
                    noClearOnExit: true
                });

                this.dateTimePicker.showPicker();
            }
            else {
                $(ev.currentTarget).datepicker({
                    defaultDate: defaultDate,
                    numberOfMonths: 2,
                    dateFormat: 'M dd, yy',
                    timezone: "+0000",
                    showButtonPanel: true,
                    changeYear: true
                });
            }
        },
        'change .be-date-field': function(ev) {
            var strDate = ev.target.value;

            this.hideError();
            this.model.unset('new_value');

            if (strDate !== '')
            {
                var val = dateFormat.parseDate(strDate);

                if (Utilities.dateIsValid(val)) {
                    if (this.model.get('type') === 'datetime') {
                        this.model.set('new_value', val.toISOString());
                        this.dateTimePicker.setDateTime(val, null);
                    }
                    else {
                        this.model.set('new_value', dateFormat.ISODate(val));
                        this.ui.field.datepicker('setDate', val);
                    }
                }
                else {
                    this.showError('Enter a valid date');
                }
            }
            else if (this.model.get('required')) {
                this.showError('Field is required');
            }
        }
    },
    getDateTime: function(value) {
        var date = null;
        var time = null;

        if (value) {
            var dateAndTime = value.split('T');
            date = dateFormat.shortFormatWithYear(dateAndTime[0]);
            time = dateAndTime[1].split(':');
            time = time[0] + ':' + time[1];
        }

        return {
            date: date,
            time: time
        }
    }
});

var Step2FieldCollectionView = Marionette.CollectionView.extend({
    className: 'content',
    template: Handlebars.compile(''),
    getItemView: function(model) {
        var type = model.get('type');

        if (['text', 'number', 'product', 'paragraph', 'url', 'urlImage', 'weight'].indexOf(type) !== -1) {
            return InputFieldItemView;
        }
        if (['user', 'static_group', 'currency', 'dropDown', 'individual', 'organization', 'opportunity', 'source', 'phase', 'status', 'tags', 'funnels', 'permissions'].indexOf(type) !== -1) {
            return SelectFieldItemView;
        }
        if (type === 'iod') {
            return SelectFieldIODItemView;
        }
        if (type === 'checkbox') {
            if ('value' in model.attributes) {
                return CheckboxFieldItemView;
            }
            return MixedCheckboxFieldItemView;
        }
        if (['date', 'datetime'].indexOf(type) !== -1) {
            return DateFieldItemView;
        }

        return Step2FieldItemView;
    },
    itemViewOptions: function(model) {
        var options = {
            parent: this.options.parent
        };
        var type = model.get('type');

        if (type === 'user') {
            options.selectUrl = '/users';
        }
        else if (type === 'static_group') {
            options.selectUrl = '/groups?' + $.param({
                rows: -1,
                element_type: this.options.parent.options.elementType,
                group_type: 'static'
            });
            options.dropdownClass = 'select2-drop-wider';
        }
        else if (type === 'permissions') {
            options.selectUrl = '/teams';
            options.selectSearch = true;
            options.selectText = 'title';
        }
        else if (['number', 'product', 'paragraph'].indexOf(type) !== -1) {
            options.fieldType = type;
        }
        else if (type === 'currency') {
            options.selectData = Currency.getUsedCurrenciesToSelect2Array();
            options.selectText = 'text';
        }
        else if (type === 'dropDown') {
            options.selectData = model.get('options');
            options.selectText = 'value';
        }
        else if (type === 'individual') {
            options.selectUrl = '/v1/individuals';
            options.selectSearch = true;
            options.selectMinimumInputLength = 1;
            options.selectText = 'full_name';
            options.dropdownClass = 'select2-drop-wider';
        }
        else if (type === 'organization') {
            options.selectUrl = '/v1/organizations';
            options.selectSearch = true;
            options.selectMinimumInputLength = 1;
            options.dropdownClass = 'select2-drop-wider';
        }
        else if (type === 'opportunity') {
            options.selectUrl = '/v1/opportunities';
            options.selectSearch = true;
            options.selectMinimumInputLength = 1;
            options.dropdownClass = 'select2-drop-wider';
        }
        else if (type === 'source') {
            options.selectData = leadSources;
        }
        else if (type === 'phase') {
            options.selectData = phases;
        }
        else if (type === 'status') {
            options.selectData = dealStatusData;
        }
        else if (type === 'tags') {
            options.selectUrl = '/tags';
            options.selectSearch = true;
            options.containerClass = 'tags-container';
        }
        else if (type === 'funnels') {
            options.selectUrl = '/funnels';
            options.selectSearch = true;
            options.containerClass = 'tags-container';
        }

        return options;
    }
});

var Step2 = Marionette.Layout.extend({
    template: Handlebars.compile(step2Template),
    templateHelpers: function() {
        return {
            elementIcon: this.options.elementIcon,
            elementsDescription: this.options.elementsDescription
        };
    },
    ui: {
        fieldsContainer: '.be-fields-container'
    },
    regions: {
        fieldsContainer: '.be-fields-container'
    },
    events: {
        'click .be-back': function() {
            this.trigger('step:back');
        },
        'click .be-save': function() {
            var allOk = true;

            // all fields should have a valid value
            for (var i = 0; i < this.valueFieldsCollection.models.length; ++i) {
                var view = this.fieldsContainer.currentView.children.findByModel(this.valueFieldsCollection.models[i]);

                if (!view.hasValidValue()) {
                    allOk = false;
                    break;
                }
            }

            if (allOk) {
                this.trigger('step:save', this.valueFieldsCollection, this.bulkUpdateId);
            }
        }
    },
    onRender: function() {
        var self = this;
        var fieldsToUpdate = [];

        _.each(this.options.fieldsCollection.models, function(m) {
            if ('id' in m.attributes) {
                fieldsToUpdate.push(m.get('id'));
            }
        });

        var data = {
            entity_type: this.options.elementType,
            fields_to_update: fieldsToUpdate
        };

        if (this.options.entityIds) {
            data.entity_ids = this.options.entityIds;
        }
        else {
            data.group_id = this.options.groupId;
            if (this.options.filterId) {
                data.filter_id = this.options.filterId;
            }
            if (this.options.sectionId) {
                data.section_id = this.options.sectionId;
            }
        }

        $.ajax({
            type: 'POST',
            url: '/bulk_update',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
            success: function(data) {
                self.bulkUpdateId = data.id;
                var models = [];

                _.each(self.options.fieldsCollection.models, function(m) {
                    var id = m.get('id');

                    if (id) {
                        var attr = _.clone(m.attributes);
                        var isMultiselect = m.get('params') && m.get('params').multiSelect

                        // if the id is not in existing_values, then there are multiple different values for it
                        if (id in data.existing_values && !isMultiselect) {
                            attr.value = data.existing_values[id];
                        }

                        models.push(attr);
                    }
                });

                self.valueFieldsCollection = new Backbone.Collection(models);

                var fieldsView = new Step2FieldCollectionView({
                    collection: self.valueFieldsCollection,
                    parent: self
                });

                self.fieldsContainer.show(fieldsView);

                _.defer(function() {
                    self.ui.fieldsContainer.nanoScroller();
                });
            },
            error: function(model) {
                var detail = JSON.parse(model.responseText).detail;

                if (detail.exception === 'BulkUpdateNoEntityError') {
                    var content = {
                        icon: 'icon-warning',
                        message: "You don't have permission to edit these items"
                    };

                    MessageBox.showOk(content, self, function() {
                        self.trigger('step:close');
                    });
                }
                else {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'There was an error processing this request',
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });

                    self.trigger('step:back');
                }
            }
        });
    },
});

var Step3 = Marionette.Layout.extend({
    template: Handlebars.compile(step3Template),
    templateHelpers: function() {
        return {
            elementIcon: this.options.elementIcon,
            totalElements: this.options.totalElements
        };
    },
    ui: {
        progressBar: '.be-progress-bar',
        completeMessage: '.be-foot-text'
    },
    initialize: function() {
        var updateValues = {};
        var fieldsToUpdate = [];

        _.each(this.options.fieldsCollection.models, function(m) {
            // permissions support empty value (remove all permissions except for the owner)
            if (m.get('type') === 'permissions' && !('new_value' in m.attributes)) {
                m.set('new_value', []);
            }

            // replace tags supports empty value (remove all tags)
            if (m.get('id') === 'replace_tags' && !('new_value' in m.attributes)) {
                m.set('new_value', []);
            }

            // custom fields that support empty values
            if ((m.get('isCustomField') && ['date', 'text'].indexOf(m.get('type')) !== -1) && !('new_value' in m.attributes)) {
                m.set('new_value', null);
            }

            if ('new_value' in m.attributes) {
                var newValue = m.get('new_value');
                var addit = true;

                if ('value' in m.attributes) {
                    var prevValue = m.get('value');

                    if (prevValue && prevValue.id) {
                        prevValue = prevValue.id;
                    }

                    if (prevValue === newValue) {
                        addit = false;
                    }
                }

                if (addit) {
                    var id = m.get('id');

                    // id: its not necessary to remove the value (setting it to null)
                    if (m.get('hasInnerIdField') && newValue) {
                        updateValues[id] = {
                            id: newValue
                        };
                    }
                    else if (m.get('flatResult') && newValue) {
                        updateValues[id] = _.map(newValue, function(v) {
                            return v.id;
                        });
                    }
                    else {
                        if ((m.get('type') === 'number') || (m.get('type') === 'product')) {
                            var number = parseFloat(newValue);

                            if (_.isNaN(number)) {
                                updateValues[id] = null;
                            } else {
                                updateValues[id] = m.get('sendAsFloat') ? number : number.toString();
                            }
                        }
                        else {
                            updateValues[id] = newValue;
                        }
                    }

                    fieldsToUpdate.push(id);
                }
            }
        });

        // ...
        var self = this;

        if (!_.isEmpty(updateValues)) {
            $.ajax({
                type: 'PATCH',
                url: '/bulk_update/' + this.options.bulkUpdateId + '?queue',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify({
                    fields_to_update: fieldsToUpdate,
                    update_values: updateValues
                }),
                success: function(data) {
                    self.ui.progressBar.width('0%');
                    self.ui.completeMessage.text('0% complete');
                    self.waitForCompletion();
                }
            });
        }
        else {
            _.defer(function() {
                self.trigger('step:done', self.options.totalElements, 0);
            });
        }
    },
    waitForCompletion: function() {
        var self = this;

        setTimeout(function() {
            if (self.isClosed) {
                return;
            }

            $.get('/bulk_update/' + self.options.bulkUpdateId, function(data) {
                if (data.status === 'finished') {
                    self.trigger('step:done', data.results.total, data.results.failed);
                }
                else {
                    var pct = (data.results.processed / Math.max(data.results.total, 1)) * 100;
                    self.ui.progressBar.width(pct + '%');
                    self.ui.completeMessage.text(Math.floor(pct) + '% complete');
                    self.waitForCompletion();
                }
            });
        }, 2000);
    }
});

var Step4 = Marionette.Layout.extend({
    template: Handlebars.compile(step4Template),
    templateHelpers: function() {
        var okElements = this.options.totalElements - this.options.failedElements;

        return {
            ok: this.options.totalElements === okElements,
            totalElements: this.options.totalElements,
            okElements: okElements,
            failedElements: this.options.failedElements,
            downloadAction: app.options.apiUrl + '/bulk_update/' + this.options.bulkUpdateId + '?results_csv',
        };
    },
    ui: {
        downloadForm: '.download-form'
    },
    events: {
        'click .be-done-centered': function() {
            this.trigger('step:done');
        },
        'click .be-done': function() {
            this.trigger('step:done');
        },
        'click .be-download-csv': function() {
            this.ui.downloadForm.submit();
        }
    }
});

export default Marionette.Layout.extend({
    className: 'bulk-edit',
    template: Handlebars.compile('<div class="be-steps-region"></div>'),
    regions: {
        stepsRegion: '.be-steps-region'
    },
    initialize: function(options) {
        this.elementTypeName = {
            'individuals': TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
            'organizations': TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}'),
            'opportunities': TextManager.parseText('${ID_DEAL, plural, capitalize}'),
            'tasks': 'Tasks'
        };
        this.elementIcon = {
            'individuals': 'icon-user',
            'organizations': 'icon-organizations',
            'opportunities': 'icon-filter',
            'tasks': 'icon-checkmark3'
        };

        this.options = options;

        if (options.selection) {
            this.entityIds = _.map(options.selection.models, function(m) {
                return m.get('id');
            });
        }

        this.selectedFields = new Backbone.Collection();
        this.selectedFields.add(new Backbone.Model());
    },
    onRender: function() {
        this.customFields = [];

        var self = this;
        var customFieldsCollection = new CustomFieldsCollection();

        customFieldsCollection.fetch({
            filterBy: [{
                attribute: 'view',
                value: this.options.elementType
            }],
            success: function(data) {
                _.each(data.models, function(model) {
                    var type = model.get('type');

                    if (type !== 'list') {
                        var cfData = {
                            id: 'custom_field.' + model.get('id'),
                            name: model.get('name'),
                            type: type,
                            required: model.get('required'),
                            isCustomField: true,
                            params: model.get('params')
                        };

                        if (type === 'dropDown') {
                            cfData.options = _.clone(model.get('options'));
                        }

                        self.customFields.push(cfData);
                    }
                });

                var leadSourcesCollection = new LeadSourcesCollection();
                leadSourcesCollection.fetch({
                    sortOn: [{
                        attribute: 'name',
                        order: 'asc'
                    }],
                    success: function(data) {
                        leadSources = [];

                        _.each(data.models, function(model) {
                            leadSources.push({
                                id: model.get('id'),
                                name: model.get('name')
                            });
                        });

                        var phasesCollection = new PhasesCollection();
                        phasesCollection.fetch({
                            success: function(data) {
                                phases = phasesCollection.getAsHierarchy();
                                self.showStep1();
                            }
                        });
                    }
                });
            }
        });
    },
    showStep1: function() {
        var self = this;

        var stepView = new Step1({
            elementIcon: this.elementIcon[this.options.elementType],
            elementsDescription: this.options.numItemsSelected + ' ' +
                                 this.elementTypeName[this.options.elementType].toLowerCase(),
            elementType: this.options.elementType,
            fieldsCollection: this.selectedFields,
            customFields: this.customFields
        });

        this.stepsRegion.show(stepView);

        this.listenTo(stepView, 'step:close', function() {
            self.close();
        });

        this.listenTo(stepView, 'step:next', function() {
            self.showStep2();
        });
    },
    showStep2: function() {
        var self = this;
        var options = {
            elementIcon: this.elementIcon[this.options.elementType],
            elementsDescription: this.options.numItemsSelected + ' ' +
                                 this.elementTypeName[this.options.elementType].toLowerCase(),
            elementType: this.options.elementType,
            fieldsCollection: this.selectedFields
        };

        if (this.entityIds) {
            options.entityIds = this.entityIds;
        }
        else {
            options.groupId = this.options.groupId;
            options.filterId = this.options.filterId;
            options.sectionId = this.options.sectionId;
        }

        var stepView = new Step2(options);

        this.stepsRegion.show(stepView);

        this.listenTo(stepView, 'step:back', function() {
            self.showStep1();
        });

        this.listenTo(stepView, 'step:close', function() {
            self.close();
        });

        this.listenTo(stepView, 'step:save', function(collection, bulkUpdateId) {
            var mbContent = {
                message: '<p style="font-size: 18px; color: black; margin-bottom: 10px;"> ' +
                         'Are you sure you want to bulk edit all these records?</p>' +
                         '<p>This operation cannot be reversed</p>',
                icon: self.elementIcon[self.options.elementType]
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.showStep3(collection, bulkUpdateId);
            });
        });
    },
    showStep3: function(collection, bulkUpdateId) {
        var self = this;

        var stepView = new Step3({
            elementIcon: this.elementIcon[this.options.elementType],
            fieldsCollection: collection,
            totalElements: this.options.numItemsSelected,
            bulkUpdateId: bulkUpdateId
        });

        this.stepsRegion.show(stepView);

        this.listenTo(stepView, 'step:done', function(total, failed) {
            self.showStep4(total, failed, bulkUpdateId);
        });
    },
    showStep4: function(totalElements, failedElements, bulkUpdateId) {
        var self = this;

        var stepView = new Step4({
            totalElements: totalElements,
            failedElements: failedElements,
            bulkUpdateId: bulkUpdateId
        });

        this.stepsRegion.show(stepView);

        this.listenTo(stepView, 'step:done', function() {
            self.trigger('bulk-edit:done');
            self.close();
        });
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import { NewSelect } from 'js/react_views/widgets/select'
import Tooltip from 'js/react_views/tooltip/tooltip'
import BuiltInDashboards from 'js/react_views/flex-dashboard/builtin_dashboards'
import PreferencesModel from 'js/models/preferences'
import { DevelopmentSelectItem } from 'app_v2/dialogs/price_lists/price_lists';
import style from './app-toolbar.css'


class CommonLeftContext extends React.Component {
    render() {
        return (
            <div className={style.dWelcomeMessage}>
                Hi there, {app.user.get('name').split(' ')[0]}
            </div>
        );
    }
}


class DashboardSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { item, onClick, onMouseEnter } = this.props;

        return (
            <li
                className={`
                    ${style.cDashboardItem}
                    ${this.state.selected ? style.iSelected : ''}
                `}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                {item.icon &&
                    <div className={`${style.iIcon} ${item.icon}`}/>
                }

                <div className={style.iName}>{item.name}</div>

                {item.editable &&
                    <div
                        className={`${style.iEdit} icon-pencil`}
                    />
                }
            </li>
        );
    }
}

class DashboardClusterDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            regionId: null,
            developmentId: null,
            phaseId: null,
        }
        this.cache = {};
        this.clusterById = {};
        this.saveVisitedFunnelId = true
    }

    componentDidMount() {
        const { activeCluster, activeFunnel } = this.props;
    
        if (activeCluster) {
            const { regionId, isCluster, clusterId, clusterName } = activeCluster;

            this.setDropdownData(regionId, isCluster 
                ? { id: clusterId, name: clusterName, isCluster: true }
                : activeFunnel, false)

        } else if (activeFunnel) {
            this.setDropdownData(activeFunnel.regionId, activeFunnel, true)
        }
    }

    setDropdownData(regionId, activeDevelopmentData, saveVisitedFunnelIdData) {
        const activeRegion = app.globalData.regions.find(region => region.id === regionId);
    
        if (activeRegion) {
            this.regionsComponent.setValue({ id: activeRegion.id, name: activeRegion.name, main: true });
        }
    
        this.cache['activeDevelopment'] = activeDevelopmentData
        this.cache['saveVisitedFunnelId'] = saveVisitedFunnelIdData
    }

    searchFilter(item, searchInputText) {
        let coinsCode = item.item?.integration_data?.coinsCode

        if(!coinsCode && item.type == 'regions'){
            coinsCode = item.integration_data?.region_id
        }

        const searchingText = item.name + (coinsCode ? ` ${coinsCode}` : "")
        return searchingText.toLowerCase().indexOf(searchInputText.toLowerCase()) !== -1;
    }

    handleRegionSelect(items) {
        const regionId = items[0].id;
        const self = this;

        const goOn = () => {
            self.setState({
                regionId: regionId
            });
            self.developmentsComponent.setData(self.cache[regionId], null);
            if(self.cache['activeDevelopment']) {
                this.saveVisitedFunnelId = self.cache['saveVisitedFunnelId']
                self.developmentsComponent.setValue(self.cache.activeDevelopment);
                self.cache.activeDevelopment = null
            } else {
                self.developmentsComponent.toggleExpanded(true)
                this.saveVisitedFunnelId = self.cache['saveVisitedFunnelId']
                self.developmentsComponent.setValue(null)
            }
        }
        if (regionId in this.cache) {
            goOn();
        } else {
            $.get(`/clusters?region_id=${regionId}&rows=-1`, (result) => {
                let developments = [];
                const clusterById = {};

                for (const cluster of result) {
                    const clusterData = {
                        isCluster: true,
                        id: cluster.id,
                        name: cluster.name,
                        item: cluster,
                        phases: []
                    }

                    clusterById[cluster.id] = clusterData;

                    developments.push(clusterData);
                }

                // funnels related to the cluster/region
                for (const funnel of app.globalData.funnelsInfo.funnels) {
                    if (funnel.cluster_id) {
                        if (funnel.cluster_id in clusterById) {
                            clusterById[funnel.cluster_id].phases.push({
                                id: funnel.id,
                                name: funnel.name,
                                item: funnel
                            });
                        }
                    } else if (funnel.region_id && funnel.region_id === regionId) {
                        developments.push({
                            id: funnel.id,
                            name: funnel.name,
                            item: funnel
                        });
                    }
                }
                Object.assign(this.clusterById, clusterById)
                developments = developments.filter(d => !d.isCluster || d.phases.length > 0);

                const plainData = [];

                for (let i = 0; i < developments.length; ++i) {
                    const d = developments[i];

                    if (d.isCluster) {
                        plainData.push({
                            id: d.id,
                            name: d.name,
                            main: true,
                            item: d.item,
                            isCluster: true,
                            borderBottom: true
                        });

                        for (const p of d.phases) {
                            plainData.push({
                                id: p.id,
                                name: p.name,
                                item: p.item,
                                borderBottom: i < developments.length - 1
                            });
                        }
                    } else {
                        plainData.push({
                            id: d.id,
                            name: d.name,
                            main: true,
                            item: d.item,
                            borderBottom: i < developments.length - 1
                        });
                    }
                }

                self.cache[regionId] = plainData;
                goOn();
            });
        }
    }

    handleDevelopmentSelect(items) {
        let saveVisitedFunnelId = true;
        if (!this.saveVisitedFunnelId) {
            saveVisitedFunnelId = this.saveVisitedFunnelId
            this.saveVisitedFunnelId = true
        }
        const newState = {
            developmentId: null,
            phaseId: null,
        };
        const development = items ? items[0] : null;
        if (!development) {
            return
        }
        const currentState = {
            funnels: null,
            isCluster: null,
            clusterId: null
        }
        if (development) {
            if (development.isCluster) {
                newState.developmentId = development.id;
                currentState.funnels = this.clusterById[development.id].phases
                currentState.clusterId = development.id
                currentState.clusterName = this.clusterById[development.id].name
                currentState.isCluster = true
                currentState.regionId = this.state.regionId
            } else {
                currentState.funnels = [development]
                currentState.isCluster = false
                newState.phaseId = development.id;
                currentState.regionId = this.state.regionId
            }
        }

        this.setState(newState);

        const self = this;
        this.props.handleClusterChange(currentState, saveVisitedFunnelId)
    }

    render() {
        const regions = app.globalData.regions.map(region => {
            return {...region, main: true}
        })
        return (

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 5
                }}>
                    <div style={{width: '200px'}}>
                        <NewSelect
                            ref={(el) => this.regionsComponent = el}
                            data={regions}
                            text='name'
                            width={200}
                            itemView={DevelopmentSelectItem}
                            placeholder='Select a region'
                            boxStyle={{
                                height: '35px',
                                alignContent: 'center'
                            }}
                            searchFilter={this.searchFilter.bind(this)}
                            onSelect={this.handleRegionSelect.bind(this)}
                        />
                    </div>

                    <div style={{width: '380px'}}>
                        <NewSelect
                            ref={(el) => this.developmentsComponent = el}
                            data={[]}
                            text='name'
                            width={326}
                            itemView={DevelopmentSelectItem}
                            options={{maxHeight: 500}}
                            height={38}
                            disabled={!this.state.regionId}
                            boxStyle={{
                                height: '35px',
                                alignContent: 'center'
                            }}
                            searchFilter={this.searchFilter.bind(this)}
                            placeholder='Select a development'
                            onSelect={this.handleDevelopmentSelect.bind(this)}
                        />
                    </div>
                </div>

        )
    }
}


class DashboardRightContext extends React.Component {
    handleFunnelChange(items) {
        vent.trigger('appToolbar:dashboard:setActiveFunnel', items[0]);
    }

    handleClusterChange(items, saveVisitedFunnelId) {
        vent.trigger('appToolbar:dashboard:setActiveCluster', items, saveVisitedFunnelId);
    }

    handleDashboardSelect(items) {
        app.user.updatePreference('default_dashboard', items[0].id);

        vent.trigger('menu-item:click');
        vent.trigger('dashboard', {
            end: true,
            replace: true
        });
    }

    render() {
        const funnels = [];

        for (const funnel of app.globalData.funnelsInfo.funnels) {
            if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                continue;
            }

            const region = funnel.region;
            const funnelRegionId = ( region && region.integration_data && region.integration_data.region_id ) ? `(${region.integration_data.region_id}) ` : '';
            const funnelCoinsCode = ( funnel.integration_data && funnel.integration_data.coinsCode ) ? `(${funnel.integration_data.coinsCode}) ` : '' ;

            funnels.push({
                id: funnel.id,
                name: funnelRegionId + funnelCoinsCode + funnel.name,
                regionId: funnel.region_id
            });
        };

        const userPrefs = app.user.get('preferences');

        const lastVisitedFunnel = userPrefs.last_visited_funnel_id;
        const activeFunnel = (lastVisitedFunnel ? funnels.find(f => f.id === lastVisitedFunnel) : null) || funnels[0];
        const preferences = app.user.get('preferences');
        const isPdcrm = AppConfig.getValue('is_pdcrm', false);
        const clusteringEnabled = AppConfig.getValue('dashboard_clustering', false);
        const isAdmin = app.user.get('is_admin');
        const newDashboardEnabled = AppConfig.getValue('dashboard.new_flex_dashboard_enabled', false);
        const funnelSelectEnabled = AppConfig.getValue('dashboard.show_header_funnel_selection', true);
        const clientId = app.user.get('client').short_id;
        let dashboards = [];

        if (newDashboardEnabled) {
            for (const dname in BuiltInDashboards) {
                if (!AppConfig.getValue('dashboard.is_enabled', true, dname)) {
                    continue;
                }

                const dashboard = BuiltInDashboards[dname];

                if (!dashboard.available || dashboard.available.indexOf(clientId) !== -1) {
                    dashboards.push({
                        id: dname,
                        name: dashboard.name,
                        isBuiltin: true,
                        icon: dashboard.icon
                    });
                }
            }

            for (const dashboard of app.globalData.dashboards) {
                dashboards.push({
                    id: dashboard.id,
                    name: dashboard.name,
                    icon: 'icon-coffee',
                    // editable: true
                });
            }
        }

        let activeDashboard = dashboards[0];

        if (!isPdcrm || newDashboardEnabled) {
            // legacy dashboards
            for (const roleId in PreferencesModel.roles) {
                if (dashboards.find(d => d.id === roleId)) {
                    continue;
                }

                if (!AppConfig.getValue('dashboard.is_enabled', true, roleId)) {
                    continue;
                }

                const role = PreferencesModel.roles[roleId];
                const legacyText = ''; // newDashboardEnabled ? ' (Legacy)' : '';

                dashboards.push({
                    id: role.id,
                    name: `${role.title} ${legacyText}`,
                    isBuiltin: true,
                    icon: 'icon-coffee'
                });
            }

            activeDashboard = dashboards.find(d => d.id === preferences['default_dashboard']) || dashboards.find(d => d.id === 'team') || dashboards[0];
        }

        const showDashboardSelector = ((isAdmin && !isPdcrm) || newDashboardEnabled) && dashboards.length > 1;

        // the dashboard creation is allowed by default
        // disabled for phase 1
        /*
        if (!('allow_dashboard_creation' in userPrefs) || userPrefs.allow_dashboard_creation) {
            dashboards = dashboards.concat([{
                id: 'separator'
            }, {
                id: 'new-dashboard',
                name: 'New Dashboard',
                icon: 'icon-plus'
            }]);
        }*/

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '15px'
            }}>
                {showDashboardSelector &&
                    <div style={{
                        width: '160px'
                    }}>
                        <NewSelect
                            data={dashboards}
                            value={activeDashboard}
                            width={190}
                            text='name'
                            itemView={DashboardSelectItem}
                            onSelect={this.handleDashboardSelect.bind(this)}
                        />
                    </div>
                }

                {newDashboardEnabled && clusteringEnabled && funnelSelectEnabled &&
                    <div style={{
                        width: '500px',
                        marginLeft: '15px'
                    }}>
                        <DashboardClusterDropdown activeCluster={userPrefs.last_visited_region_funnels} activeFunnel={activeFunnel} 
                            handleClusterChange={this.handleClusterChange}/>
                    </div>
                }

                {newDashboardEnabled && !clusteringEnabled && funnelSelectEnabled &&
                    <div style={{
                        width: '300px',
                        marginLeft: '15px'
                    }}>
                        <NewSelect
                            data={funnels}
                            value={activeFunnel}
                            width={300}
                            text='name'
                            title='name'
                            onSelect={this.handleFunnelChange.bind(this)}
                        />
                    </div>
                }
            </div>
        );
    }
}

class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showCancelSearchButton: false,
            leftContextComponent: null,
            rightContextComponent: null
        };

        this.userOptions = [{
            id: 'help',
            title: 'Support'
        }, {
            id: 'settings',
            title: 'User Preferences'
        }, {
            id: 'logout',
            title: 'Log Out'
        }];
    }

    cancelSearch() {
        this.onSearch(null);
        this.searchInput.value = '';
        this.searchInput.focus();
    }

    onSearch(value) {
        if (value) {
            vent.trigger('search:show');
            vent.trigger('search:term', value);
        } else {
            vent.trigger('search:hide');
        }

        this.setState({
            showCancelSearchButton: !!value
        });
    }

    showContextMenu(item) {
        if (item === 'dashboard') {
            this.setState({
                leftContextComponent: CommonLeftContext,
                rightContextComponent: DashboardRightContext
            });
        } else {
            this.setState({
                leftContextComponent: CommonLeftContext,
                rightContextComponent: null
            });
        }
    }

    handleUserOptionSelect(items) {
        switch (items[0].id) {
            case 'help':
                window.open('http://support.salesseek.net', '_blank');
                break;

            case 'settings':
                vent.trigger('userSettings:show');
                break;

            case 'logout':
                vent.trigger('logout');
                break;
        }
    }

    render() {
        const userPhoto = app.user.get('photo_url');
        const userInitials = app.user.get('name').split(' ').map(s => s.charAt(0)).join('');
        let LeftContextComponent = this.state.leftContextComponent;
        let RightContextComponent = this.state.rightContextComponent;

        return (
            <div className={style.appToolbar}>
                {LeftContextComponent &&
                    <div className={style.atContext}>
                        <LeftContextComponent/>
                    </div>
                }

                <div className={style.atRight}>
                    {RightContextComponent &&
                        <div className={style.atContext}>
                            <RightContextComponent/>
                        </div>
                    }

                    <div className={style.atSearch}>
                        <div className='icon-search'/>
                        <input
                            ref={(el) => this.searchInput = el}
                            placeholder='Search'
                            onChange={(ev) => this.onSearch.bind(this)(ev.target.value)}
                        />
                        <div
                            className={`
                                icon-cross
                                ${style.sCancel}
                                ${this.state.showCancelSearchButton ? style.cVisible : ''}
                            `}
                            onClick={this.cancelSearch.bind(this)}
                        />
                    </div>

                    {app.user.get('is_admin') &&
                        <div
                            className={`icon-cog2 ${style.atButton} ${style.atIcon}`}
                            style={{zIndex: 2}}
                            onClick={() => vent.trigger('settings:show')}
                        >
                            <Tooltip
                                title='App Settings'
                            />
                        </div>
                    }

                    <div
                        className={style.atThumbContainer}
                        onClick={() => this.userOptionsSelector.toggleExpanded(true)}
                    >
                        <div className={style.atThumbnail}>
                            {userPhoto ? (
                                <div className={style.tImage} style={{backgroundImage: `url('${userPhoto}')`}}/>
                            ) : (
                                <div className={style.tInitials}>{userInitials}</div>
                            )}
                        </div>

                        <div className={`icon-caret-down ${style.atCaret}`}/>
                    </div>

                    <div className={style.atSelector}>
                        <NewSelect
                            ref={(el) => this.userOptionsSelector = el}
                            data={this.userOptions}
                            searchViewVisible={false}
                            options={{minimumInputLength: -1}}
                            width={142}
                            boxStyle={{
                                visibility: 'hidden'
                            }}
                            onSelect={this.handleUserOptionSelect.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


const AppToolbarView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <Toolbar
                ref={(el) => this.component = el}
            />,
            this.$el.get(0)
        );

        const self = this;

        this.listenTo(vent, 'appMenu:highlightItem', function(item) {
            self.component.showContextMenu(item);
        });
    },
    setFocusToSearchInputBox: function() {
        this.component.searchInput.focus();
    }
});

export default AppToolbarView;

import filterOperators from 'js/views/filters/operators'
import TextManager from 'app/text-manager'


export default function() {
    var fields = [
        {
            'id': 'task_id',
            'name': 'Task',
            'operators': filterOperators.task,
            'group': 'Task'
        },
        {
            'id': 'task_type',
            'name': 'Type',
            'operators': filterOperators.task_type,
            'group': 'Task'
        },
        {
            'id': 'task_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': 'Task'
        },
        {
            'id': 'task_modified',
            'name': 'Modified',
            'operators': filterOperators.date,
            'group': 'Task'
        },
        {
            'id': 'task_creator_id',
            'name': 'Creator',
            'operators': filterOperators.user,
            'group': 'Task'
        },
        {
            'id': 'task_subject',
            'name': 'Subject',
            'operators': filterOperators.string,
            'group': 'Task'
        },
        {
            'id': 'task_text',
            'name': 'Text',
            'operators': filterOperators.string,
            'group': 'Task'
        },
        {
            'id': 'task_due_date',
            'name': 'Due Date',
            'operators': filterOperators.date,
            'group': 'Task'
        },
        {
            'id': 'task_completed',
            'name': 'Completed',
            'operators': false,
            'group': 'Task'
        },
        {
            'id': 'task_complete_date',
            'name': 'Complete Date',
            'operators': filterOperators.date,
            'group': 'Task'
        },
        {
            'id': 'task_assignee_id',
            'name': 'Assignee',
            'operators': filterOperators.user,
            'group': 'Task'
        },
        {
            'id': 'task_related_type',
            'name': 'Task Related Type',
            'operators': filterOperators.custom.dropDown,
            'group': 'Task',
            'value_def': { options: [
                {
                    id: 'individual',
                    value: TextManager.parseText('${ID_INDIVIDUAL, capitalize}')
                },
                {
                    id: 'organization',
                    value: TextManager.parseText('${ID_ORGANIZATION, capitalize}')
                },
                {
                    id: 'opportunity',
                    value: TextManager.parseText('${ID_DEAL, capitalize}')
                }
            ]}
        },
        {
            'id': 'task_related_id',
            'name': 'Related To',
            'operators': filterOperators.related,
            'group': 'Task'
        },
        {
            'id': 'task_tags',
            'name': 'Tag',
            'operators': filterOperators.tag,
            'group': 'Task'
        },
        {
            'id': 'task_funnels',
            'name': 'Funnel',
            'operators': filterOperators.funnel,
            'group': 'Task'
        },
        {
            'id': 'task_regions',
            'name': 'Region',
            'operators': filterOperators.region,
            'group': 'Task'
        },
        {
            'id': 'task_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': 'Task'
        },
        {
            'id': 'task_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': 'Task'
        },
        {
            'id': 'task_assigned_to_me',
            'name': 'Assigned To Me',
            'operators': false,
            'group': 'Task'
        },
        {
            'id': 'group_id',
            'name': 'Group',
            'operators': filterOperators.group,
            'group': 'Group'
        },
        {
            'id': 'funnel_cluster_id',
            'name': TextManager.parseText('${ID_CLUSTER, capitalize}'),
            'operators': filterOperators.funnel_cluster,
            'group': TextManager.parseText('${ID_FUNNEL, capitalize}')
        },
    ];

    return fields;
};

import $ from 'jquery'
import Backbone from 'backbone'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import app from 'js/app'
import vent from 'js/vent'
import api from 'js/api'
import forgottenTemplate from 'templates/forgotten.handlebars'


var ForgottenView = BaseView.extend({
    tagName: 'article',
    id: 'forgotten-password-container',
    className: 'hide',
    template: Handlebars.compile(forgottenTemplate),
    ui: {
        request: '#request',
        failed: '#failed',
        success: '#success'
    },
    events: {
        "click #login"  : "login",
        "click #request": "request",
        'click .go-back': 'goBack'
    },
    onShow: function() {
        this.$el.fadeIn(500);
    },
    login: function (ev) {
        if (ev) {
            ev.preventDefault();
        }
        vent.trigger('login');
    },
    request: function(ev) {
        if (ev) {
            ev.preventDefault();
        }
        var mail = this.$el.find('#email').val(),
            callbackUrl,
            view = this;

        callbackUrl = (
            window.location.protocol + '//' +
            window.location.host +
            // TODO: global app root
            (Backbone.history.root || '/') +
            '#reset'
        );

        api.forgottenPassword(null, mail, callbackUrl, function (success) {
            if (success !== null && success !== undefined) {
                view.ui.failed.hide();
                view.ui.success.fadeIn(300);
                view.ui.request.hide();
            }
            else {
                view.ui.success.hide();
                view.ui.failed.fadeIn(300);
            }
        });
    },
    goBack: function(ev) {
        if (ev) {
            ev.preventDefault();
        }
        window.location.reload();
    },
    getUrl: function () {
        return '/forgotten';
    }
});
export default ForgottenView;

import Handlebars from 'handlebars'
import Backbone from 'backbone'
import FilterQuickItems from 'js/views/filters/quick-items'
import FilterQuickOptionsBase from 'js/views/filters/quick-options-base'
import app from 'js/app.js'


var FilterModel = FilterQuickOptionsBase.FilterModel,
    FilterCollection = FilterQuickOptionsBase.FilterCollection,
    createGlobalSearchableItems = FilterQuickOptionsBase.createGlobalSearchableItems,
    createSearchableItems = FilterQuickOptionsBase.createSearchableItems,
    createAsyncItems = FilterQuickOptionsBase.createAsyncItems;

export default function() {
    var options = [
        {
            view: FilterQuickItems.ButtonView,
            name: function() {
                return 'Search for "' + this.get('search') + '"';
            },
            nameHtml: true,
            hidden: function() {
                return !(this.get('search') && this.get('search').length);
            },
            icon: 'icon-search',
            rules: function() {
                return [{
                    field: 'activity_text',
                    operator: 'contains',
                    values: this.get('search')
                }];
            }
        },
        {
            view: FilterQuickItems.SeperatorView,
            hidden: function() {
                return !this.get('search') || !this.get('search').length;
            }
        },
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'Filter By',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Creator',
                    icon: 'icon-user',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/users',
                            'name',
                            'icon-user',
                            'activity_creator_id'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Related to',
                    icon: 'icon-arrow-right',
                    click: false,
                    items: new FilterCollection([
                        createGlobalSearchableItems('activity_related_id', true)
                    ])
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            name: 'All filters',
            icon: 'icon-filters-add',
            hidden: false,
            click: function(ev, view) {
                view.root.showAdvanced();
            }
        }
    ];

    return new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        items: new FilterCollection(options)
    });
};

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import _ from 'underscore';
import classnames from 'classnames';
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import Utilities from 'js/utils/utilities'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import CustomFieldsCollection from 'js/collections/custom_fields'
import IpwTrigger from 'js/ipw_trigger'
import {
    ExtraInfoSection,
    CustomFieldsSection,
    SocialSection,
    CommunicationList,
    TextField,
    TagsField,
    Header,
    OrganizationDropdownField,
    CurrencyField,
    DateField,
    NumberField,
    DropdownField
} from 'js/react_views/common_components/common';
import app from 'js/app';

import style from './edit.css';

class Bucket extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = this.props.bucket.value;

        this.state = {
            value: this.initialValue
        };
    }

    hasChanged() {
        return this.initialValue !== this.state.value;
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) {
        this.setState({value: value});
    }

    handleInputChange(ev) {
        this.setState({
            value: ev.target.value
        });
    }

    handleInputFocus(ev) {
        this.input.select();
    }

    handleInputBlur(ev) {
        let value = parseFloat(this.state.value);

        if (_.isNaN(value)) {
            value = 0;
        }

        this.setState({
            value: value
        });

        let self = this;
        _.defer(function() {
            self.props.onValueChange();
        });
    }

    render() {
        return (
            <div className={style.bucket} key={this.props.bucket.id}>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={style.bucketValueInput}
                    value={this.state.value}
                    placeholder="Value"
                    onChange={this.handleInputChange.bind(this)}
                    onFocus={this.handleInputFocus.bind(this)}
                    onBlur={this.handleInputBlur.bind(this)}
                />
                <span className={style.bucketName}>{this.props.bucket.name}</span>
            </div>
        );
    }
}

class DealValue extends React.Component {
    constructor(props) {
        super(props);

        this.components = {};
        this.dealValue = 0;

        let self = this;

        _.forEach(this.props.buckets, function(bucket) {
            self.dealValue += bucket.value;
        });
    }

    hasChanged() {
        for (var i = 0; i < this.props.buckets.length; ++i) {
            if (this.components[this.props.buckets[i].id].hasChanged()) {
                return true;
            }
        }

        return false;
    }

    getValue() {
        let value = []

        for (var i = 0; i < this.props.buckets.length; ++i) {
            const bucket = this.props.buckets[i];

            value.push({
                id: bucket.id,
                value: this.components[bucket.id].getValue()
            });
        }

        return value;
    }

    updateBucketValue(bucketId, bucketValue) {
        this.components[bucketId].setValue(bucketValue);

        const self = this;

        _.defer(function() {
            self.updateDealValue();
        });
    }

    handleDealValueFocus() {
        this.components[this.props.buckets[0].id].input.focus();
    }

    updateDealValue() {
        if (!this.dealValueComponent) {
            return;
        }

        let dealValue = 0;

        _.forEach(this.components, function(bucket) {
            dealValue += bucket.getValue();
        });

        this.dealValueComponent.setValue(dealValue || '');
    }

    render() {
        this.components = {};

        const buckets = _.map(this.props.buckets, (bucket) => {
            return (
                <Bucket
                    ref={(el) => {if (el) {this.components[bucket.id] = el}}}
                    key={bucket.id}
                    bucket={bucket}
                    onValueChange={this.updateDealValue.bind(this)}
                />
            );
        });

        const renderValue = !AppConfig.getValue('disableValueOnDeals', false);

        return (
            <div>
                {renderValue && <TextField
                    ref={(el) => this.dealValueComponent = el}
                    label="Value"
                    value={this.dealValue || ""}
                    placeholder={TextManager.getText('ID_DEAL_VALUE')}
                    inputExtraClasses={style.dealValueInput}
                    onFocus={this.handleDealValueFocus.bind(this)}
                />}
                <div className={style.bucketsContainer}>
                    {buckets}
                </div>
            </div>
        );
    }
}

class MainInfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.components = {};
    }

    getChanges() {
        let changes = {}

        _.forEach(this.components, function(component, key) {
            if (component.hasChanged()) {
                const value = component.getValue();

                if (key === 'organization_id'){
                    if(value){
                        if (value.createNew) {
                            changes['organization'] = {
                                id: value.id,
                                name: value.name,
                                tags: value.tags,
                            };
                        } else {
                            changes[key] = value

                        }
                    } else {
                        changes['organization'] = null;
                    }
                } else {
                    changes[key] = value;
                }

            }
        });

        return changes;
    }

    getFirstInvalidField() {
        if (this.components.name.props.error) {
            return this.components.name;
        }

        if (this.components.abbreviation.props.error) {
            return this.components.abbreviation;
        }

        if (this.components.organization_id && this.components.organization_id.props.error) {
            return this.components.organization_id;
        }

        return null;
    }

    render() {
        const showOrganization = !AppConfig.getValue('disableOrganizationForDeals');
        let organization = null;
        const isNew = this.props.isNew;
        let tags = this.props.tags || [];
        let funnels = this.props.funnels || [];
        let forceValueChange = false;

        if (isNew) {
            funnels = app.user.getIndividualPreloadedFunnels();
            tags = app.user.getIndividualPreloadedTags();
            if (funnels.length > 0) {
                forceValueChange = true;
            }
            if (tags.length > 0) {
                forceValueChange = true;
            }
        }

        if (showOrganization) {
            if (this.props.organization) {
                organization = {
                    id: this.props.organization.id,
                    title: this.props.organization.name
                };
            }
        }

        let errors = {};

        if (this.props.invalidFieldErrors) {
            errors.name = this.props.invalidFieldErrors.name;
            errors.organization = this.props.invalidFieldErrors.organization;
            errors.abbreviation = this.props.invalidFieldErrors.abbreviation;
        }

        const organizationText = TextManager.parseText('${ID_ORGANIZATION, capitalize}');

        return (
            <section className={style.mainInfoSection}>
                <TextField
                    ref={(el) => this.components.name = el}
                    label="Name"
                    value={this.props.name || ''}
                    placeholder={TextManager.getText('ID_DEAL_NAME')}
                    required={true}
                    error={errors.name}
                />
                <TextField
                    ref={(el) => this.components.abbreviation = el}
                    label={TextManager.getText('ID_DEAL_ID')}
                    value={this.props.id || ''}
                    placeholder={TextManager.getText('ID_DEAL_ID_DESCRIPTION')}
                    error={errors.abbreviation}
                />
                {showOrganization && <OrganizationDropdownField
                    ref={(el) => this.components.organization_id = el}
                    label={organizationText}
                    placeholder={organizationText}
                    value={organization}
                    allowClear={true}
                    createIfNotExist={true}
                    error={errors.organization}
                    required={true}
                    onValueChange={(value) => this.props.onFieldChange('organization', value)}
                />}
                <TagsField
                    ref={(el) => this.components.tags = el}
                    label="Tags"
                    value={tags}
                    placeholder="+ Add Tag"
                    forceValueChange={forceValueChange}
                />
            </section>
        );
    }
}

class SecondInfoSection extends React.Component {
    constructor(props) {
        super(props);

        this.components = {};
        this.state = {
            phases: [],
            funnel: null,
            phase: null,
            trigger: null,
            weight: this.props.weight
        }

        this.ipwTriggerInfo = {
            phaseId: this.props.phase_id,
            date: null,
            trigger: false
        };

        this.defaultFunnelId = this.props.funnels.getLastVisitedFunnelId();
        this.phasesByFunnel = _.clone(this.props.phases);

        if (AppConfig.getValue('checkFunnelsPermissions')) {
            this.funnels = this.props.funnels.models.filter(f => security.checkPermission('view', f)).map(f => { return {id: f.get('id'), name: f.get('name')} });

            // we add the current deal's funnel although the user doesn't have permissions to see it
            if (props.funnel_id) {
                const currentFunnel = this.funnels.find(f => f.id === props.funnel_id);
                const deal = this.props.deal;

                if (!currentFunnel && deal.funnel) {
                    this.funnels.push({
                        id: deal.funnel.id,
                        name: deal.funnel.name
                    });

                    this.phasesByFunnel[deal.funnel.id] = [{
                        id: deal.phase.id,
                        text: deal.phase.name,
                        default_weight: deal.phase.default_weight,
                        order: deal.phase.order
                    }];
                }
            }

            if (this.defaultFunnelId && !this.funnels.find(f => f.id === this.defaultFunnelId)) {
                this.defaultFunnelId = this.funnels[0].id;
            }
        } else {
            this.funnels = this.props.funnels.models.map(f => { return {id: f.get('id'), name: f.get('name')} });
        }
    }

    componentDidMount() {
        this.setFunnelAndPhase(this.props.funnel_id, this.props.phase_id);
        this.render();
    }

    setFunnelAndPhase(funnelId, phaseId) {
        if (!(funnelId in this.phasesByFunnel)) {
            const phases = this.phasesByFunnel[this.defaultFunnelId];

            this.setState({
                phases: phases,
                funnel: _.findWhere(this.funnels, {id: this.defaultFunnelId}),
                phase: phases[0],
                weight: phases[0].default_weight
            });

            return;
        }

        let activePhases = this.phasesByFunnel[funnelId];

        if (!_.findWhere(activePhases, {id: phaseId})) {
            this.setState({
                phases: activePhases,
                funnel: _.findWhere(this.funnels, {id: funnelId}),
                phase: activePhases[0],
                weight: activePhases[0].default_weight
            })

            return;
        }

        const phase = _.findWhere(activePhases, {id: phaseId});

        this.setState({
            phases: activePhases,
            funnel: _.findWhere(this.funnels, {id: funnelId}),
            phase: phase,
            weight: phase.default_weight
        });
    }

    getChanges(isSaving) {
        let changes = {}
        const self = this;
        const fieldsWithInitialValues = ['buckets', 'currency', 'funnel_id', 'phase_id', 'status', 'weight'];

        _.forEach(this.components, function(component, key) {
            if (component.hasChanged() || (self.props.isNew && isSaving && (_.indexOf(fieldsWithInitialValues, key) !== -1))) {
                if (key === 'expected_close_date') {
                    const value = component.getValue()
                    changes[key] = value ? dateFormat.ISODate(value) : null;
                } else {
                    changes[key] = component.getValue();
                }
            }
        });

        // although the secondinfo is hidden, we should save the funnel_id anyway
        if (this.props.isNew && AppConfig.getValue('disableDealSecondInfoSection', false, this.props.section) && this.state.funnel) {
            changes['funnel_id'] = this.state.funnel.id;
        }

        return changes;
    }

    handleFunnelChange(id, newFunnelId) {
        this.setFunnelAndPhase(newFunnelId);

        const self = this;
        _.defer(function() {
            self.components.phase_id.setData(self.state.phases, self.state.phase.id);
        });
    }

    handlePhaseChange(id, newPhaseId, dateChanged) {
        const phase = _.findWhere(this.state.phases, {id: newPhaseId});
        let changeState = true;

        if (this.props.ipwTrigger.isActive) {
            // only update the ipwTriggerInfo if the new phase has higher order
            const phasesList = this.props.phases[this.props.funnel_id];
            const currentPhase = phasesList.find(phase => phase.id === this.ipwTriggerInfo.phaseId);
            const newPhase = phasesList.find(phase => phase.id === newPhaseId);

            if (currentPhase && newPhase && (newPhase.order > currentPhase.order)) {
                this.ipwTriggerInfo = {
                    phaseId: newPhaseId,
                    phaseName: newPhase.text,
                    date: dateChanged,
                    trigger: true
                };
            } else {
                changeState = false;
            }
        }

        if (changeState) {
            this.setState({
                phase: phase,
                weight: phase.default_weight
            });

            const self = this;

            _.defer(function() {
                if (self.components.phase_id) {
                    if (self.components.phase_id.getValue() !== self.state.phase.id) {
                        self.components.phase_id.setValue(self.state.phase.id);
                    }
                }

                if (self.components.weight) {
                    self.components.weight.setValue(self.state.weight);
                }
            });
        }
    }

    render() {
        if (!this.state.phase || AppConfig.getValue('disableDealSecondInfoSection', false, this.props.section)) {
            return null;
        }

        const statuses = [
            { id: 'none', title: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
            { id: 'none_upside', title: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
            { id: 'committed_downside', title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
            { id: 'committed', title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
        ];

        const statusValue = _.findWhere(statuses, {id: this.props.status});
        const allowDealCloseDateBeEmpty = AppConfig.getValue('allowDealCloseDateBeEmpty', false)
        const renderWeight = !AppConfig.getValue('disableWeightOnDeals', false);
        const renderCurrency = !AppConfig.getValue('disableCurrencyOnDeals', false);
        const renderStatus = !AppConfig.getValue('disableStatusOnDeals', false);
        const renderCloseDate = !AppConfig.getValue('disableCloseDateOnDeals', false);

        return (
            <section className={style.secondInfoSection}>
                {renderCurrency && <CurrencyField
                    ref={(el) => this.components.currency = el}
                    label="Currency"
                    value={this.props.currency}
                    onlyCurrenciesWithConversion={true}
                />}
                <DealValue
                    ref={(el) => this.components.buckets = el}
                    buckets={this.props.buckets}
                />
                <DropdownField
                    ref={(el) => this.components.funnel_id = el}
                    label="Funnel"
                    options={this.funnels}
                    disabled={!AppConfig.getValue('deals.edit.funnel.editable', true)}
                    value={this.state.funnel}
                    onValueChange={this.handleFunnelChange.bind(this)}
                    text="name"
                />
                <DropdownField
                    ref={(el) => this.components.phase_id = el}
                    label={TextManager.parseText('${ID_PHASE, capitalize}')}
                    options={this.state.phases}
                    disabled={!AppConfig.getValue('deals.edit.phase.editable', true)}
                    value={this.state.phase}
                    onValueChange={this.handlePhaseChange.bind(this)}
                    text="text"
                />
                {renderCloseDate && <DateField
                    ref={(el) => this.components.expected_close_date = el}
                    label={TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize}')}
                    value={this.props.close}
                    dateFormatter={dateFormat.entityInformationFormat}
                    allowEmptyValue={allowDealCloseDateBeEmpty}
                />}
                {renderStatus && <DropdownField
                    ref={(el) => this.components.status = el}
                    label={TextManager.parseText('${ID_STATUS, capitalize}')}
                    options={statuses}
                    text="title"
                    value={statusValue}
                />}
                {renderWeight && <NumberField
                    ref={(el) => this.components.weight = el}
                    label="Weight"
                    value={this.state.weight}
                />}
            </section>
        );
    }
}

class DealEditView extends React.Component {
    constructor(props) {
        super(props);

        this.isNew = !props.deal.id;
        this.productCustomFields = null;
        this.ipwTrigger = new IpwTrigger();
    }

    componentDidMount() {
        const self = this;
        _.defer(function() {
            self.mainInfoSection.components.name.focus(true);
        });
    }

    componentDidUpdate() {
        if (this.props.invalidFieldErrors && !_.isEmpty(this.props.invalidFieldErrors)) {
            let field = this.mainInfoSection.getFirstInvalidField();

            if (field) {
                field.focus();
                return;
            }

            let fieldInfo = this.customFieldsSection.getFirstInvalidField();

            if (fieldInfo) {
                if (!fieldInfo.group.props.group.hidden) {
                    if (fieldInfo.group.state.collapsed) {
                        fieldInfo.group.toggle();
                        _.defer(function() {
                            fieldInfo.field.focus();
                        });
                    } else {
                        fieldInfo.field.focus();
                    }
                } else {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error saving the individual. A required custom field is hidden',
                                timer: 3000,
                                classes: 'error'
                            };
                        }
                    });
                }
            }
        }
    }

    getDataChanges(isSaving) {
        let data = {};

        data = _.extend(data, this.mainInfoSection.getChanges());
        data = _.extend(data, this.secondInfoSection.getChanges(isSaving));
        data = _.extend(data, this.extraInfoSection.getChanges());

        if (this.customFieldsSection) {
            const customFields = this.customFieldsSection.getValues() || {};

            _.forEach(customFields, function(value, key) {
                data['custom_field.' + key] = value
            });
        }

        // set the organization if it is necessary
        if (isSaving && this.isNew && AppConfig.getValue('disableOrganizationForDeals') && data.name && !data.organization) {
            data.organization = {
                name: data.name
            };
        }

        return data
    }

    onCustomFieldValueChange(customFieldId, customFieldValue) {
        if (this.productCustomFields === null) {
            this.productCustomFields = {};

            for (let group of this.props.relatedData.processedCustomFields) {
                for (let field of group.fields) {
                    if (field.type === 'product') {
                        this.productCustomFields[field.id] = field;
                    }
                }
            }
        }

        if (customFieldId in this.productCustomFields) {
            const bucketToUpdate = this.productCustomFields[customFieldId].params.bucket.id;
            let bucketValue = Utilities.getCustomFieldProductPrice(this.productCustomFields[customFieldId], customFieldValue) * customFieldValue;
            const self = this;

            if (_.isNaN(bucketValue)) {
                bucketValue = 0;
            }

            _.forEach(this.customFieldsSection.getAllValues(), function(value, id) {
                if (value) {
                    const cf = self.productCustomFields[id];

                    if (cf && (id !== customFieldId) && (cf.params.bucket.id === bucketToUpdate)) {
                        bucketValue += Utilities.getCustomFieldProductPrice(cf, value) * value;
                    }
                }
            });

            this.secondInfoSection.components.buckets.updateBucketValue(bucketToUpdate, bucketValue);
        }
    }

    onFieldChange(field, value) {
        if (field === 'organization') {
            let organizationId = null;

            if (value && value.id && value.id !== 'create-new') {
                organizationId = value.id;
            }

            this.customFieldsSection.onEntityRelatedDataChange('organization_id', organizationId);
        }
    }

    onCustomFieldValuePopulate(customFieldId, populateMapping) {
        if (this.customFieldsSection.customFieldsList) {
            const customFieldsComponents = this.customFieldsSection.customFieldsList.props.groups || [];

            for (const cfc of customFieldsComponents) {
                const components = cfc.components || {};

                for (const k in components) {
                    if (populateMapping[k]) {
                        components[k].setValue(populateMapping[k]);
                    }
                }
            }
        }
    }

    onDateValueChange(field, value) {
        if (this.ipwTrigger.isActive) {
            const funnelId = this.props.deal.funnel_id;
            const phaseList = this.props.phases[funnelId];

            if (phaseList) {
                const phaseName = this.ipwTrigger.getPhaseNameByCfName(field.label);

                if (phaseName) {
                    const newPhase = phaseList.find(phase => phase.text === phaseName);

                    if (newPhase) {
                        this.secondInfoSection.handlePhaseChange(this.props.deal.id, newPhase.id, value);
                    }
                }
            }
        }
    }

    handleSave() {
        const changes = this.getDataChanges(true);
        const ipwTriggerInfo = this.secondInfoSection.ipwTriggerInfo;

        if (ipwTriggerInfo && ipwTriggerInfo.trigger) {
            const mbContent = {
                message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                icon: 'icon-warning'
            };
            const self = this;

            const updateFieldsAndSave = function(phaseName, triggerIpw, date) {
                self.ipwTrigger.getPopulatedIpwCustomFields(phaseName, triggerIpw, date, function(data) {
                    for (const k in data) {
                        changes[k] = data[k];
                    }

                    self.props.onSave(changes);
                });
            }

            MessageBox.showYesNo(mbContent, this.props.parent,
                function() { // Yes
                    updateFieldsAndSave(ipwTriggerInfo.phaseName, true, ipwTriggerInfo.date);
                },
                function() { // No
                    updateFieldsAndSave(ipwTriggerInfo.phaseName, false, ipwTriggerInfo.date);
                }, null,
                { staticRegion: true }
            );
        } else {
            this.props.onSave(changes);
        }
    }

    render() {
        const commentsPlaceholder = this.isNew ? TextManager.parseText('this ${ID_DEAL}') : this.props.deal.name;
        const expectedCloseDate = this.props.deal.expected_close_date ? dateFormat.createDateIgnoringTZ(this.props.deal.expected_close_date) : null;

        // if the model is new and it has info related to custom fields, we should process them
        if (this.isNew && this.props.relatedData.processedCustomFields) {
            for (const field in this.props.deal) {
                if (field.indexOf('custom_field.') !== 0) {
                    continue;
                }

                const customField = this.props.deal[field];
                const customFieldId = field.substring('custom_field.'.length);

                for (const group of this.props.relatedData.processedCustomFields) {
                    let foundIt = false;

                    for (const field of group.fields) {
                        if (field.id === customFieldId) {
                            if (field.type === 'dropDown') {
                                const option = field.options.find(o => o.id === customField);

                                if (option) {
                                    field.value_id = option.id;
                                    field.value = option.value;
                                }
                            } else {
                                field.value = customField;
                            }
                            foundIt = true;
                            break;
                        }
                    }

                    if (foundIt) {
                        break
                    }
                }
            }
        }

        let entityRelatedData = {};

        if (this.props.deal.organization) {
            entityRelatedData.organization_id = this.props.deal.organization.id;
        };

        return (
            <div
                className={style.dealEditView}
                ref={(el) => this.rootElement = el}
            >
                <div className={style.fixedHeaderView}>
                    <Header
                        isNew={this.isNew}
                        entityType={TextManager.parseText('${ID_DEAL, capitalize}')}
                        onDelete={this.props.onDelete}
                        onCancel={() => this.props.onCancel(this.getDataChanges(false))}
                        onSave={this.handleSave.bind(this)}
                        onShowPermissionView={this.props.onShowPermissionView}
                    />
                </div>
                <MainInfoSection
                    ref={(el) => this.mainInfoSection = el}
                    name={this.props.deal.name}
                    id={this.props.deal.short_id}
                    organization={this.props.deal.organization}
                    tags={this.props.deal.tags}
                    invalidFieldErrors={this.props.invalidFieldErrors}
                    onFieldChange={this.onFieldChange.bind(this)}
                    isNew={this.isNew}
                />
                <SecondInfoSection
                    isNew={this.isNew}
                    ref={(el) => this.secondInfoSection = el}
                    section={this.props.section}
                    currency={this.props.deal.currency}
                    close={expectedCloseDate}
                    deal={this.props.deal}
                    weight={this.props.deal.weight}
                    funnels={this.props.funnels}
                    phases={this.props.phases}
                    funnel_id={this.props.deal.funnel_id}
                    phase_id={this.props.deal.phase_id}
                    status={this.props.deal.status}
                    buckets={this.props.deal.buckets}
                    parent={this.props.parent}
                    ipwTrigger={this.ipwTrigger}
                />
                <ExtraInfoSection
                    ref={(el) => this.extraInfoSection = el}
                    comments={this.props.deal.comments}
                    owner={this.props.deal.owner}
                    commentsPlaceholder={commentsPlaceholder}
                />
                {this.props.relatedData.processedCustomFields && <CustomFieldsSection
                    ref={(el) => this.customFieldsSection = el}
                    processedCustomFields= {this.props.relatedData.processedCustomFields}
                    invalidFieldErrors={this.props.invalidFieldErrors}
                    onValueChange={this.onCustomFieldValueChange.bind(this)}
                    entityRelatedData={entityRelatedData}
                    onValuePopulate={this.onCustomFieldValuePopulate.bind(this)}
                    onDateValueChange={this.onDateValueChange.bind(this)}
                    parent={this.props.parent}
                />}
            </div>
        );
    }
}


export default DealEditView;
/*
    This class is used by Section. In a section you can create filters by column or using the advanced filter panel and the filters have to be available in both places,
    for instance, if you define a filter in a column the same filter is available when you open the advanced filter panel and the other way around. There are some
    exceptions for this because the 'columns filters' only allow defining 'simple filters'. A simple filter only allows filters with one variable and without any
    AND or OR rules. So if using the advanced filter panel you define a filter with multiple variables or create multiple rules for the same field, when you open
    the column filter you'll see a 'filters not allowed' message.
*/

export default class FilterManager {
    constructor(entityType, filter) {
        this.entityType = entityType;
        this.filterId = null;
        this.rules = null;

        if (filter) {
            this.setFilter(filter);
        }
    }

    setEntityType(entityType) {
        this.entityType = entityType;
    }

    setRule(fieldId, customId, data) {
        if (data) {
            if (!this.rules) {
                this.rules = [];
            }

            // update rule?
            let updated = false;

            for (let i = 0; i < this.rules.length; ++i) {
                for (let j = 0; j < this.rules[i].length; ++j) {
                    const r = this.rules[i][j];
                    let update = false;

                    if (customId) {
                        if (r.field === fieldId && r.custom === customId) {
                            this.rules[i][j] = data;
                            updated = true;
                        }
                    } else if (r.field === fieldId) {
                        this.rules[i][j] = data;
                        updated = true;
                    }
                }
            }

            if (!updated) { // new rule?
                this.rules.push([data]);
            }
        } else if (this.rules) {
            // delete all the rules applied to the fieldId
            for (let i = 0; i < this.rules.length; ++i) {
                this.rules[i] = this.rules[i].filter(orRule => orRule.field !== fieldId || orRule.custom !== customId);
            }

            this.rules = this.rules.filter(andRule => andRule.length > 0);

            if (this.rules.length === 0) {
                this.rules = null;
            }
        }
    }

    setFilter(filter) {
        if (filter) {
            this.filterId = filter.id;
            this.rules = _.clone(filter.rules);
        } else {
            this.filterId = null;
            this.rules = null;
        }
    }

    setRules(rules) {
        this.rules = _.clone(rules);

        if (!this.rules) {
            this.filterId = null;
        }
    }

    getRules() {
        return this.rules;
    }

    getColumnsRules(columns) {
        let columnsRules = {};

        if (this.rules) {
            let numFiltersPerFieldId = {};

            for (const andRule of this.rules) {
                for (const orRule of andRule) {
                    let fid = orRule.field;

                    if (orRule.custom) {
                        fid += `_${orRule.custom}`;
                    }

                    if (!(fid in numFiltersPerFieldId)) {
                        numFiltersPerFieldId[fid] = 1;
                    } else {
                        ++numFiltersPerFieldId[fid];
                    }

                    if (orRule.multiValues) {
                        ++numFiltersPerFieldId[fid]; // a way to disable this rule to be a column rule
                    }
                }
            }

            for (const andRule of this.rules) {
                for (const orRule of andRule) {
                    const column = columns.find(c => {
                        if (c.isCustomField) {
                            return c.filterId === orRule.field && c.filterCustom === orRule.custom;
                        }

                        return c.filterId === orRule.field;
                    });

                    if (column) {
                        let fid = orRule.field;

                        if (orRule.custom) {
                            fid += `_${orRule.custom}`;
                        }

                        if (numFiltersPerFieldId[fid] === 1) {
                            columnsRules[column.id] = _.clone(orRule);
                        } else {
                            columnsRules[column.id] = {
                                notAvailable: true
                            };
                        }
                    }
                }
            }
        }

        return columnsRules;
    }

    createFilter(callback) {
        if (!this.rules) {
            this.filterId = null;
            callback(null);
            return;
        }

        let rules = [];

        for (const andRule of this.rules) {
            let ors = [];

            for (const orRule of andRule) {
                let data = {
                    field: orRule.field
                };

                if (!orRule.noOperator) {
                    data.operator = orRule.operator;
                }

                if (orRule.not) {
                    data.not = true;
                }

                if (orRule.values || orRule.values === 0) {
                    data.values = orRule.values;
                }

                if (orRule.custom) {
                    data.custom = orRule.custom;
                }

                ors.push(data);
            }

            rules.push(ors);
        }

        const self = this;

        $.post(`/${this.entityType}/filters`, JSON.stringify({
            rules: rules
        }), function(result) {
            self.filterId = result.id;
            callback(self.filterId);
        });
    }
}
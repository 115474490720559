import React from 'react';

import style from './tag-list.css';

class TagList extends React.Component {
    render() {
        const { tags, caseValue } = this.props;
        const regionNameCase = caseValue === "funnels";
        return (
            <ul className={style.TagList}>
                {_.map(tags, (tag) => {
                    const regionName = regionNameCase && tag.region ? ` (${tag.region.name})` : "";
                    return <li className={style.tagItem} key={tag.id}>{tag.name}{regionName}</li>; 
                })}
            </ul>
        );
    }
}



class TagListSidebar extends React.Component {
    render() {
        const { tags, caseValue } = this.props;

        let maxTagCharecters = 20;

        let title = tags.map(tag => tag.name).join(', ');
        let tagsToDisplay = [];
        let currentTagsLength = 0;

        const regionNameCase = caseValue === "funnels";

        for (let tag of tags) {

            if ((currentTagsLength + tag.name.length) > maxTagCharecters) {
                let tagName = '';

                tagName = tag.name.substring(0, maxTagCharecters - currentTagsLength) + '...';

                tagsToDisplay.push({
                    id: tag.id,
                    name: tagName
                });

                break;
            }

            tagsToDisplay.push({
                id: tag.id,
                name: tag.name
            });

            currentTagsLength += tag.name.length;
        }

        return (
            <div className={style.TagListSidebar} title={title}>
                {_.map(tagsToDisplay, (tag) => {
                    const regionName = regionNameCase && tag.region ? ` (${tag.region.name})` : "";
                    return <div className={style.tagName} key={tag.id}>{tag.name}{regionName}</div> 
                }
                )}
            </div>
        );
    }
}

export {
    TagList,
    TagListSidebar
};

import React from 'react';
import ReactDOM from 'react-dom';
import MessageBox from 'js/views/message_box'
import {NumberField, TextField, DateField} from 'js/react_views/common_components/common';

import style from './template_generator_custom_inputs.css'

class TemplateGeneratorFillCustomInputs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            fields: this.props.fields,
        };
        
        this.values = {};

        this.fieldsCharacterLimit = {
            'text.Other_information': 29,
            'text.Other_information1': 29,
            'text.Other_details': 36,
            'text.Other_details1': 36,
            'text.Other_details2': 36,
            'text.Sales&Marketing_Proposal_and_Other_Fees': 51,
            'text.Sales&Marketing_Proposal_and_Other_Fees1': 51,
            'text.Sales&Marketing_Proposal_and_Other_Fees2': 51,
            'text.Sales&Marketing_Proposal_and_Other_Fees3': 51,
            'text.Sales&Marketing_Proposal_and_Other_Fees4': 51,
            'text.Previously_Interested_Parties': 51,
            'text.Previously_Interested_Parties1': 51,
            'text.Previously_Interested_Parties2': 51,
            'text.Previously_Interested_Parties3': 51,
        }
    }

    handleSave() {
        let self = this;
        let errors = {};
        let inputValues = {};

        for (const key in this.values) {
            let value = this.values[key].getValue();

            if (value) {
                inputValues[key] = value;
            } else {
                inputValues[key] = '';
            }
        }

        if (_.isEmpty(errors)) {
            var content = {
                message: 'Please be sure to enter the right data at this point otherwise you will need to re-select and re-add the data.',
                icon: 'icon-warning',
            };

            MessageBox.showYesNo(
                content,
                self.props.view,
                function() { // YES
                    self.props.onSave(inputValues);
                    self.props.onClose();
                }
            )
        } else {
            this.setState({
                errors: errors
            });
        }
    }

    onRefCreated(item, el) {
        this.values[item] = el;
    }

    render() {
        var self = this;

        return (
            <div className={style.CustomInputsViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                        >
                        Close
                    </div>

                    <div className={style.title}>Fill Custom Inputs</div>
                    <a
                        className={style.saveButton}
                        onClick={this.handleSave.bind(this)}
                    >Save</a>
                </div>

                <div className={style.CustomInputsBody}>
                    {
                        this.state.fields.map(function(item, i) {
                            let characterLimit = '';
                            let splitted = item.split('.');
                            let fieldType = splitted[0];
                            let fieldName = splitted[1].replaceAll('_', ' ');

                            if (item in self.fieldsCharacterLimit) {
                                characterLimit = self.fieldsCharacterLimit[item];
                            }

                            if (fieldType == 'text') {
                                return (
                                    <TextField
                                        key={item}
                                        ref={(el) => {self.onRefCreated(item, el)}}
                                        label={fieldName}
                                        fullInputBox={true}
                                        characterLimit={characterLimit}
                                        error={self.state.errors[item]}
                                    />
                                )
                            }
                            else if (fieldType == 'date') {
                                return (
                                    <DateField
                                        key={item}
                                        ref={(el) => {self.onRefCreated(item, el)}}
                                        label={fieldName}
                                        error={self.state.errors[item]}
                                    />
                                )
                            }
                            else if (fieldType == 'number') {
                                return (
                                    <NumberField
                                        key={item}
                                        ref={(el) => {self.onRefCreated(item, el)}}
                                        label={fieldName}
                                        error={self.state.errors[item]}
                                    />
                                )
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}

const TemplateGeneratorFillCustomInputsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'template-generator-custom-fill-custom-inputs');
    },
    onRender: function() {
        ReactDOM.render(
            <TemplateGeneratorFillCustomInputs
                fields={this.options.fields}
                onClose={() => this.trigger('close')}
                onSave={this.options.onSave}
                view={this.options.view}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default TemplateGeneratorFillCustomInputsView;
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import vent from 'js/vent';
import AppConfig from 'app/app-config'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization';
import { TagSelect } from 'js/react_views/widgets/select'
import TextManager from 'app/text-manager'

import style from './yoti_recipient_select.css'

class YotiRecipientSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            potentialPurchasers: [],
        }

        this.dealView = props.dealView;
        this.contactEmails = [];

        this.relatedIndividualsSelectValues = [];
        this.cfIndividualsSelectValues = [];
    }

    componentDidMount() {
        let self = this;
        const dealModel = this.dealView.model;
        const dealCfs = app.globalData.customFieldsInfo.deals;
        const dealCfsToInclude = [
            'organisation contact (potential purchaser)',
            'organisation contact (purchaser)*',
            'private individual (purchaser)*',
            'organisation (potential purchaser)',
            'private individual (potential purchaser)',
            'organisation (purchaser)*'
        ];

        let fetchContact = function(contactId, cfName) {
            let model = new IndividualModel({id: contactId});
            model.fetch({
                success: function(data) {
                    var emailComm = data.get('communication').find(comm => comm.medium === 'email');
                    if (emailComm) {
                        self.state.potentialPurchasers.push({
                            id: data.get('id'),
                            name: `${data.get('full_name')} (${cfName})`,
                            full_name: data.get('full_name'),
                            email: emailComm.value,
                        });
                    }
                }
            });
        }

        let fetchOrganization = function(organisationId, cfName) {
            let model = new OrganizationModel({id: organisationId});
            model.fetch({
                success: function(data) {
                    var emailComm = data.get('communication').find(comm => comm.medium === 'email');
                    if (emailComm) {
                        self.state.potentialPurchasers.push({
                            id: data.get('id'),
                            name: `${data.get('name')}, CF ${cfName}`,
                            full_name: data.get('name'),
                            email: emailComm.value,
                        });
                    }
                }
            });
        }

        for (const cfid in dealCfs) {
            if (dealCfsToInclude.includes(dealCfs[cfid].name.toLowerCase())) {
                const cfValue = dealModel.get(`custom_field.${cfid}`);

                if (!cfValue) {
                    continue;
                }
                const cfType = dealCfs[cfid].type;

                if (cfType === 'individual') {
                    let individuals = cfValue.split(',');
    
                    for (const individualId of individuals) {
                        fetchContact(individualId, dealCfs[cfid].name);
                    }
                } else if (cfType === 'organization') {
                    let organizations = cfValue.split(',');

                    for (const organisationId of organizations) {
                        fetchOrganization(organisationId, dealCfs[cfid].name);
                    }
                } else if (cfType === 'list') {
                    for (const entity of cfValue) {
                        if (entity.type === 'organizations') {
                            fetchOrganization(entity.id, dealCfs[cfid].name);
                        } else if (entity.type === 'individuals') {
                            fetchContact(entity.id, dealCfs[cfid].name);
                        }
                    }
                }
            }
        }
    }

    handleSave() {
        this.contactEmails = this.contactEmails.concat(this.relatedIndividualsSelectValues, this.cfIndividualsSelectValues);

        if (this.contactEmails.length === 0) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'No contacts were selected.',
                        classes: 'load-error error',
                        timer: 3000
                    };
                }
            });

            return
        }

        this.props.onSave(this.contactEmails);
        this.props.onClose();
    }

    getAllRelatedContactsWithEmail() {
        let relatedContacts = [];

        for (var individual of this.dealView.relatedData.individuals.items) {
            var emailComm = individual.communication.find(comm => comm.medium === 'email');
            if (emailComm) {
                relatedContacts.push({
                    full_name: individual.full_name,
                    email: emailComm.value,
                });
            }
        }

        return relatedContacts;
    }

    handleRelatedIndividuals(items) {
        this.relatedIndividualsSelectValues = [];

        for (const item of items) {
            if (item.id === 'all-related') {
                let contactsData = this.getAllRelatedContactsWithEmail();

                for (let contact of contactsData) {
                    this.relatedIndividualsSelectValues.push(contact);
                }
            }
            else {
                this.relatedIndividualsSelectValues.push({
                    full_name: item.value,
                    email: item.email
                });
            }
        }
    }

    handleDealPotentialPurchasers(items) {
        this.cfIndividualsSelectValues = [];

        for (const item of items) {
            this.cfIndividualsSelectValues.push({
                full_name: item.full_name,
                email: item.email,
            })

        }
    }

    render() {
        const contactPlural = TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}');
        const dealCfs = app.globalData.customFieldsInfo.deals;
        let dealCfsData = [];
        let selectAllRelatedIndividuals = [];

        for (var individual of this.dealView.relatedData.individuals.items) {
            var emailComm = individual.communication.find(comm => comm.medium === 'email');
            if (emailComm) {
                selectAllRelatedIndividuals.push({
                    id: individual.id,
                    value: individual.full_name,
                    email: emailComm.value,
                });
            }
        }

        for (const cfid in dealCfs) {
            if (dealCfs[cfid].type === 'individual') {
                dealCfsData.push(dealCfs[cfid]);
            }
        }

        return (
            <div className={style.RecipientSelectViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                    >
                        Close
                    </div>

                    <div className={style.title}>Select Recipients</div>
                    <a
                        className={style.saveButton}
                        onClick={this.handleSave.bind(this)}
                    >Save</a>
                </div>

                <div className={style.RecipientSelectBody}>

                    <div className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                        >
                            Related {contactPlural}
                        </span>
                        <TagSelect
                            ref={(el) => this.tagsSelector = el}
                            data={selectAllRelatedIndividuals}
                            text="value"
                            width="260"
                            placeholder="Select a value"
                            onChange={this.handleRelatedIndividuals.bind(this)}
                        />
                    </div>

                    <div className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                        >
                            Other {contactPlural}
                        </span>
                        <TagSelect
                            ref={(el) => this.tagsSelector = el}
                            data={this.state.potentialPurchasers}
                            text='name'
                            width="500"
                            placeholder="Select a value"
                            onChange={this.handleDealPotentialPurchasers.bind(this)}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const YotiRecipientSelectView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'yoti-recipient-select-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <YotiRecipientSelect
                dealView={this.options.dealView}
                onSave={this.options.onSave}
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default YotiRecipientSelectView;
import _ from 'underscore'
import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import app from 'js/app'
import security from 'js/utils/security'
import MessageBox from 'js/views/message_box'
import htmlSanitizer from 'js/utils/html-sanitizer'
import template from 'templates/activity/conversation-message.handlebars'


export default BaseItemView.extend({
    className: 'conversation-message',
    template: Handlebars.compile(template),
    templateHelpers: function() {
        var createdDate = this.model.get('created');
        var ownerId = this.model.get('owner_id');

        if (!ownerId) {
            var owner = this.model.get('owner');

            if (owner) {
                ownerId = owner['id'];
            }
        }

        var related = this.model.get('related');
        var allowEdit = (app.user.get('id') === ownerId) || app.user.get('is_helper');

        if (!allowEdit && related) {
            allowEdit = security.checkPermission('edit', {
                id: app.user.get('id'),
                permissions: related.permissions
            });
        }

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var params = this.model.get('params');

        var data = {
            author: params.created_by_name,
            note: htmlSanitizer.sanitize(this.model.get('note'), true),
            individualDeleted: this.model.get('individual_deleted'),
            editable: this.options.parentModel,
            allowEdit: allowEdit,
            formattedDate: createdDate
        };

        return data;
    },
    ui: {
        activityPinned: '.activity-pinned',
        iconPinned: '.icon-pinned',
        iconUnpinned: '.icon-unpinned',
        noteBody: '.note-body',
    },
    events: _.extend({
        'click .delete.has-permission': function() {
            var self = this;
            var mbContent = {
                accept_is_negative: true,
                message: 'Are you sure you want to <strong>permanently</strong> delete the activity?',
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.$el
                    .animate({ opacity: 0 }, { queue: false, duration: 200 })
                    .slideUp(250, 'easeOutQuint', function() {
                        self.trigger('deleteItem');
                    });
            });
        },
        'click .pin.has-permission': function() {
            this.trigger('togglePin');
        }
    }, BaseItemView.prototype.events),
    onRender: function() {
        this.ui.noteBody.html(htmlSanitizer.sanitize(this.model.get('note'), true));
        this.updatePinView();
    },
    updatePinView: function() {
        if (this.options.parentModel) {
            var pinned = this.model.get('pinned');

            this.ui.activityPinned.toggleClass('hide', !pinned);
            this.ui.iconPinned.toggleClass('hide', !pinned);
            this.ui.iconUnpinned.toggleClass('hide', pinned);
        }
    }
});

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import _ from 'underscore';
import classnames from 'classnames';
import vent from 'js/vent'
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import {ExtraInfoSection, CustomFieldsSection, SocialSection, CommunicationList, TextField, TagsField, Header, FunnelsField} from 'js/react_views/common_components/common';
import app from 'js/app';

import style from './edit.css';


class MainInfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.components = {};
    }

    getChanges() {
        let changes = {}

        _.forEach(this.components, function(component, key) {
            if (component.hasChanged()) {
                changes[key] = component.getValue();
            }
        });

        return changes;
    }

    getFirstInvalidField() {
        if (this.components.name.props.error) {
            return this.components.name;
        }

        return null;
    }

    render() {
        let errors = {};
        const isNew = this.props.isNew;
        let tags = this.props.tags || [];
        let funnels = this.props.funnels || [];
        let forceValueChange = false;

        if (isNew) {
            funnels = app.user.getIndividualPreloadedFunnels();
            if (funnels.length > 0) {
                forceValueChange = true;
            }

            tags = app.user.getIndividualPreloadedTags();
            if (tags.length > 0) {
                forceValueChange = true;
            }
        }

        if (this.props.invalidFieldErrors) {
            errors.name = this.props.invalidFieldErrors.missing_organization_name;
        }

        return (
            <section className={style.mainInfoSection}>
                <TextField
                    ref={(el) => this.components.name = el}
                    label="Name"
                    capitalizeValue={true}
                    value={this.props.name || ''}
                    placeholder={TextManager.parseText('${ID_ORGANIZATION, capitalize}')}
                    required={true}
                    error={errors.name}
                />
                <TextField
                    ref={(el) => this.components.website = el}
                    label="Website"
                    value={this.props.website || ''}
                    placeholder="Website URL"
                />
                <TagsField
                    ref={(el) => this.components.tags = el}
                    label="Tags"
                    value={tags}
                    placeholder="+ Add Tag"
                    forceValueChange={forceValueChange}
                />

                {AppConfig.getValue('organizations.enable_funnel_field', false) && (
                            <FunnelsField
                                ref={(el) => this.components.funnels = el}
                                label={TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}
                                value={funnels}
                                placeholder={`+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`}
                                forceValueChange={forceValueChange}
                            />
                )}
            </section>
        );
    }
}


class OrganizationEditView extends React.Component {
    constructor(props) {
        super(props);

        this.isNew = !props.organization.id;

        // todo: meter soportte de shortcut this.listenTo(vent, 'shortcut:save', function(ev) {
        // this.stopListening(vent, 'shortcut:save');
    }

    componentDidMount() {
        const self = this;
        _.defer(function() {
            self.mainInfoSection.components.name.focus(true);
        });
    }

    componentDidUpdate() {
        if (this.props.invalidFieldErrors && !_.isEmpty(this.props.invalidFieldErrors)) {
            let field = this.mainInfoSection.getFirstInvalidField();

            if (field) {
                field.focus();
                return;
            }

            let fieldInfo = this.customFieldsSection.getFirstInvalidField();

            if (fieldInfo) {
                if (!fieldInfo.group.props.group.hidden) {
                    if (fieldInfo.group.state.collapsed) {
                        fieldInfo.group.toggle();
                        _.defer(function() {
                            fieldInfo.field.focus();
                        });
                    } else {
                        fieldInfo.field.focus();
                    }
                } else {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error saving the individual. A required custom field is hidden',
                                timer: 3000,
                                classes: 'error'
                            };
                        }
                    });
                }
            }
        }
    }

    getDataChanges(isSaving) {
        let data = {};
        let communication = [];

        data = _.extend(data, this.mainInfoSection.getChanges());
        data = _.extend(data, this.extraInfoSection.getChanges());

        let websiteComm = _.clone(_.findWhere(this.props.organization.communication, {medium: 'website'}));

        if ('website' in data) {
            if (websiteComm) {
                if (data.website) {
                    websiteComm.value = data.website;
                } else {
                    websiteComm = null;
                }
            } else {
                websiteComm = {
                    medium: 'website',
                    name: null,
                    value: data.website
                };
            }
            delete data.website;
        }

        if (websiteComm) {
            communication.push(websiteComm);
        }

        communication = communication.concat(this.phoneCommunicationList.getValue());
        communication = communication.concat(this.emailCommunicationList.getValue());

        const social = this.socialSection.getValue();

        if (social.communication) {
            communication = communication.concat(social.communication);
        }

        const commToCheckA = _.sortBy(communication, function(item) {
            return item.medium + '_' + item.name + '_' + item.value;
        });

        const commToCheckB = _.sortBy(this.props.organization.communication, function(item) {
            return item.medium + '_' + item.name + '_' + item.value;
        });

        if (!_.isEqual(commToCheckA, commToCheckB)) {
            data.communication = communication;
        }

        if (this.locationCommunicationList) {
            const locations = this.locationCommunicationList.getValue();
            const locToCheckA = _.sortBy(locations, function(item) {
                return item.name + '_' + item.address;
            });

            const locToCheckB = _.sortBy(this.props.organization.locations, function(item) {
                return item.name + '_' + item.address;
            });

            if (!_.isEqual(locToCheckA, locToCheckB)) {
                data.locations = locations;
            }
        }

        if (this.customFieldsSection) {
            const customFields = this.customFieldsSection.getValues() || {};

            _.forEach(customFields, function(value, key) {
                data['custom_field.' + key] = value
            });
        }

        return data;
    }

    onCustomFieldValuePopulate(customFieldId, populateMapping) {
        if (this.customFieldsSection.customFieldsList) {
            const customFieldsComponents = this.customFieldsSection.customFieldsList.props.groups || [];

            for (const cfc of customFieldsComponents) {
                const components = cfc.components || {};

                for (const k in components) {
                    if (populateMapping[k]) {
                        components[k].setValue(populateMapping[k]);
                    }
                }
            }
        }
    }

    render() {
        let communicationItems = {};
        const self = this;

        _.forEach(['phone', 'email', 'social', 'website'], function(key) {
            communicationItems[key] = _.filter(self.props.organization.communication, function(item) {
                return item.medium === key;
            });
        });

        const commentsPlaceholder = this.isNew ? TextManager.parseText('this ${ID_ORGANIZATION}') : this.props.organization.name;
        const displayLocations = !AppConfig.getValue('disableLocationsSectionOnIndividualAndOrganization', false);
        let website = '';

        if (communicationItems.website.length > 0) {
            website = communicationItems.website[0].value;
        }

        let entityRelatedData = {};

        if (this.props.organization.id) {
            entityRelatedData.organization_id = this.props.organization.id;
        };

        return (
            <div
                className={style.organizationEditView}
                ref={(el) => this.rootElement = el}
            >
                <div className={style.fixedHeaderView}>
                    <Header
                        isNew={this.isNew}
                        entityType={TextManager.parseText('${ID_ORGANIZATION, capitalize}')}
                        onDelete={this.props.onDelete}
                        onCancel={() => this.props.onCancel(this.getDataChanges(false))}
                        onSave={() => this.props.onSave(this.getDataChanges(true))}
                        onShowPermissionView={this.props.onShowPermissionView}
                    />
                </div>
                <MainInfoSection
                    ref={(el) => this.mainInfoSection = el}
                    name={this.props.organization.name}
                    website={website}
                    tags={this.props.organization.tags}
                    funnels={this.props.organization.funnels}
                    invalidFieldErrors={this.props.invalidFieldErrors}
                    isNew={this.isNew}
                />
                <CommunicationList
                    ref={(el) => this.phoneCommunicationList = el}
                    medium="phone"
                    items={communicationItems.phone}
                />
                <CommunicationList
                    ref={(el) => this.emailCommunicationList = el}
                    medium="email"
                    items={communicationItems.email}
                    checkForDuplicates={true}
                    entityType='organizations'
                />
                <SocialSection
                    ref={(el) => this.socialSection = el}
                    items={communicationItems.social}
                />
                {displayLocations && <CommunicationList
                    ref={(el) => this.locationCommunicationList = el}
                    medium="location"
                    items={this.props.organization.locations}
                />}
                <ExtraInfoSection
                    ref={(el) => this.extraInfoSection = el}
                    comments={this.props.organization.comments}
                    owner={this.props.organization.owner}
                    commentsPlaceholder={commentsPlaceholder}
                />
                {this.props.relatedData.processedCustomFields && <CustomFieldsSection
                    ref={(el) => this.customFieldsSection = el}
                    processedCustomFields= {this.props.relatedData.processedCustomFields}
                    invalidFieldErrors={this.props.invalidFieldErrors}
                    entityRelatedData={entityRelatedData}
                    onValuePopulate={this.onCustomFieldValuePopulate.bind(this)}
                />}
            </div>
        );
    }
}

export default OrganizationEditView;
import BaseModel from 'js/models/base'


var Tag = BaseModel.extend( {
    name: 'Tag',
    urlRoot: '/tags',
    apiType: 'salesseek.core.models.tags.Tags',
    toString: function() {
        return this.get('name');
    }
} );

Tag.fromString = function(name) {
    return new Tag({name: name});
};

export default Tag;

import _ from 'underscore'
import d3 from 'd3'
import $ from 'jquery'


function filterByMyDataId(id) {
    return function() {
        var d = d3.select(this).property('mydata');

        if (typeof(d) !== "undefined" && d !== null) {
            if ( 'id' in d ) {
                return d.id === id;
            }
        }
        return false;
    };
}

function linechart() {
    var id = 'linechart',
        x = 0,
        y = 0,
        width = 400,
        height = 300,
        margin = {left:50, right:30, top:10, bottom:30},
        domain = [1, 2],
        data = [],
        span = null,
        colors = [],
        xScale, yScale,
        xAxis, yAxis,
        xContextAxis,
        series = ['hits', 'visits', 'returning', 'registered'];

    function getNumTicks() {
        // We always want a tick for each data, except when showing an hour
        // minutes. In the case of minutes we show a tick each 5 min.
        if ( span !== 'minute' ) {
            return domain.length;
        } else {
            return domain.length / 5;
        }
    }

    function getNumContextTicks() {
        if ( span === 'week' ) {
            // 3 months in quarter, which is the only full_span which
            // displays weeks. Also, it is the only current filter where
            // the full_span is not made of 1 longer time span.
            return 3;
        }
        return 1;
    }

    function getTickFormat() {
        if ( span === 'month' ) {
            return d3.time.format('%b');
        } else if ( span === 'week' || span === 'day' ) {
            return d3.time.format('%e');
        } else if ( span === 'hour' ) {
            return d3.time.format('%H');
        } else if ( span === 'minute' ) {
            return d3.time.format('%M');
        }

        // Fallback case: (null means default formatter, that is, the scale
        // default formatter)
        return null;
    }

    function getContextTickFormat() {
        return null;
    }

    function chart(selection) {
        var innerRect = {
                x: x+margin.left,
                y: y+margin.top,
                w: width - margin.left - margin.right,
                h: height - margin.top - margin.bottom
            };

        if ((innerRect.w <= 0) || (innerRect.h <= 0)) {
            return;
        }

        // Init if needed
        var svg = selection.selectAll('svg').filter(filterByMyDataId(id)),
            dataContainer,
            maxValue;

        if (svg.empty()) {
            // Initialize
            svg = selection.append('svg');
            svg.property('mydata', {id:id});

            xScale = d3.time.scale();

            yScale = d3.scale.linear();

            _.each(series, function(d, i) {
                var str = 'data ' + d;

                if ( i > 0 ) {
                    svg.append('g').attr('class', str)
                        .append('path');
                }
            });
            svg.append('g').attr('class', 'data hits').append('path');

            svg.append('g').attr('class', 'x-axis');
            svg.append('g').attr('class', 'x-context-axis');
            svg.append('g').attr('class', 'y-axis');
        }

        xScale
            .domain([domain[0], domain[domain.length-1]])
            .range([0, innerRect.w]);

        maxValue = d3.max( _.map(data, function (serie) {
            return d3.max(serie);
        }) );

        yScale
            .domain([0, maxValue])
            .range([0, -innerRect.h]);

        xAxis = d3.svg.axis()
                .scale(xScale)
                .orient('bottom')
                .ticks(getNumTicks())
                .tickSize(1)
                .tickFormat(getTickFormat());

        xContextAxis = d3.svg.axis()
                .scale(xScale)
                .orient('bottom')
                .ticks(getNumContextTicks())
                .tickSize(0)
                .tickFormat(getContextTickFormat());

        yAxis = d3.svg.axis()
            .scale(yScale)
            .orient('left')
            .ticks(5)
            .tickSize(1);

        svg.select('g.x-axis')
            .attr('transform', 'translate(' + innerRect.x + ' ' +
                (innerRect.y + innerRect.h) + ')')
            .call(xAxis);

        svg.select('g.x-context-axis')
            .attr('transform', 'translate(' + innerRect.x + ' ' +
                (innerRect.y + innerRect.h + 15) + ')')
            .call(xContextAxis);

        svg.select('g.y-axis')
            .attr('transform', 'translate(' + innerRect.x + ' ' +
                (innerRect.y + innerRect.h) + ')')
            .call(yAxis);

        _.each(data, function(serie, i) {
            var serieName = series[i],
                area = null,
                line = null,
                circles,
                selrects;

            if ( i === 0 ) {
                line = d3.svg.line()
                    .x(function(d, i2) { return xScale(domain[i2]); })
                    .y(function(d) { return yScale(d); })
                    .interpolate('monotone');
            } else if ( i < data.length - 1 ) {
                area = d3.svg.area()
                    .x(function(d, i2) { return xScale(domain[i2]); })
                    .y0(function(d, i2) {
                        return yScale(data[i+1][i2]);
                    })
                    .y1(function(d) {
                        return yScale(d);
                    })
                    .interpolate('monotone');
            } else {
                area = d3.svg.area()
                    .x(function(d, i2) { return xScale(domain[i2]); })
                    .y0(0)
                    .y1(function(d) { return yScale(d); })
                    .interpolate('monotone');
            }

            dataContainer = svg.select('g.' + serieName)
                .attr('transform', 'translate(' + innerRect.x + ' ' +
                    (innerRect.y + innerRect.h) + ')');

            if ( line !== null ) {
                dataContainer.select('path')
                    .transition()
                        .attr('d', line(serie, i));
            } else {
                dataContainer.select('path')
                    .transition()
                        .attr('d', area(serie, i));
            }

            circles = dataContainer.selectAll('circle').data(serie);

            circles
                .transition()
                .attr('cx', function(d, i) { return xScale(domain[i]); })
                .attr('cy', yScale);

            circles.enter().append('circle')
                .attr('opacity', 0)
                .attr('r', 5)
                .attr('cx', function(d, i) { return xScale(domain[i]); })
                .attr('cy', yScale);

            circles.exit()
                .remove();

            // Selection rectangles
            // TODO: Currently it is creating sel rects for each serie,
            //       that needs to be fixed to create them once.
            selrects = dataContainer.selectAll('rect').data(serie);

            selrects.enter().append('rect')
                .attr('x', function(d, i) {
                    if ( i > 0 ) {
                        return (xScale(domain[i-1]) + xScale(domain[i]))/2;
                    }
                })
                .attr('y', -innerRect.h)
                .attr('width', function(d, i) {
                    var left = xScale(domain[i]),
                        right = left;

                    if ( i > 0 ) {
                        left = (xScale(domain[i-1])+xScale(domain[i]))/2;
                    }
                    if ( i < domain.length-1 ) {
                        right = (xScale(domain[i])+xScale(domain[i+1]))/2;
                    }

                    return right - left;
                })
                .attr('height', innerRect.h)
                .on('click', function(d, i) {
                    $(this).trigger('timefilter',
                        {type: span,
                         ts: domain[i]});
                });

            selrects
                .attr('x', function(d, i) {
                    if ( i > 0 ) {
                        return (xScale(domain[i-1]) + xScale(domain[i]))/2;
                    }
                })
                .attr('y', -innerRect.h)
                .attr('width', function(d, i) {
                    var left = xScale(domain[i]),
                        right = left;

                    if ( i > 0 ) {
                        left = (xScale(domain[i-1])+xScale(domain[i]))/2;
                    }
                    if ( i < domain.length-1 ) {
                        right = (xScale(domain[i])+xScale(domain[i+1]))/2;
                    }

                    return right - left;
                })
                .attr('height', innerRect.h);

            selrects.exit().remove();
        });
    }

    chart.id = function(_) {
        if (!arguments.length) { return id; }
        id = _;
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        width = _;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _;
        return chart;
    };

    chart.domain = function(_) {
        if (!arguments.length) { return domain; }
        domain = _;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;
        return chart;
    };

    chart.span = function(_) {
        if (!arguments.length) { return span; }
        span = _;
        return chart;
    };

    chart.colors = function(_) {
        if (!arguments.length) { return colors; }
        colors = _;
        return chart;
    };

    chart.series = function(_) {
        if (!arguments.length) { return series; }
        series = _;
        return chart;
    };

    return chart;
}

export default linechart;

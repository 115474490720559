import _ from 'underscore'
import AppConfig from 'app/app-config'
import Utilities from 'js/utils/utilities'

/*
 * Safe date creation.
 */
function sDate(data) {
    var date = new Date(data);
    if (!isFinite(date)) {
        // Safari fails on 'yyyy-m-d' so it converts to 'yyyy/m/d'
        date = new Date(data.replace(/-/g,'/'));
    }
    return date;
}

var YEAR = 1,
    MONTH = 2,
    DAY = 3,
    HOUR = 4;

var one_second = 1000,
    one_minute = one_second * 60,
    one_hour = one_minute * 60,
    one_day = one_hour * 24,
    one_week = one_day * 7,
    one_month = one_day * 30;

function monthToShortText(month) {
    var m_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    if (_.isNull(month)) {
        return m_names;
    }

    return m_names[month];
}

function monthToLongText(month) {
    var m_names = ["January", "February", "March",
        "April", "May", "June", "July", "August", "September",
        "October", "November", "December"];

    return m_names[month];
}

function dayToLongText(day) {
    var days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return days[day - 1];
}

function dayToShortText(day) {
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day];
}

function hoursToAmPmText(hours) {
    if (hours < 12) {
        return '' + hours + ' AM';
    } else {
        return '' + (hours-12) + ' PM';
    }
}

function scheduledFormat(date) {
    date = _.isDate(date) ? date : new Date(date);

    var hours = date.getHours();
    hours = (hours > 9) ? hours : '0' + hours;
    var minutes = date.getMinutes();
    minutes = (minutes > 9) ? minutes : '0' + minutes;

    return hours + ':' + minutes + ' on ' + dayToLongText(date.getDay()) + ' ' + date.getDate() + ' ' + monthToLongText(date.getMonth());
}

function scheduledShortFormat(date) {
    date = _.isDate(date) ? date : new Date(date);

    var hours = date.getHours();
    hours = (hours > 9) ? hours : '0' + hours;
    var minutes = date.getMinutes();
    minutes = (minutes > 9) ? minutes : '0' + minutes;

    return hours + ':' + minutes + ' ' + monthToShortText(date.getMonth()) + ' ' + date.getDate();
}

function formatRawScheduledDate(d) {
    var dateAndTime = d.split('T');
    var date = shortFormatWithYear(dateAndTime[0]);
    var time = dateAndTime[1].split(':');
    time = time[0] + ':' + time[1];
    return time + ' ' + date;
}

function shortFormat(data) {
    data = _.isDate(data) ? data : new Date(data);
    var month = monthToShortText(data.getMonth()),
        day = data.getDate();
    return  month + ' ' + day;
}

function shortFormatWithShortYear(data) {
    data = _.isDate(data) ? data : new Date(data);
    var month = monthToShortText(data.getMonth()),
        day = data.getDate(),
        year = String(data.getFullYear());
    return  month + ' ' + day + ' \'' + year.substring(year.length, 2);
}

function shortFormatWithYear(data) {
    data = _.isDate(data) ? data : new sDate(data);
    var month = monthToShortText(data.getMonth()),
        day = data.getDate(),
        year = data.getFullYear();
    return  month + ' ' + day + ', ' + year;
}


function shortFormatWithYearUTC(data) {
    data = _.isDate(data) ? data : new Date(data);
    var month = monthToShortText(data.getUTCMonth()),
        day = data.getUTCDate(),
        year = data.getUTCFullYear();
    return  month + ' ' + day + ', ' + year;
}

function shortFormatWithYearTime(data) {
    data = _.isDate(data) ? data : new Date(data);

    var hours, minutes;
    var use24hClock = AppConfig.getClientPreferenceValue('use24hClock');
    var sufix = '';

    hours = data.getHours();

    if (!use24hClock) {
        var hours12 = Utilities.convert24hTo12hClock(hours);
        hours = hours12.hours;
        sufix = hours12.am ? ' AM' : ' PM';
    }

    hours = (hours > 9) ? hours : '0' + hours;
    minutes = data.getMinutes();
    minutes = (minutes > 9) ? minutes : '0' + minutes;

    return shortFormatWithYear(data) + ' ' + hours + ':' + minutes + sufix;
}

function shortFormatWithShortYearTime(data) {
    data = _.isDate(data) ? data : new Date(data);
    return shortFormatWithShortYear(data) + ' ' + shortFormatTime(data);
}

function shortSmartFormat(data) {
    data = _.isDate(data) ? data : new Date(data);

    var now = new Date(),
        diff = Math.abs(data - now),
        past_date = data < now,
        result;


    if (diff < one_second) {
        result = 'Now';
    } else if (diff < one_minute) {
        result = Math.floor(diff/one_second) + 's';
    } else if(diff < one_hour) {
        result = Math.floor(diff/one_minute) + 'm';
    } else if(diff < one_day) {
        result = Math.floor(diff/one_hour) + 'h';
    } else if(diff < one_week) {
        result = Math.floor(diff/one_day) + 'd';
    } else if(diff < 3*one_month) {
        result = monthToShortText(data.getMonth()) + ' ' + data.getDate();
        if(data.getFullYear() !== now.getFullYear()) {
            result += ', ' + data.getFullYear();
        }
    } else {
        result = monthToShortText(data.getMonth()) + ' ' + data.getFullYear();
    }
    return [past_date, result, shortFormatWithShortYearTime(data)];
}

function shortSmartFormatCloseDate(data) {
    data = _.isDate(data) ? data : new Date(data);

    var now = new Date(),
        diff = Math.abs(data - now),
        past_date = data < now,
        result;

    if (data.getFullYear() === now.getFullYear() &&
        data.getMonth() === now.getMonth() &&
        data.getDate() === now.getDate()) {
        result = 'Today';
    } else if (data.getFullYear() === now.getFullYear() &&
        data.getMonth() === now.getMonth() &&
        data.getDate() === now.getDate() + 1) {
        result = 'Tomorrow';
    } else if(diff < 3*one_month) {
        result = monthToShortText(data.getUTCMonth()) + ' ' + data.getUTCDate();
        if(data.getFullYear() !== now.getFullYear()) {
            result += ', ' + data.getUTCFullYear();
        }
    } else {
        result = monthToShortText(data.getUTCMonth()) + ' ' + data.getUTCDate() + ', ' + data.getUTCFullYear();
    }
    return [past_date, result];
}

function shortFormatTime(data, includeSeconds) {
    data = _.isDate(data) ? data : new Date(data);

    var hours = data.getHours();
    var minutes = data.getMinutes();
    var seconds = data.getSeconds();

    var use24hClock = AppConfig.getClientPreferenceValue('use24hClock');
    var sufix = '';

    if (!use24hClock) {
        var hours12 = Utilities.convert24hTo12hClock(hours);
        hours = hours12.hours;
        sufix = hours12.am ? ' AM' : ' PM';
    }

    hours = (hours > 9) ? hours : '0' + hours;
    minutes = (minutes > 9) ? minutes : '0' + minutes;
    seconds = (seconds > 9) ? seconds : '0' + seconds;

    return `${hours}:${minutes}${includeSeconds ? `:${seconds}` : ''}${sufix}`;
}

function shortFormatWithTime(data) {
    data = _.isDate(data) ? data : new Date(data);
    return shortFormat(data) + ' ' + shortFormatTime(data);
}

function smartFormat(date, precission, minFrom) {
    date = _.isDate(date) ? date : new Date(date);

    var now = new Date(),
        ret = '',
        from, i;

    if (date.getFullYear() !== now.getFullYear()) {
        from = YEAR;
    } else if ( date.getMonth() !== now.getMonth()) {
        from = MONTH;
    } else if ( date.getDate() !== now.getDate()) {
        from = DAY;
    } else if ( date.getHours() !== now.getHours()) {
        from = HOUR;
    } else {
        from = precission;
    }

    from = Math.min(from, precission);
    if ( minFrom ) {
        from = Math.min(from, minFrom);
    }

    for (i = from; i <= precission; i++) {
        if ( i === YEAR ) {
            ret += date.getFullYear();
            if ( i < precission ) {
                ret += ', ';
            }
        } else if ( i === MONTH ) {
            if ( i === precission ) {
                ret += monthToLongText(date.getMonth());
            } else {
                ret += monthToShortText(date.getMonth());
            }
            if ( i < precission ) {
                ret += ' ';
            }
        } else if ( i === DAY ) {
            ret += date.getDate();
            if ( i < precission ) {
                ret += ', ';
            }
        } else if ( i === HOUR ) {
            if ( i === precission ) {
                ret += hoursToAmPmText(date.getHours());
            } else {
                ret += '?';
            }
        }
    }

    return ret;
}

function smartFormatYear(date) {
    return smartFormat(date, YEAR);
}

function smartFormatMonth(date) {
    return smartFormat(date, MONTH);
}

function smartFormatWeek(date) {
    return 'Week of ' + smartFormat(date, DAY, MONTH);
}

function smartFormatDay(date) {
    return smartFormat(date, DAY, MONTH);
}

function smartFormatHour(date) {
    return smartFormat(date, HOUR, MONTH);
}

function parseDate(stringDate) {
    // /^                                      - match at the beginning of the string
    //     (                                   - date-time in the format "2015-09-18T10:51",
    //         \d{4}-\d{2}-\d{2}T\d{2}\:\d{2}    captured as the first captured portion of the match
    //     )
    //     (?:                                 - non-capturing parentheses, we're not interested in capturing the whole part
    //         (\:\d{2})                       - colon + seconds: ":33", the second captured part
    //         (\.\d{3,9})?                    - dot + fraction of a second, 3-9 digits, ".482657", optional, third captured part
    //     )?                                  - this whole part is optional
    // $/                                      - match at the end of the string
    var dateTimeExpr = /^(\d{4}-\d{2}-\d{2}T\d{2}\:\d{2})(?:(\:\d{2})(\.\d{3,9})?)?$/,
        millis, result;

    if (_.isString(stringDate)) {
        // If the date string conforms to ISO 8601 / ES5 date time string format
        // and is missing the time zone offset,
        // add the 'Z' time zone offset to prevent inconsistent interpretation by browsers
        // Also convert fractions of a second to milliseconds

        result = dateTimeExpr.exec(stringDate);
        if (result) {
            if (result[3]) {
                // just truncate the extra digits, permitting a 1 msec error
                millis = result[3].substring(1, 4);
                stringDate = stringDate.replace(dateTimeExpr, "$1$2." + millis);
            }

            stringDate += 'Z';
        }

        return new Date(stringDate);
    }

    return stringDate;
}

/**
 * return ISO 8601 date
 *
 * @param       date        JS date object
 * @returns     {string}    ISO representation of date
 */
function ISODate(date) {
    date = _.isDate(date) ? date : new Date(date);

    var d = date.getDate(),
        m = date.getMonth() + 1;

    return date.getFullYear()  + '-' + (m > 9 ? m : '0' + m) + '-' + (d > 9 ? d : '0' + d);
}

function createDateIgnoringTZ(date) {
    date = _.isDate(date) ? date : new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}

function timelineFormat(d) {
    d = _.isDate(d) ? d : new Date(d);

    var hours = d.getHours();
    var minutes = d.getMinutes();
    minutes = (minutes > 9) ? minutes : '0' + minutes;

    var time;

    if (AppConfig.getClientPreferenceValue('use24hClock')) {
        time = hours + ':' + minutes + ' ';
    } else {
        var hours12 = Utilities.convert24hTo12hClock(hours);
        time = hours12.hours + ':' + minutes + (hours12.am ? ' AM ' : ' PM ');
    }

    var now = new Date();
    var date = '';
    var sameYear = d.getFullYear() === now.getFullYear();

    if (sameYear && d.getMonth() === now.getMonth() && d.getDate() === now.getDate()) {
        date = 'Today';
    }
    else {
        // calculate the difference between dates (both since midnight)
        var midNow = new Date(now.getTime());
        midNow.setHours(0, 0, 0, 0);

        var midD = new Date(d.getTime());
        midD.setHours(0, 0, 0, 0);

        var diff = Math.abs(midD - midNow);

        if (diff <= one_day) {
            date = midD < midNow ? 'Yesterday' : 'Tomorrow';
        }
        else {
            date = monthToShortText(d.getMonth()) + ' ' + d.getDate();

            if (!sameYear) {
                date += ', ' + d.getFullYear();
            }
        }
    }

    return time + date;
}

function formatDDMMYYYYDate(date){
    var parsedDate = parseDate(date)
    if(parsedDate &&  date != ""){
        const day = parsedDate.getDate();
        const month = parsedDate.getMonth() + 1
        const year = parsedDate.getFullYear()

        return day + "/" + month + "/" + year;

    }
    return date;
}

function formatMMDDYYYYDate(date){
    var parsedDate = parseDate(date)
    if(parsedDate &&  date != ""){
        const day = parsedDate.getUTCDate();
        const month = parsedDate.getUTCMonth() + 1
        const year = parsedDate.getUTCFullYear()

        return month + "/" + day + "/" + year;

    }
    return date;
}

function formatYYYYMMDDDate(date){
    var parsedDate = parseDate(date)
    if (parsedDate &&  date != "") {
        const day = parsedDate.getDate();
        const month = parsedDate.getMonth() + 1
        const year = parsedDate.getFullYear()

        return year + '/' + month + "/" + day;

    }
    return date;
}

function formatDDMMYYYYhhmmssDate(date){
    var parsedDate = parseDate(date)
    if (parsedDate &&  date != "") {
        var hours = date.getHours();
        hours = (hours > 9) ? hours : '0' + hours;

        var minutes = date.getMinutes();
        minutes = (minutes > 9) ? minutes : '0' + minutes;

        var seconds = parsedDate.getSeconds();
        seconds = (seconds > 9) ? seconds : '0' + seconds;

        var time;
    
        if (AppConfig.getClientPreferenceValue('use24hClock')) {
            time = hours + ':' + minutes + ':' + seconds + ' ';
        } else {
            var hours12 = Utilities.convert24hTo12hClock(hours);
            time = hours12.hours + ':' + minutes + ':' + seconds + (hours12.am ? ' AM ' : ' PM ');
        }

        return formatDDMMYYYYDate(date) + ' ' + time;
    }

    return date;
}

function fullMonthYearFormat(date) {
    date = _.isDate(date) ? date : new Date(date);
    return `${monthToLongText(date.getMonth())}, ${date.getFullYear()}`;
}

function fullDayMonthYearFormat(date) {
    date = _.isDate(date) ? date : new Date(date);
    return `${monthToLongText(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`;
}

function dueItemFormat(d) {
    return timelineFormat(d);
}

function entityInformationFormat(d) {
    return shortFormatWithYear(d);
}

function entityInformationFormatUTC(d) {
    return shortFormatWithYearUTC(d);
}

function timelapseFormat(data) {
    data = _.isDate(data) ? data : new Date(data);

    var now = new Date();
    var diff = Math.abs(data - now);
    var value = '';
    var measure = '';

    if (diff < one_second) {
        return 'Now';
    } else if (diff < one_minute) {
        value = Math.floor(diff / one_second);
        measure = value === 1 ? 'second' : 'seconds';
    } else if (diff < one_hour) {
        value = Math.floor(diff / one_minute);
        measure = value === 1 ? 'minute' : 'minutes';
    } else if (diff < one_day) {
        value = Math.floor(diff / one_hour);
        measure = value === 1 ? 'hour' : 'hours';
    } else {
        return scheduledShortFormat(data);
    }

    return `${value} ${measure} ago`;
}

function shortTimelapseFormat(data) {
    data = _.isDate(data) ? data : new Date(data);

    var now = new Date();
    var diff = Math.abs(data - now);
    var value = '';
    var measure = '';

    if (diff < one_second) {
        return 'Now';
    } else if (diff < one_minute) {
        value = Math.floor(diff / one_second);
        measure = 's';
    } else if (diff < one_hour) {
        value = Math.floor(diff / one_minute);
        measure = 'm';
    } else if (diff < one_day) {
        value = Math.floor(diff / one_hour);
        measure = 'h';
    } else {
        return scheduledShortFormat(data);
    }

    return `${value}${measure}`;
}

export default {
    scheduledShortFormat: scheduledShortFormat,
    scheduledFormat: scheduledFormat,
    formatRawScheduledDate: formatRawScheduledDate,
    shortSmartFormat: shortSmartFormat,
    shortSmartFormatCloseDate: shortSmartFormatCloseDate,
    shortFormatMonth: monthToShortText,
    formatMonth: monthToLongText,
    shortFormatDay: dayToShortText,
    shortFormat: shortFormat,
    shortFormatWithYear: shortFormatWithYear,
    shortFormatWithYearUTC: shortFormatWithYearUTC,
    shortFormatWithTime: shortFormatWithTime,
    shortFormatWithYearTime: shortFormatWithYearTime,
    smartFormatYear: smartFormatYear,
    smartFormatMonth: smartFormatMonth,
    smartFormatWeek: smartFormatWeek,
    smartFormatDay: smartFormatDay,
    smartFormatHour: smartFormatHour,
    parseDate: parseDate,
    ISODate: ISODate,
    formatDDMMYYYYDate: formatDDMMYYYYDate,
    formatMMDDYYYYDate: formatMMDDYYYYDate,
    formatYYYYMMDDDate: formatYYYYMMDDDate,
    createDateIgnoringTZ: createDateIgnoringTZ,
    shortFormatWithShortYear: shortFormatWithShortYear,
    shortFormatWithShortYearTime: shortFormatWithShortYearTime,
    shortFormatTime: shortFormatTime,
    timelineFormat: timelineFormat,
    dueItemFormat: dueItemFormat,
    entityInformationFormat: entityInformationFormat,
    entityInformationFormatUTC: entityInformationFormatUTC,
    formatDDMMYYYYhhmmssDate: formatDDMMYYYYhhmmssDate,
    fullMonthYearFormat: fullMonthYearFormat,
    fullDayMonthYearFormat: fullDayMonthYearFormat,
    dayToLongText: dayToLongText,
    dayToShortText: dayToShortText,
    timelapseFormat: timelapseFormat,
    shortTimelapseFormat: shortTimelapseFormat
};

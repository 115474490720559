import _ from 'underscore'

import BaseModel from 'js/models/base'
import app from 'js/app'
import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager';


var Communication = BaseModel.extend({
    urlRoot: '/communication',
    defaults: _.extend({}, BaseModel.prototype.defaults, {
        medium: 'email'
    }),
    /*
     * Determine communication medium and return relevant href prefix
     * with communication.value
     *
     * Used on contact detail view.
     */
    valueHref: function(item) {
        /* communication.medium is either:
         *  - 'phone' (Skype link),
         *  - 'email' (Mailto link), or
         *  - 'url' (Plain url)
         */
        var result = '', medium, value;

        item = item || this;
        medium = item.get('medium');
        value = item.get('value');

        switch(medium) {
        case 'phone':
            if (value) {
                result = ("tel:" + value.replace(/\s/g, ''));
            } else {
                result = '';
            }
            break;
        case 'email':
            result = "mailto:" + value;
            if (!AppConfig.getValue('disableArchiveBCCLink', false)) {
                var archive_email = 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
                result += "?bcc=" + archive_email;
            }
            break;
        case 'url':
            result = item.get('value');
            if (result && result.indexOf('//') === -1) {
                result = 'http://' + result;
            }
            break;
        case 'linkedin':
            result = item.get('value');
            if (result && result.indexOf('linkedin.com') === -1) {
                result = 'http://www.linkedin.com/in/' + result;
            } else if (result && result.indexOf('//') === -1) {
                result = 'http://' + result;
            }
            break;
        }
        return result;
    },
    toString: function() {
        return this.get('value');
    }
});
Communication.fromString = function (string) {
    return new Communication({value: string});
};
export default Communication;

import React from 'react';

import app from 'js/app';
import PanelBase from './base';
import {DropdownField, TextField, TagsField, CheckboxField, OptionField, CurrencyValueField} from 'js/react_views/common_components/common';
import Permissions from 'app_v2/components/permissions/permissions';

import style from './save_edit_group.css';


class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: !!props.expanded
        };
    }

    expand() {
        if (this.state.expanded) {
            return;
        }

        this.setState({
            expanded: true
        });
    }

    handleHeaderClick() {
        this.setState({
            expanded: !this.state.expanded
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.accordion}
                    ${this.state.expanded ? style.expanded : ''}
                `}
            >
                <div
                    className={style.aHeader}
                    onClick={this.handleHeaderClick.bind(this)}
                >
                    <div className={style.hTitle}>{this.props.title}</div>

                    <div
                        className={`
                            ${style.hToggle}
                            ${this.state.expanded ? 'icon-caret-down' : 'icon-caret-right'}
                        `}
                    />
                </div>

                <div className={style.aContent}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}


export default class SaveEditGroupPanel extends PanelBase {
    constructor(props) {
        super(props);

        this.labelWidth = 98;
        this.groupTypes = [{
            id: 'static',
            value: 'Static'
        }, {
            id: 'smart',
            value: 'Smart'
        }];

        this.costOptions = [{
            id: 'total',
            title: 'Total'
        }, {
            id: 'cpl',
            title: 'Per Lead'
        }];

        this.state = {
            counter: 0,
            errors: {},
            groupId: null,
            populateFromGroupId: null,
            allowedTypes: null,
            groupType: null,
            groupName: '',
            groupTags: null,
            groupProtected: false,
            groupTarget: 0,
            groupCost: 0,
            groupCostBasis: null,
            permissionsAccordionExpanded: false,
            generalAccordionExpanded: true
        };
    }

    componentWillMount() {
        super.componentWillMount();

        this.showCloseButton(true);
    }

    getGeneralContent() {
        const showProtectedOption = app.user.get('is_admin') || app.user.get('is_helper');
        let allowedTypes = [];

        if (!this.state.allowedTypes) {
            allowedTypes = this.groupTypes;
        } else {
            if (this.state.allowedTypes.static) {
                allowedTypes.push(this.groupTypes[0]);
            }

            if (this.state.allowedTypes.smart) {
                allowedTypes.push(this.groupTypes[1]);
            }
        }

        return (
            <div>
                <DropdownField
                    ref={(el) => this.groupTypeComponent = el}
                    label='Group Type'
                    labelWidth={this.labelWidth}
                    placeholder='Select Group Type'
                    fullInputBox={true}
                    dropdownWidth={269}
                    options={allowedTypes}
                    disabled={!!this.state.groupId}
                    value={this.state.groupType || allowedTypes[1]}
                    minimumInputLength={-1}
                />

                <TextField
                    ref={(el) => this.groupNameComponent = el}
                    label='Group Name'
                    labelWidth={this.labelWidth}
                    value={this.state.groupName}
                    placeholder='Enter group name'
                    fullInputBox={true}
                    required={true}
                    error={this.state.errors.groupName}
                />

                <TagsField
                    ref={(el) => this.tagsComponent = el}
                    labelWidth={this.labelWidth}
                    value={this.state.groupTags}
                    placeholder='+ Add Tag'
                    fullInputBox={true}
                    dropdownWidth={269}
                />

                {showProtectedOption &&
                    <CheckboxField
                        ref={(el) => this.protectedComponent = el}
                        label='Protected'
                        value={this.state.groupProtected}
                        labelWidth={this.labelWidth}
                    />
                }
            </div>
        );
    }

    getPermissionsContent(entityId) {
        return (
            <div>
                <Permissions
                    ref={(el) => this.permissionsComponent = el}
                    entityType='groups'
                    entityId={entityId}
                />
            </div>
        );
    }

    getMarketingContent() {
        return (
            <div>
                <CurrencyValueField
                    ref={(el) => this.costComponent = el}
                    labelWidth={this.labelWidth}
                    label='Cost'
                    value={this.state.groupCost}
                    fullInputBox={true}
                />

                <OptionField
                    ref={(el) => this.costBasisComponent = el}
                    labelWidth={this.labelWidth}
                    label='Cost Basis'
                    options={this.costOptions}
                    value={this.state.groupCostBasis || this.costOptions[0]}
                />

                <CurrencyValueField
                    ref={(el) => this.targetComponent = el}
                    labelWidth={this.labelWidth}
                    label='Target'
                    value={this.state.groupTarget}
                    fullInputBox={true}
                />
            </div>
        );
    }

    show(entityType, args) {
        args = args || {};

        this.entityType = entityType;

        const self = this;

        const continueWork = (group) => {
            self.showArgs = args;

            let newState = {
                counter: self.state.counter + 1,
                errors: {},
                groupId: args.groupId || null,
                populateFromGroupId: args.populateFromGroupId || null,
                allowedTypes: args.allowedTypes,
                permissionsAccordionExpanded: !!args.permissionsAccordionExpanded,
                generalAccordionExpanded: 'generalAccordionExpanded' in args ? args.generalAccordionExpanded : true
            };

            if (group) {
                newState.groupName = group.name;
                newState.groupType = self.groupTypes.find(gt => gt.id === group.group_type);
                newState.groupTags = group.tags;
                newState.groupProtected = group.display_options?.protected || false;
                newState.groupCost = group.cost;
                newState.groupTarget = group.target;
                newState.groupCostBasis = self.costOptions.find(co => co.id === group.cost_basis);
            } else {
                newState.groupName = '';
                newState.groupType = null;
                newState.groupTags = null;
                newState.groupProtected = false;
                newState.groupCost = 0;
                newState.groupTarget = 0;
                newState.groupCostBasis = null;
            };

            self.setState(newState);

            let title = 'New Group';

            if (args.groupId) {
                title = 'Edit Group';
            } else if (args.populateFromGroupId) {
                title = 'Save As Group';
            }

            self.setTitle(title);

            if (!args.groupId) {
                _.delay(function() {
                    self.groupNameComponent.focus();
                }, 500);
            }

            super.show();
        }

        if (args.groupId) {
            this.setLoading(true);

            $.get(`/groups/${args.groupId}`, function(group) {
                self.setLoading(false);
                continueWork(group);
            });
        } else {
            continueWork();
        }
    }

    handleSave() {
        let errors = {};

        const groupName = this.groupNameComponent.getValue();

        if (!groupName) {
            errors.groupName = 'Group name is required';

            this.generalComponent.expand();

            const self = this;

            _.defer(function() {
                self.groupNameComponent.focus();
            });
        }

        this.setState({
            errors: errors
        });

        if (_.isEmpty(errors)) {
            this.setLoading(true);

            let groupData = {
                element_type: this.entityType,
                group_type: this.groupTypeComponent.getValue(),
                name: this.groupNameComponent.getValue(),
                owner: {
                    id: app.user.get('id'),
                    name: app.user.get('name')
                },
                display_options: {
                    protected: !!this.protectedComponent && this.protectedComponent.getValue()
                },
                cost: this.costComponent.getValue(),
                target: this.targetComponent.getValue()
            };

            const costBasis = this.costBasisComponent.getValue();

            if (costBasis === 'cpl') {
                groupData.cost_basis = costBasis;
            }

            const tags = this.tagsComponent.getValue();

            if (tags.length > 0) {
                groupData.tags = tags;
            }

            if (this.state.populateFromGroupId) {
                groupData.populate = true;
                groupData.from_group_id = this.state.populateFromGroupId;
            }

            if (this.showArgs?.extraFields) {
                groupData = _.extend(groupData, this.showArgs.extraFields);
            }

            let method = 'POST';
            let url = '/groups';

            if (this.state.groupId) {
                method = 'PATCH';
                url = `/groups/${this.state.groupId}`;
            }

            const self = this;
            const isNew = method === 'POST';
            const showPermissions = app.user.get('client').permission_type !== 'rba';

            $.ajax({
                type: method,
                url: url,
                dataType: 'json',
                data: JSON.stringify(groupData),
                success: function(result) {
                    if (showPermissions) {
                        $.get(`/groups/${result.id}/acl`, function(gp) {
                            const permissions = self.permissionsComponent.getPermissions().sort(p => p[0]);
                            const groupPermissions = gp.sort(p => p[0]);

                            // update permissions if they are different
                            const sp = JSON.stringify(permissions);

                            if (sp !== JSON.stringify(groupPermissions)) {
                                $.post(`/groups/${result.id}/acl`, sp, function() {
                                    self.setLoading(false);
                                    self.props.onGroupSaved(result.id, result, isNew);
                                    self.hide();
                                });
                            } else {
                                self.setLoading(false);
                                self.props.onGroupSaved(result.id, result, isNew);
                                self.hide();
                            }
                        });
                    } else {
                        self.setLoading(false);
                        self.props.onGroupSaved(result.id, result, isNew);
                        self.hide();
                    }
                }
            });
        }
    }

    getContent() {
        const showPermissions = app.user.get('client').permission_type !== 'rba';

        return (
            <div
                key={`segroup_${this.state.counter}`}
                className={style.saveEditGroup}
            >
                <div className={style.content}>
                    <Accordion
                        ref={(el) => this.generalComponent = el}
                        title='General'
                        content={this.getGeneralContent()}
                        expanded={this.state.generalAccordionExpanded}
                    />

                   {showPermissions &&
                       <Accordion
                            title='Permissions'
                            content={this.getPermissionsContent(this.state.groupId)}
                            expanded={this.state.permissionsAccordionExpanded}
                        />
                    }

                   <Accordion
                        title='Marketing'
                        content={this.getMarketingContent()}
                    />
                </div>

                <div className={style.footer}>
                    <div
                        className={style.fButton}
                        onClick={this.handleSave.bind(this)}
                    >
                        Save
                    </div>
                </div>
            </div>
        );
    }
}

import BaseCollection from 'js/collections/base'
import FunnelModel from 'js/models/funnel'
import app from 'js/app'


export default BaseCollection.extend({
    model: FunnelModel,
    getLastVisitedFunnelId: function() {
        if (this.length === 0) {
            return null;
        }

        var lastId = app.user.get('preferences').last_visited_funnel_id;
        var lastFunnel = null;

        if (lastId) {
            lastFunnel = this.find(function(m) {
                return lastId === m.get('id');
            });
        }

        return lastFunnel ? lastId : this.models[0].get('id');
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import template from 'templates/eula-viewer.handlebars'


var EulaViewer = Marionette.Layout.extend({
    className: 'eula-viewer',
    template: Handlebars.compile(template),
    events: {
        'click .accept': function(ev) {
            ev.preventDefault();

            var self = this;
            app.user.updatePreference('eula_acceptance_date', new Date().toISOString(), function() {
                self.close();
            });
        },
        'click .cancel': function(ev) {
            ev.preventDefault();
            vent.trigger('logout');
            this.close();
        }
    },
    onShow: function() {
        // Currently the only way to add a class to the modal el of a ModalRegion...
        this.$el.parent('.modal').addClass('eula-viewer-modal');
    }
});

export default EulaViewer;

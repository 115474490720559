import BaseCollection from 'js/collections/base'
import IndividualModel from 'js/models/contact'


var IndividualsCollection = BaseCollection.extend({
    defaultSortOn: [{
        attribute: 'first_name',
        order: 'asc'
    }, {
        attribute: 'last_name',
        order: 'asc'
    }],
    model: IndividualModel,
    constructor: function() {
        return BaseCollection.prototype.constructor.apply(this, arguments);
    }
});

export default IndividualsCollection;

/*
 *  This view is for task section. It contains quick filter side bar and task listing views. It return task list
 *  view for use in other places where task listing needs to be used.
 */

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react';
import ReactDOM from 'react-dom';

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import vent from 'js/vent'
import security from 'js/utils/security'
import TextManager from 'app/text-manager'
import MessageBox from 'js/views/message_box'
import dateFormat from 'js/utils/date-format'
import DateTimePicker from 'js/widgets/date-time-picker'
import ItemPermissionsView from 'js/views/item_permissions'
import TaskModel from 'js/models/task'
import TaskCollection from 'js/collections/task'
import TaskGroupModel from 'js/models/task_group'
import TaskGroupCollection from 'js/collections/task_group'
import FilterableCollection from 'js/utils/filterable_collection'
import ModalRegion from 'js/views/base/modal-region'
import NoCollectionView from 'js/views/base/no_collection'
import UserModel from 'js/models/user'
import AssignView from 'js/views/social/assign'
import LoadingView from 'js/views/loading'
import OrganizationModel from 'js/models/organization'
import IndividualModel from 'js/models/contact'
import OpportunityModel from 'js/models/opportunity'
import TaskFilterModel from 'js/models/task_filter'
import TaskListingBaseView from 'app/_components/IOD-listing/IOD/task-listing/task-listing-base'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import { getDefaultColumns } from 'js/views/base/table-body-container-view'
import GroupModel from 'js/models/group'
import GroupsCollection from 'js/collections/groups'
import GroupElementsCollection from 'js/collections/group_elements'
import GroupsClubbing from 'js/views/groups_clubbing'
import IODGroupListingView from 'app/_components/IOD-listing/IOD-group-listing/IOD-group-listing'
import FilterQuickOptionsTasks from 'js/views/filters/quick-options-tasks'
import tasksTemplate from 'app/tasks/tasks.handlebars'
import taskListContainerTemplate from 'app/tasks/task-list-container.handlebars'
import taskTemplate from 'app/tasks/task.handlebars'
import quickFilterListTemplate from 'app/tasks/quick-filter-list.handlebars'
import quickFilterItemTemplate from 'app/tasks/quick-filter-item.handlebars'
import singleListTemplate from 'app/tasks/single-task-list-view.handlebars'
import groupedTaskListsTemplate from 'app/tasks/grouped-task-lists-view.handlebars'
import noTasksTemplate from 'app/tasks/no-tasks.handlebars'
import taskListTemplate from 'app/tasks/task-list.handlebars'
import AppConfig from '../app-config'
import individualInactiveModal from 'js/views/custom_code/individual_inactive_reasons_modal'
import buildMyGroupsList from 'js/utils/build_my_groups_list.js'

import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';

var TasksView, QuickFilterList, FilterItem, SingleTaskListContainer, TaskListContainer, TaskList, TaskItem,
    TaskGroupListContainer, TaskGroupList, NoTasksView, AddTaskView;

var typeMap = {
        "individuals": "individual",
        "opportunities": "opportunity",
        "organizations": "organization"
    },
    iconMap = {
        "individuals": "icon-user",
        "opportunities": "icon-filter",
        "organizations": "icon-home"
    },
    formatResult = function(item) {
        return '<i class="' + iconMap[item.type] + '"></i> ' + item.title_highlight;
    };

/*
 * Transforms 'where' dictionary to extraArgs format
 */
function whereToExtraArgs(where) {
    return _.map(where, function(val, key) {
        if (val === true) {
            return key;
        }
        else {
            return { attribute: key, value: val};
        }
    });
}

/*
 * Transforms 'where' dictionary to URL string
 *
 * return string URLPart    like: &a=1&b=2&sample
 */
function whereToURLString(where) {
    var URLPart = '';
    _.each(where, function(val, key) {
        if (val === true) {
            URLPart += '&' + key;
        }
        else {
            URLPart += '&' + key + '=' + val;
        }
    });
    return URLPart;
}

// used in quick filter side bar list
FilterItem = Marionette.ItemView.extend({
    className: 'item',
    template: Handlebars.compile(quickFilterItemTemplate),
    ui: {
        tagsContainer: '.tags-container',
    },
    templateHelpers: function() {
        if (this.model.get('is_adhoc')) {
            if (!this.model.get('icon')) {
                return {
                    user_initials: app.user.get('name').split(' ').map(function (s) { return s.charAt(0); }).join(''),
                    user_photo: app.user.get('photo_url')
                };
            }
            else {
                return {
                    icon: this.model.get('icon')
                };
            }
        }

        return {
            is_smart: (this.model.get('group_type') === 'smart'),
            is_owner: (this.model.get('owner').id === app.user.get('id'))
        };
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.options.parent.showTable(this.model.get('id'), this.model);
            });
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.parent.clubbing.initItem(this);
        this.$el.find('.basic-list-item').tooltip();
        
        if ( !this.model.get('is_adhoc') ) {
            ReactDOM.render(
                <TagListSidebar
                    tags={this.model.get('tags')}
                />,
                this.ui.tagsContainer.get(0)
            );
        }
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
    }
});

var TagItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tag',
    template: Handlebars.compile('{{name}}')
});

// side bar list in task section left side
QuickFilterList = Marionette.CompositeView.extend({
    className: 'tasks-sidebar list-wrapper has-floating-header at-top',
    template: Handlebars.compile(quickFilterListTemplate),
    getItemView: function(model) {
        if (model && model.isFolder()) {
            return this.clubbing.SidebarFolderView;
        }

        return FilterItem;
    },
    itemViewOptions: function(model) {
        var options = {
            model: model,
            parent: this
        };

        if (model && model.isFolder()) {
            options.clubbing = this.clubbing;
        }

        return options;
    },
    ui: {
        groupsList: '.loaded-task-list',
        newGroup: '.add-new-task-group',
        regularHeader: '.list-header-nav',
        searchHeader: '.list-header-nav-search',
        searchInput: '.search-input'
    },
    events: {
        'click .add-new-task-group': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.options.parent.showNewGroup();
            });
        },
        'click .search': function() {
            this.ui.regularHeader.addClass('hidden');
            this.ui.searchHeader.removeClass('hidden');
            this.ui.searchInput.val('');
            this.ui.searchInput.focus();
        },
        'click .cancel-search': function() {
            this.ui.regularHeader.removeClass('hidden');
            this.ui.searchHeader.addClass('hidden');
            this.filterableCollection.resetFilter();
        },
        'keydown .search-input': function(ev) {
            const self = this;

            _.defer(function() {
                self.filterableCollection.filter(ev.target.value);
            });
        }
    },
    initialize: function() {
        this.collection = new GroupsCollection();
        this.clubbing = this.createClubbing();
        this.filterableCollection = new FilterableCollection(this);
        this.fetchGroups();

        this.listenTo(vent, 'group:save', this.fetchGroups);

        // this is required for highlighting loaded groups
        this.listenTo(this.collection, 'sync', function() {
            if (this.selected) {
                this.highlightItem(this.selected);
            }
        });
    },
    onRender: function() {
        this.listenTo(this.options.parent, 'select', this.highlightItem);
        this.listenTo(this.options.parent, 'group:delete', this.fetchGroups);

        this.$el.find('[data-toggle="tooltip"]').tooltip();
        this.$el.find('.all-tasks a').tooltip();
    },
    onDomRefresh: function() {
        // show scrollbar initially
        this.$el.find('.content-container').nanoScroller();
    },
    appendHtml: function(collectionView, itemView){
        collectionView.ui.groupsList.append(itemView.el);
    },
    createClubbing: function() {
        var self = this;
        var clubbing = null;

        var onClubChange = function(render, save) {
            if (render) {
                // save and restore the scroll position after render
                var scrollPosition = self.$el.find('.content').scrollTop();

                self.collection.reset(clubbing.options.rootCollection.models);
                self.filterableCollection.setInitialCollection(self.collection);

                _.defer(function() {
                    self.$el.find('.content').scrollTop(scrollPosition);
                    self.$el.find('.content-container').nanoScroller();
                });
            }

            if (save) {
                app.user.updatePreference('tasks_groups', clubbing.encodeCollection());
            }
        };

        clubbing = new GroupsClubbing({
            view: this,
            itemView: FilterItem,
            elementType: 'tasks',
            onItemMoved: function() {
                onClubChange(true, true);
            },
            onFolderCreated: function() {
                onClubChange(true, false);
            },
            onItemInsertedToFolder: function() {
                onClubChange(true, true);
            },
            onFolderNameChanged: function() {
                onClubChange(false, true);
            },
            onFolderStateChanged: function() {
                self.$el.find('.content-container').nanoScroller();
                onClubChange(false, true)
            }
        });

        return clubbing;
    },
    addAdHocGroup: function(id, name, icon, extraOptions) {
        this.collection.add(new GroupModel(_.extend({
            id: id,
            name: name,
            icon: icon,
            element_type: 'tasks',
            is_adhoc: true
        }, extraOptions)), {at: 0, silent: true});
    },
    fetchGroups: function(groupModelToSelect) {
        var self = this;

        this.collection.fetch({
            rows: -1,
            data: {
                element_type: 'tasks'
            },
            success: function () {
                // add ad hoc groups
                self.addAdHocGroup('my', 'My tasks');
                self.addAdHocGroup('all', 'All tasks', 'icon-globe2');

                if (AppConfig.getValue('tasks.groups.tasks_by_owner.visible', true)) {
                    self.addAdHocGroup('by_owner', 'Tasks by owner', 'icon-user');
                }

                if (AppConfig.getValue('tasks.groups.today.visible', true)) {
                    self.addAdHocGroup('today', 'Today', 'icon-calendar');
                }

                if (AppConfig.getValue('tasks.groups.this_week.visible', true)) {
                    self.addAdHocGroup('this_week', 'This week', 'icon-calendar');
                }

                self.addAdHocGroup('assigned_by_me', 'Assigned by me', 'icon-forward');

                if (AppConfig.getValue('tasks.groups.show_funnels_tags_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    const tags = app.globalData.tags;
                    let validTags = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                        if (tag) {
                            validTags.push(tag);
                        }
                    }

                    for (const vt of validTags) {
                        self.addAdHocGroup(vt.id, vt.name, 'icon-list-dev', {isFunnelTagGroup: true});
                    }
                }

                if (AppConfig.getValue('tasks.groups.show_funnels_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    let validFunnels = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        if (funnel) {
                            validFunnels.push(funnel);
                        }
                    }

                    for (const vf of validFunnels) {
                        self.addAdHocGroup(vf.id, vf.name, 'icon-list-dev', {isFunnelGroup: true});
                    }
                }

                if (AppConfig.getValue('tasks.groups.show_regions_adhoc_groups')) {
                    const regions = app.globalData.regions || [];

                    for (const region of regions) {
                        self.addAdHocGroup(region.id, `All tasks: ${region.name}`, 'icon-globe2', {isRegionGroup: true, regionName: region.name});
                    }
                }

                let taskGroups = app.user.get('preferences').tasks_groups;

                if (AppConfig.getValue('tasks.groups.show_my_groups')) {
                    taskGroups ??= self.collection.map(item => item.get('id'));
                    taskGroups = buildMyGroupsList(self.collection, taskGroups);
                }

                self.collection.reset(self.clubbing.manageCollection(self.collection, taskGroups).models);
                // update scrollbar after group modification
                self.$el.find('.content-container').nanoScroller();

                // ...
                if (groupModelToSelect) {
                    var gid = groupModelToSelect.get('id');
                    self.highlightItem(gid);
                    self.options.parent.rememberGroup(gid);
                }

                self.filterableCollection.setInitialCollection(self.collection);

                self.trigger('groups:loaded', self.collection);
            }
        });
    },
    highlightItem: function(id) {
        this.selected = id;

        var activeEl = this.$el.find(".group-list div[id='" + id + "']");

        activeEl.addClass('active');
        this.$el.find('.item.active').not(activeEl).removeClass('active');

        this.filterableCollection.findActiveModel();
    }
});

TaskItem = Marionette.Layout.extend({
    tagName: 'li',
    className: 'task',
    template: Handlebars.compile(taskTemplate),
    templateHelpers: function() {
        var assignee = this.model.get('assignee'),
            owner = this.model.get('owner'),
            assignee_is_user = assignee && assignee.id === app.user.get('id'),
            assignee_is_owner = assignee && assignee.id === this.model.get('owner_id'),
            dueDate = dateFormat.dueItemFormat(dateFormat.parseDate(this.model.get('due_date'))),
            dueToday = dueDate === 'Today',
            overDue = this.model.get('due_date') !== null
                && !dueToday && (dateFormat.parseDate(this.model.get('due_date')) < new Date()),
            delegateTypeChecklist = this.model.get('delegate_type') !== null
                && this.model.get('delegate_type') === 'checklist';

        return {
            assignee_is_user: assignee_is_user,
            assignee_is_owner: assignee_is_owner,
            owner_name: owner ? owner.name : null,
            // name: assignee_is_user ? 'Me' : assignee.name || null,
            name: assignee ? assignee.name : null,
            overDue: overDue,
            dueToday: dueToday,
            dueDate: dueDate,
            useNarrowView: this.options.useNarrowView,
            delegateTypeChecklist: delegateTypeChecklist
        };
    },
    ui: {
        textInput: '.edit-text-input',
        subjectInput: '.subject-input',
        taskText: '.task-text',
        taskSubject: '.task-subject',
        taskTextContainer: '.task-text-container',
        dueDateInput: '.due-date-input',
        setDueDate: '.set-due-date',
        assigneeInput: '.assignee-input',
        setAssignee: '.set-assignee',
        editTaskContainer: '.edit-task-container',
        taskDueDate: '.task-due-date',
        taskOwner: '.task-owner',
        taskAssignee: '.task-assignee',
        taskAssigneeInfo: '.task-assignee-info',
        deleteConfirm: '.delete-confirm',
        relatedInput: '.related-input',
        setRelated: '.set-related',
        relatedName: '.target-name',
        target: '.target',
        editInline: '.inline',
        editAligned: '.aligned',
        tagsInput: '.tags-input',
        funnelsInput: '.funnels-input'
    },
    regions: {
        tagsRegion: '.tags-container',
        individualInactiveReasons: {
            selector: '.individual-inactive-reasons', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    events: {
        'click .task-body': function() {
            this.$el.toggleClass('selected');
        },
        // completes the task, task intentionally not removed from list immediately
        'click .complete-task': function() {
            var self = this;
            this.model.save({
                completed: !this.model.get('completed')
            },{
                patch: true,
                wait: true,
                success: function() {
                    self.$el.find('.wrapper').toggleClass('done', self.model.get('completed'));
                    vent.trigger('update-checklist');

                    if (self.model.get('completed')) {
                        if (AppConfig.getValue('on_task_completation.show_activity_note_popup', false) ||
                            AppConfig.getValue('on_task_completation.show_task_popup', false)) {
                            vent.trigger('quick:edit:task', self.model, {
                                showNextSteps: true
                            });
                        }
                    }
                },
                error: function(model, response) {
                    let errorMsg = ''
                    if(response.status === 400){
                        errorMsg = 'You are not authorized to complete this task.'
                    }else {
                        errorMsg = 'There was an error processing this request.'
                    }
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: errorMsg,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        },
        'click .edit': function() {
            var self = this,
                input = this.ui.textInput,
                subject = this.model.get('subject'),
                text = this.model.get('text');

            this.$el.find('.task-body').fadeOut(100, function() {
                self.$el.addClass('editing');
                self.$el.find('.edit-task-container').fadeIn(100);

                // Fix for moving caret to end of input on focus
                // See: http://stackoverflow.com/a/8631903/1712562
                input.focus();
                input.val('');
                input.val(text);
                self.ui.subjectInput.val(subject);

                self.tagsSelect.setValue(self.model.get('tags'));
                self.funnelsSelect.setValue(self.model.get('funnels'));
                self.setTextAreaHeight();

                self.$el.trigger('resize');

                $(document).on('keydown.cancel-task-edit', self.hideEditCallback);
            });
        },
        'focus .edit-text-input': function(ev) {
            this.ui.editTaskContainer.addClass('focus');
        },
        'blur .edit-text-input': function(ev) {
            this.ui.editTaskContainer.removeClass('focus');
        },
        'keydown .edit-text-input': function(ev) {
            if (ev.keyCode === 13 && ev.shiftKey) {
                ev.preventDefault(); // Prevent linebreaks in textarea
                this.save();
            }
        },
        'keyup .edit-text-input': 'setTextAreaHeight',
        'click .task-save': function(ev) {
            ev.preventDefault();
            this.save();
        },
        'click .task-edit-cancel': 'exitEditMode',
        'click .target': function(ev) {
            var model,
                type = this.model.get('related_type'),
                Models = {
                    'organization': OrganizationModel,
                    'individual': IndividualModel,
                    'opportunity': OpportunityModel
                };

            model =  new Models[type]({ id: this.model.get('related_id') });

            ev.preventDefault();

            // this event used within this file
            this.trigger('show:related', model, type);
        },
        'click .delete': function(ev) {
            var self = this;

            this.$el.addClass('delete-confirmation');

            this.ui.deleteConfirm.focus();

            $('body').on('mousedown.cancel-task-delete', function(ev) {
                if (!$(ev.target).is(self.ui.deleteConfirm)) {
                    self.$el.removeClass('delete-confirmation');
                    $('body').off('mousedown.cancel-task-delete');
                }
            });
        },
        'click .delete-confirm': function() {
            var self = this;

            this.$el
                .animate({ opacity: 0 }, { queue: false, duration: 200 })
                .slideUp(250, 'easeOutQuint', function() {
                    self.model.destroy({
                        wait: true,
                        success: function () {
                            $('body').off('mousedown.cancel-task-delete');
                        },
                        error: function() {
                            self.$el
                                .animate({ opacity: 1 }, { queue: false, duration: 200 })
                                .slideDown(200, 'easeOutQuint');
                        }
                    });
                });
        },
        'keydown .delete-confirm': function(ev) {
            // Only enter deletes task when button has focus
            if (ev.keyCode !== 13) {
                this.$el.removeClass('delete-confirmation');
                this.ui.textInput.focus();
                ev.preventDefault();
                $('body').off('mousedown.cancel-task-delete');
            }
        },
        'click .set-due-date': function() {
            if (this.ui.dueDateInput.hasClass('has-value')) {
                this.removeDueDate();
                return;
            }

            var self = this,
                calendarWidth = 466,
                leftCorrection = 100,
                css = {};

            // align to window left side
            if (self.ui.setDueDate.offset().left < calendarWidth + 20 - leftCorrection) {
                css.left = 20;
            }
            // align left to due date icon
            else {
                css.left = self.ui.setDueDate.offset().left - calendarWidth + leftCorrection;
            }

            // position below
            if ($(document).height()/2 > self.ui.setDueDate.offset().top) {
                css.top = self.ui.setDueDate.offset().top + self.ui.setDueDate.height() + 10;
            }
            // position above
            else {
                css.bottom = $(document).height() - self.ui.setDueDate.offset().top + 10;
            }

            var dateTimePicker = new DateTimePicker({
                altField: this.ui.dueDateInput,
                css: css
            });
            dateTimePicker.showPicker();
        },
        'change .due-date-input': function() {
            this.dueDateTime = this.ui.dueDateInput.val();
            if (this.ui.dueDateInput.val()) {
                this.ui.dueDateInput.val(dateFormat.dueItemFormat(this.ui.dueDateInput.val()));
            }
            this.dueDate = this.ui.dueDateInput.val();
            this.ui.dueDateInput.toggleClass('has-value', this.dueDate.length > 0);
            this.ui.setDueDate.attr('title', this.dueDate).tooltip('fixTitle');
            this.ui.textInput.focus();
        },
        'keydown .set-due-date': function(ev) {
            if (this.isDeleteKey(ev) === true) {
                this.removeDueDate();
            }
        },
        'click .set-assignee': function(ev) {
            if (this.disableAssignment) {
                $(ev.currentTarget).tooltip('show');
                return;
            }

            ev.preventDefault();
            this.ui.assigneeInput.select2('open');
        },
        'click .set-related': function(ev) {
            if (this.disableRelated) {
                $(ev.currentTarget).tooltip('show');
                return;
            }

            if (this.relatedId) {
                this.relatedId = null;
                this.relatedType = null;
                this.ui.setRelated.attr('title', 'Related to').tooltip('fixTitle');
                this.relatedSelect.$el.removeClass('has-value');
                this.ui.relatedInput.select2('data', null);
            }
            else {
                ev.preventDefault();
                this.ui.relatedInput.select2('open');
            }
        },
        'change .tags-input': function() {
            var self = this;

            _.delay(function() {
                self.ui.textInput.focus();
            }, 5);
        },
        'change .funnels-input': function() {
            var self = this;

            _.delay(function() {
                self.ui.textInput.focus();
            }, 5);
        }
    },
    initialize: function() {
        var view = this;

        // we need to update icon visibility only the first time the tab is visible
        this.listenTo(vent, 'individualTabs:change opportunityTabs:change organizationTabs:change', function(id) {
            if (id === 'tasks') {
                view.updateEditIconVisibility();
                view.stopListening(vent, 'individualTabs:change opportunityTabs:change organizationTabs:change');
            }
        });
    },
    onRender: function() {
        var self = this,
            dueDate = dateFormat.dueItemFormat(dateFormat.parseDate(this.model.get('due_date'))),
            assignee = this.model.get('assignee'),
            selectVal = null,
            where = this.options.filterModel ? this.options.filterModel.get('where') : null;

        if (this.model.get('due_date')) {
            this.ui.setDueDate.attr('title', dueDate).tooltip('fixTitle');
            this.ui.dueDateInput.addClass('has-value');
        }
        else {
            this.ui.setDueDate.attr('title', 'Set due date').tooltip('fixTitle');
        }

        if (assignee && assignee.name) {
            if (assignee.id === app.user.id) {
                this.ui.setAssignee.attr('title', 'Assigned to me').tooltip('fixTitle')
                    .addClass('assignee-is-user');
            }
            else {
                this.ui.setAssignee.attr('title', 'Assigned to ' + assignee.name).tooltip('fixTitle')
                    .removeClass('assignee-is-user');
            }

            selectVal = {
                id: assignee.id,
                name: assignee.name
            };
        }
        else {
            this.ui.setAssignee.attr('title', 'Assign a user').tooltip('fixTitle');
        }

        // Init assignee select2
        this.assigneeSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.assigneeInput,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: selectVal,

            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'assignee-select-popover popover'
            }
        });

        this.ui.assigneeInput.select2('readonly', this.disableAssignment);

        this.ui.assigneeInput.select2('container')
            .toggleClass('has-value', selectVal !== null)
            .toggleClass('assignee-is-user', this.assignedUserId === app.user.id);

        this.listenTo(this.assigneeSelect, 'change', function(item) {
            self.assignedUserId = item.id;
            if (self.assignedUserId === app.user.id) {
                self.ui.setAssignee.attr('title', 'Assigned to me').tooltip('fixTitle')
                    .addClass('assignee-is-user');
            }
            else {
                self.ui.setAssignee.attr('title', 'Assigned to ' + item.name).tooltip('fixTitle')
                    .removeClass('assignee-is-user');
            }
            self.ui.assigneeInput.select2('container')
                .addClass('value-set')
                .toggleClass('assignee-is-user', self.assignedUserId === app.user.id);

            self.ui.textInput.focus();
        });

        this.$el.find('[data-toggle=tooltip]').tooltip();

        this.hideEditCallback = function(ev) {
            if (ev.which === 27 && self.$el.find(':focus').length > 0) {
                self.exitEditMode(ev);
            }
        };

        var value = {};
        if (this.model.get('related_id')) {
            value = {
                title: this.model.get('related').name,
                id: this.model.get('related_id')
            };
            this.ui.setRelated.attr('title', 'Related to ' + this.model.get('related').name).tooltip('fixTitle');
            this.relatedId = this.model.get('related_id');
            this.relatedType = this.model.get('related_type');
        }

        // Init related select2
        this.relatedSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.relatedInput,
            url: '/v1/search',
            params: {
                types: 'individuals,organizations,opportunities',
                order_by: 'last_viewed desc'
            },
            text: 'title',
            value: value,
            search: true,
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover select2-drop-wider',
                formatResult: formatResult
            }
        });

        this.listenTo(this.relatedSelect, 'change', function(item) {
            self.relatedId = item.id;
            self.relatedType = typeMap[item.type];

            self.ui.setRelated.attr('title', 'Related to ' + item.title).tooltip('fixTitle');
            self.relatedSelect.$el.addClass('has-value');

            self.ui.textInput.focus();
        });

        if (where && where.related_id) {
            this.ui.relatedInput.select2('readonly', true);
            this.disableRelated = true;
            this.ui.relatedInput.select2('data', { title: this.options.itemTitle });
            this.ui.setRelated.attr('title', 'Related to ' + this.options.itemTitle).tooltip('fixTitle');
        }

        this.tagsRegion.show(new Marionette.CollectionView({
            tagName: 'ul',
            itemView: TagItemView,
            collection: new Backbone.Collection(this.model.get('tags'))
        }));

        // Init tags select2
        _.defer(function() {
            self.tagsSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.tagsInput,
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: 'Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            self.funnelsSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.funnelsInput,
                id: 'id',
                text: 'name',
                url: '/funnels',
                search: true,

                options: {
                    placeholder: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    formatNoMatches: function() {
                        return `Type to add a ${TextManager.getText('ID_FUNNEL')}`;
                    },
                    tokenSeparators: [',']
                }
            });
        });

        this.updateEditIconVisibility();
    },
    updateEditIconVisibility: function() {
        // base on task description length aligned on inline edit icon is shown
        _.defer(function() {
            if (this.isClosed) {
                return;
            }
            var lineHeight = parseFloat(this.ui.taskText.css('line-height'));
            var numLines = Math.floor(this.ui.taskTextContainer.height() / lineHeight);

            if (numLines > 1) {
                this.ui.editInline.hide();
                this.ui.editAligned.show();
            }
            else {
                this.ui.editInline.show();
                this.ui.editAligned.hide();
            }
        }.bind(this));
    },
    removeDueDate: function() {
        if (this.disableSettingDueDate) {
            return false;
        }
        this.ui.dueDateInput.val('').removeClass('has-value');
        this.ui.setDueDate.attr('title', 'Set due date').tooltip('fixTitle').removeClass('value-set');
        this.dueDate = null;
    },
    removeAssignee: function() {
        if (this.disableAssignment) {
            return false;
        }
        this.assignedUserId = null;
        this.ui.setAssignee.attr('title', 'Assign a user').tooltip('fixTitle')
            .removeClass('assignee-is-user');
    },
    isDeleteKey: function(ev) {
        // If backspace/delete keypress on token focus, hide datepicker
        if (ev.keyCode && (ev.keyCode === 8 || ev.keyCode === 46)) {
            ev.preventDefault();
            ev.stopPropagation();
            return true;
        }
    },
    save: function() {
        var self = this,
            attrs = {
                subject: this.ui.subjectInput.val(),
                text: this.ui.textInput.val(),
                related_id: this.relatedId,
                related_type: this.relatedType
            };

        if (this.assignedUserId || this.assignedUserId === null) {
            attrs['assignee_id'] = this.assignedUserId
        }

        if (this.dueDate || this.dueDate === null) {
            attrs['due_date'] = new Date(this.dueDateTime);
        }

        attrs.tags = _.map(this.ui.tagsInput.select2('val'), function(tagId) {
            return {id: tagId};
        });

        attrs.funnels = _.map(this.ui.funnelsInput.select2('val'), function(funnelId) {
            return {id: funnelId};
        });

        this.model.save(attrs, {
            patch: true,
            success: function() {
                var dueDate = dateFormat.dueItemFormat(dateFormat.parseDate(self.model.get('due_date'))),
                    dueToday = dueDate === 'Today',
                    overDue = self.model.get('due_date') !== null && !dueToday && (dateFormat.parseDate(self.model.get('due_date')) < new Date()),
                    assignee = self.model.get('assignee');

                self.exitEditMode();
                self.ui.taskSubject.text(self.model.get('subject'));
                self.ui.taskText.text(self.model.get('text'));
                self.ui.taskDueDate.text(self.model.get('due_date') ? dueDate : '');
                self.ui.taskOwner.text(self.model.get('owner').name);
                self.ui.taskAssignee.text(assignee.name || 'Unassigned');
                self.ui.taskAssigneeInfo.toggleClass('hide', self.model.get('owner_id') === assignee.id);

                if (self.model.get('related_id')) {
                    self.ui.relatedName.text(self.model.get('related').name);
                    self.ui.target.removeClass('hide');
                }
                else {
                    self.ui.target.addClass('hide');
                }
                self.$el.find('.wrapper')
                    .toggleClass('unassigned', !self.model.get('assignee_id'))
                    .toggleClass('due-today', dueToday)
                    .toggleClass('overdue', overDue);

                self.tagsRegion.show(new Marionette.CollectionView({
                    tagName: 'ul',
                    itemView: TagItemView,
                    collection: new Backbone.Collection(self.model.get('tags'))
                }));
            }
        });
    },
    exitEditMode: function(ev) {
        var self = this;
        var fadeTime = 100;

        if (ev) {
            ev.preventDefault();
        }

        this.$el.find('.edit-task-container').fadeOut(fadeTime, function() {
            self.$el.find('.task-body').fadeIn(fadeTime);
            self.$el.removeClass('editing');
            self.$el.trigger('resize');
        });
        $(document).off('keydown.cancel-task-edit', this.hideEditCallback);

        _.delay(function() {
            self.updateEditIconVisibility();
        }, fadeTime * 2);
    },
    setTextAreaHeight: function() {
        var input = this.ui.textInput,
            innerHeight,
            maxHeight = 200;

        // Set minimum height to see if greater height is needed
        input.css('height', input.css('minHeight'));
        innerHeight = input.get(0).scrollHeight + 2;

        if (innerHeight !== input.outerHeight()) {
            input.css('height', Math.min(innerHeight, maxHeight));
        }
    }
});

NoTasksView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'list-placeholder',
    template: Handlebars.compile(noTasksTemplate),
    templateHelpers: function() {
        return {
            completed: this.options.completed
        };
    }
});

TaskList = Marionette.CompositeView.extend({
    template: Handlebars.compile(taskListTemplate),
    templateHelpers: function() {
        var previous = this.collection.start > 0;
        var next = this.collection.start + this.collection.length < this.collection.total;

        return {
            previous: previous,
            next: next,
            collectionStart: this.options.collection.start + 1,
            collectionEnd: this.options.collection.start + this.options.collection.length,
            collectionTotal: this.options.collection.total
        };
    },
    events: {
        'click li.previous a': 'showPreviousPage',
        'click li.next a': 'showNextPage'
    },
    ui: {
        start: '.start',
        stop: '.stop',
        total: '.total',
        pageCounter: '.page-counter',
        listCounter: '.list-counter'
    },
    itemView: TaskItem,
    itemViewContainer: '.task-list',
    emptyView: NoTasksView,
    itemViewOptions: function() {
        return this.options;
    },
    onBeforeRender: function() {
        // Override method for animating new items
        this.onBeforeItemAdded = function(){};
    },
    initialize: function() {
        this.collectionLength = this.options.collection.length;

        this.listenTo(this.options.collection, 'remove', function() {
            this.decrementCounters();
        }, this);
    },
    onRender: function() {
        // Animate new items on show
        this.onBeforeItemAdded = function(view) {
            view.on('show', function() {
                view.$el
                    .css('opacity', 0)
                    .slideDown(250)
                    .animate(
                        { opacity: 1 },
                        { queue: false, duration: 400 }
                    );
            });
        };
    },
    showPreviousPage: function(ev) {
        ev.preventDefault();

        this.options.collection.collectionPage--;
        this.options.collection.collectionPage = Math.max(this.options.collection.collectionPage, 1);
        this.options.fetchCollection();
    },
    showNextPage: function(ev) {
        ev.preventDefault();

        // go to next page only if there is no removed items
        if (this.options.collection.length === this.collectionLength) {
            this.options.collection.collectionPage++;
        }
        this.options.fetchCollection();
    },
    /**
     * Decrements list counters by 1.
     * Destroys DOM structure, view should be re-rendered not updated for other activities.
     */
    decrementCounters: function() {
        var stopCount = parseInt(this.ui.stop.html(), 10),
            totalCount = parseInt(this.ui.total.html(), 10);

        if (totalCount > 1) {
            this.ui.total.html(totalCount - 1);
            if (stopCount > parseInt(this.ui.start.html(), 10)) {
                this.ui.stop.html(stopCount - 1);
            }
            else {
                this.ui.pageCounter.html(0);
            }
        }
        // display nothing if there is no items, to be same as if rendered without items
        else {
            this.ui.listCounter.html('');
        }
    }
});

/*
 * This view contains two lists of completed and uncompleted tasks and area for adding new tasks.
 */
TaskListContainer = Marionette.Layout.extend({
    className: 'task-list-container',
    template: Handlebars.compile(taskListContainerTemplate),
    templateHelpers: function() {
        return {
            assignee_is_user: this.model ? this.model.get('assignee_id') === app.user.id : false,
            name: this.model ? this.model.get('name') || 'Unassigned' : ''
        };
    },
    regions: {
        groupList: '.group-list',
        uncompletedList: '.uncompleted-list',
        completedList: '.completed-list'
    },
    events: {
        'click .new-task': 'showAddTaskPopover',
        'click .show-completed': function() {
            if (this.$el.find('.expanded').length) {
                this.completedList.close();
                this.$el.find('.done-tasks-header').removeClass('expanded');
                this.$el.find('.toggle-done-tasks').removeClass('icon-visible').addClass('icon-hidden');
                this.trigger('completed:toggled');
                return;
            }

            this.completedTaskCollection = new TaskCollection();
            this.completedTaskCollection.collectionPage = 1;

            this.fetchCompletedCollection();

            this.listenTo(this.completedTaskCollection, 'change:completed remove', function() {
                if (this.options.taskCollection) {
                    this.taskCollection.collectionPage = 1;
                    this.fetchCollection();
                }
            }, this);
        }
    },
    showAddTaskPopover: function() {
        var model,
            where = this.options.filterModel.get('where');

        if (where) {
            model = new TaskModel({
                related: {
                    id: where.related_id,
                    related_type: where.related_type,
                    name: this.options.itemTitle
                }
            });
        }
        vent.trigger('quick:add:task', model);
    },
    initialize: function() {
        var self = this;

        this.listenTo(this, 'new-task', function() {
            this.showAddTaskPopover();
        });

        if (this.options.taskCollection) {
            this.taskCollection = this.options.taskCollection;
        }
        else {
            this.taskCollection = new TaskCollection(this.model.get('list'));
        }

        this.listenTo(this.taskCollection, 'change:completed remove add', function() {
            this.updateTaskCount();
            if (this.completedTaskCollection) {
                this.completedTaskCollection.collectionPage = 1;
                this.fetchCompletedCollection();
            }
        }, this);

        this.listenTo(vent, 'task:updated', function() {
            self.addTaskToCollection();
        });
    },
    onRender: function() {
        if (this.options.taskCollection) {
            this.fetchCollection();
        }
        else {
            this.showTaskList();
        }
    },
    updateTaskCount: function() {
        var self = this;
        get_task_count(_.extend({}, this.options.filterModel.get('where'), { assigned_to_me: true }), function(count){
            self.trigger('task:count:change', count);
        });
    },
    addTaskToCollection: function() {
        if (this.options.taskCollection) {
            this.fetchCollection();
        }
    },
    fetchCollection() {
        var self = this,
            orderBy = this.options.filterModel.get('order_by') || { attribute: 'due_date', order: 'asc' },
            sortOn = [{ attribute: orderBy.attribute, order: orderBy.order }];

        this.uncompletedList.show(new LoadingView());

        this.taskCollection.fetch({
            sortOn: sortOn,
            extraArgs: whereToExtraArgs(this.options.filterModel.get('where')),
            page: this.taskCollection.collectionPage || 0,
            success: function () {
                self.showTaskList();
            }
        });
        this.updateTaskCount();
    },
    showTaskList: function() {
        if (this.isClosed) {
            return;
        }

        var where = this.options.filterModel.get('where'),
            taskList = new TaskList({
                collection: this.taskCollection,
                filterModel: this.options.filterModel,
                itemTitle: this.options.itemTitle,
                entityModel: this.options.entityModel,
                useNarrowView: this.options.useNarrowView,
                fetchCollection: this.options.taskCollection && this.fetchCollection.bind(this)
            });

        this.uncompletedList.show(taskList);
        this.listenTo(taskList, 'itemview:show:related', function(task, model, type) {
            this.trigger('show:related', model, type);
        });

        // when this view is itemView
        if (this.model) {
            if (this.model.get('assignee_id') || this.model.get('assignee_id') === null) {
                this.$el.addClass('assigned-task-list');
            }
        }

        // limit task attribute selection
        if (where) {
            if ('assigned_to_me' in where) {
                this.$el.addClass('my-task-list');
            }
        }

        this.$el.find('[data-toggle=tooltip]').tooltip();
    },
    fetchCompletedCollection: function() {
        var self = this,
            extraArgs = whereToExtraArgs(this.options.filterModel.get('where'));

        extraArgs.push('completed');

        // when this view is itemView
        if (this.model) {
            if (this.model.get('assignee_id')) {
                extraArgs.push({ attribute: 'assignee_id', value: this.model.get('assignee_id') })
            }
            if (this.model.get('due_date')) {
                extraArgs.push({ attribute: 'due_date', value: this.model.get('due_date') })
            }
        }

        this.completedList.show(new LoadingView());

        this.completedTaskCollection.fetch({
            extraArgs: extraArgs,
            page: this.completedTaskCollection.collectionPage || 0,
            success: function() {
                //self.completedTaskCollection.filter()
                self.completedList.show(new TaskList({
                    collection: self.completedTaskCollection,
                    filterModel: self.options.filterModel,
                    itemTitle: self.options.itemTitle,
                    useNarrowView: self.options.useNarrowView,
                    fetchCollection: self.fetchCompletedCollection.bind(self),
                    completed: true
                }));
                self.$el.find('.done-tasks-header').addClass('expanded');
                self.$el.find('.toggle-done-tasks').addClass('icon-visible').removeClass('icon-hidden');
                self.trigger('completed:toggled');
            }
        });
    },
    fetchAll: function () {
        this.fetchCollection();

        if (this.$el.find('.expanded').length) {
            this.fetchCompletedCollection();
        }
    }
});

/*
 * When single list shown, different header is used. One in TaskListContainer is hidden with CSS.
 * This container supports loading of collection data as well.
 */
SingleTaskListContainer = Marionette.Layout.extend({
    className: 'single-list-container at-top',
    template: Handlebars.compile(singleListTemplate),
    templateHelpers: function() {
        return {
            header: this.options.filterModel.get('name'),
            includeRelated: this.whereFilter ? this.whereFilter.include_related_tasks : false,
            entityType: this.entityType,
            displayFilters: this.displayFilters
        };
    },
    regions: {
        container: '.list-container-region'
    },
    ui: {
        header: '.tasks-view-pane-header',
        scroll: '.content-container:first > .content',
        contextButtons: '.context-button',
        container: '.content-container'
    },
    events: {
        'click .new-task': function() {
            this.taskListContainer.trigger('new-task');
        },
        'click .context-button': function(ev) {
            ev.preventDefault();

            var target = $(ev.currentTarget);

            if (target.hasClass('active')) {
                return;
            }

            this.ui.contextButtons.removeClass('active');
            target.addClass('active');

            this.whereFilter.include_related_tasks = !target.hasClass('single-context');
            this.taskListContainer.fetchCollection();

            app.user.updatePreference('tasks_filter', {
                include_related_tasks: this.whereFilter.include_related_tasks
            });
        },
        'resize': 'scrollbar'
    },
    initialize: function(options) {
        var processIncludeRelatedTasks = true;

        switch(options.entityType) {
            case 'individual':
                this.entityType = TextManager.parseText('${ID_INDIVIDUAL, capitalize}');
                processIncludeRelatedTasks = !AppConfig.getValue('task_filter.individuals.force_record_view', false);
                break;

            case 'organization':
                this.entityType = TextManager.parseText('${ID_ORGANIZATION, capitalize}');
                processIncludeRelatedTasks = !AppConfig.getValue('task_filter.organizations.force_record_view', false);
                break;

            case 'opportunity':
                this.entityType = TextManager.parseText('${ID_DEAL, capitalize}');
                processIncludeRelatedTasks = !AppConfig.getValue('task_filter.opportunities.force_record_view', false);
                break;
        }

        this.displayFilters = options.displayFilters;

        if (options.displayFilters) {
            var filter = app.user.get('preferences')['tasks_filter'];

            this.whereFilter = options.filterModel.get('where');

            if (processIncludeRelatedTasks) {
                this.whereFilter.include_related_tasks = filter ? filter.include_related_tasks : true;
            }
        }
    },
    onRender: function() {
        var self = this;

        this.taskCollection = new TaskCollection();
        this.taskCollection.collectionPage = 1;
        this.options.defaultAssigneeSelf = true;
        this.taskListContainer = new TaskListContainer(
            _.extend(this.options, { taskCollection: this.taskCollection })
        );
        this.container.show(self.taskListContainer);
        this.listenTo(self.taskListContainer, 'show:related', function(model, type) {
            self.trigger('show:related', model, type);
        });
        this.listenTo(self.taskListContainer, 'completed:toggled', function() {
            self.scrollbar();
        });
        this.listenTo(vent, 'task:updated', function() {
            self.scrollbar();
        });
        this.scrollbar();
        this.listenTo(self.taskListContainer, 'task:count:change', function(taskCount) {
            self.trigger('task:count:change', taskCount);
        });
        // initially all are not completed
        this.trigger(
            'task:count:change',
            self.taskCollection.where({ assignee_id: app.user.get('id') }).length
        );

        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        if (this.displayFilters) {
            this.ui.container.addClass('has-filters');
        }

        // Resize revenue planner on window resize
        this.resizeCallback = function () {
            self.scrollbar();
        };
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = this.$el.height() - this.ui.header.height(),
            innerHeight = this.ui.scroll.find('.content-inner:first').height(),
            height = Math.min(innerHeight, availableHeight);

        if (!this.displayFilters) {
            this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));
        }

        container.nanoScroller();
        this.scrollEvents();
    },
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

/*
 * When grouping is used in filters we show several lists.
 */
TaskGroupList = Marionette.CollectionView.extend({
    itemView: TaskListContainer,
    emptyView: NoTasksView,
    itemViewOptions: function() {
        return this.options;
    }
});

/*
 * This container adds grouping header and fetches data for all collections with single call.
 */
TaskGroupListContainer = Marionette.Layout.extend({
    className: 'grouped-task-lists-container at-top',
    template: Handlebars.compile(groupedTaskListsTemplate),
    templateHelpers: function() {
        return {
            header: this.options.filterModel.get('name')
        };
    },
    regions: {
        list: '.list-region'
    },
    ui: {
        header: '.tasks-view-pane-header',
        scroll: '.content-container:first > .content'
    },
    events: {
        'resize': 'scrollbar'
    },
    onRender: function() {
        var self = this,
            extraArgs = whereToExtraArgs((this.options.filterModel.get('where'))),
            groupBy = this.options.filterModel.get('group_by'),
            orderBy = this.options.filterModel.get('order_by'),
            sortOn = orderBy ? [{ attribute: orderBy.attribute, order: orderBy.order }] : undefined;

        extraArgs.push({ attribute: 'group_by', value: groupBy });

        this.taskGroupCollection = new TaskGroupCollection();
        this.taskGroupCollection.fetch({
            sortOn: sortOn,
            extraArgs: extraArgs,
            success: function () {
                if (!self.list) {
                    return;
                }
                self.taskGroupList = new TaskGroupList(_.extend(self.options, { collection: self.taskGroupCollection }));
                self.list.show(self.taskGroupList);
                self.listenTo(self.taskGroupList, 'itemview:show:related', function(taskList, model, type) {
                    self.trigger('show:related', model, type);
                });
                self.listenTo(self.taskGroupList, 'itemview:completed:toggled', function() {
                    self.scrollbar();
                });
                self.listenTo(vent, 'task:updated', function() {
                    self.scrollbar();
                });
                self.scrollbar();
            }
        });

        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        // Resize revenue planner on window resize
        this.resizeCallback = function () {
            self.scrollbar();
        };
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = this.$el.height() - this.ui.header.height(),
            innerHeight = this.ui.scroll.find('.content-inner:first').height(),
            height = Math.min(innerHeight, availableHeight);

        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    },
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

var GroupOverviewView = IODGroupListingView.prototype.OverviewView.extend({
    templateHelpers: function() {
        var groupProtected = (this.model && this.model.get('display_options') || {}).protected;

        return {
            id: this.parent.getElementType(),
            name: this.model.get('name'),
            disableLayoutSelection: false,
            hideCostSection: true,
            hideFilters: groupProtected,
            showProtectedOption: app.user.get('is_admin') || app.user.get('is_helper'),
            enableCalendarLayout: true,
            titleWidgetsClasses: 'right-aligned',
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    groupType: 'Task',
    initialize: function() {
        IODGroupListingView.prototype.OverviewView.prototype.initialize.apply(this, arguments);

        this.collection = new GroupElementsCollection(null, {elementType: 'tasks'});
        this.filter = new TaskFilterModel({id: this.filter_id});
        this.quickFilter = new FilterQuickOptionsTasks();

        var self = this;

        this.listenTo(this, 'show:related', function(model, type) {
            self.parent.trigger('show:related', model, type);
        });
        this.listenTo(this, 'filter:update', function(filter) {
            self.parent.trigger('filter:update', filter);
        });
    },
    createTableView: function() {
        var hasPermission = security.checkPermission('edit', this.model);
        var isSmart = this.model.isSmart();
        var selectedColumns = this.model.get('columns') || this.options.selectedColumns || getDefaultColumns('tasks');

        return new TableBodyContainerView({
            parent: this,
            tableModel: this.model,
            collection: this.collection,
            elementType: 'tasks',
            filter: this.filter,
            defaultSort: this.options.defaultSort,
            selectedColumns: selectedColumns,
            defaultColumns: selectedColumns,
            addItemSelectPlaceholder: 'Search for a task',
            addItemSelectText: 'text',
            addItemNoCheckPermissions: true,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: false,
                mergeButton: false,
                newButton: false,
                addButton: hasPermission && !isSmart,
                removeFromGroup: hasPermission && !isSmart
            }
        });
    },
    addItem: function(model) {
        var self = this;

        $.ajax({
            url: this.model.url() + '/items/' + model.get('id'),
            method: 'PUT',
            success: function() {
                self.fetchCollection(null, null, null, null, {byItemAdded: true});
            }
        });
    },
    getMenuOptions: function() {
        var hasEditPermission = security.checkPermission('edit', this.model);

        return {
            canEdit: hasEditPermission,
            hasPermissions: hasEditPermission && app.user.get('client').permission_type !== 'rba',
            canDelete: hasEditPermission,
            locked: this.model.get('locked'),
            canDownloadAsCSV: !AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption') || app.user.get('is_admin'),
            canShareGroup: hasEditPermission
        };
    },
    onSelectItem: function(model) {
        vent.trigger('quick:edit:task', model);
    }
});

var GroupTableView = IODGroupListingView.extend({
    OverviewView: GroupOverviewView,
    getElementType: function() {
        return 'tasks';
    },
    onStartSidebarCollapsing() {
        const calendar = this.overviewContainerRegion.currentView?.calendarRegion?.currentView;

        if (calendar) {
            calendar.component.onStartResizing();
        }
    },
    onEndSidebarCollapsing() {
        const calendar = this.overviewContainerRegion.currentView?.calendarRegion?.currentView;

        if (calendar) {
            calendar.component.onEndResizing();
        }
    }
});


/*
 * Main view used in task section.
 */
TasksView = Marionette.Layout.extend({
    tagName: 'article',
    id: 'tasks-section',
    template: Handlebars.compile(tasksTemplate),
    regions: {
        sidebarRegion: '#tasks-sidebar',
        mainRegion: '#tasks-view-pane'
    },
    events: {
        'click #tasks-sidebar .collapse': function(ev) {
            var self = this;
            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            if (this.mainRegion.currentView?.onStartSidebarCollapsing) {
                this.mainRegion.currentView.onStartSidebarCollapsing();

                _.delay(function() {
                    self.mainRegion.currentView.onEndSidebarCollapsing();
                }, 200);
            }

            this.sidebarRegion.$el.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');

                if (self.mainRegion.currentView?.onStartSidebarCollapsing) {
                    self.mainRegion.currentView.onStartSidebarCollapsing();

                    _.delay(function() {
                        self.mainRegion.currentView.onEndSidebarCollapsing();
                    }, 200);
                }
            });
        }
    },
    initialize: function(options) {
        this.filterId = options.filter_id;
    },
    onRender: function() {
        var groupId = this.options.group_id;

        if (!groupId) {
            var initialGroup = app.user.get('preferences').initial_task_group;

            if (initialGroup) {
                groupId = initialGroup.id;
            }
        }

        const qflView = new QuickFilterList({
            parent: this
        });

        this.sidebarRegion.show(qflView);

        if (groupId?.indexOf('ft:') === 0) {
            this.listenTo(qflView, 'groups:loaded', function(collection) {
                const group = collection.models.find(g => g.get('id') === groupId.substring(3));

                if (group) {
                    this.showTable(null, group);
                } else {
                    this.showTable('all');
                }

                this.$el.find('[data-toggle="tooltip"]').tooltip();
            });
        } else {
            this.showTable(groupId || 'all');
            this.$el.find('[data-toggle="tooltip"]').tooltip();
        }
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['tasks-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.sidebarRegion.$el?.width(sidebarWidth);
                self.mainRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    showTable: function(id, model) {
        switch (id) {
            case 'all':
                this.showAllTasks();
                break;

            case 'my':
                this.showMyTasks();
                break;

            case 'table':
                this.showTasksTable();
                break;

            case 'by_owner':
                this.showByOwner();
                break;

            case 'today':
                this.showToday();
                break;

            case 'this_week':
                this.showThisWeek();
                break;

            case 'assigned_by_me':
                this.showAssignedByMe();
                break;

            case 'new':
                this.showNewGroup();
                break;

            default:
                if (model && model.get('isFunnelTagGroup')) {
                    this.showFunnelTagGroup(model);
                } else if(model && model.get('isFunnelGroup')) {
                    this.showFunnelGroup(model);
                } else if (model && model.get('isRegionGroup')) {
                    this.showRegionGroup(model);
                } else if (id.indexOf('ft:') === -1) {
                    this.showGroup(new GroupModel({id: id}));
                } else {
                    this.showAllTasks();
                }
                break;
        }
    },
    showAssignedByMe: function() {
        var rules = {
            'rules': [
                [{'field': 'task_creator_id', 'operator':'equal', 'values':{'id': app.user.get('id'),'name': app.user.get('name')}}],
                [{'field': 'task_completed', 'not':true}]
            ]
        };

        this.showGroupAdHoc('assigned_by_me', rules);
    },
    showToday: function() {
        var rules = {
            'rules': [
                [{'field': 'task_due_date', 'operator': 'equal', 'values': dateFormat.ISODate(new Date())}],
                [{'field': 'task_completed', 'not':true}]
            ]
        };

        this.showGroupAdHoc('today', rules);
    },
    showThisWeek: function() {
        var taskDueDateRule = AppConfig.getValue('useThisWeekFilterDuringForTasks') ?
            [{'field': 'task_due_date', 'operator': 'during', 'values': 'week'}] :
            [{'field': 'task_due_date', 'operator': 'within_next', 'values':{ unit: 'w', value: 1 }}]
        var rules = {
            'rules':[
                taskDueDateRule,
                [{'field': 'task_completed', 'not': true}]
        ]};

        this.showGroupAdHoc('this_week', rules);

    },
    showByOwner: function() {
        var rules = {'rules': [[{'field': 'task_completed', 'not': true}]]};
        this.showGroupAdHoc('by_owner', rules, {direction: true, field: ['assignee.name']});
    },
    showTasksTable: function() {
        var rules = {
            'rules': [
                [{'field': 'task_assignee_id', 'operator': 'equal', 'values': {'id': app.user.get('id'), 'name': app.user.get('name')}}],
                [{'field': 'task_completed', 'not': true}]
            ]
        };

        this.showGroupAdHoc('table', rules, null, true);
    },
    showMyTasks: function() {
        var rules = {
            'rules': [
                [{'field': 'task_completed', 'not': true}],
                [{'field': 'task_assignee_id', 'operator': 'equal', 'values': {'id': app.user.get('id'), 'name': app.user.get('name')}}]
            ]
        };

        this.showGroupAdHoc('my', rules, {direction: true, field: ['due_date']}, true);
    },
    showAllTasks: function() {
        var rules = {'rules': [[{'field': 'task_completed', 'not':true}]]};
        this.showGroupAdHoc('all', rules, {direction: true, field: ['due_date']}, true);
    },
    showNewGroup: function() {
        this.selectedBeforeNewGroup = this.sidebarRegion.currentView.selected;

        var model = new GroupModel();
        this.showContent(new GroupTableView(_.defaults({
            model: model,
            parent: this
        }, { editing: true, preset_element_type: 'tasks' })));

        this.trigger('select', 'newGroup');
    },
    showFunnelTagGroup: function(model) {
        const rules = [[{
            field: 'task_tags',
            operator: 'equal',
            values: {
                id: model.get('id'),
                name: model.get('name')
            }
        }]];

        const filter = new TaskFilterModel();
        const self = this;

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function (data) {
                const options = {
                    name: model.get('name'),
                    elementType: 'tasks',
                    type: 'all',
                    filter_id: data.id,
                    selectedColumns: getDefaultColumns('tasks')
                };

                self.filterId = data.id;
                self.showContent(new TaskListingBaseView(options));
                self.trigger('select', model.get('id'));
                self.rememberGroup(`ft:${model.get('id')}`);
            }
        });
    },
    showFunnelGroup: function(model) {
        const rules = [[{
            field: 'task_funnels',
            operator: 'equal',
            values: {
                id: model.get('id'),
                name: model.get('name')
            }
        }]];

        const filter = new TaskFilterModel();
        const self = this;

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function (data) {
                const options = {
                    name: model.get('name'),
                    elementType: 'tasks',
                    type: 'all',
                    filter_id: data.id,
                    selectedColumns: getDefaultColumns('tasks')
                };

                self.filterId = data.id;
                self.showContent(new TaskListingBaseView(options));
                self.trigger('select', model.get('id'));
                self.rememberGroup(`ft:${model.get('id')}`);
            }
        });
    },
    showRegionGroup: function(model) {
        const rules = [[{
            field: 'task_regions',
            operator: 'equal',
            values: {
                id: model.get('id'),
                name: model.get('regionName')
            }
        }]];

        const filter = new TaskFilterModel();
        const self = this;

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function (data) {
                const options = {
                    name: model.get('name'),
                    elementType: 'tasks',
                    type: 'all',
                    filter_id: data.id,
                    selectedColumns: getDefaultColumns('tasks')
                };

                self.filterId = data.id;
                self.showContent(new TaskListingBaseView(options));
                self.trigger('select', model.get('id'));
                self.rememberGroup(`ft:${model.get('id')}`);
            }
        });
    },
    showGroupAdHoc: function(id, rules, defaultSort, persistChanges) {
        var options = {
            elementType: 'tasks',
            type: id
        };

        if (defaultSort) {
            options.defaultSort = defaultSort;
        }

        var preferences = app.user.get('preferences');
        var stateName = `${id}_state`;

        var storeTaskTableState = function(state) {
            preferences[stateName] = preferences[stateName] || {};

            if (state.sort) {
                preferences[stateName].sort = state.sort;
            }

            if (state.filterId) {
                preferences[stateName].filterId = state.filterId;
            } else if (preferences[stateName].filterId) {
                delete preferences[stateName].filterId;
            }

            if (state.columns) {
                preferences[stateName].columns = state.columns;
            }

            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
        }

        var self = this;

        var showGroup = function() {
            var view = new TaskListingBaseView(options);

            self.showContent(view);
            self.trigger('select', id);
            self.rememberGroup(id);

            if (persistChanges) {
                self.listenTo(view, 'sort:change', function(sortField, direction) {
                    storeTaskTableState({sort: {direction: direction === 'asc', field: sortField}});
                });

                self.listenTo(view, 'filter:update', function(filter) {
                    storeTaskTableState({filterId: filter && (filter.get('id'))});
                });

                self.listenTo(view, 'columns:update', function(columns) {
                    storeTaskTableState({columns: columns});
                });
            }
        }

        var state = preferences[stateName] || {};

        if (state.sort) {
            options.defaultSort = state.sort;
        }
        if (state.columns && state.columns.length) {
            options.selectedColumns = state.columns;
        } else {
            options.selectedColumns = getDefaultColumns('tasks');
        }

        var filterId = this.filterId || state.filterId;

        if (!filterId || filterId === 'empty') {  // === 'empty' it's to support old way to store the filters
            var filter = new TaskFilterModel();

            filter.save(rules, {
                alert: false,
                success: function (data) {
                    options.filter_id = data.id;
                    showGroup();
                }
            });
        } else {
            options.filter_id = filterId;
            showGroup();
        }
    },
    showGroup: function(model) {
        this.showContent(new GroupTableView({
            filter_id: this.filterId,
            model: model
        }));

        this.trigger('select', model.get('id'));
        this.rememberGroup(model.get('id'));
    },
    rememberGroup: function(id) {
        app.user.updatePreference('initial_task_group', {
            id: id
        });
    },
    showContent: function(view) {
        var self = this;

        this.listenTo(view, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            self.trigger('push-view:' + type + ':show', model);
        });

        this.listenTo(view, 'filter:update', function(filter) {
            self.filterId = filter ? filter.get('id') : null;
            vent.trigger('AppContent:contentChange');
            _.defer(function() {
                self.filterId = null;
            });
        });

        this.listenTo(view, 'group:delete', function() {
            this.showAllTasks(this.options);

            _.defer(function() {
                self.trigger('group:delete');
            });
        });

        this.listenTo(view, 'replace-view:group:show', function(model) {
            this.showGroup(model);
        });

        this.listenTo(view, 'edit:cancel', function(model) {
            if (model.isNew()) {
                if (this.selectedBeforeNewGroup && this.selectedBeforeNewGroup[1]) {
                    this.showGroup(new GroupModel({
                        type: this.selectedBeforeNewGroup[0],
                        id: this.selectedBeforeNewGroup[1]
                    }));
                }
                else {
                    this.showAllTasks();
                }
            }
        });

        this.listenTo(view, 'fetch:fail', function(response) {
            self.showAllTasks();

            if (response && response.status === 403) { // do not have permissions
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'You do not have permissions to view this group'
                }, self);
            }
        });

        _.defer(function() {
            if (this.isClosed) {
                return;
            }
            this.mainRegion.show(view);
            this.initSidebarAndContentWidth();
            vent.trigger('AppContent:contentChange');
            this.filterId = null;
        }.bind(this));
    },
    getUrl: function() {
        var url = ['tasks'];
        var view = this.mainRegion && this.mainRegion.currentView;

        if (view && !view.isClosed && view.getUrl) {
            var model = view.options.model;

            if (model) {
                if (view.options.editing && model.isNew()) {
                    url.push('groups/new');
                }
                else {
                    url.push('groups/' + model.get('id'));
                }
            }
            else {
                url.push('groups/' + view.options.type);
            }
        } else {
            url.push('groups/all');

        }
        return url.join('/');
    },
    getParams: function() {
        if (this.filterId) {
            return {
                filter_id: this.filterId
            };
        }

        return {};
    }
});

function get_task_count(where, callback) {
    $.ajax({
        type: 'GET',
        url: '/tasks?return_count' + whereToURLString(where),
        success: function (count) {
            callback(count);
        }
    });
}

export default {
    mainView: TasksView,
    SingleTaskListContainer: SingleTaskListContainer,
    get_task_count: get_task_count
};

import _ from 'underscore'

import linkify from 'js/utils/linkify'


var forceTargetBlankFlag;

var validTags = {
    '<a>': {
        build: function(tag) {
            // get attribute values, which wont have double quotes as per regexp
            var href = tag.match(/href="(.*?)"/); href = href && href[1];
            var title = tag.match(/title="(.*?)"/); title = title && title[1];
            var target = tag.match(/target="(.*?)"/); target = target && target[1];

            // check for missing allowed protocols - http, https
            if (href && !href.match(/^(http|https):\/\//)) {
                // ignore other protocols
                if (href.match(/:\/\//)) {
                    href = "";
                }
                // prepend protocol is one missing, this is required for security purposes as well
                else {
                    href = "http://" + href;
                }
            }

            if (forceTargetBlankFlag) {
                target = "_blank";
            }

            // only _blank is allowed
            if (target !== "_blank") {
                target = '';
            }

            href = href && (' href="' + href + '"') || '';
            title = title && (' title="' + title + '"') || '';
            target = target && (' target="' + target + '"') || '';

            return "<a" + href + title + target + ">";
        }
    },
    '<p>': {},
    '<em>': {},
    '<span>': {
        build: function(tag) {
            var style = tag.match(/style="(.*?)"/); style = style && style[1];

            if (style) {
                var textDecoration = style.match('text-decoration: ?underline;');
                textDecoration = textDecoration && textDecoration[0];
                var color = style.match('color: ?(\#[0-9a-fA-F]{6});'); color = color && color[1];
                var colorWord = style.match('color: ?([a-z]+);'); colorWord = colorWord && colorWord[1];

                color = color || colorWord;

                textDecoration = textDecoration ? ' text-decoration: underline;' : '';
                color = color && ' color: ' + color + ';';

                style = ' style="' + textDecoration + color + '"';
            }

            return "<span" + style + ">";
        }
    },
    '<strong>': {},
    '<ul>': {},
    '<ol>': {},
    '<li>': {},
    '<br>': {}
};

var validCloseTags = {
    '</a>': {},
    '</p>': {},
    '</em>': {},
    '</span>': {},
    '</strong>': {},
    '</ul>': {},
    '</ol>': {},
    '</li>': {}
};

var characters = {
    '<': '&lt;',
    '>': '&gt;'
};

/**
 * White list based HTML sanitizer written for recreating limited amount of tags from TinyMCE produced content. Will
 * remove all other non alphanumeric characters.
 * If linkifyText set to true will convert recognized URLs to URL tags according to linkify logic.
 *
 * @param text                  string      HTML content
 * @param linkifyText           boolean     whether to linkify URL parts
 * @param forceTargetBlankFlag  boolean     enforce link opening in new window
 * @param skipTags              boolean     don't include html tags in result
 * @returns {*|String}          parsed HTML content, secured for use in app
 */
export default {
    sanitize: function (text, linkifyText, forceTargetBlank, skipTags) {
        forceTargetBlankFlag = forceTargetBlank;
        // splits string in tags (<tag ...>) and characters, example: ['<a href="aaa">','a','b','</a>',' ']
        // [\s\S] matches any character including newlines
        var parts = text.match(/<.*?>|[\s\S]/g);
        var safe = [];

        _.each(parts, function(part) {
            // tag
            if (part[0] === '<' && part.length > 1) {
                if (skipTags) {
                    // replace tag with space, so paragraphs wouldn't join
                    safe.push(' ');
                }
                else {
                    // close tag
                    if (part[1] === '/') {
                        if (part in validCloseTags) {
                            safe.push(part);
                        }
                    }
                    // open tag
                    else {
                        var tag = part.match(/(<.*?)[\s,>]/)[1]; // get '<tag';
                        tag = tag + '>';
                        if (tag in validTags) {
                            if ('build' in validTags[tag]) {
                                safe.push(validTags[tag].build(part));
                            }
                            else {
                                safe.push(tag);
                            }
                        }
                    }
                }
            }
            // character
            else {
                if (part in characters) {
                    safe.push(characters[part])
                }
                else {
                    safe.push(part)
                }
            }
        });
        text =  safe.join('');

        if (linkifyText) {
            var res = [];
            // split into urls, part until url tag begins and everything else for end of string, example: ['<p>www.url.com</p> abc','<a href="...">...</a>', 'abc']
            parts = text.match(/(<a .*?\/a>)|.+?(?=<a )|(.+)/gi);
            _.each(parts, function(part) {
                if (part.substring(0,3) === '<a ') {
                    res.push(part);
                }
                else {
                    res.push(linkify(part, true));
                }
            });
            text = res.join('');
        }

        return text;
    },
    removeScripts: function (text) {
        var startTag = '<script';
        var endTag = '</script>';
        var startPos = text.indexOf(startTag);
        var endPos;

        while (startPos !== -1) {
            endPos = text.indexOf(endTag, startPos);

            if (endPos !== -1) {
                text = text.substring(0, startPos) + text.substring(endPos + endTag.length);
                startPos = text.indexOf(startTag);
            }
            else {
                break;
            }
        }

        return text;
    }
}

import _ from 'underscore'
import d3 from 'd3'
import $ from 'jquery'

import dateFormat from 'js/utils/date-format'
import D3WidgetsStyle from 'js/d3/widgets_style'


function iplookup() {
    var x = 0;
    var y = 0;
    var width = 300;
    var height = 300;
    var data = {};
    var minRadius = 30;
    var maxRadius = 70;
    var spaceBetweenTags = 15; // name - last seen - visits
    var spaceBetweenNameLines = 12;
    var gidx = 0;

    function chart(selection) {
        var svg = selection.select('svg');

        if (svg.empty()) {
            svg = selection.append('svg');
        }

        svg
            .attr('width', width)
            .attr('height', height);

        var nodes = svg.selectAll('.node')
            .data(data.nodes, function(d) {
                return d.idx;
            });

        nodes
            .enter()
                .append('g')
                    .attr('class', 'node')
                    .call(function() {
                        this.append('circle')
                            .attr('class', function(d) {
                                var today = new Date();
                                var months = (today.getFullYear() - d.last_seen.getFullYear()) * 12;
                                var c = 'node-circle ';
                                months -= d.last_seen.getMonth() + 1;
                                months += today.getMonth();
                                months = Math.max(months, 0);

                                if (months <= 1)
                                    return c + 'visitors-1-months';
                                else if (months <= 2)
                                    return c + 'visitors-2-months';
                                else if (months <= 3)
                                    return c + 'visitors-3-months';
                                else
                                    return c + 'visitors-4-months';
                            })
                            .attr('r', function(d) {return d.r;})
                            .attr('cx', function(d) {return d.ix;})
                            .attr('cy', function(d) {return d.iy;});

                        this.append('text')
                            .attr('class', 'node-name name-line-1')
                            .text(function(d) {
                                return d.nameList[0];
                            })
                            .attr('x', function(d) {return d.ix;})
                            .attr('y', function(d) {return d.topMargin + d.iy - spaceBetweenTags;})
                            .attr('dominant-baseline', 'mathematical')
                            .attr('alignment-baseline', 'mathematical');

                        this.append('text')
                            .attr('class', 'node-name name-line-2')
                            .text(function(d) {
                                if (d.nameList.length > 1) {
                                    return d.nameList[1];
                                }

                                return '';
                            })
                            .attr('x', function(d) {return d.ix;})
                            .attr('y', function(d) {return d.topMargin + d.iy - spaceBetweenTags - spaceBetweenNameLines;})
                            .attr('dominant-baseline', 'mathematical')
                            .attr('alignment-baseline', 'mathematical');

                        this.append('text')
                            .attr('class', 'node-name name-line-3')
                             .text(function(d) {
                                if (d.nameList.length > 2) {
                                    return d.nameList[2];
                                }

                                return '';
                            })
                            .attr('x', function(d) {return d.ix;})
                            .attr('y', function(d) {return d.topMargin + d.iy - spaceBetweenTags - (spaceBetweenNameLines * 2);})
                            .attr('dominant-baseline', 'mathematical')
                            .attr('alignment-baseline', 'mathematical');

                        this.append('text')
                            .attr('class', 'node-last-seen')
                            .text(function(d) {return dateFormat.entityInformationFormat(d.last_seen);})
                            .attr('x', function(d) {return d.ix;})
                            .attr('y', function(d) {return d.topMargin + d.iy;})
                            .attr('dominant-baseline', 'mathematical')
                            .attr('alignment-baseline', 'mathematical');

                        this.append('text')
                            .attr('class', 'node-visits')
                            .text(function(d) {return d.count;})
                            .attr('x', function(d) {return d.ix;})
                            .attr('y', function(d) {return d.topMargin + d.iy + spaceBetweenTags;})
                            .attr('dominant-baseline', 'mathematical')
                            .attr('alignment-baseline', 'mathematical');
                    });

            nodes
                .exit()
                    .remove();

        // ...
        var moveCircles = function() {
            var circles = svg.selectAll('.node-circle');

            circles
                .transition()
                    .duration(function(d) {return d.d;})
                    .attr('cx', function(d) {return d.fx;})
                    .attr('cy', function(d) {return d.fy;})
                    .attr('r', function(d) {return d.r;});
        };

        var moveElements = function(c, offsety) {
            var elements = svg.selectAll(c);
            var oy = offsety || 0;

            elements
                .transition()
                    .duration(function(d) {return d.d;})
                    .attr('x', function(d) {return d.fx;})
                    .attr('y', function(d) {return d.fy + oy + d.topMargin;});
        };

        moveCircles();
        moveElements('.name-line-1', -spaceBetweenTags);
        moveElements('.name-line-2', -spaceBetweenTags - spaceBetweenNameLines);
        moveElements('.name-line-3', -spaceBetweenTags - (spaceBetweenNameLines * 2));
        moveElements('.node-last-seen');
        moveElements('.node-visits', spaceBetweenTags);
    }

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        width = _;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }

        // get the biggest value
        var biggest = -1;

        for (var i = 0; i < _.length; ++i) {
            var d = _[i];

            if (d.count > biggest) {
                biggest = d.count;
            }
        }

        // add extra info to data
        data = {
            nodes: _,
            links: []
        };

        var wrapNodeName = function(name, radius) {
            var li = name.split(' ');
            var lo = [];
            var c = '';
            var maxChars = Math.floor(radius / 5);

            for (var i = 0; i < li.length; ++i) {
                var l = li[i];
                var p = c;
                c += l;

                if (i !== li.length - 1) {
                    c += ' ';
                }

                if (c.length >= maxChars) {
                    lo.unshift(p);
                    c = l;
                    p = '';

                    if (lo.length > 1) {
                        c += '...';
                        break;
                    }
                }
            }

            lo.unshift(c);

            return lo;
        };

        var val = Math.min(width, height);

        minRadius = val / 12;
        maxRadius = val / 8;

        var radiusDiff = (maxRadius - minRadius);

        if (data.nodes && data.nodes.length > 0) {
            for (var i = 0; i < data.nodes.length; ++i) {
                var d = data.nodes[i];

                d.r = minRadius + ((d.count / biggest) * radiusDiff);
                d.ix = Math.floor(Math.random() * width);
                d.iy = Math.floor(Math.random() * height);
                d.d = Math.floor(Math.random() * 4000) + 1000; // transition length
                d.nameList = wrapNodeName(d.name, d.r);
                d.idx = gidx++;
                // top margin depends of the number of name lines and radius
                d.topMargin = (spaceBetweenNameLines * (d.nameList.length - 1)) / 2;
            }

            // final positions for bubbles (base on the number of visits)
            data.nodes.sort(function(a,b) { return b.count - a.count; });
            var margin = 5;
            var sx = margin;
            var sy = data.nodes[0].r + margin;
            var ry = data.nodes[0].r;

            for (var i = 0; i < data.nodes.length; ++i) {
                var d = data.nodes[i];

                sx += d.r;

                if ((sx + d.r) >= (width - margin)) {
                    sx = d.r + margin;
                    sy += ry + margin + d.r;
                    ry = d.r;
                }

                d.fx = sx;
                d.fy = sy;

                sx += d.r + margin;
            }
        }

        return chart;
    };

    return chart;
}

export default iplookup;

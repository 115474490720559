import React from 'react';
import classnames from 'classnames';
import _ from 'underscore';
import * as libPhoneNumber from 'libphonenumber-js';
import app from 'js/app'

import {NewSelect, TagSelect, PlainSelectItem, NewPlainSelectItem} from 'js/react_views/widgets/select';
import UsersCollection from 'js/collections/users.js';
import TagsCollection from 'js/collections/tags';
import PeriodsCollection from 'js/collections/periods';
import Currency from 'js/utils/currency';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import dateFormat from 'js/utils/date-format'
import Utilities from 'js/utils/utilities'
import LeadSourcesCollection from 'js/collections/lead_sources.js';
import GroupsCollection from 'js/collections/groups';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import MessageBox from 'js/views/message_box';

import style from './common.css';

const HandleNumberInputValue = function(value) {
    let newValue = null;

    if (value === '') {
        newValue = '';
    } else if (value === '-') {
        newValue = '-';
    } else if (value.indexOf('.') === value.length - 1) {
        if (!_.isNaN(parseFloat(value))) {
            newValue = value; // because parseFloat remove the point, i.e. it converts 1. to 1
        }
    } else {
        newValue = parseFloat(value);

        if (_.isNaN(newValue)) {
            newValue = '';
        }
    }

    return newValue;
}

class NotEditableField extends React.Component {
    constructor(props) {
        super(props);
        this.isNotEditableField = true;
    }

    hasChanged() {
        return false;
    }

    getValue() {
        return this.props.value;
    }

    render() {
        return null;
    }
}

class PhotoField extends React.Component {
    constructor(props) {
        super(props);
    }

    hasChanged() {
        return this.textField.hasChanged()
    }

    getValue() {
        return this.textField.getValue()
    }

    render() {
        const backgroundImage = this.props.value ? ("url('" + this.props.value + "')") : 'none';

        return (
            <div className={style.profileImageContainer}>
                <div
                    className={style.profileImage}
                    style={{backgroundImage: backgroundImage}}
                />
                <TextField
                    ref={(el) => this.textField = el}
                    extraClasses={style.photoField}
                    label={this.props.label}
                    value={this.props.value || ''}
                    placeholder={this.props.placeholder || ''}
                />
            </div>
        );
    }
}

class SourceDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        this.sourceSystemType = null;
        if (this.props.value && this.props.value.system_type) {
            this.sourceSystemType = this.props.value.system_type;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    focus() {
        this.label.scrollIntoView();
    }

    getValue() {
        if (this.valueId) {
            return {
                id: this.valueId
            };
        }

        return null;
    }

    render() {
        const self = this;

        const getSources = function(callback) {
            let leadSources = new LeadSourcesCollection();
            leadSources.fetch({
                sortOn: [{
                    attribute: 'name',
                    order: 'asc'
                }],
                success: function(data) {
                    const sources = _.map(data.models, function(item) {
                        return {
                            id: item.get('id'),
                            name: item.get('name')
                        };
                    });

                    // set source id if the value is the source_type
                    let activeSourceId = null;

                    if (self.sourceSystemType) {
                        const activeSource = _.find(data.models, function(item) {
                            return item.get('system_type') === self.sourceSystemType;
                        });

                        if (activeSource) {
                            activeSourceId = activeSource.get('id');
                            self.valueId = activeSourceId;
                        }
                    }

                    self.sourceSystemType = null;
                    callback(sources, activeSourceId);
                }
            });
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
            } else {
                this.valueId = null;
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                >
                    {TextManager.getText('ID_SOURCE')}
                </span>
                <NewSelect
                    placeholder="Select a lead source"
                    width="245"
                    data={getSources}
                    value={this.props.value}
                    editViewMod={true}
                    options={{allowClear: this.props.allowClear}}
                    onSelect={onSelect.bind(this)}
                    disabled={this.props.disabled}
                    text="name"
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ToggleFavoriteBtn extends React.Component {
    render() {
        const noFavStarClasses = ['icon-star', (!this.props.is_favorite ? '' : style.hidden), style.noFavStar].join(' ');
        const favStarClasses = ['icon-star2', (this.props.is_favorite ? '' : style.hidden), style.favStar].join(' ');

        return (
            <li
                className={style.toggleFavorite}
                onClick={this.props.onToggleFavorite}
            >
                <i className={noFavStarClasses} data-toggle="tooltip" title="Add To Favorites"/>
                <i className={favStarClasses} data-toggle="tooltip" title="Remove From Favorites"/>
            </li>
        );
    }
}

class GenericResultItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };
    }

    render() {
        return (
            <div
                className={`${style.genericResultsListItem} ${this.state.selected ? style.genericResultsListItemSelected : ''}`}
                onClick={(ev) => {ev.stopPropagation(); this.props.onClick()}}
                onMouseEnter={this.props.onMouseEnter}
            >
                <span title={this.props.data.name}>{this.props.data.name}</span>
            </div>
        );
    }

    select(selected) {
        this.setState({
            selected: selected
        });
    }
}

class GenericResultsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        };

        this.selectedItemIdx = -1;
        this.itemViews = [];
    }

    setItems(items) {
        this.selectItemByIdx(-1);

        this.setState({
            items: _.clone(items)
        });

        if (items.length > 0) {
            const self = this;

            _.defer(function() {
                self.selectItemByIdx(0);
            });
        }
    }

    selectItemByIdx(idx) {
        if (this.selectedItemIdx !== -1) {
            this.itemViews[this.selectedItemIdx].select(false);
        }

        this.selectedItemIdx = idx;

        if (this.selectedItemIdx !== -1) {
            this.itemViews[this.selectedItemIdx].select(true);
        }
    }

    moveToNextItem() {
        if (this.state.items.length > 0) {
            this.selectItemByIdx((this.selectedItemIdx + 1) % this.state.items.length);
        }
    }

    moveToPrevItem() {
        if (this.state.items.length > 0) {
            let prevItem = 0;

            if (this.selectedItemIdx !== -1) {
                prevItem = this.selectedItemIdx - 1;

                if (prevItem < 0) {
                    prevItem = this.state.items.length - 1;
                }
            }

            this.selectItemByIdx(prevItem);
        }
    }

    selectActiveItem() {
        if (this.selectedItemIdx !== -1) {
            this.props.onItemSelected(this.state.items[this.selectedItemIdx]);
            this.props.onClose();
        }
    }

    componentDidMount() {
        this.relocateResultsList();
    }

    componentDidUpdate() {
        this.relocateResultsList();
    }

    relocateResultsList() {
        const elementBB = $(this.props.element)[0].getBoundingClientRect();
        const windowHeight = $(window).height();
        const resultsHeight = $(this.el).height();

        if (this.resultsListOnTop || (elementBB.top + elementBB.height + resultsHeight > windowHeight)) {
            this.resultsListOnTop = true;
            $(this.el).css('top', (elementBB.top - resultsHeight - 17) + 'px');
        } else {
            $(this.el).css('top', (elementBB.top + elementBB.height) + 'px');
        }
    }

    render() {
        const elementBB = $(this.props.element)[0].getBoundingClientRect();
        const width = this.props.width || 400;
        const maxHeight = this.props.maxHeight || 250;

        this.itemViews = [];

        return (
            <div
                className={style.genericResultsListContainer}
                onClick={(ev) => {ev.stopPropagation(); this.props.onClose()}}
            >
                <div className={style.genericResultsListBackdrop}
                    onWheel={(ev) => {ev.preventDefault()}}
                />
                <div
                    className={style.genericResultsListContent}
                    ref={(el) => this.el = el}
                    style={{ width: width + 'px', left: elementBB.left + 'px', maxHeight: maxHeight + 'px' }}
                >
                    {_.map(this.state.items, (item, index) =>
                        <GenericResultItem
                            key={`generic-result-item-${index}`}
                            ref={(el) => { if (el) {this.itemViews.push(el)}}}
                            data={item}
                            onClick={() => this.props.onItemSelected(item)}
                            onMouseEnter={() => {this.selectItemByIdx(index)}}
                        />
                    )}
                </div>
            </div>
        );
    }
}

class PostcodeField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = props.value;
        this.state = {
            value: props.value,
            focused: false,
            showResults: false
        };

        if (this.props.forceValueChange) {
            this.initialValue = null;
        }

        this.throttleFetchSuggestions = _.throttle(this.fetchSuggestions, 300);
    }

    fetchSuggestions(term) {
        const self = this;

        $.ajax({
            type: 'GET',
            url: `/postcode/uk/${encodeURIComponent(term)}/suggest`,
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                self.setState({showResults: data.length > 0});

                if (self.resultsList) {
                    _.defer(function() {
                        self.resultsList.setItems(data.map(i => { return {id: i.id, name: i.address} }));
                    });
                }
            }
        });
    }

    hasChanged() {
        return this.getValue() !== this.initialValue;
    }

    getValue() {
        if (_.isString(this.state.value)) {
            return this.state.value.trim();
        }

        return this.state.value;
    }

    setValue(value) {
        this.setState({
            value: value
        });
    }

    handleInputChange(ev) {
        const value = ev.target.value;
        let newState = {
            value: value
        };

        if (value.length > 2) {
            this.throttleFetchSuggestions(value);
        } else {
            newState.showResults = false;
        }

        this.setState(newState);
    }

    focus() {
        this.input.focus();
    }

    onItemSelected(item) {
        const self = this;

        $.ajax({
            type: 'GET',
            url: `/postcode/uk/${encodeURIComponent(item.id)}`,
            contentType: 'application/json',
            dataType: 'json',
            success: function (data) {
                let mapping = {};

                for (const k in self.props.mapping) {
                    mapping[k] = data[self.props.mapping[k]];
                }

                self.setState({
                    value: data.postcode,
                    showResults: false
                });

                if (self.props.onValuePopulate) {
                    self.props.onValuePopulate(self.props.id, mapping)
                }

                self.focus();
            }
        });
    }

    onResultsListClosed() {
        this.setState({
            showResults: false
        });
    }

    handleKeyDown(event) {
        if (!this.state.showResults) {
            return;
        }

        switch(event.key) {
            case 'ArrowUp':
                event.preventDefault();
                this.resultsList.moveToPrevItem();
                break;

            case 'ArrowDown':
                event.preventDefault();
                this.resultsList.moveToNextItem();
                break;

            case 'Enter':
            case 'Tab':
                event.preventDefault();
                this.resultsList.selectActiveItem();
                break;

            case 'Escape':
                event.preventDefault();
                this.onResultsListClosed();
                break;
        }
    }

    render() {
        const extraClasses = this.props.extraClasses || "";
        const inputExtraClasses = this.props.inputExtraClasses || "";

        const classes = classnames({
            [style.inputField]: true,
            [extraClasses]: true,
        });
        const inputClasses = classnames({
            [style.inputFieldText]: true,
            [style.invalid]: this.props.error,
            [inputExtraClasses]: true
        });
        const label = this.props.label || '';
        const placeholder = this.props.placeholder || '';

        return (
            <div className={classes}>
                <span
                    className={style.inputFieldLabel}
                    title={label}
                >
                    {label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={inputClasses}
                    value={this.state.value}
                    placeholder={placeholder}
                    onChange={this.handleInputChange.bind(this)}
                    onFocus={() => this.setState({focused: true})}
                    onBlur={() => {
                        if (!this.state.showResults) {
                            this.setState({focused: false})
                        }
                    }}
                    onKeyDown={this.handleKeyDown.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
                {this.state.focused && this.state.showResults && <GenericResultsList
                    ref={(el) => this.resultsList = el}
                    element={this.input}
                    onItemSelected={this.onItemSelected.bind(this)}
                    onClose={this.onResultsListClosed.bind(this)}
                />}
            </div>
        );
    }
}

class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = props.value || '';
        this.state = { value: this.initialValue };

        if (this.props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.getValue() !== this.initialValue;
    }

    getValue() {
        if (this.props.isParagraph) {
            return this.state.value;
        }

        if (_.isString(this.state.value)) {
            return this.state.value.trim();
        }

        return this.state.value;
    }

    setValue(value) {
        this.setState({
            value: value
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(this.props.id, value);
        }
    }

    handleInputChange(ev) {
        if(this.props.capitalizeValue){
            this.handleCapitalizeField(ev.target.value)
        } else{
            this.setValue(ev.target.value);
        }

    }

    handleCapitalizeField(text){
        if (this.state.value === '' || (this.state.value[this.state.value.length - 1] === ' ' && text.length > this.state.value.length)) {
            var splitStr = text.split(' ');
            var lastIndex = splitStr.length - 1

            splitStr[lastIndex] = splitStr[lastIndex].charAt(0).toUpperCase() + splitStr[lastIndex].substring(1);

            this.setValue(splitStr.join(' '));
        } else {
            this.setValue(text);
        }
    }

    focus(andSelect) {
        this.input.focus();

        if (andSelect) {
            this.input.select();
        }
    }

    render() {
        const extraClasses = this.props.extraClasses || "";
        const inputExtraClasses = this.props.inputExtraClasses || "";

        const classes = classnames({
            [style.inputField]: true,
            [extraClasses]: true,
        });
        const inputClasses = classnames({
            [style.inputFieldText]: !this.props.isParagraph,
            [style.inputFieldParagraph]: this.props.isParagraph,
            [style.invalid]: this.props.error,
            [style.fullInputBox]: this.props.fullInputBox,
            [inputExtraClasses]: true
        });
        const label = this.props.label || '';
        const placeholder = this.props.placeholder || '';
        const characterLimit = this.props.characterLimit || null;

        return (
            <div className={classes}>
                <span
                    className={style.inputFieldLabel}
                    title={label}
                    style={this.labelStyle}
                >
                    {label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                {this.props.isParagraph ? (
                    <textarea
                        ref={(el) => this.input = el}
                        placeholder={placeholder}
                        name="value"
                        value={this.state.value}
                        className={inputClasses}
                        onChange={this.handleInputChange.bind(this)}
                        onFocus={this.props.onFocus}
                    />
                ) : (
                    <input
                        ref={(el) => this.input = el}
                        type="text"
                        className={inputClasses}
                        value={this.state.value}
                        placeholder={placeholder}
                        onChange={this.handleInputChange.bind(this)}
                        maxLength={characterLimit}
                        onFocus={this.props.onFocus}
                    />
                )}
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class DateField extends React.Component {
    constructor(props) {
        super(props);

        let value = props.value || '';

        if (value !== '') {
            if (_.isDate(value)) {
                value = this.getDateString(value);
            }
        }

        this.allowEmptyValue = ('allowEmptyValue' in this.props) ? this.props.allowEmptyValue : true;
        this.initialValue = value;
        this.prevValue = value;
        this.prevRenderValue = (this.props.dateFormatter && value) ? this.props.dateFormatter(dateFormat.createDateIgnoringTZ(value)) : value;
        this.state = {
            value: value,
            renderValue: this.prevRenderValue
        }

        if (props.forceValueChange) {
            this.initialValue = '';
            this.preValue = '';
            this.prevRenderValue = '';
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValue !== this.getValue();
    }

    getValue() {
        return (this.state.value || '').trim();
    }

    getDateString(date) {
        return dateFormat.ISODate(date);
    }

    setValue(newValue) {
        let value = '';
        let renderValue = '';

        if (newValue !== '') {
            const val = dateFormat.createDateIgnoringTZ(newValue);

            if (Utilities.dateIsValid(val)) {
                value = this.getDateString(val);
                renderValue = (this.props.dateFormatter && value) ? this.props.dateFormatter(dateFormat.createDateIgnoringTZ(value)) : value;
            } else {
                value = this.prevValue;
                renderValue = this.prevRenderValue;
            }
        } else if (!this.allowEmptyValue) {
            value = this.prevValue;
            renderValue = (this.props.dateFormatter && value) ? this.props.dateFormatter(dateFormat.createDateIgnoringTZ(value)) : value;
        }

        this.prevValue = value;
        this.prevRenderValue = renderValue;

        if (this.props.onValueChange){
            this.props.onValueChange(value);
        }

        this.setState({
            value: value,
            renderValue: renderValue
        });
    }

    handleInputChange(ev) {
        this.setState({
            renderValue: ev.target.value
        });
    }

    handleInputBlur() {
        if (this.prevRenderValue !== this.state.renderValue) {
            this.setValue(this.state.renderValue);
        }
    }

    handleInputFocus(ev) {
        const self = this;

        $(ev.currentTarget).datepicker({
            defaultDate: this.state.value,
            numberOfMonths: 2,
            dateFormat: 'yy-mm-dd',
            timezone: "+0000",
            showButtonPanel: true,
            yearRange: '-99:+10',
            changeYear: true,
            onSelect: function(date) {
                self.setValue(date);
            }
        });
    }

    render() {
        const date = dateFormat.parseDate(this.state.renderValue);
        let dateFormatted = '';

        if (_.isNaN(date.getTime())) { // it's not a valid date, probably it's a merge tag
            dateFormatted = this.state.renderValue;
        } else {
            dateFormatted = AppConfig.getClientPreferenceValue('useDDMMYYYYDateFormat') ? dateFormat.formatDDMMYYYYDate(this.state.renderValue) : this.state.renderValue;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label || ''}
                    style={this.labelStyle}
                >
                    {this.props.label || ''}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <input
                    type="text"
                    className={`
                        ${style.inputFieldText}
                        ${this.props.fullInputBox ? style.fullInputBox : ''}
                    `}
                    value={dateFormatted}
                    placeholder={this.props.placeholder || (AppConfig.getClientPreferenceValue('useDDMMYYYYDateFormat') ? "dd/mm/yyyy" : "yyyy-mm-dd")}
                    onChange={this.handleInputChange.bind(this)}
                    onFocus={this.handleInputFocus.bind(this)}
                    onBlur={this.handleInputBlur.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CurrencyField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
            } else {
                this.valueId = null;
            }
        };

        const currencies = Currency.getUsedCurrenciesToSelect2Array(this.props.onlyCurrenciesWithConversion);
        let value;

        if (this.props.value) {
            value = _.findWhere(currencies, {id: this.props.value});
        }

        if (!value && this.props.forceValue) {
            value = {
                id: this.props.value,
                text: this.props.value
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder="Select a currency"
                    width="260"
                    value={value}
                    data={currencies}
                    editViewMod={true}
                    options={{allowClear: this.props.allowClear}}
                    text="text"
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class DropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = -1; // we are not using null because null is a valid value for the dropdown (i.e. removing existing value)
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    setValue(valueId) {
        this.select.setValue(valueId);
    }

    setData(data, activeItemId) {
        this.select.setData(data, activeItemId);
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.select = el}
                    placeholder={this.props.placeholder || 'Select a value'}
                    width={this.props.dropdownWidth || 260}
                    value={this.props.value}
                    data={this.props.options}
                    editViewMod={!this.fullInputBox}
                    disabled={this.props.disabled}
                    options={{
                        allowClear: this.props.allowClear,
                        minimumInputLength: this.props.minimumInputLength || 0
                    }}
                    text={this.props.text || "value"}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class TriStateCheckboxField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = null;
        this.state = {
            value: this.initialValue
        };
    }

    componentDidMount() {
        this.input.indeterminate = true;
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValue !== this.state.value;
    }

    getValue() {
        return this.state.value;
    }

    handleInputChange(ev) {
        this.setState({
            value: ev.target.checked
        });
    }

    render() {
        const label = this.props.label || '';
        const id = this.props.id || label;
        let labelStyle = {};

        if (this.props.labelWidth) {
            labelStyle.width = this.props.labelWidth;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={label}
                    style={labelStyle}
                >
                    {label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <label className={style.inputFieldTriStateCheckbox}>
                    <input
                        ref={(el) => this.input = el}
                        type='checkbox'
                        onChange={this.handleInputChange.bind(this)}
                    />
                    <span className={style.triStateCheckboxSlider}/>
                </label>
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class OptionField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = this.props.value || null;
        this.state = {
            value: this.initialValue
        };

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValue?.id !== this.state.value?.id;
    }

    getValue() {
        return this.state.value?.id;
    }

    handleOptionClick(option) {
        this.setState({
            value: option
        });
    }

    render() {
        const label = this.props.label || '';
        const id = this.props.id || label;

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={label}
                    style={this.labelStyle}
                >
                    {label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <div className={style.inputFieldOption}>
                    {this.props.options.map(option => {
                        return (
                            <div
                                key={`opt_${option.id}`}
                                className={`
                                    ${style.ifOption}
                                    ${this.state.value?.id === option.id ? style.oActive : ''}
                                `}
                                onClick={() => this.handleOptionClick.bind(this)(option)}
                            >
                                {option.title}
                            </div>
                        );
                    })}
                </div>
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CheckboxField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = this.props.value || false;
        this.state = {
            value: this.initialValue
        };

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValue !== this.state.value;
    }

    getValue() {
        return this.state.value;
    }

    handleInputChange(ev) {
        const self = this;
        const label = this.props.label || '';
        const showModalConfirmation = AppConfig.getValue('deals.edit.show_custom_field_modal_confirmation', false);
        const modalCfName = AppConfig.getValue('deals.edit.modal_custom_field_name', '');

        if (ev.target.checked && showModalConfirmation && modalCfName.toLowerCase() == label.toLowerCase()) {
            MessageBox.showYesNo(
                {
                    icon: 'icon-warning',
                    message: 'I confirm that FCP referral is not required and all due diligence has been carried out in line with Connells Group Land/New Homes AML procedures',
                    accept_button_text: 'Continue',
                    cancel_button_text: 'Cancel'
                },
                this.props.parent,
                function() { // Yes
                    self.setState({
                        value: true
                    });
                }
            );
        } else {
            this.setState({
                value: ev.target.checked
            });
        }
    }

    render() {
        const label = this.props.label || '';
        const id = this.props.id || label;

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={label}
                    style={this.labelStyle}
                >
                    {label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <label className={style.inputFieldCheckbox}>
                    <input type="checkbox" checked={this.state.value} onChange={this.handleInputChange.bind(this)}/>
                    <span className={style.checkboxSlider}/>
                </label>
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ButtonField extends React.Component {
    constructor(props) {
        super(props);

        this.labelStyle = {};

        if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    render() {
        const label = this.props.label || '';
        const id = this.props.id || label;

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={label}
                    style={this.labelStyle}
                >
                    {label}
                </span>

                <div
                    className={style.inputFieldButton}
                    onClick={this.props.onClick}
                >
                    {this.props.buttonTitle || label}
                </div>

                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class NumberField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = isNaN(props.value) ? '' : props.value;
        this.state = { value: this.initialValue };

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.input.focus();
    }

    hasChanged() {
        return this.state.value !== this.initialValue;
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) {
        this.setState({
            value: value
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(this.props.id, value);
        }
    }

    handleInputChange(ev) {
        const newValue = HandleNumberInputValue(ev.target.value);

        if (newValue !== null) {
            this.setValue(newValue);
        }
    }

    handleKeyDown(ev) {
        if (!this.props.onKeyDown) {
            return;
        }

        this.props.onKeyDown(ev.key);
    }

    render() {
        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label || ''}
                    style={this.labelStyle}
                >
                    {this.props.label || ''}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={`
                        ${style.inputFieldText}
                        ${this.props.fullInputBox ? style.fullInputBox : ''}
                    `}
                    value={this.state.value}
                    step="any"
                    readOnly={this.props.disabled}
                    placeholder={this.props.placeholder || ''}
                    onChange={this.handleInputChange.bind(this)}
                    onKeyDown={this.handleKeyDown.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class IncrementalNumberField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = props.value;
        this.state = { value: props.value };

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.input.focus();
    }

    hasChanged() {
        return this.state.value !== this.initialValue;
    }

    getValue() {
        return this.state.value;
    }

    setValue(value) {
        this.setState({
            value: value
        });

        if (this.props.onValueChange) {
            this.props.onValueChange(this.props.id, value);
        }
    }

    handleIncreaseValue() {
        this.setState({
            value: (this.state.value || 0) + 1
        });
    }

    handleDecreaseValue() {
        this.setState({
            value: Math.max((this.state.value || 0) - 1, 0)
        });
    }

    render() {
        return (
            <div className={`
                    ${style.inputField}
                    ${style.NumberFieldWrapper}
                `}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label || ''}
                    style={this.labelStyle}
                >
                    {this.props.label || ''}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>

                <div className={style.minusButton} onClick={this.handleDecreaseValue.bind(this)}>
                    <div className="icon-minus-circle"/>
                </div>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={`
                        ${style.inputFieldText}
                        ${style.Number}
                    `}
                    value={this.state.value}
                    step="any"
                    placeholder={this.props.placeholder || ''}
                    readOnly={true}
                />
                <div className={style.addButton} onClick={this.handleIncreaseValue.bind(this)}>
                    <div className="icon-plus"/>
                </div>
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CurrencyValueField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = props.value || 0;
        this.currency = app.user.get('client').default_currency;

        this.state = {
            value: Currency.format(this.currency, this.initialValue)
        };

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.input.focus();
    }

    hasChanged() {
        return this.getValueNumber(this.state.value) !== this.getValueNumber(this.initialValue);
    }

    getValue() {
        return this.getValueNumber(this.state.value);
    }

    setValue(value) {
        this.setState({
            value: value
        });
    }

    getValueNumber(value) {
        return +value.replace(/[^.\d]/g, '');
    }

    handleInputChange(ev) {
        const newValue = HandleNumberInputValue(ev.target.value);

        if (newValue !== null) {
            this.setState({
                value: newValue
            });
        }
    }

    handleInputFocus() {
        this.setState({
            value: this.getValueNumber(this.state.value)
        });
    }

    handleInputBlur() {
        this.setState({
            value: Currency.format(this.currency, this.state.value)
        });
    }

    render() {
        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label || ''}
                    style={this.labelStyle}
                >
                    {this.props.label || ''}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={`
                        ${style.inputFieldText}
                        ${this.props.fullInputBox ? style.fullInputBox : ''}
                    `}
                    value={this.state.value}
                    placeholder={this.props.placeholder || ''}
                    onChange={this.handleInputChange.bind(this)}
                    onFocus={this.handleInputFocus.bind(this)}
                    onBlur={this.handleInputBlur.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ProductField extends React.Component {
    constructor(props) {
        super(props);

        this.initialValue = props.value;
        this.state = { value: props.value };

        if (props.forceValueChange) {
            this.initialValue = null;
        }
    }

    focus() {
        this.input.focus();
    }

    hasChanged() {
        return this.state.value !== this.initialValue;
    }

    getValue() {
        return this.state.value;
    }

    handleInputChange(ev) {
        const newValue = HandleNumberInputValue(ev.target.value);

        if (newValue !== null) {
            this.setState({
                value: newValue
            });

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, newValue);
            }
        }
    }

    render() {
        return (
            <div className={`${style.inputField} ${style.widerLabel}`}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label || ''}
                >
                    {this.props.label || ''}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <input
                    ref={(el) => this.input = el}
                    type="text"
                    className={style.inputFieldText}
                    value={this.state.value}
                    step="any"
                    placeholder={this.props.placeholder || ''}
                    onChange={this.handleInputChange.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CommunicationItem extends React.Component {
    constructor(props) {
        super(props);

        this.initialValues = {
            name: this.props.data.source.name || this.props.data.defaultValues.name || '',
            value: this.props.data.source.value || '',
            medium: this.props.data.source.medium,
            comments: this.props.data.source.comments || '',
            hasDuplicates: false,
            phoneNumberInvalid: this.validatePhoneNumber(this.props.data.source.medium, this.props.data.source.value),
        };

        this.state = _.clone(this.initialValues);

        if (this.props.checkForDuplicates) {
            this.state.checkForDuplicatesHRef = null;

            const self = this;
            let timeout;

            this.duplicatesChecker = function(term) {
                window.clearTimeout(timeout);

                timeout = window.setTimeout(function() {
                    if (term) {
                        let url;
                        const duplicateType = props.medium;
                        const entityType = self.props.entityType;
                        if (entityType === 'individuals') {
                            url = `/individual_duplicates?type=${duplicateType}&value=${term}`
                        } else if (entityType === 'organizations') {
                            url = `/organization_duplicates?type=${duplicateType}&value=${term}`
                        } else if (entityType === 'opportunities') {
                            return; //url = `/opportunity_duplicates?type=${duplicateType}&value=${term}`
                        } else {
                            return;
                        }
                        if (self.props.data.source.id) {
                            url += `&ignore_id=${self.props.data.source.id}`;
                        }
                        $.getJSON(url , function(data) {
                            let results = data.results;
                            if (results.length > 0) {
                                results = _.sortBy(results, (e) => e.created);

                                self.setState({
                                    checkForDuplicatesHRef: '#' + entityType + '/' + results[results.length - 1].id,
                                    hasDuplicates: true,
                                });
                            } else {
                                self.setState({
                                    checkForDuplicatesHRef: null,
                                    hasDuplicates: data.has_duplicates,
                                });
                            }
                        });
                    }
                    else {
                        self.setState({ checkForDuplicatesHRef: null });
                    }
                }, 500);
            };
        }

        if(this.props.checkEmailDomain){
            this.state.emailDomainInvalid = false;

            const self = this;
            let timeout;

            this.emailDomainChecker = function(email) {
                const domain = email.substring(email.lastIndexOf("@") +1);
                window.clearTimeout(timeout);

                timeout = window.setTimeout(function() {
                    if (domain) {
                        try {
                            const response = fetch(`https://dns.google/resolve?name=${domain}&type=MX`)
                            .then(function(response) {
                                response.json()
                                .then(function(data) {
                                    self.setState({emailDomainInvalid: !data.Answer || data.Answer.length === 0})
                                });
                            });
                        } catch(_) {
                            self.setState({ emailDomainInvalid: false });
                        }
                    }
                    else {
                        self.setState({ emailDomainInvalid: false });
                    }
                }, 500);
            }
        }
    }

    componentDidMount() {
        if (this.props.data.isNew) {
            this.valueInput.focus();
        }
    }

    handleInputChange(ev) {
        this.setState({
          [ev.target.name]: ev.target.value,
          phoneNumberInvalid: this.validatePhoneNumber(this.state.medium, ev.target.value),
        });

        if (this.duplicatesChecker) {
            this.duplicatesChecker(ev.target.value);
        }
    }

    validatePhoneNumber(medium, value) {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4851')) return null;
        if (medium !== 'phone') return null;

        try {
            libPhoneNumber.parsePhoneNumberWithError(value);
            return null;
        } catch (error) {
            if (error.message === 'INVALID_COUNTRY') {
                return null;
            }

            return Utilities.getPhoneNumberError(error);
        }
    }

    handleEmailDomainCheck(ev){
        if (this.emailDomainChecker && this.props.medium === "email") {
            this.emailDomainChecker(ev.target.value);
        }
    }

    getValue() {
        return {
            name: (this.state.name || '').trim(),
            value: (this.state.value || '').trim(),
            comments: this.state.comments
        };
    }

    render() {
        const entityTypeText = {
            'individuals': TextManager.getText('ID_INDIVIDUAL'),
            'organizations': TextManager.getText('ID_ORGANIZATION')
        }[this.props.entityType];
        const medium = this.props.medium;

        return (
            <div className={style.item}>
                <div className={style.deleteButton} onClick={this.props.onDelete}/>
                <input type="text" placeholder={this.props.data.placeholders.name} name="name" value={this.state.name} className={style.nameField} onChange={this.handleInputChange.bind(this)}/>
                {this.props.valueFieldIsParagraph ? (
                    <textarea ref={(el) => this.valueInput = el} placeholder={this.props.data.placeholders.value}
                        name="value" value={this.state.value} className={style.valueFieldTextArea}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleEmailDomainCheck.bind(this)}
                    />
                ) : (
                    <input ref={(el) => this.valueInput = el} type="text" placeholder={this.props.data.placeholders.value}
                        name="value" value={this.state.value} className={style.valueField}
                        onChange={this.handleInputChange.bind(this)}
                        onBlur={this.handleEmailDomainCheck.bind(this)}
                    />
                )}
                {this.state.phoneNumberInvalid && <div className={style.warningMessage}>
                    <i className="icon-warning" style={{marginRight: 5}}></i>{this.state.phoneNumberInvalid}
                </div>}
                <textarea
                    placeholder={this.props.data.placeholders.comments}
                    name="comments"
                    value={this.state.comments}
                    className={style.commentsField}
                    onChange={this.handleInputChange.bind(this)}
                    style={{width: '100%'}}
                />
                {this.state.checkForDuplicatesHRef && <div className={style.duplicatedErrorMessage}>
                    <i className="icon-warning" style={{marginRight: 5}}></i>There is at least <a className="link" target="_blank" href={this.state.checkForDuplicatesHRef}>1 {entityTypeText}</a> with this {medium}
                </div>}
                {!this.state.checkForDuplicatesHRef && this.state.hasDuplicates && <div className={style.duplicatedErrorMessage}>
                    <i className="icon-warning" style={{marginRight: 5}}></i>There is at least 1 {entityTypeText} with this {medium}
                </div>}
                {this.state.emailDomainInvalid && <div className={style.duplicatedErrorMessage}>
                    <i className="icon-warning" style={{marginRight: 5}}></i>The domain of this {medium} is not valid
                </div>}
            </div>
        );
    }
}

class CommunicationList extends React.Component {
    constructor(props) {
        super(props);

        this.keyGen = 0;
        this.itemComponents = {};

        const self = this;
        let items = _.map(this.props.items, function(item) {
            item = _.clone(item);

            if (self.props.medium === 'location') {
                item.value = item.address;
                delete item['address'];
            }

            let newItem = self.createItem();
            newItem.source = item;

            return newItem;
        });

        this.state = {
            items: items
        };

        this.deleteItem = this.deleteItem.bind(this);
    }

    createItem() {
        const communicationPlaceholderAndDefaultLabel = AppConfig.getValue(`communications.${this.props.medium}.label`, 'Work');

        let newItem = {
            key: this.props.medium + '_' + this.keyGen++,
            source: {},
            placeholders: {
                name: `Usage (e.g. ${communicationPlaceholderAndDefaultLabel}, Personal...)`,
                comments: 'Extra info (e.g. Contactable hours)'
            },
            defaultValues: {
                name: communicationPlaceholderAndDefaultLabel
            }
        };

        switch(this.props.medium) {
            case 'phone':
                newItem.placeholders.value = 'Phone number';
                break;

            case 'email':
                newItem.placeholders.value = 'Email address';
                break;

            case 'location':
                newItem.placeholders.name = 'Name';
                newItem.placeholders.value = 'Location address';
                newItem.placeholders.comments = 'Additional info (e.g. Opening hours)';
                newItem.defaultValues.name = '';
                break;
        }

        return newItem;
    }

    createNewItem() {
        let newItem = this.createItem();
        newItem.isNew = true;

        this.setState({
            items: this.state.items.concat([newItem])
        });
    }

    deleteItem(item) {
        this.setState({
            items: _.filter(this.state.items, function(i) {
                return i.key !== item.key
            })
        });

        delete this.itemComponents[item.key];
    }

    getValue() {
        let value = [];
        const self = this;

        _.forEach(this.state.items, function(item) {
            let component = self.itemComponents[item.key];
            let data = component.getValue();
            let addIt = false;

            if (!item.source.id) { // it's a new item
                addIt = data.value;
            } else {
                data = _.extend(_.clone(item.source), data);
                addIt = data.value;
            }

            if (addIt) {
                if (self.props.medium === 'location') {
                    data.address = data.value;
                    delete data['value'];
                } else {
                    data.medium = self.props.medium;
                }

                value.push(data);
            }
        });

        return value;
    }

    render() {
        const addIconClasses = ['icon-plus', style.icon].join(' ');

        return (
            <div className={style.communicationList}>
                {_.map(this.state.items, (item) =>
                    <CommunicationItem
                        ref={(el) => this.itemComponents[item.key] = el}
                        key={item.key}
                        data={item}
                        valueFieldIsParagraph = {this.props.medium === 'location'}
                        onDelete={() => {this.deleteItem(item)}}
                        medium={this.props.medium}
                        entityType={this.props.entityType}
                        checkForDuplicates={this.props.checkForDuplicates}
                        checkEmailDomain={this.props.checkEmailDomain}
                    />
                )}

                <div className={style.item} onClick={() => {this.createNewItem()}}>
                    <div className={style.addButton}>
                        <div className="icon-plus"/>
                    </div>
                    <div className={style.addField}>
                        <div>Add {this.props.medium}</div>
                    </div>
                </div>
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class OrganizationDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.value = this.props.value;
        this.initialValue = this.value;

        if (props.forceValueChange) {
            this.initialValue = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        const v1 = this.value ? this.value.id : null;
        const v2 = this.initialValue ? this.initialValue.id : null;

        return v1 !== v2;
    }

    getValue() {
        return this.value ? (this.value.id === "create-new" ? {id: null, name: this.getValueTitle(), funnels: app.user.getIndividualPreloadedFunnels(), createNew: true } : this.value.id) : null
    }

    getValueTitle() {
        if (!this.value) {
            return '';
        }

        return this.value.originalTitle || this.value.title;
    }

    getFullValue() {
        return this.fullValue;
    }

    focus() {
        this.label.scrollIntoView();
    }

    render() {
        const self = this;

        const processData = function(data, textToSearch) {
            // add (create new) option if it doesnt exist
            if (self.props.createIfNotExist) {
                const item = _.findWhere(data, {title: textToSearch.toLowerCase()});

                if (!item) {
                    data.unshift({
                        id: 'create-new',
                        originalTitle: textToSearch,
                        title: textToSearch + ' (Create New)'
                    });
                }
            }

            return data;
        };

        const processSelection = function(item) {
            if (item.id === 'create-new') {
                item.title = item.originalTitle + ' (New)';
            }

            return item;
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.value = items[0];
                this.fullValue = items[0];
            } else {
                this.value = null;
                this.fullValue = items[0];
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.value);
            }
        };

        let selectOptions = {
            minimumInputLength: 1,
            allowClear: this.props.allowClear,
            search_parameters: {}
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters.by_group_id = this.props.selectFromGroupId;
        }

        if (this.props.filterId) {
            selectOptions.search_parameters.by_filter_id = this.props.filterId;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={this.props.placeholder || TextManager.getText('ID_SEARCH_FOR_AN_ORGANIZATION')}
                    width={this.props.dropdownWidth || 325}
                    url="/organizations"
                    value={this.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    processData={processData.bind(this)}
                    processSelection={processSelection.bind(this)}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class IndividualDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;
        this.restrictedToOrganizationId = (this.props.entityRelatedData || {}).organization_id;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        var name = this.getValueTitle().split(" ");
        return this.valueId &&  this.valueId === "create-new" ? { id: null, first_name: name[0], last_name: name[1] } : this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    getValueTitle() {
        if (!this.valueId || !this.value) {
            return '';
        }

        return this.value.originalTitle || this.value.title;
    }

    focus() {
        this.label.scrollIntoView();
    }

    onEntityRelatedDataChange(entityRelatedData) {
        if (this.props.restrictedToOrganization) {
            this.restrictedToOrganizationId = entityRelatedData.organization_id || null;

            this.select.updateSearchParameters({
                organization_id: this.restrictedToOrganizationId
            });
        }
    }

    render() {
        const self = this;
        const processData = function(data, textToSearch) {

            data.forEach(elem => {
                elem.titleSearch = elem.title.toLowerCase()
                elem.title = elem.item && elem.item.organization ? `${elem.title} (${elem.item.organization_name})` : elem.title
                return elem
            })

            // add (create new) option if it doesnt exist
            if (self.props.createIfNotExist) {
                const item = _.findWhere(data, {titleSearch: textToSearch.toLowerCase()});

                if (!item && textToSearch.trim().indexOf(' ') != -1) {
                    data.unshift({
                        id: 'create-new',
                        originalTitle: textToSearch,
                        title: textToSearch + ' (Create New)'
                    });
                }
            }

            return data;
        };

        const processSelection = function(item) {
            if (item.id === 'create-new') {
                item.title = item.originalTitle + ' (New)';
            }

            return item;
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.value = items[0];
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.value = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        let selectOptions = {
            minimumInputLength: 1,
            allowClear: this.props.allowClear,
            search_parameters: {}
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters.by_group_id = this.props.selectFromGroupId;
        }

        if (this.props.restrictedToOrganization && this.restrictedToOrganizationId) {
            selectOptions.search_parameters.organization_id = this.restrictedToOrganizationId;
        }

        if (this.props.filterId) {
            selectOptions.search_parameters.by_filter_id = this.props.filterId;
        }

        const IndividualSelectItemComponent = AppConfig.getValue('individuals.new_select_widget.item_component', NewPlainSelectItem);

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.select = el}
                    placeholder={TextManager.getText('ID_SEARCH_FOR_AN_INDIVIDUAL')}
                    width={this.props.dropdownWidth || 325}
                    itemView={IndividualSelectItemComponent}
                    url="/individuals"
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    processData={processData.bind(this)}
                    processSelection={processSelection.bind(this)}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class OpportunityDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        let selectOptions = {
            minimumInputLength: 1,
            allowClear: this.props.allowClear,
            search_parameters: {},
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters.by_group_id = this.props.selectFromGroupId;
        }

        if (this.props.filterId) {
            selectOptions.search_parameters.by_filter_id = this.props.filterId;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={TextManager.getText('ID_SEARCH_FOR_A_DEAL')}
                    width={this.props.dropdownWidth || 325}
                    url="/opportunities"
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class TaskDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            minimumInputLength: 1,
            allowClear: this.props.allowClear
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters = {
                by_group_id: this.props.selectFromGroupId
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={'Search for a task'}
                    width={this.props.dropdownWidth || 325}
                    url='/tasks'
                    text='text'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class RegionDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters = {
                by_group_id: this.props.selectFromGroupId
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={'Search for a region'}
                    width={this.props.dropdownWidth || 325}
                    data={app.globalData.regions}
                    text='name'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ClusterDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters = {
                by_group_id: this.props.selectFromGroupId
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={`Search for a ${TextManager.getText('ID_CLUSTER')}`}
                    width={this.props.dropdownWidth || 325}
                    data={app.globalData.clusters}
                    text='name'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ChecklistDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            minimumInputLength: 0,
            allowClear: this.props.allowClear
        };

        if (this.props.entityType) {
            selectOptions.search_parameters = {
                entity_types: this.props.entityType
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={'Select a checklist'}
                    width={this.props.dropdownWidth || 325}
                    url='/checklists'
                    text='name'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class FunnelDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            minimumInputLength: 1,
            allowClear: this.props.allowClear
        };

        if (this.props.selectFromGroupId) {
            selectOptions.search_parameters = {
                by_group_id: this.props.selectFromGroupId
            };
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={`Search for a ${TextManager.getText('ID_FUNNEL')}`}
                    width={this.props.dropdownWidth || 325}
                    url='/funnels'
                    text='name'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class PhaseDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        const phases = app.globalData.phasesInfo.hierarchy;

        this.phases = [{
            id: phases[0].id,
            name: phases[0].name,
            fid: null
        }, {
            id: phases[1].id,
            name: phases[1].name,
            fid: null
        }];

        for (let i = 2; i < phases.length; ++i) {
            const group = phases[i];

            this.phases.push({
                id: 'separator'
            });

            this.phases.push({
                id: group.fid,
                name: group.name,
                isSectionTitle: true
            });

            for (const phase of group.children) {
                this.phases.push({
                    id: phase.id,
                    name: phase.name,
                    fid: group.fid
                });
            }
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={`Select a phase`}
                    width={this.props.dropdownWidth || 325}
                    text='name'
                    data={this.phases}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class AutomationDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.fullValue = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    placeholder={`Select an automation`}
                    width={this.props.dropdownWidth || 325}
                    text='name'
                    url='/automations2'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class AutomationStepDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.fullValue = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    setData(data) {
        this.dropdown.setData(data);
    }

    setValue(value) {
        if (!value) {
            this.valueId = null;
            this.fullValue = null;
        } else {
            this.valueId = value.id;
            this.fullValue = value;
        }

        this.dropdown.setValue(value);
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.dropdown = el}
                    placeholder={`Select a step`}
                    width={this.props.dropdownWidth || 325}
                    text='name'
                    data={this.props.data}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class ChecklistItemDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.fullValue = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    setData(data) {
        this.dropdown.setData(data);
    }

    setValue(value) {
        if (!value) {
            this.valueId = null;
            this.fullValue = null;
        } else {
            this.valueId = value.id;
            this.fullValue = value;
        }

        this.dropdown.setValue(value);
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.dropdown = el}
                    placeholder={`Select a checklist item`}
                    width={this.props.dropdownWidth || 325}
                    text='text'
                    data={this.props.data}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class LeadSourceDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.fullValue = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    setData(data) {
        this.dropdown.setData(data);
    }

    setValue(value) {
        if (!value) {
            this.valueId = null;
            this.fullValue = null;
        } else {
            this.valueId = value.id;
            this.fullValue = value;
        }

        this.dropdown.setValue(value);
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear,
            minimumInputLength: 0
        };

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.dropdown = el}
                    placeholder={`Select a lead source`}
                    width={this.props.dropdownWidth || 325}
                    text='name'
                    url='/lead_sources'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CampaignDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.fullValue = this.props.value || null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    focus() {
        this.label.scrollIntoView();
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    setData(data) {
        this.dropdown.setData(data);
    }

    setValue(value) {
        if (!value) {
            this.valueId = null;
            this.fullValue = null;
        } else {
            this.valueId = value.id;
            this.fullValue = value;
        }

        this.dropdown.setValue(value);
    }

    render() {
        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = null;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId, this.fullValue);
            }
        };

        let selectOptions = {
            allowClear: this.props.allowClear,
            minimumInputLength: 0
        };

        let searchParameters = {};

        if (this.props.status) {
            searchParameters.status = this.props.status;
        }

        if (this.props.searchNot) {
            searchParameters.search_not = this.props.searchNot;
        }

        if (!_.isEmpty(searchParameters)) {
            selectOptions.search_parameters = searchParameters;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <NewSelect
                    ref={(el) => this.dropdown = el}
                    placeholder={`Select a lead source`}
                    width={this.props.dropdownWidth || 325}
                    text='name'
                    url='/campaigns'
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={selectOptions}
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class UserDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const getUsers = function(callback) {
            let users = new UsersCollection();
            users.fetch({
                rows: -1,
                success: function(data) {
                    const users = _.map(data.models, function(item) {
                        return {
                            id: item.get('id'),
                            name: item.get('name')
                        };
                    });

                    callback(users);
                }
            });
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                </span>
                <NewSelect
                    placeholder={this.props.placeholder || TextManager.getText('ID_SEARCH_FOR_A_USER')}
                    width={this.props.dropdownWidth || 245}
                    data={getUsers}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={{allowClear: this.props.allowClear}}
                    text="name"
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class PeriodDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const getPeriods = function(callback) {
            let periods = new PeriodsCollection();
            periods.fetch({
                rows: -1,
                success: function(data) {
                    const periods = _.map(data.models, function(item) {
                        return {
                            id: item.get('id'),
                            name: item.get('name')
                        };
                    });

                    callback(periods);
                }
            });
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                </span>
                <NewSelect
                    placeholder={this.props.placeholder || 'Select a period'}
                    width={this.props.dropdownWidth || 245}
                    data={getPeriods}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={{allowClear: this.props.allowClear}}
                    text='name'
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class TagDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const getTags = function(callback) {
            let tags = new TagsCollection();
            tags.fetch({
                rows: -1,
                success: function(data) {
                    const tags = _.map(data.models, function(item) {
                        return {
                            id: item.get('id'),
                            name: item.get('name')
                        };
                    });

                    callback(tags);
                }
            });
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                </span>
                <NewSelect
                    placeholder={this.props.placeholder || 'Select a tag'}
                    width={this.props.dropdownWidth || 245}
                    data={getTags}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={{allowClear: this.props.allowClear}}
                    text="name"
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class GroupDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueId = this.props.value ? this.props.value.id : null;
        this.initialValueId = this.valueId;

        if (props.forceValueChange) {
            this.initialValueId = null;
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        return this.initialValueId !== this.valueId;
    }

    getValue() {
        return this.valueId;
    }

    getFullValue() {
        return this.fullValue;
    }

    render() {
        const self = this;

        const getGroups = function(callback) {
            let groups = new GroupsCollection();
            let data = {};

            if (self.props.groupType) {
                data.group_type = self.props.groupType;
            }

            if (self.props.entityType) {
                data.element_type = self.props.entityType;
            }

            groups.fetch({
                data: data,
                success: function(data) {
                    const groups = _.map(data.models, function(item) {
                        return {
                            id: item.get('id'),
                            name: item.get('name')
                        };
                    });

                    callback(groups);
                }
            });
        };

        const onSelect = function(items) {
            if (items && items.length > 0) {
                this.valueId = items[0].id;
                this.fullValue = items[0];
            } else {
                this.valueId = null;
                this.fullValue = 0;
            }

            if (this.props.onValueChange) {
                this.props.onValueChange(this.props.id, this.valueId);
            }
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    title={this.props.label}
                    style={this.labelStyle}
                >
                    {this.props.label}
                </span>
                <NewSelect
                    placeholder={this.props.placeholder || 'Search for a group'}
                    width={this.props.dropdownWidth || 245}
                    data={getGroups}
                    value={this.props.value}
                    editViewMod={!this.fullInputBox}
                    options={{allowClear: this.props.allowClear}}
                    text="name"
                    onSelect={onSelect.bind(this)}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class TagsField extends React.Component {
    constructor(props) {
        super(props);

        this.valueIds = _.map(this.props.value || [], function(value) { return value.id });
        this.initialValueIds = _.clone(this.valueIds);

        if (this.props.forceValueChange) {
            this.initialValueIds = [];
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        if (this.valueIds.length !== this.initialValueIds.length) {
            return true;
        }

        for (var i = 0; i < this.initialValueIds.length; ++i) {
            if (!_.contains(this.valueIds, this.initialValueIds[i])) {
                return true;
            }
        }

        return false;
    }

    getValue() {
        return _.map(this.valueIds, function(id) {
            return {
                id: id
            };
        });
    }

    render() {
        const onChange = function(items) {
            this.valueIds = _.map(items, function(item) { return item.id });
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                >
                    Tags
                </span>
                <TagSelect
                    value={this.props.value}
                    url='/tags'
                    text='name'
                    width={this.props.dropdownWidth || 245}
                    placeholder={this.props.placeholder}
                    editViewMod={!this.fullInputBox}
                    onChange={onChange.bind(this)}
                />
            </div>
        );
    }
}

class FunnelsField extends React.Component {
    constructor(props) {
        super(props);

        this.valueIds = _.map(this.props.value || [], function(value) { return value.id });
        this.initialValueIds = _.clone(this.valueIds);

        if (this.props.forceValueChange) {
            this.initialValueIds = [];
        }

        this.labelStyle = {};
        this.fullInputBox = !!props.fullInputBox;

        if (this.props.labelWidth === 0) {
            this.labelStyle.display = 'none';
        } else if (this.props.labelWidth) {
            this.labelStyle.width = this.props.labelWidth;
        }
    }

    hasChanged() {
        if (this.valueIds.length !== this.initialValueIds.length) {
            return true;
        }

        for (var i = 0; i < this.initialValueIds.length; ++i) {
            if (!_.contains(this.valueIds, this.initialValueIds[i])) {
                return true;
            }
        }

        return false;
    }

    getValue() {
        return _.map(this.valueIds, function(id) {
            return {
                id: id
            };
        });
    }

    render() {
        const onChange = function(items) {
            this.valueIds = _.map(items, function(item) { return item.id });
        };

        return (
            <div className={style.inputField}>
                <span
                    className={style.inputFieldLabel}
                    style={this.labelStyle}
                >
                    {TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}
                </span>
                <TagSelect
                    value={this.props.value}
                    url='/funnels'
                    text='name'
                    width={this.props.dropdownWidth || 245}
                    placeholder={this.props.placeholder}
                    editViewMod={!this.fullInputBox}
                    onChange={onChange.bind(this)}
                />
            </div>
        );
    }
}

class MultiSelectDropdownField extends React.Component {
    constructor(props) {
        super(props);

        this.valueIds = _.map(this.props.value || [], function(value) { return value.id });
        this.initialValueIds = _.clone(this.valueIds);
    }

    hasChanged() {
        if (this.valueIds.length !== this.initialValueIds.length) {
            return true;
        }

        for (var i = 0; i < this.initialValueIds.length; ++i) {
            if (!_.contains(this.valueIds, this.initialValueIds[i])) {
                return true;
            }
        }

        return false;
    }

    getValue() {
        return _.map(this.valueIds, function(id) {
            return {
                id: id
            };
        });
    }

    focus() {
        this.label.scrollIntoView();
    }

    render() {
        const onChange = function(items) {
            this.valueIds = _.map(items, function(item) { return item.id });
        };
        let text = 'value';

        if (this.props.text) {
            text = this.props.text;
        }

        let selectOptions = {
            search_parameters: {},
        };

        if (this.props.filterId) {
            selectOptions.search_parameters.by_filter_id = this.props.filterId;
        }

        return (
            <div className={style.inputField}>
                <span
                    ref={(el) => this.label = el}
                    className={style.inputFieldLabel}
                    title={this.props.label}
                >
                    {this.props.label}
                    {this.props.required && <span className={style.required}>&#x2733;</span>}
                </span>
                <TagSelect
                    value={this.props.value}
                    text={text}
                    width="245"
                    data={this.props.options}
                    url={this.props.url}
                    usesInternalSearch={false}
                    itemView={PlainSelectItem}
                    placeholder={this.props.placeholder}
                    editViewMod={true}
                    onChange={onChange.bind(this)}
                    options={selectOptions}
                />
                {this.props.error && <div className={style.errorMessage}>{this.props.error}</div>}
            </div>
        );
    }
}

class CustomFieldRenderer {
    constructor(options) {
        this.options = options;
    }

    getItemTitle(item) {
        return item.type === 'individual' && item.organizationName ? `${item.value} (${item.organizationName})` : item.value
    }

    mapMultiSelectDropdown(item) {
        const values = [];
        item.originalValue.split(',').map(optionId => {
            const option = item.options.find(opt => opt.id === optionId);

            if(option){
                values.push(option);
            }
        });

        return values;
    }

    onDateValueChange(item, newDate) {
        if (this.options.onDateValueChange) {
            this.options.onDateValueChange(item, newDate);
        }
    }

    renderCustomField(item) {
        const errors = this.options.invalidFieldErrors || {};
        const onRefCreated = this.options.onRefCreated || function() {};
        let value;

        if ('value' in item) {
            switch(item.type) {
                case 'organization':
                case 'individual':
                case 'opportunity':
                    if (item.params && item.params.multiSelect) {
                        value = this.getItemTitle(item);
                        break;
                    }

                    value = {
                        id: item.itemId,
                        title: this.getItemTitle(item)
                    };
                    break;

                case 'user':
                    value = {
                        id: item.itemId,
                        name: item.value
                    };
                    break;

                case 'dropDown':
                    if(item.params && item.params.multiSelect){
                        value = this.mapMultiSelectDropdown(item);

                    }else{
                        value = {
                            id: item.value_id,
                            value: item.value
                        };
                    }
                    break;

                default:
                    value = item.value;
                    break;
            }
        }

        let params = item.params || {};

        if ('editable_from_ui' in params && !params.editable_from_ui) {
            return (
                <NotEditableField
                    ref={(el) => {onRefCreated(item, el)}}
                    key={item.id}
                    value={value}
                    error={errors[item.id]}
                />
            );
        }

        switch(item.type) {
            case 'checkbox':
                if (item.required && !_.isBoolean(value)) {
                    return (
                        <TriStateCheckboxField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            label={item.label}
                            required={item.required}
                            error={errors[item.id]}
                        />
                    );
                }

                return (
                    <CheckboxField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        error={errors[item.id]}
                        parent={this.options.parent}
                    />
                );

            case 'text': {
                // has this field postcode support?
                const clientPreferences = app.user.get('client').preferences || {};
                let postcodeInfo = null;

                if (clientPreferences.postcode_fields) {
                    const postcodeFields = JSON.parse(clientPreferences.postcode_fields);

                    if (postcodeFields) {
                        postcodeInfo = postcodeFields.find(p => p.field_id === item.id);
                    }
                }

                if (postcodeInfo) {
                    return (
                        <PostcodeField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            value={value || ''}
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            placeholder="Enter Postcode"
                            mapping={postcodeInfo.mapping}
                            onValuePopulate={this.options.onValuePopulate}
                            error={errors[item.id]}
                        />
                    );
                }

                return (
                    <TextField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        isParagraph={item.type === 'paragraph'}
                        placeholder="Enter Text"
                        error={errors[item.id]}
                    />
                );
            }

            case 'paragraph':
                return (
                    <TextField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        isParagraph={item.type === 'paragraph'}
                        placeholder="Enter Text"
                        error={errors[item.id]}
                    />
                );

            case 'url':
                return (
                    <TextField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        placeholder="Enter URL"
                        error={errors[item.id]}
                    />
                );

            case 'urlImage':
                return (
                    <TextField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        placeholder="Image URL"
                        error={errors[item.id]}
                    />
                );

            case 'number':
                if (item.params && item.params.isCounter) {
                    return (
                        <IncrementalNumberField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            label={item.label}
                            value={value || ''}
                            required={item.required}
                            //placeholder={element.placeholder || ''}
                            error={errors[item.id]}
                        />
                    );
                }

                return (
                    <NumberField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        placeholder="Enter Number"
                        error={errors[item.id]}
                    />
                );

            case 'organization':
                let organizationFilterId = null;

                if (item.params && item.params.custom_field_filter_id) {
                    organizationFilterId = item.params.custom_field_filter_id
                }

                if (item.params && item.params.multiSelect) {
                    return (
                        <MultiSelectDropdownField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            url="/organizations"
                            value={value}
                            text='title'
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            allowClear={!item.required}
                            onValueChange={this.options.onValueChange}
                            error={errors[item.id]}
                            placeholder="Select values"
                            filterId={organizationFilterId}
                        />
                    );
                }

                return (
                    <OrganizationDropdownField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        allowClear={!item.required}
                        error={errors[item.id]}
                        createIfNotExist
                        selectFromGroupId={item.params && item.params.select_from_group && item.params.select_from_group.id}
                        filterId={organizationFilterId}
                    />
                );

            case 'individual':
                let individualFilterId = null;

                if (item.params && item.params.custom_field_filter_id) {
                    individualFilterId = item.params.custom_field_filter_id
                }

                if (item.params && item.params.multiSelect) {
                    return (
                        <MultiSelectDropdownField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            url="/individuals"
                            value={value}
                            text='title'
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            allowClear={!item.required}
                            onValueChange={this.options.onValueChange}
                            error={errors[item.id]}
                            placeholder="Select values"
                            filterId={individualFilterId}
                        />
                    );
                }

                return (
                    <IndividualDropdownField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        allowClear={!item.required}
                        error={errors[item.id]}
                        createIfNotExist
                        selectFromGroupId={item.params && item.params.select_from_group && item.params.select_from_group.id}
                        restrictedToOrganization={item.params && item.params.restricted_to_organization}
                        entityRelatedData={this.options.entityRelatedData}
                        filterId={individualFilterId}
                    />
                );

            case 'opportunity':
                let opportunityFilterId = null;

                if (item.params && item.params.custom_field_filter_id) {
                    opportunityFilterId = item.params.custom_field_filter_id
                }

                if (item.params && item.params.multiSelect) {
                    return (
                        <MultiSelectDropdownField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            url="/opportunities"
                            value={value}
                            text='title'
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            allowClear={!item.required}
                            onValueChange={this.options.onValueChange}
                            error={errors[item.id]}
                            placeholder="Select values"
                            filterId={opportunityFilterId}
                        />
                    );
                }

                return (
                    <OpportunityDropdownField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        allowClear={!item.required}
                        error={errors[item.id]}
                        selectFromGroupId={item.params && item.params.select_from_group && item.params.select_from_group.id}
                        filterId={opportunityFilterId}
                    />
                );

            case 'dropDown':
                if(item.params && item.params.multiSelect) {
                    return (
                        <MultiSelectDropdownField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            options={item.options}
                            value={value}
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            allowClear={!item.required}
                            onValueChange={this.options.onValueChange}
                            error={errors[item.id]}
                            placeholder="Select values"
                        />
                    );
                }else{
                    return (
                        <DropdownField
                            ref={(el) => {onRefCreated(item, el)}}
                            key={item.id}
                            id={item.id}
                            label={item.label}
                            options={item.options}
                            value={value}
                            required={item.required}
                            forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                            allowClear={!item.required}
                            onValueChange={this.options.onValueChange}
                            error={errors[item.id]}
                        />
                    );
                }

            case 'date':
                return (
                    <DateField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        error={errors[item.id]}
                        onValueChange={this.onDateValueChange.bind(this, item)}
                    />
                );

            case 'currency':
                return (
                    <CurrencyField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        value={value}
                        required={item.required}
                        forceValue={item.isUsingDefaultValueMergeTag}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        error={errors[item.id]}
                    />
                );

            case 'user':
                return (
                    <UserDropdownField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        label={item.label}
                        allowClear={!item.required}
                        value={value}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        error={errors[item.id]}
                    />
                );

            case 'product':
                const placeholder = params.unit_price &&
                    (params.unit_price === -1 || params.unit_price === 1) ? "Enter Value" : "Enter Quantity"
                return (
                    <ProductField
                        ref={(el) => {onRefCreated(item, el)}}
                        key={item.id}
                        id={item.id}
                        label={item.label}
                        value={value || ''}
                        required={item.required}
                        forceValueChange={item.isUsingDefaultValue || item.isUsingDefaultValueMergeTag}
                        placeholder={placeholder}
                        error={errors[item.id]}
                        onValueChange={this.options.onValueChange}
                    />
                );

            case 'list':
                return null; // this custom field is not editable
        }
    }
}

class CustomFieldGroup extends React.Component {
    constructor(props) {
        super(props);

        this.components = {};
        this.sortedComponents = [];
        this.state = {
            collapsed: this.props.group.state !== 'expanded'
        };

        this.entityRelatedData = _.clone(this.props.entityRelatedData || {});
    }

    getValues() {
        var values = {};

        _.forEach(this.components, function(component, key) {
            if (component.hasChanged()) {
                values[key] = component.getValue();
            }
        });

        return values;
    }

    getAllValues() {
        var values = {};

        _.forEach(this.components, function(component, key) {
            values[key] = component.getValue();
        });

        return values;
    }

    getFirstInvalidField() {
        for (let i = 0; i < this.sortedComponents.length; ++i) {
            const component = this.sortedComponents[i];

            if (component.props.error) {
                return component;
            }
        }

        return null;
    }

    toggle() {
        const newState = this.state.collapsed ? 'expanded' : 'collapsed';
        this.props.group.update(this.props.group.id, { state: newState });
        this.setState({
            collapsed: newState !== 'expanded'
        });
    }

    onEntityRelatedDataChange(entityRelatedData) {
        this.entityRelatedData = _.clone(entityRelatedData);

        for (const key in this.components) {
            const component = this.components[key];

            if (component.onEntityRelatedDataChange) {
                component.onEntityRelatedDataChange(this.entityRelatedData);
            }
        }
    }

    render() {
        this.props.groups.push(this);
        this.sortedComponents = [];

        const self = this;
        const cfRenderer = new CustomFieldRenderer({
            entityRelatedData: this.props.entityRelatedData,
            invalidFieldErrors: this.props.invalidFieldErrors,
            onValuePopulate: this.props.onValuePopulate,
            onValueChange: this.props.onValueChange,
            onDateValueChange: this.props.onDateValueChange,
            onRefCreated: function(item, el) {
                self.components[item.id] = el;

                if (el) {
                    self.sortedComponents.push(el);
                }
            },
            parent: this.props.parent,
        });

        let customFields = [];
        const ignoreCustomFields = this.props.ignoreCustomFields || [];

        for (const item of this.props.group.fields) {
            if (ignoreCustomFields.indexOf(item.id) !== -1) {
                continue;
            }

            customFields.push(cfRenderer.renderCustomField(item));
        }

        const toggleClasses = classnames({
            'icon-caret-right': this.state.collapsed,
            'icon-caret-down': !this.state.collapsed,
            [style.customFieldGroupToggle]: true
        });

        const groupClasses = classnames({
            [style.customFieldGroup]: true,
            [style.customFieldGroupCollapsed]: this.state.collapsed
        });

        const containerClasses = classnames({
            [style.customFieldGroupContainer]: true,
            [style.customFieldGroupContainerCollapsed]: this.state.collapsed
        });

        if (customFields.length === 0) {
            return null;
        }

        return (
            <div className={groupClasses}>
                <div className={style.customFieldGroupHeader}>
                    <i className={toggleClasses} onClick={this.toggle.bind(this)} />
                    {this.props.group.name}
                    <CustomGroupDragHandle/>
                </div>
                <div className={containerClasses}>
                    {customFields}
                </div>
            </div>
        )
    }
}

const CustomGroupDragHandle = SortableHandle(() => <i className={classnames({ "icon-drag": true, [style.customFieldGroupHandle]: true })}/>);

const CustomFieldGroupSortableElement = SortableElement(({group, groups, invalidFieldErrors, onValueChange, onValuePopulate, entityRelatedData, ignoreCustomFields, onDateValueChange, parent}) =>
    <div className={classnames({[style.customFieldGroupSortableElement]: true, [style.hidden]: (group.hidden || group.hiddenInEditMode) })}>
        <CustomFieldGroup
            key={group.id}
            group={group}
            groups={groups}
            onValueChange={onValueChange}
            onValuePopulate={onValuePopulate}
            invalidFieldErrors={invalidFieldErrors}
            entityRelatedData={entityRelatedData}
            ignoreCustomFields={ignoreCustomFields}
            onDateValueChange={onDateValueChange}
            parent={parent}
        />
    </div>
);

const CustomFieldsList = SortableContainer(({processedCustomFields, groups, invalidFieldErrors, onValueChange, onValuePopulate, entityRelatedData, ignoreCustomFields, onDateValueChange, parent}) => {
    return (
        <div className={style.customFieldsList}>
            {processedCustomFields.map((value, index) => (
                <CustomFieldGroupSortableElement
                    key={"item_" + index}
                    index={index}
                    group={value}
                    groups={groups}
                    onValueChange={onValueChange}
                    onValuePopulate={onValuePopulate}
                    invalidFieldErrors={invalidFieldErrors}
                    entityRelatedData={entityRelatedData}
                    ignoreCustomFields={ignoreCustomFields}
                    onDateValueChange={onDateValueChange}
                    parent={parent}
                />
            ))}
        </div>
    );
});

class CustomFieldsSection extends React.Component {
    constructor(props) {
        super(props);

        this.entityRelatedData = this.props.entityRelatedData || {};

        this.state = {
            processedCustomFields: this.props.processedCustomFields,
            groups: []
        };
    }

    getValues() {
        var values = {};

        _.forEach(this.state.groups, function(group) {
            if (!group.props.group.hidden) {
                values = _.extend(values, group.getValues());
            }
        });

        return values;
    }

    getAllValues() {
        var values = {};

        _.forEach(this.state.groups, function(group) {
            if (!group.props.group.hidden) {
                values = _.extend(values, group.getAllValues());
            }
        });

        return values;
    }

    getFirstInvalidField() {
        for (let i = 0; i < this.state.groups.length; ++i) {
            const field = this.state.groups[i].getFirstInvalidField();

            if (field) {
                return {
                    field: field,
                    group: this.state.groups[i]
                };
            }
        }

        return null;
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const processedCustomFields = arrayMove(prevState.processedCustomFields, oldIndex, newIndex);

            processedCustomFields[0].updateOrder(processedCustomFields);

            return {
                processedCustomFields: processedCustomFields,
                groups: []
            };
        });
    }

    onValueChange(customFieldId, customFieldValue) {
        var updateList = false;

        for (var i = 0; i < this.state.processedCustomFields.length; ++i) {
            var group = this.state.processedCustomFields[i];
            var hidden = group.hidden;

            group.manageGroupVisibility(customFieldId, customFieldValue);

            if (hidden !== group.hidden) {
                updateList = true;
            }
        }

        if (updateList) {
            this.setState({
                processedCustomFields: this.state.processedCustomFields
            })
        }

        if (this.props.onValueChange) {
            this.props.onValueChange(customFieldId, customFieldValue);
        }
    }

    onValuePopulate(customFieldId, populateMapping) {
        if (this.props.onValuePopulate) {
            this.props.onValuePopulate(customFieldId, populateMapping);
        }
    }

    onEntityRelatedDataChange(field, value) {
        this.entityRelatedData[field] = value;

        for (const group of this.state.groups) {
            group.onEntityRelatedDataChange(this.entityRelatedData);
        }
    }

    render() {
        return (
            <section className={style.customFieldsSection}>
                {this.state.processedCustomFields &&
                    <CustomFieldsList
                        ref={(el) => this.customFieldsList = el}
                        processedCustomFields={this.state.processedCustomFields}
                        ignoreCustomFields={this.props.ignoreCustomFields}
                        groups={this.state.groups}
                        onSortEnd={this.onSortEnd.bind(this)}
                        onValueChange={this.onValueChange.bind(this)}
                        onValuePopulate={this.onValuePopulate.bind(this)}
                        useDragHandle={true}
                        lockAxis={"y"}
                        invalidFieldErrors={this.props.invalidFieldErrors}
                        entityRelatedData={this.entityRelatedData}
                        onDateValueChange={this.props.onDateValueChange}
                        parent={this.props.parent}
                    />
                }
            </section>
        );
    }
}

class Header extends React.Component {
    render() {
        const showFavorite = 'is_favorite' in this.props;
        const showPermissions = app.user.get('client').permission_type !== 'rba';

        return (
            <header className={style.editHeader}>
                <a
                    className={style.cancelButton}
                    onClick={this.props.onCancel}
                >
                    Cancel
                </a>
                {this.props.isNew ? (
                    <span className={style.title}>
                        New {this.props.entityType}
                    </span>
                ) : (
                    <ul className={style.middleButtonsContainer}>
                        {showFavorite && <ToggleFavoriteBtn
                            is_favorite={this.props.is_favorite}
                            onToggleFavorite={this.props.onToggleFavorite}
                        />}
                        <li
                            className={style.deleteItem}
                            onClick={this.props.onDelete}
                            data-toggle="tooltip"
                            title="Delete Item"
                        >
                            <i className="icon-trashcan"/>
                        </li>
                        {showPermissions &&
                            <li
                                className={style.showPermissions}
                                onClick={this.props.onShowPermissionView}
                                data-toggle="tooltip"
                                title="Show Permissions"
                            >
                                <i className="icon-locked"/>
                            </li>
                        }
                    </ul>
                )}
                <a
                    className={style.saveButton}
                    onClick={this.props.onSave}
                >
                    Save
                </a>
            </header>
        );
    }
}

class SocialField extends React.Component {
    constructor(props) {
        super(props);

        this.value = '';

        if (props.item) {
            this.value = props.item.value;
        }
    }

    getValue() {
        const newValue = this.component.getValue();

        if (newValue) {
            if (this.props.item) {
                let newItemData = _.clone(this.props.item);

                newItemData.value = newValue;
                return newItemData;
            } else {  // it's a new element
                return {
                    medium: 'social',
                    name: this.props.socialId,
                    value: newValue
                };
            }
        }

        return null;
    }

    render() {
        return (
            <TextField
                ref={(el) => this.component = el}
                label={this.props.label}
                value={this.value}
                placeholder={this.props.placeholder}
            />
        );
    }
}

class SocialSection extends React.Component {
    constructor(props) {
        super(props);

        this.components = {};
        this.elements = {
            'googleplus': {
                label: 'Google+',
                placeholder: 'Google+ URL'
            },
            'linkedin': {
                label: 'Linkedin',
                placeholder: 'LinkedIn URL'
            },
            'twitter': {
                label: 'Twitter',
                placeholder: 'TwitterHandle'
            },
            'facebook': {
                label: 'Facebook',
                placeholder: 'Facebook URL'
            }
        };

        if (AppConfig.getValue('hasInstagramSocial')){
            this.elements.instagram = {
                label: 'Instagram',
                placeholder: 'Instagram URL'
            }
        }

        const self = this;

        _.forEach(this.elements, function(value, key) {
            if(key !== 'googleplus'){
                var data = _.findWhere(props.items, { name: key });

                _.extend(self.elements[key], {
                    value: data ? data.value : '',
                    source: data || {}
                });
            }
        });
    }

    getValue() {
        let value = {};
        let communication = [];
        const self = this;

        _.forEach(this.elements, function(element, key) {
            if(key !== 'googleplus'){
                let component = self.components[key];

                if (component) {
                    let newValue = component.getValue();

                    if (newValue) {
                        if (!element.source.id) { // it's a new element
                            communication.push({
                                medium: 'social',
                                name: key,
                                value: newValue
                            });
                        } else {
                            element.source = _.clone(element.source);
                            element.source.value = newValue;
                            communication.push(element.source);
                        }
                    }
                }
            }
        });

        if (communication.length > 0) {
            value.communication = communication;
        }

        if (this.components.unsubscribed_all && this.components.unsubscribed_all.hasChanged()) {
            value.unsubscribed_all = !this.components.unsubscribed_all.getValue();
        }

        if (this.components.unsubscribed_all_messages && this.components.unsubscribed_all_messages.hasChanged()) {
            value.unsubscribed_all_messages = !this.components.unsubscribed_all_messages.getValue();
        }

        return value;
    }

    render() {
        const showEmailOptedIn = 'unsubscribed_all' in this.props;
        const showMessageOptedIn = ('unsubscribed_all_messages' in this.props) && AppConfig.getValue('enableTextMessaging');

        return (
            <section className={style.socialSection}>
                {showEmailOptedIn && <CheckboxField
                    ref={(el) => this.components.unsubscribed_all = el}
                    label={TextManager.getText('ID_EMAIL_OPTED_IN')}
                    value={!this.props.unsubscribed_all}
                />}
                {showMessageOptedIn && <CheckboxField
                    ref={(el) => this.components.unsubscribed_all_messages = el}
                    label={TextManager.getText('ID_TEXTING_OPTED_IN')}
                    value={!this.props.unsubscribed_all_messages}
                />}
                {!this.props.hideSocialLinks && _.map(this.elements, (data, key) => {
                    if(key !== 'googleplus'){
                        return <TextField
                            ref={(el) => this.components[key] = el}
                            key={key}
                            label={data.label}
                            value={data.value}
                            placeholder={data.placeholder}
                        />
                    }
                })}
            </section>
        );
    }
}

class OwnerSection extends React.Component {
    constructor(props) {
        super(props);
        this.components = {};
    }

    getChanges() {
        let changes = {};

        if (this.components.owner.hasChanged()) {
            changes.owner = {
                id: this.components.owner.getValue(),
                update_default_permissions: AppConfig.getValue('updateDefaultPermissions'),
            };
        }

        return changes;
    }

    render() {
        return (
            <section className={style.ownerSection}>
                <UserDropdownField
                    label="Owner"
                    ref={(el) => this.components.owner = el}
                    value={this.props.owner}
                />
            </section>
        );
    }
}

class ExtraInfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.components = {};
    }

    getChanges() {
        let changes = {};

        if (this.components.comments.hasChanged()) {
            changes.comments = this.components.comments.getValue();
        }

        if (this.components.source && this.components.source.hasChanged()) {
            changes.source = this.components.source.getValue();
        }

        if (this.components.owner && this.components.owner.hasChanged()) {
            changes.owner = {
                id: this.components.owner.getValue(),
                update_default_permissions: AppConfig.getValue('updateDefaultPermissions'),
            };
        }

        return changes;
    }

    render() {
        const showSource = 'source' in this.props;

        return (
            <section className={style.extraInfoSection}>
                <TextField
                    ref={(el) => this.components.comments = el}
                    extraClasses={style.photoField}
                    label={TextManager.getText('ID_MORE_INFO')}
                    isParagraph="true"
                    value={this.props.comments}
                    placeholder={"Write something about " + this.props.commentsPlaceholder}
                />
                {showSource && <SourceDropdownField
                    ref={(el) => this.components.source = el}
                    value={this.props.source}
                    allowClear={true}
                />}
                {!this.props.hideOwner && <UserDropdownField
                    label="Owner"
                    ref={(el) => this.components.owner = el}
                    value={this.props.owner}
                />}
            </section>
        );
    }
}

export {
    TextField,
    DateField,
    CurrencyField,
    DropdownField,
    CheckboxField,
    NumberField,
    ProductField,
    OrganizationDropdownField,
    IndividualDropdownField,
    OpportunityDropdownField,
    TaskDropdownField,
    RegionDropdownField,
    ClusterDropdownField,
    FunnelDropdownField,
    PhaseDropdownField,
    UserDropdownField,
    TagDropdownField,
    TagsField,
    FunnelsField,
    SourceDropdownField,
    CommunicationList,
    CustomFieldsSection,
    CustomFieldRenderer,
    Header,
    SocialSection,
    ExtraInfoSection,
    OwnerSection,
    PhotoField,
    SocialField,
    OptionField,
    CurrencyValueField,
    GroupDropdownField,
    PeriodDropdownField,
    AutomationDropdownField,
    AutomationStepDropdownField,
    ChecklistDropdownField,
    ChecklistItemDropdownField,
    LeadSourceDropdownField,
    CampaignDropdownField,
    ButtonField,
    IncrementalNumberField
};
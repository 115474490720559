import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import vent from 'js/vent'
import LoadingView from 'js/views/loading'
import listTemplate from 'templates/base/list.handlebars'
import footerTemplate from 'templates/base/list_footer.handlebars'


var PaginatedListView, PlainListView, FooterView;

PaginatedListView = Marionette.Layout.extend({
    className: 'content-container',
    template: Handlebars.compile(listTemplate),
    regions: {
        listRegion: '.table-list',
        headerRegion: 'header.list-header',
        footerRegion: 'footer.pager'
    },
    events: {
        'click li.previous a': 'showPreviousPage',
        'click li.next a': 'showNextPage'
    },
    initialize: function(options) {
        var defaultOptions, extendedOptions, argOptions;

        defaultOptions = {
            parent: null,
            listItemView: null,
            listGetItemView: null,
            listItemViewOptions: {},
            showItem: function() {},
            collection: null,
            collectionLength: null,
            fetchOptions: {},
            fetchOnShow: true
        };

        // Assign options in this order: arg > extended > defaults
        argOptions = _.pick(options, _.keys(defaultOptions));
        extendedOptions = _.pick(this, _.keys(defaultOptions));
        _.defaults(argOptions, extendedOptions, defaultOptions);
        _.extend(this, argOptions);

        // Listen to changes on the collection
        if (this.collection) {
            this.listenTo(this.collection, 'sync', function() {
                this.trigger('refresh');
            });
            this.listenTo(this.collection, 'destroy', this.onCollectionDestroy);
            this.collection.collectionPage = _.isUndefined(this.options.collectionPage) ? 1 : this.options.collectionPage;
        }
        if (this.parent) {
            this.listenTo(this.parent, 'item:select', function(model) {
                this.trigger('item:select', model);
            });
        }

        this.listenTo(vent, 'contact:save', this.refresh);
        this.listenTo(vent, 'group:save', this.refresh);
        this.listenTo(vent, 'deal:save', this.refresh);
        this.listenTo(vent, 'forecast:save', this.refresh);
        this.listenTo(vent, 'activity:save', this.refresh);
        this.listenTo(vent, 'activity:delete', this.refresh);
        this.listenTo(vent, 'campaign:launch', this.refresh);
        this.listenTo(vent, 'campaign:new', this.refresh);
    },
    refresh: function() {
        this.fetchCollection();
    },
    onRender: function() {
        if (this.collection) {
            this.onCollection(this.collection);
        }
    },
    onShow: function() {
        if (this.fetchOnShow) {
            this.fetchCollection();
        }
        if(this.options.listItemViewOptions.tools && this.options.listItemViewOptions.tools.hideFooter) {
            $(this.footerRegion.el).hide();
        }
    },
    sortCollection: function(options) {
        this.sortOptions = options;
    },
    filterCollection: function(options) {
        this.filterOptions = options;
    },
    fetchCollection: function() {
        var view = this,
            fetchOptions;

        this.listRegion.show(new LoadingView());
        this.footerRegion.close();

        if (this.filterOptions && this.filterOptions.emptyCollection) {
             view.onCollection(this.filterOptions.emptyCollection);
        }
        else if (this.collection) {
            fetchOptions = {
                page: this.collection.collectionPage,
                rows: this.length,
                success: function() {
                    view.onCollection(view.collection, true);
                }
            };

            // Add any extra fetch options, like sorting or filtering
            fetchOptions = _.defaults(
                fetchOptions,
                this.sortOptions,
                this.filterOptions,
                this.fetchOptions
            );

            // And do the fetch of data
            this.collection.fetch(fetchOptions);
        }
    },
    beforeCollection: function() {
        this.listRegion.show(new LoadingView());
        this.footerRegion.close();
    },
    onCollection: function(collection, loaded) {
        this.trigger('collectionCountChange', collection.length, loaded);

        if (this.isClosed === false) {
            this.collectionLength = this.collection.length;
            this.renderPlainList(collection);
            this.renderFooter(collection);

            this.$el.nanoScroller();
        }
    },
    onCollectionDestroy: function() {
        this.fetchCollection();
    },
    renderPlainList: function(collection) {
        var ops, View;

        if (this.listRegion) {
            // show loader for collections which are not yet fetched
            if (this.collection.toBeLoaded) {
                this.collection.toBeLoaded = false;
                this.listRegion.show(new LoadingView());
                this.footerRegion.close();
            }
            else {
                ops = {
                    parent: this,
                    itemViewOptions: this.listItemViewOptions,
                    collection: collection
                };

                if (this.listGetItemView) {
                    View = PlainListView.extend({
                        getItemView: this.listGetItemView
                    });
                }
                else {
                    View = PlainListView;
                    ops.itemView = this.listItemView;
                }

                if (this.filterOptions && this.filterOptions.emptyCollectionView) {
                    ops.emptyView = this.filterOptions.emptyCollectionView;
                }

                this.listRegion.show(new View(ops));

                this.listenTo(
                    this.listRegion.currentView,
                    'showItem',
                    function (model, edit, preview) {
                        this.trigger('showItem', model, edit, preview);
                    }
                );

                this.listenTo(
                    this.listRegion.currentView,
                    'followLink',
                    function (model, link) {
                        this.trigger('followLink', model, link);
                    }
                );

                this.listenTo(
                    this.listRegion.currentView,
                    'selectionChange',
                    function (sel) {
                        this.trigger('selectionChange', sel);
                    }
                );

                this.listenTo(this.listRegion.currentView, 'itemview:mouseenter', function (childview) {
                    this.trigger('highlight', childview.model);
                });

                this.listenTo(this.listRegion.currentView, 'itemview:mouseleave', function () {
                    this.trigger('highlight');
                });
            }
        }
    },
    renderFooter: function(collection) {
        var previous, next;

        previous = this.collection.start > 0;
        next = this.collection.start + this.collection.length <
                this.collection.total;

        if ( this.footerRegion ) {
            this.footerRegion.show(new FooterView({
                previous: previous,
                next: next,
                collectionStart: collection.start + 1,
                collectionEnd: collection.start + collection.length,
                collectionTotal: collection.total,
                collection: collection
            }));
        }
    },
    showPreviousPage: function(ev) {
        ev.preventDefault();

        this.collection.collectionPage--;
        this.collection.collectionPage = Math.max(this.collection.collectionPage, 1);
        this.fetchCollection();
    },
    showNextPage: function(ev) {
        ev.preventDefault();

        // go to next page only if there is no removed items
        if (this.collection.length === this.collectionLength) {
            this.collection.collectionPage++;
        }
        this.fetchCollection();
    },
    selectAllPage: function() {
        if (this.listRegion) {
            this.listRegion.currentView.selectAllPage();
        }
    },
    deselectAllPage: function() {
        if (this.listRegion) {
            this.listRegion.currentView.deselectAllPage();
        }
    },
    addItem: function(model) {
        this.collection.add(model);
        this.collection.sync('patch');
    }
});

PlainListView = Marionette.CollectionView.extend({
    tagName: 'tbody',
    initialize: function(options) {
        this.previousClickedView = null;
        this.selectedItemViews = [];

        this.emptyView = options.itemViewOptions.emptyView;

        if (this.options.parent) {
            this.listenTo(
                this.options.parent,
                'item:select',
                function(model) {
                    var view = model && this.children.findByModel(model);
                    this.highlightRow(view && view.$el);
                }
            );
        }

        this.listenTo(
            this,
            'itemview:viewItem',
            function(sourceView, row, model, type, edit, preview) {
                this.highlightRow(row);
                this.trigger('showItem', model, edit, preview);
            }
        );

        this.listenTo(
            this,
            'itemview:followLink',
            function(sourceView, model, link) {
                this.trigger('followLink', model, link);
            }
        );

        this.listenTo(
            this,
            'itemview:selected',
            function(sourceView, prevState, state) {
                this.itemToggleSelect(sourceView, prevState, state);
                this.updateSelection();
            }
        );

        this.listenTo(
            this,
            'itemview:set:prev-click',
            function(view) {
                this.previousClickedView = view;
            }
        );

        this.listenTo(this, 'itemview:shift-select', this.shiftSelect);

        this.listenTo(this.collection, 'add change remove reset destroy',
            this.onCollectionChange);
    },
    highlightRow: function(row) {
        if (row && row.find('.disable-highlight').length) {
            return;
        }
        this.$el.find('tr').not(row).removeClass('active');
        this.$el.find(row).addClass('active');
    },
    onCollectionChange: function() {
        var view = this;

        // We need to recompute the selection
        this.selectedItemViews = this.children.filter(function(childView) {
            return view.collection.contains(childView.model) && childView.selected;
        });
        this.updateSelection();
    },
    itemToggleSelect: function(itemView, prevState, state) {
        if ( state === prevState ) {
            return;
        }

        if ( state ) {
            this.selectedItemViews.push(itemView);
        } else {
            this.selectedItemViews =
                _.without(this.selectedItemViews, itemView);
        }
    },
    shiftSelect: function(clickView) {
        var view = this,
            collection = this.collection,
            clickIndex = collection.indexOf(clickView.model),
            prevIndex, range, firstIndex, lastIndex, selecting;

        if ( !this.previousClickedView ) {
            prevIndex = clickIndex;
        } else {
            prevIndex = collection.indexOf(this.previousClickedView.model);
        }

        firstIndex = Math.min(prevIndex, clickIndex);
        lastIndex = Math.max(prevIndex, clickIndex);

        range = collection.slice(firstIndex, lastIndex+1);

        selecting = !clickView.selected;

        _.each(range, function(model){
            view.children.findByModel(model).toggleSelect({
                selecting: selecting
            });
        });

        this.previousClickedView = clickView;
    },
    updateSelection: function() {
        this.trigger('selectionChange', this.selectedItemViews);
    },
    selectAllPage: function() {
        var view = this;

        _.each(this.collection.models, function(model) {
            var item = view.children.findByModel(model);
            item.toggleSelect({selecting: true});
        });
    },
    deselectAllPage: function() {
        var view = this;

        _.each(this.collection.models, function(model) {
            var item = view.children.findByModel(model);
            item.toggleSelect({selecting: false});
        });
    }
});

FooterView = Marionette.ItemView.extend({
    tagName: 'div',
    template: Handlebars.compile(footerTemplate),
    templateHelpers: function() {
        return {
            previous: this.options.previous,
            next: this.options.next,
            collectionStart: this.options.collectionStart,
            collectionEnd: this.options.collectionEnd,
            collectionTotal: this.options.collectionTotal
        };
    },
    ui: {
        start: '.start',
        stop: '.stop',
        total: '.total',
        pageCounter: '.page-counter',
        listCounter: '.list-counter'
    },
    initialize: function() {
        this.listenTo(this.options.collection, 'remove', function() {
            this.decrementCounters();
        }, this);
    },
    /**
     * Decrements list counters by 1.
     * Destroys DOM structure, view should be re-rendered not updated for other activities.
     */
    decrementCounters: function() {
        var stopCount = parseInt(this.ui.stop.html(), 10),
            totalCount = parseInt(this.ui.total.html(), 10);

        if (totalCount > 1) {
            this.ui.total.html(totalCount - 1);
            if (stopCount > parseInt(this.ui.start.html(), 10)) {
                this.ui.stop.html(stopCount - 1);
            }
            else {
                this.ui.pageCounter.html(0);
            }
        }
        // display nothing if there is no items, to be same as if rendered without items
        else {
            this.ui.listCounter.html('');
        }
    }
});

export default PaginatedListView;

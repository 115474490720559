import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import ModalRegion from 'js/views/base/modal-region'
import app from 'js/app'
import TextManager from 'app/text-manager'
import EmailViewer from 'js/views/activity/email-viewer'
import MessageViewer from 'js/views/activity/message-viewer'
import security from 'js/utils/security'
import template from 'templates/activity/mailshot-item.handlebars'

export default BaseItemView.extend({
    template: Handlebars.compile(template),
    ui: {
        activityPinned: '.activity-pinned',
        iconPinned: '.icon-pinned',
        iconUnpinned: '.icon-unpinned'
    },
    regions: {
        emailViewer: {
            selector: '.email-viewer',
            regionType: ModalRegion.extend()
        }
    },
    events: _.extend({
        'click .view': function(ev) {
            ev.stopPropagation();

            var params = this.model.get('params') || {};

            var modelOptions = {
                created: this.model.get('created'),
                individual_name: this.getActivityAuthor(),
                note: params.content
            };
            var viewerClass;

            if (params.is_message === 'true') {
                viewerClass = MessageViewer;
            } else {
                viewerClass = EmailViewer;
                modelOptions.params = params;
            }

            var viewer = new viewerClass({model: new Backbone.Model(modelOptions)});
            var self = this;

            this.listenTo(viewer, 'email-viewer:close message-viewer:close', function() {
                self.emailViewer.reset();
            });

            this.emailViewer.show(viewer);
        },
        'click .mailshot': function(ev) {
            // ev.preventDefault();
            ev.stopPropagation();
        },
        'click .delete.has-permission': function() {
            var self = this;
            var mbContent = {
                accept_is_negative: true,
                message: 'Are you sure you want to <strong>permanently</strong> delete the activity?',
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.$el
                    .animate({ opacity: 0 }, { queue: false, duration: 200 })
                    .slideUp(250, 'easeOutQuint', function() {
                        self.trigger('deleteItem');
                    });
            });
        },
        'click .pin.has-permission': function() {
            this.trigger('togglePin');
        }
    }, BaseItemView.prototype.events),
    getActivityAuthor: function() {
        var activity_type = this.model.get('activity_type');
        if ((activity_type === 'archive:mailshot_sent') || (activity_type === 'automation:archive:mailshot_sent')) {
            var creator = this.model.get('creator');
            var author = '';

            if (creator) {
                author = creator['name'] || creator['email_address'];
            } else {
                var params = this.model.get('params');

                if (params && params.from_name) {
                    author = params.from_name;
                } else {
                    author = app.user.get('name') || app.user.get('email_address');
                }
            }

            return author;
        }

        return this.model.get('individual_name');
    },
    templateHelpers: function() {
        var createdDate = this.model.get('created');
        var params = this.model.get('params');
        var author = this.getActivityAuthor();
        var sent = false;
        var eventText;
        var reverse = false;
        var spam = false;
        var urlClicked = null;

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var activity_type = this.model.get('activity_type');
        // mailshot sent
        if ((activity_type === 'archive:mailshot_sent') || (activity_type === 'automation:archive:mailshot_sent')) {
            sent = true;
        }
        // mailshot activity
        else {
            if ((activity_type === 'automation:archive:mailshot_opened')
                || (activity_type === 'archive:mailshot_opened')) {
                eventText = 'opened';
            }
            else if ((activity_type === 'automation:archive:mailshot_url_clicked')
                || (activity_type === 'archive:mailshot_url_clicked')) {
                eventText = 'clicked this link ';
                urlClicked = params.url;
            }
            else if ((activity_type === 'automation:archive:mailshot_soft_bounce')
                || (activity_type === 'archive:mailshot_soft_bounce')) {
                eventText = 'soft bounced from';
                reverse = true;
            }
            else if ((activity_type === 'automation:archive:mailshot_hard_bounce')
                || (activity_type === 'archive:mailshot_hard_bounce')) {
                eventText = 'hard bounced from';
                reverse = true;
            }
            else if ((activity_type === 'automation:archive:mailshot_spam')
                || (activity_type === 'archive:mailshot_spam')) {
                spam = true;
            }
        }

        var ownerId = this.model.get('owner_id');

        if (!ownerId) {
            var owner = this.model.get('owner');

            if (owner) {
                ownerId = owner['id'];
            }
        }

        var related = this.model.get('related');
        var allowEdit = (app.user.get('id') === ownerId) || app.user.get('is_helper');

        if (!allowEdit && related) {
            allowEdit = security.checkPermission('edit', {
                id: app.user.get('id'),
                permissions: related.permissions
            });
        }

        return {
            is_message: params.is_message === 'true',
            mailshot_sent: sent,
            target_name: sent ? this.model.get('individual_name') : '',
            formattedDate: createdDate,
            author: author,
            individualDeleted: this.model.get('individual_deleted'),
            eventText: eventText,
            reverse: reverse,
            spam: spam,
            urlClicked: urlClicked,
            campaign_id: params.campaign_id || params.mailshot_id,
            campaign_name: params.campaign_name || params.mailshot_name,
            campaign_type_text: params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : "Direct",
            editable: this.options.parentModel,
            allowEdit: allowEdit,
            pinned: this.model.get('pinned'),
            content: params.content
        };
    },
    onRender: function () {
        this.updatePinView();
    },
    updatePinView: function() {
        var pinned = this.model.get('pinned');

        this.ui.activityPinned.toggleClass('hide', !pinned);
        this.ui.iconPinned.toggleClass('hide', !pinned);
        this.ui.iconUnpinned.toggleClass('hide', pinned);
    }
});

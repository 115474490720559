import React from 'react'

import style from './paginator.css'


export default class Paginator extends React.Component {
    render() {
        if (!this.props.paginationInfo || !this.props.paginationInfo.total) {
            return null;
        }

        const showPrev = this.props.paginationInfo.start > 0;
        const showNext = (this.props.paginationInfo.start + this.props.paginationInfo.rows) < this.props.paginationInfo.total;

        return (
            <div className={style.paginator}>
                <div
                    className={`
                        icon-arrow-left
                        ${style.pArrow}
                        ${showPrev ? style.aVisible : ''}
                    `}
                    onClick={() => this.props.onChange(this.props.paginationInfo.start - this.props.numRows)}
                />

                <div className={style.pText}>
                    {this.props.paginationInfo.start + 1}-{this.props.paginationInfo.start + this.props.paginationInfo.rows} of {this.props.paginationInfo.total}
                </div>

                <div
                    className={`
                        icon-arrow-right
                        ${style.pArrow}
                        ${showNext ? style.aVisible : ''}
                    `}
                    onClick={() => this.props.onChange(this.props.paginationInfo.start + this.props.numRows)}
                />
            </div>
        );
    }
}
import _ from 'underscore'
import Backbone from 'backbone'


var AppState = Backbone.Model.extend({
    defaults: _.extend({}, Backbone.Model.prototype.defaults, {
        user: 'No user',
        client: 'No client',
        nav_item: '',
        current_section: '',
        current_model: ''
    }),
    initialize: function() {
        // Reset current_model when you navigate to another section
        this.bind('change:current_view', function() {
            this.set({current_model: ''});
        });
    }
});

export default AppState;

import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import classnames from 'classnames';

import styles from './automation.css';

import Popover from 'js/react_views/popover/popover';
import NewAutomationView from 'js/react_views/automation/automation-new';
import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';


const statusMap = {
    on: { id: "on", icon: "icon-loop" },
    off: { id: "off", icon: "icon-stop" },
    drain: { id: "drain", icon: "icon-drain" }
};

class AutomationListingView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newAutomationPopupVisible: false
        };

        this.showNewAutomationPopup = this.showNewAutomationPopup.bind(this);
        this.hideNewAutomationPopup = this.hideNewAutomationPopup.bind(this);
        this.createAutomation = this.createAutomation.bind(this);
        this.updateAutomationData = this.updateAutomationData.bind(this);
    }

    showNewAutomationPopup () {
        this.setState({
            newAutomationPopupVisible: true
        });
    }

    hideNewAutomationPopup () {
        this.setState({
            newAutomationPopupVisible: false,
            errorField: null
        });
    }

    updateAutomationData(data) {
        this.automation = data;
        this.forceUpdate();
    }

    createAutomation() {
        const callback = (data) => {
            if (data && data.detail && data.detail.exception) {
                this.setState({
                    errorField: data.detail.key
                });
            }
            else {
                this.setState({
                    newAutomationPopupVisible: false,
                    errorField: null
                });
                this.automation = {};
            }
        };

        this.props.createAutomation(_.clone(this.automation), callback);
    }

    renderAutomationTags = (automation) => {
        var html = '';

        if (automation.tags.length > 0) {
            html += `<div className='${styles.tagContainer}' title='${automation.tags.map(tag => tag.name).join(', ')}'>`;

            for (let tag of automation.tags){
                html += `<div key=${'list-tag-' + tag.id} className='${styles.tagName}'>${tag.name}</div>`
            }

            html += '</div>';
        }

        return html;
    }

    render() {
        const automations = _.sortBy(this.props.automationList, function(automation) {
            return automation.name.toLowerCase();
        });

        return (
            <div className={styles.AutomationListingView}>
                <div className={styles.header}>
                    <h5>Automations</h5>
                </div>
                <a className={styles.newAutomation} title="Create new Automation" onClick={this.showNewAutomationPopup}>
                    <i className="icon-plus"></i> New Automation
                </a>
                <div className={styles.listContainer}>
                    <ul className={styles.list}>
                    {
                        automations.map(automation => {
                            return <li
                                key={'list-item-' + automation.id}
                                className={classnames({
                                    [styles.item]: true,
                                    [styles.selectedItem]: automation.id === this.props.automationId
                                })}
                            >
                                <a className={styles.link} onClick={() => { this.props.handleItemClick(automation.id) }}>
                                    <div className={styles.iconContainer}>
                                        <i className={statusMap[automation.state].icon}></i>
                                    </div>
                                    <div className={styles.nameContainer}>
                                        <div className={styles.name}>{automation.name}</div>
                                        <div className={styles.owner}>{automation.owner.name}</div>
                                    </div>
                                    <div style={{ clear: 'both' }} />
                                </a>
                                <TagListSidebar
                                    tags={automation.funnels ? automation.funnels : []}
                                    caseValue="funnels"
                                />
                            </li>
                        })
                    }
                    </ul>
                </div>
                {
                    this.state.newAutomationPopupVisible &&
                    <Popover
                            width={600}
                            height={400}
                            header={{
                                title: "New Automation",
                                onClose: this.hideNewAutomationPopup,
                                onSave: this.automation && this.createAutomation
                            }}
                        >
                        <NewAutomationView
                            updateAutomationData={this.updateAutomationData}
                            errorField={this.state.errorField}
                        />
                    </Popover>
                }
            </div>
        );
    }
}

export default AutomationListingView;
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import _ from 'underscore';
import $ from 'jquery';

import app from 'js/app'
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import FlexParser from './flex-parser';
import FlexRenderer from './flex-view-renderer';

import Popover from 'js/react_views/widgets/popover';
import IndividualDeals from './deals';
import RelatedFiles from 'js/react_views/detail_view_components/related-files';
import GroupMembership from 'js/react_views/detail_view_components/group-membership';
import ArchiveAddress from 'js/react_views/detail_view_components/archive-address';
import DragAndDropOverlay from 'js/react_views/detail_view_components/drag-and-drop-overlay';
import RelatedIndividuals from './RelatedIndividuals';

import {TextField, QuickFieldsView} from 'js/react_views/detail_fields/detail_fields';


import styles from './styles.css';

// view layout has 2 parts: the header (image, name, role, etc) and the rest of fields
const DEFAULT_LAYOUT = [
    [
        'style.vspace:24px',
        'system.photo_url',

        {
            style: {
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
                marginTop: '20px',
                color: '#404040'
            },

            elements: [
                'system.full_name'
            ]
        },

        {
            style: {
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                color: '#c1c4c3'
            },

            elements: [
                'system.role'
            ]
        },

        {
            style: {
                display: 'flex',
                justifyContent: 'center',
                color: '#0a97ff',
                cursor: 'pointer'
            },

            elements: [
                'system.organization_id'
            ]
        },

        {
            style: {
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '24px',
                flexDirection: 'column'
            },

            elements: [
                'system.tags',
                'system.funnels',
                'system.checklists'
            ]
        },
    ],

    [
        'style.vspace:24px',

        {
            style: {
                paddingLeft: '24px',
                paddingRight: '24px'
            },

            elements: [
                'system.comments',
                'system.communication_phones',
                'system.communication_emails',
                'system.unsubscribed_all',
                'system.unsubscribed_all_messages',
                'system.linkedin',
                'system.twitter',
                'system.facebook',
                'system.instagram',
                'system.locations',
                'system.source',
                'system.enquiry_count',
                'system.custom_field_groups',
                'system.docusign'
            ]
        }
    ]
];

const colors = {
    faintGrey: 'rgba(0, 0, 0, 0.2)',
    faintGrey2: '#c1c4cd',
    darkGrey: '#404040',
    link: '#0a97ff',
    starYellow: '#ffda00'
};

class ToggleFavoriteBtn extends React.Component {
    render() {
        const favorite = this.props.isFavorite;
        const css = `
            .style-individual-no-fav-star {
                color: ${colors.faintGrey2};
                transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            .style-individual-no-fav-star:hover {
                color: ${colors.starYellow};
            }
        `;
        const receivedStyle = this.props.style ? this.props.style : {};
        const noFavStarStyle = { opacity: favorite ? 0 : 1 };
        const favStarStyle = _.extend(
            {
                position: 'absolute',
                top: 0,
                color: colors.starYellow,
                transition: 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'
            },
            favorite ? {
                opacity: 1,
                transform: 'scale(1)'
            } : {
                opacity: 0,
                transform: 'scale(5)',
                visibility: 'hidden'
            }
        );

        return (

            <li
                style={{
                    display: 'inline-block',
                    position: 'relative',
                    cursor: 'pointer',
                    verticalAlign: 'middle'
                }}>

                <style>{css}</style>
                <i className="icon-star style-individual-no-fav-star"
                    style={_.extend({}, receivedStyle, noFavStarStyle)}
                    onClick={this.props.onToggleFavorite} />
                <i className="icon-star2"
                    style={_.extend({}, receivedStyle, favStarStyle)}
                    onClick={this.props.onToggleFavorite} />
            </li>

        );
    }
}

const headerStyle = {
    position: 'absolute',
    left: 60,
    right: 50,
    textAlign: 'center',
    whiteSpace: 'nowrap'
};

const headerBtnStyle = {
    float: 'left',
    fontSize: '18px',
    lineHeight: '42px',
    height: 42,
    color: colors.faintGrey2
};

const closeBtnStyle = _.extend(
    {},
    headerBtnStyle,
    { color: colors.link, cursor: 'pointer' }
);

const headerCenterListItemStyle = {
    display: 'block',
    height: 42,
    padding: '0 8px',
    lineHeight: '42px'
};

const headerOwnerNameStyle = _.extend(
    {
        fontSize: '13px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: colors.faintGrey2,
        cursor: 'default'
    },
    headerCenterListItemStyle
);

// quick way to replicate the behaviour of hiding the close button
// when the individual view is visible in the context of search results
const closeButtonHideStyle = `
    .app-search-browse .style-individual-close-button {
        display: none;
    }
`;

class DetailViewHeader extends React.Component {

    render() {
        const onEdit = this.props.isProtected ? null : this.props.onEdit;
        const onShowPermission = this.props.isProtected ? null : this.props.onShowPermissionView;
        const editBtnStyle = _.extend({}, headerBtnStyle, onEdit ?
            { float: 'right', color: colors.link, cursor: 'pointer' } :
            { float: 'right', cursor: 'default' }
        );
        const permBtnStyle = _.extend({}, headerCenterListItemStyle, onShowPermission ?
            { color: colors.link, cursor: 'pointer' } :
            { color: colors.faintGrey2 }
        );
        let permTooltip = 'Show Permissions';
        if (this.props.isProtected) {
            permTooltip = "You can't modify the permissions";
        }
        else if (!this.props.isPermissionsEnabled) {
            permTooltip = 'Upgrade account to access Permissions';
        }
        else if (!this.props.onEdit) {
            permTooltip = "You don't have permission to view permissions";
        }

        const showPermissions = app.user.get('client').permission_type !== 'rba';

        return (

            <header style={{ position: 'relative', margin: '9px 24px' }}>
                <style>{closeButtonHideStyle}</style>
                <a
                    style={closeBtnStyle}
                    onClick={this.props.onClose}
                    className="style-individual-close-button">Close</a>
                <ul style={headerStyle}>
                    <ToggleFavoriteBtn
                        isFavorite={this.props.isFavorite}
                        onToggleFavorite={this.props.onToggleFavorite}
                        style={headerCenterListItemStyle}
                    />
                    {AppConfig.getValue('individuals.duplicate.visible') &&
                        <li className={styles.duplicateButton} onClick={this.props.onDuplicateClick}>
                            <i className='icon-copy'/>
                        </li>
                    }
                    <li className={styles.historyButton} onClick={this.props.onHistoryClick}>
                        <i className='icon-history'/>
                    </li>
                    {showPermissions &&
                        <li
                            className='individual-permission-button'
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle'
                            }}>

                            <i
                                title={permTooltip}
                                style={permBtnStyle}
                                onClick={onShowPermission}
                                className="icon-locked" />
                        </li>
                    }
                    <li
                        style={{
                            maxWidth: 'calc(100% - 115px)',
                            display: 'inline-block',
                            verticalAlign: 'middle'
                        }}>
                        <span style={headerOwnerNameStyle}>{this.props.owner}</span>
                    </li>
                </ul>
                <a className='individual-edit-button' style={editBtnStyle} onClick={onEdit}>Edit</a>
                <div style={{clear: 'both'}}></div>
            </header>

        );
    }
}

const contactNameStyle = {
    margin: '20px 0 0',
    fontSize: '20px'
};

const contactRoleStyle = {
    display: 'block',
    color: colors.faintGrey2
};

const contactOrgStyle = {
    display: 'block'
};

const detailNavStyle = {
    margin: '0 24px',
    textAlign: 'center',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.07)'
};

const detailNavItemStyle = {
    display: 'inline-block',
    margin: '0 9px',
    height: 42,
    fontSize: '18px',
    lineHeight: '43px',
    verticalAlign: 'bottom',
};

const moreInfoHeaderStyle = {
    margin: '0 24px',
    lineHeight: '42px',
    fontSize: '11px',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.faintGrey2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

class IndividualFlexView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            individualDealsSelection: {},
            organizationDealsSelection: {},
            relatedIndividualsSelection: {},
        };

        this.sections = {};
        this.layout = this.props.layout || DEFAULT_LAYOUT;

        this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
        this.showIndividualDeals = this.showIndividualDeals.bind(this);
        this.showOrganizationDeals = this.showOrganizationDeals.bind(this);
        this.handleDealSelect = this.handleDealSelect.bind(this);
        this.handleAllDealsToggle = this.handleAllDealsToggle.bind(this);
        this.handleIndividualSelectionClear = this.handleIndividualSelectionClear.bind(this);
        this.handleOrganizationSelectionClear = this.handleOrganizationSelectionClear.bind(this);
        this.handleIndividualSelect = this.handleIndividualSelect.bind(this);
        this.handleRelatedIndividualSelect = this.handleRelatedIndividualSelect.bind(this);
        this.handleIndividualSelectionClear = this.handleIndividualSelectionClear.bind(this);
        this.handleAllIndividualsToggle = this.handleAllIndividualsToggle.bind(this);
        this.handleRelatedIndividualsToggle = this.handleRelatedIndividualsToggle.bind(this);
        this.handleNavigateIndividualPage = this.handleNavigateIndividualPage.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        this.documentDragHandler = (e) => {
            e.preventDefault();
        };
        if (typeof document !== 'undefined') {
            document.addEventListener('dragover', this.documentDragHandler);
            document.addEventListener('drop', this.documentDragHandler);
        }
    }

    componentDidUpdate() {
        if (this.props.gotoSection && this.sections[this.props.gotoSection]) {
            this.sections[this.props.gotoSection].scrollIntoView();
        }
    }

    componentWillUnmount() {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        if (typeof document !== 'undefined') {
            document.removeEventListener('dragover', this.documentDragHandler);
            document.removeEventListener('drop', this.documentDragHandler);
        }
    }

    handleOrganizationClick(ev) {
        ev.preventDefault();
        this.props.onOrganizationClick();
    }

    showIndividualDeals() {
        this.setState({dealsTab: 'individual'});
    }

    showOrganizationDeals() {
        this.setState({dealsTab: 'organization'});
    }

    handleDealSelect(selectionKey, item) {
        this.setState((prevState, props) => {
            const ext = {};
            const out = {};
            let newSelection;

            if (prevState[selectionKey][item.id]) {
                newSelection = _.clone(prevState[selectionKey]);
                delete newSelection[item.id];
            }
            else {
                ext[item.id] = true;
                newSelection = _.extend({}, prevState[selectionKey], ext);
            }
            out[selectionKey] = newSelection;
            return out;
        });
    }

    handleAllDealsToggle(selectionKey) {
        this.setState((prevState, props) => {
            const deals = selectionKey === "individualDealsSelection" ?
                    this.props.relatedData.deals :
                    this.props.relatedData.organizationDeals;
            const out = {};

            if (_.size(prevState[selectionKey]) === deals.items.length) {
                out[selectionKey] = {};
            }
            else {
                out[selectionKey] = _.object(_.map(deals.items  , deal => [deal.id, true]));
            }
            return out;
        });
    }

    handleIndividualSelectionClear() {
        this.setState({
            individualDealsSelection: {}
        });
    }

    handleOrganizationSelectionClear() {
        this.setState({
            organizationDealsSelection: {}
        });
    }

    handleIndividualSelect(selectionKey, item) {
        this.setState((prevState, props) => {
            let newSelection;

            if (prevState[selectionKey][item.id]) {
                newSelection = _.clone(prevState[selectionKey]);
                delete newSelection[item.id];
            }
            else {
                newSelection = _.extend(
                    {},
                    prevState[selectionKey],
                    { [item.id]: true }
                );
            }
            return { [selectionKey]: newSelection };
        });
    }

    handleRelatedIndividualSelect(item) {
        this.handleIndividualSelect('relatedIndividualsSelection', item);
    }

    handleIndividualSelectionClear() {
        this.setState({
            relatedIndividualsSelection: {}
        });
    }

    handleAllIndividualsToggle(selectionKey) {
        this.setState((prevState, props) => {
            const individuals = this.props.relatedData.individuals
            const out = {};

            if (_.size(prevState[selectionKey]) === individuals.items.length) {
                out[selectionKey] = {};
            }
            else {
                out[selectionKey] = _.object(
                    _.map(individuals.items, individual => [individual.id, true])
                );
            }
            return out;
        });
    }

    handleRelatedIndividualsToggle() {
        this.handleAllIndividualsToggle('relatedIndividualsSelection');
    }

    handleNavigateIndividualPage(direction) {
        const { onIndividualsPaging } = this.props;

        const individualsKey = 'individuals'
        this.handleIndividualSelectionClear();

        onIndividualsPaging(individualsKey, direction);
    }

    handleDragEnter(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        // track child elements entered and left during a drag operation
        // as a reliable way to detect dragging over the top-level element
        // http://stackoverflow.com/questions/10253663/how-to-detect-the-dragleave-event-in-firefox-when-dragging-outside-the-window/10310815#10310815
        // http://stackoverflow.com/questions/14194324/firefox-firing-dragleave-when-dragging-over-text
        if (this.dragOverNodes == undefined) {
            this.dragOverNodes = [];
        }
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: true
            });
        }
        // using nativeEvent here is essential. Use of React's SyntheticEvent will result
        // in a failure in Firefox when dragging over text nodes
        this.dragOverNodes = _.union(this.dragOverNodes, [e.nativeEvent.target]);
    }

    handleDragLeave(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        this.dragOverNodes = _.without(this.dragOverNodes, e.nativeEvent.target);
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: false
            });
        }
    }

    handleDragOver(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        e.preventDefault();
    }

    handleDrop(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        e.preventDefault();
        this.props.onDnDFileUpload(e.dataTransfer.files);
        this.setState({
            dragOver: false
        });
        this.dragOverNodes = [];
    }

    render() {
        const flexParser = new FlexParser({
            modelData: this.props.individual,
            customFieldGroups: this.props.relatedData && this.props.relatedData.processedCustomFields
        });

        const headerParseInfo = flexParser.parse(this.layout[0]);
        const detailsParseInfo = flexParser.parse(this.layout[1], headerParseInfo.outOfGroupCustomFields);

        const flexRenderer = new FlexRenderer({
            headerLayout: headerParseInfo,
            detailsLayout: detailsParseInfo,
            modelData: this.props.individual,
            checklists: this.props.checklists || [],
            customFieldGroups: this.props.relatedData && this.props.relatedData.processedCustomFields,
            onCommunicationClick: this.props.onCommunicationClick,
            onShowDocusignView: this.props.onShowDocusignView,
            onAppointmentClick: this.props.onAppointmentClick,
            handleCredasAmlUpdate: this.props.handleCredasAmlUpdate,
        });

        const fields = flexRenderer.render();
        let dealsTab = this.state.dealsTab ? this.state.dealsTab : '';

        if (!dealsTab && this.props.relatedData.organizationDeals && this.props.relatedData.organizationDeals.items.length && (!this.props.relatedData.deals || !this.props.relatedData.deals.items.length)) {
            dealsTab = 'organization';
        }

        if (!dealsTab) {
            dealsTab = 'individual';
        }

        return (
            <div className={styles.IndividualView}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
            >
                <div>
                    <DetailViewHeader
                        isFavorite={this.props.individual.is_favorite}
                        isPermissionsEnabled={this.props.isPermissionsEnabled}
                        isProtected={this.props.isProtected}
                        owner={this.props.individual.owner.name}
                        onToggleFavorite={this.props.onToggleFavorite}
                        onShowPermissionView={this.props.onShowPermissionView}
                        onEdit={this.props.onEdit}
                        onClose={this.props.onClose}
                        onDuplicateClick={this.props.onDuplicateClick}
                        onHistoryClick={this.props.onHistoryClick}
                    />
                </div>
                <div style={{
                    position: 'fixed',
                    width: '32%',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        height: 'calc(100% - 110px)',
                        overflow: 'auto'
                    }}>
                        <div>
                            {fields.headerFields}
                        </div>
                        <QuickFieldsView
                            entity={this.props.individual}
                            entityType='individuals'
                        />
                        <nav style={detailNavStyle}>
                            <ul>
                                <li style={_.extend({color: colors.darkGrey}, detailNavItemStyle)}>Details</li>
                            </ul>
                        </nav>
                        <div className={styles.contentPane}>
                            <div>
                                {fields.detailFields}

                                <div style={{paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px'}}>
                                    <IndividualDeals
                                        individualDeals={this.props.relatedData.deals}
                                        organizationDeals={this.props.relatedData.organizationDeals}
                                        onClickNext={() => {
                                            dealsTab === 'individual' && this.handleIndividualSelectionClear();
                                            dealsTab === 'organization' && this.handleOrganizationSelectionClear();
                                            this.props.onClickNextDealPage(
                                                dealsTab === 'individual' ? 'deals' : 'organizationDeals'
                                            );
                                        }}
                                        onClickPrev={() => {
                                            dealsTab === 'individual' && this.handleIndividualSelectionClear();
                                            dealsTab === 'organization' && this.handleOrganizationSelectionClear();
                                            this.props.onClickPrevDealPage(
                                                dealsTab === 'individual' ? 'deals' : 'organizationDeals'
                                            );
                                        }}
                                        individualSelection={this.state.individualDealsSelection}
                                        organizationSelection={this.state.organizationDealsSelection}
                                        onIndividualDealSelect={(item) => {
                                            this.handleDealSelect("individualDealsSelection", item);
                                        }}
                                        onOrganizationDealSelect={(item) => {
                                            this.handleDealSelect("organizationDealsSelection", item);
                                        }}
                                        onAllIndividualToggle={() => {
                                            this.handleAllDealsToggle("individualDealsSelection");
                                        }}
                                        onAllOrganizationToggle={() => {
                                            this.handleAllDealsToggle("organizationDealsSelection");
                                        }}
                                        onIndividualSelectionClear={this.handleIndividualSelectionClear}
                                        onOrganizationSelectionClear={this.handleOrganizationSelectionClear}
                                        onDealNavigate={this.props.onDealNavigate}
                                        tab={dealsTab}
                                        onShowIndividualDeals={this.showIndividualDeals}
                                        onShowOrganizationDeals={this.showOrganizationDeals}
                                        onAdd={this.props.onDealsAdd}
                                        onNew={this.props.onNewDeal}
                                        onDelete={this.props.onDealsDelete}
                                    />

                                    <div ref={(el) => this.sections.content = el}/>
                                    {
                                        !AppConfig.getValue('disableRelatedIndividualsSectionOnIndividual', false) &&
                                        <RelatedIndividuals
                                            individuals={this.props.relatedData.individuals}
                                            tab='individual'
                                            onClickNext={() => { this.handleNavigateIndividualPage(1); }}
                                            onClickPrev={() => { this.handleNavigateIndividualPage(-1); }}
                                            onNavigate={this.props.onIndividualNavigate}
                                            onLinkClick={this.props.onLinkClick}
                                            individualsSelection={this.state.relatedIndividualsSelection}
                                            onIndividualSelect={this.handleRelatedIndividualSelect}
                                            onSelectionClear={this.handleIndividualSelectionClear}
                                            onAllToggle={this.handleRelatedIndividualsToggle}
                                            onSearch={this.props.onIndividualSearch}
                                            onAdd={this.props.onIndividualsAdd}
                                            onDelete={this.props.onIndividualsDelete}
                                            onNew={this.props.onNewIndividual}
                                        />
                                    }

                                    <RelatedFiles
                                        files={this.props.relatedData.files}
                                        onClickNext={this.props.onClickNextFilePage}
                                        onClickPrev={this.props.onClickPrevFilePage}
                                        userId={this.props.userId}
                                        isEditable={this.props.isEditable}
                                        isProtected={this.props.isProtected}
                                        onAdd={this.props.onFileSelect}
                                        onUpload={this.props.onDnDFileUpload}
                                        onDelete={this.props.onFileDelete}
                                        fileUpload={this.props.relatedData.fileUpload}
                                    />
                                    <GroupMembership
                                        items={this.props.relatedData.groups}
                                        onSearch={this.props.onGroupSearch}
                                        onAdd={this.props.onGroupAdd}
                                        onRemove={this.props.onGroupRemove}
                                        onGroupClick={this.props.onGroupClick}
                                    />
                                    <ArchiveAddress email={this.props.archiveEmailAddress}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IndividualFlexView;

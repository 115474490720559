export default {
  '1Column': `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" style="box-sizing: border-box;">
  
  <head>
      <!--BEGIN-->
      <!--[if gte mso 15]>
                        <xml>
                            <o:OfficeDocumentSettings>
                            <o:AllowPNG/>
                            <o:PixelsPerInch>96</o:PixelsPerInch>
                            </o:OfficeDocumentSettings>
                        </xml>
                        <![endif]-->
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  
  </head>
  
  <body style="box-sizing: border-box; margin: 0; background-color: #fafafa;">
      <center style="box-sizing: border-box;">
          <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                    <tr>
                    <td align="center" valign="top" width="600" style="width:600px;">
                    <![endif]-->
          <!--#BEGIN-->
          <br style="box-sizing: border-box;">
          <table data-gjs-name="image" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                      <td data-gjs-name="image" valign="top" style="box-sizing: border-box;">
                          <table data-gjs-name="image" align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; min-width: 100%;">
                              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                                      <td valign="top" align="center" data-gjs-name="image" style="box-sizing: border-box; padding-top: 0; padding-bottom: 0; text-align: center;"><a data-gjs-name="image" style="box-sizing: border-box; display: block; padding: 18px; width: 100%; height: auto; max-width: 600px; font-family: Arial;"><img data-gjs-name="image" width="340" height="79" src="https://public.salesseek.net/salesseek/yourlogo.png" style="box-sizing: border-box; max-width: 564px; font-family: Arial; width: 340px; height: 79px;"></a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="heading" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="heading" style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;" data-gjs-name="heading">
                      <td valign="top" style="box-sizing: border-box;" data-gjs-name="heading">
                          <!--[if mso]>
            <table data-gjs-name="heading" align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="heading">
            <tr data-gjs-name="heading">
            <![endif]-->
                          <!--[if mso]>
            <td data-gjs-name="heading" valign="top" width="600" style="width:600px;" data-gjs-name="heading">
            <![endif]-->
                          <table data-gjs-name="heading" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody style="box-sizing: border-box;" data-gjs-name="heading">
                                  <tr style="box-sizing: border-box;" data-gjs-name="heading">
                                      <td data-gjs-name="heading" valign="top" style="box-sizing: border-box; box-sizingƒ: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 26px; font-weight: 400; border: none; padding-top: 25px;">
                                          <div data-gjs-name="heading" style="box-sizing: border-box; font-family: Arial;">A spot for your header</div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="text" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
            <table data-gjs-name="text" align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="text">
            <tr data-gjs-name="text">
            <![endif]-->
                          <!--[if mso]>
            <td data-gjs-name="text" valign="top" width="600" style="width:600px;" data-gjs-name="text">
            <![endif]-->
                          <table data-gjs-name="text" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="text" style="box-sizing: border-box; font-family: Arial;">Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="divider" dir="ltr" width="100%" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" bgcolor="#ffffff">
              <tbody data-gjs-name="divider" style="box-sizing: border-box;">
                  <tr data-gjs-name="divider" style="box-sizing: border-box;">
                      <td data-gjs-name="divider" style="box-sizing: border-box; min-width: 100%; padding: 18px;">
                          <hr data-gjs-name="divider" style="box-sizing: border-box; height: 1px; border: none; color: #eee; background-color: #eee;">
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="footer" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;" bgcolor="#ffffff">
              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
          <table data-gjs-name="footer" align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="footer">
          <tr data-gjs-name="footer">
          <![endif]-->
                          <!--[if mso]>
          <td valign="top" width="600" style="width:600px;" data-gjs-name="footer">
          <![endif]-->
                          <table data-gjs-name="footer" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="footer" style="font-family: Arial; box-sizing: border-box; font-weight: 400; text-align: center; font-size: 11px; color: #555555; letter-spacing: normal;"><em draggable="true" data-highlightable="1" style="box-sizing: border-box;">Copyright © All rights reserved.</em><br draggable="true" data-highlightable="1" style="box-sizing: border-box;"><a data-gjs-name="footer" draggable="true" data-highlightable="1" href="\${unsubscribe}" target="_blank" style="box-sizing: border-box; font-family: Arial; color: #333333;">Unsubscribe from this list</a>.<br draggable="true" data-highlightable="1" style="box-sizing: border-box;"></div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
          </td>
          <![endif]-->
                          <!--[if mso]>
          </tr>
          </table>
          <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <!--[if gte mso 9]>
      <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" data-gjs-name="boxed-text">
      <![endif]-->
              <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                      <td valign="top" style="box-sizing: border-box;">
                          <!--[if gte mso 9]>
            <td align="center" valign="top" data-gjs-name="boxed-text">
            <![endif]-->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; min-width: 100%;">
                              <tbody style="box-sizing: border-box;">
                                  <tr style="box-sizing: border-box;"></tr>
                              </tbody>
                          </table>
                          <!--[if gte mso 9]>
            </td>
            <![endif]-->
                          <!--[if gte mso 9]>
                    </tr>
                    </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table><br style="box-sizing: border-box;">
          <!--END-->
          <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
      </center>
      <!--#END-->
      <br style="box-sizing: border-box;">
  </body>
  
  </html>`,

  '1ColumnFW': `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" style="box-sizing: border-box;">
  
  <head>
      <!--BEGIN-->
      <!--[if gte mso 15]>
                        <xml>
                            <o:OfficeDocumentSettings>
                            <o:AllowPNG/>
                            <o:PixelsPerInch>96</o:PixelsPerInch>
                            </o:OfficeDocumentSettings>
                        </xml>
                        <![endif]-->
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  
  </head>
  
  <body style="box-sizing: border-box; margin: 0; background-color: #fafafa;">
      <center style="box-sizing: border-box;">
          <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                    <tr>
                    <td align="center" valign="top" width="600" style="width:600px;">
                    <![endif]-->
          <!--#BEGIN-->
          <br style="box-sizing: border-box;">
          <table data-gjs-name="image" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                      <td data-gjs-name="image" valign="top" style="box-sizing: border-box;">
                          <table data-gjs-name="image" align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; min-width: 100%;">
                              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                                      <td data-gjs-name="image" valign="top" align="center" style="box-sizing: border-box; padding-top: 0; padding-bottom: 0; text-align: center;"><a data-gjs-name="image" style="box-sizing: border-box; display: block; padding: 18px; width: 100%; height: auto; max-width: 600px; font-family: Arial;"><img data-gjs-name="image" width="340" height="79" src="https://public.salesseek.net/salesseek/yourlogo.png" style="box-sizing: border-box; max-width: 564px; font-family: Arial; width: 340px; height: 79px;"></a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="heading" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="heading" style="box-sizing: border-box;">
                  <tr data-gjs-name="heading" style="box-sizing: border-box;">
                      <td data-gjs-name="heading" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
            <table data-gjs-name="heading" align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="heading">
            <tr data-gjs-name="heading">
            <![endif]-->
                          <!--[if mso]>
            <td data-gjs-name="heading" valign="top" width="600" style="width:600px;" data-gjs-name="heading">
            <![endif]-->
                          <table data-gjs-name="heading" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="heading" style="box-sizing: border-box;">
                                  <tr data-gjs-name="heading" style="box-sizing: border-box;">
                                      <td data-gjs-name="heading" valign="top" style="box-sizing: border-box; box-sizingƒ: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 26px; font-weight: 400; border: none; padding-top: 25px;">
                                          <div data-gjs-name="heading" style="box-sizing: border-box; font-family: Arial;">A spot for your header</div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="text" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="text">
            <tr data-gjs-name="text">
            <![endif]-->
                          <!--[if mso]>
            <td valign="top" width="600" style="width:600px;" data-gjs-name="text">
            <![endif]-->
                          <table data-gjs-name="text" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="text" style="box-sizing: border-box; font-family: Arial;">Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="image" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                      <td data-gjs-name="image" valign="top" style="box-sizing: border-box;">
                          <table data-gjs-name="image" align="left" width="100%" border="0" cellpadding="0" cellspacing="0"  style="box-sizing: border-box; min-width: 100%;">
                              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                                      <td data-gjs-name="image" valign="top" align="center" style="box-sizing: border-box; padding-top: 0; padding-bottom: 0; text-align: center;"><a data-gjs-name="image" style="box-sizing: border-box; display: block; padding: 18px; width: 100%; height: auto; max-width: 600px;"><img data-gjs-name="image" width="600" height="auto" src="https://source.unsplash.com/WLUHO9A_xik/1600x900" style="box-sizing: border-box; max-width: 564px; font-family: Arial;"></a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>

          <table dir="ltr" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                      <td valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 18px;">
                          <div style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400;">
                              Left box. Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                          </div>
                      </td>
                      <td valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 18px;">
                          <div style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400;">
                              Right box. Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                          </div>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="button" dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="button" style="box-sizing: border-box;">
                  <tr data-gjs-name="button" style="box-sizing: border-box;">
                      <td data-gjs-name="button" valign="top" align="center" style="box-sizing: border-box; padding-top: 18px; padding-right: 18px; padding-bottom: 18px; padding-left: 18px;">
                          <table data-gjs-name="button" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border-collapse: separate;">
                              <tbody data-gjs-name="button" style="box-sizing: border-box;">
                                  <tr data-gjs-name="button" style="box-sizing: border-box;">
                                      <td data-gjs-name="button" align="center" valign="middle" bgcolor="#2cce27" style="box-sizing: border-box; padding-top: 8px; padding-bottom: 8px; padding-left: 15px; padding-right: 15px; border-radius: 5px; background-color: #2cce27;"><a data-gjs-name="button" href="#" target="_blank" style="box-sizing: border-box; font-weight: 700; letter-spacing: normal; line-height: 40px; text-align: center; text-decoration: none; color: #FFFFFF; font-family: Arial;">Double Click To Change</a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="divider" dir="ltr" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="divider" style="box-sizing: border-box;">
                  <tr data-gjs-name="divider" style="box-sizing: border-box;">
                      <td data-gjs-name="divider" style="box-sizing: border-box; min-width: 100%; padding: 18px;">
                          <hr data-gjs-name="divider" style="box-sizing: border-box; height: 1px; border: none; color: #eee; background-color: #eee;">
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="footer" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
          <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="footer">
          <tr data-gjs-name="footer">
          <![endif]-->
                          <!--[if mso]>
          <td valign="top" width="600" style="width:600px;" data-gjs-name="footer">
          <![endif]-->
                          <table data-gjs-name="footer" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="footer" style="font-family: Arial; box-sizing: border-box; font-weight: 400; text-align: center; font-size: 11px; color: #555555; letter-spacing: normal;"><em data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;">Copyright © All rights reserved.</em><br data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;"><a data-gjs-name="footer" draggable="true" data-highlightable="1" href="\${unsubscribe}" target="_blank" style="box-sizing: border-box; font-family: Arial; color: #333333;">Unsubscribe from this list</a>.<br data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;"></div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
          </td>
          <![endif]-->
                          <!--[if mso]>
          </tr>
          </table>
          <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <!--[if gte mso 9]>
      <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" data-gjs-name="boxed-text">
      <![endif]-->
              <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                      <td valign="top" style="box-sizing: border-box;">
                          <!--[if gte mso 9]>
            <td align="center" valign="top" data-gjs-name="boxed-text">
            <![endif]-->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; min-width: 100%;">
                              <tbody style="box-sizing: border-box;">
                                  <tr style="box-sizing: border-box;"></tr>
                              </tbody>
                          </table>
                          <!--[if gte mso 9]>
            </td>
            <![endif]-->
                          <!--[if gte mso 9]>
                    </tr>
                    </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table><br style="box-sizing: border-box;"><br style="box-sizing: border-box;"><br style="box-sizing: border-box;">
          <!--END-->
          <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
      </center>
      <!--#END-->
      <br style="box-sizing: border-box;">
  </body>
  <style>
      @media only screen and (max-width: 480px) {
          .full {
              display: block;
              width: 100%;
          }
      }
  </style>
  </html>`,

  '12Column': `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" style="box-sizing: border-box;">
  
  <head>
      <!--BEGIN-->
      <!--[if gte mso 15]>
                        <xml>
                            <o:OfficeDocumentSettings>
                            <o:AllowPNG/>
                            <o:PixelsPerInch>96</o:PixelsPerInch>
                            </o:OfficeDocumentSettings>
                        </xml>
                        <![endif]-->
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1">
  
  </head>
  
  <body style="box-sizing: border-box; margin: 0; background-color: #fafafa;">
      <center style="box-sizing: border-box;">
          <!--[if (gte mso 9)|(IE)]>
                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                    <tr>
                    <td align="center" valign="top" width="600" style="width:600px;">
                    <![endif]-->
          <!--#BEGIN-->
          <br style="box-sizing: border-box;">
          <table data-gjs-name="image" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                      <td data-gjs-name="image" valign="top" style="box-sizing: border-box;">
                          <table data-gjs-name="image" align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; min-width: 100%;">
                              <tbody data-gjs-name="image" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image" style="box-sizing: border-box;">
                                      <td data-gjs-name="image" valign="top" align="center" style="box-sizing: border-box; padding-top: 0; padding-bottom: 0; text-align: center;"><a data-gjs-name="image" style="box-sizing: border-box; display: block; padding: 18px; width: 100%; height: auto; max-width: 600px;"><img data-gjs-name="image" width="600" height="auto" src="https://source.unsplash.com/assets/photo-1428279148693-1cf2163ed67d-9869bbd99114f8d100a48d67d1b8ec56c4171e661131714f2b570e6dcc0b8bb3.jpg" style="box-sizing: border-box; max-width: 564px;"></a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="blank-space" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="blank-space" style="box-sizing: border-box;">
                  <tr data-gjs-name="blank-space" style="box-sizing: border-box;">
                      <td data-gjs-name="blank-space" valign="top" style="box-sizing: border-box;">
                          <table data-gjs-name="blank-space" align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; min-width: 100%;">
                              <tbody data-gjs-name="blank-space" style="box-sizing: border-box;">
                                  <tr data-gjs-name="blank-space" style="box-sizing: border-box;">
                                      <td data-gjs-name="blank-space" valign="top" align="center" style="box-sizing: border-box; padding-top: 0; padding-bottom: 0; text-align: center;"></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="heading" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="heading" style="box-sizing: border-box;">
                  <tr data-gjs-name="heading" style="box-sizing: border-box;">
                      <td data-gjs-name="heading" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
            <table data-gjs-name="heading" align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="heading">
            <tr data-gjs-name="heading">
            <![endif]-->
                          <!--[if mso]>
            <td valign="top" width="600" style="width:600px;" data-gjs-name="heading">
            <![endif]-->
                          <table data-gjs-name="heading" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="heading" style="box-sizing: border-box;">
                                  <tr data-gjs-name="heading" style="box-sizing: border-box;">
                                      <td data-gjs-name="heading" valign="top" style="box-sizing: border-box; box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 26px; font-weight: 400; border: none; padding-top: 25px;">
                                          <div data-gjs-name="heading" style="box-sizing: border-box; font-family: Arial;">A spot for your header</div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="text" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="text">
            <tr data-gjs-name="text">
            <![endif]-->
                          <!--[if mso]>
            <td valign="top" width="600" style="width:600px;" data-gjs-name="text">
            <![endif]-->
                          <table data-gjs-name="text" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="text" style="box-sizing: border-box;">
                                  <tr data-gjs-name="text" style="box-sizing: border-box;">
                                      <td data-gjs-name="text" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="text" style="box-sizing: border-box; font-family: Arial;">Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
            </td>
            <![endif]-->
                          <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="divider" dir="ltr" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="divider" style="box-sizing: border-box;">
                  <tr data-gjs-name="divider" style="box-sizing: border-box;">
                      <td data-gjs-name="divider" style="box-sizing: border-box; min-width: 100%; padding: 18px;">
                          <hr data-gjs-name="divider" style="box-sizing: border-box; height: 1px; border: none; color: #eee; background-color: #eee;">
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="image-text" dir="ltr" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="image-text" style="box-sizing: border-box;">
                  <tr data-gjs-name="image-text" style="box-sizing: border-box;">
                      <td data-gjs-name="image-text" valign="top" width="50%" dir="ltr" align="center" class="full" style="box-sizing: border-box; padding: 18px; text-align: center;">
                          <table data-gjs-name="image-text" align="left" border="0" cellpadding="0" cellspacing="0" width="100%"  style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="image-text" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image-text" style="box-sizing: border-box;">
                                      <td data-gjs-name="image-text" style="box-sizing: border-box;"><a data-gjs-name="image-text" style="box-sizing: border-box; display: inline-block; padding: 0px 18px 18px 18px; width: 100%;"><img data-gjs-name="image-text" width="300" height="auto" src="https://source.unsplash.com/weekly?business" style="box-sizing: border-box; display: block; font-family: Arial; margin-top: auto; margin-right: auto; margin-bottom: auto; margin-left: auto; width: 100%; height: auto;"></a></td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                      <td data-gjs-name="image-text" valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 0px 18px 18px 18px;">
                          <table data-gjs-name="image-text" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="image-text" style="box-sizing: border-box;">
                                  <tr data-gjs-name="image-text" style="box-sizing: border-box;">
                                      <td data-gjs-name="image-text" style="box-sizing: border-box;">
                                          <div data-gjs-name="image-text" style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">Insert your text in this block. You can use it to add text to your template.<br style="box-sizing: border-box;"><br style="box-sizing: border-box;"> Edit the image by double clicking, selecting or uploading an image.</div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="divider" dir="ltr" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="divider" style="box-sizing: border-box;">
                  <tr data-gjs-name="divider" style="box-sizing: border-box;">
                      <td data-gjs-name="divider"style="box-sizing: border-box; min-width: 100%; padding: 18px;">
                          <hr data-gjs-name="divider" style="box-sizing: border-box; height: 1px; border: none; color: #eee; background-color: #eee;">
                      </td>
                  </tr>
              </tbody>
          </table>
          <table data-gjs-name="footer" dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box;">
                          <!--[if mso]>
          <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="footer">
          <tr data-gjs-name="footer">
          <![endif]-->
                          <!--[if mso]>
          <td valign="top" width="600" style="width:600px;" data-gjs-name="footer">
          <![endif]-->
                          <table data-gjs-name="footer" align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; max-width: 100%; min-width: 100%;">
                              <tbody data-gjs-name="footer" style="box-sizing: border-box;">
                                  <tr data-gjs-name="footer" style="box-sizing: border-box;">
                                      <td data-gjs-name="footer" valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border: none;">
                                          <div data-gjs-name="footer" style="font-family: Arial; box-sizing: border-box; font-weight: 400; text-align: center; font-size: 11px; color: #555555; letter-spacing: normal;"><em data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;">Copyright © All rights reserved.</em><br data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;"><a draggable="true" data-highlightable="1" href="\${unsubscribe}" target="_blank" style="box-sizing: border-box; font-family: Arial; color: #333333;">Unsubscribe from this list</a>.<br data-gjs-name="footer" draggable="true" data-highlightable="1" style="box-sizing: border-box;"></div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <!--[if mso]>
          </td>
          <![endif]-->
                          <!--[if mso]>
          </tr>
          </table>
          <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table>
          <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" bgcolor="#ffffff" style="box-sizing: border-box; max-width: 600px; margin: auto; background-color: #ffffff;">
              <!--[if gte mso 9]>
      <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" data-gjs-name="boxed-text">
      <![endif]-->
              <tbody style="box-sizing: border-box;">
                  <tr style="box-sizing: border-box;">
                      <td valign="top" style="box-sizing: border-box;">
                          <!--[if gte mso 9]>
            <td align="center" valign="top" data-gjs-name="boxed-text">
            <![endif]-->
                          <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="box-sizing: border-box; min-width: 100%;">
                              <tbody style="box-sizing: border-box;">
                                  <tr style="box-sizing: border-box;"></tr>
                              </tbody>
                          </table>
                          <!--[if gte mso 9]>
            </td>
            <![endif]-->
                          <!--[if gte mso 9]>
                    </tr>
                    </table>
            <![endif]-->
                      </td>
                  </tr>
              </tbody>
          </table><br style="box-sizing: border-box;">
          <!--END-->
          <!--[if (gte mso 9)|(IE)]>
                    </td>
                    </tr>
                    </table>
                    <![endif]-->
      </center>
      <!--#END-->
      <br style="box-sizing: border-box;">
  </body>
  <style>
      @media only screen and (max-width: 480px) {
          .full {
              display: block;
              width: 100%;
          }
      }
  </style>
  </html>`
}



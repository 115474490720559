import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import ace from 'ace'
import React from 'react';
import ReactDOM from 'react-dom';
import jszip from 'jszip';

import app from 'js/app'
import vent from 'js/vent'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import backboneSelect2 from 'js/widgets/backbone-select2'
import IndividualsCollection from 'js/collections/contacts'
import CustomFieldsCollection from 'js/collections/custom_fields'
import UsersCollection from 'js/collections/users.js'
import GroupsCollection from 'js/collections/groups'
import CampaignsCollection from 'js/collections/campaigns'
import IndividualModel from 'js/models/contact'
import CampaignModel from 'js/models/campaign'
import OrganizationModel from 'js/models/organization'
import OpportunityModel from 'js/models/opportunity'
import ContentFolderModel from 'js/models/content_folder'
import MessageBox from 'js/views/message_box'
import Utilities from 'js/utils/utilities'
import htmlSanitizer from 'js/utils/html-sanitizer'
import segmentsCalculator from 'js/utils/segments_calculator'
import PaginatedListView from 'js/views/base/paginated_list'
import IndividualItemView from 'js/views/individuals/item'
import TimeZoneSelectView from 'app/widgets/time-zone-selector/time-zone-selector'
import DateTimePicker from 'js/widgets/date-time-picker'
import dateFormat from 'js/utils/date-format'
import LibraryBrowser from 'js/react_views/library-browser/browser'
import GrapesJsEditor from 'js/react_views/grapesjs-editor/grapesjs-editor'
import SEE from 'js/react_views/see/see'
import BasicTemplates from 'app/content/campaigns/_components/campaign-creation/basic-templates.js'
import SeeBasicTemplates from 'js/react_views/see/see_basic_templates'
import campaignCreationTemplate from 'app/content/campaigns/_components/campaign-creation/campaign-creation.handlebars'
import step1Template from 'app/content/campaigns/_components/campaign-creation/step-1.handlebars'
import step2ATemplate from 'app/content/campaigns/_components/campaign-creation/step-2-a.handlebars'
import step2BTemplate from 'app/content/campaigns/_components/campaign-creation/step-2-b.handlebars'
import step2CTemplate from 'app/content/campaigns/_components/campaign-creation/step-2-c.handlebars'
import step2DTemplate from 'app/content/campaigns/_components/campaign-creation/step-2-d.handlebars'
import step3Template from 'app/content/campaigns/_components/campaign-creation/step-3.handlebars'
import step4Template from 'app/content/campaigns/_components/campaign-creation/step-4.handlebars'


const EMAIL_MAX_ATTACHS_SIZE_MB = 19;
const EMAIL_MAX_ATTACHS_SIZE_BYTES = EMAIL_MAX_ATTACHS_SIZE_MB * 1024 * 1024;

var THIRD_PARTY_MAIL_MAPPED_TAGS = [
    {
      mcTag: ["{{ contact.FIRSTNAME }}", "%FIRSTNAME%", "{{ fname }}", "[firstname,fallback=]", "*|FNAME|*"],
      ssTag: '${individual.first_name}'
    },
    {
      mcTag: ["{{ contact.LASTNAME }}", "%LASTNAME%", "{{ lname }}", "[lastname,fallback=]", "*|LNAME|*"],
      ssTag: '${individual.last_name}'
    },
    {
      mcTag: ["%EMAIL%", "[email]", "{{ email }}", "*|EMAIL|*"],
      ssTag: '${individual.email}'
    },
    {
      mcTag: ['*|PHONE|*'],
      ssTag: '${individual.phone}'
    },
    {
      mcTag: ["%UNSUBSCRIBELINK%", "[unsubscribe]", "{{ unsub_url }}", "*|UNSUB|*"],
      ssTag: '${unsubscribe-url}'
    },
    {
      mcTag: ['*|UPDATE_PROFILE|*'],
      ssTag: '${unsubscribe-url}'
    },
    {
      mcTag: ['*|USER:COMPANY|*'],
      ssTag: '${individual.organization.name,default=company}'
    },
    {
      mcTag: ["%WEBCOPY%", "[websersion]", "*|ARCHIVE|*"],
      ssTag: '${vib-url}'
    },
    {
      mcTag: ['*|MC:SUBJECT|*'],
      ssTag: ''
    },
    {
      mcTag: ['*|MC_PREVIEW_TEXT|*'],
      ssTag: ''
    },
    {
      mcTag: ['<!--*|IF:MC_PREVIEW_TEXT|*-->'],
      ssTag: ''
    },
    {
      mcTag: ['<!--*|END:IF|*-->'],
      ssTag: ''
    }
];

var replaceMailTags = function(content) {
    for (const tags of THIRD_PARTY_MAIL_MAPPED_TAGS) {
        var ssTag = tags.ssTag;

        for (const tag of tags.mcTag) {
            if(tag.includes("fallback")) {
                const indexOfEquals = tag.indexOf("=") + 1;
                const startOfTag = tag.substring(0, indexOfEquals);
                const endOfTag = tag.substring(indexOfEquals);

                var defaultValue = content.substring(
                    content.indexOf(startOfTag) + startOfTag.length,
                    content.indexOf(endOfTag)
                );

                if (defaultValue.length > 0) {
                    defaultValue = ",default=" + defaultValue;

                    ssTag = ssTag.substring(0, ssTag.indexOf("}")) + defaultValue + ssTag.substring(ssTag.indexOf("}"), ssTag.length);
                }
            }
            content = Utilities.replaceAll(content, tag, ssTag);
        }
    }

    return content;
}

var processZipFile = function(zipFile, parent, onSuccess) {
    const mbProgressContent = {
        icon: 'icon-info',
        message: "Importing files..." +
            "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
            "<div class='mb-wait-cont'>0% complete</div>"
    };

    const handle = MessageBox.showNoBtn(
        mbProgressContent,
        parent,
        {
            staticRegion: true
        }
    );

    let htmlContent = '';
    let imageFiles = [];
    let numFilesToDecompress = 0;
    let folderId = null;
    let progressBar = handle.messageBox.$el.find('.mb-progress-bar');
    let completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

    progressBar.width('0%');
    completeMessage.text('0% complete');

    const checkReady = function() {
        if (numFilesToDecompress > 0 || !folderId) {
            return;
        }

        if (!htmlContent || imageFiles.length === 0) {
            handle.reset();

            MessageBox.showOk({
                message: 'Import failed<br>The ZIP file does not contain the required data. The ZIP file must contain a valid .html file and associated images',
                icon: 'icon-warning'
            }, parent);

            return;
        }

        // change the images local paths to file hosting paths
        const srcTag = 'src="';
        let idxStart = 0;
        let toReplace = {};

        while (true) {
            const srcStartIdx = htmlContent.indexOf(srcTag, idxStart);

            if (srcStartIdx === -1) {
                break;
            }

            const srcEndIdx = htmlContent.indexOf('"', srcStartIdx + srcTag.length);

            if (srcEndIdx === -1) {
                break;
            }

            const src = htmlContent.substring(srcStartIdx + srcTag.length, srcEndIdx);

            // is the src path local?
            if (src.indexOf('http') !== 0 && !toReplace[src]) {
                const imageDef = imageFiles.find(i => i.sourceName.indexOf(src) !== -1);

                if (imageDef) {
                    toReplace[src] = {
                        name: imageDef.targetName,
                        file: imageDef.file
                    };
                }
            }

            idxStart = srcEndIdx + 1;
        }

        const numFilesToUpload = _.keys(toReplace).length;

        if (AppConfig.getValue('campaigns.add_vib_link')) {
            htmlContent = htmlContent.replace(/> (.*?) <\/webversion>/g, '><a href=${vib-url} target="_blank" rel="noreferrer">View this email in your browser</a></webversion>');
            // hack: support missing >
            htmlContent = htmlContent.replace(/> (.*?) <\/webversion/g, '><a href=${vib-url} target="_blank" rel="noreferrer">View this email in your browser</a></webversion>');
            htmlContent = htmlContent.replace('#transparent', '#e5e5e5');
            htmlContent = htmlContent.replace(/<unsubscribe>(.*?)<\/unsubscribe>/g, '<unsubscribe><a href=${unsubscribe-url} target="_blank" rel="noreferrer">unsubscribe here</a></unsubscribe>');
        }

        // replace &quot; encoded char
        htmlContent = Utilities.replaceAll(htmlContent, '&quot;', "'");
        htmlContent = replaceMailTags(htmlContent);

        if (numFilesToUpload === 0) {
            handle.reset();
            onSuccess(htmlContent);
        } else {
            let numFilesUploaded = 0;

            for (const src in toReplace) {
                const replaceInfo = toReplace[src];
                let formData = new FormData();

                formData.append('parent_id', folderId);
                formData.append('file', replaceInfo.file, replaceInfo.name);

                $.ajax({
                    type: 'POST',
                    url: '/content_files',
                    contentType: false,
                    processData: false,
                    data: formData
                }).done(function(result) {
                    htmlContent = Utilities.replaceAll(htmlContent, src, result.url);

                    ++numFilesUploaded;

                    if (numFilesUploaded === numFilesToUpload) {
                        handle.reset();
                        onSuccess(htmlContent);
                    } else {
                        const pct = (numFilesUploaded / numFilesToUpload) * 100;
                        progressBar.width(pct + '%');
                        completeMessage.text(Math.floor(pct) + '% complete');
                    }
                });
            }
        }
    }

    const publicFolder = new ContentFolderModel({ id: 'public', is_public: true });

    publicFolder.fetch({
        success: function() {
            const assetsFolder = (publicFolder.get('child_folders') || []).find(c => c.name === 'Assets');
            let zipAssetsFolderId = null;

            if (assetsFolder) {
                zipAssetsFolderId = assetsFolder.id;
            }

            const createCampaignFolder = function() {
                // get and update the prefix incrementer for the campaigns
                const clientPreferences = app.user.get('client').preferences || {};
                const campaignIncrementer = parseInt(clientPreferences['campaign_prefix_incrementer'] || '1');
                let campaignName = parent.campaignData.name.toLowerCase();

                campaignName = encodeURIComponent(Utilities.replaceAll(campaignName, ' ', '-'));

                const folderName = `cbl${campaignIncrementer}-${campaignName}`;

                clientPreferences['campaign_prefix_incrementer'] = (campaignIncrementer + 1).toString();

                $.ajax({
                    type: 'PATCH',
                    url: '/clients/1',
                    dataType: 'json',
                    data: JSON.stringify({
                        preferences: clientPreferences
                    })
                });

                const folder = new ContentFolderModel();

                folder.save({
                    name: folderName,
                    description: `Files imported from ${zipFile.name}`,
                    type: 'folder',
                    parent_id: zipAssetsFolderId
                }, {
                    validate: true,
                    alert: false,
                    success: function() {
                        folderId = folder.get('id');
                        checkReady();
                    }
                });
            }

            // get (or create) the 'Assets' folder

            if (!zipAssetsFolderId) {
                const zipAssetsFolder = new ContentFolderModel();

                zipAssetsFolder.save({
                    name: 'Assets',
                    description: 'Uploaded ZIP Email Campaign Assets',
                    type: 'folder',
                    parent_id: publicFolder.get('id'),
                    protected: true
                }, {
                    validate: true,
                    alert: false,
                    success: function() {
                        zipAssetsFolderId = zipAssetsFolder.get('id');
                        createCampaignFolder();
                    }
                });
            } else {
                createCampaignFolder();
            }


        }
    });

    jszip.loadAsync(zipFile).then(function(data) {
        for (const fname in data.files) {
            if (fname.indexOf('__MACOSX') === 0) { // discard macos system files
                continue;
            }

            const file = data.files[fname];

            if (!file.dir) {
                const fext = fname.split('.').pop().toLowerCase();

                if (fext === 'html') {
                    ++numFilesToDecompress;

                    file.async('string').then(function(content) {
                        htmlContent = content;
                        --numFilesToDecompress;
                        checkReady();
                    });
                } else if (Utilities.getTypeIcon(`.${fext}`).type === 'image-file') {
                    ++numFilesToDecompress;

                    file.async('blob').then(function(content) {
                        imageFiles.push({
                            sourceName: fname,
                            targetName: `${fname.split('/').pop()}`,
                            file: content,
                        });

                        --numFilesToDecompress;
                        checkReady();
                    });
                }
            }
        }
    });
}

var splitSymbol = ',default='; // shouldn't contain regexp special chars

var Step1 = Marionette.ItemView.extend({
    className: 'step step-1',
    template: Handlebars.compile(step1Template),
    templateHelpers: function() {
        return {
            textMessagingEnabled: AppConfig.getValue('enableTextMessaging')
        };
    },
    ui: {
        directEmailSelector: '.direct-email',
        campaignEmailSelector: '.campaign-email',
        textMessageSelector: '.text-message',
        selectors: '.option',
        campaignName: '.campaign-name',
        tagsInput: '.tags-input',
        funnelsInput: '.funnels-input'
    },
    events: {
        'click .direct-email': function() {
            var self = this;
            this.options.parent.addChange('campaign_type', 'direct');
            this.options.parent.addChange('include_signature', true);
            this.ui.selectors.removeClass('selected');
            this.ui.directEmailSelector.addClass('selected');
            this.options.parent.nextStep = '2A';
            this.options.parent.campaignData.to_group = null;
            this.options.parent.campaignData.cc = null;
            this.options.parent.campaignData.subject = '';
            this.options.parent.campaignData.content = '';
            this.options.parent.campaignData.attached_files_id = [];
            this.options.parent.campaignData.attached_files = [];
            this.options.parent.campaignData.see_config = null;
            this.options.parent.$el.find('.dot-2.visited').off('click').on('click', function () {
                self.options.parent.showStep('2A');
            });
            this.options.parent.$el.find('.dot-4.visited').off('click').removeClass('visited');
            this.options.parent.addChange('last_page', Math.min(this.options.parent.campaignData.last_page, 3));
        },
        'click .campaign-email': function() {
            var self = this;
            this.options.parent.addChange('campaign_type', 'campaign');
            this.options.parent.addChange('include_signature', false);
            this.ui.selectors.removeClass('selected');
            this.ui.campaignEmailSelector.addClass('selected');
            if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4382')) {
                this.options.parent.nextStep = '2D';
            } else {
                this.options.parent.nextStep = '2B';
            }
            this.options.parent.campaignData.to_group = null;
            this.options.parent.campaignData.cc = null;
            this.options.parent.campaignData.subject = '';
            this.options.parent.campaignData.content = '';
            this.options.parent.campaignData.attached_files_id = [];
            this.options.parent.campaignData.attached_files = [];
            this.options.parent.campaignData.see_config = null;
            this.options.parent.$el.find('.dot-2.visited').off('click').on('click', function () {
                if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4382')) {
                    self.options.parent.showStep('2D');
                } else {
                    self.options.parent.showStep('2B');
                }
            });
            this.options.parent.$el.find('.dot-4.visited').off('click').removeClass('visited');
            this.options.parent.addChange('last_page', Math.min(this.options.parent.campaignData.last_page, 3));
        },
        'click .text-message': function() {
            var self = this;
            this.options.parent.addChange('campaign_type', 'message');
            this.options.parent.addChange('include_signature', false);
            this.ui.selectors.removeClass('selected');
            this.ui.textMessageSelector.addClass('selected');
            this.options.parent.nextStep = '2C';
            this.options.parent.campaignData.to_group = null;
            this.options.parent.campaignData.cc = null;
            this.options.parent.campaignData.subject = '';
            this.options.parent.campaignData.content = '';
            this.options.parent.campaignData.attached_files_id = [];
            this.options.parent.campaignData.attached_files = [];
            this.options.parent.campaignData.see_config = null;
            this.options.parent.$el.find('.dot-2.visited').off('click').on('click', function () {
                self.options.parent.showStep('2C');
            });
            this.options.parent.$el.find('.dot-4.visited').off('click').removeClass('visited');
            this.options.parent.addChange('last_page', Math.min(this.options.parent.campaignData.last_page, 3));
        },
        'keyup .campaign-name': function() {
            this.options.parent.addChange('name', this.ui.campaignName.val());

            // remove error message
            if (this.options.parent.campaignData.name) {
                this.validate(this.options.parent.campaignData);
            }
        }
    },
    initialize: function() {
        this.options.parent.nextStep = '2A';
    },
    onRender: function() {
        if ('name' in this.options.parent.campaignData) {
            this.ui.campaignName.val(this.options.parent.campaignData.name)
        }
        if (this.options.parent.campaignData.campaign_type) {
            this.ui.selectors.removeClass('selected');

            switch (this.options.parent.campaignData.campaign_type) {
                case 'campaign':
                    this.ui.campaignEmailSelector.addClass('selected');
                    if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4382')) {
                        this.options.parent.nextStep = '2D';
                    } else {
                        // todo: remove 2B view when 2D be enable for all the clients
                        this.options.parent.nextStep = '2B';
                    }
                    break;
                case 'direct':
                    this.ui.directEmailSelector.addClass('selected');
                    this.options.parent.nextStep = '2A';
                    break;
                case 'message':
                    this.ui.textMessageSelector.addClass('selected');
                    this.options.parent.nextStep = '2C';
                    break;
            }
        }

        this.tagsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.tagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,
            createSearchChoice: true,
            options: {
                placeholder:'+ Add Tag',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        this.funnelsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.funnelsInput,
            id: 'id',
            text: 'name',
            url: '/funnels',
            search: true,
            createSearchChoice: true,
            options: {
                placeholder: `+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        let tags = [], funnels = [];

        if ('tags' in this.options.parent.campaignData) {
            tags = this.options.parent.campaignData.tags;
        } else {
            tags = app.user.getIndividualPreloadedTags();
        }
        
        this.options.parent.campaignData.tags = tags;
        this.tagsSelect.setValue(this.options.parent.campaignData.tags);

        this.listenTo(this.tagsSelect, 'change', function(items) {
            var tags = _.map(items, function(item) { return { id: item.id, name: item.name }});

            this.options.parent.addChange('tags', tags);
        });

        if ('funnels' in this.options.parent.campaignData) {
            funnels = this.options.parent.campaignData.funnels;
        } else {
            funnels = app.user.getIndividualPreloadedFunnels();
        }
        this.options.parent.campaignData.funnels = funnels;
        this.funnelsSelect.setValue(this.options.parent.campaignData.funnels);

        this.listenTo(this.funnelsSelect, 'change', function(items) {
            var funnels = _.map(items, function(item) { return { id: item.id, name: item.name }});

            this.options.parent.addChange('funnels', funnels);
        });

        this.options.parent.updateTitle(TextManager.getText('ID_CREATE_ENTITY', ['${ID_CAMPAIGN, capitalize}']), TextManager.getText('ID_CHOOSE_CAMPAIGN_TYPE'))
    },
    onShow: function() {
        this.ui.campaignName.focus();
    },
    /**
     * This function is used both as static and member function.
     *
     * @param campaignData  campaign data is passed explicitly
     * @returns {boolean}
     */
    validate: function(campaignData) {
        if (!campaignData.name || campaignData.name.length === 0) {

            if (this.$el) {
                this.$el.find('.name-shake').effect("shake");
                this.$el.find('.error-1').show();
                this.ui.campaignName.addClass('error-input');
                this.ui.campaignName.focus();
            }
            return false;
        }
        if (this.$el) {
            this.$el.find('.error').hide();
            this.ui.campaignName.removeClass('error-input');
        }
        return true;
    }
});

var getTagItems = function(options, callback) {
    var items = [];
    if (options.includeUnsubscribe) {
        items = [
            {
                name: 'ESSENTIALS',
                title: true
            },
            {
                name: 'Unsubscribe',
                tag: '${unsubscribe-url}',
                customInsertTag: '<a href=${unsubscribe-url}>Unsubscribe</a>',
                parse: function () {
                    return 'javascript:void(null);'
                }
            },
            {
                name: 'View in browser',
                tag: '${vib-url}',
                customInsertTag: '<a href=${vib-url}>View in browser</a>',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }
        ];
    }
    var individualItems = [
        {
            name: 'INDIVIDUAL FIELDS',
            title: true
        },
        {
            name: 'Full Name',
            tag: '${individual.name}',
            parse: function(model) {
                return model.get('full_name');
            }
        },
        {
            name: 'First Name',
            tag: '${individual.first_name}',
            parse: function(model) {
                return model.get('first_name');
            }
        },
        {
            name: 'Last Name',
            tag: '${individual.last_name}',
            parse: function(model) {
                return model.get('last_name');
            }
        },
        {
            name: 'Email',
            tag: '${individual.email}',
            parse: function(model) {
                return _.find(model.get('communication'), function(item){ return item.medium === 'email'; }).value;
            }
        },
        {
            name: 'Phone',
            tag: '${individual.phone}',
            parse: function(model) {
                return _.find(model.get('communication'), function(item){ return item.medium === 'phone'; }).value;
            }
        },
        {
            name: TextManager.getText('ID_JOB_ROLE'),
            tag: '${individual.job_role}',
            parse: function(model) {
                return model.get('role');
            }
        }
    ];
    var organizationItems = [
        {
            name: TextManager.parseText('${ID_ORGANIZATION, uppercase} FIELDS'),
            title: true
        },
        {
            name: 'Name',
            tag: '${organization.name}',
            parse: function(model) {
                if (model.get('organization')) {
                    return model.get('organization').name;
                }
                else {
                    return '';
                }
            }
        }
    ];
    var customFields = new CustomFieldsCollection();

    var customFieldBaseParse = function(type, customFieldValue, customField) {
        if (type === 'individual') {
            if (!customFieldValue) {
                return null;
            }
            var individual = new IndividualModel({id: customFieldValue});
            return function(callback) {
                return individual.fetch({
                    success: function() {
                        callback(individual.get('full_name'));
                    }
                });
            };
        }
        else if (type === 'organization') {
            if (!customFieldValue) {
                return null;
            }
            var organization = new OrganizationModel({id: customFieldValue});
            return function(callback) {
                return organization.fetch({
                    success: function() {
                        callback(organization.get('name'));
                    }
                });
            };
        }
        else if (type === 'opportunity') {
            if (!customFieldValue) {
                return null;
            }
            var opportunity = new OpportunityModel({id: customFieldValue});
            return function(callback) {
                return opportunity.fetch({
                    success: function() {
                        callback(opportunity.get('name'));
                    }
                });
            };
        }
        else if (type === 'dropDown') {
            return customFieldValue ? _.find(customField.get('value').options, function(option) {
                return option.id === customFieldValue;
            }).value : null;
        }
        else if (type === 'number') {
            return Utilities.numberWithCommas(customFieldValue);
        }
        else if (type === 'date') {
            return customFieldValue ? dateFormat.entityInformationFormat(customFieldValue) : '';
        }
        else {
            return customFieldValue;
        }
    };

    customFields.fetch({
        rows: -1,
        success: function() {
            customFields.each(function(customField) {
                var regExSafeName;
                if (customField.get('view') === 'individuals') {
                    regExSafeName = customField.get('name').replace(/\W/g, '');
                    individualItems.push({
                        name: customField.get('name'),
                        tag: '${individual.custom.' + customField.get('short_id') + '}',
                        //tag: '${individual.custom.' + customField.get('id') + '.' + regExSafeName + '}',
                        parse: function(model) {
                            var type = customField.get('type');
                            var customFieldValue = model.get('custom_fields')[customField.get('id')];

                            return customFieldBaseParse(type, customFieldValue, customField);
                        }
                    });
                }
                else if (customField.get('view') === 'organizations') {
                    regExSafeName = customField.get('name').replace(/\W/g, '');
                    organizationItems.push({
                        name: customField.get('name'),
                        tag: '${organization.custom.' + customField.get('short_id') + '}',
                        //tag: '${organization.custom.' + customField.get('id') + '.' + regExSafeName + '}',
                        parse: function(model) {
                            var type = customField.get('type');
                            var customFieldValue = model.get('organization') ? model.get('organization').custom_fields[customField.get('id')] : '';

                            return customFieldBaseParse(type, customFieldValue, customField);
                        }
                    });
                }
            });

            items = items.concat(individualItems);
            items = items.concat(organizationItems);

            callback(items);
        }
    });
};

var getTagMap = function(callback) {
    getTagItems({ includeUnsubscribe: true }, function(items) {
        var map = {};
        _.each(items, function(item) {
            if (item.tag) {
                map[item.tag] = item;
            }
        });
        callback(map);
    });
};

var TagItemView = Marionette.ItemView.extend({
    template: Handlebars.compile("<div class='{{#if title}}title{{else}}tag-item{{/if}}'>{{name}}</div>"),
    events: {
        'click .tag-item': function() {
            this.trigger(
                'tag:selected',
                {
                    name: this.model.get('name'),
                    tag: this.model.get('tag'),
                    customInsertTag: this.model.get('customInsertTag')
                }
            );
        }
    }
});

var MergeTagList = Marionette.CompositeView.extend({
    className: 'merge-tag-list',
    template: Handlebars.compile("<div class='content-container'><div class='content'></div></div>"),
    itemViewContainer: '.content',
    itemView: TagItemView,
    collection: new Backbone.Collection(),
    initialize: function() {
        var self = this;
        this.listenTo(this, 'itemview:tag:selected', function(model, item) {
            this.trigger('tag:selected', item)
        });
        var includeUnsubscribe = this.options.parent.campaignData.campaign_type === 'campaign';
        getTagItems({ includeUnsubscribe: includeUnsubscribe }, function(items) {
            self.collection.reset(items);
            self.trigger('tags:loaded');
        });
    },
    show: function() {
        var self = this;

        this.$el.addClass('opacity');

        this.$el.show();

        $('body').one('click.close-merge-tag-list', function closeView(ev) {
            if(!$(ev.target).is('.merge-tag-list') && !$(ev.target).closest('.merge-tag-list').length) {
                self.hide();
            }
        });

        this.shown = true;
    },
    hide: function() {
        this.$el.hide();
        $('body').off('click.close-merge-tag-list');

        this.shown = false;
    },
    toggle: function() {
        if (this.shown) {
            this.hide();
        }
        else {
            this.show();
        }
    }
});

function manageTagInsertion(self, item, callback) {
    if (item.customInsertTag) {
        callback(item.customInsertTag)
    }
    else {
        MessageBox.showOk(
            {
                icon: 'icon-warning',
                message: 'Leave blank or choose alternative when ' + item.name + ' is missing',
                accept_button_text: 'Continue'
            },
            self.options.parent,
            function(val) {
                var tag;
                if (val.toString().length > 0) {
                    tag = item.tag.slice(0, -1) + splitSymbol + val + '}';
                }
                else {
                    tag = item.tag;
                }
                callback(tag);
            },
            {
                type: 'input'
            }
        );
    }
}

var Step2A = Marionette.Layout.extend({
    className: 'step step-2-a',
    template: Handlebars.compile(step2ATemplate),
    templateHelpers: function() {
        var emailSignature = '';

        if (this.options.parent.campaignData.from_user) {
            emailSignature = this.options.parent.campaignData.from_user.email_signature || '';
        }

        return {
            signature: htmlSanitizer.sanitize(emailSignature),
            include_signature: this.options.parent.campaignData.include_signature,
            track: this.options.parent.campaignData.track,
            attachmentsAllowed: this.options.parent.campaignData.name.toLowerCase().indexOf('template') !== -1
        };
    },
    ui: {
        tagInput: '.tag-input',
        editor: '.tinymce-textarea',
        includeSignatureContainer: '.include-signature-container',
        includeSignature: '.include-signature',
        trackEmail: '.track-email',
        addMergeTag: '.add-merge-tag',
        subject: '.subject',
        signature: '.signature',
        tinyContainer: '.tiny-container',
        note1: '.note-1',
        note2: '.note-2',
        signatureLink: '.email-signature-link'
    },
    regions: {
        attachsContainer: '.attachs-container-region'
    },
    events: {
        'change .include-signature': function() {
            var checked = this.ui.includeSignature.is(":checked");
            this.options.parent.addChange('include_signature', checked);
            this.ui.signature.toggle(checked);
            this.setWHYSIWYGEditorHeight();

            if (this.options.parent.campaignData.from_user &&
                this.options.parent.campaignData.from_user.id === app.user.get('id') &&
                this.options.parent.campaignData.include_signature) {
                this.ui.signatureLink.show();
            }
            else {
                this.ui.signatureLink.hide();
            }
        },
        'change .track-email': function() {
            this.options.parent.addChange('track', this.ui.trackEmail.is(":checked"));
            if (this.ui.trackEmail.is(":checked")) {
                this.ui.note1.show();
                this.ui.note2.hide();
            }
            else {
                this.ui.note1.hide();
                this.ui.note2.show();
            }
        }
        ,
        'click .add-merge-tag': function(e) {
            e.stopPropagation(); // stop event so MergeTagList doesn't get closed
            this.mergeTagList.toggle();
        },
        'focus .subject': function() {
            this.tagTarget = 'subject';
        },
        // this get called from ".trigger('keyup');" as well
        'keyup .subject': function() {
            this.options.parent.addChange('subject', this.ui.subject.val());

            // remove error message
            if (this.options.parent.campaignData.subject && this.options.parent.campaignData.subject.trim()) {
                this.validate(this.options.parent.campaignData);
            }
        },
        'click .attach-file': function(ev) {
            if (!$(ev.target).hasClass('disabled')) {
                this.showLibraryBrowser(true);
            }
        }
    },
    initialize: function() {
        this.options.parent.nextStep = '3';
        this.attachsCollection = new Backbone.Collection();

        if (this.options.parent.campaignData.attached_files) {
            _.forEach(this.options.parent.campaignData.attached_files, (file) => this.attachsCollection.add(new Backbone.Model(file)));
        }
    },
    onRender: function() {
        this.initializeMergeTagList();

        if (this.ui.subject.length > 0) {
            this.ui.subject.val(this.options.parent.campaignData.subject);
        }

        this.options.parent.updateTitle('Compose Direct Email', this.options.parent.campaignData.name);

        if (this.options.parent.campaignData.from_user) {
            var signatureChecked = this.ui.includeSignature.is(":checked");
            this.ui.signature.toggle(signatureChecked);
        } else {
            this.ui.includeSignatureContainer.hide();
        }

        var trackChecked = this.ui.trackEmail.is(":checked");
        if (trackChecked) {
            this.ui.note1.show();
        }
        else {
            this.ui.note2.show();
        }

        if (this.options.parent.campaignData.from_user && this.options.parent.campaignData.from_user.id === app.user.get('id') &&
            this.options.parent.campaignData.include_signature) {
            this.ui.signatureLink.show();
        }
    },
    onShow: function() {
        this.initializeWHYSIWYGEditor();

        this.attachsContainer.show(new AttachsContainer({
            collection: this.attachsCollection
        }));

        var self = this;
        this.listenTo(this.attachsContainer.currentView, 'itemview:file-detached', function(childView) {
            self.attachsCollection.remove(childView.model);
            self.options.parent.addChange('attached_files_id', _.map(self.attachsCollection.models, (model) => model.get('id')));
            self.options.parent.campaignData.attached_files = _.map(self.attachsCollection.models, (model) => model.toJSON());
        });
    },
    initializeMergeTagList: function() {
        var self = this;
        this.mergeTagList = new MergeTagList({ parent: this.options.parent });
        this.mergeTagList.render();
        this.ui.addMergeTag.after(this.mergeTagList.$el);
        this.listenTo(this.mergeTagList, 'tags:loaded', function() {
            self.mergeTagList.$el.find('.content-container').nanoScroller();
            self.mergeTagList.$el.hide();
        });
    },
    initializeWHYSIWYGEditor: function() {
        var self = this;
        this.ui.editor.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            paste_as_text: true,
            toolbar: 'bold italic underline bullist numlist link',
            menubar: false,
            statusbar: false,
            resize: false,
            urlconverter_callback: function(url) {
                if (url === '${unsubscribe-url}') {
                    return url;
                }

                var re = new RegExp("^(ftps?|https?)://", "i");

                if (!re.test(url)) {
                    url = 'http://' + url;
                }

                return url;
            },
            extended_valid_elements: 'a[href|target=_blank|title]',
            target_list: false,
            oninit: function() {
                self.WYSIWYGEditor = this;
                self.setWHYSIWYGEditorHeight();

                self.WYSIWYGEditor.activeEditor.show();
                if (self.options.parent.campaignData.content) {
                    self.WYSIWYGEditor.activeEditor.setContent(self.options.parent.campaignData.content);
                    self.WYSIWYGEditor.activeEditor.undoManager.add();
                }
                self.ui.editor.tinymce().focus();

                self.listenTo(self.mergeTagList, 'tag:selected', function(item) {
                    manageTagInsertion(self, item, function(tag) {
                        if (self.tagTarget === 'subject') {
                            self.ui.subject.val(self.ui.subject.val() + tag).trigger('keyup');
                        }
                        else if (!self.tagTarget || self.tagTarget === 'editor') {
                            self.WYSIWYGEditor.execCommand('mceInsertContent', false, tag);
                        }
                        self.mergeTagList.toggle();
                    });
                });

                this.activeEditor.on('focus', function() {
                    self.tagTarget = 'editor';
                });

                this.activeEditor.on('click', function() {
                    self.mergeTagList.hide();
                });

                this.activeEditor.on('change', function() {
                    self.options.parent.addChange('content', self.WYSIWYGEditor.activeEditor.getContent());
                });

                this.resizeCallback = function () {
                    self.setWHYSIWYGEditorHeight();
                };
                $(window).on('resize', this.resizeCallback);
            }
        });
    },
    setWHYSIWYGEditorHeight: function() {
        const self = this;

        _.defer(function() {
            var container = $(self.ui.editor.tinymce().getContainer());
            var signatureHeight = self.ui.includeSignature.is(":checked") ? self.ui.signature.height() + 30 : 0; // 30 = 2 x 15 padding in signature div
            var secondLineHeight = self.$el.find('.second-line').height() || 0;
            var otherLineHeights = 122;
            var height = self.$el.height() - otherLineHeights - signatureHeight - secondLineHeight;

            self.ui.tinyContainer.height(height);
            container.height(height);
            self.WYSIWYGEditor.DOM.setStyle(self.WYSIWYGEditor.DOM.get('tinymce-textarea_ifr'), 'height', height + 'px');
        });
    },
    onFileSelected: function(file) {
        this.showLibraryBrowser(false);

        var totalAttachsSize = this.attachsCollection.models.reduce((total, c) => { return total + c.get('size') }, 0);

        if ((totalAttachsSize + file.size) > EMAIL_MAX_ATTACHS_SIZE_BYTES) {
            MessageBox.showOk({
                message: `Failed to attach the file because the total size exceeds the maximum limit. The maximum allowed limit for all attachments is ${EMAIL_MAX_ATTACHS_SIZE_MB}MB`,
                icon: 'icon-warning'
            }, this.options.parent);
        } else {
            this.attachsCollection.add(new Backbone.Model(file));

            this.options.parent.addChange('attached_files_id', _.map(this.attachsCollection.models, (model) => model.get('id')));
            this.options.parent.campaignData.attached_files = _.map(this.attachsCollection.models, (model) => model.toJSON());
        }
    },
    showLibraryBrowser: function(visible) {
        ReactDOM.render(
            <div>
                {visible &&
                    <LibraryBrowser
                        folderId='root'
                        ownerIsAll={true}
                        parent={this.options.parent}
                        onCancel={() => this.showLibraryBrowser(false)}
                        onFileSelected={this.onFileSelected.bind(this)}
                    />
                }
            </div>,
            this.$el.find('.library-browser').get(0)
        );
    },
    beforeSave: function() {
        this.options.parent.addChange('content', this.WYSIWYGEditor.activeEditor.getContent());
    },
    onClose: function() {
        this.WYSIWYGEditor.remove();
        $(window).off('resize', this.resizeCallback);
    },
    /**
     * This function is used both as static and member function.
     *
     * @param campaignData  campaign data is passed explicitly
     * @returns {boolean}
     */
    validate: function(campaignData) {
        // subject can't contain spaces only
        if (this.ui.subject.length > 0 && (!campaignData.subject || !campaignData.subject.trim())) {
            if (this.$el) {
                this.$el.find('.subject-shake').effect("shake");
                this.$el.find('.error-1').show();
                this.ui.subject.addClass('error-input');
                this.ui.subject.focus();

                this.setWHYSIWYGEditorHeight();
            }
            return false;
        }
        if (this.$el) {
            this.$el.find('.error').hide();
            this.ui.subject.removeClass('error-input');

            this.setWHYSIWYGEditorHeight();
        }
        return true;
    },
    confirm: function(callback) {
        var warnings = [];

        // find at least one mailchimp tag
        var mailchimpTag = this.options.parent.campaignData.content.match(/\*\|.{1,30}?\|\*/);

        if (mailchimpTag) {
            warnings.push('The email content contains Mailchimp merge tags.');
        }

        // look for replaceme tag
        var replaceMeTag = this.options.parent.campaignData.content.match(/\${REPLACEME}/);

        if (replaceMeTag) {
            warnings.push('The email content contains ${REPLACEME} merge tag.');
        }

        // is a campaign without unsubscribe url?
        if (this.options.parent.campaignData.campaign_type === 'campaign') {
            var unsubscribeUrl = this.options.parent.campaignData.content.match(/\${unsubscribe-url}/);

            if (!unsubscribeUrl) {
                warnings.push('The email content is missing SalesSeek unsubscribe link.');
            }
        }

        if (warnings.length > 0) {
            var warningsList = [];

            for (var warning of warnings) {
                warningsList.push(`<li style="margin-top: 10px">${warning}</li>`);
            }

            MessageBox.showYesNo(
                {
                    icon: 'icon-warning',
                    message: 'The email contains these warnings. Are you sure you want to proceed?' +
                             `<br><ul style="margin-top: 10px">${warningsList.join('')}</ul>`
                },
                this.options.parent,
                function() {
                    callback();
                }
            );
        } else {
            callback();
        }
    }
});

var TemplateItem = Marionette.ItemView.extend({
    className: 'template-item',
    template: Handlebars.compile(`
        <div id="template-item">
            <div class="template-item-image" style="background-image: url('img/{{img}}')"></div>
            <div class="template-item-name">{{name}}</div>
        </div>
    `),
    events: {
        'click #template-item': function() {
            this.trigger('selected', this.model);
        }
    },
    setSelected: function(selected) {
        this.$el.toggleClass('active', this.model, selected);
    }
});

var TemplatesList = Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'templates-list',
    itemView: TemplateItem,
    onRender: function() {
        this.selectTemplateByModel(this.children.findByModel(this.collection.models[0]));
    },
    initialize: function() {
        this.selectedItem = null;

        this.listenTo(this, 'itemview:selected', function(model) {
            this.selectTemplateByModel(model);
        });
    },
    selectTemplateByModel(model) {
        if (this.selectedItem) {
            this.selectedItem.setSelected(false);
        }

        this.selectedItem = model;
        this.selectedItem.setSelected(true);
    }
});

var UserTemplateItem = Marionette.ItemView.extend({
    className: 'user-template-item',
    template: Handlebars.compile(`
        <div id="user-template-item">
            <div class="user-template-item-inner-container">
                <div class="icon"><i class="icon-user-template"></i></div>
                <div class="content">
                    <div class="title">{{name}}</div>
                    <div class="created-by">Created by {{creator.name}}</div>
                    <div class="created-at">on {{formatDateTime created}}</div>
                </div>
            </div>
        </div>
    `),
    events: {
        'click #user-template-item': function() {
            this.trigger('selected', this.model);
        }
    },
    setSelected: function(selected) {
        this.$el.toggleClass('active', this.model, selected);
    }
});

var UserTemplatesList = Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'templates-list',
    itemView: UserTemplateItem,
    onRender: function() {
        this.selectTemplateByModel(this.children.findByModel(this.collection.models[0]));
    },
    initialize: function() {
        this.selectedItem = null;

        this.listenTo(this, 'itemview:selected', function(model) {
            this.selectTemplateByModel(model);
        });
    },
    selectTemplateByModel(model) {
        if (this.selectedItem) {
            this.selectedItem.setSelected(false);
        }

        this.selectedItem = model;
        this.selectedItem.setSelected(true);
    }
});

var NoUserTemplates = Marionette.Layout.extend({
    className: 'no-user-template-message',
    template: Handlebars.compile(`
        <span class="no-user-template-message">There are no user templates to select</span>
    `)
})

var Step2D = Marionette.Layout.extend({
    className: 'step step-2-d',
    template: Handlebars.compile(step2DTemplate),
    ui: {
        templateSelector: '.template-selector',
        grapesJsContainer: '.grapesjs-container',
        uploadZipFileInput: '#upload-zip-file-input'
    },
    regions: {
        templatesList: '.templates-list-container',
        userTemplatesList: '.user-templates-list-container',
        noUserTemplatesRegion: '.no-user-templates'
    },
    events: {
        'click .option': function(event) {
            var option = $(event.currentTarget).attr('option-id')
            this.selectOption(option);
        },
        'change #upload-zip-file-input': function(e) {
            var file = e.target.files[0];
            var self = this;

            if (['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'].indexOf(file.type) !== -1) {
                processZipFile(file, this.options.parent, function(htmlContent) {
                    let seeConfig = self.options.parent.campaignData.see_config || {};
                    seeConfig.from_zip = true;

                    self.editorVisible = true;
                    self.editorContent = htmlContent;
                    self.onContentChange(self.editorContent);
                    self.onSeeConfigChange(seeConfig);
                    self.render();
                });
            } else {
                MessageBox.showOk({
                    message: 'Import failed<br>This is not a valid ZIP file',
                    icon: 'icon-warning'
                }, this.options.parent);
            }
        }
    },
    initialize: function() {
        this.options.parent.nextStep = '3';
        this.selectedTemplate = null;
        this.editorVisible = this.options.parent.campaignData.content !== '';
        this.editorContent = this.options.parent.campaignData.content || '';
        this.editorSeeConfig = this.options.parent.campaignData.see_config || {};
        this.saveAsTemplate = this.options.parent.campaignData.is_template || false;
        this.activeOption = null;
        this.usingSEE = _.contains(app.user.get('preferences').lab_flags, 'SAL-4873');
        this.basicTemplates = [];

        if (this.usingSEE) {
            this.basicTemplates = _.clone(SeeBasicTemplates);
        } else {
            this.basicTemplates = [{
                id: '1Column',
                name: '1 Column - Simple',
                img: 'template-1.svg'
            }, {
                id: '1ColumnFW',
                name: '1 Column - Image',
                img: 'template-2.svg'
            }, {
                id: '12Column',
                name: '1:2 Column',
                img: 'template-3.svg'
            }];
        }

        this.userTemplates = null

        var campaigns = new CampaignsCollection();

        const self = this;
        campaigns.fetch({
            rows: -1,
            data: {
                is_template: true,
            },
            success: function(collection) {
                if (collection) {
                    self.userTemplates = collection.models;

                    if (self.usingSEE) {
                        self.userTemplates = self.userTemplates.filter(t => !!t.get('see_config'));
                    }
                }
            }
        });

        getTagItems({ includeUnsubscribe: true }, function(items) {
            self.mergeTagItems = items;

            if (self.editorVisible) {
                self.render();
            }
        });
    },
    onContentChange: function(content) {
        this.options.parent.addChange('content', content);
    },
    onSeeConfigChange: function(config) {
        this.options.parent.addChange('see_config', config);
    },
    handleSaveAsTemplateChange: function(saveAsTemplate) {
        this.options.parent.addChange('is_template', saveAsTemplate);
    },
    selectOption(option) {
        this.ui.templateSelector.find('.active').removeClass('active');
        this.ui.templateSelector.find(`.${option}`).addClass('active');
        this.activeOption = option;

        switch(option) {
            case 'import-zip':
                this.showTemplates();
                break;

            case 'basic-template':
                this.showTemplates(this.basicTemplates);
                break;

            case 'user-template':
                this.showUserTemplates(this.userTemplates);
                break;

            case 'blank-canvas':
                this.showTemplates();
                break;
        }
    },
    onShow: function() {
        if (!this.editorVisible) {
            if (AppConfig.getValue('campaigns.enable_import_zip_files')) {
                this.selectOption('import-zip');
            } else {
                this.selectOption('basic-template');
            }
        }
    },
    onRender: function() {
        this.options.parent.updateTitle(TextManager.parseText('Compose ${ID_CAMPAIGN, capitalize} Email'), this.options.parent.campaignData.name)

        this.ui.templateSelector.toggleClass('hidden', this.editorVisible);
        this.ui.grapesJsContainer.toggleClass('hidden', !this.editorVisible);

        if (this.mergeTagItems) {
            if (this.usingSEE) {
                const seeConfig = this.options.parent.campaignData.see_config || {};

                if (seeConfig.from_zip) {
                    this.options.parent.showStep('2B', false, false);
                } else {
                    ReactDOM.render(
                        <div>
                            <SEE
                                parent={this.options.parent}
                                mergeTagItems={this.mergeTagItems}
                                config={this.editorSeeConfig || {}}
                                userType={AppConfig.getValue('campaigns.user_type', 'advance')}
                                saveAsTemplate={this.saveAsTemplate}
                                onSeeConfigChange={this.onSeeConfigChange.bind(this)}
                                onContentChange={this.onContentChange.bind(this)}
                                onSaveAsTemplateChange={this.handleSaveAsTemplateChange.bind(this)}
                            />
                        </div>,
                        this.$el.find('.grapesjs-container').get(0)
                    );
                }
            } else {
                ReactDOM.render(
                    <div>
                        <GrapesJsEditor
                            parent={this.options.parent}
                            content={this.editorContent}
                            saveAsTemplate={this.saveAsTemplate}
                            mergeTagItems={this.mergeTagItems}
                            onChange={this.onContentChange.bind(this)}
                            onSaveAsTemplateChange={this.handleSaveAsTemplateChange.bind(this)}
                        />
                    </div>,
                    this.$el.find('.grapesjs-container').get(0)
                );
            }
        }
    },
    showUserTemplates: function(templates) {
        if (this.noUserTemplatesRegion.currentView){
            this.noUserTemplatesRegion.reset()
        }

        if (this.templatesList.currentView){
            this.templatesList.reset()
        }

        if (templates && templates.length > 0) {
            var collection = new Backbone.Collection(templates);

            this.userTemplatesList.show(new UserTemplatesList({
                collection: collection
            }));
        } else if (this.userTemplatesList.currentView) {
            this.userTemplatesList.reset();
        }

        if (templates && templates.length === 0) {
            this.noUserTemplatesRegion.show(new NoUserTemplates())
        }
    },
    showTemplates: function(templates) {

        if (this.noUserTemplatesRegion.currentView){
            this.noUserTemplatesRegion.reset()
        }

        if (this.userTemplatesList.currentView){
            this.userTemplatesList.reset()
        }

        if (templates && templates.length > 0) {
            var collection = new Backbone.Collection(templates);

            this.templatesList.show(new TemplatesList({
                collection: collection
            }));
        } else if (this.templatesList.currentView) {
            this.templatesList.reset();
        }
    },
    confirm: function() {
        if (this.editorVisible) {
            Step2A.prototype.confirm.apply(this, arguments);
        } else {
            switch(this.activeOption) {
                case 'import-zip':
                    this.ui.uploadZipFileInput.trigger('click');
                    break;

                case 'basic-template':
                    this.editorVisible = true;

                    if (this.usingSEE) {
                        const template = this.templatesList.currentView.selectedItem.model;
                        this.editorContent = template.get('content');
                        this.editorSeeConfig = template.get('see_config');
                        this.onContentChange(this.editorContent);
                        this.onSeeConfigChange(this.editorSeeConfig);
                    } else {
                        this.editorContent = BasicTemplates[this.templatesList.currentView.selectedItem.model.get('id')];
                        this.onContentChange(this.editorContent);
                    }
                    break;

                case 'user-template': {
                    const template = this.userTemplatesList.currentView.selectedItem.model;
                    this.editorVisible = true;

                    if (this.usingSEE) {
                        this.editorSeeConfig = template.get('see_config') || {};
                        this.onSeeConfigChange(this.editorSeeConfig);
                    }

                    this.editorContent = template.get('content') || '';
                    this.onContentChange(this.editorContent);
                }
                break;

                case 'blank-canvas':
                    this.editorVisible = true;
                    this.editorContent = `<!doctype html>
                    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                        <head>
                            <!--BEGIN-->
                            <!--[if gte mso 15]>
                            <xml>
                                <o:OfficeDocumentSettings>
                                <o:AllowPNG/>
                                <o:PixelsPerInch>96</o:PixelsPerInch>
                                </o:OfficeDocumentSettings>
                            </xml>
                            <![endif]-->
                            <meta charset="UTF-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <meta name="viewport" content="width=device-width, initial-scale=1">
                        </head>
                        <body>
                            <center>
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                            <tr>
                            <td align="center" valign="top" width="600" style="width:600px;">
                            <![endif]-->
                            <!--#BEGIN-->
                            <!--END-->
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                            </center>
                            <!--#END-->
                            <br>
                        </body>`;
                        this.onContentChange(this.editorContent);
                    break;
            }

            if (this.editorVisible) {
                this.render();
            }
        }
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.find('.grapesjs-container').get(0));
    }
});

var Step2B = Marionette.ItemView.extend({
    className: 'step step-2-b',
    template: Handlebars.compile(step2BTemplate),
    ui: {
        tagInput: '.tag-input',
        preview: '.preview',
        addMergeTag: '.add-merge-tag',
        subject: '.subject',
        resizeHandle: '.resize-handle',
        uploadFileInput: '#upload-file-input'
    },
    events: {
        'click .add-merge-tag': function(e) {
            e.stopPropagation(); // stop event so MergeTagList doesn't get closed
            this.mergeTagList.toggle();
        },
        'focus .subject': function() {
            this.tagTarget = 'subject';
        },
         // this get called from ".trigger('keyup');" as well
        'keyup .subject': function() {
            this.options.parent.addChange('subject', this.ui.subject.val());

            // remove error message
            if (this.options.parent.campaignData.subject) {
                this.validate(this.options.parent.campaignData);
            }
        },
        'change #upload-file-input': function(e) {
            var file = e.target.files[0];
            var self = this;

            if (this.uploadingFileType === 'html') {
                if (FileReader && file) {
                    var fileReader = new FileReader();

                    fileReader.onload = function(e) {
                        self.editor.setValue(e.target.result || '');
                    };
                    fileReader.readAsText(file);
                }
            } else if (this.uploadingFileType === 'zip') {
                if (['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'].indexOf(file.type) !== -1) {
                    processZipFile(file, this.options.parent, function(htmlContent) {
                        self.editor.setValue(htmlContent);
                    });
                } else {
                    MessageBox.showOk({
                        message: 'Import failed<br>This is not a valid ZIP file',
                        icon: 'icon-warning'
                    }, this.options.parent);
                }
            }
        },
        // catch esc on code editor to not close campaign view when hiding search
        'keyup .code-editor': function(e) {
            if (e.keyCode == 27) {
                e.stopPropagation();
            }
        },
        'click .upload-zip': function() {
            this.uploadingFileType = 'zip';
            this.ui.uploadFileInput.trigger('click');
        },
        'click .upload-html': function() {
            this.uploadingFileType = 'html';
            this.ui.uploadFileInput.trigger('click');
        }
    },
    initialize: function() {
        this.options.parent.nextStep = '3';
    },
    onRender: function() {
        var self = this;
        this.initializeMergeTagList();
        this.ui.subject.val(this.options.parent.campaignData.subject);

        this.$el.find('.first-col').resizable({
            handles: {
                'e': '.resize-handle'
            },
            start: function() {
                self.ui.preview.hide();

                var pw = self.$el.width();
                var minWidth = Math.max(100, pw * 0.15);
                self.$el.find('.first-col').resizable( "option", "minWidth",  minWidth);
                var maxWidth = Math.min(pw - 630, pw * 0.6);
                self.$el.find('.first-col').resizable( "option", "maxWidth",  maxWidth);
            },
            resize: function() {
                // adjust width while dragging
                var pw = self.$el.width();
                var fw = self.$el.find('.first-col').width();
                self.$el.find('.second-col').width(pw - fw - 94 - 5); // 5 - pixel perfection adjustment
                self.editor.resize();
            },
            stop: function() {
                self.ui.preview.show();

                // set percentage width, so window resizing would work nicely
                var pw = self.$el.width();
                var fw = self.$el.find('.first-col').width();
                var fpw = (fw / pw) * 100;
                var spw = 100 - fpw;
                self.$el.find('.first-col').width('calc(' + fpw + '% - 0px)');
                self.$el.find('.second-col').width('calc(' + spw + '% - 94px)');
            }
        });
        this.options.parent.updateTitle(TextManager.parseText('Compose ${ID_CAMPAIGN, capitalize} Email'), this.options.parent.campaignData.name)
    },
    onShow: function() {
        this.initializeEditor();
    },
    initializeMergeTagList: function() {
        var self = this;
        this.mergeTagList = new MergeTagList({ parent: this.options.parent });
        this.mergeTagList.render();
        this.ui.addMergeTag.after(this.mergeTagList.$el);
        _.defer(function() {
            self.mergeTagList.$el.find('.content-container').nanoScroller();
            self.mergeTagList.$el.hide();
        });
    },
    initializeEditor: function() {
        var self = this;
        var addChange = false;
        var defaultContent = ['<!-- \n    You can cut and then paste your HTML Email code here from your chosen source, \n and then edit if necessary too \n --> \n',
                              '<!DOCTYPE html> \n',
                              '<html> \n    <body> \n        <br><br><br> \n        <center> Sample Content </center> \n    </body> \n</html>'].join('');

        ace.config.setModuleUrl(
            "ace/mode/html_worker",
            "./vendor/ace-editor-1.2.3/src-noconflict/worker-html.js"
        );
        this.editor = ace.edit("code-editor");
        this.editor.setTheme("ace/theme/clouds");
        this.editor.getSession().setMode("ace/mode/html");
        this.editor.getSession().setUseWrapMode(true);
        this.editor.setValue(this.options.parent.campaignData.content || defaultContent);

        this.editor.on("input", function() {
            var text =  self.editor.getValue();

            // ignore initial change
            if (addChange) {
                self.options.parent.addChange('content', text);
            }
            addChange = true;

            self.ui.preview.contents().find('html').get(0).innerHTML = text;
        });

        this.editor.on('focus', function() {
            self.tagTarget = 'editor';
        });

        this.listenTo(self.mergeTagList, 'tag:selected', function(item) {
            manageTagInsertion(self, item, function(tag) {
                if (self.tagTarget === 'subject') {
                    self.ui.subject.val(self.ui.subject.val() + tag).trigger('keyup');
                }
                else if (!self.tagTarget || self.tagTarget === 'editor') {
                    self.editor.insert(tag);
                }
                self.mergeTagList.toggle();
            });
        });
    },
    beforeSave: function() {
        //this.options.parent.addChange('content', this.WYSIWYGEditor.activeEditor.getContent());
    },
    onClose: function() {
        //this.options.parent.addChange('content', this.WYSIWYGEditor.activeEditor.getContent());
        //this.WYSIWYGEditor.remove();
    },
    /**
     * This function is used both as static and member function.
     *
     * @param campaignData  campaign data is passed explicitly
     * @returns {boolean}
     */
    validate: function(campaignData) {
        if (!campaignData.subject) {
            if (this.$el) {
                this.$el.find('.subject-shake').effect("shake");
                this.$el.find('.error-1').show();
                this.ui.subject.addClass('error-input');
                this.ui.subject.focus();

                this.$el.find('.third-line').addClass('reduced');
            }
            return false;
        }
        if (this.$el) {
            this.$el.find('.error').hide();
            this.ui.subject.removeClass('error-input');

            this.$el.find('.third-line').removeClass('reduced');
        }
        return true;
    },
    confirm: Step2A.prototype.confirm
});

var AttachFile = Marionette.ItemView.extend({
    template: Handlebars.compile(`
        <div class="attached-file" title="{{name}}">
            <span>{{name}}</span>
            <div class="deattach-file">×</div>
        </div>
    `),
    events: {
        'click .deattach-file': function() {
            this.trigger('file-detached');
        }
    },
    templateHelpers: function() {
        return {
            name: this.model.get('name')
        };
    }
});

var AttachsContainer = Marionette.CollectionView.extend({
    tagName: 'div',
    className: 'attachs-container',
    getItemView: function() {
        return AttachFile;
    }
});

var Step2C = Marionette.Layout.extend({
    className: 'step step-2-c',
    template: Handlebars.compile(step2CTemplate),
    templateHelpers: function() {
        return {
            maxLength: app.globalData.smsInfo.messageMaxLength,
            attachmentsAllowed: app.globalData.smsInfo.attachmentsAllowed
        };
    },
    ui: {
        tagInput: '.tag-input',
        addMergeTag: '.add-merge-tag',
        textArea: '.textarea',
        charactersLeft: '.characters-left',
        numSegments: '.num-segments',
        attachFile: '.attach-file'
    },
    regions: {
        attachsContainer: '.attachs-container-region'
    },
    events: {
        'click .add-merge-tag': function(e) {
            e.stopPropagation(); // stop event so MergeTagList doesn't get closed
            this.mergeTagList.toggle();
        },
        'click .attach-file': function(ev) {
            if (!$(ev.target).hasClass('disabled')) {
                this.showLibraryBrowser(true);
            }
        },
        'keyup .textarea': function(e) {
            this.processMessage(e.target.value, true);
        }
    },
    initialize: function() {
        this.maxNumCharacters = app.globalData.smsInfo.messageMaxLength;
        this.options.parent.nextStep = '3';
        this.attachsCollection = new Backbone.Collection();

        if (this.options.parent.campaignData.attached_files) {
            _.forEach(this.options.parent.campaignData.attached_files, (file) => this.attachsCollection.add(new Backbone.Model(file)));
        }
    },
    onRender: function() {
        this.options.parent.updateTitle('Compose Text Message', this.options.parent.campaignData.name);
        this.initializeMergeTagList();
    },
    onShow: function() {
        this.ui.textArea.val(this.options.parent.campaignData.content);
        this.ui.textArea.focus();

        this.processMessage(this.options.parent.campaignData.content);

        this.attachsContainer.show(new AttachsContainer({
            collection: this.attachsCollection
        }));

        var self = this;
        this.listenTo(this.attachsContainer.currentView, 'itemview:file-detached', function(childView) {
            self.attachsCollection.remove(childView.model);
            self.ui.attachFile.toggleClass('disabled', self.attachsCollection.length === 10);
            self.options.parent.addChange('attached_files_id', _.map(self.attachsCollection.models, (model) => model.get('id')));
            self.options.parent.campaignData.attached_files = _.map(self.attachsCollection.models, (model) => model.toJSON());
        });
    },
    validateFile: function(name, size) {
        // only jpg, gif and png formats are supported. The max size limit is 5Mb
        const dotPos = name.lastIndexOf('.');
        const ext = (dotPos !== -1 ? name.substr(dotPos + 1) : '').toLowerCase();

        if (['jpg', 'jpeg', 'png', 'gif'].indexOf(ext) === -1) {
            MessageBox.showOk({
                message: 'Supported formats are: JPEG, JPG, GIF and PNG',
                icon: 'icon-warning'
            }, this.options.parent);

            return false;
        }

        if (size > (5 * 1024 * 1024)) {
            MessageBox.showOk({
                message: 'There is a limit of 5Mb per image',
                icon: 'icon-warning'
            }, this.options.parent);

            return false;
        }

        return true;
    },
    onFileSelected: function(file) {
        this.showLibraryBrowser(false);
        this.attachsCollection.add(new Backbone.Model(file));
        this.ui.attachFile.toggleClass('disabled', this.attachsCollection.length > 10);

        this.options.parent.addChange('attached_files_id', _.map(this.attachsCollection.models, (model) => model.get('id')));
        this.options.parent.campaignData.attached_files = _.map(this.attachsCollection.models, (model) => model.toJSON());
    },
    showLibraryBrowser: function(visible) {
        ReactDOM.render(
            <div>
                {visible &&
                    <LibraryBrowser
                        folderId="public"
                        ownerIsAll={true}
                        parent={this.options.parent}
                        onCancel={() => this.showLibraryBrowser(false)}
                        onFileSelected={this.onFileSelected.bind(this)}
                        validateFile={this.validateFile.bind(this)}
                    />
                }
            </div>,
            this.$el.find('.library-browser').get(0)
        );
    },
    initializeMergeTagList: function() {
        var self = this;

        this.mergeTagList = new MergeTagList({ parent: this.options.parent });
        this.mergeTagList.render();
        this.ui.addMergeTag.after(this.mergeTagList.$el);

        this.listenTo(this.mergeTagList, 'tags:loaded', function() {
            self.mergeTagList.$el.find('.content-container').nanoScroller();
            self.mergeTagList.$el.hide();
        });

        this.listenTo(this.mergeTagList, 'tag:selected', function(item) {
            manageTagInsertion(self, item, function(tag) {
                var textArea = $(self.ui.textArea);
                var cursorPos = textArea.prop('selectionStart');
                var message = textArea.val();
                var textBefore = message.substring(0, cursorPos);
                var textAfter = message.substring(cursorPos, message.length);

                textArea.val(textBefore + tag + textAfter);
                cursorPos += tag.length;
                textArea.prop('selectionStart', cursorPos);
                textArea.prop('selectionEnd', cursorPos);
                textArea.focus();

                self.mergeTagList.toggle();
                self.processMessage(textArea.val(), true);
            });
        });
    },
    beforeSave: function() {
        this.options.parent.addChange('content', this.ui.textArea.val());
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.find('.library-browser').get(0));
    },
    processMessage: function(content, addChange) {
        var segCalculator = new segmentsCalculator(content, 'auto');
        var updateTextArea = false;

        if (segCalculator.getEncodingName() === 'UCS-2') {
            for (var i = 0; i < content.length; ++i) {
                var char = Utilities.unicodeToGsm(content.charCodeAt(i));

                if (char) {
                    content = content.substr(0, i) + char + content.substr(i + 1)
                    updateTextArea = true;
                }
            }
        }

        if (updateTextArea) {
            var selectionStart = this.ui.textArea.prop('selectionStart');
            var selectionEnd = this.ui.textArea.prop('selectionEnd');

            this.ui.textArea.val(content);
            this.ui.textArea.prop('selectionStart', selectionStart);
            this.ui.textArea.prop('selectionEnd', selectionEnd);
        }

        if (addChange) {
            this.options.parent.addChange('content', content);
        }

        var numCharactersLeft = this.maxNumCharacters - content.length;
        this.ui.charactersLeft.text(numCharactersLeft + ' character' + (numCharactersLeft !== 1 ? 's' : '') + ' left');

        var segCalculator = new segmentsCalculator(content, 'auto');
        var numSegments = segCalculator.numSegments;
        this.ui.numSegments.text(`${numSegments} segment${numSegments !== 1 ? 's' : ''} (${segCalculator.getEncodingName()})`);
    }
});

var Step3 = Marionette.Layout.extend({
    className: 'step step-3',
    template: Handlebars.compile(step3Template),
    templateHelpers: function() {
        var labFlags = app.user.get('preferences').lab_flags;

        return {
            isTextMessage: this.isTextMessage,
            previewInfoMessage: TextManager.getText(this.isTextMessage ? 'ID_PREVIEW_INDIVIDUAL_TEXT_MESSAGE' : 'ID_PREVIEW_INDIVIDUAL_EMAIL'),
            showCCList: !this.isTextMessage
        };
    },
    ui: {
        fromUser: '.from-user',
        toGroup: '.to-group',
        ccList: '.cc-list',
        bccList: '.bcc-list',
        fromEmail: '.from-email',
        toEmail: '.to-email',
        preview: '.preview',
        subject: '.subject',
        fromCont: '.from-cont',
        toCont: '.to-cont',
        sendTest: '.send-test',
        subject: '.subject'
    },
    regions: {
        individualList: '.individual-list'
    },
    events: {
        'click .send-test': function() {
            if (!this.validateSender(this.options.parent.campaignData)) {
                return;
            }

            var self = this;
            var defUser = [{
                id: app.user.get('id'),
                name: app.user.get('name')
            }];

            if (this.ui.subject.length > 0 && (!this.options.parent.campaignData.subject || !this.options.parent.campaignData.subject.trim())) {
                if (this.$el) {
                    this.$el.find('.subject-shake-container').effect("shake");
                    this.$el.find('.subject-error').show();
                    this.ui.subject.addClass('error-input');
                    this.ui.subject.focus();
                }
                return;
            }

            var entity = this.isTextMessage ? 'message' : 'email';
            MessageBox.showOk(
                {
                    icon: 'icon-user',
                    message: 'Which users would you like to send a test version of this ' + entity + ' to?',
                    accept_button_text: 'Send Now'
                },
                self.options.parent,
                function(idList) {
                    self.options.parent.model.test(self.options.parent.campaignData, idList, self.activeIndividualId);
                },
                {
                    type: 'tagSelect',
                    value: defUser,
                    idValue: defUser[0].id
                }
            );
        },
        'change .subject': function(ev) {
            this.options.parent.addChange('subject', ev.target.value);
            this.removeErrorMsgs(this.ui.subject, '.subject-error');
            this.showPreview();
        }
    },
    initialize: function() {
        this.isTextMessage = this.options.parent.campaignData.campaign_type === 'message';
        this.options.parent.nextStep = '4';
    },
    onRender: function() {
        var self = this;

        this.initFromSelect();
        this.initToGroup();

        if (this.ui.subject.length > 0) {
            this.ui.subject.val(this.options.parent.campaignData.subject);
        }

        var campaignType = this.options.parent.campaignData.campaign_type;
        this.options.parent.updateTitle(
            'Preview ' + (campaignType !== 'campaign' ? 'Direct' : (TextManager.parseText('${ID_CAMPAIGN, capitalize}') + ' Email')),
            this.options.parent.campaignData.name
        );

        if (!this.options.parent.campaignData.to_group) {
            _.defer(function () {
                var entity = self.isTextMessage ? 'Message' : 'Email';

                self.ui.preview.contents().find('html').html(
                    [
                        '<div style="display: flex; align-items: center; height: 100%; font-family: proxima-nova,Helvetica,Arial,sans-serif; line-height: 1.42857143;">',
                            '<div style="width: 100%; text-align: center;">',
                                '<div style="font-size: 18px; margin-bottom: 15px; color: #666;">' + entity + ' Preview</div>',
                                '<div style="color: #bbb; font-size: 14px;">Will show you a separate ' + entity + '<br>for each contact in your list</div>',
                            '</div>',
                        '</div>'
                    ].join('')
                );
            });
        }

        var listUrl = "/groups?rows=-1&element_type=individuals";
        if (campaignType === 'campaign' && !AppConfig.getFeatureLabValue('enableGroupsForCampaigns', 'SAL-4063')) {
            listUrl += "&mailing_list=true";
        }

        $.get(listUrl, function(list) {
            if (list.length === 0) {
                if (self.options.parent.campaignData.campaign_type === 'campaign') {
                    if (app.user.get('is_admin')) {
                        self.customToGroupError = 'Please create a Mailing List in <a href="/#content/drafts?s=editMailingLists">App Settings</a> first';
                    }
                    else {
                        self.customToGroupError = 'Please ask an admin to create a Mailing List first';
                    }
                    self.$el.find('.preview-info').html('<span style="color: red;">' + self.customToGroupError + '</span>').show();
                }
                else {
                    self.customToGroupError = TextManager.getText('ID_CREATE_A_GROUP_OF_INDIVIDUALS');
                }
            }
            else {
                if (self.options.parent.campaignData.campaign_type === 'campaign') {
                    if (app.user.get('is_admin')) {
                        self.$el.find('.preview-info').html('You can create more Mailing Lists in <a href="/#content/drafts?s=editMailingLists">App Settings</a>').show();
                    }
                    else {
                        self.$el.find('.preview-info').html('You can ask your admin to create more Mailing Lists').show();
                    }
                }
            }
        });
    },
    initFromSelect: function() {
        var self = this;
        var fromUser = null;

        if (this.options.parent.campaignData.from_user) {
            fromUser = this.options.parent.campaignData.from_user;

            if (!this.isTextMessage) {
                self.ui.fromEmail.text(this.validateEmailToSendFrom(fromUser, false));
            }
        } else if (this.options.parent.campaignData.from_custom_field) {
            fromUser = this.options.parent.campaignData.from_custom_field;
            fromUser.isCustomField = true;
            self.ui.fromEmail.text(fromUser.name + ' (Custom Field)');
        }

        function formatName(item) {
            let commValue = '';

            if (self.isTextMessage) {
                if (item.phone) {
                    commValue = `(${item.phone})`;
                }
            } else {
                commValue = `(${item.email_address})`;
            }

            const name = htmlSanitizer.sanitize(item.name);

            if (item.children || item.isCustomField) {
                return name
            } else if (self.senderBlocked(item)) {
                return `<i class="icon-blocked" style="color:red;"></i> ${name} ${commValue}`;
            }
            else {
                if (self.isTextMessage) {
                    return `${name} ${commValue}`;
                }

                return `${name} (${self.validateEmailToSendFrom(item, true)})`;
            }
        }

        var promises = [];
        var usersCollection = new UsersCollection();

        promises.push(usersCollection.fetch({
            extraFields: this.isTextMessage ? ['twilio_phone'] : []
        }));

        var customFieldsCollection = new CustomFieldsCollection();
        promises.push(customFieldsCollection.fetch({}));

        $.when(...promises).done(function() {
            var fixedPhone = null;

            // the phone number can be a text (i.e. SaleSeek), a fixed phone number (the same number is used to send all the sms no mather the user
            // who is sending them) or it can be the phone number associated with the user (the default option)
            if (app.globalData.smsInfo && app.globalData.smsInfo.originatorType !== 'user_phone') {
                fixedPhone = app.globalData.smsInfo.originatorValue;
            }

            var users = _.map(usersCollection.models, function(model) {
                var id = model.get('id');
                var phone = fixedPhone || model.get('phone');
                var preferences = model.get('preferences');

                if (!fromUser.isCustomField && fromUser.id === id) {
                    fromUser.phone = phone;
                    self.ui.fromEmail.text(phone);
                }

                return {
                    id: id,
                    name: model.get('name'),
                    email_address: model.get('email_address'),
                    phone: phone,
                    preferences: preferences
                };
            });

            self.individualCustomFields = null;

            if (customFieldsCollection !== null) {
                var customFieldsByType = {
                    individuals: [],
                    organizations: [],
                    deals: []
                };

                for (var i = 0; i < customFieldsCollection.models.length; ++i) {
                    var model = customFieldsCollection.models[i];

                    if (model.get('type') !== 'individual') {
                        continue;
                    }

                    customFieldsByType[model.get('view')].push({
                        id: model.get('id'),
                        name: model.get('name'),
                        isCustomField: true
                    });
                }

                self.individualCustomFields = [];

                if (customFieldsByType.individuals.length > 0) {
                    self.individualCustomFields.push({
                        name: TextManager.parseText('${ID_INDIVIDUAL} custom fields'),
                        children: customFieldsByType.individuals
                    });
                }
                if (customFieldsByType.organizations.length > 0) {
                    self.individualCustomFields.push({
                        name: TextManager.parseText('${ID_ORGANIZATION} custom fields'),
                        children: customFieldsByType.organizations
                    });
                }
                if (customFieldsByType.deals.length > 0) {
                    self.individualCustomFields.push({
                        name: TextManager.parseText('${ID_DEAL} custom fields'),
                        children: customFieldsByType.deals
                    });
                }
            }

            if (!self.isTextMessage) {
                self.initAdditionalRecipientsList('cc', self.ui.ccList, 'Choose CC Fields');
                self.initAdditionalRecipientsList('bcc', self.ui.bccList, 'Choose BCC Fields');
            }

            var data;

            if (AppConfig.getFeatureLabValue('enableGroupsForCampaigns', 'SAL-4063') && (self.individualCustomFields !== null)) {
                data = [{
                    name: 'users',
                    children: users
                }];
                data = data.concat(self.individualCustomFields);
            } else {
                data = users;
            }

            self.fromSelect = new backboneSelect2.SelectView({
                view: self,
                $el: self.ui.fromUser,
                text: 'name',
                data: data,
                value: fromUser,
                options: {
                    placeholder: '',
                    allowClear: false,
                    containerCssClass: 'editable-field',
                    dropdownCssClass: 'source-select-popover popover select2-drop-wider'
                },
                formatResult: function(item) {
                    return formatName(item);
                },
                formatSelection: function(item) {
                    return formatName(item);
                }
            });

            self.listenTo(self.fromSelect, 'change', function(item) {
                if (item.isCustomField) {
                    self.options.parent.addChange('from_user', null);
                    self.options.parent.addChange('from_custom_field', {
                        id: item.id,
                        name: item.name
                    });
                    self.ui.fromEmail.text(item.name + ' (Custom Field)');
                } else {
                    self.options.parent.addChange('from_custom_field', null);
                    self.options.parent.addChange('from_user', {
                        id: item.id,
                        name: item.name,
                        email_address: item.email_address,
                        email_signature: item.email_signature,
                        phone: item.phone,
                        preferences: item.preferences
                    });

                    self.ui.fromEmail.text((self.isTextMessage ? item.phone : self.validateEmailToSendFrom(item, false)) || '');
                }

                self.showPreview();
            }, self);
        });
    },
    validateEmailToSendFrom: function (item, includeAsterisk) {
        var userDomain = item.email_address.split('@')[1].toLowerCase();
        var sendFromEmail = '';

        app.user.get('client').email_sending_domains.forEach(domain => {
            if(item.preferences && sendFromEmail === ''){
                var userAlternativeSenderEmail = item.preferences.alternative_email_sender;
                if(userAlternativeSenderEmail && sendFromEmail === ''){
                    var userAlternativeDomain = userAlternativeSenderEmail.split('@')[1].toLowerCase()

                    if(domain.toLowerCase() === userAlternativeDomain){
                        sendFromEmail = includeAsterisk ? '*' : ''
                        sendFromEmail += userAlternativeSenderEmail
                    }
                }
            }

            if (domain.toLowerCase() === userDomain && sendFromEmail === ''){
                sendFromEmail = item.email_address;
            }
        })

        return sendFromEmail;
    },
    senderBlocked: function(item) {
        if (item.children || item.isCustomField) {
            return false;
        }

        if (this.isTextMessage) {
            return !item.phone;
        }

        const userPreferences = item.preferences;

        if (userPreferences && userPreferences.notValidCampaignSender) {
            return true;
        }

        var userDomain = item.email_address.split('@')[1].toLowerCase();

        return !_.find(app.user.get('client').email_sending_domains, function(domain) {
            if (domain.toLowerCase() === userDomain){
                return true;
            } else if(userPreferences) {
                var userAlternativeSenderEmail = userPreferences.alternative_email_sender;
                if(userAlternativeSenderEmail){
                    var userAlternativeDomain = userAlternativeSenderEmail.split('@')[1].toLowerCase()

                    return domain.toLowerCase() === userAlternativeDomain;
                }

                return false;
            }
        });
    },
    initAdditionalRecipientsList: function(key, element, placeholder) {
        var contactList = [];
        var self = this;

        if (!_.isEmpty(this.options.parent.campaignData[key]) && this.individualCustomFields) {
            var ids = _.map(this.options.parent.campaignData[key], function(contact) {
                if (contact.type && contact.type === 'individuals') {
                    contact.individual.title = contact.individual.full_name || contact.individual.highlight
                    contactList.push(contact.individual)
                } else {
                    return contact.custom_field_id;
                }
            });

            _.forEach(this.individualCustomFields, function(group) {
                _.forEach(group.children, function(item) {
                    if (_.contains(ids, item.id)) {
                        contactList.push(item);
                    }
                });
            });
        }

        var contactListSelect = new backboneSelect2.TagView({
            view: this,
            $el: element,
            url: '/v1/search',
            search: true,
            params: {
                types: 'individuals'
            },
            afterFetchData: function(data) {
                if (data.length > 0){
                    data = [{
                        name: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                        children: data
                    }];
                }

                return data.concat(self.individualCustomFields)
            },
            filterResults: function(item, term) {
                const children = item.children.filter(value => value.title ? value.title.toLowerCase().includes(term) : value.name.toLowerCase().includes(term));

                if (children.length === 0) return

                return {
                    name: item.name,
                    children: children
                };
            },
            value: contactList,
            options: {
                placeholder: placeholder,
                containerCssClass: 'editable-field campaign-group-select',
                dropdownCssClass: 'source-select-popover popover select2-drop-wider',
                multiple: true,
                formatResult: function(item) {
                    var title = `${_.escape(item.title || item.name)}`;
                    return `<span title="${title}">${title}</span>`;
                },
                formatSelection: function(item) {
                    var title = `${_.escape(item.title || item.name)}`;
                    return `<span title="${title}">${title}</span>`;
                },
            }
        });

        this.listenTo(contactListSelect, 'change', function(items) {
            var data = _.map(items, function(item) {
                if (item.type && item.type === 'individuals'){
                    return {
                        individual_id: item.id,
                        type: 'individuals',
                        individual: item
                    }
                } else {
                    return {
                        custom_field_id: item.id,
                        type: 'custom_field',
                        custom_field: item
                    };
                }
            });

            self.options.parent.addChange(key, data);
        });
    },
    initToGroup: function() {
        var self = this;
        var toGroup = null;

        if ('to_group' in this.options.parent.campaignData && this.options.parent.campaignData.to_group) {
            toGroup = this.options.parent.campaignData.to_group;
            if (self.options.parent.recipientCount) {
                self.ui.sendTest.show();
            }
        }

        var showList = function(item, countCallback) {
            var individualsCollection = new IndividualsCollection();

            // item can be to_group or a item from the select2 search
            var itemType;

            if (item.fromSearch) {
                itemType = item.type;
            } else {
                itemType = item.id ? 'groups' : 'individuals';
            }

            if (itemType === 'groups') {
                var data = { by_group_id: item.id };

                if (self.isTextMessage) {
                    data.has_phone = true;
                    data.unsubscribed_all_messages = false;
                } else {
                    data.has_email = true;
                    data.unsubscribed_all = false;
                }
            } else {
                individualsCollection.fetch = function(options) {
                    var individualId = null;

                    if (item.fromSearch) {
                        individualId = item.id;
                    } else if (_.isArray(item.items) && item.items.length > 0) {
                        individualId = item.items[0];
                    }

                    individualsCollection.total = 0;

                    if (individualId) {
                        var model = new IndividualModel({id: individualId});
                        model.fetch({
                            success: function(data) {
                                var valid = false;

                                if (self.isTextMessage) {
                                    valid = (data.get('communication').filter(comm => comm.medium === 'phone').length > 0) && !data.get('unsubscribed_all_messages');
                                } else {
                                    valid = (data.get('communication').filter(comm => comm.medium === 'email').length > 0) && !data.get('unsubscribed_all');
                                }

                                if (valid) {
                                    individualsCollection.add(data);
                                    individualsCollection.total = 1;
                                }

                                options.success();
                            }
                        });
                    } else {
                        option.success();
                    }
                }
            }

            var paginatedListView = new PaginatedListView({
                id: 'individuals-list',
                listItemView: IndividualItemView.extend({ confirmOnClick: false }),
                listItemViewOptions: {
                    tools: {
                        hideCheckbox: true
                    },
                    hideCommunication: true
                },
                collection: individualsCollection,
                fetchOptions: {
                    rows: 50,
                    data: data
                }
            });

            self.individualList.show(paginatedListView);

            self.listenTo(paginatedListView, 'collectionCountChange', function(){
                if (self.groupSelect) {
                    self.groupSelect.view.$el.find('.select2-chosen').addClass('shortened');
                }

                self.$el.find('.to-cont .counter').remove();
                self.$el.find('.to-cont .select2-chosen').after('<span class="counter">' + individualsCollection.total + ' people</span>');
                self.options.parent.recipientCount = individualsCollection.total;

                if (self.options.parent.recipientCount) {
                    self.ui.sendTest.show();
                }

                if (countCallback) {
                    countCallback();
                }
            });

            self.listenTo(paginatedListView, 'refresh', function() {
                // preselect first row
                paginatedListView.$el.find('.row-is-target').first().click();
            });

            self.listenTo(paginatedListView, 'showItem', function(model) {
                self.activeIndividualId = model.get('id');
                if (self.isTextMessage) {
                    var phone = _.find(model.get('communication'), function(com) {
                        return com.medium === 'phone' && com.value;
                    });
                    self.ui.toEmail.text(phone ? phone.value : 'No Phone Number');
                } else {
                    var email = _.find(model.get('communication'), function(com) {
                        return com.medium === 'email' && com.value;
                    });
                    self.ui.toEmail.text(email ? email.value : 'No Email Address');
                }
                self.showPreview(model);
            });
        };

        if ('to_group' in this.options.parent.campaignData  && this.options.parent.campaignData.to_group) {
            showList(this.options.parent.campaignData.to_group);
        }

        var enableGroupsForCampaigns = AppConfig.getFeatureLabValue('enableGroupsForCampaigns', 'SAL-4063');
        var isCampaign = this.options.parent.campaignData.campaign_type === 'campaign';
        var groupsCollection = new GroupsCollection();
        var fetchData = {
            element_type: 'individuals'
        };

        if (isCampaign && !enableGroupsForCampaigns) {
            fetchData.mailing_list = true;
        }

        groupsCollection.fetch({
            rows: -1,
            data: fetchData,
            success: function(collection) {
                var validGroupIds = collection.models.map(item => item.get('id'));
                var nameMapping = {
                    individuals: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                    groups: 'Group'
                };

                self.groupSelect = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.toGroup,
                    url: '/v1/search',
                    params: {
                        types: 'individuals,groups'
                    },
                    text: 'name',
                    search: true,
                    value: toGroup || null,
                    searchFilter: function(item) {
                        return (item.type === 'individuals') || (validGroupIds.indexOf(item.id) !== -1);
                    },
                    options: {
                        placeholder: self.options.parent.campaignData.campaign_type === 'direct' ? TextManager.getText('ID_CHOOSE_INDIVIDUAL_OR_GROUP_OF_INDIVIDUALS') : 'Choose Mailing List',
                        allowClear: false,
                        containerCssClass: 'editable-field campaign-group-select',
                        dropdownCssClass: 'source-select-popover popover select2-drop-wider',
                        minimumInputLength: 1,
                        formatResult: function(item) {
                            var title = `${_.escape(item.title || item.name)} - (${nameMapping[item.type]})`;
                            return `<span title="${title}">${title}</span>`;
                        },
                        formatSelection: function(item) {
                            var title = `${_.escape(item.title || item.name)}`;
                            return `<span title="${title}">${title}</span>`;
                        }
                    }
                });

                self.listenTo(self.groupSelect, 'collectionLoaded', function(collection) {
                    if (collection.length === 0) {
                        self.groupSelect.$el.select2('close');
                    }
                });

                self.listenTo(self.groupSelect, 'change', function(item) {
                    var groupData = {
                        name: item.title
                    };

                    if (item.type === 'individuals') {
                        groupData.id = null;
                        groupData.items = [item.id];
                    } else {
                        groupData.id = item.id;
                    }

                    self.options.parent.addChange('to_group', groupData);
                    self.ui.toEmail.text('');
                    item.fromSearch = true;

                    showList(item, function() {
                        if (self.options.parent.recipientCount) {
                            self.removeErrorMsgs(self.ui.toCont, '.to-error');
                            self.ui.sendTest.show();
                        }
                        else {
                            self.ui.sendTest.hide();
                        }
                    });
                }, self);
            }
        });
    },
    showPreview: function(model) {
        var self = this;

        if (model) {
            this.currentIndividualModel = model;
        }
        else if (!this.currentIndividualModel) {
            return;
        }

        let campaignData = _.clone(this.options.parent.campaignData);
        campaignData.subject = campaignData.subject || '<no subject>';

        $.post(
            '/campaign_preview?individual_id=' + this.currentIndividualModel.get('id'),
            JSON.stringify(campaignData),
            function(data) {
                if (self.isTextMessage) {
                    self.ui.fromEmail.text(data.from_phone);
                } else {
                    self.ui.subject.text(data.subject);
                    self.ui.fromEmail.text(data.from_email);
                }
                self.ui.preview.contents().find('html').prop('innerHTML', data.content);
            }
        ).fail(function() {
            try {
                self.ui.preview.contents().find('html').html('');

                // reinitialize from select if user had been removed from system
                var exception = JSON.parse(arguments[0].responseText).detail.exception;

                if (exception === "InvalidUserIdValueError") {
                    self.options.parent.campaignData.from_custom_field = null;
                    self.options.parent.campaignData.from_user = {
                        id: app.user.get('id'),
                        name: app.user.get('name'),
                        email_address: app.user.get('email_address'),
                        email_signature: app.user.get('email_signature'),
                        preferences: app.user.get('preferences')
                    };
                    self.initFromSelect();
                } else if (exception === 'InvalidCustomFieldIdValueError') {
                    self.ui.fromEmail.text(self.options.parent.campaignData.from_custom_field.name + ' (Custom Field)');
                }
            }
            catch(e) { }
        });
    },
    /**
     * This function is used both as static and member function.
     *
     * @param campaignData  campaign data is passed explicitly
     * @returns {boolean}
     */
    validate: function(campaignData) {
        if (this.removeErrorMsgs) {
            this.removeErrorMsgs();
        }

        if (this.validateSender && !this.validateSender(campaignData)) {
            return false;
        }

        if (this.ui.subject.length > 0 && (!campaignData.subject || !campaignData.subject.trim())) {
            if (this.$el) {
                this.$el.find('.subject-shake-container').effect("shake");
                this.$el.find('.subject-error').show();
                this.ui.subject.addClass('error-input');
                this.ui.subject.focus();
            }
            return false;
        }

        if (this.customToGroupError) {
            if (this.$el) {
                this.$el.find('.to-shake-container').effect("shake");
                this.$el.find('.error-custom').html(this.customToGroupError).show();
                this.$el.find('.preview-info').hide();
                this.ui.toCont.addClass('error-select');
            }
            return false;
        }
        if (!campaignData.to_group) {
            if (this.$el) {
                var noGroupMessage = campaignData.campaign_type === 'direct' ? "Please select the group to send email to" : TextManager.getText('ID_SELECT_MAILING_LIST_TO_SEND_CAMPAIGN');
                this.$el.find('.to-shake-container').effect("shake");
                this.$el.find('.error-1.to-error').html(noGroupMessage).show();
                this.$el.find('.preview-info').hide();
                this.ui.toCont.addClass('error-select');
            }
            return false;
        }
        if (this.options && !this.options.parent.recipientCount) {
            this.$el.find('.to-shake-container').effect("shake");
            this.$el.find('.error-2').show();
            this.$el.find('.preview-info').hide();
            this.ui.toCont.addClass('error-select');
            return false;
        }
        return true;
    },
    removeErrorMsgs: function(element, errorClass) {
        if (!this.$el) {
            return
        }

        if (element) {
            this.$el.find(errorClass).hide();
            element.removeClass('error-select');
            element.removeClass('error-input');
        } else {
            this.$el.find('.error').hide();
            this.ui.toCont.removeClass('error-select');
            this.ui.subject.removeClass('error-input');
        }
        this.$el.find('.preview-info').show();
    },
    validateSender: function(campaignData) {
        if (campaignData.from_user && this.senderBlocked(campaignData.from_user)) {
            if (this.$el) {
                MessageBox.showOk(
                    {
                        message: "You cannot send an email from an unauthorized user. Please change FROM field to an authorized user.",
                        icon: 'icon-blocked'
                    },
                    this
                );
            }
            return false;
        }
        return true;
    }
});

var Step4 = Marionette.Layout.extend({
    className: 'step step-4',
    template: Handlebars.compile(step4Template),
    templateHelpers: function() {
        return {
            title: this.options.parent.campaignData.name,
            count: this.options.parent.recipientCount,
            mailingList: this.options.parent.campaignData.to_group.name,
            tags: this.options.parent.campaignData.tags.map(tag => tag.name).join(', '),
        };
    },
    regions: {
        timeZoneSelectContainer: '.time-zone'
    },
    ui: {
        sendNow: '.send-now',
        sendLater: '.send-later',
        dateTimeInput: '.date-time-input',
        dateCont: '.date-cont',
        timeCont: '.time-cont',
        count: '.count'
    },
    events: {
        'click .send-now': function() {
            this.ui.sendNow.addClass('selected');
            this.ui.sendLater.removeClass('selected');
            this.ui.sendLater.find('.over').show();

            this.options.parent.addChange('campaign_schedule', null);
        },
        'click .send-later': function() {
            if (this.options.parent.campaignData.campaign_schedule) {
                return;
            }
            this.ui.sendNow.removeClass('selected');
            this.ui.sendLater.addClass('selected');
            this.ui.sendLater.find('.over').hide();

            this.options.parent.addChange('campaign_schedule', {
                when: this.when,
                timezone: this.timezone,
                utc_offset: this.utc_offset
            });
        },
        'click .date-cont': 'showPicker',
        'click .time-cont': 'showPicker'
    },
    initialize: function() {
        var self = this;

        if (this.options.parent.campaignData.campaign_schedule) {
            this.when = this.options.parent.campaignData.campaign_schedule.when;
            this.timezone = this.options.parent.campaignData.campaign_schedule.timezone;
        }
        // default is tomorrow 12:00
        else {
            var date = new Date();
            date.setDate(date.getDate() + 1);
            this.when = date.getFullYear() + '-' + Utilities.addLeadingZeros(date.getMonth() + 1, 2) + '-' + Utilities.addLeadingZeros(date.getDate(), 2) + 'T12:00'; // here we send local time, thus we configure ISO datetime manually
            this.timezone = null;
        }
        this.utc_offset = (new Date()).getTimezoneOffset();

        this.listenTo(this.options.parent, 'error:CampaignScheduledPastError', function() {
            self.showDateTimeError();
        });
    },
    onRender: function() {
        var self = this;
        var campaignSchedule = this.options.parent.campaignData.campaign_schedule;
        if (campaignSchedule) {
            this.ui.sendNow.removeClass('selected');
            this.ui.sendLater.addClass('selected');
            this.ui.sendLater.find('.over').hide();
        }

        this.timeZoneSelect = new TimeZoneSelectView({timeZoneId: this.timezone || 'UTC'});
        this.timeZoneSelectContainer.show(this.timeZoneSelect);
        this.listenTo(this.timeZoneSelect, 'change', function (data) {
            self.hideDateTimeError();

            if (data.id === 'UTC') {
                self.timezone = null;
                self.utc_offset = (new Date()).getTimezoneOffset();
            }
            else {
                self.timezone = data.id;
                self.utc_offset = null;
            }
            this.options.parent.addChange('campaign_schedule', {
                when: self.when,
                timezone: self.timezone,
                utc_offset: self.utc_offset
            });
        });

        this.setInitialDateTimeValues();

        var campaignType = this.options.parent.campaignData.campaign_type;
        var entity = campaignType === 'message' ? 'Message' : 'Email';
        this.options.parent.updateTitle(
            'Launch ' + (campaignType === 'direct' ? 'Direct' : (TextManager.parseText('${ID_CAMPAIGN, capitalize}') + ' ' + entity)),
            this.options.parent.campaignData.name
        );

        if (!this.options.parent.recipientCount) {
            var parameters_by_type = campaignType === 'message' ? '&has_phone=true&unsubscribed_all_messages=false' : '&has_email=true&unsubscribed_all=false';
            $.get(
                "/individuals?start=0&rows=0" + parameters_by_type + "&by_group_id=" + this.options.parent.campaignData.to_group.id,
                [],
                function(data, type, xhr) {
                    self.options.parent.recipientCount = parseInt(xhr.getResponseHeader("Records-Total"));
                    self.ui.count.text(self.options.parent.recipientCount);
                }
            )
        }
    },
    showPicker: function() {
        var self = this;
        var dateTimePicker = new DateTimePicker({
            altField: this.ui.dateTimeInput,
            css: {
                left: self.ui.sendLater.offset().left - 300,
                top: self.ui.sendLater.offset().top - 50
            },
            date: this.date,
            time: this.time
        });
        dateTimePicker.showPicker();

        this.listenTo(dateTimePicker, 'date-time:change', this.setDateTimeValues);
    },
    setTimeText: function(hours, minutes) {
        if (!AppConfig.getClientPreferenceValue('use24hClock')) {
            var h12 = Utilities.convert24hTo12hClock(hours);
            this.ui.timeCont.text(h12.hours + ':' + minutes + (h12.am ? ' AM' : ' PM'));
        } else {
            this.ui.timeCont.text(hours + ':' + minutes);
        }
    },
    setInitialDateTimeValues: function() {
        var dateAndTime = this.when.split('T');
        var date = dateFormat.createDateIgnoringTZ(dateAndTime[0]);

        this.date = dateFormat.shortFormatWithYear(date);
        this.ui.dateCont.text(this.date);

        var time = dateAndTime[1].split(':');
        this.time = time[0] + ':' + time[1];
        this.setTimeText(time[0], time[1]);

        this.ui.dateTimeInput.val(dateFormat.shortFormatWithYear(date));
    },
    setDateTimeValues: function(dateTime) {
        this.hideDateTimeError();

        this.date = dateTime.date;
        this.ui.dateCont.text(this.date);
        this.time = dateTime.time;

        var dateAndTime = this.time.split(':');
        this.setTimeText(dateAndTime[0], dateAndTime[1]);

        this.when = dateTime.year + '-' + Utilities.addLeadingZeros(dateTime.month + 1, 2) + '-' + Utilities.addLeadingZeros(dateTime.day, 2) + 'T' + dateTime.time; // here we send local time, thus we configure ISO datetime manually
        this.options.parent.addChange('campaign_schedule', {
            when: this.when,
            timezone: this.timezone,
            utc_offset: this.utc_offset
        });
    },
    showDateTimeError: function() {
        this.$el.find('.error-1').show();
        this.$el.find('.date-cont, .time-cont').addClass('error-div');
        this.$el.find('.date-cont, .time-cont').effect("shake");
    },
    hideDateTimeError: function() {
        this.$el.find('.error-1').hide();
        this.$el.find('.date-cont, .time-cont').removeClass('error-div');
    },
    validateBeforeLaunch: function() {
        if (!this.options.parent.recipientCount) {
            this.ui.count.parent().effect("shake");
            return false;
        }
        return true;
    }
});

var stepMap = {
        '1': {
            id: 1,
            view: Step1
        },
        '2A': {
            id: 2,
            view: Step2A
        },
        '2B': {
            id: 2,
            view: Step2B
        },
        '2C': {
            id: 2,
            view: Step2C
        },
        '2D': {
            id: 2,
            view: Step2D
        },
        '3': {
            id: 3,
            view: Step3
        },
        '4': {
            id: 4,
            view: Step4
        }
    };

export default Marionette.Layout.extend({
    className: 'campaign-creation',
    template: Handlebars.compile(campaignCreationTemplate),
    templateHelpers: function() {
        return {
            launchButtonText: this.model.get('status') === 'scheduled' ? TextManager.getText('ID_UPDATE_ENTITY', ['${ID_CAMPAIGN, capitalize}']) : TextManager.parseText('Launch ${ID_CAMPAIGN, capitalize}')
        };
    },
    ui: {
        saveDraft: '.save-draft',
        next: '.next',
        launchCampaign: '.launch-campaign',
        titleLeft: '.title-left',
        titleRight: '.title-right'
    },
    regions: {
        center: '.campaign-center'
    },
    events: {
        'click .cancel': function() {
            app.dirtyModelHandler.confirm(this, function() {
                if (this.options.onCancelGoto) {
                    window.location.href = this.options.onCancelGoto;
                } else {
                    this.trigger('close');
                }
            });
        },
        'click .next': function() {
            this.showStep(this.nextStep, false, true);
        },
        'click .save-draft': function() {
            var self = this;
            var isNew = this.model.isNew();

            if (this.currentStep.beforeSave) {
                this.currentStep.beforeSave();
            }
            this.model.save(this.campaignData, {
                patch: true,
                success: function() {
                    self.ui.saveDraft.toggleClass('hide', true);

                    vent.trigger('AppContent:contentChange'); // update URL if new campaign gets saved

                    app.dirtyModelHandler.remove(self.model.cid);

                    if (isNew) {
                        vent.trigger('campaign:new', self.model);
                    }
                }
            });
        },
        'click .launch-campaign': function() {
            var self = this;

            if (this.currentStep.validateBeforeLaunch && !this.currentStep.validateBeforeLaunch()) {
                return;
            }

            var message = [
                "<p>",
                _.escape(self.campaignData.name),
                "</p><p>",
                "will be sent to",
                self.recipientCount,
                "people",
                "</p>"
            ];

            if (self.campaignData.campaign_schedule) {
                var dateAndTime = self.campaignData.campaign_schedule.when.split('T');

                message.push("<p>");
                message.push("at");
                message.push(dateAndTime[1].substring(0, 5));
                message.push("</p><p>");
                message.push("on");
                message.push(dateFormat.shortFormatWithYear(dateFormat.createDateIgnoringTZ(dateAndTime[0])));
                message.push("</p>");
            }
            else {
                message.push("<p>");
                message.push("immediately");
                message.push("</p>");
            }

            MessageBox.showYesNo(
                {
                    message: message.join(" "),
                    accept_button_text: 'Confirm',
                    cancel_button_text: 'Cancel'
                },
                self,
                function () {
                    self.model.launch(
                        self.campaignData,
                        function() {
                            if (self.options.onCancelGoto) {
                                window.location.href = self.options.onCancelGoto;
                            } else {
                                self.close();
                            }
                        },
                        function(model, xhr) {
                            if (JSON.parse(xhr.responseText).detail.exception === "CampaignScheduledPastError") {
                                self.trigger("error:CampaignScheduledPastError");
                            }
                        }
                    );
                }
            );
        }
    },
    initialize: function() {
        if (this.model) {
            this.campaignData = {
                name: this.model.get('name'),
                subject: this.model.get('subject'),
                content: this.model.get('content'),
                to_group: this.model.get('to_group'),
                cc: this.model.get('cc'),
                from_user: this.model.get('from_user'),
                from_custom_field: this.model.get('from_custom_field'),
                campaign_type: this.model.get('campaign_type'),
                last_page: this.model.get('last_page'),
                current_page: this.model.get('current_page'),
                campaign_schedule: this.model.get('campaign_schedule'),
                status: this.model.get('status'),
                include_signature: this.model.get('include_signature'),
                track: this.model.get('track'),
                attached_files_id: this.model.get('attached_files_id'),
                attached_files: this.model.get('attached_files'),
                is_template: this.model.get('is_template'),
                see_config: this.model.get('see_config'),
                tags: this.model.get('tags'),
                funnels: this.model.get('funnels'),
                bcc: this.model.get('bcc'),
            };

            // check if the sender was deleted and in that case use current user as sender
            if (!this.campaignData.from_user && !this.campaignData.from_custom_field) {
                this.campaignData.from_user = {
                    id: app.user.get('id'),
                    name: app.user.get('name'),
                    email_address: app.user.get('email_address'),
                    email_signature: app.user.get('email_signature'),
                    preferences: app.user.get('preferences')
                };
            }
        }
        else {
            if (this.options.populateFrom) {
                var pf = this.options.populateFrom;
                var fromUser;

                if (pf.from_user) {
                    fromUser = {
                        id: pf.from_user.id,
                        name: pf.from_user.name,
                        email_address: pf.from_user.email_address,
                        email_signature: pf.from_user.email_signature,
                        preferences: pf.from_user.preferences
                    };
                } else {
                    fromUser = {
                        id: app.user.get('id'),
                        name: app.user.get('name'),
                        email_address: app.user.get('email_address'),
                        email_signature: app.user.get('email_signature'),
                        preferences: app.user.get('preferences')
                    };
                }

                this.campaignData = {
                    name: pf.name,
                    subject: pf.subject,
                    content: pf.content,
                    to_group: pf.to_group,
                    from_user: fromUser,
                    campaign_type: pf.campaign_type,
                    last_page: 2,
                    current_page: 2,
                    status: pf.status,
                    include_signature: pf.include_signature,
                    track: pf.track,
                    attached_files_id: pf.attached_files_id || [],
                    attached_files: pf.attached_files || [],
                    is_template: false,
                    from_template: true,
                    see_config: pf.see_config || null,
                    tags: [],
                    funnels: [],
                };
            } else {
                this.campaignData = {
                    campaign_type: 'direct',
                    content: '',
                    status: 'draft',
                    from_user: {
                        id: app.user.get('id'),
                        name: app.user.get('name'),
                        email_address: app.user.get('email_address'),
                        email_signature: app.user.get('email_signature'),
                        preferences: app.user.get('preferences')
                    },
                    from_custom_field: null,
                    include_signature: true,
                    track: true,
                    attached_files_id: [],
                    see_config: null,
                    tags: [],
                    funnels: [],
                };
            }
            this.model = new CampaignModel();
        }
    },
    onShow: function() {
        var step,
            self = this;
        // Currently the only way to add attributes to the ModalRegion el
        this.$el.parent().attr('id', 'campaign-creation-modal');

        // custom modal click closing to enable dirty model confirmation
        this.$el.parent().prev('.modal-backdrop').on('click', function() {
            app.dirtyModelHandler.confirm(this, function() {
                self.trigger('close');
            });
        });

        if (this.model.get('status') === 'scheduled') {
            this.showStep('4', true);
        }
        else if (this.campaignData.last_page) {
            for (var i = 1; i <= this.campaignData.last_page; i++) {
                step = i;
                if (step == 2) {
                    switch (this.campaignData.campaign_type) {
                        case 'direct':
                            step = '2A';
                            break;
                        case 'campaign':
                            if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4382')) {
                                step = '2D';
                            } else {
                                step = '2B';
                            }
                            break;
                        case 'message':
                            step = '2C';
                            break;
                    }
                }
                this.$el.find('.dot-' + stepMap[step].id).addClass('visited').on('click', function (step) {
                    return function() {
                        self.showStep(step);
                    }
                } (step));

                if (i < this.campaignData.current_page && stepMap[step].view.prototype.validate && !stepMap[step].view.prototype.validate(this.campaignData)) {
                    this.$el.find('.dot-' + stepMap[step].id).addClass('active');
                    this.showStep(step, true);
                    break;
                }
                else if (i === this.campaignData.current_page) {
                    this.$el.find('.dot-' + stepMap[step].id).addClass('active');
                    this.showStep(step, true);
                }
            }
        }
        else {
            this.showStep('1', true);
        }
    },
    showStep: function(step, initial, validate) {
        var self = this;

        var callback = function() {
            this.currentStep = new stepMap[step].view({parent: this});
            this.center.show(this.currentStep);

            // 4 dots at the bottom
            if (!initial) {
                this.addChange('current_page', parseInt(step));
                this.addChange('last_page', Math.max((this.campaignData.last_page || 0), this.campaignData.current_page));
            }

            this.$el.find('.dot').removeClass('active');
            this.$el.find('.dot-' + stepMap[step].id).addClass('active visited').off('click').on('click', function () {
                self.showStep(step);
            });

            if (step == 4) {
                this.ui.next.hide();

                if (this.campaignData.campaign_type !== 'message' || AppConfig.getValue('enableTextMessaging')) {
                    this.ui.launchCampaign.show();
                }
            }
            else {
                this.ui.next.show();
                this.ui.launchCampaign.hide();
            }
        };

        if (!this.currentStep || !validate || !this.currentStep.validate || this.currentStep.validate(this.campaignData)) {
           if (this.currentStep && this.currentStep.confirm) {
               this.currentStep.confirm(callback.bind(this));
           }
           else {
               callback.call(this);
           }
        }

        this.campaignData.content = self.cleaningCampaignContent(this.campaignData.content || '');
    },
    cleaningCampaignContent: function(content) {
        if(content !== ''){
            //remove comments like: '/* this is a comment */'
            content = content.replace(/\/\*[\s\S]*?\*\//g, '');
        }

        return content;
    },
    addChange: function(key, value) {
        if ((this.campaignData.status === 'draft' || this.campaignData.status === 'ready' || this.campaignData.status === 'automation') && !this.campaignData.from_template) {
            this.ui.saveDraft.toggleClass('hide', false);
        }
        this.campaignData[key] = value;

        app.dirtyModelHandler.add(this.model.cid);
    },
    updateTitle: function(left, right) {
        this.ui.titleLeft.text(left);
        this.ui.titleRight.text(right);
    },
    getUrl: function() {
        return this.model.get('id') || 'new';
    },
    onClose: function() {
        _.defer(function() {
            vent.trigger('AppContent:contentChange');
        });
    }
});

import Backbone from 'backbone'


var LoginModel = Backbone.Model.extend({
    initialize: function(){
        this.on("change", this.attributesChanged);
    },
    attributesChanged: function(){
        var valid = false;
        if (this.get('email') &&
            this.get('password')) {

            valid = true;
        }
        this.trigger("validated", valid);
    }
});
export default LoginModel;

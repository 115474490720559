import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import vent from 'js/vent'
import ModalRegion from 'js/views/base/modal-region.js'
import UsersCollection from 'js/collections/users.js'
import UserModel from 'js/models/user.js'
import MessageBox from 'js/views/message_box.js'
import PreferencesModel from 'js/models/preferences.js'
import userManagementTemplate from 'templates/settings/user_management.handlebars'
import userManagementListItemViewTemplate from 'templates/settings/user_management_list_item_view.handlebars'
import addNewUserTemplate from 'templates/settings/user_management_add_new_user.handlebars'
import uiVisibilityTemplate from 'templates/settings/user_management_ui_visibility.handlebars'
import userPreloadedFieldsTemplate from 'templates/settings/user_management_preloaded_fields.handlebars'
import Utilities from 'js/utils/utilities'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'

var userErrorMessages = {
    UserDuplicateNameError: 'User with this name already exists',
    UserDuplicateEmailError: 'User with this email address already exists'
};


var UserList, UserItemView, NewUserView, UserUiVisibilityOptionsView, UserEditPreloadedFieldsView;


const uiVisibilityOptions = [
    {
        name: 'Search',
        id: 'search'
    },
    {
        name: 'Create New',
        id: 'create-new'
    },
    {
        name: 'Dashboard',
        id: 'dashboard'
    },
    {
        name: 'Tasks',
        id: 'tasks'
    },
    {
        name: 'Activities',
        id: 'activities'
    },
    {
        name: 'Contacts',
        id: 'contacts'
    },
    {
        name: 'Deals',
        id: 'deals'
    },
    {
        name: 'Messages',
        id: 'messages'
    },
    {
        name: 'Campaigns',
        id: 'campaigns'
    },
    {
        name: 'Automations',
        id: 'automations'
    },
    {
        name: 'Content',
        id: 'content'
    },
    {
        name: 'Forecasts',
        id: 'forecasts'
    },
    {
        name: 'Social',
        id: 'social'
    },
    {
        name: 'Web',
        id: 'web'
    }
];

UserItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(userManagementListItemViewTemplate),
    regions: {
        userUiVisibilityOptions: {
            selector: '.user-visibility-options', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        userEditPreloadedFields: {
            selector: '.user-preloaded-fields',
            regionType: ModalRegion
        }
    },
    ui: {
        name: '.name',
        editName: '.edit-name',
        email: '.email',
        editEmail: '.edit-email',
        role: '.role',
        // editRole: '.edit-role',
        select2_roles: '.edit-role',
        tooltips: '[data-toggle=tooltip]',
        itemMenu: '.item-menu',
        errorMessageContainer: '.error-message-container',
        errorMessage: '.error-message',
        borderBottom: '.item-content > li',
        teamContainer: '.team-container'
    },
    templateHelpers: function () {
        return {
            'isHelper': app.user.get('is_helper'),
            'teamsLength': this.model.teams.length,
            'roleEditable': AppConfig.getValue('app_settings.users.user_management.role.editable', true)
        };
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .delete-item': function() {
            var self = this;
            MessageBox.showYesNo(
                {
                    message: Handlebars.compile('Are you sure you want to delete user {{name}}? You will be made the owner of all active items owned by this user.')({name: this.model.get('name')}),
                    accept_is_negative: true,
                    icon: 'icon-trashcan'
                },
                this,
                function yesCallback() {
                    self.model.destroy({ wait: true });
                }
            );
        },
        'click .force-reset-password': function() {
            this.model.save({ 'password_reset_required': true }, {
                patch: true
            });
        },
        'click .toggle-admin': function(ev) {
            var isAdmin = this.model.get('is_admin'),
                model = this.model,
                view = this;

            model.save({'is_admin': !isAdmin}, {
                patch: true,
                extraFields: ['preferences'],
                success: function() {
                    $(ev.currentTarget).find('.switch').toggleClass('toggled', !isAdmin);
                    view.$el.toggleClass('is-admin', !isAdmin);
                },
                error: function() {
                    model.set('is_admin', isAdmin);
                }
            });
        },
        'click .toggle-helper': function(ev) {
            var isHelper = this.model.get('is_helper'),
                model = this.model,
                view = this;

            model.save({'is_helper': !isHelper}, {
                patch: true,
                extraFields: ['preferences'],
                success: function() {
                    $(ev.currentTarget).find('.switch').toggleClass('toggled', !isHelper);
                },
                error: function() {
                    model.set('is_helper', isHelper);
                }
            });
        },
        'click .item-menu-toggle': function() {
            var popover = this.ui.itemMenu;

            popover.show();

            $('body').on('mousedown.close-popover', function(ev) {
                if( !$(ev.target).is( popover.children() ) && !$(ev.target).closest(popover).length ) {
                    $(this).off('mousedown.close-popover');
                    popover.hide();
                }
            });
        },
        'click .ui-visibility-settings': function (ev) {
            var popover = this.ui.itemMenu;

            var userUiVisibilityOptionsView = new UserUiVisibilityOptionsView({
                user: this.model
            });

            this.userUiVisibilityOptions.show(userUiVisibilityOptionsView);

            this.listenTo(userUiVisibilityOptionsView, 'cancel', function () {
                this.userUiVisibilityOptions.reset();
            });

            popover.hide();
        },
        'click .edit-preloaded-fields': function (ev) {
            var popover = this.ui.itemMenu;

            var userEditPreloadedFields = new UserEditPreloadedFieldsView({
                user: this.model
            });

            this.userEditPreloadedFields.show(userEditPreloadedFields);

            this.listenTo(userEditPreloadedFields, 'cancel', function () {
                this.userEditPreloadedFields.reset();
            });

            popover.hide();
        },
        'click .toggle-disabled': function(ev) {
            var loginDisabled = this.model.get('login_disabled'),
                model = this.model

            model.save({'login_disabled': !loginDisabled}, {
                patch: true,
                extraFields: ['preferences'],
                success: function() {
                    $(ev.currentTarget).find('.switch').toggleClass('toggled', !loginDisabled);
                },
                error: function() {
                    model.set('login_disabled', loginDisabled);
                }
            });
        },
        'click .shadow-user': function(ev) {
            Utilities.setCookie('override_user_id', this.model.get('id'));
            Utilities.setCookie('override_user_name', this.model.get('name'));

            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'User shadowed',
                        classes: 'saved success',
                        timer: 3000
                    };
                }
            });
        }
    },
    onRender: function() {
        var pref,
            def,
            roles = {};

        $.extend(true, roles, PreferencesModel.roles);

        // set select for roles dropdown selected option
        if ((pref = this.model.get('preferences'))) {
            def = pref.default_dashboard;
        }
        if (!def) {
            def = 'sales_executive';
        }

        this.roleSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.select2_roles,
            text: 'title',
            value: roles[def] || roles['sales_executive'],
            data: _.values(roles),
            options: {
                containerCssClass: 'editable-field',
                dropdownCssClass: 'role-select-popover popover'
            }
        });

        this.activateElement(this.ui.name, this.ui.editName, {field: 'name'});
        this.activateElement(this.ui.email, this.ui.editEmail, {field: 'email_address'});
        this.activateSelect2(this.ui.role, this.ui.select2_roles, {
            field: 'preferences',
            subField: 'default_dashboard'
        });

        this.ui.tooltips.tooltip({
            container: this.$el,
            delay: {
                show: 400
            }
        });

        this.ui.teamContainer.tooltip({
            title: this.model.teams.join(', '),
            container: 'body',
            placement: 'top'
        })

        if (this.model.get('is_admin')) {
            this.$el.addClass('is-admin');
        }

        var self = this;
        _.defer(function() {
            self.ui.errorMessageContainer.css('width', self.$el.width());
        });
    },
    activateSelect2: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.select2('open');
        });

        edit.on('change', function() {
            var val = edit.val(),
                model = self.model,
                preferences,
                subMod;

            // temp solution for default_dashboard which resides in preferences, REST for preferences not supported
            subMod = self.model.get(dbField.field);
            preferences = subMod ? subMod : {};
            preferences[dbField.subField] = val;
            $.ajax({
                type: 'POST',
                url: model.url() + '/preferences',
                dataType: 'json',
                contentType: "application/json;charset=utf-8",
                data: JSON.stringify(preferences),
                success: function() {
                    text.text(edit.select2('data').title);
                    hideEdit();
                    if (app.user.get('id') === self.model.get('id')) {
                        app.user.set(dbField.field, preferences);
                    }
                }
            });
        });

        edit.on('select2-close', hideEdit);

        text.text(edit.select2('data').title);
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        function hideError() {
            self.ui.borderBottom.removeClass('hide-border-bottom');
            self.ui.errorMessageContainer.hide();
        }

        function showError(message) {
            var isName = (text === self.ui.name);

            self.ui.borderBottom.addClass('hide-border-bottom');
            self.ui.errorMessage
                .toggleClass('username-error', isName)
                .toggleClass('email-error', !isName)
                .addClass('validation_error')
                .text(message)
                .addClass('invalid');

            self.ui.errorMessageContainer.show();
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.val(self.model.get(dbField.field));
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val().trim(),
                preVal = self.model.get(dbField.field),
                attrs = {},
                model = self.model;

            hideError();

            self.listenTo(model, 'invalid', function(model, errors) {
                showError(errors.name_undefined || errors.name_too_short || errors.email_undefined || errors.email_invalid);
                self.stopListening(model, 'invalid');
            });

            // temp solution for default_dashboard which resides in preferences, REST for preferences not supported
            attrs[dbField.field] = val;

            model.save(attrs, {
                patch: true,
                extraFields: ['preferences'],
                validate: true,
                alert: false,
                complete: function() {
                    self.stopListening(model, 'invalid');
                },
                success: function(model, attributes, settings) {
                    vent.trigger('alert:show', { type: 'save', model: model, xhr: settings.xhr });
                    text.text(val);
                    hideEdit();
                },
                error: function(model, xhr) {
                    model.set(dbField.field, preVal);

                    var detail = JSON.parse(xhr.responseText).detail;
                    showError(userErrorMessages[detail.exception]);
                }
            });
        });

        edit.on('blur', function() {
            hideError();
            hideEdit();
        });
    }
});

UserList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'user-list editable-list dynamic-cols',
    itemView: UserItemView
});

UserEditPreloadedFieldsView = Marionette.ItemView.extend({
    className: 'edit-user-preloaded-fields edit-form-modal',
    template: Handlebars.compile(userPreloadedFieldsTemplate),
    ui: {
        yourKeysSellerReference: '#yourkeys-seller-reference',
        individualTagsInput: '.individual-preloaded-tags-input',
        individualFunnelsInput: '.individual-preloaded-funnels-input',
/*         organizationTagsInput: '.organization-preloaded-tags-input',
        opportunityTagsInput: '.opportunity-preloaded-tags-input', */
    },
    templateHelpers: function() {
        return {
            name: this.user.get('name'),
            teams: this.user.teams,
        }
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .close': function() {
            this.trigger('cancel');
        },
        'click .save': function() {
            var self = this;
            var userPrefs = this.user.get('preferences') || {};
            var yourKeysSellerReferenceValue = this.ui.yourKeysSellerReference.val();
            var individualFunnels = [];
            var individualTags = [];
/*             var organizationTags = [];
            var opportunityTags = []; */

            if ( ! yourKeysSellerReferenceValue ) {
                delete userPrefs.yourkeys_seller_reference;
            } else {
                userPrefs.yourkeys_seller_reference = yourKeysSellerReferenceValue;
            }

            for (let tagItem of this.individualPreloadedTags.getData()) {
                individualTags.push(tagItem.id);
            }

            for (let funnelItem of this.individualPreloadedFunnels.getData()) {
                individualFunnels.push(funnelItem.id);
            }

/*             for (let tagItem of this.organizationPreloadedTags.getData()) {
                organizationTags.push(tagItem.id);
            }

            for (let tagItem of this.opportunityPreloadedTags.getData()) {
                opportunityTags.push(tagItem.id);
            }
 */
            if ( ! userPrefs.preloaded_fields) {
                userPrefs.preloaded_fields = {};
            }

            if ( ! userPrefs.preloaded_fields.individuals) {
                userPrefs.preloaded_fields.individuals = {};
            }

/*             if ( ! userPrefs.preloaded_fields.organization) {
                userPrefs.preloaded_fields.organization = {};
            }

            if ( ! userPrefs.preloaded_fields.opportunity) {
                userPrefs.preloaded_fields.opportunity = {};
            } */

            userPrefs.preloaded_fields.individuals.tags = individualTags;
            userPrefs.preloaded_fields.individuals.funnels = individualFunnels;
            
/*             userPrefs.preloaded_fields.organization.tags = organizationTags;
            userPrefs.preloaded_fields.opportunity.tags = opportunityTags; */

            $.ajax({
                type: 'POST',
                url: this.user.url() + '/preferences',
                dataType: 'json',
                contentType: "application/json;charset=utf-8",
                data: JSON.stringify(userPrefs),
                success: function () {
                    vent.trigger('alert:show', { 
                        type: function() {
                            return {
                                message: 'User saved',
                                classes: 'success',
                                timer: 3000
                            }
                        }
                    });

                    self.trigger('cancel');
                },
                error: function() {
                    vent.trigger('alert:show', { 
                        type: function() {
                            return {
                                message: 'User failed to save',
                                classes: 'error',
                                timer: 3000
                            }
                        }
                    });
                }
            });
        }
    },
    initialize: function (options) {
        this.user = options.user;
    },
    onRender: function () {
        var userPrefs = this.user.get('preferences') || {};

        this.individualPreloadedTags = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.individualTagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,

            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder:'+ Add Tag',
                formatNoMatches: function() {
                    return 'Type to add a tag';
                },
                tokenSeparators: [',']
            }
        });
        this.individualPreloadedTags.setValue(this.user.getIndividualPreloadedTags());

        this.individualPreloadedFunnels = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.individualFunnelsInput,
            id: 'id',
            text: 'name',
            url: '/funnels',
            search: true,

            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder:`+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                formatNoMatches: function() {
                    return `Type to add a ${TextManager.getText('ID_FUNNEL')}`;
                },
                tokenSeparators: [',']
            }
        });
        this.individualPreloadedFunnels.setValue(this.user.getIndividualPreloadedFunnels());

/*         this.organizationPreloadedTags = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.organizationTagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,

            options: {
                placeholder: 'Add Tag',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder:'+ Add Tag',
                formatNoMatches: function() {
                    return 'Type to add a tag';
                },
                tokenSeparators: [',']
            }
        });

        this.organizationPreloadedTags.setValue(this.user.getOrganizationPreloadedTags());

        this.opportunityPreloadedTags = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.opportunityTagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,

            options: {
                placeholder: 'Add Tag',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder:'+ Add Tag',
                formatNoMatches: function() {
                    return 'Type to add a tag';
                },
                tokenSeparators: [',']
            }
        });

        this.opportunityPreloadedTags.setValue(this.user.getOpportunityPreloadedTags()); */

        if (userPrefs.yourkeys_seller_reference) {
            this.ui.yourKeysSellerReference.val(userPrefs.yourkeys_seller_reference);
        }
    },
});

UserUiVisibilityOptionsView = Marionette.ItemView.extend({
    className: 'edit-user-ui-visibility-settings edit-form-modal',
    template: Handlebars.compile(uiVisibilityTemplate),
    templateHelpers: function() {
        let options = _.clone(uiVisibilityOptions);
        const dealsSectionsStr = (app.user.get('client').preferences || {}).deals_sections;

        if (dealsSectionsStr) {
            const dealsSections = JSON.parse(dealsSectionsStr);

            for (const section of dealsSections) {
                options.push({
                    id: section.id,
                    name: section.name
                });
            }
        }

        return {
            name: this.user.get('name'),
            options: options
        }
    },
    ui: {
        search_tab: '#search-tab',
        create_new_tab: '#create-new-tab',
        dashboard_tab: '#dashboard-tab',
        tasks_tab: '#tasks-tab',
        activities_tab: '#activities-tab',
        contacts_tab: '#contacts-tab',
        deals_tab: '#deals-tab',
        messages_tab: '#messages-tab',
        campaigns_tab: '#campaigns-tab',
        automations_tab: '#automations-tab',
        content_tab: '#content-tab',
        forecasts_tab: '#forecasts-tab',
        social_tab: '#social-tab',
        web_tab: '#web-tab'
    },
    events: {
        'click .close': function() {
            this.trigger('cancel');
        },
        'click .save': function() {
            var self = this,
                preferences = this.user.get('preferences') || {};
            var uiVisibilityOptions = preferences.ui_visibility_options || {};

            for (var item in this.ui) {
                uiVisibilityOptions[item] = this.ui[item].prop('checked');
            }

            preferences.ui_visibility_options = uiVisibilityOptions;

            if (this.default_role_selected) {
                var role_selected = this.default_role_settings.find(role => role.id === this.default_role_selected);

                if (role_selected.extra_data) {
                    for (const [key, value] of Object.entries(role_selected.extra_data)) {
                        preferences[key] = value;
                    }
                }
            }

            $.ajax({
                type: 'POST',
                url: this.user.url() + '/preferences',
                dataType: 'json',
                contentType: "application/json;charset=utf-8",
                data: JSON.stringify(preferences),
                success: function () {
                    vent.trigger('alert:show', { 
                        type: function() {
                            return {
                                message: 'User saved',
                                classes: 'success',
                                timer: 3000
                            }
                        }
                    });
                    self.trigger('cancel')
                },
                error: function() {
                    vent.trigger('alert:show', { 
                        type: function() {
                            return {
                                message: 'User failed to save',
                                classes: 'error',
                                timer: 3000
                            }
                        }
                    });
                }
            });
        },
        'change #roles': function(ev) {
            this.default_role_selected = ev.val;

            var role_selected = this.default_role_settings.find(role => role.id === ev.val);

            for (const [key, value] of Object.entries(role_selected.options)) {
                this.ui[key].prop('checked', value);
            }
        }
    },
    initialize: function (options) {
        this.user = options.user;
        this.default_role_selected = null;

        this.default_role_settings = [
            {
                id: 'sales',
                name: 'Sales',
                options: {
                    activities_tab: false,
                    automations_tab: false,
                    campaigns_tab: false,
                    contacts_tab: true,
                    content_tab: true,
                    create_new_tab: true,
                    dashboard_tab: true,
                    deals_tab: true,
                    messages_tab: false,
                    forecasts_tab: false,
                    search_tab: true,
                    social_tab: false,
                    tasks_tab: true,
                    web_tab: false
                },
                extra_data: {
                    main_activity_filter: {
                        notes: true,
                        contact_updates: true,
                        deals: true,
                        web_activity: true
                    },
                    secondary_activity_filter: {
                        all: {
                            all_for_individuals: false,
                            all_for_opportunities: true
                        },
                        notes: true,
                        contact_updates: true,
                        deals: true,
                    },
                }
            },
            {
                id: 'marketing',
                name: 'Marketing',
                options: {
                    activities_tab: false,
                    automations_tab: false,
                    campaigns_tab: true,
                    contacts_tab: true,
                    content_tab: true,
                    create_new_tab: true,
                    dashboard_tab: false,
                    deals_tab: false,
                    messages_tab: false,
                    forecasts_tab: false,
                    search_tab: false,
                    social_tab: false,
                    tasks_tab: true,
                    web_tab: true
                }
            },
            {
                id: 'admin',
                name: 'Admin',
                options: {
                    activities_tab: true,
                    automations_tab: true,
                    campaigns_tab: true,
                    contacts_tab: true,
                    content_tab: true,
                    create_new_tab: true,
                    dashboard_tab: true,
                    deals_tab: true,
                    messages_tab: true,
                    forecasts_tab: true,
                    search_tab: true,
                    social_tab: true,
                    tasks_tab: true,
                    web_tab: true
                },
                extra_data: {
                    main_activity_filter: {
                        notes: true,
                        communications: true,
                        contact_updates: true,
                        deals: true,
                        campaign_emails: true,
                        automations: true,
                        web_activity: true
                    },
                    secondary_activity_filter: {
                        all: {
                            all_for_individuals: false,
                            all_for_opportunities: true
                        },
                        notes: true,
                        communications: true,
                        contact_updates: true,
                        deals: true,
                        campaign_emails: true,
                        automations: true,
                        web_activity: true,
                    }
                }
            }
        ];
    },
    onRender: function () {
        var uiVisibilityOptions = this.user.get('preferences').ui_visibility_options || {};
        const dealsSectionsStr = (app.user.get('client').preferences || {}).deals_sections;

        if (dealsSectionsStr) {
            const dealsSections = JSON.parse(dealsSectionsStr);

            for (const section of dealsSections) {
                this.ui[`${section.id}_tab`] = this.$el.find(`#${section.id}-tab`);
            }
        }

        for (var item in this.ui) {
            this.ui[item].prop('checked', _.isUndefined(uiVisibilityOptions[item]) ? true : uiVisibilityOptions[item]);
        }

        new backboneSelect2.SelectView({
            view: this,
            $el: this.$el.find('#roles'),
            text: 'name',
            data: this.default_role_settings,
            options: {
                placeholder: 'Select Default Role',
                containerCssClass: 'editable-field',
                dropdownCssClass: 'source-select-popover popover'
            }
        });
    }
});

NewUserView = Marionette.ItemView.extend({
    className: 'edit-user-profile edit-form-modal',
    template: Handlebars.compile(addNewUserTemplate),
    ui: {
        name: '#user-name',
        emailAddress: '#user-email',
        error_messages: '.error-message'
    },
    events: {
        'keypress #user-name,#user-email': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onShow: function() {
        this.ui.name.focus();
    },
    save: function() {
        var attrs = {
                name: this.ui.name.val().trim(),
                email_address: this.ui.emailAddress.val().trim(),
                // escape server error
                password: ''
            },
            user = new UserModel(),
            view = this;

        this.error_messages.remove.call(this);

        this.listenTo(user, 'invalid', this.invalid);
        user.save(attrs, {
            validate: true,
            alert: false,
            success: function(model, attributes, settings) {
                vent.trigger('alert:show', { type: 'save', model: model, xhr: settings.xhr });

                user.teams = [];
                view.trigger('new:user', [user]);
            },
            error: function(model, xhr) {
                var detail = JSON.parse(xhr.responseText).detail;
                var params = {};
                params[detail.exception] = userErrorMessages[detail.exception];
                view.invalid(view.model, params);
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);

        var showError = function(uiElement, text) {
            uiElement
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(text)
                .addClass('invalid');
        };

        if (errors.name_undefined || errors.name_too_short || errors.UserDuplicateNameError) {
            showError(this.ui.name, errors.name_undefined || errors.name_too_short || errors.UserDuplicateNameError);
        }

        if (errors.email_undefined || errors.email_invalid || errors.UserDuplicateEmailError) {
            showError(this.ui.emailAddress, errors.email_undefined || errors.email_invalid || errors.UserDuplicateEmailError);
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default Marionette.Layout.extend({
    template: Handlebars.compile(userManagementTemplate),
    regions: {
        userList: '.user-list-container',
        addNewUser: {
            selector: '.add-new-user', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    ui: {
        addNewUserButton: '.add-new-user'
    },
    events: {
        'click .add-new-user': function(ev) {
            var newUserView = new NewUserView();
            this.addNewUser.show(newUserView);
            this.listenTo(newUserView, 'new:user', function(user) {
                this.users.add(user);
                this.addNewUser.reset();
            });
            this.listenTo(newUserView, 'cancel', function() {
                this.addNewUser.reset();
            });
        }
    },
    initialize: function() {
        this.users = new UsersCollection();
    },
    onRender: function() {
        var self = this,
            teamsByUserId = this.getTeams();

        this.users.fetch({
            rows: -1,
            extraFields: [
                'preferences'
            ],
            success: function() {
                for (let user of self.users.models) {
                    let teams = [];

                    if (user.get('id') in teamsByUserId) {
                        teams = teamsByUserId[user.get('id')];
                    }

                    user.teams = teams;
                }

                self.userList.show(new UserList({ collection: self.users }));
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });
    },
    getTeams: function () {
        let self = this,
            teamsByUserId = {};

        for (let team of app.globalData.teams) {
            const usersId = self.getUsersId(team);

            for (const userId of usersId) {
                if (!(userId in teamsByUserId)) {
                    teamsByUserId[userId] = [];
                }

                if (teamsByUserId[userId].indexOf(team.name) === -1) {
                    teamsByUserId[userId].push(team.name);
                }
            }
        }

        return teamsByUserId;
    },
    getUsersId: function (team) {
        let usersId = [];

        for (const child of team.children) {
            usersId.push(child.user_id);

            if (child.children.length > 0) {
                usersId = usersId.concat(this.getUsersId(child));
            }
        }

        return usersId;
    },
    scrollEvents: function() {
        var container = this.$el.find('.content-container'),
            header = this.$el.find('.detail-header');

        $(container).find('.content').scroll(function() {
            if (container.find('.user-list').position().top < -1) {
                if (!header.hasClass('header-shadow')) {
                    header.addClass('header-shadow');
                }
            } else {
                header.removeClass('header-shadow');
            }
        });
    }
});

import React from 'react';
import ReactDOM from 'react-dom';
import Backbone from 'backbone';

import vent from 'js/vent';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import MessageBox from 'js/views/message_box';
import { NewSelect, TagSelect } from 'js/react_views/widgets/select';
import { Subject, Notes } from './common';
import AppConfig from 'app/app-config';
import dateFormat from 'js/utils/date-format';
import DateTimePicker from 'js/widgets/date-time-picker';
import htmlSanitizer from 'js/utils/html-sanitizer';
import TaskModel from 'js/models/task';
import IndividualInactiveModal from 'js/views/custom_code/individual_inactive_reasons_modal';
import ModalRegion from 'js/views/base/modal-region';

import style from './task.css';
import commonStyle from './common.css';


const ANIMATION_TIME = 400;
const PANEL_WIDTH = 463;

const RELATED_TYPE = {
    individuals: 'individual',
    individual: 'individual',
    opportunities: 'opportunity',
    opportunity: 'opportunity',
    organizations: 'organization',
    organization: 'organization'
};


class EditTask extends React.Component {
    constructor(props) {
        super(props);

        const task = props.task || {};

        this.isNew = !task.id;
        this.taskTypes = AppConfig.getValue('quick_add_task.types_list', []);

        let creator = null;
        let assignee = null;
        let related = null;

        if (task.creator) {
            creator = {
                id: task.creator.id,
                title: task.creator.name
            };
        }

        if (task.assignee) {
            assignee = {
                id: task.assignee.id,
                title: task.assignee.name
            };
        }

        if (task.related) {
            related = {
                id: task.related.id,
                title: task.related.name,
                type: task.related.type
            };
        }
        this.originalValues = {
            description: task.text || '',
            subject: task.subject || '',
            dueDate: task.due_date ? dateFormat.parseDate(task.due_date) : '',
            tags: task.tags || (props.preloaded ? app.user.getIndividualPreloadedTags() || null : null),
            related: related,
            creator: creator,
            assignee: assignee,
            taskType: this.taskTypes.find(t => t.id === task.task_type) || this.taskTypes[0],
            funnels: task.funnels || (props.preloaded ? app.user.getIndividualPreloadedFunnels() || null : null)
        };
        if (this.isNew) {
            this.originalValues.assignee = {
                id: app.user.get('id'),
                title: app.user.get('name')
            };

            this.originalValues.creator = {
                id: app.user.get('id'),
                title: app.user.get('name')
            };
        }

        this.state = {
            description: this.originalValues.description,
            subject: this.originalValues.subject,
            dueDate: this.originalValues.dueDate,
            tags: this.originalValues.tags,
            related: this.originalValues.related,
            creator: this.originalValues.creator,
            assignee: this.originalValues.assignee,
            taskType: this.originalValues.taskType,
            descriptionFocused: false,
            funnels: this.originalValues.funnels
        };
    }

    componentWillUpdate(nextProps) {
        if (nextProps.task?.related && !this.relatedUpdated) {
            const related = {
                id: nextProps.task.related.id,
                title: nextProps.task.related.name,
                type: nextProps.task.related.type || nextProps.task.related.related_type
            };

            this.relatedComponent.setValue(related);
            this.relatedUpdated = true;
            this.setState({
                related: related
            });

            if (this.isNew) {
                this.originalValues.related = related;
            }
        }

        if (AppConfig.getValue('tasks.preloaded_tags_field', true) && nextProps.task?.tags && !this.tagsUpdated) {
            this.tagsComponent.setValue(nextProps.task.tags);
            this.tagsUpdated = true;
            this.setState({
                tags: nextProps.task.tags
            });
            
            if (this.isNew) {
                this.originalValues.tags = nextProps.task.tags;
            }
        }

        if (AppConfig.getValue('tasks.preloaded_funnels_field', false) && nextProps.task?.funnels && !this.funnelsUpdated) {
            const properFunnelsValue = this.handleProperFunnels(nextProps.task.funnels)
            this.funnelsComponent.setValue(properFunnelsValue);
            this.funnelsUpdated = true;
            this.setState({
                funnels: properFunnelsValue
            });

            if (this.isNew) {
                this.originalValues.funnels = properFunnelsValue;
            }
        }
    }

    handleProperFunnels(funnels){
        if (funnels && Array.isArray(funnels[0])) {
            funnels = funnels[0]
        }
        return funnels
    }

    handleTaskTypeSelect(items) {
        this.setState({
            taskType: items[0]
        });
    }

    handleSubjectChange(subject) {
        this.setState({
            subject: subject
        });
    }

    handleDescriptionChange(ev) {
        this.setState({
            description: ev.target.value
        });
    }

    handleAssigneeChange(items) {
        this.setState({
            assignee: items[0]
        });
    }

    handleCreatorChange(items) {
        this.setState({
            creator: items[0]
        });
    }

    handleRelatedChange(items) {
        this.setState({
            related: items ? items[0] : null
        });
    }

    handleTagsChange(items) {
        this.tagsUpdated = true;
        this.setState({
            tags: items
        });
    }

    handleFunnelsChange(items) {
        this.funnelsUpdated = true;
        this.setState({
            funnels: items
        });
    }

    handleDueDateClick() {
        let date = null;
        let time = AppConfig.getValue('quick_add_task.default_time', '11:00');

        if (this.state.dueDate) {
            date = this.state.dueDate;
            time = dateFormat.shortFormatTime(date);
        }

        const target = $(this.dueDateComponent);
        const dateTimePicker = new DateTimePicker({
            altField: target,
            css: {
                left: target.offset().left - 280,
                top: target.offset().top + 34
            },
            date: date,
            time: time
        });

        dateTimePicker.showPicker();

        const self = this;

        this.props.parent.listenTo(dateTimePicker, 'date-time:change', (datetime) => {
            self.setState({
                dueDate: datetime.rawDateTime
            });
        });
    }

    getChanges(forClosing) {
        this.relatedComponent.setError(false);
        if (!this.state.related && !forClosing) {
            this.relatedComponent.setError(true);
            return null;
        } 

        if (AppConfig.getValue('tasks.enable_funnel_field', false) && (!this.state.funnels || this.state.funnels.length == 0) && !forClosing) {
            this.funnelsComponent.setError(true);
            return null;
        }

        const changes = {};
        const isNew = !forClosing && this.isNew;

        if (this.originalValues.description !== this.state.description) {
            changes.text = this.state.description;
        }

        if (this.originalValues.subject !== this.state.subject) {
            changes.subject = this.state.subject;
        }

        if (this.originalValues.dueDate !== this.state.dueDate) {
            changes.due_date = this.state.dueDate;
        }

        if ((this.originalValues.taskType?.id !== this.state.taskType?.id) || isNew) {
            changes.task_type = this.state.taskType.id;
        }

        const otags = this.originalValues.tags ? this.originalValues.tags.map(t => {return { id: t.id }}).sort() : [];
        const ntags = this.state.tags ? this.state.tags.map(t => { return { id: t.id }}).sort() : [];

        const ofunnels = this.originalValues.funnels ? this.originalValues.funnels.map(t => {return { id: t.id }}).sort() : [];
        const nfunnels = this.state.funnels ? this.state.funnels.map(t => { return { id: t.id }}).sort() : [];

        if ((JSON.stringify(otags) !== JSON.stringify(ntags)) || isNew) {
            changes.tags = ntags;
        }

        if ((JSON.stringify(ofunnels) !== JSON.stringify(nfunnels)) || isNew) {
            changes.funnels = nfunnels;
        }

        if (!_.isEqual(this.originalValues.related, this.state.related) || isNew) {
            changes.related_id = this.state.related.id;
            changes.related_type = RELATED_TYPE[this.state.related.type];
        }

        if (!_.isEqual(this.originalValues.creator, this.state.creator) || isNew) {
            changes.creator_id = this.state.creator.id;
        }

        if (!_.isEqual(this.originalValues.assignee, this.state.assignee) || isNew) {
            changes.assignee_id = this.state.assignee.id;
        }

        return changes;
    }

    dataHasChanged(forClosing) {
        return !_.isEmpty(this.getChanges(forClosing));
    }

    render() {
        const dueDate = this.state.dueDate ? `${dateFormat.shortFormatTime(this.state.dueDate)} ${dateFormat.shortFormatWithYear(this.state.dueDate)}` : '';

        return (
            <div className={style.tEdit}>
                <div className={style.eContent}>
                    <div className={commonStyle.cTitle}>
                        Subject
                    </div>

                    <div style={{marginTop: 5}}>
                        <Subject
                            value={this.state.subject}
                            suggestions={AppConfig.getValue('quick_add_task.subject_suggestions', null)}
                            onChange={this.handleSubjectChange.bind(this)}
                        />
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{width: '50%', marginRight: 5}}
                        >
                            Task Type
                        </div>

                        <div
                            className={commonStyle.cTitle}
                            style={{width: '50%', marginLeft: 5}}
                        >
                            Due Date
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5, position: 'relative'}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                data={this.taskTypes}
                                value={this.state.taskType}
                                text='title'
                                width={240}
                                onSelect={this.handleTaskTypeSelect.bind(this)}
                            />
                        </div>

                        <div style={{width: 'calc(50% - 5px)'}}>
                            <div
                                ref={(el) => this.dueDateComponent = el}
                                className={style.cDueDate}
                                onClick={this.handleDueDateClick.bind(this)}
                            >
                                {dueDate}
                            </div>
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div className={commonStyle.cTitle}>
                            Description
                        </div>

                        <textarea
                            className={`
                                ${style.cDescription}
                                ${this.state.descriptionFocused ? commonStyle.cFocused : ''}
                            `}
                            style={{marginTop: 5}}
                            placeholder='Description'
                            value={this.state.description}
                            onChange={this.handleDescriptionChange.bind(this)}
                            onFocus={() => this.setState({ descriptionFocused: true })}
                            onBlur={() => this.setState({ descriptionFocused: false })}
                        />
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginRight: 5
                            }}
                        >
                            Created by
                        </div>

                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginLeft: 5
                            }}
                        >
                            Assigned to
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                url='/users'
                                value={this.state.creator}
                                options={{minimumInputLength: 1}}
                                onSelect={this.handleCreatorChange.bind(this)}
                            />
                        </div>

                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                url='/users'
                                value={this.state.assignee}
                                options={{minimumInputLength: 1}}
                                onSelect={this.handleAssigneeChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginRight: 5
                            }}
                        >
                            Related to
                            <span className={commonStyle.tRequired}>&#x2733;</span>
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                ref={(el) => this.relatedComponent = el}
                                url='/v1/search'
                                text='title'
                                value={this.state.related}
                                placeholder='Select a Contact or Deal'
                                options={{
                                    allowClear: true,
                                    search_parameters: {
                                        types: 'individuals,organizations,opportunities',
                                        order_by: 'last_viewed desc'
                                    }
                                }}
                                onSelect={this.handleRelatedChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                        >
                            Tags
                        </div>

                        <div
                            style={{marginTop: 5}}
                        >
                            <TagSelect
                                ref={(el) => this.tagsComponent = el}
                                url='/tags'
                                value={this.state.tags}
                                text='name'
                                width={400}
                                placeholder='Add Tags'
                                onChange={this.handleTagsChange.bind(this)}
                            />
                        </div>
                    </div>

                    {AppConfig.getValue('tasks.enable_funnel_field', false) && (<div
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                        >
                            Developments
                        </div>
                        <div
                            style={{marginTop: 5}}
                        >
                            <TagSelect
                                ref={(el) => this.funnelsComponent = el}
                                url='/funnels'
                                value={this.state.funnels}
                                text='name'
                                width={400}
                                placeholder='Add Developments'
                                onChange={this.handleFunnelsChange.bind(this)}
                            />
                        </div>
                    </div>)}
                </div>
            </div>
        );
    }
}

class NextSteps extends React.Component {
    constructor(props) {
        super(props);

        this.activeStep = 0;
        this.state = {
            step: 'asking',
            askingFor: props.steps[this.activeStep],
            inputFocused: false,
            tags: [],
            dueDate: null
        };
    }

    initEditor() {
        const self = this;
        const input = $(this.inputComponent);
        const mainComponent = $(this.mainComponent);

        input.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor',
            statusbar: true,
            resize: false,
            relative_urls: false,
            remove_script_host: false,
            height: 0,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            oninit: function(activeEditor) {
                self.tinyEditor = activeEditor;
                self.tinyContainer = mainComponent.find('.tox-tinymce:first');
                self.tinyContainer.css({
                    'width': '100%',
                    'height': '190px',
                    'border-top-left-radius': '3px',
                    'border-top-right-radius': '3px',
                    'border-bottom-left-radius': '0',
                    'border-bottom-right-radius': '0',
                    'border': '1px solid #C1C4CD',
                    'border-bottom': '0'
                });

                activeEditor.on('focus', function() {
                    self.setState({ inputFocused: true });
                    self.tinyContainer.css('border-color', '#0A97FF');
                });

                activeEditor.on('blur', function() {
                    self.setState({ inputFocused: false });
                    self.tinyContainer.css('border-color', '#C1C4CD');
                });

                activeEditor.on('paste', function () {
                    _.defer(function() {
                        activeEditor.setContent(htmlSanitizer.sanitize(activeEditor.getContent(), true));
                    });
                });

                mainComponent.find('.tox-statusbar').css('display', 'none');
            },
            setup: function(ed) {
                ed.on('keydown', function (ev) {
                    if (ev.keyCode === 13 && ev.shiftKey === true) {
                        ev.preventDefault(); // Prevent creating a line-break on enter keydown
                        self.updateNote();
                    }
                });

                ed.on('init', function() {
                    this.getDoc().body.style.fontSize = '14px';
                    this.getDoc().body.style.color = '#666666';
                    this.getDoc().body.style['font-family'] = '"proxima-nova","Helvetica","Arial","sans-serif"';
                });
            }
        });
    }

    handleCreate() {
        if (this.state.askingFor === 'note') {
            this.setState({
                step: 'creatingNote'
            });

            const self = this;

            _.defer(function() {
                self.initEditor();
            });
        } else if (this.state.askingFor === 'task') {
            this.props.onFinish(true);
        }
    }

    handleCancel() {
        if (this.state.askingFor === 'note') {
            if (AppConfig.getValue(`on_task_completation.activity_note_popup.${this.props.task.related.type}.show_inactive_reasons_popup`, false)) {
                const region = this.props.parent.addRegion('individual_inactive_modal', { selector: 'message-box', regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false }) });

                const inactiveModal = new IndividualInactiveModal({
                    individual_id: this.props.task.related.id
                });

                region.show(inactiveModal);

                const self = this;

                this.props.parent.listenTo(inactiveModal, 'cancel', function () {
                    region.reset();
                    self.nextStep();
                });
            } else {
                AppConfig.getValue(`on_task_completation.activity_note_popup.${this.props.task.related.type}.cancel_action`, null, this.props.task.related.id);

                this.nextStep();
            }
        } else {
            this.nextStep();
        }
    }

    handleTagsChange(items) {
        this.setState({
            tags: items
        });
    }

    handleDateClick(ev) {
        if (this.state.dueDate) {
            this.setState({dueDate: null});
        } else {
            const self = this;
            const target = $(self.inputCalendarComponent);

            target.datepicker({
                numberOfMonths: 2,
                dateFormat: 'M d, yy',
                timezone: "+0000",
                showButtonPanel: true,
                yearRange: '-99:+10',
                changeYear: true,
                onSelect: function(dateStr) {
                    self.setState({dueDate: dateStr});
                }
            });

            target.datepicker('show');
        }
    }

    handlePost() {
        const self = this;
        let tags = this.state.tags;

        const createNote = () => {
            const text = htmlSanitizer.sanitize($(this.inputComponent).val().replace(/(\n)/gm,""));

            $.post('/activities', JSON.stringify({
                activity_type: 'note',
                item_id: this.props.task.related.id,
                item_type: this.props.task.related.type,
                note: text,
                tags: tags,
                target_date: this.state.dueDate || null
            }), function(result) {
                self.nextStep();
            });
        }

        // ...
        if (AppConfig.getValue('activities.notes.on_creation.suggested_tags.check_condition', false, tags.map(t => t.id))) {
            const availableTags = AppConfig.getValue('activities.notes.on_creation.suggested_tags.tags_list');

            if (availableTags && availableTags.length > 0) {
                const self = this;

                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'Please ensure one of these tags is added',
                    accept_button_text: 'Save'
                }, this.props.parent, function(idsList) {
                    if (idsList.length > 0) {
                        tags = [...tags, ...idsList.map(id => { return { id: id } })];
                    }

                    createNote();
                }, {
                    type: 'tagSelect',
                    availableTags: availableTags
                }, null, true);
            } else {
                createNote();
            }
        } else {
            createNote();
        }
    }

    nextStep() {
        if (this.state.step === 'creatingNote' && this.tinyEditor) {
            this.tinyEditor.off();
            this.tinyEditor.destroy();
        }

        ++this.activeStep;

        if (this.activeStep < this.props.steps.length) {
            this.setState({
                step: 'asking',
                askingFor: this.props.steps[this.activeStep]
            });
        } else {
            this.props.onFinish();
        }
    }

    render() {
        if (this.state.step === 'asking') {
            const message = this.state.askingFor === 'note' ? 'What do you want to do next?' : 'Do you want to add a follow up task?';
            const createButtonCaption = `Create ${this.state.askingFor === 'note' ? 'activity note' : 'another task'}`;
            const defaultCloseCaption = `Close without ${this.state.askingFor === 'note' ? 'note' : 'task'}`;
            const closeButtonCaption = this.state.askingFor === 'note' ?
                AppConfig.getValue(`on_task_completation.activity_note_popup.${this.props.task.related.type}.cancel_button_text`, defaultCloseCaption) :
                defaultCloseCaption;

            return (
                <div className={style.tNextSteps}>
                    <div className={style.nsAsk}>
                        <div className={style.aMessage}>{message}</div>
                        <div className={style.aButtons}>
                            <div
                                className={style.bClose}
                                onClick={this.handleCancel.bind(this)}
                            >
                                {closeButtonCaption}
                            </div>
                            <div
                                className={style.bCreate}
                                onClick={this.handleCreate.bind(this)}
                            >
                                {createButtonCaption}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.step === 'creatingNote') {
            let date = ' (past)';

            if (this.state.dueDate) {
                date = `: ${dateFormat.shortFormatWithYear(dateFormat.parseDate(this.state.dueDate))}`;
            }

            const iconTitle = `Activity date${date}`;

            return (
                <div
                    ref={(el) => this.mainComponent = el}
                    className={style.tNextSteps}
                >
                    <div className={style.nsEditor}>
                        <textarea
                            ref={(el) => this.inputComponent = el}
                            onFocus={() => this.setState({ inputFocused: true })}
                            onBlur={() => this.setState({ inputFocused: false })}
                        />

                        <div
                            className={`
                                ${style.eToolbar}
                                ${this.state.inputFocused ? style.tFocused : ''}
                            `}
                        >
                            <input
                                ref={(el) => this.inputCalendarComponent = el}
                                style={{display: 'none'}}
                            />
                            <div
                                className={`
                                    ${style.tIcon}
                                    ${this.state.dueDate ? style.iActive : ''}
                                `}
                                title={iconTitle}
                                onClick={this.handleDateClick.bind(this)}
                            >
                                <i className='icon-history'/>
                            </div>
                            <div style={{width: '100%'}}>
                                <TagSelect
                                    url='/tags'
                                    value={this.state.tags}
                                    text='name'
                                    placeholder='Add Tags'
                                    width='326'
                                    onChange={this.handleTagsChange.bind(this)}
                                    options={{
                                        noBorder: true
                                    }}
                                />
                            </div>
                        </div>

                        <div className={style.eButtons}>
                            <div
                                className={`${style.bButton} ${style.bCancel}`}
                                onClick={this.nextStep.bind(this)}
                            >
                                Cancel
                            </div>
                            <div
                                className={`${style.bButton} ${style.bPost}`}
                                onClick={this.handlePost.bind(this)}
                            >
                                Post
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }
}


export class ViewTask extends React.Component {
    constructor(props) {
        super(props);

        this.nextSteps = [];

        this.state = {
            expanded: !!props.fullView || !!props.expanded,
            completed: props.task.completed,
            showNextSteps: false,
            numComments: 0
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.showNextSteps && this.props.task.completed) {
            this.manageNextSteps();
        }

        if (!this.props.fullView) {
            const self = this;

            const args = {
                rows: -1,
                order_by: 'user_created desc',
                item_type: 'tasks',
                item_id: this.props.task.id,
                tasks: true
            };

            $.get(`/activities/?${$.param(args)}`, function(result) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    numComments: result.length
                });
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    manageNextSteps() {
        this.nextSteps = [];

        if (AppConfig.getValue('on_task_completation.show_activity_note_popup')) {
            this.nextSteps.push('note');
        }

        if (AppConfig.getValue('on_task_completation.show_task_popup')) {
            this.nextSteps.push('task');
        }

        if (this.nextSteps.length > 0) {
            if (this.props.fullView) {
                this.setState({
                    showNextSteps: true
                });

                this.props.onHideFooter();
            } else {
                vent.trigger('quick:edit:task', new Backbone.Model({ id: this.props.task.id }), { showNextSteps: true });
            }
        }
    }

    toggleVisualization(ev) {
        ev.stopPropagation();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    toggleCompletation(ev) {
        ev.stopPropagation();

        const completed = !this.state.completed;
        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/tasks/${this.props.task.id}`,
            data: JSON.stringify({ completed: completed }),
            contentType: 'application/json',
            dataType: 'json',
            success: function() {
                vent.trigger('update-checklist');
                vent.trigger('task:updated');

                self.setState({
                    completed: completed
                });

                if (completed) {
                    self.manageNextSteps();
                }
            }
        });
    }

    handleNextStepsFinish(createNewTask) {
        this.setState({
            showNextSteps: false
        });

        this.props.onShowFooter();

        if (createNewTask) {
            this.props.onCreateTask();
        }
    }

    gotoFullView(ev, editing) {
        ev.stopPropagation();

        if (this.props.fullView) {
            return;
        }

        vent.trigger('quick:edit:task', new Backbone.Model({ id: this.props.task.id }), { editing: editing });
    }

    deleteTask(ev) {
        ev.stopPropagation();

        const mbContent = {
            message: 'Are you sure you want to <strong>permanently</strong> delete this task?',
            icon: 'icon-trashcan',
            accept_is_negative: true
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            $.ajax({
                type: 'DELETE',
                url: `/tasks/${self.props.task.id}`,
                success: function () {
                    vent.trigger('task:deleted');
                }
            });
        });
    }

    handleRelatedClick() {
        const related = this.props.task.related;

        if (!related?.id || !this.props.fullView) {
            return null;
        }

        const url = related.type === 'opportunities' ? 'deals' : related.type;

        window.location = `/#${url}/${related.id}`;

        if (this.props.fullView) {
            this.props.onClose();
        }
    }

    handleCommentsClick(ev) {
        if (this.props.fullView) {
            return;
        }

        this.gotoFullView(ev, false);
    }

    render() {
        const task = this.props.task;
        const ddate = task.due_date ? dateFormat.parseDate(task.due_date) : null;
        const dueDate = ddate ? `${dateFormat.shortFormatTime(ddate)} ${dateFormat.shortFormatWithYear(ddate)}` : null;
        const taskTypes = AppConfig.getValue('quick_add_task.types_list', []);
        const taskType = taskTypes.find(t => t.id === task.task_type)?.title || '-';

        return (
            <div className={`
                ${style.tView}
                ${this.state.completed ? style.tCompleted : ''}
                ${this.state.showNextSteps ? style.tShowingNextSteps : ''}
                ${this.state.expanded ? style.tExpanded : ''}
                ${this.props.fullView ? style.tFullView : ''}
            `}>
                <div className={style.vContent}>
                    <div
                        className={style.cHeader}
                        onClick={this.gotoFullView.bind(this)}
                    >
                        <div
                            className={`
                                ${style.hChecker}
                                ${this.state.completed ? style.cActive : ''}
                            `}
                            onClick={this.toggleCompletation.bind(this)}
                        >
                            <div className={`${style.cMark} icon-checkmark3`}/>
                        </div>

                        <div className={style.hSubject}>
                            {task.subject || task.text}
                        </div>

                        <div className={style.hRight}>
                            {dueDate && <div className={`${style.rIcon} icon-clock`}/>}
                            {dueDate && <div className={style.rDate}>{dueDate}</div>}

                            {!this.props.fullView &&
                                <div
                                    className={`
                                        ${style.rToggler}
                                        ${this.state.expanded ? 'icon-caret-up' : 'icon-caret-down'}
                                    `}
                                    onClick={this.toggleVisualization.bind(this)}
                                />
                            }
                        </div>

                        {!this.props.fullView &&
                            <div className={style.hHover}>
                                {this.state.expanded &&
                                    <div
                                        className={style.hItem}
                                        onClick={this.deleteTask.bind(this)}
                                    >
                                        <div
                                            className='icon-trashcan'
                                            style={{color: '#E54A45'}}
                                        />
                                    </div>
                                }
                                <div
                                    className={style.hItem}
                                    onClick={(ev) => this.gotoFullView(ev, true)}
                                >
                                    <div
                                        className='icon-pencil'
                                        style={{color: '#0A97FF'}}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    {this.state.expanded && !this.state.showNextSteps &&
                        <div className={style.cBody}>
                            <div style={{background: '#E8EBED', height: '1px'}}/>

                            <div className={style.cText}>
                                {task.text}
                            </div>

                            <div className={style.cInfo}>
                                <div className={style.iRow}>
                                    <div className={style.iItem}>
                                        <div className={style.iTitle}>Assigned to</div>
                                        <div className={style.iValue} title={task.assignee?.name}>{task.assignee?.name || ''}</div>
                                    </div>

                                    <div className={style.iItem}>
                                        <div className={style.iTitle}>Comments</div>
                                        <div
                                            className={`${style.iValue} ${this.props.fullView ? '' : style.vLink}`}
                                            onClick={this.handleCommentsClick.bind(this)}
                                        >
                                            {this.state.numComments === 0 ? 'No comments' : `${this.state.numComments} Comment${this.state.numComments !== 1 ? 's' : ''}`}
                                        </div>
                                    </div>
                                </div>

                                <div className={style.iRow}>
                                    <div className={style.iItem}>
                                        <div className={style.iTitle}>Related to</div>
                                        <div
                                            className={`${style.iValue} ${this.props.fullView ? style.vLink : ''}`}
                                            title={task.related?.name}
                                            onClick={this.handleRelatedClick.bind(this)}
                                        >
                                            {task.related?.name || ''}
                                        </div>
                                    </div>

                                    <div className={style.iItem}>
                                        <div className={style.iTitle}>Task Type</div>
                                        <div
                                            className={style.iValue}
                                            title={taskType}
                                        >
                                            {taskType}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.props.fullView &&
                                <div style={{marginTop: '20px'}}>
                                    <Notes
                                        entityType='tasks'
                                        entityId={task.id}
                                        parent={this.props.parent}
                                        onNotesUpdated={(notes) => this.setState({ numComments: notes.length })}
                                    />
                                </div>
                            }

                            {task.tags.length > 0 &&
                                <div className={style.cTags}>
                                    {task.tags.map(tag => {
                                        return (
                                            <div
                                                key={`tag_${tag.id}`}
                                                className={style.tTag}
                                            >
                                                {tag.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {task.funnels !== undefined && task.funnels.length > 0 &&
                                <div className={style.cTags}>
                                    {task.funnels.map(funnel => {
                                        return (
                                            <div
                                                key={`funnel_${funnel.id}`}
                                                className={style.tTag}
                                            >
                                                {funnel.name}
                                            </div>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                    }

                    {this.state.expanded && this.state.showNextSteps &&
                        <NextSteps
                            task={task}
                            steps={this.nextSteps}
                            parent={this.props.parent}
                            onFinish={this.handleNextStepsFinish.bind(this)}
                        />
                    }
                </div>
            </div>
        );
    }
}

class TaskPanelReact extends React.Component {
    constructor(props) {
        super(props);

        const activeView = props.id ? 'view' : 'edit';
        let task = {};

        this.state = {
            right: -PANEL_WIDTH,
            activeView: activeView,
            loading: activeView === 'view',
            task: _.isEmpty(task) ? null : task,
            showFooter: true
        };
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        if (this.props.id) {
            $.get(`/tasks/${this.props.id}`, function(result) {
                self.setState({
                    task: result,
                    loading: false
                });

                vent.trigger('AppContent:contentChange');

                if (self.props.editing) {
                    self.handleEdit();
                }
            });
        } else if (this.props.preloadedFields) {
            this.setState({
                task: this.props.preloadedFields
            });
        }

        _.defer(function() {
            self.setState({
                right: 0
            });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    patchTask(data, callback) {
        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/tasks/${this.props.id}`,
            data: JSON.stringify(data),
            success: function(result) {
                vent.trigger('task:updated');
                callback(result);
            }
        });
    }

    handleSave() {
        const data = this.component.getChanges(false);

        if (data) {
            this.setState({
                loading: true
            });

            const self = this;

            if (!this.props.id) {
                $.post('/tasks', JSON.stringify(data), function(result) {
                    vent.trigger('task:created');
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Task saved',
                                classes: 'success',
                                timer: 3000
                            };
                        }
                    });

                    self.handleClose();
                });
            } else {
                self.patchTask(data, function(task) {
                    self.setState({
                        loading: false,
                        task: task,
                        activeView: 'view'
                    });
                });
            }
        }
    }

    handleEdit() {
        this.setState({
            activeView: 'edit'
        });
    }

    handleCancel() {
        const mbContent = {
            accept_is_negative: true,
            message: 'Are you sure you want to cancel the task?',
            icon: 'icon-warning'
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            self.setState({
                loading: true
            });

            self.patchTask({
                status: 'cancelled'
            }, function(task) {
                self.setState({
                    loading: false,
                    task: task,
                    activeView: 'view'
                });
            });
        });
    }

    handleClose(checkUnsavedChanges, backdropClick) {
        const close = () => {
            if (this.state.activeView === 'edit' && this.state.task?.id && !backdropClick) { // editing an existing task. Going back to the 'view' view
                this.setState({
                    activeView: 'view'
                });
            } else {
                const self = this;

                this.setState({
                    right: -PANEL_WIDTH
                });

                _.delay(function() {
                    self.props.onClose();
                    vent.trigger('AppContent:contentChange');
                }, ANIMATION_TIME);
            }
        }

        if (this.state.activeView === 'edit' && checkUnsavedChanges && this.component.dataHasChanged(true)) {
            const mbContent = {
                accept_is_negative: true,
                message: 'Unsaved data will be lost. Are you sure you want to continue?',
                icon: 'icon-warning'
            };

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                close();
            });
        } else {
            close();
        }
    }

    createNewTask() {
        vent.trigger('quick:add:task', new TaskModel({ related: this.state.task.related }));
    }

    render() {
        let Component = null;
        let title = 'Task';
        let preloadedFields = this.props.id ? false : true;
        if (this.state.activeView === 'edit') {
            title = this.props.id ? 'Edit Task' : 'Create Task';
            Component = EditTask;
        } else if (this.state.task) {
            Component = ViewTask;
        }

        return (
            <div>
                <div
                    className={commonStyle.aTopBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aLeftBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aDialog}
                    style={{
                        right: this.state.right
                    }}
                >
                    <div className={commonStyle.dHeader}>
                        <div
                            className={commonStyle.hClose}
                            onClick={() => this.handleClose.bind(this)(true)}
                        >
                            {(this.state.activeView === 'edit' && this.state.task?.id) ? 'Back' : 'Close'}
                        </div>

                        <div className={commonStyle.hTitle}>
                            {title}
                        </div>
                    </div>

                    {Component &&
                        <div className={commonStyle.dContent}>
                            <Component
                                ref={(el) => this.component = el}
                                task={this.state.task}
                                parent={this.props.parent}
                                fullView={true}
                                showNextSteps={this.props.showNextSteps}
                                onClose={this.handleClose.bind(this)}
                                onEdit={this.handleEdit.bind(this)}
                                onHideFooter={() => this.setState({ showFooter: false })}
                                onShowFooter={() => this.setState({ showFooter: true })}
                                onCreateTask={this.createNewTask.bind(this)}
                                preloaded={preloadedFields}
                            />
                        </div>
                    }

                    {this.state.activeView === 'edit' &&
                        <div className={commonStyle.dFooter}>
                            {/*this.state.task?.id && this.state.task.status !== 'cancelled' &&
                                <div
                                    className={`${commonStyle.fButton} ${commonStyle.bRed}`}
                                    onClick={this.handleCancel.bind(this)}
                                >
                                    Cancel Task
                                </div>
                            */}

                            <div
                                className={`${commonStyle.fButton} ${commonStyle.bGreen}`}
                                style={{marginLeft: 'auto'}}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    }

                    {this.state.activeView === 'view' && this.state.showFooter &&
                        <div className={commonStyle.dFooter}>
                            {/*this.state.task?.id && this.state.task.status !== 'cancelled' &&
                                <div
                                    className={`${commonStyle.fButton} ${commonStyle.bRed}`}
                                    onClick={this.handleCancel.bind(this)}
                                >
                                    Cancel Task
                                </div>
                            */}

                            <div
                                className={`${commonStyle.fButton} ${commonStyle.bBlue}`}
                                style={{marginLeft: 'auto'}}
                                onClick={this.handleEdit.bind(this)}
                            >
                                Edit
                            </div>
                        </div>
                    }

                    {this.state.loading &&
                        <div className={commonStyle.dLoading}>
                            <LoadingIndicator/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const TaskPanel = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <TaskPanelReact
                parent={this}
                {...this.options || {}}
                onClose={() => this.trigger('panel:close')}
            />,
            this.$el.get(0)
        );
    },
    getTaskId: function() {
        return this.options.id || null;
    }
});

export default TaskPanel;
import React from 'react';
import ReactDOM from 'react-dom';
import { FilterItem } from 'js/react_views/automation/automation-node-create-edit'

class ReactPhaseFilterItemsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: props.filters
        };

        this.handleFilterClick = this.handleFilterClick.bind(this);
    }

    handleFilterClick(ev, rule, ind) {
        const callback = (filter) => {
            this.setState({
                filter_id: filter.id,
                filter: filter
            });
        };

        this.props.showFilter(ev.target, callback, this.state.filter, rule, ind);
    }

    removeFilterRule(ind) {
        const callback = (filter) => {
            this.setState({
                filter_id: filter.id,
                filter: filter
            });
        };
        this.props.removeFilterRule(callback, this.state.filter, ind);
    }

    render(){
        const {filterFields} = this.props
        const {filter} = this.state
        var self = this
        return (
            <div className="filter-input-container">
                <label htmlFor="filters">
                    <i className="icon-search"></i> Filters 
                    {
                        self.state.filter && (_.size(self.state.filter.rules) > 0)
                        && <span className="filter-count-label">{_.size(self.state.filter.rules)}</span>
                    }
                </label>
                <input
                    className="filter-input"
                    placeholder="Filter..."
                    name="filters"
                    id="filters"
                    value=""
                    onClick={this.handleFilterClick}/>
                {
                    filter && 
                    filter.rules.map((rule, ind) => {
                        return <FilterItem
                                key={ind}
                                rule={rule}
                                edit={(ev) => { self.handleFilterClick(ev, rule, ind); }}
                                remove={() => { self.removeFilterRule(ind); }}
                                filterFields={filterFields}
                            />
                    })
                }
            </div>
        )
    }
}

var PhaseFilterItemsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <ReactPhaseFilterItemsView 
                filters={this.options.filters} 
                filterFields={this.options.filterFields} 
                showFilter={this.options.showFilter}
                removeFilterRule={this.options.removeFilterRule}/>,
            this.$el.get(0)
        );
    },
});

export default PhaseFilterItemsView;
import React from 'react';

import style from './filters_bar.css';

class FiltersBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={style.sFiltersBar}>
                <div className={style.fMessage}>Filters</div>
                <div className={style.fCounter}>{this.props.filters.length}</div>

                <div className={style.fContainer}>
                    {this.props.filters.map((item, iidx) => {
                        return (
                            <div
                                key={`f_${item.id}_${iidx}`}
                                className={style.fItem}
                            >
                                {item.title}

                                <div
                                    className={`${style.fClose} icon-cross`}
                                    onClick={() => this.props.onFilterRuleDeleted(item)}
                                />
                            </div>
                        )
                    })}
                </div>

                <div
                    className={style.fClearAll}
                    onClick={this.props.onAllFiltersRulesDeleted}
                >
                    Clear all
                </div>
            </div>
        );
    }
}

export default FiltersBar;
import _ from 'underscore'
import ModalRegion from 'js/views/base/modal-region'
import contextMenuTemplate from 'templates/content/mailshot_popover.handlebars'
import contextMenuItemTemplate from 'templates/content/mailshot_popover_item.handlebars'

var PopoverItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(contextMenuItemTemplate),
    events: {
        'click': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            if (this.isClickable(this.model)) {
                this.trigger('click', this.model);
            }
        }
    },
    isClickable: function(model) {
        return !this.isDisabled(model) && !this.isSeperator(model);
    },
    isDisabled: function(model) {
        var disabled = model.get('disabled');

        if (_.isFunction(disabled)) {
            return disabled();
        }

        return disabled;
    },
    isSeperator: function(model) {
        return model.get('seperator');
    }
});

var PopoverCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'basic-list popover-list',
    defaultItemView: PopoverItemView,
    getItemView: function(model) {
        var view = model.get('view');
        if (!view) {
            if (this.getDefaultItemView) {
                view = this.getDefaultItemView(model);
            } else {
                view = this.defaultItemView;
            }
        }

        return view;
    }
});

function initPanes(popover) {
    popover.showPane = function(pane) {
        var previous = this.$el.find('.popover-pane.active');

        previous.removeClass('active');
        pane.addClass('active');
    };
    popover.resetPanes = function() {
        var root = this.$el.find('.popover-pane.root');
        this.showPane(root);
    };
}

var CampaignMenuPopover = Marionette.Layout.extend({
    className: 'popover popover-panes right',
    template: Handlebars.compile(contextMenuTemplate),
    collectionView: PopoverCollectionView,
    regions: {
        listRegion: '.basic-list-container',
        previewRegion: {
            selector: '.preview-region', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    ui: {
        cancelConfirmation: '.cancel-confirm-pane',
        delete_confirmation: '.delete-confirm-pane'
    },
    events: {
        'click .cancel': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.resetPanes();
        },
        'click .cancel-confirm-pane .save': function() {
            this.trigger('popover:click:cancel-confirm');
        },
        'click .delete-confirm-pane .save': function() {
            this.trigger('popover:click:delete-confirm');
        }
    },
    onRender: function() {
        this.listRegion.show(new this.collectionView({
            collection: this.collection
        }));
        this.listenTo(this.listRegion.currentView, 'itemview:click', function(itemView, model) {
            this.trigger('popover:click:' + model.get('name'), itemView, model);
        });

        initPanes(this);

        // Show cancel confirmation pane
        this.on('popover:click:cancel-campaign', function() {
            this.showPane(this.ui.cancelConfirmation);
        });
        // Show delete confirmation pane
        this.on('popover:click:delete', function() {
            this.showPane(this.ui.delete_confirmation);
        });
    },
    onShow: function() {
        var view = this;

        $(document).on('mousedown.hide-item-menu focusin.hide-item-menu', function(ev) {
            if (
                !$(ev.target).is( view.$el.find('*') ) &&
                !$(ev.target).closest(view.$el).length
            ) {
                view.close();
                $(document).off('mousedown.hide-item-menu focusin.hide-item-menu');
            }
        });
    },
    onClose: function() {
        $(document).off('mousedown.hide-item-menu focusin.hide-item-menu');
    }
});

export default CampaignMenuPopover;
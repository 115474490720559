import $ from 'jquery'
import React from 'react'

import app from 'js/app'
import vent from 'js/vent'
import Utilities from 'js/utils/utilities'
import AppConfig from 'app/app-config'
import TaskModel from 'js/models/task'
import {Panel, PanelRenderer} from './widgets/core'
import {ANCHOR_BITFIELD, EDITOR_WIDTH, GRID_SIZE, adjustToGrid} from './defs'
import BuiltInDashboards from './builtin_dashboards'
import {DashboardEditor} from './editor'

import style from './dashboard.css'

class Fetcher {
    constructor(props) {
        this.cache = {};
        this.activeQueries = {};
        this.activeFunnel = null;
        this.activeClusterFunnels = null;
        this.isCluster = null;
    }

    setActiveFunnel(funnel) {
        this.activeFunnel = _.clone(funnel);
    }

    setActiveClusterFunnels(funnels) {
        this.activeClusterFunnels = _.clone(funnels)
    }

    setIsCluster(isCluster) {
        this.isCluster = _.clone(isCluster)
    }

    getMergeTagValue(mergeTag, item) {
        switch(mergeTag) {
            case '${active_cluster.cluster}': {
                const currentClusterSelected = this.activeClusterFunnels[0].item.cluster

                if(!currentClusterSelected) {
                    return
                }

                return {
                    id: currentClusterSelected.id,
                    name: currentClusterSelected.name
                }
            }
            case '${active_cluster.funnels}': {
                if (!this.activeClusterFunnels) {
                    return 
                }
                return this.activeClusterFunnels?.map(funnel => {return {id: funnel.id, name: funnel.name}})
            }
            case '${individual_development_filter}': {
                return this.isCluster ? 'funnel_cluster_id' : 'individual_funnels'
            }
            case '${individual_development_filter_value}': {
                return this.isCluster ? this.getMergeTagValue('${active_cluster.cluster}') : {
                    id: this.getMergeTagValue('${active_funnel.funnel_id}'),
                    name: this.getMergeTagValue('${active_funnel.name}')
                }
            }
            case '${task_development_filter}': {
                return this.isCluster ? 'funnel_cluster_id' : 'task_funnels'
            }
            case '${task_development_filter_value}': {
                return this.isCluster ? this.getMergeTagValue('${active_cluster.cluster}') : this.getMergeTagValue('${active_funnel.funnel}')
            }
            case '${active_funnel.funnel_id}': {
                if (this.activeClusterFunnels) {
                    return (this.activeClusterFunnels.map(f => f.id)).join(',')
                }
                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === this.activeFunnel.id);

                if (!funnel) {
                    return null;
                }
                return funnel.id
            }
            case '${active_funnel.tag_id}': {
                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === this.activeFunnel.id);

                if (!funnel) {
                    return null;
                }
                const tag = app.globalData.tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                if (!tag) {
                    return null;
                }

                return tag.id;
            }
            case '${active_funnel.funnel}': {
                return this.isCluster ? this.getMergeTagValue('${active_cluster.funnels}') : {
                    id: this.getMergeTagValue('${active_funnel.funnel_id}'),
                    name: this.getMergeTagValue('${active_funnel.name}')
                }
            }
            case '${active_funnel.tag}': {
                const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === this.activeFunnel.id);

                if (!funnel) {
                    return null;
                }
                const tag = app.globalData.tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                if (!tag) {
                    return null;
                }

                return {
                    id: tag.id,
                    name: tag.name
                };
            }

            case '${active_funnel.name}':
                if (this.activeClusterFunnels && !this.isCluster) {
                    return this.activeClusterFunnels[0].name
                }
                return this.activeFunnel.name;

            case '${active_funnel.id}':
                if (this.activeClusterFunnels && !this.isCluster) {
                    return this.activeClusterFunnels[0].id
                }
                return this.activeFunnel.id;

            case '${date.today}':
                return new Date().toISOString().split('T')[0];

            case '${datetime.today}':
                return new Date().toISOString();

            case '${item.id}':
                return item?.id;

            case '${item.name}':
                return item?.name;

            case '${user.id}':
                    return app.user.get('id');

            case '${user.salesseeksupport}': {
                const userName ="SalesSeek Support";
                const userId = app.globalData.users.find(x=> x.name.toLowerCase() === userName.toLowerCase())?.id;
                return {
                    "id": userId,
                    "name": userName
                };
            }

            case '${connells.salesseeksupport.id}':{
                let userId = '87c25135-9363-4827-9ae6-60aba5116055';

                const host = window.location.hostname;
                const ppos = host.indexOf('.');
                const environment = host.substring(ppos + 1, host.indexOf('.', ppos + 1));

                if (environment === 'connellsdemo') {
                    userId = 'a2e0db11-d40f-4307-b7ad-bc84e6dba120';
                }

                return userId;
            }
        }

        if(mergeTag.indexOf('${date.today.operator[') === 0) {
            // Handles both ${date.today.operator[+1]} OR ${date.today.operator[-1]}
            const operator = mergeTag.substring(mergeTag.indexOf('[')+1, mergeTag.indexOf(']'));
            let currentDate = new Date();
            currentDate.setDate(currentDate.getDate() + eval(operator));
            return currentDate.toISOString().split("T")[0];
        }
        else if(mergeTag.indexOf('${custom_field.') === 0) {
            return this.getNextCustomField(mergeTag, null);
        }

        return null;
    }

    getNextCustomField(mergeTag, customFieldsInfo) {
        // Circulate through custom fields by getting the next object/property using expressions
        // ${custom_field.PROPERTY_NAME[EXPRESSION].PROPERTY_NAME} i.e. ${custom_field.individualsArray[e=>e.name==="Gender"].id}
        // ${custom_field.PROPERTY_NAME[EXPRESSION].PROPERTY_NAME[EXPRESSION].PROPERTY_NAME} i.e. ${custom_field.dealsArray[e=>e.name==="Lead Source"].optionsArray[e=> e.value==="Glenigan"].id}
        mergeTag = mergeTag.replace('${custom_field.','').replace('}','');
        let propertyName = mergeTag.substring(0, mergeTag.indexOf('['));

        customFieldsInfo = 
            customFieldsInfo === null
            ? app.globalData.customFieldsInfo[propertyName]
            : customFieldsInfo[propertyName];

        // Get custom field object
        const findExpression = eval(
            mergeTag.substring(
                mergeTag.indexOf('[')+1,
                mergeTag.indexOf(']')));
        const customFieldObj = customFieldsInfo.find(findExpression);


        // Get property value or search next object for property value
        const nextMergeTag = mergeTag.substring(mergeTag.indexOf('].')+2);
        return nextMergeTag.indexOf('[') >= 0
            ? this.getNextCustomField(nextMergeTag, customFieldObj) // Search next object to find property value
            : customFieldObj[nextMergeTag]; // Get property value
    }

    isMergeTag(value) {
        return value && _.isString(value) && value.indexOf('${') === 0;
    }

    processArgs(args) {
        args = args || {};

        let newArgs = {};

        for (const k in args) {
            const value = args[k];

            if (_.isArray(value)) {
                let newArray = [];

                for (let i = 0; i < value.length; ++i) {
                    if (this.isMergeTag(value[i])) {
                        const newValue = this.getMergeTagValue(value[i]);

                        if (newValue !== null) {
                            newArray.push(newValue);
                        }
                    } else {
                        newArray.push(value[i]);
                    }
                }
                newArgs[k] = newArray;
            } else if (_.isObject(value) && !_.isDate(value)) {
                newArgs[k] = this.processArgs(value);
            } else {
                if (this.isMergeTag(value)) {
                    const newValue = this.getMergeTagValue(value);

                    if (newValue !== null) {
                        newArgs[k] = newValue;
                    }
                } else {
                    newArgs[k] = value;
                }
            }
        }

        return newArgs;
    }

    processRules(rules, item) {
        rules = JSON.parse(JSON.stringify(rules)); // deep clone

        let newOuterRules = [];

        for (const outer of rules) {
            let newInnerRules = [];

            for (const inner of outer) {
                let addRule = true;

                for (const key in inner) {
                    let value = inner[key];

                    if (_.isObject(value)) {
                        for (const ik in value) {
                            let iv = value[ik];

                            if (this.isMergeTag(iv)) {
                                const tagValue = this.getMergeTagValue(iv, item);

                                if (tagValue === null) {
                                    addRule = false;
                                    break;
                                } else {
                                    value[ik] = tagValue;
                                }
                            }
                        }
                    } else if (this.isMergeTag(value)) {
                        const tagValue = this.getMergeTagValue(value, item);

                        if (tagValue === null) {
                            addRule = false;
                        } else {
                            inner[key] = this.getMergeTagValue(value, item);
                        }
                    }
                }

                if (addRule) {
                    newInnerRules.push(inner);
                }
            }

            if (newInnerRules.length > 0) {
                newOuterRules.push(newInnerRules);
            }
        }

        return newOuterRules.length > 0 ? newOuterRules : null;
    }

    getFilterId(type, rules, callback, item) {
        rules = this.processRules(rules, item);

        if (!rules) {
            cb(null);
            return;
        }
        const hashKey = `${type}/${JSON.stringify(rules)}`;

        if (hashKey in this.cache) {
            callback(this.cache[hashKey]);
            return;
        }

        if (hashKey in this.activeQueries) {
            this.activeQueries[hashKey].push(callback);
        }

        this.activeQueries[hashKey] = [callback];

        const self = this;

        $.post(`/${type}/filters`, JSON.stringify({rules: rules}), function(data) {
            self.cache[hashKey] = data.id;

            for (const cb of self.activeQueries[hashKey]) {
                cb(data.id);
            }

            delete self.activeQueries[hashKey];
        });
    }

    get(url, args, callback, options) {
        // todo: sort args alphabetically
        options = options || {};
        args = this.processArgs(args);

        const now = new Date().getTime();
        const query = `${url}?${$.param(args)}`;

        if (query in this.cache) {
            const cacheEntry = this.cache[query];

            if (!options.force && (!options.cache_validity || ((now - cacheEntry.timestamp) <= options.cache_validity * 60000))) {
                callback(cacheEntry.data, cacheEntry.paginationInfo);
                return;
            }
        }

        if (query in this.activeQueries) {
            this.activeQueries[query].push(callback);
            return;
        }

        this.activeQueries[query] = [callback];

        const self = this;

        $.get(query, function(data, _, xhr) {
            let cacheData = {
                timestamp: now,
                data: data
            };

            let recordsTotal = xhr.getResponseHeader('Records-Total');

            if (recordsTotal) {
                cacheData.paginationInfo = {
                    start: parseInt(xhr.getResponseHeader('Records-Start')),
                    rows: parseInt(xhr.getResponseHeader('Records-Rows')),
                    total: parseInt(recordsTotal)
                };
            }

            self.cache[query] = cacheData;

            for (const cb of self.activeQueries[query]) {
                cb(cacheData.data, cacheData.paginationInfo);
            }

            delete self.activeQueries[query];
        });
    }
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        const lastVisitedFunnel = app.user.get('preferences').last_visited_funnel_id;
        const lastVisitedRegionClusterObject = app.user.get('preferences').last_visited_region_funnels;
        
        const activeFunnel = (lastVisitedFunnel ? app.globalData.funnelsInfo.funnels.find(f => f.id === lastVisitedFunnel) : null) || app.globalData.funnelsInfo.funnels[0];

        this.fetcher = new Fetcher();
        if (lastVisitedRegionClusterObject && lastVisitedRegionClusterObject.funnels) {
            this.fetcher.setActiveClusterFunnels(lastVisitedRegionClusterObject.funnels)
            this.fetcher.setIsCluster(lastVisitedRegionClusterObject.isCluster)
        } else {
            this.fetcher.setActiveFunnel(activeFunnel);
        }
        

        this.widgetRectStateModified = null;
        this.currentDashboardId = null;

        for (const event of ['task:created', 'task:updated', 'task:completed:change', 'appointment:created', 'appointment:updated', 'activity:save']) {
            props.parent.listenTo(vent, event, (params) => this.root.onEvent(event, params));
        }

        const self = this;

        props.parent.listenTo(vent, 'individual:view:closed deal:view:closed organization:view:closed', function() {
            if (self.widgetRectStateModified) {
                self.widgetRectStateModified.setRectState('normal');
                self.widgetRectStateModified = null;
            }
        });

        this.state = {
            rootVisible: false,
            editing: false
        };

        this.onEditing = {
            state: null,
            selectedWidget: null,
            prevMousePos: null,
            mouseAccum: null
        };

        _.defer(function() {
            //self.loadDashboard(BuiltInDashboards[props.dashboardId || 'new-team']);
            self.loadDashboard(BuiltInDashboards[props.dashboardId || 'team-beta']);
            // self.setActiveDashboard({id: 'new-dashboard'});
        });
    }

    componentDidMount() {
        $(window).on('resize', this.onResize.bind(this));
        $(window).on('mousemove', this.onMouseMove.bind(this));
        $(window).on('mouseup', this.onMouseUp.bind(this));
    }

    componentWillUnmount() {
        $(window).off('resize', this.onResize);
        $(window).off('mousemove', this.onMouseMove);
        $(window).off('mouseup', this.onMouseUp);

        if (this.root) {
            this.root.onDestroy();
        }
    }

    onResize() {
        const self = this;

        _.defer(function() {
            if (!self.component) {
                return;
            }

            let bbox = self.component.getBoundingClientRect();

            if (self.state.editing) {
                bbox.width -= EDITOR_WIDTH;
            }

            const diff = [bbox.width - self.size[0], bbox.height - self.size[1]];

            if (diff[0] || diff[1]) {
                self.size = [bbox.width, bbox.height];
                self.root.onParentResized(diff);
            }
        });
    }

    manageWidgetMoving(offset) {
        this.onEditing.mouseAccum = [this.onEditing.mouseAccum[0] + offset[0], this.onEditing.mouseAccum[1] + offset[1]];

        this.onEditing.selectedWidget.setPosition(
            adjustToGrid(this.onEditing.mouseAccum[0]),
            adjustToGrid(this.onEditing.mouseAccum[1])
        );
    }

    manageWidgetResizing(offset) {
        this.onEditing.mouseAccum = [this.onEditing.mouseAccum[0] + offset[0], this.onEditing.mouseAccum[1] + offset[1]];

        const border = this.onEditing.param;
        const widget = this.onEditing.selectedWidget;
        let newSize = _.clone(widget.size);
        let newPos = _.clone(widget.position);

        for (let i = 0; i < 2; ++i) {
            const size = adjustToGrid(widget.size[i] + (this.onEditing.mouseAccum[i] * border[i]));

            if (size === widget.size[i]) {
                continue;
            }

            newSize[i] = size;

            if (widget.minSize && newSize[i] < widget.minSize[i]) {
                newSize[i] = widget.minSize[i];
            }

            if (widget.maxSize && newSize[i] > widget.maxSize[i]) {
                newSize[i] = widget.maxSize[i];
            }

            if (newSize[i] === widget.size[i]) {
                continue;
            }

            const delta = size - widget.size[i];

            if (border[i] >= 0) {
                this.onEditing.mouseAccum[i] -= delta;
            } else {
                this.onEditing.mouseAccum[i] += delta;
                newPos[i] -= delta;
            }
        }

        if (newSize[0] !== widget.size[0] || newSize[1] !== widget.size[1]) {
            widget.setSize(newSize[0], newSize[1]);
        }

        if (newPos[0] !== widget.position[0] || newPos[1] !== widget.position[1]) {
            widget.setPosition(newPos[0], newPos[1]);
        }
    }

    onMouseMove(ev) {
        if (this.onEditing.state !== 'moving' && this.onEditing.state !== 'resizing') {
            return;
        }

        const offset = [ev.clientX - this.onEditing.prevMousePos[0], ev.clientY - this.onEditing.prevMousePos[1]];

        if (offset[0] === 0 && offset[1] === 0) {
            return;
        }

        const mousePos = [ev.clientX, ev.clientY];

        switch(this.onEditing.state) {
            case 'moving':
                this.manageWidgetMoving(offset);
                break;

            case 'resizing':
                this.manageWidgetResizing(offset);
                break;
        }

        this.onEditing.prevMousePos = [ev.clientX, ev.clientY];
        this.checkWidgetsOverlapping();
    }

    onMouseUp() {
        switch(this.onEditing.state) {
            case 'moving': {
                const widget = this.onEditing.selectedWidget;
                const parent = this.root.getContainerAtScreenPos(widget.position[0], widget.position[1], widget.id);

                if ((!parent && widget.parent) || (parent.id !== widget.parent.id)) {
                    widget.setParent(parent);
                }
            }
            // fall!

            case 'resizing':
                this.onEditing.selectedWidget.sendToBack();
                this.checkWidgetsOverlapping();
                break;
        }

        this.onEditing.state = null;
    }

    checkWidgetsOverlapping() {
        const widgets = this.root.findWidgets(); // get all widgets

        for (const w of widgets) {
            w.overlap(false);
        }

        for (let i = 0; i < widgets.length - 1; ++i) {
            for (let j = i + 1; j < widgets.length; ++j) {
                const w1 = widgets[i];
                const w2 = widgets[j];

                if (w1.parent.id === w2.parent.id && Utilities.rectsIntersect(w1.position[0], w1.position[1], w1.size[0], w1.size[1], w2.position[0], w2.position[1], w2.size[0], w2.size[1])) {
                    w1.overlap(true);
                    w2.overlap(true);
                }
            }
        }
    }

    adjustDashboardInitialSizeAndPosition(container, ratio) {
        if (container.position) {
            container.position = [container.position[0] * ratio[0], container.position[1] * ratio[1]];
        }

        if (container.size) {
            container.size = [container.size[0] * ratio[0], container.size[1] * ratio[1]];
        }

        for (const w of container.children) {
            if (w.position) {
                w.position = [w.position[0] * ratio[0], w.position[1] * ratio[1]];
            }

            if (w.size) {
                w.size = [w.size[0] * ratio[0], w.size[1] * ratio[1]];
            }

            if (w.children) {
                this.adjustDashboardInitialSizeAndPosition(w, ratio);
            }

            if (w.tabs) {
                for (const tab of w.tabs) {
                    this.adjustDashboardInitialSizeAndPosition(tab, ratio);
                }
            }
        }
    }

    loadDashboard(dashboard) {
        if (dashboard.id === this.currentDashboardId) {
            return;
        }

        this.setState({
            rootVisible: false
        });

        const content = JSON.parse(JSON.stringify(dashboard.content)) // clone dashboard;

        this.size = content.size;

        // adjust initial position & size to the current screen size
        const bbox = this.component.getBoundingClientRect();

        const ratio = [
            Utilities.clamp(bbox.width / this.size[0], 0.5, 2.5),
            Utilities.clamp(bbox.height / this.size[1], 0.5, 2.5)
        ];

        if (Math.abs(1.0 - ratio[0]) >= 0.5) {
            this.size = [this.size[0] * ratio[0], this.size[1] * ratio[1]];
            this.adjustDashboardInitialSizeAndPosition(content, ratio);
        }

        this.root = new Panel({
            position: [0, 0],
            size: this.size,
            anchor: ANCHOR_BITFIELD.all,
            fetcher: this.fetcher,
            triggerEvent: this.triggerEvent.bind(this),
            isRoot: true
        });

        this.root.addNewWidgets(content.children);
        this.onResize();

        const self = this;

        _.defer(function() {
            self.setState({
                rootVisible: true
            });
        });
    }

    setActiveFunnel(funnel) {
        app.user.updatePreference('last_visited_funnel_id', funnel.id);
        this.fetcher.setActiveFunnel(funnel);
        this.root.onEvent('funnel:change');
    }

    setActiveCluster(clusterObject, saveVisitedFunnelId) {
        if(saveVisitedFunnelId){
            const sameVisitedFunnelId = clusterObject.funnels.find(funnel => funnel.id == app.user.get('preferences').last_visited_funnel_id)
            const objectToPersist = {
                'last_visited_region_funnels': clusterObject,
                'last_visited_funnel_id': sameVisitedFunnelId ? sameVisitedFunnelId.id : clusterObject.funnels[0].id
            }
            app.user.updateMultiplePreferences(objectToPersist)
        }
        this.fetcher.setActiveClusterFunnels(clusterObject.funnels)
        this.fetcher.setIsCluster(clusterObject.isCluster)
        this.root.onEvent('cluster:change');
    }

    setActiveDashboard(dashboard) {
        let dashDef = null;

        if (dashboard.id === 'new-dashboard') {
            const bbox = this.component.getBoundingClientRect();

            dashDef = {
                name: 'New Dashboard',
                content: {
                    size: [bbox.width - EDITOR_WIDTH, bbox.height],
                    children: []
                }
            };

            this.startEditing();
        } else if (dashboard.isBuiltin) {
            dashDef = BuiltInDashboards[dashboard.id];
        } else {
            dashDef = app.globalData.dashboards.find(d => d.id === dashboard.id);
        }

        if (dashDef) {
            this.activeDashboard = _.clone(dashboard);
            this.loadDashboard(dashDef);
        }
    }

    startEditing() {
        this.setState({
            editing: true
        });

        this.onResize();
    }

    endEditing() {
        this.setState({
            editing: false
        });

        this.onResize();
    }

    selectWidget(widget) {
        if (widget && this.onEditing.selectedWidget && widget.id === this.onEditing.selectedWidget.id) {
            return;
        }

        if (this.onEditing.selectedWidget) {
            this.onEditing.selectedWidget.select(false);
            this.onEditing.selectedWidget = null;
        }

        this.onEditing.selectedWidget = widget;

        if (this.onEditing.selectedWidget) {
            this.onEditing.selectedWidget.select(true);
        }
    }

    processEventOnEditing(event, params) {
        switch(event) {
            case 'editor:selectWidget':
                this.selectWidget(params.widget);

                if (params.checkOverlapping) {
                    this.checkWidgetsOverlapping();
                }
                break;

            case 'editor:moveWidget':
                if (!params.widget.parent.isRoot) {
                    params.widget.setParent(this.root);
                }

                params.widget.bringToFront();

                this.onEditing.state = 'moving';
                this.onEditing.prevMousePos = _.clone(params.mousePos);
                this.onEditing.mouseAccum = _.clone(params.widget.position);
                this.selectWidget(params.widget);
                break;

            case 'editor:resizeWidget':
                params.widget.bringToFront();

                this.onEditing.state = 'resizing';
                this.onEditing.prevMousePos = _.clone(params.mousePos);
                this.onEditing.param = _.clone(params.mul);
                this.onEditing.mouseAccum = [0, 0];
                this.selectWidget(params.widget);
                break;

            case 'editor:deleteWidget':
                this.selectWidget(null);
                params.widget.parent.removeWidget(params.widget);
                this.checkWidgetsOverlapping();
                break;
        }
    }

    triggerEvent(event, params) {
        params = params || {};

        if (event.indexOf('editor:') === 0) {
            this.processEventOnEditing(event, params);
        } else {
            switch(event) {
                case 'showEntity':
                    switch(params.type) {
                        case 'individuals':
                            this.props.onPushViewIndividual(params.id, params.options);
                            break;

                        case 'opportunities':
                            this.props.onPushViewOpportunity(params.id, params.options);
                            break;

                        case 'organizations':
                            this.props.onPushViewOrganization(params.id, params.options);
                            break;
                    }

                    if (params.rectState) {
                        if (this.widgetRectStateModified) {
                            this.widgetRectStateModified.setRectState('normal');
                            this.widgetRectStateModified = null;
                        }

                        params.widget.setRectState(params.rectState);

                        if (params.rectState !== 'normal') {
                            this.widgetRectStateModified = params.widget;
                        }
                    }
                    break;

                case 'showContactsSection':
                    switch(params.contact_type) {
                        case 'individuals':
                            if (params.filter_rules) {
                                this.fetcher.getFilterId('individuals', params.filter_rules, (filterId) => {
                                    window.location.href = `#contacts/individuals?filter_id=${filterId}`;
                                }, params.item);
                            } else {
                                window.location.href = '#contacts/leadIndividuals';
                            }
                            break;
                    }
                    break;

                case 'showDealsSection': {
                    let path = `#deals/${params.deal_type}`; // all | active 
                    if (params.filter_rules) {
                        this.fetcher.getFilterId('opportunities', params.filter_rules, (filterId) => {
                            window.location.href = `${path}?filter_id=${filterId}`;
                        }, params.item);
                    } else {
                        window.location.href = `${path}`;
                    }
                    break;
                }
                
                case 'showAppointmentsSection': {
                    let path = `#appointments/${params.appointment_type}`; // all | upcoming | confirmed | pending 
                    if (params.filter_rules) {
                        this.fetcher.getFilterId('appointments', params.filter_rules, (filterId) => {
                            window.location.href = `#appointments/confirmed?filter_id=${filterId}`;
                        }, params.item);
                    } else {
                        window.location.href = `${path}`;
                    }
                    break;
                }
                
                case 'showTasksSection':
                    if (params.filter_rules) {
                        this.fetcher.getFilterId('tasks', params.filter_rules, (filterId) => {
                            window.location.href = `#tasks/groups/all?filter_id=${filterId}`;
                        });
                    } else {
                        window.location.href = '#tasks/groups/all';
                    }
                    break;

                case 'showQuickAddTaskView': {
                    let model = null;
                    if (params?.preloadedFields) {
                        model = new TaskModel(this.fetcher.processArgs(params.preloadedFields));
                    }

                    vent.trigger('quick:add:task', model);
                }
                break;

                case 'showQuickAddAppointmentView':
                    vent.trigger('quick:add:appointment', this.fetcher.processArgs(params));
                    break;

                case 'showNewIndividualView':
                    vent.trigger('individuals:detail:new', params || {});
                    break;

                case 'showNewLeadView': {
                    let leadParams = _.extend(params || {}, {
                        isLead: true
                    });

                    // in this case the source field is mandatory and should be manually updated.
                    if (!AppConfig.getValue('individuals.edit.source.disabled_with_value', false)) {
                        if (!leadParams.preloadedFields) {
                            leadParams.preloadedFields = {};
                        }

                        leadParams.preloadedFields.source = { system_type: 'unspecified' };
                    }

                    vent.trigger('individuals:detail:new', this.fetcher.processArgs(leadParams));
                }
                break;

                default:
                    this.root.onEvent(event, params);
                    break;
            }
        }
    }

    render() {
        return (
            <div
                ref={(el) => this.component = el}
                className={style.dashboard}
            >
                {this.state.rootVisible &&
                    <PanelRenderer
                        widget={this.root}
                        editing={this.state.editing}
                        fixed={true}
                        parent={this.props.parent}
                    />
                }

                {this.state.rootVisible && this.state.editing &&
                    <DashboardEditor
                        root={this.root}
                        dashboard={this.activeDashboard}
                        onClose={() => {this.endEditing()}}
                    />
                }
            </div>
        );
    }
}

export default Dashboard;
import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import backboneSelect2 from 'js/widgets/backbone-select2'

import funnelsKpiTemplate from 'templates/widgets/funnels-kpi.handlebars'
import funnelsKpiItemTemplate from 'templates/widgets/funnels-kpi-item.handlebars'


const ItemView = Marionette.ItemView.extend({
    className: 'funnels-kpi-item',
    template: Handlebars.compile(funnelsKpiItemTemplate)
});

const ListView = Marionette.CompositeView.extend({
    className: 'funnels-kpi-list',
    template: Handlebars.compile('<div class="funnels-kpi-list"/>'),
    itemViewContainer: '.funnels-kpi-list',
    itemView: ItemView
});

const FunnelsKpi = Marionette.Layout.extend({
    className: 'funnels-kpi-widget',
    template: Handlebars.compile(funnelsKpiTemplate),
    regions: {
        listRegion: '.funnels-kpi-list-container'
    },
    ui: {
        periodSelect: '.funnels-kpi-period-select'
    },
    onRender: function() {
        var pad = function(number) { return ('0' + number).slice(-2); };
        var now = new Date();
        var weekAgo = new Date(now - 604800000);
        var month = now.getUTCMonth() + 1;
        var quarterMonth = Math.floor(now.getUTCMonth() / 3) * 3 + 1;
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        this.startDates = {
            week: {
                value: weekAgo.getUTCFullYear() + '-' + pad(weekAgo.getUTCMonth() + 1) +
                       '-' + pad(weekAgo.getUTCDate()),
                label: months[weekAgo.getUTCMonth()] + ' ' + weekAgo.getUTCDate() + ' - Today'
            },
            month: {
                value: now.getUTCFullYear() + '-' + pad(month) + '-01',
                label: months[now.getUTCMonth()] + ' 1 - Today'
            },
            quarter: {
                value: now.getUTCFullYear() + '-' + pad(quarterMonth) + '-01',
                label: months[quarterMonth - 1] + ' 1 - Today'
            },
            year: {
                value: now.getUTCFullYear() + '-01-01',
                label: 'Jan 1 - Today'
            }
        };

        var periodData = [
            {
                id: 'week',
                text: 'Last 7 Days',
                description: this.startDates.week.label
            },
            {
                id: 'month',
                text: 'Month to Date',
                description: this.startDates.month.label
            },
            {
                id: 'quarter',
                text: 'Quarter to Date',
                description: this.startDates.quarter.label
            },
            {
                id: 'year',
                text: 'Year to Date',
                description: this.startDates.year.label
            }
        ];

        this.activePeriod = periodData[0];

        var periodSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.periodSelect,
            text: 'text',
            data: periodData,
            value: this.activePeriod,
            options: {
                containerCssClass: 'select2-plain',
                dropdownCssClass: 'period-select-popover popover has-description',
                minimumResultsForSearch: -1,
                formatResult: function(item, container) {
                    return Handlebars.compile(
                        item.text +
                        '<div class="description">' + item.description + '</div>'
                    );
                }
            }
        });

        var self = this;

        this.listenTo(periodSelect, 'change', function(item) {
            self.activePeriod = item;
            self.fetchData();
        });

        this.fetchData();
    },
    fetchData: function() {
        var self = this;

        $.get(`/widgets/funnels_kpi?start_date=${this.startDates[this.activePeriod.id].value}`, function(data) {
            self.listRegion.show(new ListView({ collection: new Backbone.Collection(data) }));
        });
    }
});

export default FunnelsKpi;

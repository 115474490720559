import React from 'react';

import app from 'js/app';
import { NewSelect } from 'js/react_views/widgets/select';

import style from './permissions.css';


export default class Permissions extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [{
            id: 'view',
            title: 'View'
        }, {
            id: 'edit',
            title: 'Edit'
        }, {
            id: 'all',
            title: 'All'
        }];

        this.viewActiveItem = null;
        this.editActiveItem = null;
        this.allActiveItem = null;

        this.state = {
            loading: false,
            viewItems: [],
            editItems: [],
            allItems: [],
            viewAdding: false,
            editAdding: false,
            allAdding: false,
            itemSelected: null
        };
    }

    componentDidMount() {
        this.fetchItems();
    }

    getPermissionType(permission) {
        switch (permission) {
            case 'salesseek.core.view':
                return 'view';

            case 'salesseek.core.edit':
                return 'edit';

            case 'salesseek.core.all_permissions':
                return 'all';
        }

        return null;
    }

    fetchItems() {
        this.setState({
            loading: true
        });

        let counter = 0;
        let items = {
            view: [],
            edit: [],
            all: []
        };

        const checkFinish = () => {
            --counter;

            if (counter <= 0) {
                this.setState({
                    loading: false,
                    viewItems: items.view,
                    editItems: items.edit,
                    allItems: items.all
                });

                this.manageDroppableAreas();
            }
        }

        // ...
        if (this.props.entityId) {
            const self = this;

            $.get(`/${this.props.entityType}/${this.props.entityId}/acl`, function(result) {
                counter = result.length;

                for (const p of result) {
                    const id = p[0];
                    const permissions = self.getPermissionType(p[1]);

                    if (!permissions) {
                        continue;
                    }

                    if (id.indexOf('salesseek.core.owner') === 0) {
                        $.get(`/users/${id.slice(21)}`, function(owner) {
                            items[permissions].push({
                                id: owner.id,
                                name: owner.name,
                                isOwner: true,
                                userPhoto: owner.photo_url
                            });

                            checkFinish();
                        });
                    } else if (id.indexOf('salesseek.core.team') === 0) {
                        $.get(`/teams/${id.slice(20)}`, function(team) {
                            if (team.team_type === 'user') {
                                $.get(`/users/${team.id}`, function(teamUser) {
                                    items[permissions].push({
                                        id: teamUser.id,
                                        name: teamUser.name,
                                        userPhoto: teamUser.photo_url
                                    });

                                    checkFinish();
                                });
                            } else {
                                items[permissions].push({
                                    id: id.slice(20),
                                    name: team.name,
                                    isTeam: true
                                });

                                checkFinish();
                            }
                        });
                    }
                }
            });
        } else { // it's a new entity, so by default the active user has 'owner' permissions and depending of the configuration the 'everyone' team can be assigned as well
            const self = this;

            $.get('/permission_defaults', function(result) {
                items.all.push({
                    id: app.user.get('id'),
                    name: app.user.get('name'),
                    isOwner: true,
                    userPhoto: app.user.get('photo_url')
                });

                if (result[self.props.entityType] === 'all') {
                    $.get('/teams2?search=everyone', function(teams) {
                        const everyone = teams.find(t => t.team_type === 'everyone');

                        if (everyone) {
                            items.all.push({
                                id: everyone.id,
                                name: everyone.name,
                                isTeam: true
                            });
                        }

                        checkFinish();
                    });
                } else {
                    checkFinish();
                }
            });
        }
    }

    manageDroppableAreas() {
        for (const ic of this.itemsComponents) {
            const el = $(ic);

            el.draggable({
                handle: `.${style.iHandle}`,
                revert: 'invalid',
                revertDuration: 150,
                start: function() {
                    el.addClass(style.iDragging);
                },
                stop: function() {
                    el.removeClass(style.iDragging);
                }
            });
        }

        const self = this;
        const columnsIds = ['view', 'edit', 'all'];

        for (const c of columnsIds) {
            const el = $(this.columnsComponents[c]);
            const columnId = c;

            el.droppable({
                hoverClass: style.cItemHover,
                activeClass: style.cDropActive,
                accept: function(item) {
                    return el.has(item).length === 0;
                },
                drop: function(_, item) {
                    const itemId = item.draggable.attr('id');
                    let newState = {};
                    let movingItem = null;

                    // remove from source column
                    for (const t of columnsIds) {
                        const key = `${t}Items`;
                        let items = self.state[key];
                        let idx = items.findIndex(i => i.id === itemId)

                        if (idx !== -1) {
                            movingItem = items[idx];
                            items.splice(idx, 1);
                            newState[key] = items;
                            break;
                        }
                    }

                    // add to target column
                    const key = `${columnId}Items`;
                    let items = self.state[key];

                    items.push(movingItem);
                    newState[key] = items;

                    self.setState(newState);
                }
            });
        }
    }

    formatResult(item) {
        if (item.type === 'salesseek.core.models.teams.Team') {
            return `${item.title} (Team)`;
        }

        return item.title;
    }

    handleDoneClick(columnId) {
        let newState = {
            [`${columnId}Adding`]: false
        };

        const item = this[`${columnId}ActiveItem`];

        if (item) {
            const key = `${columnId}Items`;

            let itemData = {
                id: item.id,
                name: item.title,
            };

            if (item.type === 'salesseek.core.models.teams.Team') {
                itemData.isTeam = true;
                newState[key] = [...this.state[key], itemData];
            } else {
                const self = this;

                $.get(`/users/${item.id}`, function(user) {
                    itemData.userPhoto = user.photo_url;

                    self.setState({
                        [key]: [...self.state[key], itemData]
                    });
                });
            }
        }

        this.setState(newState);
    }

    handleSelect(columnId, item) {
        this[`${columnId}ActiveItem`] = item;
    }

    getItemComponent(item) {
        if (item.isOwner) {
            return (
                <div
                    className={style.iContent}
                    title='Owner permissions cannot be modified'
                >
                    <div className={style.iThumbnail}>
                        <div className='icon-locked'/>
                    </div>

                    <div className={style.iNameAndTeam}>
                        <div
                            className={style.iName}
                            title={item.name}
                        >
                            {item.name}
                        </div>

                        <div className={style.iTeam}>Owner</div>
                    </div>
                </div>
            );
        }

        if (item.isTeam) {
            return (
                <div
                    className={style.iContent}
                >
                    <div className={style.iThumbnail}>
                        <div className='icon-users'/>
                    </div>

                    <div className={style.iNameAndTeam}>
                        <div
                            className={style.iName}
                            title={item.name}
                        >
                            {item.name}
                        </div>

                        <div className={style.iTeam}>Team</div>
                    </div>

                    <div className={style.iHandle}><div/></div>
                </div>
            );
        }

        const initials = item.name.split(' ').map(function (s) { return s.charAt(0); }).join('');
        const photo = item.userPhoto;

        return (
            <div
                className={style.iContent}
            >
                <div className={style.iThumbnail}>
                    {photo ? (
                        <div className={style.iPhoto} style={{backgroundImage: `url('${photo}')`}}/>
                    ) : (
                        <div>{initials}</div>
                    )}
                </div>

                <div className={style.iNameAndTeam}>
                    <div
                        className={style.iName}
                        title={item.name}
                    >
                        {item.name}
                    </div>
                </div>

                <div className={style.iHandle}><div/></div>
            </div>
        );
    }

    processUsersData(data) {
        return data.filter(i => {
            return (
                !this.state.viewItems.find(a => a.id === i.id) &&
                !this.state.editItems.find(a => a.id === i.id) &&
                !this.state.allItems.find(a => a.id === i.id)
            );
        });
    }

    handleItemClick(columnId, itemId) {
        if (this.state.itemSelected && this.state.itemSelected.columnId === columnId && this.state.itemSelected.id === itemId) {
            this.setState({
                itemSelected: null
            });
        } else {
            this.setState({
                itemSelected: {
                    columnId: columnId,
                    id: itemId
                }
            });
        }
    }

    handleAddClick(columnId) {
        this.setState({
            [`${columnId}Adding`]: true
        });
    }

    handleDeleteSelectedItemClick() {
        let newState = {
            itemSelected: null
        };

        const is = this.state.itemSelected;
        const aid = `${is.columnId}Items`;

        newState[aid] = this.state[aid].filter(i => i.id !== is.id);

        this.setState(newState);
    }

    getPermissions() {
        let permissions = [];

        for (const t of ['view', 'edit', 'all']) {
            const items = this.state[`${t}Items`];

            for (const item of items) {
                let name = '';

                if (item.isOwner) {
                    name = `salesseek.core.owner#${item.id}`;
                } else if (item.isTeam) {
                    name = `salesseek.core.team#${item.id}`;
                } else {
                    name = `salesseek.core.user#${item.id}`;
                }

                permissions.push([name, `salesseek.core.${t}_permissions`]);
            }
        }

        return permissions;
    }

    render() {
        this.itemsComponents = [];
        this.columnsComponents = {};

        let itemSelected = this.state.itemSelected || {};

        return (
            <div className={style.permissions}>
                <div className={style.pColumns}>
                    {this.columns.map(column => {
                        return (
                            <div
                                ref={(el) => {this.columnsComponents[column.id] = el}}
                                key={`col_${column.id}`}
                                className={style.column}
                            >
                                <div className={style.cTitle}>{column.title}</div>

                                <div className={style.cContent}>
                                    {this.state[`${column.id}Items`].map(item => {
                                        return (
                                            <div
                                                ref={(el) => {this.itemsComponents.push(el)}}
                                                key={`${column.id}_${item.id}`}
                                                id={item.id}
                                                className={`
                                                    ${style.cItem}
                                                    ${item.isOwner ? style.iOwner : ''}
                                                    ${(itemSelected.id === item.id && itemSelected.columnId === column.id) ? style.iSelected : ''}
                                                `}
                                                onClick={() => this.handleItemClick.bind(this)(column.id, item.id)}
                                            >
                                                {this.getItemComponent(item)}
                                            </div>
                                        );
                                    })}
                                </div>

                                {this.state[`${column.id}Adding`] ? (
                                    <div className={style.cUserSelector}>
                                        <NewSelect
                                            url='/teams'
                                            placeholder='Search users'
                                            options={{
                                                formatResult: this.formatResult.bind(this)
                                            }}
                                            onSelect={(items) => this.handleSelect.bind(this)(column.id, items[0])}
                                            processData={this.processUsersData.bind(this)}
                                        />

                                        <div
                                            className={style.cDone}
                                            onClick={() => this.handleDoneClick.bind(this)(column.id)}
                                        >
                                            Done
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={style.cAdd}
                                        onClick={() => this.handleAddClick.bind(this)(column.id)}
                                    >
                                        Add
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {this.state.itemSelected &&
                    <div
                        className={style.pDelete}
                        onClick={this.handleDeleteSelectedItemClick.bind(this)}
                    >
                        Delete
                    </div>
                }
            </div>
        );
    }
}
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import TaskModel from 'js/models/task'
import ActivityModel from 'js/models/activity'
import OpportunityModel from 'js/models/opportunity'
import vent from 'js/vent';

import AppConfig from 'app/app-config'
import DateTimePicker from 'js/widgets/date-time-picker'
import {NewSelect} from 'js/react_views/widgets/select';
import dateFormat from 'js/utils/date-format'

import style from './appointment.css'

class Appointment extends React.Component {
    constructor(props) {
        super(props);

        this.propertyCfValue = null;
        this.appointmentTypeCfValue = null;
 
        this.appointmentTag = [];
    }

    componentDidMount() {
        var tag = [];
        var systemTags = app.globalData.tags;

        var appointmetTag = systemTags.find(t => t.name.toLowerCase() === 'appointment');

        if (appointmetTag) {
            tag.push(appointmetTag);
        }

        this.appointmentTag = tag;
    }

    clickDueDate() {
        var element = $('#appointment-date');

        var position = element.offset();

        var left = position.left
        var right = position.top + element.height() + 10;

        var dateTimePicker = new DateTimePicker({
            altField: element,
            time: AppConfig.getValue('quick_add_task.default_time', '11:00'),
            css: {
                left: left,
                top: right,
            }
        });
        dateTimePicker.showPicker();
    }

    handleSave() {
        var data = {};
        var noteText = new Array();

        var dueDateValue = $('#appointment-date').val() ? new Date($('#appointment-date').val()) : null;

        if ( !this.propertyCfValue || !this.appointmentTypeCfValue || !dueDateValue) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'All fields are required',
                        classes: 'load-error error',
                        timer: 3000
                    };
                }
            });

            return;
        }

        var deal = new OpportunityModel({ id: this.propertyCfValue });
        deal.fetch({
            wait: true,
            success: function() {
                var propertyStr = `Property: ${deal.get('name')}`;

                var dealCfs = deal.get('custom_fields');
                var addressCfId = null;
                var cityCfId = null;
                var postCodeCfId = null;

                const dealCustomFields = app.globalData.customFieldsInfo.deals;

                for (var cfId in dealCustomFields) {
                    if (dealCustomFields[cfId].name.toLowerCase() === 'address 1') {
                        addressCfId = cfId;
                    }
                    else if (dealCustomFields[cfId].name.toLowerCase() === 'city') {
                        cityCfId = cfId;
                    }
                    else if (dealCustomFields[cfId].name.toLowerCase() === 'postcode') {
                        postCodeCfId = cfId;
                    }

                    if (addressCfId && cityCfId && postCodeCfId) {
                        break;
                    }
                }

                if (addressCfId && dealCfs[addressCfId]) {
                    propertyStr += `, ${dealCfs[addressCfId]}`;
                }
                if (cityCfId && dealCfs[cityCfId]) {
                    propertyStr += `, ${dealCfs[cityCfId]}`;
                }
                if (postCodeCfId && dealCfs[postCodeCfId]) {
                    propertyStr += `, ${dealCfs[postCodeCfId]}`;
                }

                noteText.push(propertyStr);
            }
        });

        const indvCustomFields = app.globalData.customFieldsInfo.individuals;
        for (var cfId in indvCustomFields) {
            if (indvCustomFields[cfId].name.toLowerCase() === 'appointment type') {
                data[`custom_field.${cfId}`] = this.appointmentTypeCfValue;

                var options = indvCustomFields[cfId].options;

                for (var key in options) {
                    if (key === this.appointmentTypeCfValue) {
                        noteText.push(`Appointment type: ${options[key]}`);
                    }
                }
            }
            else if (indvCustomFields[cfId].name.toLowerCase() === 'property') {
                data[`custom_field.${cfId}`] = this.propertyCfValue;
            }
            else if (indvCustomFields[cfId].name.toLowerCase() === 'viewing date') {
                var dateValue = dateFormat.formatYYYYMMDDDate(dueDateValue);

                data[`custom_field.${cfId}`] = dateValue
                noteText.push(`Viewing Date: ${dateValue}`);
            }
            else if (indvCustomFields[cfId].name.toLowerCase() === 'viewing time') {
                var timeVal = dateFormat.shortFormatTime(dueDateValue, false);

                data[`custom_field.${cfId}`] = timeVal;
                noteText.push(`Viewing Time: ${timeVal}`);
            }

            if (data.length === 4) {
                break;
            }
        }

        _.extend(_.clone(this.props.model.attributes), data);

        var self = this;
        this.props.model.save(data, {
            patch: true,
            wait: true,
            success: function() {
                vent.trigger('contact:save');

                var activityNote = new ActivityModel({
                    note: noteText.join('\n'),
                    activity_type: 'note',
                    item_id: self.props.model.get('id'),
                    item_type: "individuals",
                    tags: self.appointmentTag
                }); 

                activityNote.save();

                var task = new TaskModel({
                    subject: 'Appointment',
                    text: noteText.join('\n'),
                    related_id: self.props.model.get('id'),
                    related_type: 'individual',
                    due_date: dueDateValue,
                    assignee_id: app.user.get('id'),
                    tags: self.appointmentTag,
                });

                task.save();

                self.props.onClose();
            }
        });
    }

    handlePropertyFieldChange(items) {
        if (items && items.length > 0) {
            this.propertyCfValue = items[0].id;
        } else {
            this.propertyCfValue = null;
        }
    }

    handleAppointmentFieldChange(items) {
        if (items && items.length > 0) {
            this.appointmentTypeCfValue = items[0].id;
        } else {
            this.appointmentTypeCfValue = null;
        }
    }

    render() {
        const customFields = app.globalData.customFieldsInfo.individuals;

        var appointmentTypeOptions = [];
        var appointmentTypeCfId = null;
        var propertyCfId = null;

        for (var cfId in customFields) {
            if (customFields[cfId].name.toLowerCase() === 'appointment type') {
                appointmentTypeCfId = cfId;

                for (var key in customFields[cfId].options) {
                    appointmentTypeOptions.push({
                        id: key,
                        value: customFields[cfId].options[key]
                    })
                }
            }

            if (customFields[cfId].name.toLowerCase() === 'property') {
                propertyCfId = cfId;
            }

            if (appointmentTypeCfId && propertyCfId) {
                break;
            }
        }

        return (
            <div className={style.appointmentViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                        >
                        Close
                    </div>

                    <div className={style.title}>Book an Appointment</div>
                    <a
                        className={style.saveButton}
                        onClick={this.handleSave.bind(this)}
                    >Save</a>
                </div>

                <div className={style.AppointmentBody}>
                    <div className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                            title="Property"
                        >
                            Property
                        </span>
                        <NewSelect
                            placeholder="Select a value"
                            width="260"
                            url="/opportunities"
                            editViewMod={true}
                            onSelect={this.handlePropertyFieldChange.bind(this)}
                            options={
                                {
                                    search_parameters:{
                                    section_id:"plots"
                                    }
                                }
                            }
                        />
                    </div>

                    <div className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                            title="Appointment Type"
                        >
                            Appointment Type
                        </span>
                        <NewSelect
                            placeholder="Select a value"
                            width="260"
                            data={appointmentTypeOptions}
                            editViewMod={true}
                            text="value"
                            onSelect={this.handleAppointmentFieldChange.bind(this)}
                        />
                    </div>

                    <div id="set-appointment-date" onClick={this.clickDueDate.bind(this)} className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                            title="Appointment Date"
                        >
                            Appointment Date
                        </span>
                        <input
                            type="text"
                            id="appointment-date"
                            placeholder="Set Appointment Date"
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const AppointmentView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'appointment-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <Appointment
                model={this.options.model}
                relatedData={this.options.relatedData}
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default AppointmentView;


var currencyMap;

// TODO: Reusing the same table as in currency.js, this should be factored
// away
currencyMap = {
    AED: {
        number: 784,
        symbol: '\u062f.\u0625',
        format: '$_.__',
        minorUnit: 2
    },
    AFN: {
        number: 971,
        symbol: '\u060b',
        format: '$_.__',
        minorUnit: 2
    },
    ALL: {
        number: 8,
        symbol: 'Lek',
        format: '$_.__',
        minorUnit: 2
    },
    AMD: {
        number: 51,
        symbol: '\u058F',
        format: '$_.__',
        minorUnit: 2
    },
    ANG: {
        number: 532,
        symbol: '\u0192',
        format: '$_.__',
        minorUnit: 2
    },
    AOA: {
        number: 973,
        symbol: 'Kz',
        format: '$_.__',
        minorUnit: 2
    },
    ARS: {
        number: 32,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    AUD: {
        number: 36,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    AWG: {
        number: 533,
        symbol: '\u0192',
        format: '$_.__',
        minorUnit: 2
    },
    AZN: {
        number: 944,
        symbol: '\u043c\u0430\u043d',
        format: '$_.__',
        minorUnit: 2
    },
    BAM: {
        number: 977,
        symbol: 'KM',
        format: '$_.__',
        minorUnit: 2
    },
    BBD: {
        number: 52,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    BDT: {
        number: 50,
        symbol: '\u09f3',
        format: '$_.__',
        minorUnit: 2
    },
    BGN: {
        number: 975,
        symbol: '\u043b\u0432',
        format: '$_.__',
        minorUnit: 2
    },
    BHD: {
        number: 48,
        symbol: '.\u062f.\u0628',
        format: '$_.__',
        minorUnit: 3
    },
    BIF: {
        number: 108,
        symbol: 'F',
        format: '$_.__',
        minorUnit: 0
    },
    BMD: {
        number: 60,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    BND: {
        number: 96,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    BOB: {
        number: 68,
        symbol: '$b',
        format: '$_.__',
        minorUnit: 2
    },
    BOV: {
        number: 984,
        symbol: 'Bs',
        format: '$_.__',
        minorUnit: 2
    },
    BRL: {
        number: 986,
        symbol: 'R$',
        format: '$_.__',
        minorUnit: 2
    },
    BSD: {
        number: 44,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    BTN: {
        number: 64,
        symbol: '\u0e3f',
        format: '$_.__',
        minorUnit: 2
    },
    BWP: {
        number: 72,
        symbol: 'P',
        format: '$_.__',
        minorUnit: 2
    },
    BYR: {
        number: 974,
        symbol: 'p.',
        format: '$_.__',
        minorUnit: 0
    },
    BZD: {
        number: 84,
        symbol: 'BZ$',
        format: '$_.__',
        minorUnit: 2
    },
    CAD: {
        number: 124,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    CDF: {
        number: 976,
        symbol: 'F',
        format: '$_.__',
        minorUnit: 2
    },
    //CHE
    CHF: {
        number: 756,
        symbol: 'CHF',
        format: '$_.__',
        minorUnit: 2
    },
    //CHW
    //CLF
    CLP: {
        number: 152,
        symbol: '$',
        format: '$_.__',
        minorUnit: 0
    },
    CNY: {
        number: 156,
        symbol: '\u00a5',
        format: '$_.__',
        minorUnit: 2
    },
    COP: {
        number: 170,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    // COU: 970
    CRC: {
        number: 188,
        symbol: '\u20a1',
        format: '$_.__',
        minorUnit: 2
    },
    CUC: {
        number: 931,
        symbol: 'CUC$',
        format: '$_.__',
        minorUnit: 2
    },
    CUP: {
        number: 192,
        symbol: '\u20b1',
        format: '$_.__',
        minorUnit: 2
    },
    CVE: {
        number: 132,
        symbol: '$00', // ought to be a cifrão
        format: '_.__$',
        minorUnit: 0
    },
    CZK: {
        number: 203,
        symbol: 'K\u010d',
        format: '$_.__',
        minorUnit: 2
    },
    DJF: {
        number: 262,
        symbol: 'Fdj',
        format: '$_.__',
        minorUnit: 2
    },
    DKK: {
        number: 208,
        symbol: 'kr',
        format: '$_.__',
        minorUnit: 2
    },
    DOP: {
        number: 214,
        symbol: 'RD$',
        format: '$_.__',
        minorUnit: 2
    },
    DZD: {
        number: 12,
        symbol: '\u062f.\u062c',
        format: '$_.__',
        minorUnit: 2
    },
    EGP: {
        number: 818,
        symbol: 'E£',
        format: '$_.__',
        minorUnit: 2
    },
    ERN: {
        number: 232,
        symbol: 'Nfk',
        format: '$_.__',
        minorUnit: 2
    },
    ETB: {
        number: 230,
        symbol: 'Br',
        format: '$_.__',
        minorUnit: 2
    },
    EUR: {
        number: 978,
        symbol: '\u20ac',
        format: '$_.__',
        minorUnit: 2
    },
    FJD: {
        number: 242,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    FKP: {
        number: 238,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    GBP: {
        number: 826,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    // GGP
    GEL: {
        number: 981,
        symbol: '\u10da',
        format: '$_.__',
        minorUnit: 2
    },
    GHS: {
        number: 936,
        symbol: 'GH\u20b5',
        format: '$_.__',
        minorUnit: 2
    },
    GIP: {
        number: 292,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    GMD: {
        number: 270,
        symbol: 'D',
        format: '$_.__',
        minorUnit: 2
    },
    GNF: {
        number: 324,
        symbol: 'FG',
        format: '$_.__',
        minorUnit: 0
    },
    GTQ: {
        symbol: 'Q',
        format: '$_.__',
        minorUnit: 2
    },
    GYD: {
        number: 328,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    HKD: {
        number: 344,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    HNL: {
        number: 340,
        symbol: 'L',
        format: '$_.__',
        minorUnit: 2
    },
    HRK: {
        number: 191,
        symbol: 'kn',
        format: '$_.__',
        minorUnit: 2
    },
    HUF: {
        number: 348,
        symbol: 'Ft',
        format: '$_.__',
        minorUnit: 2
    },
    IDR: {
        number: 360,
        symbol: 'Rp',
        format: '$_.__',
        minorUnit: 2
    },
    ILS: {
        number: 376,
        symbol: '\u20aa',
        format: '$_.__',
        minorUnit: 2
    },
    // IMP
    INR: {
        number: 356,
        symbol: '\u20b9',
        format: '$_.__',
        minorUnit: 2
    },
    IQD: {
        number: 368,
        symbol: '\u062f.\u0639',
        format: '$_.__',
        minorUnit: 3
    },
    IRR: {
        number: 364,
        symbol: '\ufdfc',
        format: '$_.__',
        minorUnit: 2
    },
    ISK: {
        number: 352,
        symbol: 'kr',
        format: '$_.__',
        minorUnit: 2
    },
    // JEP
    JMD: {
        number: 388,
        symbol: 'J$',
        format: '$_.__',
        minorUnit: 2
    },
    JOD: {
        number: 400,
        symbol: '',
        format: '$_.__',
        minorUnit: 3
    },
    JPY: {
        number: 392,
        symbol: '\u00a5',
        format: '$_.__',
        minorUnit: 0
    },
    KES: {
        number: 404,
        symbol: 'KSh',
        format: '$_.__',
        minorUnit: 2
    },
    KGS: {
        number: 417,
        symbol: '\u043b\u0432',
        format: '$_.__',
        minorUnit: 2
    },
    KHR: {
        number: 116,
        symbol: '\u17db',
        format: '$_.__',
        minorUnit: 2
    },
    KMF: {
        number: 174,
        symbol: 'CF',
        format: '$_.__',
        minorUnit: 0
    },
    KPW: {
        number: 408,
        symbol: '\u20a9',
        format: '$_.__',
        minorUnit: 0
    },
    KRW: {
        number: 410,
        symbol: '\u20a9',
        format: '$_.__',
        minorUnit: 0
    },
    KWD: {
        number: 414,
        symbol: '\u062f.\u0643',
        format: '$_.__',
        minorUnit: 3
    },
    KYD: {
        number: 136,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    KZT: {
        number: 398,
        symbol: '\u043b\u0432',
        format: '$_.__',
        minorUnit: 2
    },
    LAK: {
        number: 418,
        symbol: '\u20ad',
        format: '$_.__',
        minorUnit: 0
    },
    LBP: {
        number: 422,
        symbol: '£',
        format: '$_.__',
        minorUnit: 0
    },
    LKR: {
        number: 144,
        symbol: '\u20a8',
        format: '$_.__',
        minorUnit: 2
    },
    LRD: {
        number: 430,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    LSL: {
        number: 426,
        symbol: 'L',
        format: '$_.__',
        minorUnit: 2
    },
    LTL: {
        number: 440,
        symbol: 'Lt',
        format: '$_.__',
        minorUnit: 2
    },
    LVL: {
        number: 428,
        symbol: 'Ls',
        format: '$_.__',
        minorUnit: 2
    },
    LYD: {
        number: 434,
        symbol: '\u0644.\u062f',
        format: '$_.__',
        minorUnit: 3
    },
    MAD: {
        number: 504,
        symbol: '\u062f.\u0645.',
        format: '$_.__',
        minorUnit: 3
    },
    MDL: {
        number: 498,
        symbol: 'L',
        format: '$_.__',
        minorUnit: 2
    },
    MGA: {
        number: 969,
        symbol: 'Ar',
        format: '$_.__',
        minorUnit: 2 // 0.7
    },
    MKD: {
        number: 807,
        symbol: '\u0434\u0435\u043d',
        format: '$_.__',
        minorUnit: 0
    },
    MMK: {
        number: 104,
        symbol: 'K',
        format: '$_.__',
        minorUnit: 0
    },
    MNT: {
        number: 496,
        symbol: '\u20ae',
        format: '$_.__',
        minorUnit: 2
    },
    MRO: {
        number: 478,
        symbol: 'UM',
        format: '$_.__',
        minorUnit: 2 // 0.7
    },
    MUR: {
        number: 480,
        symbol: '\u20a8',
        format: '$_.__',
        minorUnit: 2
    },
    MVR: {
        number: 462,
        symbol: 'MRf',
        localSymbol: '\u0783',
        format: '$_.__',
        minorUnit: 2
    },
    MWK: {
        number: 454,
        symbol: 'MK',
        format: '$_.__',
        minorUnit: 2
    },
    MXN: {
        number: 484,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    // MXV
    MYR: {
        number: 458,
        symbol: 'RM',
        format: '$_.__',
        minorUnit: 2
    },
    MZN: {
        number: 943,
        symbol: 'MT',
        format: '$_.__',
        minorUnit: 2
    },
    NAD: {
        number: 516,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    NGN: {
        number: 566,
        symbol: '\u20a6',
        format: '$_.__',
        minorUnit: 2
    },
    NIO: {
        number: 558,
        symbol: 'C$',
        format: '$_.__',
        minorUnit: 2
    },
    NOK: {
        number: 578,
        symbol: 'kr',
        format: '$_.__',
        minorUnit: 2
    },
    NPR: {
        number: 524,
        symbol: '\u20a8',
        format: '$_.__',
        minorUnit: 2
    },
    NZD: {
        number: 554,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    OMR: {
        number: 512,
        symbol: '\ufdfc',
        format: '$_.__',
        minorUnit: 3
    },
    PAB: {
        number: 590,
        symbol: 'B/.',
        format: '$_.__',
        minorUnit: 2
    },
    PEN: {
        number: 604,
        symbol: 'S/.',
        format: '$_.__',
        minorUnit: 2
    },
    PGK: {
        number: 598,
        symbol: 'K',
        format: '$_.__',
        minorUnit: 2
    },
    PHP: {
        number: 608,
        symbol: '\u20b1',
        format: '$_.__',
        minorUnit: 2
    },
    PKR: {
        number: 586,
        symbol: '\u20a8',
        format: '$_.__',
        minorUnit: 2
    },
    PLN: {
        number: 985,
        symbol: 'z\u0142',
        format: '$_.__',
        minorUnit: 2
    },
    PYG: {
        symbol: 'Gs',
        format: '$_.__',
        minorUnit: 0
    },
    QAR: {
        number: 634,
        symbol: '\ufdfc',
        format: '$_.__',
        minorUnit: 2
    },
    RON: {
        number: 946,
        symbol: 'lei',
        format: '$_.__',
        minorUnit: 2
    },
    RSD: {
        number: 941,
        symbol: '\u0414\u0438\u043d\u002e',
        format: '$_.__',
        minorUnit: 2
    },
    RUB: {
        number: 643,
        symbol: '\u0440\u0443\u0431',
        format: '$_.__',
        minorUnit: 2
    },
    RWF: {
        number: 646,
        symbol: 'FRw',
        format: '$_.__',
        minorUnit: 0
    },
    SAR: {
        number: 682,
        symbol: '\ufdfc',
        format: '$_.__',
        minorUnit: 2
    },
    SBD: {
        number: 90,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    SCR: {
        number: 690,
        symbol: '\u20a8',
        format: '$_.__',
        minorUnit: 2
    },
    SDG: {
        number: 938,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    SEK: {
        number: 752,
        symbol: 'kr',
        format: '$_.__',
        minorUnit: 2
    },
    SGD: {
        number: 938,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    SHP: {
        number: 654,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    SLL: {
        number: 694,
        symbol: 'Le',
        format: '$_.__',
        minorUnit: 0
    },
    SOS: {
        number: 706,
        symbol: 'S',
        format: '$_.__',
        minorUnit: 2
    },
    SRD: {
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    SSP: {
        number: 728,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    STD: {
        number: 678,
        symbol: 'Db',
        format: '$_.__',
        minorUnit: 0
    },
    SYP: {
        number: 760,
        symbol: '£',
        format: '$_.__',
        minorUnit: 2
    },
    SZL: {
        number: 748,
        symbol: 'E',
        format: '$_.__',
        minorUnit: 2
    },
    THB: {
        number: 764,
        // symbol: '\u0e3f', current font doesnt support the symbol for thb
        symbol: 'THB',
        format: '$_.__',
        minorUnit: 2
    },
    TJS: {
        number: 972,
        symbol: 'SM',
        format: '$_.__',
        minorUnit: 2
    },
    TMT: {
        number: 934,
        symbol: 'T',
        format: '$_.__',
        minorUnit: 2
    },
    TND: {
        number: 788,
        symbol: '\u062f.\u062a',
        format: '$_.__',
        minorUnit: 3
    },
    TOP: {
        number: 776,
        symbol: 'T$',
        format: '$_.__',
        minorUnit: 2
    },
    TRY: {
        number: 949,
        symbol: '\u20ba',
        format: '$_.__',
        minorUnit: 2
    },
    TTD: {
        number: 780,
        symbol: 'TT$',
        format: '$_.__',
        minorUnit: 2
    },
    TVD: {  // Non-ISO: actually AUD
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    TWD: {
        number: 901,
        symbol: 'NT$',
        formalLocalSymbol: '\u5713',
        localSymbol: '\u5143',
        format: '$_.__',
        minorUnit: 2
    },
    TZS: {
        number: 834,
        symbol: '',
        format: function (wholePart, fractionalPart) {
            return (
                (wholePart === 0 ? '=' : wholePart.toString()) +
                '/' +
                (fractionalPart === 0 ? '=': fractionalPart.toString())
                );
        },
        minorPart: 2
    },
    UAH: {
        number: 980,
        symbol: '\u20b4',
        format: '$_.__',
        minorUnit: 2
    },
    UGX: {
        number: 800,
        symbol: 'USh',
        format: function (wholePart/*, fractionalPart*/) {
            /**
             * The Ugandan shilling now has no subdivision
             */
            return wholePart + 'USh';
        },
        minorUnit: 2
    },
    USD: {
        number: 840,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    // USN
    // USS
    // UYI
    UYU: {
        number: 858,
        symbol: '$U',
        format: '$_.__',
        minorUnit: 2
    },
    UZS: {
        number: 860,
        symbol: '\u043b\u0432',
        format: '$_.__',
        minorUnit: 2
    },
    VEF: {
        number: 973,
        symbol: 'Bs',
        format: '$_.__',
        minorUnit: 2
    },
    VND: {
        number: 704,
        symbol: '\u20ab',
        format: '$_.__',
        minorUnit: 0
    },
    VUV: {
        number: 548,
        symbol: 'VT',
        format: '$_.__',
        minorUnit: 0
    },
    WST: {
        number: 882,
        symbol: 'WS$',
        format: '$_.__',
        minorUnit: 2
    },
    XAF: {
        number: 950,
        symbol: 'FCFA',
        format: '$_.__',
        minorUnit: 0
    },
    // XAG
    // XAU
    // XBA
    // XBB
    // XBC
    // XBD
    XCD: {
        number: 951,
        symbol: '$',
        format: '$_.__',
        minorUnit: 2
    },
    // XDR
    // XFU
    XOF: {
        number: 952,
        symbol: 'CFA',
        format: '$_.__',
        minorUnit: 0
    },
    // XPD
    XPF: {
        number: 953,
        symbol: 'F',
        format: '$_.__',
        minorUnit: 0
    },
    // XPT
    // XTS
    YER: {
        number: 886,
        symbol: '\ufdfc',
        format: '$_.__',
        minorUnit: 2
    },
    ZAR: {
        number: 710,
        symbol: 'R',
        format: '$_.__',
        minorUnit: 2
    },
    ZMW: {
        number: 976,
        symbol: 'ZK',
        format: '$_.__',
        minorUnit: 2
    },
    XXX: {
        number: 999,
        symbol: '',
        format: '$_.__',
        minorUnit: 2
    }
};

function currencyFormat(value, symbol) {
    var result;

    if (value < 9.5) {
        // The regular expression matches a decimal point followed by one or more zeros
        // occurring at the end of the string. In other words if there are only zeros
        // after the decimal point, they will be stripped.
        result = value.toFixed(2).replace(/\.0+$/, '');
    } else if (value < 1000) {
        result = value.toFixed(0);
    } else if (value < 1000000) {
        result = (value / 1000).toFixed(1).replace(/\.0+$/, '') + 'K';
    } else {
        result = (value / 1000000).toFixed(1).replace(/\.0+$/, '') + 'M';
    }
    return symbol + result;
}

function shortCurrencyFormat(code, value) {
    var currency, symbol;

    code = typeof code === 'string' ? code.toUpperCase() : code;
    currency = currencyMap[code] || currencyMap.XXX;
    symbol = currency.symbol;

    return currencyFormat(value, symbol);
}

export default shortCurrencyFormat;


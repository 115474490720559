import _ from 'underscore'
import Marionette from 'Backbone.Marionette';
import Handlebars from 'handlebars';
import React from 'react';
import ReactDOM from 'react-dom';
import vent from 'js/vent'
import app from 'js/app'
import AppConfig from 'app/app-config'
import security from 'js/utils/security'
import MessageBox from 'js/views/message_box'
import BulkEdit from 'js/views/bulk_edit'
import MergeView from 'js/views/merge'
import appContent from 'js/views/appcontent'
import SearchReactView from 'js/react_views/search/search';
import IndividualModel from 'js/models/contact'


var SearchReactContainerView = Marionette.ItemView.extend({
    initialize: function() {
        var self = this;

        this.searchTerm = "";

        this.groupedResults = {
            individuals: [],
            opportunities: [],
            organizations: []
        };

        this.allSelected = {};
        this.hasSelected = {};

        this.listenTo(vent, 'search:toggle', function() {
            if ($("#app-stacks").hasClass("app-search-visible")) {
                self.$el.trigger('search:hide');
            }
            else {
                self.show();
            }
        });
        this.listenTo(vent, 'search:show', function() {
            if (!$("#app-stacks").hasClass("app-search-visible")) {
                self.show();
            }
        });
        this.listenTo(vent, 'search:hide', function() {
            if ($("#app-stacks").hasClass("app-search-visible")) {
                self.$el.trigger('search:hide');
            }
        });
        this.listenTo(vent, 'search:term', function(term) {
            self.handleSearch(term);
        });
        this.listenTo(vent, 'search:close menu-item:click', function() {
            self.$el.trigger('search:hide');
        });
        this.listenTo(vent, 'organization:delete individual:delete opportunity:delete', function() {
            if ($("#app-stacks").hasClass("app-search-visible")) {
                self.handleBackToResults();
                self.handleSearch(self.searchTerm);
            }
        });

        // search has single instance so no need to unbind
        // todo: es necesario esto?
        // $(document).bind('keyup.escape', function(ev) {
        //     if (ev.which === 27) {
        //         self.$el.trigger('search:hide');
        //     }
        // });
    },
    render: function() {
        ReactDOM.render(
            <SearchReactView
                searchTerm={this.searchTerm}
                loading={this.loading}
                hasResults={this.hasResults}
                groupedResults={this.groupedResults}
                browseMode={this.browseMode}
                allSelected={this.allSelected}
                hasSelected={this.hasSelected}
                handleBackToResults={this.handleBackToResults.bind(this)}
                handleClose={this.handleClose.bind(this)}
                handleItemClick={this.handleItemClick.bind(this)}
                handleCBClick={this.handleCBClick.bind(this)}
                handleSelectAllClick={this.handleSelectAllClick.bind(this)}
                handleSearch={this.handleSearch.bind(this)}
                handleBulkDelete={this.handleBulkDelete.bind(this)}
                handleBulkEdit={this.handleBulkEdit.bind(this)}
                handleMergeItems={this.handleMergeItems.bind(this)}
                handleNewIndividual={this.handleNewIndividual.bind(this)}
                />,
            this.$el.get(0)
        );
    },
    handleBackToResults: function() {
        if (!this.browseMode) {
            return;
        }

        this.browseMode = false;

        // legacy hiding of Close button in item views
        $('#app-stacks').removeClass("app-search-browse");

        this.openedItem.viewOpen = false;

        this.render();
    },
    handleClose: function() {
        var currentLocation = window.location.href;

        if (currentLocation !== this.locationPreShow) {
            window.location.href = this.locationPreShow;
        }

        this.$el.trigger('search:hide');
    },
    handleItemClick: function(type, id) {
        var self = this;
        app.dirtyModelHandler.confirm(this, function () {
            self.browseMode = type;

            // legacy hiding of Close button in item views
            $('#app-stacks').addClass("app-search-browse");

            self.openedItem && (self.openedItem.viewOpen = false);
            self.openedItem = self.groupedResults[type][self.resultMap[id]];
            self.openedItem.viewOpen = true;

            self.render();
            vent.trigger(type + ':detail', {}, id);
        });
    },
    handleCBClick: function(type, id, select) {
        this.groupedResults[type][this.resultMap[id]].selected = select;

        const hasUnselected = !!_.find(this.groupedResults[type], function(item) { return !item.selected; });

        this.allSelected[type] = !hasUnselected;

        this.hasSelected[type] = !!_.find(this.groupedResults[type], function(item) { return item.selected; });

        this.render();
    },
    handleSelectAllClick: function(type) {
        const hasUnselected = !!_.find(this.groupedResults[type], function(item) { return !item.selected; });

        _.each(this.groupedResults[type], function(item) {
            item.selected = hasUnselected;
        });

        this.allSelected[type] = hasUnselected;

        this.hasSelected[type] = !!_.find(this.groupedResults[type], function(item) { return item.selected; });

        this.render();
    },
    handleSearch: function(searchTerm, options) {
        this.searchTerm = _.isString(searchTerm) ? searchTerm : this.searchTerm;
        this.loading = true;
        this.render();
        this.throttledHandleSearch(searchTerm, options);
    },
    throttledHandleSearch: _.debounce(function(searchTerm, options) {
        var self = this;
        var additionalFields = AppConfig.getValue('search.individuals.additional_fields', []);
        const excludeReservationsFromResults = AppConfig.getValue('search.opportunities.exclude_reservations_from_results', false);

        $.ajax({
            type: 'GET',
            url: '/v1/search',
            data: {
                search: this.searchTerm,
                start: 0,
                rows: 20,
                rows_per_type: true,
                search_extra: true,
                additional_fields: additionalFields.join(',')
            },
            contentType: 'application/json',
            dataType: 'json',
            success: function (results) {
                // key: uuid, value: ind in groupedResults subarray
                self.resultMap = {};

                self.groupedResults = {
                    individuals: [],
                    opportunities: [],
                    organizations: []
                };

                self.allSelected = {};
                self.hasSelected = {};

                if (options && options.preselectId) {
                    var item = _.find(results, function(item) { return item.id === options.preselectId;});
                    if (item) {
                        item.selected = true;
                        self.hasSelected[item.type] = true;
                    }
                }

                self.hasResults = false;
                _.each(results, function (item) {
                    if (item.type in self.groupedResults) {
                        if (excludeReservationsFromResults && item.type === 'opportunities' && item.funnel.toLowerCase() === 'reservations') {
                            return;
                        }

                        const ind = self.groupedResults[item.type].push(item) - 1;
                        self.resultMap[item.id] = ind;
                        self.hasResults = true;
                    }
                });

                self.loading = false;
                self.render();
            }
        });
    }, 500),
    handleBulkDelete: function(type) {
        var self = this;

        var selection = _.filter(this.groupedResults[type], function(item) { return item.selected; });
        var selectedCount = selection.length;
        var permittedIds = [];

        _.each(this.groupedResults[type], function(item) {
            if (item.selected && security.checkPermission('delete', item)) {
                permittedIds.push(item.id);
            }
        });

        var permittedCount = permittedIds.length;

        // 3 cases:
        var mbContent = {
            message: '',
            icon: 'icon-trashcan',
            itemsLength: permittedCount
        };

        var canDelete = true;
        if (permittedCount === 0) { // 1. can't delete anything
            canDelete = false;
            mbContent.message = "You don't have permission to delete selected item" + (selectedCount > 1 ? 's' : '');
            mbContent.icon = 'icon-blocked';
            mbContent.itemsLength = null;
            MessageBox.showOk( mbContent, this.options.parent );
        }
        else if (permittedCount !== selectedCount) { // 2. can delete some items
            mbContent.accept_is_negative = true;
            mbContent.message = '<p>You have permission to delete ' + permittedCount +
                ' of ' + selectedCount + ' selected items.</p>' +
                '<strong class="cta">Do you want to continue?</strong>';
        }
        else { // 3. can delete all items
            mbContent.accept_is_negative = true;
            mbContent.message = 'Are you sure you want to <strong>permanently</strong> delete ' +
                permittedCount + ' item' + (permittedCount > 1 ? 's' : '') + '?';
        }

        var mbProgressContent = {
            icon: 'icon-trashcan',
            itemsLength: permittedCount,
            message: "Deleting " + permittedCount + " item" + (permittedCount > 1 ? "s" : "") +
                "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                "<div class='mb-wait-cont'>0% complete</div>"
        };

        var deleteData = {
            entity_type: type,
            entity_ids: permittedIds
        };


        if (canDelete) {
            MessageBox.showYesNo(
                mbContent,
                this.options.parent,
                function() { // Yes
                    var handle = MessageBox.showNoBtn(
                        mbProgressContent,
                        self.options.parent,
                        {
                            staticRegion: true
                        }
                    );

                    var progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                    var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                    progressBar.width('0%');
                    completeMessage.text('0% complete');

                    $.ajax({
                        type: 'POST',
                        url: '/bulk_delete?queue',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify(deleteData),
                        success: function(data) {
                            var interval = setInterval(function() {
                                if (self.isClosed) {
                                    return;
                                }

                                $.get('/bulk_delete/' + data.id, function(data) {
                                    if (data.status === 'finished') {
                                        clearInterval(interval);
                                        handle.reset();

                                        self.handleOpenView(selection);

                                        self.handleSearch();
                                    }
                                    else if (data.status === 'failed') {
                                        // nothing specified, but don't continue fetching
                                        clearInterval(interval);
                                    } else {
                                        var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                        progressBar.width(pct + '%');
                                        completeMessage.text(Math.floor(pct) + '% complete');
                                    }
                                });
                            }, 2000);
                        },
                        error: function() {
                            handle.reset();

                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'There was an error processing this request',
                                        classes: 'load-error error',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            );
        }
    },
    handleBulkEdit: function(type) {
        var self = this;

        var selection = _.filter(this.groupedResults[type], function(item) { return item.selected; });
        var selectedCount = selection.length;

        if (selectedCount < 2) {
            var content = {
                message: 'Please select at least 2 records for bulk edit',
                icon: 'icon-warning'
            };

            MessageBox.showOk(content, this.options.parent);
        }
        else {
            var data = {
                elementType: type,
                numItemsSelected: selectedCount,
                selection: new Backbone.Collection(_.filter(this.groupedResults[type], function(item) { return item.selected; }))
            };

            var view = new BulkEdit(data);

            this.listenTo(view, 'bulk-edit:done', function() {
                self.handleOpenView(selection);

                self.handleSearch();
            });

            this.options.parent.bulkEditRegion.show(view);
        }
    },
    handleMergeItems: function(type) {
        var self = this;

        var selection = _.filter(this.groupedResults[type], function(item) { return item.selected; });
        var selectedCount = selection.length;

        if (selectedCount < 2 || selectedCount > 5) {
            MessageBox.showOk(
                {
                    message: 'You can merge between 2 and 5 contacts',
                    icon: 'icon-warning'
                },
                this.options.parent
            );
            return;
        }

        var mergeView = new MergeView({
            elementType: type.slice(0, -1), // no 's' at the end!
            item_ids: selection.map(function(item){ return item.id; })
        });
        this.listenTo(mergeView, 'merge:done', function(data) {
            self.handleOpenView(selection);

            self.handleSearch(null, { preselectId: data.id });
        });
        this.options.parent.mergeRegion.show(mergeView);
    },
    canStartSearchMode: function() {
        return !$("#app-stacks").hasClass("app-search-visible");
    },
    activeSearchMode: function() {
        this.show();
    },
    show: function() {
        this.$el.trigger('search:show');
        this.browseMode = false;

        // legacy hiding of Close button in item views
        $('#app-stacks').removeClass("app-search-browse");

        this.searchTerm = "";
        this.groupedResults = {
            individuals: [],
            opportunities: [],
            organizations: []
        };
        this.allSelected = {};
        this.hasSelected = {};
        this.locationPreShow = window.location.href;
        this.render();
    },
    handleOpenView: function(selection) {
        var self = this;

        var regions = appContent.contentRegions;

        // if any of the contacts is open in a detail view, close it
        _.each(regions, function(region) {
            var view = region.currentView;

            if (view && view.model && (_.find(selection, function(item) { return item.id === view.model.id; }))) {
                view.closeView();
                self.handleBackToResults();
            }
        });
    },
    handleNewIndividual: function() {
        var searchTerm = this.searchTerm.trim();
        var individualData = {};
        var reEmail = /\S+@\S+\.\S+/;
        var rePhone = /[0-9]/;

        if (reEmail.test(searchTerm)) {
            individualData.communication = [{medium: 'email', value: searchTerm}];
        } else if (rePhone.test(searchTerm)) {
            individualData.communication = [{medium: 'phone', value: searchTerm}];
        } else {
            var separatorPos = searchTerm.indexOf(' ');

            if (separatorPos === -1) {
                individualData.last_name = searchTerm;
            } else {
                individualData.first_name = searchTerm.substring(0, separatorPos);
                individualData.last_name = searchTerm.substring(separatorPos + 1);
            }
        }

        vent.trigger('individuals:detail:new', {model: new IndividualModel(individualData)});
        this.$el.trigger('search:hide');
    }
});

export default SearchReactContainerView;

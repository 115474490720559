import _ from 'underscore'
import Backbone from 'backbone'


export default class {
    constructor(collectionView) {
        this.view = collectionView;
        this.filter = _.debounce(this.filter, 300);
        this.activeModel = null;

        this.setInitialCollection(this.view.collection);
    }

    setInitialCollection(initialCollection) {
        this.initialCollection = new Backbone.Collection(initialCollection.models);
    }

    findActiveModel() {
        this.activeModel = null;

        const self = this;

        _.defer(function() {
            for (let i = 0; i < self.view.children.length; ++i) {
                const view = self.view.children.findByIndex(i);

                if (view.model.isFolder()) {
                    for (let c = 0; c < view.container.currentView.children.length; ++c) {
                        const childView = view.container.currentView.children.findByIndex(c);

                        if (childView.$el.hasClass('active')) {
                            self.activeModel = childView.model;
                            return;
                        }
                    }
                } else if (view.$el.hasClass('active')) {
                    self.activeModel = view.model;
                    return;
                }
            }
        });
    }

    filter(text) {
        const lowerText = text.toLowerCase();
        let modelsByRating = {};

        for (const model of this.initialCollection.models) {
            if (model.isFolder()) {
                for (const im of model.get('groups').models) {
                    const idx = im.get('name').toLowerCase().indexOf(lowerText);

                    if (idx !== -1) {
                        if (!(idx in modelsByRating)) {
                            modelsByRating[idx] = [];
                        }

                        modelsByRating[idx].push(im);
                    }
                }
            } else {
                const idx = model.get('name').toLowerCase().indexOf(lowerText);

                if (idx !== -1) {
                    if (!(idx in modelsByRating)) {
                        modelsByRating[idx] = [];
                    }

                    modelsByRating[idx].push(model);
                }
            }
        };

        const ratings = _.sortBy(_.keys(modelsByRating), r => parseInt(r));
        let newModels = [];

        for (const r of ratings) {
            newModels = newModels.concat(_.sortBy(modelsByRating[r], m => m.get('name').toLowerCase()));
        }

        this.setModels(newModels);
        this.view.$el.addClass('disable-dragging');
    }

    resetFilter() {
        this.setModels(this.initialCollection.models);
        this.view.$el.removeClass('disable-dragging');
    }

    setModels(models) {
        this.view.collection.reset(models);

        if (this.activeModel) {
            const view = this.view.children.findByModel(this.activeModel);

            if (view) {
                view.$el.addClass('active');
            } else {
                for (let i = 0; i < this.view.children.length; ++i) {
                    const child = this.view.children.findByIndex(i);

                    if (child.model.isFolder()) {
                        const cview = child.container.currentView.children.findByModel(this.activeModel);

                        if (cview) {
                            cview.$el.addClass('active');
                            break;
                        }
                    }
                }
            }
        }
    }
}
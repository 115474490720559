import _ from 'underscore'

import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import GroupElementsCollection from 'js/collections/group_elements'
import IndividualFilterModel from 'js/models/individual_filter'
import IndividualItemView from 'js/views/individuals/item'
import FilterQuickOptionsIndividuals from 'js/views/filters/quick-options-individuals'
import TextManager from 'app/text-manager'
import NoCollectionView from 'js/views/base/no_collection'
import vent from 'js/vent'
import app from 'js/app'
import AppConfig from 'app/app-config'
import TableBodyContainerView from 'js/views/base/table-body-container-view'


var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    templateHelpers: function() {
        let showDemographicMapLayout = this.options?.demographicMap?.staticPins;

        if (showDemographicMapLayout) {
            var clientPreferences = app.user.get('client').preferences || {};

            if (!clientPreferences.demographic_maps) {
                showDemographicMapLayout = false;
            } else {
                const mapInfo = JSON.parse(clientPreferences.demographic_maps).individuals;

                if (!mapInfo || !mapInfo.address_builder) {
                    showDemographicMapLayout = false;
                }
            }
        }

        return {
            id: this.parent.getElementType(),
            name: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
            hideInfo: true,
            disableLayoutSelection: !showDemographicMapLayout,
            enableDemographicMapLayout: showDemographicMapLayout
        };
    },
    tools: {
        newItem: true,
        addItem: false,
        deleteItem: true,
        editItem: true,
        selectAllPage: true,
        editColumns: true,
        mergeItems: true,
        selectAll: true
    },
    groupType: "Individual",
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new IndividualFilterModel({id: this.filter_id});
        this.collection = new GroupElementsCollection(null, {elementType: 'individuals'});
        this.collection.toBeLoaded = true;
        this.quickFilter = new FilterQuickOptionsIndividuals();

        this.fetchOptions = _.extend({
            extended: true,
            group_id: "all",
            filter_id: this.filter && this.filter.get('id')
        }, this.fetchOptions);

        if (options.owner_id) {
            this.fetchOptions.owner_id = options.owner_id;
        }

        if (options.tag_ids) {
            this.fetchOptions.tag_ids = options.tag_ids;
        }

        if (options.funnel_ids) {
            this.fetchOptions.funnel_ids = options.funnel_ids;
        }

        this.listenTo(vent, 'individual:delete', function(model) {
            this.collection.remove(model);
        });

        this.listenTo(vent, 'organization:delete', function(model) {
            var id = model.get('id');
            var items = this.collection.filter(function(model) {
                var organization = model.get('organization');
                return organization && organization.id === id;
            });
            this.collection.remove(items);
        });
    },
    createTableView: function() {
        var defaultColumns = AppConfig.getValue('group_pages.individuals.default_columns', [
            'photo_url',
            'first_name',
            'last_name',
            'organization.name',
            'email',
            'phone'
        ]);

        if (this.options.extra_columns) {
            defaultColumns = defaultColumns.concat(this.options.extra_columns.split(','));
        }

        let tableOptions = {
            parent: this,
            collection: this.collection,
            customFields: this.customFields,
            campaigns: this.campaigns,
            type: this.options.type,
            elementType: this.options.elementType,
            filter: this.filter,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                newButton: true
            },
            defaultColumns: defaultColumns
        };

        const defaultSort = AppConfig.getValue('group_pages.individuals.default_sort');

        if (defaultSort) {
            tableOptions.defaultSort = defaultSort;
        }

        return new TableBodyContainerView(tableOptions);
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:individual:show', {model: model});
    },
    newItem: function() {
        this.options.parent.trigger('push-view:individual:new', {});
    }
});

export default IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'individuals';
    },
    getUrl: function() {
        if (this.options.owner_id) {
            return 'myIndividuals';
        }
        else {
            return 'individuals';
        }
    },
    getParams: function() {
        var params = IODListingBaseView.prototype.getParams.apply(this, arguments) || {};

        if (this.options.extra_columns) {
            params.extra_columns = this.options.extra_columns;
        }

        if ('sp' in this.options) {
            params.sp = '';
        }

        return params;
    }
});

import React from 'react';
import classnames from 'classnames';

import style from './ViewPaneNav.css';

class ViewPaneNav extends React.Component {
    render() {
        const moreInfoTabCns = classnames(
            style.moreInfoTab,
            {[style.active]: this.props.activeTab === 'moreInfo'}
        );
        const detailTabCns = classnames(
            style.detailTab,
            {[style.active]: this.props.activeTab === 'details'}
        );
        const iconCns = classnames(
            style.moreInfoIcon,
            'icon-info'
        );

        const { onTabClick } = this.props;

        return (
            <nav className={style.ViewPaneNav}>
                <ul>
                    <li className={moreInfoTabCns}
                        onClick={() => { onTabClick('moreInfo'); }}>
                        <i className={iconCns} />
                    </li>
                    <li className={detailTabCns}
                        onClick={() => { onTabClick('details'); }}>
                        Details
                    </li>
                </ul>
            </nav>
        );
    }
}

export default ViewPaneNav;

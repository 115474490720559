import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import TagsCollection from 'js/collections/tags'
import backboneSelect2 from 'js/widgets/backbone-select2'
import activityFilterTemplate from 'templates/activity/activity-filter.handlebars'


var filterTypeMap = {
        individuals: 'all_for_individuals',
        organizations: 'all_for_organizations',
        opportunities: 'all_for_opportunities'
    };

var integrationFilters = {
    xero: {
        tooltip: 'Xero',
        icon: 'icon-xero-logo'
    }
};

var formatResult = function(item) {
    // separator line doesn't need text
    return item.line ? '': item.name;
};

var emptyCollectionView = Marionette.ItemView.extend({
    template: Handlebars.compile("<div class='no-activity'>No activity for current selection</div>")
});

var filterStatus = function() {
    var self = this,
        typeFilterSize = _.reduce(this.typeFilters, function(memo, type) { return type in self.filter ? memo + 1 : memo }, 0),
        baseFilterSize = 0,
        typeFilterCount = this.typeFilters.length;

    baseFilterSize += 'creator_or_owner_id' in this.filter ? 1 : 0;
    baseFilterSize += 'tag_id' in this.filter ? 1 : 0;
    baseFilterSize += this.filter['all'] && !this.filter['all'][filterTypeMap[this.options.type]] ? 1 : 0;

    var typeFilter = typeFilterSize < typeFilterCount,
        baseFilter =  !!baseFilterSize;

    return {
        typeFilterSize: typeFilterSize,
        typeFilter: typeFilter,
        baseFilter: baseFilter,
        resetActive: typeFilter || baseFilter
    };
};

export default Marionette.ItemView.extend({
    template: Handlebars.compile(activityFilterTemplate),
    templateHelpers: function() {
        var typeName,
            typeNameMap = {
                individuals: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                organizations: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                opportunities: TextManager.parseText('${ID_DEAL, capitalize}')
            };
        if (this.options.type) {
            typeName = typeNameMap[this.options.type];
        }
        return _.extend({
            mainFilter: this.options.mainFilter,
            targetTypeName: typeName,
            integrations: this.integrations,
            // default is "all" activities
            getAll: !this.filter['all'] || this.filter['all'][filterTypeMap[this.options.type]],
            resetFiltersActive: filterStatus.call(this).resetActive,
            appointmentsAvailables: AppConfig.getValue('app_nav_items.appointments.visible')
        }, this.filter);
    },
    ui: {
        container: '.filter-container',
        toggle: '.toggle',
        userInput: '.user-input',
        tagInput: '.tag-input',
        selectUser: '.select-user',
        contextButtons: '.context-button',
        filterCount: '.filter-count',
        header: '.header',
        resetFilters: '.reset-filters',
        typeFilters: '.type-filter'
    },
    events: {
        'click .header': function() {
            this.ui.container.toggle();
            this.ui.toggle.toggleClass('icon-caret-down').toggleClass('icon-caret-right');
            this.adjustList();
        },
        'click .select-user': function() {
            this.ui.userInput.select2('open');
        },
        'click .type-filter button': function(e) {
            var target = $(e.target).closest('button');
            if (target.hasClass('active')) {
                delete this.filter[target.attr('filter')];
            }
            else {
                this.filter[target.attr('filter')] = true;
            }
            target.toggleClass('active');
            this.loadCollection();
            this.storeFilter();
            this.updateFilterCount();
        },
        'click .context-button': function(e) {
            e.preventDefault();
            var target = $(e.currentTarget);
            if (target.hasClass('active')) {
                return;
            }
            this.ui.contextButtons.removeClass('active');
            target.addClass('active');
            this.filter['all'] = this.filter['all'] || {};
            this.filter['all'][filterTypeMap[this.options.type]] = !this.filter['all'][filterTypeMap[this.options.type]];
            this.loadCollection();
            this.storeFilter();
            this.updateFilterCount();
        },
        'click .reset-filters': function() {
            var self = this,
                all = this.filter['all'] || {};
            this.filter = {};
            _.each(this.typeFilters, function(type) { self.filter[type] = true; });
            this.ui.typeFilters.find('button').addClass('active');
            this.ui.selectUser.find('div').text('Any User');
            if (this.ui.contextButtons.length > 0) {
                this.ui.contextButtons.removeClass('active');
                // preserve other type "all" activity status
                this.filter['all'] = all;
                // default is "all" activities
                this.filter['all'][filterTypeMap[this.options.type]] = true;
                $(this.ui.contextButtons[1]).addClass('active');
            }

            this.tagSelect.setValue([]);

            this.loadCollection();
            this.storeFilter();
            this.updateFilterCount();
        }
    },
    initialize: function() {
        var self = this,
            filter;

        this.typeFilters = ['notes', 'communications', 'contact_updates', 'deals', 'campaign_emails', 'automations', 'web_activity', 'tasks'];

        if (AppConfig.getValue('app_nav_items.appointments.visible')) {
            this.typeFilters.push('appointments');
        }

        if (this.options.mainFilter) {
            filter = app.user.get('preferences')['main_activity_filter'];
        }
        // used for context items
        else {
            filter = app.user.get('preferences')['secondary_activity_filter'];
        }

        if (filter) {
            this.filter = _.clone(filter);
        }
        else {
            // default filter if nothing set
            this.filter = {};
            _.each(this.typeFilters, function(type) { self.filter[type] = true; });

            // web_activity filter is off by default on main dashboard
            if (this.options.mainFilter) {
                delete this.filter['web_activity'];
            }
        }

        if (!this.options.mainFilter) {
            if (AppConfig.getValue(`activity_filter.secondary.${this.options.type}.force_record_view`)) {
                if (!this.filter['all']) {
                    this.filter['all'] = {};
                }

                this.filter['all'][filterTypeMap[this.options.type]] = false;
            }
        }

        this.listenTo(vent, 'change:activity:filter:owner', function(item, storeFilters) {
            this.changeOwner(item, !!storeFilters);
        }, this);
        this.listenTo(vent, 'remove:activity:filter:owner', function() {
            var id, name;
            if ('creator_or_owner_name' in this.filter) {
                id =  this.filter['creator_or_owner_id'];
                name = this.filter['creator_or_owner_name'];
            }
            else {
                id = 0;
                name = 'Any User';
            }
            this.changeOwner({ id: id, name: name }, false);
        }, this);

        // ...
        _.defer(function() {
            self.integrations = [];

            if (_.contains(app.user.get('preferences').lab_flags, 'SAL-3868')) {
                _.each(app.globalData.integrationsInfo.authorized, function(integration) {
                    if (integrationFilters[integration.id]) {
                        var id = 'integration:' + integration.id;

                        self.typeFilters.push(id);
                        self.integrations.push({
                            id: id,
                            icon: integrationFilters[integration.id].icon,
                            tooltip: integrationFilters[integration.id].tooltip,
                            active: filter ? self.filter[id] : true
                        });

                        // not initial filters defined, by default the filters are active
                        if (!filter) {
                            self.filter[id] = true
                        }
                    }
                });
            }

            if (self.integrations.length > 0) {
                self.render();
            }

            if (!self.isClosed) {
                self.loadCollection();
                self.initTooltips();
                self.updateFilterCount();
            }
        });
    },
    onRender: function() {
        this.userSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.userInput,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            staticModels: [
                new Backbone.Model({ id: 0, name: 'Any User' }),
                new Backbone.Model({ id: app.user.get('id'), name: app.user.get('name') + ' (me)' }),
                // This is separator. Should be searchable same as previous items. Item without id has border.
                new Backbone.Model({ name: 'Any User' + app.user.get('name') + ' (me)', line: true })
            ],
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover',
                formatResult: formatResult
            }
        });
        this.listenTo(this.userSelect, 'change', function(item) {
            this.changeOwner(item, true);
        }, this);

        // tag selector
        var self = this;

        var createTagsSelector = function(value) {
            self.tagSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.tagInput,
                url: '/tags',
                value: value,
                params: {
                    rows: -1
                },
                text: 'name',
                options: {
                    containerCssClass: 'select2-block select2-plain',
                    dropdownCssClass: 'popover',
                    formatResult: formatResult
                }
            });
            self.listenTo(self.tagSelect, 'change', function(items) {
                self.changeTag(items);
            });
        }

        // backward compatibility
        if (this.filter.tag_id && this.filter.tag_name) {
            this.filter.tag_ids = [this.filter.tag_id];
            var tagIds = [{
                id: this.filter.tag_id,
                name: this.filter.tag_name
            }];

            createTagsSelector(tagIds);
        } else if (this.filter.tag_ids) {
            var filterTags = this.filter.tag_ids.split(',');
            var tags = new TagsCollection();

            tags.fetch({
                rows: -1,
                success: function() {
                    var tagIds = [];

                    for (var model of tags.models) {
                        if (filterTags.indexOf(model.get('id')) !== -1) {
                            tagIds.push({
                                id: model.get('id'),
                                name: model.get('name')
                            });
                        }
                    }

                    createTagsSelector(tagIds);
                }
            });
        } else {
            createTagsSelector([]);
        }

        if ('creator_or_owner_name' in this.filter) {
            this.ui.selectUser.find('div').text(this.filter['creator_or_owner_name']);
        }
        else {
            this.ui.selectUser.find('div').text('Any User');
        }
    },
    changeTag: function(items) {
        if (items.length === 0) {
            delete this.filter['tag_ids'];
        } else {
            this.filter['tag_ids'] = items.map(i => i.id).join(',');
        }

        this.loadCollection();
        this.updateFilterCount();
        this.storeFilter();
    },
    changeOwner: function(item, storeFilters) {
        this.ui.selectUser.find('div').text(item.name);
        if (item.id === 0) {
            delete this.filter['creator_or_owner_id'];
            delete this.filter['creator_or_owner_name'];
        }
        else {
            this.filter['creator_or_owner_id'] = item.id;
            this.filter['creator_or_owner_name'] = item.name;
        }
        this.loadCollection();
        this.updateFilterCount();
        if (storeFilters) {
            this.storeFilter();
        }
    },
    adjustList: function() {
        this.$el.closest('.activity-feed-widget').find('#activity-list').css(
            'top',  this.ui.container.is(':visible') ? this.ui.container.height() + 15 : 0
        );
    },
    loadCollection: function() {
        var filter = _.clone(this.filter);
        if (!filter['all'] || filter['all'][filterTypeMap[this.options.type]]) {
            const typeKey = this.options.type === 'opportunities' ? 'deals' : this.options.type;

            filter['include_ind_related'] = AppConfig.getValue(`${typeKey}.activities.include_ind_related`, true);
            filter['include_org_related'] = AppConfig.getValue(`${typeKey}.activities.include_org_related`, true);
            filter['include_deal_related'] = AppConfig.getValue(`${typeKey}.activities.include_deal_related`, true);
        }
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-3868')) {
            if (filter['notes']) {
                filter['integration:xero'] = true;
            } else {
                delete filter['integration:xero'];
            }
        }

        // Get activity days to fetch
        if(this.options && this.options.isDashboard){
            const clientPreferences = app.user.get('client').preferences || {};
            filter['is_dashboard'] = true;

            if (clientPreferences.dashboard_activities_fetch_last_days && !isNaN(clientPreferences.dashboard_activities_fetch_last_days)) {
                if(clientPreferences.dashboard_activities_fetch_last_days !== -1){
                    var date = new Date();
                    date.setDate(date.getDate() - parseInt(clientPreferences.dashboard_activities_fetch_last_days));
                    filter['user_created_after'] = date.toISOString();
                }
            } else {
                var date = new Date();
                date.setDate(date.getDate() - 1);
                filter['user_created_after'] = date.toISOString();
            }
        }

        delete filter['all'];

        // remove empty filters
        if ('tag_id' in filter && !filter['tag_id']) {
            delete filter['tag_id'];
        }
        if ('tag_ids' in filter && !filter['tag_ids']) {
            delete filter['tag_ids'];
        }

        this.trigger('filter', {
            data: filter,
            emptyCollection: filterStatus.call(this).typeFilterSize === 0 ? new Backbone.Collection() : null,
            emptyCollectionView: emptyCollectionView
        });
    },
    storeFilter: function() {
        this.sanitizeFilter();
        this.ui.resetFilters.toggleClass('active', filterStatus.call(this).resetActive);
        if (this.options.mainFilter) {
            app.user.updatePreference('main_activity_filter', _.clone(this.filter));
        }
        // used for context items
        else {
            app.user.updatePreference('secondary_activity_filter', _.clone(this.filter));
        }
    },
    sanitizeFilter: function() {
        var self = this,
            filter = {};
        if (this.filter['all']) {
            filter['all'] = this.filter['all'];
            delete this.filter['all'];
        }
        _.each(this.typeFilters, function(filterName) {
            if (self.filter[filterName]) {
                filter[filterName] = self.filter[filterName];
                delete self.filter[filterName];
            }
        });

        if (this.filter['creator_or_owner_id'] && this.filter['creator_or_owner_name']) {
            filter['creator_or_owner_id'] = self.filter['creator_or_owner_id'];
            filter['creator_or_owner_name'] = self.filter['creator_or_owner_name'];
            delete self.filter['creator_or_owner_id'];
            delete self.filter['creator_or_owner_name'];
        }

        delete self.filter['tag_id'];
        delete self.filter['tag_name'];

        if (this.filter['tag_ids'] && this.filter['tag_ids'].length > 0) {
            filter['tag_ids'] = this.filter['tag_ids'];
            delete this.filter['tag_ids'];
        }

        // unset all other fields
        _.each(this.filter, function(val, key) {
            filter[key] = null;
        });

        this.filter = filter;
    },
    initTooltips: function() {
        this.$el.find('[data-toggle=tooltip]').tooltip();
    },
    updateFilterCount: function() {
        var status = filterStatus.call(this);

        this.ui.header.toggleClass('active', status.baseFilter || status.typeFilter);

        if (status.typeFilter) {
            this.ui.filterCount.addClass('active').text(status.typeFilterSize);
        }
        else {
            this.ui.filterCount.removeClass('active');
        }
    }
});

import _ from 'underscore'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import PaginatedListView from 'js/views/base/paginated_list.js'
import FacebookItemView from 'js/views/social/facebook_item.js'
import TwitterItemView from 'js/views/social/twitter_item.js'
import SocialItemsCollection from 'js/collections/social.js'
import ListNavbarView from 'js/views/base/list_navbar.js'
import BaseToolbarView from 'js/views/base/toolbar.js'
import EmptySocialItemView from 'templates/social/empty_item.handlebars'


var NavView, EmptyItemView;

function createSocialListView() {
    return new PaginatedListView({
        listGetItemView: function(model){
            if (!model) {
                return TwitterItemView;
            }

            switch (model.get('network')) {
            case 'twitter':
                return TwitterItemView;
            case 'facebook':
                return FacebookItemView;
            }
        },
        listItemViewOptions: {
            section: 'Social',
            url: 'social/feed',
            emptyView: EmptyItemView
        },
        collection: new SocialItemsCollection(),
        fetchOptions: {
            extraArgs: ['app']
        }
    });
}

function createSocialToolbarView() {
    return new BaseToolbarView({
        tools: {
            newItem: false,
            removeItem: false,
            moveToGroup: false,
            selectAllPage: true,
            assignItem: true,
            hideIfEmpty: true
        }
    });
}

EmptyItemView = Marionette.ItemView.extend({
    className: 'empty-list-item',
    template: Handlebars.compile(EmptySocialItemView)
});

NavView = ListNavbarView.extend({
    className: 'list-wrapper social-feed',
    title: 'Social',
    showTabs: false,
    tabs: [
        {
            id: 'social',
            title: 'Social',
            'class': 'list-nav-social',
            view: createSocialListView,
            toolbarView: createSocialToolbarView
        }
    ],
    initialize: function() {
        this.listenTo(this, 'showItem', function(model) {
            this.trigger('push-view:individual:show', {model: model});
        });

        this.listenTo(this, 'toolbar:assign', this.assign);
        this.listenTo(this, 'toolbar:unassign', this.unassign);
    },
    assign: function (user, sel) {
        _.each(sel, function(view) {
            view.assignItem(user);
        });
    },
    unassign: function (sel) {
        _.each(sel, function(view) {
            view.removeAssignee();
        });
    },
    getUrl: function() {
        return 'social';
    }
});

export default NavView;

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'

import vent from 'js/vent.js'
import app from 'js/app.js'
import PaginatedListView from 'js/views/base/paginated_list.js'
import ForecastItemView from 'js/views/forecasts/item.js'
import ForecastsCollection from 'js/collections/forecasts.js'
import ListNavbarView from 'js/views/base/list_navbar.js'
import BaseToolbarView from 'js/views/base/toolbar.js'
import MessageBox from 'js/views/message_box.js'
import createErrorTemplate from 'templates/forecasts/create-error.handlebars'


var NavView;

function createForecastsListView(options) {
    return new PaginatedListView(_.extend({
        id: 'forecasts-list',
        listItemView: ForecastItemView,
        listItemViewOptions: {
            section: 'Forecasts',
            url: 'forecasts'
        },
        collection: new ForecastsCollection(),
        fetchOptions: {
            data: options.collectionData
        }
    }, options));
}

function createForecastsToolbarView() {
    return new BaseToolbarView({
        tools: {
            newItem: true,
            deleteItem: true,
            selectAllPage: true,
            aggregate: true
        }
    });
}
/*
function createForecastsListSorterView() {
    return new BaseListSorterView();
}

function createForecastsListFiltersView() {
    return new BaseListFiltersView();
}
*/
NavView = ListNavbarView.extend({
    title: 'Forecasts',
    className: 'list-wrapper list-collapsible forecasts-section',
    showTabs: false,
    collapse: true,
    // sortable: true,
    // filterable: true,
    tabs: [
        {
            id: 'forecasts',
            title: 'Forecasts',
            'class': 'list-nav-forecasts',
            view: createForecastsListView,
            toolbarView: createForecastsToolbarView
            // listSorterView: createForecastsListSorterView,
            // listFiltersView: createForecastsListFiltersView
        }
    ],
    initialize: function() {
        ListNavbarView.prototype.initialize.apply(this, arguments);

        this.listenTo(this, 'showItem', function(model) {
            this.trigger('push-view:forecast:show', {model: model});
        });

        this.listenTo(this, 'toolbar:new', function() {
            this.trigger('push-view:forecast:new', {});
        });

        this.listenTo(this, 'toolbar:delete', function(sel) {
            this.deleteItems( sel );
        });

        this.listenTo(this, 'toolbar:aggregate', this.aggregate);
    },
    onClose: function() {
        // Uncollapse List incase collapsed
        vent.trigger('list:uncollapse');
    },
    aggregate: function (aggregateData) {
        var view = this,
            aggregateCollection = new ForecastsCollection(),
            aggregateName = "";

        // ...
        _.each(aggregateData.items, function(view) {
            aggregateCollection.add(view.model, {silent: true});
            aggregateName+=" & "+view.model.get('name');
        });

        // we save a snapshot of the currencies conversion table
        var conversions = app.user.get('client').currencies;

        if (conversions) {
            var currConv = {};
            $.extend(currConv, conversions);
            currConv.currency = app.user.get('client').default_currency;
            conversions = currConv;
        }

        aggregateName = aggregateName.substr(3);
        var xhr = aggregateCollection.createAggregate({
            data: {name: aggregateName, currencies_conversion: conversions},
            beforeSend: function() {
                vent.trigger("alert:show", {
                    type: 'save',
                    model: aggregateCollection.model.prototype,
                    xhr: xhr
                });
            },
            success: function(aggregate, data) {
                // check all deals on forecasts have appropiate conversion rate
                if (data.error) {
                    if (data.error === 'deals_without_currency_conversion') {
                        var message = {
                                action: 'aggregate',
                                forecastsLength: aggregateData.items.length,
                                deals: data.deals,
                                isAdmin: app.user.get('is_admin')
                            },
                            template = Handlebars.compile(createErrorTemplate),
                            mbContent = {
                                message: template(message),
                                icon: 'icon-blocked'
                            };

                        vent.trigger("alert:hide");
                        MessageBox.showOk(mbContent, view);
                    }
                }
                else {
                    aggregateData.success();

                    view.trigger('push-view:forecast:show', {model: aggregate});
                    vent.trigger('forecast:save');

                    vent.trigger("alert:show", {
                        type: 'save',
                        model: aggregateCollection.model.prototype,
                        xhr: xhr
                    });
                }
            },
            error: function() {
                vent.trigger("alert:show", {
                    type: 'save',
                    model: aggregateCollection.model.prototype,
                    xhr: xhr
                });
            }
        });
    },
    getUrl: function() {
        return 'forecasts';
    }
});

export default NavView;

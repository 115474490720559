import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import security from 'js/utils/security';
import vent from 'js/vent';
import TextManager from 'app/text-manager';
import Section from 'app_v2/sections/base/nsection';
import getFilters from './filters';
import dateFormat from 'js/utils/date-format';
import AppConfig from 'app/app-config';
import FinderContentManager from 'app_v2/sections/finder_content_manager';
import { getAllColumns, DEFAULT_COLUMNS_IDS } from './table_columns';


const AdhocGroupIdUrlMapping = {
    user_deals: 'active',
    won_deals: 'won',
    lost_deals: 'lost',
    owned: 'my',
    team_owned: 'team',
    favorites: 'elephants'
};

const groupIdToUrl = (id) => AdhocGroupIdUrlMapping[id] || id;

const groupUrlToId = (url) => {
    for (const k in AdhocGroupIdUrlMapping) {
        if (AdhocGroupIdUrlMapping[k] === url) {
            return k;
        }
    }

    return url;
}

export default class DealsReactView extends React.Component {
    constructor(props) {
        super(props);

        this.allColumns = getAllColumns();
        this.defaultColumns = [];
        this.entityIdToShow = props.entityId;

        for (const cid of DEFAULT_COLUMNS_IDS) {
            this.defaultColumns.push(this.allColumns.find(c => c.id === cid));
        }

        this.finderContentManager = new FinderContentManager();
    }

    componentDidMount() {
        this.mounted = true;

        const parent = this.props.parent;
        const self = this;

        parent.listenTo(vent, 'deal:save deal:delete', function() {
            self.sectionComponent.setDataDirty();
        });

        this.createFinderStructure();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createFinderStructure(activeGroupId) {
        this.finderContentManager.clear();

        const dealType = this.props.section ? this.props.section.name : TextManager.parseText('${ID_DEAL, plural, capitalize}');

        const adhocGroupOptions = {
            section: {
                activeView: 'table',
                entityType: 'opportunities',
                views: {
                    table: {
                        view: 'groupTable',
                        toolbar: {
                            searchable: true,
                            columnsEditable: true,
                            advancedFilters: true,
                            saveAsGroup: true,
                            saveAsCsv: true
                        },
                        actions: {
                            new: AppConfig.getValue('deals.new_button.visible', true),
                            edit: true,
                            delete: true
                        },
                        content: {
                            selectableRows: true,
                            allColumns: this.allColumns,
                            columns: this.defaultColumns,
                            filters: getFilters()
                        }
                    },
                    funnel: {
                        view: 'groupTableFunnel',
                        toolbar: {
                            searchable: true,
                            advancedFilters: true,
                            saveAsGroup: true,
                            saveAsCsv: true
                        },
                        actions: {
                            new: AppConfig.getValue('deals.new_button.visible', true),
                            edit: true,
                            delete: true
                        },
                        content: {
                            filters: getFilters(),
                            tabs: ['funnel', 'momemtum']
                        }
                    }
                }
            }
        };

        const clientPreferences = app.user.get('client').preferences || {};

        // initialize finder content manager
        let fixedContent = [];

        fixedContent.push(
            this.finderContentManager.createAdhocGroup(
                groupUrlToId('active'),
                `Active ${dealType}`,
                'icon-funnel',
                {phase_types: 'user'},
                adhocGroupOptions
            )
        );

        if (AppConfig.getValue('deals.groups.show_system_adhoc_groups', true, this.props.sectionId)) {
            let lostTitle = TextManager.getText('ID_LOST_DEALS');
            let wonTitle = TextManager.getText('ID_WON_DEALS');

            if (this.props.section) {
                if (this.props.section.lost_title) {
                    lostTitle = `${this.props.section.lost_title} ${this.props.section.name}`;
                } else {
                    lostTitle = `Lost ${this.props.section.name}`;
                }

                if (this.props.section.won_title) {
                    wonTitle = `${this.props.section.won_title} ${this.props.section.name}`;
                } else {
                    wonTitle = `Won ${this.props.section.name}`;
                }
            }

            fixedContent = [
                ...fixedContent,
                ...[
                    this.finderContentManager.createAdhocGroup(
                        groupUrlToId('team'),
                        `Team ${dealType}`,
                        'icon-users',
                        {team: true, by_period_id: 'current'},
                        adhocGroupOptions
                    ),
                    this.finderContentManager.createAdhocGroup(
                        groupUrlToId('my'),
                        `My ${dealType}`,
                        'icon-user',
                        {owner_id: app.user.get('id'), by_period_id: 'current'},
                        adhocGroupOptions
                    ),
                    this.finderContentManager.createAdhocGroup(
                        groupUrlToId('lost'),
                        lostTitle,
                        'icon-funnels',
                        {phase_types: 'lost'},
                        adhocGroupOptions
                    ),
                    this.finderContentManager.createAdhocGroup(
                        groupUrlToId('won'),
                        wonTitle,
                        'icon-funnels',
                        {phase_types: 'won'},
                        adhocGroupOptions
                    )
                ]
            ];

            const dashboard = app.user.get('preferences').default_dashboard;

            if (['ceo', 'sales_manager'].indexOf(dashboard) !== -1 && !AppConfig.getValue('disableDealElephants')) {
                fixedContent.push(
                    this.finderContentManager.createAdhocGroup(
                        groupUrlToId('elephants'),
                        'Elephants',
                        'icon-elephant',
                        {group_id: 'favorites'},
                        adhocGroupOptions
                    )
                );
            }

            const periods = this.parsePeriods(app.globalData.periods, dealType);

            for (const period of periods) {
                fixedContent.push(
                    this.finderContentManager.createAdhocGroup(
                        `periods/${period.id}`,
                        period.name,
                        'icon-calendar',
                        {closing_in_period_id: period.id},
                        adhocGroupOptions
                    )
                );
            }
        }

        let sections = [{
            id: 'deals',
            entityType: 'deals',
            name: dealType,
            content: fixedContent
        }];

        this.fetchGroupsUserStructure(sections, activeGroupId);
    }

    fetchGroupsUserStructure(sections, activeGroupId) {
        const self = this;
        const sectionName = this.props.section?.name.toLowerCase();

        const isGroupVisible = (group) => {
            return !sectionName || group.name.toLowerCase().includes(sectionName);
        };

        this.finderContentManager.fetchUserStructure('opportunities', function(structure) {
            if (!self.mounted) {
                return;
            }

            const section = sections[0];
            section.content = [...section.content, ...structure];

            self.finderContentManager.addSections(sections, 0);

            // initial group
            if (!activeGroupId) {
                if (self.props.initialGroupId) {
                    activeGroupId = self.props.initialGroupId;
                } else {
                    activeGroupId = app.user.get('preferences').initial_deal_group || 'active';
                }
            }

            activeGroupId = groupUrlToId(activeGroupId);

            // does the group exist?
            const groupExists = !!self.finderContentManager.getElement(activeGroupId);

            self.finderContentManager.setActiveGroupId(groupExists ? activeGroupId : groupUrlToId('active'));
            self.handleGroupSelect(self.finderContentManager.getActiveGroup(), true);
        }, isGroupVisible);
    }

    parsePeriods(periods, dealType) {
        const now = new Date();
        const periodsToShow = _.first(_.filter(periods, function(period, idx) {
            period.quarter = (idx %= 4) + 1;
            return new Date(period.end_date) > now;
        }), 4);

        if (this.isQuarterPeriod(periods)) {
            return _.map(periodsToShow, function(period) {
                const date = new Date(`${period.start_date}T00:00:00`);
                const quarter = period.quarter || Math.floor(date.getMonth() / 3) + 1;
                const year = String(date.getFullYear());

                return {
                    id: period.id,
                    name: `Q${quarter} '${year.substring(year.length, 2)} ${dealType}`
                };
            }).reverse();
        } else {
            return _.map(periodsToShow, function(period) {
                const date = new Date(period.start_date);
                const month = dateFormat.shortFormatMonth(date.getMonth());
                const year = String(date.getFullYear());

                return {
                    id: period.id,
                    name: `${month} '${year.substring(year.length, 2)} ${dealType}`
                };
            }).reverse();
        }
    }

    isQuarterPeriod(periods) {
        const lastEndDate = new Date(periods[periods.length - 1].end_date);
        const yearBeforeDate = new Date(lastEndDate.getFullYear() - 1, lastEndDate.getMonth(), lastEndDate.getDate());
        const lastYearPeriods = periods.filter(function(period) { return new Date(period.end_date) > yearBeforeDate });

        return lastYearPeriods.length <= 8;
    }

    configureGroup(group) {
        let groupColumns = [];

        if (group.columns?.length > 0) {
            for (const cid of group.columns) {
                const col = this.allColumns.find(c => c.id === cid);

                if (col) {
                    groupColumns.push(col);
                }
            }
        } else {
            groupColumns = this.defaultColumns;
        }

        const hasPermission = security.checkPermission('edit', group);
        const isSmart =  group.groupType === 'smart';
        let moreOptionsItems = [];

        if (hasPermission) {
            moreOptionsItems.push({
                id: 'editGroup',
                title: 'Edit Group',
                args: {
                    groupId: group.shortId,
                    entityType: 'opportunities'
                }
            });
        };

        if (hasPermission && app.user.get('client').permission_type !== 'rba') {
            moreOptionsItems.push({
                id: 'permissionsGroup',
                title: 'Permissions Group',
                args: {
                    groupId: group.shortId,
                    entityType: 'individuals'
                }
            });
        }

        if (hasPermission) {
            moreOptionsItems = moreOptionsItems.concat([{
                id: 'separator'
            }, {
                id: 'deleteGroup',
                title: 'Delete Group',
                args: {
                    groupId: group.shortId,
                    groupName: group.name
                }
            }]);
        }

        group.section = {
            activeView: 'table',
            entityType: 'opportunities',
            views: {
                table: {
                    view: 'groupTable',
                    toolbar: {
                        searchable: true,
                        columnsEditable: hasPermission,
                        advancedFilters: !group.protected,
                        saveAsGroup: true,
                        saveAsCsv: true,
                        moreOptions: {
                            items: moreOptionsItems
                        }
                    },
                    actions: {
                        new: false,
                        edit: true,
                        add: !isSmart && hasPermission,
                        remove: !isSmart && hasPermission,
                        delete: true
                    },
                    content: {
                        selectableRows: true,
                        allColumns: this.allColumns,
                        columns: groupColumns,
                        filters: getFilters()
                    }
                },
                funnel: {
                    view: 'groupTableFunnel',
                    toolbar: {
                        searchable: true,
                        advancedFilters: true,
                        saveAsGroup: true,
                        saveAsCsv: true
                    },
                    actions: {
                        new: AppConfig.getValue('deals.new_button.visible', true),
                        edit: true,
                        delete: true
                    },
                    content: {
                        filters: getFilters(),
                        tabs: ['funnel', 'momemtum']
                    }
                }
            }
        };
    }

    handleGroupSelect(group, noRememberGroup) {
        const self = this;

        const processGroup = (grp) => {
            self.sectionComponent.setGroup(grp);
            vent.trigger('AppContent:contentChange');

            // remember group
            if (!noRememberGroup) {
                let prefKey = 'initial_deal_group';

                if (self.props.sectionId) {
                    prefKey += `_${self.props.sectionId}`;
                }

                app.user.updatePreference(prefKey, grp.id);
            }

            if (self.entityIdToShow) {
                self.sectionComponent.showEntity(self.entityIdToShow);
                self.entityIdToShow = null;
            }
        }

        if (group.adhoc) {
            processGroup(group);
        } else {
            $.get(`/${group.id}`, function(result) {
                group = _.clone(group);

                group.filter = result.filter || null;
                group.columns = result.columns;

                const sort = result.display_options?.sort;

                if (sort) {
                    group.orderBy = {
                        columnId: _.isArray(sort.field) ? sort.field[0] : sort.field,
                        direction: sort.direction ? 'asc' : 'desc'
                    };
                }

                self.configureGroup(group);
                processGroup(group);
            });
        }
    }

    handleGroupSave(groupId) {
        this.createFinderStructure(`groups/${groupId}`);
    }

    handleToolbarViewChange(viewId) {
        if (viewId === 'funnel') {
            this.sectionComponent.collapseFinder();
        }
    }

    getUrl() {
        let path = ['deals'];

        if (this.props.sectionId) {
            path.push(`section/${this.props.sectionId}`);
        }

        let params = {};
        const entityId = this.sectionComponent.getEntityIdOnPanel();
        const activeGroup = this.finderContentManager.getActiveGroup();

        if (entityId) {
            path.push(entityId)

            if (activeGroup) {
                params.c = `deals/${groupIdToUrl(activeGroup.id)}`;
            }
        } else if (activeGroup) {
            path.push(groupIdToUrl(activeGroup.id));
        }

        params = _.isEmpty(params) ? '' : `?${$.param(params)}`;

        return path.join('/') + params;
    }

    getParams() {
        let params = {}

        return params;
    }

    render() {
        return (
            <Section
                ref={(el) => this.sectionComponent = el}
                parent={this.props.parent}
                entityType='opportunities'
                sectionId={this.props.sectionId}
                finder={{
                    contentManager: this.finderContentManager,
                    createGroups: true,
                    createFolders: true,
                    pinGroups: false,
                    onGroupSelected: this.handleGroupSelect.bind(this)
                }}
                content={{
                    sharedFilters: {
                        columns: this.allColumns
                    },
                    sharedSearch: true,
                    onGroupSaved: this.handleGroupSave.bind(this),
                    onToolbarViewChanged: this.handleToolbarViewChange.bind(this)
                }}
            />
        );
    }
}

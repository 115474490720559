import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import vent from 'js/vent'
import app from 'js/app'
import React from 'react';
import ReactDOM from 'react-dom';

import GroupModel from 'js/models/group'
import GroupsCollection from 'js/collections/groups'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import IODGroupListingView from 'app/_components/IOD-listing/IOD-group-listing/IOD-group-listing'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import GroupElementsCollection from 'js/collections/group_elements'
import FilterableCollection from 'js/utils/filterable_collection'
import FilterQuickOptionsActivities from 'js/views/filters/quick-options-activities'
import ActivityFilterModel from 'js/models/activity_filter'
import security from 'js/utils/security'
import GroupsClubbing from 'js/views/groups_clubbing'
import MessageBox from 'js/views/message_box'
import AppConfig from 'app/app-config'
import buildMyGroupsList from 'js/utils/build_my_groups_list.js'

import activitiesTemplate from 'app/activities/activities.handlebars'
import quickFilterListTemplate from 'app/activities/quick-filter-list.handlebars'
import quickFilterItemTemplate from 'app/activities/quick-filter-item.handlebars'

import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';

var GroupOverviewView = IODGroupListingView.prototype.OverviewView.extend({
    templateHelpers: function() {
        var groupProtected = (this.model && this.model.get('display_options') || {}).protected;
        return {
            id: this.parent.getElementType(),
            name: this.model.get('name'),
            disableLayoutSelection: true,
            hideCostSection: true,
            hideFilters: groupProtected,
            showProtectedOption: app.user.get('is_admin') || app.user.get('is_helper'),
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    groupType: 'Activities',
    initialize: function() {
        IODGroupListingView.prototype.OverviewView.prototype.initialize.apply(this, arguments);

        this.collection = new GroupElementsCollection(null, {elementType: 'activities'});
        this.filter = new ActivityFilterModel({id: this.options.filter_id || this.filter_id});
        this.quickFilter = new FilterQuickOptionsActivities();

        var self = this;

        this.listenTo(this, 'show:related', function(model, type) {
            self.parent.trigger('show:related', model, type);
        });
        this.listenTo(this, 'filter:update', function(filter) {
            self.parent.trigger('filter:update', filter);
        });
    },
    createTableView: function() {
        var hasPermission = security.checkPermission('edit', this.model);
        var isSmart = this.model.isSmart();

        return new TableBodyContainerView({
            parent: this,
            tableModel: this.model,
            collection: this.collection,
            elementType: 'activities',
            filter: this.filter,
            defaultSort: this.options.defaultSort,
            selectedColumns: this.model.get('columns') || this.options.selectedColumns,
            addItemSelectPlaceholder: 'Search for an activity',
            addItemSelectText: 'note',
            addItemNoCheckPermissions: true,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: false,
                mergeButton: false,
                newButton: false,
                addButton: hasPermission && !isSmart,
                removeFromGroup: hasPermission && !isSmart
            }
        });
    },
    addItem: function(model) {
        var self = this;

        $.ajax({
            url: this.model.url() + '/items/' + model.get('id'),
            method: 'PUT',
            success: function() {
                self.fetchCollection(null, null, null, null, {byItemAdded: true});
            }
        });
    },
    getMenuOptions: function() {
        var hasEditPermission = security.checkPermission('edit', this.model);

        return {
            canEdit: hasEditPermission,
            hasPermissions: hasEditPermission && app.user.get('client').permission_type !== 'rba',
            canDelete: hasEditPermission,
            locked: this.model.get('locked'),
            canDownloadAsCSV: !AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption') || app.user.get('is_admin'),
            canShareGroup: hasEditPermission
        };
    }
});


var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    templateHelpers: function() {
        return {
            id: this.parent.getElementType(),
            name: this.groupModel ? this.groupModel.get('name') : (this.options?.name || 'Activities'),
            hideInfo: true,
            disableLayoutSelection: true
        };
    },
    groupType: "Activities",
    initialize: function(options) {
        IODListingBaseView.prototype.OverviewView.prototype.initialize.apply(this, arguments);

        this.collection = new GroupElementsCollection(null, {elementType: 'activities'});
        this.filter = new ActivityFilterModel({id: this.options.filterId});
        this.quickFilter = new FilterQuickOptionsActivities();

        var self = this;

        this.listenTo(this, 'show:related', function(model, type) {
            self.parent.trigger('show:related', model, type);
        });
        this.listenTo(this, 'filter:update', function(filter) {
            self.parent.trigger('filter:update', filter);
        });
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            tableModel: this.groupModel,
            collection: this.collection,
            elementType: 'activities',
            filter: this.filter,
            defaultSort: this.options.defaultSort,
            selectedColumns: this.options.selectedColumns,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
            }
        });
    }
});


var GroupTableView = IODGroupListingView.extend({
    OverviewView: GroupOverviewView,
    getElementType: function() {
        return 'activities';
    }
});


var TableView = IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'activities';
    }
});


var FilterItem = Marionette.ItemView.extend({
    className: 'item',
    template: Handlebars.compile(quickFilterItemTemplate),
    ui: {
        tagsContainer: '.tags-container',
    },
    templateHelpers: function() {
        const owner = this.model.get('owner');
        const isSmart = this.model.get('group_type') === 'smart';
        const icon = this.model.get('icon') || (isSmart ? 'icon-smart-group' : 'icon-list');

        return {
            is_owner: (owner?.id === app.user.get('id')),
            icon: icon
        };
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.showGroup(this.model);
            });
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.parent.clubbing.initItem(this);
        this.$el.find('.basic-list-item').tooltip();

        ReactDOM.render(
            <TagListSidebar
                tags={this.model.get('tags') || []}
            />,
            this.ui.tagsContainer.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
    }
});


var QuickFilterList = Marionette.CompositeView.extend({
    className: 'activities-sidebar list-wrapper has-floating-header at-top',
    template: Handlebars.compile(quickFilterListTemplate),
    getItemView: function(model) {
        if (model && model.isFolder()) {
            return this.clubbing.SidebarFolderView;
        }

        return FilterItem;
    },
    itemViewOptions: function(model) {
        var options = {
            model: model,
            parent: this
        };

        if (model && model.isFolder()) {
            options.clubbing = this.clubbing;
        }

        return options;
    },
    ui: {
        groupsList: '.loaded-activity-list',
        all: '.all-activities',
        newGroup: '.add-new-activity-group',
        regularHeader: '.list-header-nav',
        searchHeader: '.list-header-nav-search',
        searchInput: '.search-input'
    },
    events: {
        'click .add-new-activity-group': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.options.parent.showNewGroup();
            });
        },
        'click .all-activities': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.options.parent.showAllActivities();
            });
        },
        'click .search': function() {
            this.ui.regularHeader.addClass('hidden');
            this.ui.searchHeader.removeClass('hidden');
            this.ui.searchInput.val('');
            this.ui.searchInput.focus();
        },
        'click .cancel-search': function() {
            this.ui.regularHeader.removeClass('hidden');
            this.ui.searchHeader.addClass('hidden');
            this.filterableCollection.resetFilter();
        },
        'keydown .search-input': function(ev) {
            const self = this;

            _.defer(function() {
                self.filterableCollection.filter(ev.target.value);
            });
        }
    },
    initialize: function() {
        this.collection = new GroupsCollection();
        this.clubbing = this.createClubbing();
        this.filterableCollection = new FilterableCollection(this);
        this.fetchGroups();

        this.listenTo(vent, 'group:save', this.fetchGroups);

        // this is required for highlighting loaded groups
        this.listenTo(this.collection, 'sync', function() {
            if (this.selected) {
                this.highlightItem.apply(this, this.selected);
            }
        });
    },
    onRender: function() {
        this.listenTo(this.options.parent, 'select', this.highlightItem);
        this.listenTo(this.options.parent, 'group:delete', this.fetchGroups);

        this.$el.find('[data-toggle="tooltip"]').tooltip();
        this.$el.find('.all-activities a').tooltip();
    },
    onDomRefresh: function() {
        // show scrollbar initially
        this.$el.find('.content-container').nanoScroller();
    },
    appendHtml: function(collectionView, itemView){
        collectionView.ui.groupsList.append(itemView.el);
    },
    createClubbing: function() {
        var self = this;
        var clubbing = null;

        var onClubChange = function(render, save) {
            if (render) {
                // save and restore the scroll position after render
                var scrollPosition = self.$el.find('.content').scrollTop();

                self.collection.reset(clubbing.options.rootCollection.models);
                self.filterableCollection.setInitialCollection(self.collection);

                _.defer(function() {
                    self.$el.find('.content').scrollTop(scrollPosition);
                    self.$el.find('.content-container').nanoScroller();
                });
            }

            if (save) {
                app.user.updatePreference('activities_groups', clubbing.encodeCollection());
            }
        };

        clubbing = new GroupsClubbing({
            view: this,
            itemView: FilterItem,
            elementType: 'activities',
            onItemMoved: function() {
                onClubChange(true, true);
            },
            onFolderCreated: function() {
                onClubChange(true, false);
            },
            onItemInsertedToFolder: function() {
                onClubChange(true, true);
            },
            onFolderNameChanged: function() {
                onClubChange(false, true);
            },
            onFolderStateChanged: function() {
                self.$el.find('.content-container').nanoScroller();
                onClubChange(false, true)
            }
        });

        return clubbing;
    },
    fetchGroups: function(groupModelToSelect) {
        var self = this;

        this.collection.fetch({
            rows: -1,
            data: {
                element_type: 'activities'
            },
            success: function () {
                // add ad hoc groups
                if (AppConfig.getValue('activities.groups.show_funnels_tags_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    const tags = app.globalData.tags;
                    let validTags = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                        if (tag) {
                            validTags.push(tag);
                        }
                    }

                    for (const vt of validTags) {
                        self.addAdHocGroup(vt.id, vt.name, 'icon-list-dev', {isFunnelTagGroup: true});
                    }
                }

                if (AppConfig.getValue('activities.groups.show_funnels_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    let validFunnels = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        if (funnel) {
                            validFunnels.push(funnel);
                        }
                    }

                    for (const vf of validFunnels) {
                        self.addAdHocGroup(vf.id, vf.name, 'icon-list-dev', {isFunnelGroup: true});
                    }
                }

                let activityGroups = app.user.get('preferences').activities_groups;

                if (AppConfig.getValue('activities.groups.show_my_groups')) {
                    activityGroups ??= self.collection.map(item => item.get('id'));
                    activityGroups = buildMyGroupsList(self.collection, activityGroups);
                }

                // ...
                self.collection.reset(self.clubbing.manageCollection(self.collection, activityGroups).models);
                // update scrollbar after group modification
                self.$el.find('.content-container').nanoScroller();

                // ...
                if (groupModelToSelect) {
                    var gid = groupModelToSelect.get('id');
                    self.highlightItem('groups', gid);
                    self.options.parent.rememberGroup('groups', gid);
                }

                self.filterableCollection.setInitialCollection(self.collection);

                self.trigger('groups:loaded', self.collection);
            }
        });
    },
    addAdHocGroup: function(id, name, icon, extraOptions) {
        this.collection.add(new GroupModel(_.extend({
            id: id,
            name: name,
            icon: icon,
            element_type: 'activities',
            is_adhoc: true
        }, extraOptions)), {at: 0, silent: true});
    },
    showGroup: function(model) {
        this.options.parent.showGroup(model);
    },
    highlightItem: function(type, id) {
        this.selected = arguments;

        var activeEl;
        if (type === 'groups') {
            activeEl = this.$el.find(".group-list div[id='" + id + "']");
        }
        else {
            activeEl = this.ui[type];
        }

        activeEl.addClass('active');
        this.$el.find('.item.active').not(activeEl).removeClass('active');

        this.filterableCollection.findActiveModel();
    }
});


var ActivitiesView = Marionette.Layout.extend({
    tagName: 'article',
    id: 'activities-section',
    template: Handlebars.compile(activitiesTemplate),
    regions: {
        sidebarRegion: '#activities-sidebar',
        mainRegion: '#activities-view-pane'
    },
    events: {
        'click #activities-sidebar .collapse': function(ev) {
            var self = this;

            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            this.sidebarRegion.$el.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');
            });
        }
    },
    initialize: function(options) {
        this.filterId = options.filter_id;
    },
    onRender: function() {
        var groupType = this.options.type;
        var groupId = this.options.group_id;

        if (!groupType) {
            var initialGroup = app.user.get('preferences').initial_activity_group;

            if (initialGroup) {
                groupType = initialGroup.type;
                groupId = initialGroup.id;
            }
        }

        const qflView = new QuickFilterList({
            parent: this
        });

        this.sidebarRegion.show(qflView);

        if (groupType?.indexOf('ft:') === 0) {
            this.listenTo(qflView, 'groups:loaded', function(collection) {
                const group = collection.models.find(g => g.get('id') === groupType.substring(3));

                if (group) {
                    this.showFunnelTagGroup(group);
                } else {
                    this.showAllActivities();
                }

                this.$el.find('[data-toggle="tooltip"]').tooltip();
            });
        } else {
            this.showInitialGroup(groupType || 'all', groupId);
            this.$el.find('[data-toggle="tooltip"]').tooltip();
        }
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['activities-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.sidebarRegion.$el?.width(sidebarWidth);
                self.mainRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    showInitialGroup: function(type, id) {
        switch (type) {
            case 'all':
                this.showAllActivities();
                break;

            case 'new':
                this.showNewGroup();
                break;

            case 'groups':
                if (id) {
                    this.showGroup(new GroupModel({id: id}));
                } else {
                    this.showAllActivities();
                }
                break;

            default:
                this.showAllActivities();
                break;
        }
    },
    showNewGroup: function() {
        this.selectedBeforeNewGroup = this.sidebarRegion.currentView.selected;

        var model = new GroupModel();
        this.showContent(new GroupTableView(_.defaults({
            model: model,
            parent: this
        }, { editing: true, preset_element_type: 'activities' })));

        this.trigger('select', 'newGroup');
    },
    showAllActivities: function() {
        this.showContent(new TableView({
            filterId: this.filterId
        }));

        this.trigger('select', 'all');
        this.rememberGroup('all');
    },
    showFunnelTagGroup(model) {
        const rules = [[{
            field: 'activity_tags',
            operator: 'equal',
            values: {
                id: model.get('id'),
                name: model.get('name')
            }
        }]];

        const filter = new ActivityFilterModel();
        const self = this;

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function (data) {
                self.showContent(new TableView({
                    name: model.get('name'),
                    filterId: data.id
                }));

                self.trigger('select', 'groups', model.get('id'));
                self.rememberGroup(`ft:${model.get('id')}`);
            }
        });
    },
    showFunnelGroup(model) {
        const rules = [[{
            field: 'activity_funnels',
            operator: 'equal',
            values: {
                id: model.get('id'),
                name: model.get('name')
            }
        }]];

        const filter = new ActivityFilterModel();
        const self = this;

        filter.save({
            rules: rules
        }, {
            alert: false,
            success: function (data) {
                self.showContent(new TableView({
                    name: model.get('name'),
                    filterId: data.id
                }));

                self.trigger('select', 'groups', model.get('id'));
                self.rememberGroup(`ft:${model.get('id')}`);
            }
        });
    },
    showGroup: function(model) {
        if (model.get('isFunnelTagGroup')) {
            this.showFunnelTagGroup(model);
            return;
        }
        if (model.get('isFunnelGroup')) {
            this.showFunnelGroup(model);
            return;
        }
        this.showContent(new GroupTableView({
            filter_id: this.filterId,
            model: model
        }));

        this.trigger('select', 'groups', model.get('id'));
        this.rememberGroup('groups', model.get('id'));
    },
    showContent: function(view) {
        var self = this;

        this.listenTo(view, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            self.trigger('push-view:' + type + ':show', model);
        });

        this.listenTo(view, 'filter:update', function(filter) {
            self.filterId = filter ? filter.get('id') : null;
            vent.trigger('AppContent:contentChange');
            _.defer(function() {
                self.filterId = null;
            });
        });

        this.listenTo(view, 'group:delete', function() {
            this.showAllActivities(this.options);

            _.defer(function() {
                self.trigger('group:delete');
            });
        });

        this.listenTo(view, 'replace-view:group:show', function(model) {
            this.showGroup(model);
        });

        this.listenTo(view, 'edit:cancel', function(model) {
            if (model.isNew()) {
                if (this.selectedBeforeNewGroup && this.selectedBeforeNewGroup[1]) {
                    this.showGroup(new GroupModel({
                        type: this.selectedBeforeNewGroup[0],
                        id: this.selectedBeforeNewGroup[1]
                    }));
                }
                else {
                    this.showAllActivities();
                }
            }
        });

        this.listenTo(view, 'fetch:fail', function(response) {
            self.showAllActivities();

            if (response && response.status === 403) { // do not have permissions
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'You do not have permissions to view this group'
                }, self);
            }
        });

        _.defer(function() {
            if (this.isClosed) {
                return;
            }
            this.mainRegion.show(view);
            vent.trigger('AppContent:contentChange');
            this.initSidebarAndContentWidth();
            this.filterId = null;
        }.bind(this));
    },
    rememberGroup: function(type, id) {
        app.user.updatePreference('initial_activity_group', {
            type: type,
            id: id
        });
    },
    getUrl: function() {
        var url = ['activities'];
        var view = this.mainRegion && this.mainRegion.currentView;

        if (view && !view.isClosed && view.getUrl) {
            var model = view.options.model;

            if (model) {
                if (view.options.editing && view.options.model.isNew()) {
                    url.push('groups/new');
                }
                else {
                    url.push('groups/' + model.get('id'));
                }
            }
            else {
                url.push('all');
            }
        } else {
            url.push('all');
        }

        return url.join('/');
    },
    getParams: function() {
        if (this.filterId) {
            return {
                filter_id: this.filterId
            };
        }

        return {};
    }
});

export default ActivitiesView;

import React from 'react';
import classnames from 'classnames';

import styles from './widgets.css';

class Button extends React.Component {
    render() {
        const {
            size,
            color,
            deemphasized,
            href,
            target,
            title,
            onClick,
            children
        } = this.props;

        const classes = classnames({
            [styles.Button]: true,
            [styles[`${color}Color`]]: color,
            [styles.deemphasized]: deemphasized,
            [styles[`${size}Size`]]: size
        });

        return (
            <a
                className={classes}
                href={href}
                target={target}
                title={title}
                onClick={onClick}>

                {size === 'fill'
                    ?
                        <span className={styles.inner}>{children}</span>
                    :
                        children
                }
            </a>
        );
    }
}

export {
    Button
};

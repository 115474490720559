import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import ModalRegion from 'js/views/base/modal-region'

import app from 'js/app';
import AppointmentsReactView from './appointments/appointments';
import DealsReactView from './deals/deals';
import MessagesReactView from './messages/messages';
import ReportsReactView from './reports/reports';
import CampaignsReactView from './campaigns/campaigns';


const COMPONENT_VIEW_MAPPING = {
    appointments: AppointmentsReactView,
    deals: DealsReactView,
    messages: MessagesReactView,
    reports: ReportsReactView,
    campaigns: CampaignsReactView
};

const SectionView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    regions: {
        bulkEditRegion: {
            selector: '.bulk-edit-region',
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        this.renderReact();
    },
    renderReact() {
        const ComponentView = COMPONENT_VIEW_MAPPING[this.options.type];
        const sectionId = this.options.sectionId;
        let section = null;

        if (sectionId) {
            const clientPreferences = app.user.get('client').preferences;

            if (clientPreferences && clientPreferences.deals_sections) {
                const dealsSections = JSON.parse(clientPreferences.deals_sections);
                section = dealsSections.find(s => s.id === sectionId);
            }
        }

        ReactDOM.render(
            <ComponentView
                ref={(el) => this.component = el}
                parent={this}
                section={section}
                {...this.options}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    },
    getUrl: function() {
        return this.component.getUrl();
    },
    getParams: function() {
        return this.component.getParams();
    }
});

export default SectionView;


// EEC means the country is in the European Economic Community

export default [
    {
        "id": "004",
        "name": "Afghanistan",
        "code": "AF"
    },
    {
        "id": "248",
        "name": "Åland",
        "code": "AX"
    },
    {
        "id": "008",
        "name": "Albania",
        "code": "AL"
    },
    {
        "id": "012",
        "name": "Algeria",
        "code": "DZ"
    },
    {
        "id": "016",
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "id": "020",
        "name": "Andorra",
        "code": "AD"
    },
    {
        "id": "024",
        "name": "Angola",
        "code": "AO"
    },
    {
        "id": "660",
        "name": "Anguilla",
        "code": "AI"
    },
    {
        "id": "010",
        "name": "Antarctica",
        "code": "AQ"
    },
    {
        "id": "028",
        "name": "Antigua and Barbuda",
        "code": "AG"
    },
    {
        "id": "032",
        "name": "Argentina",
        "code": "AR"
    },
    {
        "id": "051",
        "name": "Armenia",
        "code": "AM"
    },
    {
        "id": "533",
        "name": "Aruba",
        "code": "AW"
    },
    {
        "id": "036",
        "name": "Australia",
        "code": "AU"
    },
    {
        "id": "040",
        "name": "Austria",
        "EEC": true,
        "code": "AT"
    },
    {
        "id": "031",
        "name": "Azerbaijan",
        "code": "AZ"
    },
    {
        "id": "044",
        "name": "Bahamas",
        "code": "BS"
    },
    {
        "id": "048",
        "name": "Bahrain",
        "code": "BH"
    },
    {
        "id": "050",
        "name": "Bangladesh",
        "code": "BD"
    },
    {
        "id": "052",
        "name": "Barbados",
        "code": "BB"
    },
    {
        "id": "112",
        "name": "Belarus",
        "code": "BY"
    },
    {
        "id": "056",
        "name": "Belgium",
        "EEC": true,
        "code": "BE"
    },
    {
        "id": "084",
        "name": "Belize",
        "code": "BZ"
    },
    {
        "id": "204",
        "name": "Benin",
        "code": "BJ"
    },
    {
        "id": "060",
        "name": "Bermuda",
        "code": "BM"
    },
    {
        "id": "064",
        "name": "Bhutan",
        "code": "BT"
    },
    {
        "id": "068",
        "name": "Bolivia",
        "code": "BO"
    },
    {
        "id": "535",
        "name": "Bonaire, Sint Eustatius and Saba",
        "code": ""
    },
    {
        "id": "070",
        "name": "Bosnia and Herzegovina",
        "code": "BA"
    },
    {
        "id": "072",
        "name": "Botswana",
        "code": "BW"
    },
    {
        "id": "074",
        "name": "Bouvet Island",
        "code": "BV"
    },
    {
        "id": "076",
        "name": "Brazil",
        "code": "BR"
    },
    {
        "id": "086",
        "name": "British Indian Ocean Territory",
        "code": "IO"
    },
    {
        "id": "096",
        "name": "Brunei Darussalam",
        "code": "BN"
    },
    {
        "id": "100",
        "name": "Bulgaria",
        "EEC": true,
        "code": "BG"
    },
    {
        "id": "854",
        "name": "Burkina Faso",
        "code": "BF"
    },
    {
        "id": "108",
        "name": "Burundi",
        "code": "BI"
    },
    {
        "id": "116",
        "name": "Cambodia",
        "code": "KH"
    },
    {
        "id": "120",
        "name": "Cameroon",
        "code": "CM"
    },
    {
        "id": "124",
        "name": "Canada",
        "code": "CA"
    },
    {
        "id": "132",
        "name": "Cape Verde",
        "code": "CV"
    },
    {
        "id": "136",
        "name": "Cayman Islands",
        "code": "KY"
    },
    {
        "id": "140",
        "name": "Central African Republic",
        "code": "CF"
    },
    {
        "id": "148",
        "name": "Chad",
        "code": "TD"
    },
    {
        "id": "152",
        "name": "Chile",
        "code": "CL"
    },
    {
        "id": "156",
        "name": "China",
        "code": "CN"
    },
    {
        "id": "162",
        "name": "Christmas Island",
        "code": "CX"
    },
    {
        "id": "166",
        "name": "Cocos (Keeling) Islands",
        "code": "CC"
    },
    {
        "id": "170",
        "name": "Colombia",
        "code": "CO"
    },
    {
        "id": "174",
        "name": "Comoros",
        "code": "KM"
    },
    {
        "id": "178",
        "name": "Congo (Brazzaville)",
        "code": "CG"
    },
    {
        "id": "180",
        "name": "Congo (Kinshasa)",
        "code": "CD"
    },
    {
        "id": "184",
        "name": "Cook Islands",
        "code": "CK"
    },
    {
        "id": "188",
        "name": "Costa Rica",
        "code": "CR"
    },
    {
        "id": "384",
        "name": "Côte d'Ivoire",
        "code": "CI"
    },
    {
        "id": "191",
        "name": "Croatia",
        "code": "HR"
    },
    {
        "id": "192",
        "name": "Cuba",
        "code": "CU"
    },
    {
        "id": "531",
        "name": "Curaçao",
        "code": ""
    },
    {
        "id": "196",
        "name": "Cyprus",
        "EEC": true,
        "code": "CY"
    },
    {
        "id": "203",
        "name": "Czech Republic",
        "EEC": true,
        "code": "CZ"
    },
    {
        "id": "208",
        "name": "Denmark",
        "EEC": true,
        "code": "DK"
    },
    {
        "id": "262",
        "name": "Djibouti",
        "code": "DJ"
    },
    {
        "id": "212",
        "name": "Dominica",
        "code": "DM"
    },
    {
        "id": "214",
        "name": "Dominican Republic",
        "code": "DO"
    },
    {
        "id": "218",
        "name": "Ecuador",
        "code": "EC"
    },
    {
        "id": "818",
        "name": "Egypt",
        "code": "EG"
    },
    {
        "id": "222",
        "name": "El Salvador",
        "code": "SV"
    },
    {
        "id": "226",
        "name": "Equatorial Guinea",
        "code": "GQ"
    },
    {
        "id": "232",
        "name": "Eritrea",
        "code": "ER"
    },
    {
        "id": "233",
        "name": "Estonia",
        "EEC": true,
        "code": "EE"
    },
    {
        "id": "231",
        "name": "Ethiopia",
        "code": "ET"
    },
    {
        "id": "238",
        "name": "Falkland Islands",
        "code": "FK"
    },
    {
        "id": "234",
        "name": "Faroe Islands",
        "code": "FO"
    },
    {
        "id": "242",
        "name": "Fiji",
        "code": "FJ"
    },
    {
        "id": "246",
        "name": "Finland",
        "EEC": true,
        "code": "FI"
    },
    {
        "id": "250",
        "name": "France",
        "EEC": true,
        "code": "FR"
    },
    {
        "id": "254",
        "name": "French Guiana",
        "code": "GF"
    },
    {
        "id": "258",
        "name": "French Polynesia",
        "code": "PF"
    },
    {
        "id": "260",
        "name": "French Southern Lands",
        "code": "TF"
    },
    {
        "id": "266",
        "name": "Gabon",
        "code": "GA"
    },
    {
        "id": "270",
        "name": "Gambia",
        "code": "GM"
    },
    {
        "id": "268",
        "name": "Georgia",
        "code": "GE"
    },
    {
        "id": "276",
        "name": "Germany",
        "EEC": true,
        "code": "DE"
    },
    {
        "id": "288",
        "name": "Ghana",
        "code": "GH"
    },
    {
        "id": "292",
        "name": "Gibraltar",
        "code": "GI"
    },
    {
        "id": "300",
        "name": "Greece",
        "EEC": true,
        "code": "GR"
    },
    {
        "id": "304",
        "name": "Greenland",
        "code": "GL"
    },
    {
        "id": "308",
        "name": "Grenada",
        "code": "GD"
    },
    {
        "id": "312",
        "name": "Guadeloupe",
        "code": "GP"
    },
    {
        "id": "316",
        "name": "Guam",
        "code": "GU"
    },
    {
        "id": "320",
        "name": "Guatemala",
        "code": "GT"
    },
    {
        "id": "831",
        "name": "Guernsey",
        "code": "GG"
    },
    {
        "id": "324",
        "name": "Guinea",
        "code": "GN"
    },
    {
        "id": "624",
        "name": "Guinea-Bissau",
        "code": "GW"
    },
    {
        "id": "328",
        "name": "Guyana",
        "code": "GY"
    },
    {
        "id": "332",
        "name": "Haiti",
        "code": "HT"
    },
    {
        "id": "334",
        "name": "Heard and McDonald Islands",
        "code": "HM"
    },
    {
        "id": "340",
        "name": "Honduras",
        "code": "HN"
    },
    {
        "id": "344",
        "name": "Hong Kong",
        "code": "HK"
    },
    {
        "id": "348",
        "name": "Hungary",
        "EEC": true,
        "code": "HU"
    },
    {
        "id": "352",
        "name": "Iceland",
        "code": "ID"
    },
    {
        "id": "356",
        "name": "India",
        "code": "IN"
    },
    {
        "id": "360",
        "name": "Indonesia",
        "code": "ID"
    },
    {
        "id": "364",
        "name": "Iran",
        "code": "IR"
    },
    {
        "id": "368",
        "name": "Iraq",
        "code": "IQ"
    },
    {
        "id": "372",
        "name": "Ireland",
        "EEC": true,
        "code": "IE"
    },
    {
        "id": "833",
        "name": "Isle of Man",
        "code": "IM"
    },
    {
        "id": "376",
        "name": "Israel",
        "code": "IL"
    },
    {
        "id": "380",
        "name": "Italy",
        "EEC": true,
        "code": "IT"
    },
    {
        "id": "388",
        "name": "Jamaica",
        "code": "JM"
    },
    {
        "id": "392",
        "name": "Japan",
        "code": "JP"
    },
    {
        "id": "832",
        "name": "Jersey",
        "code": "JE"
    },
    {
        "id": "400",
        "name": "Jordan",
        "code": "JO"
    },
    {
        "id": "398",
        "name": "Kazakhstan",
        "code": "KZ"
    },
    {
        "id": "404",
        "name": "Kenya",
        "code": "KE"
    },
    {
        "id": "296",
        "name": "Kiribati",
        "code": "KI"
    },
    {
        "id": "408",
        "name": "Korea, North",
        "code": ""
    },
    {
        "id": "410",
        "name": "Korea, South",
        "code": "KR"
    },
    {
        "id": "414",
        "name": "Kuwait",
        "code": "KW"
    },
    {
        "id": "417",
        "name": "Kyrgyzstan",
        "code": "KG"
    },
    {
        "id": "418",
        "name": "Laos",
        "code": "LA"
    },
    {
        "id": "428",
        "name": "Latvia",
        "EEC": true,
        "code": "LV"
    },
    {
        "id": "422",
        "name": "Lebanon",
        "code": "LB"
    },
    {
        "id": "426",
        "name": "Lesotho",
        "code": "LS"
    },
    {
        "id": "430",
        "name": "Liberia",
        "code": "LR"
    },
    {
        "id": "434",
        "name": "Libya",
        "code": "LY"
    },
    {
        "id": "438",
        "name": "Liechtenstein",
        "code": "LI"
    },
    {
        "id": "440",
        "name": "Lithuania",
        "EEC": true,
        "code": "LT"
    },
    {
        "id": "442",
        "name": "Luxembourg",
        "EEC": true,
        "code": "LU"
    },
    {
        "id": "446",
        "name": "Macau",
        "code": "MO"
    },
    {
        "id": "807",
        "name": "Macedonia",
        "code": "MK"
    },
    {
        "id": "450",
        "name": "Madagascar",
        "code": "MG"
    },
    {
        "id": "454",
        "name": "Malawi",
        "code": "MW"
    },
    {
        "id": "458",
        "name": "Malaysia",
        "code": "MY"
    },
    {
        "id": "462",
        "name": "Maldives",
        "code": "MV"
    },
    {
        "id": "466",
        "name": "Mali",
        "code": "ML"
    },
    {
        "id": "470",
        "name": "Malta",
        "EEC": true,
        "code": "MT"
    },
    {
        "id": "584",
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "id": "474",
        "name": "Martinique",
        "code": "MQ"
    },
    {
        "id": "478",
        "name": "Mauritania",
        "code": "MR"
    },
    {
        "id": "480",
        "name": "Mauritius",
        "code": "MU"
    },
    {
        "id": "175",
        "name": "Mayotte",
        "code": "YT"
    },
    {
        "id": "484",
        "name": "Mexico",
        "code": "MX"
    },
    {
        "id": "583",
        "name": "Micronesia",
        "code": "FM"
    },
    {
        "id": "498",
        "name": "Moldova",
        "code": "MD"
    },
    {
        "id": "492",
        "name": "Monaco",
        "code": "MC"
    },
    {
        "id": "496",
        "name": "Mongolia",
        "code": "MN"
    },
    {
        "id": "499",
        "name": "Montenegro",
        "code": "ME"
    },
    {
        "id": "500",
        "name": "Montserrat",
        "code": "MS"
    },
    {
        "id": "504",
        "name": "Morocco",
        "code": "MA"
    },
    {
        "id": "508",
        "name": "Mozambique",
        "code": "MZ"
    },
    {
        "id": "104",
        "name": "Myanmar",
        "code": "MM"
    },
    {
        "id": "516",
        "name": "Namibia",
        "code": "NA"
    },
    {
        "id": "520",
        "name": "Nauru",
        "code": "NR"
    },
    {
        "id": "524",
        "name": "Nepal",
        "code": "NP"
    },
    {
        "id": "528",
        "name": "Netherlands",
        "EEC": true,
        "code": "NL"
    },
    {
        "id": "530",
        "name": "Netherlands Antilles",
        "code": "AN"
    },
    {
        "id": "540",
        "name": "New Caledonia",
        "code": "NC"
    },
    {
        "id": "554",
        "name": "New Zealand",
        "code": "NZ"
    },
    {
        "id": "558",
        "name": "Nicaragua",
        "code": "NI"
    },
    {
        "id": "562",
        "name": "Niger",
        "code": "NE"
    },
    {
        "id": "566",
        "name": "Nigeria",
        "code": "NG"
    },
    {
        "id": "570",
        "name": "Niue",
        "code": "NU"
    },
    {
        "id": "574",
        "name": "Norfolk Island",
        "code": "NF"
    },
    {
        "id": "580",
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "id": "578",
        "name": "Norway",
        "code": "NO"
    },
    {
        "id": "512",
        "name": "Oman",
        "code": "OM"
    },
    {
        "id": "586",
        "name": "Pakistan",
        "code": "PK"
    },
    {
        "id": "585",
        "name": "Palau",
        "code": "PW"
    },
    {
        "id": "275",
        "name": "Palestine",
        "code": "PS"
    },
    {
        "id": "591",
        "name": "Panama",
        "code": "PA"
    },
    {
        "id": "598",
        "name": "Papua New Guinea",
        "code": "PG"
    },
    {
        "id": "600",
        "name": "Paraguay",
        "code": "PY"
    },
    {
        "id": "604",
        "name": "Peru",
        "code": "PE"
    },
    {
        "id": "608",
        "name": "Philippines",
        "code": "PH"
    },
    {
        "id": "612",
        "name": "Pitcairn",
        "code": "PN"
    },
    {
        "id": "616",
        "name": "Poland",
        "EEC": true,
        "code": "PL"
    },
    {
        "id": "620",
        "name": "Portugal",
        "EEC": true,
        "code": "PT"
    },
    {
        "id": "630",
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "id": "634",
        "name": "Qatar",
        "code": "QA"
    },
    {
        "id": "638",
        "name": "Reunion",
        "code": "RE"
    },
    {
        "id": "642",
        "name": "Romania",
        "EEC": true,
        "code": "RO"
    },
    {
        "id": "643",
        "name": "Russian Federation",
        "code": "RU"
    },
    {
        "id": "646",
        "name": "Rwanda",
        "code": "RW"
    },
    {
        "id": "652",
        "name": "Saint Barthélemy",
        "code": "BL"
    },
    {
        "id": "654",
        "name": "Saint Helena",
        "code": "SH"
    },
    {
        "id": "659",
        "name": "Saint Kitts and Nevis",
        "code": "KN"
    },
    {
        "id": "662",
        "name": "Saint Lucia",
        "code": "LC"
    },
    {
        "id": "663",
        "name": "Saint Martin (French part)",
        "code": "MF"
    },
    {
        "id": "666",
        "name": "Saint Pierre and Miquelon",
        "code": "PM"
    },
    {
        "id": "670",
        "name": "Saint Vincent and the Grenadines",
        "code": "VC"
    },
    {
        "id": "882",
        "name": "Samoa",
        "code": "WS"
    },
    {
        "id": "674",
        "name": "San Marino",
        "code": "SM"
    },
    {
        "id": "678",
        "name": "Sao Tome and Principe",
        "code": "ST"
    },
    {
        "id": "682",
        "name": "Saudi Arabia",
        "code": "SA"
    },
    {
        "id": "686",
        "name": "Senegal",
        "code": "SN"
    },
    {
        "id": "688",
        "name": "Serbia",
        "code": "RS"
    },
    {
        "id": "690",
        "name": "Seychelles",
        "code": "SC"
    },
    {
        "id": "694",
        "name": "Sierra Leone",
        "code": "SL"
    },
    {
        "id": "702",
        "name": "Singapore",
        "code": "SG"
    },
    {
        "id": "534",
        "name": "Sint Maarten (Dutch part)",
        "code": ""
    },
    {
        "id": "703",
        "name": "Slovakia",
        "EEC": true,
        "code": "SK"
    },
    {
        "id": "705",
        "name": "Slovenia",
        "EEC": true,
        "code": "SI"
    },
    {
        "id": "090",
        "name": "Solomon Islands",
        "code": "SB"
    },
    {
        "id": "706",
        "name": "Somalia",
        "code": "SO"
    },
    {
        "id": "710",
        "name": "South Africa",
        "code": "ZA"
    },
    {
        "id": "239",
        "name": "South Georgia and South Sandwich Islands",
        "code": "GS"
    },
    {
        "id": "728",
        "name": "South Sudan",
        "code": ""
    },
    {
        "id": "724",
        "name": "Spain",
        "EEC": true,
        "code": "ES"
    },
    {
        "id": "144",
        "name": "Sri Lanka",
        "code": "LK"
    },
    {
        "id": "736",
        "name": "Sudan",
        "code": "SD"
    },
    {
        "id": "740",
        "name": "Suriname",
        "code": "SR"
    },
    {
        "id": "744",
        "name": "Svalbard and Jan Mayen Islands",
        "code": "SJ"
    },
    {
        "id": "748",
        "name": "Swaziland",
        "code": "SZ"
    },
    {
        "id": "752",
        "name": "Sweden",
        "EEC": true,
        "code": "SE"
    },
    {
        "id": "756",
        "name": "Switzerland",
        "code": "CH"
    },
    {
        "id": "760",
        "name": "Syria",
        "code": "SY"
    },
    {
        "id": "158",
        "name": "Taiwan",
        "code": "TW"
    },
    {
        "id": "762",
        "name": "Tajikistan",
        "code": "TJ"
    },
    {
        "id": "834",
        "name": "Tanzania",
        "code": "TZ"
    },
    {
        "id": "764",
        "name": "Thailand",
        "code": "TH"
    },
    {
        "id": "626",
        "name": "Timor-Leste",
        "code": "TL"
    },
    {
        "id": "768",
        "name": "Togo",
        "code": "TG"
    },
    {
        "id": "772",
        "name": "Tokelau",
        "code": "TK"
    },
    {
        "id": "776",
        "name": "Tonga",
        "code": "TO"
    },
    {
        "id": "780",
        "name": "Trinidad and Tobago",
        "code": "TT"
    },
    {
        "id": "788",
        "name": "Tunisia",
        "code": "TN"
    },
    {
        "id": "792",
        "name": "Turkey",
        "code": "TR"
    },
    {
        "id": "795",
        "name": "Turkmenistan",
        "code": "TM"
    },
    {
        "id": "796",
        "name": "Turks and Caicos Islands",
        "code": "TC"
    },
    {
        "id": "798",
        "name": "Tuvalu",
        "code": "TV"
    },
    {
        "id": "800",
        "name": "Uganda",
        "code": "UG"
    },
    {
        "id": "804",
        "name": "Ukraine",
        "code": "UA"
    },
    {
        "id": "784",
        "name": "United Arab Emirates",
        "code": "AE"
    },
    {
        "id": "826",
        "name": "United Kingdom",
        "EEC": true,
        "code": "GB"
    },
    {
        "id": "581",
        "name": "United States Minor Outlying Islands",
        "code": "UM"
    },
    {
        "id": "840",
        "name": "United States of America",
        "code": "US"
    },
    {
        "id": "858",
        "name": "Uruguay",
        "code": "UY"
    },
    {
        "id": "860",
        "name": "Uzbekistan",
        "code": "UZ"
    },
    {
        "id": "548",
        "name": "Vanuatu",
        "code": "VU"
    },
    {
        "id": "336",
        "name": "Vatican City",
        "code": "VA"
    },
    {
        "id": "862",
        "name": "Venezuela",
        "code": "VE"
    },
    {
        "id": "704",
        "name": "Vietnam",
        "code": "VN"
    },
    {
        "id": "092",
        "name": "Virgin Islands, British",
        "code": "VG"
    },
    {
        "id": "850",
        "name": "Virgin Islands, U.S.",
        "code": "VI"
    },
    {
        "id": "876",
        "name": "Wallis and Futuna Islands",
        "code": "WF"
    },
    {
        "id": "732",
        "name": "Western Sahara",
        "code": "EH"
    },
    {
        "id": "887",
        "name": "Yemen",
        "code": "YE"
    },
    {
        "id": "894",
        "name": "Zambia",
        "code": "ZM"
    },
    {
        "id": "716",
        "name": "Zimbabwe",
        "code": "ZW"
    }
];

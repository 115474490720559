import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react'
import ReactDOM from 'react-dom'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import html2pdf from 'html2pdf.js'
import canvg from 'canvg'
import KPIWidget from 'js/react_views/widgets/kpi.js'
import dateFormat from 'js/utils/date-format'

import style from './campaign-summary.css'


class LinksClicked extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            values: {
                peopleClicked: 0,
                clickRate: 0,
                totalClicks: 0,
                didntClick: 0,
                didntClickRate: 0
            },
            links: []
        };

        this.hasValues = false;
    }

    setValues(values, links) {
        this.hasValues = true;
        this.setState({
            values: values,
            links: links
        });
    }

    render() {
        const peopleClicked = this.hasValues ? this.state.values.peopleClicked : '-';
        const clickRate = this.hasValues ? `${+parseFloat(this.state.values.clickRate * 100).toFixed(2)}%` : '-';
        const totalClicks = this.hasValues ? this.state.values.totalClicks : '-';
        const clicksPerPerson = this.hasValues ? +parseFloat(this.state.values.totalClicks / Math.max(this.state.values.peopleClicked, 1)).toFixed(2) : '-';
        const didntClick = this.hasValues ? this.state.values.didntClick : '-';
        const didntClickRate = this.hasValues ? `${+parseFloat(this.state.values.didntClickRate * 100).toFixed(2)}%` : '-';

        return (
            <div className={style.linksClicked}>
                <div className={style.infoRow}>
                    <div className={style.rowStats}>
                        <div className={style.statsValue}>{peopleClicked}</div>
                        <div className={style.statsInfo}>
                            <div className={style.statsName}>People clicked</div>
                            <div className={style.statsDesc}>{`Giving you a ${clickRate} click rate`}</div>
                        </div>
                    </div>
                    <div className={style.rowStats}>
                        <div className={style.statsValue}>{totalClicks}</div>
                        <div className={style.statsInfo}>
                            <div className={style.statsName}>Total clicks</div>
                            <div className={style.statsDesc}>{`Made by a total of ${peopleClicked} people`}</div>
                        </div>
                    </div>
                </div>
                <div className={style.infoRow}>
                    <div className={style.rowStats}>
                        <div className={style.statsValue}>{clicksPerPerson}</div>
                        <div className={style.statsInfo}>
                            <div className={style.statsName}>Clicks per person</div>
                            <div className={style.statsDesc}>Average of all those who clicked</div>
                        </div>
                    </div>
                    <div className={style.rowStats}>
                        <div className={style.statsValue}>{didntClick}</div>
                        <div className={style.statsInfo}>
                            <div className={style.statsName}>Didn't click</div>
                            <div className={style.statsDesc}>{`That's ${didntClickRate} of those who opened`}</div>
                        </div>
                    </div>
                </div>
                <div className={style.linksTable}>
                    <div className={style.tableHeader}>
                        <div className={style.urlCell}>Link (URL)</div>
                        <div className={style.uniqueCell}>Unique</div>
                        <div className={style.totalCell}>Total</div>
                    </div>
                    {this.state.links.map(link => {
                        return (
                            <div
                                className={style.tableRow}
                                key={link.url}
                            >
                                <div className={style.urlCell}>{link.url}</div>
                                <div className={style.uniqueCell}>{link.unique}</div>
                                <div className={style.totalCell}>{link.total}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

class ReactView extends React.Component {
    constructor(props) {
        super(props);

        this.firstRowKpisSections = [{
            id: 'send',
            name: 'Sent'
        }, {
            id: 'unique_open',
            name: 'Unique Opens',
            backgroundColor: '#9fee3e',
            fontColor: 'white'
        }, {
            id: 'clicks',
            name: 'Clicks',
            backgroundColor: '#0ed33f',
            fontColor: 'white'
        }, {
            id: 'unopened',
            name: 'Unopened',
            backgroundColor: '#0c97ff',
            fontColor: 'white'
        }];

        this.secondRowKpisSections = [{
            id: 'delivered',
            name: 'Delivered'
        }, {
            id: 'bounce',
            name: 'Bounced',
            backgroundColor: '#ffd20f',
            fontColor: 'white'
        }, {
            id: 'unsubscribed',
            name: 'Unsubscribes',
            backgroundColor: '#ff283b',
            fontColor: 'white'
        }, {
            id: 'spam',
            name: 'SPAM',
            backgroundColor: '#f28f5a',
            fontColor: 'white'
        }];

        this.state = {
            graphData: []
        };
    }

    setData(data) {
        const unopened = data.totals.send - data.totals.unique_open;
        const sendToDiv = Math.max(data.totals.send, 1); // to avoid divisions by 0

        this.firstRowKpis.setValues({
            'send': {current: data.totals.send},
            'unique_open': {current: data.totals.unique_open, fixedDelta: `= ${data.totals.open} total opens`},
            'clicks': {current: data.totals.unique_click, fixedDelta: `= ${data.totals.click} total clicks`},
            'unopened': {current: unopened, fixedDelta: `= ${+parseFloat(unopened / sendToDiv * 100).toFixed(2)}% unopened`}
        });

        this.secondRowKpis.setValues({
            'delivered': {current: data.totals.delivered},
            'bounce': {current: data.totals.bounce, fixedDelta: `= ${+parseFloat(data.totals.bounce / sendToDiv * 100).toFixed(2)}% bounce rate`},
            'unsubscribed': {current: data.totals.unsubscribed, fixedDelta: `= ${+parseFloat(data.totals.unsubscribed / sendToDiv * 100).toFixed(2)}% unsubscribe rate`},
            'spam': {current: data.totals.spam, fixedDelta: `= ${+parseFloat(data.totals.spam / sendToDiv * 100).toFixed(2)}% marked as SPAM`}
        });

        this.linksClicked.setValues({
            peopleClicked: data.totals.unique_click,
            clickRate: data.totals.unique_click / sendToDiv,
            totalClicks: data.totals.click,
            didntClick: data.totals.unique_open - data.totals.unique_click,
            didntClickRate: (data.totals.unique_open - data.totals.unique_click) / Math.max(data.totals.unique_open, 1)
        }, data.links);

        let graphData = [];
        let startingHour = new Date(this.props.campaign.get('sent')).getHours();

        for (let h = 0; h < 24; ++h) {
            const ch = (startingHour + h) % 24;

            graphData.push({
                name: ch.toString(),
                opens: data.hours[ch] ? data.hours[ch].unique_open : 0,
                clicks: data.hours[ch] ? data.hours[ch].unique_click : 0
            });
        }

        this.setState({graphData: graphData});
    }

    downloadAsPdf() {
        const element = document.getElementById('campaign-summary-sheet-react');
        const svgEl = $(element).find('svg');
        let nodesToRecover = [];
        let nodesToRemove = [];

        svgEl.each(function(idx, node) {
            const parentNode = node.parentNode;
            const svg = parentNode.innerHTML;
            const canvas = document.createElement('canvas');

            canvg(canvas, svg);

            nodesToRecover.push({
                parent: parentNode,
                child: node
            });

            parentNode.removeChild(node);

            nodesToRemove.push({
                parent: parentNode,
                child: canvas
            });

            parentNode.appendChild(canvas);
        });

        html2pdf(element, {
            filename: `${this.props.campaign.get('name')} summary sheet`
        });

        for (const node of nodesToRemove) {
            node.parent.removeChild(node.child);
        }

        for (const node of nodesToRecover) {
            node.parent.appendChild(node.child);
        }
    }

    render() {
        const sent = this.props.campaign.get('sent');
        const sentDate = dateFormat.entityInformationFormat(sent);
        const sentHour = dateFormat.shortFormatTime(sent);
        let hours = new Date(sent).getHours();
        hours = (hours > 9) ? hours : '0' + hours;

        return (
            <div>
                <div
                    className={style.downloadAsPdf}
                    onClick={this.downloadAsPdf.bind(this)}
                >
                    <div>Download as PDF</div>
                </div>
                <div id='campaign-summary-sheet-react' className={style.campaignSummarySheet}>
                    <div className={style.header}>
                        <div className={style.campaignName}>{this.props.campaign.get('name')}</div>
                        <div className={style.sentDate}>sent {sentDate} at {sentHour}</div>
                    </div>
                    <div className={style.legend}>
                        <div className={style.legendRect} style={{background: '#9fee3e'}}/>
                        <div>Opens +</div>
                        <div className={style.legendRect} style={{background: '#0ed33f', marginLeft: '5px'}}/>
                        <div>Link Clicks for first 24 hours</div>
                    </div>
                    <div className={style.sectionContent}>
                        <AreaChart
                            width={700}
                            height={300}
                            data={this.state.graphData}
                        >
                            <XAxis dataKey='name'/>
                            <YAxis />
                            <Area type='monotone' isAnimationActive={false} dataKey='opens' stroke='#9fee3e' fill='#9fee3e' />
                            <Area type='monotone' isAnimationActive={false} dataKey='clicks' stroke='#0ed33f' fill='#0ed33f' />
                        </AreaChart>
                        <div className={style.initialDate}>{dateFormat.shortFormat(sent)}: {hours}:00</div>
                    </div>
                    <div className={style.section}>
                        <div className={style.sectionName}>Campaign Summary KPIs</div>
                    </div>
                    <div className={style.sectionContent}>
                        <KPIWidget
                            ref={(el) => this.firstRowKpis = el}
                            sections={this.firstRowKpisSections}
                        />
                        <KPIWidget
                            ref={(el) => this.secondRowKpis = el}
                            sections={this.secondRowKpisSections}
                        />
                    </div>
                    <div className={style.section}>
                        <div className={style.sectionName}>Links Clicked</div>
                    </div>
                    <div className={style.sectionContent}>
                        <LinksClicked
                            ref={(el) => this.linksClicked = el}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'campaign-summary-sheet-modal');
    },
    onRender: function() {
        const self = this;

        $.ajax({
            url: `/campaigns/${this.options.campaign.get('id')}/summary`,
            method: 'GET',
            success: function(response) {
                self.reactEl.setData(response);
            }
        });

        this.renderReact();
    },
    renderReact: function() {
        ReactDOM.render(
            <ReactView
                ref={(el) => this.reactEl = el}
                campaign={this.options.campaign}
            />
            , this.$el.get(0)
        );
    }
});
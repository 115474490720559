import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import Currency from 'js/utils/currency'
import LoadingView from 'js/views/loading'
import OpportunitiesCollection from 'js/collections/opportunities'
import TeamsCollection from 'js/collections/teams'
import teamSelectorTemplate from 'templates/widgets/team_selector.handlebars'
import teamSelectorListTemplate from 'templates/widgets/team_selector_list.handlebars'
import teamSelectorListItemIndividualTemplate from 'templates/widgets/team_selector_list_item_individual.handlebars'
import teamSelectorListItemTeamTemplate from 'templates/widgets/team_selector_list_item_team.handlebars'


var TeamSelector, ItemView, IndividualView, TeamView, ListView, NoCollectionView, createPctString;

createPctString = function(value, total) {
    return '' + (Math.min(1, value / total) * 100) + '%';
};

ItemView = Marionette.ItemView.extend({
    tagName: 'li',
    ui: {
        barClosed: '.bar-closed',
        barCommitted: '.bar-committed'
    },
    templateHelpers: function() {
        var currency = app.user.get('client').default_currency,
            output = {
                closed_str: Currency.shortFormat(currency, this.model.get('closed')),
                target_str: Currency.shortFormat(currency, this.model.get('target'))
            };

        if (this.model.get('id') === app.user.get('id')) {
            output.name = 'me';
            output.my_name = this.model.get('name');
        }

        if (_.isNumber(this.model.get('committed'))) {
            output.committed_str = Currency.shortFormat(currency, this.model.get('committed'));
        }

        return output;
    },
    initialize: function() {
        this.$el.addClass(this.options.model.get('team_id') ? 'team' : 'user');
    },
    onRender: function() {
        var self = this,
            closed = this.model.get('closed'),
            committed = this.model.get('committed'),
            target = this.model.get('target');

        this.ui.barClosed.css('width', createPctString(closed, target));
        this.ui.barCommitted.css('width', createPctString(committed, target));

        this.$el.find('[data-toggle="tooltip"]').tooltip();
        this.$el.on('click', function() {
            self.$el.find('[data-toggle="tooltip"]').tooltip('destroy');
        });
    }
});

IndividualView = ItemView.extend({
    template: Handlebars.compile(teamSelectorListItemIndividualTemplate),
    events: {
        'click': function() {
            this.trigger('click:individual');
        }
    }
});

TeamView = ItemView.extend({
    template: Handlebars.compile(teamSelectorListItemTeamTemplate),
    events: {
        'click': function() {
            this.trigger('click:team');
        }
    }
});

NoCollectionView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile('<div class="center">No team members</div>')
});

ListView = Marionette.CompositeView.extend({
    className: 'content-container list-pane',
    template: Handlebars.compile(teamSelectorListTemplate),
    itemViewContainer: '.basic-list',
    emptyView: NoCollectionView,
    initialize: function() {
        var self = this;
        // treat manager of the current team as a user item, not as a team item
        // and don't show the committed value for them
        this.collection.each(function(item) {
            if (item.get('team_id') && item.get('team_id') === self.options.teamId) {
                item.unset('team_id');
                item.unset('team_name');
                item.unset('committed');
            }
        });
        this.listenTo(this.options.container, 'team:opportunities:loaded', function() {
            self.$el.nanoScroller();
        });
    },
    getItemView: function(item) {
        if (item) {
            if (item.get('team_id')) {
                return TeamView;
            }
            else {
                return IndividualView;
            }
        }
    }
});

/**
 * options
 *      teamId      uuid|null   Id for team which should be loaded initially. If null all base users will be loaded.
 *      managerId   uuid        If teamId is not provided, team is requested by manager id.
 *      loadOpps    bool        Whether to load opportunities.
 *      showArrow   bool        Whether to show visual arrow on right side.
 *
 * events
 *      team:loaded     managerId   fires when team structure is loaded and shown, provides manager id for team.
 *
 *      team:opportunities:loaded   opportunities, teamId   fires when team structure is loaded and opportunities
 *                                                          requested from backend, provides opportunities and
 *                                                          id for team.
 *
 *      individual:clicked      individualId    fires when clicked on an user without team, provides user Id.
 *
 *      individual:opportunities:loaded     opportunities   fires when opportunities requested and loaded for user,
 *                                                          provides opportunity list;
 */
TeamSelector = Marionette.Layout.extend({
    className: 'list-wrapper no-toolbar',
    template: Handlebars.compile(teamSelectorTemplate),
    templateHelpers: function() {
        return {
            clientName: this.activeTab === 'team' ? (this.options.teamId || this.options.managerId ? 'My Team' : app.user.get('client').name) : 'Branch'
        };
    },
    ui: {
        header: '.list-header .header-inner',
        title: '.list-header .title',
        teamTab: '.team-tab',
        branchTab: '.branch-tab',
        quotaWidget: '.list-header .quota-widget',
        list: '.list-container',
        arrow: '.arrow',
        closedValue: '.quota-closed-value',
        committedValue: '.quota-committed-value',
        targetValue: '.quota-target-value',
        barClosed: '.bar-closed',
        barCommitted: '.bar-committed'
    },
    regions: {
        loaderRegion: '.loader-container'
    },
    events: {
        'click .back': function() {
            if (!this.history.pop()) {
                return;
            }
            var history = this.history;
            var historyItem = history[history.length - 1];
            var prevHistItem;

            if (historyItem) {
                this.loadList(historyItem.id, true);
                this.setTitle(historyItem.name);

                if (history.length > 1) {
                    prevHistItem = history[history.length - 2];
                    this.$el.find('.back-to-text').text(prevHistItem.name);
                }
                else if (this.options.teamId || this.options.managerId) {
                    this.$el.find('.back-to-text').text(this.activeTab === 'team' ? 'My Team' : 'Branch');
                } else {
                    this.$el.find('.back-to-text').text(app.user.get('client').name);
                }

                vent.trigger('change:activity:filter:owner', {
                    id: historyItem.managerId,
                    name: historyItem.managerName
                }, true);
            }
            else {
                // load initial team
                if (this.activeTab === 'team') {
                    this.loadList(this.options.teamId, true);
                } else {
                    this.loadList(null, true, false, null);
                }

                this.setTitle(this.templateHelpers().clientName);
                this.$el.find('.back-to')
                    .animate({ opacity: 0 }, 150)
                    .hide(200, 'easeOutQuint');
                vent.trigger('remove:activity:filter:owner');
            }

            this.positionArrow();
            this.ui.header.removeClass('deselect-all');
        },
        'click .list-header .header-inner.deselect-all': function() {
            var teamId, historyItem;
            if (this.history.length) {
                historyItem = this.history[this.history.length - 1];
                teamId = historyItem.id;
                vent.trigger('change:activity:filter:owner', {
                    id: historyItem.managerId,
                    name: historyItem.managerName
                }, true);
            }
            else {
                teamId = null;
                vent.trigger('remove:activity:filter:owner');
            }
            // reload current list
            if (this.activeTab === 'team') {
                this.loadList(teamId, false, true);
            } else {
                this.loadList(null, false, true, null);
            }
            this.positionArrow();
            this.ui.header.removeClass('deselect-all');
        },
        'click .team-tab': function() {
            if (this.activeTab !== 'team') {
                this.setActiveTab('team');
                this.loadList();
            }
        },
        'click .branch-tab': function() {
            if (this.activeTab !== 'branch') {
                this.setActiveTab('branch');
                this.loadList(null, false, false, null);
            }
        }
    },
    initialize: function() {
        this.history = [];
        this.activeTab = 'team';
        this.everyoneTeamId = null;
    },
    onRender: function() {
        this.loadList();
        if (!this.options.showArrow) {
            this.ui.arrow.hide();
        }
    },
    setActiveTab: function(tab) {
        this.ui.teamTab.toggleClass('is-active', tab === 'team');
        this.ui.branchTab.toggleClass('is-active', tab === 'branch');
        this.$el.find('.back-to').css('opacity', 0);
        this.ui.teamTab.text('My Team');
        this.ui.branchTab.text('Branch');
        this.history = [];
        this.activeTab = tab;
    },
    loadList: function(teamId, fromBack, refresh, managerId) {
        var self = this,
            UrlPart = '';

        this.showLoader();

        if (teamId) {
            UrlPart = '?team_id=' + teamId;
        }
        else if (managerId) {
            UrlPart = '?manager_id=' + managerId;
        }
        else if (_.isUndefined(teamId) && this.options.teamId) {
            UrlPart = '?team_id=' + this.options.teamId;
        }
        else if (_.isUndefined(managerId) && this.options.managerId) {
            UrlPart = '?manager_id=' + this.options.managerId;
        }

        if (this.options.loadOpps) {
            UrlPart = (UrlPart ? UrlPart + '&' : '?') + 'load_opps';
        }

        var internalLoadList = function() {
            $.ajax({
                url: '/widgets/team_members' + UrlPart,
                type: 'GET',
                dataType: 'JSON',
                contentType: "application/json; charset=utf-8",
                success: function(data) {
                    self.hideLoader();

                    if (!data) {
                        self.trigger('team:loaded', false);
                        return;
                    }
                    var collection = new Backbone.Collection(data.members),
                        teamName = data.team_name,
                        teamId = data.team_id,
                        managerId = data.manager_id,
                        listView = new ListView({ collection: collection, teamId: teamId, container: self });

                    if (teamName) {
                        if ((managerId === self.options.managerId) && (self.activeTab === 'team')) {
                            self.setTitle('My Team');
                        }
                        else {
                            self.setTitle(teamName);
                        }
                        self.setQuotaValues(data.closed, data.committed, data.target);
                    } else {
                        if (self.activeTab === 'branch') {
                            self.setQuotaValues(data.closed, data.committed, data.target);
                        } else {
                            self.hideQuotaWidget();
                        }
                    }

                    self.animateList(listView, fromBack, function() {
                        self.trigger('team:loaded', managerId || self.everyoneTeamId);
                        self.trigger('team:opportunities:loaded', data.opportunities, teamId);
                    }, refresh);

                    self.listenTo(listView, 'itemview:click:team', function(item) {
                        var teamId = item.model.get('team_id'),
                            teamName = item.model.get('team_name'),
                            historyItem;

                        this.loadList(teamId);
                        this.setTitle(teamName);
                        this.history.push({
                            id: teamId,
                            name: teamName,
                            managerName: item.model.get('name'),
                            managerId: item.model.get('id')
                        });

                        this.positionArrow();
                        if (this.history.length > 1) {
                            historyItem = this.history[this.history.length - 2];
                            this.$el.find('.back-to-text').text(historyItem.name);
                        }
                        else if (this.options.teamId || this.options.managerId) {
                            this.$el.find('.back-to-text').text(this.activeTab === 'team' ? 'My Team' : 'Branch');
                        }
                        else {
                            this.$el.find('.back-to-text').text(app.user.get('client').name);
                        }
                        this.$el.find('.back-to')
                            .show(200, 'easeOutQuint')
                            .animate({ opacity: 1 }, 150);

                        self.ui.header.removeClass('deselect-all');

                        vent.trigger('change:activity:filter:owner', {
                            id: item.model.get('id'),
                            name: item.model.get('name')
                        }, true);
                    });
                    self.listenTo(listView, 'itemview:click:individual', function(item) {
                        // do nothing if item is already active
                        if ($(item.$el).hasClass('active')) {
                            return;
                        }

                        self.trigger('individual:clicked', item.model.get('id'));

                        var opportunities;

                        $(self.$el).find('.team-list').toggleClass('has-active', !item.$el.hasClass('active'));

                        var active = $(self.$el).find('.active');
                        active.removeClass('active');
                        active.find('.basic-list-item').removeClass('disable-effects');

                        $(item.$el).addClass('active');
                        $(item.$el).find('.basic-list-item').addClass('disable-effects');

                        self.ui.header.addClass('deselect-all');

                        if (this.options.loadOpps) {
                            opportunities = new OpportunitiesCollection();

                            opportunities.fetch({
                                url: '/users/' + item.model.get('id') + '/deals',
                                rows: 200,
                                success: function () {
                                    self.trigger('individual:opportunities:loaded', opportunities);
                                    self.positionArrow(item);
                                }
                            });
                        }
                        else {
                            self.positionArrow(item);
                        }

                        vent.trigger('change:activity:filter:owner', {
                            id: item.model.get('id'),
                            name: item.model.get('name')
                        }, true);
                    });

                    // ...
                    var manager = collection.get(managerId);

                    vent.trigger('change:activity:filter:owner', {
                        id: managerId,
                        name: manager ? manager.get('name') : ''
                    }, true);
                }
            });
        }

        if (this.activeTab === 'branch' && !this.everyoneTeamId) {
            var self = this;
            var teams = new TeamsCollection();

            teams.fetch({
                data: {
                    rows: -1,
                    search: ''
                },
                success: function(data) {
                    self.everyoneTeamId = data.models.find(m => m.get('short_id') === 'everyone').get('id');
                    internalLoadList();
                }
            });
        } else {
            internalLoadList();
        }
    },
    animateList: function(newListView, direction, callback, refresh) {
        if (this.isClosed) {
            return;
        }
        var self = this,
            newListEl = newListView.render().$el,
            oldListEl = this.ui.list.find('.list-pane'),
            slideType = direction ? 'sr' : 'sl',
            slideOpts = {
                sl: ['slin', 'slout'],
                sr: ['srin', 'srout']
            };

        if (refresh) {
            self.oldListView.close();
            self.ui.list.html(newListEl);
            this.oldListView = newListView;
            callback();
        }
        else if (oldListEl.length) {
            if (slideType === 'sl') {
                this.ui.list.append(newListEl);
            }
            else {
                this.ui.list.prepend(newListEl);
            }

            oldListEl.on('webkitAnimationEnd oanimationend mozAnimationEnd animationend', function() {
                newListEl.removeClass(slideOpts[slideType][0]);
                self.oldListView.close();
                self.oldListView = newListView;
                callback();
            });

            newListEl.addClass(slideOpts[slideType][0]);
            oldListEl.addClass(slideOpts[slideType][1]);
        }
        else {
            this.ui.list.prepend(newListEl);
            this.oldListView = newListView;
            callback();
        }
    },
    setTitle: function(teamName) {
        if (!this.isClosed) {
            if (AppConfig.getValue('enableBranchSelectionOnSalesManagerDashboard')) {
                if (this.activeTab === 'team') {
                    this.ui.teamTab.text(teamName);
                } else if (this.activeTab === 'branch') {
                    this.ui.branchTab.text(teamName);
                }
            } else {
                this.ui.title.text(teamName);
            }
        }
    },
    setQuotaValues: function(closed, committed, target) {
        if (this.isClosed) {
            return;
        }
        var currency = app.user.get('client').default_currency;

        this.ui.closedValue.text(Currency.shortFormat(currency, closed));
        this.ui.committedValue.text(Currency.shortFormat(currency, committed));
        this.ui.targetValue.text(Currency.shortFormat(currency, target));
        this.ui.barClosed.css('width', createPctString(closed, target));
        this.ui.barCommitted.css('width', createPctString(committed, target));
        this.ui.quotaWidget.show();
    },
    hideQuotaWidget: function() {
        if(this.ui.quotaWidget){
            this.ui.quotaWidget.hide();
        }
    },
    positionArrow: function(item) {
        if (!this.options.showArrow) {
            return;
        }

        var self = this,
            arrow = this.$el.find('.active-arrow');

        if (item) {
            arrow.detach();
            arrow.addClass('member-arrow');
            item.$el.append(arrow);
        }
        else {
            arrow.detach();
            arrow.removeClass('member-arrow');
            this.$el.append(arrow);
        }
    },
    showLoader: function() {
        if (!this.isClosed) {
            this.ui.title.hide();
            this.ui.quotaWidget.hide();
            this.$el.find('.team-list li').css({opacity: 0});
            this.loaderRegion.show(new LoadingView());
        }
    },
    hideLoader: function() {
        if (!this.isClosed) {
            this.ui.title.show();
            this.ui.quotaWidget.show();
            this.ui.list.show();
            this.loaderRegion.reset();
        }
    }
});

export default TeamSelector;

import $ from 'jquery'
import _ from 'underscore'
import React from 'react'

import app from 'js/app'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import FunnelsCollection from 'js/collections/funnels'
import GroupElementsCollection from 'js/collections/group_elements'
import PhasesCollection from 'js/collections/phases'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import dateFormat from 'js/utils/date-format'
import html2pdf from 'html2pdf.js'

import style from './deals-calendar.css'


class DealsCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            calendars: []
        };

        this.phases = [];

        const clientPreferences = app.user.get('client').preferences || {};

        if (clientPreferences.custom_colorkeys) {
            const ck = JSON.parse(clientPreferences.custom_colorkeys).find(ck => ck.dropdown_id === 'phase.name');
            if (ck) {
                this.customColorKey = ck.colours_ex;
            }
        }

        if (app.globalData.dealsCalendarFilterId) {
            this.fetchData();
        } else {
            const filter = new OpportunityFilterModel();
            const currentYear = new Date().getUTCFullYear();
            const self = this;
            let rule = {
                field: 'opportunity_closed_date',
                operator: 'between',
                values: {
                    start: `${currentYear}-01-01`,
                    end: `${currentYear + 1}-12-31`,
                }
            };

            const customFilter = AppConfig.getValue('deals_calendar.filter');

            if (customFilter) {
                rule = _.extend(rule, customFilter);
            }

            filter.save({
                rules: [[rule]]
            }, {
                alert: false,
                success: function(data) {
                    app.globalData.dealsCalendarFilterId = data.get('id');
                    self.fetchData();
                }
            });
        }

        if (this.props.actionsView) {
            const self = this;

            this.props.actionsView.clickCallback = function(actionId) {
                if (actionId === 'download-pdf') {
                    self.downloadAsPdf();
                }
            }
        }
    }

    fetchData() {
        let funnels = null;
        let deals = null;
        let phases = null;
        const self = this;
        let dealColumns = ['name', 'phase', 'funnel', 'expected_close_date', 'abbreviation'];

        const customFields = app.globalData.customFieldsInfo.deals;
        let unitNumberCfId = null;

        for (const cfId in customFields) {
            if (customFields[cfId].name.toLowerCase() === 'unit no.') {
                unitNumberCfId = cfId;
                break;
            }
        }

        if (unitNumberCfId) {
            dealColumns.push(`custom_fields.${unitNumberCfId}`);
        }

        const checkReady = function() {
            if (funnels === null || deals === null || phases === null) {
                return;
            }

            const currentYear = new Date().getUTCFullYear();
            let currentYearCalendar = {
                year: currentYear,
                funnels: []
            };
            let nextYearCalendar = {
                year: currentYear + 1,
                funnels: []
            };

            let validPhases = [];

            for (const phase of phases) {
                if (phase.get('phase_type') !== 'user') {
                    continue;
                }

                const name = phase.get('name');

                if (!AppConfig.getValue('deals_calendar.phase_visible_in_list', true, name)) {
                    continue;
                }

                if (_.findWhere(validPhases, {name: name})) {
                    continue;
                }

                validPhases.push({
                    name: name,
                    order: phase.get('order')
                });
            }

            const sortedPhases = _.sortBy(validPhases, 'order');

            self.phases = sortedPhases.map(p => p.name);

            for (const funnel of funnels) {
                if (funnel.get('exclude_from_calendar_report')) {
                    continue
                }

                const funnelId = funnel.get('id');

                let dealsPerMonth = {
                    currentYear: [],
                    nextYear: []
                };

                for (const y in dealsPerMonth) {
                    dealsPerMonth[y] = {
                        months: [],
                        numWon: 0
                    };

                    for (let i = 0; i < 12; ++i) {
                        dealsPerMonth[y].months.push({
                            deals: [],
                            numWon: 0
                        });
                    }
                }

                // deals
                for (const deal of deals) {
                    if (deal.get('funnel').id !== funnelId || deal.get('phase').phase_type === 'lost') {
                        continue;
                    }

                    var reportDate = AppConfig.getClientPreferenceValue('dealsCalendarReportDate');

                    if ( !reportDate ) {
                        reportDate = 'expected_close_date';
                    }

                    const date = deal.get(reportDate);

                    if (!date) {
                        continue;
                    }

                    const expectedCloseDate = new Date(date);
                    const expectedCloseMonth = expectedCloseDate.getMonth();
                    const key = (expectedCloseDate.getFullYear() === currentYear) ? 'currentYear' : 'nextYear';

                    if (AppConfig.getValue('deals_calendar.phase_is_won', null, deal.get('phase'))) {
                        ++dealsPerMonth[key].months[expectedCloseMonth].numWon; // wons per month
                        ++dealsPerMonth[key].numWon; // wons per funnel
                    }

                    var bubbleDealName = '';
                    if (AppConfig.getValue("funnel.display_bubble.show_unit_no", false) && (unitNumberCfId && deal.get(`custom_fields.${unitNumberCfId}`))) {
                        bubbleDealName = deal.get(`custom_fields.${unitNumberCfId}`);
                    } else {
                        bubbleDealName = AppConfig.getValue('deals_calendar.deal_name_formatter', deal.get('abbreviation'), deal.get('abbreviation'))
                    }

                    dealsPerMonth[key].months[expectedCloseMonth].deals.push({
                        id: deal.get('id'),
                        name: bubbleDealName,
                        phase: deal.get('phase').name
                    });
                }

                const cyFunnel = {
                    id: funnel.get('id'),
                    name: funnel.get('name'),
                    months: dealsPerMonth.currentYear.months,
                    numDealsWon: dealsPerMonth.currentYear.numWon,
                    numDeals: dealsPerMonth.currentYear.months.reduce((total, m) => total + m.deals.length, 0)
                };

                if (cyFunnel.numDeals > 0) {
                    currentYearCalendar.funnels.push(cyFunnel);
                }

                const nyFunnel = {
                    id: funnel.get('id'),
                    name: funnel.get('name'),
                    months: dealsPerMonth.nextYear.months,
                    numDealsWon: dealsPerMonth.nextYear.numWon,
                    numDeals: dealsPerMonth.nextYear.months.reduce((total, m) => total + m.deals.length, 0)
                };

                if (nyFunnel.numDeals) {
                    nextYearCalendar.funnels.push(nyFunnel);
                }
            }

            self.setState({calendars: [currentYearCalendar, nextYearCalendar]});
        }

        const funnelsCollection = new FunnelsCollection();

        funnelsCollection.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function(data) {
                funnels = data.models;
                checkReady();
            }
        });

        const dealsCollection = new GroupElementsCollection(null, {elementType: 'opportunities'});

        dealsCollection.fetch({
            rows: -1,
            data: {
                filter_id: app.globalData.dealsCalendarFilterId,
                columns: dealColumns.concat(AppConfig.getValue('deals_calendar.extra_columns', []))
            },
            success: function(data) {
                deals = data.models;
                checkReady();
            }
        });

        const phasesCollection = new PhasesCollection();

        phasesCollection.fetch({
            success: function(data) {
                phases = data.models;
                checkReady();
            }
        });
    }

    getColorByPhase(phaseName) {
        if (this.customColorKey) {
            const lowerPhase = AppConfig.getValue('deals_calendar.map_phase', phaseName, phaseName).toLowerCase();

            for (const entry of this.customColorKey) {
                if (entry.option_id.indexOf(lowerPhase) !== -1) {
                    return {
                        background: entry.hex,
                        font: entry.font_hex || 'black'
                    };
                }
            }
        }

        return {
            background: '#a2c5ff',
            font: 'black'
        };
    }

    downloadAsPdf() {
        const element = document.getElementById('deals-calendar-container');
        const toExportEl = $(document.createElement('div'));

        $(element).clone().appendTo(toExportEl);
        toExportEl.find(`.${style.dealsCalendar}`).addClass(style.pdfExport);

        html2pdf(toExportEl[0], {
            filename: `Calendar view ${dateFormat.formatDDMMYYYYDate(new Date())}`,
            pagebreak: {
                mode: 'avoid-all',
                after: `.${style.calendar}:first-child`
            },
            jsPDF: {
                orientation: 'landscape',
                format: 'a3'
            }
        });
    }

    render() {
        if (this.state.calendars.length === 0) {
            return (
                <div
                    style={{marginTop: '20px'}}
                >
                    <LoadingIndicator/>
                </div>
            );
        }

        const months = dateFormat.shortFormatMonth(null);

        return (
            <div className={style.dealsCalendar}>
                <div className={style.pdfHeader}>
                    <div className={style.phasesList}>
                        <div className={`${style.phHeader} ${style.phRow}`}>Key</div>
                        {this.phases.map(phase => {
                            const color = this.getColorByPhase(phase);

                            return (
                                <div
                                    key={`pn_${phase}`}
                                    className={style.phRow}
                                    style={{
                                        color: color.font,
                                        background: color.background
                                    }}
                                >
                                    {phase}
                                </div>
                            );
                        })}
                    </div>
                    <div className={style.phTitle}>{AppConfig.getValue('deals_calendar.title', '')}</div>
                    <div className={style.phDate}>{dateFormat.formatDDMMYYYYDate(new Date())}</div>
                </div>

                {this.state.calendars.map(calendar => {
                    let budgetMonthTotals = [];
                    let wonMonthTotals = [];
                    let varianceMonthTotals = [];
                    let budgetCalendarTotals = 0;
                    let wonCalendarTotals = 0;
                    let varianceCalendarTotals = 0;

                    return (
                        <table
                            className={style.calendar}
                            key={`calendar_${calendar.year}`}
                        >
                            <thead>
                                <tr>
                                    <th>
                                        {calendar.year}
                                    </th>
                                    {months.map(month => <th key={month}>{month}</th>)}
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {calendar.funnels.map(funnel => {
                                    const funnelVariance = funnel.numDeals - funnel.numDealsWon;

                                    return (
                                        <tr
                                            key={`funnel_${calendar.year}_${funnel.id}`}
                                        >
                                            <td className={style.cellName}>{funnel.name}</td>
                                            {funnel.months.map((month, midx) => {
                                                const variance = month.deals.length - month.numWon;

                                                budgetMonthTotals[midx] = (budgetMonthTotals[midx] || 0) + month.deals.length;
                                                wonMonthTotals[midx] = (wonMonthTotals[midx] || 0) + month.numWon;
                                                varianceMonthTotals[midx] = (varianceMonthTotals[midx] || 0) + variance;

                                                budgetCalendarTotals += month.deals.length;
                                                wonCalendarTotals += month.numWon;
                                                varianceCalendarTotals += variance;

                                                return (
                                                    <td
                                                        key={`month_${calendar.year}_${midx}`}
                                                        className={style.cellData}
                                                    >
                                                        <div className={style.dealBubbles}>
                                                            {month.deals.map(deal => {
                                                                const color = this.getColorByPhase(deal.phase);

                                                                return (
                                                                    <div
                                                                        key={`deal_${calendar.year}_${deal.id}`}
                                                                        className={style.bubble}
                                                                        style={{
                                                                            background: color.background,
                                                                            color: color.font
                                                                        }}
                                                                        onClick={() => this.props.onShowDeal(deal.id)}
                                                                    >
                                                                        {deal.name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className={style.footer}>
                                                            {month.deals.length > 0 && <div className={style.numDeals}>{month.deals.length}</div>}
                                                            {month.numWon > 0 && <div className={style.dealsWon}>{month.numWon}</div>}
                                                            {variance !== 0 && <div className={`${style.dealsVariance} ${variance < 0 ? style.vNegative : ''}`}>{Math.abs(variance)}</div>}
                                                        </div>
                                                    </td>
                                                );
                                            })}
                                            <td className={style.cellSummary}>
                                                <div className={style.summaryContainer}>
                                                    {funnel.numDeals > 0 && <div className={style.totalDeals}>{funnel.numDeals}</div>}
                                                    {funnel.numDealsWon > 0 && <div className={style.totalDealsWon}>{funnel.numDealsWon}</div>}
                                                    {funnelVariance !== 0 && <div className={`${style.totalDealsVariance} ${funnelVariance < 0 ? style.dvNegative : ''}`}>{Math.abs(funnelVariance)}</div>}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td
                                        className={style.cellTotalsName}
                                        style={{borderBottom: 'none'}}
                                    >
                                        Total Budget
                                    </td>
                                    {budgetMonthTotals.map((budget, tidx) => {
                                        return (
                                            <td
                                                key={`bt_${calendar.year}_${tidx}`}
                                                className={style.cellData}
                                                style={{borderBottom: 'none'}}
                                            >
                                                <div className={style.cellSummaryFooter}>
                                                    {budget > 0 && <div className={style.numDeals}>{budget}</div>}
                                                </div>
                                            </td>
                                        );
                                    })}

                                    <td
                                        className={style.cellSummary}
                                        style={{borderBottom: 'none'}}
                                    >
                                        <div className={style.summaryContainer}>
                                            {budgetCalendarTotals > 0 && <div className={style.totalDeals}>{budgetCalendarTotals}</div>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className={style.cellTotalsName}
                                        style={{borderBottom: 'none', borderTop: 'none'}}
                                    >
                                        Total Plots
                                    </td>
                                    {budgetMonthTotals.map((__, tidx) => {
                                        return (
                                            <td
                                                key={`won_${calendar.year}_${tidx}`}
                                                className={style.cellData}
                                                style={{borderBottom: 'none', borderTop: 'none'}}
                                            >
                                                <div className={style.cellSummaryFooter}>
                                                    {wonMonthTotals[tidx] > 0 && <div className={style.dealsWon}>{wonMonthTotals[tidx]}</div>}
                                                </div>
                                            </td>
                                        );
                                    })}

                                    <td
                                        className={style.cellSummary}
                                        style={{borderBottom: 'none', borderTop: 'none'}}
                                    >
                                        <div className={style.summaryContainer}>
                                            {wonCalendarTotals > 0 && <div className={style.totalDealsWon}>{wonCalendarTotals}</div>}
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        className={style.cellTotalsName}
                                        style={{borderTop: 'none'}}
                                    >
                                        Variance
                                    </td>
                                    {budgetMonthTotals.map((__, tidx) => {
                                        return (
                                            <td
                                                key={`var_${calendar.year}_${tidx}`}
                                                className={style.cellData}
                                                style={{borderTop: 'none'}}
                                            >
                                                <div className={style.cellSummaryFooter}>
                                                    {varianceMonthTotals[tidx] !== 0 && <div className={`${style.dealsVariance} ${varianceMonthTotals[tidx] < 0 ? style.vNegative : ''}`}>{Math.abs(varianceMonthTotals[tidx])}</div>}
                                                </div>
                                            </td>
                                        );
                                    })}

                                    <td
                                        className={style.cellSummary}
                                        style={{borderTop: 'none'}}
                                    >
                                        <div className={style.summaryContainer}>
                                            {varianceCalendarTotals !== 0 && <div className={`${style.totalDealsVariance} ${varianceCalendarTotals < 0 ? style.dvNegative : ''}`}>{Math.abs(varianceCalendarTotals)}</div>}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    );
                })}
            </div>
        );
    }
}

export default DealsCalendar;
import guid from 'js/utils/guid'

const MEDIA_CSS = `
  @media only screen and (max-width: 480px) {
    [seeMediaStyle="full"] {
      display: block;
      width: 100%;
    }

    td.desktopOnly {
      display: none !important;
    }

    .plotOffer > tbody > tr:first-child {
      display: table-row !important;
    }

    .plotOffer > tbody > tr:last-child {
      display: none !important;
    }
`;

const BASE_CSS = `
  .seeSectionContainer {
    max-width: 600px !important;
    position: relative;
  }

  .seeBlockEmptyMessage {
    background: #eaeaea;
    border: 2px dashed #c5c5c5;
    text-align: center;
    padding: 15px 0;
    color: black;
    font-size: 18px;
    margin: 15px 0;
    padding: 15px 0;
    font-family: 'Proxima Nova';
  }

  .seeBlockEmptyMessage.hidden {
    display: none;
  }

  .seeBlockContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }

  .seeBlockContainer p {
    margin: 0;
  }

  .seeBlockContainer h1 {
    margin: 0;
  }

  .seeBlockContainer h2 {
    margin: 0;
  }

  .seeLink {}

  [contenteditable] {
    outline: none;
  }

  [seeMediaStyle="full"].mobileView {
    display: block;
    width: 100%;
  }

  [seeMediaStyle="full"].mobileView.desktopOnly {
    display: none !important;
  }

  [seePlotOfferItem="true"].mobileView > tbody > tr:first-child {
    display: table-row !important;
  }

  [seePlotOfferItem="true"].mobileView > tbody > tr:last-child {
    display: none !important;
  }
`;

export default {
  css: BASE_CSS,
  mediaCss: MEDIA_CSS,
  baseHtml: `
    <!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" style="box-sizing: border-box;">
      <head>
          <!--[if gte mso 15]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
          <![endif]-->
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1">
          <style type="text/css">${BASE_CSS}</style>
      </head>

      <body style="box-sizing: border-box; margin: 0;">
        <center style="box-sizing: border-box;">
          <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="seeBodyTable" style="background-color: #fafafa;">
            <tbody>
              <tr>
                <td align="center" valign="top" class="seeBodyCell">
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody id="seeTableTBody">
                      <tr>
                        <td align="center" valign="top" style="margin: 0 auto; padding: 0; width: 100%">
                          <!--[if (gte mso 9)|(IE)]><table align="center" border="0" cellspacing="0" cellpadding="0" width="660" style="width:660px;"><tr><td><![endif]-->
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="seeSectionContainer">
                            <tbody>
                              <tr>
                                <td valign="top" class="seeBlockSection" id="seeHeaderSection" style="padding-top: 15px; padding-bottom: 20px; background-color: #ffffff; border-style: none; border-color: #000000; border-width: 0px;">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="top">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="seeSectionContainer">
                            <tbody>
                              <tr>
                                <td valign="top" class="seeBlockSection" id="seeBodySection" style="padding-top: 15px; padding-bottom: 30px; background-color: #ffffff;">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" valign="top">
                          <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" class="seeSectionContainer">
                            <tbody>
                              <tr>
                                <td valign="top" class="seeBlockSection" id="seeFooterSection" style="padding-top: 15px; padding-bottom: 30px; background-color: #ffffff;">
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </center>
      </body>
    </html>
  `,
  emptyBlockMessageHtml: function(id, title) {
    return `<div seeEditorOnly="true" id="${id}" class="seeBlockEmptyMessage"><span>${title}</span></div>`;
  },
  imageBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="imageBlock">
      <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="max-width: 600px; margin: auto;">
        <tbody>
          <tr>
            <td>
              <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%;">
                <tbody>
                  <tr>
                    <td id="seeInnerTd" valign="top" style="text-align:center;">
                      <a style="width: 100%; display: block; width: 100% !important; height: auto !important;">
                        <img height="auto" style="max-width: 100%; vertical-align: top;"/>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    `;
  },
  textBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="textBlock">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;">
        <tbody>
          <tr>
            <td valign="top">
              <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%; min-width: 100%;" width="100%">
                <tbody>
                  <tr>
                    <td valign="top" style="box-sizing: border-box; padding-left: 20px; padding-right: 20px; font-family: Arial, Helvetica, sans-serif; font-weight: 400; border: none">
                      <div contenteditable="true" style="padding-top: 20px; padding-bottom: 20px; word-break: break-word;">
                        <p>
                          This is a text Box
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `;
  },
  buttonBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="buttonBlock">
      <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: auto; padding-top: 10px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px;">
        <tbody>
          <tr>
            <td id="seeOuterTd" valign="top" align="center" align="center" valign="middle">
              <!-- MSO_CODE -->

              <!--[if !mso]> <!-->
              <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate !important;">
                <tbody>
                    <tr>
                      <td id="seeInnerTd" align="center" valign="middle" style="box-sizing: border-box; padding: 12px 24px; border-radius: 5px; color: #ffffff; background-color: #333333;">
                        <a target="_blank" style="font-size: 14px; font-family: Arial; text-align: center; text-decoration: none; color: #ffffff; font-weight: bold;">Button</a>
                      </td>
                    </tr>
                </tbody>
              </table>
              <!-- <![endif]-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    `;
  },
  spacerBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="spacerBlock" style="height: inherit; display: block">
      <div style="font-size: 1px; line-height: 20px; mso-line-height-rule: exactly;">&nbsp;</div>
    </div>
    `;
  },
  dividerBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="dividerBlock" style="padding-top: 5px; padding-bottom: 5px;">
      <table align="center" border="0" cellpadding="0" cellspacing="0" width="50%" style="background-color: transparent; border-top: 2px solid #c1c1c1;">
        <tbody>
          <tr>
            <td valign="top" style="min-width: 100%;">
            </td>
          </tr>
        </tbody>
      </table>
      </div>
    `;
  },
  socialsBlockHtml: function() {
    return `<div class="seeBlockContainer" seeOrder="ftilypc" id="${guid()}" seeInitialize="true" seeBlockType="socialsBlock">
      <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%">
        <tbody>
          <tr>
            <td>
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;">
                <tbody>
                  <tr>
                    <td align="center" valign="top">
                      <table border="0" cellpadding="0" cellspacing="0">
                        <tbody>
                          <tr id="socialsContainer">
                          </tr>
                        <tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    `;
  },
  socialItemBlockHtml: function(id, href, image, size) {
    return `<td valign="top" style="padding-right: 5px;">
      <a href="${href}" target="_blank" style="display: block; padding-top: 5px; width: 100%; height: auto;">
        <img class="seeSocialImage" id=${id} src="https://public.salesseek.net/salesseek/social_icons/${image}" alt="#" border="0" height="auto" width="${size}" style="display: block; border: 0; width: ${size}px;">
      </a>
    </td>
    `;
  },
  triColumnBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="triColumnBlock">
      <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff; padding-top: 15px; padding-bottom: 15px; padding-left: 0px; padding-right: 0px;" >
        <tbody>
            <tr>
              <td valign="top" width="33.3%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td valign="top" width="33.3%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td valign="top" width="33.3%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
        </tbody>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
        <!-- // END TEMPLATE -->
      </table>
    `;
  },
  multiColumnBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="multiColumnBlock">
      <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff; padding-top: 15px; padding-bottom: 15px; padding-left: 0px; padding-right: 0px;" >
        <tbody>
            <tr>
              <td valign="top" width="50%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td valign="top" width="50%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
        </tbody>
        <!--[if (gte mso 9)|(IE)]>
        </td>
        </tr>
        </table>
        <![endif]-->
        <!-- // END TEMPLATE -->
      </table>
    `;
  },
  singleColumnBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="singleColumnBlock">
      <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff; padding-top: 15px; padding-bottom: 15px; padding-left: 0px; padding-right: 0px; border-style: none; border-color: #000000; border-width: 1px;" >
        <tbody>
            <tr>
              <td valign="top" width="100%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box;">
                <table width="100%" border="0" cellpadding="0" cellspacing="0" style="box-sizing: border-box; border: none;">
                  <tbody>
                    <tr>
                      <td class="seeColumn">
                        <div seeEditorOnly="true" class="seeBlockEmptyMessage" style="font-size: 18px; text-align: center; padding: 10px;">Drag content here</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
        </tbody>
      </table>
    `;
  },
  footerBlockHtml: function(organizationName, organizationWebsite, organizationAddress) {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="footerBlock">
      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;">
        <tbody>
          <tr>
            <td valign="top">
              <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%; min-width: 100%;" width="100%">
                <tbody>
                  <tr>
                    <td valign="top" style="box-sizing: border-box; padding-left: 20px; padding-right: 20px; font-family: Arial, Helvetica, sans-serif; font-weight: 400; border: none">
                      <div contenteditable="true" style="word-break: break-word; text-align: center; padding-top: 20px; padding-bottom: 20px;">
                        <p style="font-size: 10px; line-height: 15px;">Copyright © ` + '${current_date,format="%Y"} - ' + `${organizationName || 'Your Company'} - All rights reserved.</p>
                        <p style="font-size: 10px; line-height: 15px;">Our mailing address is:</p>
                        <p style="font-size: 10px; line-height: 15px;">${organizationAddress || 'Your Company Address'}</p>
                        <p style="font-size: 10px; line-height: 15px;"><span><a href="${organizationWebsite || 'https://www.yourcompany.com'}" class="seeLink">${organizationWebsite || 'https://www.yourcompany.com'}</a></span></p>
                        <p style="font-size: 10px; line-height: 15px;"><span><a href="` + '${unsubscribe-url}' + `" class="seeLink">Unsubscribe</a></span></p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `;
  },
  vibBlockHtml: function(color, fontFamily) {
    return `<tr id="seeVibBlock">
      <td align="center" valign="top">
        <div class="seeBlockContainer" seeIsVibBlock="true" seeBlockType="textBlock" seeHasBlockTools="false" style="font-size: 11px; font-family: ${fontFamily}; color: ${color}; text-align: center; max-width: 600px; background-color: transparent;">
            <div contenteditable="true">
              <p>
                <a href="` + '${vib-url}' + `" target="_blank" class="seeLink">View in browser</a>
              </p>
            </div>
        </div>
      </td>
    </tr>
    `;
  },
  plotOfferItemBlockHtml: function(id) {
    return `
      <table seePlotOfferItem="true" class="plotOffer" id="${id}" align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" style="background-color: #fafafa;">
        <tbody>
          <tr id="seeLeftPO" style="display: none;">
            <td valign="top" width="50%" height="300px" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box; background-color: #c1c4cd;">
              <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="max-width: 600px; margin: auto;">
                <tbody>
                  <tr>
                    <td>
                      <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%;">
                        <tbody>
                          <tr>
                            <td valign="top" style="text-align:center;">
                              <img height="auto" style="max-width: 100%; height: 300px; object-fit: cover; vertical-align: top;"/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td valign="top" width="50%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box; background-color: white;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;">
                <tbody>
                  <tr>
                    <td valign="top">
                      <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%; min-width: 100%;" width="100%">
                        <tbody>
                          <tr>
                            <td valign="top" style="box-sizing: border-box; padding-left: 40px; padding-right: 40px; border: none; text-align: center;">
                              <div style="padding-top: 40px; padding-bottom: 40px; word-break: break-word;">
                                <p class="seePlotOfferTitle" style="color: #666666; letter-spacing: 1px; font-size: 18px; font-weight: 600; line-height: 24px; padding: 10px; font-family: Arial, Helvetica, sans-serif;">Plot's Name</p>
                                <p class="seePlotOfferNumber" style="color: #666666; font-size: 14px; font-weight: 600; line-height: 20px; padding: 0 25px 5px; font-family: Arial, Helvetica, sans-serif;">Plot's Number</p>
                                <p class="seePlotOfferDescription" style="font-size: 14px; line-height: 18px; padding: 3px 25px 5px; color: #666666; font-family: Arial, Helvetica, sans-serif;">Plot's description</p>
                                <p class="seePlotOfferPrice" style="color: #666666; font-size: 14px; line-height: 20px; padding: 10px 25px 5px; font-weight: 600; font-style: italic; font-family: Arial, Helvetica, sans-serif;">Available from just £0</p>
                              </div>

                              <div>
                                <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: auto; padding-top: 10px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px;">
                                  <tbody>
                                    <tr>
                                      <td id="seeOuterTd" valign="top" align="center" align="center" valign="middle">
                                        <!-- MSO_CODE -->

                                        <!--[if !mso]> <!-->
                                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate !important;">
                                          <tbody>
                                              <tr>
                                                <td id="seeInnerTd" align="center" valign="middle" style="box-sizing: border-box; padding: 12px 35px; border-radius: 20px; color: #ffffff; background-color: #333333;">
                                                  <a target="_blank" style="font-size: 14px; font-family: Arial; text-align: center; text-decoration: none; color: #ffffff; font-weight: bold;">Find out more</a>
                                                </td>
                                              </tr>
                                          </tbody>
                                        </table>
                                        <!-- <![endif]-->
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr id="seeRightPO" style="display: none;">
            <td valign="top" width="50%" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box; background-color: white;">
              <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;">
                <tbody>
                  <tr>
                    <td valign="top">
                      <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%; min-width: 100%;" width="100%">
                        <tbody>
                          <tr>
                            <td valign="top" style="box-sizing: border-box; padding-left: 40px; padding-right: 40px; font-weight: 400; border: none; text-align: center;">
                              <div style="padding-top: 40px; padding-bottom: 40px; word-break: break-word;">
                                <p class="seePlotOfferTitle" style="color: #666666; letter-spacing: 1px; font-size: 18px; font-weight: 600; line-height: 24px; padding: 10px; font-family: Arial, Helvetica, sans-serif;">Plot's Name</p>
                                <p class="seePlotOfferNumber" style="color: #666666; font-size: 14px; font-weight: 600; line-height: 20px; padding: 0 25px 5px; font-family: Arial, Helvetica, sans-serif;">Plot's Number</p>
                                <p class="seePlotOfferDescription" style="font-size: 14px; line-height: 18px; padding: 3px 25px 5px; color: #666666; font-family: Arial, Helvetica, sans-serif;">Plot's description</p>
                                <p class="seePlotOfferPrice" style="color: #666666; font-size: 14px; line-height: 20px; padding: 10px 25px 5px; font-weight: 600; font-style: italic; font-family: Arial, Helvetica, sans-serif;">Available from just £0</p>
                              </div>

                              <div>
                                <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: auto; padding-top: 10px; padding-bottom: 10px; padding-left: 20px; padding-right: 20px;">
                                  <tbody>
                                    <tr>
                                      <td id="seeOuterTd" valign="top" align="center" align="center" valign="middle">
                                        <!-- MSO_CODE -->

                                        <!--[if !mso]> <!-->
                                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate !important;">
                                          <tbody>
                                              <tr>
                                                <td id="seeInnerTd" align="center" valign="middle" style="box-sizing: border-box; padding: 12px 35px; border-radius: 20px; color: #ffffff; background-color: #333333;">
                                                  <a target="_blank" style="font-size: 14px; font-family: Arial; text-align: center; text-decoration: none; color: #ffffff; font-weight: bold;">Find out more</a>
                                                </td>
                                              </tr>
                                          </tbody>
                                        </table>
                                        <!-- <![endif]-->
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td valign="top" width="50%" height="300px" dir="ltr" class="full" seeMediaStyle="full" style="box-sizing: border-box; background-color: #c1c4cd;">
              <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="max-width: 600px; margin: auto;">
                <tbody>
                  <tr>
                    <td>
                      <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%;">
                        <tbody>
                          <tr>
                            <td valign="top" style="text-align:center;">
                              <img height="auto" style="max-width: 100%; height: 300px; object-fit: cover; vertical-align: top;"/>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    `;
  },
  plotOffersBlockHtml: function() {
    return `<div class="seeBlockContainer" id="${guid()}" seeBlockType="plotOffersBlock">
      <table dir="ltr" width="100%" border="0" cellpadding="0" cellspacing="0" style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" >
        <tbody>
          <tr>
            <td id="seePlotsContainer">
            </td>
          </tr>
        </tbody>
      </table>
    `;
  }
};
import React from 'react';
import Marionette from 'Backbone.Marionette';

import vent from 'js/vent';
import PanelBase from './base';
import OpportunityView from 'js/views/opportunities/show';
import OpportunityModel from 'js/models/opportunity'

import style from './deal_entity.css';


export default class DealEntityPanel extends PanelBase {
    constructor(props) {
        super(props);

        this.dealId = null;
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        this.props.parent.listenTo(vent, 'deal:view:closed deal:view:cancel', function() {
            if (!self.mounted) {
                return;
            }

            self.hide();
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    show(dealId) {
        super.show();

        this.dealId = dealId;

        const self = this;

        _.defer(function() {
            const region = new Marionette.Region({
                el: self.container
            });

            const view = new OpportunityView({
                model: new OpportunityModel({id: dealId})
            });

            region.show(view);

            vent.trigger('AppContent:contentChange');
        });
    }

    hide() {
        super.hide();

        this.dealId = null;

        _.defer(function() {
            vent.trigger('AppContent:contentChange');
        });
    }

    getEntityId() {
        return this.dealId;
    }

    getContent() {
        return (
            <div
                ref={(el) => this.container = el}
                className={style.dealEntity}
            />
        );
    }
}

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import TextManager from 'app/text-manager'
import thirdPartyFormLeadsTemplate from 'templates/settings/third_party_form_leads.handlebars'


export default Marionette.Layout.extend({
    className: 'third-party-form-leads',
    template: Handlebars.compile(thirdPartyFormLeadsTemplate),
    templateHelpers: function() {
        return {
            shortId: app.user.get('client').short_id,
            host: TextManager.getText('ID_HOST')
        };
    },
    events: {
        'click .web-leads-link': function() {
            $('a[view=editWebLeadsSettings]').click();
        }
    },
    onRender: function() {
        this.$el.find('.content-container').nanoScroller();
    }
});

import app from 'js/app';
import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager';
import dateFormat from 'js/utils/date-format';
import Currency from 'js/utils/currency';

const createGroupOperators = function(entityType) {
    return [{
        id: 'equal',
        title: 'Is',
        fields: [{
            id: 'group',
            type: 'groups',
            entityType: entityType
        }],
        getValues(values) {
            return {
                id: values.group.id,
                name: values.group.name
            };
        },
        getValuesPartsContent(values) {
            return values.name;
        },
        parseValues(values) {
            if (!values) {
                return null;
            }

            return {
                group: {
                    id: values.id,
                    name: values.name
                }
            };
        }
    }, {
        id: 'empty',
        title: 'Is Empty'
    }, {
        id: 'not_empty',
        title: 'Is Not Empty',
        not: true
    }]
}

const defaultDateParser = function(values) {
    if (!values) {
        return null;
    }

    return {
        date: new Date(values)
    };
}

const timeUnits = [{
    id: 'h',
    title: 'Hours'
}, {
    id: 'd',
    title: 'Days'
}, {
    id: 'w',
    title: 'Weeks'
}, {
    id: 'm',
    title: 'Months'
}, {
    id: 'y',
    title: 'Years'
}];

const duringOptions = [{
    id: 'day',
    title: 'Today'
}, {
    id: 'week',
    title: 'This Week'
}, {
    id: 'month',
    title: 'This Month'
}, {
    id: 'quarter',
    title: 'This Quarter'
}, {
    id: 'year',
    title: 'This Year'
}];

const appointmentStatus = [{
    id: 'confirmed',
    title: 'Confirmed'
}, {
    id: 'pending',
    title: 'Pending'
}, {
    id: 'cancelled',
    title: 'Cancelled'
}, {
    id: 'completed',
    title: 'Completed'
}];

const forecastStatus = [{
    id: 'none',
    title: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}')
}, {
    id: 'none_upside',
    title: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}')
}, {
    id: 'committed_downside',
    title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}')
}, {
    id: 'committed',
    title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
}];

const activityType = [{
    id: 'email',
    title: 'Email'
}, {
    id: 'phone',
    title: 'Phone'
}];

let campaignType = null;

const getCampaignTypeList = () => {
    if (campaignType !== null) {
        return campaignType;
    }

    const list = [{
        id: 'direct',
        title: 'Direct'
    }, {
        id: 'campaign',
        title: TextManager.parseText('${ID_CAMPAIGN, capitalize}')
    }];


    if (AppConfig.getValue('enableTextMessaging')) {
        list.push({
            id: 'message',
            title: 'Text Message',
        });
    }

    campaignType = list;
    return list;
}

let currencies = null;

const getCurrenciesList = () => {
    if (currencies !== null) {
        return currencies;
    }

    const list = [];

    for (const c of Currency.getUsedCurrencies()) {
        list.push({
            id: c,
            title: Currency.composeCurrencyName(c)
        });
    }

    currencies = list;
    return list;
}

let activityTypeEx = null;

const getActivityTypeExList = () => {
    if (activityTypeEx !== null) {
        return activityTypeEx;
    }

    const list = [
        {id: 'auto:email', title: 'Auto Email'},
        {id: 'auto:call', title: 'Call'},
        {id: 'auto:website_register', title: 'Website registration'},
        {id: 'auto:web_tracking', title: 'Website tracking'},
        {id: 'auto:individual_creation', title: 'Individual created'},
        {id: 'auto:individual_merge', title: 'Individual merged'},
        {id: 'auto:individual_import', title: 'Individual imported'},
        {id: 'auto:new_archive_individual', title: 'Contact created by archive email'},
        {id: 'auto:organization_creation', title: 'Organization created'},
        {id: 'auto:organization_merge', title: 'Organization merged'},
        {id: 'auto:organization_import', title: 'Organization imported'},
        {id: 'auto:organization_opportunity_deletion', title: 'Opportunity deleted'},
        {id: 'auto:opportunity_creation', title: 'Opportunity created'},
        {id: 'auto:opportunity_import', title: 'Opportunity imported'},
        {id: 'auto:opportunity_movement', title: 'Opportunity changed phase'},
        {id: 'auto:mailing_list_unsubscribed_all', title: 'Contact unsubscribed'},
        {id: 'auto:mailing_list_subscriptions', title: 'Contact mailing preferences updated'},
        {id: 'auto:message', title: 'SMS Sent'},
        {id: 'archive:email', title: 'Contact archive email'},
        {id: 'archive:opportunity', title: 'Opportunity archive email'},
        {id: 'archive:mailshot_sent', title: 'Email sent'},
        {id: 'archive:mailshot_opened', title: 'Email opened'},
        {id: 'archive:mailshot_url_clicked', title: 'Email url clicked'},
        {id: 'archive:mailshot_soft_bounce', title: 'Email soft bounced'},
        {id: 'archive:mailshot_hard_bounce', title: 'Email hard bounced'},
        {id: 'archive:mailshot_spam', title: 'Email spam'},
        {id: 'archive:mailshot_reject', title: 'Email rejected'},
        {id: 'archive:message', title: 'SMS Received'},
        {id: 'automation:archive:mailshot_sent', title: 'Automation email sent'},
        {id: 'automation:archive:mailshot_opened', title: 'Automation email opened'},
        {id: 'automation:archive:mailshot_url_clicked', title: 'Automation URL clicked'},
        {id: 'automation:archive:mailshot_soft_bounce', title: 'Automation email soft bounced'},
        {id: 'automation:archive:mailshot_hard_bounce', title: 'Automation email hard bounced'},
        {id: 'automation:archive:mailshot_spam', title: 'Automation email spam'},
        {id: 'automation:archive:mailshot_reject', title: 'Automation email rejected'},
        {id: 'note', title: 'Note'},
        {id: 'task:completed', title: 'Task completed'},
        {id: 'automation:debug', title: 'Automation execution'},
        {id: 'appointment:note', title: 'Appointment note'}
    ];

    activityTypeEx = _.sortBy(list, 'title');

    return activityTypeEx;
}

const forecastStatusOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'status',
        type: 'dropdown',
        useGetValues: true,
        options: forecastStatus
    }],
    getValues(values) {
        const status = forecastStatus.find(s => s.id === (values.status || values));

        return {
            id: status.id,
            value: status.title
        };
    },
    getValuesPartsContent(values) {
        return values.value;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            status: forecastStatus.find(s => s.id === values.id)
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const phaseOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'phase',
        type: 'phases'
    }],
    getValues(values) {
        return {
            funnel_id: values.phase.fid,
            id: values.phase.id,
            name: values.phase.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            phase: {
                fid: values.funnel_id,
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const appointmentOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'appointment',
        type: 'appointments'
    }],
    getValues(values) {
        return {
            id: values.appointment.id,
            name: values.appointment.text
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            appointment: {
                id: values.id,
                text: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const appointmentStatusOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'status',
        type: 'dropdown',
        options: appointmentStatus,
        plain: true
    }],

    getValues(values) {
        return values.status;
    },
    parseValues(values) {
        return {
            status: appointmentStatus.find(s => s.id === values)
        };
    }
}];

const appointmentTypeOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: AppConfig.getValue('appointments.types_list', []),
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    parseValues(values) {
        const options = AppConfig.getValue('appointments.types_list', []);

        return {
            type: options.find(o => o.id === values)
        };
    }
}];

const opportunityOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'opportunity',
        type: 'opportunities'
    }],
    getValues(values) {
        return {
            id: values.opportunity.id,
            name: values.opportunity.title
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            opportunity: {
                id: values.id,
                title: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const regionOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'region',
        type: 'regions'
    }],
    getValues(values) {
        return {
            id: values.region.id,
            name: values.region.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            region: {
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const dateOperators = [{
    id: 'equal',
    title: 'Exactly',
    fields: [{
        id: 'date',
        type: 'date'
    }],
    getValues(values) {
        return values.date;
    },
    parseValues(values) {
        return defaultDateParser(values);
    }
}, {
    id: 'less_than',
    title: 'Before',
    fields: [{
        id: 'date',
        type: 'date'
    }],
    getValues(values) {
        return values.date;
    },
    parseValues(values) {
        return defaultDateParser(values);
    }
}, {
    id: 'more_than',
    title: 'After',
    fields: [{
        id: 'date',
        type: 'date'
    }],
    getValues(values) {
        return values.date;
    },
    parseValues(values) {
        return defaultDateParser(values);
    }
}, {
    id: 'between',
    title: 'Between',
    fields: [{
        id: 'start',
        type: 'date'
    }, {
        id: 'end',
        type: 'date'
    }],
    getValues(values) {
        return {
            start: values.start,
            end: values.end
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.start],
            ' and ',
            [values.end]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            start: new Date(values.start),
            end: new Date(values.end)
        };
    }
}, {
    id: 'within_last',
    title: 'Within Last',
    fields: [{
        id: 'counter',
        type: 'number'
    }, {
        id: 'unit',
        type: 'dropdown',
        options: timeUnits
    }],
    getValues(values) {
        return {
            value: values.counter,
            unit: values.unit
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.value],
            [timeUnits.find(t => t.id === values.unit).title]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            counter: values.value,
            unit: timeUnits.find(t => t.id === values.unit)
        };
    }
}, {
    id: 'within_next',
    title: 'Within Next',
    fields: [{
        id: 'counter',
        type: 'number'
    }, {
        id: 'unit',
        type: 'dropdown',
        options: timeUnits
    }],
    getValues(values) {
        return {
            value: values.counter,
            unit: values.unit
        };
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            counter: values.value,
            unit: timeUnits.find(t => t.id === values.unit)
        };
    }
}, {
    id: 'during',
    title: 'During',
    fields: [{
        id: 'span',
        type: 'dropdown',
        options: duringOptions
    }],
    getValues(values) {
        return {
            value: values.span
        };
    },
    getValuesPartsContent(values) {
        const option = duringOptions.find(o => o.id === values.value);
        return option.title;
    },
    parseValues(values) {
        return {
            span: duringOptions.find(o => o.id === values.value)
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const webPageVisitedOperators = [{
    id: 'equal',
    title: 'Exactly',
    fields: [{
        id: 'date',
        type: 'date'
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: values.date
        };
    },
    getValuesPartsContent(values) {
        return `${values.url} ${dateFormat.shortFormatWithYear(dateFormat.parseDate(values.value))}`;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            date: dateFormat.parseDate(values.value),
            url: values.url
        };
    }
}, {
    id: 'less_than',
    title: 'Before',
    fields: [{
        id: 'date',
        type: 'date'
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: values.date
        };
    },
    getValuesPartsContent(values) {
        return `${values.url} ${dateFormat.shortFormatWithYear(dateFormat.parseDate(values.value))}`;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            date: dateFormat.parseDate(values.value),
            url: values.url
        };
    }
}, {
    id: 'more_than',
    title: 'After',
    fields: [{
        id: 'date',
        type: 'date'
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: values.date
        };
    },
    getValuesPartsContent(values) {
        return `${values.url} ${dateFormat.shortFormatWithYear(dateFormat.parseDate(values.value))}`;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            date: dateFormat.parseDate(values.value),
            url: values.url
        };
    }
}, {
    id: 'between',
    title: 'Between',
    fields: [{
        id: 'start',
        type: 'date'
    }, {
        id: 'end',
        type: 'date'
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: {
                start: values.start,
                end: values.end
            }
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.url],
            [dateFormat.shortFormatWithYear(dateFormat.parseDate(values.value.start))],
            ' and ',
            [dateFormat.shortFormatWithYear(dateFormat.parseDate(values.value.end))]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            url: values.url,
            start: new Date(values.value.start),
            end: new Date(values.value.end)
        };
    }
}, {
    id: 'within_last',
    title: 'Within Last',
    fields: [{
        id: 'counter',
        type: 'number'
    }, {
        id: 'unit',
        type: 'dropdown',
        options: timeUnits
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: {
                value: values.counter,
                unit: values.unit
            }
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.url]
            [values.value.value],
            [timeUnits.find(t => t.id === values.value.unit).title]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            url: values.url,
            counter: values.value.value,
            unit: timeUnits.find(t => t.id === values.value.unit)
        };
    }
}, {
    id: 'within_next',
    title: 'Within Next',
    fields: [{
        id: 'counter',
        type: 'number'
    }, {
        id: 'unit',
        type: 'dropdown',
        options: timeUnits
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: {
                value: values.counter,
                unit: values.unit
            }
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.url]
            [values.value.value],
            [timeUnits.find(t => t.id === values.value.unit).title]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            url: values.url,
            counter: values.value.value,
            unit: timeUnits.find(t => t.id === values.value.unit)
        };
    }
}, {
    id: 'during',
    title: 'During',
    fields: [{
        id: 'span',
        type: 'dropdown',
        options: duringOptions
    }, {
        id: 'url',
        type: 'text',
        placeholder: 'http://website_url'
    }],
    getValues(values) {
        return {
            url: values.url,
            value: {
                value: values.span
            }
        };
    },
    getValuesPartsContent(values) {
        const option = duringOptions.find(o => o.id === values.value.value);
        return `${values.url} ${option.title}`;
    },
    parseValues(values) {
        return {
            url: values.url,
            span: duringOptions.find(o => o.id === values.value.value)
        };
    }
}];

const userOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'user',
        type: 'users'
    }],
    getValues(values) {
        return {
            id: values.user.id,
            name: values.user.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            user: {
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const tagOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'tag',
        type: 'tags'
    }],
    getValues(values) {
        return {
            id: values.tag.id,
            name: values.tag.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            tag: {
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const individualOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'individual',
        type: 'individuals'
    }],
    getValues(values) {
        return {
            id: values.individual.id,
            name: values.individual.title
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            individual: {
                id: values.id,
                title: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const periodOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'period',
        type: 'periods'
    }],
    getValues(values) {
        return values.period;
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            period: values
        };
    }
}];

const organizationOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'organization',
        type: 'organizations'
    }],
    getValues(values) {
        return {
            id: values.organization.id,
            name: values.organization.title
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            organization: {
                id: values.id,
                title: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const funnelOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'funnel',
        type: 'funnels'
    }],
    getValues(values) {
        return {
            id: values.funnel.id,
            name: values.funnel.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            funnel: {
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const clusterOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'cluster',
        type: 'clusters'
    }],
    getValues(values) {
        return {
            id: values.cluster.id,
            name: values.cluster.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            cluster: {
                id: values.id,
                name: values.name
            }
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const textOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'text',
        type: 'text'
    }],
    getValues(values) {
        return values.text;
    },
    parseValues(values) {
        return {
            text: values
        };
    }
}, {
    id: 'contains',
    title: 'Contains',
    fields: [{
        id: 'text',
        type: 'text'
    }],
    getValues(values) {
        return values.text;
    },
    parseValues(values) {
        return {
            text: values
        };
    }
}, {
    id: 'starts_with',
    title: 'Begins With',
    fields: [{
        id: 'text',
        type: 'text'
    }],
    getValues(values) {
        return values.text;
    },
    parseValues(values) {
        return {
            text: values
        };
    }
}, {
    id: 'ends_with',
    title: 'Ends With',
    fields: [{
        id: 'text',
        type: 'text'
    }],
    getValues(values) {
        return values.text;
    },
    parseValues(values) {
        return {
            text: values
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const numberOperators = [{
    id: 'equal',
    title: 'Equals',
    fields: [{
        id: 'value',
        type: 'number'
    }],
    getValues(values) {
        return values.value;
    },
    parseValues(values) {
        return {
            value: values
        };
    }
}, {
    id: 'less_than',
    title: 'Less Than',
    fields: [{
        id: 'value',
        type: 'number'
    }],
    getValues(values) {
        return values.value;
    },
    parseValues(values) {
        return {
            value: values
        };
    }
}, {
    id: 'less_than_equal',
    title: 'Less Than Or Equal To',
    fields: [{
        id: 'value',
        type: 'number'
    }],
    getValues(values) {
        return values.value;
    },
    parseValues(values) {
        return {
            value: values
        };
    }
}, {
    id: 'more_than',
    title: 'More Than',
    fields: [{
        id: 'value',
        type: 'number'
    }],
    getValues(values) {
        return values.value;
    },
    parseValues(values) {
        return {
            value: values
        };
    }
}, {
    id: 'more_than_equal',
    title: 'More Than Or Equal To',
    fields: [{
        id: 'value',
        type: 'number'
    }],
    getValues(values) {
        return values.value;
    },
    parseValues(values) {
        return {
            value: values
        };
    }
}, {
    id: 'between',
    title: 'Between',
    fields: [{
        id: 'start',
        type: 'number',
    }, {
        id: 'end',
        type: 'number'
    }],
    getValues(values) {
        return {
            start: values.start,
            end: values.end
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.start],
            ' and ',
            [values.end]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            start: values.start,
            end: values.end
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const checkboxOperators = [{
    id: 'equal',
    title: 'Is True',
    fixedValue: 'true'
} ,{
    id: 'not_equal',
    title: 'Is False',
    fixedValue: 'true',
    not: true
}, {
    id: 'empty',
    title: 'Is Empty',
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const boolOperators = [{
    id: 'equal',
    title: 'Is True',
    noOperator: true
} ,{
    id: 'not_equal',
    title: 'Is False',
    not: true,
    noOperator: true
}];

// this operator is for fields like 'unsubscribed all' that in the FE is presented as 'email opted in'. So if the user set 'email opted in' to true
// the filter should set 'unsubscribed all' to false
const reverseBoolOperators = [{
    id: 'not_equal',
    title: 'Is True',
    not: true,
    noOperator: true
} ,{
    id: 'equal',
    title: 'Is False',
    noOperator: true
}];

const activityTypeOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: activityType,
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    getValuesPartsContent(values) {
        return activityType.find(a => a.id === values).title;
    },
    parseValues(values) {
        return {
            type: activityType.find(a => a.id === values)
        };
    }
}];

const activityTypeExOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: getActivityTypeExList(),
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    getValuesPartsContent(values) {
        return activityTypeEx.find(a => a.id === values).title;
    },
    parseValues(values) {
        return {
            type: activityTypeEx.find(a => a.id === values)
        };
    }
}];

const automationOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'automation',
        type: 'automations',
        populate: 'step' // this means it has to populate the step field once an automation is selected
    }, {
        id: 'step',
        type: 'automationSteps'
    }],
    getValues(values) {
        return {
            automation2: {
                id: values.automation.id,
                name: values.automation.name
            },
            automation2_step: {
                id: values.step.id,
                name: values.step.name
            }
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.automation2.name],
            ' / ',
            [values.automation2_step.name]
        ];
    },
    parseValues(values) {
        if (!values) {
            return;
        }

        return {
            automation: values.automation2,
            step: values.automation2_step
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty',
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const checklistOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'checklist',
        type: 'checklists'
    }],
    getValues(values) {
        return {
            id: values.checklist.id,
            name: values.checklist.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            checklist: values
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const checklistItemOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'checklist',
        type: 'checklists',
        populate: 'item'
    }, {
        id: 'item',
        type: 'checklistItems'
    }],
    getValues(values) {
        return {
            checklist: {
                id: values.checklist.id,
                name: values.checklist.name
            },
            checklist_item: {
                id: values.item.id,
                name: values.item.text
            }
        };
    },
    getValuesPartsContent(values) {
        return [
            [values.checklist.name],
            ' / ',
            [values.checklist_item.name]
        ];
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            checklist: values.checklist,
            item: {
                id: values.checklist_item.id,
                text: values.checklist_item.name
            }
        };
    }
}]

const leadSourceOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'source',
        type: 'leadSources'
    }],
    getValues(values) {
        return {
            id: values.source.id,
            name: values.source.name
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        if (!values) {
            return null;
        }

        return {
            source: values
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const communicationOperators = [{
    id: 'equal',
    title: 'Includes',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: activityType,
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    getValuesPartsContent(values) {
        return activityType.find(a => a.id === values).title;
    },
    parseValues(values) {
        return {
            type: activityType.find(a => a.id === values)
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const campaignOperators = [{
    id: 'any',
    title: 'In',
    fields: [{
        id: 'campaign',
        type: 'campaigns'
    }],
    getValues(values) {
        return {
            campaign: {
                id: values.campaign.id,
                name: values.campaign.name
            }
        };
    },
    getValuesPartsContent(values) {
        return values.campaign.name;
    },
    parseValues(values) {
        return values;
    }
}];

const campaignTypeOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: function() {
            return getCampaignTypeList();
        },
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    parseValues(values) {
        return {
            type: getCampaignTypeList().find(s => s.id === values)
        };
    }
}, {
    id: 'not_equal',
    not: true,
    title: 'Is Not',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: function() {
            return getCampaignTypeList();
        },
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    parseValues(values) {
        return {
            type: getCampaignTypeList().find(s => s.id === values)
        };
    }
}];

const dropdownOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'value',
        type: 'dropdown',
        options: null,
        plain: true
    }],
    getValues(values) {
        return values.value.id;
    },
    getValuesPartsContent(values) {
        return this.fields[0].options.find(o => o.id === values).title;
    },
    parseValues(values) {
        return {
            value: this.fields[0].options.find(o => o.id === values)
        };
    }
}, {
    id: 'not_equal',
    not: true,
    title: 'Is Not',
    fields: [{
        id: 'value',
        type: 'dropdown',
        options: null,
        plain: true
    }],
    getValues(values) {
        return values.value.id;
    },
    getValuesPartsContent(values) {
        return this.fields[0].options.find(o => o.id === values).title;
    },
    parseValues(values) {
        return {
            value: this.fields[0].options.find(o => o.id === values)
        };
    }
}, {
    id: 'contains',
    title: 'Contains',
    fields: [{
        id: 'value',
        type: 'dropdown',
        options: null,
        plain: true
    }],
    getValues(values) {
        return values.value.id;
    },
    getValuesPartsContent(values) {
        return this.fields[0].options.find(o => o.id === values).title;
    },
    parseValues(values) {
        return {
            value: this.fields[0].options.find(o => o.id === values)
        };
    }
}, {
    id: 'not_contains',
    not: true,
    title: 'Not Contains',
    fields: [{
        id: 'value',
        type: 'dropdown',
        options: null,
        plain: true
    }],
    getValues(values) {
        return values.value.id;
    },
    getValuesPartsContent(values) {
        return this.fields[0].options.find(o => o.id === values).title;
    },
    parseValues(values) {
        return {
            value: this.fields[0].options.find(o => o.id === values)
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const currencyOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: function() {
            return getCurrenciesList();
        },
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    parseValues(values) {
        return {
            type: getCurrenciesList().find(s => s.id === values)
        };
    }
}, {
    id: 'not_equal',
    not: true,
    title: 'Is Not',
    fields: [{
        id: 'type',
        type: 'dropdown',
        options: function() {
            return getCurrenciesList();
        },
        plain: true
    }],
    getValues(values) {
        return values.type;
    },
    parseValues(values) {
        return {
            type: getCurrenciesList().find(s => s.id === values)
        };
    }
}, {
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

const opportunityBucketOperators = [{
    id: 'equal',
    title: 'Is',
    fields: [{
        id: 'bucket',
        type: 'dropdown',
        options: function() {
            return app.globalData.buckets.map(b => {
                return {
                    id: b.id,
                    title: b.name
                };
            });
        }
    }],
    getValues(values) {
        return {
            id: values.bucket.id,
            name: values.bucket.title
        };
    },
    getValuesPartsContent(values) {
        return values.name;
    },
    parseValues(values) {
        return {
            bucket: {
                id: values.id,
                title: values.name
            }
        };
    }
}];

const basicOperators = [{
    id: 'empty',
    title: 'Is Empty'
}, {
    id: 'not_empty',
    title: 'Is Not Empty',
    not: true
}];

export default {
    appointmentOperators: appointmentOperators,
    appointmentStatusOperators: appointmentStatusOperators,
    appointmentTypeOperators: appointmentTypeOperators,
    appointmentGroupOperators: createGroupOperators('appointments'),
    opportunityGroupOperators: createGroupOperators('opportunity'),
    individualGroupOperators: createGroupOperators('individuals'),
    opportunityOperators: opportunityOperators,
    tagOperators: tagOperators,
    dateOperators: dateOperators,
    datetimeOperators: dateOperators,
    userOperators: userOperators,
    textOperators: textOperators,
    numberOperators: numberOperators,
    individualOperators: individualOperators,
    organizationOperators: organizationOperators,
    basicOperators: basicOperators,
    regionOperators: regionOperators,
    funnelOperators: funnelOperators,
    clusterOperators: clusterOperators,
    forecastStatusOperators: forecastStatusOperators,
    phaseOperators: phaseOperators,
    checkboxOperators: checkboxOperators,
    boolOperators: boolOperators,
    reverseBoolOperators: reverseBoolOperators,
    periodOperators: periodOperators,
    activityTypeOperators: activityTypeOperators,
    activityTypeExOperators: activityTypeExOperators,
    automationOperators: automationOperators,
    checklistOperators: checklistOperators,
    checklistItemOperators: checklistItemOperators,
    leadSourceOperators: leadSourceOperators,
    communicationOperators: communicationOperators,
    webPageVisitedOperators: webPageVisitedOperators,
    campaignOperators: campaignOperators,
    campaignTypeOperators: campaignTypeOperators,
    dropdownOperators: dropdownOperators,
    currencyOperators: currencyOperators,
    opportunityBucketOperators: opportunityBucketOperators
};
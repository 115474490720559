import $ from 'jquery'
import React from 'react'
import { PieChart, Pie, Cell } from 'recharts';
import html2pdf from 'html2pdf.js'
import canvg from 'canvg'

import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './group-insights.css'

const COLORS = ['#00dba8', '#9200ff', '#ff01ff', '#00d56e', '#03bff2', '#4060f5'];
const NO_VALUE_COLOR = '#636363';

class GroupInsights extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnName: props.columnName,
            activePie: null,
            total: 0,
            data: null
        };

        let requestParams = {
            element_type: this.props.elementType,
            column_id: this.props.columnId
        };

        for (const k in this.props.fetchOptions) {
            if (this.props.fetchOptions[k]) {
                requestParams[k] = this.props.fetchOptions[k];
            }
        }

        const self = this;

        $.get(
            '/widgets/group_insights',
            $.param(requestParams),
            function(response) {
                if (!self.mounted) {
                    return;
                }

                let newState = {
                    data: response.items_total,
                    total: response.total
                };

                if (response.no_value_total) {
                    newState.data.push({
                        name: `No ${self.state.columnName} set`,
                        total: response.no_value_total,
                        isNoValueGroup: true
                    });
                }

                self.setState(newState);
            }
        );
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    downloadAsPdf() {
        if (!this.state.data) {
            return;
        }

        const toExportEl = $(document.createElement('div'));
        $(this.graphEl).clone().appendTo(toExportEl);

        toExportEl.find(`.${style.downloadButton}`).remove();
        toExportEl.find(`.${style.bottom}`).remove();
        toExportEl.find(`.${style.listContent}`).addClass(style.pdfExport);

        const svgEl = $(toExportEl).find('svg');

        svgEl.each(function(idx, node) {
            const parentNode = node.parentNode;
            const svg = parentNode.innerHTML;
            const canvas = document.createElement('canvas');

            canvg(canvas, svg);

            parentNode.removeChild(node);
            parentNode.appendChild(canvas);
        });

        html2pdf(toExportEl[0], {
            filename: 'Field Insights'
        });

        toExportEl.remove();
    }

    render() {
        return (
            <div className={style.groupInsights} ref={(el) => this.graphEl = el}>
                <div className={style.header}>
                    <div className={style.title}>Insights</div>
                    <div
                        className={style.downloadButton}
                        onClick={this.downloadAsPdf.bind(this)}
                    >
                        Download as PDF
                    </div>
                </div>
                {this.state.data ? (
                    <div className={style.content}>
                        {<div className={style.graph}>
                            <PieChart width={375} height={275}>
                                <Pie
                                    data={this.state.data}
                                    cx={187}
                                    cy={125}
                                    innerRadius={90}
                                    outerRadius={120}
                                    fill='#8884d8'
                                    dataKey='total'
                                    isAnimationActive={false}
                                    onMouseEnter={(pie) => {this.setState({activePie: {name: pie.name, percent: `${(pie.percent * 100).toFixed(2)}%`}})}}
                                    onMouseLeave={() => this.setState({activePie: null})}
                                >
                                    {this.state.data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.isNoValueGroup ? NO_VALUE_COLOR : COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                            {this.state.activePie && <div className={style.activePieInfo}>
                                <div className={style.activePercent}>{this.state.activePie.percent}</div>
                                <div className={style.activeName}>{this.state.activePie.name}</div>
                            </div>}
                        </div>}

                        <div className={style.list}>
                            <div className={style.listHeader}>
                                {this.state.columnName}
                            </div>
                            <div className={style.listContent}>
                                {this.state.data.map((entry, index) => {
                                    return (
                                        <div key={`item-${index}`} className={style.listItem}>
                                            <div
                                                className={style.listItemColor}
                                                style={{
                                                    backgroundColor: entry.isNoValueGroup ? NO_VALUE_COLOR : COLORS[index % COLORS.length]
                                                }}
                                            />
                                            <div>{entry.name}</div>
                                            <div className={style.listItemValue}>{entry.total}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={style.listFooter}>
                                Total: {this.state.total}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={style.loading}>
                        <LoadingIndicator/>
                    </div>
                )}
                <div className={style.bottom}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                    >
                        Close
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupInsights;
import Handlebars from 'handlebars';
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'
import backboneSelect2 from 'js/widgets/backbone-select2'
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import DateTimePicker from 'js/widgets/date-time-picker'
import TaskModel from 'js/models/task'
import ActivityModel from 'js/models/activity'
import segmentsCalculator from 'js/utils/segments_calculator'
import dateFormat from 'js/utils/date-format'
import QuickAddAppointmentReact from './quick_add_appointment/quick_add_appointment';
import QuickAddReportReact from './quick_add_report/quick_add_report';
import Utils from 'js/utils/utilities'
import MessageBox from 'js/views/message_box'
import TextManager from 'app/text-manager'

import quickAddTaskTemplate from 'templates/quick_add/quick-add-task.handlebars'
import quickAddNoteTemplate from 'templates/quick_add/quick-add-note.handlebars'
import quickCreateMessageTemplate from 'templates/quick_add/quick-create-message.handlebars'

var typeMap = {
    "individuals": "individual",
    "opportunities": "opportunity",
    "organizations": "organization"
};

var iconMap = {
    "individuals": "icon-user",
    "opportunities": "icon-filter",
    "organizations": "icon-home"
};

var formatResult = function(item) {
    var title = Utils.replaceAll(item.title_highlight, '<br>', ' ');
    var emStart = title.indexOf('<em>');
    let startHtmlStr = '';
    let endHtmlStr = '';

    if (AppConfig.getValue('individualsSelectTagsHoverTaskActivitiesAppointments', false) && item.type === 'individuals' && item.tags && item.tags.length > 0) {
        startHtmlStr = '<div  title="' + item['tags'].join(', ') + '">';
        endHtmlStr = '</div>';
    }

    if (emStart !== -1) {
        var emEnd = title.indexOf('</em>', emStart);

        if (emEnd !== -1) {
            var beg = _.escape(title.substring(0, emStart));
            var mid = _.escape(title.substring(emStart + 4, emEnd));
            var end = _.escape(title.substring(emEnd + 5));

            return startHtmlStr + '<i class="' + iconMap[item.type] + '"></i> ' + `${beg}<em>${mid}</em>${end}` + endHtmlStr;
        }
    }

    return startHtmlStr + '<i class="' + iconMap[item.type] + '"></i> ' + _.escape(title) + endHtmlStr;
};

var QuickAddTask = Marionette.Layout.extend({
    className: 'quick-add-task',
    template: Handlebars.compile(quickAddTaskTemplate),
    templateHelpers: function() {
        var isNew = !this.options.model || !this.options.model.get('id');

        return {
            // default assignee
            assigneeName: this.assignee ? this.assignee.name : app.user.get('name'),
            action: isNew ? 'Create' : 'Save',
            title: isNew ? 'New' : 'Edit',
            hasSubjectSuggestions: !!AppConfig.getValue('quick_add_task.subject_suggestions'),
            enabledFunnels: AppConfig.getValue('tasks.enable_funnel_field', false)
        };
    },
    ui: {
        taskText: '.task-text',
        taskSubject: '.subject-input',
        setDueDate: '.set-due-date',
        tagsInput: '.tags-input',
        funnelsInput: '.funnels-input',
        relatedInput: '.related-input',
        selectRelated: '.select-recent',
        assigneeInput: '.assignee-input',
        subjectInputDropdown: '.subject-input-dropdown',
        selectAssignee: '.select-assignee',
        close: '.close',
        save: '.save-task',
        related: '.related',
        assignee: '.assignee',
        dueDateInput: '.date-input',
        footer: '.textarea-footer',
        dueDateInfo: '.due-date-info',
        dueDateValue: '.date'
    },
    events: {
        'click .select-assignee': function(ev) {
            ev.preventDefault();
            this.ui.assigneeInput.select2('open');
        },
        'click .select-recent': function(ev) {
            ev.preventDefault();
            this.ui.relatedInput.select2('open');
        },
        'click .close': function() {
            this.close();
            vent.trigger('close:quickRegion');
        },
        'click .set-due-date': function() {
            var self = this;
            var dateTimePicker = new DateTimePicker({
                altField: this.ui.dueDateInput,
                time: AppConfig.getValue('quick_add_task.default_time', '11:00'),
                css: {
                    left: self.ui.setDueDate.offset().left + self.ui.setDueDate.width() - 480,
                    top: self.ui.setDueDate.offset().top + self.ui.setDueDate.height() + 10
                }
            });
            dateTimePicker.showPicker();
        },
        'change .date-input': function() {
            this.manageDueDateVisibility(this.ui.dueDateInput.val());
        },
        'click .save-task': function() {
            var self = this;
            var tags = _.map(this.ui.tagsInput.select2('val'), function(tagId) {
                    return {id: tagId};
                });

            var funnels = _.map(this.ui.funnelsInput.select2('val'), function(funnelId) {
                return {id: funnelId};
            });

            var dueDate = this.ui.dueDateInput.val();

            if (!this.task.related_id) {
                MessageBox.showOk({
                    message: TextManager.getText('ID_RELATED_FIELD_REQUIRED_ON_NEW_TASK'),
                    icon: 'icon-warning'
                }, this);
            } else {
                var task = new TaskModel({
                    id: this.taskId,
                    assignee_id: this.task.assignee_id,
                    due_date: dueDate ? new Date(dueDate).toISOString() : '',
                    related_id: this.task.related_id,
                    related_type: this.task.related_type,
                    subject: this.ui.taskSubject.val(),
                    text: this.ui.taskText.val(),
                    tags: tags,
                    funnels: funnels
                });


                const saveTask = () => {
                    task.save(null, {
                        patch: true,
                        success: function() {
                            if (self.options.onTaskCreated) {
                                self.options.onTaskCreated(task);
                            }

                            if (!self.options.model || !self.options.model.get('id')) {
                                vent.trigger('task:created');
                            }

                            if (self.options.model && self.options.model.get('id')) {
                                vent.trigger('task:updated', task);
                                self.close();
                            }
                            else if (self.options.model && self.options.model.get('related')) {
                                vent.trigger('close:quickRegion');
                                vent.trigger('task:updated');
                            }
                            else {
                                if (self.options.gotoRelatedItem) {
                                    vent.trigger('quick:add:goto-releated-item', 'Task', self.task.related_type, self.task.related_id);
                                } else {
                                    self.close();
                                }
                            }
                        }
                    });
                };

                if (AppConfig.getValue('tasks.on_creation.suggested_tags.check_condition', false, this.ui.tagsInput.select2('val'))) {
                    const availableTags = AppConfig.getValue('tasks.on_creation.suggested_tags.tags_list');

                    if (availableTags && availableTags.length > 0) {
                        const self = this;

                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: 'Please ensure one of these tags is added',
                            accept_button_text: 'Save'
                        }, this, function(idsList) {
                            if (idsList.length > 0) {
                                const tags = [...task.get('tags'), ...idsList.map(id => { return { id: id } })];
                                task.set('tags', tags);
                            }

                            saveTask();
                        }, {
                            type: 'tagSelect',
                            availableTags: availableTags
                        }, null, true);
                    } else {
                        saveTask();
                    }
                } else {
                    saveTask();
                }
            }
        },
        'click .delete-due-date': function() {
            this.ui.dueDateInput.val('');
            this.manageDueDateVisibility(null);
        },
        'focus .task-text': function() {
            this.ui.footer.addClass('focus');
        },
        'blur .task-text': function() {
            this.ui.footer.removeClass('focus');
        },
        'mouseenter .task-text': function() {
            this.ui.footer.addClass('hover');
        },
        'mouseleave .task-text': function() {
            this.ui.footer.removeClass('hover');
        },
        'mouseenter .textarea-footer': function() {
            this.ui.taskText.addClass('hover');
        },
        'mouseleave .textarea-footer': function() {
            this.ui.taskText.removeClass('hover');
        },
        'change .tags-input': function(ev) {
            var self = this;

            _.delay(function() {
                var container = self.ui.tagsInput.parent().find('.select2-container.tags-input');
                var containerHeight = container.height();
                var lineHeight = container.find('.select2-search-field').height();
                self.$el.height(self.initialHeight + (containerHeight - lineHeight));
            }, 5);
        },
        'change .funnels-input': function(ev) {
            var self = this;

            _.delay(function() {
                var container = self.ui.funnelsInput.parent().find('.select2-container.funnels-input');
                var containerHeight = container.height();
                var lineHeight = container.find('.select2-search-field').height();
                self.$el.height(self.initialHeight + (containerHeight - lineHeight));
            }, 5);
        },
        'click .title.clickable': function(ev) {
            ev.preventDefault();
            this.ui.subjectInputDropdown.select2('open');
        }
    },
    initialize: function() {
        this.tags = [];
        this.funnels = [];
        if (this.options.model) {
            this.taskId = this.options.model.get('id');
            this.text = this.options.model.get('text');
            this.subject = this.options.model.get('subject');
            this.assignee = this.options.model.get('assignee');
            this.related = this.options.model.get('related');
            if (this.related) {
                this.related.title = this.related.name;
            }
            this.tags = this.options.model.get('tags') ? this.options.model.get('tags') : [];
            this.funnels = this.options.model.get('funnels') ? this.options.model.get('funnels') : [];
            this.dueDate = this.options.model.get('due_date');
        }

        // WHEN CREATING A NEW TASK, APPLY USER PRELAODED TAGS
        if ( ( !this.model || !this.options.model.get('id') ) && this.funnels.length === 0) {
            this.funnels = app.user.getIndividualPreloadedFunnels();
        }

        if ( ( !this.model || !this.options.model.get('id') ) && this.tags.length === 0) {
            this.tags = app.user.getIndividualPreloadedTags();
        }

        this.task = {};
        // default assignee
        this.task.assignee_id = this.assignee ? this.assignee.id : app.user.get('id');
    },
    onRender: function() {
        // init text
        this.ui.taskText.text(this.text || '');
        this.ui.taskSubject.val(this.subject || '');

        // init date
        if (!this.model) {
            var clientPreferences = app.user.get('client').preferences || {};
            if (clientPreferences.tasks_settings) {
                var taskSettings = JSON.parse(clientPreferences.tasks_settings);
                var newDate = new Date(Date.now());

                switch(taskSettings.active_span) {
                    case 'custom':
                        newDate.setDate(newDate.getDate() + taskSettings.custom_days);
                        break;

                    case '1-day':
                    case '1-week':
                    case '14-days':
                        newDate.setDate(newDate.getDate() + {
                            '1-day': 1,
                            '1-week': 7,
                            '14-days': 14
                        }[taskSettings.active_span]);
                        break;

                    case '1-month':
                        newDate.setMonth(newDate.getMonth() + 1);
                        break;

                    case '1-year':
                        newDate.setFullYear(newDate.getFullYear() + 1);
                        break;
                }

                this.dueDate = newDate;
            }
        }

        var date = dateFormat.parseDate(this.dueDate);
        this.ui.dueDateInput.val(date || '');
        this.manageDueDateVisibility(date ? date : null);

        // subject suggestions
        const subjectSuggestions = AppConfig.getValue('quick_add_task.subject_suggestions');

        if (subjectSuggestions) {
            let suggestions = [];

            for (let i = 0; i < subjectSuggestions.length; ++i) {
                suggestions.push({
                    id: `s_${i}`,
                    name: subjectSuggestions[i]
                });
            }

            var subjectSuggestionsSelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.subjectInputDropdown,
                data: suggestions,
                text: 'name',
                options: {
                    containerCssClass: 'select2-block select2-plain',
                    dropdownCssClass: 'popover select2-drop-wider drop-right-aligned'
                }
            });

            this.listenTo(subjectSuggestionsSelect, 'change', function(item) {
                this.ui.taskSubject.val(item.name);
                this.ui.taskSubject.focus();
            }, this);
        }

        // init assignee select
        var defaultAssignee = this.assignee || app.user.toJSON();
        this.ui.assignee.text(defaultAssignee.name);
        this.assigneeSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.assigneeInput,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: defaultAssignee,
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover select2-drop-wider'
            }
        });
        this.listenTo(this.assigneeSelect, 'change', function(item) {
            this.ui.assignee.text(item.name);
            this.task.assignee_id = item.id;
        }, this);

        // init related select
        this.relatedSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.relatedInput,
            url: '/v1/search',
            params: {
                types: 'individuals,organizations,opportunities',
                order_by: 'last_viewed desc',
                additional_fields: 'communications',
            },
            text: 'title',
            value:  this.related || {},
            search: true,
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover select2-drop-wider',
                formatResult: formatResult
            }
        });
        if (this.related) {
            this.ui.related.text(this.related.title);
            this.task.related_id = this.related.id;
            this.task.related_type = this.related.related_type;
        }
        this.listenTo(this.relatedSelect, 'change', function(item) {
            this.ui.related.text(item.title);
            this.task.related_id = item.id;
            this.task.related_type = typeMap[item.type];
        }, this);

        // init tags select
        var self = this;

        _.defer(function() {
            self.initialHeight = self.$el.height();
            self.ui.taskText.focus();

            self.tagSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.tagsInput,
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: 'Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            self.funnelSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.funnelsInput,
                id: 'id',
                text: 'name',
                url: '/funnels',
                search: true,

                options: {
                    placeholder: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return `Type to add a ${TextManager.getText('ID_FUNNEL')}`;
                    },
                    tokenSeparators: [',']
                }
            });
            self.funnelSelect.setValue(self.funnels);

            self.tagSelect.setValue(self.tags);
        });
    },
    onShow: function() {
        this.$el.parent().attr('id', 'quick-add-task-modal');
    },
    manageDueDateVisibility: function(dueDate) {
        var hasValidDate = !!dueDate;

        if (hasValidDate) {
            this.ui.dueDateValue.text(dateFormat.shortFormatWithYear(dueDate));
            this.ui.dueDateInfo.attr('title', dateFormat.shortFormatWithYearTime(dueDate)).tooltip('fixTitle');

            const today = new Date();
            const endDate = new Date(dueDate)

            if (endDate.getTime() < today.getTime()) {
                this.ui.dueDateInfo.addClass('past-date-input');
            } else {
                this.ui.dueDateInfo.removeClass('past-date-input');
            }
        }

        this.ui.tagsInput.toggleClass('narrow', hasValidDate);
        this.ui.dueDateInfo.toggleClass('hide', !hasValidDate);
        this.ui.setDueDate.toggleClass('hide', hasValidDate);
    }
});

var QuickCreateMessage = Marionette.Layout.extend({
    className: 'quick-create-message',
    template: Handlebars.compile(quickCreateMessageTemplate),
    templateHelpers: function() {
        return {
            maxLength: app.globalData.smsInfo.messageMaxLength
        };
    },
    ui: {
        messageText: '.message-text',
        footer: '.textarea-footer',
        charactersLeft: '.characters-left',
        selectTemplate: '.select-sms-template',
        templateInput: '.sms-template-input',
    },
    events: {
        'keyup .message-text': function(e) {
            this.updateMessageInfo(e.target.value)
        },
        'click .close': function() {
            this.close();
            vent.trigger('close:quickRegion');
        },
        'click .send-message': function() {
            var message = this.ui.messageText.val();

            if (message) {
                var self = this;
                $.post('/send_message', JSON.stringify({
                    'trigger_type': 'individual',
                    'trigger_id': this.options.individualId,
                    'to_number': this.options.phoneNumber,
                    'from_id': app.user.get('id'),
                    'from_type': 'user',
                    'check_unsubscription': AppConfig.getValue('quick_message.check_unsubscription'),
                    'body': message
                    }))
                    .done(function(response) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Message sent',
                                    classes: 'success',
                                    timer: 3000
                                };
                            }
                        });

                        self.close();
                        vent.trigger('close:quickRegion');
                    })
                    .fail(function(response) {
                        var error = JSON.parse(response.responseText)
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: `Error sending the message: ${error.detail.message}`,
                                    classes: 'load-error error',
                                    timer: 3000
                                };
                            }
                        });
                    })
            }
        },
        'focus .message-text': function() {
            this.ui.footer.addClass('focus');
        },
        'blur .message-text': function() {
            this.ui.footer.removeClass('focus');
        },
        'mouseenter .message-text': function() {
            this.ui.footer.addClass('hover');
        },
        'mouseleave .message-text': function() {
            this.ui.footer.removeClass('hover');
        },
        'mouseenter .textarea-footer': function() {
            this.ui.messageText.addClass('hover');
        },
        'mouseleave .textarea-footer': function() {
            this.ui.messageText.removeClass('hover');
        },
        'click .select-sms-template': function(ev) {
            ev.preventDefault();
            this.ui.templateInput.select2('open');
        },
    },
    onShow: function() {
        this.ui.messageText.focus();

        let url;

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5741')) { // new campaigns section
            url = '/campaigns?rows=-1&status=ready&campaign_type=message&campaign_subtype=template';
        } else {
            url = '/campaigns?rows=-1&status=ready,draft&campaign_type=message&search=template';
        }

        this.templateSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.templateInput,
            url: url,
            options: {
                placeholder: "Select template SMS",
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover'
            }
        });
        this.listenTo(this.templateSelect, 'change', function(item) {
            this.ui.messageText.val(item.content);
            this.updateMessageInfo(item.content);
        }, this);
    },
    updateMessageInfo: function(content) {
        var segCalculator = new segmentsCalculator(content, 'auto');
        var updateTextArea = false;

        if (segCalculator.getEncodingName() === 'UCS-2') {
            for (var i = 0; i < content.length; ++i) {
                var char = Utils.unicodeToGsm(content.charCodeAt(i));

                if (char) {
                    content = content.substr(0, i) + char + content.substr(i + 1)
                    updateTextArea = true;
                }
            }
        }

        if (updateTextArea) {
            var selectionStart = this.ui.messageText.prop('selectionStart');
            var selectionEnd = this.ui.messageText.prop('selectionEnd');

            this.ui.messageText.val(content);
            this.ui.messageText.prop('selectionStart', selectionStart);
            this.ui.messageText.prop('selectionEnd', selectionEnd);
        }

        var numCharactersLeft = app.globalData.smsInfo.messageMaxLength - content.length;
        var info = `${numCharactersLeft} character${numCharactersLeft !== 1 ? 's' : ''} left`;

        var segCalculator = new segmentsCalculator(content, 'auto');
        var numSegments = segCalculator.numSegments;

        info += ` | ${numSegments} segment${numSegments !== 1 ? 's' : ''} (${segCalculator.getEncodingName()})`;

        this.ui.charactersLeft.text(info);
    }
});

var QuickAddAppointment = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'quick-add-appointment-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <QuickAddAppointmentReact
                parent={this}
                {...this.options || {}}
            />,
            this.$el.get(0)
        );
    }
});

var QuickAddReport = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'quick-add-report-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <QuickAddReportReact
                parent={this}
                {...this.options || {}}
            />,
            this.$el.get(0)
        );
    }
});

var QuickAddNote = Marionette.Layout.extend({
    className: 'quick-add-note',
    template: Handlebars.compile(quickAddNoteTemplate),
    templateHelpers: function() {
        return {
            funnelsEnabled: AppConfig.getValue('activities.notes.enable_funnel_field', false)
        }
    },
    ui: {
        noteText: '.note-text',
        setDueDate: '.set-due-date',
        tagsInput: '.tags-input',
        funnelsInput: '.funnels-input',
        relatedInput: '.related-input',
        selectRelated: '.select-recent',
        close: '.close',
        save: '.save-note',
        related: '.related',
        assignee: '.assignee',
        dueDateInput: '.date-input',
        addTask: '.add-task',
        footer: '.textarea-footer'
    },
    events: {
        'click .select-recent': function(ev) {
            ev.preventDefault();
            this.ui.relatedInput.select2('open');
        },
        'click .close': function() {
            if (this.options.onNoteCancelled) {
                this.options.onNoteCancelled();
            }

            vent.trigger('close:quickRegion');
        },
        'click .set-due-date': function() {
            if (this.ui.dueDateInput.val()) {
                this.ui.dueDateInput.val('');
                this.ui.dueDateInput.trigger('change');
            }
            else {
                this.ui.dueDateInput.datepicker('show');
            }
        },
        'change .date-input': function() {
            if (this.ui.dueDateInput.val()) {
                this.ui.setDueDate.attr('title', 'Due ' + this.ui.dueDateInput.val()).tooltip('fixTitle').addClass('value-set');
            }
            else {
                this.ui.setDueDate.attr('title', 'Set due date').tooltip('fixTitle').removeClass('value-set');
            }
        },
        'click .add-task': function() {
            this.note.add_task = !this.note.add_task;
        },
        'click .save-note': function() {
            if (!this.note.url_type) {
                $(this.ui.selectRelated).addClass('validation_error');
                return;
            }
            var self = this;
            var tags = _.map(this.ui.tagsInput.select2('val'), function(tagId) {
                    return {id: tagId};
                });
            
            var funnels = _.map(this.ui.funnelsInput.select2('val'), function(funnelId) {
                return {id: funnelId};
            });

            // if the note has a parent activity, the note should be merged with it
            if (this.options.parentActivity) {
                var pact = this.options.parentActivity;

                pact.save({
                    activity_type: pact.get('activity_type'),
                    note: `${pact.get('note')} - ${this.ui.noteText.val()}`,
                    params: {
                        'more_info': this.ui.noteText.val()
                    }
                }, {
                    patch: true,
                    success: function() {
                        if (self.options.onNoteCreated) {
                            self.options.onNoteCreated(note);
                        }

                        vent.trigger('update:activity');

                        if (self.options.related.id){
                            vent.trigger('close:quickRegion');
                        }else {
                            vent.trigger('quick:add:goto-releated-item', 'Note', pact.get('related_type'), pact.get('related_id'));
                        }
                    }
                });
            } else {
                var note = new ActivityModel({
                    add_task: !!this.note.add_task,
                    target_date: this.ui.dueDateInput.val() || null,
                    note: this.ui.noteText.val(),
                    activity_type: 'note',
                    tags: tags,
                    funnels: funnels
                });

                const saveNote = () => {
                    note.save(null, {
                        url: '/' + this.note.url_type + '/' + this.note.url_id + '/activities/',
                        success: function() {
                            if (self.options.onNoteCreated) {
                                self.options.onNoteCreated(note);
                            }

                            if (self.options.related && self.options.related.id){
                                vent.trigger('close:quickRegion');
                            }else {
                                vent.trigger('quick:add:goto-releated-item', 'Note', self.note.related_type, self.note.related_id);
                            }
                        }
                    });
                };

                if (AppConfig.getValue('activities.notes.on_creation.suggested_tags.check_condition', false, this.ui.tagsInput.select2('val'))) {
                    const availableTags = AppConfig.getValue('activities.notes.on_creation.suggested_tags.tags_list');

                    if (availableTags && availableTags.length > 0) {
                        const self = this;

                        MessageBox.showOk({
                            icon: 'icon-warning',
                            message: 'Please ensure one of these tags is added',
                            accept_button_text: 'Save'
                        }, this, function(idsList) {
                            if (idsList.length > 0) {
                                const tags = [...note.get('tags'), ...idsList.map(id => { return { id: id } })];
                                note.set('tags', tags);
                            }

                            saveNote();
                        }, {
                            type: 'tagSelect',
                            availableTags: availableTags
                        }, null, true);
                    } else {
                        saveNote();
                    }
                } else {
                    saveNote();
                }
            }
        },
        'focus .note-text': function() {
            this.ui.footer.addClass('focus');
        },
        'blur .note-text': function() {
            this.ui.footer.removeClass('focus');
        },
        'mouseenter .note-text': function() {
            this.ui.footer.addClass('hover');
        },
        'mouseleave .note-text': function() {
            this.ui.footer.removeClass('hover');
        },
        'mouseenter .textarea-footer': function() {
            this.ui.noteText.addClass('hover');
        },
        'mouseleave .textarea-footer': function() {
            this.ui.noteText.removeClass('hover');
        },
        'change .tags-input': function(ev) {
            var self = this;

            _.delay(function() {
                var container = self.ui.tagsInput.parent().find('.select2-container.tags-input');
                var containerHeight = container.height();
                var lineHeight = container.find('.select2-search-field').height();
                self.$el.height(self.initialHeight + (containerHeight - lineHeight));
            }, 5);
        },
        'change .funnels-input': function(ev) {
            var self = this;

            _.delay(function() {
                var container = self.ui.tagsInput.parent().find('.select2-container.funnels-input');
                var containerHeight = container.height();
                var lineHeight = container.find('.select2-search-field').height();
                self.$el.height(self.initialHeight + (containerHeight - lineHeight));
            }, 5);
        }
    },
    initialize: function() {
        this.note = {};
        this.tags = [];
        this.funnels = [];

        if (this.options.related) {
            this.related = {
                id: this.options.related.id,
                type: this.options.related.type,
                title: this.options.related.name
            };
        }

        if (this.options.funnels && this.options.funnels.length > 0) {
            this.funnels = this.options.funnels;
        } else {
            this.funnels = app.user.getIndividualPreloadedFunnels();
        }

        if (this.options.tags && this.options.tags.length > 0) {
            this.tags = this.options.tags;
        } else {
            this.tags = app.user.getIndividualPreloadedTags();
        }
    },
    onRender: function() {
        // init date select
        this.ui.dueDateInput.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            dateFormat: 'M dd, yy' // TODO: i18n
        });
        this.ui.setDueDate.attr('title', 'Set due date').tooltip('fixTitle');

        // init related select
        this.relatedSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.relatedInput,
            url: '/v1/search',
            params: {
                types: 'individuals,organizations,opportunities',
                order_by: 'last_viewed desc'
            },
            text: 'title',
            value: this.related || {},
            search: true,
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'popover select2-drop-wider',
                formatResult: formatResult
            }
        });
        if (this.related) {
            this.ui.related.text(this.related.title);
            this.note.related_id = this.related.id;
            this.note.related_type = typeMap[this.related.type];
            this.note.url_id = this.related.id;
            this.note.url_type = this.related.type;
        }
        this.listenTo(this.relatedSelect, 'change', function(item) {
            this.ui.related.text(item.title);
            this.note.url_id = item.id;
            this.note.url_type = item.type;
            this.note.related_id = item.id;
            this.note.related_type = typeMap[item.type];
        }, this);

        // init tags select
        var self = this;

        _.defer(function() {
            self.ui.noteText.focus();
            self.initialHeight = self.$el.height();

            self.tagSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.tagsInput,
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: 'Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            self.tagSelect.setValue(self.tags);

            self.funnelSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.funnelsInput,
                id: 'id',
                text: 'name',
                url: '/funnels',
                search: true,

                options: {
                    placeholder: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return `Type to add a ${TextManager.getText('ID_FUNNEL')}`;
                    },
                    tokenSeparators: [',']
                }
            });

            self.funnelSelect.setValue(self.funnels);

        });
    }
});

export default {
    Task: QuickAddTask,
    Note: QuickAddNote,
    Message: QuickCreateMessage,
    Appointment: QuickAddAppointment,
    Report: QuickAddReport,
}
import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import WebStatsReducedView from 'js/views/web_stats_reduced'
import socialMediaTemplate from 'templates/social_media.handlebars'


export default Marionette.Layout.extend({
    className: 'widget social-stats-widget',
    template: Handlebars.compile(socialMediaTemplate),
    regions: {
        widgetContainer: '.social-stats-content'
    },

    initialize: function() {
        var series = ['facebook_posts', 'tweets_retweets'];
        // webstats reduced is currently hardcoded at 4.
        var seriesNames = ['Posts', 'Tweets', '', ''];
        var view = this;

        this.stats = new WebStatsReducedView({
            filtersEnabled: false,
            seriesNames: seriesNames,
            series: series
        });

        this.listenTo(this.stats, 'setFilter', function(name, value) {
            view.trigger('setFilter', name, value);
        });
    },

    onRender: function() {
        this.widgetContainer.show(this.stats);
        this.stats.$el.attr('class', 'social-stats');
    },

    fetchData: function(filters) {
        var view = this;

        var filterBy = [];
        if (filters.timespan && filters.timespan.length) {
            filterBy.push({
                attribute: 'timefilter',
                value: filters.timespan[0].period
            });

            if (filters.timespan[0].ts) {
                var offset = (new Date()).getTimezoneOffset() * 60000;
                filterBy.push({
                    attribute: 'timets',
                    value: filters.timespan[0].ts + offset
                });
            }
        }

        if (filters.segments && filters.segments.length) {
            filterBy.push({
                attribute: 'segmentation',
                value: {
                    'page-views': 'hits',
                    'visits': 'visitors',
                    'returning': 'returning',
                    'registered': 'registered'
                }[filters.segments[0].type]
            });
        }

        if (filters.content) {
            _.each(filters.content, function(d) {
                filterBy.push({
                    attribute: (d.type === 'page') ? 'pagefilter' : 'source',
                    value: d.url
                });
            });
        }

        if (filters.location) {
            _.each(filters.location, function(d) {
                filterBy.push({
                    attribute: 'location',
                    value: d.code
                });
            });
        }

        var url = [];
        _.each(filterBy, function (filter) {
            var value = filter.value;
            if (value === undefined) {
                return;
            }
            url.push('filter=' + encodeURIComponent(filter.attribute + (filter.op || '=') + value));
        });

        $.get(
            '/widgets/social_media',
            url.join('&'),
            function(data) {
                if (!view.isClosed) {
                    view.updateData(data.time, data.segmentation);
                }
            }
        );
    },
    updateData: function(time, segmentation) {
        this.stats.updateData(time, segmentation);
    },
    updateFilters: function(filters) {
        this.stats.updateFilters(filters);
    }
});

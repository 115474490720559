import BaseModel from 'js/models/base'


var RelatedFile = BaseModel.extend({
    name: 'RelatedFile',
    urlRoot: '/related_files',
    apiType: 'salesseek.contacts.models.relatedFile.RelatedFile'
});

export default RelatedFile;

import React from 'react';
import ReactDOM from 'react-dom';

import Table from 'app_v2/components/table/table';
import app from 'js/app';
import { NewSelect } from 'js/react_views/widgets/select';
import pdfGenerator from './pdf_generator';
import OpportunityFilterModel from 'js/models/opportunity_filter';

import style from './price_lists.css';


const TABLE_COLUMNS = [{
    id: 'houseType',
    title: 'House Type',
    type: 'text',
    width: 105
}, {
    id: 'plot',
    title: 'Plot',
    type: 'text',
    width: 70
}, {
    id: 'description',
    title: 'Description',
    type: 'text',
    editable: true
}, {
    id: 'expectedCompletation',
    title: 'Expected Completion',
    type: 'text',
    editable: true,
    width: 190
}, {
    id: 'sqft',
    title: 'SQ FT',
    type: 'text',
    width: 70
}, {
    id: 'price',
    title: 'Price',
    type: 'currency',
    width: 105,
    editable: true
}, {
    id: 'favorite',
    title: 'Favorite',
    type: 'bool',
    width: 85,
    editable: true
}];

export class DevelopmentSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { item, onClick, onMouseEnter } = this.props;
        let codeNumber = null;

        if (item.type == 'regions') {
            codeNumber = item.integration_data?.region_id;
        } else if (!item.isCluster) {
            codeNumber = item.item.integration_data?.coinsCode;
        }

        return (
            <li
                className={`
                    ${style.developmentSelectItem}
                    ${item.main ? style.dsiMain : ''}
                    ${item.borderBottom ? style.dsiBorderBottom : ''}
                    ${this.state.selected ? style.dsiSelected : ''}
                `}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                <div>{item.name}</div>

                {codeNumber &&
                    <div className={style.dsiCodeNumber}>{codeNumber}</div>
                }
            </li>
        );
    }
}


class PriceLists extends React.Component {
    constructor(props) {
        super(props);

        this.schemes = [{
            id: 'partExchange',
            title: 'Part Exchange'
        }, {
            id: 'homeChange',
            title: 'Home Change'
        }, {
            id: 'bankMumDad',
            title: 'Bank of Mum & Dad'
        }, {
            id: 'depositBoost',
            title: 'Deposit Boost'
        }, {
            id: 'armedForces',
            title: 'Armed Forces/Key Worker Discount'
        }, {
            id: 'firstHome',
            title: 'First Home'
        }, {
            id: 'depositUnlock',
            title: 'Deposit Unlock'
        }, {
            id: 'staircase',
            title: 'Staircase'
        }, {
            id: 'heyloReachFlex',
            title: 'Heylo Reach Flex'
        }, {
            id: 'ownNewRate',
            title: 'Own New Rate Reducer'
        }, {
            id: 'ownNewDeposit',
            title: 'Own New Deposit Drop'
        }];

        this.rows = [];
        this.cache = {};
        this.descriptionCFId = null;
        this.expectedCompletationCfId = null;
        this.pdfStyle = 'persimmon';

        this.state = {
            advisorName: '',
            phone: '',
            email: '',
            openingTimes: '',
            address: '',
            selectedSchemes: {},
            inputFocused: null,
            rowsSelected: [],
            allRowsSelected: false,
            regionId: null,
            developmentId: null,
            developmentName: '',
            phaseId: null
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData() {
        if (!this.state.phaseId && !this.state.developmentId) {
            this.tableComponent.setData([], 0, 0, 0);
        } else {
            const customFields = app.globalData.customFieldsInfo.opportunitiesArray;
            const cfid = (name) => customFields.find(cf => cf.name.toLowerCase() === name.toLowerCase())?.id;
            const filter = new OpportunityFilterModel();
            const dealTypeCf = customFields.find(cf => cf.name.toLowerCase() === 'deal type');
            const self = this;

            this.tableComponent.setLoading(true);

            let availablePhasesIds = [];

            if (this.state.phaseId) {
                availablePhasesIds.push(app.globalData.phasesInfo.phases.find(p => p.funnel_id === this.state.phaseId && p.name.toLowerCase() === 'available plots')?.id);
            } else {
                const funnels = app.globalData.funnelsInfo.funnels.filter(f => f.cluster_id === this.state.developmentId && f.region_id === this.state.regionId);

                for (const funnel of funnels) {
                    availablePhasesIds.push(app.globalData.phasesInfo.phases.find(p => p.funnel_id === funnel.id && p.name.toLowerCase() === 'available plots')?.id);
                }
            }

            availablePhasesIds = availablePhasesIds.filter(id => !!id);

            const phasesFilter = [];

            for (const pid of availablePhasesIds) {
                phasesFilter.push({
                    field: 'opportunity_phase_id',
                    operator: 'equal',
                    values: {
                        id: pid
                    }
                });
            }

            filter.save({
                rules: [[{
                    field: 'opportunity_custom',
                    custom: dealTypeCf.id,
                    operator: 'equal',
                    values: dealTypeCf.optionsArray.find(o => o.value.toLowerCase() === 'plot').id
                }],
                    phasesFilter
                ]
            }, {
                alert: false,
                success: (fresult) => {
                    $.get(`/opportunities?by_filter_id=${fresult.id}&rows=-1`, (result) => {
                        self.rows = [];

                        self.descriptionCFId = cfid('Price List Property Description');
                        self.expectedCompletationCfId = cfid('Price Lists Expected Completion');
                        const houseTypeCfId = cfid('House Type');
                        const unitNoCfId = cfid('Unit NO.');
                        const areaFt2CfId = cfid('Area (ft2)');

                        for (const row of result) {
                            const bucket = row.buckets.find(b => b.name.toLowerCase() === 'gross release price');

                            self.rows.push({
                                id: row.id,
                                houseType: row[`custom_field.${houseTypeCfId}`] || '',
                                plot: row[`custom_field.${unitNoCfId}`] || '',
                                description: row[`custom_field.${self.descriptionCFId}`] || '',
                                expectedCompletation: row[`custom_field.${self.expectedCompletationCfId}`] || '',
                                sqft: row[`custom_field.${areaFt2CfId}`] || '',
                                price: bucket?.value || 0,
                                favorite: false
                            });
                        }

                        self.pdfStyle = 'persimmon';

                        if (result.length > 0) {
                            self.pdfStyle = result[0].funnel?.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';
                        }

                        self.tableComponent.setData(self.rows, 0, 0, self.rows.length);
                        self.cache[self.state.phaseId] = JSON.parse(JSON.stringify(self.rows));
                        self.tableComponent.setLoading(false);
                    });
                }
            });
        }
    }

    toggleScheme(schemeId) {
        const selectedSchemes = this.state.selectedSchemes;

        selectedSchemes[schemeId] = !selectedSchemes[schemeId];

        this.setState({
            selectedSchemes: selectedSchemes
        });
    }

    handleRowSelectionChange(rowSelection) {
        this.setState({
            rowsSelected: rowSelection.rows.map(r => r.id),
            allRowsSelected: rowSelection.page !== null
        });
    }

    handleRemove() {
        if (this.state.allRowsSelected) {
            this.rows = [];
        } else if (this.state.rowsSelected.length > 0) {
            this.rows = this.rows.filter(r => this.state.rowsSelected.indexOf(r.id) === -1);
        }

        this.tableComponent.handleUnselectAll();
        this.tableComponent.setData(this.rows, 0, 0, this.rows.length);
    }

    handleSaveAsPdf() {
        const salesInfo = {
            advisorName: this.state.advisorName,
            phone: this.state.phone,
            email: this.state.email,
            openingTimes: this.state.openingTimes,
            address: this.state.address
        };

        const schemes = [];

        for (const scheme of this.schemes) {
            if (this.state.selectedSchemes[scheme.id]) {
                schemes.push(scheme);
            }
        }

        const finalRows = [...this.rows.filter(r => r.favorite), ...this.rows.filter(r => !r.favorite)];

        if (finalRows.length > 0) {
            pdfGenerator(salesInfo, this.state.developmentName, schemes, finalRows, this.pdfStyle);
        }
    }

    handleRegionSelect(items) {
        const regionId = items[0].id;
        const self = this;

        const goOn = () => {
            self.setState({
                regionId: regionId
            });

            self.developmentsComponent.setData(self.cache[regionId], null);
            self.developmentsComponent.setValue(null);
        }

        if (regionId in this.cache) {
            goOn();
        } else {
            $.get(`/clusters?region_id=${regionId}&rows=-1`, (result) => {
                let developments = [];
                const clusterById = {};

                for (const cluster of result) {
                    const clusterData = {
                        isCluster: true,
                        id: cluster.id,
                        name: cluster.name,
                        item: cluster,
                        phases: []
                    }

                    clusterById[cluster.id] = clusterData;

                    developments.push(clusterData);
                }

                // funnels related to the cluster/region
                for (const funnel of app.globalData.funnelsInfo.funnels) {
                    if (funnel.cluster_id) {
                        if (funnel.cluster_id in clusterById) {
                            clusterById[funnel.cluster_id].phases.push({
                                id: funnel.id,
                                name: funnel.name,
                                item: funnel
                            });
                        }
                    } else if (funnel.region_id && funnel.region_id === regionId) {
                        developments.push({
                            id: funnel.id,
                            name: funnel.name,
                            item: funnel
                        });
                    }
                }

                developments = developments.filter(d => !d.isCluster || d.phases.length > 0);

                const plainData = [];

                for (let i = 0; i < developments.length; ++i) {
                    const d = developments[i];

                    if (d.isCluster) {
                        plainData.push({
                            id: d.id,
                            name: d.name,
                            main: true,
                            item: d.item,
                            isCluster: true,
                            borderBottom: true
                        });

                        for (const p of d.phases) {
                            plainData.push({
                                id: p.id,
                                name: p.name,
                                item: p.item,
                                borderBottom: i < developments.length - 1
                            });
                        }
                    } else {
                        plainData.push({
                            id: d.id,
                            name: d.name,
                            main: true,
                            item: d.item,
                            borderBottom: i < developments.length - 1
                        });
                    }
                }

                self.cache[regionId] = plainData;
                goOn();
            });
        }
    }

    handleDevelopmentSelect(items) {
        const newState = {
            developmentId: null,
            developmentName: '',
            phaseId: null,
            address: '',
            openingTimes: ''
        };

        const development = items ? items[0] : null;

        if (development) {
            newState.developmentName = development.name;

            if (development.isCluster) {
                newState.developmentId = development.id;
            } else {
                newState.phaseId = development.id;

                if (development.item?.location?.address) {
                    newState.address = development.item.location.address;
                }

                if (development?.item?.marketing_details?.opening_hours) {
                    const openingTimes = [];

                    for (const day in development.item.marketing_details.opening_hours) {
                        openingTimes.push(`${day}: ${development.item.marketing_details.opening_hours[day]}`);
                    }

                    newState.openingTimes = openingTimes.join('; ');
                }
            }
        }

        this.setState(newState);

        const self = this;

        _.defer(() => {
            self.fetchData();
        });
    }

    handleCellValueEdited(rowId, columnId, value) {
        let cfId = null;

        if (columnId === 'description' && this.descriptionCFId) {
            cfId = this.descriptionCFId;
        } else if (columnId === 'expectedCompletation' && this.expectedCompletationCfId) {
            cfId = this.expectedCompletationCfId;
        }

        if (cfId) {
            $.ajax({
                type: 'PATCH',
                url: `/opportunities/${rowId}`,
                data: JSON.stringify({
                    [`custom_field.${cfId}`]: value
                }),
                contentType: 'application/json',
                dataType: 'json'
            });
        }
    }

    render() {
        const schemesGroups = [
            this.schemes.slice(0, 6),
            this.schemes.slice(6)
        ];

        return (
            <div className={style.priceLists}>
                <div className={style.header}>
                    <div
                        className={style.hBox}
                        style={{fontSize: '18px'}}
                        onClick={this.props.onClose}
                    >
                        <div className='icon-cross'/>
                    </div>

                    <div className={style.hTitle}>Price List</div>

                    <div
                        className={style.hBox}
                        style={{marginLeft: 'auto'}}
                        onClick={this.handleSaveAsPdf.bind(this)}
                    >
                        <div className='icon-floppy-disk'/>
                    </div>
                </div>

                <div className={style.sectionsContainer}>
                    <div className={style.inputSection}>
                        <div className={style.fieldsRow}>
                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Region</div>

                                <div style={{width: '200px'}}>
                                    <NewSelect
                                        data={app.globalData.regions}
                                        text='name'
                                        width={300}
                                        placeholder='Select a region'
                                        onSelect={this.handleRegionSelect.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Development</div>

                                <div style={{width: '425px'}}>
                                    <NewSelect
                                        ref={(el) => this.developmentsComponent = el}
                                        data={[]}
                                        text='name'
                                        width={425}
                                        itemView={DevelopmentSelectItem}
                                        disabled={!this.state.regionId}
                                        placeholder='Select a development'
                                        onSelect={this.handleDevelopmentSelect.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={style.fieldsRow}>
                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Sales Advisor Name</div>
                                <input
                                    className={`
                                        ${style.fiValue}
                                        ${this.state.inputFocused === 'advisorName' ? style.vFocused : ''}
                                    `}
                                    value={this.state.advisorName}
                                    onChange={(ev) => this.setState({ advisorName: ev.target.value })}
                                    onFocus={() => this.setState({ inputFocused: 'advisorName' })}
                                    onBlur={() => this.setState({ inputFocused: null })}
                                />
                            </div>

                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Phone Number</div>
                                <input
                                    className={`
                                        ${style.fiValue}
                                        ${this.state.inputFocused === 'phone' ? style.vFocused : ''}
                                    `}
                                    value={this.state.phone}
                                    onChange={(ev) => this.setState({ phone: ev.target.value })}
                                    onFocus={() => this.setState({ inputFocused: 'phone' })}
                                    onBlur={() => this.setState({ inputFocused: null })}
                                />
                            </div>

                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Email</div>
                                <input
                                    className={`
                                        ${style.fiValue}
                                        ${this.state.inputFocused === 'email' ? style.vFocused : ''}
                                    `}
                                    value={this.state.email}
                                    onChange={(ev) => this.setState({ email: ev.target.value })}
                                    onFocus={() => this.setState({ inputFocused: 'email' })}
                                    onBlur={() => this.setState({ inputFocused: null })}
                                />
                            </div>
                        </div>

                        <div className={style.fieldsRow}>
                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Address</div>
                                <input
                                    className={`
                                        ${style.fiValue}
                                        ${style.vWider}
                                        ${this.state.inputFocused === 'address' ? style.vFocused : ''}
                                    `}
                                    placeholder='E.g Pleasant Avenue, Fauxton, AB3 4CD'
                                    value={this.state.address}
                                    onChange={(ev) => this.setState({ address: ev.target.value })}
                                    onFocus={() => this.setState({ inputFocused: 'address' })}
                                    onBlur={() => this.setState({ inputFocused: null })}
                                />
                            </div>

                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Opening Times</div>
                                <input
                                    className={`
                                        ${style.fiValue}
                                        ${style.vWider}
                                        ${this.state.inputFocused === 'openingTimes' ? style.vFocused : ''}
                                    `}
                                    placeholder='E.g Thursday to Monday - 10am- 5pm'
                                    value={this.state.openingTimes}
                                    onChange={(ev) => this.setState({ openingTimes: ev.target.value })}
                                    onFocus={() => this.setState({ inputFocused: 'openingTimes' })}
                                    onBlur={() => this.setState({ inputFocused: null })}
                                />
                            </div>
                        </div>

                        <div className={style.fieldsRow}>
                            <div className={style.fieldInput}>
                                <div className={style.fiTitle}>Select schemes:</div>

                                {schemesGroups.map((schemeGroup, sgidx) => {
                                    return (
                                        <div
                                            key={`sg_${sgidx}`}
                                            className={style.fieldsRow}
                                            style={{marginTop: sgidx === 0 ? 0 : '10px'}}
                                        >
                                            {schemeGroup.map(scheme => {
                                                return (
                                                    <div
                                                        key={scheme.id}
                                                        className={style.fiCheckbox}
                                                        onClick={() => this.toggleScheme.bind(this)(scheme.id)}
                                                    >
                                                        <div
                                                            className={`
                                                                ${style.cBox}
                                                                ${this.state.selectedSchemes[scheme.id] ? style.bChecked : ''}
                                                            `}
                                                        >
                                                            <div className={`${style.bMark} icon-checkmark3`}/>
                                                        </div>

                                                        <div className={style.cTitle}>
                                                            {scheme.title}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className={style.resultsSection}>
                        <div className={style.rToolbar}>
                            <div
                                className={`
                                    ${style.tButton}
                                    ${this.state.rowsSelected.length > 0 || this.state.allRowsSelected ? style.bVisible : ''}
                                `}
                                onClick={this.handleRemove.bind(this)}
                            >
                                Remove
                            </div>
                        </div>

                        <Table
                            ref={(el) => this.tableComponent = el}
                            columns={TABLE_COLUMNS}
                            rows={[]}
                            selectableRows={true}
                            rowsClickables={false}
                            onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                            onCellValueEdited={this.handleCellValueEdited.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const PriceListsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'price-lists-modal');
        this.$el.css('height', '100%');
    },
    onRender: function() {
        ReactDOM.render(
            <PriceLists
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default PriceListsView;
import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import Bucket from 'js/models/bucket.js'
import BucketsCollection from 'js/collections/buckets.js'
import FunnelsCollection from 'js/collections/funnels.js'
import TextManager from 'app/text-manager'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import MessageBox from 'js/views/message_box.js'
import importFieldGroupTemplate from 'templates/settings/import_fieldgroup.handlebars'
import importFieldTemplate from 'templates/settings/import_field.handlebars'
import concatenatedColumnTemplate from 'templates/settings/import_concatenated_column.handlebars'
import importCODTemplate from 'templates/settings/import_COD.handlebars'


var fieldItem = Backbone.Model.extend({
    getValue: function(){
        return {'ss_field':this.get('tag'), 'csv_field':this.selected_csvfield};
    },
    isMapped: function(){
        if (this.selected_csvfield !== undefined && this.selected_csvfield !== '') {
            return true;
        }
        return false;
    }
});

var field_collection = Backbone.Collection.extend({
    model: fieldItem
});

var groupItem = Backbone.Model.extend({
});

var group_collection = Backbone.Collection.extend({
    model: groupItem
});

var ConcatenatedColumnView = Marionette.ItemView.extend({
    template: Handlebars.compile(concatenatedColumnTemplate),
    tagName: 'li',
    className: 'concatenated-column',
    ui:{
        columnName: ".column-name",
        checkmark: '.checkmark'
    },
    events: {
        'click .add-concatenated-button:first': 'onAddColumn',
        'click .remove-concatenated-button:first': 'onRemoveColumn',
        'click .checkmark:first': function() {
            this.fieldSelect.setValue(null);
            this.ui.columnName.trigger('change');
        }
    },
    onRender: function() {
        var view = this,
            mapped_field = this.model.get('mapped_field');

        if(mapped_field !== undefined) {
            this.model.selected_csvfield = mapped_field;
        }

        this.fieldSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.columnName,
            text: 'text',
            data: this.options.commonData.csv_fields,
            options: {
                placeholder: 'Select column',
                dropdownCssClass: 'popover',
                allowClear: true
            }
        });
        this.ui.columnName.on('change', function() {
            var field = view.ui.columnName.val();
            if(field) {
                view.model.selected_csvfield = field;
            } else {
                delete view.model.selected_csvfield;
            }
            view.setSelected(field);
        });

        this.$el.find('[data-toggle=tooltip]').tooltip({ container: this.$el });

        if(this.model.selected_csvfield){
            this.setSelected(this.model.selected_csvfield);
        }
    },
    setSelected: function(field) {
        this.$el.toggleClass('has-value', field && field.length > 0);
        this.fieldSelect.setValue(field);

        this.trigger('onSave');
    },
    onAddColumn: function() {
        this.trigger('onAddColumn');
    },
    onRemoveColumn: function() {
        var view = this;

        this.$el
            .animate({
                opacity: 0
            }, {
                queue: false,
                duration: 100
            })
            .slideUp(200, 'easeOutQuad', function() {
                $(this).trigger('resize');
                view.trigger('onRemoveColumn');
            });
    },
    focusField: function() {
        this.ui.columnName.select2('focus');
    }
});

var ConcatenatedColumnsView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'concatenated-columns',
    itemView: ConcatenatedColumnView,
    itemViewOptions: function() {
        return {
            commonData: this.options.commonData
        };
    },
    onBeforeRender: function() {
        // Override method for animating new items
        this.onBeforeItemAdded = function(){};
    },
    onRender: function() {
        // Animate new items on show
        this.onBeforeItemAdded = function(view) {
            view.on('show', function() {
                view.$el
                    .css('opacity', 0)
                    .show(200, function(){
                        view.$el.trigger('resize');
                    })
                    .animate(
                        { opacity: 1 },
                        { queue: false, duration: 400 }
                    );
                view.focusField();
            });
        };
    },
    getMapping: function() {
        var result = [];
        _.each(this.collection.models, function(model){
            if(model.isMapped()) {
                result.push(model.getValue());
            }
        });
        if (result.length === 0){
            return null;
        }
        return result;
    },
    getMappedFields: function() {
        var fields = [];
        _.each(this.collection.models, function(model){
            if(model.selected_csvfield !== undefined) {
                fields.push(model.selected_csvfield);
            }
        });
        return fields;
    }
});

var fieldItemView = Marionette.Layout.extend({
    template: Handlebars.compile(importFieldTemplate),
    tagName: 'li',
    ui:{
        btnDelete: ".remove-button",
        columnName: ".column-name",
        mapping_preview_container: '.mapping-preview-container:first',
        mapping_preview: '.mapping-preview:first',
        checkmark: '.checkmark'
    },
    regions:{
        concatenatedRegion: '.concatenated-columns-container'
    },
    events: {
        'click .add-button:first': 'onAdd',
        'click .remove-button:first': 'onRemove',
        'click .toggle-preview:first': 'togglePreview',
        'click .checkmark:first': function() {
            this.fieldSelect.setValue(null);
            this.ui.columnName.trigger('change');
        }
    },
    togglePreview: function() {
        var v = this.ui.mapping_preview_container.is(':visible');

        this.$el.toggleClass('preview-visible', !v);

        this.ui.mapping_preview_container
            .animate({
                opacity: v ? 0 : 1
            }, {
                queue: false,
                duration: 200
            })
            .slideToggle(v ? 200 : 400, v ? 'easeOutQuad' : 'easeOutBack', function() {
                $(this).trigger('resize');
            });
    },
    hidePreview: function() {
        this.ui.mapping_preview_container
            .animate({
                opacity: 0
            }, {
                queue: false,
                duration: 200
            })
            .slideUp(200, 'easeOutQuad', function() {
                $(this).trigger('resize');
            });

        this.$el.removeClass('preview-visible');
    },
    onRender: function () {
        var view = this;

        if (this.model.get('concatenated') && !this.concatenatedColumnsView) {
            var mapped_fields = this.model.get('mapped_field');
            this.concatenatedColumns = new field_collection();
            this.concatenatedColumnsView = new ConcatenatedColumnsView({
                collection: this.concatenatedColumns,
                commonData: this.options.commonData
            });

            this.listenTo(this.concatenatedColumnsView, 'itemview:onSave', this.onSave);
            this.listenTo(this.concatenatedColumnsView, 'itemview:onAddColumn', this.onAddColumn);
            this.listenTo(this.concatenatedColumnsView, 'itemview:onRemoveColumn', this.onRemoveColumn);
            this.listenTo(this.concatenatedColumns, 'add remove', function() {
                this.$el.toggleClass('has-concatenated', this.concatenatedColumns.models.length > 1);
            });

            if(mapped_fields) {
                _.each(mapped_fields, function(field){
                    view.addColumn(field.csv_field);
                });
            }else {
                this.onAddColumn();
            }

            this.$el.toggleClass('has-concatenated', this.concatenatedColumns.models.length > 1);

            this.concatenatedRegion.show(this.concatenatedColumnsView);
        } else {
            this.model.selected_csvfield = this.model.get('mapped_field');

            this.fieldSelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.columnName,
                text: 'text',
                data: this.options.commonData.csv_fields,
                options: {
                    placeholder: 'Select column',
                    dropdownCssClass: 'popover',
                    allowClear: true
                }
            });
            this.ui.columnName.on('change', function() {
                var field = view.ui.columnName.val();


                if (!field) {
                    delete view.model.selected_csvfield;
                    view.model.unset('mapped_field');
                } else {
                    view.model.selected_csvfield = field;
                }
                view.setSelected(field);
            });

            //If is extra field add the delete button
            if (this.model.get('isExtraField')) {
                this.$el.addClass('extra-field');
            }

            if(this.model.selected_csvfield){
                this.setSelected(this.model.selected_csvfield);
            }

        }

        if(this.model.get("multiple")){
            this.$el.addClass('multiple-field');
        }

        if(this.model.get("mandatory")){
            this.$el.addClass('required-field');
        }
    },
    setSelected: function(field) {
        this.updateMappingPreview(field);
        this.fieldSelect.setValue(field);
        this.ui.checkmark.toggleClass('bounce-in', (field && field.length > 0));
        this.$el.toggleClass('has-value', (field && field.length > 0));
        if(!this.options.commonData.reloading) {
            this.trigger('onSave');
        }
    },
    updateMappingPreview: function(fields) {
        var label = this.model.get('text'),
            result = [],
            temp = Handlebars.compile([
                '{{#each rows}}',
                    '<li>',
                        '<span class="label">' + label + '</span>',
                        '<span class="value">{{this}}</span>',
                    '</li>',
                '{{/each}}'
                ].join(''));


        if(typeof(fields) === 'string') {
            if(fields === '') {
                this.hidePreview();
                return;
            }
            fields = [fields];
        }else if(fields.length===0){
            this.hidePreview();
            return;
        }

        var view = this;

        _.each(this.options.commonData.sample_data.rows, function(row){
            var cat = '';
            _.each(fields, function(field){
                var index = view.options.commonData.sample_data.headers.indexOf(field);
                cat += row[index]+' ';
            });
            result.push(cat);
        });

        this.ui.mapping_preview.html(temp({rows: result}));
    },
    onAdd: function() {
        this.trigger('onAdd');
    },
    onRemove: function() {
        var view = this;

        this.$el
            .animate({
                opacity: 0
            }, {
                queue: false,
                duration: 200
            })
            .slideUp(200, 'easeOutQuad', function() {
                $(this).trigger('resize');
                view.trigger('onRemove');
            });
    },
    onSave: function() {
        if (this.model.get('concatenated')) {
            var mappedFields = this.concatenatedColumnsView.getMappedFields();
            this.ui.checkmark.toggleClass('bounce-in', mappedFields.length!==0);
            this.$el.toggleClass('has-value', mappedFields.length!==0);
            this.updateMappingPreview(mappedFields);
        }
        this.trigger('onSave');
    },
    onAddColumn: function() {
        this.addColumn();
    },
    onRemoveColumn: function(itemView) {
        this.concatenatedColumns.remove(itemView.model);
        if(itemView.model.isMapped()){
            this.onSave();
        }
    },
    addColumn: function(csv_field) {
        var newItem = JSON.parse(JSON.stringify(this.model)),
            newModel = new fieldItem(newItem);

        if(csv_field !== undefined) {
            newModel.selected_csvfield = csv_field;
        }else {
            delete newModel.selected_csvfield;
        }
        newModel.unset('mapped_field');
        newModel.isConcatenated = true;
        this.concatenatedColumns.add(newModel);
    },
    focusField: function() {
        if (this.model.get('concatenated')) {
            this.concatenatedColumnsView.children.findByIndex(0).focusField();
        } else {
            this.ui.columnName.select2('focus');
        }
    },
    getMapping: function(){
        if (this.model.get('concatenated')) {
            var value = this.concatenatedColumnsView.getMapping();
            if(value !== null) {
                return {ss_field: this.model.get('tag'), csv_field: value};
            }
        }else if (this.model.isMapped()) {
            return this.model.getValue();
        }
        return null;
    }
});

var fieldColView = Marionette.CollectionView.extend({
    itemView: fieldItemView,
    itemViewOptions: function() {
        return {
            commonData: this.options.commonData
        };
    },
    tagName: 'ul',
    initialize: function (options) {
        this.listenTo(this,"itemview:onAdd",this.onAddField);
        this.listenTo(this,"itemview:onRemove",this.onRemoveField);
        //this.listenTo(this,"itemview:onSave",this.onSave);
    },
    onRender: function() {
        this.$el.trigger('resize');
    },
    onAddField: function(itemView){
        var newItem = itemView.model.clone(),
            tag = newItem.get('tag').split('@')[0],
            position = this.collection.indexOf(itemView.model)+1,
            count = _.countBy(this.collection.models, function(model){
                return model.get('tag').indexOf(tag+'@') === 0;
            }).true;

        newItem.set('tag', tag+'@'+(count+1));
        newItem.set('isExtraField', true);
        this.collection.add(newItem,{
                at : position
        });
        this.render();
        this.children.findByModel(newItem).focusField();
    },
    onRemoveField: function(itemView){
        var tag = itemView.model.get('tag'),
            count = 1;

        this.collection.remove(itemView.model);
        //If its a multiple field renumber them as needed
        if (tag.indexOf('@')!==-1){
            tag = tag.split('@')[0];
            _.each(this.collection.models, function(model){
                if(model.get('tag') === tag || model.get('tag').indexOf(tag+'@')===0){
                    model.set('tag',tag+'@'+count);
                    count++;
                }
            });
        }
    },
    getMapping: function(){
        var result = [];
        //Get mapped fields
        this.children.each(function(child){
            var value = child.getMapping();
            if(value !== null){
                result.push(value);
            }
        });
        return result;
    }
});

var groupItemView = Marionette.Layout.extend({
    template: Handlebars.compile(importFieldGroupTemplate),
    tagName: 'li',
    ui:{
        btnDelete: ".remove-button",
        columnName: ".column-name",
        checkmark: '.checkmark'
    },
    regions:{
        fieldsRegion: '.fields-container'
    },
    events: {
        'click .add-button:first': 'onAdd',
        'click .remove-button:first': 'onRemove'
    },
    onRender: function () {
        var mapped_fields = [],
            group_name = this.model.get('tag'),
            mapped_group = _.find(this.options.commonData.import_data.mapping, function(group){
                return group.group === group_name;
            });

        if(mapped_group !== undefined) {
            mapped_fields = mapped_group.children;
        }

        if(this.model.get('multiple')){
            this.$el.addClass('multiple-field');
        }

        //If is extra field add the delete button
        if(this.model.get('isExtraGroup')){
            this.$el.addClass('extra-field');
        }

        var fields = this.model.get('children');

        //Add current mapping to the fields already mapped
        _.each(fields, function(field){
            var mapped_field = _.find(mapped_fields, function(mapped_field){
                return mapped_field.ss_field === field.tag;
            });
            if(mapped_field !== undefined) {
                field.mapped_field = mapped_field.csv_field;
            }
        });

        var fieldCol = new field_collection(fields);
        this.fieldColView = new fieldColView({
            collection: fieldCol,
            mapped_fields: mapped_fields,
            commonData: this.options.commonData
        });
        this.listenTo(this.fieldColView,'itemview:onSave', this.onSave);

        this.fieldsRegion.show(this.fieldColView);
    },
    onAdd: function() {
        this.trigger('onAdd');
    },
    onRemove: function() {
        var view = this;

        this.$el
            .animate({
                opacity: 0
            }, {
                queue: false,
                duration: 200
            })
            .slideUp(200, 'easeOutQuad', function() {
                $(this).trigger('resize');
                view.trigger('onRemove');
            });
    },
    onSave: function() {
        this.trigger('onSave');
    },
    getMapping: function() {
        var result = this.fieldColView.getMapping();
        return result;
    }
});

var groupColView = Marionette.CollectionView.extend({
    itemView: groupItemView,
    itemViewOptions: function() {
        return {
            commonData: this.options.commonData
        };
    },
    tagName: 'ul',
    initialize: function () {
        this.listenTo(this,"itemview:onAdd",this.onAddGroup);
        this.listenTo(this,"itemview:onRemove",this.onRemoveGroup);
        this.listenTo(this,"itemview:onSave",this.onSave);
    },
    onRender: function() {
        this.$el.trigger('resize');
    },
    onAddGroup: function(itemView){
        var newItem = JSON.parse(JSON.stringify(itemView.model)),
            newGroupItem = new groupItem(newItem),
            tag = newGroupItem.get('tag').split('@')[0],
            position = this.collection.indexOf(itemView.model)+1,
            count = _.countBy(this.collection.models, function(model){
                return model.get('tag').indexOf(tag) === 0;
            }).true;

        this.options.commonData.reloading = true;

        _.each(newGroupItem.get('children'), function(childField){
            delete childField.mapped_field;
        });
        newGroupItem.set('tag', tag+'@'+count);
        newGroupItem.set('isExtraGroup', true);
        this.collection.add(newGroupItem,{
            at : position
        });
        this.render();

        this.options.commonData.reloading = false;
    },
    onRemoveGroup: function(itemView){
        var tag = itemView.model.get('tag'),
            count = 1;

        this.collection.remove(itemView.model);
        //If its a multiple field renumber them as needed
        if (tag.indexOf('@')!==-1){
            tag = tag.split('@')[0];
            _.each(this.collection.models, function(model){
                if(model.get('tag') === tag || model.get('tag').indexOf(tag+'@')===0){
                    model.set('tag',tag+'@'+count);
                    count++;
                }
            });
        }
        this.onSave();
    },
    onSave: function(){
        this.trigger('onSave');
    },
    getMapping: function(){
        var result = [],
            remapped = {};
        //Get mapped fields
        this.children.each(function(itemView){
            var value = itemView.getMapping();
            if (value.length!==0) {
                result.push({group: itemView.model.get('tag'), children: value});
            }
        });

        //Renumber multiple fields if needed (case middle ones are missing)
        _.each(result, function(item){
            var tag = item.group,
                simple_tag = tag.split('@')[0];

            if(tag.indexOf('@')!==-1 && remapped[simple_tag] === undefined){
                var count = 0;
                remapped[simple_tag]=true;
                _.each(result, function(item2){
                    if(item2.group.indexOf(simple_tag)===0){
                        if(count===0){
                            item2.group=simple_tag;
                        }else{
                            item2.group=simple_tag+'@'+count;
                        }
                        count++;
                    }
                });
            }
        });

        return result;
    }
});

export default Marionette.Layout.extend({
    template: Handlebars.compile(importCODTemplate),
    ui: {
        scroll: '.content-container > .content',
        slides: '#import-slides .slide',
        fetching: '#import-fetching',
        pending: '#import-pending',
        processing: '#import-processing',
        progressBar: '#import-processing .inner-bar',
        progressPercent: '#import-processing .percent',
        upload: '#import-upload',
        mapping: '#import-mapping',
        finished: '#import-finished',
        failed: '#import-failed',
        mappingTable: '.mapping-table',
        forceUpdate: '#force-update-checkbox',
        files: '#select-file',
        drop_area: '.drop-action-area',
        data_field_list: '.data-field-list',
        valid_rows: '.valid-rows-total',
        invalid_rows: '.invalid-rows-total',
        downloadForm: '.download-form',
        save_status: '.save-status'
    },
    regions: {
        mappingFields: '.mapping-fields'
    },
    events: {
        'click .btn-import': 'onImport',
        'click .btn-delete': 'onDeleteImport',
        'click .download-template': function() {
            var hiddenIFrameID = 'hiddenDownloader',
                iframe = document.getElementById(hiddenIFrameID);
            if (iframe === null) {
                iframe = document.createElement('iframe');
                iframe.id = hiddenIFrameID;
                iframe.style.display = 'none';
                document.body.appendChild(iframe);
            }
            iframe.src = this.csv_template;
        },
        'click .download-csv': function(){
            this.ui.downloadForm.submit();
        },
        'click .dismiss': 'onInvalidDismiss',
        'click .import-reset': 'onImportReset',
        'drop .drop-action-area': function(e) {
            e.stopPropagation();
            e.preventDefault();
            this.upload(e.originalEvent.dataTransfer.files);
        },
        'change #select-file': function() {
            var files = this.ui.files[0].files;

            this.upload(files);
        },
        'click .toggle-data-fields': function(ev) {
            var link = $(ev.currentTarget);

            this.ui.data_field_list.find( 'dt, dd').not('.required-field')
                .slideToggle(300)
                .promise().done(function() {
                    var expanded = $(this[0]).is(':visible'),
                    linkText = expanded ? 'Minimise fields' : 'Show all fields';
                    link.text(linkText);
                });
        },
        'resize': 'scrollbar',
        'change #force-update-checkbox': function(ev) {
            this.commonData.import_data.force_update = $(ev.currentTarget).is(':checked');
            this.updateImport(this.commonData.import_data);
        }
    },
    url: '/import',
    initialize: function() {
        this.commonData = {
            import_data: {},
            sample_data: {},
            csv_fields: [],
            reloading: false
        };

        this.errorTitleByException = {
            ImportLocationRequiresOrganizationError: TextManager.parseText('${ID_ORGANIZATION, capitalize} Required')
        };
    },
    onRender: function() {
        this.showFetching();
        this.fetchImports();

        this.preventDropOnBody = $('body').on('dragover drop', function(e) {
            e.stopPropagation();
            e.preventDefault();
        });

        // Drag and drop solution for firefox bug
        // See: http://stackoverflow.com/a/10310815/1712562
        $.fn.draghover = function() {
            return this.each(function() {
                var collection = $(),
                self = $(this);

                self.on('dragenter', function(e) {
                    if (collection.length === 0) {
                        self.trigger('draghoverstart');
                    }
                    collection = collection.add(e.target);
                });

                self.on('dragleave drop', function(e) {
                    collection = collection.not(e.target);
                    if (collection.length === 0) {
                        self.trigger('draghoverend');
                    }
                });
            });
        };

        this.ui.drop_area.draghover().on({
            'draghoverstart': function() {
                $(this).addClass('dragover');
            },
            'draghoverend': function() {
                $(this).removeClass('dragover');
            }
        });

        // Update scrollbar
        this.scrollbar();
        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    close: function() {
        this.preventDropOnBody.unbind();
    },
    fetchImports: function() {
        var view = this;

        $.ajax({
            url: this.url,
            data: {
                rows: 1
            },
            success: function(data) {
                var item = data[0],
                    bucketsDef,
                    customFieldsDef,
                    funnelsDef;

                if (item) {
                    view.commonData.import_data = item;
                    if (item.confirmed === true) {
                        if (item.status === 'pending'){
                            view.showPending(item);
                        }
                        else if (item.status === 'in_progress'){
                            view.showProcessing(item);
                        }
                        else if (item.status === 'mapping'){
                            if (view.import_type === 'deals') {
                                bucketsDef = view.fetchBuckets();
                                funnelsDef = view.fetchFunnels();
                            }
                            else {
                                bucketsDef = ($.Deferred()).resolve();
                                funnelsDef = ($.Deferred()).resolve();
                            }
                            customFieldsDef = view.fetchCustomFields();

                            $.when(bucketsDef, funnelsDef, customFieldsDef).done(function() {
                                view.showMapping();
                            });
                        }
                        else if (item.status === 'finished'){
                            view.showFinished(item);
                        }
                        else if (item.status === 'failed'){
                            view.showFailed(item);
                        }
                    } else {
                        view.showConfirmDialog(item);
                    }
                } else {
                    view.showUpload();
                }

                view.scrollbar();
            }
        });
    },
    fetchBuckets: function() {
        var view = this;

        this.buckets = new BucketsCollection();
        return this.buckets.fetch({
            success: function () {
                view.buckets.fetched = true;
            }
        });
    },
    fetchFunnels: function() {
        var view = this;

        this.funnels = new FunnelsCollection();
        return this.funnels.fetch({
            success: function() {
                view.funnels.fetched = true;
            }
        });
    },
    fetchCustomFields: function() {
        var view = this;

        view.customFields = new CustomFieldsCollection();
        return view.customFields.fetch({
            filterBy: [{
                attribute: 'view',
                value: view.import_type
            }],
            success: function() {
                view.customFields = new CustomFieldsCollection(view.customFields.filter(m => m.get('type') !== 'list'));
                view.customFields.fetched = true;
            }
        });
    },
    hideSlides: function(){
        this.ui.slides.hide();
    },
    showSlide: function(el) {
        this.ui.slides.stop().hide(0);
        el.fadeIn(600);
        this.scrollbar();
    },
    showFetching: function() {
        this.showSlide(this.ui.fetching);
    },
    showUpload: function() {
        this.showSlide(this.ui.upload);
    },
    showMappingCommon: function() {
        var view = this;
        this.commonData.sample_data = this.commonData.import_data.sample_data;
        this.showSlide(this.ui.mapping);

        this.ui.forceUpdate.prop('checked', this.commonData.import_data.force_update);

        //Set table values
        // var cols = _.map(sample_data.headers,function(row){return {"sTitle": row};});

        this.commonData.csv_fields = _.map(this.commonData.sample_data.headers,function(row){return {text: row, id:row};});

        // if(typeof(view.dataTable) !== 'undefined') {
        //     view.dataTable.fnUpdate(sample_data.rows, cols);
        // } else {
        //     view.dataTable = this.ui.mappingTable.dataTable({
        //         aaData: sample_data.rows,
        //         aoColumns: cols,
        //         bFilter: false,
        //         bPaginate: false,
        //         bInfo: false
        //     });
        // }

        //Clone all fields
        view.allFields = JSON.parse(JSON.stringify(view.base_fields));
        //Append fields for the custom fields
        var customfields=[];
        _.each(this.customFields.models, function(customField){
            customfields.push({
                text: customField.get('name'),
                tag: 'custom_field.' + customField.get('id'),
                mandatory: customField.get('required')
            });
        });

        //If you have buckets (you are a deal, map them)
        var bucketfields=[];
        if(typeof(view.buckets) !== 'undefined') {
            _.each(view.buckets.models, function(bucket){
                var name = bucket.get('name');
                bucketfields.push({text: name, tag: name});
            });
        }
        if(bucketfields.length!==0)
            view.allFields.push({group:'Buckets', tag:'buckets', children: bucketfields});

        if(customfields.length!==0)
            view.allFields.push({group:'Custom Fields', tag:'custom', children: customfields});

        //Check mapping so if there are 'multiple' fields mapped add them before render
        _.each(this.commonData.import_data.mapping, function(mapped_group){
            if(mapped_group.group.split('@').length===2){
                var name = mapped_group.group.split('@')[0],
                    original = _.find(view.allFields, function(field){
                        return field.tag === name;
                    }),
                    position = view.allFields.indexOf(original),
                    //Hack to clone the object
                    cloned = JSON.parse(JSON.stringify(original));

                cloned.tag = mapped_group.group;
                cloned.isExtraGroup = true;
                view.allFields.splice(position+1,0,cloned);
            }
        });

        this.commonData.reloading = true;
        //Set fields container
        var groupCol = new group_collection(this.allFields);
        this.groupColView = new groupColView({
            collection: groupCol,
            commonData: this.commonData
        });

        this.mappingFields.show(this.groupColView);
        this.listenTo(this.groupColView,'onSave',this.onSaveMapping);
        this.scrollbar();
        this.commonData.reloading = false;
    },
    showPending: function(item) {
        this.showSlide(this.ui.pending);

        if (item.confirmed) {
            setTimeout(this.fetchImports.bind(this), 5*1000);
        }
    },
    showProcessing: function(item) {
        if (this.ui.processing.is(':hidden')) {
            this.showSlide(this.ui.processing);
        }

        var percent = Math.round(100 * item.rows_offset / item.rows_total);

        this.ui.progressBar.css('width', percent + '%');
        this.ui.progressPercent.text(percent);

        if (item.confirmed) {
            setTimeout(this.fetchImports.bind(this), 5*1000);
        }
    },
    showFinished: function(item) {
        this.showSlide(this.ui.finished);
        this.ui.valid_rows.text(item.import_result.valid_rows);
        this.ui.invalid_rows
            .text(item.import_result.invalid_rows)
            .closest('.alert')
            .toggleClass('hide', (item.import_result.invalid_rows <= 0));
        this.ui.downloadForm.attr('action','api/imports/'+item.id+'?csv');
    },
    showFailed: function(item) {
        if (item.import_result) {
            this.ui.failed.find('.error-message').text(item.import_result.error_message).show();
        }
        this.showSlide(this.ui.failed);
    },

    upload: function(files) {
        var formData = new FormData(),
            view = this;
        formData.append('file', files[0]);

        this.ui.upload.addClass('uploading');

        $.ajax({
            type: 'POST',
            url: this.url,
            contentType: false,
            processData: false,
            data: formData,
            success: function(data) {
                view.showConfirmDialog(data);
            },
            error: function() {
                view.showErrorDialog('Upload failed!');
            }
        });
    },
    showConfirmDialog: function(data) {
        var view = this;
        this.showPending(data);

        this.ui.upload.removeClass('uploading');

        var mbContent = {
            icon: 'icon-checkmark',
            message: '<h4>Upload successful!</h4>' +
                '<p class="cta">Are you sure you want to import this data?</p>',
            accept_button_text: 'Continue',
            cancel_button_text: 'Cancel'
        };

        MessageBox.showYesNo(
            mbContent,
            this,
            function() {
                $.ajax({
                    type: 'POST',
                    url: view.url + '/' + data.id + '/confirm',
                    complete: function() {
                        view.showFetching();
                        view.fetchImports();
                    }
                });
            }, //Yes
            function() {
                $.ajax({
                    type: 'DELETE',
                    url: view.url + '/' + data.id,
                    complete: function() {
                        view.showFetching();
                        view.fetchImports();
                    }
                });
            } //No
        );
    },
    showErrorDialog: function(title, message) {
        title = title || 'Error';
        message = message || 'Please make sure you are using the correct file type.';

        var mbContent = {
            icon: 'icon-warning',
            message: '<h4>'+title+'</h4>' +
                '<p>' + message + '</p>'
        };

        this.ui.upload.removeClass('uploading');

        MessageBox.showOk(mbContent, this);
    },
    onSaveMapping: function(){
        var mapping = this.groupColView.getMapping();
        if(mapping !== null){
            this.commonData.import_data.mapping = mapping;
        }

        this.updateImport(this.commonData.import_data);
    },
    updateImport: function(data) {
        var view = this;

        this.ui.save_status
            .text('Saving...')
            .show();

        $.ajax({
            type: 'PATCH',
            url: this.url + '/' + data.id,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
            success: function(a,b,c) {
                view.ui.save_status.text('Mapping saved');
            },
            error: function() {
                view.showErrorDialog('Autosave failed!');
            }
        });
    },
    onImport: function() {
        var view = this,
            mapping = this.groupColView.getMapping();

        if(mapping !== null){
            this.commonData.import_data.mapping = mapping;
        }

        this.commonData.import_data.finish_mapping = true;
        $.ajax({
            type: 'PATCH',
            url: this.url + '/' + this.commonData.import_data.id,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(this.commonData.import_data),
            success: function(a,b,c) {
                view.ui.save_status.hide();
                view.showFetching();
                view.fetchImports();
            },
            error: function(data) {
                var json = JSON.parse(data.responseText);
                var message = json.detail.message;
                var title = view.errorTitleByException[json.detail.exception] || 'Import error!';

                view.showErrorDialog(title, message);
            }
        });
    },
    onDeleteImport: function(){
        var view = this;
        var mbContent = {
            accept_is_negative: true,
            icon: 'icon-trashcan',
            message: '<h4>Restart Import</h4>' +
                '<p class="cta">All data for this import will be deleted. Are you sure you want to continue?</p>',
            accept_button_text: 'Restart',
            cancel_button_text: 'Cancel'
        };

        MessageBox.showYesNo(
            mbContent,
            this,
            function() {
                $.ajax({
                    type: 'DELETE',
                    url: view.url + '/' + view.commonData.import_data.id,
                    complete: function() {
                        view.ui.save_status.hide();
                        view.showFetching();
                        view.fetchImports();
                    }
                });
            }, //Yes
            function() {
            } //No
        );
    },
    onInvalidDismiss: function(){
        this.$el.find('.invalid-rows')
            .animate({
                opacity: 0
            }, {
                duration: 200,
                queue: false
            })
            .slideUp(400, function() {
                $(this)
                    .addClass('hide')
                    .removeAttr('style');
            });
    },
    onImportReset: function() {
        var view = this;

        $.ajax({
            type: 'DELETE',
            url: view.url + '/' + view.commonData.import_data.id,
            complete: function() {
                view.ui.save_status.hide();
                view.showFetching();
                view.fetchImports();
            }
        });
    },
    scrollbar: function() {
        if (this.isClosed) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.round(container.height()),
            contentHeight = Math.ceil(this.ui.scroll.find('.content-inner:first').height());

        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    },
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item'
import PaginatedListView from 'js/views/base/paginated_list'
import ListNavbarView from 'js/views/base/list_navbar'
import BaseToolbarView from 'js/views/base/toolbar'
import MessageBox from 'js/views/message_box'
import ContentFileModel from 'js/models/content_file'
import Utilities from 'js/utils/utilities'
import app from 'js/app'
import security from 'js/utils/security'
import vent from 'js/vent'


var FileItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(
        '<td>' +
        '   <ul class="rf-item">' +
        '       <li class="table-list-thumb">'+
        '           <div class="thumb rf-thumb">' +
        '               <div class="placeholder-image">' +
        '                   <i class="{{icon}}"></i>' +
        '              </div>' +
        '              <i class="rf-arrow-down icon-arrow-down" data-toggle="tooltip" title data-original-title="Download"></i>' +
        '           </div>' +
        '       </li>' +
        '       <li class="table-list-info">' +
        '           <div class="rf-name">{{name}}</div>' +
        '       </li>' +
        '       <form class="download-form hide" method="POST" action="{{downloadAction}}"></form>' +
        '       {{#if canBeDeleted}}' +
        '           <li class="rf-delete-button">' +
        '           <button type="button" class="button button-red" id="delete">Delete</button>' +
        '       {{/if}}' +
        '       </li>' +
        '       <li class="table-list-thumb {{currentUserClass}}">' +
        '           <div class="thumb" data-toggle="tooltip" data-placement="right" title data-original-title="{{authorName}}">' +
        '               {{#if authorPhoto}}' +
        '                   <div class="profile-image" style="background-image: url(\'{{authorPhoto}}\');"></div>' +
        '               {{else}}' +
        '                   <div class="author-initials">{{authorInitials}}</div>' +
        '               {{/if}}' +
        '           </div>' +
        '       </li>' +
        '   </ul>' +
        '</td>'
    ),
    templateHelpers: function()
    {
        var owner = this.model.get('owner');
        var ownerName = owner.name;

        return {
            icon: Utilities.getTypeIcon(this.model.get('ext')).icon,
            authorName: ownerName,
            authorInitials: ownerName.split(' ').map(function (s) { return s.charAt(0); }).join(''),
            authorPhoto: owner.photo_url,
            currentUserClass: app.user.get('id') === owner.id ? 'current-user' : '',
            downloadAction: app.options.apiUrl + '/content_files/' + this.model.get('id') + '?download',
            canBeDeleted: this.options.canBeDeleted
        };
    },
    ui: {
        downloadForm: '.download-form'
    },
    events: {
        'click .rf-item': function(ev) {
            ev.preventDefault();
            this.ui.downloadForm.submit();
        },
        'click .rf-delete-button': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            var mbContent = {
                accept_is_negative: true,
                message: Handlebars.compile('Are you sure you would like to remove {{name}}?')({name: this.model.get('name')}),
                icon: 'icon-trashcan'
            };
            var self = this;

            MessageBox.showYesNo(mbContent, this,
                function() { // yes
                    $.ajax({
                        url: '/' + self.options.elementType + '/' + self.options.elementId + '/related_files/' + self.model.get('id'),
                        type: 'DELETE',
                        success: function() {
                            self.model.trigger('destroy', self.model, self.model.collection);
                        }
                    });
                }
            );
        }
    }
});

var UploadingView = Marionette.Layout.extend({
    template: Handlebars.compile(
        '<div class="area">' +
            '<div class="uploading-bar-background">' +
                '<div class="uploading-bar"></div>' +
            '</div>' +
            '<span class="left-text">Now uploading...</span>' +
            '<span class="right-text"><span>' +
        '</div>'
    ),
    ui: {
        progressBar: '.uploading-bar',
        progressText: '.right-text'
    },
    onRender: function() {
        this.updateProgress(0, 0);
    },
    updateProgress: function(percent, numFilesUploaded) {
        var text = (numFilesUploaded + ' out of ' + this.options.numFiles +
                   (this.options.numFiles > 1 ? ' files' : ' file') + ' completed');

        this.ui.progressBar.css('width', percent + '%');
        this.ui.progressText.text(text);
    }
});

var DragNDropView = Marionette.Layout.extend({
    template: Handlebars.compile(
        '<div class="area">' +
            '<div class="text-parent">' +
                '<span class="black-text">Drag & drop files here</span>' +
                '<span class="grey-text">or</span>' +
                '<a href="#" class="link-text">Upload from your Computer</a>' +
                '<input class="hide" id="select-file" type="file" multiple>' +
            '</div>' +
        '</div>'),
    ui: {
        fileInput: '#select-file'
    },
    events: {
        'click .link-text': function(ev) {
            ev.preventDefault();
            this.ui.fileInput.trigger('click');
        },
        'change #select-file': function(ev) {
            this.trigger('upload-files', $(ev.target)[0].files);
        }
    },
    onRender: function() {
        var self = this;

        this.preventDropOnBody = $('body').on('dragover drop', function(e) {
            e.stopPropagation();
            e.preventDefault();
        });

        // Drag and drop solution for firefox bug
        // See: http://stackoverflow.com/a/10310815/1712562
        $.fn.draghover = function() {
            return this.each(function() {
                var collection = $(),
                self = $(this);

                self.on('dragenter', function(e) {
                    if (collection.length === 0) {
                        self.trigger('draghoverstart');
                    }
                    collection = collection.add(e.target);
                });

                self.on('dragleave drop', function(e) {
                    collection = collection.not(e.target);
                    if (collection.length === 0) {
                        self.trigger('draghoverend');
                    }
                });
            });
        };

        // ...
        this.contentContainer = $(document).find('.content-container.overview-container');

        if (this.contentContainer.parent().find('.related-files-big-drop-area').length === 0) {
            this.contentContainer.parent().append(
                '<div class="related-files-big-drop-area hide">' +
                    '<div class="text-container">' +
                        '<div class="icon icon-arrow-up"></div>' +
                        '<div class="text">Drop files anywhere</div>' +
                        '<div class="text">on this section</div>' +
                        '<div class="small text">They will appear in</div>' +
                        '<div class="small text">Related files</div>' +
                    '</div>' +
                '</div>'
            );
        }

        this.bigDropArea = this.contentContainer.parent().find('.related-files-big-drop-area');

        this.contentContainer.draghover().on({
            draghoverstart: function() {
                self.bigDropArea.removeClass('hide');
            }
        });

        this.bigDropArea.draghover().on({
            draghoverend: function() {
                self.bigDropArea.addClass('hide');
            },
            drop: function(ev) {
                ev.stopPropagation();
                ev.preventDefault();
                self.trigger('upload-files', ev.originalEvent.dataTransfer.files);
            }
        });
    },
    close: function() {
        this.contentContainer.draghover().off();
        this.bigDropArea.draghover().off();
        this.preventDropOnBody.unbind();
    }
});

export default Marionette.Layout.extend({
    template: Handlebars.compile('<div>' +
        '<div class="dnd-area"></div>' +
        '<div class="related-files-list-container"></div>'
    ),
    regions: {
        filesList: '.related-files-list-container',
    },
    onRender: function() {
        var self = this;
        var hasEditPermission = security.checkPermission('edit', this.model);

        this.listView = new PaginatedListView({
            id: 'related-files-list',
            listItemView: FileItemView,
            listItemViewOptions: {
                tools: {},
                canBeDeleted: hasEditPermission,
                elementType: this.options.elementType,
                elementId: this.model.get('id')
            },
            collection: this.options.collection,
            fetchOptions: {
                rows: 10
            },
            sortOptions: [{
                attribute: 'modified',
                order: 'desc'
            }]
        });

        var toolbarView = new BaseToolbarView({
            collection: this.options.collection,
            tools: {
                addItem: hasEditPermission
            },
            toolLabels: {
                addItem: 'Add from Library'
            },
            addItemOptions: {
                selectUrl: '/content_files',
                selectText: 'name',
                selectPlaceholder: 'Search for a file',
                selectDropdownExtraClasses: 'has-description',
                selectFormatResult: function(item, container, query, escapeMarkup) {
                    var name = [];
                    window.Select2.util.markMatch(_.escape(item.name), query.term, name, escapeMarkup);

                    return Handlebars.compile([
                        name.join(''),
                        '<div class="description"><i class="target-icon">&#8627;</i><span>',
                        _.escape(item.path.join('/')),
                        '</span></div>'
                    ].join(''))();
                }
            }
        });

        var navbar = new ListNavbarView({
            title: 'Related Files',
            showTabs: false,
            iconClass: 'icon-user',
            tabs: [
                {
                    id: 'relatedFiles',
                    title: 'Related Files',
                    'class': 'list-nav-related-files',
                    view: this.listView,
                    toolbarView: toolbarView
                }
            ]
        });

        this.listenTo(toolbarView, 'toolbar:add-item', function(item) {
            $.ajax({
                url: '/' + self.options.elementType + '/' + self.model.get('id') + '/related_files',
                type: 'PUT',
                dataType: 'json',
                data: JSON.stringify([{id: item.get('id')}]),
                success: function() {
                    self.listView.fetchCollection();
                }
            });
        });

        this.filesList.show(navbar);

        // ...
        if (hasEditPermission) {
            _.defer(function() {
                if (self.isClosed) {
                    return;
                }
                var list = navbar.$el.find('.list-container');

                list.prepend('<div class="drag-n-drop-area"></div>');

                self.addRegion('dragNDrop', '.drag-n-drop-area');
                self.showDragNDropView();

            });
        }
    },
    showDragNDropView: function() {
        var dnd = new DragNDropView();
        var self = this;

        this.listenTo(dnd, 'upload-files', function(files) {
            var formData = new FormData();
            var sizes = [];
            var totalSize = 0;

            _.each(files, function(f) {
                formData.append('files', f);
                totalSize += f.size;
                sizes.push(totalSize);
            });

            var uploadingView = new UploadingView({
                numFiles: files.length
            });

            $.ajax({
                xhr: function() {
                    var xhr = new window.XMLHttpRequest();

                    xhr.upload.addEventListener('progress', function(evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            percentComplete = parseInt(percentComplete * 100);

                            if (percentComplete <= 100) {
                                var numFilesUploaded = 0;

                                for (var i = sizes.length - 1; i >= 0; --i) {
                                    if (evt.loaded >= sizes[i]) {
                                        numFilesUploaded = i + 1;
                                        break;
                                    }
                                }

                                uploadingView.updateProgress(percentComplete, numFilesUploaded);
                            }
                        }
                    }, false);

                    return xhr;
                },
                complete: function() {
                    self.showDragNDropView();
                },
                success: function() {
                    self.listView.fetchCollection();
                },
                error: function(xhr, textStatus, errorThrown) {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error uploading ' + (files.length > 1 ? 'files: ' : 'file: ') + errorThrown,
                                timer: 3000,
                                classes: 'error'
                            };
                        }
                    });
                },
                type: 'POST',
                url: '/' + self.options.elementType + '/' + self.model.get('id') + '/related_files',
                contentType: false,
                processData: false,
                data: formData
            });

            self.dragNDrop.show(uploadingView);
        });

        this.dragNDrop.show(dnd);
    }
});

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import GroupElementsCollection from 'js/collections/group_elements'
import IndividualFilterModel from 'js/models/individual_filter'
import IndividualItemView from 'js/views/individuals/item'
import FilterQuickOptionsIndividuals from 'js/views/filters/quick-options-individuals'
import OrganizationFilterModel from 'js/models/organization_filter'
import OrganizationItemView from 'js/views/organizations/item'
import FilterQuickOptionsOrganizations from 'js/views/filters/quick-options-organizations'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import OpportunityItemView from 'js/views/opportunities/item'
import FilterQuickOptionsDeals from 'js/views/filters/quick-options-deals'
import NoCollectionView from 'js/views/base/no_collection'
import ItemPermissionsView from 'js/views/item_permissions'
import GroupModel from 'js/models/group'
import ModalRegion from 'js/views/base/modal-region'
import app from 'js/app'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import security from 'js/utils/security'
import linkify from 'js/utils/linkify'
import dateFormat from 'js/utils/date-format'
import Currency from 'js/utils/currency'
import MessageBox from 'js/views/message_box'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'


var DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.changes = {};
        this.redyToMakeDirty = false; // flag to when register view as dirty
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
        this.redyToMakeDirty = true;
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        if (key === 'protected') {
            let display_options = this.model.get('display_options') || {}

            display_options[key] = value

            this.changes.display_options = display_options
        } else {
            this.changes[key] = value;
        }
        if (this.redyToMakeDirty) {
            app.dirtyModelHandler.add(this.model.cid);
        }
    },
    removeChange: function(key) {
        delete this.changes[key];
    },
    saveEdition: function() {
        var controller = this;

        this.trigger('editing:save:before');

        if(_.keys(this.changes).length || this.model.isNew()) {
            this.model.save(this.changes, {
                validate: true,
                patch: true,
                success: function() {
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('group:save', controller.model);
                    app.dirtyModelHandler.remove(controller.model.cid);
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    cancelEdition: function() {
        app.dirtyModelHandler.confirm(this, function() {
            var controller = this;
            this.model.fetch({
                success: function () {
                    controller.trigger('editing:cancel');
                    controller.endEditing();
                }
            });
        });
    },
    destroyModel: function(callback) {
        this.model.destroy({
            wait: true,
            success: callback
        });
    }
});

var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function () {
        var owner = this.model.get('owner');
        var groupProtected = (this.model.get('display_options') || {}).protected;
        var type = this.model.get('element_type');
        var clientPreferences = app.user.get('client').preferences || {};
        var enableDemographicMapLayout = false;

        if (clientPreferences.demographic_maps) {
            var mapInfo = JSON.parse(clientPreferences.demographic_maps)[type];
            enableDemographicMapLayout = mapInfo && (mapInfo.address_builder || mapInfo.static_pins);
        }

        var data = {
            owner: owner ? { name: owner.name } : null,
            isSmart: this.model.isSmart(),
            hideFilters: groupProtected,
            enableDemographicMapLayout: enableDemographicMapLayout,
            enableListLayout: true,
            showProtectedOption: app.user.get('is_admin') || app.user.get('is_helper'),
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };

        if (type === 'individuals' || type === 'organizations') {
            data.disableLayoutSelection = !data.enableDemographicMapLayout;
            data.enableListLayout = false;
        }

        return data;
    },
    ui: _.extend({
        display_name: '.detail-name',
        display_owner: '.detail-owner .display-field',
        displayCostBasis: '.cost-basis',
        displayCost: '.cost',
        edit_owner: '.field-owner',
        edit_type: '.field-type',
        edit_element: '.field-element',
        edit_name: '.field-name',
        edit_comments: '.field-more-info',
        editCostBasis: '.field-cost-basis',
        editCost: '.field-cost',
        editTarget: '.field-target',
        error_messages: '.error-message',
        comments_container: '.more-info-container',
        header: '.detail-header',
        tooltips: '[data-toggle=tooltip]',
        downloadForm: '.download-form',
        protected: '.display-options-protected',
        tagsInput: '.tags-input',
        funnelsInput: '.funnels-input'
    }, BaseOverviewView.prototype.ui),
    regions: _.defaults({
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    }, BaseOverviewView.prototype.regions),
    events: _.defaults({
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'change .field-name': function changeName(ev) {
            this.controller.addChange('name', $(ev.target).val());
        },
        'change .field-more-info': function changeComments(ev) {
            this.controller.addChange('comments', $(ev.target).val());
        },
        'click .field-cost-basis': function(ev) {
            this.controller.addChange('cost_basis', $(ev.target).val());
        },
        'click .display-options-protected': function(ev) {
            this.controller.addChange('protected', ev.target.checked);
        },
        'click .detail-nav .tabs a': 'showTab',
        'change .field-cost': function(ev) {
            this.onChangeCurrencyField('cost', $(ev.target), 0);
        },
        'focus .field-cost': function() {
            this.ui.editCost.val(this.ui.editCost.attr('unformatted-value'));
        },
        'blur .field-cost': function() {
            this.onBlurCurrencyField(this.ui.editCost);
        },
        'change .field-target': function(ev) {
            this.onChangeCurrencyField('target', $(ev.target), null);
        },
        'focus .field-target': function() {
            this.ui.editTarget.val(this.ui.editTarget.attr('unformatted-value'));
        },
        'blur .field-target': function() {
            this.onBlurCurrencyField(this.ui.editTarget);
        },
        'click .edit-permissions.has-permission': 'permissions',
        'click .has-permission.delete-button': 'delete'
    }, BaseOverviewView.prototype.events),
    initialize: function(options) {
        var hasPermission = security.checkPermission('edit', this.model),
            isSmart = this.model.isSmart();

        this.tools = {
            newItem: false,
            deleteItem: true,
            addItem: !isSmart && hasPermission,
            removeItem: !isSmart && hasPermission,
            selectAllPage: true,
            editColumns: hasPermission,
            selectAll: true,
            editItem: true,
            mergeItems: true
        };
        this.toolLabels = {
            removeItem: "Remove from Group"
        };
        this.fetchOptions = {
            group_id: this.model.get('id')
        };

        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.listenTo(this.model, 'sync', this.onModelSync);

        this.listenTo(this.controller, 'editing:cancel', this.errorMessages_hide);
        this.listenTo(this.controller, 'editing:start', function() {
            this.errorMessages_unhide();
            this.ui.edit_name[0].select();
        });
        this.listenTo(this.controller, 'editing:save:after', this.errorMessages_remove);

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.errorMessages_remove();
            if (errors.missing_name) {
                this.ui.edit_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_name)
                    .addClass('invalid');
            }
        });
    },
    onChangeCurrencyField: function(name, elem, defaultValue) {
        var val = parseFloat(elem.val());

        if (_.isNaN(val)) {
            this.controller.addChange(name, defaultValue);
            return;
        }

        this.controller.addChange(name, val);
    },
    onBlurCurrencyField: function(elem) {
        var val = elem.val();
        var fval = parseFloat(val);

        if (val === '' || _.isNaN(fval)) {
            elem.val('');
            elem.removeAttr('unformatted-value');
        }
        else {
            elem.val(Currency.format(app.user.get('client').default_currency, fval));
            elem.attr('unformatted-value', fval.toFixed(2));
        }
    },
    getMenuOptions: function() {
        var isSystemGroup = this.model.get('system_type') !== null;
        var hasEditPermission = security.checkPermission('edit', this.model) && !isSystemGroup;
        var groupProtected = (this.model.get('display_options') || {}).protected;

        var enableMenuOptions = groupProtected ? (app.user.get('is_admin') || app.user.get('is_helper')) : true;

        return {
            canEdit: hasEditPermission && enableMenuOptions,
            hasPermissions: hasEditPermission && enableMenuOptions && app.user.get('client').permission_type !== 'rba',
            locked: this.model.get('locked'),
            disableSave: !enableMenuOptions,
            canDownloadAsCSV: !AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption') || app.user.get('is_admin'),
            canDelete: hasEditPermission && enableMenuOptions,
            canShareGroup: hasEditPermission
        };
    },
    startEditing: function() {
        this.controller.startEditing();
    },
    permissions: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            } );
        }
    },
    'delete': function() {
        var self = this,
            preferences = app.user.get('preferences'),
            hiddenNotes = preferences['hidden_notes'] || {},
            note ='',
            initMessageActions;

        if (!('delete-group' in hiddenNotes)) {
            var element = this.options.elementType === 'individuals' ? '${ID_INDIVIDUAL, plural}' : '${ID_ORGANIZATION, plural}';
            var link = '<a href="https://support.salesseek.net/general/deals-organizations-and-individuals/how-to-bulk-update-or-delete-records" target="_blank">Bulk Delete</a> ';
            note = '<div class="note-container">' + TextManager.getText('ID_DELETE_GROUP_WARNING_MESSAGE', [element, link]) +
                '<div class="hide-note">click here if you don\'t want to see this message again</div></div>'

            initMessageActions = function(container) {
                container.find('.hide-note').click(function(){
                    hiddenNotes['delete-group'] = true;
                    app.user.updatePreference('hidden_notes', hiddenNotes);
                    container.find('.note-container').hide();
                });
            }
        }

        var mbContent = {
                accept_is_negative: true,
                message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?{{{note}}}')({name: this.model.get('name'), note: note}),
                initMessageActions: initMessageActions,
                icon: 'icon-trashcan'
            };

        MessageBox.showYesNo(mbContent, this, function() {
            var element_type = self.model.attributes.element_type;

            self.controller.destroyModel(function() {
                self.options.parent.trigger('group:delete', element_type);
            });
        });
    },
    onRender: function() {
        BaseOverviewView.prototype.onRender.call(this);

        this.initTooltips();

        var owner = this.model.get('owner');
        this.ownerSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_owner,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: {
                name: owner.name,
                id: owner.id
            },
            options: {
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.ownerSelect, 'change', this.change_owner);
        // Check for admin permissions
        if ( !security.checkPermission('change_ownership', this.model) ) {
            this.ownerSelect.$el.select2('readonly', true);
            this.ownerSelect.$el.select2('container').tooltip({
                title: "You don't have permission to edit owner",
                container: 'body'
            });
        }

        var typeOptions = [{
            id: 'smart',
            name: 'Smart'
        }];

        var type = this.model.get('group_type');

        if (this.options.preset_element_type !== 'messages') {
            typeOptions.unshift({
                id: 'static',
                name: 'Static'
            });
        } else {
            type = 'smart';
        }

        this.typeSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_type,
            text: 'name',
            value: type || 'static',
            data: typeOptions,

            options: {
                placeholder: 'Select a group type',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.typeSelect, 'change', function(item) {
            var id = item && item.id;
            this.controller.addChange('group_type', id);
        });
        if (this.model.isNew()) {
            this.typeSelect.$el.trigger('change');
        }
        this.typeSelect.$el.select2('readonly', !this.model.isNew());

        var element = this.model.get('element_type');

        this.elementSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_element,
            text: 'name',
            value: element || this.options.preset_element_type || 'individuals',
            data: [
                {
                    id: 'individuals',
                    name: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}')
                },
                {
                    id: 'organizations',
                    name: TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}'),
                },
                {
                    id: 'opportunities',
                    name: TextManager.parseText('${ID_DEAL, plural, capitalize}')
                },
                {
                    id: 'activities',
                    name: 'Activities'
                },
                {
                    id: 'tasks',
                    name: 'Tasks'
                },
                {
                    id: 'messages',
                    name: 'Messages'
                }
            ],

            options: {
                placeholder: 'Select a group element',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.elementSelect, 'change', function(item) {
            var id = item && item.id;
            this.controller.addChange('element_type', id);
        });
        if (this.model.isNew()) {
            this.elementSelect.$el.trigger('change');
        }
        else {
            this.onModelSync();
        }

        this.elementSelect.$el.select2('readonly', !this.model.isNew());

        this.$el.find('.detail-name').tooltip();

        this.tagsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.tagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,

            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder:'+ Add Tag',
                tokenSeparators: [',']
            }
        });

        let tags = [];

        if (this.model.isNew()) {
            tags = app.user.getIndividualPreloadedTags();
            this.controller.addChange('tags', tags);
        } else {
            tags = this.model.get('tags');
        }

        this.tagsSelect.setValue(tags);

        this.listenTo(this.tagsSelect, 'change', function(items) {
            var tags = _.map(items, function(item) { return { id: item.id }});
            this.controller.addChange('tags', tags);
        });


        this.funnelsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.funnelsInput,
            id: 'id',
            text: 'name',
            url: '/funnels',
            search: true,

            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                placeholder: `+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                tokenSeparators: [',']
            }
        });

        let funnels = [];

        if (this.model.isNew()) {
            funnels = app.user.getIndividualPreloadedFunnels();
            this.controller.addChange('funnels', funnels);
        } else {
            funnels = this.model.get('funnels');
        }
        this.funnelsSelect.setValue(funnels);

        this.listenTo(this.funnelsSelect, 'change', function(items) {
            var funnels = _.map(items, function(item) { return { id: item.id }});
            this.controller.addChange('funnels', funnels);
        });
    },
    onModelSync: function() {
        if (this.isClosed) {
            return;
        }

        var owner = this.model.get('owner'),
            owner_name = owner.name,
            name = this.model.get('name'),
            comments = this.model.get('comments'),
            costBasis = { 'cpl': 'Cost per lead', 'total': 'Total cost' },
            cost = this.model.get('cost'),
            target = this.model.get('target'),
            groupProtected = (this.model.get('display_options') || {}).protected;

        this.ui.display_owner.text(owner_name);
        this.ui.display_name.text(name).attr('title', name).tooltip('fixTitle');
        this.ui.displayCostBasis.text(costBasis[ this.model.get('cost_basis') || 'total' ]); // Set default
        this.ui.displayCost.text(Currency.format(app.user.get('client').default_currency, cost));
        this.ui.protected.prop('checked', groupProtected);

        this.ownerSelect.setValue({
            name: owner.name,
            id: owner.id
        });

        this.ui.edit_name.val(name);
        this.ui.edit_comments.val(comments);
        this.ui.editCostBasis.filter("[value='" + this.model.get('cost_basis') + "']").prop('checked', true);
        this.ui.editCost.val(Currency.format(app.user.get('client').default_currency, cost));
        this.ui.editCost.attr('unformatted-value', cost);

        if (!_.isUndefined(target) && target !== null) {
            this.ui.editTarget.val(Currency.format(app.user.get('client').default_currency, target));
            this.ui.editTarget.attr('unformatted-value', target);
        }

        this.$el.find('.cost-container').toggleClass('no-value', cost <= 0);
        this.$el.find('.info-toggle').tooltip({
            title: comments ? linkify(comments) : 'No information to display',
            container: 'body',
            placement: 'bottom'
        });
    },
    createFilterView: function() {
        if (this.model.isNew()) {
            return;
        }

        return BaseOverviewView.prototype.createFilterView.apply(this, arguments);
    },
    onFilterUpdate: function(filter) {
        var view = this;

        if (this.model.isSmart()) {
            this.model.save({
                filter_id: filter && filter.get('id') || null
            }, {
                patch: true,
                complete: function() {
                    BaseOverviewView.prototype.onFilterUpdate.call(view, filter);
                }
            });
        } else {
            BaseOverviewView.prototype.onFilterUpdate.call(this, filter);
        }
    },
    createListView: function() {},
    createTableView: function() {},
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
    },
    initTooltips: function() {
        // Header tools
        this.ui.header.find('[data-toggle=tooltip]').tooltip({
            placement: 'bottom',
            container: 'body'
        });

        // All remaining tooltips
        this.ui.tooltips.tooltip({
            container: 'body'
        });
    },
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
        this.ui.error_messages.empty().removeClass('invalid');
    },
    errorMessages_hide: function() {
        if (this.isClosed) {
            return;
        }

        this.ui.error_messages.hide();
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    },
    showTab: function(ev) {
        BaseOverviewView.prototype.showTab.apply(this, arguments);

        if (this.filterRegion && this.filterRegion.$el) {
            var tab = $(ev.currentTarget).attr('href');
            if (tab === '#' + this.model.get('id') + '-info-pane') {
                this.filterRegion.$el.hide();
            } else {
                this.filterRegion.$el.show();
            }
        }
    },
});

var IndividualOverviewView = OverviewView.extend({
    groupType: "Individual",
    initialize: function() {
        OverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new IndividualFilterModel({id: this.filter_id});
        this.collection = new GroupElementsCollection(null, {elementType: 'individuals'});
        this.collection.toBeLoaded = true;
        this.quickFilter = new FilterQuickOptionsIndividuals();

        this.fetchOptions = _.extend({
            extended: true
        }, this.fetchOptions);
    },
    createTableView: function() {
        // if there are no columns selected there should be null instead of empty array, as empty array implies
        // selecting zero columns and thus not showing any. TODO: fix app wide
        var selectedColumns = this.getSelectedColumns();
        if (selectedColumns && selectedColumns.length === 0) {
            selectedColumns = null;
        }

        if (this.model.get('system_type') !== null) {
            this.model.doNotPersistColumns = true;
            this.model.doNotPersistSort = true;
        }

        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            type: this.options.type,
            elementType: this.options.elementType,
            selectedColumns: selectedColumns,
            customFields: this.customFields,
            campaigns: this.campaigns,
            tableModel: this.model,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                addButton: !this.model.isSmart(),
                removeFromFavorites: this.model.isFavorites(),
                removeFromGroup: !this.model.isSmart()
            }
        });
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:individual:show', {model: model});
    }
});

var OrganizationOverviewView = OverviewView.extend({
    groupType: "Organization",
    initialize: function() {
        OverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new OrganizationFilterModel({id: this.filter_id});
        this.collection = new GroupElementsCollection(null, {elementType: 'organizations'});
        this.collection.toBeLoaded = true;
        this.quickFilter = new FilterQuickOptionsOrganizations();
    },
    createTableView: function() {
        // if there are no columns selected there should be null instead of empty array, as empty array implies
        // selecting zero columns and thus not showing any. TODO: fix app wide
        var selectedColumns = this.getSelectedColumns();
        if (selectedColumns && selectedColumns.length === 0) {
            selectedColumns = null;
        }
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            type: this.options.type,
            elementType: this.options.elementType,
            selectedColumns: selectedColumns,
            customFields: this.customFields,
            tableModel: this.model,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                addButton: !this.model.isSmart(),
                removeFromFavorites: this.model.isFavorites(),
                removeFromGroup: !this.model.isSmart()
            }
        });
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:organization:show', {model: model});
    }
});

export default IODListingBaseView.extend({
    OverviewView: OverviewView,
    events: {
        'click .save': function clickSave() {
            var display_options = this.controller.changes.display_options || {};

            this.controller.saveEdition();

            // When group is updated to refresh the template (needed only when group protected is changed to hide/show filter)
            if (!_.isUndefined(display_options.protected)) {
                this.render();
            }
        },
        'click .cancel': function clickCancelSave() {
            this.controller.cancelEdition();
        },
        'click .close-view': 'closeView'
    },
    initialize: function(options) {
        _.extend(this, options);

        if (this.model.isNew()) {
            this.creatingNewItem = true;
            this.model.set('owner', { name: app.user.get('name'), id: app.user.get('id') });
        }

        this.controller = new DetailViewController({
            model: this.model
        });

        this.listenTo(this.controller, 'editing:start', this.onStartEditing);
        this.listenTo(this.controller, 'editing:end', this.onEndEditing);
        this.listenTo(this.controller, 'editing:cancel', this.onCancelEditing);
        this.listenTo(this.model, 'sync', this.onModelSync);
        this.listenTo(this.model, 'destroy remove', this.onModelDestroy);
    },
    createOverviewView: function() {
        var OverviewView = {
            'individuals': IndividualOverviewView,
            'organizations': OrganizationOverviewView
        }[this.getElementType()];

        if (!OverviewView) {
            OverviewView = this.OverviewView;
        }

        return new OverviewView({
            controller: this.controller,
            parent: this,
            model: this.model,
            filter_id: this.filter_id,
            preset_element_type: this.options.preset_element_type,
            elementType: this.getElementType()
        });
    },
    onStartEditing: function() {
        this.$el.addClass('edit-mode');
    },
    onEndEditing: function() {
        // Exit edit mode
        // OR
        // Cancel create new item
        if (this.model.id && !this.options.disableCancel) {
            this.$el.removeClass('edit-mode');
            this.render();
            vent.trigger('AppContent:contentChange');
        } else {
            this.close();
        }
        this.trigger('edit:end');
    },
    onCancelEditing: function() {
        this.trigger('edit:cancel', this.model);
    },
    onModelSync: function() {
        if (this.creatingNewItem) {
            if (this.options.preset_element_type === "opportunities") {
                this.options.parent.showGroup(this.model);
            }
            else {
                this.creatingNewItem = false;
                this.editing = false;
                this.render();
                vent.trigger('AppContent:contentChange');
            }
        }
    },
    onModelDestroy: function() {
        this.close();
    },
    closeView: function(ev) {
        if (ev) {
            ev.preventDefault();
        }
        this.trigger('view:close', this);
    },
    getElementType: function() {
        return this.model.get('element_type');
    },
    getUrl: function() {
        if (this.model.isNew()) {
            return 'group/' + this.model.get('element_type') + '/new';
        }
        return 'group/' + this.model.get('element_type') + '/' + (this.model.get('short_id') || this.model.get('id'));
    },
    onDomRefresh: function() {
        if (!this.model.id) {
            // should be here for focusing input field
            this.controller.startEditing();
        }
    }
});

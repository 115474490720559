import _ from 'underscore'
import Backbone from 'backbone'

import BaseModel from 'js/models/base'


var PhaseModel = BaseModel.extend({
    name: 'Phase',
    urlRoot: '/phases',
    apiType: 'salesseek.opportunities.models.phase.Phase',
    defaults: _.extend({}, BaseModel.prototype.defaults, {
        name: 'Name',
        comments: 'Comments',
        default_weight: 0,
        default_duration: null
    }),
    toString: function() {
        return this.get('name');
    },
    validate: function(attrs) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name_undefined = 'Name is required';
        } else if (attrs.name.length === 0) {
            errors.name_too_short = 'Please enter a Phase name';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

PhaseModel.fromString = function (string) {
    return new PhaseModel({name: string});
};

export default PhaseModel;

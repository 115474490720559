import React from 'react'

import {SavedMessage, SimpleInput, RoundedOptionsGroup, ColorInputField, BorderSettings, RangeInput, PaddingInput, ColumnLayout} from './components'
import {UpdateBlockSettingsAction, AddPlotOfferAction, DeletePlotOfferAction} from './actions'
import {NewSelect} from 'js/react_views/widgets/select'
import {Alignments, Fonts, BorderStyles, VerticalAlignment} from './consts'
import Utilities from 'js/utils/utilities'
import guid from 'js/utils/guid'
import Currency from 'js/utils/currency'
import AppConfig from 'app/app-config'

import htmlBlocks from './html_blocks'

import style from './see.css'

const getFolderIdByPath = (path, cache, callback) => {
  if (!path) {
    callback(null);
    return;
  }

  const cachePathId = `/${path.toLowerCase()}`;

  if (cachePathId in cache) {
    callback(cache[cachePathId].id);
    return;
  }

  // ...
  let publicFolder = null;
  let currentFolder = null;
  let fullFolderName = '';
  const folderNames = path.split('/');

  const findFolderId = () => {
    if (folderNames.length > 0) {
      const folderName = folderNames.shift().toLowerCase();
      fullFolderName += `/${folderName}`;

      if (!(fullFolderName in cache)) {
        let found = false;

        for (const f of currentFolder.folders) {
          if (f.name.toLowerCase() === folderName) {
            found = true;

            $.get(`/content_folders/${f.id}`, function(data) {
              cache[fullFolderName] = {
                id: f.id,
                folders: (data.child_folders || []).map(c => {
                  return {
                    id: c.id,
                    name: c.name
                  };
                })
              };

              currentFolder = cache[fullFolderName];
              findFolderId();
            });
            break;
          }
        }

        if (!found) {
          callback(currentFolder.id);
        }
      } else {
        currentFolder = cache[fullFolderName];
        findFolderId();
      }
    } else {
      callback(currentFolder.id);
    }
  }

  // folders
  if (cache.publicFolder) {
      publicFolder = cache.publicFolder;
      currentFolder = publicFolder;

      findFolderId();
  } else {
    $.get('/content_folders/public', function(data) {
      let pf = {
        id: data.id,
        folders: (data.child_folders || []).map(f => {
          return {
            id: f.id,
            name: f.name
          };
        })
      };

      cache.publicFolder = pf;
      currentFolder = pf;

      findFolderId();
    });
  }
}


class ColumnBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.table = props.block.find("table[dir='ltr']");
    this.showColumnLayout = props.block.attr('seeblocktype') === 'multiColumnBlock';
    let numColums = 1;

    if (this.showColumnLayout) {
      numColums = 2;
    } else if (props.block.attr('seeblocktype') === 'triColumnBlock') {
      numColums = 3;
    }

    let state = {
      paddingTop: parseInt(this.table.css('padding-top')),
      paddingBottom: parseInt(this.table.css('padding-top')),
      paddingLeft: parseInt(this.table.css('padding-left') || 0),
      paddingRight: parseInt(this.table.css('padding-right') || 0),
      borderStyle: BorderStyles.find(s => s.id === (this.table.css('border-style') || 'none')),
      borderWidth: this.table.css('border-width'),
      borderColor: Utilities.rgb2hex(this.table.css('border-color'))
    };

    const columns = this.table.find('td.full');

    this.columns = [];
    let columnsMobileVisibility = [];
    let columnsVerAlignment = [];

    for (let i = 0; i < numColums; ++i) {
      this.columns.push($(columns[i]));

      columnsMobileVisibility.push(!this.columns[i].hasClass('desktopOnly'));
      columnsVerAlignment.push(this.columns[i].css('vertical-align'));
    }

    state.columnsMobileVisibility = columnsMobileVisibility;
    state.columnsVerAlignment = columnsVerAlignment;

    if (this.showColumnLayout) {
      const column1Width = parseFloat(this.columns[0].css('width'));
      const column2Width = parseFloat(this.columns[1].css('width'));

      if (column1Width === column2Width) {
        state.activeColumnLayout = 'equal';
      } else if (column1Width > column2Width) {
        if ((column1Width / column2Width) < 2.5) {
          state.activeColumnLayout = '2/3-1/3';
        } else {
          state.activeColumnLayout = '3/4-1/4';
        }
      } else {
        if ((column2Width / column1Width) < 2.5) {
          state.activeColumnLayout = '1/3-2/3';
        } else {
          state.activeColumnLayout = '1/4-3/4';
        }
      }
    }

    this.state = state;
  }

  handleBackgroundColorChange(color) {
    const currentColor = Utilities.rgb2hex(this.table.css('background-color'));

    if (color !== currentColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setBackgroundColor',
        oldValue: currentColor,
        newValue: color
      }));
    }
  }

  setBackgroundColor(color) {
    this.table.css('background-color', color);
    this.colorComponent.setValue(color);
    this.props.onContentChange();
  }

  handlePaddingTopModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingTop',
      oldValue: oldValue,
      newValue: newValue
    }));
  }

  setPaddingTop(value) {
    this.setState({
      paddingTop: parseInt(value)
    });

    this.paddingInputComponent.setValue('top', value);
    this.table.css('padding-top', `${value}px`);
    this.props.onContentChange();
  }


  handlePaddingBottomModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingBottom',
      oldValue: oldValue,
      newValue: newValue
    }));
  }

  setPaddingBottom(value) {
    this.setState({
      paddingBottom: parseInt(value)
    });

    this.paddingInputComponent.setValue('bottom', value);
    this.table.css('padding-bottom', `${value}px`);
    this.props.onContentChange();
  }

  handlePaddingLeftModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingLeft',
      oldValue: oldValue,
      newValue: newValue
    }));
  }

  setPaddingLeft(value) {
    this.setState({
      paddingLeft: parseInt(value)
    });

  this.paddingInputComponent.setValue('left', value);
    this.table.css('padding-left', `${value}px`);
    this.props.onContentChange();
  }

  handlePaddingRightModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingRight',
      oldValue: oldValue,
      newValue: newValue
    }));
  }

  setPaddingRight(value) {
    this.setState({
      paddingRight: parseInt(value)
    });

    this.paddingInputComponent.setValue('right', value);
    this.table.css('padding-right', `${value}px`);
    this.props.onContentChange();
  }

  handlePaddingChange(padding, value) {
    switch (padding) {
      case 'top':
        this.handlePaddingTopModify(this.state.paddingTop, value);
        break;

      case 'right':
        this.handlePaddingRightModify(this.state.paddingRight, value);
        break;

      case 'bottom':
        this.handlePaddingBottomModify(this.state.paddingBottom, value);
        break;

      case 'left':
        this.handlePaddingLeftModify(this.state.paddingLeft, value);
        break;
    }
  }

  handleBorderStyle(items) {
    const style = items[0];
    const currentStyle = this.table.css('border-style');

    if (currentStyle === style.id) {
      return;
    }

    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setBorderStyle',
      oldValue: this.state.borderStyle,
      newValue: style
    }));
  }

  setBorderStyle(style) {
    this.table.css('border-style', style.id);
    this.props.onContentChange();
    this.borderSettingsEl.setStyle(style.id);

    this.setState({
      borderStyle: style
    });
  }

  handleBorderColorChange(color) {
    const currentColor = Utilities.rgb2hex(this.table.css('border-color'));

    if (color !== currentColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setBorderColor',
        oldValue: currentColor,
        newValue: color
      }));
    }
  }

  setBorderColor(color) {
    this.table.css('border-color', color);
    this.props.onContentChange();
    this.borderSettingsEl.setColor(color);
    this.setState({
      borderColor: color
    });
  }

  handleBorderWidthChange(ev) {
    this.setBorderWidth(ev.target.value);
  }

  handleBorderWidthModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setBorderWidth',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  setBorderWidth(width) {
    this.table.css('border-width', width);
    this.props.onContentChange();
    this.setState({
      borderWidth: width
    });
  }

  handleColumnLayoutChange(layout) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setColumnLayout',
      oldValue: this.state.activeColumnLayout,
      newValue: layout
    }));
  }

  setColumnLayout(layout) {
    switch (layout) {
      case 'equal':
        this.columns[0].css('width', '50%');
        this.columns[1].css('width', '50%');
        break;

      case '2/3-1/3':
        this.columns[0].css('width', '66.6%');
        this.columns[1].css('width', '33.3%');
        break;

      case '1/3-2/3':
        this.columns[0].css('width', '33.3%');
        this.columns[1].css('width', '66.6%');
        break;

      case '3/4-1/4':
        this.columns[0].css('width', '75%');
        this.columns[1].css('width', '25%');
        break;

      case '1/4-3/4':
        this.columns[0].css('width', '25%');
        this.columns[1].css('width', '75%');
        break;
    }

    this.columnLayoutComponent.setActive(layout);
    this.props.onContentChange();
    this.setState({
      activeColumnLayout: layout
    });
  }

  handleColumnMobileVisibility(ev, columnIdx) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setColumnMobileVisibility',
      oldValue: this.state.columnsMobileVisibility[columnIdx],
      newValue: !ev.target.checked,
      newParams: {
        columnIdx: columnIdx
      }
    }));
  }
  setColumnMobileVisibility(visible, _, newParams) {
    const columnIdx = newParams.columnIdx;

    this.columns[columnIdx].toggleClass('desktopOnly', !visible);

    let state = this.state.columnsMobileVisibility;
    state[columnIdx] = visible;

    this.props.onContentChange();
    this.setState({
      columnsMobileVisibility: state
    });
  }

  handleVerAlignChange(align, columnIdx) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setColumnVerAlign',
      oldValue: this.state.columnsVerAlignment[columnIdx],
      newValue: align.id,
      newParams: {
        columnIdx: columnIdx
      }
    }));
  }

  setColumnVerAlign(align, _, newParams) {
    const columnIdx = newParams.columnIdx;

    this.columns[columnIdx].css('vertical-align', align);

    let state = this.state.columnsVerAlignment;
    state[columnIdx] = align;

    this.props.onContentChange();
    this.setState({
      columnsVerAlignment: state
    });
  }

  render() {
    const backgroundColor = Utilities.rgb2hex(this.table.css('background-color'));

    return (
      <div className={style.columnBlockSettings}>
        {this.showColumnLayout &&
          <div style={{marginBottom: '20px'}}>
            <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Choose column layout</div>
            <ColumnLayout
              ref={(el) => this.columnLayoutComponent = el}
              active={this.state.activeColumnLayout}
              onChange={this.handleColumnLayoutChange.bind(this)}
            />
          </div>
        }

        <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Background Color</div>
        <ColorInputField
          ref={(el) => this.colorComponent = el}
          editor={this.props.editor}
          value={backgroundColor}
          onChange={this.handleBackgroundColorChange.bind(this)}
        />

        <div style={{marginTop: '20px'}}>
          <PaddingInput
            ref={(el) => this.paddingInputComponent = el}
            values={[this.state.paddingTop, this.state.paddingRight, this.state.paddingBottom, this.state.paddingLeft]}
            onChange={this.handlePaddingChange.bind(this)}
          />
        </div>

        <div className={style.simpleLabel} style={{marginTop: '20px', marginBottom: '10px'}}>Border</div>
        <BorderSettings
          ref={(el) => this.borderSettingsEl = el}
          editor={this.props.editor}
          width={this.state.borderWidth}
          style={this.state.borderStyle}
          color={this.state.borderColor}
          onWidthChange={this.handleBorderWidthChange.bind(this)}
          onWidthModify={this.handleBorderWidthModify.bind(this)}
          onStyleChange={this.handleBorderStyle.bind(this)}
          onColorChange={this.handleBorderColorChange.bind(this)}
        />

        {this.state.columnsVerAlignment.map((alignment, idx) => {
          const name = `ver_align_${idx}`;
          const value = VerticalAlignment.find(va => va.id === alignment);

          return (
            <div
              key={name}
              className={style.sbsItem}
              style={{
                marginTop: '20px',
                justifyContent: 'space-between'
              }}
            >
              <div className={style.simpleDesc}>
                Column {idx + 1} - vertical alignment
              </div>

              <div style={{width: '130px'}}>
                <NewSelect
                  data={VerticalAlignment}
                  value={value}
                  width={130}
                  options={{minimumInputLength: -1}}
                  onSelect={(items) => this.handleVerAlignChange.bind(this)(items[0], idx)}
                />
              </div>
            </div>
          );
        })}

        {this.state.columnsMobileVisibility.map((visible, idx) => {
          const name = `hide_in_mobile_${idx}`;

          return (
            <div
              key={name}
              className={style.sbsItem}
              style={{marginTop: '20px'}}
            >
              <div
                className={style.simpleDesc}
                style={{width: '160px'}}
              >
                Column {idx + 1} - hide in mobile
              </div>

              <label htmlFor={name}>
                <div className='checkbox-button-container'>
                  <input
                    type='checkbox'
                    name={name}
                    id={name}
                    checked={!visible}
                    onChange={(ev) => this.handleColumnMobileVisibility.bind(this)(ev, idx)}
                    style={{display: 'none'}}
                  />
                  <div className='checkbox-button'>
                    <div className='checkbox-button-slider'/>
                  </div>
                </div>
              </label>
            </div>
          )
        })}
      </div>
    );
  }
}

class SocialsBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.socialsItemsDesc = {
      f: {
        id: 'facebook',
        icon: 'icon-facebook',
        img: 'fb.png'
      },
      t: {
        id: 'twitter',
        icon: 'icon-twitter',
        img: 'tw.png'
      },
      i: {
        id: 'instagram',
        icon: 'icon-instagram',
        img: 'ig.png'
      },
      l: {
        id: 'linkedin',
        icon: 'icon-linkedin',
        img: 'li.png'
      },
      y: {
        id: 'youtube',
        icon: 'icon-youtube',
        img: 'yt.png'
      },
      p: {
        id: 'pinterest',
        icon: 'icon-pinterest',
        img: 'pin.png'
      },
      c: {
        id: 'custom',
        icon: 'icon-link',
        img: 'link.png'
      }
    };

    this.socialsContainer = props.block.find('#socialsContainer');
    this.orderIds = props.block.attr('seeOrder') || 'ftilypc'; // facebook, twitter, instagram, linkeding, etc...

    this.iconColors = [{
      id: 'dark',
      title: 'Dark'
    }, {
      id: 'light',
      title: 'Light'
    }, {
      id: 'branded',
      title: 'Original'
    }];

    this.iconSizes = [{
      id: '24px',
      title: 'Small',
    }, {
      id: '32px',
      title: 'Medium',
    }, {
      id: '40px',
      title: 'Large',
    }];

    let iconColor = null;
    let iconSize = null;
    const socialItems = this.socialsContainer.children();

    let socialsItemsDef = [];

    for (let i = 0; i < this.orderIds.length; ++i) {
      socialsItemsDef.push(this.socialsItemsDesc[this.orderIds[i]]);
    }

    this.state = {
      facebook: false,
      twitter: false,
      instagram: false,
      linkedin: false,
      youtube: false,
      pinterest: false,
      custom: false,
      facebookLink: 'https://www.facebook.com/',
      twitterLink: 'https://www.twitter.com/',
      instagramLink: 'https://www.instagram.com/',
      linkedinLink: 'https://www.linkedin.com/',
      youtubeLink: 'https://www.youtube.com/',
      pinterestLink: 'https://www.pinterest.com/',
      customLink: 'http://www.yourcompany.com',
      alignment: this.props.block.find('table').attr('align'),
      socialsItems: socialsItemsDef
    };

    if (socialItems.length > 0) {
      const firstItem = socialItems.find('.seeSocialImage');
      const src = firstItem.attr('src');
      const width = firstItem.css('width');

      iconColor = this.iconColors.find(c => src.indexOf(c.id) !== -1);
      iconSize = this.iconSizes.find(s => s.id === width);

      for (const si of socialItems) {
        const img = $(si).find('.seeSocialImage');
        this.state[img.attr('id')] = true;
        this.state[`${img.attr('id')}Link`] = img.parent().attr('href');
      }
    }

    this.state.iconColor = iconColor || this.iconColors[0];
    this.state.iconSize = iconSize || this.iconSizes[0];

    this.linkValueOnFocus = {};

    this.onLinkUpdated = this.onLinkUpdated.bind(this);
    this.onEnableUpdated = this.onEnableUpdated.bind(this);
  }

  componentDidMount() {
    const self = this;

    if (this.props.block.attr('seeInitialize') === 'true') {
      this.props.block.attr('seeInitialize', 'false');

      // the default ones
      this.setState({
        facebook: true
      });

      _.defer(function() {
        self.buildSocialItems();
      });
    }

    this.container.sortable({
      axis: 'y',
      items: `.${style.sbsItem}`,
      handle: `.${style.sbsiHandler}`,
      stop: function() {
        let newOrder = '';

        for (const item of self.container.children()) {
          const id = $(item).attr('id');

          if (!id) {
            break;
          }

          newOrder += id[0];
        }

        self.props.editor.executeAction(new UpdateBlockSettingsAction({
          editor: self.props.editor,
          setMethod: 'setItemsOrder',
          oldValue: self.orderIds,
          newValue: newOrder
        }));
      }
    });
  }

  componentWillUnmount() {
    this.container.sortable('destroy');
  }

  buildSocialItems() {
    this.socialsContainer.empty();

    for (let i = 0; i < this.orderIds.length; ++i) {
      const item = this.socialsItemsDesc[this.orderIds[i]];

      if (this.state[item.id]) {
        this.socialsContainer.append($(htmlBlocks.socialItemBlockHtml(item.id, this.state[`${item.id}Link`], `${this.state.iconColor.id}-${item.img}`, parseInt(this.state.iconSize.id))));
      }
    }

    this.props.onContentChange();
  }

  setItemsOrder(order) {
    this.orderIds = order;
    this.props.block.attr('seeOrder', order);
    this.buildSocialItems();

    let socialsItemsDef = [];

    for (let i = 0; i < this.orderIds.length; ++i) {
      socialsItemsDef.push(this.socialsItemsDesc[this.orderIds[i]]);
    }

    this.setState({
      socialsItems: socialsItemsDef
    });
  }

  onLinkFocus(socialId) {
    this.linkValueOnFocus[socialId] = this.state[`${socialId}Link`];
  }

  onLinkBlur(socialId) {
    if (this.state[`${socialId}Link`] === this.linkValueOnFocus[socialId]) {
      return;
    }

    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setLink',
      oldValue: this.linkValueOnFocus[socialId],
      newValue: this.state[`${socialId}Link`],
      newParams: {socialId: socialId},
      ignoreDo: true
    }));
  }

  onLinkUpdated(socialId, ev) {
    this.setLink(ev.target.value, null, {socialId: socialId});
  }

  setLink(newValue, oldValue, newParams) {
    this.setState({
      [`${newParams.socialId}Link`]: newValue
    })

    this.socialsContainer.find(`#${newParams.socialId}`).parent().attr('href', newValue);
    this.props.onContentChange();
  }

  onEnableUpdated(socialId, ev) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setSocialItem',
      oldValue: this.state[socialId],
      newValue: ev.target.checked,
      newParams: {socialId: socialId}
    }));
  }

  setSocialItem(newValue, oldValue, newParams) {
    if (newValue === oldValue) {
      return;
    }

    this.setState({
      [newParams.socialId]: newValue
    });

    const self = this;

    _.defer(function() {
      self.buildSocialItems();
    });
  }

  handleIconColorChange(items) {
    const color = items[0];

    if (color.id !== this.state.iconColor.id) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setIconColor',
        oldValue: this.state.iconColor,
        newValue: color
      }));
    }
  }

  setIconColor(color) {
    this.setState({
      iconColor: color
    });

    const self = this;

    _.defer(function() {
      self.iconColorEl.setValue(color.id);
      self.buildSocialItems();
    });
  }

  handleIconSizeChange(items) {
    const size = items[0];

    if (size.id !== this.state.iconSize.id) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setIconSize',
        oldValue: this.state.iconSize,
        newValue: size
      }));
    }
  }

  setIconSize(size) {
    this.setState({
      iconSize: size
    });

    const self = this;

    _.defer(function() {
      self.iconSizeEl.setValue(size.id);
      self.buildSocialItems();
    });
  }

  handleAlignment(alignId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setAlignment',
      oldValue: this.state.alignment,
      newValue: alignId
    }));
  }

  setAlignment(alignId) {
    this.props.block.find('table').attr('align', alignId);

    this.setState({
      alignment: alignId
    });

    this.props.onContentChange();
  }

  render() {
    return (
      <div className={style.socialsBlockSettings}>
        <div
          ref={(el) => this.container = $(el)}
        >
          {this.state.socialsItems.map(si => {
            return (
              <div
                key={si.id}
                id={si.id}
                className={style.sbsItem}
              >
                <div className={style.sbsiHandler}/>
                <div className={style.sbsiContent}>
                  <div className={si.icon}/>
                  <input
                    className={style.sbsicUrl}
                    type='text'
                    value={this.state[`${si.id}Link`]}
                    onChange={(ev) => this.onLinkUpdated(si.id, ev)}
                    onFocus={() => this.onLinkFocus.bind(this)(si.id)}
                    onBlur={() => this.onLinkBlur.bind(this)(si.id)}
                  />
                </div>
                <label htmlFor={`${si.id}_enable`}>
                  <div className='checkbox-button-container'>
                    <input
                      type='checkbox'
                      name='enable'
                      id={`${si.id}_enable`}
                      checked={this.state[si.id]}
                      onChange={(ev) => this.onEnableUpdated(si.id, ev)}
                      style={{display: 'none'}}
                    />
                    <div className='checkbox-button'>
                      <div className='checkbox-button-slider'/>
                    </div>
                  </div>
                </label>
              </div>
            )
          })}
          <div className={style.sbsRow} style={{marginTop: '20px'}}>
            <div className={style.sbsrColumn}>
              <div className={style.sbsrcTitle}>Icon Color</div>
              <div style={{width: '130px'}}>
                <NewSelect
                  ref={(el) => this.iconColorEl = el}
                  data={this.iconColors}
                  value={this.state.iconColor}
                  width={130}
                  options={{minimumInputLength: -1}}
                  onSelect={this.handleIconColorChange.bind(this)}
                />
              </div>
            </div>
            <div className={style.sbsrColumn}>
              <div className={style.sbsrcTitle}>Icon Size</div>
              <div style={{width: '130px'}}>
                <NewSelect
                  ref={(el) => this.iconSizeEl = el}
                  data={this.iconSizes}
                  value={this.state.iconSize}
                  width={130}
                  options={{minimumInputLength: -1}}
                  onSelect={this.handleIconSizeChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Alignment</div>
          <RoundedOptionsGroup
            options={Alignments}
            selected={this.state.alignment}
            onOptionSelected={this.handleAlignment.bind(this)}
          />
        </div>
      </div>
    );
  }
}

class DividerBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.sizes = [{
      id: '100%',
      title: 'Full Width',
      pixels: '600px'
    }];

    for (let pc = 90; pc >= 10; pc -= 10) {
      this.sizes.push({
        id: `${pc}%`,
        title: `${pc}%`,
        pixels: `${(pc * 6)}px`
      });
    }

    this.styles = [{
      id: 'solid',
      title: 'Solid'
    }, {
      id: 'dashed',
      title: 'Dashed'
    }, {
      id: 'dotted',
      title: 'Dotted'
    }];

    this.thicknessOptions = [{
      id: '2px',
      html: `<div class='${style.thickness}'/>`
    }, {
      id: '4px',
      html: `<div class='${style.thickness} ${style.tn2}'/>`
    }, {
      id: '8px',
      html: `<div class='${style.thickness} ${style.tn3}'/>`
    }];

    this.table = props.block.find('table');

    this.state = {
      thickness: this.thicknessOptions.find(to => to.id === this.table.css('border-top-width')).id,
      align: this.table.attr('align')
    };
  }

  handleDividerColorChange(color) {
    const currentColor = Utilities.rgb2hex(this.table.css('border-top-color'));

    if (color !== currentColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setColor',
        oldValue: currentColor,
        newValue: color
      }));
    }
  }

  setColor(color) {
    this.table.css('border-top-color', color);
    this.colorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleBorderStyleChange(items) {
    const currentBorderStyle = this.table.css('border-top-style');
    const borderStyle = items[0].id;

    if (currentBorderStyle !== borderStyle) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setBorderStyle',
        oldValue: currentBorderStyle,
        newValue: borderStyle
      }));
    }
  }

  setBorderStyle(borderStyle) {
    this.table.css('border-top-style', borderStyle);
    this.borderStyleComponent.setValue(borderStyle);
    this.props.onContentChange();
  }

  handleBorderSizeChange(items) {
    const currentSize = this.table.css('width');
    const size = items[0];

    if (currentSize !== size.pixels) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setBorderSize',
        oldValue: currentSize,
        newValue: size.id
      }));
    }
  }

  setBorderSize(size) {
    this.table.attr('width', size);
    this.borderSizeComponent.setValue(size);
    this.props.onContentChange();
  }

  handleThickness(optionId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setThickness',
      oldValue: this.state.thickness,
      newValue: optionId
    }));
  }

  setThickness(optionId) {
    this.table.css('border-top-width', optionId);
    this.props.onContentChange();

    this.setState({
      thickness: optionId
    });
  }

  handleAlignment(optionId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setAlignment',
      oldValue: this.state.align,
      newValue: optionId
    }));
  }

  setAlignment(optionId) {
    this.table.attr('align', optionId);
    this.props.onContentChange();

    this.setState({
      align: optionId
    });
  }

  render() {
    const borderStyle = BorderStyles.find(bs => bs.id === this.table.css('border-top-style'));
    const borderSize = this.sizes.find(s => s.id === this.table.attr('width'));
    const color = Utilities.rgb2hex(this.table.css('border-top-color'));

    return (
      <div className={style.dividerBlockSettings}>
        <div className={style.dbsRow}>
          <div className={style.dbsrColumn}>
            <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Divider Style</div>
            <NewSelect
              ref={(el) => this.borderStyleComponent = el}
              data={this.styles}
              width={148}
              value={borderStyle}
              options={{minimumInputLength: -1}}
              onSelect={this.handleBorderStyleChange.bind(this)}
            />
          </div>
          <div className={style.dbsrColumn}>
            <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Divider Size</div>
            <NewSelect
              ref={(el) => this.borderSizeComponent = el}
              data={this.sizes}
              width={148}
              value={borderSize}
              options={{minimumInputLength: -1}}
              onSelect={this.handleBorderSizeChange.bind(this)}
            />
          </div>
        </div>
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Thickness</div>
        <RoundedOptionsGroup
          options={this.thicknessOptions}
          optionStyle={{height: '30px'}}
          selected={this.state.thickness}
          onOptionSelected={this.handleThickness.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Alignment</div>
        <RoundedOptionsGroup
          options={Alignments}
          selected={this.state.align}
          onOptionSelected={this.handleAlignment.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Divider Color</div>
        <ColorInputField
          ref={(el) => this.colorComponent = el}
          editor={this.props.editor}
          value={color}
          onChange={this.handleDividerColorChange.bind(this)}
        />
      </div>
    );
  }
}

class SpacerBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.div = props.block.find('div');

    this.state = {
      height: parseInt(this.div.css('line-height')) || 20
    };
  }

  handleSliderModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setHeight',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  handleSlider(ev) {
    this.setHeight(ev.target.value);
  }

  setHeight(value) {
    this.setState({
      height: parseInt(value)
    });

    this.div.css('line-height', `${value}px`);
    this.props.onContentChange();
  }

  render() {
    return (
      <div className={style.spacerBlockSettings}>
        <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Space Height</div>
        <RangeInput
          min={20}
          max={1000}
          value={this.state.height}
          onChange={this.handleSlider.bind(this)}
          onModify={this.handleSliderModify.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginBottom: '10px', textAlign: 'center'}}>{`${this.state.height}px`}</div>
      </div>
    );
  }
}

class ButtonBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.sizeOptions = [{
      id: 'small',
      html: `<div class='${style.buttonSizeIcon}'>S</div>`
    }, {
      id: 'medium',
      html: `<div class='${style.buttonSizeIcon}'>M</div>`
    }, {
      id: 'large',
      html: `<div class='${style.buttonSizeIcon}'>L</div>`
    }];

    this.roundedCornersOptions = [{
      id: '0px',
      icon: 'icon-corner-square'
    }, {
      id: '5px',
      icon: 'icon-corner-round-sml'
    }, {
      id: '20px',
      icon: 'icon-corner-round-mid'
    }];

    this.table = props.block.find("table[dir='ltr']");
    this.outerTd = props.block.find('#seeOuterTd');
    this.innerTd = props.block.find('#seeInnerTd');
    this.anchor = this.innerTd.find('a');
    this.msoCodeBlock = this.outerTd[0].childNodes[1];

    let buttonSizeId = 'small';

    switch (this.innerTd.css('padding-top')) {
      case '12px':
        buttonSizeId = 'large';
        break;

      case '10px':
        buttonSizeId = 'medium';
        break;
    }

    const fontFamily = this.anchor.css('font-family');

    this.state = {
      buttonText: this.anchor.text(),
      buttonLink: this.anchor.attr('href') || '',
      buttonSize: buttonSizeId,
      borderRadius: this.roundedCornersOptions.find(rc => rc.id === this.innerTd.css('border-top-left-radius')).id,
      align: this.outerTd.attr('align'),
      paddingTop: parseInt(this.table.css('padding-top')),
      paddingBottom: parseInt(this.table.css('padding-top')),
      font: Fonts.find(f => f.title === fontFamily || `"${f.title}"` === fontFamily) || Fonts[0]
    };

    const self = this;

    _.defer(function() {
      self.buildMsoCode();
    });
  }

  onSettingsChange() {
    this.buildMsoCode();
    this.props.onContentChange();
  }

  buildMsoCode() {
    const width = this.innerTd[0].clientWidth;
    const height = this.innerTd[0].clientHeight;
    const arcSize = Math.floor((parseInt(this.innerTd.css('border-radius')) / height) * 100);

    const code = `[if mso]>
      <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${this.anchor.attr('href') || ''}" style="height:${height}px;v-text-anchor:middle;width:${width}px;" arcsize="${arcSize}%" stroke="f" fillcolor="${Utilities.rgb2hex(this.innerTd.css('background-color'))}">
        <v:textbox style="mso-fit-shape-to-text:t">
          <center style="color:${Utilities.rgb2hex(this.anchor.css('color'))};font-family:${Utilities.replaceAll(this.anchor.css('font-family'), '"', '')};font-size:${this.anchor.css('fontSize')}px;font-weight:bold;">
              ${this.anchor.text()}
          </center>
        </v:textbox>
      </v:roundrect>
      <![endif]`;

    this.msoCodeBlock.nodeValue = code;
  }

  handlePaddingTop(ev) {
    this.setPaddingTop(ev.target.value);
  }

  handlePaddingTopModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingTop',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  setPaddingTop(value) {
    this.setState({
      paddingTop: parseInt(value)
    });

    this.table.css('padding-top', `${value}px`);
    this.props.onContentChange();
  }

  handlePaddingBottomModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setPaddingBottom',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  handlePaddingBottom(ev) {
    this.setPaddingBottom(ev.target.value);
  }

  setPaddingBottom(value) {
    this.setState({
      paddingBottom: parseInt(value)
    });

    this.table.css('padding-bottom', `${value}px`);
    this.props.onContentChange();
  }

  handleButtonTextChange(ev) {
    this.setButtonText(ev.target.value);
  }

  handleButtonTextModify(oldValue, newValue) {
    // the modify event is triggered when the input box lost the focus and the arguments are: the value when the input get the focus and the value when the input lost the focus
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setButtonText',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  setButtonText(text) {
    this.setState({
      buttonText: text
    });

    this.anchor.text(text);
    this.onSettingsChange();
  }

  handleButtonLinkChange(ev) {
    this.setButtonLink(ev.target.value);
  }

  handleButtonLinkModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setButtonLink',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  setButtonLink(link) {
    this.setState({
      buttonLink: link
    });

    if (link) {
      this.anchor.attr('href', link);
    } else {
      this.anchor.removeAttr('href');
    }

    this.onSettingsChange();
  }

  handleBackgroundColorChange(color) {
    const currentColor = Utilities.rgb2hex(this.innerTd.css('background-color'));

    if (color !== currentColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setBackgroundColor',
        oldValue: currentColor,
        newValue: color
      }));
    }
  }

  setBackgroundColor(color) {
    this.innerTd.css('background-color', color);
    this.backgroundColorComponent.setValue(color);
    this.onSettingsChange();
  }

  handleFontColorChange(color) {
    const currentColor = Utilities.rgb2hex(this.innerTd.css('color'));

    if (color !== currentColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFontColor',
        oldValue: currentColor,
        newValue: color
      }));
    }
  }

  setFontColor(color) {
    this.anchor.css('color', color);
    this.innerTd.css('color', color);
    this.fontColorComponent.setValue(color);
    this.onSettingsChange();
  }

  handleButtonSize(optionId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setButtonSize',
      oldValue: this.state.buttonSize,
      newValue: optionId
    }));
  }

  setButtonSize(optionId) {
    let padding = '6px 12px';
    let fontSize = '11px';

    switch (optionId) {
      case 'large':
        padding = '12px 24px';
        fontSize = '14px';
        break;

      case 'medium':
        padding = '10px 20px';
        fontSize = '12px';
        break;
    }

    this.setState({
      buttonSize: optionId
    });

    this.innerTd.css('padding', padding);
    this.anchor.css('fontSize', fontSize);

    this.onSettingsChange();
  }

  handleBorderRadius(optionId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setBorderRadius',
      oldValue: this.state.borderRadius,
      newValue: optionId
    }));
  }

  setBorderRadius(optionId) {
    this.setState({
      borderRadius: optionId
    });

    this.innerTd.css('border-radius', optionId);

    this.onSettingsChange();
  }

  handleAlign(optionId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setAlign',
      oldValue: this.state.align,
      newValue: optionId
    }));
  }

  setAlign(optionId) {
    this.setState({
      align: optionId
    });

    this.outerTd.attr('align', optionId);

    this.onSettingsChange();
  }

  handleFont(items) {
    const currentFontFamily = Utilities.replaceAll(this.anchor.css('font-family') || '', '"', '');
    const currentFont = Fonts.find(f => f.title === currentFontFamily);
    const font = items[0];

    if (!currentFont || (currentFont.id !== font.id)) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFont',
        oldValue: currentFont,
        newValue: font
      }));
    }
  }

  setFont(newFont, oldFont) {
    this.anchor.css('font-family', newFont.title);
    this.props.editor.onFontChange(oldFont, newFont);
    this.fontComponent.setValue(newFont.id);
    this.onSettingsChange();
  }

  render() {
    const backgroundColor = Utilities.rgb2hex(this.innerTd.css('background-color'));
    const fontColor = Utilities.rgb2hex(this.anchor.css('color'));

    return (
      <div className={style.buttonBlockSettings}>
        <SimpleInput
          label='Button Text'
          value={this.state.buttonText}
          placeholder='Button'
          onChange={this.handleButtonTextChange.bind(this)}
          onModify={this.handleButtonTextModify.bind(this)}
        />
        <div style={{marginTop: '10px'}}/>
        <SimpleInput
          label='Button Link'
          value={this.state.buttonLink}
          placeholder='https://example.com'
          onChange={this.handleButtonLinkChange.bind(this)}
          onModify={this.handleButtonLinkModify.bind(this)}
        />
        <div style={{marginTop: '10px'}}/>
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Button Size</div>
        <RoundedOptionsGroup
          options={this.sizeOptions}
          selected={this.state.buttonSize}
          onOptionSelected={this.handleButtonSize.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Background Color</div>
        <ColorInputField
          ref={(el) => this.backgroundColorComponent = el}
          editor={this.props.editor}
          value={backgroundColor}
          onChange={this.handleBackgroundColorChange.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Rounded Corners</div>
        <RoundedOptionsGroup
          options={this.roundedCornersOptions}
          selected={this.state.borderRadius}
          onOptionSelected={this.handleBorderRadius.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Alignment</div>
        <RoundedOptionsGroup
          options={Alignments}
          selected={this.state.align}
          onOptionSelected={this.handleAlign.bind(this)}
        />

        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Padding Top</div>
        <RangeInput
          value={this.state.paddingTop}
          onChange={this.handlePaddingTop.bind(this)}
          onModify={this.handlePaddingTopModify.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginBottom: '10px', textAlign: 'center'}}>{`${this.state.paddingTop}px`}</div>

        <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Padding Bottom</div>
        <RangeInput
          value={this.state.paddingBottom}
          onChange={this.handlePaddingBottom.bind(this)}
          onModify={this.handlePaddingBottomModify.bind(this)}
        />
        <div className={style.simpleLabel} style={{marginBottom: '10px', textAlign: 'center'}}>{`${this.state.paddingBottom}px`}</div>

        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Font</div>
        <div style={{width: '155px'}}>
          <NewSelect
            ref={(el) => this.fontComponent = el}
            data={Fonts}
            value={this.state.font}
            width={200}
            options={{minimumInputLength: -1}}
            onSelect={this.handleFont.bind(this)}
          />
        </div>
        <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Font Color</div>
        <ColorInputField
          ref={(el) => this.fontColorComponent = el}
          editor={this.props.editor}
          value={fontColor}
          onChange={this.handleFontColorChange.bind(this)}
        />
      </div>
    );
  }
}

class ImageBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    const img = this.props.block.find('img');
    const anchor = this.props.block.find('a');
    const td = this.props.block.find('#seeInnerTd');

    this.state = {
      link: anchor.attr('href') || '',
      altText: img.attr('alt') || '',
      width: img.width(),
      height: img.height(),
      image: img.attr('src') || null,
      alignId: td.css('text-align') || 'center',
      hasSpacing: td.css('padding-left') !== '0px',
      folderId: null
    };

    this.originalWidth = img.attr('seeOriginalWidth') || 0;

    const self = this;

    img[0].onload = function() {
      self.originalWidth = img.width();
      self.setImageWidth(self.originalWidth);
      img.attr('seeOriginalWidth', self.originalWidth);
    }
  }

  componentDidMount() {
    this.mounted = true;

    let folderId = null;
    const self = this;

    const goOn = () => {
      self.setState({
        folderId: folderId || 'public'
      });

      if (self.props.blockParams && self.props.blockParams.openLibraryBrowser) {
        _.defer(function() {
          self.imagePreview.click();
        });
      }
    }

    const folderPath = AppConfig.getValue('campaigns.see.blocks.image.custom_folder', null, this.props.funnelId);

    if (folderPath) {
      getFolderIdByPath(folderPath, this.props.cache, function(fid) {
        if (!self.mounted) {
          return;
        }

        folderId = fid;
        goOn();
      });
    } else {
      goOn();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleLinkModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setLink',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  handleLinkChange(ev) {
    this.setLink(ev.target.value);
  }

  setLink(value) {
    this.props.block.find('a').attr('href', value);

    this.setState({
      link: value
    });

    this.linkSavedMessage.onContentChange();
    this.props.onContentChange();
  }

  handleAltTextModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setAltText',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  handleAltTextChange(ev) {
    this.setAltText(ev.target.value);
  }

  setAltText(value) {
    this.props.block.find('img').attr('alt', value);

    this.setState({
      altText: value
    });

    this.altTextSavedMessage.onContentChange();
    this.props.onContentChange();
  }

  onImageSelected(image) {
    // remove the fixed width to get the real size of the image
    const img = this.props.block.find('img');
    img.width('auto');
    img.attr('width', 'auto');

    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setImageUrl',
      oldValue: this.state.image,
      newValue: image ? image.url : null
    }));
  }

  setImageUrl(imageUrl) {
    if (imageUrl) {
      const img = this.props.block.find('img');

      img.css('width', '');
      img.attr('width');
      img.attr('src', imageUrl);

      this.setState({
        'image': imageUrl
      });

      this.props.onContentChange();
    } else {
      this.deleteImage();
    }
  }

  deleteImage() {
    this.props.block.find('img').removeAttr('src');

    this.setState({
      image: null,
      width: 0,
      height: 0
    });

    this.props.onContentChange();
  }

  setImageWidth(width) {
    const img = this.props.block.find('img');
    img.width(`${width}px`);
    img.attr('width', width);

    this.setState({
      width: width,
      height: img.height()
    });

    this.props.onContentChange();
  }

  handleSliderModify(oldValue, newValue) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setWidth',
      oldValue: oldValue,
      newValue: newValue,
      ignoreDo: true
    }));
  }

  handleSlider(ev) {
    this.setWidth(ev.target.value);
  }

  setWidth(value) {
    this.setImageWidth(value);
    this.props.onContentChange();
  }

  handleAlignment(alignId) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setAlignment',
      oldValue: this.state.alignId,
      newValue: alignId
    }));
  }

  setAlignment(alignId) {
    this.props.block.find('#seeInnerTd').css('text-align', alignId);
    this.props.onContentChange();

    this.setState({
      alignId: alignId
    });
  }

  handleSpacing(ev) {
    this.props.editor.executeAction(new UpdateBlockSettingsAction({
      editor: this.props.editor,
      setMethod: 'setSpacing',
      oldValue: this.state.hasSpacing,
      newValue: ev.target.checked
    }));
  }

  setSpacing(hasSpacing) {
    const td = this.props.block.find('#seeInnerTd');

    td.css('padding-left', hasSpacing ? '15px' : '0px');
    td.css('padding-right', hasSpacing ? '15px' : '0px');

    this.setState({
      hasSpacing: hasSpacing
    });
  }

  render() {
    return (
      <div className={style.imageBlockSettings}>
        <div className={style.ibsTitle}>Add an Image</div>
        <div className={style.ibsImage}>
          <div
            ref={(el) => this.imagePreview = el}
            className={style.ibsImagePreviewContainer}
            onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this), this.state.folderId)}
          >
            {this.state.image ? (
              <img className={style.ibsImagePreview} src={this.state.image}/>
            ) : (
              <div>
                <div className={`${style.ibsIcon} icon-upload`}/>
                <div>Upload Image</div>
              </div>
            )}
          </div>
          <div className={style.ibsOptions}>
            <div
              className={style.ibsOption}
              onClick={() => this.onImageSelected.bind(this)(null)}
            >
              <div className='icon-trashcan'/>
              <div>Delete</div>
            </div>
            <div className={style.ibsOption}>
              <div className='icon-reload'/>
              <div
                onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this), this.state.folderId)}
              >
                Replace
              </div>
            </div>
          </div>
        </div>
        <div className={style.ibsTitle} style={{marginBottom: '10px'}}>Image Link</div>

        <SimpleInput
          label='Add an optional link to the image.'
          value={this.state.link}
          placeholder='https://example.com'
          onChange={this.handleLinkChange.bind(this)}
          onModify={this.handleLinkModify.bind(this)}
        />
        <div style={{marginTop: '5px', marginLeft: '10px'}}>
          <SavedMessage ref={(el) => this.linkSavedMessage = el}/>
        </div>
        <SimpleInput
          label='Alt text'
          value={this.state.altText}
          onChange={this.handleAltTextChange.bind(this)}
          onModify={this.handleAltTextModify.bind(this)}
        />
        <div style={{marginTop: '5px', marginLeft: '10px'}}>
          <SavedMessage ref={(el) => this.altTextSavedMessage = el}/>
        </div>
        <div className={style.simpleItalicLabel}>Alt text appears when images don't load correctly and help those who are visually impared.</div>

        <div className={style.ibsTitle} style={{marginTop: '20px'}}>Size</div>
        <div style={{padding: '10px 20px'}}>
          <RangeInput
            max={this.originalWidth}
            value={this.state.width}
            onChange={this.handleSlider.bind(this)}
            onModify={this.handleSliderModify.bind(this)}
          />
          <div className={style.ibsSizeValues}>{this.state.width}px X {this.state.height}px</div>
        </div>

        <div style={{marginTop: '10px', marginBottom: '20px'}} className={style.ibsTitle}>Alignment</div>
        <RoundedOptionsGroup
          options={Alignments}
          selected={this.state.alignId}
          onOptionSelected={this.handleAlignment.bind(this)}
        />

        <div className={style.simpleRow} style={{marginTop: '20px'}}>
          <div className={style.simpleDesc}>Add image spacing</div>
          <label htmlFor='hasSpacing'>
            <div className='checkbox-button-container'>
              <input
                type='checkbox'
                name='hasSpacing'
                id='hasSpacing'
                checked={this.state.hasSpacing}
                onChange={this.handleSpacing.bind(this)}
                style={{display: 'none'}}
              />
              <div className='checkbox-button'>
                <div className='checkbox-button-slider'/>
              </div>
            </div>
          </label>
        </div>
      </div>
    );
  }
}

class PlotOfferSettings extends React.Component {
  constructor(props) {
    super(props);

    const block = props.data.block;

    this.descriptionMaxLen = 200;
    this.titleEl = block.find('.seePlotOfferTitle');
    this.numberEl = block.find('.seePlotOfferNumber');
    this.descriptionEl = block.find('.seePlotOfferDescription');
    this.priceEl = block.find('.seePlotOfferPrice');
    this.anchorEl = block.find('a');
    this.plot = null;
    this.leftPO = block.find('#seeLeftPO');
    this.rightPO = block.find('#seeRightPO');
    this.leftTextBlock = $(this.leftPO.find('.full')[1]);
    this.rightTextBlock = $(this.rightPO.find('.full')[0]);
    this.leftTD = this.leftPO.find('#seeInnerTd');
    this.rightTD = this.rightPO.find('#seeInnerTd');
    this.leftMsoCodeBlock = this.leftPO.find('#seeOuterTd')[0].childNodes[1];
    this.rightMsoCodeBlock = this.rightPO.find('#seeOuterTd')[0].childNodes[1];

    this.idx = props.idx;

    const plotId = this.titleEl.attr('seePlotId');
    const plotName = this.titleEl.attr('seePlotName');

    if (plotId && plotName) {
      this.plot = {
        id: plotId,
        name: plotName
      };
    }

    const dealTypeCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'deal type');

    if (dealTypeCf) {
      this.dealTypeCfId = dealTypeCf.id;

      for (const oid in dealTypeCf.options) {
        if (dealTypeCf.options[oid].toLowerCase() === 'plot') {
          this.plotTypeOptionId = oid;
          break;
        }
      }
    }

    this.plotUnderOfferCfId = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'plot under offer?')?.id;
    this.houseTypeCfId = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'plot offer house type')?.id;
    this.availablePhaseId = app.globalData.phasesInfo.phases.find(p => p.funnel_id === this.props.funnelId && p.name.toLowerCase() === 'available plots')?.id;

    this.state = {
      folderId: null,
      image: block.find('img').attr('src') || null,
      description: $(this.descriptionEl[0])?.text() || ''
    };
  }

  componentDidMount() {
    this.mounted = true;

    if (this.plot) {
      const self = this;

      if (this.plot.id in this.props.cache) {
        getFolderIdByPath(AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.custom_folder', null, this.props.cache[this.plot.id]), this.props.cache, function(folderId) {
          if (!self.mounted) {
            return;
          }

          self.setState({
            folderId: folderId
          });
        });
      } else {
        $.get(`/opportunities/${this.plot.id}`, function(data) {
          if (!self.mounted) {
            return;
          }

          self.props.cache[self.plot.id] = data;

          getFolderIdByPath(AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.custom_folder', null, data), self.props.cache, function(folderId) {
            if (!self.mounted) {
              return;
            }

            self.setState({
              folderId: folderId
            });
          });
        });
      }
    }

    this.adjustVisualization();
    this.buildMsoCode();
  }

  componentDidUpdate() {
    if (this.props.idx !== this.idx) {
      this.adjustVisualization();
      this.idx = this.props.idx;
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  buildMsoCode() {
    for (const side of ['left', 'right']) {
      const innerTd = this[`${side}PO`].find('#seeOuterTd');
      const anchor = innerTd.find('a');
      const width = innerTd[0].clientWidth;
      const height = innerTd[0].clientHeight;
      const arcSize = Math.floor((parseInt(innerTd.css('border-radius')) / height) * 100);

      const code = `[if mso]>
        <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${anchor.attr('href') || ''}" style="height:${height}px;v-text-anchor:middle;width:${width}px;" arcsize="${arcSize}%" stroke="f" fillcolor="${Utilities.rgb2hex(innerTd.css('background-color'))}">
          <v:textbox style="mso-fit-shape-to-text:t">
            <center style="color:${Utilities.rgb2hex(anchor.css('color'))};font-family:${Utilities.replaceAll(anchor.css('font-family'), '"', '')};font-size:${anchor.css('fontSize')}px;font-weight:bold;">
                Find out more
            </center>
          </v:textbox>
        </v:roundrect>
        <![endif]`;

      this[`${side}MsoCodeBlock`].nodeValue = code;
    }
  }

  adjustVisualization() {
    if ((this.props.idx % 2) === 0) {
      this.leftPO.css('display', 'none');
      this.rightPO.css('display', 'table-row');
    } else {
      this.rightPO.css('display', 'none');
      this.leftPO.css('display', 'table-row');
    }

    if (this.props.idx > 1) {
      this.props.data.block.css('margin-top', '3px');
    } else {
      this.props.data.block.css('margin-top', '0');
    }

    this.props.onContentChange();
  }

  handleDescriptionChange(ev) {
    const newValue = ev.target.value;

    this.descriptionEl.text(newValue);

    this.setState({
      description: newValue
    });

    const self = this;

    _.defer(function() {
      self.props.onContentChange();
    });
  }

  onImageSelected(image) {
    const img = this.props.data.block.find('img');

    if (image?.url) {
      img.attr('src', image.url);
      img.css('width', '100%');

      this.setState({
        image: image.url
      });
    } else {
      img.removeAttr('src');
      img.css('width', '');

      this.setState({
        image: null
      });
    }

    const self = this;

    _.defer(function() {
      self.props.onContentChange();
    });
  }

  handlePlotChange(items) {
    const item = items[0];
    const name = AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.name', item.name, item);

    this.titleEl.text(name);
    this.titleEl.attr('seePlotId', item.id);
    this.titleEl.attr('seePlotName', item.name);

    const price = parseInt(AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.price', 0, item) || 0);
    this.priceEl.text(`Available from just ${Currency.format(app.user.get('client').default_currency, price)}`);

    const number = AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.number', 0, item);
    this.numberEl.text(`Plot ${number}`);

    const url = AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.url', null, item);
    this.anchorEl.attr('href', url);

    this.buildMsoCode();

    const self = this;

    getFolderIdByPath(AppConfig.getValue('campaigns.see.dynamic_content.plot_offer.custom_folder', null, item), this.props.cache, function(folderId) {
      self.setState({
        folderId: folderId
      });
    });

    _.defer(function() {
      self.props.onContentChange();
    });
  }

  getPlots(callback) {
    if (!this.props.funnelId) {
      callback([]);
      return;
    }

    const params = {
      funnel_id: this.props.funnelId,
      rows: -1
    };

    if (this.availablePhaseId) {
      params.phase_id = this.availablePhaseId;
    }

    let cache = this.props.cache.plotsByFunnelId || {};

    if (cache[this.props.funnelId]) {
      callback(cache[this.props.funnelId]);
    } else {
      const self = this;

      $.get(`/opportunities?${$.param(params)}`, function(data) {
        if (!self.mounted) {
          return;
        }

        const plots = data.filter(deal => {
          return (deal[`custom_field.${self.dealTypeCfId}`] == self.plotTypeOptionId);
        });

        plots.map(plot => {
          const houseType = plot[`custom_field.${self.houseTypeCfId}`];

          if (houseType) {
            plot.name += ` - ${houseType}`;
          }

          if (plot[`custom_field.${self.plotUnderOfferCfId}`]) {
            plot.name += `${houseType ? ' - ' : ' '}(LIVE Offer)`;
          }
        });

        cache[self.props.funnelId] = plots;
        self.props.cache.plotsByFunnelId = cache;

        callback(plots);
      });
    }
  }

  setFont(font) {
    this.titleEl.css('font-family', font);
    this.priceEl.css('font-family', font);
    this.numberEl.css('font-family', font);
    this.descriptionEl.css('font-family', font);
    this.leftTD.find('a').css('font-family', font);
    this.rightTD.find('a').css('font-family', font);
    this.buildMsoCode();
  }

  setFontColor(color) {
    this.titleEl.css('color', color);
    this.priceEl.css('color', color);
    this.numberEl.css('color', color);
    this.descriptionEl.css('color', color);
    this.buildMsoCode();
  }

  setFirstTextBackgroundColor(color) {
    this.leftTextBlock.css('background-color', color);
  }

  setSecondTextBackgroundColor(color) {
    this.rightTextBlock.css('background-color', color);
  }

  setFirstButtonBackgroundColor(color) {
    this.leftTD.css('background-color', color);
    this.buildMsoCode();
  }

  setSecondButtonBackgroundColor(color) {
    this.rightTD.css('background-color', color);
    this.buildMsoCode();
  }

  setFirstButtonFontColor(color) {
    this.leftTD.find('a').css('color', color);
    this.buildMsoCode();
  }

  setSecondButtonFontColor(color) {
    this.rightTD.find('a').css('color', color);
    this.buildMsoCode();
  }

  render() {
    const backgroundImage = this.state.image || 'none';

    return (
      <div className={style.poOffer}>
        <div className={style.poHeader}>
          <div
            className={style.poName}
          >
            {`Plot Offer ${this.props.idx}`}
          </div>

          {this.props.canBeDeleted &&
            <i
              className={`icon-trashcan ${style.poDelete}`}
              onClick={this.props.onDelete}
            />
          }
        </div>

        <div className={style.poOfferContent}>
          <div style={{
            background: 'white'
          }}>
            <NewSelect
              data={this.getPlots.bind(this)}
              width={291}
              value={this.plot}
              text='name'
              options={{minimumInputLength: 0}}
              onSelect={this.handlePlotChange.bind(this)}
            />
          </div>

          <div className={style.poText}>
            Add an image
          </div>

          <div
            className={style.ibsImage}
            style={{padding: '0 10px'}}
          >
            <div
              ref={(el) => this.imagePreview = el}
              className={style.ibsImagePreviewContainer}
              style={{background: 'white'}}
              onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this), this.state.folderId)}
            >
              {this.state.image ? (
                <img className={style.ibsImagePreview} src={this.state.image}/>
              ) : (
                <div>
                  <div className={`${style.ibsIcon} icon-upload`}/>
                  <div>Upload Image</div>
                </div>
              )}
            </div>
            <div className={style.ibsOptions}>
              <div
                className={style.ibsOption}
                onClick={() => this.onImageSelected.bind(this)(null)}
              >
                <div className='icon-trashcan'/>
                <div>Delete</div>
              </div>
              <div className={style.ibsOption}>
                <div className='icon-reload'/>
                <div
                  onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this), this.state.folderId)}
                >
                  Replace
                </div>
              </div>
            </div>
          </div>

          <div className={style.poText}>
            Description text
          </div>

          <div className={style.poTextArea}>
            <textarea
              maxLength='200'
              value={this.state.description}
              onChange={this.handleDescriptionChange.bind(this)}
            />

            <div className={style.poCharsCounter}>
              {`${this.state.description.length}/${this.descriptionMaxLen} characters`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PlotOffersBlockSettings extends React.Component {
  constructor(props) {
    super(props);

    this.plotsContainer = $(props.block).find('#seePlotsContainer');
    this.maxNumPlots = 5;

    let plots = [];

    for (const plotOffer of this.plotsContainer.find('[seePlotOfferItem="true"]')) {
      const block = $(plotOffer);

      plots.push({
        id: block.attr('id'),
        block: block
      });

      this.plotsContainer.append(block);
    }

    this.state = {
      plots: plots
    };
  }

  componentDidMount() {
    this.mounted = true;

    // at least should exist one plot offer
    const self = this;

    if (this.state.plots.length === 0) {
      const id = guid();

      this.addPlotOffer(id, this.getNewPlotOfferBlock(id, false));

      _.defer(function() {
        self.setInitialValues();
        self.props.onContentChange();
      });
    } else {
      _.defer(function() {
        self.setInitialValues();
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setInitialValues() {
    const paragraphs = this.plotsContainer.find('p');
    const fontFamily = paragraphs.css('font-family');
    const font = Fonts.find(f => f.title === fontFamily || `"${f.title}"` === fontFamily) || Fonts[0];

    const leftPO = this.plotsContainer.find('#seeLeftPO');
    const rightPO = this.plotsContainer.find('#seeRightPO');

    const leftTextBlock = $(leftPO.find('.full')[1]);
    const rightTextBlock = $(rightPO.find('.full')[0]);

    this.textFont = font;
    this.textFontColor = Utilities.rgb2hex(paragraphs.css('color'));
    this.firstTextBackgroundColor = Utilities.rgb2hex(leftTextBlock.css('background-color'));
    this.secondTextBackgroundColor = Utilities.rgb2hex(rightTextBlock.css('background-color'));

    const firstButton = leftPO.find('#seeInnerTd');
    const secondButton = rightPO.find('#seeInnerTd');

    this.firstButtonBackgroundColor = Utilities.rgb2hex(firstButton.css('background-color'));
    this.secondButtonBackgroundColor = Utilities.rgb2hex(secondButton.css('background-color'));
    this.firstButtonFontColor = Utilities.rgb2hex(firstButton.find('a').css('color'));
    this.secondButtonFontColor = Utilities.rgb2hex(secondButton.find('a').css('color'));

    if (AppConfig.getValue('campaigns.user_type', 'advance') === 'advance') {
      this.fontComponent.setValue(font.id);
      this.fontColorComponent.setValue(this.textFontColor);

      this.firstTextBackgroundColorComponent.setValue(this.firstTextBackgroundColor);
      this.secondTextBackgroundColorComponent.setValue(this.secondTextBackgroundColor);

      this.firstButtonBackgroundColorComponent.setValue(this.firstButtonBackgroundColor);
      this.secondButtonBackgroundColorComponent.setValue(this.secondButtonBackgroundColor);

      this.firstButtonFontColorComponent.setValue(this.firstButtonFontColor);
      this.secondButtonFontColorComponent.setValue(this.secondButtonFontColor);
    }
  }

  addPlotOffer(id, block) {
    const newPlot = {
      id: id,
      block: block
    };

    this.plotsContainer.append(block);

    this.setState({
      plots: [...this.state.plots, newPlot]
    });
  }

  deletePlotOffer(id) {
    const plot = this.state.plots.find(p => p.id === id);

    if (plot) {
      plot.block.remove();

      this.setState({
        plots: this.state.plots.filter(p => p.id !== plot.id)
      });
    }
  }

  getNewPlotOfferBlock(id, initializeStyle) {
    const block = $(htmlBlocks.plotOfferItemBlockHtml(id));

    // update style based on the left/right situation
    if (initializeStyle) {
      const paragraphs = block.find('p');
      paragraphs.css('font-family', this.textFont.title);
      paragraphs.css('color', this.textFontColor);

      const leftPO = block.find('#seeLeftPO');
      const rightPO = block.find('#seeRightPO');

      const leftTextBlock = $(leftPO.find('.full')[1]);
      const rightTextBlock = $(rightPO.find('.full')[0]);

      leftTextBlock.css('background-color', this.firstTextBackgroundColor);
      rightTextBlock.css('background-color', this.secondTextBackgroundColor);

      const firstButton = leftPO.find('#seeInnerTd');
      const secondButton = rightPO.find('#seeInnerTd');

      firstButton.css('background-color', this.firstButtonBackgroundColor);
      secondButton.css('background-color', this.secondButtonBackgroundColor);

      firstButton.find('a').css('color', this.firstButtonFontColor);
      secondButton.find('a').css('color', this.secondButtonFontColor);
    }

    return block;
  }

  handleAddPlotOffer() {
    const id = guid();
    const block = this.getNewPlotOfferBlock(id, true);

    // ...
    this.props.editor.executeAction(new AddPlotOfferAction({
      editor: this.props.editor,
      id: id,
      block: block,
      onContentChange: this.props.onContentChange
    }));
  }

  handleDeletePlotOffer(plot) {
    this.props.editor.executeAction(new DeletePlotOfferAction({
      editor: this.props.editor,
      plot: plot,
      onContentChange: this.props.onContentChange
    }));
  }

  handleFontChange(items) {
    const paragraphs = this.plotsContainer.find('p');
    const fontFamily = paragraphs.css('font-family') || '';
    const currentFontFamily = Utilities.replaceAll(fontFamily, '"', '');
    const currentFont = Fonts.find(f => f.title === currentFontFamily);
    const font = items[0];

    if (!currentFont || (currentFont.id !== font.id)) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFont',
        oldValue: currentFont,
        newValue: font
      }));
    }
  }

  setFont(newFont, oldFont) {
    this.textFont = newFont;

    for (const component of this.components) {
      component.setFont(newFont.title);
    }

    this.props.editor.onFontChange(oldFont, newFont);
    this.fontComponent.setValue(newFont.id);
    this.props.onContentChange();
  }

  handleFontColorChange(color) {
    if (color !== this.textFontColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFontColor',
        oldValue: this.textFontColor,
        newValue: color
      }));
    }
  }

  setFontColor(color) {
    this.textFontColor = color;

    for (const component of this.components) {
      component.setFontColor(color);
    }

    this.fontColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleFirstTextBackgroundColorChange(color) {
    if (color !== this.firstTextBackgroundColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFirstTextBackgroundColor',
        oldValue: this.firstTextBackgroundColor,
        newValue: color
      }));
    }
  }

  setFirstTextBackgroundColor(color) {
    this.firstTextBackgroundColor = color;

    for (const component of this.components) {
      component.setFirstTextBackgroundColor(color);
    }

    this.firstTextBackgroundColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleSecondTextBackgroundColorChange(color) {
    if (color !== this.secondTextBackgroundColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setSecondTextBackgroundColor',
        oldValue: this.secondTextBackgroundColor,
        newValue: color
      }));
    }
  }

  setSecondTextBackgroundColor(color) {
    this.secondTextBackgroundColor = color;

    for (const component of this.components) {
      component.setSecondTextBackgroundColor(color);
    }

    this.secondTextBackgroundColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleFirstButtonBackgroundColorChange(color) {
    if (color !== this.firstButtonBackgroundColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFirstButtonBackgroundColor',
        oldValue: this.firstButtonBackgroundColor,
        newValue: color
      }));
    }
  }

  setFirstButtonBackgroundColor(color) {
    this.firstButtonBackgroundColor = color;

    for (const component of this.components) {
      component.setFirstButtonBackgroundColor(color);
    }

    this.firstButtonBackgroundColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleSecondButtonBackgroundColorChange(color) {
    if (color !== this.secondButtonBackgroundColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setSecondButtonBackgroundColor',
        oldValue: this.secondButtonBackgroundColor,
        newValue: color
      }));
    }
  }

  setSecondButtonBackgroundColor(color) {
    this.secondButtonBackgroundColor = color;

    for (const component of this.components) {
      component.setSecondButtonBackgroundColor(color);
    }

    this.secondButtonBackgroundColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleFirstButtonFontColorChange(color) {
    if (color !== this.firstButtonFontColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setFirstButtonFontColor',
        oldValue: this.firstButtonFontColor,
        newValue: color
      }));
    }
  }

  setFirstButtonFontColor(color) {
    this.firstButtonFontColor = color;

    for (const component of this.components) {
      component.setFirstButtonFontColor(color);
    }

    this.firstButtonFontColorComponent.setValue(color);
    this.props.onContentChange();
  }

  handleSecondButtonFontColorChange(color) {
    if (color !== this.secondButtonFontColor) {
      this.props.editor.executeAction(new UpdateBlockSettingsAction({
        editor: this.props.editor,
        setMethod: 'setSecondButtonFontColor',
        oldValue: this.secondButtonFontColor,
        newValue: color
      }));
    }
  }

  setSecondButtonFontColor(color) {
    this.secondButtonFontColor = color;

    for (const component of this.components) {
      component.setSecondButtonFontColor(color);
    }

    this.secondButtonFontColorComponent.setValue(color);
    this.props.onContentChange();
  }

  render() {
    this.components = [];

    const showStyleSettings = AppConfig.getValue('campaigns.user_type', 'advance') === 'advance';

    return (
      <div className={style.plotOffersBlockSettings}>
        {showStyleSettings &&
          <div>
            <div className={style.ibsTitle}>
              Plot Offer Settings
            </div>

            <div className={style.simpleSpaceRow}>
              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Block Font</div>

                <div style={{width: '155px'}}>
                  <NewSelect
                    ref={(el) => this.fontComponent = el}
                    data={Fonts}
                    value={this.state.font}
                    width={200}
                    options={{minimumInputLength: -1}}
                    onSelect={this.handleFontChange.bind(this)}
                  />
                </div>
              </div>

              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Block Font Color</div>

                <ColorInputField
                  ref={(el) => this.fontColorComponent = el}
                  editor={this.props.editor}
                  value={this.textFontColor}
                  onChange={this.handleFontColorChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={style.ibsTitle}
              style={{
                marginTop: '30px'
              }}
            >
              1st Block Settings
            </div>

            <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Text Background</div>

            <ColorInputField
              ref={(el) => this.firstTextBackgroundColorComponent = el}
              editor={this.props.editor}
              value={this.firstTextBackgroundColor}
              onChange={this.handleFirstTextBackgroundColorChange.bind(this)}
            />

            <div className={style.simpleSpaceRow}>
              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Button Color</div>

                <ColorInputField
                  ref={(el) => this.firstButtonBackgroundColorComponent = el}
                  editor={this.props.editor}
                  value={this.firstButtonBackgroundColor}
                  onChange={this.handleFirstButtonBackgroundColorChange.bind(this)}
                />
              </div>

              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Button Font Color</div>

                <ColorInputField
                  ref={(el) => this.firstButtonFontColorComponent = el}
                  editor={this.props.editor}
                  value={this.firstButtonFontColor}
                  onChange={this.handleFirstButtonFontColorChange.bind(this)}
                />
              </div>
            </div>

            <div
              className={style.ibsTitle}
              style={{
                marginTop: '30px'
              }}
            >
              2nd Block Settings
            </div>

            <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Text Background</div>

            <ColorInputField
              ref={(el) => this.secondTextBackgroundColorComponent = el}
              editor={this.props.editor}
              value={this.secondTextBackgroundColor}
              onChange={this.handleSecondTextBackgroundColorChange.bind(this)}
            />

            <div className={style.simpleSpaceRow}>
              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Button Color</div>

                <ColorInputField
                  ref={(el) => this.secondButtonBackgroundColorComponent = el}
                  editor={this.props.editor}
                  value={this.secondButtonBackgroundColor}
                  onChange={this.handleSecondButtonBackgroundColorChange.bind(this)}
                />
              </div>

              <div>
                <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Button Font Color</div>

                <ColorInputField
                  ref={(el) => this.secondButtonFontColorComponent = el}
                  editor={this.props.editor}
                  value={this.secondButtonFontColor}
                  onChange={this.handleSecondButtonFontColorChange.bind(this)}
                />
              </div>
            </div>
          </div>
        }

        <div
          className={style.ibsTitle}
          style={{
            marginTop: showStyleSettings ? '30px' : '0'
          }}
        >
          Plot Offers
        </div>

        <div className={style.poContent}>
          {this.state.plots.map((plot, pidx) =>
            <PlotOfferSettings
              ref={(el) => { if (el) { this.components.push(el) } }}
              key={`plot_${plot.id}`}
              data={plot}
              idx={pidx + 1}
              canBeDeleted={this.state.plots.length > 1}
              editor={this.props.editor}
              funnelId={this.props.funnelId}
              cache={this.props.cache}
              onDelete={() => this.handleDeletePlotOffer(plot)}
              onContentChange={this.props.onContentChange}
            />
          )}
        </div>

        {this.state.plots.length < this.maxNumPlots &&
          <div
            className={style.poAddPlot}
            onClick={this.handleAddPlotOffer.bind(this)}
          >
            <i className="icon-plus"/>
            <div>Add Plot</div>
          </div>
        }
      </div>
    );
  }
}

export {
  ButtonBlockSettings,
  ImageBlockSettings,
  SpacerBlockSettings,
  DividerBlockSettings,
  SocialsBlockSettings,
  ColumnBlockSettings,
  PlotOffersBlockSettings
}
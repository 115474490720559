import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import React from 'react'
import ReactDOM from 'react-dom'

import PreferencesModel from 'js/models/preferences'
import UsersCollection from 'js/collections/users.js'
import MessageBox from 'js/views/message_box'
import vent from 'js/vent'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import {TagSelect} from 'js/react_views/widgets/select'
import style from './team_management.css'


class TeamMemberView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            memberships: this.props.member.memberships || [],
            allMemberships: this.props.member.memberships || [],
        };

        this.membershipEls = [];
    }

    componentDidMount() {
        const userGroup = $(this.elUserGroup);
        const self = this;

        userGroup.draggable({
            handle: `.${style.tmHandler}`,
            cursor: 'move',
            helper: 'clone',
            revert: 'invalid',
            revertDuration: 250,
            zIndex: 999,
            start: function(ev, ui) {
                $(ui.helper).addClass(style.cloned);
                self.props.parent.setEnableMember(self.props.member.id, false);
            },
            stop: function(ev, ui) {
                self.props.parent.setEnableMember(self.props.member.id, true);
            }
        });

        const content = $(this.elContent);

        content.droppable({
            accept: `.${style.tmUserGroup}`,
            hoverClass: style.hovered,
            tolerance: 'pointer',
            drop: function(ev, ui) {
                const targetId = self.props.member.id;
                const sourceId = ui.draggable.attr('id');

                if (targetId !== sourceId) {
                    self.props.onMoved(sourceId, targetId);
                }
            }
        });

        // only display fully visible memberships
        let msVisibleWidth = this.membershipsEl.clientWidth;
        const msWidth = this.membershipsEl.scrollWidth;

        if (msWidth > msVisibleWidth) {
            let newMemberships = [];
            let accum = 0;
            const margin = 10;
            const xmoreElWidth = 58; // "+X more" message

            msVisibleWidth -= xmoreElWidth;

            for (let i = 0; i < this.state.memberships.length; ++i) {
                const elWidth = this.membershipEls[i].offsetWidth;

                if (accum + elWidth < msVisibleWidth) {
                    accum += elWidth + margin;
                    newMemberships.push(this.state.memberships[i]);
                }
            }

            this.setState({
                memberships: newMemberships
            });
        }
    }

    setEnable(enable) {
        $(this.elContent).toggleClass(style.disabled, !enable);
    }

    toggleSelection() {
        const selected = !this.state.selected;

        this.setState({
            selected: selected
        });

        this.props.onSelect(this.props.member.id, selected);
    }

    render() {
        const deepMargin = this.props.member.deep * 30;

        this.membershipEls = [];

        return (
            <div className={style.teamMember}>
                <div
                    className={`${style.tmSelector} ${this.state.selected ? `icon-checkmark ${style.tmSelected}` : 'icon-checkmark2'}`}
                    onClick={this.toggleSelection.bind(this)}
                />
                <div
                    ref={(el) => this.elContent = el}
                    className={style.tmContent}
                    style={{marginLeft: `${deepMargin}px`}}
                >
                    <div
                        id={this.props.member.id}
                        ref={(el) => this.elUserGroup = el}
                        className={style.tmUserGroup}
                        style={{
                            width: `calc(40% - ${deepMargin + 10}px)`,
                            minWidth: 'auto'
                        }}
                    >
                        <div className={style.tmHandler}/>
                        <div className={style.tmUser}>
                            <div className={style.uName}>{this.props.member.name}</div>
                            <div className={style.uRole}>{this.props.member.role}</div>
                        </div>
                    </div>
                    <div
                        ref={(el) => this.membershipsEl = el}
                        className={style.tmMemberships}
                        title={this.state.allMemberships.join(', ')}
                    >
                        {this.state.memberships.map((ms, msidx) =>
                            <div
                                ref={(el) => this.membershipEls.push(el)}
                                key={`ms_${msidx}`}
                                className={style.msName}
                            >
                                {ms}
                            </div>
                        )}
                        {this.state.memberships.length !== this.state.allMemberships.length &&
                            <div className={style.msMoreTeams}>
                                +{this.state.allMemberships.length - this.state.memberships.length} more
                            </div>
                        }
                    </div>
                    <div className={style.tmActions}>
                        <div
                            className={style.tmDelete}
                            onClick={() => this.props.onDelete(this.props.member.id)}
                        >
                            &#8213;
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class EditTeamMembersView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.team.name,
            description: this.props.team.description,
            status: this.props.team.status,
            members: this.getPlainMembersList(this.props.team.children || []),
            selectedMembers: [],
            tableIdx: 0
        };

        this.membersComponents = {};
    }

    componentDidMount() {
        const dropArea = $(this.tableHeaderEl);
        const self = this;

        dropArea.droppable({
            accept: `.${style.tmUserGroup}`,
            hoverClass: style.thHovered,
            tolerance: 'pointer',
            drop: function(ev, ui) {
                const sourceId = ui.draggable.attr('id');
                self.manageMemberMovement(sourceId, self.props.team.id);
            }
        });
    }

    getPlainMembersList(members) {
        let plain = [];

        const addMember = (member, parent, deep) => {
            let data = _.clone(member);

            data.name = member.user.name;
            data.role = PreferencesModel.roles[this.props.usersDashboard[member.user_id]].title;
            data.memberships = (this.props.teamsByUserId[member.user_id] || []).filter((team) => team !== this.props.team.name);
            data.deep = deep;
            data.parent = parent;
            data.grandpa = parent ? parent.parent : null;

            plain.push(data);

            if (member.children) {
                for (const c of member.children) {
                    addMember(c, data, deep + 1);
                }
            }
        };

        for (const m of members) {
            addMember(m, null, 0);
        }

        return plain;
    }

    setEnableMember(memberId, enable) {
        for (const member of this.state.members) {
            if (member.id === memberId || member.parent?.id === memberId || member.grandpa?.id === memberId) {
                this.membersComponents[member.id].setEnable(enable);
            }
        }
    }

    manageMemberSelection(memberId, selected) {
        let selectedMembers = this.state.selectedMembers;

        if (selected) {
            selectedMembers.push(memberId);
        } else {
            selectedMembers = _.without(selectedMembers, memberId);
        }

        this.setState({selectedMembers: selectedMembers});
    }

    manageMemberDeletion(memberId) {
        this.deleteMembers([memberId]);
    }

    getTeamHierarchyLength(team) {
        let length = 1;

        for (const child of team.children) {
            const clen = 1 + this.getTeamHierarchyLength(child);

            if (clen > length) {
                length = clen;
            }
        }

        return length;
    }

    manageMemberMovement(memberId, parentId) {
        const member = this.state.members.find(m => m.id === memberId);
        const parent = this.state.members.find(m => m.id === parentId);
        const deep = (parent ? 1 : 0) + this.getTeamHierarchyLength(member);

        if (!parent || deep < 4) {
            const self = this;

            $.ajax({
                type: 'PATCH',
                url: `/team2_members/${memberId}`,
                dataType: 'json',
                data: JSON.stringify({
                    parent_id: parentId
                }),
                success: () => {
                    self.fetchTeam();
                }
            });
        }
    }

    handleEditTeam() {
        const self = this;

        let teamData = _.clone(this.props.team);

        teamData.name = this.state.name;
        teamData.description = this.state.description;

        this.props.parent.showEditTeamView(teamData, function(data) {
            if (data) {
                $.ajax({
                    type: 'PATCH',
                    url: `/teams2/${self.props.team.id}`,
                    dataType: 'json',
                    data: JSON.stringify(data),
                    success: (response) => {
                        self.setState({
                            name: response.name,
                            description: response.description
                        });

                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Team updated',
                                    classes: 'saved success',
                                    timer: 3000
                                };
                            }
                        });
                    }
                });
            }
        });
    }

    handleAddTeamMemberClick() {
        const self = this;

        this.props.parent.showAddTeamMemberView(function(members) {
            if (members) {
                const validMembers = members.filter((m) => !self.state.members.find((m2) => m2.user_id === m.id));

                if (validMembers.length === 0) {
                    return;
                }

                let counter = 0;

                for (const member of validMembers) {
                    const data = {
                        user_id: member.id,
                        parent_id: self.props.team.id
                    };

                    ++counter;

                    $.post('/team2_members', JSON.stringify(data), (response) => {
                        --counter;

                        if (counter === 0) {
                            self.fetchTeam();
                        }
                    });
                }
            }
        });
    }

    fetchTeam() {
        const self = this;

        $.get(`/teams2/${this.props.team.id}?children=true`, (team) => {
            self.setState({
                members: this.getPlainMembersList(team.children),
                selectedMembers: [],
                tableIdx: this.state.tableIdx + 1
            });

            let teams = app.globalData.teams.map(t => t.id === team.id ? team : t);
            app.globalData.teams = teams;
        });
    }

    setStatus(newStatus) {
        if (newStatus === this.state.status) {
            return;
        }

        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/teams2/${this.props.team.id}`,
            dataType: 'json',
            data: JSON.stringify({status: newStatus}),
            success: (response) => {
                self.setState({
                    status: response.status
                });

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Team updated',
                            classes: 'saved success',
                            timer: 3000
                        };
                    }
                });
            }
        });
    }

    deleteMembers(members) {
        let counter = 0;
        const self = this;

        for (const m of members) {
            ++counter;

            $.ajax({
                type: 'DELETE',
                url: `/team2_members/${m}`,
                success: () => {
                    --counter;

                    if (counter === 0) {
                        self.fetchTeam();

                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: `${members.length > 1 ? 'Members' : 'Member'} deleted`,
                                    classes: 'saved success',
                                    timer: 3000
                                };
                            }
                        });
                    }
                }
            });
        }
    }

    render() {
        const team = this.props.team;
        this.membersComponents = {};

        return (
            <div className={style.editTeamMembersView}>
                <div
                    className={style.teamManagementHeader}
                    style={{justifyContent: 'flex-start'}}
                >
                    <div
                        className={style.backButton}
                        onClick={this.props.onBack}
                    >
                        <i className='icon-arrow-left'/>
                    </div>
                    <div className={style.title}>Team Management</div>
                </div>
                <div className={style.teamViewContent}>
                    <div className={style.teamInfo}>
                        <div className={style.tiThumbnail}>{this.state.name[0]}</div>
                        <div className={style.tiNameAndDesc}>
                            <div className={style.tiNameEdition}>
                                <div className={style.tiName}>{this.state.name}</div>
                                <div
                                    className={`${style.tiIcon} icon-pencil`}
                                    onClick={this.handleEditTeam.bind(this)}
                                />
                            </div>
                            <div className={style.tiDesc}>{this.state.description}</div>
                        </div>
                        <div className={style.tiNumUsers}>{this.state.members.length}</div>
                        <div className={style.tiStatus}>
                            <div
                                className={`${style.tiOff} ${this.state.status !== 'on' ? style.tiActive : ''}`}
                                onClick={() => this.setStatus.bind(this)('off')}
                            >
                                Off
                            </div>
                            <div
                                className={`${style.tiLive} ${this.state.status === 'on' ? style.tiActive : ''}`}
                                onClick={() => this.setStatus.bind(this)('on')}
                            >
                                Live
                            </div>
                        </div>
                    </div>
                    <div className={style.teamActions}>
                        <div
                            className={style.taButton}
                            style={{background: '#43d351'}}
                            onClick={this.handleAddTeamMemberClick.bind(this)}
                        >
                            Add Team Member
                        </div>
                        {this.state.selectedMembers.length > 0 &&
                            <div
                                className={style.taButton}
                                style={{background: '#e64b46'}}
                                onClick={() => this.deleteMembers.bind(this)(this.state.selectedMembers)}
                            >
                                Delete
                            </div>
                        }
                    </div>
                    <div
                        ref={(el) => this.tableHeaderEl = el}
                        className={style.teamTableHeader}
                    >
                        <div className={style.thColumn}/>
                        <div className={style.thColumn}>Team Members</div>
                        <div className={style.thColumn}>Team Memberships</div>
                        <div className={style.thColumn}/>
                    </div>
                    <div
                        key={`team_members_table_${this.state.tableIdx}`}
                        className={style.teamTableContent}
                    >
                        {this.state.members.map(member =>
                            <TeamMemberView
                                ref={(el) => this.membersComponents[member.id] = el}
                                key={`team_member_${member.id}`}
                                member={member}
                                parent={this}
                                onSelect={this.manageMemberSelection.bind(this)}
                                onDelete={this.manageMemberDeletion.bind(this)}
                                onMoved={this.manageMemberMovement.bind(this)}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

class AddTeamMemberView extends React.Component {
    constructor(props) {
        super(props);

        this.members = [];
    }

    handleAddClick() {
        this.props.onClose(this.members);
    }

    handleMembersChange(members) {
        this.members = members.map(m => {
            return {
                id: m.id,
                name: m.title
            };
        });
    }

    render() {
        return (
            <div className={style.editTeamView}>
                <div className={style.ntvBackdrop}/>
                <div className={style.ntvDialog}>
                    <div className={style.ntvdHeader}>
                        <div
                            className={style.ntvdhClose}
                            onClick={() => this.props.onClose(null)}
                        >
                            Close
                        </div>
                        <div className={style.ntvdhTitle}>Add Team Members</div>
                    </div>
                    <div className={style.ntvdContent}>
                        <div className={style.ntvdcRow}>
                            <div className={style.ntvdcrTitle}>Select a team member</div>
                            <div style={{width: '250px'}}>
                                <TagSelect
                                    url='/users'
                                    text='title'
                                    width='250'
                                    onChange={this.handleMembersChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div
                            className={style.ntvdcSave}
                            onClick={this.handleAddClick.bind(this)}
                        >
                            Add to Team
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class EditTeamView extends React.Component {
    constructor(props) {
        super(props);

        const team = this.props.team || {};

        this.state = {
            name: team.name || '',
            description: team.description || '',
            showError: false,
            editing: !!this.props.team
        };
    }

    componentDidMount() {
        this.inputName.focus();
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value,
            showError: false
        });
    }

    handleDescriptionChange(event) {
        this.setState({
            description: event.target.value
        });
    }

    handleSaveClick() {
        if (!this.state.name) {
            this.setState({showError: true});
        } else {
            this.props.onClose({
                name: this.state.name,
                description: this.state.description
            });
        }
    }

    render() {
        return (
            <div className={style.editTeamView}>
                <div className={style.ntvBackdrop}/>
                <div className={style.ntvDialog}>
                    <div className={style.ntvdHeader}>
                        <div
                            className={style.ntvdhClose}
                            onClick={() => this.props.onClose(null)}
                        >
                            Close
                        </div>
                        <div className={style.ntvdhTitle}>{`${this.state.editing ? 'Edit Team' : 'Create New Team'}`}</div>
                    </div>
                    <div className={style.ntvdContent}>
                        <div className={style.ntvdcRow}>
                            <div className={style.ntvdcrTitle}>Team Name</div>
                            <div>
                                <input
                                    type='text'
                                    ref={(el) => this.inputName = el}
                                    value={this.state.name}
                                    placeholder='Team Name'
                                    className={`${this.state.showError ? 'validation_error' : ''}`}
                                    onChange={this.handleNameChange.bind(this)}
                                />
                                {this.state.showError && <div className={style.ntvdcrError}>Please enter a Team name</div>}
                            </div>
                        </div>
                        <div className={style.ntvdcRow}>
                            <div className={style.ntvdcrTitle}>Description</div>
                            <textarea
                                rows='3'
                                style={{resize: 'none'}}
                                value={this.state.description}
                                placeholder='Add a short description of your Team'
                                onChange={this.handleDescriptionChange.bind(this)}
                            />
                        </div>
                        {!this.state.editing &&
                            <div
                                style={{
                                    marginLeft: '15%',
                                    marginTop: '20px',
                                    color: '#b2b8c1'
                                }}
                            >
                                <div>Add a Team name and description. Click save to</div>
                                <div>add your Team Members on the following screen.</div>
                            </div>
                        }
                        <div
                            className={style.ntvdcSave}
                            onClick={this.handleSaveClick.bind(this)}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class TeamsView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            teams: [],
            teamsByUserId: {},
            loadingTeams: false,
            addTeamMemberCallback: null,
            editTeam: null,
            editTeamMembers: null
        };

        this.teamsEls = [];

        const self = this;

        _.defer(() => self.fetchData());
    }

    fetchData() {
        this.setState({loadingTeams: true});

        let teams = null;
        let usersDashboard = null;
        let teamsByUserId = {};
        const self = this;

        const checkReady = () => {
            if (teams !== null && usersDashboard !== null) {
                self.setState({
                    teams: teams,
                    teamsByUserId: teamsByUserId,
                    usersDashboard: usersDashboard,
                    loadingTeams: false
                });
            }
        }

        $.get('/teams2?team_types=sales&children=true', (response) => {
            teams = response;

            for (let team of teams) {
                const usersId = this.getUsersId(team);

                for (const userId of usersId) {
                    if (!(userId in teamsByUserId)) {
                        teamsByUserId[userId] = [];
                    }

                    if (teamsByUserId[userId].indexOf(team.name) === -1) {
                        teamsByUserId[userId].push(team.name);
                    }
                }

                team.numUsers = usersId.length;
            }

            checkReady();
        });

        let usersCollection = new UsersCollection();

        usersCollection.fetch({
            success: (data) => {
                usersDashboard = {};

                for (const user of data.models) {
                    usersDashboard[user.get('id')] = (user.get('preferences') || {}).default_dashboard || 'team'
                }

                checkReady();
            }
        });
    }

    getUsersId(team) {
        let usersId = [];

        for (const child of team.children) {
            usersId.push(child.user_id);

            if (child.children.length > 0) {
                usersId = usersId.concat(this.getUsersId(child));
            }
        }

        return usersId;
    }

    handleEditTeamMembersClose() {
        this.setState({editTeamMembers: null});
        this.fetchData();
    }

    handleEditTeamMembersClick(team) {
        this.setState({editTeamMembers: team});
    }

    showEditTeamView(team, callback) {
        this.setState({
            editTeam: {
                team: team,
                callback: callback
            }
        });
    }

    showAddTeamMemberView(callback) {
        this.setState({addTeamMemberCallback: callback});
    }

    handleEditTeamClose(team) {
        this.state.editTeam.callback(team);
        this.setState({editTeam: null});
    }

    handleNewTeam() {
        const self = this;
        this.showEditTeamView(null, function(team) {
            if (team) {
                const teamData = {
                    name: team.name,
                    description: team.description,
                    status: 'off',
                    team_type: 'sales'
                };

                $.post('/teams2', JSON.stringify(teamData), (newTeam) => {
                    newTeam.numUsers = 0;

                    self.setState({teams: self.state.teams.concat(newTeam)});

                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Team saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });

                    _.defer(() => {
                        self.teamsEls[self.teamsEls.length - 1].scrollIntoView({
                            block: 'nearest'
                        });
                    });
                });
            }
        });
    }

    handleAddTeamMemberClose(members) {
        this.state.addTeamMemberCallback(members);
        this.setState({addTeamMemberCallback: null});
    }

    deleteTeam(team) {
        const self = this;

        MessageBox.showYesNo(
            {
                message: Handlebars.compile(
                    'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours. </strong>' +
                    `Are you sure you want to delete this team?<strong class="cta">${_.escape(team.name)}</strong>`),
                icon: 'icon-trashcan',
                accept_is_negative: true,
                accept_button_text: 'Delete',
                cancel_button_text: 'Cancel'
            },
            this.props.parent,
            () => { // yes
                $.ajax({
                    type: 'DELETE',
                    url: `/teams2/${team.id}`,
                    success: () => {
                        const teams = self.state.teams.filter((t) => t.id !== team.id);

                        self.setState({teams: teams});

                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Team deleted',
                                    classes: 'saved success',
                                    timer: 3000
                                };
                            }
                        });
                    }
                });
            }
        );
    }

    render() {
        this.teamsEls = [];

        return (
            <div>
                {!this.state.editTeamMembers ? (
                    <div className={style.teamsView}>
                        <div className={style.teamManagementHeader}>
                            <div className={style.title}>Team Management</div>
                            <div
                                className={style.newButton}
                                onClick={this.handleNewTeam.bind(this)}
                            >
                                New Team
                            </div>
                        </div>
                        <div className={style.description}>Create and manage teams and team hierarchy</div>
                        <div className={style.tableHeader}>
                            <div className={style.column}>Team + Description</div>
                            <div className={style.column}>Users</div>
                            <div className={style.column}>Status</div>
                            <div
                                className={style.column}
                                style={{width: '80px'}}
                            />
                        </div>
                        {!this.state.loadingTeams ?
                            (
                                <div className={style.tableRows}>
                                    {this.state.teams.map((team, tidx) => {
                                        return (
                                            <div
                                                ref={(el) => this.teamsEls.push(el)}
                                                key={`team_${tidx}`}
                                                className={style.row}
                                            >
                                                <div className={style.innerRow}>
                                                    <div className={style.cell}>
                                                        <div className={style.thumbnail}>{team.name[0]}</div>
                                                        <div className={style.teamNameAndDesc}>
                                                            <div className={style.teamName}>{team.name}</div>
                                                            <div className={style.teamDesc}>{team.description}</div>
                                                        </div>
                                                    </div>
                                                    <div className={style.cell}>
                                                        <div className={style.numUsers}>{team.numUsers}</div>
                                                    </div>
                                                    <div className={style.cell}>
                                                        <div className={`${style.status} ${team.status === 'on' ? style.live : ''}`}>{team.status === 'on' ? 'Live' : 'Off'}</div>
                                                    </div>
                                                    <div className={style.cell}>
                                                        <div className={style.actions}>
                                                            <div
                                                                className='icon-pencil'
                                                                onClick={() => this.handleEditTeamMembersClick.bind(this)(team)}
                                                            />
                                                            <div
                                                                className={`${team.status === 'on' ? style.disabled : ''}`}
                                                                onClick={() => this.deleteTeam.bind(this)(team)}
                                                            >
                                                                &#8213;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div style={{marginTop: '30px'}}><LoadingIndicator/></div>
                            )
                        }
                    </div>
                ) : (
                    <EditTeamMembersView
                        team={this.state.editTeamMembers}
                        teamsByUserId={this.state.teamsByUserId}
                        usersDashboard={this.state.usersDashboard}
                        parent={this}
                        onBack={this.handleEditTeamMembersClose.bind(this)}
                    />
                )}

                {this.state.editTeam &&
                    <EditTeamView
                        team={this.state.editTeam.team}
                        onClose={this.handleEditTeamClose.bind(this)}
                    />
                }

                {this.state.addTeamMemberCallback &&
                    <AddTeamMemberView
                        onClose={this.handleAddTeamMemberClose.bind(this)}
                    />
                }
            </div>
        );
    }
}


export default Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <TeamsView
                parent={this}
            />,
            this.$el.get(0)
        );
    }
});

import React from 'react';
import classnames from 'classnames';
import _ from 'underscore';

import Currency from 'js/utils/currency';
import AppConfig from 'app/app-config';
import Checkbox from 'js/react_views/widgets/checkbox';

import style from './deal-list.css';

class DealList extends React.Component {
    composeFunnelDisplayText(funnel) {
        const region = funnel.region;
        const funnelRegionId = ( region && region.integration_data && region.integration_data.region_id ) ? `(${region.integration_data.region_id}) ` : '';
        const funnelCoinsCode = ( funnel.integration_data && funnel.integration_data.coinsCode ) ? `(${funnel.integration_data.coinsCode}) ` : '' ;

        return funnelRegionId + funnelCoinsCode + funnel.name;
    }

    render() {
        const { deals, onDealNavigate, dealsSelection, onDealSelect } = this.props;
        const selectionEnabled = onDealSelect && _.isObject(dealsSelection);

        return (
            <ul className={style.DealList}>
                {_.map(deals, (deal) => {
                    let selected = false;
                    if (_.isObject(dealsSelection)) {
                        selected = dealsSelection[deal.id];
                    }

                    const bubbleText = AppConfig.getClientPreferenceValue('showDealIdOnBubble') ? deal.abbreviation : deal.name;

                    return (
                        <li
                            className={style.item}
                            key={deal.id}
                            onClick={(e) => {
                                e.preventDefault();
                                onDealNavigate(deal);
                            }}>
                            <div className={style.itemInner}>
                                {selectionEnabled &&
                                    <div className={style.itemCheckbox}>
                                        <Checkbox
                                            checked={selected}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDealSelect(deal);
                                            }} />
                                    </div>
                                }
                                <div className={style.itemAbbr}>
                                    <div className={style.itemAbbrInner}>
                                        {bubbleText}
                                    </div>
                                </div>
                                <div className={style.itemName}>
                                    <a
                                        className={style.itemNameLink}
                                        href={'#/deals/' + deal.id}
                                        onClick={(e) => {e.preventDefault();}}>
                                        {deal.name}
                                    </a>
                                </div>
                                <div className={style.itemInfo}>
                                    {deal.currency && !!deal.default_value && AppConfig.getValue('individuals.overview.related_deals.value.visible', true) &&
                                        <div>{Currency.format(deal.currency, deal.value)}</div>
                                    }
                                    <small className={style.itemPhase}>
                                        {deal.phase.name}
                                    </small>
                                    <div>
                                        <span className={style.itemFunnelName}>
                                            {this.composeFunnelDisplayText(deal.funnel)}
                                        </span>
                                        <i
                                            className={classnames(
                                                'icon-funnel-sm',
                                                style.itemFunnelIcon
                                            )} />
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

export default DealList;

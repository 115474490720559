import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import loadingTemplate from 'templates/loading.handlebars'


var LoadingView = Marionette.Layout.extend( {
    className: 'loading-view',
    template: Handlebars.compile( loadingTemplate )
} );

export default LoadingView;

import app from 'js/app';

export default class FinderContentManager {
    constructor() {
        this.sections = [];
        this.activeSectionIdx = -1;
        this.activeGroupId = null;
    }

    clear() {
        this.sections = [];
        this.activeSectionIdx = -1;
        this.activeGroupId = null;
    }

    addSections(sections, activeSectionIdx, activeGroupId) {
        for (const s of sections) {
            let section = _.clone(s);

            if (!('content' in section)) {
                section.content = [];
            }

            this.sections.push(section);
        }

        this.activeSectionIdx = activeSectionIdx || 0;
        this.activeGroupId = activeGroupId || null;
    }

    createAdhocGroup(id, name, icon, fetchArgs, options) {
        let group = {
            type: 'group',
            id: id,
            name: name,
            icon: icon,
            pinned: true,
            adhoc: true,
            fetchArgs: fetchArgs || null
        };

        if (options) {
            for (const option of ['section']) {
                if (option in options) {
                    group[option] = options[option]
                }
            }
        }

        return group;
    }

    // @DEPRECATED
    createSpecialGroup(id, name, icon, options) {
        return {
            type: 'group',
            id: id,
            shortId: id,
            name: name,
            icon: icon,
            fixed: true,
            pinned: true,
            special: true,
            fetchArgs: options?.fetchArgs || null,
            defaultView: options?.defaultView || null,
            badge: options?.badge || null,
            actions: options?.actions || null,
            toolbar: options?.toolbar || null
        };
    }

    addFolder(id, name, fixed, content) {
        const section = this.getActiveSection();

        if (!section) {
            return;
        }

        const folder = {
            type: 'folder',
            id: id,
            name: name,
            fixed: !!fixed,
            content: _.clone(content || [])
        };

        section.content.unshift(folder);

        return folder.id;
    }

    createGroup(group) {
        const newGroup = {
            type: 'group',
            id: `groups/${group.id}`,
            shortId: group.id,
            name: group.name,
            groupType: group.group_type,
            icon: group.group_type === 'static' ? 'icon-list' : 'icon-smart-group',
            fetchArgs: { group_id: group.id }
        };

        return newGroup;
    }

    getActiveSection() {
        return this.sections[this.activeSectionIdx];
    }

    getSections() {
        return this.sections;
    }

    getActiveGroup() {
        if (!this.sections[this.activeSectionIdx] || !this.activeGroupId) {
            return null;
        }

        return this.getElementContent(this.sections[this.activeSectionIdx].content, this.activeGroupId);
    }

    getActiveGroupId() {
        return this.activeGroupId;
    }

    setActiveGroupId(groupId) {
        this.activeGroupId = groupId;
    }

    setElementName(elementId, name) {
        const section = this.getActiveSection();

        if (!section) {
            return;
        }

        let element = this.getElementContent(section.content, elementId);

        if (!element) {
            return;
        }

        element.name = name;
        section.content = this.sortContent(section.content);
    }

    setElementPinState(elementId, pinned) {
        const section = this.getActiveSection();

        if (!section) {
            return;
        }

        this.setElementPinStateContent(section.content, elementId, pinned);
    }

    setElementPinStateContent(content, elementId, pinned) {
        for (const e of content) {
            if (elementId === e.id) {
                e.pinned = pinned;

                if (e.type === 'folder') {
                    this.setElementPinStateContent(e.content, null, pinned);
                }
            } else if (e.type === 'folder') {
                this.setElementPinStateContent(e.content, elementId, pinned);
            }
        }
    }

    moveElement(elementId, parentId) {
        const element = this.getElement(elementId);
        const parent = parentId ? this.getElement(parentId) : this.getActiveSection();

        if (!element || !parent) {
            return;
        }

        this.deleteElement(elementId);

        parent.content.push(element);
        parent.content = this.sortContent(parent.content);
    }

    deleteElement(elementId) {
        const section = this.getActiveSection();

        if (!section) {
            return false;
        }

        this.deleteElementContent(section.content, elementId);
    }

    deleteElementContent(content, elementId) {
        for (const [idx, e] of content.entries()) {
            if (e.id === elementId) {
                content.splice(idx, 1);
                return true;
            }

            if (e.type === 'folder') {
                const deleted = this.deleteElementContent(e.content, elementId);

                if (deleted) {
                    return true;
                }
            }
        }

        return false;
    }

    getElement(elementId) {
        const section = this.getActiveSection();

        if (!section) {
            return null;
        }

        return this.getElementContent(section.content, elementId);
    }

    getElementContent(content, elementId) {
        for (const e of content) {
            if (e.id === elementId) {
                return e;
            } else if (e.type === 'folder') {
                const element = this.getElementContent(e.content, elementId);

                if (element) {
                    return element;
                }
            }
        }

        return null;
    }

    getFirstGroup() {
        const section = this.getActiveSection();

        if (!section) {
            return null;
        }

        return this.getFirstGroupContent(section.content);
    }

    getFirstGroupContent(content, elementId) {
        for (const e of content) {
            if (e.type === 'group') {
                return e;
            } else if (e.type === 'folder') {
                const element = this.getFirstGroupContent(e.content);

                if (element) {
                    return element;
                }
            }
        }

        return null;
    }

    saveUserStructure() {
        const section = this.getActiveSection();
        let structure = [];

        for (const item of section.content) {
            if (item.pinned || item.dynamic) {
                continue;
            }

            if (item.type === 'folder') {
                const def = {
                    id: item.id,
                    name: item.name,
                    open: !!item.opened,
                    groups: []
                };

                for (const child of item.content) {
                    if (child.type === 'group') {
                        def.groups.push(child.shortId || child.id);
                    }
                }

                structure.push(def);
            } else {
                structure.push(item.shortId || item.id);
            }
        }

        let entityType = section.entityType;

        if (entityType === 'deals') {
            entityType = 'opportunities';
        }

        const key = `${entityType}_groups`;
        app.user.updatePreference(key, structure);
    }

    fetchUserStructure(entityType, callback, filter, elementCallback) {
        const blueprint = app.user.get('preferences')[`${entityType}_groups`] || [];
        const self = this;

        $.get(`/groups?start=0&rows=-1&element_type=${entityType}`, function(result) {
            const content = self.parseContent(result, blueprint, filter, false, elementCallback);
            callback(self.sortContent(content));
        });
    }

    isElementInsideFolder(elementId) {
        const section = this.getActiveSection();

        if (!section) {
            return false;
        }

        return !section.content.find(e => e.id === elementId);
    }

    sortContent(content) {
        let fixeds = [];
        let folders = [];
        let groups = [];

        for (const e of content) {
            if (e.fixed) {
                fixeds.push(e);
            } else if (e.type === 'folder') {
                e.content = this.sortContent(e.content);
                folders.push(e);
            } else {
                groups.push(e);
            }
        }

        return [...fixeds, ..._.sortBy(folders, f => f.name.toLowerCase()), ..._.sortBy(groups, g => g.name.toLowerCase())];
    }

    parseContent(groupsList, blueprint, filter, isAFolder, elementCallback) {
        let folders = [];
        let groups = [];

        for (const def of blueprint) {
            if (_.isObject(def)) { // it's a folder
                const data = {
                    type: 'folder',
                    id: def.id,
                    name: def.name,
                    opened: !!def.open,
                    content: this.parseContent(groupsList, def.groups, filter, true, elementCallback)
                };

                if (elementCallback) {
                    elementCallback(data);
                }

                folders.push(data);
            } else {
                if (def.length === 36) { // it's a uuid - todo: implement a better uuid checker
                    const group = groupsList.find(g => g.id === def);

                    if (group && (!filter || filter(group))) {
                        const data = {
                            type: 'group',
                            id: `groups/${group.id}`,
                            shortId: group.id,
                            name: group.name,
                            groupType: group.group_type,
                            icon: group.group_type === 'static' ? 'icon-list' : 'icon-smart-group',
                            fetchArgs: { group_id: group.id },
                            protected: !!group.display_options?.protected,
                            permissions: group.permissions
                        };

                        if (elementCallback) {
                            elementCallback(data);
                        }

                        groups.push(data);
                    }
                }
            }
        }

        if (!isAFolder) {
            for (const group of groupsList) {
                const gid = `groups/${group.id}`;

                if (groups.find(g => g.id === gid)) {
                    continue;
                }

                const groupInsideFolder = false;

                for (const folder of folders) {
                    if (this.getElementContent(folder.content, gid)) {
                        groupInsideFolder = true;
                        break;
                    }
                }

                if (groupInsideFolder) {
                    continue;
                }

                if (filter && !filter(group)) {
                    continue;
                }

                const data = {
                    type: 'group',
                    id: `groups/${group.id}`,
                    shortId: group.id,
                    name: group.name,
                    groupType: group.group_type,
                    icon: group.group_type === 'static' ? 'icon-list' : 'icon-smart-group',
                    fetchArgs: { group_id: group.id },
                    protected: !!group.display_options?.protected,
                    permissions: group.permissions
                };

                if (elementCallback) {
                    elementCallback(data);
                }

                groups.push(data);
            }
        }

        return [...folders, ...groups];
    }
}
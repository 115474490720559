import $ from 'jquery'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import UserModel from 'js/models/user'
import app from 'js/app'
import vent from 'js/vent'
import api from 'js/api'
import salesseekLoginTemplate from 'templates/login_salesseek.handlebars'


const SalesSeekLoginView = BaseView.extend({
    tagName: 'article',
    id: 'login-container',
    template: Handlebars.compile(salesseekLoginTemplate),
    templateHelpers: function() {
        const logo = app.options.clientDetails.logo;
        let logoSrc = null;

        if (logo) {
            if (logo.ext === '.svg') {
                $.ajax({
                    url: logo.url,
                    type: 'GET',
                    async: false,
                    success: function (data) {
                        logoSrc = `data:image/svg+xml;base64,${btoa(data)}`;
                    }
                });
            }
            else {
                logoSrc = logo.url;
            }
        }

        return {
            logoSrc: logoSrc,
            formClass: logoSrc ? 'with-logo' : ''
        };
    },
    ui: {
        'name': 'legend h3 span',
        'login_error_msg': '#login-error-msg'
    },
    events: {
        "click #login"      : "login",
        "click #forgotten"  : "forgotten",
        "keyup #email"      : "setEmail",
        "change #email"     : "setEmail",
        "input #email"      : "setEmail",
        "keyup #password"   : "setPassword",
        "change #password"  : "setPassword",
        "input #password"   : "setPassword"
        // TODO: "Stay signed in" event handler
    },
    initialize: function () {
        BaseView.prototype.initialize.apply(this, arguments);
    },
    onRender: function(){
        this.listenTo(this.model, "validated", this.validated, this);

        var view = this,
            options = app.options;

        if (options.clientDetails?.name) {
            view.ui.name.text(options.clientDetails.name);
        }

        this.setFieldsContent();
    },
    onShow: function() {
        this.$el.fadeIn(500);
        this.setEmail();
        this.setPassword();
    },
    forgotten: function (ev) {
        ev.preventDefault();
        vent.trigger('forgotten');
    },
    validated: function(valid) {
        var loginButton = this.$el.find("#login");
        if (valid) {
            loginButton.removeAttr("disabled");
        } else {
            loginButton.attr("disabled", "true");
        }
    },
    setFieldsContent: function() {
        this.setEmail();
        this.setPassword();

        var view = this;
        setTimeout(function() {
            if (view.isClosed) {
                return;
            }

            // we stop when we have an email or password
            view.setFieldsContent();
        }, 100);
    },
    setEmail: function(e){
        this.model.set({
            email: this.$el.find("#email").val()
        });
    },
    setPassword: function(){
        this.model.set({
            password: this.$el.find("#password").val()
        });
    },
    login: function(){
        this.setEmail();
        this.setPassword();

        var email = this.model.get('email').trim(),
            password = this.model.get('password'),
            view = this,
            next = this.options.cameFrom ?
                this.options.cameFrom.replace(/^\/+/, '') : null;

        api.login(null, email, password,
            function(user, error, otpPassed) {
                if (user) {
                    app.user = new UserModel(user);
                }

                if (user) {
                    if (otpPassed === 'false') {
                        vent.trigger('login:2fa_required', next);
                    } else {
                        if (app.user.get('password_reset_required')) {
                            vent.trigger('login:password_change',
                                         app.user, next);
                        } else {
                            vent.trigger('login:success', next);
                        }
                    }
                } else {
                    if (error.exception === 'UserPasswordResetRequired') {
                        vent.trigger('reset', error.token);
                    } else {
                        var login_err_msg = 'Your email or password is incorrect';

                        if (error.exception == 'UserLoginDisabledError') {
                            login_err_msg = 'This user account`s purpose excludes login capabilities'
                        }
                        if (error.exception == 'UserLoginTooManAttemptsError') {
                            login_err_msg = 'You have reached the maximum number of login attempts. You will be locked out for 10 mins.'
                        }

                        view.ui.login_error_msg.html(login_err_msg);

                        $('#login-form .alert').fadeIn(300);
                        view.trigger('login:failure');
                    }
                }
            });
        return false;
    },
    getUrl: function () {
        var result = 'login';
        if (this.options.cameFrom) {
            result += (
                '?came_from=' +
                encodeURIComponent(this.options.cameFrom)
                );
        }
        return result;
    }
});

export default SalesSeekLoginView;

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import security from 'js/utils/security'
import ItemPermissionsView from 'js/views/item_permissions'
import ContentFolderModel from 'js/models/content_folder'
import ContentFileModel from 'js/models/content_file'
import ModalRegion from 'js/views/base/modal-region'
import NoCollectionView from 'js/views/base/no_collection'
import HTMLEditorView from 'js/views/html_editor'
import MessageBox from 'js/views/message_box'
import Utilities from 'js/utils/utilities'
import contentViewTemplate from 'templates/content/content.handlebars'
import libraryViewTemplate from 'templates/content/library_view.handlebars'
import treeViewTemplate from 'templates/content/tree_view.handlebars'
import treeItemViewTemplate from 'templates/content/tree_item_view.handlebars'
import childListTemplate from 'templates/content/child_list_view.handlebars'
import folderListItemViewTemplate from 'templates/content/folder_list_item_view.handlebars'
import fileListItemViewTemplate from 'templates/content/file_list_item_view.handlebars'
import addNewFolderTemplate from 'templates/content/add_new_folder.handlebars'
import uploadsPanelTemplate from 'templates/content/uploads-panel.handlebars'
import uploadItemTemplate from 'templates/content/upload-item.handlebars'


var ContentView, TreeView, TreeItemView, ListView, ListFolderItemView, ListFileItemView, NewFolderView, LibraryView,
    UploadsPanelView, UploadItemView;

const SUPPORTEDEXTENSIONS = [
    // IMAGES
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.tiff',
    '.webp',
    '.svg',

    // DOCUMENTS
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.pp',
    '.ppx',
    '.csv',
    '.odt',
    '.fodt',
    '.ods',
    '.fods',
    '.odp',
    '.fodp',
    '.odg',
    '.fodg',
    '.odf',
    '.txt',
    '.rtf',
]

function fileIsEditable(model) {
    var ext = model.get('ext') ? model.get('ext') : '';
    return (['.htm', '.html', '.txt'].indexOf(ext) !== -1);
}

TreeItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tree-item',
    attributes: function() {
        return { folder_id: this.model.get('id') };
    },
    template: Handlebars.compile(treeItemViewTemplate),
    templateHelpers: function() {
        return {
            name: this.model.get('short_id') === 'public' ? 'File Hosting' : this.model.get('name'),
        };
    },
    ui: {
        childrenContainer: '.children',
        title: '.title'
    },
    events: {
        'click .basic-list-item': function(ev) {
            if ($(ev.target).is('.expand-icon')) {
                return;
            }

            ev.stopPropagation();

            var self = this;
            this.model.fetch({
                url: this.model.url() + '?detail=extended',
                success: function() {
                    self.content.showLibraryView(self.model);
                }
            });
        },
        'click .basic-list-item .expand-icon': function(ev) {
            var self = this;

            ev.stopPropagation();

            // Only perform a fresh fetch when the folder expands
            if (!this.$el.hasClass('expanded')) {
                this.model.fetch({
                    url: this.model.url() + '?detail=extended',
                    success: function() {
                        self.renderChildren();
                        // Tell the parent that this has expanded
                        self.trigger('item:expanded', self.model);
                    }
                });
            }
            this.$el.toggleClass('expanded');
        }
    },
    initialize: function(options) {
        this.content = options.content;

        this.listenTo(this.model, 'change:name', function(model, name) {
            this.ui.title.text(name);
        });
    },
    renderChildren: function() {
        var self = this,
            model = this.model,
            childFolders = model.get('child_folders');

        self.ui.childrenContainer.empty();

        var systemFolders = [];
        var regularFolders = [];

        _.each(childFolders, function(child) {
            var contentFolderModel = new ContentFolderModel(child),
                treeItem = new TreeItemView({
                    model: contentFolderModel,
                    content: self.content
                });

            self.listenTo(treeItem, 'item:expanded', function(folderModel) {
                this.trigger('item:expanded', folderModel);
            });

            // if the folder doesnt have owner, it is a system folder
            if (contentFolderModel.get('owner')) {
                regularFolders.push(treeItem);
            }
            else {
                systemFolders.push(treeItem);
            }
        });

        // system folders should be on the top of the list
        for (var i =0; i < systemFolders.length; ++i) {
           self.ui.childrenContainer.append(systemFolders[i].render().$el);
        }

        for (var i =0; i < regularFolders.length; ++i) {
           self.ui.childrenContainer.append(regularFolders[i].render().$el);
        }

        if (childFolders) {
            this.$el.addClass('has-children');
        }
    }
});

TreeView = Marionette.Layout.extend({
    className: 'list-wrapper has-sticky-nav at-top',
    template: Handlebars.compile(treeViewTemplate),
    ui: {
        tree: '.files-tree',
        publicFiles: '.public-tree'
    },
    events: {
        'click .new-tree-item': function() {
            this.content.showNewFolderView();
        }
    },
    initialize: function(options) {
        this.content = options.content;
        this.modelId = options.modelId;
    },
    onRender: function() {
        var self = this,
            content = this.content;

        this.listenTo(self.content, 'folder:select', self.selectCurrentFolder);

        this.listenTo(this.content, 'folder:new', function(contentFolder) {
            this.addFolder(contentFolder);
        });

        this.listenTo(this.content, 'delete:folder', function(contentFolder) {
            this.removeFolder(contentFolder);
        });

        this.publicModel = new ContentFolderModel({ id: 'public', is_public: true });
        this.publicModel.fetch({
            url: this.publicModel.url() + '?detail=extended',
            success: function() {
                var rootItemView = new TreeItemView({
                    model: self.publicModel,
                    content: content
                });
                self.ui.publicFiles.html(rootItemView.render().$el);

                // Expand root folder by default
                rootItemView.renderChildren();
                rootItemView.$el.addClass('has-children expanded');

                self.$el.find('.mail-nav-container.sub-section-nav').show();

                self.scrollbar();
                self.$el.find('.content-container:first > .content').scroll(self.scrollEvents.bind(self));

                // When an item is expanded, recalculate scrollbar
                self.listenTo(rootItemView, 'item:expanded', self.expandFolder);
            }
        });

        app.shortTermCache.get('/content_folders/root', {
            detail: 'extended'
        }, function(data) {
            self.model = new ContentFolderModel(data);

            self.content.showLibraryView(new ContentFolderModel({
                id: self.modelId || self.model.get('id')
            }));

            var rootItemView = new TreeItemView({
                model: self.model,
                content: content
            });
            self.ui.tree.html(rootItemView.render().$el);

            // Expand root folder by default
            rootItemView.renderChildren();
            rootItemView.$el.addClass('has-children expanded');

            self.$el.find('.mail-nav-container.sub-section-nav').show();

            self.scrollbar();
            self.$el.find('.content-container:first > .content').scroll(self.scrollEvents.bind(self));

            // When an item is expanded, recalculate scrollbar
            self.listenTo(rootItemView, 'item:expanded', self.expandFolder);
        });
    },
    addFolder: function(folderModel) {
        var treeViewItem = new TreeItemView({
            model: folderModel,
            content: this.content
        });

        this.$el.find("[folder_id='" + this.content.folder.get('id') + "']").find('.children:first').append(
            treeViewItem.render().$el
        );
    },
    removeFolder: function(folderModel) {
        this.$el.find("[folder_id='" + folderModel.get('id') + "']").remove();
    },
    selectCurrentFolder: function(folder) {
        this.highlightItem(folder);
    },
    highlightItem: function(type) {
        var activeEl;
        if (type instanceof ContentFolderModel) {
            activeEl = this.$el.find("[folder_id='" + type.get('id') + "']");
        }

        this.$el.find('li.active').not(activeEl).removeClass('active');
        activeEl.addClass('active');
    },
    expandFolder: function(folderModel) {
        this.selectCurrentFolder(folderModel);
        this.scrollbar();
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        this.$el.find('.content-container:first').nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.$el.find('.content-container:first > .content');

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

function initPane(context) {
    context.ui.menu.showPane = function(pane) {
        var previous = $(this).find('.popover-pane.active');

        previous.removeClass('active');
        pane.addClass('active');
    };
    context.ui.menu.resetPanes = function() {
        var root = $(this).find('.popover-pane.root');
        this.showPane(root);
    };
}

ListFolderItemView = Marionette.Layout.extend({
    tagName: 'tr',
    className: function() {
        return this.model.get('description').length ? 'has-description' : '';
    },
    template: Handlebars.compile(folderListItemViewTemplate),
    ui: {
        name: '.name',
        fieldName: '.field-name',
        editName: '.edit-name',
        description: '.description',
        fieldDescription: '.field-description',
        editDescription: '.edit-description',
        menu: '.item-menu',
        tooltips: '[data-toggle=tooltip]',
        confirmDeleteName: '.confirm-delete-name'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .item-title': 'showFolder',
        'click .thumb i': 'showFolder',
        'click .info-icon': function() {
            this.ui.description.slideToggle(300);
            this.$el.toggleClass('info-expanded');
        },
        'click .item-menu-toggle': function(e) {
            var menu = this.ui.menu;

            // offsetParent work only on shown items
            menu.show();

            this.positionTarget = $(e.currentTarget);
            Utilities.positionMenu(menu, this.positionTarget);

            $(document).on('mousedown.close-item-menu', function(ev) {
                if (!$.contains( ev.target, menu ) && !$(ev.target).closest( menu ).length) {
                    menu.stop().hide();
                    $(this).off('mousedown.close-item-menu');
                }
            });
        },
        'click .delete-item': function() {
            var pane = this.$el.find('.delete-confirm-pane');
            this.ui.menu.showPane(pane);
            Utilities.positionMenu(this.ui.menu, this.positionTarget);
        },
        'click .delete-confirm-pane .cancel': function(ev) {
            ev.preventDefault();
            this.ui.menu.resetPanes();
            Utilities.positionMenu(this.ui.menu, this.positionTarget);
        },
        'click .delete-confirm': function() {
            var self = this;
            this.ui.menu.hide();
            $(document).off('mousedown.close-item-menu');
            this.model.destroy({
                wait: true,
                success: function() {
                    self.content.trigger('delete:folder', self.model);
                },
                error: function(model, attributes, settings) {
                    // override default alert with custom error message
                    var mbContent = {
                        message: JSON.parse(settings.xhr.responseText).detail.message,
                        icon: 'icon-warning',
                    };

                    vent.trigger("alert:hide", true);
                    MessageBox.showOk(mbContent, self);
                }
            });
        }
    },
    remove: function(){
        var self = this;
        this.$el.children('td')
            .animate({
                paddingTop: 0,
                paddingBottom: 0,
                opacity: 0
            }, 200)
            .wrapInner('<div />')
            .children()
            .slideUp(200, function() {
                Marionette.Layout.prototype.remove.call(self);
            });
    },
    templateHelpers: function() {
        return {
            is_public: this.model.get('is_public'),
            owner_name: this.model.get('owner') && this.model.get('owner')['name'],
            not_protected: !this.model.get('protected')
        };
    },
    initialize: function(options) {
        var self = this;

        this.content = options.content;

        this.model.on('change:description', function(model, val) {
            if (val.length) {
                self.$el.addClass('has-description');
            } else {
                $(self.ui.description).hide(); // Needs to be wrapped in jquery selector for some reason??
                self.$el.removeClass('info-expanded has-description');
            }
        });
    },
    onRender: function() {
        this.activateElement(this.ui.name, this.ui.fieldName, this.ui.editName, {field: 'name'}, function(val) {
            this.ui.confirmDeleteName.text(val);
        });
        this.activateElement(
                this.ui.description, this.ui.fieldDescription, this.ui.editDescription, {field: 'description'}
            );
        this.ui.tooltips.tooltip();
        initPane(this);
    },
    activateElement: function(text, edit, editButton, dbField, extraSuccess) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            cancelButton = container.find('.cancel'),
            prevValue;

        function hideEdit(restorePrevValue) {
            if (restorePrevValue) {
                self.model.set(dbField.field, prevValue);
                edit.val(prevValue);
            }
            container.removeClass('editing');
            self.ui.menu.resetPanes();
            $(document).off('mousedown.cancel-editing');
        }

        editButton.on('click', function() {
            prevValue = self.model.get(dbField.field);
            container.addClass('editing');
            self.ui.menu.showPane(container);
            Utilities.positionMenu(self.ui.menu, self.positionTarget);
            edit.focus();

            $(document).on('mousedown.cancel-editing', function(ev) {
                if(!$.contains( ev.target, container ) && !$(ev.target).closest( container ).length ) {
                    hideEdit(true);
                }
            });
        });

        container.submit(function() {
            var val = edit.val(),
                attrs = {},
                model = self.model;

            attrs[dbField.field] = val;
            model.save(attrs, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit(false);
                    if (extraSuccess) {
                        extraSuccess.call(self, val);
                    }
                },
                error: function(model, response) {
                    hideEdit(true);
                    if (response.responseText) {
                        var rdo = JSON.parse(response.responseText);

                        if (rdo.detail.exception === 'NameAlreadyExistsError') {
                            var mbContent = {
                                message: Handlebars.compile('The name "{{name}}" is already taken. Please choose a different name.')({name: val}),
                                icon: 'icon-blocked',
                            };

                            MessageBox.showOk(mbContent, self);
                        }
                    }
                }
            });
            return false;
        });

        cancelButton.on('click', function(ev) {
            ev.preventDefault(); // Prevent form submit
            hideEdit(true);
            Utilities.positionMenu(self.ui.menu, self.positionTarget);
        });
    },
    showFolder: function() {
        var model = this.model;

        model.fetch({
            url: model.url() + '?detail=extended',
            success: this.content.showLibraryView.bind(this.content, model)
        });
    }
});

ListFileItemView = Marionette.Layout.extend({
    tagName: 'tr',
    className: function() {
        var hasDescription = this.model.get('description').length ? ' has-description' : '';
        var fileType = Utilities.getTypeIcon(this.model.get('ext')).type;
        var publicFile = this.options.content.folder.get('is_public') ? ' public-file' : '';

        return fileType + hasDescription + publicFile;
    },
    template: Handlebars.compile(fileListItemViewTemplate),
    ui: {
        name: '.name',
        fieldName: '.field-name',
        editName: '.edit-name',
        description: '.description',
        fieldDescription: '.field-description',
        editDescription: '.edit-description',
        downloadForm: '.download-form',
        menu: '.item-menu',
        tooltips: '[data-toggle=tooltip]',
        thumbFrame: '.thumb-frame',
        confirmDeleteName: '.confirm-delete-name'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .item-title': function() {
            this.ui.downloadForm.submit();
        },
        'click .thumb-link-icon': function() {
            this.ui.downloadForm.submit();
        },
        'click .info-icon': function() {
            this.ui.description.slideToggle(300);
            this.$el.toggleClass('info-expanded');
        },
        'click .item-menu-toggle': function(e) {
            var menu = this.ui.menu;

            // offsetParent work only on shown items
            menu.show();

            this.positionTarget = $(e.currentTarget);
            Utilities.positionMenu(menu, this.positionTarget);

            $(document).on('mousedown.close-item-menu', function(ev) {
                if(!$.contains( ev.target, menu ) && !$(ev.target).closest( menu ).length) {
                    menu.stop().hide();
                    $(this).off('mousedown.close-item-menu');
                }
            });
        },
        'click .download-item': function() {
            this.ui.downloadForm.submit();
        },
        'click .has-permission.show-permissions': function() {
            var ipv = new ItemPermissionsView({model: this.model});
            this.content.acl.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.content.acl.reset();
            });
        },
        'click .delete-item': function() {
            var pane = this.$el.find('.delete-confirm-pane');
            this.ui.menu.showPane(pane);
            Utilities.positionMenu(this.ui.menu, this.positionTarget);
        },
        'click .delete-confirm-pane .cancel': function(ev) {
            ev.preventDefault();
            this.ui.menu.resetPanes();
            Utilities.positionMenu(this.ui.menu, this.positionTarget);
        },
        'click .delete-confirm': function() {
            this.ui.menu.hide();
            $(document).off('mousedown.close-item-menu');
            this.model.destroy({
                wait: true
            });
        },
        'click .duplicate': function() {
            var view = this;

            this.ui.menu.hide();

            $.post(
                '/content_files/' + this.model.get('id') + '/duplicate',
                null,
                function() {
                    view.content.mainRegion.currentView.fetchData();
                }
            );
        },
        'click .open-in-editor': function() {
            var editor = new HTMLEditorView({
                content: this.model.get('content'),
                filename: this.model.get('name'),
                extension: this.model.get('ext'),
                description: this.model.get('description'),
                saveAsEnabled: true,
                formatChangeEnabled: false
            });

            var view = this;

            this.listenTo(editor, 'html-editor:save', function(content) {
                view.model.save({content: content}, {
                    patch: true
                });

                view.content.htmlEditorRegion.reset();
            });

            this.listenTo(editor, 'html-editor:save-as', function(filename, description, content) {
                var formData = new FormData();

                formData.append('filename', filename);
                formData.append('description', description);
                formData.append('content', content);
                formData.append('parent_id', this.model.get('parent_id'));

                $.ajax({
                    type: 'POST',
                    url: '/content_files',
                    contentType: false,
                    processData: false,
                    data: formData,
                    success: function() {
                        view.content.mainRegion.currentView.fetchData();
                    }
                });

                view.content.htmlEditorRegion.reset();
            });

            this.listenTo(editor, 'html-editor:close', function() {
                view.content.htmlEditorRegion.reset();
            });

            this.content.htmlEditorRegion.show(editor);
        }
    },
    remove: function(){
        var self = this;
        this.$el.children('td')
            .animate({
                paddingTop: 0,
                paddingBottom: 0,
                opacity: 0
            }, 200)
            .wrapInner('<div />')
            .children()
            .slideUp(200, function() {
                Marionette.Layout.prototype.remove.call(self);
            });
    },
    templateHelpers: function() {
        var photoUrl = false;
        var downloadAction;
        var downloadMethod = 'POST';

        if (this.model.get('has_thumb')) {
            photoUrl = app.options.apiUrl + '/content_files/' + this.model.get('id') + '?thumb&client=' +
            app.user.get('client')['short_id'];
        }

        if (this.options.content.folder.get('is_public')) {
            downloadAction = this.model.get('url');
            downloadMethod = 'GET';
        }
        else {
            downloadAction = app.options.apiUrl + '/content_files/' + this.model.get('id') + '?download';
        }

        var hasPermissions = security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba';

        return {
            is_public: this.model.get('is_public'),
            owner_name: this.model.get('owner') && this.model.get('owner')['name'],
            downloadMethod: downloadMethod,
            downloadAction: downloadAction,
            photo_url: photoUrl,
            icon: Utilities.getTypeIcon(this.model.get('ext')).icon,
            content_editable: false, // fileIsEditable(this.model),
            showPermission: hasPermissions
        };
    },
    initialize: function(options) {
        var self = this;

        this.content = options.content;

        this.model.on('change:description', function(model, val) {
            if (val.length) {
                self.$el.addClass('has-description');
            }
            else {
                self.ui.description.hide();
                self.$el.removeClass('info-expanded has-description');
            }
        });
    },
    onRender: function() {
        this.activateElement(this.ui.name, this.ui.fieldName, this.ui.editName, {field: 'name'}, function(val) {
            this.ui.confirmDeleteName.text(val);
        });
        this.activateElement(
                this.ui.description, this.ui.fieldDescription, this.ui.editDescription, {field: 'description'}
            );
        this.ui.tooltips.tooltip();
        initPane(this);
        this.showIFrameThumb();
    },
    showIFrameThumb: function() {
        var content = this.model.get('content');
        if (content) {
            this.ui.thumbFrame.load(function() {
                $(this).contents().find('body').empty().append($.parseHTML(content, null, false));
                $(this).removeClass('hide');
            });
        }
    },
    activateElement: function(text, edit, editButton, dbField, extraSuccess) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            cancelButton = container.find('.cancel'),
            prevValue;

        function hideEdit(restorePrevValue) {
            if (restorePrevValue) {
                self.model.set(dbField.field, prevValue);
                edit.val(prevValue);
            }

            container.removeClass('editing');
            self.ui.menu.resetPanes();
            $(document).off('mousedown.cancel-editing');
        }

        editButton.on('click', function() {
            var value = self.model.get(dbField.field);

            prevValue = value;
            container.addClass('editing');
            self.ui.menu.showPane(container);
            Utilities.positionMenu(self.ui.menu, self.positionTarget);
            edit.focus();

            if (edit === self.ui.fieldName) {
                // set the name without the extension
                var periodPosition = value.lastIndexOf('.');

                if (periodPosition !== -1) {
                    edit.val(value.substring(0, periodPosition));
                }

                edit.select();
            }

            $(document).on('mousedown.cancel-editing', function(ev) {
                if(!$.contains( ev.target, container ) && !$(ev.target).closest( container ).length ) {
                    hideEdit(true);
                }
            });
        });

        container.submit(function() {
            var val = edit.val(),
                attrs = {},
                model = self.model;

            if (edit === self.ui.fieldName) {
                var ext = model.get('ext');

                if (ext) {
                    val += ext;
                }
            }

            attrs[dbField.field] = val;
            model.save(attrs, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit(false);
                    if (extraSuccess) {
                        extraSuccess.call(self, val);
                    }
                },
                error: function(model, response) {
                    hideEdit(true);
                    if (response.responseText) {
                        var rdo = JSON.parse(response.responseText);
                        if (rdo.detail === 'name_already_exists') {
                            var mbContent = {
                                message: Handlebars.compile('The name "{{name}}" is already taken. Please choose a different name.')({name: val}),
                                icon: 'icon-blocked'
                            };

                            MessageBox.showOk(mbContent, self);
                        }
                    }
                }
            });
            return false;
        });

        cancelButton.on('click', function(ev) {
            ev.preventDefault(); // Prevent form submit
            hideEdit(true);
            Utilities.positionMenu(self.ui.menu, self.positionTarget);
        });
    }
});

// Use CompositeView for table headers in template
ListView = Marionette.CompositeView.extend({
    tagName: 'table',
    className: 'basic-table-list editable-table-list',
    template: Handlebars.compile(childListTemplate),
    //itemView: ListFolderItemView,
    getItemView: function(item) {
        if (item) {
            if (item.get('type') === 'folder') {
                return ListFolderItemView;
            }
            else {
                return ListFileItemView;
            }
        }
    },
    itemViewContainer: 'tbody',
    emptyView: NoCollectionView,
    itemViewOptions: function() {
        return {
            content: this.options.content
        };
    },
    onRender: function() {
        if (this.options.parent.model.get('is_public')) {
            this.$el.find('thead tr').addClass('public-file');
        }
    }
});

NewFolderView = Marionette.ItemView.extend({
    className: 'edit-form-modal',
    template: Handlebars.compile(addNewFolderTemplate),
    ui: {
        name: '#folder-name',
        description: '#folder-description',
        error_messages: '.error-message',
        save: '.save'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                this.ui.save.trigger('click');
                return false;
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    initialize: function(options) {
        this.folderId = options.folderId;
    },
    onShow: function() {
        this.$el.find('.form-control:first').focus().select();
    },
    save: function() {
        var attrs = {
                name: this.ui.name.val(),
                description: this.ui.description.val(),
                parent_id: this.folderId,
                type: 'folder' // used for ordering in comparison function
            },
            contentFolder = new ContentFolderModel(),
            view = this;

        this.error_messages.remove.call(this);

        this.listenTo(contentFolder, 'invalid', this.invalid);
        contentFolder.save(attrs, {
            validate: true,
            success: function() {
                contentFolder.set('type', 'folder');
                view.trigger('folder:new', contentFolder);
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);
        if (errors.name_undefined || errors.name_too_short) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined || errors.name_too_short)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

var BreadcrumbsView, BreadcrumbView;

BreadcrumbView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(['<i class="icon-caret-right"></i>',
            '<a href="#content/{{id}}" class="link">{{name}}</a>'
        ].join('')),
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            this.trigger('navigate', this.model.id);
        }
    },
    templateHelpers: function() {
        return {
            name: this.model.get('short_id') === 'public' ? 'File Hosting' : this.model.get('name'),
        };
    },
    onRender: function() {
        this.$el.find('a').tooltip({
            title: this.model.get('name')
        });
    },
    onClose: function() {
        this.$el.find('a').tooltip('destroy');
    }
});
BreadcrumbsView = Marionette.CompositeView.extend({
    className: 'breadcrumbs-container',
    template: Handlebars.compile('<ul class="breadcrumbs"></ul>'),
    itemView: BreadcrumbView,
    itemViewContainer: 'ul.breadcrumbs',
    onRender: function() {
        this.$el.find('ul.breadcrumbs')
            .append( Handlebars.compile('<li class="current"></li>') )
            .find('.current')
            .text( this.model.get('short_id') === 'public' ? 'File Hosting' : this.model.get('name') );
    },
    onShow: function() {
        this.truncateBreadcrumbs();
        this.onResize = function() {
            this.truncateBreadcrumbs();
        }.bind(this);
        $(window).on('resize', this.onResize);
    },
    onClose: function() {
        $(window).off('resize', this.onResize);
    },
    truncateBreadcrumbs: _.debounce(function() {
        var items = this.$el.find('li'),
            itemWidth;

        // Reset to calculate full width
        items.css('maxWidth', '');

        if ( this.$el.find('.breadcrumbs').width() > this.$el.width() ) {
            itemWidth = ( this.$el.width() - this.$el.find('.current').width() ) / this.collection.length;
            items.not('.current').css('maxWidth', Math.floor(itemWidth) );
        }
    }, 200)

});

UploadItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'uploading',
    template: Handlebars.compile(uploadItemTemplate),
    templateHelpers: function() {
        return {
            parentId: this.model.get('parent_id'),
            name: this.model.get('file').name
        };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            if (this.model.id) {
                this.trigger('click');
            }
        }
    },
    modelEvents: {
        'change:uploading': function() {
            this.$el.toggleClass('uploading', this.model.get('uploading'));
        },
        'change:id': function(model, id) {
            if (id && id.length) {
                this.$el.addClass('uploaded');
            }
        },
        'change:error': function(model, error) {
            this.$el.toggleClass('upload-error', error);
        }
    },
    upload: function() {
        var uploadFile = this.model;
        var formData = new FormData();
        var parentView = uploadFile.get('parent_view');

        formData.append('file', uploadFile.get('file'));
        formData.append('parent_id', uploadFile.get('parent_id'));

        $.ajax({
            type: 'POST',
            url: '/content_files',
            contentType: false,
            processData: false,
            data: formData,
            success: function(data) {
                var fileModel = new ContentFileModel(data);

                uploadFile.set('id', data.id);
                // used for ordering in comparison function
                fileModel.set('type', 'file');
                parentView.mixCollection.add(fileModel);
            },
            complete: function() {
                uploadFile.set('uploading', false);
            },
            error: function(resp) {
                let errMsg = 'Upload failed.';

                try {
                    let json = JSON.parse(resp.responseText);
                    let exception = json.detail.exception;

                    if (exception === 'UnsupportedExtError') {
                        errMsg = 'Upload failed. This folder only supports ' + SUPPORTEDEXTENSIONS.join(', ') + ' files.';
                    }
                }
                catch (e) {

                }
                parentView.onUploadError(errMsg);
                uploadFile.set('error', errMsg);
            }
        });
    }
});

UploadsPanelView = Marionette.CompositeView.extend({
    className: 'uploads-panel at-top has-sticky-nav',
    template: Handlebars.compile(uploadsPanelTemplate),
    itemView: UploadItemView,
    itemViewContainer: 'ul.uploads-list',
    ui: {
        header: '.header',
        status: '.status-bar .status'
    },
    events: {
        'click .close-panel': function(ev) {
            var self = this;
            ev.preventDefault();

            this.$el
                .trigger('uploadspanel:hide')
                .removeClass('minimised')
                .animate({
                    top: '100%',
                    marginTop: 0
                },{
                    duration: 600,
                    easing: 'easeOutQuint',
                    complete: function() {
                        self.collection.reset();
                        $(this).hide(0);
                    }
                });
        },
        'click .minimise-panel': function(ev) {
            var self = this;
            ev.preventDefault();

            if (this.$el.hasClass('minimised')) {
                this.showSelf();
                this.$el.removeClass('minimised');
                return;
            }

            this.$el
                .trigger('uploadspanel:minimise')
                .addClass('minimised')
                .animate({
                    top: '100%',
                    marginTop: -self.ui.header.height()
                }, 600, 'easeOutQuint');
        }
    },
    initialize: function(options) {
        this.content = options.content;
    },
    onRender: function() {
        this.listenTo(this, 'after:item:added', this.showSelf);
        this.listenTo(this, 'after:item:added item:removed', this.scrollbar);
        this.listenTo(this, 'itemview:click', function(view) {
            this.content.showLibraryView(view.model.get('parent_view').model);
        });

        this.$el.find('.content-container > .content').scroll(this.scrollEvents.bind(this));
    },
    showSelf: function() {
        var self = this;

        // Ensure uploads don't begin until panel animation is complete
        if ( this.$el.is(':hidden') || this.$el.hasClass('minimised') ) {
            this.$el
                .show(0)
                .animate({
                    top: '50%',
                    marginTop: 0
                }, {
                    queue: false,
                    duration: 600,
                    easing: 'easeOutQuint',
                    complete: function() {
                        self.$el.trigger('uploadspanel:show');
                        self.startUploading();
                    }
                });
        } else {
            this.startUploading();
        }
    },
    startUploading: function() {
        // Only upload files that need uploaded - i.e. not previously uploaded files
        this.children.each(function(view) {
            if (view.model.get('uploading') && view.model.get('toUpload')) {
                view.model.set('toUpload', false);
                view.upload();
            }
        });
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        var container = this.$el.find('.content-container');
        if (!this.$el.hasClass('hidden')) {
            container.nanoScroller();
            this.scrollEvents();
        }
    }, 100),
    scrollEvents: function() {
        var container = this.$el.find('.content-container > .content');

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

LibraryView = Marionette.Layout.extend({
    template: Handlebars.compile(libraryViewTemplate),
    ui: {
        files: '#select-file',
        title: '.title',
        breadcrumbs: '.breadcrumbs',
        uploadButton: '.upload',
        newFolderButton: '.new-folder',
        publicMessage: '.public-message',
        contentList: '#content-view-pane-lists'
    },
    regions: {
        folderListRegion: '.content-folder-list',
        fileListRegion: '.content-file-list',
        breadcrumbsRegion: '.breadcrumbs-region'
    },
    events: {
        'click .new-folder': function() {
            this.parent.showNewFolderView();
        },
        'change #select-file': function() {
            if (!this.ui.files[0].files.length) {
                return;
            }
            this.ui.uploadButton.attr('disabled', true);
            this.ui.files.attr('disabled', true);
            this.parent.uploadFile(this.ui.files[0].files, this);
            this.ui.files.val('');
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.ui.contentList.toggleClass('public', this.model.get('is_public'));
        this.ui.publicMessage.hide();
        if (this.model.get('latest')) {
            this.showContent();
        } else {
            this.fetchData();
        }
    },
    fetchData: function() {
        var self = this;

        app.shortTermCache.get(`${this.model.urlRoot}/${this.model.get('id')}`, {
            detail: 'extended'
        }, function(data) {
            self.model = new ContentFolderModel(data);
            self.showContent();
        });
    },
    showContent: function() {
        var model = this.model,
            files = model.get('child_files'),
            folders = model.get('child_folders');

        this.setBreadcrumb();

        if (model.get('protected') && !model.get('is_public')) {
            this.ui.newFolderButton.hide();
        }
        else {
            this.listenTo(this.parent, 'folder:new', function(contentFolder) {
                this.mixCollection.add(contentFolder);
            });
        }

        this.mixCollection = new (Backbone.Collection.extend({
            model: function(attrs) {
                if (attrs.type === 'folder') {
                    return new ContentFolderModel(attrs);
                }
                else {
                    return new ContentFileModel(attrs);
                }
            },
            comparator: function(a, b) {
                if (a.get('type') !== b.get('type')) {
                    return a.get('type') < b.get('type') ? 1 : -1;
                }
                return a.get('name').toLowerCase() > b.get('name').toLowerCase() ? 1 : -1;
            }
        }))();

        if (folders) {
            _.each(folders, function(el) { el.type = 'folder'; });
            this.mixCollection.add(folders);
        }
        if (files) {
            _.each(files, function(el) { el.type = 'file'; });
            this.mixCollection.add(files);
        }

        this.mixCollection.each(function(model) {
            model.parse(model.attributes);
        });

        this.mixListView = new ListView({
            collection: this.mixCollection,
            content: this.parent,
            parent: this
        });
        this.listenTo(this.mixListView, 'show', this.scrollbar());
        this.listenTo(this.mixCollection, 'add', function(model) {
            // re-render to reflect order
            this.mixListView.render();

            if (model.get('type') === 'file') {
                this.onUploadComplete();
            }
        });
        this.folderListRegion.show(this.mixListView);
    },
    setBreadcrumb: function() {
        var self = this,
            parents = new Backbone.Collection( this.model.get('parent_folders') );

        this.breadcrumbsView = new BreadcrumbsView({ model: self.model, collection: parents });
        this.breadcrumbsRegion.show(this.breadcrumbsView);

        this.listenTo(this.breadcrumbsView, 'itemview:navigate', function(childView) {
            var folderModel = new ContentFolderModel({ id: childView.model.id });
            folderModel.fetch({
                url: folderModel.url() + '?detail=extended',
                success: function() {
                    self.parent.showLibraryView(folderModel);
                }
            });
        });

        this.ui.title.text( this.model.get('short_id') === 'public' ? 'File Hosting' : this.model.get('name') );
        if (this.model.get('is_public')) {
            this.ui.publicMessage.show();
        } else {
            this.ui.publicMessage.hide();
        }
    },
    setBreadcrumbs: function() {
        var self = this;
        this.ui.breadcrumbs.html('');
        _.each(this.model.get('parent_folders'), function(parent) {
            self.ui.breadcrumbs.append(
                $('<li>').text(parent.name)
                    .attr('idd', parent.id)
                    .append( $('<i class="icon-caret-right">') )
            );
        });
        this.ui.breadcrumbs.find('a').on('click', function() {
            var folderModel = new ContentFolderModel({ id: $(this).attr('idd') });
            folderModel.fetch({
                url: folderModel.url() + '?detail=extended',
                success: function() {
                    self.parent.showLibraryView(folderModel);
                }
            });
        });
        this.ui.breadcrumbs.append( $('<li>').text(self.model.get('name')) );
    },
    onUploadComplete: function() {
        this.enableButtons();
    },
    onUploadError: function(errMsg) {
        if (this.uploadErrorDialogVisible) {
            return;
        }

        var mbContent = {
            message: errMsg,
            icon: 'icon-warning',
            accept_button_text: 'Close'
        };

        var self = this;
        this.uploadErrorDialogVisible = true;

        MessageBox.showOk(mbContent, this, function() {
            self.uploadErrorDialogVisible = false;
            self.enableButtons(); // to enabled the upload button again
        });
    },
    enableButtons: function() {
        this.ui.uploadButton.removeAttr('disabled');
        this.ui.files.removeAttr('disabled');
    },
    scrollbar: function() {
        this.$el.find('#content-view-pane-lists').nanoScroller();
    },
    getUrl: function() {
        return 'library/' + (this.model.get('short_id') || this.model.get('id'));
    }
});

ContentView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'content-section',
    template: Handlebars.compile(contentViewTemplate),
    regions: {
        sidebarRegion: '#content-sidebar',
        mainRegion: '#content-view-pane',
        htmlEditorRegion: {
            selector: '.html-editor', // selector it self not used in ModalRegion
            regionType: ModalRegion.extend({backdrop: 'static'})
        },
        addNewFolder: {
            selector: '.add-new-folder', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        acl: {
            selector: '.acl', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        uploadsRegion: '#uploads-region'
    },
    events: {
        'uploadspanel:show': function() {
            this.sidebarRegion.$el.find('.content-container:first').css('bottom', '50%');
            this.sidebarRegion.currentView.scrollbar();
        },
        'uploadspanel:hide': function() {
            this.sidebarRegion.$el.find('.content-container:first').css('bottom', 0);
            this.sidebarRegion.currentView.scrollbar();
        },
        'uploadspanel:minimise': function() {
            var headerHeight = this.uploadsRegion.currentView.ui.header.height();
            this.sidebarRegion.$el.find('.content-container:first').css('bottom', headerHeight);
            this.sidebarRegion.currentView.scrollbar();
        }
    },
    onRender: function() {
        var treeView = new TreeView({
            content: this,
            modelId: this.options.id,
        });
        this.sidebarRegion.show(treeView);

        this.uploadsCollection = new Backbone.Collection();
        var uploadsPanelView = new UploadsPanelView({
            content: this,
            collection: this.uploadsCollection
        });
        this.uploadsRegion.show(uploadsPanelView);
    },
    showLibraryView: function(folderModel) {
        this.folder = folderModel;

        this.mainRegion.show(new LibraryView({
            parent: this,
            model: folderModel
        }));

        this.trigger('folder:select', folderModel);

        vent.trigger('AppContent:contentChange');
    },
    showNewFolderView: function() {
        var newFolderView = new NewFolderView({
            folderId: this.folder.get('id')
        });
        this.addNewFolder.show(newFolderView);

        this.listenTo(newFolderView, 'folder:new', function(contentFolder) {
            this.trigger('folder:new', contentFolder);
            this.addNewFolder.reset();

            // Show library view if not already shown
            if (!(this.mainRegion && this.mainRegion.currentView instanceof LibraryView)) {
                this.showLibraryView(this.folder);
            }
        });

        this.listenTo(newFolderView, 'cancel', function() {
            this.addNewFolder.reset();
        });
    },
    uploadFile: function(files, folderView) {
        // folder supports this kind of files?
        var allowedExtensions = this.folder.get('restricted_to_extensions');

        if (allowedExtensions) {
            for (const file of files) {
                var ext = file.name.split('.').pop();
                var view = this;

                if (!ext || allowedExtensions.indexOf(ext.toLowerCase()) === -1) {
                    var validExts = '';

                    _.each(allowedExtensions, function(ext, i) {
                        if (i > 0) {
                            validExts += (i === allowedExtensions.length - 1) ? ' & ' : ', ';
                        }

                        validExts += ext.toUpperCase();
                    });

                    var mbContent = {
                        message: 'Upload failed. This folder only supports ' + validExts + ' files.',
                        icon: 'icon-warning',
                        accept_button_text: 'Close'
                    };

                    MessageBox.showOk(mbContent, this, function() {
                        folderView.enableButtons(); // to enabled the upload button again
                    });
                    return;
                }
            }
        }

        // ...
        for (const file of files) {
            var uploadFile = new Backbone.Model({
                file: file,
                parent_id: this.folder.id,
                parent_view: folderView,
                uploading: true,
                toUpload: true
            });

            this.uploadsCollection.add(uploadFile);
        }
    },
    getUrl: function() {
        var url = 'content';
        if (this.mainRegion && this.mainRegion.currentView) {
            url += '/' + this.mainRegion.currentView.getUrl();
        }
        return url;
    }
});

export default ContentView;

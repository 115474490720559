export const GRID_SIZE = 20;
export const EDITOR_WIDTH = 300;

export const ANCHOR_BITFIELD = {
    none: 0,
    top: 1,
    right: 2,
    bottom: 4,
    left: 8,
    all: 15
};

export const adjustToGrid = (value) => {
    if ((value % GRID_SIZE) === 0) {
        return value;
    }

    for (let i = 1; i < GRID_SIZE; ++i) {
        if (((value + i) % GRID_SIZE) === 0) {
            return value + i;
        }

        if (((value - i) % GRID_SIZE) === 0) {
            return value - i;
        }
    }

    return value;
}

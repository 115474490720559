import _ from 'underscore'

import BaseModel from 'js/models/base'


var GroupModel = BaseModel.extend({
    name: 'Group',
    urlRoot: '/groups',
    apiType: 'salesseek.contacts.models.group.Group',
    toString: function() {
        return this.get('name');
    },
    isGroup: function() {
        return this.attributes && this.get('group_type') !== 'smart';
    },
    isSmart: function() {
        return this.attributes && this.get('group_type') === 'smart';
    },
    isFavorites: function() {
        return this.get('special_type') === 'favorites';
    },
    isFolder: function() {
        return this.get('special_type') === 'folder';
    },
    validate: function(attrs) {
        var errors = {};

        // Group name is required
        if (_.isUndefined(attrs.name)) {
            if (this.isNew()) {
                errors.missing_name = 'Group name is required';
            }
        }
        else {
            if (_.isNull(attrs.name) || attrs.name.length === 0) {
                errors.missing_name = 'Group name is required';
            }
        }

        if (this.isNew() && (_.isUndefined(attrs.group_type) || _.isNull(attrs.group_type))) {
            errors.missing_group_type = 'Group type is required';
        }

        if (this.isNew() && (_.isUndefined(attrs.element_type) || _.isNull(attrs.element_type))) {
            errors.missing_element_type = 'Group element is required';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});
GroupModel.fromString = function (string) {
    return new GroupModel({name: string});
};
export default GroupModel;

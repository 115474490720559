import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import GroupsCollection from 'js/collections/groups.js'
import template from 'templates/groups/list-contains.handlebars'


export default Marionette.Layout.extend({
    template: Handlebars.compile(template),
    ui: {
        groups: 'input'
    },
    initialize: function(options) {
        _.extend(this, options);

        this.collection = new GroupsCollection();
    },
    onRender: function() {
        this.onGroup();

        this.collection.fetch({
            rows: -1,
            data: {
                by_static_contained_item: true,
                item_id: this.itemId,
                element_type: this.elementType
            },
            success: this.onGroup.bind(this)
        });
    },
    onGroup: function() {
        if (this.isClosed) {
            return;
        }

        if (this.groupSelect) {
            this.groupSelect.$el.off('change', this.onGroupChange);
            this.groupSelect.$el.off('choice-selected', this.onChoiceSelected);
            this.groupSelect.destroy();
        }

        this.groupSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.groups,
            value: this.collection.map(function(model) {
                return {
                    id: model.get('id'),
                    title: model.get('name')
                };
            }),
            id: 'id',
            text: 'title',
            url: '/groups',
            search: true,
            createSearchChoice: true,
            params: {
                element_type: this.elementType,
                group_type: 'static'
            },

            options: {
                placeholder: 'Add to group',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'role-select-popover popover select2-drop-wider',
                multiple: true,
                formatNoMatches: function() {
                    return 'Type to add to group';
                },
                tokenSeparators: [","],
                formatSelection: function(item) {
                    return "<div class='group-option' group-id='" + item.id + "'>" + item.title + "</div>";
                }
            }
        });

        this.groupSelect.$el.on('change', {self: this}, this.onGroupChange);
        this.groupSelect.$el.on('choice-selected', {self: this}, this.onChoiceSelected);
    },
    onChoiceSelected: function(ev, choiceEl) {
        var self = ev.data.self;
        var uuid = $(choiceEl).find('.group-option').attr('group-id');

        switch(self.elementType) {
            case 'opportunities':
                window.location.href = '/#deals/groups/' + uuid;
                break;
            case 'activities':
                window.location.href = '/#activities/groups/' + uuid;
                break;
            default:
                window.location.href = '/#contacts/group/' + self.elementType + '/' + uuid;
                break;
        }
    },
    onGroupChange: function(e) {
        var view = e.data.self || this;

        if (e.added) {
            $.ajax({
                url: view.collection.urlRoot() + '/' + e.added.id + '/items/' + view.itemId,
                method: 'PUT'
            });
        }
        if (e.removed) {
            $.ajax({
                url: view.collection.urlRoot() + '/' + e.removed.id + '/items/' + view.itemId,
                method: 'DELETE'
            });
        }
    }
});

import filterOperators from 'js/views/filters/operators'
import filterCommon from 'js/views/filters/common'
import TextManager from 'app/text-manager'

export default function() {
    var fields = [
        {
            'id': 'activity_type',
            'name': 'Type',
            'operators': filterOperators.custom.dropDown,
            'group': 'Activity',
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'activity_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': 'Activity'
        },
        {
            'id': 'activity_creator_id',
            'name': 'Creator',
            'operators': filterOperators.user,
            'group': 'Activity'
        },
        {
            'id': 'activity_text',
            'name': 'Text',
            'operators': filterOperators.string,
            'group': 'Activity'
        },
        {
            'id': 'activity_related_type',
            'name': 'Related Type',
            'operators': filterOperators.custom.dropDown,
            'group': 'Activity',
            'value_def': { options: [
                {
                    id: 'individuals',
                    value: TextManager.parseText('${ID_INDIVIDUAL, capitalize}')
                },
                {
                    id: 'organizations',
                    value: TextManager.parseText('${ID_ORGANIZATION, capitalize}')
                },
                {
                    id: 'opportunities',
                    value: TextManager.parseText('${ID_DEAL, capitalize}')
                }
            ]}
        },
        {
            'id': 'activity_related_id',
            'name': 'Related To',
            'operators': filterOperators.related,
            'group': 'Activity'
        },
        {
            'id': 'activity_tags',
            'name': 'Tag',
            'operators': filterOperators.tag,
            'group': 'Activity'
        },
        {
            'id': 'activity_funnels',
            'name': TextManager.parseText('${ID_FUNNEL, capitalize}'),
            'operators': filterOperators.funnel,
            'group': 'Activity'
        },
        {
            'id': 'activity_regions',
            'name': 'Region',
            'operators': filterOperators.region,
            'group': 'Activity'
        },
        {
            'id': 'activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': 'Activity'
        },
        {
            'id': 'group_id',
            'name': 'Group',
            'operators': filterOperators.group,
            'group': 'Group'
        },
        {
            'id': 'funnel_cluster_id',
            'name': TextManager.parseText('${ID_CLUSTER, capitalize}'),
            'operators': filterOperators.funnel_cluster,
            'group': TextManager.parseText('${ID_FUNNEL, capitalize}')
        },
    ];

    return fields;
};

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import Sifter from 'Sifter'

import TextManager from 'app/text-manager'
import FilterQuickItems from 'js/views/filters/quick-items.js'
import FunnelsCollection from 'js/collections/funnels.js'
import PhasesCollection from 'js/collections/phases.js'


var FilterModel = Backbone.Model.extend({
    defaults: {
        hidden: function() {
            var search = this.results('search');
            var name = this.results('name');
            return search && name && name.toUpperCase().indexOf(search.toUpperCase()) === -1;
        },
        click: function(ev, view) {
            var rules = this.results('rules');
            if (rules) {
                view.root.addRule(new Backbone.Collection(rules));
                view.root.close();
            }
        }
    },
    results: function(key) {
        var value = this.get(key);
        if (_.isFunction(value)) {
            value = value.call(this);
        }
        return value;
    }
});

var FilterCollection = Backbone.Collection.extend({
    model: FilterModel
});

function parseAsyncItems(attr, value, text, icon, field) {
    var sifter = new Sifter(attr);
    var results = sifter.search(value || '', {
        fields: [text],
        sort: value && [{field: text, direction: 'asc'}],
        limit: 10
    });

    return _.map(results.items, function(info) {
        var item = attr[info.id];
        return {
            id: item.id,
            view: FilterQuickItems.ButtonView,
            name: _.escape(item[text]),
            data: item,
            hidden: false,
            icon: icon,
            rules: [{
                field: field,
                operator: 'equal',
                values: {
                    id: item.id,
                    name: item[text]
                }
            }]
        }
    });
}

function createAsyncItems(url, text, icon, field) {
    var items = new FilterCollection();

    var xhr;
    var model = new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        items: function() {
            var search = this.get('search');
            if (!xhr) {
                items.reset([{
                    view: FilterQuickItems.LabelView,
                    name: 'Loading filters...',
                    hidden: false
                }]);

                items.parse = function(attr) {
                    return parseAsyncItems(
                        attr,
                        search,
                        text,
                        icon,
                        field
                    );
                };
                xhr = items.fetch({
                    url: url,
                    data: {
                        rows: -1
                    },
                    error: function() {
                        items.reset({
                            view: FilterQuickItems.LabelView,
                            name: 'Error',
                            hidden: false
                        });
                    },
                    success: function(collection, data) {
                        xhr.json = data;
                    }
                });
            } else if (xhr.json) {
                items.reset(parseAsyncItems(
                    xhr.json,
                    search,
                    text,
                    icon,
                    field
                ));
            }
            return items;
        }
    });

    model.listenTo(model, 'change:search', function() {
        this.results('items');
    });

    return model;
}

function parseSearchableItems(attr, value, text, expandedInfo, fieldId) {
    var sifter = new Sifter(attr);
    var results = sifter.search(value || '', {
        fields: [text],
        sort: value && [{field: text, direction: 'asc'}],
        limit: 10
    });

    return _.map(results.items, function(info) {
        var item = attr[info.id];
        var groupType = item.item ? item.item.group_type : item.group_type;
        var ruleValues = null;

        if (item.type === 'communication') {
            var entityType = item.item ? item.item.entity_type : item.entity_type;
            ruleValues = item[text];
            fieldId = entityType === 'organizations' ? 'organization_communication_value' : 'communication_value';
        } else {
            ruleValues = {
                id: item.id,
                name: item[text]
            };
        }

        return {
            id: item.id,
            view: {
                'smart': FilterQuickItems.LabelView
            }[item.group_type] || FilterQuickItems.ButtonView,
            name: _.escape(item[text]),
            description: {
                'smart': 'Filtering by Smart Group is not supported. To view this group, select it from the sidebar.'
            }[groupType],
            data: item,
            hidden: false,
            disabled: {
                'smart': true
            }[groupType] || false,
            icon: {
                'groups': 'icon-list',
                'individuals': 'icon-user',
                'organizations': 'icon-home',
                'opportunities': 'icon-filter',
                'communication': function() {
                    var medium = item.item ? item.item.medium : item.medium;
                    return medium === 'email' ? 'icon-email' : 'icon-phone';
                },
                'salesseek.core.models.group.Group': 'icon-list',
                'salesseek.contacts.models.individual.Individual': 'icon-user',
                'salesseek.contacts.models.organization.Organization': 'icon-home',
                'salesseek.opportunities.models.opportunity.Opportunity': 'icon-filter'
            }[item.type],
            rules: [{
                field: fieldId || {
                    'groups': 'group_id',
                    'individuals': 'individual_id',
                    'organizations': 'organization_id',
                    'opportunities': 'opportunity_id',

                    'salesseek.core.models.group.Group': 'group_id',
                    'salesseek.contacts.models.individual.Individual': 'individual_id',
                    'salesseek.contacts.models.organization.Organization': 'organization_id',
                    'salesseek.opportunities.models.opportunity.Opportunity': 'opportunity_id'
                }[item.type],
                operator: 'equal',
                values: ruleValues
            }],
            expandedInfo: function() {
                if (_.isFunction(expandedInfo)) {
                    return expandedInfo(item.item || item);
                }

                return expandedInfo;
            }
        }
    });
}

function createSearchableItems(url, text, expandedInfo) {
    var xhr;
    var items = new FilterCollection();
    var model = new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        hidden: function() {
            var search = this.get('search');
            if (!search || !search.length) {
                items.reset();
            }
            return false;
        },
        items: function() {
            var search = this.get('search');
            var hasData = items.find(function(item) {
                return item.has('data');
            });
            if (!hasData) {
                items.reset([{
                    view: FilterQuickItems.LabelView,
                    name: 'Searching filters...',
                    hidden: false
                }]);
            }
            items.parse = function(attr) {
                return parseSearchableItems(
                    attr,
                    search,
                    search && search.length ? 'title' : text,
                    expandedInfo
                );
            };

            xhr && xhr.abort();
            xhr = items.fetch({
                url: url,
                data: search && search.length ? {
                    search: search || ''
                } : null,
                error: function(model, xhr) {
                    if (xhr.status) {
                        items.reset({
                            view: FilterQuickItems.LabelView,
                            name: 'Error',
                            hidden: false
                        });
                    }
                }
            });
            return items;
        }
    });

    model.listenTo(model, 'change:search', function() {
        this.results('items');
    });

    return model;
}

function createGlobalSearchableItems(fieldId, emptySearch) {
    var xhr;
    var items = new FilterCollection();
    var model = new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        hidden: function() {
            var search = this.get('search');
            var hidden = !emptySearch && (!search || !search.length);
            if (hidden) {
                items.reset();
            }
            return hidden;
        },
        items: function() {
            var search = this.get('search');
            if (!search && !emptySearch) {
                xhr = xhr && xhr.abort();
                items.reset();
                return items;
            }

            var hasData = items.find(function(item) {
                return item.has('data');
            });
            if (!hasData) {
                items.reset([{
                    view: FilterQuickItems.LabelView,
                    name: 'Searching filters...',
                    hidden: false
                }]);
            }
            items.parse = function(attr) {
                return parseSearchableItems(attr.results, search, 'title', null, fieldId);
            };

            xhr && xhr.abort();
            xhr = items.fetch({
                url: '/search',
                data: {
                    terms: '"' + search + '"'
                },
                error: function(model, xhr) {
                    if (xhr.status) {
                        items.reset({
                            view: FilterQuickItems.LabelView,
                            name: 'Error',
                            hidden: false
                        });
                    }
                }
            });
            return items;
        }
    });

    model.listenTo(model, 'change:search', function() {
        this.results('items');
    });

    return model;
}

function createPhaseItems() {
    return new FilterModel({
        view: FilterQuickItems.GroupView,
        name: TextManager.parseText('Funnel & ${ID_PHASE, capitalize}'),
        icon: 'icon-funnel-sm',
        click: false,
        items: function() {
            var collection = new FilterCollection();
            var phases = new PhasesCollection();

            phases.fetch({
                success: function() {
                    var phasesByFunnel = phases.getAsHierarchy();
                    var items = [];

                    // global won&lost (only if the client has more than one funnel)
                    if (phasesByFunnel.length > 1) {
                        var wonLostPhases = phases.getWonLost();

                        for (var i = 0; i < 2; ++i) {
                            var phase = wonLostPhases[i];

                            items.push(new FilterModel({
                                view: FilterQuickItems.ButtonView,
                                id: phase.id,
                                name: phase.name,
                                icon: (i === 0) ? 'icon-thumbs-up' : 'icon-thumbs-down',
                                rules: [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    values: {
                                        id: phase.id,
                                        name: phase.name
                                    }
                                }]
                            }));
                        }
                    }

                    _.each(phasesByFunnel, function(funnel) {
                        if (phasesByFunnel.length > 1) {
                            items.push(new FilterModel({
                                view: FilterQuickItems.SeperatorView
                            }));
                        }

                        // funnel
                        items.push(new FilterModel({
                            view: FilterQuickItems.ButtonView,
                            id: funnel.fid,
                            name: funnel.name,
                            icon: 'icon-funnel-sm',
                            rules: [{
                                field: 'opportunity_funnel_id',
                                operator: 'equal',
                                values: {
                                    id: funnel.fid,
                                    name: funnel.name
                                }
                            }]
                        }));

                        // phases
                        _.each(funnel.children, function(phase) {
                            items.push(new FilterModel({
                                view: FilterQuickItems.ButtonView,
                                id: funnel.fid + ':' + phase.id,
                                name: phase.name,
                                extendedClass: 'tabbed-item',
                                rules: [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    values: {
                                        id: phase.id,
                                        name: phase.funnel.name + ': ' + phase.name,
                                        funnel_id: funnel.fid
                                    }
                                }]
                            }));
                        });
                    });

                    collection.reset(items);
                }
            });

            return collection;
        }
    });
}

export default {
    FilterModel: FilterModel,
    FilterCollection: FilterCollection,

    createAsyncItems: createAsyncItems,
    parseSearchableItems: parseSearchableItems,
    createSearchableItems: createSearchableItems,
    createGlobalSearchableItems: createGlobalSearchableItems,
    createPhaseItems: createPhaseItems
};

import React from 'react';
import classnames from 'classnames';

import TextManager from 'app/text-manager';

import {Button} from 'js/react_views/widgets/widgets';
import Checkbox from 'js/react_views/widgets/checkbox';
import Popover from 'js/react_views/widgets/popover';
import {
    NewSelect,
    IndividualSelectItem
} from 'js/react_views/widgets/select';
import AppConfig from 'app/app-config';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import IndividualList from 'js/react_views/detail_view_components/individual-list';
import ListPager from 'js/react_views/detail_view_components/list-pager';

import style from './Individuals.css';

class Individuals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {showAddItemPopover: false};

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleAddItemPopoverClose = this.handleAddItemPopoverClose.bind(this);
        this.handleIndividualsAdd = this.handleIndividualsAdd.bind(this);
        this.handleSelectedIndividualsAdd = this.handleSelectedIndividualsAdd.bind(this);
        this.handleIndividualsRemove = this.handleIndividualsRemove.bind(this);
    }

    handleAddButtonClick() {
        this.setState({showAddItemPopover: true});
    }

    handleAddItemPopoverClose() {
        this.setState({showAddItemPopover: false});
    }

    handleIndividualsAdd(items) {
        this.props.onAdd(items);
        this.setState({showAddItemPopover: false});
    }

    handleSelectedIndividualsAdd() {
        const items = _.map(
            this.props.organizationSelection,
            (val, key) => ({id: key})
        );
        this.props.onAdd(items).done(() => {
            this.props.onOrganizationSelectionClear();
        });
    }

    handleIndividualsRemove() {
        const items = _.map(this.props.dealSelection, (val, key) => ({ id: key }));
        this.props.onDelete(items).done(() => {
            this.props.onDealSelectionClear();
        });
    }

    render() {
        const {
            dealIndividuals,
            organizationIndividuals,
            onClickNext,
            onClickPrev,
            dealSelection,
            organizationSelection,
            onDealIndividualSelect,
            onOrganizationIndividualSelect,
            onAllDealToggle,
            onAllOrganizationToggle,
            tab,
            onShowDealIndividuals,
            onShowOrganizationIndividuals,
            onNavigate,
            onLinkClick,
            onNew
        } = this.props;

        const dealTabClasses = classnames({
            [style.dealTab]: true,
            [style.active]: tab === 'deal'
        });
        const orgTabClasses = classnames({
            [style.organizationTab]: true,
            [style.active]: tab === 'organization'
        });

        let individuals = dealIndividuals;
        let individualsSelection = dealSelection;
        let onIndividualSelect = onDealIndividualSelect;
        let onAllToggle = onAllDealToggle;
        if (tab === 'organization') {
            individuals = organizationIndividuals;
            individualsSelection = organizationSelection;
            onIndividualSelect = onOrganizationIndividualSelect;
            onAllToggle = onAllOrganizationToggle;
        }
        let allSelected = individuals &&
                          individuals.items.length &&
                          _.every(individuals.items, (individual) => (individualsSelection[individual.id]));

        const dealsIndividualsMessage = TextManager.getText('ID_DEAL_DEALS_INDIVIDUALS');
        const organizationIndividualsMessage = TextManager.getText('ID_DEAL_ORGANIZATION_INDIVIDUALS');
        const IndividualSelectItemComponent = AppConfig.getValue('individuals.new_select_widget.item_component', IndividualSelectItem);
        let individualSelectSearchParameters = null;

        if (AppConfig.getValue('is_pdcrm')) {
            individualSelectSearchParameters = {
                search_extra: true,
                search_only_on: 'full_name, communications',
                search_partial: true
            };
        }

        return (
            <div className={style.Individuals}>
                <nav className={style.nav}>
                    <ul className={style.navTabs}>
                        {!this.props.disableDealIndividualsTab && <li className={dealTabClasses}
                            onClick={onShowDealIndividuals}
                            title={dealsIndividualsMessage}>{dealsIndividualsMessage}</li>
                        }
                        {this.props.showOrganizationIndividualsTab && !!organizationIndividuals &&
                            <li className={orgTabClasses}
                                onClick={onShowOrganizationIndividuals}
                                title={organizationIndividualsMessage}>{organizationIndividualsMessage}</li>
                        }
                    </ul>
                </nav>
                <div className={style.toolbar}>
                    <ul className={style.toolbarTools}>
                        {individuals && individuals.items.length > 0 &&
                            <li className={style.selectAllTool}>
                                <Checkbox checked={allSelected} onClick={onAllToggle} />
                            </li>
                        }
                        {!_.isEmpty(dealSelection) && tab === 'deal' &&
                            <li className={`${style.removeFromDealTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.handleIndividualsRemove}>
                                    {TextManager.getText('ID_ENTITY_REMOVE_FROM', ['${ID_DEAL, capitalize}'])}
                                </Button>
                            </li>
                        }
                        {!_.isEmpty(organizationSelection) && tab === 'organization' &&
                            <li className={`${style.addToDealTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.handleSelectedIndividualsAdd}>
                                    Add to Deal
                                </Button>
                            </li>
                        }
                        {tab === 'deal' &&
                            <li className={`${style.addTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.handleAddButtonClick}>
                                    Add
                                </Button>
                            </li>
                        }
                        {tab === 'deal' &&
                            <li className={`${style.newTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={onNew}>
                                    New
                                </Button>
                            </li>
                        }
                    </ul>
                    {this.state.showAddItemPopover &&
                        <Popover
                            title="Add an item"
                            wider={true}
                            onClose={this.handleAddItemPopoverClose}>
                            <NewSelect
                                url="/individuals"
                                text="title"
                                itemView={IndividualSelectItemComponent}
                                onSelect={this.handleIndividualsAdd}
                                onCancel={this.handleAddItemPopoverClose}
                                placeholder="Search for someone"
                                width={325}
                                options={{
                                    minimumInputLength: 1,
                                    initiallyExpanded:true,
                                    search_parameters: individualSelectSearchParameters
                                }}
                            />
                        </Popover>
                    }
                </div>
                {!individuals &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {individuals && individuals.items.length > 0 &&
                    <div>
                        <IndividualList
                            individuals={individuals.items}
                            onNavigate={onNavigate}
                            onLinkClick={onLinkClick}
                            onIndividualSelect={onIndividualSelect}
                            individualsSelection={individualsSelection} />
                        <ListPager
                            start={individuals.start}
                            currentPageLength={individuals.items.length}
                            total={individuals.total}
                            onClickNext={onClickNext}
                            onClickPrev={onClickPrev} />
                    </div>
                }
                {individuals && individuals.items.length === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default Individuals;

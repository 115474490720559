import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import vent from 'js/vent'

import app from 'js/app'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import EditLogoSettings from 'js/views/settings/edit_logo_settings'
import EditCsvTotalSettings from 'js/views/settings/data_export_setings'
import EditCustomMapPins from 'js/views/settings/edit_custom_map_pins'
import EditDealsCalenderSettings from 'js/views/settings/edit_deals_calendar'

import uiTemplate from 'templates/settings/ui.handlebars'
import viewContainerTemplate from 'templates/settings/ui_view_container.handlebars'
import boolTemplate from 'templates/settings/ui_bool.handlebars'
import viewTemplate from 'templates/settings/ui_view.handlebars'


var EmptyItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile('<div></div>')
});

var BoolItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(boolTemplate),
    events: {
        'click .checkbox-switch-control': function(ev) {
            var newValue = ev.target.checked;
            var client = app.user.get('client');
            var preferences = client.preferences || {};
            var itemId = this.model.get('id');
            var self = this;

            preferences[itemId] = newValue ? 'true' : 'false';

            $.ajax({
                type: 'PATCH',
                url: '/clients/' + client.id,
                data: JSON.stringify({
                    preferences: preferences
                }),
                contentType: 'application/json',
                dataType: 'json',
                success: function() {
                    self.model.set('value', newValue);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                },
                error: function() {
                    client.preferences[itemId] = newValue ? 'false' : 'true';
                    $(ev.target).prop('checked', !newValue);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error saving preferences',
                                classes: 'saved error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
    }
});

var ViewItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(viewTemplate),
    events: {
        'click .icon-cog': function(ev) {
            this.trigger('show-view');
        }
    }
});

var ViewContainerView = Marionette.Layout.extend({
    template: Handlebars.compile(viewContainerTemplate),
    templateHelpers: function() {
        return {
            title: this.options.title
        };
    },
    ui: {
        title: '.title-text'
    },
    regions: {
        viewContainer: '.inner-view-container'
    },
    events: {
        'click .header-back-button': function() {
            // only goes back if the view doesn't have the onHeaderBack method or if it returns false (that means the view doesn't process the event)
            if (!this.view.onHeaderBack || !this.view.onHeaderBack()) {
                this.trigger('go-back');
            }
        }
    },
    setTitle: function(title) {
        this.ui.title.text(title);
    },
    onRender: function() {
        this.view = new this.options.viewType({
            header: this
        });
        this.viewContainer.show(this.view);
    }
});

var SettingsList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'settings-list',
    getItemView: function(model) {
        switch(model.get('type')) {
            case 'bool':
                return BoolItemView;

            case 'view':
                return ViewItemView;
        };

        return EmptyItemView;
    }
});

export default Marionette.Layout.extend({
    className: 'advanced-settings',
    template: Handlebars.compile(uiTemplate),
    ui: {
        contentContainer: '.content-container',
        listContainer: '.ui-list-container',
        viewContainer: '.view-container'
    },
    regions: {
        settingsList: '.settings-list-container',
        viewContainer: '.view-container'
    },
    onRender: function() {
        var items = [
            {
                type: 'bool',
                id: 'use24hClock',
                name: 'Use 24 hour clock',
                value: AppConfig.getClientPreferenceValue('use24hClock')
            },
            {
                type: 'bool',
                id: 'startWeekOnSunday',
                name: 'Start week on Sunday',
                value: AppConfig.getClientPreferenceValue('startWeekOnSunday')
            },
            {
                type: 'bool',
                id: 'showDealIdOnBubble',
                name: TextManager.parseText("Show ${ID_DEAL, capitalize}'s ID on bubble"),
                value: AppConfig.getClientPreferenceValue('showDealIdOnBubble')
            },
            {
                type: 'bool',
                id: 'useDDMMYYYYDateFormat',
                name: 'Enable date format DD/MM/YYYY',
                value: AppConfig.getClientPreferenceValue('useDDMMYYYYDateFormat')
            },
            {
                type: 'bool',
                id: 'hideDownloadAsCsvOption',
                name: 'Hide Download as CSV option',
                value: AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption')
            },
            {
                type: 'view',
                id: 'logoSettings',
                name: 'Logo',
                title: 'Logo Settings',
                view: EditLogoSettings
            },
            {
                type: 'view',
                id: 'customMapPins',
                name: 'Demographics View Settings',
                view: EditCustomMapPins
            },
            {
                type: 'view',
                id: 'showDealsCalendar',
                name: 'Deals Calendar Settings',
                view: EditDealsCalenderSettings
            },
            {
                type: 'view',
                id: 'csvTotalSettings',
                name: 'Data Export Settings',
                title: 'Data Export Settings',
                view: EditCsvTotalSettings
            },
        ];

        var settingsView = new SettingsList({
            collection: new Backbone.Collection(items)
        });

        this.listenTo(settingsView, 'itemview:show-view', function(itemView) {
            var model = itemView.model;
            this.showView(model.get('view'), model.get('title') || model.get('name'));
        });

        this.settingsList.show(settingsView);
        this.$el.find('.content-container').nanoScroller();
    },
    showView: function(viewType, title) {
        var view = new ViewContainerView({
            viewType: viewType,
            title: title
        });

        this.listenTo(view, 'go-back', function() {
            this.viewContainer.reset();
            this.ui.viewContainer.addClass('hidden');
            this.ui.listContainer.removeClass('hidden');
        });

        this.viewContainer.show(view);
        this.ui.viewContainer.removeClass('hidden');
        this.ui.listContainer.addClass('hidden');
    }
});

import _ from 'underscore';
import React from 'react';
import classnames from 'classnames';
import app from 'js/app'

import {Button} from 'js/react_views/widgets/widgets';
import Checkbox from 'js/react_views/widgets/checkbox';
import {NewSelect} from 'js/react_views/widgets/select';
import Popover from 'js/react_views/widgets/popover';
import DealList from 'js/react_views/detail_view_components/deal-list';
import ListPager from 'js/react_views/detail_view_components/list-pager';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config'
import PhasesCollection from 'js/collections/phases'
import OpportunityFilterModel from 'js/models/opportunity_filter'

import style from './deals.css';

class IndividualDeals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddDealPopover: false,
            filterId: null
        };

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleAddDealPopoverClose = this.handleAddDealPopoverClose.bind(this);
        this.handleDealsAdd = this.handleDealsAdd.bind(this);
        this.handleIndividualDealsRemove = this.handleIndividualDealsRemove.bind(this);

        if (AppConfig.getValue('individualsViewAddDealFilterByPhaseName')) {
            if (!_.isUndefined(app.globalData.individualsViewAddDealFilterId)) {
                this.state.filterId = app.globalData.individualsViewAddDealFilterId;
            } else {
                let phases = new PhasesCollection(app.globalData.phasesInfo.phases);
                const phaseName = AppConfig.getValue('individualsViewAddDealFilterByPhaseName');
                let rules = [];

                for (const m of phases.models) {
                    if (m.get('name') === phaseName) {
                        rules.push({
                            field: 'opportunity_phase_id',
                            operator: 'equal',
                            values: {id: m.get('id')}
                        });
                    }
                }

                if (rules.length > 0) {
                    const self = this;
                    let filter = new OpportunityFilterModel();

                    filter.save({
                        rules: [rules],
                    }, {
                        alert: false,
                        success: function(fdata) {
                            self.setState({filterId: fdata.get('id')});
                            app.globalData.individualsViewAddDealFilterId = fdata.get('id');
                        }
                    });
                } else {
                    app.globalData.individualsViewAddDealFilterId = null;
                }
            }
        }
    }

    handleAddButtonClick() {
        this.setState({showAddDealPopover: true});
    }

    handleAddDealPopoverClose() {
        this.setState({showAddDealPopover: false});
    }

    handleDealsAdd(items) {
        this.props.onAdd(items).done(() => {
            // this is not ideal as it will clear the org deal selection
            // even when we're adding a deal through the select widget
            // and not from the org's deal list
            this.props.onOrganizationSelectionClear();
        });
        this.setState({showAddDealPopover: false});
    }

    handleIndividualDealsRemove() {
        this.props.onDelete(this.props.individualSelection).done(() => {
            this.props.onIndividualSelectionClear();
        });
    }

    render() {
        const indTabClasses = classnames({
            [style.individualTab]: true,
            [style.active]: this.props.tab === 'individual'
        });
        const orgTabClasses = classnames({
            [style.organizationTab]: true,
            [style.active]: this.props.tab === 'organization'
        });

        let deals = this.props.individualDeals;
        let dealsSelection = this.props.individualSelection;
        let onDealSelect = this.props.onIndividualDealSelect;
        let onAllToggle = this.props.onAllIndividualToggle;
        if (this.props.tab === 'organization') {
            deals = this.props.organizationDeals;
            dealsSelection = this.props.organizationSelection;
            onDealSelect = this.props.onOrganizationDealSelect;
            onAllToggle = this.props.onAllOrganizationToggle;
        }
        let allSelected = deals && deals.items.length &&
            _.every(deals.items, (deal) => (dealsSelection[deal.id]));

        const individualDealsMessage = TextManager.getText('ID_ENTITY_OWNS_ENTITY', ['${ID_INDIVIDUAL, capitalize}', '${ID_DEAL, capitalize, plural}']);
        const organizationDealsMessage = TextManager.getText('ID_ENTITY_OWNS_ENTITY', ['${ID_ORGANIZATION, capitalize}', '${ID_DEAL, capitalize, plural}']);
        let searchParameters = {};

        if (this.state.filterId) {
            searchParameters.by_filter_id = this.state.filterId;
        }

        return (
            <div className={style.IndividualDeals}>
                <nav className={style.nav}>
                    <ul className={style.navTabs}>
                        <li className={indTabClasses}
                            onClick={this.props.onShowIndividualDeals}
                            title={individualDealsMessage}>{individualDealsMessage}</li>
                        {!!this.props.organizationDeals && AppConfig.getValue('individuals.overview.organization_opportunities_tab.visible', true) &&
                            <li className={orgTabClasses}
                                onClick={this.props.onShowOrganizationDeals}
                                title={organizationDealsMessage}>{organizationDealsMessage}</li>
                        }
                    </ul>
                </nav>
                <div className={style.toolbar}>
                    <ul className={style.toolbarTools}>
                        {deals && deals.items.length > 0 &&
                            <li className={style.selectAllTool}>
                                <Checkbox checked={allSelected} onClick={onAllToggle} />
                            </li>
                        }
                        {!_.isEmpty(this.props.individualSelection) && this.props.tab === 'individual' &&
                            <li className={`${style.removeFromIndTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.handleIndividualDealsRemove}>
                                    {TextManager.getText('ID_ENTITY_REMOVE_FROM', ['${ID_INDIVIDUAL, capitalize}'])}
                                </Button>
                            </li>
                        }
                        {!_.isEmpty(this.props.organizationSelection) && this.props.tab === 'organization' &&
                            <li className={`${style.addToIndTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.handleDealsAdd(_.map(
                                            dealsSelection,
                                            (val, key) => ({id: key})
                                        ));
                                    }}>
                                    {TextManager.getText('ID_ENTITY_ADD_TO', ['${ID_INDIVIDUAL, capitalize}'])}
                                </Button>
                            </li>
                        }
                        {this.props.tab === 'individual' && AppConfig.getValue('individuals.overview.related_deals.add_button.visible', true) &&
                            <li className={`${style.addTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.handleAddButtonClick}>
                                    Add
                                </Button>
                            </li>
                        }
                        {this.props.tab === 'individual' && AppConfig.getValue('individuals.overview.individual_opportunities_tab.visible', true) &&
                            <li className={`${style.newTool} tool-button`}>
                                <Button
                                    color="blue"
                                    onClick={this.props.onNew}>
                                    New
                                </Button>
                            </li>
                        }
                    </ul>
                    {this.state.showAddDealPopover &&
                        <Popover
                            title="Add an item"
                            wider={true}
                            onClose={this.handleAddDealPopoverClose}>
                            <NewSelect
                                url="/opportunities"
                                onSelect={this.handleDealsAdd}
                                onCancel={this.handleAddDealPopoverClose}
                                width={325}
                                text="title_highlight"
                                placeholder={TextManager.getText('ID_SEARCH_FOR_A_DEAL')}
                                options={{
                                    minimumInputLength: 1,
                                    initiallyExpanded: true,
                                    search_parameters: searchParameters
                                }}
                            />
                        </Popover>
                    }
                </div>
                {!deals &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {deals && deals.items.length > 0 &&
                    <div>
                        <DealList
                            deals={deals.items}
                            onDealNavigate={this.props.onDealNavigate}
                            onDealSelect={onDealSelect}
                            dealsSelection={dealsSelection} />
                        <ListPager
                            start={deals.start}
                            currentPageLength={deals.items.length}
                            total={deals.total}
                            onClickNext={this.props.onClickNext}
                            onClickPrev={this.props.onClickPrev} />
                    </div>
                }
                {deals && deals.items.length === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default IndividualDeals;

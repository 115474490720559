import _ from 'underscore'

import BaseModel from 'js/models/base'


export default BaseModel.extend({
    name: 'Content folder',
    urlRoot: '/content_folders',
    apiType: 'salesseek.opportunities.models.content_folder.ContentFolder',
    validate: function(attrs) {
        var errors = {};

        if (this.isNew()) {
            if (_.isUndefined(attrs.name)) {
                errors.name_undefined = 'Folder name required';
            }
        }

        if (!_.isUndefined(attrs.name)) {
            if (attrs.name.length === 0) {
                errors.name_too_short = 'One character minimum required';
            }
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

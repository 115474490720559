import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import AppConfig from 'app/app-config'
import integrationsTemplate from 'templates/settings/integrations.handlebars';


var getJsonFromUrl = function(loc) {
    var query = loc.search.substr(1);
    var result = {};
    query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
};

var config = {
    'twitter': {
        name: 'Twitter',
        icon: 'icon-twitter',
        connectionEnabledInAllTiers: true
    },
    'facebook': {
        name: 'Facebook',
        icon: 'icon-facebook',
        connectedMessage: 'Connected Page',
        connectionEnabledInAllTiers: true
    },
    'xero': {
        name: 'Xero',
        icon: 'icon-xero-logo'
    },
    'proposify': {
        name: 'Proposify',
        icon: 'icon-proposify'
    },
    'trust_pilot': {
        name: 'Trust Pilot',
        icon: 'icon-trust-pilot'
    },
    'sage': {
        name: 'Sage One',
        icon: 'icon-sage-logo',
        directLink: 'http://support.salesseek.net/integrations/sage/sageone-integration',
        available: function() {
            return !AppConfig.getValue('disableSageOneIntegration');
        }
    },
    'slack': {
        name: 'Slack',
        icon: 'icon-slack-logo'
    },
    'zendesk': {
        name: 'Zendesk',
        icon: 'icon-zendesk-logo'
    },
    'intercom': {
        name: 'Intercom',
        icon: 'icon-intercom-logo'
    },
    'mailchimp': {
        name: 'Mailchimp',
        icon: 'icon-mailchimp-logo',
        connectionEnabledInAllTiers: true
    },
    'sagelive': {
        name: 'Sage Live',
        icon: 'icon-sage-logo',
        available: function() {
            var labFlags = app.user.get('preferences').lab_flags;
            return _.contains(labFlags, 'INT-52');
        }
    },
    'docusign': {
        name: 'DocuSign',
        icon: 'icon-docusign-logo'
    },
    'eightbyeight': {
        name: '8x8 Phone Systems',
        icon: 'icon-eightbyeight-logo',
        available: function() {
            var labFlags = app.user.get('preferences').lab_flags;
            return _.contains(labFlags, 'INT-53');
        }
    },
    'quickbooks': {
        name: 'QuickBooks Accounting',
        icon: 'icon-quickbooks-logo'
    },
    'freshdesk': {
        name: 'FreshDesk',
        icon: 'icon-freshdesk-logo',
        available: function() {
            var labFlags = app.user.get('preferences').lab_flags;
            return _.contains(labFlags, 'INT-72');
        },
        ifNotAvailableDirectLink: 'http://support.salesseek.net/integrations/freshdesk-integration'
    },
    'zapier': {
        name: 'Zapier',
        icon: 'icon-zapier-logo',
        directLink: 'https://zapier.com/developer/invite/37454/f7d11f47989019552cc137d96a7028d1/'
    },
    'chromeextension': {
        name: 'Chrome Extension',
        icon: 'icon-chrome-plugin-logo',
        directLink: AppConfig.getValue('chromeExtensionUrl')
    },
    'calendly': {
        name: 'Calendly',
        icon: 'icon-calendly-logo',
        directLink: 'http://support.salesseek.net/integrations/calendly/calendly-integration'
    },
    'piesync': {
        name: 'PieSync',
        icon: 'icon-piesync-logo',
        directLink: 'https://www.piesync.com/salesseek/',
        available: function() {
            return !AppConfig.getValue('disablePieSyncIntegration');
        }
    }
};

var winOptions = 'width=680,height=570,menubar=0,toolbar=0,location=0,personalbar=0,dependent=1';

export default Marionette.ItemView.extend({
    template: Handlebars.compile(integrationsTemplate),
    templateHelpers: function() {
        var labFlags = app.user.get('preferences').lab_flags;
        var self = this;
        var integrations = [];

        _.each(config, function(val, type) {
            var enabled = !('available' in val) || val.available();
            var hasDirectLink = val.directLink;

            if (!enabled && val.ifNotAvailableDirectLink) {
                enabled = true;
                hasDirectLink = true;
            }

            integrations.push({
                type: type,
                name: val.name,
                icon: val.icon,
                enabled: enabled,
                hasDirectLink: hasDirectLink
            });
        });

        integrations = _.sortBy(integrations, 'name');

        return {
            integrations: integrations
        };
    },
    
    events: {
        'click .button.connect': function(ev) {
            var target = $(ev.target);

            if (!target.hasClass('disabled-colorized')) {
                this.authorize(target.attr('id'));
            }
        },
        'click .button.disconnect': function(ev) {
            this.deAuthorize($(ev.target).attr('id'));
        }
    },
    initialize: function() {
        this.model = new Backbone.Model();
    },
    onRender: function () {
        var self = this;

        _.each(config, function(val, type) {
            self.fetchStatus(type);
        });
    },
    fetchStatus: function(type) {
        if (config[type].directLink) {
            return;
        }

        var view = this;

        $.ajax({
            url: view.getBaseUrl() + '/integrations/' + type,
            dataType: 'json',
            success: function (data) {
                if (view.isClosed) {
                    return;
                }

                view.model.set(type, data);
                view.update();
            }
        });

        view.model.set(type, null);
        view.update();
    },
    update: function() {
        var view = this;
        var numConnections = 0;
        var featureTier = app.user.get('client').feature_tier || 'enterprise';

        // Do this for each integration type
        _.each(config, function(val, type) {
            var integrationData = view.model.get(type);
            var element = view.$el.find('.' + type + '-account');

            if (_.isNull(integrationData) || _.isUndefined(integrationData)) {
                if (!config[type].directLink) {
                    // It is loading yet
                    element.addClass('processing').find('.status').text('...');
                }
            } else {
                var status = integrationData.status;

                if (status === 'authorized') {
                    element.removeClass('processing').addClass('connected');
                    element.find('.name').text(integrationData.extra_data.account_name);
                    element.find('.screen-name').text(val.connectedMessage || 'Connected Account');
                    ++numConnections;
                } else if (status === 'deauthorized') {
                    var connectEnabled = (featureTier === 'starting') ? val.connectionEnabledInAllTiers : true;
                    var connectButton = element.find('.button.connect');

                    if (connectEnabled && (featureTier !== 'enterprise')) {
                        connectEnabled = numConnections < 3; // 3 = max num connections enable for starting and growing
                    }

                    connectButton.toggleClass('disabled-colorized', !connectEnabled);

                    if (connectEnabled) {
                        connectButton.tooltip('disable');
                    }
                    else {
                        connectButton.tooltip({
                            title: 'Upgrade account to connect more Integrations',
                            container: 'body',
                            placement: 'right'
                        });
                    }

                    element.removeClass('connected processing');
                    element.find('.name, .screen-name').text('');
                    element.find('.account-thumb').removeClass('has-photo');
                    element.find('.profile-image').css('background-image', 'none');
                } else {
                    // Shouldn't happen, but if it happens, it is best to do nothing and leave the view 'loading'
                }
            }
        });
    },
    authorize: function(type) {
        var view = this,
            interval;

        if (this.authWin && !this.authWin.closed) {
            return;
        }

        var integration = config[type];

        if (integration.directLink) {
            window.open(integration.directLink, '_blank');
            return;
        }

        if (integration.labFlag && !_.contains(app.user.get('preferences').lab_flags, integration.labFlag) && integration.ifNotLabFlagDirectLink) {
            window.open(integration.ifNotLabFlagDirectLink, '_blank');
            return;
        }

        var element = this.$el.find('.' + type + '-account');
        element.addClass('processing').find('.status').text('Connecting...');

        this.authWin = window.open(
            this.getBaseUrl() + '/integrations/' + type + '/authorize',
            "",
            winOptions
        );

        // ping window until it is redirected from target site or closed
        interval = setInterval(function() {
            // window has been closed
            if (view.authWin.closed) {
                clearInterval(interval);
                return;
            }
            try {
                var closeWindow = function() {
                    clearInterval(interval);
                    view.authWin.close();
                    view.fetchStatus(type);
                };

                // Sage is a special case, where the return page does not shares the host
                if (type === 'sage') {
                    if (view.authWin.location.host.indexOf('salesseek') !== -1 ) {
                        closeWindow();
                    }
                    return;
                }

                // do nothing while we are in initial page
                if (view.authWin.location.host !== window.location.host) {
                    return;
                }

                if (type === 'facebook') {
                    if (view.authWin.location.hash === '#finished') {
                        closeWindow();
                    }
                } else {
                    // zendesk has a pre_configure step which should not be closed until authorized
                    if (type === 'zendesk' && view.authWin.location.pathname.indexOf('pre_configure') !== -1) {
                        return;
                    } else if (type === 'docusign' && view.authWin.location.pathname.indexOf('configure') !== -1 &&
                    view.authWin.location.hash !== '#finished') {
                        return;
                    } else if (type === 'freshdesk' && view.authWin.location.pathname.indexOf('configure') !== -1 &&
                    view.authWin.location.hash !== '#finished') {
                        return;
                    } else if (view.authWin.document.readyState === 'complete') {
                        closeWindow();
                    }
                }
            }
            catch(e) {
                // do nothing while we are in different URL
                return;
            }
        }, 100);
    },
    deAuthorize: function (type) {
        var view = this;

        $.ajax({
            method: 'PATCH',
            url: this.getBaseUrl() + '/integrations/' + type,
            processData: false,
            contentType: 'application/json',
            data: JSON.stringify({
                status: 'deauthorized',
                credentials: {},
                extra_data: {}
            }),
            dataType: 'json',
            success: function(data) {
                view.model.set(type, data);
                view.update();
            }
        });

        view.model.set('type', null);
        view.update();
    },
    onClose: function () {
        if (this.authWin) {
            this.authWin.close();
        }
    },
    getBaseUrl: function() {
        return app.options.apiUrl;
    }
});

import _ from 'underscore'
import Handlebars from 'handlebars'
import twitter from 'twitter-text'

import BaseSocialItemView from 'js/views/base/social_item.js'
import twitterItemTemplate from 'templates/social/twitter_item.handlebars';


var TwitterItemView = BaseSocialItemView.extend({
    template: Handlebars.compile(twitterItemTemplate),
    className: 'tweet-item',
    events: _.extend({}, BaseSocialItemView.prototype.events)
});

Handlebars.registerHelper('tweet', function (text) {
    return new Handlebars.SafeString(twitter.autoLink(text));
});
export default TwitterItemView;

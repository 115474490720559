import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import stacksTemplate from 'templates/stacks.handlebars'
import ModalRegion from 'js/views/base/modal-region'


var StacksView = Marionette.Layout.extend({
    template: Handlebars.compile(stacksTemplate),
    regions: {
        appMenu: '#new-app-menu',
        appSearch: '#app-search',
        appContentContainer: '#app-content-container',
        appToolbar: '#app-toolbar',
        bulkEditRegion: {
            selector: '.bulk-edit-region',
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        },
        mergeRegion: {
            selector: '.merge-region', // unused
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        }
    },
    events: {
        'search:show': 'onSearchShow',
        'search:hide': 'onSearchHide',
        'search:browse': 'onSearchBrowse',
        'search:browse:back': 'browseMode',
        'mousedown .sidebar-draggable-border': 'startSidebarDragging',
        'mouseup': 'onMouseUp',
        'mousemove': 'onMouseMove'
    },
    onShow: function() {
        vent.trigger('chrome:show');
    },
    onSearchShow: function() {
        this.$el.parent()
            .addClass('app-search-visible')
            .removeClass('app-search-browse');
    },
    onSearchHide: function() {
        this.$el.parent()
            .removeClass('app-search-visible app-search-browse');
    },
    browseMode: function() {
        this.$el.parent().addClass('app-search-browse');
    },
    onSearchBrowse: function(ev, listView, type, model) {
        var args = {
                end: true,
                searchResults: true
            };
        this.browseMode();
        if (model) {
            vent.trigger(type + ':detail', args, model.id);
        }
        setTimeout(function() {
            listView.scrollBar();
        }, 400); // Time for animation to complete ...
    },
    startSidebarDragging: function(ev) {
        const sidebarEl = $(ev.target).closest('.sidebar');
        const contentEl = sidebarEl.next();

        this.draggingSidebarInfo = {
            sidebar: sidebarEl,
            content: contentEl,
            newGroup: contentEl.next(),
            mouseX: ev.screenX
        };

        this.draggingSidebarInfo.content.addClass('dragging-sidebar')
    },
    onMouseUp() {
        if (this.draggingSidebarInfo) {
            const id = this.draggingSidebarInfo.sidebar.attr('id');
            let sidebarPrefs = (app.user.get('preferences') || {}).groups_sidebar_width || {};

            sidebarPrefs[id] = this.draggingSidebarInfo.sidebar.width();
            app.user.updatePreference('groups_sidebar_width', sidebarPrefs);

            // ...
            this.draggingSidebarInfo.content.removeClass('dragging-sidebar')
            this.draggingSidebarInfo = null;
        }
    },
    onMouseMove(ev) {
        if (this.draggingSidebarInfo) {
            ev.stopPropagation();
            ev.preventDefault();

            let deltaX = ev.screenX - this.draggingSidebarInfo.mouseX;
            this.draggingSidebarInfo.mouseX = ev.screenX;

            const sidebarWidth = this.draggingSidebarInfo.sidebar.width();
            const sidebarMinWidth = 220;
            const sidebarMaxWidth = 500;

            if ((sidebarWidth + deltaX) < sidebarMinWidth) {
                deltaX = sidebarWidth - sidebarMinWidth;
            } else if ((sidebarWidth + deltaX) > sidebarMaxWidth) {
                deltaX = sidebarMaxWidth - sidebarWidth;
            }

            if (deltaX === 0) {
                return;
            }

            const contentLeft = parseInt(this.draggingSidebarInfo.content.css('left'));

            this.draggingSidebarInfo.sidebar.width(sidebarWidth + deltaX);
            this.draggingSidebarInfo.content.css('left', `${contentLeft + deltaX}px`);
            this.draggingSidebarInfo.newGroup?.css('left', `${contentLeft + deltaX}px`);

            vent.trigger('section-sidebar:resize');
        }
    }
});

export default StacksView;

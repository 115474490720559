import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Sifter from 'Sifter'

import FilterQuickItems from 'js/views/filters/quick-items.js'
import FilterQuickOptionsBase from 'js/views/filters/quick-options-base.js'
import app from 'js/app.js'

export default Marionette.Layout.extend({
    template: Handlebars.compile(`
        <div class="content-container">
            <div class="filter-pane-container content left-pane"></div>
            <div class="filter-pane-container content right-pane"></div>
        </div>`
    ),
    className: 'popover-content quick-filters-container',
    regions: {
        leftContentRegion: '.filter-pane-container.left-pane',
        rightContentRegion: '.filter-pane-container.right-pane'
    },
    ui: {
        scroll: '.content-container',
        rightContent: '.filter-pane-container.right-pane'
    },
    events: {
        'mousedown': function(ev) {
            ev.preventDefault();
        },
        'mouseenter li:not(.active) > a': function(ev) {
            var allItems = this.getAll();
            var element = $(ev.currentTarget);
            allItems.not(element.addClass('focused')).removeClass('focused');
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        this.listenTo(this.rules, 'add remove reset update', function() {
            this.resetContent();
        });

        this.listenTo(this.parent, 'quick-filter:search', function(search) {
            this.search = search;
            if (this.contentModel) {
                this.contentModel.set('search', search);
            }

            var focused = this.getFocused(this.getAll());
            if (!focused.length) {
                this.onDownKey();
            }
        });

        this.listenTo(this.parent, 'quick-filter:up', this.onUpKey);

        this.listenTo(this.parent, 'quick-filter:down', this.onDownKey);

        this.listenTo(this.parent, 'quick-filter:enter', this.onEnterKey);

        this.setContent(this.model);
    },
    getFocused: function(items) {
        return items.filter('.focused:visible');
    },
    getAll: function() {
        return this.$el.find('li:not(.active) > a');
    },
    onUpKey: function() {
        var allItems = this.getAll();
        var items = allItems.filter(':visible');
        var focused = this.getFocused(items);
        var element;
        if (items.first().is(focused)) {
            element = items.first();
        } else {
            element = items.eq(items.index(focused) - 1);
        }
        allItems.not(element.addClass('focused')).removeClass('focused');
    },
    onDownKey: function() {
        var allItems = this.getAll();
        var items = allItems.filter(':visible');
        var focused = this.getFocused(items);
        var element;
        if (items.last().is(focused)) {
            element = items.last();
        } else {
            element = items.eq(items.index(focused) + 1);
        }
        allItems.not(element.addClass('focused')).removeClass('focused');
    },
    onEnterKey: function() {
        this.getFocused(this.getAll()).click();
    },
    setContent: function(model, parent) {
        this.contentModel = model;

        if (model) {
            model.set('search', this.search);

            var viewType = model.get('view');
            var contentView = new viewType({
                parent: parent,
                root: this,
                model: model
            });

            this.leftContentRegion.show(contentView);

            // custom filters
            if (model.get('customFilters')) {
                var customFiltersModel = new FilterQuickOptionsBase.FilterModel({
                    view: FilterQuickItems.GroupView,
                    inline: true,
                    items: model.get('customFilters')
                });

                this.rightContentRegion.show(new FilterQuickItems.GroupView({
                    parent: parent,
                    root: this,
                    model: customFiltersModel
                }));

                this.ui.rightContent.removeClass('hidden');
                this.$el.addClass('extended');
            } else {
                this.ui.rightContent.addClass('hidden');
                this.rightContentRegion.reset();
                this.$el.removeClass('extended');
            }

            _.defer(function() {
                this.onDownKey();
            }.bind(this));

            // ...
            this.listenTo(contentView, 'filter-item:on-collection', this.filterItemOnCollection);

            for (var i = 0; i < contentView.children.length; ++i) {
                var v = contentView.children.findByIndex(i);
                this.listenTo(v, 'filter-item:on-collection', this.filterItemOnCollection);
            }
        } else {
            this.leftContentRegion.reset();
            this.rightContentRegion.reset();
        }
    },
    filterItemOnCollection: function() {
        var view = this;
        var contentView = this.leftContentRegion.currentView;

        _.defer(function() {
            var containerHeight = Math.min(480, contentView.$el.height() + 12);
            view.$el.css('height', containerHeight);
        });
    },
    resetContent: function() {
        var contentView = this.leftContentRegion.currentView;
        if (!contentView.isClosed) {
            contentView.render();

            _.defer(function() {
                this.onDownKey();
            }.bind(this));
        }
    },
    addRule: function(rule) {
        this.rules.add({
            items: rule
        });
        this.trigger('clear-search');
    },
    showAdvanced: function(model) {
        this.trigger('advanced', model);
    }
});

import BaseCollection from 'js/collections/base'
import CampaignModel from 'js/models/campaign'


export default BaseCollection.extend({
    model: CampaignModel,
    add: function() {
        if (!this.total) {
            this.total = 0;
        }
        this.total += 1;
        BaseCollection.prototype.add.apply(this, arguments);
    },
    remove: function() {
        BaseCollection.prototype.remove.apply(this, arguments);
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react';
import ReactDOM from 'react-dom';

import TextManager from 'app/text-manager'
import KPIWidget from 'js/react_views/widgets/kpi.js'
import TimeSpanWidget from 'js/react_views/widgets/time-span.js'

import style from './campaign-performance.css'


export default Marionette.Layout.extend({
    template: Handlebars.compile(''),
    initialize: function(options) {
        this.sections = [{
            id: 'campaigns_sent',
            name: TextManager.parseText('${ID_CAMPAIGN, plural, capitalize} Sent')
        }, {
            id: 'emails_sent',
            name: 'Emails Sent'
        }, {
            id: 'delivery_rate',
            name: 'Delivery Rate',
            isPercentage: true
        }, {
            id: 'open_rate',
            name: 'Open Rate',
            isPercentage: true
        }, {
            id: 'click_rate',
            name: 'Click Rate',
            isPercentage: true
        }, {
            id: 'unsubscribe_rate',
            name: 'Unsubscribe Rate',
            isPercentage: true
        }];

        this.fetchOptions = _.clone(options.fetchOptions);
        this.dateFilter = {
            'filter_by_date_field': 'sent'
        };
    },
    setFetchOptions: function(fetchOptions) {
        this.fetchOptions = _.clone(fetchOptions);
        this.fetchValues();
    },
    onSpanChanged: function(spanId, params) {
        var beginDate = null;
        var endDate = null;

        if (spanId === 'custom') {
            beginDate = params.beginDate;
            endDate = params.endDate;
        } else if (spanId !== 'all') {
            var end = new Date();
            var begin = new Date(end);

            switch(spanId) {
                case 'day':
                    begin.setDate(end.getDate() - 1);
                    break;

                case 'week':
                    begin.setDate(end.getDate() - 7);
                    break;

                case 'month':
                    begin.setMonth(end.getMonth() - 1);
                    break;

                case 'quarter':
                    begin.setMonth(end.getMonth() - 3);
                    break;

                case 'year':
                    begin.setFullYear(end.getFullYear() - 1);
                    break;
            }

            beginDate = begin.toISOString();
            endDate = end.toISOString();
        }

        if (beginDate && endDate) {
            this.dateFilter.filter_by_begin_date = beginDate;
            this.dateFilter.filter_by_end_date = endDate;
        } else {
            delete this.dateFilter.filter_by_begin_date;
            delete this.dateFilter.filter_by_end_date;
        }

        this.trigger('update-filters', this.dateFilter);
        this.fetchValues();
    },
    onRender: function() {
        this.renderReact();
        this.fetchValues();
    },
    renderReact: function() {
        ReactDOM.render(
            <div className={style.CampaignPerformance}>
                <div className={style.Row}>
                    <div className={style.Title}>{TextManager.parseText('${ID_CAMPAIGN, capitalize} Performance')}</div>
                    <div className={style.SpanWidget}>
                        <TimeSpanWidget
                            onSpanChanged={this.onSpanChanged.bind(this)}
                        />
                    </div>
                </div>
                <KPIWidget
                    ref={(el) => this.kpiWidget = el}
                    sections={this.sections}
                />
            </div>, this.$el.get(0)
        );
    },
    fetchValues: function() {
        const data = _.extend(_.clone(this.fetchOptions), this.dateFilter);
        const self = this;

        $.ajax({
            type: 'GET',
            url: '/campaigns_performance',
            contentType: 'application/json',
            dataType: 'json',
            data: data,
            success: function(response) {
                self.kpiWidget.setValues(response);
            }
        });
    }
});
import GroupModel from 'js/models/group'
import app from 'js/app'


export default GroupModel.extend({
    name: 'Favorites',
    url: function() {
        return app.user.url() + '/favorite_individuals';
    }
});

import _ from 'underscore';
import React from 'react';

import {Button} from 'js/react_views/widgets/widgets';
import RelatedFilesList from 'js/react_views/detail_view_components/related-files-list';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './TemplatedContent.css';

class TemplatedContent extends React.Component {
    render() {
        const {
            userId,
            onCreateNew,
            onDelete
        } = this.props;

        let documents = null;

        if (this.props.documents) {
            documents = _.clone(this.props.documents);
            documents.items = _.sortBy(this.props.documents.items, 'created').reverse();
        }

        return (
            <div className={style.TemplatedContent}>
                <header className={style.header}>
                    {onCreateNew &&
                        <div className={`${style.headerBtnBox} tool-button`}>
                            <Button
                                color="blue"
                                onClick={onCreateNew}>
                                New Document
                            </Button>
                        </div>
                    }
                    <h5 className={style.heading}>Templated Content</h5>
                </header>
                {!documents &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {documents && documents.items.length > 0 &&
                    <RelatedFilesList
                        files={documents.items}
                        userId={userId}
                        onDelete={onDelete} 
                        onYoti={this.props.onYoti}
                        onYotiReminder={this.props.onYotiReminder} 
                        archiveYotiEnvelope={this.props.archiveYotiEnvelope} />
                }
                {documents && documents.items.length === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default TemplatedContent;

import _ from "underscore";
import React from "react";
import app from "js/app";
import Currency from "js/utils/currency";
import Utilities from "js/utils/utilities";
import LoadingIndicator from "js/react_views/widgets/loading-indicator";
import ActivityCreator from "js/utils/activity_creator";
import CommunicationModel from "js/models/communication";
import Tooltip from "js/react_views/tooltip/tooltip";
import { NewSelect } from "js/react_views/widgets/select";

import style from "./region_list.css";

export default class RegionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      footerData: [],
      fetchingData: false,
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData(divisionIds, regionIds) {
    if (!this.mounted) {
      return;
    }

    const query = this.props.widget.params.query;

    if (!query) {
      return;
    }

    // only displays the loader the first time
    this.setState({
      fetchingData: true,
    });

    let options = _.clone(query.options || {});
    options.force = true;

    const args =
      divisionIds.length || regionIds.length
        ? {
            funnel_ids: divisionIds.join(","),
            team_ids: regionIds.join(","),
          }
        : {};

    this.props.widget.fetcher.get(
      query.url,
      args,
      this.onData.bind(this),
      options
    );
  }

  onData(data) {
    if (!this.mounted) {
      return;
    }

    let leagueTotals = {
      compliance: 0,
      get_instructed: 0,
      qualify: 0,
      site_instructed: 0,
      trying_to_win: 0,
      total: 0,
      value: 0,
    };

    const leagueData = Object.keys(data).map((e, i) => {
      const regionData = data[e];
      const item = {
        id: i,
        region_name: e,
        compliance: regionData["Compliance"],
        get_instructed: regionData["Get Instructed"],
        qualify: regionData["Qualify"],
        site_instructed: regionData["Site Instructed"],
        trying_to_win: regionData["Trying to win"],
        total: regionData.num_opportunities,
        value: regionData.sum_opportunities,
      };

      Object.keys(leagueTotals).forEach((e, i) => {
        leagueTotals[e] += item[e];
      });

      return item;
    });

    this.setState({
      listData: _.sortBy(leagueData, (f) => f.region_name.toLowerCase()),
      footerData: leagueTotals,
      fetchingData: false,
    });
  }

  render() {
    return (
      <div className={style.regionList}>
        <div
          className={`${style.loadingContainer} ${
            this.state.fetchingData ? style.active : ""
          }`}
        >
          <div className={style.loadingIcon}>
            <LoadingIndicator />
          </div>
        </div>
        <Filters onRefreshData={this.fetchData.bind(this)} />
        <RegionTable
          listData={this.state.listData}
          footerData={this.state.footerData}
        />
      </div>
    );
  }
}

class Filters extends React.Component {
  constructor(props) {
    super(props);

    this.dropdownsData = this.getDropdownsData();

    const self = this;

    _.defer(function () {
      self.createDropdowns();
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  getDropdownsData() {
    const host = window.location.hostname;
    const ppos = host.indexOf(".");
    const environment = host.substring(ppos + 1, host.indexOf(".", ppos + 1));

    // hardcoded to connells data
    if (environment === "connellsdemo") {
      return [
        {
          users: [
            "3379710a-d757-4815-ac58-a537b5ef7c3d", //Test User1
          ],
          brand: {
            name: "Connells",
          },
          divisions: [
            {
              id: "a850488e-a42d-4051-ae41-192eb702eb8e",
              name: "Connells Land",
            },
            {
              id: "823588fe-64e6-4031-88c3-696af03cd5da",
              name: "Connells New Homes",
            },
            {
              id: "71ac035d-bfb5-4609-942c-a6a545b10f7f",
              name: "Connells Land Acquisition",
            },
            // {
            //   id: "039035e7-50b1-489c-902e-825dbb89c1bb",
            //   name: "Archived Connells Land",
            // },
            // {
            //   id: "8008c29e-31f7-489d-ac84-df71b68e2b14",
            //   name: "Archived Connells New Homes",
            // },
          ],
          regions: [
            {
              id: "3f71b01f-c0f6-4100-89a7-e170ace2583f",
              name: "Connells Land Central East",
            },
            {
              id: "275638f7-4c82-43a3-a742-4611fede82fa",
              name: "Connells Land Central South",
            },
            {
              id: "170f8e00-de08-48d0-83d3-26770b528f75",
              name: "Connells Land Central West",
            },
            {
              id: "0ed61ba2-1483-455a-8afc-675f1db55394",
              name: "Connells Land Midlands East",
            },
            {
              id: "9520a677-ae28-4152-9665-6c31deab5eb9",
              name: "Connells Land Midlands West",
            },
            {
              id: "0a45acb4-313c-4041-9997-624a12711710",
              name: "Connells Land Southern",
            },
            {
              id: "052bbfe1-75fb-459d-95aa-f425164359e8",
              name: "Connells New Homes Central East",
            },
            {
              id: "abc48fc3-8023-4fa0-b4cf-09fe5c8cea39",
              name: "Connells New Homes Central South",
            },
            {
              id: "83158cdf-93ac-45e7-a089-b7f4db046d77",
              name: "Connells New Homes Central West",
            },
            {
              id: "f172c6b8-4e73-45e8-814a-6434ae7245bd",
              name: "Connells New Homes Midlands East",
            },
            {
              id: "eb6e7a61-646e-4c4a-b417-21cb005af90d",
              name: "Connells New Homes Midlands West",
            },
            {
              id: "4313a4ae-e4bd-4a26-a50d-5946c76b942c",
              name: "Connells New Homes Southern",
            },
          ],
        },
        {
          users: [
            "43d6e77d-cbd5-4a3f-aca5-8ee2bdb6e74e", //Test User2
          ],
          brand: {
            name: "Sequence",
          },
          divisions: [
            {
              id: "507a32f3-7905-4e00-abc2-1910d85c42ef",
              name: "Sequence Land",
            },
            {
              id: "82c51c03-15df-4b7c-9e3f-d0bd40648870",
              name: "Sequence New Homes",
            },
            {
              id: "80992e0f-aaaa-48bf-8bd3-177442cfd60a",
              name: "Sequence Land Acquisition",
            },
            // {
            //   id: "ec31564d-5c5e-4df2-a6e3-b5d69877758e",
            //   name: "Archived Sequence Land",
            // },
            // {
            //   id: "9c5c3002-f2d7-4929-9457-8c60ed76139e",
            //   name: "Archived Sequence New Homes",
            // },
          ],
          regions: [
            {
              id: "6eceba13-d08c-4d9e-b2d7-d65928c7eb33",
              name: "Sequence Land EC North",
            },
            {
              id: "fabb13db-988d-49eb-8a1a-1f0480f20e26",
              name: "Sequence Land EC South",
            },
            {
              id: "d71fa32e-7991-47e3-ba3c-870deb034b53",
              name: "Sequence Land London",
            },
            {
              id: "841a0f14-5d05-4db2-9268-5d553e5587c4",
              name: "Sequence Land North & Scotland",
            },
            {
              id: "f85576ba-399d-40a1-93d4-d2b1dcfe23a7",
              name: "Sequence Land South",
            },
            {
              id: "492a9f9c-86b1-4fdb-abdb-e7e78694a9c8",
              name: "Sequence New Homes E&C North",
            },
            {
              id: "6f8b579b-ce9b-46c8-a3fa-39c0c5b71099",
              name: "Sequence New Homes E&C South",
            },
            {
              id: "a7198998-bd6c-467f-86a8-89bc1b73a926",
              name: "Sequence New Homes London",
            },
            {
              id: "a052ba97-10fc-4780-add0-e66015125125",
              name: "Sequence New Homes North & Scotland",
            },
            {
              id: "0d83cddd-1a27-42d3-8bbc-b60c8f9f19c6",
              name: "Sequence New Homes South",
            },
            {
              id: "e4875880-3d4e-40d3-8378-63a8f1b1f04b",
              name: "Sequence New Homes Sussex",
            },
          ],
        },
        {
          users: [
            "451bfd92-0241-4604-b491-516920aafba0", //Nathan Butler
          ],
          brand: {
            name: "Countrywide",
          },
          divisions: [
            {
              id: "3f403e71-ad54-4937-ab39-7f1e0ed89518",
              name: "Countrywide Land",
            },
            {
              id: "a99969c1-ca11-4721-89dd-41e9fec482e1",
              name: "Countrywide New Homes",
            },
            // {
            //   id: "01a321b1-91c2-4ea4-ba36-482cd2ba2460",
            //   name: "Countrywide Archived",
            // },
          ],
          regions: [
            {
              id: "ab4a7723-341b-4a0e-ba5f-1a60540ee821",
              name: "Countrywide Land Anglia & Central Counties",
            },
            {
              id: "9673dd6f-dfc2-4c78-a66a-62e278f90d5c",
              name: "Countrywide Land Central South & Wales",
            },
            {
              id: "477ef821-1923-4a2e-a7bd-73db7434364f",
              name: "Countrywide Land Home Counties",
            },
            {
              id: "3ea34c2e-b3b2-41bd-b114-1bedf8d042d7",
              name: "Countrywide Land Kent & Sussex",
            },
            {
              id: "558408ed-1af5-4f1a-9085-d91ee46f1ff8",
              name: "Countrywide Land London & Essex",
            },
            {
              id: "c8822639-cc76-47c6-bf6e-b99168b039ef",
              name: "Countrywide Land N.Wales, West & Birmingham",
            },
            {
              id: "69ee2bb5-b7e0-4375-9577-a49984501686",
              name: "Countrywide Land North England",
            },
            {
              id: "351cda63-05cb-4530-b754-83d8066430a3",
              name: "Countrywide Land Scotland",
            },
            {
              id: "3fbecd17-2862-479b-9290-0a1487948c75",
              name: "Countrywide Land South Coast",
            },
            {
              id: "31bab10c-75c3-41cd-aace-16edf8cbaa12",
              name: "Countrywide Land South West",
            },
            {
              id: "67cab06d-3780-4f9e-9a80-302829c4fdff",
              name: "Countrywide New Homes Anglia & Central Counties",
            },
            {
              id: "c9bfc13d-86f4-4bfc-817d-8bd75ee62d87",
              name: "Countrywide New Homes Central South & Wales",
            },
            {
              id: "9af4cd22-e0c8-49bf-baaf-1613f13f53e7",
              name: "Countrywide New Homes Home Counties",
            },
            {
              id: "26006b73-948c-4c2c-a801-0be18d57b5f1",
              name: "Countrywide New Homes Kent & Sussex",
            },
            {
              id: "642c9e1f-c6b1-474a-bc6a-af5e82a4c7b1",
              name: "Countrywide New Homes London & Essex",
            },
            {
              id: "9fc95a78-045d-49e8-bf38-f8a9b5ea1755",
              name: "Countrywide New Homes N.Wales, West & Birmingham",
            },
            {
              id: "2da1a7a1-748c-4771-93ca-20e8b2b0e1cd",
              name: "Countrywide New Homes North England",
            },
            {
              id: "9250294a-b682-4ca4-b7df-bcf0d1aa7bbc",
              name: "Countrywide New Homes Scotland",
            },
            {
              id: "86d82019-e566-480c-b2cd-2cfbcec90ef1",
              name: "Countrywide New Homes South Coast",
            },
            {
              id: "931a7b2e-0dc2-4959-b850-7467611d0a8d",
              name: "Countrywide New Homes South West",
            },
          ],
        },
      ];
    }

    return [
      {
        users: [
          "cfa7aa19-9892-4486-9bae-2043335d437e", //Jason Willetts
        ],
        brand: {
          name: "Connells",
        },
        divisions: [
          { id: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6", name: "Connells Land" },
          {
            id: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
            name: "Connells New Homes",
          },
          {
            id: "7bd7cc89-ad03-4f78-94c4-7bbbd59c23f5",
            name: "Connells Land Acquisition",
          },
        ],
        regions: [
          {
            id: "7d53ae89-aeda-49c3-a2f7-af7d5f7bdb28",
            name: "Connells Land Central East",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "558f5ad0-34db-470b-90f4-b705bdeacebc",
            name: "Connells Land Central South",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "8a17e064-8368-4841-81bf-24ae6193ae5c",
            name: "Connells Land Central West",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "c413c8b2-c959-4e37-9270-54365192f8ad",
            name: "Connells Land Midlands East",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "e3b75d6e-c6bf-497d-8c0c-6f2cb66bd44f",
            name: "Connells Land Midlands West",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "aa1ee1ed-234a-4c09-9963-65a18b9e3fa5",
            name: "Connells Land Southern",
            divisionid: "4c63e2a7-2e3e-423f-b2fd-f6cf39283fe6",
          },
          {
            id: "bc1395c1-9225-4de0-b87c-79e0c77afc17",
            name: "Connells New Homes Central East",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
          {
            id: "7122b839-39c2-4ea0-87c3-6b7d1739f056",
            name: "Connells New Homes Central South",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
          {
            id: "d8b52cfc-143d-4d74-8266-818fa0ee3384",
            name: "Connells New Homes Central West",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
          {
            id: "1f484b56-06d1-4c4b-8434-0cdb720c12f0",
            name: "Connells New Homes Midlands East",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
          {
            id: "d93376bc-3d78-4c47-933f-fcf58c89f106",
            name: "Connells New Homes Midlands West",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
          {
            id: "012918a5-fd7c-4e39-8e73-aca6b191d648",
            name: "Connells New Homes Southern",
            divisionid: "69070d35-044e-485b-9783-8e5d4f8a3dcd",
          },
        ],
      },
      {
        users: [
          "a60107df-4d86-4d98-9d29-ab9e92668148", //Jason Howes
        ],
        brand: {
          name: "Sequence",
        },
        divisions: [
          { id: "3d1db541-6ad4-4589-99a5-90c1206a0760", name: "Sequence Land" },
          {
            id: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
            name: "Sequence New Homes",
          },
          {
            id: "8ea77ad8-eacb-4aba-948b-d28c40451203",
            name: "Sequence Land Acquisition",
          },
        ],
        regions: [
          {
            id: "39dba157-9f47-4f72-8a76-6c8172d0c531",
            name: "Sequence Land EC North",
            divisionid: "3d1db541-6ad4-4589-99a5-90c1206a0760",
          },
          {
            id: "9598b3b7-dc9a-4b4a-9add-d013de6fd88d",
            name: "Sequence Land EC South",
            divisionid: "3d1db541-6ad4-4589-99a5-90c1206a0760",
          },
          {
            id: "4034409e-84c7-4ddc-b91b-36d6cbeff834",
            name: "Sequence Land London",
            divisionid: "3d1db541-6ad4-4589-99a5-90c1206a0760",
          },
          {
            id: "75c87f20-2911-4c5e-bcfa-1a5cdc9816a7",
            name: "Sequence Land North & Scotland",
            divisionid: "3d1db541-6ad4-4589-99a5-90c1206a0760",
          },
          {
            id: "4cbbe680-0ca0-4e8a-afa3-4dacdf912798",
            name: "Sequence Land South",
            divisionid: "3d1db541-6ad4-4589-99a5-90c1206a0760",
          },
          {
            id: "db0d1e72-f7dd-43f2-ae55-f80ab06e2510",
            name: "Sequence NH E&C North",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
          {
            id: "4b507507-4a79-49e3-812e-ad281bb8e073",
            name: "Sequence NH E&C South",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
          {
            id: "07ff9d4a-0297-4c49-9b95-5f9e3a33791f",
            name: "Sequence NH London",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
          {
            id: "a1dd1763-5cdb-475b-870f-b808c2e7ad3a",
            name: "Sequence NH North & Scotland",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
          {
            id: "3e8027fd-14a8-4e9d-a869-e7a2f7dd1bd4",
            name: "Sequence NH South",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
          {
            id: "277daec8-aee9-40f7-a80a-e2f0be6d6a56",
            name: "Sequence NH Sussex",
            divisionid: "e668c8db-6a9e-4d0d-b56a-d31d4d9175c9",
          },
        ],
      },
      {
        users: [
          "25b41f46-8487-4da8-b674-055f933c061e", //Nathan Butler
          "5d73c2d3-0b89-4b4d-912a-ca8e7c4b88e9", //Owen McLaughlin
        ],
        brand: {
          name: "Countrywide",
        },
        divisions: [
          {
            id: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
            name: "Countrywide Land",
          },
          {
            id: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
            name: "Countrywide New Homes",
          },
        ],
        regions: [
          {
            id: "9260de3e-9729-42e5-a9e6-3ed36f1e3404",
            name: "Countrywide Land Anglia & Central Counties",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "426b2581-476c-496d-abaf-3aa44dca7d0e",
            name: "Countrywide Land Central South & Wales",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "f40f0ec8-cf40-4a5e-a2db-56bdd8b1e320",
            name: "Countrywide Land Home Counties",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "2adb3ec0-4ccf-4457-8f30-31ba91aa22b4",
            name: "Countrywide Land Kent & Sussex",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "34205b49-6539-48b6-8333-7308090182e5",
            name: "Countrywide Land London & Essex",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "67311373-3794-4ffa-97cc-7feecabbf3ff",
            name: "Countrywide Land N.Wales, West & Birmingham",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "b0f884f2-d07b-4c84-8a4d-dbf1f31237f6",
            name: "Countrywide Land North England",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "d17d2c74-4064-4475-8370-aed1778be87d",
            name: "Countrywide Land Scotland",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "e48aeea1-03a0-4fca-9e36-b818d41853d5",
            name: "Countrywide Land South Coast",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "60242af2-9a3f-46bd-9e8d-76d803d3a3e4",
            name: "Countrywide Land South West",
            divisionid: "62dbf798-e0ca-429c-b8a9-631e9a84568a",
          },
          {
            id: "f1b837bb-76e9-4458-a11f-51753d02cdc5",
            name: "Countrywide New Homes Anglia & Central Counties",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "55622b7d-1ad2-4183-9276-f71035ad5875",
            name: "Countrywide New Homes Central South & Wales",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "73083855-2d8d-495e-94da-e65a17942404",
            name: "Countrywide New Homes Home Counties",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "0800a544-04b4-4881-b8aa-a1a7c802d670",
            name: "Countrywide New Homes Kent & Sussex",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "081448d9-10ab-4c60-9df8-dbdb90d003bb",
            name: "Countrywide New Homes London & Essex",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "5109ba30-76cc-40b3-9e46-d3ae76dc6ec9",
            name: "Countrywide New Homes N.Wales West & Birmingham",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "3362c021-2849-4906-8779-c5fbb2c8636e",
            name: "Countrywide New Homes North England",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "c1225abc-7f63-4c00-9601-f13ab5d03d34",
            name: "Countrywide New Homes Scotland",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "766ba60e-fb7b-4d0f-87bc-5180c13d1055",
            name: "Countrywide New Homes South Coast",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
          {
            id: "589791e7-1644-4838-8fc8-e448547eed2d",
            name: "Countrywide New Homes South West",
            divisionid: "c7311f9e-3b7b-4db4-84ec-504a94db6bbd",
          },
        ],
      },
    ];
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  createDropdowns() {
    if (!this.mounted) {
      return;
    }

    // Default brand based on user
    let userId = app.user.get("id");
    //let userId ="cfa7aa19-9892-4486-9bae-2043335d437e"; //Connells (Jason Willetts)
    //let userId ="a60107df-4d86-4d98-9d29-ab9e92668148"; //Sequence (Jason Howes)
    //let userId ="25b41f46-8487-4da8-b674-055f933c061e"; //Countrywide (Nathan Butler)
    //let userId ="5d73c2d3-0b89-4b4d-912a-ca8e7c4b88e9"; //Countrywide (Owen McLaughlin)
    const userData = this.dropdownsData.find((e) => e.users.includes(userId));
    const defaultBrand = userData?.brand;

    // Set dropdowns
    const brands = (userData ? [userData] : this.dropdownsData).map(
      (e) => e.brand
    );
    this.hasBrands = brands?.length > 1;

    // Load data
    this.brandsElement.setData(brands);
    this.brandsElement.setValue(defaultBrand, true);

    this.onBrandChange();
  }

  getSelectedBrand() {
    return this.dropdownsData.find(
      (e) => e.brand.name === this.brandsElement.selectedItem?.name
    );
  }

  onBrandChange() {
    // Reset cascading dropdowns data
    const brand = this.getSelectedBrand();

    this.divisionsElement.setData(brand?.divisions.map((e) => e) ?? []);
    this.divisionsElement.setValue(null, true);

    this.regionsElement.setData(brand?.regions.map((e) => e) ?? []);
    this.regionsElement.setValue(null, true);

    this.refreshData();
  }

  onDivisionChange() {
    if (this.divisionsElement.selectedItem) {
      // Update regions based on division
      this.regionsElement.setData(
        this.getSelectedBrand().regions.filter(
          (e) => e.divisionid === this.divisionsElement.selectedItem.id
        )
      );
      this.regionsElement.setValue(null);
    } else {
      // Reset both divisions and regions
      this.onBrandChange();
    }
  }

  onRegionChange() {
    if (this.regionsElement.selectedItem) {
      // Set division based on region
      this.divisionsElement.setValue(
        this.getSelectedBrand().divisions.find(
          (e) => e.id === this.regionsElement.selectedItem.divisionid
        ),
        true
      );
    }

    this.refreshData();
  }

  onClearFilters() {
    const parentElement = this.hasBrands
      ? this.brandsElement
      : this.divisionsElement;

    parentElement.setValue(null);
  }

  refreshData() {
    const divisionIds = this.divisionsElement.selectedItem
      ? [this.divisionsElement.selectedItem.id]
      : this.divisionsElement.data.map((e) => e.id);

    const regionIds = this.regionsElement.selectedItem
      ? [this.regionsElement.selectedItem.id]
      : this.regionsElement.data.map((e) => e.id);

    this.props.onRefreshData(divisionIds, regionIds);
  }

  render() {
    return (
      <div className={style.regionFilters}>
        <div
          className={`${style.filterItem} ${style.brand} ${
            this.hasBrands ? "" : style.hidden
          }`}
        >
          <NewSelect
            ref={(el) => (this.brandsElement = el)}
            data={[]}
            placeholder="Brand"
            text="name"
            onSelect={this.onBrandChange.bind(this)}
            options={{
              minimumInputLength: -1,
              allowClear: true,
            }}
          />
        </div>
        <div className={`${style.filterItem} ${style.division}`}>
          <NewSelect
            ref={(el) => (this.divisionsElement = el)}
            data={[]}
            placeholder="Division"
            text="name"
            className={`${style.filterToggle} ${
              this.brandsElement?.selectedItem ? style.active : ""
            }`}
            onSelect={this.onDivisionChange.bind(this)}
            options={{
              minimumInputLength: -1,
              allowClear: true,
            }}
          />
        </div>
        <div className={`${style.filterItem} ${style.region}`}>
          <NewSelect
            ref={(el) => (this.regionsElement = el)}
            data={[]}
            placeholder="Region"
            text="name"
            width={350}
            className={`${style.filterToggle} ${
              this.brandsElement?.selectedItem ? style.active : ""
            }`}
            onSelect={this.onRegionChange.bind(this)}
            options={{
              minimumInputLength: -1,
              allowClear: true,
            }}
          />
        </div>
        <div
          className={`${style.clear_filter} ${
            (this.hasBrands && this.brandsElement?.selectedItem) ||
            this.divisionsElement?.selectedItem ||
            this.regionsElement?.selectedItem
              ? style.active
              : ""
          }`}
        >
          <a
            onClick={(ev) => {
              ev.stopPropagation();
              this.onClearFilters();
            }}
          >
            Clear
          </a>
        </div>
      </div>
    );
  }
}

class RegionTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={style.regionTableContainer}>
        <div className={style.regionTableHead}>
          <table className={style.regionTable}>
            <RegionTableHeaderRow />
          </table>
        </div>
        <div className={style.regionTableBody}>
          <table className={style.regionTable}>
            <tbody>
              {this.props.listData.map((e) => {
                return <RegionTableItemRow key={e.id} data={e} />;
              })}
            </tbody>
          </table>
        </div>
        <div className={style.regionTableFooter}>
          <table className={style.regionTable}>
            <RegionTableFooterRow data={this.props.footerData} />
          </table>
        </div>
      </div>
    );
  }
}

class RegionTableItemRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr>
        <td title={this.props.data.region_name}>
          {this.props.data.region_name}
        </td>
        <td>{this.props.data.trying_to_win}</td>
        <td>{this.props.data.qualify}</td>
        <td>{this.props.data.get_instructed}</td>
        <td>{this.props.data.compliance}</td>
        <td>{this.props.data.site_instructed}</td>
        <td>
          <span className={`badge badge-line ${style.total_badge}`} title="No. Units">{this.props.data.total}</span>
          {Currency.format("GBP", Math.floor(this.props.data.value))}
        </td>
      </tr>
    );
  }
}

class RegionTableHeaderRow extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th></th>
          <th>Trying to win</th>
          <th>Qualify</th>
          <th>Get Instructed</th>
          <th>Compliance</th>
          <th>Site Instructed</th>
          <th>Value</th>
        </tr>
      </thead>
    );
  }
}

class RegionTableFooterRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tfoot>
        <tr>
          <td></td>
          <td>{this.props.data.trying_to_win}</td>
          <td>{this.props.data.qualify}</td>
          <td>{this.props.data.get_instructed}</td>
          <td>{this.props.data.compliance}</td>
          <td>{this.props.data.site_instructed}</td>
          <td>
            <span className={`badge badge-line ${style.total_badge}`}  title="Total No. Units">
              {this.props.data.total}
            </span>
            {Currency.format("GBP", Math.floor(this.props.data.value))}
          </td>
        </tr>
      </tfoot>
    );
  }
}

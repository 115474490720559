import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import backboneSelect2 from 'js/widgets/backbone-select2'
import vent from 'js/vent'
import template from 'templates/settings/security.handlebars'


export default Marionette.Layout.extend({
    className: 'security',
    template: Handlebars.compile(template),
    ui: {
        periodSelect: '.period',
        enable2fa: '.enable-2fa'
    },
    events: {
        'click .enable-2fa': function(ev) {
            var target = $(ev.target);
            var enabled = target.is(":checked");
            $.post('/clients/1/otp_settings', JSON.stringify({
                otp_enabled: enabled
            }), function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Preferences saved',
                            classes: 'saved success',
                            timer: 3000
                        };
                    }
                });
            });
        }
    },
    onRender: function() {
        var self = this;

        $.get('/clients/1/otp_settings', function(otpSettings) {
            self.ui.enable2fa.prop('checked', otpSettings.otp_enabled);

            var periods = [{
                id: 'always',
                title: 'Always require re-authentication',
                days: 0
            }, {
                id: 'never',
                title: 'Never require re-authentication',
                days: null,
            }];

            for (var i = 7; i > 0; --i) {
                var plural = (i === 1) ? '' : 's';

                periods.unshift({
                    id: `${i}_day${plural}`,
                    title: `${i} Day${plural}`,
                    days: i
                });
            }

            var activePeriod = null;

            if (otpSettings.otp_expires_in_days === 0) {
                activePeriod = periods[periods.length - 2]; // always
            } else if (otpSettings.otp_expires_in_days >= 1 && otpSettings.otp_expires_in_days <= 7) {
                activePeriod = periods[otpSettings.otp_expires_in_days - 1];
            } else {
                activePeriod = periods[periods.length - 1]; // never
            }

            var periodSelect = new backboneSelect2.SelectView({
                view: self,
                $el: self.ui.periodSelect,
                value: activePeriod,
                data: periods,
                text: 'title',
                options: {
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'period-select-popover popover select2-drop-wider'
                }
            });

            self.listenTo(periodSelect, 'change', function(period) {
                $.post('/clients/1/otp_settings', JSON.stringify({
                    otp_expires_in_days: period.days
                }), function() {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                });
            });
        });
    }
});

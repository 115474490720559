import React from 'react';

import styles from './popover.css';


class Popover extends React.Component {
    render() {
        // default width 600
        const height = this.props.height;
        const width = this.props.width || 600;

        return (
            <div className={styles.PopoverContainer}>
                <div className={styles.popoverBackdrop}>
                </div>
                <div className={styles.popover} style={{ width: width, height: height, top: "10%", left: "calc(50% - " + width/2 + "px)" }}>
                    {
                        this.props.header &&
                        <div className={styles.header}>
                            {
                                this.props.header.onClose &&
                                <a className={styles.closeButton} onClick={this.props.header.onClose}>Close</a>
                            }
                            { this.props.header.title }
                            {
                                this.props.header.onDeleteIcon &&
                                <i className="icon-trashcan" onClick={this.props.header.onDeleteIcon}/>
                            }
                            {
                                this.props.header.onSave &&
                                <a className={styles.saveButton} onClick={this.props.header.onSave}>Save</a>
                            }
                            {
                                this.props.header.onDelete &&
                                <a className={styles.deleteButton} onClick={this.props.header.onDelete}>Delete</a>
                            }
                        </div>
                    }
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Popover;
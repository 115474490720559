import PaginatedListView from 'js/views/base/paginated_list.js'
import TwitterItemView from 'js/views/social/twitter_item.js'
import FacebookItemView from 'js/views/social/facebook_item.js'
import ListNavbarView from 'js/views/base/list_navbar.js'
import BaseToolbarView from 'js/views/base/toolbar.js'


function createSocialList(topView, collection) {
    var navbarView, listView, toolbarView;

    listView = new PaginatedListView({
        id: 'social-list',
        listGetItemView: function(model){
            if (!model) {
                return TwitterItemView;
            }

            switch (model.get('network')) {
            case 'twitter':
                return TwitterItemView;
            case 'facebook':
                return FacebookItemView;
            }
        },
        listItemViewOptions: {
            section: 'Social',
            url: 'social/feed'
        },
        collection: collection
    });

    toolbarView = new BaseToolbarView({
        tools: {
            newItem: false,
            removeItem: false,
            moveToGroup: false,
            selectAllPage: true,
            assignItem: true
        }
    });

    navbarView = new ListNavbarView({
        className: 'nested-social-feed',
        title: 'Social',
        showTabs: false,
        tabs: [
            {
                id: 'social',
                title: 'Social',
                'class': 'list-nav-social',
                view: listView,
                toolbarView: toolbarView
            }
        ]
    });

    return navbarView;
}

export default createSocialList;

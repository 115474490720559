import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import Currency from 'js/utils/currency.js'
import ClientModel from 'js/models/client.js'
import forecastCurrencyTemplate from 'templates/settings/forecast_currency.handlebars'
import addNewCurrencyTemplate from 'templates/settings/forecast_currency_add_new_currency.handlebars'
import conversionListItemTemplate from 'templates/settings/forecast_currency_list_item_view.handlebars'


var currencies,
    ConversionItemModel = Backbone.Model.extend({});

var ConversionItemCollection = Backbone.Collection.extend( {
    model: ConversionItemModel
} );

var AddCurrencyView = Marionette.ItemView.extend({
    className: 'add-currency edit-form-modal',
    template: Handlebars.compile(addNewCurrencyTemplate),
    ui: {
        editCurrency: '.edit-currency',
        conversion: '#conversion',
        currency_label: '.currency-label'
    },
    events: {
        'keypress #conversion': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        },
        'change #conversion': function(ev) {
            var target = $(ev.currentTarget);
            var value = parseFloat(target.val() || 0);

            if (!isNaN(value)) {
                this.conversion = value;
                target.val(value);
            }
            else {
                target.val(this.conversion);
            }
        }
    },
    initialize: function() {
        var client = app.user.get('client');
        var actualCurrency = client.default_currency || 'USD';
        var clientCurrencies = client.currencies || {};

        this.currenciesAvailable = [];

        for (var i = 0; i < currencies.length; ++i) {
            var c = currencies[i];

            // it is not the default currency and doesn't have conversion value yet
            if ((c.id !== actualCurrency) && (!clientCurrencies[c.id])) {
                this.currenciesAvailable.push({ id: c.id, text: c.text });
            }
        }
    },
    onRender: function() {
        if (this.currenciesAvailable.length > 0) {
            var view = this;

            this.currency = this.currenciesAvailable[0].id;
            this.conversion = 1.0;

            this.currencySelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.editCurrency,
                text: 'text',
                value: this.currency,
                data: this.currenciesAvailable,
                options: {
                    placeholder: 'Select currency',
                    dropdownCssClass: 'currency-select-popover popover'
                }
            });

            this.ui.editCurrency.on('change', function() {
                view.currency = view.ui.editCurrency.select2('val');
                view.ui.currency_label.text(view.ui.editCurrency.select2('data').id);
            });
        }
    },
    onShow: function() {
        this.ui.editCurrency.select2("focus");
    },
    templateHelpers: function() {
        return {
            currencies_available: this.currenciesAvailable.length > 0,
            default_currency: app.user.get('client').default_currency,
            selected_currency: this.currenciesAvailable.length ? this.currenciesAvailable[0].id : ''
        };
    },
    save: function() {
        this.trigger('save', this.currency, this.conversion.toString());
    }
});

var ConversionItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(conversionListItemTemplate),
    templateHelpers: function() {
        return  { default_currency: app.user.get('client').default_currency };
    },
    ui: {
        conversion: '.conversion',
        editConversion: '.edit-conversion',
        tooltips: '[data-toggle=tooltip]'
    },
    events: {
        'click .delete-item': function() {
            var client = new ClientModel(app.user.get('client'));
            var currencies = client.get('currencies') || {};

            delete currencies[this.model.get('id')];
            client.save({'currencies': currencies}, {patch: true});

            this.model.collection.remove(this.model);
        }
    },
    onRender: function() {
        this.activateElement(this.ui.conversion, this.ui.editConversion, 'conversion');

        this.ui.tooltips.tooltip({
            container: this.$el,
            placement: 'left'
        });
    },
    activateElement: function(text, edit, field) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('blur', function() {
            var val = edit.val();
            var model = self.model;

            val = parseFloat(val);

            if (_.isNaN(val)) {
                return;
            }

            // Save if the value has been changed
            if (val.toString() !== model.get(field)) {
                edit.val(val);
                text.text(val.toString());
                model.set(field, val.toString());

                // ...
                var client = new ClientModel(app.user.get('client'));
                var currencies = client.get('currencies') || {};

                currencies[model.get('id')] = model.get(field);
                client.save({'currencies': currencies}, {patch: true});
            }

            hideEdit();
        });
    },
    onBeforeClose: function() {
        this.ui.tooltips.tooltip('destroy');
    }
});

var ConversionsList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'currency-list editable-list',
    itemView: ConversionItemView
});

export default Marionette.Layout.extend({
    className: 'currency-settings',
    template: Handlebars.compile(forecastCurrencyTemplate),
    ui: {
        defaultCurrency: ".default-currency"
    },
    regions: {
        currenciesListRegion: '.currencies-list-container',
        addCurrency: {
            selector: '.add-currency',
            regionType: ModalRegion
        }
    },
    events: {
        'click #tracking-code': function(ev) {
            $(ev.currentTarget).focus().select();
        },
        'click .add-currency': function() {
            var addCurrencyView = new AddCurrencyView();

            this.addCurrency.show(addCurrencyView);

            this.listenTo(addCurrencyView, 'save', function(currency, conversion) {
                var model = new ClientModel(app.user.get('client'));
                var currencies = model.get('currencies') || {};

                currencies[currency] = conversion;

                model.save({'currencies': currencies}, {patch: true});
                this.addCurrency.reset();

                this.showConversions();
            });

            this.listenTo(addCurrencyView, 'cancel', function() {
                this.addCurrency.reset();
            });
        }
    },
    initialize: function() {
        currencies = Currency.getUsedCurrenciesToSelect2Array();
    },
    onRender: function() {
        var currency = app.user.get('client').default_currency || 'USD';
        var view = this;

        this.currencySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.defaultCurrency,
            text: 'text',
            value: currency,
            data: currencies,
            options: {
                placeholder: 'Select currency',
                dropdownCssClass: 'currency-select-popover popover'
            }
        });

        // ...
        this.ui.defaultCurrency.on('change', function() {
            var val = view.ui.defaultCurrency.select2('val');
            var attrs = app.user.get('client');
            var model = new ClientModel(attrs);

            // if the client has conversions defined the change of currency will destroy them
            var clientCurrencies = app.user.get('client').currencies || {};

            if (_.keys(clientCurrencies).length > 0) {
                var mbContent = {
                    message: Handlebars.compile(
                            '<p>If you change default currency, ' +
                            'all currency conversions will be permanently deleted.</p>' +
                            '<strong class="cta">Do you wish to continue?</strong>'
                        ),
                    accept_is_negative: true,
                    icon: 'icon-warning'
                };
                MessageBox.showYesNo(mbContent, view,
                    function() { // Yes
                        attrs.currencies = {};
                        attrs.default_currency = val;
                        model.save(attrs, {
                            patch: true,
                            success: function() {
                                app.user.set('client', attrs);
                            }
                        });
                        view.render();
                    },
                    function() { // No
                        view.ui.defaultCurrency.select2('val', model.get('default_currency'));
                    });
            }
            else {
                attrs.default_currency = val;
                model.save(attrs, {
                    patch: true,
                    success: function() {
                        app.user.set('client', attrs);
                    }
                });
            }
        });

        // ...
        this.showConversions();
    },
    showConversions: function() {
        var clientCurrencies = app.user.get('client').currencies || {};
        var collection = new ConversionItemCollection();

        for (var key in clientCurrencies) {
            if (clientCurrencies.hasOwnProperty(key)) {
                collection.add(new ConversionItemModel({
                    id: key,
                    currency: Currency.composeCurrencyName(key),
                    conversion: clientCurrencies[ key ]
                }));
            }
        }

        this.currenciesListRegion.show(new ConversionsList({ collection: collection }));
        this.$el.find('.content-container').nanoScroller();
    }
});

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import DateTimePicker from 'js/widgets/date-time-picker'
import dateFormat from 'js/utils/date-format';
import app from 'js/app'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import ChecklistModel from 'js/models/checklist.js'
import ChecklistTaskModel from 'js/models/checklist_task.js'
import ChecklistsCollection from 'js/collections/checklists.js'
import ChecklistsTaskCollection from 'js/collections/checklist_tasks.js'
import TeamsCollection from 'js/collections/teams.js'
import checklistsTemplate from 'templates/settings/checklists.handlebars'
import addChecklistTemplate from 'templates/settings/add_new_checklist.handlebars'
import addChecklistTaskTemplate from 'templates/settings/add_new_checklist_task.handlebars'
import checklistListItemViewTemplate from 'templates/settings/checklist_list_item_view.handlebars'
import taskListItemViewTemplate from 'templates/settings/checklist_tasks_item_view.handlebars'
import checklistTaskTemplate from 'templates/settings/checklist_tasks.handlebars'

// ---------------- Checklist Item --------------------------------
var ChecklistItemView = Marionette.Layout.extend( {
    tagName: 'li',
    template: Handlebars.compile( checklistListItemViewTemplate ),
    ui: {
        name: '.name',
        creator: '.creator',
        state: '.state',
        tooltips: '[data-toggle=tooltip]'
    },
    attributes: function() {
        return {
            cid: this.model.cid
        };
    },
    templateHelpers: function() {
        return { 
            creator: this.model.attributes.creator.name,
            add_auto_task: this.model.get('auto_add_tasks'),
            for_automation: this.model.get('for_automation')
        };
    },
    events: {
        'click .delete-item': function() {
            var view = this,
                mbContent = {
                    message: Handlebars.compile(
                            'Deleting a checklist will permanently remove it from your data' +
                            '<strong class="cta">Do you want to continue?</strong>'
                        ),
                    icon: 'icon-warning',
                    accept_is_negative: true
                };

            this.ui.tooltips.tooltip('hide');

            MessageBox.showYesNo( mbContent, this, function() {
                view.$el.fadeOut(400, function() {
                    view.ui.tooltips.tooltip('destroy');
                    view.model.destroy();
                });
            });
        },
        'click .edit-item': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('edit-checklist', this.model);
        },
        'click .name': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('checklist-task-list', this.model);
        },
        'click .checklist-state': function(ev) {
            if (ev.target.value === 'published') {
                var self = this;
                var checklistTasks;

                $.ajax({
                    url: `/checklists/${self.model.get('id')}/items`,
                    type: 'GET',
                    async: false,
                    success: function (data) {
                        checklistTasks = data
                    }
                });

                if (checklistTasks.length === 0) {
                    MessageBox.showOk({
                        message: 'Please add at least one task to the checklist before publishing.',
                        icon: 'icon-warning'
                    }, this);

                    $(ev.target).blur();
                    this.$el.find(".checklist-state[value='" + this.model.get('state') + "']").prop("checked", true);

                    return;
                }
            }

            this.model.save({ state: ev.target.value }, { patch: true });
        }
    },
    onRender: function() {
        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });

        this.$el.find(".checklist-state[value='" + this.model.get('state') + "']").prop("checked", true);
    },

    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val();
            var attr = {};

            attr[dbField.field] = val;

            self.model.save( attr, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit();
                }
            });
        });

        edit.on('blur', hideEdit);
    }
});

// ---------------- Checklist List --------------------------------
var ChecklistList = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'checklist-list editable-list',
    itemView: ChecklistItemView,

    initialize: function() {
        this.itemViewOptions = {parent: this};
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: false,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var cid = $(ui.item).attr('cid');
                var model = self.collection.get(cid);
                var child = self.children.findByModel(model);
                var newOrder = self.$el.children().index(child.$el);
                if (model.get('order') !== newOrder) {
                    var attrs = {
                        order: newOrder
                    };

                    model.save(attrs, {
                        patch: true,
                        wait: true,
                        success: function() {
                            self.trigger('change:order');
                        }
                    });
                }
            }
        });
    }
} );

// ---------------- New Checklist ---------------------------------
var NewChecklistView = Marionette.Layout.extend( {
    className: 'edit-checklist edit-form-modal',
    template: Handlebars.compile( addChecklistTemplate ),
    ui: {
        name: '#checklist-name',
        auto_add_tasks: '#checklists-auto-add-tasks',
        for_automation: '#checklists-for-automation',
        hidden: '#checklists-hidden',
        title: '.title',
        error_messages: '.error-message',
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
            this.valuesChanged = true;
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .checkbox-switch-control': function(ev) {
            this.valuesChanged = true;
        },
        'click .save': function(ev) {
            ev.preventDefault();
            this.save();
        },
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('cancel');
        }
    },

    save: function() {
        var name = this.ui.name.val();
        var auto_add_tasks = this.ui.auto_add_tasks.is(":checked");
        var for_automation = this.ui.for_automation.is(":checked");
        var hidden = this.ui.hidden.is(":checked");

        // ...
        var settings = this.checklistToEdit?.get('checklist_settings') || {};
        settings.hidden = hidden;

        var attrs = {
            name: name,
            creator: app.user.attributes,
            auto_add_tasks,
            for_automation,
            state: this.editing ? this.checklistToEdit.attributes.state : 'draft',
            entity_type: this.fieldsView,
            checklist_settings: settings
        };

        // ...
        if (!this.dataIsValid(name)) {
            return;
        }

        // ...
        if (!this.haveChanges()) {
            this.trigger('cancel');
            return;
        }

        // ...
        if (this.editing) {
            this.checklistToEdit.save(attrs, {
                patch: true
            });
            this.trigger('update:checklist');
        }
        else {
            var field = new ChecklistModel( attrs );
            field.save();
            this.trigger('new:checklist', field);
        }
    },

    haveChanges: function(){
        if (this.editing) {
            return this.valuesChanged;
        }

        return true;
    },

    dataIsValid: function(name)
    {
        this.error_messages.remove.call(this);

        if (!name) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text('A checklist name is required')
                .addClass('invalid');
        }

        // ...
        var somethingIsWrong = (!name);

        // the field doesnt exists
        for ( var i = 0; i < this.existingFields.length; ++i ) {
            if ((this.existingFields.models[ i ] !== this.checklistToEdit) &&
                (this.existingFields.models[ i ].get( 'name' ) === name )) {
                this.ui.name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text('This checklist name is already in use')
                    .addClass('invalid');
                somethingIsWrong = true;
            }
        }

        return !somethingIsWrong;
    },

    initialize: function( options ) {
        this.editing = !options.isNew;
        this.existingFields = options.existingFields;
        this.fieldsView = options.fieldsView;
        this.valuesChanged = false;

        if (this.editing) {
            this.checklistToEdit = options.checklistToEdit;
        }
    },

    onShow: function() {
        if (this.editing) {
            const settings = this.checklistToEdit.get('checklist_settings') || {};

            this.ui.title.text('Edit Checklist');
            this.ui.name.focus();
            this.ui.name.val(this.checklistToEdit.get('name'));
            this.ui.auto_add_tasks.attr('checked', this.checklistToEdit.get('auto_add_tasks'));
            this.ui.for_automation.attr('checked', this.checklistToEdit.get('for_automation'));
            this.ui.hidden.attr('checked', !!settings.hidden);
        }
        else {
            this.ui.title.text('Create New Checklist');
        }
    },

    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
} );

// ---------------- Task Item -------------------------------------
var TaskItemView = Marionette.Layout.extend( {
    tagName: 'li',
    template: Handlebars.compile( taskListItemViewTemplate ),
    ui: {
        text: '.name',
        authorizers: '.authorizers',
        tooltips: '[data-toggle=tooltip]'
    },
    attributes: function() {
        return {
            cid: this.model.cid
        };
    },
    events: {
        'click .delete-item': function() {
            var view = this,
                mbContent = {
                    message: Handlebars.compile(
                            'Deleting a task will permanently remove it from your data' +
                            '<strong class="cta">Do you want to continue?</strong>'
                        ),
                    icon: 'icon-warning',
                    accept_is_negative: true
                };

            this.ui.tooltips.tooltip('hide');

            MessageBox.showYesNo( mbContent, this, function() {
                view.$el.fadeOut(400, function() {
                    view.ui.tooltips.tooltip('destroy');
                    view.model.urlRoot = '/checklists/' + view.model.get('checklist_id') + '/items';
                    view.model.destroy();
                });
            });
        },
        'click .edit-item': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('edit-task', this.model);
        }
    },
    onRender: function() {
        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });
    },
    templateHelpers: function() {
        return { 
            authorizers: this.model.attributes.authorizers.map(item => {
                var name = item.title || item.name
                return "<span class='authorizer'>" + name + "</span>"
            }).join(' ')
        };
    },

    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val();
            var attr = {};

            attr[dbField.field] = val;

            self.model.save( attr, {
                patch: true,
                success: function() {
                    text.text(val);
                    hideEdit();
                }
            });
        });

        edit.on('blur', hideEdit);
    }
});

// ---------------- Task List -------------------------------------
var TaskList = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'task-list editable-list',
    itemView: TaskItemView,

    initialize: function() {
        this.itemViewOptions = {parent: this};
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: false,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var cid = $(ui.item).attr('cid');
                var model = self.collection.get(cid);
                var child = self.children.findByModel(model);
                var newOrder = self.$el.children().index(child.$el);
                if (model.get('order') !== newOrder) {
                    var attrs = {
                        order: newOrder
                    };
                    model.urlRoot = '/checklists/' + model.get('checklist_id') + '/items';
                    model.save(attrs, {
                        patch: true,
                        wait: true,
                        success: function() {
                            self.trigger('change:order');
                        }
                    });
                }
            }
        });
    }
} );

// ---------------- New Task --------------------------------------
var NewTaskView = Marionette.Layout.extend( {
    className: 'edit-checklist edit-form-modal',
    template: Handlebars.compile( addChecklistTaskTemplate ),
    ui: {
        title: '.title',
        text: '#task-text',
        dueDate: '#dueDate',
        specificDueDate: '#specific',
        relativeDueDate: '#relative',
        assignee: '#assignee',
        tagsInput: '#tags',
        authorizerInput: '#authorizers',
        uploadFile: '#task-upload-file',
        error_messages: '.error-message',
        setDueDateSpecific: '.set-due-date',
        dueDateRelativeOption: '#dueDateRelativeOption',
        dueDateRelativeValue: '#dueDateRelativeValue'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
            this.valuesChanged = true;
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .checkbox-switch-control': function(ev) {
            this.valuesChanged = true;
        },
        'click .save': function(ev) {
            ev.preventDefault();
            this.save();
        },
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('cancel');
        },
        'change #dueDate': function(ev) {
            this.valuesChanged = true;
            if(this.dueDate === 'datetime'){
                this.ui.relativeDueDate.hide()
                this.ui.specificDueDate.show()
            }else{
                this.ui.relativeDueDate.show()
                this.ui.specificDueDate.hide()
            }
        },
        'click .set-due-date': function() {
            var self = this;
            var dateTimePicker = new DateTimePicker({
                altField: this.ui.specificDueDate,
                css: {
                    left: self.ui.setDueDateSpecific.offset().left,
                    top: self.ui.setDueDateSpecific.offset().top + self.ui.setDueDateSpecific.height() + 10
                }
            });
            dateTimePicker.showPicker();
        },
        'change #specific': function() {
            this.manageDueDateVisibility(this.ui.specificDueDate.val());
            this.specificDueDate = this.ui.specificDueDate.val();
            this.valuesChanged = true;
        },
        'change #dueDateRelativeValue': function() {
            this.valuesChanged = true;
        },
        'change #dueDateRelativeOption': function() {
            this.valuesChanged = true;
        },
        'change #assignee': function(ev) {
            this.valuesChanged = true;
        },
        'change #tags': function() {
            this.valuesChanged = true;
        },
        'change #authorizers': function() {
            this.valuesChanged = true;
        }
    },

    manageDueDateVisibility: function(dueDate) {
        var hasValidDate = !!dueDate;

        if (hasValidDate && this.ui.dueDate && this.ui.dueDate.val() === 'datetime') {
            this.ui.setDueDateSpecific.val(dateFormat.shortFormatWithYearTime(dueDate));
        }
    },

    save: function() {
        var text = this.ui.text.val();
        var assignee = (this.assignee && this.assignee.id !== 0) ? this.assignee : undefined

        // ...
        var attrs = {
            text: text,
            assignee: assignee && {id: assignee.id},
            authorizers: this.authorizerSelect.getData(),
            tags: this.tagSelect.getData(),
            allow_file_upload: this.ui.uploadFile.is(":checked"),
            is_owner_assignee: this.assignee && this.assignee.id === 0,
            due_date: this.mapDueDate(this.ui.dueDate.val()),
        };

        // ...
        if (!this.dataIsValid(text)) {
            return;
        }

        // ...
        if (!this.haveChanges()) {
            this.trigger('cancel');
            return;
        }

        // ...
        if (this.editing) {
            this.taskToEdit.urlRoot = '/checklists/' + self.options.checklist.id + '/items';
            this.taskToEdit.save(attrs, {
                patch: true
            });
            this.trigger('update:task');
        }
        else {
            var checklistTaskModel = new (ChecklistTaskModel.extend({
                urlRoot: function() {
                    return '/checklists/' + self.options.checklist.id + '/items';
                },
                
            }));

            checklistTaskModel.save(attrs)
            this.trigger('new:task', checklistTaskModel);
        }
    },
    mapDueDate: function(date){
        var dueDate;
        if(date === 'datetime'){
            dueDate = {
                type: date,
                value: dateFormat.parseDate(this.ui.setDueDateSpecific.val()),
                timezone: "UTC"
            }
        } else if (date === 'relative_datetime'){
            dueDate = {
                type: date,
                timezone: "UTC",
                option: this.dueDateRelativeOption
            }
            dueDate[dueDate.option] = parseInt(this.ui.dueDateRelativeValue.val())
        } else
            return undefined
        
        return dueDate;
    },

    haveChanges: function(){
        if (this.editing) {
            return this.valuesChanged;
        }

        return true;
    },

    dataIsValid: function(name)
    {
        this.error_messages.remove.call(this);

        if (!name) {
            this.ui.text
                .addClass('validation_error')
                .nextAll('.error-message')
                .text('A task text is required')
                .addClass('invalid');
        }

        // ...
        var somethingIsWrong = (!name);

        return !somethingIsWrong;
    },

    initialize: function( options ) {
        this.editing = !options.isNew;
        this.existingFields = options.existingFields;
        this.fieldsView = options.fieldsView;
        this.valuesChanged = false;
    
        if (this.editing) {
            var dueDate = options.taskToEdit.get('due_date')
            this.taskToEdit = options.taskToEdit;
            this.assignee = options.taskToEdit.get('assignee')
            this.tags = options.taskToEdit.get('tags')
            this.authorizers = options.taskToEdit.get('authorizers')
            if(options.taskToEdit.get('is_owner_assignee') && !this.assignee){
                this.assignee = { id: 0, name: 'Owner of the trigger record' };
            }
            if(dueDate){
                this.dueDate = dueDate.type
                if(this.dueDate === 'datetime'){
                    this.specificDueDate = dateFormat.shortFormatWithYearTime(dueDate.value)
                }
                else{
                    this.dueDateRelativeOption = dueDate.option
                    this.dueDateRelativeValue = dueDate[dueDate.option]
                }
            }
            
            
        }
    },
    onRender: function() {
        self = this;

        // Due Date Select

        var dueUnits = [
            {
                id: 'relative_datetime',
                text: 'Relative Date'
            },
            {
                id: 'datetime',
                text: 'Specific Date'
            }
        ];

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.dueDate,
            text: 'text',
            data: dueUnits,
            value: this.dueDate,
            options: {
                placeholder: 'Choose a Due Date',
                dropdownCssClass: 'popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        this.ui.dueDate.on('change', function() {
            self.dueDate = self.ui.dueDate.select2('val');
        });

        // Relative Date Options Select
        var relativeDates = [
            { id: "years", title: "Years after trigger" },
            { id: "months", title: "Months after trigger" },
            { id: "days", title: "Days after trigger" },
            // { id: "hours", title: "Hours after trigger" },
            // { id: "minutes", title: "Minutes after trigger" },
            // { id: "seconds", title: "Seconds after trigger" },
            { id: "monday", title: "Monday" },
            { id: "tuesday", title: "Tuesday" },
            { id: "wednesday", title: "Wednesday" },
            { id: "thursday", title: "Thursday" },
            { id: "friday", title: "Friday" },
            { id: "saturday", title: "Saturday" },
            { id: "sunday", title: "Sunday" }
        ];

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.dueDateRelativeOption,
            text: 'title',
            data: relativeDates,
            value: this.dueDateRelativeOption ? this.dueDateRelativeOption : "",
            options: {
                placeholder: 'Select an option',
                dropdownCssClass: 'popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        this.ui.dueDateRelativeOption.on('change', function() {
            self.dueDateRelativeOption = self.ui.dueDateRelativeOption.select2('data').id;
        });

        // Assignee select
        this.calculatedAssignee = { id: 0, name: 'Owner of the trigger record' };

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.assignee,
            url: '/users',
            text: 'name',
            value: this.assignee,
            staticModels: [
                new Backbone.Model(this.calculatedAssignee),
                new Backbone.Model({ line: true })
            ],
            options: {
                placeholder: 'Choose Assignee',
                dropdownCssClass: 'popover',
                formatResult: formatResult
            }
        });

        var formatResult = function(item) {
            return item.line ? '': item.name;
        };

        this.ui.assignee.on('change', function() {
            self.assignee = self.ui.assignee.select2('data');
        });

        // Authorizers Select
        _.defer(function() {
            self.initialHeight = self.$el.height();

            self.authorizerSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.authorizerInput,
                id: 'id',
                text: 'name',
                url: '/teams',
                search: true,
                options: {
                    placeholder: 'Add Authorizers',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a Team';
                    },
                    formatResult: function(item) {
                        return item.name || item.title;
                    },
                    formatSelection: function(item) {
                        var name = item.name || item.title
                        return "<div class='group-option' group-id='" + item.id + "'>" + name + "</div>";
                    },
                    tokenSeparators: [',']
                }
            });

            self.authorizerSelect.setValue(self.authorizers);

            if(!self.authorizers){
                var teams = new TeamsCollection();
                teams.fetch({
                    data: {
                        rows: -1,
                        search: ''
                    },
                    success: function(data) {
                        var everyoneTeam = data.models.find(m => m.get('short_id') === 'everyone');
                        if(everyoneTeam){
                            self.authorizerSelect.setValue([everyoneTeam.attributes]); 
                        }
                            
                    }
                });
    
            }
            
        });

        // Tag Select
        _.defer(function() {
            self.initialHeight = self.$el.height();

            self.tagSelect = new backboneSelect2.TagView({
                view: self,
                $el: self.ui.tagsInput,
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,
                options: {
                    placeholder: 'Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    minimumResultsForSearch: 1,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            self.tagSelect.setValue(self.tags);
        });

        if(this.dueDate){
            this.dueDate === 'datetime' ? 
                this.ui.relativeDueDate.hide() : this.ui.specificDueDate.hide()
        }else{
            this.ui.relativeDueDate.hide();
            this.ui.specificDueDate.hide();
        }
        
    },

    onShow: function() {
        if (this.editing) {

            this.ui.title.text('Edit Task');
            this.ui.text.focus();

            this.ui.text.val(this.taskToEdit.get('text'));
            this.ui.uploadFile.attr('checked', this.taskToEdit.get('allow_file_upload'));
            this.ui.setDueDateSpecific.val(this.specificDueDate);
            this.ui.dueDateRelativeValue.val(this.dueDateRelativeValue);
        }
        else {
            this.ui.title.text('New Task');
        }
    },

    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
} );

// ---------------- Task Main View --------------------------------
var ChecklistTasks = Marionette.Layout.extend( {
    className: 'checklists-task-settings',
    template: Handlebars.compile( checklistTaskTemplate ),
    ui: {
        contentContainer: '.content-container'
    },
    events: {
        'click .new-task': function() {
            this.showCreateOrUpdateChecklistTasksView();
        },
        'click .header-back-button': function(ev) {
            ev.preventDefault();
            this.trigger('cancel');
        }
    },
    regions: {
        addNewTask: {
            selector: '.new-task',
            regionType: ModalRegion
        },
        taskList: '.checklist-task-list-container',
    },

    initialize: function( options ) {
        var self = this;

        this.fields = new ChecklistsTaskCollection();
        this.fields.on("change:order", function() {
            self.fetchFields();
        });

        this.fieldsView = options.fieldsView;
        this.fieldsTitle = options.checklist.attributes.name;
    },

    onRender: function() {
        this.fetchFields();
    },

    fetchFields: function() {
        var self = this;

        var checklistItemsCollection = new (ChecklistsTaskCollection.extend({
            urlRoot: function() {
                return '/checklists/' + self.options.checklist.id + '/items';
            },
            defaultSortOn: [{
                attribute: 'checklist_items.order',
                order: 'asc'
            }]
        }));

        checklistItemsCollection.fetch({
            success: function() {
                var list = new TaskList( { collection: checklistItemsCollection } );

                self.listenTo(list, 'edit-task', function(check) {
                    this.showCreateOrUpdateChecklistTasksView(check);
                });

                self.taskList.show(list);
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });
    },

    templateHelpers: function() {
        return { title: this.fieldsTitle };
    },
    showCreateOrUpdateChecklistTasksView: function(check) {
        var newTaskView;

        if (check) { // we want to edit a checklist
            newTaskView = new NewTaskView({
                isNew: false,
                taskToEdit: check,
                checklist: this.options.checklist,
                existingFields: this.fields,
                fieldsView: this.fieldsView
            });
        }
        else { // new checklist
            newTaskView = new NewTaskView({
                isNew: true,
                checklist: this.options.checklist,
                existingFields: this.fields,
                fieldsView: this.fieldsView
            });
        }

        this.addNewTask.show( newTaskView );

        this.listenTo( newTaskView, 'new:task', function( field ) {
            this.fields.add( field );
            this.addNewTask.reset();
            this.$el.find('.content-container').nanoScroller();
        });

        this.listenTo(newTaskView, 'update:task', function() {
            this.addNewTask.reset();
            this.taskList.currentView.render();
        });

        this.listenTo( newTaskView, 'cancel', function() {
            this.addNewTask.reset();
        } );
    },

    scrollEvents: function() {
        var header = this.$el.find( '.detail-header' );
        var view = this;

        $( this.ui.contentContainer ).find( '.content' ).scroll( function() {
            if( view.ui.contentContainer.find( '.checklist-list-container' ).position().top < -1 ) {
                if( !header.hasClass( 'header-shadow' ) ) {
                    header.addClass('header-shadow');
                }
            }
            else{
                header.removeClass( 'header-shadow' );
            }
        } );
    }
} );

// ---------------- Checklist Main View ---------------------------
export default Marionette.Layout.extend( {
    className: 'checklists-settings',
    template: Handlebars.compile( checklistsTemplate ),
    ui: {
        contentContainer: '.content-container'
    },
    regions: {
        checklistList: '.checklist-list-container',
        addNewChecklist: {
            selector: '.new-checklist',
            regionType: ModalRegion
        },
        checklistTasks: '.checklists',
    },
    events: {
        'click .new-checklist': function() {
            this.showChecklistView();
        }
    },

    initialize: function( options ) {
        var self = this;

        this.fields = new ChecklistsCollection();
        this.fields.on("change:order", function() {
            self.fetchFields();
        });

        this.fieldsView = options.fieldsView;
        this.fieldsTitle = options.fieldsTitle;
    },

    onRender: function() {
        this.fetchFields();
    },

    fetchFields: function() {
        var self = this;

        this.fields.fetch( {
            extraArgs: [ {
                attribute: 'entity_types',
                value: this.fieldsView
            } ],
            sortOn: [{ attribute: 'checklists.order', order: 'asc'}],
            success: function() {
                var list = new ChecklistList( { collection: self.fields } );

                self.listenTo(list, 'edit-checklist', function(check) {
                    this.showChecklistView(check);
                });

                self.listenTo(list, 'checklist-task-list', function(check) {
                    this.showChecklistTaskView(check);
                });

                self.checklistList.show(list);
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        });
    },

    templateHelpers: function() {
        return { title: this.fieldsTitle };
    },

    showChecklistView: function(check) {
        var newChecklistView;

        if (check) { // we want to edit a checklist
            newChecklistView = new NewChecklistView({
                isNew: false,
                checklistToEdit: check,
                existingFields: this.fields,
                fieldsView: this.fieldsView
            });
        }
        else { // new checklist
            newChecklistView = new NewChecklistView({
                isNew: true,
                existingFields: this.fields,
                fieldsView: this.fieldsView
            });
        }

        this.addNewChecklist.show( newChecklistView );

        this.listenTo( newChecklistView, 'new:checklist', function( field ) {
            this.fields.add( field );
            this.addNewChecklist.reset();
            this.$el.find('.content-container').nanoScroller();
        });

        this.listenTo(newChecklistView, 'update:checklist', function() {
            this.addNewChecklist.reset();
            this.checklistList.currentView.render();
        });

        this.listenTo( newChecklistView, 'cancel', function() {
            this.addNewChecklist.reset();
        } );
    },
    showChecklistTaskView: function(check) {

        if (check) { // we want to edit a checklist
            var checkListTaskView = new ChecklistTasks({
                checklist: check,
                existingFields: this.fields,
                fieldsView: this.fieldsView
            });

            this.checklistTasks.show(checkListTaskView)

            this.listenTo(checkListTaskView, 'cancel', function() {
                this.checklistTasks.reset();
                this.render()
            } );
        }

    },

    scrollEvents: function() {
        var header = this.$el.find( '.detail-header' );
        var view = this;

        $( this.ui.contentContainer ).find( '.content' ).scroll( function() {
            if( view.ui.contentContainer.find( '.checklist-list-container' ).position().top < -1 ) {
                if( !header.hasClass( 'header-shadow' ) ) {
                    header.addClass('header-shadow');
                }
            }
            else{
                header.removeClass( 'header-shadow' );
            }
        } );
    }
} );

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import widgetTemplate from 'app/dashboard/widgets/pipeline/widget.handlebars'
import itemTemplate from 'app/dashboard/widgets/pipeline/item.handlebars'
import funnelTemplate from 'app/dashboard/widgets/pipeline/funnel.handlebars'


var PipelineView, ItemView, ItemsListView, DealBubbleItemView, DealsBubbleListView, FunnelItemView, FunnelsListView;
var maxNumberOfFunnelsVisible = 5;

FunnelItemView = Marionette.ItemView.extend({
    template: Handlebars.compile(funnelTemplate),
    events: {
        'click': function() {
            this.trigger('funnel:selected');
        }
    },
    ui: {
        funnel: '.funnel'
    },
    templateHelpers: function() {
        return {
            name: this.model.get('name')
        };
    },
    onRender: function() {
        if (this.options.numFunnels < 2) {
            this.ui.funnel.addClass('hide');
        }
        else if (this.model.get('id') === this.options.activeFunnelId) {
            this.ui.funnel.addClass('disabled');
        }
    }
});

FunnelsListView = Marionette.CollectionView.extend({
    itemView: FunnelItemView,
    itemViewOptions: function() {
        return {
            activeFunnelId: this.options.parent.activeFunnel.get('id'),
            numFunnels: this.collection.length
        };
    },
    initialize: function() {
        this.firstVisibleFunnelIdx = 0;
        this.fullCollection = this.collection;
        this.filterCollectionByVisibility();
    },
    filterCollectionByVisibility: function() {
        this.collection = new Backbone.Collection(this.fullCollection.slice(this.firstVisibleFunnelIdx, this.firstVisibleFunnelIdx + maxNumberOfFunnelsVisible));
    },
    hasItemsTop: function() {
        return this.firstVisibleFunnelIdx > 0;
    },
    hasItemsBottom: function() {
        return this.firstVisibleFunnelIdx < (this.fullCollection.length - maxNumberOfFunnelsVisible);
    },
    scroll: function(numMovs) {
        this.firstVisibleFunnelIdx = this.firstVisibleFunnelIdx + numMovs;
        this.filterCollectionByVisibility();
        this.render();
    }
});

DealBubbleItemView = Marionette.ItemView.extend({
    template: Handlebars.compile('{{abbreviation}}')
});

DealsBubbleListView = Marionette.CollectionView.extend({
    itemView: DealBubbleItemView
});

ItemView = Marionette.Layout.extend({
    tagName: 'tr',
    template: Handlebars.compile(itemTemplate),
    templateHelpers: function() {
        var change = this.model.get('change');
        var isPositive = change > 0;
        var isNegative = change < 0;

        return {
            isPositive: isPositive,
            isNegative: isNegative
        };
    },
    regions: {
        favoriteDealsRegion: '.favorite-deals>div'
    },
    onRender: function() {
        this.favoriteDealsRegion.show(new DealsBubbleListView({
            collection: this.model.get('deals')
        }));
    }
});

ItemsListView = Marionette.CollectionView.extend({
    itemViewContainer: 'tbody',
    itemView: ItemView
});

PipelineView = Marionette.Layout.extend({
    className: 'pipeline at-top',
    template: Handlebars.compile(widgetTemplate),
    ui: {
        scroll: '.content-container > .content',
        header: '.pipeline-header',
        funnelName: '.funnel-name',
        topArrow: '.funnels-container-top-arrow',
        bottomArrow: '.funnels-container-bottom-arrow',
        period: '.period-change'
    },
    regions: {
        itemsContainer: '.items-container',
        funnelsContainerRegion: '.funnels-container'
    },
    events: {
        'click .header .expand': function() {
            // Prevent double expanding - breaks chain of animation
            if (this.expanding) {
                return;
            }

            this.expanding = true;

            // // Once all parent containers have animated
            function afterParentExpand() {
                this.expanding = false;
                this.expanded = !this.expanded;
                this.scrollbar();
            }

            this.trigger('expand', this, afterParentExpand.bind(this));
        },
        'click .funnels-container-top-arrow': function(ev) {
            if (!this.ui.topArrow.hasClass('disabled')) {
                this.scrollFunnelsList(-1);
            }
        },
        'click .funnels-container-bottom-arrow': function() {
            if (!this.ui.bottomArrow.hasClass('disabled')) {
                this.scrollFunnelsList(1);
            }
        }
    },
    initialize: function(options) {
        this.funnels = options.funnels;
        this.activeFunnel = this.funnels.models[0];
        this.period = options.period;

        var leadsModel = options.collection.models[0];
        var initialFunnel = app.user.get('preferences').last_visited_funnel_id;

        if (initialFunnel) {
            this.activeFunnel = _.find(this.funnels.models, function(m) { return m.get('id') === initialFunnel; }) || this.activeFunnel;
        }

        // ... create the structure funnel=>phases collection
        var f = {};

        for (var i = 0; i < options.collection.length; ++i) {
            var m = options.collection.models[i];
            var id = m.get('funnel_id');

            if (f[id]) {
                f[id].push(m);
            }
            else {
                f[id] = [leadsModel, m];
            }
        }

        _.each(_.keys(f), function(k) {
            for(var i = 0; i < options.funnels.length; ++i) {
                var d = options.funnels.models[i];

                if (d.get('id') !== k) {
                    continue;
                }

                d.set('collection', new Backbone.Collection(f[k]));
                break;
            }
        });
    },
    onRender: function() {
        this.scrollbar();
        this.ui.scroll.scroll(this.scrollEvents.bind(this));
        this.ui.period.text(this.period || 'Weekly Change');

        if (this.funnels.length > maxNumberOfFunnelsVisible) {
            this.ui.topArrow.removeClass('invisible');
            this.ui.bottomArrow.removeClass('invisible');
        }

        this.resizeCallback = function() {
            this.scrollbar();
        }.bind(this);

        $(window).on('resize', this.resizeCallback);
    },
    onShow: function() {
        this.showData();

        this.funnelsView = new FunnelsListView({collection: this.funnels, parent: this});
        var self = this;

        this.funnelsView.on('itemview:funnel:selected', function(child) {
            self.activeFunnel = child.model;
            self.showData();
            self.funnelsView.render();

            app.user.updatePreference('last_visited_funnel_id', self.activeFunnel.get('id'));
        });

        this.funnelsContainerRegion.show(this.funnelsView);
        this.scrollFunnelsList(0);
    },
    scrollFunnelsList: function(numMovs) {
        this.funnelsView.scroll(numMovs);

        if (this.funnels.length > maxNumberOfFunnelsVisible) {
            this.ui.topArrow.toggleClass('disabled', !this.funnelsView.hasItemsTop());
            this.ui.bottomArrow.toggleClass('disabled', !this.funnelsView.hasItemsBottom());
        }
    },
    showData: function() {
        this.itemsContainer.show(new ItemsListView({collection: this.activeFunnel.get('collection')}));
        this.ui.funnelName.text(this.activeFunnel.get('name'));
    },
    onBeforeClose: function() {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            contentHeight = Math.ceil( container.find('.content-inner:first').outerHeight(true) );

        function update() {
            if (!this.isClosed) {
                var availableHeight = Math.round( (this.$el.height() - this.ui.header.height()) );

                this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

                container.nanoScroller();
                this.scrollEvents();

                this.trigger('animation-finished');
            }
        }

        container.stop().animate({
            height: contentHeight
        }, 300, update.bind(this));

    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

export default PipelineView;

import $ from 'jquery'
import _ from 'underscore'
import app from 'js/app'


export default {
    deals: {
        organization_individuals: {
            custom_fetch: function(deal, callback) {
                const customFields = app.globalData.customFieldsInfo.deals;
                let reservationCfId = null;

                for (const cfId in customFields) {
                    if (customFields[cfId].name.toLowerCase() === 'reservation') {
                        reservationCfId = cfId;
                        break;
                    }
                }

                if (!reservationCfId) {
                    callback([]);
                    return;
                }

                const cfLabel = `custom_field.${reservationCfId}`;

                if (!deal.get(cfLabel)) {
                    callback([]);
                    return;
                }

                $.get(`/opportunities/${deal.get(cfLabel)}`, function(data) {
                    let individualCustomFieldIds = [];

                    for (const cfId in customFields) {
                        if (customFields[cfId].type === 'individual') {
                            individualCustomFieldIds.push(cfId);
                        }
                    }

                    if (individualCustomFieldIds.length === 0) {
                        callback([]);
                        return;
                    }

                    let individualsIdToFetch = [];

                    for (const cfId of individualCustomFieldIds) {
                        const cfl = `custom_field.${cfId}`;

                        if (!data[cfl]) {
                            continue;
                        }

                        individualsIdToFetch.push(data[cfl]);
                    }

                    individualsIdToFetch = _.uniq(individualsIdToFetch);

                    if (individualsIdToFetch.length === 0) {
                        callback([]);
                        return;
                    }

                    let count = individualsIdToFetch.length;
                    let results = [];

                    const checkReady = () => {
                        --count;

                        if (count === 0) {
                            callback(results);
                        }
                    }

                    for (const iid of individualsIdToFetch) {
                        $.ajax({
                            type: 'GET',
                            url: `/individuals/${iid}`,
                            success: function(individual) {
                                results.push(individual);
                                checkReady();
                            },
                            error: function() {
                                checkReady();
                            }
                        });
                    }
                });
            }
        }
    }
}
import _ from 'underscore';
import React from 'react';
import ReactDOM from 'react-dom';

import dateFormat from 'js/utils/date-format';
import Table from 'app_v2/components/table/table';
import PopoverMenu from 'app_v2/components/popover_menu/menu';
import FiltersBar from 'app_v2/sections/base/components/filters_bar';
import GroupPageFetcher from 'app_v2/sections/base/group_page_fetcher';
import { getAllColumns as getIndividualsAllColumns } from 'app_v2/sections/individuals/table_columns';
import getIndividualsFilters from 'app_v2/sections/individuals/filters';
import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import AdvancedFilterPanel from 'app_v2/sections/panels/advanced_filter';
import ConfigureColumnsPanel from 'app_v2/sections/panels/configure_columns';
import FilterManager from 'app_v2/sections/base/filter_manager';
import AddEntity from 'app_v2/sections/base/components/add_entity';
import MessageBox from 'js/views/message_box';
import AppConfig from 'app/app-config';
import app from 'js/app';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './campaign_creation.css';


class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            removeButtonVisible: false,
            rowSelection: null,
            addPopoverDialog: {
                visible: false
            }
        };
    }

    handleAddClick() {
        const bb = this.addButtonComponent.getBoundingClientRect();

        this.setState({
            addPopoverDialog: {
                visible: true,
                top: bb.bottom + 5,
                left: bb.right - 350
            }
        });
    }

    handleEntityAdded(entity) {
        this.setState({
            addPopoverDialog: {
                visible: false
            }
        });

        if (entity) {
            this.props.addEntity(entity);
        }
    }

    handleRemoveClick() {
        const rows = this.state.rowSelection.rows;
        const itemsMessage = `${rows.length} item${rows.length > 1 ? 's' : ''}`;
        const content = {
            message: `Are you sure you want to remove ${itemsMessage} from ${this.props.title}?`,
            icon: 'icon-trashcan',
            itemsLength: rows.length,
            accept_is_negative: true
        };

        const self = this;

        MessageBox.showYesNo(
            content,
            this.props.parent,
            function() { // Yes
                self.props.removeEntities(self.state.rowSelection.rows.map(r => r.id));
            }
        );
    }

    onRowSelectionChange(rowSelection) {
        this.setState({
            removeButtonVisible: rowSelection.rows.length > 0,
            rowSelection: rowSelection.rows.length > 0 ? rowSelection : null
        });
    }

    render() {
        const preview = this.props.view === 'preview';
        const editing = !preview;
        const smartEditing = this.props.view === 'smartEdit';
        const staticEditing = this.props.view === 'staticEdit';

        return (
            <div className={style.rToolbar}>
                {editing &&
                    <div
                        className={`
                            ${style.rActionButton}
                            icon-cross
                        `}
                        style={{marginRight: '10px'}}
                        onClick={this.props.onClose}
                    />
                }

                <div className={style.tTitle}>
                    {this.props.title}
                </div>

                <div className={style.tRightArea}>
                    {this.state.removeButtonVisible &&
                        <div
                            className={style.rButton}
                            style={{marginRight: '10px'}}
                            onClick={this.handleRemoveClick.bind(this)}
                        >
                            Remove From Group
                        </div>
                    }

                    {this.props.numUndeliverableContacts > 0 &&
                        <div className={style.tUndeliverable}>
                            {`${this.props.numUndeliverableContacts} Undeliverable Contact${this.props.numUndeliverableContacts !== 1 ? 's' : ''}`}
                        </div>
                    }

                    {editing &&
                        <div
                            className={`
                                ${style.rActionButton}
                                icon-table-columns
                            `}
                            style={{marginLeft: '10px'}}
                            onClick={() => this.props.onShowPanel('configureColumns')}
                        />
                    }

                    {preview &&
                        <div
                            className={style.rButton}
                            style={{marginLeft: '10px'}}
                            onClick={this.props.onEdit}
                        >
                            Edit
                        </div>
                    }

                    {editing &&
                        <div
                            style={{
                                marginLeft: '20px',
                                marginRight: '20px',
                                width: '1px',
                                background: '#cccccc'
                            }}
                        />
                    }

                    {smartEditing &&
                        <div
                            className={style.rButton}
                            onClick={() => this.props.onShowPanel('advancedFilter')}
                        >
                            <div>Advanced Filter</div>
                            <div
                                className='icon-filter'
                                style={{
                                    marginLeft: '20px'
                                }}
                            />
                        </div>
                    }

                    {staticEditing &&
                        <div
                            ref={(el) => this.addButtonComponent = el}
                            className={style.rButton}
                            onClick={this.handleAddClick.bind(this)}
                        >
                            Add
                        </div>
                    }

                    {this.state.addPopoverDialog.visible &&
                        <AddEntity
                            top={this.state.addPopoverDialog.top}
                            left={this.state.addPopoverDialog.left}
                            entityType='individuals'
                            onClose={this.handleEntityAdded.bind(this)}
                        />
                    }
                </div>
            </div>
        );
    }
}


class GroupSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            items: [],
            activeItemId: props.group?.id || null,
            popoverMenu: {
                visible: false
            }
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.getNumContactsCounter = 0;

        const self = this;

        let args = {
            rows: -1,
            order_by: 'modified desc',
            include_on_fly_groups: false
        };

        if (this.props.funnelId) {
            const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === this.props.funnelId);

            if (funnel && AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')) {
                args.funnel_ids = funnel.id
            } else if (funnel && AppConfig.getValue('individuals.groups.show_funnels_tags_adhoc_groups')) {
                const tag = app.globalData.tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                if (tag) {
                    args.tag_ids = tag.id;
                }
            }
        }

        $.get(`/mailing_lists?${$.param(args)}`, function(data) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                items: _.sortBy(data, d => d.name.toLowerCase())
            });

            self.createDynamicGroups();
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createNewGroups(property, validProperties, sortFunction) {
        let newGroups = [];

        if (validProperties.length > 0) {
            for (const vp of validProperties) {
                newGroups.push({
                    id: vp.id,
                    name: vp.name,
                    group_type: 'static',
                    dynamic: true,
                    [property]: vp.id
                });
            }

            const allGroups = [...newGroups, ...this.state.items];

            this.setState({
                items: _.sortBy(allGroups, sortFunction),
                loading: false
            });
        }
    }

    createDynamicGroups() {
        const self = this;

        if (AppConfig.getValue('individuals.groups.show_funnels_tags_adhoc_groups')) {
            const funnels = app.globalData.funnelsInfo.funnels;
            const tags = app.globalData.tags;
            let validTags = [];

            for (const funnel of funnels) {
                if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                    continue;
                }

                if (this.props.funnelId && this.props.funnelId !== funnel.id) {
                    continue;
                }

                const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                if (tag) {
                    validTags.push(tag);
                }
            }
            if (validTags.length > 0) {
                this.createNewGroups('tag_ids', validTags, (d) => d.name.toLowerCase())
            } else {
                this.setState({
                    loading: false
                });
            }

        } else if(AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')) {
            const funnels = app.globalData.funnelsInfo.funnels;
            let validFunnels = [];
            for (const funnel of funnels) {
                if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                    continue;
                }
                validFunnels.push(funnel);
            }
            
            if (validFunnels.length > 0) {
                this.createNewGroups('funnel_ids', validFunnels, (d) => d.funnel_ids !== this.props.funnelId)
            } else {
                this.setState({
                    loading: false
                });
            }
        } else {
            this.setState({
                loading: false
            });
        }
    }

    selectItem(item) {
        this.setState({
            activeItemId: item.id
        });

        this.props.onGroupChange(item);
    }

    handleNewAudienceClick() {
        const bbox = this.newAudienceButton.getBoundingClientRect();
        let items = [{
            id: 'static',
            title: 'Static Audience'
        }, {
            id: 'smart',
            title: 'Smart Audience'
        }];

        this.setState({
            popoverMenu: {
                visible: true,
                top: bbox.bottom - 4,
                left: bbox.left + (bbox.width / 2),
                items: items
            }
        });
    }

    handlePopoverMenuClose(itemId) {
        this.setState({
            popoverMenu: {
                visible: false
            }
        });

        if (!itemId) {
            return;
        }

        this.props.onNewGroup(itemId);
    }

    render() {
        return (
            <div
                className={`${style.sBlock} ${style.rLeft}`}
                style={{
                    width: 'calc((100% - 40px) / 3)'
                }}
            >
                <div
                    style={{
                        fontSize: '18px',
                        marginBottom: '20px'
                    }}
                >
                    Select your audience
                </div>

                <div style={{
                    fontSize: '14px',
                    marginBottom: '20px'
                }}>
                    Choose from existing audiences or create a new one
                </div>

                <div
                    ref={(el) => this.newAudienceButton = el}
                    className={style.rButton}
                    style={{marginBottom: '20px'}}
                    onClick={this.handleNewAudienceClick.bind(this)}
                >
                    + Create New Audience
                </div>

                <div
                    style={{
                        fontSize: '18px',
                        marginBottom: '20px'
                    }}
                >
                    Or select an existing audience:
                </div>

                <div className={style.rRecipientsList}>
                    <div className={style.rlHeader}>
                        <div className={style.rhTitle}>Audience</div>
                    </div>

                    <div className={style.rlContent}>
                        {this.state.loading &&
                            <div style={{marginTop: '20px'}}>
                                <LoadingIndicator/>
                            </div>
                        }

                        {!this.state.loading && this.state.items.map(item => {
                            return (
                                <div
                                    key={`i_${item.id}`}
                                    className={`
                                        ${style.rcItem}
                                        ${this.state.activeItemId === item.id ? style.riActive : ''}
                                    `}
                                    onClick={() => this.selectItem.bind(this)(item)}
                                >
                                    <div title={item.name}>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {this.state.popoverMenu.visible &&
                    <PopoverMenu
                        top={this.state.popoverMenu.top}
                        left={this.state.popoverMenu.left}
                        items={this.state.popoverMenu.items}
                        onClose={this.handlePopoverMenuClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

class Preview extends React.Component {
    constructor(props) {
        super(props);

        const columnsCreator = new ColumnsCreator('individuals');

        this.tableColumns = [
            columnsCreator.date('created', 'Created', { filterable: false, sortable: false, sortableAnniversary: false, groupable: false }),
            columnsCreator.text('first_name', 'First Name', { sortable: false, groupable: false }),
            columnsCreator.text('last_name', 'Last Name', { sortable: false, groupable: false }),
            columnsCreator.communications('email', 'Email'),
            columnsCreator.communications('phone', 'Phone'),
            columnsCreator.leadSource('source', 'Lead Source', { filterable: false, sortable: false, groupable: false })
        ];

        this.numRowsPerPage = 50;

        this.fetcher = new GroupPageFetcher('individuals', this.numRowsPerPage, this.tableColumns);

        this.state = {
            group: null,
            numValidContacts: 0,
            numContacts: 0
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getNumValidContacts() {
        return this.state.numValidContacts;
    }

    setGroup(group) {
        this.setState({
            group: group
        });

        if (group.dynamic) {
            const fetchArgs = AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')
                ? { funnel_ids: group.funnel_ids }
                : AppConfig.getValue('individuals.groups.show_funnels_tags_adhoc_groups')
                ? { tag_ids: group.tag_ids }
                : {};
            this.fetcher.setFetchArgs(fetchArgs);
        } else {
            this.fetcher.setFetchArgs({ group_id: group.id });
        }

        const self = this;

        // get number of valid contacts
        let args = {
            rows: 0
        };

        if (group.dynamic) {
            if (AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')) {
                args.funnel_ids = group.funnel_ids;
            } else {
                args.tag_ids = group.tag_ids;
            }
            
        } else {
            args.by_group_id = group.id;
        };

        if (this.props.isMessage) {
            args.has_phone = true;
            args.unsubscribed_all_messages = false;
        } else {
            args.has_email = true;
            args.unsubscribed_all = false;
        }

        $.get(`/individuals?${$.param(args)}`, function(data, _, request) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                numValidContacts: parseInt(request.getResponseHeader('Records-Total'))
            });
        });

        _.defer(function() {
            self.fetchTablePage(0);
        });
    }

    fetchTablePage(page) {
        const self = this;

        this.table.setLoading(true);

        this.fetcher.fetch(page, function(result) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                numContacts: result.total
            });

            self.table.setLoading(false);
            self.table.setData(result.rows, page, result.start, result.total, result.numRows);
        });
    }

    openContact(contactId) {
        window.open(`/#individuals/${contactId}`, '_blank');
    }

    render() {
        const numUndeliverableContacts = Math.max(this.state.numContacts - this.state.numValidContacts, 0);

        return (
            <div
                className={`${style.sBlock} ${style.rRight}`}
                style={{
                    width: 'calc((((100% - 40px) / 3) + 10px) * 2)',
                    padding: '20px 20px 0 20px'
                }}
            >
                {this.state.group &&
                    <div
                        style={{
                            width: '100%',
                            height: '100%'
                        }}
                    >
                        <Toolbar
                            title={this.state.group.name}
                            view='preview'
                            numUndeliverableContacts={numUndeliverableContacts}
                            onEdit={() => this.props.onEditGroup(this.state.group)}
                        />

                        <div
                            style={{
                                marginTop: '30px',
                                height: 'calc(100% - 100px)'
                            }}
                        >
                            <Table
                                ref={(el) => this.table = el}
                                rows={[]}
                                columns={this.tableColumns}
                                hasPaginator={true}
                                onGotoPage={this.fetchTablePage.bind(this)}
                                onRowClick={this.openContact.bind(this)}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class Edit extends React.Component {
    constructor(props) {
        super(props);

        const defaultColumns = props.group.columns || ['created', 'first_name', 'last_name', 'email', 'phone', 'source'];

        this.allColumns = getIndividualsAllColumns();

        if (this.props.group.group_type === 'static') {
            // remove the ability to define filters per column
            for (let column of this.allColumns) {
                column.filterable = false;
            }
        }

        this.tableColumns = this.allColumns.filter(c => defaultColumns.indexOf(c.id) !== -1);
        this.numRowsPerPage = 50;
        this.filters = getIndividualsFilters();
        this.pageFetched = 0;
        this.fetcher = new GroupPageFetcher('individuals', this.numRowsPerPage, this.tableColumns);

        this.fetcher.setFetchArgs({
            group_id: props.group.id
        });

        this.filterManager = new FilterManager('individuals', this.tableColumns);

        if (this.props.group.filter) {
            this.filterManager.setRules(this.props.group.filter.rules);
        }

        this.state = {
            filtersBarFilters: null,
            numContacts: 0,
            numValidContacts: 0
        };
    }

    componentDidMount() {
        this.mounted = true;

        this.computeFiltersBarFilters();
        this.fetchTablePage(0);
        this.fetchNumValidContacts();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getNumValidContacts() {
        return this.state.numValidContacts;
    }

    updateGroup() {
        const data = {
            columns: this.table.getColumns().map(c => c.id)
        };

        if (this.props.group.group_type === 'smart') {
            data.filter_id = this.filterManager.filterId || null;
        }

        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/groups/${this.props.group.id}`,
            data: JSON.stringify(data),
            contentType: 'application/json',
            dataType: 'json',
            success: function() {
                self.fetchNumValidContacts();
            }
        });
    }

    fetchTablePage(page) {
        const self = this;
        const pageToFetch = page || this.pageFetched;

        this.table.setLoading(true);

        this.fetcher.fetch(pageToFetch, function(result) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                numContacts: result.total
            });

            self.pageFetched = pageToFetch;
            self.table.setLoading(false);
            self.table.setData(result.rows, pageToFetch, result.start, result.total, result.numRows);
        });
    }

    fetchNumValidContacts() {
        let args = {
            rows: 0,
            by_group_id: this.props.group.id
        };

        if (this.props.isMessage) {
            args.has_phone = true;
            args.unsubscribed_all_messages = false;
        } else {
            args.has_email = true;
            args.unsubscribed_all = false;
        }

        this.setState({
            numValidContacts: -1
        });

        const self = this;

        $.get(`/individuals?${$.param(args)}`, function(data, _, request) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                numValidContacts: parseInt(request.getResponseHeader('Records-Total'))
            });
        });
    }

    openContact(contactId) {
        window.open(`/#individuals/${contactId}`, '_blank');
    }

    computeFiltersBarFilters() {
        let filtersBarFilters = [];
        const rules = this.filterManager.getRules();

        if (rules) {
            let columnsAlreadyAdded = {};

            for (const r of _.flatten(rules)) {
                const column = this.table.getColumns().find(c => {
                    if (c.isCustomField) {
                        return c.filterId === r.field && c.filterCustom === r.custom;
                    }

                    return c.filterId === r.field;
                });

                if (column && column.id in columnsAlreadyAdded) {
                    continue;
                }

                let title = '';
                let colId = null;

                if (column) {
                    title = column.title;
                    colId = column.id;
                } else {
                    let filter = null;

                    for (const fg in this.filters) {
                        filter = this.filters[fg].find(f => f.id === r.field);

                        if (filter) {
                            break;
                        }
                    }

                    // there are some filters that doesnt have a column (e.g: group_id)
                    if (filter?.notRelatedColumn) {
                        title = filter.title;
                    } else {
                        continue;
                    }
                }

                filtersBarFilters.push({
                    id: r.field,
                    columnId: colId,
                    title: title
                });

                columnsAlreadyAdded[colId] = true;
            }
        }

        this.setState({
            filtersBarFilters: filtersBarFilters.length === 0 ? null : filtersBarFilters
        });
    }

    handleAdvancedFilterRulesChange(rules) {
        this.filterManager.setRules(rules);
        this.table.setColumnsRules(this.filterManager.getColumnsRules());

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.updateGroup();
            self.fetchTablePage(0);
        });
    }

    handleTableFilterRuleChange(ruleFieldId, ruleCustomId, rule) {
        this.filterManager.setRule(ruleFieldId, ruleCustomId, rule);
        this.table.setColumnsRules(this.filterManager.getColumnsRules());

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.updateGroup();
            self.fetchTablePage(0);
        });
    }

    handleFilterRuleDelete(columnId) {
        const column = this.table.getColumns().find(c => c.id === columnId);

        this.filterManager.setRule(column.filterId, column.filterCustom, null);
        this.table.setColumnsRules(this.filterManager.getColumnsRules());

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.updateGroup();
            self.fetchTablePage(0);
        });
    }

    handleAllFiltersRulesDeleted() {
        this.setState({
            filtersBarFilters: null
        });

        this.table.setColumnsRules({});
        this.filterManager.setRules(null);

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(null);
            self.updateGroup();
            self.fetchTablePage(0);
        });
    }

    handleSelectedColumnsChange(selectedColumns) {
        this.table.setColumns(selectedColumns);
        this.filterManager.setColumns(selectedColumns);

        this.fetcher.setColumns(selectedColumns);
        this.updateGroup();
        this.fetchTablePage();
    }

    handlePanelShow(panelId) {
        if (panelId === 'advancedFilter') {
            this.advancedFilterPanel.show(this.filterManager.getRules());
        } else if (panelId === 'configureColumns') {
            this.configureColumnsPanel.show(this.table.getColumns());
        }
    }

    handleAddEntity(entity) {
        const self = this;

        $.ajax({
            url: `/groups/${this.props.group.id}/items/${entity.id}`,
            method: 'PUT',
            success: function() {
                self.fetchTablePage(0);
                self.fetchNumValidContacts();
            }
        });
    }

    handleRemoveEntities(entities) {
        const itemsMessage = `${entities.length} item${entities.length > 1 ? 's' : ''}`;
        const handle = MessageBox.showNoBtn({
            icon: 'icon-trashcan',
                message: 'Removing ' + itemsMessage +
                "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                "<div class='mb-wait-cont'>0% complete</div>"
            },
            this.props.parent,
            {
                staticRegion: true
            }
        );

        const removeData = {
            group_id: this.props.group.id,
            entity_ids: entities
        };

        const progressBar = handle.messageBox.$el.find('.mb-progress-bar');
        const completeMessage = handle.messageBox.$el.find('.mb-wait-cont');
        const self = this;

        progressBar.width('0%');
        completeMessage.text('0% complete');

        $.ajax({
            type: 'POST',
            url: '/bulk_remove?queue',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(removeData),
            success: function(data) {
                const waitForCompletion = function() {
                    setTimeout(function() {
                        $.get('/bulk_remove/' + data.id, function(data) {
                            if (data.status === 'finished') {
                                handle.reset();
                                self.fetchTablePage(0);
                                self.fetchNumValidContacts();
                            }
                            else if (data.status !== 'failed') {
                                const pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                progressBar.width(pct + '%');
                                completeMessage.text(Math.floor(pct) + '% complete');
                                waitForCompletion();
                            }
                        });
                    }, 2000);
                };

                waitForCompletion();
            }
        });
    }

    handleRowSelectionChange(rowSelection) {
        this.toolbar.onRowSelectionChange(rowSelection);
    }

    handleShowAdvancedFilter(filter) {
        this.advancedFilterPanel.show(this.filterManager.getRules());
    }

    handleTableSortChange(columnId, sortDir) {
        this.fetcher.orderBy(columnId, sortDir);
        this.fetchTablePage();
    }

    render() {
        const tableHeightMod = this.state.filtersBarFilters ? 110 : 60;
        let numUndeliverableContacts = 0;

        if (this.state.numValidContacts !== -1) {
            numUndeliverableContacts = Math.max(this.state.numContacts - this.state.numValidContacts, 0);
        }

        return (
            <div
                className={`${style.sBlock} ${style.rRight}`}
                style={{
                    width: '100%',
                    padding: '20px'
                }}
            >
                <Toolbar
                    ref={(el) => this.toolbar = el}
                    title={this.props.group.name}
                    view={`${this.props.group.group_type}Edit`}
                    numUndeliverableContacts={numUndeliverableContacts}
                    parent={this.props.parent}
                    onClose={this.props.onClose}
                    onShowPanel={this.handlePanelShow.bind(this)}
                    removeEntities={this.handleRemoveEntities.bind(this)}
                    addEntity={this.handleAddEntity.bind(this)}
                />

                {this.state.filtersBarFilters &&
                    <div style={{
                        width: '100%'
                    }}>
                        <FiltersBar
                            filters={this.state.filtersBarFilters}
                            onFilterRuleDeleted={this.handleFilterRuleDelete.bind(this)}
                            onAllFiltersRulesDeleted={this.handleAllFiltersRulesDeleted.bind(this)}
                        />
                    </div>
                }

                <div
                    style={{
                        height: `calc(100% - ${tableHeightMod}px)`,
                        width: '100%'
                    }}
                >
                    <Table
                        ref={(el) => this.table = el}
                        rows={[]}
                        columns={this.tableColumns}
                        hasPaginator={true}
                        selectableRows={this.props.group.group_type === 'static'}
                        onGotoPage={this.fetchTablePage.bind(this)}
                        onRowClick={this.openContact.bind(this)}
                        onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                        onFilterRuleChange={this.handleTableFilterRuleChange.bind(this)}
                        showAdvancedFilter={this.handleShowAdvancedFilter.bind(this)}
                        onSortChange={this.handleTableSortChange.bind(this)}
                    />
                </div>

                <div
                    style={{
                        position: 'fixed',
                        top: '50px',
                        bottom: 0,
                        right: 0,
                        zIndex: 10
                    }}
                >
                    <AdvancedFilterPanel
                        ref={(el) => this.advancedFilterPanel = el}
                        entityType={this.props.entityType}
                        filters={this.filters}
                        onFilterRulesChange={this.handleAdvancedFilterRulesChange.bind(this)}
                    />

                    <ConfigureColumnsPanel
                        ref={(el) => this.configureColumnsPanel = el}
                        allColumns={this.allColumns}
                        width={625}
                        onSelectedColumnsChange={this.handleSelectedColumnsChange.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

class StepRecipients extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group: null,
            savedOnflyGroupId: null, // this is the group id of the group that is saved in the database
            onflyGroup: null,
            view: 'preview'
        };
    }

    componentDidMount() {
        this.mounted = true;

        if (this.props.campaignData.to_group) {
            const self = this;

            this.setState({
                view: 'loading'
            });

            $.get(`/groups/${this.props.campaignData.to_group.id}`, function(data) {
                if (!self.mounted) {
                    return;
                }

                let newState = {};

                if (data.system_type === 'on_fly') {
                    newState.onflyGroup = data;
                    newState.savedOnflyGroupId = data.id;
                    newState.view = 'edit';
                } else {
                    newState.group = data;
                    newState.view = 'preview';

                    _.defer(function() {
                        self.previewComponent.setGroup(data);
                    });
                }

                self.setState(newState);
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onNext() {
        if (!this.props.campaignData.to_group && !this.state?.group?.dynamic) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'You have to select an audience group'
            }, this.props.parent);

            return;
        }

        let numValidContacts = 0;
        let activeGroup = null;

        if (this.state.view === 'preview') {
            numValidContacts = this.previewComponent.getNumValidContacts();
            activeGroup = this.state.group;
        } else {
            numValidContacts = this.editComponent.getNumValidContacts();
            activeGroup = this.state.onflyGroup;
        }

        if (numValidContacts === 0) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'The selected group contains no contacts. Please add recipients or choose another group.'
            }, this.props.parent);

            return;
        }

        if (activeGroup.dynamic) {
            this.props.showLoading(true);
            const self = this;
            const filterField = 'funnel_ids' in activeGroup ? 'individual_funnels' : 'individual_tags'
            
            $.post('/individuals/filters', JSON.stringify({
                rules: [[{
                    field: filterField,
                    operator: 'equal',
                    values: {
                        id: activeGroup.id,
                        name: activeGroup.name
                    }
                }]]
            }), function(filter) {
                let data = {
                    element_type: 'individuals',
                    group_type: 'smart',
                    name: `${activeGroup.name} on ${dateFormat.shortFormatWithYear(new Date())}`,
                    mailing_list: true,
                    on_fly: true,
                    filter_id: filter.id
                };

                $.post('/groups', JSON.stringify(data), function(result) {
                    self.props.updateCampaignData('to_group', {
                        id: result.id,
                        name: result.name
                    });

                    self.props.showLoading(false);
                    self.props.gotoNextStep();
                });
            });
        } else {
            this.props.gotoNextStep();
        }
    }

    handleGroupChange(group) {
        this.setState({
            group: group
        });

        if (!group.dynamic) {
            this.props.updateCampaignData('to_group', {
                id: group.id,
                name: group.name
            });
        }

        this.previewComponent.setGroup(group);
    }

    handleEditGroup(group) {
        this.props.showLoading(true);

        // create the on-fly group cloning the selected one
        const url = group.group_type === 'static' ? `/groups?populate=true&from_group_id=${group.id}` : '/groups';

        let data = {
            element_type: 'individuals',
            group_type: group.group_type,
            name: `${group.name} on ${dateFormat.shortFormatWithYear(new Date())}`,
            mailing_list: true,
            on_fly: true
        };

        if (group.filter_id) {
            data.filter_id = group.filter_id;
        }

        const self = this;

        $.post(url, JSON.stringify(data), function(result) {
            self.props.updateCampaignData('to_group', {
                id: result.id,
                name: result.name
            });

            self.props.showLoading(false);

            self.setState({
                view: 'edit',
                onflyGroup: result
            });
        });
    }

    handleNewGroup(groupType) {
        this.props.showLoading(true);

        const data = {
            element_type: 'individuals',
            group_type: groupType,
            name: `New ${groupType === 'smart' ? 'Smart' : 'Static'} Audience`,
            mailing_list: true,
            on_fly: true
        };

        const self = this;

        $.post('/groups', JSON.stringify(data), function(result) {
            self.props.updateCampaignData('to_group', {
                id: result.id,
                name: result.name
            });

            self.props.showLoading(false);

            self.setState({
                view: 'edit',
                onflyGroup: result
            });
        });
    }

    handleEditClose() {
        // delete the on-fly group
        const self = this;
        const mbContent = {
            accept_is_negative: true,
            message: 'Are you sure you want to discard this audience group?',
            icon: 'icon-trashcan'
        };

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            self.props.resetProcess(self.props.stepIdx);
            self.props.updateCampaignData('to_group', null);
            self.props.showLoading(true);

            const deleteGroup = () => {
                $.ajax({
                    type: 'DELETE',
                    url: `/groups/${self.state.onflyGroup.id}`,
                    complete: function() {
                        self.props.showLoading(false);
                        self.setState({
                            view: 'preview',
                            onflyGroup: null
                        });
                    }
                });
            };

            // before delete the group we have to save the campaign removing the reference to the group
            if (self.state.savedOnflyGroupId) {
                self.props.saveDraft(null, function() {
                    deleteGroup();
                }, true);
            } else {
                deleteGroup();
            }
        });
    }

    onDraftSaved(campaignData) {
        if (campaignData.to_group?.system_type === 'on_fly') {
            this.setState({
                savedOnflyGroupId: campaignData.to_group.id
            });
        } else {
            this.setState({
                savedOnflyGroupId: null
            });
        }
    }

    renderActiveView() {
        const isMessage = this.props.campaignData.campaign_type === 'message';

        switch (this.state.view) {
            case 'preview':
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <GroupSelector
                            group={this.props.campaignData.to_group}
                            funnelId={this.props.campaignData.funnel_id}
                            onGroupChange={this.handleGroupChange.bind(this)}
                            onNewGroup={this.handleNewGroup.bind(this)}
                        />

                        <Preview
                            ref={(el) => this.previewComponent = el}
                            onEditGroup={this.handleEditGroup.bind(this)}
                            isMessage={isMessage}
                        />
                    </div>
                );

            case 'edit':
                return (
                    <Edit
                        ref={(el) => this.editComponent = el}
                        group={this.state.onflyGroup}
                        parent={this.props.parent}
                        onClose={this.handleEditClose.bind(this)}
                        isMessage={isMessage}
                    />
                );
        }

        return null;
    }

    render() {
        return (
            <div className={`${style.cStep} ${style.cRecipients}`}>
                {this.renderActiveView()}
            </div>
        );
    }
}

export default StepRecipients;
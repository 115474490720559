import _ from 'underscore';

import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';


export default class {
    constructor(options) {
        this.modelData = options.modelData;
        this.customFieldGroups = options.customFieldGroups;
    }

    parse(layout, outOfGroupCustomFields) {
        this.outOfGroupCustomFields = _.clone(outOfGroupCustomFields || []);

        return {
            outOfGroupCustomFields: this.outOfGroupCustomFields,
            elements: this.parseLayout(layout)
        };
    }

    parseSystemField(fieldId) {
        let fieldInfo = {
            id: fieldId
        };

        switch(fieldId) {
            case 'photo_url':
                fieldInfo.type = 'photo';
                fieldInfo.label = 'Photo';
                fieldInfo.placeholder = 'Image URL';
                break;

            case 'first_name':
                fieldInfo.type = 'text';
                fieldInfo.label = 'Name';
                fieldInfo.placeholder = 'First Name';
                fieldInfo.capitalizeValue = true;
                break;

            case 'last_name':
                fieldInfo.type = 'text';
                fieldInfo.placeholder = 'Last Name';
                fieldInfo.capitalizeValue = true;
                break;

            case 'role':
                fieldInfo.type = 'text';
                fieldInfo.label = TextManager.getText('ID_JOB_ROLE');
                fieldInfo.placeholder = 'Role';
                break;

            case 'organization_id': {
                let organization;

                if (this.modelData.organization) {
                    organization = {
                        id: this.modelData.organization.id,
                        title: this.modelData.organization.name
                    };
                }

                fieldInfo.type = 'organization';
                fieldInfo.label = TextManager.parseText('${ID_ORGANIZATION, capitalize}');
                fieldInfo.value = organization;
            }
            break;

            case 'tags':
                fieldInfo.type = 'tags';
                fieldInfo.placeholder = '+ Add Tag';
                break;

            case 'funnels':
                fieldInfo.type = 'funnels';
                fieldInfo.placeholder = `+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`;
                break;

            case 'communication_phones':
                fieldInfo.type = 'communicationList';
                fieldInfo.medium = 'phone';
                break;

            case 'communication_emails':
                fieldInfo.type = 'communicationList';
                fieldInfo.medium = 'email';
                break;

            case 'locations':
                if (AppConfig.getValue('disableLocationsSectionOnIndividualAndOrganization')) {
                    return null;
                }

                fieldInfo.type = 'locations';
                break

            case 'unsubscribed_all':
                fieldInfo.type = 'checkbox';
                fieldInfo.label = TextManager.getText('ID_EMAIL_OPTED_IN');
                fieldInfo.invertValue = true;
                break;

            case 'unsubscribed_all_messages':
                if (!AppConfig.getValue('enableTextMessaging')) {
                    return null;
                }

                fieldInfo.type = 'checkbox';
                fieldInfo.label = TextManager.getText('ID_TEXTING_OPTED_IN');
                fieldInfo.invertValue = true;
                break;

            case 'linkedin':
                fieldInfo.type = 'social';
                fieldInfo.label = 'Linkedin';
                fieldInfo.placeholder = 'Linkedin URL';
                break;

            case 'twitter':
                fieldInfo.type = 'social';
                fieldInfo.label = 'Twitter';
                fieldInfo.placeholder = 'TwitterHandle';
                break;

            case 'facebook':
                fieldInfo.type = 'social';
                fieldInfo.label = 'Facebook';
                fieldInfo.placeholder = 'Facebook URL';
                break;

            case 'instagram':
                fieldInfo.type = 'social';
                fieldInfo.label = 'Instagram';
                fieldInfo.placeholder = 'Instagram URL';
                break;

            case 'comments':
                fieldInfo.type = 'paragraph';
                fieldInfo.label = TextManager.getText('ID_MORE_INFO');
                fieldInfo.placeholder = `Write something about ${!this.modelData.id ? TextManager.parseText('this ${ID_INDIVIDUAL}') : (this.modelData.first_name + ' ' + this.modelData.last_name)}`;
                break;

            case 'source':
                fieldInfo.type = 'source';
                break;

            case 'owner':
                fieldInfo.type = 'user';
                fieldInfo.label = 'Owner';
                break;

            case 'custom_field_groups':
                fieldInfo.type = 'customFieldGroups';
                break;

            case 'enquiry_count':
                fieldInfo.type = 'incrementalNumber';
                fieldInfo.label = 'Enquiry Count';
                break;

        }

        return fieldInfo;
    }

    parseStyle(style) {
        const parts = style.split(':');
        let styleInfo = {};

        switch(parts[0]) {
            case 'hspace':
            case 'vspace':
                styleInfo.type = parts[0];
                styleInfo.value = parts[1];
                break;
        }

        return styleInfo;
    }

    parseCustomField(fieldId) {
        if (this.customFieldGroups) {
            for (const group of this.customFieldGroups) {
                let customFieldInfo = group.fields.find(cf => cf.id === fieldId);

                if (customFieldInfo) {
                    this.outOfGroupCustomFields.push(customFieldInfo.id);

                    return {
                        type: 'customField',
                        cfInfo: customFieldInfo
                    };
                }
            }
        }

        return null;
    }

    parseGroups(groups) {
        let groupsInfo = [];
        let customFieldInGroups = [];
        let defaultGroup = null;

        for (const group of groups) {
            if (group.isDefault) {
                defaultGroup = {
                    id: group.id,
                    name: group.name,
                    isDefault: true,
                    elements: []
                };
            } else {
                groupsInfo.push({
                    id: group.id,
                    name: group.name,
                    visible_if: group.visible_if,
                    elements: group.elements ? this.parseLayout(group.elements) : null,
                });

                customFieldInGroups = customFieldInGroups.concat(group.elements);
            }
        }

        if (defaultGroup && this.customFieldGroups) {
            let defaultLayout = [];

            for (const group of this.customFieldGroups) {
                for (const field of group.fields) {
                    const cfKey = `cf.${field.id}`;

                    if ((customFieldInGroups.indexOf(cfKey) === -1) && (this.outOfGroupCustomFields.indexOf(field.id) === -1)) {
                        defaultLayout.push(cfKey);
                    }
                }
            }

            if (defaultLayout.length > 0) {
                defaultGroup.elements = this.parseLayout(defaultLayout);
                groupsInfo.push(defaultGroup);
            }
        }

        return groupsInfo;
    }

    parseLayout(layout) {
        let layoutInfo = [];

        for (const element of layout) {
            if (_.isObject(element)) {
                if (element.type === 'groups_section') {
                    layoutInfo.push({
                        type: element.type,
                        style: element.style || {},
                        elements: this.parseGroups(element.elements)
                    });
                } else {
                    layoutInfo.push({
                        type: 'block',
                        style: element.style || {},
                        elements: this.parseLayout(element.elements)
                    });
                }
            } else {
                const elementParts = element.split('.');

                switch(elementParts[0]) {
                    case 'system': {
                        const fieldInfo = this.parseSystemField(elementParts[1]);

                        if (fieldInfo) {
                            layoutInfo.push(fieldInfo);
                        }
                    }
                    break;

                    case 'cf': {
                        const args = elementParts[2]
                        const cfInfo = this.parseCustomField(elementParts[1]);

                        if (cfInfo) {
                            cfInfo.args = args || [];
                            layoutInfo.push(cfInfo);
                        }
                    }
                    break;

                    case 'style':
                        layoutInfo.push(this.parseStyle(elementParts[1]));
                        break;
                }
            }
        }

        return layoutInfo;
    }
}
import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'underscore'

import vent from 'js/vent';
import ModalRegion from 'js/views/base/modal-region';
import { SubmitPopover, ChoicesDialog } from 'app_v2/dialogs/choices/choices';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import MessageBox from 'js/views/message_box';
import {NumberField, TextField} from 'js/react_views/common_components/common';
import Table from 'app_v2/components/table/table';
import AppConfig from 'app/app-config';
import Utilities from 'js/utils/utilities';

import style from './choices.css'


const CHOICES_TABS = [{
    id: 'selections',
    title: 'Selections'
}, {
    id: 'ordersPlaced',
    title: 'Orders Placed'
}, {
    id: 'payments',
    title: 'Payments'
}];


const WISHLIST_SEQUENCE_STEPS = [{
    id: 'requestingItems',
    title: (wishlist) => {
        if (wishlist) {
            return 'Requested Selections - Draft';
        }

        return 'Requested Selections - Not Started';
    },
    description: (wishlist) => {
        if (wishlist) {
            return 'The homeowner has made their initial selections which are currently saved as a draft. View or edit below.';
        }

        return `The homeowner has not started a Selections wishlist. To begin a wishlist, click below.`;
    }
}, {
    id: 'salesAdvisorReview',
    title: 'Sales Advisor Review',
    description: 'The homeowner has submitted their selections for review. Arrange an appointment and review all selections.'
}, {
    id: 'commercialTeamReview',
    title: 'Commercial Team Review',
    description: 'Sales Team have submitted POAs to the Commercial Team - currently awaiting POA prices to be added.'
}, {
    id: 'sendHomeownerApproval',
    title: 'Send for Homeowner Approval',
    description: 'Selections are ready to be sent to the homeowner for final approval. Click below to review and send for approval.',
    disabled: (primaryBuyer) => {
        return !primaryBuyer;
    }
}, {
    id: 'waitingHomeownerApproval',
    title: 'Sent for Homeowner Approval',
    description: 'An email has been sent to the homeowner - currently awaiting their approval.',
    disabled: (primaryBuyer) => {
        return !primaryBuyer;
    }
}, {
    id: 'homeownerApproved',
    title: 'Homeowner Approved',
    description: '',
    disabled: (primaryBuyer) => {
        return !primaryBuyer;
    }
}, {
    id: 'paymentAndSubmit',
    title: 'Log Payment + Submit to COINS',
    description: 'The homeowner has approved their selections! Time to log payment and submit to COINS.'
}, {
    id: 'submitted',
    title: 'Payment Taken + Submitted to COINS',
    description: 'Payment has been taken and selections submitted to COINS. Click below to create a new wishlist.'
}];


class Payments extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [{
            id: 'created',
            title: 'Date',
            type: 'date'
        }, {
            id: 'amount',
            title: 'Amount Taken',
            type: 'currency'
        }, {
            id: 'last4Digits',
            title: 'Last 4 Digits',
            type: 'text'
        }, {
            id: 'notes',
            title: 'Notes',
            type: 'text'
        }];

        this.state = {
            submitPopover: null
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchPayments();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleSubmitPopoverClose(data) {
        if (data) {
            data.opportunity_id = this.props.deal.id;
            data.skip_coins = true;

            const self = this;

            $.ajax({
                type: 'POST',
                url: '/coins_choices_submits?skip_coins',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(data),
                success: function() {
                    if (!self.mounted) {
                        return;
                    }

                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Payment submitted',
                                timer: 5000,
                                classes: 'success'
                            };
                        }
                    });

                    self.fetchPayments();
                }
            });
        }

        this.setState({
            submitPopover: null
        });
    }

    showSubmitPopover() {
        this.setState({
            submitPopover: true
        });
    }

    fetchPayments() {
        this.tableComponent.setLoading(true);

        const self = this;

        $.get(`/coins_choices_submits?opportunity_id=${this.props.deal.id}`, (data) => {
            if (!self.mounted) {
                return;
            }

            const rows = [];

            for (const item of data) {
                rows.push({
                    created: item.created,
                    amount: item.amount_paid,
                    last4Digits: item.last_4_digits,
                    notes: item.notes || ''
                });
            }

            self.tableComponent.setData(rows, 0, 0, rows.length);
            self.tableComponent.setLoading(false);
        });
    }

    render() {
        return (
            <div className={style.cPayments}>
                <div className={style.paHeader}>
                    <div
                        className={style.bButton}
                        onClick={this.showSubmitPopover.bind(this)}
                    >
                        Add Payment
                    </div>
                </div>

                <Table
                    ref={(el) => this.tableComponent = el}
                    columns={this.columns}
                    rows={[]}
                    rowsClickables={false}
                    hasPaginator={true}
                />

                {this.state.submitPopover &&
                    <SubmitPopover
                        onClose={this.handleSubmitPopoverClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

class Wishlist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            wishlist: null,
            activeStatus: 'requestingItems',
            sequenceSteps: [],
            primaryBuyer: null
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchWishlist();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onWishlistCreated(wishlist) {
        this.setState({
            wishlist: wishlist,
            activeStatus: 'requestingItems'
        });
    }

    onWishlistUpdated(wishlist) {
        this.setState({
            wishlist: wishlist
        });
    }

    sendEmail(recipient, subject, body, callback, attachmentId) {
        const brandCode = this.props.deal.funnel.integration_data?.brandCode;
        let fromId = null;

        if (brandCode === 'C') {
            fromId = app.globalData.users.find(u => u.email_address.toLowerCase() === 'noreply@charleschurch.com')?.id;
        } else {
            fromId = app.globalData.users.find(u => u.email_address.toLowerCase() === 'noreply@persimmonhomes.com')?.id;
        }

        const data = {
            trigger_id: this.props.deal.id,
            trigger_type: 'deal',
            from_id: fromId || app.user.get('id'),
            from_type: 'user',
            recipient_name: recipient.name,
            recipient_email: recipient.email,
            subject: subject,
            body: body,
            create_activity_log: false
        }

        if (attachmentId) {
            data.attached_files_id = [attachmentId];
        }

        $.post('/send_email', JSON.stringify(data), () => {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'The email has been sent',
                        timer: 5000,
                        classes: 'success'
                    };
                }
            });

            if (callback) {
                callback();
            }
        });
    }

    sendForHOApproval(updateStatus, pdf) {
        const goOn = (fileId) => {
            const brand = this.props.deal.funnel.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';
            const campaignId = AppConfig.getValue(`deals.choices.campaigns_id.${brand}.homeowner_selections_approval`);
            const self = this;

            this.getCampaign(campaignId, (campaign) => {
                let body = campaign.body;
                const baseUrl = `${app.options.apiUrl}/coins_choices_wishlists/${this.state.wishlist.id}`;

                if (self.state.primaryBuyer?.email) {
                    body = Utilities.replaceAll(body, '${reject_choices_url}', `${baseUrl}/reject?by=${self.state.primaryBuyer.id}`);
                    body = Utilities.replaceAll(body, '${approve_choices_url}', `${baseUrl}/approve?by=${self.state.primaryBuyer.id}`);

                    self.sendEmail(self.state.primaryBuyer, campaign.subject, body, () => {
                        if (updateStatus) {
                            self.updateActiveStatus('waitingHomeownerApproval');
                        }
                    }, fileId);
                }
            });
        }

        // ...
        if (pdf) {
            this.savePdf(pdf, true, goOn);
        } else {
            // find the file id for the pdf
            $.get(`/opportunities/${this.props.deal.id}/related_files`, (result) => {
                let fileId = null;

                for (const file of result) {
                    if (file.description === 'for_approval') {
                        fileId = file.id;
                        break;
                    }
                }

                goOn(fileId);
            });
        }
    }

    confirmPOAsPrices() {
        const user = app.globalData.users.find(u => u.id === this.state.wishlist.last_modified_by_id);
        const campaignId = AppConfig.getValue('deals.choices.campaigns_id.poas_updated');
        const self = this;

        this.getCampaign(campaignId, (campaign) => {
            let body = campaign.body;
            const url = `${app.options.appUrl}/#deals/${this.props.deal.id}?show_choices=regular`;
            const recipient = {
                name: user.name,
                email: user.email_address
            }

            body = Utilities.replaceAll(body, '${review_url}', url);

            this.sendEmail(recipient, campaign.subject, body, () => {
                self.updateActiveStatus(self.state.primaryBuyer ? 'sendHomeownerApproval' : 'paymentAndSubmit');
            });
        });
    }

    sendPOAsToCommercial() {
        const recipientAddress = this.props.deal?.funnel?.region?.commercial_team_email;

        if (!recipientAddress) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'The region does not have a commercial team email',
                        timer: 5000,
                        classes: 'error'
                    };
                }
            });

            return;
        }

        const campaignId = AppConfig.getValue('deals.choices.campaigns_id.selections_poa_requested');
        const self = this;

        this.getCampaign(campaignId, (campaign) => {
            let body = campaign.body;
            const url = `${app.options.appUrl}/#deals/${this.props.deal.id}?show_choices=poa`;
            const recipient = {
                name: 'Commercial Team',
                email: recipientAddress
            }

            body = Utilities.replaceAll(body, '${pending_selections_poa_url}', url);

            this.sendEmail(recipient, campaign.subject, body, () => {
                self.updateActiveStatus('commercialTeamReview');
            });
        });
    }

    getCampaign(campaignId, callback) {
        $.get(`/campaigns/${campaignId}`, function(result) {
            callback({
                subject: result.subject,
                body: result.content
            });
        });
    }

    onCtaButtonClicked(ctaButtonId, contextData) {
        const self = this;

        switch (ctaButtonId) {
            case 'markAsReviewed':
                this.updateActiveStatus('salesAdvisorReview');
                break;

            case 'sendPOAsToCommercial':
                this.sendPOAsToCommercial();
                break;

            case 'progressToSubmit':
                this.updateActiveStatus('paymentAndSubmit');
                break;

            case 'rejectWishlist':
                this.rejectWishlist(contextData);
                break;

            case 'confirmPOAsPrices':
                this.confirmPOAsPrices();
                break;

            case 'sendForHOApproval':
                this.sendForHOApproval(true, contextData);
                break;

            case 'submitToCoins':
                this.submitToCoins(contextData);
                break;
        }
    }

    cancelApproval() {
        const content = {
            message: 'Are you sure you want to cancel the approval?',
            accept_is_negative: true,
            icon: 'icon-warning'
        };

        const self = this;

        MessageBox.showYesNo(content, this.props.parent, function() {
            const brand = self.props.deal.funnel.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';
            const campaignId = AppConfig.getValue(`deals.choices.campaigns_id.${brand}.approval_cancelled`);

            self.getCampaign(campaignId, (campaign) => {
                if (self.state.primaryBuyer?.email) {
                    self.sendEmail(self.state.primaryBuyer, campaign.subject, campaign.body, () => {
                        self.updateActiveStatus('requestingItems', true);
                    });
                } else {
                    self.updateActiveStatus('requestingItems', true);
                }
            });
        });
    }

    fetchWishlist() {
        this.setState({
            loading: true
        });

        const self = this;
        let counter = 0;
        let wishlist = null;
        let primaryBuyer = null;
        let buildProgress = 0;

        const checkReady = () => {
            --counter;

            if (counter > 0) {
                return;
            }

            let newState = {
                loading: false,
                buildProgress: buildProgress
            };

            if (primaryBuyer) {
                newState.primaryBuyer = primaryBuyer;
            }

            if (wishlist) {
                newState.wishlist = wishlist;
                newState.activeStatus = wishlist.status;

                if (this.props.show) {
                    if (this.props.show === 'poa') {
                        if (wishlist.status === 'commercialTeamReview') {
                            _.defer(() => {
                                self.props.showChoicesDialog(wishlist, !!primaryBuyer, true, buildProgress);
                            });
                        }
                    } else if (this.props.show === 'regular') {
                        _.defer(() => {
                            self.props.showChoicesDialog(wishlist, !!primaryBuyer, false, buildProgress);
                        });
                    }
                }
            }

            this.setState(newState);

            _.defer(() => {
                self.manageStepsVisibility();
            });
        }

        // ...
        const primaryBuyerCf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'primary buyer');
        const primaryBuyerId = this.props.deal[`custom_field.${primaryBuyerCf?.id}`];

        if (primaryBuyerId) {
            ++counter;

            $.get(`/individuals/${primaryBuyerId}`, (individual) => {
                if (!self.mounted) {
                    return;
                }

                primaryBuyer = {
                    id: individual.id,
                    name: individual.full_name,
                    email: individual.communication.find(comm => comm.medium === 'email' && comm.value)?.value
                };

                checkReady();
            }).fail(() => {
                checkReady();
            });
        }

        // ...
        ++counter;

        $.get(`/coins_choices_wishlists?opportunity_id=${this.props.deal.id}&status=in_progress`, (result) => {
            if (!self.mounted) {
                return;
            }

            wishlist = result[0];

            checkReady();
        });

        // ...
        ++counter;

        const funnel = this.props.deal.funnel;
        const region = funnel.region?.integration_data?.region_id;
        const development = funnel.integration_data?.coinsCode;
        const plot = this.props.deal[`custom_field.${app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'unit no.')?.id}`];

        $.get(`/coins_build_progress?region=${region}&development=${development}&plot=${plot}`, function(result) {
            if (result.success) {
                if (result.data.length > 0) {
                    buildProgress = parseInt(result.data[0].build_progress_stage || '0');
                }
            }

            checkReady();
        });
    }

    getStepTitle(step) {
        if (_.isFunction(step.title)) {
            return step.title(this.state.wishlist);
        }

        return step.title;
    }

    getStepDescription(step) {
        if (_.isFunction(step.description)) {
            return step.description(this.state.wishlist);
        }

        return step.description;
    }

    getTitleStyle(step) {
        if (('disabled' in step) && step.disabled(this.state.primaryBuyer)) {
            return {
                textDecoration: 'line-through'
            };
        }

        return null;
    }

    getStatusStyle(stepId) {
        const activeStatus = this.state.activeStatus;
        const currentIdx = WISHLIST_SEQUENCE_STEPS.findIndex(s => s.id === activeStatus);
        const stepIdx = WISHLIST_SEQUENCE_STEPS.findIndex(s => s.id === stepId);

        if (stepIdx < currentIdx) {
            return {
                borderColor: '#70d061',
                background: '#70d061'
            };
        } else if (stepIdx === currentIdx) {
            if (activeStatus === 'requestingItems') {
                if (this.state.wishlist) {
                    return {
                        borderColor: '#70d061',
                        background: '#70d061'
                    };
                } else {
                    return {
                        borderColor: '#70d061'
                    };
                }
            } else if (activeStatus === 'submitted') {
                return {
                    borderColor: '#70d061',
                    background: '#70d061'
                }
            } else {
                return {
                    borderColor: '#d68f49',
                    background: '#d68f49'
                };
            }
        }

        return null;
    }

    getStatusIcon() {
        switch (this.state.activeStatus) {
            case 'requestingItems':
            case 'salesAdvisorReview':
            case 'commercialTeamReview':
            case 'sendHomeownerApproval':
                return 'icon-unlocked';

            case 'waitingHomeownerApproval':
            case 'paymentAndSubmit':
            case 'submitted':
                return 'icon-locked';
        };

        return null;
    }

    getStatusFirstLine() {
        switch (this.state.activeStatus) {
            case 'requestingItems':
            case 'salesAdvisorReview':
            case 'commercialTeamReview':
            case 'sendHomeownerApproval':
                return 'Wishlist Editable';

            case 'waitingHomeownerApproval':
                return 'Locked Pending Approval';

            case 'paymentAndSubmit':
            case 'submitted':
                return 'Locked';
        };

        return '';
    }

    getStatusSecondLine() {
        switch (this.state.activeStatus) {
            case 'requestingItems':
                if (this.state.wishlist) {
                    return 'To edit click View/Edit below';
                }

                return 'To create click Create below';

            case 'salesAdvisorReview':
            case 'commercialTeamReview':
            case 'sendHomeownerApproval':
                return 'To edit click View/Edit below';

            case 'waitingHomeownerApproval':
            case 'paymentAndSubmit':
            case 'submitted':
                return 'To edit selections, cancel approval';
        };

        return '';
    }

    handleGotoNextStatus() {
        let nextStatus = null;

        switch (this.state.activeStatus) {
            case 'requestingItems':
                nextStatus = 'salesAdvisorReview';
                break;
        }

        if (nextStatus !== null) {
            this.updateActiveStatus(nextStatus);
        }
    }

    rejectWishlist(reason) {
        const user = app.globalData.users.find(u => u.id === this.state.wishlist.last_modified_by_id);
        const campaignId = AppConfig.getValue('deals.choices.campaigns_id.commercial_team_incomplete');
        const self = this;

        this.getCampaign(campaignId, (campaign) => {
            let body = campaign.body;
            const recipient = {
                name: user.name,
                email: user.email_address
            }

            body = Utilities.replaceAll(body, '${message}', reason);

            this.sendEmail(recipient, campaign.subject, body, () => {
                self.updateActiveStatus('requestingItems');
            });
        });
    }

    updateActiveStatus(status, removeApprovalData) {
        const self = this;
        const data = {
            status: status
        };

        if (removeApprovalData) {
            data.approved_by_id = null;
            data.approval_date = null;
        }

        $.ajax({
            type: 'PATCH',
            url: `/coins_choices_wishlists/${this.state.wishlist.id}`,
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
            success: function(result) {
                self.setState({
                    activeStatus: result.status,
                    wishlist: result
                });

                _.defer(() => {
                    self.manageStepsVisibility();
                });
            }
        });
    }

    manageStepsVisibility() {
        let steps = _.clone(WISHLIST_SEQUENCE_STEPS);
        const currentIdx = WISHLIST_SEQUENCE_STEPS.findIndex(s => s.id === this.state.activeStatus);

        if (currentIdx > WISHLIST_SEQUENCE_STEPS.findIndex(s => s.id === 'sendHomeownerApproval')) {
            steps = steps.filter(s => s.id !== 'sendHomeownerApproval');
        } else {
            steps = steps.filter(s => s.id !== 'waitingHomeownerApproval');
        }

        this.setState({
            sequenceSteps: steps
        });
    }

    renderButtons() {
        switch (this.state.activeStatus) {
            case 'requestingItems':
                if (this.state.wishlist) {
                    return (
                        <div className={style.wButtons}>
                            <div
                                className={`${style.bButton} ${style.bNeutral}`}
                                onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                            >
                                View/Edit Selections
                            </div>

                            <div
                                className={`${style.bButton} ${style.bNeutral}`}
                                onClick={this.handleGotoNextStatus.bind(this)}
                            >
                                Progress to Review Status
                            </div>
                        </div>
                    );
                }

                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(null, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            Create New Wishlist
                        </div>
                    </div>
                );

            case 'salesAdvisorReview':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            Review/Edit Draft Wishlist
                        </div>
                    </div>
                );

            case 'commercialTeamReview':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, true, this.state.buildProgress)}
                        >
                            Add POA Prices
                        </div>

                        <div
                            className={`${style.bButton} ${style.bNeutral}`}
                            onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            View/Edit Selections
                        </div>
                    </div>
                );

            case 'sendHomeownerApproval':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            Review + Send Wishlist for Approval
                        </div>
                    </div>
                );

            case 'waitingHomeownerApproval':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={`${style.bButton} ${style.bNeutral}`}
                            onClick={() => this.sendForHOApproval.bind(this)(false)}
                        >
                            Resend Approval Email
                        </div>

                        <div
                            className={`${style.bButton} ${style.bCancel}`}
                            onClick={this.cancelApproval.bind(this)}
                        >
                            Cancel Approval
                        </div>
                    </div>
                );

            case 'paymentAndSubmit':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(this.state.wishlist, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            Log Payment + Submit to COINS
                        </div>

                        <div
                            className={`${style.bButton} ${style.bCancel}`}
                            onClick={this.cancelApproval.bind(this)}
                        >
                            Cancel Approval
                        </div>
                    </div>
                );

            case 'submitted':
                return (
                    <div className={style.wButtons}>
                        <div
                            className={style.bButton}
                            onClick={() => this.props.showChoicesDialog(null, !!this.state.primaryBuyer, false, this.state.buildProgress)}
                        >
                            Create New Wishlist
                        </div>

                        <div
                            className={`${style.bButton} ${style.bNeutral}`}
                            style={{marginLeft: 'auto'}}
                            onClick={() => this.props.showOrdersPlacedDialog()}
                        >
                            View Placed Orders
                        </div>
                    </div>
                );
        }

        return null;
    }

    sendSelectionsApprovedEmail(fileId, callback) {
        const brand = this.props.deal.funnel.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';
        const campaignId = AppConfig.getValue(`deals.choices.campaigns_id.${brand}.selections_approved`);
        const self = this;

        this.getCampaign(campaignId, (campaign) => {
            let body = campaign.body;

            if (self.state.primaryBuyer?.email) {
                self.sendEmail(self.state.primaryBuyer, campaign.subject, body, () => {
                    callback(true);
                }, fileId);
            } else {
                callback(false);
            }
        });
    }

    savePdf(pdf, forApproval, callback) {
        const coinsCode = this.props.deal.funnel.integration_data?.coinsCode;
        const plotNumber = this.props.deal[`custom_field.${app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'unit no.')?.id}`];
        let filename = '';

        if (coinsCode) {
            filename += coinsCode;
        }

        if (plotNumber) {
            if (filename !== '') {
                filename += '/';
            }

            filename += `${plotNumber} - `;
        }

        const now = new Date();
        let day = now.getDate();
        day = ((day < 9) ? '0' : '') + day;

        let month = now.getMonth() + 1;
        month = ((month < 9) ? '0' : '') + month;

        let hours = now.getHours();
        hours = ((hours < 9) ? '0' : '') + hours;

        let minutes = now.getMinutes();
        minutes = ((minutes < 9) ? '0' : '') + minutes;

        const brandCode = this.props.deal.funnel.integration_data?.brandCode;

        filename += `${brandCode === 'C' ? 'Select Options' : 'Finishing Touches'} - ${now.getFullYear()}${month}${day} ${hours}${minutes}${forApproval ? ' - For Approval' : ''}.pdf`;

        $.post(`/opportunities/${this.props.deal.id}/related_files`, JSON.stringify({
            files: [{
                filename: filename,
                description: forApproval ? 'for_approval' : '',
                file: pdf.substring(pdf.indexOf(',') + 1) // skip mime information
            }]
        }), (result) => {
            callback(result[0].id);
        });
    }

    submitToCoins(data) {
        const self = this;

        const finishProcess = () => {
            this.savePdf(data.pdf, false, (fileId) => {
                this.sendSelectionsApprovedEmail(fileId, () => {
                    this.updateActiveStatus('submitted');
                    this.setState({
                        loading: false
                    });
                });
            });
        }

        this.setState({
            loading: true
        });

        $.get(`/coins_choices?wishlist_id=${this.state.wishlist.id}`, (items) => {
            if (items.length > 0) {
                const choicesData = _.clone(data);

                choicesData.opportunity_id = self.props.deal.id;
                choicesData.wishlist_id = self.state.wishlist.id
                delete choicesData.pdf;

                $.ajax({
                    type: 'POST',
                    url: '/coins_choices_submits',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(choicesData),
                    error: function(result) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: `Error submitting to COINS: ${result?.responseJSON?.detail?.message}`,
                                    timer: 5000,
                                    classes: 'error'
                                };
                            }
                        });

                        self.setState({
                            loading: false
                        });
                    },
                    success: function() {
                        finishProcess();

                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Selections submitted to COINS',
                                    timer: 5000,
                                    classes: 'success'
                                };
                            }
                        });
                    }
                });
            } else {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There are not items selected',
                            timer: 5000,
                            classes: 'error'
                        };
                    }
                });

                self.setState({
                    loading: false
                });
            }
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div
                    style={{marginTop: '30px'}}
                >
                    <LoadingIndicator/>
                </div>
            );
        }

        const statusIcon = this.getStatusIcon();

        return (
            <div className={style.cWishlist}>
                <div className={style.wHeader}>
                    <div className={style.hTitle}>Selections</div>
                    <div className={style.hStatus}>
                        <div className={style.sFirstLine}>
                            {statusIcon &&
                                <span
                                    className={statusIcon}
                                    style={{marginRight: '6px'}}
                                />
                            }
                            <span>{this.getStatusFirstLine()}</span>
                        </div>

                        <div className={style.sSecondLine}>
                            {this.getStatusSecondLine()}
                        </div>
                    </div>
                </div>

                <div className={style.wSequence}>
                    {this.state.sequenceSteps.map(step => {
                        return (
                            <div
                                key={`s_${step.id}`}
                                className={`
                                    ${style.sStep}
                                    ${step.id === this.state.activeStatus ? style.sActive : ''}
                                `}
                            >
                                <div className={style.sHeader}>
                                    <div
                                        className={style.sStatus}
                                        style={this.getStatusStyle(step.id)}
                                    />

                                    <div
                                        className={style.sTitle}
                                        style={this.getTitleStyle(step)}
                                    >
                                        {this.getStepTitle(step)}
                                    </div>
                                </div>


                                <div className={style.sDescription}>
                                    {this.getStepDescription(step)}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {this.renderButtons()}
            </div>
        );
    }
}


class Choices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTabId: 'selections'
        };

        this.mounted = false;
    }

    selectTab(tabId) {
        if (tabId === this.state.activeTabId) {
            return;
        }

        if (tabId === 'ordersPlaced') {
            this.props.showOrdersPlacedDialog();
            return;
        }

        this.setState({
            activeTabId: tabId
        });
    }

    onWishlistCreated(wishlist) {
        if (this.activeComponent?.onWishlistCreated) {
            this.activeComponent.onWishlistCreated(wishlist);
        }
    }

    onWishlistUpdated(wishlist) {
        if (this.activeComponent?.onWishlistUpdated) {
            this.activeComponent.onWishlistUpdated(wishlist);
        }
    }

    onCtaButtonClicked(ctaButtonId, contextData) {
        if (this.activeComponent?.onCtaButtonClicked) {
            this.activeComponent.onCtaButtonClicked(ctaButtonId, contextData);
        }
    }

    render() {
        return (
            <div className={style.choices}>
                <div className={style.cTabControl}>
                    {CHOICES_TABS.map(tab => {
                        return (
                            <div
                                key={`t_${tab.id}`}
                                className={`
                                    ${style.tTab}
                                    ${this.state.activeTabId === tab.id ? style.tActive : ''}
                                `}
                                onClick={() => this.selectTab.bind(this)(tab.id)}
                            >
                                {tab.title}
                            </div>
                        );
                    })}
                </div>

                <div className={style.cContent}>
                    {this.state.activeTabId === 'selections' &&
                        <Wishlist
                            ref={(el) => this.activeComponent = el}
                            deal={this.props.deal}
                            parent={this.props.parent}
                            show={this.props.show}
                            showChoicesDialog={this.props.showChoicesDialog}
                            showOrdersPlacedDialog={this.props.showOrdersPlacedDialog}
                        />
                    }

                    {this.state.activeTabId === 'payments' &&
                        <Payments
                            deal={this.props.deal}
                        />
                    }
                </div>
            </div>
        );
    }
}


const ChoicesView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    regions: {
        choicesRegion: {
            selector: '.choices-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        }
    },
    initialize: function(options) {
        this.deal = options.model.toJSON();
        this.choicesComponent = null;
    },
    showOrdersPlacedDialog() {
        const choicesDialog = new ChoicesDialog({
            deal: this.deal,
            view: 'ordersPlaced'
        });

        this.choicesRegion.show(choicesDialog);
    },
    showChoicesDialog(wishlist, hasBuyer, managePOAs, buildProgress) {
        const choicesDialog = new ChoicesDialog({
            deal: this.deal,
            wishlist: wishlist,
            hasBuyer: hasBuyer,
            buildProgress: buildProgress,
            view: 'requested',
            managePOAs: managePOAs
        });

        const self = this;

        this.listenTo(choicesDialog, 'wishlist:created', (wishlist) => {
            self.choicesComponent.onWishlistCreated(wishlist);
        });

        this.listenTo(choicesDialog, 'wishlist:updated', (wishlist) => {
            self.choicesComponent.onWishlistUpdated(wishlist);
        });

        this.listenTo(choicesDialog, 'ctaButton:clicked', (ctaButtonId, contextData) => {
            self.choicesComponent.onCtaButtonClicked(ctaButtonId, contextData);
        });

        this.choicesRegion.show(choicesDialog);
    },
    onRender: function() {
        this.$el.css('height', '100%');
        this.renderReact();
    },
    renderReact() {
        ReactDOM.render(
            <Choices
                ref={(el) => this.choicesComponent = el}
                deal={this.deal}
                show={this.options.show}
                parent={this}
                showChoicesDialog={this.showChoicesDialog.bind(this)}
                showOrdersPlacedDialog={this.showOrdersPlacedDialog.bind(this)}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default ChoicesView;

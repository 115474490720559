import Handlebars from 'handlebars'

import BaseItemView from 'js/views/base/item.js'
import organizationItemTemplate from 'templates/organizations/item.handlebars';


var OrganizationItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(organizationItemTemplate),
    templateHelpers: function () {
        return {
            primaryWebsite: this.model.primaryWebsite(),
            primaryPhone: this.model.primaryPhone(),
            primaryLocation: this.model.primaryLocation()
        };
    },
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        this.confirmOnClick = true;
    }
});

export default OrganizationItemView;

import $ from 'jquery'
import _ from 'underscore'

import BaseCollection from 'js/collections/base'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import OpportunityModel from 'js/models/opportunity'
import TaskModel from 'js/models/task'
import ActivityModel from 'js/models/activity'


var modelByElementType = {
    individuals: IndividualModel,
    organizations: OrganizationModel,
    opportunities: OpportunityModel,
    tasks: TaskModel,
    activities: ActivityModel,
    texts: ActivityModel,
    messages: ActivityModel
};

export default BaseCollection.extend({
    initialize: function(models, options) {
        var super_init = BaseCollection.prototype.initialize;

        this.options = options;
        this.model = modelByElementType[options.elementType];

        super_init.apply(this, arguments);
    },
    fetch: function(options) {
        if (options.page && !options.start && options.start !== 0) {
            // Only use the page number if we've not explicitly set a
            // start
            options.rows = options.rows || this.defaultRows;
            options.start = (options.page - 1) * options.rows;
        }

        var self = this;
        var data = {
            item_type: this.options.elementType
        };

        data = _.extend(data, options.data);

        options.start && (data.start = options.start);
        options.rows && (data.rows = options.rows);

        if (options.columnsIds) {
            data.columns = options.columnsIds;
        }

        if (options.sortOn) {
            var attribute = options.sortOn[0].attribute;

            if (options.sortOn[0].modifier) {
                attribute += ':' + options.sortOn[0].modifier;
            }

            data.order_by = attribute + ' ' + options.sortOn[0].order;
        }

        // cancel previous fetch
        if (this.xhr) {
            this.xhr.abort();
        }

        var params = [];
        _.each(data, function(val, key) {
            var encodedVal;

            if (!_.isUndefined(val)) {
                if (_.isArray(val)) {
                    encodedVal = _.map(val, function(item) {
                        return encodeURIComponent(item);
                    }).join(',');
                }
                else {
                    encodedVal = encodeURIComponent(val);
                }

                params.push(encodeURIComponent(key) + '=' + encodedVal);
            }
        });

        this.xhr = $.get(
            '/group_pages?' + params.join('&'),
            function(data, textStatus, request) {
                var rows = _.map(data.rows, function(row) {
                    var nd = {
                        id: row.id,
                        permissions: row.permissions
                    };
                    for (var i = 0; i < data.columns.length; ++i) {
                        nd[data.columns[i]] = row.cells[i];
                    }

                    return new self.model(nd);
                });
                self.reset(rows, {silent: true});

                self.total = parseInt(request.getResponseHeader('Records-Total'), 10);
                self.start = parseInt(request.getResponseHeader('Records-Start'), 10);
                self.rows = parseInt(request.getResponseHeader('Records-Rows'), 10);
                self.totals = data.totals;
                self.columns = data.columns;

                if (options.success) {
                    options.success(self);
                }

                self.xhr = null;
            }
        );
    }
});

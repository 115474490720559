import React from 'react';

import DragAndDropOverlay from 'js/react_views/detail_view_components/drag-and-drop-overlay';

import style from './DropTargetContainer.css';

class DropTargetContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dragOver: false };

        this.bindMethods();
    }

    bindMethods() {
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDragEnter(e) {
        const { onDrop } = this.props;

        if (!onDrop) {
            return;
        }
        // track child elements entered and left during a drag operation
        // as a reliable way to detect dragging over the top-level element
        // http://stackoverflow.com/questions/10253663/how-to-detect-the-dragleave-event-in-firefox-when-dragging-outside-the-window/10310815#10310815
        // http://stackoverflow.com/questions/14194324/firefox-firing-dragleave-when-dragging-over-text
        if (this.dragOverNodes == undefined) {
            this.dragOverNodes = [];
        }
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: true
            });
        }
        // using nativeEvent here is essential. Use of React's SyntheticEvent will result
        // in a failure in Firefox when dragging over text nodes
        this.dragOverNodes = _.union(this.dragOverNodes, [e.nativeEvent.target]);
    }

    handleDragLeave(e) {
        const { onDrop } = this.props;

        if (!onDrop) {
            return;
        }
        this.dragOverNodes = _.without(this.dragOverNodes, e.nativeEvent.target);
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: false
            });
        }
    }

    handleDragOver(e) {
        const { onDrop } = this.props;

        if (!onDrop) {
            return;
        }
        e.preventDefault();
    }

    handleDrop(e) {
        const { onDrop } = this.props;

        if (!onDrop) {
            return;
        }
        e.preventDefault();
        onDrop(e.dataTransfer.files);
        this.setState({
            dragOver: false
        });
        this.dragOverNodes = [];
    }

    render() {
        return (
            <div className={style.DropTargetContainer}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}>

                <div className={style.innerContainer}>
                    {this.props.children}
                </div>
                {this.state.dragOver &&
                    <DragAndDropOverlay />
                }
            </div>
        );
    }
}

export default DropTargetContainer;

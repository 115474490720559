import {ANCHOR_BITFIELD} from './../defs'

export default {
    new_leads_kpi: {
        type: 'kpi_simple',
        params: {
            title: 'New Leads',
            icon: 'icon-users',
            link: {
                event: 'showContactsSection',
                params: {
                    contact_type: 'individuals',
                    filter_rules: [[{
                        field: 'individual_source',
                        operator: 'empty',
                        not: true
                    }], [{
                        field: 'individual_tags',
                        operator: 'equal',
                        values: {
                            id: '${active_funnel.tag_id}',
                            name: '${active_funnel.name}'
                        }
                    }], [{
                        field: 'individual_became_lead',
                        operator: 'more_than',
                        values: '${date.today}'
                    }]]
                }
            },
            auto_refresh: 0.5, // minutes
            on_data_change_event: 'leads:new',
            query: {
                url: '/widgets/num_new_leads',
                args: {
                    tag_ids: '${active_funnel.tag_id}'
                }
            }
        }
    },
    total_leads_kpi: {
        type: 'kpi_simple',
        params: {
            title: 'Total Leads',
            icon: 'icon-users',
            link: {
                event: 'showContactsSection',
                params: {
                    contact_type: 'individuals',
                    filter_rules: [[{
                        field: 'individual_source',
                        operator: 'empty',
                        not: true
                    }], [{
                        field: 'individual_tags',
                        operator: 'equal',
                        values: {
                            id: '${active_funnel.tag_id}',
                            name: '${active_funnel.name}'
                        }
                    }]]
                }
            },
            refresh_on: ['leads:new'],
            query: {
                url: '/widgets/num_total_leads',
                args: {
                    tag_ids: '${active_funnel.tag_id}'
                }
            }
        }
    },
    lead_source_insights: {
        type: 'insights',
        params: {
            title: 'Lead Source Insights',
            refresh_on: ['leads:new', 'funnel:change'],
            query: {
                url: '/widgets/lead_insights',
                args: {
                    tag_ids: '${active_funnel.tag_id}'
                }
            },
            link: {
                event: 'showContactsSection',
                params: {
                    contact_type: 'individuals',
                    filter_rules: [[{
                        field: 'individual_source',
                        operator: 'equal',
                        values: {
                            id: '${item.id}',
                            name: '${item.name}'
                        }
                    }]]
                }
            }
        }
    },
    new_leads_list: {
        type: 'list_simple',
        params: {
            title: 'New Leads',
            item_type: 'lead',
            button: {
                title: 'New Lead',
                event: 'showNewIndividualView',
                params: {
                    leadSource: { system_type: 'unspecified' }
                }
            },
            refresh_on: ['leads:new', 'funnel:change', 'activity:save'],
            query: {
                url: '/leads',
                args: {
                    rows: 20,
                    order_by: 'became_lead_date desc',
                    tag_ids: '${active_funnel.tag_id}',
                    my_team: true,
                    has_activity: false
                }
            }
        },
        editor: {
            initial_size: [280, 440]
        }
    },
    total_tasks_kpi: {
        type: 'kpi_simple',
        params: {
            title: 'Total Tasks',
            icon: 'icon-checkmark3',
            link: {
                event: 'showTasksSection',
                params: {
                    filter_rules: [[{
                        field: 'task_assigned_to_me'
                    }], [{
                        field: 'task_completed',
                        not: true
                    }]]
                }
            },
            refresh_on: ['task:created', 'task:updated'],
            query: {
                url: '/tasks',
                args: {
                    return_count: true,
                    assigned_to_me: true,
                    completed: false
                }
            }
        }
    },
    overdue_tasks_kpi: {
        type: 'kpi_simple',
        params: {
            title: 'Overdue',
            icon: 'icon-warning',
            link: {
                event: 'showTasksSection',
                params: {
                    filter_rules: [[{
                        field: 'task_assigned_to_me'
                    }], [{
                        field: 'task_completed',
                        not: true
                    }], [{
                        field: 'task_due_date',
                        operator: 'less_than',
                        values: '${datetime.today}'
                    }]]
                }
            },
            query: {
                url: '/tasks',
                args: {
                    return_count: true,
                    assigned_to_me: true,
                    overdue: true,
                    completed: false
                }
            },
            refresh_on: ['task:created', 'task:updated']
        }
    },
    task_insights: {
        type: 'insights',
        params: {
            title: 'Task Insights',
            chart_type: 'bar',
            query: {
                url: '/widgets/task_insights'
            },
            refresh_on: ['task:created', 'task:updated'],
            color_by_type: {
                Overdue: 'red',
                Incomplete: 'green'
            },
            link_by_type: {
                Overdue: {
                    event: 'showTasksSection',
                    params: {
                        filter_rules: [[{
                            field: 'task_due_date',
                            operator: 'less_than',
                            values: '${datetime.today}'
                        }], [{
                            field: 'task_completed',
                            not: true
                        }]]
                    }
                },
                Incomplete: {
                    event: 'showTasksSection',
                    params: {
                        filter_rules: [[{
                            field: 'task_completed',
                            not: true
                        }]]
                    }
                }
            }
        }
    },
    tasks_list: {
        type: 'list_simple',
        params: {
            title: 'Tasks',
            item_type: 'task',
            button: {
                title: 'New Task',
                event: 'showQuickAddTaskView'
            },
            query: {
                url: '/tasks',
                args: {
                    rows: 20,
                    assigned_to_me: true,
                    order_by: 'due_date desc'
                }
            },
            refresh_on: ['task:created', 'task:updated']
        },
        editor: {
            initial_size: [280, 440]
        }
    },
    funnel: {
        type: 'panel',
        min_size: [400, 500],
        children: [{
            type: 'funnel',
            position: [20, 20],
            size: [360, 400],
            min_size: [360, 400],
            params: {
                view: 'funnel'
            }
        }, {
            type: 'funnel_colorkey',
            position: [20, 440],
            size: [360, 40]
        }],
        editor: {
            initial_size: [400, 540]
        }
    },
    momentum: {
        type: 'panel',
        min_size: [400, 500],
        children: [{
            type: 'funnel',
            position: [20, 20],
            size: [360, 400],
            min_size: [360, 400],
            params: {
                view: 'sos'
            }
        }, {
            type: 'funnel_colorkey',
            position: [20, 440],
            size: [360, 40]
        }],
        editor: {
            initial_size: [400, 540]
        }
    },
    site_map: {
        type: 'panel',
        min_size: [400, 500],
        children: [{
            type: 'site_map',
            position: [20, 20],
            size: [360, 400],
            min_size: [360, 400],
        }, {
            type: 'funnel_colorkey',
            position: [20, 440],
            size: [360, 40]
        }],
        editor: {
            initial_size: [400, 540]
        }
    },
    task_calendar: {
        type: 'task_calendar',
        min_size: [400, 400],
        editor: {
            initial_size: [400, 400]
        }
    },
    phases_summary: {
        type: 'phases_summary'
    },
    tab_control: {
        type: 'tab_control',
        editor: {
            initial_tabs: ['TabPage1'],
            initial_size: [400, 400]
        }
    },
    panel: {
        type: 'panel',
        editor: {
            initial_size: [300, 400]
        }
    }
};
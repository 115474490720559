import _ from 'underscore'

import Backbone from 'backbone'
import BaseCollection from 'js/collections/base'


/* OpportunityModel and OpportunitiesCollection are co-dependent
 * require them dynamically and set up whichever one loaded first
 * manually.
 */
var OpportunityModel = null,
    OpportunitiesCollection;
try {
    OpportunityModel = require('js/models/opportunity');
} catch (e) {
    require(['js/models/opportunity']);
}
OpportunitiesCollection = BaseCollection.extend({
    model: OpportunityModel,

    getQuotaValues: function() {
        return OpportunitiesCollection.getQuotaValues(this);
    }
});

OpportunitiesCollection.getQuotaValues = function(collection){
    var sumClosed = 0;
    var sumCommitted = 0;

    _.each( collection.models, function(d){
        var phase = d.get('phase'),
            phase_type = phase.phase_type || 'user',
            value = d.get( 'value' ),
            forecast_status = d.get('status');

        if ( phase_type === 'won' ) {
            sumClosed += value;
        }

        if ( forecast_status === 'committed' || forecast_status === 'committed_downside' ||
             phase_type === 'won' ) {
            sumCommitted += value;
        }
    });

    return {
        closed: sumClosed,
        committed: sumCommitted
    };
};

export default OpportunitiesCollection;

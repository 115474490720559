import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import ModalRegion from 'js/views/base/modal-region'
import MessageBox from 'js/views/message_box'
import app from 'js/app'
import vent from 'js/vent'
import TagModel from 'js/models/tag'
import TagsCollection from 'js/collections/tags'
import tagsTemplate from 'templates/settings/tags.handlebars'
import tagItemTemplate from 'templates/settings/tag_item_view.handlebars'
import addNewTagTamplate from 'templates/settings/add_new_tag.handlebars'


var TagItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(tagItemTemplate),
    ui: {
        name: '.name',
        editName: '.edit-name',
        editIcon: '.icon-pencil',
        nameContainer: '.name-container',
        tooltips: '[data-toggle=tooltip]',
        errorMessage: '.error-message',
        errorMessageContainer: '.error-message-container',
        deleteItem: '.delete-item',
        editItem: '.edit-item'
    },
    attributes: function() {
        return {
            cid: this.model.cid
        };
    },
    events: {
        'click .delete-item': function() {
            var view = this,
                mbContent = {
                    message: Handlebars.compile(
                        'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours.</strong>' +
                        `Are you sure you want to delete this tag?<strong class="cta">${_.escape(this.model.get('name'))}</strong>` +
                        'Type in the tag name to proceed.If unsure, please contact support.'
                    ),
                    icon: 'icon-warning',
                    accept_is_negative: true,
                    accept_button_text: 'Delete',
                    cancel_button_text: 'Cancel'
                };

            this.ui.tooltips.tooltip('hide');

            MessageBox.showYesNo(
                mbContent,
                this,
                function(name) { // yes
                    if (name === view.model.get('name')) {
                        view.$el.fadeOut(400, function() {
                            view.ui.tooltips.tooltip('destroy');
                            view.trigger('tag:destroy', view.model);
                        });
                    }
                },
                function() {}, // no
                null,
                { prompt: {
                    type: 'input',
                    conditional_accept_button_shown: view.model.get('name')
                }}
            );
        }
    },
    onRender: function() {
        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });

        // ...
        var self = this;

        function hideEdit() {
            self.ui.deleteItem.show();
            self.ui.editItem.show();

            self.ui.nameContainer.removeClass('editing');
        }

        function hideError() {
            self.ui.errorMessageContainer.hide();
        }

        function showError(message) {
            self.ui.errorMessage
                .addClass('validation_error')
                .text(message);

            self.ui.errorMessageContainer.show();
        }

        this.ui.editIcon.on('click', function() {
            self.ui.deleteItem.hide();
            self.ui.editItem.hide();

            self.ui.nameContainer.addClass('editing');
            self.ui.editName.val(self.model.get('name'));
            self.ui.editName.focus();
        });

        self.ui.editName.on('change', function() {
            var name = self.ui.editName.val().trim();
            var message = null;

            if (name === '') {
                message = 'A tag name is required';
            }
            else if (self.options.tagsCollection.find(function(m) {
                return m.get('name') === name;
            })) {
                message = 'This tag name is already in use';
            }

            if (message) {
                showError(message);
            }
            else {
                hideError();

                self.model.save({'name': name}, {
                    patch: true,
                    alert: false,
                    wait: true,
                    success: function(model, attributes, settings) {
                        self.trigger('tag:rename');
                        vent.trigger('alert:show', { type: 'save', model: model, xhr: settings.xhr });
                        self.ui.name.text(name);
                        hideEdit();
                    },
                    error: function(model, xhr) {
                        var detail = JSON.parse(xhr.responseText).detail;

                        if (detail.exception === 'DuplicatedNameError') {
                            showError('This tag name is already in use');
                        }
                        else {
                            showError(detail.message);
                        }
                    }
                });
            }
        });

        self.ui.editName.on('blur', function() {
            hideError();
            hideEdit();
        });
    }
});

var TagsList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'tags-list editable-list',
    itemView: TagItemView,
    itemViewOptions: function() {
        return {
            tagsCollection: this.collection
        };
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: true,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var cid = $(ui.item).attr('cid');
                var model = self.collection.get(cid);
                var child = self.children.findByModel(model);
                var newOrder = self.$el.children().index(child.$el);

                if (model.get('order') !== newOrder) {
                    var attrs = {
                        order: newOrder
                    };

                    model.save(attrs, {
                        patch: true,
                        wait: true,
                        success: function() {
                            self.trigger('change:order');
                        }
                    });
                }
            }
        });

        this.listenTo(this, 'itemview:tag:destroy', function(itemView, model) {
            model.destroy({
                wait: true,
                success: function() {
                    self.trigger('fetch-tags', true);
                }
            });
        });

        this.listenTo(this, 'itemview:tag:rename', function() {
            self.trigger('fetch-tags', true);
        });
    }
});

var NewTagView = Marionette.Layout.extend({
    className: 'new-tag edit-form-modal',
    template: Handlebars.compile(addNewTagTamplate),
    ui: {
        name: '#tag-name',
        errorMessage: '.error-message'
    },
    events: {
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('cancel');
        },
        'keypress input[type="text"]': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': function(ev) {
            ev.preventDefault();
            this.save();
        }
    },
    onShow: function() {
        this.ui.name.focus();
    },
    initialize: function(options) {
        this.loading = false;
    },
    save: function() {
        if (this.loading) {
            return;
        }
        this.loading = true;

        this.ui.name.removeClass('validation_error');
        this.ui.errorMessage.hide();

        var name = this.ui.name.val().trim();
        var message = null;

        if (name === '') {
            message = 'A tag name is required';
        }
        else if (this.options.tagsCollection.find(function(m) {
            return m.get('name') === name;
        })) {
            message = 'This tag name is already in use';
        }

        if (message) {
            this.showErrorMessage(message);
            this.loading = false;
        }
        else {
            var tag = new TagModel({name: name});
            var self = this;

            tag.save({}, {
                alert: false,
                success: function(model, attributes, settings) {
                    self.loading = false;

                    vent.trigger('alert:show', { type: 'save', model: model, xhr: settings.xhr });
                    self.trigger('tag:new');
                },
                error: function(model, xhr) {
                    self.loading = false;

                    var detail = JSON.parse(xhr.responseText).detail;

                    if (detail.exception === 'DuplicatedNameError') {
                        self.showErrorMessage('This tag name is already in use');
                    }
                    else {
                        self.showErrorMessage(detail.message);
                    }
                }
            });
        }
    },
    showErrorMessage: function(message) {
        this.ui.name
            .addClass('validation_error')
            .nextAll('.error-message')
            .text(message)
            .addClass('invalid');

        this.ui.errorMessage.show();
    }
});

export default Marionette.Layout.extend({
    className: 'tags-settings',
    template: Handlebars.compile(tagsTemplate),
    ui: {
        contentContainer: '.content-container'
    },
    regions: {
        tagsList: '.tags-list-container',
        addNewTag: {
            selector: '.new-tag',
            regionType: ModalRegion
        }
    },
    events: {
        'click .new-tag': function() {
            var view = new NewTagView({
                tagsCollection: this.tags
            });

            this.listenTo(view, 'cancel', function() {
                this.addNewTag.reset();
            });

            this.listenTo(view, 'tag:new', function() {
                this.addNewTag.reset();
                this.fetchTags(true);
            });

            this.addNewTag.show(view);
        }
    },
    initialize: function(options) {
        this.tags = new TagsCollection();
    },
    onRender: function() {
        this.fetchTags();
    },
    fetchTags: function(cacheIt) {
        var self = this;

        this.tags.fetch({
            rows: -1,
            success: function() {
                var view = new TagsList({collection: self.tags});
                self.tagsList.show(view);
                self.$el.find('.content-container').nanoScroller();

                self.listenTo(view, 'fetch-tags change:order', function(cacheIt) {
                    self.fetchTags(cacheIt);
                });

                if (cacheIt) {
                    app.globalData.tags = self.tags.toJSON();
                }
            }
        });
    }
});

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'

import Marionette from 'Backbone.Marionette'
import app from 'js/app.js'
import vent from 'js/vent.js'
import currencyFormat from 'js/utils/currency_format.js'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import braintreeCountryCodes from 'js/utils/braintree_country_codes.js'
import LoadingView from 'js/views/loading.js'
import subscriptionSettingsTemplate from 'templates/settings/subscription_settings.handlebars'
import subscriptionDetailTemplate from 'templates/settings/subscription_detail.handlebars'


/**
* This view shows the billing address details. This is a dumb view that just renders the information received
* on creation time.
*/
var SubscriptionDetailView = Marionette.ItemView.extend({
    template: Handlebars.compile( subscriptionDetailTemplate ),
    events: {
        'change .currency-container': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.onCurrencyChange();
        },
        'change .subscription-plan-container': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.trigger('subscription-plan-change', this.subscriptionPlanSelect.$el.select2('val'));
        }
    },
    ui: {
        subscriptionInfo: '.subscription-info'
    },
    initialize: function() {
        var currency = this.model.get('currency');
        var discount_users = this.model.get('discount_users');
        var price_per_user = this.model.get('price_per_user');
        var vat_subtotal = this.model.get('vat_subtotal');
        var vat_amount = this.model.get('vat_amount');
        var total = this.model.get('total');

        this.model.set('show_discount_users', (discount_users > 0));
        this.model.set('price_per_user_formatted', currencyFormat(currency, price_per_user));
        this.model.set('vat_subtotal_formatted', currencyFormat(currency, vat_subtotal));
        this.model.set('vat_amount_formatted', currencyFormat(currency, vat_amount));
        this.model.set('total_formatted', currencyFormat(currency, total));
    },
    onRender: function() {
        this.currencySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.$el.find('.currency-container'),
            text: 'name',
            data: [{
                id: 'USD',
                name: 'USD'
            }, {
                id: 'GBP',
                name: 'GBP'
            }, {
                id: 'EUR',
                name: 'EUR'
            }],

            options: {
                placeholder: 'Select a currency',
                containerCssClass: 'select2-form-field',
                dropdownCssClass: 'individual-select-popover popover'
            }
        });

        this.currencySelect.$el.select2('val', this.model.get('currency'));

        var tiers = [{
            id: 'growing',
            name: 'Professional'
        }];

        var activeTier = this.model.get('subscription_tier');

        if (activeTier === 'starting') {
            tiers.unshift({
                id: 'starting',
                name: 'Basic'
            });
        }

        if (activeTier === 'enterprise') {
            tiers.push({
                id: 'enterprise',
                name: 'Enterprise'
            });
        }

        this.subscriptionPlanSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.$el.find('.subscription-plan-container'),
            text: 'name',
            value: activeTier,
            data: tiers,
            options: {
                placeholder: 'Select a plan',
                containerCssClass: 'select2-form-field',
                dropdownCssClass: 'individual-select-popover popover'
            }
        });

        if (this.model.get('lock_plan')) {
            this.subscriptionPlanSelect.$el.select2('disable');
            this.currencySelect.$el.select2('disable');
            this.ui.subscriptionInfo.text('Your subscription has custom properties, please contact us for any modification.');
        }
        else if (activeTier !== 'enterprise') {
            this.ui.subscriptionInfo.text('If you want to upgrade to the Enterprise Plan please contact us.');
        }
    },
    onCurrencyChange: function() {
        this.trigger('currency-change', {
            'currency': this.$el.find('td.currency-container').val()
        });
    }
});


/**
 * This view controls the settings panel when displaying the billing address. This view is responsible for the
 * communication with the server and rendering the BillingFormView with the data received. This view is as well
 * responsible of sending the information to the server on updating.
 */
var SubscriptionSettingsView = Marionette.Layout.extend({
    id: 'billing-address-settings',
    className: 'settings-detail at-top',
    template: Handlebars.compile( subscriptionSettingsTemplate ),
    ui: {
        contentContainer: '.detail-content.content-container'
    },
    regions: {
        loadingRegion: '.loading-container',
        subscriptionDetailRegion: '.subscription-detail-container'
    },
    initialize: function() {
        this.model = new Backbone.Model({
            subscription_details: null,
            loading: false
        });
    },
    onRender: function() {
        var view = this;

        // TODO: Fetch details
        $.ajax({
            method: 'GET',
            url: '/subscription_details',
            dataType: 'json',
            success: function(data) {
                view.model.set({
                    'subscription_details': data,
                    'loading': false
                });
                view.update();
            },
            error: function() {
                view.ui.contentContainer.hide();

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There was an error processing this request',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    update: function() {
        if (this.isClosed) {
            return;
        }
        // Show the loading view if we are loading data
        if (this.model.get('loading') === true) {
            this.loadingRegion.show(new LoadingView());
        } else {
            this.loadingRegion.reset();
        }

        // Show the details if we are not loading data
        if (this.model.get('loading') === false) {
            var detailView = new SubscriptionDetailView({
                model: new Backbone.Model(this.model.get('subscription_details'))
            });
            this.listenTo(detailView, 'currency-change', function (data) {
                this.changeValue('currency', data.currency);
            });
            this.listenTo(detailView, 'subscription-plan-change', function(plan) {
                this.changeValue('subscription_tier', plan, function() {
                    app.user.get('client').feature_tier = plan;
                });
            });
            this.subscriptionDetailRegion.show(detailView);
        } else {
            this.subscriptionDetailRegion.reset();
        }

        // Update scrollbars
        this.scrollbar();
    },
    changeValue: function(name, value, successCallback) {
        var view = this;
        var data = {};
        data[name] = value;

        $.ajax({
            method: 'PATCH',
            url: '/subscription_details',
            processData: false,
            contentType: 'application/json',
            data: JSON.stringify(data),
            dataType: 'json',
            success: function(data) {
                view.model.set({
                    'subscription_details': data,
                    'loading': false
                });
                view.update();

                if (successCallback) {
                    successCallback();
                }
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        this.$('.content-container').nanoScroller();
    }, 100)
});


export default SubscriptionSettingsView;

import GroupModel from 'js/models/group'
import app from 'js/app'


export default GroupModel.extend({
    name: 'Recent',
    doNotPersistSort: true,
    url: function() {
        return app.user.url() + '/recent_organizations';
    }
});

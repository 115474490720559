import BaseModel from 'js/models/base'
import AppConfig from 'app/app-config'

var Preferences = BaseModel.extend({
    name: 'Preferences',
    urlRoot: '/preferences',
    url: function () {
        return '/preferences';
    }
});

Preferences.roles = {
    'team': {id: 'team', title: 'Team'},
    'sales_executive': {id: 'sales_executive', title: 'Sales Executive'},
    'sales_manager': {id: 'sales_manager', title: 'Sales Manager'},
    'telesales': {id: 'telesales', title: 'Telesales'},
    'marketing': {id: 'marketing', title: 'Marketing'},
    'ceo': {id: 'ceo', title: 'CEO'},
    'new-team': {id: 'new-team', title: 'New Team'},
    'team-beta': {id: 'team-beta', title: 'Team Beta'},
    'sales': {id: 'sales', title: 'Sales'},
};

if (AppConfig.getValue('enableIntentDashboard')) {
    Preferences.roles['intent'] = {
        id: 'intent',
        title: 'Intent'
    };
}

export default Preferences;

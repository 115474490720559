import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import TextManager from 'app/text-manager';
import {TextField} from 'js/react_views/common_components/common';
import {NewSelect, TagSelect} from 'js/react_views/widgets/select';
import dateFormat from 'js/utils/date-format'
import AppConfig from 'app/app-config';
import SeeBasicTemplates from 'js/react_views/see/see_basic_templates';
import ZipImporter from './zip_importer';
import MessageBox from 'js/views/message_box';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './campaign_creation.css';

class HorCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: !!props.active
        };
    }

    setActive(active) {
        this.setState({
            active: active
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.sHorCard}
                    ${this.state.active ? style.sActive : ''}
                `}
                onClick={this.props.onClick}
            >
                <div className={style.sIconContainer}>
                    <div className={`
                        ${style.icIcon}
                        ${this.props.icon}
                    `}/>
                </div>

                <div className={style.sTextContainer}>
                    <div className={style.tcTitle}>{this.props.title}</div>
                    <div className={style.tcDescription}>{this.props.description}</div>
                </div>
            </div>
        );
    }
}

class HorCardContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIdx: props.activeIdx
        };
    }

    handleCardSelect(cardIdx) {
        this.cardsComponents[this.state.activeIdx].setActive(false);
        this.cardsComponents[cardIdx].setActive(true);

        this.setState({
            activeIdx: cardIdx
        });

        this.props.onCardSelected(cardIdx);
    }

    render() {
        this.cardsComponents = [];

        return (
            <div
                className={`
                    ${style.sHorCardContainer}
                    ${this.props.style === 'vertical' ? style.sVScroll : ''}
                `}
            >
                {this.props.cards.map((card, cidx) => {
                    return (
                        <HorCard
                            ref={(el) => { if (el) { this.cardsComponents.push(el) }}}
                            key={`hcard_${cidx}`}
                            title={card.title}
                            description={card.description}
                            icon={card.icon}
                            active={cidx === this.state.activeIdx}
                            onClick={() => this.handleCardSelect.bind(this)(cidx)}
                        />
                    );
                })}
            </div>
        );
    }
}

class BasicTemplateCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: !!props.active
        };
    }

    setActive(active) {
        this.setState({
            active: active
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.sBasicTemplateCard}
                    ${this.state.active ? style.bActive : ''}
                `}
                onClick={this.props.onClick}
            >
                <div
                    className={style.bImage}
                    style={{
                        backgroundImage: `url(img/${this.props.data.img})`
                    }}
                />

                <div
                    className={style.bName}
                    title={this.props.data.name}
                >
                    {this.props.data.name}
                </div>
            </div>
        );
    }
}

class VerCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: !!props.active
        };
    }

    setActive(active) {
        this.setState({
            active: active
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.sVerCard}
                    ${this.state.active ? style.vActive : ''}
                `}
                onClick={this.props.onClick}
            >
                <div className={style.vIconContainer}>
                    <div className={`
                        ${style.vicIcon}
                        icon-user-template
                    `}/>
                </div>

                <div
                    className={style.vName}
                    title={this.props.data.name}
                >
                    {this.props.data.name}
                </div>

                <div className={style.vInfo}>
                    Created by {this.props.data.creator}
                </div>

                <div className={style.vInfo}>
                    On {dateFormat.formatDDMMYYYYDate(this.props.data.created)}, {dateFormat.shortFormatTime(this.props.data.created, true)}
                </div>
            </div>
        );
    }
}

class VerCardContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIdx: props.activeIdx,
            loading: false
        };
    }

    setLoading(loading) {
        this.setState({
            loading: loading
        });
    }

    handleCardSelect(cardIdx) {
        this.cardsComponents[this.state.activeIdx].setActive(false);
        this.cardsComponents[cardIdx].setActive(true);

        this.setState({
            activeIdx: cardIdx
        });

        this.props.onCardSelected(cardIdx);
    }

    render() {
        const CardComponent = this.props.cardComponent || VerCard;

        this.cardsComponents = [];

        return (
            <div
                className={style.sVerCardContainer}
                style={{justifyContent: this.props.cardComponent ? 'space-evenly' : 'normal'}}
            >
                {!this.state.loading && this.props.cards.map((card, cidx) => {
                    return (
                        <CardComponent
                            ref={(el) => { if (el) { this.cardsComponents.push(el) }}}
                            key={`hcard_${cidx}`}
                            data={card}
                            active={cidx === this.state.activeIdx}
                            onClick={() => this.handleCardSelect.bind(this)(cidx)}
                        />
                    );
                })}

                {!this.state.loading && this.props.cards.length === 0 &&
                    <div className={style.vEmptyMessage}>
                        {this.props.emptyMessage || 'There are not templates to select'}
                    </div>
                }

                {this.state.loading &&
                    <LoadingIndicator/>
                }
            </div>
        );
    }
}

class StepImportTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dragOver: false
        };
    }

    handleFileDrop(ev) {
        ev.stopPropagation();
        ev.preventDefault();

        this.processZipFile(ev.dataTransfer.files);

        this.setState({
            dragOver: false
        });
    }

    handleDragLeave(ev) {
        if (ev.currentTarget.contains(ev.relatedTarget)) {
            return;
        }

        this.setState({
            dragOver: false
        });
    }

    handleUploadFileClick() {
        $(this.fileSelectInput).click();
    }

    handleFileSelectDialog() {
        this.processZipFile(this.fileSelectInput.files);
    }

    processZipFile(files) {
        const file = files[0];

        if (!file) {
            return;
        }

        if (!this.zipImporter) {
            this.zipImporter = new ZipImporter(this.props.parent);
        }

        const self = this;

        if (this.zipImporter.import(file, this.props.campaignData.name, function(htmlContent) {
            if (htmlContent === null) { // error
                return;
            }

            // todo:
            // self.props.updateCampaignDataObj({
            //     content: htmlContent,
            //     see_config: null // to force the html view
            // });
            // self.props.gotoNextStep();
        }));
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <div
                    className={style.bTitle}
                    style={{marginBottom: '30px'}}
                >
                    Import an email template
                </div>

                <div
                    className={style.sFileUpload}
                    onDragEnter={() => this.setState({dragOver: true})}
                    onDragLeave={this.handleDragLeave.bind(this)}
                    onDragOver={(ev) => ev.preventDefault()}
                    onDrop={this.handleFileDrop.bind(this)}
                >
                    <div>Drag & drop files here</div>
                    <div style={{color: 'grey', margin: '0 12px'}}>or</div>
                    <div
                        className={style.fuLink}
                        onClick={this.handleUploadFileClick.bind(this)}
                    >
                        Upload from your Computer
                    </div>

                    <input
                        ref={(input) => {this.fileSelectInput = input}}
                        onChange={this.handleFileSelectDialog.bind(this)}
                        style={{display: 'none'}}
                        type='file'
                    />

                    {this.state.dragOver &&
                        <div className={style.fuOverlay}/>
                    }
                </div>
            </div>
        );
    }
}

class StepChooseBasicTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.activeTemplateIdx = 0;
    }

    componentDidMount() {
        this.handleTemplateSelect(0);
    }

    handleTemplateSelect(templateIdx) {
        this.activeTemplateIdx = templateIdx;

        const template = SeeBasicTemplates[this.activeTemplateIdx];

        this.props.onDataChangeObj({
            content: template.content,
            subject: '',
            see_config: template.see_config
        });
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <div
                    className={style.bTitle}
                    style={{marginBottom: '30px'}}
                >
                    Select a basic template
                </div>

                <VerCardContainer
                    cards={SeeBasicTemplates}
                    activeIdx={0}
                    cardComponent={BasicTemplateCard}
                    onCardSelected={this.handleTemplateSelect.bind(this)}
                />
            </div>
        );
    }
}

class StepChooseUserTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.allTemplates = [];
        this.activeCategory = props.templateCategories.length === 1 ? props.templateCategories[0].id : null;

        this.state = {
            templates: []
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchTemplates();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchTemplates() {
        const args = {
            rows: -1,
            order_by: 'modified desc',
            campaign_type: 'campaign',
            campaign_subtype: 'template',
            status: 'ready'
        };

        this.cardContainer.setLoading(true);

        const self = this;

        $.get(`/campaigns?${$.param(args)}`, function(result) {
            if (!self.mounted) {
                return;
            }

            self.cardContainer.setLoading(false);

            self.allTemplates = result.map(t => {
                return {
                    id: t.id,
                    name: t.name,
                    creator: t.creator.name,
                    created: t.created,
                    data: t
                };
            });

            self.filterTemplatesByCategory();
        });
    }

    filterTemplatesByCategory() {
        let templates = [];

        if (!this.activeCategory) {
            templates = [];
        } else {
            templates = this.allTemplates.filter(t => t.data.see_config.templateCategory === this.activeCategory);
        }

        this.setState({
            templates: templates
        });

        if (templates.length > 0) {
            const self = this;

            _.defer(function() {
                self.handleTemplateSelect(0);
            });
        }
    }

    handleCategoryChange(items) {
        this.activeCategory = items ? items[0].id : null;
        this.filterTemplatesByCategory();
    }

    handleTemplateSelect(templateIdx) {
        const template = this.state.templates[templateIdx];

        if (template) {
            this.props.onDataChangeObj({
                content: template.data.content,
                subject: template.data.subject || '',
                see_config: template.data.see_config
            });
        } else {
            this.props.onDataChangeObj({
                content: '',
                subject: '',
                see_config: null
            });
        }
    }

    render() {
        const activeCategory = this.props.templateCategories.length === 1 ? this.props.templateCategories[0] : null;

        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <div className={style.sSection}>
                        <div className={`${style.bTitle} ${style.tCenter}`}>
                            Select a {TextManager.getText('ID_CAMPAIGN_CATEGORY')}
                        </div>

                        <div
                            style={{
                                background: 'white',
                                width: '100%',
                                marginTop: '20px'
                            }}
                        >
                            <NewSelect
                                value={null}
                                data={this.props.templateCategories}
                                value={activeCategory}
                                options={{
                                    minimumInputLength: -1,
                                    allowClear: true
                                }}
                                width={250}
                                placeholder={`Select a ${TextManager.getText('ID_CAMPAIGN_CATEGORY')}`}
                                onSelect={this.handleCategoryChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: '50px',
                        overflow: 'hidden'
                    }}
                >
                    <div
                        className={`${style.bTitle} ${style.tCenter}`}
                        style={{marginBottom: '30px'}}
                    >
                        Select an email template
                    </div>

                    <div
                        style={{
                            height: 'calc(100% - 55px)'
                        }}
                    >
                        <VerCardContainer
                            ref={(el) => this.cardContainer = el}
                            cards={this.state.templates}
                            activeIdx={0}
                            emptyMessage='There are not user templates to select'
                            onCardSelected={this.handleTemplateSelect.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class StepChooseCampaignOption extends React.Component {
    constructor(props) {
        super(props);

        this.campaignOptions = [{
            id: 'userTemplate',
            icon: 'icon-user-template',
            title: 'Email Templates',
            description: 'Create an email using existing templates'
        }];

        if (props.userType === 'advance') {
            if (AppConfig.getValue('campaigns.enable_import_zip_files')) {
                this.campaignOptions.push({
                    id: 'importTemplate',
                    icon: 'icon-zip-upload',
                    title: 'Import Email via ZIP',
                    description: 'Import your HTML email template + images via ZIP to edit HTML only'
                });
            }

            this.campaignOptions = [...this.campaignOptions, ...[{
                    id: 'basicTemplate',
                    icon: 'icon-basic-template',
                    title: 'Basic Template',
                    description: 'Get started quickly with a simple blank template'
                }, {
                    id: 'blankCanvas',
                    icon: 'icon-blank-canvas',
                    title: 'Blank Canvas',
                    description: 'Build your email from scratch with the drag and drop editor'
                }]
            ];
        }

        this.state = {
            campaignOptionIdx: 0
        };
    }

    handleCampaignOptionSelect(idx) {
        const campaignOption = this.campaignOptions[idx];

        switch (campaignOption.id) {
            case 'blankCanvas':
                this.props.onDataChangeObj({
                    content: '',
                    subject: '',
                    see_config: {
                        dummy: '' // this is to force to show the SEE editor in the step_compose, because if see_config is null we are showing the html editor
                    }
                });
                break;

            case 'userTemplate':
            case 'importTemplate':
                this.props.onDataChangeObj({
                    content: '',
                    subject: '',
                    see_config: null
                });
                break;
        }

        this.setState({
            campaignOptionIdx: idx
        });
    }

    isDataValid() {
        const campaignOption = this.campaignOptions[this.state.campaignOptionIdx];

        if (campaignOption.id === 'userTemplate') {
            if (!this.props.campaignData.see_config) {
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'You have to select a template'
                }, this.props.parent);

                return false;
            }
        }

        return true;
    }

    renderCampaignOption() {
        const campaignOption = this.campaignOptions[this.state.campaignOptionIdx];

        switch (campaignOption.id) {
            case 'userTemplate':
                return (
                    <StepChooseUserTemplate
                        templateCategories={this.props.templateCategories}
                        onDataChange={this.props.onDataChange}
                        onDataChangeObj={this.props.onDataChangeObj}
                    />
                );

            case 'basicTemplate':
                return (
                    <StepChooseBasicTemplate
                        onDataChangeObj={this.props.onDataChangeObj}
                    />
                );

            case 'importTemplate':
                return (
                    <StepImportTemplate
                        campaignData={this.props.campaignData}
                        parent={this.props.parent}
                    />
                );

            case 'blankCanvas':
                return (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <div className={style.bTitle}>
                            Click next
                        </div>
                    </div>
                );
        }

        return null;
    }

    render() {
        return (
            <div className={style.cStep}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <div
                        className={style.sBlock}
                        style={{
                            width: 'calc((100% - 40px) / 3)',
                            paddingBottom: '30px'
                        }}
                    >
                        <div
                            className={style.bTitle}
                            style={{marginBottom: '30px'}}
                        >
                            Select an email template
                        </div>

                        <HorCardContainer
                            cards={this.campaignOptions}
                            activeIdx={this.state.campaignOptionIdx}
                            style='vertical'
                            onCardSelected={this.handleCampaignOptionSelect.bind(this)}
                        />
                    </div>

                    <div
                        className={style.sBlock}
                        style={{
                            width: 'calc((((100% - 40px) / 3) + 10px) * 2)',
                            overflow: 'hidden'
                        }}
                    >
                        {this.renderCampaignOption()}
                    </div>
                </div>
            </div>
        );
    }
}

class StepChooseCampaign extends React.Component {
    constructor(props) {
        super(props);

        this.campaignTypes = [{
            id: 'campaign',
            icon: 'icon-campaign-email',
            title: 'Campaign Email',
            description: 'Send a rich HTML Campaign to one of your Mailing Lists'
        }];

        if (this.props.userType === 'advance') {
            this.campaignTypes.push({
                id: 'direct',
                icon: 'icon-direct-email',
                title: 'Direct Email',
                description: 'Send a text-based email to all the members of any group'
            });
        }

        if (this.props.userType === 'advance' && AppConfig.getValue('enableTextMessaging')) {
            this.campaignTypes.push({
                id: 'message',
                icon: 'icon-campaign-sms',
                title: 'Text Message',
                description: 'Send a SMS message to all contacts of any group'
            });
        }

        this.state = {
            campaignTypeIdx: Math.max(this.campaignTypes.findIndex(ct => ct.id === this.props.campaignData.campaign_type), 0),
            errors: {}
        };

        this.tags = this.props.campaignData?.tags || [];
    }

    componentDidMount() {
        this.mounted = true;
        this.nameComponent.focus();
        this.props.onDataChange('campaign_type', this.campaignTypes[this.state.campaignTypeIdx].id);

        this.setState({
            campaignTypeIdx: this.state.campaignTypeIdx
        });

        const showRegionAndDevelopment = AppConfig.getValue('campaigns.step_choose.show_region_and_development', false);

        if (showRegionAndDevelopment) {
            this.regions = null;
            this.developmentsByRegion = null;

            let initialRegionId = null;
            let initialFunnelId = null;

            const self = this;

            const checkReady = () => {
                if (!self.mounted || self.regions === null || self.developmentsByRegion === null) {
                    return;
                }

                const activeRegionId = initialRegionId || self.regions[0]?.id;
                const developments = self.developmentsByRegion[activeRegionId] || [];

                self.regionComponent.setData(self.regions, activeRegionId);
                self.developmentComponent.setData(developments, initialFunnelId || developments[0]?.id);
            }

            self.regions = app.globalData.regions.map(region => {
                return {
                    id: region.id,
                    name: region.name
                };
            });

            $.get('/funnels?is_archive=false', function(data) {
                self.developmentsByRegion = {};

                const funnelId = self.props.campaignData.funnel_id;

                for (const funnel of data) {
                    const regionId = funnel.region_id;

                    if (!regionId) {
                        continue;
                    }

                    if (!(regionId in self.developmentsByRegion)) {
                        self.developmentsByRegion[regionId] = [];
                    }

                    if (funnelId === funnel.id) {
                        initialFunnelId = funnelId;
                        initialRegionId = regionId;
                    }

                    self.developmentsByRegion[regionId].push({
                        id: funnel.id,
                        name: funnel.name,
                        regionId: regionId
                    });
                }

                checkReady();
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleCampaignSelect(cardIdx) {
        this.setState({
            campaignTypeIdx: cardIdx
        });

        this.props.onDataChange('campaign_type', this.campaignTypes[cardIdx].id);
        this.props.resetProcess();
    }

    handleRegionChange(items) {
        const region = items[0];
        const developments = this.developmentsByRegion[region.id];

        this.developmentComponent.setData(developments, developments?.length > 0 ? developments[0].id : null);
    }

    handleDevelopmentChange(items) {
        this.props.onDataChange('funnel_id', items[0].id);

        const tag = app.globalData.tags.find(t => t.name.toLowerCase() === items[0].name.toLowerCase());

        if (tag) {
            this.tags = [_.clone(tag)];
        } else {
            this.tags = [];
        }

        const currentTags = this.props.campaignData.tags;

        if (!currentTags || (this.tags.length !== currentTags.length) || (this.tags.length > 0 && this.tags[0].id !== currentTags[0].id)) {
            this.props.onDataChange('tags', this.tags);
        }
    }

    handleTagsChange(items) {
        this.tags = _.clone(items);
        this.props.onDataChange('tags', this.tags);
    }

    getData() {
        const name = this.nameComponent.getValue();
        let errors = {};

        if (!name) {
            errors.name = 'Campaign name required';
        }

        this.setState({
            errors: errors
        });

        if (!_.isEmpty(errors)) {
            return null;
        }

        const campaignType = this.campaignTypes[this.state.campaignTypeIdx].id;

        let data = {
            name: name,
            tags: this.tags,
            campaignType: campaignType
        };

        return data;
    }

    render() {
        const showRegionAndDevelopment = (this.props.campaignData.campaign_subtype === 'campaign') && AppConfig.getValue('campaigns.step_choose.show_region_and_development', false);

        return (
            <div className={style.cStep}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <div
                        className={style.sBlock}
                        style={{
                            width: 'calc((100% - 40px) / 3)',
                            overflow: 'auto'
                        }}
                    >
                        <div className={style.bTitle}>Name your campaign</div>

                        <div
                            className={style.bDescription}
                            style={{marginTop: '20px', marginBottom: '10px'}}
                        >
                            This name will appear on internal insights, reports and templates
                        </div>

                        <TextField
                            ref={(el) => this.nameComponent = el}
                            labelWidth={0}
                            placeholder='Enter Campaign Name'
                            value={this.props.campaignData?.name || ''}
                            onValueChange={(_, value) => this.props.onDataChange('name', value)}
                            fullInputBox={true}
                            inputExtraClasses={style.inputField}
                            error={this.state.errors.name}
                        />

                        <div
                            className={style.bTitle}
                            style={{
                                display: showRegionAndDevelopment ? 'none' : 'block',
                                marginTop: '50px',
                                marginBottom: '20px'
                            }}
                        >
                            Tags
                        </div>

                        <div
                            className={style.bDescription}
                            style={{
                                display: showRegionAndDevelopment ? 'none' : 'block',
                                marginBottom: '20px'
                            }}
                        >
                            What tags do you wish to use for your Campaign?
                        </div>

                        <div style={{
                            display: showRegionAndDevelopment ? 'none' : 'block',
                            background: 'white',
                            width: '100%'
                        }}>
                            <TagSelect
                                ref={(el) => this.tagsComponent = el}
                                value={null}
                                url='/tags'
                                text='name'
                                value={this.tags}
                                width={245}
                                placeholder='+ Add Tag'
                                editViewMod={false}
                                onChange={this.handleTagsChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        className={style.sBlock}
                        style={{width: 'calc((((100% - 40px) / 3) + 10px) * 2)'}}
                    >
                        <div
                            className={style.bTitle}
                            style={{marginBottom: '20px'}}
                        >
                            Select a campaign type
                        </div>

                        <div
                            style={{width: '100%'}}
                        >
                            <HorCardContainer
                                cards={this.campaignTypes}
                                activeIdx={this.state.campaignTypeIdx}
                                onCardSelected={this.handleCampaignSelect.bind(this)}
                            />
                        </div>

                        <div
                            style={{
                                display: showRegionAndDevelopment ? 'flex' : 'none',
                                width: '100%',
                                justifyContent: 'center',
                                marginTop: '70px'
                            }}
                        >
                            <div
                                className={style.sSection}
                                style={{width: '50%'}}
                            >
                                <div className={`${style.bTitle} ${style.tCenter}`}>
                                    Select a region
                                </div>

                                <div
                                    style={{
                                        background: 'white',
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                >
                                    <NewSelect
                                        ref={(el) => {this.regionComponent = el}}
                                        value={null}
                                        data={[]}
                                        options={{minimumInputLength: -1}}
                                        width={250}
                                        text='name'
                                        placeholder='Select a region'
                                        onSelect={this.handleRegionChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div
                                className={style.sSection}
                                style={{width: '50%'}}
                            >
                                <div className={`${style.bTitle} ${style.tCenter}`}>
                                    Select a development
                                </div>

                                <div
                                    style={{
                                        background: 'white',
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                >
                                    <NewSelect
                                        ref={(el) => {this.developmentComponent = el}}
                                        value={null}
                                        data={[]}
                                        options={{minimumInputLength: -1}}
                                        width={250}
                                        text='name'
                                        placeholder='Select a development'
                                        onSelect={this.handleDevelopmentChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class StepChoose extends React.Component {
    constructor(props) {
        super(props);

        this.campaignName = '';

        this.state = {
            activeView: 'chooseCampaign',
        };
    }

    handleDataChange(key, value) {
        this.props.updateCampaignData(key, value);

        if (key === 'name') {
            this.campaignName = value;
        }
    }

    onBreadCrumbClick(myself) {
        if (myself) {
            return this.state.activeView !== 'chooseCampaign';
        }

        if (this.state.activeView === 'chooseCampaign' && this.chooseCampaignComponent.getData() === null) {
            return false;
        }

        return true;
    }

    onNext(passingAgain) {
        if (this.state.activeView === 'chooseCampaign') {
            const data = this.chooseCampaignComponent.getData();

            if (!data) {
                return;
            }

            if (passingAgain) { // this is to avoid select again the templates or import again a zip file because i've already done it
                this.props.gotoNextStep();
            }

            if (data.campaignType === 'campaign') {
                this.setState({
                    activeView: 'chooseCampaignOption'
                });
            } else {
                this.props.updateCampaignDataObj({
                    content: '',
                    subject: '',
                    see_editor: null
                });

                this.props.gotoNextStep();
            }
        } else {
            if (this.chooseCampaignOption.isDataValid()) {
                this.props.gotoNextStep();
            }
        }
    }

    render() {
        const templateCategories = this.props.templateCategories.filter(t => t.id !== 'draft');

        switch (this.state.activeView) {
            case 'chooseCampaign':
                return (
                    <div
                        style={{height: '100%'}}
                    >
                        <StepChooseCampaign
                            ref={(el) => this.chooseCampaignComponent = el}
                            userType={this.props.userType}
                            campaignData={this.props.campaignData}
                            resetProcess={this.props.resetProcess}
                            onDataChange={this.handleDataChange.bind(this)}
                            onDataChangeObj={(obj) => this.props.updateCampaignDataObj(obj)}
                        />
                    </div>
                );

            case 'chooseCampaignOption':
                return (
                    <StepChooseCampaignOption
                        ref={(el) => this.chooseCampaignOption = el}
                        parent={this.props.parent}
                        userType={this.props.userType}
                        templateCategories={templateCategories}
                        campaignData={this.props.campaignData}
                        onDataChange={this.handleDataChange.bind(this)}
                        onDataChangeObj={(obj) => this.props.updateCampaignDataObj(obj, true)}
                    />
                );
        }
    }
}

export default StepChoose;
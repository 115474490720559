import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import app from 'js/app';
import {Select, PlainSelectItem, NewSelect, TagSelect} from 'js/react_views/widgets/select';
import TextManager from 'app/text-manager';

import styles from './automation.css';


const statusMap = {
    on: { id: "on", title: "On" },
    off: { id: "off", title: "Off" },
    drain: { id: "drain", title: "Drain" }
};

const elementMap = {
    individuals: { id: "individuals", title: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'), icon: "icon-user" },
    organizations: { id: "organizations", title: TextManager.parseText('${ID_ORGANIZATION, capitalize}'), icon: "icon-home" },
    opportunities: { id: "opportunities", title: TextManager.parseText('${ID_DEAL, capitalize}'), icon: "icon-funnel-sm" }
};

class AutomationEditView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.automation.name,
            element: this.props.automation.entity_type,
            status: this.props.automation.state,
            description: this.props.automation.description,
            owner: {
                id: this.props.automation.owner.id,
                name: this.props.automation.owner.name,
                title: this.props.automation.owner.name
            },
            tags: this.props.automation.tags,
            funnels: this.props.automation.funnels
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateAutomation = this.updateAutomation.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleSelectOwner = this.handleSelectOwner.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleFunnelChange = this.handleFunnelChange.bind(this);

    }

    handleInputChange(ev) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleStatusChange(items) {
        const item = items[0];

        this.setState({
          status: item.id
        });
    }

    handleSelectOwner(items) {
        const item = items[0];

        this.setState({
            owner: {
                id: item.id,
                name: item.title,
                title: item.title
            }
        });
    }

    updateAutomation() {
        this.props.updateAutomation({
            id: this.props.automation.id,
            name: this.state.name,
            state: this.state.status,
            description: this.state.description,
            owner: this.state.owner,
            tags: this.state.tags,
            funnels: this.state.funnels,
        });
    }

    statusTypeSearch(search, done) {
        done(_.map(statusMap, item => item));
    }

    handleTagChange(items){
        this.setState({
            tags: _.map(items, function(item) { return { id: item.id }})
        })
    }

    handleFunnelChange(items){
        this.setState({
            funnels: _.map(items, function(item) { return { id: item.id }})
        })
    }

    render() {
        const ownerName = this.props.automation.owner.name;
        const name = this.props.automation.name;

        const showPermissionsButton = app.user.get('client').permission_type !== 'rba';

        return (
            <div className={styles.AutomationEditView}>
                <div className={styles.header}>
                    <a className={styles.cancelButton} onClick={this.props.handleCancelClicked}>Cancel</a>
                    <span className={styles.center}>
                        <div className={styles.deleteButton} title="Delete Item" onClick={this.props.deleteAutomation}>
                            <i className="icon-trashcan"></i>
                        </div>
                        {showPermissionsButton &&
                            <div className={styles.permissionsButton} title="Show Permissions" onClick={this.props.showPermissions}>
                                <i className="icon-locked"></i>
                            </div>
                        }
                    </span>
                    <a className={styles.saveButton} onClick={this.updateAutomation}>Save</a>
                </div>
                <div className={styles.attributeContainer}>
                    <label htmlFor="name">Name</label>
                    <input type="text" className={styles.attribute} placeholder="Name" autoComplete="off" name="name" id="name" value={this.state.name} onChange={this.handleInputChange} /><br />

                    <label htmlFor="element">Element <i className={elementMap[this.state.element].icon} /></label>
                    <input className={styles.attribute + " " + styles.hasIcon} disabled="disabled" name="element" id="element" value={elementMap[this.state.element].title} onChange={this.handleInputChange} /><br />

                    <label htmlFor="status">Status</label>
                    <NewSelect
                        className={styles.attribute}
                        data={_.map(statusMap, item => item)}
                        value={statusMap[this.state.status]}
                        onSelect={this.handleStatusChange}
                        placeholder="Select Status"
                        width={190}
                        containerStyle={{
                            position: "relative",
                            width: "190px",
                            display: "inline-block",
                            height: "32px",
                            lineHeight: "32px",
                            verticalAlign: "middle"
                        }}
                        boxStyle={{
                            background: null,
                            borderWidth: 0,
                            paddingLeft: 0
                        }}
                        dropDownStyle={{
                            left: 0,
                            zIndex: 1
                        }}
                    /><br/>

                    <label htmlFor="description">Description</label>
                    <textarea
                        className={styles.attribute}
                        placeholder="More Information"
                        autoComplete="off"
                        name="description"
                        id="description"
                        value={this.state.description}
                        onChange={this.handleInputChange}
                    /><br />

                    <label htmlFor="owner">Owner</label>
                    <NewSelect
                        className={styles.attribute}
                        value={this.state.owner}
                        width={190}
                        url="/users"
                        onSelect={this.handleSelectOwner}
                        options={{minimumInputLength: 1}}
                        containerStyle={{
                            position: "relative",
                            width: "190px",
                            display: "inline-block",
                            height: "32px",
                            lineHeight: "32px",
                            verticalAlign: "middle"
                        }}
                        boxStyle={{
                            background: null,
                            borderWidth: 0,
                            paddingLeft: 0
                        }}
                        dropDownStyle={{
                            left: 0,
                            zIndex: 1
                        }}
                    /><br/>
                    <div>
                    <label htmlFor="tags">Tags</label>
                    <div style={{
                        position: "relative",
                        width: "190px",
                        display: "inline-block",
                        minHeight: "32px",
                        lineHeight: "32px",
                        verticalAlign: "middle",
                        textAlign: "left",
                    }}>
                        <TagSelect
                            url='/tags'
                            text='name'
                            width='240'
                            value={this.state.tags}
                            placeholder='+ Add Tag'
                            onChange={this.handleTagChange}
                        />
                    </div>
                    </div>
                    <div>
                    <label htmlFor="funnels">{TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}</label>
                    <div style={{
                        position: "relative",
                        width: "190px",
                        display: "inline-block",
                        minHeight: "32px",
                        lineHeight: "32px",
                        verticalAlign: "middle",
                        textAlign: "left",
                    }}>
                        <TagSelect
                            url='/funnels'
                            text='name'
                            width='240'
                            value={this.state.funnels}
                            placeholder={`+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`}
                            onChange={this.handleFunnelChange}
                        />
                    </div>
                    </div>
                    <br/>
                </div>
            </div>
        )
    }
}

export default AutomationEditView;
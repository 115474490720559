import React from 'react';
import classnames from 'classnames';
import AppConfig from 'app/app-config'

import app from 'js/app'

import style from './Toolbar.css';

class Toolbar extends React.Component {
    renderCloseButton() {
        const { onClose } = this.props;
        return (
            <a className={style.closeButton} onClick={onClose}>
                Close
            </a>
        );
    }

    renderHistoryButton() {
        return (
            <li className={style.historyButton} onClick={this.props.onHistoryClick}>
                <i className='icon-history'/>
            </li>
        );
    }

    renderPermissionsButton() {
        if (app.user.get('client').permission_type === 'rba') {
            return null;
        }

        return (
            <li className={style.permissionsButtonWrapper}>
                <i
                    className={classnames(
                        style.permissionsButton,
                        'icon-locked',
                        {[style.disabled]: !this.props.onShowPermissionView}
                    )}
                    onClick={this.props.onShowPermissionView} />
            </li>
        );
    }

    renderOwnerName() {
        return (
            <li className={style.ownerNameWrapper}>
                <span className={style.ownerName}>
                    {this.props.ownerName}
                </span>
            </li>
        );
    }

    renderCenterSection() {
        return (
            <ul className={style.centerSection}>
                {this.renderHistoryButton()}
                {this.renderPermissionsButton()}
                {this.renderOwnerName()}
            </ul>
        )
    }

    renderEditButton() {
        const classes = classnames(
            style.editButton,
            {[style.disabled]: !this.props.onEdit}
        );
        return (
            <a className={classes} onClick={this.props.onEdit}>
                Edit
            </a>
        );
    }

    render() {
        return (
            <div className={style.fixedHeaderView}>
                <header className={style.Toolbar}>
                    {this.renderCloseButton()}
                    {this.renderCenterSection()}
                    {this.renderEditButton()}
                </header>
            </div>
            
        );
    }
};

export default Toolbar;

import React from "react";
import LoadingIndicator from "js/react_views/widgets/loading-indicator";
import style from "./kpi_box.css";
import Currency from "js/utils/currency";

const NO_BOX_ITEMS = 4;

export default class KpiBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: this.formatData(null),
      fetchingData: false,
    };

    const self = this;

    _.defer(function () {
      self.fetchData();
    });
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchData(force) {
    if (!this.mounted) {
      return;
    }

    const query = this.props.widget.params.query;

    if (!query) {
      return;
    }

    // only displays the loader the first time
    this.setState({
      fetchingData: true,
    });

    let options = _.clone(query.options || {});
    options.force = force;

    this.props.widget.fetcher.get(
      query.url,
      query.args,
      this.onData.bind(this),
      options
    );
  }

  onData(data) {
    if (!this.mounted) {
      return;
    }

    this.setState({
      values: this.formatData(data),
      fetchingData: false,
    });
  }

  formatData(data) {
    const keys = data ? Object.keys(data) : [];
    return [...Array(NO_BOX_ITEMS)].map((e, i) => {
      return {
        key: i,
        caption: keys?.[i]?.replace("_", " "),
        value: Currency.format(null, Math.floor(data?.[keys?.[i]])),
      };
    });
  }

  render() {
    const params = this.props.widget.params;
    const renderer = this.props.renderer;

    if (renderer) {
    }

    return (
      <div className={style.kpiBox}>
        <div className={style.title}>
          <div className={style.tContainer}>
            <div className={style.tTitle}>{params.title}</div>
          </div>
        </div>
        <div className={style.content}>
          <div className={style.cBox}>
            {this.state.fetchingData ? (
              <div className={style.loadingContainer}>
                <LoadingIndicator />
              </div>
            ) : (
              this.state.values.map((e) => {
                return (
                  <KpiBoxItem
                    key={e.key}
                    caption={e?.caption}
                    value={e?.value}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  }
}

class KpiBoxItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={style.cBoxCol}>
        <div className={style.cBoxKpi}>{this.props.value}</div>
        <div className={style.cBoxCaption}>{this.props.caption}</div>
      </div>
    );
  }
}

import _ from 'underscore'
import Handlebars from 'handlebars'

import AppConfig from 'app/app-config'
import IndividualListingBaseView from 'app/_components/IOD-listing/IOD/individual-listing/individual-listing-base'
import IndividualFavoritesModel from 'js/models/individual_favorites'
import app from 'js/app'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'


var BaseOverviewView = IndividualListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: true,
            disableLayoutSelection: true,
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    tools: {
        newItem: false,
        deleteItem: true,
        addItem: true,
        removeItem: true,
        editItem: true,
        selectAllPage: true,
        selectAll: true,
        editColumns: true,
        mergeItems: true
    },
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.fetchOptions.group_id = this.model.get('id');

        this.toolLabels = {
            removeItem: "Remove from Favorites"
        };
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            customFields: this.customFields,
            tableModel: this.model,
            type: this.options.type,
            elementType: this.options.elementType,
            filter: this.filter,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                addButton: true,
                removeFromFavorites: true
            }
        });
    },
    getSelectedColumns: function() {
        return this.model.get('columns');
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:individual:show', {model: model});
    }
});

export default IndividualListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);
        this.model = new IndividualFavoritesModel();
    },
    onRender: function() {
        var view = this;
        this.model.fetch({
            success: function() {
                IndividualListingBaseView.prototype.onRender.apply(view);
            }
        });

        this.$el.find('.detail-name').tooltip();
    },
    getElementType: function() {
        return 'individuals';
    },
    getUrl: function() {
        return 'favorites';
    }
});

import React from 'react';
import classnames from 'classnames';
import * as libPhoneNumber from 'libphonenumber-js';

import app from 'js/app'
import Utilities from 'js/utils/utilities';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import IndividualModel from 'js/models/contact'
import vent from 'js/vent'
import Currency from 'js/utils/currency';
import MessageBox from 'js/views/message_box'

import {Button} from 'js/react_views/widgets/widgets';
import {NewSelect} from 'js/react_views/widgets/select';
import { getDealSection } from 'js/react_views/DealView/DealView'

import styles from './styles.css';
import fieldContainerStyle from './FieldContainer.css';


class ProposifyButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            proposalID: null,
            proposalLink: null,
        };
    }

    componentDidMount() {
        const customFields = app.globalData.proposifyInfo;
        const proposalIDField = customFields['Proposal ID'];
        const proposalLinkField = customFields['Proposal Link'];
        const proposalIDCustomField = `custom_field.${proposalIDField}`;
        const proposalLinkCustomField = `custom_field.${proposalLinkField}`;
        const deal = this.props.deal;

        if ((proposalIDCustomField in deal) && (proposalLinkCustomField in deal)) {
            this.setState({
                proposalID: deal[proposalIDCustomField],
                proposalLink: deal[proposalLinkCustomField],
            });
        }
    }

    handleProposifyClick(dealID) {
        if (this.state.proposalID) {
            const newWindow = window.open(
                this.state.proposalLink,
                '_blank',
                'noopener,noreferrer',
            )
            if (newWindow) newWindow.opener = null
        } else {
            this.setState({ loading: true });
            const self = this
            $.post(`/opportunities/${dealID}/proposal`, function (response) {
                self.setState({
                    loading: false,
                    proposalID: response.proposal_id,
                    proposalLink: response.proposal_link,
                });
                self.props.parent.trigger(
                    'replace-view:deal:show',
                    { id: self.props.deal.id, section: self.props.section },
                );
            }).fail(function (error) {
                self.setState({ loading: false });
                const content = {
                    message: error.responseJSON.message,
                    icon: 'icon-warning'
                };

                MessageBox.showOk(content, self.props.parent);
            });
        }
    }


    render() {
        const deal = this.props.deal;

        let buttonText = 'Create Proposal';
        if (this.state.proposalID) {
            buttonText = 'View Proposal'
        }

        return (
            <div>
                <FieldContainer label="Proposify">
                    {this.state.loading ? (
                        <small className="extra-item-field">Creating proposal...</small>
                    ) : (
                        <ButtonBox>
                            <Button
                                color="green"
                                onClick={() => this.handleProposifyClick(deal.id)}>
                                {buttonText}
                            </Button>
                        </ButtonBox>
                    )}
                </FieldContainer>
            </div>
        );
    }
}

class GenericButton extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <FieldContainer label={this.props.label}>
                    <ButtonBox>
                        <Button
                            color='green'
                            onClick={this.props.onClick}
                        >
                            {this.props.title}
                        </Button>
                    </ButtonBox>
                </FieldContainer>
            </div>
        );
    }
}

class CommunicationLocationButtonBody extends React.Component {
    render() {
        const { children, iconClass } = this.props;
        return (
            <span className={`${styles.CommunicationLocationButtonBody} communication-button`}>
                <i className={classnames(iconClass, styles.icon)} />
                {children}
            </span>
        );
    }
}

class SocialButtonBody extends React.Component {
    render() {
        const { iconClass } = this.props;
        return (
            <span className={`${styles.SocialButtonBody} social-button`}>
                <i className={classnames(iconClass, styles.icon)} />
            </span>
        );
    }
}

class PhoneButton extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            smsEnabled: false,
            invalidFormat: false
        };

        if (props.smsActive && app.user.get('can_send_sms')) {
            // phone number should be in a valid format
            const number = props.href.slice(4); // 4 = tel:
            this.state.invalidFormat = !Utilities.getNormalizedPhoneNumber(number);
            this.state.smsEnabled = true;
        }
    }

    render() {
        const { href, onClick } = this.props;

        return (
            <div className={styles.PhoneButtonBody}>
                {this.state.smsEnabled &&
                    <Button
                        deemphasized={this.state.invalidFormat}
                        onClick={(ev) => {
                            if (!this.state.invalidFormat) {
                                onClick(ev, 'sms')
                            }
                        }}>

                        <CommunicationLocationButtonBody iconClass="icon-chat">
                            Text
                        </CommunicationLocationButtonBody>
                    </Button>
                }
                <Button
                    href={href}
                    target='_blank'
                    onClick={onClick}>

                    <CommunicationLocationButtonBody iconClass="icon-phone">
                        Call
                    </CommunicationLocationButtonBody>
                </Button>
            </div>
        );
    }
}

class EmailButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                onClick={onClick}>

                <CommunicationLocationButtonBody iconClass="icon-email">
                    Email
                </CommunicationLocationButtonBody>
            </Button>
        );
    }
}

class SendAllEmailButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                onClick={onClick}>

                <CommunicationLocationButtonBody iconClass="icon-email">
                    Send to all emails
                </CommunicationLocationButtonBody>
            </Button>
        );
    }
}

class LocationButton extends React.Component {
    render() {
        const { href } = this.props;
        return (
            <Button
                href={href}
                target='_blank'>

                <CommunicationLocationButtonBody iconClass="icon-location">
                    View
                </CommunicationLocationButtonBody>
            </Button>
        );
    }
}

class LinkedinButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                color='linkedin'
                onClick={onClick}>

                <SocialButtonBody iconClass="icon-linkedin" />
            </Button>
        );
    }
}

class TwitterButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                color='twitter'
                onClick={onClick}>

                <SocialButtonBody iconClass="icon-twitter" />
            </Button>
        );
    }
}

class FacebookButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                color='facebook'
                onClick={onClick}>

                <SocialButtonBody iconClass="icon-facebook" />
            </Button>
        );
    }
}

class GooglePlusButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                color='googleplus'
                onClick={onClick}>

                <SocialButtonBody iconClass="icon-googleplus" />
            </Button>
        );
    }
}

class InstagramButton extends React.Component {
    render() {
        const { href, onClick } = this.props;
        return (
            <Button
                href={href}
                target='_blank'
                color='instagram'
                onClick={onClick}>

                <SocialButtonBody iconClass="icon-instagram" />
            </Button>
        );
    }
}

const buttonsByType = {
    phone: PhoneButton,
    email: EmailButton,
    linkedin: LinkedinButton,
    twitter: TwitterButton,
    facebook: FacebookButton,
    googleplus: GooglePlusButton,
    instagram: InstagramButton,
};

const socialLabels = {
    linkedin: 'Linkedin',
    twitter: 'Twitter',
    facebook: 'Facebook',
    googleplus: 'Google+',
    instagram: 'Instagram'
};

const commFormatters = {
    twitter: (username) => ('@' + username),
};

const commValidators = {
    phone: (value) => {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4851')) return null;

        try {
            libPhoneNumber.parsePhoneNumberWithError(value);
            return null;
        } catch (error) {
            if (error.message === 'INVALID_COUNTRY') {
                return null;
            }

            return Utilities.getPhoneNumberError(error);
        }
    },
}

class FieldContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMoreInfo: false
        };

        this.toggleShowInfo = this.toggleShowInfo.bind(this);
    }

    toggleShowInfo() {
        this.setState((prevState, props) => ({
            showMoreInfo: !prevState.showMoreInfo
        }));
    }

    render() {
        const { label, labelColor, children, moreInfo, extendedContent } = this.props;
        const { showMoreInfo } = this.state;

        const labelClasses = classnames({
            [fieldContainerStyle.label]: true,
            [fieldContainerStyle.condensed]: moreInfo,
            [fieldContainerStyle[`${labelColor}LabelColor`]]: labelColor,
        });

        return (
            <div className={`${fieldContainerStyle.FieldContainer} ${this.props.useWiderLabel ? fieldContainerStyle.widerLabel : ''}`}>
                <div className={fieldContainerStyle.box}>
                    {label && (
                        <div className={labelClasses} title={label}>
                            {label}
                        </div>
                    )}
                    <div className={fieldContainerStyle.field}>
                        {children}
                    </div>
                </div>
                {showMoreInfo &&
                    <div className={fieldContainerStyle.box}>
                        <div className={fieldContainerStyle.label}>{TextManager.getText('ID_MORE_INFO')}</div>
                        <div className={fieldContainerStyle.fieldMoreInfo}>
                            {moreInfo}
                        </div>
                    </div>
                }
                {moreInfo && (
                    showMoreInfo ? (
                        <i
                            className={classnames(
                                'icon-cross',
                                fieldContainerStyle.hideMoreInfoButton
                            )}
                            onClick={this.toggleShowInfo} />
                    ) : (
                        <i
                            className={classnames(
                                'icon-info',
                                fieldContainerStyle.showMoreInfoButton
                            )}
                            onClick={this.toggleShowInfo} />
                    )
                )}
                {extendedContent}
            </div>
        );
    }
};

class ButtonFloat extends React.Component {
    render() {
        return (
            <aside className={styles.ButtonFloat}>
                {this.props.children}
            </aside>
        );
    }
}

class ButtonBox extends React.Component {
    render() {
        return (
            <div className={styles.ButtonBox}>
                {this.props.children}
            </div>
        );
    }
}

class EditButton extends React.Component {
    render() {
        const { onClick } = this.props;
        return (
            <i
                className={classnames(styles.EditButton, 'icon-pencil')}
                onClick={onClick} />
        );
    }
}

class TextFieldBody extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false
        };
    }

    componentDidUpdate() {
        if (this.input) {
            this.input.focus();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (_.isUndefined(nextProps.inputValue)) {
            this.setState({ saving: false });
        }
    }

    handleKeyDown(event) {
        const { onCancel } = this.props;

        if (event.key === 'Enter') {
            // lose focus so the input can get disabled
            // blur will trigger a save
            this.input.blur();
        }
        else if (event.key === 'Escape') {
            onCancel();
        }
    }

    save() {
        this.setState({ saving: true });
        this.props.onSave(() => {
            this.setState({ saving: false });
        });
    }

    render() {
        const { isParagraph, value, editable, inputValue, onChange, style } = this.props;
        const { saving } = this.state;
        const classes = classnames(styles.TextFieldBody, {
            [styles.Paragraph]: isParagraph
        });

        return (
            <div
                className={classes}>

                {_.isUndefined(inputValue) ?
                    <div className={classnames(
                        styles.fieldDisplay, {
                            [styles.editable]: editable
                        }
                    )}>
                        <p className={classnames(
                            styles.value, {
                                [styles[`${style}Style`]]: style
                            }
                        )}>
                            {value}
                        </p>
                        {editable &&
                            <i
                                className={classnames(styles.editButton, 'icon-pencil')}
                                onClick={() => { onChange(value); }} />
                        }
                    </div>
                :
                    <input
                        className={styles.fieldInput}
                        type="text"
                        value={inputValue}
                        disabled={saving}
                        onChange={(event) => { onChange(event.target.value) }}
                        onKeyDown={this.handleKeyDown.bind(this)}
                        onBlur={() => { this.save(); }}
                        ref={(input) => { this.input = input; }} />
                }
            </div>
        );
    }
}

class TextField extends React.Component {
    render() {
        const { label, value, editable, inputValue, onChange, onCancel, onSave, style, isParagraph } = this.props;

        return (
            <FieldContainer label={label}>
                <TextFieldBody
                    isParagraph={isParagraph}
                    value={value}
                    editable={editable}
                    inputValue={inputValue}
                    onChange={onChange}
                    onCancel={onCancel}
                    onSave={onSave}
                    style={style} />
            </FieldContainer>
        );
    }
}


class ProductField extends React.Component {
    render() {
        const { label, quantity, unitPrice, currency } = this.props;

        return (
            <FieldContainer label={label} useWiderLabel={true}>
                <div className={styles.ProductField}>
                    <span className={styles.Quantity}>{quantity}</span>
                    <span>{Currency.format(currency, quantity * unitPrice)}</span>
                </div>
            </FieldContainer>
        );
    }
}

class EntityListField extends React.Component {
    render() {
        const entityMapping = {
            opportunities: 'deals',
            opportunity: 'deals',
            individual: 'individuals',
            organization: 'organizations',
        };

        const { label, value } = this.props;

        return (
            <FieldContainer label={label}>
                <div className={styles.EntityListField}>
                    <div className={styles.EntityListFieldListContainer}>
                        {value.map(item => {
                            let section = null;
                            if (item.type === 'opportunities' || item.type === 'opportunity'){
                                section = getDealSection(label);
                            }
                            return (
                            <div
                                className={styles.EntityListFieldItem}
                                key={item.id}
                            >
                                <a href={`#${entityMapping[item.type] || item.type}/${section ? 'section/' + section.id + '/' : ''}${item.id}`}>{item.name || item.title}</a>
                            </div>
                        )})}
                    </div>
                </div>
            </FieldContainer>
        );
    }
}

class SelectFieldBody extends React.Component {
    constructor(props) {
        super(props);

        this.data = this.props.data;

        this.state = {
            editing: false
        };
    }

    render() {
        const { value, width, text, onSelect } = this.props;
        const { editing } = this.state;

        return (
            <div
                className={styles.SelectFieldBody}>

                {editing ?
                    <div className={styles.selectBox}>
                        <NewSelect
                            ref={(ref) => {this.selector = ref}}
                            value={value}
                            data={this.data}
                            onSelect={(items) => {
                                onSelect(items);
                            }}
                            onClose={() => { this.setState({ editing: false }); }}
                            placeholder=""
                            text={text}
                            width={width}
                            options={{initiallyExpanded: true}}
                        />
                    </div>
                :
                    <div className={styles.fieldDisplay}>
                        <p className={styles.value}>
                            {value.title}
                        </p>
                        {this.props.editable &&
                            <i
                                className={classnames(styles.editButton, 'icon-pencil')}
                                onClick={() => { this.setState({ editing: true }); }}
                            />
                        }
                    </div>
                }
            </div>
        );
    }

    setData(data) {
        this.data = data;

        if (this.selector) {
            this.selector.setData(this.data);
        }
    }
}

class SelectField extends React.Component {
    render() {
        const { label, data, text, width, value, onSearch, onSelect } = this.props;
        let editable = true;

        if ('editable' in this.props) {
            editable = this.props.editable;
        }

        return (
            <FieldContainer label={label}>
                <SelectFieldBody
                    ref={(ref) => {this.selectFieldBody = ref}}
                    value={value}
                    data={data}
                    text={text}
                    width={width}
                    onSelect={onSelect}
                    editable={editable}/>
            </FieldContainer>
        );
    }

    setData(data) {
        this.selectFieldBody.setData(data);
    }
}

class CheckboxIcon extends React.Component {
    render() {
        const { value } = this.props;
        const classes = classnames(styles.CheckboxIcon, {
            [styles.true]: value,
            [styles.false]: !value,
            'icon-checkmark3': value,
            'icon-cross': !value
        });

        return (
            <i className={classes} />
        );
    }
}

class CheckboxField extends React.Component {
    render() {
        const { label, value } = this.props;
        return (
            <FieldContainer label={label}>
                <CheckboxIcon value={value}/>
            </FieldContainer>
        );
    }
}

class IndividualFieldBody extends React.Component {
    render() {
        const { itemId, photoUrl, name, organizationName } = this.props;
        return (
            <a
                className={styles.IndividualFieldBody}
                href={'#individuals/' + itemId}>

                <div className={styles.picture}>
                    {/* always show placeholder because photoUrl, even if present,
                        might be a dead link */}
                    <div className={styles.imagePlaceholder}>
                        <i className={classnames('icon-user', styles.placeholderIcon)} />
                    </div>
                    {photoUrl &&
                        <div
                            className={styles.image}
                            style={{
                                backgroundImage: `url('${photoUrl}')`
                            }} />
                    }
                </div>
                <div className={styles.info}>
                    <div className={styles.name}>
                        {name}
                    </div>
                    <small className={styles.organizationName}>
                        {organizationName}
                    </small>
                </div>
                <div className={styles.navArrow}>
                    <i className={classnames('icon-angle-right', styles.navArrowIcon)} />
                </div>
            </a>
        );
    }
}

class IndividualField extends React.Component {
    render() {
        const { label, itemId, name, photoUrl, organizationName } = this.props;
        return (
            <FieldContainer label={label}>
                <IndividualFieldBody
                    itemId={itemId}
                    name={name}
                    photoUrl={photoUrl}
                    organizationName={organizationName} />
            </FieldContainer>
        );
    }
}

class LinkFieldBody extends React.Component {
    render() {
        const { href, children } = this.props;
        return (
            <a
                className={styles.LinkFieldBody}
                href={href}
                target={`${AppConfig.getValue('linkClickUrlTarget', "_self")}`}>

                {children}
            </a>
        );
    }
}

class OrganizationField extends React.Component {
    render() {
        const { label, itemId, name } = this.props;
        return (
            <FieldContainer label={label}>
                <LinkFieldBody href={'#organizations/' + itemId}>
                    {name}
                </LinkFieldBody>
            </FieldContainer>
        );
    }
}

class OpportunityField extends React.Component {
    render() {
        const { label, itemId, name, fromGroup } = this.props;
        const section = fromGroup && getDealSection(fromGroup.title);
        return (
            <FieldContainer label={label}>
                <LinkFieldBody href={`#deals/${section ? `section/${section.id}/` : ''}` + itemId + `${fromGroup && section ? `?=deals/section/${section.id}/groups/${fromGroup.id}` : ''}`}>
                    {name}
                </LinkFieldBody>
            </FieldContainer>
        );
    }
}

class ImageBox extends React.Component {
    render() {
        const { url } = this.props;
        return (
            <a className={styles.ImageBox} href={url}>
                <img className={styles.image} src={url} />
            </a>
        );
    }
}

class ImageField extends React.Component {
    render() {
        const { label, url } = this.props;
        return (
            <FieldContainer label={label}>
                <ImageBox url={url} />
            </FieldContainer>
        );
    }
}

class PreviewIframe extends React.Component {
    render() {
        const { src, height } = this.props;
        return (
            <iframe
                className={styles.PreviewIframe}
                frameBorder='0'
                src={src}
                height={height} />
        );
    }
}

class UrlField extends React.Component {
    render() {
        const { label, url, params } = this.props;
        let iframe;
        if (params && params.show_preview) {
            iframe = <PreviewIframe
                src={params.previewUrl}
                height={params.preview_height} />;
        }
        return (
            <FieldContainer label={label} extendedContent={iframe}>
                <LinkFieldBody href={Utilities.httpUrl(url)}>
                    {url}
                </LinkFieldBody>
            </FieldContainer>
        );
    }
}

class CommunicationFieldBody extends React.Component {
    render() {
        const { children, button, href, onClick } = this.props;

        return (
            <div className={styles.CommunicationFieldBody}>
                {!this.props.greyOut && <ButtonFloat>
                    {button}
                </ButtonFloat>}
                <a
                    className={`${styles.value} ${this.props.greyOut ? styles.greyOut : ''}`}
                    href={href}
                    target="_blank"
                    onClick={onClick}>

                    {children}
                </a>
            </div>
        );
    }
}

class CommunicationField extends React.Component {
    render() {
        const { medium, name, value, href, comments, smsActive, onClick } = this.props;

        const type = medium === 'social' ? name : medium;
        const label = {
            'phone': name || 'Phone',
            'email': name || 'Email',
            'social': socialLabels[name],
            'website': 'Website'
        }[medium];
        const formatter = commFormatters[type];
        const outputValue = formatter ? formatter(value) : value;
        const validator = commValidators[type];
        let error = validator ? validator(value) : null;
        const outputHref = href || value;
        const Button = buttonsByType[type];

        const displayErrMsg = AppConfig.getValue(`communication.${medium}.showErrorMessage`, true);

        if ( ! displayErrMsg ) {
            error = false;
        }

        return (
            <FieldContainer label={label} moreInfo={comments}>
                <CommunicationFieldBody
                    button={Button && <Button href={outputHref} smsActive={smsActive} onClick={onClick} />}
                    href={outputHref}
                    onClick={onClick}
                    greyOut={this.props.greyOut}
                >
                    <span
                        title={outputValue}
                    >
                        {outputValue}
                    </span>
                </CommunicationFieldBody>
                {error && <div style={{
                    fontSize: 13,
                    color: '#ffa404',
                    lineHeight: 'initial',
                }}><i className="icon-warning" style={{marginRight: 5}}></i>{error}</div>}
            </FieldContainer>
        );
    }
}

class LocationAddress extends React.Component {
    render() {
        return (
            <address className={styles.LocationAddress}>
                {this.props.children}
            </address>
        );
    }
}

class LocationField extends React.Component {
    render() {
        const { comments, location, label } = this.props;

        return (
            <FieldContainer label={label || "Location"} moreInfo={comments}>
                <ButtonFloat>
                    <LocationButton
                        href={'https://www.google.com/maps/place/' + location} />
                </ButtonFloat>
                <LocationAddress>
                    {location}
                </LocationAddress>
            </FieldContainer>
        );
    }
}

class DocusignField extends React.Component {
    render() {
        const { onShowDocusignView } = this.props;
        return (
            <FieldContainer label="DocuSign">
                <ButtonBox>
                    <Button onClick={onShowDocusignView}>
                        <span className='docusign-button'>Send to DocuSign</span>
                    </Button>
                </ButtonBox>
            </FieldContainer>
        );
    }
}

class CredasButton extends React.Component {
    render() {
        const { handleCredasAmlUpdate } = this.props;

        return (
            <div className={styles.CredasDealButtonContainer}>
                <div className={styles.CredasButton} onClick={handleCredasAmlUpdate}/>
            </div>
        );
    }
}

class TrustPilotButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleTrustPilotClick () {
        let self = this;
        this.setState({ loading: true });
    
        $.ajax({
            type: 'POST',
            url: '/trust_pilot/create_invitation',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                individual_id: this.props.model.id,
            }),
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Trust Pilot Invitation Sent.',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            },
            complete: function() {
                self.setState({ loading: false });
            }
        });
    }

    isJson(jsonString) {
        try {
            var o = JSON.parse(jsonString);

            if (o && typeof o === "object") {
                return o;
            }
        }
        catch (e) { }
    
        return false;
    }

    render() {
        return (
            <div className={styles.TrustPilot}>
                {this.state.loading ? (
                    <small className="extra-item-field">Invitation to TrustPilot… Please do not refresh screen</small>
                ) : (
                    <ButtonBox>
                        <Button  onClick={() => this.handleTrustPilotClick()}>
                            <span className={styles.TrustPilotButton}>TrustPilot Invite</span>
                        </Button>
                    </ButtonBox>
                )}
            </div>
        );
    }
}

class EmailTemplating extends React.Component {
    render() {
        const self = this;

        const getTemplates = function(callback) {
            if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5741')) { // new campaigns section
                app.shortTermCache.get('/pdcrm_templated_campaigns', {}, function(data) {
                    callback(data);
                });
            } else {
                let args = {
                    rows: -1,
                    status: 'ready,draft',
                    campaign_type: 'direct,campaign',
                    search: 'template'
                };

                app.shortTermCache.get('/campaigns', args, function(data) {
                    callback(data);
                });
            }
        }

        const onClick = function() {
            if (self.template) {
                if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5741')) { // new campaigns section
                    $.get(`/campaigns/${self.template.id}`, function(campaign) {
                        vent.trigger('campaigns:new', {
                            contactEmailTemplating: {
                                template: campaign,
                                toContact: {
                                    id: self.props.entityId,
                                    name: self.props.entityName
                                }
                            },
                            onCloseGoto: window.location.href
                        });
                    });
                } else {
                    self.template.to_group = {
                        name: self.props.entityName,
                        id: null,
                        items: [self.props.entityId]
                    };

                    vent.trigger('campaigns:new', {
                        populateFrom: self.template,
                        onCancelGoto: window.location.href
                    });
                }
            }
        }

        const handleSelectTemplate = function(items) {
            if (items) {
                self.template = items[0];
            }
        }

        const placeholder = `Select templated email ${this.props.value ? `for ${this.props.value}` : ''}`

        return (
            <FieldContainer>
                <div className={styles.EmailTemplatingContainer}>
                    <div style={{width: '340px'}} title={placeholder}>
                        <NewSelect
                            placeholder={placeholder}
                            data={getTemplates}
                            editViewMod={true}
                            text="name"
                            width="292"
                            onSelect={handleSelectTemplate}
                            containerStyle={{maxWidth: '312px', position: 'relative'}}
                        />
                    </div>
                    <div style={{marginLeft: '3px'}}>
                        <Button
                            onClick={onClick}
                        >
                            <CommunicationLocationButtonBody iconClass="icon-pencil">
                                Edit
                            </CommunicationLocationButtonBody>
                        </Button>
                    </div>
                </div>
            </FieldContainer>
        );
    }
}

class ChecklistsProgress extends React.Component {
    render() {
        let checklists = [];
        let defaultProgressBarColor = '#ff9a01'

        for (const cl of (this.props.checklists || [])) {
            const numCompletedSteps = cl.get('completed').length;
            const numSteps = numCompletedSteps + cl.get('next_steps').length;

            const checklist_settings = cl.get('checklist_settings') || {};

            let showChecklist = checklist_settings['checklist_progress'] || false
            const showCompleted = checklist_settings['show_completed'] || false

            if(showChecklist && !showCompleted && cl.get('next_steps').length === 0){
                showChecklist = false
            }

            const colour = checklist_settings['colour'] || defaultProgressBarColor

            if(showChecklist){
                checklists.push({
                    id: cl.get('id'),
                    name: cl.get('name'),
                    completed: (numCompletedSteps / numSteps) * 100,
                    colour: colour
                });
            }

        }

        return (
            <div className={styles.ChecklistsProgress}>
                {checklists.map(checklist => { return (
                    <div
                        key={`checklist_${checklist.id}`}
                        className={styles.ChecklistProgress}
                    >
                        <div className={styles.ChecklistProgressBarContainer}>
                            <div
                                className={styles.ChecklistProgressBar}
                                style={{width: `${checklist.completed}%`, background: checklist.colour}}
                            />
                        </div>
                        <div className={styles.ChecklistName}>{checklist.name}</div>
                    </div>
                )})}
            </div>
        );
    }
}

class QuickFieldsView extends React.Component {
    constructor(props) {
        super(props);

        this.fields = [];

        const clientPreferences = app.user.get('client').preferences || {};
        let quickFields = clientPreferences['quick_fields'];

        if (!quickFields) {
            return;
        }

        quickFields = JSON.parse(quickFields);

        const prefsFields = quickFields[this.props.entityType];

        if (!prefsFields) {
            return;
        }

        const cfs = app.globalData.customFieldsInfo[this.props.entityType] || {};

        for (const pf of prefsFields) {
            const fieldInfo = pf.field_id.split('.');

            if (fieldInfo[0] !== 'custom_field') {
                continue;
            }

            const cf = cfs[fieldInfo[1]];

            if (!cf) {
                continue;
            }

            let value = null;

            if (cf.type === 'checkbox') {
                value = this.props.entity[pf.field_id] || 'false';
            } else {
                if (!(pf.field_id in this.props.entity)) {
                    continue;
                }

                value = this.props.entity[pf.field_id];
            }

            // ...
            let backgroundColor = null;
            let fontColor = null;

            if (cf.type === 'dropDown') {
                const option = pf.options[value] || {};
                backgroundColor = option.background_color || pf.default?.background_color;
                fontColor = option.font_color || pf.default?.font_color;
                value = cf.options[value];
            } else if (cf.type === 'checkbox') {
                const option = pf.options[value] || {};
                backgroundColor = option.background_color || pf.default?.background_color;
                fontColor = option.font_color || pf.default?.font_color;

                if (!_.isBoolean(value)) {
                    value = value === 'true' ? true : false;
                }
            } else {
                backgroundColor = pf.background_color;
                fontColor  = pf.font_color;
            }

            this.fields.push({
                name: cf.name,
                value: value,
                isBoolean: cf.type === 'checkbox',
                style: {
                    color: fontColor || '#ffffff',
                    backgroundColor: backgroundColor || '#cecece'
                }
            });
        }
    }

    render() {
        if (this.fields.length === 0) {
            return null;
        }

        return (
            <div className={styles.QuickFieldsView}>
                {this.fields.map((field, fidx) => {
                    return (
                        <div
                            key={`qf_${fidx}`}
                            className={styles.QuickField}
                            style={field.style}
                            title={`${field.name}: ${field.value}`}
                        >
                            {field.isBoolean ? (
                                <div className={styles.BooleanContainer}>{field.name}:
                                    <div className={`
                                        ${field.value ? 'icon-checkmark3' : 'icon-cross'}
                                        ${field.value ? styles.True : styles.False}
                                    `}/>
                                </div>
                            ) : (
                                <div className={styles.Container}>{field.name}: {field.value}</div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class AppointmentField extends React.Component {
    render() {
        const { onAppointmentClick } = this.props;
        return (
            <div className={styles.Appointment}>
                <ButtonBox>
                    <Button onClick={onAppointmentClick}>
                        <span className={styles.AppointmentButton}>Appointment</span>
                    </Button>
                </ButtonBox>
            </div>
        );
    }
}

class ConnellsSendComplianceEmailButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    sendComplianceEmail (deal_id) {
        let self = this;
        this.setState({
            loading: true,
        });

        $.ajax({
            type: 'GET',
            url: `/compliance_send_email/${deal_id}`,
            success: function(response) {
                MessageBox.showOk({
                    icon: 'icon-info',
                    message: 'Successfully send email to Compliance Team.'
                }, self.props.parent, function() {
                    self.setState({
                        loading: false,
                    });
                });
            },
            error: function() {
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'Failed to send email.'
                }, self.props.parent, function() {
                    self.setState({
                        loading: false,
                    });
                });
            }
        });
    }

    render () {
        const deal_id = this.props.deal.id;

        return(
            <div className={styles.ConnellsComplianceEmailButtonContainer}>
                {this.state.loading ? (
                    <small className="extra-item-field">Sending Email...</small>
                ) : (
                    <ButtonBox>
                        <Button  onClick={() => this.sendComplianceEmail(deal_id)}>
                            <span>Send to Compliance</span>
                        </Button>
                    </ButtonBox>
                )}
            </div>
        );
    }
}

export {
    FieldContainer,
    TextField,
    SelectField,
    CheckboxField,
    IndividualField,
    OrganizationField,
    OpportunityField,
    ImageField,
    UrlField,
    CommunicationField,
    LocationField,
    DocusignField,
    EmailTemplating,
    ProductField,
    EntityListField,
    ChecklistsProgress,
    SendAllEmailButton,
    ProposifyButton,
    GenericButton,
    QuickFieldsView,
    AppointmentField,
    CredasButton,
    TrustPilotButton,
    ConnellsSendComplianceEmailButton
};

import React from 'react';
import classnames from 'classnames';

import style from './loading-indicator.css';

class LoadingIndicator extends React.Component {
    render() {
        return (
            <div className={style.LoadingIndicator}>
            </div>
        );
    }
}

export default LoadingIndicator;

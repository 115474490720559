import React from 'react';

import app from 'js/app'
import TextManager from 'app/text-manager'
import { DateField } from 'js/react_views/common_components/common'

class SingleCustomFieldDateFilter extends React.Component {
    constructor(props) {
        super(props);

        this.dateFrom = null;
        this.dateTo = null;

        if (props.cfType === 'opportunity_custom') {
            this.customField = app.globalData.customFieldsInfo.opportunitiesArray.find(c => c.name.toLowerCase() === props.cfName.toLowerCase());
        } else if (props.cfType === 'individual_custom') {
            this.customField = app.globalData.customFieldsInfo.individualsArray.find(c => c.name.toLowerCase() === props.cfName.toLowerCase());
        } else if (props.cfType === 'organization_custom') {
            this.customField = app.globalData.customFieldsInfo.organizationsArray.find(c => c.name.toLowerCase() === props.cfName.toLowerCase());
        }
    }

    getDateData() {
        let data = [];

        if (this.dateFrom) {
            data.push(
                {
                    "field": this.customField.id,
                    "custom": this.props.cfType,
                    "operator": "more_than",
                    "value": this.dateFrom
                }
            );
        }

        if (this.dateTo) {
            data.push(
                {
                    "field": this.customField.id,
                    "custom": this.props.cfType,
                    "operator": "less_than",
                    "value": this.dateTo
                }
            );
        }

        return data;
    }

    handleFromDateChange(item) {
        let dateFrom = null;

        if (item) {
            dateFrom = item;
        }

        this.dateFrom = dateFrom;
    }

    handleToDateChange(item) {
        let dateTo = null;

        if (item) {
            dateTo = item;
        }

        this.dateTo = dateTo;
    }

    render() {
        return (
            <div>
                <DateField
                    labelWidth={'30%'}
                    fullInputBox={true}
                    label={this.props.dateFieldLabel + ' From'}
                    placeholder={this.props.dateFieldPlaceholder + ' From'}
                    onValueChange={this.handleFromDateChange.bind(this)}
                />

                <DateField
                    labelWidth={'30%'}
                    fullInputBox={true}
                    label={this.props.dateFieldLabel + ' To'}
                    placeholder={this.props.dateFieldPlaceholder + ' To'}
                    onValueChange={this.handleToDateChange.bind(this)}
                />
            </div>
        );
    }
}

class EntityDate extends React.Component {
    constructor(props) {
        super(props);

        this.dateFrom = null;
        this.dateTo = null;
    }

    getDateData() {
        let data = [];
        let dateFilterField = 'created';
        if (this.props.dateFilterField) {
            dateFilterField = this.props.dateFilterField;
        }

        if (this.dateFrom) {
            data.push(
                {
                    "field": dateFilterField,
                    "operator": "more_than",
                    "value": this.dateFrom
                }
            );
        }

        if (this.dateTo) {
            data.push(
                {
                    "field": dateFilterField,
                    "operator": "less_than",
                    "value": this.dateTo
                }
            );
        }

        return data;
    }

    handleFromDateChange(item) {
        let dateFrom = null;

        if (item) {
            dateFrom = item;
        }

        this.dateFrom = dateFrom;
    }

    handleToDateChange(item) {
        let dateTo = null;

        if (item) {
            dateTo = item;
        }

        this.dateTo = dateTo;
    }

    render() {
        return (
            <div>
                <DateField
                    labelWidth={'30%'}
                    fullInputBox={true}
                    label={this.props.dateFieldLabel + ' From'}
                    placeholder={this.props.dateFieldPlaceholder + ' From'}
                    onValueChange={this.handleFromDateChange.bind(this)}
                />

                <DateField
                    labelWidth={'30%'}
                    fullInputBox={true}
                    label={this.props.dateFieldLabel + ' To'}
                    placeholder={this.props.dateFieldPlaceholder + ' To'}
                    onValueChange={this.handleToDateChange.bind(this)}
                />
            </div>
        );
    }
}

class BrokerLeadsReportFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    getDateData() {
        let data = [];

        data = data.concat(this.dateFilter.getDateData());
        data = data.concat(this.dateAssigned.getDateData());
        data = data.concat(this.dateFinanciallyQualified.getDateData());

        return data;
    }

    render() {
        const contactText = TextManager.parseText('${ID_INDIVIDUAL, capitalize}');

        return (
            <div>
                <EntityDate
                    ref={(input) => { this.dateFilter = input; }}
                    dateFieldLabel={`${contactText} Created`}
                    dateFieldPlaceholder={`${contactText} Created Date`}
                />

                <SingleCustomFieldDateFilter
                    ref={(input) => { this.dateAssigned = input; }}
                    cfName='Date Assigned to Broker'
                    cfType='individual_custom'
                    dateFieldLabel='Broker Assigned'
                    dateFieldPlaceholder='Period Start'
                />

                <SingleCustomFieldDateFilter
                    ref={(input) => { this.dateFinanciallyQualified = input; }}
                    cfName='Date Financially Qualified'
                    cfType='individual_custom'
                    dateFieldLabel='Financially Qualified'
                    dateFieldPlaceholder='Financially Qualified'
                />
            </div>
        );
    }
}

class UnActionedTasksReportFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    getDateData() {
        let data = [];

        data = data.concat(this.taskCreatedDate.getDateData());
        data = data.concat(this.taskDueDate.getDateData());

        return data;
    }

    render() {
        return (
            <div>
                <EntityDate
                    ref={(input) => { this.taskCreatedDate = input; }}
                    //dateFilterField='created'
                    dateFieldLabel={'Task Created'}
                    dateFieldPlaceholder={'Task Created Date'}
                />

                <EntityDate
                    ref={(input) => { this.taskDueDate = input; }}
                    dateFilterField='due_date'
                    dateFieldLabel={'Task Due'}
                    dateFieldPlaceholder={'Task Due Date'}
                />
            </div>
        );
    }
}

export {
    EntityDate,
    SingleCustomFieldDateFilter,
    BrokerLeadsReportFilter,
    UnActionedTasksReportFilter,
}

import Marionette from 'Backbone.Marionette'


var CustomRegion = Marionette.Region.extend({
    show: function(view, renderArgs) {
        this.ensureEl();
        if (view !== this.currentView) {
            this.close();
            view.render.apply(view, renderArgs);
            this.open(view);
        } else {
            view.render.apply(view, renderArgs);
        }
        Marionette.triggerMethod.call(view, "show");
        Marionette.triggerMethod.call(this, "show", view);

        this.currentView = view;
    }
});

export default CustomRegion;

import React from 'react';

import style from './search.css';

export default class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputVisible: false,
            searchTerm: ''
        };

        this.doSearch = _.debounce(this.doSearch, 300);
    }

    handleInputChange(ev) {
        this.setState({
            searchTerm: ev.target.value
        });

        this.doSearch(ev.target.value);
    }

    handleClick() {
        if (this.state.expanded) {
            return;
        }

        this.setState({
            searchTerm: '',
            expanded: true
        });

        const self = this;

        _.defer(function() {
            self.inputComponent.focus();
        });
    }

    handleCancel() {
        this.setState({
            expanded: false
        });

        this.props.onSearch(null);
    }

    doSearch(term) {
        this.props.onSearch(term);
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                }}
            >
                <div
                    className={`
                        ${style.stSearch}
                        ${this.state.expanded ? style.sExpanded : ''}
                    `}
                    style={{
                        position: 'absolute',
                        right: 0
                    }}
                    onClick={this.handleClick.bind(this)}
                >
                    <div className={`${style.sIcon} icon-search`}/>

                    <input
                        ref={(el) => this.inputComponent = el}
                        className={style.sInput}
                        placeholder='Quick search'
                        tabIndex='-1'
                        value={this.state.searchTerm}
                        onChange={this.handleInputChange.bind(this)}
                    />

                    <div
                        className={
                            `${style.sIconCancel}
                            icon-cross
                        `}
                        onClick={this.handleCancel.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
import filterOperators from 'js/views/filters/operators'
import filterCommon from 'js/views/filters/common'
import TextManager from 'app/text-manager'

const campaignText = TextManager.parseText('${ID_CAMPAIGN, capitalize}');
const funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

export default function() {
    var fields = [
        {
            'id': 'campaign_id',
            'name': campaignText,
            'operators': filterOperators.campaign,
            'group': campaignText
        }, {
            'id': 'campaign_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': campaignText
        }, {
            'id': 'campaign_creator_id',
            'name': 'Creator',
            'operators': filterOperators.user,
            'group': campaignText
        }, {
            'id': 'campaign_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': campaignText
        }, {
            'id': 'campaign_modified',
            'name': 'Modified',
            'operators': filterOperators.date,
            'group': campaignText
        }, {
            'id': 'campaign_last_modified_by_id',
            'name': 'Last Modified By',
            'operators': filterOperators.user,
            'group': campaignText
        }, {
            'id': 'campaign_last_modified_by_me',
            'name': 'Last Modified By Me',
            'operators': false,
            'group': campaignText
        }, {
            'id': 'campaign_owner_id',
            'name': 'Owner',
            'operators': filterOperators.user,
            'group': campaignText
        }, {
            'id': 'campaign_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': campaignText
        }, {
            'id': 'campaign_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': campaignText
        }, {
            'id': 'campaign_to_group_id',
            'name': 'To Group',
            'operators': filterOperators.group,
            'group': campaignText
        }, {
            'id': 'campaign_subject',
            'name': 'Subject',
            'operators': filterOperators.string,
            'group': campaignText
        }, {
            'id': 'campaign_campaign_type',
            'name': 'Type',
            'operators': filterOperators.custom.dropDown,
            'group': campaignText,
            'value_def': {options: [{
                id: 'direct',
                value: 'Direct'
            }, {
                id: 'campaign',
                value: campaignText
            }, {
                id: 'message',
                value: 'Text Message'
            }]}
        }, {
            'id': 'campaign_sent',
            'name': 'Sent',
            'operators': filterOperators.date,
            'group': campaignText
        }, {
            'id': 'group_id',
            'name': 'Group',
            'operators': filterOperators.group,
            'group': 'Group'
        }, {
            'id': 'funnel_cluster_id',
            'name': TextManager.parseText('${ID_CLUSTER, capitalize}'),
            'operators': filterOperators.funnel_cluster,
            'group': funnelText
        }

    ];

    return fields;
};

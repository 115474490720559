import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'


var BaseView = function() {};

_.extend(BaseView.prototype, Backbone.Events, {
    $el: null,
    id: 'id',
    text: 'name',
    value: null,
    options: {},
    view: null,
    data: null,

    constructor: function(options) {
        _.extend(this, options);

        if (this.view.isClosed) {
            return;
        }

        if (this.data instanceof Backbone.Collection) {
            this.data = this.data.toJSON();
        }

        this.options = _.extend({}, _.defaults(options.options, this.getDefaults()));

        this.init();
        this.create();
    },
    init: function() {
        var view = this;
        if (this.view) {
            this.listenTo(this.view, 'close', function() {
                view.destroy();
            });
            if (this.view.controller) {
                this.listenTo(this.view.controller, 'editing:end', function() {
                    view.updateData();
                });
            }
        }
    },
    create: function() {
        this.$el.select2(this.options);
        this.$el.on('change', this.onChange.bind(this));
    },
    getItemId: function(item) {
        return item[this.id];
    },
    getItem: function(item, forSelection) {
        var text = item[this.text] || '';

        if (item.appendCreateNew) {
            if (forSelection) {
                text = text + ' (New)';
            }
            else {
                text = text + ' (Create new)';
            }
        }

        return {
            id: this.getItemId(item),
            text: text
        };
    },
    formatResult: function(result, container, query, escapeMarkup) {
        result = this.getItem(result);
        return $.fn.select2.defaults.formatResult(result, container, query, escapeMarkup);
    },
    formatSelection: function(data, container, escapeMarkup) {
        data = this.getItem(data, true);
        return $.fn.select2.defaults.formatSelection(data, container, escapeMarkup);
    },
    getDefaults: function() {
        var self = this;

        var defaults = {
            id: this.getItemId.bind(this),
            formatResult: this.formatResult.bind(this),
            formatSelection: this.formatSelection.bind(this)

        };

        if (this.createSearchChoice) {
            if (typeof this.createSearchChoice === 'function') {
                defaults.createSearchChoice = this.createSearchChoice.bind(this);
            } else {
                defaults.createSearchChoice = function(term, data) {
                    var hideCreateNew = false,
                        item = {};
                    term = term.trim();
                    if (term.length === 0) {
                        return;
                    }

                    // don't add 'create new' choice if title already in DB
                    _.each(data, function(item) {
                        if (item.title.toLowerCase() === term.toLowerCase()) {
                            hideCreateNew = true;
                        }
                    });

                    if (hideCreateNew) {
                        return null;
                    }

                    item[this.text] = term;
                    item.appendCreateNew = true;

                    return item;
                }.bind(this);
            }
        }

        if (this.url) {
            if (this.search) {
                defaults.ajax = {
                    url: this.url,
                    data: function(term) {
                        var data = _.defaults({}, this.params);
                        data.search = term;
                        this.searchTerm = term
                        return data;
                    }.bind(this),
                    results: function(data) {
                        if(this.afterFetchData){
                            data = this.afterFetchData(data);
                        }
                        if(this.filterResults && this.searchTerm){
                            var self = this;
                            data = data.map(item => self.filterResults(item, self.searchTerm.toLowerCase()))
                        }
                        if (this.searchFilter) {
                            data = _.filter(data, this.searchFilter);
                        }
                        return {
                            results: data
                        };
                    }.bind(this),
                    initSelection: function(element, callback) {
                        callback(this.getData());
                    }.bind(this),
                    quietMillis: 500
                };
            } else {
                (function() {
                    var filter;
                    var text = this.text;
                    var url = this.url;
                    var params = this.params || {};

                    defaults.query = function(query) {
                        if (filter) {
                            filter(query);
                        } else {
                            var collection = new Backbone.Collection();
                            collection.fetch({
                                url: url,
                                data: _.defaults({}, params),
                                success: function(collection) {
                                    self.trigger('collectionLoaded', collection);
                                    filter = window.Select2.query.local(function() {
                                        _.each(this.staticModels, function(model, ind) {
                                            collection.remove({ id: model.get('id') });
                                            collection.add(model, { at: ind });
                                        });
                                        var results = collection.toJSON();

                                        var value = this.getData();
                                        if (_.isObject(value) && !_.isArray(value)) {
                                            var found = _.find(results, function(item) {
                                                return this.getItemId(item) === this.getItemId(value);
                                            }.bind(this));
                                            if (!found) {
                                                results.splice(0, 0, value);
                                            }
                                        }

                                        return {
                                            results: results,
                                            text: text
                                        };
                                    }.bind(this));

                                    filter(query);
                                }.bind(this)
                            });
                        }
                    }.bind(this);
                }.bind(this))();
            }
        }

        if (this.data) {
            defaults.data = {
                results: this.data,
                text: this.text
            };
        }

        return defaults;
    },
    setValue: function(value) {
        this.value = value;
        this.updateData();
    },
    getValue: function() {
        return this.value;
    },
    getData: function() {
        return this.$el.select2('data');
    },
    updateData: function() {
        var value = this.getValue();
        if (typeof value === 'object') {
            this.$el.select2('data', value);
        } else {
            this.$el.select2('val', value);
        }
    },
    onChange: function() {
        this.trigger('change', this.getData());
    },
    destroy: function() {
        this.$el.select2('destroy');
        this.trigger('destroy');
    }
});
BaseView.extend = Backbone.View.extend;

var SelectView = BaseView.extend({
    constructor: function() {
        BaseView.prototype.constructor.apply(this, arguments);
    },
    create: function() {
        BaseView.prototype.create.apply(this, arguments);

        this.updateData();
    }
});

var CollectionView = BaseView.extend({
    value: null,
    search: null,

    constructor: function() {
        BaseView.prototype.constructor.apply(this, arguments);
    },
    create: function() {
        BaseView.prototype.create.apply(this, arguments);

        // Hide dropdown before animation
        this.$el.select2('dropdown').addClass('hidden');
        this.$el
        .on('select2-open', function() {
            $(this).select2('dropdown').addClass('bounce-in');
        })
        .on('select2-close', function() {
            $(this).select2('dropdown').removeClass('bounce-in');
        });
        this.bounceTokens();

        if (this.value instanceof Backbone.Collection) {
            this.listenTo(this.value, 'sync', this.updateData.bind(this));
        }

        this.updateData();
    },
    getDefaults: function() {
        var defaults = BaseView.prototype.getDefaults.apply(this, arguments);
        defaults.multiple = true;

        return defaults;
    },
    setValue: function(value) {
        if (this.value instanceof Backbone.Collection) {
            this.stopListening(this.value, 'sync');
        }

        BaseView.prototype.setValue.apply(this, arguments);

        if (this.value instanceof Backbone.Collection) {
            this.listenTo(value, 'sync', this.updateData.bind(this));
        }
    },
    getValue: function() {
        if (this.value instanceof Backbone.Collection) {
            return this.value.toJSON();
        } else {
            return this.value;
        }
    },
    updateData: function() {
        BaseView.prototype.updateData.apply(this, arguments);

        this.bounceTokens();
    },
    onChange: function() {
        this.bounceTokens();

        this.trigger('change', this.getData());
    },
    bounceTokens: function() {
        this.$el.select2('container')
            .find('.select2-search-choice')
            .addClass('bounce-in');
    }
});

var TagView = CollectionView.extend({
    getDefaults: function() {
        var defaults = CollectionView.prototype.getDefaults.apply(this, arguments);
        defaults.tags = [];
        defaults.createSearchChoice = function(term) {
            term = term.trim();
            if (term.length === 0) {
                return;
            }

            var item = {};
            item[this.text] = term;

            return item;
        }.bind(this);

        return defaults;
    }
});

export default {
    BaseView: BaseView,
    SelectView: SelectView,
    CollectionView: CollectionView,
    TagView: TagView
};

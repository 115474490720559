import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager';
import { NewSelect } from 'js/react_views/widgets/select';
import htmlSanitizer from 'js/utils/html-sanitizer';
import MergeTags from './merge_tags';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import MessageBox from 'js/views/message_box';

import style from './campaign_creation.css';


const SenderBlocked = (item, isTextMessage) => {
    if (!item || item.isCustomField) {
        return false;
    }

    if (isTextMessage) {
        return !item.phone;
    }

    const userPreferences = item.preferences;

    if (userPreferences && userPreferences.notValidCampaignSender) {
        return true;
    }

    const userDomain = item.email_address.split('@')[1].toLowerCase();

    return !_.find(app.user.get('client').email_sending_domains, function(domain) {
        if (domain.toLowerCase() === userDomain) {
            return true;
        } else if (userPreferences) {
            const userAlternativeSenderEmail = userPreferences.alternative_email_sender;

            if (userAlternativeSenderEmail) {
                const userAlternativeDomain = userAlternativeSenderEmail.split('@')[1].toLowerCase();
                return domain.toLowerCase() === userAlternativeDomain;
            }

            return false;
        }
    });
}

class Subject extends React.Component {
    constructor(props) {
        super(props);

        this.mergeTags = new MergeTags(this.props.parent);
        this.mergeTagItems = this.mergeTags.getTagItems(true, props.campaignData.funnel_id);

        this.state = {
            value: props.campaignData.subject || '',
            mergeTagSelectorExpanded: false
        };

        this.triggerSubjectDataChangeEvent = _.debounce(this.triggerSubjectDataChangeEvent, 500);
    }

    triggerSubjectDataChangeEvent(newValue) {
        this.props.onDataChange('subject', newValue);
    }

    handleValueChange(ev) {
        const newValue = ev.target.value;

        this.setState({
            value: newValue
        });

        this.triggerSubjectDataChangeEvent(newValue);
    }

    handleMergeTagButtonClick() {
        this.mergeTagSelector.toggleExpanded(true);

        this.setState({
            mergeTagSelectorExpanded: true
        });
    }

    handleMergeTagSelect(items) {
        const self = this;

        this.mergeTags.manageTagInsertion(items[0], function(tag) {
            const newValue = self.state.value + tag;

            self.setState({
                value: newValue
            });

            self.props.onDataChange('subject', newValue);
        });
    }

    handleMergeTagClose() {
        this.setState({
            mergeTagSelectorExpanded: false
        });
    }

    render() {
        return (
            <div
                className={style.fSubject}
                title={this.state.value}
            >
                <input
                    className={style.sInput}
                    type='text'
                    placeholder='Enter your subject line here'
                    value={this.state.value}
                    onChange={this.handleValueChange.bind(this)}
                />

                <div
                    className={style.sMergeTagButton}
                    onClick={this.handleMergeTagButtonClick.bind(this)}
                >
                    Add Merge Tag
                </div>

                <div
                    className={`
                        ${style.meDropdown}
                        ${this.state.mergeTagSelectorExpanded ? style.meExpanded : ''}
                    `}
                    style={{
                        right: 50,
                        top: 0
                    }}
                >
                    <NewSelect
                        ref={(el) => this.mergeTagSelector = el}
                        data={this.mergeTagItems}
                        width={250}
                        text='name'
                        boxStyle={{
                            visibility: 'hidden'
                        }}
                        onSelect={this.handleMergeTagSelect.bind(this)}
                        onClose={this.handleMergeTagClose.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

class FromItemView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        let content = htmlSanitizer.sanitize(this.props.content);

        if (!this.props.item.isCustomField && this.props.item.communication) {
            content += ` (${this.props.item.communication})`;
        }

        return (
            <div
                className={`
                    ${style.fFromItem}
                    ${this.props.disabled ? style.iDisabled : ''}
                    ${this.state.selected ? style.iSelected : ''}
                `}
                onClick={!this.props.disabled && this.props.onClick}
                onMouseEnter={!this.props.disabled && this.props.onMouseEnter}
            >
                {SenderBlocked(this.props.item, this.props.item.isTextMessage) &&
                    <div
                        className='icon-blocked'
                        style={{
                            color: 'red',
                            marginRight: '5px'
                        }}
                    />
                }

                <div
                    dangerouslySetInnerHTML={{ __html: content}}
                />
            </div>
        );
    }
}

class From extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            from: null,
            fromBlocked: false,
            dropdownExpanded: false
        };
    }

    isBlocked() {
        return this.state.fromBlocked;
    }

    getFromItems(callback) {
        // the phone number can be a text (i.e. SaleSeek), a fixed phone number (the same number is used to send all the sms no mather the user
        // who is sending them) or it can be the phone number associated with the user (the default option)
        let fixedPhone = null;

        if (app.globalData.smsInfo && app.globalData.smsInfo.originatorType !== 'user_phone') {
            fixedPhone = app.globalData.smsInfo.originatorValue;
        }

        const self = this;

        // ...
        const getItemFromUser = (user) => {
            const phone = fixedPhone || user.phone_number || '';

            return {
                id: user.id,
                name: user.name,
                email_address: user.email_address,
                phone: phone,
                communication: isTextMessage ? phone : user.email_address,
                isTextMessage: isTextMessage,
                preferences: user.preferences
            };
        }

        const setFromItem = (items, defaultItem) => {
            const campaignData = self.props.campaignData;
            let from = null;
            let triggerUpdate = false;

            if (campaignData.from_user_id) {
                from = items.find(i => i.id === campaignData.from_user_id);
            } else if (campaignData.from_custom_field_id) {
                from = items.find(i => i.id === campaignData.from_custom_field_id);
            }

            if (!from) {
                from = defaultItem;
                triggerUpdate = true;
            }

            const blocked = SenderBlocked(from, isTextMessage);

            if (!self.state.from) {
                self.setState({
                    from: from,
                    fromBlocked: blocked
                });

                self.props.onSelect(from, triggerUpdate, blocked);
            }

            callback(items);
        }

        // ...
        const isTextMessage = this.props.campaignData.campaign_type === 'message';

        if (!isTextMessage && AppConfig.hasValue('campaigns.step_preview.fixed_from')) {
            let fixedFromItems = AppConfig.getValue('campaigns.step_preview.fixed_from', [], this.props.campaignData);

            if (fixedFromItems && fixedFromItems.length > 0) {
                setFromItem(fixedFromItems.map(getItemFromUser), getItemFromUser(fixedFromItems[0]));
                return;
            }
        }

        $.get(`/users?extra_fields=${isTextMessage ? 'twilio_phone' : ''}`, function(users) {
            let items = [{
                name: 'Users',
                isSectionTitle: true
            }];

            items = items.concat(users.map(u => getItemFromUser(u)));

            if (AppConfig.getFeatureLabValue('enableGroupsForCampaigns', 'SAL-4063')) {
                const entities = ['individuals', 'deals', 'organizations'];
                const textIds = ['${ID_INDIVIDUAL}', '${ID_DEAL}', '${ID_ORGANIZATION}'];

                for (let i = 0; i < entities.length; ++i) {
                    const individualCustomFields = app.globalData.customFieldsInfo[`${entities[i]}Array`].filter(cf => cf.type === 'individual');

                    if (individualCustomFields.length > 0) {
                        items.push({
                            name: TextManager.parseText(`${textIds[i]} custom fields`),
                            isSectionTitle: true
                        });

                        items = items.concat(individualCustomFields.map(cf => {
                            return {
                                id: cf.id,
                                name: cf.name,
                                isCustomField: true
                            };
                        }));
                    }
                }
            }

            setFromItem(items, items[1]); // items[0] == section title
        });
    }

    handleDropdownClick() {
        this.dropdown.toggleExpanded(true);

        this.setState({
            dropdownExpanded: true
        });
    }

    handleDropdownSelect(items) {
        const blocked = SenderBlocked(items[0], this.props.campaignData.campaign_type === 'message');

        this.setState({
            from: items[0],
            fromBlocked: blocked
        });

        this.props.onSelect(items[0], true, blocked);
    }

    handleDropdownClose() {
        this.setState({
            dropdownExpanded: false
        });
    }

    render() {
        const isTextMessage = this.props.campaignData.campaign_type === 'message';
        const from = this.state.from;
        let fromText = '';

        if (from) {
            fromText = from.name;

            if (!from.isCustomField && from.communication) {
                fromText += ` (${from.communication})`;
            }
        }

        return (
            <div
                className={style.fFrom}
                onClick={this.handleDropdownClick.bind(this)}
                title={fromText}
            >
                {this.state.fromBlocked &&
                    <div
                        className='icon-blocked'
                        style={{
                            color: 'red',
                            marginRight: '5px'
                        }}
                    />
                }

                <div className={style.fName}>{fromText}</div>

                <div className={style.fArrow}/>

                <div
                    className={`
                        ${style.meDropdown}
                        ${this.state.dropdownExpanded ? style.meExpanded : ''}
                    `}
                    style={{
                        left: -10,
                        top: 5
                    }}
                >
                    <NewSelect
                        ref={(el) => this.dropdown = el}
                        data={this.getFromItems.bind(this)}
                        width={338}
                        text='name'
                        itemView={FromItemView}
                        boxStyle={{
                            visibility: 'hidden'
                        }}
                        onSelect={this.handleDropdownSelect.bind(this)}
                        onClose={this.handleDropdownClose.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

class To extends React.Component {
    render() {
        let to = this.props.name || '';

        return (
            <div className={style.fTo}>
                <div
                    className={style.tValue}
                    title={to}
                >
                    {to}
                </div>

                {to &&
                    <div
                        className={`
                            ${style.tContactsCounter}
                            ${this.props.numContacts === 0 ? style.ccEmpty : ''}
                        `}
                    >
                        {this.props.numContacts} {this.props.numContacts !== 1 ? 'contacts' : 'contact'}
                    </div>
                }
            </div>
        );
    }
}

class ContactItem extends React.Component {
    render() {
        const data = this.props.data;

        return (
            <div
                className={`
                    ${style.contactItem}
                    ${this.props.active ? style.cActive : ''}
                `}
                onClick={this.props.onSelected}
            >
                <div className={style.cContainer}>
                    <div className={style.cIconContainer}>
                        {data.photo_url ? (
                            <div
                                className={style.iImage}
                                style={{
                                    backgroundImage: `url('${data.photo_url}')`
                                }}
                            />
                        ) : (
                            <div className={`${style.iIcon} icon-user`}/>
                        )}
                    </div>

                    <div className={style.cNameAndOrg}>
                        <div className={style.cName}>{data.full_name}</div>

                        {data.organization?.name &&
                            <div className={style.cOrg}>
                                {data.organization.name}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class ContactsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIdx: 0
        };
    }

    handleContactSelect(cidx) {
        this.setState({
            activeIdx: cidx
        });

        this.props.onContactSelected(this.props.contacts[cidx]);
    }

    render() {
        return (
            <div
                className={style.pContactsList}
                style={{
                    borderBottomLeftRadius: '6px',
                    borderBottomRightRadius: '6px',
                }}
            >
                {this.props.contacts === null &&
                    <LoadingIndicator/>
                }

                {this.props.contacts !== null && this.props.contacts.map((contact, cidx) => {
                    return (
                        <ContactItem
                            key={contact.id}
                            data={contact}
                            active={cidx === this.state.activeIdx}
                            onSelected={() => this.handleContactSelect.bind(this)(cidx)}
                        />
                    );
                })}
            </div>
        );
    }
}

class CCField extends React.Component {
    constructor(props) {
        super(props);

        this.individualCustomFields = {
            individuals: [],
            deals: [],
            organizations: []
        };

        this.textIds = {
            individuals: '${ID_INDIVIDUAL}',
            deals: '${ID_DEAL}',
            organizations: '${ID_ORGANIZATION}'
        };

        if (AppConfig.getFeatureLabValue('enableGroupsForCampaigns', 'SAL-4063')) {
            const entities = ['individuals', 'deals', 'organizations'];

            for (const entity in this.individualCustomFields) {
                const icf = app.globalData.customFieldsInfo[`${entity}Array`].filter(cf => cf.type === 'individual');

                    // items.push({
                    //     title: TextManager.parseText(`${textIds[i]} custom fields`),
                    //     isSectionTitle: true
                    // });

                this.individualCustomFields[entity] = icf.map(cf => {
                    return {
                        id: cf.id,
                        title: cf.name,
                        isCustomField: true
                    };
                });
            }
        }

        let items = [];

        for (const item of this.props.items) {
            let idata = {};

            if (item.type === 'custom_field') {
                idata.id = item.custom_field_id;
                idata.title = item.custom_field.name;
                idata.isCustomField = true;
            } else {
                idata.id = item.individual_id;
                idata.title = item.individual.full_name;
            }

            items.push(idata);
        }

        this.state = {
            dropdownExpanded: false,
            items: items
        };
    }

    handleProcessData(data, textToSearch) {
        let items = [];

        data = data.filter(d => !this.state.items.find(i => i.id === d.id));

        if (data.length > 0) {
            items = [{
                title: TextManager.getText('ID_INDIVIDUAL'),
                isSectionTitle: true
            }];

            items = items.concat(data.map(i => {
                return {
                    id: i.id,
                    title: i.title
                };
            }));
        }

        const tts = textToSearch.toLowerCase();

        for (const entity in this.individualCustomFields) {
            const cfs = this.individualCustomFields[entity].filter(cf => {
                return cf.title.toLowerCase().indexOf(tts) !== -1 && !this.state.items.find(i => i.id === cf.id);
            });

            if (cfs.length > 0) {
                items.push({
                    title: TextManager.parseText(`${this.textIds[entity]} custom fields`),
                    isSectionTitle: true
                });

                items = items.concat(cfs);
            }
        }

        return items;
    }

    handleDropdownClick() {
        this.dropdown.toggleExpanded(true);

        this.setState({
            dropdownExpanded: true
        });
    }

    handleDropdownSelect(items) {
        const newItems = this.state.items.concat(items);

        this.setState({
            items: newItems
        });

        const key = this.props.isCC ? 'cc' : 'bcc';
        const data = newItems.map(i => {
            if (i.isCustomField) {
                return {
                    type: 'custom_field',
                    custom_field_id: i.id
                };
            }

            return {
                type: 'individuals',
                individual_id: i.id
            }
        });

        this.props.onDataChange(key, data);
    }

    handleDropdownClose() {
        this.setState({
            dropdownExpanded: false
        });
    }

    handleItemDelete(item) {
        const newItems = this.state.items.filter(i => i.id !== item.id);

        this.setState({
            items: newItems
        });

        const key = this.props.isCC ? 'cc' : 'bcc';
        const data = newItems.map(i => {
            if (i.isCustomField) {
                return {
                    type: 'custom_field',
                    custom_field_id: i.id
                };
            }

            return {
                type: 'individuals',
                individual_id: i.id
            }
        });

        this.props.onDataChange(key, data);
    }

    render() {
        const empty = (this.state.items.length === 0);

        return (
            <div
                className={style.fCC}
                onClick={this.handleDropdownClick.bind(this)}
            >
                {empty &&
                    <div
                        style={{
                            color: 'rgba(0, 0, 0, 0.3)'
                        }}
                    >
                        Choose {this.props.isCC ? 'CC' : 'BCC'} Fields
                    </div>
                }

                {!empty &&
                    <div className={style.fCCContainer}>
                        {this.state.items.map(item => {
                            return (
                                <div
                                    key={`cc_${item.id}`}
                                    className={style.fCCItem}
                                    onClick={(ev) => ev.stopPropagation()}
                                >
                                    {item.title}
                                    <div
                                        className={style.fCCItemClear}
                                        onClick={(ev) => { ev.stopPropagation(); this.handleItemDelete.bind(this)(item) }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                }

                <div
                    className={`
                        ${style.meDropdown}
                        ${this.state.dropdownExpanded ? style.meExpanded : ''}
                    `}
                    style={{
                        left: -10,
                        top: 5
                    }}
                >
                    <NewSelect
                        ref={(el) => this.dropdown = el}
                        url='/v1/search'
                        width={338}
                        text='title'
                        processData={this.handleProcessData.bind(this)}
                        options={{
                            minimumInputLength: 0,
                            search_parameters: {
                                types: 'individuals'
                            }
                        }}
                        boxStyle={{
                            visibility: 'hidden'
                        }}
                        onSelect={this.handleDropdownSelect.bind(this)}
                        onClose={this.handleDropdownClose.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

class LeftSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contacts: null,
            numContacts: 0
        };
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        if (this.props.campaignData.to_group?.items?.length > 0) { // it's a single contact
            $.get(`/individuals/${this.props.campaignData.to_group.items[0]}`, function(contact) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    contacts: [contact],
                    numContacts: 1
                });

                self.props.updatePreview(contact);
            });
        } else {
            let params = {
                rows:  1,
                order_by: 'first_name asc,last_name asc'
            };

            if (this.props.campaignData.campaign_type === 'message') {
                params.has_phone = true;
                params.unsubscribed_all_messages = false;
            } else {
                params.has_email = true;
                params.unsubscribed_all = false;
            }

            if (this.props.toGroup) {
                params.rows = 50;
                params.by_group_id = this.props.toGroup.id;
            }

            $.get(`/individuals?${$.param(params)}`, function(data, _, request) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    contacts: data,
                    numContacts: self.props.toGroup ? parseInt(request.getResponseHeader('Records-Total')) : 1
                });

                let contact = data.length > 0 ? data[0] : null;

                if (contact && !self.props.toGroup) {
                    contact.full_name += ' (For Preview Only)';
                }

                self.props.updatePreview(contact);
            });
        }
    }

    getNumContacts() {
        return this.state.numContacts;
    }

    isFromBlocked() {
        return this.fromComponent.isBlocked();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleFromSelect(item, dataHasChanged, blocked) {
        if (item.isCustomField) {
            if (dataHasChanged) {
                this.props.onDataChangeObj({
                    from_user: null,
                    from_user_id: null,
                    from_custom_field: { id: item.id },
                });
            }

            this.props.onFromChange(`${item.name} (Custom Field)`, blocked);
        } else {
            if (dataHasChanged) {
                this.props.onDataChangeObj({
                    from_user: { id: item.id },
                    from_custom_field: null,
                    from_custom_field_id: null
                });
            }

            this.props.onFromChange(item.communication, blocked);
        }
    }

    render() {
        const isTextMessage = this.props.campaignData.campaign_type === 'message';
        const ccFieldsVisible = !isTextMessage && this.props.userType === 'advance';

        return (
            <div
                className={`${style.sBlock} ${style.pLeft}`}
                style={{
                    width: 'calc((100% - 40px) / 3)',
                    minWidth: '350px',
                    padding: 0
                }}
            >
                <div
                    className={style.pForm}
                    style={{
                        borderTopLeftRadius: '6px',
                        borderTopRightRadius: '6px',
                    }}
                >
                    <div className={style.fRow}>
                        <div className={style.fTitle}>From:</div>
                        <div className={style.fValue}>
                            <From
                                ref={(el) => this.fromComponent = el}
                                userType={this.props.userType}
                                campaignData={this.props.campaignData}
                                onSelect={this.handleFromSelect.bind(this)}
                            />
                        </div>
                    </div>

                    {!isTextMessage &&
                        <div className={style.fRow}>
                            <div className={style.fTitle}>Subject:</div>
                            <div className={style.fValue}>
                                <Subject
                                    campaignData={this.props.campaignData}
                                    onDataChange={this.props.onDataChange}
                                    parent={this.props.parent}
                                />
                            </div>
                        </div>
                    }

                    {this.props.errors.subject &&
                        <div className={style.fError}>
                            {this.props.errors.subject}
                        </div>
                    }
                </div>

                <div className={style.pFormSeparator}/>

                <div className={style.pForm}>
                    <div className={style.fRow}>
                        <div className={style.fTitle}>To:</div>
                        <div className={style.fValue}>
                            <To
                                name={this.props.toGroup?.name || 'Template/Automation Mode Preview'}
                                numContacts={this.state.numContacts}
                            />
                        </div>
                    </div>

                    {ccFieldsVisible &&
                        <div className={style.fRow}>
                            <div className={style.fTitle}>CC:</div>
                            <div className={style.fValue}>
                                <CCField
                                    isCC={true}
                                    items={this.props.campaignData.cc || []}
                                    onDataChange={this.props.onDataChange}
                                />
                            </div>
                        </div>
                    }

                    {ccFieldsVisible &&
                        <div className={style.fRow}>
                            <div className={style.fTitle}>BCC:</div>
                            <div className={style.fValue}>
                                <CCField
                                    isCC={false}
                                    items={this.props.campaignData.bcc || []}
                                    onDataChange={this.props.onDataChange}
                                />
                            </div>
                        </div>
                    }
                </div>

                <ContactsList
                    contacts={this.state.contacts}
                    onContactSelected={this.props.updatePreview}
                />
            </div>
        );
    }
}

class MessagePreview extends React.Component {
    render() {
        return (
            <div className={style.pMessage}>
                {this.props.content}
            </div>
        );
    }
}

class HTMLPreview extends React.Component {
    componentDidMount() {
        $(this.iframe).contents().find('html').get(0).innerHTML = this.props.content;
    }

    render() {
        return (
            <iframe
                ref={(el) => this.iframe = el}
                style={{
                    width: this.props.mobileView ? '320px' : '100%'
                }}
                sandbox='allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation'
            />
        );
    }
}

class RightSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previewContent: null,
            toComm: '',
            subject: '',
            loading: false,
            mobileView: false
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    updatePreview(to) {
        let newState = {};

        if (to) {
            const self = this;
            const campaignData = _.clone(this.props.campaignData);

            this.setState({
                loading: true
            });

            const url = `/campaign_preview?individual_id=${to.id}`;

            $.post(url, JSON.stringify(campaignData))
                .done(function(preview) {
                    if (!self.mounted) {
                        return;
                    }

                    const isTextMessage = self.props.campaignData.campaign_type === 'message';

                    let newState = {
                        previewContent: preview.content,
                        subject: preview.subject,
                        loading: false
                    };

                    newState.toComm = isTextMessage ? preview.to_phone : preview.to_email;

                    self.setState(newState);
                })
                .fail(function(error) {
                    if (!self.mounted) {
                        return;
                    }

                    self.setState({
                        previewContent: '',
                        subject: '',
                        toComm: '',
                        loading: false
                    });
                });
        } else {
            this.setState({
                previewContent: null,
                toComm: '',
                subject: ''
            });
        }
    }

    handleSendTest() {
        if (this.props.fromBlocked) {
            MessageBox.showOk({
                icon: 'icon-blocked',
                message: 'You cannot send an email from an unauthorized user. Please change FROM field to an authorized user.'
            }, this.props.parent);

            return;
        }

        const defUser = [{
            id: app.user.get('id'),
            name: app.user.get('name')
        }];

        const entity = this.props.campaignData.campaign_type === 'message' ? 'message' : 'email';
        const self = this;

        MessageBox.showOk({
            icon: 'icon-user',
            message: 'Which users would you like to send a test version of this ' + entity + ' to?',
            accept_button_text: 'Send Now'
        }, this.props.parent, function(idsList) {
            self.props.sendTest(idsList);
        }, {
            type: 'tagSelect',
            value: defUser,
            idValue: defUser[0].id
        });
    }

    render() {
        const isTextMessage = this.props.campaignData.campaign_type === 'message';
        const warningMessage = isTextMessage ? 'This is for preview purposes only.' : 'This is for preview purposes only. Different email clients may display this email differently.';
        const showPreview = this.state.previewContent;
        const PreviewComponent = isTextMessage ? MessagePreview : HTMLPreview;

        return (
            <div
                className={`${style.sBlock} ${style.pRight}`}
                style={{
                    width: 'calc((((100% - 40px) / 3) + 10px) * 2)',
                    padding: 0
                }}
            >
                <div className={style.rHeader}>
                    <div className={style.rInfo}>
                        <div className={style.rRow}>
                            <div className={style.rName}>From:</div>
                            <div className={style.rValue}>{this.props.from}</div>
                        </div>

                        <div className={style.rRow}>
                            <div className={style.rName}>To:</div>
                            <div className={style.rValue}>{this.state.toComm}</div>
                        </div>

                        {!isTextMessage &&
                            <div className={style.rRow}>
                                <div className={style.rName}>Subject:</div>
                                <div className={style.rValue}>{this.state.subject}</div>
                            </div>
                        }
                    </div>

                    <div className={style.rActions}>
                        <div
                            className={style.rButton}
                            onClick={() => this.props.gotoStep('compose')}
                        >
                            Edit Content
                        </div>

                        {this.state.toComm &&
                            <div
                                className={style.rButton}
                                style={{
                                    background: '#F5A427',
                                    marginLeft: '10px'
                                }}
                                onClick={this.handleSendTest.bind(this)}
                            >
                                Send a Test {isTextMessage ? 'Message' : 'Email'}
                            </div>
                        }

                        {!isTextMessage &&
                            <div
                                className={`
                                    ${style.rIcon}
                                    icon-desktop
                                `}
                                style={{
                                    marginLeft: '20px',
                                    marginRight: '10px'
                                }}
                                onClick={() => this.setState({ mobileView: false })}
                            />
                        }

                        {!isTextMessage &&
                            <div
                                className={`
                                    ${style.rIcon}
                                    icon-phone1
                                `}
                                onClick={() => this.setState({ mobileView: true })}
                            />
                        }
                    </div>
                </div>

                <div className={style.rWarning}>
                    {warningMessage}
                </div>

                <div className={style.rPreview}>
                    {!this.state.loading && showPreview &&
                        <PreviewComponent
                            content={this.state.previewContent}
                            mobileView={this.state.mobileView}
                        />
                    }

                    {!this.state.loading && !showPreview &&
                        <div className={style.pPreviewMessage}>
                            <div className={style.mTitle}>{isTextMessage ? 'Message' : 'Email'} Preview</div>
                            <div className={style.mDescription}>
                                Will show you a separate {isTextMessage ? 'Message' : 'Email'}
                                <br/>
                                for each contact in your list
                            </div>
                        </div>
                    }

                    {this.state.loading &&
                        <div
                            style={{
                                marginTop: '20px'
                            }}
                        >
                            <LoadingIndicator/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class StepPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            from: '',
            fromBlocked: false,
            subject: this.props.campaignData.subject || '',
            to: null,
            errors: {}
        };

        this.toGroup = null;

        if (props.campaignData.campaign_subtype === 'campaign') {
            this.toGroup = props.campaignData.to_group;
        }
    }

    isReadyToFinalise() {
        this.setState({
            errors: {}
        });

        if (this.props.campaignData.campaign_type !== 'message' && !this.props.campaignData.subject) {
            this.setState({
                errors: {
                    subject: 'Email subject required'
                }
            });
            return false;
        }

        if (this.leftSection.isFromBlocked()) {
            MessageBox.showOk({
                icon: 'icon-blocked',
                message: 'You cannot send an email from an unauthorized user. Please change FROM field to an authorized user.'
            }, this.props.parent);

            return false;
        }

        return true;
    }

    handleFromChange(from, fromBlocked) {
        this.setState({
            from: from,
            fromBlocked: fromBlocked
        });
    }

    handleLeftSectionDataChange(key, data) {
        this.handleLeftSectionDataObjChange({
            [key]: data
        });
    }

    handleLeftSectionDataObjChange(data) {
        for (const key in data) {
            if (key === 'subject') {
                this.setState({
                    subject: data
                });

                break;
            }
        }

        this.props.updateCampaignDataObj(data);

        const self = this;

        _.defer(function() {
            self.rightSection.updatePreview(self.state.to);
        });
    }

    handlePreviewUpdate(to) {
        this.setState({
            to: to
        });

        this.rightSection.updatePreview(to);
    }

    handleTestSend(idsList) {
        if (idsList.length === 0) {
            return;
        }

        this.props.sendTest({
            to: idsList.join(','),
            individualId: this.state.to.id
        });
    }

    onNext() {
        this.setState({
            errors: {}
        });

        if (this.props.campaignData.campaign_type !== 'message' && !this.props.campaignData.subject) {
            this.setState({
                errors: {
                    subject: 'Email subject required'
                }
            });
            return;
        }


        if (this.leftSection.getNumContacts() === 0) {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'The selected group contains no contacts. Please add recipients or choose another group.'
            }, this.props.parent);

            return;
        }

        if (this.leftSection.isFromBlocked()) {
            MessageBox.showOk({
                icon: 'icon-blocked',
                message: 'You cannot send an email from an unauthorized user. Please change FROM field to an authorized user.'
            }, this.props.parent);

            return;
        }

        this.props.gotoNextStep();
    }

    render() {
        return (
            <div className={`${style.cStep} ${style.cPreview}`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}
                >
                    <LeftSection
                        ref={(el) => this.leftSection = el}
                        campaignData={this.props.campaignData}
                        userType={this.props.userType}
                        toGroup={this.toGroup}
                        parent={this.props.parent}
                        errors={this.state.errors}
                        onFromChange={this.handleFromChange.bind(this)}
                        onDataChange={this.handleLeftSectionDataChange.bind(this)}
                        onDataChangeObj={this.handleLeftSectionDataObjChange.bind(this)}
                        updatePreview={this.handlePreviewUpdate.bind(this)}
                    />

                    <RightSection
                        ref={(el) => this.rightSection = el}
                        campaignData={this.props.campaignData}
                        parent={this.props.parent}
                        from={this.state.from}
                        fromBlocked={this.state.fromBlocked}
                        subject={this.state.subject}
                        gotoStep={this.props.gotoStep}
                        sendTest={this.handleTestSend.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default StepPreview;
import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import Utilities from 'js/utils/utilities'
import attachmentsTemplate from 'templates/activity/email-viewer-attachments.handlebars'
import attachmentItemTemplate from 'templates/activity/email-viewer-attachment-item.handlebars'


var attachmentItemView = Marionette.ItemView.extend({
    template: Handlebars.compile(attachmentItemTemplate),
    tagName: 'div',
    className: 'item-container',
    ui: {
        downloadForm: '.download-form'
    },
    events: {
        'click .icon-arrow-down': function() {
            this.ui.downloadForm.submit();
        }
    },
    templateHelpers: function() {
        var name = this.model.get('name');
        var dotPos = name.lastIndexOf('.');
        var ext = dotPos !== -1 ? name.substr(dotPos) : '';
        var fileInfo = Utilities.getTypeIcon(ext);

        return {
            name: name,
            icon: fileInfo.icon,
            fileType: fileInfo.type,
            downloadAction: app.options.apiUrl + '/content_files/' + this.model.get('id') + '?download'
        }
    }
});

var attachmentsGridRowView = Marionette.CompositeView.extend({
    template: Handlebars.compile('<div class="row-fluid"></div>'),
    itemView: attachmentItemView,
    itemViewContainer: 'div.row-fluid',
    initialize: function() {
        this.collection = new Backbone.Collection(_.toArray(this.model.attributes));
    }
});

var attachmentsGridView = Marionette.CompositeView.extend({
    template: Handlebars.compile(''),
    itemView: attachmentsGridRowView,
    initialize: function() {
        var grid = this.collection.groupBy(function(list, iterator) {
            return Math.floor(iterator / 2); // 2 = num of columns
        });

        this.collection = new Backbone.Collection(_.toArray(grid));
    }
});

export default  Marionette.Layout.extend({
    template: Handlebars.compile(attachmentsTemplate),
    regions: {
        containerRegion: '.grid-container'
    },
    ui: {
        arrow: '.arrow'
    },
    events: {
        'click .header': function() {
            this.containerRegion.$el.toggleClass('show');
            this.ui.arrow.toggleClass('down');
            this.trigger('attachments:change-visualization');
        }
    },
    templateHelpers: function() {
        var len = this.options.collection.length;

        return {
            numberAttachments: len + (len === 1 ? ' attachment' : ' attachments')
        };
    },
    onShow: function() {
        this.containerRegion.show(new attachmentsGridView({
            collection: this.options.collection
        }));
    }
});
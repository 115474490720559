import $ from 'jquery'
import _ from 'underscore'

import app from 'js/app'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import PaginatedListView from 'js/views/base/paginated_list.js'
import OpportunityItemView from 'js/views/opportunities/item.js'
import ListNavbarView from 'js/views/base/list_navbar.js'
import BaseToolbarView from 'js/views/base/toolbar.js'
import OpportunitiesCollection from 'js/collections/opportunities'


/*
 * contactModel         set contactModel if you want to generate companies name from contacts name
 * organizationModel    pass organization to be used when creating deals
 */
    function createDealsList(topView, collection, tools, extraTools, contactModel, organizationModel, fromIndividualModel) {
    var navbarView, listView, toolbarView;

    var dealsText = TextManager.parseText('XX${ID_DEAL, plural, capitalize}');

    if (fromIndividualModel) {
        var defaultSet = false;

        var individualOpportunitiesCollection = new (OpportunitiesCollection.extend({
            urlRoot: function () {
                return '/individuals/' + fromIndividualModel.get('id') + '/opportunities';
            },
            defaultSortOn: [{
                attribute: 'full_name',
                order: 'asc'
            }]
        }));

        var createIndividualOpportunitiesListView = function () {
            var IndividualOpportunitiesListView = new PaginatedListView({
                id: 'deals-list',
                listItemView: OpportunityItemView,
                listItemViewOptions: {
                    section: 'Deals',
                    url: 'opportunities',
                    tools: tools
                },
                showItem: function (model) {
                    topView.trigger('replace-view:deal:show', {
                        model: model
                    });
                },
                collection: individualOpportunitiesCollection
            });

            IndividualOpportunitiesListView.listenTo(IndividualOpportunitiesListView, 'followLink', function(model, link) {
                app.followLink(model, link);
            });

            if (!defaultSet) {
                IndividualOpportunitiesListView.listenTo(IndividualOpportunitiesListView, 'collectionCountChange', function (count, loaded) {
                    // switch to organization deals, if there is no direct deal and individual has organization
                    // collection is undefined if individual has no organization
                    if (loaded && count === 0 && collection) {
                        navbarView.showTab('organizationsDealsTab');
                    }
                });
                defaultSet = true;
            }

            return IndividualOpportunitiesListView;
        };

        var createListView = function() {
            var listView = new PaginatedListView({
                id: 'deals-list',
                listItemView: OpportunityItemView,
                listItemViewOptions: {
                    section: 'Deals',
                    url: 'opportunities',
                    tools: tools
                },
                showItem: function(model) {
                    topView.trigger('replace-view:deal:show', {
                        model: model
                    });
                },
                collection: collection
            });

            listView.listenTo(listView, 'followLink', function(model, link) {
                app.followLink(model, link);
            });

            return listView;
        };

        var createIndividualToolbarView = function() {
            return new BaseToolbarView({
                collection: individualOpportunitiesCollection,
                tools: _.extend({
                    newItem: true,
                    addItem: true,
                    removeItem: true,
                    moveToGroup: true,
                    selectAllPage: true
                }, extraTools),
                toolLabels: {
                    removeItem: TextManager.getText('ID_ENTITY_REMOVE_FROM', ['${ID_INDIVIDUAL, capitalize}'])
                }
            });
        };

        var createToolbarView = function() {
            return new BaseToolbarView({
                collection: collection,
                tools: _.extend({
                    selectAllPage: true,
                    customAction: true
                }, extraTools),
                toolLabels: {
                    customAction: TextManager.getText('ID_ENTITY_ADD_TO', ['${ID_INDIVIDUAL, capitalize}'])
                },
                tooltTitles: {
                    customAction: TextManager.getText('ID_ENTITY_ADD_SELECTED_TO', ['${ID_INDIVIDUAL, capitalize}'])
                }
            });
        };

        var tabs = [
            {
                id: 'individualsDealsTab',
                title: TextManager.getText('ID_ENTITY_OWNS_ENTITY', ['${ID_INDIVIDUAL, capitalize}', '${ID_DEAL, plural, capitalize}']),
                'class': 'list-nav-deals',
                view: createIndividualOpportunitiesListView,
                toolbarView: createIndividualToolbarView
            }
        ];

        if (collection) {
            tabs.push({
                id: 'organizationsDealsTab',
                title: 'Organization\'s Deals',
                'class': 'list-nav-deals',
                view: createListView,
                toolbarView: createToolbarView
            });
        }

        navbarView = new ListNavbarView({
            title: dealsText,
            showTabs: true,
            iconClass: 'icon-filter',
            tabs: tabs
        });

        navbarView.listenTo(navbarView, 'individualsDealsTab:showItem organizationsDealsTab:showItem', function(model) {
            if (topView.pushIndividuals) {
                topView.trigger('push-view:deal:show', {
                    model: model
                });
            }
            else {
                topView.trigger('replace-view:deal:show', {
                    model: model
                });
            }
        });

        navbarView.listenTo(navbarView, 'individualsDealsTab:toolbar:new', function() {
            topView.trigger('replace-view:deal:new', {
                organization: organizationModel,
                fromIndividualModel: fromIndividualModel
            });
        });

        navbarView.listenTo(navbarView, 'individualsDealsTab:toolbar:add-item', function(model) {
            $.ajax({
                url: '/individuals/' + fromIndividualModel.get('id') + '/opportunities/' + model.get('id'),
                method: 'PUT',
                contentType: 'application/json',
                success: function(item) {
                    individualOpportunitiesCollection.add(item);
                }
            });
        });

        navbarView.listenTo(navbarView, 'individualsDealsTab:toolbar:remove', function(selection) {
            var ids = _.map(selection.items, function(item) {
                return { id: item.model.get('id') };
            });
            $.ajax({
                url: '/individuals/' + fromIndividualModel.get('id') + '/opportunities',
                method: 'DELETE',
                data: JSON.stringify(ids),
                contentType: 'application/json',
                success: function() {
                    individualOpportunitiesCollection.remove(ids);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.getText('ID_ENTITY_REMOVED_FROM_ENTITY', ['${ID_DEAL, capitalize, pluralize=' + ids.length + '}', '${ID_INDIVIDUAL}']),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        });

        navbarView.listenTo(navbarView, 'organizationsDealsTab:toolbar:customAction', function(selection) {
            var ids = _.map(selection.items, function(item) {
                return { id: item.model.get('id') };
            });
            $.ajax({
                url: '/individuals/' + fromIndividualModel.get('id') + '/opportunities',
                method: 'PUT',
                data: JSON.stringify(ids),
                contentType: 'application/json',
                success: function() {
                    selection.success();
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.getText('ID_ENTITY_ADDED_TO_ENTITY', ['${ID_DEAL, capitalize, pluralize=' + ids.length + '}', '${ID_INDIVIDUAL}']),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        });
    }
    else {
        listView = new PaginatedListView({
            id: 'deals-list',
            listItemView: OpportunityItemView,
            listItemViewOptions: {
                section: 'Deals',
                url: 'opportunities',
                tools: tools
            },
            collection: collection,
            fetchOnShow: false
        });

        toolbarView = new BaseToolbarView({
            tools: _.extend({
                newItem: true,
                removeItem: true,
                moveToGroup: true,
                selectAllPage: true
            }, extraTools)
        });

        navbarView = new ListNavbarView({
            title: dealsText,
            showTabs: false,
            iconClass: 'icon-filter',
            tabs: [
                {
                    id: 'deals',
                    title: dealsText,
                    'class': 'list-nav-deals',
                    view: listView,
                    toolbarView: toolbarView
                }
            ]
        });

        navbarView.listenTo(navbarView, 'showItem', function (model) {
            topView.trigger('replace-view:deal:show', {
                model: model
            });
        });

        navbarView.listenTo(navbarView, 'toolbar:new', function () {
            topView.trigger('replace-view:deal:new', {
                organization: organizationModel,
                contactModel: contactModel
            });
        });
    }

    return navbarView;
}

export default createDealsList;

import React from 'react'

import app from 'js/app'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import { NewSelect } from 'js/react_views/widgets/select'

import style from './funnel_colorkey.css'


class ColorkeySelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { item, onClick, onMouseEnter } = this.props;

        return (
            <li
                className={`
                    ${style.ckDropdownItem}
                    ${this.state.selected ? style.iSelected : ''}
                `}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
            >
                <div className={style.iTitle}>{item.text}</div>
                <div className={style.iDescription}>{item.description}</div>
            </li>
        );
    }
}

class None extends React.Component {
    render() {
        return (
            <div className={style.none}>No color key applied</div>
        );
    }
}

class CloseDate extends React.Component {
    render() {
        return (
            <div className={style.eList}>
                <div
                    className={`close-in-color ${style.eEntry}`}
                    title='Close date is before the current period end'
                >
                    <div className='value'>Before period end</div>
                </div>

                <div
                    className={`close-out-color ${style.eEntry}`}
                    title='Close date is after the current period end'
                >
                    <div className='value'>After period end</div>
                </div>
            </div>
        );
    }
}

class Activity extends React.Component {
    render() {
        return (
            <div className={style.eList}>
                <div
                    className={`activity-last-day ${style.eEntry}`}
                    title='Active in the last 2 days'
                >
                    <div className='value'>&lt; 2d</div>
                </div>

                <div
                    className={`activity-last-5-days ${style.eEntry}`}
                    title='Active between 2-6 days ago'
                >
                    <div className='value'>2-6d</div>
                </div>

                <div
                    className={`activity-last-2-weeks ${style.eEntry}`}
                    title='Active between 1-2 weeks ago'
                >
                    <div className='value'>1-2w</div>
                </div>

                <div
                    className={`activity-last-month ${style.eEntry}`}
                    title='Active between 2-4 weeks ago'
                >
                    <div className='value'>2-4w</div>
                </div>

                <div
                    className={`activity-more-1-month ${style.eEntry}`}
                    title='Active over 4 weeks ago'
                >
                    <div className='value'>4+w</div>
                </div>
            </div>
        );
    }
}

class Forecast extends React.Component {
    render() {
        return (
            <div className={style.eList}>
                <div
                    className={`forecast-committed ${style.eEntry}`}
                    title='Forecast status is Committed'
                >
                    <div className='value'>Commited</div>
                </div>

                <div
                    className={`forecast-downside ${style.eEntry}`}
                    title='Forecast status is Committed Downside'
                >
                    <div className='value'>Downside</div>
                </div>

                <div
                    className={`forecast-upside ${style.eEntry}`}
                    title='Forecast status is None Upside'
                >
                    <div className='value'>Upside</div>
                </div>

                <div
                    className={`forecast-out ${style.eEntry}`}
                    title='Forecast status is None'
                >
                    <div className='value'>None</div>
                </div>
            </div>
        );
    }
}

class GoAssignIssue extends React.Component {
    render() {
        return (
            <div className={style.eList}>
                <div
                    className={`go-assign-issue-go ${style.eEntry}`}
                >
                    <div className='value'>Go</div>
                </div>

                <div
                    className={`go-assign-issue-assign ${style.eEntry}`}
                >
                    <div className='value'>Assign</div>
                </div>

                <div
                    className={`go-assign-issue-issue ${style.eEntry}`}
                >
                    <div className='value'>Issue</div>
                </div>
            </div>
        );
    }
}

class Custom extends React.Component {
    render() {
        const options = app.globalData.colorkeysInfo[this.props.data.dropdown_id] || {};

        return (
            <div className={style.eList}>
                {this.props.data.colors.map(color => {
                    let cStyle = {
                        backgroundColor: color.hex
                    };

                    const name = options[color.dropdown_id] || color.name;

                    if (color.font_hex) {
                        cStyle.color = color.font_hex;
                    }

                    return (
                        <div
                            key={color.option_id || color.id}
                            className={style.eEntry}
                            style={cStyle}
                        >
                            <div
                                className='value'
                                title={name}
                            >
                                {name}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default class FunnelColorKey extends React.Component {
    constructor(props) {
        super(props);

        this.colorkeys = [{
            id: 'none',
            text: 'None',
            description: 'Remove all color from funnel.'
        }, {
            id: 'closedate',
            text: 'Close',
            description: 'Color by close date, relative to the current period end.'
        }, {
            id: 'activity',
            text: 'Activity',
            description: TextManager.getText('ID_FUNNEL_COLOR_BY_DEAL_ACTIVITY')
        }];

        if (!AppConfig.getValue('disableForecastOptionFromFunnelColorWidget')) {
            this.colorkeys.push({
                id: 'forecast',
                text: 'Forecast',
                description: TextManager.getText('ID_FUNNEL_COLOR_BY_FORECAST_STATUS')
            });
        }

        if (AppConfig.getValue('enableGoAssignIssueOptionFromFunnelColorWidget')) {
            this.colorkeys.push({
                id: 'goAssignIssue',
                text: 'Go-Assign-Issue',
                description: 'Color priority status.'
            });
        }

        if (AppConfig.getValue('customColorKeys')) {
            const clientPreferences = app.user.get('client').preferences;
            const customColorkeys = clientPreferences?.custom_colorkeys ? JSON.parse(clientPreferences.custom_colorkeys) : [];

            if (customColorkeys && customColorkeys.length > 0) {
                for (const cfColorKeys of customColorkeys) {
                    this.colorkeys.push({
                        id: `custom_colorkey_${cfColorKeys.dropdown_id}`,
                        dropdown_id: cfColorKeys.dropdown_id,
                        is_system_field: cfColorKeys.is_system_field,
                        text: cfColorKeys.name,
                        description: cfColorKeys.description,
                        colors: cfColorKeys.colours || cfColorKeys.colours_ex
                    });
                }
            }
        }

        const preferences = app.user.get('preferences');
        const initialColorkey = AppConfig.getValue('funnel.colorkey_widget.fixed_value') || preferences.funnel_color || 'activity';

        this.activeColorkey = this.colorkeys.find(ck => ck.id === initialColorkey) || this.colorkeys[2];

        this.state = {
            activeComponent: this.getColorkeyComponentById(this.activeColorkey.id)
        };
    }

    onColorkeyChange(items) {
        this.activeColorkey = items[0];

        this.props.onColorkeyChanged(this.activeColorkey.id);

        this.setState({
            activeComponent: this.getColorkeyComponentById(this.activeColorkey.id)
        });

        let preferences = app.user.get('preferences');
        preferences.funnel_color = this.activeColorkey.id;
        $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
    }

    getColorkeyComponentById(id) {
        if (id.indexOf('custom_colorkey_') === 0) {
            return Custom;
        }

        return {
            none: None,
            closedate: CloseDate,
            activity: Activity,
            goAssignIssue: GoAssignIssue,
            forecast: Forecast
        }[id];
    }

    render() {
        const ActiveComponent = this.state.activeComponent;

        return (
            <div className={`funnel-widget inverse ${style.colorkey}`}>
                {!AppConfig.getValue('funnel.colorkey_widget.fixed_value') &&
                    <div className={style.ckDropdown}>
                        <NewSelect
                            data={this.colorkeys}
                            value={this.activeColorkey}
                            text='text'
                            itemView={ColorkeySelectItem}
                            onSelect={this.onColorkeyChange.bind(this)}
                            options={{
                                minimumInputLength: -1,
                                maxHeight: 500
                            }}
                        />
                    </div>
                }

                <div
                    id='color-key-widget'
                    className={`
                        ${style.ckEntries}
                        ${AppConfig.getValue('funnel.colorkey_widget.fixed_value') ? style.eNoDropdown : ''}
                    `}
                >
                    <ActiveComponent
                        data={this.activeColorkey}
                    />
                </div>
            </div>
        );
    }
}
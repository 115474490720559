import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import backboneSelect2 from 'js/widgets/backbone-select2'
import ModalRegion from 'js/views/base/modal-region'
import readerTemplate from 'templates/pdf_viewer.handlebars'
import loadingDocumentTemplate from 'templates/generating_template.handlebars'

const { pdfjsLib } = globalThis;

var LoadingView = Marionette.Layout.extend({
    className: 'loading-view',
    template: Handlebars.compile(loadingDocumentTemplate),
    templateHelpers: function() {
        return {
            title: 'Loading document...',
            message: 'Please wait.'
        };
    }
});

var PDFViewer = Marionette.Layout.extend({
    template: Handlebars.compile(readerTemplate),
    ui: {
        pdf_canvas: '#pdf-canvas',
        modal_body: '.modal-body',
        doc_size: '.pdf-doc-size',
        num_pages: '#num-pages',
        current_page: '#current-page',
        container: '.content-container',
        fullscreen_icon: '#full-screen-icon'
    },
    regions: {
        loadingRegion: {
            selector: '.loading-pdf-document',
            regionType: ModalRegion.extend({backdrop: 'static'})
        }
    },
    events: {
        'change .pdf-doc-size': function(ev) {
            ev.preventDefault();
            this.renderPage();
        },
        'click #less-zoom': function(ev) {
            ev.preventDefault();
            this.automaticZoom = Math.max(0.25, this.automaticZoom - 0.1);
            this.ui.doc_size.select2('val', 'automatic-zoom');
            this.renderPage();
        },
        'click #more-zoom': function(ev) {
            ev.preventDefault();
            this.automaticZoom = Math.min(this.automaticZoom + 0.1, 10);
            this.ui.doc_size.select2('val', 'automatic-zoom');
            this.renderPage();
        },
        'click #prev-page': function(ev) {
            ev.preventDefault();
            if (this.currentPage > 1) {
                --this.currentPage;
                this.ui.current_page.val(this.currentPage);
                this.renderPage();
            }
        },
        'click #next-page': function(ev) {
            ev.preventDefault();
            if (this.currentPage < this.pdfDoc.numPages ) {
                ++this.currentPage;
                this.ui.current_page.val(this.currentPage);
                this.renderPage();
            }
        },
        'change #current-page': function(ev) {
            ev.preventDefault();
            var val = parseInt($(ev.currentTarget).val(), 10);

            if (val && (val !== this.currentPage) && (val > 0) && (val <= this.pdfDoc.numPages)) {
                this.currentPage = val;
                this.renderPage();
            }

            $(ev.currentTarget).val(this.currentPage);
        },
        'click #full-screen': function(ev) {
            ev.preventDefault();

            if (this.ui.fullscreen_icon.hasClass('icon-resize-enlarge')) {
                this.ui.fullscreen_icon.removeClass('icon-resize-enlarge');
                this.ui.fullscreen_icon.addClass('icon-resize-shrink');
            }
            else {
                this.ui.fullscreen_icon.addClass('icon-resize-enlarge');
                this.ui.fullscreen_icon.removeClass('icon-resize-shrink');
            }

            this.trigger('pdf-viewer:toggle-fullscreen');
            this.renderPage(); // to adjust to viewer changes
            this.ui.container.nanoScroller();
        }
    },

    initialize: function(options) {
        this.options = options;
        this.pdfDoc = null;
        this.automaticZoom = 1.0;
        this.currentPage = 1;

        pdfjsLib.GlobalWorkerOptions.workerSrc = '../vendor/pdfjs-3.11.174/pdf.worker.js';
    },

    onShow: function() {
        var data = [
            {
                id: 'automatic-zoom',
                name: 'Automatic Zoom'
            },
            {
                id: 'actual-size',
                name: 'Actual Size'
            },
            {
                id: 'fit-width',
                name: 'Fit Width'
            },
            {
                id: 'fit-height',
                name: 'Fit Height'
            }
        ];

        var vals = [100, 125, 150, 200, 300, 400];

        for (var i = 0; i < vals.length; ++i) {
            data.push({
                id: String(vals[i]),
                name: vals[i] + '%'
            });
        }

        this.selectSize = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.doc_size,
            data: data,
            value: 'actual-size',
            options: {
                containerCssClass: 'select2-small',
                dropdownCssClass: 'sorter-select-popover popover',
                minimumResultsForSearch: -1
            }
        });


        // this.$el.parent().addClass('pdf-modal');

        if (this.options.pdf_file_id) {
            this.showPDF(this.options.pdf_file_id);
        }
    },

    showPDF: function(file_id, resetView) {
        if (file_id) {
            var view = this;
            var url = app.options.apiUrl + '/content_files/' + file_id + '?download';

            this.currentPage = 1;
            this.ui.current_page.val(this.currentPage);

            if (resetView) {
                this.automaticZoom = 1.0;
                this.ui.doc_size.select2('val', 'actual-size');
            }

            this.loadingRegion.show(new LoadingView());

            pdfjsLib.getDocument(url).promise.then(function(doc) {
                view.pdfDoc = doc;
                view.ui.num_pages.text(doc.numPages);
                view.renderPage();
                view.loadingRegion.reset();
            }).catch(function(e) {
                view.pdfDoc = null;
                view.ui.num_pages.text(0);
                view.renderPage();
                view.loadingRegion.reset();
                if (e.name === "MissingPDFException") {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Missing file in system',
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
                else {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'There was an error processing this request',
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
        else {
            this.pdfDoc = null;
            this.automaticZoom = 1.0;
            this.currentPage = 1;

            var canvas = this.ui.pdf_canvas;
            var context = canvas[0].getContext('2d');

            // clear the canvas
            context.clearRect(0, 0, canvas.attr('width'), canvas.attr('height'));
        }
    },

    getDocScale: function(pageWidth, pageHeight) {
        var value = this.ui.doc_size.select2('val');

        switch(value) {
            case 'automatic-zoom':
                break;

            case 'actual-size':
                this.automaticZoom = 1.0;
                break;

            case 'fit-width':
                this.automaticZoom = this.ui.modal_body.width() / pageWidth;
                break;

            case 'fit-height':
                var ws = this.ui.modal_body.width() / pageWidth;
                var hs = this.ui.modal_body.height() / pageHeight;
                this.automaticZoom = Math.min(ws, hs);
                break;

            default:
                this.automaticZoom = parseFloat(value) / 100.0;
                break;
        }

        return this.automaticZoom;
    },

    renderPage: function() {
        var view = this;
        var canvas = this.ui.pdf_canvas;

        if (this.pdfDoc) {
            this.pdfDoc.getPage(this.currentPage).then(function (page) {
                var scale = view.getDocScale(page._pageInfo.view[2], page._pageInfo.view[3]);
                var viewport = page.getViewport({scale: scale});

                canvas.attr('width', viewport.width);
                canvas.attr('height', viewport.height);
                canvas.css('width', viewport.width);
                canvas.css('height', viewport.height);

                var renderContext = {
                    canvasContext: canvas[0].getContext('2d'),
                    viewport: viewport
                };

                var renderTask = page.render(renderContext);

                renderTask.promise.then(function () {
                    view.ui.container.nanoScroller();
                });
            });
        }
        // remove previously rendered pdf if current not set due to error
        else {
            view.ui.pdf_canvas.html('');

            canvas.attr('width', 0);
            canvas.attr('height', 0);

            view.ui.container.nanoScroller();
        }
    }
});

export default PDFViewer;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import AppConfig from 'app/app-config'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import ModalRegion from 'js/views/base/modal-region.js'
import PhasesCollection from 'js/collections/phases.js'
import PhaseModel from 'js/models/phase.js'
import FunnelsCollection from 'js/collections/funnels.js'
import FunnelModel from 'js/models/funnel.js'
import OpportunitiesCollection from 'js/collections/opportunities.js'
import CustomFieldsCollection from 'js/collections/custom_fields'
import PeriodsCollection from 'js/collections/periods.js'
import ClustersCollection from 'js/collections/clusters.js'
import MessageBox from 'js/views/message_box.js'
import FilterAdvancedView from 'js/views/filters/advanced'
import FilterFields from 'js/views/filters/fields'
import filterOperators from 'js/views/filters/operators'
import Currency from 'js/utils/currency'
import ItemPermissionsView from 'js/views/item_permissions';
import RegionCollection from 'src/js/collections/region.js'
import RegionModel from 'js/models/region.js'
import YourkeysIframeDataView from 'js/react_views/yourkeys-iframe/yourkeys-iframe';

import regionFunnelsTemplate from 'templates/settings/region_funnels.handlebars'
import funnelPhasesListItemViewTemplate from 'templates/settings/funnel_phases_list_item_view.handlebars'
import addNewPhaseTemplate from 'templates/settings/funnel_phases_add_new_phase.handlebars'
import addNewFunnelTemplate from 'templates/settings/funnel_add_new_funnel.handlebars'
import phaseGateFilterTemplate from 'templates/settings/phase_gate_filter.handlebars'
import PhaseFilterItemsView from 'js/react_views/phase-filters/phaseFilters.js'
import funnelPeriodsListItemViewTemplate from 'templates/settings/funnel_periods_list_item_view.handlebars'
import addNewRegionTemplate from 'templates/settings/region_add_new_region.handlebars'
import regionItemViewTemplate from 'templates/settings/region_item_view.handlebars'
import funnelListItemView from 'templates/settings/funnel_list_item_view.handlebars'
import regionAddFunnel from 'templates/settings/region_add_funnel.handlebars'
import regionFunnelsView from 'templates/settings/region_funnels_view.handlebars'
import funnelViewTemplate from 'templates/settings/funnel_view_item_template.handlebars'
import funnelCommunicationTemplate from 'templates/settings/funnel_communication_template.handlebars'
import funnelMarketingOpeningHoursTemplate from 'templates/settings/funnel_opening_hours.handlebars'

var RegionFunnelView, NewRegionView, RegionList, RegionItemView, FunnelListView, FunnelListItemView, AddFunnelToRegion, FunnelView, FunnelCommunicationsView;
var PhaseList, PhaseItemView, NewPhaseView, NewFunnelView, PhaseFilterView, FunnelMarketingOpeningHours;

var DEAL_GROUPS = [
    TextManager.parseText('${ID_DEAL, capitalize}'),
    TextManager.getText('ID_DEAL_BUCKET'),
    TextManager.getText('ID_ENTITY_ACTIVITY', ['${ID_DEAL, capitalize}']),
    TextManager.getText('ID_ENTITY_PINNED_ACTIVITY', ['${ID_DEAL, capitalize}']),
    TextManager.getText('ID_ENTITY_CHECKLIST', ['${ID_DEAL, capitalize}'])
]

var filterCustomFields = {
    'opportunity_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_DEAL, capitalize}']),
        'view': 'deals'
    }
};

PhaseItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(funnelPhasesListItemViewTemplate),
    ui: {
        name: '.name',
        editName: '.edit-name',
        hint: '.hint',
        editHint: '.edit-hint',
        weight: '.weight',
        editWeight: '.edit-weight',
        entranceGate: '#entrance-gate',
        exitGate: '#exit-gate',
        tooltips: '[data-toggle=tooltip]'
    },
    attributes: function() {
        return {
            id: this.model.get('id')
        };
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .delete-item': function() {
            // check there are more phases
            if (this.collection.length === 1) {
                this.trigger('phase:invalid-delete', 'This is the only phase and cannot be deleted.');
                return;
            }

            // check don't exist deals on this phase
            var opps = new OpportunitiesCollection();
            var self = this;

            opps.fetch({
                data: {
                    phase_id: this.model.get('id')
                },
                rows: 1,
                success: function() {
                    if (opps.length > 0 ) {
                        self.trigger('phase:invalid-delete', TextManager.getText('ID_PHASE_WITH_OPEN_DEALS'));
                    }
                    else {
                        self.model.destroy({ wait: true });
                    }
                }
            });
        },
        'click #entrance-gate': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('edit-entrance-filter', this.model);
        },
        'click #exit-gate': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('edit-exit-filter', this.model);
        }
    },
    initialize: function() {
        this.listenTo(vent, "filterChange", function(phaseId, gate, filterCount){
            if(this.model.get("id") === phaseId){
                gate === "entrance" ? this.ui.entranceGate.text(filterCount) : this.ui.exitGate.text(filterCount)
            }
        })
    },
    templateHelpers: function() {
        return {
            isUserPhase: this.model.get('phase_type') === 'user' ? true : false,
            entranceCount: this.model.get('entrance_gate_filter') ? this.model.get('entrance_gate_filter').rules.length : 0,
            exitCount: this.model.get('exit_gate_filter') ? this.model.get('exit_gate_filter').rules.length : 0
        };
    },
    onRender: function() {
        this.activateElement(this.ui.name, this.ui.editName, {field: 'name'});
        this.activateElement(this.ui.hint, this.ui.editHint, {field: 'hint'});
        this.activateElement(
            this.ui.weight,
            this.ui.editWeight,
            {field: 'default_weight', parse: parseFloat, limits: { min:0, max: 1}}
        );

        this.ui.tooltips.tooltip({
            container: this.$el,
            placement: 'left'
        });

        //TODO Feature lab
        if(!_.contains(app.user.get('preferences').lab_flags, 'SAL-4398')){
            this.ui.entranceGate.parent().remove();
            this.ui.exitGate.parent().remove();
            this.ui.name.parent().parent().css({
                width: '23%'
            });
            this.ui.hint.parent().parent().css({
                width: '50%'
            });
        }
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val(),
                attrs = {
                    funnel_id: self.options.funnelId,
                    phase_id: self.model.get('id')
                },
                model = self.model;

            if (dbField.parse) {
                val = dbField.parse(val);
                if (_.isNaN(val)) {
                    return;
                }
                edit.val(val);
            }

            if (dbField.limits) {
                if (val < dbField.limits.min) {
                    val = dbField.limits.min;
                }
                else if (val > dbField.limits.max) {
                    val = dbField.limits.max;
                }
                edit.val(val);
            }

            attrs[dbField.field] = val;

            // If no value is provided for hint - display placeholder
            if (dbField.field === 'hint' && !val) {
                val = 'No info provided';
            }

            model.save(attrs, {
                patch: true,
                wait: true,
                success: function() {
                    text.text(val);
                    hideEdit();
                }
            });

        });

        edit.on('blur', hideEdit);
    },
    onBeforeClose: function() {
        // sanitize tooltips
        this.ui.tooltips.tooltip('destroy');
    }
});

PhaseList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'phase-list editable-list',
    itemView: PhaseItemView,
    itemViewOptions: function() {
        return {
            collection: this.collection,
            funnelId: this.options.funnelId,
            parent: this
        };
    },
    initialize: function() {
        var self = this;

        this.collectionDict = {};

        _.each(this.collection.models, function(m) {
            self.collectionDict[m.get('id')] = m;
        });
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: false,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var movingId = $(ui.item).attr('id');
                var children = self.$el.children();

                var successCallback = function() {
                    self.trigger('change:order');
                };

                for (var i = 0; i < children.length; ++i) {
                    var c = $(children[i]);
                    var id = c.attr('id');

                    if (movingId === id) {
                        var model = self.collectionDict[movingId];

                        if (model.get('order') !== i) {
                            var attrs = {
                                funnel_id: self.options.funnelId,
                                order: i
                            };

                            model.save(attrs, {
                                patch: true,
                                wait: true,
                                success: successCallback
                            });
                        }

                        break;
                    }
                }
            }
        });
    }
});

var PeriodItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(funnelPeriodsListItemViewTemplate),
    templateHelpers: function() {
        return {
            value: this.value
        };
    },
    ui: {
        value: '.value',
        editValue: '.edit-value',
        editContainer: '.editable-field-container'
    },
    attributes: function() {
        return {
            id: this.model.get('id')
        };
    },
    events: {
        'change .edit-value': function(ev) {
            var input = $(ev.target);
            var value = parseFloat(input.val());

            if (!_.isNaN(value)) {
                input.val($.trim(value));
                this.trigger('period:save', value);
            } else {
                this.ui.editContainer.removeClass('editing');
            }
        },
        'blur .edit-value': function() {
            this.ui.editContainer.removeClass('editing');
        },
        'click .edit-icon': function() {
            this.ui.editContainer.addClass('editing');
            this.ui.editValue.val(this.value);
            this.ui.editValue.focus();
            this.ui.editValue.select();
        }
    },
    initialize: function(options) {
        this.value = 0;

        const periods = options.funnelBudget.periods || [];
        const period = periods.find(p => p.id === this.model.get('id'));

        if (period) {
            this.value = period.value;
        }
    },
    onValueSaved: function(value) {
        this.value = value;
        this.ui.value.text(this.value);
        this.ui.editContainer.removeClass('editing');
    }
});

var PeriodList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'periods-list editable-list',
    itemView: PeriodItemView,
    itemViewOptions: function() {
        return {
            collection: this.collection,
            funnelBudget: this.options.funnelBudget
        };
    },
    initialize: function() {
        this.on('itemview:period:save', function(childView, value) {
            const periodId = childView.model.get('id');
            let funnelBudget = _.clone(this.options.funnelBudget);
            let periods = funnelBudget.periods || [];
            let period = periods.find(p => p.id === periodId);

            if (period) {
                period.value = value;
            } else {
                periods.push({
                    id: periodId,
                    value: value
                });
            }

            const clientPreferences = _.clone(app.user.get('client').preferences) || {};
            const funnelBudgets = clientPreferences.funnel_budgets ? JSON.parse(clientPreferences.funnel_budgets) : [];
            let prefFunnelBudget = funnelBudgets.find(f => f.id === funnelBudget.id);

            if (prefFunnelBudget) {
                prefFunnelBudget.periods = periods;
            } else {
                funnelBudgets.push(funnelBudget);
            }

            clientPreferences.funnel_budgets = JSON.stringify(funnelBudgets);

            $.ajax({
                type: 'PATCH',
                url: '/clients/1',
                dataType: 'json',
                data: JSON.stringify({
                    preferences: clientPreferences
                }),
                success: function(data) {
                    childView.onValueSaved(value);
                    app.user.get('client').preferences = data.preferences;

                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        });
    }
});

NewPhaseView = Marionette.ItemView.extend({
    className: 'edit-phase edit-form-modal',
    template: Handlebars.compile(addNewPhaseTemplate),
    ui: {
        name: '#name',
        hint: '#hint',
        error_messages: '.error-message'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'keypress #name': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onShow: function() {
        this.ui.name.focus();
    },
    save: function() {
        var attrs = {
                name: this.ui.name.val(),
                hint: this.ui.hint.val(),
                order: 0,
                funnel_id: this.options.funnelId
            },
            phase = new PhaseModel(),
            view = this;

        this.error_messages.remove.call(this);

        this.listenTo(phase, 'invalid', this.invalid);
        phase.save(attrs, {
            validate: true,
            success: function() {
                view.trigger('new:phase');
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);
        if (errors.name_undefined || errors.name_too_short) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined || errors.name_too_short)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

FunnelMarketingOpeningHours = Marionette.Layout.extend({
    template: Handlebars.compile(funnelMarketingOpeningHoursTemplate),
    templateHelpers: function() {
        return {
            openingHours: this.openingHours,
        }
    },
    ui: {
        opening_hours_value: '.opening-hours-value',
        edit_opening_hours_value: '.edit-opening-hours-value',
        save_button: '.save',
        monday_span: '#monday-span',
        tuesday_span: '#tuesday-span',
        wednesday_span: '#wednesday-span',
        thursday_span: '#thursday-span',
        friday_span: '#friday-span',
        saturday_span: '#saturday-span',
        sunday_span: '#sunday-span',
        monday_value: '#monday-value',
        tuesday_value: '#tuesday-value',
        wednesday_value: '#wednesday-value',
        thursday_value: '#thursday-value',
        friday_value: '#friday-value',
        saturday_value: '#saturday-value',
        sunday_value: '#sunday-value',
    },
    events: {
        'click .edit-funnel-opening-hours': function() {
            this.ui.edit_opening_hours_value.show();
            this.ui.save_button.show();
            this.ui.opening_hours_value.hide();
        },
        'click .save': function() {
            var self = this;
            var marketing_details = this.model.get('marketing_details') || {};

            for (const [key, value] of Object.entries(this.openingHours)) {
                var keyLowercase = key.toLowerCase();
                this.openingHours[key] = this.ui[`${keyLowercase}_value`].val();
            }

            marketing_details['opening_hours'] = this.openingHours;

            this.model.save({
                marketing_details: JSON.stringify(marketing_details),
            }, {
                patch: true,
                wait: true,
                success: function() {
                    self.hideEdit();
                },
                error: function() {
                    self.hideEdit();
                }
            });
        }
    },
    initialize: function(options) {
        this.model = options.model;
        var marketing_details = this.model.get('marketing_details') || {};
        this.openingHours = {
            'Monday': '',
            'Tuesday': '',
            'Wednesday': '',
            'Thursday': '',
            'Friday': '',
            'Saturday': '',
            'Sunday': '',
        };

        if (marketing_details.opening_hours) {
            this.openingHours = marketing_details.opening_hours;
        }
    },
    onRender: function() {
        this.ui.edit_opening_hours_value.hide();
        this.ui.save_button.hide();

        for (const [key, value] of Object.entries(this.openingHours)) {
            var keyLowercase = key.toLowerCase();
            this.ui[`${keyLowercase}_span`].text(value);
            this.ui[`${keyLowercase}_value`].val(value);
        }
    },
    hideEdit: function() {
        this.ui.edit_opening_hours_value.hide();
        this.ui.save_button.hide();
        this.ui.opening_hours_value.show();

        this.render();
    }
});

FunnelCommunicationsView = Marionette.Layout.extend({
    template: Handlebars.compile(funnelCommunicationTemplate),
    templateHelpers: function() {
        return {
            communications: this.model.get('communication')
        };
    },
    ui: {
        edit_communications: '.edit-communications',
        communications: '.communications',
    },
    events: {
        'click .edit-funnel-communications': function() {
            this.ui.communications.hide();
            this.ui.edit_communications.show();
        },
        'click .save': function() {
            var self = this;
            var communications = [];
            var communication_rows = document.getElementsByClassName('communication-row');

            for (var row of communication_rows) {
                var row_dict = {};

                var id = row.querySelector('input[name="id"]');
                var medium = row.querySelector('input[name="medium"]');
                var name = row.querySelector('input[name="name"]');
                var value = row.querySelector('input[name="value"]');

                if ( ! value || ! medium ) {
                    continue;
                }

                row_dict['medium'] = medium.value;
                row_dict['value'] = value.value;

                if (id) {
                    row_dict['id'] = id.value
                }
                if (name) {
                    row_dict['name'] = name.value
                }

                communications.push(row_dict);
            }

            this.model.save({
                communication: communications
            }, {
                patch: true,
                wait: true,
                success: function() {
                    self.hideEdit();
                },
                error: function() {
                    self.hideEdit();
                }
            });
        },
        'click .add-communication-medium-email': function() {
            this.addCommunicationRow('email');
        },
        'click .add-communication-medium-phone': function() {
            this.addCommunicationRow('phone');
        },
        'click .add-communication-medium-social': function() {
            this.addCommunicationRow('social');
        },
        'click .delete-item': function(ev) {
            ev.target.parentElement.remove();
        }
    },
    initialize: function(options) {
        this.model = options.model;
    },
    onRender: function() {
        this.ui.edit_communications.hide();
    },
    hideEdit: function() {
        this.ui.communications.show();
        this.ui.edit_communications.hide();
        this.render();
    },
    addCommunicationRow: function(medium) {
        var name_value = medium.charAt(0).toUpperCase() + medium.slice(1);

        var html = `
            <div class="communication-row">
                <input name="medium" class="editable-field medium" type="text" value="${medium}" style="display:none">
                <input name="name" class="editable-field name" value="${name_value}" type="text">
                <input name="value" class="editable-field value" type="text">
                <div class="delete-item delete-icon">
                    <div></div>
                </div>
            </div>
        `;
        var element = document.getElementsByClassName('button-row')[0];
        $(html).insertBefore(element);
    }
});

RegionItemView = Marionette.ItemView.extend({
    //tagName: 'li',
    className: function() {
        return 'region-row';
    },
    template: Handlebars.compile(regionItemViewTemplate),
    events: {
        'click .delete-icon': function(ev) {
            ev.stopPropagation();
            this.trigger('region:delete-item', this.model, this.collection);
        },
        'click .icon-pencil': function(ev) {
            ev.stopPropagation();

            this.trigger('region:edit-item', this.model);
        },
        'click': function(ev) {
            ev.preventDefault();
            this.trigger('region:select');
        }
    },
    attributes: function() {
        return {
            id: this.model.get('id')
        };
    },
});


FunnelListItemView = Marionette.ItemView.extend({
    className: function () {
        return 'funnel-row';
    },
    template: Handlebars.compile(funnelListItemView),
    templateHelpers: function() {
        return {
            showPermissions: app.user.get('client').permission_type !== 'rba'
        };
    },
    events: {
        'click .delete-icon': function(ev) {
            ev.stopPropagation();
            this.trigger('funnel:delete-item', this.model, this.collection);
        },
        'click .icon-cog': function(ev) {
            ev.preventDefault();
            this.trigger('funnel:select');
        },
        'click .show-permissions': function(ev) {
            ev.stopPropagation();
            this.trigger('funnel:show-permissions', this.model);
        },
    },
    attributes: function() {
        return {
            id: this.model.get('id')
        };
    },
});

RegionList = Marionette.CollectionView.extend({
    className: 'region-list',
    itemView: RegionItemView,
    itemViewOptions: function() {
        return {
            collection: this.collection,
            fullCollection: this.fullCollection
        };
    },
    initialize: function() {
        var self = this;

        this.collectionDict = {};

        _.each(this.collection.models, function(m) {
            self.collectionDict[m.get('id')] = m;
        });

        this.activeRegionId = this.options.activeRegionId

        this.on('itemview:region:select', function(childView) {
            self.activeRegionId = childView.model.get('id');
        });
    },
});

FunnelListView = Marionette.CollectionView.extend({
    className: 'funnel-list',
    itemView: FunnelListItemView,
    itemViewOptions: function() {
        return {
            collection: this.collection,
            fullCollection: this.fullCollection
        };
    },
    initialize: function() {
        var self = this;

        this.collectionDict = {};

        _.each(this.collection.models, function(m) {
            self.collectionDict[m.get('id')] = m;
        });
    },
    onRender: function() {
        var self = this;

        this.$el.sortable({
            axis: 'y',
            containment: this.$el,
            scroll: false,
            tolerance: 'pointer',
            handle: '.item-handle',
            stop: function(ev, ui) {
                var movingId = $(ui.item).attr('id');
                var children = self.$el.children();

                for (var i = 0; i < children.length; ++i) {
                    var c = $(children[i]);
                    var id = c.attr('id');

                    if (movingId === id) {
                        var model = self.collectionDict[movingId];

                        if (model.get('order') !== i) {
                            var attrs = {
                                funnel_id: model.get('id'),
                                order: i
                            };

                            model.save(attrs, {
                                patch: true,
                                wait: true,
                            });
                        }

                        break;
                    }
                }
            }
        });
    }
});

NewFunnelView = Marionette.ItemView.extend({
    className: 'edit-funnel edit-form-modal',
    template: Handlebars.compile(addNewFunnelTemplate),
    templateHelpers: function() {
        return {
            is_pdcrm: this.is_pdcrm
        }
    },
    ui: {
        name: '#name',
        createTag: '#auto-create-tag',
        createTeam: '#auto-create-team',
        createGroups: '#auto-create-groups',
        groupsSelect: '.groups-select',
        groupsInput: '#groups-select',
        error_messages: '.error-message'
    },
    events: {
        'change #name': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'keypress #name': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        },
        'click #auto-create-groups': function(ev) {
            var checked = ev.target.checked;

            if (checked) {
                this.ui.groupsInput.show();
            } else {
                this.ui.groupsInput.hide();
            }
        },
        'click #auto-create-tag': function(ev) {
            var checked = ev.target.checked;

            let groupsSelectData = [
                {
                    id: 'opportunities',
                    name: 'Plots'
                },
                {
                    id: 'campaigns',
                    name: TextManager.parseText('${ID_CAMPAIGN, capitalize, pluralize}')
                }
            ];

            if (checked) {
                // These groups should be added only when Auto create tag is true
                let groupsTags = [
                    {
                        id: 'individuals',
                        name: TextManager.parseText('${ID_INDIVIDUAL, capitalize, pluralize}')
                    },
                    {
                        id: 'tasks',
                        name: 'Tasks'
                    },
                    {
                        id: 'activities',
                        name: 'Activities'
                    }
                ];

                groupsSelectData = groupsTags.concat(groupsSelectData);
            }

            this.createGroupsSelect(groupsSelectData);
        }
    },
    initialize: function(options) {
        this.is_pdcrm = AppConfig.getValue('is_pdcrm', false);
        this.region_id = options.region_id;
    },
    onRender: function() {
        var self = this;

        if (this.is_pdcrm) {
            this.ui.createTag.prop('checked', true);
            this.ui.createTeam.prop('checked', true);
            this.ui.createGroups.prop('checked', true);

            _.defer(function() {
                let groupsSelectData = [
                    {
                        id: 'individuals',
                        name: TextManager.parseText('${ID_INDIVIDUAL, capitalize, pluralize}')
                    },
                    {
                        id: 'tasks',
                        name: 'Tasks'
                    },
                    {
                        id: 'activities',
                        name: 'Activities'
                    },
                    {
                        id: 'opportunities',
                        name: 'Plots'
                    },
                    {
                        id: 'campaigns',
                        name: TextManager.parseText('${ID_CAMPAIGN, capitalize, pluralize}')
                    }
                ];

                self.createGroupsSelect(groupsSelectData);
            });
        }
    },
    createGroupsSelect: function(data) {
        let groupsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.groupsSelect,
            id: 'id',
            text: 'name',
            search: true,
            data: data,
            options: {
                placeholder: 'Add Group',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'groups-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        groupsSelect.setValue(data);
    },
    save: function() {
        var attrs = {
            name: this.ui.name.val(),
        };

        if (this.region_id !== 'no-regions') {
            attrs['region_id'] = this.region_id;
        }
        var funnel = new FunnelModel();
        var view = this;

        if (this.is_pdcrm) {
            var create = {};

            if (this.ui.createTag.prop('checked')) {
                create.tag = true;
            }
            if (this.ui.createTeam.prop('checked')) {
                create.team = true;
            }
            if (this.ui.createGroups.prop('checked')) {
                var groups = {};

                for (var id of this.ui.groupsSelect.select2('val')) {
                    groups[id] = true;
                }

                create.groups = groups;
            }

            attrs.create = create;
        }

        this.error_messages.remove.call(this);
        this.listenTo(funnel, 'invalid', this.invalid);

        funnel.save(attrs, {
            validate: true,
            success: function() {
                view.trigger('new:funnel', funnel);
            },
            error: function(model, response, settings) {
                var detail;
                var funnel_text = TextManager.parseText('${ID_FUNNEL, capitalize}');

                try {
                    detail = JSON.parse(response.responseText).detail;
                }
                catch (e) {
                }

                if (detail && detail.exception === 'DuplicateFunnelNameError') {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: `A ${funnel_text} named ${attrs.name} already exists`,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);
        if (errors.name_undefined || errors.name_too_short || errors.name_length_warning) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined || errors.name_too_short || errors.name_length_warning)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

AddFunnelToRegion = Marionette.ItemView.extend({
    className: 'add-funnel-to-region',
    template: Handlebars.compile(regionAddFunnel),
    ui: {
        funnelsInput: '.funnels-input',
    },
    events: {
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        },
    },
    initialize: function(options) {
        this.region_id = options.region_id;

        if (this.region_id === 'no-regions') {
            this.region_id = null;
        }

        this.funnels = new FunnelsCollection();
    },
    onRender: function() {
        var funnel_text = TextManager.parseText('${ID_FUNNEL, capitalize}');

        this.tagSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.funnelsInput,
            id: 'id',
            text: 'name',
            url: '/funnels',
            search: true,
            options: {
                placeholder: `Add ${funnel_text}`,
                containerCssClass: 'select2-block',
                dropdownCssClass: 'groups-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });
    },
    save: function() {
        var self = this;
        var funnelName = TextManager.parseText('${ID_FUNNEL, capitalize, plural}');
        var funnelData = this.tagSelect.getData();

        for (const [i, funnel] of funnelData.entries()) {
            $.ajax({
                type: 'PATCH',
                url: `/funnels/${funnel.id}`,
                dataType: 'json',
                contentType: "application/json;charset=utf-8",
                data: JSON.stringify({
                    funnel_id: funnel.id,
                    region_id: self.region_id,
                }),
                success: function () {
                    if(i === funnelData.length-1) {
                        vent.trigger('alert:show', { 
                            type: function() {
                                return {
                                    message: `Successfully added ${funnelName} to Region.`,
                                    classes: 'success',
                                    timer: 3000
                                }
                            }
                        });
    
                        self.trigger('funnel:update-region');
                    }
                },
                error: function() {
                    vent.trigger('alert:show', { 
                        type: function() {
                            return {
                                message: `Failed to add ${funnelName} to Region.`,
                                classes: 'error',
                                timer: 3000
                            }
                        }
                    });
                }
            });
        }
    }
});

NewRegionView = Marionette.ItemView.extend({
    className: 'edit-region edit-form-modal',
    template: Handlebars.compile(addNewRegionTemplate),
    templateHelpers: function() {
        var integration_data = null;

        if (this.model) {
            integration_data = this.model.get('integration_data') || {};
        }

        return {
            showIntegrationData: this.editing,
            integration_data: integration_data,
        }
    },
    ui: {
        title: '.title',
        name: '#name',
        description: '#description',
        address: '#address',
        address_info: '#address-info',
        userNotificationList: "#user-notification-list",
        commercialTeamEmail: '#commercial-team-email',
        error_messages: '.error-message',
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
            this.valuesChanged = true;
        },
        'change textarea': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
            this.valuesChanged = true;
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        },
    },
    initialize: function(options) {
        this.editing = !options.isNew;
        this.model = options.model;
        this.userNotificationList = [];
        this.valuesChanged = false;
    },
    onShow: function() {
        this.userNotificationSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.userNotificationList,
            //id: 'id',
            text: 'title',
            url: '/users',
            search: true,
            options: {
                placeholder: 'Add User',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'groups-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        if (this.editing) {
            var location = this.model.get('location');

            this.ui.title.text('Edit Region');
            this.ui.name.val(this.model.get('name'));
            this.ui.description.val(this.model.get('description'));

            if (location) {
                this.ui.address.val(location.address);
                this.ui.address_info.val(location.comments);
            }

            this.userNotificationSelect.setValue(this.parseUserNotificationsListValues(this.model.get('user_notification_list')));
            this.ui.commercialTeamEmail.val(this.model.get('commercial_team_email') || '');
        }

        this.listenTo(this.userNotificationSelect, 'change', function(items) {
            this.dealFieldsSelected = this.processUserNotificationValuesSelected(items);
        });
    },
    haveChanges: function(){
        if (this.editing) {
            return this.valuesChanged;
        }

        return true;
    },
    parseUserNotificationsListValues: function(items) {
        let usersSelected = [];
        let userIds = [];

        for (let item of items) {
            usersSelected.push({
                id: item.id,
                title: item.name
            });

            userIds.push(item.id);
        }

        this.userNotificationList = userIds;
        return usersSelected;
    },
    processUserNotificationValuesSelected: function(items) {
        let users = [];
        for (let item of items) {
            users.push(item.id);
        }

        this.valuesChanged = true;
        this.userNotificationList = users;
    },
    save: function() {
        var view = this;
        this.error_messages.remove.call(this);

        var attrs = {
            name: this.ui.name.val(),
            description: this.ui.description.val(),
            user_notification_list: this.userNotificationList,
            location: {
                name: 'Address',
                address: this.ui.address.val(),
                comments: this.ui.address_info.val(),
            },
            commercial_team_email: this.ui.commercialTeamEmail.val()
        };

        if (this.model && this.model.get('location')) {
            attrs['location']['id'] = this.model.get('location').id;
        }

        if (!this.haveChanges()) {
            this.trigger('cancel');
            return;
        }

        if (this.editing) {
            this.listenTo(this.model, 'invalid', this.invalid);

            const location = this.model.get('location');
            if (location) {
                attrs.location['id'] = location.id;
            }
            this.model.save(attrs, {
                patch: true,
                validate: true,
                success: function() {
                    view.trigger('region:saved', view.model);
                },
                error: function(model, response, settings) {
                    var detail;
                    var message = 'Unable to update region.';

                    try {
                        detail = JSON.parse(response.responseText).detail;
                    }
                    catch (e) {
                    }

                    if (detail && detail.exception === 'DuplicateRegionNameError') {
                        message = 'A region named ' + attrs.name + ' already exists.';
                    }

                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        } else {
            var region = new RegionModel();
            this.listenTo(region, 'invalid', this.invalid);

            region.save(attrs, {
                validate: true,
                success: function() {
                    view.trigger('region:saved', region);
                },
                error: function(model, response, settings) {
                    var detail;
                    var message = 'Unable to create new region.';

                    try {
                        detail = JSON.parse(response.responseText).detail;
                    }
                    catch (e) {
                    }

                    if (detail && detail.exception === 'DuplicateRegionNameError') {
                        message = 'A region named ' + attrs.name + ' already exists.';
                    }

                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);

        if (errors.name_undefined) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
})

// Phase Filter View
PhaseFilterView = Marionette.Layout.extend({
    className: 'show-phase-filter',
    template: Handlebars.compile(phaseGateFilterTemplate),
    ui: {
        filterInput: '#filters'
    },
    regions: {
        phaseFilterListReg: '.phase-filter-list-container',
        popoverRegion: '.popover-region',
    },
    events: {
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    templateHelpers: function() {
        return {
            title: this.title,
            phaseName: this.phase.get("name")
        };
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onShow: function() {
        var self = this;
        this.loadCustomFields(function() {
            self.phaseFilterListReg.show(
                new PhaseFilterItemsView({
                    filters: self.gate === 'entrance' ? self.phase.get("entrance_gate_filter") : self.phase.get("exit_gate_filter"),
                    filterFields: self.filterFields,
                    showFilter: self.showFilter.bind(self),
                    removeFilterRule: self.removeFilterRule.bind(self)
                })
            );
        });
    },
    showFilter(target, callback, filter, rule, ind) {
        target = $(target);
        var title, model;

        var self = this;

        if (rule) {
            title = 'Edit Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection(rule)
            });
        }
        else {
            title = 'Add Rule';
            model = new Backbone.Model({
                items: new Backbone.Collection()
            });
        }

        var popoverView = new FilterAdvancedView({
            title: title,
            collection: model.get('items'),
            fields: _.clone(this.filterFields)
        });

        this.listenTo(popoverView, 'form-saved', function() {
            var newRule = [];
            model.get('items').each(function(item) {
                newRule.push(item.attributes)
            });
            filter = filter || {};
            filter.rules = filter.rules || [];
            if (rule) {
                filter.rules[ind] = newRule;
            }
            else {
                filter.rules.push(newRule);
            }

            var self = this;

            $.ajax({
                type: 'PATCH',
                url: '/phases/' + self.phase.get("id"),
                data: JSON.stringify(self.gate === 'entrance' ? {entrance_gate_filter: filter} : {exit_gate_filter: filter}),
                contentType: 'application/json',
                dataType: 'json',
                success: function(res) {
                    var filter = self.gate === 'entrance' ? res.entrance_gate_filter : res.exit_gate_filter
                    callback(filter);
                    self.updatePhaseGateFilters(filter, self.phase, self.gate);
                    vent.trigger('filterChange', self.phase.get("id"), self.gate, filter.rules.length);
                }
            })
        });

        this.popoverRegion.show(popoverView);
        this.popoverRegion.$el.css({
            left: target.offset().left - 40,
            top: target.offset().top + 40,
            position: 'fixed'
        });
        this.popoverRegion.$el.show();
    },
    removeFilterRule(callback, filter, ind) {
        var self = this;
        filter = filter || {};
        filter.rules = filter.rules || [];
        filter.rules.splice(ind, 1);

        $.ajax({
            type: 'PATCH',
            url: '/phases/' + self.phase.get("id"),
            data: JSON.stringify(self.gate === 'entrance' ? {entrance_gate_filter: filter} : {exit_gate_filter: filter}),
            contentType: 'application/json',
            dataType: 'json',
            success: function(res) {
                var filter = self.gate === 'entrance' ? res.entrance_gate_filter : res.exit_gate_filter
                callback(filter);
                self.updatePhaseGateFilters(filter, self.phase, self.gate);
                vent.trigger('filterChange', self.phase.get("id"), self.gate, filter.rules.length);
            }
        });
    },
    updatePhaseGateFilters: function(filter, phase, gate) {
        if(gate === "entrance"){
            phase.attributes.entrance_gate_filter = filter
        } else {
            phase.attributes.exit_gate_filter = filter
        }
    },
    buildFilterFields: function() {
        var allFields = _.clone(FilterFields());

        var fields = allFields.filter(field => {
            return field.group && DEAL_GROUPS.includes(field.group)
        })

        _.each(this.customFields, function (collection, id) {
            collection.each(function (model) {
                if (model.get('type') !== 'list') {
                    fields.push({
                        'id': id + '#' + model.get('id'),
                        'name': model.get('name'),
                        'operators': filterOperators.custom[model.get('type')],
                        'group': filterCustomFields[id].name,
                        'value_def': model.get('value')
                    });
                }
            });
        });

        this.filterFields = fields;
    },
    loadCustomFields: function(callback) {
        var view = this;

        function checkReady() {
            var unready = _.filter(view.customFields, function(item) {
                return !item;
            });
            if (unready.length === 0) {
                view.buildFilterFields();
                callback();
            }
        }

        this.customFields = {};
        _.each(filterCustomFields, function(item, id) {
            view.customFields[id] = null;

            var collection = new CustomFieldsCollection();
            collection.fetch({
                filterBy: [{
                    attribute: 'view',
                    value: item.view
                }],
                complete: function() {
                    view.customFields[id] = collection;
                    checkReady();
                }
            });
        });
    }
});

FunnelView = Marionette.Layout.extend({
    className: 'content-funnel-view',
    template: Handlebars.compile(funnelViewTemplate),
    templateHelpers: function() {
        let ykDevelopmentSettingsVisible = false;

        const userPreferences = app.user.get('preferences') || {};
        const funnel_integration_data = this.model.get('integration_data') || {};

        if (userPreferences.yourkeys_seller_reference && funnel_integration_data.developmentReference) {
            ykDevelopmentSettingsVisible = true;
        }

        return {
            integration_data: this.model.get('integration_data') || {},
            ykDevelopmentSettingsVisible: ykDevelopmentSettingsVisible,
        }
    },
    regions: {
        phaseListReg: '.phase-list-container',
        wonPhaseReg: '.won-phase',
        lostPhaseReg: '.lost-phase',
        periodList: '.reporting-periods-list-container',
        funnelCommunications: '.funnels-marketing-contact-details',
        funnelOpeningHours: '.funnels-marketing-opening-hours',
        addNewPhase: {
            selector: '.add-new-phase', // selector it self not used in ModalRegion
            regionType: ModalRegion
        },
        addNewFunnel: {
            selector: '.add-new-funnel',
            regionType: ModalRegion
        },
        showGateFilters: {
            selector: '.filter-count',
            regionType: ModalRegion
        },
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        yourkeysDevelopmentSettings: {
            selector: '.yourkeys-development-settings',
            regionType: ModalRegion
        }
    },
    ui: {
        bubbleRepresentationSelect: '.funnel-settings .info-container #bubble-representation',
        clusterSelect: '.funnel-settings .info-container #cluster',
        nameHeader: '.name-header',
        hintHeader: '.hint-header',
        gateHeader: '.gate-header',
        budgetValueUnitSelect: '.funnel-budget .budget-value-unit',
        excludeFromCalendarReport: '.funnel-settings .info-container .exclude-from-calendar-checkbox',
        isArchive: '.funnel-settings .info-container .archive-funnel',
        funnel_name: '.funnel-name',
        edit_funnel_name: '#edit-funnel-name',
        address_save: '.address-save',
        edit_address_funnel: '.edit-funnel-address-info',
        funnel_address: '.funnel-address',
        funnel_address_info: '.funnel-address-info',
        edit_funnel_address: '#edit-funnel-address',
        edit_funnel_address_info: '#edit-funnel-address-info',
    },
    events: {
        'click .add-new-phase': function() {
            var newPhaseView = new NewPhaseView({funnelId: this.model.get('id')});
            this.addNewPhase.show(newPhaseView);
            this.listenTo(newPhaseView, 'new:phase', function() {
                // we fetch all phases to get correct ordering
                this.fetchPhases();
                this.addNewPhase.reset();
            });
            this.listenTo(newPhaseView, 'cancel', function() {
                this.addNewPhase.reset();
            });
        },
        'click .exclude-from-calendar-checkbox': function(ev) {
            var newValue = ev.target.checked;

            this.model.save({
                exclude_from_calendar_report: newValue
            }, {
                patch: true,
            });
        },
        'click .archive-funnel': function(ev) {
            var newValue = ev.target.checked;

            this.model.save({
                is_archive: newValue
            }, {
                patch: true,
            });
        },
        'click .edit-funnel-name-icon': function() {
            var self = this;
            var display_field = this.ui.funnel_name.closest('.display-field');
            var edit_funnel_name = this.ui.edit_funnel_name;

            display_field.hide();
            edit_funnel_name.show();
            edit_funnel_name.focus();

            function hideEdit() {
                display_field.show();
                edit_funnel_name.hide();
            }

            edit_funnel_name.on('change', function() {
                var val = self.ui.edit_funnel_name.val();

                if (val) {
                    var attrs = {
                        funnel_id: self.model.get('id'),
                        name: val
                    };
    
                    self.model.save(attrs, {
                        patch: true,
                        wait: true,
                        success: function() {
                            self.ui.funnel_name.text(val);
                            hideEdit();
                        },
                        error: function() {
                            hideEdit();
                        }
                    });
                } else {
                    hideEdit();
                }
            });

            this.ui.edit_funnel_name.on('blur', hideEdit);
        },
        'click .edit-funnel-address': function() {
            var display_field = this.ui.funnel_address.closest('.display-field');

            display_field.hide();
            this.ui.edit_address_funnel.show();
        },
        'click .address-save': function() {
            var self = this;
            var display_field = this.ui.funnel_address.closest('.display-field');
            var ui_edit_addres_funnel = this.ui.edit_address_funnel;

            function hideEdit() {
                display_field.show();
                ui_edit_addres_funnel.hide();
            }

            var attrs = {
                location: {
                    name: 'Address',
                    address: this.ui.edit_funnel_address.val(),
                    comments: this.ui.edit_funnel_address_info.val(),
                }
            }

            if (this.model.get('location')) {
                attrs['location']['id'] = this.model.get('location').id;
            }

            this.model.save(attrs, {
                patch: true,
                wait: true,
                success: function() {
                    self.ui.funnel_address.text(self.ui.edit_funnel_address.val());
                    self.ui.funnel_address_info.text(self.ui.edit_funnel_address_info.val());
                    hideEdit();
                },
                error: function() {
                    hideEdit();
                }
            });
        },
        'click .yourkeys-development-settings': function() {
            const funnel_integration_data = this.model.get('integration_data') || {};

            const yourkeysIframeDataView = new YourkeysIframeDataView({
                model: this.model,
                onCloseButtonClick: null,
                loadDevelopment: true,
            });

            this.yourkeysDevelopmentSettings.show(yourkeysIframeDataView);
        }
    },
    initialize: function(options) {
        var self = this;
        this.model = options.model;

        this.phases = new PhasesCollection();
        this.phases.on("change:order", function() {
            self.fetchPhases();
        });
        this.phases.on("destroy", function() {
            self.fetchPhases();
        });
    },
    onRender: function() {
        var self = this;
        var customFieldsCollection = new CustomFieldsCollection();

        if(!_.contains(app.user.get('preferences').lab_flags, 'SAL-4398')){
            this.ui.gateHeader.remove();

            this.ui.nameHeader.css({
                width: '23%'
            });
            this.ui.hintHeader.css({
                width: '50%'
            });
        }

        customFieldsCollection.fetch({
            filterBy: [{
                attribute: 'view',
                value: 'opportunities'
            }],
            success: function(data) {
                self.bubbleRepresentationData = [{
                    id: 'deal_value',
                    name: TextManager.getText('ID_DEAL_VALUE')
                }];

                _.each(data.models, function(cf) {
                    if (cf.get('type') === 'number') {
                        self.bubbleRepresentationData.push({
                            id: cf.get('id'),
                            name: cf.get('name')
                        });
                    }
                });

                var activeRepresentation = 'deal_value';

                activeRepresentation = self.getValidBubbleRepresentation(self.model.get('bubble_representation'));

                self.bubbleRepresentationSelect = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.bubbleRepresentationSelect,
                    text: 'name',
                    value: activeRepresentation,
                    data: self.bubbleRepresentationData,

                    options: {
                        placeholder: 'Select a field',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'bubble-representation-select-popover popover'
                    }
                });

                self.listenTo(self.bubbleRepresentationSelect, 'change', function(item) {
                    self.model.save({
                        bubble_representation: item.id
                    }, {
                        patch: true,
                    });
                });
            }
        });

        var budgetUnits = [{
            id: 'units',
            text: 'Units'
        }].concat(Currency.getUsedCurrenciesToSelect2Array(true));

        this.budgetValueUnitSelect = new backboneSelect2.SelectView({
            view: this,
            $el: self.ui.budgetValueUnitSelect,
            data: budgetUnits,
            text: 'text',
            value: budgetUnits.find(e => e.id === this.getFunnelBudget(this.model.get('id')).units),
            options: {
                placeholder: 'Select a unit',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'popover'
            }
        });

        this.listenTo(this.budgetValueUnitSelect, 'change', function(item) {
            const clientPreferences = _.clone(app.user.get('client').preferences) || {};
            var funnelBudgets = [];

            if (clientPreferences.funnel_budgets) {
                funnelBudgets = JSON.parse(clientPreferences.funnel_budgets);
            }
            var funnel = funnelBudgets.find(f => f.id === this.model.get('id'));

            if (!funnel) {
                funnelBudgets.push({id: this.model.get('id')});
                funnel = funnelBudgets[0];
            }

            if (funnel) {
                funnel.units = item.id;

                clientPreferences.funnel_budgets = JSON.stringify(funnelBudgets);

                $.ajax({
                    type: 'PATCH',
                    url: '/clients/1',
                    dataType: 'json',
                    data: JSON.stringify({
                        preferences: clientPreferences
                    }),
                    success: function(data) {
                        app.user.get('client').preferences = data.preferences;

                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Preferences saved',
                                    classes: 'saved success',
                                    timer: 3000
                                };
                            }
                        });
                    }
                });
            }
        });

        this.periodsCollection = new PeriodsCollection();

        this.periodsCollection.fetch({
            sortOn: [{
                attribute: 'start_date',
                order: 'asc'
            }],
            rows: -1,
            success: function() {
                self.showPeriodsList();
            }
        });

        this.showPeriodsList();
        this.fetchPhases();
        this.showFunnelSettingsList();
        this.showMarketingDetails();
        this.showMarketingOpeningHours();

        this.clustersCollection = new ClustersCollection();
        this.clustersCollection.fetch({
            sortOn: [{
                attribute: 'name',
                order: 'asc'
            }],
            data: {
                region_id: self.model.get('region_id')
            },
            rows: -1,
            success: function(data) {
                self.clusterData = [];

                _.each(data.models, function(cluster) {
                    self.clusterData.push({
                        id: cluster.get('id'),
                        name: cluster.get('name')
                    });
                });

                let activeCluster = self.getValidCluster(self.model.get('cluster_id'));

                self.clusterSelect = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.clusterSelect,
                    text: 'name',
                    value: activeCluster,
                    data: self.clusterData,

                    options: {
                        placeholder: `Select a ${TextManager.parseText('${ID_CLUSTER}')}`,
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'bubble-representation-select-popover popover'
                    }
                });

                self.listenTo(self.clusterSelect, 'change', function(item) {
                    self.model.save({
                        cluster_id: item.id
                    }, {
                        patch: true,
                    });
                });
            }
        });

        this.ui.edit_funnel_name.hide();
        this.ui.funnel_name.text(this.model.get('name'));
        this.ui.edit_funnel_name.val(this.model.get('name'));

        var location = this.model.get('location');
        this.ui.edit_address_funnel.hide();

        if (location) {
            this.ui.funnel_address.text(location.address);
            this.ui.edit_funnel_address.val(location.address);

            this.ui.funnel_address_info.text(location.comments);
            this.ui.edit_funnel_address_info.val(location.comments);
        }
    },
    fetchPhases: function() {
        var self = this;
        this.phases.fetch({
            data: {
                funnel_id: self.model.get('id')
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                // strip out won and lost phases
                _.each(self.phases, function(undef, id, phases) {
                    switch (phases.at(id).get('phase_type')) {
                    case 'won':
                        self.wonPhase = phases.at(id);
                        break;
                    case 'lost':
                        self.lostPhase = phases.at(id);
                        break;
                    }
                });
                self.phases.remove(self.wonPhase);
                self.phases.remove(self.lostPhase);

                var phaseList = new PhaseList({ collection: self.phases, funnelId: self.model.get('id') });

                var wonPhase = new PhaseItemView({model: self.wonPhase, parent: self});
                var lostPhase = new PhaseItemView({model: self.lostPhase, parent: self})

                self.listenTo(phaseList, "edit-entrance-filter", function(phase) {
                    this.showFilterView('Entrance Gate', 'entrance', phase);
                });

                self.listenTo(self, "edit-entrance-filter", function(phase) {
                    this.showFilterView('Entrance Gate', 'entrance', phase);
                });

                self.listenTo(phaseList, "edit-exit-filter", function(phase) {
                    this.showFilterView('Exit Gate', 'exit', phase);
                });

                self.listenTo(self, "edit-exit-filter", function(phase) {
                    this.showFilterView('Exit Gate', 'exit', phase);
                });

                phaseList.on('itemview:phase:invalid-delete', function(child, message) {
                    self.showMessageBoxError(message);
                });

                self.phaseListReg.show(phaseList);
                self.wonPhaseReg.show(wonPhase);
                self.lostPhaseReg.show(lostPhase);
                self.$el.find('.content-container').nanoScroller();
            }
        });
    },
    showFilterView: function(title, gate, phase) {
        var filterView = new PhaseFilterView({
            title: title,
            gate: gate,
            phase: phase,
            parent: this
        });

        this.listenTo(filterView, 'cancel', function() {
            this.showGateFilters.reset();
        });

        this.showGateFilters.show(filterView);
    },
    showMessageBoxError: function(message) {
        var mbContent = {
            message: message,
            icon: 'icon-blocked'
        };

        MessageBox.showOk(mbContent, this);
    },
    getFunnelBudget: function(funnelId) {
        if (funnelId) {
            const clientPreferences = app.user.get('client').preferences || {};

            if (clientPreferences.funnel_budgets) {
                let funnel = JSON.parse(clientPreferences.funnel_budgets).find(f => f.id === funnelId);

                if (funnel) {
                    return funnel;
                }
            }
        }

        return {
            id: funnelId,
            units: 'units',
            periods: []
        };
    },
    showPeriodsList: function() {
        this.periodList.show(new PeriodList({ collection: this.periodsCollection, funnelBudget: this.getFunnelBudget(this.model.get('id')) }));
        this.$el.find('.content-container').nanoScroller();
    },
    showFunnelSettingsList: function () {
        this.ui.excludeFromCalendarReport.prop('checked', this.model.get('exclude_from_calendar_report'));
        this.ui.isArchive.prop('checked', this.model.get('is_archive'));
        this.$el.find('.content-container').nanoScroller();
    },
    getValidBubbleRepresentation: function(representation) {
        if (representation && _.find(this.bubbleRepresentationData, function(r) {return r.id === representation;})) {
            return representation;
        }

        return 'deal_value';
    },
    showMarketingDetails: function() {
        this.funnelCommunicationsView = new FunnelCommunicationsView({
            model: this.model,
        });

        this.funnelCommunications.show(this.funnelCommunicationsView);
    },
    showMarketingOpeningHours: function() {
        this.funnelMarketingOpeningHoursView = new FunnelMarketingOpeningHours({
            model: this.model,
        });

        this.funnelOpeningHours.show(this.funnelMarketingOpeningHoursView);
    },
    getValidCluster: function(cluster) {
        if (cluster && _.find(this.clusterData, function(r) {return r.id === cluster;})) {
            return cluster;
        }

        return null;
    },
});

RegionFunnelView = Marionette.Layout.extend({
    className: 'content',
    template: Handlebars.compile(regionFunnelsTemplate),
    regions: {
        regionContainer: '.region-container',
        liveFunnelsContainer: '.live-funnels-container',
        archiveFunnelsContainer: '.archive-funnels-container',
        addNewFunnel: {
            selector: '.create-new-funnel',
            regionType: ModalRegion
        },
        addNewRegion: {
            selector: '.add-new-region',
            regionType: ModalRegion
        },
        addFunnelRegion: {
            selector: '.add-new-funnel-to-region',
            regionType: ModalRegion
        },
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    events: {
        'click .create-new-funnel': function() {
            this.createFunnel();
        },
        'click .add-new-region': function() {
            this.createOrEditRegion();
        },
        'click .add-new-funnel-to-region': function() {
            this.addFunnelToRegion();
        }
    },
    initialize: function(options) {
        this.regions = new RegionCollection();
        this.activeFunnels = new FunnelsCollection();
        this.archivedFunnels = new FunnelsCollection();
        this.activeRegionId = options.activeRegionId;
    },
    onRender: function() {
        this.fetchRegions();
    },
    createFunnel: function() {
        var newFunnelView = new NewFunnelView({
            region_id: this.activeRegionId,
        });
        var self = this;

        this.addNewFunnel.show(newFunnelView);

        this.listenTo(newFunnelView, 'new:funnel', function(model) {
            self.fetchRegions();
            self.addNewFunnel.reset();
        });

        this.listenTo(newFunnelView, 'cancel', function() {
            self.addNewFunnel.reset();
        });
    },
    createOrEditRegion: function(model) {
        if (model && model.get('id') === 'no-regions') {
            this.showMessageBoxError('You cannot edit this region.');

            return;
        }

        var self = this;
        var newRegionView;
        
        if (model) {
            newRegionView = new NewRegionView({
                isNew: false,
                model: model,
            });
        } else {
            newRegionView = new NewRegionView({
                isNew: true,
            });
        }

        this.addNewRegion.show(newRegionView);

        this.listenTo(newRegionView, 'region:saved', function(model) {
            self.activeRegionId = model.get('id');
            self.fetchRegions();
            self.addNewRegion.reset();
        });

        this.listenTo(newRegionView, 'cancel', function() {
            self.addNewRegion.reset();
        });
    },
    addFunnelToRegion: function() {
        var self = this;

        var addFunnelToRegion = new AddFunnelToRegion({
            region_id: this.activeRegionId
        });

        this.addFunnelRegion.show(addFunnelToRegion);

        this.listenTo(addFunnelToRegion, 'funnel:update-region', function() {
            self.fetchRegions();
            self.addFunnelRegion.reset();
        });

        this.listenTo(addFunnelToRegion, 'cancel', function() {
            self.addFunnelRegion.reset();
        });
    },
    fetchRegions: function() {
        var self = this;
        var funnelsByRegionId = {};
        var noRegionFunnels = [];

        $.get({
            url: '/funnels',
            async: false,
            success: function(response) {
                var funnels = response;

                for (var funnel of funnels) {
                    if (funnel.region_id) {
                        if ( ! funnelsByRegionId[funnel.region_id]) {
                            funnelsByRegionId[funnel.region_id] = [];
                        }

                        funnelsByRegionId[funnel.region_id].push(funnel);
                    } else {
                        noRegionFunnels.push(funnel);
                    }
                }
            }
        });

        this.regions.fetch({
            sortOn: [{
                attribute: 'name',
                order: 'asc'
            }],
            success: function () {
                for (var region of self.regions.models) {
                    var funnel_count = 0;

                    if (funnelsByRegionId[region.id]) {
                        funnel_count = funnelsByRegionId[region.id].length;
                    }

                    region['attributes']['funnel_count'] = funnel_count;
                }

                self.regions.add(new RegionModel(_.extend({
                    id: 'no-regions',
                    name: 'No Regions',
                    funnel_count: noRegionFunnels.length,
                })));

                self.activeRegionId = self.activeRegionId || self.regions.models[0].get('id');

                self.regionsList = new RegionList({
                    collection: self.regions,
                    activeRegionId: self.activeRegionId,
                });

                self.regionsList.on('itemview:region:select', function(childView) {
                    if (childView.model.get('id') !== self.activeFunnelId) {
                        self.setActiveRegion(childView);
                    }
                });

                self.regionsList.on('itemview:region:edit-item', function(child, model) {
                    self.createOrEditRegion(model);
                });

                self.regionsList.on('itemview:region:delete-item', function(child, model, collection) {
                    if (model.get('id') === 'no-regions') {
                        self.showMessageBoxError('Unable to delete this region.');

                        return;
                    }

                    var mbContent = {
                        accept_is_negative: true,
                        message: '<p><strong>This cannot be undone</strong></p>Are you sure you want to delete this region?',
                        icon: 'icon-trashcan'
                    };

                    MessageBox.showYesNo(mbContent, self,
                        function() { // yes
                            var funnels = new FunnelsCollection();

                            funnels.fetch({
                                data: {
                                    region_id: model.get('id')
                                },
                                rows: 1,
                                success: function() {
                                    if (funnels.length > 0 ) {
                                        self.showMessageBoxError(TextManager.getText('ID_REGION_WITH_FUNNELS'));
                                    }
                                    else {
                                        model.destroy({
                                            wait: true,
                                            success: function() {
                                                self.fetchRegions();
                                                self.activeRegionId = null;
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    );
                });

                self.regionContainer.show(self.regionsList);

                var model = self.regionsList.collection.get(self.activeRegionId);
                var itemView = model ? self.regionsList.children.findByModel(model) : null;
                self.setActiveRegion(itemView);
            }
        });
    },
    setActiveRegion: function(itemView) {
        var id = itemView.model.get('id');
        this.activeRegionId = id;

        this.regionsList.$el.find('div.active').removeClass('active');
        itemView.$el.addClass('active');

        this.fetchFunnels();

        this.trigger('region:selected', id);
    },
    fetchFunnels: function() {
        var self = this;
        var region_id = this.activeRegionId;

        var data = {
            'is_archive': false
        };

        if (this.activeRegionId === 'no-regions') {
            data['no_region_funnels'] = true;
        } else {
            data['region_id'] = region_id;
        }

        this.activeFunnels.fetch({
            data: data,
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                self.activeFunnelsList = new FunnelListView({
                    collection: self.activeFunnels
                });

                self.liveFunnelsContainer.show(self.activeFunnelsList);

                self.activeFunnelsList.on('itemview:funnel:select', function(childView) {
                    self.selectFunnel(childView);
                });

                self.activeFunnelsList.on('itemview:funnel:delete-item', function(child, model, collection) {
                    self.deleteFunnel(model, collection);
                });

                self.activeFunnelsList.on('itemview:funnel:show-permissions', function(child, model) {
                    self.showFunnelPermissionsView(model);
                });
            }
        });

        data['is_archive'] = true;

        this.archivedFunnels.fetch({
            data: data,
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                self.archivedFunnelsList = new FunnelListView({
                    collection: self.archivedFunnels
                });

                self.archiveFunnelsContainer.show(self.archivedFunnelsList);

                self.archivedFunnelsList.on('itemview:funnel:select', function(childView) {
                    self.selectFunnel(childView);
                });

                self.archivedFunnelsList.on('itemview:funnel:delete-item', function(child, model, collection) {
                    self.deleteFunnel(model, collection);
                });

                self.archivedFunnelsList.on('itemview:funnel:show-permissions', function(child, model) {
                    self.showFunnelPermissionsView(model);
                });
            }
        });
    },
    selectFunnel: function(childView) {
        var id = childView.model.get('id');

        this.trigger('funnel:selected', id);
    },
    deleteFunnel: function(model, collection) {
        var self = this;
        var funnel_text = TextManager.parseText('${ID_FUNNEL}');

        var mbContent = {
            accept_is_negative: true,
            message: `<p><strong>This cannot be undone</strong></p>Are you sure you want to delete this ${funnel_text}?`,
            icon: 'icon-trashcan'
        };

        MessageBox.showYesNo(mbContent, self,
            function() { // yes
                $.get({
                    url: '/funnels',
                    async: false,
                    success: function(response) {
                        // check there are more funnels
                        if (response.length === 1) {
                            self.showMessageBoxError('This is the only funnel and cannot be deleted.');

                            return;
                        }
                    }
                });

                // check don't exist deals on this funnel
                var opps = new OpportunitiesCollection();

                opps.fetch({
                    data: {
                        funnel_id: model.get('id')
                    },
                    rows: 1,
                    success: function() {
                        if (opps.length > 0 ) {
                            self.showMessageBoxError(TextManager.getText('ID_FUNNEL_WITH_DEALS'));
                        }
                        else {
                            model.destroy({
                                wait: true,
                                success: function() {
                                    self.fetchRegions();
                                }
                            });
                        }
                    }
                });
            }
        );
    },
    showFunnelPermissionsView: function(model) {
        var self = this;

        if (this.aclRegion && model.id) {
            var ipv = new ItemPermissionsView({model: model, canChangePermissions: true});
            this.aclRegion.show(ipv);

            this.listenTo(ipv, 'close', function() {
                self.aclRegion.reset();
            });
        }
    },
    showMessageBoxError: function(message) {
        var mbContent = {
            message: message,
            icon: 'icon-blocked'
        };

        MessageBox.showOk(mbContent, this);
    },
});

export default Marionette.Layout.extend({
    className: 'region-funnel-view',
    template: Handlebars.compile(regionFunnelsView),
    regions: {
        content: '.content-container',
    },
    ui: {
        title: '.title',
        left_arrow_header: '.left-arrow-header',
    },
    events: {
        'click .left-arrow-header': function(ev) {
            ev.preventDefault();
            this.showRegionFunnelView();
        },
    },
    initialize: function() {
        this.activeRegionId = null;
    },
    onRender: function() {
        this.showRegionFunnelView();
    },
    onShow: function() {
        // SET NEW APP MODAL WIDTH
        this.appSettingsElement = document.getElementById('app-settings-modal');
        const positionInfo = this.appSettingsElement.getBoundingClientRect();
        this.appSettingsElementOriginalWidth = positionInfo.width;

        window.addEventListener('resize', this.setModalWindowWidth);
        this.setModalWindowWidth();
    },
    onBeforeClose: function(){
        // REVERT OLD APP MODAL WIDTH
        this.appSettingsElement.style.width = `${this.appSettingsElementOriginalWidth}px`;
        window.removeEventListener('resize', this.setModalWindowWidth);
    },
    setModalWindowWidth() {
        // THE IDEA IS FOR THE SCREEN TO BE 5% of the right
        const appSettingsElement = document.getElementById('app-settings-modal');
        var new_width = window.innerWidth - (window.innerWidth * 0.05) - appSettingsElement.offsetLeft;
        appSettingsElement.style.width = `${new_width}px`;
    },
    showRegionFunnelView: function() {
        var self = this;
        this.regionFunnelView = new RegionFunnelView({
            activeRegionId: this.activeRegionId,
        });

        this.ui.left_arrow_header.hide();

        this.listenTo(this.regionFunnelView, 'funnel:selected', function(funnelId) {
            self.showFunnelView(funnelId);
        });

        this.listenTo(this.regionFunnelView, 'region:selected', function(region_id) {
            self.activeRegionId = region_id;
        });

        this.content.show(this.regionFunnelView);

        this.changeTitleText(TextManager.parseText('${ID_FUNNEL, capitalize, plural}'))
    },
    showFunnelView: function(funnelId) {
        var self = this;
        var funnelModel = new FunnelModel({id: funnelId});

        funnelModel.fetch({
            success: function() {
                self.funnelView = new FunnelView({
                    model: funnelModel,
                });

                self.changeTitleText(funnelModel.get('name'));
                self.ui.left_arrow_header.show();

                self.content.show(self.funnelView);
            },
            error: function(_, response) {
                //display error
            }
        });
    },
    changeTitleText(text) {
        this.ui.title.text(text);
    }
});
import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import braintree from 'braintree-web'

import app from 'js/app.js'
import vent from 'js/vent.js'
import currencyFormat from 'js/utils/currency_format.js'
import Utilities from 'js/utils/utilities'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import braintreeCountryCodes from 'js/utils/braintree_country_codes.js'
import LoadingView from 'js/views/loading.js'
import paymentDetailsSettingssTemplate from 'templates/settings/payment_details_settings.handlebars';
import paymentDetailsSubscriptionTemplate from 'templates/settings/payment_details_subscription.handlebars';
import paymentDetailsCreditCardsTemplate from 'templates/settings/payment_details_credit_card.handlebars';


/**
 * This view shows the small subscription information at the top of the payment details view.
 *
 * amount (number): Amount to be charged
 * currency (string): Currency code for the amount
 * has_subscription (bool): Indicates if there is a running subscription, or if this will be the first payment
 */
var SubscriptionDetailsView = Marionette.ItemView.extend({
    id: 'subscription-details',
    template: Handlebars.compile( paymentDetailsSubscriptionTemplate ),
    ui: {
        amount_text: '.amount-value',
        payment_date_text: '.payment-date-text'
    },
    initialize: function() {
        var currency = this.model.get('currency');
        var next_payment_amount = this.model.get('next_payment_amount');

        if (_.isString(next_payment_amount)) {
            next_payment_amount = parseFloat(next_payment_amount);
        }

        this.model.set('next_payment_amount_formatted', currencyFormat(currency, next_payment_amount));
    }
});

/**
 * This view contains the braintree form. It just requires a BT token to be initialized.
 *
 * braintree_token: A braintree token
 *
 * Triggers:
 * payment-updated: {braintree_nonce} This is triggered when the user saves the form
 * cancel-subscription: This is triggered if the user clicks on 'Cancel Subscription'
 */
var CreditCardDetailsView = Marionette.ItemView.extend({
    id: 'credit-card-details',
    template: Handlebars.compile( paymentDetailsCreditCardsTemplate ),
    ui: {
        braintree_container: '.braintree-container',
        saveButton: '.save'
    },
    events: {
        'submit .credit-card-form': function( ev ) {
            ev.preventDefault();
            ev.stopPropagation();

            if (!this.subscriptionDetails || !this.threeDSecure) {
                return;
            }

            const self = this;

            this.dropinInstance.requestPaymentMethod(function(err, payload) {
                if (err) {
                    console.log(err);
                    return;
                }

                const threeDSecureParams = {
                    amount: self.subscriptionDetails.total,
                    nonce: payload.nonce,
                    bin: payload.details.bin,
                    email: app.user.get('email_address'),
                    onLookupComplete: function(data, next) {
                        next();
                    }
                };

                if (self.options.billingAddress.first_name) {
                    const billingDetails = self.options.billingAddress;
                    let billingAddress = {};

                    if (Utilities.isASCII(billingDetails.first_name)) {
                        billingAddress.givenName = billingDetails.first_name;
                    }

                    if (billingDetails.last_name && Utilities.isASCII(billingDetails.last_name)) {
                        billingAddress.surname = billingDetails.last_name;
                    }

                    if (billingDetails.street_address) {
                        billingAddress.streetAddress = billingDetails.street_address;
                    }

                    if (billingDetails.extended_address) {
                        billingAddress.extendedAddress = billingDetails.extended_address;
                    }

                    if (billingDetails.locality) {
                        billingAddress.locality = billingDetails.locality;
                    }

                    if (billingDetails.postal_code) {
                        billingAddress.postalCode = billingDetails.postal_code;
                    }

                    if (billingDetails.country_code) {
                        const entry = _.findWhere(braintreeCountryCodes, {id: billingDetails.country_code});

                        if (entry) {
                            billingAddress.countryCodeAlpha2 = entry.code;
                        }
                    }

                    if (!_.isEmpty(billingAddress)) {
                        threeDSecureParams.billingAddress = billingAddress;
                    }
                }

                self.threeDSecure.verifyCard(threeDSecureParams, function(verifyErr, verifyResponse) {
                    if (verifyErr) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'There was an error verifying the card',
                                    classes: 'load-error error',
                                    timer: 3000
                                };
                            }
                        });
                        return;
                    }

                    self.trigger('payment-updated', {
                        'braintree_nonce': verifyResponse.nonce
                    });
                });
            });
        },
        'click button.delete': function( ev ) {
            ev.preventDefault();
            ev.stopPropagation();

            this.trigger('cancel-subscription');
        }
    },
    onDomRefresh: function() {
        // Braintree setup is done in onDomRefresh because it requires the container to be in the DOM
        var view = this;

        const checkReady = function() {
            if (view.subscriptionDetails && view.threeDSecure) {
                view.ui.saveButton.removeClass('disabled');
            }
        }

        $.ajax({
            method: 'GET',
            url: '/subscription_details',
            dataType: 'json',
            success: function(data) {
                view.subscriptionDetails = data;
                checkReady();
            },
            error: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There was an error getting the subscription details',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });

        braintree.dropin.create({
            authorization: view.model.get('braintree_token'),
            container: '#braintree-container',
        }, function(err, dropinInstance) {
            if (err) {
                console.log(err);
                return;
            }

            braintree.client.create({
                authorization: view.model.get('braintree_token')
            }, function(clientErr, clientInstance) {
                braintree.threeDSecure.create({
                    version: 2,
                    client: clientInstance,
                }, function(threeDSecureErr, threeDSecureInstance) {
                    if (threeDSecureErr) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: threeDSecureErr,
                                    classes: 'load-error error',
                                    timer: 3000
                                };
                            }
                        });
                        return;
                    }

                    view.threeDSecure = threeDSecureInstance;
                    checkReady();
                });
            });

            view.dropinInstance = dropinInstance;
        });
    }
});

var PaymentDetailsSettingsView = Marionette.Layout.extend({
    id: 'payment-details-settings',
    className: 'settings-detail at-top',
    template: Handlebars.compile( paymentDetailsSettingssTemplate ),
    ui: {
        contentContainer: '.detail-content.content-container',
        fillAddressWarning: '.fill-address-warning'
    },
    regions: {
        loadingRegion: '.loading-container',
        subscriptionRegion: '.subscription-details-container',
        creditCardRegion: '.credit-card-container'
    },
    initialize: function() {
        this.model = new Backbone.Model({
            loading: false,
            billing_address: false,
            has_valid_billing_address: null,
            payment_details: null
        });
    },
    onRender: function() {
        this.fetchBillingAddress();
    },
    fetchBillingAddress: function() {
        var view = this;

        $.ajax({
            method: 'GET',
            url: '/billing_details',
            dataType: 'json',
            success: function(data) {
                if (this.isClosed) {
                    return;
                }

                view.model.set('billing_address', data);
                view.onBillingAddress(data);
            },
            error: function() {
                view.ui.contentContainer.hide();

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There was an error processing this request',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    onBillingAddress: function() {
        if ( this.model.get('billing_address').first_name ) {
            this.model.set('has_valid_billing_address', true);
            this.fetchPaymentDetails();
        } else {
            this.model.set({
                'has_valid_billing_address': false,
                'loading': false
            });
            this.update();
        }
    },
    fetchPaymentDetails: function() {
        var view = this;

        $.ajax({
            method: 'GET',
            url: '/payment_details',
            dataType: 'json',
            success: function(data) {
                view.model.set({
                    'payment_details': data,
                    'loading': false
                });
                view.update();
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    updatePaymentMethod: function(nonce) {
        var view = this;

        $.ajax({
            method: 'PATCH',
            url: '/payment_details',
            processData: false,
            contentType: 'application/json',
            data: JSON.stringify({
                braintree_nonce: nonce
            }),
            dataType: 'json',
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Credit card information saved',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });

                view.model.set({
                    'payment_details': data,
                    'loading': false
                });
                view.update();
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    cancelSubscription: function() {
        var view = this;

        $.ajax({
            method: 'DELETE',
            url: '/payment_details',
            complete: function() {
                view.fetchPaymentDetails();
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    update: function() {
        if (this.isClosed) {
            return;
        }
        // If `has_valid_billing_address` is true or null (not yet determined) we go into the normal region selector
        // but if it has a false value (it has determined that these is no address) then it only shows the warning
        // to fill in the billing address.
        if ( this.model.get('has_valid_billing_address') !== false ) {
            // If it is loading, show the loading view
            if (this.model.get('loading') === true) {
                this.loadingRegion.show(new LoadingView());
            } else {
                this.loadingRegion.reset();
            }

            // Show the subscription details if its not loading
            if (this.model.get('loading') === false) {
                this.subscriptionRegion.show(new SubscriptionDetailsView({
                    model: new Backbone.Model(this.model.get('payment_details'))
                }));
            } else {
                this.subscriptionRegion.reset();
            }

            // Show the CC details if its not loading
            if (this.model.get('loading') === false) {
                var ccView = new CreditCardDetailsView({
                    model: new Backbone.Model(this.model.get('payment_details')),
                    billingAddress: this.model.get('billing_address')
                });
                this.listenTo(ccView, 'payment-updated', function (data) {
                    this.updatePaymentMethod(data.braintree_nonce);
                });
                this.listenTo(ccView, 'cancel-subscription', this.cancelSubscription);

                this.creditCardRegion.show(ccView);
            } else {
                this.creditCardRegion.reset();
            }

            // If there is a valid billing address, hide that warning
            this.ui.fillAddressWarning.hide();
        } else {
            // Hide everything except the warning to fill a valid billing address
            this.loadingRegion.reset();
            this.subscriptionRegion.reset();
            this.creditCardRegion.reset();
            this.ui.fillAddressWarning.show();
        }

        // Update scrollbars
        this.scrollbar();
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        this.$('.content-container').nanoScroller();
    }, 100)
});

export default PaymentDetailsSettingsView;

import React from 'react';
import classnames from 'classnames';

import style from './archive-address.css';

class ArchiveAddress extends React.Component {
    render() {
        const { email } = this.props;

        return (
            <div className={style.ArchiveAddress}>
                Archive emails by BCCing to this address:<br />
                <a
                    className={style.link}
                    href={`mailto:?bcc=${email}`}>

                    {email}
                </a>
            </div>
        );
    }
}

export default ArchiveAddress;

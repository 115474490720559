import React from 'react'

import app from 'js/app'
import ActivitiesList from 'js/react_views/activities-list/activities-list'

export default class List extends React.Component {
    constructor(props) {
        super(props);

        this.numRows = 50;
    }

    fetchData(fetchStart, callback) {
        let fetchArgs = {
            start: fetchStart,
            rows: this.numRows,
            order_by: 'user_created desc',
            include_ind_related: true,
            include_org_related: true,
            include_deal_related: true
        };

        const initialNumFilters = Object.keys(fetchArgs).length;
        const preferences = app.user.get('preferences');
        let doFetch = true;

        if ('dashboard_activity_filter' in preferences) {
            const filtersState = app.user.get('preferences')['dashboard_activity_filter'];

            // todo: if the 'notes' filter is off, integration:xero should be off too
            for (const filter in filtersState) {
                if (!filtersState[filter]) {
                    continue;
                }

                fetchArgs[filter] = filtersState[filter];
            }

            if (initialNumFilters === Object.keys(fetchArgs).length) {
                doFetch = false;
            }
        }

        if (doFetch) {
            // Get activity days to fetch
            const clientPreferences = app.user.get('client').preferences;

            if (clientPreferences.dashboard_activities_fetch_last_days && !isNaN(clientPreferences.dashboard_activities_fetch_last_days)) {
                if (clientPreferences.dashboard_activities_fetch_last_days !== -1) {
                    var date = new Date();
                    date.setDate(date.getDate() - parseInt(clientPreferences.dashboard_activities_fetch_last_days));
                    fetchArgs['user_created_after'] = date.toISOString();
                }
            } else {
                var date = new Date();
                date.setDate(date.getDate() - 1);
                fetchArgs['user_created_after'] = date.toISOString();
            }

            this.props.widget.fetcher.get('/v1/activities', fetchArgs, function(data, paginationInfo) {
                callback(data, paginationInfo);
            });
        } else {
            callback([], null);
        }
    }

    handleActivityClick(activity) {
        if (!activity.related) {
            return;
        }

        this.props.widget.triggerEvent('showEntity', {
            type: activity.related.type,
            id: activity.related.id,
            rectState: 'leftDocked',
            widget: this.props.widget
        });
    }

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                <ActivitiesList
                    numRows={this.numRows}
                    filtersStateField='dashboard_activity_filter'
                    fetchDataFunction={this.fetchData.bind(this)}
                    onActivityClick={this.handleActivityClick.bind(this)}
                />
            </div>
        );
    }
}
import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import AppConfig from 'app/app-config';
import app from 'js/app';

export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('individuals');

    // TODO: implement all columns

    const fixedColumns = [
        columnsCreator.text('index', '#', { width: 100, filterable: false, sortable: false, groupable: false }),
        columnsCreator.image('photo_url', 'Photo', { fallbackIcon: 'icon-user', width: 80 }),
        columnsCreator.text('first_name', 'First Name', { filterable: true }),
        columnsCreator.text('last_name', 'Last Name', { filterable: true }),
        columnsCreator.text('sortable_name', 'Sortable Name'),
        columnsCreator.text('full_name', 'Full Name', { filterable: true }),
        columnsCreator.date('created', 'System Created', { minWidth: 165 }),
        columnsCreator.date('user_created', 'Created', { filterable: false }),
        columnsCreator.date('modified', 'Modified'),
        columnsCreator.text('comments', 'More Info', { filterable: true, filterId: 'individual_comments' } ),
        columnsCreator.bool('unsubscribed_all', 'Email Opted In', { width: 160, customValues: ['No', 'Yes'], filterable: true, filterType: 'reverseBool' }),
        columnsCreator.bool('unsubscribed_all_messages', 'Texting Opted In', { width: 180, customValues: ['No', 'Yes'], filterable: true, filterType: 'reverseBool' }),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true }),
        columnsCreator.communications('email', 'Email', { sortable: true, filterable: true, filterId: 'communication_value', filterType: 'text' }),
        columnsCreator.communications('phone', 'Phone'),
        columnsCreator.text('linkedin', 'Linkedin', { sortable: false }),
        columnsCreator.text('twitter', 'Twitter', { sortable: false }),
        columnsCreator.text('Facebook', 'Facebook', { sortable: false }),
        columnsCreator.text('googleplus', 'Google+', { sortable: false }),
        columnsCreator.text('instagram', 'Instagram', { sortable: false }),
        columnsCreator.text('role', 'Job Role', { filterable: true }),
        columnsCreator.locations('locations', 'Locations'),
        columnsCreator.leadSource('source', 'Lead Source'),
        columnsCreator.date('became_lead_date', 'Became Lead Date'),
        columnsCreator.text('owner.name', 'Owner'),
        columnsCreator.date('last_viewed', 'Last Viewed'),
        columnsCreator.activity('last_direct_activity', 'Last Activity'),
        columnsCreator.date('last_direct_activity.modified', 'Last Activity Date', { filterable: false }),
        columnsCreator.activity('pinned_activity', 'Starred Activity'),
        columnsCreator.text('next_direct_task.text', 'Next Task Description'),
        columnsCreator.date('next_direct_task.due_date', 'Next Task Date', { filterable: false }),
        columnsCreator.text('next_direct_task.subject', 'Next Task Subject'),
        columnsCreator.text('organization.name', 'Organization', { filterable: true, filterId: 'organization_id', filterType: 'organization' }),
        columnsCreator.text('organization.website', 'Organization Website'),
        columnsCreator.text('organization.comments', 'Organization More Info'),
        columnsCreator.locations('organization.locations', 'Organization Locations'),
        columnsCreator.communications('organization.email', 'Organization Emails'),
        columnsCreator.communications('organization.phone', 'Organization Phones'),
        columnsCreator.text('organization.linkedin', 'Organization Linkedin', { sortable: false }),
        columnsCreator.text('organization.twitter', 'Organization Twitter', { sortable: false }),
        columnsCreator.text('organization.facebook', 'Organization Facebook', { sortable: false }),
        columnsCreator.text('organization.googleplus', 'Organization Google+', { sortable: false }),
        columnsCreator.text('organization.instagram', 'Organization Instagram', { sortable: false }),
        columnsCreator.text('organization.owner.name', 'Organization Owner', { sortable: false }),
        columnsCreator.activity('organization.last_direct_activity', 'Organization Last Activity'),
        columnsCreator.date('organization.last_direct_activity.modified', 'Organization Last Activity Date', { filterable: false }),
        columnsCreator.activity('organization.pinned_activity', 'Organization Starred Activity'),
        columnsCreator.text('organization.next_direct_task.text', 'Organization Next Task Description'),
        columnsCreator.date('organization.next_direct_task.due_date', 'Organization Next Task Date', { filterable: false }),
        columnsCreator.text('organization.next_direct_task.subject', 'Organization Next Task Subject')
    ];

    const customFieldsColumns = columnsCreator.customFields('individuals');
    const organizationCustomFieldsColumns = columnsCreator.customFields('organizations', true);
    //const campaignsColumns = columnsCreator.campaigns(); todo: should we implement this?

    return [...fixedColumns, ...customFieldsColumns, ...organizationCustomFieldsColumns];
}

export function getDefaultColumns() {
    const defaultColumns = ['photo', 'first_name', 'last_name', 'organization.name', 'email', 'phone'];
    return AppConfig.getValue('group_pages.individuals.default_columns', defaultColumns);
}

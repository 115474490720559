import $ from 'jquery'
import _ from 'underscore'

import app from 'js/app'
import currencyFormat from 'js/utils/currency_format'
import shortCurrencyFormat from 'js/utils/short_currency'


var usedCurrencies = [
    'AED',  // United Arab Emirates Dirham
    'AUD',  // Australian dollar
    'BRL',  // Brasilian real
    'CAD',  // Canadian dollar
    'CHF',  // Swiss franc
    'CLP',  // Chilean peso
    'CNY',  // Chinese yuan
    'CZK',  // Czech koruna
    'DKK',  // Danish krone
    'EGP',  // Egyptian pound
    'EUR',  // Euro
    'FJD',  // Fiji dollar
    'GBP',  // British pound
    'HKD',  // Hong Kong dollar
    'HNL',  // Honduran Lempira
    'HUF',  // Hungarian forint
    'ISK',  // Iceland krona
    'INR',  // Indian rupee
    'IDR',  // Indonesian rupiah
    'ILS',  // Israeli new shekel
    'JPY',  // Japanese yen
    'KRW',  // Korean won
    'MYR',  // Malasyan ringgit
    'MXN',  // Mexican peso
    'NZD',  // New Zealand dollar
    'NOK',  // Norwegian krone
    'PKR',  // Pakistan rupee
    'PHP',  // Philippine peso
    'PLN',  // Polish zloty
    'RUB',  // Russian ruble
    'SGD',  // Singapore dollar
    'SEK',  // Swedish krona
    'TWD',  // Taiwan dollar
    'THB',  // Thai baht
    'TRY',  // Turkish lira
    'USD',  // US dollar
    'ZAR'   // South Africa rand
];

var usedCurrenciesInfo = {
    AED: {
        name: 'United Arab Emirates Dirham'
    },

    AUD: {
        name: 'Australian Dollar'
    },

    BRL: {
        name: 'Brasilian Real'
    },

    CAD: {
        name: 'Canadian Dollar'
    },

    CHF: {
        name: 'Swiss Franc'
    },

    CLP: {
        name: 'Chilean Peso'
    },

    CNY: {
        name: 'Chinese Yuan'
    },

    CZK: {
        name: 'Czech Koruna'
    },

    DKK: {
        name: 'Danish Krone'
    },

    EGP: {
        name: 'Egyptian Pound'
    },

    EUR: {
        name: 'Euro'
    },

    FJD: {
        name: 'Fiji Dollar'
    },

    GBP: {
        name: 'British Pound'
    },

    HKD: {
        name: 'Hong Kong Dollar'
    },

    HNL: {
        name: 'Honduran Lempira'
    },

    HUF: {
        name: 'Hungarian Forint'
    },

    ISK: {
        name: 'Iceland Krona'
    },

    INR: {
        name: 'Indian Rupee'
    },

    IDR: {
        name: 'Indonesian Rupiah'
    },

    ILS: {
        name: 'Israeli New Shekel'
    },

    JPY: {
        name: 'Japanese yen'
    },

    KRW: {
        name: 'Korean Won'
    },

    MYR: {
        name: 'Malasyan Ringgit'
    },

    MXN: {
        name: 'Mexican Peso'
    },

    NZD: {
        name: 'New Zealand Dollar'
    },

    NOK: {
        name: 'Norwegian Krone'
    },

    PKR: {
        name: 'Pakistan Rupee'
    },

    PHP: {
        name: 'Philippine Peso'
    },

    PLN: {
        name: 'Polish Zloty'
    },

    RUB: {
        name: 'Russian Ruble'
    },

    SGD: {
        name: 'Singapore Dollar'
    },

    SEK: {
        name: 'Swedish Krona'
    },

    TWD: {
        name: 'Taiwan Dollar'
    },

    THB: {
        name: 'Thai Baht'
    },

    TRY: {
        name: 'Turkish Lira'
    },

    USD: {
        name: 'US Dollar'
    },

    ZAR: {
        name: 'South Africa Rand'
    }
};


// ...
var currency = {
    format: function(code, value, options) {
        return currencyFormat(code, value, options);
    },

    shortFormat: function(code, value) {
        return shortCurrencyFormat(code, value);
    },

    getUsedCurrencies: function() {
        return usedCurrencies;
    },

    composeCurrencyName: function(code) {
        if (code in usedCurrenciesInfo) {
            return code + ' (' + usedCurrenciesInfo[code].name + ')';
        }
        // fallback for broken currency names
        else {
            return code;
        }
    },

    convertToDefaultCurrency: function(value, currency, conversionTable) {
        var defaultCurrency = app.user.get('client').default_currency;

        if (conversionTable && (conversionTable.currency === defaultCurrency) && (conversionTable[currency])) {
            return {
                value: (value / conversionTable[currency]),
                converted: true
            };
        }

        return {
            value: value,
            converted: false
        };
    },

    getUsedCurrenciesToSelect2Array: function(onlyCurrenciesWithConversion) {
        var rdo = [];
        var withConversion = onlyCurrenciesWithConversion || false;
        var conversionTable = {};

        $.extend(conversionTable, app.user.get('client').currencies);

        // default currency
        conversionTable[app.user.get('client').default_currency] = "1.0";

        for (var i = 0; i < usedCurrencies.length; ++i) {
            if (!withConversion || (usedCurrencies[i] in conversionTable)) {
                rdo.push({ id: usedCurrencies[i], text: this.composeCurrencyName(usedCurrencies[i])});
            }
        }

        return rdo;
    }
};

export default currency;

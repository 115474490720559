import React from 'react';

import style from './menu.css';


const ARROW_HEIGHT = 18;
const MENU_WIDTH = 236;


export default class PopoverMenu extends React.Component {
    constructor(props) {
        super(props);

        if (props.anchor === 'left') {
            this.top = props.top;
            this.left = props.left;
        } else {
            this.top = this.props.top + ARROW_HEIGHT;
            this.left = this.props.left - (MENU_WIDTH / 2)
        }

        this.state = {
            confirmationView: null
        };
    }

    handleBackdropClick(ev) {
        ev.stopPropagation();
        this.props.onClose();
    }

    handleItemClick(item) {
        if (item.confirmation) {
            switch (item.confirmationId) {
                case 'delete':
                    this.confirmationTitle = 'Delete Item';
                    this.confirmationDescription = `Are you sure you want to <b>permanently</b> delete ${item.confirmationName}?`;
                    this.confirmationCancelTitle = 'Cancel';
                    this.confirmationAcceptTitle = 'Delete';
                    break;

                case 'cancelCampaign':
                    this.confirmationTitle = 'Cancel Campaign';
                    this.confirmationDescription = `Are you sure you want to cancel ${item.confirmationName}?`;
                    this.confirmationCancelTitle = 'No';
                    this.confirmationAcceptTitle = 'Yes';
                    break;
            }

            this.setState({
                confirmationView: item
            });
        } else {
            this.props.onClose(item.id);
        }
    }

    handleCancelDeletion() {
        this.setState({
            confirmationView: null
        });
    }

    handleDeletion() {
        const item = this.state.confirmationView;

        this.setState({
            confirmationView: null
        });

        this.props.onClose(item.id);
    }

    render() {
        return (
            <div
                className={style.backdrop}
                onClick={this.handleBackdropClick.bind(this)}
            >
                <div
                    className={style.dialog}
                    style={{
                        width: MENU_WIDTH,
                        top: this.top,
                        left: this.left
                    }}
                    onClick={(ev) => ev.stopPropagation()}
                >
                    {this.props.anchor === 'left' ?
                        <div className={style.leftArrow}/>
                    :
                        <div className={style.arrow}/>
                    }

                    {this.state.confirmationView ? (
                        <div className={style.deletion}>
                            <div className={style.dTitle}>{this.confirmationTitle}</div>
                            <div
                                className={style.dMessage}
                                dangerouslySetInnerHTML={{ __html: this.confirmationDescription}}
                            />

                            <div className={style.dFooter}>
                                <div
                                    className={style.dCancel}
                                    onClick={this.handleCancelDeletion.bind(this)}
                                >
                                    <div
                                        className='icon-angle-left'
                                        style={{
                                            fontSize: '24px',
                                            marginRight: '5px'
                                        }}
                                    />
                                    <div>{this.confirmationCancelTitle}</div>
                                </div>
                                <div
                                    className={style.dDelete}
                                    onClick={this.handleDeletion.bind(this)}
                                >
                                    {this.confirmationAcceptTitle}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={style.items}>
                            {this.props.items.map((item, iidx) => {
                                if (item.id === 'separator') {
                                    return (
                                        <div
                                            key={`item_separator_${iidx}`}
                                            className={style.separator}
                                        />
                                    );
                                }

                                return (
                                    <div
                                        key={`item_${item.id}`}
                                        className={style.item}
                                        onClick={() => this.handleItemClick.bind(this)(item)}
                                    >
                                        {item.icon &&
                                            <div className={`
                                                ${style.icon}
                                                ${item.icon}
                                            `}/>
                                        }

                                        {item.title}

                                        {item.confirmation &&
                                            <div
                                                className={`
                                                    icon-angle-right
                                                    ${style.rightAngle}
                                                `}
                                            />
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import TextManager from 'app/text-manager'
import vent from 'js/vent.js'
import PaginatedListView from 'js/views/base/paginated_list.js'
import IndividualItemView from 'js/views/individuals/item.js'
import ListNavbarView from 'js/views/base/list_navbar.js'
import BaseToolbarView from 'js/views/base/toolbar.js'
import ActivityCreator from 'js/utils/activity_creator.js'
import IndividualsCollection from 'js/collections/contacts'


function createIndividualsList(topView, collection, tools, extraTools, fetchOptions, organizationModel, opportunityModel) {
    var navbarView, listView, tabs;

    // when coming from opportunity view
    if (opportunityModel) {
        var defaultSet = false;

        var opportunityIndividualsCollection = new (IndividualsCollection.extend({
            urlRoot: function () {
                return '/opportunities/' + opportunityModel.get('id') + '/individuals';
            },
            defaultSortOn: [{
                attribute: 'full_name',
                order: 'asc'
            }]
        }));

        var createOpportunityIndividualsListView = function () {
            var opportunityIndividualsListView = new PaginatedListView({
                id: 'individuals-list',
                listItemView: IndividualItemView,
                listItemViewOptions: {
                    section: 'Contacts',
                    url: 'individuals',
                    tools: tools,
                    extraField: 'role'
                },
                showItem: function (model) {
                    var args = {
                        end: true,
                        source: this // Pass in list view
                    };
                    vent.trigger('individuals:detail', args, model);
                },
                collection: opportunityIndividualsCollection,
                fetchOptions: fetchOptions
            });

            opportunityIndividualsListView.listenTo(opportunityIndividualsListView, 'followLink', function(model, link) {
                app.followLink(model, link);
            });

            if (!defaultSet) {
                opportunityIndividualsListView.listenTo(opportunityIndividualsListView, 'collectionCountChange', function (count, loaded) {
                    if (loaded && count === 0) {
                        navbarView.showTab('organizationIndividualsTab');
                    }
                });
                defaultSet = true;
            }

            return opportunityIndividualsListView;
        };

        var createListView = function() {
            var listView = new PaginatedListView({
                id: 'individuals-list',
                listItemView: IndividualItemView,
                listItemViewOptions: {
                    section: 'Contacts',
                    url: 'individuals',
                    tools: tools,
                    extraField: 'role'
                },
                showItem: function(model) {
                    var args = {
                        end: true,
                        source: this // Pass in list view
                    };
                    vent.trigger('individuals:detail', args, model);
                },
                collection: collection,
                fetchOptions: fetchOptions
            });

            listView.listenTo(listView, 'followLink', function(model, link) {
                app.followLink(model, link);
            });

            return listView;
        };

        var createOpportunityToolbarView = function() {
            return new BaseToolbarView({
                collection: opportunityIndividualsCollection,
                tools: _.extend({
                    newItem: true,
                    addItem: true,
                    removeItem: true,
                    moveToGroup: true,
                    selectAllPage: true
                }, extraTools),
                toolLabels: {
                    removeItem: 'Remove from Deal'
                },
                addItemOptions: extraTools.addItemOptions
            });
        };

        var createToolbarView = function() {
            return new BaseToolbarView({
                collection: collection,
                tools: _.extend({
                    selectAllPage: true,
                    customAction: true
                }, extraTools),
                toolLabels: {
                    customAction: 'Add to Deal'
                },
                tooltTitles: {
                    customAction: 'Add selected to deal'
                },
                addItemOptions: extraTools.addItemOptions
            });
        };

        tabs = [
            {
                id: 'opportunityIndividualsTab',
                title: TextManager.getText('ID_ENTITY_OWNS_ENTITY', ['${ID_DEAL, capitalize}', '${ID_INDIVIDUAL, capitalize, plural}']),
                'class': 'list-nav-individuals',
                view: createOpportunityIndividualsListView,
                toolbarView: createOpportunityToolbarView
            },
            {
                id: 'organizationIndividualsTab',
                title: TextManager.getText('ID_ENTITY_OWNS_ENTITY', ['${ID_ORGANIZATION, capitalize}', '${ID_INDIVIDUAL, capitalize, plural}']),
                'class': 'list-nav-individuals',
                view: createListView,
                toolbarView: createToolbarView
            }
        ];
    }
    else {
        listView = new PaginatedListView({
            id: 'individuals-list',
            listItemView: IndividualItemView,
            listItemViewOptions: {
                section: 'Contacts',
                url: 'individuals',
                tools: tools,
                extraField: 'role'
            },
            showItem: function(model) {
                var args = {
                    end: true,
                    source: this // Pass in list view
                };
                vent.trigger('individuals:detail', args, model);
            },
            collection: collection,
            fetchOptions: fetchOptions
        });

        listView.listenTo(listView, 'followLink', function(model, link) {
            app.followLink(model, link);
        });

        var toolbarView = new BaseToolbarView({
            collection: collection,
            tools: _.extend({
                newItem: tools.hasPermissions || false,
                addItem: tools.hasPermissions || false,
                removeItem: true,
                moveToGroup: true,
                selectAllPage: true
            }, extraTools),
            addItemOptions: extraTools.addItemOptions
        });

        tabs = [
            {
                id: 'individuals',
                title: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
                'class': 'list-nav-individuals',
                view: listView,
                toolbarView: toolbarView
            }
        ];
    }

    navbarView = new ListNavbarView({
        title: 'Individuals',
        showTabs: tabs.length > 1,
        iconClass: 'icon-user',
        tabs: tabs
    });

    if (opportunityModel) {
        navbarView.listenTo(navbarView, 'opportunityIndividualsTab:showItem organizationIndividualsTab:showItem', function(model) {
            if (topView.pushIndividuals) {
                topView.trigger('push-view:individual:show', {
                    model: model
                });
            }
            else {
                topView.trigger('replace-view:individual:show', {
                    model: model
                });
            }
        });

        navbarView.listenTo(navbarView, 'opportunityIndividualsTab:toolbar:new', function() {
            topView.trigger('replace-view:individual:new', {
                organization: organizationModel,
                fromOpportunityModel: opportunityModel
            });
        });

        navbarView.listenTo(navbarView, 'opportunityIndividualsTab:toolbar:add-item', function(model) {
            $.ajax({
                url: '/opportunities/' + opportunityModel.get('id') + '/individuals/' + model.get('id'),
                method: 'PUT',
                contentType: 'application/json',
                success: function(item) {
                    opportunityIndividualsCollection.add(item);
                }
            });
        });

        navbarView.listenTo(navbarView, 'opportunityIndividualsTab:toolbar:remove', function(selection) {
            var ids = _.map(selection.items, function(item) {
                return { id: item.model.get('id') };
            });
            $.ajax({
                url: '/opportunities/' + opportunityModel.get('id') + '/individuals',
                method: 'DELETE',
                data: JSON.stringify(ids),
                contentType: 'application/json',
                success: function() {
                    opportunityIndividualsCollection.remove(ids);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.getText('ID_ENTITY_REMOVED_FROM_ENTITY', ['${ID_INDIVIDUAL, capitalize, pluralize=' + ids.length + '}', '${ID_DEAL}']),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        });

        navbarView.listenTo(navbarView, 'organizationIndividualsTab:toolbar:customAction', function(selection) {
            var ids = _.map(selection.items, function(item) {
                return { id: item.model.get('id') };
            });
            $.ajax({
                url: '/opportunities/' + opportunityModel.get('id') + '/individuals',
                method: 'PUT',
                data: JSON.stringify(ids),
                contentType: 'application/json',
                success: function() {
                    selection.success();
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.getText('ID_ENTITY_ADDED_TO_ENTITY', ['${ID_INDIVIDUAL, capitalize, pluralize=' + ids.length + '}', '${ID_DEAL}']),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        });
    }
    else {
        navbarView.listenTo(navbarView, 'showItem', function(model) {
            if (topView.pushIndividuals) {
                topView.trigger('push-view:individual:show', {
                    model: model
                });
            } else {
                topView.trigger('replace-view:individual:show', {
                    model: model
                });
            }
        });

        navbarView.listenTo(navbarView, 'toolbar:new', function() {
            topView.trigger('replace-view:individual:new', {
                organization: organizationModel
            });
        });

        navbarView.listenTo(navbarView, 'toolbar:add-item', function(model) {
            listView.addItem(model);
        });
    }

    return navbarView;
}

export default createIndividualsList;

import $ from 'jquery'
import _ from 'underscore'
import React from 'react'

import classnames from 'classnames'
import app from 'js/app'
import ContentFolderModel from 'js/models/content_folder'
import Utilities from 'js/utils/utilities'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import {NewSelect} from 'js/react_views/widgets/select'
import UsersCollection from 'js/collections/users.js'

import style from './browser.css'

function uploadFile(file, parentFolderId, callbacks) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('parent_id', parentFolderId);

    $.ajax({
        type: 'POST',
        url: '/content_files',
        contentType: false,
        processData: false,
        data: formData,
        xhr: function() {
            var xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    callbacks.progress(event.loaded / event.total);
                }
            }, false);

            return xhr;
        }
    }).done(function(data) {
        callbacks.done(data);
    }).fail(function() {
        callbacks.fail();
    });
}


class ContentFolder extends React.Component {
    render() {
        return (
            <div
                className={style.Folder}
                title={this.props.data.name}
                onClick={() => this.props.onFolderSelected(this.props.data)}
            >
                <div className={style.Thumbnail}>
                    <i className={`${style.Icon} icon-folder`}/>
                </div>
                <div className={style.Name}>{this.props.data.name}</div>
            </div>
        );
    }
}

class ContentFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        const self = this;

        if (props.data.fileToUpload) {
            this.state.uploadingFile = props.data.fileToUpload;
            this.state.percentComplete = 0;

            _.defer(function() {
                self.el.scrollIntoView();
            });

            uploadFile(
                props.data.fileToUpload,
                props.parentFolder.get('id'),
                {
                    progress: function(pc) {
                        self.setState({
                            percentComplete: pc
                        });
                    },
                    done: function(data) {
                        self.setState({
                            fileData: data,
                            uploadingFile: null
                        });
                    },
                    fail: function() {
                        self.setState({
                            uploadingFile: null
                        });
                        self.props.onFileUploadError(self.props.data.id);
                    }
                }
            );
        } else {
            this.state.fileData = this.props.data;
        }
    }

    render() {
        if (this.state.uploadingFile) {
            const filename = this.state.uploadingFile.name;
            const dotPos = filename.lastIndexOf('.');
            const ext = dotPos !== -1 ? filename.substr(dotPos) : '';
            const icon = Utilities.getTypeIcon(ext);
            const progressBarWidth = this.state.percentComplete * 84;
            const iconClasses = classnames({
                [style.Icon]: true,
                [icon.icon]: true
            });

            return (
                <div
                    ref={(el) => this.el = el}
                    className={style.File}
                    title={filename}
                >
                    <div className={style.Thumbnail}>
                        <div className={style.ProgressContainer}>
                            <div className={style.ProgressBar} style={{width: progressBarWidth + 'px'}}/>
                        </div>
                        <i className={iconClasses}/>
                    </div>
                    <div className={style.Name}>{filename}</div>
                </div>
            );
        } else {
            const file = this.state.fileData;
            const icon = Utilities.getTypeIcon(file.ext);
            const iconClasses = classnames({
                [style.Icon]: true,
                [icon.icon]: true
            });
            let imageUrl = null;

            if (icon.type === 'image-file') {
                imageUrl = file.url;
            } else if (file.has_thumb) {
                imageUrl = app.options.apiUrl + '/content_files/' + file.id + '?thumb&client=' + app.user.get('client')['short_id'];
            }

            return (
                <div
                    className={style.File}
                    title={file.name}
                    onClick={() => this.props.onFileSelected(this.state.fileData)}
                >
                    <div className={style.Thumbnail}>
                        {imageUrl
                            ? <img className={style.Image} src={imageUrl}/>
                            : <i className={iconClasses}/>
                        }
                    </div>
                    <div className={style.Name}>{file.name}</div>
                </div>
            );
        }
    }
}

class LoadingView extends React.Component {
    render() {
        return (
            <div className={`loader-container ${style.Loader}`}>
                <div className='loading-view'><div className='loader'/></div>
            </div>
        );
    }
}

class IconView extends React.Component {
    render() {
        return (
            <div className={style.IconViewContent}>
                {this.props.folders.map(folder => {
                    return <ContentFolder
                        key={folder.id}
                        data={folder}
                        onFolderSelected={this.props.onFolderSelected}
                    />
                })}

                {this.props.files.map(file => {
                    return <ContentFile
                        key={file.id}
                        data={file}
                        parentFolder={this.props.parentFolder}
                        onFileUploadError={this.props.onFileUploadError}
                        onFileSelected={this.props.onFileSelected}
                    />
                })}
            </div>
        );
    }
}

class ListContentFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        const self = this;

        if (props.file.fileToUpload) {
            this.state.uploadingFile = props.file.fileToUpload;
            this.state.percentComplete = 0;

            _.defer(function() {
                self.el.scrollIntoView();
            });

            uploadFile(
                props.file.fileToUpload,
                props.parentFolder.get('id'),
                {
                    progress: function(pc) {
                        self.setState({
                            percentComplete: pc
                        });
                    },
                    done: function(data) {
                        self.setState({
                            uploadingFile: null,
                            fileData: data
                        });
                    },
                    fail: function() {
                        self.setState({
                            uploadingFile: null
                        });
                        self.props.onFileUploadError(self.props.file.id);
                    }
                }
            );
        } else {
            this.state.fileData = this.props.file;
        }
    }

    render() {
        if (this.state.uploadingFile) {
            const filename = this.state.uploadingFile.name;
            const dotPos = filename.lastIndexOf('.');
            const ext = dotPos !== -1 ? filename.substr(dotPos) : '';
            const icon = Utilities.getTypeIcon(ext);
            const progressBarWidth = this.state.percentComplete * 100;

            return (
                <div
                    ref={(el) => this.el = el}
                    className={style.Row}
                >
                    <i className={`${style.Column} ${style.Icon} ${icon.icon}`}/>
                    <div className={`${style.Column} ${style.Text}`} title={filename}>{filename}</div>
                    <div
                        className={`${style.Column}`}
                    >
                        <div className={style.ProgressContainer}>
                            <div className={style.ProgressBar} style={{width: progressBarWidth + '%'}}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            const file = this.state.fileData;
            const icon = Utilities.getTypeIcon(file.ext);
            const imageUrl = icon.type === 'image-file' ? file.url : app.options.apiUrl + '/content_files/' + file.id + '?thumb&client=' + app.user.get('client')['short_id'];

            return (
                <div
                    className={style.Row}
                    onClick={() => this.props.onFileSelected(file)}
                >
                    {imageUrl
                        ? <div className={`${style.Column} ${style.Image}`}><img src={imageUrl}/></div>
                        : <i className={`${style.Column} ${style.Icon} ${icon.icon}`}/>
                    }
                    <div className={`${style.Column} ${style.Text}`} title={file.name}>{file.name}</div>
                    <div
                        className={`${style.Column} ${style.Text}`}
                        title={file.url}
                        onClick={(ev) => {
                            ev.stopPropagation();

                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(file.url);
                            }
                        }}
                    >
                        {file.url}
                    </div>
                    <div className={`${style.Column} ${style.Text}`} title={file.owner.name}>{file.owner.name}</div>
                    <div className={`${style.Column} ${style.Modified}`}>
                        <div>{dateFormat.timelineFormat(file.modified)}</div>
                        <div className={style.ModifyBy}>by {file.last_modified_by_name}</div>
                    </div>
                </div>
            );
        }
    }
}

class ListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sortBy: {
                field: 'name',
                order: 'asc'
            }
        };
    }

    cloneAndSortItems(items) {
        let sortedItems = _.clone(items);

        // 'previous folder' always goes the first
        const previousFolder = _.find(sortedItems, i => i.isPreviousFolder);

        if (previousFolder) {
            sortedItems = _.reject(sortedItems, i => i.isPreviousFolder);
        }

        switch(this.state.sortBy.field) {
            case 'url':
                sortedItems = _.sortBy(sortedItems, (i) => i.url || i.description);
                break;

            case 'owner':
                sortedItems = _.sortBy(sortedItems, (i) => i.owner.name);
                break;

            default:
                sortedItems = _.sortBy(sortedItems, this.state.sortBy.field);
                break;
        }

        if (this.state.sortBy.order === 'desc') {
            sortedItems.reverse();
        }

        if (previousFolder) {
            sortedItems.unshift(previousFolder);
        }

        return sortedItems;
    }

    handleHeaderClick(field) {
        if (this.state.sortBy.field === field) {
            this.setState({
                sortBy: {
                    field: field,
                    order: this.state.sortBy.order === 'asc' ? 'desc' : 'asc'
                }
            });
        } else {
            this.setState({
                sortBy: {
                    field: field,
                    order: 'asc'
                }
            });
        }
    }

    render() {
        const folders = this.cloneAndSortItems(this.props.folders);
        const files = this.cloneAndSortItems(this.props.files);

        return (
            <div className={style.ListViewContent}>
                <div className={`${style.Row} ${style.HeaderRow}`}>
                    <div className={`${style.Column} ${style.HeaderTitle}`}/>
                    <div
                        className={`${style.Column} ${style.HeaderTitle}`}
                        onClick={() => this.handleHeaderClick.bind(this)('name')}
                    >
                        <div className={style.HTName}>Name</div>
                        {this.state.sortBy.field === 'name' &&
                            <i className={`${this.state.sortBy.order === 'asc' ? 'icon-caret-up' : 'icon-caret-down'}`}/>
                        }
                    </div>
                    <div
                        className={`${style.Column} ${style.HeaderTitle}`}
                        onClick={() => this.handleHeaderClick.bind(this)('url')}
                    >
                        <div className={style.HTName}>Public URL</div>
                        {this.state.sortBy.field === 'url' &&
                            <i className={`${this.state.sortBy.order === 'asc' ? 'icon-caret-up' : 'icon-caret-down'}`}/>
                        }
                    </div>
                    <div
                        className={`${style.Column} ${style.HeaderTitle}`}
                        onClick={() => this.handleHeaderClick.bind(this)('owner')}
                    >
                        <div className={style.HTName}>Owner</div>
                        {this.state.sortBy.field === 'owner' &&
                            <i className={`${this.state.sortBy.order === 'asc' ? 'icon-caret-up' : 'icon-caret-down'}`}/>
                        }
                    </div>
                    <div
                        className={`${style.Column} ${style.HeaderTitle}`}
                        onClick={() => this.handleHeaderClick.bind(this)('modified')}
                    >
                        <div className={style.HTName}>Modified</div>
                        {this.state.sortBy.field === 'modified' &&
                            <i className={`${this.state.sortBy.order === 'asc' ? 'icon-caret-up' : 'icon-caret-down'}`}/>
                        }
                    </div>
                </div>
                <div className={style.ListContent}>
                    {folders.map(folder => {
                        return (
                            <div
                                key={folder.id}
                                className={style.Row}
                                onClick={() => this.props.onFolderSelected(folder)}
                            >
                                <div className={`${style.Column} ${style.Icon} icon-folder`}/>
                                <div className={`${style.Column} ${style.Text}`} title={folder.name}>{folder.name}</div>
                                <div className={`${style.Column} ${style.Text}`} title={folder.description}>{folder.description}</div>
                                {!folder.isPreviousFolder && <div className={`${style.Column} ${style.Text}`} title={folder.owner ? folder.owner.name : ''}>{folder.owner ? folder.owner.name : ''}</div>}
                                {!folder.isPreviousFolder &&
                                    <div className={`${style.Column} ${style.Modified}`}>
                                        <div>{dateFormat.timelineFormat(folder.modified)}</div>
                                        {folder.last_modified_by_name && <div className={style.ModifyBy}>by {folder.last_modified_by_name}</div>}
                                    </div>
                                }
                            </div>
                        );
                    })}

                    {files.map(file => (
                        <ListContentFile
                            key={file.id}
                            file={file}
                            parentFolder={this.props.parentFolder}
                            onFileUploadError={this.props.onFileUploadError}
                            onFileSelected={this.props.onFileSelected}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

class Browser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            folders: [],
            loading: false,
            owner: {id: app.user.get('id'), name: app.user.get('name')},
            activeView: 'list',
            searchString: '',
            newFolderDialogVisible: false,
            newFolderName: '',
            newFolderDesc: '',
            newFolderError: '',
            breadCrumbs: []
        };

        if (props.ownerIsAll) {
            this.state.owner = {id: 'all', name: 'All'};
        }

        this.doSearch = _.debounce(this.doSearch, 300);

        const self = this;

        _.defer(function() {
            self.loadFolder(props.folderId, true);
        });
    }

    openFileDialog() {
        let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });

        this.fileSelectInput.dispatchEvent(event);
    }

    openNewFolderDialog() {
        this.setState({
            newFolderDialogVisible: true,
            newFolderName: 'untitled folder',
            newFolderDesc: ''
        });

        const self = this;

        _.defer(function() {
            self.newFolderEl.select();
            self.newFolderEl.focus();
        });
    }

    handleFileSelected(event) {
        for (const file of event.target.files) {
            this.uploadFile(file);
        }
    }

    uploadFile(file) {
        let uploadFile = true;

        if (this.props.validateFile) {
            uploadFile = this.props.validateFile(file.name, file.size)
        }

        if (uploadFile) {
            const upload = (fileToUpload) => {
                let files = this.state.files;

                files.push({
                    fileToUpload: fileToUpload,
                    id: `${fileToUpload.name}_${new Date()}`
                });

                this.setState({
                    files: files
                });
            }

            if (this.props.imageMaxWidth || this.props.compressImages) {
                const icon = Utilities.getTypeIcon(`.${file.name.split('.').pop()}`);

                // resize the image
                if (icon.type === 'image-file') {
                    const reader = new FileReader();
                    const self = this;

                    reader.readAsDataURL(file);
                    reader.onloadend = (ev) => {
                        const image = new Image();
                        image.src = ev.target.result;

                        image.onload = (img) => {
                            const resizeImage = image.width > self.props.imageMaxWidth;

                            if (resizeImage || self.props.compressImages) {
                                const elem = document.createElement('canvas');
                                const width = resizeImage ? self.props.imageMaxWidth : image.width;
                                const scaleFactor = width / image.width;

                                elem.width = width;
                                elem.height = image.height * scaleFactor;

                                const ctx = elem.getContext('2d');

                                ctx.drawImage(image, 0, 0, elem.width, elem.height);

                                if (self.props.compressImages) {
                                    const dataUrl = elem.toDataURL(file.type, 0.7);
                                    const byteString = atob(dataUrl.split(',')[1]);
                                    const ab = new ArrayBuffer(byteString.length);
                                    const ia = new Uint8Array(ab);

                                    for (let i = 0; i < byteString.length; ++i) {
                                        ia[i] = byteString.charCodeAt(i);
                                    }

                                    const blob = new Blob([ab], {type: file.type});
                                    upload(new File([blob], file.name, {type: file.type}));
                                }
                                else {
                                    ctx.canvas.toBlob((blob) => {
                                        upload(new File([blob], file.name, {
                                            type: file.type,
                                            lastModified: Date.now()
                                        }));
                                    }, file.type, 1);
                                }
                            } else {
                                upload(file);
                            }
                        }
                    }
                } else {
                    upload(file);
                }
            } else {
                upload(file);
            }
        }
    }

    doSearch(str) {
        if (!str) {
            this.loadFolder(this.folder.get('id'));
            return;
        }

        this.setState({loading: true});

        const self = this;
        let data = {
            search: str,
            parent_type: 'id',
            parent: this.folder.get('id')
        };

        if (this.state.owner.id !== 'all') {
            data.owner_id = this.state.owner.id;
        }

        $.ajax({
            type: 'GET',
            url: '/content_files',
            data: data,
            contentType: 'application/json',
            dataType: 'json',
            success: function(results) {
                const files = self.props.filter ? results.filter(f => self.props.filter(f)) : results;

                self.setState({
                    loading: false,
                    files: files,
                    folders: []
                });
            }
        });
    }

    loadFolder(folderId, initBreadCrumbs) {
        if (folderId) {
            this.folder = new ContentFolderModel({ id: folderId });
            const self = this;

            this.setState({loading: true});

            let data = {
                detail: 'extended'
            };

            if (this.state.owner.id !== 'all') {
                data.owner_id = this.state.owner.id;
            }

            this.folder.fetch({
                data: data,
                success: function() {
                    let newState = {};

                    if (initBreadCrumbs) {
                        let breadCrumbs = [];

                        if (!self.folder.get('parent_id')) {
                            breadCrumbs = [{
                                id: self.props.folderId,
                                name: 'File Hosting'
                            }];
                        } else {
                            for (const f of self.folder.get('parent_folders')) {
                                breadCrumbs.push({
                                    id: f.id,
                                    name: (f.short_id === 'root' || f.short_id === 'public') ? 'File Hosting' : f.name
                                });
                            }

                            breadCrumbs.push({
                                id: self.folder.get('id'),
                                name: self.folder.get('name')
                            });
                        }

                        newState.breadCrumbs = breadCrumbs;
                    }

                    let files = self.folder.get('child_files');

                    if (self.props.filter && files) {
                        files = files.filter(f => self.props.filter(f));
                    }

                    let folders = self.folder.get('child_folders') || [];

                    if (self.folder.get('parent_id')) {
                        folders.unshift({
                            id: self.folder.get('parent_id'),
                            name: 'Previous Folder',
                            isPreviousFolder: true
                        });
                    }

                    newState.files = files || [];
                    newState.folders = folders;
                    newState.loading = false;

                    self.setState(newState);
                }
            });
        }
    }

    onFileUploadError(fileId) {
        this.setState({
            files: _.filter(this.state.files, (item) => item.id !== fileId)
        });

        MessageBox.showOk({
            message: 'Error uploading the file',
            icon: 'icon-warning'
        }, this.props.parent);
    }

    onFolderSelected(folder) {
        let breadCrumbs = this.state.breadCrumbs;

        if (folder.isPreviousFolder) {
            breadCrumbs.pop();
        } else {
            breadCrumbs.push({
                id: folder.id,
                name: folder.name
            });
        }

        this.loadFolder(folder.id);

    }

    onFileSelected(file) {
        let selectFile = true;

        if (this.props.validateFile) {
            selectFile = this.props.validateFile(file.name, file.size);
        }

        if (selectFile) {
            this.props.onFileSelected(file);
        }
    }

    folderDescChange(ev) {
        const value = ev.target.value;

        this.setState({
            newFolderDesc: value
        });
    }

    folderNameChange(ev) {
        const value = ev.target.value;
        let newState = {
            newFolderName: value
        };

        if (value && this.state.newFolderError) {
            newState.newFolderError = '';
        }

        this.setState(newState);
    }

    saveFolder() {
        if (!this.state.newFolderName) {
            this.setState({
                newFolderError: 'One character minimum required'
            });

            return;
        }

        const contentFolder = new ContentFolderModel();
        const self = this;

        contentFolder.save({
            name: this.state.newFolderName,
            description: this.state.newFolderDesc,
            parent_id: this.folder.get('id'),
        }, {
            success: function(data) {
                let folders = self.state.folders;

                folders.push(data.attributes);

                self.setState({
                    newFolderDialogVisible: false,
                    folders: folders
                });
            }
        });
    }

    handleDrop(ev) {
        ev.preventDefault();

        if (this.state.searchString) {
            return;
        }

        for (const file of ev.dataTransfer.files) {
            this.uploadFile(file);
        }
    }

    handleUserChange(items) {
        this.setState({
            owner: items[0]
        });

        const self = this;

        _.defer(function() {
            if (self.state.searchString) {
                self.doSearch(self.state.searchString);
            } else {
                self.loadFolder(self.folder.id);
            }
        });
    }

    handleSearchChange(ev) {
        const value = ev.target.value;

        this.setState({
            searchString: value
        });

        this.doSearch(value);
    }

    getUsers(callback) {
        const users = new UsersCollection();

        users.fetch({
            success: function(data) {
                let users = _.map(data.models, function(item) {
                    return {
                        id: item.get('id'),
                        name: item.get('name')
                    };
                });

                users.sort((a, b) => {
                    const la = a.name.toLowerCase();
                    const lb = b.name.toLowerCase();

                    if (la > lb) {
                        return 1;
                    } else if (la < lb) {
                        return -1;
                    }

                    return 0;
                });

                users.unshift({id: 'all', name: 'All'})

                callback(users);
            }
        });
    }

    handleBreadCrumbClick(crumbIdx) {
        const crumb = this.state.breadCrumbs[crumbIdx];

        this.loadFolder(crumb.id);

        this.setState({
            breadCrumbs: this.state.breadCrumbs.slice(0, crumbIdx + 1)
        });
    }

    render() {
        const activeView = this.state.activeView;
        const ActiveViewComponent = activeView === 'list' ? ListView : IconView;

        return (
            <div className={style.BrowserMask}>
                <div className={style.Browser}>
                    <div className={style.Header}>
                        <div className={style.Title}>Content Browser</div>
                        <div style={{width: '200px'}}>
                            <NewSelect
                                data={this.getUsers}
                                value={this.state.owner}
                                text='name'
                                onSelect={this.handleUserChange.bind(this)}
                            />
                        </div>
                        <div className={style.ViewSelector}>
                            <div
                                className={`icon-fileview ${style.ViewType} ${activeView === 'icon' ? style.Active : ''}`}
                                onClick={() => this.setState({activeView: 'icon'})}
                            />
                            <div
                                className={`icon-list ${style.ViewType} ${activeView === 'list' ? style.Active : ''}`}
                                onClick={() => this.setState({activeView: 'list'})}
                            />
                        </div>
                        <input
                            className={style.Search}
                            type='text'
                            placeholder='Search'
                            value={this.state.searchString}
                            onChange={this.handleSearchChange.bind(this)}
                        />
                        <button
                            className='button button-blue'
                            type="button"
                            onClick={this.openNewFolderDialog.bind(this)}
                        >
                            New Folder
                        </button>
                    </div>

                    <div className={style.BreadCrumbs}>
                        {this.state.breadCrumbs.map((crumb, cidx) => {
                            return (
                                <div
                                    key={`bc_${crumb.id}`}
                                    className={style.BCItem}
                                    onClick={() => this.handleBreadCrumbClick.bind(this)(cidx)}
                                >
                                    <div>{crumb.name}</div>

                                    {cidx < this.state.breadCrumbs.length - 1 &&
                                        <div className={`
                                            ${style.Arrow}
                                            icon-caret-right
                                        `}/>
                                    }
                                </div>
                            );
                        })}
                    </div>

                    <input
                        ref={(el) => this.fileSelectInput = el}
                        className={style.FileSelectInput}
                        type='file'
                        onChange={this.handleFileSelected.bind(this)}
                        multiple
                    />
                    <div
                        className={style.Content}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDrop={this.handleDrop.bind(this)}
                    >
                        {this.state.loading ? (
                            <LoadingView/>
                        ) : (
                            <ActiveViewComponent
                                folders={this.state.folders}
                                files={this.state.files}
                                parentFolder={this.folder}
                                onFolderSelected={this.onFolderSelected.bind(this)}
                                onFileSelected={this.onFileSelected.bind(this)}
                                onFileUploadError={this.onFileUploadError.bind(this)}
                            />
                        )}
                    </div>
                    <div className={style.Bottom}>
                        <span
                            className={style.Cancel}
                            onClick={this.props.onCancel}
                        >
                            Cancel
                        </span>
                        {!this.state.searchString && <span className={style.Upload}>
                            <span style={{marginRight: '5px'}}>Drag + drop to Upload or</span>
                            <button
                                className='button button-blue'
                                type="button"
                                onClick={this.openFileDialog.bind(this)}
                            >
                                Upload
                            </button>
                        </span>}
                    </div>
                </div>
                {this.state.newFolderDialogVisible && <div className={style.NewFolderMask}>
                    <div className={style.NewFolder}>
                        <div className={style.NFHeader}>
                            <div
                                className={style.NFHClose}
                                onClick={() => this.setState({newFolderDialogVisible: false})}
                            >
                                Close
                            </div>
                            <div className={style.NFHTitle}>Create Folder</div>
                        </div>
                        <div className={style.NFForm}>
                            <div className={style.NFFRow}>
                                <div className={style.NFFRTitle}>Name</div>
                                <div style={{width: '70%'}}>
                                    <input
                                        ref={(el) => {this.newFolderEl = el}}
                                        className={style.NFFRInput}
                                        type='text'
                                        value={this.state.newFolderName}
                                        onChange={this.folderNameChange.bind(this)}
                                        placeholder='Folder name'
                                        required
                                    />
                                    {this.state.newFolderError && <div className={style.NFFRError}>{this.state.newFolderError}</div>}
                                </div>
                            </div>
                            <div className={style.NFFRow}>
                                <div className={style.NFFRTitle}>Description</div>
                                <div style={{width: '70%'}}>
                                    <textarea
                                        className={style.NFFRTextArea}
                                        placeholder='Folder description'
                                        value={this.state.newFolderDesc}
                                        onChange={this.folderDescChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className={style.NFFSave} onClick={this.saveFolder.bind(this)}>Save</div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
};

export default Browser;
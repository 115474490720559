import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import d3 from 'd3'

import D3IPLookup from 'js/d3/iplookup'
import emptyTemplate from 'templates/empty.handlebars'


export default Marionette.View.extend({
    className: 'iplookup-widget',
    template: emptyTemplate,

    initialize: function() {
        this.d3 = new D3IPLookup();
        this.data = [];

        var view = this;

        $(window).on('resize', function() {
            view.renderD3();
        });
    },
    renderD3: function() {
        // if ( !this.funnel.deals || !this.funnel.phases ) {
        //     return;
        // }

        // ...
        this.d3.width(this.$el.width());
        this.d3.height(this.$el.height());
        this.d3.data(this.data);

        d3.select(this.$el.get(0)).call(this.d3);
    },
    onRender: function() {
        this.renderD3();
    },
    setInfo: function(info) {
        this.data = [];

        for (var i = 0; i < info.length; ++i) {
            var m = info.models[i];

            this.data.push({
                'name': m.get('name'),
                'last_seen': new Date(m.get('last_seen')),
                'count': m.get('count')
            });
        }

        if (this.data) {
            this.renderD3();
        }
    }
});

import $ from 'jquery';
import React from 'react';

import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './base.css';

const DEFAULT_PANEL_WIDTH = 400;
const ANIMATION_TIME = 400;

export default class PanelBase extends React.Component {
    constructor(props) {
        super(props);

        this.panelWidth = props.width || DEFAULT_PANEL_WIDTH;
        this.offset = -this.panelWidth;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentWillMount() {
        this.setState({
            visible: false,
            renderable: false,
            title: this.props.title || null,
            hasCloseButton: !!this.props.hasCloseButton,
            loading: false
        });
    }

    handleBackdropClick(ev) {
        ev.stopPropagation();
        this.hide();
    }

    showCloseButton(show) {
        this.setState({
            hasCloseButton: show
        });
    }

    setTitle(title) {
        this.setState({
            title: title
        });
    }

    setLoading(loading) {
        this.setState({
            loading: loading
        });
    }

    show() {
        if (this.state.visible) {
            return;
        }

        this.setState({
            renderable: true
        });

        const self = this;

        _.defer(function() {
            self.offset = 0;

            self.setState({
                visible: true
            });
        });
    }

    hide() {
        if (!this.state.visible || this.state.loading) {
            return;
        }

        this.offset = -this.panelWidth;

        this.setState({
            visible: false
        });

        const self = this;

        _.delay(function() {
            if (!self.mounted) {
                return;
            }

            self.setState({
                renderable: false
            });
        }, ANIMATION_TIME)
    }

    handleClose() {
        this.hide();
    }

    render() {
        // we are adding the state 'renderable' because the panel enters and exits with an animation and to do this the panel has to be visible all
        // the time. The problem is when the panel is outside the screen and the user press tab the panel gains focus and we want to avoid this.
        if (!this.state.renderable) {
            return null;
        }

        let dialogStyle = {
            width: this.panelWidth,
            right: this.offset
        };

        let backdropStyle = {
            right: this.panelWidth
        };

        // we are using 2 backdrops because if we use only one we lost the nice shadow effect of the toolbar over the panel
        return (
            <div className={style.panelBase}>
                {this.state.visible && !this.props.noBackdrop &&
                    <div
                        className={style.backdrop}
                        onClick={this.handleBackdropClick.bind(this)}
                        style={{
                            right: this.panelWidth
                        }}
                    />
                }

                {this.state.visible && !this.props.noBackdrop &&
                    <div
                        className={style.topBackdrop}
                        onClick={this.handleBackdropClick.bind(this)}
                    />
                }

                <div
                    className={style.dialog}
                    style={dialogStyle}
                >
                    {(this.state.title || this.state.hasCloseButton) &&
                        <div className={style.dHeader}>
                            {this.state.hasCloseButton &&
                                <div
                                    className={style.hClose}
                                    onClick={this.handleClose.bind(this)}
                                >
                                    Close
                                </div>
                            }

                            {this.state.title &&
                                <div className={style.hTitle}>
                                    {this.state.title}
                                </div>
                            }
                        </div>
                    }

                    <div className={style.dContent}>
                        {this.getContent()}
                    </div>

                    {this.state.loading &&
                        <div className={style.dLoader}>
                            <LoadingIndicator/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

import React from 'react';
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'

import app from 'js/app';
import vent from 'js/vent';
import Table from 'app_v2/components/table/table';
import funnel from 'js/d3/funnel';
import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import FunnelViewEx from 'js/views/funnelEx';
import FunnelMap from 'js/react_views/funnel-map/funnel-map';
import GroupPageFetcher from 'app_v2/sections/base/group_page_fetcher';
import FunnelColorKey from 'js/react_views/flex-dashboard/widgets/funnel_colorkey';
import AppConfig from 'app/app-config';
import IpwTrigger from 'js/ipw_trigger';
import OpportunityModel from 'js/models/opportunity';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './group_table_funnel.css';


const NUM_DEALS_TO_FETCH = 5000;


class Funnel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;

        const region = new Marionette.Region({
            el: this.element
        });

        const isPdcrm = AppConfig.getValue('is_pdcrm', false);

        this.funnel = new FunnelViewEx({
            dashboardView: isPdcrm ? 'pdcrm' : 'ss',
            triggerAllEvents: true,
            funnelColor: this.props.colorkeyId,
            hideWeeks: true
        });

        this.funnel.listenTo(this.funnel, 'show-deal', (dealId) => {
            this.props.onShowDeal(dealId);
        });

        this.funnel.listenTo(this.funnel, 'deal-phase-change', (data) => {
            this.handlePhaseChange(data);
        });

        region.show(this.funnel);

        this.setData(this.props);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handlePhaseChange(data) {
        const deal = data.deal;
        const phase = data.phase;
        const funnel = data.funnel

        if (!phase && !funnel) {
            return;
        }

        let dealData = {};

        if (phase) {
            dealData.phase_id = phase.id;
        } else {
            dealData.funnel_id = funnel.id;
        }

        if (AppConfig.getValue('showIPWCampaignTriggerMessage') && deal && phase) {
            const currentPhase = deal.get('phase');

            if (currentPhase && (phase.order > currentPhase.order)) {
                const mbContent = {
                    message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                    icon: 'icon-warning'
                };

                const self = this;

                const updateFields = function(phase, data, triggerIpw) {
                    const ipwTrigger = new IpwTrigger();

                    ipwTrigger.getPopulatedIpwCustomFields(phase.name, triggerIpw, null, function(fields) {
                        dealData = _.extend(dealData, fields);
                        self.updateDeal(deal.id, dealData, deal);
                    });
                }

                MessageBox.showYesNo(mbContent, this.funnel,
                    function() { // Yes
                        updateFields(phase, data, true);
                    },
                    function() { // No
                        updateFields(phase, data, false);
                    }, null,
                    {
                        staticRegion: true
                    }
                );
            } else {
                this.updateDeal(data.dealId, dealData);
            }
        } else {
            this.updateDeal(data.dealId, dealData);
        }
    }

    updateDeal(dealId, data) {
        const model = new OpportunityModel({id: dealId});
        const self = this;

        model.save(data, {
            patch: true,
            validate: false,
            ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
            params: {
                check_phase_change_allowed: true,
                check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
            },
            error: function(_, response) {
                let detail = '';

                try {
                    detail = JSON.parse(response.responseText).detail;
                } catch (_) {}

                if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' || detail.exception === 'OpportunityPhaseGateNotClear')) {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            let message = detail.message;

                            if (detail.exception === 'OpportunityPhaseGateNotClear') {
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }

                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            },
            complete: function() {
                self.props.onDealPhaseUpdated();
            }
        });
    }

    setData(data) {
        if (!data.phases || !data.periods || !data.deals) {
            return;
        }

        const funnel = new Backbone.Model(app.globalData.funnelsInfo.funnels.find(f => f.id === data.funnelId));

        this.funnel.setFunnelType(this.props.type);
        this.funnel.setPhases(data.phases);
        this.funnel.setPeriods(data.periods);
        this.funnel.setFunnels([funnel], data.funnelId);
        this.funnel.setDeals(new Backbone.Collection(data.deals).models);

        this.funnel.renderFunnel();
    }

    setDeals(deals) {
        this.funnel.setDeals(new Backbone.Collection(deals).models);
        this.funnel.renderFunnel();
    }

    highlight(rowId) {
        this.funnel.highlight(rowId);
        this.funnel.renderFunnel();
    }

    setColorKey(colorkeyId) {
        this.funnel.funnelView.setColorKey(colorkeyId);
        this.funnel.renderFunnel();
    }

    renderFunnel() {
        this.funnel.renderFunnel();
    }

    render() {
        return (
            <div
                style={{
                    height: 'calc(100% - 108px)'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        position: 'relative'
                    }}
                >
                    <div
                        ref={(el) => this.element = el}
                        className='deals-funnel-panel funnel-widget-container inverse'
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '6px',
                            left: 0
                        }}
                    />
                </div>
            </div>
        );
    }
}


class SiteMap extends React.Component {
    constructor(props) {
        super(props);

        this.funnel = new funnel();
        this.funnel.periods(props.periods);
        this.funnel.colorkey(props.colorkeyId);

        this.deals = new Backbone.Collection(props.deals).models;
    }

    render() {
        return (
            <div
                style={{
                    height: 'calc(100% - 108px)'
                }}
            >
                <div
                    style={{
                        background: '#494970',
                        borderRadius: '6px',
                        padding: '20px',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <FunnelMap
                            ref={(el) => this.component = el}
                            colorScheme={this.funnel.colorScheme}
                            style='solid'
                            funnelId={this.state.funnelId}
                            deals={this.deals}
                            showExtraControls={true}
                            onShowDeal={this.props.onShowDeal}
                        />
                    </div>
                </div>
            </div>
        );
    }

    setColorKey(colorkeyId) {
        this.funnel.colorkey(colorkeyId);
        this.component.updateDealsColors();
    }
}


export default class GroupTableFunnel extends React.Component {
    constructor(props) {
        super(props);

        this.phasesByFunnelId = {};
        this.dataDirty = true;
        this.visible = false;

        const preferences = app.user.get('preferences');
        this.colorkeyId = preferences.funnel_color || 'activity';

        let allTabs = [{
            id: 'funnel',
            title: 'Funnel'
        }, {
            id: 'momemtum',
            title: 'Momemtum'
        }];

        if (app.globalData.funnelsInfo.mapsAvailable) {
            allTabs.push({
                id: 'site_map',
                title: 'Site Map'
            })
        }

        if (props.tabs) {
            this.tabs = allTabs.filter(t => props.tabs.indexOf(t.id) !== -1);
        } else {
            this.tabs = allTabs;
        }

        const columnsCreator = new ColumnsCreator('opportunities');

        const defaultColumns = [
            columnsCreator.text('name', 'Name', { filterable: true }),
            columnsCreator.currency('buckets_total', 'Gross Release Price', { greedyWidth: true }),
        ];

        this.columns = AppConfig.getValue('deals.group_funnel_table_view.columns', null, {
            sectionId: this.props.sectionId,
            columnsCreator: columnsCreator
        }) || defaultColumns;

        let columnsIds = ['name', 'status', 'abbreviation', 'expected_close_date', 'organization.name', 'phase_last_changed',
                          'funnel', 'phase', 'value', 'default_value', 'currency', 'owner.name', 'latest_activity_in_days',
                          'bubble_representation_value'];

        columnsIds = columnsIds.concat(AppConfig.getValue('dealsListingExtraColumns', []));

        for (const c of this.columns) {
            if (c.ids) { // it's a composed column
                columnsIds = columnsIds.concat(c.ids);
            } else {
                columnsIds.push(c.id)
            }
        }

        if (AppConfig.getValue('customColorKeys')) {
            const clientPreferences = app.user.get('client').preferences;
            const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ? JSON.parse(clientPreferences.custom_colorkeys) : [];

            if (customColorkeys && customColorkeys.length > 0){
                for (var ck of customColorkeys) {
                    if (!ck.is_system_field) {
                        columnsIds.push(`custom_fields.${ck.dropdown_id}`);
                    }
                }
            }
        }

        if (AppConfig.getValue('funnel.display_bubble.check_achieved_price_and_asking_price')) {
            columnsIds.push('buckets');
        }

        const extraColumnsBySection = AppConfig.getValue('deals.list.extra_columns', null, this.props.sectionId) || [];

        if (extraColumnsBySection.length > 0) {
            columnsIds = columnsIds.concat(extraColumnsBySection);
        }

        if (app.globalData.funnelsInfo.mapsAvailable) {
            columnsIds = columnsIds.concat(app.globalData.funnelsInfo.fieldIds);
            columnsIds.push('buckets');
        }

        columnsIds = _.uniq(columnsIds);

        this.periods = new Backbone.Collection(app.globalData.periods).models;
        this.rowSelection = null;
        this.allRowsSelected = false;
        this.initFiltersBarOnShow = props.sharedFilterManager?.filterId;

        this.fetcher = new GroupPageFetcher('opportunities', NUM_DEALS_TO_FETCH, columnsIds.map(cid => {
            return {id: cid}
        }));

        this.fetcher.setSectionId(props.sectionId);
        this.fetcher.setFetchArgs(props.group.fetchArgs || {});

        if (props.group.orderBy) {
            this.fetcher.orderBy(props.group.orderBy.columnId, props.group.orderBy.direction);
        }

        this.state = {
            activeTab: 'funnel',
            funnelId: null,
            loading: false
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setDataDirty() {
        this.dataDirty = true;

        if (this.visible) {
            this.fetchData();
        }
    }

    searchBy(term) {
        if (term === null) { // search closed
            if (this.fetcher.getSearchTerm()) {
                this.fetcher.searchBy(null);
                this.fetchData();
            }
        } else {
            this.fetcher.searchBy(term);
            this.fetchData();
        }
    }

    onStartResizing() {
        this.table.onStartResizing();
    }

    onEndResizing() {
        this.table.onEndResizing();
    }

    show() {
        this.visible = true;

        let dirty = this.dataDirty;

        if (this.fetcher.getSearchTerm() !== this.props.sharedSearchTerm) {
            this.fetcher.searchBy(this.props.sharedSearchTerm);
            dirty = true;
        }

        if (this.fetcher.filterId !== this.props.sharedFilterManager.filterId) {
            this.fetcher.filterBy(this.props.sharedFilterManager.filterId);
            dirty = true;
        }

        if (dirty) {
            this.handleRowSelectionChange(null);
            this.fetchData();
        } else {
            this.handleRowSelectionChange(this.rowSelection);
        }

        this.table.adjustColumnsWidth();

        this.props.setBarsWidth('540px');

        const self = this;

        _.defer(function() {
            self.table.setColumnsRules(self.props.sharedFilterManager.getColumnsRules(self.table.getColumns()));

            if (self.initFiltersBarOnShow) {
                self.initFiltersBarOnShow = false;
                self.props.onFiltersChange(self.props.sharedFilterManager, self.table.getColumns(), self.props.filters);
            }
        });
    }

    hide() {
        this.visible = false;
    }

    fetchData() {
        const self = this;

        this.table.setLoading(true);
        this.dataDirty = false;

        this.setState({
            loading: true
        });

        this.fetcher.fetch(0, function(result) {
            if (!self.mounted) {
                return;
            }

            self.table.setData(result.rows, 0, result.start, result.total, result.numRows);
            self.table.setLoading(false);

            // enable the funnel visualization only if all the deals have the same funnel
            $.get(`/opportunities/check_same_funnel?${$.param(self.fetcher.getGroupPageArgs(0))}`, function(funnelId) {
                if (!self.mounted) {
                    return;
                }

                self.deals = result.rows;

                if (funnelId) {
                    self.phases = new Backbone.Collection(app.globalData.phasesInfo.phases.filter(p => p.funnel_id === funnelId) || []).models;
                }

                if (self.funnel) {
                    self.funnel.setDeals(self.deals);
                }

                self.setState({
                    funnelId: funnelId,
                    loading: false
                });
            });
        });
    }

    onStartResizing() {
        this.table.onStartResizing();
    }

    onEndResizing() {
        this.table.onEndResizing();
        this.funnel?.renderFunnel();
    }

    handleRowMouseEnter(rowId) {
        if (this.funnel) {
            this.funnel.highlight(rowId);
        }
    }

    handleRowMouseLeave(rowId) {
        if (this.funnel) {
            this.funnel.highlight(null);
        }
    }

    handleColorkeyChange(colorkeyId) {
        if (this.funnel) {
            this.funnel.setColorKey(colorkeyId);
        } else if (this.siteMap) {
            this.siteMap.setColorKey(colorkeyId);
        }
    }

    handleDealPhaseUpdated() {
        this.fetchData();
        this.props.onDealUpdated();
    }

    handleTableSortChange(columnId, sortDir) {
        this.fetcher.orderBy(columnId, sortDir);
        this.fetchData();
    }

    handleRowSelectionChange(rowSelection) {
        this.rowSelection = rowSelection;

        if (!rowSelection || !rowSelection.page) {
            this.allRowsSelected = false;
        }

        this.props.onRowSelectionChange(rowSelection, this.allRowsSelected);
    }

    handleTableFilterRuleChange(ruleFieldId, ruleCustomId, rule) {
        this.props.sharedFilterManager.setRule(ruleFieldId, ruleCustomId, rule);

        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchData();

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    deleteFilterRule(filter) {
        this.props.sharedFilterManager.setRule(filter.id, filter.custom, null);
        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchData();

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    deleteAllFiltersRules() {
        this.table.setColumnsRules({});
        this.props.sharedFilterManager.setRules(null);
        this.fetcher.filterBy(null);
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        this.fetchData();

        this.props.updateGroup(this.props.group, {
            filterId: null
        });
    }

    setFilterRules(rules) {
        this.props.sharedFilterManager.setRules(rules);
        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchData();

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    showPanel(panelId, showArgs) {
        switch (panelId) {
            case 'entity':
                this.props.showEntityPanel(showArgs);
                break;

            case 'advancedFilter':
                this.props.showAdvancedFilterPanel(this.props.entityType, this.props.filters, this.props.sharedFilterManager.getRules());
                break;

            case 'group':
                this.props.showGroupPanel(this.props.entityType, showArgs);
                break;
        }
    }

    saveCurrentGroupAsCsv() {
        const args = this.fetcher.getGroupPageArgs(0);

        args.rows = -1;
        args.csv = true;

        this.props.saveAsCsv(this.props.group?.name || this.props.entityType, `/group_pages?${$.param(args)}`);
    }

    render() {
        let groupFilterable = true;

        if (this.props.group?.protected) {
            groupFilterable = false;
        }

        return (
            <div className={style.tableFunnel}>
                <div className={style.tTable}>
                    <Table
                        ref={(el) => this.table = el}
                        hasPaginator={true}
                        selectableRows={true}
                        columns={this.columns}
                        filterable={groupFilterable}
                        rows={[]}
                        onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                        onRowClick={(rowId) => this.showPanel.bind(this)('entity', rowId)}
                        onMouseEnter={this.handleRowMouseEnter.bind(this)}
                        onMouseLeave={this.handleRowMouseLeave.bind(this)}
                        onFilterRuleChange={this.handleTableFilterRuleChange.bind(this)}
                        onSortChange={this.handleTableSortChange.bind(this)}
                        showAdvancedFilter={() => this.showPanel.bind(this)('advancedFilter')}
                    />
                </div>

                <div className={style.tFunnel}>
                    {!this.state.loading && this.state.funnelId &&
                        <div
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <div className={style.tTabControl}>
                                {this.tabs.map(t => {
                                    return (
                                        <div
                                            key={`t_${t.id}`}
                                            className={`
                                                ${style.tTab}
                                                ${this.state.activeTab === t.id ? style.tActive : ''}
                                            `}
                                            onClick={() => this.setState({ activeTab: t.id })}
                                        >
                                            {t.title}
                                        </div>
                                    );
                                })}
                            </div>

                            {this.state.activeTab === 'funnel' &&
                                <Funnel
                                    key='funnelView'
                                    ref={(el) => this.funnel = el}
                                    funnelId={this.state.funnelId}
                                    phases={this.phases}
                                    periods={this.periods}
                                    deals={this.deals}
                                    type='funnel'
                                    colorkeyId={this.colorkeyId}
                                    onShowDeal={(rowId) => this.showPanel.bind(this)('entity', rowId)}
                                    onDealPhaseUpdated={this.handleDealPhaseUpdated.bind(this)}
                                />
                            }

                            {this.state.activeTab === 'momemtum' &&
                                <Funnel
                                    key='momemtumView'
                                    ref={(el) => this.funnel = el}
                                    funnelId={this.state.funnelId}
                                    phases={this.phases}
                                    periods={this.periods}
                                    deals={this.deals}
                                    type='sos'
                                    colorkeyId={this.colorkeyId}
                                    onShowDeal={(rowId) => this.showPanel.bind(this)('entity', rowId)}
                                    onDealPhaseUpdated={this.handleDealPhaseUpdated.bind(this)}
                                />
                            }

                            {this.state.activeTab === 'site_map' &&
                                <SiteMap
                                    ref={(el) => this.siteMap = el}
                                    funnelId={this.state.funnelId}
                                    deals={this.deals}
                                    periods={this.periods}
                                    colorkeyId={this.colorkeyId}
                                    onShowDeal={(rowId) => this.showPanel.bind(this)('entity', rowId)}
                                />
                            }

                            <div className={style.tColorKey}>
                                <FunnelColorKey
                                    onColorkeyChanged={this.handleColorkeyChange.bind(this)}
                                />
                            </div>
                        </div>
                    }

                    {this.state.loading &&
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <LoadingIndicator/>
                        </div>
                    }

                    {!this.state.loading && !this.state.funnelId &&
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            TOO MANY FUNNELS
                        </div>
                    }
                </div>
            </div>
        );
    }
}
import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'

import BaseModel from 'js/models/base'
import BucketsCollection from 'js/collections/buckets'
import PhasesCollection from 'js/collections/phases'
import ForecastOpportunityCollection from 'js/collections/forecast_deals'
import app from 'js/app'
import Currency from 'js/utils/currency'


var Forecast;

Forecast = BaseModel.extend({
    name: 'Forecast',
    urlRoot: '/forecasts',
    apiType: 'salesseek.opportunities.models.forecast.Forecast',
    cloneable: true,
    defaults: function () {
        return {
            period: '',
            published: 'default'
        };
    },
    validate: function(attrs) {
        var errors = {};

        // Group name is required
        if (_.isUndefined(attrs.name)) {
            if (this.isNew()) {
                errors.missing_name = 'Forecast name is required';
            }
        }
        else {
            if (_.isNull(attrs.name) || attrs.name.length === 0) {
                errors.missing_name = 'Forecast name is required';
            }
        }

        // period is required
        if (_.isUndefined(attrs.period)) {
            if (this.isNew()) {
                errors.missing_period = 'Forecast period is required';
            }
        }
        else {
            if (_.isNull(attrs.period.id) || attrs.period.id === "0") {
                errors.missing_period = 'Forecast period is required';
            }
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    populateNew: function(callback, error) {
        var buckets = new BucketsCollection(),
            phases = new PhasesCollection();
        function fetchPhases() {
            phases.fetch({success: function() {
                if (callback) {
                    callback();
                }
            }, error: error});
        }
        buckets.fetch({success: function () {
            fetchPhases();
        }, error: error});
    },
    haveDealsProperlyConversionRate: function(returnInvalidDeals) {
        var conversionTable = app.user.get('client')['currencies'] || {};
        var defaultCurrency = app.user.get('client')['default_currency'];
        var collection = new ForecastOpportunityCollection(this.get('forecast_opportunities'));
        var opportunities = collection.models;
        var wantInfo = returnInvalidDeals || false;
        var info = [];
        var valid = true;

        for (var i = 0; i < opportunities.length; ++i) {
            var oppDef = opportunities[i];
            var opp = oppDef.get('opportunity');
            var currency = opp.get('currency');

            if ((currency !== defaultCurrency) && (!(currency in conversionTable))) {
                if (wantInfo){
                    info.push({ deal: opp.get('name'), currency: currency });
                    valid = false;
                }
                else {
                    return { valid: false };
                }
            }
        }

        return { info: info, valid: valid };
    },
    /**
     * Get quota values.
     *
     * @param opportunities collection  list of deals, set this value to override FC values. Used when there is no FC.
     * @returns {{committed: number, committedDownside: number, noneUpside: number}}
     */
    getQuotaValues: function(opportunities) {
        var totalCommitted = 0;
        var totalCommittedDownside = 0;
        var totalNoneUpside = 0;
        var conversionTable = {};

        if (opportunities) {
            $.extend(conversionTable, app.user.get('client')['currencies']);
            conversionTable.currency = app.user.get('client')['default_currency'];
        }
        else {
            conversionTable = this.get('currencies_conversion');
        }

        _.each(
            opportunities ? opportunities.models : this.get('forecast_opportunities'),
            function(opp) {
                var status = opportunities ? opp.get('status') : opp['status'],
                    value = 0;

                _.each(
                    opportunities ? opp.get('buckets') : opp['forecast_opportunity_buckets'],
                    function(b) {
                        value += b['value'];
                    }
                );

                if (opportunities) {
                    value = Currency.convertToDefaultCurrency(value, opp.get('currency'), conversionTable).value;
                }
                else if (opp.opportunity) {
                    value = Currency.convertToDefaultCurrency(value, opp['opportunity']['currency'], conversionTable).value;
                }

                if ( status === 'committed' ) {
                    totalCommitted += value;
                }
                else if ( status === 'committed_downside' ) {
                    totalCommittedDownside += value;
                }
                else if ( status === 'none_upside' ) {
                    totalNoneUpside += value;
                }
            }
        );

        return {
            committed: totalCommitted,
            committedDownside: totalCommittedDownside,
            noneUpside: totalNoneUpside
        };
    },
    toString: function() {
        return this.get('name');
    }
});
Forecast.fromString = function (string) {
    return new Forecast({name: string});
};
export default Forecast;

import $ from 'jquery'
import React from 'react';

import style from './tooltip.css';


class Tooltip extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visibility: 'hidden',
            left: 0,
            top: 0
        }

        this.timeToShow = props.timeToShow || 1000; // in milliseconds
        this.visible = false;
    }

    componentDidMount() {
        const parent = $(this.backdrop).parent();
        const self = this;

        parent.mouseenter(function() {
            self.showTooltip(true);
        });

        parent.mouseleave(function() {
            self.showTooltip(false);
        });

        const scrollableParent = this.getScrollableParent(parent[0]);

        if (scrollableParent) {
            $(scrollableParent).scroll(function() {
                self.showTooltip(false);
            });
        }
    }

    getScrollableParent(parent) {
        if (!parent) {
            return null;
        }

        let horScroll = parent.scrollWidth > parent.clientWidth;
        let verScroll = parent.scrollHeight > parent.clientHeight;

        if (horScroll || verScroll) {
            return parent;
        }

        return this.getScrollableParent(parent.parentNode);
    }

    showTooltip(show) {
        if ((show && this.visible) || (!show && !this.visible)) {
            return;
        }

        this.visible = show;

        if (show) {
            const self = this;

            _.delay(function() {
                if (!self.visible) {
                    return;
                }

                const parent = $(self.backdrop).parent();
                const parentbbox = parent[0].getBoundingClientRect();

                const mebbox = self.el.getBoundingClientRect();
                const align = self.props.align || 'bottom';
                const offset = 10;

                let left = 0;
                let top = 0;

                switch (align) {
                    case 'top':
                        left = (parentbbox.left + (parentbbox.width / 2)) - (mebbox.width / 2);
                        top = parentbbox.top - mebbox.height - offset;
                        break;

                    case 'bottom':
                        left = (parentbbox.left + (parentbbox.width / 2)) - (mebbox.width / 2);
                        top = parentbbox.bottom + offset;
                        break;

                    case 'left':
                        left = parentbbox.left - mebbox.width - offset;
                        top = (parentbbox.top + (parentbbox.height / 2)) - (mebbox.height / 2);
                        break;

                    case 'right':
                        left = parentbbox.right + offset;
                        top = (parentbbox.top + (parentbbox.height / 2)) - (mebbox.height / 2);
                        break;
                }

                self.setState({
                    left: left,
                    top: top,
                    visibility: 'visible'
                });
            }, this.timeToShow);
        } else {
            this.setState({
                visibility: 'hidden'
            });
        }
    }

    render() {
        return (
            <div
                ref={(el) => this.backdrop = el}
                className={style.TooltipBackdrop}
            >
                <div
                    ref={(el) => this.el = el}
                    className={`
                        ${style.Tooltip}
                        ${style[this.props.align || 'bottom']}
                    `}
                    style={{
                        visibility: this.state.visibility,
                        left: this.state.left,
                        top: this.state.top
                    }}
                >
                    <div>{this.props.title}</div>
                </div>
            </div>
        );
    }
}

export default Tooltip;
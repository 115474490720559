import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import classnames from 'classnames';
import security from 'js/utils/security'
import {TagList} from 'js/react_views/detail_view_components/tag-list';

import styles from './automation.css';


const statusMap = {
    on: { id: "on", title: "On" },
    off: { id: "off", title: "Off" },
    drain: { id: "drain", title: "Drain" }
};

const iconMap = {
    individuals: "icon-user",
    organizations: "icon-home",
    opportunities: "icon-funnel-sm"
};

class AutomationDetailView extends React.Component {
    render() {
        const ownerName = this.props.automation.owner.name;
        const name = this.props.automation.name;
        const hasEditPermission = security.checkPermission('edit', this.props.automation);
        const editTooltipCaption = hasEditPermission ? '' : "You don't have permission to edit this automation";
        const tags = this.props.automation.tags;
        const funnels = this.props.automation.funnels;

        const smallIconClasses = this.props.automation.entity_type ? classnames({
            [styles.smallIcon]: true,
            [iconMap[this.props.automation.entity_type]]: true
        }) : null;
        const editButtonClasses = classnames({
            [styles.editButton]: true,
            [styles.disabled]: !hasEditPermission
        });

        const handleEditClicked = function() {
            if (hasEditPermission) {
                this.props.handleEditClicked();
            }
        }

        return (
            <div className={styles.AutomationDetailView}>
                <div className={styles.header}>
                    <div className={styles.title}>Details</div>
                    <span className={styles.ownerName}>{ownerName}</span>
                    <a className={editButtonClasses} onClick={handleEditClicked.bind(this)} data-toggle="tooltip" title={editTooltipCaption}>Edit</a>
                </div>
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                        <i className="icon-bolt" />
                        <div className={smallIconClasses}></div>
                    </div>
                </div>
                <div className={styles.name} data-toggle="tooltip" title={name}>
                    {name}
                </div>
                <TagList
                    tags={tags}
                />
                <TagList
                    tags={funnels}
                    caseValue="funnels"
                />
                <div className={styles.stateContainer}>
                    <div className={styles.state}>
                        {statusMap[this.props.automation.state].title}
                    </div>
                </div>
                <div className={styles.descriptionContainer}>
                    Description
                    <div className={styles.description}>
                        {this.props.automation.description}
                    </div>
                </div>
            </div>
        )
    }
}

export default AutomationDetailView;
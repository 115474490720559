import _ from 'underscore'

import BaseCollection from 'js/collections/base'
import PeriodModel from 'js/models/period'


export default BaseCollection.extend({
    model: PeriodModel,
    getCurrentPeriodId: function() {
        var today = new Date(),
            ret = null;

        _.each(this.models, function(period) {
            var start = new Date(period.get('start_date')),
                end = new Date(period.get('end_date'));

            if (today >= start && today < end) {
                ret = period.get('id');
                return false;
            }
        });

        return ret;
    }
});

import _ from 'underscore';
import React from 'react';
import classnames from 'classnames';

import Utilities from 'js/utils/utilities';

import {Button} from 'js/react_views/widgets/widgets';
import Popover from 'js/react_views/widgets/popover';
import {
    NewSelect,
    FileSelectItem
} from 'js/react_views/widgets/select';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import RelatedFilesList from 'js/react_views/detail_view_components/related-files-list';
import ListPager from 'js/react_views/detail_view_components/list-pager';

import style from './related-files.css';

const colors = {
    faintestGrey: 'rgba(0, 0, 0, 0.07)',
    fainterGrey: 'rgba(0, 0, 0, 0.1)',
    faintGrey: 'rgba(0, 0, 0, 0.2)',
    faintGrey2: '#c1c4cd',
    faintGrey3: 'rgba(165, 170, 182, 0.6)',
    superLightGrey: '#f6f6f6',
    veryLightGrey: '#e0e1e6',
    quiteLightGrey: '#b5babf',
    midGrey: '#9ea7b0',
    darkishGrey: '#666666',
    darkGrey: '#404040',
    bluishGrey: '#888fa0',
    softBlue: '#70c2ff', // lighten(blue, 20%);
    lightBlue: '#d4ecff',
    paleBlue: '#eef7ff',
    blueBackground1: 'rgba(10, 151, 248, 0.8)',
    blueBackground2: 'rgba(10, 151, 255, 0.06)',
    link: '#0a97ff',
    starYellow: '#ffda00',
    green: '#43d350',
    paleGreen: '#A0F89D',
    red: '#ff283a',
    mutedRed: '#e54a45',
    linkedin: '#0075b6',
    twitter: '#00abf1',
    facebook: '#39579d',
    googleplus: '#da3f26',
    instagram: '#C13584'
};

const fontSizes = {
    plusTiny: 7,
    tiny: 11,
    smallest: 12,
    smaller: 13,
    normal: 14,
    large: 16,
    larger: 18,
    largest: 20,
    huge: 24
};

class RelatedFiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {showAddFilePopover: false};

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleAddFilePopoverClose = this.handleAddFilePopoverClose.bind(this);
        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.handleFileSelectDialog = this.handleFileSelectDialog.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
    }

    componentWillMount() {
        this.downloadForms = {};
    }

    handleAddButtonClick() {
        this.setState({showAddFilePopover: true});
    }

    handleAddFilePopoverClose() {
        this.setState({showAddFilePopover: false});
    }

    handleUploadClick() {
        let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        this.fileSelectInput.dispatchEvent(event);
    }

    handleFileSelectDialog() {
        this.props.onUpload(this.fileSelectInput.files);
    }

    handleFileSelect(items) {
        this.props.onAdd(items);
        this.setState({showAddFilePopover: false});
    }

    render() {
        return (
            <div
                className={classnames(
                    style.RelatedFiles,
                    { [style.readOnly]: !this.props.isEditable }
                )}>
                <div className={style.header}>
                    {this.props.isEditable && this.props.onAdd &&
                        <div className={`${style.headerBtnBox} tool-button`}>
                            <Button
                                color="blue"
                                onClick={this.handleAddButtonClick}>
                                Add from Library
                            </Button>
                        </div>
                    }
                    <h5
                        style={{
                            height: 42,
                            margin: 0,
                            color: colors.darkGrey,
                            fontSize: fontSizes.larger,
                            lineHeight: '43px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>Related Files</h5>
                    {this.state.showAddFilePopover &&
                        <Popover
                            title="Add an item"
                            wider={true}
                            onClose={this.handleAddFilePopoverClose}>
                            <NewSelect
                                url="/content_files"
                                itemView={FileSelectItem}
                                onSelect={this.handleFileSelect}
                                onCancel={this.handleAddFilePopoverClose}
                                width={325}
                                text="name"
                                placeholder="Search for a file"
                                options={{minimumInputLength: 1, initiallyExpanded: true}}
                             />
                        </Popover>
                    }
                </div>
                {this.props.isEditable &&
                    <div
                        style={{
                            marginTop: 12,
                            paddingBottom: 10,
                            borderBottom: '1px solid ' + colors.fainterGrey
                        }}>
                        {this.props.fileUpload ?
                            <div
                                style={{
                                    height: 70,
                                    padding: '20px 20px 0',
                                    borderRadius: 5,
                                    backgroundColor: colors.paleBlue,
                                    color: colors.quiteLightGrey,
                                    textAlign: 'right'
                                }}>
                                <div
                                    style={{
                                        position: 'relative',
                                        height: 10,
                                        borderRadius: 7,
                                        backgroundColor: colors.veryLightGrey
                                    }}>
                                    <div
                                        style={{
                                            height: 10,
                                            width: this.props.fileUpload.percentComplete + '%',
                                            borderRadius: 7,
                                            backgroundColor: colors.paleGreen
                                        }}></div>
                                </div>
                                <span style={{float: 'left'}}>Now uploading…</span>
                                {this.props.fileUpload.numFilesUploaded} out of {this.props.fileUpload.numFiles} file{this.props.fileUpload.numFiles > 1 && 's'} completed
                            </div>
                            :
                            <div className={style.uploadInstructions}>
                                Drag &#038; drop files here
                                {this.props.onUpload &&
                                    <span>
                                        <span style={{margin: '0 12px', color: 'grey'}}>or</span>
                                        <a
                                            onClick={this.handleUploadClick}
                                            style={{cursor: 'pointer'}}>
                                            Upload from your Computer</a>
                                        <input
                                            ref={(input) => {this.fileSelectInput = input;}}
                                            onChange={this.handleFileSelectDialog}
                                            style={{display: 'none'}} type="file" multiple />
                                    </span>
                                }
                            </div>
                        }
                    </div>
                }
                {!this.props.files &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {this.props.files && this.props.files.items.length > 0 &&
                    <div>
                        <RelatedFilesList
                            files={this.props.files.items}
                            userId={this.props.userId}
                            onDelete={this.props.isEditable && this.props.onDelete}
                            onYoti={this.props.onYoti} 
                            onYotiReminder={this.props.onYotiReminder} 
                            archiveYotiEnvelope={this.props.archiveYotiEnvelope}/>
                        <ListPager
                            start={this.props.files.start}
                            currentPageLength={this.props.files.items.length}
                            total={this.props.files.total}
                            onClickNext={this.props.onClickNext}
                            onClickPrev={this.props.onClickPrev} />
                    </div>
                }
                {this.props.files && this.props.files.items.length === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default RelatedFiles;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2'
import GroupsCollection from 'js/collections/groups'
import IndividualFavoritesModel from 'js/models/individual_favorites'
import IndividualsCollection from 'js/collections/contacts'
import IndividualItemView from 'js/views/individuals/item'
import OrganizationsCollection from 'js/collections/organizations'
import OrganizationItemView from 'js/views/organizations/item'
import OpportunitiesCollection from 'js/collections/opportunities'
import OpportunityItemView from 'js/views/opportunities/item'
import PaginatedListView from 'js/views/base/paginated_list'
import app from 'js/app'
import selectorTemplate from 'app/dashboard/widgets/group-selector/selector.handlebars'


var GroupSelectorView;

GroupSelectorView = Marionette.Layout.extend({
    className: 'group-widget',
    template: Handlebars.compile(selectorTemplate),
    ui: {
        selector: '.group-select'
    },
    regions: {
        listRegion: '.widget-content'
    },
    initialize: function() {
        this.collection = new GroupsCollection();
    },
    onRender: function() {
        var view = this,
            count = 0,
            favorites = new IndividualFavoritesModel({id: 'favorites'});

        function isReady() {
            count++;
            if (count === 2) {
                view.collection.add(favorites, { at: 0 });
                view.onCollection();
            }
        }

        favorites.fetch({
            complete: isReady
        });

        this.collection.fetch({
            complete: isReady
        });
    },
    onCollection: function() {
        var value = this.collection.at(0);
        var groupSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.selector,
            text: 'title',
            url: '/groups',
            params: {
                include_favorite: true
            },
            search: true,
            value:  { id: this.collection.at(0).get('id'), title: this.collection.at(0).get('name') },
            data: this.collection,
            options: {
                placeholder: 'Select a group',
                containerCssClass: 'select2-plain select2-block title',
                dropdownCssClass: 'group-select-popover popover select2-drop-wider',
                minimumInputLength: 1,
                formatNoMatches: function() {
                    return 'No available Groups';
                }
            }
        });
        this.listenTo(groupSelect, 'change', function (data) {
            this.showListView(data.item)
        });

        if (value) {
            this.showListView(value.toJSON());
        }
    },
    showListView: function(data) {
        if (!this.listRegion) {
            return;
        }

        var listView = this.createListView(data);
        if (!listView) {
            this.listRegion.reset();
            return;
        }

        this.listRegion.show(listView);
    },
    createListView: function(data) {
        var list,
            elementType = data.element_type;
        if (elementType === 'individuals') {
            list = new PaginatedListView({
                id: 'individuals-list',
                listItemView: IndividualItemView,
                listItemViewOptions: {
                    section: 'Contacts',
                    url: 'individuals',
                    tools: {}
                },
                collection: new IndividualsCollection(),
                fetchOptions: {
                    data: {
                        by_group_id: data.id
                    }
                }
            });
            this.listenTo(list, 'showItem', function(item) {
                this.trigger('push-view:individual:show', { model: item });
            });
        }
        else if (elementType === 'organizations') {
            list = new PaginatedListView({
                id: 'organizations-list',
                listItemView: OrganizationItemView,
                listItemViewOptions: {
                    section: 'Organization',
                    url: 'organization',
                    tools: {}
                },
                collection: new OrganizationsCollection(),
                fetchOptions: {
                    data: {
                        by_group_id: data.id
                    }
                }
            });
            this.listenTo(list, 'showItem', function(item) {
                this.trigger('push-view:organization:show', { model: item });
            });
        }
        else if (elementType === 'opportunities') {
            list = new PaginatedListView({
                id: 'opportunities-list',
                listItemView: OpportunityItemView,
                listItemViewOptions: {
                    section: 'Opportunity',
                    url: 'deal',
                    tools: {}
                },
                collection: new OpportunitiesCollection(),
                fetchOptions: {
                    data: {
                        by_group_id: data.id
                    }
                }
            });
            this.listenTo(list, 'showItem', function(item) {
                this.trigger('push-view:deal:show', { model: item });
            });
        }
        return list;
    }
});

export default GroupSelectorView;

import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'

import FilterQuickItems from 'js/views/filters/quick-items.js'
import FilterQuickOptionsBase from 'js/views/filters/quick-options-base.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import Currency from 'js/utils/currency.js'


var FilterModel = FilterQuickOptionsBase.FilterModel,
    FilterCollection = FilterQuickOptionsBase.FilterCollection,
    createAsyncItems = FilterQuickOptionsBase.createAsyncItems,
    createSearchableItems = FilterQuickOptionsBase.createSearchableItems,
    createGlobalSearchableItems = FilterQuickOptionsBase.createGlobalSearchableItems,
    createPhaseItems = FilterQuickOptionsBase.createPhaseItems;

export default function() {
    var options = [
        {
            view: FilterQuickItems.ButtonView,
            name: function() {
                return 'Search for "' + this.get('search') + '"';
            },
            nameHtml: true,
            hidden: function() {
                return !(this.get('search') && this.get('search').length);
            },
            icon: 'icon-search',
            rules: function() {
                return [{
                    field: 'opportunity_name',
                    operator: 'contains',
                    values: this.get('search')
                }];
            }
        },
        {
            view: FilterQuickItems.SeperatorView,
            hidden: function() {
                return !this.get('search') || !this.get('search').length;
            }
        },
        createGlobalSearchableItems(),
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'quick filters',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'New this week',
                    icon: 'icon-plus',
                    rules: [{
                        field: 'opportunity_created',
                        operator: 'within_last',
                        values: {
                            unit: 'w',
                            value: 1
                        }
                    }]
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Close date',
                    icon: 'icon-calendar',
                    click: false,
                    items: new FilterCollection([
                        {
                            view: FilterQuickItems.GroupView,
                            inline: true,
                            icon: 'icon-calendar',
                            items: new FilterCollection([
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'Today',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'opportunity_closed_date',
                                        operator: 'during',
                                        values: 'day'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This week',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'opportunity_closed_date',
                                        operator: 'during',
                                        values: 'week'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This month',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'opportunity_closed_date',
                                        operator: 'during',
                                        values: 'month'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This quarter',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'opportunity_closed_date',
                                        operator: 'during',
                                        values: 'quarter'
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: 'This year',
                                    icon: 'icon-calendar',
                                    rules: [{
                                        field: 'opportunity_closed_date',
                                        operator: 'during',
                                        values: 'year'
                                    }]
                                }
                            ])
                        },
                        {
                            view: FilterQuickItems.GroupView,
                            name: 'Date range...',
                            icon: 'icon-cog2',
                            hidden: false,
                            click: function(ev, view) {
                                view.root.showAdvanced(new Backbone.Model({
                                    items: new Backbone.Collection([{
                                        field: 'opportunity_closed_date',
                                        operator: 'between'
                                    }])
                                }));
                            }
                        }
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.getText('ID_DEAL_VALUE'),
                    icon: 'icon-stats',
                    click: false,
                    items: new FilterCollection([
                        {
                            view: FilterQuickItems.GroupView,
                            inline: true,
                            items: new FilterCollection([
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: function() {
                                        var currency = app.user.get('client').default_currency;
                                        return Currency.shortFormat(currency, 0) + ' - ' +
                                               Currency.shortFormat(currency, 1000);
                                    },
                                    icon: 'icon-stats',
                                    rules: [{
                                        field: 'opportunity_buckets_total',
                                        operator: 'less_than_equal',
                                        values: 1000
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: function() {
                                        var currency = app.user.get('client').default_currency;
                                        return Currency.shortFormat(currency, 1000) + ' - ' +
                                               Currency.shortFormat(currency, 10000);
                                    },
                                    icon: 'icon-stats',
                                    rules: [{
                                        field: 'opportunity_buckets_total',
                                        operator: 'between',
                                        values: {
                                            start: 1000,
                                            end: 10000
                                        }
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: function() {
                                        var currency = app.user.get('client').default_currency;
                                        return Currency.shortFormat(currency, 10000) + ' - ' +
                                               Currency.shortFormat(currency, 100000);
                                    },
                                    icon: 'icon-stats',
                                    rules: [{
                                        field: 'opportunity_buckets_total',
                                        operator: 'between',
                                        values: {
                                            start: 10000,
                                            end: 100000
                                        }
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: function() {
                                        var currency = app.user.get('client').default_currency;
                                        return Currency.shortFormat(currency, 100000) + ' - ' +
                                               Currency.shortFormat(currency, 1000000);
                                    },
                                    icon: 'icon-stats',
                                    rules: [{
                                        field: 'opportunity_buckets_total',
                                        operator: 'between',
                                        values: {
                                            start: 100000,
                                            end: 1000000
                                        }
                                    }]
                                },
                                {
                                    view: FilterQuickItems.ButtonView,
                                    name: function() {
                                        var currency = app.user.get('client').default_currency;
                                        return Currency.shortFormat(currency, 1000000) + '+';
                                    },
                                    icon: 'icon-stats',
                                    rules: [{
                                        field: 'opportunity_buckets_total',
                                        operator: 'more_than_equal',
                                        values: 1000000
                                    }]
                                }
                            ])
                        },
                        {
                            view: FilterQuickItems.GroupView,
                            name: 'Value range...',
                            icon: 'icon-cog2',
                            hidden: false,
                            click: function(ev, view) {
                                view.root.showAdvanced(new Backbone.Model({
                                    items: new Backbone.Collection([{
                                        field: 'opportunity_buckets_total'
                                    }])
                                }));
                            }
                        }
                    ])
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'Filter By',
            items: new FilterCollection([
                createPhaseItems(),
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Owner',
                    icon: 'icon-locked',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/users',
                            'name',
                            'icon-locked',
                            'opportunity_owner_id'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                    icon: 'icon-user',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/individuals', 'full_name', function(item) {
                            return item.organization_name;
                        })
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                    icon: 'icon-home',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/organizations', 'name')
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Group',
                    icon: 'icon-list',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/groups', 'name')
                    ])
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            name: 'All filters',
            icon: 'icon-filters-add',
            hidden: false,
            click: function(ev, view) {
                view.root.showAdvanced();
            }
        }
    ];

    var cfQuickFilters = app.globalData.customFieldsQuickFilters.opportunities;
    var cfItems = new FilterCollection(cfQuickFilters.map(cf => {
        return {
            view: FilterQuickItems.AngleButtonView,
            name: cf.name,
            icon: 'icon-funnel',
            click: function(ev, view) {
                view.root.showAdvanced(new Backbone.Model({
                    items: new Backbone.Collection([{
                        field: 'opportunity_custom',
                        custom: cf.id
                    }])
                }));
            }
        };
    }));

    if (cfItems.length === 0) {
        cfItems.add(new FilterModel({
            view: FilterQuickItems.EmptyGroupView
        }));
    }

    var customFilters = [{
        view: FilterQuickItems.GroupView,
        inline: true,
        name: 'custom filter by',
        items: cfItems
    }];

    return new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        items: new FilterCollection(options),
        customFilters: new FilterCollection(customFilters)
    });
};

import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import {GroupSelectItem, NewSelect} from 'js/react_views/widgets/select';
import MultiSelect from 'js/react_views/widgets/multiselect';
import classnames from 'classnames';
import Currency from 'js/utils/currency'
import PhasesCollection from 'js/collections/phases'
import CustomFieldsCollection from 'js/collections/custom_fields'
import TagsCollection from 'js/collections/tags';
import TextManager from 'app/text-manager'
import styles from './automation.css';
import Popover from 'js/react_views/popover/popover';
import app from 'js/app'

import dateFormat from 'js/utils/date-format'

let phasesList = [];
let phaseDDRef = null;

const intervalList = ['years', 'months', 'days']; //'hours', 'minutes', 'seconds'];

const containerStyle = {
    position: "relative",
    width: "250px",
    display: "inline-block",
    height: "32px",
    lineHeight: "32px",
    verticalAlign: "middle"
};

const boxStyle = {
    background: null,
    borderWidth: 0,
    paddingLeft: 0
};

const dropDownStyle = {
    left: 0,
    zIndex: 1
};

const textFactory = (id, title, key, value) => (self) => {
    const errorIds = [id, "action_config." + id, "action_config." + id + ".value"];

    return <div key={key}>
        <label htmlFor={key} >{title}</label>
        <textarea
            className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: errorIds.indexOf(self.props.errorField) !== -1 })}
            placeholder={title}
            autoComplete="off"
            name={key}
            id={key}
            value={self.state && self.state[key] || value || ""}
            onChange={ev => { self.handleInputChange(ev) }}
        /><br />
    </div>;
};

const inputFactory = (id, title, key, value) => (self) => {
    const errorIds = [id, "action_config." + id, "action_config." + id + ".value"];

    return <div key={key}>
        <label htmlFor={key}>{title}</label>
        <input
            type="text"
            className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: errorIds.indexOf(self.props.errorField) !== -1 })}
            placeholder={title}
            autoComplete="off"
            name={key}
            id={key}
            value={self.state && self.state[key] || value || ""}
            onChange={ev => { self.handleInputChange(ev) }}
        /><br />
    </div>;
};

const numberFactory = (title, key, value, minValue, maxValue) => (self) => {
    let val = "";

    if (self.state && (self.state[key] || self.state[key] === 0)) {
        val = self.state[key];
    }
    else if (value || value === 0) {
        val = value;
    }

    return <div key={key}>
        <label htmlFor={key}>{title}</label>
        <input
            type="number"
            className={styles.attribute}
            placeholder={title}
            autoComplete="off"
            name={key}
            id={key}
            value={val}
            onChange={ev => { self.handleInputChange(ev, minValue, maxValue) }}
        /><br />
    </div>;
};

const checkBoxFactory = (title, key, value) => (self) =>
    <div key={key}>
        <label htmlFor={key} className={styles.checkBoxLabel}>{title}
            <div className={styles.checkBoxContainer}>
                <input
                    type="checkbox"
                    name={key}
                    id={key}
                    defaultChecked={self.state && self.state[key] || value || false}
                    onChange={ev => { self.handleInputChange(ev) }}
                />
                <div className={styles.checkBox}>
                    <div className={styles.slider}></div>
                </div>
            </div>
        </label>
    </div>;

const dateFactory = (title, key, value) => (self) =>
    <div key={key}>
        <label htmlFor="datetime">{title}</label>
        <input
            className={styles.attribute}
            placeholder="Date..."
            autoComplete="off"
            name={key}
            id={key}
            value={self.state && self.state[key] || value || ""}
            onClick={ev => {
                self.handleDateClick(ev)
            }}
            style={{ cursor: "auto" }}
            readOnly={true} // readonly because react complains missing onChange
        /><br />
    </div>;



const dateFactoryCustomField = (title, key, value) => (self) =>
    <DateTimeSelectView
        label={title}
        prefix={"relative_date_cf_"+key}
        key={key}
        id={key}
        value={value}
        data={self.state || value || {}}
        errorField={self.props.errorField}
        handleSelectClick={self.handleSelectClickChangeState}
        handleDateTimeClick={self.handleCustomFieldDateClick}
        handleInputChange={self.handleCustomFieldDateInputChange}
    />;


class DateTimeSelectView extends React.Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(ev) {
        this.props.handleInputChange(ev);
    }

    render() {
        const timeSpan = [
            { id: "years", title: "Years after trigger" },
            { id: "months", title: "Months after trigger" },
            { id: "days", title: "Days after trigger" },
            // { id: "hours", title: "Hours after trigger" },
            // { id: "minutes", title: "Minutes after trigger" },
            // { id: "seconds", title: "Seconds after trigger" },
            { id: "monday", title: "Monday" },
            { id: "tuesday", title: "Tuesday" },
            { id: "wednesday", title: "Wednesday" },
            { id: "thursday", title: "Thursday" },
            { id: "friday", title: "Friday" },
            { id: "saturday", title: "Saturday" },
            { id: "sunday", title: "Sunday" }
        ];

        const data = [{
            id: "datetime",
            title: "Specific Date"
        }, {
            id: "relative_datetime",
            title: "Relative Date"
        }];

        const prefix = this.props.prefix;
        const date = this.props.data[prefix + "_date"];
        const date_type = this.props.data[prefix + "_date_type"];
        const relative_datetime_type = this.props.data[prefix + "_relative_datetime_type"];
        const relative_datetime_value = this.props.data[prefix + "_relative_datetime_value"];
        const skip_working_days = this.props.data[prefix + "_skip_working_days"];

        const value = _.findWhere(data, {id: date_type});
        const label = this.props.label || "Due Date";

        return (
            <div key={this.props.id}>
                <label htmlFor="datetime">{label}</label>
                <NewSelect
                    className={classnames({
                        [styles.attribute]: true,
                        [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf(this.props.id) >= 0))
                    })}
                    value={value}
                    onSelect={(items) => { this.props.handleSelectClick(prefix + "_date_type", items); }}
                    data={data}
                    width={250}
                    containerStyle={containerStyle}
                    boxStyle={boxStyle}
                    dropDownStyle={dropDownStyle}
                /><br />
                {
                    date_type === "datetime" &&
                    <span>
                        <label></label>
                        <input
                            className={classnames({
                                [styles.attribute]: true,
                                [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf(this.props.id) >= 0))
                            })}
                            placeholder="Date..."
                            autoComplete="off"
                            name={prefix + "_date"}
                            id={prefix + "_date"}
                            value={date || ""}
                            onClick={ev => { this.props.handleDateTimeClick(ev) }}
                            style={{ cursor: "auto" }}
                            readOnly={true /* readonly because react complains missing onChange */}
                        /><br />
                    </span>
                }
                {
                    date_type === "relative_datetime" &&
                    <span>
                        <label></label>
                        <input
                            type="number"
                            className={classnames({
                                [styles.attribute]: true,
                                [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf(this.props.id) >= 0))
                            })}
                            placeholder="0"
                            name={prefix + "_relative_datetime_value"}
                            id={prefix + "_relative_datetime_value"}
                            value={relative_datetime_value || "0"}
                            onChange={ev => { this.props.handleInputChange(ev) }}
                            style={{ cursor: "auto", width: "60px", padding: "0", textAlign: "center" }}
                        />
                        <NewSelect
                            className={classnames({
                                [styles.attribute]: true,
                                [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf(this.props.id) >= 0))
                            })}
                            value={{
                                id: relative_datetime_type,
                                title: relative_datetime_type ? _.find(timeSpan, (item) => { return relative_datetime_type === item.id; }).title : null
                            }}
                            onSelect={(items) => { this.props.handleSelectClick(prefix + "_relative_datetime_type", items); }}
                            placeholder="Select an option"
                            width={185}
                            data={timeSpan}
                            containerStyle={_.extend({}, containerStyle, { width: "185px" })}
                            boxStyle={boxStyle}
                            dropDownStyle={dropDownStyle}
                        /><br />
                    </span>
                }
                {
                    date_type === "relative_datetime" &&
                    <span>
                        {checkBoxFactory('Ignore Assignee Holidays', prefix + "_skip_working_days", skip_working_days || false)(this)}
                    </span>
                }
            </div>
        )
    }
}

const DDRelatedType = (title, id, key, value, options, onSelect) => (self) => {
    options = options || {};

    const data = [
        {
            id: 'custom_field',
            title: 'Custom Field'
        }
    ];
    let relatedType = value && value.id;
    if (relatedType == 'custom_field') {
        if (self.state.custom_field) {
            relatedType = self.state.custom_field.type;
        } else {
            relatedType = null;
        }
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value && _.find(data, (item) => item.id === value.id)}
                text="title"
                onSelect={items => onSelect(key, items)}
                placeholder="Select related type"
                data={data}
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
            {value && value.id == 'custom_field'
                ? manageDropDown("Custom Field", "custom_field", DDCustomFields, {
                    search_parameters: {
                        view: self.props.entityType,
                        type: JSON.stringify(['individual', 'organization', 'opportunity'])
                    },
                })(self)
                : <div></div>
            }
            {relatedType
                ? {
                    individual: selectorFactoryMap.update_individual(self),
                    organization: selectorFactoryMap.update_organization(self),
                    opportunity: selectorFactoryMap.update_opportunity(self),
                }[relatedType]
                : <div></div>
            }
        </div>
    );
}

class CreateUpdateItemView extends React.Component {
    constructor(props) {
        super(props);

        this.availableItems = this.getFieldElements();
        this.itemsIdSelected = [];

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleDateTimeClick = this.handleDateTimeClick.bind(this);

        this.handleSelectClickChangeState = this.handleSelectClickChangeState.bind(this);
        this.handleCustomFieldDateClick = this.handleCustomFieldDateClick.bind(this);
        this.handleCustomFieldDateInputChange = this.handleCustomFieldDateInputChange.bind(this)

    }

    getFieldElements() {
        let fieldMap = {};
        this.props.createUpdateFields.forEach(field => {
            if (field.db_id) {
                fieldMap[field.db_id] = true;
            }
            else {
                fieldMap[field.id] = true;
            }
        });
        let list = [];
        this.props.createUpdateFieldsAll.forEach(field => {
            if (!(field.id in fieldMap || field.db_id in fieldMap)) {
                list.push(field);
            }
        });
        return list;
    }

    getAvailableItems() {
        this.availableItems = _.reject(this.props.createUpdateFieldsAll, (item) => { return _.contains(this.itemsIdSelected, item.id); });
        return this.availableItems;
    }

    handleNewFieldClick(items) {
        this.props.handleNewFieldClick(items);

        if (items) {
            this.itemsIdSelected.push(items[0].id);
            this.selector.setData(this.getAvailableItems());
        }
    }

    handleRemoveFieldClick(id) {
        this.props.handleRemoveFieldClick(id);

        if (id) {
            this.itemsIdSelected = _.without(this.itemsIdSelected, id);
            this.selector.setData(this.getAvailableItems());
        }
    }

    handleInputChange(ev, minValue, maxValue) {
        this.props.handleInputChange(ev, minValue, maxValue);
    }

    handleSelectClick(field, key, items) {
        if (items) {
            field.value_id = items[0].id;
            field.value_title = items[0].name;
        } else {
            field.value_id = null;
            field.value_title = null;
        }

        if (field.isDropDown) {
            field.value = {
                id: field.value_id,
                name: field.value_title
            };
        }

        this.props.handleSelectClick(key, items);
    }

    handleSelectClickChangeState(id, items) {
        this.props.handleSelectClickChangeState(id, items);
    }

    handleDateClick(ev) {
        this.props.handleDateClick(ev);
    }

    handleDateTimeClick(ev) {
        this.props.handleDateTimeClick(ev);
    }

    handleCustomFieldDateClick(ev) {
        this.props.handleCustomFieldDateClick(ev);
    }

    handleCustomFieldDateInputChange(ev) {
        this.props.handleCustomFieldDateInputChange(ev);
    }

    render() {
        const self = this;

        const handleDDSelectClick = (fieldId, items) => {
            self.handleSelectClick(this, fieldId, items);
        };

        return (
            <div>
                <label className={styles.fieldCountContainer}>New Field<span className={styles.count}>{this.props.createUpdateFields.length}</span></label>
                <NewSelect
                    className={styles.attribute}
                    text="name"
                    ref={(el) => this.selector = el}
                    onSelect={items => { this.handleNewFieldClick(items); }}
                    placeholder="Select field here"
                    data={this.availableItems}
                    containerStyle={containerStyle}
                    width={250}
                    boxStyle={boxStyle}
                    dropDownStyle={dropDownStyle}
                /><br />
                {
                    _.map(this.props.createUpdateFields, field => {
                        const fieldKey = "createUpdate_" + field.id;

                        // create a 'value' field for dropdowns
                        if (_.contains(['user', 'source', 'organization', 'individual', 'opportunity', 'currency', 'phase', 'dropDown'], field.type)) {
                            field.isDropDown = true;

                            field.value = {
                                id: field.value_id,
                                name: field.value_title
                            };
                        }

                        const fieldAllInfo = this.props.createUpdateFieldsAll.find(fieldAll => fieldAll.id === field.id);

                        return (
                            <div key={field.id} className={styles.createUpdateWrapper}>
                                {(field.type === "text") && inputFactory(field.id, field.name, fieldKey, field.value)(this)}
                                {(field.type === "user") && DDUsers(field.name, field.id, fieldKey, field.value, null, handleDDSelectClick.bind(field))(this)}
                                {(field.type === "source") && DDSources(field.name, field.id, fieldKey, field.value, handleDDSelectClick.bind(field))(this)}
                                {(field.type === "organization") && DDIndOrgDeals(field.name, field.id, fieldKey, field.value, "/organizations", handleDDSelectClick.bind(field))(this)}
                                {(field.type === "individual") && DDIndOrgDeals(field.name, field.id, fieldKey, field.value, "/individuals", handleDDSelectClick.bind(field))(this)}
                                {(field.type === "opportunity") && DDIndOrgDeals(field.name, field.id, fieldKey, field.value, "/opportunities", handleDDSelectClick.bind(field))(this)}
                                {(field.type === "currency") && DDCurrencies(field.name, field.id, fieldKey, field.value, handleDDSelectClick.bind(field))(this)}
                                {(field.type === "phase") && DDPhases(field.name, field.id, fieldKey, field.value, handleDDSelectClick.bind(field))(this)}
                                {(field.type === "checkbox") && checkBoxFactory(field.name, fieldKey, field.value)(this)}
                                {(field.type === "date" && !fieldAllInfo.customField) && dateFactory(field.name, fieldKey, field.value)(this)}
                                {(field.type === "date" && fieldAllInfo.customField) && dateFactoryCustomField(field.name, fieldKey, fieldAllInfo.data)(this)}
                                {(field.type === "number") && numberFactory(field.name, fieldKey, field.value)(this)}
                                {(field.type === "dropDown") && DDCustomField(field.name, field.id, fieldKey, field.options, field.value, handleDDSelectClick.bind(field))(this)}
                                {(field.type === "paragraph") && textFactory(field.id, field.name, fieldKey, field.value)(this)}
                                {(field.type === "url") && inputFactory(field.id, field.name, fieldKey, field.value)(this)}
                                {(field.type === "urlImage") && inputFactory(field.id, field.name, fieldKey, field.value)(this)}
                                {(field.type === "tags") && MultiSelectFactory(field.name, fieldKey, "/tags", field.values)(this)}
                                {(field.type === "weight") && numberFactory(field.name, fieldKey, field.value, 0, 1)(this)}

                                <icon className={"icon-delete " + styles.removeIcon} onClick={() => this.handleRemoveFieldClick(field.id)}/>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

const DDCustomField = (title, id, key, data, value, onSelect) => (self) => {
    if (value) {
        value.value = value.name;
    }

    let text = "value"

    if (data.length > 0 && 'name' in data[0]){
        text = "name"
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                data={data}
                text={text}
                onSelect={items => onSelect(key, items)}
                placeholder="Select an option"
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDCustomFields = (title, id, key, value, options, onSelect) => (self) => {
    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a custom field"
                url="/custom_fields"
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDSources = (title, id, key, value, onSelect) => (self) => {
    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a source"
                url="/lead_sources"
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDIndOrgDeals = (title, id, key, value, url, onSelect) => (self) => {
    if (value) {
        value.title = value.name;
    }

    const placeholder = {
        '/individuals': TextManager.getText('ID_SELECT_AN_INDIVIDUAL'),
        '/organizations': TextManager.getText('ID_SELECT_AN_ORGANIZATION}'),
        '/opportunities': TextManager.getText('ID_SELECT_A_DEAL')
    }[url];

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="title"
                onSelect={items => onSelect(key, items)}
                placeholder={placeholder}
                url={url}
                options={{minimumInputLength: 1}}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDUsers = (title, id, key, value, options, onSelect) => (self) => {
    if (value) {
        value.title = value.name;
    }

    var composeUsersList = function(callback) {
        $.getJSON('/users', function(data) {
            var users = [];

            if (options && options.addOwnerOfTheTriggerOption) {
                users = [{
                    id: 'owner-of-the-trigger-record',
                    title: 'Owner of the trigger record'
                }, {
                    id: 'separator'
                }];
            }

            _.forEach(data, function(user) {
                users.push({
                    id: user.id,
                    title: user.name
                });
            });

            callback(users);
        });
    };

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                data={composeUsersList}
                value={value}
                onSelect={items => onSelect(key, items)}
                placeholder="Select a user"
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDCampaigns = (title, id, key, value, onSelect) => (self) => {
    let searchParameters = {
        status: 'ready'
    };

    if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5741')) { // new campaigns section
        searchParameters.campaign_subtype = 'automation';
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder={TextManager.getText('ID_SELECT_A_CAMPAIGN')}
                url="/campaigns"
                options={{ search_parameters: searchParameters }}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDAutomations = (title, id, key, value, options, onSelect) => (self) => {
    options = options || {};

    if (!('minimumInputLength' in options)) {
        options.minimumInputLength = 1;
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder="Select an automation"
                url="/automations2"
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDCampaignRecipient = (title, id, key, value, options, onSelect) => (self) => {
    options = options || {};

    const composeDataList = function(callback) {
        let collection = new CustomFieldsCollection();

        collection.fetch({
            filterBy: [{
                attribute: 'view',
                value: options.element_type
            }],
            complete: function() {
                let data = [];
                let activeItemId = null;

                if (options.element_type === 'opportunities') {
                    data.push({
                        id: 'opportunity_related_contacts',
                        name: 'All Related ' + TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
                    });
                } else if (options.element_type === 'individuals') {
                    data = [{
                        id: 'send-to-this-individual',
                        name: TextManager.getText('ID_SEND_TO_THIS_INDIVIDUAL')
                    }, {
                        id: 'separator'
                    }];

                    if (!value) {
                        activeItemId = 'send-to-this-individual';
                    }
                }

                _.forEach(collection.models, function(model) {
                    if (model.get('type') === 'individual') {
                        data.push({
                            id: model.get('id'),
                            name: model.get('name')
                        });
                    }
                });

                callback(data, activeItemId);
            }
        });
    };

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                data={composeDataList}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder="Select recipient"
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br/>
        </div>
    );
}

const DDGroups = (title, id, key, value, options, onSelect) => (self) => {
    if (value) {
        value.title = value.name;
    }

    options = options || {};

    if (!('minimumInputLength' in options)) {
        options.minimumInputLength = 1;
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="title"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a group"
                url="/groups"
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDChecklists = (title, id, key, value, options, onSelect) => (self) => {
    options = options || {};

    if (!('minimumInputLength' in options)) {
        options.minimumInputLength = 1;
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="name"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a checklist"
                url="/checklists"
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDChecklistItems = (title, id, key, value, options, onSelect) => (self) => {
    options = options || {};

    const checklist_item_id = self.state.checklist_id;
    if (!checklist_item_id) return <div key={key}></div>;

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                value={value}
                text="text"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a checklist item"
                url={`/checklists/${checklist_item_id}/items`}
                options={options}
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDCurrencies = (title, id, key, value, onSelect) => (self) => {
    if (value) {
        value.text = value.name;
    }

    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                data={Currency.getUsedCurrenciesToSelect2Array()}
                value={value}
                text="text"
                onSelect={items => onSelect(key, items)}
                placeholder="Select a currency"
                width={250}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const DDPhases = (title, id, key, value, onSelect) => (self) => {
    return (
        <div key={key}>
            <label>{title}</label>
            <NewSelect
                ref={(el) => phaseDDRef = el}
                className={classnames({
                    [styles.attribute]: true,
                    [styles.errorAttribute]: (self.props.errorField && (self.props.errorField.indexOf(id) >= 0))
                })}
                data={phasesList}
                value={value}
                text={(value) => {
                    if (value.funnel) {
                        return `${value.funnel.name}: ${value.name}`;
                    }
                    return value.name;
                }}
                onSelect={(items) => onSelect(key, items)}
                placeholder={TextManager.getText('ID_SELECT_A_PHASE')}
                width={250}
                options={{formatResult: phasesFormatter}}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>
    );
}

const MultiSelectFactory = (title, key, url, values) => (self) => {
    let addMultiSelectItem = self.props.addMultiSelectItem || self.addMultiSelectItem;
    let removeMultiSelectItem = self.props.removeMultiSelectItem || self.removeMultiSelectItem;

    return <div key={key}>
        <label>{title}</label>
        <div className={styles.attribute} style={{ height: 'auto' }}>
            <MultiSelect
                items={self.state && self.state[key].values || values}
                selectItem={GroupSelectItem}
                onSearch={(search, done) => {
                    self.props.DDSearch(
                        url,
                        {},
                        search,
                        done
                    );
                }}
                onAdd={item => addMultiSelectItem(key, item)}
                onRemove={item => removeMultiSelectItem(key, item)}
                //onItemClick={this.props.onGroupClick}
                itemCustomCSS={{ background: '#addcff', color: 'white' }}
            />
        </div>
    </div>
};

export class EntityUpdatedEditRuleView extends React.Component {
    onRuleUpdate(rule) {
        this.props.update(rule);
    }

    render() {
        const { fields, rule } = this.props;

        const field = fields.find(field => field.id === rule.field);

        return <div className={styles.AutomationNodeCreateEditView}>
            <div className={styles.attributeContainer} style={{marginTop: 25}}>
                <label className={styles.fieldCountContainer}>Field</label>
                <NewSelect
                    className={styles.attribute}
                    text="name"
                    onSelect={(items) => this.onRuleUpdate({
                        type: 'value',
                        field: items[0] && items[0].id,
                    })}
                    placeholder="Select field here"
                    value={field}
                    data={fields}
                    containerStyle={containerStyle}
                    width={250}
                    boxStyle={boxStyle}
                    dropDownStyle={dropDownStyle}
                />
                <br/>
                {field && field.view && <field.view
                    field={field}
                    rule={rule}
                    onRuleUpdate={(rule) => this.onRuleUpdate(_.extend({}, rule, {
                        type: 'value',
                        field: field.id,
                    }))}
                />}
            </div>
        </div>
    }
}export class FilterItem extends React.Component {
    render() {
        const items = [];
        let key = 0;

        _.each(this.props.rule, part => {
            var field_id = part.field;
            var custom_id = part.custom;
            var operator_id = part.operator;
            var value = part.values;
            var not = part.not;

            var field = _.find(this.props.filterFields, function(item) {
                var info = item.id.split('#');
                return info[0] === field_id && info[1] === custom_id;
            });

            var operators = field && field.operators || [];
            var operator = _.find(operators, function(item) {
                return item.id === operator_id;
            });
            if (operator && operator.text) {
                value = operator.text(value);
            }

            var field_value;
            if (field) {
                if (!field.group || field.name.indexOf(field.group) !== -1 || field.group.indexOf(field.name) !== -1 || field.group === this.groupType) {
                    field_value = field.name;
                }
                else {
                    field_value = field.group + ' ' + field.name;
                }

                if (field.value_def && field.value_def.options) {
                    for (var i = 0; i < field.value_def.options.length; ++i) {
                        if (field.value_def.options[i].id === value) {
                            value = field.value_def.options[i].value;
                            break;
                        }
                    }
                }
            }
            else {
                field_value = field_id;
            }

            var text = [
                field_value,
                operator && operator.name || operator_id,
                value
            ].join(' ');

            key = key + 1;
            items.push(
                <span key={key} className={styles.subItem}>
                    {text}
                    {
                        not &&
                        <i className={classnames({ "icon-blocked": true, [styles.iconBlocked]: true })}></i>
                    }
                    {
                        !not &&
                        <i className={classnames({ "icon-checkmark3": true, [styles.iconCheckmark3]: true })}></i>
                    }
                </span>
            );
        });

        return (
            <div className={styles.FilterItem}>
                <span className={styles.itemContainer}>{items}</span>
                <i className={classnames({ "icon-pencil": true, [styles.iconPencil]: true })} onClick={this.props.edit}></i>
                <i className={classnames({ "icon-cross": true, [styles.iconCross]: true })} onClick={this.props.remove}></i>
            </div>
        )
    }
}

const phasesFormatter = (item) => {
    if (item.funnel) {
        return `<strong>${item.funnel.name}:</strong> ${item.name}`;
    }

    return item.name;
};

const manageDropDown = (title, varName, constructor, options, text) => (self) =>{
    let value = null;
    text = text || 'name';
    const id = varName + '_id';

    if (self.state[varName]) {
        value = {
            id: self.state[varName].id,
            [text]: self.state[varName][text]
        }
    }

    const handler = (key, items) => {
        var newValue = items.length > 0 ? items[0] : null;
        var newState = {};

        newState[id] = newValue ? newValue.id : null;
        newState[varName] = newValue ? newValue : null;

        self.setState(newState);
    };

    if (options) {
        return constructor(title, id, id, value, options, handler)(self);
    }

    return constructor(title, id, id, value, handler)(self);
};

let selectorFactoryMap = {
    type: (self) => {return manageDropDown("Related Type", "type", DDRelatedType, { element_type: self.props.entityType })(self)},
    event_type: (self) =>
        <div key="event_type">
            <label>Event Type</label>
            <NewSelect
                className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: self.props.errorField === "event_type" })}
                value={stepTypeMap[self.state.step_type]['subTypeMapByEntity'][self.props.entityType][self.state.event_type]}
                onSelect={items => { self.handleSelectClick("event_type", items); }}
                placeholder="Event Type"
                width={250}
                data={_.map(stepTypeMap[self.state.step_type]['subTypeMapByEntity'][self.props.entityType], item => item)}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>,
    group_id: (self) => {return manageDropDown("Group", "group", DDGroups, { search_parameters: { element_type: self.props.entityType } })(self)},
    static_group_id: (self) => {return manageDropDown("Group", "group", DDGroups, { search_parameters: { element_type: self.props.entityType, group_type: "static" }})(self)},
    campaign_id: manageDropDown(TextManager.parseText('${ID_CAMPAIGN, capitalize}'), "campaign", DDCampaigns),
    recipient_cf_id: (self) => {return manageDropDown("Recipient", "recipient_cf", DDCampaignRecipient, { element_type: self.props.entityType })(self)},
    checklist_id: (self) => {return manageDropDown("Checklist", "checklist", DDChecklists, { search_parameters: { entity_types: self.props.entityType, states: 'published' } })(self)},
    checklist_item_id: (self) => {return manageDropDown("Checklist Item", 'checklist_item', DDChecklistItems, {}, 'text')(self)},
    url: (self) =>
        <div key="url">
            <label htmlFor="url">Url</label>
            <input
                type="url"
                className={styles.attribute}
                placeholder="Url"
                autoComplete="off"
                name="url"
                id="url"
                value={self.state.url || ""}
                onChange={ev => { self.handleInputChange(ev) }}
            /><br />
        </div>,
    action_type: (self) =>
        <div key="action_type">
            <label>Action Type</label>
            <NewSelect
                className={styles.attribute}
                value={stepTypeMap[self.state.step_type]['subTypeMapByEntity'][self.props.entityType][self.state.action_type]}
                onSelect={items => { self.handleSelectClick("action_type", items); }}
                placeholder="Action Type"
                width={250}
                data={_.map(stepTypeMap[self.state.step_type]['subTypeMapByEntity'][self.props.entityType], item => item)}
                containerStyle={containerStyle}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            /><br />
        </div>,
    note: textFactory("note", "Note", "note"),
    target_date: (self) => <DateTimeSelectView
            prefix="target"
            key="target_date"
            id="target_date"
            data={self.state}
            errorField={self.props.errorField}
            handleSelectClick={self.handleSelectClick}
            handleDateTimeClick={self.handleDateTimeClick}
            handleInputChange={self.handleInputChange}
        />,
    tags: MultiSelectFactory("Tags", 'tags', "/tags"),
    owner_id: manageDropDown("Owner", "owner", DDUsers),
    subject: inputFactory("subject", "Subject", "subject"),
    text: textFactory("text", "Text", "text"),
    headers: textFactory("headers", "Headers", "headers"),
    post_url: inputFactory("post_url", "Url", "post_url"),
    generate_alert_activities: (self) => {
        var enabled = _.isUndefined(self.state.generate_alert_activities) ? true : self.state.generate_alert_activities;
        return checkBoxFactory("Generate Alerts", "generate_alert_activities", enabled)(self);
    },
    ignore_unsubscribed_all: (self) => {
        if ( ! app.user.get('is_helper') ) {
            return null;
        }

        var enabled = _.isUndefined(self.state.ignore_unsubscribed_all) ? false : self.state.ignore_unsubscribed_all;
        return checkBoxFactory("Ignore Email Opt In", "ignore_unsubscribed_all", enabled)(self);
    },
    body: textFactory("body", "Body", "body"),
    due_date: (self) => <DateTimeSelectView
        prefix="due"
        key="due_date"
        id="due_date"
        data={self.state}
        errorField={self.props.errorField}
        handleSelectClick={self.handleSelectClick}
        handleDateTimeClick={self.handleDateTimeClick}
        handleInputChange={self.handleInputChange}
    />,
    assignee_id: manageDropDown("Assignee", "assignee", DDUsers, { addOwnerOfTheTriggerOption: true }),
    phase_id: manageDropDown(TextManager.parseText('${ID_PHASE, capitalize}'), "phase", DDPhases),
    wait_config: (self) => <DateTimeSelectView
        prefix="wait"
        label="Wait Until"
        key="wait_config"
        id="wait_config"
        data={self.state}
        errorField={self.props.errorField}
        handleSelectClick={self.handleSelectClick}
        handleDateTimeClick={self.handleDateTimeClick}
        handleInputChange={self.handleInputChange}
    />,
    filter_id: (self) => <div key="filter">
            <label htmlFor="filters">
                <i className="icon-search"></i> Filters
                {
                    self.state.filter
                    && (_.size(self.state.filter.rules) > 0)
                    && <span className={styles.filterCount}>{_.size(self.state.filter.rules)}</span>
                }
            </label>
            <input
                className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: self.props.errorField === "filter_id" })}
                placeholder="Filter..."
                autoComplete="off"
                name="filters"
                id="filters"
                value=""
                onClick={self.handleFilterClick}
            />
            <div>
                {
                    self.state.filter &&
                    self.state.filter.rules.map((rule, ind) => {
                        return <FilterItem
                                    key={ind}
                                    rule={rule}
                                    edit={(ev) => { self.handleFilterClick(ev, rule, ind); }}
                                    remove={() => { self.removeFilterRule(ind); }}
                                    filterFields={self.props.filterFields}
                                />
                    })
                }
            </div>
        </div>,
    entity_updated: (self) => <EntityUpdatedView
        key="entityUpdatedItem"
        {...self.state}
        entityType={self.props.entityType}
        handleEntityUpdated={self.handleEntityUpdated}
    />,
    automation2_id: (self) => manageDropDown("Automation", "automation2", DDAutomations, { search_parameters: { entity_types: self.props.entityType } })(self),
    update_individual: (self) => <CreateUpdateItemView
            key="createUpdateItem"
            type="individual"
            createUpdateFieldsAll={self.props.createUpdateFieldsAll["update_individual"]}
            handleNewFieldClick={self.handleNewFieldClick}
            handleRemoveFieldClick={self.handleRemoveFieldClick}
            createUpdateFields={
                self.props.createUpdateFieldsAll["update_individual"]
                .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
                .map(field => self.state["createUpdate_" + field.id]) || []
            }
            handleInputChange={self.handleInputChangeCreateUpdate}
            handleSelectClick={self.handleSelectClickCreateUpdate}
            handleDateClick={self.handleDateClick}
            handleDateTimeClick={self.handleDateTimeClick}
            handleSelectClickChangeState={self.handleSelectClick}
            handleCustomFieldDateClick={self.handleCustomFieldDateClick}
            handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
            DDSearch={self.props.DDSearch}
            addMultiSelectItem={self.addMultiSelectItem}
            removeMultiSelectItem={self.removeMultiSelectItem}
            errorField={self.props.errorField}
        />,
    create_individual: (self) => <CreateUpdateItemView
            key="createUpdateItem"
            type="individual"
            createUpdateFieldsAll={self.props.createUpdateFieldsAll["create_individual"]}
            handleNewFieldClick={self.handleNewFieldClick}
            handleRemoveFieldClick={self.handleRemoveFieldClick}
            createUpdateFields={
                self.props.createUpdateFieldsAll["create_individual"]
                .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
                .map(field => {
                    return self.state["createUpdate_" + field.id];
                }) || []
            }
            handleInputChange={self.handleInputChangeCreateUpdate}
            handleSelectClick={self.handleSelectClickCreateUpdate}
            handleDateTimeClick={self.handleDateTimeClick}
            handleDateClick={self.handleDateClick}
            handleSelectClickChangeState={self.handleSelectClick}
            handleCustomFieldDateClick={self.handleCustomFieldDateClick}
            handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
            DDSearch={self.props.DDSearch}
            addMultiSelectItem={self.addMultiSelectItem}
            removeMultiSelectItem={self.removeMultiSelectItem}
            errorField={self.props.errorField}
        />,
    update_organization: (self) => <CreateUpdateItemView
        key="createUpdateItem"
        type="organization"
        createUpdateFieldsAll={self.props.createUpdateFieldsAll["update_organization"]}
        handleNewFieldClick={self.handleNewFieldClick}
        handleRemoveFieldClick={self.handleRemoveFieldClick}
        createUpdateFields={
            self.props.createUpdateFieldsAll["update_organization"]
            .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
            .map(field => {
                return self.state["createUpdate_" + field.id];
            }) || []
        }
        handleInputChange={self.handleInputChangeCreateUpdate}
        handleSelectClick={self.handleSelectClickCreateUpdate}
        handleDateTimeClick={self.handleDateTimeClick}
        handleDateClick={self.handleDateClick}
        handleSelectClickChangeState={self.handleSelectClick}
        handleCustomFieldDateClick={self.handleCustomFieldDateClick}
        handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
        DDSearch={self.props.DDSearch}
        addMultiSelectItem={self.addMultiSelectItem}
        removeMultiSelectItem={self.removeMultiSelectItem}
        errorField={self.props.errorField}
    />,
    create_organization: (self) => <CreateUpdateItemView
        key="createUpdateItem"
        type="organization"
        createUpdateFieldsAll={self.props.createUpdateFieldsAll["create_organization"]}
        handleNewFieldClick={self.handleNewFieldClick}
        handleRemoveFieldClick={self.handleRemoveFieldClick}
        createUpdateFields={
            self.props.createUpdateFieldsAll["create_organization"]
            .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
            .map(field => {
                return self.state["createUpdate_" + field.id];
            }) || []
        }
        handleInputChange={self.handleInputChangeCreateUpdate}
        handleSelectClick={self.handleSelectClickCreateUpdate}
        handleDateTimeClick={self.handleDateTimeClick}
        handleDateClick={self.handleDateClick}
        handleSelectClickChangeState={self.handleSelectClick}
        handleCustomFieldDateClick={self.handleCustomFieldDateClick}
        handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
        DDSearch={self.props.DDSearch}
        addMultiSelectItem={self.addMultiSelectItem}
        removeMultiSelectItem={self.removeMultiSelectItem}
        errorField={self.props.errorField}
    />,
    update_opportunity: (self) => <CreateUpdateItemView
            key="createUpdateItem"
            type="opportunity"
            createUpdateFieldsAll={self.props.createUpdateFieldsAll["update_opportunity"]}
            handleNewFieldClick={self.handleNewFieldClick}
            handleRemoveFieldClick={self.handleRemoveFieldClick}
            createUpdateFields={
                self.props.createUpdateFieldsAll["update_opportunity"]
                .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
                .map(field => {
                    return self.state["createUpdate_" + field.id];
                }) || []
            }
            handleInputChange={self.handleInputChangeCreateUpdate}
            handleSelectClick={self.handleSelectClickCreateUpdate}
            handleDateTimeClick={self.handleDateTimeClick}
            handleDateClick={self.handleDateClick}
            handleSelectClickChangeState={self.handleSelectClick}
            handleCustomFieldDateClick={self.handleCustomFieldDateClick}
            handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
            DDSearch={self.props.DDSearch}
            addMultiSelectItem={self.addMultiSelectItem}
            removeMultiSelectItem={self.removeMultiSelectItem}
            errorField={self.props.errorField}
        />,
    create_opportunity: (self) => <CreateUpdateItemView
            key="createUpdateItem"
            type="opportunity"
            createUpdateFieldsAll={self.props.createUpdateFieldsAll["create_opportunity"]}
            handleNewFieldClick={self.handleNewFieldClick}
            handleRemoveFieldClick={self.handleRemoveFieldClick}
            createUpdateFields={
                self.props.createUpdateFieldsAll["create_opportunity"]
                .filter(field => ("createUpdate_" + field.id) in self.state && !_.isNull(self.state["createUpdate_" + field.id]))
                .map(field => {
                    return self.state["createUpdate_" + field.id];
                }) || []
            }
            handleInputChange={self.handleInputChangeCreateUpdate}
            handleSelectClick={self.handleSelectClickCreateUpdate}
            handleDateTimeClick={self.handleDateTimeClick}
            handleDateClick={self.handleDateClick}
            handleSelectClickChangeState={self.handleSelectClick}
            handleCustomFieldDateClick={self.handleCustomFieldDateClick}
            handleCustomFieldDateInputChange={self.handleCustomFieldDateInputChange}
            DDSearch={self.props.DDSearch}
            addMultiSelectItem={self.addMultiSelectItem}
            removeMultiSelectItem={self.removeMultiSelectItem}
            errorField={self.props.errorField}
        />
};

const stepTypeMap = {
    //merge: { id: "merge", title: "Merge", icon: "icon-merge-down" },
    event: {
        id: "event",
        title: "Event",
        icon: "icon-event",
        selector: selectorFactoryMap["event_type"],
        subTypeKey: "event_type",
        subTypeMapByEntity: {
            individuals: {
                individual_created: {
                    id: "individual_created",
                    icon: "icon-individuals",
                    title: "Individual Created (Trigger)",
                },
                individual_updated: {
                    id: "individual_updated",
                    icon: "icon-individuals",
                    title: "Individual Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                task_created: {
                    id: "task_created",
                    icon: "icon-checkmark3",
                    title: "Task Created (Trigger)",
                    config: [{ selector: selectorFactoryMap["filter_id"] }],
                },
                task_updated: {
                    id: "task_updated",
                    icon: "icon-checkmark3",
                    title: "Task Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                checklist_completed: {
                    id: "checklist_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                checklist_item_completed: {
                    id: "checklist_item_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Item Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                        { selector: selectorFactoryMap["checklist_item_id"] },
                    ],
                },
                group_join: {
                    id: "group_join",
                    icon: "icon-filters-add",
                    title: "Group Join",
                    config: [{ selector: selectorFactoryMap["group_id"] }]
                },
                group_leave: {
                    id: "group_leave",
                    icon: "icon-filters-minus",
                    title: "Group Leave",
                    config: [{ selector: selectorFactoryMap["group_id"] }]
                },
                filter_join: {
                    id: "filter_join",
                    icon: "icon-filters-add",
                    title: "Filter Join",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                },
                filter_leave: {
                    id: "filter_leave",
                    icon: "icon-filters-minus",
                    title: "Filter Leave",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                },
                campaign_email_opened: {
                    id: "campaign_email_opened",
                    icon: "C",
                    title: TextManager.parseText('${ID_CAMPAIGN, capitalize} Email Opened'),
                    config: [{ selector: selectorFactoryMap["campaign_id"] }]
                },
                campaign_email_url_clicked: {
                    id: "campaign_email_url_clicked",
                    icon: "C",
                    title: TextManager.parseText('${ID_CAMPAIGN, capitalize} Email Url Clicked'),
                    config: [
                        { selector: selectorFactoryMap["campaign_id"] },
                        { selector: selectorFactoryMap["url"] }
                    ]
                },
                web_registration: {
                    id: "web_registration",
                    icon: "icon-globe",
                    title: "Web Registration"
                }
                //web_visit: {
                //    id: "web_visit",
                //    title: "Web Visit"
                //}
            },
            organizations: {
                organization_created: {
                    id: "organization_created",
                    icon: "icon-organizations",
                    title: "Organization Created (Trigger)",
                },
                organization_updated: {
                    id: "organization_updated",
                    icon: "icon-organizations",
                    title: "Organization Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                task_created: {
                    id: "task_created",
                    icon: "icon-checkmark3",
                    title: "Task Created (Trigger)",
                    config: [{ selector: selectorFactoryMap["filter_id"] }],
                },
                task_updated: {
                    id: "task_updated",
                    icon: "icon-checkmark3",
                    title: "Task Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                checklist_completed: {
                    id: "checklist_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                checklist_item_completed: {
                    id: "checklist_item_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Item Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                        { selector: selectorFactoryMap["checklist_item_id"] },
                    ],
                },
                group_join: {
                    id: "group_join",
                    icon: "icon-filters-add",
                    title: "Group Join",
                    config: [{ selector: selectorFactoryMap["static_group_id"] }]
                },
                group_leave: {
                    id: "group_leave",
                    icon: "icon-filters-minus",
                    title: "Group Leave",
                    config: [{ selector: selectorFactoryMap["static_group_id"] }]
                },
                filter_join: {
                    id: "filter_join",
                    icon: "icon-filters-add",
                    title: "Filter Join",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                },
                filter_leave: {
                    id: "filter_leave",
                    icon: "icon-filters-minus",
                    title: "Filter Leave",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                }
            },
            opportunities: {
                opportunity_created: {
                    id: "opportunity_created",
                    icon: "icon-funnel-sm",
                    title: "Deal Created (Trigger)",
                },
                opportunity_updated: {
                    id: "opportunity_updated",
                    icon: "icon-funnel-sm",
                    title: "Deal Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                task_created: {
                    id: "task_created",
                    icon: "icon-checkmark3",
                    title: "Task Created (Trigger)",
                    config: [{ selector: selectorFactoryMap["filter_id"] }],
                },
                task_updated: {
                    id: "task_updated",
                    icon: "icon-checkmark3",
                    title: "Task Updated (Trigger)",
                    config: [{ selector: selectorFactoryMap["entity_updated"] }],
                },
                checklist_completed: {
                    id: "checklist_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                checklist_item_completed: {
                    id: "checklist_item_completed",
                    icon: "icon-checkmark3",
                    title: "Checklist Item Completed (Trigger)",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                        { selector: selectorFactoryMap["checklist_item_id"] },
                    ],
                },
                group_join: {
                    id: "group_join",
                    icon: "icon-filters-add",
                    title: "Group Join",
                    config: [{ selector: selectorFactoryMap["group_id"] }]
                },
                group_leave: {
                    id: "group_leave",
                    icon: "icon-filters-minus",
                    title: "Group Leave",
                    config: [{ selector: selectorFactoryMap["group_id"] }]
                },
                filter_join: {
                    id: "filter_join",
                    icon: "icon-filters-add",
                    title: "Filter Join",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                },
                filter_leave: {
                    id: "filter_leave",
                    icon: "icon-filters-minus",
                    title: "Filter Leave",
                    config: [{ selector: selectorFactoryMap["filter_id"] }]
                },
                phase_entered: {
                    id: "phase_entered",
                    icon: "icon-phase-enter",
                    title: TextManager.parseText('${ID_PHASE, capitalize} Entered'),
                    config: [{ selector: selectorFactoryMap["phase_id"] }]
                },
                phase_leave: {
                    id: "phase_leave",
                    icon: "icon-phase-leave",
                    title: TextManager.parseText('${ID_PHASE, capitalize} Leave'),
                    config: [{ selector: selectorFactoryMap["phase_id"] }]
                },
                related_individual_added: {
                    id: "related_individual_added",
                    icon: "icon-individuals",
                    title: `Related ${TextManager.parseText('${ID_INDIVIDUAL, capitalize}')} Added`,
                },
                related_individual_removed: {
                    id: "related_individual_removed",
                    icon: "icon-individuals",
                    title: `Related ${TextManager.parseText('${ID_INDIVIDUAL, capitalize}')} Removed`,
                },
            }
        }
    },
    action: {
        id: "action",
        title: "Action",
        icon: "icon-action",
        selector: selectorFactoryMap["action_type"],
        subTypeKey: "action_type",
        subTypeMapByEntity: {
            individuals: {
                update_related: {
                    id: "update_related",
                    icon: "icon-checkmark3",
                    title: "Update Related",
                    config: [
                        { selector: selectorFactoryMap["type"] },
                    ],
                },
                add_checklist: {
                    id: "add_checklist",
                    icon: "icon-checkmark3",
                    title: "Add Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_checklist: {
                    id: "remove_checklist",
                    icon: "icon-checkmark3",
                    title: "Remove Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_all_checklists: {
                    id: "remove_all_checklists",
                    icon: "icon-checkmark3",
                    title: "Remove All Checklist",
                },
                create_activity: {
                    id: "create_activity",
                    icon: "icon-note",
                    title: "Create Activity",
                    config: [
                        { selector: selectorFactoryMap["note"] },
                        { selector: selectorFactoryMap["target_date"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                create_task: {
                    id: "create_task",
                    icon: "icon-checkmark3",
                    title: "Create Task",
                    config: [
                        { selector: selectorFactoryMap["subject"] },
                        { selector: selectorFactoryMap["text"] },
                        { selector: selectorFactoryMap["due_date"] },
                        { selector: selectorFactoryMap["assignee_id"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                join_group: {
                    id: "join_group",
                    icon: "icon-join-group",
                    title: "Join Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                leave_group: {
                    id: "leave_group",
                    icon: "icon-leave-group",
                    title: "Leave Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                update_individual: {
                    id: "update_individual",
                    icon: "icon-pencil",
                    title: TextManager.getText("ID_UPDATE_ENTITY", ['${ID_INDIVIDUAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["update_individual"] }
                    ]
                },
                create_individual: {
                    id: "create_individual",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_INDIVIDUAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_individual"] }
                    ]
                },
                create_organization: {
                    id: "create_organization",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_ORGANIZATION, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_organization"] }
                    ]
                },
                create_opportunity: {
                    id: "create_opportunity",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_DEAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_opportunity"] }
                    ]
                },
                send_campaign: {
                    id: "send_campaign",
                    icon: "icon-campaign",
                    title: TextManager.parseText('Send ${ID_CAMPAIGN, capitalize}'),
                    config: [
                        { selector: selectorFactoryMap["campaign_id"] },
                        { selector: selectorFactoryMap["recipient_cf_id"] },
                        { selector: selectorFactoryMap["generate_alert_activities"] },
                        { selector: selectorFactoryMap["ignore_unsubscribed_all"] }
                    ]
                },
                rest_post: {
                    id: "rest_post",
                    icon: "icon-file-css",
                    title: "REST POST",
                    config: [
                        { selector: selectorFactoryMap["post_url"] },
                        { selector: selectorFactoryMap["headers"] },
                        { selector: selectorFactoryMap["body"] }
                    ]
                }
            },
            organizations: {
                update_related: {
                    id: "update_related",
                    icon: "icon-checkmark3",
                    title: "Update Related",
                    config: [
                        { selector: selectorFactoryMap["type"] },
                    ],
                },
                add_checklist: {
                    id: "add_checklist",
                    icon: "icon-checkmark3",
                    title: "Add Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_checklist: {
                    id: "remove_checklist",
                    icon: "icon-checkmark3",
                    title: "Remove Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_all_checklists: {
                    id: "remove_all_checklists",
                    icon: "icon-checkmark3",
                    title: "Remove All Checklist",
                },
                create_activity: {
                    id: "create_activity",
                    icon: "icon-note",
                    title: "Create Activity",
                    config: [
                        { selector: selectorFactoryMap["note"] },
                        { selector: selectorFactoryMap["target_date"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                create_task: {
                    id: "create_task",
                    icon: "icon-checkmark3",
                    title: "Create Task",
                    config: [
                        { selector: selectorFactoryMap["subject"] },
                        { selector: selectorFactoryMap["text"] },
                        { selector: selectorFactoryMap["due_date"] },
                        { selector: selectorFactoryMap["assignee_id"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                join_group: {
                    id: "join_group",
                    icon: "icon-join-group",
                    title: "Join Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                leave_group: {
                    id: "leave_group",
                    icon: "icon-leave-group",
                    title: "Leave Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                update_organization: {
                    id: "update_organization",
                    icon: "icon-pencil",
                    title: TextManager.getText("ID_UPDATE_ENTITY", ['${ID_ORGANIZATION, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["update_organization"] }
                    ]
                },
                create_individual: {
                    id: "create_individual",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_INDIVIDUAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_individual"] }
                    ]
                },
                create_organization: {
                    id: "create_organization",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_ORGANIZATION, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_organization"] }
                    ]
                },
                create_opportunity: {
                    id: "create_opportunity",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_DEAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_opportunity"] }
                    ]
                },
                send_campaign: {
                    id: "send_campaign",
                    icon: "icon-campaign",
                    title: TextManager.parseText('Send ${ID_CAMPAIGN, capitalize}'),
                    config: [
                        { selector: selectorFactoryMap["campaign_id"] },
                        { selector: selectorFactoryMap["recipient_cf_id"] },
                        { selector: selectorFactoryMap["generate_alert_activities"] },
                        { selector: selectorFactoryMap["ignore_unsubscribed_all"] }
                    ]
                },
                rest_post: {
                    id: "rest_post",
                    icon: "icon-file-css",
                    title: "REST POST",
                    config: [
                        { selector: selectorFactoryMap["post_url"] },
                        { selector: selectorFactoryMap["headers"] },
                        { selector: selectorFactoryMap["body"] }
                    ]
                }
            },
            opportunities: {
                update_related: {
                    id: "update_related",
                    icon: "icon-checkmark3",
                    title: "Update Related",
                    config: [
                        { selector: selectorFactoryMap["type"] },
                    ],
                },
                add_checklist: {
                    id: "add_checklist",
                    icon: "icon-checkmark3",
                    title: "Add Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_checklist: {
                    id: "remove_checklist",
                    icon: "icon-checkmark3",
                    title: "Remove Checklist",
                    config: [
                        { selector: selectorFactoryMap["checklist_id"] },
                    ],
                },
                remove_all_checklists: {
                    id: "remove_all_checklists",
                    icon: "icon-checkmark3",
                    title: "Remove All Checklist",
                },
                create_activity: {
                    id: "create_activity",
                    icon: "icon-note",
                    title: "Create Activity",
                    config: [
                        { selector: selectorFactoryMap["note"] },
                        { selector: selectorFactoryMap["target_date"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                create_task: {
                    id: "create_task",
                    icon: "icon-checkmark3",
                    title: "Create Task",
                    config: [
                        { selector: selectorFactoryMap["subject"] },
                        { selector: selectorFactoryMap["text"] },
                        { selector: selectorFactoryMap["due_date"] },
                        { selector: selectorFactoryMap["assignee_id"] },
                        { selector: selectorFactoryMap["tags"] }
                    ]
                },
                join_group: {
                    id: "join_group",
                    icon: "icon-join-group",
                    title: "Join Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                leave_group: {
                    id: "leave_group",
                    icon: "icon-leave-group",
                    title: "Leave Group",
                    config: [
                        { selector: selectorFactoryMap["static_group_id"] }
                    ]
                },
                update_opportunity: {
                    id: "update_opportunity",
                    icon: "icon-pencil",
                    title: TextManager.getText("ID_UPDATE_ENTITY", ['${ID_DEAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["update_opportunity"] }
                    ]
                },
                create_individual: {
                    id: "create_individual",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_INDIVIDUAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_individual"] }
                    ]
                },
                create_organization: {
                    id: "create_organization",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_ORGANIZATION, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_organization"] }
                    ]
                },
                create_opportunity: {
                    id: "create_opportunity",
                    icon: "icon-plus",
                    title: TextManager.getText("ID_CREATE_ENTITY", ['${ID_DEAL, capitalize}']),
                    config: [
                        { selector: selectorFactoryMap["create_opportunity"] }
                    ]
                },
                send_campaign: {
                    id: "send_campaign",
                    icon: "icon-campaign",
                    title: TextManager.parseText('Send ${ID_CAMPAIGN, capitalize}'),
                    config: [
                        { selector: selectorFactoryMap["campaign_id"] },
                        { selector: selectorFactoryMap["recipient_cf_id"] },
                        { selector: selectorFactoryMap["generate_alert_activities"] },
                        { selector: selectorFactoryMap["ignore_unsubscribed_all"] }
                    ]
                },
                rest_post: {
                    id: "rest_post",
                    icon: "icon-file-css",
                    title: "REST POST",
                    config: [
                        { selector: selectorFactoryMap["post_url"] },
                        { selector: selectorFactoryMap["headers"] },
                        { selector: selectorFactoryMap["body"] }
                    ]
                }
            }
        }
    },
    wait: {
        id: "wait",
        title: "Wait",
        icon: "icon-wait",
        selector: selectorFactoryMap["wait_config"]
    },
    branch: {
        id: "branch",
        title: "Branch",
        icon: "icon-branch",
        selector: selectorFactoryMap["filter_id"]
    },
    call: {
        id: "call",
        title: "Call",
        icon: "icon-connect",
        selector: selectorFactoryMap["automation2_id"]
    },
    merge: {
        id: "merge",
        title: "Merge",
        icon: "icon-merge-down"
    }
};


export class AutomationDeleteConfirmationView extends React.Component {
    render() {
        return <div className={styles.AutomationDeleteConfirmationView}>
            <div className={styles.iconContainer}>
                <i className="icon-trashcan" />
            </div>
            {
                this.props.cannotDelete &&
                <div className={styles.infoContainer}>
                    <strong>You are not allowed to delete this node. </strong><br />
                    Please remove parent relationships first to remove this node.
                </div>
            }
            {
                !this.props.cannotDelete &&
                <div className={styles.infoContainer}>
                    <strong>You are about to delete this node. </strong><br />
                    All items in this node will be removed from automation.
                </div>
            }
        </div>;
    }
}

class AutomationNodeCreateEditView extends React.Component {
    constructor(props) {
        super(props);

        this.props.updateAutomationNodeData({});

        this.state = {
            id: this.props.step && this.props.step.id || null,
            name: this.props.step && this.props.step.name || '',
            step_type: this.props.step && this.props.step.step_type || null,
            event_type: this.props.step && this.props.step.event_type || null,
            group: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.group ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.group ||
                null,
            group_id: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.group_id ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.group_id ||
                null,
            checklist: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist ||
                this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist_item &&
                this.props.step.event_config.checklist_item.checklist ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.checklist ||
                null,
            checklist_id: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist_id ||
                this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist_item && 
                this.props.step.event_config.checklist_item.checklist_id ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.checklist_id ||
                null,
            checklist_item: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist_item ||
                null,
            checklist_item_id: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.checklist_item_id ||
                null,
            campaign: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.campaign ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.campaign ||
                null,
            campaign_id: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.campaign_id ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.campaign_id ||
                null,
            phase: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.phase ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.phase ||
                null,
            phase_id: this.props.step &&
                this.props.step.event_config &&
                this.props.step.event_config.phase_id ||
                this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.phase_id ||
                null,
            url: this.props.step && this.props.step.event_config && this.props.step.event_config.url || null,
            action_type: this.props.step && this.props.step.action_type || null,
            note: this.props.step && this.props.step.action_config && this.props.step.action_config.note || '',
            tags: {
                values: this.getTagValues()
            },
            target_date: '',
            text: this.props.step && this.props.step.action_config && this.props.step.action_config.text || '',
            assignee: this.props.step && this.props.step.action_config && this.props.step.action_config.assignee || null,
            assignee_id: this.props.step && this.props.step.action_config && this.props.step.action_config.assignee_id || null,
            filter: this.props.step && this.props.step.filter || null,
            filter_id: this.props.step && this.props.step.filter_id || null,
            rules: this.props.step && this.props.step.rules || null,
            automation2: this.props.step && this.props.step.automation2 || null,
            automation2_id: this.props.step && this.props.step.automation2_id || null,
            recipient_cf: this.props.step && this.props.step.action_config && this.props.step.action_config.recipient_cf || null,
            recipient_cf_id: this.props.step && this.props.step.action_config && this.props.step.action_config.recipient_cf_id || null,
            post_url: this.props.step && this.props.step.action_config && this.props.step.action_config.post_url || null,
            headers: this.props.step && this.props.step.action_config && this.props.step.action_config.headers || '',
            body: this.props.step && this.props.step.action_config && this.props.step.action_config.body || '',
            subject: this.props.step && this.props.step.action_config && this.props.step.action_config.subject || '',
            generate_alert_activities: this.props.step && this.props.step.action_config && this.props.step.action_config.generate_alert_activities,
            ignore_unsubscribed_all: this.props.step && this.props.step.action_config && this.props.step.action_config.ignore_unsubscribed_all,
            type: this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.type ||
                null,
            custom_field_id: this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.custom_field_id ||
                null,
            custom_field: this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.custom_field ||
                null,
            data: this.props.step &&
                this.props.step.action_config &&
                this.props.step.action_config.data ||
                null,
        };

        if (this.props.step && this.props.step.event_config && this.props.step.event_config.filter) {
            this.state.filter = this.props.step.event_config.filter;
            this.state.filter_id = this.props.step.event_config.filter_id;
        }

        if (this.props.step && this.props.step.event_type in ENTITY_UPDATE_FIELDS) {
            this.state.rules = this.props.step.event_config.rules;
        }

        this.parseRelativeDate("target", this.props.step && this.props.step.action_config && this.props.step.action_config.target_date, false);
        this.parseRelativeDate("wait", this.props.step && this.props.step.wait_config, false);
        this.parseRelativeDate("due", this.props.step && this.props.step.action_config && this.props.step.action_config.due_date, false);

        if (this.props.step && this.props.step.action_type === 'create_task') {
            // no assignee_id means the node is using 'owner-of-the-trigger-record' option
            if (this.props.step.action_config.assignee_id === null) {
                this.state.assignee_id = 'owner-of-the-trigger-record';
                this.state.assignee = {
                    id: 'owner-of-the-trigger-record',
                    name: 'Owner of the trigger record'
                };
            }
        }

        if (this.props.step && this.props.step.action_type === 'send_campaign') {
            // no recipient_id means the node is using 'send-to-this-individual' option
            if (this.props.step.action_config.recipient_cf_id === null) {
                this.state.recipient_cf_id = 'send-to-this-individual';
                this.state.recipient_cf = {
                    id: 'send-to-this-individual',
                    name: TextManager.getText('ID_SEND_TO_THIS_INDIVIDUAL')
                };
            }
        }

        if (this.props.step && this.props.step.action_type === 'update_related') {
            this.state.type_id = this.props.step.action_config.type;
            this.state.type = {
                id: this.props.step.action_config.type,
            };
        }

        if (
            this.props.step && (
                this.props.step.action_type === "update_individual" ||
                this.props.step.action_type === "create_individual" ||
                this.props.step.action_type === "update_organization" ||
                this.props.step.action_type === "create_organization" ||
                this.props.step.action_type === "update_opportunity" ||
                this.props.step.action_type === "create_opportunity" ||
                this.props.step.action_type === "update_related"
            )
        ) {
            let actionConfig = this.props.step.action_config;
            let actionType = this.props.step.action_type;
            if (actionType === "update_related") {
                let relatedType = actionConfig.type;
                if (relatedType === "custom_field") {
                    relatedType = actionConfig.custom_field.type
                }
                actionType = {
                    individual: 'update_individual',
                    organization: 'update_organization',
                    opportunity: 'update_opportunity',
                }[relatedType];
                actionConfig = actionConfig.data;
            }
            this.props.createUpdateFieldsAll[actionType]
                .filter(field => field.id in actionConfig && !_.isNull(actionConfig[field.id]) ||
                    field.db_id in actionConfig && !_.isNull(actionConfig[field.db_id]))
                .forEach(field => {
                    if (field.type === 'tags') {
                        if (actionType[0] === 'u') { // update
                            if (field.action === actionConfig[field.db_id].action) {
                                this.state["createUpdate_" + field.id] = {
                                    id: field.id,
                                    db_id: field.db_id,
                                    name: field.name,
                                    type: field.type,
                                    values: actionConfig[field.db_id].values.map(i => ({ id: i.tag_id, name: i.tag.name }))
                                };
                            }
                        }
                        else { // create
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                db_id: field.db_id,
                                name: field.name,
                                type: field.type,
                                values: actionConfig[field.db_id].map(i => ({ id: i.tag_id, name: i.tag.name }))
                            };
                        }
                    }
                    else if (field.hasInnerIdField) {
                        if (actionType[0] === 'u') { // update
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id].value_id,
                                value_title: actionConfig[field.id].value &&
                                    (actionConfig[field.id].value.title || actionConfig[field.id].value.name) || ""
                            };
                        }
                        else { // create
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id].id,
                                value_title: actionConfig[field.id].title || actionConfig[field.id].name || ""
                            };
                        }
                    }
                    else if (field.type === "dropDown" && field.options) {
                        if (actionType[0] === 'u') { // update
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id].value,
                                value_title: field.options.find(i => i.id === actionConfig[field.id].value).name,
                                options: field.options
                            };
                        }
                        else { // create
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id],
                                value_title: field.options.find(i => i.id === actionConfig[field.id]).name,
                                options: field.options
                            };
                        }
                    }
                    else if (field.type === 'currency') {
                        if (actionType[0] === 'u') { // update
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id].value,
                                value_title: Currency.composeCurrencyName(actionConfig[field.id].value)
                            };
                        }
                        else { // create
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value_id: actionConfig[field.id],
                                value_title: Currency.composeCurrencyName(actionConfig[field.id])
                            };
                        }
                    }
                    else if (field.type === 'date') {
                        if (actionType[0] === 'u') { // update
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value: dateFormat.shortFormatWithYear(actionConfig[field.id].value)
                            };

                            this["raw_createUpdate_" + field.id] = actionConfig[field.id].value;
                        }
                    }
                    else {
                        const negateValue = field.id === 'unsubscribed_all';

                        if (actionType[0] === 'u') { // update
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value: negateValue ? !actionConfig[field.id].value : actionConfig[field.id].value
                            };
                        }
                        else { // create
                            this.state["createUpdate_" + field.id] = {
                                id: field.id,
                                name: field.name,
                                type: field.type,
                                value: negateValue ? !actionConfig[field.id] : actionConfig[field.id]
                            };
                        }
                    }
                });

            if (actionConfig.custom_fields) {
                let list;
                if (actionType[0] === 'u') { // update
                    list = actionConfig.custom_fields.values;
                }
                else { // create
                    list = actionConfig.custom_fields;
                }

                list.forEach(item => {
                    // ignore if custom field removed in settings
                    if (!item.custom_field) {
                        return;
                    }

                    let data = {
                        id: item.custom_field_id,
                        name: item.custom_field.name,
                        type: item.custom_field.type
                    };

                    if (item.custom_field.type === 'individual') {
                        data.value_id = item.value;
                        data.value_title = item.value_extra ? item.value_extra.full_name : '';
                    }
                    else if (item.custom_field.type === 'organization') {
                        data.value_id = item.value;
                        data.value_title = item.value_extra ? item.value_extra.name : '';
                    }
                    else if (item.custom_field.type === 'opportunity') {
                        data.value_id = item.value;
                        data.value_title = item.value_extra ? item.value_extra.name : '';
                    }
                    else if (item.custom_field.type === 'user') {
                        data.value_id = item.value;
                        data.value_title = item.value_extra ? item.value_extra.name : '';
                    }
                    else if (item.custom_field.type === 'currency') {
                        data.value_id = item.value;
                        data.value_title = Currency.composeCurrencyName(item.value);
                    }
                    else if (item.custom_field.type === 'date') {
                        const prefix = 'relative_date_cf_createUpdate_' + item.custom_field_id;
                        if (item.value) {
                            this.parseRelativeDate(prefix, item.value, true)
                        } else {
                            this['raw_' + prefix + '_date'] = null;
                            this.state[prefix + '_date'] = null;
                            this.state[prefix + '_date_type'] = null;
                            this.state[prefix + "_relative_datetime_type"] = null;
                            this.state[prefix + "_relative_datetime_value"] = null;
                            this.state[prefix + "_skip_working_days"] = false;
                            data.value = null;
                        }
                        const field = this.props.createUpdateFieldsAll[actionType].find(fieldAll => fieldAll.id === item.custom_field_id)
                        if (field.data === undefined) {
                            field.data = {};
                        }
                        this.fillDateCustomFieldData(prefix, field.data)
                    }
                    else if (item.custom_field.type === 'dropDown') {
                        const option = item.custom_field.options.find((option) => option.id === item.value);
                        data.value_id = item.value;
                        data.value_title = option ? option.value : null;
                        data.options = item.custom_field.options.map(option => ({ id: option.id, value: option.value }));
                    }
                    // checkbox, number, ...
                    else {
                        data.value = item.value;
                    }

                    this.state["createUpdate_" + item.custom_field_id] = data;
                })
            }
        }

        const phasesCollection = new PhasesCollection();
        let self = this;

        phasesCollection.fetch({
            success: function() {
                let phases = [];
                let phaseWon;
                let phaseLost;

                _.each(phasesCollection.models, function(phase) {
                    const phaseType = phase.get('phase_type');

                    if (phaseType === 'won') {
                        phaseWon = phase;
                    } else if (phaseType === 'lost') {
                        phaseLost = phase;
                    } else {
                        phases.push(phase);
                    }
                });

                phases.push(phaseWon);
                phases.push(phaseLost);

                phasesList = _.map(phases, function(phase) {
                    return {
                        id: phase.get('id'),
                        name: phase.get('name'),
                        funnel: phase.get('funnel'),
                    };
                });

                if (phaseDDRef) {
                    phaseDDRef.setData(phasesList);
                }
            }
        });

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputChangeCreateUpdate = this.handleInputChangeCreateUpdate.bind(this);
        this.handleSelectClickCreateUpdate = this.handleSelectClickCreateUpdate.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.removeFilterRule = this.removeFilterRule.bind(this);
        this.handleEntityUpdated = this.handleEntityUpdated.bind(this);
        this.handleDateTimeClick = this.handleDateTimeClick.bind(this);
        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleDateTimeClick = this.handleDateTimeClick.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.subTypeSearch = this.subTypeSearch.bind(this);
        this.handleNewFieldClick = this.handleNewFieldClick.bind(this);
        this.handleRemoveFieldClick = this.handleRemoveFieldClick.bind(this);
        this.addMultiSelectItem = this.addMultiSelectItem.bind(this);
        this.removeMultiSelectItem = this.removeMultiSelectItem.bind(this);
        this.handleCustomFieldDateClick = this.handleCustomFieldDateClick.bind(this);
        this.handleCustomFieldDateInputChange = this.handleCustomFieldDateInputChange.bind(this);
    }

    getTagValues() {
        if (this.props.step && this.props.step.action_config && this.props.step.action_config.tags) {
            const tags = this.props.step.action_config.tags;

            if (_.isArray(tags)) {
                return tags.map(i => ({ id: i.tag_id, name: i.tag.name }));
            } else if (_.isObject(tags) && tags.values) {
                return tags.values.map(i => ({ id: i.tag_id, name: i.tag.name }));
            }
        }

        return [];
    }

    handleInputChange(ev) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleInputChangeCreateUpdate(ev, minValue, maxValue) {
        const target = ev.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if ((minValue || minValue === 0) && value < minValue) {
            value = minValue;
        }
        if ((maxValue || maxValue === 0) && value > maxValue) {
            value = maxValue;
        }

        this.setState(prevState => {
            prevState[name].value = value;
            return prevState;
        });
    }

    handleSelectClick(id, items) {
        const item = items[0];

        this.setState({ [id]: item.id });
    }

    handleSelectClickCreateUpdate(id, items) {
        const item = items[0];

        this.setState(prevState => {
            if (!(id in prevState)) {
                prevState[id] = {};
            }

            prevState[id].value_id = item.id;
            prevState[id].value_title = item.title || item.name;

            prevState[id].value = {
                id: item.id,
                value: prevState[id].value_title
            };

            return prevState;
        });
    }

    parseRelativeDate(prefix, data, isCustomField) {
        if (!data) {
            return;
        }
        else if (typeof data === "string") {
            data = {
                type: "datetime",
                value: data,
            }
        }

        if (data.type === 'datetime') {
            this.state[prefix + "_date_type"] = 'datetime';
            if (isCustomField) {
                this.state[prefix + "_date"] = dateFormat.shortFormatWithYear(data.value);
            } else {
                this.state[prefix + "_date"] = dateFormat.shortFormatWithYearTime(data.value + "Z");
            }

            this["raw_" + prefix + "_date"] = data && data.value;
        }
        else if (data.type === 'relative_datetime') {
            this.state[prefix + "_date_type"] = 'relative_datetime';

            // find first set value
            let type = _.find(intervalList, (type) => _.isNumber(data[type]));
            let value;

            // simple offset
            if (type) {
                value = data[type];
            }
            // weekday offset
            else if (!type && data.weekday) {
                type = data.weekday;
                value = data.weekday_offset;
            }

            this.state[prefix + "_relative_datetime_type"] = type;
            this.state[prefix + "_relative_datetime_value"] = value;
            this.state[prefix + "_skip_working_days"] = data.skip_user_off_days || false;
        }
    }

    setRelativeDate(prefix, data) {
        if (this.state[prefix + "_date_type"] === 'datetime') {
            data.type = "datetime";
            data.value = this["raw_" + prefix + "_date"];
            data.timezone = "UTC";
        }
        else if (this.state[prefix + "_date_type"] === 'relative_datetime') {
            data.type = "relative_datetime";

            // reset all time field;
            data.years = null;
            data.months = null;
            data.days = null;
            // data.hours = null;
            // data.minutes = null;
            // data.seconds = null;

            data.timezone = "UTC";

            // year, month, ...
            if (_.contains(intervalList, this.state[prefix + "_relative_datetime_type"])) {
                // set time field
                data[this.state[prefix + "_relative_datetime_type"]] = parseInt(this.state[prefix + "_relative_datetime_value"]);
            }
            // Monday, Tuesday, ...
            else {
                data.weekday = this.state[prefix + "_relative_datetime_type"];
                data.weekday_offset = parseInt(this.state[prefix + "_relative_datetime_value"]);
            }

            // Skip working days
            data.skip_user_off_days = this.state[prefix + "_skip_working_days"];
            data.skip_user_holidays = this.state[prefix + "_skip_working_days"];
        }
    }

    elementTypeSearch(search, done) {
        done(_.map(stepTypeMap, item => item));
    }

    subTypeSearch(search, done) {
        done(_.map(stepTypeMap[this.state.step_type]['subTypeMapByEntity'][this.props.entityType], item => item));
    }

    handleFilterClick(ev, rule, ind) {
        const callback = (filter) => {
            this.setState({
                filter_id: filter.id,
                filter: filter
            });
        };

        this.props.showFilter(ev.target, callback, this.state.filter, rule, ind);
    }

    removeFilterRule(ind) {
        const callback = (filter) => {
            this.setState({
                filter_id: filter.id,
                filter: filter
            });
        };
        this.props.removeFilterRule(callback, this.state.filter, ind);
    }

    handleEntityUpdated(rules) {
        this.setState({
            rules: rules,
        });
    }

    handleDateTimeClick(ev) {
        ev.persist();

        const callback = (data) => {
            this["raw_" + ev.target.name] = data.rawDateTime;
            ev.target.value = dateFormat.shortFormatWithYearTime(ev.target.value);
            this.handleInputChange(ev);
        };

        this.props.showDateTime(ev.target, callback);
    }

    handleDateClick(ev) {
        ev.persist();

        const callback = () => {
            const target = ev.target;
            this["raw_" + target.name] = dateFormat.ISODate(target.value);

            this.setState(prevState => {
                prevState[target.name].value = target.value;
                return prevState;
            });
        };

        this.props.showDate(ev.target, callback);
    }

    handleCustomFieldDateClick(ev) {
        ev.persist();

        const callback = () => {
            const target = ev.target;
            this["raw_" + target.name] = dateFormat.ISODate(target.value);

            this.setState(prevState => {
                prevState[target.name] = target.value;
                return prevState;
            });
        };

        this.props.showDate(ev.target, callback);
    }

    handleCustomFieldDateInputChange(ev) {
        const target = ev.target;
        this.setState({
          [target.name]: target.value
        });
    }

    addMultiSelectItem(key, item) {
        this.setState(prevState => {
            let list = [];
            prevState[key].values && prevState[key].values.forEach(i => {
                list.push({ id: i.id, name: i.name });
            });
            list.push({ id: item.id, name: item.name });
            return { [key]: _.extend({}, prevState[key], { values: list }) };
        })
    }

    removeMultiSelectItem(key, item) {
        this.setState(prevState => {
            let list = [];
            prevState[key].values && prevState[key].values.forEach(i => {
                if (i.id !== item.id) {
                    list.push({ id: i.id, name: i.name });
                }
            });
            return { [key]: _.extend({}, prevState[key], { values: list }) };
        })
    }

    handleNewFieldClick(items) {
        const item = items[0];

        if (!this.state["createUpdate_" + item.id]) {
            this.setState({ ["createUpdate_" + item.id]: {
                id: item.id,
                db_id: item.db_id,
                name: item.name,
                type: item.type,
                options: item.options,
                value: null
            }});
        }
    }

    handleRemoveFieldClick(id) {
        this.setState({ ["createUpdate_" + id]: null });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state) {
            return;
        }

        let actionConfig = {
            note: this.state.note,
            text: this.state.text,
            assignee_id: this.state.assignee_id,
            group_id: this.state.group_id,
            checklist_id: this.state.checklist_id,
            campaign_id: this.state.campaign_id,
            recipient_cf_id: this.state.recipient_cf_id,
            post_url: this.state.post_url,
            headers: this.state.headers,
            body: this.state.body,
            phase_id: this.state.phase_id,
            subject: this.state.subject,
            generate_alert_activities: this.state.generate_alert_activities,
            ignore_unsubscribed_all: this.state.ignore_unsubscribed_all,
            tags: (
                this.state.tags &&
                this.state.tags.values &&
                this.state.tags.values.map(field => ({ tag_id: field.id }))
            ),
            target_date: {},
            due_date: {}
        };

        this.setRelativeDate("target", actionConfig.target_date);
        this.setRelativeDate("due", actionConfig.due_date);

        const waitConfig = {};
        this.setRelativeDate("wait", waitConfig);

        if (
            this.state.action_type === "update_individual" ||
            this.state.action_type === "create_individual" ||
            this.state.action_type === "update_organization" ||
            this.state.action_type === "create_organization" ||
            this.state.action_type === "update_opportunity" ||
            this.state.action_type === "create_opportunity" ||
            (
                (this.state.action_type === "update_related" && this.state.type_id) &&
                (this.state.type_id !== "custom_field" || this.state.custom_field)
            )
        ) {
            actionConfig = {};
            let entityConfig = actionConfig;
            let customFields = [];

            let actionType = this.state.action_type;
            if (actionType === "update_related") {
                let relatedType = this.state.type_id;
                if (relatedType === "custom_field") {
                    relatedType = this.state.custom_field['type']
                }
                actionType = {
                    individual: 'update_individual',
                    organization: 'update_organization',
                    opportunity: 'update_opportunity',
                }[relatedType];
                actionConfig = {
                    type: this.state.type_id,
                    custom_field_id: this.state.custom_field_id,
                    data: {},
                }
                entityConfig = actionConfig.data;
            }
            this.props.createUpdateFieldsAll[actionType]
                .filter(field => ("createUpdate_" + field.id) in this.state && this.state["createUpdate_" + field.id])
                .forEach(field => {
                    if (field.customField) {
                        if (this.state["createUpdate_" + field.id]) {
                            let value;
                            if (field.type in { 'individual': true, 'organization': true, 'opportunity': true, 'user': true, 'currency': true, 'dropDown': true }) {
                                value = this.state["createUpdate_" + field.id].value_id;
                            }
                            else if (field.type === 'date') {
                                const prefix = "relative_date_cf_createUpdate_" + field.id;
                                if (field.data === undefined) {
                                    field.data = {};
                                }
                                this.fillDateCustomFieldData(prefix, field.data)
                                value = field.data.relative_date_config || {};
                            }
                            else if (field.type === 'number') {
                                value = parseInt(this.state["createUpdate_" + field.id].value);
                            }
                            else {
                                value = this.state["createUpdate_" + field.id].value;
                            }
                            customFields.push({ action: "set", custom_field_id: field.id, value: value });
                        }
                    }
                    else {
                        if (field.type === 'tags') {
                            if (this.state.action_type[0] === 'u') { // update
                                entityConfig[field.db_id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].values &&
                                    {
                                        action: field.action,
                                        values: this.state["createUpdate_" + field.id].values.map(field => ({ action: "add", tag_id: field.id }))
                                    };
                            }
                            else { // create
                                entityConfig[field.db_id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].values &&
                                    this.state["createUpdate_" + field.id].values.map(field => ({ action: "add", tag_id: field.id }))
                            }
                        }
                        else if (field.hasInnerIdField) {
                            if (this.state.action_type[0] === 'u') { // update
                                entityConfig[field.id] = this.state["createUpdate_" + field.id] && { action: "set", value_id: this.state["createUpdate_" + field.id].value_id };
                            }
                            else { // create
                                entityConfig[field.id + "_id"] = this.state["createUpdate_" + field.id].value_id;
                            }
                        }
                        else if (field.type === "currency" || field.type === "dropDown") {
                            if (this.state.action_type[0] === 'u') { // update
                                entityConfig[field.id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].value_id &&
                                    { action: "set", value: this.state["createUpdate_" + field.id].value_id };
                            }
                            else { // create
                                entityConfig[field.id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].value_id;
                            }
                        }
                        else if (field.type === "weight") {
                            if (this.state.action_type[0] === 'u') { // update
                                entityConfig[field.id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].value &&
                                    { action: "set", value: parseFloat(this.state["createUpdate_" + field.id].value) };
                            }
                            else { // create
                                entityConfig[field.id] =
                                    this.state["createUpdate_" + field.id] &&
                                    this.state["createUpdate_" + field.id].value &&
                                    parseFloat(this.state["createUpdate_" + field.id].value);
                            }
                        }
                        else if (field.type === "date") {
                            // update
                            entityConfig[field.id] = this["raw_createUpdate_" + field.id] && { action: "set", value: this["raw_createUpdate_" + field.id] };
                        }
                        else {
                            if (this.state.action_type[0] === 'u') { // update
                                entityConfig[field.id] = this.state["createUpdate_" + field.id] && { action: "set", value: this.state["createUpdate_" + field.id].value };
                            }
                            else { // create
                                entityConfig[field.id] = this.state["createUpdate_" + field.id].value;
                            }
                        }
                    }
                });

            if (this.state.action_type[0] === 'u') { // update
                entityConfig.custom_fields = { action: "replace", values: customFields };
            }
            else { // create
                entityConfig.custom_fields = customFields;
            }
        }

        this.props.updateAutomationNodeData({
            id: this.state.id,
            name: this.state.name,
            step_type: this.state.step_type,
            event_type: this.state.event_type,
            event_config: {
                group_id: this.state.group_id,
                campaign_id: this.state.campaign_id,
                phase_id: this.state.phase_id,
                url: this.state.url,
                filter_id: this.state.filter_id,
                rules: this.state.rules,
                checklist_id: this.state.checklist_id,
                checklist_item_id: this.state.checklist_item_id,
            },
            action_type: this.state.action_type,
            action_config: actionConfig,
            wait_config: waitConfig,
            dueDate: waitConfig,
            filter_id: this.state.filter_id,
            automation2_id: this.state.automation2_id,
        });
    }

    fillDateCustomFieldData (prefix, fieldData) {
        if (this.state[prefix + '_date_type']) {
            const customFieldConfig = {}
            this.setRelativeDate(prefix, customFieldConfig);
            fieldData['relative_date_config'] = customFieldConfig;
            fieldData[prefix + "_date_type"] = customFieldConfig.type;
        }
        fieldData['raw_' + prefix + "_date"] = this['raw_' + prefix + '_date'];
        fieldData[prefix + "_date"] = this.state[prefix + '_date'];
        fieldData[prefix + "_relative_datetime_type"] = this.state[prefix + "_relative_datetime_type"];
        fieldData[prefix + "_relative_datetime_value"] = this.state[prefix + "_relative_datetime_value"];
        fieldData[prefix + "_skip_working_days"] = this.state[prefix + "_skip_working_days"];
    }

    render() {
        const elementList = [];

        if (this.state.step_type && stepTypeMap[this.state.step_type].selector) {
            elementList.push(stepTypeMap[this.state.step_type].selector(this));

            if (stepTypeMap[this.state.step_type].subTypeKey) {
                const options = stepTypeMap[this.state.step_type].subTypeMapByEntity[this.props.entityType][this.state[stepTypeMap[this.state.step_type].subTypeKey]];
                if (options && options.config) {
                    _.each(options.config, el => {
                        elementList.push(el.selector(this));
                    });
                }
            }
        }

        let smallIconClasses;
        if (
            this.state.step_type
            && stepTypeMap[this.state.step_type].subTypeMapByEntity
            && this.state[stepTypeMap[this.state.step_type].subTypeKey]
        ) {
            smallIconClasses = this.state.step_type ? classnames({
                [styles.smallIcon]: true,
                [stepTypeMap[this.state.step_type].subTypeMapByEntity[this.props.entityType][this.state[stepTypeMap[this.state.step_type].subTypeKey]].icon]: true
            }) : null;
        }

        return (
            <div className={styles.AutomationNodeCreateEditView}>
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                        <i className={this.state.step_type ? (stepTypeMap[this.state.step_type]).icon : null} />
                        {
                            smallIconClasses &&
                            <div className={smallIconClasses} />
                        }
                    </div>
                </div>

                <div className={styles.attributeContainer}>
                    <label htmlFor="name">Node Name</label>
                    <input
                        type="text"
                        className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: this.props.errorField === "name" })}
                        placeholder="Node Name"
                        autoComplete="off"
                        name="name"
                        id="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                    /><br />

                    <label htmlFor="step_type">Building Block</label>
                    <NewSelect
                        className={classnames({ [styles.attribute]: true, [styles.errorAttribute]: this.props.errorField === "step_type" })}
                        value={stepTypeMap[this.state.step_type]}
                        data={_.map(stepTypeMap, item => item)}
                        onSelect={(items) => { this.handleSelectClick("step_type", items); }}
                        placeholder="Building Block"
                        width={250}
                        containerStyle={containerStyle}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                        disabled={!!this.state.id}
                    /><br/>
                    { elementList }
                </div>
            </div>
        )
    }
}

class EntityUpdatedTarget {
    constructor() {
        this.targets = [
            {
                id: 'new',
                name: 'New',
            },
            {
                id: 'old',
                name: 'Old',
            },
        ];

        this.target = this.target.bind(this);
        this.view = this.view.bind(this);
    }

    target(rule) {
        return this.targets.find(v => v.id === rule.target);
    }

    view(props) {
        return <div>
            <label className={styles.fieldCountContainer}>Target</label>
            <NewSelect
                className={styles.attribute}
                text="name"
                onSelect={(items) => props.onRuleUpdate({
                    target: items[0] && items[0].id,
                    value: props.rule.value,
                })}
                placeholder="Select target here"
                value={this.target(props.rule)}
                data={this.targets}
                containerStyle={containerStyle}
                width={250}
                boxStyle={boxStyle}
                dropDownStyle={dropDownStyle}
            />
        </div>;
    }
};

const ENTITY_UPDATE_OPERATOR_TYPES = {
    boolean: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            values: [
                {
                    id: true,
                    name: 'True',
                },
                {
                    id: false,
                    name: 'False',
                },
            ],
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets, values } = operator;
                const target = targets.target(rule);
                const value = values.find(v => v.id === rule.value);
                return <span>{`${target.name}: ${field.name} is ${value.name}`}</span>;
            },
            view: (props) => {
                const { operator, rule, onRuleUpdate } = props;
                const { targets, values } = operator;

                const value = values.find(v => v.id === rule.value);

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            target: rule.target,
                            value: items[0].id,
                        })}
                        placeholder="Select value here"
                        value={value}
                        data={values}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                </div>
            },
        },
    ],
    text: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                return <span>{`${target.name}: ${field.name} is ${rule.value}`}</span>;
            },
            view: (props) => {
                const { operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <input
                        type="text"
                        className={classnames({ [styles.attribute]: true, })}
                        placeholder="Value"
                        autoComplete="off"
                        name="value"
                        id="value"
                        value={rule.value}
                        onChange={(ev) => onRuleUpdate({
                            target: rule.target,
                            value: ev.target.value,
                        })}
                    />
                    <br/>
                </div>
            },
        },
    ],
    number: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                return <span>{`${target.name}: ${field.name} is ${rule.value}`}</span>;
            },
            view: (props) => {
                const { operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <input
                        type="number"
                        className={classnames({ [styles.attribute]: true, })}
                        placeholder="Value"
                        autoComplete="off"
                        name="value"
                        id="value"
                        value={rule.value}
                        onChange={(ev) => onRuleUpdate({
                            target: rule.target,
                            value: ev.target.value,
                        })}
                    />
                    <br/>
                </div>
            },
        },
    ],
    date: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                return <span>{`${target.name}: ${field.name} is ${dateFormat.shortFormatWithYear(rule.value)}`}</span>;
            },
            view: (props) => {
                const { operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <input
                        className={styles.attribute}
                        placeholder="Date..."
                        autoComplete="off"
                        name="value"
                        id="value"
                        value={rule.value && dateFormat.shortFormatWithYear(rule.value)}
                        onClick={ev => {
                            ev.preventDefault();

                            const target = $(ev.target);
                            target.datepicker({
                                numberOfMonths: 2,
                                showButtonPanel: true,
                                dateFormat: 'M d, yy',
                                parent: this,
                            });
                            target.datepicker('show');
                    
                            target.off('change').on('change', () => onRuleUpdate({
                                target: rule.target,
                                value: dateFormat.ISODate(dateFormat.parseDate(target.val())),
                            }));
                        }}
                        style={{ cursor: "auto" }}
                        readOnly={true} // readonly because react complains missing onChange
                    />
                    <br/>
                </div>
            },
        },
    ],
    dropdown: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                const value = field.model.get('options').find(v => v.id === rule.value);
                return <span>{`${target.name}: ${field.name} is ${value && value.value}`}</span>;
            },
            view: (props) => {
                const { field, operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <NewSelect
                        className={styles.attribute}
                        text={'value'}
                        onSelect={(items) => onRuleUpdate({
                            target: rule.target,
                            value: items[0].id,
                        })}
                        placeholder="Select value here"
                        data={field.model.get('options')}
                        value={rule.value}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                </div>
            },
        },
    ],
    currency: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                return <span>{`${target.name}: ${field.name} is ${Currency.composeCurrencyName(rule.value)}`}</span>;
            },
            view: (props) => {
                const { operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>Value</label>
                    <NewSelect
                        className={classnames({
                            [styles.attribute]: true,
                        })}
                        data={Currency.getUsedCurrenciesToSelect2Array()}
                        value={{
                            id: rule.value,
                            text: Currency.composeCurrencyName(rule.value),
                        }}
                        text="text"
                        onSelect={items => onRuleUpdate({
                            target: rule.target,
                            value: items[0].id,
                        })}
                        placeholder="Select a currency"
                        width={250}
                        containerStyle={containerStyle}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                </div>
            },
        },
    ],
    entity: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'equals',
            name: 'Equals',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                const valueName = field.entityName ?  field.entityName(rule.value) : rule.value[field.entityText];
                return <span>{`${target.name}: ${field.name} is ${valueName}`}</span>;
            },
            view: (props) => {
                const { field, operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>{field.name}</label>
                    <NewSelect
                        className={styles.attribute}
                        text={field.entityText}
                        onSelect={(items) => onRuleUpdate({
                            target: rule.target,
                            value_id: items[0].id,
                            value: items[0],
                        })}
                        placeholder="Select value here"
                        processData={field.processData}
                        value={rule.value}
                        url={field.entityUrl}
                        containerStyle={containerStyle}
                        width={250}
                        options={field.entityOptions}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                </div>
            },
        },
    ],
    entity_list: [
        {
            id: 'changed',
            name: 'Changed',
            text: (field) => `Changed: ${field.name}`,
        },
        {
            id: 'has',
            name: 'Has',
            targets: new EntityUpdatedTarget(),
            text: (field, operator, rule) => {
                const { targets } = operator;
                const target = targets.target(rule);
                const valueName = field.entityName ?  field.entityName(rule.value) : rule.value[field.entityText];
                return <span>{`${target.name}: ${field.name} has ${valueName}`}</span>;
            },
            view: (props) => {
                const { field, operator, rule, onRuleUpdate } = props;
                const { targets } = operator;

                return <div>
                    <targets.view rule={rule} onRuleUpdate={onRuleUpdate} />
                    <label className={styles.fieldCountContainer}>{field.name}</label>
                    <NewSelect
                        className={styles.attribute}
                        text={field.entityText}
                        onSelect={(items) => onRuleUpdate({
                            target: rule.target,
                            value_id: items[0].id,
                            value: items[0],
                        })}
                        placeholder="Select value here"
                        processData={field.processData}
                        value={rule.value}
                        url={field.entityUrl}
                        containerStyle={containerStyle}
                        width={250}
                        options={field.entityOptions}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                </div>
            },
        },
    ],
}

function EntityUpdatedCustomField(model, options) {
    return _.extend({
        id: `custom_field.${model.get("id")}`,
        name: model.get('name'),
        text: (field, rule) => {
            const operator = field.operators.find(operator => operator.id === rule.operator);
            if (!operator) {
                return 'Unknown';
            }

            return operator.text(field, operator, rule);
        },
        view: (props) => {
            const { field, rule, onRuleUpdate } = props;
            const operator = field.operators.find(operator => operator.id === rule.operator);

            return <div>
                <label className={styles.fieldCountContainer}>Operator</label>
                <NewSelect
                    className={styles.attribute}
                    text="name"
                    onSelect={(items) => onRuleUpdate({
                        operator: items[0] && items[0].id,
                    })}
                    placeholder="Select operator here"
                    value={operator}
                    data={field.operators}
                    containerStyle={containerStyle}
                    width={250}
                    boxStyle={boxStyle}
                    dropDownStyle={dropDownStyle}
                />
                <br/>
                {operator && operator.view && <operator.view
                    field={field}
                    operator={operator}
                    rule={rule}
                    onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                        operator: operator.id,
                    }, rule))}
                />}
            </div>
        },
    }, options);
}

const ENTITY_UPDATE_CUSTOM_FIELDS = {
    text: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.text,
        model: model,
    }),
    paragraph: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.text,
        model: model,
    }),
    url: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.text,
        model: model,
    }),
    urlImage: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.text,
        model: model,
    }),
    number: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.number,
        model: model,
    }),
    date: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.date,
        model: model,
    }),
    currency: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.currency,
        model: model,
    }),
    checkbox: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.boolean,
        model: model,
    }),
    dropDown: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.dropdown,
        model: model,
    }),
    individual: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.entity,
        entityUrl: '/individuals',
        entityText: 'full_name',
        processData: (data) => _.map(data, (value) => value.item),
    }),
    organization: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.entity,
        model: model,
        entityUrl: '/organizations',
        entityText: 'name',
        processData: (data) => _.map(data, (value) => value.item),
    }),
    opportunity: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.entity,
        model: model,
        entityUrl: '/opportunities',
        entityText: 'name',
        processData: (data) => _.map(data, (value) => value.item),
    }),
    user: (model) => EntityUpdatedCustomField(model, {
        operators: ENTITY_UPDATE_OPERATOR_TYPES.entity,
        model: model,
        entityUrl: '/users',
        entityText: 'name',
    }),
}

const ENTITY_UPDATE_FIELDS = {
    individual_updated: [
        {
            id: 'owner_id',
            name: 'Owner',
            entityUrl: '/users',
            entityText: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            entityName: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'tags',
            name: 'Tags',
            entityUrl: '/tags',
            entityText: 'name',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity_list'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'funnels',
            name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            entityUrl: '/funnels',
            entityText: 'name',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity_list'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        }
    ],
    organization_updated: [
        {
            id: 'owner_id',
            name: 'Owner',
            entityUrl: '/users',
            entityText: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            entityName: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'tags',
            name: 'Tags',
            entityUrl: '/tags',
            entityText: 'name',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity_list'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'funnels',
            name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
            entityUrl: '/funnels',
            entityText: 'name',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity_list'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        }
    ],
    opportunity_updated: [
        {
            id: 'owner_id',
            name: 'Owner',
            entityUrl: '/users',
            entityText: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            entityName: (value) => {
                if (value.title) {
                    return value.title;
                }

                return value.name;
            },
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'phase_id',
            name: 'Phase',
            entityUrl: '/phases',
            entityText: (value) => {
                if (value.funnel) {
                    return `${value.funnel.name}: ${value.name}`;
                }
                return value.name;
            },
            entityName: (value) => {
                if (value && value.funnel) {
                    return `${value.funnel.name}: ${value.name}`;
                }

                return ''
            },
            entityOptions: {formatResult: phasesFormatter},
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
        {
            id: 'tags',
            name: 'Tags',
            entityUrl: '/tags',
            entityText: 'name',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['entity_list'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({
                            operator: operator.id,
                        }, rule))}
                    />}
                </div>
            },
        },
    ],
    task_updated: [
        {
            id: 'completed',
            name: 'Completed',
            text: (field, rule) => {
                const operator = field.operators.find(operator => operator.id === rule.operator);
                if (!operator) {
                    return 'Unknown';
                }

                return operator.text(field, operator, rule);
            },
            operators: ENTITY_UPDATE_OPERATOR_TYPES['boolean'],
            view: (props) => {
                const { field, rule, onRuleUpdate } = props;
                const operator = field.operators.find(operator => operator.id === rule.operator);

                return <div>
                    <label className={styles.fieldCountContainer}>Operator</label>
                    <NewSelect
                        className={styles.attribute}
                        text="name"
                        onSelect={(items) => onRuleUpdate({
                            operator: items[0] && items[0].id,
                        })}
                        placeholder="Select operator here"
                        value={operator}
                        data={field.operators}
                        containerStyle={containerStyle}
                        width={250}
                        boxStyle={boxStyle}
                        dropDownStyle={dropDownStyle}
                    />
                    <br/>
                    {operator && operator.view && <operator.view
                        field={field}
                        operator={operator}
                        rule={rule}
                        onRuleUpdate={(rule) => onRuleUpdate(_.extend({}, rule, {
                            operator: operator.id,
                        }))}
                    />}
                </div>
            },
        },
    ],
}

export class EntityUpdatedView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: ENTITY_UPDATE_FIELDS[props.event_type],
        }

        this.fetchCustomFields();
    }

    fetchCustomFields() {
        const { entityType} = this.props;

        const customFields = new CustomFieldsCollection();
        customFields.fetch({
            rows: -1,
            filterBy: [{
                attribute: 'view',
                value: entityType,
            }],
            success: this.onFetchCustomFields.bind(this),
        });
    }

    onFetchCustomFields(data) {
        const { event_type } = this.props;
        const fields = _.union(
            ENTITY_UPDATE_FIELDS[event_type],
            data.map((model) => {
                const view = ENTITY_UPDATE_CUSTOM_FIELDS[model.get('type')];
                return view && view(model);
            }).filter((view) => {
                return view;
            }),
        );

        this.setState({
            fields: fields,
        });
    }

    render() {
        const { props } = this;
        const { fields } = this.state;

        return <div>
            <label htmlFor="rules">Rules</label>
            <div style={{display: 'inline-block', marginTop: 6}}>
                <EntityUpdatedRulesView
                    rules={props.rules || { type: 'all', where: [] }}
                    fields={fields}
                    update={(rules) => props.handleEntityUpdated(rules)}
                />
            </div>
        </div>;
    }
} 


export class EntityUpdatedRulesView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newRule: null,
        };
    }

    setNewRule(rule) {
        this.setState({
            newRule: rule,
        });
    }

    onNewRuleClose() {
        this.setState({
            newRule: null,
        });
    }

    onNewRuleSave() {
        const { rules } = this.props;
        const { newRule } = this.state;
        const where = rules.where.slice();
        where.push(newRule);

        this.setRules(_.extend({}, rules, {
            where: where,
        }));

        this.onNewRuleClose();
    }

    onRuleUpdate(index, rule) {
        const { rules } = this.props;
        const where = rules.where.slice();
        where[index] = rule;

        this.setRules(_.extend({}, rules, {
            where: where,
        }));
    }

    onRuleRemove(index) {
        const { rules } = this.props;
        const where = rules.where.slice();
        where.splice(index, 1);

        this.setRules(_.extend({}, rules, {
            where: where,
        }));
    }

    setRules(rules) {
        this.props.update(rules);
    }

    render() {
        const { fields, rules } = this.props;
        const { newRule } = this.state;
        
        return (
            <div style={{width: 250}}>
                {rules.where.map((rule, index) => {
                    return <EntityUpdatedRuleValueView
                        key={index}
                        rule={rule}
                        fields={fields}
                        update={(rule) => this.onRuleUpdate(index, rule)}
                        remove={() => this.onRuleRemove(index)}
                    />;
                })}
                <button onClick={() => this.setNewRule({})}>Add</button>
                {newRule && <Popover
                    width={600}
                    height={400}
                    header={{
                        title: "New Rule",
                        onClose: this.onNewRuleClose.bind(this),
                        onSave: this.onNewRuleSave.bind(this),
                    }}
                >
                    <EntityUpdatedEditRuleView
                        fields={fields}
                        rule={newRule}
                        update={(rule) => this.setNewRule(rule)}
                    />
                </Popover>}
            </div>
        );
    }
}

export class EntityUpdatedRuleValueView extends React.Component {
    constructor(props) {
        super(props);

        const { rule } = props;
        this.state = {
            rule: rule.type === 'not' ? rule.value : rule,
            not: rule.type === 'not',
            editRule: null,
        };
    }

    setEditRule(rule) {
        this.setState({
            editRule: rule,
        });
    }

    onEditRuleClose() {
        this.setState({
            editRule: null,
        });
    }

    onEditRuleSave() {
        const { editRule } = this.state;
        this.update(editRule);

        this.onEditRuleClose();
    }

    setNotRule(value) {
        this.setState({
            not: value,
        }, () => this.update(this.state.rule));
    }

    update(rule) {
        const {update} = this.props;
        const {not} = this.state;
        if (not) {
            update({
                type: 'not',
                value: rule,
            });
        } else {
            update(rule);
        }
    }

    render() {
        const { fields } = this.props;
        const { not, rule, editRule } = this.state;
        const field = fields.find(field => field.id === rule.field);
        if (!field) return null;

        return (
            <div>
                <div className={styles.FilterItem} style={{marginLeft: 'auto', marginBottom: 2, display: 'block'}}>
                    <span className={styles.itemContainer}>{field.text(field, rule)}</span>
                    <i className={classnames({
                        "icon-blocked": not,
                        [styles.iconBlocked]: not,
                        "icon-checkmark3": !not,
                        [styles.iconCheckmark3]: !not,
                    })} onClick={() => this.setNotRule(!not)}></i>
                    <i className={classnames({ "icon-pencil": true, [styles.iconPencil]: true })} onClick={() => this.setEditRule(rule)}></i>
                    <i className={classnames({ "icon-cross": true, [styles.iconCross]: true })} onClick={this.props.remove}></i>
                </div>
                {editRule && <Popover
                    width={600}
                    height={400}
                    header={{
                        title: "Edit Rule",
                        onClose: this.onEditRuleClose.bind(this),
                        onSave: this.onEditRuleSave.bind(this),
                    }}
                >
                    <EntityUpdatedEditRuleView
                        fields={fields}
                        rule={editRule}
                        update={(rule) => this.setEditRule(rule)}
                    />
                </Popover>}
            </div>
        );
    }
}

export default AutomationNodeCreateEditView;

import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import _ from 'underscore';
import $ from 'jquery';

import onClickOutside from 'react-onclickoutside';
import dateFormat from 'js/utils/date-format';
import Currency from 'js/utils/currency';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';

import {TagList} from 'js/react_views/detail_view_components/tag-list';
import {Button} from 'js/react_views/widgets/widgets';
import Popover from 'js/react_views/widgets/popover';
import {
    TextField,
    CheckboxField,
    IndividualField,
    OrganizationField,
    OpportunityField,
    ImageField,
    UrlField,
    CommunicationField,
    LocationField,
    DocusignField,
    EmailTemplating,
    ChecklistsProgress,
    SendAllEmailButton,
    EntityListField
} from 'js/react_views/detail_fields/detail_fields';
import IndividualDeals from './deals';
import RelatedFiles from 'js/react_views/detail_view_components/related-files';
import GroupMembership from 'js/react_views/detail_view_components/group-membership';
import ArchiveAddress from 'js/react_views/detail_view_components/archive-address';
import DragAndDropOverlay from 'js/react_views/detail_view_components/drag-and-drop-overlay';

import styles from './styles.css';

const colors = {
    faintestGrey: 'rgba(0, 0, 0, 0.07)',
    fainterGrey: 'rgba(0, 0, 0, 0.1)',
    faintGrey: 'rgba(0, 0, 0, 0.2)',
    faintGrey2: '#c1c4cd',
    faintGrey3: 'rgba(165, 170, 182, 0.6)',
    superLightGrey: '#f6f6f6',
    veryLightGrey: '#e0e1e6',
    quiteLightGrey: '#b5babf',
    midGrey: '#9ea7b0',
    darkishGrey: '#666666',
    darkGrey: '#404040',
    bluishGrey: '#888fa0',
    softBlue: '#70c2ff', // lighten(blue, 20%);
    lightBlue: '#d4ecff',
    paleBlue: '#eef7ff',
    blueBackground1: 'rgba(10, 151, 248, 0.8)',
    blueBackground2: 'rgba(10, 151, 255, 0.06)',
    link: '#0a97ff',
    starYellow: '#ffda00',
    green: '#43d350',
    paleGreen: '#A0F89D',
    red: '#ff283a',
    mutedRed: '#e54a45',
    linkedin: '#0075b6',
    twitter: '#00abf1',
    facebook: '#39579d',
    googleplus: '#da3f26',
    instagram: '#C13584'
};

const fontSizes = {
    plusTiny: 7,
    tiny: 11,
    smallest: 12,
    smaller: 13,
    normal: 14,
    large: 16,
    larger: 18,
    largest: 20,
    huge: 24
};

const zOrder = {
    menus: 100
};

class ToggleFavoriteBtn extends React.Component {
    render() {
        const favorite = this.props.isFavorite;
        const css = `
            .style-individual-no-fav-star {
                color: ${colors.faintGrey2};
                transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            .style-individual-no-fav-star:hover {
                color: ${colors.starYellow};
            }
        `;
        const receivedStyle = this.props.style ? this.props.style : {};
        const noFavStarStyle = { opacity: favorite ? 0 : 1 };
        const favStarStyle = _.extend(
            {
                position: 'absolute',
                top: 0,
                color: colors.starYellow,
                transition: 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'
            },
            favorite ? {
                opacity: 1,
                transform: 'scale(1)'
            } : {
                opacity: 0,
                transform: 'scale(5)',
                visibility: 'hidden'
            }
        );

        return (

            <li
                style={{
                    display: 'inline-block',
                    position: 'relative',
                    cursor: 'pointer',
                    verticalAlign: 'middle'
                }}>

                <style>{css}</style>
                <i className="icon-star style-individual-no-fav-star"
                    style={_.extend({}, receivedStyle, noFavStarStyle)}
                    onClick={this.props.onToggleFavorite} />
                <i className="icon-star2"
                    style={_.extend({}, receivedStyle, favStarStyle)}
                    onClick={this.props.onToggleFavorite} />
            </li>

        );
    }
}

const headerStyle = {
    position: 'absolute',
    left: 60,
    right: 50,
    textAlign: 'center',
    whiteSpace: 'nowrap'
};

const headerBtnStyle = {
    float: 'left',
    fontSize: fontSizes.larger,
    lineHeight: '42px',
    height: 42,
    color: colors.faintGrey2
};

const closeBtnStyle = _.extend(
    {},
    headerBtnStyle,
    { color: colors.link, cursor: 'pointer' }
);

const headerCenterListItemStyle = {
    display: 'block',
    height: 42,
    padding: '0 8px',
    lineHeight: '42px'
};

const headerOwnerNameStyle = _.extend(
    {
        fontSize: fontSizes.smaller,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: colors.faintGrey2,
        cursor: 'default'
    },
    headerCenterListItemStyle
);

// quick way to replicate the behaviour of hiding the close button
// when the individual view is visible in the context of search results
const closeButtonHideStyle = `
    .app-search-browse .style-individual-close-button {
        display: none;
    }
`;

class DetailViewHeader extends React.Component {

    render() {
        const onEdit = this.props.isProtected ? null : this.props.onEdit;
        const onShowPermission = this.props.isProtected ? null : this.props.onShowPermissionView;
        const editBtnStyle = _.extend({}, headerBtnStyle, onEdit ?
            { float: 'right', color: colors.link, cursor: 'pointer' } :
            { float: 'right', cursor: 'default' }
        );
        const permBtnStyle = _.extend({}, headerCenterListItemStyle, onShowPermission ?
            { color: colors.link, cursor: 'pointer' } :
            { color: colors.faintGrey2 }
        );
        let permTooltip = 'Show Permissions';
        if (this.props.isProtected) {
            permTooltip = "You can't modify the permissions";
        }
        else if (!this.props.isPermissionsEnabled) {
            permTooltip = 'Upgrade account to access Permissions';
        }
        else if (!this.props.onEdit) {
            permTooltip = "You don't have permission to view permissions";
        }

        return (
            <div className={styles.fixedHeaderView}>
                <header style={{ position: 'relative', margin: '9px 24px' }}>
                    <style>{closeButtonHideStyle}</style>
                    <a
                        style={closeBtnStyle}
                        onClick={this.props.onClose}
                        className="style-individual-close-button">Close</a>
                    <ul style={headerStyle}>
                        <ToggleFavoriteBtn
                            isFavorite={this.props.isFavorite}
                            onToggleFavorite={this.props.onToggleFavorite}
                            style={headerCenterListItemStyle}
                        />
                        <li className={styles.duplicateButton} onClick={this.props.onDuplicateClick}>
                            <i className='icon-copy'/>
                        </li>
                        <li
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle'
                            }}>

                            <i
                                title={permTooltip}
                                style={permBtnStyle}
                                onClick={onShowPermission}
                                className="icon-locked" />
                        </li>
                        <li
                            style={{
                                maxWidth: 'calc(100% - 62px)',
                                display: 'inline-block',
                                verticalAlign: 'middle'
                            }}>
                            <span style={headerOwnerNameStyle}>{this.props.owner}</span>
                        </li>
                    </ul>
                    <a style={editBtnStyle} onClick={onEdit}>Edit</a>
                    <div style={{clear: 'both'}}></div>
                </header>
            </div>
        );
    }
}

const imageContainerStyle = {
    position: 'relative',
    margin: '0 auto',
    width: 100,
    height: 100
};

const imageBoxStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '50%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
};

const imagePlaceholderStyle = _.extend(
    {
        border: '1px solid ' + colors.faintGrey,
        textAlign: 'center'
    },
    imageBoxStyle
);

const imagePlaceholderIconStyle = {
    lineHeight: '96px',
    fontSize: fontSizes.huge,
    color: colors.faintGrey
};

class DetailContactPicture extends React.Component {
    render() {
        return (

            <div style={imageContainerStyle}>
                <div style={imagePlaceholderStyle}>
                    <i className="icon-user" style={imagePlaceholderIconStyle} />
                </div>
                {this.props.imageUrl &&
                    <div style={_.extend({backgroundImage: 'url(' + this.props.imageUrl + ')'}, imageBoxStyle)} />
                }
            </div>

        );
    }
}

const contactNameStyle = {
    margin: '20px 0 0',
    fontSize: fontSizes.largest
};

const contactRoleStyle = {
    display: 'block',
    color: colors.faintGrey2
};

const contactOrgStyle = {
    display: 'block'
};

const detailNavStyle = {
    margin: '0 24px',
    textAlign: 'center',
    boxShadow: '0 1px 0 ' + colors.faintestGrey
};

const detailNavItemStyle = {
    display: 'inline-block',
    margin: '0 9px',
    height: 42,
    fontSize: fontSizes.large,
    lineHeight: '43px',
    verticalAlign: 'bottom',
    cursor: 'pointer'
};

const moreInfoHeaderStyle = {
    margin: '0 24px',
    lineHeight: '42px',
    fontSize: fontSizes.tiny,
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.faintGrey2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
};

const DragHandle = SortableHandle(() => <i className={classnames({ "icon-drag": true, [styles.handle]: true })} />);

const SortableItem = SortableElement(({data}) =>
    <div className={classnames({[styles.SortableItem]: true, [styles.Hidden]: data.hidden})}><CustomFieldGroup key={data.id} data={data}/></div>
);

const SortableList = SortableContainer(({items}) => {
  return (
    <div className={styles.SortableContainer}>
      {items.map((value, index) => (
        <SortableItem key={"item-" + index} index={index} data={value} />
      ))}
    </div>
  );
});

class SortableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const items = arrayMove(prevState.items, oldIndex, newIndex);
            items[0].updateOrder(items);
            return { items: items };
        });
    };

    render() {
        return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis={"y"} />;
    }
}

class CustomFieldGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.data.state !== 'expanded'
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const newState = this.state.collapsed ? "expanded" : "collapsed";
        this.props.data.update(this.props.data.id, { state: newState });
        this.setState((prevState) => {
            return { collapsed: !prevState.collapsed };
        });
    }

    render() {
        if (this.props.data.fields.length === 0) {
            return null;
        }

        let customFields = _.compact(_.map(
            this.props.data.fields,
            (item) => {
                if (!item.value && !item.originalValue && !_.isNumber(item.value) && !_.isBoolean(item.value)) {
                    return (null);
                }

                if (item.type === 'dropDown' && item.params && item.params.multiSelect){
                    let values = [];
                    item.originalValue.split(',').map(valueId => {
                        values.push(item.options.find(opt => opt.id === valueId).value || '');
                    })
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={values.join(', ')} />
                    );
                }

                if (item.type === 'date') {
                    return (
                        <TextField
                            key={item.id}
                            label={item.label}
                            value={dateFormat.entityInformationFormat(dateFormat.createDateIgnoringTZ(item.value))} />
                    );
                }
                else if (item.type === 'checkbox') {
                    if (item.value || AppConfig.getValue('custom_fields.overview.show_false_checkboxes')) {
                        return <CheckboxField key={item.id} label={item.label} value={item.value} />;
                    }

                    return null;
                }
                else if (item.type === 'individual') {
                    return (
                        <IndividualField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId}
                            photoUrl={item.photoUrl}
                            organizationName={item.organizationName} />
                    );
                }
                else if (item.type === 'organization') {
                    return (
                        <OrganizationField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId} />
                    );
                }
                else if (item.type === 'opportunity') {
                    return (
                        <OpportunityField
                            key={item.id}
                            label={item.label}
                            name={item.value}
                            itemId={item.itemId} />
                    );
                }
                else if (item.type === 'urlImage') {
                    return (
                        <ImageField
                            key={item.id}
                            label={item.label}
                            url={item.value} />
                    );
                }
                else if (item.type === 'url') {
                    return (
                        <UrlField
                            key={item.id}
                            label={item.label}
                            url={item.value}
                            params={item.params} />
                    );
                }
                else if (item.type === 'paragraph') {
                    return (
                        <TextField key={item.id} label={item.label} isParagraph={true} value={item.value} />
                    );
                }
                else if (item.type === 'list') {
                    return (
                        <EntityListField
                            key={item.id}
                            label={item.label}
                            value={item.value}
                        />
                    );
                }
                return (
                    <TextField key={item.id} label={item.label} value={item.value} />
                );
            }
        ));

        if (customFields.length === 0) {
            return null;
        }

        const toggleClasses = classnames({
            "icon-caret-right": this.state.collapsed,
            "icon-caret-down": !this.state.collapsed,
            [styles.toggle]: true
        });

        return (
            <div className={styles.GroupContainer}>
                <div className={styles.CustomFieldGroup}>
                    <div className={styles.header}>
                        <i className={toggleClasses} onClick={this.toggle} />
                        {this.props.data.name}
                        <DragHandle />
                    </div>
                    {
                        !this.state.collapsed &&
                        <div className={styles.container}>
                            {customFields}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class IndividualView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'details',
            individualDealsSelection: {},
            organizationDealsSelection: {},
        };

        this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
        this.handleDetailsClick = this.handleDetailsClick.bind(this);
        this.handleInfoClick = this.handleInfoClick.bind(this);
        this.showIndividualDeals = this.showIndividualDeals.bind(this);
        this.showOrganizationDeals = this.showOrganizationDeals.bind(this);
        this.handleDealSelect = this.handleDealSelect.bind(this);
        this.handleAllDealsToggle = this.handleAllDealsToggle.bind(this);
        this.handleIndividualSelectionClear = this.handleIndividualSelectionClear.bind(this);
        this.handleOrganizationSelectionClear = this.handleOrganizationSelectionClear.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        this.documentDragHandler = (e) => {
            e.preventDefault();
        };
        if (typeof document !== 'undefined') {
            document.addEventListener('dragover', this.documentDragHandler);
            document.addEventListener('drop', this.documentDragHandler);
        }
    }

    componentWillUnmount() {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        if (typeof document !== 'undefined') {
            document.removeEventListener('dragover', this.documentDragHandler);
            document.removeEventListener('drop', this.documentDragHandler);
        }
    }

    handleOrganizationClick(ev) {
        ev.preventDefault();
        this.props.onOrganizationClick();
    }

    handleDetailsClick() {
        this.setState({activeTab: 'details' });
    }

    handleInfoClick() {
        this.setState({activeTab: 'info' });
    }

    showIndividualDeals() {
        this.setState({dealsTab: 'individual'});
    }

    showOrganizationDeals() {
        this.setState({dealsTab: 'organization'});
    }

    handleDealSelect(selectionKey, item) {
        this.setState((prevState, props) => {
            const ext = {};
            const out = {};
            let newSelection;

            if (prevState[selectionKey][item.id]) {
                newSelection = _.clone(prevState[selectionKey]);
                delete newSelection[item.id];
            }
            else {
                ext[item.id] = true;
                newSelection = _.extend({}, prevState[selectionKey], ext);
            }
            out[selectionKey] = newSelection;
            return out;
        });
    }

    handleAllDealsToggle(selectionKey) {
        this.setState((prevState, props) => {
            const deals = selectionKey === "individualDealsSelection" ?
                    this.props.relatedData.deals :
                    this.props.relatedData.organizationDeals;
            const out = {};

            if (_.size(prevState[selectionKey]) === deals.items.length) {
                out[selectionKey] = {};
            }
            else {
                out[selectionKey] = _.object(_.map(deals.items  , deal => [deal.id, true]));
            }
            return out;
        });
    }

    handleIndividualSelectionClear() {
        this.setState({
            individualDealsSelection: {}
        });
    }

    handleOrganizationSelectionClear() {
        this.setState({
            organizationDealsSelection: {}
        });
    }

    handleDragEnter(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        // track child elements entered and left during a drag operation
        // as a reliable way to detect dragging over the top-level element
        // http://stackoverflow.com/questions/10253663/how-to-detect-the-dragleave-event-in-firefox-when-dragging-outside-the-window/10310815#10310815
        // http://stackoverflow.com/questions/14194324/firefox-firing-dragleave-when-dragging-over-text
        if (this.dragOverNodes == undefined) {
            this.dragOverNodes = [];
        }
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: true
            });
        }
        // using nativeEvent here is essential. Use of React's SyntheticEvent will result
        // in a failure in Firefox when dragging over text nodes
        this.dragOverNodes = _.union(this.dragOverNodes, [e.nativeEvent.target]);
    }

    handleDragLeave(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        this.dragOverNodes = _.without(this.dragOverNodes, e.nativeEvent.target);
        if (this.dragOverNodes.length === 0) {
            this.setState({
                dragOver: false
            });
        }
    }

    handleDragOver(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        e.preventDefault();
    }

    handleDrop(e) {
        if (!this.props.isEditable || this.props.isProtected) {
            return;
        }
        e.preventDefault();
        this.props.onDnDFileUpload(e.dataTransfer.files);
        this.setState({
            dragOver: false
        });
        this.dragOverNodes = [];
    }

    render() {
        const orgId = this.props.individual.organization ? this.props.individual.organization.id : null;
        const orgName = this.props.individual.organization ? this.props.individual.organization.name : null;
        const infoTabColor = this.state.activeTab === 'details' ? colors.faintGrey2 : colors.darkGrey;
        const detailsTabColor = this.state.activeTab === 'details' ? colors.darkGrey : colors.faintGrey2;
        const showMessageOptedIn = AppConfig.getValue('enableTextMessaging');
        let dealsTab = this.state.dealsTab ? this.state.dealsTab : '';
        const {checklists} = this.props
        if (!dealsTab &&
            this.props.relatedData.organizationDeals &&
            this.props.relatedData.organizationDeals.items.length &&
            (!this.props.relatedData.deals || !this.props.relatedData.deals.items.length)) {
            dealsTab = 'organization';
        }
        if (!dealsTab) {
            dealsTab = 'individual';
        }
        let phoneNumbers = _.map(
            _.where(this.props.individual.communication, {medium: 'phone'}),
            (item) =>
                <CommunicationField
                    key={item.id}
                    medium={item.medium}
                    name={item.name}
                    value={item.value}
                    comments={item.comments}
                    href={item.href}
                    smsActive={!this.props.individual.unsubscribed_all_messages}
                    onClick={(ev, action) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        this.props.onCommunicationClick(item.id, item.href, action);
                    }} />
        );

        const enableContactEmailTemplating = AppConfig.getValue('enableContactEmailTemplating', false);
        const individualEmails = _.where(this.props.individual.communication, {medium: 'email'});
        const showAllEmailButton = AppConfig.getValue('individuals.overview.communications.all_email_button.visible', false) && individualEmails && individualEmails.length > 1;
        let allEmailButton = null

        if (showAllEmailButton){
            const values = individualEmails.map(email => email.value)
            let href = `mailto:${values.join(',')}`

            if(!AppConfig.getValue('disableArchiveBCCLink', false)){
                const archive_email = 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
                href += "?bcc=" + archive_email;
            }

            allEmailButton = (
                <div className={styles.AllEmailButtonContainer}>
                    <SendAllEmailButton
                        href={href}
                        onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            this.props.onCommunicationClick(null, href, null, individualEmails.map(email => email.id));
                        }}
                    />
                </div>
            )
        }



        let emailAddresses = _.map(individualEmails,
            (item) => {
                let showEmailTemplatingSection = false;

                if (item.value) {
                    showEmailTemplatingSection = true;
                }

                return (
                    <div key={item.id}>
                        <CommunicationField
                            medium={item.medium}
                            name={item.name}
                            value={item.value}
                            comments={item.comments}
                            href={item.href}
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                this.props.onCommunicationClick(item.id, item.href);
                            }}
                        />
                        {showEmailTemplatingSection && enableContactEmailTemplating &&
                            <EmailTemplating
                                entityName={this.props.individual.full_name}
                                entityId={this.props.individual.id}
                                value={item.value}
                            />
                        }
                    </div>
                );
            }
        );

        let socialLinks = _.map(
            _.where(this.props.individual.communication, {medium: 'social'}),
            (item) => {
                if((item.name !== 'instagram' || AppConfig.getValue('hasInstagramSocial')) && item.name !== 'googleplus'){
                    return <CommunicationField
                        key={item.id}
                        medium={item.medium}
                        name={item.name}
                        value={item.value}
                        comments={item.comments}
                        href={item.href} />
                }
            }
        );

        let locations = null;

        if (!AppConfig.getValue('disableLocationsSectionOnIndividualAndOrganization')) {
            locations = _.map(
                this.props.individual.locations,
                (item) =>
                    <LocationField
                        key={item.id}
                        label={item.name}
                        location={item.address}
                        comments={item.comments} />
            );
        }

        let sortableFieldGroups;
        if (this.props.relatedData.processedCustomFields) {
            // we only want groups with fields having a valid value
            var groups = _.compact(this.props.relatedData.processedCustomFields.map(group => {
                for (var i = 0; i < group.fields.length; ++i) {
                    if ('value' in group.fields[i]) {
                        if ((group.fields[i].type !== 'checkbox') || group.fields[i].value) {
                            return group;
                        }
                    }
                }
                return null;
            }));

            sortableFieldGroups = <SortableComponent items={groups} />
        }

        const showTags = AppConfig.getValue('individuals.overview.show_tags');

        return (

            <div className={styles.IndividualView}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}>
                <div className={styles.innerContainer}>
                    <DetailViewHeader
                        isFavorite={this.props.individual.is_favorite}
                        isPermissionsEnabled={this.props.isPermissionsEnabled}
                        isProtected={this.props.isProtected}
                        owner={this.props.individual.owner.name}
                        onToggleFavorite={this.props.onToggleFavorite}
                        onShowPermissionView={this.props.onShowPermissionView}
                        onEdit={this.props.onEdit}
                        onClose={this.props.onClose}
                        onDuplicateClick={this.props.onDuplicateClick}
                    />
                    <div className={styles.contentPane}>
                        <section style={{padding: 24, textAlign: 'center'}}>
                            <DetailContactPicture imageUrl={this.props.individual.photo_url} />
                            <h3 style={contactNameStyle}>{this.props.individual.full_name}</h3>
                            {this.props.individual.role &&
                                <span style={contactRoleStyle}>{this.props.individual.role}</span>
                            }
                            {orgId &&
                                <a
                                    href={'#organizations/' + orgId}
                                    style={contactOrgStyle}
                                    onClick={this.handleOrganizationClick}>
                                    {orgName}
                                </a>
                            }
                            {showTags && <TagList tags={this.props.individual.tags} />}
                            {checklists && checklists.length > 0 && <ChecklistsProgress checklists={checklists}/>}
                        </section>
                    </div>
                    <nav style={detailNavStyle}>
                        <ul>
                            <li style={_.extend({color: infoTabColor}, detailNavItemStyle)}
                                onClick={this.handleInfoClick}>
                                <i className="icon-info" style={{fontSize: fontSizes.larger, lineHeight: 'inherit'}} />
                            </li>
                            <li style={_.extend({color: detailsTabColor}, detailNavItemStyle)}
                                onClick={this.handleDetailsClick}>Details</li>
                        </ul>
                    </nav>
                    <div className={styles.contentPane}>
                        {this.state.activeTab === 'details' ?
                            <section style={{padding: 24}}>
                                {phoneNumbers}
                                {allEmailButton}
                                {emailAddresses}
                                <CheckboxField label={TextManager.getText('ID_EMAIL_OPTED_IN')} value={!this.props.individual.unsubscribed_all} />
                                {showMessageOptedIn && <CheckboxField label={TextManager.getText('ID_TEXTING_OPTED_IN')} value={!this.props.individual.unsubscribed_all_messages} />}
                                {socialLinks}
                                {locations}
                                {this.props.individual.source &&
                                    <TextField
                                        label={TextManager.getText('ID_SOURCE')}
                                        value={this.props.individual.source.name} />
                                }
                                {sortableFieldGroups}
                                {this.props.onShowDocusignView &&
                                    <DocusignField
                                        onShowDocusignView={this.props.onShowDocusignView} />
                                }
                                <IndividualDeals
                                    individualDeals={this.props.relatedData.deals}
                                    organizationDeals={this.props.relatedData.organizationDeals}
                                    onClickNext={() => {
                                        dealsTab === 'individual' && this.handleIndividualSelectionClear();
                                        dealsTab === 'organization' && this.handleOrganizationSelectionClear();
                                        this.props.onClickNextDealPage(
                                            dealsTab === 'individual' ? 'deals' : 'organizationDeals'
                                        );
                                    }}
                                    onClickPrev={() => {
                                        dealsTab === 'individual' && this.handleIndividualSelectionClear();
                                        dealsTab === 'organization' && this.handleOrganizationSelectionClear();
                                        this.props.onClickPrevDealPage(
                                            dealsTab === 'individual' ? 'deals' : 'organizationDeals'
                                        );
                                    }}
                                    individualSelection={this.state.individualDealsSelection}
                                    organizationSelection={this.state.organizationDealsSelection}
                                    onIndividualDealSelect={(item) => {
                                        this.handleDealSelect("individualDealsSelection", item);
                                    }}
                                    onOrganizationDealSelect={(item) => {
                                        this.handleDealSelect("organizationDealsSelection", item);
                                    }}
                                    onAllIndividualToggle={() => {
                                        this.handleAllDealsToggle("individualDealsSelection");
                                    }}
                                    onAllOrganizationToggle={() => {
                                        this.handleAllDealsToggle("organizationDealsSelection");
                                    }}
                                    onIndividualSelectionClear={this.handleIndividualSelectionClear}
                                    onOrganizationSelectionClear={this.handleOrganizationSelectionClear}
                                    onDealNavigate={this.props.onDealNavigate}
                                    tab={dealsTab}
                                    onShowIndividualDeals={this.showIndividualDeals}
                                    onShowOrganizationDeals={this.showOrganizationDeals}
                                    onAdd={this.props.onDealsAdd}
                                    onNew={this.props.onNewDeal}
                                    onDelete={this.props.onDealsDelete} />
                                <RelatedFiles
                                    files={this.props.relatedData.files}
                                    onClickNext={this.props.onClickNextFilePage}
                                    onClickPrev={this.props.onClickPrevFilePage}
                                    userId={this.props.userId}
                                    isEditable={this.props.isEditable}
                                    isProtected={this.props.isProtected}
                                    onAdd={this.props.onFileSelect}
                                    onUpload={this.props.onDnDFileUpload}
                                    onDelete={this.props.onFileDelete}
                                    fileUpload={this.props.relatedData.fileUpload} />
                                <GroupMembership
                                    items={this.props.relatedData.groups}
                                    onSearch={this.props.onGroupSearch}
                                    onAdd={this.props.onGroupAdd}
                                    onRemove={this.props.onGroupRemove}
                                    onGroupClick={this.props.onGroupClick} />
                                <ArchiveAddress email={this.props.archiveEmailAddress} />
                            </section>
                            :
                            <section style={{padding: 24}}>
                                <h5 style={moreInfoHeaderStyle}>Additional Information</h5>
                                <TextField
                                    label={TextManager.getText('ID_MORE_INFO')}
                                    isParagraph="true"
                                    value={this.props.individual.comments || 'No information to display'} />
                            </section>
                        }
                    </div>
                </div>
                {this.state.dragOver &&
                    <DragAndDropOverlay />
                }
            </div>

        );
    }
}

export default IndividualView;

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import vent from 'js/vent';
import Table from 'app_v2/components/table/table';
import dateFormat from 'js/utils/date-format';
import Utilities from 'js/utils/utilities';

import style from './appointments.css';


const TABLE_COLUMNS = [{
    id: 'startDate',
    title: 'Date',
    type: 'text',
    width: 70
}, {
    id: 'startTime',
    title: 'Time',
    type: 'text',
    width: 55
}, {
    id: 'type',
    title: 'Type',
    type: 'text',
    greedyWidth: true
}, {
    id: 'status',
    title: 'Status',
    type: 'text',
    width: 85,
    color: function(row) {
        switch (row.status.toLowerCase()) {
            case 'pending':
                return '#F5A427';

            case 'cancelled':
                return '#E34B4A';
        }

        return '#43D350';
    }
}];


class Appointments extends React.Component {
    componentDidMount() {
        this.mounted = true;

        const self = this;

        this.props.parent.listenTo(vent, 'appointment:created appointment:updated', function() {
            self.fetchData();
        });

        this.fetchData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onActive() {
        const self = this;

        _.defer(function() {
            self.upcomingTable.adjustColumnsWidth();
            self.pastTable.adjustColumnsWidth();
        });

        this.fetchData();
    }

    fetchData() {
        const self = this;

        this.upcomingTable.setLoading(true);
        this.pastTable.setLoading(true);

        let allRows = [];
        let numFetches = 0;

        const processRows = () => {
            if (numFetches > 0) {
                return;
            }

            let upcoming = [];
            let past = [];
            const now = new Date();

            allRows = _.sortBy(_.uniq(allRows, false, r => r.id), 'start_date');

            for (const row of allRows) {
                const startDate = dateFormat.parseDate(row.start_date);
                const endDate = dateFormat.parseDate(row.end_date);

                const appointment = {
                    id: row.id,
                    startDate: `${dateFormat.shortFormatMonth(startDate.getMonth())} ${startDate.getDate()}`,
                    startTime: dateFormat.shortFormatTime(startDate),
                    type: row.appointment_type,
                    status: Utilities.capitalize(row.status),
                    realStatus: row.status
                };

                if (now > endDate) {
                    past.push(appointment);
                } else {
                    upcoming.push(appointment);
                }
            }

            self.upcomingTable.setData(upcoming, 0, 0, upcoming.length, upcoming.length);
            self.upcomingTable.setLoading(false);

            self.pastTable.setData(past, 0, 0, past.length, past.length);
            self.pastTable.setLoading(false);

            self.props.badge.toggleClass('hidden', !upcoming.find(u => u.realStatus === 'pending'));
        }

        // for individuals we search for appointments for which the individual is the primary attendee
        if (this.props.type === 'individual') {
            ++numFetches;

            const indArgs = {
                rows: -1,
                guest_role: 'primary',
                guest_id: this.props.model.get('id')
            };

            $.get(`/appointments?${$.param(indArgs)}`, function(result) {
                if (!self.mounted) {
                    return;
                }

                --numFetches;
                allRows = [...allRows, ...result];
                processRows();
            });
        }

        // ...
        ++numFetches;

        const args = {
            rows: -1,
            related_id: this.props.model.get('id'),
            related_type: this.props.type
        };

        $.get(`/appointments?${$.param(args)}`, function(result) {
            if (!self.mounted) {
                return;
            }

            --numFetches;
            allRows = [...allRows, ...result];
            processRows();
        });
    }

    handleNewClick() {
        // for individuals the model is 'primary_attendee' instead 'related'
        const options = {};

        if (this.props.type === 'individual') {
            options.primaryAttendee = this.props.model.toJSON();
        } else {
            options.related = this.props.model.toJSON();
        }

        vent.trigger('quick:add:appointment', options);
    }

    handleRowClick(rowId) {
        vent.trigger('quick:edit:appointment', rowId);
    }

    render() {
        return (
            <div className={style.appointments}>
                <div className={style.aSection}>
                    <div className={style.sHeader}>
                        <div className={style.hTitle}>Upcoming Appointments</div>
                        <div
                            className={style.hButton}
                            onClick={this.handleNewClick.bind(this)}
                        >
                            New
                        </div>
                    </div>

                    <div className={style.sContent}>
                        <Table
                            ref={(el) => this.upcomingTable = el}
                            parent={this.props.parent}
                            hasPaginator={true}
                            columns={TABLE_COLUMNS}
                            rowsClickables={true}
                            onRowClick={this.handleRowClick.bind(this)}
                        />
                    </div>
                </div>

                <div
                    className={style.aSection}
                    style={{marginTop: '40px'}}
                >
                    <div className={style.sHeader}>
                        <div className={style.hTitle}>Past Appointments</div>
                    </div>

                    <div className={style.sContent}>
                        <Table
                            ref={(el) => this.pastTable = el}
                            parent={this.props.parent}
                            hasPaginator={true}
                            columns={TABLE_COLUMNS}
                            rowsClickables={true}
                            onRowClick={this.handleRowClick.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const AppointmentsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        this.$el.css('height', '100%');
        this.renderReact();
    },
    onActive: function() {
        this.component.onActive();
    },
    renderReact() {
        ReactDOM.render(
            <Appointments
                ref={(el) => this.component = el}
                parent={this}
                type={this.options.type}
                model={this.options.model}
                badge={this.options.badge}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default AppointmentsView;

import _ from 'underscore'
import React from 'react'

import app from 'js/app'
import vent from 'js/vent'
import Currency from 'js/utils/currency'
import Utilities from 'js/utils/utilities'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import ActivityCreator from 'js/utils/activity_creator'
import CommunicationModel from 'js/models/communication'
import Tooltip from 'js/react_views/tooltip/tooltip'
import {NewSelect} from 'js/react_views/widgets/select'

import style from './plot_cards.css'


const NUM_ROWS_PER_FETCH = 10


class LoaderTrigger extends React.Component {
    componentDidMount() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);

        this.observer.observe(this.loadingRef);
    }

    handleObserver() {
        this.props.fetchData();
    }

    isVisible() {
        const parent = $(this.loadingRef).closest(`.${style.plotCards}`);
        const pbbox = parent[0].getBoundingClientRect();
        const mybbox = this.loadingRef.getBoundingClientRect();

        if (Utilities.rectsIntersect(pbbox.x, pbbox.y, pbbox.width, pbbox.height, mybbox.x, mybbox.y, mybbox.width, mybbox.height)) {
            return true;
        }

        return false;
    }

    render() {
        return (
            <div
                ref={(el) => this.loadingRef = el}
                className={style.lLoadingRef}
            />
        );
    }
}

class PlotCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingContact: false,
            contact: null
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchContact();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchContact(force) {
        this.setState({
            loadingContact: true
        });

        const self = this;

        this.props.widget.fetcher.get(`/opportunities/${this.props.deal.id}/individuals`, {
            start: 0,
            rows: 1,
            order_by: 'full_name asc'
        }, function(data) {
            if (self.mounted) {
                let newState = {
                    loadingContact: false
                };

                if (data && data.length > 0) {
                    const contact = data[0];
                    const phone = contact.communication.find(c => c.medium === 'phone');
                    const email = contact.communication.find(c => c.medium === 'email');

                    let contactData = {
                        id: contact.id,
                        name: contact.full_name
                    };

                    if (phone) {
                        contactData.phone = new CommunicationModel(phone);
                    }

                    if (email) {
                        contactData.email = new CommunicationModel(email);
                    }

                    newState.contact = contactData;
                }

                self.setState(newState);
            }
        }, {force: !!force});
    }

    getFieldValue(field) {
        const deal = this.props.deal;
        const pIdx = field.indexOf('.');
        const fieldType = field.substring(0, pIdx);
        const fieldId = field.substring(pIdx + 1).toLowerCase();

        if (fieldType === 'bucket') {
            const bucket = deal.buckets.find(b => b.name.toLowerCase() === fieldId);

            if (!bucket) {
                return null;
            }

            return {
                name: bucket.name,
                value: bucket.value
            }
        }

        if (fieldType === 'custom_field') {
            const cf = app.globalData.customFieldsInfo.dealsArray.find(c => c.name.toLowerCase() === fieldId);

            if (!cf) {
                return null;
            }

            const value = deal[`custom_field.${cf.id}`];

            if (value || _.isNumber(value)) {
                return {
                    name: cf.name,
                    value: value
                }
            }
        }

        return null;
    }

    getPrice(field) {
        const deal = this.props.deal;
        const value = this.getFieldValue(field);

        if (!value) {
            return null;
        }

        return (
            <div
                key={field}
                className={style.dPrice}
            >
                <div className={style.fValue}>{Currency.format(deal.currency, value.value)}</div>
                <div
                    className={style.fName}
                >
                    <Tooltip
                        title={value.name}
                    />
                    {value.name}
                </div>
            </div>
        );
    }

    getFeatures(features) {
        let lines = [];

        for (const feature of features) {
            const value = this.getFieldValue(feature[1]);

            if (!value) {
                continue;
            }

            lines.push(
                <div
                    key={feature[1]}
                    className={style.fItem}
                >
                    {feature[0] && <div className={`${style.iIcon} ${feature[0]}`}/>}
                    <div className={style.iValue}>{value.value}</div>
                </div>
            );
        }

        if (lines.length > 0) {
            return (
                <div
                    key={`features_${this.props.deal.id}`}
                    className={style.dFeatures}
                >
                    {lines}
                </div>
            );
        }

        return null;
    }

    getDescription() {
        const params = this.props.params;

        if (!params.prices && !params.features) {
            return [];
        }

        let lines = [];

        for (const price of params.prices) {
            let p = this.getPrice(price);

            if (p) {
                lines.push(p);
            }
        }

        let f = this.getFeatures(params.features);

        if (f) {
            lines.push(f)
        }

        return lines;
    }

    handleCommunicationClick(medium) {
        const comm = this.state.contact[medium];

        const activity = ActivityCreator.createAutoCommunication(medium, comm, 'individuals', this.state.contact.id);

        if (activity) {
            activity.save({}, {
                complete: function() {
                    vent.trigger('update:activity');
                }
            });
        }

        window.open(comm.valueHref());
    }

    handleAddContactClick() {
        this.selectComponent.toggleExpanded(true);
    }

    handleContactSelection(items) {
        const contact = items[0];
        const self = this;

        $.ajax({
            url: `/opportunities/${this.props.deal.id}/individuals`,
            method: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify([{id: contact.id}]),
            success: function() {
                self.fetchContact(true);
            }
        });
    }

    getContactInfo() {
        if (this.state.loadingContact) {
            return (
                <div className={style.cLoadingContact}>
                    <LoadingIndicator/>
                </div>
            );
        }

        if (this.state.contact) {
            const contact = this.state.contact;

            return (
                <div className={style.cContact}>
                    <div
                        className={style.cName}
                    >
                        <Tooltip
                            title={contact.name}
                        />
                        {contact.name}
                    </div>

                    <div className={style.cActions}>
                        {contact.phone &&
                            <div
                                className={style.aItem}
                                onClick={() => this.handleCommunicationClick.bind(this)('phone')}
                            >
                                <div className='icon-phone'/>
                            </div>
                        }

                        {contact.email &&
                            <div
                                className={style.aItem}
                                onClick={() => this.handleCommunicationClick.bind(this)('email')}
                            >
                                <div className='icon-email'/>
                            </div>
                        }

                        <div
                            className={style.aButton}
                            onClick={() => this.props.widget.triggerEvent('showEntity', {
                                type: 'individuals',
                                id: contact.id,
                                widget: this.props.widget,
                                rectState: 'leftDocked'
                            })}
                        >
                            <div>Open details</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className={style.cAddContactContainer}>
                <div
                    className={style.cAddContact}
                    onClick={this.handleAddContactClick.bind(this)}
                >
                    <div className='icon-plus'/>
                </div>

                <div
                    className={style.cDropdown}
                >
                    <NewSelect
                        ref={(el) => this.selectComponent = el}
                        url='/individuals'
                        options={{minimumInputLength: 1}}
                        width={182}
                        boxStyle={{
                            visibility: 'hidden'
                        }}
                        onSelect={this.handleContactSelection.bind(this)}
                    />
                </div>
            </div>
        );
    }

    render() {
        const deal = this.props.deal;

        let barStyle = {
            width: (deal.phase_number + 1) / this.props.numPhases * 100
        };

        if (this.props.phaseColors) {
            const pc = this.props.phaseColors.find(c => c.name.toLowerCase() === deal.phase_name.toLowerCase());

            if (pc) {
                barStyle.background = pc.hex;

                if (pc.font_hex) {
                    barStyle.color = pc.font_hex;
                }
            }
        }

        return (
            <div className={style.pPlotCard}>
                <div
                    className={style.cTitle}
                >
                    <Tooltip
                        title={deal.name}
                    />
                    {deal.name}
                </div>

                <div className={style.cDescription}>
                    {this.getDescription().map(l => l)}
                </div>

                <div className={style.cSeparator}/>

                {this.getContactInfo()}

                <div className={style.cPhase}>
                    <div
                        className={style.pBar}
                        style={barStyle}
                    />
                    <div
                        className={style.pName}
                    >
                        <Tooltip
                            title={deal.phase_name}
                        />
                        {deal.phase_name}
                    </div>
                </div>
            </div>
        );
    }
}


class PlotList extends React.Component {
    constructor(props) {
        super(props);

        const self = this;

        this.props.widget.addEventListener('funnel:change', function() {
            if (self.mounted) {
                self.fetchStart = 0;
                self.noMoreDeals = false;

                self.fetchPhases();
                self.setState({
                    deals: [],
                    filteredDeals: []
                });
            }
        });

        this.props.widget.addEventListener('plotCards:setPhase', function(params) {
            if (self.mounted) {
                self.filterByPhaseId = params.phaseId;
                self.filterDeals();
            }
        });

        this.state = {
            deals: [],
            filteredDeals: [],
            numPhases: 0,
            loading: false
        };

        this.fetchStart = 0;
        this.filterByPhaseId = null;
        this.noMoreDeals = false;
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchPhases();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchPhases() {
        const self = this;

        this.props.widget.fetcher.get('/phases', {
            start: 0,
            rows: -1,
            funnel_id: this.props.widget.fetcher.activeFunnel.id
        }, function(data) {
            if (!self.mounted) {
                return;
            }

            self.setState({
                numPhases: data.length - 2 // 2 = won & lost phases
            });
        });
    }

    fetchData() {
        if (!this.mounted || this.noMoreDeals || this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        });

        const self = this;

        this.props.widget.fetcher.get('/opportunities', {
            start: this.fetchStart,
            rows: NUM_ROWS_PER_FETCH,
            order_by: 'name asc',
            funnel_id: this.props.widget.fetcher.activeFunnel.id,
            by_period_id: 'current'
        }, function(data, paginationInfo) {
            if (!self.mounted) {
                return;
            }

            let newState = {
                loading: false
            };

            // remove duplicates
            newState.deals = self.state.deals;

            for (const d of data) {
                if (!newState.deals.find(c => c.id === d.id)) {
                    newState.deals.push(d);
                }
            }

            self.fetchStart += NUM_ROWS_PER_FETCH;
            self.noMoreDeals = newState.deals.length >= paginationInfo.total;

            self.setState(newState);

            _.defer(function() {
                self.filterDeals();

                if (self.loaderTrigger && self.loaderTrigger.isVisible()) {
                    self.fetchData();
                }
            });
        });
    }

    filterDeals() {
        if (!this.filterByPhaseId) {
            this.setState({
                filteredDeals: this.state.deals
            });
        } else {
            let deals = [];

            for (const deal of this.state.deals) {
                if (deal.phase_id !== this.filterByPhaseId) {
                    continue;
                }

                deals.push(deal);
            }

            this.setState({
                filteredDeals: deals
            });
        }
    }

    render() {
        const clientPreferences = app.user.get('client').preferences;
        const customColorkeys = clientPreferences?.custom_colorkeys ? JSON.parse(clientPreferences.custom_colorkeys) : [];
        let phaseColors = null;

        if (customColorkeys.length > 0) {
            phaseColors = customColorkeys[0].colours_ex;
        }

        return (
            <div className={style.pList}>
                {this.state.filteredDeals.map(deal => {
                    return (
                        <PlotCard
                            key={`pcard_${deal.id}`}
                            deal={deal}
                            widget={this.props.widget}
                            params={this.props.itemParams}
                            numPhases={this.state.numPhases}
                            phaseColors={phaseColors}
                        />
                    );
                })}

                {!this.noMoreDeals &&
                    <LoaderTrigger
                        ref={(el) => this.loaderTrigger= el}
                        fetchData={this.fetchData.bind(this)}
                    />
                }

                {this.state.loading &&
                    <div className={style.pLoading}>
                        <LoadingIndicator/>
                    </div>
                }
            </div>
        );
    }
}

export default class PlotCards extends React.Component {
    render() {
        return (
            <div className={style.plotCards}>
                <PlotList
                    widget={this.props.widget}
                    itemParams={this.props.widget.params.item_params}
                />
            </div>
        );
    }
}
import React from 'react';
import Marionette from 'Backbone.Marionette'
import Backbone from 'backbone'

import app from 'js/app';
import vent from 'js/vent';
import Table from 'app_v2/components/table/table';
import funnel from 'js/d3/funnel';
import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import FunnelViewEx from 'js/views/funnelEx';
import FunnelMap from 'js/react_views/funnel-map/funnel-map';
import GroupPageFetcher from 'app_v2/sections/base/group_page_fetcher';
import FunnelColorKey from 'js/react_views/flex-dashboard/widgets/funnel_colorkey';
import AppConfig from 'app/app-config';
import IpwTrigger from 'js/ipw_trigger';
import OpportunityModel from 'js/models/opportunity';

import style from './table_funnel.css';


const NUM_DEALS_TO_FETCH = 5000;


class Funnel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;

        const region = new Marionette.Region({
            el: this.element
        });

        const isPdcrm = AppConfig.getValue('is_pdcrm', false);

        this.funnel = new FunnelViewEx({
            dashboardView: isPdcrm ? 'pdcrm' : 'ss',
            triggerAllEvents: true,
            funnelColor: this.props.colorkeyId,
            hideWeeks: true
        });

        this.funnel.listenTo(this.funnel, 'show-deal', (dealId) => {
            this.props.onShowDeal(dealId);
        });

        this.funnel.listenTo(this.funnel, 'deal-phase-change', (data) => {
            this.handlePhaseChange(data);
        });

        region.show(this.funnel);

        this.setData(this.props);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handlePhaseChange(data) {
        const deal = data.deal;
        const phase = data.phase;
        const funnel = data.funnel

        if (!phase && !funnel) {
            return;
        }

        let dealData = {};

        if (phase) {
            dealData.phase_id = phase.id;
        } else {
            dealData.funnel_id = funnel.id;
        }

        if (AppConfig.getValue('showIPWCampaignTriggerMessage') && deal && phase) {
            const currentPhase = deal.get('phase');

            if (currentPhase && (phase.order > currentPhase.order)) {
                const mbContent = {
                    message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                    icon: 'icon-warning'
                };

                const self = this;

                const updateFields = function(phase, data, triggerIpw) {
                    const ipwTrigger = new IpwTrigger();

                    ipwTrigger.getPopulatedIpwCustomFields(phase.name, triggerIpw, null, function(fields) {
                        dealData = _.extend(dealData, fields);
                        self.updateDeal(deal.id, dealData, deal);
                    });
                }

                MessageBox.showYesNo(mbContent, this.funnel,
                    function() { // Yes
                        updateFields(phase, data, true);
                    },
                    function() { // No
                        updateFields(phase, data, false);
                    }, null,
                    {
                        staticRegion: true
                    }
                );
            } else {
                this.updateDeal(data.dealId, dealData);
            }
        } else {
            this.updateDeal(data.dealId, dealData);
        }
    }

    updateDeal(dealId, data) {
        const model = new OpportunityModel({id: dealId});
        const self = this;

        model.save(data, {
            patch: true,
            validate: false,
            ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
            params: {
                check_phase_change_allowed: true,
                check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
            },
            error: function(_, response) {
                let detail = '';

                try {
                    detail = JSON.parse(response.responseText).detail;
                } catch (_) {}

                if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' || detail.exception === 'OpportunityPhaseGateNotClear')) {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            let message = detail.message;

                            if (detail.exception === 'OpportunityPhaseGateNotClear') {
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }

                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            },
            complete: function() {
                self.props.onDealPhaseUpdated();
            }
        });
    }

    setData(data) {
        if (!data.phases || !data.periods || !data.deals) {
            return;
        }

        const funnel = new Backbone.Model(app.globalData.funnelsInfo.funnels.find(f => f.id === data.funnelId));

        this.funnel.setFunnelType(this.props.type);
        this.funnel.setPhases(data.phases);
        this.funnel.setPeriods(data.periods);
        this.funnel.setFunnels([funnel], data.funnelId);
        this.funnel.setDeals(new Backbone.Collection(data.deals).models);

        this.funnel.renderFunnel();
    }

    highlight(rowId) {
        this.funnel.highlight(rowId);
        this.funnel.renderFunnel();
    }

    setColorKey(colorkeyId) {
        this.funnel.funnelView.setColorKey(colorkeyId);
        this.funnel.renderFunnel();
    }

    render() {
        return (
            <div
                style={{
                    height: 'calc(100% - 108px)'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        position: 'relative'
                    }}
                >
                    <div
                        ref={(el) => this.element = el}
                        className='funnel-widget-container inverse'
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '6px'
                        }}
                    />
                </div>
            </div>
        );
    }
}


class SiteMap extends React.Component {
    constructor(props) {
        super(props);

        this.funnel = new funnel();
        this.funnel.periods(props.periods);
        this.funnel.colorkey(props.colorkeyId);

        this.deals = new Backbone.Collection(props.deals).models;
    }

    render() {
        return (
            <div
                style={{
                    height: 'calc(100% - 108px)'
                }}
            >
                <div
                    style={{
                        background: '#494970',
                        borderRadius: '6px',
                        padding: '20px',
                        height: '100%'
                    }}
                >
                    <div
                        style={{
                            height: '100%',
                            position: 'relative'
                        }}
                    >
                        <FunnelMap
                            ref={(el) => this.component = el}
                            colorScheme={this.funnel.colorScheme}
                            style='solid'
                            funnelId={this.props.funnelId}
                            deals={this.deals}
                            showExtraControls={true}
                            onShowDeal={this.props.onShowDeal}
                        />
                    </div>
                </div>
            </div>
        );
    }

    setColorKey(colorkeyId) {
        this.funnel.colorkey(colorkeyId);
        this.component.updateDealsColors();
    }
}


export default class TableFunnel extends React.Component {
    constructor(props) {
        super(props);

        this.groupLoaded = null;
        this.phasesByFunnelId = {};

        const preferences = app.user.get('preferences');
        this.colorkeyId = preferences.funnel_color || 'activity';

        this.tabs = [{
            id: 'funnel',
            title: 'Funnel'
        }, {
            id: 'momemtum',
            title: 'Momemtum'
        }];

        if (app.globalData.funnelsInfo.mapsAvailable) {
            this.tabs.push({
                id: 'site_map',
                title: 'Site Map'
            })
        }

        this.state = {
            activeTab: 'funnel'
        };

        const columnsCreator = new ColumnsCreator('opportunities');

        this.columns = [
            columnsCreator.text('name', 'Name', { filterable: true } ),
            columnsCreator.currency('buckets_total', 'Gross Release Price', { greedyWidth: true })
        ];

        let columnsIds = ['name', 'status', 'abbreviation', 'expected_close_date', 'organization.name', 'phase_last_changed',
                          'funnel', 'phase', 'value', 'default_value', 'currency', 'owner.name', 'latest_activity_in_days',
                          'bubble_representation_value'];

        columnsIds = columnsIds.concat(AppConfig.getValue('dealsListingExtraColumns', []));

        if (AppConfig.getValue('customColorKeys')) {
            const clientPreferences = app.user.get('client').preferences;
            const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ? JSON.parse(clientPreferences.custom_colorkeys) : [];

            if (customColorkeys && customColorkeys.length > 0){
                for (var ck of customColorkeys) {
                    if (!ck.is_system_field) {
                        columnsIds.push(`custom_fields.${ck.dropdown_id}`);
                    }
                }
            }
        }

        if (AppConfig.getValue('funnel.display_bubble.check_achieved_price_and_asking_price')) {
            columnsIds.push('buckets');
        }

        const extraColumnsBySection = AppConfig.getValue('deals.list.extra_columns', null, this.props.sectionId) || [];

        if (extraColumnsBySection.length > 0) {
            columnsIds = columnsIds.concat(extraColumnsBySection);
        }

        if (app.globalData.funnelsInfo.mapsAvailable) {
            columnsIds = columnsIds.concat(app.globalData.funnelsInfo.fieldIds);
            columnsIds.push('buckets');
        }

        columnsIds = _.uniq(columnsIds);

        this.fetcher = new GroupPageFetcher('opportunities', NUM_DEALS_TO_FETCH, columnsIds.map(cid => { return {id: cid} }));
        this.fetcher.setSectionId(this.props.sectionId);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    show(group) {
        const self = this;

        _.defer(function() {
            self.table.adjustColumnsWidth();
        });

        if (this.groupLoaded?.id === group.id) {
            return;
        }

        this.fetchData(group);
    }

    setFilterId(filterId) {
        this.fetcher.filterBy(filterId);
    }

    fetchData(group) {
        if (!group) {
            group = this.groupLoaded;
        }

        if (!group) {
            return;
        }

        const self = this;

        this.table.setLoading(true);

        const funnelId = this.props.funnelId;
        let phases = null;
        let periods = null;
        let deals = null;

        const checkReady = () => {
            if (!phases || !periods || !deals) {
                return;
            }

            self.funnel.setData({
                funnelId: funnelId,
                phases: phases,
                periods: periods,
                deals: deals
            });

            self.table.setLoading(false);
        }

        // ...
        if (funnelId in this.phasesByFunnelId) {
            phases = this.phasesByFunnelId[funnelId];
            checkReady();
        } else {
            phases = new Backbone.Collection(app.globalData.phasesInfo.phases.filter(p => p.funnel_id === funnelId)).models;
            this.phasesByFunnelId[funnelId] = phases;
            checkReady();
        }

        // ...
        if (!this.periods) {
            this.periods = new Backbone.Collection(app.globalData.periods).models;
        }

        periods = this.periods;
        checkReady();

        // ...
        this.fetcher.setFetchArgs(group.fetchArgs);

        this.fetcher.fetch(0, function(result) {
            if (!self.mounted) {
                return;
            }

            self.table.setData(result.rows, 0, result.start, result.total, result.numRows);
            self.groupLoaded = group;

            deals = result.rows;
            self.deals = deals;

            checkReady();
        });
    }

    hide() {
        this.table.handleUnselectAll();
    }

    onStartResizing() {
        this.table.onStartResizing();
    }

    onEndResizing() {
        this.table.onEndResizing();
    }

    handleRowMouseEnter(rowId) {
        if (this.funnel) {
            this.funnel.highlight(rowId);
        }
    }

    handleRowMouseLeave(rowId) {
        if (this.funnel) {
            this.funnel.highlight(null);
        }
    }

    handleColorkeyChange(colorkeyId) {
        if (this.funnel) {
            this.funnel.setColorKey(colorkeyId);
        } else if (this.siteMap) {
            this.siteMap.setColorKey(colorkeyId);
        }
    }

    handleDealPhaseUpdated() {
        this.fetchData();
        this.props.onDealUpdated();
    }

    handleTableSortChange(columnId, sortDir) {
        this.fetcher.orderBy(columnId, sortDir);
        this.fetchData();
    }

    setColumnsRules(rules) {
        this.table.setColumnsRules(rules);
    }

    render() {
        return (
            <div className={style.tableFunnel}>
                <div className={style.tTable}>
                    <Table
                        ref={(el) => this.table = el}
                        hasPaginator={true}
                        selectableRows={true}
                        columns={this.columns}
                        rows={[]}
                        onRowSelectionChange={this.props.onRowSelectionChange}
                        onRowClick={this.props.onRowClick}
                        onMouseEnter={this.handleRowMouseEnter.bind(this)}
                        onMouseLeave={this.handleRowMouseLeave.bind(this)}
                        onFilterRuleChange={this.props.onFilterRuleChange}
                        onSortChange={this.handleTableSortChange.bind(this)}
                    />
                </div>

                <div className={style.tFunnel}>
                    <div className={style.tTabControl}>
                        {this.tabs.map(t => {
                            return (
                                <div
                                    key={`t_${t.id}`}
                                    className={`
                                        ${style.tTab}
                                        ${this.state.activeTab === t.id ? style.tActive : ''}
                                    `}
                                    onClick={() => this.setState({ activeTab: t.id })}
                                >
                                    {t.title}
                                </div>
                            );
                        })}
                    </div>

                    {this.state.activeTab === 'funnel' &&
                        <Funnel
                            key='funnelView'
                            ref={(el) => this.funnel = el}
                            funnelId={this.props.funnelId}
                            phases={this.phasesByFunnelId[this.props.funnelId]}
                            periods={this.periods}
                            deals={this.deals}
                            type='funnel'
                            colorkeyId={this.colorkeyId}
                            onShowDeal={this.props.onRowClick}
                            onDealPhaseUpdated={this.handleDealPhaseUpdated.bind(this)}
                        />
                    }

                    {this.state.activeTab === 'momemtum' &&
                        <Funnel
                            key='momemtumView'
                            ref={(el) => this.funnel = el}
                            funnelId={this.props.funnelId}
                            phases={this.phasesByFunnelId[this.props.funnelId]}
                            periods={this.periods}
                            deals={this.deals}
                            type='sos'
                            colorkeyId={this.colorkeyId}
                            onShowDeal={this.props.onRowClick}
                            onDealPhaseUpdated={this.handleDealPhaseUpdated.bind(this)}
                        />
                    }

                    {this.state.activeTab === 'site_map' &&
                        <SiteMap
                            ref={(el) => this.siteMap = el}
                            funnelId={this.props.funnelId}
                            deals={this.deals}
                            periods={this.periods}
                            colorkeyId={this.colorkeyId}
                            onShowDeal={this.props.onRowClick}
                        />
                    }

                    <div className={style.tColorKey}>
                        <FunnelColorKey
                            onColorkeyChanged={this.handleColorkeyChange.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
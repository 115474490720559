import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2'
import TextManager from 'app/text-manager'
import app from 'js/app'
import vent from 'js/vent'
import BaseToolbarView from 'js/views/base/toolbar'
import ColumnSelectView from 'js/views/base/column-select'
import LoadingView from 'js/views/loading'
import CustomFieldsCollection from 'js/collections/custom_fields'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import ModalRegion from 'js/views/base/modal-region'
import Utilities from 'js/utils/utilities'
import security from 'js/utils/security'
import footerTemplate from 'templates/base/list_footer.handlebars'
import toolbarTemplate from 'templates/base/table_detail_toolbar.handlebars'


var CUSTOM_FIELD_COLUMNS = {
    checkbox: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    currency: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    date: {
        template: Handlebars.compile('{{#if value}}{{formatEntityInformationDate value}}{{/if}}'),
        templateHelpers: function(model, column) {
            // replace - to / to ignore timezone
            var date = model[column.id];
            return {
                value: date ? new Date(date.replace(/-/g,'/')) : ''
            };
        }
    },
    dropDown: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(row, column) {
            var value = row[column.id];
            return {
                value: value
            };
        }
    },
    individual: {
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(model, column) {
            return {
                name: model[column.id] ? model[column.id].full_name : ''
            };
        }
    },
    number: {
        template: Handlebars.compile('<div style="text-align: right;">{{value}}</div>'),
        templateHelpers: function(model, column) {
            return {
                value: Utilities.numberWithCommas(model[column.id])
            };
        }
    },
    organization: {
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(model, column) {
            return {
                name: model[column.id] ? model[column.id].name : ''
            };
        }
    },
    opportunity: {
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(model, column) {
            return {
                name: model[column.id] ? model[column.id].name : ''
            };
        }
    },
    paragraph: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    text: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    url: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    urlImage: {
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(model, column) {
            return {
                value: model[column.id]
            };
        }
    },
    user: {
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(model, column) {
            return {
                name: model[column.id] ? model[column.id].name : ''
            };
        }
    },
    product: {
        template: Handlebars.compile('<div style="text-align: right;">{{value}}</div>'),
        templateHelpers: function(model, column) {
            return {
                value: Utilities.numberWithCommas(model[column.id])
            };
        }
    },
};

function flatten(data, delimiter, keys, new_data) {
    delimiter = delimiter || '.';
    keys = keys || [];
    new_data = new_data || [];

    _.each(data, function(value, key) {
        var new_keys = keys.slice();
        new_keys.push(key);
        if (_.isArray(value)) {
            value = value[0];
        }
        if (_.isObject(value)) {
            flatten(value, delimiter, new_keys, new_data);
        } else {
            new_data.push(new_keys.join(delimiter));
        }
    });

    return new_data;
}

var TableHeaderRowView = Marionette.ItemView.extend({
    tagName: 'th',
    className: function() {
        var classes = [''];
        if (this.model.get('sort')) {
            classes.push('sortable');
        }

        if (this.model.get('headerClass')) {
            classes.push(this.model.get('headerClass'));
        }

        return classes.join(' ');
    },
    getTemplate: function() {
        var headerTemplate = this.model.get('headerTemplate');

        if (headerTemplate) {
            return headerTemplate;
        }

        return Handlebars.compile([
            '<span class="column-title" style="{{style}}">{{name}}</span>',
            '{{#if sort}}<i class="icon-caret-down"></i>{{/if}}'
        ].join(''));
    },
    templateHelpers: function() {
        var name = this.model.get('name');
        if (_.isFunction(name)) {
            name = name();
        }
        return {
            name: name
        };
    },
    events: {
        click: function(ev) {
            ev.preventDefault();

            var onHeaderClick = this.model.get('onHeaderClick');

            if (onHeaderClick) {
                onHeaderClick(ev);
            }
            else if (this.model.get('sort')) {
                this.direction = !this.direction;
                this.trigger('sort', this.model, this.direction);
            }
        }
    },
    onRender: function() {
        this.listenTo(this.options.parent, 'set-sort', function(id, direction) {
            if (_.isEqual(this.model.get('id'), id)) {
                this.direction = direction;
                if (direction) {
                    this.$el.removeClass('desc').addClass('sorting asc');
                } else {
                    this.$el.removeClass('asc').addClass('sorting desc');
                }
            } else {
                this.$el.removeClass('sorting asc desc');
            }
        });

        // max width should work only on limited width table
        if (this.model.get('maxHeaderWidth') && this.options.parent.fixedTable) {
            this.$el.attr('max-width', this.model.get('maxHeaderWidth'));
        }
        if (this.model.get('minHeaderWidth')) {
            this.$el.attr('min-width', this.model.get('minHeaderWidth'));
        }
        if (this.model.get('constHeaderWidth')) {
            this.$el.attr('const-width', this.model.get('constHeaderWidth'));
        }
    }
});

var TableHeaderView = Marionette.CollectionView.extend({
    tagName: 'tr',
    itemView: TableHeaderRowView,
    itemViewOptions: function() {
        return {
            parent: this
        };
    },
    initialize: function() {
        this.listenTo(this, 'itemview:sort', function(childview, model, direction) {
            this.trigger('sort', model, direction);
        });

        this.fixedTable = this.options.parent.$el.find('.basic-table-list-container table').css('table-layout') === 'fixed';
    },
    onRender: function() {
        var self = this;

        if (this.options.parent.fixedHeader) {
            var headerCont = this.options.parent.$el.find('.header-clone-container');
            var dataCont = this.options.parent.$el.find('.basic-table-list-container');
            if (this.fixedTable) {
                headerCont.find('table').css({'opacity': '0', visibility: 'hidden'});

                _.defer(function () {
                    self.simulateMinMaxWidth();
                });

                $(window).on('resize', this.simulateMinMaxWidth.bind(this));
                vent.on('sidebar:collapse', this.simulateMinMaxWidth.bind(this))
            }
            else {
                headerCont.addClass('fixed');
                headerCont.find('table').css({'opacity': '0', visibility: 'hidden'});

                _.defer(function() {
                    headerCont.scrollLeft(dataCont.scrollLeft());
                });
                $(dataCont).on('scroll', function() {
                    headerCont.scrollLeft($(this).scrollLeft());
                });

                _.defer(function () {
                    self.adjustHeader();
                });

                $(window).on('resize', _.debounce(function() {
                    self.adjustHeader.bind(this)
                }, 1000, true));
                vent.on('sidebar:collapse', this.adjustHeader.bind(this));
                vent.on('deals:expand-table', this.adjustHeader.bind(this));
                this.listenTo(this.options.parent.options.parent, 'layout-mode-change', function() {
                    _.defer(self.adjustHeader.bind(this));
                });
            }
        }
    },
    onBeforeClose: function () {
        if (this.fixedTable) {
            $(window).off('resize', this.simulateMinMaxWidth.bind(this));
        }
        else {
            $(window).off('resize', this.adjustHeader.bind(this));
        }
    },
    simulateMinMaxWidth: function() {
        this.$el.find('th[max-width]').css('width', '');
        this.$el.find('th[min-width]').css('width', '');
        this.$el.find('th[const-width]').each(function (ind, th) {
            var el = $(th);
            var width = el.attr('const-width');
            el.css('width', width + 'px');
        });
        var change = true;
        while (change) {
            change = false;
            this.$el.find('th[max-width]').each(function (ind, th) {
                var el = $(th);
                var maxWidth = el.attr('max-width');
                var hasWidth = !!el[0].style.width.length;
                if (!hasWidth && maxWidth && el.outerWidth() > maxWidth) {
                    el.css('width', maxWidth + 'px');
                    change = true;
                }
            });
        }
        change = true;
        while (change) {
            change = false;
            this.$el.find('th[min-width]').each(function (ind, th) {
                var el = $(th);
                var minWidth = el.attr('min-width');
                var hasWidth = !!el[0].style.width.length;
                if (!hasWidth && minWidth && el.outerWidth() < minWidth) {
                    el.css('width', minWidth + 'px');
                    change = true;
                }
            });
        }
        this.trigger('th:width:change');
    },
    adjustHeader: function() {
        this.trigger('th:width:needs:change');
    }
});

var TableBodyView = Marionette.ItemView.extend({
    template: Handlebars.compile('{{#each items}}<tr data-id="{{id}}">{{#each items}}<td data-id="{{id}}">{{{value}}}</td>{{/each}}</tr>{{{extraRow.template}}}{{/each}}'),
    tagName: 'tbody',
    events: {
        'click tr': function(ev) {
            // limit clicking on totals row
            if (ev.currentTarget.dataset.id) {
                app.dirtyModelHandler.confirm(this, function() {
                    this.trigger('select', ev.currentTarget.dataset.id);
                });
            }
        },
        mouseenter: function(ev) {
            this.trigger('mouseenter', ev.currentTarget.dataset.id);
        },
        mouseleave: function(ev) {
            this.trigger('mouseleave', ev.currentTarget.dataset.id);
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        var view = this;
        _.each(this.model.get('items'), function(row) {
            _.each(row.items, function(col) {
                if (col.data.onRender) {
                    col.data.onRender(view.$el.find('tr[data-id="' + row.id + '"] td[data-id="' + col.id + '"]'), row.data, col.data, view);
                }
            });

            if (view.options.extraRow) {
                var tr = view.$el.find('tr[data-id="' + row.id + '"]');
                var next = tr.next();
                tr.on('mouseenter', function() { next.addClass('hover'); }).on('mouseleave', function() { next.removeClass('hover') });
                next.on('mouseenter', function() { next.addClass('hover'); tr.addClass('hover'); }).on('mouseleave', function() { next.removeClass('hover'); tr.removeClass('hover'); });
                row.extraRow.onRender(next, row.data);
            }

            if (view.parent.model && view.parent.model.get('id') === 'drafts' && row.usedInAutomation) {
                view.$el.find('tr[data-id="' + row.id + '"]').css('background', '#ededed');
            }
        });

        if (this.options.showTotals) {
            this.addTotals();
        }
    },
    /**
     * Add an extra row at the end for aggregate values.
     */
    addTotals: function() {
        var totals = this.options.totals,
            columnString = _.reduce(this.options.columns, function(str, column) {
                if (column.id in totals) {
                    return str + '<td>' + column.template(totals[column.id]) + '</td>';
                }
                else {
                    return str + '<td></td>';
                }
            }, '');

        var rowClass = (this.columns.length > 0 && this.columns[0].id === 'selection') ?
                        'aggregate-row-selection' : 'aggregate-row-no-selection';
        this.$el.append('<tr class="' + rowClass + '">' + columnString + '</tr>');
    }
});

var TableBodyEmptyView = Marionette.ItemView.extend({
    template: Handlebars.compile('<tr class="empty-collection"><td><div><span>No items to display</span></div></td></tr>'),
    tagName: 'tbody'
});

var FooterView = Marionette.ItemView.extend({
    tagName: 'div',
    template: Handlebars.compile(footerTemplate),
    templateHelpers: function() {
        return {
            previous: this.options.previous,
            next: this.options.next,
            collectionStart: this.options.collectionStart,
            collectionEnd: this.options.collectionEnd,
            collectionTotal: this.options.collectionTotal
        };
    }
});

var ColumnToolbarView = BaseToolbarView.extend({
    template: Handlebars.compile(toolbarTemplate),
    ui: _.extend({
        editColumns: '.edit-columns-item'
    }, BaseToolbarView.prototype.ui),
    events: _.extend({
        'click .edit-columns-item': 'editColumnsItem'
    }, BaseToolbarView.prototype.events),
    editColumnsItem: function(ev) {
        ev.preventDefault();
        this.trigger('toolbar:edit-columns');
    }
});

export default Marionette.Layout.extend({
    tagName: 'div',
    template: Handlebars.compile([
        '<div class="popover-region"></div>',
        '<header class="header list-header">',
            '<div class="list-toolbar toolbar-container"></div>',
        '</header>',
        '<div class="basic-table-list-container scrollable">',
            '<table class="basic-table-list link-rows">',
                '<thead></thead>',
                '<tbody></tbody>',
            '</table>',
            '<div class="loader-container"></div>',
            '<footer class="pager"></footer>',
        '</div>',
        '<div class="header-clone-container"><table class="basic-table-list"></table></div>',
        '<div class="column-region"></div>'
    ].join('')),
    ui: {
        table: 'table',
        loaderContainer: '.loader-container'
    },
    regions: {
        toolbarRegion: '.list-toolbar',
        headerRegion: '.basic-table-list-container table thead',
        bodyRegion: {
            selector: '.basic-table-list-container table tbody',
            regionType: Marionette.Region.extend({
                open: function(view) {
                    this.$el.replaceWith(view.$el);
                },
                close: function() {
                    var view = this.currentView;
                    if (!view || view.isClosed){
                        return;
                    }

                    var $el = $('<tbody></tbody>');
                    view.$el.before($el);
                    this.$el = $el;

                    Marionette.Region.prototype.close.apply(this, arguments);
                }
            })
        },
        footerRegion: 'footer.pager',
        columnRegion: {
            selector: '.column-region',
            regionType: ModalRegion
        },
        loaderContainer: '.loader-container',
        menuRegion: '.popover-region'
    },
    events: {
        'click li.previous a': 'showPreviousPage',
        'click li.next a': 'showNextPage'
    },
    columns: {},
    defaultColumns: [],
    collectionLength: 50,
    sort: null,
    sortDirection: true,
    serializeData: function() {},
    initialize: function(options) {
        _.extend(this, options);

        // ...
        if (options.model) {
            var display_options = options.model.get('display_options');

            // ignore stored sort when doNotPersistSort is set
            if (display_options && display_options.sort && !this.model.doNotPersistSort) {
                this.sortId = display_options.sort.field;
                this.sortDirection = display_options.sort.direction;
            }
        }
        this.collection.collectionPage = 1;

        this.listenTo(this.collection, 'add change remove reset destroy', function() {
            this.onCollection(this.collection);
        });

        this.listenTo(vent, 'contact:save', this.refresh);

        // convert to dictionary for better performance
        if (this.availableColumns) {
            this.availableColumns = _.object(_.map(this.availableColumns, function (name) {
                return [name, true]
            }));
        }
    },
    refresh: function() {
        this.fetchData();
    },
    isDefaultColumns: function(columns) {
        var defaultColumns = this.defaultColumns;
        var length = Math.max(columns.length, defaultColumns.length);
        for (var i = 0; i < length; i++) {
            if (columns[i] !== defaultColumns[i]) {
                return false;
            }
        }

        return true;
    },
    onRender: function() {
        var self = this;

        _.defer(function() {
            self.fetchData();
        });

        this.listenTo(this.parent, 'edit-columns', function() {
            var columnView = new ColumnSelectView({
                columns: this.getAllColumns(),
                selectedColumns: this.getSelectedColumnIds()
            });
            this.columnRegion.show(columnView);

            this.listenTo(columnView, 'save', function(collection) {
                var columns = collection.map(function(model) {
                    return model.get('id');
                });
                this.selectedColumns = columns;

                if (this.isDefaultColumns(columns)) {
                    columns = [];
                }
                if (this.model && security.checkPermission('edit', this.model) && !this.model.doNotPersistColumns) {
                    this.model.save({
                        columns: columns
                    }, {
                        patch: true,
                        success: function() {
                            this.trigger('update-columns', columns);
                            this.fetchCollection();
                        }.bind(this)
                    });
                } else {
                    this.trigger('update-columns', columns);
                    this.fetchCollection();
                }
            });

            this.listenTo(columnView, 'close', function() {
                this.columnRegion.reset();
            });
        });
    },
    createCustomFieldColumn: function(model) {
        var columns,
            id = model.get('id'),
            view = model.get('view'),
            foreignCustom = view !== this.options.customfield_type,
            typeMap = {
                organizations: 'organization'
            },
            nameMap = {
                organizations: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
            };

        var fid = 'custom_fields.' + id;
        if (foreignCustom) {
            fid = typeMap[view] + '.' + fid;
        }

        return _.extend({
            id: fid,
            customfield: model,
            name: foreignCustom ? nameMap[view] + ' ' + model.get('name') : model.get('name'),
            sort: [(foreignCustom ? typeMap[view] + '.' : '') + 'custom_fields.' + id],
            foreignCustom: foreignCustom
        }, CUSTOM_FIELD_COLUMNS[model.get('type')]);
    },
    fetchData: function() {
        var self = this;

        if (!this.customfields && this.customfield_type) {
            this.fetchingCustomFields = true;

            this.customfields = new CustomFieldsCollection();
            this.customfields.fetch({
                success: function() {
                    self.fetchingCustomFields = false;

                    if (!self.fetchingCollection) {
                        self.createViews();
                    }
                }
            });
        }

        this.fetchCollection(true);
    },
    beforeCollection: function(extraOptions) {
        extraOptions = extraOptions || {};

        if (extraOptions.showLoadingView) {
            this.bodyRegion.close();
            this.headerRegion.close();
            this.footerRegion.close();
            this.ui.loaderContainer.show();
            this.loaderContainer.show(new LoadingView());
        }
    },
    onCollection: function(collection) {
        if (this.parent.listModeVisible) {
            return;
        }
        var fixed = !this.selectedColumns || _.isEqual(this.selectedColumns, this.defaultColumns);
        this.$el.find('.basic-table-list').toggleClass('fixed', fixed);

        this.collection = collection;
        this.fetchingCollection = false;

        if (!this.fetchingCustomFields) {
            this.createViews();
        }
    },
    createViews: function() {
        var prevSelectedRows = this.selectedRows;

        if (this.options.tools) {
            this.createToolbarView();
        }

        this.createHeaderView();
        this.createBodyView();
        this.createFooterView();

        this.loaderContainer.close();
        this.ui.loaderContainer.hide();

        if (prevSelectedRows && prevSelectedRows.length > 0) {
            var self = this;

            this.selectedRows = _.filter(prevSelectedRows, function(r) {
                return self.collection.get(r.id);
            });

            _.each(this.selectedRows, function(r) {
                var checkbox = self.$el.find('tr[data-id="' + r.id + '"] .table-list-checkbox');
                checkbox.addClass('checked');
                checkbox.parent().parent().addClass('selected');
            });

            this.onRowSelectionChange();
        }
    },
    getAllColumns: function() {
        var view = this,
            allColumns = _.clone(this.columns);

        if (this.availableColumns) {
            allColumns = _.filter(allColumns, function(col) { return col.id in view.availableColumns });
        }

        if (this.customfields) {
            this.customfields.each(function(model) {
                var viewType = model.get('view');
                // use only same type custom fields and organization custom fields for all types
                if (view.options.customfield_type === viewType || viewType === 'organizations') {
                    allColumns.push(view.createCustomFieldColumn(model));
                }
            });
        }
        return allColumns;
    },
    getSelectedColumnIds: function() {
        return _.isEmpty(this.selectedColumns) ? this.defaultColumns : this.selectedColumns;
    },
    getSelectedColumns: function() {
        var selectedColumnIds,
            selectedColumns;

        selectedColumnIds = this.getSelectedColumnIds();

        selectedColumns = _.chain(this.getAllColumns())
            .map(function(value) {
                return [value.id, value];
            })
            .object()
            .pick(selectedColumnIds)
            .sortBy(function(value) {
                return selectedColumnIds.indexOf(value.id);
            })
            .value();

        // add empty column for "Grand Totals" string, if its taken by aggregate value
        var firstCol = selectedColumns[0];
        if (firstCol.id === 'default_value' || firstCol.id === 'buckets_total' || 'bucket' in firstCol) {
            selectedColumns.unshift({
                id: 'empty_column',
                name: '',
                template: Handlebars.compile('')
            });
        }

        if (this.addSelectionColumn) {
            this.selectedRows = [];
            selectedColumns.unshift(this.getSelectionColumn());
        }

        return selectedColumns;
    },
    getSelectionColumn: function() {
        var self = this;

        this.selectionCols = [];

        return {
            id: 'selection',
            name: '',
            pinned: true,
            template: Handlebars.compile([
                '<div class="table-list-checkbox">',
                    '<i class="icon-checkmark mark"></i>',
                    '<i class="icon-checkmark2 unmark"></i>',
                '</div>'
            ].join('')),
            headerTemplate: Handlebars.compile([
                '<div class="table-list-checkbox">',
                    '<i class="icon-checkmark mark"></i>',
                    '<i class="icon-checkmark2 unmark"></i>',
                '</div>'
            ].join('')),
            onRender: function(el, rowData) {
                self.selectionCols.push({
                    el: el,
                    data: rowData
                });

                var checkbox = el.find('.table-list-checkbox');
                var idx = self.selectionCols.length - 1;

                // ...
                checkbox.click(function(ev) {
                    ev.stopPropagation();
                    ev.preventDefault();

                    var checkboxesIdx = [];
                    var selecting = !checkbox.hasClass('checked');

                    if (ev.shiftKey) {
                        if ((idx === self.prevCheckClickedIdx) || _.isUndefined(self.prevCheckClickedIdx)) {
                            checkboxesIdx.push(idx);
                        }
                        else {
                            var first;
                            var last;

                            if (self.prevCheckClickedIdx < idx) {
                                first = self.prevCheckClickedIdx;
                                last = idx;
                            }
                            else {
                                first = idx;
                                last = self.prevCheckClickedIdx;
                            }

                            for (var i = first; i <= last; ++i) {
                                checkboxesIdx.push(i);
                            }
                        }
                    }
                    else {
                        checkboxesIdx.push(idx);
                    }

                    self.prevCheckClickedIdx = idx;

                    _.each(checkboxesIdx, function(c) {
                        var checkboxInfo = self.selectionCols[c];
                        var checkbox = checkboxInfo.el.find('.table-list-checkbox');

                        checkbox.toggleClass('checked', selecting);
                        checkboxInfo.el.parent().toggleClass('selected', selecting);

                        if (checkbox.hasClass('checked')) {
                            if (self.selectedRows.indexOf(checkboxInfo.data) === -1) {
                                self.selectedRows.push(checkboxInfo.data);
                            }
                        }
                        else {
                            self.selectedRows = _.filter(self.selectedRows, function(d) {
                                return d.id != checkboxInfo.data.id;
                            });
                        }

                        self.onRowSelectionChange();
                    });
                });
            },
            onHeaderClick: function(ev) {
                var checkbox = $(ev.target);

                if (!checkbox.hasClass('table-list-checkbox')) {
                    checkbox = checkbox.parent();
                }

                checkbox.toggleClass('checked');

                var selecting = checkbox.hasClass('checked');
                self.selectedRows = [];

                _.each(self.selectionCols, function(c) {
                    c.el.find('.table-list-checkbox').toggleClass('checked', selecting);
                    c.el.parent().toggleClass('selected', selecting);

                    if (selecting) {
                        self.selectedRows.push(c.data);
                    }
                });

                self.onRowSelectionChange();

                if (self.toolbarRegion && selecting) {
                    self.toolbarRegion.currentView.onAllPageSelected();
                }
            },
            maxHeaderWidth: '50'
        };
    },
    clearSelection: function() {
        this.selectedRows = [];
        this.onRowSelectionChange();
    },
    onRowSelectionChange: function() {
        if (this.toolbarRegion) {
            this.toolbarRegion.currentView.setSelection(this.selectedRows);
        }
    },
    createHeaderView: function() {
        var self = this;

        var tableHeaderView = new TableHeaderView({
            collection: new Backbone.Collection(this.getSelectedColumns()),
            parent: this
        });

        this.listenTo(tableHeaderView, 'render', function() {
            tableHeaderView.trigger('set-sort', this.sortId, this.sortDirection);
        });

        this.listenTo(tableHeaderView, 'itemview:sort', function(childview) {
            this.sortId = childview.model.get('id');
            this.sortDirection = childview.direction;

            tableHeaderView.trigger('set-sort', this.sortId, this.sortDirection);

            if (this.model && security.checkPermission('edit', this.model) && !this.model.doNotPersistSort) {
                var display_options = this.model.get('display_options') || {};
                var sort = {};

                if (display_options && display_options.sort) {
                    sort = display_options.sort;
                }

                if ((this.sortId !== sort.field) || (this.sortDirection !== sort.direction)) {
                    sort.field = this.sortId;
                    sort.direction = this.sortDirection;
                    display_options.sort = sort;

                    this.model.set('display_options', display_options);
                    this.model.save({
                        display_options: display_options
                    }, {
                        patch: true
                    });
                }
            }

            // ...
            this.fetchCollection();
        });

        if (this.fixedHeader) {
            this.listenTo(tableHeaderView, 'th:width:change', function () {
                var headerTable = self.$el.find('.header-clone-container table');
                var headerElements = headerTable.find('th');
                var firstRowElements = self.$el.find('.basic-table-list-container tr:first').find('td');
                var dataTable = self.$el.find('.basic-table-list-container table');

                headerTable.css({
                    'opacity': 0,
                    'visibility': 'hidden',
                    'width': '100%'
                });
                dataTable.css('width', '100%');

                _.each(headerElements, function (th, ind) {
                    if ($(th).attr('const-width')) {
                        return;
                    }
                    var width = $(th).outerWidth();
                    $(firstRowElements[ind]).css('width', width + 'px');
                });

                headerTable.css({
                    'opacity': 1,
                    'visibility': 'visible'
                });
            });
            this.listenTo(tableHeaderView, 'th:width:needs:change', function () {
                var total = 0;
                var headerTable = self.$el.find('.header-clone-container table');
                var headerElements =  headerTable.find('th');
                var dataTable = self.$el.find('.basic-table-list-container table');
                var firstRowElements =  dataTable.find('tr:first').find('td');
                var scrollLeft = self.$el.find('.basic-table-list-container').scrollLeft();

                headerElements.css('width', '');
                firstRowElements.css('width', '');

                headerTable.css({
                    'opacity': 0,
                    'visibility': 'hidden'
                });

                headerTable.css('width', 'initial');
                headerTable.css('min-width', 'initial');
                dataTable.css('width', 'initial');
                dataTable.css('min-width', 'initial');

                if (this.collection.length) {
                    _.each(firstRowElements, function (td, ind) {
                        var headerOuterWidth = $(headerElements[ind]).outerWidth();
                        // safari bug on outerWidth
                        if (headerOuterWidth === 0) {
                            // calc children width plus padding 2*8
                            headerOuterWidth = _.reduce($(headerElements[ind]).children(), function(memo, el) { return memo + $(el).width();  }, 0) + 16;
                        }
                        var width = Math.max($(td).outerWidth(), headerOuterWidth);
                        width = width + 1; // magic number to compensate FF outerWidth() fluctuation
                        var maxWidth = $(headerElements[ind]).attr('max-width');
                        if (maxWidth) {
                            width = Math.min(width, maxWidth);
                        }
                        $(headerElements[ind]).css('width', width + 'px');
                        $(td).css('width', width + 'px');
                        total += width;
                    });
                    headerTable.css('width', total + 'px');
                    headerTable.css('min-width', '100%');
                    dataTable.css('width', total + 'px');
                    dataTable.css('min-width', '100%');
                }
                else {
                    headerTable.css('min-width', '100%');
                }

                headerTable.css({
                    'opacity': 1,
                    'visibility': 'visible'
                });

                self.$el.find('.basic-table-list-container').scrollLeft(scrollLeft);
                self.$el.find('.header-clone-container').scrollLeft(scrollLeft);
            });
        }

        this.headerRegion.show(tableHeaderView);

        tableHeaderView.trigger('set-sort', this.sortId, this.sortDirection);
    },
    createBodyView: function() {
        var self = this,
            columns = this.getSelectedColumns(),
            totals = {},
            currencies = {};

        var collection = _.map(this.collection.toJSON(), function(item, index) {
            return {
                id: item.id,
                data: item,
                items: _.map(columns, function(column) {
                    var data = {
                        index: index + 1
                    };
                    if (column.templateHelpers) {
                        data = _.extend(data, column.templateHelpers(item, column));
                    }
                    else {
                        data = _.extend(data, item);
                    }

                    // we get totals only for limited amount of columns
                    if (column.id === 'default_value') {
                        if (column.id in totals) {
                            totals[column.id].default_value += item.default_value;
                        }
                        else {
                            totals[column.id] = { default_value: item.default_value };
                        }
                    }
                    else if (column.id === 'weighted_value') {
                        if (column.id in totals) {
                            totals[column.id].weighted_value += data.weighted_value;
                        }
                        else {
                            totals[column.id] = { weighted_value: data.weighted_value };
                        }
                    }
                    else if (column.id === 'buckets_total') {
                        if (column.id in totals) {
                            totals[column.id].value += item.buckets_total;
                        }
                        else {
                            totals[column.id] = { value: item.buckets_total };
                        }

                        // buckets_total guarantees currency existence
                        currencies[item.currency] = true;
                    }
                    else if ('bucket' in column) {
                        var val = item[column.id];

                        if (column.id in totals) {
                            totals[column.id].value.value += val;
                        }
                        else {
                            // nested object because of existing template format
                            totals[column.id] = { value: { value: val } };
                        }
                    }

                    return {
                        id: column.id,
                        data: column,
                        value: column.template(data)
                    };
                }),
                extraRow: self.extraRow ? self.extraRows[self.extraRow] : null,
                usedInAutomation: item.used_in_automations
            };
        });

        if (_.size(currencies) === 1) {
            totals['buckets_total']['currency'] = _.keys(currencies)[0];
        }
        else if (_.size(currencies) > 1) {
            delete totals['buckets_total'];
        }

        var bodyView;
        if (this.collection.total) {
            bodyView = new TableBodyView({
                model: new Backbone.Model({
                    items: collection
                }),
                totals: totals,
                columns: columns,
                showTotals: this.showTotals,
                parent: this,
                extraRow: !!self.extraRow
            });
        }
        else {
            bodyView = new TableBodyEmptyView();
        }

        this.listenTo(bodyView, 'select', function(id) {
            this.trigger('select', this.collection.get(id));
        });

        this.bodyRegion.show(bodyView);

        if (this.fixedHeader) {
            var table = this.$el.find('.basic-table-list-container .basic-table-list');
            var header = table.find('thead');
            if (header.length) {
                this.$el.find('.header-clone-container table').html(header);
            }

            this.$el.find('.basic-table-list-container').addClass('fixed-header');
        }
    },
    createToolbarView: function() {
        var toolbarParams = {
            collection: this.collection,
            tools: this.options.tools,
            toolLabels: this.options.toolLabels
        };

        if (this.model && (this.model.get('element_type') === 'individuals')) {
            toolbarParams.addItemOptions = {
                selectDropdownExtraClasses: 'individual-select-popover',
                selectFormatResult: function(item, container) {
                    var message = '{{title}}'

                    if (item.item.organization_name) {
                        message += '<div class="organization-name">{{orgName}}</div>';
                        container.addClass('has-organization-name');
                    }

                    return Handlebars.compile(message)({title: item.title, orgName: item.item.organization_name});
                }
            }
        }

        var toolbarView = new ColumnToolbarView(toolbarParams);

        this.listenTo(toolbarView, 'toolbar:new', function() {
            this.trigger('toolbar:new');
        });
        this.listenTo(toolbarView, 'toolbar:add-item', function(model) {
            this.trigger('toolbar:add-item', model);
        });
        this.listenTo(toolbarView, 'toolbar:delete-bulk', function(options) {
            this.trigger('toolbar:delete-bulk', options);
        });
        this.listenTo(toolbarView, 'toolbar:remove', function(data) {
            this.trigger('toolbar:remove', data);
        });
        this.listenTo(toolbarView, 'toolbar:edit', function(options) {
            this.trigger('toolbar:edit', options);
        });
        this.listenTo(toolbarView, 'toolbar:merge', function(options) {
            this.trigger('toolbar:merge', options);
        });
        this.toolbarRegion.show(toolbarView);
    },
    createFooterView: function() {
        var previous, next;

        previous = this.collection.start > 0;
        next = this.collection.start + this.collection.length < this.collection.total;

        if ( this.footerRegion ) {
            this.footerRegion.show(new FooterView({
                previous: previous,
                next: next,
                collectionStart: this.collection.start + 1,
                collectionEnd: this.collection.start + this.collection.length,
                collectionTotal: this.collection.total
            }));
        }
    },
    fetchCollection: function(showLoadingView) {
        this.fetchingCollection = true;

        var extraOptions = {
            showLoadingView: showLoadingView,
            fetchingFromTable: true
        };

        if (!this.model) { // its an special group
            extraOptions.columnsIds = this.getSelectedColumnIds();
        }

        this.trigger(
            'fetch-collection',
            {},
            this.collection.collectionPage,
            this.collectionLength,
            this.getSorts(this.sortId, this.sortDirection),
            extraOptions
        );
    },
    getSorts: function(sortId, sortDirection) {
        var self = this;
        if (_.isArray(sortId)) {
            return _.map(sortId, function(sort) {
                return self.getSort(sort.attribute, sort.order)[0];
            });
        }
        else {
            return this.getSort(sortId, sortDirection);
        }
    },
    getSort: function(sortId, sortDirection) {
        var column = _.find(this.getAllColumns(), function(column) {
            return column.id === sortId;
        });
        if (column) {
            var sort = column.sort;
            if (_.isFunction(sort)) {
                return sort(sortDirection);

            } else {
                return _.map(sort, function(field) {
                    return {
                        attribute: field,
                        order: sortDirection ? 'asc' : 'desc'
                    };
                });
            }
        }
        // With optimization custom fields are not loaded on initial opening, so this is a fallback for creating
        // sort array. This relays on assumption that custom fields doesn't have functions for creating sort array.
        else {
            return [{
                attribute: sortId,
                order: sortDirection ? 'asc' : 'desc'
            }];
        }
    },
    showPreviousPage: function(ev) {
        ev.preventDefault();

        this.collection.collectionPage--;
        this.collection.collectionPage = Math.max(this.collection.collectionPage, 1);
        this.fetchCollection(true);
    },
    showNextPage: function(ev) {
        ev.preventDefault();

        this.collection.collectionPage++;
        this.fetchCollection(true);
    }
});

import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'
import TextManager from 'app/text-manager'

import backboneSelect2 from 'js/widgets/backbone-select2'
import template from 'templates/cohort_analysis.handlebars'


export default Marionette.Layout.extend({
    template: Handlebars.compile(template),
    ui: {
        groups: '.groups-select',
        switcherDealsVolumePhase: '.deals-volume .switch',
        switcherLabel: '.field-label',
        widgetContainer: '.widget-container',
        phases: '.phases',
        lostValue: '.lost-value',
        wonValue: '.won-value'
    },
    events: {
        'change .groups-select': function() {
            this.fetchGroupsData();
        },

        'click .deals-volume .switch': function() {
            this.setSwitcher(!this.switcherInDeals);
            this.fetchGroupsData();
        }
    },

    initialize: function(options) {
        this.options = options;
        // this.chart = new D3LeadQualityChart();
    },

    renderWidget: function(data) {
        // this.chart.width(this.ui.widgetContainer.width());
        // this.chart.height(this.ui.widgetContainer.height());
        // this.chart.data(data);

        // d3.select(this.ui.widgetContainer.get(0)).call(this.chart);


        //TEMP until we have a widget specification
        this.ui.lostValue.text(data.lost);
        this.ui.wonValue.text(data.won);

        this.ui.phases.empty();
        for (var i = 0; i < data.phases_info.length; ++i) {
            var p = data.phases_info[i];
            this.ui.phases.append($('<li>').text(p.phase.name + ": " + p.group + "/" + p.total));
        }
    },

    onRender: function() {
        this.initGroupsSelectBox(this.options.groups);

        this.setSwitcher(true);
        this.fetchGroupsData();
    },

    fetchGroupsData: function() {
        // ...
        var view = this;
        var data = {
            group: this.ui.groups.select2('val'),
            deals: this.switcherInDeals
        };

        $.post(
            '/widgets/cohort_analysis',
            JSON.stringify(data),
            function(d) {
                view.renderWidget(d);
            }
        );
    },

    initGroupsSelectBox: function(groups) {
        var data = [];
        var keys = _.keys(groups);

        for (var i = 0; i < keys.length; ++i) {
            data.push({
                name: groups[keys[i]].name,
                id: groups[keys[i]].id
            });
        }

        //
        this.reachingPhaseSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.groups,
            text: 'name',
            data: data,
            value: data[0],

            options: {
                containerCssClass: 'select2-plain select2-block title',
                dropdownCssClass: 'phase-select-popover popover'
            }
        });
    },

    setSwitcher: function(deals) {
        this.switcherInDeals = deals;

        this.ui.switcherDealsVolumePhase.toggleClass('toggled', !deals);
        this.ui.switcherLabel.text(deals ? TextManager.parseText('# ${ID_DEAL, plural, capitalize}') : '$ Volume');
    }
});

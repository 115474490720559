import React from 'react'

import LoadingIndicator from 'js/react_views/widgets/loading-indicator'

import style from './development_head_counter.css'
import app from 'js/app'

export default class DevelopmentHeadCounter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visitors: 0,
            fetchingData: false,
            funnel_stat_id: '',
            showAddVisitor: true
        };
        
        const self = this;

        this.props.widget.addEventListener('refresh', function() {
            self.fetchData(true);
        });

        this.props.widget.addEventListener('cluster:change', function() {
            const {last_visited_region_funnels} = app.user.get('preferences')
            
            self.setState({
                showAddVisitor: !last_visited_region_funnels.isCluster
            })
        });

        

        _.defer(function() {
            self.fetchData();
        });
    }

    fetchData(force) {
        if (!this.mounted) {
            return;
        }

        let query = this.props.widget.params.query;
        if (!query) {
            return;
        }

        /*
            The reason date is set here and not in builtin_dashboard.js is,
            I think builtin_dashboard loads once, so if the session stays open
            for a couple of days, date would not update.
        */
        const today = new Date().toISOString().slice(0, 10)
        query.args['entry_date'] = today;

        // only displays the loader the first time
        if (this.state.value === null) {
            this.setState({
                fetchingData: true
            });
        }
        let options = _.clone(query.options || {});
        options.force = force;

        this.props.widget.fetcher.get(query.url, query.args, this.onData.bind(this), options);
    }

    onData(data) {
        if (!this.mounted) {
            return;
        }

        if (data && data.length > 0) {
            this.setState({
                visitors: data.reduce((accumulator, currentFunnelStat) => accumulator + currentFunnelStat.visitors, 0),
                funnel_stat_id: data[0]['id'],
                fetchingData: false
            });
        }
    }

    handleExpandClick() {
        let self = this;

        $.ajax({
            type: 'PATCH',
            url: `/funnel_stats_increment/${this.state.funnel_stat_id}`,
            dataType: 'json',
            success: function(data) {
                if (data) {
                    self.setState({
                        visitors: data['visitors'],
                    });
                }
            }
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const params = this.props.widget.params;
        const renderer = this.props.renderer;
        const showVisitorClicker = this.state.showAddVisitor

        let iconContainerStyle = {};
        let secondRowStyle = {};
        let thirdRowTitleStyle = {};

        if (renderer) {
            const bbox = renderer.getBoundingClientRect();
            const rectSize = Math.max(bbox.height / 3.2, 37);

            iconContainerStyle = {
                width: rectSize,
                height: rectSize,
                fontSize: Math.max(bbox.height / 5.4, 22)
            };

            secondRowStyle = {
                fontSize: Math.max(bbox.height / 4.3, 28)
            };

            thirdRowTitleStyle = {
                fontSize: Math.max(bbox.height / 12, 10)
            };
        }

        return (
            <div className={style.headCounter}>
                <div className={style.LeftSide}>
                    <div className={style.firstRow}>
                        <div
                            className={style.tTitle}
                            style={thirdRowTitleStyle}
                        >
                            {params.title}
                        </div>

                        <div
                            className={style.fIconContainer}
                            style={iconContainerStyle}
                        >
                            <div className={params.icon}/>
                        </div>
                    </div>


                    {this.state.fetchingData ? (
                        <div className={style.kSecondRow}>
                            <LoadingIndicator/>
                        </div>
                    ) : (
                        <div
                            className={style.kSecondRow}
                            style={secondRowStyle}
                        >
                            {this.state.visitors}
                        </div>
                    )}
                </div>

                {showVisitorClicker && <div className={style.rightSide}>
                    <div className={style.firstRow}>
                        <div
                            className={style.fIconContainer}
                            style={iconContainerStyle}
                            onClick={this.handleExpandClick.bind(this)}
                        >
                            <div className='icon-plus'></div>
                        </div>
                    </div>

                    <div 
                        className={style.secondRow}
                        style={thirdRowTitleStyle}
                    >
                        Add Visitor
                    </div>
                </div>}
            </div>
        )
    }
}
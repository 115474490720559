import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import AppConfig from 'app/app-config'
import vent from 'js/vent'
import TextManager from 'app/text-manager'
import LoadingView from 'js/views/loading'
import EditIntegrationSettings from 'js/views/settings/edit_integration_settings'
import EditWebTrackingSettings from 'js/views/settings/edit_web_tracking_settings'
import EditWebLeadsSettings from 'js/views/settings/edit_web_leads_settings'
import EditThirdPartyFormLeadsSettings from 'js/views/settings/edit_third_party_form_leads_settings'
import EditUserManagementSettings from 'js/views/settings/edit_user_management_settings'
import EditLeadSources from 'js/views/settings/edit_lead_sources'
import editMailingLists from 'js/views/settings/edit_mailing_lists'
import EditBucketSettings from 'js/views/settings/edit_bucket_settings'
import EditForecastCurrencySettings from 'js/views/settings/edit_forecast_currency_settings'
import EditCreditCardSettings from 'js/views/settings/edit_credit_card_settings'
import EditBillingAddressSettings from 'js/views/settings/edit_billing_address_settings'
import EditSubscriptionSettings from 'js/views/settings/edit_subscription_settings'
import EditDefaultPermissions from 'js/views/settings/edit_default_permissions'
import EditTeamManagement from 'js/views/settings/team_management/team_management';
import EditFunnelPhasesSettings from 'js/views/settings/edit_funnel_phases_settings'
import EditReportingPeriodSettings from 'js/views/settings/edit_reporting_period_settings'
import EditCustomFieldsSettings from 'js/views/settings/edit_custom_fields_settings'
import EditChecklistsSettings from 'js/views/settings/edit_checklists_settings'
import EditTagsSettings from 'js/views/settings/edit_tags_settings'
import EditAdvancedSettings from 'js/views/settings/edit_ui_settings'
import EditTasksSettings from 'js/views/settings/edit_tasks_settings'
import EditDebugSettings from 'js/views/settings/edit_debug_settings'
import EditSecuritySettings from 'js/views/settings/edit_security_settings'
import ImportContacts from 'js/views/settings/import_contacts'
import ImportOrganizations from 'js/views/settings/import_organizations'
import ImportDeals from 'js/views/settings/import_deals'
import settingsTemplate from 'templates/settings.handlebars';


var SettingsView = Marionette.Layout.extend({
    id: 'app-settings-modal-inner',
    template: Handlebars.compile(settingsTemplate),
    tagName: 'article',
    templateHelpers: function() {
        return {
            list: this.list
        };
    },
    list: [
        {
            visible: false,
            title: 'Company',
            description: 'Global settings for your company account'
        },
        {
            visible: true,
            title: 'Users',
            description: 'Manage users and permissions and configure hierarchy',
            children: [
                {
                    title: 'User Management',
                    description: 'Create, edit, and delete users',
                    viewId: 'editUserManagementSettings',
                    visible: true
                },
                {
                    title: 'Team Management',
                    description: 'Configure and manage your teams',
                    viewId: 'editTeamManagement',
                    visible: true
                },
                {
                    title: 'Permission Defaults',
                    description: 'Set default permissions for different items',
                    viewId: 'editDefaultPermissions',
                    visible: true
                },
                {
                    title: 'Security',
                    description: 'Setup Two-Factor Authentication (2FA)',
                    viewId: 'editSecuritySettings',
                    visible: function() {
                        return app.user.get('is_helper');
                    }
                }
            ]
        },
        {
            visible: true,
            title: 'Web',
            description: 'Configuration for Web tracking and registration',
            children: [
                {
                    title: 'Web Tracking',
                    description: 'Setup and configure Web Tracking for your website',
                    viewId: 'editWebTrackingSettings',
                    visible: true
                },
                {
                    title: 'Web Leads',
                    description: 'Setup creation of leads from Web registrations',
                    viewId: 'editWebLeadsSettings',
                    visible: true
                },
                {
                    title: 'Third Party Form Leads',
                    description: 'Gather leads from Web form services',
                    viewId: 'editThirdPartyFormLeadsSettings',
                    visible: true
                }
            ]
        },
        {
            visible: true,
            title: 'Leads',
            description: 'Create, edit, and delete lead sources',
            viewId: 'editLeadSources'
        },
        {
            visible: true,
            title: TextManager.parseText('${ID_DEAL, capitalize, plural} & ${ID_FORECAST, capitalize, plural}'),
            description: TextManager.parseText('Create, edit, and delete revenue buckets and ${ID_PHASE, plural}'),
            children: [
                {
                    title: 'Revenue Buckets',
                    description: TextManager.getText('ID_APP_SETTINGS_REVENUE_BUCKETS_DESCRIPTION'),
                    viewId: 'editBucketSettings',
                    visible: true
                },
                {
                    title: 'Reporting Periods',
                    description: 'Set dates for reporting periods over the next two years',
                    viewId: 'editReportingPeriodSettings',
                    visible: true
                },
                {
                    title: TextManager.parseText('${ID_FUNNEL, plural, capitalize}'),
                    description: TextManager.parseText('Manage ${ID_FUNNEL, capitalize, plural} and ${ID_PHASE, capitalize, plural} here'),
                    viewId: 'editFunnelPhases',
                    visible: true
                },
                {
                    title: 'Currency',
                    description: TextManager.getText('ID_APP_SETTINGS_CURRENCY_DESCRIPTION'),
                    viewId: 'editForecastCurrencySettings',
                    visible: true
                }
            ]
        },
        {
            visible: false,
            title: 'Tasks',
            description: 'Manage default tasks creation date',
            viewId: 'editTasksSettings'
        },
        {
            visible: true,
            title: 'Mailing Lists',
            description: 'Manage your public mailing list groups',
            viewId: 'editMailingLists',
            disabledInTier: 'starting'
        },
        {
            visible: true,
            title: 'Data Import',
            description: TextManager.getText('ID_APP_SETTINGS_DATA_IMPORT_DESCRIPTION'),
            children: [
                {
                    title: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY', ['${ID_INDIVIDUAL, plural, capitalize}']),
                    description: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY_DESCRIPTION', ['${ID_INDIVIDUAL, plural}']),
                    viewId: 'importContacts',
                    visible: true
                },
                {
                    title: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY', ['${ID_ORGANIZATION, plural, capitalize}']),
                    description: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY_DESCRIPTION', ['${ID_ORGANIZATION, plural}']),
                    viewId: 'importOrganizations',
                    visible: true
                },
                {
                    title: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY', ['${ID_DEAL, plural, capitalize}']),
                    description: TextManager.getText('ID_APP_SETTINGS_IMPORT_ENTITY_DESCRIPTION', ['${ID_DEAL, plural}']),
                    viewId: 'importDeals',
                    visible: true
                },
                {
                    title: 'Review Photos',
                    description: 'Verify and select profile photos for your imported contacts',
                    visible: true
                }
            ]
        },
        {
            visible: true,
            icon: 'icon-upload',
            title: 'Custom Fields',
            description: 'Set your custom fields',
            children: [
                {
                    title: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CUSTOM_FIELDS_DESCRIPTION', ['${ID_INDIVIDUAL, plural}']),
                    viewId: 'editIndividualCustomFieldsSettings',
                    params: JSON.stringify( { fieldsView: 'individuals', fieldsTitle: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}') } ),
                    visible: true
                },
                {
                    title: TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CUSTOM_FIELDS_DESCRIPTION', ['${ID_ORGANIZATION, plural}']),
                    viewId: 'editOrganizationCustomFieldsSettings',
                    params: JSON.stringify( { fieldsView: 'organizations', fieldsTitle: TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}') } ),
                    visible: true
                },
                {
                    title: TextManager.parseText('${ID_DEAL, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CUSTOM_FIELDS_DESCRIPTION', ['${ID_DEAL, plural}']),
                    viewId: 'editDealCustomFieldsSettings',
                    params: JSON.stringify( { fieldsView: 'deals', fieldsTitle: TextManager.parseText('${ID_DEAL, plural, capitalize}') } ),
                    visible: true
                }
            ]
        },
        {
            visible: function() {
                return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
            },
            icon: 'icon-upload',
            title: 'Checklists',
            description: 'Create, edit and delete checklists',
            children: [
                {
                    title: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CHECKLISTS_DESCRIPTION', ['${ID_INDIVIDUAL, plural}']),
                    viewId: 'editIndividualChecklistsSettings',
                    params: JSON.stringify( { fieldsView: 'individuals', fieldsTitle: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}') } ),
                    visible: true
                },
                {
                    title: TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CHECKLISTS_DESCRIPTION', ['${ID_ORGANIZATION, plural}']),
                    viewId: 'editOrganizationChecklistsSettings',
                    params: JSON.stringify( { fieldsView: 'organizations', fieldsTitle: TextManager.parseText('${ID_ORGANIZATION, plural, capitalize}') } ),
                    visible: true
                },
                {
                    title: TextManager.parseText('${ID_DEAL, plural, capitalize}'),
                    description: TextManager.getText('ID_APP_SETTINGS_CHECKLISTS_DESCRIPTION', ['${ID_DEAL, plural}']),
                    viewId: 'editDealChecklistsSettings',
                    params: JSON.stringify( { fieldsView: 'opportunities', fieldsTitle: TextManager.parseText('${ID_DEAL, plural, capitalize}') } ),
                    visible: true
                }
            ]
        },
        {
            visible: true,
            title: 'Integrations',
            description: 'Connect to 3rd party applications',
            viewId: 'editIntegrationSettings'
        },
        {
            visible: true,
            title: 'Tags',
            description: 'Define tags for marking records and activities',
            viewId: 'editTagsSettings'
        },
        {
            visible: true,
            title: 'Advanced Settings',
            description: 'Configure additional advanced settings',
            viewId: 'editAdvancedSettings'
        },
        {
            visible: true,
            hasInitialLoading: true,
            title: 'Payment and Billing',
            description: 'View and manage your billing address and payment details',
            children: [
                {
                    title: '1. Billing Details',
                    description: 'Billing address and tax details used for invoicing',
                    viewId: 'editBillingAddressSettings',
                    visible: true
                },
                {
                    title: '2. Subscription Details',
                    description: 'Price information and currency selection for your subscription',
                    viewId: 'editSubscriptionSettings',
                    visible: true
                },
                {
                    title: '3. Payment Details',
                    description: 'View your plan and edit your payment method',
                    viewId: 'editCreditCardSettings',
                    visible: true
                }
            ]
        },
        {
            visible: function() {
                /*
                    https://salesseek.atlassian.net/browse/SAL-5520
                    HIDE DEBUG SETTINGS MENU
                    FOR NOW WE DECIDED ONLY TO HIDE IT, NOT REMOVE
                */
                return false;
                return app.user.get('is_helper');
            },
            title: 'Debug',
            description: 'Manage debug utilities',
            viewId: 'editDebugSettings'
        }
    ],
    regions: {
        navPanel: '.settings-nav-panel',
        detailPanel: '.settings-detail-panel'
    },
    ui: {
        accordions: '.accordion-item'
    },
    events: {
        // 'click .close': function() { vent.trigger('settings:hide'); },
        'click .close': function() { this.trigger( 'close' ); },
        'click .accordion-title': function(ev) {
            var self = this,
                accordion = $(ev.currentTarget).parent('.accordion-item');

            if (accordion.hasClass('disabled')) {
                return;
            }

            function scrollToItem () {
                // Scroll to expanded accordion
                self.$el.find('.settings-nav-panel .content').animate({
                    scrollTop: accordion.get(0).offsetTop
                }, 300, 'easeInOutQuint');
                self.scrollbar();
            }

            accordion
                .toggleClass('expanded')
                .find('.accordion-content').slideToggle(200, 'easeOutQuint', scrollToItem);

            this.hideSubItems(accordion);
        },
        'click .basic-list-item': function(ev) {
            var target = $(ev.currentTarget);

            if (target.parent().hasClass('disabled')) {
                return;
            }

            // Prevent removing active class from accordion children
            if (!target.hasClass('accordion-title')) {
                this.highlightActive(target);
            }

            if (target.attr('view')) {
                this.showDetailView(target.attr('view'), target.attr('hasInitialLoading'), target.attr('params'));
            }
        }
    },
    initialize: function(options) {
        _.extend(this, options);

        this.listenTo(this.detailPanel, 'show close', function() {
            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        });
    },
    onShow: function(){
        // Currently the only way to add attributes to the ModalRegion el
        this.$el.parent().attr('id', 'app-settings-modal');
        this.scrollbar();

        if (this.viewId) {
            var selectedItem = this.$el.find('.basic-list-item[view="' + this.viewId + '"]');
            var parentItem = selectedItem;
            while (true) {
                parentItem = parentItem.parent().parent()
                    .closest('.accordion-item.has-children')
                    .find('> .basic-list-item.has-description.accordion-title')
                    .click();
                if (!parentItem.length) {
                    break;
                }
            }
            selectedItem.click();
        }

        var itemsDisabled = this.$el.find('.accordion-item.disabled');
        var self = this;

        itemsDisabled.each(function() {
            $(this).tooltip({
                title: 'Upgrade account to access ' + self.getDisabledInfoByViewId($(this).find('.basic-list-item').attr('view')),
                container: 'body',
                placement: 'right'
            });
        });

        vent.trigger('settings:on:show');
    },
    getDisabledInfoByViewId: function(viewId) {
        if (viewId === 'editMailingLists') {
            return 'Campaigns';
        }

        return '';
    },
    onClose: function() {
        vent.trigger('settings:on:close');
    },
    scrollbar: function() {
        this.$el.find('.settings-nav-panel .content-container').nanoScroller();
    },
    showDetailView: function(viewId, hasInitialLoading, params) {
        this.viewId = viewId;

        var views = {
            'editIntegrationSettings': EditIntegrationSettings,
            'editFunnelPhases': EditFunnelPhasesSettings,
            'editWebTrackingSettings': EditWebTrackingSettings,
            'editUserManagementSettings': EditUserManagementSettings,
            'importContacts': ImportContacts,
            'importOrganizations': ImportOrganizations,
            'importDeals': ImportDeals,
            'editLeadSources': EditLeadSources,
            'editMailingLists': editMailingLists,
            'editBucketSettings': EditBucketSettings,
            'editForecastCurrencySettings': EditForecastCurrencySettings,
            'editCreditCardSettings': EditCreditCardSettings,
            'editBillingAddressSettings': EditBillingAddressSettings,
            'editSubscriptionSettings': EditSubscriptionSettings,
            'editDefaultPermissions': EditDefaultPermissions,
            'editTeamManagement': EditTeamManagement,
            'editWebLeadsSettings': EditWebLeadsSettings,
            'editThirdPartyFormLeadsSettings': EditThirdPartyFormLeadsSettings,
            'editReportingPeriodSettings': EditReportingPeriodSettings,
            'editIndividualCustomFieldsSettings': EditCustomFieldsSettings,
            'editOrganizationCustomFieldsSettings': EditCustomFieldsSettings,
            'editDealCustomFieldsSettings': EditCustomFieldsSettings,
            'editIndividualChecklistsSettings': EditChecklistsSettings,
            'editOrganizationChecklistsSettings': EditChecklistsSettings,
            'editDealChecklistsSettings': EditChecklistsSettings,
            'editTagsSettings': EditTagsSettings,
            'editAdvancedSettings': EditAdvancedSettings,
            'editTasksSettings': EditTasksSettings,
            'editDebugSettings': EditDebugSettings,
            'editSecuritySettings': EditSecuritySettings
        };

        var paramsDict = {};

        if (params) {
            paramsDict = $.parseJSON(params);
        }

        var nextView = new views[viewId](paramsDict);

        this.viewWaitingInitialLoading = null;

        if (hasInitialLoading) {
            var view = this;

            this.viewWaitingInitialLoading = nextView;
            var loadingView = new LoadingView();
            this.detailPanel.show(loadingView);

            this.listenTo(nextView, 'initialLoadingFinished', function(waitingView) {
                if (view.viewWaitingInitialLoading === waitingView) {
                    view.viewWaitingInitialLoading = null;
                    view.detailPanel.show(nextView);
                }
            } );
        }
        else {
            this.detailPanel.show(nextView);
        }
    },
    highlightActive: function(target) {
        var activeItem = target.closest('.nav-item'),
            activeGroup = activeItem.closest('.accordion-item');

        this.hideSubItems(activeGroup);

        this.$el.find('.nav-item.active').not(activeItem).removeClass('active');
        activeItem.addClass('active');
        this.$el.find('.accordion-item.has-active').not(activeGroup).removeClass('has-active');
        activeGroup.addClass('has-active');
    },
    hideSubItems: function(active) {
        var self = this;
        this.$el.find('.accordion-item.expanded').not(active)
            .removeClass('expanded')
            .find('.accordion-content').slideUp(200, 'easeOutQuint', function() {
                self.scrollbar();
            });
    },
    getUrl: function() {
        if (this.viewId) {
            return this.viewId;
        } else {
            return 'settings';
        }
    }
});

export default SettingsView;

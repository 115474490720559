import _ from 'underscore'

import app from 'js/app'
import PaginatedListView from 'js/views/base/paginated_list'
import LeadItemView from 'app/_components/lead-item/lead-item'
import PDCRMLeadsCollection from 'js/collections/pdcrm_leads'
import LeadsCollection from 'js/collections/leads'
import ListNavbarView from 'js/views/base/list_navbar'
import BaseToolbarView from 'js/views/base/toolbar'
import ActivityCreator from 'js/utils/activity_creator'
import vent from 'js/vent'
import AppConfig from 'app/app-config'


var NavView;

function createLeadsListView(options) {
    var fetchOptions = {
        sortOn: [{
            attribute: 'became_lead_date',
            order: 'desc'
        }],
        data: _.extend({
            my_team: true,
            has_activity: false,
        }, options.collectionData) || {}
    };

    // is there a filter to define what a lead is?
    var clientPreferences = app.user.get('client').preferences || {};

    if (clientPreferences.dashboard_leads_list_filter_id) {
        fetchOptions.data.by_filter_id = clientPreferences.dashboard_leads_list_filter_id;
        fetchOptions.data.check_is_lead = false;
    }

    var collection = null;

    if (AppConfig.getValue('use_pdrcm_improved_endpoints')) {
        collection = new PDCRMLeadsCollection();
    } else {
        collection = new LeadsCollection();
    }

    return new PaginatedListView(_.extend({
        id: 'leads-list',
        listItemView: LeadItemView,
        listItemViewOptions: {
            section: 'Leads',
            url: 'leads',
            isDraggable: options.parent.options.itemsDraggable,
            showTags: options.parent.options.showTags
        },
        collection: collection,
        fetchOptions: fetchOptions
    }, options));
}

function createLeadsToolbarView(collection) {
    var selectAllPage = true;

    if (AppConfig.getValue('is_pdcrm')) {
        selectAllPage = app.user.get('is_admin') || app.user.get('is_helper');
    }

    return new BaseToolbarView({
        collection: collection,
        tools: {
            newItem: true,
            deleteItem: true,
            selectAllPage: selectAllPage,
        }
    });
}

NavView = ListNavbarView.extend({
    title: 'Leads',
    showTabs: false,
    tabs: [
        {
            id: 'leads',
            title: 'Leads',
            class: 'list-nav-leads',
            view: createLeadsListView,
            toolbarView: createLeadsToolbarView
        }
    ],
    initialize: function() {
        ListNavbarView.prototype.initialize.apply(this, arguments);

        this.listenTo(this, 'showItem', function(model) {
            this.trigger('push-view:individual:show', {model: model});
        });

        // Open a new-form when clicking on the toolbar '+' button
        this.listenTo(this, 'toolbar:new', function() {
            this.trigger('push-view:individual:new', {
                leadSource: { system_type: 'unspecified' }
            });
        });

        this.listenTo(this, 'toolbar:delete', function(sel) {
            this.deleteItems( sel, { url: '/individuals/' } );
        });

        this.listenTo(this, 'followLink', function(model, link) {
            app.followLink(model, link);
        });
    },
    getUrl: function() {
        return 'leads';
    }
});

export default NavView;

import _ from 'underscore'

import BaseContactModel from 'js/models/basecontact'
import LocationModel from 'js/models/location'
import LocationsCollection from 'js/collections/locations'
import CommunicationModel from 'js/models/communication'
import ActivityModel from 'js/models/activity'
import BaseCollection from 'js/collections/base'
import UserModel from 'js/models/user'
import vent from 'js/vent'
import TextManager from 'app/text-manager'


var OrganizationModel = BaseContactModel.extend({
    name: 'Organization',
    urlRoot: '/organizations',
    apiType: 'salesseek.contacts.models.organization.Organization',
    initialize: function() {
        BaseContactModel.prototype.initialize.apply(this, arguments);
        this.listenTo(this, 'sync', function(model, resp) {
            if (!resp) {
                vent.trigger('organization:delete', model);
            }
        });
    },
    validate: function(attrs) {
        var errors = {};

        if (this.isNew()) {
            if (_.isUndefined(attrs.name)) {
                errors.missing_organization_name = TextManager.parseText('${ID_ORGANIZATION, capitalize} name required');
            }
        }

        if (!_.isUndefined(attrs.name)) {
            if (_.isNull(attrs.name)) {
                errors.null_attribute = true;
            }
            else if (attrs.name.length === 0) {
                errors.organization_name_too_short = 'One character minimum required';
            }
        }

        // if location is defined
        if (_.isObject(attrs.locations) && attrs.locations.length !== 0) {
            _.each(attrs.locations.models, function(location) {
                if ( !location.isValid() ) {
                    errors.missing_location++;
                }
            });
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    toString: function() {
        return this.get('name');
    }
});
OrganizationModel.fromString = function (string) {
    return new OrganizationModel({name: string});
};

export default OrganizationModel;

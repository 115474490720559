import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'
import React from 'react';
import ReactDOM from 'react-dom';

import backboneSelect2 from 'js/widgets/backbone-select2'
import DateTimePicker from 'js/widgets/date-time-picker'
import MessageBox from 'js/views/message_box'
import dateFormat from 'js/utils/date-format'
import vent from 'js/vent'
import LeadListView from 'app/_components/lead-listing/lead-list'
import IncomingLeadListView from 'app/dashboard/_components/incoming-lead-list/incoming-lead-list'
import ActivityListView from 'js/views/activity/list'
import LeadActivityListView from 'app/dashboard/_components/lead-activity-list/lead-activity-list'
import SocialFeedView from 'js/views/social/feed'
import GroupModel from 'js/models/group'
import ClientModel from 'js/models/client'
import GroupSelectorView from 'app/dashboard/widgets/group-selector/selector'
import OpportunitiesCollection from 'js/collections/opportunities'
import PhasesCollection from 'js/collections/phases'
import PeriodsCollection from 'js/collections/periods'
import FunnelsCollection from 'js/collections/funnels'
import LeadsCollection from 'js/collections/leads'
import CustomFieldsCollection from 'js/collections/custom_fields'
import IndividualFilterModel from 'js/models/individual_filter'
import ForecastModel from 'js/models/forecast'
import ForecastsCollection from 'js/collections/forecasts'
import UserModel from 'js/models/user'
import TeamsCollection from 'js/collections/teams'
import OpportunityModel from 'js/models/opportunity'
import TeamSelector from 'js/widgets/team-selector'
import FunnelsKpi from 'js/widgets/funnels-kpi'
import FunnelViewEx from 'js/views/funnelEx'
import LeadQualityView from 'js/views/lead_quality'
import CohortAnalysisView from 'js/views/cohort_analysis'
import RevenueCampaignView from 'js/views/revenue_campaign'
import CampaignContributionView from 'js/views/campaign_contribution'
import SocialMediaView from 'js/views/social_media'
import WebStatsReducedView from 'js/views/web_stats_reduced'
import StatsProvider from 'js/views/stats_provider'
import TimespanHeader from 'js/views/timespan_header'
import KPIView from 'js/views/kpi'
import GroupsSelectorView from 'js/views/groups_selector'
import GroupsCollection from 'js/collections/groups'
import ModalRegion from 'js/views/base/modal-region'
import app from 'js/app'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import Currency from 'js/utils/currency'
import LoadingView from 'js/views/loading'
import IpwTrigger from 'js/ipw_trigger'
import PipelineView from 'app/dashboard/widgets/pipeline/pipeline'
import RevenuePlannerView from 'app/dashboard/widgets/revenue-planner/revenue-planner'
import IntentDashboardView from 'app/dashboard/react/intent'
import FlexDashboardView from 'js/react_views/flex-dashboard/dashboard'
import dashboardTemplate from 'app/dashboard/dashboard.handlebars'
import telesalesDashboardTemplate from 'app/dashboard/telesales-dashboard.handlebars'
import salesPersonDashboardTemplate from 'app/dashboard/salesperson-dashboard.handlebars'
import salesManagerDashboardTemplate from 'app/dashboard/salesmanager-dashboard.handlebars'
import salesManagerHillGroupDashboardTemplate from 'app/dashboard/salesmanager-hillgroup-dashboard.handlebars'
import marketingDashboardTemplate from 'app/dashboard/marketing-dashboard.handlebars'
import ceoDashboardTemplate from 'app/dashboard/ceo-dashboard.handlebars'
import forecastWidgetTemplate from 'templates/forecasts/widget.handlebars'
import activitySummaryTemplate from 'app/dashboard/activity-summary.handlebars'

var Dashboard, TelesalesDashboard, SalesPersonDashboard, SalesManagerDashboard, SalesManagerHillGroupDashboard, CeoDashboard, ForecastWidgetView,
    ActivitySummaryView, TeamDashboard, MarketingDashboard, IntentDashboard, FlexDashboard;

// ------------------------------------------------------------------------
//  UTIL functions
//  TODO: This functions should be factored away, most probably moved to
//        the model layer
//

var onCreateDealFromLead = function(context, info) {
    var orgName = info.lead.get('organization_name');
    var dealName = 'Deal ' + (orgName ? orgName : info.lead.get('full_name'));

    var data = {
        name: dealName,
        funnelId: info.funnelId,
        phaseId: info.phaseId,
        organization: info.lead.get('organization')
    };

    context.trigger('push-view:deal:new', {initialData: data});
};

var dealPhaseChange = function(view, opportunity, data, onComplete) {
    var deal = data.deal;
    var dealId = data.dealId;
    var phase = data.phase;
    var funnel = data.funnel;

    if (phase || funnel) {
        data = {
            id: dealId
        };

        if (phase) {
            data.phase_id = phase.id;
        }
        else {
            data.funnel_id = funnel.id;
        }

        if(AppConfig.getValue('showIPWCampaignTriggerMessage', false) && phase && deal) {
            const currentPhase = deal.get('phase');

            if (currentPhase && (phase.order > currentPhase.order)) {
                var mbContent = {
                    message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                    icon: 'icon-warning'
                };

                const updateFields = function(phase, data, opportunity, onComplete, triggerIpw) {
                    var ipwTrigger = new IpwTrigger();

                    ipwTrigger.getPopulatedIpwCustomFields(phase.name, triggerIpw, null, function(fields) {
                        data = _.extend(data, fields);
                        updateDealPhaseChange(data, opportunity, onComplete);
                    });
                }

                MessageBox.showYesNo(mbContent, view,
                    function() { // Yes
                        updateFields(phase, data, opportunity, onComplete, true);
                    },
                    function() { // No
                        updateFields(phase, data, opportunity, onComplete, false);
                    }, null,
                    {
                        staticRegion: true
                    }
                );
            } else {
                updateDealPhaseChange(data, opportunity, onComplete);
            }
        } else {
            updateDealPhaseChange(data, opportunity, onComplete);
        }
    }
};

var updateDealPhaseChange = function(data, opportunity, onComplete){
    opportunity.save(data, {
        patch: true,
        validate: false,
        ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
        params: {
            check_phase_change_allowed: true,
            check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
        },
        error: function(model, response, settings) {
            var detail;

            try {
                detail = JSON.parse(response.responseText).detail;
            }
            catch (e) {
            }

            if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' ||
            detail.exception === 'OpportunityPhaseGateNotClear')) {
                vent.trigger('alert:hide', true);
                vent.trigger('alert:show', {
                    type: function() {
                        var message = detail.message
                            if (detail.exception === 'OpportunityPhaseGateNotClear'){
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        },
        complete: function() {
            if (onComplete) {
                onComplete();
            }
        }
    });
}

var getLeadDefinitionFilterId = function(callback) {
    var leadDefinition = AppConfig.getValue('leadDefinition');

    if (leadDefinition) {
        var customFields = new CustomFieldsCollection();

        customFields.fetch({
            rows: -1,
            filterBy: [{
                attribute: 'view',
                value: 'individuals'
            }],
            success: function(data){
                var cf = _.find(data.models, function(item) { return item.get('name') === leadDefinition[0] });

                if (cf) {
                    var option = _.find(cf.get('options') || [], function(option) { return option.value === leadDefinition[1] });

                    if (option) {
                        var filter = new IndividualFilterModel();

                        filter.save({rules: [[{ custom: cf.get('id'), field: 'individual_custom', operator: 'equal', values: option.id }]]}, {
                            alert: false,
                            success: function(data) {
                                callback(data.id);
                            },
                            error: function() {
                                callback(null);
                            }
                        });
                    } else {
                        callback(null);
                    }
                }
                else {
                    callback(null);
                }
            },
            error: function() {
                callback(null);
            }
        });
    } else {
        callback(null);
    }
};

var funnelHelper = {
    initialize: function(context) {
        context.funnelIsExpanded = false;
        context.dealViewOpened = context.options.dealViewOpened || null;
    },

    onShow: function(context) {
        var funnelContainer = context.$el.find('.funnel-widget-container');

        context.listenTo(context.funnelView, 'funnel:expanded', function() {
            context.funnelIsExpanded = true;
            funnelContainer.addClass('expanded');
        });

        context.listenTo(context.funnelView, 'funnel:shrinked', function() {
            var dealId = null;

            if (context.dealViewOpened) {
                dealId = context.dealViewOpened.model.get('id');
                context.dealViewOpened.closeView();
                funnelContainer.removeClass('deal-shown');
            }

            context.funnelIsExpanded = false;
            funnelContainer.removeClass('expanded');

            if (dealId) {
                context.showDeal(dealId);
            }
        });

        context.listenTo(context.funnelView, 'show-deal', function(dealId) {
            context.showDeal(dealId);
        });
    },

    showDeal: function(context, dealId) {
        var options = {
            id: dealId,
            onViewCreation: function(view) {
                context.dealViewOpened = view;

                if (context.funnelIsExpanded) {
                    var funnelContainer = context.$el.find('.funnel-widget-container');

                    context.listenTo(view, 'render', function() {
                        funnelContainer.addClass('deal-shown');
                        context.funnelView.renderFunnel();
                    });

                    context.listenTo(view, 'view:close', function() {
                        context.dealViewOpened = null;
                        funnelContainer.removeClass('deal-shown');
                        _.defer(function() {
                            context.funnelView.renderFunnel();
                        });
                    });

                    context.listenTo(view, 'replace-view:organization:show replace-view:individual:show', function() {
                        context.dealViewOpened = null;
                        funnelContainer.removeClass('deal-shown');
                        context.funnelView.shrink();
                    });
                }
            }
        };

        if (context.funnelIsExpanded) {
            options.viewOptions = {
                condensed: true
            };
        }

        context.listenTo(vent, 'deal:view:closed', function() {
            context.dealViewOpened = null;
        });

        context.trigger('push-view:deal:show', options);
    }
};


Dashboard = Marionette.Layout.extend({
    template: Handlebars.compile(dashboardTemplate),
    regions: {
        roleDashboardRegion: '.role-dashboard-container'
    },
    initialize: function(options) {
        this.options = _.extend({}, options);
    },
    getUrl: function() {
        if (this.roleDashboardRegion && this.roleDashboardRegion.currentView) {
            return this.roleDashboardRegion.currentView.getUrl();
        } else {
            return 'dashboard';
        }
    },
    onDealViewOpened: function(dealView) {
        // this function is called from appcontroller:dealDetail()
        this.options.dealViewOpened = dealView;
    },
    getParams: function() {
        if (this.roleDashboardRegion &&
            this.roleDashboardRegion.currentView &&
            this.roleDashboardRegion.currentView.getParams
        ) {
            return this.roleDashboardRegion.currentView.getParams();
        } else {
            return {};
        }
    },
    onRender: function() {
        var preferences = app.user.get('preferences'),
            default_dashboard = preferences && preferences['default_dashboard'],
            dashboard;

        const newDashboardEnabled = AppConfig.getValue('dashboard.new_flex_dashboard_enabled', false)

        if (newDashboardEnabled && !AppConfig.getValue('dashboard.is_enabled', true, default_dashboard)) {
            default_dashboard = 'team-beta';

            const configDashboardId = AppConfig.getValue('dashboard.default_dashboard_id', false);
            if (configDashboardId) {
                default_dashboard = configDashboardId;
            }
        }

        if (default_dashboard === 'ceo') {
            this.roleDashboardRegion.show(new CeoDashboard(this.options));
        }
        else if (default_dashboard === 'sales_manager') {
            if (AppConfig.getValue('dashboard.sales_manager.use_hillgroup_dashboard')) {
                this.roleDashboardRegion.show(new SalesManagerHillGroupDashboard(this.options));
            } else {
                dashboard = new SalesManagerDashboard(this.options);
                this.roleDashboardRegion.show(dashboard);

                // if we don't have a team, SalesManagerDashboard can't be rendered smoothly
                this.listenTo(dashboard, 'noTeam', function() {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: "Sales manager has no team, redirecting to sales person dashboard",
                                timer: 3000
                            };
                        }
                    });
                    this.roleDashboardRegion.show(new SalesPersonDashboard(this.options));
                    // Bubble events
                    this.listenTo(this.roleDashboardRegion.currentView, 'all', function(name, options) {
                        this.trigger(name, options);
                    });
                });
            }
        }
        else if (default_dashboard === 'telesales') {
            this.roleDashboardRegion.show(new TelesalesDashboard(this.options));
        }
        else if (default_dashboard === 'team') {
            this.roleDashboardRegion.show(new TeamDashboard(this.options));
        } else if (['new-team', 'team-beta', 'sales', 'persimmon-team-beta'].indexOf(default_dashboard) !== -1) {
            this.roleDashboardRegion.show(new FlexDashboard(_.extend(this.options, {parent: this, dashboardId: default_dashboard})));
        } else if (default_dashboard === 'marketing') {
            this.roleDashboardRegion.show(new MarketingDashboard(this.options));
        }
        else if ((default_dashboard === 'intent') && AppConfig.getValue('enableIntentDashboard')) {
            this.roleDashboardRegion.show(new IntentDashboard(this.options));
        }
        else {
            this.roleDashboardRegion.show(new SalesPersonDashboard(this.options));
        }

        // Bubble events
        this.listenTo(this.roleDashboardRegion.currentView, 'all', function(name, options) {
            this.trigger(name, options);
        });
    },
    onShow: function() {
        // Hacky way of overriding right stacks column width
        if (app.user.get('email_address') === 'alex.bell@aptui.com') {
            this.$el.parent().parent().addClass('four-column-stacks');
        }

        // Tell the body the dashboard is visible
        // TODO: cleaner solution
        $('body').addClass('dashboard-visible');
    },
    onClose: function() {
        // Hacky way of overriding right stacks column width
        if (app.user.get('email_address') === 'alex.bell@aptui.com') {
            this.$el.parent().parent().removeClass('four-column-stacks');
        }

        // Tell the body the dashboard has closed
        // TODO: cleaner solution
        $('body').removeClass('dashboard-visible');
    }
});

MarketingDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'marketing-dashboard',
    className: 'dashboard at-top',
    template: Handlebars.compile(marketingDashboardTemplate),
    regions: {
        pipelineRegion: '.pipeline-widget-container',
        leadQualityRegion: '.lead-quality-widget-container',
        revenueCampaignRegion: '.revenue-campaign-widget-container',
        campaignContributionRegion: '.campaign-contribution-widget-container',
        timespanRegion: '.timespan-header-container',
        webStatsRegion: '.web-stats-widget-container',
        socialStatsRegion: '.social-stats-widget-container',
        KPIRegion: '.kpi-widget-container',
        cohortAnalysisRegion: '.cohort-pipeline-widget-region',
        leadsListRegion: '.leads-widget-container',
        addRemoveGroups: {
            selector: '.groups-selector',
            regionType: ModalRegion
        },
        loaderRegion: '.loader-container'
    },
    ui: {
        dashboardPanels: '.dashboard-panel',
        header: '.dashboard-header',
        footer: '.dashboard-footer',
        scroll: '.dashboard-content.content-container > .content',
        campaignPanel: '.campaign-panel',
        webStatsPanel: '.web-stats-panel',
        leadsListPanel: '.leads-widget-container',
        pipelinePanel: '.pipeline-widget-container',
        KPIPanel: '.kpi-widget-container',
        dashboardContent: '.dashboard-content'
    },
    events: {
        'click .groups-selector': function(ev) {
            ev.preventDefault();

            var groupsSelector = new GroupsSelectorView();
            var view = this;

            this.listenTo(groupsSelector, 'close', function() {
                view.addRemoveGroups.reset();
            });

            this.listenTo(groupsSelector, 'save', function(d) {
                view.selectedGroups = d.slice(0);

                var model = new ClientModel(app.user.get('client'));
                model.save({'marketing_groups': {groups: JSON.stringify(d)}}, {patch: true});

                view.addRemoveGroups.reset();
                view.loadGroups();
            });

            if (this.groupsLoaded) {
                groupsSelector.setGroups(this.allGroups, this.selectedGroups);
            }

            this.addRemoveGroups.show(groupsSelector);
        }
    },
    initialize: function(options) {
        this.widgetsCreated = false;
        this.statsProvider = new StatsProvider.WebStatsProvider();
        this.statsProvider.setFilter('timespan', [{
            type: 'lastPeriod',
            period: 'day'
        }]);
        this.loadGroups();

        this.setTarget(options.target);
    },
    setTarget: function(targetId) {
        this.target = targetId;

        var targets = {
            'leads': 'leads-target-visible'
        };
        var target = targets[targetId];

        this.$el
            .removeClass(_.reject(targets, function(item) {
                return item === target;
            }).join(' '))
            .addClass(target);

        // Update scrollbar as layout may have changed
        this.scrollbar();
    },
    loadGroups: function() {
        this.groupsLoaded = false;

        var view = this;
        var gc = new GroupsCollection();
        gc.fetch({
            rows: -1
        });

        this.listenTo(gc, 'sync', function() {
            view.allGroups = {};

            for(var i = 0; i < gc.models.length; ++i) {
                var g = gc.models[i];
                view.allGroups[g.get('id')] = {
                    id: g.get('id'),
                    name: g.get('name')
                };
            }

            // load groups and check that them still exist
            view.selectedGroups = [];
            $.get('/client_marketing_groups', function(beg) {
                if (beg && beg.groups) {
                    var groups = JSON.parse(beg.groups);

                    for (var j = 0; j < groups.length; ++j) {
                        if (view.allGroups[groups[j]]) {
                            view.selectedGroups.push(groups[j]);
                        }
                    }
                }

                if (view.addRemoveGroups.currentView) {
                    view.addRemoveGroups.currentView.setGroups(view.allGroups, view.selectedGroups);
                    // view.render();
                }

                view.groupsLoaded = true;
                view.hideLoader();
                view.showWidgets();
            });
        });
    },
    onRender: function() {
        this.showLoader();
        this.resizeCallback = function () {
            this.scrollbar();
            this.updateCharts();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);
    },
    updateCharts: _.debounce(function() {
        if (this.isClosed) {
            return;
        }
        if (this.webStatsRegion.currentView && !this.webStatsRegion.currentView.isClosed) {
            this.webStatsRegion.currentView.updateData();
        }
        if (this.socialStatsRegion.currentView && !this.socialStatsRegion.currentView.isClosed) {
            this.socialStatsRegion.currentView.updateData();
        }
    }, 200),
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    updateFilters: function(name, value) {
        this.statsProvider.setFilter(name, value);

        var filters = this.statsProvider.getFilters();
        this.timespanRegion.currentView.updateFilters(filters);
        this.webStatsRegion.currentView.updateFilters(filters);
        this.socialStatsRegion.currentView.updateFilters(filters);

        this.fetchData();
    },
    showWidgets: function() {
        var view = this;

        // Init Scrollbars
        this.scrollbar();
        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        if (!this.widgetsCreated) {

            var timespanHeader = new TimespanHeader();
            this.listenTo(timespanHeader, 'setFilter', this.updateFilters);
            this.listenTo(timespanHeader, 'render', function() {
                _.defer(function() {
                    var filters = view.statsProvider.getFilters();
                    view.timespanRegion.currentView.updateFilters(filters);
                    view.webStatsRegion.currentView.updateFilters(filters);
                    view.socialStatsRegion.currentView.updateFilters(filters);
                });
            });
            this.timespanRegion.show(timespanHeader);

            this.leadQualityRegion.show(new LeadQualityView({groups: this.selectedGroups}));
            this.revenueCampaignRegion.show(new RevenueCampaignView({groups: this.selectedGroups}));
            this.campaignContributionRegion.show(new CampaignContributionView({
                groups: this.selectedGroups,
                allGroups: this.allGroups
            }));
            this.ui.campaignPanel.removeClass('before-load hidden');
            this.KPIRegion.show(new KPIView());
            this.ui.KPIPanel.removeClass('before-load hidden');

            this.showPipeline('week', 'Weekly Change', null, null);

            this.listenTo(vent, 'updateTimeSpan', function(timeSpan, timeSpanLabel, customBeginDate, customEndDate) {
                this.showPipeline(timeSpan, timeSpanLabel, customBeginDate, customEndDate);
                this.showLeadsList(timeSpan, customBeginDate, customEndDate);
            });

            var socialMediaView = new SocialMediaView();
            this.listenTo(socialMediaView, 'setFilter', this.updateFilters);
            this.socialStatsRegion.show(socialMediaView);
            this.initWebStats();
            this.ui.webStatsPanel.removeClass('before-load hidden');

            // this.cohortAnalysisRegion.show(new CohortAnalysisView({groups: this.allGroups}));
            this.showLeadsList();

            // Update scrollbar
            // TODO: this should ideally update at the correct time
            // i.e. when ALL the widgets have loaded and fully rendered
            this.scrollbar();

            this.listenTo(this.leadQualityRegion.currentView, 'expand', this.expandCampaignPanel);
            this.listenTo(this.revenueCampaignRegion.currentView, 'expand', this.expandCampaignPanel);
            this.listenTo(this.campaignContributionRegion.currentView, 'expand', this.expandCampaignPanel);

            this.listenTo(this.leadQualityRegion.currentView, 'group_highlighted', this.groupHighlighted);
            this.listenTo(this.revenueCampaignRegion.currentView, 'group_highlighted', this.groupHighlighted);

            this.listenTo(this.leadQualityRegion.currentView, 'group_selected', this.groupSelected);
            this.listenTo(this.revenueCampaignRegion.currentView, 'group_selected', this.groupSelected);
            this.listenTo(this.campaignContributionRegion.currentView, 'group_selected', this.groupSelected);

            // Link scrolling between lead quality chart and revenue campaign chart
            var allScrolls = [
                    this.leadQualityRegion.currentView.ui.scroll,
                    this.revenueCampaignRegion.currentView.ui.scroll
                ];
            var linkedScrolls = $($.map(allScrolls, function(el){return el.get();}));
            linkedScrolls.scroll(function(){
                linkedScrolls.scrollTop($(this).scrollTop());
            });

            this.widgetsCreated = true;
        }
        else { // we only update the groups
            if (this.leadQualityRegion.currentView) {
                this.leadQualityRegion.currentView.onGroupsDataChanged(this.selectedGroups);
            }

            if (this.revenueCampaignRegion.currentView) {
                this.revenueCampaignRegion.currentView.onGroupsDataChanged(this.selectedGroups);
            }

            if (this.campaignContributionRegion.currentView) {
                this.campaignContributionRegion.currentView.onGroupsDataChanged(this.selectedGroups);
            }
        }
    },
    initWebStats: function() {
        var webStats = new WebStatsReducedView({filtersEnabled: false});

        this.listenTo(webStats, 'setFilter', this.updateFilters);

        this.webStatsRegion.show(webStats);
        this.fetchData();
    },
    showPipeline: function(timespan, timespanLabel, beginDate, endDate) {
        var funnels = new FunnelsCollection();
        var self = this;

        var url = `/pipeline?time_span=${timespan}`

        if(timespan === 'custom' && beginDate && endDate){
            url += `&begin_date=${beginDate}&end_date=${endDate}`
        }

        funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                $.ajax({
                    url: url,
                    success: function(data) {
                        if (self.isClosed) {
                            return;
                        }

                        var currency = app.user.get('client')['default_currency'];

                        var models = _.map(data.phase_stats, function(d) {
                            return new Backbone.Model({
                                id: null,
                                phase: d.phase.name,
                                phase_type: d.phase.phase_type,
                                funnel_id: d.phase.funnel_id,
                                value: Currency.shortFormat(currency, d.value),
                                count: d.total,
                                change: d.delta
                            });
                        });

                        for (var funnelId in data.won_stats.funnels) {
                            var stats = data.won_stats.funnels[funnelId];

                            models.push(new Backbone.Model({
                                phase: data.won_stats.phase.name,
                                phase_type: 'won',
                                funnel_id: funnelId,
                                value: Currency.shortFormat(currency, stats.value),
                                count: stats.total,
                                change: stats.delta
                            }));
                        }

                        for (var funnelId in data.lost_stats.funnels) {
                            var stats = data.lost_stats.funnels[funnelId];

                            models.push(new Backbone.Model({
                                phase: data.lost_stats.phase.name,
                                phase_type: 'lost',
                                funnel_id: funnelId,
                                value: Currency.shortFormat(currency, stats.value),
                                count: stats.total,
                                change: stats.delta
                            }));
                        }

                        models.unshift(new Backbone.Model({
                            id: null,
                            phase: 'Leads',
                            value: Currency.shortFormat(currency, data.lead_stats.value),
                            count: data.lead_stats.total,
                            change: data.lead_stats.delta
                        }));

                        var collection = new Backbone.Collection(models);

                        if (self.pipelineRegion) {
                            self.pipelineRegion.show(new PipelineView({
                                funnels: funnels,
                                collection: collection,
                                period: timespanLabel
                            }));
                            self.ui.pipelinePanel.removeClass('before-load hidden');
                            self.listenTo(self.pipelineRegion.currentView, 'expand', function(view, callback) {
                                self.expandPanel(self.ui.pipelinePanel, callback);
                            });
                            self.listenTo(self.pipelineRegion.currentView, 'animation-finished', function() {
                                self.scrollbar();
                            });
                        }
                    }
                });
            }
        });

        /*
        var self = this,
            models,
            collection,
            currency;

        $.ajax({
            url: '/pipeline',
            success: function(data) {
                if ( self.isClosed ) {
                    return;
                }

                currency = app.user.get('client')['default_currency'];

                models = _.map(data.phase_stats, function(d) {
                    return new Backbone.Model({
                        id: null,
                        phase: d.phase.name,
                        value: Currency.shortFormat(currency, d.value),
                        count: d.total,
                        change: d.delta
                    });
                });

                models.unshift(new Backbone.Model({
                    id: null,
                    phase: 'Leads',
                    value: Currency.shortFormat(currency, data.lead_stats.value),
                    count: data.lead_stats.total,
                    change: data.lead_stats.delta
                }));

                collection = new Backbone.Collection(models);

                if (self.pipelineRegion) {
                    self.pipelineRegion.show(new PipelineView({
                        collection: collection
                    }));
                    self.ui.pipelinePanel.removeClass('before-load hidden');
                    self.listenTo(self.pipelineRegion.currentView, 'expand', function(view, callback) {
                        self.expandPanel(self.ui.pipelinePanel, callback);
                    });
                    self.listenTo(self.pipelineRegion.currentView, 'animation-finished', function() {
                        self.scrollbar();
                    });
                }
            }
        });
*/
    },
    showLeadsList: function(timeSpan='week', beginDate, endDate) {
        var self = this;

        getLeadDefinitionFilterId(function(filterId) {
            var options = null;

            if (filterId) {
                options = {
                    collectionData: {
                        by_filter_id: filterId,
                        check_is_lead: false,
                    }
                };
            }

            if (timeSpan){

                if (!options){
                    options = {
                        collectionData: {
                            time_span: timeSpan
                        }
                    }
                } else {
                    options.collectionData.time_span = timeSpan;
                }

                if(timeSpan === 'custom'){
                    options.collectionData.period_begin_date = beginDate;
                    options.collectionData.period_end_date = endDate;
                }

            }

            var leadListView = new IncomingLeadListView(options);

            self.listenTo(leadListView, 'push-view:individual:show', function(options) {
                self.setTarget('leads');
                self.trigger('push-view:individual:show', options);
            });

            self.listenTo(leadListView, 'push-view:individual:new', function(options) {
                self.setTarget('leads');
                self.trigger('push-view:individual:new', options);
            });

            self.leadsListRegion.show(leadListView);
            self.ui.leadsListPanel.removeClass('before-load hidden');
        });
    },
    fetchData: function() {
        var view = this;

        this.statsProvider.fetchData(this.statsProvider.getFilters(), null, function(context, data) {
            if (!view.isClosed) {
                view.webStatsRegion.currentView.updateData(data.get('time'), data.get('segmentation'));
                view.socialStatsRegion.currentView.fetchData(view.statsProvider.getFilters());
            }
        });
    },
    expandPanel: function(el, callback) {
        var expanded = el.hasClass('expanded'),
            dummy;

        if (!expanded) {
            dummy = $(el[0].cloneNode(false)).removeClass('expanded').addClass('dummy');

            dummy.css('height', el.height());

            dummy.insertAfter(el);

            el.css({
                position: 'absolute',
                top: el.offset().top,
                left: el.offset().left - this.$el.offset().left,
                height: el.outerHeight(),
                width: el.outerWidth(),
                margin: '0'
            });

            el.insertAfter(this.$el.find('.dashboard-content'));

            el.addClass('expanded');
            this.$el.addClass('inactive');

            el
                .stop()
                .animate({
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%'
                }, 300, 'easeOutQuart', function() {
                    if (callback) {
                        callback();
                    }
                });
        }
        else {
            dummy = this.$el.find('.dummy');

            this.$el.removeClass('inactive');

            el
                .removeClass('expanded')
                .stop()
                .animate({
                    top: dummy.offset().top,
                    left: dummy.offset().left - this.$el.offset().left,
                    height: dummy.outerHeight(),
                    width: dummy.outerWidth()
                }, 300, 'easeOutQuart', function() {

                    el.insertAfter(dummy);

                    dummy.remove();

                    $(this)
                        .css({
                            position: '',
                            top: '',
                            left: '',
                            height: '',
                            width: '',
                            margin: ''
                        });

                    if (callback) {
                        callback();
                    }
                });
        }
    },
    groupSelected: function(widget, group) {
        this.leadQualityRegion.currentView.onGroupSelected(group);
        this.revenueCampaignRegion.currentView.onGroupSelected(group);
        this.campaignContributionRegion.currentView.onGroupSelected(group);
    },
    groupHighlighted: function(widget, group) {
        if (widget !== this.leadQualityRegion.currentView) {
            this.leadQualityRegion.currentView.onGroupHighlighted(group);
        }

        if (widget !== this.revenueCampaignRegion.currentView) {
            this.revenueCampaignRegion.currentView.onGroupHighlighted(group);
        }

        if (widget !== this.campaignContributionRegion.currentView) {
            this.campaignContributionRegion.currentView.onGroupHighlighted(group);
        }
    },
    // Specific expanding for campaign panel and its widgets
    expandCampaignPanel: function(widget, callback) {
        var widgetEl = widget.$el.parent(),
            inactive = this.ui.campaignPanel.find('.widget-container').not(widgetEl);

        var afterExpand = function() {
            callback();
            widgetEl.removeClass('expanding');
        };

        widgetEl.addClass('expanding');

        if (!widget.expanded) {
            this.expandPanel(widgetEl, afterExpand);
            inactive.addClass('inactive');
        } else {
            this.expandPanel(widgetEl, _.debounce(function() {
                afterExpand();
                inactive.removeClass('inactive');
            }, 50));
        }
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.round( (this.$el.height() - this.ui.header.height()) ),
            contentHeight = Math.ceil( container.find('.content-inner:first').outerHeight(true) ),
            height = Math.min(availableHeight, contentHeight);

        container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    },
    getUrl: function() {
        return 'dashboard/marketing';
    },
    getParams: function() {
        var params = {};

        if (this.target) {
            params.target = this.target;
        }
        return params;
    },
    showLoader: function() {
        this.ui.dashboardContent.hide();
        this.loaderRegion.show( new LoadingView() );
    },
    hideLoader: function() {
        this.ui.dashboardContent.show();
        this.loaderRegion.reset();
    }
});

TelesalesDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'telesales-dashboard',
    template: Handlebars.compile(telesalesDashboardTemplate),
    regions: {
        activityListRegion: '.activity-feed-widget',
        socialListRegion: '.social-list-widget',
        leadsListRegion: '.leads-list-widget',
        dayListRegion: '.day-list-widget'
    },
    initialize: function() {
        var view = this;

        this.listenTo(vent, 'organization:delete individual:delete deal:delete', function() {
            view.showActivityList();
        });
    },
    onRender: function() {
        this.showActivityList();
        this.showSocialList();
        this.showDayList();
        this.showLeadsList();
    },
    showActivityList: function() {
        this.activityListRegion.show(new ActivityListView({
            parent: this
        }));

        this.listenTo(this.activityListRegion.currentView, 'selectActivityItem', function(type, id) {
            var correctedType;

            correctedType = {
                'individuals': 'individual',
                'leads': 'individual',
                'organizations': 'organization',
                'opportunities': 'deal'
            }[type];

            this.trigger('push-view:' + correctedType + ':show', {id: id});
        });

        this.listenTo(this.activityListRegion.currentView, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }

            this.trigger('push-view:' + type + ':show', model);
        }, this);
    },
    showSocialList: function() {
        var listView = new SocialFeedView();

        this.listenTo(listView, 'push-view:individual:show', function(options) {
            this.trigger('push-view:individual:show', options);
        });

        this.socialListRegion.show(listView);
    },
    showDayList: function() {
        var listView;

        listView = new GroupSelectorView();

        this.listenTo(listView, 'push-view:individual:show', function(options) {
            this.trigger('push-view:individual:show', options);
        });
        this.listenTo(listView, 'push-view:deal:show', function(options) {
            this.trigger('push-view:deal:show', options);
        });
        this.listenTo(listView, 'push-view:organization:show', function(options) {
            this.trigger('push-view:organization:show', options);
        });

        this.dayListRegion.show(listView);
    },
    showLeadsList: function() {
        var self = this;

        getLeadDefinitionFilterId(function(filterId) {
            var options = {
                showTags: AppConfig.getValue('dashboard.leads.show_tags')
            };

            if (filterId) {
                options.collectionData = {
                    by_filter_id: filterId,
                    check_is_lead: false
                };
            }

            var leadListView = new LeadListView(options);

            self.listenTo(leadListView, 'push-view:individual:show', function(options) {
                self.trigger('push-view:individual:show', options);
            });

            self.listenTo(leadListView, 'push-view:individual:new', function(options) {
                self.trigger('push-view:individual:new', options);
            });

            self.leadsListRegion.show(leadListView);
        });
    },
    getUrl: function() {
        return 'dashboard/telesales';
    }
});

SalesManagerHillGroupDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'sales-manager-dashboard',
    template: Handlebars.compile(salesManagerHillGroupDashboardTemplate),
    regions: {
        funnelRegion: '.funnel-widget-container',
        leadsListRegion: '.leads-list-widget',
        forecastRegion: '.forecast-widget',
        funnelsKpiRegion: '.funnels-kpi-container'
    },
    events: {
        'show-deal': function(ev, id) {
            ev.stopPropagation();
            this.showDeal(id);
        },
        'change-funnel': function(ev, id) {
            this.activeFunnel = id;
            this.showFunnel();
            app.user.updatePreference('last_visited_funnel_id', id);
        },
        'deal-phase-change': function(ev, data) {
            const self = this;

            dealPhaseChange(this, new OpportunityModel({id: data.dealId}), data, function() {
                self.showFunnel();
            });
        },
        'create-deal-from-lead': function(ev, info) {
            onCreateDealFromLead(this, info);
        }
    },
    initialize: function(options) {
        this.funnelView = new FunnelViewEx({hideWeeks: true });
        this.activeFunnel = null;
        this.setTarget(options.target);

        funnelHelper.initialize(this);

        const self = this;

        this.listenTo(vent, 'deal:save', function() {
            self.fetchDeals(function() {
                self.funnelView.renderFunnel();
            });
        });
    },
    onShow: function() {
        this.funnelRegion.show(this.funnelView);
        this.activeFunnel = null;

        _.defer(function() {
            var container = $('#sales-manager-dashboard .funnel-widget-container');
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                _.debounce(function(ev) {
                    if (ev.target.className === container.attr('class')) {
                        this.funnelView.renderFunnel();
                    }
                }.bind(this), 100)
            );
        }.bind(this));

        this.showFunnel();
        this.showLeadsList();
        this.funnelsKpiRegion.show(new FunnelsKpi());

        funnelHelper.onShow(this);
    },
    showDeal: function(id) {
        this.setTarget('funnel');
        funnelHelper.showDeal(this, id);
    },
    showFunnel: function() {
        var periods = new PeriodsCollection();
        var funnels = new FunnelsCollection();
        var promises = [];

        promises.push(funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }]
        }));

        promises.push(periods.fetch({}));

        var self = this;

        $.when(...promises).done(function() {
            if (self.isClosed) {
                return;
            }

            var checkReady = function() {
                if (self.phases && self.deals) {
                    self.funnelView.renderFunnel();
                }
            }

            self.activeFunnel = self.activeFunnel || funnels.getLastVisitedFunnelId();
            self.funnels = funnels;
            self.funnelView.setFunnels(funnels.models, self.activeFunnel);
            self.fetchDeals(checkReady);

            var phases = new PhasesCollection();

            phases.fetch({
                data: {
                    funnel_id: self.activeFunnel
                },
                success: function() {
                    self.phases = phases;
                    self.funnelView.setPhases(phases.models);
                    checkReady();
                }
            });
        });
    },
    showLeadsList: function() {
        var leadsListView = new LeadActivityListView({
            itemsDraggable: true,
            showTags: AppConfig.getValue('dashboard.leads.show_tags'),
            preferencesKey: 'sales_manager_dashboard_tab_id'
        });

        this.listenTo(leadsListView, 'push-view:individual:show', function(options) {
            this.setTarget('leads');
            this.trigger('push-view:individual:show', options);
        });

        this.listenTo(leadsListView, 'push-view:individual:new', function(options) {
            this.setTarget('leads');
            this.trigger('push-view:individual:new', options);
        });

        this.listenTo(leadsListView, 'selectActivityItem', function(type, id) {
            var correctedType;

            correctedType = {
                'individuals': 'individual',
                'leads': 'individual',
                'organizations': 'organization',
                'opportunities': 'deal'
            }[type];

            this.setTarget('activity');
            this.trigger('push-view:' + correctedType + ':show', {id: id});
        });

        this.listenTo(leadsListView, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            this.setTarget('activity');
            this.trigger('push-view:' + type + ':show', model);
        }, this);

        this.leadsListRegion.show(leadsListView);
    },
    showForecastWidget: function(forecasts, deals) {
        if (this.forecastRegion) {
            this.forecastRegion.show(new ForecastWidgetView({
                collection: forecasts,
                funnel: this.funnelView,
                deals: deals
            }));
            this.forecastRegion.$el.removeClass('before-load hidden');
        }
    },
    fetchDeals: function(callback) {
        var self = this;

        var doFetch = function(orderBy) {
            var deals = new OpportunitiesCollection();
            var data = {
                funnel_id: self.activeFunnel,
                by_period_id: 'current',
                owner_id: self.funnelView.allOrMyDeals.id === 'my_deals' ? app.user.get('id') : undefined,
                snapshot: AppConfig.getValue('funnelPopup') === 'extended',
                order_by: orderBy || 'name asc'
            };

            deals.fetch({
                rows: -1,
                data: data,
                success: function() {
                    if (self.isClosed) {
                        return;
                    }

                    self.deals = deals;
                    self.funnelView.setDeals(deals.models);
                    self.fetchForecastData();

                    if (callback) {
                        callback();
                    }
                }
            });
        }

        var customOrderBy = AppConfig.getValue('deals.custom_order_by');

        if (customOrderBy && app.globalData.dealsCustomOrderBy !== null) {
            if (!_.isUndefined(app.globalData.dealsCustomOrderBy)) {
                doFetch(app.globalData.dealsCustomOrderBy);
            } else {
                if (customOrderBy.is_custom_field) {
                    var customFields = new CustomFieldsCollection();

                    customFields.fetch({
                        rows: -1,
                        filterBy: [{
                            attribute: 'view',
                            value: 'opportunities'
                        }],
                        success: function(res) {
                            var customCF = res.models.find(m => m.get('name').toLowerCase() === customOrderBy.field.toLowerCase());

                            if (customCF) {
                                app.globalData.dealsCustomOrderBy = `custom_fields.${customCF.get('id')} ${customOrderBy.dir}`;
                                doFetch(app.globalData.dealsCustomOrderBy);
                            } else {
                                app.globalData.dealsCustomOrderBy = null;
                                doFetch();
                            }
                        }
                    });
                } else {
                    doFetch();
                }
            }
        } else {
            doFetch();
        }
    },
    fetchForecastData: function() {
        var self = this;
        var collection = new ForecastsCollection();

        collection.fetch({
            url: '/forecasts',
            data: {
                my_team: false,
                rows: -1,
                order_by: 'published_date desc'
            },
            success: function() {
                if ( collection.length > 0 ) {
                    self.showForecastWidget(collection, self.deals);
                } else {
                    self.showForecastWidget(null, self.deals);
                }
            }
        });
    },
    setTarget: function(targetId) {
        this.target = targetId;

        var targets = {
            'funnel': 'funnel-target-visible',
            'activity': 'activity-target-visible',
            'leads': 'leads-target-visible'
        };
        var target = targets[targetId];

        this.$el
            .removeClass(_.reject(targets, function(item) {
                return item === target;
            }).join(' '))
            .addClass(target);
    },
    onClose: function() {
        this.funnelView.close();
    },
    getUrl: function() {
        return 'dashboard/sales-manager';
    },
    getParams: function() {
        var params = {};

        if (this.target) {
            params.target = this.target;
        }
        return params;
    }
});

SalesManagerDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'sales-manager-dashboard',
    template: Handlebars.compile(salesManagerDashboardTemplate),
    events: {
        'show-deal': function(ev, id) {
            this.showDeal(id);
        },
        'deal-phase-change': 'dealPhaseChange',
        'change-funnel': 'changeFunnel',
        'create-deal-from-lead': function(ev, info) {
            onCreateDealFromLead(this, info);
        }
    },
    regions: {
        funnelRegion: '.funnel-widget-container',
        teamListRegion: '.team-list-container',
        activitySummaryRegion: '.activity-summary-widget-container',
        leadsListRegion: '.leads-list-widget',
        forecastRegion: '.forecast-widget'
    },
    initialize: function(options) {
        var view = this;
        this.funnelView = new FunnelViewEx({ hideWeeks: true });
        this.selectedUserIdx = -1;
        this.listenTo(vent, 'deal:save', function() {
            if (view.activeUserData) {
                view.fetchUserOpps(view.activeUserData, view.selectedUserIdx === 1, function() {
                    view.showUser(view.activeUserData);
                });
            }
        });

        this.setTarget(options.target);
        this.activeFunnel = null;

        funnelHelper.initialize(this);
    },
    onRender: function() {
        var self = this;

        this.fetchData(function() {
            self.showTeamSelector();
        });

        this.showLeadsList();

        funnelHelper.onShow(this);
    },
    /**
     * Creates team selector which loads team structure. Initially loads team with manager as current user.
     */
    showTeamSelector: function() {
        if(this.isClosed){
            return;
        }

        this.teamSelector = new TeamSelector({ managerId: app.user.id, showArrow: true });
        this.teamListRegion.show(this.teamSelector);
        // use listenToOnce after library update
        this.listenTo(this.teamSelector, 'team:loaded', function(managerId) {
            if (!managerId) {
                this.trigger('noTeam');
            }
            else {
                this.loadTeam(managerId);
            }
        }, this);
        this.listenTo(this.teamSelector, 'individual:clicked', function(individualId) {
            let user = { id: individualId };
            this.updateSelectedUserIdx(user);
            this.fetchExtraData(false, this.selectedUserIdx);
        }, this);
    },
    /**
     * Loads team data for Sales Manager Dashboard.
     *
     * @param managerId     team manager id
     */
    loadTeam: function(managerId) {
        this.funnelView.showLoader();

        var self = this;
        // Fetch the team
        var teams = new TeamsCollection();
        teams.fetch({
            url: '/v1/users/' + managerId + '/dashboards/sales_manager/teams',
            success: function() {
                self.selectedUserIdx = -1;
                self.teams = teams;
                self.onTeam(teams.models[0]);
            }
        });
    },
    onShow: function() {
        this.funnelRegion.show( this.funnelView );
        this.funnelView.showLoader();

        _.defer(function() {
            var container = $('#sales-manager-dashboard .funnel-widget-container');
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                _.debounce(function(ev) {
                    if (ev.target.className === container.attr('class')) {
                        this.renderFunnel();
                    }
                }.bind(this), 100)
            );
        }.bind(this));
    },
    showDeal: function(id) {
        this.setTarget('funnel');
        funnelHelper.showDeal(this, id);
    },
    changeFunnel: function(ev, id) {
        this.activeFunnel = id;
        this.funnelView.setFunnels(this.funnels.models, this.activeFunnel);
        this.funnelView.renderFunnel();
        app.user.updatePreference('last_visited_funnel_id', id);
    },
    fetchUserOpps: function(userData, isManagerMember, callback) {
        var url,
            data = {},
            oppCollection = new OpportunitiesCollection(),
            view = this;

        if (isManagerMember) {
            // For the manager listed as the first team member, we want just their own deals.
            url = '/opportunities?compact';
            data.owner_id = userData.user.id;
            data.rows = -1;
            data.phase_types = 'user';
            data.snapshot = AppConfig.getValue('funnelPopup') === 'extended';
        } else {
            // Everyone else, get deals recursively as the user might be managing a team.
            // This includes user 0, the manager representing the current team
            url = '/opportunities?compact';
            data = {
                team: true,
                team_manager_id: userData.user.id,
                by_period_id: 'current',
                rows: -1,
                phase_types: 'user',
                snapshot: AppConfig.getValue('funnelPopup') === 'extended'
            }
        }
        oppCollection.fetch({
            url: url,
            data: data,
            success: function() {
                view.funnelView.hideLoader();
                userData.opportunities = oppCollection;
                if (callback) {
                    callback();
                }
            }
        });
    },
    /**
     * Loads phases, periods ands favorite opportunities
     *
     * @param callback  runs after all data loaded
     */
    fetchData: function(callback) {
        var view = this,
            phases,
            funnels,
            periods;

        this.periods = null;
        this.funnels = null;

        function checkReady() {
            if (view.phases && view.periods && view.funnels) {
                callback();
            }
        }

        // Fetch the funnels
        funnels = new FunnelsCollection();
        funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                view.activeFunnel = view.activeFunnel || funnels.getLastVisitedFunnelId();
                view.funnels = funnels;

                if (!view.funnelView.isClosed) {
                    view.funnelView.setFunnels( view.funnels.models, view.activeFunnel );
                }

                checkReady();
            }
        });

        // Fetch the phases
        phases = new PhasesCollection();
        phases.fetch({
            success: function() {
                view.phases = phases;
                view.funnelView.setPhases( view.phases.models );
                checkReady();
            }
        });

        // Fetch periods
        periods = new PeriodsCollection();
        periods.fetch({
            success: function() {
                view.periods = periods;
                view.funnelView.setPeriods( view.periods.models );
                checkReady();
            }
        });
    },
    fetchExtraData: function(excludeForecasts, dataId = 0) {

        if (!excludeForecasts) {
            this.loadForecastsByDataId(dataId);
        }

        this.loadUserOppsByDataId(dataId);
    },
    loadForecastsByDataId: function(dataId) {
        let userData = this.data[dataId];
        let view = this;
        let everyone = userData.user.get('id') === 'everyone';

        userData.forecasts = null;
        userData.forecast = null;

        var forecastsCollection = new ForecastsCollection();
        forecastsCollection.fetch({
            url: everyone ? '/forecasts' : ('/users/' + userData.user.id + '/forecasts'),
            data: {
                rows: -1,
                order_by: 'published_date desc'
            },
            success: function() {
                userData.forecasts = forecastsCollection;
                userData.forecast = forecastsCollection.length ? forecastsCollection.models[0] : null;
                view.checkExtraDataReady(dataId);
            }
        });
    },
    loadUserOppsByDataId: function(dataId) {
        let view = this;
        let userData = this.data[dataId];
        userData.opportunities = null;

        if (userData.user.get('id') === 'everyone') {
            var oppCollection = new OpportunitiesCollection();

            oppCollection.fetch({
                url: '/opportunities',
                data: {
                    by_period_id: 'current',
                    rows: -1,
                    snapshot: AppConfig.getValue('funnelPopup') === 'extended'
                },
                success: function() {
                    view.funnelView.hideLoader();
                    userData.opportunities = oppCollection;
                    view.checkExtraDataReady(dataId);
                }
            });
        } else {
            this.fetchUserOpps(userData, dataId === 1, function() {
                view.checkExtraDataReady(dataId);
            });
        }
    },
    checkExtraDataReady: function (dataId) {
        let userData = this.data[dataId];
        if (userData.forecasts!==null && userData.opportunities!==null) {
            this.onExtraData(dataId);
        }
    },
    onTeam: function(team) {
        if (!team) {
            app.user.updatePreference('default_dashboard', 'sales_executive');
            vent.trigger('dashboard');
            return;
        }

        var view = this;

        if (this.isClosed) {
            return;
        }

        // Store the team
        this.team = team;

        this.users = [];
        _.each(this.team.get('members'), function(d) {
            view.users.push(new UserModel(d));
        });

        var computeTotalStats = false;
        var totalStatistics = null;

        // insert the manager at the beginning of the list to represent the team
        if (this.team.get('manager')) {
            this.users.splice(0, 0, new UserModel(this.team.get('manager')));
        } else {
            this.users.splice(0, 0, new UserModel({id: 'everyone'}));
            computeTotalStats = true;
        }

        this.data = _.map(this.users, function(user) {
            var statistics = user.get('statistics');

            if (statistics && computeTotalStats) {
                if (totalStatistics) {
                    totalStatistics.opportunities.won.total_value += statistics.opportunities.won.total_value;
                    totalStatistics.activities.total += statistics.activities.total;
                    totalStatistics.activities.with_communication_medium.phone += statistics.activities.with_communication_medium.phone;
                    totalStatistics.activities.with_communication_medium.email += statistics.activities.with_communication_medium.email;
                } else {
                    totalStatistics = _.clone(statistics);
                }
            }

            return {
                user: user,
                statistics: statistics,
                forecast: null,
                forecasts: null,
                opportunities: null
            };
        });

        if (computeTotalStats) {
            this.data[0].statistics = totalStatistics;
        }


        // Fetch forecasts
        this.fetchExtraData();
    },
    onExtraData: function(loadedDataId) {
        if (this.selectedUserIdx===loadedDataId) {
            this.showUser(this.data[this.selectedUserIdx]);
        } else if (this.selectedUserIdx===-1) {
            this.showUser(this.data[0]);
        }
    },
    updateSelectedUserIdx: function (user) {
        // Get data for that user
        // User 0 is the team. Only individual members can be selected
        for (var i = 1; i < this.data.length; ++i) {
            var d = this.data[i];

            if (d.user.id !== user.id) {
                continue;
            }
            this.selectedUserIdx = i;
        }
    },
    showUser: function(userData) {
        var deals = userData.opportunities;
        this.activeUserData = userData;

        // Render funnel
        if (deals) {
            this.renderFunnel(deals);
        }

        // Render forecast widget
        this.showForecastWidget(userData.forecasts, deals);

        // Render statistics widget
        this.showActivitySummary(userData.user.id, userData.statistics);
    },
    showActivitySummary: function(user_id, statistics) {
        if (!statistics) {
            return;
        }

        var activitiesStats = statistics.activities,
            opportunitiesStats = statistics.opportunities,
            currency = app.user.get('client')['default_currency'];

        var manager = this.team.get('manager');

        this.activitySummaryModel = new Backbone.Model({
            manager_id: manager ? manager.id : null,
            recursive: this.selectedUserIdx != 1,
            user_id: user_id,
            num_phone_calls:
                activitiesStats.with_communication_medium.phone || 0,
            num_emails:
                activitiesStats.with_communication_medium.email || 0,
            num_total_activity: activitiesStats.total || 0,
            closed_value: Currency.shortFormat(
                currency, opportunitiesStats.won.total_value)
        });

        if (this.activitySummaryRegion) {
            this.activitySummaryRegion.show(new ActivitySummaryView({
                model:this.activitySummaryModel
            }));
            this.activitySummaryRegion.$el.removeClass('before-load hidden');
        }
    },
    showLeadsList: function(/*user*/) {
        var leadsListView = new LeadActivityListView({
            itemsDraggable: true,
            showTags: AppConfig.getValue('dashboard.leads.show_tags'),
            preferencesKey: 'sales_manager_dashboard_tab_id'
        });

        this.listenTo(leadsListView, 'push-view:individual:show', function(options) {
            this.setTarget('leads');
            this.trigger('push-view:individual:show', options);
        });

        this.listenTo(leadsListView, 'push-view:individual:new', function(options) {
            this.setTarget('leads');
            this.trigger('push-view:individual:new', options);
        });

        this.listenTo(leadsListView, 'selectActivityItem', function(type, id) {
            var correctedType;

            correctedType = {
                'individuals': 'individual',
                'leads': 'individual',
                'organizations': 'organization',
                'opportunities': 'deal'
            }[type];

            this.setTarget('activity');
            this.trigger('push-view:' + correctedType + ':show', {id: id});
        });

        this.listenTo(leadsListView, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            this.setTarget('activity');
            this.trigger('push-view:' + type + ':show', model);
        }, this);

        this.leadsListRegion.show(leadsListView);
    },
    renderFunnel: function() {
        var prevOpps;   // previous opportunities to enable refresh of funnel

        return function(opps) {
            if (_.isUndefined(opps)) {
                opps = prevOpps;
            }
            else {
                prevOpps = opps;
            }

            this.funnelView.setDeals( opps.models );
            this.funnelView.renderFunnel();
        };
    }(),
    showForecastWidget: function(forecasts, deals) {
        if ( this.forecastRegion ) {
            this.forecastRegion.show(new ForecastWidgetView({
                collection: forecasts,
                funnel: this.funnelView,
                deals: deals
            }));
            this.forecastRegion.$el.removeClass('before-load hidden');
        }
    },
    setTarget: function(targetId) {
        this.target = targetId;

        var targets = {
            'funnel': 'funnel-target-visible',
            'activity': 'activity-target-visible',
            'leads': 'leads-target-visible'
        };
        var target = targets[targetId];

        this.$el
            .removeClass(_.reject(targets, function(item) {
                return item === target;
            }).join(' '))
            .addClass(target);
    },
    onClose: function() {
        this.funnelView.close();
    },
    getUrl: function() {
        return 'dashboard/sales-manager';
    },
    getParams: function() {
        var params = {};

        if (this.target) {
            params.target = this.target;
        }
        return params;
    },
    dealPhaseChange: function(ev, data) {
        var view = this;

        dealPhaseChange(view, new OpportunityModel({id: data.dealId}), data);
    }
});

CeoDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'ceo-dashboard',
    className: 'dashboard at-top',
    template: Handlebars.compile(ceoDashboardTemplate),
    regions: {
        teamListRegion: '.team-list-widget',
        pipelineRegion: '.pipeline-widget',
        revenuePlannerRegion: '.revenue-planner-widget-container'
    },
    ui: {
        appMinHeight: '.app-min-height',
        dashboardWidgets: '.dashboard-widget',
        overviewWidget: '.overview-widget',
        overviewWidgetContainer: '.overview-widget-container',
        header: '.dashboard-header',
        footer: '.dashboard-footer',
        scroll: '.dashboard-content.content-container > .content',
        timeSpan: '.timespan .time',
        customPeriod: '.custom-time-container',
        setCustomBeginDate: '.set-begin-date',
        setCustomEndDate: '.set-end-date',
    },
    events: {
        'click .revenue-planner-widget-container .expand': 'toggleRevenueExpand',
        'click .pipeline-widget .expand': 'togglePipelineExpand',
        'resize': 'scrollbar',
        'show-deal': 'showDeal',
        'deal-phase-change': 'dealPhaseChange',
        'change-funnel': 'changeFunnel',
        'click .header .day': function() {
            this.handleTimeSpanSelect('day', 'Daily Change', false);
        },
        'click .header .week': function() {
            this.handleTimeSpanSelect('week', 'Weekly Change', false);
        },

        'click .header .month': function() {
            this.handleTimeSpanSelect('month', 'Monthly Change', false);
        },

        'click .header .quarter': function() {
            this.handleTimeSpanSelect('quarter', 'Quarterly Change', false);
        },

        'click .header .year': function() {
            this.handleTimeSpanSelect('year', 'Yearly Change', false);
        },

        'click .header .custom': function() {
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },
        'change .set-begin-date': function() {
            this.customBeginDate = dateFormat.formatYYYYMMDDDate(this.ui.setCustomBeginDate.val());
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },
        'change .set-end-date': function() {
            this.customEndDate = dateFormat.formatYYYYMMDDDate(this.ui.setCustomEndDate.val());
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },
        'click .header .time': function(ev) {
            var active = $(ev.currentTarget);
            ev.preventDefault();
            active.addClass('active');
            this.ui.timeSpan.not(active).removeClass('active');
        },
        'focus .set-begin-date': function(e) {
            this.ui.setCustomBeginDate.datepicker( {
                numberOfMonths: 2,
                dateFormat: 'M dd, yy',
                showAnim: 'fadeIn',
                showButtonPanel: true,
                changeYear: true,
                timezone: "+0000"
            } );
        },
        'focus .set-end-date': function() {
            this.ui.setCustomEndDate.datepicker( {
                numberOfMonths: 2,
                dateFormat: 'M dd, yy',
                showAnim: 'fadeIn',
                showButtonPanel: true,
                changeYear: true,
                timezone: "+0000"
            } );
        },
    },
    initialize: function(options) {
        this.options = _.extend({}, options);
        this.timeSpan = 'week';
        this.activeFunnel = null;

    },
    onRender: function() {
        var self = this;
        this.ui.customPeriod.hide();

        this.fetchData();

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);

        // here we check when CSS transform has completed on container
        this.$el.find(".team-list-widget").on($.support.transition.end, function(e) {
            var propertyName = e.propertyName || e.originalEvent.propertyName;
            if (e.target === this && propertyName === 'transform') {
                self.funnelView.renderFunnel();
            }
        });
    },
    handleTimeSpanSelect: function(timeSpan, timeSpanLabel, showCustom) {
        this.timeSpan = timeSpan;
        this.period = timeSpanLabel;
        if(showCustom) {
            this.ui.customPeriod.show();
        } else {
            this.ui.customPeriod.hide();
        }

        if(!showCustom || (this.customBeginDate && this.customEndDate)){
            this.fetchData();
        }
    },
    showDeal: function(ev, id) {
        this.trigger('push-view:deal:show', {id: id});
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    fetchData: function() {
        var view = this;
        this.funnels = new FunnelsCollection();

        var url = `/pipeline?time_span=${view.timeSpan}`

        if(view.timeSpan === 'custom'){
            url += `&begin_date=${view.customBeginDate}&end_date=${view.customEndDate}`
        }

        this.funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                $.ajax({
                    url: url,
                    success: function(data) {
                        if ( view.isClosed ) {
                            return;
                        }

                        view.data = data;

                        view.showPipeline();
                        view.showTeamSelector();
                        view.ui.overviewWidgetContainer.removeClass('before-load hidden');

                        view.revenuePlannerRegion.show(new RevenuePlannerView());
                        view.revenuePlannerRegion.$el.removeClass('before-load hidden');

                        view.scrollbar();
                        view.ui.scroll.scroll(view.scrollEvents.bind(view));
                    }
                });
            }
        });
    },
    showTeamSelector: function() {
        var self = this;

        this.teamSelector = new TeamSelector({ loadOpps: true, showArrow: true });
        this.teamListRegion.show(this.teamSelector);
        // use listenToOnce after library update
        this.listenTo(this.teamSelector, 'team:opportunities:loaded', function() {
            // self.teamListRegion.$el.removeClass('before-load hidden');
        });
        this.listenTo(this.teamSelector, 'team:opportunities:loaded', function(opportunities, teamId) {
            if (teamId) {
                self.showFunnel(opportunities);
            }
            else {
                self.showPipeline();
            }
        });
        this.listenTo(this.teamSelector, 'individual:opportunities:loaded', function(opportunities) {
            self.showFunnel(opportunities);
        });
    },
    showPipeline: function() {
        var view = this,
            collection,
            currency;

        currency = app.user.get('client')['default_currency'];

        var models = _.map(view.data.phase_stats, function(d) {
            return new Backbone.Model({
                id: null,
                phase: d.phase.name,
                phase_type: d.phase.phase_type,
                funnel_id: d.phase.funnel_id,
                value: Currency.shortFormat(currency, d.value),
                count: d.total,
                change: d.delta
            });
        });

        for (var funnelId in view.data.won_stats.funnels) {
            var stats = view.data.won_stats.funnels[funnelId];

            models.push(new Backbone.Model({
                phase: view.data.won_stats.phase.name,
                phase_type: 'won',
                funnel_id: funnelId,
                value: Currency.shortFormat(currency, stats.value),
                count: stats.total,
                change: stats.delta
            }));
        }

        for (var funnelId in view.data.lost_stats.funnels) {
            var stats = view.data.lost_stats.funnels[funnelId];

            models.push(new Backbone.Model({
                phase: view.data.lost_stats.phase.name,
                phase_type: 'lost',
                funnel_id: funnelId,
                value: Currency.shortFormat(currency, stats.value),
                count: stats.total,
                change: stats.delta
            }));
        }

        models.unshift(new Backbone.Model({
            id: null,
            phase: 'Leads',
            value: Currency.shortFormat(currency, view.data.lead_stats.value),
            count: view.data.lead_stats.total,
            change: view.data.lead_stats.delta
        }));

        collection = new Backbone.Collection(models);

        if (this.pipelineRegion) {
            this.pipelineRegion.show(new PipelineView({
                funnels: this.funnels,
                collection: collection,
                period: this.period
            }));
            this.overviewResize();
        }
    },
    /*
     * @param   list|OpportunityCollection    opportunities
     */
    showFunnel: function(opportunities) {
        var self = this,
            phases = new PhasesCollection(),
            periods = new PeriodsCollection(),
            funnels = new FunnelsCollection();

        this.funnelView = new FunnelViewEx({ hideWeeks: true });
        this.pipelineRegion.show(this.funnelView);
        this.funnelView.showLoader();

        $.when(
            phases.fetch({
                success: function() {
                    self.funnelView.setPhases( phases.models );
                }
            }),
            periods.fetch({
                success: function() {
                    self.funnelView.setPeriods( periods.models );
                }
            }),
            funnels.fetch({
                data: {
                    permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
                },
                sortOn: [{
                    attribute: 'order',
                    order: 'asc'
                }],
                success: function() {
                    self.funnelView.setFunnels(funnels.models, self.activeFunnel || funnels.getLastVisitedFunnelId());
                }
            })
        )
        .done(
            function() {
                self.funnelView.hideLoader();
                if (opportunities) {
                    if (!(opportunities instanceof OpportunitiesCollection)) {
                        opportunities = new OpportunitiesCollection(opportunities);
                    }
                    self.opportunities = opportunities;
                    self.funnelView.setDeals(opportunities.models);
                }
                else {
                    self.funnelView.setDeals(self.opportunities.models);
                }
                self.funnelView.renderFunnel();
                self.overviewResize();
            }
        );
    },
    changeFunnel: function(ev, id) {
        this.activeFunnel = id;
        this.funnelView.setFunnels(this.funnels.models, this.activeFunnel);
        this.funnelView.renderFunnel();
        app.user.updatePreference('last_visited_funnel_id', id);
    },
    widgetSizes: function() {
        var dashHeight = this.$el.height(),
            revenueEl = this.$el.find('.revenue-planner-widget-container'),
            revenueHeight = revenueEl.height(),
            pipeViewEl = this.$el.find('.pipeline-widget'),
            pipelineHeight = pipeViewEl.height();

        return {
            pipelineHeight: pipelineHeight,
            overviewHeight: pipelineHeight, // For proper height animation
            revenueHeight: revenueHeight
        };
    },
    toggleWidget: function(widgetEl) {
        if (widgetEl.hasClass('expanded')) {
            this.minimizeWidget(widgetEl);
        } else {
            this.expandWidget(widgetEl);
        }
    },
    expandWidget: function(widgetEl) {
        widgetEl.addClass('expanded');
        this.ui.dashboardWidgets.not(widgetEl).addClass('inactive');
    },
    minimizeWidget: function(widgetEl) {
        widgetEl.removeClass('expanded');
        this.ui.dashboardWidgets.removeClass('inactive');
    },
    toggleRevenueExpand: function() {
        var view = this,
            revenue = this.revenuePlannerRegion.$el,
            expanded,
            dummy,
            scrollPosition;

        expanded = revenue.hasClass('expanded');

        if (!expanded) {
            dummy = $(revenue[0].cloneNode(false)).removeClass('expanded').addClass('dummy');
            scrollPosition = revenue.find('.scroll-x').scrollLeft();

            this.$el.addClass('inactive');

            revenue.css({
                position: 'absolute',
                top: revenue.position().top + view.ui.header.height(),
                left: revenue.position().left,
                height: revenue.height(),
                width: revenue.width()
            });

            dummy.insertBefore(revenue);

            revenue.insertAfter(this.$el.find('.dashboard-content'));

            this.toggleWidget(revenue);

            revenue.animate({
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%'
                }, 500, 'easeOutQuart')
                .removeClass('condensed');

            revenue.find('.scroll-x').scrollLeft(scrollPosition);
        }
        else {
            dummy = this.$el.find('.revenue-planner-widget-container.dummy');
            scrollPosition = revenue.find('.scroll-x').scrollLeft();

            this.$el.removeClass('inactive');

            this.revenuePlannerRegion.currentView.closeBucketConfiguration();

            revenue.addClass('condensed')
                .animate({
                    top: dummy.position().top + view.ui.header.height(),
                    left: dummy.position().left,
                    height: dummy.height(),
                    width: dummy.width()
                }, 500, 'easeOutQuart', function() {

                    revenue.insertAfter(dummy);

                    dummy.remove();

                    $(this).css({
                        position: '',
                        top: '',
                        left: '',
                        height: '',
                        width: ''
                    });

                    revenue.find('.scroll-x').scrollLeft(scrollPosition);
                });

            this.toggleWidget(revenue);
        }
    },
    togglePipelineExpand: function(ev) {
        var overview = this.$el.find('.overview-widget-container');

        if (ev) {
            this.toggleWidget(overview);
        }
    },
    overviewResize: function() {
        var view = this;

        this.$el.find('.overview-widget').animate({
            height: view.widgetSizes().overviewHeight
        }, 400, 'easeOutQuint', function() {
            view.pipelineRegion.currentView.$el.trigger('resize');
        });
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.round( (this.$el.height() - this.ui.header.height()) ),
            contentHeight = Math.ceil( container.find('.content-inner:first').outerHeight(true) ),
            height = Math.min(availableHeight, contentHeight);

        // container.find('.content-inner:first').css('minHeight', contentHeight);

        container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    },
    getUrl: function() {
        return 'dashboard/ceo';
    },
    dealPhaseChange: function(ev, data) {
        var view = this;

        dealPhaseChange(view, this.opportunities.get(data.dealId), data, function() {
            view.funnelView.renderFunnel();
        });
    }
});

SalesPersonDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'sales-person-dashboard',
    template: Handlebars.compile(salesPersonDashboardTemplate),
    regions: {
        funnelRegion: '.funnel-widget-container',
        activityRegion: '.activity-feed-widget',
        leadsListRegion: '.leads-list-widget',
        forecastRegion: '.forecast-widget'
    },
    events: {
        'show-deal': function(ev, id) {
            this.showDeal(id);
        },
        'change-funnel': 'changeFunnel',
        'create-deal-from-lead': function(ev, info) {
            onCreateDealFromLead(this, info);
        },
        'deal-phase-change': 'dealPhaseChange'
    },
    initialize: function(options) {
        var view = this;
        this.options = _.extend({}, options);
        this.funnelView = new FunnelViewEx( { hideWeeks: true } );
        this.funnelTarget = (this.options.funnel === 'true');
        this.activeFunnel = null;
        this.listenTo(vent, 'deal:save', function() {
            view.fetchOpps(view.renderFunnel.bind(view));
        });

        funnelHelper.initialize(this);

        this.listenTo(vent, 'organization:delete individual:delete deal:delete', function() {
            view.showActivities();
        });
    },
    onRender: function() {
        this.setFunnelTarget(this.funnelTarget);
    },
    onShow: function() {
        this.funnelRegion.show( this.funnelView );

        _.defer(function() {
            var container = $('#sales-person-dashboard .funnel-widget-container');
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                _.debounce(function(ev) {
                    if (ev.target.className === container.attr('class')) {
                        this.renderFunnel(this.phases, this.opps);
                    }
                }.bind(this), 100)
            );
        }.bind(this));

        this.forecasts = new ForecastsCollection();

        this.showRegistrations();
        this.showActivities();
        this.showFunnel();

        this.funnelRegion.$el.removeClass('before-load hidden');

        funnelHelper.onShow(this);

        // HACKY - adding class to region el
        this.$el.parent().parent().addClass('individual-visible');

        this.$el.find('.widgets-container.content-container').nanoScroller();
    },
    showDeal: function(id) {
        this.setFunnelTarget(true);
        funnelHelper.showDeal(this, id);
    },
    changeFunnel: function(ev, id) {
        this.activeFunnel = id;
        this.showFunnel();
        app.user.updatePreference('last_visited_funnel_id', id);
    },
    onBeforeClose: function() {
        // HACKY - removing class from region el
        this.$el.parent().parent().removeClass('individual-visible');
    },
    showRegistrations: function () {
        var self = this;

        getLeadDefinitionFilterId(function(filterId) {
            var options = _.extend({
                itemsDraggable: true,
                showTags: AppConfig.getValue('dashboard.leads.show_tags')
            }, self.options);


            if (filterId) {
                options.collectionData = {
                    by_filter_id: filterId,
                    check_is_lead: false
                };
            }

            var leadsListView = new LeadListView(options);

            self.listenTo(leadsListView, 'push-view:individual:show', function(options) {
                self.trigger('push-view:individual:show', options);
            });

            self.listenTo(leadsListView, 'push-view:individual:new', function(options) {
                self.trigger('push-view:individual:new', options);
            });

            self.leadsListRegion.show(leadsListView);
            self.leadsListRegion.$el.removeClass('before-load hidden');

            if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
                self.leadsListRegion.currentView.$el.parent().addClass('higher');
            }
        });
    },
    showActivities: function() {
        var view = this;

        this.activityRegion.show(new ActivityListView(
            _.extend({
                parent: this,
                preferencesKey: 'sales_person_dashboard_tab_id',
                rows: 50
            }, this.options)
        ));
        this.activityRegion.$el.removeClass('before-load hidden');

        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            this.activityRegion.$el.addClass('higher');
        }

        this.listenTo(this.activityRegion.currentView, 'selectActivityItem', function(type, id) {
            var correctedType;

            correctedType = {
                'individuals': 'individual',
                'leads': 'individual',
                'organizations': 'organization',
                'opportunities': 'deal'
            }[type];

            view.setFunnelTarget(false);
            this.trigger('push-view:' + correctedType + ':show', {id: id});
        });

        this.listenTo(this.activityRegion.currentView, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            this.trigger('push-view:' + type + ':show', model);
        }, view);
    },
    fetchOpps: function(callback) {
        var view = this;

        var doFetch = function(orderBy) {
            var opps = new OpportunitiesCollection();
            var data = {
                funnel_id: view.activeFunnel,
                by_period_id: 'current',
                owner_id: app.user.id,
                snapshot: AppConfig.getValue('funnelPopup') === 'extended',
                order_by: orderBy || 'name asc'
            };

            opps.fetch({
                rows: -1,
                data: data,
                success: function () {
                    if (view.isClosed) {
                        return;
                    }

                    view.opps = opps;
                    view.funnelView.hideLoader();
                    view.funnelView.setDeals(opps.models);
                    if (callback) {
                        callback();
                    }

                    view.fetchForecastData();
                }
            });
        }

        var customOrderBy = AppConfig.getValue('deals.custom_order_by');

        if (customOrderBy && app.globalData.dealsCustomOrderBy !== null) {
            if (!_.isUndefined(app.globalData.dealsCustomOrderBy)) {
                doFetch(app.globalData.dealsCustomOrderBy);
            } else {
                if (customOrderBy.is_custom_field) {
                    var customFields = new CustomFieldsCollection();

                    customFields.fetch({
                        rows: -1,
                        filterBy: [{
                            attribute: 'view',
                            value: 'opportunities'
                        }],
                        success: function(res) {
                            var customCF = res.models.find(m => m.get('name').toLowerCase() === customOrderBy.field.toLowerCase());

                            if (customCF) {
                                app.globalData.dealsCustomOrderBy = `custom_fields.${customCF.get('id')} ${customOrderBy.dir}`;
                                doFetch(app.globalData.dealsCustomOrderBy);
                            } else {
                                app.globalData.dealsCustomOrderBy = null;
                                doFetch();
                            }
                        }
                    });
                } else {
                    doFetch();
                }
            }
        } else {
            doFetch();
        }
    },
    showFunnel: function() {
        var view = this,
            phases = new PhasesCollection(),
            periods = new PeriodsCollection(),
            funnels = new FunnelsCollection();

        this.funnelView.showLoader();

        this.phases = null;
        this.periods = null;
        this.funnels = null;
        this.opps = null;

        function checkReady() {
            if (view.phases && view.opps && view.periods && view.funnels) {
                view.renderFunnel();
                view.showQuotaWidget();
            }
        }

        funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function() {
                if (view.funnelView.isClosed) {
                    return;
                }

                view.activeFunnel = view.activeFunnel || funnels.getLastVisitedFunnelId();

                view.funnels = funnels;
                view.funnelView.setFunnels(funnels.models, view.activeFunnel);

                phases.fetch({
                    data: {
                        funnel_id: view.activeFunnel
                    },
                    success: function() {
                        view.phases = phases;
                        view.funnelView.setPhases(phases.models);
                        checkReady();
                    }
                });

                view.fetchOpps(checkReady);
            }
        });

        // Fetch periods
        periods.fetch({
            success: function() {
                view.periods = periods;
                view.funnelView.setPeriods( view.periods.models );
                checkReady();
            }
        });
    },
    renderFunnel: function() {
        this.funnelView.renderFunnel();
    },
    showQuotaWidget: function() {
        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            return;
        }

        var user = app.user,
            sumClosed = 0,
            sumCommitted = 0,
            quota = user.getTotalQuotaValue(),
            quotaCurrency = user.get('client')['default_currency'];

        this.$el.find('.quota-widget-container').removeClass('before-load hidden');

        if ( !this.opps || (!quota && quota !== 0) || !quotaCurrency ) {
            d3.select('.quota-widget .quota-target-value')
                .text('0');

            d3.select('.quota-widget .quota-closed-value')
                .text('0');

            d3.select('.quota-widget .bar')
                .style('width', '0%');

            return;
        }

        var oppValues = this.opps.getQuotaValues(this.phases);
        var fcValues = this.selectedForecast ? this.selectedForecast.getQuotaValues() : ForecastModel.prototype.getQuotaValues(this.opps);

        sumClosed = oppValues.closed;
        sumCommitted = fcValues.committed + fcValues.committedDownside;

        d3.select('.quota-widget .quota-target-value')
            .text( Currency.shortFormat(quotaCurrency, quota) );

        d3.select('.quota-widget .quota-closed-value')
            .text( Currency.shortFormat(quotaCurrency, sumClosed) );

        d3.select('.quota-widget .quota-committed-value')
            .text( Currency.shortFormat(quotaCurrency, sumCommitted) );

        d3.select('.quota-widget .bar-closed')
            .style('width', function() {
                var pct = Math.floor(sumClosed * 100 / quota);
                return pct + '%';
            });

        d3.select('.quota-widget .bar-committed')
            .style('width', function() {
                var pct = Math.floor(sumCommitted * 100 / quota);
                return pct + '%';
            });
    },
    showForecastWidget: function(forecasts, deals) {
        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            return;
        }

        if ( this.forecastRegion ) {
            var forecastWidgetView = new ForecastWidgetView({
                collection: forecasts,
                funnel: this.funnelView,
                deals: deals,
                initialForecastId: this.selectedForecast ? this.selectedForecast.get('id') : null
            });
            this.listenTo(forecastWidgetView, 'updateForecastValues', function(forecast) {
                this.selectedForecast = forecast;
                this.showQuotaWidget();
            });
            this.forecastRegion.show(forecastWidgetView);
            this.forecastRegion.$el.removeClass('before-load hidden');
        }
    },
    fetchForecastData: function() {
        var view = this;

        this.forecasts.fetch({
            url: '/users/' + app.user.id + '/forecasts',
            data: {
                rows: -1,
                order_by: 'published_date desc'
            },
            success: function() {
                if ( view.forecasts.length > 0 ) {
                    view.showForecastWidget(view.forecasts, view.opps);
                } else {
                    view.showForecastWidget(null, view.opps);
                }
            }
        });
    },
    onClose: function() {
        this.funnelView.close();
    },
    setFunnelTarget: function(funnelTarget) {
        var add, remove;

        this.funnelTarget = funnelTarget;
        if (funnelTarget) {
            add = 'funnel-target-visible';
            remove = 'activity-target-visible';
        } else {
            add = 'activity-target-visible';
            remove = 'funnel-target-visible';
        }
        this.$el.removeClass(remove);
        this.$el.addClass(add);
    },
    getUrl: function() {
        return 'dashboard/sales-person';
    },
    getParams: function() {
        var params = {};
        params.funnel = !!this.funnelTarget;

        return params;
    },
    dealPhaseChange: function(ev, data) {
        var view = this;

        dealPhaseChange(view, new OpportunityModel({id: data.dealId}), data, function() {
            view.showFunnel();
        });
    }
});

IntentDashboard = Marionette.Layout.extend({
    id: 'intent-dashboard',
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <IntentDashboardView/>,
            this.$el.get(0)
        );
    },
    getUrl: function() {
        return 'dashboard/intent';
    }
});

FlexDashboard = Marionette.Layout.extend({
    id: 'flex-dashboard',
    template: Handlebars.compile(''),
    onRender: function() {
        this.listenTo(vent, 'appToolbar:dashboard:setActiveFunnel', function(funnel) {
            this.component.setActiveFunnel(funnel);
        });

        this.listenTo(vent, 'appToolbar:dashboard:setActiveCluster', function(cluster, saveVisitedFunnelId) {
            this.component.setActiveCluster(cluster, saveVisitedFunnelId);
        });

        ReactDOM.render(
            <FlexDashboardView
                ref={(el) => this.component = el}
                onPushViewIndividual={(id, options) => this.handlePushViewEntity('individual', id, options)}
                onPushViewOpportunity={(id, options) => this.handlePushViewEntity('deal', id, options)}
                onPushViewOrganization={(id, options) => this.handlePushViewEntity('organization', id, options)}
                parent={this}
                dashboardId={this.options.dashboardId}
            />,
            this.$el.get(0)
        );
    },
    handlePushViewEntity: function(entityType, entityId, options) {
        this.options.parent.trigger(`push-view:${entityType}:show`, {
            id: entityId,
            options: options || {}
        });
    },
    getUrl: function() {
        return 'dashboard/flex';
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

TeamDashboard = Marionette.Layout.extend({
    tagName: 'article',
    id: 'sales-person-dashboard',
    template: Handlebars.compile(salesPersonDashboardTemplate),
    regions: {
        funnelRegion: '.funnel-widget-container',
        activityRegion: '.activity-feed-widget',
        leadsListRegion: '.leads-list-widget',
        forecastRegion: '.forecast-widget'
    },
    events: {
        'show-deal': function(ev, id) {
            this.showDeal(id);
        },
        'change-funnel': 'changeFunnel',
        'deal-phase-change': 'dealPhaseChange',
        'create-deal-from-lead': function(ev, info) {
            onCreateDealFromLead(this, info);
        }
    },
    initialize: function(options) {
        var view = this;
        this.options = _.extend({}, options);
        this.funnelView = new FunnelViewEx( { hideWeeks: true } );
        this.funnelTarget = (this.options.funnel === 'true');
        this.activeFunnel = null;

        funnelHelper.initialize(this);

        this.listenTo(vent, 'deal:save', function() {
            view.fetchOpps(view.renderFunnel.bind(view));
        });

        this.listenTo(vent, 'organization:delete individual:delete deal:delete', function() {
            view.showActivities();
        });
    },
    onRender: function() {
        this.setFunnelTarget(this.funnelTarget);
    },
    onShow: function() {
        this.funnelRegion.show( this.funnelView );

        _.defer(function() {
            var container = $('#sales-person-dashboard .funnel-widget-container');
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                _.debounce(function(ev) {
                    if (ev.target.className === container.attr('class')) {
                        this.renderFunnel(this.phases, this.opps);
                    }
                }.bind(this), 100)
            );
        }.bind(this));

        this.showRegistrations();
        this.showActivities();
        this.showFunnel();
        this.showQuotaWidget();

        funnelHelper.onShow(this);

        // HACKY - adding class to region el
        this.$el.parent().parent().addClass('individual-visible');
    },
    showDeal: function(id) {
        this.setFunnelTarget(true);
        funnelHelper.showDeal(this, id);
    },
    onBeforeClose: function() {
        // HACKY - removing class from region el
        this.$el.parent().parent().removeClass('individual-visible');
    },
    showRegistrations: function () {
        var self = this;

        getLeadDefinitionFilterId(function(filterId) {
            var options = {
                itemsDraggable: true,
                showTags: AppConfig.getValue('dashboard.leads.show_tags'),
                collectionData: {
                    'my_team': false
                }
            };

            if (filterId) {
                options.collectionData.by_filter_id = filterId;
                options.collectionData.check_is_lead = false;
            }

            var leadsExtraFilterId = (app.user.get('preferences') || {}).leads_dashboard_extra_filter_id;

            if(leadsExtraFilterId){
                options.collectionData.by_filter_id = leadsExtraFilterId;
            }

            var leadsListView = new LeadListView(options);

            self.listenTo(leadsListView, 'push-view:individual:show', function(options) {
                self.trigger('push-view:individual:show', options);
            });

            self.listenTo(leadsListView, 'push-view:individual:new', function(options) {
                self.trigger('push-view:individual:new', options);
            });

            self.leadsListRegion.show(leadsListView);

            if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
                self.leadsListRegion.currentView.$el.parent().addClass('higher');
            }
        });
    },
    showActivities: function() {
        var view = this;

        this.activityRegion.show(new ActivityListView(_.extend(
            {
                parent: this,
                preferencesKey: 'team_dashboard_tab_id',
                rows: 50
            }, this.options)
        ));

        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            this.activityRegion.$el.addClass('higher');
        }

        this.listenTo(this.activityRegion.currentView, 'selectActivityItem', function(type, id) {
            var correctedType;

            correctedType = {
                'individuals': 'individual',
                'leads': 'individual',
                'organizations': 'organization',
                'opportunities': 'deal'
            }[type];

            view.setFunnelTarget(false);
            this.trigger('push-view:' + correctedType + ':show', {id: id});
        });

        this.listenTo(this.activityRegion.currentView, 'show:related', function(model, type) {
            // support for deal/opportunity naming duality
            if (type === 'opportunity') {
                type = 'deal';
            }
            this.trigger('push-view:' + type + ':show', model);
        }, view);
    },
    fetchOpps: function(callback) {
        var view = this;

        var doFetch = function(orderBy) {
            var onSuccess = (deals) => {
                if (view.isClosed) {
                    return;
                }

                view.opps = deals;
                view.funnelView.setDeals(deals.models);
                view.fetchForecastData();

                if (callback) {
                    callback();
                }
            }

            const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === view.activeFunnel);
            const useNewEndpoint = AppConfig.getValue('dashboard.use_optimised_funnel_query', false) && AppConfig.getValue('deals.aftercare.funnel_name') !== funnel.name;

            if (useNewEndpoint) {
                let args = {};

                if (orderBy) {
                    args.order_by = orderBy;
                }

                let fields = [];
                const unitNo = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === 'unit no.');

                if (unitNo) {
                    fields.push(unitNo.id);
                }

                if (fields.length > 0) {
                    args.fields = fields.join(',');
                }
                $.get(`/pdcrm_funnel_opportunities/${view.activeFunnel}?${$.param(args)}`, function(deals) {
                    for (let deal of deals) {
                        deal.value = 0;

                        for (const bucket of deal.buckets || []) {
                            deal.value += bucket.value || 0;
                        }

                        deal.expected_close_date = dateFormat.parseDate(deal.expected_close_date);
                        deal.funnel_id = view.activeFunnel;
                        deal.phase = _.clone(app.globalData.phasesInfo.phases.find(p => p.id === deal.phase_id));
                        deal.bubble_representation_value = deal.value;

                        for (const field of fields) {
                            deal[`custom_field.${field}`] = deal[field] || '';
                        }
                    }

                    onSuccess(new OpportunitiesCollection(deals));
                });
            } else {
                var opps = new OpportunitiesCollection();
                var data = {
                    funnel_id: view.activeFunnel,
                    by_period_id: 'current',
                    owner_id: view.funnelView.allOrMyDeals.id === 'my_deals' ? app.user.get('id') : undefined,
                    snapshot: AppConfig.getValue('funnelPopup') === 'extended',
                    order_by: orderBy || 'name asc'
                };

                opps.fetch({
                    rows: -1,
                    data: data,
                    success: function() {
                        onSuccess(opps);
                    }
                });
            }
        }

        var customOrderBy = AppConfig.getValue('deals.custom_order_by');

        if (customOrderBy && app.globalData.dealsCustomOrderBy !== null) {
            if (!_.isUndefined(app.globalData.dealsCustomOrderBy)) {
                doFetch(app.globalData.dealsCustomOrderBy);
            } else {
                if (customOrderBy.is_custom_field) {
                    var customFields = new CustomFieldsCollection();

                    customFields.fetch({
                        rows: -1,
                        filterBy: [{
                            attribute: 'view',
                            value: 'opportunities'
                        }],
                        success: function(res) {
                            var customCF = res.models.find(m => m.get('name').toLowerCase() === customOrderBy.field.toLowerCase());

                            if (customCF) {
                                app.globalData.dealsCustomOrderBy = `custom_fields.${customCF.get('id')} ${customOrderBy.dir}`;
                                doFetch(app.globalData.dealsCustomOrderBy);
                            } else {
                                app.globalData.dealsCustomOrderBy = null;
                                doFetch();
                            }
                        }
                    });
                } else {
                    doFetch();
                }
            }
        } else {
            doFetch();
        }
    },
    showFunnel: function() {
        var view = this,
            periods = new PeriodsCollection();

        this.phases = null;
        this.periods = null;
        this.funnels = null;
        this.opps = null;

        function checkReady() {
            if (view.phases && view.opps && view.periods && view.funnels) {
                view.renderFunnel();
                view.showQuotaWidget();
            }
        }

        var funnels = new FunnelsCollection(app.globalData.funnelsInfo.funnels);

        view.activeFunnel = view.activeFunnel || funnels.getLastVisitedFunnelId();

        view.funnels = funnels;
        view.funnelView.setFunnels(funnels.models, view.activeFunnel);

        view.phases = new PhasesCollection(app.globalData.phasesInfo.phases.filter(p => p.funnel_id === view.activeFunnel));
        view.funnelView.setPhases(view.phases.models);

        view.fetchOpps(checkReady);

        // Fetch periods
        periods.fetch({
            success: function() {
                view.periods = periods;
                view.funnelView.setPeriods( view.periods.models );
                checkReady();
            }
        });
    },
    renderFunnel: function() {
        this.funnelView.renderFunnel();
    },
    showQuotaWidget: function() {
        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            return;
        }

        var user = app.user,
            sumClosed = 0,
            sumCommitted = 0,
            quota = user.getTotalQuotaValue(),
            quotaCurrency = user.get('client')['default_currency'];

        this.$el.find('.quota-widget-container').removeClass('before-load hidden');

        if ( !this.opps || (!quota && quota !== 0) || !quotaCurrency ) {
            d3.select('.quota-widget .quota-target-value')
                .text('0');

            d3.select('.quota-widget .quota-closed-value')
                .text('0');

            d3.select('.quota-widget .bar')
                .style('width', '0%');

            return;
        }

        var oppValues = this.opps.getQuotaValues(this.phases);
        var fcValues = this.selectedForecast ? this.selectedForecast.getQuotaValues() : ForecastModel.prototype.getQuotaValues(this.opps);

        sumClosed = oppValues.closed;
        sumCommitted = fcValues.committed + fcValues.committedDownside;

        d3.select('.quota-widget .quota-target-value')
            .text( Currency.shortFormat(quotaCurrency, quota) );

        d3.select('.quota-widget .quota-closed-value')
            .text( Currency.shortFormat(quotaCurrency, sumClosed) );

        d3.select('.quota-widget .quota-committed-value')
            .text( Currency.shortFormat(quotaCurrency, sumCommitted) );

        d3.select('.quota-widget .bar-closed')
            .style('width', function() {
                var pct = Math.floor(sumClosed * 100 / quota);
                return pct + '%';
            });

        d3.select('.quota-widget .bar-committed')
            .style('width', function() {
                var pct = Math.floor(sumCommitted * 100 / quota);
                return pct + '%';
            });
    },
    showForecastWidget: function(forecasts, deals) {
        if (AppConfig.getValue('disableForecastAndQuotaWidgetsForTeamAndSalesExec', false)) {
            return;
        }

        if ( this.forecastRegion ) {
            var forecastWidgetView = new ForecastWidgetView({
                collection: forecasts,
                funnel: this.funnelView,
                deals: deals,
                initialForecastId: this.selectedForecast ? this.selectedForecast.get('id') : null
            });
            this.listenTo(forecastWidgetView, 'updateForecastValues', function(forecast) {
                this.selectedForecast = forecast;
                this.showQuotaWidget();
            });
            this.forecastRegion.show(forecastWidgetView);
            this.forecastRegion.$el.removeClass('before-load hidden');
        }
    },
    fetchForecastData: function() {
        var view = this,
            collection = new ForecastsCollection();

        collection.fetch({
            url: '/forecasts',
            data: {
                my_team: false,
                rows: -1,
                order_by: 'published_date desc'
            },
            success: function() {
                if ( collection.length > 0 ) {
                    view.showForecastWidget(collection, view.opps);
                } else {
                    view.showForecastWidget(null, view.opps);
                }
            }
        });
    },
    onClose: function() {
        this.funnelView.close();
    },
    setFunnelTarget: function(funnelTarget) {
        var add, remove;

        this.funnelTarget = funnelTarget;
        if (funnelTarget) {
            add = 'funnel-target-visible';
            remove = 'activity-target-visible';
        } else {
            add = 'activity-target-visible';
            remove = 'funnel-target-visible';
        }
        this.$el.removeClass(remove);
        this.$el.addClass(add);
    },
    getUrl: function() {
        return 'dashboard/team';
    },
    getParams: function() {
        var params = {};
        params.funnel = !!this.funnelTarget;

        return params;
    },
    changeFunnel: function(ev, id) {
        this.activeFunnel = id;
        this.showFunnel();
        app.user.updatePreference('last_visited_funnel_id', id);
    },
    dealPhaseChange: function(ev, data) {
        var view = this;

        dealPhaseChange(view, new OpportunityModel({id: data.dealId}), data, function() {
            view.showFunnel();
        });
    }
});

ForecastWidgetView = Marionette.ItemView.extend({
    template: Handlebars.compile(forecastWidgetTemplate),
    ui: {
        upsideText: '.none_upside-value',
        committedText: '.committed_downside-value',
        downsideText: '.downside-value',
        upsideBar: '.none_upside-bar',
        committedBar: '.committed_downside-bar',
        downsideBar: '.downside-bar',
        forecastSelect: '.forecast-select'
    },
    events: {
        'change .forecast-select': 'updateForecastValues'
    },
    initialize: function(options) {
        this.funnel = options.funnel;
        this.initialForecastId = options.initialForecastId;
    },
    onRender: function() {
        // Initialize forecast selector
        this.initSelect2();

        // Update forecast values (legend & bar chart)
        this.updateForecastValues();
    },
    updateForecastValues: function() {
        var forecastId,
            forecast,
            values,
            valueUpside = 0,
            valueCommitted,
            valueDownside,
            user = app.user,
            quotaCurrency = user.get('client')['default_currency'];

        // First get selected forecast
        forecastId = this.ui.forecastSelect.select2('val');
        forecast = this.collection && this.collection.get(forecastId);

        // Calculate total values in each category
        if ( forecast && forecastId !== 'default') {
            values = forecast.getQuotaValues();
        }
        else if (this.options.deals) {
            values = ForecastModel.prototype.getQuotaValues(this.options.deals);
        }
        else {
            return;
        }

        valueCommitted = values.committed + values.committedDownside;
        valueDownside = values.committed;
        valueUpside = valueCommitted + values.noneUpside;


        // Write legend values
        this.ui.upsideText.text(Currency.shortFormat(quotaCurrency, valueUpside));
        this.ui.committedText.text(Currency.shortFormat(quotaCurrency, valueCommitted));
        this.ui.downsideText.text(Currency.shortFormat(quotaCurrency, valueDownside));

        // Render barchart
        function width(value) {
            if(value === 0) {
                return '0%';
            }
            return Math.floor(value * 100 / valueUpside) + '%';
        }

        this.ui.upsideBar.css('width', width(valueUpside));
        this.ui.committedBar.css('width', width(valueCommitted));
        this.ui.downsideBar.css('width', width(valueDownside));

        var status = {};
        if (forecast) {
            _.each(forecast.get('forecast_opportunities'), function(item) {
                status[item['opportunity_id']] = item['status'];
            });
        }

        this.funnel.setDealsStatus(status);
        this.funnel.renderFunnel();

        app.user.updatePreference('last_forecast_id', forecastId);

        this.trigger('updateForecastValues', forecast);
    },
    initSelect2: function() {
        var forecasts = this.collection || new ForecastsCollection();
        var lastForecastId = app.user.get('preferences')['last_forecast_id'];

        // unset persited id if FC is removed
        if (!forecasts.get(lastForecastId)) {
            lastForecastId = null;
        }

        forecasts.add({
            id: 'default',
            name: 'Default forecast'
        }, {at: 0});

        this.forecastSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.forecastSelect,
            text: 'name',
            data: forecasts && forecasts.toJSON() || [],

            options: {
                placeholder: 'Select a forecast',
                containerCssClass: 'select2-plain select2-block title',
                dropdownCssClass: 'forecast-select-popover popover',
                formatNoMatches: function() {
                    return 'No available Forecasts';
                }
            }
        });

        this.ui.forecastSelect.select2('val', this.initialForecastId || lastForecastId || forecasts.models[0].id);
    }
});

ActivitySummaryView = Marionette.ItemView.extend({
    className: 'kpi-widget activity-kpi-widget activity-summary-widget',
    template: Handlebars.compile(activitySummaryTemplate),
    ui: {
        periodSelect: '.kpi-period-select'
    },
    events: {
        'change .kpi-period-select': 'onPeriodSelect'
    },
    initialize: function() {
        this.model.set({ period: 'week' });
        this.model.on('change', function() {
            this.render();
        }, this);
    },
    onRender: function() {
        this.initPeriodSelect();
    },
    initPeriodSelect: function() {
        var pad = function(number) {
                return ('0' + number).slice(-2);
            },
            now = new Date(),
            weekAgo = new Date(now - 604800000),
            month = now.getUTCMonth() + 1,
            quarterMonth = Math.floor(now.getUTCMonth() / 3) * 3 + 1,
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            startDates = {
                week: {
                    value: weekAgo.getUTCFullYear() + '-' + pad(weekAgo.getUTCMonth() + 1) +
                           '-' + pad(weekAgo.getUTCDate()),
                    label: months[weekAgo.getUTCMonth()] + ' ' + weekAgo.getUTCDate() + ' - Today'
                },
                month: {
                    value: now.getUTCFullYear() + '-' + pad(month) + '-01',
                    label: months[now.getUTCMonth()] + ' 1 - Today'
                },
                quarter: {
                    value: now.getUTCFullYear() + '-' + pad(quarterMonth) + '-01',
                    label: months[quarterMonth - 1] + ' 1 - Today'
                },
                year: {
                    value: now.getUTCFullYear() + '-01-01',
                    label: 'Jan 1 - Today'
                }
            },
            periodData = [
                {
                    id: 'week',
                    text: 'Last 7 Days',
                    description: startDates.week.label
                },
                {
                    id: 'month',
                    text: 'Month to Date',
                    description: startDates.month.label
                },
                {
                    id: 'quarter',
                    text: 'Quarter to Date',
                    description: startDates.quarter.label
                },
                {
                    id: 'year',
                    text: 'Year to Date',
                    description: startDates.year.label
                }
            ],
            formatResult = function(item, container) {
                return Handlebars.compile(
                    item.text +
                    '<div class="description">' + item.description + '</div>'
                );
            };

        this.startDates = startDates;

        this.periodSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.periodSelect,
            text: 'text',
            data: periodData,
            value: this.model.get('period'),
            options: {
                containerCssClass: 'select2-plain',
                dropdownCssClass: 'period-select-popover popover has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },
    onPeriodSelect: function(ev) {
        var statistics = new Backbone.Model(),
            currency = app.user.get('client')['default_currency'],
            view = this;

        this.model.set({ period: ev.added.id });

        var url;
        var managerId = this.model.get('manager_id');

        if (managerId) {
            url = `/v1/users/${managerId}/dashboards/sales_manager/member_stats/${this.model.get('user_id')}`;
        } else {
            url = `/v1/dashboards/sales_manager/member_stats`;
        }

        url += `?start_date=${this.startDates[ev.added.id].value}`

        if (this.model.get('recursive')) {
            url += '&recursive';
        }

        statistics.fetch({
            url: url,
            success: function() {
                var activities = statistics.get('activities'),
                    opportunities = statistics.get('opportunities');

                view.model.set({
                    num_phone_calls: activities.with_communication_medium.phone || 0,
                    num_emails: activities.with_communication_medium.email || 0,
                    num_total_activity: activities.total || 0,
                    closed_value: Currency.shortFormat(currency, opportunities.won.total_value)
                });
            }
        });
    }
});

export default Dashboard;

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'

import linechart from 'js/d3/linechart'
import timespanTemplate from 'templates/web/timespan-widget.handlebars'
import segmentsTemplate from 'templates/web/segments-widget.handlebars'
import statsReducedTemplate from 'templates/web/stats-reduced-widget.handlebars'


/*
 * This widget shows a linechart of visits in a timespan. It has a
 * header to filter last day, month, quarter and year.
 * Whatever time filter is selected is raised as an event
 */
var TimespanWidget = Marionette.Layout.extend({
    tagName: 'article',
    className: 'time-span-widget',
    template: Handlebars.compile(timespanTemplate),
    events: {
        'click .header .day' : 'filterLastDay',
        'click .header .week' : 'filterLastWeek',
        'click .header .month' : 'filterLastMonth',
        'click .header .quarter' : 'filterLastQuarter',
        'click .header .year' : 'filterLastYear',
        'timefilter' : 'setTimeFilter'
    },
    ui: {
        activableTabs: '.header .time',
        chartContainer: '.time-chart-container'
    },
    initialize: function(options) {
        this.series = ['hits', 'visits', 'returning', 'registered'];
        this.filterBySeries = [];

        if ('series' in options) {
            this.series = options.series;
        }
    },
    onRender: function() {
        this.chart = linechart();

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    },
    filterLastPeriod: function(period) {
        var filter = {
            type: 'lastPeriod',
            period: period
        };
        this.trigger('setFilter', 'timespan', [filter]);
    },
    filterLastDay: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('day');
    },
    filterLastWeek: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('week');
    },
    filterLastMonth: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('month');
    },
    filterLastQuarter: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('quarter');
    },
    filterLastYear: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('year');
    },
    setTimeFilter: function(ev, data) {
        var filter = {
            type: 'naturalPeriod',
            period: data.type,
            ts: data.ts ? data.ts.getTime() : null
        };
        if ( data.type !== 'minute' ) {
            this.trigger('setFilter', 'timespan', [filter]);
        }
    },
    updateFilters: function(filters) {
        if (this.isClosed) {
            return;
        }

        var timespanFilters = filters.timespan,
            timespanFilter = (timespanFilters && timespanFilters.length) ?
                                timespanFilters[0] : null;

        this.ui.activableTabs.removeClass('active');

        if ( timespanFilter && timespanFilter.type === 'lastPeriod' ) {
            this.ui.activableTabs.filter('.' + timespanFilter.period)
                .addClass('active');
        }

        // ...
        var segmentsFilters = filters.segments;
        var segmentsFilter = (segmentsFilters && segmentsFilters.length) ?
                            segmentsFilters[0] : null;

        this.filterBySeries = [];

        if (segmentsFilter) {
            var serie = (segmentsFilter.type === 'page-views') ? 'hits' : segmentsFilter.type;

            for (var i = this.series.indexOf(serie); i < this.series.length; ++i) {
                this.filterBySeries.push(this.series[i]);
            }

        }
    },
    updateData: function(data) {
        if (!data) {
            return;
        }

        var height = this.ui.chartContainer.height(),
            width = this.ui.chartContainer.width();

        if (!height || !width) {
            return;
        }

        this.chart.domain(
            _.map(data.domain, function(d) {
                return new Date(d + ' GMT');
            })
        );

        var self = this;
        this.chart.data(_.map(this.series, function(item) {
            if ((self.filterBySeries.length === 0) || (self.filterBySeries.indexOf(item) !== -1)) {
                return data[item];
            }
            return [];
        }));

        this.chart.span(data.span);

        this.chart.width(width);
        this.chart.height(height);

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    }
});

var SegmentsWidget = Marionette.Layout.extend({
    tagName: 'article',
    className: 'segments-widget',
    template: Handlebars.compile(segmentsTemplate),
    ui: {
        activableRows: '.segments-container .segments td',
        segments: '.segments-container .segments'
    },
    events: {
        'click .segments .page-views': 'filterPageViews',
        'click .segments .visits': 'filterVisits',
        'click .segments .returning': 'filterReturning',
        'click .segments .registered': 'filterRegistered'
    },
    initialize: function(options) {
        this.filtersEnabled = true;
        this.seriesNames = null;
        this.series = ['hits', 'visits', 'returning', 'registered'];

        if ('filtersEnabled' in options) {
            this.filtersEnabled = options.filtersEnabled;
        }

        if ('seriesNames' in options) {
            this.seriesNames = options.seriesNames;
        }

        if ('series' in options) {
            this.series = options.series;
        }
    },
    onRender: function() {
        if (this.seriesNames) {
            $(this.$el.find('.page-views .item-title')).text(this.seriesNames[0]);
            $(this.$el.find('.visits .item-title')).text(this.seriesNames[1]);
            $(this.$el.find('.returning .item-title')).text(this.seriesNames[2]);
            $(this.$el.find('.registered .item-title')).text(this.seriesNames[3]);
        }
    },
    filter: function(type) {
        if (!this.filtersEnabled) {
            return;
        }

        var filter = {
            type: type
        };
        this.trigger('setFilter', 'segments', [filter]);
    },
    filterPageViews: function(ev) {
        ev.preventDefault();
        this.filter('page-views');
    },
    filterVisits: function(ev) {
        ev.preventDefault();
        this.filter('visits');
    },
    filterReturning: function(ev) {
        ev.preventDefault();
        this.filter('returning');
    },
    filterRegistered: function(ev) {
        ev.preventDefault();
        this.filter('registered');
    },
    updateFilters: function(filters) {
        if (this.isClosed) {
            return;
        }

        var segmentsFilters = filters.segments,
            filter = (segmentsFilters && segmentsFilters.length) ?
                                segmentsFilters[0] : null;

        this.ui.activableRows.removeClass('active');

        if ( filter ) {
            this.ui.activableRows.filter('.' + filter.type)
                .addClass('active');
        }
    },
    updateData: function(data) {
        var self = this,
            maxSegment;

        if (!data) {
            return;
        }

        maxSegment = d3.max(_.map(this.series, function(item) {
            return data[item];
        }));

        function updateSegment(selector, value) {
            var sel;

            sel = d3.select(self.ui.segments.get(0));

            sel.select(selector)
                .call(function() {
                    var pct = value / maxSegment,
                        pctStr = (pct * 100) + '%';

                    this.select('.segment-value')
                        .text(value);

                    this.select('.segment-bar')
                        .transition()
                            .style('width', pctStr);

                });
        }

        updateSegment('.page-views', data[this.series[0]]);
        updateSegment('.visits', data[this.series[1]]);
        updateSegment('.returning', data[this.series[2]]);
        updateSegment('.registered', data[this.series[3]]);
    }
});


export default Marionette.Layout.extend({
    className: 'widget web-stats-widget',
    template: Handlebars.compile(statsReducedTemplate),
    regions: {
        timeSpanRegion: '.timespan-widget-container',
        segmentsRegion: '.segments-widget-container'
    },

    initialize: function(options) {
        this.options = options;
    },

    onRender: function() {
        this.spanWidget = new TimespanWidget(this.options);
        this.segmentsWidget = new SegmentsWidget(this.options);

        this.timeSpanRegion.show(this.spanWidget);
        this.segmentsRegion.show(this.segmentsWidget);

        // ...
        this.listenTo(this.spanWidget, 'setFilter', this.setFilter);
        this.listenTo(this.segmentsWidget, 'setFilter', this.setFilter);
    },

    updateData: function(dataTime, dataSegmentation) {
        if (!arguments.length) {
            dataTime = this.dataTime;
            dataSegmentation = this.dataSegmentation;
        } else {
            this.dataTime = dataTime;
            this.dataSegmentation = dataSegmentation;
        }

        this.spanWidget.updateData(dataTime);
        this.segmentsWidget.updateData(dataSegmentation);
    },

    updateFilters: function(filters) {
        this.spanWidget.updateFilters(filters);
        this.segmentsWidget.updateFilters(filters);
    },

    setFilter: function(name, value) {
        this.trigger('setFilter', name, value);
    }
});

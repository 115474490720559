import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import LocationModel from 'js/models/location'
import template from 'templates/base/location-list.handlebars'
import itemTemplate from 'templates/base/location-item.handlebars'


var LocationsListView, LocationInnerListView, LocationItemView;

LocationItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'expandable location-item communication-item',
    template: Handlebars.compile(itemTemplate),
    events: {
        'keydown .add-item-link': function(ev) {
            var key = ev.keyCode;

            // If no keycode or tab key
            if (!key || key === 9) {
                return;
            }

            // If key is number or letter or return
            if ((key >=48 && key <= 57) || (key >= 65 && key <= 90) || key === 13) {
                this.addItem();
                // If return key, prevent default
                return (key !== 13);
            }
        },
        'change .field-communication-name': 'change_name',
        'change .field-communication-value': 'change_address',
        'change .field-communication-info': 'change_comments',
        'click .add-item-link': 'addItem',
        'click .delete-item': 'deleteItem',
        'click .expand': function() {
            this.$el.toggleClass('expanded');
        }
    },
    ui: {
        edit_address: '.field-communication-value',
        error_messages: '.error-message'
    },
    initialize: function(options) {
        var view = this;
        this.controller = options.controller;

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            view.error_messages.remove.call(view);
            view.ui.edit_address.addClass('validation_error')
                .nextAll('.error-message')
                    .text(errors.missing_address)
                .addClass('invalid');
        });
    },
    onRender: function() {
        // Style as new item if no model.id is present
        this.$el.toggleClass('is-new', !this.model.id);

        this.$el.find('[data-toggle=tooltip]').tooltip();
    },
    change_name: function(ev) {
        this.model.set('name', $(ev.target).val());
    },
    change_address: function(ev) {
        this.model.set('address', $(ev.target).val());
    },
    change_comments: function(ev) {
        this.model.set('comments', $(ev.target).val());
    },
    addItem: function() {
        this.$el
            .removeClass('is-new')
            .find('.field-communication-value:first')
            .focus()
            .select();
    },
    deleteItem: function(ev) {
        $(ev.currentTarget).tooltip('hide');

        this.$el
            .animate(
                { opacity: 0 },
                { queue: false, duration: 200 }
            )
            .slideUp(200, 'easeOutQuart', function() {
                $(ev.currentTarget).tooltip('destroy'); // Kill it good!
                this.trigger('deleteItem');
            }.bind(this));
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

LocationInnerListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'communications-list locations-list',
    itemView: LocationItemView,
    initialize: function(options) {
        var view = this;
        this.controller = options.controller;
        this.originalCollection = options.originalCollection;
        this.collection = new Backbone.Collection();

        this.updateCollection();

        this.listenTo(this.controller, 'editing:cancel', function() {
            view.children.each( function(view){
                view.error_messages.hide.call(view);
            } );
        });
        this.listenTo(this.controller, 'editing:start', function() {
            view.onEditingStart.call(view);
            view.children.each( function(view) {
                view.error_messages.unhide.call(view);
            } );
        });
        this.listenTo(this.controller, 'editing:save:after', function() {
            view.children.each( function(view){
                view.error_messages.remove.call(view);
            } );
        });
        this.listenTo(this.controller, 'editing:save:before',
            this.onEditingSaveBefore);
        this.listenTo(this.controller, 'editing:end',
            this.onEditingEnd);

        this.listenTo(this.collection, 'change', this.onCollectionChange);

        this.listenTo(this, 'itemview:deleteItem', this.deleteItem);
    },
    updateCollection: function() {
        this.collection.reset(this.originalCollection.models);
    },
    onEditingStart: function() {
        // Add an extra item row for adding
        this.collection.add(new LocationModel());
    },
    onEditingEnd: function() {
        this.updateCollection();
    },
    onEditingSaveBefore: function() {
        this.originalCollection.reset(
            this.collection.filter(function(d) {
                return d.get('name') && d.get('name').length || d.get('address') && d.get('address').length;
            })
        );

        // Mark locations field as dirty
        // TODO: Mark it only when needed
        this.controller.addChange('locations', this.originalCollection);
    },
    onCollectionChange: function() {
        var last;

        if (this.controller.isEditing()) {
            last = this.collection.last();
            if (!last ||
                (last.get('address') && last.get('address').length))
            {
                this.collection.add(new LocationModel());
            }
        }
    },
    deleteItem: function(view) {
        this.collection.remove(view.model);
    }
});

LocationsListView = Marionette.Layout.extend({
    template: Handlebars.compile(template),
    regions: {
        listRegion: '.locations-list-container'
    },
    initialize: function(options) {
        this.controller = options.controller;
    },
    onRender: function() {
        this.listRegion.show(new LocationInnerListView({
            controller: this.controller,
            originalCollection: this.collection
        }));
    }
});

export default LocationsListView;

import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import app from 'js/app'
import TextManager from 'app/text-manager';
import {Select, PlainSelectItem, NewSelect, TagSelect} from 'js/react_views/widgets/select';
import classnames from 'classnames';

import styles from './automation.css';


const elementMap = {
    individuals: { id: "individuals", title: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'), icon: "icon-user" },
    organizations: { id: "organizations", title: TextManager.parseText('${ID_ORGANIZATION, capitalize}'), icon: "icon-home" },
    opportunities: { id: "opportunities", title: TextManager.parseText('${ID_DEAL, capitalize}'), icon: "icon-funnel-sm" }
};


class NewAutomationView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            owner: {
                id: app.user.get('id'),
                name: app.user.get('name'),
                title: app.user.get('name')
            },
            tags: app.user.getIndividualPreloadedTags(),
            funnels: app.user.getIndividualPreloadedFunnels(),
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectClick = this.handleSelectClick.bind(this);
        this.handleSelectOwner = this.handleSelectOwner.bind(this);
        this.elementTypeSearch = this.elementTypeSearch.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleFunnelChange = this.handleFunnelChange.bind(this);
    }

    handleInputChange(ev) {
        const target = ev.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSelectClick(items) {
        const item = items[0];

        this.setState({
          element: item.id
        });
    }

    handleSelectOwner(items) {
        const item = items[0];

        this.setState({
            owner: {
                id: item.id,
                name: item.title,
                title: item.title
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state) {
            return;
        }

        this.props.updateAutomationData({
            name: this.state.name,
            entity_type: this.state.element,
            owner: this.state.owner,
            tags: this.state.tags,
            funnels: this.state.funnels,
        });
    }

    elementTypeSearch(search, done) {
        done(_.map(elementMap, item => item));
    }

    handleTagChange(items){
        this.setState({
            tags: _.map(items, function(item) { return { id: item.id }})
        })
    }

    handleFunnelChange(items){
        this.setState({
            funnels: _.map(items, function(item) { return { id: item.id }})
        })
    }

    render() {
        const smallIconClasses = this.state.element ? classnames({
            [styles.smallIcon]: true,
            [elementMap[this.state.element].icon]: true
        }) : null;

        return (
            <div className={styles.NewAutomationView}>
                <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                        <i className="icon-bolt" />
                        <div className={smallIconClasses}></div>
                    </div>
                </div>

                <div className={styles.attributeContainer}>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        className={classnames({
                            [styles.attribute]: true,
                            [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf('name') >= 0))
                        })}
                        placeholder="Name"
                        autoComplete="off"
                        name="name"
                        id="name"
                        value={this.state.name || ""}
                        onChange={this.handleInputChange}
                    /><br />

                    <label htmlFor="element">Element</label>
                    <NewSelect
                        className={classnames({
                            [styles.attribute]: true,
                            [styles.errorAttribute]: (this.props.errorField && (this.props.errorField.indexOf('entity_type') >= 0))
                        })}
                        value={elementMap[this.state.element]}
                        data={_.map(elementMap, item => item)}
                        onSelect={this.handleSelectClick}
                        placeholder="Element"
                        width={250}
                        containerStyle={{
                            position: "relative",
                            width: "250px",
                            display: "inline-block",
                            height: "32px",
                            lineHeight: "32px",
                            verticalAlign: "middle"
                        }}
                        boxStyle={{
                            background: null,
                            borderWidth: 0,
                            paddingLeft: 0
                        }}
                        dropDownStyle={{
                            left: 0,
                            zIndex: 1
                        }}
                    /><br/>

                    <label htmlFor="owner">Owner</label>
                    <NewSelect
                        className={styles.attribute}
                        value={this.state.owner}
                        width={250}
                        url="/users"
                        onSelect={this.handleSelectOwner}
                        options={{minimumInputLength: 1}}
                        containerStyle={{
                            position: "relative",
                            width: "250px",
                            display: "inline-block",
                            height: "32px",
                            lineHeight: "32px",
                            verticalAlign: "middle"
                        }}
                        boxStyle={{
                            background: null,
                            borderWidth: 0,
                            paddingLeft: 0
                        }}
                        dropDownStyle={{
                            left: 0,
                            zIndex: 1
                        }}
                    /><br/>
                    <label htmlFor="tags">Tags</label>
                    <div style={{
                        position: "relative",
                        width: "250px",
                        display: "inline-block",
                        minHeight: "32px",
                        lineHeight: "32px",
                        verticalAlign: "middle",
                        textAlign: "left",
                    }}>
                        <TagSelect
                            url='/tags'
                            text='name'
                            width='240'
                            value={this.state.tags}
                            placeholder='+ Add Tag'
                            onChange={this.handleTagChange}
                        />
                    </div><br/>
                    <label htmlFor="funnels">{TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}</label>
                    <div style={{
                        position: "relative",
                        width: "250px",
                        display: "inline-block",
                        minHeight: "32px",
                        lineHeight: "32px",
                        verticalAlign: "middle",
                        textAlign: "left",
                    }}>
                        <TagSelect
                            url='/funnels'
                            text='name'
                            width='240'
                            value={this.state.funnels}
                            placeholder={`+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`}
                            onChange={this.handleFunnelChange}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default NewAutomationView;
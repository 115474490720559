import _ from 'underscore'

import vent from 'js/vent'
import app from 'js/app'
import ModalRegion from 'js/views/base/modal-region'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import CampaignsCollection from 'js/collections/campaigns'
import IndividualItemView from 'js/views/individuals/item'
import NoCollectionView from 'js/views/base/no_collection'
import CampaignCreationView from 'app/content/campaigns/_components/campaign-creation/campaign-creation'
import CampaignPreview from 'app/content/campaigns/_components/campaign-preview/campaign-preview'


var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = IODListingBaseView.prototype.OverviewView.extend({
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.collection = new CampaignsCollection();
        this.collection.toBeLoaded = true;

        this.filter_id = this.options.filterId;

        if (this.group) {
            const filter = this.group.get('filter');
            this.filter_id = (filter && filter.id) || this.filter_id;
        }

        this.fetchOptions = _.extend({
            extended: true
        }, this.fetchOptions);
    },
    download: function() {
        var url = app.options.apiUrl + '/campaigns';
        var params = _.extend({
            csv: true,
            columns: _.map(this.tableRegion.currentView.getSelectedColumns(), function(column) { return column.id || column; })
        }, this.fetchOptions);

        if (this.sortOptions && this.sortOptions.length > 0) {
            params.order_by = _.map(this.sortOptions, function(item) {
                return item.attribute + ' ' + item.order;
            }).join(',');
        }

        this.downloadForm(url, params);
    },
    onFilterUpdate: function(filter) {
        var view = this;

        this.filter_id = filter ? filter.get('id') : null;

        if (this.group && this.group.get('group_type') === 'smart') {
            this.group.save({
                filter_id: filter && filter.get('id') || null
            }, {
                patch: true,
                complete: function() {
                    BaseOverviewView.prototype.onFilterUpdate.call(view, filter);
                }
            });
        } else {
            BaseOverviewView.prototype.onFilterUpdate.call(this, filter);
        }
    },
    duplicateStatic: function() {
        const groupId = this.options.group ? this.options.group.get('id') : this.model.get('id');
        const groupComments = this.options.group ? this.options.group.get('comments') : '';

        BaseOverviewView.prototype.duplicateStatic.call(this, groupId, groupComments, 'campaigns');
    },
    duplicateSmart: function() {
        const groupId = this.options.group ? this.options.group.get('id') : this.model.get('id');
        const groupComments = this.options.group ? this.options.group.get('comments') : '';

        BaseOverviewView.prototype.duplicateSmart.call(this, groupId, groupComments, 'campaigns');
    },
    getParams: function() {
        if (this.filter_id && (!this.group || this.group.get('group_type') !== 'smart')) {
            return {
                filter_id: this.filter_id
            };
        }

        return {};
    }
});

export default IODListingBaseView.extend({
    regions: _.extend({
        campaignRegion: {
            selector: '#campaign-creation', // selector it self not used in ModalRegion
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false}) // statics disables native closing of modal
        }}, IODListingBaseView.prototype.regions
    ),
    OverviewView: OverviewView,
    showItem: function(model, edit, preview) {
        var self = this;

        var callback = function() {
            if (self.viewType === 'sent' || preview) {
                self.campaignRegion.show(new CampaignPreview({model: model}));
            }
            else {
                self.campaignRegion.show(new CampaignCreationView({model: model}));
            }
            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        };

        if (model) {
            model.fetch({
                success: function () {
                    callback();
                }
            });
        }
        else {
            callback();
        }
    },
    getElementType: function() {
        return 'campaigns';
    }
});

import React from 'react';

import style from './content-pane.css';

class ContentPane extends React.Component {
    render () {
        return (
            <div className={style.ContentPane}>
                {this.props.children}
            </div>
        );
    }
}

export default ContentPane;

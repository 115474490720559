import app from 'js/app'
import AppConfig from 'app/app-config'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import vent from 'js/vent'

import dealsCalendarTemplate from 'templates/settings/edit_deals_calendar.handlebars'

export default Marionette.Layout.extend({
    className: 'edit-deals-calendar',
    template: Handlebars.compile(dealsCalendarTemplate),
    ui: {
        showDealsCalendar: '#show-deals-calendar',
        reportDateField: '#report-date'
    },
    events: {
        'click .save': function() {
            var self = this;
            var clientPreferences = app.user.get('client').preferences || {};

            if(_.keys(this.changes).length === 0) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'No Changes Made',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });

                return;
            }

            for (const key in this.changes) {
                clientPreferences[key] = this.changes[key];
            }

            $.ajax({
                type: 'PATCH',
                url: '/clients/1',
                dataType: 'json',
                data: JSON.stringify({
                    preferences: clientPreferences
                }),
                success: function(data) {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });

                    self.changes = {};
                }
            });
        },
        'click #show-deals-calendar': function(ev) {
            var newValue = ev.target.checked ? 'true' : 'false';

            this.addChange('showDealsCalendar', newValue);
        }
    },
    initialize: function() {
        this.changes = {};
    },
    onRender: function() {
        const dealCustomFields = app.globalData.customFieldsInfo.deals;
        var fields = [
            {
                id: 'expected_close_date',
                name: 'Close Date'
            }
        ];

        for (const cfId in dealCustomFields) {
            if (dealCustomFields[cfId].type === 'date') {
                fields.push({
                    id: cfId,
                    name: dealCustomFields[cfId].name
                });
            }
        }

        var reportDateValue = AppConfig.getClientPreferenceValue('dealsCalendarReportDate');
        if (reportDateValue && reportDateValue.includes('custom_field')) {
            var parts = reportDateValue.split('.');

            if (parts[1]) {
                reportDateValue = parts[1];
            }
        }

        this.reportDateSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.reportDateField,
            text: 'name',
            data: fields,
            value: reportDateValue,
            options: {
                placeholder: 'Select Report Date',
                containerCssClass: 'editable-field',
                dropdownCssClass: 'source-select-popover popover'
            }
        });

        this.listenTo(this.reportDateSelect, 'change', function(item) {
            var id = item && item.id;

            if (id !== 'expected_close_date') {
                id = 'custom_fields.' + id;
            }

            this.addChange('dealsCalendarReportDate', id);
        });

        this.ui.showDealsCalendar.prop('checked', AppConfig.getClientPreferenceValue('showDealsCalendar'));
    },
    addChange: function(key, value) {
        this.changes[key] = value;
    },
});
import React from 'react';

import { NewSelect } from 'js/react_views/widgets/select';

import style from './add_entity.css';

export default class AddEntity extends React.Component {
    formatter(item, search) {
        const value = item.title || item.name;

        if (search) {
            const idx = value.toLowerCase().indexOf(search.toLowerCase());
            return value.substring(0, idx) + '<span style="text-decoration: underline;">' + value.substring(idx, idx + search.length) + '</span>' + value.substring(idx + search.length);
        }

        return value;
    }

    render() {
        const searchParameters = _.clone(this.props.searchArgs) || {};

        if (this.props.sectionId) {
            searchParameters.section_id = this.props.sectionId;
        }

        return (
            <div
                className={style.abAddPopover}
                onClick={() => this.props.onClose(null)}
            >
                <div
                    className={style.pDialog}
                    style={{
                        top: this.props.top,
                        left: this.props.left
                    }}
                >
                    <div className={style.dTitle}>Add an item</div>

                    <div className={style.dDropdown}>
                        <NewSelect
                            url={`/${this.props.entityType}`}
                            width={330}
                            options={{
                                minimumInputLength: 1,
                                formatResult: this.formatter.bind(this),
                                search_parameters: searchParameters
                            }}
                            onSelect={(items) => this.props.onClose(items[0])}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
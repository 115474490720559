import $ from 'jquery'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import app from 'js/app'
import vent from 'js/vent'
import api from 'js/api'
import browserSupportTemplate from 'templates/browser_support.handlebars'


var BrowserError = BaseView.extend({
    className: 'browser-support-message',
    tagName: 'article',
    template: Handlebars.compile(browserSupportTemplate),
    onRender: function(){
        this.$el.find('[data-toggle=tooltip]').tooltip({
            container: 'body',
            placement: 'bottom'
        });
    }
});
export default BrowserError;

import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import app from 'js/app';
import TextManager from 'app/text-manager';

export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('opportunities');
    const funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

    const fixedColumns = [
        columnsCreator.text('index', '#', { width: 100, filterable: false, sortable: false, groupable: false }),
        columnsCreator.text('name', 'Name', { filterable: true } ),
        columnsCreator.date('created', 'Created'),
        columnsCreator.text('comments', 'More Info', { filterable: true, filterId: 'opportunity_comments' } ),
        columnsCreator.tags('tags', 'Tags', { minWidth: 150, filterable: true, sortable: true, groupable: true }),
        columnsCreator.text('abbreviation', 'Deal Id', { groupable: false }),
        columnsCreator.date('expected_close_date', 'Close Date', { filterId: 'opportunity_closed_date' }),
        columnsCreator.text('currency', 'Currency', { width: 120 }),
        columnsCreator.currency('default_value', `${app.user.get('client').default_currency} Value`),
        columnsCreator.currency('weighted_value', `Weighted ${app.user.get('client').default_currency} Value`),
        columnsCreator.forecastStatus('status', 'Status'),
        columnsCreator.text('weight', 'Weight'),
        columnsCreator.currency('buckets_total', 'Deal Value'),
        columnsCreator.text('latest_activity_in_days', 'Last Activity (Days)'),
        columnsCreator.text('funnel.name', funnelText, { filterable: true, filterId: 'opportunity_funnel_id', filterType: 'funnel' }),
        columnsCreator.text('phase.name', 'Phase', { filterable: true, filterId: 'opportunity_phase_id', filterType: 'phase' }),
        columnsCreator.text('owner.name', 'Owner'),
        columnsCreator.text('phase.default_weight', 'Phase Default Weight'),
        columnsCreator.date('phase_last_changed', 'Phase Last Changed', { filterable: false }),
        columnsCreator.activity('last_direct_activity', 'Last Activity'),
        columnsCreator.date('last_direct_activity.modified', 'Last Activity Date', { filterable: false }),
        columnsCreator.activity('pinned_activity', 'Starred Activity'),
        columnsCreator.text('organization.name', 'Organization', { filterable: true, filterId: 'organization_id', filterType: 'organization' }),
        columnsCreator.text('organization.website', 'Organization Website'),
        columnsCreator.text('organization.comments', 'Organization More Info'),
        columnsCreator.locations('organization.locations', 'Organization Locations'),
        columnsCreator.communications('organization.email', 'Organization Emails'),
        columnsCreator.communications('organization.phone', 'Organization Phones'),
        columnsCreator.text('organization.linkedin', 'Organization Linkedin', { sortable: false }),
        columnsCreator.text('organization.twitter', 'Organization Twitter', { sortable: false }),
        columnsCreator.text('organization.facebook', 'Organization Facebook', { sortable: false }),
        columnsCreator.text('organization.googleplus', 'Organization Google+', { sortable: false }),
        columnsCreator.text('organization.instagram', 'Organization Instagram', { sortable: false }),
        columnsCreator.text('organization.owner.name', 'Organization Owner', { sortable: false }),
        columnsCreator.activity('organization.last_direct_activity', 'Organization Last Activity'),
        columnsCreator.date('organization.last_direct_activity.modified', 'Organization Last Activity Date', { filterable: false }),
        columnsCreator.activity('organization.pinned_activity', 'Organization Starred Activity'),
        columnsCreator.text('funnel.region_name', `${funnelText} Region`, { sortable: false }),
    ];

    const customFieldsColumns = columnsCreator.customFields('opportunities');
    const organizationCustomFieldsColumns = columnsCreator.customFields('organizations', true);
    const bucketsColumns = columnsCreator.buckets();

    return [...fixedColumns, ...customFieldsColumns, ...organizationCustomFieldsColumns, ...bucketsColumns];
}

export const DEFAULT_COLUMNS_IDS = [
    'name',
    'organization.name',
    'funnel.name',
    'phase.name',
    'expected_close_date',
    'owner.name',
    'default_value',
    'weight'
];
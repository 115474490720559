import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app';
import AppConfig from 'app/app-config';
import MessageBox from 'js/views/message_box';
import vent from 'js/vent';

import style from './credas.css'


class Credas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    handleStartProcess() {
        let self = this;
        const credasCheckJourneyStarted = AppConfig.getValue('credasCheckJourneyStarted', false);

        /*
            Only connells use credas, this change needs to be implemented only for the demo system initially.
            In the future the change needed is remove app config value 
            and leave the code inside the if (credasCheckJourneyStarted) 
        */

        if (credasCheckJourneyStarted) {
            let credasProcessIdCfId = null;
            const attrs = this.props.model.attributes;
            const customFields = app.globalData.customFieldsInfo.individuals;

            for (const cfId in customFields) {
                if (customFields[cfId].name.toLowerCase() === 'credas process id') {
                    credasProcessIdCfId = cfId;
                    break;
                }
            }

            if (credasProcessIdCfId && `custom_field.${credasProcessIdCfId}` in attrs && attrs[`custom_field.${credasProcessIdCfId}`]) {
                MessageBox.showYesNo(
                    {
                        icon: 'icon-user',
                        message: 'AML process has already been started, would you wish to cancel or initiate a new one?',
                        accept_button_text: 'Continue',
                        cancel_button_text: 'Cancel'
                    },
                    this.props.parentView,
                    function() { // Yes
                        self.startProcess();
                    },
                    function() { // No
                        return;
                    },
                );
            } else {
                this.startProcess();
            }
        } else {
            this.startProcess();
        }
    }

    startProcess() {
        if (this.state.loading) {
            return;
        }
        const self = this;

        this.setState({
            loading: true,
        });

        $.ajax({
            type: 'POST',
            url: '/credas/start_journey',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                individual_id: this.props.model.id,
            }),
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Credas journey has been requested. We will notify you when contact has completed the journey',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            },
            complete: function() {
                self.setState({
                    loading: false,
                });
                self.props.refreshIndividualView();
            }
        });
    }

    handleResubmitIdCheck() {
        if (this.state.loading) {
            return;
        }
        const self = this;

        this.setState({
            loading: true,
        });

        $.ajax({
            type: 'POST',
            url: '/credas/resubmit_id_check',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                individual_id: this.props.model.id,
            }),
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Credas resubmit Id check has been sent.',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            },
            complete: function() {
                self.setState({
                    loading: false,
                });
                self.props.refreshIndividualView();
            }
        });
    }

    handleCheckDuplicates() {
        var self = this;

        $.ajax({
            type: 'POST',
            url: '/credas/check_duplicates',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                individual_id: this.props.model.id,
            }),
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'No duplicates found. You can safely start a new Process.',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            },
            complete: function() {
                self.props.refreshIndividualView();
            }
        });
    }

    handleViewReport() {
        var self = this;

        $.ajax({
            type: 'POST',
            url: '/credas/generate_report',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({
                individual_id: this.props.model.id,
            }),
            success: function(response) {
                // open link in new tab
                window.open(response.url, "_blank");
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });
    }

    handleUpdateStatus() {
        var self = this;

        $.ajax({
            type: 'GET',
            url: `/credas/update_status/${this.props.model.id}`,
            success: function(response) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Succesfully updated status.',
                            classes: 'success',
                            timer: 3000
                        };
                    }
                });

                self.props.refreshIndividualView();
            },
            error: function(data) {
                var message = 'Unable to process request.';
                var resp = self.isJson(data.responseText);

                if (resp) {
                    message = resp.detail.message
                }

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: message,
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        })
    }

    isJson(jsonString) {
        try {
            var o = JSON.parse(jsonString);

            if (o && typeof o === "object") {
                return o;
            }
        }
        catch (e) { }
    
        return false;
    }

    render() {
        return (
            <div className={style.credasViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.props.onClose}
                        >
                        Close
                    </div>

                    <div className={style.title}>Credas AML</div>
                </div>

                <div className={style.credasBody}>
                    <div className={style.credasLogo}></div>

                    <div
                        className={style.checkDuplicates}
                        onClick={this.handleCheckDuplicates.bind(this)}
                    >
                        <i className="item-icon icon-users"></i>
                        Check Duplicates
                    </div>

                    <div
                        className={style.startProcess}
                        onClick={this.handleStartProcess.bind(this)}
                    >
                        <i className="icon-plus"></i>
                        Start AML Process
                    </div>

                    <div
                        className={style.startProcess}
                        onClick={this.handleResubmitIdCheck.bind(this)}
                    >
                        <i className="icon-plus"></i>
                        Resubmit Id Check
                    </div>

                    <div
                        className={style.viewReport}
                        onClick={this.handleViewReport.bind(this)}
                    >
                        <i className="icon-file"></i>
                        View AML Report
                    </div>

                    <div
                        className={style.updateStatus}
                        onClick={this.handleUpdateStatus.bind(this)}
                    >
                        <div className={style.loop}></div>
                        Update Status
                    </div>
                </div>
            </div>
        )
    }
}


const CredasView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'credas-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <Credas
                model={this.options.model}
                refreshIndividualView={this.options.refreshIndividualView}
                parentView={this.options.parentView}
                onClose={() => this.trigger('close')}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default CredasView;

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import ActivityFeedView from 'js/views/activity/feed.js'
import createSocialList from 'js/views/social/create-social-list.js'
import RelatedFilesListView from 'js/views/related_files_list.js'
import GroupModel from 'js/models/group.js'
import appContent from 'js/views/appcontent.js'
import app from 'js/app.js'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import vent from 'js/vent.js'
import CommunicationsListView from 'js/views/base/communications-list.js'
import LocationsListView from 'js/views/base/locations-list.js'
import createDealsList from 'js/views/opportunities/create-deals-list.js'
import ItemPermissionsView from 'js/views/item_permissions.js'
import api from 'js/api.js'
import security from 'js/utils/security.js'
import linkify from 'js/utils/linkify.js'
import MessageBox from 'js/views/message_box.js'
import OrganizationModel from 'js/models/organization.js'
import ActivityCreator from 'js/utils/activity_creator.js'
import UserModel from 'js/models/user.js'
import LeadSourcesCollection from 'js/collections/lead_sources.js'
import ModalRegion from 'js/views/base/modal-region.js'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import OpportunitiesCollection from 'js/collections/opportunities.js'
import CommunicationCollection from 'js/collections/communications.js'
import LocationsCollection from 'js/collections/locations.js'
import SocialItemsCollection from 'js/collections/social.js'
import ChecklistsCollection from 'js/collections/checklists.js'
import CustomFieldsView from 'js/views/custom_fields.js'
import TasksView from 'app_v2/panels/tasks_list.js'
import ChecklistsView from 'js/react_views/checklists/checklists.js'
import GroupListContainsView from 'js/views/groups/list-contains.js'
import RelatedFilesCollection from 'js/collections/related_files.js'
import IndividualContainerView from 'app/contacts/individual/individual-container'
import individualViewTemplate from 'templates/individuals/detail.handlebars'
import individualOverviewTemplate from 'templates/individuals/detail_overview.handlebars'
import DocusignPrepareView from 'js/views/individuals/docusign-prepare'
import ConversationsView from 'js/react_views/conversations/conversations.js'
import AppointmentsView from 'js/react_views/appointments/appointments'


var DetailViewController, IndividualView, OverviewView;

DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.changes = {};
        this.redyToMakeDirty = false; // flag to when register view as dirty
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
        this.redyToMakeDirty = true;
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        this.changes[key] = value;
        if (this.redyToMakeDirty) {
            app.dirtyModelHandler.add(this.model.cid);
        }
    },
    removeChange: function(key) {
        delete this.changes[key];
    },
    saveEdition: function() {
        var controller = this;

        this.trigger('editing:save:before');

        if (_.keys(this.changes).length) {
            this.model.save(this.changes, {
                extraFields: ['locations'],
                patch: true,
                success: function() {
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('contact:save');
                    app.dirtyModelHandler.remove(controller.model.cid);
                    vent.trigger('update:activity');
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    cancelEdition: function() {
        app.dirtyModelHandler.confirm(this, function() {
            var controller = this;
            this.changes = {};
            this.model.fetch({
                 success: function () {
                     controller.trigger('editing:cancel');
                     controller.endEditing();
                 }
            });
        });
    },
    destroyModel: function() {
        if ( this.model.urlRoot === '/leads' ) {
            this.model.destroy( { url: '/individuals/' + encodeURIComponent( this.model.id ) } );
        }
        else {
            this.model.destroy();
        }
    },
    followCommunicationLink: function(communication, link, onSaveCallback) {
        var activity = ActivityCreator.createAutoCommunication(
            communication.get('medium'),
            communication,
            this.model.get('type'),
            this.model.get('id')
        );

        window.open(link);

        if (activity) {
            activity.save({}, {
                complete: function() {
                    vent.trigger('update:activity');
                    if (onSaveCallback) {
                        onSaveCallback(activity);
                    }
                }
            });
        }
    },
    followMultipleCommunicationLink: function(communications, link) {
        communications.forEach(communication => {
            var activity = ActivityCreator.createAutoCommunication(
                communication.get('medium'),
                communication,
                this.model.get('type'),
                this.model.get('id')
            );

            if (activity) {
                activity.save({}, {
                    complete: function() {
                        vent.trigger('update:activity');
                    }
                });
            }
        })

        window.open(link);

    }
});

IndividualView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-individuals content-container',
    template: Handlebars.compile(individualViewTemplate),
    regions: {
        individualContainerRegion: '.overview-container',
        activityFeedRegion: '.notes-pane',
        taskFeedRegion: '.tasks-pane',
        socialFeedRegion: '.social-pane',
        conversationsRegion: '.conversations-pane',
        appointmentsRegion: '.appointments-pane',
        customFieldsRegion: '.custom-fields-container',
        checklistsRegion: '.checklists-container'
    },
    events: {
        'click .toggle-favorite': 'toggleFavorite',
        'click .edit.has-permission': 'startEditing',
        'click .save': 'saveItem',
        'click .cancel': 'cancelEditing',
        'click .organization-link': 'goToOrganization',
        'click .tabs a': function(ev) {
            ev.preventDefault();
            this.showTab($(ev.currentTarget));
        },
        'click .close-view': 'closeView',
        'resize': 'scrollbar'
    },
    ui: {
        'activity': '.activity-container',
        'conversationsTab': '.conversations-tab',
        'appointmentsTab': '.appointments-tab'
    },
    initialize: function() {
        if ( !this.model.id ) {
            this.creatingNewItem = true;
            this.model.set("owner", { id: app.user.get('id'), name: app.user.get('name') });
            if (this.options.fromOpportunityModel) {
                this.model.set('opportunities', [{ id: this.options.fromOpportunityModel.get('id') }]);
            }
            if (this.options.fromIndividualModel) {
                this.model.set('individuals', [{ id: this.options.fromIndividualModel.get('id') }]);
            }
        }
        this.editing = this.options.editing;

        this.controller = new DetailViewController({
            model: this.model
        });

        this.listenTo(vent, 'update-checklist', function() {
            this.createChecklistsView();
        });

        this.listenTo(vent, 'update-tasks', function() {
            this.createTasksView();
        });

        this.listenTo(this.controller, 'editing:start',
            this.onStartEditing);
        this.listenTo(this.controller, 'editing:end',
            this.onEndEditing);
        this.listenTo(this.model, 'destroy remove', this.onModelDestroy);

        if (AppConfig.getValue('individuals.groups.recently_viewed.visible', true) && this.model.get('id')) {
            $.ajax({
                url: '/users/' + app.user.get('id') + '/recent_individuals/items/' + this.model.get('id'),
                contentType: 'application/json',
                processData: false,
                dataType: 'json',
                type: 'PUT'
            });
        }

        this.initialRightTab = this.options.rightTab;
    },
    toggleFavorite: function(ev) {
        $(ev.currentTarget).tooltip('hide');
        $(ev.currentTarget).toggleClass('favorite');
    },
    startEditing: function() {
        this.controller.startEditing();
    },
    saveItem: function() {
        if (this.manageCustomFields()) {
            this.controller.saveEdition();
        }
    },
    /**
     * Checks whether custom fields are changed, are there valid values and marks changes in controller if so.
     * Shows and hides error fields accordingly.
     *
     * @returns {boolean}   whether custom fields are ready to be saved
     */
    manageCustomFields: function() {
        var customFieldsRegion = this.customFieldsRegion && this.customFieldsRegion.currentView;
        var individualContainerRegion = this.individualContainerRegion.currentView;
        if (customFieldsRegion) {
            if (customFieldsRegion.valuesAreValid()) {
                customFieldsRegion.showInvalidFieldsError(false);
                if (customFieldsRegion.hasChanges()) {
                    this.controller.addChange('custom_fields', customFieldsRegion.getChanges());
                }
            }
            else {
                individualContainerRegion.error_messages.remove.call(individualContainerRegion);
                customFieldsRegion.showInvalidFieldsError(true);

                var changes = _.extend(this.model.attributes, this.controller.changes);
                var errors = this.model.validate(changes);
                individualContainerRegion.showInvalidFields(errors);

                return false;
            }
        }

        return true;
    },
    cancelEditing: function() {
        this.controller.cancelEdition();
    },
    onStartEditing: function() {
        var view = this;
        this.listenTo(vent, 'shortcut:save', function(ev) {
            var target = $(ev.target);

            // if we have a input with focus we made it lost and gain again to force to save the data inside it
            if (target && target.is('input')) {
                target.blur();
                target.focus();
            }

            view.saveItem();
        });

        // ...
        this.$el.addClass('edit-mode');
        this.$el.find('.related-content').hide();
        // Show details pane on edit
        this.$el.find('.details-tab a').tab('show');

        if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
            this.customFieldsRegion.currentView.onStartEditing();
        }
    },
    onEndEditing: function() {
        this.stopListening(vent, 'shortcut:save');

        // Close the view if there is no item to show
        if (!this.model.id) {
            this.closeView();
            return;
        }

        var overviewView;
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            // do stuff here to do with going out of new item creation state
            // that the old code does by means of complex interaction between
            // the main view and the OverviewView

            if (this.creatingNewItem) {
                this.creatingNewItem = false;
                this.editing = false;
                // rerendering will show React overview in place of the
                // Marionette one used for editing
                this.render();
                vent.trigger('AppContent:contentChange');
            }
            else {
                this.showOverviewView(IndividualContainerView);
            }
            this.$el.removeClass('edit-mode');
        }
        else {
            this.$el.removeClass('edit-mode');
            this.$el.find('.related-content').show();
            // Show details pane on edit
            this.$el.find('.details-tab a').tab('show');
            if (this.customFieldsRegion && this.customFieldsRegion.currentView) {
                this.customFieldsRegion.currentView.onEndEditing();
            }
        }
    },
    onModelDestroy: function() {
        this.trigger('view:close', this);
    },
    onWindowResize: function() {
        var display = this.ui.activity.css('display') !== 'none';
        if (this.lastDisplay === display) { return; }
        this.lastDisplay = display;

        this.updateActivityTab($('#notes-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#tasks-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#social-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#conversations-pane'), $('.overview.content'), this.ui.activity);
        this.updateTab($('#appointments-pane'), $('.overview.content'), this.ui.activity);

        this.$el.find('.tabs .active').removeClass('active');
        this.$el.find('.detail-nav .tabs .details-tab a').tab('show');
        if (display) {
            if (this.initialRightTab === 'Task') {
                this.$el.find('.activity-container .tabs .tasks-tab a').tab('show');
                delete this.initialRightTab;
            }
            else {
                this.$el.find('.activity-container .tabs .notes-tab a').tab('show');
            }
        }
    },
    updateActivityTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }

        var self = this,
            view = this.activityFeedRegion.currentView;
        if (view) {
            view.removeTinyMCE(view);
            // exit edit mode for list elements not to break TinyMCE
            view.listRegion.currentView.children.each(function (item) {
                item.exitEditMode && item.exitEditMode();
            });
        }

        location.append(content);
        _.defer(function () {
            var view = self.activityFeedRegion.currentView;
            view && view.initTinyMCE(view);
        });
    },
    updateTab: function(content, location1, location2) {
        var location;
        if (this.ui.activity.css('display') === 'none') {
            location = location1;
        } else {
            location = location2;
        }
        location.append(content);
    },
    onClose: function() {
        $(window).off('resize', this.onWindowResize);
        this.$el.parent('.region').removeClass('non-condensed-detail-region');
        $('#app-stacks').removeClass('non-condensed-app-stacks');
    },
    onRender: function() {
        // //hacky way to detect when activity-container is shown/hidden
        $(window).on('resize', $.proxy(this.onWindowResize, this));

        var self = this;

        if (this.model.id) {
            this.model.fetch({
                extraFields: ['organization'],
                success: function() {
                    self.onModel(self.model);
                }
            });
        } else {
            this.individualContainerRegion.show(new IndividualContainerView({
                model: this.model,
                isLead: !!this.options.isLead
            }));
            this.listenTo(this.individualContainerRegion.currentView, 'close-view', this.closeView);
            this.listenTo(this.individualContainerRegion.currentView, 'replace-view:edit', function() {
                self.individualContainerRegion.currentView.setEditing(true, true);
            });
            this.listenTo(this.individualContainerRegion.currentView, 'go-to-organization', this.goToOrganization);
            this.listenTo(this.individualContainerRegion.currentView, 'contact:save', function() {
                self.stopListening(self.individualContainerRegion.currentView, 'contact:save');
                self.onModel(self.model);
                self.fetchSocial();
                self.createTasksView();
            });
            this.listenTo(this.individualContainerRegion.currentView, 'show-tab', function(tab) {
                self.showTab(self.$el.find(`a#${tab}`));
            });
        }
    },
    showOverviewView: function(OverviewViewLayout) {
        var overviewView = new OverviewViewLayout({
            controller: this.controller,
            parent: this,
            model: this.model,
            leadSources: this.leadSources,
            gotoSection: this.options.gotoSection
        });
        var self = this;

        this.listenTo(overviewView, 'close-view', this.closeView);
        // this.listenTo(overviewView, 'replace-view:edit', this.replaceOverviewViewForEdit);
        this.listenTo(overviewView, 'replace-view:edit', function() {
            overviewView.setEditing(true, true);
        });
        this.listenTo(overviewView, 'go-to-organization', this.goToOrganization);
        this.listenTo(overviewView, 'show-tab', function(tab) {
            self.showTab(self.$el.find(`a#${tab}`));
        });
        this.individualContainerRegion.show(overviewView);

        if (OverviewViewLayout === IndividualContainerView) {
            // hack the nanoscroller property off the overview region's dom element
            // after showing IndividualContainerView in the region
            // this is to allow nanoScroller to initialize itself when OverviewView
            // is shown for editing which would otherwise be prevented by other views
            // nested inside OverviewView that also use nanoScroller
            delete this.individualContainerRegion.$el.get(0).nanoscroller;
        }
    },
    onModel: function(model) {
        if (this.isClosed) {
            return;
        }

        if (AppConfig.getValue('individuals.groups.recently_viewed.visible', true)) {
            // Update recents list
            $.ajax({
                type: 'PUT',
                url: app.user.url() + '/recent_individuals/items/' + model.get('id'),
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({id: model.get('id')})
            });
        }

        var overviewViewLayout = OverviewView;

        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            overviewViewLayout = IndividualContainerView;
        }

        // Render overview region
        if ( this.individualContainerRegion ) {
            this.showOverviewView(overviewViewLayout);
        }

        this.model = model;
        this.fetchSocial();
        this.createTasksView();
        this.createConversationsView();
        this.createAppointmentsView();

        if(!this.checklistsRegion.currentView){
            this.createChecklistsView();
        }

        this.onWindowResize();

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.initCustomFieldsView();
        }

        this.scrollbar();
    },
    createChecklistsView: function() {
        const self = this

        app.shortTermCache.get(`/individuals/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            var checklists = [];
            var checklistsCollection = new ChecklistsCollection(data);

            for (const checklist of checklistsCollection.models) {
                const clSettings = checklist.get('checklist_settings') || {};

                if (!clSettings.hidden) {
                    checklists.push(checklist);
                }
            }

            if (checklists.length > 0){
                self.ui.activity.addClass('checklist-content');
                self.checklistsRegion.show(new ChecklistsView({
                    model: self.model,
                    checklists: checklists,
                    entityType: 'individual'
                }));
            }
        });
    },
    createAppointmentsView: function() {
        if (!AppConfig.getValue('app_nav_items.appointments.visible')) {
            return;
        }

        this.ui.appointmentsTab.removeClass('hide');

        const appointmentsView = new AppointmentsView({
            model: this.model,
            type: 'individual',
            badge: this.$el.find('.appointments-badge')
        });

        this.appointmentsRegion.show(appointmentsView);
    },
    createConversationsView: function() {
        const self = this;

        AppConfig.getValue('conversations.enable_for_individual', false, {
            model: this.model,
            callback: function(enabled) {
                if (enabled) {
                    self.ui.conversationsTab.removeClass('hide');

                    const conversationsView = new ConversationsView({
                        model: self.model,
                        type: 'individual',
                        badge: self.$el.find('.messages-badge'),
                        conversationId: self.options.conversation_id
                    });

                    self.conversationsRegion.show(conversationsView);

                    if (self.options.conversation_id) {
                        _.defer(function() {
                            self.$el.find('#conversations').click();
                        });
                    }
                }
            }
        });
    },
    createTasksView: function() {
        const tasksView = new TasksView({
            related: this.model.toJSON()
        });

        this.taskFeedRegion.show(tasksView);

        const self = this;

        this.listenTo(tasksView, 'task:count:change', function(count) {
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }

        /*
        this.taskContainer = new TasksView.SingleTaskListContainer({
            filterModel: new Backbone.Model({
                where: {
                    'related_type': 'individual',
                    'related_id': this.model.get('id')
                }
            }),
            defaultAssigneeSelf: true,
            itemTitle: this.model.get('full_name'),
            entityType: 'individual',
            entityModel: this.model,
            displayFilters: true
        });

        var self = this;

        this.taskFeedRegion.show(this.taskContainer);
        this.listenTo(this.taskContainer, 'task:count:change', function(count) {
            // Targets all instances of '.task-counter'
            self.$el.find('.task-counter').text(count || '');
        });

        if (AppConfig.getValue('displayTasksTabByDefault')) {
            _.defer(function() {
                self.$el.find('#tasks').click();
            });
        }
        */
    },
    fetchSocial: function() {
        var socialUrl = this.model.url() + '/social';

        this.onSocialFeed(new (SocialItemsCollection.extend({
            urlRoot: function() {
                return socialUrl;
            }
        }))());

        this.onActivityFeed(this.model);
    },
    onShow: function() {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
            this.$el.parent('.region').addClass('non-condensed-detail-region');
            $('#app-stacks').addClass('non-condensed-app-stacks');
        }
    },
    /**
     * Used only with the React OverviewView to switch to the Marionette-
     * -based view for editing
     */
    replaceOverviewViewForEdit: function() {
        var overviewView = new OverviewView({
            controller: this.controller,
            parent: this,
            model: this.model,
            leadSources: this.leadSources
        });
        this.individualContainerRegion.show(overviewView);
        // customFieldsRegion is in the child overviewView (i know…)
        // we've recreated the overviewView so need to reset customFieldsRegion
        // so its host element is queried from the DOM again
        this.customFieldsRegion.reset();
        this.initCustomFieldsView();
        this.controller.startEditing();
        this.scrollbar();
    },
    onActivityFeed: function(model) {
        if ( this.activityFeedRegion ) {
            this.activityFeedRegion.show(new ActivityFeedView({
                parent: this,
                model: model
            }));
        }

        this.onWindowResize();
    },
    onSocialFeed: function(collection) {
        if ( this.socialFeedRegion ) {
            this.socialFeedRegion.show(createSocialList(
                this.options.parent,
                collection
            ));
        }

        this.onWindowResize();
    },
    showTab: function(tab) {
        var tabId = tab.attr('id');

        switch(tabId) {
            case 'notes':
                this.activityFeedRegion.currentView.fetchActivity();
                break;

            case 'tasks':
                this.taskFeedRegion.currentView.show();
                break;

            case 'social':
                this.fetchSocial();
                break;

            case 'appointments':
                this.appointmentsRegion.currentView.onActive();
                break;
        }

        if (!tab.parent().hasClass('active')) {
            tab.tab('show'); // Bootstrap tabs
            vent.trigger('individualTabs:change', tabId);
            this.scrollbar();
        }
    },
    goToOrganization: function(ev) {
        var orgShortId;

        if (ev) {
            ev.preventDefault();
        }

        orgShortId = this.model.get('organization_short_id') || this.model.get('organization_id');

        this.trigger('replace-view:organization:show', {
            id: orgShortId
        });
    },
    closeView: function(event) {
        if (event) {
            event.preventDefault();
        }
        app.dirtyModelHandler.confirm(this, function() {
            this.trigger('view:close', this);
        });
    },
    getUrl: function() {
        var id = this.model.get('short_id') || this.model.id;

        return 'individuals/' + (id ? id : 'new');
    },
    getParams: function() {
        var params = {};
        if (this.controller.isEditing()) {
            params.edit = true;
        }

        return params;
    },
    initCustomFieldsView: function() {
        var view = this;
        var customFields = new CustomFieldsCollection();

        customFields.fetch({
            filterBy: [{
                attribute: 'view',
                value: 'individuals'
            }],
            success: function() {
                if (view.isClosed) {
                    return;
                }

                var customFieldsView = new CustomFieldsView();

                customFieldsView.onDataLoaded({
                    model: view.model,
                    collectionDefines: customFields,
                    editing: view.controller.editing
                });

                view.listenTo(customFieldsView, 'editing:valueChanged', view.manageCustomFields);

                view.customFieldsRegion.show(customFieldsView);
                view.scrollbar();
            }
        });
    },
    scrollbar: _.debounce(function() {
        if (!this.closed) {
            this.$el.nanoScroller();

            if (!this.individualContainerRegion || !this.individualContainerRegion.$el) {
                // nothing to do
                return;
            }

            if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002') &&
                this.individualContainerRegion.currentView &&
                this.individualContainerRegion.currentView.constructor === IndividualContainerView) {

                // React view in use - don't init nanoScroller
                return;
            }

            this.individualContainerRegion.$el.nanoScroller();
        }
    }, 500)

    /*
    events: {
        'click nav .tabs a': 'scrollBar'
    },
    */
});

var TagItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tag',
    template: Handlebars.compile('{{name}}'),
});

/*
 * The main area of the detail page, with the header, communications,
 * locations, comments, related deals...
 */
OverviewView = Marionette.Layout.extend({
    className: 'overview content',
    template: Handlebars.compile(individualOverviewTemplate),
    ui: {
        favoriteStar: '.toggle-favorite',
        display_name: '.name-container .display-field',
        displayRole: '.role-container .display-field',
        display_owner: '.detail-owner .display-field',
        display_organization: '.organization-link',
        display_image_mask: '.profile-image-mask',
        display_photo: '.profile-image',
        display_comments: '.more-info',
        display_source: '.source.display-field',
        edit_first_name: '.field-first-name',
        edit_last_name: '.field-last-name',
        editRole: '.field-role',
        edit_organization: '.field-organization',
        edit_photo_url: '.photo-url',
        edit_comments: '.field-more-info',
        edit_source: '.field-source',
        edit_twitter: '.twitter-handle',
        editTags: '.field-tags',
        error_messages: '.error-message',
        fullname_warning_message: '.fullname-warning-message',
        select2_sources: '.field-source-container',
        // comments_container: '.more-info-container',
        source_container: '.source-container',
        edit_owner: '.field-owner',
        header: '.detail-header',
        tooltips: '[data-toggle=tooltip]',
        docusignContainer: '.docusign-container'
    },
    events: {
        'click .delete-button': function() {
            app.dirtyModelHandler.confirm(this, function() {
                var self = this,
                    mbContent = {
                        accept_is_negative: true,
                        message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({name: this.model.toString()}),
                        icon: 'icon-trashcan'
                    };
                MessageBox.showYesNo(mbContent, this, function () {
                    self.controller.destroyModel();
                    vent.trigger('model:delete');
                });
            });
        },
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .add-to-favorites': 'addToFavorites',
        'click .remove-from-favorites': 'removeFromFavorites',
        'change .field-first-name': 'change_first_name',
        'change .field-last-name': 'change_last_name',
        'change .field-role': function() {
            this.controller.addChange('role', this.ui.editRole.val());
        },
        'keyup .field-first-name': function(ev) {
            var first = $(ev.target).val().trim();
            var last  = this.ui.edit_last_name.val().trim();
            this.individualsSearcher(first + ' ' + last);
        },
        'keyup .field-last-name': function(ev) {
            var first = this.ui.edit_first_name.val().trim();
            var last  = $(ev.target).val().trim();
            this.individualsSearcher(first + ' ' + last);
        },
        'paste .field-first-name': function(ev) {
            var first = ev.originalEvent.clipboardData.getData('text/plain').trim();
            var last  = this.ui.edit_last_name.val().trim();
            this.individualsSearcher(first + ' ' + last);
        },
        'paste .field-last-name': function(ev) {
            var first = this.ui.edit_first_name.val().trim();
            var last  = ev.originalEvent.clipboardData.getData('text/plain').trim();
            this.individualsSearcher(first + ' ' + last);
        },
        //'blur .field-organization': 'change_organization',
        //'blur .field-owner': 'change_owner',
        'change .photo-url': 'change_photo_url',
        'change .field-more-info': 'change_comments',
        'change .twitter-handle': 'change_twitter',
        'click .edit-permissions.has-permission': 'showACL',
        'change .field-source': function(ev) {
            var selectVal = $(ev.target).select2('val');
            this.controller.addChange('source', selectVal ? {id: selectVal} : null);
        },
        'click .send-to-docusign': function(ev) {
            ev.preventDefault();

            this.docusignRegion.show(new DocusignPrepareView({
                model: this.model
            }));
        }
    },
    regions: {
        communicationsRegion: '.communications-container',
        locationsRegion: '.locations-container',
        relatedDealsRegion: '.related-deals',
        relatedFilesRegion: '.related-files',
        relatedGroupsRegion: '.related-groups',
        tagsRegion: '.tags-container .display-field',
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        docusignRegion: {
            selector: '.loading-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        }
        // aclRegion: '.acl-region'
    },
    templateHelpers: function () {
        var owner = this.model.get('owner');

        return {
            owner: owner ? { name: owner.name } : null,
            getPhoto: this.model.getPhoto(),
            archiveAddress: 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST')
        };
    },
    initialize: function(options) {
        var view = this;
        this.controller = options.controller;

        this.locationCollection = new LocationsCollection(this.model.get('locations'));

        this.communicationCollection = new CommunicationCollection(this.model.get('communication'));

        this.listenTo(this.model, 'sync', function() {

            // if using the react overview view, the parent will take care
            // of rerendering itself
            if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4002')) {
                return;
            }

            var parent = this.options.parent;
            // for new items re-render parent view
            if (parent.creatingNewItem) {
                parent.creatingNewItem = false;
                parent.editing = false;
                parent.render();
                vent.trigger('AppContent:contentChange');
            }
            else {
                this.onModelSync();
            }
        });

        this.listenTo(this.controller, 'editing:cancel', function() {
            view.error_messages.remove.call(view);
            this.communicationCollection.reset(this.model.get('communication'));
        });

        this.listenTo(this.controller, 'editing:start', function() {
            view.error_messages.unhide.call(view);
            view.ui.edit_first_name.select();
        });

        this.listenTo(this.controller, 'editing:save:after', function() {
            view.error_messages.remove.call(view);
            // reset collection to set id's for newly created locations
            this.locationCollection.reset(this.model.get('locations'));
            this.communicationCollection.reset(this.model.get('communication'));
        });

        this.listenTo(this.controller, 'editing:save:before', function() {
            view.error_messages.remove.call(view);
        });

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.showInvalidFields(errors);
        });

        this.initIndividualsSearcher();
    },
    initIndividualsSearcher: function() {
        var timeout;
        var view = this;

        this.individualsSearcher = function(term) {
            window.clearTimeout(timeout);

            timeout = window.setTimeout(function() {
                if (term) {
                    var url = '/individuals?search=' + term + '&search_pattern=${term}';
                    $.getJSON(url , function(data) {
                        if (!view.isClosed) {
                            // get the info from the last created organization (not including current org)
                            if (!view.model.isNew()) {
                                var mid = view.model.get('id');

                                data = _.filter(data, function(e) {
                                    return e.id !== mid;
                                });
                            }

                            if (data.length > 0) {
                                data = _.sortBy(data, function(e) {
                                    return e.item.created;
                                });

                                var existingInd = data[data.length - 1].item;
                                var href = '#individuals/' + existingInd.id;

                                view.showWarningMessage(view.ui.fullname_warning_message, '<i class="icon-warning" style="margin-right: 5px;"></i>There is at least <a class="link" href="' + href + '">1 ' + TextManager.getText('ID_INDIVIDUAL') + '</a> with this name');
                            }
                            else {
                                view.hideWarningMessage(view.ui.fullname_warning_message);
                            }
                        }
                    });
                }
                else {
                    view.hideWarningMessage(view.ui.fullname_warning_message);
                }
            }, 500);
        };
    },
    showWarningMessage: function(ui, message) {
        ui.html(message);
        ui.show();
    },
    hideWarningMessage: function(ui) {
        ui.hide();
    },
    showInvalidFields: function(errors) {
        if (errors) {
            if (errors.missing_last_name || errors.last_name_too_short) {
                this.ui.edit_last_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_last_name || errors.last_name_too_short)
                    .addClass('invalid');
            }
            if (errors.missing_organization) {
                this.ui.edit_organization.addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_organization)
                    .addClass('invalid');
            }
        }

        this.$el.find('.validation_error:first').focus();
    },
    onRender: function() {
        this.communicationsRegion.show(new CommunicationsListView({
            controller: this.controller,
            collection: this.communicationCollection,
            checkEmailExistenceFor: 'individuals',
            entityModel: this.model,
            controller: this.controller
        }));

        this.locationsRegion.show(new LocationsListView({
            controller: this.controller,
            collection: this.locationCollection
        }));

        this.initTooltips();

        this.initSelect2();

        // is the user connected to docusign?
        var self = this;

        $.get('/integrations?status=authorized', function(data) {
            if (_.findWhere(data, {id: 'docusign'})) {
                self.ui.docusignContainer.removeClass('hide');
            }
        });
    },
    onModelSync: function() {
        var view = this,
            isFavorite = this.model.get('is_favorite') || false,
            firstName = this.model.get('first_name') || '',
            lastName = this.model.get('last_name') || '',
            fullName = firstName + ' ' + lastName,
            owner = this.model.get('owner'),
            owner_name = owner ? owner.name : '',
            org_id = this.model.get('organization_id'),
            org_name = this.model.get('organization_name') || '',
            photo_url = this.model.get('photo_url'),
            comments = this.model.get('comments'),
            organization = view.model.get('organization'),
            source = this.model.get('source');

        this.ui.favoriteStar.toggleClass('favorite', isFavorite);

        this.ui.display_name.text(fullName);
        this.ui.displayRole.text(this.model.get('role') || '');
        this.ui.display_owner.text(owner_name);
        if (photo_url) {
            this.ui.display_photo.css('background-image', 'url(' + photo_url + ')');
        }
        this.ui.display_image_mask.toggleClass('has-photo', !!photo_url);

        if (org_name) {
            this.ui.display_organization.text(org_name).css('visibility', 'visible');
        }
        else {
            this.ui.display_organization.text(org_name).css('visibility', 'hidden');
        }

        this.tagsRegion.show(new Marionette.CollectionView({
            tagName: 'ul',
            itemView: TagItemView,
            collection: new Backbone.Collection(this.model.get('tags'))
        }));

        if (this.tagsSelect) {
            this.tagsSelect.setValue(this.model.get('tags'));
        }

        if (source) {
            this.ui.display_source.text(source.name);
            this.ui.select2_sources.select2('val', source.id);
            // this.ui.edit_source.select2('val', source.id);
        }
        else {
            this.ui.select2_sources.select2('val', null);
        }
        this.ui.display_comments.html((comments ? linkify(comments) : 'No information to display'));

        this.ui.edit_first_name.val(firstName);
        this.ui.edit_last_name.val(lastName);
        this.ownerSelect.setValue({
            name: owner.name,
            id: owner.id
        });
        this.orgSelect.setValue(org_id ? {id: org_id, title: org_name} : null);
        this.ui.edit_photo_url.val(photo_url);

        this.ui.source_container.toggleClass('no-value', !source);

        this.ui.edit_comments.val(comments);
        // this.ui.comments_container.toggleClass('no-value', !comments.length);

        if (organization) {
            organization = new OrganizationModel(view.model.get('organization'));
            var opportunitiesCollection = new (OpportunitiesCollection.extend({
                defaultRows: 20,
                urlRoot: function() {
                    return '/organizations/' + organization.get('id') + '/opportunities';
                }
            }))();
            opportunitiesCollection.fetch({
                success: function() {
                    view.onDeals(opportunitiesCollection);
                }
            });
        }
        else {
            this.onDeals();
        }

        this.relatedGroupsRegion.show(new GroupListContainsView({
            elementType: 'individuals',
            itemId: this.model.get('id')
        }));

        var relatedFiles = new RelatedFilesListView({
            collection: new (RelatedFilesCollection.extend({
                urlRoot: function() {
                    return '/individuals/' + view.model.get('id') + '/related_files';
                }
            })),
            model: this.model,
            elementType: 'individuals'
        });

        this.relatedFilesRegion.show(relatedFiles);

        this.options.parent.scrollbar();
    },
    serializeData: function() {
        var self = this,
            source = this.model.get('source'),
            data = Marionette.Layout.prototype.serializeData.apply(this, arguments),
            // leadSourceNames = [{id: 0, name: 'None'}];
            leadSourceNames = [],
            setUnspecifiedAsDefault = false;

        // if we are creating the lead, None source is not permitted
        if (!this.model.isNew()) {
            leadSourceNames.push({id:null, name: 'None'});
        }
        else if (this.options.parent.options.isLead) { // we set Unspecified as default source on new leads
            setUnspecifiedAsDefault = true;
        }

        _.each(this.options.leadSources.models, function(item) {
            var curSource = {id: item.get('id'), name: item.get('name')};
            leadSourceNames.push(curSource);

            // recreate source with id and name, if only 'system_type' provided
            if ((setUnspecifiedAsDefault && (item.get('system_type') === 'unspecified')) ||
                ((source && !source.id && (source.system_type === item.get('system_type'))))) {
                self.model.set('source', curSource);
                self.controller.addChange('source', { id: curSource.id });
            }
        });
        return _.extend(data, { sources: leadSourceNames });
    },
    onDeals: function(deals) {
        var relatedDealsList,
            organization = this.model.get('organization');

        organization = organization && new OrganizationModel(organization);

        relatedDealsList = createDealsList(
            this.options.parent,
            deals,
            {
                hideCheckbox: false
            },
            null,
            // pass contact model if organization is not set
            organization ? null : this.model,
            organization,
            this.model
        );

        if ( this.relatedDealsRegion ) {
            this.relatedDealsRegion.show(relatedDealsList);
            this.options.parent.scrollbar();
        }
    },
    addToFavorites: function(ev) {
        var model = this.model,
            model_id = model.get('id'),
            user = app.user;

        ev.preventDefault();

        // TODO: We shouldn't be having this logic on a view, should be
        // on the api/models layer
        // TODO: Hardcoded the model url, because for leads we need to use
        // the individuals also, which makes using the models url wrong,
        // however, some refactoring is needed here

        $.ajax({
            url: user.url() + '/favorite_individuals/items/' + model_id,
            dataType: 'json',
            type: 'PUT',
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify({id: model_id}),
            complete: function() {
                model.fetch();
            }
        });
    },
    removeFromFavorites: function(ev) {
        var model = this.model,
            model_id = model.get('id'),
            user = app.user;

        ev.preventDefault();

        $.ajax({
            url: user.url() + '/favorite_individuals/items/' + model_id,
            contentType: "application/json;charset=utf-8",
            type: 'DELETE',
            complete: function() {
                model.fetch();
            }
        });
    },
    change_first_name: function(ev) {
        this.controller.addChange('first_name', $(ev.target).val());
    },
    change_last_name: function(ev) {
        this.controller.addChange('last_name', $(ev.target).val());
    },
    change_organization: function(item) {
        var id = item && item.id,
            name = item && item.title;

        // change organization by id
        if (id) {
            // create ghost model to work with backbone-relational when creating new individual
            if (this.model.isNew()) {
                this.controller.addChange('organization', new OrganizationModel({ id:id }));
                this.controller.removeChange('organization_id');
                this.model.unset('organization_id');
            }
            // for existing individual patch will be used using organization_id only
            else {
                this.controller.addChange('organization_id', id);
                this.controller.removeChange('organization');
                this.model.unset('organization');
            }
        }
        // unset organization with organization_id:null
        else if (name === null || name.length === 0) {
            this.controller.addChange('organization_id', null);
            this.controller.removeChange('organization');
            this.model.unset('organization');
        }
        // change organization by creating new organization
        else {
            this.controller.removeChange('organization_id');
            this.model.unset('organization_id');
            this.controller.addChange('organization', new OrganizationModel({name: name}));
        }
    },
    changeTags: function(items) {
        var ids = _.map(items, function(item) {
            return {id: item.id};
        });

        this.controller.addChange('tags', ids);
    },
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
        this.controller.addChange('owner', {
            id: id,
            name: item && item.name
        });
    },
    change_photo_url: function(ev) {
        this.controller.addChange('photo_url', $(ev.target).val());
    },
    change_comments: function(ev) {
        this.controller.addChange('comments', $(ev.target).val());
    },
    change_twitter: function(ev) {
        this.controller.addChange('twitter_screen_name',
            $(ev.target).val());
    },
    showACL: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    initTooltips: function() {
        // Header tools
        this.ui.header.find('[data-toggle=tooltip]').tooltip({
            placement: 'bottom',
            container: 'body'
        });

        // All remaining tooltips
        this.ui.tooltips.tooltip({
            container: 'body'
        });
    },
    initSelect2: function() {
        var view = this;

        var source = this.model.get('source');
        var sourceSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.select2_sources,
            value: source,
            data: this.options.leadSources.toJSON(),

            options: {
                placeholder: 'Select a lead source',
                allowClear: true,
                containerCssClass: 'editable-field',
                dropdownCssClass: 'source-select-popover popover'
            }
        });
        this.listenTo(sourceSelect, 'change', function(value) {
            view.controller.addChange('source', value);
        });

        // Set default
        if (source) {
            this.ui.select2_sources.select2('val', source.id);
        }
        this.ui.source_container.toggleClass('no-value', !source);

        // ...
        var orgName = this.model.get('organization_name');
        var orgId = this.model.get('organization_id');
        var orgDesc = null;

        if (orgName && orgId) {
            orgDesc = {id: orgId, title: orgName};
        }

        this.orgSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_organization,
            url: '/organizations',
            search: true,
            createSearchChoice: true,
            id: 'title',
            text: 'title',
            value: orgDesc,

            options: {
                allowClear: true,
                placeholder: TextManager.getText('ID_SEARCH_FOR_AN_ORGANIZATION'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });
        this.listenTo(this.orgSelect, 'change', this.change_organization);

        var owner = this.model.get('owner');
        this.ownerSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_owner,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: {
                name: owner.name,
                id: owner.id
            },
            options: {
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.ownerSelect, 'change', this.change_owner);

        _.defer(function() {
            view.tagsSelect = new backboneSelect2.TagView({
                view: view,
                $el: view.ui.editTags,
                value: view.model.get('tags'),
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,

                options: {
                    placeholder: '+ Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });

            view.listenTo(view.tagsSelect, 'change', view.changeTags);
        });

        // Check for admin permissions
        if ( !security.checkPermission('change_ownership', this.model) ) {
            this.ownerSelect.$el.select2('readonly', true);
            this.ownerSelect.$el.select2('container').tooltip({
                title: "You don't have permission to edit owner",
                container: 'body'
            });
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default IndividualView;

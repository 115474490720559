import _ from 'underscore'
import Handlebars from 'handlebars'

import AppConfig from 'app/app-config'
import BaseCollection from 'js/collections/base'
import IndividualModel from 'js/models/contact'
import IndividualListingBaseView from 'app/_components/IOD-listing/IOD/individual-listing/individual-listing-base'
import IndividualRecentModel from 'js/models/individualRecent'
import IndividualRecentCreatedModel from 'js/models/individualRecentCreated'
import app from 'js/app'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'


var BaseOverviewView = IndividualListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: true,
            disableLayoutSelection: true,
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        if (this.options.byCreateDate) {
            this.fetchOptions.group_id = 'created';
        }
        else {
            this.fetchOptions.group_id = 'recents';
        }
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            selectedColumns: this.getSelectedColumns(),
            customFields: this.customFields,
            tableModel: this.model,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                addButton: true
            },
            elementType: this.options.elementType,
            defaultSort: this.options.byCreateDate ? { direction: false, field: ["created"] } : { direction: false, field: ["last_viewed"] }
        });
    },
    getSelectedColumns: function() {
        return this.model.get('columns');
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:individual:show', {model: model});
    }
});

export default IndividualListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);

        if (this.options.byCreateDate) {
            this.model = new IndividualRecentCreatedModel();
        }
        else {
            this.model = new IndividualRecentModel();
        }
    },
    onRender: function() {
        var view = this;

        this.model.fetch({
            success: function() {
                view.showOverviewView();
            }
        });
    },
    getElementType: function() {
        return 'individuals';
    },
    getUrl: function() {
        if (this.options.byCreateDate) {
            return 'recentCreatedIndividuals';
        }
        else {
            return 'recentIndividuals';
        }
    }
});

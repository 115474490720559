import $ from 'jquery'
import _ from 'underscore'
import * as libPhoneNumber from 'libphonenumber-js'

import app from 'js/app'

var unicodeToGsmMapping = {
    0x00AB: '"',
    0x00AD: '-',
    0x00B4: "'",
    0x00BB: '"',
    0x00F7: '/',
    0x01C0: '|',
    0x01C3: '!',
    0x02B9: "'",
    0x02BA: '"',
    0x02BC: "'",
    0x02C4: '^',
    0x02C6: '^',
    0x02C8: "'",
    0x02CB: '`',
    0x02CD: '_',
    0x02DC: '~',
    0x2002: ' ',
    0x3000: '`',
    0x0301: "'",
    0x0302: '^',
    0x0303: '~',
    0x030B: '"',
    0x030E: '"',
    0x0331: '_',
    0x0332: '_',
    0x0338: '/',
    0x0589: ':',
    0x05C0: '|',
    0x05C3: ':',
    0x066A: '%',
    0x066D: '*',
    0x200B: ' ',
    0x2010: '-',
    0x2011: '-',
    0x2012: '-',
    0x2013: '-',
    0x2014: '-',
    0x2015: '--',
    0x2016: '||',
    0x2017: '_',
    0x2018: "'",
    0x2019: "'",
    0x201A: ',',
    0x201B: "'",
    0x201C: '"',
    0x201D: '"',
    0x201E: '"',
    0x201F: '"',
    0x2032: "'",
    0x2033: '"',
    0x2034: "'",
    0x2035: '`',
    0x2036: '"',
    0x2037: "'",
    0x2038: '^',
    0x2039: '<',
    0x203A: '>',
    0x203D: '?',
    0x2044: '/',
    0x204E: '*',
    0x2052: '%',
    0x2053: '~',
    0x2060: ' ',
    0x20E5: '\\',
    0x2212: '-',
    0x2215: '/',
    0x2216: '\/',
    0x2217: '*',
    0x2223: '|',
    0x2236: ':',
    0x223C: '~',
    0x2264: '<=',
    0x2265: '>=',
    0x2266: '<=',
    0x2267: '>=',
    0x2303: '^',
    0x2329: '<',
    0x232A: '>',
    0x266F: '#',
    0x2731: '*',
    0x2758: '|',
    0x2762: '!',
    0x27E6: '[',
    0x27E8: '<',
    0x27E9: '>',
    0x2983: '{',
    0x2984: '}',
    0x3003: '"',
    0x3008: '<',
    0x3009: '>',
    0x301B: ']',
    0x301C: '~',
    0x301D: '"',
    0x301E: '"',
    0xFEFF: ' '
};

const PHONE_NUMBER_ERRORS = {
    INVALID_COUNTRY: 'Unknown country code',
    NOT_A_NUMBER: 'Invalid phone number',
    TOO_LONG: 'Phone number too long',
    TOO_SHORT: 'Phone number too short',
};

var utilities = {
    isHTML: function(text) {
        return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(text);
    },

    isASCII: function(str) {
        return /^[\x00-\x7F]*$/.test(str);
    },

    getTextFromHTMLContent(content) {
        try {
            var newContent = content;
            var el = $('<div/>');

            el.append(newContent).ready(function() {
                // remove all event handlers
                for (const e of el.find('*')) {
                    // el.find('*').off('onerror');
                    for (const key in e) {
                        if (key.indexOf('on') === 0 && _.isFunction(e[key])) {
                            e[key] = '';
                        }
                    }
                }
            });

            newContent = el.text().trim();

            // remove comments
            var startCommentTag = '<!--';
            var endCommentTag = '-->';

            var startCommentIdx = newContent.indexOf(startCommentTag);

            while (startCommentIdx !== -1) {
                var endCommentIdx = newContent.indexOf(endCommentTag, startCommentIdx + startCommentTag.length);

                if (endCommentIdx === -1) {
                    break;
                }

                newContent = newContent.substring(0, startCommentIdx) + newContent.substring(endCommentIdx + endCommentTag.length, newContent.length);

                startCommentIdx = newContent.indexOf(startCommentTag);
            }

            return newContent;
        } catch(e) {
            return content;
        }
    },

    numberWithCommas: function(number) {
        if ((number !== null) && !_.isUndefined(number)) {
            var parts = number.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }

        return '';
    },

    dateIsValid: function(date) {
        return _.isDate(date) && !_.isNaN(date.getTime());
    },

    replaceAll: function(text, searchValue, newValue, ignoreCase) {
        return text.replace(new RegExp(searchValue.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignoreCase?"gi":"g")),(typeof(newValue)=="string")?newValue.replace(/\$/g,"$$$$"):newValue);
    },

    /**
    * returns the first index where the predicate truth test passes;
    * otherwise returns -1.
    * @param array      list
    * @param predicate  function
    * @returns {int} index
    */
    findIndex: function(array, predicate) {
        if (!_.isArray(array)) {
            return -1;
        }

        for (var i = 0; i < array.length; ++i) {
            if (predicate(array[i])) {
                return i;
            }
        }

        return -1;
    },

    /**
     * Adds 'http://' to URL if protocol is missing.
     *
     * @param: string    url to validate
     * @returns {string} updated url
     */
    httpUrl: function(url) {
        if (url && !url.match(/^[a-zA-Z]+:\/\//)) {
            return 'http://' + url;
        }

        return url;
    },

    /**
     * returns the icon type based on file extension
     *
     * @param: string    file extension (including dot)
     * @returns {object} dictionary with icon type and file type
     */
    getTypeIcon: function(fileExt) {
        var ext = fileExt ? fileExt.toLowerCase() : '',
            icon = 'icon-file',
            type = 'unknown-file';

        // Supporting popular file types
        // Data from: http://www.fileinfo.com/
        if( ['.log', '.msg', '.pages', '.rtf', '.rtx', '.txt', '.wpd'].indexOf(ext) !== -1) {
            // TODO: create text file icon - 'icon-text'
            icon = 'icon-file';
            type = 'text-file';
        }
        else if( ['.doc', '.docx', '.dot', '.dotx', '.wps'].indexOf(ext) !== -1) {
            icon = 'icon-file-word';
            type = 'word-file';
        }
        else if( ['.ods', '.odt', '.ott', '.sxw', '.stw'].indexOf(ext) !== -1) {
            icon = 'icon-file-openoffice';
            type = 'openoffice-file';
        }
        else if( ['.gsheet', '.numbers', '.xlr', '.xls', '.xlsx'].indexOf(ext) !== -1) {
            icon = 'icon-file-excel';
            type = 'excel-file';
        }
        else if( ['.key', '.pps', '.ppt', '.pptx'].indexOf(ext) !== -1) {
            icon = 'icon-file-powerpoint';
            type = 'powerpoint-file';
        }
        else if( ['.pdf'].indexOf(ext) !== -1) {
            icon = 'icon-file-pdf';
            type = 'pdf-file';
        }
        else if( ['.bmp', '.gif', '.jpg', '.jpe', '.jpeg', '.png', '.svg', '.tif', '.tiff'].indexOf(ext) !== -1) {
            icon = 'icon-image';
            type = 'image-file';
        }
        else if( ['.psd', '.psb'].indexOf(ext) !== -1) {
            icon = 'icon-photoshop';
            type = 'photoshop-file';
        }
        else if( ['.ai', '.ait'].indexOf(ext) !== -1) {
            icon = 'icon-illustrator';
            type = 'illustrator-file';
        }
        else if( ['.3g2', '.3gp', '.avi', '.flv', '.m4v', '.mov', '.mp4','.mpg', '.rm', '.swf', '.vob', '.wmv']
            .indexOf(ext) !== -1) {
            icon = 'icon-film';
            type = 'video-file';
        }
        else if( ['.asp', '.aspx', '.htm', '.html', '.js', '.php', '.rss', '.xhtml'].indexOf(ext) !== -1) {
            icon = 'icon-file-xml';
            type = 'web-file';
        }
        else if( ['.css', '.less'].indexOf(ext) !== -1) {
            icon = 'icon-file-css';
            type = 'css-file';
        }
        else if( ['.7z', '.gz', '.pkg', '.rar', '.rpm', '.sitx', '.tar.gz', '.zip', '.zipx'].indexOf(ext) !== -1) {
            icon = 'icon-file-zip';
            type = 'compressed-file';
        }

        return {
            icon: icon,
            type: type
        };
    },

    /**
     * Smart position menu against clicked item. Scrolls when clicked item not enough visible.
     *
     * @param menu              object  menu object
     * @param target            object  clicked object menu should be aligned to
     */
    positionMenu: function(menu, target) {
        var menuBB = menu[0].getBoundingClientRect();
        var arrow = menu.find('.arrow');
        var arrowBB = menu.find('.arrow')[arrow.length > 1 ? 1 : 0].getBoundingClientRect();
        var targetBB = target[0].getBoundingClientRect();

        var top = targetBB.top - ((arrowBB.top + arrowBB.height / 2) - menuBB.top) + (targetBB.height / 2);
        var left = targetBB.left + targetBB.width + arrowBB.width;

        menu.css({
            'position': 'fixed',
            'margin-top': '0px',
            top: `${top}px`,
            'margin-left': '0px',
            left: `${left}px`
        });
    },

    addLeadingZeros: function(value, length) {
        var zeros = '';
        var mul = Math.pow(10, length - 1);

        for (var i = 0; i < length - 1; ++i) {
            if (value < mul) {
                zeros += '0';
            }
            mul /= 10;
        }

        return zeros + value;
    },

    setCookie: function(name, value, exDays) {
        var expirationValue = 100 * 365; // never by default

        if (exDays) {
            var d = new Date();
            d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
            expirationValue = d.toUTCString();
        }

        var expires = "expires=" + expirationValue;
        document.cookie = name + '=' + value + ';' + expires + ';path=/';
    },

    deleteCookie: function(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    },

    getCookie: function(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    },

    convert12hTo24hClock: function(hours, am) {
        if (_.isString(hours)) {
            hours = parseInt(hours);
        }

        if (hours === 12) {
            hours = 0;
        }

        if (!am) {
            hours += 12;
        }

        return hours;
    },

    convert24hTo12hClock: function(hours) {
        if (_.isString(hours)) {
            hours = parseInt(hours);
        }

        return {
            hours: (hours % 12) || 12,
            am: hours < 12
        };
    },

    getNormalizedPhoneNumber: function(phone) {
        var number = '';

        for (var i = 0; i < phone.trim().length; ++i) {
            var c = phone[i];

            if (((c >= '0') && (c <= '9')) || (i === 0 && c === '+')) {
                number += c;
            }
        }

        if (number[0] === '+') {
            return number;
        }

        var clientPreferences = app.user.get('client').preferences || {};
        var defaultSmsPhonePrefix = clientPreferences.default_sms_phone_prefix;

        if (number[0] === '0') {
            if (!defaultSmsPhonePrefix) {
                return '';
            }

            return defaultSmsPhonePrefix + number.substring(1);
        }

        if (defaultSmsPhonePrefix) {
            return defaultSmsPhonePrefix + phone;
        }

        // try to guess the country code
        if (number.length === 10) {  // it can be an american number
            return '+1' + number;
        }

        if (number.length === 11 && number[0] === '1') {  // it can be an american phone number without the +
            return '+' + number;
        }

        return '';
    },

    getCustomFieldProductPrice: function(customField, quantity) {
        var data = customField.get ? customField.toJSON() : customField;

        if (data.type !== 'product') {
            return 0;
        }

        var params = data.params;
        quantity = parseInt(quantity);

        if (_.isArray(params.unit_price)) {
            const values = _.sortBy(params.unit_price, 'min_quantity').reverse();

            for (var i = 0; i < values.length; ++i) {
                if (values[i].min_quantity <= quantity) {
                    return parseFloat(values[i].price);
                }
            }

            return 0;
        }

        return parseFloat(params.unit_price);
    },

    unicodeToGsm(unicodeChar) {
        if (unicodeChar in unicodeToGsmMapping) {
            return unicodeToGsmMapping[unicodeChar];
        }

        return null;
    },

    getPhoneNumberError(error) {
        if (error instanceof libPhoneNumber.ParseError && PHONE_NUMBER_ERRORS[error.message]) {
            return PHONE_NUMBER_ERRORS[error.message];
        }
        return 'Invalid phone number';
    },

    rgb2hex(rgb) {
        if (rgb.indexOf('rgb') === 0) {
            return '#' + rgb.match(/[0-9|.]+/g).map((x,i) => i === 3 ? parseInt(255 * parseFloat(x)).toString(16).padStart(2, '0') : parseInt(x).toString(16).padStart(2, '0')).join('');
        }

        return rgb;
    },

    isValidHexColor(hex) {
        // test 6 and 3 digits hex colors
        return /^#[0-9A-F]{6}$/i.test(hex) || /^#([0-9A-F]{3}){1,2}$/i.test(hex);
    },

    pointInBoundingBox(x, y, bb) {
        if (x < bb.left) {
            return false;
        }

        if (x > bb.right) {
            return false;
        }

        if (y < bb.top) {
            return false;
        }

        if (y > bb.bottom) {
            return false;
        }

        return true;
    },

    pointInRect(x, y, rx, ry, rw, rh) {
        if (x < rx) {
            return false;
        }

        if (x > (rx + rw)) {
            return false;
        }

        if (y < ry) {
            return false;
        }

        if (y > (ry + rh)) {
            return false;
        }

        return true;
    },

    rectsIntersect(x1, y1, w1, h1, x2, y2, w2, h2) {
        if (x1 > (x2 + w2)) {
            return false;
        }

        if (y1 > (y2 + h2)) {
            return false;
        }

        if ((x1 + w1) < x2) {
            return false;
        }

        if ((y1 + h1) < y2) {
            return false;
        }

        return true;
    },

    clamp: function(val, min, max) {
        return Math.min(Math.max(val, min), max);
    },

    capitalize(text) {
        return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },

    getUrlFileExtension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
};

export default utilities;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import defaultPermissionTemplate from 'templates/settings/default_permission.handlebars'
import defaultPermissionListTemplate from 'templates/settings/default_permission_list.handlebars'


var ListView =  Marionette.ItemView.extend({
    tagName: 'ul',
    className: 'default-permissions-list editable-list',
    template: Handlebars.compile(defaultPermissionListTemplate),
    ui: {

    },
    events: {
        'click .change-permission': function(ev) {
            var el = $(ev.target);
            this.options.list[el.attr('key')] = el.val();
            $.ajax({
                url: '/permission_defaults',
                contentType: 'application/json',
                dataType: 'json',
                type: 'PATCH',
                data: JSON.stringify(this.options.list)
            });
        }
    },
    templateHelpers: function() {
        var permissionList = [];
        var renameDict = {
            deals: TextManager.parseText('${ID_DEAL, plural, capitalize}'),
            individuals: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}')
        };

        _.each(this.options.list, function(val, key) {
            var name = (renameDict[key] || key).replace('_', ' ');
            var el = {
                key: key,
                name: name,
                inheritFromFunnelAvailable: AppConfig.getValue(`permission_defaults.${key}.enable_inherit_from_funnel`, false),
                inheritFromFunnelTitle: AppConfig.getValue(`permission_defaults.${key}.inherit_from_funnel_title`, 'Inherit from Funnel')
            };
            el[val] = true;
            permissionList.push(el);
        });
        return { list: permissionList };
    },
    onRender: function() {

    }
});

export default Marionette.Layout.extend({
    className: 'default-permissions',
    template: Handlebars.compile(defaultPermissionTemplate),
    regions: {
        listContainer: '.default-permissions-list-container'
    },
    onRender: function() {
        var self = this;
        $.ajax({
            url: '/permission_defaults',
            success: function(data) {
                self.listContainer.show(new ListView({list: data}));
                self.$el.find('.content-container').nanoScroller();
            }
        });
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import dateFormat from 'js/utils/date-format'
import Utilities from 'js/utils/utilities'
import AppConfig from 'app/app-config';
import TextManager from 'app/text-manager'
import AttachmentsView from 'js/views/activity/attachments-viewer'
import htmlSanitizer from 'js/utils/html-sanitizer'
import emailViewerTemplate from 'templates/activity/email-viewer.handlebars'


var emailItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'email',
    template: Handlebars.compile('<a class="link" href="mailto:{{email_address}}?bcc={{email_archive}}">{{email_address}}</a>'),
    templateHelpers: function() {
        return {
            'email_address': this.model.get('email_address'),
            'email_archive': 'archive@' + app.user.get('client')['short_id'] + '.' + TextManager.getText('ID_HOST')
        }
    }
});

var contactRecipientItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile('<a href="/#individuals/{{id}}" class="link"><span class="display-to">{{name}}</span><i class="icon-angle-right nav-icon"></i></a>'),
    onRender: function() {
        var view = this;

        this.$el.find('a').tooltip({
            title: view.model.get('email_address'),
            container: view.model.get('parent')
        });
    }
});


var userRecipientItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile('<span class="display-to">{{name}}</span>'),
    onRender: function() {
        var view = this;

        this.$el.find('a').tooltip({
            title: view.model.get('email_address'),
            container: view.model.get('parent')
        });
    }
});

var itemListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'comma-separated-list',
    getItemView: function(model) {
        if ( model.get('type') === 'email_address' ) {
            return emailItemView;
        }
        else if (model.get('is_user')) {
            return userRecipientItemView;
        }
        else {
            return contactRecipientItemView;
        }
    }
});

export default Marionette.Layout.extend({
    template: Handlebars.compile(emailViewerTemplate),
    templateHelpers: function() {
        var params = this.model.get('params');
        var createdFromAutomation = params.created_from_automation === 'true' ? true : false;
        var toEmails = [];
        var ccEmails = [];

        if (params.to_emails) {
            if (_.isArray(params.to_emails)) {
                toEmails = params.to_emails;
            } else {
                toEmails = [params.to_emails];
            }
        }

        if (params.cc_emails) {
            if (_.isArray(params.cc_emails)) {
                ccEmails = params.cc_emails;
            } else {
                ccEmails = [params.cc_emails];
            }
        }

        var emails = toEmails.concat(ccEmails);

        var title = 'Email';
        if (createdFromAutomation) {
            title = 'Automation';
        }

        return {
            displayEmailButton: emails.length > 0,
            title: title,
            displayEmailsSection: !createdFromAutomation
        };
    },
    className: 'email-viewer scrolling-modal-inner',
    events: {
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('email-viewer:close');
        },
        'click .to-show-more': function(ev) {
            ev.preventDefault();
            var params = this.model.get('params');
            this.prepareList(this.ui.to_show_more, this.toListRegion, params.to_emails, params.to_ids, params.to_names, true);
        },
        'click .cc-show-more': function(ev) {
            ev.preventDefault();
            var params = this.model.get('params');
            this.prepareList(this.ui.cc_show_more, this.ccListRegion, params.cc_emails, params.cc_ids, params.cc_names, true);
        },
        'click .email-button': function() {
            var params = this.model.get('params');
            var toEmails = (params.to_emails || []).filter(e => e);
            var ccEmails = (params.cc_emails || []).filter(e => e);
            var href = 'mailto:';
            var joinChar = '?';

            if (toEmails.length > 0) {
                href += toEmails.join(',');

                if (ccEmails.length > 0) {
                    href += `?cc=${ccEmails.join(',')}`;
                    joinChar = '&';
                }
            } else {
                href += ccEmails.join(',');
            }

            if (!AppConfig.getValue('disableArchiveBCCLink', false)) {
                var archiveEmail = 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
                href += `${joinChar}bcc=${archiveEmail}`;
                joinChar = '&';
            }

            var emailContent = this.model.get('note');

            if (Utilities.isHTML(emailContent)) {
                emailContent = Utilities.getTextFromHTMLContent(emailContent);
            }

            href += `${joinChar}body=${encodeURIComponent(emailContent)}`;

            window.open(href);
        },
        'resize': 'scrollbar'
    },
    regions: {
        toListRegion: '.to-list',
        ccListRegion: '.cc-list',
        attachmentsRegion: '.attachments-container'
    },
    ui: {
        header: '.modal-header',
        display_from: '.display-from',
        display_subject: '.display-subject',
        display_date: '.display-date',
        email_body_plain: '.email-body-plain',
        email_body_html: '.email-body-html',
        scroll: '.content-container > .content',
        to_text: '.to',
        cc_text: '.cc',
        to_show_more: '.to-show-more-text',
        cc_show_more: '.cc-show-more-text'
    },
    initialize: function(options) {
        this.options = options;
    },
    onShow: function() {
        this.$el.parent().addClass('email-viewer-modal scrolling-modal');

        var params = _.clone(this.options.model.get('params'));
        var date = dateFormat.shortFormatWithYear(new Date(this.options.model.get('created')));

        var contact_name = '';
        var from_contact = params.from_contact === 'true';
        var isHillGroupEmail = params.email_id !== '';

        if (isHillGroupEmail) {
            contact_name = from_contact ? (this.model.get('individual_name') || this.model.get('organization_name')) : params.from_email;
            params.to_emails = [params.to_emails];
            params.to_ids = [null];
            params.to_names = params.to_emails;
            params.cc_emails = [params.cc_emails];
            params.cc_ids = [null];
            params.cc_names = params.cc_emails;
        } else if (params.created_from_data_capture === 'true' || params.created_from_loan_capture === 'true') {
            contact_name = params.from_name + ' <' + params.from_email + '>';
        } else if (params.from_name) {
            contact_name = params.from_name;
        } else {
            contact_name = from_contact ? (this.model.get('individual_name') || this.model.get('organization_name')) : this.options.model.get('creator')['name'];
        }

        this.ui.display_from.text(contact_name);
        this.ui.display_subject.text(params.subject || '(no subject)');
        this.ui.display_subject.toggleClass('placeholder', !params.subject);

        this.ui.display_date.text(date);

        var emailContent = this.options.model.get('note');
        var isHTML = Utilities.isHTML(emailContent);
        var emailBody = isHTML ? this.ui.email_body_html : this.ui.email_body_plain;

        emailBody.removeClass('hide');

        if (isHTML) {
            emailBody.contents().find('html').html(htmlSanitizer.removeScripts(emailContent));

            var createdFromAutomation = params.created_from_automation === 'true' ? true : false;
            if (createdFromAutomation) {
                emailBody.contents().find('html').css('font-family', '"proxima-nova","Helvetica","Arial",sans-serif')
            }

            var self = this;
            var time = 500;
            var adjustSize = function() {
                setTimeout(function() {
                    if (self.isClosed || !emailBody[0]?.contentWindow?.document) {
                        return;
                    }

                    if (
                        (emailBody[0].scrollHeight !== emailBody[0].contentWindow.document.documentElement.scrollHeight) ||
                        (emailBody[0].scrollWidth !== emailBody[0].contentWindow.document.documentElement.scrollWidth)
                    ) {
                        emailBody.css('height', emailBody[0].contentWindow.document.documentElement.scrollHeight + 'px');
                        emailBody.css('width', emailBody[0].contentWindow.document.documentElement.scrollWidth + 'px');
                        self.scrollbar();
                        time = 1000;
                    }

                    adjustSize();
                }, time);
            }

            adjustSize();
        } else {
            emailBody.text(emailContent);
        }

        if (!this.prepareList(this.ui.to_show_more, this.toListRegion, params.to_emails, params.to_ids, params.to_names, false, from_contact)) {
            this.ui.to_text.hide();
        }

        if (!this.prepareList(this.ui.cc_show_more, this.ccListRegion, params.cc_emails, params.cc_ids, params.cc_names, false, from_contact)) {
            this.ui.cc_text.hide();
        }

        this.prepareAttachments();

        // Update scrollbar
        this.scrollbar();
        // Bind scrolling for header and footer shadows
        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);

        $(window).on('resize', this.resizeCallback);
    },
    prepareAttachments : function() {
        var params = this.options.model.get('params');

        if (params.attachments_ids) {
            var collection = new Backbone.Collection();

            for (var i = 0; i < params.attachments_ids.length; ++i) {
                collection.add(new Backbone.Model({
                    id: params.attachments_ids[i],
                    name: params.attachments_names[i]
                }));
            }

            var attachView = new AttachmentsView({ collection: collection });
            var view = this;

            this.listenTo(attachView, 'attachments:change-visualization', function() {
                view.scrollbar();
            });

            this.attachmentsRegion.show(attachView);
        }
    },
    prepareList: function(ui, region, emails, ids, names, include_all, items_are_users) {
        if (emails) {
            var collection = new Backbone.Collection();
            var emails_to_show = include_all ? emails.length : Math.min(emails.length, 3);
            var emails_hidden = emails.length - emails_to_show;

            for (var i = 0; i < emails_to_show; ++i) {
                var model = new Backbone.Model({
                    id: ids[i],
                    email_address: emails[i],
                    name: names[i],
                    is_user: items_are_users,
                    parent: this.$el,
                    type: ids[i] === null ? 'email_address' : undefined
                });

                collection.add(model);
            }

            if (emails_hidden > 0) {
                ui.text('show ' + emails_hidden + ' more...');
            }
            else {
                ui.text('');
            }

            region.show(new itemListView({
                collection: collection
            }));

            return true;
        }

        return false;
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.floor(this.$el.height() - this.ui.header.height()),
            contentHeight = Math.ceil(container.find('.content-inner:first').outerHeight(true)),
            height = Math.min(availableHeight, contentHeight);

        // NOTE: Height needs to be hard-coded in CSS until we can update the lists without just rerendering the
        // whole view - which causes flickering before list is given height below...
        container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

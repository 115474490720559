import jszip from 'jszip';

import AppConfig from 'app/app-config';
import MessageBox from 'js/views/message_box';
import ContentFolderModel from 'js/models/content_folder';
import Utilities from 'js/utils/utilities';


const THIRD_PARTY_MAIL_MAPPED_TAGS = [
    {
      mcTag: ["{{ contact.FIRSTNAME }}", "%FIRSTNAME%", "{{ fname }}", "[firstname,fallback=]", "*|FNAME|*"],
      ssTag: '${individual.first_name}'
    },
    {
      mcTag: ["{{ contact.LASTNAME }}", "%LASTNAME%", "{{ lname }}", "[lastname,fallback=]", "*|LNAME|*"],
      ssTag: '${individual.last_name}'
    },
    {
      mcTag: ["%EMAIL%", "[email]", "{{ email }}", "*|EMAIL|*"],
      ssTag: '${individual.email}'
    },
    {
      mcTag: ['*|PHONE|*'],
      ssTag: '${individual.phone}'
    },
    {
      mcTag: ["%UNSUBSCRIBELINK%", "[unsubscribe]", "{{ unsub_url }}", "*|UNSUB|*"],
      ssTag: '${unsubscribe-url}'
    },
    {
      mcTag: ['*|UPDATE_PROFILE|*'],
      ssTag: '${unsubscribe-url}'
    },
    {
      mcTag: ['*|USER:COMPANY|*'],
      ssTag: '${individual.organization.name,default=company}'
    },
    {
      mcTag: ["%WEBCOPY%", "[websersion]", "*|ARCHIVE|*"],
      ssTag: '${vib-url}'
    },
    {
      mcTag: ['*|MC:SUBJECT|*'],
      ssTag: ''
    },
    {
      mcTag: ['*|MC_PREVIEW_TEXT|*'],
      ssTag: ''
    },
    {
      mcTag: ['<!--*|IF:MC_PREVIEW_TEXT|*-->'],
      ssTag: ''
    },
    {
      mcTag: ['<!--*|END:IF|*-->'],
      ssTag: ''
    }
];

export default class {
    constructor(parent) {
        this.parent = parent;
    }

    import(file, campaignName, callback) {
        if (['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip'].indexOf(file.type) === -1) {
            MessageBox.showOk({
                message: 'Import failed<br>This is not a valid ZIP file',
                icon: 'icon-warning'
            }, this.parent);

            callback(null);
            return;
        }

        const mbProgressContent = {
            icon: 'icon-info',
            message: "Importing files..." +
                "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                "<div class='mb-wait-cont'>0% complete</div>"
        };

        const handle = MessageBox.showNoBtn(
            mbProgressContent,
            this.parent,
            {
                staticRegion: true
            }
        );

        let htmlContent = null;
        let imageFiles = [];
        let folderId = null;

        const progressBar = handle.messageBox.$el.find('.mb-progress-bar');
        const completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

        progressBar.width('0%');
        completeMessage.text('0% complete');

        // ...
        const self = this;

        const checkReady = () => {
            if (!folderId || htmlContent === null) {
                return;
            }

            if (!htmlContent || imageFiles.length === 0) {
                handle.reset();

                MessageBox.showOk({
                    message: 'Import failed<br>The ZIP file does not contain the required data. The ZIP file must contain a valid .html file and associated images',
                    icon: 'icon-warning'
                }, self.parent);

                return;
            }

            self.processZipCampaign(handle, folderId, htmlContent, imageFiles, callback);
        }

        this.getCampaignFolder(file, campaignName, function(fid) {
            folderId = fid;
            checkReady();
        });

        this.getZipCampaignInfo(file, function(content, imgFiles) {
            htmlContent = content;
            imageFiles = imgFiles;
            checkReady();
        });
    }

    processZipCampaign(handle, parentFolderId, htmlContent, imageFiles, callback) {
        // change the images local paths to file hosting paths
        const progressBar = handle.messageBox.$el.find('.mb-progress-bar');
        const completeMessage = handle.messageBox.$el.find('.mb-wait-cont');
        const srcTag = 'src="';
        let idxStart = 0;
        let toReplace = {};

        while (true) {
            const srcStartIdx = htmlContent.indexOf(srcTag, idxStart);

            if (srcStartIdx === -1) {
                break;
            }

            const srcEndIdx = htmlContent.indexOf('"', srcStartIdx + srcTag.length);

            if (srcEndIdx === -1) {
                break;
            }

            const src = htmlContent.substring(srcStartIdx + srcTag.length, srcEndIdx);

            // is the src path local?
            if (src.indexOf('http') !== 0 && !toReplace[src]) {
                const imageDef = imageFiles.find(i => i.sourceName.indexOf(src) !== -1);

                if (imageDef) {
                    toReplace[src] = {
                        name: imageDef.targetName,
                        file: imageDef.file
                    };
                }
            }

            idxStart = srcEndIdx + 1;
        }

        const numFilesToUpload = _.keys(toReplace).length;

        if (AppConfig.getValue('campaigns.add_vib_link')) {
            htmlContent = htmlContent.replace(/> (.*?) <\/webversion>/g, '><a href=${vib-url} target="_blank" rel="noreferrer">View this email in your browser</a></webversion>');
            // hack: support missing >
            htmlContent = htmlContent.replace(/> (.*?) <\/webversion/g, '><a href=${vib-url} target="_blank" rel="noreferrer">View this email in your browser</a></webversion>');
            htmlContent = htmlContent.replace('#transparent', '#e5e5e5');
            htmlContent = htmlContent.replace(/<unsubscribe>(.*?)<\/unsubscribe>/g, '<unsubscribe><a href=${unsubscribe-url} target="_blank" rel="noreferrer">unsubscribe here</a></unsubscribe>');
        }

        // replace &quot; encoded char
        htmlContent = Utilities.replaceAll(htmlContent, '&quot;', "'");
        htmlContent = this.replaceMailTags(htmlContent);

        if (numFilesToUpload === 0) {
            handle.reset();
            callback(htmlContent);
        } else {
            let numFilesUploaded = 0;

            for (const src in toReplace) {
                const replaceInfo = toReplace[src];
                let formData = new FormData();

                formData.append('parent_id', parentFolderId);
                formData.append('file', replaceInfo.file, replaceInfo.name);

                $.ajax({
                    type: 'POST',
                    url: '/content_files',
                    contentType: false,
                    processData: false,
                    data: formData
                }).done(function(result) {
                    htmlContent = Utilities.replaceAll(htmlContent, src, result.url);

                    ++numFilesUploaded;

                    if (numFilesUploaded === numFilesToUpload) {
                        handle.reset();
                        callback(htmlContent);
                    } else {
                        const pct = (numFilesUploaded / numFilesToUpload) * 100;
                        progressBar.width(pct + '%');
                        completeMessage.text(Math.floor(pct) + '% complete');
                    }
                });
            }
        }
    }

    getZipCampaignInfo(zipFile, callback) {
        let numFilesToDecompress = 0;
        let htmlContent = '';
        let imageFiles = [];

        const checkReady = () => {
            if (numFilesToDecompress > 0) {
                return;
            }

            callback(htmlContent, imageFiles);
        }

        jszip.loadAsync(zipFile).then(function(data) {
            for (const fname in data.files) {
                if (fname.indexOf('__MACOSX') === 0) { // discard macos system files
                    continue;
                }

                const file = data.files[fname];

                if (!file.dir) {
                    const fext = fname.split('.').pop().toLowerCase();

                    if (fext === 'html') {
                        ++numFilesToDecompress;

                        file.async('string').then(function(content) {
                            htmlContent = content;
                            --numFilesToDecompress;
                            checkReady();
                        });
                    } else if (Utilities.getTypeIcon(`.${fext}`).type === 'image-file') {
                        ++numFilesToDecompress;

                        file.async('blob').then(function(content) {
                            imageFiles.push({
                                sourceName: fname,
                                targetName: `${fname.split('/').pop()}`,
                                file: content,
                            });

                            --numFilesToDecompress;
                            checkReady();
                        });
                    }
                }
            }
        });
    }

    getCampaignFolder(zipFile, campaignName, callback) {
        const publicFolder = new ContentFolderModel({ id: 'public', is_public: true });
        const self = this;

        publicFolder.fetch({
            success: function() {
                // get (or create) the 'Assets' folder
                const assetsFolder = (publicFolder.get('child_folders') || []).find(c => c.name === 'Assets');

                if (assetsFolder) {
                    self.createCampaignFolder(zipFile, assetsFolder.id, campaignName, callback);
                } else {
                    const zipAssetsFolder = new ContentFolderModel();

                    zipAssetsFolder.save({
                        name: 'Assets',
                        description: 'Uploaded ZIP Email Campaign Assets',
                        type: 'folder',
                        parent_id: publicFolder.get('id'),
                        protected: true
                    }, {
                        validate: true,
                        alert: false,
                        success: function(result) {
                            self.createCampaignFolder(zipFile, result.id, callback);
                        }
                    });
                }
            }
        });
    }

    createCampaignFolder(zipFile, parentFolderId, campaignName, callback) {
        // get and update the prefix incrementer for the campaigns
        const clientPreferences = app.user.get('client').preferences || {};
        const campaignIncrementer = parseInt(clientPreferences['campaign_prefix_incrementer'] || '1');

        campaignName = encodeURIComponent(Utilities.replaceAll(campaignName, ' ', '-'));

        const folderName = `cbl${campaignIncrementer}-${campaignName}`;

        clientPreferences['campaign_prefix_incrementer'] = (campaignIncrementer + 1).toString();

        $.ajax({
            type: 'PATCH',
            url: '/clients/1',
            dataType: 'json',
            data: JSON.stringify({
                preferences: clientPreferences
            })
        });

        const folder = new ContentFolderModel();

        folder.save({
            name: folderName,
            description: `Files imported from ${zipFile.name}`,
            type: 'folder',
            parent_id: parentFolderId
        }, {
            validate: true,
            alert: false,
            success: function(result) {
                callback(result.id);
            }
        });
    }

    replaceMailTags(content) {
        for (const tags of THIRD_PARTY_MAIL_MAPPED_TAGS) {
            var ssTag = tags.ssTag;

            for (const tag of tags.mcTag) {
                if (tag.includes('fallback')) {
                    const indexOfEquals = tag.indexOf('=') + 1;
                    const startOfTag = tag.substring(0, indexOfEquals);
                    const endOfTag = tag.substring(indexOfEquals);

                    var defaultValue = content.substring(
                        content.indexOf(startOfTag) + startOfTag.length,
                        content.indexOf(endOfTag)
                    );

                    if (defaultValue.length > 0) {
                        defaultValue = ',default=' + defaultValue;

                        ssTag = ssTag.substring(0, ssTag.indexOf('}')) + defaultValue + ssTag.substring(ssTag.indexOf('}'), ssTag.length);
                    }
                }

                content = Utilities.replaceAll(content, tag, ssTag);
            }
        }

        return content;
    }
}

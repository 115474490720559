import React from 'react';
import onClickOutside from 'react-onclickoutside';

const fontSizes = {
    plusTiny: 7,
    tiny: 11,
    smallest: 12,
    smaller: 13,
    normal: 14,
    large: 16,
    larger: 18,
    largest: 20,
    huge: 24
};

const zOrder = {
    menus: 100
};

const commonStyles = {
    popoverShadow: '0 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.12)'
};

class Popover extends React.Component {
    handleClickOutside() {
        this.props.onClose();
    }

    render() {
        const width = this.props.wider ? 350 : 236;

        return (
            <div
                style={{
                    position: 'absolute',
                    zIndex: zOrder.menus,
                    right: 0,
                    top: '100%',
                    width: width,
                    marginTop: '18px',
                    borderRadius: 3,
                    boxShadow: commonStyles.popoverShadow,
                    background: 'white'
                }}>
                {/* arrow */}
                <div
                    style={{
                        position: 'absolute',
                        top: -18,
                        left: '91%',
                        width: 36,
                        height: 18,
                        marginLeft: -18,
                        overflow: 'hidden'
                    }}>
                    <div
                        style={{
                            position: 'absolute',
                            top: 9,
                            marginLeft: 9,
                            width: 18,
                            height: 18,
                            transform: 'rotate(45deg)',
                            background: 'white',
                            boxShadow: commonStyles.popoverShadow,
                            overflow: 'hidden'
                        }} />
                </div>
                <h5
                    style={{
                        margin: 0,
                        padding: '0 20px',
                        fontSize: fontSizes.larger,
                        lineHeight: '42px',
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }}>{this.props.title}</h5>
                <div
                    style={{padding: 12}}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default onClickOutside(Popover);

import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import d3 from 'd3'

import AppConfig from 'app/app-config'
import FunnelsCollection from 'js/collections/funnels'
import PhasesCollection from 'js/collections/phases'
import PeriodsCollection from 'js/collections/periods'
import OpportunityModel from 'js/models/opportunity'
import OpportunitiesCollection from 'js/collections/opportunities'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import ForecastsCollection from 'js/collections/forecasts'
import CustomFieldsCollection from 'js/collections/custom_fields'
import FunnelViewEx from 'js/views/funnelEx'
import DealListingBaseView from 'app/_components/IOD-listing/IOD/deal-listing/deal-listing-base'
import ItemPermissionsView from 'js/views/item_permissions'
import FilterQuickOptionsDeals from 'js/views/filters/quick-options-deals'
import GroupModel from 'js/models/group'
import ModalRegion from 'js/views/base/modal-region'
import app from 'js/app'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import security from 'js/utils/security'
import Currency from 'js/utils/currency'
import MessageBox from 'js/views/message_box'
import dateFormat from 'js/utils/date-format'
import funnelTemplate from 'app/_components/IOD-listing/IOD/deal-listing/deal-listing-overview.handlebars'
import IpwTrigger from 'js/ipw_trigger'


var BaseOverviewView = DealListingBaseView.prototype.OverviewView;

var NUM_DEALS_TO_FETCH = 5000;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(funnelTemplate),
    templateHelpers: function() {
        var model = this.model;
        var groupProtected = (model && model.get('display_options') || {}).protected;
        var clientPreferences = app.user.get('client').preferences || {};
        var enableDemographicMapLayout = false;

        if (model && clientPreferences.demographic_maps) {
            var mapInfo = JSON.parse(clientPreferences.demographic_maps).opportunities;
            enableDemographicMapLayout = mapInfo && (mapInfo.address_builder || mapInfo.static_pins);
        }

        enableDemographicMapLayout |= !!this.options?.demographicMap?.staticPins;

        return {
            id: this.parent.getElementType(),
            name: model && model.get('name') || this.title,
            hideInfo: true,
            hideFilters: groupProtected,
            enableDemographicMapLayout: enableDemographicMapLayout
        };
    },
    regions: _.defaults({
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        funnelContainer: '.funnel-widget-container'
    }, BaseOverviewView.prototype.regions),
    events: _.defaults({
        'click .select-box nav': 'toggleDropdown',
        'deal-phase-change': 'dealPhaseChange',
        'show-deal': 'showDeal',
        'change-funnel': 'changeFunnel',
        'change .layout-mode .radios input[type=radio].table-mode-radio': function() {
            var self = this;

            if (this.listModeVisible) {
                this.parent.$el.addClass('expanded-table');

                delete this.listModeVisible;

                this.$el.find('.deals-list-panel').one(
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                    function() {
                        _.defer(function() {
                            self.$el.find('.deals-funnel-panel').hide();
                            vent.trigger('deals:expand-table');
                            self.fetchCollection({}, self.tablePage, 50, null, {fetchingFromTable: true});
                        });
                    }
                );
            } else { // map layout active
                _.defer(function() {
                    vent.trigger('deals:expand-table');
                    self.fetchCollection({}, self.tablePage, 50, null, {fetchingFromTable: true});
                });
            }

            if (this.mapRegion && this.mapRegion.currentView) {
                this.mapRegion.currentView.component.hide();
            }
        },
        'change .layout-mode .radios input[type=radio].list-mode-radio': function() {
            this.listModeVisible = true;
            this.parent.$el.removeClass('expanded-table');
            this.$el.find('.deals-funnel-panel').show();
            this.fetchCollection(null, null, null, null, this.listFetchExtraOptions);

            if (this.mapRegion && this.mapRegion.currentView) {
                this.mapRegion.currentView.component.hide();
            }
        },
        'change .layout-mode .radios input[type=radio].map-mode-radio': function() {
            var self = this;

            if (this.listModeVisible) {
                this.parent.$el.addClass('expanded-table');
                this.$el.find('.deals-funnel-panel').hide();

                delete this.listModeVisible;

                this.$el.find('.deals-list-panel').one(
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                    function() {
                        _.defer(function() {
                            self.mapRegion.currentView.component.show();
                        });
                    }
                );
            } else { // table layout active
                _.defer(function() {
                    self.mapRegion.currentView.component.show();
                });
            }
        }
    }, BaseOverviewView.prototype.events),
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        if (this.model) {
            var hasPermission = security.checkPermission('edit', this.model),
                isSmart = this.model.isSmart();

            this.tools = {
                newItem: false,
                deleteItem: true,
                addItem: !isSmart && hasPermission,
                removeItem: !isSmart && hasPermission,
                selectAllPage: true,
                editColumns: hasPermission,
                selectAll: true,
                editItem: true
            };
            this.toolLabels = {
                removeItem: "Remove from Group"
            };
        }

        this.dt = new Date();
        this.deltaWeeks = 0;
        this.funnelView = new FunnelViewEx({hideDealsSelect: true, section: this.options.section});
        this.activeFunnel = this.options.section ? (this.options.section.default_funnel_id || null) : null;
        this.funnelIsExpanded = false;
        this.dealViewWithFunnelExpanded = null;
        this.tableFetchExtraOptions = {};
        this.listFetchExtraOptions = {};

        if (this.activeFunnel) {
            app.user.updatePreference('last_visited_funnel_id', this.activeFunnel);
        }

        this.listenTo( this.funnelView, 'funnel:timeChange', function( weeks ) {
            weeks = weeks || void 0;
            if (this.deltaWeeks === weeks) {
                return;
            }

            this.deltaWeeks = weeks;
            this.fetchDealsCollectionByDeltaWeek();
        } );

        this.listenTo(this.funnelView, 'funnel:expanded', function() {
            this.funnelIsExpanded = true;
            this.parent.trigger('funnel:expanded');
        });

        this.listenTo(this.funnelView, 'funnel:shrinked', function() {
            if (this.dealViewWithFunnelExpanded) {
                this.dealViewWithFunnelExpanded.closeView();
                this.dealViewWithFunnelExpanded = null;
                this.$el.find('.deals-funnel-panel').removeClass('narrow');
            }

            this.funnelIsExpanded = false;
            this.parent.trigger('funnel:shrinked');
        });

        this.listenTo(this.funnelView, 'show-deal', function(dealId) {
            this.showDeal(null, dealId);
        });

        this.listenTo(vent, 'opportunity:delete', function(model) {
            this.collection.remove(model);
        });

        this.listenTo(vent, 'organization:delete', function(model) {
            var id = model.get('id');
            var items = this.collection.filter(function(model) {
                var organization = model.get('organization');
                return organization && organization.id === id;
            });
            this.collection.remove(items);
        });

        if (options.initInTableMode) {
            var self = this;

            this.parent.$el.addClass('expanded-table');

            _.defer(function() {
                self.parent.$el.find('.table-mode-radio').click();
            });
        }
        else {
            this.listModeVisible = true;
        }

        this.listenTo(vent, 'deals:sidebarCollapsed deals:sidebarUncollapsed', function() {
            this.funnelView.renderFunnel();
        });

        this.fetchCollection = _.debounce(this.fetchCollection, 500);
    },
    onBeforeClose: function () {
        // HACKY - removing class from region el
        this.parent.$el.parent().parent().removeClass('deals-visible');
    },
    showTableView: function() {
        var self = this;

        if (!this.tableRegion) {
            return;
        }

        var tableView = this.createTableView();
        if (!tableView) {
            return;
        }

        this.listenTo(tableView, 'fetch-collection', this.fetchCollection);

        this.listenTo(tableView, 'toolbar:new', this.newItem);
        this.listenTo(tableView, 'toolbar:add-item', this.addItem);
        this.listenTo(tableView, 'toolbar:delete-bulk', function(options) {
            this.deleteItems(options, true);
        });
        this.listenTo(tableView, 'toolbar:remove', function(data) {
            this.removeItems(data, true);
        });
        this.listenTo(tableView, 'toolbar:edit', function(options) {
            this.editItems(options, true);
        });
        this.listenTo(tableView, 'select', this.onSelectItem);

        this.listenTo(tableView, 'highlight', function(model) {
            this.funnelView.highlight( model ? model.id : null );
            this.funnelView.renderFunnel();
        });

        this.listenTo(tableView, 'update-columns', function(columns) {
            this.selectedColumns = columns;
        });

        this.listenTo(tableView, 'group:showInsights', this.showGroupInsights);

        this.listenTo(tableView, 'page:change', function(page) {
            this.tablePage = page;
        });

        this.tableRegion.show(tableView);
        this.tablePage = tableView.initialPage || 1;

        if (this.model) {
            var display_options = this.model.get('display_options');

            if (display_options && display_options.show_as === 'table') {
                _.defer(function() {
                    self.$el.find('.table-mode-radio').click();
                });
            }
        }
    },
    showListView: function() {
        if (!this.listRegion) {
            return;
        }

        var listView = this.createListView();
        if (!listView) {
            return;
        }

        this.listenTo(listView, 'fetch-collection', this.fetchCollection);

        this.listenTo(listView, 'toolbar:new', this.newItem);
        this.listenTo(listView, 'toolbar:add-item', this.addItem);
        this.listenTo(listView, 'toolbar:delete-bulk', this.deleteItems);
        this.listenTo(listView, 'toolbar:remove', this.removeItems);
        this.listenTo(listView, 'toolbar:edit', this.editItems);

        this.listenTo(listView, 'showItem', this.onSelectItem);
        this.listenTo(listView, 'followLink', this.onFollowLink);

        this.listenTo(listView, 'highlight', function(model) {
            this.funnelView.highlight( model ? model.id : null );
            this.funnelView.renderFunnel();
        });

        this.listRegion.show(listView);
    },
    onShow: function() {
        var view = this;

        BaseOverviewView.prototype.onShow.apply(this, arguments);

        this.funnelContainer.show(this.funnelView);
        this.funnels = new FunnelsCollection(app.globalData.funnelsInfo.funnels);

        view.activeFunnel = view.activeFunnel || view.funnels.getLastVisitedFunnelId();

        let funnels = view.funnels.models;

        if (view.options.section) {
            if (view.options.section.funnels_list_hidden) {
                funnels = [view.funnels.models.find(f => f.get('id') === view.activeFunnel)];
            }

            if (view.options.section.won_lost_phases_hidden) {
                view.funnelView.funnelView.funnel.wonLostPhasesVisible(false);
            }

            if (view.options.section.deals_total_value_hidden) {
                view.funnelView.funnelView.funnel.dealsTotalValueVisible(false);
            }
        }

        view.funnelView.setFunnels(funnels, view.activeFunnel);
        view.phases = new PhasesCollection(app.globalData.phasesInfo.phases);
        view.funnelView.setPhases(view.phases.models);
        view.funnelView.renderFunnel();

        view.periods = new PeriodsCollection(app.globalData.periods);
        view.funnelView.setPeriods(view.periods.models);

        this.listenTo(vent, 'deal:save deal:delete deal:elephant:added deal:elephant:removed', view.fetchCollection);

        // HACKY - adding class to region el
        this.parent.$el.parent().parent().addClass('deals-visible');

        _.defer(function() {
            var container = $('#deals-widgets-left');
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                function(ev) {
                    if (ev.target.id === 'deals-widgets-left') {
                        _.defer(function() {
                            this.funnelView.renderFunnel();
                        }.bind(this));
                    }
                }.bind(this)
            );
        }.bind(this));

        var container = this.$el.find('.deals-list-panel');
        container.on(
            'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
            function(ev) {
                if (ev.target === container[0]) {
                    view.updateListHeight();
                }
            }
        );

        this.$el.find('.detail-name').tooltip();

        return this;
    },
    getDeltaDate: function(delta) {
        delta = delta || 0;

        var dt = new Date();

        // Remove hours, minutes, seconds...
        dt.setHours(0, 0, 0, 0);

        // Move Today forward to next Monday
        if (dt.getDay() === 1) {
            dt.setDate(dt.getDay() + 1);
        }
        while (dt.getDay() !== 1) {
            dt.setDate(dt.getDate() + 1);
        }

        var newDt = new Date(dt);
        newDt.setUTCDate(dt.getUTCDate() - (7 * delta));

        return newDt;
    },
    fetchCollection: function(options, page, rows, sortOptions, extraOptions) {
        extraOptions = extraOptions || {};
        var isShowingTable = this.parent.$el.hasClass('expanded-table');
        var doFetch = true;

        // hook to enable correct data fetching
        if (isShowingTable) {
            extraOptions.fetchingFromTable = true;
        }

        if (extraOptions.byItemAdded || extraOptions.byFilterUpdate) {
            extraOptions = this.fetchingFromTable ? this.tableFetchExtraOptions : this.listFetchExtraOptions;
        }
        else {
            if (extraOptions.fetchingFromTable) {
                this.tableFetchExtraOptions = extraOptions;
            }
            else {
                this.listFetchExtraOptions = extraOptions;
            }
        }

        // ...
        if ((isShowingTable && extraOptions.fetchingFromTable) || (!isShowingTable && !extraOptions.fetchingFromTable))
        {
            var finalRows = NUM_DEALS_TO_FETCH;
            var finalPage = 1;

            if (isShowingTable) {
                this.page = page || this.page;
                this.rows = rows || this.rows;
                finalRows = this.rows;
                finalPage = this.page;
            }

            options = options || this.options.fetchOptions || {};
            this.sortOptions = sortOptions || this.sortOptions;
            this.fetchingFromTable = extraOptions.fetchingFromTable;

            if (this.options.section) {
                options.section_id = this.options.section.id;
            }

            if (!extraOptions.fetchingFromTable) {
                extraOptions.columnsIds = ['name', 'status', 'abbreviation', 'expected_close_date', 'organization.name', 'phase_last_changed',
                                           'funnel', 'phase', 'value', 'default_value', 'currency', 'owner.name', 'latest_activity_in_days',
                                           'bubble_representation_value'];

                if(AppConfig.getValue('dealsListingExtraColumns')){
                    extraOptions.columnsIds = extraOptions.columnsIds.concat(AppConfig.getValue('dealsListingExtraColumns'))
                }

                if(AppConfig.getValue('customColorKeys')){
                    const clientPreferences = app.user.get('client').preferences;
                    const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ?
                        JSON.parse(clientPreferences.custom_colorkeys) : [];

                    if (customColorkeys && customColorkeys.length > 0){
                        for (var ck of customColorkeys) {
                            if (!ck.is_system_field) {
                                extraOptions.columnsIds.push(`custom_fields.${ck.dropdown_id}`);
                            }
                        }
                    }
                }

                if(AppConfig.getValue('funnel.display_bubble.check_achieved_price_and_asking_price')){
                    extraOptions.columnsIds.push('buckets')
                }

                const extraColumnsBySection = AppConfig.getValue('deals.list.extra_columns', null, this.options.section) || [];

                if (extraColumnsBySection.length > 0) {
                    extraOptions.columnsIds = extraOptions.columnsIds.concat(extraColumnsBySection);
                }

                if (app.globalData.funnelsInfo.mapsAvailable) {
                    extraOptions.columnsIds = extraOptions.columnsIds.concat(app.globalData.funnelsInfo.fieldIds);
                    extraOptions.columnsIds.push('buckets');
                }

                var customOrderBy = AppConfig.getValue('deals.custom_order_by');

                if (customOrderBy && customOrderBy.is_custom_field) {
                    if (_.isUndefined(app.globalData.dealsCustomOrderBy)) {
                        doFetch = false;

                        var self = this;
                        var customFields = new CustomFieldsCollection();

                        customFields.fetch({
                            rows: -1,
                            filterBy: [{
                                attribute: 'view',
                                value: 'opportunities'
                            }],
                            success: function(res) {
                                var customCF = res.models.find(m => m.get('name').toLowerCase() === customOrderBy.field.toLowerCase());

                                if (customCF) {
                                    var sortOptions = [{
                                        attribute: `custom_fields.${customCF.get('id')}`,
                                        order: customOrderBy.dir
                                    }];

                                    app.globalData.dealsCustomOrderBy = `${sortOptions[0].attribute} ${sortOptions[0].order}`;
                                    BaseOverviewView.prototype.fetchCollection.call(self, options, finalPage, finalRows, sortOptions, extraOptions);
                                } else {
                                    app.globalData.dealsCustomOrderBy = null;
                                    BaseOverviewView.prototype.fetchCollection.call(self, options, finalPage, finalRows, self.sortOptions, extraOptions);
                                }
                            }
                        });
                    } else if (app.globalData.dealsCustomOrderBy) {
                        doFetch = false;

                        var sortInfo = app.globalData.dealsCustomOrderBy.split(' ');
                        var sortOptions = [{
                            attribute: sortInfo[0],
                            order: sortInfo[1]
                        }];

                        BaseOverviewView.prototype.fetchCollection.call(this, options, finalPage, finalRows, sortOptions, extraOptions);
                    }
                }
            }

            if (doFetch) {
                BaseOverviewView.prototype.fetchCollection.call(this, options, finalPage, finalRows, this.sortOptions, extraOptions);
            }
        }
    },
    beforeCollection: function(options) {
        if (options && options.fetchingFromTable) {
            if (this.tableRegion && this.tableRegion.currentView) {
                this.tableRegion.currentView.beforeCollection(options);
            }
        }
        else {
            if (this.listRegion && this.listRegion.currentView) {
                this.listRegion.currentView.beforeCollection(options);
            }
        }

        this.funnelView.showLoader();
    },
    onCollection: function(collection, fetchingFromTable) {
        // if we click fast, view might be closed before response arrives
        if (this.isClosed || !collection ) {
            return;
        }

        var isShowingTable = this.parent.$el.hasClass('expanded-table');

        this.funnelView.hideLoader();

        if (collection.total > NUM_DEALS_TO_FETCH && !isShowingTable) {
            this.parent.$el.addClass('expanded-table');
            this.parent.$el.find('.table-mode-radio').click();
        } else {
            if (fetchingFromTable) {
                if (isShowingTable && this.tableRegion) {
                    // fetch first page if we don't have results for current page but we have results
                    if ((collection.length === 0) && (collection.collectionPage > 1) && (collection.total > 0)) {
                        this.fetchCollection(null, 1, null, null, this.tableFetchExtraOptions);
                    }
                    else {
                        this.collection = collection;
                        this.tableRegion.currentView.onCollection(collection);
                    }
                }
            }
            else {
                if (!isShowingTable && this.listRegion) {
                    this.collection = collection;
                    this.listRegion.currentView.onCollection(collection);

                    // Update funnel
                    if (this.deltaWeeks === 0) {
                        this.setFunnelDeals(collection.models);
                    }
                    else {
                        this.fetchDealsCollectionByDeltaWeek();
                    }

                    // Update quota widget
                    this.showQuotaWidget(collection);
                }
            }
        }
    },
    fetchDealsCollectionByDeltaWeek: function() {
        var view = this;
        var deals = new OpportunitiesCollection();
        var fetchOptions = _.extend({}, this.fetchOptions,
            {
                delta_weeks: this.deltaWeeks,
                owner_id: this.allOrMyDeals && this.allOrMyDeals.id === 'my_deals' ? app.user.get('id') : undefined
            });

        // endpoint /opportunities use the old name for the group_id
        fetchOptions.by_group_id = fetchOptions.group_id;

        this.funnelView.showLoader();

        deals.fetch({
            rows: -1,
            data: fetchOptions,
            success: function(collection) {
                view.funnelView.hideLoader();
                view.setFunnelDeals(collection.models);
            }
        });
    },
    fetchDealsCollectionByOwner: function() {
        var view = this;
        var deals = new OpportunitiesCollection();
        var fetchOptions = _.extend({}, this.fetchOptions,
            {
                delta_weeks: this.deltaWeeks,
                owner_id: this.allOrMyDeals && this.allOrMyDeals.id === 'my_deals' ? app.user.get('id') : undefined
            });

        // endpoint /opportunities use the old name for the group_id
        fetchOptions.by_group_id = fetchOptions.group_id;

        this.funnelView.showLoader();

        deals.fetch({
            rows: -1,
            data: fetchOptions,
            success: function(collection) {
                view.funnelView.hideLoader();
                view.setFunnelDeals(collection.models);
            }
        });
    },
    setFunnelDeals: function(models) {
        this.funnelView.setDeals(models, this.getDeltaDate(this.deltaWeeks));
        this.funnelView.renderFunnel();
    },
    showQuotaWidget: function( opps ) {
        var user = app.user,
            quotaCurrency = app.user.get('client').default_currency;

        let quotaWidgetVisible = AppConfig.getValue('group_pages.deals.quota_widget.visible', true);

        if (this.options.section && this.options.section.quota_widget_hidden) {
            quotaWidgetVisible = false;
        }

        if (!quotaWidgetVisible) {
            d3.select('.quota-widget').style('display', 'none');

            d3.select('.deals-list-container').style('bottom', '0px');

            return
        }

        this.$el.find('.quota-widget').removeClass('before-load hidden');

        if ( !opps || !quotaCurrency ) {
            d3.select('.quota-widget .quota-target-value')
                .text('0');

            d3.select('.quota-widget .quota-closed-value')
                .text('0');

            d3.select('.quota-widget .quota-bar-chart .bar')
                .style('width', '0%');

            return;
        }

        var sumClosed = this.collection.totals.closed_value;
        var sumCommitted = this.collection.totals.committed_value;
        var target = '-';
        var numericTarget = 1;

        if (this.model) {
            var modelTarget = parseFloat(this.model.get('target'));

            if (!_.isNaN(modelTarget)) {
                target = Currency.shortFormat(quotaCurrency, modelTarget);
                numericTarget = modelTarget;
            }
        }

        d3.select('.quota-widget .quota-target-value')
            .text( target );

        d3.select('.quota-widget .quota-closed-value')
            .text( Currency.shortFormat(quotaCurrency, sumClosed) );

        d3.select('.quota-widget .quota-committed-value')
            .text( Currency.shortFormat(quotaCurrency, sumCommitted) );


        d3.select('.quota-widget .bar-closed')
            .style('width', function() {
                var pct = Math.floor(sumClosed * 100 / numericTarget);
                return pct + '%';
            });

        d3.select('.quota-widget .bar-committed')
            .style('width', function() {
                var pct = Math.floor(sumCommitted * 100 / numericTarget);
                return pct + '%';
            });
    },
    showDeal: function(ev, id) {
        var options = {
            id: id,
        };

        if (this.funnelIsExpanded) {
            var self = this;
            var funnelPanel = this.$el.find('.deals-funnel-panel');

            options.viewOptions = {
                condensed: true
            };

            options.onViewCreation = function(view) {
                self.dealViewWithFunnelExpanded = view;

                self.listenTo(view, 'view:close', function() {
                    self.dealViewWithFunnelExpanded = null;
                    funnelPanel.removeClass('narrow');
                    self.funnelView.renderFunnel();
                });

                self.listenTo(view, 'replace-view:organization:show replace-view:individual:show', function() {
                    if (self.dealViewWithFunnelExpanded) {
                        self.dealViewWithFunnelExpanded = null;
                        self.$el.find('.deals-funnel-panel').removeClass('narrow');
                    }

                    self.funnelView.shrink();
                });
            };

            funnelPanel.addClass('narrow');
            this.funnelView.renderFunnel();
        }

        this.parent.trigger('push-view:deal:show', options);
    },
    changeFunnel: function(ev, id) {
        this.activeFunnel = id;
        this.funnelView.setFunnels(this.funnels.models, this.activeFunnel);
        this.funnelView.renderFunnel();
        app.user.updatePreference('last_visited_funnel_id', id);
    },
    toggleDropdown: function(ev) {
        var view = this;
        ev.preventDefault();
        ev.stopPropagation();
        function toggleExpand() {
            view.ui.listSorter.toggleClass('expanded');
        }
        if (!this.dropdownExpanded) {
            $(document).on('click.close-dropdown', function() {
                toggleExpand();
                $(this).off('click.close-dropdown');
                view.dropdownExpanded = false;
            });
        } else {
            $(document).off('click.close-dropdown');
        }
        this.dropdownExpanded = !this.dropdownExpanded;
        toggleExpand();
    },
    onFilterUpdate: function(filter) {
        var view = this;

        if (this.model && security.checkPermission('edit', this.model) && this.model.isSmart()) {
            this.model.save({
                filter_id: filter && filter.get('id') || null
            }, {
                patch: true,
                complete: function() {
                    BaseOverviewView.prototype.onFilterUpdate.call(view, filter);
                }
            });
        } else {
            BaseOverviewView.prototype.onFilterUpdate.call(this, filter);
        }
    },
    dealPhaseChange: function(ev, data) {
        var view = this,
            dealId = data.dealId,
            phase = data.phase,
            funnel = data.funnel,
            opportunity;

        if (phase || funnel) {
            var opportunityData = {
                id: dealId
            };

            if (phase) {
                opportunityData.phase_id = phase.id;
            }
            else {
                opportunityData.funnel_id = funnel.id;
            }

            opportunity = new OpportunityModel({id: dealId});

            if (AppConfig.getValue('showIPWCampaignTriggerMessage', false) && phase && data.deal) {
                const currentPhase = data.deal.get('phase');

                if (currentPhase && (phase.order > currentPhase.order)) {
                    var mbContent = {
                        message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                        icon: 'icon-warning'
                    };

                    const updateFields = function(phase, opportunity, opportunityData, triggerIpw) {
                        var ipwTrigger = new IpwTrigger();

                        ipwTrigger.getPopulatedIpwCustomFields(phase.name, triggerIpw, null, function(fields) {
                            opportunityData = _.extend(opportunityData, fields);
                            view.updateDealPhaseChange(opportunity, opportunityData, view);
                        });
                    }

                    MessageBox.showYesNo(mbContent, view,
                        function() { // Yes
                            updateFields(phase, opportunity, opportunityData, true);
                        },
                        function() { // No
                            updateFields(phase, opportunity, opportunityData, false);
                        }, null,
                        {
                            staticRegion: true
                        }
                    );
                } else {
                    view.updateDealPhaseChange(opportunity, opportunityData, view);
                }
            }else{
                view.updateDealPhaseChange(opportunity, opportunityData, view);
            }
        }
    },

    updateDealPhaseChange: function(opportunity, opportunityData, view){
        opportunity.save(opportunityData, {
            patch: true,
            validate: false,
            ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
            params: {
                check_phase_change_allowed: true,
                check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
            },
            success: function() {
                view.fetchCollection();
            },
            error: function(model, response, settings) {
                var detail;

                try {
                    detail = JSON.parse(response.responseText).detail;
                }
                catch (e) {
                }

                if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' ||
                    detail.exception === 'OpportunityPhaseGateNotClear')) {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            var message = detail.message
                            if (detail.exception === 'OpportunityPhaseGateNotClear'){
                                    message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                                }
                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }

                view.funnelView.renderFunnel();
            }
        });
    },
    getMenuOptions: function() {
        var hasEditPermission = security.checkPermission('edit', this.model);
        var clientPreferences = app.user.get('client').preferences || {};
        var templatedReports = clientPreferences.templated_reports ? JSON.parse(clientPreferences.templated_reports) : {};

        return {
            canEdit: hasEditPermission,
            hasPermissions: hasEditPermission && app.user.get('client').permission_type !== 'rba',
            canDelete: hasEditPermission,
            locked: this.model ? this.model.get('locked') : false,
            canSaveAsForecast: true,
            canDowloadFinancialReport: AppConfig.getValue('enableDownloadFinancialReportOption', false),
            canDownloadAsCSV: !AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption') || app.user.get('is_admin'),
            canDownloadTemplatedReport: templatedReports.opportunities && templatedReports.opportunities.templates && templatedReports.opportunities.templates.default,
            canShareGroup: hasEditPermission
        };
    },
    startEditing: function() {
        this.parent.trigger('group:edit', this.model);
    },
    'delete': function() {
        var self = this,
            preferences = app.user.get('preferences'),
            hiddenNotes = preferences['hidden_notes'] || {},
            note ='',
            initMessageActions;

        if (!('delete-group' in hiddenNotes)) {
            var link = '<a href="https://support.salesseek.net/general/deals-organizations-and-individuals/how-to-bulk-update-or-delete-records" target="_blank">Bulk Delete</a> ';
            note = '<div class="note-container">' + TextManager.getText('ID_DELETE_GROUP_WARNING_MESSAGE', ['${ID_DEAL, plural}', link]) + '</div>';

            initMessageActions = function(container) {
                container.find('.hide-note').click(function(){
                    hiddenNotes['delete-group'] = true;
                    app.user.updatePreference('hidden_notes', hiddenNotes);
                    container.find('.note-container').hide();
                });
            }
        }

        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?{{{note}}}')({name: this.model.get('name'), note: note}),
            initMessageActions: initMessageActions,
            icon: 'icon-trashcan'
        };

        MessageBox.showYesNo(mbContent, this, function() {
            self.model.destroy();
            self.parent.trigger('group:delete');
        });
    },
    permissions: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            } );
        }
    }
});

export default DealListingBaseView.extend({
    id: 'deals-section',
    className: 'detail detail-groups',
    template: Handlebars.compile('<div class="overview-container"></div>'),
    OverviewView: OverviewView,
    getUrl: function () {
        var url = [this.type];
        if (this.model) {
            url.push(this.model.get('id'));
        } else if (this.fetchOptions && this.fetchOptions.closing_in_period_id) {
            url.push(this.fetchOptions.closing_in_period_id);
        } else if (this.fetchOptions && this.fetchOptions.funnel_id) {
            url.push(this.fetchOptions.funnel_id);
        }
        return url.join('/');
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'

import StatsProvider from 'js/views/stats_provider'
import pagenetchart from 'js/d3/pagenetchart'
import worldchart from 'js/d3/worldchart'
import sourceschart from 'js/d3/sourceschart'
import dateFormat from 'js/utils/date-format'
import countryName from 'js/utils/countryName'
import WebStatsReducedView from 'js/views/web_stats_reduced'
import IPLookupTableView from 'js/views/iplookup_table'
import IPLookupD3View from 'js/views/iplookup_d3'
import backboneSelect2 from 'js/widgets/backbone-select2'
import webViewTemplate from 'templates/web/web-section.handlebars'
import sidebarTemplate from 'templates/web/sidebar.handlebars'
import filterItemTemplate from 'templates/web/filter-item.handlebars'
import overviewTemplate from 'templates/web/overview.handlebars'
import iplookupTemplate from 'templates/web/iplookup.handlebars'
import sourcesTemplate from 'templates/web/sources-widget.handlebars'
import contentTemplate from 'templates/web/content-widget.handlebars'
import locationsWidgetTemplate from 'templates/web/location-widget.handlebars'


var WebView, SidebarView, FilterItemView, OverviewDashboard,
    IPLookupDashboard, SourcesWidget, ContentWidget, LocationWidget;

/*
 * WebView contains the sidebar and the current visible view and widgets.
 * It wires all together (displaying the selected widget, and passing it
 * the selected filters)
 */
WebView = Marionette.Layout.extend({
    tagName: 'article',
    id: 'web-section',
    template: Handlebars.compile(webViewTemplate),
    regions: {
        sidebarRegion: '#web-nav',
        dashboardRegion: '#web-dashboards-container',
        widgetRegion: '#web-widgets-container'
    },
    initialize: function() {
        this.webStatsProvider = new StatsProvider.WebStatsProvider();
        this.ipLookupStatsProvider = new StatsProvider.IPLookupStatsProvider();
        this.activeStatsProvider = this.webStatsProvider;
    },
    onRender: function() {
        var sidebarView = new SidebarView();

        this.sidebarRegion.show(sidebarView);

        this.listenTo(sidebarView, 'showSection', this.showSection);
        this.listenTo(sidebarView, 'resetFilters', this.resetFilters);
        this.listenTo(sidebarView, 'removeFilter', this.removeFilter);
        this.listenTo(sidebarView, 'setFilter', this.setFilter);

        sidebarView.showOverview();

        this.resetFilters();
    },
    showSection: function(sectionName) {
        var newView;

        if (!this.dashboardRegion || !this.widgetRegion) {
            return;
        }

        if(this.currentSectionName === sectionName) {
            return;
        }

        this.currentSectionName = sectionName;

        var prevStatsProvider = this.activeStatsProvider;

        if ( sectionName === 'overview' ) {
            newView = new OverviewDashboard(this.webStatsProvider);
            this.dashboardRegion.show(newView);
            this.listenTo(newView, 'data', this.onData);
            this.widgetRegion.reset();
            this.$el.removeClass('widget-visible');
            this.activeStatsProvider = this.webStatsProvider;
        } else if ( sectionName === 'ipLookup' ) {
            newView = new IPLookupDashboard(this.ipLookupStatsProvider);
            this.dashboardRegion.show(newView);
            this.widgetRegion.reset();
            this.$el.removeClass('widget-visible');
            this.activeStatsProvider = this.ipLookupStatsProvider;
        } else if ( sectionName === 'location' ) {
            newView = new LocationWidget({data: this.webStatsProvider.getLocationData()});
            this.widgetRegion.show(newView);
            this.$el.addClass('widget-visible');
            this.activeStatsProvider = this.webStatsProvider;
        }

        // newView.updateFilters(this.activeStatsProvider.getFilters());
        this.listenTo(newView, 'setFilter', this.setFilter);

        if (prevStatsProvider !== this.activeStatsProvider) {
            this.onFilterChange();
        }
    },
    getUrl: function() {
        return 'web';
    },
    resetFilters: function() {
        this.activeStatsProvider.resetFilters();
        this.onFilterChange();
    },
    setFilter: function(name, value) {
        this.activeStatsProvider.setFilter(name, value);
        this.onFilterChange();
    },
    removeFilter: function(name, filter) {
        this.activeStatsProvider.removeFilter(name, filter);
        this.onFilterChange();
    },
    onFilterChange: function() {
        if ( this.sidebarRegion ) {
            this.sidebarRegion.currentView.updateFilters(this.activeStatsProvider.getFilters());
        }

        if ( this.dashboardRegion && this.dashboardRegion.currentView ) {
            this.dashboardRegion.currentView.updateFilters(this.activeStatsProvider.getFilters());
        }
    },
    onData: function() {
        if (this.widgetRegion && this.widgetRegion.currentView) {
            this.widgetRegion.currentView.updateData(this.activeStatsProvider.getLocationData());
        }
    }
});

/*
 * The sidebar contains links to sections and filter widgets. It also
 * display the currently applied filters.
 * It gets externally notified of new filters and displays them.
 * If a filter is removed from the sidebar an event is publised.
 * If a view or widget gets selected for display, an event is published.
 */
SidebarView = Marionette.Layout.extend({
    tagName: 'section',
    className: 'content',
    template: Handlebars.compile(sidebarTemplate),
    regions: {
        timespanFiltersRegion:
            '#web-time-span-link .web-widget-filters',
        segmentsFiltersRegion:
            '#web-segments-link .web-widget-filters',
        contentFiltersRegion:
            '#web-network-link .web-widget-filters',
        locationsFiltersRegion:
            '#web-locations-link .web-widget-filters'
    },
    ui: {
        overviewItem: '.web-overview-dashboard-link',
        ipLookupItem: '.iplookup-dashboard-link',
        timespanItem: '#web-time-span-link',
        segmentsItem: '#web-segments-link',
        networkItem: '#web-network-link',
        locationItem: '#web-locations-link',
        domainItem: '#web-domain-link',
        resetFiltersButton: '.remove-all-filters',
        domainSelect: '.web-domain-select'
    },
    events: {
        'click .remove-all-filters': 'resetFilters',
        'click .web-overview-dashboard-link': 'showOverview',
        'click .iplookup-dashboard-link': 'showIPLookup',
        // 'click #web-time-span-link': 'showTimespan',
        // 'click #web-segments-link': 'showSegments',
        // 'click #web-network-link': 'showNetwork',
        'click #web-locations-link': 'showLocation'
    },
    initialize: function() {
        this.domains = [{
            id: 'all',
            name: 'All Web Properties'
        }];
    },
    onRender: function() {
        var view = this;

        this.sections = [
            {name: 'overview', ui: this.ui.overviewItem},
            {name: 'ipLookup', ui: this.ui.ipLookupItem},
            {name: 'timespan', ui: this.ui.timespanItem},
            {name: 'segments', ui: this.ui.segmentsItem},
            {name: 'network', ui: this.ui.networkItem},
            {name: 'location', ui: this.ui.locationItem}
        ];

        this.allTrafficItemView = Marionette.ItemView.extend({
            tagName: 'li',
            template: Handlebars.compile('<small>All Traffic</small>')
        });

        this.timespanFiltersView = new Marionette.CollectionView({
            tagName: 'ul',
            itemView: FilterItemView,
            collection: new Backbone.Collection()
        });

        this.segmentsFiltersView = new Marionette.CollectionView({
            tagName: 'ul',
            itemView: FilterItemView,
            collection: new Backbone.Collection()
        });

        this.contentFiltersView = new Marionette.CollectionView({
            tagName: 'ul',
            emptyView: this.allTrafficItemView,
            itemView: FilterItemView,
            collection: new Backbone.Collection()
        });

        this.locationsFiltersView = new Marionette.CollectionView({
            tagName: 'ul',
            itemView: FilterItemView,
            collection: new Backbone.Collection()
        });

        this.timespanFiltersRegion.show(this.timespanFiltersView);
        this.segmentsFiltersRegion.show(this.segmentsFiltersView);
        this.contentFiltersRegion.show(this.contentFiltersView);
        this.locationsFiltersRegion.show(this.locationsFiltersView);

        // TODO: We need some generic treatment of filter sections
        this.listenTo(this.timespanFiltersView, 'itemview:removeFilter',
            function(childView) {
                view.trigger(
                    'removeFilter',
                    'timespan',
                    childView.model.get('filter'));
            });

        this.listenTo(this.segmentsFiltersView, 'itemview:removeFilter',
            function(childView) {
                view.trigger(
                    'removeFilter',
                    'segments',
                    childView.model.get('filter'));
            });

        this.listenTo(this.contentFiltersView, 'itemview:removeFilter',
            function(childView) {
                view.trigger(
                    'removeFilter',
                    'content',
                    childView.model.get('filter'));
            });

        this.listenTo(this.locationsFiltersView, 'itemview:removeFilter',
            function(childView) {
                view.trigger(
                    'removeFilter',
                    'location',
                    childView.model.get('filter'));
            });

        $.get('/tracker/domains', function(domains) {
            if (domains.length < 2) {
                return;
            }

            for (const [i, domain] of domains.entries()) {
                view.domains.push({
                    id: `domain_${i}`,
                    name: domain
                });
            }

            view.domainSelect = new backboneSelect2.SelectView({
                view: view,
                $el: view.ui.domainSelect,
                text: 'name',
                value: view.domains[0],
                data: view.domains,
                options: {
                    placeholder: 'Select Web Property',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'popover'
                }
            });

            view.listenTo(view.domainSelect, 'change', function(item) {
                if (!item || item.id === 'all') {
                    view.trigger('removeFilter', 'domain');
                } else {
                    view.trigger('setFilter', 'domain', item.name);
                }
            });

            view.ui.domainItem.removeClass('hidden');
        });
    },
    highlightSection: function(sectionName) {
        _.each(this.sections, function(d) {
            if (d.name === sectionName) {
                d.ui.addClass('active');
            } else {
                d.ui.removeClass('active');
            }
        });
    },
    resetFilters: function(ev) {
        if(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        if (this.domainSelect) {
            this.domainSelect.setValue(this.domains[0]);
        }

        this.trigger('resetFilters');
    },
    showSection: function(ev, sectionName) {
        if (ev ) {
            ev.preventDefault();
        }
        this.highlightSection(sectionName);
        this.trigger('showSection', sectionName);
    },
    showOverview: function(ev) {
        this.showSection(ev, 'overview');
    },
    showIPLookup: function(ev) {
        this.showSection(ev, 'ipLookup');
    },
    showTimespan: function(ev) {
        this.showSection(ev, 'timespan');
    },
    showSegments: function(ev) {
        this.showSection(ev, 'segments');
    },
    showNetwork: function(ev) {
        this.showSection(ev, 'network');
    },
    showLocation: function(ev) {
        this.showSection(ev, 'location');
    },
    updateFilters: function(filters) {
        var showResetButton = !filters.resetFiltersDisabled;
        this.ui.resetFiltersButton.toggle(showResetButton);

        this.updateTimespanFilters(filters.timespan);
        this.updateSegmentsFilters(filters.segments);
        this.updateContentFilters(filters.content);
        this.updateLocationsFilters(filters.location);
    },
    updateTimespanFilters: function(filters) {
        var filter, description, model, models;

        filter = (filters && filters.length) ? filters[0] : null;

        if (filter) {
            if ( filter.type === 'lastPeriod' ) {
                description = 'Last ' + filter.period;
            } else if ( filter.type === 'naturalPeriod' ) {
                if (filter.period === 'year') {
                    description = dateFormat.smartFormatYear(
                        new Date(filter.ts));
                } else if (filter.period === 'month') {
                    description = dateFormat.smartFormatMonth(
                        new Date(filter.ts));
                } else if (filter.period === 'week') {
                    description = dateFormat.smartFormatWeek(
                        new Date(filter.ts));
                } else if (filter.period === 'day') {
                    description = dateFormat.smartFormatDay(
                        new Date(filter.ts));
                } else if (filter.period === 'hour') {
                    description = dateFormat.smartFormatHour(
                        new Date(filter.ts));
                } else {
                    description = '???';
                }
            } else {
                description = '???';
            }

            model = new Backbone.Model({
                description: description,
                filter: filter,
                removable: !filter.removableDisabled
            });

            models = [model];
        } else {
            models = [];
        }

        if ( this.timespanFiltersView ) {
            this.timespanFiltersView.collection.reset(models);
        }
    },
    updateSegmentsFilters: function(filters) {
        var filter, model, models, descriptions;

        descriptions = {
            'page-views': 'Page views',
            'visits': 'Visits',
            'returning': 'Returning visits',
            'registered': 'Registered visits'
        };

        filter = (filters && filters.length) ? filters[0] : null;

        if (filter) {
            model = new Backbone.Model({
                className: filter.type + '-filter active',
                description: descriptions[filter.type],
                filter: filter,
                removable: !filter.removableDisabled
            });

            models = [model];
        } else {
            models = [];
        }

        if ( this.segmentsFiltersView ) {
            this.segmentsFiltersView.collection.reset(models);
        }
    },
    updateContentFilters: function(filters) {
        if (!this.contentFiltersView) {
            return;
        }

        filters = filters || [];

        // ...
        if (filters.hideSection) {
            this.ui.networkItem.hide();
        }
        else {
            var models = _.map(filters, function(d) {
                return new Backbone.Model({
                    description: d.shortUrl,
                    fullText: d.fullUrl,
                    filter: d,
                    removable: !d.removableDisabled
                });
            });

            this.ui.networkItem.show();
            this.contentFiltersView.collection.reset(models);
        }
    },
    updateLocationsFilters: function(filters) {
        if (!this.locationsFiltersView) {
            return;
        }

        filters = filters || [];

        // ...
        if (filters.hideSection) {
            this.ui.locationItem.hide();
        }
        else {
            var models = _.map(filters, function(d) {
                return new Backbone.Model({
                    code: d.code,
                    description: d.name,
                    filter: d,
                    removable: !d.removableDisabled
                });
            });

            this.ui.locationItem.show();
            this.locationsFiltersView.collection.reset(models);
        }
    }
});

FilterItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: function() {
        return this.model.get('className');
    },
    template: Handlebars.compile(filterItemTemplate),
    events: {
        'click .remove-filter': 'removeFilter'
    },
    removeFilter: function(ev) {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        this.$el.tooltip('destroy');
        this.trigger('removeFilter', this.model);
    },
    onRender: function() {
        if(this.model.get('fullText')) {
            this.$el.tooltip({
                title: this.model.get('fullText'),
                container: 'body'
            });
            this.$el.tooltip('show');
        }
    }
});

IPLookupDashboard = Marionette.Layout.extend({
    tagName: 'div',
    id: 'iplookup-dashboard',
    className: 'web-dashboard',
    template: Handlebars.compile(iplookupTemplate),
    regions: {
        TableRegion: '.iplookup-table-container',
        D3Region: '.iplookup-d3-container'
    },
    initialize: function(statsProvider) {
        this.statsProvider = statsProvider;
    },
    onRender: function() {
        this.d3 = new IPLookupD3View();
        this.D3Region.show(this.d3);

        this.table = new IPLookupTableView(this.statsProvider);
        this.TableRegion.show(this.table);

        var view = this;

        this.listenTo(this.table, 'iplookup:on-collection-fetched', function(collection) {
            view.d3.setInfo(collection);
        });
    },
    onShow: function() {
        $(window).on('resize', this.onWindowResize.bind(this));
    },
    updateFilters: function(filters) {
    },
    onWindowResize: function() {
        // if (this.data) {
        //     this.updateData(this, this.data);
        // }
    }
});

/*
 * This is the general overview dashboard
 * This view is a layout of other widgets.
 */
OverviewDashboard = Marionette.Layout.extend({
    tagName: 'div',
    id: 'web-overview-dashboard',
    className: 'web-dashboard',
    template: Handlebars.compile(overviewTemplate),
    regions: {
        statsReducedRegion: '.stats-reduced-container',
        sourcesRegion: '.sources-widget-container',
        contentRegion: '.content-widget-container'
    },
    initialize: function(statsProvider) {
        this.statsProvider = statsProvider;
    },
    onRender: function() {
        this.statsReduced = new WebStatsReducedView();
        this.sourcesWidget = new SourcesWidget();
        this.contentWidget = new ContentWidget();

        this.listenTo(this.statsReduced, 'setFilter', this.setFilter);
        this.listenTo(this.sourcesWidget, 'setFilter', this.setFilter);
        this.listenTo(this.contentWidget, 'setFilter', this.setFilter);

        this.statsReducedRegion.show(this.statsReduced);
        this.sourcesRegion.show(this.sourcesWidget);
        this.contentRegion.show(this.contentWidget);
    },
    onShow: function() {
        $(window).on('resize', this.onWindowResize.bind(this));
    },
    onWindowResize: function() {
        if (this.data) {
            this.updateData(this, this.data);
        }
    },
    setFilter: function(name, value) {
        // Pass up any filter setting from a widget
        this.trigger('setFilter', name, value);
    },
    updateFilters: function(filters) {
        this.statsReduced.updateFilters(filters);
        this.sourcesWidget.updateFilters(filters);
        this.contentWidget.updateFilters(filters);
        this.statsProvider.fetchData(filters, this, this.updateData);
    },
    updateData: function(context, data) {
        context.data = data;
        if (!context.statsReducedRegion || !context.sourcesRegion || !context.contentRegion)
        {
            return;
        }

        context.statsReduced.updateData(data.get('time'), data.get('segmentation'));
        context.sourcesWidget.updateData(data.get('sources'));
        context.contentWidget.updateData(data.get('visited_pages'));

        context.trigger('data', data);
    }
});


SourcesWidget = Marionette.Layout.extend({
    tagName: 'article',
    id: 'sources-container',
    template: Handlebars.compile(sourcesTemplate),
    ui: {
        chartContainer: '#sources-chart-container'
    },
    events: {
        'pagefilter': 'setSourceFilter'
    },
    onRender: function() {
        this.chart = sourceschart();

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    },
    setSourceFilter: function(ev, data) {
        var filter = {
            type: 'source',
            url: data.url,
            shortUrl: data.shortUrl,
            fullUrl: (data.serverURL.length === data.shortUrl.length) ? null : data.serverURL
        };
        this.trigger('setFilter', 'content', [filter]);
    },
    updateFilters: function() {},
    updateData: function(data) {
        if (!data) {
            return;
        }

        this.chart.data(data);
        this.chart.width(this.ui.chartContainer.width());
        this.chart.height(this.ui.chartContainer.height());

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    }
});

ContentWidget = Marionette.Layout.extend({
    tagName: 'article',
    id: 'pages-container',
    template: Handlebars.compile(contentTemplate),
    ui: {
        chartContainer: '#page-net-chart-container'
    },
    events: {
        'pagefilter': 'setPageFilter'
    },
    onRender: function() {
        this.chart = pagenetchart();

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    },
    setPageFilter: function(ev, data) {
        var filter = {
            type: 'page',
            url: data.url,
            shortUrl: data.shortPath,
            fullUrl: (data.path.length === data.shortPath.length) ? null : data.path
        };
        this.trigger('setFilter', 'content', [filter]);
    },
    updateFilters: function() {},
    updateData: function(data) {
        if (!data) {
            return;
        }

        this.chart.data(data);
        this.chart.width(this.ui.chartContainer.width());
        this.chart.height(this.ui.chartContainer.height());

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);
    }
});

LocationWidget = Marionette.Layout.extend({
    tagName: 'section',
    className: 'web-widget-full locations-widget-full',
    template: Handlebars.compile(locationsWidgetTemplate),
    ui: {
        chartContainer: '#world-chart-container',
        topLocationEntries: '#top-locations > ul > li'
    },
    events: {
        'countryfilter': 'setCountryFilter',
        'click .close': 'close',
        'click #top-locations > ul > li': 'setTopCountryFilter'
    },
    initialize: function(options) {
        this.data = options.data || null;
    },
    onRender: function() {
        var view = this;

        this.chart = worldchart();

        _.defer(function() {
            view.updateData(view.data);
        });
    },
    setCountryFilter: function(ev, data) {
        var filter = {
            code: data.code,
            name: data.name
        };
        this.trigger('setFilter', 'location', [filter]);
    },
    setTopCountryFilter: function(ev) {
        var code, filter;

        ev.preventDefault();
        ev.stopPropagation();

        code = $(ev.currentTarget).data('country-code');

        filter = {
            code: code,
            name: countryName(code)
        };

        this.trigger('setFilter', 'location', [filter]);
    },
    updateFilters: function() {},
    updateData: function(data) {
        if (!data) {
            return;
        }

        var COLORS = {
            'page-views': '#ffb2f8',
            'visits': '#00f1b0',
            'returning': '#eeff51',
            'registered': '#ff5b69'
        };

        this.data = data;

        this.updateTopCountries(data);

        this.chart.data(data.locations);
        this.chart.color(COLORS[data.segment]);
        this.chart.width(this.ui.chartContainer.width());
        this.chart.height(this.ui.chartContainer.height());

        d3.select(this.ui.chartContainer.get(0))
            .call(this.chart);

        this.$el.removeClass(function(index, css) {
            return (css.match(/\bsegment-\S+/g) || []).join(' ');
        });
        this.$el.addClass('segment-' + data.segment);
    },
    updateTopCountries: function(data) {
        var view = this;

        view.ui.topLocationEntries.hide();

        var count = view.ui.topLocationEntries.length;
        _.chain(data.locations)
            .map(function(value, key) {
                return {
                    code: key,
                    value: value,
                    name: countryName(key)
                };
            })
            .sortBy(function(d) {
                return -d.value;
            })
            .filter(function(value, key) {
                return key < count;
            })
            .each(function(value, key) {
                var entryEl = view.ui.topLocationEntries.eq(key);

                entryEl.data('country-code', value.code);
                entryEl.find('.location-title').text(value.name);
                entryEl.find('.value-bubble').text(value.value);
                entryEl.show();
            });
    }
});

export default WebView;

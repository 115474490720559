import React from 'react';

import PanelBase from './base';

import style from './configure_columns.css';


class AvailableItem extends React.Component {
    render() {
        let title = this.props.column.title;

        if (this.props.column.isCustomField) {
            title += ' (Custom Field)';
        } else if (this.props.column.isBucket) {
            title += ' (Bucket)'
        }

        return (
            <div className={style.item}>
                <div className={style.name}>{title}</div>
                <div
                    className={`
                        ${style.icon}
                        icon-plus-circle
                    `}
                    onClick={() => this.props.onAddColumm(this.props.column)}
                />
            </div>
        );
    }
}

class SelectedItem extends React.Component {
    render() {
        let title = this.props.column.title;

        if (this.props.column.isCustomField) {
            title += ' (Custom Field)';
        } else if (this.props.column.isBucket) {
            title += ' (Bucket)'
        }

        return (
            <div
                id={this.props.column.id}
                ref={(el) => this.component = el}
                className={style.item}
            >
                <div className={style.handle}><div/></div>

                <div className={style.name}>{title}</div>

                <div
                    className={`
                        ${style.icon}
                        icon-minus-circle
                    `}
                    onClick={() => this.props.onDeleteColumm(this.props.column)}
                />
            </div>
        );
    }
}

export default class ConfigureColumnsPanel extends PanelBase {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0,
            filter: '',
            availableColumns: [],
            selectedColumns: [],
            filteredColumns: []
        };

        this.sortableInitialized = false;
    }

    componentWillMount() {
        super.componentWillMount();

        this.setTitle('Configure Table Columns');
        this.showCloseButton(true);
    }

    componentWillUnmount() {
        if (this.sortableInitialized) {
            $(this.selectedColumnContainer).sortable('destroy');
        }
    }

    initSortableColumn() {
        const self = this;

        _.defer(function() {
            const el = $(self.selectedColumnContainer);

            el.sortable({
                axis: 'y',
                items: `.${style.item}`,
                handle: `.${style.handle}`,
                helper: 'clone',
                start: function(_, ui) {
                    ui.helper.addClass(style.beingSorted);
                }
            });

            self.sortableInitialized = true;
        });
    }

    show(selectedColumns) {
        let initialColumns = [];
        let atBeginning = true;

        this.fixedColumns = [];

        // the fixed columns only can be at the beginning or the end of the columns
        for (const c of selectedColumns) {
            if (c.fixed) {
                if (c.type !== 'select') {
                    this.fixedColumns.push({
                        column: c,
                        atBeginning: atBeginning
                    });
                }
            } else {
                initialColumns.push(c);
                atBeginning = false;
            }
        }

        this.initialColumns = JSON.stringify(initialColumns);
        this.allColumns = this.props.allColumns.filter(c => !c.fixed);

        this.setState({
            counter: this.state.counter + 1,
            availableColumns: this.allColumns.filter(c => !initialColumns.find(sc => sc.id === c.id)),
            selectedColumns: initialColumns,
            filteredColumns: []
        });

        this.initSortableColumn();
        super.show();
    }

    handleColumnAdd(column) {
        const selectedColumns = [...this.state.selectedColumns, column];

        let newState = {
            availableColumns: this.allColumns.filter(c => !selectedColumns.find(sc => sc.id === c.id)),
            selectedColumns: selectedColumns
        };

        if (this.state.filter) {
            newState.filteredColumns = newState.availableColumns.filter(c => c.title.toLowerCase().includes(this.state.filter.toLowerCase()))
        }

        this.setState(newState);
        this.initSortableColumn();
    }

    handleColumnDelete(column) {
        const selectedColumns = this.state.selectedColumns.filter(c => c.id !== column.id);

        let newState = {
            availableColumns: this.allColumns.filter(c => !selectedColumns.find(sc => sc.id === c.id)),
            selectedColumns: selectedColumns
        };

        if (this.state.filter) {
            newState.filteredColumns = newState.availableColumns.filter(c => c.title.toLowerCase().includes(this.state.filter.toLowerCase()))
        }

        this.setState(newState);
        this.initSortableColumn();
    }

    handleClearAll() {
        let newState = {
            availableColumns: _.clone(this.allColumns),
            selectedColumns: []
        };

        if (this.state.filter) {
            newState.filteredColumns = newState.availableColumns.filter(c => c.title.toLowerCase().includes(this.state.filter.toLowerCase()))
        }

        this.setState(newState);
        this.initSortableColumn();
    }

    handleFilterChange(ev) {
        let newState = {
            filter: ev.target.value
        };

        if (newState.filter) {
            newState.filteredColumns = this.state.availableColumns.filter(c => c.title.toLowerCase().includes(newState.filter.toLowerCase()))
        } else {
            newState.filteredColumns = [];
        }

        this.setState(newState);
    }

    handleSave() {
        if (this.state.selectedColumns.length === 0) {
            return;
        }

        // get the real order for the items
        let selectedColumns = [];
        const container = $(this.selectedColumnContainer);

        for (const item of container.children()) {
          const id = $(item).attr('id');

          if (!id) {
            continue;
          }

          selectedColumns.push(this.allColumns.find(c => c.id === id));
        }

        if (JSON.stringify(selectedColumns) !== this.initialColumns) {
            // add fixed columns
            for (const fc of this.fixedColumns) {
                if (fc.atBeginning) {
                    selectedColumns.unshift(fc.column);
                } else {
                    selectedColumns.push(fc.column);
                }
            }

            this.props.onSelectedColumnsChange(selectedColumns);
        }

        this.hide();
    }

    getContent() {
        const availableColumns = this.state.filter ? this.state.filteredColumns : this.state.availableColumns;

        return (
            <div
                key={`configure_columns_${this.state.counter}`}
                className={style.configureColumns}
            >
                <div className={style.columnsContainer}>
                    <div className={style.column}>
                        <div className={style.title}>Add Columns</div>
                        <input
                            className={style.filter}
                            value={this.state.filter}
                            placeholder='Search Property'
                            onChange={this.handleFilterChange.bind(this)}
                        />

                        <div className={style.itemsContainer}>
                            {availableColumns.map(column => {
                                return (
                                    <AvailableItem
                                        key={`ca_${column.id}`}
                                        column={column}
                                        onAddColumm={this.handleColumnAdd.bind(this)}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div className={style.column}>
                        <div className={style.title}>Selected Columns</div>

                        <div
                            ref={(el) => this.selectedColumnContainer = el}
                            className={style.itemsContainer}
                            style={{marginTop: '62px'}}
                        >
                            {this.state.selectedColumns.map(column => {
                                return (
                                    <SelectedItem
                                        key={`cs_${column.id}`}
                                        column={column}
                                        onDeleteColumm={this.handleColumnDelete.bind(this)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={style.footer}>
                    <div
                        className={`
                            ${style.button}
                            ${style.blue}
                        `}
                        onClick={this.handleClearAll.bind(this)}
                    >
                        Clear All
                    </div>

                    <div
                        className={`
                            ${style.button}
                            ${style.green}
                            ${this.state.selectedColumns.length === 0 ? style.disabled : ''}
                        `}
                        onClick={this.handleSave.bind(this)}
                    >
                        Save
                    </div>
                </div>
            </div>
        );
    }
}

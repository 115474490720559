import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import BaseItemView from 'js/views/base/item'
import dateFormat from 'js/utils/date-format'
import Utils from 'js/utils/utilities'
import ModalRegion from 'js/views/base/modal-region'
import MessageBox from 'js/views/message_box'
import EmailViewer from 'js/views/activity/email-viewer'
import security from 'js/utils/security'
import template from 'templates/activity/email-item.handlebars'

var TagItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'tag',
    template: Handlebars.compile('{{name}}'),
});

export default BaseItemView.extend({
    template: Handlebars.compile(template),
    regions: {
        emailViewer: {
            selector: '.email-viewer',
            regionType: ModalRegion.extend()
        },
        tagsRegion: '.tags-container'
    },
    ui: {
        activityPinned: '.activity-pinned',
        iconPinned: '.icon-pinned',
        iconUnpinned: '.icon-unpinned'
    },
    events: _.extend({
        'click .view': function(ev) {
            ev.stopPropagation();

            var viewer = new EmailViewer({model: this.model});
            var view = this;

            this.listenTo(viewer, 'email-viewer:close', function() {
                view.emailViewer.reset();
            });

            this.emailViewer.show(viewer);
        },
        'click .delete.has-permission': function() {
            var self = this;
            var mbContent = {
                accept_is_negative: true,
                message: 'Are you sure you want to <strong>permanently</strong> delete the activity?',
                icon: 'icon-trashcan'
            };

            MessageBox.showYesNo(mbContent, this, function() {
                self.$el
                    .animate({ opacity: 0 }, { queue: false, duration: 200 })
                    .slideUp(250, 'easeOutQuint', function() {
                        self.trigger('deleteItem');
                    });
            });
        },
        'click .pin.has-permission': function() {
            this.trigger('togglePin');
        }
    }, BaseItemView.prototype.events),
    templateHelpers: function() {
        var createdDate = this.model.get('created');
        var params = this.model.get('params') || {};

        if (createdDate) {
            createdDate = dateFormat.timelineFormat(new Date(createdDate));
        }

        var at = this.model.get('activity_type');
        var contact_name = '';

        if (at === 'archive:email') {
            contact_name = this.model.get('individual_name') || this.model.get('organization_name');
        }
        else {
            contact_name = this.model.get('opportunity_name');
        }

        if (params.created_from_data_capture === 'true' || params.created_from_loan_capture === 'true') {
            author = params.from_email;
            target_name = contact_name
        }
        else {
            var creator = this.model.get('creator');
            var user_str = '';

            if (creator) {
                user_str = creator['name'] || creator['email_address'];
            } else {
                user_str = app.user.get('name') || app.user.get('email_address');
            }

            var target_name = '';
            var author = '';

            if (params.from_contact === 'true') {
                author = contact_name;
                target_name = (function() {
                    var names,
                        last_name,
                        recipients = '';

                    if (_.isArray(params.to_names) && params.to_names.length) {
                        names = params.to_names.slice();
                        last_name = names.pop();
                        if (names.length) {
                            recipients = names.join(', ') + ' and ';
                        }
                        recipients += last_name;
                    }
                    else {
                        recipients = user_str;
                    }

                    return recipients;
                })();
            }
            else if (params.from_name) {
                author = params.from_name;
                target_name = contact_name;
                if (params.to_names) {
                    target_name = params.to_names[0];
                }
            }
            else {
                author = user_str;
                target_name = contact_name;
            }
        }

        var numberAttachments = null;

        if (params.attachments_ids) {
            var len = params.attachments_ids.length;
            numberAttachments = len + (len === 1 ? ' file' : ' files');
        }

        var ownerId = this.model.get('owner_id');

        if (!ownerId) {
            var owner = this.model.get('owner');

            if (owner) {
                ownerId = owner['id'];
            }
        }

        var note = this.model.get('note') || '';

        if (Utils.isHTML(note)) {
            note = Utils.getTextFromHTMLContent(note);
        }

        note = Utils.replaceAll(note, '<br>', '');
        note = Utils.replaceAll(note, '↵', '');

        var maxNoteLen = 200;

        if (note.length > maxNoteLen) {
            var ellipsis = '...';
            note = note.substring(0, maxNoteLen - ellipsis.length) + ellipsis;
        }

        var createdFromDataCapture = params.created_from_data_capture;
        var createdFromLoanCapture = params.created_from_loan_capture;

        var related = this.model.get('related');
        var allowEdit = (app.user.get('id') === ownerId) || app.user.get('is_helper');

        if (!allowEdit && related) {
            allowEdit = security.checkPermission('edit', {
                id: app.user.get('id'),
                permissions: related.permissions
            });
        }

        return {
            target_name: target_name,
            formattedDate: createdDate,
            createdFromEmailCapture: createdFromDataCapture || createdFromLoanCapture,
            createdFromLoanCapture: createdFromLoanCapture,
            author: author,
            subject: params.subject,
            body: note,
            individualDeleted: this.model.get('individual_deleted'),
            numberAttachments: numberAttachments,
            editable: this.options.parentModel,
            allowEdit: allowEdit,
            pinned: this.model.get('pinned')
        };
    },
    onRender: function () {
        this.updatePinView();
        this.tagsRegion.show(new Marionette.CollectionView({
            tagName: 'ul',
            itemView: TagItemView,
            collection: new Backbone.Collection(this.model.get('tags'))
        }));
    },
    updatePinView: function() {
        var pinned = this.model.get('pinned');

        this.ui.activityPinned.toggleClass('hide', !pinned);
        this.ui.iconPinned.toggleClass('hide', !pinned);
        this.ui.iconUnpinned.toggleClass('hide', pinned);
    }
});

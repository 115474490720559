import _ from 'underscore';
import React from 'react';
import Juice from 'juice';
import Handlebars from 'handlebars'
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import ace from 'ace';
import beautify from 'js-beautify';
import guid from 'js/utils/guid'

import MessageBox from 'js/views/message_box'
import LibraryBrowser from 'js/react_views/library-browser/browser'
import Utilities from 'js/utils/utilities';

import {NewSelect} from 'js/react_views/widgets/select';

import style from './grapesjs-editor.css';

const Blocks = [{
  id: '1Block',
  name: '1 Block',
  category: 'Content Blocks',
  content: `
        <table width="100%" style="background-color: #ffffff; box-sizing: border-box; width: 100%; height: auto; min-height: 50px; margin-top: 0px; margin-right: auto; margin-left: auto; padding: 5px; font-family: Arial, Helvetica, sans-serif; font-weight: 400; max-width: 600px; border:none;">
          <tbody>
            <tr><td></td></tr>
          </tbody>
        </table>`,
  attributes: {class: 'icon-block'}
}, {
  id: '1-2Blocks',
  name: '1/2 Blocks',
  category: 'Content Blocks',
  content: `<table dir="ltr" width="100%"  style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" >
    <tbody>
        <tr>
          <td valign="top" width="50%" dir="ltr" class="full" style="padding: 18px;">
            <table width="100%" style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border:none;">
              <tbody>
                <tr><td><div>Left content block - edit or delete this text.</div></td></tr>
              </tbody>
            </table>
          </td>
          <td valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 18px;">
            <table width="100%" style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border:none;">
              <tbody>
                <tr><td><div>Right content block - edit or delete this text.</div></td></tr>
              </tbody>
            </table>
          </td>
        </tr>
    </tbody>
    <!--[if (gte mso 9)|(IE)]>
    </td>
    </tr>
    </table>
    <![endif]-->
    <!-- // END TEMPLATE -->
  </table>
  <style>
      @media only screen and (max-width: 480px) {
          .full {
              display: block;
              width: 100%;
          }
      }
  </style>`,
  attributes: {class: 'icon-2-blocks'}
}, {
  id: 'heading',
  name: 'Heading',
  category: 'Text Blocks',
  attributes: {class: 'icon-typ-text'},
  content: `
  <table dir="ltr" width="100%"  style="max-width: 600px; margin: auto; background-color: #ffffff;" border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="heading">
    <tbody data-gjs-name="heading">
      <tr data-gjs-name="heading">
        <td valign="top" data-gjs-name="heading">
          <!--[if mso]>
          <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="heading">
          <tr>
          <![endif]-->
          <!--[if mso]>
          <td valign="top" width="600" style="width:600px;" data-gjs-name="heading">
          <![endif]-->
          <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" data-gjs-name="heading">
            <tbody data-gjs-name="heading">
              <tr data-gjs-name="heading">          
                <td valign="top" style="box-sizingƒ: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 26px; font-weight: 400; border:none;" data-gjs-name="heading">
                  <div data-gjs-name="heading">A spot for your header</div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--[if mso]>
          </td>
          <![endif]-->
                  
          <!--[if mso]>
          </tr>
          </table>
          <![endif]-->
        </td>
      </tr>
    </tbody>
  </table>`,
}, {
  id: 'text',
  name: 'Text',
  category: 'Text Blocks',
  content: `
  <table dir="ltr" width="100%"  style="max-width: 600px; margin: auto; background-color: #ffffff;" border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="text">
    <tbody data-gjs-name="text">
      <tr data-gjs-name="text">
        <td valign="top" data-gjs-name="text">
          <!--[if mso]>
          <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="text">
          <tr data-gjs-name="text">
          <![endif]-->
            
          <!--[if mso]>
          <td valign="top" width="600" style="width:600px;" data-gjs-name="text">
          <![endif]-->
          <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" data-gjs-name="text">
            <tbody data-gjs-name="text">
              <tr data-gjs-name="text">          
                <td valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border:none" data-gjs-name="text">
                  <div data-gjs-name="text">Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...</div>
                </td>
              </tr>
            </tbody>
          </table>
          <!--[if mso]>
          </td>
          <![endif]-->
                  
          <!--[if mso]>
          </tr>
          </table>
          <![endif]-->
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-typ-justified'}
}, {
  id: 'imageText',
  name: 'Image + Text',
  category: 'Text Blocks',
  content: `<table dir="ltr" width="100%"  style=" width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="image-text">
    <tbody data-gjs-name="image-text">
        <tr data-gjs-name="image-text">
            <td valign="top" width="50%" dir="ltr" class="full" style="padding: 18px; text-align: center;" data-gjs-name="image-text">
              <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" data-gjs-name="image-text">
                <tbody data-gjs-name="image-text">
                  <tr data-gjs-name="image-text">          
                    <td data-gjs-name="image-text">
                      <a style="display: inline-block; padding: 0px 18px 18px 18px; width: 100%;" data-gjs-name="link-image"><img data-gjs-name="image-text" class="image-text" width="300" height="auto"></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 0px 18px 18px 18px;" data-gjs-name="image-text">
              <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" data-gjs-name="image-text">
                <tbody data-gjs-name="image-text">
                  <tr data-gjs-name="image-text">          
                    <td data-gjs-name="image-text">
                      <div style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-top: 20px; line-height: 150%; font-weight: 400; border:none;" data-gjs-name="image-text">Insert your text in this block. You can use it to add text to your template.<br><br> Edit the image by double clicking, selecting or uploading an image.</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
        </tr>
    </tbody>
  </table>
  <style>
    .image-text {
      display: block;
      margin: auto !important;
      width: 100% !important;
      height: auto !important;
    }
  </style>`,
  attributes: {class: 'icon-typ-list-single'}
}, {
  id: 'boxedText',
  name: 'Boxed Text',
  category: 'Text Blocks',
  content: `
  <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%"  style="max-width: 600px; margin: auto; background-color: #ffffff;" width="100%" data-gjs-name="boxed-text">
    <!--[if gte mso 9]>
    <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" data-gjs-name="boxed-text">
    <![endif]-->
    <tbody data-gjs-name="boxed-text">
      <tr data-gjs-name="boxed-text">
        <td valign="top" data-gjs-name="boxed-text">
          <!--[if gte mso 9]>
          <td align="center" valign="top" data-gjs-name="boxed-text">
          <![endif]-->
          <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:100%;" data-gjs-name="boxed-text">
            <tbody data-gjs-name="boxed-text">
              <tr data-gjs-name="boxed-text">
                <td style="padding-left:18px; padding-bottom:18px; padding-right:18px;" data-gjs-name="boxed-text">
                  <table border="0" cellspacing="0" width="100%" style="box-sizing: border-box; background-color: #404040;" data-gjs-name="boxed-text">
                    <tbody data-gjs-name="boxed-text">
                      <tr data-gjs-name="boxed-text">
                        <td valign="top" style="font-size: 14px; font-weight: 400; font-family: Arial, Helvetica, sans-serif; color: #ffffff; line-height: 150%; padding: 18px; border:none;" data-gjs-name="boxed-text">
                          <div data-gjs-name="boxed-text">Insert your text in this coloured block. Edit the background colour in the Style > Decoration panel.</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <!--[if gte mso 9]>
          </td>
          <![endif]-->
                  
          <!--[if gte mso 9]>
                  </tr>
                  </table>
          <![endif]-->
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-typ-shape'}
}, {
  id: '1-2TextGrid',
  name: '1/2 Text Grid',
  category: 'Text Blocks',
  content: `<table dir="ltr" width="100%"  style=" width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="1/2-text-grid">
    <tbody data-gjs-name="1/2-text-grid">
        <tr data-gjs-name="1/2-text-grid">
            <td valign="top" width="50%" dir="ltr" class="full" style="padding: 18px;" data-gjs-name="1/2-text-grid">
                <div style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400;" data-gjs-name="1/2-text-grid">
                  Left box. Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                </div>
            </td>
            <td valign="top" width="50%" dir="ltr" class="full" style="box-sizing: border-box; padding: 18px;" data-gjs-name="1/2-text-grid">
                <div style="box-sizing: border-box; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400;" data-gjs-name="1/2-text-grid">
                  Right box. Insert your text in this block. You can use it to add text to your template. To text or not to text, that is the question...
                </div>
            </td>
        </tr>
    </tbody>
  </table>
  <style>
      @media only screen and (max-width: 480px) {
          .full {
              display: block;
              width: 100%;
          }
      }
  </style>`,
  attributes: {class: 'icon-typ-columns-two'}
}, {
  id: 'image',
  name: 'Image',
  category: 'Multimedia',
  content: `
  <table dir="ltr" width="100%"  border="0" cellpadding="0" cellspacing="0"  style="max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="image">
    <tbody data-gjs-name="image">
      <tr data-gjs-name="image">
        <td valign="top" data-gjs-name="image">
          <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%;" data-gjs-name="image">
            <tbody data-gjs-name="image">
              <tr data-gjs-name="image">
                <td valign="top" style="padding-top: 0; padding-bottom: 0; text-align:center;" data-gjs-name="image">
                  <a style="display: inline-block; padding: 18px; width: 100%; display: block; width: 100% !important; height: auto !important; max-width:600px !important" data-gjs-name="link-image"><img width="600" height="auto" data-gjs-name="image" style="max-width: 564px"/></a>        
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-link-image'}
}, {
  id: 'video',
  name: 'Video',
  type: 'video',
  content: `<table dir="ltr" width="100%"  style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="video">
    <tbody data-gjs-name="video">
      <tr data-gjs-name="video">
        <td data-gjs-name="video">
          <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" style="min-width:100%;" data-gjs-name="video">
            <tbody data-gjs-name="video">
              <tr data-gjs-name="video">
                <td valign="top" data-gjs-name="video">
                  <video width="100%" controls data-gjs-name="video">Your browser does not support the video tag.</video>     
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`,
  category: 'Multimedia',
  attributes: {class: 'icon-fil-movie'}
}, {
  id: 'socials',
  name: 'Socials',
  category: 'Multimedia',
  content: `
  <table border="0" width="100%" align="center" cellpadding="0" cellspacing="0" style="box-sizing: border-box; width: 100%; max-width: 600px; background-color: #ffffff;" data-gjs-name="social">
    <tbody data-gjs-name="social">
      <tr data-gjs-name="social">
        <td align="center" valign="top" style="padding-top:9px; padding-right:9px; padding-left:9px;" data-gjs-name="social">
          <table border="0" width="100%" align="center" cellpadding="0" cellspacing="0" data-gjs-name="social">
            <tbody data-gjs-name="social">
              <tr data-gjs-name="social">
                <td align="center" valign="top" data-gjs-name="social">
                  <!--[if mso]>
                  <table align="center" border="0" cellspacing="0" cellpadding="0" data-gjs-name="social">
                  <tr data-gjs-name="social">
                  <![endif]-->
                
                  <!--[if mso]>
                  <td align="center" valign="top" data-gjs-name="social">
                  <![endif]-->
                  <table border="0" align="center" cellpadding="0" cellspacing="0" style="display: inline;" data-gjs-name="social">
                    <tbody data-gjs-name="social">
                      <tr data-gjs-name="social">
                        <td valign="top" style="padding-right:10px; padding-bottom:9px;" data-gjs-name="social">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="social">
                            <tbody data-gjs-name="social">
                              <tr data-gjs-name="social">
                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;" data-gjs-name="social">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" data-gjs-name="social">
                                    <tbody data-gjs-name="social">
                                      <tr data-gjs-name="social">
                                        <td align="center" valign="middle" width="24" data-gjs-name="social">
                                          <a href="https://www.linkedin.com/" style="text-decoration: none; border: 0;" data-gjs-name="social">
                                            <img src="https://public.salesseek.net/salesseek/ss-linkedin-128.png" alt="#" border="0" width="20" style="display: block; border: 0; width: 20px;" data-gjs-name="social">
                                          </a>
                                        </td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                  <!--[if mso]>
                  <td align="center" valign="top" data-gjs-name="social">
                  <![endif]-->
                  <table border="0" align="center" cellpadding="0" cellspacing="0" style="display: inline;" data-gjs-name="social">
                    <tbody data-gjs-name="social">
                      <tr data-gjs-name="social">
                        <td valign="top" style="padding-right:10px; padding-bottom:9px;" data-gjs-name="social">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="social">
                            <tbody data-gjs-name="social">
                              <tr data-gjs-name="social">
                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;" data-gjs-name="social">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" data-gjs-name="social">
                                    <tbody data-gjs-name="social">
                                      <tr data-gjs-name="social">
                                        <td align="center" valign="middle" width="24" data-gjs-name="social">
                                          <a href="https://facebook.com/" style="text-decoration: none; border: 0;" data-gjs-name="social">
                                            <img src="https://public.salesseek.net/salesseek/ss-fb-128.png" alt="#" border="0" width="20" style="display: block; width: 20px;" data-gjs-name="social">
                                          </a>
                                        </td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                  <!--[if mso]>
                  <td align="center" valign="top" data-gjs-name="social">
                  <![endif]-->
                  <table border="0" align="center" cellpadding="0" cellspacing="0" style="display: inline;" data-gjs-name="social">
                    <tbody data-gjs-name="social">
                      <tr data-gjs-name="social">
                        <td valign="top" style="padding-right:10px; padding-bottom:9px;" data-gjs-name="social">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="social">
                            <tbody data-gjs-name="social">
                              <tr data-gjs-name="social">
                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;" data-gjs-name="social">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" data-gjs-name="social">
                                    <tbody data-gjs-name="social">
                                      <tr data-gjs-name="social">
                                        <td align="center" valign="middle" width="24" data-gjs-name="social">
                                          <a href="https://twitter.com/" style="text-decoration: none;" data-gjs-name="social">
                                            <img src="https://public.salesseek.net/salesseek/ss-twitter-128.png" alt="#" border="0" width="20" style="display: block; width: 20px;" data-gjs-name="social">
                                          </a>
                                        </td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                  <!--[if mso]>
                  <td align="center" valign="top" data-gjs-name="social">
                  <![endif]-->
                  <table border="0" align="center" cellpadding="0" cellspacing="0" style="display: inline;" data-gjs-name="social">
                    <tbody data-gjs-name="social">
                      <tr data-gjs-name="social">
                        <td valign="top" style="padding-right:10px; padding-bottom:9px;" data-gjs-name="social">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="social">
                            <tbody data-gjs-name="social">
                              <tr data-gjs-name="social">
                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;" data-gjs-name="social">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" data-gjs-name="social">
                                    <tbody data-gjs-name="social">
                                      <tr data-gjs-name="social">
                                        <td align="center" valign="middle" width="24" data-gjs-name="social">
                                          <a href="https://instagram.com/" style="text-decoration: none;" data-gjs-name="social">
                                            <img src="https://public.salesseek.net/salesseek/ss-insta-128.png" alt="#" border="0" width="20" style="display: block; width: 20px;" data-gjs-name="social">
                                          </a>
                                        </td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                  <!--[if mso]>
                  <td align="center" valign="top" data-gjs-name="social">
                  <![endif]-->
                  <table border="0" align="center" cellpadding="0" cellspacing="0" style="display: inline;" data-gjs-name="social">
                    <tbody data-gjs-name="social">
                      <tr data-gjs-name="social">
                        <td valign="top" style="padding-right:10px; padding-bottom:9px;" data-gjs-name="social">
                          <table border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="social">
                            <tbody data-gjs-name="social">
                              <tr data-gjs-name="social">
                                <td align="left" valign="middle" style="padding-top:5px; padding-right:10px; padding-bottom:5px; padding-left:9px;" data-gjs-name="social">
                                  <table align="left" border="0" cellpadding="0" cellspacing="0" data-gjs-name="social">
                                    <tbody data-gjs-name="social">
                                      <tr data-gjs-name="social">
                                        <td align="center" valign="middle" width="24" data-gjs-name="social">
                                          <a href="https://youtube.com/" style="text-decoration: none;" data-gjs-name="social">
                                            <img src="https://public.salesseek.net/strengthspartnership/download.png" alt="#" border="0" width="20" style="display: block; width: 20px;" data-gjs-name="social">
                                          </a>
                                        </td>                         
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!--[if mso]>
                  </td>
                  <![endif]-->
                  <!--[if mso]>
                  </tr>
                  </table>
                  <![endif]-->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-social-share'}
}, {
  id: 'button',
  name: 'Button',
  category: 'Utilities',
  content: `
  <table dir="ltr" border="0" cellpadding="0" cellspacing="0" width="100%"  style="max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="button">
    <tbody data-gjs-name="button">
      <tr data-gjs-name="button">
        <td valign="top" align="center" align="center" valign="middle" style="padding-top:18px; padding-right:18px; padding-bottom:18px; padding-left:18px;" data-gjs-name="button">
          <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate !important;" data-gjs-name="button">
            <tbody data-gjs-name="button">
                <tr data-gjs-name="button">
                  <td align="center" valign="middle" style="box-sizing: border-box; padding-top: 8px; padding-bottom: 8px; padding-left: 15px; padding-right: 15px; border-radius: 5px;background-color: #2cce27;" data-gjs-name="button">
                    <a href="#" target="_blank" style="font-weight: 700;letter-spacing: normal;line-height: 40px;text-align: center;text-decoration: none;color: #FFFFFF;" data-gjs-name="button">Double Click To Change</a>
                  </td>
                </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-ui-button'}
}, {
  id: 'divider',
  name: 'Divider',
  category: 'Utilities',
  content: `<table dir="ltr" width="100%"  style="width: 100%; max-width: 600px; margin: auto; background-color: #ffffff;" data-gjs-name="divider">
    <tbody data-gjs-name="divider">
      <tr data-gjs-name="divider">
        <td style="min-width:100%; padding:18px;" data-gjs-name="divider">
          <hr style="height:1px; border:none; color:#eee; background-color:#eee;" data-gjs-name="divider"/>
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-lay-reflect-vertical'}
}, {
  id: 'blankSpace',
  name: 'Blank Space',
  category: 'Utilities',
  content: `<table dir="ltr" width="100%"  style="width: 100%; max-width: 600px; margin: auto;" data-gjs-name="blank-space">
    <tbody data-gjs-name="blank-space">
      <tr data-gjs-name="blank-space">
        <td data-gjs-name="blank-space">
          <table border="0" cellpadding="0" cellspacing="0" width="100%" style="height: 1px; padding: 25px 0;" data-gjs-name="blank-space">
            <tbody data-gjs-name="blank-space">
              <tr data-gjs-name="blank-space">
                <td data-gjs-name="blank-space">
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>`,
  attributes: {class: 'icon-lay-rectangle-marquee'}
}, {
  id: 'html-code',
  name: 'HTML Code',
  category: 'Utilities',
  content: `<div data-html-code data-gjs-name="html-code">Modify this HTML code in Settings</div>`,
  attributes: {class: 'icon-fil-code'}
},{
  id: 'footer',
  name: 'Footer',
  category: 'Utilities',
  content: `<table dir="ltr" width="100%"  style="max-width: 600px; margin: auto; background-color: #ffffff;" border="0" cellpadding="0" cellspacing="0" width="100%" data-gjs-name="footer">
  <tbody data-gjs-name="footer">
    <tr data-gjs-name="footer">
      <td valign="top" data-gjs-name="footer">
        <!--[if mso]>
        <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;" data-gjs-name="footer">
        <tr data-gjs-name="footer">
        <![endif]-->
          
        <!--[if mso]>
        <td valign="top" width="600" style="width:600px;" data-gjs-name="footer">
        <![endif]-->
        <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%; min-width:100%;" width="100%" data-gjs-name="footer">
          <tbody data-gjs-name="footer">
            <tr data-gjs-name="footer">          
              <td valign="top" style="box-sizing: border-box; padding: 0px 18px 18px 18px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 150%; font-weight: 400; border:none" data-gjs-name="footer">
              <div style="font-family: Arial; box-sizing: border-box; font-weight: 400; text-align: center; font-size: 11px; color: #555555; letter-spacing: normal;" data-gjs-name="footer">
                <em draggable="true" data-highlightable="1" style="box-sizing: border-box;" data-gjs-name="footer">Copyright ©  All rights reserved.</em>
                <br draggable="true" data-highlightable="1" style="box-sizing: border-box;" data-gjs-name="footer">
                <a draggable="true" data-highlightable="1" href="\${unsubscribe}" target="_blank" style="box-sizing: border-box;" data-gjs-name="footer">Unsubscribe from this list</a>.<br draggable="true" data-highlightable="1" data-gjs-name="footer" style="box-sizing: border-box;">
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!--[if mso]>
        </td>
        <![endif]-->
                
        <!--[if mso]>
        </tr>
        </table>
        <![endif]-->
      </td>
    </tr>
  </tbody>
</table>`,
  attributes: {class: 'icon-footer-block'}
}]

const Units = {
  spacing: [
    {id: 'pixel',       name: 'px'},
    {id: 'em',          name: 'em'},
    {id: 'rem',         name: 'rem'},
    {id: 'percentage',  name: '%'}
  ],
  size: [
    {id: 'pixel',       name: 'px'},
    {id: 'percentage',  name: '%'},
    {id: 'vw',          name: 'vw'}
  ],
  fontSize: [
    {id: 'pixel',       name: 'px'},
    {id: 'percentage',  name: '%'},
    {id: 'vh',          name: 'vh'}
  ],
  borderRadius: [
    {id: 'pixel',       name: 'px'},
    {id: 'percentage',  name: '%'}
  ]
};

const Fonts = [
  {id: 'arial',             name: 'Arial'},
  {id: 'arialBlack',        name: 'Arial Black'},
  {id: 'brushScriptMt',     name: 'Brush Script MT'},
  {id: 'comicSansMs',       name: 'Comic Sans MS'},
  {id: 'courierNew',        name: 'Courier New'},
  {id: 'georgia',           name: 'Georgia'},
  {id: 'helvetica',         name: 'Helvetica'},
  {id: 'impact',            name: 'Impact'},
  {id: 'lucidaSansUnicode', name: 'Lucida Sans Unicode'},
  {id: 'tahoma',            name: 'Tahoma'},
  {id: 'timesNewRoman',     name: 'Times New Roman'},
  {id: 'trebuchetMs',       name: 'Trebuchet MS'},
  {id: 'verdana',           name: 'Verdana'},

  // Google fonts
  {id: 'lato',              name: 'Lato',               link: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'},
  {id: 'lora',              name: 'Lora',               link: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap'},
  {id: 'merriweather',      name: 'Merriweather',       link: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'},
  {id: 'merriweatherSans',  name: 'Merriweather Sans',  link: 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,700;0,800;1,300;1,400;1,700;1,800&display=swap'},
  {id: 'noticiaText',       name: 'Noticia Text',       link: 'https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap'},
  {id: 'openSans',          name: 'Open Sans',          link: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap'},
  {id: 'playfairDisplay',   name: 'Playfair Display',   link: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'},
  {id: 'roboto',            name: 'Roboto',             link: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'},
  {id: 'sourceSansPro',     name: 'Source Sans Pro',    link: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'}
];

const Colors = {
  black: '#000000',
  navy: '#000080',
  darkblue: '#00008b',
  mediumblue: '#0000cd',
  blue: '#0000ff',
  darkgreen: '#006400',
  green: '#008000',
  teal: '#008080',
  darkcyan: '#008b8b',
  deepskyblue: '#00bfff',
  darkturquoise: '#00ced1',
  mediumspringgreen: '#00fa9a',
  lime: '#00ff00',
  springgreen: '#00ff7f',
  aqua: '#00ffff',
  cyan: '#00ffff',
  midnightblue: '#191970',
  dodgerblue: '#1e90ff',
  lightseagreen: '#20b2aa',
  forestgreen: '#228b22',
  seagreen: '#2e8b57',
  darkslategray: '#2f4f4f',
  darkslategrey: '#2f4f4f',
  limegreen: '#32cd32',
  mediumseagreen: '#3cb371',
  turquoise: '#40e0d0',
  royalblue: '#4169e1',
  steelblue: '#4682b4',
  darkslateblue: '#483d8b',
  mediumturquoise: '#48d1cc',
  indigo: '#4b0082',
  darkolivegreen: '#556b2f',
  cadetblue: '#5f9ea0',
  cornflowerblue: '#6495ed',
  rebeccapurple: '#663399',
  mediumaquamarine: '#66cdaa',
  dimgray: '#696969',
  dimgrey: '#696969',
  slateblue: '#6a5acd',
  olivedrab: '#6b8e23',
  slategray: '#708090',
  slategrey: '#708090',
  lightslategray: '#778899',
  lightslategrey: '#778899',
  mediumslateblue: '#7b68ee',
  lawngreen: '#7cfc00',
  chartreuse: '#7fff00',
  aquamarine: '#7fffd4',
  maroon: '#800000',
  purple: '#800080',
  olive: '#808000',
  gray: '#808080',
  grey: '#808080',
  skyblue: '#87ceeb',
  lightskyblue: '#87cefa',
  blueviolet: '#8a2be2',
  darkred: '#8b0000',
  darkmagenta: '#8b008b',
  saddlebrown: '#8b4513',
  darkseagreen: '#8fbc8f',
  lightgreen: '#90ee90',
  mediumpurple: '#9370db',
  darkviolet: '#9400d3',
  palegreen: '#98fb98',
  darkorchid: '#9932cc',
  yellowgreen: '#9acd32',
  sienna: '#a0522d',
  brown: '#a52a2a',
  darkgray: '#a9a9a9',
  darkgrey: '#a9a9a9',
  lightblue: '#add8e6',
  greenyellow: '#adff2f',
  paleturquoise: '#afeeee',
  lightsteelblue: '#b0c4de',
  powderblue: '#b0e0e6',
  firebrick: '#b22222',
  darkgoldenrod: '#b8860b',
  mediumorchid: '#ba55d3',
  rosybrown: '#bc8f8f',
  darkkhaki: '#bdb76b',
  silver: '#c0c0c0',
  mediumvioletred: '#c71585',
  indianred: '#cd5c5c',
  peru: '#cd853f',
  chocolate: '#d2691e',
  tan: '#d2b48c',
  lightgray: '#d3d3d3',
  lightgrey: '#d3d3d3',
  thistle: '#d8bfd8',
  orchid: '#da70d6',
  goldenrod: '#daa520',
  palevioletred: '#db7093',
  crimson: '#dc143c',
  gainsboro: '#dcdcdc',
  plum: '#dda0dd',
  burlywood: '#deb887',
  lightcyan: '#e0ffff',
  lavender: '#e6e6fa',
  darksalmon: '#e9967a',
  violet: '#ee82ee',
  palegoldenrod: '#eee8aa',
  lightcoral: '#f08080',
  khaki: '#f0e68c',
  aliceblue: '#f0f8ff',
  honeydew: '#f0fff0',
  azure: '#f0ffff',
  sandybrown: '#f4a460',
  wheat: '#f5deb3',
  beige: '#f5f5dc',
  whitesmoke: '#f5f5f5',
  mintcream: '#f5fffa',
  ghostwhite: '#f8f8ff',
  salmon: '#fa8072',
  antiquewhite: '#faebd7',
  linen: '#faf0e6',
  lightgoldenrodyellow: '#fafad2',
  oldlace: '#fdf5e6',
  red: '#ff0000',
  fuchsia: '#ff00ff',
  magenta: '#ff00ff',
  deeppink: '#ff1493',
  orangered: '#ff4500',
  tomato: '#ff6347',
  hotpink: '#ff69b4',
  coral: '#ff7f50',
  darkorange: '#ff8c00',
  lightsalmon: '#ffa07a',
  orange: '#ffa500',
  lightpink: '#ffb6c1',
  pink: '#ffc0cb',
  gold: '#ffd700',
  peachpuff: '#ffdab9',
  navajowhite: '#ffdead',
  moccasin: '#ffe4b5',
  bisque: '#ffe4c4',
  mistyrose: '#ffe4e1',
  blanchedalmond: '#ffebcd',
  papayawhip: '#ffefd5',
  lavenderblush: '#fff0f5',
  seashell: '#fff5ee',
  cornsilk: '#fff8dc',
  lemonchiffon: '#fffacd',
  floralwhite: '#fffaf0',
  snow: '#fffafa',
  yellow: '#ffff00',
  lightyellow: '#ffffe0',
  ivory: '#fffff0',
  white: '#ffffff'
};

let GrapesJsEditorInstance = null;

const ComponentTypes = editor => {
  editor.TraitManager.addType('textarea', {}); // dummy trait type

  editor.DomComponents.addType('htmlCode', {
    isComponent: el => {
      if (el.tagName === 'DIV') {
        const attr = el.attributes.item(0);
        return attr && attr.name === 'data-html-code';
      }

      return false;
    },
    model: {
      defaults: {
        tagName: 'div',
        draggable: true,
        droppable: false,
        editable: false,
        traits: [{
          type: 'textarea',
          label: 'Code',
          name: 'code'
        }]
      },
      init() {
        this.on('change:attributes:code', function(component, value) {
          component.set('content', value);
        });
      }
    }
  });

  // link editor
  editor.Commands.add('open-link-editor', {
    run: function(editor, sender, data) {
      GrapesJsEditorInstance.openLinkEditor(data.component || editor.getSelected(), data.isNew);
    }
  });

  const linkType = editor.DomComponents.getType('link');

  editor.DomComponents.addType('link', {
    model: linkType,
    view: linkType.view.extend({
      events: {
        dblclick: 'editLink'
      },
      editLink: function(ev) {
        ev.stopImmediatePropagation();
        editor.runCommand('open-link-editor', {component: this.model});
      }
    })
  });

  let linkRteAction = editor.RichTextEditor.remove('link');

  const getAllComponents = function(component) {
    component = component || editor.DomComponents.getWrapper();

    var components = component.get('components').models;
    component.get('components').forEach(function(component) {
      components = components.concat(getAllComponents(component));
    });

    return components;
  }

  const isValidAnchor = function(rte) {
    const anchor = rte.selection().anchorNode;
    const parentNode = anchor && anchor.parentNode;
    return parentNode && parentNode.nodeName == 'A';
  }

  linkRteAction.state = function(rte) {
    if (rte && rte.selection()) {
      return isValidAnchor(rte) ? 1 : 0;
    }

    return 0;
  }

  linkRteAction.result = function(rte) {
    if (isValidAnchor(rte)) {
      rte.exec('unlink');
      return;
    }

    const linkId = `__link_${guid()}`;
    let link = rte.exec('createLink', linkId);

    editor.getSelected().view.disableEditing();

    const linkComponent = getAllComponents().filter(c => (c.get('attributes').href || '') == linkId)[0];
    let attributes = linkComponent.get('attributes');

    attributes.href = '';

    if (linkComponent.get('content') === linkId) { // it's a new link
      linkComponent.set('content', '');
    }

    linkComponent.set('attributes', attributes);

    editor.runCommand('open-link-editor', {
      component: linkComponent,
      isNew: true
    });
  }

  editor.RichTextEditor.add('link', linkRteAction);
}

const updateStyle = function(element, newStyle, editor) {
  if(element.get('custom-name') === 'Body' && editor){
    editor.DomComponents.getWrapper().set('style', newStyle);
  }
  let currentStyle = _.clone(element.getStyle() || {});
  currentStyle = _.extend(currentStyle, newStyle);
  element.setStyle(currentStyle);
}

class LinkEditor extends React.Component {
  constructor(props) {
    super(props);

    this.linkTypes = [{
      id: 'url',
      title: 'URL'
    }, {
      id: 'email',
      title: 'E-mail'
    }];

    this.protocols = [{
      id: 'http',
      title: 'http://'
    }, {
      id: 'https',
      title: 'https://'
    }, {
      id: 'ftp',
      title: 'ftp://'
    }, {
      id: 'news',
      title: 'news://'
    }, {
      id: 'other',
      title: 'other'
    }];

    this.targetTypes = [{
      id: 'newWindow',
      title: 'New Window (_blank)',
      value: '_blank'
    }, {
      id: 'topmostWindow',
      title: 'Topmost Window (_top)',
      value: '_top'
    }, {
      id: 'sameWindow',
      title: 'Same Window (_self)',
      value: '_self'
    }, {
      id: 'parentWindow',
      title: 'Parent Window (_parent)',
      value: '_parent'
    }];

    this.mailToPrefix = 'mailto:';

    const component = this.props.component;
    const attributes = component.get('attributes');

    let url = attributes.href;
    let protocol = null;
    let linkType = this.linkTypes[0];
    let emailAddress = '';
    let messageSubject = '';
    let messageBody = '';

    if (url) {
      if (url.indexOf(this.mailToPrefix) === 0) {
        linkType = this.linkTypes[1];
        url = url.substring(this.mailToPrefix.length);

        const paramsIdx = url.indexOf('?');

        if (paramsIdx !== -1) {
          const params = url.substring(paramsIdx + 1).split('&');

          for (const p of params) {
            const parts = p.split('=');
            const value = decodeURIComponent(parts[1].replace(/\+/g, '%20'));

            if (parts[0] === 'subject') {
              messageSubject = value;
            } else if (parts[0] === 'body') {
              messageBody = value;
            }
          }

          emailAddress = url.substring(0, paramsIdx);
        }
        else {
          emailAddress = url;
        }
      } else {
        for (const p of this.protocols) {
          if (url.indexOf(p.title) === 0) {
            protocol = p;
            url = url.substring(protocol.title.length);
            break;
          }
        }

        if (!protocol) {
          protocol = this.protocols[this.protocols.length - 1]; // other
        }
      }
    } else {
      protocol = this.protocols[0]; // http
    }

    let target = null;

    if (attributes.target) {
      target = this.targetTypes.find(t => t.value === attributes.target);
    }

    this.state = {
      linkType: linkType,
      displayText: component.get('content'),
      protocol: protocol,
      target: target,
      url: url,
      emailAddress: emailAddress,
      messageSubject: messageSubject,
      messageBody: messageBody
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(ev, what) {
    this.setState({[what]: ev.target.value});
  }

  onChangeUrl(ev) {
    const value = ev.target.value;

    // remove the protocol for the url
    for (let p of this.protocols) {
      if (value.indexOf(p.title) === 0) {
        if (value.length > p.title.length) { // remove the protocol only when there are more text written to avoid let the field empty
          this.setState({
            protocol: p,
            url: value.substring(p.title.length)
          });

          this.protocolSelect.setValue(p.id);
          return;
        }
        break;
      }
    }

    this.setState({url: ev.target.value});
  }

  onChangeLinkType(items) {
    const linkType = items[0];

    if (linkType.id === 'url') {
      this.setState({
        linkType: linkType,
        url: '',
        protocol: this.protocols[0],
        target: null
      });
    } else {
      this.setState({
        linkType: linkType,
        emailAddress: '',
        messageSubject: '',
        messageBody: ''
      });
    }
  }

  onSave() {
    const component = this.props.component;
    let attributes = component.get('attributes');

    if (this.state.linkType.id === 'url') {
      if (this.state.url) {
        if (this.state.protocol.id !== 'other') {
          attributes.href = this.state.protocol.title + this.state.url;
        } else {
          attributes.href = this.state.url;
        }
      }

      if (this.state.target) {
        attributes.target = this.state.target.value;
      } else {
        delete attributes.target;
      }
    } else {
      attributes.href = this.mailToPrefix + this.state.emailAddress;
      let params = {};

      if (this.state.messageSubject) {
        params.subject = this.state.messageSubject;
      }

      if (this.state.messageBody) {
        params.body = this.state.messageBody;
      }

      if (!_.isEmpty(params)) {
        attributes.href += '?' + $.param(params);
      }

      delete attributes.target;
    }

    component.set('attributes', attributes);
    component.set('content', this.state.displayText);
    component.view.render();

    this.props.onClose();
  }

  onClose() {
    // if the link is new we delete the created component
    if (this.props.isNew) {
      const component = this.props.component;
      this.props.component.remove();
    }

    this.props.onClose();
  }

  render() {
    return (
      <div className={style.LinkEditorMask}>
        <div className={style.LinkEditor}>
          <div className={style.LEHeader}>
            <div className={style.LEHClose} onClick={this.onClose.bind(this)}>Close</div>
            <div className={style.LEHTitle}>Link</div>
          </div>
          <div className={style.LEContent}>
            <div className={style.LECRow}>
              <div className={style.LECRowLabel}>Display Text</div>
              <input
                className={style.LECRowInputValue}
                value={this.state.displayText}
                onChange={(ev) => this.onChange(ev, 'displayText')}
              />
            </div>
            <div className={style.LECRow}>
              <div className={style.LECRowLabel}>Link Type</div>
              <div style={{width: '50%'}}>
                <NewSelect
                  data={this.linkTypes}
                  value={this.state.linkType}
                  width={234}
                  options={{minimumInputLength: -1}}
                  onSelect={this.onChangeLinkType.bind(this)}
                />
              </div>
            </div>
            {this.state.linkType.id === 'url' && <div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>Protocol</div>
                <div style={{width: '50%'}}>
                  <NewSelect
                    ref={(el) => this.protocolSelect = el}
                    data={this.protocols}
                    value={this.state.protocol}
                    width={234}
                    options={{minimumInputLength: -1}}
                    onSelect={(items) => this.setState({protocol: items[0]})}
                  />
                </div>
              </div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>URL</div>
                <input
                  className={style.LECRowInputValue}
                  value={this.state.url}
                  onChange={this.onChangeUrl.bind(this)}
                />
              </div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>Target</div>
                <div style={{width: '50%'}}>
                  <NewSelect
                    data={this.targetTypes}
                    value={this.state.target}
                    width={234}
                    placeholder="Select target"
                    options={{minimumInputLength: -1, allowClear: true}}
                    onSelect={(items) => this.setState({target: items ? items[0] : null})}
                  />
                </div>
              </div>
            </div>}
            {this.state.linkType.id === 'email' && <div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>E-Mail Address</div>
                <input
                  className={style.LECRowInputValue}
                  value={this.state.emailAddress}
                  onChange={(ev) => this.onChange(ev, 'emailAddress')}
                />
              </div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>Message Subject</div>
                <input
                  className={style.LECRowInputValue}
                  value={this.state.messageSubject}
                  onChange={(ev) => this.onChange(ev, 'messageSubject')}
                />
              </div>
              <div className={style.LECRow}>
                <div className={style.LECRowLabel}>Message Body</div>
                <div style={{width: '50%'}}>
                  <textarea
                    style={{height: '100px', width: '100%', resize: 'none'}}
                    value={this.state.messageBody}
                    onChange={(ev) => this.onChange(ev, 'messageBody')}
                  />
                </div>
              </div>
            </div>}

            <div className={style.LECRow}>
              <div className={style.LECRowLabel}/>
              <div style={{width: '50%', marginTop: '20px'}}>
                <div
                  className={style.LESave}
                  onClick={this.onSave.bind(this)}
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class MergeTagSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectTag = this.selectTag.bind(this);
  }

  onClose(ev) {
    this.props.onClose();
  }

  selectTag(tag) {
    if (tag.customInsertTag) {
      this.setSelectionRange();
      this.props.rte.insertHTML(tag.customInsertTag);
      GrapesJsEditorInstance.editor.getSelected().view.syncContent({force: true});
    } else {
      const self = this;

      MessageBox.showOk(
        {
          icon: 'icon-warning',
          message: 'Leave blank or choose alternative when ' + tag.name + ' is missing',
          accept_button_text: 'Continue'
        },
        self.props.parent,
        function(val) {
          let tagValue = tag.tag;

          if (val.toString().length > 0) {
            tagValue = `${tag.tag.slice(0, -1)},default=${val}}`;
          }

          self.setSelectionRange();
          self.props.rte.insertHTML(tagValue);

          GrapesJsEditorInstance.editor.getSelected().view.syncContent({force: true});
        }, {
          type: 'input'
        }
      );
    }
  }

  render() {
    let dialogTop = this.props.top + 25;
    const dialogHeight = 300;
    const editorTop = this.props.editorDomElement.offsetTop;
    const editorHeight = this.props.editorDomElement.offsetHeight;

    if (dialogTop + dialogHeight > editorTop + editorHeight) {
      dialogTop = this.props.top - dialogHeight - 10;
    }

    return (
      <div
        className={style.MergeTagSelectorMask}
        onClick={this.onClose.bind(this)}
      >
        <div
          className={style.MergeTagSelector}
          style={{top: `${dialogTop}px`, left: `${this.props.left}px`}}
        >
          {this.props.mergeTags.map((tag, index) => {
            if (tag.title) {
              return (
                <div
                  key={`tagItem_${index}`}
                  className={style.TagTitle}
                >
                    {tag.name}
                </div>
              );
            } else {
              return (
                <div
                  key={`tagItem_${index}`}
                  className={style.Tag}
                  onClick={() => this.selectTag(tag)}
                >
                    {tag.name}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }

  getTextNodesIn(node) {
    let textNodes = [];

    if (node.nodeType === 3) {
      textNodes.push(node);
    } else {
      const children = node.childNodes;

      for (var i = 0, len = children.length; i < len; ++i) {
        textNodes.push.apply(textNodes, this.getTextNodesIn(children[i]));
      }
    }
    return textNodes;
  }

  setSelectionRange() {
    const el = this.props.rte.el;
    const start = this.props.selectionStart;
    const end = this.props.selectionEnd;

    let range = document.createRange();
    range.selectNodeContents(el);

    const textNodes = this.getTextNodesIn(el);
    let foundStart = false;
    let charCount = 0;
    let endCharCount;

    for (let [i, textNode] of textNodes.entries()) {
      endCharCount = charCount + textNode.length;

      if (!foundStart && start >= charCount && (start < endCharCount || (start == endCharCount && i <= textNodes.length))) {
        range.setStart(textNode, start - charCount);
        foundStart = true;
      }

      if (foundStart && end <= endCharCount) {
        range.setEnd(textNode, end - charCount);
        break;
      }

      charCount = endCharCount;
    }

    let selection = this.props.rte.selection()
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

class CodeViewer extends React.Component {
  render() {
    const self = this;

    _.defer(function() {
      ace.config.setModuleUrl(
        'ace/mode/html_worker',
        './vendor/ace-editor-1.2.3/src-noconflict/worker-html.js'
      );

      const content = beautify.html(self.props.content || '');

      self.editor = ace.edit('code-editor');
      self.editor.setTheme('ace/theme/clouds');
      self.editor.getSession().setMode('ace/mode/html');
      self.editor.getSession().setUseWrapMode(true);
      self.editor.setValue(content);
    });

    return (
      <div className={style.CodeViewerMask}>
        <div className={style.CodeViewer}>
          <div className={style.Header}>
            <div className={style.Title}>View/Edit HTML</div>
            <div
              className={style.Close}
              onClick={() => this.props.onCancel()}
            >
              &#x00d7;
            </div>
          </div>
          <div id='code-editor' className={style.Code}/>
          <div className={style.Footer}>
            <div
              className={style.SaveButton}
              onClick={() => this.props.onAccept(this.editor.getValue())}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class SimpleInputField extends React.Component {
  render() {
    return (
      <div className={style.InputFieldContainer}>
        <div className={style.InputFieldLabel}>{this.props.label}</div>
        <input type="text" className={style.SimpleInputField}/>
      </div>
    );
  }
}

class SizeInputField extends React.Component {
  constructor(props) {
    super(props);

    const valueInfo = this.parseValue(this.props.value || '');

    this.state = {
      value: valueInfo.value,
      unit: valueInfo.unit
    };

    this.incValue = this.incValue.bind(this);
  }

  refresh() {
    const valueInfo = this.parseValue(this.props.value || '');

    this.setState({
      value: valueInfo.value,
      unit: valueInfo.unit
    });
  }

  parseValue(newValue) {
    let value = '';
    let unit = null;

    const lowerValue = newValue.toLowerCase();

    if (this.props.stringValues && this.props.stringValues.indexOf(lowerValue) !== -1) {
      value = lowerValue;
      unit = null;
    } else {
      const number = parseFloat(newValue);

      if (_.isNaN(number)) {
        value = this.props.defaultValue;
        unit = null;
      } else {
        value = number;
        unit = this.props.units.find(u => u.name === lowerValue.substring(number.toString().length));

        if (!unit) {
          unit = this.props.units.find(u => u.id === 'pixel');
        }
      }
    }

    return {
      value: value,
      unit: unit
    };
  }

  showUnitsDropdown() {
    this.select.toggleExpanded(!this.select.isExpanded());
  }

  onUnitSelect(items) {
    this.onValueChanged(`${this.state.value}${items[0].name}`);
  }

  updateValue(ev) {
    this.onValueChanged(`${ev.target.value}${this.state.unit ? this.state.unit.name : ''}`);
  }

  onValueChanged(newValue) {
    const valueInfo = this.parseValue(newValue);

    this.setState({
      value: valueInfo.value,
      unit: valueInfo.unit
    });

    this.props.onChange(`${valueInfo.value}${valueInfo.unit ? valueInfo.unit.name : ''}`);
  }

  onChange(ev) {
    this.setState({
      value: ev.target.value
    });
  }

  onKeyDown(ev) {
    if (ev.key === 'Enter') {
      this.updateValue(ev);
    }
  }

  incValue(inc) {
    if (!this.state.unit) {
      this.onValueChanged('0px');
    } else {
      this.onValueChanged(`${this.state.value + inc}${this.state.unit.name}`);
    }
  }

  setValue(value) {
    const valueInfo = this.parseValue(value);

    this.setState({
      value: valueInfo.value,
      unit: valueInfo.unit
    });
  }

  render() {
    return (
      <div className={style.InputFieldContainer} >
        <div className={style.InputFieldLabel}>{this.props.label}</div>
        <div className={`${style.InputFieldInputs} ${this.props.narrow ? style.Narrow : ''} ${this.props.wide ? style.Wide : ''}`}>
          <input
            type="text"
            className={style.InputField}
            value={this.state.value}
            placeholder={this.props.defaultValue}
            onChange={this.onChange.bind(this)}
            onBlur={this.updateValue.bind(this)}
            onKeyDown={this.onKeyDown.bind(this)}
          />
          <div className={style.InputFieldUnits} onClick={this.showUnitsDropdown.bind(this)}>
            <div>{this.state.unit ? this.state.unit.name : ''}</div>
            <div className={style.UnitsDropdownContainer}>
              <NewSelect
                ref={(el) => this.select = el}
                data={this.props.units}
                value={this.state.unit}
                width={70}
                text="name"
                boxStyle={{display: "none"}}
                options={{minimumInputLength: -1}}
                onSelect={this.onUnitSelect.bind(this)}
              />
            </div>
          </div>
          <div className={style.InputFieldModifiers}>
            <div className={style.ModifiersControl}>
              <div className={style.InputFieldInc} onClick={() => this.incValue(1)}><div>{'\u02C4'}</div></div>
              <div className={style.InputFieldDec} onClick={() => this.incValue(-1)}><div>{'\u02C5'}</div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class DropdownInputField extends React.Component {
  refresh() {
    if (this.props.value) {
      this.select.setValue(this.props.value.id);
    }
  }

  render() {
    return (
      <div className={style.InputFieldContainer}>
        <div className={style.InputFieldLabel}>{this.props.label}</div>
        <div className={style.DropdownInputField}>
          <NewSelect
            ref={(el) => this.select = el}
            data={this.props.options}
            value={this.props.value}
            width={this.props.width || 200}
            text="name"
            options={{minimumInputLength: -1}}
            onSelect={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

class BorderInputField extends React.Component {
  constructor(props) {
    super(props);

    this.components = {};
    this.styles = [];

    for (let style of ['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', 'none', 'hidden']) {
      this.styles.push({
        id: style,
        name: style
      });
    }

    const border = this.parseValue(this.props.value);

    this.state = (border === 'none') ? {border: 'none'} : border;
  }

  parseValue(value) {
    if (!value || value === 'none') {
      return 'none';
    }

    const parts = value.split(' ');

    const width = parseInt(parts[0].replace( /^\D+/g, ''));

    if (width === 0){
      return 'none';
    }

    return {
      width: parts[0],
      style: this.styles.find(e => e.id === parts[1]),
      color: parts[2]
    };
  }

  onStyleChange(items) {
    this.setState({style: items[0]});
    this.onChange(this.state.width, items[0].id, this.state.color);
  }

  onWidthChange(value) {
    this.setState({width: value});
    this.onChange(value, this.state.style && this.state.style.id, this.state.color);
  }

  onColorChange(value) {
    this.setState({color: value});
    this.onChange(this.state.width, this.state.style && this.state.style.id, value);
  }

  onChange(width, style, color) {
    if (width && parseInt(width.replace( /^\D+/g, '')) > 0){
      this.props.onChange(`${width} ${style} ${color}`);
    } else {
      this.props.onChange('none');
    }
  }

  refresh() {
    const border = this.parseValue(this.props.value);

    this.setState(border === 'none' ? {border: 'none'} : border);

    for (let c in this.components) {
      this.components[c].refresh();
    }
  }

  render() {
    return (
      <div className={style.BorderInput}>
        <SizeInputField
          ref={(el) => {this.components.width = el}}
          units={Units.borderRadius}
          stringValues={[]}
          label="Width"
          value={this.state.width}
          defaultValue='0px'
          narrow={true}
          onChange={this.onWidthChange.bind(this)}
        />
        <DropdownInputField
          ref={(el) => this.components.style = el}
          label="Style"
          options={this.styles}
          value={this.state.style}
          onChange={this.onStyleChange.bind(this)}
        />
        <ColorInputField
          ref={(el) => this.components.color = el}
          label="Color"
          value={this.state.color}
          onChange={this.onColorChange.bind(this)}
          narrow={true}
        />
      </div>
    );
  }
}

class CheckboxInputField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({value: value});
    this.props.onChange(value);
  }

  refresh() {
    this.setState({value: this.props.value});
  }

  render() {
    const value = this.props.value;

    return (
      <div className={style.InputFieldContainer}>
        <div className={style.InputFieldLabel}>{this.props.label}</div>
        <div className={style.CheckboxInputField}>
          <div className={`${style.CheckboxNo} ${!this.state.value ? style.CheckboxActive : ''}`} onClick={() => this.onChange(false)}>No</div>
          <div className={`${style.CheckboxYes} ${this.state.value ? style.CheckboxActive : ''}`} onClick={() => this.onChange(true)}>Yes</div>
        </div>
      </div>
    );
  }
}

class ColorInputField extends React.Component {
  constructor(props) {
    super(props);

    const value = this.parseValue(props.value || '#000000');

    this.state = {
      nameValue: value.name,
      hexValue: value.hex
    };
  }

  onColorChange(event) {
    this.updateValue(event.target.value);
  }

  onChangeText(event) {
    this.setState({nameValue: event.target.value});
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.updateValue(this.state.nameValue);
    }
  }

  onBlurText() {
    this.updateValue(this.state.nameValue);
  }

  updateValue(value) {
    const val = this.parseValue(value);

    this.setState({
      nameValue: val.name,
      hexValue: val.hex
    });

    this.props.onChange(val.hex);
  }

  parseValue(value) {
    const lowerVal = value.toLowerCase();
    let hexValue = '';
    let nameValue = '';

    if (lowerVal in Colors) {
      hexValue = Colors[lowerVal];
      nameValue = lowerVal;
    } else {
      hexValue = value;
      nameValue = value;
    }

    return {
      hex: hexValue,
      name: nameValue
    }
  }

  refresh() {
    const val = this.parseValue(this.props.value || '#000000');

    this.setState({
      nameValue: val.name,
      hexValue: val.hex
    });
  }

  render() {
    return (
      <div className={style.InputFieldContainer}>
        <div className={style.InputFieldLabel}>{this.props.label}</div>
        <div className={style.ColorInputField}>
          <input
            ref={(el) => this.colorInput = el}
            type="color"
            style={{display: "none"}}
            onChange={this.onColorChange.bind(this)}
          />
          <input
            ref={(el) => this.textInput = el}
            type="text"
            value={this.state.nameValue}
            className={`${style.ColorTextInput} ${this.props.narrow ? style.ColorTextInputNarrow : ''}`}
            onChange={this.onChangeText.bind(this)}
            onKeyDown={this.onKeyDown.bind(this)}
            onBlur={this.onBlurText.bind(this)}
          />
          <div style={{background: this.state.hexValue}} className={style.ColorColorInput} onClick={() => this.colorInput.click()}/>
        </div>
      </div>
    );
  }
}

class FontInputField extends React.Component {
  constructor(props) {
    super(props);
  }

  refresh() {
    this.dropdown.refresh()
  }

  render() {
    let value = null;

    if (this.props.value) {
      value = Fonts.find(f => f.name === this.props.value);
    } else {
      value = Fonts.find(f => f.id === 'arial');
    }

    return (
      <DropdownInputField
        ref={(el) => this.dropdown = el}
        label={this.props.label}
        options={Fonts}
        value={value}
        onChange={(items) => this.props.onChange(items[0])}
      />
    );
  }
}

class FontWeightInputField extends React.Component {
  constructor(props) {
    super(props);

    this.weights = [
      {id: 'thin',        name: 'Thin',         value: 100},
      {id: 'extraLight',  name: 'Extra-Light',  value: 200},
      {id: 'light',       name: 'Light',        value: 300},
      {id: 'normal',      name: 'Normal',       value: 400},
      {id: 'medium',      name: 'Medium',       value: 500},
      {id: 'semiBold',    name: 'Semi-Bold',    value: 600},
      {id: 'bold',        name: 'Bold',         value: 700},
      {id: 'extraBold',   name: 'Extra-Bold',   value: 800},
      {id: 'ultraBold',   name: 'Ultra-Bold',   value: 900}
    ];
  }

  refresh() {
    this.dropdown.refresh()
  }

  render() {
    let value = null;

    if (this.props.value) {
      const intValue = parseInt(this.props.value)
      value = this.weights.find(w => w.value === intValue);
    }

    return (
      <DropdownInputField
        ref={(el) => this.dropdown = el}
        label={this.props.label}
        options={this.weights}
        value={value}
        onChange={(items) => this.props.onChange(items[0].value)}
      />
    );
  }
}

class FontLetterSpacingInputField extends React.Component {
  constructor(props) {
    super(props);

    this.letterSpacing = [
      {id: 'normal',        name: 'Normal',       value: '0px'},
      {id: 'extraSmall',    name: 'Extra-Small',  value: '0.2px'},
      {id: 'small',         name: 'Small',        value: '0.5px'},
      {id: 'medium',        name: 'Medium',       value: '1px'},
      {id: 'large',         name: 'Large',        value: '2px'},
      {id: 'extraLarge',    name: 'Extra-Large',  value: '5px'}
    ];
  }

  refresh() {
    this.dropdown.refresh()
  }

  render() {
    let value = null;

    if (this.props.value) {
      value = this.letterSpacing.find(w => w.value === this.props.value);
    }

    return (
      <DropdownInputField
        ref={(el) => this.dropdown = el}
        label={this.props.label}
        options={this.letterSpacing}
        value={value}
        onChange={(items) => this.props.onChange(items[0].value)}
      />
    );
  }
}

class FontLineHeightInputField extends React.Component {
  constructor(props) {
    super(props);

    this.lineHeights = [
      {id: 'normal',         name: 'Normal',          value: '100%'},
      {id: 'slight',         name: 'Slight',          value: '125%'},
      {id: '1/2spacing',     name: '1 1/2 Spacing',   value: '150%'},
      {id: 'doubleSpacing',  name: 'Double Spacing',  value: '200%'}
    ];
  }

  refresh() {
    this.dropdown.refresh()
  }

  render() {
    let value = null;

    if (this.props.value) {
      const intValue = parseInt(this.props.value)
      value = this.lineHeights.find(w => w.value === intValue);
    }

    return (
      <DropdownInputField
        ref={(el) => this.dropdown = el}
        label={this.props.label}
        options={this.lineHeights}
        value={value}
        onChange={(items) => this.props.onChange(items[0].value)}
      />
    );
  }
}

class DivSizeInputField extends React.Component {
  refresh() {
    this.sizeInput.refresh()
  }

  render() {
    return (
      <SizeInputField
        ref={(el) => this.sizeInput = el}
        label={this.props.label}
        units={Units.size}
        stringValues={['auto']}
        value={this.props.value}
        defaultValue='auto'
        onChange={this.props.onChange}
      />
    )
  }
}

class FontSizeInputField extends React.Component {
  constructor(props) {
    super(props);

    this.fontSizes = [
      {id: 'common10',     name: '10',           value: '10px'},
      {id: 'common12',     name: '12',           value: '12px'},
      {id: 'common14',     name: '14',           value: '14px'},
      {id: 'common16',     name: '16',           value: '16px'},
      {id: 'common18',     name: '18',           value: '18px'},
      {id: 'common20',     name: '20',           value: '20px'},
      {id: 'common24',     name: '24',           value: '24px'},
      {id: 'common32',     name: '32',           value: '32px'},
      {id: 'common48',     name: '48',           value: '48px'},
      {id: 'common64',     name: '64',           value: '64px'},
      {id: 'defaultSize',  name: 'Default Size', value: '14px'},
      {id: 'other10',      name: '10',           value: '10px'},
      {id: 'other11',      name: '11',           value: '11px'},
      {id: 'other12',      name: '12',           value: '12px'},
      {id: 'other13',      name: '13',           value: '13px'},
      {id: 'other14',      name: '14',           value: '14px'},
      {id: 'other15',      name: '15',           value: '15px'},
      {id: 'other16',      name: '16',           value: '16px'},
      {id: 'other17',      name: '17',           value: '17px'},
      {id: 'other18',      name: '18',           value: '18px'},
      {id: 'other19',      name: '19',           value: '19px'},
      {id: 'other20',      name: '20',           value: '20px'},
      {id: 'other21',      name: '21',           value: '21px'},
      {id: 'other22',      name: '22',           value: '22px'},
      {id: 'other23',      name: '23',           value: '23px'},
      {id: 'other24',      name: '24',           value: '24px'},
      {id: 'other25',      name: '25',           value: '25px'},
      {id: 'other26',      name: '26',           value: '26px'},
      {id: 'other27',      name: '27',           value: '27px'},
      {id: 'other28',      name: '28',           value: '28px'},
      {id: 'other29',      name: '29',           value: '29px'},
      {id: 'other30',      name: '30',           value: '30px'},
      {id: 'other31',      name: '31',           value: '31px'},
      {id: 'other32',      name: '32',           value: '32px'},
      {id: 'other33',      name: '33',           value: '33px'},
      {id: 'other34',      name: '34',           value: '34px'},
      {id: 'other35',      name: '35',           value: '35px'},
      {id: 'other36',      name: '36',           value: '36px'},
      {id: 'other37',      name: '37',           value: '37px'},
      {id: 'other38',      name: '38',           value: '38px'},
      {id: 'other39',      name: '39',           value: '39px'},
      {id: 'other40',      name: '40',           value: '40px'},
      {id: 'other41',      name: '41',           value: '41px'},
      {id: 'other42',      name: '42',           value: '42px'},
      {id: 'other43',      name: '43',           value: '43px'},
      {id: 'other44',      name: '44',           value: '44px'},
      {id: 'other45',      name: '45',           value: '45px'},
      {id: 'other46',      name: '46',           value: '46px'},
      {id: 'other47',      name: '47',           value: '47px'},
      {id: 'other48',      name: '48',           value: '48px'},
      {id: 'other49',      name: '49',           value: '49px'},
      {id: 'other50',      name: '50',           value: '50px'},
      {id: 'other51',      name: '51',           value: '51px'},
      {id: 'other52',      name: '52',           value: '52px'},
      {id: 'other53',      name: '53',           value: '53px'},
      {id: 'other54',      name: '54',           value: '54px'},
      {id: 'other55',      name: '55',           value: '55px'},
      {id: 'other56',      name: '56',           value: '56px'},
      {id: 'other57',      name: '57',           value: '57px'},
      {id: 'other58',      name: '58',           value: '58px'},
      {id: 'other59',      name: '59',           value: '59px'},
      {id: 'other60',      name: '60',           value: '60px'},
      {id: 'other61',      name: '61',           value: '61px'},
      {id: 'other62',      name: '62',           value: '62px'},
      {id: 'other63',      name: '63',           value: '63px'},
      {id: 'other64',      name: '64',           value: '64px'},
    ];
  }

  refresh() {
    this.dropdown.refresh()
  }

  render() {
    let value = null;

    if (this.props.value) {
      value = this.fontSizes.find(w => w.value === this.props.value);
    }

    return (
      <DropdownInputField
        ref={(el) => this.dropdown = el}
        label={this.props.label}
        options={this.fontSizes}
        value={value}
        onChange={(items) => this.props.onChange(items[0].value)}
      />
    );
  }
}

class PaddingInputField extends React.Component {
  refresh() {
    this.sizeInput.refresh()
  }

  render() {
    return (
      <SizeInputField
        ref={(el) => this.sizeInput = el}
        label={this.props.label}
        units={Units.fontSize}
        stringValues={['auto']}
        value={this.props.value}
        defaultValue='auto'
        wide={true}
        onChange={this.props.onChange}
      />
    )
  }
}

const BackgroundImageLayerDragHandle = SortableHandle(() => <i className={`fa fa-arrows ${style.BILHeaderDrag}`}></i>);

const BackgroundSortableImageLayer = SortableElement(({value, collapsed, id, displayLayer, components, onChange, editor, onDelete}) =>
  <div style={{zIndex: 9999, marginTop: '5px'}}>
    <BackgroundImageLayer
      ref={(el) => {if (el) { components.push(el)}}}
      key={id}
      value={value}
      collapsed={collapsed}
      id={id}
      displayLayer={displayLayer}
      onChange={onChange}
      onDelete={onDelete}
      editor={editor}
    />
  </div>
);

const BrackgroundImageLayersList = SortableContainer(({layers, displayLayer, components, onChange, editor, onDelete}) => {
  return (
    <div className={style.BackgroundImageLayersList}>
      {layers.map((layer, index) => { return (
        <BackgroundSortableImageLayer
          key={index}
          index={index}
          value={layer}
          collapsed={layer.collapsed}
          id={layer.id}
          displayLayer={displayLayer}
          components={components}
          onChange={onChange}
          onDelete={onDelete}
          editor={editor}
        />
      )})}
    </div>
  );
});

class BackgroundImageLayer extends React.Component {
  constructor(props) {
    super(props);

    this.components = {};

    this.repeats = [{
      id: 'repeat',
      name: 'Repeat'
    }, {
      id: 'repeat-x',
      name: 'Repeat X'
    }, {
      id: 'repeat-y',
      name: 'Repeat Y'
    }, {
      id: 'no-repeat',
      name: 'No Repeat'
    }];

    this.positions = [{
      id: 'left top',
      name: 'Left Top'
    }, {
      id: 'left center',
      name: 'Left Center'
    }, {
      id: 'left bottom',
      name: 'Left Bottom'
    }, {
      id: 'right top',
      name: 'Right Top'
    }, {
      id: 'right center',
      name: 'Right Center'
    }, {
      id: 'right bottom',
      name: 'Right Bottom'
    }, {
      id: 'center top',
      name: 'Center Top'
    }, {
      id: 'center center',
      name: 'Center Center'
    }, {
      id: 'center bottom',
      name: 'Center Bottom'
    }];

    this.attachments = [{
      id: 'scroll',
      name: 'Scroll'
    }, {
      id: 'fixed',
      name: 'Fixed'
    }, {
      id: 'local',
      name: 'Local'
    }];

    this.sizes = [{
      id: 'auto',
      name: 'Auto'
    }, {
      id: 'cover',
      name: 'Cover'
    }, {
      id: 'contain',
      name: 'Contain'
    }];

    this.state = {
      collapsed: this.props.collapsed,
      repeat: this.repeats.find(e => e.id === this.props.value.repeat) || this.repeats[0],
      position: this.positions.find(e => e.id === this.props.value.repeat) || this.positions[0],
      attachment: this.attachments.find(e => e.id === this.props.value.repeat) || this.attachments[0],
      size: this.sizes.find(e => e.id === this.props.value.repeat) || this.sizes[0],
      image: this.props.value.image || 'none'
    };
  }

  onRepeatChange(value) {
    this.setState({repeat: value});
    this.props.onChange(this.props.id, {
      repeat: value,
      position: this.state.position,
      attachment: this.state.attachment,
      size: this.state.size,
      image: this.state.image
    });
  }

  onPositionChange(value) {
    this.setState({position: value});

    const self = this;
    _.defer(function() {
      self.props.onChange();
    });
  }

  onAttachmentChange(value) {
    this.setState({attachment: value});

    const self = this;
    _.defer(function() {
      self.props.onChange();
    });
  }

  onSizeChange(value) {
    this.setState({size: value});

    const self = this;
    _.defer(function() {
      self.props.onChange();
    });
  }

  onImageSelected(image) {
    this.setState({image: image ? `url(${image.url})` : 'none'});

    const self = this;
    _.defer(function() {
      self.props.onChange();
    });
  }

  onHeaderClick() {
    if (this.state.collapsed) {
      this.props.displayLayer(this.props.id)
    }
  }

  collapse(collapse) {
    this.setState({
      collapsed: collapse
    });
  }

  refresh() {
    for (const c in this.components) {
      this.components[c].refresh();
    }
  }

  render() {
    const backgroundImage = this.state.image || 'none';

    return (
      <div className={`${style.BackgroundImageLayer} ${this.state.collapsed ? style.BILCollapsed : ''}`}>
        <div className={style.BILHeader} onClick={this.onHeaderClick.bind(this)}>
          <BackgroundImageLayerDragHandle/>
          <div className={style.BILHeaderName}>{`Layer ${this.props.id + 1}`}</div>
          <i
            className={`icon-trashcan ${style.BILHeaderDelete}`}
            onClick={() => {
                this.onImageSelected(null)
                this.props.onDelete(this.props.id)
              }
            }
          />
          <div className={style.BILHeaderImage} style={{backgroundImage: `${backgroundImage}`}}/>
        </div>
        {!this.state.collapsed &&
          <div className={style.BILContent}>
            <div
              className={style.BILContentSelectImage}
              onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this))}
            >
              Select Image
            </div>
            {backgroundImage !== 'none' &&
              <div className={style.BILContentImageContainer}>
                <div
                  className={style.BILContentImageRemove}
                  onClick={() => this.onImageSelected(null)}
                >
                  &#x00d7;
                </div>
                <div
                  className={style.BILContentImage}
                  style={{backgroundImage: `${backgroundImage}`}}
                />
              </div>
            }
            <div className={style.ContentRow}>
              <DropdownInputField
                ref={(el) => this.components.repeat = el}
                label="Repeat"
                options={this.repeats}
                value={this.state.repeat}
                width={140}
                onChange={(items) => this.onRepeatChange(items[0])}
              />
              <DropdownInputField
                ref={(el) => this.components.repeat = el}
                label="Position"
                options={this.positions}
                value={this.state.position}
                width={140}
                onChange={(items) => this.onPositionChange(items[0])}
              />
            </div>
            <div className={style.ContentRow}>
              <DropdownInputField
                ref={(el) => this.components.repeat = el}
                label="Attachment"
                options={this.attachments}
                value={this.state.attachment}
                width={140}
                onChange={(items) => this.onAttachmentChange(items[0])}
              />
              <DropdownInputField
                ref={(el) => this.components.repeat = el}
                label="Size"
                options={this.sizes}
                value={this.state.size}
                width={140}
                onChange={(items) => this.onSizeChange(items[0])}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}

class BackgroundImageLayers extends React.Component {
  constructor(props) {
    super(props);

    this.components = [];

    this.state = {
      layers: []
    };
  }

  refresh() {
    let layers = [];

    let valuesInfo = {
      repeat: [],
      position: [],
      attachment: [],
      size: [],
      image: null
    };

    for (const k of ['repeat', 'position', 'attachment', 'size', 'image']) {
      const value = this.props.value[`background-${k}`]

      if (value) {
        valuesInfo[k] = value.split(',');
      }
    }

    if (valuesInfo.repeat.length > 0) {
      for (let i = 0; i < valuesInfo.repeat.length; ++i) {
        layers.push({
          id: i,
          collapsed: i !== 0,
          repeat: valuesInfo.repeat[i].trim(),
          position: valuesInfo.position[i].trim(),
          attachment: valuesInfo.attachment[i].trim(),
          size: valuesInfo.size[i].trim(),
          image: valuesInfo.image[i] ? valuesInfo.image[i].trim() : null
        });
      }
    }

    this.setState({layers: layers});

    for (const c of this.components) {
      c.refresh();
    }
  }

  displayLayer(layerId) {
    for (const c of this.components) {
      c.collapse(c.props.id !== layerId);
    }
  }

  buildLayersParams() {
    let values = [];
    let layers = _.clone(this.state.layers);

    for (const k of ['repeat', 'position', 'attachment', 'size', 'image']) {
      let v = [];

      for (const l of layers) {
        const component = this.components.find(c => c.props.id === l.id);
        const value = component.state[k];

        l[k] = value;
        v.push((k === 'image') ? value : value.id);
      }

      values.push([`background-${k}`, v.join(', ')]);
    }

    this.setState({layers: layers});
    this.props.onChange(values);
  }

  addLayer() {
    let layers = this.state.layers;
    const layersById = layers.sort(l => l.id);
    const layerId = layersById.length > 0 ? layersById[layersById.length - 1].id + 1 : 0;

    layers.push({
      id: layerId,
      repeat: 'repeat',
      position: 'left top',
      attachment: 'scroll',
      size: 'auto',
      image: null
    });

    this.setState({layers: layers});

    const self = this;

    _.defer(function() {
      self.displayLayer(layerId);
    });
  }

  deleteLayer(layerId) {
    let layers = this.state.layers.filter(l => l.id !== layerId);
    this.setState({layers: layers});

    if (layers.length > 0) {
      this.displayLayer(layerId);
    }
  }

  onSortEnd({oldIndex, newIndex}) {
    this.setState(({layers}) => ({
      layers: arrayMove(layers, oldIndex, newIndex)
    }));

    const self = this;
    _.defer(function() {
      self.buildLayersParams();
    });
  }

  render() {
    this.components = [];

    const addLayerMarginTop = this.state.layers.length > 0 ? 10 : 0;

    return (
      <div className={style.BackgroundImageLayers}>
        <BrackgroundImageLayersList
          layers={this.state.layers}
          displayLayer={this.displayLayer.bind(this)}
          onChange={this.buildLayersParams.bind(this)}
          editor={this.props.editor}
          components={this.components}
          useDragHandle={true}
          lockAxis="y"
          onSortEnd={this.onSortEnd.bind(this)}
          onDelete={this.deleteLayer.bind(this)}
        />
        <div
          className={style.BackgroundImageLayersAddLayer}
          onClick={this.addLayer.bind(this)}
          style={{marginTop: `${addLayerMarginTop}px`}}
        >
          <i className="icon-plus"/>
          <div>Add Layer</div>
        </div>
      </div>
    );
  }
}

const Sections = [
  {
    section: 'baseDimensions',
    visible: ['image', 'divider', 'blank-space', 'html-code']
  },
  {
    section: 'padding',
    visible: ['heading', 'text', 'footer', 'image', 'divider', 'html-code']
  },
  {
    section: 'baseTypography',
    visible: ['heading', 'text', 'footer', 'image-text', 'boxed-text', 'button', '1/2-text-grid']
  },
  {
    section: 'text-align',
    visible: ['heading', 'text', 'footer', 'image-text', 'boxed-text', 'button', '1/2-text-grid']
  },
  {
    section: 'font-style-decoration',
    visible: ['heading', 'text', 'footer', 'image-text', 'boxed-text', 'button', '1/2-text-grid']
  },
  {
    section: 'background-color',
    visible: ['body', 'heading', 'text', 'footer', 'image-text', 'boxed-text', '1/2-text-grid', 'image', 'video', 'social', 'button', 'divider', 'blank-space']
  },
  {
    section: 'border-radius',
    visible: ['boxed-text', 'button']
  },
  {
    section: 'border',
    visible: ['body', 'boxed-text', 'button', 'image', 'video']
  },
  {
    section: 'image-align',
    visible: ['link-image']
  },
  {
    section: 'background-images',
    visible: ['body']
  },
  {
    section: 'dimensions',
    visible: ['body', 'heading', 'text', 'footer', 'image-text', 'boxed-text', '1/2-text-grid', 'image', 'video', 'social', 'button', 'divider', 'blank-space', 'html-code', 'link-image']
  },
  {
    section: 'typography',
    visible: ['heading', 'text', 'footer', 'image-text', 'boxed-text', 'button', '1/2-text-grid']
  },

]

const showSection = function(sectionName, componentName) {
  const section =  Sections.find(s => sectionName === s.section)

  if (!componentName || componentName === ""){
    return true;
  }

  return section && componentName && section.visible && section.visible.includes(componentName)
}

class StyleSectionTypographyCategory extends React.Component {
  constructor(props) {
    super(props);

    this.components = {};
    this.state = {
      textAlignKey: 0,
      fontStyleKey: 0
    }
  }

  refresh() {
    for (const c in this.components) {
      this.components[c].refresh();
    }
  }

  onFontChange(font) {
    this.props.editor.changeElementFont(this.props.selectedElement, font);
  }

  toggleFontStyleAndDecorations(style, value) {
    const elementStyle = this.props.selectedElement.getStyle() || {};

    if (elementStyle[style] === value) {
      updateStyle(this.props.selectedElement, {[style]: 'unset'});
    } else {
      updateStyle(this.props.selectedElement, {[style]: value});
    }

    this.setState({fontStyleKey: this.state.fontStyleKey + 1});
  }

  render() {
    const elementStyle = this.props.selectedElement.getStyle() || {};
    const alignOptions = [['left', 'icon-typ-align-left'], ['center', 'icon-typ-align-centre'], ['right', 'icon-typ-align-right'], ['justify', 'icon-typ-justified']];
    let elName = this.props.selectedElement.get('name') || this.props.selectedElement.get('custom-name');

    if (elName){
      elName = elName.toLowerCase();
    }

    return (
      <div style={{paddingLeft: 5, paddingRight: 5}}>
        {showSection('baseTypography', elName) && <div>
        <div className={style.ContentRow}>
          <FontInputField
            ref={(el) => this.components.font = el}
            label="Font"
            value={elementStyle['font-family']}
            onChange={this.onFontChange.bind(this)}
          />
          <FontSizeInputField
            ref={(el) => this.components.fontSize = el}
            label="Font Size"
            value={elementStyle['font-size']}
            onChange={(value) => updateStyle(this.props.selectedElement, {'font-size': value})}
          />
        </div>
        <div className={style.ContentRow} style={{marginTop: 5}}>
          <FontWeightInputField
            ref={(el) => this.components.fontWeight = el}
            label="Font Weight"
            value={elementStyle['font-weight']}
            onChange={(value) => updateStyle(this.props.selectedElement, {'font-weight': value})}
          />
          <FontLetterSpacingInputField
            ref={(el) => this.components.letterSpacing = el}
            label="Letter Spacing"
            value={elementStyle['letter-spacing']}
            onChange={(value) => updateStyle(this.props.selectedElement, {'letter-spacing': value})}
          />
        </div>
        <div className={style.ContentRow} style={{marginTop: 5}}>
          <ColorInputField
            ref={(el) => this.components.fontColor = el}
            label="Font Color"
            value={elementStyle.color || "#000000"}
            onChange={(value) => updateStyle(this.props.selectedElement, {color: value})}
          />
          <FontLineHeightInputField
            ref={(el) => this.components.lineHeight = el}
            label="Line Height"
            value={elementStyle['line-height']}
            onChange={(value) => updateStyle(this.props.selectedElement, {'line-height': value})}
          />
        </div></div>}
        {showSection('text-align', elName) &&
        <div className={style.Group4Options} style={{marginTop: 10}}>
          <div className={style.Group4OptionsLabel}>Text Align</div>
          <div className={style.Group4OptionsContent}>
            {alignOptions.map((option) => { return (
              <div
                key={`${this.state.textAlignKey}_${option[0]}`}
                className={`${style.Group4OptionsOption} ${elementStyle['text-align'] === option[0] ? style.Group4OptionsActiveOption : ''}`}
                onClick={() => {updateStyle(this.props.selectedElement, {'text-align': option[0]}); this.setState({textAlignKey: this.state.textAlignKey + 1})}}
              >
                <i className={option[1]}/>
              </div>
            )})}
          </div>
        </div>}
        {showSection('font-style-decoration', elName) &&
        <div className={style.Group4Options} style={{marginTop: 10}}>
          <div className={style.Group4OptionsLabel}>Font Style + Decorations</div>
          <div
            className={style.Group4OptionsContent}
            key={`${this.state.fontStyleKey}`}
          >
            <div
              className={`${style.Group4OptionsOption} ${elementStyle['font-weight'] === 'bold' ? style.Group4OptionsActiveOption : ''}`}
              onClick={() => {this.toggleFontStyleAndDecorations('font-weight', 'bold')}}
            >
              <i className="icon-typ-bold"/>
            </div>
            <div
              className={`${style.Group4OptionsOption} ${elementStyle['font-style'] === 'italic' ? style.Group4OptionsActiveOption : ''}`}
              onClick={() => {this.toggleFontStyleAndDecorations('font-style', 'italic')}}
            >
              <i className="icon-typ-italic"/>
            </div>
            <div
              className={`${style.Group4OptionsOption} ${elementStyle['text-decoration'] === 'underline' ? style.Group4OptionsActiveOption : ''}`}
              onClick={() => {this.toggleFontStyleAndDecorations('text-decoration', 'underline')}}
            >
              <i className="icon-typ-underline"/>
            </div>
            <div
              className={`${style.Group4OptionsOption} ${elementStyle['text-decoration'] === 'line-through' ? style.Group4OptionsActiveOption : ''}`}
              onClick={() => {this.toggleFontStyleAndDecorations('text-decoration', 'line-through')}}
            >
              <i className="icon-typ-strike"/>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

class StyleSectionContainerStyleCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subcategoryCollapseState: {
        padding: false,
        borderRadius: false,
        border: false,
        backgroundImageLayers: false
      },
      borderRadiusKey: 0,
      imgAlignKey: 0
    };

    this.components = {};
  }

  toggleSubcategoryCollapseState(subcategory) {
    this.setState(prevState => {
      prevState.subcategoryCollapseState[subcategory] = !prevState.subcategoryCollapseState[subcategory];
      return prevState;
    });
  }

  refresh() {
    for (const c in this.components) {
      this.components[c].refresh();
    }
  }

  updateBackgroundImageLayers(values) {
    for (const v of values) {
      updateStyle(this.props.selectedElement, {[v[0]]: v[1]});
    }
  }

  render() {
    const elementStyle = this.props.selectedElement.getStyle() || {};
    const padding = [['Top', 'padding-top'], ['Bottom', 'padding-bottom']];
    const radiusOptions = [['0px', 'icon-corner-square'], ['5px', 'icon-corner-round-sml'], ['20px', 'icon-corner-round-mid'], ['50px', 'icon-corner-round-lrg']];
    const imgAlignOptions = [['left', 'icon-img-align-left'], ['center', 'icon-img-align-center'], ['right', 'icon-img-align-right']]
    
    let elName = this.props.selectedElement.get('name') || this.props.selectedElement.get('custom-name');
    
    if (elName){
      elName = elName.toLowerCase();
    }

    return (
      <div>
        {showSection('baseDimensions', elName) &&
        <div className={style.ContentRow}>
          <DivSizeInputField
            ref={(el) => this.components.width = el}
            label="Width"
            value={elementStyle.width}
            onChange={(value) => updateStyle(this.props.selectedElement, {width: value})}
          />
          <DivSizeInputField
            ref={(el) => this.components.height = el}
            label="Height"
            value={elementStyle.height}
            onChange={(value) => updateStyle(this.props.selectedElement, {height: value})}
          />
        </div>}
        {showSection('padding', elName) &&
        <div className={style.CollapsableSubcategory}>
          <div className={style.SubcategoryHeader} onClick={() => this.toggleSubcategoryCollapseState('padding')}>
            <div className={style.SubcategoryHeaderTitle}> Padding</div>
            <div className={style.SubcategoryHeaderCaret}>
              <i className={`${this.state.subcategoryCollapseState.advancedPadding ? 'icon-caret-right' : 'icon-caret-down'}`}/>
            </div>
          </div>
          {!this.state.subcategoryCollapseState.padding &&
            <div className={style.SubcategoryContent}>
              <div className={style.Padding}>
                {padding.map(padding => {return (
                  <PaddingInputField
                    ref={(el) => this.components[padding[1]] = el}
                    key={padding[0]}
                    label={padding[0]}
                    value={elementStyle[padding[1]]}
                    onChange={(value) => updateStyle(this.props.selectedElement, {[padding[1]]: value})}
                  />
                )})}
              </div>
            </div>
          }
        </div>}
        <div style={{paddingLeft: 5, paddingRight: 5}}>
          <div className={style.ContentRow}>
            {showSection('background-color', elName) &&
            <ColorInputField
              ref={(el) => this.components.backgroundColor = el}
              label="Background Color"
              value={elementStyle['background-color'] || '#ffffff'}
              onChange={(value) => updateStyle(this.props.selectedElement, {'background-color': value}, this.props.editor.editor)}
            />
            }
          </div>
          {showSection('border-radius', elName) &&
          <div className={style.Group4Options} style={{marginTop: 10}}>
            <div className={style.Group4OptionsLabel}>Rounded Corners</div>
            <div className={style.Group4OptionsContent}>
              {radiusOptions.map((option) => { return (
                <div
                  key={`${this.state.borderRadiusKey}_${option[0]}`}
                  className={`${style.Group4OptionsOption} ${style.OptionIcon} ${elementStyle['border-radius'] === option[0] ? style.Group4OptionsActiveOption : ''}`}
                  onClick={() => {updateStyle(this.props.selectedElement, {'border-radius': option[0]}); this.setState({borderRadiusKey: this.state.borderRadiusKey + 1})}}
                >
                  <i className={option[1]}/>
                </div>
              )})}
            </div>
          </div>}
          {showSection('image-align', elName) &&
          <div className={style.Group4Options} style={{marginTop: 10}}>
            <div className={style.Group4OptionsLabel}>Image Alignment</div>
            <div className={style.Group4OptionsContent}>
              {imgAlignOptions.map((option) => {return (
                <div
                  key={`${this.state.imgAlignKey}_${option[0]}`}
                  className={`${style.Group4OptionsOption} ${style.OptionIcon} ${elementStyle['text-align'] === option[0] ? style.Group4OptionsActiveOption : ''}`}
                  onClick={() => {
                    updateStyle(this.props.selectedElement, {'text-align': option[0]}); this.setState({imgAlignKey: this.state.imgAlignKey + 1})}}>
                  <i className={option[1]}/>
                </div>
              )})}
            </div>
          </div>}
          {showSection('border', elName) &&
          <div className={style.CollapsableSubcategory}>
            <div className={style.SubcategoryHeader} onClick={() => this.toggleSubcategoryCollapseState('border')}>
              <div className={style.SubcategoryHeaderTitle}>Border</div>
              <div className={style.SubcategoryHeaderCaret}>
                <i className={`${this.state.subcategoryCollapseState.border ? 'icon-caret-right' : 'icon-caret-down'}`}/>
              </div>
            </div>
            {!this.state.subcategoryCollapseState.border &&
              <BorderInputField
                ref={(el) => this.components.border = el}
                value={elementStyle.border}
                onChange={(value) => updateStyle(this.props.selectedElement, {'border': value})}
              />
            }
          </div>}
          {showSection('background-images', elName) &&
          <div className={style.CollapsableSubcategory}>
            <div className={style.SubcategoryHeader} onClick={() => this.toggleSubcategoryCollapseState('backgroundImageLayers')}>
              <div className={style.SubcategoryHeaderTitle}>Background Image Layers</div>
              <div className={style.SubcategoryHeaderCaret}>
                <i className={`${this.state.subcategoryCollapseState.backgroundImageLayers ? 'icon-caret-right' : 'icon-caret-down'}`}/>
              </div>
            </div>
            {!this.state.subcategoryCollapseState.backgroundImageLayers &&
              <BackgroundImageLayers
                ref={(el) => this.components.backgroundImageLayers = el}
                value={elementStyle}
                onChange={this.updateBackgroundImageLayers.bind(this)}
                editor={this.props.editor}
              />
            }
          </div>}
        </div>
      </div>
    )
  }
}

class StyleSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryCollapseState: {
        dimensions: false,
        typography: false,
        decoration: false
      }
    };

    this.components = {};

    this.categories = [{
      id: 'typography',
      name: 'Typography',
      component: StyleSectionTypographyCategory
    }, {
      id: 'dimensions',
      name: 'Container Style',
      component: StyleSectionContainerStyleCategory
    }];
  }

  toggleCategoryCollapseState(category) {
    this.setState(prevState => {
      prevState.categoryCollapseState[category] = !prevState.categoryCollapseState[category];
      return prevState;
    });
  }

  refresh() {
    if (this.props.selectedElement) {
      for (const c of this.categories) {
        if(this.components[c.id]){
          this.components[c.id].refresh();
        }
      }
    }
  }

  render() {
    if (!this.props.selectedElement) {
      return (
        <div className={style.SelectElementMessage}>Select an element before using this section</div>
      );
    }

    return (
      <div className={style.StyleSection}>
        {this.categories.map(category => {
          const CategoryComponent = category.component;
          let elName = this.props.selectedElement.get('name') || this.props.selectedElement.get('custom-name');

          if (elName){
            elName = elName.toLowerCase();
          }

          return (
            <div className={style.CollapsableCategory} key={category.id}>
              {showSection(category.id, elName) && (<div>
              <div className={style.CategoryHeader} onClick={() => this.toggleCategoryCollapseState(category.id)}>
                <div className={style.CategoryHeaderTitle}>{category.name}</div>
                <div className={style.CategoryHeaderCaret}><i className={`${this.state.categoryCollapseState[category.id] ? 'icon-caret-right' : 'icon-caret-down'}`}/></div>
              </div>
              {!this.state.categoryCollapseState[category.id] &&
                <div className={style.CategoryContent}>
                  <CategoryComponent
                    ref={(el) => this.components[category.id] = el}
                    selectedElement={this.props.selectedElement}
                    editor={this.props.editor}
                  />
                </div>
              }
              </div>)}
            </div>
          )
        })}
      </div>
    );
  }
}

class SettingsDropdownField extends React.Component {
  constructor(props) {
    super(props);

    this.options = this.props.trait.get('options').map(o => { return { id: o.value, name: o.name } });

    this.state = {
      value: this.options.find(o => o.id === (this.props.value || 'so'))
    };
  }

  refresh() {
    this.setState({value: this.options.find(o => o.id === (this.props.value || 'so'))});
  }

  onChange(items) {
    this.props.onChange(items[0].id);
  }

  render() {
    return (
      <div className={style.SettingsField}>
        <div className={style.SettingsFieldLabel}>{this.props.label}</div>
        <div style={{width: '228px'}}>
          <NewSelect
            ref={(el) => this.select = el}
            data={this.options}
            value={this.state.value}
            text="name"
            width={228}
            options={{minimumInputLength: -1}}
            onSelect={this.onChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

class SettingsCheckboxField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !!this.props.value
    };
  }

  refresh() {
    this.setState({value: !!this.props.value});
  }

  onChange(ev) {
    const newValue = ev.target.checked;

    this.setState({
      value: newValue
    });

    this.props.onChange(newValue);
  }

  render() {
    return (
      <div className={style.SettingsField}>
        <div className={style.SettingsFieldLabel}>{this.props.label}</div>
        <input
          type="checkbox"
          className="checkbox-switch-control"
          id={`checkbox-control-${this.props.label}`}
          checked={this.state.value}
          onChange={this.onChange.bind(this)}
        />
        <label htmlFor={`checkbox-control-${this.props.label}`} className="checkbox-switch"></label>
      </div>
    );
  }
}

class SettingsTextAreaField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || ''
    };
  }

  refresh() {
    this.setState({value: this.props.value});
  }

  onChange(ev) {
    this.setState({
      value: ev.target.value
    });
  }

  updateValue(ev) {
    this.props.onChange(this.state.value);
  }

  render() {
    return (
      <div className={style.SettingsField}>
        <div className={style.SettingsFieldLabel}>{this.props.label}</div>
        <textarea
          className={style.SettingsFieldTextAreaInput}
          value={this.state.value}
          onBlur={this.updateValue.bind(this)}
          onChange={this.onChange.bind(this)}
        ></textarea>
      </div>
    );
  }
}

class SettingsTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || ''
    };
  }

  refresh() {
    this.setState({value: this.props.value});
  }

  onChange(ev) {
    this.setState({
      value: ev.target.value
    });
  }

  updateValue() {
    this.props.onChange(this.state.value);
  }

  onKeyDown(ev) {
    if (ev.key === 'Enter') {
      this.updateValue();
    }
  }

  render() {
    return (
      <div className={style.SettingsField}>
        <div className={style.SettingsFieldLabel}>{this.props.label}</div>
        <input
          className={style.SettingsFieldInput}
          type="text"
          value={this.state.value}
          onBlur={this.updateValue.bind(this)}
          onChange={this.onChange.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
        />
      </div>
    );
  }
}

class SettingsSection extends React.Component {
  constructor(props) {
    super(props);

    this.components = [];

    this.state = {
      collapsed: false,
      componentKey: 0
    };
  }

  refresh() {
    if (this.props.selectedElement) {
      for (const c of this.components) {
        c.refresh();
      }
    }
  }

  toggleCollapseState() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    if (!this.props.selectedElement) {
      return (
        <div className={style.SelectElementMessage}>Select an element before using this section</div>
      );
    }

    const element = this.props.selectedElement;
    let parameters = [];

    element.get('traits').each(function(trait) {
      let name = trait.get('label') || trait.get('name');
      name = (name.charAt(0).toUpperCase() + name.slice(1)); // capitalize it

      switch(trait.get('type')) {
        case 'text':
          // ignore href trait (we have the link editor for that)
          if (trait.get('name') !== 'href') {
            parameters.push({
              name: name,
              component: SettingsTextField,
              trait: trait,
              getValue: function() {
                if (name === 'Id') {
                  return element.getId();
                }
                return  trait.getTargetValue();
              }
            });
          }
          break;

        case 'textarea':
          parameters.push({
            name: name,
            component: SettingsTextAreaField,
            trait: trait,
            getValue: function() {
              return trait.getTargetValue();
            }
          });
          break;

        case 'checkbox':
          parameters.push({
            name: name,
            component: SettingsCheckboxField,
            trait: trait,
            getValue: function() {
              return trait.getTargetValue();
            }
          });
          break;

        case 'select':
          // ignore target trait (we have the link editor for that)
          if (trait.get('name') !== 'target') {
            parameters.push({
              name: name,
              component: SettingsDropdownField,
              trait: trait,
              getValue: function() {
                return trait.getTargetValue();
              }
            });
          }
          break;
      }
    });

    this.components = [];

    return (
      <div key={`settings_${this.state.componentKey}`} className={style.CollapsableCategory}>
        <div className={style.CategoryHeader} onClick={() => this.toggleCollapseState()}>
          <div className={style.CategoryHeaderTitle}>Component Settings</div>
          <div className={style.CategoryHeaderCaret}><i className={`${this.state.collapsed ? 'icon-caret-right' : 'icon-caret-down'}`}/></div>
        </div>
        {!this.state.collapsed &&
          <div className={style.SettingFieldsContent}>
            {parameters.map((p, idx) => {
              const FieldComponent = p.component;

              return (
                <FieldComponent
                  ref={(el) => {if (el) {this.components.push(el)}}}
                  key={`${p.name}_${idx}`}
                  label={p.name}
                  value={p.getValue()}
                  trait={p.trait}
                  onChange={(value) => {
                    this.setState({componentKey: this.state.componentKey + 1});
                    p.trait.setTargetValue(value);
                  }}
                />
            )})}
          </div>
        }
      </div>
    );
  }
}

class GrapesJsEditor extends React.Component {
  constructor(props) {
    super(props);

    this.editor = null;
    this.components = {};
    this.usedGoogleFonts = {};
    this.state = {
      activeTab: 'blocks',
      selectedElement: null,
      libraryBrowserCallback: null,
      codeViewerData: null,
      mergeTagSelectorData: null,
      linkEditorData: null,
      saveAsTemplate: props.saveAsTemplate,
    };
  }

  componentDidMount() {
    // I do the import here because grapesjs uses Backbone too and do some modifications to it
    const grapesjs = require('grapesjs');
    const self = this;
    const {saveAsTemplate} = self.state;
    
    _.defer(function() {
      GrapesJsEditorInstance = self;
      
      self.editor = grapesjs.init({
        container: '#grapesjsContainer',
        plugins: [ComponentTypes],
        noticeOnUnload: false,
        storageManager: false,
        panels: {defaults: [{
          id: 'devices-actions',
          el: '#panelDevices',
          buttons: [{
            id: 'deviceDesktop',
            attributes: {class: 'fa fa-desktop'},
            command: function(editor) { editor.setDevice('Desktop') }
          }, {
            id: 'deviceTablet',
            attributes: {class: 'fa fa-tablet'},
            command: function(editor) { editor.setDevice('Tablet') }
          }, {
            id: 'deviceMobile',
            attributes: {class: 'fa fa-mobile'},
            command: function(editor) { editor.setDevice('Mobile portrait') }
          }]
        }, {
          id: 'basic-actions',
          el: '#panelBasicActions',
          buttons: [{
            id: 'viewComponents',
            attributes: {class: 'fa fa-square-o', title: 'View Outlines', 'data-tooltip-pos': 'bottom'},
            command: 'sw-visibility',
            context: 'sw-visibility'
          }, {
            id: 'viewCode',
            attributes: {class: 'fa fa-code', title: 'View code', 'data-tooltip-pos': 'bottom'},
            command: function(editor) {
              let body = editor.getHtml();

              const header = `<!doctype html>
              <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
                  <head>
                      <!--BEGIN-->
                      <!--[if gte mso 15]>
                      <xml>
                          <o:OfficeDocumentSettings>
                          <o:AllowPNG/>
                          <o:PixelsPerInch>96</o:PixelsPerInch>
                          </o:OfficeDocumentSettings>
                      </xml>
                      <![endif]-->
                      <meta charset="UTF-8">
                      <meta http-equiv="X-UA-Compatible" content="IE=edge">
                      <meta name="viewport" content="width=device-width, initial-scale=1">
                      <style>${editor.getCss()}</style>
                  </head>`;
              
              if (body !== "" && body.includes("<!--BEGIN-->") && body.includes("<!--END-->")){
                body = body.replace(body.match(/<!--BEGIN-->[\s\S]*?<!--#BEGIN-->/g), '');
                body = body.replace(body.match(/<!--END-->[\s\S]*?<!--#END-->/g), '');
              }
                
              const formattedBody = `
              <body>
                <center>
                  <!--[if (gte mso 9)|(IE)]>
                  <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                  <tr>
                  <td align="center" valign="top" width="600" style="width:600px;">
                  <![endif]--><!--#BEGIN-->
                    ${body}
                  <!--END-->
                  <!--[if (gte mso 9)|(IE)]>
                  </td>
                  </tr>
                  </table>
                  <![endif]-->
                </center>
                <!--#END-->
                <br>
              </body>`
              
              const html = `${header}${formattedBody}</html>`;
              const htmlAndCss = Juice(html);

              self.openCodeViewer(htmlAndCss, function(content) {
                editor.setComponents(content);
              });
            }
          }, {
            id: 'undo',
            attributes: {class: 'fa fa-undo', title: 'Undo', 'data-tooltip-pos': 'bottom'},
            command: function(editor) { editor.runCommand('core:undo') }
          }, {
            id: 'redo',
            attributes: {class: 'fa fa-repeat', title: 'Redo', 'data-tooltip-pos': 'bottom'},
            command: function(editor) { editor.runCommand('core:redo') }
          }, {
            id: 'clearCanvas',
            attributes: {class: 'fa fa-trash', title: 'Clear canvas', 'data-tooltip-pos': 'bottom'},
            command: function(editor) {
              var mbContent = {
                accept_is_negative: true,
                message: Handlebars.compile('Are you sure you want to clear the editor?'),
                icon: 'icon-trashcan'
              };

              MessageBox.showYesNo(mbContent, self.props.parent, function() {
                editor.runCommand('core:canvas-clear')
              });
            },
          }, {
            id: 'saveAsTemplate',
            label: `<div class="save-template-switch-container">
                      <input type="checkbox" class="form-control editable-field checkbox-switch-control" id='saveAsTemplate' name='saveAsTemplate' ${saveAsTemplate ? 'checked' : ''}>
                      <label for='saveAsTemplate' class="editable-field checkbox-switch"></label>
                      <label class="label control-label" for="saveAsTemplate">Save as Template</label>
                    </div>`,
            attributes: {title: 'Save as template', 'data-tooltip-pos': 'bottom'},
            command: function(editor, opt) {
              const {saveAsTemplate} = self.state;
              opt.attributes.label = `<div class="save-template-switch-container">
                <input type="checkbox" class="form-control editable-field checkbox-switch-control" id='saveAsTemplate' name='saveAsTemplate' ${!saveAsTemplate ? 'checked' : ''}>
                <label for='saveAsTemplate' class="editable-field checkbox-switch"></label>
                <label class="label control-label" for="saveAsTemplate">Save as Template</label>
              </div>`
              self.setState({saveAsTemplate: !saveAsTemplate})
              self.props.onSaveAsTemplateChange(!saveAsTemplate)
            }
          }]
        }]},
        blockManager: {
          appendTo: '#grapesjsBlocks'
        },
        canvasCss: '.gjs-selected { outline: 3px solid #3b97e3 !important; outline-offset: 1px !important;} body { padding:50px 0}'
      });

      self.editor.Keymaps.removeAll();

      const blockManager = self.editor.BlockManager;

      for (const block of Blocks) {
        blockManager.add(block.id, {
          label: block.name,
          category: block.category,
          content: block.content,
          attributes: block.attributes || {}
        });
      }

      self.numChangesCountEventsToIgnore = 0;
      self.currentContent = '';

      let content = (self.props.content || '').trim();

      if (content) {
        // replace spaces by an empty span
        content = content.replace(/&nbsp;/g, "<span> </span>");
        content = Juice(content);
        self.editor.setComponents(content);
        self.numChangesCountEventsToIgnore = 2;
      }

      self.editor.on('component:selected', function(model) {
        self.setState({selectedElement: model});
        self.components.styleSection.refresh();
        self.components.settingsSection.refresh();
      });

      self.editor.on('component:deselected', function(model) {
        if (self.state.selectedElement && (model.cid === self.state.selectedElement.cid)) {
          self.setState({selectedElement: null});
          self.components.styleSection.refresh();
          self.components.settingsSection.refresh();
        }
      });

      self.editor.on('component:add', function(model) {
        if (model.get('activeOnRender')) {
          self.setState({selectedElement: model});
          self.components.styleSection.refresh();
          self.components.settingsSection.refresh();
        }
      });

      self.editor.on('styleable:change:width', function(model) {
        if (self.components.styleSection &&
            self.components.styleSection.components.dimensions &&
            self.components.styleSection.components.dimensions.components.width) {
          const newWidth = model.changed.style.width;
          self.components.styleSection.components.dimensions.components.width.sizeInput.setValue(newWidth);
        }

        if(self.components.styleSection.props.selectedElement && self.components.styleSection.props.selectedElement.get('tagName') === "img"){
          const newWidth = 600 * model.changed.style.width.replace(/[^0-9]/g, '') / 100;
          self.components.styleSection.props.selectedElement.attributes.attributes.width =  newWidth;
        }
      });

      self.editor.on('styleable:change:height', function(model) {
        if (self.components.styleSection &&
            self.components.styleSection.components.dimensions &&
            self.components.styleSection.components.dimensions.components.height) {
          const newHeight = model.changed.style.height;
          self.components.styleSection.components.dimensions.components.height.sizeInput.setValue(newHeight);
        }
      });

      self.editor.on('change:changesCount', function() {
        if (self.editor) {
          let body = self.editor.getHtml();

          const header = `<!doctype html>
          <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
              <head>
                  <!--BEGIN-->
                  <!--[if gte mso 15]>
                  <xml>
                      <o:OfficeDocumentSettings>
                      <o:AllowPNG/>
                      <o:PixelsPerInch>96</o:PixelsPerInch>
                      </o:OfficeDocumentSettings>
                  </xml>
                  <![endif]-->
                  <meta charset="UTF-8">
                  <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  <meta name="viewport" content="width=device-width, initial-scale=1">
                  <style>${self.editor.getCss()}</style>
              </head>`;
          
          if (body !== "" && body.includes("<!--BEGIN-->") && body.includes("<!--END-->")){
            body = body.replace(body.match(/<!--BEGIN-->[\s\S]*?<!--#BEGIN-->/g), '');
            body = body.replace(body.match(/<!--END-->[\s\S]*?<!--#END-->/g), '');
          }
            
          const formattedBody = `
          <body>
            <center>
              <!--[if (gte mso 9)|(IE)]>
              <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
              <tr>
              <td align="center" valign="top" width="600" style="width:600px;">
              <![endif]--><!--#BEGIN-->
                ${body}
              <!--END-->
              <!--[if (gte mso 9)|(IE)]>
              </td>
              </tr>
              </table>
              <![endif]-->
            </center>
            <!--#END-->
            <br>
          </body>`
              
          const html = `${header}${formattedBody}</html>`;
          const htmlAndCss = Juice(html);

          // this is to avoid call onChange when the content is loaded the first time
          if (self.numChangesCountEventsToIgnore > 0) {
            --self.numChangesCountEventsToIgnore;
              self.currentContent = htmlAndCss;
          } else if (htmlAndCss !== self.currentContent) {
            self.currentContent = htmlAndCss;
            self.props.onChange(htmlAndCss);
          }
        }
      });

      self.editor.Commands.add('open-assets', {
        run: function(editor, _, info) {
          self.openLibraryBrowser(function(image) {
            info.target.set('src', image.url);
          });
        }
      });

      if (self.props.mergeTagItems) {
        self.editor.RichTextEditor.add('custom-vars', {
          icon: `
            <div
              class="GrapesJsMergeTagButton"
              style="font-size: 13px; color: #dddddd"
            >
              <span style="margin-right: 10px">Use a Merge Tag</span>
              <span style="font-size: 10px">&#x25bc;</span>
            </div>`,
          event: 'click',
          result: (rte, action) => {
            const selection = rte.selection();
            self.openMergeTagSelector(rte, selection.anchorOffset, selection.focusOffset, action.btn);
          }
        });
      }
    });
  }

  changeElementFont(element, font) {
    let currentStyle = element.getStyle() || {};
    let currentFont = Fonts.find(f => f.name === currentStyle['font-family']);

    if (currentFont && currentFont.link && (currentFont.id in this.usedGoogleFonts)) {
      --this.usedGoogleFonts[currentFont.id];

      if (this.usedGoogleFonts[currentFont.id] === 0) {
        const component = this.editor.DomComponents.getWrapper().find(`link[id=${currentFont.id}]`);
        if (component.length > 0) {
          component[0].remove();
        }
      }
    }

    if (font.link) {
      if (!(font.id in this.usedGoogleFonts)) {
        this.usedGoogleFonts[font.id] = 0;
      }
      ++this.usedGoogleFonts[font.id];

      if (this.usedGoogleFonts[font.id] === 1) {
        const link = `<link id="${font.id}" href="${font.link}" rel="stylesheet">`;
        this.editor.getComponents().add(link);
      }
    }

    updateStyle(element, {'font-family': font.name});
  }

  libraryBrowserFilter(file) {
    return Utilities.getTypeIcon(file.ext).type === 'image-file';
  }

  openLibraryBrowser(callback) {
    this.setState({libraryBrowserCallback: callback});
  }

  onImageSelected(image) {
    this.state.libraryBrowserCallback(image);
    this.setState({libraryBrowserCallback: null});
  }

  openCodeViewer(content, callback) {
    this.setState({
      codeViewerData: {
        content: content,
        callback: callback
      }
    });
  }

  onHtmlCodeModified(content) {
    this.state.codeViewerData.callback(content);
    this.setState({codeViewerData: null});
  }

  openMergeTagSelector(rte, selectionStart, selectionEnd, el) {
    const position = $(el).offset();

    this.setState({
      mergeTagSelectorData: {
        rte: rte,
        selectionStart: selectionStart,
        selectionEnd: selectionEnd,
        top: position.top,
        left: position.left
      }
    });
  }

  openLinkEditor(component, isNew) {
    this.setState({
      linkEditorData: {
        component: component,
        isNew: isNew
      }
    });
  }

  render() {
    return (
      <div>
        <div
          ref={(el) => this.editorDomElement = el}
          className={style.GrapesJsEditor}
        >
          <div className={style.EditSection}>
            <div id="panelTop" className={style.PanelTop}>
                <div id="panelDevices" className={style.PanelDevices}></div>
                <div id="panelBasicActions" className={style.PanelBasicActions}></div>
            </div>
            <div className={style.EditorContainer} id="grapesjsContainer"/>
          </div>
          <div className={style.Sections}>
            <div className={style.Header}>
              <div className={`${style.HeaderTab} ${this.state.activeTab === 'blocks' ? style.TabActive : ''}`} onClick={() => this.setState({activeTab: 'blocks'})}>
                <div className={style.TabIcon}><i className="icon-blocks"/></div>
                <div className={style.TabTitle}>Blocks</div>
              </div>
              <div className={`${style.HeaderTab} ${this.state.activeTab === 'style' ? style.TabActive : ''}`} onClick={() => this.setState({activeTab: 'style'})}>
                <div className={style.TabIcon}><i className="icon-des-pencil"/></div>
                <div className={style.TabTitle}>Style</div>
              </div>
              <div className={`${style.HeaderTab} ${this.state.activeTab === 'settings' ? style.TabActive : ''}`} onClick={() => this.setState({activeTab: 'settings'})}>
                <div className={style.TabIcon}><i className="icon-cog2"/></div>
                <div className={style.TabTitle}>Settings</div>
              </div>
            </div>
            <div className={style.SectionContainer}>
              <div id="grapesjsBlocks" className={`${this.state.activeTab !== 'blocks' ? style.SectionHidden : ''}`}/>
              <div className={`${this.state.activeTab !== 'style' ? style.SectionHidden : ''}`}>
                <StyleSection
                  ref={(el) => this.components.styleSection = el}
                  selectedElement={this.state.selectedElement}
                  editor={this}
                />
              </div>
              <div className={`${this.state.activeTab !== 'settings' ? style.SectionHidden : ''}`}>
                <SettingsSection
                  ref={(el) => this.components.settingsSection = el}
                  selectedElement={this.state.selectedElement}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.libraryBrowserCallback && <LibraryBrowser
            folderId="public"
            filter={this.libraryBrowserFilter.bind(this)}
            parent={this.props.parent}
            onCancel={() => this.setState({libraryBrowserCallback: null})}
            onFileSelected={this.onImageSelected.bind(this)}
        />}
        {this.state.codeViewerData && <CodeViewer
          content={this.state.codeViewerData.content}
          onCancel={() => this.setState({codeViewerData: null})}
          onAccept={this.onHtmlCodeModified.bind(this)}
        />}
        {this.state.mergeTagSelectorData && <MergeTagSelector
          rte={this.state.mergeTagSelectorData.rte}
          selectionStart={this.state.mergeTagSelectorData.selectionStart}
          selectionEnd={this.state.mergeTagSelectorData.selectionEnd}
          top={this.state.mergeTagSelectorData.top}
          left={this.state.mergeTagSelectorData.left}
          mergeTags={this.props.mergeTagItems}
          parent={this.props.parent}
          editorDomElement={this.editorDomElement}
          onClose={() => this.setState({mergeTagSelectorData: null})}
        />}
        {this.state.linkEditorData && <LinkEditor
          component={this.state.linkEditorData.component}
          isNew={this.state.linkEditorData.isNew}
          onClose={() => this.setState({linkEditorData: null})}
        />}
      </div>
    );
  }
}

export default GrapesJsEditor;

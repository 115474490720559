import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import AutomationItemView from 'app/automations/automation/automation'
import AutomationReactContainerView from 'app/automations/automation'
import AutomationModel from 'js/models/automation'
import Automation2Model from 'js/models/automation2'
import AutomationsCollection from 'js/collections/automations'
import Automations2Collection from 'js/collections/automations2'
import vent from 'js/vent'
import app from 'js/app'
import automationsViewTemplate from 'app/automations/automations.handlebars'
import sidebarViewTemplate from 'app/automations/sidebar.handlebars'
import sidebarItemViewTemplate from 'app/automations/sidebar_item.handlebars'


var SidebarItemView = Marionette.Layout.extend({
    tagName: 'li',
    className: function() {
        return this.model.get('running') ? '' : 'paused';
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    template: Handlebars.compile(sidebarItemViewTemplate),
    ui: {
        toggle_status: '.toggle-status'
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            this.parent.showAutomation(this.model);
        },
        'click .toggle-status': function(ev) {
            var view = this;

            ev.preventDefault();
            ev.stopPropagation();

            // Show immediate feedback in UI
            this.model.set('running', !this.model.get('running'));
            this.onStatusChange();

            this.model.save({
                running: this.model.get('running')
            }, {
                patch: true,
                error: function() {
                    // Change back if there is an error
                    // TODO: show feedback on run/pause button if switched back
                    view.onStatusChange();
                }
            });
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
        this.listenTo(this.model, 'sync', this.onStatusChange);
    },
    onRender: function() {
        this.ui.toggle_status.tooltip({
            title: this.model.get('running') ? 'Pause Automation' : 'Run Automation'
        });
    },
    onStatusChange: function() {
        var running = this.model.get('running');

        this.$el.toggleClass('paused', !running);
        this.ui.toggle_status
            .tooltip('hide')
            .attr('data-original-title', running ? 'Pause Automation' : 'Run Automation')
            .tooltip('fixTitle');
    }
});

var SidebarView = Marionette.CompositeView.extend({
    className: 'list-wrapper has-floating-header at-top',
    template: Handlebars.compile(sidebarViewTemplate),
    itemView: SidebarItemView,
    itemViewOptions: function(model) {
        return {
            model: model,
            parent: this
        };
    },
    ui: {
        'items': '.automations-list .basic-list',
        'newItem': '.new-item'
    },
    events: {
        'click .new-item': function(ev) {
            ev.preventDefault();

            $(ev.currentTarget).tooltip('hide');

            this.parent.showNewAutomation();
        }
    },
    initialize: function(options) {
        this.parent = options.parent;

        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
            this.collection = new Automations2Collection();
        }
        else {
            this.collection = new AutomationsCollection();
        }

        this.fetchCollection();

        this.listenTo(vent, 'automation:save', function() {
            this.fetchCollection();
        });

        this.listenTo(this.collection, 'sync', function() {
            if (this.selected) {
                this.highlightItem.apply(this, this.selected);
            }
        });
    },
    fetchCollection: function() {
        this.collection.fetch({
            rows: -1
        });
    },
    onRender: function() {
        this.listenTo(this.parent, 'select', this.highlightItem);

        this.$el.find('[data-toggle="tooltip"]').tooltip();
    },
    appendHtml: function(collectionView, itemView){
        collectionView.ui.items.append(itemView.el);
    },
    showAutomation: function(model) {
        this.parent.showAutomation(model);
    },
    highlightItem: function(type, model) {
        this.selected = arguments;

        var activeEl = this.$el.find(".automations-list ul li[id='" + model.get('id') + "']");

        activeEl.addClass('active');
        this.$el.find('li.active').not(activeEl).removeClass('active');
    }
});

// TODO: Apply 'details-visible' class to this view, and remove after detail view is closed
export default Marionette.Layout.extend({
    className: 'automations-section',
    template: Handlebars.compile(automationsViewTemplate),
    regions: {
        'sidebarRegion': '#automations-sidebar',
        'contentRegion': '#automations-view-pane'
    },
    initialize: function() {
        this.automationToShow = null;
    },
    onRender: function() {
        var type = this.options.type,
            id = this.options.id,
            edit = this.options.edit;

        if (type === 'automation') {
            if (id) {
                this.automationToShow = {
                    id: id,
                    edit: edit
                };
            }
            else {
                this.showNewAutomation();
            }
        }

        // ...
        var sidebar = new SidebarView({
            parent: this
        });

        var view = this;

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
            this.listenTo(vent, 'automation:save', function (model) {
                view.automationToShow = {
                    id: model.get('id'),
                    edit: false
                };
            });
        }

        this.listenTo(vent, 'automation:delete', function() {
            // if there are automations, select the first one
            if (sidebar.collection.length > 0) {
                view.showAutomation(sidebar.collection.models[0], false);
            }

            sidebar.$el.find('.content-container').nanoScroller();
        });

        this.listenTo(sidebar.collection, 'sync', function() {
            if (view.automationToShow) {
                var model = sidebar.collection.get(view.automationToShow.id);

                if (model) {
                    view.showAutomation(model, view.automationToShow.edit);
                }

                view.automationToShow = null;
            }

            sidebar.$el.find('.content-container').nanoScroller();
        });

        this.sidebarRegion.show(sidebar);
    },
    showAutomation: function(model, editing) {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
            this.contentRegion.show(new AutomationReactContainerView({
                automationModel: model,
                parent: this
            }));
        }
        else {
            this.contentRegion.show(new AutomationItemView({
                model: model,
                parent: this,
                editing: editing
            }));
        }
        this.trigger('select', 'automation', model);
    },
    showNewAutomation: function() {
        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
            this.showAutomation(new Automation2Model());
        }
        else {
            this.showAutomation(new AutomationModel(), true);
        }
    },
    closeItem: function() {
        this.contentRegion.reset();
    },
    getUrl: function() {
        var url = ['automations'];

        var contentRegion = this.contentRegion && this.contentRegion.currentView;
        if (contentRegion) {
            url.push(contentRegion.getUrl());
        }
        return url.join('/');
    },
    getParams: function() {
        var view = this.contentRegion && this.contentRegion.currentView;
        if (view) {
            return view.getParams();
        } else {
            return {};
        }
    }
});

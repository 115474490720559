import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import PDFViewerView from 'js/views/pdf_viewer'
import ContentFolderModel from 'js/models/content_folder'
import ModalRegion from 'js/views/base/modal-region'
import MessageBox from 'js/views/message_box'
import Utilities from 'js/utils/utilities'
import generatorTemplate from 'templates/template_generator.handlebars'
import itemTemplate from 'templates/template_generator_template.handlebars'
import generatingTemplate from 'templates/generating_template.handlebars'
import TemplateGeneratorFillCustomInputsView from 'js/react_views/template_generator_custom_inputs/template_generator_custom_inputs'
import AppConfig from 'app/app-config'

var TemplateModel = Backbone.Model.extend({
});

var TemplateItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'template-item',
    template: Handlebars.compile(itemTemplate),

    events: {
        'click': function(ev) {
            ev.preventDefault();
            this.trigger('select');
        }
    },

    onRender: function() {
    }
});

var TemplatesListView = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'template-list',
    itemView: TemplateItemView,

    onRender: function() {
        this.listenTo(this, 'itemview:select', function(itemView) {
            this.trigger('templates-list:show-template', itemView);
        });
    }
});

var GeneratingView = Marionette.Layout.extend({
    className: 'loading-view',
    template: Handlebars.compile(generatingTemplate),
    templateHelpers: function() {
        return {
            title: 'Creating ' + this.options.fileExt + ' from Template...',
            message: 'This process takes up to one minute.'
        };
    }
});


var DefaultViewerView = Marionette.Layout.extend({
    template: Handlebars.compile('<div class="caption"></div>'),
    ui: {
        caption: '.caption'
    },
    setCaption: function(caption) {
        this.ui.caption.text(caption);
    }
});

// ...
export default Marionette.Layout.extend({
    template: Handlebars.compile(generatorTemplate),
    regions: {
        defaultViewerRegion: '.default-viewer-region',
        pdfViewerRegion: '.pdf-viewer-region',
        templatesListRegion: '.templates-region',
        generatingRegion: {
            selector: '.template-generating',
            regionType: ModalRegion.extend({backdrop: 'static'})
        },
        customInputsRegion: {
            selector: '.custom-inputs',
            regionType: ModalRegion.extend({backdrop: 'static'}),
        },
    },
    ui: {
        generate_button: '#generate',
        cancel_button: '#cancel',
        download_button: '#download',
        header_title: '.title'
    },
    events: {
        'click #generate': function(ev) {
            ev.preventDefault();

            if ($(ev.currentTarget).hasClass('disabled')) {
                return;
            }

            this.findCustomInputsInFile();
        },
        'click #cancel': function(ev) {
            ev.preventDefault();
            this.discardGenerateFile();
            this.trigger('template-generator:close');
        },
        'click #download': function(ev) {
            ev.preventDefault();

            if ($(ev.currentTarget).hasClass('disabled')) {
                return;
            }

            this.attachTemplateToDeal(this.generatedTemplateId);
        }
    },

    initialize: function(options) {
        this.options = options;
        this.templateId = null;
        this.generatedTemplateId = null;
        this.generatingTemplateExt = null;
        this.activeTemplate = null;
        this.templateViewers = {};
        this.templateViewerRegionActive = null;
        this.custom_input_values = {};
        this.custom_inputs = [];
    },

    onShow: function() {
        this.$el.parent().addClass('template-modal');
        this.setButtonsLayout('init');
        this.fetchTemplates();
    },

    generateTemplate: function() {
        if (this.templateId) {
            this.generatingRegion.show(new GeneratingView({
                fileExt: this.getDisplayableExt(this.activeTemplate.model.get('ext'))
            }));

            this.generatedTemplateId = null;
            this.generatingTemplateExt = this.activeTemplate.model.get('ext');

            var that = this;
            var data = {
                'content_file_id': this.templateId,
                'deal_id': this.options.deal.get('id'),
                'custom_inputs': this.custom_input_values,
            };

            $.post('/pdf_generation', JSON.stringify(data), function(response) {
                let sendPdfNotification = AppConfig.getValue("send_pdf_generation_email", true)

                if (sendPdfNotification) {
                    var mbContent = {
                        message: '<b>We will notify you when the document is ready for review.</b>',
                        icon: 'icon-warning',
                    };
    
                    MessageBox.showOk(mbContent, that, function() { // yes
                        that.trigger('template-generator:close');
                    });
                } else {
                    that.waitForGeneration(response.id);
                }
                
                /*
                    We might return this solution later. Dennis is in talks with the guys from ASPOSE.
                    -- for future reference return the code below and delete the Message Box above
                    that.waitForGeneration(response.id);
                */
            });
        }
    },

    findCustomInputsInFile: function() {
        var self = this;
        this.custom_inputs = [];

        this.generatingRegion.show(new GeneratingView({
            fileExt: this.getDisplayableExt(this.activeTemplate.model.get('ext'))
        }));

        $.ajax({
            url: '/custom_inputs/' + self.templateId,
            type: 'GET',

            success: function(data) {
                self.custom_inputs = data;
            },
            complete: function() {
                self.generatingRegion.reset();
                self.fillCustomInputs();
            }
        });
    },

    fillCustomInputs: function() {
        let self = this;

        if (this.custom_inputs.length === 0) {
            this.generateTemplate();
            return;
        }

        const handleSave = function (data) {
            self.custom_input_values = data;
            self.generateTemplate();
        }

        this.customInputsRegion.show(new TemplateGeneratorFillCustomInputsView({
            fields: self.custom_inputs,
            onSave: handleSave,
            view: self,
        }));
    },

    discardGenerateFile: function() {
        if (this.generatedTemplateId) {

            $.ajax({
                url: '/content_files/' + this.generatedTemplateId,
                type: 'DELETE'
            });

            this.generatedTemplateId = null;
            this.generatingTemplateExt = null;
        }
    },

    waitForGeneration: function(id) {
        var that = this;

        setTimeout(function() {
            if (that.isClosed) {
                return;
            }

            // is the generation still in progress?
            $.get('/pdf_generation/' + id, function(response) {
                var mbContent;

                if (response.status === 'finished') {
                    that.generatedTemplateId = response.dst_content_file_id;
                    that.displayTemplate(that.generatingTemplateExt, that.generatedTemplateId, {
                        resetView: true,
                        caption: 'Document generated'
                    });
                    that.generatingRegion.reset();
                    that.showTemplateRegion(false);
                    that.setButtonsLayout('final');

                    // ...
                    mbContent = {
                        message: '<b>New ' + that.getDisplayableExt(that.generatingTemplateExt) + ' created from template.</b>',
                        icon: Utilities.getTypeIcon(that.generatingTemplateExt).icon,
                        accept_button_text: 'Ok'
                    };

                    MessageBox.showOk(mbContent, that);
                }
                else if (response.status === 'error') {
                    that.generatingRegion.reset();

                    mbContent = {
                        message: '<b>' + that.getDisplayableExt(that.generatingTemplateExt) + ' creation failed, please try again.</b>',
                        icon: 'icon-warning',
                        accept_button_text: 'Retry',
                        cancel_button_text: 'Close'
                    };

                    MessageBox.showYesNo(mbContent, that, function() { // retry
                        that.showTemplateRegion(true);
                        that.setActiveTemplate(that.templatesListRegion.currentView.children.findByIndex(0));
                        that.setButtonsLayout('init');
                    }, function() { // cancel
                        that.trigger('template-generator:close');
                    });
                }
                else {
                    that.waitForGeneration(id);
                }
            });
        }, 2000);
    },

    fetchTemplates: function() {
        var templatesFolder = new ContentFolderModel({ id: 'document_templates' });
        var that = this;

        this.templatesCollection = new Backbone.Collection();

        templatesFolder.fetch({
            url: templatesFolder.url() + '?detail=extended',
            success: function() {
                var files = templatesFolder.get('child_files');

                // sort by date
                files.sort(function(a, b) {
                    if (a.modified > b.modified) { return -1; }
                    if (b.modified > a.modified) { return 1; }
                    return 0;
                });

                // ...
                for (var i = 0; i < files.length; ++i) {
                    var file = files[i];
                    var thumb = null;
                    var icon = null;

                    if (file.has_thumb) {
                        thumb = app.options.apiUrl + '/content_files/' + file.id + '?thumb&client=' + app.user.get('client').short_id;
                    }
                    else {
                        icon = Utilities.getTypeIcon(file.ext).icon;
                    }

                    var templateModel = new TemplateModel({
                        'name': file.name,
                        'id': file.id,
                        'thumbnail': thumb,
                        'icon': icon,
                        'ext': file.ext
                    });

                    that.templatesCollection.add(templateModel);
                }

                // first template is active
                if (files.length > 0) {
                    that.showTemplateRegion(true);
                    that.setActiveTemplate(that.templatesListRegion.currentView.children.findByIndex(0));
                }
                else {
                    var mbContent = {
                        message: '<b><p>Please upload your templates to Content > Document Templates.</p></b>',
                        icon: 'icon-warning',
                        accept_button_text: 'Close'
                    };

                    MessageBox.showOk(mbContent, that, function() {
                        that.trigger('template-generator:close');
                    });
                }
            }
        });
    },

    attachTemplateToDeal: function(file_id) {
        var that = this;

        this.options.deal.fetch({
            success: function() {
                var files = [];
                var gf = that.options.deal.get('generated_files');

                for (var i = 0; i < gf.length; ++i)
                    files.push(gf[i].id);

                files.push(file_id);

                that.options.deal.save({
                    generated_files: files
                }, {
                    patch: true,
                    success: function() {
                        that.trigger('template-generator:generated_and_saved');
                    }
                });

            }
        });
    },

    showTemplateRegion: function(show) {
        if (!show) {
            this.templatesListRegion.reset();
        }
        else {
            var templatesList = new TemplatesListView({collection: this.templatesCollection});
            var that = this;

            this.listenTo(templatesList, 'templates-list:show-template', function(itemView) {
                that.setActiveTemplate(itemView);
            });

            this.templatesListRegion.show(templatesList);
        }
    },

    setActiveTemplate: function(itemView) {
        if (itemView !== this.activeTemplate) {
            var model = itemView.model;

            if (this.activeTemplate) {
                this.activeTemplate.$el.removeClass('selected');
            }

            this.templateId = model.get('id');
            this.displayTemplate(model.get('ext'), this.templateId, {caption: model.get('name')});

            this.activeTemplate = itemView;
            this.activeTemplate.$el.addClass('selected');
        }
    },

    setButtonsLayout: function(layout) {
        if (layout === 'final' ) {
            this.ui.generate_button.addClass('button-muted disabled');
            this.ui.generate_button.removeClass('button-green');
            this.ui.download_button.removeClass('button-muted disabled');
            this.ui.header_title.addClass('invisible');
        }
        else {
            this.ui.generate_button.removeClass('button-muted disabled');
            this.ui.generate_button.addClass('button-green');
            this.ui.download_button.addClass('button-muted disabled');
            this.ui.header_title.removeClass('invisible');
        }
    },

    getTemplateViewer: function(type) {
        var viewer;
        switch(type) {
            case 'pdf':
                if (this.templateViewers[type]) {
                    return this.templateViewers[type];
                }

                // ...
                viewer = new PDFViewerView();
                this.templateViewers[type] = viewer;

                this.listenTo(viewer, 'pdf-viewer:toggle-fullscreen', function() {
                    this.$el.parent().toggleClass('fullscreen');
                });

                this.pdfViewerRegion.show(viewer);
                return viewer;

            default:
                if (this.templateViewers['default']) {
                    return this.templateViewers['default'];
                }

                // ...
                viewer = new DefaultViewerView();
                this.templateViewers['default'] = viewer;
                this.defaultViewerRegion.show(viewer);
                return viewer;
        }
    },

    displayTemplate: function(fileExt, templateId, options) {
        options = options || {};

        var region = null;

        switch(fileExt) {
            case '.pdf':
                region = this.pdfViewerRegion;
                this.getTemplateViewer('pdf').showPDF(templateId, options.resetView);
                break;

            default:
                region = this.defaultViewerRegion;
                this.getTemplateViewer('default').setCaption(options.caption);
                break;
        }

        if (this.templateViewerRegionActive !== region) {
            if (this.templateViewerRegionActive) {
                this.templateViewerRegionActive.$el.hide();
            }

            this.templateViewerRegionActive = region;
            this.templateViewerRegionActive.$el.show();
        }
    },

    getDisplayableExt: function(ext) {
        return ext.toUpperCase().substring(1);
    }
});

import React from 'react';

import app from 'js/app';
import guid from 'js/utils/guid';
import PopoverMenu from 'app_v2/components/popover_menu/menu';

import style from './finder.css';


class DragManager {
    constructor(contentManager) {
        this.contentManager = contentManager;
        this.dragging = false;
        this.draggingGroupId = null;
        this.draggingGroupIsInsideFolder = false;
        this.contentComponent = null;
        this.foldersOver = [];
    }

    enterFolder(folderId) {
        this.foldersOver.push(folderId);

        if (this.contentComponent) {
            this.contentComponent.manageDropOutline();
        }
    }

    leaveFolder(folderId) {
        this.foldersOver = this.foldersOver.filter(f => f !== folderId);

        if (this.contentComponent) {
            this.contentComponent.manageDropOutline();
        }
    }

    isOverAFolder() {
        return this.foldersOver.length > 0;
    }

    setContentComponent(component) {
        this.contentComponent = component;
    }

    isDraggingGroupInsideFolder() {
        return this.draggingGroupIsInsideFolder;
    }

    dragStarted(groupId) {
        this.dragging = true;
        this.draggingGroupId = groupId;
        this.draggingGroupIsInsideFolder = this.contentManager.isElementInsideFolder(groupId);
        this.foldersOver = [];
    }

    dragEnded() {
        this.dragging = false;

        if (this.foldersOver.length > 0) {
            const folder = this.foldersOver[this.foldersOver.length - 1];
            this.contentManager.moveElement(this.draggingGroupId, folder);
        } else  if (this.draggingGroupIsInsideFolder) {
            this.contentManager.moveElement(this.draggingGroupId, null);
        }

        this.contentManager.saveUserStructure();
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.multipleSections = this.props.sections?.length > 1;
    }

    handleClick() {
        // todo: implement
    }

    render() {
        return (
            <div className={style.fHeader}>
                <div
                    className={`
                        ${style.hName}
                        ${this.multipleSections ? style.tSelectable : ''}
                    `}
                    onClick={this.handleClick.bind(this)}
                >
                    <div>{this.props.activeSection?.name || ''}</div>

                    {this.multipleSections > 0 &&
                        <div className={`${style.tIcon} icon-caret-down`}/>
                    }
                </div>

                <div
                    className={`
                        ${style.hToggle}
                        ${this.props.collapsed ? 'icon-arrow-right' : 'icon-arrow-left'}
                    `}
                    onClick={this.props.onFinderToggle}
                />
            </div>
        );
    }
}

class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            popoverVisible: false,
            addNewOptions: []
        };
    }

    componentDidMount() {
        this.setCreateOptions({
            createGroups: this.props.createGroups,
            createFolders: this.props.createFolders
        });
    }

    handleSearchTermChange(ev) {
        const value = ev.target.value;

        this.setState({
            searchTerm: value
        });

        this.props.onSearchTermChange(value);
    }

    handlePopoverMenuClose(optionId) {
        let newState = {
            popoverVisible: false
        };

        if (optionId) {
            newState.searchTerm = '';

            if (this.state.searchTerm) {
                this.props.onSearchTermChange('');
            }

            if (optionId === 'newFolder') {
                this.props.onNewFolder();
            } else if (optionId === 'newGroup') {
                const args = this.state.addNewOptions.find(o => o.id === optionId).args || null;
                this.props.onNewGroup(args);
            }
        }

        this.setState(newState);
    }

    searchGainFocus() {
        this.input.focus();
    }

    searchLostFocus() {
        this.input.blur();
    }

    setCreateOptions(options) {
        const createGroups = 'createGroups'in options ? options.createGroups : this.props.createGroups;
        const createFolders = 'createFolders'in options ? options.createFolders : this.props.createFolders;
        let addNewOptions = [];

        if (createGroups) {
            let option = {
                id: 'newGroup',
                title: 'Add New Group'
            };

            if (_.isObject(createGroups)) {
                option.args = {
                    allowedTypes: _.clone(createGroups)
                };
            }

            addNewOptions.push(option);
        }

        if (createFolders) {
            addNewOptions.push({
                id: 'newFolder',
                title: 'Add New Folder'
            });
        }

        this.setState({
            addNewOptions: addNewOptions
        });
    }

    render() {
        let popoverArgs = {};

        if (this.state.popoverVisible && this.addNewButton) {
            const bbox = this.addNewButton.getBoundingClientRect();

            popoverArgs = {
                top: bbox.top + bbox.height,
                left: bbox.left + (bbox.width / 2),
                items: this.state.addNewOptions
            };
        }

        return (
            <div className={style.fToolbar}>
                <div className={style.tFirstRow}>
                    <div className={style.tTitle}>Groups</div>

                    <div
                        ref={(el) => this.addNewButton = el}
                        className={style.tNewButton}
                        style={{
                            visibility: this.state.addNewOptions.length > 0 ? 'visible' : 'hidden'
                        }}
                        onClick={() => this.setState({popoverVisible: true})}
                    >
                        + Add New
                    </div>
                </div>
                <div className={style.tSecondRow}>
                    <input
                        ref={(el) => this.input = el}
                        className={style.tSearch}
                        placeholder='Search Groups'
                        value={this.state.searchTerm}
                        onChange={this.handleSearchTermChange.bind(this)}
                    />

                    <div className={`${style.tSearchIcon} icon-search`}/>
                </div>

                {this.state.popoverVisible &&
                    <PopoverMenu
                        {...popoverArgs}
                        onClose={this.handlePopoverMenuClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

class Group extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            badgeValue: null
        };
    }

    handlePin(ev) {
        ev.stopPropagation();
        this.props.onElementPinChanged(this.props.id, !this.props.pinned);
    }

    componentDidMount() {
        this.mounted = true;

        this.updateBadge();

        if (this.props.draggable) {
            const self = this;
            const el = $(this.component);

            el.draggable({
                handle: `.${style.gHandler}`,
                helper: 'clone',
                axis: 'y',
                start: function(_, ui) {
                    ui.helper.addClass(style.gDragging);
                    self.props.onDragStarted(self.props.id);
                },
                stop: function() {
                    self.props.onDragEnded();
                }
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    updateBadge() {
        if (this.props.badge) {
            const self = this;

            if (_.isFunction(this.props.badge)) {
                this.props.badge(function(value) {
                    if (!self.mounted) {
                        return;
                    }

                    self.setState({
                        badgeValue: value
                    });
                });
            } else {
                this.setState({
                    badgeValue: this.props.badge
                });
            }
        }
    }

    render() {
        const active = this.props.id === this.props.activeGroupId;

        return (
            <div
                ref={(el) => this.component = el}
                className={`
                    ${style.cGroup}
                    ${active ? style.gActive : ''}
                `}
                onClick={() => this.props.onGroupSelected(this.props.id)}
            >
                {!this.props.fixed && !this.props.adhoc && this.props.draggable &&
                    <div className={style.gHandler}><div/></div>
                }

                <div className={`
                    ${style.gIcon}
                    ${this.props.icon}
                    ${this.props.groupType === 'smart' ? style.iSmart : ''}
                `}
                />

                <div className={style.gName}>{this.props.name}</div>

                {this.state.badgeValue !== null && this.state.badgeValue > 0 &&
                    <div className={style.gBadge}>{this.state.badgeValue}</div>
                }

                {!this.props.fixed && !this.props.adhoc && this.props.pinGroups &&
                    <div
                        className={`
                            ${style.gPin}
                            ${this.props.pinned ? 'icon-star2' : 'icon-star'}
                            ${this.props.pinned ? style.gPinned : ''}
                        `}
                        onClick={this.handlePin.bind(this)}
                    />
                }
            </div>
        );
    }
}

class Folder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened,
            editingName: !!props.editingName,
            editingNameValue: props.editingName ? this.props.name : '',
            name: this.props.name
        };

        this.initialEditing = !!props.editingName;
    }

    componentDidMount() {
        const el = $(this.component);
        const self = this;

        el.droppable({
            accept: `.${style.cGroup}`,
            tolerance: 'pointer',
            over: function() {
                self.props.dragManager.enterFolder(self.props.id);
                el.addClass(style.fDraggingOver);
            },
            out: function() {
                self.props.dragManager.leaveFolder(self.props.id);
                el.removeClass(style.fDraggingOver);
            },
            drop: function() {
                el.removeClass(style.fDraggingOver);
                self.props.onDragEnded();
            }
        });

        if (this.initialEditing) {
            this.inputComponent.focus();
            this.inputComponent.select();

            this.inputComponent.scrollIntoView({
                block: 'nearest'
            });
        }
    }

    toggleVisualization() {
        const opened = !this.state.opened;

        this.setState({
            opened: opened
        });

        this.props.onFolderOpened(this.props.id, opened);
    }

    handlePin(ev) {
        ev.stopPropagation();

        this.props.onElementPinChanged(this.props.id, !this.props.pinned);
    }

    handleEdit(ev) {
        ev.stopPropagation();

        this.setState({
            editingName: true,
            editingNameValue: this.state.name
        });

        const self = this;

        _.defer(function() {
            self.inputComponent.focus();
        });
    }

    handleDelete(ev) {
        ev.stopPropagation();

        this.props.onFolderDeleted(this.props.id);
    }

    handleNameChange(ev) {
        this.setState({
            editingNameValue: ev.target.value
        });
    }

    handleNameBlur() {
        this.finishNameEdition(this.initialEditing ? this.state.name : null);
    }

    handleNameKeyDown(ev) {
        if (ev.key === 'Enter') {
            this.finishNameEdition(this.state.editingNameValue);
        } else if (ev.key === 'Escape') {
            this.finishNameEdition(this.initialEditing ? this.state.name : null);
        }
    }

    finishNameEdition(newName) {
        let newState = {
            editingName: false,
            editingNameValue: ''
        };

        if (newName) {
            this.props.onFolderRenamed(this.props.id, newName);
            newState.name = newName;
        }

        this.setState(newState);
    }

    render() {
        const content = this.state.opened ? this.props.content : [];

        return (
            <div
                ref={(el) => this.component = el}
                className={style.cFolder}
            >
                <div
                    className={style.folderHeader}
                    onClick={this.toggleVisualization.bind(this)}
                >
                    <div
                        className={`
                            ${style.fToggler}
                            ${this.state.opened ? style.tOpened : ''}
                            ${this.state.opened ? 'icon-caret-down' : 'icon-caret-right'}
                        `}
                    />

                    <div className={`${style.fFolderIcon} icon-folder`}/>

                    {this.state.editingName ? (
                        <input
                            ref={(el) => this.inputComponent = el}
                            className={style.fEditingName}
                            value={this.state.editingNameValue}
                            placeholder='New Folder'
                            onClick={(ev) => ev.stopPropagation()}
                            onChange={this.handleNameChange.bind(this)}
                            onBlur={this.handleNameBlur.bind(this)}
                            onKeyDown={this.handleNameKeyDown.bind(this)}
                        />
                    ) : (
                        <div className={style.fName}>{this.state.name}</div>
                    )}

                    {!this.props.fixed && !this.props.adhoc && !this.state.editingName &&
                        <div className={style.fControls}>
                            <div
                                className={`
                                    ${style.cEdit}
                                    icon-pencil
                                `}
                                onClick={this.handleEdit.bind(this)}
                            />

                            {this.props.content.length === 0 &&
                                <div
                                    className={`
                                        ${style.cDelete}
                                        icon-minus-circle
                                    `}
                                    onClick={this.handleDelete.bind(this)}
                                />
                            }

                            {this.props.pinFolders &&
                                <div
                                    className={`
                                        ${style.cPin}
                                        ${this.props.pinned ? 'icon-star2' : 'icon-star'}
                                        ${this.props.pinned ? style.cPinned : ''}
                                    `}
                                    onClick={this.handlePin.bind(this)}
                                />
                            }
                        </div>
                    }
                </div>

                <div className={style.folderContent}>
                    {content.map(e => {
                        const Component = e.type === 'group' ? Group : Folder;

                        return (
                            <Component
                                key={`c_${e.id}`}
                                {...e}
                                activeGroupId={this.props.activeGroupId}
                                pinGroups={this.props.pinGroups}
                                pinFolders={this.props.pinFolders}
                                dragManager={this.props.dragManager}
                                onGroupSelected={this.props.onGroupSelected}
                                onElementPinChanged={this.props.onElementPinChanged}
                                onFolderRenamed={this.props.onFolderRenamed}
                                onFolderDeleted={this.props.onFolderDeleted}
                                onFolderOpened={this.props.onFolderOpened}
                                onDragStarted={this.props.onDragStarted}
                                onDragEnded={this.props.onDragEnded}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

class Content extends React.Component {
    componentDidMount() {
        if (this.props.pinned) {
            return;
        }

        this.props.dragManager.setContentComponent(this);

        const el = $(this.component);
        const self = this;

        this.dragOver = false;

        el.droppable({
            accept: `.${style.cGroup}`,
            tolerance: 'pointer',
            over: function() {
                self.dragOver = true;
                self.manageDropOutline();
            },
            out: function() {
                self.dragOver = false;
                self.manageDropOutline();
            },
            drop: function() {
                self.dragOver = false;
                self.manageDropOutline();

                if (!self.props.dragManager.isOverAFolder()) {
                    self.props.onDragEnded();
                }
            }
        });
    }

    updateActiveGroupBadge() {
        const group = this.components[this.props.activeGroupId];

        if (group && group.updateBadge) {
            group.updateBadge();
        }
    }

    manageDropOutline() {
        if (this.dragOver) {
            if (!this.props.dragManager.isDraggingGroupInsideFolder() || this.props.dragManager.isOverAFolder()) {
                $(this.component).removeClass(style.cDraggingOver);
            } else {
                $(this.component).addClass(style.cDraggingOver);
            }
        } else {
            $(this.component).removeClass(style.cDraggingOver);
        }
    }

    getPinnedChildren(content) {
        let pinnedChildren = [];

        for (const e of content) {
            if (e.pinned) {
                pinnedChildren.push(e);
            } else if (e.type === 'folder') {
                pinnedChildren = [...pinnedChildren, ...this.getPinnedChildren(e.content)];
            }
        }

        return pinnedChildren;
    }

    scrollToGroup(groupId) {
        if (groupId in this.components) {
            this.components[groupId].component.scrollIntoView({
                block: 'nearest'
            });
        }
    }

    render() {
        this.components = {};

        let content = this.props.content;

        if (this.props.pinned) {
            let newContent = [];

            for (const e of content) {
                if (!e.pinned) {
                    if (e.type === 'folder') { // the parent is not pinned but maybe some of the children are
                        newContent = [...newContent, ...this.getPinnedChildren(e.content)];
                    }

                    continue;
                }

                newContent.push(e);
            }

            content = newContent;
        }

        if (content.length === 0) {
            return null;
        }

        return (
            <div
                ref={(el) => this.component = el}
                className={`
                    ${style.fContent}
                    ${this.props.pinned ? style.cPinned : ''}
                `}
            >
                {content.map(e => {
                    if ((this.props.pinned && !e.pinned) || (!this.props.pinned && e.pinned)) {
                        return null;
                    }

                    const Component = e.type === 'group' ? Group : Folder;

                    return (
                        <Component
                            ref={(el) => { if (el) { this.components[e.id] = el; } }}
                            key={`c_${e.id}`}
                            {...e}
                            draggable={!this.props.pinned}
                            activeGroupId={this.props.activeGroupId}
                            pinGroups={this.props.pinGroups}
                            pinFolders={this.props.pinFolders}
                            editingName={e.id === this.props.editingFolderId}
                            dragManager={this.props.dragManager}
                            onGroupSelected={this.props.onGroupSelected}
                            onElementPinChanged={this.props.onElementPinChanged}
                            onFolderRenamed={this.props.onFolderRenamed}
                            onFolderDeleted={this.props.onFolderDeleted}
                            onFolderOpened={this.props.onFolderOpened}
                            onDragStarted={this.props.onDragStarted}
                            onDragEnded={this.props.onDragEnded}
                        />
                    );
                })}
            </div>
        );
    }
}

export default class Finder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: !!props.collapsed,
            counter: 0,
            filterBy: '',
            activeSection: null,
            activeGroupId: null,
            editingFolderId: null
        };

        this.dragManager = new DragManager(props.contentManager);
    }

    setCollapsed(collapsed) {
        this.setState({
            collapsed: collapsed
        });
    }

    updateActiveGroupBadge() {
        this.content?.updateActiveGroupBadge();
        this.pinnedContent?.updateActiveGroupBadge();
    }

    isCollapsed() {
        return this.state.collapsed;
    }

    handleOverlayClick(ev) {
        ev.stopPropagation();
        this.props.onToggle();
    }

    handleFinderToggle() {
        this.props.onToggle();
    }

    onContentManagerUpdated(expandFinder, silent) {
        this.setState({
            counter: this.state.counter + 1,
            activeSection: this.props.contentManager.getActiveSection(),
            activeGroupId: this.props.contentManager.getActiveGroupId()
        });

        if (!silent) {
            this.props.onGroupSelected(this.props.contentManager.getActiveGroup(), expandFinder);
        }

        // scroll into the selected group
        const activeGroup = this.props.contentManager.getActiveGroup();

        if (activeGroup) {
            this.pinnedContent?.scrollToGroup(activeGroup.id);
            this.content?.scrollToGroup(activeGroup.id);
        }
    }

    onGroupSelected() {
        const activeGroupId = this.props.contentManager.getActiveGroupId();

        this.setState({
            activeSection: this.props.contentManager.getActiveSection(),
            activeGroupId: activeGroupId
        });

        this.pinnedContent?.scrollToGroup(activeGroupId);
        this.content?.scrollToGroup(activeGroupId);
    }

    handleSearchTermChange(term) {
        this.setState({
            filterBy: term,
            counter: this.state.counter + 1
        });
    }

    handleGroupSelect(groupId) {
        this.setState({
            activeGroupId: groupId,
        });

        this.props.contentManager.setActiveGroupId(groupId);

        const activeGroup = this.props.contentManager.getActiveGroup();

        this.props.onGroupSelected(activeGroup);
    }

    handleDragStart(groupId) {
        this.dragManager.dragStarted(groupId);
    }

    handleDragEnd() {
        this.dragManager.dragEnded();

        this.setState({
            activeSection: this.props.contentManager.getActiveSection() // to redraw the content
        });
    }

    handleElementPinChange(elementId, pinned) {
        this.props.contentManager.setElementPinState(elementId, pinned);

        this.setState({
            activeSection: this.props.contentManager.getActiveSection() // to redraw the content
        });
    }

    handleFolderRename(folderId, newName) {
        this.props.contentManager.setElementName(folderId, newName);

        // todo: the pinned folders should be a shadow of the original one? I mean, if I rename the folder or if I add new groups to the folder, the pinned copy
        // should reflect the same changes?

        this.setState({
            activeSection: this.props.contentManager.getActiveSection(), // to redraw the content
            editingFolderId: null
        });

        this.props.contentManager.saveUserStructure();
    }

    handleFolderDelete(folderId) {
        this.props.contentManager.deleteElement(folderId);

        this.setState({
            activeSection: this.props.contentManager.getActiveSection() // to redraw the content
        });

        this.props.contentManager.saveUserStructure();
    }

    handleFolderOpen(folderId, opened) {
        let folder = this.props.contentManager.getElement(folderId);

        folder.opened = opened;

        this.setState({
            activeSection: this.props.contentManager.getActiveSection() // to redraw the content
        });

        this.props.contentManager.saveUserStructure();
    }

    handleNewFolder() {
        const folderId = this.props.contentManager.addFolder(guid(), 'New Folder');

        this.setState({
            editingFolderId: folderId
        });
    }

    getContentElementsFiltered(content, term) {
        let elements = [];

        for (const e of content) {
            if (e.name.toLowerCase().includes(term)) {
                let item = _.clone(e);

                if (item.type === 'folder') {
                    item.opened = false;
                }

                elements.push(item);
            } else if (e.type === 'folder') {
                elements = [...elements, ...this.getContentElementsFiltered(e.content, term)];
            }
        }

        return elements;
    }

    isAnyElementPinned(content) {
        for (const e of content) {
            if (e.pinned) {
                return true;
            }

            if (e.type === 'folder') {
                const pinned = this.isAnyElementPinned(e.content);

                if (pinned) {
                    return true;
                }
            }
        }

        return false;
    }

    searchGainFocus() {
        this.toolbar.searchGainFocus();
    }

    searchLostFocus() {
        this.toolbar.searchLostFocus();
    }

    setCreateOptions(options) {
        this.toolbar.setCreateOptions(options);
    }

    renderContent(content, pinned) {
        return (
            <Content
                ref={(el) => { if (pinned) { this.pinnedContent = el; } else { this.content = el; } }}
                pinned={pinned}
                content={content}
                pinGroups={this.props.pinGroups}
                pinFolders={this.props.pinFolders}
                editingFolderId={this.state.editingFolderId}
                activeGroupId={this.state.activeGroupId}
                dragManager={this.dragManager}
                onGroupSelected={this.handleGroupSelect.bind(this)}
                onElementPinChanged={this.handleElementPinChange.bind(this)}
                onFolderRenamed={this.handleFolderRename.bind(this)}
                onFolderDeleted={this.handleFolderDelete.bind(this)}
                onFolderOpened={this.handleFolderOpen.bind(this)}
                onDragStarted={this.handleDragStart.bind(this)}
                onDragEnded={this.handleDragEnd.bind(this)}
            />
        );
    }

    render() {
        const activeSection = this.state.activeSection;

        let content = [];
        let renderPinnedContent = true;

        if (activeSection) {
            if (this.state.filterBy) {
                content = this.getContentElementsFiltered(activeSection.content, this.state.filterBy.toLowerCase());
                renderPinnedContent = this.isAnyElementPinned(content);
            } else {
                content = activeSection.content;
            }
        }

        return (
            <div
                className={`
                    ${style.finder}
                    ${this.state.collapsed ? style.collapsed : ''}
                `}
            >
                <Header
                    sections={this.props.contentManager.getSections()}
                    activeSection={activeSection}
                    collapsed={this.state.collapsed}
                    onFinderToggle={this.handleFinderToggle.bind(this)}
                />

                {activeSection &&
                    <Toolbar
                        ref={(el) => this.toolbar = el}
                        createGroups={this.props.createGroups}
                        createFolders={this.props.createFolders}
                        onSearchTermChange={this.handleSearchTermChange.bind(this)}
                        onNewFolder={this.handleNewFolder.bind(this)}
                        onNewGroup={this.props.onNewGroup}
                    />
                }

                {activeSection &&
                    <div
                        key={`finder_content_${this.state.counter}`}
                        className={style.fContentContainer}
                    >
                        {renderPinnedContent &&
                            this.renderContent(content, true)
                        }

                        {this.renderContent(content)}
                    </div>
                }

                {this.state.collapsed &&
                    <div
                        className={style.fOverlay}
                        onClick={this.handleOverlayClick.bind(this)}
                    />
                }
            </div>
        );
    }
}

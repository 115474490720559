import $ from 'jquery'
import _ from 'underscore'
import React from 'react'
import html2pdf from 'html2pdf.js'

import app from 'js/app'
import Currency from 'js/utils/currency'
import ContentFileModel from 'js/models/content_file'
import AppConfig from 'app/app-config'
import {NewSelect} from 'js/react_views/widgets/select'
import Utilities from 'js/utils/utilities'

import style from './funnel-map.css'

let PopupCache = {
};


class PointPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: this.getName(),
            style: {
                top: 0,
                left: 0,
                opacity: 0
            }
        }

        this.mounted = false;
    }

    componentDidMount() {
        const container = $(this.props.container)[0];
        const component = $(this.component)[0];

        let top = this.props.hoverPoint.y - component.clientHeight - 20;
        let left = this.props.hoverPoint.x - (component.clientWidth / 2);

        if (top < container.scrollTop) {
            top = this.props.hoverPoint.y + 20;
        }

        if (left < container.scrollLeft) {
            left = container.scrollLeft + 10;
        } else if ((left + component.clientWidth) > (container.scrollLeft + container.clientWidth)) {
            left = (container.scrollLeft + container.clientWidth) - component.clientWidth - 10;
        }

        this.setState({
            style: {
                top: top,
                left: left,
                opacity: 1
            }
        });

        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getName() {
        const key = `name_${this.props.hoverPoint.deal.get('id')}`;

        if (key in PopupCache) {
            return PopupCache[key];
        }

        if (AppConfig.hasValue('funnel_map.name_formatter')) {
            const self = this;

            AppConfig.getValue('funnel_map.name_formatter', '', {
                deal: this.props.hoverPoint.deal,
                callback: function(value) {
                    PopupCache[key] = value;

                    if (self.mounted) {
                        self.setState({name: value});
                    }
                }
            });

            return 'loading...';
        }

        return '';
    }

    render() {
        const deal = this.props.hoverPoint.deal;

        return (
            <div
                ref={(el) => this.component = el}
                className={style.fmPointPopup}
                style={this.state.style}
            >
                <div className={style.fmhpName}>{this.state.name}</div>
                <div className={style.fmhMoreInfo}>
                    <div className={style.fmhPointId}>{AppConfig.getValue('funnel_map.point_id_formatter', deal.get('name'), deal)}</div>
                    <div>|</div>
                    <div className={style.fhmPhase}>{deal.get('phase').name}</div>
                </div>
            </div>
        );
    }
}

class FloorSelector extends React.Component {
    constructor(props) {
        super(props);

        this.floors = [];

        if (props.mapConfiguration.floors) {
            for (const f of props.mapConfiguration.floors) {
                let floor = _.clone(f);
                floor.id = `f_${f.name}`;
                this.floors.push(floor);
            }
        }
    }

    render() {
        if (this.floors.length < 2) {
            return null;
        }

        return (
            <div
                className={`${style.fmFloorSelector} ${this.props.informationTableVisible ? style.fmInformationTableVisible : ''}`}
            >
                <NewSelect
                    width='180'
                    data={this.floors}
                    value={this.floors.find(f=> f.name === this.props.activeFloorName)}
                    text='name'
                    onSelect={(items) => this.props.onFloorChange(items[0])}
                />
            </div>
        );
    }
}

class InformationTableBase extends React.Component {
    getBucketValue(deal, bucketName) {
        const bucket = deal.get('buckets').find(b => b.name.toLowerCase() === bucketName.toLowerCase());

        if (bucket && bucket.value) {
            return Currency.format(null, bucket.value);
        }

        return 0;
    }

    getDeals() {
        let areaCfIds = {};

        for (const cfId in app.globalData.customFieldsInfo.deals) {
            const cfname = app.globalData.customFieldsInfo.deals[cfId].name.toLowerCase();

            if (cfname === 'area (ft2)') {
                areaCfIds.ft2 = cfId;
            } else if (cfname === 'area (m2)') {
                areaCfIds.m2 = cfId;
            }

            if (_.size(areaCfIds) === 2) {
                break;
            }
        }

        let deals = [];

        for (const point of this.props.points) {
            const deal = point.deal;
            const areaFt2 = deal.get(`custom_field.${areaCfIds.ft2}`) || 0;
            const areaM2 = deal.get(`custom_field.${areaCfIds.m2}`) || 0;

            deals.push({
                id: deal.get('id'),
                plotNumber: point.id,
                areaFt: areaFt2 || +(areaM2 * 10.764).toFixed(2), // m2 to ft2
                netPrice: this.getBucketValue(deal, 'Housebuilder Net Price'),
                grossPrice: this.getBucketValue(deal, 'Gross Release Price'),
                deal: deal
            });
        }

        const collator = new Intl.Collator('en', {numeric: true, sensitivity: 'base'});

        return deals.sort((a, b) => collator.compare(a.plotNumber, b.plotNumber))
    }
}

class InformationTable extends InformationTableBase {
    constructor(props) {
        super(props);

        this.state = {
            highlightedId: null
        };

        this.rowsComponents = {};
    }

    highlight(dealId) {
        this.setState({
            highlightedId: dealId
        });

        if (dealId && this.rowsComponents[dealId]) {
            this.rowsComponents[dealId].scrollIntoView({
                block: 'nearest'
            });
        }
    }

    render() {
        const deals = this.getDeals();
        this.rowsComponents = {};

        return (
            <div className={style.fmInformationTable}>
                <div className={style.itHeader}>
                    <div className={style.hCol}>Plot Number</div>
                    <div className={style.hCol}>Area (ft2)</div>
                    <div className={style.hCol}>Net Achieved Price</div>
                    <div className={style.hCol}>Gross Release Price</div>
                </div>
                <div className={style.itContent}>
                    {deals.map(deal => {
                        const isHighglighted = AppConfig.getValue('funnel_map.is_value_highlighted', false, deal.deal);

                        return (
                            <div
                                key={deal.id}
                                ref={(el) => this.rowsComponents[deal.id] = el}
                                className={`${style.itRow} ${deal.id === this.state.highlightedId ? style.rHighlighted : ''}`}
                                onClick={() => this.props.onClickDeal(deal.id)}
                            >
                                <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.plotNumber}</div>
                                <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.areaFt}</div>
                                <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.netPrice}</div>
                                <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.grossPrice}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class PrintableInformationTable extends InformationTableBase {
    render() {
        const deals = this.getDeals();

        return (
            <div className={style.fmPrintableInformationTable}>
                <div className={style.itHeader}>
                    <div className={style.hCol}>Plot Number</div>
                    <div className={style.hCol}>Area (ft2)</div>
                    <div className={style.hCol}>Net Achieved Price</div>
                    <div className={style.hCol}>Gross Release Price</div>
                </div>
                {deals.map(deal => {
                    const isHighglighted = AppConfig.getValue('funnel_map.is_value_highlighted', false, deal.deal);

                    return (
                        <div
                            key={deal.id}
                            className={style.itRow}
                        >
                            <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.plotNumber}</div>
                            <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.areaFt}</div>
                            <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.netPrice}</div>
                            <div className={`${style.rCell} ${isHighglighted ? style.cHighlighted : ''}`}>{deal.grossPrice}</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class FunnelMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundUrl: null,
            backgroundWidth: 0,
            loading: false,
            showPoints: false,
            pointsRatio: 0,
            points: [],
            shortcuts: [],
            pointPopup: null,
            showInformationTable: false,
            isFullscreen: false
        };

        this.activeFloor = null;

        if (props.funnelId) {
            const self = this;

            _.defer(function() {
                self.setFunnel(app.globalData.funnelsInfo.funnels.find(f => f.id === props.funnelId));
            });
        };

        if (props.deals) {
            const self = this;

            _.defer(function() {
                self.setDeals(props.deals);
            });
        };

        if (this.props.actionsView) {
            this.props.actionsView.clickCallback = this.onActionClick.bind(this);
        }
    }

    setFunnel(funnel) {
        this.setState({
            showPoints: false,
            backgroundWidth: 0,
            backgroundUrl: null
        });

        this.funnelName = funnel.name;
        this.mapConfiguration = null;
        this.activeFloor = null;

        if (funnel && funnel.map_configuration) {
            this.mapConfiguration = funnel.map_configuration;

            if (this.mapConfiguration.floors) {
                this.loadFloor(this.mapConfiguration.floors.find(f => f.is_default));
            } else {
                this.loadFloor(this.mapConfiguration);
            }
        }
    }

    loadFloor(floor) {
        this.setState({loading: true});

        const contentFile = new ContentFileModel({id: floor.background_id});
        const self = this;

        this.activeFloor = null;

        contentFile.fetch({
            success: function(file) {
                self.activeFloor = _.clone(floor);
                self.setDeals(self.deals);

                self.setState({
                    loading: false,
                    backgroundUrl: file.get('url'),
                    shortcuts: self.activeFloor.shortcuts || []
                });
            }
        });
    }

    setDeals(deals) {
        this.deals = deals;

        if (!this.activeFloor) {
            return;
        }

        let points = [];
        let extraFieldId = null;

        if (this.activeFloor.field_id.indexOf('custom_field.') === 0) {
            extraFieldId = this.activeFloor.field_id.replace('custom_field.', 'custom_fields.');
        }

        for (const deal of deals) {
            // when the data is returned by the group_pages endpoint, the name of the field is 'custom_fields.<id>', when the data is returned
            // by the opportunities endpoint, the name of the field is 'custom_field.<id>'
            const value = deal.get(this.activeFloor.field_id) || deal.get(extraFieldId);

            if (value) {
                const point = this.activeFloor.points.find(p => p.id === value);

                if (point) {
                    points.push({
                        id: point.id,
                        x: point.x,
                        y: point.y,
                        boxPos: point.box_pos ? _.clone(point.box_pos) : null,
                        deal: deal
                    });
                }
            }
        }

        const self = this;

        _.defer(function() {
            self.setState({
                points: points
            });
        });
    }

    onActionClick(actionId) {
        if (actionId === 'download-pdf') {
            this.downloadAsPdf();
        } else if (actionId === 'table-view') {
            this.props.actionsView.$el.find('#table-view').toggleClass('active', !this.state.showInformationTable);
            this.toggleInformationTable();
        }
    }

    onFunnelTransitionBegin(isFullscreen) {
        if (this.props.actionsView) {
            this.props.actionsView.$el.find('#table-view').css('display', isFullscreen ? 'block' : 'none');
        }

        this.setState({
            showPoints: !isFullscreen,
            isFullscreen: isFullscreen
        });
    }

    onFunnelTransitionEnd(isFullscreen) {
        if (isFullscreen) {
            const self = this;

            _.defer(function() {
                self.handleBackgroundWidth(self.backgroundWidth);
            });
        }
    }

    updateDealsColors() {
        this.setState({points: this.state.points.map(point => point)}); // setting the same value we force react to redraw them
    }

    handleZoom(zoomInc) {
        const newWidth = this.state.backgroundWidth + zoomInc;
        const ratio = newWidth / this.backgroundWidth;

        this.setState({
            backgroundWidth: newWidth,
            showPoints: false
        });

        const self = this;

        _.defer(function() {
            self.setState({
                pointsRatio: ratio,
                showPoints: true
            });
        });
    }

    handleZoomIn() {
        this.handleZoom(this.zoomStep);
    }

    handleZoomOut() {
        this.handleZoom(-this.zoomStep);
    }

    handleBackgroundWidth(backgroundWidth) {
        if (!this.backgroundContainer) {
            return;
        }

        const zoomStepPC = 0.1;

        this.zoomStep = Math.floor(backgroundWidth * zoomStepPC);
        this.backgroundWidth = backgroundWidth;

        // adjust initial width to fit in the view
        const containerWidth = $(this.backgroundContainer)[0].clientWidth;

        this.setState({
            backgroundWidth: containerWidth
        });

        const self = this;

        _.defer(function() {
            self.setState({
                pointsRatio: containerWidth / self.backgroundWidth,
                showPoints: true
            });
        });
    }

    handleImageLoad({target:img}) {
        this.originalImageSize = [img.naturalWidth, img.naturalHeight];
        this.handleBackgroundWidth(img.naturalWidth);
    }

    handleFloorChange(floor) {
        this.loadFloor(floor);
    }

    onMapMouseDown(ev) {
        ev.preventDefault();

        this.draggingPos = [ev.screenX, ev.screenY];
        this.dragging = true;

        const bkg = $(this.backgroundContainer);
        this.bkgScrollPos = [bkg.scrollLeft(), bkg.scrollTop()];
    }

    onMapMouseUp(ev) {
        this.dragging = false;
    }

    onMapMouseMove(ev) {
        if (!this.dragging) {
            return;
        }

        const delta = [ev.screenX - this.draggingPos[0], ev.screenY - this.draggingPos[1]];
        const background =  $(this.backgroundContainer);

        background.scrollLeft(this.bkgScrollPos[0] - delta[0]);
        background.scrollTop(this.bkgScrollPos[1] - delta[1]);
    }

    onMouseEnter(x, y, deal) {
        if (this.informationTable) {
            this.informationTable.highlight(deal.id);
        }

        this.setState({
            hoverPoint: {
                x: x,
                y: y,
                deal: deal
            }
        });
    }

    onMouseLeave() {
        if (this.informationTable) {
            this.informationTable.highlight(null);
        }

        this.setState({hoverPoint: null});
    }

    toggleInformationTable() {
        this.setState({
            showInformationTable: !this.state.showInformationTable
        });
    }

    handleClickDeal(dealId) {
        this.props.onShowDeal(dealId);
        this.onMouseLeave();
    }

    handleClickShortcut(ev, shortcut) {
        ev.stopPropagation();

        const floor = this.mapConfiguration.floors.find(f => f.name === shortcut.floor);

        if (floor) {
            this.loadFloor(floor);
        }
    }

    downloadAsPdf() {
        const source = $(document.getElementById('funnel-map-background-container'));
        const target = $(document.createElement('div'));

        source.clone().appendTo(target);

        target.find(`.${style.fmShortcuts}`).css('display', 'none');

        const tableTarget = target.find(`.${style.fmPrintableInformationTable}`);
        const tableSource = source.find(`.${style.fmPrintableInformationTable}`);

        tableTarget.css('display', 'block');
        tableTarget.css('visibility', 'visible');

        tableSource.css('display', 'block');

        const orientation = (this.originalImageSize[0] >= this.originalImageSize[1]) ? 'landscape' : 'portrait';
        const aspectRatio = this.originalImageSize[1] / this.originalImageSize[0];
        const pageSize = orientation === 'landscape' ? [1585, 1121] : [1121, 1585];
        let mapWidth = pageSize[0] - 340; // 340 is the table width
        let mapHeight = mapWidth * aspectRatio;

        // in landscape mode the map should fit in 1 page, so if the height is bigger than 1 page, we have to scale down the map
        if ((orientation === 'landscape') && (mapHeight > pageSize[1])) {
            mapWidth = (pageSize[1] * mapWidth) / mapHeight;
            mapHeight = pageSize[1];
        }

        const image = target.find('img');
        const img2PaperRatio = mapWidth / this.originalImageSize[0];
        const imageBB = source.find('img')[0].getBoundingClientRect();
        const screenImgSize = [imageBB.width, imageBB.height];

        image.css('width', mapWidth);
        image.css('height', mapHeight);
        tableTarget.css('width', pageSize[0] - mapWidth);

        // adjust points position/size to the page coordinates
        const pointStyle = this.activeFloor.point_style;
        let pointFontSize = 14;
        let pointWidth = 10;
        let pointHeight = 10;

        if (pointStyle) {
            pointWidth = parseInt(pointStyle.width);
            pointHeight = parseInt(pointStyle.height);
            pointFontSize = parseInt(pointStyle.fontSize) || pointFontSize;
        }

        for (const point of this.state.points) {
            const sanitizedId = `#point_${this.sanitizeId(point.id)}`;
            const sourcePoint = $(source.find(sanitizedId));
            const targetPoint = $(target.find(sanitizedId));

            // normalized coordinates
            const topNorm = point.y / this.originalImageSize[1];
            const leftNorm = point.x / this.originalImageSize[0];

            targetPoint.css('top', topNorm * mapHeight);
            targetPoint.css('left', leftNorm * mapWidth);
            targetPoint.css('width', pointWidth * img2PaperRatio);
            targetPoint.css('height', pointHeight * img2PaperRatio);

            for (const attr of ['background-color', 'color']) {
                targetPoint.css(attr, sourcePoint.css(attr));
            }

            const div = targetPoint.find('div');
            div.css('font-size', pointFontSize * img2PaperRatio);
        }

        const self = this;

        _.defer(function() {
            const tableSourceBB = tableSource[0].getBoundingClientRect();
            const tableHeight = tableSourceBB.height + parseFloat(tableSource.find(`.${style.itRow}`).css('height'));
            const numberOfPages = Math.ceil(tableHeight / pageSize[1]);

            target.css('height', Math.max(numberOfPages * pageSize[1], mapHeight));
            tableSource.css('display', 'none');

            // print the map in all the remaining pages
            for (let p = 0; p < numberOfPages - 1; ++p) {
                const pageY = pageSize[1] * (p + 1);

                if (pageY < mapHeight) {
                    continue;
                }

                const points = target.find(`.${style.fmBackgroundContainer}`).clone();
                const map = points.appendTo(target);

                map.css('top', pageY);

                for (let p of points.find(`.${style.fmPoint}`)) {
                    const point = $(p);
                    point.css('top', parseFloat(point.css('top')) + pageY);
                }
            }

            html2pdf(target[0], {
                filename: self.funnelName,
                pagebreak: {
                    mode: 'avoid-all',
                    after: `.${style.fmBackgroundContainer}`
                },
                jsPDF: {
                    orientation: orientation,
                    format: 'a3'
                }
            });
        });
    }

    sanitizeId(id) {
        return Utilities.replaceAll(id, '.', '_');
    }

    render() {
        let pointStyle = this.activeFloor ? _.clone(this.activeFloor.point_style) : {};
        let backgroundContainerStyle = this.activeFloor ? {backgroundColor: this.activeFloor.background_color || 'transparent'} : {};
        let defaultFontSize = 14;
        let pointHeight = 0;

        if (pointStyle) {
            const newWidth = parseInt(pointStyle.width) * this.state.pointsRatio;
            const newHeight = parseInt(pointStyle.height) * this.state.pointsRatio;

            pointStyle.width = `${newWidth}px`;
            pointStyle.height = `${newHeight}px`;
            defaultFontSize = parseInt(pointStyle.fontSize) || defaultFontSize;
        }

        const showingTable = this.state.showInformationTable && (this.state.isFullscreen || this.props.showExtraControls);

        return (
            <div
                className={`
                    ${style.funnelMap}
                    ${this.props.style === 'solid' ? style.fmSolid : ''}
                `}
            >
                {this.state.loading &&
                    <div className={style.fmMessage}>Loading map...</div>
                }
                {!this.state.loading && this.state.backgroundUrl &&
                    <div>
                        <div
                            ref={(el) => this.backgroundContainer = el}
                            id='funnel-map-background-container'
                            style={backgroundContainerStyle}
                            className={`${style.fmBackgroundContainer} ${showingTable ? style.fmInformationTableVisible : ''}`}
                            onMouseDown={this.onMapMouseDown.bind(this)}
                            onMouseUp={this.onMapMouseUp.bind(this)}
                            onMouseMove={this.onMapMouseMove.bind(this)}
                            onMouseLeave={() => this.dragging = false}
                        >
                            <img
                                ref={(el) => this.background = el}
                                className={style.fmBackground}
                                src={this.state.backgroundUrl}
                                style={{
                                    width: this.state.backgroundWidth ? `${this.state.backgroundWidth}px` : '100%'
                                }}
                                onLoad={this.handleImageLoad.bind(this)}
                            />
                            {this.state.showPoints && !_.isNaN(this.state.pointsRatio) &&
                                <div>
                                    <div className={style.fmPoints}>
                                        {this.state.points.map(point => {
                                            const x = point.x * this.state.pointsRatio;
                                            const y = point.y * this.state.pointsRatio;
                                            let ps = _.clone(pointStyle);

                                            ps.top = `${y}px`;
                                            ps.left = `${x}px`;

                                            let priceStyle = {
                                                top: `${y + pointHeight + (2 * this.state.pointsRatio)}px`,
                                                left: `${x - (5 * this.state.pointsRatio)}px`,
                                                fontSize: `${8 * this.state.pointsRatio}px`
                                            };

                                            const priceValueAndStyle = AppConfig.getValue('funnel_map.value_formatter', [0, {}], point.deal);
                                            priceStyle = _.extend(priceStyle, priceValueAndStyle[1]);

                                            return (
                                                <div key={point.id}>
                                                    <div
                                                        id={`point_${this.sanitizeId(point.id)}`}
                                                        className={`${style.fmPoint} funnelMapPoint ${this.props.colorScheme(point.deal) || ''}`}
                                                        style={ps}
                                                        onClick={() => this.handleClickDeal.bind(this)(point.deal.get('id'))}
                                                        onMouseEnter={() => this.onMouseEnter.bind(this)(x, y, point.deal)}
                                                        onMouseLeave={this.onMouseLeave.bind(this)}
                                                    >
                                                        <div
                                                            className={style.fmPointId}
                                                            style={{fontSize: `${defaultFontSize * this.state.pointsRatio}px`}}
                                                        >
                                                            {point.id}
                                                        </div>
                                                    </div>
    {/*                                                <div
                                                        className={style.fmPointPrice}
                                                        style={priceStyle}
                                                        dangerouslySetInnerHTML={{ __html: priceValueAndStyle[0]}}
                                                    />*/}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.fmShortcuts}>
                                        {this.state.shortcuts.map((shortcut, sidx) => {
                                            const x = shortcut.x * this.state.pointsRatio;
                                            const y = shortcut.y * this.state.pointsRatio;

                                            return (
                                                <div
                                                    key={`shortcut_${sidx}`}
                                                    className={style.fmShortcut}
                                                    style={{top: `${y}px`, left: `${x}px`}}
                                                    onClick={(ev) => this.handleClickShortcut.bind(this)(ev, shortcut)}
                                                >
                                                    <div
                                                        style={{fontSize: `${defaultFontSize * this.state.pointsRatio}px`}}
                                                    >
                                                        +
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            {this.state.hoverPoint &&
                                <PointPopup
                                    hoverPoint={this.state.hoverPoint}
                                    container={this.backgroundContainer}
                                />
                            }
                            <PrintableInformationTable
                                points={this.state.points || []}
                            />
                        </div>

                        {showingTable &&
                            <InformationTable
                                ref={(el) => this.informationTable = el}
                                points={this.state.points || []}
                                onClickDeal={this.handleClickDeal.bind(this)}
                            />
                        }

                        <FloorSelector
                            mapConfiguration={this.mapConfiguration}
                            onFloorChange={this.handleFloorChange.bind(this)}
                            activeFloorName={this.activeFloor.name}
                            informationTableVisible={showingTable}
                        />

                        {this.props.showExtraControls &&
                            <div>
                                <div
                                    className={`
                                        ${style.fmDownload}
                                        ${showingTable ? style.fmInformationTableVisible : ''}
                                        icon-download
                                    `}
                                    onClick={this.downloadAsPdf.bind(this)}
                                />

                                <div
                                    className={`
                                        ${style.fmToggleTable}
                                        ${showingTable ? style.fmInformationTableVisible : ''}
                                        ${showingTable ? 'icon-arrow-right' : 'icon-arrow-left'}
                                    `}
                                    onClick={this.toggleInformationTable.bind(this)}
                                />
                            </div>
                        }

                        <div
                            className={`${style.fmZoomControls} ${showingTable ? style.fmInformationTableVisible : ''}`}
                        >
                            <div
                                className='icon-plus2'
                                onClick={this.handleZoomIn.bind(this)}
                            />
                            <div
                                style={{marginTop: '10px'}}
                                className='icon-minus'
                                onClick={this.handleZoomOut.bind(this)}
                            />
                        </div>
                    </div>
                }
                {!this.state.loading && !this.state.backgroundUrl &&
                    <div className={style.fmMessage}>This funnel does not have a map</div>
                }
            </div>
        );
    }
}

export default FunnelMap;
import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import Currency from 'js/utils/currency.js'
import ModalRegion from 'js/views/base/modal-region.js'
import MessageBox from 'js/views/message_box.js'
import LeadSourceModel from 'js/models/lead_source.js'
import LeadSourcesCollection from 'js/collections/lead_sources.js'
import UsersCollection from 'js/collections/users.js'
import vent from 'js/vent.js'
import editLeadSourcesTemplate from 'templates/settings/lead_sources.handlebars'
import leadSourcesListItemViewTemplate from 'templates/settings/lead_sources_list_item_view.handlebars'
import addNewLeadSourceTemplate from 'templates/settings/lead_sources_add_new_lead_source.handlebars'


var LeadSourceList, LeadSourceItemView, NewLeadSourceView;

LeadSourceItemView = Marionette.Layout.extend({
    tagName: 'li',
    template: Handlebars.compile(leadSourcesListItemViewTemplate),
    ui: {
        name: '.name',
        editName: '.edit-name',
        cost: '.cost',
        editCost: '.edit-cost',
        assignee: '.assignee',
        editAssignee: '.edit-assignee',
        tooltips: '[data-toggle=tooltip]'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .delete-item': function() {
            // sources with valid system_type can't be destroyed
            if (this.model.get('system_type')) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Default sources cannot be deleted',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
            else {
                var view = this,
                    mbContent = {
                        message: Handlebars.compile(
                             'The entity may be related to a functionality or business flow and deletion may interrupt the workflow(s). <strong> Note: Restoration of a deleted entity is not guaranteed and may take up to 48 hours. </strong>' +
                             `Are you sure you want to delete this source?<strong class="cta">${_.escape(this.model.get('name'))}</strong>` +
                             'Type in the source name to proceed.If unsure, please contact support.'
                        ),
                        icon: 'icon-warning',
                        accept_is_negative: true,
                        accept_button_text: 'Delete',
                        cancel_button_text: 'Cancel'
                    };

                this.ui.tooltips.tooltip('hide');

                MessageBox.showYesNo(
                    mbContent,
                    this,
                    function(name) { // yes
                        if (name === view.model.get('name')) {
                            view.$el.fadeOut(400, function() {
                                view.ui.tooltips.tooltip('destroy');
                                view.model.destroy({wait: true});
                            });
                        }
                    },
                    function() {}, // no
                    null,
                    { prompt: {
                        type: 'input',
                        conditional_accept_button_shown: view.model.get('name')
                    }}
                );
            }
        },
        'click .cost-basis': function(ev) {
            this.model.save({ cost_basis: ev.target.value }, { patch: true });
        }
    },
    templateHelpers: function() {
        return {
            assignee: this.model.get('default_owner'),
            currency: this.options.currency
        };
    },
    onRender: function() {
        // Init select2
        var assignee = this.model.get('default_owner'),
            users = this.options.users.toJSON(),
            value = assignee || null,
            // Set first admin as default assignee
            defaultAssignee = _.find(users, function(item) {
                return item.is_admin;
            });

        this.assigneeSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.editAssignee,
            text: 'name',
            value: value,
            data: users,
            getItemText: function(item) {
                // Set first admin as default assignee
                if (item.id === defaultAssignee.id) {
                    return Handlebars.compile(
                        '<span class="default-item">Default</span>' + item[this.text]
                    );
                } else {
                    return item[this.text];
                }
            },
            options: {
                placeholder: 'Select a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'assignee-select-popover popover'
            }
        });

        this.activateElement(this.ui.name, this.ui.editName, {field: 'name'});
        this.activateElement(this.ui.cost, this.ui.editCost, {field: 'cost', parse: parseFloat, currency: true});
        this.activateSelect2(this.ui.assignee, this.ui.editAssignee, {field: 'default_owner', object: true});

        this.$el.find(".cost-basis[value='" + this.model.get('cost_basis') + "']").prop("checked", true);

        this.ui.tooltips.tooltip({
            container: 'body',
            placement: 'left'
        });
    },
    activateSelect2: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.select2('open');
        });

        edit.on('change', function() {
            var val = edit.select2('val'),
                attrs = {};

            attrs[dbField.field] = val ? {id: val} : null;

            self.model.save(attrs, {
                patch: true,
                success: function() {
                    var selection = edit.select2('data');
                    text.text(selection ? selection.name : 'Unassigned');
                }
            });

        });

        edit.on('select2-blur', hideEdit);
    },

    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon'),
            isSelect = edit.is('select');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.focus();
        });

        edit.on('change', function() {
            var val = edit.val(),
                attrs = {},
                model = self.model;

            if (dbField.parse) {
                val = dbField.parse(val);
                if (_.isNaN(val)) {
                    return;
                }
                edit.val(val);
            }

            if (dbField.object) {
                attrs[dbField.field] = val ? {id: val} : null;
            }
            else {
                attrs[dbField.field] = val;
            }
            model.save(attrs, {
                patch: true,
                success: function() {
                    if (isSelect) {
                        val = edit.find("[value=" + val + "]").text();
                    }

                    if (dbField.currency) {
                        val = Currency.format(self.options.currency, val);
                    }

                    text.text(val);
                    hideEdit();
                }
            });

        });

        edit.on('blur', hideEdit);
    }
});

LeadSourceList = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'lead-source-list editable-list',
    itemView: LeadSourceItemView,
    itemViewOptions: function() {
        return this.options;
    }
});

NewLeadSourceView = Marionette.ItemView.extend({
    className: 'edit-lead-source edit-form-modal',
    template: Handlebars.compile(addNewLeadSourceTemplate),
    ui: {
        name: '#lead-source-name',
        error_messages: '.error-message'
    },
    events: {
        'keypress #lead-source-name': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();
                this.save();
            }
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onShow: function() {
        this.ui.name.focus();
    },
    save: function() {
        var attrs = {
                name: this.ui.name.val()
            },
            leadSource = new LeadSourceModel(),
            view = this;

        this.error_messages.remove.call(this);

        this.listenTo(leadSource, 'invalid', this.invalid);
        leadSource.save(attrs, {
            validate: true,
            success: function() {
                view.trigger('new:leadSource', [leadSource]);
            }
        });
    },
    invalid: function(model, errors) {
        this.error_messages.remove.call(this);
        if (errors.name_undefined || errors.name_too_short) {
            this.ui.name
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.name_undefined || errors.name_too_short)
                .addClass('invalid');
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default Marionette.Layout.extend({
    className: 'lead-sources',
    template: Handlebars.compile(editLeadSourcesTemplate),
    regions: {
        leadSourceList: '.lead-source-list-container',
        addNewLeadSource: {
            selector: '.add-new-lead-source', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    events: {
        'click .add-new-lead-source': function() {
            var newLeadSourceView = new NewLeadSourceView();
            this.addNewLeadSource.show(newLeadSourceView);
            this.listenTo(newLeadSourceView, 'new:leadSource', function(leadSource) {
                this.leadSources.add(leadSource);
                this.addNewLeadSource.reset();
            });
            this.listenTo(newLeadSourceView, 'cancel', function() {
                this.addNewLeadSource.reset();
            });
        }
    },
    initialize: function() {
        this.leadSources = new LeadSourcesCollection();
        this.users = new UsersCollection();
    },
    onRender: function () {
        var self = this;
        $.when(
            this.users.fetch(),
            this.leadSources.fetch({
                sortOn: [{
                    attribute: 'name',
                    order: 'asc'
                }]
            })
        ).always(
            function() {
                self.leadSourceList.show(new LeadSourceList({
                    collection: self.leadSources,
                    users: self.users,
                    currency: app.user.get('client').default_currency
                }));
                self.$el.find('.content-container').nanoScroller();
                self.scrollEvents();
            }
        );
    },
    scrollEvents: function() {
        var container = this.$el.find('.content-container'),
            header = this.$el.find('.detail-header');

        $(container).find('.content').scroll(function(){
            if(container.find('.lead-source-list').position().top < -1) {
                if(!header.hasClass('header-shadow')){
                    header.addClass('header-shadow');
                }
            }else{
                header.removeClass('header-shadow');
            }
        });
    }
});

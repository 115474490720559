import $ from 'jquery';
import React from 'react';
import Backbone from 'backbone'

import Table from 'app_v2/components/table/table';
import GroupPageFetcher from 'app_v2/sections/base/group_page_fetcher';

const TABLE_DEFAULT_NUM_ROWS = 50;

export default class GroupTable extends React.Component {
    constructor(props) {
        super(props);

        const numRows = props.numRows || TABLE_DEFAULT_NUM_ROWS;

        this.fetcher = new GroupPageFetcher(props.entityType, numRows, props.columns);
        this.fetcher.setSectionId(props.sectionId);
        this.fetcher.setFetchArgs(props.group.fetchArgs);

        if (props.group.orderBy) {
            this.fetcher.orderBy(props.group.orderBy.columnId, props.group.orderBy.direction);
        }

        this.lastPageFetched = null;
        this.dataDirty = true;
        this.visible = false;
        this.rowSelection = null;
        this.allRowsSelected = false;
        this.initFiltersBarOnShow = props.sharedFilterManager?.filterId;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchPage(page) {
        if (!_.isNumber(page)) {
            page = this.lastPageFetched || 0;
        }

        this.table.setLoading(true);
        this.lastPageFetched = page;
        this.dataDirty = false;

        const self = this;

        this.fetcher.fetch(page, function(result) {
            if (self.mounted) {
                self.table.setData(result.rows, page, result.start, result.total, result.numRows);
                self.table.setLoading(false);
            }
        });
    }

    handleSortChange(columnId, sortDir) {
        this.fetcher.orderBy(columnId, sortDir);
        this.fetchPage();

        this.props.updateGroup(this.props.group, {
            orderBy: this.fetcher.getOrderBy()
        });
    }

    handleFilterRuleChange(ruleFieldId, ruleCustomId, rule) {
        this.props.sharedFilterManager.setRule(ruleFieldId, ruleCustomId, rule);

        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchPage(0);

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    handleRowSelectionChange(rowSelection) {
        this.rowSelection = rowSelection;

        if (!rowSelection || !rowSelection.page) {
            this.allRowsSelected = false;
        }

        this.props.onRowSelectionChange(rowSelection, this.allRowsSelected);
    }

    deleteFilterRule(filter) {
        this.props.sharedFilterManager.setRule(filter.id, filter.custom, null);

        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchPage(0);

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    deleteAllFiltersRules() {
        this.table.setColumnsRules({});
        this.props.sharedFilterManager.setRules(null);
        this.fetcher.filterBy(null);
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        this.fetchPage(0);

        this.props.updateGroup(this.props.group, {
            filterId: null
        });
    }

    selectAll() {
        this.allRowsSelected = true;
    }

    setDataDirty() {
        this.dataDirty = true;

        if (this.visible) {
            this.fetchPage(0);
        }
    }

    searchBy(term) {
        if (term === null) { // search closed
            if (this.fetcher.getSearchTerm()) {
                this.fetcher.searchBy(null);
                this.fetchPage(0);
            }
        } else {
            this.fetcher.searchBy(term);
            this.fetchPage(0);
        }
    }

    setColumns(selectedColumns) {
        this.table.setColumns(selectedColumns);
        this.fetcher.setColumns(selectedColumns);
        this.fetchPage();

        this.props.updateGroup(this.props.group, {
            columns: selectedColumns
        });

        const self = this;

        _.defer(function() {
            self.table.setColumnsRules(self.props.sharedFilterManager.getColumnsRules(self.table.getColumns()));
        });
    }

    setFilterRules(rules) {
        this.props.sharedFilterManager.setRules(rules);
        this.table.setColumnsRules(this.props.sharedFilterManager.getColumnsRules(this.table.getColumns()));
        this.props.onFiltersChange(this.props.sharedFilterManager, this.table.getColumns(), this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.fetcher.filterBy(filterId);
            self.fetchPage(0);

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    showPanel(panelId, showArgs) {
        switch (panelId) {
            case 'entity':
                this.props.showEntityPanel(showArgs);
                break;

            case 'configureColumns':
                this.props.showConfigureColumnsPanel(this.props.allColumns, this.table.getColumns());
                break;

            case 'advancedFilter':
                this.props.showAdvancedFilterPanel(this.props.entityType, this.props.filters, this.props.sharedFilterManager.getRules());
                break;

            case 'group':
                this.props.showGroupPanel(this.props.entityType, showArgs);
                break;
        }
    }

    saveCurrentGroupAsCsv() {
        const args = this.fetcher.getGroupPageArgs(0);

        args.rows = -1;
        args.csv = true;

        this.props.saveAsCsv(this.props.group?.name || this.props.entityType, `/group_pages?${$.param(args)}`);
    }

    saveCurrentGroupAsForecast() {
        this.props.saveAsForecast(this.props.group?.name || this.props.entityType);
    }

    onStartResizing() {
        this.table.onStartResizing();
    }

    onEndResizing() {
        this.table.onEndResizing();
    }

    show() {
        this.visible = true;

        let dirty = this.dataDirty;

        if (this.fetcher.getSearchTerm() !== this.props.sharedSearchTerm) {
            this.fetcher.searchBy(this.props.sharedSearchTerm);
            dirty = true;
        }

        if (this.fetcher.filterId !== this.props.sharedFilterManager.filterId) {
            this.fetcher.filterBy(this.props.sharedFilterManager.filterId);
            dirty = true;
        }

        if (dirty) {
            this.handleRowSelectionChange(null);
            this.fetchPage(0);
        } else {
            this.handleRowSelectionChange(this.rowSelection);
        }

        this.props.setBarsWidth('100%');

        const self = this;

        _.defer(function() {
            self.table.setColumnsRules(self.props.sharedFilterManager.getColumnsRules(self.table.getColumns()));

            if (self.initFiltersBarOnShow) {
                self.initFiltersBarOnShow = false;
                self.props.onFiltersChange(self.props.sharedFilterManager, self.table.getColumns(), self.props.filters);
            };
        });
    }

    hide() {
        this.visible = false;
    }

    render() {
        let groupFilterable = true;

        if (this.props.group?.protected) {
            groupFilterable = false;
        }

        return (
            <Table
                ref={(el) => this.table = el}
                parent={this.props.parent}
                hasPaginator={true}
                {...this.props}
                filterable={groupFilterable}
                onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                onRowClick={(rowId) => this.showPanel.bind(this)('entity', rowId)}
                // onRowContext={this.handleRowContext.bind(this)}
                // onRowButtonClick={this.handleRowButtonClick.bind(this)}
                onFilterRuleChange={this.handleFilterRuleChange.bind(this)}
                onSortChange={this.handleSortChange.bind(this)}
                showAdvancedFilter={() => this.showPanel.bind(this)('advancedFilter')}
                onGotoPage={(page) => this.fetchPage.bind(this)(page)}
            />
        );
    }
}

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import vent from 'js/vent.js'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import braintreeCountryCodes from 'js/utils/braintree_country_codes.js'
import LoadingView from 'js/views/loading.js'
import billingAddressSettingsTemplate from 'templates/settings/billing_address_settings.handlebars'
import billingAddressFormTemplate from 'templates/settings/billing_address_form.handlebars'
import billingAddressDetailTemplate from 'templates/settings/billing_address_detail.handlebars'


/**
 * Helper function to get a country info record from its code
 */
var getCountryInfo = function(countryCode){
    for(var i = 0; i < braintreeCountryCodes.length; ++i) {
        var ci = braintreeCountryCodes[i];

        if (ci.id === countryCode) {
            return ci;
        }
    }

    return null;
};


/**
 * This view shows the billing address details. This is a dumb view that just renders the information received
 * on creation time.
 */
var BillingAddressDetailView = Marionette.ItemView.extend({
    template: Handlebars.compile( billingAddressDetailTemplate ),
    initialize: function() {
        var countryInfo = getCountryInfo(this.model.get('country_code'));
        if (countryInfo) {
            this.model.set('country_name', countryInfo.name);
        }
    }
});

/**
 * This view shows the billing address form. This is a dumb view that just renders the information as directed
 * by its parent.
 */
var BillingFormView = Marionette.ItemView.extend({
    template: Handlebars.compile( billingAddressFormTemplate ),
    ui: {
        firstName_group: '.form-group.cc-first-name-container',
        lastName_group: '.form-group.cc-last-name-container',
        streetAddress_group: '.form-group.cc-street-address-container',
        exdendedAddress_group: '.form-group.cc-extended-address-container',
        city_group: '.form-group.cc-locality-container',
        region_group: '.form-group.cc-region-container',
        postalCode_group: '.form-group.cc-postal-code-container',
        country_group: '.form-group.cc-country-code-numeric-container',

        vatNo_group: '.form-group.cc-vat-number',
        usedWithinEec_group: '.form-group.cc-used-within-eec',

        response: '.response-message'
    },
    events: {
        'click .save': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.onClickSave();
        }
    },
    onRender: function() {
        this.countrySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.$el.find('#field-country-code-numeric'),
            text: 'name',
            data: braintreeCountryCodes,

            options: {
                placeholder: 'Select your country',
                containerCssClass: 'select2-form-field',
                dropdownCssClass: 'individual-select-popover popover'
            }
        });

        this.countrySelect.$el.select2('val', this.model.get('country_code'));

        if (this.model.get('used_within_eec') === 'Yes') {
            this.ui.usedWithinEec_group.find('.editable-field').prop('checked', true);
        }
    },
    onClickSave: function() {
        // Do not save if local validation fails
        if (this.validate() === false) {
            return;
        }

        // Tell parent that we want to save the form
        this.trigger('save-form', this.getFormData());
    },
    validate: function() {
        var formData = this.getFormData();
        var anyError = false;

        if ( !formData.first_name || formData.first_name.length === 0 ) {
            anyError = true;
        }

        if ( !formData.last_name || formData.last_name.length === 0 ) {
            anyError = true;
        }

        if ( !formData.street_address || formData.street_address.length === 0 ) {
            anyError = true;
        }

        if ( !formData.locality || formData.locality.length === 0 ) {
            anyError = true;
        }

        if ( !formData.region || formData.region.length === 0 ) {
            anyError = true;
        }

        if ( !formData.postal_code || formData.postal_code.length === 0 ) {
            anyError = true;
        }

        if ( formData.country_name === null ) {
            anyError = true;
        }

        if ( anyError ) {
            this.ui.response.text( 'Please fill out all mandatory fields.' )
                .show();
        } else {
            this.ui.response.hide();
        }

        return !anyError;
    },
    getFormData: function() {
        var country_code = this.ui.country_group.find('#field-country-code-numeric').val();
        var country_info = getCountryInfo(country_code);

        return {
            'first_name': this.ui.firstName_group.find('.editable-field').val(),
            'last_name': this.ui.lastName_group.find('.editable-field').val(),
            'street_address': this.ui.streetAddress_group.find('.editable-field').val(),
            'extended_address': this.ui.exdendedAddress_group.find('.editable-field').val(),
            'locality': this.ui.city_group.find('.editable-field').val(),
            'region': this.ui.region_group.find('.editable-field').val(),
            'postal_code': this.ui.postalCode_group.find('.editable-field').val(),
            'country_code': country_code,
            'country_name': country_info ? country_info.name : null,
            'vat_number': this.ui.vatNo_group.find('.editable-field').val(),
            'used_within_eec': this.ui.usedWithinEec_group.find('.editable-field').prop('checked') ? 'Yes' : 'No'
        };
    }
});

/**
 * This view controls the settings panel when displaying the billing address. This view is responsible for the
 * communication with the server and rendering the BillingFormView with the data received. This view is as well
 * responsible of sending the information to the server on updating.
 */
var BillingAddressSettingsView = Marionette.Layout.extend({
    id: 'billing-address-settings',
    className: 'settings-detail at-top',
    template: Handlebars.compile( billingAddressSettingsTemplate ),
    regions: {
        loadingRegion: '.loading-container',
        billingAddressDetailRegion: '.billing-address-detail-container',
        billingAddressFormRegion: '.billing-address-form-container'
    },
    ui: {
        contentContainer: '.detail-content.content-container',
        editBilling: '.edit-billing',
        saveBilling: '.save-billing'
    },
    events: {
        'click .edit-billing': function() {
            this.startEditingForm();
        },
        'click .save-billing': function() {
            if (this.formView.validate()) {
                this.saveForm(this.formView.getFormData());
            }
        }
    },
    initialize: function() {
        this.model = new Backbone.Model({
            billing_address: null,
            editing: false,
            loading: false
        });
    },
    onRender: function() {
        var view = this;

        $.ajax({
            method: 'GET',
            url: '/billing_details',
            dataType: 'json',
            success: function(data) {
                view.model.set({
                    'billing_address': data,
                    'loading': false
                });
                view.update();
            },
            error: function() {
                view.ui.contentContainer.hide();

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There was an error processing this request',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    update: function() {
        if (this.isClosed) {
            return;
        }
        // Show the loading view if we are loading
        if (this.model.get('loading') === true) {
            this.loadingRegion.show(new LoadingView());
        } else {
            this.loadingRegion.reset();
        }

        // Show the detail view when not in edit mode and not loading
        if (this.model.get('editing') === false && this.model.get('loading') === false) {
            var detailView = new BillingAddressDetailView({
                model: new Backbone.Model(this.model.get('billing_address'))
            });
            this.billingAddressDetailRegion.show(detailView);
            this.ui.editBilling.show();
        } else {
            this.billingAddressDetailRegion.reset();
            this.ui.editBilling.hide();
        }

        // Show the form view when in edit mode and not loading
        if (this.model.get('editing') === true && this.model.get('loading') === false) {
            this.formView = new BillingFormView({
                model: new Backbone.Model(this.model.get('billing_address'))
            });
            this.billingAddressFormRegion.show(this.formView);
            this.ui.saveBilling.show();
        } else {
            this.billingAddressFormRegion.reset();
            this.ui.saveBilling.hide();
        }

        // Update scrollbars
        this.scrollbar();
    },
    startEditingForm: function() {
        this.model.set('editing', true);
        this.update();
    },
    saveForm: function(data) {
        var view = this;

        $.ajax({
            method: 'PATCH',
            url: '/billing_details',
            processData: false,
            contentType: 'application/json',
            data: JSON.stringify(data),
            dataType: 'json',
            success: function(data) {
                vent.trigger('settings:show', 'editCreditCardSettings');
            }
        });

        view.model.set('loading', true);
        view.update();
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed) {
            return;
        }

        this.$('.content-container').nanoScroller();
    }, 100)
});

export default BillingAddressSettingsView;

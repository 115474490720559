import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import BaseImport from 'js/views/settings/import.js'
import MessageBox from 'js/views/message_box.js'
import TextManager from 'app/text-manager'


export default BaseImport.extend({
    className: 'settings-detail at-top has-sticky-nav import-deals',
    templateHelpers: function() {
        return {
            name: TextManager.parseText('${ID_DEAL, capitalize, plural}'),
            text1: TextManager.getText('ID_APP_SETTINGS_IMPORT_DEALS_UPDATING_POLICY'),
            text2: TextManager.parseText('${ID_DEAL, capitalize, plural}')
        };
    },
    url: '/deals_import',
    import_type: 'deals',
    csv_template: 'resources/data_import_template_-_deals.csv',
    base_fields:[
        {group: 'General', tag: 'general', children:
            [
                {text: 'Deal Id', tag: 'abbreviation'},
                {text: 'Name', tag: 'name', mandatory: true},
                {text: TextManager.parseText('${ID_ORGANIZATION, capitalize}'), tag: 'organization', mandatory: true},
                {text: 'Funnel', tag: 'funnel', mandatory: true},
                {text: TextManager.parseText('${ID_PHASE, capitalize}'), tag: 'phase', mandatory: true},
                {text: 'Owner', tag: 'owner_email_address'},
                {text: 'Created', tag: 'created_date'},
                {text: 'Modified', tag: 'modified_date'},
                {text: 'Close Date', tag: 'close_date'},
                {text: 'Currency', tag: 'currency'},
                {text: 'Weight', tag: 'weight'}
            ]
        },
        {group: 'Other', tag: 'other', children:
            [
                {text: 'Group', tag: 'group'},
                {text: TextManager.getText('ID_MORE_INFO'), tag: 'more_info'}
            ]
        },
        {group: 'Task', tag: 'task', children:
            [
                {text: 'Subject', tag: 'subject'},
                {text: 'Content', tag: 'content'},
                {text: 'Due Date', tag: 'due_date'},
                {text: 'Assignee', tag: 'assignee'},
                {text: 'Creation Date', tag: 'created'},
                {text: 'Complete Date', tag: 'complete_date'}
            ]
        },
        {group: 'Activity', tag: 'activity', children:
            [
                {text: 'Note', tag: 'note'},
                {text: 'Creation Date', tag: 'date'},
                {text: 'Author', tag: 'author'}
            ]
        }
    ],
    showMapping: function() {
        if(typeof(this.buckets) === 'undefined' || !this.buckets.fetched || typeof(this.customFields) === 'undefined' || !this.customFields.fetched) {
            return;
        }
        if (this.funnels.size() === 1) {
            this.base_fields[0].children = _.reject(this.base_fields[0].children, function(item) {
                return item.tag === 'funnel';
            });
        }
        else {
            var funnelField = _.find(this.base_fields[0].children, function(item) {
                return item.tag === 'funnel';
            });

            if (!funnelField) {
                this.base_fields[0].children.splice(2, 0, {text: 'Funnel', tag: 'funnel', mandatory: true})
            }
        }
        this.showMappingCommon();
    }
});

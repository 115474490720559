import _ from 'underscore'
import React from 'react'
import Juice from 'juice'

import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import app from 'js/app'
import guid from 'js/utils/guid'
import MessageBox from 'js/views/message_box'
import {NewSelect} from 'js/react_views/widgets/select'
import Utilities from 'js/utils/utilities'
import LibraryBrowser from 'js/react_views/library-browser/browser'
import {Fonts, FontSizes, FontWeights, BorderStyles, getBlockTypes} from './consts'
import {
  ButtonBlockSettings, ImageBlockSettings, SpacerBlockSettings, DividerBlockSettings,
  SocialsBlockSettings, ColumnBlockSettings, FooterBlockSettings, PlotOffersBlockSettings
} from './blocks_settings'
import {SavedMessage, ColorInputField, BorderSettings, ColorPicker, SimpleInput} from './components'
import Canvas from './canvas'
import htmlBlocks from './html_blocks'

import style from './see.css'


class DropdownInputField extends React.Component {
  refresh() {
    if (this.props.value) {
      this.select.setValue(this.props.value.id);
    }
  }

  render() {
    return (
      <div className={style.inputFieldContainer}>
        <div className={style.inputFieldLabel}>{this.props.label}</div>
        <div className={style.dropdownInputField}>
          <NewSelect
            ref={(el) => this.select = el}
            data={this.props.options}
            value={this.props.value}
            width={this.props.width || 200}
            text={this.props.text || 'name'}
            options={{minimumInputLength: -1}}
            onSelect={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

class BackgroundImageLayer extends React.Component {
  constructor(props) {
    super(props);

    this.components = {};

    this.repeats = [{
      id: 'repeat',
      name: 'Repeat'
    }, {
      id: 'repeat-x',
      name: 'Repeat X'
    }, {
      id: 'repeat-y',
      name: 'Repeat Y'
    }, {
      id: 'no-repeat',
      name: 'No Repeat'
    }];

    this.positions = [{
      id: 'left top',
      name: 'Left Top'
    }, {
      id: 'left center',
      name: 'Left Center'
    }, {
      id: 'left bottom',
      name: 'Left Bottom'
    }, {
      id: 'right top',
      name: 'Right Top'
    }, {
      id: 'right center',
      name: 'Right Center'
    }, {
      id: 'right bottom',
      name: 'Right Bottom'
    }, {
      id: 'center top',
      name: 'Center Top'
    }, {
      id: 'center center',
      name: 'Center Center'
    }, {
      id: 'center bottom',
      name: 'Center Bottom'
    }];

    this.attachments = [{
      id: 'scroll',
      name: 'Scroll'
    }, {
      id: 'fixed',
      name: 'Fixed'
    }, {
      id: 'local',
      name: 'Local'
    }];

    this.sizes = [{
      id: 'auto',
      name: 'Auto'
    }, {
      id: 'cover',
      name: 'Cover'
    }, {
      id: 'contain',
      name: 'Contain'
    }];

    this.state = {
      repeat: this.repeats.find(e => e.id === this.props.value.repeat) || this.repeats[0],
      position: this.positions.find(e => e.id === this.props.value.position) || this.positions[0],
      attachment: this.attachments.find(e => e.id === this.props.value.attachment) || this.attachments[0],
      size: this.sizes.find(e => e.id === this.props.value.size) || this.sizes[0],
      image: this.props.value.image || 'none'
    };
  }

  onRepeatChange(value) {
    this.setState({repeat: value});
    this.onValueChange();
  }

  onPositionChange(value) {
    this.setState({position: value});
    this.onValueChange();
  }

  onAttachmentChange(value) {
    this.setState({attachment: value});
    this.onValueChange();
  }

  onSizeChange(value) {
    this.setState({size: value});
    this.onValueChange();
  }

  onImageSelected(image) {
    this.setState({image: image ? `url(${image.url})` : 'none'});
    this.onValueChange();
  }

  onValueChange() {
    const self = this;

    _.defer(function() {
      self.props.onChange({
        repeat: self.state.repeat.id,
        position: self.state.position.id,
        attachment: self.state.attachment.id,
        size: self.state.size.id,
        image: self.state.image
      });
    });
  }

  render() {
    const backgroundImage = this.state.image || 'none';

    return (
      <div className={style.backgroundImageLayer}>
        <div className={style.bilHeader}>
          <div className={style.bilhName}>Layer</div>
          <i
            className={`icon-trashcan ${style.bilhDelete}`}
            onClick={() => this.onImageSelected(null)}
          />
          <div className={style.bilhImage} style={{backgroundImage: `${backgroundImage}`}}/>
        </div>
        <div className={style.bilContent}>
          <div
            className={style.bilcSelectImage}
            onClick={() => this.props.editor.openLibraryBrowser(this.onImageSelected.bind(this))}
          >
            Select Image
          </div>
          {backgroundImage !== 'none' &&
            <div className={style.bilcImageContainer}>
              <div
                className={style.bilcImageRemove}
                onClick={() => this.onImageSelected(null)}
              >
                &#x00d7;
              </div>
              <div
                className={style.bilcImage}
                style={{backgroundImage: `${backgroundImage}`}}
              />
            </div>
          }
          <div className={style.bilcRow}>
            <DropdownInputField
              ref={(el) => this.components.repeat = el}
              label="Repeat"
              options={this.repeats}
              value={this.state.repeat}
              width={140}
              onChange={(items) => this.onRepeatChange(items[0])}
            />
            <DropdownInputField
              ref={(el) => this.components.repeat = el}
              label="Position"
              options={this.positions}
              value={this.state.position}
              width={140}
              onChange={(items) => this.onPositionChange(items[0])}
            />
          </div>
          <div className={style.bilcRow}>
            <DropdownInputField
              ref={(el) => this.components.repeat = el}
              label="Attachment"
              options={this.attachments}
              value={this.state.attachment}
              width={140}
              onChange={(items) => this.onAttachmentChange(items[0])}
            />
            <DropdownInputField
              ref={(el) => this.components.repeat = el}
              label="Size"
              options={this.sizes}
              value={this.state.size}
              width={140}
              onChange={(items) => this.onSizeChange(items[0])}
            />
          </div>
        </div>
      </div>
    );
  }
}

class TextStyleInput extends React.Component {
  constructor(props) {
    super(props);

    // if the font has fallback we should remove it
    const fontParts = this.props.value.font.split(',').map(p => p.trim());

    this.state = {
      size: FontSizes.find(f => f.title === this.props.value.size),
      font: Fonts.find(f => f.title.toLowerCase() === fontParts[0].toLowerCase()),
      weight: FontWeights.find(f => f.id === this.props.value.weight),
      color: this.props.value.color
    };
  }

  handleColorChange(color) {
    this.setState({
      color: color.hex
    });

    this.onStyleChange();
  }

  handleFontWeightChange(items) {
    this.setState({
      weight: items[0]
    });

    this.onStyleChange();
  }

  handleFontChange(items) {
    this.setState({
      font: items[0]
    });

    this.onStyleChange();
  }

  handleFontSizeChange(items) {
    this.setState({
      size: items[0]
    });

    this.onStyleChange();
  }

  onStyleChange() {
    const self = this;
    const prevFontId = this.state.font.id;

    _.defer(function() {
      let fallback = '';

      if (self.state.font.fallback) {
        fallback = `, ${self.state.font.fallback}`;
      }

      fallback += `, sans-serif;`;

      self.props.onChange({
        font: `${self.state.font.title}${fallback}`,
        fontId: self.state.font.id,
        prevFontId: prevFontId,
        size: self.state.size.title,
        weight: self.state.weight.id,
        color: self.state.color
      });
    });
  }

  render() {
    return (
      <div className={style.textStyleInput}>
        <div className={style.tsiColumn}>
          <div className={style.tsicTitle}>Size</div>
          <div style={{width: '70px', background: 'white', borderRadius: '3px'}}>
            <NewSelect
              data={FontSizes}
              value={this.state.size}
              width={70}
              options={{minimumInputLength: -1}}
              titleStyle={{marginRight: '10px'}}
              onSelect={this.handleFontSizeChange.bind(this)}
            />
          </div>
        </div>
        <div className={style.tsiColumn}>
          <div className={style.tsicTitle}>Color</div>
          <div
            ref={(el) => this.colorInput = el}
            className={style.tsicColor}
          >
            <div
              className={style.tsiccInput}
              style={{backgroundColor: this.state.color}}
              onClick={() => this.props.editor.showColorPicker(this.colorInput, this.state.color, this.handleColorChange.bind(this))}
            />
          </div>
        </div>
        <div className={style.tsiColumn}>
          <div className={style.tsicTitle}>Font</div>
          <div style={{width: '100px', background: 'white', borderRadius: '3px'}}>
            <NewSelect
              data={Fonts}
              value={this.state.font}
              width={150}
              options={{minimumInputLength: -1}}
              titleStyle={{marginRight: '20px'}}
              onSelect={this.handleFontChange.bind(this)}
            />
          </div>
        </div>
        <div className={style.tsiColumn}>
          <div className={style.tsicTitle}>Font Weight</div>
          <div style={{width: '76px', background: 'white', borderRadius: '3px'}}>
            <NewSelect
              data={FontWeights}
              value={this.state.weight}
              width={100}
              options={{minimumInputLength: -1}}
              titleStyle={{marginRight: '20px'}}
              onSelect={this.handleFontWeightChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

class SectionSettingsPopup extends React.Component {
  constructor(props) {
    super(props);

    const sectionStyle = this.props.section.style;

    this.backgroundColor = sectionStyle.backgroundColor || '#ffffff';
    this.borderStyle = BorderStyles.find(s => s.id === (sectionStyle.borderStyle || 'none')),
    this.borderColor = sectionStyle.borderColor || '#000000';
    this.borderWidth = sectionStyle.borderWidth || '0px';

    let imageLayer = null;

    if (sectionStyle.backgroundLayerImage && sectionStyle.backgroundLayerImage.image !== 'none') {
      imageLayer = sectionStyle.backgroundLayerImage;
    }

    this.state = {
      borderWidth: this.borderWidth,
      imageLayer: imageLayer,
      paddingTop: sectionStyle.paddingTop || 0,
      paddingBottom: sectionStyle.paddingBottom || 0
    };
  }

  handlePaddingTop(ev) {
    const value = ev.target.value;

    this.setState({
      paddingTop: parseInt(value)
    });

    this.updateStyle();
  }

  handlePaddingBottom(ev) {
    const value = ev.target.value;

    this.setState({
      paddingBottom: parseInt(value)
    });

    this.updateStyle();
  }

  handleBorderStyle(items) {
    this.borderStyle = items[0];
    this.updateStyle();
  }

  handleBackgroundColorChange(color) {
    this.backgroundColor = color;
    this.updateStyle();
  }

  handleBorderColorChange(color) {
    this.borderColor = color;
    this.updateStyle();
  }

  handleBorderWidthChange(ev) {
    this.borderWidth = ev.target.value;

    this.setState({
      borderWidth: this.borderWidth
    });

    this.updateStyle();
  }

  handleAddLayerClick() {
    this.setState({
      imageLayer: {
        repeat: 'repeat',
        position: 'left top',
        attachment: 'scroll',
        size: 'auto',
        image: null
      }
    });
  }

  handleBackgroundImageLayerChange(values) {
    if (values.image === 'none') {
      this.setState({imageLayer: null});
    }

    this.props.onBackgroundImageLayerUpdated(values);
  }

  updateStyle() {
    this.props.onStyleUpdated({
      backgroundColor: this.backgroundColor,
      borderStyle: this.borderStyle.id,
      borderColor: this.borderColor,
      borderWidth: this.borderWidth,
      paddingTop: this.state.paddingTop,
      paddingBottom: this.state.paddingBottom
    });
  }

  render() {
    return (
      <div className={style.settingsPopup}>
        <div className={style.spHeader}>
          <div className={style.sphTitle}>{this.props.section.name} Settings</div>
          <div
            className={`${style.sphClose} icon-cross`}
            onClick={this.props.onClose}
          />
        </div>
        <div className={style.spContent}>
          <div className={style.spDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Background Color</div>
          <ColorInputField
            editor={this.props.editor}
            value={this.backgroundColor}
            onChange={this.handleBackgroundColorChange.bind(this)}
          />
          <div className={style.simpleLabel} style={{marginTop: '10px', marginBottom: '10px'}}>Padding Top</div>
          <input
            type='range'
            min={0}
            max={100}
            value={this.state.paddingTop}
            className={style.slider}
            onChange={this.handlePaddingTop.bind(this)}
          />
          <div className={style.simpleLabel} style={{marginBottom: '10px', textAlign: 'center'}}>{`${this.state.paddingTop}px`}</div>

          <div className={style.simpleLabel} style={{marginBottom: '10px'}}>Padding Bottom</div>
          <input
            type='range'
            min={0}
            max={100}
            value={this.state.paddingBottom}
            className={style.slider}
            onChange={this.handlePaddingBottom.bind(this)}
          />
          <div className={style.simpleLabel} style={{marginBottom: '10px', textAlign: 'center'}}>{`${this.state.paddingBottom}px`}</div>
          <div className={style.spDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Border</div>
          <BorderSettings
            editor={this.props.editor}
            width={this.state.borderWidth}
            style={this.borderStyle}
            color={this.borderColor}
            onWidthChange={this.handleBorderWidthChange.bind(this)}
            onStyleChange={this.handleBorderStyle.bind(this)}
            onColorChange={this.handleBorderColorChange.bind(this)}
          />
          <div className={style.spImageLayer}>
            <div className={style.spDesc}>Background Image Layer</div>
            {this.state.imageLayer ? (
              <BackgroundImageLayer
                value={this.state.imageLayer}
                editor={this.props.editor}
                onChange={this.handleBackgroundImageLayerChange.bind(this)}
              />
            ) : (
              <div
                className={style.spilAddLayer}
                onClick={this.handleAddLayerClick.bind(this)}
              >
                <i className="icon-plus"/>
                <div>Add Layer</div>
              </div>
            )}
            <div className={style.spilWarning}>Background images are not supported in most email clients, including MS Outlook. Make sure you pick a similar fallback color.</div>
          </div>
        </div>
      </div>
    );
  }
}

class EmailSettings extends React.Component {
  constructor(props) {
    super(props);

    const clientPreferences = app.user.get('client').preferences || {};
    const globalDetails = clientPreferences.see_global_details ? JSON.parse(clientPreferences.see_global_details) : {};

    this.state = {
      addVIB: this.props.addVIBEnabled,
      preHeaderText: this.props.canvas.body.attr('seePreHeaderText') || '',
      activeSectionPopup: null,
      organizationName: globalDetails.organization_name,
      organizationWebsite: globalDetails.organization_website,
      organizationAddress: globalDetails.organization_address,
    };

    this.sectionSpacing = [
      {id: 'none',          title: 'None',         value: '0px'},
      {id: 'extraSmall',    title: 'Extra-Small',  value: '5px'},
      {id: 'small',         title: 'Small',        value: '10px'},
      {id: 'medium',        title: 'Medium',       value: '20px'},
      {id: 'large',         title: 'Large',        value: '40px'},
      {id: 'extraLarge',    title: 'Extra-Large',  value: '80px'}
    ];

    this.showPopup = this.showPopup.bind(this);
  }

  handleAddVIBClick(ev) {
    this.props.manageViewInBrowserLink(ev.target.checked);

    this.setState({
      addVIB: ev.target.checked
    });
  }

  handlePreHeaderTextChange(ev) {
    this.setState({
      preHeaderText: ev.target.value
    });

    this.props.canvas.body.attr('seePreHeaderText', ev.target.value);
    this.props.onContentChange();
    this.preHeaderTextSavedMessage.onContentChange();
  }

  showPopup(section) {
    if (section) {
      const container = this.props.canvas.sections[section].container;
      let backgroundPositionParts = container.css('background-position').split(' ');
      let backgroundPositionX = '';
      let backgroundPositionY = '';

      switch (backgroundPositionParts[0]) {
        case '0%':
          backgroundPositionX = 'left';
          break;

        case '50%':
          backgroundPositionX = 'center';
          break;

        case '100%':
          backgroundPositionX = 'right';
          break;
      }

      switch (backgroundPositionParts[1]) {
        case '0%':
          backgroundPositionY = 'top';
          break;

        case '50%':
          backgroundPositionY = 'center';
          break;

        case '100%':
          backgroundPositionY = 'bottom';
          break;
      }

      this.setState({
        activeSectionPopup: {
          name: section,
          style: {
            backgroundColor: Utilities.rgb2hex(container.css('background-color')),
            borderWidth: container.css('border-width'),
            borderStyle: container.css('border-style'),
            borderColor: Utilities.rgb2hex(container.css('border-color')),
            paddingTop: parseInt(container.css('padding-top')),
            paddingBottom: parseInt(container.css('padding-bottom')),
            backgroundLayerImage: {
              repeat: container.css('background-repeat'),
              position: `${backgroundPositionX} ${backgroundPositionY}`,
              attachment: container.css('background-attachment'),
              size: container.css('background-size'),
              image: container.css('background-image'),
            }
          }
        }
      });
    } else {
      this.setState({
        activeSectionPopup: null
      });
    }
  }

  handleSectionSpacing(items) {
    const margin = items[0].value;

    this.props.canvas.sections.header.container.parents('.seeSectionContainer').css('margin-bottom', margin);
    this.props.canvas.sections.body.container.parents('.seeSectionContainer').css('margin-bottom', margin);
    this.props.onContentChange();
  }

  handleBackgroundImageLayerChange(values) {
    const container = this.props.canvas.sections[this.state.activeSectionPopup.name].container;

    for (const k in values) {
      container.css(`background-${k}`, values[k]);
    }

    this.props.onContentChange();
  }

  handleSectionPopupStyleUpdated(style) {
    const container = this.props.canvas.sections[this.state.activeSectionPopup.name].container;

    container.css('background-color', style.backgroundColor);
    container.css('border-style', style.borderStyle);
    container.css('border-color', style.borderColor);
    container.css('border-width', style.borderWidth);
    container.css('padding-top', style.paddingTop);
    container.css('padding-bottom', style.paddingBottom);

    this.props.onContentChange();
  }

  handleBackgroundColorChange(color) {
    this.props.canvas.bodyTable.css('background-color', color);
    this.props.onContentChange();
  }

  handleLinkColorChange(color) {
    this.props.onLinkColorChange(color);
  }

  updateGlobalValues() {
    let clientPreferences = app.user.get('client').preferences || {};

    clientPreferences.see_global_details = JSON.stringify({
      organization_name: this.state.organizationName,
      organization_website: this.state.organizationWebsite,
      organization_address: this.state.organizationAddress,
    });

    const self = this;

    $.ajax({
      type: 'PATCH',
      url: '/clients/1',
      data: JSON.stringify({preferences: clientPreferences}),
      contentType: 'application/json',
      dataType: 'json',
      success: function() {
        self.updateCompanyDetailsMessage.onContentChange();
      }
    });
  }

  render() {
    let bodyBackgroundColor;
    let sectionSpacing;
    let vibBlockBackgroundColor;
    let linkColor;

    if (!this.state.activeSectionPopup) {
      const sectionMargin = this.props.canvas.sections.header.container.parents('.seeSectionContainer').css('margin-bottom');
      sectionSpacing = this.sectionSpacing.find(s => s.value === sectionMargin) || this.sectionSpacing[0];
      bodyBackgroundColor = Utilities.rgb2hex(this.props.canvas.bodyTable.css('background-color'));
      linkColor = Utilities.rgb2hex(this.props.canvas.cssRules.link.style.color);
    }

    if (this.state.addVIB) {
      const color = this.props.canvas.vibBlockContainer.css('background-color');

      if (color.indexOf('rgba') === 0) {
        vibBlockBackgroundColor = 'transparent';
      } else {
        vibBlockBackgroundColor = Utilities.rgb2hex(color);
      }
    }

    return (
      <div className={style.emailSettings}>
        {!this.state.activeSectionPopup &&
          <div>
            {this.props.isTemplate && this.props.templateCategories &&
              <div>
                <div className={style.esTitle}>Template</div>

                <div>
                  <div className={style.esDesc}>Select an email {TextManager.getText('ID_CAMPAIGN_CATEGORY')}:</div>
                  <DropdownInputField
                    options={this.props.templateCategories}
                    value={this.props.templateCategory}
                    width={140}
                    text='title'
                    onChange={(items) => this.props.onTemplateCategoryChange(items[0])}
                  />
                </div>
              </div>
            }

            <div className={style.esTitle}>Pre-Header</div>
            <div className={style.esDesc} style={{marginTop: '10px'}}>Edit optional pre-header text, a short preview sentence that some email inboxes display.</div>
            <textarea maxLength='150' className={style.esText} value={this.state.preHeaderText} onChange={this.handlePreHeaderTextChange.bind(this)}/>
            <div className={style.esRow}>
              <SavedMessage ref={(el) => this.preHeaderTextSavedMessage = el}/>
              <div className={style.esCharCounter}>{this.state.preHeaderText.length}/150 characters</div>
            </div>
            <div className={style.esRow} style={{marginTop: '15px'}}>
              <div className={style.esDesc}>Add 'View in browser' link</div>
              <label htmlFor='addVIB'>
                <div className='checkbox-button-container'>
                  <input
                    type='checkbox'
                    name='addVIB'
                    id='addVIB'
                    checked={this.state.addVIB}
                    onChange={this.handleAddVIBClick.bind(this)}
                    style={{display: 'none'}}
                  />
                  <div className='checkbox-button'>
                    <div className='checkbox-button-slider'/>
                  </div>
                </div>
              </label>
            </div>

            {this.state.addVIB &&
              <div>
                <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>'View in browser' Background Color</div>
                <ColorInputField
                  value={vibBlockBackgroundColor}
                  editor={this.props.editor}
                  onChange={(color) => {
                    this.props.canvas.vibBlockContainer.css('background-color', color);
                    this.props.onContentChange();
                  }}
                />
              </div>
            }

            <div className={style.esTitle}>Section Settings</div>
            <div className={style.esDesc} style={{marginTop: '10px'}}>Edit and update the background color, padding, border and styles of your email.</div>
            <div className={style.esSectionsContainer}>
              <div className={style.esSection}>
                <div className={style.esSectionTitle}>Header Section</div>
                <div
                  className={`${style.esSectionIcon} icon-pencil`}
                  onClick={() => this.showPopup('header')}
                />
              </div>
              <div className={style.esSection}>
                <div className={style.esSectionTitle}>Body Section</div>
                <div
                  className={`${style.esSectionIcon} icon-pencil`}
                  onClick={() => this.showPopup('body')}
                />
              </div>
              <div className={style.esSection}>
                <div className={style.esSectionTitle}>Footer Section</div>
                <div
                  className={`${style.esSectionIcon} icon-pencil`}
                  onClick={() => this.showPopup('footer')}
                />
              </div>
            </div>
            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Section Spacing</div>
            <div style={{width: '150px'}}>
              <NewSelect
                data={this.sectionSpacing}
                value={sectionSpacing}
                width={150}
                placeholder='Select spacing'
                options={{minimumInputLength: -1}}
                onSelect={this.handleSectionSpacing.bind(this)}
              />
            </div>

            <div className={style.esTitle}>Text Settings</div>
            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Background Color</div>
            <ColorInputField
              editor={this.props.editor}
              value={bodyBackgroundColor}
              onChange={this.handleBackgroundColorChange.bind(this)}
            />

            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Link Color</div>
            <ColorInputField
              editor={this.props.editor}
              value={linkColor}
              onChange={this.handleLinkColorChange.bind(this)}
            />

            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Text Body</div>
            <TextStyleInput
              editor={this.props.editor}
              value={this.props.sectionsTextDefaultSettings.paragraph}
              onChange={(value) => this.props.onSectionTextDefaultSettingsChange('paragraph', value)}
            />

            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Heading 1</div>
            <TextStyleInput
              editor={this.props.editor}
              value={this.props.sectionsTextDefaultSettings.heading1}
              onChange={(value) => this.props.onSectionTextDefaultSettingsChange('heading1', value)}
            />

            <div className={style.esDesc} style={{marginTop: '10px', marginBottom: '5px'}}>Heading 2</div>
            <TextStyleInput
              editor={this.props.editor}
              value={this.props.sectionsTextDefaultSettings.heading2}
              onChange={(value) => this.props.onSectionTextDefaultSettingsChange('heading2', value)}
            />

            <div className={style.esTitle} style={{marginBottom: '10px'}}>Global Company Details</div>
            <SimpleInput
              label='Company/Organization'
              value={this.state.organizationName}
              placeholder='Your Company'
              onChange={(ev) => this.setState({organizationName: ev.target.value})}
            />
            <div style={{marginTop: '10px'}}/>
            <SimpleInput
              label='Website'
              value={this.state.organizationWebsite}
              placeholder='https://www.yourcompany.com'
              onChange={(ev) => this.setState({organizationWebsite: ev.target.value})}
            />
            <div style={{marginTop: '10px'}}/>
            <SimpleInput
              label='Address'
              value={this.state.organizationAddress}
              placeholder='Your Company Address'
              onChange={(ev) => this.setState({organizationAddress: ev.target.value})}
            />
            <div style={{marginTop: '10px', marginBottom: '10px'}}>
              <SavedMessage ref={(el) => this.updateCompanyDetailsMessage = el}/>
            </div>
            <div
              className={style.simpleButton}
              onClick={this.updateGlobalValues.bind(this)}
            >
              Update all company details
            </div>
          </div>
        }

        {this.state.activeSectionPopup &&
          <SectionSettingsPopup
            section={this.state.activeSectionPopup}
            onClose={() => this.showPopup(null)}
            editor={this.props.editor}
            onStyleUpdated={this.handleSectionPopupStyleUpdated.bind(this)}
            onBackgroundImageLayerUpdated={this.handleBackgroundImageLayerChange.bind(this)}
          />
        }
      </div>
    );
  }
}

class BuildSection extends React.Component {
  constructor(props) {
    super(props);

    this.blockComponents = [];
  }

  componentDidMount() {
    const self = this;

    for (const b of this.blockComponents) {
      const block = $(b);

      block.draggable({
        cursor: 'move',
        helper: 'clone',
        iframeFix: true,
        // iframeScroll: true,
        start: function() {
          self.props.onStartDragging(block.attr('id'));
        },
        stop: function() {
          self.props.onStopDragging();
        },
        drag: function(ev) {
          self.props.onDrag(ev);
        }
      });
    }
  }

  componentWillUnmount() {
    for (const block of this.blockComponents) {
      $(block).draggable('destroy');
    }
  }

  render() {
    this.blockComponents = [];

    const blockTypes = getBlockTypes(this.props.userType);

    return (
      <div
        className={style.buildSection}
      >
        {blockTypes.byCategory.map((category, cidx) => {
          return (
            <div
              key={`category_${cidx}`}
              className={style.bsCategory}
            >
              <div>{category.title}</div>

              <div className={style.bsBlocks}>
                {category.blocks.map(block => {
                  return (
                    <div
                      key={block.id}
                      id={block.id}
                      ref={(el) => {if(el) { this.blockComponents.push(el) }}}
                      className={style.seeEditorBlock}
                    >
                      <div className={`${style.seeEditorBlockIcon} ${block.icon}`}/>
                      <div className={style.blockName}>{block.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
        )})}
      </div>
    );
  }
}

class SEE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actionsQueue: {
        queue: [],
        idx: 0
      },
      activeConfigurationView: {
        id: 'build'
      },
      libraryBrowser: null,
      colorPicker: null
    };

    this.cache = {};
    this.usedGoogleFonts = {};
    this.activeBlock = null;
    this.templateCategory = props.templateCategories ? (props.templateCategories.find(c => c.id === props.config.templateCategory) || props.templateCategories[0]) : null;

    this.blockSettingsComponentByBlockType = {
      imageBlock: ImageBlockSettings,
      textBlock: null,
      buttonBlock: ButtonBlockSettings,
      spacerBlock: SpacerBlockSettings,
      dividerBlock: DividerBlockSettings,
      socialsBlock: SocialsBlockSettings,
      singleColumnBlock: ColumnBlockSettings,
      multiColumnBlock: ColumnBlockSettings,
      triColumnBlock: ColumnBlockSettings,
      footerBlock: null,
      plotOffersBlock: PlotOffersBlockSettings
    };

    this.sectionsTextDefaultSettings = _.clone(this.props.config.sectionsTextDefaultSettings || {
      paragraph: {
        size: '15',
        color: '#1a1a1a',
        font: 'Arial',
        weight: 'normal'
      },
      heading1: {
        size: '40',
        color: '#1f1f1f',
        font: 'Arial',
        weight: 'bold'
      },
      heading2: {
        size: '24',
        color: '#1f1f1f',
        font: 'Arial',
        weight: 'bold'
      }
    });

    this.linkColor = this.props.config.linkColor || '#c1c1c1';

    this.mergeTags = this.props.mergeTagItems.map((tag, idx) => {
      let mt = _.clone(tag);

      mt.id = `tag_${idx}`;
      mt.isSectionTitle = mt.title;
      delete mt.title;

      return mt;
    });

    const defaultColors = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'];
    this.colorPickerMostUsedColors = AppConfig.getValue('campaigns.see.color_picker.default_colors', null, this.props.funnelId) || defaultColors;
  }

  onStartDragging(blockType) {
    this.canvas.onStartDragging(blockType);
  }

  onStopDragging() {
    this.canvas.onStopDragging();
  }

  onDrag(ev) {
    this.canvas.onDrag(ev);
  }

  executeAction(action, noRedo) {
    action.do();

    if (!noRedo) {
      let actionsQueue = this.state.actionsQueue;

      // if it's in the middle of the queue, discard from current idx until the end
      if (actionsQueue.idx < actionsQueue.queue.length) {
        actionsQueue.queue.splice(actionsQueue.idx, actionsQueue.queue.length - actionsQueue.idx);
      }

      actionsQueue.queue.push(action);
      actionsQueue.idx = actionsQueue.queue.length;

      this.setState({
        actionsQueue: actionsQueue
      });
    }
  }

  undoAction() {
    if (this.state.actionsQueue.idx > 0) {
      let actionsQueue = this.state.actionsQueue;

      --actionsQueue.idx;
      actionsQueue.queue[actionsQueue.idx].undo();

      this.setState({
        actionsQueue: actionsQueue
      });
    }
  }

  redoAction() {
    if (this.state.actionsQueue.idx < this.state.actionsQueue.queue.length) {
      let actionsQueue = this.state.actionsQueue;
      const action = actionsQueue.queue[actionsQueue.idx];

      action.redo();
      ++actionsQueue.idx;

      this.setState({
        actionsQueue: actionsQueue
      });
    }
  }

  clearCanvas() {
    const mbContent = {
      accept_is_negative: true,
      message: Handlebars.compile('Are you sure you want to clear the editor? This action cannot be undone'),
      icon: 'icon-trashcan'
    };
    const self = this;

    MessageBox.showYesNo(mbContent, this.props.parent, function() {
      self.onCanvasBlockSelected(null);
      self.canvas.clear();
      self.onSeeConfigChange();
      self.setState({
        actionsQueue: {
          queue: [],
          idx: 0
        }
      });
    });
  }

  onConfigurationIconClick() {
    this.canvas.selectBlock(null);

    const activeView = this.state.activeConfigurationView.id;

    this.setState({
      activeConfigurationView: {
        id: (activeView === 'build') ? 'emailSettings' : 'build'
      }
    });
  }

  onCanvasBlockSelected(blockId, blockParams) {
    if (!this.activeBlock && !blockId) {
      return;
    }

    if (this.activeBlock && blockId && this.activeBlock.attr('id') === blockId) {
      return;
    }

    if (this.activeBlock) {
      this.canvas.hideInlineTextEditor(blockParams?.fromAction);
      this.activeBlock.find('.seeBlockHighlighter').removeClass('active');
      this.activeBlock = null;
    }

    const block = this.canvas.getBlockById(blockId);

    this.canvas.setSelectedBlock(block);

    if (block) {
      this.activeBlock = block;
      this.activeBlock.find('.seeBlockHighlighter').addClass('active');

      const blockType = block.attr('seeBlockType');

      if (blockType === 'textBlock' || blockType === 'footerBlock') {
        this.canvas.showInlineTextEditor(block);
      }

      if (this.blockSettingsComponentByBlockType[block.attr('seeBlockType')]) {
        const contentLocked = block.attr('seecontentlocked');

        if (contentLocked !== 'true' || this.props.userType === 'advance') {
          this.setState({
            activeConfigurationView: {
              id: 'blockSettings',
              blockType: blockType,
              block: block,
              blockParams: blockParams || {}
            }
          });
        } else {
          this.setState({
            activeConfigurationView: {
              id: 'build'
            }
          });
        }
      } else {
        this.setState({
          activeConfigurationView: {
            id: 'build'
          }
        });
      }
    } else {
      this.setState({
        activeConfigurationView: {
          id: 'build'
        }
      });
    }
  }

  libraryBrowserFilter(file) {
    return Utilities.getTypeIcon(file.ext).type === 'image-file';
  }

  openLibraryBrowser(callback, folderId) {
    this.setState({
      libraryBrowser: {
        callback: callback,
        folderId: folderId || 'public'
      }
    });
  }

  onImageSelected(image) {
    this.state.libraryBrowser.callback(image);
    this.setState({libraryBrowser: null});
  }

  showColorPicker(el, color, callback) {
    this.setState({
      colorPicker: {
        el: el,
        color: color,
        callback: callback
      }
    });
  }

  onColorPickerChange(color) {
    this.colorPickerLastColorUsed = color.hex;
    this.state.colorPicker.callback(color);
  }

  onColorPickerClose() {
    if (this.colorPickerLastColorUsed && this.colorPickerMostUsedColors.indexOf(this.colorPickerLastColorUsed) === -1) {
      this.colorPickerMostUsedColors.pop();
      this.colorPickerMostUsedColors.unshift(this.colorPickerLastColorUsed);
    }

    this.colorPickerLastColorUsed = null;
    this.setState({colorPicker: null});
  }

  manageViewInBrowserLink(add) {
    if (add) {
      this.canvas.addVIBBlock();
    } else {
      this.canvas.removeVIBBlock();
    }
  }

  manageSectionTextDefaultSettingsChange(type, value) {
    this.canvas.cssRules[type].style['font-size'] = `${value.size}px`;
    this.canvas.cssRules[type].style.color = value.color;
    this.canvas.cssRules[type].style['font-family'] = value.font;
    this.canvas.cssRules[type].style['font-weight'] = value.weight;
    this.canvas.cssRules[type].style['line-height'] = `${Math.floor(value.size * 1.5)}px`;

    this.sectionsTextDefaultSettings[type] = value;
    this.onFontChange(Fonts.find(f => f.id === value.prevFontId), Fonts.find(f => f.id === value.fontId));
    this.onSeeConfigChange();
  }

  setLinkColor(color) {
    this.linkColor = color;
    this.canvas.cssRules.link.style.color = color;
    this.onSeeConfigChange();
  }

  onSeeConfigChange() {
    const ite = this.canvas.state.inlineTextEditorInfo;
    let marginTop = null;

    if (ite.visible) {
      marginTop = ite.blockContainer.css('margin-top');

      if (marginTop) {
        ite.blockContainer.css('margin-top', '');
      }
    }

    const selectedBlock = this.canvas.state.selectedBlock;
    let marginBottom = null;

    if (selectedBlock) {
      marginBottom = selectedBlock.css('margin-bottom');

      if (marginBottom) {
        selectedBlock.css('margin-bottom', '');
      }
    }

    const bodyTableWidth = this.canvas.bodyTable.attr('width');
    this.canvas.bodyTable.attr('width', '100%');

    // ...
    const content = $(this.canvas.iframe).contents().get(0);
    let html = `<!doctype html>${content.documentElement.outerHTML}`;

    // all the contenteditable attributes have to be set to true (because the lock mechanism maybe changed them to false)
    html = Utilities.replaceAll(html, `contenteditable="false"`, `contenteditable="true"`);

    this.props.onSeeConfigChange({
      sectionsTextDefaultSettings: _.clone(this.sectionsTextDefaultSettings),
      linkColor: this.linkColor,
      html: html,
      templateCategory: (this.props.isTemplate && this.templateCategory) ? this.templateCategory.id : null
    });

    this.props.onContentChange(this.getCampaignHtml(content));

    // ...
    if (marginTop) {
      ite.blockContainer.css('margin-top', marginTop);
    }

    if (marginBottom) {
      selectedBlock.css('margin-bottom', marginBottom);
    }

    this.canvas.bodyTable.attr('width', bodyTableWidth);
  }

  onFontChange(from, to) {
    // manage google fonts links
    if (from && from.link && (from.id in this.usedGoogleFonts)) {
      --this.usedGoogleFonts[from.id];

      if (this.usedGoogleFonts[from.id] === 0) {
        const component = this.canvas.head.find(`link[id=${from.id}]`);

        if (component.length > 0) {
          component[0].remove();
        }
      }
    }

    if (to.link) {
      if (!(to.id in this.usedGoogleFonts)) {
        this.usedGoogleFonts[to.id] = 0;
      }

      ++this.usedGoogleFonts[to.id];

      if (this.usedGoogleFonts[to.id] === 1) {
        if (this.canvas.head.find(`link[id=${to.id}]`).length === 0) {
          const link = `<link id="${to.id}" href="${to.link}" rel="stylesheet">`;
          this.canvas.head.append(link);
        }
      }
    }
  }

  onTemplateCategoryChange(value) {
    this.templateCategory = value;
    this.onSeeConfigChange();
  }

  getCampaignHtml(content) {
    let root = $(content);
    let html = root.contents().get(1).outerHTML;

    // save updated css rules
    let stylePos = -1;

    for (const i of ['p', 'h1', 'h2']) {
      const pos = html.indexOf(`.seeBlockContainer ${i}`);
      html = html.substring(0, pos) + html.substring(html.indexOf('}', pos) + 1);

      if (stylePos === -1) {
        stylePos = pos;
      }
    }

    let newCss = '';

    for (const i of ['link', 'paragraph', 'heading1', 'heading2']) {
      newCss += this.canvas.cssRules[i].cssText;
    }

    html = html.substring(0, stylePos) + newCss + html.substring(stylePos);

    const styleTagPos = html.indexOf('<style');

    // apply css styles to the elements
    html = Juice(html);

    // add media css
    html = html.substring(0, styleTagPos) + `<style type="text/css">${htmlBlocks.mediaCss}</style>` + html.substring(styleTagPos);

    // remove extensions scripts elements
    const scriptInitTag = '<script src="moz-extension://';
    const scriptEndTag = '</script>';
    let startPos = html.indexOf(scriptInitTag);

    while (startPos !== -1) {
      const endPos = html.indexOf(scriptEndTag, startPos + scriptInitTag.length);

      if (endPos === -1) {
        break;
      }

      html = html.substring(0, startPos) + html.substring(endPos + scriptEndTag.length);
      startPos = html.indexOf(scriptInitTag);
    }

    // remove seeEditorOnly elements
    let tag = '<div seeeditoronly="true"';
    startPos = html.indexOf(tag);

    while (startPos !== -1) {
      const endPos = html.indexOf('</div>', startPos + tag.length);

      if (endPos === -1) {
        break;
      }

      html = html.substring(0, startPos) + html.substring(endPos + 6);
      startPos = html.indexOf(tag);
    }

    // remove some attributes
    for (const attr of ['id', 'seemovementlocked', 'seecontentlocked', 'seeblocktype', 'seehasblocktools', 'seeplotofferitem', 'seeisvibblock', 'contenteditable', 'seeinitialize', 'seeorder', 'seepreheadertext', 'seeoriginalwidth', 'seeplotid', 'seeplotname', 'data-gramm']) {
      const tag = `${attr}="`;
      let startPos = html.indexOf(tag);

      while (startPos !== -1) {
        const endPos = html.indexOf('"', startPos + tag.length);

        if (endPos === -1) {
          break;
        }

        html = html.substring(0, startPos) + html.substring(endPos + 1);
        startPos = html.indexOf(tag);
      }
    }

    // manage class tag. We remove all non allowed classes
    tag = 'class="';
    startPos = html.indexOf(tag);

    const allowedClasses = ['desktopOnly', 'plotOffer'];

    while (startPos !== -1) {
      const endPos = html.indexOf('"', startPos + tag.length);

      if (endPos === -1) {
        break;
      }

      const classContent = html.substring(startPos + tag.length, endPos);
      const classList = classContent.split(' ').map(c => c.trim());
      let newClassList = [];

      for (const c of classList) {
        if (allowedClasses.indexOf(c) !== -1) {
          newClassList.push(c);
        }
      }

      if (newClassList.length === 0) { // delete class tag
        html = html.substring(0, startPos) + html.substring(endPos + 1);
        startPos = html.indexOf(tag);
      } else { // leave only the allowed classes
        html = html.substring(0, startPos) + ` class="${newClassList.join(' ')}" ` + html.substring(endPos + 1);
        startPos = html.indexOf(tag, startPos + tag.length);
      }
    }


    // remove height: 100%;
    html = Utilities.replaceAll(html, ' height: 100%;', '');

    const preHeaderText = this.canvas.body.attr('seePreHeaderText');

    if (preHeaderText) {
      // insert the preheader text as the first element in the body (and make it hidden)
      const bodyStartPos = html.indexOf('<body');
      const bodyEndPos = html.indexOf('>', bodyStartPos) + 1;

      html = html.substring(0, bodyEndPos) + `
        <div style="display: none;">${preHeaderText}</div>` + html.substring(bodyEndPos);
    }

    return `<!doctype html>${html}`;
  }

  render() {
    const BlockComponent = this.state.activeConfigurationView.id === 'blockSettings' ? this.blockSettingsComponentByBlockType[this.state.activeConfigurationView.blockType] : null;
    let settingsTitle = 'Email';
    const blockTypes = getBlockTypes();

    if (this.state.activeConfigurationView.id === 'blockSettings') {
      const blockInfo = blockTypes.plain.find(bt => bt.id === this.state.activeConfigurationView.blockType);

      if (blockInfo && blockInfo.settingsTitle) {
        settingsTitle = blockInfo.settingsTitle;
      } else {
        settingsTitle = this.state.activeConfigurationView.blockType.replace('Block', '');
      }
    }

    const actionsQueue = this.state.actionsQueue;

    return (
      <div className={style.SEE}>
        <div className={style.mainSection}>
          <div className={style.sectionHeader}>
            <div className={style.visualizationTools}>
              <div
                className={`${style.mainToolsButton} icon-desktop`}
                onClick={() => this.canvas.setVisualizationMode('desktop')}
              />
              <div
                className={`${style.mainToolsButton} icon-phone1`}
                onClick={() => this.canvas.setVisualizationMode('phone')}
              />
            </div>
            <div className={style.editorTools}>
              <div
                title='Undo'
                className={`${style.mainToolsButton} ${actionsQueue.idx === 0 ? style.buttonDisabled : ''} icon-reply`}
                onClick={this.undoAction.bind(this)}
              />
              <div
                title='Redo'
                className={`${style.mainToolsButton} ${actionsQueue.idx >= actionsQueue.queue.length ? style.buttonDisabled : ''} icon-forward`}
                onClick={this.redoAction.bind(this)}
              />

              {this.props.userType === 'advance' &&
                <div
                  title='Clear canvas'
                  className={`${style.mainToolsButton} icon-trashcan`}
                  onClick={this.clearCanvas.bind(this)}
                />
              }
            </div>
          </div>
          <div className={style.sectionContent}>
            <Canvas
              ref={(el) => this.canvas = el}
              userType={this.props.userType}
              content={this.props.config.html}
              mergeTagItems={this.mergeTags}
              parent={this.props.parent}
              editor={this}
              inlineTextEditorDefaults={this.sectionsTextDefaultSettings}
              linkColor={this.linkColor}
              executeAction={this.executeAction.bind(this)}
              onBlockSelected={this.onCanvasBlockSelected.bind(this)}
              onContentChange={this.onSeeConfigChange.bind(this)}
            />
          </div>
        </div>
        <div className={style.rightSection}>
          <div className={style.sectionHeader}>
            <div className={style.title}>{this.state.activeConfigurationView.id === 'build' ? 'Build' : `${settingsTitle} Settings`}</div>
            {this.state.activeConfigurationView.id === 'build' ? (
              <div
                className={style.emailSettingsButton}
                onClick={this.onConfigurationIconClick.bind(this)}
                style={{
                  display: this.props.userType === 'advance' ? 'flex' : 'none'
                }}
              >
                <div
                  className={`${style.icon} icon-cog2`}
                  style={{marginRight: '2px'}}
                />
                <div>Email Settings</div>
              </div>
            ) : (
              <div
                className={`${style.icon} icon-arrow-left`}
                onClick={this.onConfigurationIconClick.bind(this)}
              />
            )}
          </div>
          <div className={style.sectionContent}>
            <div className={style.sectionContentContainer}>
              {this.state.activeConfigurationView.id === 'blockSettings' &&
                <BlockComponent
                  ref={(el) => this.activeBlockComponent = el}
                  key={$(this.state.activeConfigurationView.block[0]).attr('id')}
                  block={$(this.state.activeConfigurationView.block[0])}
                  blockParams={this.state.activeConfigurationView.blockParams}
                  editor={this}
                  funnelId={this.props.funnelId}
                  cache={this.cache}
                  onContentChange={this.onSeeConfigChange.bind(this)}
                />
              }
              {this.state.activeConfigurationView.id === 'build' &&
                <BuildSection
                  userType={this.props.userType}
                  onStartDragging={this.onStartDragging.bind(this)}
                  onStopDragging={this.onStopDragging.bind(this)}
                  onDrag={this.onDrag.bind(this)}
                />
              }
              {this.state.activeConfigurationView.id === 'emailSettings' &&
                <EmailSettings
                  editor={this}
                  canvas={this.canvas}
                  addVIBEnabled={!!this.canvas.vibBlock}
                  isTemplate={this.props.isTemplate}
                  templateCategories={this.props.templateCategories}
                  templateCategory={this.templateCategory}
                  sectionsTextDefaultSettings={this.sectionsTextDefaultSettings}
                  linkColor={this.linkColor}
                  manageViewInBrowserLink={this.manageViewInBrowserLink.bind(this)}
                  onTemplateCategoryChange={this.onTemplateCategoryChange.bind(this)}
                  onLinkColorChange={this.setLinkColor.bind(this)}
                  onContentChange={this.onSeeConfigChange.bind(this)}
                  onSectionTextDefaultSettingsChange={this.manageSectionTextDefaultSettingsChange.bind(this)}
                />
              }
            </div>
          </div>
        </div>
        {this.state.libraryBrowser &&
          <LibraryBrowser
            folderId={this.state.libraryBrowser.folderId}
            filter={this.libraryBrowserFilter.bind(this)}
            parent={this.props.parent}
            ownerIsAll={true}
            imageMaxWidth={1200}
            compressImages={true}
            onCancel={() => this.setState({libraryBrowser: null})}
            onFileSelected={this.onImageSelected.bind(this)}
          />
        }
        {this.state.colorPicker &&
          <ColorPicker
            el={this.state.colorPicker.el}
            color={this.state.colorPicker.color}
            onChange={this.onColorPickerChange.bind(this)}
            onClose={this.onColorPickerClose.bind(this)}
            presetColors={this.colorPickerMostUsedColors}
          />
        }
      </div>
    );
  }
}

export default SEE;
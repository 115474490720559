import $ from 'jquery'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import InnerItemPermissionsView from 'js/views/inner_item_permissions'
import LoadingView from 'js/views/loading'
import itemPermissionsTemplate from 'templates/item_permissions.handlebars'


// ...
var ItemPermissionsView = Marionette.Layout.extend({
    className: 'item-permissions-container',
    template: Handlebars.compile(itemPermissionsTemplate),
    regions: {
        itemsContainer: '.modal-body'
    },
    events: {
        'click .close': function() {
            this.trigger( 'close' );
        }
    },

    initialize: function( options ) {
        this.options = options;
    },

    onShow: function()
    {
        this.itemsContainer.show( new LoadingView() );

        this.fetchData(); // ... first data request ...
    },

    fetchData: function() {
        this.$el.addClass( 'loading' );

        // ...
        var aclUrl = this.model.url() + '/acl';
        var view   = this;

        // TODO: error handling
        $.getJSON( aclUrl, '',
                   function( acl ) {
                        var itemsPermissionsView = new InnerItemPermissionsView( view.options );
                        itemsPermissionsView.setData( acl );

                        view.listenTo( itemsPermissionsView, 'fetchData', function() {
                            view.fetchData();
                        } );

                        view.itemsContainer.show( itemsPermissionsView );
                        view.$el.addClass('loaded').removeClass('loading');
                    } );
    }
});

export default ItemPermissionsView;

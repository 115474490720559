import $ from 'jquery'
import _ from 'underscore'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import d3 from 'd3'

import BaseModel from 'js/models/base'
import backboneSelect2 from 'js/widgets/backbone-select2'
import BucketsCollection from 'js/collections/buckets'
import dateFormat from 'js/utils/date-format'
import widgetTemplate from 'app/dashboard/widgets/revenue-planner/widget.handlebars'
import yAxisTemplate from 'app/dashboard/widgets/revenue-planner/y-axis.handlebars'
import chartItemTemplate from 'app/dashboard/widgets/revenue-planner/chart-item.handlebars'
import headcountItemTemplate from 'app/dashboard/widgets/revenue-planner/headcount-item.handlebars'
import bucketLegendItemTemplate from 'app/dashboard/widgets/revenue-planner/bucket-legend-item.handlebars'
import leadModelTemplate from 'app/dashboard/widgets/revenue-planner/lead-model.handlebars'
import salesModelTemplate from 'app/dashboard/widgets/revenue-planner/sales-model.handlebars'


var RevenuePlannerView, YAxisView, ChartView, ChartItemView, ChartBarView,
    HeadcountListView, HeadcountInputItemView, BucketLegendView,
    BucketLegendItemView, LeadModelView, SalesModelView,
    colors,
    monthsBack = 12, monthsForward = 24, monthsTotal = 12 + monthsBack + monthsForward,
    RevenuePlannerConfigModel = BaseModel.extend({
        name: 'Revenue Planner Config',
        urlRoot: '/revenue_planner_config/'
    }),
    RevenuePlannerBucketModel = BaseModel.extend({
        name: 'Revenue Planner Bucket',
        urlRoot: '/revenue_planner_bucket/'
    });

var lengths = [
    { id: 0, title: 'Perpetual' }
];
// add month 1 to 6 and 18
lengths.push({
    id: 1,
    title: '1 month'
});
_.each(_.range(2, 7).concat(18), function(i) {
    lengths.push({
        id: i,
        title: i + ' months'
    });
});
// add years 1 to 10 and years 15,20,25,30,35
lengths.push({
    id: 12,
    title: '1 year'
});
_.each(_.range(2, 11).concat(_.range(15, 36, 5)), function(i) {
    lengths.push({
        id: i * 12,
        title: i + ' years'
    });
});

colors = d3.scale.category10();

RevenuePlannerView = Marionette.Layout.extend({
    className: 'revenue-planner-widget',
    template: Handlebars.compile(widgetTemplate),
    regions: {
        yAxisRegion: '.y-axis',
        chartRegion: '.revenue-chart tbody',
        headcountRegion: '.revenue-chart tfoot',
        bucketLegendRegion: '.bucket-legend-container',
        leadModelRegion: '.lead-model-container',
        salesModelRegion: '.sales-model-container'
    },
    ui: {
        revenueSelect: '.revenue-select',
        configurationToggle: '.toggle-bucket-configuration'
    },
    events: {
        'change .revenue-select': 'selectRevenue',
        'click .toggle-bucket-configuration': 'toggleBucketConfiguration'
    },
    onRender: function() {
        this.display = 'bookings';

        // this.chartRegion.show(new ChartView(new Backbone.Model({

        // })));

        this.initSelect2();

        this.$el.addClass('bookings');
    },
    onShow: function() {
        // Fetch data
        this.fetchData();
    },
    fetchData: function() {
        var view = this,
            // set id as 1 so PATCH instead of POST would be made when saving changes later
            // doesn't work with 'new' keyword when opening second time because relational stores this model
            revenuePlannerConfigModel = new RevenuePlannerConfigModel({ id: 1 });

        revenuePlannerConfigModel.fetch({
            success: function() {
                var lastCount;

                view.buckets = new BucketsCollection(revenuePlannerConfigModel.get('bucket_configs'));

                view.leadsModel = new Backbone.Model({
                    monthly_new_leads: revenuePlannerConfigModel.get('monthly_new_leads'),
                    monthly_lead_growth: revenuePlannerConfigModel.get('monthly_lead_growth'),
                    close_ratio: revenuePlannerConfigModel.get('close_ratio'),
                    sales_cycle: revenuePlannerConfigModel.get('sales_cycle')
                });

                view.salesModel = new Backbone.Model({
                    productivity: revenuePlannerConfigModel.get('productivity'),
                    ramp_time: revenuePlannerConfigModel.get('ramp_time')
                });

                view.serverDate = revenuePlannerConfigModel.get('current_date');
                view.headCountsByDate = revenuePlannerConfigModel.get('head_counts_by_date');

                view.headcountCollection = new Backbone.Collection(
                    _.map(_.range(monthsTotal), function(d) {
                        var date,
                            count;
                        date = new Date(view.serverDate);
                        date = new Date(date.getFullYear(), date.getMonth());
                        date.setMonth(date.getMonth() + d - monthsBack);

                        if (date in view.headCountsByDate) {
                            count = view.headCountsByDate[date];
                            lastCount = count;
                        }
                        // we use last count for month which is not stored yet
                        else if (lastCount) {
                            count = lastCount;
                        }
                        // if nothing stored set count 4
                        else {
                            count = 4;
                        }

                        return new Backbone.Model({
                            date: date,
                            count: count
                        });
                    })
                );

                view.onDataReady();
            }
        });
    },
    onDataReady: function() {
        var view = this;

        if ( this.isClosed ) {
            return;
        }

        this.bucketsCollection = new Backbone.Collection(
            this.buckets.map(function(d, i) {
                return new Backbone.Model({
                    id: d.get('id'),
                    color: colors(i),
                    name: d.get('name'),
                    index: i,
                    percentage: d.get('mix'),
                    average_selling_price: d.get('average_selling_price'),
                    length: d.get('length'),
                    renewal: d.get('renewal'),
                    recognition_start: d.get('recognition_policy_start'),
                    recognition_monthly: d.get('recognition_policy_monthly'),
                    recognition_end: d.get('recognition_policy_end')
                });
            })
        );

        this.listenTo(this.leadsModel, 'change', function() {
            view.renderChart();
        });

        this.listenTo(this.salesModel, 'change', function() {
            view.renderChart();
        });

        this.listenTo(this.headcountCollection, 'batch-update', function() {
            new RevenuePlannerConfigModel({ id: 1 }).save(
                {
                    'head_counts_by_date': view.headCountsByDate
                },
                {
                    patch: true
                }
            );

            view.renderChart();
        });

        this.listenTo(this.headcountCollection, 'change', function(model) {
            view.headCountsByDate[model.get('date')] = model.get('count');
        });

        this.listenTo(this.bucketsCollection, 'change', function() {
            view.renderChart();
        });

        this.renderYAxis();
        this.renderChart();
        this.renderHeadcountInputs();
        this.renderBucketLegend();
        this.renderLeadModel();
        this.renderSalesModel();

        this.centerChart();
    },
    centerChart: function() {
        // Center chart on today
        var chartScroll = this.$el.find('.scroll-x');

        var width = chartScroll.width();
        var realWidth = chartScroll.prop('scrollWidth');

        // Data starts from today - 1 year till today + 3 years for a total of 4 years
        var months = this.headcountCollection.length;
        // Today's index is start + 1 year
        var monthIndex = 12;

        // Get today's X axis position relative to the container
        var todayX = realWidth / months * (monthIndex + 0.5);
        // Center today by taking X axis and minusing half of the container's width
        chartScroll.scrollLeft(todayX - (width / 2), 0);
    },
    renderYAxis: function() {
        this.yAxisRegion.show(new YAxisView());
    },
    renderChart: function() {
        var view = this,
            nowDate, startDate, endDate, model, months, collection;

        nowDate = new Date(view.serverDate);
        startDate = new Date(
            nowDate.getFullYear(),
            nowDate.getMonth() - monthsBack);

        endDate = new Date(
            nowDate.getFullYear(),
            nowDate.getMonth());
        endDate.setMonth(endDate.getMonth() + monthsForward);

        model = new Backbone.Model({
            domain: [startDate, endDate]
        });

        months = _.map(_.range(monthsTotal), function(d) {
            var date;

            date = new Date(view.serverDate);
            date = new Date(date.getFullYear(), date.getMonth());
            date.setMonth(date.getMonth() + d - monthsBack);

            return new Backbone.Model({
                date_str: dateFormat.shortFormatMonth(date.getMonth()) + ' ' + (date.getYear()-100),
                leads: 0,
                closed_deals: 0,
                buckets: new Backbone.Collection(
                    _.map(view.bucketsCollection.models,
                          function(b, i) {
                                return new Backbone.Model({
                                    percentage: b.get('percentage'),
                                    average_selling_price:
                                        b.get('average_selling_price'),
                                    color: colors(i),
                                    value: 0,
                                    renewed_value: 0
                                });
                            }
                    )
                ),
                closed_value: 0,
                renewed_value: 0
            });
        });

        function bookingsChart() {
            var month_index, close_month_index,
                new_leads, lead_growth, salesCycleMonths;


            salesCycleMonths = Math.floor(
                view.leadsModel.get('sales_cycle') / 30);
            month_index = -salesCycleMonths;
            close_month_index = month_index + salesCycleMonths;
            new_leads = view.leadsModel.get('monthly_new_leads');
            lead_growth = view.leadsModel.get('monthly_lead_growth');

            //As each month from now it grows lead_growth%, we have to calculate
            //how it was at the beginning
            _.each(_.range(0, salesCycleMonths + monthsBack), function() {
                new_leads = new_leads / (1 + lead_growth/100);
            });

            // Start generating leads
            _.each(_.range(month_index, months.length), function() {
                var closeRatio = view.leadsModel.get('close_ratio'),
                    closedDeals;

                if( close_month_index < months.length) {
                    closedDeals = new_leads * closeRatio / 100;
                    months[close_month_index].set('closed_deals', closedDeals);
                }

                close_month_index += 1;
                new_leads = new_leads * (1 + lead_growth/100);
            });

            //Calculate closing leals values and renewal
            _.each(months, function(month, mi) {
                var closedDeals = month.get('closed_deals');

                _.each(
                    month.get('buckets').models,
                    //Update each bucket
                    function(bucket,bi) {
                        var bucketModel = view.bucketsCollection.models[bi],
                        bucketLenght = bucketModel.get('length'),
                        bucketRenewal = bucketModel.get('renewal'),
                        closeValue = bucket.get('value'),
                        renewedValue = bucket.get('renewed_value'),
                        renewalBucket, renewalBucketValue;

                        //Calculate bucket value
                        closeValue += bucket.get('average_selling_price') *
                                      (bucket.get('percentage')/100) *
                                      closedDeals;
                        bucket.set('value', closeValue);

                        //Calculate renewal
                        if(mi+bucketLenght<months.length) {
                            renewalBucket = months[mi+bucketLenght].get('buckets').models[bi];
                            renewalBucketValue = renewalBucket.get('renewed_value') +
                                                 (closeValue + renewedValue) * bucketRenewal/100;
                            renewalBucket.set('renewed_value', renewalBucketValue);
                        }
                    }
                );
            });

            // Calculate accumulated bucket values
            _.each(months, function(month) {
                var accumulated = 0,
                    closed = 0,renewed = 0;

                _.each(month.get('buckets').models, function(bucket) {
                    var bucket_value = bucket.get('value') + bucket.get('renewed_value');
                    renewed += bucket.get('renewed_value');
                    closed += bucket.get('value');

                    accumulated += bucket_value || 0;
                    bucket.set('accumulated_value', Math.round(accumulated));
                });

                month.set('closed_value', closed);
                month.set('renewed_value', renewed);
            });

            // Reverse order
            _.each(months, function(month) {
                var buckets = month.get('buckets').models.reverse();

                month.set('buckets', new Backbone.Collection(buckets));
            });
        }

        function capacityChart() {
            var productivity, ramp_time, headCounts, incs, i, prods,
                initial_head_count;

            initial_head_count = view.headcountCollection.models[0].get('count');
            productivity = view.salesModel.get('productivity');
            ramp_time = view.salesModel.get('ramp_time');

            // Calculate increments
            headCounts = _.map(view.headcountCollection.models, function(m) {
                return m.get('count');
            });
            incs = [0];
            for (i = 1; i < headCounts.length; i++) {
                incs.push(headCounts[i]-headCounts[i-1]);
            }

            // Calculate productivities due to initial headcount
            prods = _.map(months, function() {
                return productivity * initial_head_count;
            });

            // Apply changes due to increments / decrements in headcount
            _.each(incs, function(d, i/*, list*/) {
                var index, currentProductivity;

                index = i;
                currentProductivity = 0;

                while (index < prods.length) {
                    if ( d > 0 ) {
                        currentProductivity += productivity * 30 /
                            ramp_time;
                        currentProductivity = Math.min(currentProductivity,
                            productivity);
                    } else {
                        currentProductivity = productivity;
                    }

                    prods[index] += currentProductivity * d;

                    index++;
                }
            });

            _.each(months, function(m, i) {
                m.set('capacity', prods[i]);
            });
        }

        function recognitionChart() {
            var values;

            values = _.map(months, function() {
                return 0;
            });

            // For every bucket
            _.each(view.bucketsCollection.models, function(bucket, bi) {
                var avp, length, renewal, start, monthly, end;

                avp = bucket.get('average_selling_price');
                length = bucket.get('length');
                renewal = bucket.get('renewal');
                start = bucket.get('recognition_start');
                monthly = bucket.get('recognition_monthly');
                end = bucket.get('recognition_end');

                _.each(months, function(month, mi) {
                    var bucket, bucketValue, renew, contractMonth, i;

                    bucket = month.get('buckets').models[bi];
                    bucketValue = bucket.get('value');
                    renew = 1; // A factor that affects all values
                    contractMonth = 0;

                    for (i = mi; i < values.length; i++) {
                        if (contractMonth === length && length) {
                            // Add to this month the 'end' part
                            values[i] +=
                                bucketValue * (end/100) * renew;

                            // And continue with renewals
                            renew *= renewal/100;
                        }

                        contractMonth = contractMonth % length;

                        if (contractMonth === 0) {
                            // Add to this month the 'start' part
                            values[i] +=
                                bucketValue * (start/100) * renew;
                        } else {
                            // Add to this month the 'monthly' part
                            values[i] +=
                                bucketValue * (monthly/100) * renew;
                        }

                        if (length === 0) {
                            // For perpetual buckets, we are done after
                            // the first start payment
                            break;
                        }

                        contractMonth += 1;
                    }
                });
            });

            // Assign values
            _.each(months, function(m, i) {
                m.set('recognized', values[i]);
            });
        }

        function calcPercentages() {
            var temp, maxValue = 0;

            // Calculate max value
            _.each(months, function(month) {
                if (view.display === 'bookings') {
                    temp = 0;
                } else if ( view.display === 'capacity' ) {
                    temp = month.get('capacity');
                } else if ( view.display === 'recognized' ) {
                    temp = month.get('recognized');
                } else {
                    temp = 0;
                }
                maxValue = Math.max(maxValue, temp);

                _.each(month.get('buckets').models, function(bucket) {
                    var value = bucket.get('accumulated_value');

                    maxValue = Math.max(maxValue, value);
                });
            });

            // Calculate percentages
            _.each(months, function(month) {
                var capacityValue,
                closed = month.get('closed_value'),
                renewed = month.get('renewed_value');

                if (view.display === 'bookings') {
                    capacityValue = 0;
                } else if ( view.display === 'capacity' ) {
                    capacityValue = month.get('capacity');
                } else if ( view.display === 'recognized' ) {
                    capacityValue = month.get('recognized');
                } else {
                    capacityValue = 0;
                }

                month.set('closed_pct', Math.floor(closed*100/maxValue) + '%');
                month.set('renewed_pct', Math.floor((renewed+closed)*100/maxValue) + '%');


                month.set('capacity_pct', Math.floor(
                    capacityValue*100/maxValue) + '%');

                _.each(month.get('buckets').models, function(bucket){
                    var value = bucket.get('accumulated_value');

                    bucket.set('value_pct', Math.floor(
                        value*100/maxValue) +'%');
                });

            });
        }

        bookingsChart();
        capacityChart();
        recognitionChart();
        calcPercentages();

        collection = new Backbone.Collection(months);

        this.chart = new ChartView({
            model: model,
            collection: collection,
            mode: this.display
        });
        this.chartRegion.show(this.chart);
    },
    renderHeadcountInputs: function() {
        this.headcountRegion.show(new HeadcountListView({
            collection: this.headcountCollection
        }));
    },
    renderBucketLegend: function() {
        this.bucketLegendRegion.show(new BucketLegendView({
            collection: this.bucketsCollection
        }));
    },
    renderLeadModel: function() {
        this.leadModelRegion.show(new LeadModelView({
            model: this.leadsModel
        }));
    },
    renderSalesModel: function() {
        this.salesModelRegion.show(new SalesModelView({
            model: this.salesModel
        }));
    },
    selectRevenue: function(ev) {
        var index = ev.val;
        if (index === 'bookings') {
            // Bookings
            this.display = 'bookings';
            this.$el
                .removeClass('bookings-capacity bookings-recognized')
                .addClass('bookings');
        } else if (index === 'bookings-capacity') {
            // Bookings + Capacity
            this.display = 'capacity';
            this.$el
                .removeClass('bookings bookings-recognized')
                .addClass('bookings-capacity');
        } else if (index === 'bookings-recognized') {
            // Bookings + Recognized
            this.display = 'recognized';
            this.$el
                .removeClass('bookings bookings-capacity')
                .addClass('bookings-recognized');
        }

        if (this.bucketLegendRegion.$el.hasClass('expanded')) {
            this.toggleBucketConfiguration();
        }

        this.renderChart();
    },
    toggleBucketConfiguration: function() {
        // TODO: Move to item views and trigger from here
        var container = this.$el.find('.bucket-configuration'),
            isExpanded = this.bucketLegendRegion.$el.hasClass('expanded'),
            beforeHeight = 0,
            targetHeight = 'auto',
            finalHeight,
            easing = 'easeOutBack';

        if (isExpanded) {
            beforeHeight = container.height();
            targetHeight = 0;
            easing = 'easeOutExpo';
        }

        // TODO: move expanded class higher up in DOM instead of applying to both elements
        this.bucketLegendRegion.$el.toggleClass('expanded', !isExpanded);
        this.ui.configurationToggle.toggleClass('expanded', !isExpanded);

        finalHeight = container.css('height', targetHeight).height();

        container.css('height', beforeHeight)
            .animate({height: finalHeight}, 500, easing);
    },
    closeBucketConfiguration: function() {
        if (this.bucketLegendRegion.$el.hasClass('expanded')) {
            this.bucketLegendRegion.$el.removeClass('expanded');
            this.ui.configurationToggle.removeClass('expanded');
            this.$el.find('.bucket-configuration').css('height', 0); // Doesn't animate on forceClose
        }
    },
    initSelect2: function() {
        function formatResult(item) {
            return Handlebars.compile(
                item.text +
                '<div class="description">' + item.description + '</div>'
            );
        }
        var revenueOptions = [
            {
                id: 'bookings',
                text: 'Bookings',
                description: ''
            },
            {
                id: 'bookings-capacity',
                text: 'Bookings + Capacity',
                description: ''
            },
            {
                id: 'bookings-recognized',
                text: 'Bookings + Recognized',
                description: ''
            }
        ];

        this.revenueSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.revenueSelect,
            text: 'text',
            data: revenueOptions,
            value: 'bookings',
            options: {
                containerCssClass: 'select2-plain',
                dropdownCssClass: 'revenue-select-popover popover', // has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    }
});

// ------------------------------------------------------------------------
//  Y-Axis View
// ------------------------------------------------------------------------

YAxisView = Marionette.ItemView.extend({
    className: 'y-axis-inner',
    template: Handlebars.compile(yAxisTemplate)
});

// ------------------------------------------------------------------------
//  Chart View
// ------------------------------------------------------------------------
ChartBarView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'bar',
    template: Handlebars.compile(''),
    onRender: function() {
        this.$el.css('height', this.model.get('value_pct'));
        this.$el.css('background-color', this.model.get('color'));
    }
});

ChartItemView = Marionette.CompositeView.extend({
    tagName: 'td',
    template: Handlebars.compile(chartItemTemplate),
    itemViewContainer: '.booking-bar',
    itemView: ChartBarView,
    ui: {
        secondaryBar: '.secondary-bar',
        renewedBar_new: '.renewed-booking-bar .new',
        renewedBar_renewed: '.renewed-booking-bar .renewed'
    },
    events: {
        'mouseenter .bar-container': 'showTooltips',
        'mouseleave .bar-container': 'hideTooltips'
    },
    initialize: function() {
        this.collection = this.model.get('buckets');
    },
    onRender: function() {
        this.ui.secondaryBar.css('height', this.model.get('capacity_pct'));

        this.ui.renewedBar_new.css('height', this.model.get('closed_pct'));
        this.ui.renewedBar_renewed.css('height', this.model.get('renewed_pct'));

        this.initPrimaryTooltip();
    },
    initPrimaryTooltip: function() {
        var closed = Math.round(this.model.get('closed_value')),
            renewed = Math.round(this.model.get('renewed_value')),
            accumulated = closed + renewed,
            msg;

        // Custom template allows custom class to be added for styling
        // TODO: Find a way to make this work with Handlebars for better security
        var html = '<div class="tooltip revenue-planner-tooltip ' + this.options.mode + '">' +
                '<div class="tooltip-arrow"></div>' +
                '<ul class="tooltip-inner"></ul>' +
            '</div>';

        if (this.options.mode === 'bookings') {
            msg = Handlebars.compile(
                '<li class="renewed"><span>Renewed:</span> ' + renewed + '</li>' +
                '<li class="closed"><span>Closed:</span> ' + closed + '</li>' +
                '<li class="total"><span>Total:</span> ' + accumulated + '</li>'
            );

            this.$el.find('.booking-bar .bar:first-child').tooltip({
                template: html,
                title: msg,
                trigger: 'focus',
                html: true
            });
        }
        else if(this.options.mode === 'capacity') {
            var capacity = this.model.get('capacity');

            msg = Handlebars.compile(
                '<li class="renewed"><span>Renewed:</span> ' + renewed + '</li>' +
                '<li class="closed"><span>Closed:</span> ' + closed + '</li>' +
                '<li class="total"><span>Total:</span> ' + accumulated + '</li>' +
                '<li class="capacity"><span>Capacity:</span> ' + capacity + '</li>'
            );

            this.$el.find('.renewed-booking-bar .bar:first-child').tooltip({
                template: html,
                title: msg,
                trigger: 'focus',
                html: true
            });
        }
        else {
            var recognized = Math.round(this.model.get('recognized'));

            msg = Handlebars.compile(
                '<li class="renewed"><span>Renewed:</span> ' + renewed + '</li>' +
                '<li class="closed"><span>Closed:</span> ' + closed + '</li>' +
                '<li class="total"><span>Total:</span> ' + accumulated + '</li>' +
                '<li class="recognized"><span>Recognized:</span> ' + recognized + '</li>'
            );

            this.$el.find('.renewed-booking-bar .bar:first-child').tooltip({
                template: html,
                title: msg,
                trigger: 'focus',
                html: true
            });
        }

    },
    showTooltips: function() {
        this.$el.find('.booking-bar .bar:first-child').tooltip('show');
        this.$el.find('.renewed-booking-bar .bar:first-child').tooltip('show');
    },
    hideTooltips: function(e) {
        var self = this,
            tooltip = $(e.relatedTarget).closest('.tooltip.fade.top.in')[0];
        // do not hide tooltip if on tooltip itself
        if (tooltip) {
            $(tooltip).one('mouseleave', function(e) {
                // do not hide tooltip if going back
                if ($(e.relatedTarget).closest(self.$el)[0]) {
                    return;
                }
                self.hideTooltips(e);
            });
            return;
        }
        this.$el.find('.booking-bar .bar:first-child').tooltip('hide');
        this.$el.find('.renewed-booking-bar .bar:first-child').tooltip('hide');
    }
});

ChartView = Marionette.CollectionView.extend({
    tagName: 'tr',
    className: 'chart-bars',
    itemView: ChartItemView,
    initialize: function()
    {
        this.itemViewOptions={mode: this.options.mode};
    }
});

// ------------------------------------------------------------------------
//  Headcount input fields
// ------------------------------------------------------------------------
HeadcountInputItemView = Marionette.ItemView.extend({
    tagName: 'td',
    className: 'headcount-item',
    template: Handlebars.compile(headcountItemTemplate),
    ui: {
        count: '.headcount-input'
    },
    events: {
        'focus .headcount-input': 'onFocus',
        'blur .headcount-input': 'onBlur',
        'click .add': 'addOne',
        'click .subtract': 'subtractOne'
    },
    onRender: function() {
        this.listenTo(this.model, 'change', this.onModelChange);
    },
    onFocus: function() {
        this.$el.addClass('has-focus');
    },
    onBlur: function(ev) {
        var target = $(ev.currentTarget);
        var value = parseInt(target.val() || 0, 10);

        this.$el.removeClass('has-focus');

        if (!isNaN(value)) {
            target.val(value);

            if (this.model.get('count') !== value) {
                this.trigger('modifyValue',value);
            }
        }
        else {
            target.val(this.model.get('count'));
        }

    },
    addOne: function() {
        this.trigger('add-person');
    },
    subtractOne: function() {
        this.trigger('remove-person');
    },
    onModelChange: function() {
        this.ui.count.val(this.model.get('count'));
    }
});

HeadcountListView = Marionette.CollectionView.extend({
    tagName: 'tr',
    className: 'headcount-list',
    itemView: HeadcountInputItemView,
    initialize: function() {
        this.listenTo(this, 'itemview:add-person', this.addPerson);
        this.listenTo(this, 'itemview:remove-person', this.removePerson);
        this.listenTo(this, 'itemview:modifyValue', this.modifyValue);
    },
    addPerson: function(childView) {
        var count = childView.model.get('count');

        this.modifyValue(childView, count + 1);
    },
    removePerson: function(childView) {
        var count = childView.model.get('count');

        this.modifyValue(childView, count - 1);
    },
    modifyValue: function(childView, change) {
        var model = childView.model,
            models = this.collection.models,
            index = _.indexOf(models, model),
            i;

        for (i = index; i < models.length; i++) {
            models[i].set('count', change);
        }

        this.collection.trigger('batch-update');
    }
});

// ------------------------------------------------------------------------
//  Buckets legend
// ------------------------------------------------------------------------

BucketLegendItemView = Marionette.ItemView.extend({
    tagName: 'li',
    template: Handlebars.compile(bucketLegendItemTemplate),
    ui: {
        lengthSelect: '.bucket-length-select'
    },
    events: {
        'change .bucket-mix-input': 'changePercentage',
        'change .bucket-asp': 'changeAsp',
        // 'change .bucket-length select': 'changeLength',
        'change .bucket-renewal': 'changeRenewal',
        'change .bucket-start': 'changeRecognizedStart',
        'change .bucket-monthly': 'changeRecognizedMonthly',
        'change .bucket-end': 'changeRecognizedEnd'
    },
    onRender: function() {
        this.initSelect2();
    },
    initSelect2: function() {
        var self = this;
        this.lengthSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.lengthSelect,
            text: 'title',
            data: lengths,
            value: this.model.get('length'),

            options: {
                containerCssClass: 'select2-block uninverse',
                dropdownCssClass: 'length-select-popover popover'
            }
        });
        this.listenTo(this.lengthSelect, 'change', function(data) {
            self.storeConfig(self.model, 'length', data.id);
        });
    },
    changePercentage: function(ev) {
        this.changeValueSafe('percentage', ev, '%', 0, 100);
    },
    changeAsp: function(ev) {
        this.changeValueSafe('average_selling_price', ev);
    },
    changeRenewal: function(ev) {
        this.changeValueSafe('renewal', ev, '%', 0, 100);
    },
    changeRecognizedStart: function(ev) {
        this.changeValueSafe('recognition_start', ev, '%', 0, 100);
    },
    changeRecognizedMonthly: function(ev) {
        this.changeValueSafe('recognition_monthly', ev, '%', 0, 100);
    },
    changeRecognizedEnd: function(ev) {
        this.changeValueSafe('recognition_end', ev, '%', 0, 100);
    },
    changeValueSafe: function(attr, ev, sufix, min, max) {
        var target = $(ev.currentTarget);
        var value = parseFloat(target.val() || 0);

        if (_.isNumber(min) && value < min) {
            value = min;
        }
        else if (_.isNumber(max) && value > max) {
            value = max;
        }

        sufix = sufix || '';

        if (!isNaN(value)) {
            this.model.set(attr, value);
            this.storeConfig(this.model, attr, value);
            target.val(value + sufix);
        }
        else {
            target.val(this.model.get(attr) + sufix);
        }
    },
    storeConfig: function(model, attr, value) {
        var revenuePlannerConfigModel = new RevenuePlannerBucketModel({ id: model.get('id') }),
            mapping = {
                'percentage': 'mix',
                'recognition_start': 'recognition_policy_start',
                'recognition_monthly': 'recognition_policy_monthly',
                'recognition_end': 'recognition_policy_end'
            },
            attrs = {};

        // legacy mapping
        if (mapping[attr]) {
            attr = mapping[attr];
        }

        attrs[attr] = value;

        revenuePlannerConfigModel.save(
            attrs,
            {
                patch: true
            }
        );
    }
});

// Change to CompositeView if approppiate
BucketLegendView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'bucket-legend-list',
    itemView: BucketLegendItemView
});

// ------------------------------------------------------------------------
//  Lead model
// ------------------------------------------------------------------------

LeadModelView = Marionette.ItemView.extend({
    template: Handlebars.compile(leadModelTemplate),
    events: {
        'change #monthly-new-leads': 'changeMonthlyNewLeads',
        'change #monthly-lead-growth': 'changeMonthlyLeadGrowth',
        'change #close-ratio': 'changeCloseRatio',
        'change #sales-cycle': 'changeSalesCycle'
    },
    changeMonthlyNewLeads: function(ev) {
        this.changeValueSafe('monthly_new_leads', ev);
    },
    changeMonthlyLeadGrowth: function(ev) {
        this.changeValueSafe('monthly_lead_growth', ev, '%');
    },
    changeCloseRatio: function(ev) {
        this.changeValueSafe('close_ratio', ev, '%', 0, 100);
    },
    changeSalesCycle: function(ev) {
        this.changeValueSafe('sales_cycle', ev, ' days');
    },
    changeValueSafe: function(attr, ev, sufix, min, max) {
        var target = $(ev.currentTarget);
        var value = parseFloat(target.val() || 0);

        if (_.isNumber(min) && value < min) {
            value = min;
        }
        else if (_.isNumber(max) && value > max) {
            value = max;
        }

        sufix = sufix || '';

        if(!isNaN(value)) {
            this.model.set(attr, value);
            this.storeConfig(attr, value);
            target.val(value + sufix);
        }
        else {
            target.val(this.model.get(attr) + sufix);
        }
    },
    storeConfig: function(attr, value) {
        var revenuePlannerConfigModel = new RevenuePlannerConfigModel({ id: 1 }),
            attrs = {};

        attrs[attr] = value;
        revenuePlannerConfigModel.save(
            attrs,
            {
                patch: true
            }
        );
    }
});

// ------------------------------------------------------------------------
//  Sales model
// ------------------------------------------------------------------------

SalesModelView = Marionette.ItemView.extend({
    template: Handlebars.compile(salesModelTemplate),
    events: {
        'change #productivity': 'changeProductivity',
        'change #ramp-time': 'changeRampTime'
    },
    changeProductivity: function(ev) {
        this.changeValueSafe('productivity', ev);
    },
    changeRampTime: function(ev) {
        this.changeValueSafe('ramp_time', ev, ' days');
    },
    changeValueSafe: function(attr, ev, sufix) {
        var target = $(ev.currentTarget);
        var value = parseFloat(target.val() || 0);

        sufix = sufix || '';

        if(!isNaN(value)) {
            this.model.set(attr, value);
            this.storeConfig(attr, value);
            target.val(value + sufix);
        }
        else {
            target.val(this.model.get(attr) + sufix);
        }
    },
    storeConfig: function(attr, value) {
        var revenuePlannerConfigModel = new RevenuePlannerConfigModel({ id: 1 }),
            attrs = {};

        attrs[attr] = value;
        revenuePlannerConfigModel.save(
            attrs,
            {
                patch: true
            }
        );
    }
});

export default RevenuePlannerView;

import _ from 'underscore'

import filterOperators from 'js/views/filters/operators.js'
import filterCommon from 'js/views/filters/common'
import app from 'js/app.js'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'

var dealText = TextManager.parseText('${ID_DEAL, capitalize}');
var dealBucketText = TextManager.getText('ID_DEAL_BUCKET');
var dealActivityText = TextManager.getText('ID_ENTITY_ACTIVITY', ['${ID_DEAL, capitalize}']);
var dealPinnedActivityText = TextManager.getText('ID_ENTITY_PINNED_ACTIVITY', ['${ID_DEAL, capitalize}']);
var dealChecklist = TextManager.getText('ID_ENTITY_CHECKLIST', ['${ID_DEAL, capitalize}']);
var funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

var individualText = TextManager.parseText('${ID_INDIVIDUAL, capitalize}');
var individualLocation = TextManager.getText('ID_ENTITY_LOCATION', ['${ID_INDIVIDUAL, capitalize}']);
var individualCommunication = TextManager.getText('ID_ENTITY_COMMUNICATION', ['${ID_INDIVIDUAL, capitalize}']);
var individualActivity = TextManager.getText('ID_ENTITY_ACTIVITY', ['${ID_INDIVIDUAL, capitalize}']);
var individualPinnedActivity = TextManager.getText('ID_ENTITY_PINNED_ACTIVITY', ['${ID_INDIVIDUAL, capitalize}']);
var individualChecklist = TextManager.getText('ID_ENTITY_CHECKLIST', ['${ID_INDIVIDUAL, capitalize}']);
var individualWebActivity = TextManager.getText('ID_INDIVIDUAL_WEB_ACTIVITY');
var campaignsText = TextManager.parseText('${ID_CAMPAIGN, capitalize, plural}');

var organizationText = TextManager.parseText('${ID_ORGANIZATION, capitalize}');
var organizationLocation = TextManager.getText('ID_ENTITY_LOCATION', ['${ID_ORGANIZATION, capitalize}']);
var organizationCommunication = TextManager.getText('ID_ENTITY_COMMUNICATION', ['${ID_ORGANIZATION, capitalize}']);
var organizationActivity = TextManager.getText('ID_ENTITY_ACTIVITY', ['${ID_ORGANIZATION, capitalize}']);
var organizationPinnedActivity = TextManager.getText('ID_ENTITY_PINNED_ACTIVITY', ['${ID_ORGANIZATION, capitalize}']);
var organizationChecklist = TextManager.getText('ID_ENTITY_CHECKLIST', ['${ID_ORGANIZATION, capitalize}']);

export default function() {
    var fields = [
        {
            'id': 'individual_id',
            'name': individualText,
            'operators': filterOperators.individual,
            'group': individualText
        },
        {
            'id': 'individual_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_first_name',
            'name': 'First Name',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_last_name',
            'name': 'Last Name',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_tags',
            'name': 'Tag',
            'operators': filterOperators.tag,
            'group': individualText
        },
        {
            'id': 'individual_funnels',
            'name': 'Funnel',
            'operators': filterOperators.funnel,
            'group': individualText
        },
        {
            'id': 'individual_regions',
            'name': 'Region',
            'operators': filterOperators.region,
            'group': individualText
        },
        {
            'id': 'individual_photo_url',
            'name': 'Photo URL',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': individualText
        },
        {
            'id': 'individual_modified',
            'name': 'Modified',
            'operators': filterOperators.date,
            'group': individualText
        },
        {
            'id': 'individual_became_lead',
            'name': 'Became Lead',
            'operators': filterOperators.date,
            'group': individualText
        },
        {
            'id': 'individual_twitter',
            'name': 'Twitter',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_facebook',
            'name': 'Facebook',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'individual_instagram',
            'name': 'Instagram',
            'operators': filterOperators.string,
            'group': individualText,
            'isAvailable': function() {
                return AppConfig.getValue('hasInstagramSocial', false);
            }
        },
        {
            'id': 'individual_linkedin',
            'name': 'LinkedIn',
            'operators': filterOperators.string,
            'group': individualText
        },
        {
            'id': 'owner_id',
            'name': 'Owner',
            'operators': filterOperators.user,
            'group': individualText
        },

        {
            'id': 'individual_role',
            'name': TextManager.getText('ID_JOB_ROLE'),
            'operators': filterOperators.string,
            'group': individualText
        },

        {
            'id': 'individual_source',
            'name': 'Lead Source',
            'operators': filterOperators.lead_source,
            'group': individualText
        },
        {
            'id': 'is_individual_favorite',
            'name': 'Is Favorite',
            'operators': false,
            'group': individualText
        },
        {
            'id': 'is_individual_recent',
            'name': 'Is Recent',
            'operators': false,
            'group': individualText
        },
        {
            'id': 'individual_unsubscribed_all',
            'name': 'Unsubscribed',
            'operators': false,
            'group': individualText
        },
        {
            'id': 'individual_unsubscribed_all_messages',
            'name': 'Unsubscribed Texts',
            'operators': false,
            'group': individualText,
            'isAvailable': function() {
                return AppConfig.getValue('enableTextMessaging', false);
            }
        },
        {
            'id': 'individual_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': individualText
        },
        {
            'id': 'individual_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': individualText
        },
        {
            'id': 'individual_automation2',
            'name': 'Automation',
            'operators': filterOperators.automation2,
            'group': individualText,
            'isAvailable': function() {
                return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
            }
        },
        {
            'id': 'location_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': individualLocation
        },
        {
            'id': 'location_address',
            'name': 'Address',
            'operators': filterOperators.string,
            'group': individualLocation
        },
        {
            'id': 'location_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': individualLocation
        },

        {
            'id': 'communication_type',
            'name': 'Communications',
            'operators': filterOperators.communication,
            'group': individualCommunication
        },
        {
            'id': 'communication_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': individualCommunication
        },
        {
            'id': 'communication_value',
            'name': 'Value',
            'operators': filterOperators.string,
            'group': individualCommunication
        },
        {
            'id': 'communication_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': individualCommunication
        },

        {
            'id': 'activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': individualActivity
        },
        {
            'id': 'activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': individualActivity
        },
        {
            'id': 'activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': individualActivity
        },
        {
            'id': 'individual_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': individualActivity
        },
        {
            'id': 'individual_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': individualActivity,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'individual_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': individualActivity
        },
        {
            'id': 'individual_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': individualActivity
        },

        {
            'id': 'individual_pinned_activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': individualPinnedActivity
        },
        {
            'id': 'individual_pinned_activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': individualPinnedActivity
        },
        {
            'id': 'individual_pinned_activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': individualPinnedActivity
        },
        {
            'id': 'individual_pinned_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': individualPinnedActivity
        },
        {
            'id': 'individual_pinned_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': individualPinnedActivity,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'individual_pinned_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': individualPinnedActivity
        },
        {
            'id': 'individual_pinned_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': individualPinnedActivity
        },

        {
            'id': 'individual_checklist_id',
            'name': 'Checklist',
            'operators': filterOperators.individual_checklist,
            'group': individualChecklist
        },
        {
            'id': 'individual_checklist_item_assigned_to_me',
            'name': 'Assigned to Me',
            'operators': filterOperators.individual_checklist_item,
            'group': individualChecklist
        },

        {
            'id': 'individual_activity_web_page_visited',
            'name': 'Visited',
            'operators': filterOperators.date,
            'group': individualWebActivity,
            'parameter': {
                'id': 'url',
                'type': 'string',
                'placeholder': 'http://website_url',
                'prefix': null
            },
            'parseValues': function(data) {
                data.parameter_value = data.values.url;
                data.values = data.values.value;
            },
            'composeValues': function(data) {
                return {
                    'url': data.parameter_value || '',
                    'value': data.values
                };
            },
            'composeText': function(values, operator) {
                var url = values.url || 'any url';
                var operatorText = operator.name + (operator.text ? (' ' + operator.text(values.value)) : '');

                return [individualWebActivity + ' Visited', url, operatorText].join(' ');
            }
        },
        {
            'id': 'individual_activity_web_page_registered',
            'name': 'Registered',
            'operators': filterOperators.date,
            'group': individualWebActivity
        },
        {
            'id': 'organization_id',
            'name': organizationText,
            'operators': filterOperators.organization,
            'group': organizationText
        },
        {
            'id': 'organization_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': organizationText
        },
        {
            'id': 'organization_website',
            'name': 'Website',
            'operators': filterOperators.string,
            'group': organizationText
        },
        {
            'id': 'organization_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': organizationText
        },
        {
            'id': 'organization_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': organizationText
        },
        {
            'id': 'organization_modified',
            'name': 'Modified',
            'operators': filterOperators.date,
            'group': organizationText
        },
        {
            'id': 'organization_owner_id',
            'name': 'Owner',
            'operators': filterOperators.user,
            'group': organizationText
        },
        {
            'id': 'organization_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': organizationText
        },
        {
            'id': 'organization_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': organizationText
        },
        {
            'id': 'is_organization_recent',
            'name': 'Is Recent',
            'operators': false,
            'group': organizationText
        },
        {
            'id': 'organization_tags',
            'name': 'Tag',
            'operators': filterOperators.tag,
            'group': organizationText
        },
        {
            'id': 'organization_funnels',
            'name': 'Funnel',
            'operators': filterOperators.funnel,
            'group': organizationText
        },
        {
            'id': 'organization_regions',
            'name': 'Region',
            'operators': filterOperators.region,
            'group': organizationText
        },
        {
            'id': 'organization_automation2',
            'name': 'Automation',
            'operators': filterOperators.automation2,
            'group': organizationText,
            'isAvailable': function() {
                return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
            }
        },
        {
            'id': 'organization_location_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': organizationLocation
        },
        {
            'id': 'organization_location_address',
            'name': 'Address',
            'operators': filterOperators.string,
            'group': organizationLocation
        },
        {
            'id': 'organization_location_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': organizationLocation
        },

        {
            'id': 'organization_communication_type',
            'name': 'Communications',
            'operators': filterOperators.communication,
            'group': organizationCommunication
        },
        {
            'id': 'organization_communication_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': organizationCommunication
        },
        {
            'id': 'organization_communication_value',
            'name': 'Value',
            'operators': filterOperators.string,
            'group': organizationCommunication
        },
        {
            'id': 'organization_communication_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': organizationCommunication
        },

        {
            'id': 'organization_activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': organizationActivity
        },
        {
            'id': 'organization_activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': organizationActivity
        },
        {
            'id': 'organization_activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': organizationActivity
        },
        {
            'id': 'organization_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': organizationActivity
        },
        {
            'id': 'organization_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': organizationActivity,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'organization_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': organizationActivity
        },
        {
            'id': 'organization_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': organizationActivity
        },

        {
            'id': 'organization_pinned_activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': organizationPinnedActivity
        },
        {
            'id': 'organization_pinned_activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': organizationPinnedActivity
        },
        {
            'id': 'organization_pinned_activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': organizationPinnedActivity
        },
        {
            'id': 'organization_pinned_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': organizationPinnedActivity
        },
        {
            'id': 'organization_pinned_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': organizationPinnedActivity,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'organization_pinned_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': organizationPinnedActivity
        },
        {
            'id': 'organization_pinned_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': organizationPinnedActivity
        },

        {
            'id': 'organization_checklist_id',
            'name': 'Checklist',
            'operators': filterOperators.organization_checklist,
            'group': organizationChecklist
        },
        {
            'id': 'organization_checklist_item_assigned_to_me',
            'name': 'Assigned to Me',
            'operators': filterOperators.organization_checklist_item,
            'group': organizationChecklist
        },
        {
            'id': 'opportunity_id',
            'name': dealText,
            'operators': filterOperators.opportunity,
            'group': dealText
        },
        {
            'id': 'opportunity_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': dealText
        },
        {
            'id': 'opportunity_funnel_id',
            'name': TextManager.parseText('${ID_FUNNEL, capitalize}'),
            'operators': filterOperators.funnel,
            'group': dealText
        },
        {
            'id': 'opportunity_funnel_region_id',
            'name': TextManager.parseText('${ID_FUNNEL, capitalize}') + ' Region',
            'operators': filterOperators.funnel_region,
            'group': dealText
        },
        {
            'id': 'funnel_cluster_id',
            'name': TextManager.parseText('${ID_CLUSTER, capitalize}'),
            'operators': filterOperators.funnel_cluster,
            'group': funnelText
        },
        {
            'id': 'opportunity_phase_id',
            'name': TextManager.parseText('${ID_PHASE, capitalize}'),
            'operators': filterOperators.phase,
            'group': dealText
        },
        {
            'id': 'opportunity_status',
            'name': TextManager.parseText('${ID_STATUS, capitalize}'),
            'operators': filterOperators.status,
            'group': dealText
        },
        {
            'id': 'opportunity_closed_date',
            'name': TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize} Date'),
            'operators': filterOperators.date,
            'group': dealText
        },
        {
            'id': 'opportunity_comments',
            'name': TextManager.getText('ID_MORE_INFO'),
            'operators': filterOperators.string,
            'group': dealText
        },
        {
            'id': 'opportunity_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': dealText
        },
        {
            'id': 'opportunity_modified',
            'name': 'Modified',
            'operators': filterOperators.date,
            'group': dealText
        },
        {
            'id': 'opportunity_tags',
            'name': 'Tag',
            'operators': filterOperators.tag,
            'group': dealText
        },
        {
            'id': 'is_opportunity_favorite',
            'name': 'Is Elephant',
            'operators': false,
            'group': dealText,
            'isAvailable': function() {
                return !AppConfig.getValue('disableDealElephants');
            }
        },
        {
            'id': 'opportunity_period_id',
            'name': 'Period',
            'operators': filterOperators.period,
            'group': dealText
        },
        {
            'id': 'opportunity_owner_id',
            'name': 'Owner',
            'operators': filterOperators.user,
            'group': dealText
        },
        {
            'id': 'opportunity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': dealText
        },
        {
            'id': 'opportunity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': dealText
        },
        {
            'id': 'opportunity_automation2',
            'name': 'Automation',
            'operators': filterOperators.automation2,
            'group': dealText,
            'isAvailable': function() {
                return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
            }
        },
        {
            'id': 'opportunity_bucket_id',
            'name': 'Bucket',
            'operators': filterOperators.opportunity_bucket,
            'group': dealBucketText
        },
        {
            'id': 'opportunity_bucket_name',
            'name': 'Name',
            'operators': filterOperators.string,
            'group': dealBucketText
        },
        {
            'id': 'opportunity_bucket_value',
            'name': 'Value',
            'operators': filterOperators.number,
            'group': dealBucketText
        },
        {
            'id': 'opportunity_buckets_total',
            'name': 'Total',
            'operators': filterOperators.number,
            'group': dealBucketText
        },

        {
            'id': 'opportunity_activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': dealActivityText,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'opportunity_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': dealActivityText
        },

        {
            'id': 'opportunity_pinned_activity_note',
            'name': 'Note',
            'operators': filterOperators.string,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_target_date',
            'name': 'Reminder date',
            'operators': filterOperators.date,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_modified',
            'name': 'Note created date',
            'operators': filterOperators.date,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_type',
            'name': 'Type',
            'operators': filterOperators.communication,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_type_ex',
            'name': 'Type Ex',
            'operators': filterOperators.custom.dropDown,
            'group': dealPinnedActivityText,
            'value_def': { options: filterCommon.getActivityTypesList() }
        },
        {
            'id': 'opportunity_pinned_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': dealPinnedActivityText
        },
        {
            'id': 'opportunity_pinned_activity_owned_by_me',
            'name': 'Owned By Me',
            'operators': false,
            'group': dealActivityText
        },
        {
            'id': 'opportunity_pinned_activity_created_by_me',
            'name': 'Created By Me',
            'operators': false,
            'group': dealActivityText
        },

        {
            'id': 'opportunity_checklist_id',
            'name': 'Checklist',
            'operators': filterOperators.opportunity_checklist,
            'group': dealChecklist
        },
        {
            'id': 'opportunity_checklist_item_assigned_to_me',
            'name': 'Assigned to Me',
            'operators': filterOperators.opportunity_checklist_item,
            'group': dealChecklist
        },
        {
            'id': 'group_id',
            'name': 'Group',
            'operators': filterOperators.group,
            'group': 'Group'
        },
        {
            'id': 'task_text',
            'name': 'Text',
            'operators': filterOperators.string,
            'group': 'Task'
        },
        {
            'id': 'task_subject',
            'name': 'Subject',
            'operators': filterOperators.string,
            'group': 'Task'
        }
    ];

    if (true) {
        fields.push(
            {
                'id': 'campaign_stats_send',
                'name': 'Sent',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_delivered',
                'name': 'Delivered',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_open',
                'name': 'Opened',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_click',
                'name': 'Clicked',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_unsubscribed',
                'name': 'Unsubscribed',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_bounce',
                'name': 'Bounced',
                'operators': filterOperators.campaign,
                'group': campaignsText
            },
            {
                'id': 'campaign_stats_spam',
                'name': 'Marked Spam',
                'operators': filterOperators.campaign,
                'group': campaignsText
            }
        );
    }

    // remove non available fields
    return _.filter(fields, function(item) {
        return !('isAvailable' in item) || item.isAvailable();
    });
};

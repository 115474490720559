import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import TextManager from 'app/text-manager'
import webTrackingTemplate from 'templates/settings/web_tracking.handlebars';


export default Marionette.ItemView.extend({
    className: 'web-tracking',
    template: Handlebars.compile(webTrackingTemplate),
    ui: {
        code: "#tracking-code"
    },
    events: {
        'click #tracking-code': function(ev) {
            $(ev.currentTarget).focus().select();
        }
    },
    onRender: function() {
        var cId = app.user.get('client').short_id;
        this.ui.code.text('<script src="https://tstatic.' + TextManager.getText('ID_HOST') + '/tracker.js?clientid=' + cId + '"></script>');
    }
});

import React from 'react';
import Table from 'app_v2/components/table/table';
import KPIWidget from 'js/react_views/widgets/kpi.js';
import TimeSpanWidget from 'js/react_views/widgets/time-span.js';
import TextManager from 'app/text-manager';

import style from './dashboard.css'

class WidgetHeader extends React.Component {
    render() {
        return (
            <div className={style.wHeader}>
                <div className={style.hTitle}>{this.props.title}</div>

                {this.props.button &&
                    <div
                        className={style.hButton}
                        onClick={() => { if (this.props.onButtonClick) { this.props.onButtonClick(); } }}
                    >
                        {this.props.button}
                    </div>
                }
            </div>
        );
    }
}

class RecentDrafts extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [{
            id: 'name',
            title: 'Campaign',
            type: 'text'
        }, {
            id: 'modified',
            title: 'Modified',
            type: 'date',
            width: 150
        }];
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData() {
        this.table.setLoading(true);

        const self = this;

        $.get('/widgets/recent_drafts', function(result) {
            if (!self.mounted) {
                return;
            }

            self.table.setData(result);
            self.table.setLoading(false);
        });
    }

    onResize() {
        this.table?.adjustColumnsWidth();
    }

    refresh() {
        this.fetchData();
    }

    render() {
        return (
            <div
                className={style.widget}
                style={{width: 'calc(50% - 10px)'}}
            >
                <WidgetHeader
                    title='Recent Drafts'
                    button='+ New Campaign'
                    onButtonClick={this.props.onCreateCampaign}
                />

                <div
                    className={style.wContent}
                    style={{height: 'calc(100% - 51px)'}}
                >
                    <Table
                        ref={(el) => this.table = el}
                        columns={this.columns}
                        rows={[]}
                        onRowClick={this.props.onRowClick}
                    />
                </div>
            </div>
        );
    }
}

class ScheduledCampaigns extends React.Component {
    constructor(props) {
        super(props);

        this.columns = [{
            id: 'name',
            title: 'Campaign',
            type: 'text'
        }, {
            id: 'schedule',
            title: 'Scheduled Date + Time',
            type: 'date',
            width: 150
        }];
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    fetchData() {
        this.table.setLoading(true);

        const self = this;

        $.get('/widgets/scheduled_campaigns', function(result) {
            if (!self.mounted) {
                return;
            }

            self.table.setData(result);
            self.table.setLoading(false);
        });
    }

    onResize() {
        this.table?.adjustColumnsWidth();
    }

    refresh() {
        this.fetchData();
    }

    render() {
        return (
            <div
                className={style.widget}
                style={{width: 'calc(50% - 10px)'}}
            >
                <WidgetHeader
                    title='Scheduled Campaigns'
                    button='View More'
                    onButtonClick={() => this.props.onGotoGroup('scheduled')}
                />

                <div
                    className={style.wContent}
                    style={{height: 'calc(100% - 51px)'}}
                >
                    <Table
                        ref={(el) => this.table = el}
                        columns={this.columns}
                        rows={[]}
                        onRowClick={this.props.onRowClick}
                    />
                </div>
            </div>
        );
    }
}

class CampaignPerformanceOverview extends React.Component {
    constructor(props) {
        super(props);

        this.sections = [{
            id: 'campaigns_sent',
            name: TextManager.parseText('${ID_CAMPAIGN, plural, capitalize} Sent')
        }, {
            id: 'emails_sent',
            name: 'Emails Sent'
        }, {
            id: 'delivery_rate',
            name: 'Delivery Rate',
            isPercentage: true
        }, {
            id: 'open_rate',
            name: 'Open Rate',
            isPercentage: true
        }, {
            id: 'click_rate',
            name: 'Click Rate',
            isPercentage: true
        }, {
            id: 'unsubscribe_rate',
            name: 'Unsubscribe Rate',
            isPercentage: true
        }];

        this.dateFilter = {
            'filter_by_date_field': 'sent'
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.fetchValues();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchValues() {
        const self = this;
        const filters = $.param(this.dateFilter);

        $.get(`/campaigns_performance?status=sent&search_not=template&${filters}`, function(result) {
            if (!self.mounted) {
                return;
            }

            self.kpiWidget.setValues(result);
        });
    }

    refresh() {
        this.fetchValues();
    }

    handleSpanChange(spanId, params) {
        let beginDate = null;
        let endDate = null;

        if (spanId === 'custom') {
            beginDate = params.beginDate;
            endDate = params.endDate;
        } else if (spanId !== 'all') {
            const end = new Date();
            const begin = new Date(end);

            switch(spanId) {
                case 'day':
                    begin.setDate(end.getDate() - 1);
                    break;

                case 'week':
                    begin.setDate(end.getDate() - 7);
                    break;

                case 'month':
                    begin.setMonth(end.getMonth() - 1);
                    break;

                case 'quarter':
                    begin.setMonth(end.getMonth() - 3);
                    break;

                case 'year':
                    begin.setFullYear(end.getFullYear() - 1);
                    break;
            }

            beginDate = begin.toISOString();
            endDate = end.toISOString();
        }

        if (beginDate && endDate) {
            this.dateFilter.filter_by_begin_date = beginDate;
            this.dateFilter.filter_by_end_date = endDate;
        } else {
            delete this.dateFilter.filter_by_begin_date;
            delete this.dateFilter.filter_by_end_date;
        }

        this.fetchValues();
    }

    render() {
        return (
            <div className={style.widget}>
                <WidgetHeader
                    title='Campaign Performance Overview'
                    button='View More Insights'
                    onButtonClick={() => this.props.onGotoGroup('insights')}
                />

                <div
                    className={style.wContent}
                    style={{paddingTop: '30px'}}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <TimeSpanWidget
                            onSpanChanged={this.handleSpanChange.bind(this)}
                        />
                    </div>

                    <div
                        style={{margin: '0 -20px'}}
                    >
                        <KPIWidget
                            ref={(el) => this.kpiWidget = el}
                            sections={this.sections}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default class CampaignsDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.components = [];

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    onStartResizing() {
        this.onResizing = true;

        const self = this;

        const tick = () => {
            self.onResize();

            if (self.onResizing) {
                self.resizingFrameId = window.requestAnimationFrame(tick);
            } else {
                window.cancelAnimationFrame(self.resizingFrameId);
            }
        }

        this.resizingFrameId = window.requestAnimationFrame(tick);
    }

    onEndResizing() {
        this.onResizing = false;
    }

    onResize() {
        for (const component of this.components) {
            if (component.onResize) {
                component.onResize();
            }
        }
    }

    refresh() {
        for (const component of this.components) {
            if (component.refresh) {
                component.refresh();
            }
        }
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        this.components = [];

        return (
            <div className={style.campaignsDashboard}>
                <div
                    className={style.widgetsContainer}
                    style={{maxHeight: '50%'}}
                >
                    <RecentDrafts
                        ref={(el) => { if (el) { this.components.push(el) } }}
                        onCreateCampaign={this.props.onCreateCampaign}
                        onRowClick={this.props.onEditCampaign}
                    />

                    <ScheduledCampaigns
                        ref={(el) => { if (el) { this.components.push(el) } }}
                        onGotoGroup={this.props.onGotoGroup}
                        onRowClick={this.props.onEditCampaign}
                    />
                </div>

                <div
                    className={style.widgetsContainer}
                    style={{maxHeight: '50%'}}
                >
                    <CampaignPerformanceOverview
                        ref={(el) => { if (el) { this.components.push(el) } }}
                        onGotoGroup={this.props.onGotoGroup}
                    />
                </div>
            </div>
        );
    }
}

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import MessageBox from 'js/views/message_box'
import PhasesCollection from 'js/collections/phases'
import containerTemplate from 'app/automations/automation/detail.handlebars'
import overviewTemplate from 'app/automations/automation/detail-overview.handlebars'
import editTextTemplate from 'app/automations/automation/edit-text.handlebars'
import editParagraphTemplate from 'app/automations/automation/edit-paragraph.handlebars'
import editNumberTemplate from 'app/automations/automation/edit-number.handlebars'
import editNumberUnitTemplate from 'app/automations/automation/edit-number-unit.handlebars'
import editSelectTemplate from 'app/automations/automation/edit-select.handlebars'
import editActivityTemplate from 'app/automations/automation/edit-activity.handlebars'
import editMailshotTemplate from 'app/automations/automation/edit-mailshot.handlebars'
import editTaskTemplate from 'app/automations/automation/edit-task.handlebars'
import displayValueTemplate from 'app/automations/automation/display-value.handlebars'
import displaySelectTemplate from 'app/automations/automation/display-select.handlebars'
import displayActivityTemplate from 'app/automations/automation/display-activity.handlebars'
import displayMailshotTemplate from 'app/automations/automation/display-mailshot.handlebars'
import displayTaskTemplate from 'app/automations/automation/display-task.handlebars'
import displayWarningTemplate from 'app/automations/automation/display-warning.handlebars'
import editUrlClickTemplate from 'app/automations/automation/edit-url-click.handlebars'
import displayUrlClickTemplate from 'app/automations/automation/display-url-click.handlebars'
import editGroupEventTemplate from 'app/automations/automation/edit-group-event.handlebars'
import displayGroupEventTemplate from 'app/automations/automation/display-group-event.handlebars'


var BaseEditView = Marionette.Layout.extend({
    className: 'editable-container',
    templateHelpers: function() {
        return {
            name: this.options.name,
            placeholder: this.options.placeholder,
            value: this.getValue()
        };
    },
    ui: {
        error_messages: '.error-message'
    },
    initialize: function(options) {
        this.controller = options.controller;
        this.attribute = options.attribute;
        this.type = options.type;
    },
    onShow: function() {
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.errorMessages_remove();
            if (errors[this.attribute]) {
                this.invalid(model, errors[this.attribute]);
            }
        });
        this.errorMessages_unhide();
    },
    getValue: function() {
        var value = this.controller.changes[this.attribute],
            data;

        if (!_.isUndefined(value)) {
            data = value;
        } else {
            data = this.model.get(this.attribute);
        }

        return data.data;
    },
    addChange: function(value) {
        this.controller.addChange(
            this.attribute,
            {
                'type': this.type,
                'data': value
            }
        );
    },
    validation: function() {},
    invalid: function() {},
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
        this.ui.error_messages.empty().removeClass('invalid');
    },
    errorMessages_hide: function() {
        this.ui.error_messages.hide();
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    }
});

var BaseDisplayView = Marionette.Layout.extend({
    className: 'editable-container',
    templateHelpers: function() {
        return {
            name: this.options.name,
            value: this.getValue()
        };
    },
    initialize: function(options) {
        this.controller = options.controller;
        this.attribute = options.attribute;
    },
    onBeforeRender: function() {
        this.$el.toggleClass('no-value', !this.getValue());
    },
    getValue: function() {
        var value = this.model.get(this.attribute);
        return value && value.data;
    }
});

var WarningDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayWarningTemplate),
    templateHelpers: function() {
        return {
            warning: this.options.warning
        };
    },
    onBeforeRender: function() {}
});

var WarningEditView = BaseEditView.extend({
    template: Handlebars.compile(displayWarningTemplate),
    templateHelpers: function() {
        return {
            warning: this.options.warning
        };
    }
});

var TextEditView = BaseEditView.extend({
    template: Handlebars.compile(editTextTemplate),
    templateHelpers: function() {
        return {
            name: this.options.name,
            value: this.getValue()
        };
    },
    events: {
        'change input': function(ev) {
            this.addChange($(ev.currentTarget).val());
        }
    }
});

var TextDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayValueTemplate)
});

var TextValidationEditView = BaseEditView.extend({
    template: Handlebars.compile(editParagraphTemplate),
    ui: _.extend({
        input: 'textarea'
    }, BaseEditView.prototype.ui),
    events: {
        'change textarea': function(ev) {
            this.addChange($(ev.currentTarget).val());
        }
    },
    validation: function(attrs) {
        var errors;

        if (!_.isString(attrs.data)) {
            errors = "Text field is required";
        }

        if (errors) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        this.ui.input
            .addClass('validation_error')
            .nextAll('.error-message')
            .text(errors)
            .addClass('invalid');
    }
});

var ActivityEditView = BaseEditView.extend({
    template: Handlebars.compile(editActivityTemplate),
    ui: _.extend({
        note: '.field-note',
        targetValue: '.field-target-value',
        targetUnit: '.field-target-unit',
        targetFields: '.target-fields',
        targetTypes: 'input[name=group_note_type]'
    }, BaseEditView.prototype.ui),
    events: {
        'change .field-note': 'updateData',
        'change .field-target-value': 'updateData',
        'change input.note-type': 'toggleType'
    },
    onRender: function() {
        var targetUnits = [
            {
                id: 'days',
                text: 'days',
                multiplier: 1
            },
            {
                id: 'weeks',
                text: 'weeks',
                multiplier: 7
            }
        ];

        var value = this.getValue();
        var targetValue = value && value.target_date;
        var targetUnit = 0;
        if (targetValue && targetValue % 7 === 0) {
            targetValue /= 7;
            targetUnit = 1;
        }

        this.ui.targetValue.val(targetValue);
        $(this.ui.targetTypes.get(targetValue ? 1 : 0)).prop('checked', true);
        this.ui.targetFields.toggle(targetValue ? true : false);

        this.unitSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.targetUnit,
            text: 'text',
            data: targetUnits,
            value: targetUnits[targetUnit],

            options: {
                dropdownCssClass: 'target-unit-select-popover popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },
    isReminder: function() {
        var type = this.ui.targetTypes.filter(':checked').val(),
            isReminder = type && type === "reminder";

        return isReminder;
    },
    toggleType: function() {
        this.ui.targetFields.toggle(this.isReminder());

        this.updateData();
    },
    updateData: function() {
        var data = {
            note: this.ui.note.val()
        };
        if (this.isReminder()) {
            var targetValue = parseInt(this.ui.targetValue.val(), 10);
            var multiplier = this.ui.targetUnit.select2('data').multiplier;
            data.target_date = targetValue * multiplier;
        }
        this.addChange(data);
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isString(attrs.data.note) || attrs.data.note.length === 0) {
            errors.note = "Text field is required";
        }

        if (_.isObject(attrs.data) && !_.isUndefined(attrs.data.target_date) &&
            (!_.isNumber(attrs.data.target_date) || _.isNaN(attrs.data.target_date))) {
            errors.target_date = "Numeric field is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.note) {
            this.ui.note
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.note)
                .addClass('invalid');
        }

        if (errors.target_date) {
            this.ui.targetValue
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.target_date)
                .addClass('invalid');
        }
    }
});

var ActivityDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayActivityTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        var targetDate = value && value.target_date;
        if (targetDate % 7 === 0) {
            targetDate = (targetDate / 7) + ' weeks';
        } else if (targetDate) {
            targetDate = targetDate + ' days';
        }

        return {
            name: this.options.name,
            value: {
                note: value && value.note,
                target_date: targetDate
            }
        };
    }
});

var NumberUnitEditView = BaseEditView.extend({
    template: Handlebars.compile(editNumberUnitTemplate),
    ui: _.extend({
        value: '.field-value',
        unit: '.field-unit'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function() {
            this.addChange(parseInt(this.ui.value.val(), 10) * this.ui.unit.select2('data').multiplier);
        }
    },
    onRender: function() {
        var targetUnits = [
            {
                id: 'days',
                text: 'days',
                multiplier: 1
            },
            {
                id: 'weeks',
                text: 'weeks',
                multiplier: 7
            }
        ];

        var value = this.getValue();
        var unit = 0;
        if (value && value % 7 === 0) {
            value /= 7;
            unit = 1;
        }
        this.ui.value.val(value);

        this.unitSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.unit,
            text: 'text',
            data: targetUnits,
            value: targetUnits[unit],

            options: {
                dropdownCssClass: 'unit-select-popover popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },
    validation: function(attrs) {
        var errors;

        if (!_.isNumber(attrs.data)) {
            errors = "Numeric field is required";
        }

        if (errors) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        this.ui.value
            .addClass('validation_error')
            .nextAll('.error-message')
            .text(errors)
            .addClass('invalid');
    }
});

var NumberUnitDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayValueTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        if (value % 7 === 0) {
            value = (value / 7) + ' weeks';
        } else {
            value = value + ' days';
        }

        return {
            name: this.options.name,
            value: value
        };
    }
});

var EmailEditView = BaseEditView.extend({
    template: Handlebars.compile(editSelectTemplate),
    ui: _.extend({
        'input': 'input'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function() {
            this.addChange({
                campaign: this.ui.input.select2('data')
            });
        }
    },
    onRender: function() {
        var value = this.getValue();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/campaigns?status=ready,draft,sent,scheduled',
            search: true,
            text: 'name',
            value: value && value.campaign,

            options: {
                placeholder: TextManager.getText('ID_SELECT_A_CAMPAIGN'),
                dropdownCssClass: 'mailshot-select-popover popover select2-drop-wider'
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.campaign)) {
            errors.mailshot = TextManager.getText('ID_ENTITY_IS_REQUIRED', ['${ID_CAMPAIGN, capitalize}']);
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.mailshot) {
            this.ui.input.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.mailshot)
                .addClass('invalid');
        }
    }
});

var EmailDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayValueTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            name: this.options.name,
        }
    }
});

var EmailUrlClickEditView = BaseEditView.extend({
    template: Handlebars.compile(editUrlClickTemplate),
    ui: _.extend({
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function() {
            this.addChange({
                campaign: this.ui.input1.select2('data'),
                url: this.ui.input2.val()
            });
        }
    },
    onRender: function() {
        var value = this.getValue();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input1,
            url: '/campaigns?status=ready,draft,sent,scheduled',
            search: true,
            text: 'name',
            value: value && value.campaign,

            options: {
                placeholder: TextManager.getText('ID_SELECT_A_CAMPAIGN'),
                dropdownCssClass: 'mailshot-select-popover popover select2-drop-wider'
            }
        });

        this.ui.input2.val(value && value.url);
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.campaign)) {
            errors.mailshot = TextManager.getText('ID_ENTITY_IS_REQUIRED', ['${ID_CAMPAIGN, capitalize}']);
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.mailshot) {
            this.ui.input1.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.mailshot)
                .addClass('invalid');
        }
    }
});

var EmailUrlClickDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayUrlClickTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            campaign: value && value.campaign.name,
            url: value && value.url
        };
    }
});

var SendCampaignEditView = BaseEditView.extend({
    template: Handlebars.compile(editMailshotTemplate),
    ui: _.extend({
        'input1': 'input:eq(0)',
        'input2': 'input:eq(1)'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function() {
            this.addChange({
                campaign: this.ui.input1.select2('data'),
                filter: this.ui.input2.select2('val') === 'filtered'
            });
        }
    },
    onRender: function() {
        var value = this.getValue();

        function formatResult(item) {
            return Handlebars.compile(
                item.name +
                '<div class="description">' + item.description + '</div>'
            );
        }

        this.select1 = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input1,
            url: '/campaigns?status=ready',
            search: true,
            text: 'name',
            value: value && value.campaign,

            options: {
                placeholder: TextManager.getText('ID_SELECT_A_CAMPAIGN'),
                dropdownCssClass: 'mailshot-select-popover popover select2-drop-wider'
            }
        });

        this.select2 = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input2,
            data: this.data,
            value: value && (value.filter ? 'filtered' : 'all') || 'filtered',

            options: {
                placeholder: 'Select a recipient',
                dropdownCssClass: 'mailshot-recipient-select-popover popover has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        this.addChange({
            campaign: this.ui.input1.select2('data'),
            filter: this.ui.input2.select2('val') === 'filtered'
        });
    },
    data:[
        {
            id: 'all',
            name: TextManager.getText('ID_CAMPAIGN_GROUP'),
            description: TextManager.getText('ID_SEND_TO_CAMPAIGN_GROUP')
        },
        {
            id: 'filtered',
            name: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
            description: TextManager.parseText('Only send to the ${ID_INDIVIDUAL} who triggered this automation')
        }
    ],
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.campaign)) {
            errors.mailshot = TextManager.getText('ID_ENTITY_IS_REQUIRED', ['${ID_CAMPAIGN, capitalize}']);
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.mailshot) {
            this.ui.input1.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.mailshot)
                .addClass('invalid');
        }
        if (errors.filter) {
            this.ui.input1.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.mailshot)
                .addClass('invalid');
        }
    }
});

var SendCampaignDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayMailshotTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            name: this.options.name,
            value: value,
            filter: value && _.find(this.data, function(item) {
                return item.id === (value.filter ? 'filtered' : 'all');
            })
        };
    },
    data:[
        { id: 'all', name: 'mailshot group' },
        { id: 'filtered', name: 'individual' }
    ]
});

var TaskEditView = BaseEditView.extend({
    template: Handlebars.compile(editTaskTemplate),
    ui: _.extend({
        text: '.field-text',
        dueValue: '.field-due-value',
        dueUnit: '.field-due-unit',
        assigneeInput: '.field-assignee-select'
    }, BaseEditView.prototype.ui),
    events: {
        'change .field': function() {
            var change = {
                text: this.ui.text.val(),
                due_date: {
                    unit: this.ui.dueUnit.select2('data').id,
                    value: parseInt(this.ui.dueValue.val())
                }
            };

            var assigneeId = this.ui.assigneeInput.select2('data').id;
            if (assigneeId !== 0) {
                change.assignee = {
                    id: this.ui.assigneeInput.select2('data').id
                }
            }

            this.addChange(change);
        }
    },
    onRender: function() {
        var value = this.getValue(),
            dueUnits = [
                {
                    id: 'days',
                    text: 'days after trigger'
                },
                {
                    id: 'weeks',
                    text: 'weeks after trigger'
                }
            ];

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.dueUnit,
            text: 'text',
            data: dueUnits,
            value: dueUnits[value && value.due_date && value.due_date.unit === 'weeks' ? 1 : 0],
            options: {
                dropdownCssClass: 'popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        var formatResult = function(item) {
            // separator line doesn't need text
            return item.line ? '': item.name;
        };

        var calculatedAssignee = { id: 0, name: 'Owner of the trigger record' };

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.assigneeInput,
            url: '/users',
            text: 'name',
            value: value && value.assignee ? value.assignee : calculatedAssignee,
            staticModels: [
                new Backbone.Model(calculatedAssignee),
                // This is separator. Should be searchable same as previous items. Item without id has border.
                new Backbone.Model({ name: calculatedAssignee.name, line: true })
            ],
            options: {
                placeholder: 'Choose assignee',
                dropdownCssClass: 'popover',
                formatResult: formatResult
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isString(attrs.data.text) || attrs.data.text.length === 0) {
            errors.text = "Text field is required";
        }

        if (_.isObject(attrs.data) && !_.isUndefined(attrs.data.due_date) &&
            (!_.isNumber(attrs.data.due_date.value) || _.isNaN(attrs.data.due_date.value))) {
            errors.due_date = "Numeric field is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.text) {
            this.ui.text
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.text)
                .addClass('invalid');
        }

        if (errors.due_date) {
            this.ui.dueValue
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.due_date)
                .addClass('invalid');
        }
    }
});

var TaskDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayTaskTemplate),
    templateHelpers: function() {
        var value = this.getValue(),
            val = value && value.due_date && value.due_date.value,
            unit = value && value.due_date && value.due_date.unit;

        if (parseInt(val) === 1) {
            unit = unit.slice(0, -1);
        }

        return {
            text: value && value.text,
            dueText: val + ' ' + unit + ' after trigger',
            assignee: value && value.assignee ? value.assignee.name : 'Owner of the trigger record'
        };
    }
});

var GroupEventEditView = BaseEditView.extend({
    template: Handlebars.compile(editGroupEventTemplate),
    ui: _.extend({
        triggerAfterValue: '.field-due-value',
        triggerAfterUnit: '.field-due-unit',
    }, BaseEditView.prototype.ui),
    events: {
        'change .field': function() {
            var change = {
                trigger_after: {
                    unit: this.ui.triggerAfterUnit.select2('data').id,
                    value: parseInt(this.ui.triggerAfterValue.val())
                }
            };

            this.addChange(change);
        }
    },
    getValue: function() {
        var value = BaseEditView.prototype.getValue.call(this);
        if (_.isNull(value) || _.isUndefined(value)) {
            value = {};
            this.addChange(value);
        }
        if (_.isNull(value.trigger_after) || _.isUndefined(value.trigger_after)) {
            value.trigger_after = {
                'unit': 'hours',
                'value': 0
            };
            this.addChange(value);
        }
        return value;
    },
    onRender: function() {
        var value = this.getValue(),
            triggerAfter = [
                {
                    id: 'hours',
                    text: 'hours after trigger'
                },
                {
                    id: 'days',
                    text: 'days after trigger'
                },
                {
                    id: 'weeks',
                    text: 'weeks after trigger'
                }
            ];

        new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.triggerAfterUnit,
            text: 'text',
            data: triggerAfter,
            value: value && value.trigger_after && _.find(triggerAfter, function(item) {
                return value.trigger_after.unit === item.id;
            }) || triggerAfter[0],
            options: {
                dropdownCssClass: 'popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (_.isObject(attrs.data) && !_.isUndefined(attrs.data.trigger_after) &&
            (!_.isNumber(attrs.data.trigger_after.value) || _.isNaN(attrs.data.trigger_after.value))) {
            errors.trigger_after = "Numeric field is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.trigger_after) {
            this.ui.triggerAfterValue
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.trigger_after)
                .addClass('invalid');
        }
    }
});

var GroupEventDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displayGroupEventTemplate),
    templateHelpers: function() {
        var value = this.getValue(),
            val = value && value.trigger_after && value.trigger_after.value,
            unit = value && value.trigger_after && value.trigger_after.unit;

        if (parseInt(val) === 1) {
            unit = unit.slice(0, -1);
        }

        return {
            triggerText: val === 0 ? 'Immediately' : val + ' ' + unit + ' after trigger',
            warning: this.options.warning
        };
    }
});

var GroupActionEditView = BaseEditView.extend({
    template: Handlebars.compile(editSelectTemplate),
    ui: _.extend({
        'input': 'input'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function(ev) {
            this.addChange({data: $(ev.currentTarget).select2('data')});
        }
    },
    onRender: function() {
        var value = this.getValue();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/groups?' + $.param({
                rows: -1,
                element_type: this.options.element_type,
                group_type: 'static'
            }),
            text: 'name',
            value: value && value.data,

            options: {
                placeholder: 'Select a group',
                dropdownCssClass: 'period-select-popover popover select2-drop-wider'
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.data)) {
            errors.group = "Group is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.group) {
            this.ui.input.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.group)
                .addClass('invalid');
        }
    }
});

var GroupActionDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displaySelectTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            name: this.options.name,
            value: value && value.data && value.data.name
        };
    }
});

var UserEditView = BaseEditView.extend({
    template: Handlebars.compile(editSelectTemplate),
    ui: _.extend({
        'input': 'input'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function(ev) {
            this.addChange({data: $(ev.currentTarget).select2('data')});
        }
    },
    onRender: function() {
        var value = this.getValue();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/users',
            text: 'name',
            value: value && value.data,

            options: {
                allowClear: !this.options.required,
                placeholder: 'Select a user',
                dropdownCssClass: 'period-select-popover popover'
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (this.options.required && (!_.isObject(attrs.data) || !_.isObject(attrs.data.data))) {
            errors.group = "User is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.group) {
            this.ui.input.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.group)
                .addClass('invalid');
        }
    }
});

var UserDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displaySelectTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            name: this.options.name,
            value: value && value.data && value.data.name
        };
    }
});

var LeadEditView = BaseEditView.extend({
    template: Handlebars.compile(editSelectTemplate),
    ui: _.extend({
        'input': 'input'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function(ev) {
            this.addChange({data: $(ev.currentTarget).select2('data')});
        }
    },
    onRender: function() {
        var value = this.getValue();

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.input,
            url: '/lead_sources',
            text: 'name',
            value: value && value.data,

            options: {
                placeholder: 'Select a lead source',
                dropdownCssClass: 'period-select-popover popover'
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.data)) {
            errors.group = "Lead source is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.group) {
            this.ui.input.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.group)
                .addClass('invalid');
        }
    }
});

var LeadDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displaySelectTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        return {
            name: this.options.name,
            value: value && value.data && value.data.name
        };
    }
});

var PhaseEditView = BaseEditView.extend({
    template: Handlebars.compile(editSelectTemplate),
    ui: _.extend({
        'input': 'input'
    }, BaseEditView.prototype.ui),
    events: {
        'change input': function(ev) {
            var item = $(ev.currentTarget).select2('data');
            this.addChange({
                phase: item,
                funnel: item.funnel,
            });
        }
    },
    onRender: function() {
        var value = this.getValue();
        var phases = new PhasesCollection();
        var self = this;

        phases.fetch({
            success: function() {
                var funnelPhases = phases.getWonLost().concat(phases.getAsHierarchy());

                self.select = new backboneSelect2.SelectView({
                    view: self,
                    $el: self.ui.input,
                    data: funnelPhases,
                    value: value && value.phase ? {
                        id: value.phase.id,
                        name: value.phase.name,
                        funnel: value.phase.funnel || value.funnel
                    } : null,

                    getItemId: function(item) {
                        var value = item.id;
                        if (item.funnel) {
                            value += ':' + item.funnel.id;
                        }
                        return value;
                    },

                    formatSelection: function(item) {
                        if (item.funnel) {
                            return item.funnel.name + ': ' + item.name;
                        } else {
                            return item.name;
                        }
                    },

                    options: {
                        placeholder: TextManager.getText('ID_SELECT_A_PHASE'),
                        dropdownCssClass: 'period-select-popover popover'
                    }
                });
            }
        });
    },
    validation: function(attrs) {
        var errors = {};

        if (!_.isObject(attrs.data) || !_.isObject(attrs.data.phase)) {
            errors.phase = "Phase is required";
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    invalid: function(model, errors) {
        if (errors.phase) {
            this.ui.input.select2('container')
                .addClass('validation_error')
                .nextAll('.error-message')
                .text(errors.phase)
                .addClass('invalid');
        }
    }
});

var PhaseDisplayView = BaseDisplayView.extend({
    template: Handlebars.compile(displaySelectTemplate),
    templateHelpers: function() {
        var value = this.getValue();
        var phase = value && value.phase;
        var funnel = value && value.phase && (value.phase.funnel || value.funnel);
        var name = phase ? (funnel ? funnel.name + ': ' + phase.name : phase.name) : '';
        return {
            name: this.options.name,
            value: name
        };
    }
});

var elementNames = {
    individuals: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
    opportunities: TextManager.parseText('${ID_DEAL, plural, capitalize}')
};


var events = {
    'individuals': [
/*        {
            id: 'web_visit',
            name: 'Visited website',
            edit_view: TextEditView,
            display_view: TextDisplayView,
            options: {
                name: 'Visited',
                placeholder: 'Web page url'
            }
        },*/
        {
            id: 'web_registration',
            name: 'Registered on website',
            edit_view: null,
            display_view: null
        },
        {
            id: 'tweet_mention',
            name: 'Tweeted',
            edit_view: TextEditView,
            display_view: TextDisplayView,
            options: {
                name: 'Containing',
                placeholder: 'Keyword or phrase (optional)'
            }
        },
        {
            id: 'facebook_post',
            name: 'Posted on Facebook page',
            edit_view: TextEditView,
            display_view: TextDisplayView,
            options: {
                name: 'Containing',
                placeholder: 'Keyword or phrase (optional)'
            }
        },
        {
            id: 'campaign_email_open',
            name: TextManager.parseText('${ID_CAMPAIGN, capitalize} email opened'),
            edit_view: EmailEditView,
            display_view: EmailDisplayView,
            options: {
                name: 'Opened'
            }
        },
        {
            id: 'campaign_email_url_click',
            name: TextManager.parseText('${ID_CAMPAIGN, capitalize} email url clicked'),
            edit_view: EmailUrlClickEditView,
            display_view: EmailUrlClickDisplayView,
            options: {
                name: 'Clicked'
            }
        },
        {
            id: 'group_join',
            name: 'Joined group',
            edit_view: GroupEventEditView,
            display_view: GroupEventDisplayView,
            options: {
                name: 'Joined',
                placeholder: 'Group',
                warning: 'This event will trigger for all group members the 1st time it runs'
            }
        },
        {
            id: 'group_leave',
            name: 'Left group',
            edit_view: GroupEventEditView,
            display_view: GroupEventDisplayView,
            options: {
                name: 'Left',
                placeholder: 'Group'
            }
        },
        {
            id: 'inactivity',
            name: 'No activity',
            edit_view: NumberUnitEditView,
            display_view: NumberUnitDisplayView,
            options: {
                name: 'Inactive for',
                value: {
                    value: '0',
                    unit: 'days'
                },
                placeholder: {
                    value: '0',
                    unit: 'days'
                }
            }
        }
    ],
    'opportunities': [
        {
            id: 'phase_change',
            name: 'Deal entered phase',
            edit_view: PhaseEditView,
            display_view: PhaseDisplayView,
            options: {
                name: 'Phase changed to',
                placeholder: 'Phase'
            }
        },
        {
            id: 'group_join',
            name: 'Joined group',
            edit_view: WarningEditView,
            display_view: WarningDisplayView,
            options: {
                name: 'Joined',
                placeholder: 'Group',
                warning: 'This event will trigger for all group members the 1st time it runs'
            }
        },
        {
            id: 'group_leave',
            name: 'Left group',
            edit_view: null,
            display_view: null,
            options: {
                name: 'Left',
                placeholder: 'Group'
            }
        },
        {
            id: 'inactivity',
            name: 'No activity',
            edit_view: NumberUnitEditView,
            display_view: NumberUnitDisplayView,
            options: {
                name: 'Inactive for',
                value: {
                    value: '0',
                    unit: 'days'
                },
                placeholder: {
                    value: '0',
                    unit: 'days'
                }
            }
        }
    ]
};

var actions = {
    'individuals': [
        {
            id: 'create_activity_note',
            name: 'Post a note',
            edit_view: ActivityEditView,
            display_view: ActivityDisplayView,
            options: {
                value: {
                    note: '',
                    value: '1'
                },
                placeholder: {
                    note: 'Write a note',
                    value: '0'
                }
            }
        },
        {
            id: 'create_deal',
            name: 'Create a deal',
            edit_view: UserEditView,
            display_view: UserDisplayView,
            options: {
                name: 'Assign to',
                placeholder: "User",
                required: false
            }
        },
        {
            id: 'send_campaign',
            name: TextManager.getText('ID_SEND_A_CAMPAIGN'),
            edit_view: SendCampaignEditView,
            display_view: SendCampaignDisplayView,
            options: {
                name: TextManager.parseText('${ID_CAMPAIGN, capitalize}'),
                placeholder: TextManager.parseText('${ID_CAMPAIGN, capitalize}')
            }
        },
        {
            id: 'post_tweet',
            name: 'Post a tweet',
            edit_view: TextValidationEditView,
            display_view: TextDisplayView,
            options: {
                name: 'Tweet',
                placeholder: 'Compose a new Tweet',
                validate: true
            }
        },
        {
            id: 'create_task',
            name: 'Create a task',
            edit_view: TaskEditView,
            display_view: TaskDisplayView,
            options: {}
        },
        {
            id: 'join_group',
            name: 'Join group',
            edit_view: GroupActionEditView,
            display_view: GroupActionDisplayView,
            options: {
                name: 'Join',
                placeholder: 'Group',
                element_type: 'individuals'
            }
        },
        {
            id: 'leave_group',
            name: 'Leave group',
            edit_view: GroupActionEditView,
            display_view: GroupActionDisplayView,
            options: {
                name: 'Leave',
                placeholder: 'Group',
                element_type: 'individuals'
            }
        },
        {
            id: 'change_lead_source',
            name: "Set lead source",
            edit_view: LeadEditView,
            display_view: LeadDisplayView,
            options: {
                name: 'Lead source',
                placeholder: 'Lead source'
            }
        },
        {
            id: 'change_owner',
            name: 'Change owner',
            edit_view: UserEditView,
            display_view: UserDisplayView,
            options: {
                name: 'Assign to',
                placeholder: 'User',
                required: true
            }
        }
    ],
    'opportunities': [
        {
            id: 'create_activity_note',
            name: 'Post a note',
            edit_view: ActivityEditView,
            display_view: ActivityDisplayView,
            options: {
                value: {
                    note: '',
                    value: '1'
                },
                placeholder: {
                    note: 'Write a note',
                    value: '0'
                }
            }
        },
        {
            id: 'create_deal',
            name: 'Create a deal',
            edit_view: UserEditView,
            display_view: UserDisplayView,
            options: {
                name: 'Assign to',
                placeholder: "User",
                required: false
            }
        },
        {
            id: 'create_task',
            name: 'Create a task',
            edit_view: TaskEditView,
            display_view: TaskDisplayView,
            options: {}
        },
        {
            id: 'change_phase',
            name: 'Change deal phase',
            edit_view: PhaseEditView,
            display_view: PhaseDisplayView,
            options: {
                name: 'Change phase to',
                placeholder: 'Phase'
            }
        },
        {
            id: 'join_group',
            name: 'Join group',
            edit_view: GroupActionEditView,
            display_view: GroupActionDisplayView,
            options: {
                name: 'Join',
                placeholder: 'Group',
                element_type: 'opportunities'
            }
        },
        {
            id: 'leave_group',
            name: 'Leave group',
            edit_view: GroupActionEditView,
            display_view: GroupActionDisplayView,
            options: {
                name: 'Leave',
                placeholder: 'Group',
                element_type: 'opportunities'
            }
        },
        {
            id: 'change_owner',
            name: 'Change owner',
            edit_view: UserEditView,
            display_view: UserDisplayView,
            options: {
                name: 'Assign to',
                placeholder: 'User',
                required: true
            }
        }
    ]
};

var DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.changes = {};
        this.validation = {};
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        this.changes[key] = value;
    },
    removeChange: function(key) {
        delete this.changes[key];
    },
    saveEdition: function() {
        var controller = this;

        this.trigger('editing:save:before');

        if(_.keys(this.changes).length || this.model.isNew()) {
            this.model.save(this.changes, {
                validate: this.validation,
                patch: true,
                success: function() {
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('automation:save', controller.model);
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    addValidation: function(key, fn) {
        this.validation[key] = fn;
    },
    removeValidation: function(key) {
        delete this.validation[key];
    },
    cancelEdition: function() {
        var controller = this;
        this.model.fetch({
            success: function(){
                controller.trigger('editing:cancel');
                controller.endEditing();
            }
        });
    },
    destroyModel: function() {
        this.model.destroy();
    }
});

var DetailView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-automation at-top',
    template: Handlebars.compile(containerTemplate),
    templateHelpers: function () {
        var owner = this.model.get('owner');
        return {
            owner: owner ? { name: owner['name'] } : null
        };
    },
    regions: {
        overviewContainerRegion: '.overview-container'
    },
    ui: {
        header: '.detail-header',
        footer: '.detail-footer',
        send_dialog: '.send-dialog',
        display_owner: '.owner'
    },
    events: {
        'click .edit.has-permission': function clickEdit() {
            this.controller.startEditing();
        },
        'click .save': function clickSave() {
            this.controller.saveEdition();
        },
        'click .cancel': function clickCancelSave() {
            this.controller.cancelEdition();
        },
        'click .close-view': 'closeView',
        'click .delete-button': function() {
            var self = this,
                mbContent = {
                    accept_is_negative: true,
                    message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({name: this.model.get('name')}),
                    icon: 'icon-trashcan'
                };
            MessageBox.showYesNo(mbContent, this, function() {
                self.controller.destroyModel();
                self.closeView();
                vent.trigger('automation:delete');
            });
        },
        'resize': 'scrollbar'
    },
    initialize: function() {
        if (this.model.isNew()) {
            this.creatingNewItem = true;
            this.model.set("owner", { id: app.user.get('id'), name: app.user.get('name') });
        }
        this.parent = this.options.parent;
        this.editing = this.options.editing;

        this.controller = new DetailViewController({
            model: this.model
        });

        this.listenTo(this.parent, 'refresh', function() {
            this.trigger('item:select', this.model);
        });
        this.listenTo(this.controller, 'editing:start', this.onStartEditing);
        this.listenTo(this.controller, 'editing:end', this.onEndEditing);
        this.listenTo(this.model, 'sync', this.onModelSync);
        this.listenTo(this.model, 'destroy remove', this.onModelDestroy);

        this.listenTo(this, 'render close', function() {
            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        });
    },
    onRender: function() {
        var view = this,
            model = this.model;

        // New item styling if no model.id is present
        this.$el.toggleClass('is-new', !model.id);

        this.listenTo(this.overviewContainerRegion, 'show', function(child) {
            this.scrollbar();
            child.$el.scroll(this.scrollEvents.bind(this));
        });

        if (model.id) {
            this.model.fetch({
                success: function() {
                    view.onModel(model);

                    if (view.editing) {
                        view.controller.startEditing();
                    }
                }
            });
        }
        else {
            // Render overview region
            this.overviewContainerRegion.show(new OverviewView({
                controller: this.controller,
                parent: this,
                model: model
            }));

            if (this.editing) {
                this.controller.startEditing();
            }
        }

        this.ui.header.find('[data-toggle=tooltip]').tooltip({ placement: 'bottom' });

        // Resize revenue planner on window resize
        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    onStartEditing: function() {
        this.$el.addClass('edit-mode');
        this.scrollbar();
    },
    onEndEditing: function() {
        // Exit edit mode
        // OR
        // Cancel create new item
        if (this.model.id) {
            this.$el.removeClass('edit-mode');
            this.scrollbar();
        }
        else {
            this.parent.closeItem();
        }
    },
    onModelSync: function() {
        if (this.creatingNewItem) {
            this.creatingNewItem = false;
            this.editing = false;
            this.render();
            vent.trigger('AppContent:contentChange');
        }
    },
    onModelDestroy: function() {
        this.trigger('view:close', this);
    },
    onModel: function(model) {
        if (this.overviewContainerRegion) {
            this.overviewContainerRegion.show(new OverviewView({
                controller: this.controller,
                parent: this,
                model: model
            }));

            var owner = this.model.get('owner');
            this.ui.display_owner.text(owner ? owner['name'] : '');
        }
    },
    closeView: function(ev) {
        if (ev) {
            ev.preventDefault();
        }
        this.parent.closeItem();
    },
    getUrl: function() {
        if (this.model.isNew()) {
           return 'new';
        }
        return this.model.get('short_id') || this.model.get('id');
    },
    getParams: function() {
        var params = {};
        if (this.controller.isEditing()) {
            params.edit = true;
        }

        return params;
    },
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.overviewContainerRegion.$el) {
            return;
        }

        var container = this.overviewContainerRegion.$el,
            footerHeight = this.ui.footer.is(':visible') ? this.ui.footer.height() : 0,
            availableHeight = (this.$el.height() - this.ui.header.height() - footerHeight),
            overviewHeight = container.find('.content-inner:first').outerHeight();
            // If we eventually add a footer ...
            // height = Math.min(overviewHeight, availableHeight);

        // container.css('maxHeight', height); // If we eventually add a footer
        container.css('height', availableHeight);
        this.$el.toggleClass('has-sticky-nav', (overviewHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.overviewContainerRegion.currentView.$el;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});

/*
 * The main area of the detail page, with the header, communications,
 * locations, comments, related deals...
 */
var OverviewView = Marionette.Layout.extend({
    className: 'overview content',
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        var inputUrl;
        var group = this.model.get('group');

        if (group) {
            if (group.element_type === 'opportunities') {
                inputUrl = '#deals/groups/' + group.short_id;
            }
            else {
                inputUrl = '#contacts/group/' + group.short_id;
            }
        }

        return {
            inputUrl: inputUrl
        };
    },
    regions: {
        eventDisplayRegion: '.display-event-region',
        eventEditRegion: '.field-event-region',
        actionDisplayRegion: '.display-action-region',
        actionEditRegion: '.field-action-region'
    },
    ui: {
        display_name: '.display-name',
        display_description: '.display-description',
        display_element_type: '.display-element-type',
        display_group: '.display-group',
        display_action_type: '.display-action-type',
        display_event_type: '.display-event-type',
        edit_name: '.field-name',
        edit_description: '.field-description',
        edit_element_type: '.field-element-type',
        edit_group: '.field-group',
        edit_action_type: '.field-action-type',
        edit_event_type: '.field-event-type',
        error_messages: '.error-message',
        toggle_status: '.toggle-status',
        descriptionContainer: '.description',
        tooltips: '[data-toggle=tooltip]'
    },
    events: {
        'click .description .toggle-expand': function(ev) {
            var container = this.ui.descriptionContainer;
            ev.preventDefault();
            container.toggleClass('expanded');
            $(ev.currentTarget).text( container.hasClass('expanded') ? 'less' : 'more' );
            this.$el.trigger('resize');
        },
        'click .toggle-status': function(ev) {
            var view = this;

            ev.preventDefault();

            // Show immediate feedback in UI
            this.model.set('running', !this.model.get('running'));
            this.onStatusChange();

            this.model.save({
                running: this.model.get('running')
            }, {
                patch: true,
                error: function() {
                    // Change back if there is an error
                    // TODO: show feedback on run/pause button if switched back
                    view.onStatusChange();
                }
            });
        },
        'click .step-group .display-field.link': function(ev) {
            ev.preventDefault();
            var group = this.model.get('group');
            if (group.element_type === 'opportunities') {
                vent.trigger('groups:opportunities', group.id);
            }
            else {
                vent.trigger('groups:detail', null, group.id, group.element_type);
            }
        },
        'change .field-name': function(ev) {
            this.controller.addChange('name', $(ev.target).val());
        },
        'change .field-description': function(ev) {
            this.controller.addChange('description', $(ev.target).val());
        },
        'change .field-element-type': function(ev) {
            this.controller.addChange('element_type', $(ev.target).val());

            var oldModel = this.model.toJSON();
            var model = this.model.toJSON();
            _.forEach(this.controller.changes, function(value, key){
                model[key] = value;
            });

            var element_type = model.element_type;
            var group;

            if (model.group && model.group.element_type === element_type) {
                group = {
                    id: model.group.id,
                    title: model.group.name,
                    item: model.group
                };
            } else if (oldModel.group && oldModel.group.element_type === element_type) {
                this.controller.removeChange('group');
                group = {
                    id: oldModel.group.id,
                    title: oldModel.group.name,
                    item: oldModel.group
                };
            }

            this.ui.edit_group.prop('disabled', element_type ? false : true);
            this.groupSelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.edit_group,
                url: '/groups?' + $.param({
                    element_type: element_type
                }),
                search: true,
                text: 'title',
                value: group,
                options: {
                    placeholder: 'Search for a group',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'group-select-popover popover select2-drop-wider',
                    minimumInputLength: 1
                }
            });
            this.groupSelect.$el.trigger('change');

            var event_type;
            if (model.event && _.find(events[element_type], function(value) { return value.id === model.event.type; })) {
                event_type = model.event.type;
            } else if (oldModel.event && _.find(events[element_type], function(value) { return value.id === oldModel.event.type; })) {
                this.controller.removeChange('event');
                event_type = oldModel.event.type;
            }
            this.ui.edit_event_type.prop('disabled', element_type ? false : true);
            this.eventSelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.edit_event_type,
                text: 'name',
                data: events[element_type] || [],
                value: event_type,

                options: {
                    placeholder: 'Search for an event',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'event-select-popover popover has-description'
                }
            });
            this.eventSelect.$el.trigger('change');

            var action_type;
            if (model.action && _.find(actions[element_type], function(value) { return value.id === model.action.type; })) {
                action_type = model.action.type;
            } else if (oldModel.action && _.find(actions[element_type], function(value) { return value.id === oldModel.action.type; })) {
                this.controller.removeChange('action');
                action_type = oldModel.action.type;
            }
            this.ui.edit_action_type.prop('disabled', element_type ? false : true);
            this.actionSelect = new backboneSelect2.SelectView({
                view: this,
                $el: this.ui.edit_action_type,
                text: 'name',
                data: actions[element_type] || [],
                value: action_type,

                options: {
                    placeholder: 'Search for an action',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'action-select-popover popover has-description'
                }
            });
            this.actionSelect.$el.trigger('change');
        },
        'change .field-group': function(ev) {
            var result = $(ev.target).select2('data');
            this.controller.addChange('group', result && result.item);
        },
        'change .field-event-type': function(ev) {
            var event = $(ev.target).select2('data');
            if (!event) {
                this.controller.addChange('event', null);
                this.eventDisplayRegion.reset();
                this.eventEditRegion.reset();
                return;
            }

            var oldModel = this.model.toJSON();
            var oldEventType = oldModel['event'] && oldModel['event'].type;
            var newEventType = event.id;
            if (oldEventType === newEventType) {
                this.controller.removeChange('event');
            } else {
                this.controller.addChange('event', {type: newEventType, data: null});
            }

            if (event.display_view) {
                var displayView = new event.display_view(_.extend({
                    controller: this.controller,
                    model: this.model,
                    attribute: 'event',
                    type: newEventType
                }, event.options));
                this.eventDisplayRegion.show(displayView);
            } else {
                this.eventDisplayRegion.reset();
            }

            if (event.edit_view) {
                var editView = new event.edit_view(_.extend({
                    controller: this.controller,
                    model: this.model,
                    attribute: 'event',
                    type: newEventType
                }, event.options));
                this.eventEditRegion.show(editView);

                this.controller.addValidation('event', editView.validation.bind(editView));
            } else {
                this.eventEditRegion.reset();

                this.controller.removeValidation('event');
            }
        },
        'change .field-action-type': function(ev) {
            var action = $(ev.target).select2('data');
            if (!action) {
                this.controller.addChange('action', null);
                this.actionDisplayRegion.reset();
                this.actionEditRegion.reset();
                return;
            }

            var oldModel = this.model.toJSON();
            var oldActionType = oldModel['action'] && oldModel['action'].type;
            var newActionType = action.id;
            if (oldActionType === newActionType) {
                this.controller.removeChange('action');
            } else {
                this.controller.addChange('action', {type: newActionType, data: null});
            }

            if (action.display_view) {
                var displayView = new action.display_view(_.extend({
                    controller: this.controller,
                    model: this.model,
                    attribute: 'action',
                    type: newActionType
                }, action.options));
                this.actionDisplayRegion.show(displayView);
            } else {
                this.actionDisplayRegion.reset();
            }

            if (action.edit_view) {
                var editView = new action.edit_view(_.extend({
                    controller: this.controller,
                    model: this.model,
                    attribute: 'action',
                    type: newActionType
                }, action.options));
                this.actionEditRegion.show(editView);

                this.controller.addValidation('action', editView.validation.bind(editView));
            } else {
                this.actionEditRegion.reset();

                this.controller.removeValidation('action');
            }
        }
    },
    initialize: function(options) {
        this.controller = options.controller;

        this.listenTo(this.model, 'sync', this.onModelSync);

        this.listenTo(this.controller, 'editing:cancel', this.errorMessages_hide);
        this.listenTo(this.controller, 'editing:start', this.errorMessages_unhide);
        this.listenTo(this.controller, 'editing:save:after', this.errorMessages_remove);

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.errorMessages_remove();

            if (errors.name) {
                this.ui.edit_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.name)
                    .addClass('invalid');
            }

            if (errors.element_type) {
                this.ui.edit_element_type.select2('container')
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.element_type)
                    .addClass('invalid');
            }

            if (errors.group) {
                this.ui.edit_group.select2('container')
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.group)
                    .addClass('invalid');
            }

            if (errors.event_type) {
                this.ui.edit_event_type.select2('container')
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.event_type)
                    .addClass('invalid');
            }

            if (errors.action_type) {
                this.ui.edit_action_type.select2('container')
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.action_type)
                    .addClass('invalid');
            }

            // Focus the first invalid element (deferred to target child views too)
            _.defer(function() {
                if (this.$el.find('.validation_error:first').hasClass('select2-container')) {
                    // Only way to focus select2-container programmatically
                    this.$el.find('.validation_error:first').next('.select2-offscreen').select2('focus');
                } else {
                    this.$el.find('.validation_error:first').focus();
                }
                // Trigger resize in DOM
                this.$el.trigger('resize');
            }.bind(this));
        });
    },
    onRender: function() {
        this.initTooltips();

        this.updateSelect2();

        this.resizeCallback =  _.debounce(function() {
            this.updateDescriptionWidth();
        }.bind(this), 100);
        $(window).on('resize', this.resizeCallback);
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    onModelSync: function() {
        if (this.isClosed) {
            return;
        }

        var model = this.model.toJSON();

        this.ui.display_name.text(model.name);
        this.ui.display_description.text(model.description);
        this.ui.descriptionContainer.removeClass('expanded')
            .find('.toggle-expand')
            .text('more');
        this.updateDescriptionWidth();
        this.ui.display_element_type.text(elementNames[model.element_type]);
        this.ui.display_group.text(model.group ? model.group.name : '');
        var event = _.find(events[model.element_type], function(item) {
            return item.id === model.event.type;
        });
        this.ui.display_event_type.text(event ? event.name : '');
        var action = _.find(actions[model.element_type], function(item) {
            return item.id === model.action.type;
        });
        this.ui.display_action_type.text(action ? action.name : '');

        this.updateSelect2();

        this.onStatusChange();

        this.$el.trigger('resize');
    },
    updateSelect2: function() {
        var model = this.model.toJSON();

        this.elementTypeSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_element_type,
            text: 'name',
            value: model.element_type,
            data: [
                {
                    id: 'individuals',
                    name: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}')
                },
                {
                    id: 'opportunities',
                    name: TextManager.parseText('${ID_DEAL, plural, capitalize}')
                }
            ],

            options: {
                placeholder: 'Select an element type',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'event-select-popover popover has-description'
            }
        });
        this.elementTypeSelect.$el.trigger('change');
    },
    onStatusChange: function() {
        var running = this.model.get('running');

        this.$el.toggleClass('paused', !running);
        this.ui.toggle_status
            .tooltip('hide')
            .attr('data-original-title', running ? 'Pause Automation' : 'Run Automation')
            .tooltip('fixTitle');
    },
    updateDescriptionWidth: function() {
        if (this.isClosed) {
            return;
        }

        var container = this.ui.descriptionContainer,
            isOverflowing = (this.ui.display_description.width() > container.width());

        if (container.hasClass('expanded')) {
            return;
        }

        container.toggleClass('expandable', isOverflowing);
    },
    initTooltips: function() {
        // Initialize all tooltips
        this.ui.tooltips.tooltip();

        // Update title on status toggle
        this.ui.toggle_status
            .attr('data-original-title', this.model.get('running') ? 'Pause Automation' : 'Run Automation')
            .tooltip('fixTitle');
    },
    showTab: function(ev) {
        ev.preventDefault();
        $(ev.currentTarget).tab('show'); // Bootstrap tabs
    },
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
        this.ui.error_messages.empty().removeClass('invalid');
    },
    errorMessages_hide: function() {
        this.ui.error_messages.hide();
        if (this.eventEditRegion.currentView) {
            this.eventEditRegion.currentView.errorMessages_hide();
        }
        if (this.actionEditRegion.currentView) {
            this.actionEditRegion.currentView.errorMessages_hide();
        }
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    }
});

export default DetailView;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import FilterFields from 'js/views/filters/fields'
import backboneSelect2 from 'js/widgets/backbone-select2'
import filterAdvancedTemplate from 'templates/filters/advanced-popover.handlebars'
import filterAdvancedItemTemplate from 'templates/filters/advanced-item.handlebars'

var ParameterStringView = Marionette.Layout.extend({
    template: Handlebars.compile('<input class="value" type="text" placeholder="{{placeholder}}" value="{{value}}"/>'),
    events: {
        'change .value': function(ev) {
            ev.preventDefault();

            if (this.options.onChange) {
                this.options.onChange($(ev.currentTarget).val());
            }
        }
    },
    templateHelpers: function()
    {
        return {
            value: this.options.value || '',
            placeholder: this.options.data.placeholder || ''
        };
    }
});


var RulesItemView = Marionette.Layout.extend({
    className: 'rule form-group',
    template: Handlebars.compile(filterAdvancedItemTemplate),
    templateHelpers: function() {
        // Supply unique identifier to label 'for' reference
        return { cid: this.cid };
    },
    ui: {
        inclusion: '.rule-inclusion',
        field: '.field',
        operator: '.operator',
        or: '.or',
        prefix: '.prefix'
    },
    events: {
        'click .delete-item': function(ev) {
            var view = this;
            ev.preventDefault();

            function destroy() {
                view.model.destroy();
            }

            this.$el
                .animate(
                    { opacity: 0 },
                    { queue: false, duration: 200 }
                )
                .slideUp(200, destroy);
        },
        'change .include-toggle': function(ev) {
            ev.preventDefault();

            this.model.unset('not');
        },
        'change .exclude-toggle': function(ev) {
            ev.preventDefault();

            this.model.set('not', true);
        },
        'focus .rule-inclusion input[type=radio]': function(ev) {
            $(ev.currentTarget).closest('.radios').addClass('has-focus');
        },
        'blur .rule-inclusion input[type=radio]': function(ev) {
            $(ev.currentTarget).closest('.radios').removeClass('has-focus');
        }
    },
    regions: {
        valuesRegion: '.values-container',
        parameterRegion: '.parameter'
    },
    initialize: function(options) {
        _.extend(this, options);

        this.parametersView = {
            string: ParameterStringView
        };
    },
    validate: function(attrs) {
        this.model.trigger('validate');
        var field_id = this.model.get('field');
        var custom_id = this.model.get('custom');
        var operator_id = this.model.get('operator');
        var errors = {};

        var join_id;
        if (field_id) {
            join_id = [field_id];
            if (custom_id) {
                join_id.push(custom_id);
            }
            join_id = join_id.join('#');
        }

        var fields = this.fields;
        var field = join_id && _.find(fields, function(item) {
            return item.id === join_id;
        });

        if (field) {
            var operators = field.operators;
            var operator = operator_id && _.find(operators, function(item) {
                return item.id === operator_id;
            });

            if (operators && !operator) {
                errors.operator = true;
            }
        } else {
            errors.field = true;
        }

        if (this.valueView) {
            var valuesError = this.valueView.validate(attrs);
            if (valuesError) {
                errors.values = valuesError;
            }
        }

        return _.isEmpty(errors) ? false : errors;
    },
    onRender: function() {
        this.model.validate = this.validate.bind(this);

        this.listenTo(this.model, 'validate', function() {
            this.errorMessages_remove();
        });

        this.listenTo(this.model, 'invalid', function(model, errors) {
            if (errors.field) {
                this.ui.field.find('.select2-choice').addClass('validation_error');
            }
            if (errors.operator) {
                this.ui.operator.find('.select2-choice').addClass('validation_error');
            }
        });

        var field_id = this.model.get('field');
        var custom_id = this.model.get('custom');
        var operator_id = this.model.get('operator');

        var field_id_join;
        if (field_id) {
            field_id_join = [field_id];
            if (custom_id) {
                field_id_join.push(custom_id);
            }
            field_id_join = field_id_join.join('#');
        }

        var fields = this.fields;
        var field = field_id_join && _.find(fields, function(item) {
            return item.id === field_id_join;
        }) || fields[0];

        this.fieldSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.field,
            data: _.chain(fields)
                .groupBy(function(item) {
                    return item.group;
                })
                .map(function(item, name) {
                    return {
                        'name': name,
                        'children': item
                    };
                }).value(),
            value: field,

            options: {
                matcher: function(term, optText, data) {
                    var group = data.group;
                    var allText = (group ? group + ' ' : '') + optText;
                    return allText.toUpperCase().indexOf(term.toUpperCase()) >= 0;
                },
                placeholder: 'Select a field',
                containerCssClass: 'form-control',
                dropdownCssClass: 'rule-select-popover popover'
            }
        });
        this.listenTo(this.fieldSelect, 'change', this.onFieldUpdate);
        var operators = field.operators;
        var operator = operator_id && _.find(operators, function(item) {
            return item.id === operator_id;
        }) || operators[0];

        this.operatorSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.operator,
            data: operators || [],
            value: operator,

            options: {
                placeholder: 'Select an operator',
                containerCssClass: 'form-control',
                dropdownCssClass: 'rule-select-popover popover'
            }
        });
        this.listenTo(this.operatorSelect, 'change', this.onOperatorUpdate);
    },
    onShow: function() {
        this.fieldSelect.trigger('change', { initialChange: true });

        this.onCollectionUpdate();

        this.$el.show();

        this.$el.find('[data-toggle="tooltip"]').tooltip();
    },
    onCollectionUpdate: function() {
        if (this.isClosed) {
            return;
        }

        if (this.collection.indexOf(this.model) === this.collection.length - 1) {
            this.ui.or.fadeOut(200);
        } else {
            this.ui.or.fadeIn(200);
        }
    },
    onFieldUpdate: function(options) {
        var data = this.fieldSelect.$el.select2('data');

        var info = data && data.id.split('#');
        this.model.set('field', info[0]);
        if (info.length > 1) {
            this.model.set('custom', info[1]);
        } else {
            this.model.unset('custom');
        }
        this.value_def = data.value_def;

        this.updateOperatorSelect(data.operators, options);

        // Trigger a resize when extra fields are added or removed
        this.$el.trigger('resize');
    },
    updateOperatorSelect: function(operators, options) {
        if (operators) {
            var operator_id = this.operatorSelect.$el.select2('val');
            var operator = _.find(operators, function(item) {
                return item.id === operator_id;
            }) || operators[0];

            this.operatorSelect.options.data.results = operators;
            this.operatorSelect.$el.select2(this.operatorSelect.options);
            this.operatorSelect.$el.select2('data', operator);

            this.operatorSelect.trigger('change', options);

            if (operators.length > 1) {
                this.operatorSelect.$el.select2('container').parent().show();
            } else {
                this.operatorSelect.$el.select2('container').parent().hide();

                this.ui.prefix.text(operator.name);
                this.ui.prefix.show();
            }
        } else {
            this.operatorSelect.$el.select2('container').parent().hide();

            this.valueView = null;
            this.valuesRegion.reset();

            this.model.unset('operator');
            this.model.unset('values');
        }
    },
    onOperatorUpdate: function(options) {
        var data = this.operatorSelect.$el.select2('data');

        this.model.set('operator', data.id);

        var prefix = data && data.prefix;

        if (prefix) {
            this.ui.prefix.text(prefix);
            this.ui.prefix.show();
        } else {
            this.ui.prefix.hide();
        }

        var controls = this.$el.find('.controls');

        if (controls && controls.length > 0) {
            if (data && data.higherControls) {
                $(controls[0]).addClass('two-lines');
            } else {
                $(controls[0]).removeClass('two-lines');
            }
        }

        var ValueView = data && data.view;

        if (this.valuesRegion) {
            if (ValueView) {
                if (!(options && options.initialChange)) {
                    this.model.unset('values', { silent: true });
                }
                this.valueView = new ValueView({
                    model: this.model,
                    value_def: this.value_def
                });
                this.valuesRegion.show(this.valueView);
            } else {
                this.valueView = null;
                this.valuesRegion.reset();

                this.model.unset('values');
            }
        }

        var field = this.fieldSelect.$el.select2('data');
        var self = this;

        if (field.parameter) {
            this.parameterRegion.show(new this.parametersView[field.parameter.type]({
                data: field.parameter,
                value: self.model.get('parameter_value'),
                onChange: function(val) {
                    self.model.set('parameter_value', val);
                }
            }));

            if (prefix && !_.isUndefined(field.parameter)) {
                if (field.parameter.prefix) {
                    this.ui.prefix.text(field.parameter.prefix);
                }
                else {
                    this.ui.prefix.hide();
                }
            }
        }
        else {
            this.model.unset('parameter_value');
            this.parameterRegion.reset();
        }

        // Trigger a resize when extra fields are added or removed
        this.$el.trigger('resize');
    },
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
    },
    errorMessages_hide: function() {
        this.ui.error_messages.hide();
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    }
});

var RulesCollectionView = Marionette.CollectionView.extend({
    tagName: 'form',
    className: 'rules form-inline',
    itemView: RulesItemView,
    itemViewOptions: function() {
        return {
            collection: this.collection,
            fields: this.fields
        };
    },
    initialize: function(options) {
        _.extend(this, options);

        this.listenTo(this.collection, 'remove', this.onCollectionUpdate);
    },
    onCollectionUpdate: function() {
        if (!this.collection.length) {
            this.collection.add({});
        }
        this.$el.trigger('resize');
    },
    appendHtml: function(collectionView, itemView, index) {
        var childAtIndex;

        // could just quickly
        // use prepend
        if (index === 0) {
            return collectionView.$el.prepend(itemView.el);
        } else {
            // see if there is already
            // a child at the index
            childAtIndex = collectionView.$el.children().eq(index);
            if (childAtIndex.length) {
                return childAtIndex.before(itemView.el);
            } else {
                return collectionView.$el.append(itemView.el);
            }
        }
    },
    onBeforeRender: function() {
        // Override method for animating new items
        this.onBeforeItemAdded = function(){};
    },
    onRender: function() {
        // Animate new items on show
        this.onBeforeItemAdded = function(view) {
            view.on('show', function() {
                view.$el
                    .css('opacity', 0)
                    .slideDown(200)
                    .animate(
                        { opacity: 1 },
                        { queue: false, duration: 400 }
                    );
            });
        };
    }
});

export default Marionette.Layout.extend({
    className: 'add-filter-rule-popover',
    template: Handlebars.compile(filterAdvancedTemplate),
    templateHelpers: function() {
        return {
            title: this.title
        };
    },
    regions: {
        rulesRegion: '.rules-container'
    },
    events: {
        'click .add-item': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.clonedCollection.add({});
        },
        'keypress .add-item': function(ev) {
            // Enter key
            if (ev.keyCode === 13) {
                this.clonedCollection.add({});
                return false;
            }
        },
        'click .form-save': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            var valid = !this.clonedCollection.find(function(model) {
                return !model.isValid();
            });

            if (valid) {
                var filters = [];
                var filterFields = FilterFields();

                this.clonedCollection.each(function(model) {
                    var filter = model.toJSON();
                    var field = _.findWhere(filterFields, {id: filter.field});

                    if (field && field.composeValues) {
                        filter.values = field.composeValues(filter);
                        delete filter['parameter_value']
                    }

                    filters.push(filter);
                });

                this.collection.reset(filters);
                this.trigger('form-saved');
                this.close();
            }
        },
        'click .form-cancel': function(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            this.close();
        }
    },
    initialize: function(options) {
        _.extend(this, options);

        var filters = [];
        var filterFields = FilterFields();

        this.collection.each(function(model) {
            var filter = model.toJSON();
            var field = _.findWhere(filterFields, {id: filter.field});

            if (field && field.parseValues) {
                field.parseValues(filter);
            }

            filters.push(filter);
        });

        this.clonedCollection = new Backbone.Collection(filters);
        if (!this.clonedCollection.length) {
            this.clonedCollection.add({});
        }
    },
    onShow: function() {
        var view = this;

        this.$el.show();
        this.$el.focus();

        if (this.rulesRegion) {
            this.rulesRegion.show(new RulesCollectionView({
                collection: this.clonedCollection,
                fields: this.fields
            }));
        }

        $(document).on('mousedown.hide-rule-popover', function(ev) {
            if (
                !$(ev.target).is( view.$el.children() ) &&
                !$(ev.target).closest(view.$el).length &&
                !$('*').datepicker( 'widget' ).is(':visible') &&
                !$(ev.target).is('.select2-drop-mask') &&
                !$(ev.target).is( $('.rule-select-popover').children() ) &&
                !$(ev.target).is( view.$el.parent() )
            ) {
                view.close();
                $(document).off('mousedown.hide-rule-popover');
            }
        });
    }
});

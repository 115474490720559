class Action {
  constructor(options) {
    this.options = options || {};
  }

  redo() {
    this.do();
  }

  do() {}
  undo() {}

  scrollToBlock(blockId) {
    if (!blockId) {
      return;
    }

    const block = this.options.canvas.getBlockById(blockId);
    const bbox = block[0].getBoundingClientRect();
    const iframebbox = this.options.canvas.iframe.getBoundingClientRect();

    if ((bbox.bottom < 0) || (bbox.top > iframebbox.height)) {
      block[0].scrollIntoView();
    }
  }

  getInsertionInfo(block, insertInfo) {
    let iinfo = null;

    if (insertInfo) {
      iinfo = {
        insertBefore: insertInfo.before
      };

      if (insertInfo.block.hasClass('seeBlockEmptyMessage')) {
        if (insertInfo.block.text().indexOf('block') !== -1) { // main section
          iinfo.sibling = insertInfo.block;
        } else {
          // this would be easier using an id for the columns, but that wouldn't work for existing campaigns
          iinfo.emptyMessageBlockParentId = insertInfo.block.parents('.seeBlockContainer').attr('id');
          iinfo.emptyMessageColumnIdxParent = insertInfo.block.parents('td.full').prev('td.full').length === 0 ? 0 : 1; // first o second column?
        }
      } else {
        iinfo.siblingId = insertInfo.block.attr('id');
      }
    } else if (block) {
      const prevSibling = block.prev('.seeBlockContainer');
      const nextSibling = block.next('.seeBlockContainer');

      if (prevSibling.length > 0) {
        iinfo = {
          siblingId: prevSibling.attr('id'),
          insertBefore: false
        };
      } else if (nextSibling.length > 0) {
        iinfo = {
          siblingId: nextSibling.attr('id'),
          insertBefore: true
        };
      }

      // is the block inside another block (i.e. singlecolumn or multicolumn block) or in a main section (header, body, footer)?
      const emptyMessageBlock = block.siblings('.seeBlockEmptyMessage');

      if (emptyMessageBlock.text().indexOf('block') !== -1) { // main section
        iinfo = {
          sibling: emptyMessageBlock,
          insertBefore: true
        };
      } else { // N column(s) block
        iinfo = {
          emptyMessageBlockParentId: emptyMessageBlock.parents('.seeBlockContainer').attr('id'),
          emptyMessageColumnIdxParent: emptyMessageBlock.parents('td.full').prev('td.full').length === 0 ? 0 : 1, // first o second column?
          insertBefore: true
        };
      }
    }

    return iinfo;
  }

  getBlockInsertionInfo(insertInfo) {
    if (!insertInfo) {
      return null;
    }

    if (insertInfo.siblingId) {
      return {
        block: this.options.canvas.getBlockById(insertInfo.siblingId),
        before: insertInfo.insertBefore
      };
    } else if (insertInfo.sibling) {
      return {
        block: insertInfo.sibling,
        before: insertInfo.insertBefore
      };
    } else if (insertInfo.emptyMessageBlockParentId) {

      return {
        block: this.options.canvas.getBlockById(insertInfo.emptyMessageBlockParentId).find('td.seeColumn').eq(insertInfo.emptyMessageColumnIdxParent).find('.seeBlockEmptyMessage'),
        before: insertInfo.insertBefore
      };
    }
  }
}


export class SelectBlockAction extends Action {
  do() {
    this.currentBlockId = this.options.currentBlock?.attr('id');
    this.newBlockId = this.options.newBlock?.attr('id');
    this.options.canvas.props.onBlockSelected(this.newBlockId, _.extend(this.options.newBlockParams || {}, {fromAction: true}));
  }

  undo() {
    this.scrollToBlock(this.currentBlockId);
    this.options.canvas.props.onBlockSelected(this.currentBlockId, {fromAction: true});
  }

  redo() {
    this.scrollToBlock(this.newBlockId);
    this.options.canvas.props.onBlockSelected(this.newBlockId, {fromAction: true});
  }
}


export class AddBlockAction extends Action {
  do() {
    this.insertInfo = this.getInsertionInfo(null, this.options.insertInfo);
    this.options.canvas.addBlock(this.options.section, this.options.html, this.options.insertInfo);
  }

  undo() {
    this.options.canvas.deleteBlock(this.options.html.attr('id'));
  }

  redo() {
    this.options.canvas.addBlock(this.options.section, this.options.html, this.getBlockInsertionInfo(this.insertInfo), {redoingAction: true});
  }
}


export class DeleteBlockAction extends Action {
  do() {
    this.block = this.options.block.clone();
    this.section = this.options.canvas.getSectionById(this.options.block.parents('.seeBlockSection').attr('id'));
    this.insertInfo = this.getInsertionInfo(this.options.block);

    this.options.canvas.deleteBlock(this.block.attr('id'));
  }

  undo() {
    this.options.canvas.addBlock(this.section, this.block.clone(), this.getBlockInsertionInfo(this.insertInfo));
  }

  redo() {
    this.options.canvas.deleteBlock(this.block.attr('id'));
  }
}


export class CloneBlockAction extends Action {
  do() {
    this.section = this.options.canvas.getSectionById(this.options.block.parents('.seeBlockSection').attr('id'));
    this.prevSiblingId = this.options.block.attr('id');
    this.insertInfo = this.getInsertionInfo(this.options.block);
    this.newBlock = this.options.canvas.cloneBlock(this.options.block);
  }

  undo() {
    this.options.canvas.deleteBlock(this.newBlock.attr('id'));
  }

  redo() {
    const blockInserted = this.options.canvas.addBlock(this.section, this.newBlock.clone(), this.getBlockInsertionInfo(this.insertInfo));
    this.options.canvas.configureClonedBlock(blockInserted, true);
  }
}


export class MoveBlockAction extends Action {
  do() {
    this.insertInfoOrigin = this.getInsertionInfo(this.options.movingBlock);
    this.insertInfoTarget = this.getInsertionInfo(null, this.options.insertInfo);
    this.movingBlockId = this.options.movingBlock.attr('id');
    this.move(this.insertInfoTarget);
  }

  undo() {
    this.move(this.insertInfoOrigin);
  }

  redo() {
    this.move(this.insertInfoTarget);
  }

  move(ii) {
    const insertInfo = this.getBlockInsertionInfo(ii);
    const movingBlock = this.options.canvas.getBlockById(this.movingBlockId);

    if (insertInfo.before) {
      insertInfo.block.before(movingBlock);
    } else {
      insertInfo.block.after(movingBlock);
    }

    this.options.canvas.manageEmptyBlocksVisibility();
    this.options.canvas.props.onContentChange();
  }
}

export class LockBlockAction extends Action {
  do() {
    this.options.block.attr(`see${this.options.type}locked`, this.options.locked ? 'true' : 'false');
    this.options.component.setLocked(this.options.locked);
    this.options.canvas.props.onContentChange();
  }

  undo() {
    this.options.block.attr(`see${this.options.type}locked`, this.options.locked ? 'false' : 'true');
    this.options.component.setLocked(!this.options.locked);
    this.options.canvas.props.onContentChange();
  }
}

export class UpdateBlockSettingsAction extends Action {
  do() {
    if (!this.options.ignoreDo) {
      const component = this.options.editor.activeBlockComponent;
      component[this.options.setMethod](this.options.newValue, this.options.oldValue, this.options.newParams);
    }
  }

  undo() {
    const component = this.options.editor.activeBlockComponent;
    component[this.options.setMethod](this.options.oldValue, this.options.newValue, this.options.newParams);
  }

  redo() {
    const component = this.options.editor.activeBlockComponent;
    component[this.options.setMethod](this.options.newValue, this.options.oldValue, this.options.newParams);
  }
}

export class UpdateITEAction extends Action {
  undo() {
    this.options.canvas.setActiveITEHtml(this.options.oldValue);
  }

  redo() {
    this.options.canvas.setActiveITEHtml(this.options.newValue);
  }
}

export class AddPlotOfferAction extends Action {
  do() {
    const component = this.options.editor.activeBlockComponent;
    component.addPlotOffer(this.options.id, this.options.block);

    const self = this;

    _.defer(function() {
      self.options.onContentChange();
    });
  }

  undo() {
    const component = this.options.editor.activeBlockComponent;
    component.deletePlotOffer(this.options.id);

    const self = this;

    _.defer(function() {
      self.options.onContentChange();
    });
  }
}

export class DeletePlotOfferAction extends Action {
  do() {
    const component = this.options.editor.activeBlockComponent;
    this.plot = _.clone(this.options.plot);

    component.deletePlotOffer(this.plot.id);

    const self = this;

    _.defer(function() {
      self.options.onContentChange();
    });
  }

  undo() {
    const component = this.options.editor.activeBlockComponent;
    component.addPlotOffer(plot);

    const self = this;

    _.defer(function() {
      self.options.onContentChange();
    });
  }

  redo() {
    const component = this.options.editor.activeBlockComponent;
    component.deletePlotOffer(this.plot.id);

     const self = this;

    _.defer(function() {
      self.options.onContentChange();
    });
  }
}
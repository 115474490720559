import _ from 'underscore'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'

import IPLookupTableDetailView from 'js/views/iplookup_table_detail'
import IPLookupCollection from 'js/collections/iplookup'
import template from 'templates/iplookup_table_view.handlebars'


export default Marionette.Layout.extend({
    className: 'iplookup-view-pane',
    template: Handlebars.compile(template),
    regions: {
        tableRegion: '.content'
    },
    initialize: function(statsProvider) {
        this.statsProvider = statsProvider;
        this.collection = new IPLookupCollection();
    },
    onShow: function() {
        this.showTableView();
    },
    showTableView: function() {
        var tableView = new IPLookupTableDetailView({
            parent: this,
            collection: this.collection
        });

        this.listenTo(tableView, 'fetch-collection', this.fetchCollection);

        this.tableRegion.show(tableView);
    },
    fetchCollection: function(options, page, rows, sortOptions) {
        this.fetchOptions = _.extend({}, this.fetchOptions, options);
        this.page = page || this.page;
        this.rows = rows || this.rows;
        this.sortOptions = sortOptions || this.sortOptions;

        this.statsProvider.fetchData(this.fetchOptions, this.page, this.rows, this.sortOptions, this.onCollection.bind(this));
    },
    onCollection: function(collection) {
        // if we click fast, view might be closed before response arrives
        if (this.isClosed || !collection ) {
            return;
        }

        collection.collectionPage = this.collection.collectionPage;
        this.collection = collection;

        if (this.tableRegion && this.tableRegion.currentView) {
            this.tableRegion.currentView.onCollection(collection);
        }

        this.trigger('iplookup:on-collection-fetched', collection);

        this.$el.find('.content:first').parent().nanoScroller();
    }
});

import React from 'react';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import htmlSanitizer from 'js/utils/html-sanitizer'
import AppConfig from 'app/app-config'
import _ from 'underscore';
import $ from 'jquery';
import guid from 'js/utils/guid';

import style from './select.css';

const colors = {
    faintestGrey: 'rgba(0, 0, 0, 0.07)',
    fainterGrey: 'rgba(0, 0, 0, 0.1)',
    faintGrey: 'rgba(0, 0, 0, 0.2)',
    faintGrey2: '#c1c4cd',
    faintGrey3: 'rgba(165, 170, 182, 0.6)',
    superLightGrey: '#f6f6f6',
    veryLightGrey: '#e0e1e6',
    quiteLightGrey: '#b5babf',
    midGrey: '#9ea7b0',
    darkishGrey: '#666666',
    darkGrey: '#404040',
    bluishGrey: '#888fa0',
    softBlue: '#70c2ff', // lighten(blue, 20%);
    lightBlue: '#d4ecff',
    paleBlue: '#eef7ff',
    blueBackground1: 'rgba(10, 151, 248, 0.8)',
    blueBackground2: 'rgba(10, 151, 255, 0.06)',
    link: '#0a97ff',
    starYellow: '#ffda00',
    green: '#43d350',
    paleGreen: '#A0F89D',
    red: '#ff283a',
    mutedRed: '#e54a45',
    linkedin: '#0075b6',
    twitter: '#00abf1',
    facebook: '#39579d',
    googleplus: '#da3f26',
    instagram: '#C13584'
};

const fontSizes = {
    plusTiny: 7,
    tiny: 11,
    smallest: 12,
    smaller: 13,
    normal: 14,
    large: 16,
    larger: 18,
    largest: 20,
    huge: 24
};

const commonStyles = {
    popoverShadow: '0 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 12px 0px rgba(0, 0, 0, 0.12)'
};

const SELECT_LIST_PADDING = 8;

class SelectExpanded extends React.Component {
    constructor(props) {
        super(props);

        this.itemViews = [];
        this.originalData = _.clone(this.props.data || []);

        this.el = null;
        this.resultsListEl = null;
        this.resultsListOnTop = false;
        this.minimumInputLength = 'minimumInputLength' in this.props.options ? this.props.options.minimumInputLength : 0;
        this.maxHeight = 'maxHeight' in this.props.options ? this.props.options.maxHeight : 214;
        this.searchParameters = _.clone(this.props.searchParameters || {});
        this.addTagsHover = 'addTagsHover' in this.props.options ? this.props.options.addTagsHover : false;

        this.state = {
            textToSearch: '',
            searching: false,
            selectedItemIdx: -1
        }

        let data = [];
        
        if (this.props.data && this.props.data.length > 0) {
            if (this.minimumInputLength <= 0) {
                data = this.props.data;

                if (this.props.value && this.props.value.id) {
                    this.state.selectedItemIdx = this.props.data.findIndex( item => {
                        return item.id === this.props.value.id
                    });
                } else {
                    this.state.selectedItemIdx = 0;
                }
            }
        } else if (this.props.url && this.minimumInputLength <= 0) {
            this.doSearchRequest('');
        }

        this.state.data = data;
        this.doSearchRequest = _.debounce(this.doSearchRequest, 300);
    }

    componentDidMount() {
        this.relocateResultsList();
        this.scrollToSelectedItem();

        if (this.state.selectedItemIdx !== -1) {
            this.selectItemByIdx(this.state.selectedItemIdx);
        }
    }

    componentDidUpdate() {
        this.relocateResultsList();

        if (this.state.selectedItemIdx !== -1) {
            this.selectItemByIdx(this.state.selectedItemIdx);
        }
    }

    relocateResultsList() {
        const elementBB = $(this.props.element)[0].getBoundingClientRect();
        const windowHeight = $(window).height();
        const resultsHeight = $(this.el).height();

        if (this.resultsListOnTop || (elementBB.top + elementBB.height + resultsHeight > windowHeight)) {
            this.resultsListOnTop = true;
            $(this.el).css('top', (elementBB.top - resultsHeight) + 'px');
        } else {
            $(this.el).css('top', (elementBB.top + elementBB.height) + 'px');
        }
    }

    render() {
        const width = this.props.width;
        const elementBB = $(this.props.element)[0].getBoundingClientRect();

        let searchMsg;
        const searchViewVisible = this.minimumInputLength > -1 && !this.props.options.usesInternalSearch;

        this.itemViews = [];

        if (searchViewVisible || this.props.options.usesInternalSearch) {
            if (this.state.textToSearch.length < this.minimumInputLength) {
                let remaining = (this.minimumInputLength - this.state.textToSearch.length);
                searchMsg = 'Please enter ' + remaining + ' more character' + ((remaining > 1) ? 's' : '');
            } else if (this.state.searching) {
                searchMsg = 'Searching...';
            } else if (this.state.textToSearch.length > 0 && this.state.data.length === 0) {
                searchMsg = 'No matches found';
            }
        }

        const formatter = this.props.options.formatResult || this.defaultFormatter.bind(this)
        const disabledIds = this.props.disabledIds || [];
        const self = this;
        let itemIdx = -1;

        let items = _.map(this.state.data, function(item, index) {
            if (item.id === 'separator') {
                return <SeparatorItem
                    key={item.id + '_' + index}
                    ref={(view) => {if (view) {self.itemViews.push(view)}}}
                    selectable={false}
                />
            } else if (item.isSectionTitle) {
                return <SectionTitleItem
                    key={`title_${index}`}
                    ref={(view) => {if (view) {self.itemViews.push(view)}}}
                    selectable={false}
                    title={item.name || item.title || item[this.props.selectedKey] || item.value}
                />
            } else {
                return <self.props.itemView
                    key={`item.id_${index}`}
                    content={formatter(item, self.state.textToSearch)}
                    disabled={_.contains(disabledIds, item.id)}
                    item={item}
                    selectedKey={self.props.selectedKey}
                    ref={(view) => {if (view) {self.itemViews.push(view)}}}
                    onMouseEnter={() => {self.selectItemByIdx(index)}}
                    onClick={() => {self.props.onItemSelect(item)}}
                    selectable={true}
                    addTagsHover={self.addTagsHover}
                />
            }
        });

        return (
            <div
                className={style.ExpandedContainer}
                onClick={(ev) => {ev.stopPropagation(); this.props.onClose()}}
            >
                <div className={style.Backdrop}></div>
                <div
                    ref={(el) => this.el = el}
                    className={style.Content}
                    style={{ width: width + "px", left: elementBB.left + "px" }}
                    onWheel={(ev) => {
                        var el = $(this.resultsListEl);
                        el.scrollTop(el.scrollTop() + ev.deltaY);
                    }}
                >
                    {searchViewVisible &&
                        <div
                            style={{
                                padding: 8,
                                borderBottom: '1px solid ' + colors.faintestGrey
                            }}>
                            <input
                                type="text"
                                autoFocus
                                onClick={this.onSearchInputClick}
                                onChange={this.handleSearchInput.bind(this)}
                                onKeyDown={this.handleKeyDown.bind(this)}
                                style={{
                                    width: '100%',
                                    height: 32
                                }} />
                        </div>
                    }
                    {this.state.data.length > 0 &&
                        <ul
                            ref={(el) => this.resultsListEl = el}
                            style={{
                                position: 'relative',
                                maxHeight: this.maxHeight,
                                padding: SELECT_LIST_PADDING,
                                overflow: 'auto',
                                listStyleType: 'none'
                            }}>
                            {
                                _.map(items, (item, index) => (
                                    item
                                ))
                            }
                        </ul>
                    }
                    {!!searchMsg &&
                        <div
                            style={{
                                padding: 8,
                                lineHeight: '20px',
                                color: colors.faintGrey2
                            }}>
                            {searchMsg}
                        </div>
                    }
                </div>
            </div>
        );
    }

    onSearchInputClick(event) {
        event.stopPropagation();
    }

    scrollToSelectedItem() {
        if (this.state.selectedItemIdx !== -1) {
            const list = $(ReactDOM.findDOMNode(this.resultsListEl));
            const selectedItem = $(ReactDOM.findDOMNode(this.itemViews[this.state.selectedItemIdx]));

            const listHeight = list.outerHeight();
            const scrolledUp = list.scrollTop();
            const itemHeight = selectedItem.outerHeight();
            const itemTop = selectedItem.position().top;
            const hiddenAbove = SELECT_LIST_PADDING - itemTop;
            const hiddenBelow = (itemTop + itemHeight) - (listHeight - SELECT_LIST_PADDING);

            if (hiddenAbove > 0) {
                list.scrollTop(scrolledUp - hiddenAbove);
            }
            if (hiddenBelow > 0) {
                list.scrollTop(scrolledUp + hiddenBelow);
            }
        }
    }

    handleKeyDown(event) {
        var disabledIds = this.props.disabledIds || [];

        switch(event.key) {
            case 'ArrowUp':
                event.preventDefault();

                if (this.state.selectedItemIdx !== -1) {
                    var previousIdx = -1;

                    for (var i = this.state.selectedItemIdx - 1; i >= 0; --i) {
                        if (!_.contains(disabledIds, this.state.data[i].id)) {
                            previousIdx = i;
                            break;
                        }
                    }

                    if (previousIdx !== -1) {
                        this.selectItemByIdx(previousIdx);
                        this.scrollToSelectedItem();
                    }
                }
                break;

            case 'ArrowDown':
                event.preventDefault();
                if (this.state.selectedItemIdx !== -1) {
                    var nextIdx  = -1;

                    for (var i = this.state.selectedItemIdx + 1; i < this.state.data.length; ++i) {
                        if (!_.contains(disabledIds, this.state.data[i].id)) {
                            nextIdx = i;
                            break;
                        }
                    }

                    if (nextIdx !== -1) {
                        this.selectItemByIdx(this.state.selectedItemIdx + 1);
                        this.scrollToSelectedItem();
                    }
                }
                break;

            case 'Enter':
            case 'Tab':
                event.preventDefault();
                if (this.state.selectedItemIdx !== -1) {
                    this.props.onItemSelect(this.state.data[this.state.selectedItemIdx]);
                    this.props.onClose();
                }
                break;

            case 'Escape':
                event.preventDefault();
                this.props.onClose();
                break;
        }
    }

    selectItemByIdx(idx) {
        if (this.state.selectedItemIdx !== -1) {
            this.itemViews[this.state.selectedItemIdx].setState({selected: false});
        }

        this.state.selectedItemIdx = idx;

        if (this.state.selectedItemIdx !== -1) {
            this.itemViews[this.state.selectedItemIdx].setState({selected: true});
        }
    }

    defaultFormatter(item, search) {
        const value = this.props.text(item);

        if (search) {
            const idx = value.toLowerCase().indexOf(search.toLowerCase());
            return value.substring(0, idx) + '<span style="text-decoration: underline;">' + value.substring(idx, idx + search.length) + '</span>' + value.substring(idx + search.length);
        }

        return value;
    }

    updateSearchParameters(searchParameters) {
        this.searchParameters = _.clone(searchParameters);
    }

    doSearchRequest(searchText) {
        const self = this;
        let parameters = this.searchParameters;

        parameters.search = searchText;

        this.searchRequested = searchText;

        $.getJSON(this.props.url + '?' + $.param(parameters), function(data) {
            if (self.searchRequested === self.state.textToSearch) {
                data = data || [];

                if (self.props.processData) {
                    data = self.props.processData(data, self.state.textToSearch);
                }

                self.setState({
                    data: data,
                    searching: false,
                    selectedItemIdx: data.length > 0 ? 0 : -1
                });
            }
        });
    }

    handleSearchInput(event) {
        const inputText = event.target.value;

        this.selectItemByIdx(-1);

        let newState = {
            textToSearch: inputText,
            data: []
        };

        if (this.minimumInputLength > 0 && inputText.length < this.minimumInputLength) {
            this.setState(newState);
            return;
        }

        if (this.props.url) {
            if (inputText) {
                newState.searching = true;
                this.doSearchRequest(inputText);
            } else if (this.minimumInputLength === 0) {
                newState.searching = true;
                this.doSearchRequest('');
            } else {
                newState.searching = false;
            }
        } else {
            const newData = _.clone(this.originalData);

            if (inputText) {
                const searchLower = inputText.toLowerCase();

                newState.data = _.filter(newData, (item) => {
                    if (item.id === 'separator') {
                        return false;
                    }

                    if (this.props.searchFilter) {
                        return this.props.searchFilter(item, inputText);
                    } else {
                        return this.props.text(item).toLowerCase().indexOf(searchLower) !== -1;
                    }
                });
            } else if (this.minimumInputLength <= 0) {
                newState.data = newData;
            }

            newState.selectedItemIdx = newState.data.length > 0 ? 0 : -1;
        }

        this.setState(newState);
    }

    setData(data) {
        data = data || [];
        this.originalData = _.clone(data);

        let newState = {
            data: data,
            selectedItemIdx: data.length > 0 ? 0 : 1,
            searching: false
        };

        if (this.el) {
            this.setState(newState);
        } else {
            _.extend(this.state, newState);
        }
    }
}

class NewSelect extends React.Component {
    constructor(props) {
        super(props);

        this.text = this.text.bind(this);
        this.selectExpanded = null;
        this.data = [];
        this.searchParameters = {};

        if (this.props.options && this.props.options.search_parameters) {
            this.searchParameters = _.clone(this.props.options.search_parameters);
        }

        if (this.props.data) {
            if (_.isFunction(this.props.data)) {
                this.props.data(this.setData.bind(this));
            } else {
                this.data = this.props.data;
            }
        }

        this.state = {
            text: this.props.text || 'title',
            expanded: false,
            textColor: colors.faintGrey2,
            error: false
        };
        this.state.selectedTitle = this.props.value ? this.text(this.props.value) : null;

        if (this.props.options && this.props.options.initiallyExpanded) {
            const self = this;

            _.defer(function() {
                self.toggleExpanded(true);
            });
        }
    }

    setError(error) {
        this.setState({
            error: error
        });
    }

    text(item) {
        const { text } = this.state;
        if (_.isFunction(text)) {
            return text(item);
        }

        return item[text];
    }

    toggleExpanded(expanded) {
        if (this.props.disabled) {
            return;
        }

        this.setState({expanded: expanded});
    }

    isExpanded() {
        return this.state.expanded
    }

    onItemSelect(item, silent) {
        this.selectedItem = item;

        if (item) {
            if (this.props.processSelection) {
                item = this.props.processSelection(item);
            }

            this.setState({selectedTitle: this.text(item)});
            this.boxStyle = _.clone(this.boxStyle);
            this.setState({
                textColor: colors.darkishGrey
            });
        } else {
            this.setState({
                selectedTitle: '',
                textColor: colors.faintGrey2
            });
        }

        if (!silent && this.props.onSelect) {
            if (item) {
                this.props.onSelect([item]);
            } else {
                this.props.onSelect(null);
            }
        }
    }

    updateSearchParameters(searchParameters) {
        this.searchParameters = _.extend(this.searchParameters, searchParameters);

        for (const key in this.searchParameters) {
            if (_.isUndefined(this.searchParameters[key]) || this.searchParameters[key] === null) {
                delete this.searchParameters[key];
            }
        }

        if (this.selectExpanded) {
            this.selectExpanded.updateSearchParameters(searchParameters);
        }
    }

    render() {
        const ItemView = this.props.itemView || NewPlainSelectItem;
        const options = this.props.options || {};

        if (this.props.value) {
            this.state.textColor = colors.darkishGrey;
        }

        this.boxStyle = _.extend(
            {
                color: this.state.textColor,
            },
            this.state.expanded ?
                {
                    borderColor: colors.softBlue,
                    color: this.props.value ? colors.darkishGrey : colors.softBlue,
                    borderRadius: 3
                } : {},
            this.props.disabled ?
                {
                    opacity: "0.5"
                } : {},
            this.props.boxStyle
        );

        let titleStyle = {
            display: 'block',
            marginRight: 40,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'hidden',
            color: !this.state.selectedTitle && "#c1c4cd"
        };


        titleStyle = _.extend(titleStyle, this.props.titleStyle || {});

        const selectArrowStyle = this.props.selectArrowStyle || {};

        const placeholder = this.props.placeholder || 'Search';
        const removeOptionClasses = classnames({
            [style.SelectBoxRemoveOption]: true,
            [style.Hidden]: !this.state.selectedTitle
        });
        const selectBoxClasses = classnames({
            [style.SelectBox]: true,
            [style.AllowClear]: options.allowClear,
            [style.EditViewMod]: this.props.editViewMod,
            [style.Error]: this.state.error
        });

        const value = this.selectedItem || this.props.value;
        return (
            <div
                className={this.props.className}
                ref={(el) => {this.el = el;}}
                style={this.props.containerStyle || { position: 'relative' }}>
                <a
                    className={selectBoxClasses}
                    style={this.boxStyle}
                    onClick={(ev) => {ev.stopPropagation(); this.toggleExpanded(true)}}>
                    <span style={titleStyle}>{this.state.selectedTitle || placeholder}</span>
                    <span
                        style={{
                            display: 'inline-block',
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: 30,
                            height: '100%',
                            color: colors.faintGrey2,
                            textAlign: 'center'
                        }}>
                        {options.allowClear &&
                            <b
                                className={removeOptionClasses}
                                onClick={(ev) => {ev.stopPropagation(); this.setValue(null)}}
                            >
                                {'\u00d7'}
                            </b>
                        }
                        <b className={style.SelectBoxArrowOption} style={selectArrowStyle}>{'\u25BC'}</b>
                    </span>
                </a>
                {this.state.expanded &&
                    <SelectExpanded
                        ref={(el) => {if (el) {this.selectExpanded = el}}}
                        element={this.el}
                        onClose={() => this.onClose()}
                        onItemSelect={(item) => {this.onItemSelect(item)}}
                        processData={this.props.processData}
                        itemView={ItemView}
                        data={this.data}
                        value={value}
                        url={this.props.url}
                        width={this.props.width || 210}
                        options={options}
                        searchParameters={this.searchParameters}
                        searchFilter={this.props.searchFilter}
                        text={this.text}
                    />
                }
            </div>
        );
    }

    onClose() {
        this.toggleExpanded(false);

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    setData(data, activeItemId) {
        this.data = data;

        if (this.selectExpanded) {
            this.selectExpanded.setData(data);
        }

        if (activeItemId) {
            this.setValue(activeItemId);
        }
    }

    setValue(valueId, silent) {
        if (valueId !== null && !_.isUndefined(valueId)) {
            let item = null;

            if (_.isObject(valueId)) {
                item = _.clone(valueId);
            } else {
                item = _.findWhere(this.data, { id: valueId });
            }

            if (item) {
                this.onItemSelect(item, silent);
            }
        } else {
            this.onItemSelect(null, silent);
        }
    }
}

class TagView extends React.Component {
    handleDelete(event) {
        event.stopPropagation();

        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    render() {
        return (
            <li className={style.Choice}>
                <div className={style.Name}>
                    {this.props.text}
                </div>
                <div
                    className={style.Delete}
                    onClick={this.handleDelete.bind(this)}
                >
                    &#x00d7;
                </div>
            </li>
        );
    }
}

class TagSelect extends NewSelect {
    constructor(props) {
        super(props)

        this.text = this.text.bind(this);
        this.disabledIds = _.map(this.props.value || [], function(value) { return value.id });
        const items = _.map(this.props.value || [], function(item) { return item });

        this.state = {
            items: items,
            searchHidden: true,
            error: false
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    clear() {
        this.disabledIds = [];

        this.setState({
            items: []
        });
    }

    text(item) {
        const { text } = this.props;
        if (_.isFunction(text)) {
            return text(item);
        }

        return item[text];
    }

    onItemSelect(item) {
        const newItems = this.state.items.concat(item);

        this.disabledIds.push(item.id);
        this.setState({
            items: newItems
        });

        if (this.props.onChange) {
            this.props.onChange(_.clone(newItems));
        }
    }

    handleDelete(item) {
        var newItems = _.filter(this.state.items, function (i) { return i.id !== item.id });

        this.disabledIds = _.without(this.disabledIds, item.id);
        this.setState({
            items: newItems
        });

        if (this.props.onChange) {
            this.props.onChange(_.clone(newItems));
        }
    }

    handleFocus() {
        if (this.state.expanded) {
            return;
        }

        this.setState({
            searchHidden: false
        });

        this.toggleExpanded(true);

        const self = this;

        _.defer(function() {
            self.input.focus();
        });
    }

    handleKeyDown(event) {
        if (this.state.expanded) {
            this.selectExpanded.handleKeyDown(event);
        }
    }

    handleChange(event) {
        if (this.state.expanded) {
            this.selectExpanded.handleSearchInput(event);
        }
    }

    handleBlur() {
        this.setState({
            searchHidden: true
        });

        this.input.value = '';
    }

    setValue(items) {
        this.disabledIds = items.map(i => i.id);
        this.setState({
            items: items
        });
    }

    setError(error) {
        this.setState({
            error: error
        })
    }

    render() {
        const placeholder = this.props.placeholder || 'Search';
        const ItemView = this.props.itemView || NewPlainSelectItem;
        const items = _.map(this.state.items, (item) => {
            return <TagView
                key={guid()}
                text={this.text(item)}
                onDelete={() => {this.handleDelete(item)}}
            />
        });
        const searchClasses = classnames({
            [style.SearchChoice]: true,
            [style.Hidden]: false //this.state.searchHidden @Cesar: I've commented this because it provoques an issue selecting the items when there are more than one line in the tag box
        });
        const tagClasses = classnames({
            [style.TagSelect]: true,
            [style.EditViewMod]: this.props.editViewMod,
            [style.Expanded]: this.state.expanded,
            [style.Raw]: this.props.raw,
            [style.TagSelectError]: this.state.error
        });

        const showPlaceholder = this.props.placeholder && items.length === 0 && this.state.searchHidden;
        let searchParameters = {};

        if (this.props.options && this.props.options.search_parameters) {
            searchParameters = _.clone(this.props.options.search_parameters);
        }

        return (
            <div className={tagClasses}
                ref={(el) => {this.el = el}}
                onClick={this.handleFocus.bind(this)}
            >
                {showPlaceholder &&
                    <div className={style.Placeholder}>{this.props.placeholder}</div>
                }
                <ul className={style.Choices}>
                    {items}
                    <li className={searchClasses}>
                        <input className={style.SearchField}
                            ref={(el) => {this.input = el}}
                            type="text"
                            onBlur={this.handleBlur.bind(this)}
                            onChange={this.handleChange.bind(this)}
                            onKeyDown={this.handleKeyDown.bind(this)}
                        />
                    </li>
                </ul>
                {this.state.expanded &&
                    <SelectExpanded
                        ref={(el) => {if (el) {this.selectExpanded = el}}}
                        element={this.el}
                        onClose={() => this.onClose()}
                        onItemSelect={(item) => {this.onItemSelect(item)}}
                        processData={this.props.processData}
                        itemView={ItemView}
                        data={this.data}
                        disabledIds={this.disabledIds}
                        value={this.props.value}
                        url={this.props.url}
                        width={this.props.width || 210}
                        options={{
                            usesInternalSearch: this.props.usesInternalSearch || true
                        }}
                        searchParameters={searchParameters}
                        text={this.text}
                    />
                }
            </div>
        );
    }
}

class Select extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.initiallyExpanded) {
            this.state = _.defaults(this.getExpandState(), this.getResetState());
        }
        else {
            this.state = this.getResetState();
        }

        this.itemViews = {};

        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.open = this.open.bind(this);
        this.refocusInput = this.refocusInput.bind(this);
        this.handleTextInput = this.handleTextInput.bind(this);
        this.handleSearchResponse = this.handleSearchResponse.bind(this);
        this.handleItemSelect = this.handleItemSelect.bind(this);
        this.cancelSelect = this.cancelSelect.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleArrowKey = this.handleArrowKey.bind(this);
        this.selectHighlightedItem = this.selectHighlightedItem.bind(this);
        this.scrollListToSelected = this.scrollListToSelected.bind(this);
        this.getResetState = this.getResetState.bind(this);
    }

    componentDidMount() {
        // it should now be possible to get the DOM node's offset
        // reevaluate the expanded state to calculate the correct value for state.up
        this.setState((prevState) => (prevState.expanded ? this.getExpandState() : {}));
        if (this.props.preloadData) {
            this.props.onSearch("", this.handleSearchResponse);
        }
    }

    componentDidUpdate() {
        this.scrollListToSelected();
    }

    scrollListToSelected() {
        if (!_.isNumber(this.state.selectedIndex)) {
            return;
        }
        let selectedItem = this.itemViews[this.state.searchResults[this.state.selectedIndex].id];
        if (!selectedItem) {
            return;
        }
        selectedItem = $(ReactDOM.findDOMNode(selectedItem));
        let list = $(this.resultsListEl);
        let listHeight = list.outerHeight();
        let scrolledUp = list.scrollTop();
        let itemHeight = selectedItem.outerHeight();
        let itemTop = selectedItem.position().top;
        let hiddenAbove = SELECT_LIST_PADDING - itemTop;
        let hiddenBelow = (itemTop + itemHeight) - (listHeight - SELECT_LIST_PADDING);
        if (hiddenAbove > 0) {
            list.scrollTop(scrolledUp - hiddenAbove);
        }
        if (hiddenBelow > 0) {
            list.scrollTop(scrolledUp + hiddenBelow);
        }
    }

    getResetState() {
        let ret = {search: '', selectedIndex: null, expanded: false};

        if (!this.props.keepSearchResults || !this.state || !this.state.searchResults) {
            ret.searchResults = [];
        }

        return ret;
    }

    getExpandState() {
        let up = false;
        const $el = $(this.el);
        if ($el.length) {
            up = $el.offset().top > $(window).height() / 2;
        }
        return { expanded: true, up: up };
    }

    toggleExpanded(expand) {
        if (this.props.disabled) {
            return;
        }

        if (expand) {
            this.setState(this.getExpandState());
            return;
        }
        if (expand === false) {
            this.setState(this.getResetState());
            return;
        }
        if (!this.state.expanded) {
            if (this.props.preloadData) {
                this.props.onSearch("", this.handleSearchResponse);
            }
        }
        this.setState((prevState) => {
            if (prevState.expanded) {
                return this.getResetState();
            }
            else {
                return this.getExpandState();
            }
        });
    }

    open() {
        this.toggleExpanded(true);
        this.input.focus();
    }

    refocusInput(ev) {
        ev.preventDefault();
        this.input && this.input.focus();
    }

    handleClickOutside() {
        this.setState(this.getResetState());
        if (this.props.onClickOutside) {
            this.props.onClickOutside();
        }
    }

    handleTextInput(event) {
        const search = event.target.value,
            searching = (search.length >= ("minLength" in this.props ? this.props.minLength : 1));
        this.setState((prevState) => {
            // expand if not already expanded
            if (prevState.expanded) {
                return {search: search, searchResults: [], selectedIndex: null, searching: searching}
            }
            else {
                return _.defaults(
                    {search: search, searchResults: [], selectedIndex: null, searching: searching},
                    this.getExpandState()
                );
            }
        });
        if (!searching) {
            // call also with empty search term to cancel handling
            // any outstanding requests
            this.props.onSearch();
            return;
        }
        this.props.onSearch(search, this.handleSearchResponse);
    }

    handleSearchResponse(data) {
        this.setState((prevState, props) => {
            if (!this.props.preloadData && !prevState.searching) {
                return {};
            }
            this.itemViews = {};
            const disabledIds = props.disabledIds || {};
            let selectedIndex = null;
            _.find(data, (item, index) => {
                if (!disabledIds[item.id]) {
                    selectedIndex = index;
                    return true;
                }
            });
            return {
                searchResults: data,
                selectedIndex: selectedIndex,
                searching: false
            };
        });
    }

    handleItemSelect(item) {
        this.setState(this.getResetState());
        this.props.onSelect([item]);
    }

    cancelSelect() {
        this.setState(this.getResetState());
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    handleArrowKey(event) {
        event.preventDefault();
        event.persist();
        this.setState((prevState, props) => {
            if (!prevState.searchResults.length) {
                return {};
            }
            const items = prevState.searchResults;
            const disabledIds = props.disabledIds || {};

            let nextIndex = prevState.selectedIndex;
            const offByOne = event.key === 'ArrowDown' ? -1 : items.length;
            let startIndex = _.isNumber(prevState.selectedIndex) ? prevState.selectedIndex : offByOne;
            let inc = 1;
            let endIndex = items.length;
            if (event.key === 'ArrowDown') {
                startIndex++;
            }
            else {
                startIndex--;
                endIndex = -1;
                inc = -1;
            }

            _.find(_.range(startIndex, endIndex, inc), (index) => {
                if (!disabledIds[items[index].id]) {
                    nextIndex = index;
                    return true;
                }
            });

            return { selectedIndex: nextIndex };
        });
    }

    selectHighlightedItem(defaultCancel) {
        if (this.state.searchResults.length && _.isNumber(this.state.selectedIndex)) {
            this.handleItemSelect(this.state.searchResults[this.state.selectedIndex]);
        }
        else if (defaultCancel) {
            this.cancelSelect();
        }
    }

    handleKeyDown(event) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            this.handleArrowKey(event);
        }
        else if (event.key === 'Enter') {
            this.selectHighlightedItem();
        }
        else if (event.key === 'Escape') {
            event.preventDefault();
            this.cancelSelect();
        }
        else if (event.key === 'Tab') {
            this.selectHighlightedItem(true);
        }
    }

    render() {
        const ItemView = this.props.itemView;
        const disabledIds = this.props.disabledIds || {};
        const css = `
            .style-select-box {
                border-color: ${colors.fainterGrey};
            }
            .style-select-box:hover {
                border-color: ${colors.faintGrey};
            }
        `;
        const boxStyle = _.extend(
            {
                display: 'block',
                position: 'relative',
                height: 32,
                paddingLeft: 10,
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: 3,
                background: 'white',
                color: this.props.value ? '#666666' : colors.faintGrey2,
                lineHeight: '30px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textDecoration: 'none'
            },
            this.state.expanded ?
                {
                    borderColor: colors.softBlue,
                    color: colors.softBlue
                } : {},
            this.props.disabled ?
                {
                    opacity: "0.5"
                } : {},
            this.props.boxStyle
        );
        const tooltipPositionStyle = this.state.up ? { bottom: '100%' } : { top: '100%' };
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Search';
        let searchMsg;
        if (this.state.search.length < ("minLength" in this.props ? this.props.minLength : 1)) {
            searchMsg = 'Please enter 1 more character';
        }
        else if (this.state.searching) {
            searchMsg = 'Searching...';
        }
        else if (this.state.searchResults.length === 0) {
            searchMsg = 'No matches found';
        }

        let selectedTitle;
        if (this.props.value) {
            selectedTitle = (this.state.searchResults.find(
                item => {
                    return item.id === this.props.value;
                }
            ) || {})[this.props.selectedKey || 'title'] || this.props.title;
        }

        return (
            <div
                id={this.props.id}
                name={this.props.name}
                className={this.props.className}
                ref={(el) => {this.el = el;}}
                style={this.props.containerStyle || { position: 'relative' }}>
                <style>{css}</style>
                {this.props.inlineInput &&
                    <input
                        type="text"
                        ref={(input) => { this.input = input; }}
                        value={this.state.search}
                        onClick={() => {this.toggleExpanded(true);}}
                        onChange={this.handleTextInput}
                        onKeyDown={this.handleKeyDown}
                        style={{
                            width: '100%',
                            height: 32,
                            border: '0px'
                        }} />
                ||
                    <a
                        className="style-select-box"
                        style={boxStyle}
                        onClick={() => {this.toggleExpanded();}}>
                        <span
                            style={{
                                display: 'block',
                                marginRight: 40,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'hidden',
                                color: !selectedTitle && "#c1c4cd"
                            }}>{selectedTitle || placeholder}</span>
                        <span
                            style={{
                                display: 'inline-block',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                width: 30,
                                height: '100%',
                                color: colors.faintGrey2,
                                textAlign: 'center'
                            }}>
                            <b
                                style={{
                                    display: 'block',
                                    height: '100%',
                                    marginTop: 1,
                                    fontFamily: 'sans-serif',
                                    fontSize: fontSizes.plusTiny,
                                    userSelect: 'none'
                                }}>{'\u25BC'}</b>
                        </span>
                    </a>
                }
                {this.state.expanded &&
                    <div
                        style={_.extend({
                            position: 'absolute',
                            width: '100%',
                            borderRadius: 3,
                            boxShadow: commonStyles.popoverShadow,
                            background: 'white',
                            zIndex: 1,
                            marginBottom: '3px'
                        }, tooltipPositionStyle, this.props.dropDownStyle)}
                        onClick={this.refocusInput}>
                        {!this.props.inlineInput && !this.props.hideSearch &&
                            <div
                                style={{
                                    padding: 8,
                                    borderBottom: '1px solid ' + colors.faintestGrey
                                }}>
                                <input
                                    type="text"
                                    autoFocus
                                    ref={(input) => { this.input = input; }}
                                    value={this.state.search}
                                    onChange={this.handleTextInput}
                                    onKeyDown={this.handleKeyDown}
                                    style={{
                                        width: '100%',
                                        height: 32
                                    }} />
                            </div>
                        }
                        {this.state.searchResults.length > 0 &&
                            <ul
                                ref={(el) => this.resultsListEl = el}
                                style={{
                                    position: 'relative',
                                    maxHeight: 214,
                                    padding: SELECT_LIST_PADDING,
                                    overflow: 'auto',
                                    listStyleType: 'none'
                                }}>
                                {
                                    _.map(this.state.searchResults, (item, index) => (
                                        <ItemView
                                            key={item.id}
                                            ref={(view) => {this.itemViews[item.id] = view;}}
                                            item={item}
                                            selectedKey={this.props.selectedKey}
                                            selected={index === this.state.selectedIndex}
                                            disabled={disabledIds[item.id]}
                                            onClick={() => {this.handleItemSelect(item)}} />
                                    ))
                                }
                            </ul>
                        }
                        {!!searchMsg &&
                            <div
                                style={{
                                    padding: 8,
                                    lineHeight: '20px',
                                    color: colors.faintGrey2
                                }}>
                                {searchMsg}
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

const WrappedSelect = onClickOutside(Select);

class IndividualSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { content, item, disabled, onClick, onMouseEnter } = this.props;

        const classes = classnames({
            [style.IndividualSelectItem]: true,
            [style.selected]: this.state.selected,
            [style.disabled]: disabled
        });

        let htmlContent = htmlSanitizer.sanitize(content);

        if (item.item.organization_name) {
            htmlContent = htmlContent +
            "<br><span class=" + style.orgName + ">" + item.item.organization_name + "</span>"
        }

        return (
            <li className={classes} onClick={!disabled && onClick} onMouseEnter={!disabled && onMouseEnter}
                dangerouslySetInnerHTML={{__html: htmlContent}}
            />
        );
    }
}

class ExtendedIndividualSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const content = htmlSanitizer.sanitize(this.props.content);
        const item = this.props.item.item;
        const email = item.communication.find(c => c.medium === 'email' && c.value);
        const tags = item.tags || [];
        let xtraInfo = [];

        const phone = item.communication.find(c => c.medium === 'phone' && c.value);
        if (phone) {
            xtraInfo.push(phone.value);
        }

        const additionalFields = AppConfig.getValue('individuals.new_select_widget.additional_fields', []);

        for (const af of additionalFields) {
            const value = item[af];

            if (value) {
                xtraInfo.push(value);
            }
        }

        return (
            <li
                className={`
                    ${style.ExtendedIndividualSelectItem}
                    ${this.state.selected ? style.selected : ''}
                    ${this.props.disabled ? style.disabled : ''}
                `}
                onClick={!this.props.disabled && this.props.onClick}
                onMouseEnter={!this.props.disabled && this.props.onMouseEnter}
            >
                <div className={style.xName}>
                    <div dangerouslySetInnerHTML={{__html: content}}/>
                    {email &&
                        <div style={{marginLeft: '5px'}}>({email.value})</div>
                    }
                </div>

                {tags.length > 0 &&
                    <div
                        className={style.TagsContainer}
                        title={tags.map(t => t.name).join(', ')}
                    >
                        {tags.map((tag, tidx) => {
                            return (
                                <div
                                    key={`tag_${tidx}`}
                                    className={style.Tag}
                                >
                                    {tag.name}
                                </div>
                            );
                        })}
                    </div>
                }

                {xtraInfo.length > 0 &&
                    <div className={style.xExtraInfo}>
                        {xtraInfo.join(' | ')}
                    </div>
                }
            </li>
        );
    }
}

class DealSelectItem extends React.Component {
    render() {
        const { item, selected, disabled, onClick } = this.props;

        const classes = classnames({
            [style.DealSelectItem]: true,
            [style.selected]: selected,
            [style.disabled]: disabled
        });

        return (
            <li className={classes} onClick={!disabled && onClick}>
                {item.title}
            </li>
        );
    }
}

class FileSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { item, content, disabled, onClick, onMouseEnter } = this.props;

        const classes = classnames({
            [style.FileSelectItem]: true,
            [style.selected]: this.state.selected,
            [style.disabled]: disabled
        });

        const htmlContent = htmlSanitizer.sanitize(content) +
            "<div class=" + style.filePath + ">" +
            "    <i class=" + style.pathIcon + ">\u21B3</i>" +
            "    <span>" + item.path.join('/') +"</span>" +
            "</div>";

        return (
            <li className={classes} onClick={!disabled && onClick} onMouseEnter={!disabled && onMouseEnter}
                dangerouslySetInnerHTML={{__html: htmlContent}}
            />
        );
    }
}

class GroupSelectItem extends React.Component {
    render() {
        const { item, selected, disabled, onClick } = this.props;

        const classes = classnames({
            [style.GroupSelectItem]: true,
            [style.selected]: selected,
            [style.disabled]: disabled
        });

        return (
            <li className={classes} onClick={!disabled && onClick}>
                {item.title || item.name}
            </li>
        );
    };
}

class NewPlainSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { content, disabled, onClick, onMouseEnter, item, addTagsHover } = this.props;
        let title = item.title || item.name || item[this.props.selectedKey] || '';

        if (addTagsHover && item.type === 'individuals' && item.tags) {
            title = item.tags.join(', ');
        }

        const classes = classnames({
            [style.PlainSelectItem]: true,
            [style.selected]: this.state.selected,
            [style.disabled]: disabled
        });

        return (
            <li className={classes} onClick={!disabled && onClick} onMouseEnter={!disabled && onMouseEnter}
                dangerouslySetInnerHTML={{__html: htmlSanitizer.sanitize(content)}}
                title={htmlSanitizer.sanitize(title)}
            />
        );
    }
}

class PlainSelectItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }

    render() {
        const { item, disabled, onClick, onMouseEnter } = this.props;

        const classes = classnames({
            [style.PlainSelectItem]: true,
            [style.selected]: this.state.selected,
            [style.disabled]: disabled
        });

        return (
            <li className={classes} onClick={!disabled && onClick} onMouseEnter={!disabled && onMouseEnter}>
                {item.title || item.name || item[this.props.selectedKey] || item.value}
            </li>
        );
    }
}

class SeparatorItem extends React.Component {
    render() {
        return (
            <li className={style.SeparatorItem}/>
        );
    }
}

class SectionTitleItem extends React.Component {
    render() {
        return (
            <li className={style.SectionTitleItem}>{this.props.title}</li>
        );
    }
}

export {
    WrappedSelect as Select,
    NewSelect,
    TagSelect,
    IndividualSelectItem,
    ExtendedIndividualSelectItem,
    DealSelectItem,
    FileSelectItem,
    GroupSelectItem,
    PlainSelectItem,
    NewPlainSelectItem
};

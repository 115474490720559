import $ from 'jquery'
import _ from 'underscore'

import WebStatsCollection from 'js/collections/webstats'
import IPLookupCollection from 'js/collections/iplookup'


var IPLookupStatsProvider = function() {
    this.fetchData = function(fetchOptions, page, rows, sortOptions, callback) {
        var collection = new IPLookupCollection();

        collection.fetch({
            page: page,
            rows: rows,
            sortOn: sortOptions,
            filterBy: [{
                attribute: 'last_seen',
                value: this.filters.timespan[0].period
            }],
            data: fetchOptions,
            success: function() {
                if (callback) {
                    callback(collection);
                }
            }
        });
    };

    this.resetFilters = function() {
        this.filters = {
            timespan: [{
                type: 'lastPeriod',
                period: 'month',
                removableDisabled: true

            }],
            segments: [{
                type: 'visits',
                removableDisabled: true
            }],
            content: {
                hideSection: true
            },
            location: {
                hideSection: true
            },
            resetFiltersDisabled: true
        };
    };

    this.setFilter = function(name, value) {};

    this.removeFilter = function(name) {};

    this.getLocationData = function() {
        return null;
    };

    this.getFilters = function() {
        return this.filters;
    };

    // ...
    this.resetFilters();
};

// ...
var WebStatsProvider = function() {
    this.fetchData = function(filters, context, callback) {
        var statsCollection = new WebStatsCollection(),
            filterBy = [];

        if (filters.timespan && filters.timespan.length) {
            filterBy.push({
                attribute: 'timefilter',
                value: filters.timespan[0].period
            });

            if (filters.timespan[0].ts) {
                var offset = (new Date()).getTimezoneOffset() * 60000;
                filterBy.push({
                    attribute: 'timets',
                    value: filters.timespan[0].ts + offset
                });
            }
        }

        if (filters.segments && filters.segments.length) {
            filterBy.push({
                attribute: 'segmentation',
                value: {
                    'page-views': 'hits',
                    'visits': 'visitors',
                    'returning': 'returning',
                    'registered': 'registered'
                }[filters.segments[0].type]
            });
        }

        if (filters.content) {
            _.each(filters.content, function(d) {
                filterBy.push({
                    attribute: (d.type === 'page') ? 'pagefilter' : 'source',
                    value: d.url
                });
            });
        }

        if (filters.location) {
            _.each(filters.location, function(d) {
                filterBy.push({
                    attribute: 'location',
                    value: d.code
                });
            });
        }

        if (filters.domain) {
            filterBy.push({
                attribute: 'domain',
                value: filters.domain
            });
        }

        //filterBy = [];
        var that = this;

        statsCollection.fetch({
            alert: true,
            filterBy: filterBy,
            success: function() {
                var data = statsCollection.models[0];
                that.data = data;

                if (callback) {
                    callback(context, data);
                }
            }
        });
    };

    this.setFilter = function(name, value) {
        this.filters = _.clone(this.filters);
        this.filters[name] = value;
    };

    this.removeFilter = function(name, filter) {
        if (this.filters[name]) {
            if (_.isArray(this.filters[name])) {
                this.filters[name] = _.without(this.filters[name], filter);
            } else {
                delete this.filters[name];
            }
        }
    };

    this.resetFilters = function() {
        this.filters = {
            timespan: [{type: 'lastPeriod', period: 'month'}],
            segments: [{type: 'visits'}],
            content: []
        };
    };

    this.getFilters = function() {
        return this.filters;
    };

    this.getLocationData = function() {
        var locationData, locations, segment, segmentType;

        if (!this.data) {
            return null;
        }

        locations = this.data.get('locations');
        if (!locations) {
            return null;
        }

        segment = this.filters.segments[0];
        segmentType = segment ? segment.type : 'visits';

        locationData = {
            locations: locations,
            segment: segmentType
        };
        return locationData;
    };

    // 'constructor'
    this.resetFilters();
};

export default {
    WebStatsProvider: WebStatsProvider,
    IPLookupStatsProvider: IPLookupStatsProvider
};

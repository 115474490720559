import React from 'react';
import TextManager from 'app/text-manager';

import {
    TextField
} from 'js/react_views/detail_fields/detail_fields';

import style from './MoreInfo.css';

class MoreInfo extends React.Component {
    render() {
        const { comments } = this.props;
        return (
            <section className={style.MoreInfo}>
                <h5 className={style.header}>
                    Additional Information
                </h5>
                <TextField
                    label={TextManager.getText('ID_MORE_INFO')}
                    isParagraph="true"
                    value={comments || 'No information to display'} />
            </section>
        );
    }
}

export default MoreInfo;

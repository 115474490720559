import $ from 'jquery'
import _ from 'underscore'
import React from 'react'

import app from 'js/app'
import vent from 'js/vent'
import guid from 'js/utils/guid';
import AppConfig from 'app/app-config'
import dateFormat from 'js/utils/date-format'
import TaskFilterModel from 'js/models/task_filter'
import GroupElementsCollection from 'js/collections/group_elements'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import {NewSelect} from 'js/react_views/widgets/select';
import PopoverMenu from 'app_v2/components/popover_menu/menu';

import style from './calendar.css'

const DAY_OF_THE_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const VITEM_HEIGHT = 22;
const KANBAN_TASK_HEIGHT = 42;
const ONE_DAY_MS = 1000 * 60 * 60 * 24;

const adjustDateToFirstDayOfTheWeek = function(date) {
    let newDate = new Date(date.getTime());
    let day = newDate.getDay();

    if (day === 0) { // sunday
        day = 7;
    }

    const startOnSunday = AppConfig.getClientPreferenceValue('startWeekOnSunday');

    if (startOnSunday) {
        if (day !== 7) { // it's not sunday
            newDate.setDate(newDate.getDate() - day);
        }
    } else {
        if (day !== 1) { // it's not monday
            newDate.setDate(newDate.getDate() - (day - 1));
        }
    }

    return newDate;
};

const isSameDate = (date1, date2) => {
    if (!date1 || !date2) {
        return false;
    }

    return (date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear());
};

const isSameDateWithHours = (date1, date2) => {
    if (!date1 || !date2) {
        return false;
    }

    return (date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear() &&
            date1.getHours() === date2.getHours());
};

const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

const daysDiff = (date1, date2) => {
    return Math.floor((date2 - date1) / 86400000);
};

const minutesDiff = (date1, date2) => {
    return Math.floor((date2 - date1) / 60000);
}

const APPOINTMENT_POPOVER_WIDTH = 300;
const APPOINTMENT_POPOVER_HEIGHT = 130;

class AppointmentPopover extends React.Component {
    render() {
        let x = (this.props.position.x - this.props.parentBbox.x) - (APPOINTMENT_POPOVER_WIDTH / 2);
        let y = (this.props.position.y - this.props.parentBbox.y) + 5;

        if ((y + APPOINTMENT_POPOVER_HEIGHT) > this.props.parentBbox.height) {
            y -= APPOINTMENT_POPOVER_HEIGHT + 5;
        }

        if ((x + APPOINTMENT_POPOVER_WIDTH) > this.props.parentBbox.width) {
            x = this.props.parentBbox.width - APPOINTMENT_POPOVER_WIDTH;
        }

        if (x < 0) {
            x = 0;
        }

        const data = this.props.appointment.model.toJSON();
        const primaryAttendee = data.guests.find(g => g.role === 'primary')?.guest_name || '-';
        const sdate = dateFormat.parseDate(data.start_date);
        const edate = dateFormat.parseDate(data.end_date);
        const datetime = `${dateFormat.shortFormatDay(sdate.getDay())} ${sdate.getDate()}, ${dateFormat.shortFormatMonth(sdate.getMonth())} | ${dateFormat.shortFormatTime(sdate)} - ${dateFormat.shortFormatTime(edate)}`;
        const funnelName = data.funnels ? data.funnels[0].name : null

        return (
            <div
                className={style.appointmentPopover}
                style={{
                    left: `${x}px`,
                    top: `${y}px`,
                }}
            >
                <div className={style.apTitle}>
                    {primaryAttendee}
                </div>

                <div style={{width: '100%', height: '1px', background: '#ECEDED'}}/>

                <div className={style.apContent}>
                    <div className={style.cRow}>
                        <div
                            className={`${style.rIcon} icon-calendar`}
                            style={{marginTop: '4px'}}
                        />
                        <div
                            style={{width: 'calc(100% - 25px)'}}
                        >
                            <div className={style.rText}>
                                {data.title || data.description}
                            </div>
                            <div className={style.rSubText}>{data.appointment_type}</div>
                        </div>
                    </div>

                    <div className={style.cRow}>
                        <div
                            className={`${style.rIcon} icon-clock`}
                            style={{marginTop: '2px'}}
                        />
                        <div className={style.rText}>{datetime}</div>
                    </div>
                    {funnelName && <div className={style.cRow}>
                        <div
                            className={`${style.rIcon} icon-location`}
                            style={{marginTop: '2px'}}
                        />
                        <div className={style.rText}>{funnelName}</div>
                    </div>}
                </div>
            </div>
        );
    }
}

class ScheduleAppointment extends React.Component {
    render() {
        const data = this.props.data;
        const spanTime = data.numDays > 1 ? 'All Day' : `${dateFormat.shortFormatTime(data.startDate)} - ${dateFormat.shortFormatTime(data.endDate)}`;
        let value = data.title || data.description;

        if (data.numDays > 1) {
            value += ` (Day ${data.day} / ${data.numDays})`;
        }

        return (
            <div
                className={style.scheduleAppointment}
                onClick={(ev) => { ev.stopPropagation(); this.props.onEditAppointment() }}
                title={value}
            >
                <div className={style.sSpan}>{spanTime}</div>
                <div className={style.sValue}>{value}</div>
            </div>
        );
    }
}


class TopAppointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: {
                top: 0,
                left: 0,
                width: 0,
                finishing: false
            }
        };
    }

    componentDidMount() {
        this.adjustPositionAndSize();
    }

    adjustPositionAndSize() {
        const parent = $(this.props.parent);
        const data = this.props.data;
        let finishing = false;
        let newStyle = {};

        switch (this.props.visualization) {
            case 'day': {
                const refBbox = parent[0].getBoundingClientRect();

                newStyle.top = `${data.order * VITEM_HEIGHT}px`;
                newStyle.left = `${data.day * refBbox.width}px`;
                newStyle.width = `${refBbox.width - 10}px`;

                finishing = data.finishing;
            }
            break;

            case 'week': {
                const refBbox = parent.find(`.${style.cDay}`)[0].getBoundingClientRect();

                newStyle.top = `${data.order * VITEM_HEIGHT}px`;
                newStyle.left = `${data.day * refBbox.width}px`;
                newStyle.width = `${(Math.min(data.numDays, 7 - data.day) * refBbox.width) - 10}px`;

                finishing = (data.day + data.numDays) < 7;
            }
            break;
        }

        this.setState({
            style: newStyle,
            finishing: finishing
        });
    }

    render() {
        const data = this.props.data;
        const text = data.item.title || data.item.description;

        return (
            <div
                className={`
                    ${style.topAppointment}
                    ${style.calendarItem}
                    ${data.starting ? style.tStarting : ''}
                    ${this.state.finishing ? style.tFinishing : ''}
                `}
                style={this.state.style}
                onClick={(ev) => { ev.stopPropagation(); this.props.onEditAppointment() }}
                onMouseEnter={(ev) => this.props.onShowAppointmentPopover(data.item, {x: ev.clientX, y: ev.clientY})}
                onMouseMove={(ev) => this.props.onShowAppointmentPopover(data.item, {x: ev.clientX, y: ev.clientY})}
                onMouseLeave={() => this.props.onShowAppointmentPopover(null)}
            >

                {!data.starting &&
                    <div className={style.tLeftArrow}/>
                }

                <div className={style.aDescription}>
                    {text}
                </div>

                {!this.state.finishing &&
                    <div className={style.tRightArrow}/>
                }
            </div>
        );
    }
}

class AppointmentEx extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible || false,
            style: {
                top: 0,
                left: 0,
                width: 0
            }
        };

        this.smallVisualization = false;
    }

    componentDidMount() {
        this.adjustPositionAndSize();
    }

    setVisible(visible) {
        this.setState({
            visible: visible
        });
    }

    adjustPositionAndSize() {
        const parent = $(this.props.parent);
        const data = this.props.data;
        let newStyle = {};

        switch (this.props.visualization) {
            case 'day': {
                const parentBbox = parent[0].getBoundingClientRect();
                const refBbox = parent.find('#hour_0').find(`.${style.rContent}`)[0].getBoundingClientRect(); // this is the first cell and the positions are calculated based on this
                const startEl = parent.find(`#hour_${data.hour}`).find(`.${style.rContent}`);
                const startBbox = startEl.find(`.${style.itemsContainer}`)[0].getBoundingClientRect();

                const appointmentWidth = ((refBbox.width - 10) / data.numSharingWith);
                const leftOffset = (data.order * appointmentWidth);
                const left = (startBbox.left - parentBbox.left) + leftOffset;
                const width = refBbox.width - leftOffset - 10;
                const height = (data.numMinutes / 60) * refBbox.height;

                this.smallVisualization = height <= 24;

                newStyle.top = `${(startBbox.top - refBbox.top) + (data.minutes / 60) * refBbox.height}px`;
                newStyle.left = `${left}px`;
                newStyle.width = `${width}px`;
                newStyle.height = `${Math.max(height, 11)}px`;
                newStyle.zIndex = data.order;
            }
            break;

            case 'week': {
                const parentBbox = parent.find(`.${style.wRows}`)[0].getBoundingClientRect();
                const refBbox = parent.find('#hour_0_0')[0].getBoundingClientRect(); // this is the first cell and the positions are calculated based on this
                const startEl = parent.find(`#hour_${data.day}_${data.hour}`);
                const startBbox = startEl.find(`.${style.itemsContainer}`)[0].getBoundingClientRect();

                const appointmentWidth = ((refBbox.width - 10) / data.numSharingWith);
                const leftOffset = (data.order * appointmentWidth);
                const left = (startBbox.left - parentBbox.left) + leftOffset;
                const width = refBbox.width - leftOffset - 10;
                const height = (data.numMinutes / 60) * refBbox.height;

                this.smallVisualization = height <= 24;

                newStyle.top = `${(startBbox.top - refBbox.top) + (data.minutes / 60) * refBbox.height}px`;
                newStyle.left = `${left}px`;
                newStyle.width = `${width}px`;
                newStyle.height = `${Math.max(height, 11)}px`;
                newStyle.zIndex = data.order;
            }
            break;

            case 'month': {
                const refBbox = parent[0].getBoundingClientRect();
                const startEl = parent.find(`#day_${data.week}_${data.day}`);
                const startBbox = startEl.find(`.${style.itemsContainer}`)[0].getBoundingClientRect();

                newStyle.top = `${(startBbox.top - refBbox.top) + (data.order * VITEM_HEIGHT)}px`;
                newStyle.left = `${startBbox.left - refBbox.left}px`;
                newStyle.width = `${(startBbox.width * data.numDays - 5)}px`;
            }
            break;
        }

        this.setState({style: newStyle});
    }

    getTimeFormatted() {
        const item = this.props.data.item;
        return `${dateFormat.shortFormatTime(item.startDate)} - ${dateFormat.shortFormatTime(item.endDate)}`;
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        const item = this.props.data.item;
        const timeFormatted = this.getTimeFormatted();
        const borderer = (this.props.visualization === 'day' || this.props.visualization === 'week') && this.props.data.order > 0;
        const text = item.title || item.description;
        let statusStyle = '';

        switch (item.model.get('status')) {
            case 'pending':
                statusStyle = style.aPending;
                break;

            case 'cancelled':
                statusStyle = style.aCancelled;
                break;
        }

        return (
            <div
                className={`
                    ${style.appointmentEx}
                    ${this.smallVisualization ? style.aSmall : ''}
                    ${borderer ? style.aBorderer : ''}
                    ${statusStyle}
                `}
                style={this.state.style}
                onClick={(ev) => { ev.stopPropagation(); this.props.onEditAppointment() }}
                onMouseEnter={(ev) => this.props.onShowAppointmentPopover(item, {x: ev.clientX, y: ev.clientY})}
                onMouseMove={(ev) => this.props.onShowAppointmentPopover(item, {x: ev.clientX, y: ev.clientY})}
                onMouseLeave={() => this.props.onShowAppointmentPopover(null)}
            >
                {text &&
                    <div className={style.aDescription}>
                        {text}
                    </div>
                }

                {(this.props.visualization === 'day' || this.props.visualization === 'week') &&
                    <div className={`
                        ${style.aTime}
                        ${text ? style.tWithMarginTop : ''}
                    `}>
                        {timeFormatted}
                    </div>
                }
            </div>
        );
    }
}

class KanbanTask extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            completed: props.data.completed
        };
    }

    setCompleted(completed) {
        this.setState({
            completed: completed
        });
    }

    render() {
        const item = this.props.data;
        const overdue = item.dueDate < new Date();

        return (
            <div
                className={`
                    ${style.kanbanTask}
                    ${this.state.completed ? style.kCompleted : ''}
                    ${overdue && !this.state.completed ? style.kOverdue : ''}
                `}
                title={`${item.subject} ${item.text}`}
                onClick={(ev) => { ev.stopPropagation(); this.props.onEditTask()} }
            >
                <div
                    className={`
                        ${style.kCheckbox}
                        ${this.state.completed ? style.kCompleted : ''}
                    `}
                    onClick={(ev) => { ev.stopPropagation(); this.props.onToggleTaskCompleted() }}
                >
                    <div className={`icon-checkmark3 ${style.kIcon}`}/>
                </div>

                <div className={style.kDetails}>
                    <div className={style.kDescription}>
                        {item.subject &&
                            <span className={style.kSubject}>{item.subject}</span>
                        }

                        {item.text &&
                            <span className={style.kText}>{item.text}</span>
                        }
                    </div>

                    <div className={style.kTime}>{dateFormat.shortFormatTime(item.dueDate)}</div>
                </div>
            </div>
        );
    }
}

class TaskEx extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: props.visible || false,
            completed: props.data.item.completed,
            style: {
                top: 0,
                left: 0,
                width: 0
            }
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.adjustPositionAndSize();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate() {
        if (!this.mounted) {
            return;
        }

        if (this.state.completed !== this.props.data.item.completed) {
            this.setState({
                completed: this.props.data.item.completed
            });
        }
    }

    setCompleted(completed) {
        this.setState({
            completed: completed
        });
    }

    setVisible(visible) {
        this.setState({
            visible: visible
        });
    }

    adjustPositionAndSize() {
        const parent = $(this.props.parent);
        const data = this.props.data;
        let newStyle = {};

        switch (this.props.visualization) {
            case 'day': {
                const refBbox = parent[0].getBoundingClientRect();

                newStyle.top = `${data.order * VITEM_HEIGHT}px`;
                newStyle.left = `${data.day * refBbox.width + 5}px`;
                newStyle.width = `${refBbox.width - 10}px`;
            }
            break;

            case 'week': {
                const refBbox = parent.find(`.${style.cDay}`)[0].getBoundingClientRect();

                newStyle.top = `${data.order * VITEM_HEIGHT}px`;
                newStyle.left = `${data.day * refBbox.width + 5}px`;
                newStyle.width = `${refBbox.width - 10}px`;
            }
            break;

            case 'month': {
                const parentBbox = parent[0].getBoundingClientRect();
                const startDayEl = parent.find(`#day_${data.week}_${data.day}`);
                const startBbox = startDayEl.find(`.${style.itemsContainer}`)[0].getBoundingClientRect();

                newStyle.top = (startBbox.top - parentBbox.top) + (data.order * VITEM_HEIGHT);
                newStyle.left = startBbox.left - parentBbox.left;
                newStyle.width = (startBbox.width * data.numDays - 10);
            }
            break;
        }

        this.setState({style: newStyle});
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        const item = this.props.data.item;
        const overdue = item.dueDate < new Date();
        const showSubject = this.props.showSubject && item.subject;
        let text = item.text;

        if (!showSubject && !text && item.subject) {
            text = item.subject;
        }

        return (
            <div
                className={`
                    ${style.taskEx}
                    ${this.state.completed ? style.tCompleted : ''}
                    ${overdue && !this.state.completed ? style.tOverdue : ''}
                    ${this.props.visualization === 'schedule' ? style.tSchedule : ''}
                `}
                style={this.state.style}
                onClick={(ev) => { ev.stopPropagation(); this.props.onEditTask()} }
            >
                <div className={style.tHeader}>
                    <div
                        className={`
                            ${style.tCheckbox}
                            ${this.state.completed ? style.tCompleted : ''}
                        `}
                        onClick={(ev) => { ev.stopPropagation(); this.props.onToggleTaskCompleted() }}
                    >
                        <div className={`icon-checkmark3 ${style.tIcon}`}/>
                    </div>
                    <div
                        className={style.tText}
                        title={item.text}
                    >
                        {showSubject && <div className={style.tSubject}>{`${item.subject}${item.text ? ':' : ''}`}</div>}
                        <div>{text}</div>
                    </div>
                </div>
            </div>
        );
    }
}

class MoreItems extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numItems: 0
        };
    }

    getText() {
        let entity = 'element';

        if (this.props.entityType) {
            entity = this.props.entityType === 'appointments' ? 'appointment' : 'task';
        }

        return `${entity}${this.state.numItems !== 1 ? 's' : ''}`;
    }

    setNumItems(num) {
        this.setState({
            numItems: num
        });
    }

    getNumItems() {
        return this.state.numItems;
    }

    render() {
        if (this.state.numItems === 0) {
            return null;
        }

        return (
            <div
                className={style.moreItems}
                onClick={(ev) => { ev.stopPropagation(); this.props.onShowMoreItems() }}
            >
                +{this.state.numItems} more {this.getText()}
            </div>
        );
    }
}

class Schedule extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = new Date(this.props.date);

        this.state = {
            days: []
        };
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    getDateRange() {
        const start = new Date(this.currentDate.getTime());
        const end = new Date(start.getTime());

        end.setFullYear(end.getFullYear() + 1);

        return {
            start: start,
            end: end
        };
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    buildCalendar(allItems) {
        let currentDay = {
            date: new Date(this.currentDate.getTime()),
            items: []
        };
        let days = [];

        let items = [];

        // expand multidays appointments
        for (const i of allItems) {
            let item = _.clone(i);

            if (item.type === 'appointments') {
                if (!isSameDate(item.startDate, item.endDate)) {
                    let d = new Date(item.startDateNorm);
                    let day = 1;
                    const numDays = daysDiff(item.startDateNorm, item.endDateNorm) + 1;

                    while (d <= item.endDateNorm) {
                        let ic = _.clone(item);

                        ic.startDate = new Date(d);
                        ic.day = day;
                        ic.numDays = numDays;

                        items.push(ic);
                        d.setDate(d.getDate() + 1);
                        ++day;
                    }
                } else {
                    item.numDays = 1;
                    items.push(item);
                }
            } else {
                item.numDays = 1;
                items.push(item);
            }
        }

        const orderedItems = _.sortBy(items, item => item.dueDate || item.startDate);

        for (const item of orderedItems) {
            const itemDate = item.dueDate || item.startDate;

            if (isSameDate(itemDate, currentDay.date)) {
                currentDay.items.push(item);
            } else if (itemDate >= this.currentDate) {
                // first we show the appointments and after the tasks
                currentDay.items = [...currentDay.items.filter(i => i.type === 'appointments'), ...currentDay.items.filter(i => i.type === 'tasks')];

                days.push(currentDay);
                currentDay = {
                    date: new Date(itemDate),
                    items: [item]
                };
            }
        }

        if (currentDay) {
            // first we show the appointments and after the tasks
            currentDay.items = [...currentDay.items.filter(i => i.type === 'appointments'), ...currentDay.items.filter(i => i.type === 'tasks')];

            days.push(currentDay);
        }

        this.setState({
            days: days
        });
    }

    handleToggleTaskCompleted(task) {
        task.completed = !task.completed;

        this.props.onToggleTaskCompleted(task);
        this.itemsComponents[task.id].setCompleted(task.completed);
    }

    render() {
        const today = new Date();

        this.itemsComponents = {};

        return (
            <div
                key={`schedule_${dateFormat.ISODate(this.currentDate)}`}
                className={style.schedule}
            >
                {this.state.days.map((day, didx) => {
                    const today = isToday(day.date);

                    return (
                        <div
                            key={`day_${didx}`}
                            className={style.sRow}
                        >
                            <div
                                className={`
                                    ${style.sDate}
                                    ${today ? style.sToday : ''}
                                `}
                            >
                                <div
                                    className={style.sNumber}
                                    onClick={(ev) => {ev.stopPropagation(); this.props.onDateSpanChange(day.date, 'week')}}
                                >
                                    {day.date.getDate()}
                                </div>
                                <div className={style.sDateDesc}>{`${dateFormat.shortFormatMonth(day.date.getMonth())}, ${dateFormat.shortFormatDay(day.date.getDay())}`}</div>
                            </div>
                            <div
                                className={style.sTasks}
                                onClick={(ev) => {
                                    let td = new Date(day.date.getTime());
                                    td.setHours(11);
                                    this.props.onCreateItem(td, ev);
                                }}
                            >
                                {day.items.map(item => {
                                    if (item.type === 'appointments') {
                                        return (
                                            <ScheduleAppointment
                                                ref={(el) => this.itemsComponents[item.id] = el}
                                                key={item.id}
                                                data={item}
                                                onEditAppointment={() => this.props.onEditAppointment(item.model)}
                                            />
                                        );
                                    } else {
                                        return (
                                            <TaskEx
                                                ref={(el) => {if (el) { this.itemsComponents[item.id] = el }}}
                                                key={item.id}
                                                data={{item: item}}
                                                visible={true}
                                                visualization='schedule'
                                                onEditTask={() => { this.props.onEditTask(item.model)} }
                                                onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)({id: item.id, item: item, completed: item.completed})}
                                            />
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class KanbanDay extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = new Date(this.props.date);

        this.state = {
            items: [],
            columnsHeight: 0
        };
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    getDateRange() {
        const start = new Date(this.currentDate.getTime());
        const end = new Date(start.getTime());

        end.setDate(end.getDate() + 1);

        return {
            start: start,
            end: end
        };
    }

    onWindowResize() {
        this.setState({
            columnsHeight: Math.max(this.state.items.length * (KANBAN_TASK_HEIGHT + 5) + 5, $(this.columnsComponent)[0].getBoundingClientRect().height)
        });
    }

    handleToggleTaskCompleted(vitem) {
        vitem.item.completed = !vitem.item.completed;

        this.props.onToggleTaskCompleted(vitem.item);
        this.itemsComponents[vitem.id].setCompleted(vitem.item.completed);
    }

    buildCalendar(allItems) {
        let date = new Date(this.currentDate.getTime());

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        let items = _.sortBy(allItems.filter(i => i.dueDate && isSameDate(i.dueDate, date)), i => i.dueDate);

        this.setState({
            items: items,
            columnsHeight: Math.max(items.length * (KANBAN_TASK_HEIGHT + 5) + 5, $(this.columnsComponent)[0].getBoundingClientRect().height)
        });
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    render() {
        const gmtOffset = this.currentDate.getTimezoneOffset();
        const gmt = gmtOffset / 60;
        const agmt = Math.abs(gmt);
        const gmtText = `GMT${gmt < 0 ? '+' : '-'}${agmt < 10 ? '0' : ''}${agmt}`;
        const now = new Date();
        const today = isToday(this.currentDate);
        const blurred = !today && this.currentDate < now;

        this.itemsComponents = {};

        return (
            <div
                ref={(el) => this.component = el}
                key={`week_${dateFormat.ISODate(this.currentDate)}`}
                className={style.day}
            >
                <div className={style.dHeader}>
                    <div className={style.hTime}>{gmtText}</div>
                    <div className={style.hDivider}/>
                    <div className={style.hContent}>
                        <div
                            className={`
                                ${style.cTitle}
                                ${today ? style.cToday : ''}
                                ${blurred ? style.cBlurred : ''}
                            `}
                        >
                            <div className={style.tDay}>{dateFormat.shortFormatDay(this.currentDate.getDay())}</div>
                            <div className={style.tNumber}>{this.currentDate.getDate()}</div>
                        </div>

                        <div className={style.hKanbanContent}/>
                    </div>
                </div>

                <div
                    ref={(el) => {if (el) {this.columnsComponent = el}}}
                    className={style.dColumns}
                >
                    <div
                        className={style.dColumn}
                        style={{
                            height: `${this.state.columnsHeight}px`
                        }}
                        onClick={(ev) => {
                            let td = new Date(this.currentDate.getTime());
                            td.setHours(11);
                            this.props.onCreateItem(td, ev);
                        }}
                    >
                        {this.state.items.map(item => {
                            return (
                                <KanbanTask
                                    ref={(el) => {if (el) { this.itemsComponents[item.id] = el }}}
                                    key={item.id}
                                    data={item}
                                    onEditTask={() => { this.props.onEditTask(item.model)} }
                                    onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)({id: item.id, item: item, completed: item.completed})}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

class Day extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = new Date(this.props.date);
        this.alreadyScrolled = false;
        this.state = {
            hours: [],
            vitems: [],
            vitemsTop: [],
            topItemsContainerHeight: 0
        };
    }

    scrollToWorkingHour() {
        this.hoursContainer.scrollTop = (this.hoursRefs[8].offsetTop - 10);
    }

    getDateRange() {
        const start = new Date(this.currentDate.getTime());
        const end = new Date(start.getTime());

        end.setDate(end.getDate() + 1);

        return {
            start: start,
            end: end
        };
    }

    onWindowResize() {
        for (const k in this.itemsComponents) {
            this.itemsComponents[k].adjustPositionAndSize();
        }
    }

    buildCalendar(allItems) {
        let date = new Date(this.currentDate.getTime());

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // create hours information
        let hours = [];

        for (let h = 0; h < 24; ++h) {
            hours.push({
                date: new Date(date.getTime())
            });

            date.setHours(date.getHours() + 1);
        }

        // create top and regular items
        let vitems = [];
        let vitemsTop = [];

        const dayStart = new Date(this.currentDate.getTime());

        dayStart.setHours(0);
        dayStart.setMinutes(0);
        dayStart.setSeconds(0);
        dayStart.setMilliseconds(0);

        const dayEnd = new Date();
        dayEnd.setTime(dayStart.getTime() + ONE_DAY_MS - 1);

        for (const item of allItems) {
            let vi = {
                id: guid(),
                item: item
            };

            if (item.type === 'appointments') {
                // part of the appointment has to occur this week
                if ((!item.startDate || !item.endDate) ||
                    (item.endDate < dayStart) ||
                    (item.startDate > dayEnd)
                ) {
                    continue;
                }

                const numDays = daysDiff(item.startDateNorm, item.endDateNorm) + 1;

                if (numDays > 1) {
                    vi.starting = isSameDate(dayStart, item.startDateNorm); // does the appointment begin this day?
                    vi.finishing = isSameDate(dayStart, item.endDateNorm); // does the appointment finish this day?

                    vitemsTop.push(vi);
                } else {
                    vi.hour = item.startDate.getHours();
                    vi.minutes = item.startDate.getMinutes();
                    vi.minutesNorm = (vi.hour * 60) + vi.minutes; // num minutes from 00:00
                    vi.numMinutes = minutesDiff(item.startDate, item.endDate);

                    vitems.push(vi)
                }
            } else if (isSameDate(dayStart, item.dueDate)) {
                vitemsTop.push(vi);
            }
        }

        if (vitems.length > 0) {
            const numMinutesPerDay = 24 * 60;
            let vitemsOrder = Array.from({length: numMinutesPerDay}, () => 0); // order per minute

            // sort the appointments based on their length (longer appears on the left)
            for (let m = 0; m < numMinutesPerDay; ++m) {
                const vitemsMinute = vitems.filter(vi => vi.minutesNorm === m);

                if (vitemsMinute.length === 0) {
                    continue;
                }

                const vitemsSorted = _.sortBy(vitemsMinute, vi => vi.numMinutes).reverse();

                for (let vi of vitemsSorted) {
                    vi.order = vitemsOrder[m];

                    for (let i = 0; i < vi.numMinutes; ++i) {
                        vitemsOrder[m + i] = vi.order + 1;
                    }
                }
            }

            // calculate how many appointments share same span time
            for (const vi of vitems) {
                let maxOrder = 0;

                for (let m = vi.minutesNorm; m < vi.minutesNorm + vi.numMinutes; ++m) {
                    if (vitemsOrder[m] > maxOrder) {
                        maxOrder = vitemsOrder[m];
                    }
                }

                // the number of appoinments == max order
                vi.numSharingWith = maxOrder;
            }
        }

        // sort top appointments
        let topItemsContainerHeight = 0;

        if (vitemsTop.length > 0) {
            vitemsTop = _.sortBy(vitemsTop, vi => vi.numDays).reverse();

            for (let o = 0; o < vitemsTop.length; ++o) {
                vitemsTop[o].order = o;
            }

            topItemsContainerHeight = (vitemsTop[vitemsTop.length - 1].order + 1) * VITEM_HEIGHT;
        }

        this.setState({
            hours: hours,
            vitems: vitems,
            vitemsTop: vitemsTop,
            topItemsContainerHeight: topItemsContainerHeight
        });

        if (!this.alreadyScrolled) {
            const self = this;

            _.defer(function() {
                self.hoursContainer.scrollTop = (self.hoursRefs[8].offsetTop - 10);
                self.alreadyScrolled = true;
            });
        }
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    handleToggleTaskCompleted(task) {
        task.completed = !task.completed;

        this.props.onToggleTaskCompleted(task);
        this.itemsComponents[task.id].setCompleted(task.completed);
    }

    render() {
        const gmtOffset = this.currentDate.getTimezoneOffset();
        const gmt = gmtOffset / 60;
        const agmt = Math.abs(gmt);
        const gmtText = `GMT${gmt < 0 ? '+' : '-'}${agmt < 10 ? '0' : ''}${agmt}`;
        const now = new Date();
        const today = isToday(this.currentDate);
        const blurred = !today && this.currentDate < now;

        this.itemsComponents = {};
        this.hoursRefs = [];

        return (
            <div
                key={`day_${dateFormat.ISODate(this.currentDate)}`}
                className={style.day}
            >
                <div className={style.dHeader}>
                    <div className={style.hTime}>{gmtText}</div>
                    <div className={style.hDivider}/>
                    <div className={style.hContent}>
                        <div
                            className={`
                                ${style.cTitle}
                                ${today ? style.cToday : ''}
                                ${blurred ? style.cBlurred : ''}
                            `}
                        >
                            <div className={style.tDay}>{dateFormat.shortFormatDay(this.currentDate.getDay())}</div>
                            <div className={style.tNumber}>{this.currentDate.getDate()}</div>
                        </div>
                        <div
                            ref={(el) => this.headerComponent = el}
                            className={style.dContainer}
                            style={{
                                height: `${Math.max(this.state.topItemsContainerHeight, 20)}px`
                            }}
                        >
                            {this.state.vitemsTop.map(vi => {
                                if (vi.item.type === 'appointments') {
                                    return (
                                        <TopAppointment
                                            ref={(el) => {if (el) { this.itemsComponents[vi.id] = el }}}
                                            key={vi.id}
                                            data={vi}
                                            visualization='day'
                                            parent={this.headerComponent}
                                            onEditAppointment={() => {this.props.onEditAppointment(vi.item.model)}}
                                            onShowAppointmentPopover={this.props.onShowAppointmentPopover}
                                        />
                                    );
                                } else {
                                    return (
                                        <TaskEx
                                            ref={(el) => {if (el) { this.itemsComponents[vi.id] = el }}}
                                            key={vi.id}
                                            data={vi}
                                            parent={this.headerComponent}
                                            visible={true}
                                            visualization='day'
                                            onEditTask={() => { this.props.onEditTask(vi.item.model)} }
                                            onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)(vi)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>

                <div
                    ref={(el) => this.hoursContainer = el}
                    className={style.dRows}
                >
                    {this.state.hours.map((hour, hidx) => {
                        return (
                            <div
                                ref={(el) => this.hoursRefs[hidx] = el}
                                key={`hour_${hidx}`}
                                id={`hour_${hidx}`}
                                className={style.dRow}
                            >
                                <div className={style.rTime}>{dateFormat.shortFormatTime(hour.date, false)}</div>
                                <div className={style.rDivider}/>
                                <div
                                    className={style.rContent}
                                    onClick={(ev) => this.props.onCreateItem(hour.date, ev)}
                                >
                                    <div className={style.itemsContainer}/>
                                </div>
                            </div>
                        );
                    })}

                    {this.state.vitems.map(vi => {
                        if (vi.item.type === 'appointments') {
                            return (
                                <AppointmentEx
                                    ref={(el) => {if (el) { this.itemsComponents[vi.id] = el }}}
                                    key={vi.id}
                                    data={vi}
                                    visible={true}
                                    visualization='day'
                                    parent={this.hoursContainer}
                                    onEditAppointment={() => { this.props.onEditAppointment(vi.item.model)}}
                                    onShowAppointmentPopover={this.props.onShowAppointmentPopover}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}

class KanbanWeek extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = new Date(this.props.date);

        this.state = {
            days: [],
            columnsHeight: 0
        };

        this.numElementsLongerColumn = 0;
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    onWindowResize() {
        this.setState({
            columnsHeight: Math.max(this.numElementsLongerColumn * (KANBAN_TASK_HEIGHT + 5) + 5, $(this.columnsComponent)[0].getBoundingClientRect().height)
        });
    }

    getDateRange() {
        const start = new Date(this.state.days[0].date.getTime());
        const end = new Date(this.state.days[this.state.days.length - 1].date.getTime());

        end.setDate(end.getDate() + 1);

        return {
            start: start,
            end: end
        };
    }

    handleToggleTaskCompleted(vitem) {
        vitem.item.completed = !vitem.item.completed;

        this.props.onToggleTaskCompleted(vitem.item);
        this.itemsComponents[vitem.id].setCompleted(vitem.item.completed);
    }

    buildCalendar(allItems) {
        let date = adjustDateToFirstDayOfTheWeek(this.currentDate);

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // create days information
        let days = [];
        this.numElementsLongerColumn = 0;

        for (let i = 0; i < 7; ++i) {
            let dayDate = new Date(date.getTime());
            let items = _.sortBy(allItems.filter(i => i.dueDate && isSameDate(i.dueDate, date)), i => i.dueDate);

            if (items.length > this.numElementsLongerColumn) {
                this.numElementsLongerColumn = items.length;
            }

            days.push({
                date: new Date(date.getTime()),
                items: items
            });

            date.setDate(date.getDate() + 1);
        }

        this.setState({
            days: days,
            columnsHeight: Math.max(this.numElementsLongerColumn * (KANBAN_TASK_HEIGHT + 5) + 5, $(this.columnsComponent)[0].getBoundingClientRect().height)
        });
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    render() {
        const gmtOffset = this.currentDate.getTimezoneOffset();
        const gmt = gmtOffset / 60;
        const agmt = Math.abs(gmt);
        const gmtText = `GMT${gmt < 0 ? '+' : '-'}${agmt < 10 ? '0' : ''}${agmt}`;
        const today = new Date();
        const firstWeekDay = adjustDateToFirstDayOfTheWeek(today);

        this.itemsComponents = {};

        return (
            <div
                ref={(el) => this.component = el}
                key={`week_${dateFormat.ISODate(this.currentDate)}`}
                className={style.week}
            >
                <div className={style.wHeader}>
                    <div className={style.hTime}>{gmtText}</div>
                    <div className={style.hDivider}/>
                    <div style={{width: '100%'}}>
                        <div className={style.hContent}>
                            {this.state.days.map((day, didx) => {
                                const today = isToday(day.date);
                                const blurred = !today && (day.date < firstWeekDay);

                                return (
                                    <div
                                        key={`day_${didx}`}
                                        className={style.cDay}
                                    >
                                        <div
                                            className={`
                                                ${style.cTitle}
                                                ${today ? style.cToday : ''}
                                                ${blurred ? style.cBlurred : ''}
                                            `}
                                        >
                                            <div className={style.tDay}>{dateFormat.shortFormatDay(day.date.getDay())}</div>
                                            <div
                                                className={style.tNumber}
                                                onClick={(ev) => {ev.stopPropagation(); this.props.onDateSpanChange(day.date, 'day')}}
                                            >
                                                {day.date.getDate()}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            ref={(el) => this.headerComponent = el}
                            className={style.hKanbanContent}
                        >
                            {this.state.days.map((_, didx) => {
                                return (
                                    <div key={`day_${didx}_2`} className={style.cKanbanDay}/>
                                );
                            })}
                        </div>
                    </div>
                </div>


                <div
                    ref={(el) => {if (el) {this.columnsComponent = el}}}
                    className={style.wColumns}
                >
                    {this.state.days.map((day, didx) => {
                        return (
                            <div
                                className={style.wColumn}
                                key={`col_${didx}`}
                                style={{
                                    height: `${this.state.columnsHeight}px`
                                }}
                                onClick={(ev) => {
                                    let td = new Date(day.date.getTime());
                                    td.setHours(11);
                                    this.props.onCreateItem(td, ev);
                                }}
                            >
                                {day.items.map(item => {
                                    return (
                                        <KanbanTask
                                            ref={(el) => {if (el) { this.itemsComponents[item.id] = el }}}
                                            key={item.id}
                                            data={item}
                                            onEditTask={() => { this.props.onEditTask(item.model)} }
                                            onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)({id: item.id, item: item, completed: item.completed})}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class Week extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = new Date(this.props.date);
        this.alreadyScrolled = false;
        this.state = {
            days: [],
            vitems: [],
            vitemsTop: [],
            topItemsContainerHeight: 0
        };
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    scrollToWorkingHour() {
        this.hoursContainer.scrollTop = this.hoursRefs[8].offsetTop - 10;
    }

    getDateRange() {
        const start = new Date(this.state.days[0].date.getTime());
        const end = new Date(this.state.days[this.state.days.length - 1].date.getTime());

        end.setDate(end.getDate() + 1);

        return {
            start: start,
            end: end
        };
    }

    handleToggleTaskCompleted(vitem) {
        vitem.item.completed = !vitem.item.completed;

        this.props.onToggleTaskCompleted(vitem.item);
        this.itemsComponents[vitem.id].setCompleted(vitem.item.completed);
    }

    onWindowResize() {
        for (const k in this.itemsComponents) {
            this.itemsComponents[k].adjustPositionAndSize();
        }
    }

    buildCalendar(allItems) {
        let date = adjustDateToFirstDayOfTheWeek(this.currentDate);

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // create days/hours information
        let days = [];

        for (let i = 0; i < 7; ++i) {
            let hours = [];
            let dayDate = new Date(date.getTime());

            for (let h = 0; h < 24; ++h) {
                const d = new Date(dayDate.getTime());

                hours.push({
                    date: d,
                });

                dayDate.setHours(dayDate.getHours() + 1);
            }

            days.push({
                date: new Date(date.getTime()),
                hours: hours
            });

            date.setDate(date.getDate() + 1);
        }

        // there are 2 types of items in the week/day view:
        // 1. the appointments longer than 1 day that appear in the top and always have the same size.
        // 2. the appointments that start and end in the same day which size depends of the number of hours they last
        //    and if they are sharing the same 'cell' with others appointments
        let vitems = [];
        let vitemsTop = [];

        const weekStart = adjustDateToFirstDayOfTheWeek(this.currentDate);

        weekStart.setHours(0);
        weekStart.setMinutes(0);
        weekStart.setSeconds(0);
        weekStart.setMilliseconds(0);

        const weekEnd = new Date();
        weekEnd.setTime(weekStart.getTime() + (ONE_DAY_MS * 7) - 1);

        for (const item of allItems) {
            let vi = {
                id: guid(),
                item: item
            };

            if (item.type === 'appointments') {
                // part of the appointment has to occur this week
                if ((!item.startDate || !item.endDate) ||
                    (item.startDate > weekEnd) ||
                    (item.endDate < weekStart)
                ) {
                    continue;
                }

                const numDays = daysDiff(item.startDateNorm, item.endDateNorm) + 1;

                if (numDays > 1) {
                    const finishing = item.endDateNorm <= weekEnd; // does the appointment finish this week?

                    vi.starting = item.startDateNorm >= weekStart; // does the appointment begin this week?
                    vi.day = vi.starting ? daysDiff(weekStart, item.startDateNorm) : 0;

                    if (vi.starting && finishing) {
                        vi.numDays = numDays;
                    } else if (vi.starting) {
                        vi.numDays = 7 - vi.day;
                    } else {
                        vi.numDays = daysDiff(weekStart, item.endDateNorm) + 1;
                    }

                    vitemsTop.push(vi);
                } else {
                    vi.day = daysDiff(weekStart, item.startDateNorm);
                    vi.hour = item.startDate.getHours();
                    vi.minutes = item.startDate.getMinutes();
                    vi.minutesNorm = (vi.hour * 60) + vi.minutes; // num minutes from 00:00
                    vi.numMinutes = minutesDiff(item.startDate, item.endDate);

                    vitems.push(vi)
                }
            } else if (item.dueDate && item.dueDate >= weekStart && item.dueDate < weekEnd) {
                vi.day = daysDiff(weekStart, item.dueDate);
                vi.numDays = 1;

                vitemsTop.push(vi);
            }
        }

        if (vitems.length > 0) {
            const numMinutesPerDay = 24 * 60;

            // sort the appointments based on their length (longer appears on the left)
            for (let d = 0; d < 7; ++d) {
                const vitemsOrder = Array.from({length: numMinutesPerDay}, () => 0); // order per minute
                const vitemsDay = vitems.filter(vi => vi.day === d);

                for (let m = 0; m < numMinutesPerDay; ++m) {
                    const vitemsMinute = vitemsDay.filter(vi => vi.minutesNorm === m);

                    if (vitemsMinute.length === 0) {
                        continue;
                    }

                    const vitemsSorted = _.sortBy(vitemsMinute, vi => vi.numMinutes).reverse();

                    for (let vi of vitemsSorted) {
                        vi.order = vitemsOrder[m];

                        for (let i = 0; i < vi.numMinutes; ++i) {
                            vitemsOrder[m + i] = vi.order + 1;
                        }
                    }
                }

                // calculate how many appointments share same span time
                for (const vi of vitemsDay) {
                    let maxOrder = 0;

                    for (let m = vi.minutesNorm; m < vi.minutesNorm + vi.numMinutes; ++m) {
                        if (vitemsOrder[m] > maxOrder) {
                            maxOrder = vitemsOrder[m];
                        }
                    }

                    // the number of appoinments == max order
                    vi.numSharingWith = maxOrder;
                }
            }
        }

        // sort top appointments
        let topItemsContainerHeight = 0;

        if (vitemsTop.length > 0) {
            let vitemsOrder = Array.from({length: 7}, () => 0);

            vitemsTop = _.sortBy(vitemsTop, vi => vi.numDays).reverse();

            for (let vi of vitemsTop) {
                vi.order = vitemsOrder[vi.day];

                for (let i = vi.day; i < vi.day + vi.numDays; ++i) {
                    vitemsOrder[i] = vi.order + 1;
                }
            }

            vitemsTop = _.sortBy(vitemsTop, vi => vi.order);
            topItemsContainerHeight = (vitemsTop[vitemsTop.length - 1].order + 1) * VITEM_HEIGHT;
        }

        this.setState({
            days: days,
            vitems: vitems,
            vitemsTop: vitemsTop,
            topItemsContainerHeight: topItemsContainerHeight
        });

        if (!this.alreadyScrolled) {
            const self = this;

            _.defer(function() {
                self.hoursContainer.scrollTop = self.hoursRefs[8].offsetTop - 10;
                self.alreadyScrolled = true;
            });
        }
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    render() {
        const gmtOffset = this.currentDate.getTimezoneOffset();
        const gmt = gmtOffset / 60;
        const agmt = Math.abs(gmt);
        const gmtText = `GMT${gmt < 0 ? '+' : '-'}${agmt < 10 ? '0' : ''}${agmt}`;
        const today = new Date();
        const firstWeekDay = adjustDateToFirstDayOfTheWeek(today);

        this.itemsComponents = {};
        this.hoursRefs = [];

        return (
            <div
                ref={(el) => this.component = el}
                key={`week_${dateFormat.ISODate(this.currentDate)}`}
                className={style.week}
            >
                <div className={style.wHeader}>
                    <div className={style.hTime}>{gmtText}</div>
                    <div className={style.hDivider}/>
                    <div style={{width: '100%'}}>
                        <div className={style.hContent}>
                            {this.state.days.map((day, didx) => {
                                const today = isToday(day.date);
                                const blurred = !today && (day.date < firstWeekDay);

                                return (
                                    <div
                                        key={`day_${didx}`}
                                        className={style.cDay}
                                    >
                                        <div
                                            className={`
                                                ${style.cTitle}
                                                ${today ? style.cToday : ''}
                                                ${blurred ? style.cBlurred : ''}
                                            `}
                                        >
                                            <div className={style.tDay}>{dateFormat.shortFormatDay(day.date.getDay())}</div>
                                            <div
                                                className={style.tNumber}
                                                onClick={(ev) => {ev.stopPropagation(); this.props.onDateSpanChange(day.date, 'day')}}
                                            >
                                                {day.date.getDate()}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            ref={(el) => this.headerComponent = el}
                            className={style.hContent}
                            style={{
                                height: `${Math.max(this.state.topItemsContainerHeight, 20)}px`,
                                marginTop: '15px'
                            }}
                        >
                            {this.state.days.map((_, didx) => {
                                return (
                                    <div key={`day_${didx}_2`} className={`${style.cDay} ${style.dBorderer}`}/>
                                );
                            })}

                            {this.state.vitemsTop.map(vi => {
                                if (vi.item.type === 'appointments') {
                                    return (
                                        <TopAppointment
                                            ref={(el) => {if (el) { this.itemsComponents[vi.id] = el }}}
                                            key={vi.id}
                                            data={vi}
                                            visualization='week'
                                            parent={this.headerComponent}
                                            onEditAppointment={() => {this.props.onEditAppointment(vi.item.model)}}
                                            onShowAppointmentPopover={this.props.onShowAppointmentPopover}
                                        />
                                    );
                                } else {
                                    return (
                                        <TaskEx
                                            ref={(el) => {if (el) { this.itemsComponents[vi.id] = el }}}
                                            key={vi.id}
                                            data={vi}
                                            parent={this.headerComponent}
                                            visible={true}
                                            visualization='week'
                                            onEditTask={() => { this.props.onEditTask(vi.item.model)} }
                                            onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)(vi)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>

                {this.state.days.length > 0 &&
                    <div
                        ref={(el) => this.hoursContainer = el}
                        className={style.wRows}
                    >
                        {this.state.days[0].hours.map((hour, hidx) => {
                            return (
                                <div
                                    ref={(el) => this.hoursRefs[hidx] = el}
                                    key={`hour_${hidx}`}
                                    className={style.wRow}
                                >
                                    <div className={style.rTime}>{dateFormat.shortFormatTime(hour.date, false)}</div>
                                    <div className={style.rDivider}/>
                                    <div className={style.rContent}>
                                        {this.state.days.map((day, didx) => {
                                            const items = day.hours[hidx].items;

                                            return (
                                                <div
                                                    key={`${didx}_${hidx}`}
                                                    id={`hour_${didx}_${hidx}`}
                                                    className={style.cCell}
                                                    onClick={(ev) => {
                                                        let td = new Date(day.date.getTime());
                                                        td.setHours(hidx);
                                                        this.props.onCreateItem(td, ev);
                                                    }}
                                                >
                                                    <div className={style.itemsContainer}/>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}

                        {this.state.vitems.map(vitem => {
                            if (vitem.item.type === 'appointments') {
                                return (
                                    <AppointmentEx
                                        ref={(el) => {if (el) { this.itemsComponents[vitem.id] = el }}}
                                        key={vitem.id}
                                        data={vitem}
                                        visualization='week'
                                        visible={true}
                                        parent={this.component}
                                        onEditAppointment={() => { this.props.onEditAppointment(vitem.item.model)}}
                                        onShowAppointmentPopover={this.props.onShowAppointmentPopover}
                                    />
                                );
                            }
                        })}
                    </div>
                }
            </div>
        );
    }
}

class Year extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = this.props.date;
        this.state = {
            months: []
        };
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    getDateRange() {
        // it's not necessary to load the tasks in the year view because we are not showing anything related to them
        return null;
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    buildCalendar() {
        let months = [];

        for (let m = 0; m < 12; ++m) {
            let date = adjustDateToFirstDayOfTheWeek(new Date(this.currentDate.getFullYear(), m, 1));
            let weeks = [];

            for (let w = 0; w < 6; ++w) {
                let week = [];

                for (let d = 0; d < 7; ++d) {
                    const d = new Date(date.getTime());

                    week.push({
                        date: d
                    });

                    date.setDate(date.getDate() + 1);
                }

                weeks.push(week)
            }

            months.push(weeks);
        }

        this.setState({
            months: months
        });
    }

    render() {
        const currentMonth = new Date().getMonth();
        const startOnSunday = AppConfig.getClientPreferenceValue('startWeekOnSunday');
        const days = (startOnSunday ? 'SMTWTFS': 'MTWTFSS').split('');
        const today = new Date();

        return (
            <div className={style.year}>
                {this.state.months.map((month, midx) => {
                    return (
                        <div
                            key={`month_${midx}`}
                            className={`
                                ${style.yMonth}
                                ${midx < currentMonth ? style.blurred : ''}
                            `}
                        >
                            <div className={style.mTitle}>{dateFormat.formatMonth(midx)}</div>

                            <div className={style.yWeek}>
                                {days.map((d, di) => {
                                    return (
                                        <div
                                            key={`d_${d}_${di}`}
                                            className={style.yDay}
                                            style={{color: '#70757a'}}
                                        >
                                            {d}
                                        </div>
                                    );
                                })}
                            </div>

                            {month.map((week, widx) => {
                                return (
                                    <div
                                        key={`week_${midx}_${widx}`}
                                        className={style.yWeek}
                                    >
                                        {week.map((day, didx) => {
                                            const today = isToday(day.date);
                                            const blurred = day.date.getMonth() !== midx;

                                            return (
                                                <div
                                                    key={`day_${midx}_${widx}_${didx}`}
                                                    className={`
                                                        ${style.yDay}
                                                        ${today && !blurred ? style.today : ''}
                                                        ${blurred ? style.blurred : ''}
                                                    `}
                                                    onClick={(ev) => {ev.stopPropagation(); this.props.onDateSpanChange(day.date, 'month')}}
                                                >
                                                    {day.date.getDate()}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

class Month extends React.Component {
    constructor(props) {
        super(props);

        this.currentDate = this.props.date;
        this.state = {
            weeks: [],
            vitems: []
        };
    }

    componentDidMount() {
        this.buildCalendar([]);
    }

    getDateRange() {
        const start = new Date(this.state.weeks[0][0].date.getTime());
        const lastWeek = this.state.weeks[this.state.weeks.length - 1];
        let end = new Date(lastWeek[lastWeek.length - 1].date.getTime());

        end.setDate(end.getDate() + 1);

        return {
            start: start,
            end: end
        };
    }

    buildCalendar(allItems) {
        const initialDay = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
        const weekDay = adjustDateToFirstDayOfTheWeek(initialDay);
        const diffDays = Math.trunc((initialDay - weekDay) / ONE_DAY_MS);
        const monthNumDays = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1, 0).getDate();
        const numDaysToDisplay = monthNumDays + diffDays;
        const numWeeks = Math.ceil(numDaysToDisplay / 7);

        // create week/days information
        const currentDay = adjustDateToFirstDayOfTheWeek(initialDay);
        let weeks = [];

        for (let w = 0; w < numWeeks; ++w) {
            let week = [];

            for (let d = 0; d < 7; ++d) {
                const date = new Date(currentDay.getTime());

                week.push({
                    date: date
                });

                currentDay.setDate(currentDay.getDate() + 1);
            }

            weeks.push(week);
        }

        // the calendar items are created by weeks, i.e. the number of items related to an appointment depends of how many weeks the appointment
        // is part of. I mean, an appointment can last only 3 days, but can start on sunday week 1, and finish on tuesday week 2. In this case
        // we'll create 2 calendar items.
        let weekStart = new Date();
        let weekEnd = new Date();
        let vitems = [];

        for (let w = 0; w < numWeeks; ++w) {
            weekStart.setTime(weekDay.getTime());
            weekEnd.setTime(weekDay.getTime() + (ONE_DAY_MS * 7) - 1);

            let vitemsWeek = [];

            for (const item of allItems) {
                let vi = {
                    id: guid(),
                    item: item,
                    week: w
                };

                if (item.type === 'appointments') {
                    // part of the appointment has to occur this week
                    if ((!item.startDate && !item.endDate) ||
                        (item.startDate && item.startDate > weekEnd) ||
                        (item.endDate && item.endDate < weekStart)
                    ) {
                        continue;
                    }

                    let appointmentDuration = 1;

                    if (item.startDateNorm && item.endDateNorm) {
                        appointmentDuration = daysDiff(item.startDateNorm, item.endDateNorm) + 1;
                    }

                    if (appointmentDuration === 1) {
                        vi.day = daysDiff(weekStart, item.startDateNorm);
                        vi.numDays = 1;
                    } else {
                        const appointmentRemainingDays = daysDiff(weekStart, item.endDateNorm) + 1;
                        const start = item.startDateNorm >= weekStart; // does the appointment start this week?

                        vi.day = start ? daysDiff(weekStart, item.startDateNorm) : 0;
                        vi.numDays = Math.min(appointmentRemainingDays, 7) - vi.day;
                    }

                    vitemsWeek.push(vi);
                } else if (item.dueDate && item.dueDate >= weekStart && item.dueDate <= weekEnd) {
                    vi.day = daysDiff(weekStart, item.dueDate);
                    vi.numDays = 1;
                    vitemsWeek.push(vi);
                }
            }

            // sort the appointments based on their length (longer appears on the top)
            if (vitemsWeek.length > 0) {
                let vitemsOrder = [0, 0, 0, 0, 0, 0, 0]; // order per day

                for (let d = 0; d < 7; ++d) {
                    const vitemsDay = vitemsWeek.filter(vi => vi.day === d);

                    if (vitemsDay.length === 0) {
                        continue;
                    }

                    const vitemsSorted = _.sortBy(vitemsDay, vi => vi.numDays).reverse();

                    for (let vi of vitemsSorted) {
                        vi.order = vitemsOrder[d];

                        for (let i = 0; i < vi.numDays; ++i) {
                            vitemsOrder[d + i] = vi.order + 1;
                        }
                    }
                }

                vitems = [...vitems, ...vitemsWeek];
            }

            weekDay.setDate(weekDay.getDate() + 7);
        }

        this.setState({
            weeks: weeks,
            vitems: vitems
        });

        this.manageItemsVisibility();
    }

    onWindowResize() {
        for (const k in this.itemsComponents) {
            this.itemsComponents[k].adjustPositionAndSize();
        }

        this.manageItemsVisibility();
    }

    manageItemsVisibility() {
        const self = this;

        _.defer(function() {
            // let's see how many vertical items are available per week
            const el = $(self.component);

            // the days in the first week have the height a bit smaller because they are printing the day of the wee (mon, tue,...)
            // so to simplify we always get the height of each week
            for (let w = 0; w < self.state.weeks.length; ++w) {
                const vitemsPerWeek = self.state.vitems.filter(vi => vi.week === w);

                if (vitemsPerWeek.length === 0) {
                    continue;
                }

                const itemsContainerEl = el.find(`#week_${w}`).find(`.${style.itemsContainer}`);
                const itemsContainerHeight = itemsContainerEl[0].getBoundingClientRect().height;
                const maxNumItemsVisible = Math.floor(itemsContainerHeight / VITEM_HEIGHT);

                for (let d = 0; d < 7; ++d) {
                    const moreItemsComponent = self.moreItemsComponents[`${w}_${d}`];
                    moreItemsComponent.setNumItems(0);
                }

                for (let d = 0; d < 7; ++d) {
                    const vitemsPerDay = vitemsPerWeek.filter(vi => vi.day === d);

                    if (vitemsPerDay.length === 0) {
                        continue;
                    }

                    for (const vi of vitemsPerDay) {
                        const vitemVisible = (vi.order < maxNumItemsVisible - 1); // -1 = the height for the '+x more items' component

                        self.itemsComponents[vi.id].setVisible(vitemVisible);

                        if (!vitemVisible) {
                            for (let i = vi.day; i < (vi.day + vi.numDays); ++i) {
                                const mic = self.moreItemsComponents[`${w}_${i}`];
                                mic.setNumItems(mic.getNumItems() + 1);
                            }
                        }
                    }
                }
            }
        });
    }

    setCurrentDate(date) {
        this.currentDate = date;
        this.buildCalendar([]);
    }

    handleToggleTaskCompleted(vitem) {
        vitem.item.completed = !vitem.item.completed;

        this.props.onToggleTaskCompleted(vitem.item);
        this.itemsComponents[vitem.id].setCompleted(vitem.item.completed);
    }

    render() {
        this.itemsComponents = {};
        this.moreItemsComponents = {};

        const today = new Date();

        return (
            <div
                ref={(el) => this.component = el}
                key={`month_${dateFormat.ISODate(this.currentDate)}`}
                className={style.month}
            >
                {this.state.vitems.map(vitem => {
                    if (vitem.item.type === 'appointments') {
                        return (
                            <AppointmentEx
                                ref={(el) => {if (el) { this.itemsComponents[vitem.id] = el }}}
                                key={vitem.id}
                                data={vitem}
                                visualization='month'
                                parent={this.component}
                                onEditAppointment={() => { this.props.onEditAppointment(vitem.item.model)}}
                                onShowAppointmentPopover={this.props.onShowAppointmentPopover}
                            />
                        );
                    } else {
                        return (
                            <TaskEx
                                ref={(el) => {if (el) { this.itemsComponents[vitem.id] = el }}}
                                key={vitem.id}
                                data={vitem}
                                parent={this.component}
                                visualization='month'
                                onEditTask={() => { this.props.onEditTask(vitem.item.model)} }
                                onToggleTaskCompleted={() => this.handleToggleTaskCompleted.bind(this)(vitem)}
                            />
                        );
                    }
                })}

                {this.state.weeks.map((week, widx) => {
                    return (
                        <div
                            key={`week_${widx}`}
                            id={`week_${widx}`}
                            className={style.mWeek}
                            style={{height: `calc(100% / ${this.state.weeks.length})`}}
                        >
                            {week.map((d, dix) => {
                                const day = d.date;
                                let date = day.getDate();

                                if (date === 1) {
                                    date += ` ${dateFormat.shortFormatMonth(day.getMonth())}`;
                                }

                                const today = isToday(day);

                                return (
                                    <div
                                        key={`day_${widx}_${dix}`}
                                        id={`day_${widx}_${dix}`}
                                        className={style.mDay}
                                        onClick={(ev) => {
                                            let td = new Date(day.getTime());

                                            td.setHours(11);
                                            this.props.onCreateItem(td, ev);
                                        }}
                                    >
                                        <div className={style.dateInfo}>
                                            {widx === 0 &&
                                                <div className={style.dayOfTheWeek}>
                                                    {DAY_OF_THE_WEEK[day.getDay()]}
                                                </div>
                                            }
                                            <div
                                                className={`
                                                    ${style.date}
                                                    ${day.getMonth() !== this.currentDate.getMonth() ? style.diffMonth : ''}
                                                    ${today ? style.today : ''}
                                                `}
                                                onClick={(ev) => {ev.stopPropagation(); this.props.onDateSpanChange(day, 'week')}}
                                            >
                                                {date}
                                            </div>
                                        </div>

                                        <div className={style.itemsContainer}>
                                            <MoreItems
                                                ref={(el) => this.moreItemsComponents[`${widx}_${dix}`] = el}
                                                entityType={this.props.entityType}
                                                onShowMoreItems={() => this.props.onDateSpanChange(day, 'week')}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export class CalendarDateSelector extends React.Component {
    constructor(props) {
        super(props);

        this.spanTime = [{
            id: 'day',
            title: 'Day'
        }, {
            id: 'week',
            title: 'Week'
        }, {
            id: 'month',
            title: 'Month'
        }, {
            id: 'year',
            title: 'Year'
        }, {
            id: 'schedule',
            title: 'Schedule'
        }];

        this.entityTypes = [{
            id: 'appointments',
            title: 'Appointments'
        }, {
            id: 'tasks',
            title: 'Tasks'
        }];

        this.currentDate = new Date(this.props.date.getTime());
        this.currentSpan = this.props.span;

        this.state = {
            date: this.buildDate(),
            visible: props.visible || false
        };
    }

    setDateSpan(date, span) {
        this.currentDate = new Date(date.getTime());
        this.currentSpan = span;

        this.setState({
            date: this.buildDate()
        });

        this.selectComponent.setValue(span);

        this.props.onDateSpanChange(this.currentDate, this.currentSpan);
    }

    buildDate() {
        switch(this.currentSpan) {
            case 'day':
            case 'kanbanDay':
                return `${this.currentDate.getDate()} ${dateFormat.formatMonth(this.currentDate.getMonth())} ${this.currentDate.getFullYear()}`;

            case 'week':
            case 'kanbanWeek': {
                const date = adjustDateToFirstDayOfTheWeek(this.currentDate);
                let months = [];

                for (let d = 0; d < 7; ++d) {
                    const month = date.getMonth();

                    if (months.indexOf(month) === -1) {
                        months.push(month);
                    }

                    date.setDate(date.getDate() + 1);
                }

                if (months.length === 1) {
                    return `${dateFormat.formatMonth(months[0])} ${date.getFullYear()}`;
                }

                return `${dateFormat.shortFormatMonth(months[0])} – ${dateFormat.shortFormatMonth(months[1])} ${date.getFullYear()}`;
            }

            case 'month':
                return `${dateFormat.formatMonth(this.currentDate.getMonth())} ${this.currentDate.getFullYear()}`;

            case 'year':
                return this.currentDate.getFullYear();

            case 'schedule': {
                const start = new Date(this.currentDate.getTime());
                const end = new Date(start.getTime());

                end.setFullYear(end.getFullYear() + 1);

                return `${dateFormat.shortFormatMonth(start.getMonth())} ${start.getFullYear()} – ${dateFormat.shortFormatMonth(end.getMonth())} ${end.getFullYear()}`;
            }
        }

        return '';
    }

    handleEntityTypeSelect(items) {
        this.props.onEntityTypeChange(items[0].id);
    }

    handleSpanSelect(items) {
        if (this.currentSpan === items[0].id) {
            return;
        }

        this.currentSpan = items[0].id;

        this.setState({
            date: this.buildDate()
        });

        this.props.onSpanChange(this.currentSpan);
    }

    show() {
        this.setState({
            visible: true
        });
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    handleSpanPrev() {
        switch(this.currentSpan) {
            case 'day':
            case 'kanbanDay':
            case 'schedule':
                this.currentDate.setDate(this.currentDate.getDate() - 1);
                break;

            case 'week':
            case 'kanbanWeek':
                this.currentDate.setDate(this.currentDate.getDate() - 7);
                break;

            case 'month':
                this.currentDate.setMonth(this.currentDate.getMonth() - 1);
                break;

            case 'year':
                this.currentDate.setFullYear(this.currentDate.getFullYear() - 1);
                break;
        }

        this.setState({
            date: this.buildDate()
        });

        this.props.onDateChange(this.currentDate);
    }

    handleSpanNext() {
        switch(this.currentSpan) {
            case 'day':
            case 'kanbanDay':
            case 'schedule':
                this.currentDate.setDate(this.currentDate.getDate() + 1);
                break;

            case 'week':
            case 'kanbanWeek':
                this.currentDate.setDate(this.currentDate.getDate() + 7);
                break;

            case 'month':
                this.currentDate.setMonth(this.currentDate.getMonth() + 1);
                break;

            case 'year':
                this.currentDate.setFullYear(this.currentDate.getFullYear() + 1);
                break;
        }

        this.setState({
            date: this.buildDate()
        });

        this.props.onDateChange(this.currentDate);
    }

    handleTodayClick() {
        this.currentDate = new Date();

        this.setState({
            date: this.buildDate()
        });

        this.props.onDateChange(this.currentDate);
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        return (
            <div className={style.calendarDateSelector}>
                <div className={style.dsDate}>{this.state.date}</div>
                <div className={style.dsArrows}>
                    <div
                        className={style.dsArrow}
                        onClick={this.handleSpanPrev.bind(this)}
                    >
                        <div className='icon-angle-left'/>
                    </div>
                    <div
                        className={style.dsArrow}
                        onClick={this.handleSpanNext.bind(this)}
                    >
                        <div className='icon-angle-right'/>
                    </div>
                </div>

                {this.props.showEntitySelector &&
                    <div className={style.dsEntityTypeSelector}>
                        <NewSelect
                            width={140}
                            data={this.entityTypes}
                            value={this.entityTypes[0]}
                            options={{minimumInputLength: -1}}
                            onSelect={this.handleEntityTypeSelect.bind(this)}
                        />
                    </div>
                }

                <div className={style.dsSpanSelector}>
                    <NewSelect
                        ref={(el) => this.selectComponent = el}
                        width={115}
                        data={this.spanTime}
                        value={this.spanTime.find(st => st.id === this.props.span)}
                        options={{minimumInputLength: -1}}
                        onSelect={this.handleSpanSelect.bind(this)}
                    />
                </div>
                <div
                    className={style.dsToday}
                    onClick={this.handleTodayClick.bind(this)}
                >
                    Today
                </div>
            </div>
        );
    }
}

export class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            visible: !!this.props.visible,
            items: null,
            span: this.props.span || 'month',
            popoverMenu: null,
            appointmentPopover: null,
            entityType: props.entityType
        };

        this.currentFilter = null;
        this.groupId = null;
        this.currentDate = new Date(this.props.date.getTime());
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleWindowResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize.bind(this));
    }

    handleWindowResize() {
        if (!this.state.visible || this.state.loading || !this.activeViewComponent || !this.activeViewComponent.onWindowResize) {
            return;
        }

        this.activeViewComponent.onWindowResize();
    }

    scrollToWorkingHour() {
        if (this.activeViewComponent.scrollToWorkingHour) {
            this.activeViewComponent.scrollToWorkingHour();
        }
    }

    fetchData() {
        if (!this.state.visible) {
            return;
        }

        const createCalendarItem = function(model) {
            let data = null;

            if (model.get('type') === 'appointments') {
                let startDate = null;
                let startDateNorm = null;

                if (model.get('start_date')) {
                    startDate = dateFormat.parseDate(model.get('start_date'));
                    startDateNorm = dateFormat.parseDate(model.get('start_date'));
                    startDateNorm.setHours(0, 0, 0, 0);
                }

                let endDate = null;
                let endDateNorm = null;

                if (model.get('end_date')) {
                    endDate = dateFormat.parseDate(model.get('end_date'));
                    endDateNorm = dateFormat.parseDate(model.get('end_date'));
                    endDateNorm.setHours(0, 0, 0, 0);
                }

                data = {
                    id: model.get('id'),
                    type: 'appointments',
                    title: model.get('title'),
                    description: model.get('description'),
                    startDate: startDate,
                    endDate: endDate,
                    startDateNorm: startDateNorm,
                    endDateNorm: endDateNorm,
                    model: model
                };
            } else {
                data = {
                    id: model.get('id'),
                    type: 'tasks',
                    text: model.get('text'),
                    subject: model.get('subject'),
                    dueDate: dateFormat.parseDate(model.get('due_date')),
                    completed: model.get('completed'),
                    model: model
                };
            }

            return data;
        }

        const self = this;

        if (this.props.fetchDataFunction) {
            this.setState({
                loading: true
            });

            this.props.fetchDataFunction(function(data) {
                let items = [];

                for (const model of data) {
                    const item = createCalendarItem(model);

                    if (item) {
                        items.push(item);
                    }
                }

                self.setState({
                    loading: false,
                    items: items
                });

                self.activeViewComponent.buildCalendar(items.filter(i => i.type === self.state.entityType));
            });
        }
        else {
            const dateRange = this.activeViewComponent.getDateRange();

            if (!dateRange) {
                this.setState({
                    items: []
                });

                return;
            }

            // ...
            this.setState({
                loading: true
            });

            let rules = [];


            if (this.currentFilter) {
                const currentRules = this.currentFilter.get('rules');

                if (currentRules) {
                    rules = _.clone(currentRules);

                    // delete 'completed' rule. The calendar must show all the tasks
                    for (let i = 0; i < rules.length; ++i) {
                        rules[i] = rules[i].filter(r => r.field !== 'task_completed');
                    }

                    rules = rules.filter(r => r.length > 0);
                }
            }

            rules.push([{field: 'task_due_date', operator: 'between', 'values': {start: dateFormat.ISODate(dateRange.start), end: dateFormat.ISODate(dateRange.end)}}]);

            let filter = new TaskFilterModel();

            filter.save({
                rules: rules
            }, {
                alert: false,
                success: function(data) {
                    const tasksCollection = new GroupElementsCollection(null, {elementType: 'tasks'});
                    let fetchData = {
                        filter_id: data.get('id')
                    };

                    if (self.groupId) {
                        fetchData.group_id = self.groupId;
                    }

                    tasksCollection.fetch({
                        rows: -1,
                        data: fetchData,
                        success: function(response) {
                            let tasks = [];

                            for (const model of response.models) {
                                const item = createCalendarItem(model);

                                if (item) {
                                    tasks.push(item);
                                }
                            }

                            self.setState({
                                loading: false,
                                tasks: tasks
                            });

                            self.activeViewComponent.buildCalendar(tasks);
                        }
                    });
                }
            });
        }
    }

    show(currentFilter, groupId) {
        this.setState({
            visible: true
        });

        this.currentFilter = currentFilter;
        this.groupId = groupId;

        const self = this;

        _.defer(function() {
            self.fetchData();
        });
    }

    hide() {
        this.setState({
            visible: false
        });
    }

    isVisible() {
        return this.state.visible;
    }

    setFilter(filter) {
        this.currentFilter = filter;

        if (this.state.visible) {
            this.fetchData();
        }
    }

    setCurrentDate(date) {
        this.currentDate = new Date(date.getTime());
        this.activeViewComponent.setCurrentDate(new Date(date.getTime()));

        const self = this;

        _.defer(function() {
            self.fetchData();
        });
    }

    setSpan(span, noFetchData) {
        this.setState({
            span: span
        });

        if (!noFetchData) {
            const self = this;

            _.defer(function() {
                _.defer(function() {
                    self.fetchData();
                });
            });
        }
    }

    setDateSpan(date, span) {
        this.setSpan(span, true);

        const self = this;

        _.defer(function() {
            self.setCurrentDate(date);
        });
    }

    setTaskCompleted(taskId, completed) {
        if (this.state.loading) {
            return;
        }

        if (this.state.entityType && this.state.entityType !== 'tasks') {
            return;
        }

        const items = this.state.items;
        let updated = false;

        for (let item of items) {
            if (item.id === taskId) {
                if (item.completed !== completed) {
                    item.completed = completed;
                    updated = true;
                }
                break;
            }
        }

        if (updated) {
            this.setState({
                items: items
            });

            this.activeViewComponent.buildCalendar(items.filter(i => i.type === this.state.entityType));
        }
    }

    handleToggleTaskCompleted(task) {
        $.ajax({
            type: 'PATCH',
            url: `/tasks/${task.id}`,
            data: JSON.stringify({ completed: task.completed }),
            dataType: 'json',
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Task saved',
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });

                vent.trigger('task:completed:change', {
                    taskId: task.id,
                    completed: task.completed
                });
            }
        });
    }

    handleCreateItem(date, ev) {
        if (this.state.entityType) {
            this.createItem(this.state.entityType, date);
        } else {
            this.setState({
                popoverMenu: {
                    date: date,
                    top: ev.clientY,
                    left: ev.clientX,
                    items: [{
                        id: 'tasks',
                        title: 'Add Task',
                        icon: 'icon-checkmark3'
                    }, {
                        id: 'appointments',
                        title: 'Add Appointment',
                        icon: 'icon-calendar'
                    }]
                }
            });
        }
    }

    handlePopoverMenuClose(itemId) {
        const date = this.state.popoverMenu.date;

        this.setState({
            popoverMenu: null
        });

        if (!itemId) {
            return;
        }

        this.createItem(itemId, date);
    }

    createItem(entityType, date) {
        if (entityType === 'appointments') {
            this.props.onCreateAppointment(date);
        } else {
            this.props.onCreateTask(date);
        }
    }

    onStartResizing() {
        this.onResizing = true;

        const self = this;

        const tick = () => {
            self.handleWindowResize();

            if (self.onResizing) {
                self.resizingFrameId = window.requestAnimationFrame(tick);
            } else {
                window.cancelAnimationFrame(self.resizingFrameId);
            }
        }

        this.resizingFrameId = window.requestAnimationFrame(tick);
    }

    onEndResizing() {
        this.onResizing = false;
    }

    setEntityType(entityType) {
        this.setState({
            entityType: entityType
        });

        const self = this;

        _.defer(function() {
            self.activeViewComponent.buildCalendar(self.state.items.filter(i => i.type === entityType));
        });
    }

    handleShowAppointmentPopover(appointment, position) {
        if (appointment) {
            this.setState({
                appointmentPopover: {
                    appointment: appointment,
                    position: position,
                    parentBbox: this.calendarComponent.getBoundingClientRect()
                }
            });
        } else {
            this.setState({
                appointmentPopover: null
            });
        }
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        let SpanComponent = null;
        const kanbanView = this.state.entityType === 'tasks';

        switch(this.state.span) {
            case 'day':
                SpanComponent = kanbanView ? KanbanDay : Day;
                break;

            case 'week':
                SpanComponent = kanbanView ? KanbanWeek : Week;
                break;

            case 'month':
                SpanComponent = Month;
                break;

            case 'year':
                SpanComponent = Year;
                break;

            case 'schedule':
                SpanComponent = Schedule;
                break;
        }

        return (
            <div
                ref={(el) => this.calendarComponent = el}
                className={style.calendar}
            >
                <SpanComponent
                    ref={(el) => this.activeViewComponent = el}
                    date={this.currentDate}
                    entityType={this.state.entityType}
                    onEditTask={this.props.onEditTask}
                    onCreateItem={this.handleCreateItem.bind(this)}
                    onEditAppointment={this.props.onEditAppointment}
                    onToggleTaskCompleted={this.handleToggleTaskCompleted.bind(this)}
                    onDateSpanChange={this.props.onDateSpanChange}
                    onShowAppointmentPopover={this.handleShowAppointmentPopover.bind(this)}
                />
                {this.state.loading &&
                    <div className={style.loaderContainer}>
                        <div className={style.loader}><LoadingIndicator/></div>
                    </div>
                }

                {this.state.popoverMenu &&
                    <PopoverMenu
                        top={this.state.popoverMenu.top}
                        left={this.state.popoverMenu.left}
                        items={this.state.popoverMenu.items}
                        onClose={this.handlePopoverMenuClose.bind(this)}
                    />
                }

                {this.state.appointmentPopover &&
                    <AppointmentPopover
                        appointment={this.state.appointmentPopover.appointment}
                        position={this.state.appointmentPopover.position}
                        parentBbox={this.state.appointmentPopover.parentBbox}
                    />
                }
            </div>
        );
    }
}
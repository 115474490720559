import _ from 'underscore';
import $ from 'jquery';
import Marionette from 'Backbone.Marionette';
import Handlebars from 'handlebars';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import vent from 'js/vent';
import security from 'js/utils/security';
import Utilities from 'js/utils/utilities';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';

import IndividualReactView from 'js/react_views/individual/individual';
import CommunicationModel from 'js/models/communication';
import CommunicationCollection from 'js/collections/communications';
import IndividualsCollection from 'js/collections/contacts.js';
import IndividualModel from 'js/models/contact';
import TaskModel from 'js/models/task'
import OpportunitiesCollection from 'js/collections/organizations';
import RelatedFilesCollection from 'js/collections/related_files';
import GroupsCollection from 'js/collections/groups';
import ChecklistsCollection from 'js/collections/checklists.js';
import MessageBox from 'js/views/message_box';
import ModalRegion from 'js/views/base/modal-region';
import ItemPermissionsView from 'js/views/item_permissions';
import OrganizationView from 'js/react_views/OrganizationView/OrganizationView';
import OrganizationEditView from 'js/react_views/OrganizationView/edit';
import HistoryView from 'js/react_views/history/history';
import CDDCheckerView from 'js/react_views/cdd_checker/cdd_checker';

import BaseContainerView from 'js/react_containers/BaseContainerView';


const INDIVIDUALS_PER_PAGE = 20;
const DEALS_PER_PAGE = 20;
const FILES_PER_PAGE = 10;

const OrganizationContainerView = BaseContainerView.extend({
    regions: {
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        },
        historyRegion: {
            selector: '.history-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        cddCheckerRegion: {
            selector: '.cdd-checker',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        }
    },
    initialize: function(options) {
        BaseContainerView.prototype.initialize.apply(this, arguments);

        this.containerType = 'organizations';
        this.bindMethods();
        this.controller = options.controller;
        this.communicationCollection = new CommunicationCollection(this.model.get('communication'));

        var isNew = this.model.isNew();
        var self = this;

        this.editing = isNew;
        this.invalidFieldErrors = null;

        this.listenTo(this.model, 'sync', function() {
            this.processCustomFields(function() {
                self.render();
            });
        });
        this.listenTo(this.model, 'change', function() {
            this.render();
        });

        this.listenTo(vent, 'update-related-files', function() {
            this.fetchRelatedFiles();
        });

        this.listenTo(vent, 'update-checklist-progress', function() {
            this.fetchChecklists();
        });

        if (!isNew) {
            this.setLogoUrl();
            this.fetchRelatedIndividuals();
            this.fetchRelatedDeals();
            this.fetchRelatedFiles();
            this.fetchGroups();
            this.fetchChecklists();
        } else {
            this.processCustomFields(function() {
                self.render();
            });
        }

        this.archiveEmailAddress = 'archive@' + app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
    },
    bindMethods: function() {
        this.showPermissionView = this.showPermissionView.bind(this);
        this.handleCommunicationClick = this.handleCommunicationClick.bind(this);
        this.handleIndividualNavigate = this.handleIndividualNavigate.bind(this);
        this.handleIndividualsAdd = this.handleIndividualsAdd.bind(this);
        this.handleNewIndividual = this.handleNewIndividual.bind(this);
        this.handleDealNavigate = this.handleDealNavigate.bind(this);
        this.handleDealsPaging = this.handleDealsPaging.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleNewDeal = this.handleNewDeal.bind(this);
        this.handleFileAdd = this.handleFileAdd.bind(this);
        this.handleDnDFileUpload = this.handleDnDFileUpload.bind(this);
        this.handleFileDelete = this.handleFileDelete.bind(this);
        this.handleGroupSearch = this.handleGroupSearch.bind(this);
        this.handleGroupAdd = this.handleGroupAdd.bind(this);
        this.handleGroupRemove = this.handleGroupRemove.bind(this);
        this.handleGroupClick = this.handleGroupClick.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    },
    processIndividuals: function(individuals) {
        var plainIndividuals = individuals.toJSON();
        _.each(plainIndividuals, function(individual) {
            var id = individual.id;
            individual.primaryEmail = individuals.get(id).primaryEmail();
            individual.primaryPhone = individuals.get(id).primaryPhone();
        });
        return plainIndividuals;
    },
    fetchChecklists: function() {
        const self = this;

        app.shortTermCache.get(`/organizations/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            if (data.length > 0) {
                self.checklists = new ChecklistsCollection(data).models;
                self.render();
            }
        });
    },
    fetchRelatedIndividuals: function(start) {
        var individuals = new (IndividualsCollection.extend({
            urlRoot: () => `${this.model.url()}/individuals`,
            defaultSortOn: [{
                attribute: 'full_name',
                order: 'asc'
            }],
            defaultRows: INDIVIDUALS_PER_PAGE
        }))();
        this.updateRelatedData({ individuals: null });
        individuals.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                individuals: {
                    items: this.processIndividuals(individuals),
                    total: individuals.total,
                    start: individuals.start
                }
            });
        });
    },
    handleIndividualsPaging: function(direction) {
        var individuals = this.relatedData.individuals;
        if (!individuals) {
            return;
        }

        var start = individuals.start;
        var maxStart = Math.floor(individuals.total / INDIVIDUALS_PER_PAGE) * INDIVIDUALS_PER_PAGE;
        start = Math.min(Math.max(start + direction * INDIVIDUALS_PER_PAGE, 0), maxStart);
        this.fetchRelatedIndividuals(start);
    },
    fetchRelatedDeals: function(start) {
        var deals = new (OpportunitiesCollection.extend({
            urlRoot: () => `/organizations/${this.model.get('id')}/opportunities`,
            defaultRows: DEALS_PER_PAGE
        }))();
        this.updateRelatedData({ deals: null });
        deals.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                deals: {
                    items: deals.toJSON(),
                    total: deals.total,
                    start: deals.start
                }
            });
        });
    },
    handleDealsPaging: function(direction) {
        var deals = this.relatedData.deals;
        if (!deals) {
            return;
        }

        var start = deals.start;
        var maxStart = Math.floor(deals.total / DEALS_PER_PAGE) * DEALS_PER_PAGE;
        start = Math.min(Math.max(start + direction * DEALS_PER_PAGE, 0), maxStart);
        this.fetchRelatedDeals(start);
    },
    fetchRelatedFiles: function(start) {
        var files = new (RelatedFilesCollection.extend({
            urlRoot: () => `/organizations/${this.model.get('id')}/related_files`,
            defaultRows: FILES_PER_PAGE
        }))();
        this.updateRelatedData({ files: null });
        files.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                files: {
                    items: files.toJSON(),
                    total: files.total,
                    start: files.start
                }
            });
        });
    },
    handleFilesPaging: function(direction) {
        var files = this.relatedData.files;
        if (!files) {
            return;
        }

        var start = files.start;
        var maxStart = Math.floor(files.total / FILES_PER_PAGE) * FILES_PER_PAGE;
        start = Math.min(Math.max(start + direction * FILES_PER_PAGE, 0), maxStart);
        this.fetchRelatedFiles(start);
    },
    fetchGroups: function() {
        var Groups = new GroupsCollection();
        Groups.fetch({
            rows: -1,
            data: {
                by_static_contained_item: true,
                item_id: this.model.get('id'),
                element_type: 'organizations'
            }
        }).done(() => {
            this.updateRelatedData({ groups: Groups.toJSON() });
        });
    },
    setLogoUrl: function() {
        var communication = this.model.get('communication');
        var websiteField = _.findWhere(communication, {medium: 'website'});
        if (!websiteField) {
            this.updateRelatedData({
                logoUrl: null
            });
            return;
        }
        var websiteUrl = websiteField.value;
        if (websiteUrl) {
            // get the domain
            var domain = websiteUrl;

            // remove protocol
            domain = domain.replace(/(^\w+:|^)\/\//, '');

            // remove www
            var str = 'www.';
            var idx = domain.indexOf(str);

            if (idx === 0) {
                domain = domain.substring(idx + str.length);
            }

            // remove path and params
            _.each(['/','?'], function(s) {
                idx = domain.indexOf(s);

                if (idx !== -1) {
                    domain = domain.substring(0, idx);
                }
            });

            this.updateRelatedData({
                logoUrl: `https://logo.clearbit.com/${domain}?size=100`
            });
        } else {
            this.updateRelatedData({
                logoUrl: null
            });
        }
    },
    handleHistoryClick: function() {
        const historyView = new HistoryView({
            entityType: 'organizations',
            entityId: this.model.get('id'),
            entityName: this.model.get('name')
        });

        this.historyRegion.show(historyView);
    },
    showPermissionView: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
                this.render();
            });
        }
    },
    doSearchRequest: _.debounce(function(settings) {
        if (!settings) {
            return;
        }
        $.ajax(settings);
    }, 500),
    handleAutoSuggestSearch: function(urlPrefix, searchText, done) {
        var self = this;

        if (!searchText) {
            // this will cause responses to any outstanding search requests
            // to be ignored
            this.autoSuggestTimestamp = null;
            // call the debounced function with no settings to cancel
            // any previously scheduled request
            this.doSearchRequest();
            return;
        }

        // use a single timestamp for deal, file, group searches as we won't
        // search for all of them at the same time
        this.autoSuggestTimestamp = new Date().getTime();

        this.doSearchRequest({
            url: urlPrefix + searchText,
            // set up context for the response callback,
            // containing the timestamp at the time of making the request
            context: { timeStamp: this.autoSuggestTimestamp },
            success: function(data) {
                if (this.timeStamp !== self.autoSuggestTimestamp) {
                    // another search has been initiated before this one completed,
                    // ignore
                    return;
                }
                if (done) {
                    done(data);
                }
            }
        });
    },
    handleIndividualNavigate: function(individual) {
        this.options.parent.trigger('replace-view:individual:show', {
            id: individual.id
        });
    },
    handleIndividualsAdd: function(items) {
        var view = this;

        return $.ajax({
            url: '/organizations/' + this.model.get('id') + '/individuals',
            type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({ id: items[0].id }),
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: TextManager.getText('ID_ENTITY_ADDED_TO_ENTITY', ['${ID_INDIVIDUAL, capitalize}', '${ID_ORGANIZATION}']),
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
            },
            complete: function() {
                view.fetchRelatedIndividuals();
            }
        });
    },
    handleNewIndividual: function() {
        this.options.parent.trigger('replace-view:individual:new', {
            organization: this.model
        });
    },
    handleFileAdd: function(items) {
        var view = this;

        $.ajax({
            url: '/organizations/' + this.model.get('id') + '/related_files',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(_.map(items, (item) => ({ id: item.id }))),
            complete: function() {
                view.fetchRelatedFiles();
            }
        });
    },
    handleDnDFileUpload: function(files) {
        var formData = new FormData(),
            totalSize = 0,
            cumulativeSize = [],
            view = this;

        _.each(files, function(f) {
            formData.append('files', f);
            totalSize += f.size;
            cumulativeSize.push(totalSize);
        });

        $.ajax({
            type: 'POST',
            url: '/organizations/' + this.model.get('id') + '/related_files',
            contentType: false,
            processData: false,
            data: formData,
            xhr: function() {
                var xhr = new XMLHttpRequest();

                xhr.upload.addEventListener("progress", (event) => {
                    var numFilesUploaded = 0,
                        percentComplete;

                    if (event.lengthComputable) {
                        percentComplete = event.loaded / event.total * 100;

                        for (var i = cumulativeSize.length - 1; i >= 0; i--) {
                            if (event.loaded >= cumulativeSize[i]) {
                                numFilesUploaded = i + 1;
                                break;
                            }
                        }
                        view.updateRelatedData({ fileUpload: {
                            numFiles: files.length,
                            numFilesUploaded: numFilesUploaded,
                            percentComplete: percentComplete
                        } });
                    }
                }, false);

                return xhr;
            }
        }).done(function() {
            view.fetchRelatedFiles();
            view.updateRelatedData({ fileUpload: null });
        }).fail(function(xhr, textStatus, errorThrown) {
            vent.trigger('alert:show', {
                type: function() {
                    var filesText = files.length > 1 ? 'files' : 'file';
                    return {
                        message: Handlebars.compile('Error uploading {{filesText}}: {{errorMsg}}')({
                            filesText: filesText,
                            errorMsg: errorThrown
                        }),
                        timer: 3000,
                        classes: 'error'
                    };
                }
            });
            view.updateRelatedData({ fileUpload: null });
        });

        this.updateRelatedData({ fileUpload: {
            numFiles: files.length,
            numFilesUploaded: 0,
            percentComplete: 0
        } });
    },
    handleFileDelete: function(file) {
        var message = Handlebars.compile(
            'Are you sure you would like to remove {{fileName}}?'
        )({ fileName: file.name });
        var mbContent = {
            accept_is_negative: true,
            message: message,
            icon: 'icon-trashcan'
        };
        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                $.ajax({
                    url: '/organizations/' + self.model.get('id') + '/related_files/' + file.id,
                    type: 'DELETE',
                    complete: function() {
                        self.fetchRelatedFiles();
                        vent.trigger('update-checklist');
                    }
                });
            }
        );
    },
    handleDealNavigate: function(deal) {
        this.options.parent.trigger('replace-view:deal:show', { id: deal.id });
    },
    handleNewDeal: function() {
        this.options.parent.trigger('replace-view:deal:new', {
            organization: this.model
        });
    },
    handleGroupSearch: function(searchText, done) {
        this.handleAutoSuggestSearch(
            '/groups?element_type=organizations&group_type=static&search=',
            searchText,
            done
        );
    },
    handleGroupAdd: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'PUT',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupRemove: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'DELETE',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupClick: function(item) {
        window.location.href = '/#contacts/group/organizations/' + item.id;
    },
    handleLinkClick: function(item, uri) {
        if (item.type === 'individuals') {
            app.followLink(new IndividualModel(item), uri);
        }
    },
    handleCommunicationClick: function(commId, link) {
        var communication = this.communicationCollection.get(commId);
        var self = this;

        this.controller.followCommunicationLink(communication, link, function(activity) {
           if (AppConfig.getValue('on_phone_call.show_activity_note_popup', false) && communication.get('medium') === 'phone') {
                var content = {
                    message: 'Would you like to create an activity note?',
                    icon: 'icon-question'
                };

                var related = {
                    id: self.model.get('id'),
                    type: 'organizations',
                    related_type: 'organization',
                    name: self.model.get('name')
                };

                var showTaskPopup = function() {
                    content.message = 'Would you like to create a task?';

                    MessageBox.showYesNo(content, self, function() {
                        vent.trigger('quick:add:task',
                            new TaskModel({ related: related }),
                            {
                                onTaskCreated: function() {
                                    self.trigger('show-tab', 'tasks');
                                }
                            }
                        );
                    });
                }

                MessageBox.showYesNo(content, self, function() {
                    vent.trigger('quick:add:note', {
                        parentActivity: activity,
                        onNoteCreated: showTaskPopup,
                        onNoteCancelled: showTaskPopup,
                        related: related
                    });
                }, function() { // no
                    showTaskPopup();
                });
            }
        });
    },
    handleEdit: function() {
        this.trigger('replace-view:edit');
    },
    addCommunicationHrefs: function(communication) {
        return _.map(communication, function(item) {
            var model, output;
            if (item.medium === 'website') {
                output = _.clone(item);
                output.href = Utilities.httpUrl(output.value);
                return output;
            }
            if (_.contains(['email', 'phone'], item.medium)) {
                model = new CommunicationModel(item);
                return _.extend({}, item, { href: model.valueHref() });
            }
            if (item.medium != 'social') {
                return item;
            }
            if (item.name === 'linkedin') {
                output = _.clone(item);
                if (output.value.indexOf('linkedin.com') === -1) {
                    output.href = 'https://www.linkedin.com/in/' + output.value;
                } else if (output.value.indexOf('//') === -1) {
                    output.href = 'https://' + output.value;
                }
                return output;
            }
            if (item.name === 'twitter') {
                output = _.clone(item);
                output.href = 'https://twitter.com/' + output.value;
                return output;
            }
            if (item.name === 'facebook' || item.name === 'googleplus' || item.name === 'instagram') {
                output = _.clone(item);
                if (output.value.indexOf('//') === -1) {
                    output.href = 'https://' + output.value;
                }
                else {
                    output.href = output.value;
                }
                return output;
            }
        });
    },
    handleDelete: function() {
        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({ name: this.model.get('name') }),
            icon: 'icon-trashcan'
        };

        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                self.model.destroy();
                vent.trigger('deal:delete');
                vent.trigger('model:delete');
            }
        );
    },
    handleClose: function() {
        this.trigger('close-view');
        vent.trigger('organization:view:closed');
    },
    handleCancel: function(dataChanged) {
        if (!_.isEmpty(dataChanged)) {
            app.dirtyModelHandler.add(this.model.cid);
        }

        app.dirtyModelHandler.confirm(this, function() {
            app.dirtyModelHandler.remove(this.model.cid);
            if (!this.model.get('id')) {
                this.trigger('close-view');
            } else {
                this.invalidFieldErrors = null;
                this.setEditing(false, true);
            }
        });
    },
    handleSave: function(dataChanged) {
        this.invalidFieldErrors = null;

        var attrs = _.extend(_.clone(this.model.attributes), dataChanged);
        var errors = this.model.validate(attrs) || {};
        _.extend(errors, this.validateCustomFields(this.relatedData.processedCustomFields, attrs));

        if (_.isEmpty(errors)) {
            if (_.isEmpty(dataChanged)) {
                this.setEditing(false, true);
            } else {
                var self = this;

                this.model.save(dataChanged, {
                    patch: true,
                    wait: true,
                    success: function() {
                        app.dirtyModelHandler.remove(self.model.cid);
                        vent.trigger('contact:save');
                        vent.trigger('AppContent:contentChange');

                        self.fetchRelatedIndividuals();
                        self.fetchRelatedDeals();
                        self.fetchRelatedFiles();
                        self.fetchGroups();
                        self.processCustomFields(function() {
                            self.setLogoUrl();
                            self.setEditing(false, true);
                        })
                    },
                    error: function(model, response) {
                        self.triggerSaveErrorAlert(TextManager.parseText('${ID_ORGANIZATION, capitalize}'), response);
                    }
                });
            }
        } else {
            this.invalidFieldErrors = errors;
            this.render();
        }
    },
    handleCDDCheckerClick() {
        const view = new CDDCheckerView({
            id: this.model.get('id')
        });

        this.cddCheckerRegion.show(view);
    },
    setEditing: function(editing, andRender) {
        if (editing !== this.editing) {
            this.editing = editing;

            if (andRender) {
                this.render();
            }
        }
    },
    render: function() {
        var orgData = this.model.toJSON();

        orgData.communication = this.addCommunicationHrefs(orgData.communication);

        var isEditable = security.checkPermission('edit', {
            id: this.model.get('id'),
            permissions: this.model.get('permissions')
        });
        var isPermissionsEnabled = app.user.get('client').feature_tier !== 'starting' && app.user.get('client').permission_type !== 'rba';

        var onEdit = null;
        var onShowPermissionView = null;
        if (isEditable) {
            onEdit = this.handleEdit;
            if (isPermissionsEnabled) {
                onShowPermissionView = this.showPermissionView;
            }
        }

        // if it's a new model, we populate the custom fields with the default values
        if (this.model.isNew()) {
            var customFields = {};

            _.forEach((this.relatedData.processedCustomFields || []), function(group) {
                _.forEach(group.fields, function(field) {
                    if ('originalValue' in field) {
                        customFields[field.id] = field.originalValue;
                    }
                });
            });

            if (!_.isEmpty(customFields)) {
                orgData.custom_fields = customFields;
            }
        }

        ReactDOM.render(
            <div>
                {this.editing ? (
                    <OrganizationEditView
                        organization={orgData}
                        relatedData={this.relatedData}
                        invalidFieldErrors={this.invalidFieldErrors}
                        onSave={this.handleSave.bind(this)}
                        onCancel={this.handleCancel.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        onShowPermissionView={onShowPermissionView}
                    />
                ) : (
                    <OrganizationView
                        organization={orgData}
                        relatedData={this.relatedData}
                        isEditable={isEditable}
                        userId={app.user.get('id')}
                        onShowPermissionView={onShowPermissionView}
                        onHistoryClick={this.handleHistoryClick.bind(this)}
                        onCommunicationClick={this.handleCommunicationClick}
                        onClickNextIndividualPage={() => {this.handleIndividualsPaging(1)}}
                        onClickPrevIndividualPage={() => {this.handleIndividualsPaging(-1)}}
                        onIndividualNavigate={this.handleIndividualNavigate}
                        onIndividualsAdd={this.handleIndividualsAdd}
                        onNewIndividual={this.handleNewIndividual}
                        onDealNavigate={this.handleDealNavigate}
                        onClickNextDealPage={() => {this.handleDealsPaging(1)}}
                        onClickPrevDealPage={() => {this.handleDealsPaging(-1)}}
                        onLinkClick={this.handleLinkClick}
                        onNewDeal={this.handleNewDeal}
                        onClickNextFilePage={() => { this.handleFilesPaging(1); }}
                        onClickPrevFilePage={() => { this.handleFilesPaging(-1); }}
                        onFileAdd={this.handleFileAdd}
                        onDnDFileUpload={this.handleDnDFileUpload}
                        onFileDelete={this.handleFileDelete}
                        onGroupSearch={this.handleGroupSearch}
                        onGroupAdd={this.handleGroupAdd}
                        onGroupRemove={this.handleGroupRemove}
                        onGroupClick={this.handleGroupClick}
                        onCDDChecker={this.handleCDDCheckerClick.bind(this)}
                        archiveEmailAddress={this.archiveEmailAddress}
                        onEdit={onEdit}
                        onClose={this.handleClose}
                        checklists={this.checklists}
                    />
                )}
            </div>,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default OrganizationContainerView;

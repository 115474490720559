import _ from 'underscore';

import TextManager from 'app/text-manager';
import Utilities from 'js/utils/utilities';
import MessageBox from 'js/views/message_box';

export default class {
    constructor(parent) {
        this.parent = parent;
    }

    getTagItems(includeUnsubscribe, funnelId) {
        let essentialItems = [];

        if (includeUnsubscribe) {
            essentialItems = [{
                name: 'ESSENTIALS',
                isSectionTitle: true,
                title: true
            }, {
                name: 'Unsubscribe',
                tag: '${unsubscribe-url}',
                customInsertTag: '<a href=${unsubscribe-url}>Unsubscribe</a>',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'View in browser',
                tag: '${vib-url}',
                customInsertTag: '<a href=${vib-url}>View in browser</a>',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }];
        }

        let individualItems = [{
            name: 'INDIVIDUAL FIELDS',
            isSectionTitle: true,
            title: true
        }, {
            name: 'Full Name',
            tag: '${individual.name}',
            parse: function(model) {
                return model.get('full_name');
            }
        }, {
            name: 'First Name',
            tag: '${individual.first_name}',
            parse: function(model) {
                return model.get('first_name');
            }
        }, {
            name: 'Last Name',
            tag: '${individual.last_name}',
            parse: function(model) {
                return model.get('last_name');
            }
        }, {
            name: 'Email',
            tag: '${individual.email}',
            parse: function(model) {
                return _.find(model.get('communication'), function(item){ return item.medium === 'email'; }).value;
            }
        }, {
            name: 'Phone',
            tag: '${individual.phone}',
            parse: function(model) {
                return _.find(model.get('communication'), function(item){ return item.medium === 'phone'; }).value;
            }
        }, {
            name: TextManager.getText('ID_JOB_ROLE'),
            tag: '${individual.job_role}',
            parse: function(model) {
                return model.get('role');
            }
        }];

        let organizationItems = [{
            name: TextManager.parseText('${ID_ORGANIZATION, uppercase} FIELDS'),
            isSectionTitle: true,
            title: true
        }, {
            name: 'Name',
            tag: '${organization.name}',
            parse: function(model) {
                if (model.get('organization')) {
                    return model.get('organization').name;
                }
                else {
                    return '';
                }
            }
        }];

        let funnelItems = [];

        if (funnelId) {
            funnelItems = [{
                name: 'FUNNEL FIELDS',
                isSectionTitle: true,
                title: true
            }, {
                name: 'Name',
                tag: '${funnel.name}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Pretty Name',
                tag: '${funnel.integration.prettyName}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Address',
                tag: '${funnel.address}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Contact Details Email',
                tag: '${funnel.contact_details.email}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Contact Details Phone',
                tag: '${funnel.contact_details.phone}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Contact Details Social',
                tag: '${funnel.contact_details.social}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Opening Hours',
                tag: '${funnel.opening_hours}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Region Name',
                tag: '${funnel.region}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Max Price',
                tag: '${funnel.integration.maxPrice}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Min Price',
                tag: '${funnel.integration.minPrice}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }, {
                name: 'Summary',
                tag: '${funnel.integration.summary}',
                parse: function () {
                    return 'javascript:void(null);'
                }
            }];
        };

        for (const cf of app.globalData.customFieldsInfo.individualsArray) {
            individualItems.push({
                name: cf.name,
                tag: '${individual.custom.' + cf.id + '}',
                parse: function(model) {
                    return this.parseCustomField(cf, model);
                }
            });
        }

        for (const cf of app.globalData.customFieldsInfo.organizationsArray) {
            organizationItems.push({
                name: cf.name,
                tag: '${organization.custom.' + cf.id + '}',
                parse: function(model) {
                    return this.parseCustomField(cf, model);
                }
            });
        }

        return [...essentialItems, ...individualItems, ...organizationItems, ...funnelItems];
    }

    parseCustomField(cf, model) {
        const value = model.get(`custom_field.${cf.id}`);

        if (value === null || _.isUndefined(value)) {
            return null;
        }

        switch (cf.type) {
            case 'individual':
                return function(callback) {
                    $.get(`/individuals/${value}`, function(data) {
                        callback(data.full_name);
                    });
                };

            case 'organization':
                return function(callback) {
                    $.get(`/organizations/${value}`, function(data) {
                        callback(data.name);
                    });
                };

            case 'opportunity':
                return function(callback) {
                    $.get(`/opportunities/${value}`, function(data) {
                        callback(data.name);
                    });
                };

            case 'dropDown':
                return cf.value.options.find(o => o.id === value)?.value || null;

            case 'number':
                return Utilities.numberWithCommas(value);

            default:
                return value;
        }
    }

    manageTagInsertion(item, callback) {
        const splitSymbol = ',default=';

        if (item.customInsertTag) {
            callback(item.customInsertTag)
        }
        else {
            MessageBox.showOk({
                icon: 'icon-warning',
                message: `Leave blank or choose alternative when ${item.name} is missing`,
                accept_button_text: 'Continue'
            },
            this.parent,
            function(val) {
                let tag;

                if (val.toString().length > 0) {
                    tag = item.tag.slice(0, -1) + splitSymbol + val + '}';
                }
                else {
                    tag = item.tag;
                }

                callback(tag);
            }, {
                type: 'input'
            });
        }
    }
}

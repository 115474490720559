import Marionette from 'Backbone.Marionette';
import React from 'react';
import ReactDOM from 'react-dom';
import Handlebars from 'handlebars';
import _ from 'underscore';

import app from 'js/app';
import vent from 'js/vent';
import security from 'js/utils/security';
import Currency from 'js/utils/currency';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import dateFormat from 'js/utils/date-format';
import IpwTrigger from 'js/ipw_trigger';

import IndividualsCollection from 'js/collections/contacts';
import OrganizationModel from 'js/models/organization';
import IndividualModel from 'js/models/contact';
import RelatedFilesCollection from 'js/collections/related_files';
import GroupsCollection from 'js/collections/groups';
import ContentFilesCollection from 'js/collections/content_files';
import ChecklistsCollection from 'js/collections/checklists.js';
import TemplateGeneratorView from 'js/views/template_generator';
import YotiDocumentPreviewView from 'js/views/yoti_document_preview';
import HistoryView from 'js/react_views/history/history';
import YourkeysIframeDataView from 'js/react_views/yourkeys-iframe/yourkeys-iframe';
import YotiRecipientSelectView from 'js/react_views/yoti/yoti_recipient_select'

import DealView from 'js/react_views/DealView/DealView';
import DealEditView from 'js/react_views/DealView/edit';

import MessageBox from 'js/views/message_box';
import ModalRegion from 'js/views/base/modal-region';
import ItemPermissionsView from 'js/views/item_permissions';

import BaseContainerView from 'js/react_containers/BaseContainerView';

const INDIVIDUALS_PER_PAGE = 20;
const FILES_PER_PAGE = 10;

const DealContainerView = BaseContainerView.extend({
    regions: {
        aclRegion: {
            selector: '.acl-region',         // selector itself not used in ModalRegion
            regionType: ModalRegion
        },
        templateGeneratorRegion: {
            selector: '.template-generator', // selector itself not used in ModalRegion
            regionType: ModalRegion.extend({backdrop: 'static'})
        },
        historyRegion: {
            selector: '.history-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        documentPreviewRegion: {
            selector: '.document-preview-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: true})
        },
        yourkeysIframeRegion: {
            selector: '.yourkeys-iframe-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        },
        yotiRecipientsSelectRegion: {
            selector: '.yoti-recipient-select-region',
            regionType: ModalRegion.extend({backdrop: 'static'})
        }
    },
    initialize: function() {
        BaseContainerView.prototype.initialize.apply(this, arguments);

        this.containerType = 'deals';
        this.bindMethods();
        this.initDealStateMap();
        // inputData holds values to be saved on the deal model
        this.inputData = {};
        // renderedInputData holds user input to be rendered as feedback
        // when editing
        this.renderedInputData = {};

        var isNew = this.model.isNew();
        var self = this;

        this.editing = isNew;
        this.invalidFieldErrors = null;

        this.listenTo(this.model, 'sync', function() {
            this.processCustomFields(function() {
                self.render();
            });
        });
        this.listenTo(this.model, 'change', function() {
            this.render();
        });

        this.listenTo(vent, 'update-related-files', function() {
            this.fetchRelatedFiles();
        });

        this.listenTo(vent, 'update-checklist-progress', function() {
            this.fetchChecklists();
        });

        if (!isNew) {
            this.fetchRelatedIndividuals();
            this.fetchOrganizationIndividuals();
            this.fetchRelatedFiles();
            this.getTemplatedContent();
            this.fetchGroups();
            this.fetchChecklists();
        } else {
            this.processCustomFields(function() {
                self.render();
            });
        }

        this.archiveEmailAddress =
            this.model.get('abbreviation') + '.archive@' +
            app.user.get('client').short_id + '.' + TextManager.getText('ID_HOST');
    },
    initDealStateMap: function() {
        this.dealStateMap = {
            currencies: _.map(
                Currency.getUsedCurrenciesToSelect2Array(true),
                function(item) {
                    return { id: item.id, title: item.text };
                }
            ),
            statuses: [
                { id: 'none', title: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
                { id: 'none_upside', title: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
                { id: 'committed_downside', title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
                { id: 'committed', title: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
            ],
            funnels: _.map(
                this.options.funnels.toJSON(),
                function(funnel) {
                    return { id: funnel.id, title: funnel.name, permissions: funnel.permissions, integration_data: funnel.integration_data }
                }
            ),
            phases: _.object(_.map(
                this.options.phases,
                function(funnelPhases, id) {
                    return [
                        id, _.map(
                            funnelPhases,
                            function(value) {
                                return {
                                    id: value.id,
                                    title: value.text,
                                    default_weight: value.default_weight
                                };
                            }
                        )
                    ];
                }
            ))
        };
    },
    bindMethods: function() {
        this.handleIndividualNavigate = this.handleIndividualNavigate.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.handleIndividualsPaging = this.handleIndividualsPaging.bind(this);
        this.handleIndividualsAdd = this.handleIndividualsAdd.bind(this);
        this.handleIndividualsDelete = this.handleIndividualsDelete.bind(this);
        this.handleNewIndividual = this.handleNewIndividual.bind(this);
        this.handleFileSearch = this.handleFileSearch.bind(this);
        this.handleFileAdd = this.handleFileAdd.bind(this);
        this.handleDnDFileUpload = this.handleDnDFileUpload.bind(this);
        this.handleFileDelete = this.handleFileDelete.bind(this);
        this.handleFileYoti = this.handleFileYoti.bind(this);
        this.handleGroupSearch = this.handleGroupSearch.bind(this);
        this.handleGroupAdd = this.handleGroupAdd.bind(this);
        this.handleGroupRemove = this.handleGroupRemove.bind(this);
        this.handleGroupClick = this.handleGroupClick.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
        this.processInput = this.processInput.bind(this);
        this.handleCancelDataChange = this.handleCancelDataChange.bind(this);
        this.handleDataSave = this.handleDataSave.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.showPermissionView = this.showPermissionView.bind(this);
        this.handleElephantClick = this.handleElephantClick.bind(this);
        this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
        this.handleCreateNewDocument = this.handleCreateNewDocument.bind(this);
        this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
    },
    processIndividuals: function(individuals) {
        var plainIndividuals = individuals.toJSON();
        _.each(plainIndividuals, function(individual) {
            var id = individual.id;
            individual.primaryEmail = individuals.get(id).primaryEmail();
            individual.primaryPhone = individuals.get(id).primaryPhone();
        });
        return plainIndividuals;
    },
    fetchRelatedIndividuals: function(start) {
        this.updateRelatedData({ individuals: null });

        let url = null;

        if (AppConfig.getValue('use_pdrcm_improved_endpoints')) {
            url = `/pdcrm_opportunity_individuals/${this.model.get('id')}`;
        } else {
            const params = {
                start: start || 0,
                rows: INDIVIDUALS_PER_PAGE,
                order_by: 'full_name asc'
            };

            url = `/opportunities/${this.model.get('id')}/individuals?${$.param(params)}`
        }

        const self = this;

        $.get(url, function(data) {
            data.map(d => _.extend(d, {type: 'individuals'}));

            self.updateRelatedData({
                individuals: {
                    items: self.processIndividuals(new IndividualsCollection(data)),
                    total: data.length,
                    start: 0
                }
            });
        });
    },
    fetchOrganizationIndividuals: function(start) {
        var view = this;

        if (AppConfig.hasValue('deals.organization_individuals.custom_fetch')) {
            AppConfig.getValue('deals.organization_individuals.custom_fetch', null, {
                deal: this.model,
                callback: function(individuals) {
                    view.updateRelatedData({
                        organizationIndividuals: {
                            items: view.processIndividuals(new IndividualsCollection(individuals)),
                            total: individuals.length,
                            start: 0
                        }
                    });
                }
            });
        } else {
            var organization = this.model.get('organization');

            if (!organization) {
                return;
            }

            organization = new OrganizationModel(organization);
            var individuals = new (IndividualsCollection.extend({
                urlRoot: function() {
                    return organization.url() + '/individuals';
                },
                defaultSortOn: [{
                    attribute: 'full_name',
                    order: 'asc'
                }],
                defaultRows: INDIVIDUALS_PER_PAGE
            }))();
            this.updateRelatedData({ organizationIndividuals: null });
            individuals.fetch({
                start: start || 0
            }).done(function() {
                view.updateRelatedData({
                    organizationIndividuals: {
                        items: view.processIndividuals(individuals),
                        total: individuals.total,
                        start: individuals.start
                    }
                });
            });
        }
    },
    handleIndividualsPaging: function(individualsKey, direction) {
        var individuals = this.relatedData[individualsKey];
        if (!individuals) {
            return;
        }

        var start = individuals.start;
        var maxStart = Math.floor(individuals.total / INDIVIDUALS_PER_PAGE) * INDIVIDUALS_PER_PAGE;
        start = Math.min(Math.max(start + direction * INDIVIDUALS_PER_PAGE, 0), maxStart);
        if (individualsKey === 'individuals') {
            this.fetchRelatedIndividuals(start);
        }
        if (individualsKey === 'organizationIndividuals') {
            this.fetchOrganizationIndividuals(start);
        }
    },
    handleIndividualNavigate: function(individual) {
        window.location.href = `/#individuals/${individual.id}`;
    },
    fetchChecklists: function() {
        const self = this;

        app.shortTermCache.get(`/opportunities/${this.model.get('id')}/checklists`, {
            rows: -1,
            order_by: 'modified desc'
        }, function(data) {
            if (data.length > 0) {
                self.checklists = new ChecklistsCollection(data).models;
                self.render();
            }
        });
    },
    fetchRelatedFiles: function(start) {
        var files = new (RelatedFilesCollection.extend({
            urlRoot: () => `/opportunities/${this.model.get('id')}/related_files`,
            defaultRows: FILES_PER_PAGE
        }))();
        this.updateRelatedData({ files: null });
        files.fetch({
            start: start || 0
        }).done(() => {
            this.updateRelatedData({
                files: {
                    items: files.toJSON(),
                    total: files.total,
                    start: files.start
                }
            });
        });
    },
    handleFilesPaging: function(direction) {
        var files = this.relatedData.files;
        if (!files) {
            return;
        }

        var start = files.start;
        var maxStart = Math.floor(files.total / FILES_PER_PAGE) * FILES_PER_PAGE;
        start = Math.min(Math.max(start + direction * FILES_PER_PAGE, 0), maxStart);
        this.fetchRelatedFiles(start);
    },
    fetchGroups: function() {
        var Groups = new GroupsCollection();
        Groups.fetch({
            rows: -1,
            data: {
                by_static_contained_item: true,
                item_id: this.model.get('id'),
                element_type: 'opportunities'
            }
        }).done(() => {
            this.updateRelatedData({ groups: Groups.toJSON() });
        });
    },
    getTemplatedContent: function() {
        var templatedContent = new ContentFilesCollection(
            this.model.get('generated_files')
        );
        this.updateRelatedData({
            templatedContent: {
                items: templatedContent.toJSON(),
                total: templatedContent.total,
                start: templatedContent.start
            }
        });
    },
    isTemplatedDocumentCreationEnabled: function() {
        var featureTier = app.user.get('client').feature_tier;
        return featureTier !== 'starting' && featureTier !== 'growing';
    },
    handleCreateNewDocument: function() {
        if (this.isTemplatedDocumentCreationEnabled()) {
            var templateGenerator = new TemplateGeneratorView({deal: this.model});

            this.listenTo(templateGenerator, 'template-generator:generated_and_saved', function() {
                this.templateGeneratorRegion.reset();
                this.getTemplatedContent();
            });

            this.listenTo(templateGenerator, 'template-generator:close', function() {
                this.templateGeneratorRegion.reset();
            });

            this.templateGeneratorRegion.show(templateGenerator);
        }
    },
    handleDeleteDocument: function(file) {
        var view = this;

        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({ name: file.name }),
            icon: 'icon-trashcan'
        };

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                view.model.fetch({
                    success: function(model) {
                        var files = _.pluck(
                            _.reject(model.get('generated_files'), function(f) { return f.id === file.id; }),
                            'id'
                        );
                        model.save({ generated_files: files }, {
                            patch: true,
                            success: function(model) {
                                $.ajax({
                                    url: '/content_files/' + file.id,
                                    type: 'DELETE'
                                });
                                view.getTemplatedContent();
                            }
                        });
                    }
                });
            }
        );
    },
    handleLinkClick: function(item, uri) {
        var model;
        if (item.type === 'individuals') {
            model = new IndividualModel(item);
        }
        else {
            return;
        }
        app.followLink(model, uri);
    },
    doSearchRequest: _.debounce(function(settings) {
        if (!settings) {
            return;
        }
        $.ajax(settings);
    }, 500),
    handleAutoSuggestSearch: function(urlPrefix, searchText, done) {
        var self = this;

        if (!searchText) {
            // this will cause responses to any outstanding search requests
            // to be ignored
            this.autoSuggestTimestamp = null;
            // call the debounced function with no settings to cancel
            // any previously scheduled request
            this.doSearchRequest();
            return;
        }

        this.autoSuggestTimestamp = new Date().getTime();

        this.doSearchRequest({
            url: urlPrefix + searchText,
            // set up context for the response callback,
            // containing the timestamp at the time of making the request
            context: { timeStamp: this.autoSuggestTimestamp },
            success: function(data) {
                if (this.timeStamp !== self.autoSuggestTimestamp) {
                    // another search has been initiated before this one completed,
                    // ignore
                    return;
                }
                if (done) {
                    done(data);
                }
            }
        });
    },
    handleIndividualsAdd: function(items) {
        var view = this,
            ids = _.map(items, (item) => ({ id: item.id }));

        return $.ajax({
            url: '/opportunities/' + this.model.get('id') + '/individuals',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(ids),
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: TextManager.getText('ID_ENTITY_ADDED_TO_ENTITY', ['${ID_INDIVIDUAL, capitalize, pluralize=' + ids.length + '}', '${ID_DEAL}']),
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
            },
            complete: function() {
                view.fetchRelatedIndividuals();
            }
        });
    },
    handleIndividualsDelete: function(items) {
        var view = this,
            ids = _.map(items, (item) => ({ id: item.id }));

        return $.ajax({
            url: '/opportunities/' + this.model.get('id') + '/individuals',
            type: 'DELETE',
            contentType: 'application/json',
            data: JSON.stringify(ids),
            success: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: TextManager.getText('ID_ENTITY_REMOVED_FROM_ENTITY', ['${ID_INDIVIDUAL, capitalize, pluralize=' + ids.length + '}', '${ID_DEAL}']),
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
            },
            complete: function() {
                view.fetchRelatedIndividuals();
            }
        });
    },
    handleNewIndividual: function() {
        var organization = this.model.get('organization');
        if (organization) {
            organization = new OrganizationModel(organization);
        }
        this.options.parent.trigger('replace-view:individual:new', {
            organization: organization,
            fromOpportunityModel: this.model
        });
    },
    handleFileSearch: function(searchText, done) {
        this.handleAutoSuggestSearch('/content_files?search=', searchText, done);
    },
    handleFileAdd: function(items) {
        var view = this;

        $.ajax({
            url: '/opportunities/' + this.model.get('id') + '/related_files',
            type: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(_.map(items, (item) => ({ id: item.id }))),
            complete: function() {
                view.fetchRelatedFiles();
            }
        });
    },
    handleDnDFileUpload: function(files) {
        var formData = new FormData(),
            totalSize = 0,
            cumulativeSize = [],
            view = this;

        _.each(files, function(f) {
            formData.append('files', f);
            totalSize += f.size;
            cumulativeSize.push(totalSize);
        });

        $.ajax({
            type: 'POST',
            url: '/opportunities/' + this.model.get('id') + '/related_files',
            contentType: false,
            processData: false,
            data: formData,
            xhr: function() {
                var xhr = new XMLHttpRequest();

                xhr.upload.addEventListener("progress", (event) => {
                    var numFilesUploaded = 0,
                        percentComplete;

                    if (event.lengthComputable) {
                        percentComplete = event.loaded / event.total * 100;

                        for (var i = cumulativeSize.length - 1; i >= 0; i--) {
                            if (event.loaded >= cumulativeSize[i]) {
                                numFilesUploaded = i + 1;
                                break;
                            }
                        }
                        view.updateRelatedData({ fileUpload: {
                            numFiles: files.length,
                            numFilesUploaded: numFilesUploaded,
                            percentComplete: percentComplete
                        } });
                    }
                }, false);

                return xhr;
            }
        }).done(function() {
            view.fetchRelatedFiles();
            view.updateRelatedData({ fileUpload: null });
        }).fail(function(xhr, textStatus, errorThrown) {
            vent.trigger('alert:show', {
                type: function() {
                    var filesText = files.length > 1 ? 'files' : 'file';
                    return {
                        message: Handlebars.compile('Error uploading {{filesText}}: {{errorMsg}}')({
                            filesText: filesText,
                            errorMsg: errorThrown
                        }),
                        timer: 3000,
                        classes: 'error'
                    };
                }
            });
            view.updateRelatedData({ fileUpload: null });
        });

        this.updateRelatedData({ fileUpload: {
            numFiles: files.length,
            numFilesUploaded: 0,
            percentComplete: 0
        } });
    },
    handleFileDelete: function(file) {
        var message = Handlebars.compile(
            'Are you sure you would like to remove {{fileName}}?'
        )({ fileName: file.name });
        var mbContent = {
            accept_is_negative: true,
            message: message,
            icon: 'icon-trashcan'
        };
        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                $.ajax({
                    url: '/opportunities/' + self.model.get('id') + '/related_files/' + file.id,
                    type: 'DELETE',
                    complete: function() {
                        self.fetchRelatedFiles();
                        vent.trigger('update-checklist');
                    }
                });
            }
        );
    },
    handleFileYoti: function(files) {
        var self = this;

        const onYoti = function(files, dealView) {
            const onSave = function(contactData) {
                self.handleSendToYoti(files, dealView, contactData);
            }
    
            var yotiRecipientSelect = new YotiRecipientSelectView({
                onSave: onSave,
                dealView: dealView,
            });

            self.yotiRecipientsSelectRegion.show(yotiRecipientSelect);
        }
        
        var yotiDocumentPreview = new YotiDocumentPreviewView({files: files, onYoti: onYoti, dealView: this})
        this.documentPreviewRegion.show(yotiDocumentPreview);

        this.listenTo(yotiDocumentPreview, 'yoti-document-preview:close', function() {
            this.documentPreviewRegion.reset();
        });
    },
    handleSendToYoti: function (files, dealView, contactData) {
        // Array check
        if (files && !Array.isArray(files)) {
            files = [files];
        }

        var message = Handlebars.compile(
            `
                <b>Make sure</b> you have <b>reviewed</b> ${files.length > 1 ? 'these documents ' : 'this document'} before sending! <br /><br />
                Moreover, please make sure <b>the recipient(s) email details are correct</b> before sending.
                Should these details be incorrect, you will have to manually update
                them in YOTI and CRM before resending the invitation link. <br /><br />
                Submit <b>{{fileName}}</b> for YOTI signature collecting?
            `
        )({ fileName: files.map(file => file.name).join(', ') });
        var mbContent = {
            message: message,
            icon: 'icon-paperplane',
            accept_button_text: 'Proceed',
            cancel_button_text: 'Go Back'
        };

        // Improper extension check
        if (_.any(files, (file) => !['.pdf', '.doc', '.docx'].includes(file.ext))) {
            dealView.showErrorAlert('Supported file formats are .pdf, .doc and .docx.');
            return;
        }

        MessageBox.showYesNo(mbContent, this,
            function(value) { // yes
                var handle = MessageBox.showNoBtn({
                    icon: 'icon-paperplane',
                    message: 'Creating a YOTI envelope... ' +
                        "<div class='mb-wait-cont'></div>"
                    },
                    dealView.options.parent,
                    {
                        staticRegion: true
                    }
                );

                var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');
                completeMessage.text('Processing...');

                $.ajax({
                    type: 'POST',
                    url: '/create_yoti_envelope',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify({
                        opportunity_id: dealView.model.get('id'),
                        file_ids: files.map(file => file.id),
                        contact_data: contactData,
                    }),
                    success: function(data) {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'YOTI envelope creation has been started.',
                                    classes: 'success',
                                    timer: 3000
                                };
                            }
                        });
                    },
                    error: function(data) {
                        var detail = {};
                        try {
                            detail = JSON.parse(data.responseText).detail;
                        } catch (e) {
                            detail.message = 'An unknown exception occured. Please try again.'
                        }
                        dealView.showErrorAlert(detail.message);
                    },
                    complete: function() {
                        dealView.fetchRelatedFiles();
                        handle.reset();

                        if (dealView.documentPreviewRegion.currentView) {
                            dealView.documentPreviewRegion.currentView.trigger('yoti-document-preview:close')
                        }
                    }
                });
            },
            null,
            null,
            {
                // wait for the callback to finish before closing modal
                closeOnCallbackEnd: true,
                callbackLoadingText: 'Sending...',
                callbackLoadingColor: '#c1c4cd'
            }
        );
    },
    handleGroupSearch: function(searchText, done) {
        this.handleAutoSuggestSearch(
            '/groups?element_type=opportunities&group_type=static&search=',
            searchText,
            done
        );
    },
    handleGroupAdd: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'PUT',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupRemove: function(item) {
        var self = this;

        return $.ajax({
            url: '/groups/' + item.id + '/items/' + this.model.get('id'),
            type: 'DELETE',
            complete: function() {
                self.fetchGroups();
            }
        });
    },
    handleGroupClick: function(item) {
        window.location.href = '/#deals/groups/' + item.id;
    },
    listItemsByTitle: function(items, search, done) {
        if (search) {
            done(_.filter(items, function(item) {
                return item.title.match(new RegExp(search, 'i'));
            }));
        }
        else {
            done(items);
        }
    },
    dataProcessors: {
        weight: function(val) {
            val = parseFloat(val) || 0;

            if (val < 0) {
                val = 0;
            }
            else if (val > 1) {
                val = 1;
            }
            return val;
        },
        status: function(val) {
            return val;
        },
        phase_id: function(val) {
            return val;
        },
        funnel_id: function(val) {
            return val;
        },
        currency: function(val) {
            return val;
        },
        buckets: function(val) {
            return _.map(val, function(bucket) {
                var newBucket = _.clone(bucket);
                var value = newBucket.value;

                value = parseFloat(value);
                if (_.isNaN(value)) {
                    value = 0;
                }
                if (value < 0) {
                    value = 0;
                }

                newBucket.value = value;
                return newBucket;
            });
        }
    },
    handleDataChange: function(key, value, renderInput, skipRender) {
        this.inputData[key] = this.dataProcessors[key](value);
        if (renderInput) {
            this.renderedInputData[key] = value;
        }

        if (key === 'funnel_id') {
            this.handleDataChange(
                'phase_id',
                this.dealStateMap.phases[value][0].id,
                renderInput,
                true
            );
        }

        if (key === 'phase_id') {
            this.handleDataChange(
                'weight',
                _.findWhere(
                    this.dealStateMap.phases[this.inputData['funnel_id'] || this.model.get('funnel_id')],
                    { id: value }
                ).default_weight,
                renderInput,
                true
            );
        }

        if (!skipRender) {
            this.render();
        }
    },
    processInput: function() {
        this.renderedInputData = _.clone(this.inputData);
        if (this.renderedInputData.buckets) {
            this.renderedInputData.buckets = _.map(
                this.inputData.buckets,
                function(bucket) {
                    return _.clone(bucket);
                }
            );
        }
        this.render();
    },
    handleCancelDataChange: function() {
        this.inputData = {};
        this.renderedInputData = {};
        this.render();
    },
    handleDataSave: function(onError) {
        var view = this;
        var changes = _.object(_.compact(_.map(this.inputData, function(val, key) {
            if (view.model.get(key) === val) {
                return null;
            }
            return [key, val];
        })));

        if (_.keys(changes).length) {
            if(AppConfig.getValue('showIPWCampaignTriggerMessage', false) && 'phase_id' in changes && !('funnel_id' in changes)) {
                const phases = view.options.phases[view.model.get('funnel_id')];
                const currentPhase = phases.find(phase => phase.id === view.model.get('phase_id'));
                const newPhase = phases.find(phase => phase.id === changes.phase_id);

                if (currentPhase && newPhase && (newPhase.order > currentPhase.order)) {
                    var mbContent = {
                        message: Handlebars.compile('<p>Trigger IPW campaign?</p>'),
                        icon: 'icon-warning'
                    };

                    var updateFields = function(changes, triggerIpw) {
                        var ipwTrigger = new IpwTrigger();
                        var actualPhase = view.options.phases[view.model.get('funnel_id')].find(phase => phase.id === changes.phase_id);

                        ipwTrigger.getPopulatedIpwCustomFields(actualPhase.text, triggerIpw, null, function(fields) {
                            changes = _.extend(changes, fields);
                            view.saveData(changes);
                        });
                    }

                    MessageBox.showYesNo(mbContent, view,
                        function() { // Yes
                            updateFields(changes, true);
                        },
                        function() { // No
                            updateFields(changes, false);
                        }, null,
                        {
                            staticRegion: true
                        }
                    );
                } else {
                    view.saveData(changes);
                }
            } else{
                view.saveData(changes);
            }
        }
        else {
            view.inputData = {};
            view.renderedInputData = {};
            view.render();
        }
    },
    saveData: function(changes) {
        var view = this;

        this.model.save(changes, {
            extraFields: ['locations'],
            patch: true,
            validate: true,
            ignoreResponseAlertSet: { OpportunityPhaseChangeNotAllowed: true },
            params: {
                check_phase_change_allowed: true,
                check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
            },
            success: function() {
                if ('phase_id' in changes) {
                    vent.trigger('deal:save:phase', view.model);
                    view.updateRelatedData({ processedCustomFields: view.relatedData.processCustomFields });
                }
                vent.trigger('deal:save');
                view.inputData = {};
                view.renderedInputData = {};
                view.render();
            },
            error: function(model, response, settings) {
                // revert model attribute values that we were trying to save
                var attrsToRestore = _.object(_.map(
                    view.model.changedAttributes(),
                    function(value, key) {
                        return [key, view.model.previous(key)];
                    }
                ));
                view.model.set(attrsToRestore);

                var detail;
                try {
                    detail = JSON.parse(response.responseText).detail;
                }
                catch (e) {
                }

                if (detail && (detail.exception === 'OpportunityPhaseChangeNotAllowed' ||
                detail.exception === 'OpportunityPhaseGateNotClear')) {
                    vent.trigger('alert:hide', true);
                    vent.trigger('alert:show', {
                        type: function() {
                            var message = detail.message
                            if (detail.exception === 'OpportunityPhaseGateNotClear'){
                                message = message.replace('opportunity', TextManager.getText('ID_DEAL'))
                            }
                            return {
                                message: message,
                                classes: 'load-error error',
                                timer: 3000
                            };
                        }
                    });
                }
            }
        });
    },
    handleEdit: function() {
        this.trigger('replace-view:edit');
    },
    handleHistoryClick: function() {
        const historyView = new HistoryView({
            entityType: 'opportunities',
            entityId: this.model.get('id'),
            entityName: this.model.get('name')
        });

        this.historyRegion.show(historyView);
    },
    handleYourKeysIframeDataClick: function(event, onCloseButtonClickFunction) {
        /*
            event is dummy variable
            in YourKeysDealOpenButton - onClick={openYourkeysIframe ? this.props.onShowIframeDataClick : this.openYourkeysUrl.bind(this)}
            the click event is being sent as a variable to this function
        */
        const yourkeysIframeDataView = new YourkeysIframeDataView({
            model: this.model,
            onCloseButtonClick: onCloseButtonClickFunction,
            loadDevelopment: false,
        });

        this.yourkeysIframeRegion.show(yourkeysIframeDataView);
    },
    showPermissionView: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
                this.render();
            });
        }
    },
    handleDuplicateClick: function() {
        const duplicate = () => {
            var self = this;

            $.ajax({
                type: 'POST',
                url: `${this.model.url()}?clone`,
                success: function(deal) {
                    self.options.parent.trigger('replace-view:deal:show', {
                        id: deal.id,
                        section: self.options.section
                    });

                    vent.trigger('deal:save');
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: TextManager.parseText('${ID_DEAL, capitalize} has been duplicated'),
                                timer: 3000,
                                classes: 'success'
                            };
                        }
                    });
                }
            });
        }

        const content = {
            message: `Are you sure you would like to clone ${this.model.get('name')}? The process is going to create an identical copy of your original entity.`,
            icon: 'icon-question'
        };

        MessageBox.showYesNo(content, this, function() {
            duplicate();
        });
    },
    handleElephantClick: function() {
        var model = this.model,
            model_id = model.get('id'),
            newVal = !model.get('is_favorite');

        // optimistically set new value on the model
        // so the UI feels responsive
        model.set('is_favorite', newVal);

        $.ajax({
            url: app.user.url() + '/favorite_opportunities/items/' + model_id,
            type: newVal ? 'PUT' : 'DELETE'
        }).done(function() {
            if (newVal) {
                vent.trigger('deal:elephant:added');
            }
            else {
                vent.trigger('deal:elephant:removed');
            }
        }).fail(function() {
            // setting favourite status failed, set the previous value back on the model
            model.set('is_favorite', !newVal);
        });
    },
    handleClose: function() {
        this.trigger('close-view');
        vent.trigger('deal:view:closed');
    },
    handleOrganizationClick: function() {
        this.trigger('go-to-organization');
    },
    handleCancel: function(dataChanged) {
        if (!_.isEmpty(dataChanged)) {
            app.dirtyModelHandler.add(this.model.cid);
        }

        app.dirtyModelHandler.confirm(this, function() {
            app.dirtyModelHandler.remove(this.model.cid);
            if (!this.model.get('id')) {
                this.trigger('close-view');
            } else {
                this.invalidFieldErrors = null;
                this.setEditing(false, true);
            }

            vent.trigger('deal:view:cancel');
        });
    },
    handleSave: function(dataChanged) {
        this.invalidFieldErrors = null;

        var attrs = _.extend(_.clone(this.model.attributes), dataChanged);
        var errors = this.model.validate(attrs) || {};
        _.extend(errors, this.validateCustomFields(this.relatedData.processedCustomFields, attrs));

        if (_.isEmpty(errors)) {
            if (_.isEmpty(dataChanged)) {
                this.setEditing(false, true);
            } else {
                var self = this;
                var oldData = _.clone(this.model.attributes);

                this.model.save(dataChanged, {
                    extraFields: ['locations'],
                    patch: true,
                    validate: true,
                    ignoreResponseAlertSet: {OpportunityPhaseChangeNotAllowed: true},
                    params: {
                        check_phase_change_allowed: true,
                        check_phase_gate: _.contains(app.user.get('preferences').lab_flags, 'SAL-4398')
                    },
                    wait: true,
                    success: function() {
                        if ('phase_id' in dataChanged) {
                            vent.trigger('deal:save:phase', self.model);
                        }

                        app.dirtyModelHandler.remove(self.model.cid);
                        vent.trigger('deal:save');
                        vent.trigger('AppContent:contentChange');

                        self.fetchRelatedIndividuals();
                        self.fetchOrganizationIndividuals();
                        self.fetchRelatedFiles();
                        self.getTemplatedContent();
                        self.fetchGroups();
                        self.processCustomFields(function() {
                            self.setEditing(false, true);
                        })

                        AppConfig.getValue('conversations.on_deal_update', null, {
                            section: self.options.section,
                            old: oldData,
                            new: self.model.attributes
                        });
                    },
                    error: function(model, response) {
                        self.triggerSaveErrorAlert(TextManager.parseText('${ID_DEAL, capitalize}'), response);
                    }
                });
            }
        } else {
            this.invalidFieldErrors = errors;
            this.render();
        }
    },
    handleDelete: function() {
        const canDelete = AppConfig.getValue('deals.handle_delete', true, {
            deal: this.model,
            this: this,
        });
        
        if ( ! canDelete ) return;

        var mbContent = {
            accept_is_negative: true,
            message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({ name: this.model.get('name') }),
            icon: 'icon-trashcan'
        };

        var self = this;

        MessageBox.showYesNo(mbContent, this,
            function() { // yes
                self.model.destroy();
                vent.trigger('deal:delete');
                vent.trigger('model:delete');
            }
        );
    },
    setEditing: function(editing, andRender) {
        if (editing !== this.editing) {
            this.editing = editing;

            if (andRender) {
                this.render();
            }
        }
    },
    showErrorAlert: function(message, classes = 'load-error error', timer = 3000) {
        vent.trigger('alert:show', {
            type: function() {
                return {
                    message: message,
                    classes: classes,
                    timer: timer
                };
            }
        });
    },
    render: function() {
        var dealData = this.model.toJSON();

        var isEditable = security.checkPermission('edit', {
            id: this.model.get('id'),
            permissions: this.model.get('permissions')
        });
        var featureTier = app.user.get('client').feature_tier;
        var isPermissionsEnabled = featureTier !== 'starting' && app.user.get('client').permission_type !== 'rba';

        var onEdit = null;
        var onShowPermissionView = null;
        if (isEditable) {
            onEdit = this.handleEdit;
            if (isPermissionsEnabled) {
                onShowPermissionView = this.showPermissionView;
            }
        }

        var preferences = app.user.get('preferences');
        var dashboard = preferences && preferences['default_dashboard'];
        var isManagerUser = (dashboard === 'ceo' || dashboard === 'sales_manager');

        // if it's a new model, we populate some fields and the custom fields with the default values
        if (this.model.isNew()) {
            var defaultFunnelId = this.options.funnels.getLastVisitedFunnelId();
            var phase = this.options.phases[defaultFunnelId][0];

            if (dealData.organization && dealData.organization.attributes) {
                dealData.organization = dealData.organization.toJSON();
            }

            dealData.buckets = this.options.buckets;
            dealData.currency = app.user.get('client')['default_currency'];
            dealData.funnel_id = defaultFunnelId;
            dealData.phase_id = phase.id;
            dealData.status = 'none';
            dealData.weight = phase.default_weight

            if (this.options.initialData) {
                var initialData = this.options.initialData;

                if (initialData.name) {
                    dealData.name = this.options.initialData.name;
                }

                if (initialData.organization) {
                    dealData.organization = initialData.organization
                }

                if (initialData.funnelId) {
                    dealData.funnel_id = initialize.funnelId;
                }

                if (initialData.phaseId) {
                    dealData.phase_id = initialize.phaseId;
                } else if (initialData.funnelId) {
                    dealData.phase_id = this.options.phases[initialData.funnelId][0].id;
                }
            }

            var customFields = {};

            _.forEach((this.relatedData.processedCustomFields || []), function(group) {
                _.forEach(group.fields, function(field) {
                    if ('originalValue' in field) {
                        customFields[field.id] = field.originalValue;
                    }
                });
            });

            if (!_.isEmpty(customFields)) {
                for (const cfid in customFields) {
                    dealData[`custom_field.${cfid}`] = customFields[cfid];
                }
            }
        }

        ReactDOM.render(
            <div>
                {this.editing ? (
                    <DealEditView
                        section={this.options.section}
                        deal={dealData}
                        relatedData={this.relatedData}
                        phases={this.options.phases}
                        funnels={this.options.funnels}
                        parent={this.options.parent}
                        invalidFieldErrors={this.invalidFieldErrors}
                        onSave={this.handleSave.bind(this)}
                        onCancel={this.handleCancel.bind(this)}
                        onDelete={this.handleDelete.bind(this)}
                        onShowPermissionView={onShowPermissionView}
                    />
                ) : (
                    <DealView
                        section={this.options.section}
                        deal={dealData}
                        dealStateMap={this.dealStateMap}
                        userId={app.user.get('id')}
                        isManagerUser={isManagerUser}
                        inputData={this.renderedInputData}
                        parent={this.options.parent}
                        processedInputData={this.inputData}
                        processInput={this.processInput}
                        onDataChange={this.handleDataChange}
                        onCancelDataChange={this.handleCancelDataChange}
                        onDataSave={this.handleDataSave}
                        relatedData={this.relatedData}
                        isEditable={isEditable}
                        onEdit={onEdit}
                        onShowPermissionView={onShowPermissionView}
                        onElephantClick={this.handleElephantClick}
                        onDuplicateClick={this.handleDuplicateClick}
                        onHistoryClick={this.handleHistoryClick.bind(this)}
                        onShowIframeDataClick={this.handleYourKeysIframeDataClick.bind(this)}
                        onOrganizationClick={this.handleOrganizationClick}
                        onIndividualNavigate={this.handleIndividualNavigate}
                        onLinkClick={this.handleLinkClick}
                        onIndividualsPaging={this.handleIndividualsPaging}
                        onIndividualsAdd={this.handleIndividualsAdd}
                        onIndividualsDelete={this.handleIndividualsDelete}
                        onNewIndividual={this.handleNewIndividual}
                        onClickNextFilePage={() => { this.handleFilesPaging(1); }}
                        onClickPrevFilePage={() => { this.handleFilesPaging(-1); }}
                        onFileSearch={this.handleFileSearch}
                        onFileAdd={this.handleFileAdd}
                        onDnDFileUpload={this.handleDnDFileUpload}
                        onFileDelete={this.handleFileDelete}
                        onFileYoti={this.handleFileYoti}
                        onCreateNewDocument={
                            this.isTemplatedDocumentCreationEnabled() &&
                            isEditable &&
                            this.handleCreateNewDocument
                        }
                        onDeleteDocument={isEditable && this.handleDeleteDocument}
                        onGroupSearch={this.handleGroupSearch}
                        onGroupAdd={this.handleGroupAdd}
                        onGroupRemove={this.handleGroupRemove}
                        onGroupClick={this.handleGroupClick}
                        archiveEmailAddress={this.archiveEmailAddress}
                        onClose={this.handleClose}
                        checklists={this.checklists}
                    />
                )}
            </div>,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default DealContainerView;

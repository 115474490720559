import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import mergeTemplate from 'templates/merge/merge.handlebars'
import photoFieldTemplate from 'templates/merge/photo_field.handlebars'
import defaultFieldTemplate from 'templates/merge/default_field.handlebars'
import CustomFieldsCollection from 'js/collections/custom_fields'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import TextManager from 'app/text-manager'


var fields = {
    individual: [
        {
            id: 'photo_url',
            name: 'Photo',
            type: 'photo_url'
        },
        {
            id: 'first_name',
            name: 'First name',
            type: 'text'
        },
        {
            id: 'last_name',
            name: 'Last name',
            type: 'text'
        },
        {
            id: 'role',
            name: TextManager.getText('ID_JOB_ROLE'),
            type: 'text'
        },
        {
            id: 'organization',
            name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
            type: 'organization'
        },
        {
            id: 'linkedin',
            name: 'LinkedIn',
            type: 'communication'
        },
        {
            id: 'twitter',
            name: 'Twitter',
            type: 'twitter'
        },
        {
            id: 'facebook',
            name: 'Facebook',
            type: 'communication'
        },
        /*{
            id: 'googleplus',
            name: 'Google+',
            type: 'communication'
        },*/
        {
            id: 'instagram',
            name: 'Instagram',
            type: 'communication'
        },
        {
            id: 'comments',
            name: TextManager.getText('ID_MORE_INFO'),
            type: 'text'
        },
        {
            id: 'source',
            name: 'Source',
            type: 'source'
        },
        {
            id: 'owner',
            name: 'Owner',
            type: 'user'
        }
    ],
    organization: [
        {
            id: 'name',
            name: 'Name',
            type: 'text'
        },
        {
            id: 'website',
            name: 'Website',
            type: 'communication'
        },
        {
            id: 'linkedin',
            name: 'LinkedIn',
            type: 'communication'
        },
        {
            id: 'twitter',
            name: 'Twitter',
            type: 'twitter'
        },
        {
            id: 'facebook',
            name: 'Facebook',
            type: 'communication'
        },
        /*{
            id: 'googleplus',
            name: 'Google+',
            type: 'communication'
        },*/
        {
            id: 'instagram',
            name: 'Instagram',
            type: 'communication'
        },
        {
            id: 'comments',
            name: TextManager.getText('ID_MORE_INFO'),
            type: 'text'
        },
        {
            id: 'owner',
            name: 'Owner',
            type: 'user'
        }
    ]
};

var formatters = {
    'text': function(data) {
        return data;
    },
    'paragraph': function(data) {
        return data;
    },
    'date': function(data) {
        return data ? dateFormat.entityInformationFormat(data) : '';
    },
    'currency': function(data) {
        return data;
    },
    'photo_url': function(data) {
        return data;
    },
    'number': function(data) {
        return data ? data.toString() : '';
    },
    'product': function(data) {
        return data ? data.toString() : '';
    },
    'communication': function(data) {
        return data && data.value ? data.value : '';
    },
    'twitter': function(data) {
        return data && data.value ? '@' + data.value : '';
    },
    'individual': function(data) {
        return data && data.full_name ? data.full_name : '';
    },
    'organization': function(data) {
        return data && data.name ? data.name : '';
    },
    'opportunity': function(data) {
        return data && data.name ? data.name : '';
    },
    'user': function(data) {
        return data && data.name ? data.name : '';
    },
    'checkbox': function(data) {
        return data ? 'on' : 'off';
    }
};

var updateFormatters = {
};

var DefaultFieldView = Marionette.ItemView.extend({
    className: function() {
        return 'merge-prop prop-' + this.model.get('values').length + '-val';
    },
    template: Handlebars.compile(defaultFieldTemplate),
    events: {
        'click .merge-val': "select"
    },
    onRender: function() {
        this.$el.find('.merge-val').first().addClass('selected');
    },
    select: function(ev) {
        var selected = $(ev.target);

        this.model.set('selected', selected.data('index'));
        selected.siblings().removeClass('selected');
        selected.addClass('selected');
    }
});

var PhotoFieldView = DefaultFieldView.extend({
    template: Handlebars.compile(photoFieldTemplate)
});

var FieldCollectionView = Marionette.CollectionView.extend({
    getItemView: function(item) {
        var type = item.get('type');
        if (type && type === 'photo_url') {
            return PhotoFieldView;
        }
        else {
            return DefaultFieldView;
        }
    }
});

export default Marionette.Layout.extend({
    className: 'merge-view',
    template: Handlebars.compile(mergeTemplate),
    subTitleTemplate: Handlebars.compile(
        'There are {{conflictsCount}} conflicts. Please choose your preferences below:'
    ),
    subTitleSingleConflictTemplate: Handlebars.compile(
        'There is one conflict. Please choose your preference below:'
    ),
    subTitleNoConflictsTemplate: Handlebars.compile(
        'The {{elementCount}} records are identical. Please click Merge to proceed.'
    ),
    templateHelpers: function() {
        var elementType = {
            'individual': TextManager.parseText('${ID_INDIVIDUAL, plural}'),
            'organization': TextManager.parseText('${ID_ORGANIZATION, plural}')
        };

        return {
            elementType: elementType[this.options.elementType],
            elementCount: this.options.item_ids.length
        };
    },
    ui: {
        mergeSubTitle: '.merge-description',
        fieldsBox: '.merge-properties',
        loadingOverlay: '.loading-overlay',
        inprogressOverlay: '.inprogress-overlay'
    },
    events: {
        'click .mb-accept': function(ev) {
            var view = this;

            ev.preventDefault();

            MessageBox.showYesNo(
                {
                    message: '<p style="font-size: 18px; color: black; margin-bottom: 10px;"> ' +
                             'Are you sure you want to merge these records?</p>' +
                             '<p>This operation cannot be reversed</p>',
                    icon: 'icon-warning'
                },
                view,
                function () {
                    view.merge();
                }
            );
        },
        'click .mb-cancel': function(ev) {
            ev.preventDefault();
            this.close();
        },
    },
    conflictsData: [],
    onRender: function() {
        var view = this;

        this.fetchData().done(function() {
            view.processConflicts();

            view.ui.loadingOverlay.hide();

            var fieldsView;

            if (view.conflicts.length) {
                view.ui.mergeSubTitle.text(
                    view.conflicts.length > 1 ?
                        view.subTitleTemplate({
                            conflictsCount: view.conflicts.length
                        })
                    :
                        view.subTitleSingleConflictTemplate({})
                );

                fieldsView = new FieldCollectionView({
                    collection: view.conflicts,
                    el: view.ui.fieldsBox
                });
                fieldsView.render();
                view.$el.find('.content-container').nanoScroller();
            }
            else {
                view.ui.mergeSubTitle.text(view.subTitleNoConflictsTemplate({
                    elementCount: view.options.item_ids.length
                }));
            }
        });
    },
    fetchData: function() {
        var item_ids = this.options.item_ids,
            reqBody = {},
            view = this;

        reqBody[this.options.elementType + '_ids'] = item_ids;

        var conflictsReq = $.ajax({
            type: 'POST',
            url: '/' + this.options.elementType + 's/' + item_ids[0] + '/merge/check_conflicts',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(reqBody)
        });

        conflictsReq.done(function(data) {
            view.conflictsData = data.conflicts;
        });

        this.customFields = new CustomFieldsCollection();
        var customFieldsReq = this.customFields.fetch({
            filterBy: [{
                attribute: 'view',
                value: this.options.elementType + 's'
            }]
        });

        return $.when(conflictsReq, customFieldsReq);
    },
    processConflicts: function() {
        var view = this;

        var baseConflicts = _.map(fields[view.options.elementType], function(field) {
            var values = view.conflictsData[field.id];

            if (!values) {
                return null;
            }

            return new Backbone.Model({
                key: field.id,
                type: field.type,
                label: field.name,
                selected: 0,
                values: _.map(values, function(val, idx) {
                    var label = val,
                        value = val;

                    if (formatters[field.type]) {
                        label = formatters[field.type](val);
                    }

                    if (updateFormatters[field.type]) {
                        value = updateFormatters[field.type](val);
                    }

                    return { value: value, label: label, index: idx };
                })
            });
        });

        var conflicts = new Backbone.Collection(_.compact(baseConflicts));

        var customConflicts = view.customFields.map(function(field) {
            var values = view.conflictsData['custom_field.' + field.id];

            if (!values) {
                return null;
            }

            return new Backbone.Model({
                key: 'custom_field.' + field.id,
                type: field.get('type'),
                label: field.get('name'),
                selected: 0,
                values: _.map(values, function(val, idx) {
                    var label = val,
                        value = val;

                    if (field.get('type') === 'dropDown') {
                        var params = field.get('params') || {}

                        if(params && params.multiSelect && val.indexOf(',') > 0){
                            const values = []
                            val.split(',').map(opt => {
                                values.push(_.findWhere(field.get('options'), { id: opt }).value);
                            })

                            label = values.join(',')
                        }else{
                            label = _.findWhere(field.get('options'), { id: val }).value;
                        }
                    }
                    else if (formatters[field.get('type')]) {
                        label = formatters[field.get('type')](val);
                    }

                    if (updateFormatters[field.get('type')]) {
                        value = updateFormatters[field.get('type')](val);
                    }

                    return { value: value, label: label, index: idx };
                })
            });
        });

        conflicts.add(_.compact(customConflicts));

        this.conflicts = conflicts;
    },
    onShow: function() {
        this.$el.parent().addClass('contact-merge-modal');
    },
    merge: function () {
        var patchBody = {
                data: {}
            },
            view = this;

        patchBody[this.options.elementType + '_ids'] = this.options.item_ids;

        this.conflicts.each(function(item) {
            var type = item.get('type'),
                key = item.get('key'),
                values = item.get('values'),
                value = values[item.get('selected')].value;

            if (type && updateFormatters[type]) {
                value = updateFormatters[type](value);
            }

            patchBody.data[key] = value;
        });

        this.ui.inprogressOverlay.show();

        $.ajax({
            type: 'PATCH',
            url: '/' + this.options.elementType + 's/' + this.options.item_ids[0] + '/merge',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(patchBody)
        }).done(function(data) {
            view.ui.inprogressOverlay.hide();
            view.close();
            view.trigger('merge:done', data);
        }).fail(function() {
            view.ui.inprogressOverlay.hide();
            MessageBox.showOk(
                {
                    message: '<p style="font-size: 18px; color: black; margin-bottom: 10px;"> ' +
                             'We were unable to merge these records</p>' +
                             '<p>Please contact ' + TextManager.getText('ID_SUPPLIER') + ' to&nbsp;resolve this issue</p>',
                    icon: 'icon-warning'
                },
                view,
                null,
                null,
                'error'
            );
        });
    }
});

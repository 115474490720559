import app from 'js/app'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import backboneSelect2 from 'js/widgets/backbone-select2.js'
import TextManager from 'app/text-manager'
import vent from 'js/vent'

import csvTotalSettingsTemplate from 'templates/settings/data_export_setings.handlebars'

export default Marionette.Layout.extend({
    className: 'edit-csv-total-logo-settings',
    template: Handlebars.compile(csvTotalSettingsTemplate),
    templateHelpers: function() {
        return {
            contactLabel: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
            dealLabel: TextManager.parseText('${ID_DEAL, capitalize}'),
        }
    },
    ui: {
        dealFields: '#deal-fields',
        individualFields: '#individual-fields',
    },
    events: {
        'click .save': function(ev) {
            var clientPreferences = app.user.get('client').preferences || {};

            var dataExportOptions = {
                opportunities: this.dealFieldsSelected,
                individuals: this.individualFieldsSelected,
            };

            clientPreferences.dataExportOptions = JSON.stringify(dataExportOptions);

            $.ajax({
                type: 'PATCH',
                url: '/clients/1',
                dataType: 'json',
                data: JSON.stringify({
                    preferences: clientPreferences
                }),
                success: function(data) {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                },
                error: function() {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error saving preferences',
                                classes: 'saved error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
    },
    initialize: function() {
        this.dealFieldsSelected = [];
        this.individualFieldsSelected = [];

        var clientPreferences = app.user.get('client').preferences || {};
        var dataExportOptions = clientPreferences.dataExportOptions ? JSON.parse(clientPreferences.dataExportOptions) : {};

        if (dataExportOptions) {
            if (dataExportOptions.opportunities) {
                this.dealFieldsSelected = this.processFieldValues(dataExportOptions.opportunities);
            }

            if (dataExportOptions.individuals) {
                this.individualFieldsSelected = this.processFieldValues(dataExportOptions.individuals);
            }
        }
    },
    onRender: function() {
        const dealCustomFields = app.globalData.customFieldsInfo.deals;
        const individualCustomFields = app.globalData.customFieldsInfo.individuals;

        var individualFields = [];
        var dealFields = [
            {
                id: 'default_value',
                name: app.user.get('client')['default_currency'] + ' Value'
            },
            {
                id: 'weighted_value',
                name: 'Weighted ' + app.user.get('client')['default_currency'] + ' Value'
            },            
        ];

        for (const cfId in dealCustomFields) {
            if (dealCustomFields[cfId].type === 'number') {
                dealFields.push({
                    id: cfId,
                    name: dealCustomFields[cfId].name
                });
            }
        }

        for (const cfId in individualCustomFields) {
            if (individualCustomFields[cfId].type === 'number') {
                individualFields.push({
                    id: cfId,
                    name: individualCustomFields[cfId].name
                });
            }
        }

        this.dealFields = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.dealFields,
            text: 'name',
            data: dealFields,
            options: {
                placeholder: 'Select Fields',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        this.indvidualFields = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.individualFields,
            text: 'name',
            data: individualFields,
            options: {
                placeholder: 'Select Fields',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });

        if (this.dealFieldsSelected.length > 0) {
            this.dealFields.setValue(this.dealFieldsSelected);
        }

        if (this.individualFieldsSelected.length > 0) {
            this.indvidualFields.setValue(this.individualFieldsSelected);
        }

        this.listenTo(this.dealFields, 'change', function(items) {
            this.dealFieldsSelected = this.processFieldValuesSelected(items);
        });

        this.listenTo(this.indvidualFields, 'change', function(items) {
            this.individualFieldsSelected = this.processFieldValuesSelected(items);
        });
    },
    processFieldValues: function(selectedFields) {
        var fields = [];

        for (var selected of selectedFields) {
            fields.push({
               id: selected.id.replace('custom_fields.', ''),
               name: selected.name,
            })
        }

        return fields;
    },
    processFieldValuesSelected: function(items) {
        var fields = [];

        for (var item of items) {
            var id = item.id;
            var addPrefixToId = true;

            if (id === 'default_value' || id === 'weighted_value') {
                addPrefixToId = false;
            }

            if (addPrefixToId) {
                id = 'custom_fields.' + id;
            }

            fields.push({
                id: id,
                name: item.name,
            });
        }

        return fields;
    }
});

import _ from 'underscore'
import React from 'react'
import ReactDOM from 'react-dom';
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import IODTableReactView from 'js/react_views/iod-table/iod-table'
import ContactPreview from 'js/react_views/contact-preview/contact-preview'
import app from 'js/app'
import GroupElementsCollection from 'js/collections/group_elements'
import CustomFieldsCollection from 'js/collections/custom_fields'
import IndividualModel from 'js/models/contact'
import IndividualFilterModel from 'js/models/individual_filter'

import style from './marketing.css';

const ContactTypeIdx = 13;
const BorrowerStatusIdx = 14;
const CONTACT_TYPE_PREFERENCE_KEY = 'marketing_display_all_contacts';

class MarketingReactView extends React.Component {
    constructor(props) {
        super(props);

        this.collection = new GroupElementsCollection(null, {elementType: 'individuals'});
        this.page = 1;
        this.filter = null;
        this.recommendedCampaignByRowId = {};

        let userPrefs = app.user.get('preferences') || {};

        this.state = {
            loading: false,
            previewContactId: null,
            displayAllContacts: !!userPrefs[CONTACT_TYPE_PREFERENCE_KEY]
        };

        this.assignedCampaignCustomField = null;
        this.noCampaignOption = null;

        let collection = new CustomFieldsCollection();
        const self = this;

        collection.fetch({
            filterBy: [{
                attribute: 'view',
                value: 'individuals'
            }],
            complete: function() {
                self.assignedCampaignCustomField = collection.models.find(m => m.get('name') === 'Assigned Campaign');
                self.noCampaignOption = self.assignedCampaignCustomField.get('options').find(c => c.value.indexOf('No Campaign') !== -1) || {};
                self.updateFilter(self.state.displayAllContacts);
            }
        });

        this.columns = [{
            id: 'last_name',
            headerText: 'Last Name'
        }, {
            id: 'first_name',
            headerText: 'First Name'
        }, {
            id: 'contact_type',
            headerText: 'Contact Type'
        }, {
            id: 'status',
            headerText: 'Status'
        }, {
            id: 'marketing.recommended_campaign',
            headerText: 'Recommended Campaign',
            minWidth: 200,
            dropdownInfo: {
                text: 'value',
                data: function(row, callback) {
                    if (self.assignedCampaignCustomField) {
                        _.defer(function() {
                            let recommendedCampaignOption = null;

                            if (row.recommended_campaign) {
                                recommendedCampaignOption = self.assignedCampaignCustomField.get('options').find(c => c.value.toLowerCase() === row.recommended_campaign.toLowerCase());
                            }

                            callback(self.assignedCampaignCustomField.get('options'), (recommendedCampaignOption || self.noCampaignOption)['id']);
                        });
                    }
                },
                onSelect: function(row, items) {
                    self.recommendedCampaignByRowId[row.id] = items[0].id;
                }
            }
        }, {
            id: 'reason',
            headerText: 'Reason'
        }, {
            id: 'marketing.assign',
            headerText: 'Assign'
        }, {
            id: 'marketing.ignore',
            headerText: 'Ignore'
        }];
    }

    updateFilter(displayAllContacts) {
        let filter = this.filter || new IndividualFilterModel();
        let rules = [];

        if (this.assignedCampaignCustomField) {
            rules.push([{
                field: 'individual_custom',
                operator: 'empty',
                custom: this.assignedCampaignCustomField.get('id')
            }]);
        }

        if (!displayAllContacts) {
            rules.push([{
                field: 'individual_owned_by_me'
            }]);
        }

        if (rules.length > 0) {
            const self = this;
            filter.save({
                rules: rules
            }, {
                alert: false,
                patch: this.filter !== null,
                success: function() {
                    self.filter = filter;
                    self.fetchCollection();
                }
            });
        } else {
            this.filter = null;
            this.fetchCollection();
        }
    }

    fetchCollection() {
        const self = this;

        this.setState({
            loading: true,
            previewContactId: null
        });

        this.collection.fetch({
            page: this.page,
            rows: 50,
            data: {
                filter_id: this.filter ? this.filter.get('id') : null,
                marketing: true,
                contact_preview: true
            },
            success: function() {
                self.setState({loading: false});
            }
        });
    }

    handleButtonClick(row, columnId) {
        let individual = new IndividualModel({id: row.id});
        let changes = {};

        if (columnId === 'marketing.assign') {
            if (this.recommendedCampaignByRowId[row.id]) {
                changes[`custom_field.${this.assignedCampaignCustomField.get('id')}`] = this.recommendedCampaignByRowId[row.id];
            }
        } else {
            changes[`custom_field.${this.assignedCampaignCustomField.get('id')}`] = this.noCampaignOption.id;
        }

        const self = this;

        if (!_.isEmpty(changes)) {
            individual.save(changes, {
                patch: true,
                success: function() {
                    self.fetchCollection();
                }
            });
        }
    }

    gotoPrevPage(ev) {
        ev.preventDefault();
        --this.page;
        this.fetchCollection();
    }

    gotoNextPage(ev) {
        ev.preventDefault();
        ++this.page;
        this.fetchCollection();
    }

    onContactTypeChange(ev) {
        const newValue = ev.target.checked;
        app.user.updatePreference(CONTACT_TYPE_PREFERENCE_KEY, newValue);
        this.setState({displayAllContacts: newValue});
        this.updateFilter(newValue);
    }

    render() {
        const rows = this.collection.map(function(item) {
            return _.clone(item.attributes);
        });
        const contactPreviewVisible = !!this.state.previewContactId;

        // modify the columns id based on the custom field id
        if (this.collection.columns) {
            this.columns[2].id = this.collection.columns[ContactTypeIdx];
            this.columns[3].id = this.collection.columns[BorrowerStatusIdx];
        }

        return (
            <div className={style.MarketingSection}>
                {contactPreviewVisible && <ContactPreview
                    individualId={this.state.previewContactId}
                    individual={this.collection.get(this.state.previewContactId)}
                    columns={this.collection.columns}
                />}
                <div
                    className={style.ContactTypeSelector}
                    style={{top: contactPreviewVisible ? '255px' : '15px'}}
                >
                    <div>My Contacts</div>
                    <div style={{marginRight: '10px', marginLeft: '10px'}} className="switch-container">
                        <input
                            type="checkbox"
                            className="checkbox-switch-control"
                            id="contact-type"
                            checked={this.state.displayAllContacts}
                            onChange={this.onContactTypeChange.bind(this)}
                        />
                        <label htmlFor="contact-type" className="checkbox-switch"/>
                    </div>
                    <div>All Contacts</div>
                </div>
                <IODTableReactView
                    fixedTable={true}
                    firefox={app.firefox}
                    elementType="individuals"
                    loader={this.state.loading}
                    columns={_.clone(this.columns)}
                    rows={rows}
                    totals={{}}
                    pagerValues={{start: this.collection.start + 1, stop: this.collection.start + this.collection.length, total: this.collection.total}}
                    onRowClick={(id) => {this.setState({previewContactId: id})}}
                    handlePrevClick={this.gotoPrevPage.bind(this)}
                    handleNextClick={this.gotoNextPage.bind(this)}
                    handleCellButtonClick={this.handleButtonClick.bind(this)}
                    componentDidMountCallback={() => {}}
                    componentWillUnmountCallback={() => {}}
                    previewVisible={contactPreviewVisible}
                    activeRowId={this.state.previewContactId}
                />
            </div>
        );
   }
}

export default BaseView.extend({
    className: 'marketing-section',
    tagName: 'article',
    template: Handlebars.compile('<div class="react-container"/>'),
    ui: {
        react: '.react-container'
    },
    onRender: function() {
        ReactDOM.render(
            <MarketingReactView
            />,
            this.ui.react.get(0)
        );
    },
    onClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.find('.react-container').get(0));
    },
    getUrl: function() {
        return 'marketing';
    }
});
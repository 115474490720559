import React from 'react';

import BulkEditView from 'js/views/bulk_edit';
import MessageBox from 'js/views/message_box';
import security from 'js/utils/security';
import vent from 'js/vent';
import TextManager from 'app/text-manager';
import AddEntity from 'app_v2/sections/base/components/add_entity';
import { NewSelect } from 'js/react_views/widgets/select';

import style from './actions_bar.css';

export default class ActionsBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entityType: props.entityType,
            deleteButtonActive: props.delete,
            editButtonActive: props.edit,
            newButtonActive: props.new,
            addButtonActive: props.add,
            mergeButtonActive: props.merge,
            removeButtonActive: props.remove,

            deleteButtonVisible: false,
            editButtonVisible: false,
            mergeButtonVisible: false,
            removeButtonVisible: false,

            addEntitySearchArgs: null,

            addPopoverDialog: {
                visible: false
            },
            selectionMessageVisible: false,
            allItemsSelected: false,
            numRows: 0,
            totalRows: 0
        };

        this.rows = [];
    }

    onRowSelectionChange(rowsSelection, allRowsSelected) {
        if (!rowsSelection) {
            rowsSelection = {
                page: null,
                rows: []
            };
        }

        let newState = {
            deleteButtonVisible: rowsSelection.rows.length > 0,
            removeButtonVisible: rowsSelection.rows.length > 0,
            editButtonVisible: rowsSelection.rows.length > 1,
            mergeButtonVisible: rowsSelection.rows.length > 1 && rowsSelection.rows.length < 6,
            selectionMessageVisible: rowsSelection.page !== null,
        };

        if (newState.selectionMessageVisible) {
            newState.allItemsSelected = rowsSelection.rows.length === rowsSelection.page.totalRows;
            newState.numRows = rowsSelection.rows.length;
            newState.totalRows = rowsSelection.page.totalRows;
        }

        if (newState.selectionMessageVisible && allRowsSelected) {
            newState.allItemsSelected = true;
        }

        this.rows = _.clone(rowsSelection.rows);

        this.setState(newState);
    }

    handleSelectAll() {
        this.setState({
            allItemsSelected: true
        });

        if (this.props.onSelectAll) {
            this.props.onSelectAll();
        }
    }

    handleMerge() {
        // TODO
    }

    handleAdd() {
        const bb = this.addButtonComponent.getBoundingClientRect();

        this.setState({
            addPopoverDialog: {
                visible: true,
                top: bb.bottom + 5,
                left: bb.right - 350
            }
        });
    }

    handleRemove() {
        this.handleBulkRemove(
            this.state.allItemsSelected ? null : this.rows,
            this.state.allItemsSelected ? this.state.totalRows : this.rows.length
        );
    }

    handleEdit() {
        this.handleBulkEdit(
            this.state.allItemsSelected ? null : this.rows,
            this.state.allItemsSelected ? this.state.totalRows : this.rows.length
        );
    }

    handleDelete() {
        this.handleBulkDelete(
            this.state.allItemsSelected ? null : this.rows,
            this.state.allItemsSelected ? this.state.totalRows : this.rows.length
        );
    }

    handleBulkRemove(rows, numItemsSelected) {
        const itemsMessage = rows ? `${numItemsSelected } item${numItemsSelected > 1 ? 's' : ''}` : 'all items';
        const content = {
            message: `Are you sure you want to remove ${itemsMessage} from ${this.props.group.name}?`,
            icon: 'icon-trashcan',
            itemsLength: numItemsSelected,
            accept_is_negative: true
        };

        let removeData = {
            group_id: this.props.group.shortId
        };

        if (!rows) {
            // TODO: it looks like the filterid is only set when the group is loaded. Check what happens if i load a group, change the filter and try
            // to bulk_remove
            if (this.props.group.filterId) {
                removeData.filter_id = this.props.group.filterId;
            }
        } else {
            removeData.entity_ids = rows.map(r => r.id);
        }

        const self = this;

        MessageBox.showYesNo(
            content,
            this.props.parent,
            function() { // Yes
                const handle = MessageBox.showNoBtn({
                        icon: 'icon-trashcan',
                        message: 'Removing ' + itemsMessage +
                        "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                        "<div class='mb-wait-cont'>0% complete</div>"
                    },
                    self.props.parent,
                    {
                        staticRegion: true
                    }
                );

                const progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                const completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                progressBar.width('0%');
                completeMessage.text('0% complete');

                $.ajax({
                    type: 'POST',
                    url: '/bulk_remove?queue',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(removeData),
                    success: function(data) {
                        const waitForCompletion = function() {
                            setTimeout(function() {
                                $.get('/bulk_remove/' + data.id, function(data) {
                                    if (data.status === 'finished') {
                                        handle.reset();
                                        self.props.onBulkProcessCompleted('remove');
                                    }
                                    else if (data.status !== 'failed') {
                                        const pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                        progressBar.width(pct + '%');
                                        completeMessage.text(Math.floor(pct) + '% complete');
                                        waitForCompletion();
                                    }
                                });
                            }, 2000);
                        };

                        waitForCompletion();
                    }
                });
            }
        );
    }

    handleBulkDelete(rows, numItemsSelected) {
        let mbProgressContent;
        let mbContent;
        let deleteData;
        let canDelete = true;

        if (rows === null) { // all the items
            mbContent = {
                message: 'Are you sure you want to <strong>permanently</strong> delete all permited items from ' + this.props.group.name + '?',
                icon: 'icon-trashcan',
                itemsLength: numItemsSelected,
                accept_is_negative: true
            };

            mbProgressContent = {
                icon: 'icon-trashcan',
                message: "Deleting permitted items" +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.state.entityType,
                group_id: this.props.group.shortId
            };

            // on smart groups the group id is enough
            if (this.props.group.filterId && !this.props.group.isSmart) {
                deleteData.filter_id = this.props.group.filterId;
            }

            if (this.props.sectionId) {
                deleteData.section_id = this.props.sectionId;
            }
        } else {
            const checkPermissions = _.contains(['individuals', 'organizations', 'opportunities'], this.state.entityType);
            const toDeleteCount = rows.length; // number of items I want to delete
            let permittedCount = 0; // number of items I have permission to delete
            let permittedIds = [];

            for (const row of rows) {
                if (!checkPermissions || security.checkPermission('delete', new Backbone.Model(row))) {
                    ++permittedCount;
                    permittedIds.push(row.id);
                }
            }

            // 3 cases:
            mbContent = {
                message: '',
                icon: 'icon-trashcan',
                itemsLength: permittedCount
            };

            if (permittedCount === 0) { // 1. can't delete anything
                canDelete = false;
                mbContent.message = "You don't have permission to delete selected item" + (toDeleteCount > 1 ? 's' : '');
                mbContent.icon = 'icon-blocked';
                mbContent.itemsLength = null;

                MessageBox.showOk( mbContent, this.props.parent );
            } else if (permittedCount !== toDeleteCount) { // 2. can delete some items
                mbContent.accept_is_negative = true;
                mbContent.message = '<p>You have permission to delete ' + permittedCount +
                    ' of ' + toDeleteCount + ' selected items.</p>' +
                    '<strong class="cta">Do you want to continue?</strong>';
            } else { // 3. can delete all items
                mbContent.accept_is_negative = true;
                mbContent.message = 'Are you sure you want to <strong>permanently</strong> delete ' +
                    permittedCount + ' item' + (permittedCount > 1 ? 's' : '') + '?';
            }

            mbProgressContent = {
                icon: 'icon-trashcan',
                itemsLength: permittedCount,
                message: "Deleting " + permittedCount + " item" + (permittedCount > 1 ? "s" : "") +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.state.entityType,
                entity_ids: permittedIds
            };
        }

        if (canDelete) {
            const self = this;

            MessageBox.showYesNo(
                mbContent,
                this.props.parent,
                function() { // Yes
                    const handle = MessageBox.showNoBtn(
                        mbProgressContent,
                        self.props.parent,
                        {
                            staticRegion: true
                        }
                    );

                    const progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                    const completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                    progressBar.width('0%');
                    completeMessage.text('0% complete');

                    $.ajax({
                        type: 'POST',
                        url: '/bulk_delete?queue',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify(deleteData),
                        success: function(data) {
                            const waitForCompletion = function() {
                                setTimeout(function() {
                                    if (self.isClosed) {
                                        return;
                                    }

                                    $.get('/bulk_delete/' + data.id, function(data) {
                                        if (data.status === 'finished') {
                                            handle.reset();
                                            self.props.onBulkProcessCompleted('delete');
                                        }
                                        else if (data.status === 'failed') {
                                            // nothing specified, but don't continue fetching
                                        }
                                        else {
                                            var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                            progressBar.width(pct + '%');
                                            completeMessage.text(Math.floor(pct) + '% complete');
                                            waitForCompletion();
                                        }
                                    });
                                }, 2000);
                            };
                            waitForCompletion();
                        },
                        error: function() {
                            handle.reset();

                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'There was an error processing this request',
                                        classes: 'load-error error',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            );
        }
    }

    handleBulkEdit(rows, numItemsSelected) {
        let data = {
            elementType: this.state.entityType,
            numItemsSelected: numItemsSelected
        };

        // if rows is null that means all the rows are affected
        if (rows === null) {
            // todo: manage this
            /*
            let isSmartGroup = false;

            if (this.options.tableModel) {
                data.groupId = this.options.tableModel.get('id');
                isSmartGroup = this.options.tableModel.get('group_type') === 'smart';
            }
            else {
                data.groupId = specialGroups[this.options.type];
            }

            // on smart groups the group id is enough
            if (this.options.parent.filter && !isSmartGroup) {
                data.filterId = this.options.parent.filter.get('id');
            }

            if (this.options.parent.section) {
                data.sectionId = this.options.parent.section.id;
            }
            */
        } else {
            data.selection = new Backbone.Collection(rows.map(r => {
                return {
                    id: r.id
                };
            }));
        }

        const view = new BulkEditView(data);
        const self = this;

        this.props.parent.listenTo(view, 'bulk-edit:done', function() {
            self.props.onBulkProcessCompleted('edit');
        });

        this.props.parent.bulkEditRegion.show(view);
    }

    handleAddEntity(item) {
        this.props.onAddEntity(item);
        this.closeAddPopoverDialog();
    }

    closeAddPopoverDialog() {
        this.setState({
            addPopoverDialog: {
                visible: false
            }
        });
    }

    getSelectionMessage() {
        if (!this.state.selectionMessageVisible) {
            return null;
        }

        const entityTypeMapping = {
            opportunities: 'ID_DEAL',
            appointments: 'ID_APPOINTMENT'
        };

        const entityType = entityTypeMapping[this.state.entityType];
        const entityType1 = this.state.numRows !== 1 ? TextManager.parseText('${' + entityType +', plural}') : TextManager.getText(entityType);
        const entityType2 = this.state.totalRows !== 1 ? TextManager.parseText('${' + entityType + ', plural}') : TextManager.getText(entityType);

        if (this.state.allItemsSelected) {
            return (
                <div className={style.abMessage}>
                    <span>All <b>{this.state.totalRows}</b> {entityType2} selected</span>
                </div>
            )
        }

        return (
            <div
                className={style.abMessage}
            >
                <span>
                    The <b>{this.state.numRows}</b> {entityType1} on this page are selected. <a onClick={this.handleSelectAll.bind(this)}>Select all <b>{this.state.totalRows}</b> {entityType2}</a>
                </span>
            </div>
        )
    }

    setButtonsActive(buttonsActive) {
        const buttons = ['delete', 'edit', 'remove', 'merge', 'add', 'new'];
        let newState = {};

        for (const b of buttons) {
            newState[`${b}ButtonActive`] = buttonsActive[b] || false;
        }

        this.setState(newState);
    }

    setAddEntitySearchArgs(args) {
        this.setState({
            addEntitySearchArgs: args
        });
    }

    setEntityType(entityType) {
        this.setState({
            entityType: entityType
        });
    }

    render() {
        return (
            <div className={style.sActionsbar}>
                <div className={style.abGroup}>
                    {this.state.removeButtonActive && this.state.removeButtonVisible &&
                        <div
                            className={`${style.abButton} ${style.abRight} ${style.abBlue}`}
                            onClick={this.handleRemove.bind(this)}
                        >
                            Remove From Group
                        </div>
                    }

                    {this.state.deleteButtonActive && this.state.deleteButtonVisible &&
                        <div
                            className={`${style.abButton} ${style.abRed}`}
                            onClick={this.handleDelete.bind(this)}
                        >
                            Delete
                        </div>
                    }

                    {this.state.editButtonActive && this.state.editButtonVisible &&
                        <div
                            className={`${style.abButton} ${style.abGreen}`}
                            onClick={this.handleEdit.bind(this)}
                        >
                            Edit
                        </div>
                    }

                    {this.state.mergeButtonActive && this.state.mergeButtonVisible &&
                        <div
                            className={`${style.abButton} ${style.abGreen}`}
                            onClick={this.handleMerge.bind(this)}
                        >
                            Merge
                        </div>
                    }
                </div>

                {this.getSelectionMessage()}

                {this.state.newButtonActive &&
                    <div
                        className={`${style.abButton} ${style.abRight} ${style.abBlue}`}
                        onClick={this.props.onNewEntity}
                    >
                        New
                    </div>
                }

                {this.state.addButtonActive &&
                    <div
                        ref={(el) => this.addButtonComponent = el}
                        className={`${style.abButton} ${style.abRight} ${style.abBlue}`}
                        onClick={this.handleAdd.bind(this)}
                    >
                        Add
                    </div>
                }

                {this.state.addPopoverDialog.visible &&
                    <AddEntity
                        top={this.state.addPopoverDialog.top}
                        left={this.state.addPopoverDialog.left}
                        entityType={this.state.entityType}
                        sectionId={this.props.sectionId}
                        searchArgs={this.state.addEntitySearchArgs}
                        onClose={this.handleAddEntity.bind(this)}
                    />
                }
            </div>
        );
    }
}
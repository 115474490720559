import _ from 'underscore'
import AppConfig from 'app/app-config'

var getActivityTypesList = function() {
    var activitiesDef = [
        {
            prefix: 'auto:',
            list: [
                {id: 'email', value: 'Auto Email'},
                {id: 'call', value: 'Call'},
                {id: 'website_register', value: 'Website registration'},
                {id: 'web_tracking', value: 'Website tracking'},
                {id: 'individual_creation', value: 'Individual created'},
                {id: 'individual_merge', value: 'Individual merged'},
                {id: 'individual_import', value: 'Individual imported'},
                {id: 'new_archive_individual', value: 'Contact created by archive email'},
                {id: 'organization_creation', value: 'Organization created'},
                {id: 'organization_merge', value: 'Organization merged'},
                {id: 'organization_import', value: 'Organization imported'},
                {id: 'organization_opportunity_deletion', value: 'Opportunity deleted'},
                {id: 'opportunity_creation', value: 'Opportunity created'},
                {id: 'opportunity_import', value: 'Opportunity imported'},
                {id: 'opportunity_movement', value: 'Opportunity changed phase'},
                {id: 'mailing_list_unsubscribed_all', value: 'Contact unsubscribed'},
                {id: 'mailing_list_subscriptions', value: 'Contact mailing preferences updated'},
                {id: 'message', value: 'SMS Sent'}
            ]
        },
        {
            prefix: 'archive:',
            list: [
                {id: 'email', value: 'Contact archive email'},
                {id: 'opportunity', value: 'Opportunity archive email'},
                {id: 'mailshot_sent', value: 'Email sent'},
                {id: 'mailshot_opened', value: 'Email opened'},
                {id: 'mailshot_url_clicked', value: 'Email url clicked'},
                {id: 'mailshot_soft_bounce', value: 'Email soft bounced'},
                {id: 'mailshot_hard_bounce', value: 'Email hard bounced'},
                {id: 'mailshot_spam', value: 'Email spam'},
                {id: 'mailshot_reject', value: 'Email rejected'},
                {id: 'message', value: 'SMS Received'}
            ]
        },
        {
            prefix: 'automation:archive:',
            list: [
                {id: 'mailshot_sent', value: 'Automation email sent'},
                {id: 'mailshot_opened', value: 'Automation email opened'},
                {id: 'mailshot_url_clicked', value: 'Automation URL clicked'},
                {id: 'mailshot_soft_bounce', value: 'Automation email soft bounced'},
                {id: 'mailshot_hard_bounce', value: 'Automation email hard bounced'},
                {id: 'mailshot_spam', value: 'Automation email spam'},
                {id: 'mailshot_reject', value: 'Automation email rejected'}
            ]
        },
        {
            prefix: '',
            list: [
                {id: 'note', value: 'Note'},
                {id: 'task:completed', value: 'Task completed'},
                {id: 'automation:debug', value: 'Automation execution'},
                {id: 'appointment:note', value: 'Appointment note'}
            ]
        }
    ];

    if (AppConfig.getValue('enableIndividualFieldUpdateActivities')) {
        activitiesDef[0].list.push('individual_update');
        activitiesDef[0].list.push('individual_field_update');
    }

    var result = [];

    _.each(activitiesDef, function(def) {
        _.each(def.list, function(activity) {
            var id = def.prefix + activity.id;
            var value = activity.value

            result.push({
                id: id,
                value: value
            });
        });
    });

    return _.sortBy(result, 'value');
}

var getActivityTypeValue = function(value) {
    return getActivityTypesList().find(activityType => activityType.id === value)
}

export default {
    getActivityTypesList: getActivityTypesList,
    getActivityTypeValue: getActivityTypeValue
}

import _ from 'underscore'
import $ from 'jquery'

export default class GroupPageFetcher {
    constructor(entityType, numRows, columns) {
        this.entityType = entityType;
        this.numRows = numRows;
        this.orderByStr = null;
        this.searchTerm = null;
        this.searchOnlyOn = null;
        this.filterId = null;
        this.sectionId = null;
        this.fetchArgs = null;
        this.fetchIdx = 0;

        this.setColumns(columns);

        switch (entityType) {
            case 'individuals':
                this.searchOnlyOn = 'full_name, communications';
                break;
        }
    }

    setColumns(columns) {
        this.columns = columns.map(c => c.id).join(',');
        this.fetchIdx++;
    }

    searchBy(searchTerm) {
        this.searchTerm = searchTerm;
        this.fetchIdx++;
    }

    getSearchTerm() {
        return this.searchTerm;
    }

    orderBy(columnId, sortDir) {
        this.orderByStr = `${columnId} ${sortDir}`;
        this.fetchIdx++;
    }

    getOrderBy() {
        const parts = this.orderByStr.split(' ');

        if (parts.length === 2) {
            return {
                columnId: parts[0],
                sortDir: parts[1]
            };
        }

        return null;
    }

    filterBy(filterId) {
        this.filterId = filterId;
        this.fetchIdx++;
    }

    setSectionId(sectionId) {
        this.sectionId = sectionId;
        this.fetchIdx++;
    }

    setFetchArgs(fetchArgs) {
        this.fetchArgs = fetchArgs || null;
        this.fetchIdx++;
    }

    getFetchArgs() {
        return this.fetchArgs;
    }

    fetch(page, callback) {
        const args = this.getGroupPageArgs(page);
        const fetchIdx = this.fetchIdx;
        const self = this;

        $.get(`/group_pages?${$.param(args)}`, function(result, _, request) {
            if (fetchIdx !== self.fetchIdx) {
                return;
            }

            const start = parseInt(request.getResponseHeader('Records-Start'));
            let rows = [];

            for (const [idx, row] of result.rows.entries()) {
                let item = {
                    id: row.id,
                    permissions: row.permissions,
                    index: start + idx + 1
                };

                for (let i = 0; i < result.columns.length; ++i) {
                    item[result.columns[i]] = row.cells[i];
                }

                rows.push(item);
            }

            callback({
                rows: rows,
                total: parseInt(request.getResponseHeader('Records-Total')),
                start: start,
                numRows: parseInt(request.getResponseHeader('Records-Rows'))
            });
        });
    }

    getGroupPageArgs(page) {
        let args = {
            item_type: this.entityType,
            start: page * this.numRows,
            rows: this.numRows,
            columns: this.columns
        };

        if (this.orderByStr) {
            args.order_by = this.orderByStr;
        }

        if (this.filterId) {
            args.filter_id = this.filterId;
        }

        if (this.sectionId) {
            args.section_id = this.sectionId;
        }

        if (this.searchTerm) {
            args.search = this.searchTerm;
            args.search_extra = true;
            args.search_only_on = this.searchOnlyOn;
        }

        if (this.fetchArgs) {
            _.extend(args, this.fetchArgs);
        }

        return args;
    }
}
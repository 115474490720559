import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import BaseView from 'js/views/base/base'
import api from 'js/api'
import addItemTemplate from 'templates/base/add-item.handlebars'


var AddItemView = BaseView.extend({
    template: Handlebars.compile(addItemTemplate),
    templateHelpers: function() {
        return {
            placeholder: this.selectPlaceholder
        };
    },
    className: 'add-item-container popover popover-wider',
    ui: {
        addItemInput: '.add-item-input',
        alert: '.alert'
    },
    events: {
        'submit form': function(ev) {
            ev.preventDefault();

            this.addItem();
        },
        'keyup': 'hidePermissionAlert'
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        // for opportunities the search box is wider
        var dropdownCssClass = 'deal-select-popover popover select2-drop-wider';

        if (this.selectDropdownExtraClasses) {
            dropdownCssClass += ' ' + this.selectDropdownExtraClasses;
        }

        var options = {
            view: this,
            $el: this.ui.addItemInput,
            url: this.selectUrl || this.ItemModel.prototype.urlRoot,
            search: true,
            text: this.selectText || 'title',

            options: {
                placeholder: this.selectPlaceholder || 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: dropdownCssClass,
                minimumInputLength: 1
            }
        };

        if (this.selectFormatResult) {
            options.formatResult = this.selectFormatResult;
        }

        this.select = new backboneSelect2.SelectView(options);
        this.listenTo(this.select, 'change', this.addItem);
    },
    addItem: function(item) {
        if (!item) {
            return;
        }
        var view = this;
        var model = new this.ItemModel({id: item.id});
        var url;
        if (this.selectUrl === '/content_files') {
            url = '/content_files/' + model.get('id');
        }
        model.fetch({
            url: url || null,
            success: function() {
                view.trigger('add-item', model);
            }
        });
    },
    showPermissionAlert: function(model) {
        var name = model.get('name') || model.toString();
        this.ui.alert.text('You do not have permission to edit ' + name).show();
    },
    hidePermissionAlert: function() {
        this.ui.alert.hide();
    }
});

export default AddItemView;

import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

import guid from 'js/utils/guid'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import TextManager from 'app/text-manager';


function setDefaultId(model) {
    if (!model.id) {
        model.id = guid().replace(/-/g, '');
        model.set(model.idAttribute, model.id);
    }
}
var BaseModel = Backbone.Model.extend({
    constructor: function () {
        var super_c = Backbone.Model.prototype.constructor;
        super_c.apply(this, arguments);

        this.fetched = false;
        this.listenTo(this, 'sync', function() {
            this.fetched = true;
        });
    },
    clear: function() {
        this.fetched = false;
        Backbone.Model.prototype.clear.apply(this, arguments);
    },
    title: function() {
        return this.toString();
    },
    cloneable: false,
    parsers: {
        'created': dateFormat.parseDate,
        'real_created': dateFormat.parseDate,
        'modified': dateFormat.parseDate,
        'user_created': dateFormat.parseDate,
    },
    serializers: {
    },
    toJSON: function() {
        var super_json = Backbone.Model.prototype.toJSON,
            json = super_json.apply(this, arguments);

//            if ( this.isLocked() ) {
//                return this.id;
//            }

        _.each(_.pairs(this.serializers), function(serialize) {
            var attr = serialize[0], serializer = serialize[1];
            json[attr] = serializer(json[attr]);
        });
        return json;
    },
    parse: function (resp) {
        var attr, super_parse = Backbone.Model.prototype.parse;
        if (this.parsers) {
            for (attr in resp) {
                if (this.parsers.hasOwnProperty(attr)) {
                    resp[attr] = this.parsers[attr](resp[attr]);
                }
            }
        }
        return super_parse.apply(this, arguments);
    },
    url: function (fields, extraFields, params) {
        var super_url = Backbone.Model.prototype.url,
            url = super_url.apply(this, arguments);
        if (fields && fields.length) {
            if (url.indexOf('?') > -1) {
                url += '&fields=';
            } else {
                url += '?fields=';
            }
            url += _.map(fields, encodeURIComponent).join('&fields=');
        }
        if (extraFields && extraFields.length) {
            if (url.indexOf('?') > -1) {
                url += '&extra_fields=';
            } else {
                url += '?extra_fields=';
            }
            url += _.map(extraFields,
                         encodeURIComponent).join('&extra_fields=');
        }
        if (params) {
            if (url.indexOf('?') > -1) {
                url += '&' + $.param(params);
            } else {
                url += '?' + $.param(params);
            }
        }
        return url;
    },
    fetch: function (options) {
        var subFetchFields = [],
            super_fetch = Backbone.Model.prototype.fetch;
        options = options ? _.clone(options) : {};
        if (options.at) {
            options.headers = (options.headers ? _.clone(options.headers) : {});
            options.headers['Accept-Datetime'] = options.at.toISOString();
        }
        if (!options.noSubFetch) {
            options = options ? _.clone(options) : {};
        }
        options.extraFields = options.extraFields || [];
        options.extraFields = _.union(options.extraFields,
                                      subFetchFields);

        if (!options.url) {
            options.url = this.url(options.fields, options.extraFields);
        }
        return super_fetch.call(this, options);
    },
    save: function (attributes, options) {
        var subFetchFields = [],
            super_save = Backbone.Model.prototype.save;
        options = options ? _.clone(options) : {};
        if (options.at) {
            options.headers = (options.headers ? _.clone(options.headers) : {});
            options.headers['Accept-Datetime'] = options.at.toISOString();
        }
        if (!options.noSubFetch) {
            options = options ? _.clone(options) : {};
        }
        options.extraFields = options.extraFields || [];
        options.extraFields = _.union(options.extraFields,
                                      subFetchFields);

        if (!options.url) {
            options.url = this.url(options.fields, options.extraFields, options.params);
        }

        var model = this;

        if (options.alert || options.alert === void 0) {
            var beforeSend = options.beforeSend;
            options.beforeSend = function(xhr) {
                vent.trigger("alert:show", { type: 'save', model: model, xhr: xhr});

                if (beforeSend) {
                    beforeSend.apply(this, arguments);
                }
            };

            var success = options.success;
            options.success = function(model, attributes, settings) {
                // it's possible that the BE has deleted some attributes in the model, so we update the model's attributes
                model.clear({silent: true});
                model.set(attributes);

                vent.trigger("alert:show", { type: 'save', model: model, xhr: settings.xhr });

                if (success) {
                    success.apply(this, arguments);
                }
            };

            var error = options.error;
            options.error = function(model, response, settings) {
                var exception;
                try {
                    exception = JSON.parse(response.responseText).detail.exception;
                }
                catch (e) {
                }

                if (!settings.ignoreResponseAlertSet || !(exception in settings.ignoreResponseAlertSet)) {
                    vent.trigger("alert:show", {type: 'save', model: model, xhr: settings.xhr});
                }
                else {
                    vent.trigger("alert:hide");
                }

                if (error) {
                    error.apply(this, arguments);
                }
            };
        }

        var complete = options.complete;
        options.complete = function() {
            model.saving = false;

            if (complete) {
                complete.apply(this, arguments);
            }
        };

        //do we only care about double saving if the model is new?
        if (this.isNew() && this.saving) {
            return false;
        } else {
            //model.save returns false if invalid and undefined if valid
            //shouldn't it return the xhr?
            var result = super_save.call(this, attributes, options);
            //model is saving only if result !== false
            this.saving = result !== false;

            return result;
        }
    },
    get: function () {
        var super_get = Backbone.Model.prototype.get,
            result = super_get.apply(this, arguments);

        if (result instanceof Backbone.Collection) {
            result.parent = this;
        }
        return result;
    },
    // .clone has a different meaning
    createClone: function (options) {
        // TODO: This is forecast specific and should not be here
        var newModel = new this.constructor(),
            model = this,
            ajaxOptions = _.extend({}, options, {
                url: this.url() + '?clone',
                dataType: 'json',
                type: 'POST'
            });

        if (options.alert || options.alert === void 0) {
            var beforeSend = options.beforeSend;
            ajaxOptions.beforeSend = function(xhr) {
                vent.trigger("alert:show", { type: 'clone', model: newModel, xhr: xhr});

                if (beforeSend) {
                    beforeSend.apply(this, arguments);
                }
            };

            var success = options.success;
            ajaxOptions.success = function(data, textStatus, xhr) {
                vent.trigger("alert:show", { type: 'clone', model: newModel, xhr: xhr });

                newModel.set(data, {parse: true, silent: true});
                if (success) {
                    success(newModel, data, options);
                }
            };

            var error = options.error;
            ajaxOptions.error = function(data, textStatus, xhr) {
                vent.trigger("alert:show", { type: 'clone', model: newModel, xhr: xhr });

                if (error) {
                    error(model, data, options);
                }
            };
        }

        // TODO: error handling
        return $.ajax(ajaxOptions);
    },
    setDefaultId: function () {
        setDefaultId(this);
    },
    sync: function (type, data, options) {
        options = options || {};

        var error = options.error;
        options.error = function(model, response, settings) {
            if (settings.xhr.status === 502){
                vent.trigger('bad_gateway');
            }
            if (settings.xhr.status === 503){
                vent.trigger('server_temporarily_unavailable');
            }

            if (error) {
                error.apply(this, arguments);
            }
        };

        return Backbone.Model.prototype.sync.call(this, type, data, options);
    },
    destroy: function(options) {
        var super_destroy = Backbone.Model.prototype.destroy;

        options = options ? _.clone(options) : {};
        var model = this;

        if (options.alert || options.alert === void 0) {
            var beforeSend = options.beforeSend;
            options.beforeSend = function(xhr) {
                vent.trigger("alert:show", { type: 'delete', model: model, xhr: xhr });

                if (beforeSend) {
                    beforeSend.apply(this, arguments);
                }
            };

            var success = options.success;
            options.success = function(model, attributes, settings) {
                vent.trigger("alert:show", { type: 'delete', model: model, xhr: settings.xhr });

                if (success) {
                    success.apply(this, arguments);
                }
            };

            var error = options.error;
            options.error = function(model, response, settings) {
                var errorMsg;

                if (response.status === 403) {
                    errorMsg = 'You do not have permissions to delete this entity.';
                    try {
                        const detail = JSON.parse(response.responseText).detail;
                        if ('exception' in detail) {
                            if (detail.exception === 'IndividualPermissionsCredasAmlCompletedError') {
                                const contactText = TextManager.parseText('${ID_INDIVIDUAL}');
                                errorMsg = `You are not allowed to delete this ${contactText} as an AML journey has been completed.`;
                            } else if (detail.exception === 'OpportunityPermissionsPassedCompliancePhaseError') {
                                const dealText = TextManager.parseText('${ID_DEAL, capitalize}');
                                errorMsg = `${dealText} has passed the Compliance phase.`;
                            }
                        }
                    }
                    catch (e) {
                    }
                }
                vent.trigger("alert:show", { type: 'delete', model: model, error: errorMsg, xhr: settings.xhr });

                if (error) {
                    error.apply(this, arguments);
                }
            };
        }

        return super_destroy.call(this, options);
    }
},
{
    createPermission: function (context) {
        if (!context.urlRoot) {
            context = context.prototype;
        }

        return (
            'salesseek.core.create.' +
            _.result(context, 'urlRoot')
                .toString()
                .split('/', 1)
                .reverse()[0]
                .replace(/[^\w]/g, '')
            );
    }
});
export default BaseModel;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import vent from 'js/vent'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config';
import ModalRegion from 'js/views/base/modal-region.js'
import BaseModel from 'js/models/base.js'
import LeadSourcesCollection from 'js/collections/lead_sources.js'
import CustomFieldsCollection from 'js/collections/custom_fields.js'
import LoadingView from 'js/views/loading.js'
import webLeadsTemplate from 'templates/settings/web_leads.handlebars'
import sourceSelectTemplate from 'templates/settings/web_leads_source_select.handlebars'
import mappingListTemplate from 'templates/settings/web_leads_mapping_list.handlebars'
import addNewMappingTemplate from 'templates/settings/web_leads_add_new_mapping.handlebars'


var SourceSelect,
    MappingList,
    NewMappingView,
    leadFields = {
        'name': {id: 'name', title: 'Full Name (First & Last)', group: 'individuals'},
        'first_name': {id: 'first_name', title: 'First Name', group: 'individuals'},
        'last_name': {id: 'last_name', title: 'Last Name', group: 'individuals'},
        'job_role': {id: 'job_role', title: TextManager.getText('ID_JOB_ROLE'), group: 'individuals'},
        'owner': {id: 'owner', title: 'Owner', group: 'individuals'},
        'phone': {id: 'phone', title: 'Phone', group: 'individuals'},
        'email': {id: 'email', title: 'Email', group: 'individuals'},
        'address': {id: 'address', title: 'Address', group: 'individuals'},
        'comments': {id: 'comments', title: 'Comments', group: 'individuals'},
        'source': {id: 'source', title: 'Source', group: 'individuals'},
        'email_opted_in': {id: 'email_opted_in', title: TextManager.getText('ID_EMAIL_OPTED_IN'), group: 'individuals'},
        'message_opted_in': {id: 'message_opted_in', title: TextManager.getText('ID_TEXTING_OPTED_IN'), group: 'individuals'},
        'groups': {id: 'groups', title: 'Groups', group: 'individuals'},
        'organization_name': {id: 'organization_name', title: 'Name', group: 'organizations'},
        'organization_more_info': {id: 'organization_more_info', title: TextManager.getText('ID_MORE_INFO'), group: 'organizations'},
        'organization_location': {id: 'organization_location', title: 'Location', group: 'organizations'},
        'organization_phone': {id: 'organization_phone', title: 'Phone', group: 'organizations'},
        'organization_email': {id: 'organization_email', title: 'Email', group: 'organizations'},
        'organization_website': {id: 'organization_website', title: 'Website', group: 'organizations'},
        'deal_name': {id: 'deal_name', title: 'Name', group: 'deals'},
        'deal_close_date': {id: 'deal_close_date', title: 'Close Date', group: 'deals'},
        'deal_more_info': {id: 'deal_more_info', title: TextManager.getText('ID_MORE_INFO'), group: 'deals'},
        'deal_funnel': {id: 'deal_funnel', title: 'Funnel', group: 'deals'},
        'deal_phase': {id: 'deal_phase', title: 'Phase', group: 'deals'}
    },
    groupedLeadFields = [],
    fieldPrefix = {
        individuals: TextManager.parseText('${ID_INDIVIDUAL, capitalize} - '),
        organizations: TextManager.parseText('${ID_ORGANIZATION, capitalize} - '),
        deals: TextManager.parseText('${ID_DEAL, capitalize} - ')
    },
    WebFormModel = BaseModel.extend({
        name: 'Web Form',
        urlRoot: '/web_form'
    });

SourceSelect = Marionette.ItemView.extend({
    className: 'web-leads-source',
    template: Handlebars.compile(sourceSelectTemplate),
    ui: {
        webLeadsSource: '#web-leads-source'
    },
    onRender: function() {
        var view = this;

        var sources = this.options.sources.toJSON();

        // Get source
        var source = _.find(sources, function(item) {
            return (item.id === view.options.source.id);
        });
        if (!source) {
            // No source, so set to the default source (unspecified)
            source = _.find(sources, function(item) {
                return (item.system_type === 'unspecified');
            });

            // Save it because there should be one set
            view.model.save(
                { source: { id: source.id } },
                { patch: true }
            );
        }

        var sourceSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.webLeadsSource,
            text: 'name',
            value: source,
            data: sources,
            options: {
                containerCssClass: 'editable-field',
                dropdownCssClass: 'source-select-popover popover'
            }
        });
        this.listenTo(sourceSelect, 'change', function(val) {
            view.model.save(
                { source: { id: val.id } },
                { patch: true }
            );
        });
    }
});

MappingList = Marionette.ItemView.extend({
    tagName: 'ul',
    className: 'mapping-list editable-list',
    template: Handlebars.compile(mappingListTemplate),
    ui: {
        item: '.item-content'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        }
    },
    templateHelpers: function() {
        var mappings = [];

        _.each(this.model.get('mappings'), function(el) {
            mappings.push({
                form_field_name: el.form_field_name,
                leadFieldName: leadFields[el.lead_field] && leadFields[el.lead_field].title
            });
        });

        return {
            mappings: mappings
        };
    },
    onRender: function() {
        var self = this;
        $.each(this.ui.item, function(ind, el) {
            var mapping = self.model.get('mappings')[ind];

            this.fieldSelect = new backboneSelect2.SelectView({
                view: self,
                $el: $(el).find('.edit-lead-field'),
                text: 'title',
                value: mapping.lead_field,
                data: groupedLeadFields,
                options: {
                    placeholder: 'Select a field',
                    containerCssClass: 'editable-field',
                    dropdownCssClass: 'mapping-select-popover popover'
                }
            });

            self.activateElement($(el).find('.name'), $(el).find('.edit-name'), 'form_field_name', ind);
            self.activateSelect2($(el).find('.lead-field'), $(el).find('.edit-lead-field'), 'lead_field',  ind);
            self.removeElement(el, ind);
        });

        this.$el.find('[data-toggle=tooltip]').tooltip({
            container: this.$el
        });
    },
    activateSelect2: function(text, edit, dbField, ind) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.select2('open');
        });

        edit.on('change', function() {
            var val = edit.select2('val'),
                mappings = self.model.get('mappings');

            mappings[ind][dbField] = val;

            self.model.save({
                mappings: mappings
            },
            {
                patch: true,
                complete: function() {
                    var data = edit.select2('data');
                    text.text(fieldPrefix[data.group] + data.title);
                    hideEdit();
                }
            });

        });

        edit.on('select2-close', hideEdit);

        var data = edit.select2('data');
        text.text(fieldPrefix[data.group] + data.title);
    },
    /**
     * Add events to show/hide input and store view field in DB .
     *
     * @param obj   text        jquery text field
     * @param obj   edit        jquery input field
     * @param obj   dbField     corresponding field in model
     */
    activateElement: function(text, edit, dbField, ind) {
        var self = this,
            container = edit.closest('.editable-field-container'),
            editIcon = container.find('.edit-icon');

        function hideEdit() {
            edit.hide();
            container.removeClass('editing');
        }

        editIcon.on('click', function() {
            container.addClass('editing');
            edit.show().focus();
        });

        edit.on('change', function() {
            var val = edit.val(),
                mappings = self.model.get('mappings');

            mappings[ind][dbField] = val;

            self.model.save({
                mappings: mappings
            },
            {
                patch: true,
                complete: function() {
                    text.text(val);
                    hideEdit();
                }
            });

        });

        edit.on('blur', hideEdit);
    },
    removeElement: function(el, ind) {
        var self = this;

        $(el).next().find('.delete-item').on('click', function() {
            var mappings = self.model.get('mappings');

            $(this).tooltip('destroy');

            mappings.splice(ind, 1);

            self.model.save({
                mappings: mappings
            },
            {
                patch: true,
                complete: function() {
                    // re-render to update indexes for field editing
                    self.render();
                }
            });
        });
    }
});

NewMappingView = Marionette.ItemView.extend({
    className: 'edit-mapping edit-form-modal',
    template: Handlebars.compile(addNewMappingTemplate),
    ui: {
        name: '#name',
        mappedField: '#mapped-field',
        error_messages: '.error-message'
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .save': 'save',
        'click .close': function() {
            this.trigger('cancel');
        }
    },
    onRender: function() {
        this.sourceSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.mappedField,
            text: 'title',
            data: groupedLeadFields,
            options: {
                placeholder: 'Select a field',
                dropdownCssClass: 'mapping-select-popover popover'
            }
        });
    },
    save: function() {
        var mapping = {
            form_field_name: this.ui.name.val(),
            lead_field: this.ui.mappedField.val()
        };
        var errors = this.validate(mapping);
        if (errors) {
            this.error_messages.remove.call(this);
            if (errors.missing_name) {
                this.ui.name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_name)
                    .addClass('invalid');
            }
            if (errors.missing_mapping) {
                this.ui.mappedField.select2('container').children('a.select2-choice')
                    .addClass('validation_error')
                    .parent()
                    .nextAll('.error-message')
                    .text(errors.missing_mapping)
                    .addClass('invalid');
            }
        } else {
            this.error_messages.hide.call(this);
            this.trigger('new:mapping', mapping);
        }
    },
    validate: function(mapping) {
        var errors = {};
        if (!mapping.form_field_name) {
            errors.missing_name = 'Field Name is required';
        }
        if (!mapping.lead_field) {
            errors.missing_mapping = 'Please select a mapping field';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    error_messages: {
        remove: function() {
            this.$el.find('.validation_error').removeClass('validation_error');
            this.ui.error_messages.empty().removeClass('invalid');
        },
        hide: function() {
            this.ui.error_messages.hide();
        },
        unhide: function() {
            this.ui.error_messages.show();
        }
    }
});

export default Marionette.Layout.extend({
    className: 'web-leads',
    template: Handlebars.compile(webLeadsTemplate),
    templateHelpers: function(){
        return {
            value: AppConfig.getClientPreferenceValue('prevent_web_leads_duplicates')
        };
    },
    regions: {
        sourceSelect: ".source-select",
        mappingList: ".mapping-list-container",
        addNewMapping: {
            selector: '.add-new-mapping', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    ui: {
        code: "#tracking-code",
        preventDuplicates: '#prevent-duplicates',
    },
    events: {
        'click #tracking-code': function(ev) {
            $(ev.currentTarget).focus().select();
        },
        'click .add-new-mapping': function() {
            var self = this,
                newMappingView = new NewMappingView();
            this.addNewMapping.show(newMappingView);
            this.listenTo(newMappingView, 'new:mapping', function(mapping) {
                var mappings = self.webForm.get('mappings');
                mappings.push(mapping);

                // the id is required to do a PATCH instead a POST
                if (!self.webForm.get('id')) {
                    self.webForm.set('id', 1);
                }

                self.webForm.save(
                    {
                        mappings: mappings
                    },
                    {
                        patch: true,
                        success: function() {
                            self.showMappingList();
                        }
                    }
                );
                self.addNewMapping.reset();
            });
            this.listenTo(newMappingView, 'cancel', function() {
                this.addNewMapping.reset();
            });
        },
        'click #prevent-duplicates': function(ev){
            var newValue = ev.target.checked;
            var client = app.user.get('client');
            var preferences = client.preferences || {};
            var self = this;

            preferences['prevent_web_leads_duplicates'] = newValue ? 'true' : 'false';

            $.ajax({
                type: 'PATCH',
                url: '/clients/' + client.id,
                data: JSON.stringify({
                    preferences: preferences
                }),
                contentType: 'application/json',
                dataType: 'json',
                success: function() {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Preferences saved',
                                classes: 'saved success',
                                timer: 3000
                            };
                        }
                    });
                },
                error: function() {
                    client.preferences['prevent_web_leads_duplicates'] = newValue ? 'false' : 'true';
                    $(ev.target).prop('checked', !newValue);
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Error saving preferences',
                                classes: 'saved error',
                                timer: 3000
                            };
                        }
                    });
                }
            });
        }
    },
    initialize: function() {
        if (AppConfig.getValue('enableTextMessaging')) {
            leadFields.message_opted_in = {id: 'message_opted_in', title: TextManager.getText('ID_TEXTING_OPTED_IN'), group: 'individuals'};
        }
        if (AppConfig.getValue('showTagsOnWebLeadForm')) {
            leadFields.tags = {id: 'tags', title: 'Tags', group: 'individuals'};
            leadFields.organization_tags = {id: 'organization_tags', title: 'Tags', group: 'organizations'};
            leadFields.deal_tags = {id: 'deal_tags', title: 'Tags', group: 'deals'};
        }
    },
    onRender: function() {
        var self = this,
            cId = app.user.get('client')['short_id'],
            deferForm = $.Deferred(),
            deferCustomFields = $.Deferred(),
            formProm = deferForm.promise(),
            customFieldsProm = deferCustomFields.promise();

        this.ui.code.text('<script src="https://tstatic.' + TextManager.getText('ID_HOST') + '/register.js?clientid=' + cId + '"></script>');

        // set id as 1 so PATCH instead of POST would be made when saving changes later
        // doesn't work with 'new' keyword when opening second time because relational stores this model
        this.webForm = new WebFormModel({ id: 1 });
        this.webForm.fetch({
            complete: function() {
                self.showSourceSelect(self.webForm.get('source'));
                deferForm.resolve();
            }
        });

        // load custom fields
        this.mappingList.show(new LoadingView());

        this.customFieldsLoaded = false;

        var customFields = new CustomFieldsCollection();
        customFields.fetch({
            success: function() {
                for (var i = 0; i < customFields.length; ++i) {
                    var cf = customFields.models[i];

                    if (['organization', 'individual', 'opportunity', 'user', 'list'].indexOf(cf.get('type')) !== -1) {
                        continue;
                    }

                    var id = cf.get('id');
                    leadFields[id] = {id: id, title: cf.get('name') + ' (Custom Field)', group: cf.get('view')};
                }

                self.customFieldsLoaded = true;
                self.buildGroupedLeadFields();
                deferCustomFields.resolve();
            }
        });

        $.when(formProm, customFieldsProm).done(function() {
            self.showMappingList();
        });

        this.scrollEvents();
    },
    buildGroupedLeadFields: function() {
        var groups = {
            individuals: {
                title: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                children: []
            },
            organizations: {
                title: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                children: []
            },
            deals: {
                title: TextManager.parseText('${ID_DEAL, capitalize}'),
                children: []
            }
        };

        _.each(_.keys(leadFields), function(k) {
            var field = leadFields[k];
            groups[field.group].children.push(field);
        });

        groupedLeadFields = [groups.individuals, groups.organizations, groups.deals];
    },
    showSourceSelect: function(selectedSource) {
        var self = this,
            leadSources = new LeadSourcesCollection();
        leadSources.fetch({
            success: function() {
                self.sourceSelect.show(new SourceSelect({
                    sources: leadSources,
                    source: selectedSource,
                    model: self.webForm
                }));
            }
        });
    },
    showMappingList: function() {
        if (this.customFieldsLoaded) {
            // before show the list, we check that all mapping items are in the leadFields list
            // (maybe a custom field has been delete)
            var mappings = this.webForm.get('mappings') || [];
            var i = 0;
            var changed = false;

            while(i < mappings.length) {
                if (!(mappings[i].lead_field in leadFields)) {
                    mappings.splice(i, 1);
                    changed = true;
                }
                else {
                    ++i;
                }
            }

            if (changed) {
                this.webForm.set('mappings', mappings);
            }

            // ...
            this.mappingListView = new MappingList({ model: this.webForm });

            this.listenTo(this.mappingListView, 'show', function() {
                this.scrollBar();
            });

            this.mappingList.show(this.mappingListView);
        }
    },
    scrollBar: function() {
        this.$el.find('.content-container').nanoScroller();
    },
    scrollEvents: function() {
        var container = this.$el.find('.content-container'),
            header = this.$el.find('.detail-header');

        $(container).find('.content').scroll(function() {
            if (container.find('section:first-child').position().top < -1) {
                if(!header.hasClass('header-shadow')){
                    header.addClass('header-shadow');
                }
            }
            else {
                header.removeClass('header-shadow');
            }
        });
    }
});

import _ from 'underscore'
import React from 'react'

import guid from 'js/utils/guid'
import Utilities from 'js/utils/utilities'
import AppConfig from 'app/app-config';
import {ANCHOR_BITFIELD} from './../defs'
import {EditingHelperRenderer} from './../editor'
import KpiSimple from './kpi_simple'
import KpiBox from './kpi_box'
import RegionList from './region_list'
import KpiRow from './kpi_row'
import ForecastStats from './forecast_stats'
import ListSimple from './list_simple'
import PlotCards from './plot_cards'
import Insights from './insights'
import ActivityInsights from './activity_insights'
import TaskCalendar from './task_calendar'
import Funnel from './funnel'
import SiteMap from './site_map'
import PhasesSummary from './phases_summary'
import ActivitiesList from './activities_list'
import DealsCalendar from './deals_calendar'
import ContactCards from './contact_cards'
import DevelopmentHeadCounter from './development_head_counter';
import app from 'js/app'


import style from './core.css'


const WIDGET_COMPONENT_BY_TYPE = {
    development_head_counter: DevelopmentHeadCounter,
    kpi_simple: KpiSimple,
    kpi_box: KpiBox,
    kpi_row: KpiRow,
    region_list: RegionList,
    forecast_stats: ForecastStats,
    list_simple: ListSimple,
    plot_cards: PlotCards,
    insights: Insights,
    activity_insights: ActivityInsights,
    task_calendar: TaskCalendar,
    funnel: Funnel,
    site_map: SiteMap,
    phases_summary: PhasesSummary,
    activities_list: ActivitiesList,
    deals_calendar: DealsCalendar,
    contact_cards: ContactCards
};

let getRendererByWidget;

export class Widget {
    constructor(props) {
        props = props || {};

        this.id = props.id || guid();
        this.type = props.type || 'widget';
        this.position = props.position || [0, 0];
        this.size = props.size || [0, 0];
        this.minSize = props.min_size || null;
        this.maxSize = props.max_size || null;
        this.anchor = props.anchor || ANCHOR_BITFIELD.none;
        this.params = props.params || {};
        this.fetcher = props.fetcher;
        this.triggerEvent = props.triggerEvent;
        this.parent = props.parent || null;
        this.rectState = 'normal'; // rect means the position and the size
        this.zIndexExtra = 0;
        this.overlapped = false;

        this.eventsListeners = {};
        this.sizeToGrow = [0, 0];
        this.sizeToShrink = [0, 0];

        if (this.params.auto_refresh) {
            this.intervalId = window.setInterval(() => this.refresh(), this.params.auto_refresh * 60000);
        }

        if (this.params.refresh_on) {
            for (const event of this.params.refresh_on) {
                this.addEventListener(event, this.refresh.bind(this));
            }
        }
    }

    onDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }

        this.eventsListeners = {};
    }

    onParentResized(diff) {
        this.adjustSizeAndPosition(diff);
        this.setDirty();
    }

    adjustSizeAndPosition(diff) {
        if (!(this.anchor & ANCHOR_BITFIELD.right) && !(this.anchor & ANCHOR_BITFIELD.bottom)) {
            return false;
        }

        let newWidth = this.size[0];
        let newHeight = this.size[1];
        let newX = this.position[0];
        let newY = this.position[1];
        let resized = false;

        if (diff[0]) {
            if (this.anchor & ANCHOR_BITFIELD.right) {
                if (this.anchor & ANCHOR_BITFIELD.left) {
                    let diffX = diff[0];

                    if (diffX > 0 && this.sizeToGrow[0]) {
                        this.sizeToGrow[0] += diffX;

                        if (this.sizeToGrow[0] > 0) {
                            diffX = this.sizeToGrow[0];
                            this.sizeToGrow[0] = 0;
                        } else {
                            diffX = 0;
                        }
                    }

                    if (diffX < 0 && this.sizeToShrink[0]) {
                        this.sizeToShrink[0] += diffX;

                        if (this.sizeToShrink[0] < 0) {
                            diffX = this.sizeToShrink[0];
                            this.sizeToShrink[0] = 0;
                        } else {
                            diffX = 0;
                        }
                    }

                    // ...
                    newWidth += diffX;

                    if (this.minSize && newWidth < this.minSize[0]) {
                        this.sizeToGrow[0] += newWidth - this.minSize[0];
                        newWidth = this.minSize[0];
                    }

                    if (this.maxSize && newWidth > this.maxSize[0]) {
                        this.sizeToShrink[0] += this.maxSize[0] - newWidth;
                        newWidth = this.maxSize[0];
                    }

                    resized = true;
                } else {
                    newX += diff[0];
                }
            }
        }

        if (diff[1]) {
            if (this.anchor & ANCHOR_BITFIELD.bottom) {
                if (this.anchor & ANCHOR_BITFIELD.top) {
                    let diffY = diff[1];

                    if (diffY > 0 && this.sizeToGrow[1]) {
                        this.sizeToGrow[1] += diffY;

                        if (this.sizeToGrow[1] > 0) {
                            diffY = this.sizeToGrow[1];
                            this.sizeToGrow[1] = 0;
                        } else {
                            diffY = 0;
                        }
                    }

                    if (diffY < 0 && this.sizeToShrink[1]) {
                        this.sizeToShrink[1] += diffY;

                        if (this.sizeToShrink[1] < 0) {
                            diffY = this.sizeToShrink[1];
                            this.sizeToShrink[1] = 0;
                        } else {
                            diffY = 0;
                        }
                    }

                    // ...
                    newHeight += diffY;

                    if (this.minSize && newHeight < this.minSize[1]) {
                        this.sizeToGrow[1] += newHeight - this.minSize[1];
                        newHeight = this.minSize[1];
                    }

                    if (this.maxSize && newHeight > this.maxSize[1]) {
                        this.sizeToShrink[1] += this.maxSize[1] - newHeight;
                        newHeight = this.maxSize[1];
                    }

                    resized = true;
                } else {
                    newY += diff[1];
                }
            }
        }

        this.size = [newWidth, newHeight];
        this.position = [newX, newY];

        return resized;
    }

    setRectState(state) {
        if (state === this.rectState) {
            return;
        }

        if (state === 'fullscreen') {
            this.prevPosition = _.clone(this.position);
            this.prevSize = _.clone(this.size);
        }

        // let the parent container know I'm changing my rect state
        if (this.parent) {
            this.parent.onChildRectStateChanged(this, state);
        }

        this.prevRectState = this.rectState;
        this.rectState = state;

        this.setDirty();
        this.onEvent('rectState:changed', state);
    }

    onChildRectStateChanged(child, state) {
        if (this.parent) {
            this.parent.onChildRectStateChanged(child, state);
        }
    }

    buildStyle() {
        let style = {};

        switch(this.rectState) {
            case 'normal': {
                let position = this.position;
                let size = this.size;

                if (this.prevRectState === 'fullscreen') {
                    position = this.prevPosition;
                    size = this.prevSize;
                }

                style = {
                    position: 'absolute',
                    top: `${position[1]}px`,
                    left: `${position[0]}px`,
                    width: `${size[0]}px`,
                    height: `${size[1]}px`
                };

                if (this.zIndexExtra > 0) {
                    style.zIndex = this.zIndexExtra;
                }
            }
            break;

            case 'leftDocked':
                style = {
                    position: 'fixed',
                    top: '50px',
                    left: '60px',
                    bottom: '0px',
                    width: window.screen.width > 1110 ? 'calc(33% - 20px)' : '455px',
                    zIndex: 5,
                    boxShadow: '0 0 4px rgb(0 0 0 / 10%), 0 0 0 1px rgb(0 0 0 / 4%)'
                };
                break;

            case 'fullscreen':
                style = {
                    position: 'fixed',
                    top: '60px',
                    left: '70px',
                    bottom: '10px',
                    right: '10px',
                    zIndex: 10
                };
                break;
        }

        return style;
    }

    bringToFront() {
        this.zIndexExtra = 100;
        this.setDirty();
    }

    sendToBack() {
        this.zIndexExtra = 0;
        this.setDirty();
    }

    addEventListener(event, callback) {
        if (event in this.eventsListeners) {
            this.eventsListeners[event].push(callback);
        } else {
            this.eventsListeners[event] = [callback];
        }
    }

    onEvent(event, params) {
        if (!(event in this.eventsListeners)) {
            return;
        }

        for (const cb of this.eventsListeners[event]) {
            cb(params);
        }
    }

    refresh() {
        this.onEvent('refresh');
    }

    setDirty() {
        this.onEvent('dirty');
    }

    setPosition(x, y) {
        this.position = [x, y];
        this.setDirty();
    }

    setSize(w, h) {
        this.size = [w, h];
        this.setDirty();
    }

    setParent(parent) {
        const myPos = this.getScreenPosition();
        const newParentPos = parent.getScreenPosition();

        this.position = [myPos[0] - newParentPos[0], myPos[1] - newParentPos[1]];
        this.parent.removeWidget(this);

        parent.addWidget(this);

        this.setDirty();
    }

    getScreenPosition() {
        let pos = [this.position[0], this.position[1]];

        if (this.parent) {
            const parentPos = this.parent.getScreenPosition();

            pos[0] += parentPos[0];
            pos[1] += parentPos[1];
        }

        return pos;
    }

    select(selected) {
        if (this.selected === selected) {
            return;
        }

        this.selected = selected;
        this.setDirty();
    }

    overlap(overlapped) {
        if (this.overlapped === overlapped) {
            return;
        }

        this.overlapped = overlapped;
        this.setDirty();
    }

    getSaveInfo() {
        let info = {
            id: this.id,
            type: this.type,
            position: _.clone(this.position),
            size: _.clone(this.size),
            anchor: this.anchor,
            params: _.clone(this.params)
        };

        if (this.minSize) {
            info.min_size = _.clone(this.minSize);
            info.max_size = _.clone(this.maxSize);
        }

        return info;
    }
}

export class Container extends Widget {
    constructor(props) {
        super(props);

        this.isContainer = true;
    }

    createWidget(props) {
        let widget = null;

        props.fetcher = this.fetcher;
        props.triggerEvent = this.triggerEvent;
        props.parent = this;

        switch(props.type) {
            case 'panel':
                widget = new Panel(props);
                break;

            case 'tab_control':
                widget = new TabControl(props);
                break;

            default:
                widget = new Widget(props);
                break;
        }

        return widget;
    }
}

export class Panel extends Container {
    constructor(props) {
        super(props);

        this.type = 'panel';
        this.children = [];
        this.isRoot = props.isRoot;

        for (const child of props.children || []) {
            this.addNewWidget(child);
        }
    }

    onParentResized(diff) {
        const currentSize = _.clone(this.size);
        const resized = this.adjustSizeAndPosition(diff);

        if (resized) {
            for (const child of this.children) {
                const newDiff = [this.size[0] - currentSize[0], this.size[1] - currentSize[1]];
                child.onParentResized(newDiff);
            }
        }

        this.setDirty();
    }

    onDestroy() {
        for (const child of this.children) {
            child.onDestroy();
        }

        super.onDestroy();
    }

    findWidgets(widgetsId) {
        let widgets = [];

        for (const child of this.children) {
            if (child.isContainer) {
                widgets = widgets.concat(child.findWidgets(widgetsId));
            } else if (!widgetsId || widgetsId.indexOf(child.id) !== -1) {
                widgets.push(child);
            }
        }

        return widgets;
    }

    addNewWidget(widgetDef) {
        const widget = this.createWidget(widgetDef);

        this.children.push(widget);

        return widget;
    }

    addNewWidgets(widgetsDef) {
        for (const wd of widgetsDef) {
            this.addNewWidget(wd);
        }
    }

    addWidget(widget) {
        widget.parent = this;

        this.children.push(widget);
        this.setDirty();
    }

    removeWidget(widget) {
        widget.parent = null;

        this.children = this.children.filter(c => c.id !== widget.id);
        this.setDirty();
    }

    getContainerAtScreenPos(x, y, widgetIdToIgnore) {
        const myPos = this.getScreenPosition();

        for (const child of this.children) {
            if (!child.isContainer) {
                continue;
            }

            const container = child.getContainerAtScreenPos(x, y, widgetIdToIgnore);

            if (container) {
                return container;
            }
        }

        if (this.id !== widgetIdToIgnore && Utilities.pointInRect(x, y, myPos[0], myPos[1], this.size[0], this.size[1])) {
            return this;
        }

        return null;
    }

    onEvent(event, params) {
        for (const child of this.children) {
            child.onEvent(event, params);
        }

        super.onEvent(event, params);
    }

    getSaveInfo() {
        let children = [];

        for (const child of this.children) {
            children.push(child.getSaveInfo());
        }

        return children;
    }
}

export class TabControl extends Container {
    constructor(props) {
        super(props);

        this.type = 'tab_control';
        this.tabs = [];
        this.header = props.header;
        this.expandable = props.expandable;
        this.activeTabIdx = 0;

        const tabsHeight = 50; // this height is the tabs selector

        for (const tab of props.tabs) {
            if (!this.isAvailable(tab.available)) {
                continue;
            }

            const panel = new Panel({
                position: [0, tabsHeight],
                size: [props.size[0], props.size[1] - tabsHeight],
                anchor: ANCHOR_BITFIELD.all,
                fetcher: props.fetcher,
                triggerEvent: props.triggerEvent,
                parent: this
            });

            for (const child of tab.children) {
                panel.addNewWidget(child);
            }

            this.tabs.push({
                id: guid(), // todo: read this from the json
                title: this.processTabTitle(tab.title),
                style: tab.style || {},
                panel: panel,
                onSelected: tab.on_selected || null
            });
        }
    }

    processTabTitle(title) {
        let newTitle = title;

        while (true) {
            const tagStart = newTitle.indexOf('${');

            if (tagStart === -1) {
                break;
            }

            const tagEnd = newTitle.indexOf('}', tagStart);

            if (tagEnd === -1) {
                break;
            }

            const tag = newTitle.substr(tagStart, tagEnd);
            let tagValue = '';

            switch (tag) {
                case '${activities.fetch_info}': {
                    const preferences = app.user.get('client').preferences;

                    if (preferences.dashboard_activities_fetch_last_days && !isNaN(preferences.dashboard_activities_fetch_last_days)) {
                        if (preferences.dashboard_activities_fetch_last_days !== -1) {
                            const days = parseInt(preferences.dashboard_activities_fetch_last_days);
                            tagValue =` (${days > 1 ? `${days} days` : `24h` })`;
                        }
                    } else {
                        tagValue = ' (24h)';
                    }
                }
                break;
            }

            newTitle = Utilities.replaceAll(newTitle, tag, tagValue);
        }

        return newTitle;
    }

    isAvailable(toCheck) {
        if (!toCheck) {
            return true;
        }

        switch (toCheck) {
            case 'site_maps':
                return app.globalData.funnelsInfo.mapsAvailable;

            case 'deals_calendar':
                return _.contains(app.user.get('preferences').lab_flags, 'SAL-4868');

            case 'activities_list':
                return AppConfig.getValue('dashboard.is_widget_enabled', true, 'activities_list');
        }

        return true;
    }

    setSize(w, h) {
        if (w === this.size[0] && h === this.size[1]) {
            return;
        }

        const diff = [w - this.size[0], h - this.size[1]];

        this.size = [w, h];

        for (const tab of this.tabs) {
            tab.panel.onParentResized(diff);
        }

        this.setDirty();
    }

    onChildRectStateChanged(child, state) {
        if (this.rectState === 'fullscreen') {
            this.childModifiedMyState = child;
            this.shrink();
        } else if (this.rectState === 'normal' && this.childModifiedMyState?.id === child.id) {
            this.childModifiedMyState = null;
            this.expand();
        }
    }

    expand() {
        this.setRectState('fullscreen');
    }

    shrink() {
        this.setRectState('normal');
    }

    onParentResized(diff) {
        const currentSize = _.clone(this.size);
        const resized = this.adjustSizeAndPosition(diff);

        if (resized) {
            for (const tab of this.tabs) {
                const newDiff = [this.size[0] - currentSize[0], this.size[1] - currentSize[1]];
                tab.panel.onParentResized(newDiff);
            }
        }

        this.setDirty();
    }

    getContainerAtScreenPos(x, y, widgetIdToIgnore) {
        return this.tabs[this.activeTabIdx].panel.getContainerAtScreenPos(x, y, widgetIdToIgnore);
    }

    onDestroy() {
        for (const tab of this.tabs) {
            tab.panel.onDestroy();
        }

        super.onDestroy();
    }

    findWidgets(widgetsId) {
        let widgets = [];

        for (const tab of this.tabs) {
            widgets = widgets.concat(tab.panel.findWidgets(widgetsId));
        }

        return widgets;
    }

    onEvent(event, params) {
        for (const tab of this.tabs) {
            tab.panel.onEvent(event, params);
        }

        super.onEvent(event, params);
    }
}


class WidgetRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.widget = props.widget;
        this.mounted = false;

        this.state = {
            style: this.widget.buildStyle(),
            selected: false,
            overlapped: false,
            renderer: null
        };

        this.widget.addEventListener('dirty', this.onWidgetDirty.bind(this));
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            renderer: this.component
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onWidgetDirty() {
        if (!this.mounted) {
            return;
        }

        this.setState({
            style: this.widget.buildStyle(),
            selected: this.widget.selected,
            overlapped: this.widget.overlapped
        });
    }

    render() {
        const WidgetComponent = WIDGET_COMPONENT_BY_TYPE[this.widget.type];
        
        return (
            <div style={this.state.style}>
                <div
                    ref={(el) => this.component = el}
                    className={style.widget}
                >
                    <WidgetComponent
                        widget={this.widget}
                        renderer={this.state.renderer}
                        parent={this.props.parent}
                    />
                </div>

                {this.props.editing &&
                    <EditingHelperRenderer
                        widget={this.widget}
                        selected={this.state.selected}
                        overlapped={this.state.overlapped}
                    />
                }
            </div>
        );
    }
}

export class PanelRenderer extends WidgetRenderer {
    render() {
        return (
            <div style={this.state.style}>
                {this.props.editing &&
                    <div
                        className={style.grid}
                        id={`dashboard_editor_panel_grid_${this.widget.isRoot ? 'root' : this.widget.id}`}
                    />
                }

                <div
                    className={`
                        ${style.panel}
                        ${this.props.editing ? style.editing : ''}
                    `}
                >
                    {this.widget.children.map(child => {
                        const Renderer = getRendererByWidget(child);

                        return (
                            <Renderer
                                key={child.id}
                                widget={child}
                                editing={this.props.editing}
                                parent={this.props.parent}
                            />
                        );
                    })}
                </div>

                {this.props.editing && !this.props.fixed &&
                    <EditingHelperRenderer
                        widget={this.widget}
                        selected={this.state.selected}
                    />
                }
            </div>
        );
    }
}

export class TabControlRenderer extends WidgetRenderer {
    constructor(props) {
        super(props);

        this.state = _.extend(this.state, {
            activeTab: 0
        });
    
        this.widget.addEventListener('rectState:changed', this.onRectStateChanged.bind(this));
    }
    
    componentDidMount() {
        super.componentDidMount();

        const activeTab = this.widget.tabs[this.state.activeTab];

        if (activeTab.onSelected === 'fullscreen') {
            this.widget.expand();
        }
    }

    selectTab(tabIdx) {
        this.props.widget.activeTabIdx = tabIdx;

        this.setState({
            activeTab: tabIdx
        });

        const activeTab = this.widget.tabs[tabIdx];

        if (activeTab.onSelected === 'fullscreen') {
            this.widget.expand();
        }
    }

    handleExpandClick() {
        if (this.widget.rectState === 'normal') {
            this.widget.expand();
        } else {
            this.widget.shrink();
        }
    }

    onRectStateChanged() {
        const currentSize = this.widget.size;
        const self = this;

        _.defer(function() {
            const bbox = self.component.getBoundingClientRect();
            const diff = [bbox.width - currentSize[0], bbox.height - currentSize[1]];

            if (diff[0] || diff[1]) {
                self.widget.onParentResized(diff);
            }
        });
    }

    render() {
        let contentStyle = {};

        if (this.widget.tabs[this.state.activeTab].style.content_bg_color) {
            contentStyle.background = this.widget.tabs[this.state.activeTab].style.content_bg_color;
        }

        let header = null;

        if (this.widget.header) {
            header = (
                <div className={style.tcInfo}>
                    {this.widget.header.icon && <div className={`${this.widget.header.icon} ${style.iIcon}`}/>}
                    {this.widget.header.title && <div className={style.iTitle}>{this.widget.header.title}</div>}
                </div>
            );
        }

        return (
            <div
                ref={(el) => this.component = el}
                style={this.state.style}
            >
                <div
                    className={`
                        ${style.tabControl}
                        ${this.props.editing ? style.editing : ''}
                    `}
                >
                    <div className={style.tcHeader}>
                        {header}

                        <div className={style.tcTabs}>
                            {this.widget.tabs.map((tab, tidx) => {
                                return (
                                    <div
                                        key={tab.id}
                                        className={`
                                            ${style.tTab}
                                            ${tidx === this.state.activeTab ? style.tActive : ''}
                                        `}
                                        onClick={() => this.selectTab.bind(this)(tidx)}
                                    >
                                        {tab.title}
                                    </div>
                                );
                            })}

                            {this.widget.expandable &&
                                <div
                                    className={`
                                        ${style.tcExpandControl}
                                        ${this.widget.rectState === 'normal' ? 'icon-resize-enlarge' : 'icon-resize-shrink'}
                                    `}
                                    onClick={this.handleExpandClick.bind(this)}
                                />
                            }
                        </div>
                    </div>  
                    <div
                        className={style.tcContent}
                        style={contentStyle}>
                            <PanelRenderer
                                key={this.widget.tabs[this.state.activeTab].id}
                                widget={this.widget.tabs[this.state.activeTab].panel}
                                editing={this.props.editing}
                                fixed={true}
                                parent={this.props.parent}
                            />
                    </div>
                    
                </div>

                {this.props.editing &&
                    <EditingHelperRenderer
                        widget={this.widget}
                        selected={this.state.selected}
                    />
                }
            </div>
        );
    }
}


getRendererByWidget = function(widget) {
    if (!widget.isContainer) {
        return WidgetRenderer;
    }

    if (widget.type === 'tab_control') {
        return TabControlRenderer;
    } else if (widget.type === 'panel') {
        return PanelRenderer;
    }

    return null;
}
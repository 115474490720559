import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import vent from 'js/vent'
import Currency from 'js/utils/currency'
import dateFormat from 'js/utils/date-format'
import kpiTemplate from 'templates/kpi.handlebars'


export default Marionette.Layout.extend({
    className: 'kpi-widget',
    template: Handlebars.compile(kpiTemplate),
    ui: {
        timeSpan: '.timespan .time',
        leads: '.kpi-leads',
        leadsValue: '.leads-value',
        leadsPercentage: '.leads-percentage',
        asp: '.kpi-asp',
        aspValue: '.asp-value',
        aspPercentage: '.asp-percentage',
        cpl: '.kpi-cpl',
        cplValue: '.cpl-value',
        cplPercentage: '.cpl-percentage',
        salesCycle: '.kpi-sales-cycle',
        salesCycleValue: '.sales-cycle-value',
        salesCyclePercentage: '.sales-cycle-percentage',
        cpw: '.kpi-cpw',
        cpwValue: '.cpw-value',
        cpwPercentage: '.cpw-percentage',
        won: '.kpi-won',
        wonValue: '.won-value',
        wonPercentage: '.won-percentage',
        tooltips: '[data-toggle=tooltip]',
        customPeriod: '.custom-time-container',
        setCustomBeginDate: '.set-begin-date',
        setCustomEndDate: '.set-end-date',
    },
    events: {
        'click .header .day': function() {
            this.handleTimeSpanSelect('day', 'Daily Change', false);
        },
        'click .header .week': function() {
            this.handleTimeSpanSelect('week', 'Weekly Change', false);
        },

        'click .header .month': function() {
            this.handleTimeSpanSelect('month', 'Monthly Change', false);
        },

        'click .header .quarter': function() {
            this.handleTimeSpanSelect('quarter', 'Quarterly Change', false);
        },

        'click .header .year': function() {
            this.handleTimeSpanSelect('year', 'Yearly Change', false);
        },

        'click .header .custom': function() {
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },

        'change .set-begin-date': function() {
            this.customBeginDate = dateFormat.formatYYYYMMDDDate(this.ui.setCustomBeginDate.val());
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },

        'change .set-end-date': function() {
            this.customEndDate = dateFormat.formatYYYYMMDDDate(this.ui.setCustomEndDate.val());
            this.handleTimeSpanSelect('custom', 'Period Change', true);
        },

        'click .header .time': function(ev) {
            var active = $(ev.currentTarget);
            ev.preventDefault();
            active.addClass('active');
            this.ui.timeSpan.not(active).removeClass('active');
        },

        'focus .set-begin-date': function(e) {
            this.ui.setCustomBeginDate.datepicker( {
                numberOfMonths: 2,
                dateFormat: 'M dd, yy',
                showAnim: 'fadeIn',
                showButtonPanel: true,
                changeYear: true,
                timezone: "+0000"
            } );
        },
        'focus .set-end-date': function() {
            this.ui.setCustomEndDate.datepicker( {
                numberOfMonths: 2,
                dateFormat: 'M dd, yy',
                showAnim: 'fadeIn',
                showButtonPanel: true,
                changeYear: true,
                timezone: "+0000"
            } );
        },
    },

    initialize: function() {
        this.timeSpan = 'week';
    },

    onRender: function() {
        this.fetchData();
        this.ui.customPeriod.hide();

        this.ui.tooltips.tooltip({ placement: 'bottom' });
    },

    handleTimeSpanSelect: function(timeSpan, timeSpanLabel, showCustom) {
        this.timeSpan = timeSpan;
        this.period = timeSpanLabel;
        if(showCustom) {
            this.ui.customPeriod.show();
        } else {
            this.ui.customPeriod.hide();
        }

        if(!showCustom || (this.customBeginDate && this.customEndDate)){
            this.fetchData();
            vent.trigger('updateTimeSpan', this.timeSpan, this.period, this.customBeginDate, this.customEndDate);
        }
    },

    fetchData: function() {
        var view = this;
        var data = {
            time_span: this.timeSpan,
            begin_date: this.timeSpan === 'custom' ? this.customBeginDate : undefined,
            end_date: this.timeSpan === 'custom' ? this.customEndDate : undefined
        };

        $.post(
            '/widgets/kpi',
            JSON.stringify(data),
            function(d) {
                if (!view.isClosed) {
                    var currency = app.user.get('client').default_currency;

                    view.ui.leadsValue.text(d.leads.value);
                    view.ui.leadsPercentage.text(view.getPercentageString(d.leads.percentage));
                    view.setPerformanceClass(view.ui.leads, d.leads.percentage);

                    view.ui.aspValue.text(Currency.format(currency, d.asp.value));
                    view.ui.aspPercentage.text(view.getPercentageString(d.asp.percentage));
                    view.setPerformanceClass(view.ui.asp, d.asp.percentage);

                    view.ui.cplValue.text(Currency.format(currency, d.cost_lead.value));
                    view.ui.cplPercentage.text(view.getPercentageString(d.cost_lead.percentage));
                    view.setPerformanceClass(view.ui.cpl, d.cost_lead.percentage);

                    view.ui.salesCycleValue.text(Math.round(d.sales_cycle.value));
                    view.ui.salesCyclePercentage.text(view.getPercentageString(d.sales_cycle.percentage));
                    view.setPerformanceClass(view.ui.salesCycle, d.sales_cycle.percentage);

                    view.ui.cpwValue.text(Currency.format(currency, d.cost_won.value));
                    view.ui.cpwPercentage.text(view.getPercentageString(d.cost_won.percentage));
                    view.setPerformanceClass(view.ui.cpw, d.cost_won.percentage);

                    view.ui.wonValue.text(Math.round(d.win_loss.value));
                    view.ui.wonPercentage.text(view.getPercentageString(d.win_loss.percentage));
                    view.setPerformanceClass(view.ui.won, d.win_loss.percentage);
                }
            }
        );
    },

    getPercentageString: function(value) {
        var val = value ? Math.round(value) : null;

        if (val && val !== 0) {
            return Math.abs(val) + '%';
        }

        return '';
    },

    setPerformanceClass: function(el, value) {
        var val = value && Math.round(value);

        if (val && val !== 0) {
            el
                .toggleClass('down', (val < 0))
                .toggleClass('up', (val > 0))
                .removeClass('unchanged');
        } else {
            el
                .toggleClass('unchanged', val === 0 || value === 0)
                .removeClass('down up');
        }
    }
});

import operators from 'app_v2/filter_operators';
import TextManager from 'app/text-manager'

export default {
    Campaign: [{
        id: 'campaign_created',
        title: 'Created',
        operators: operators.dateOperators
    }, {
        id: 'campaign_creator_id',
        title: 'Creator',
        operators: operators.userOperators
    }, {
        id: 'campaign_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        operators: operators.boolOperators
    }, {
        id: 'campaign_modified',
        title: 'Modified',
        operators: operators.dateOperators
    }, {
        id: 'campaign_last_modified_by_me',
        title: 'Last Modified By Me',
        notTitle: 'Not Last Modified By Me',
        operators: operators.boolOperators
    }, {
        id: 'campaign_owner_id',
        title: 'Owner',
        operators: operators.userOperators
    }, {
        id: 'campaign_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'campaign_name',
        title: 'Name',
        operators: operators.textOperators
    }, {
        id: 'campaign_to_group_id',
        title: 'To Group',
        operators: operators.individualGroupOperators
    }, {
        id: 'campaign_subject',
        title: 'Subject',
        operators: operators.textOperators
    }, {
        id: 'campaign_campaign_type',
        title: 'Type',
        operators: operators.campaignTypeOperators
    }, {
        id: 'campaign_sent',
        title: 'Sent',
        operators: operators.dateOperators
    }, {
        id: 'campaign_tags',
        title: 'Tag',
        operators: operators.tagOperators
    }],
    Group: [{
        id: 'campaign_group_id',
        title: 'Group',
        operators: operators.individualGroupOperators
    }],
    [TextManager.parseText('${ID_FUNNEL, capitalize}')]: [{
        id: 'funnel_cluster_id',
        title: TextManager.parseText('${ID_CLUSTER, capitalize}'),
        operators: operators.clusterOperators
    }]
};

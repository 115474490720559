import Backbone from 'backbone'
import _ from 'underscore'
import Handlebars from 'handlebars'

import CampaignListingBaseView from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-listing-base'
import IndividualFavoritesModel from 'js/models/individual_favorites'
import ListingCampaignTableView from 'app/_components/IOD-listing/_components/listing-campaign-table'
import CampaignPerformanceView from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-performance'
import CampaignFinder from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-finder'
import CampaignSummarySheet from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-summary'
import ModalRegion from 'js/views/base/modal-region'
import app from 'js/app'
import vent from 'js/vent'
import TextManager from 'app/text-manager'
import security from 'js/utils/security'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import FilterQuickOptionsCampaigns from 'js/views/filters/quick-options-campaigns'
import CampaignFilterModel from 'js/models/campaign_filter'


var BaseOverviewView = CampaignListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    className: 'automated-campaign-overview',
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: true,
            hideFilters: false,
            disableLayoutSelection: true,
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    regions: _.extend({
        widgetsRegion: '.widgets-area',
        titleWidgetsRegion: '.title-widgets-area',
        summaryRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    }, BaseOverviewView.prototype.regions),
    groupType: 'Campaigns',
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = new CampaignFilterModel({id: this.options.filterId || this.filter_id});
        this.quickFilter = new FilterQuickOptionsCampaigns();

        this.fetchOptions = _.extend({
            for_automations: true
        }, this.fetchOptions);

        this.initialFetchOptions = _.clone(this.fetchOptions);
    },
    onRender: function() {
        BaseOverviewView.prototype.onRender.apply(this, arguments);

        var campaignPerformance = new CampaignPerformanceView({
            parent: this,
            fetchOptions: this.fetchOptions
        });

        this.listenTo(campaignPerformance, 'update-filters', function(filterOptions) {
            this.performanceFilters = _.clone(filterOptions);
            this.fetchOptions = _.extend(_.clone(this.initialFetchOptions), filterOptions);

            if (this.finderFilters) {
                this.fetchOptions = _.extend(this.fetchOptions, this.finderFilters);
            }

            this.fetchCollection();
        });

        this.widgetsRegion.show(campaignPerformance);

        // ...
        var campaignFinder = new CampaignFinder();

        this.listenTo(campaignFinder, 'update-filters', function(filterOptions) {
            this.finderFilters = _.clone(filterOptions);
            this.fetchOptions = _.extend(_.clone(this.initialFetchOptions), filterOptions);

            if (this.performanceFilters) {
                this.fetchOptions = _.extend(this.fetchOptions, this.performanceFilters);
            }

            campaignPerformance.setFetchOptions(this.fetchOptions);
            this.fetchCollection();
        });

        this.titleWidgetsRegion.show(campaignFinder);
    },
    onSelectItem: function(model) {
        this.options.parent.showItem(model, null, true);
    },
    onCampaignPreview: function(model) {
        this.options.parent.showItem(model, null, true);
    },
    onCampaignShowSummarySheet: function(model) {
        var summaryView = new CampaignSummarySheet({
            campaign: model
        });

        this.summaryRegion.show(summaryView);

        this.listenTo(summaryView, 'close', function() {
            this.summaryRegion.reset();
        });
    },
    onDelete: function(model) {
        this.collection.remove(model);
        model.destroy();
    },
    getMenuOptions: function() {
        return {
            disableSave: true,
            canDownloadAsCSV: true
        };
    },
    createTableView: function() {
        if (true) { // _.contains(app.user.get('preferences').lab_flags, 'SAL-3826')) {
            return new TableBodyContainerView({
                parent: this,
                collection: this.collection,
                type: this.options.type,
                elementType: this.options.elementType,
                tableModel: this.model,
                fixedWhenDefaultColumns: true,
                showBarRow: true,
                availableColumns: [
                    'name',
                    'automation',
                    'campaign_automation_status',
                    'modified',
                    'last_sent',
                    'email_event_stats_send',
                    'email_event_stats_delivered',
                    'email_event_stats_open',
                    'email_event_stats_click',
                    'email_event_stats_unsubscribed',
                    'email_event_stats_spam',
                    'email_event_stats_bounce',
                    'email_event_stats_open_rate',
                    'email_event_stats_click_rate',
                    'email_event_stats_delivered_rate',
                    'email_event_stats_hard_bounce',
                    'email_event_stats_soft_bounce',
                    'email_event_stats_unsubscribed_all',
                    'email_event_stats_unsubscribed_list'
                ],
                defaultColumns: [
                    'name',
                    'automation',
                    'campaign_automation_status',
                    'email_event_stats_send',
                    'email_event_stats_delivered',
                    'email_event_stats_open',
                    'email_event_stats_click',
                    'email_event_stats_unsubscribed',
                    'email_event_stats_spam',
                    'email_event_stats_bounce'
                ]
            });
        }
        else {
            return new ListingCampaignTableView({
                parent: this,
                model: this.model,
                collection: this.collection,
                availableColumns: [
                    'menu',
                    'icon',
                    'name',
                    'automation',
                    'campaign_automation_status',
                    'sent',
                    'delivered',
                    'opened',
                    'clicked',
                    'unsub',
                    'spam',
                    'bounce',
                    'last_updated_2',
                    'last_sent',
                    'open_rate',
                    'click_rate',
                    'delivered_rate',
                    'hard_bounce',
                    'soft_bounce',
                    'unsubscribed_all',
                    'unsubscribed_list'
                ],
                defaultColumns: [
                    'menu',
                    'icon',
                    'name',
                    'automation',
                    'campaign_automation_status',
                    'sent',
                    'delivered',
                    'opened',
                    'clicked',
                    'unsub',
                    'spam',
                    'bounce'
                ],
                extraRow: 'click-rate-bar',
                fixedHeader: true,
                sortId: [{
                    attribute: 'last_updated_2',
                    order: false
                }, {
                    attribute: 'status',
                    order: true
                }]
            });
        }
    }
});

export default CampaignListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);

        this.model = new Backbone.Model({ name: TextManager.parseText('Automated ${ID_CAMPAIGN, pluralize, capitalize}'), id: "automated" });
        this.model.local = true;
        this.model.doNotPersistSort = true;
        this.model.doNotPersistColumns = true;
    },
    onRender: function() {
        CampaignListingBaseView.prototype.onRender.apply(this);

        this.$el.find('.detail-name').tooltip();
    },
    getUrl: function() {
        var url = 'automated';
        if (this.campaignRegion && this.campaignRegion.currentView) {
            url += '/' + this.campaignRegion.currentView.getUrl();
        }
        return url;
    },
    getElementType: function() {
        return 'sent_campaigns';
    }
});

import BaseModel from 'js/models/base'


var Checklist = BaseModel.extend( {
    name: 'Checklist',
    urlRoot: '/checklists',
    apiType: 'salesseek.core.models.checklists.Checklists',
    toString: function() {
        return this.get( 'name' );
    }
} );

Checklist.fromString = function(name, auto_add_tasks, creator, state) {
    return new Checklist({name: name, auto_add_tasks: auto_add_tasks, creator: creator, state: state || {}});
};

export default Checklist;
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import Utilities from 'js/utils/utilities';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import { getRowCellComponent, getRowCellValue } from './row_cells';
import HeaderCells from './header_cells';
import ColumnPopover from './column_popover';
import PopoverMenu from 'app_v2/components/popover_menu/menu';

import style from './table.css';


class TableRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };

        this.selectCellComponent = null;
    }

    componentDidMount() {
        if (this.props.selected) {
            this.setSelect(true);
        }
    }

    handleSelect() {
        this.setState({
            selected: true
        });

        this.props.onSelected(this.props.row.id);
    }

    handleUnselect() {
        this.setState({
            selected: false
        });

        this.props.onUnselected(this.props.row.id);
    }

    setSelect(selected) {
        this.setState({
            selected: selected
        });

        this.selectCellComponent.setSelect(selected);
    }

    handleRowClick(ev) {
        ev.stopPropagation();

        if (this.props.rowsClickables && _.isEmpty(this.props.row.summaryInfo)) {
            this.props.onClicked(this.props.row.id, this.props.row);
        }
    }

    handleRowMouseEnter() {
        if (_.isEmpty(this.props.row.summaryInfo)) {
            this.props.onMouseEnter(this.props.row.id);
        }
    }

    handleRowMouseLeave() {
        if (_.isEmpty(this.props.row.summaryInfo)) {
            this.props.onMouseLeave(this.props.row.id);
        }
    }

    handleButtonClick(buttonId) {
        this.props.onButtonClicked(this.props.row.id, buttonId);
    }

    handleAction(actionId) {
        this.props.onAction(this.props.row.id, actionId);
    }

    handleContext(column, bbox) {
        this.props.onContext(this.props.row, column, bbox);
    }

    handleCellValueEdit(rowId, columnId, newValue) {
        if (this.props.onCellValueEdited) {
            this.props.onCellValueEdited(rowId, columnId, newValue);
        }
    }

    scrollIntoView() {
        this.rowComponent.scrollIntoView({
            block: 'nearest'
        });
    }

    render() {
        const summaryInfo = this.props.row.summaryInfo;
        const isSummaryRow = !_.isEmpty(summaryInfo);

        return (
            <div
                ref={(el) => this.rowComponent = el}
                className={`
                    ${style.rRow}
                    ${this.props.grouped ? style.rGrouped : ''}
                    ${this.state.selected ? style.rSelected : ''}
                    ${(this.props.rowsClickables && !isSummaryRow) ? style.rClickable : ''}
                    ${isSummaryRow ? style.rSummary : ''}
                `}
                onClick={this.handleRowClick.bind(this)}
                onMouseEnter={this.handleRowMouseEnter.bind(this)}
                onMouseLeave={this.handleRowMouseLeave.bind(this)}
            >
                {this.props.columns.map(column => {
                    const RowCellComponent = getRowCellComponent(column.type);

                    return (
                        <RowCellComponent
                            ref={(el) => { if (column.id === '__select__') { this.selectCellComponent = el } }}
                            key={`cell_${column.id}_${this.props.row.id}`}
                            column={column}
                            row={this.props.row}
                            parent={this.props.parent}
                            isSummaryTitle={isSummaryRow && summaryInfo.titleCellId === column.id}
                            isSummaryValue={isSummaryRow && summaryInfo.valueCellId === column.id}
                            onSelected={this.handleSelect.bind(this)}
                            onUnselected={this.handleUnselect.bind(this)}
                            onButtonClicked={this.handleButtonClick.bind(this)}
                            onAction={this.handleAction.bind(this)}
                            onContext={this.handleContext.bind(this)}
                            onCellValueEdited={this.handleCellValueEdit.bind(this)}
                        />
                    );
                })}
            </div>
        );
    }
}

class TableGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false,
            collapsed: false
        };

        this.rowsSelected = [];
        this.rowsComponents = {};
    }

    toggleCollapsed() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleRowClick(rowId, row) {
        this.props.onClicked(rowId, row);
    }

    handleRowMouseEnter(rowId) {
        this.props.onMouseEnter(rowId);
    }

    handleRowMouseLeave(rowId) {
        this.props.onMouseLeave(rowId);
    }

    handleRowButtonClick(rowId, buttonId) {
        this.props.onButtonClicked(rowId, buttonId);
    }

    handleRowAction(rowId, actionId) {
        this.props.onAction(rowId, actionId);
    }

    handleRowContext(row, column, bbox) {
        this.props.onContext(row, column, bbox);
    }

    handleRowSelect(rowId) {
        this.rowsSelected.push(rowId);
        this.props.onSelected(rowId);
    }

    handleRowUnselect(rowId) {
        this.rowsSelected = this.rowsSelected.filter(r => r !== rowId);
        this.props.onUnselected(rowId);

        if (this.state.selected) {
            this.setState({
                selected: false
            });
        }
    }

    handleCellValueEdit(rowId, columnId, newValue) {
        if (this.props.onCellValueEdited) {
            this.props.onCellValueEdited(rowId, columnId, newValue);
        }
    }

    handleSelect(ev) {
        ev.stopPropagation();
        this.setSelect(!this.state.selected, true);
    }

    setSelect(selected, triggerEvents) {
        this.setState({
            selected: selected
        });

        for (const row of this.props.row.rows) {
            if (triggerEvents) {
                if (selected) {
                    this.handleRowSelect(row.id);
                } else {
                    this.handleRowUnselect(row.id);
                }
            }

            this.rowsComponents[row.id]?.setSelect(selected);
        }
    }

    scrollIntoView() {
        this.headerComponent.scrollIntoView({
            block: 'nearest'
        });
    }

    render() {
        this.rowsComponents = {};

        const hasSelector = this.props.columns[0].type === 'select';
        const value = this.props.row.groupValue;

        return (
            <div className={style.rGroup}>
                <div
                    ref={(el) => this.headerComponent = el}
                    className={style.gHeader}
                    onClick={this.toggleCollapsed.bind(this)}
                >
                    {hasSelector &&
                        <div
                            className={`
                                ${style.gSelector}
                                ${this.state.selected ? style.gSelected : ''}
                                ${this.state.selected ? 'icon-checkmark' : 'icon-checkmark2'}
                            `}
                            style={{width: this.props.columns[0].width}}
                            onClick={this.handleSelect.bind(this)}
                        />
                    }

                    <div className={style.gTitle}>{this.props.row.groupValue}</div>

                    <div
                        className={`
                            ${style.gCaret}
                            ${this.state.collapsed ? 'icon-caret-right' : 'icon-caret-down'}
                        `}
                    />
                </div>

                {!this.state.collapsed &&
                    <div className={style.gRows}>
                        {this.props.row.rows.map((row, ridx) => {
                            return (
                                <TableRow
                                    ref={(el) => {if (el) {this.rowsComponents[row.id] = el}} }
                                    key={`row_${row.id}_${ridx}`}
                                    columns={this.props.columns}
                                    row={row}
                                    rowsClickables={this.props.rowsClickables}
                                    parent={this.props.parent}
                                    grouped={true}
                                    selected={this.rowsSelected.indexOf(row.id) !== -1}
                                    onSelected={this.handleRowSelect.bind(this)}
                                    onUnselected={this.handleRowUnselect.bind(this)}
                                    onClicked={this.handleRowClick.bind(this)}
                                    onMouseEnter={this.handleRowMouseEnter.bind(this)}
                                    onMouseLeave={this.handleRowMouseLeave.bind(this)}
                                    onButtonClicked={this.handleRowButtonClick.bind(this)}
                                    onAction={this.handleRowAction.bind(this)}
                                    onContext={this.handleRowContext.bind(this)}
                                    onCellValueEdited={this.handleCellValueEdit.bind(this)}
                                />
                            );
                        })}
                    </div>
                }
            </div>
        );
    }
}

class Paginator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            total: 0,
            numRows: 0,
            start: 0,
            visible: props.visible
        };
    }

    setData(page, start, total, numRows) {
        this.setState({
            page: page,
            start: start,
            total: total,
            numRows: numRows
        });
    }

    setVisible(visible) {
        this.setState({
            visible: visible
        });
    }

    render() {
        if (!this.state.visible || !this.state.total) {
            return null;
        }

        const from = this.state.start + 1;
        const to = this.state.start + this.state.numRows;

        return (
            <div className={style.paginator}>
                {this.state.page > 0 &&
                    <div
                        className={style.pAction}
                        onClick={() => this.props.onGotoPage(this.state.page - 1)}
                    >
                        Prev
                    </div>
                }

                <div className={style.pInfo}>{from}-{to} of {this.state.total}</div>

                {to < this.state.total &&
                    <div
                        className={style.pAction}
                        onClick={() => this.props.onGotoPage(this.state.page + 1)}
                    >
                        Next
                    </div>
                }
            </div>
        );
    }
}


export default class Table extends React.Component {
    constructor(props) {
        super(props);

        this.columnsRules = props.columnsRules || {};
        this.headerCellsComponents = null;
        this.totalRows = 0;
        this.selectableRows = props.selectableRows;
        this.selection = {
            page: null,
            rows: []
        };

        this.columns = [];

        this.state = {
            hasHorScroll: false,
            rows: this.props.rows || [],
            loading: false,
            groupByColumnId: null,
            popoverMenu: {
                visible: false
            },
            filterView: {
                visible: false
            }
        };
    }

    componentDidMount() {
        const self = this;

        _.defer(function() {
            self.setColumns(self.props.columns);
        });
    }

    handleRowClick(rowId, row) {
        this.props.onRowClick(rowId, row);
    }

    handleRowMouseEnter(rowId) {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(rowId);
        }
    }

    handleRowMouseLeave(rowId) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(rowId);
        }
    }

    handleRowButtonClick(rowId, buttonId) {
        this.props.onRowButtonClick(rowId, buttonId);
    }

    handleRowAction(rowId, actionId) {
        this.props.onRowAction(rowId, actionId);
    }

    onRowSelectionChange() {
        this.props.onRowSelectionChange({
            page: this.selection.page,
            rows: this.state.rows.filter(r => this.selection.rows.indexOf(r.id) !== -1)
        });
    }

    handleSelectRow(rowId) {
        if (this.selection.rows.indexOf(rowId) !== -1) {
            return;
        }

        this.selection.rows.push(rowId);
        this.selection.page = null;

        this.onRowSelectionChange();
    }

    handleUnselectRow(rowId) {
        if (this.selection.rows.indexOf(rowId) === -1) {
            return;
        }

        this.selection.rows = this.selection.rows.filter(r => r !== rowId);
        this.selection.page = null;
        this.headerCellsComponents['__select__'].setSelect(false);
        this.onRowSelectionChange();
    }

    handleSelectPage() {
        this.selection.page = {
            totalRows: this.totalRows
        };

        this.selection.rows = [];

        for (const rowId in this.rowsComponents) {
            const component = this.rowsComponents[rowId];

            component.setSelect(true);

            if (component.props.row.isGroup) {
                this.selection.rows = [...this.selection.rows, ...component.props.row.rows.map(r => r.id)];
            } else {
                this.selection.rows.push(rowId);
            }
        }

        this.onRowSelectionChange();
    }

    handleUnselectPage() {
        this.selection.page = null;
        this.selection.rows = [];

        for (const row of _.values(this.rowsComponents)) {
            row.setSelect(false);
        }

        this.onRowSelectionChange();
    }

    handleUnselectAll() {
        if (!this.selectableRows) {
            return;
        }

        this.selection.page = null;
        this.selection.rows = [];

        if (this.headerCellsComponents['__select__']) {
            this.headerCellsComponents['__select__'].setSelect(false);
        }

        for (const row of _.values(this.rowsComponents)) {
            row.setSelect(false);
        }

        this.onRowSelectionChange();
    }

    handleFilterViewShow(column) {
        const bbox = this.headerCellsComponents[column.id].getBbox();
        const tableBbox = this.table.getBoundingClientRect();

        let position = {
            top: bbox.top + bbox.height + 5,
            left: Utilities.clamp(bbox.left - 20, tableBbox.left, tableBbox.right - 222)
        };

        this.setState({
            filterView: {
                visible: true,
                position: position,
                column: column,
                rule: this.columnsRules[column.id]
            }
        });
    }

    handleFilterViewClearRules() {
        this.props.onFilterRuleChange(this.state.filterView.column.filterId, this.state.filterView.column.filterCustom, null);
    }

    handleFilterViewClose(closeInfo) {
        if (closeInfo) {
            if (closeInfo.sortDir) {
                this.props.onSortChange(this.state.filterView.column.sortId, closeInfo.sortDir);
            } else if ('rule' in closeInfo) {
                this.props.onFilterRuleChange(this.state.filterView.column.filterId, this.state.filterView.column.filterCustom, closeInfo.rule);
            } else if (closeInfo.showAdvancedFilter) {
                this.props.showAdvancedFilter();
            } else if ('groupBy' in closeInfo) {
                this.setState({
                    groupByColumnId: closeInfo.groupBy
                });
            }
        }

        const cellComponent = this.headerCellsComponents[this.state.filterView.column.id];
        cellComponent.setSelected(false);

        this.setState({
            filterView: {
                visible: false
            }
        });
    }

    handleRowContext(row, column, bbox) {
        let items = [];

        if (_.isFunction(column.menuItems)) {
            items = column.menuItems(row);
        } else {
            items = column.menuItems;
        }

        this.setState({
            popoverMenu: {
                visible: true,
                top: bbox.top - 10,
                left: bbox.right,
                anchor: 'left',
                row: row,
                items: items.filter(i => !i.disabled || !i.disabled())
            }
        });
    }

    handlePopoverMenuClose(itemId) {
        const row = this.state.popoverMenu.row;

        this.setState({
            popoverMenu: {
                visible: false
            }
        });

        if (!itemId) {
            return;
        }

        this.props.onRowContext(row, itemId);
    }

    handleCellValueEdit(row, column, newValue) {
        if (this.props.onCellValueEdited) {
            this.props.onCellValueEdited(row, column, newValue);
        }
    }

    setColumnsRules(rules) {
        this.columnsRules = rules;

        for (const columnId in this.headerCellsComponents || {}) {
            const cellComponent = this.headerCellsComponents[columnId];

            if (cellComponent.setActive) {
                cellComponent.setActive((columnId in this.columnsRules) && !this.columnsRules[columnId].notAvailable);
            }
        }
    }

    setLoading(loading) {
        this.setState({
            loading: loading
        });

        this.paginator?.setVisible(!loading);

        if (loading) {
            this.handleUnselectAll();
        } else {
            // scroll to the top
            if (this.firstRowComponent) {
                this.firstRowComponent.scrollIntoView();
            }
        }
    }

    setColumns(columns) {
        this.originalColumns = _.clone(columns || []); // doesnt include the selectable column or any other additional adhoc columns
        this.columns = _.clone(columns || []);

        if (this.selectableRows) {
            this.columns.unshift({
                id: '__select__',
                type: 'select',
                fixed: true,
                width: 50
            });
        }

        if (this.state.groupByColumnId) {
            if (!this.columns.find(c => c.id === this.state.groupByColumnId)) {
                this.setState({
                    groupByColumnId: null
                });
            }
        }

        this.adjustColumnsWidth();
    }

    getColumns() {
        return this.columns;
    }

    setSelectableRows(selectable) {
        if (selectable === this.selectableRows) {
            return;
        }

        this.selectableRows = selectable;
        this.setColumns(this.originalColumns);
    }

    setData(rows, page, start, total) {
        this.paginator?.setData(page, start, total, rows.length);
        this.totalRows = total;

        this.setState({
            rows: rows
        });
    }

    onStartResizing() {
        this.onResizing = true;

        const self = this;

        const tick = () => {
            self.onResize();

            if (self.onResizing) {
                self.resizingFrameId = window.requestAnimationFrame(tick);
            } else {
                window.cancelAnimationFrame(self.resizingFrameId);
            }
        }

        this.resizingFrameId = window.requestAnimationFrame(tick);
    }

    onEndResizing() {
        this.onResizing = false;
    }

    onResize() {
        this.adjustColumnsWidth();
    }

    adjustColumnsWidth() {
        if (!this.table) {
            return;
        }

        const bbox = this.table.getBoundingClientRect();
        let availableTableWidth = bbox.width - 30; // 30 == padding right & left
        let numColumnsSameWidth = this.columns.length;

        // fixed width columns
        for (const column of this.columns) {
            if (column.width) {
                availableTableWidth -= column.width;
                --numColumnsSameWidth;
            }
        }

        // greedy columns
        const existGreedyColumns = !!this.columns.find(c => c.greedyWidth);

        if (existGreedyColumns) {
            for (const column of this.columns) {
                if (!column.greedyWidth && column.minWidth && !column.width) {
                    availableTableWidth -= column.minWidth;
                    --numColumnsSameWidth;
                }
            }
        }

        const equalWidth = availableTableWidth / numColumnsSameWidth;
        let finalTableWidth = 0;

        for (const column of this.columns) {
            let finalWidth = 0;

            if (column.width) {
                finalWidth = column.width;
            } else {
                if (existGreedyColumns && column.minWidth) {
                    if (column.greedyWidth) {
                        finalWidth = Math.max(column.minWidth, equalWidth);
                    } else {
                        finalWidth = column.minWidth;
                    }
                } else {
                    finalWidth = equalWidth;
                }

                if (column.minWidth && finalWidth < column.minWidth) {
                    finalWidth = column.minWidth;
                }
            }

            column.finalWidth = `${finalWidth}px`;
            finalTableWidth += finalWidth;
        }

        this.setState({
            hasHorScroll: finalTableWidth > bbox.width
        });
    }

    getRowsProcessed() {
        if (!this.state.groupByColumnId) {
            return this.state.rows;
        }

        let rowsByValue = {};
        let valuesOrder = [];

        const column = this.columns.find(c => c.id === this.state.groupByColumnId);

        for (const row of this.state.rows) {
            let value = getRowCellValue(column, row[column.id], true);

            if (_.isObject(value)) {
                let innerValues = [];

                for (const k in value) {
                    if (value[k]) {
                        innerValues.push(value[k]);
                    }
                }

                value = innerValues.join(', ');
            }

            if (value in rowsByValue) {
                rowsByValue[value].push(row);
            } else {
                rowsByValue[value] = [row];
                valuesOrder.push(value);
            }
        }

        let rows = [];

        for (const [idx, value] of valuesOrder.entries()) {
            rows.push({
                id: `group_${idx}_${value}`,
                isGroup: true,
                groupValue: value,
                rows: rowsByValue[value]
            });
        }

        return rows;
    }

    render() {
        this.rowsComponents = {};
        this.headerCellsComponents = {};
        this.firstRowComponent = null;

        const rows = this.getRowsProcessed();
        let rowsClickables = true;

        if ('rowsClickables' in this.props) {
            rowsClickables = this.props.rowsClickables;
        }

        return (
            <div
                ref={(el) => this.table = el}
                className={`
                    ${style.table}
                    ${this.props.hasPaginator ? style.hasPaginator : ''}
                    ${this.state.hasHorScroll ? style.hasHorScroll : ''}
                `}
            >
                <div className={style.container}>
                    <div className={style.header}>
                        {this.columns.map(column => {
                            const HeaderCellComponent = HeaderCells(column.type);

                            return (
                                <HeaderCellComponent
                                    ref={(el) => {if(el) { this.headerCellsComponents[column.id] = el}}}
                                    key={`col_${column.id}`}
                                    column={column}
                                    tableFilterable={this.props.filterable}
                                    onPageSelected={this.handleSelectPage.bind(this)}
                                    onPageUnselected={this.handleUnselectPage.bind(this)}
                                    onFilter={this.handleFilterViewShow.bind(this)}
                                />
                            );
                        })}
                    </div>

                    {this.state.loading ? (
                        <div style={{
                            marginTop: '30px'
                        }}>
                            <LoadingIndicator/>
                        </div>
                    ) : (
                        <div
                            className={`
                                ${style.rows}
                                ${rows.length === 0 ? style.rEmpty : ''}
                            `}
                        >
                            {rows.map((row, ridx) => {
                                const Component = row.isGroup ? TableGroup : TableRow;

                                return (
                                    <Component
                                        ref={(el) => {
                                            if (el) {this.rowsComponents[row.id] = el}
                                            if (ridx === 0) {this.firstRowComponent = el}
                                        }}
                                        key={`row_${row.id}_${ridx}`}
                                        columns={this.columns}
                                        row={row}
                                        parent={this.props.parent}
                                        rowsClickables={rowsClickables}
                                        onSelected={this.handleSelectRow.bind(this)}
                                        onUnselected={this.handleUnselectRow.bind(this)}
                                        onClicked={this.handleRowClick.bind(this)}
                                        onMouseEnter={this.handleRowMouseEnter.bind(this)}
                                        onMouseLeave={this.handleRowMouseLeave.bind(this)}
                                        onButtonClicked={this.handleRowButtonClick.bind(this)}
                                        onAction={this.handleRowAction.bind(this)}
                                        onContext={this.handleRowContext.bind(this)}
                                        onCellValueEdited={this.handleCellValueEdit.bind(this)}
                                    />
                                );
                            })}

                            {rows.length === 0 &&
                                <div className={style.rNoItems}>No items to display</div>
                            }
                        </div>
                    )}
                </div>

                {this.props.hasPaginator &&
                    <Paginator
                        ref={(el) => this.paginator = el}
                        onGotoPage={this.props.onGotoPage}
                    />
                }


                {this.state.filterView.visible &&
                    <ColumnPopover
                        column={this.state.filterView.column}
                        tableFilterable={this.props.filterable}
                        rule={this.state.filterView.rule}
                        position={this.state.filterView.position}
                        groupBy={this.state.groupByColumnId}
                        onClose={this.handleFilterViewClose.bind(this)}
                        onClearRules={this.handleFilterViewClearRules.bind(this)}
                    />
                }

                {this.state.popoverMenu.visible &&
                    <PopoverMenu
                        top={this.state.popoverMenu.top}
                        left={this.state.popoverMenu.left}
                        anchor={this.state.popoverMenu.anchor}
                        items={this.state.popoverMenu.items}
                        onClose={this.handlePopoverMenuClose.bind(this)}
                    />
                }
            </div>
        );
    }
}

import _ from 'underscore'

import BaseCollection from 'js/collections/base'
import PhaseModel from 'js/models/phase'


var PhasesCollection = BaseCollection.extend({
    model: PhaseModel,
    /**
     * Sorts phases primary by phase type and secondary by given order.
     * SortBy() is a stable sort!
     */
    defaultSort: function() {
        var phaseTypeOrder = {'user': 1, 'won': 2, 'lost': 3};
        this.models = this.sortBy(function(el) {
            return el.attributes.order;
        });
        this.models = this.sortBy(function(el) {
            return phaseTypeOrder[el.attributes.phase_type];
        });
    },
    /**
     *  Returns the phases as children of theirs parent funnel
    */
    getAsHierarchy: function() {
        var phasesByFunnel = {};
        var wonLostPhases = [null, null];

        for (var i = 0; i < this.models.length; ++i) {
            var phase = this.models[i];
            var fid = phase.get('funnel_id');

            var entry = {
                id: phase.get('id'),
                name: phase.get('name'),
                order: phase.get('order'),
                phase_type: phase.get('phase_type')
            };

            if (!fid) { // won&lost phases
                wonLostPhases[(entry.phase_type === 'won') ? 0 : 1] = entry;
                continue;
            }

            // ...
            var funnel = phase.get('funnel');

            if (!(fid in phasesByFunnel)) {
                phasesByFunnel[fid] = {
                    fid: funnel.id, // it is called fid instead id because if we use id, the item would be selectable on the select2 box
                    order: funnel.order,
                    name: funnel.name,
                    children: []
                };
            }

            entry.funnel = {
                id: funnel.id,
                name: funnel.name
            };

            phasesByFunnel[funnel.id].children.push(entry);
        }

        var rdo = [];

        // add won&lost phases to all the funnels
        _.each(_.keys(phasesByFunnel), function(k) {
            phasesByFunnel[k].children = _.sortBy(phasesByFunnel[k].children, 'order');

            for (var w = 0; w < 2; ++w) {
                phasesByFunnel[k].children.push(
                    _.extend({
                        funnel: {
                            id: phasesByFunnel[k].fid,
                            name: phasesByFunnel[k].name
                        }
                    }, wonLostPhases[w])
                );
            }

            rdo.push(phasesByFunnel[k]);
        });

        return _.sortBy(rdo, 'order');
    },
    /**
     *  Returns won & lost phases in that order
    */
    getWonLost: function() {
        var wonLostPhases = [null, null];

        for (var i = 0; i < this.models.length; ++i) {
            var phase = this.models[i];
            var fid = phase.get('funnel_id');

            if (!fid) {
                var idx = (phase.get('phase_type') === 'won') ? 0 : 1;

                wonLostPhases[idx] = {
                    id: phase.get('id'),
                    name: phase.get('name')
                };

                if (wonLostPhases[0] && wonLostPhases[1]) {
                    break;
                }
            }
        }

        return wonLostPhases;
    }
});
export default PhasesCollection;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app.js'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format.js'
import BucketsCollection from 'js/collections/buckets.js'
import Currency from 'js/utils/currency.js'


var TableView, statusMap;

var Handsontable = window.Handsontable;

statusMap = {
    'none': TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}'),
    'none_upside': TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}'),
    'committed_downside': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}'),
    'committed': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
};

TableView = Marionette.Layout.extend({
    className: 'handsontable-container',
    // Inline styling on table el to fix strange issue with scrolling and fixed rows/columns
    template: Handlebars.compile('<div id="forecast-monthly-table" style="position: relative; overflow: auto;" />'),
    ui: {
        table: '#forecast-monthly-table'
    },
    initialize: function (options) {
        this.parent = options.parent;
        this.controller = options.controller;

        this.listenTo(this.controller, 'weighted', function(weighted) {
            if (!this.tableShown) {
                return;
            }
            this.showTable({ weighted: weighted });
        });
    },
    onRender: function() {
        var view = this;

        _.defer(function() {
            var container = $('.detail-forecasts.content-container').parent();
            container.on(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                function(ev) {
                    if (container[0].contains(ev.target)) {
                        _.defer(function() {
                            if (view.isClosed || !view.tableShown) {
                                return;
                            }
                            view.setTableSize();
                            view.ui.table.handsontable('render');
                        });
                    }
                }
            );
        });
        this.listenTo(vent, 'list:toggle-collapse', function() {
            if (!view.tableShown) {
                return;
            }
            view.setTableSize();
            view.ui.table.handsontable('render');
        });

        this.buckets = new BucketsCollection();
        this.buckets.fetch({
            rows: -1,
            success: function() {
                if (!view.isClosed) {
                    view.showTable({ weighted: view.options.controller.weighted });
                }
            }
        });
    },
    setTableSize: function() {
        var availableWidth = this.$el.width();
        var availableHeight = this.$el.height();
        this.ui.table.width(availableWidth).height(availableHeight);
    },
    showTable: function(options) {
        this.tableShown = true;

        // Table size has to be set before handsontable init
        this.setTableSize();

        this.resizeCallback = function() {
            if (this.isClosed) {
                return;
            }
            this.setTableSize();
            this.ui.table.handsontable('render');
        }.bind(this);

        $(window).on('resize', this.resizeCallback);

        this.ui.table.handsontable(_.extend({
            fillHandle: false,
            manualColumnMove: false,
            manualColumnResize: false,
            readOnly: true,
            stretchH: 'all',
            fixedColumnsLeft: 1,
            fixedRowsTop: 2
        }, this.getData(options)));
    },
    onBeforeClose: function() {
        $(window).off('resize', this.resizeCallback);
    },
    getData: function(options) {
        var phase_type_order = ['won', 'user', 'lost'];

        // Get the forecast opportunity models
        var fopps = this.model.get('forecast_opportunities');
        fopps = _.filter(fopps, function(m) {
            return m.opportunity.expected_close_date;
        });

        // Sort them by phase
        fopps = _.sortBy(fopps, function(m) {
            return -m.opportunity.phase.order;
        });
        fopps = _.sortBy(fopps, function(m) {
            var phase_type = m.opportunity.phase.phase_type;
            return phase_type_order.indexOf(phase_type);
        });

        // Obtain month range
        var startDate = new Date();
        var endDate = startDate;
        _.each(fopps, function(m) {
            var closeDate = m.opportunity.expected_close_date;
            closeDate = new Date(closeDate);

            if (startDate > closeDate) {
                startDate = closeDate;
            }

            if (endDate < closeDate) {
                endDate = closeDate;
            }
        });

        // Create months
        var months = [];
        var currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

        while (currentDate < endDate) {
            months.push(currentDate);
            currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1);
        }

        // Create headers
        var colHeaders = ['Monthly Forecast', '', '', '', '', ''];
        var topRow = [TextManager.parseText('${ID_DEAL, capitalize}'), TextManager.parseText('${ID_ORGANIZATION, capitalize}'), 'Funnel',
                      TextManager.parseText('${ID_PHASE, capitalize}'), TextManager.parseText('${ID_STATUS, capitalize}'), 'Weight'];
        var colWidths = [200, 200, 100, 100, 120, 80];
        var mergeCells = [];
        var weightColumn = 5;

        var totalRowsLookup = [];
        var renderer = function(instance, td, row, col, prop, value) {
            if (row < 2) {
                Handsontable.Dom.addClass(td, 'col-heading');
            }
            if (totalRowsLookup[row]) {
                Handsontable.Dom.addClass(td, 'phase-total-cell');
            }
            if (typeof value === 'number') {
                Handsontable.Dom.addClass(td, 'htNumeric');
            }

            Handsontable.renderers.TextRenderer.apply(this, arguments); //render as text

            return td;
        };
        var columns = [{renderer: renderer}, {renderer: renderer}, {renderer: renderer}, {renderer: renderer}, {renderer: renderer}, {renderer: renderer}];

        var buckets = this.buckets;
        var currency = app.user.get('client').default_currency;

        _.each(months, function(month) {

            colHeaders.push(dateFormat.shortFormatMonth(month.getMonth()));

            for (var i = 0; i < buckets.length - 1; i++) {
                colHeaders.push('');
            }

            buckets.each(function(bucket) {
                topRow.push(bucket.get('name'));
                colWidths.push(130);
                columns.push({
                    renderer: function(instance, td, row, col, prop, value, cellProperties) {
                        if (row < 2) {
                            Handsontable.Dom.addClass(td, 'col-heading');
                        }
                        if (totalRowsLookup[row]) {
                            Handsontable.Dom.addClass(td, 'phase-total-cell');
                        }

                        if (typeof value === 'number') {
                            Handsontable.Dom.addClass(td, 'htNumeric');
                            value = (col === weightColumn) ? value : Currency.format(currency, value);
                        }

                        //render as text
                        Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties);

                        return td;
                    }
                });
            });
        });

        var data = _.map(fopps, function(m) {
            var closeDate = m.opportunity.expected_close_date;
            closeDate = new Date(closeDate);
            var weight = m.weight;

            var values = [
                m.opportunity.name,
                m.opportunity.organization ? m.opportunity.organization.name : '',
                m.opportunity.funnel.name,
                m.opportunity.phase.name,
                statusMap[m.status],
                weight
            ];

            _.each(months, function(month) {
                var start = values.length;
                buckets.each(function(bucket) {
                    if (closeDate.getFullYear() === month.getFullYear() &&
                        closeDate.getMonth() === month.getMonth())
                    {
                        var buckets = m.forecast_opportunity_buckets;

                        var foppBucket = _.find(buckets, function(oppBucket) {
                            return bucket.id === oppBucket.bucket_id;
                        });

                        if ( ! _.isUndefined(foppBucket) ) {
                            var value = foppBucket.value;
                            if (options && options.weighted) {
                                value = value * weight;
                            }
                            values.push(value);
                        } else {
                            values.push(0);
                        }

                    } else {
                        values.push(0);
                    }
                });
                mergeCells.push({
                    row: 0,
                    col: start,
                    rowspan: 1,
                    colspan: values.length - start
                });
            });

            return values;
        });


        if (data.length > 0) {
            // Precompute total rows
            var currentPhase = data[0][3];
            var totals = _.map(_.rest(data[0], 6), function() { return 0; });
            var grandTotals = _.map(_.rest(data[0], 6), function() { return 0; });
            var totalRows = [];
            var insertionPoint = 0;

            var createTotalRow = function() {
                totalRows.push({
                    insertionPoint: insertionPoint,
                    phaseName: currentPhase,
                    totals: totals
                });

                // After inserting this row total plus an empty row, further row totals insertion points are
                // displaced 2 extra positions
                insertionPoint += 1;

                // And reset the totals array
                totals = _.map(_.rest(data[0], 6), function() { return 0; });
            };

            _.each(data, function(row) {
                if (currentPhase !== row[3]) {
                    createTotalRow();
                }

                currentPhase = row[3];
                for (var i = 0; i < totals.length; i++) {
                    totals[i] += row[i+6];

                    if (currentPhase !== 'Lost') {
                        grandTotals[i] += row[i+6];
                    }
                }

                insertionPoint += 1;
            });
            createTotalRow();

            // Set the length to the same as data
            totalRowsLookup.length = data.length;

            // Inject total rows
            _.each(totalRows, function(totalRow) {
                var newRow = [
                    'Total ' + totalRow.phaseName,
                    '',
                    '',
                    '',
                    '',
                    ''
                ];

                newRow = newRow.concat(totalRow.totals);

                data.splice(totalRow.insertionPoint, 0, newRow);
                totalRowsLookup.splice(totalRow.insertionPoint, 0, true);
            });

            // Inject grand totals
            var grandTotalsRow = [
                'Totals',
                '',
                '',
                '',
                '',
                ''
            ];

            grandTotalsRow = grandTotalsRow.concat(grandTotals);

            data.push(grandTotalsRow);

            // Add column headers
            data.splice(0, 0, colHeaders, topRow);
            // Offset totalRowsLookup
            totalRowsLookup.splice(0, 0, void 0, void 0);
        }

        // Remove zeroes
        _.each(data, function(row) {
            _.each(row, function(value, index) {
                if (value === 0) {
                    row[index] = '';
                }
            });
        });

        return {
            data: data,
            colHeaders: false,
            colWidths: colWidths,
            columns: columns,
            mergeCells: mergeCells
        };
    }
});

export default TableView;

import _ from 'underscore'
import Handlebars from 'handlebars'

import app from 'js/app.js'
import BaseItemView from 'js/views/base/item.js'
import AppConfig from 'app/app-config.js'
import opportunityItemTemplate from 'templates/opportunities/item.handlebars'


var OpportunityItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(opportunityItemTemplate),
    templateHelpers: function() {
        var bubbleText = this.model.get(AppConfig.getClientPreferenceValue('showDealIdOnBubble') ? 'abbreviation' : 'name').substring(0, 5);

        return {
            currency: this.model.get('currency'),
            value: AppConfig.getValue('deals.groups.display_value', true) && this.model.get('value'),
            organizationName: this.model.get('organization.name'),
            ownerCurrentUser: (this.model.get('owner') === app.user),
            funnelName: this.model.get('funnel').name,
            phase_name: this.model.get('phase').name,
            truncAbbreviation: bubbleText
        };
    },
    events: _.extend({
        'mouseenter': function() {
            this.trigger('mouseenter');
        },
        'mouseleave': function() {
            this.trigger('mouseleave');
        }
    }, BaseItemView.prototype.events),
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        this.confirmOnClick = true;
    }
});

export default OpportunityItemView;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import Utilities from 'js/utils/utilities'
import security from 'js/utils/security'
import CampaignModel from 'js/models/campaign'
import IndividualFilterModel from 'js/models/individual_filter'
import TableDetailView from 'js/views/base/table_detail'
import CampaignMenuPopover from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-menu-popover'
import dateFormat from 'js/utils/date-format'

var openSmartGroup = function(field, rowData) {
    var filterModel = new IndividualFilterModel();
    filterModel.save({
            rules: [[{
                field: field,
                operator: "any",
                values: {
                    campaign: {
                        id: rowData.id,
                        name: rowData.name
                    }
                }
            }]]
        },
        {
        success: function() {
            // sp for skip persistence
            window.location = '/#contacts/individuals?filter_id=' + filterModel.get('id') + '&sp';
        }
    });
};

var COLUMNS = [
    {
        pinned: true,
        id: 'menu',
        name: '',
        template: Handlebars.compile('<div class="menu-container"></div><a class="item-menu-toggle"> <i class="icon-dotdotdot"></i> </a>'),
        onRender: function(el, rowData, colData, tableBodyView) {
            el.find('.item-menu-toggle').click(function(ev) {
                // Allow user to see default browser contextmenu with shift + click
                if (ev.shiftKey) {
                    return;
                }

                var model = tableBodyView.parent.collection.get(rowData);
                var hasEditPermission = security.checkPermission('edit', model);

                ev.stopPropagation();
                ev.preventDefault();

                var menuItems;
                switch (rowData.status) {
                    case 'draft':
                    case 'ready':
                        menuItems = [
                            {
                                name: 'edit',
                                text: 'Edit',
                                disabled: function() {
                                    return !hasEditPermission;
                                }
                            },
                            {
                                name: 'permissions',
                                text: 'Set Permissions',
                                disabled: function() {
                                    return !hasEditPermission || app.user.get('client').permission_type === 'rba';
                                }
                            },
                            {
                                name: 'preview',
                                text: 'Preview'
                            },
                            {
                                name: 'duplicate',
                                text: 'Duplicate'
                            },
                            {
                                name: 'delete',
                                text: 'Delete',
                                navigation: true,
                                disabled: function() {
                                    return !hasEditPermission;
                                }
                            }
                        ];
                        break;
                    case 'scheduled':
                        menuItems = [
                            {
                                name: 'edit-schedule',
                                text: 'Edit Schedule',
                                disabled: function() {
                                    return !hasEditPermission;
                                }
                            },
                            {
                                name: 'permissions',
                                text: 'Set Permissions',
                                disabled: function() {
                                    return !hasEditPermission || app.user.get('client').permission_type === 'rba';
                                }
                            },
                            {
                                name: 'cancel-campaign',
                                text: 'Cancel Campaign',
                                navigation: true,
                                disabled: function() {
                                    return !hasEditPermission;
                                }
                            },
                            {
                                name: 'preview',
                                text: 'Preview'
                            },
                            {
                                name: 'duplicate',
                                text: 'Duplicate'
                            }
                        ];
                        break;
                    case 'sent':
                        menuItems = [
                            {
                                name: 'preview',
                                text: 'Preview'
                            },
                            {
                                name: 'duplicate',
                                text: 'Duplicate'
                            }
                        ];
                        break;
                }

                if (rowData.automation || rowData.automation2) {
                    menuItems = [
                        {
                            name: 'preview',
                            text: 'Preview'
                        },
                        {
                            name: 'duplicate',
                            text: 'Duplicate'
                        }
                    ]
                }

                var popoverContainerView = new CampaignMenuPopover({
                    model: model,
                    collection: new Backbone.Collection(menuItems)
                });

                popoverContainerView.listenTo(popoverContainerView, 'popover:click:preview', function() {
                    popoverContainerView.close();

                    tableBodyView.parent.trigger('campaign:preview', model);
                });
                popoverContainerView.listenTo(popoverContainerView, 'popover:click:edit', function() {
                    popoverContainerView.close();

                    tableBodyView.parent.trigger('campaign:edit', model, tableBodyView.parent.collection);
                });
                popoverContainerView.listenTo(popoverContainerView, 'popover:click:delete-confirm', function() {
                    popoverContainerView.close();

                    tableBodyView.parent.trigger('delete', model);
                });

                popoverContainerView.listenTo(popoverContainerView, 'popover:click:edit-schedule', function() {
                    popoverContainerView.close();

                    tableBodyView.parent.trigger('select', model);
                });

                popoverContainerView.listenTo(popoverContainerView, 'popover:click:cancel-confirm', function() {
                    popoverContainerView.close();

                    model.cancel(function() {
                        tableBodyView.parent.collection.remove(model);
                    }, { silent: true });
                });

                popoverContainerView.listenTo(popoverContainerView, 'popover:click:duplicate', function() {
                    popoverContainerView.close();

                    var newModel = new CampaignModel(model.attributes);

                    newModel.set('id', null);
                    newModel.set('owner', null);
                    newModel.set('owner_id', null);
                    newModel.set('is_template', false);

                    newModel.duplicate(function() {
                        tableBodyView.parent.fetchCollection(true);
                    });
                });

                popoverContainerView.listenTo(popoverContainerView, 'popover:click:permissions', function() {
                    popoverContainerView.close();
                    tableBodyView.parent.trigger('campaign:permissions', model);
                });

                popoverContainerView.render();
                popoverContainerView.$el.show();
                el.find('.menu-container').html(popoverContainerView.$el);
                popoverContainerView.onShow();

                Utilities.positionMenu(popoverContainerView.$el, $(ev.currentTarget), 30, 10);
            });
        },
        headerClass: 'menu',
        constHeaderWidth: '40'
    },
    {
        pinned: true,
        id: 'icon',
        name: '',
        template: Handlebars.compile('<span class="item-type-icon"><i class="{{iconClass}}"></i></span>'),
        templateHelpers: function(data) {
            var iconClass;
            if ((data.status === "ready" || data.status === "draft") && data.used_in_automations) {
                iconClass = 'icon-automated-email';
            }
            else {
                switch (data.campaign_type) {
                    case 'direct':
                        iconClass = 'icon-direct-email';
                        break;
                    case 'campaign':
                        iconClass = 'icon-campaign-email';
                        break;
                    case 'message':
                        iconClass = 'icon-campaign-sms';
                        break;
                }
            }
            return {
                iconClass: iconClass
            }
        },
        headerClass: 'icon',
        constHeaderWidth: '40'
    },
    {
        id: 'name',
        name: TextManager.parseText('${ID_CAMPAIGN, capitalize}'),
        sort: ['name'],
        columns: {name: true},
        template: Handlebars.compile('{{name}}'),
        headerClass: 'campaign-name',
        minHeaderWidth: '200'
    },
    {
        id: 'send_to',
        name: 'Send To',
        sort: ['to_group.name'],
        columns: {to_group: [{ name: true }]},
        template: Handlebars.compile('<a href="/#contacts/group/individuals/{{to_group.id}}">{{to_group.name}}</a>'),
        onRender: function(el) {
            el.on('click', function(ev) {
                ev.stopPropagation();
            });
        }
    },
    {
        id: 'sent_to',
        name: 'Sent To',
        sort: ['to_group.name'],
        columns: {to_group: [{ name: true }]},
        template: Handlebars.compile('<a href="/#contacts/group/individuals/{{to_group.id}}">{{to_group.name}}</a>'),
        onRender: function(el) {
            el.on('click', function(ev) {
                ev.stopPropagation();
            });
        },
        maxHeaderWidth: '200'
    },
    {
        id: 'send_from',
        name: 'Send From',
        sort: ['from.name'],
        columns: {from_user: [{ name: true }]},
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(data) {
            var name = '';

            if (data.from_user) {
                name = data.from_user.name;
            } else if (data.from_custom_field) {
                name = data.from_custom_field.name + ' (Custom Field)';
            }

            return {
                name: name
            };
        }
    },
    {
        id: 'sent_from',
        name: 'Sent From',
        sort: ['from.name'],
        columns: {from_user: [{ name: true }]},
        template: Handlebars.compile('{{name}}'),
        templateHelpers: function(data) {
            var name = '';

            if (data.from_user) {
                name = data.from_user.name;
            } else if (data.from_custom_field) {
                name = data.from_custom_field.name + ' (Custom Field)';
            }

            return {
                name: name
            };
        }
    },
    {
        id: 'created',
        name: 'Created',
        sort: ['created'],
        columns: {created: true},
        template: Handlebars.compile('{{formatEntityInformationDate created}}')
    },
    {
        id: 'last_updated',
        name: 'Updated',
        sort: ['modified'],
        columns: {modified: true},
        template: Handlebars.compile('{{formatTimelineDate modified}}')
    },
    {
        id: 'date',
        name: 'Sent Date',
        sort: ['sent'],
        columns: {sent: true},
        template: Handlebars.compile('{{formatTimelineDate sent}}'),
        templateHelpers: function(data) {
            return {
                sent: dateFormat.parseDate(data.sent)
            };
        },
        headerClass: 'send-date',
        maxHeaderWidth: '140'
    },
    {
        id: 'scheduled_for',
        name: 'Scheduled For',
        sort: ['campaign_schedule.when_utc'],
        columns: {campaign_schedule: [{ when: true }]},
        template: Handlebars.compile('{{formatRawScheduledDate campaign_schedule.when}} ({{getTZName campaign_schedule.timezone}})')
    },
    {
        id: 'subject',
        name: 'Subject',
        sort: ['subject'],
        columns: {subject: true},
        template: Handlebars.compile('{{subject}}')
    },
    {
        id: 'automation',
        name: 'Automation',
        sort: ['automation.name'],
        columns: {automation: [{ name: true }]},
        template: Handlebars.compile('<a href="{{url}}">{{name}}</a>'),
        templateHelpers: function(data) {
            var name = '';
            var url = '';

            if (data.automation) {
                name = data.automation.name;
                url = '/#automations/' + data.automation.id;
            } else if (data.automation2) {
                name = data.automation2.name;
                url = '/#automations';
            }

            return {
                name: name,
                url: url
            }
        },
        onRender: function(el, rowData, colData) {
            el.on('click', function(ev) {
                ev.stopPropagation();
            });
        }
    },
    {
        id: 'campaign_automation_status',
        name: 'Status',
        sort: ['campaign_automation_status'],
        columns: { campaign_automation_status: true },
        template: Handlebars.compile('{{campaign_automation_status}}')
    },
    {
        id: 'sent',
        name: 'Sent',
        style: 'color: #c1c4cd;',
        sort: ['email_event_stats.send'],
        columns: {email_event_stats: [{ send: true }]},
        template: Handlebars.compile('<div class="clickable">{{email_event_stats.send}}</div>'),
        maxHeaderWidth: '55',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_send", rowData);
            });
        }
    },
    {
        id: 'delivered',
        name: 'Delivered',
        style: 'color: lightgray;',
        sort: ['email_event_stats.delivered'],
        columns: {email_event_stats: [{ delivered: true }]},
        template: Handlebars.compile('<div class="clickable">{{email_event_stats.delivered}}</div>'),
        maxHeaderWidth: '92',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_delivered", rowData);
            });
        }
    },
    {
        id: 'opened',
        name: 'Opened',
        style: 'color: lightgreen;',
        sort: ['email_event_stats.open'],
        columns: {email_event_stats: [{ open: true }]},
        template: Handlebars.compile('<div class="clickable">{{value}}</div>'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.open
            };
        },
        maxHeaderWidth: '74',
        onRender: function(el, rowData, colData, tableBodyView) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_open", rowData);
            });
        }
    },
    {
        id: 'clicked',
        name: 'Clicked',
        style: 'color: #72E28A;',
        sort: ['email_event_stats.click'],
        columns: {email_event_stats: [{ click: true }]},
        template: Handlebars.compile('<div class="clickable">{{value}}</div>'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.click
            };
        },
        maxHeaderWidth: '77',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_click", rowData);
            });
        }
    },
    {
        id: 'unsub',
        name: 'Unsub',
        style: 'color: brown;',
        sort: ['email_event_stats.unsubscribed'],
        columns: {email_event_stats: [{ unsubscribed: true }]},
        template: Handlebars.compile('<div class="clickable">{{value}}</div>'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.unsubscribed
            };
        },
        maxHeaderWidth: '66',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_unsubscribed", rowData);
            });
        }
    },
    {
        id: 'spam',
        name: 'Spam',
        style: 'color: #F28F5A;',
        sort: ['email_event_stats.spam'],
        columns: {email_event_stats: [{ spam: true }]},
        template: Handlebars.compile('<div class="clickable">{{value}}</div>'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.spam
            };
        },
        maxHeaderWidth: '56',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_spam", rowData);
            });
        }
    },
    {
        id: 'bounce',
        name: 'Bounce',
        style: 'color: #F0D34D;',
        sort: ['email_event_stats.bounce'],
        columns: {email_event_stats: [{ bounce: true }]},
        template: Handlebars.compile('<div class="clickable">{{value}}</div>'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.bounce
            };
        },
        maxHeaderWidth: '76',
        onRender: function(el, rowData, colData) {
            el.attr('style', colData.style);

            el.on('click', function(ev) {
                ev.stopPropagation();
                openSmartGroup("campaign_stats_bounce", rowData);
            });
        }
    },
    {
        id: 'last_updated_2',
        name: 'Last Updated',
        sort: ['modified'],
        columns: {modified: true},
        template: Handlebars.compile('{{formatTimelineDate modified}}')
    },
    {
        id: 'last_sent',
        name: 'Last Sent',
        sort: ['last_sent'],
        columns: {sent: true},
        template: Handlebars.compile('{{formatTimelineDate last_sent}}'),
        headerClass: 'send-date',
        maxHeaderWidth: '140'
    },
    {
        id: 'open_rate',
        name: 'Open Rate',
        sort: ['email_event_stats.open_rate'],
        columns: {email_event_stats: [{ open_rate: true }]},
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : (data.email_event_stats.open_rate + '%')
            };
        }
    },
    {
        id: 'click_rate',
        name: 'Click Rate',
        sort: ['email_event_stats.click_rate'],
        columns: {email_event_stats: [{ click_rate: true }]},
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : (data.email_event_stats.click_rate + '%')
            };
        }
    },
    {
        id: 'delivered_rate',
        name: 'Delivery Rate',
        sort: ['email_event_stats.delivered_rate'],
        columns: {email_event_stats: [{ delivered_rate: true }]},
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : (data.email_event_stats.delivered_rate + '%')
            };
        }
    },
    {
        id: 'hard_bounce',
        name: 'Hard Bounce',
        sort: ['email_event_stats.hard_bounce'],
        columns: {email_event_stats: [{ hard_bounce: true }]},
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.hard_bounce
            };
        }
    },
    {
        id: 'soft_bounce',
        name: 'Soft Bounce',
        sort: ['email_event_stats.soft_bounce'],
        columns: {email_event_stats: [{ soft_bounce: true }]},
        template: Handlebars.compile('{{value}}'),
        templateHelpers: function(data) {
            return {
                value: data.campaign_type === 'message' ? '-' : data.email_event_stats.soft_bounce
            };
        }
    },
    {
        id: 'unsubscribed_all',
        name: 'All Emails Unsubscribed',
        sort: ['email_event_stats.unsubscribed_all'],
        columns: {email_event_stats: [{ unsubscribed_all: true }]},
        template: Handlebars.compile('{{email_event_stats.unsubscribed_all}}')
    },
    {
        id: 'unsubscribed_list',
        name: 'List Only Unsubscribed',
        sort: ['email_event_stats.unsubscribed_list'],
        columns: {email_event_stats: [{ unsubscribed_list: true }]},
        template: Handlebars.compile('{{email_event_stats.unsubscribed_list}}')
    }
];

var DEFAULT_COLUMNS = [
    'menu',
    'icon',
    'name',
    'send_to',
    'send_from',
    'created',
    'last_updated',
    'subject'
];

var EXTRA_ROWS = {
    'click-rate-bar': {
        template: '<tr class="sent-campaign-bar-row">' +
            '<td colspan="12">' + // value updated in onRender
                '<div class="bar-container">' +
                    '<div class="opened-bar"><span class="val"></span></div>' +
                    '<div class="clicked-bar"><span class="val"></span></div>' +
                '</div>' +
            '</td>' +
        '</tr>',
        onRender: function(el, data) {
            if (data.email_event_stats.send === 0) {
                el.hide();
                return;
            }
            var opened = data.email_event_stats.open_rate;
            var clicked = data.email_event_stats.click_rate;
            if (opened === 0) {
                el.hide();
                return;
            }

            var openedBar = el.find('.opened-bar');
            openedBar.css('width', opened + '%');
            if (opened > 3 && (opened - clicked) > 5) {
                openedBar.find('.val').text(Math.round(opened) + '%');
            }

            var clickedBar = el.find('.clicked-bar');
            if (clicked === 0) {
                clickedBar.hide();
            }
            else {
                clickedBar.css('width', 'calc(' + clicked + '% - 6px)');
                if (clicked > 3) {
                    clickedBar.find('.val').text(Math.round(clicked) + '%');
                }
            }

            var msg = '<div style="text-align: left;"> Open Rate: ' + Math.round(opened) + '%<br>Click Rate: ' + Math.round(clicked) + '%</div>';
            openedBar.attr('data-original-title', msg).tooltip({html: true});
            clickedBar.attr('data-original-title', msg).tooltip({html: true});

            // update span, when columns customized
            el.find('td').attr('colspan', el.prev().find('td').length);
        }
    }
};

export default TableDetailView.extend({
    columns: COLUMNS,
    defaultColumns: DEFAULT_COLUMNS,
    sortId: 'last_updated',
    sortDirection: false,
    extraRows: EXTRA_ROWS
});

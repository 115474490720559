import $ from 'jquery'
import _ from 'underscore'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import FunnelsCollection from 'js/collections/funnels'
import GroupElementsCollection from 'js/collections/group_elements'
import CustomFieldsCollection from 'js/collections/custom_fields'
import OpportunityModel from 'js/models/opportunity'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import Currency from 'js/utils/currency'
import AppConfig from 'app/app-config'
import dateFormat from 'js/utils/date-format'
import {NewSelect} from 'js/react_views/widgets/select'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'

import style from './snapshot.css'

export class SnapshotReact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            funnels: [],
            loans: [],
            loansComponentId: {},
            activeFunnelId: null,
            paginatorInfo: null,
            loading: false,
            search: '',
            sortingOptions: [{
                id: 'completation_asc',
                title: 'Close Date Asc',
                order: 'phase.order asc'
            }, {
                id: 'completation_desc',
                title: 'Close Date Desc',
                order: 'phase.order desc'
            }, {
                id: 'created_asc',
                title: 'Created Asc',
                order: 'created asc'
            }, {
                id: 'created_desc',
                title: 'Created Desc',
                order: 'created desc'
            }, {
                id: 'value_asc',
                title: 'Value Asc',
                order: 'default_value asc'
            }, {
                id: 'value_desc',
                title: 'Value Desc',
                order: 'default_value desc'
            }, {
                id: 'priority_status_asc',
                title: 'Priority Status Asc',
                order: 'priority_status asc'
            }, {
                id: 'priority_status_desc',
                title: 'Priority Status Desc',
                order: 'priority_status desc'
            }]
        };

        this.debouncedFetchLoans = _.debounce(this.fetchLoans, 300);

        this.activeSortIdx = 0;

        const clientPreferences = app.user.get('client').preferences;
        const snapshotPreferences = (clientPreferences && clientPreferences.loans_snapshot) ? JSON.parse(clientPreferences.loans_snapshot) : {};

        this.numColumns = snapshotPreferences.num_cols || 3;
        this.numLoansToFetch = (snapshotPreferences.num_rows || 5) * this.numColumns;
        this.progressBarColor = snapshotPreferences.bar_colour || '#0937bf';
        this.displayStatusToggle = !_.isUndefined(snapshotPreferences.toggle) ? snapshotPreferences.toggle : true;
        this.statusColor = {
            go: '#60d937',
            assign: '#ff9a01',
            attention: '#ff9a01', // some systems use attention instead assign
            issue: '#ed230d'
        };

        if (snapshotPreferences.colours) {
            this.statusColor.go = snapshotPreferences.colours[0];
            this.statusColor.assign = snapshotPreferences.colours[1];
            this.statusColor.attention = snapshotPreferences.colours[1];
            this.statusColor.issue = snapshotPreferences.colours[2];
        }

        this.collection = new GroupElementsCollection(null, {elementType: 'opportunities'});

        // Status Priority & Borrower
        const self = this;

        const createSortingOptionAndFetchFunnels = function(priorityStatusCf, borrowerCf, setState) {
            self.priorityStatusCf = priorityStatusCf;

            // order by borrower option
            if (borrowerCf) {
                let sortingOptions = self.state.sortingOptions;

                sortingOptions.push({
                    id: 'borrower_asc',
                    title: 'Borrower Asc',
                    order: `custom_fields.${borrowerCf}.id asc`
                });

                sortingOptions.push({
                    id: 'borrower_desc',
                    title: 'Borrower Desc',
                    order: `custom_fields.${borrowerCf}.id desc`
                });

                if (setState) {
                    self.setState({sortingOptions: sortingOptions});
                } else {
                    self.state.sortingOptions = sortingOptions;
                }
            }

            self.fetchFunnels();
        }

        const fetchCustomFields = function() {
            if ('dealPriorityStatusCf' in app.globalData) {
                createSortingOptionAndFetchFunnels(app.globalData.dealPriorityStatusCf, app.globalData.dealBorrowerCf);
            } else {
                let customFields = new CustomFieldsCollection();
                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'deals'
                    }],
                    success: function() {
                        app.globalData.dealPriorityStatusCf = customFields.models.find(cf => cf.get('name').toLowerCase() === 'priority status');
                        app.globalData.dealBorrowerCf = customFields.models.find(cf => cf.get('name').toLowerCase() === 'borrower');
                        createSortingOptionAndFetchFunnels(app.globalData.dealPriorityStatusCf, app.globalData.dealBorrowerCf, true);
                    }
                });
            }
        }

        this.filterId = null;

        if ('snapshotOpportunitiesFilterId' in app.globalData) {
            self.filterId = app.globalData.snapshotOpportunitiesFilterId;
            fetchCustomFields();
        } else {
            let filter = new OpportunityFilterModel();
            const valuesRange = AppConfig.getValue('hideDealsBubblesWithValueInRange');

            filter.save({
                rules: [[{
                    field: 'opportunity_bucket_value',
                    operator: 'more_than',
                    values: valuesRange ? valuesRange[1] : 30000
                }]]
            }, {
                alert: false,
                success: function() {
                    self.filterId = filter.get('id');
                    app.globalData.snapshotOpportunitiesFilterId = self.filterId;
                    fetchCustomFields();
                }
            });
        }
    }

    fetchFunnels() {
        let funnels = new FunnelsCollection();
        const self = this;

        funnels.fetch({
            data: {
                permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
            },
            sortOn: [{
                attribute: 'order',
                order: 'asc'
            }],
            success: function(data) {
                let funnels = [];

                for (const funnel of data.models) {
                    funnels.push({
                        id: funnel.get('id'),
                        name: funnel.get('name')
                    });
                }

                funnels.push({
                    id: null,
                    name: 'All'
                });

                self.setState({
                    funnels: funnels,
                    activeFunnelId: funnels[0].id
                });

                self.fetchLoans(funnels[0].id, 1);
            }
        });
    }

    fetchLoans(funnelId, page) {
        const self = this;

        let data = {
            snapshot: true,
            phase_types: 'user',
            order_by: this.state.sortingOptions[this.activeSortIdx].order,
            filter_id: this.filterId,
            search: this.state.search
        };

        if (funnelId) {
            data.funnel_id = funnelId;
        }

        this.setState({
            loading: true
        });

        this.collection.fetch({
            page: page,
            rows: this.numLoansToFetch,
            data: data,
            success: function(data) {
                let loans = [];
                let loansComponentId = {};

                for (let loan of data.models) {
                    const closeDate = loan.get('snapshot_expected_close_date');

                    loans.push({
                        id: loan.get('snapshot_id'),
                        name: loan.get('snapshot_name'),
                        phase: loan.get('snapshot_phase'),
                        completed: loan.get('snapshot_completed_percentage'),
                        value: Currency.format(loan.get('snapshot_currency'), loan.get('snapshot_value')),
                        closeDate: closeDate ? dateFormat.formatMMDDYYYYDate(closeDate) : null,
                        status: self.displayStatusToggle ? loan.get('snapshot_funnel_drops_value') : null
                    });

                    loansComponentId[loan.get('snapshot_id')] = 0;
                }

                self.setState({
                    loading: false,
                    loans: loans,
                    loansComponentId: loansComponentId,
                    paginatorInfo: {
                        page: page,
                        start: data.start,
                        end: data.start + data.rows,
                        total: data.total,
                        hasPrev: data.start > 0,
                        hasNext: data.total > (data.start + data.rows)
                    }
                });
            }
        });
    }

    incPage(inc) {
        this.fetchLoans(this.state.activeFunnelId, this.state.paginatorInfo.page + inc);
    }

    setActiveFunnel(funnelId) {
        if (funnelId !== this.state.activeFunnelId) {
            this.setState({activeFunnelId: funnelId});
            this.fetchLoans(funnelId, 1);
        }
    }

    onSortingChanged(items) {
        this.activeSortIdx = this.state.sortingOptions.findIndex(o => o.id === items[0].id);
        this.fetchLoans(this.state.activeFunnelId, 1);
    }

    cycleStatus(loan) {
        if (!this.priorityStatusCf) {
            return;
        }

        const status = loan.status.toLowerCase();
        const options = this.priorityStatusCf.get('options');
        let idx = options.findIndex(o => o.value.toLowerCase() === status);

        if (idx !== -1) {
            const nextState = options[(idx + 1) % options.length];
            let opportunityModel = new OpportunityModel({id: loan.id});
            const self = this;

            opportunityModel.save({
                [`custom_field.${this.priorityStatusCf.get('id')}`]: nextState.id
            }, {
                patch: true,
                success: function() {
                    let loansComponentId = self.state.loansComponentId;
                    loansComponentId[loan.id] += 1;
                    loan.status = nextState.value;
                    self.setState({loansComponentId: loansComponentId});
                }
            });
        }
    }

    displayLoan(loan) {
        this.props.parent.trigger('push-view:deal:show', {id: loan.id});
    }

    onSearchChanged(ev) {
        this.setState({search: ev.target.value});
        this.debouncedFetchLoans(this.state.activeFunnelId, 1);
    }

    render() {
        return (
            <div className={style.LoansSnapshot}>
                <div className={style.Toolbar}>
                    <div className={style.Tabs}>
                        {this.state.funnels.map(funnel => { return (
                            <div
                                key={`tab_${funnel.id}`}
                                className={`${style.Tab} ${this.state.activeFunnelId === funnel.id ? style.TabActive : ''}`}
                                onClick={() => this.setActiveFunnel(funnel.id)}
                            >
                                {funnel.name}
                            </div>
                        )})}
                    </div>
                    <div className={style.SearchAndSort}>
                        <div className={style.Search}>
                            <div style={{marginRight: '10px'}}>Search:</div>
                            <input type="text" value={this.state.search} onChange={this.onSearchChanged.bind(this)}/>
                        </div>
                        <div className={style.Sorting}>
                            <div style={{marginRight: '10px'}}>Sort Loans:</div>
                            <div className={style.SortingSelector}>
                                <NewSelect
                                    data={this.state.sortingOptions}
                                    value={this.state.sortingOptions[this.activeSortIdx]}
                                    searchViewVisible={false}
                                    options={{minimumInputLength: -1, maxHeight: 350}}
                                    onSelect={this.onSortingChanged.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.CardsContainer}>
                    {this.state.loading && <div className={style.LoadingCards}><LoadingIndicator/></div>}
                    {!this.state.loading && this.state.paginatorInfo && this.state.paginatorInfo.total === 0 && <div className={style.NoCards}>No items to display</div>}
                    {!this.state.loading && <div
                        className={style.Cards}
                        style={{gridTemplateColumns: `repeat(${this.numColumns}, 320px`}}
                    >
                        {this.state.loans.map(loan => { return (
                            <div
                                key={`card_${loan.id}_${this.state.loansComponentId[loan.id]}`}
                                className={style.Card}
                                onClick={() => this.displayLoan(loan)}
                            >
                                <div className={style.CardRow}>
                                    <div className={style.CardName} title={loan.name}>{loan.name}</div>
                                    {loan.status && <div
                                        className={style.CardStatus}
                                        style={{background: this.statusColor[loan.status.toLowerCase()]}}
                                        onClick={(ev) => {ev.stopPropagation(); this.cycleStatus(loan)}}
                                    >
                                        {loan.status}
                                    </div>}
                                </div>
                                <div className={style.CardProgressBarInfo}>
                                    <div className={style.CardProgressBarContainer}>
                                        <div
                                            className={style.CardProgressBar}
                                            style={{background: this.progressBarColor, width: `${loan.completed * 100}%`}}
                                        />
                                    </div>
                                    <div className={style.CardPhaseName}>{loan.phase}</div>
                                </div>
                                <div className={style.CardRow}>
                                    <div className={style.CardValue}>{loan.value}</div>
                                    {loan.closeDate && <div className={style.CardCloseContainer}>
                                        <div>Close:</div>
                                        <div className={style.CardClose}>{loan.closeDate}</div>
                                    </div>}
                                </div>
                            </div>
                        )})}
                    </div>}
                </div>
                {this.state.paginatorInfo && !this.state.loading && this.state.paginatorInfo.total > 0 &&  <div className={style.Paginator}>
                    {this.state.paginatorInfo.hasPrev && <div className={style.PaginatorButton} onClick={() => this.incPage(-1)}>Prev</div>}
                    <div className={style.PaginatorInfo}>{`${this.state.paginatorInfo.start + 1}-${this.state.paginatorInfo.end} of ${this.state.paginatorInfo.total}`}</div>
                    {this.state.paginatorInfo.hasNext && <div className={style.PaginatorButton} onClick={() => this.incPage(1)}>Next</div>}
                </div>}
            </div>
        );
    }
}

export var SnapshotMarionette = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <SnapshotReact
                parent={this}
            />,
            this.$el.get(0)
        );
    },
    getUrl: function() {
        return 'snapshot';
    }
});

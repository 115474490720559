import _ from 'underscore'

import app from 'js/app'
import vent from 'js/vent'
import BaseModel from 'js/models/base'
import TextManager from 'app/text-manager'


function launch(data) {
    var status = data.status,
        error = data.error,
        message,
        classes;

    if (!status) {
        message = "Saving ...";
        classes = 'saving processing';
    }
    else if (status >= 200 && status < 300) {
        message = TextManager.parseText('${ID_CAMPAIGN, capitalize} Successfully Launched!');
        classes = 'saved success';
    }
    else {
        message = TextManager.parseText('Error launching ${ID_CAMPAIGN}: ') + error;
        classes = 'save-error error';
    }

    return {
        message: message,
        classes: classes,
        timer: !!status
    };
}

function test(data, model) {
    var status = data.status,
        error = data.error,
        message,
        classes;

    if (!status) {
        message = "Saving ...";
        classes = 'saving processing';
    }
    else if (status >= 200 && status < 300) {
        message = model.get('campaign_type') === 'message' ? 'Test message sent!' : 'Test email sent!';
        classes = 'saved success';
    }
    else {
        message = TextManager.parseText('Error testing ${ID_CAMPAIGN}: ' + error);
        classes = 'save-error error';
    }

    return {
        message: message,
        classes: classes,
        timer: !!status
    };
}

function duplicate(data) {
    var status = data.status,
        error = data.error,
        message,
        classes;

    if (!status) {
        message = "Saving ...";
        classes = 'saving processing';
    }
    else if (status >= 200 && status < 300) {
        message = TextManager.parseText('${ID_CAMPAIGN, capitalize} duplicated in Drafts folder!');
        classes = 'saved success';
    }
    else {
        message = TextManager.parseText('Error duplicating ${ID_CAMPAIGN}: ' + error);
        classes = 'save-error error';
    }

    return {
        message: message,
        classes: classes,
        timer: !!status
    };
}

function cancel(data) {
    var status = data.status,
        error = data.error,
        message,
        classes;

    if (!status) {
        message = "Canceling ...";
        classes = 'saving processing';
    }
    else if (status >= 200 && status < 300) {
        message = TextManager.parseText('${ID_CAMPAIGN, capitalize} canceled and moved to Drafts view!');
        classes = 'saved success';
    }
    else {
        message = TextManager.parseText('Error canceling ${ID_CAMPAIGN}: ' + error);
        classes = 'save-error error';
    }

    return {
        message: message,
        classes: classes,
        timer: !!status
    };
}

export default BaseModel.extend({
    name: TextManager.parseText('${ID_CAMPAIGN, capitalize}'),
    urlRoot: '/campaigns',
    launch: function(campaignData, success, error) {
        var self = this;

        this.save(
            campaignData,
            {
                patch: true,
                url: this.url() + "?send",
                ignoreResponseAlertSet: {'CampaignScheduledPastError': true},
                success: function(model, attributes, settings) {
                    vent.trigger("alert:show", { type: launch, model: self, xhr: settings.xhr });

                    app.dirtyModelHandler.remove(self.cid);
                    vent.trigger('campaign:launch', self);

                    success();
                },
                error: function() {
                    error.apply(this, arguments);
                }
            }
        );
    },
    test: function(campaignData, emailList, activeIndividualId) {
        var self = this;

        this.save(
            campaignData,
            {
                patch: true,
                url: this.url() + "?test=" + emailList.join(',') + '&individual_id=' + activeIndividualId,
                success: function(model, attributes, settings) {
                    vent.trigger("alert:show", {type: test, model: self, xhr: settings.xhr});
                }
            }
        )
    },
    duplicate: function(callback) {
        var self = this;

        this.save(
            {},
            {
                patch: true,
                success: function(model, attributes, settings) {
                    vent.trigger("alert:show", {type: duplicate, model: self, xhr: settings.xhr});
                    callback();
                }
            }
        )
    },
    cancel: function(success, options) {
        var self = this;
        options = options || {};

        _.extend(options, {
            patch: true,
            url: this.url() + "?cancel",
            success: function(model, attributes, settings) {
                vent.trigger("alert:show", { type: cancel, model: self, xhr: settings.xhr });

                success();
            }
        });

        this.save({}, options);
    }
});

import React from 'react';
import classnames from 'classnames';

import style from './checkbox.css';

class Checkbox extends React.Component {
    render() {
        const { checked, onClick } = this.props;
        const classes = classnames({
            [style.Checkbox]: true,
            'icon-checkmark': checked,
            'icon-checkmark2': !checked,
            [style.checked]: checked
        });
        return (
            <i className={classes} onClick={onClick} />
        );
    }
}

export default Checkbox;

import _ from 'underscore';
import React from 'react';

import TextManager from 'app/text-manager';

import {Button} from 'js/react_views/widgets/widgets';
import DealList from 'js/react_views/detail_view_components/deal-list';
import ListPager from 'js/react_views/detail_view_components/list-pager';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';

import style from './Deals.css';
import AppConfig from 'app/app-config'

class Deals extends React.Component {
    render() {
        const {
            deals,
            onDealNavigate,
            onNew,
            onClickNext,
            onClickPrev
        } = this.props;

        const renderOrganizationDealsNewButton = AppConfig.getValue("organizations.related_deals.new_button.visible", true)

        return (
            <div className={style.Deals}>
                <header className={style.header}>
                    {renderOrganizationDealsNewButton && (
                    <div className={`${style.headerBtnBox} tool-button`}>
                        <Button
                            color="blue"
                            onClick={onNew}>
                            New
                        </Button>
                    </div>)}
                    <h5 className={style.heading}>{TextManager.parseText('${ID_DEAL, capitalize, plural}')}</h5>
                </header>
                {!deals &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {deals && deals.total > 0 &&
                    <div>
                        <DealList
                            deals={deals.items}
                            onDealNavigate={onDealNavigate} />
                        <ListPager
                            start={deals.start}
                            currentPageLength={deals.items.length}
                            total={deals.total}
                            onClickNext={onClickNext}
                            onClickPrev={onClickPrev} />
                    </div>
                }
                {deals && deals.total === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default Deals;

import $ from 'jquery';
import React from 'react';
import Backbone from 'backbone'

import vent from 'js/vent';
import {Calendar} from 'js/react_views/calendar/calendar';
import CampaignsDashboard from 'app_v2/sections/campaigns/dashboard';
import Table from 'app_v2/components/table/table';
import TableFunnel from 'app_v2/sections/base/components/table_funnel';
import FilterManager from './filter_manager';
import GroupPageFetcher from './group_page_fetcher';
import AdvancedFilterPanel from 'app_v2/sections/panels/advanced_filter';
import ReportsDateFilterPanel from 'app_v2/sections/panels/reports_date_filter';
import ConfigureColumnsPanel from 'app_v2/sections/panels/configure_columns';
import SaveEditGroupPanel from 'app_v2/sections/panels/save_edit_group';
import DealEntityPanel from 'app_v2/sections/panels/deal_entity';
import FinderPanel from 'app_v2/sections/panels/finder';
import Toolbar from './components/toolbar';
import ActionsBar from './components/actions_bar';
import FiltersBar from './components/filters_bar';

import style from './section.css';


const TABLE_DEFAULT_NUM_ROWS = 50;


export default class Section extends React.Component {
    constructor(props) {
        super(props);

        let state = {
            activeView: props.activeView,
            filtersBarFilters: null,
            customRendererTop: null,
            customRendererTopHeight: 0
        };

        this.activeFetcher = null;
        this.lastPageFetched = null;

        // table configuration
        let columns = null;

        if (props.table) {
            const numRows = props.table.numRows || TABLE_DEFAULT_NUM_ROWS;

            columns = props.table.columns;

            this.tableProps = _.clone(this.props.table);

            // default values
            if (!this.tableProps.numRows) {
                this.tableProps.numRows = TABLE_DEFAULT_NUM_ROWS;
            }

            if (!props.table.fetcher) {
                this.groupPageFetcher = new GroupPageFetcher(props.entityType, numRows, columns);
                this.groupPageFetcher.setSectionId(this.props.sectionId);
            } else {
                this.groupPageFetcher = props.table.fetcher;
            }

            this.activeFetcher = this.groupPageFetcher;
        }

        // finder
        if (props.finder) {
            this.hasFinder = true;
            state.finderCollapsed = false;
        }

        this.filterManager = new FilterManager(props.entityType, columns);

        this.state = state;
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        // finder shortcut
        if (this.hasFinder) {
            this.props.parent.listenTo(vent, 'shortcut:palette', function() {
                if (self.finderPanel) {
                    if (self.state.finderCollapsed) {
                        self.finderPanel.searchGainFocus();
                    } else {
                        self.finderPanel.searchLostFocus();
                    }
                }

                self.handleFinderToggle();
            });
        }

        // todo: manage the rest of entities
        this.props.parent.listenTo(vent, 'deal:save', function() {
            // todo: check that the table is showing deals
            if (!self.mounted) {
                return;
            }

            self.fetchTablePage();
        });

        this.props.parent.listenTo(vent, 'appointment:created appointment:updated', function() {
            if (self.props.entityType === 'appointments') {
                self.fetchTablePage();

                if (self.state.activeView === 'calendar') {
                    self.calendar.fetchData();
                }
            }
        });

        $(window).on('resize', this.resizeCallback.bind(this));
    }

    componentWillUnmount() {
        this.mounted = false;

        $(window).off('resize');
    }

    resizeCallback() {
        this.table?.onResize();
    }

    refresh() {
        switch (this.state.activeView) {
            case 'table':
                this.fetchTablePage(0);
                break;

            case 'calendar':
                this.calendar.fetchData();
                break;

            case 'funnel':
                this.funnel.fetchData();
                break;

            case 'campaignsDashboard':
                this.campaignsDashboard.refresh();
                break;
        }
    }

    fetchTablePage(page) {
        if (!this.table || !this.activeFetcher || this.state.activeView !== 'table') {
            return;
        }

        this.finderPanel?.updateActiveGroupBadge();

        if (!_.isNumber(page)) {
            page = this.lastPageFetched;
        }

        this.table.setLoading(true);
        this.lastPageFetched = page;

        const self = this;

        this.activeFetcher.fetch(page, function(result) {
            if (self.mounted) {
                self.table.setData(result.rows, page, result.start, result.total, result.numRows);
                self.table.setLoading(false);
            }
        });
    }

    onContentManagerUpdated(expandFinder, silent) {
        this.finderPanel.onContentManagerUpdated(expandFinder, silent);
        vent.trigger('AppContent:contentChange');
    }

    getUrl() {
        let path = [];

        switch (this.props.entityType) {
            case 'opportunities':
                path.push('deals');
                break;

            case 'conversations':
                path.push('messages');
                break;

            default:
                path.push(this.props.entityType);
                break;
        }

        if (this.props.section) {
            path.push(`section/${this.props.section.id}`);
        }

        if (this.props.finder?.contentManager) {
            const activeGroup = this.props.finder.contentManager.getActiveGroup();

            if (activeGroup) {
                path.push(activeGroup.id);
            }
        }

        return path.join('/');
    }

    handleNewEntity() {
        if (this.props.actions?.onNew) {
            this.props.actions.onNew();
            return;
        }

        switch (this.props.entityType) {
            case 'appointments':
                vent.trigger('quick:add:appointment');
                break;
        }
    }

    handleAddEntity(entity) {
        if (this.props.actions?.onAdd) {
            this.props.actions.onAdd(entity);
            return;
        }

        const activeGroup = this.props.finder.contentManager.getActiveGroup();
        const self = this;

        $.ajax({
            url: `/${activeGroup.id}/items/${entity.id}`,
            method: 'PUT',
            success: function() {
                self.fetchTablePage();
            }
        });
    }

    handleBulkProcessCompleted(bulkType) {
        this.fetchTablePage();

        if (this.funnel) {
            this.funnel.fetchData();
        }
    }

    handleRowClick(rowId, row) {
        if (this.props.onRowClick) {
            this.props.onRowClick(rowId, row);
        } else {
            // todo: move this to onRowClick in appointments (similar to messages)
            if (this.props.entityType === 'appointments') {
                vent.trigger('quick:edit:appointment', rowId);
            } else {
                this.showPanel('entity', rowId);
            }
        }
    }

    handleRowContext(row, itemId) {
        if (this.props.onRowContext) {
            this.props.onRowContext(row, itemId);
        }
    }

    handleRowButtonClick(rowId, buttonId) {
        if (this.props.onRowButtonClick) {
            this.props.onRowButtonClick(rowId, buttonId);
        }
    }

    handleRowSelectionChange(rowSelection) {
        if (this.actionsBar) {
            this.actionsBar.onRowSelectionChange(rowSelection);
        }
    }

    handleShowAdvancedFilter(filter) {
        this.showPanel('advancedFilter');
    }

    handleTableSortChange(columnId, sortDir) {
        this.activeFetcher.orderBy(columnId, sortDir);
        this.fetchTablePage();
        this.updateGroup(false, false, true);
    }

    handleTableFilterRuleChange(ruleFieldId, ruleCustomId, rule) {
        this.filterManager.setRule(ruleFieldId, ruleCustomId, rule);

        if (this.table) {
            this.table.setColumnsRules(this.filterManager.getColumnsRules());
        }

        if (this.funnel) {
            this.funnel.setColumnsRules(this.filterManager.getColumnsRules());
        }

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.activeFetcher.filterBy(filterId);
            self.fetchTablePage(0);

            if (self.calendar) {
                self.calendar.fetchData();
            }

            if (self.funnel) {
                self.funnel.setFilterId(filterId);
                self.funnel.fetchData();
            }

            self.updateGroup(false, true);

            if (self.props.onFiltersChanged) {
                self.props.onFilterChanged();
            }
        });
    }

    handleAdvancedFilterRulesChange(rules) {
        this.filterManager.setRules(rules);

        if (this.table) {
            this.table.setColumnsRules(this.filterManager.getColumnsRules());
        }

        if (this.funnel) {
            this.funnel.setColumnsRules(this.filterManager.getColumnsRules());
        }

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.activeFetcher.filterBy(filterId);
            self.fetchTablePage(0);

            if (self.calendar) {
                self.calendar.fetchData();
            }

            if (self.funnel) {
                self.funnel.setFilterId(filterId);
                self.funnel.fetchData();
            }

            self.updateGroup(false, true);

            if (self.props.onFiltersChanged) {
                self.props.onFilterChanged();
            }
        });
    }

    handleFilterRuleDelete(columnId) {
        const column = this.table.getColumns().find(c => c.id === columnId);

        this.filterManager.setRule(column.filterId, column.filterCustom, null);

        if (this.table) {
            this.table.setColumnsRules(this.filterManager.getColumnsRules());
        }

        if (this.funnel) {
            this.funnel.setColumnsRules(this.filterManager.getColumnsRules());
        }

        this.computeFiltersBarFilters();

        const self = this;

        this.filterManager.createFilter(function(filterId) {
            self.activeFetcher.filterBy(filterId);
            self.fetchTablePage(0);

            if (self.calendar) {
                self.calendar.fetchData();
            }

            if (self.funnel) {
                self.funnel.setFilterId(filterId);
                self.funnel.fetchData();
            }

            self.updateGroup(false, true);

            if (self.props.onFiltersChanged) {
                self.props.onFilterChanged();
            }
        });
    }

    handleAllFiltersRulesDeleted() {
        this.deleteAllFilters(true);

        if (this.table) {
            this.fetchTablePage(0);
        }

        if (this.calendar) {
            this.calendar.fetchData();
        }

        if (this.funnel) {
            this.funnel.fetchData();
        }

        if (this.props.onFiltersChanged) {
            this.props.onFilterChanged();
        }
    }

    handleSaveCurrentGroupAsCsv() {
        let args = this.activeFetcher.getGroupPageArgs(0);

        args.rows = -1;
        args.csv = true;

        const self = this;

        $.get(`/group_pages?${$.param(args)}`, function(result) {
            const group = self.props.finder.contentManager.getActiveGroup();
            const blob = new Blob([result], {type: 'text/csv'});
            const a = document.createElement('a');

            a.download = `${group?.name || self.props.entityType}.csv`;
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
        });
    }

    handleViewChange(viewId) {
        switch (this.state.activeView) {
            case 'table':
                this.table.handleUnselectAll();
                break;

            case 'calendar':
                this.calendar.hide();
                break;

            case 'funnel':
                this.funnel.hide();
                break;

            case 'campaignsDashboard':
                this.campaignsDashboard.hide();
                break;
        }

        this.toolbar.setActiveView(viewId);

        // todo: set the activeFetcher (table or campaigns or whatever)

        switch (viewId) {
            case 'table': {
                const self = this;

                _.defer(function() {
                    self.table.adjustColumnsWidth();
                });
            }
            break;

            case 'calendar':
                this.calendar.show();
                break;

            case 'funnel':
                this.funnel.show(this.props.finder.contentManager.getActiveGroup());
                break;

            case 'campaignsDashboard':
                this.campaignsDashboard.show();
                break;
        }

        this.setState({
            activeView: viewId
        });
    }

    handleSearch(term) {
        if (term === null) { // search closed
            if (this.activeFetcher.getSearchTerm()) {
                this.activeFetcher.searchBy(null);
                this.fetchTablePage(0);
            }
        } else {
            this.activeFetcher.searchBy(term);
            this.fetchTablePage(0);
        }
    }

    handleSelectedColumnsChange(selectedColumns) {
        this.table.setColumns(selectedColumns);
        this.filterManager.setColumns(selectedColumns);
        this.activeFetcher.setColumns(selectedColumns);
        this.fetchTablePage();
        this.updateGroup(true);
    }

    updateGroup(updateColumns, updateFilters, updateSorting) {
        let data = {
            columns: updateColumns ? this.table.getColumns().map(c => c.id) : null,
            orderBy: updateSorting ? this.activeFetcher.getOrderBy() : null
        };

        if (updateFilters) {
            data.filterId = this.filterManager.filterId;
        }

        let doUpdate = true;

        if (this.props.updateGroup) {
            doUpdate = !this.props.updateGroup(data);
        }

        if (doUpdate) {
            const activeGroup = this.props.finder.contentManager.getActiveGroup();

            if (activeGroup && !activeGroup.special && !activeGroup.protected) {
                let newData = {};

                if (data.columns) {
                    newData.columns = data.columns;
                }

                if (data.orderBy) {
                    newData.display_options = {
                        sort: {
                            direction: data.orderBy.sortDir === 'asc',
                            field: [data.orderBy.columnId]
                        }
                    };
                }

                if (activeGroup.groupType === 'smart' && updateFilters) {
                    newData.filter_id = data.filterId;
                }

                if (!_.isEmpty(newData)) {
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Saving group...',
                                timer: 3000,
                                classes: 'saving processing'
                            };
                        }
                    });

                    $.ajax({
                        type: 'PATCH',
                        url: `/groups/${activeGroup.shortId}`,
                        data: JSON.stringify(newData),
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function () {
                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'Group saved',
                                        timer: 3000,
                                        classes: 'saved success'
                                    };
                                }
                            });
                        }
                    });
                }
            }
        }
    }

    deleteAllFilters(updateGroupFilters) {
        this.setState({
            filtersBarFilters: null
        });

        if (this.table) {
            this.table.setColumnsRules({});
        }

        if (this.funnel) {
            this.funnel.setColumnsRules({});
            this.funnel.setFilterId(null);
        }

        this.filterManager.setRules(null);
        this.activeFetcher?.filterBy(null);

        this.updateGroup(false, updateGroupFilters);
    }

    computeFiltersBarFilters() {
        let filtersBarFilters = [];
        const rules = this.filterManager.getRules();

        if (rules) {
            let columnsAlreadyAdded = {};

            for (const r of _.flatten(rules)) {
                const column = this.table.getColumns().find(c => {
                    if (c.isCustomField) {
                        return c.filterId === r.field && c.filterCustom === r.custom;
                    }

                    return c.filterId === r.field;
                });

                if (column && column.id in columnsAlreadyAdded) {
                    continue;
                }

                let title = '';
                let colId = null;

                if (column) {
                    title = column.title;
                    colId = column.id;
                } else {
                    let filter = null;

                    for (const fg in this.props.filters) {
                        filter = this.props.filters[fg].find(f => f.id === r.field);

                        if (filter) {
                            break;
                        }
                    }

                    // there are some filters that doesnt have a column (e.g: group_id)
                    if (filter?.notRelatedColumn) {
                        title = filter.title;
                    } else {
                        continue;
                    }
                }

                filtersBarFilters.push({
                    id: r.field,
                    columnId: colId,
                    title: title
                });

                columnsAlreadyAdded[colId] = true;
            }
        }

        this.setState({
            filtersBarFilters: filtersBarFilters.length === 0 ? null : filtersBarFilters
        });
    }

    showPanel(panelId, showArg) {
        switch (panelId) {
            case 'advancedFilter':
                this.advancedFilterPanel.show(this.filterManager.getRules());
                break;

            case 'reportsDateFilter':
                this.reportsDateFilter.show();
                break;

            case 'saveEditGroup':
                let args = {};

                if (showArg) {
                    if (showArg.saveAs) {
                        if (this.props.toolbar.saveAsArgs) {
                            if (_.isFunction(this.props.toolbar.saveAsArgs)) {
                                args = this.props.toolbar.saveAsArgs();
                            } else {
                                args = this.props.toolbar.saveAsArgs;
                            }
                        } else {
                            args.populateFromGroupId = this.props.finder.contentManager.getActiveGroup().shortId;
                        }
                    } else if (showArg.groupId) {
                        args.groupId = showArg.groupId;
                    }

                    if (showArg.allowedTypes) {
                        args.allowedTypes = showArg.allowedTypes;
                    }

                    if (showArg.entityType) {
                        args.entityType = showArg.entityType;
                    }

                    if (showArg.extraFields) {
                        args.extraFields = showArg.extraFields;
                    }

                    for (const f of ['permissionsAccordionExpanded', 'generalAccordionExpanded']) {
                        if (f in showArg) {
                            args[f] = showArg[f];
                        }
                    }
                }

                this.saveEditGroupPanel.show(args);
                break;

            case 'configureColumns':
                this.configureColumnsPanel.show(this.table.getColumns());
                break;

            case 'entity':
                if (this.entityPanel) {
                    this.entityPanel.show(showArg);
                }
                break;
        }
    }

    handleFinderToggle() {
        const collapsed = !this.state.finderCollapsed;

        this.setState({
            finderCollapsed: collapsed
        });

        this.finderPanel.setCollapsed(collapsed);

        for (const el of [this.table, this.funnel, this.calendar, this.campaignsDashboard]) {
            if (el) {
                el.onStartResizing();

                _.delay(function() {
                    el.onEndResizing();
                }, 400);
            }
        }
    }

    handleFinderNewGroup(args) {
        this.showPanel('saveEditGroup', args);
    }

    handleFinderGroupSelect(group, letFinderExpanded) {
        if (!group) {
            return;
        }

        const newActiveView = group.defaultView || 'table';
        const self = this;

        this.handleViewChange(newActiveView);

        _.defer(function() {
            self.toolbar.setTitle(group.name);


            if (self.props.entityType === 'reports') {
                let showReportDateFilter = false;
                const dateReportFilters = [
                    'Cancellation',
                    'Un-actioned Enquiries',
                    'Reservation Source Details',
                    'Regional Visitors',
                    'Regional Enquiries',
                    'Financial Report',
                    'Broker Leads',
                    'Un-actioned Tasks',
                    'Leads created last 7 days.',
                    'Progressed plots/reservations last 7 days.',
                    'Tasks completed last 7 days.',
                    'Tasks due in the next 7 days.',
                    'All calls and emails in the last week.',
                    // add reports here to view filter menu
                ]

                if (dateReportFilters.includes(group.name)) {
                    showReportDateFilter = true;
                }

                self.toolbar.setReportDateFilter(showReportDateFilter)
            }

            // autocollapse disabled for now until decide what we want
            // if (!letFinderExpanded && !self.state.finderCollapsed) {
            //     self.handleFinderToggle();
            // }

            if (self.props.finder.onGroupPreSelected) {
                self.props.finder.onGroupPreSelected(group);
            }

            if (group.special || group.dynamic) {
                if (self.actionsBar) {
                    if (group.actions) {
                        self.actionsBar.setButtonsActive({
                            new: group.actions.new,
                            delete: group.actions.delete,
                            edit: group.actions.edit,
                            merge: group.actions.merge
                        });
                    } else {
                        self.actionsBar.setButtonsActive({
                            new: self.props.actions.new,
                            delete: self.props.actions.delete,
                            edit: self.props.actions.edit,
                            merge: self.props.actions.merge
                        });
                    }

                    if (group.entityType) {
                        self.actionsBar.setEntityType(group.entityType);
                    } else {
                        self.actionsBar.setEntityType(self.props.entityType);
                    }
                }

                if (self.toolbar) {
                    if (group.toolbar) {
                        self.toolbar.setButtonsActive(group.toolbar);
                    }
                }

                self.deleteAllFilters();

                let groupFetchArgs = _.clone(group.fetchArgs || {});

                self.activeFetcher?.setFetchArgs(groupFetchArgs);

                if (group.dynamicFilter) {
                    self.table?.setLoading(true);

                    group.dynamicFilter(function(filter) {
                        self.activeFetcher?.filterBy(filter.id);
                        self.fetchTablePage(0);

                        if (self.actionsBar?.setLoadedGroup) {
                            self.actionsBar.setLoadedGroup({
                                id: group.id,
                                isSpecial: true,
                                filterId: filter.id,
                                sectionId: self.props.sectionId
                            });
                        }

                        self.filterManager.setRules(filter.rules);
                        self.table.setColumnsRules(self.filterManager.getColumnsRules());
                        self.computeFiltersBarFilters();
                    });
                } else {
                    self.fetchTablePage(0);

                    if (self.actionsBar?.setLoadedGroup) {
                        self.actionsBar.setLoadedGroup({
                            id: group.id,
                            isSpecial: true,
                            filterId: self.filterManager.filterId,
                            sectionId: self.props.sectionId
                        });
                    }
                }
            } else {
                if (self.actionsBar) {
                    if (group.groupType === 'static') {
                        self.actionsBar.setButtonsActive({
                            add: self.props.actions.add,
                            edit: self.props.actions.edit,
                            merge: self.props.actions.merge,
                            remove: self.props.actions.remove
                        });
                    } else {
                        self.actionsBar.setButtonsActive({
                            edit: self.props.actions.edit,
                            merge: self.props.actions.merge,
                            delete: self.props.actions.delete
                        });
                    }

                    if (group.entityType) {
                        self.actionsBar.setEntityType(group.entityType);
                    } else {
                        self.actionsBar.setEntityType(self.props.entityType);
                    }
                }

                // todo: if there are more fetchers set the group to all of them
                self.loadGroup(group);
            }

            if (self.props.finder.onGroupSelected) {
                self.props.finder.onGroupSelected(group);
            }

            vent.trigger('AppContent:contentChange');
        });
    }

    loadGroup(group) {
        const self = this;

        this.table?.setLoading(true); // to avoid display the previous rows meanwhile the group information is loaded

        $.get(`/${group.id}`, function(result) {
            const defColumns = (result.columns && result.columns.length > 0) ? result.columns : self.tableProps.defaultColumnsIds;
            let columns = [];

            for (const cid of defColumns) {
                const column = self.tableProps.allColumns.find(c => c.id === cid);

                if (column) {
                    columns.push(column);
                }
            }

            self.filterManager.setColumns(columns);
            self.table.setColumns(columns);
            self.deleteAllFilters();

            self.activeFetcher.setColumns(columns);
            self.activeFetcher.setFetchArgs(group.fetchArgs);
            self.fetchTablePage(0);

            if (self.actionsBar?.setLoadedGroup) {
                self.actionsBar.setLoadedGroup({
                    id: result.id,
                    name: result.name,
                    isSmart: result.group_type === 'smart',
                    filterId: self.filterManager.filterId,
                    sectionId: self.props.sectionId,
                });
            }

            if (result.filter) {
                self.filterManager.setRules(result.filter.rules);
                self.table.setColumnsRules(self.filterManager.getColumnsRules());
                self.computeFiltersBarFilters();
            }
        });
    }

    calendarDataFetcher(callback) {
        if (this.props.entityType === 'appointments') {
            let url = '/appointments';
            let args = {};

            if (this.filterManager.filterId) {
                args.filter_id = this.filterManager.filterId;
            }

            const activeGroup = this.props.finder?.contentManager?.getActiveGroup();
            const fetchArgs = activeGroup?.fetchArgs || {};

            for (const arg of ['status', 'tag_ids', 'upcoming']) {
                if (arg in fetchArgs) {
                    args[arg] = fetchArgs[arg];
                }
            }

            $.get(`${url}?${$.param(args)}`, function(result) {
                callback(result.map(i => new Backbone.Model(i)));
            });
        }
    }

    handleCalendarDateChange(date) {
        this.calendar.setCurrentDate(date);
    }

    handleCalendarSpanChange(span) {
        this.calendar.setSpan(span);
    }

    handleCalendarDateSpanChange(date, span) {
        this.calendar.setDateSpan(date, span);
    }

    handleEditAppointment(appointment) {
        vent.trigger('quick:edit:appointment', appointment.get('id'));
    }

    handleCreateAppointment(date) {
        vent.trigger('quick:add:appointment', {
            startDate: date
        });
    }

    handleGotoGroup(groupId) {
        this.props.finder?.contentManager.setActiveGroupId(groupId);
        this.onContentManagerUpdated(!this.finderPanel.isCollapsed());
    }

    setGroupClosable(closable) {
        this.toolbar.setGroupClosable(closable);
    }

    setTableColumnsInfo(columns, allColumns, defaultColumnsIds) {
        this.tableProps.columns = columns;
        this.tableProps.allColumns = allColumns;
        this.tableProps.defaultColumnsIds = defaultColumnsIds;

        this.table.setColumns(columns);
        this.filterManager.setColumns(columns);
    }

    setFiltersInfo(entityType, filters) {
        this.filterManager.setEntityType(entityType);
        this.advancedFilterPanel.setEntityType(entityType);
        this.advancedFilterPanel.setFilters(filters);
    }

    setActionsBarInfo(entityType, data) {
        this.actionsBar.setEntityType(entityType);

        if (data.buttons) {
            this.actionsBar.setButtonsActive(data.buttons);
        }

        if (data.addEntitySearchArgs) {
            this.actionsBar.setAddEntitySearchArgs(data.addEntitySearchArgs);
        }
    }

    setToolbarInfo(data) {
        if ('moreOptionsItems' in data) {
            this.toolbar.setMoreOptionsItems(data.moreOptionsItems);
        }
    }

    setFinderCreateOptions(options) {
        this.finderPanel.setCreateOptions(options);
    }

    setTableSelectableRows(selectable) {
        this.tableProps.selectableRows = selectable;
        this.table.setSelectableRows(selectable);
    }

    setCustomRendererTop(renderer, height) {
        this.setState({
            customRendererTop: renderer,
            customRendererTopHeight: height
        });
    }

    render() {
        const actionsBarVisible = !_.isEmpty(this.props.actions || {});
        let tableHeightMod = this.state.filtersBarFilters ? 130 : 82;

        if (!actionsBarVisible) {
            tableHeightMod -= 47;
        }

        if (this.state.customRendererTopHeight) {
            tableHeightMod += this.state.customRendererTopHeight;
        }

        // ...
        return (
            <div>
                {this.hasFinder &&
                    <FinderPanel
                        ref={(el) => this.finderPanel = el}
                        {...this.props.finder}
                        entityType={this.props.entityType}
                        collapsed={this.state.finderCollapsed}
                        onToggle={this.handleFinderToggle.bind(this)}
                        onGroupSelected={this.handleFinderGroupSelect.bind(this)}
                        onNewGroup={this.handleFinderNewGroup.bind(this)}
                    />
                }

                <div
                    className={`
                        ${style.section}
                        ${this.hasFinder ? style.hasFinder : ''}
                        ${this.state.finderCollapsed ? style.finderCollapsed : ''}
                    `}
                >
                    <Toolbar
                        ref={(el) => this.toolbar = el}
                        {...this.props.toolbar || {}}
                        parent={this.props.parent}
                        calendar={this.props.calendar}
                        hasFinder={this.hasFinder}
                        activeView={this.state.activeView}
                        onShowPanel={this.showPanel.bind(this)}
                        onCloseGroup={this.props.onCloseGroup}
                        onSearch={this.handleSearch.bind(this)}
                        onViewSelected={this.handleViewChange.bind(this)}
                        onCalendarDateChange={this.handleCalendarDateChange.bind(this)}
                        onCalendarSpanChange={this.handleCalendarSpanChange.bind(this)}
                        onCalendarDateSpanChange={this.handleCalendarDateSpanChange.bind(this)}
                        onGroupDelete={this.props.onGroupDelete}
                        saveCurrentGroupAsCsv={this.handleSaveCurrentGroupAsCsv.bind(this)}
                        advancedFilter={this.props.advancedFilter}
                    />

                    {this.state.filtersBarFilters &&
                        <FiltersBar
                            filters={this.state.filtersBarFilters}
                            onFilterRuleDeleted={this.handleFilterRuleDelete.bind(this)}
                            onAllFiltersRulesDeleted={this.handleAllFiltersRulesDeleted.bind(this)}
                        />
                    }

                    {actionsBarVisible &&
                        <ActionsBar
                            ref={(el) => this.actionsBar = el}
                            entityType={this.props.entityType}
                            parent={this.props.parent}
                            {...this.props.actions || {}}
                            onNewEntity={this.handleNewEntity.bind(this)}
                            onAddEntity={this.handleAddEntity.bind(this)}
                            onBulkProcessCompleted={this.handleBulkProcessCompleted.bind(this)}
                        />
                    }

                    {this.state.customRendererTop && this.state.customRendererTop()}

                    <div
                        style={{
                            height: `calc(100% - ${tableHeightMod}px)`,
                            display: this.state.activeView === 'table' ? 'block' : 'none'
                        }}
                    >
                        <Table
                            ref={(el) => this.table = el}
                            {...this.tableProps}
                            parent={this.props.parent}
                            fetcher={this.activeFetcher}
                            filterable={true}
                            hasPaginator={true}
                            onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                            onRowClick={this.handleRowClick.bind(this)}
                            onRowContext={this.handleRowContext.bind(this)}
                            onRowButtonClick={this.handleRowButtonClick.bind(this)}
                            onFilterRuleChange={this.handleTableFilterRuleChange.bind(this)}
                            onSortChange={this.handleTableSortChange.bind(this)}
                            showAdvancedFilter={this.handleShowAdvancedFilter.bind(this)}
                            onGotoPage={(page) => this.fetchTablePage.bind(this)(page)}
                        />
                    </div>

                    {this.props.calendar &&
                        <div
                            style={{
                                height: `calc(100% - ${tableHeightMod}px)`,
                                position: 'relative',
                                marginTop: '-20px',
                            }}
                        >
                            <Calendar
                                ref={(el) => this.calendar = el}
                                visible={this.state.activeView === 'calendar'}
                                date={this.props.calendar.date}
                                span={this.props.calendar.span}
                                entityType='appointments'
                                fetchDataFunction={this.calendarDataFetcher.bind(this)}
                                onDateSpanChange={(date, span) => this.toolbar.setCalendarDateSpan(date, span)}
                                onEditAppointment={this.handleEditAppointment.bind(this)}
                                onCreateAppointment={this.handleCreateAppointment.bind(this)}
                            />
                        </div>
                    }

                    {this.props.funnel &&
                        <div
                            style={{
                                height: `calc(100% - ${tableHeightMod}px)`,
                                display: this.state.activeView === 'funnel' ? 'block' : 'none'
                            }}
                        >
                            <TableFunnel
                                ref={(el) => this.funnel = el}
                                sectionId={this.props.sectionId}
                                funnelId='9a779f92-e010-41e9-a460-c1d1838ec8b5' // TODO: TEMP!!!!
                                onRowClick={this.handleRowClick.bind(this)}
                                onDealUpdated={this.fetchTablePage.bind(this)}
                                onFilterRuleChange={this.handleTableFilterRuleChange.bind(this)}
                                onRowSelectionChange={this.handleRowSelectionChange.bind(this)}
                            />
                        </div>
                    }

                    {this.props.campaignsDashboard &&
                        <div
                            style={{
                                height: `calc(100% - ${tableHeightMod}px)`,
                                position: 'relative'
                            }}
                        >
                            <CampaignsDashboard
                                ref={(el) => this.campaignsDashboard = el}
                                onGotoGroup={this.handleGotoGroup.bind(this)}
                                onCreateCampaign={this.props.campaignsDashboard.onCreateCampaign}
                                onEditCampaign={this.props.campaignsDashboard.onEditCampaign}
                            />
                        </div>
                    }
                </div>

                <AdvancedFilterPanel
                    ref={(el) => this.advancedFilterPanel = el}
                    entityType={this.props.entityType}
                    filters={this.props.filters}
                    onFilterRulesChange={this.handleAdvancedFilterRulesChange.bind(this)}
                />

                <ReportsDateFilterPanel 
                    ref={(el) => this.reportsDateFilter = el}
                    activeGroup={this.props.finder.contentManager.getActiveGroup()}
                    parent={this.props.parent}
                />

                <SaveEditGroupPanel
                    ref={(el) => this.saveEditGroupPanel = el}
                    entityType={this.props.entityType}
                    onGroupSaved={this.props.onGroupSaved}
                />

                {this.tableProps &&
                    <ConfigureColumnsPanel
                        ref={(el) => this.configureColumnsPanel = el}
                        allColumns={this.tableProps.allColumns}
                        width={625}
                        onSelectedColumnsChange={this.handleSelectedColumnsChange.bind(this)}
                    />
                }

                {this.props.entityType === 'opportunities' &&
                    <DealEntityPanel
                        ref={(el) => this.entityPanel = el}
                        parent={this.props.parent}
                        noBackdrop={true}
                        width={925}
                    />
                }
            </div>
        );
    }
}

import 'js/patches'
import app from 'js/app'
import app_init from 'js/app_init'
import AppState from 'js/models/appstate'
import AppRouter from 'js/router'
import AppController from 'js/controllers/appcontroller'


// Pass the AppState model, the routers, and the controllers to the App
// upon initialization
var appUrl = window.location.origin || (window.location.protocol + '//' + window.location.host);

var options = {
    appState: AppState,
    appRouter: AppRouter,
    appController: AppController,
    controllers: [],
    appUrl: appUrl,
    apiUrl: appUrl + '/api'
};

app.options = options;
app.start(options);

window.app = app;

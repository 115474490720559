import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import React from 'react'
import ReactDOM from 'react-dom'

import vent from 'js/vent'
import MessageBox from 'js/views/message_box'
import TextManager from 'app/text-manager'
import app from 'js/app'
import LibraryBrowser from 'js/react_views/library-browser/browser'
import {ColorInputField, ColorPicker} from 'js/react_views/see/components'
import {NewSelect} from 'js/react_views/widgets/select'
import {DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON, DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR} from 'js/react_views/demographic-map/consts'

import style from './edit_custom_map_pins.css'


const ELEMENTS = [{
    id: 'individual',
    title: TextManager.parseText('${ID_INDIVIDUAL, capitalize, pluralize}'),
    icon: 'icon-user'
}, {
    id: 'organization',
    title: TextManager.parseText('${ID_ORGANIZATION, capitalize, pluralize}'),
    icon: 'icon-organizations'
}, {
    id: 'opportunity',
    title: TextManager.parseText('${ID_DEAL, capitalize, pluralize}'),
    icon: 'icon-funnel'
}];

const ELEMENT_PLURAL = {
    individual: 'individuals',
    organization: 'organizations',
    opportunity: 'opportunities'
};


let ColorPickerMostUsedColors = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'];

let OverrideIsOptions = [{
    id: 'not empty',
    title: 'Not Empty'
}];


class DefaultPinItem extends React.Component {
    constructor(props) {
        super(props);

        const mapConfig = this.props.mapConfig;

        this.state = {
            fillColor: mapConfig?.dynamic_pins?.default?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR
        };
    }

    handleChangeFillColor(color) {
        this.setState({
            fillColor: color
        });

        this.props.editor.onDefaultPinColorChange();
    }

    render() {
        return (
            <div className={`${style.pin} ${style.default}`}>
                <div className={style.pinContainer}>
                    <div
                        className={style.pinIcon}
                        style={{fill: this.state.fillColor}}
                        dangerouslySetInnerHTML={{ __html: DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}}
                    />
                    <div className={style.pinName}>Default Pin Color</div>
                    <div style={{marginLeft: 'auto', marginRight: '190px'}}>
                        <ColorInputField
                            value={this.state.fillColor}
                            editor={this.props.editor}
                            onChange={this.handleChangeFillColor.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class StaticPinItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iconEl: null
        };


        if (props.data.icon) {
            const self = this;

            $.ajax({
                url: props.data.icon,
                type: 'GET',
                success: function (data) {
                    let el = $('<div/>');

                    el.append(data);

                    const svgEl = el.find('svg');

                    svgEl.css('width', '32px');
                    svgEl.css('height', '32px');
                    svgEl.find('path').css('fill', props.data.color);

                    self.setState({
                        iconEl: el
                    });
                }
            });
        }
    }

    render() {
        const pin = this.props.data;

        return (
            <div className={style.pin}>
                <div className={style.pinContainer}>
                    <div
                        className={style.pinIcon}
                        style={{fill: this.props.data.color}}
                        dangerouslySetInnerHTML={{ __html: this.state.iconEl ? this.state.iconEl[0].outerHTML : DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}}
                    />

                    <div className={style.pinNameDesc}>
                        <div className={style.pinName}>{pin.name}</div>
                        <div className={style.pinDesc}>{pin.address.name}</div>
                    </div>

                    <div className={style.pinActions}>
                        <div
                            className={`${style.pinActionIcon} icon-pencil`}
                            onClick={() => this.props.onEdit(pin)}
                        />
                        <div
                            className={style.pinActionDelete}
                            onClick={() => this.props.onDelete(pin)}
                        >
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class DynamicPinItem extends React.Component {
    render() {
        const pin = this.props.data;

        return (
            <div className={style.pin}>
                <div className={style.pinContainer}>
                    <div
                        className={style.pinIcon}
                        style={{fill: '#c2c4cd'}}
                        dangerouslySetInnerHTML={{ __html: DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}}
                    />
                    <div className={style.pinNameDesc}>
                        <div className={style.pinName}>{pin.name}</div>
                        <div className={style.pinDesc}>'{pin.dropdownName}'</div>
                    </div>
                    <div className={style.pinActions}>
                        <div
                            className={`${style.pinActionIcon} icon-pencil`}
                            onClick={() => this.props.onEdit(pin)}
                        />
                        <div
                            className={style.pinActionDelete}
                            onClick={() => this.props.onDelete(pin)}
                        >
                            <div/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class EditAddressField extends React.Component {
    constructor(props) {
        super(props);

        const field = this.props.field;
        let fieldsInUse = [];

        for (const fiu of this.props.addressBuilderFields) {
            if (fiu.id !== field?.id) {
                fieldsInUse.push(fiu.id);
            }
        }

        this.fields = this.getFields(ELEMENT_PLURAL[props.elementId], fieldsInUse);

        if (this.fields.length === 0) {
            const self = this;

            MessageBox.showOk({
                icon: 'icon-warning',
                message: 'There are no more available fields',
            }, this.props.parent, function() {
                self.props.onClose();
            });
        }

        this.activeField = this.fields[0];
        this.activeOverrideField = this.fields[0];
        this.activeOverrideIs = OverrideIsOptions[0];

        if (field) {
            this.activeField = this.fields.find(f => f.id === field.id) || this.fields[0];

            if (field.overrideIf) {
                this.activeOverrideField = this.fields.find(f => f.id === field.overrideIf.id) || this.fields[0];
            }
        }

        this.state = {
            dataHasChanged: !field,
            optional: !!field?.isOptional,
            override: !!field?.overrideIf,
            overrideTo: field?.overrideIf?.to || '',
        };
    }

    handleClose() {
        this.props.onClose();
    }

    handleSave() {
        let changes = {
            id: this.activeField.id,
            isOptional: this.state.optional
        };

        if (this.state.override) {
            changes.overrideIf = {
                id: this.activeOverrideField.id,
                is: this.activeOverrideIs,
                to: this.state.overrideTo
            };
        }

        this.props.onClose(changes);
    }

    handleFieldChange(items) {
        this.activeField = items[0];

        this.setState({
            dataHasChanged: true
        });
    }

    handleOverrideFieldChange(items) {
        this.activeOverrideField = items[0];

        this.setState({
            dataHasChanged: true
        });
    }

    handleOverrideIsChange(items) {
        this.activeOverrideIs = items[0];

        this.setState({
            dataHasChanged: true
        });
    }

    handleOverrideToChange(ev) {
        this.setState({
            overrideTo: ev.target.value,
            dataHasChanged: true
        });
    }

    handleOptionalChange(ev) {
        this.setState({
            optional: ev.target.checked,
            dataHasChanged: true
        });
    }

    handleOverrideChange(ev) {
        this.setState({
            override: ev.target.checked,
            dataHasChanged: true
        });
    }

    getFields(elementId, ignore) {
        const cfs = app.globalData.customFieldsInfo[elementId];
        let fields = [];

        for (const id in cfs) {
            const cf = cfs[id];

            if (cf.type !== 'text') {
                continue;
            }

            const cfid = `custom_field.${id}`;

            if (ignore && ignore.indexOf(cfid) !== -1) {
                continue;
            }

            fields.push({
                id: cfid,
                title: cf.name
            });
        }

        return fields;
    }

    render() {
        const dialogTitle = `${this.props.field ? 'Edit' : 'New'} Address Field`;

        return (
            <div className={style.newDynamicPinBackdrop}>
                <div className={style.ndpDialog}>
                    <div className={style.ndpHeader}>
                        <div className={style.ndphContent}>
                            <div
                                className={style.ndphButton}
                                onClick={this.handleClose.bind(this)}
                            >
                                Close
                            </div>
                            <div className={style.ndphTitle}>{dialogTitle}</div>
                            <div
                                className={style.ndphButton}
                                style={{color: '#43d350', visibility: this.state.dataHasChanged ? 'visible' : 'hidden'}}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    </div>

                    <div className={style.ndpContent}>
                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Field</div>
                            <div style={{width: '220px'}}>
                                <NewSelect
                                    data={this.fields}
                                    value={this.activeField}
                                    width='220'
                                    editViewMod={true}
                                    onSelect={this.handleFieldChange.bind(this)}
                                />
                            </div>
                        </div>

                        <div style={{marginTop: '10px'}}/>

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Optional</div>
                            <div className='switch-container'>
                                <input
                                    type='checkbox'
                                    className='checkbox-switch-control'
                                    id='field-optional'
                                    name='field-optional'
                                    checked={this.state.optional}
                                    onChange={this.handleOptionalChange.bind(this)}
                                />
                                <label
                                    htmlFor='field-optional'
                                    className='checkbox-switch'
                                />
                            </div>
                        </div>

                        <div style={{marginTop: '10px'}}/>

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Override</div>
                            <div className='switch-container'>
                                <input
                                    type='checkbox'
                                    className='checkbox-switch-control'
                                    id='field-override'
                                    name='field-override'
                                    checked={this.state.override}
                                    onChange={this.handleOverrideChange.bind(this)}
                                />
                                <label
                                    htmlFor='field-override'
                                    className='checkbox-switch'
                                />
                            </div>
                        </div>

                        {this.state.override &&
                            <div className={style.ndpSubContent}>
                                <div className={style.ndpcRow}>
                                    <div className={style.ndpcrLabel}>Field</div>
                                    <div style={{width: '220px'}}>
                                        <NewSelect
                                            data={this.fields}
                                            value={this.activeOverrideField}
                                            width='220'
                                            editViewMod={true}
                                            onSelect={this.handleOverrideFieldChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className={style.ndpcRow}>
                                    <div className={style.ndpcrLabel}>Is</div>
                                    <div style={{width: '220px'}}>
                                        <NewSelect
                                            data={OverrideIsOptions}
                                            value={this.activeOverrideIs}
                                            width='220'
                                            editViewMod={true}
                                            onSelect={this.handleOverrideIsChange.bind(this)}
                                        />
                                    </div>
                                </div>

                                <div className={style.ndpcRow}>
                                    <div className={style.ndpcrLabel}>Value</div>
                                    <input
                                        className={style.ndpcrInput}
                                        placeholder='Type an override value'
                                        value={this.state.overrideTo}
                                        onChange={this.handleOverrideToChange.bind(this)}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class AddressBuilder extends React.Component {
    componentDidMount() {
        const el = $(this.listEl);
        const self = this;

        el.sortable({
            containment: el,
            scroll: false,
            tolerance: 'pointer',
            handle: `.${style.rHandler}`,
            axis: 'y',
            start: function(ev, ui) {
                $(ui.item).addClass(style.tDragged);
            },
            stop: function(ev, ui) {
                $(ui.item).removeClass(style.tDragged);

                // new order for the fields
                let newOrder = [];

                for (const c of el.children(`.${style.tRow}`)) {
                    newOrder.push($(c).attr('id'));
                }

                self.props.onSortFields(newOrder);
            }
        });
    }

    render() {
        return (
            <div className={style.addressBuilder}>
                <div className={style.psHeader}>
                    <div className={style.psTitle}>Address Builder</div>
                    <div
                        className={style.psButton}
                        onClick={() => this.props.onEditField(null)}
                    >
                        Add New Address Field
                    </div>
                </div>
                <div className={style.tableHeader}>
                    <div className={style.tTitle}>Field</div>
                </div>
                <div
                    ref={(el) => this.listEl = el}
                    className={style.tableContent}
                >
                    {this.props.fields.map(field => {
                        return (
                            <div
                                key={`field_${field.id}`}
                                id={field.id}
                                className={style.tRow}
                            >
                                <div className={style.rHandler}/>
                                <div>{field.name}</div>
                                <div className={style.rActions}>
                                    <div
                                        className={`${style.aIcon} icon-pencil`}
                                        onClick={() => this.props.onEditField(field)}
                                    />
                                    <div
                                        className={style.aDelete}
                                        onClick={() => this.props.onDeleteField(field)}
                                    >
                                        <div/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class PinsSection extends React.Component {
    construtor(props) {
        this.defaultPin = null;
    }

    render() {
        const title = `${this.props.type} pin settings`;
        const buttonLabel = `new ${this.props.type} pin`;
        const PinComponent = this.props.type === 'dynamic' ? DynamicPinItem : StaticPinItem;
        let defaultPinComponent = null;

        if (this.props.type === 'dynamic') {
            const elementId = this.props.elementId;

            defaultPinComponent = (
                <DefaultPinItem
                    ref={(el) => {if (el) this.defaultPin = el}}
                    key={`dynamic_pin_${elementId}`}
                    editor={this.props.editor}
                    elementId={elementId}
                    mapConfig={this.props.mapConfig[ELEMENT_PLURAL[elementId]]}
                />
            );
        }

        return (
            <div className={style.pinsSection}>
                <div className={style.psHeader}>
                    <div className={style.psTitle}>{title}</div>
                    <div
                        className={style.psButton}
                        onClick={() => this.props.onEditPin(null)}
                    >
                        {buttonLabel}
                    </div>
                </div>
                <div className={style.psPinsList}>
                    {defaultPinComponent}
                    {this.props.pins.map(pin => {
                        return (
                            <PinComponent
                                key={pin.name}
                                data={pin}
                                editor={this.props.editor}
                                mapConfig={this.props.mapConfig}
                                onDelete={this.props.onDeletePin}
                                onEdit={this.props.onEditPin}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

class EditStaticPin extends React.Component {
    constructor(props) {
        super(props);

        // build anchors
        const verOptions = ['Top', 'Middle', 'Bottom'];
        const horOptions = ['Left', 'Center', 'Right'];

        this.anchors = [];

        for (const v of verOptions) {
            for (const h of horOptions) {
                this.anchors.push({
                    id: `${v.toLowerCase()}-${h.toLowerCase()}`,
                    title: `${v} ${h}`
                });
            }
        }

        const pin = this.props.pin;

        if (pin?.icon) {
            this.loadIcon(pin.icon);
        }

        this.state = {
            dataHasChanged: false,
            color: pin?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR,
            icon: null,
            name: pin?.name || '',
            clickUrl: pin?.clickUrl || '',
            hoverMessage: pin?.hoverMessage || '',
            errors: {}
        };

        this.activeAnchor = pin ? this.anchors.find(a => a.id === pin.anchor) : this.anchors[0];
        this.address = pin?.address ? _.clone(pin.address) : null;
    }

    handleClose() {
        this.props.onClose();
    }

    handleSave() {
        let isOk = true;

        if (!this.state.name.trim()) {
            this.setState({
                errors: _.extend(this.state.errors, {name: 'A name is required'})
            });

            isOk = false;
        }

        if (!this.address) {
            this.setState({
                errors: _.extend(this.state.errors, {address: 'An address is required'})
            });

            isOk = false;
        }

        if (!isOk) {
            return;
        }

        if (!this.props.pin || (this.props.pin.name !== this.state.name)) {
            isOk = !this.props.editor.getStaticPinByName(this.props.elementId, this.state.name);
        }

        if (isOk) {
            this.props.onClose({
                name: this.state.name,
                elementId: this.props.elementId,
                address: this.address,
                icon: this.state.icon,
                color: this.state.color,
                anchor: this.activeAnchor.id,
                clickUrl: this.state.clickUrl,
                hoverMessage: this.state.hoverMessage
            });
        } else {
            this.setState({
                errors: _.extend(this.state.errors, {name: 'This name is already in use'})
            });
        }
    }

    handleNameChange(ev) {
        this.setState({
            name: ev.target.value,
            dataHasChanged: true,
            errors: _.extend(this.state.errors, {name: null})
        });
    }

    handleAnchorChange(items) {
        this.activeAnchor = items[0];

        this.setState({
            dataHasChanged: true
        });
    }

    handleAddressSelect(items) {
        this.address = items[0];

        this.setState({
            dataHasChanged: true,
            errors: _.extend(this.state.errors, {address: null})
        });
    }

    handleClickUrlChange(ev) {
        this.setState({
            clickUrl: ev.target.value,
            dataHasChanged: true
        });
    }

    handleHoverMessageChange(ev) {
        this.setState({
            hoverMessage: ev.target.value,
            dataHasChanged: true
        });
    }

    handleColorChange(color) {
        const el = this.state.iconEl;

        if (el) {
            el.find('path').css('fill', color);
        }

        this.setState({
            color: color,
            dataHasChanged: true
        });
    }

    onIconSelected(icon) {
        if (icon) {
            this.loadIcon(icon.url)
        }

        this.setState({
            dataHasChanged: true
        });
    }

    loadIcon(iconUrl) {
        const self = this;

        $.ajax({
            url: iconUrl,
            type: 'GET',
            success: function (data) {
                let el = $('<div/>');

                el.append(data);

                const svgEl = el.find('svg');

                svgEl.css('width', '64px');
                svgEl.css('height', '64px');
                svgEl.find('path').css('fill', self.state.color);

                self.setState({
                    icon: iconUrl,
                    iconEl: el
                });
            }
        });
    }

    render() {
        const dialogTitle = `${this.props.pin ? 'Edit' : 'New'} Static Pin`;

        return (
            <div className={style.newDynamicPinBackdrop}>
                <div className={style.ndpDialog}>
                    <div className={style.ndpHeader}>
                        <div className={style.ndphContent}>
                            <div
                                className={style.ndphButton}
                                onClick={this.handleClose.bind(this)}
                            >
                                Close
                            </div>
                            <div className={style.ndphTitle}>{dialogTitle}</div>
                            <div
                                className={style.ndphButton}
                                style={{color: '#43d350', visibility: this.state.dataHasChanged ? 'visible' : 'hidden'}}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                    <div className={style.ndpContent}>
                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Name</div>
                            <input
                                autoFocus={!this.props.pin}
                                className={`${style.ndpcrInput} ${this.state.errors.name ? style.iError : ''}`}
                                placeholder='Name'
                                value={this.state.name}
                                onChange={this.handleNameChange.bind(this)}
                            />
                        </div>
                        {this.state.errors.name &&
                            <div className={style.ndpcRow}>
                                <div className={style.ndpcrLabel}/>
                                <div className={style.ndpcrError}>{this.state.errors.name}</div>
                            </div>
                        }

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Address/Location</div>
                            <div style={{width: '220px'}}>
                                <NewSelect
                                    url='/geocoding'
                                    text='name'
                                    value={this.address}
                                    width='350'
                                    placeholder='No. Street, Town, County, Country'
                                    editViewMod={true}
                                    options={{minimumInputLength: 5}}
                                    onSelect={this.handleAddressSelect.bind(this)}
                                />
                            </div>
                        </div>
                        {this.state.errors.address &&
                            <div className={style.ndpcRow}>
                                <div className={style.ndpcrLabel}/>
                                <div className={style.ndpcrError}>{this.state.errors.address}</div>
                            </div>
                        }

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Color</div>
                            <ColorInputField
                                value={this.state.color}
                                editor={this.props.editor}
                                onChange={this.handleColorChange.bind(this)}
                            />
                        </div>

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Icon</div>
                            <div
                                className={style.ndpcrIconContainer}
                                onClick={() => this.props.editor.openLibraryBrowser(this.onIconSelected.bind(this))}
                            >
                                <div
                                    className={style.ndpcrIcon}
                                    style={{fill: this.state.color}}
                                    dangerouslySetInnerHTML={{ __html: this.state.icon ? this.state.iconEl[0].outerHTML : DEMOGRAPHIC_MAP_DEFAULT_SVG_ICON}}
                                />
                            </div>

                            {this.state.icon &&
                                <div
                                    className={style.ndpcrOption}
                                    onClick={() => this.setState({icon: null, iconEl: null})}
                                >
                                    <div style={{marginRight: '5px'}} className='icon-trashcan'/>
                                    <div>Delete</div>
                                </div>
                            }
                        </div>

{/*                     We have disable the anchor field because it looks like there is a bug in the map library and if the anchor no is in the bottom of the icon
                        it is moved around when the map is zoomed
                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Anchor</div>
                            <div style={{width: '220px'}}>
                                <NewSelect
                                    data={this.anchors}
                                    value={this.activeAnchor}
                                    width='220'
                                    editViewMod={true}
                                    onSelect={this.handleAnchorChange.bind(this)}
                                />
                            </div>
                        </div>*/}

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>URL</div>
                            <input
                                className={style.ndpcrInput}
                                placeholder='http://www.example.com'
                                value={this.state.clickUrl}
                                onChange={this.handleClickUrlChange.bind(this)}
                            />
                        </div>

                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Hover Message</div>
                            <input
                                className={style.ndpcrInput}
                                placeholder='Message'
                                value={this.state.hoverMessage}
                                onChange={this.handleHoverMessageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class EditDynamicPin extends React.Component {
    constructor(props) {
        super(props);

        const pin = this.props.pin;

        this.dropdownFields = this.getDropdowns(ELEMENT_PLURAL[props.elementId]);
        this.activeDropdownField = this.dropdownFields.find(d => d.id === pin?.dropdownId) || this.dropdownFields[0];

        this.state = {
            name: pin?.name || '',
            nameError: null,
            dropdownOptions: this.getActiveDropdownOptions(pin),
            dataHasChanged: false
        };
    }

    getActiveDropdownOptions(pin) {
        let options = [{
            id: 'default',
            name: 'Default',
            color: pin?.colors.find(c => c.id === 'default').color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR
        }];

        for (const o of this.activeDropdownField.options) {
            options.push({
                id: o.id,
                name: o.title,
                color: pin?.colors.find(c => c.id === o.id)?.color || DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR
            });
        }

        return options;
    }

    getDropdowns(elementId) {
        const cfs = app.globalData.customFieldsInfo[elementId];
        let dropdowns = [];

        for (const id in cfs) {
            const cf = cfs[id];

            if (cf.type !== 'dropDown') {
                continue;
            }

            let options = [];

            for (const oid in cf.options) {
                options.push({
                    id: oid,
                    title: cf.options[oid]
                });
            }

            dropdowns.push({
                id: id,
                title: cf.name,
                options: options
            });
        }

        return dropdowns;
    }

    handleDropdownFieldChange(items) {
        this.activeDropdownField = items[0];

        this.setState({
            dropdownOptions: this.getActiveDropdownOptions(),
            dataHasChanged: true
        });
    }

    handleColorChange(optionId, color) {
        let ddOption = this.state.dropdownOptions.find(o => o.id === optionId);

        if (ddOption) {
            ddOption.color = color;

            this.setState({
                dataHasChanged: true
            });
        }
    }

    handleClose() {
        this.props.onClose();
    }

    handleSave() {
        if (!this.state.name.trim()) {
            this.setState({
                nameError: 'A name is required'
            });
        } else if (this.state.name.toLowerCase() === 'default') {
            this.setState({
                nameError: 'This name is already in use'
            });
        } else {
            let isOk = true;

            if (!this.props.pin || (this.props.pin.name !== this.state.name)) {
                isOk = !this.props.editor.getDynamicPinByName(this.props.elementId, this.state.name);
            }

            if (isOk) {
                this.props.onClose({
                    name: this.state.name,
                    dropdownId: this.activeDropdownField.id,
                    options: this.state.dropdownOptions
                });
            } else {
                this.setState({
                    nameError: 'This name is already in use'
                });
            }
        }
    }

    handleInputChange(ev) {
        this.setState({
            name: ev.target.value,
            dataHasChanged: true,
            nameError: null
        });
    }

    render() {
        const dialogTitle = `${this.props.pin ? 'Edit' : 'New'} Dynamic Pin`;

        return (
            <div className={style.newDynamicPinBackdrop}>
                <div className={style.ndpDialog}>
                    <div className={style.ndpHeader}>
                        <div className={style.ndphContent}>
                            <div
                                className={style.ndphButton}
                                onClick={this.handleClose.bind(this)}
                            >
                                Close
                            </div>
                            <div className={style.ndphTitle}>{dialogTitle}</div>
                            <div
                                className={style.ndphButton}
                                style={{color: '#43d350', visibility: this.state.dataHasChanged ? 'visible' : 'hidden'}}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                    <div className={style.ndpContent}>
                        <div style={{marginBottom: '25px'}} className={style.ndpcBadge}>
                            <div className={`${style.ndpcbBolt} icon-bolt`}/>
                            <div className={style.ndpcbMarker}>
                                <div className='icon-location'/>
                            </div>
                        </div>
                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Name</div>
                            <input
                                autoFocus={!this.props.pin}
                                className={`${style.ndpcrInput} ${this.state.nameError ? style.iError : ''}`}
                                placeholder='Name'
                                value={this.state.name}
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </div>
                        {this.state.nameError &&
                            <div className={style.ndpcRow}>
                                <div className={style.ndpcrLabel}/>
                                <div className={style.ndpcrError}>{this.state.nameError}</div>
                            </div>
                        }
                        <div className={style.ndpcRow}>
                            <div className={style.ndpcrLabel}>Dropdown Field</div>
                            <div style={{width: '220px'}}>
                                <NewSelect
                                    ref={(el) => this.dropdownFieldsComponent = el}
                                    data={this.dropdownFields}
                                    value={this.activeDropdownField}
                                    width='220'
                                    editViewMod={true}
                                    onSelect={this.handleDropdownFieldChange.bind(this)}
                                />
                            </div>
                        </div>

                        <div className={style.ndpcDropdownOptionsTitle}>
                            <div>Dropdown Option</div>
                            <div>Pin Color</div>
                        </div>

                        <div className={style.ndpcDropdownOptions}>
                            {this.state.dropdownOptions.map(opt => {
                                return (
                                    <div
                                        key={opt.id}
                                        className={style.dOption}
                                    >
                                        <div
                                            className={style.doName}
                                            title={opt.name}
                                        >
                                            <div>{opt.name}</div>
                                        </div>
                                        <ColorInputField
                                            value={opt.color}
                                            editor={this.props.editor}
                                            onChange={(color) => this.handleColorChange(opt.id, color)}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ElementSettings extends React.Component {
    constructor(props) {
        super(props);

        const preferences = app.user.get('client').preferences;
        this.mapConfig = preferences?.demographic_maps ? JSON.parse(preferences.demographic_maps) : {};

        // dropdown name by id
        this.dropdownNameById = {};

        const cfs = app.globalData.customFieldsInfo[ELEMENT_PLURAL[this.props.elementId]];

        for (const id in cfs) {
            const cf = cfs[id];

            if (cf.type === 'dropDown') {
                this.dropdownNameById[id] = cf.name;
            }
        }

        let dynamicPins = [];
        let staticPins = [];
        let addressBuilderFields = [];

        if (!_.isEmpty(this.mapConfig)) {
            const elementConfig = this.mapConfig[ELEMENT_PLURAL[this.props.elementId]];

            if (elementConfig?.address_builder) {
                for (const f of elementConfig.address_builder) {
                    const fid = f.field_id;

                    let field = {
                        id: fid,
                        isOptional: f.is_optional,
                    };

                    if (f.override_if) {
                        field.overrideIf = {
                            id: f.override_if.field_id,
                            is: OverrideIsOptions.find(d => d.id === f.override_if.is) || OverrideIsOptions[0],
                            to: f.override_if.to
                        };
                    }

                    field.name = this.getAddressBuilderFieldName(fid);

                    addressBuilderFields.push(field);
                }
            }

            if (elementConfig?.dynamic_pins) {
                for (const name in elementConfig.dynamic_pins) {
                    const pin = elementConfig.dynamic_pins[name];

                    if (name !== 'default') {
                        dynamicPins.push({
                            name: name,
                            elementId: this.props.elementId,
                            dropdownId: pin.dropdown_id,
                            dropdownName: this.dropdownNameById[pin.dropdown_id],
                            colors: _.clone(pin.colors)
                        });
                    }
                }
            }

            if (elementConfig?.static_pins) {
                for (const name in elementConfig.static_pins) {
                    const pin = elementConfig.static_pins[name];

                    staticPins.push({
                        name: name,
                        elementId: this.props.elementId,
                        address: {
                            name: pin.address,
                            coords: pin.coords
                        },
                        color: pin.color,
                        anchor: pin.anchor,
                        icon: pin.icon,
                        clickUrl: pin.click_url,
                        hoverMessage: pin.hover_msg
                    });
                }
            }
        }

        this.state = {
            dynamicPins: dynamicPins,
            staticPins: staticPins,
            addressBuilderFields: addressBuilderFields,
            colorPicker: null,
            libraryBrowserCallback: null,
            editDynamicPinDialog: {
                visible: false,
                pin: null
            },
            editStaticPinDialog: {
                visible: false,
                pin: null
            },
            editAddressFieldDialog: {
                visible: false,
                field: null
            }
        };
    }

    getAddressBuilderFieldName(id) {
        if (id.indexOf('custom_field.') === 0) {
            const cfs = app.globalData.customFieldsInfo[ELEMENT_PLURAL[this.props.elementId]];
            return cfs[id.substring('custom_field.'.length)]?.name || 'Unknown';
        }

        return 'System Field'; // fix this if we add support for system fields
    }

    showColorPicker(el, color, callback) {
        this.setState({
          colorPicker: {
            el: el,
            color: color,
            callback: callback
          }
        });
    }

    onColorPickerChange(color) {
        this.colorPickerLastColorUsed = color.hex;
        this.state.colorPicker.callback(color);
    }

    onColorPickerClose() {
        if (this.colorPickerLastColorUsed && ColorPickerMostUsedColors.indexOf(this.colorPickerLastColorUsed) === -1) {
          ColorPickerMostUsedColors.pop();
          ColorPickerMostUsedColors.unshift(this.colorPickerLastColorUsed);
        }

        this.colorPickerLastColorUsed = null;
        this.setState({colorPicker: null});
    }

    onDefaultPinColorChange(elementId, color) {
        this.savePrefs();
    }

    onPinDelete(pin, pinType) {
        const mbContent = {
            message: 'Are you sure you want to <strong>permanently</strong> delete this pin?',
            icon: 'icon-trashcan',
            accept_is_negative: true
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            const pins = _.filter(self.state[`${pinType}Pins`], dp => dp.elementId !== pin.elementId || dp.name !== pin.name);

            self.setState({
                [`${pinType}Pins`]: pins
            });

            _.defer(function() {
                self.savePrefs();
            });
        });
    }

    onAddressFieldDelete(field) {
        const mbContent = {
            message: 'Are you sure you want to delete this field?',
            icon: 'icon-trashcan',
            accept_is_negative: true
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            const fields = _.filter(self.state.addressBuilderFields, f => f.id !== field.id);

            self.setState({
                addressBuilderFields: fields
            });

            _.defer(function() {
                self.savePrefs();
            });
        });
    }

    onAddressSortFields(newOrder) {
        let fieldsById = {};

        for (const f of this.state.addressBuilderFields) {
            fieldsById[f.id] = f;
        }

        let newFields = [];

        for (const id of newOrder) {
            newFields.push(fieldsById[id]);
        }

        this.setState({
            addressBuilderFields: newFields
        });

        const self = this;

        _.defer(function() {
            self.savePrefs();
        });
    }

    openLibraryBrowser(callback) {
        this.setState({
          libraryBrowserCallback: callback
        });
    }

    onIconSelected(icon) {
        this.state.libraryBrowserCallback(icon);
        this.setState({libraryBrowserCallback: null});
    }

    libraryBrowserFilter(file) {
        return file.ext.toLowerCase() === '.svg';
    }

    showEditDynamicPinDialog(pin) {
        this.setState({
            editDynamicPinDialog: {
                visible: true,
                pin: pin
            }
        });
    }

    showEditStaticPinDialog(pin) {
        this.setState({
            editStaticPinDialog: {
                visible: true,
                pin: pin
            }
        });
    }

    showEditAddressFieldDialog(field) {
        this.setState({
            editAddressFieldDialog: {
                visible: true,
                field: field
            }
        });
    }

    closeDynamicPinDialog(changes) {
        if (changes) {
            const pin = this.state.editDynamicPinDialog.pin;
            let dynamicPins = this.state.dynamicPins;
            let pinToUpdate = pin ? dynamicPins.find(d => d.elementId === pin.elementId && d.name === pin.name) : {};

            pinToUpdate.name = changes.name;
            pinToUpdate.elementId = this.props.elementId;
            pinToUpdate.dropdownId = changes.dropdownId;
            pinToUpdate.dropdownName = this.dropdownNameById[changes.dropdownId];
            pinToUpdate.colors = changes.options.map(o => {
                return {
                    id: o.id,
                    color: o.color
                };
            });

            if (!pin) {
                dynamicPins.push(pinToUpdate);
            }

            this.setState({
                editDynamicPinDialog: {
                    visible: false,
                    pin: null
                },
                dynamicPins: dynamicPins
            });

            const self = this;

            _.defer(function() {
                self.savePrefs();
            });
        } else {
            this.setState({
                editDynamicPinDialog: {
                    visible: false,
                    pin: null
                }
            });
        }
    }

    closeStaticPinDialog(changes) {
        if (changes) {
            const pin = this.state.editStaticPinDialog.pin;
            let staticPins = this.state.staticPins;
            let pinToUpdate = pin ? staticPins.find(s => s.name === pin.name) : {};

            pinToUpdate.name = changes.name;
            pinToUpdate.elementId = changes.elementId;
            pinToUpdate.address = changes.address;
            pinToUpdate.icon = changes.icon;
            pinToUpdate.anchor = changes.anchor;
            pinToUpdate.color = changes.color;
            pinToUpdate.clickUrl = changes.clickUrl;
            pinToUpdate.hoverMessage = changes.hoverMessage;

            if (!pin) {
                staticPins.push(pinToUpdate);
            }

            this.setState({
                editStaticPinDialog: {
                    visible: false,
                    pin: null
                },
                staticPins: staticPins
            });

            const self = this;

            _.defer(function() {
                self.savePrefs();
            });
        } else {
            this.setState({
                editStaticPinDialog: {
                    visible: false,
                    pin: null
                }
            });
        }
    }

    closeAddressFieldDialog(changes) {
        if (changes) {
            const field = this.state.editAddressFieldDialog.field;
            let addressBuilderFields = this.state.addressBuilderFields;
            let fieldToUpdate = field ? addressBuilderFields.find(f => f.id === field.id) : {};

            fieldToUpdate.id = changes.id;
            fieldToUpdate.name = this.getAddressBuilderFieldName(changes.id);
            fieldToUpdate.isOptional = changes.isOptional;
            fieldToUpdate.overrideIf = changes.overrideIf;

            if (!field) {
                addressBuilderFields.push(fieldToUpdate);
            }

            this.setState({
                editAddressFieldDialog: {
                    visible: false,
                    field: null
                },
                addressBuilderFields: addressBuilderFields
            });

            const self = this;

            _.defer(function() {
                self.savePrefs();
            });
        } else {
            this.setState({
                editAddressFieldDialog: {
                    visible: false,
                    field: null
                }
            });
        }
    }

    savePrefs() {
        let demographicMaps = {
            individuals: {
                address_builder: [],
                dynamic_pins: {},
                static_pins: {}
            },
            organizations: {
                address_builder: [],
                dynamic_pins: {},
                static_pins: {}
            },
            opportunities: {
                address_builder: [],
                dynamic_pins: {},
                static_pins: {}
            }
        };

        const elementId = ELEMENT_PLURAL[this.props.elementId];

        for (const e of ['individuals', 'organizations', 'opportunities']) {
            if (!this.mapConfig[e] || e === elementId) {
                continue;
            }

            if (this.mapConfig[e].address_builder) {
                demographicMaps[e].address_builder = _.clone(this.mapConfig[e].address_builder);
            }

            if (this.mapConfig[e].dynamic_pins) {
                demographicMaps[e].dynamic_pins = _.clone(this.mapConfig[e].dynamic_pins);
            }

            if (this.mapConfig[e].static_pins) {
                demographicMaps[e].static_pins = _.clone(this.mapConfig[e].static_pins);
            }
        }

        const pinColor = this.dynamicPinsSectionComponent.defaultPin.state.fillColor;

        if (pinColor !== DEMOGRAPHIC_MAP_DEFAULT_PIN_COLOR) {
            demographicMaps[elementId].dynamic_pins = {
                default: {
                    color: pinColor
                }
            };
        }

        for (const dp of this.state.dynamicPins) {
            demographicMaps[elementId].dynamic_pins[dp.name] = {
                dropdown_id: dp.dropdownId,
                colors: dp.colors
            };
        }

        for (const sp of this.state.staticPins) {
            demographicMaps[elementId].static_pins[sp.name] = {
                address: sp.address.name,
                coords: sp.address.coords,
                icon: sp.icon,
                click_url: sp.clickUrl,
                hover_msg: sp.hoverMessage,
                anchor: sp.anchor,
                color: sp.color
            };
        }

        if (this.state.addressBuilderFields.length > 0) {
            let fields = [];

            for (const f of this.state.addressBuilderFields) {
                let data = {
                    field_id: f.id,
                    is_optional: f.isOptional
                };

                if (f.overrideIf) {
                    data.override_if = {
                        field_id: f.overrideIf.id,
                        is: f.overrideIf.is.id,
                        to: f.overrideIf.to
                    }
                }

                fields.push(data);
            }

            if (fields.length > 0) {
                demographicMaps[elementId].address_builder = fields;
            }
        }

        for (const e in demographicMaps) {
            if (_.isEmpty(demographicMaps[e].dynamic_pins)) {
                delete demographicMaps[e].dynamic_pins;
            }

            if (_.isEmpty(demographicMaps[e].static_pins)) {
                delete demographicMaps[e].static_pins;
            }

            if (demographicMaps[e].address_builder.length === 0) {
                delete demographicMaps[e].address_builder;
            }
        }

        for (const e in demographicMaps) {
            if (_.isEmpty(demographicMaps[e])) {
                delete demographicMaps[e];
            }
        }

        let clientPreferences = app.user.get('client').preferences;

        clientPreferences.demographic_maps = JSON.stringify(demographicMaps);

        const self = this;

        $.ajax({
            type: 'PATCH',
            url: '/clients/1',
            dataType: 'json',
            data: JSON.stringify({
                preferences: clientPreferences
            }),
            success: function(data) {
                self.mapConfig = JSON.parse(data.preferences.demographic_maps);

                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Preferences saved',
                            classes: 'saved success',
                            timer: 3000
                        };
                    }
                });
            }
        });
    }

    getDynamicPinByName(elementId, name) {
        const lname = name.toLowerCase();

        for (const pin of this.state.dynamicPins) {
            if (pin.elementId === pin.elementId && pin.name === lname) {
                return pin;
            }
        }

        return null;
    }

    getStaticPinByName(elementId, name) {
        const lname = name.toLowerCase();

        for (const pin of this.state.staticPins) {
            if (pin.elementId === pin.elementId && pin.name === lname) {
                return pin;
            }
        }

        return null;
    }

    render() {
        return (
            <div className={style.elementSettings}>
                <AddressBuilder
                    fields={this.state.addressBuilderFields}
                    onEditField={this.showEditAddressFieldDialog.bind(this)}
                    onDeleteField={this.onAddressFieldDelete.bind(this)}
                    onSortFields={this.onAddressSortFields.bind(this)}
                />

                <div style={{marginTop: '60px'}}/>

                <PinsSection
                    ref={(el) => this.dynamicPinsSectionComponent = el}
                    type='dynamic'
                    elementId={this.props.elementId}
                    pins={this.state.dynamicPins}
                    editor={this}
                    mapConfig={this.mapConfig}
                    onEditPin={this.showEditDynamicPinDialog.bind(this)}
                    onDeletePin={(pin) => this.onPinDelete.bind(this)(pin, 'dynamic')}
                />

                <div style={{marginTop: '60px'}}/>

                <PinsSection
                    type='static'
                    pins={this.state.staticPins}
                    editor={this}
                    mapConfig={this.mapConfig}
                    onEditPin={this.showEditStaticPinDialog.bind(this)}
                    onDeletePin={(pin) => this.onPinDelete.bind(this)(pin, 'static')}
                />

                {this.state.colorPicker &&
                  <ColorPicker
                    el={this.state.colorPicker.el}
                    color={this.state.colorPicker.color}
                    onChange={this.onColorPickerChange.bind(this)}
                    onClose={this.onColorPickerClose.bind(this)}
                    presetColors={this.colorPickerMostUsedColors}
                  />
                }

                {this.state.editDynamicPinDialog.visible &&
                    <EditDynamicPin
                        pin={this.state.editDynamicPinDialog.pin}
                        elementId={this.props.elementId}
                        editor={this}
                        onClose={this.closeDynamicPinDialog.bind(this)}
                    />
                }

                {this.state.editStaticPinDialog.visible &&
                    <EditStaticPin
                        pin={this.state.editStaticPinDialog.pin}
                        elementId={this.props.elementId}
                        editor={this}
                        onClose={this.closeStaticPinDialog.bind(this)}
                    />
                }

                {this.state.editAddressFieldDialog.visible &&
                    <EditAddressField
                        elementId={this.props.elementId}
                        field={this.state.editAddressFieldDialog.field}
                        addressBuilderFields={this.state.addressBuilderFields}
                        parent={this.props.parent}
                        onClose={this.closeAddressFieldDialog.bind(this)}
                    />
                }

                {this.state.libraryBrowserCallback &&
                  <LibraryBrowser
                    folderId='root'
                    filter={this.libraryBrowserFilter.bind(this)}
                    parent={this.props.parent}
                    onCancel={() => this.setState({libraryBrowserCallback: null})}
                    onFileSelected={this.onIconSelected.bind(this)}
                  />
                }
            </div>
        );
    }
}

class ElementSelection extends React.Component {
    render() {
        return (
            <div className={style.elementSelection}>
                <div>Create and manage static and dynamic pins based on entity type</div>
                <div className={style.esHeader}>
                    <div className={style.hTitle}>Pin Type</div>
                </div>
                <div className={style.esContent}>
                    {ELEMENTS.map(element => {
                        return (
                            <div
                                key={`element_${element.id}`}
                                className={style.cRow}
                                onClick={() => this.props.onElementSelected(element.id)}
                            >
                                <div className={style.rIcon}>
                                    <div className={element.icon}/>
                                </div>
                                <div className={style.rTitle}>{element.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

class CustomMapPins extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedElementId: null
        };
    }

    handleElementSelection(elementId) {
        this.setState({
            selectedElementId: elementId
        });

        const header = this.props.parent.options.header;

        if (elementId) {
            const element = ELEMENTS.find(e => e.id === elementId);
            header.setTitle(`${header.options.title} (${element.title})`);
        } else {
            header.setTitle(header.options.title);
        }
    }

    onHeaderBack() {
        if (this.state.selectedElementId) {
            this.handleElementSelection(null);
            return true;
        }

        return false;
    }

    render() {
        if (!this.state.selectedElementId) {
            return (
                <ElementSelection
                    onElementSelected={this.handleElementSelection.bind(this)}
                />
            );
        }

        return (
            <ElementSettings
                elementId={this.state.selectedElementId}
                parent={this.props.parent}
            />
        );
    }
}

export default Marionette.Layout.extend({
    className: 'edit-custom-map-pins',
    template: Handlebars.compile(''),
    onHeaderBack: function() {
        return this.component.onHeaderBack();
    },
    onRender: function() {
        ReactDOM.render(
            <CustomMapPins
                ref={(el) => {this.component = el}}
                parent={this}
            />,
            this.$el.get(0)
        );
    }
});

import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import handlebars from 'handlebars'

import vent from 'js/vent'
import api from 'js/api'
import app from 'js/app'
import appContent from 'js/views/appcontent'
import ErrorView from 'js/views/base/error'
import AlertView from 'js/views/alert'
import AppConfig from 'app/app-config'

import MessageBox from 'js/views/message_box'
import DashboardView from 'app/dashboard/dashboard'
import ContactsView from 'app/contacts/contacts'
import LeadListView from 'app/_components/lead-listing/lead-list'

import IndividualView from 'js/views/individuals/show'
import IndividualModel from 'js/models/contact'
import OrganizationView from 'js/views/organizations/show'
import OrganizationModel from 'js/models/organization'
import SocialFeedView from 'js/views/social/feed'

import WebView from 'js/views/web'
import OpportunityView from 'js/views/opportunities/show'
import OpportunityModel from 'js/models/opportunity'
import ForecastsListView from 'js/views/forecasts/list'

import ForecastView from 'js/views/forecasts/show'
import ForecastModel from 'js/models/forecast'
import forecastCreateErrorTemplate from 'templates/forecasts/create-error.handlebars'
import TasksView from 'app/tasks/tasks'
import ActivitiesView from 'app/activities/activities'
import TextsView from 'app/texts/texts'

import ContentView from 'js/views/content'
import CampaignsView from 'app/campaigns/campaigns'
import AutomationsView from 'app/automations/automations'
import AutomationReactContainerView from 'app/automations/automation'
import Automation2Model from 'js/models/automation2'
import SettingsView from 'js/views/settings'
import UserSettingsView from 'js/views/user_settings'
import ForcedPasswordChangeView from 'js/views/forced_password_change'
import BrowserSupportView from 'js/views/browser_support'
import MarketingView from 'js/react_views/marketing/marketing'
import SectionView from 'app_v2/sections/section'
import PortalAdminView from 'js/react_views/portal-admin/portal'

import LoginModel from 'js/models/login'
import LoginView from 'js/views/login'
import InvalidClientView from 'js/views/invalid_client'
import ForgottenView from 'js/views/forgotten'
import TwoFactorAuthView from 'js/views/2f_auth'
import DealsView from 'app/deals/deals'


var AppController;

function listenToView(controller, view) {
    controller.listenTo(view, 'push-view:recent:individuals:show', function() {
        controller.recentIndividuals({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:recent:organizations:show', function() {
        controller.recentOrganizations({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:recent:created:individuals:show', function() {
        controller.recentCreatedIndividuals({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:recent:created:organizations:show', function() {
        controller.recentCreatedOrganizations({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:favorites:show', function() {
        controller.favorites({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:elephants:show', function() {
        controller.elephants({
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:group:show', function(options) {
        controller.groupDetail({
            id: options.id,
            model: options.model,
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:group:show', function(options) {
        controller.groupDetail({
            id: options.id,
            model: options.model,
            replaceView: view,
            smart: options.smart
        }, controller.show);
    });

    controller.listenTo(view, 'set-view:group:show', function(options) {
        controller.setViewGroupDetail({
            id: options.id,
            model: options,
            replaceView: view,
            smart: options.smart
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:group:new', function(options) {
        controller.groupDetail({
            contextView: view,
            edit: true,
            smart: options.smart
        }, controller.show);
    });

    controller.listenTo(view, 'push-view-with-context:individual:show', function(options) {
        controller.individualDetail({
            id: options.id,
            model: options.model,
            context: options.context || 'individualList'
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:individual:show', function(options) {
        controller.individualDetail({
            id: options.id,
            model: options.model,
            gotoSection: options.options?.gotoSection,
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:individual:show', function(options) {
        controller.individualDetail({
            id: options.id,
            model: options.model,
            replaceView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view-with-context:individual:new', function(options) {
        controller.individualDetail({
            id: options.id,
            model: options.model,
            edit: true,
            organization: options.organization,
            leadSource: options.leadSource,
            context: options.controller || 'leadList'
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:individual:new', function(options) {
        controller.individualDetail({
            contextView: view,
            edit: true,
            organization: options.organization,
            leadSource: options.leadSource
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:individual:new', function(options) {
        controller.individualDetail({
            edit: true,
            organization: options.organization,
            leadSource: options.leadSource,
            fromOpportunityModel: options.fromOpportunityModel,
            fromIndividualModel: options.fromIndividualModel,
            replaceView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:organization:show', function(options) {
        controller.organizationDetail({
            id: options.id,
            model: options.model,
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:organization:show', function(options) {
        controller.organizationDetail({
            id: options.id,
            model: options.model,
            replaceView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:organization:new', function() {
        controller.organizationDetail({
            contextView: view,
            edit: true
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:organization:new', function() {
        controller.organizationDetail({
            replaceView: view,
            edit: true
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:deal:show', function(options) {
        controller.dealDetail({
            id: options.id,
            viewOptions: options.viewOptions,
            onViewCreation: options.onViewCreation,
            model: options.model,
            contextView: view,
            section: options.section
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:deal:show', function(options) {
        controller.dealDetail({
            id: options.id,
            model: options.model,
            replaceView: view,
            section: options.section,
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:deal:new', function(options) {
        controller.dealDetail({
            contextView: view,
            edit: true,
            organization: options.organization,
            initialData: options.initialData,
            section: options.section
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:deal:new', function(options) {
        controller.dealDetail({
            replaceView: view,
            edit: true,
            organization: options.organization,
            contactModel: options.contactModel,
            initialData: options.initialData,
            fromIndividualModel: options.fromIndividualModel
        }, controller.show);
    });

    controller.listenTo(view, 'replace-view:forecast:show', function(options) {
        controller.forecastDetail({
            id: options.id,
            model: options.model,
            replaceView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:forecast:show', function(options) {
        controller.forecastDetail({
            id: options.id,
            model: options.model,
            contextView: view
        }, controller.show);
    });

    controller.listenTo(view, 'push-view:forecast:new', function() {
        controller.forecastDetail({
            contextView: view,
            edit: true
        }, controller.show);
    });

    controller.listenTo(view, 'view:close', function() {
        controller.closeView({
            view: view
        }, controller.show);
    });

    if (view.controller) {
        view.getParams = function() {
            var params = {};
            if (this.controller.editing) {
                params.edit = true;
            }

            return params;
        };

        controller.listenTo(view.controller, 'editing:start', function() {
            vent.trigger('AppContent:contentChange');
        });

        controller.listenTo(view.controller, 'editing:end', function() {
            vent.trigger('AppContent:contentChange');
        });
    }
}

AppController = Marionette.Controller.extend({
    /**
     * @param options.silent    bool    whether not to update URL
     * @param view
     * @param DefaultContext
     */
    show: function(options, view, DefaultContext, contextOptions) {
        contextOptions = contextOptions || {};

        listenToView(this, view);

        if (options.replaceView) {
            appContent.replaceView({ silent: options.silent }, options.replaceView, view, [[view, []]]);
        }
        else if (options.contextView && !options.replace) {
            appContent.insertAfter({ silent: options.silent }, options.contextView, view, {});
        }
        else {
            var contextView = options.contextView || ( DefaultContext && new DefaultContext(contextOptions) );
            if (contextView) {
                listenToView(this, contextView);
                appContent.replace({ silent: options.silent }, [[contextView, []], [view, []]]);
            } else {
                appContent.replace({ silent: options.silent }, [[view, []]]);
            }
        }

        if (options.onShow) {
            options.onShow(view);
        }
    },

    /**
     * Shows the dashboard section
     */
    dashboard: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new DashboardView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'dashboard');
        });
    },

    /**
     * Shows the contacts section
     */
    contacts: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new ContactsView();
            options.silent = true;
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show favorites
     */
    favorites: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'favorites'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    elephants: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'elephants'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show recent created individuals
     */
    recentIndividuals: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'recentIndividuals'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show recent viewed organizations
     */
    recentOrganizations: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'recentOrganizations'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show recently created individuals
     */
    recentCreatedIndividuals: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'recentCreatedIndividuals'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show recently created organizations
     */
    recentCreatedOrganizations: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'recentCreatedOrganizations'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show new group detail
     */
    groupNew: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'new',
                id: null,
                edit: true
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show group detail
     */
    groupDetail: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'groups'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, _.extend(options, {silent: true}), view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Show group detail
    */
   setViewGroupDetail: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'groups'
            }, options);

            if(options.model.get('element_type') === 'opportunities') {
                var view = new DealsView(options);
                options.silent = true;
                callback.call(controller, options, view);

                let itemId = 'deals';

                if (options.sectionId) {
                    itemId += `-section-${options.sectionId}`;
                }

                vent.trigger('appMenu:highlightItem', itemId);
            } else {

                var view = new ContactsView(options);

                callback.call(controller, options, view);
                vent.trigger('appMenu:highlightItem', 'groups');
            }
        });
    },

    /**
     * Shows the individuals list
     */
    individualList: function(options, callback, type) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: type || 'individuals'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, _.extend(options, {silent: true}), view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Shows an individual detail
     *
     * options:
     *  - id: The id or short_id of an individual to display
     *  - model: The model of an individual to display
     *  - context: The context to show ('individualList' | 'leadList')
     *  - contextView: The context view that will remain visible to the left
     *  - edit: A boolean indicating if the view should be opened in edit mode
     *  - organization: Useful for filling the organization of a new individual
     *  - leadSource: Useful for filling the value of a new lead being created
     *  - rightTab: a string indicating which sub-tab to open in right column
     *
     * The model option takes precedence to the id option. If neither are provided a creation form is displayed
     * The contextView option takes precedence to the context option. If neither are provided an individualList is
     *  displayed
     */
    individualDetail: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var detailView, model;

            model = options.model || new IndividualModel({id: options.id});
            if (options.organization) {
                model.set('organization', options.organization);
                model.set('organization_id', options.organization.id);
                model.set('organization_name', options.organization.get('name'));
            }
            if (options.leadSource) {
                model.set('source', options.leadSource);
            }

            if (options.preloadedFields) {
                for (const k in options.preloadedFields) {
                    model.set(k, options.preloadedFields[k]);
                }
            }

            detailView = new IndividualView({
                model: model,
                editing: options.edit,
                rightTab: options.rightTab,
                isLead: options.isLead,
                fromOpportunityModel: options.fromOpportunityModel,
                fromIndividualModel: options.fromIndividualModel,
                gotoSection: options.gotoSection,
                conversation_id: options.conversation_id
            });

            if (options.contextView) {
                 callback.call(controller, options, detailView);
            }
            else {
                // lead
                if (options.context === 'leadList') {
                    callback.call(controller, options, detailView, LeadListView);
                }
                // individual
                else {
                    options.contextView = new ContactsView(_.extend({ type: 'individuals'}, options));
                    options.replace = true;

                    callback.call(controller, options, detailView);
                }
            }

            if (options.replace || (!options.contextView && !options.replaceView)) {
                vent.trigger('appMenu:highlightItem', 'groups');
            }
        });
    },

    individualNew: function(options, callback) {
        options = _.extend({
            id: null,
            edit: true
        }, options);

        this.individualDetail(options, callback);
    },

    /**
     * Shows the organizations list
     */
    organizationList: function(options, callback, type) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: type || 'organizations'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Shows an organization detail
     *
     * options:
     *  - id: The id or short_id of an organization to display
     *  - model: The model of an organization to display
     *  - contextView: The context view that will remain visible to the left
     *  - edit: A boolean indicating if the view should be opened in edit mode
     *  - rightTab: a string indicating which sub-tab to open in right column
     *
     * The model option takes precedence to the id option
     */
    organizationDetail: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var detailView, model;
            var DetailView = OrganizationView;
            var Model = OrganizationModel;

            model = options.model || new Model({id: options.id});

            detailView = new DetailView({
                model: model,
                editing: options.edit,
                rightTab: options.rightTab
            });

            if (options.contextView) {
                 callback.call(controller, options, detailView);
            }
            else {
                options.contextView = new ContactsView(_.extend({ type: 'organizations' }, options));
                options.replace = true;

                callback.call(controller, options, detailView);
            }

            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    organizationNew: function(options, callback) {
        options = _.extend({
            id: null,
            edit: true
        }, options);

        this.organizationDetail(options, callback);
    },

    /**
     * Shows the social section
     */
    socialList: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new SocialFeedView();
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'social');
        });
    },

    /**
     * Shows the web section
     */
    web: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new WebView();
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'web');
        });
    },

    /**
     * Shows the portal admin section
     */
    portal: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new PortalAdminView();
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'portal');
        });
    },

    /**
     * Shows the deals list section, with the table and funnel
     */
    dealFunnel: function(options, callback) {
        if (AppConfig.getValue('deals.new_section_enabled', false, {sectionId: options.sectionId})) {
            this.deals(options, callback);
        } else {
            var controller = this;

            _.defer(function(){
                var view = new DealsView(options);
                callback.call(controller, _.extend(options, {silent: true}), view);

                let itemId = 'deals';

                if (options.sectionId) {
                    itemId += `-section-${options.sectionId}`;
                }

                vent.trigger('appMenu:highlightItem', itemId);
            });
        }
    },

    dealList: function(options, callback) {
        if (AppConfig.getValue('deals.new_section_enabled', false, {sectionId: options.sectionId})) {
            this.deals(options, callback);
        } else {
            var controller = this;

            _.defer(function() {
                options = _.extend({
                    type: 'deals'
                }, options);

                var view = new ContactsView(options);
                callback.call(controller, _.extend(options, {silent: true}), view);
                vent.trigger('appMenu:highlightItem', 'deals');
            });
        }
    },

    /**
     * Shows the deal detail
     *
     * options:
     *  - id: The id or short_id of a deal to display
     *  - model: The model of a deal to display
     *  - contextView: The context view that will remain visible to the left
     *  - edit: A boolean indicating if the view should be opened in edit mode
     *  - organization: Useful for filling the organization of a new deal
     *  - rightTab: a string indicating which sub-tab to open in right column
     *  - viewOptions: specify the way the window is shown
     *  - onViewCreation: this callback is called after view creation
     *
     * The model option takes precedence to the id option
     */
    dealDetail: function(options, callback) {
        if (AppConfig.getValue('deals.new_section_enabled', false, {sectionId: options.sectionId})) {
            let newOptions = {
                entityId: options.id,
                show_choices: options.choices || null
            };

            if (options.context) {
                let contextParts = options.context.split('/');
                contextParts.shift(); // remove 'deals'. We already know we are here

                if (contextParts.length === 1) {
                    newOptions.type = contextParts[0];
                } else {
                    newOptions.type = contextParts[0];
                    newOptions.id = contextParts[1];
                }
            }

            this.deals(newOptions, callback);
        } else {
            var controller = this;

            _.defer(function(){
                var detailView, model;
                var ListView = DealsView;
                var DetailView = OpportunityView;
                var Model = OpportunityModel;

                model = options.model || new Model({id: options.id});
                if (options.organization) {
                    model.set('organization', options.organization);
                    model.set('organization_id', options.organization.id);
                    model.set('organization_name', options.organization.get('name'));
                }

                if (options.section && model.isNew()) {
                    var condition = options.section.conditions[0];
                    model.set(`custom_field.${condition.custom_field_id}`, condition.value);
                }

                detailView = new DetailView(_.defaults({
                    model: model,
                    editing: options.edit,
                    contactModel: options.contactModel,
                    rightTab: options.rightTab,
                    viewOptions: options.viewOptions,
                    fromIndividualModel: options.fromIndividualModel
                }, options));

                if (options.onViewCreation) {
                    options.onViewCreation(detailView);
                }

                if (options.contextView && options.contextView.onDealViewOpened) {
                    options.contextView.onDealViewOpened(detailView);
                }

                var listViewOptions = {
                    sectionId: options.sectionId
                };

                callback.call(controller, options, detailView, ListView, listViewOptions);

                if (!options.contextView && !options.replaceView) {
                    if (options.sectionId) {
                        vent.trigger('appMenu:highlightItem', `deals-section-${options.sectionId}`);
                    } else {
                        vent.trigger('appMenu:highlightItem', 'deals');
                    }
                }
            });
        }
    },

    dealNew: function(options, callback) {
        options = _.extend({
            id: null,
            edit: true
        }, options);

        this.dealDetail(options, callback);
    },

    /**
     * Shows the list of forecasts section
     */
    forecastList: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new ForecastsListView();
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'forecasts');
        });
    },

    /**
     * Shows the forecast detail
     *
     * options:
     *  - id: The id or short_id of a forecast to display
     *  - model: The model of a forecast to display
     *  - contextView: The context view that will remain visible to the left
     *  - edit: A boolean indicating if the view should be opened in edit mode
     *
     * The model option takes precedence to the id option
     */
    forecastDetail: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var detailView, model;
            var ListView = ForecastsListView;
            var DetailView = ForecastView;
            var Model = ForecastModel;
            var Handlebars = _;
            var createErrorTemplate = forecastCreateErrorTemplate;

            function cont() {
                var doit = true;

                if (model.isNew()) {
                    // check if all deals have a properly conversion rate
                    var dealsInfo = model.haveDealsProperlyConversionRate(true);

                    if (!dealsInfo.valid) {
                        var message = {
                                action: 'create',
                                forecastsLength: 1,
                                deals: dealsInfo.info,
                                isAdmin: app.user.get('is_admin')
                            },
                            template = Handlebars.compile(createErrorTemplate),
                            mbContent = {
                                message: template(message),
                                icon: 'icon-blocked'
                            };

                        MessageBox.showOk(mbContent, options.contextView);
                        doit = false;
                    }
                }

                if (doit) {
                    detailView = new DetailView({
                        model: model,
                        editing: options.edit
                    });

                    callback.call(controller, options, detailView, ListView);
                }
            }

            model = options.model || new Model({id: options.id});

            // Populate a new forecast
            if (model.isNew()) {
                model.populateNew(cont);
            }
            else {
                cont();
            }

            vent.trigger('appMenu:highlightItem', 'forecasts');
        });
    },

    forecastNew: function(options, callback) {
        options = _.extend({
            id: null,
            edit: true
        }, options);

        this.forecastDetail(options, callback);
    },

    /**
     * Shows the tasks section
     */
    tasks: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new TasksView.mainView(_.extend(options, {silent: true}));
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'tasks');
        });
    },

    /**
     * Shows the activities section
     */
    activities: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new ActivitiesView(_.extend(options, {silent: true}));
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'activities');
        });
    },

    /**
     * Shows the content section
     */
    content: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new ContentView({
                id: options.id
            });
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'content');
        });
    },

    /**
     * Shows the texts section
     */
    texts: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new TextsView({
                filterId: options.filter_id
            });
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'texts');
        });
    },

    /**
     * Shows the messages section
     */
    messages: function(options, callback) {
        var controller = this;
        var groupId = null;

        if (options.group_id) {
            groupId = options.group_id;
        }

        _.defer(function(){
            var view = new SectionView({
                type: 'messages',
                initialGroupId: groupId
            });
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'messages');
        });
    },

    /**
     * Shows the campaigns section
     */
    campaigns: function(options, callback) {
        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5741')) { // new campaigns section
            var controller = this;

            _.defer(function(){
                var view = new SectionView({
                    type: 'campaigns',
                    initialGroupId: options.type || null,
                    initialSubGroupId: options.gid || null,
                    id: options.id || null,
                    contactEmailTemplating: options.contactEmailTemplating || null,
                    onCloseGoto: options.onCloseGoto || null
                });
                callback.call(controller, options, view);
                vent.trigger('appMenu:highlightItem', 'campaigns');
            });
        } else {
            var controller = this;

            _.defer(function(){
                var view = new CampaignsView({
                    type: options.type,
                    id: options.id,
                    groupId: options.gid,
                    filterId: options.filter_id,
                    new: options.new,
                    preview: options.preview,
                    populateFrom: options.populateFrom,
                    onCancelGoto: options.onCancelGoto
                });
                callback.call(controller, _.extend(options, {silent: true}), view);
                vent.trigger('appMenu:highlightItem', 'campaigns');
            });
        }
    },

    /**
     * Links to leads list
     */
    leads: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                type: 'leadIndividuals'
            }, options);

            var view = new ContactsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'groups');
        });
    },

    /**
     * Shows the automation section
     */
    automations: function(options, callback) {
        var controller = this;

        _.defer(function(){
            if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
                var view = new AutomationReactContainerView();
            }
            else {
                var view = new AutomationsView();
            }
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'automation');
        });
    },

    /**
     * Shows new automation
     */
    automationsNew: function(options, callback) {
        var controller = this;

        _.defer(function(){
            options = _.extend({
                id: false,
                edit: true,
                type: 'automation'
            }, options);

            var view = new AutomationsView(options);
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'automation');
        });
    },

    /**
     * Shows automation
     */
    automationsDetail: function(options, callback) {
        var controller = this;

        if (!_.contains(app.user.get('preferences').lab_flags, 'SAL-4026')) {
            var view = new AutomationReactContainerView({
                automationIdToLoad: options.id
            });
        } else {
            options = _.extend({
                id: false,
                type: 'automation'
            }, options);

            var view = new AutomationsView(options);
        }

        _.defer(function(){
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'automation');
        });
    },

    marketing: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new MarketingView();
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'marketing');
        });
    },

    appointments: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new SectionView(_.extend({
                type: 'appointments',
                initialGroupId: options.group_id || null,
                initialAppointmentId: options.appointment_id || null
            }, options));
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'appointments');
        });
    },

    reports: function(options, callback) {
        var controller = this;

        _.defer(function(){
            var view = new SectionView({
                type: 'reports'
            });
            callback.call(controller, options, view);
            vent.trigger('appMenu:highlightItem', 'reports');
        });
    },

    deals: function(options, callback) {
        var controller = this;

        _.defer(function() {
            var groupId = null;

            if (options.type) {
                if (options.id) {
                    groupId = `${options.type}/${options.id}`;
                } else {
                    groupId = options.type;
                }
            }

            var view = new SectionView({
                type: 'deals',
                entityId: options.entityId || null,
                sectionId: options.sectionId || null,
                initialGroupId: groupId
            });

            callback.call(controller, options, view);

            let itemId = 'deals';

            if (options.sectionId) {
                itemId += `-section-${options.sectionId}`;
            }

            vent.trigger('appMenu:highlightItem', itemId);
        });
    },

    /**
     * Close a view.
     *
     * options:
     *  - view: The view to be closed
     *
     * Right now we only support closing of the last view, so if the view is not the last one, it does nothing.
     */
    closeView: function(options) {
        var view, lastRegion;

        view = options.view;
        lastRegion = _.last(appContent.contentRegions);

        if (lastRegion.currentView !== view) {
            return;
        }

        appContent.pop({});
    },

    initialize: function() {
        var controller = this;

        this.listenTo(vent, 'logout', function () {
            controller.logout();
        });
        this.listenTo(vent, 'browserVersionError', function (version) { controller.browserVersionError(version); });
        this.listenTo(vent, 'login', function (cameFrom) { controller.login(cameFrom); });
        this.listenTo(vent, 'login:password_change', function (user, cameFrom) {
            controller.loginPasswordChange(false, user, cameFrom);
        });
        this.listenTo(vent, 'login:2fa_required', function(next) { controller.twoFactorAuth(next); });
        this.listenTo(vent, 'reset', function (token) {
            controller.loginPasswordChange(true, null, token);
        });
        this.listenTo(vent, 'forgotten', function () { controller.forgotten(); });
        this.listenTo(vent, 'error', function(sourceView, args) {
            var ev = new ErrorView({});
            appContent.replaceView({}, sourceView, ev, args);
        }, this);
        this.listenTo(vent, 'settings:show', function(id) {
            if ( !app.user.get('is_admin') ) {
                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'You do not have permissions to see this resource'
                }, this.options.appLayout);

                return;
            }

            _.defer(function(){
                app.showSettings(SettingsView, id);
            });
        });
        this.listenTo(vent, 'settings:hide', function () { app.hideSettings(); });
        this.listenTo(vent, 'userSettings:show', function(id) {
            _.defer(function(){
                app.showUserSettings(UserSettingsView, id);
            });
        });
        this.listenTo(vent, 'userSettings:hide', function () { app.hideUserSettings(); });
        this.listenTo(vent, 'quick:add:task', function(model, options) {
            app.showQuickAddTask(model, options);
        });
        this.listenTo(vent, 'quick:edit:task', function(model, options) {
            app.showQuickAddTask(model, options);
        });
        this.listenTo(vent, 'quick:add:note', function(options) {
            app.showQuickAddNote(options);
        });
        this.listenTo(vent, 'quick:create:message', function(individualId, phoneNumber) {
            app.showQuickCreateMessage(individualId, phoneNumber);
        });
        this.listenTo(vent, 'quick:add:appointment', function(options) {
            app.showQuickAddAppointment(null, options);
        });
        this.listenTo(vent, 'quick:edit:appointment', function(appointmentId) {
            app.showQuickAddAppointment(appointmentId);
        });
        this.listenTo(vent, 'quick:add:goto-releated-item', function(type, related_type, related_id) {
            app.hideQuickAdd();

            if (related_type) {
                switch(related_type) {
                    case 'opportunity':
                        controller.dealDetail({id: related_id, rightTab: type}, controller.show);
                        break;

                    case 'organization':
                        controller.organizationDetail({id: related_id, rightTab: type}, controller.show);
                        break;

                    case 'individual':
                        controller.individualDetail({id: related_id, rightTab: type}, controller.show);
                        break;
                }
            }
        });
        this.listenTo(vent, 'close:quickRegion', function() {
            app.hideQuickAdd();
        });
        this.listenTo(vent, 'file-preview:show', function(file) {
            app.showFilePreview(file);
        });
        this.listenTo(vent, 'file-preview:hide', function() {
            app.hideFilePreview();
        });
        this.listenTo(vent, 'alert:show', function(options) {
            app.showAlert(AlertView, options);
        });
        this.listenTo(vent, 'alert:hide', function (now) { app.hideAlert(now); });

        this.listenTo(vent, 'dashboard', function() {
            controller.dashboard({}, controller.show);
        });
        this.listenTo(vent, 'contacts', function() {
            controller.contacts({}, controller.show);
        });
        this.listenTo(vent, 'groups:favorites', function() {
            controller.favorites({}, controller.show);
        });
        this.listenTo(vent, 'groups:recents', function() {
            controller.recents({}, controller.show);
        });
        this.listenTo(vent, 'groups:detail', function(args, id, elementType) {
            controller.groupDetail({id: id, element_type: elementType}, controller.show);
        });
        this.listenTo(vent, 'groups:detail:new', function(args, id) {
            controller.groupNew({}, controller.show);
        });
        this.listenTo(vent, 'groups:opportunities', function(id) {
            controller.dealFunnel(_.extend({ type: 'groups' }, { id: id }), controller.show);
        });
        this.listenTo(vent, 'content:detail', function(args, id) {
            controller.content({
                id: id,
                type: 'library'
            }, controller.show);
        });
        this.listenTo(vent, 'individuals:list', function() {
            controller.individualList({}, controller.show);
        });
        this.listenTo(vent, 'social:feed', function() {
            controller.socialList({}, controller.show);
        });
        this.listenTo(vent, 'web:show', function() {
            controller.web({}, controller.show);
        });
        this.listenTo(vent, 'leads:list', function() {
            controller.leads({}, controller.show);
        });
        this.listenTo(vent, 'automation:list', function() {
            controller.automations({}, controller.show);
        });
        this.listenTo(vent, 'leads:detail:new', function() {
            controller.individualNew({ context: 'leadList', isLead: true }, controller.show);
        });
        this.listenTo(vent, 'opportunities:list', function() {
            controller.dealList({}, controller.show);
        });
        this.listenTo(vent, 'opportunities:funnel', function(params) {
            controller.dealFunnel({sectionId: params.eventParams}, controller.show);
        });
        this.listenTo(vent, 'forecasts:list', function() {
            controller.forecastList({}, controller.show);
        });
        this.listenTo(vent, 'forecasts:detail', function(id) {
            controller.forecastDetail({id: id}, controller.show);
        });
        this.listenTo(vent, 'tasks:show', function() {
            controller.tasks({}, controller.show);
        });
        this.listenTo(vent, 'activities:show', function() {
            controller.activities({}, controller.show);
        });
        this.listenTo(vent, 'content:show', function() {
            controller.content({}, controller.show);
        });
        this.listenTo(vent, 'texts:show', function() {
            controller.texts({}, controller.show);
        });
        this.listenTo(vent, 'messages:show', function() {
            controller.messages({}, controller.show);
        });
        this.listenTo(vent, 'campaigns:show', function() {
            controller.campaigns({}, controller.show);
        });
        this.listenTo(vent, 'campaigns:new', function(options) {
            controller.campaigns(_.extend({ new: true }, options), controller.show);
        });
        this.listenTo(vent, 'individuals:detail', function(args, id, rightTab) {
            controller.individualDetail({id: id, rightTab: rightTab}, controller.show);
        });
        this.listenTo(vent, 'individuals:detail:new', function(options) {
            controller.individualNew(options || {}, controller.show);

        });
        this.listenTo(vent, 'leads:detail', function(args, id) {
            controller.individualDetail({id: id}, controller.show);
        });
        this.listenTo(vent, 'organizations:detail', function(args, id, rightTab) {
            controller.organizationDetail({id: id, rightTab: rightTab}, controller.show);
        });
        this.listenTo(vent, 'organizations:detail:new', function(args, id) {
            controller.organizationNew({}, controller.show);
        });
        this.listenTo(vent, 'opportunities:detail', function(args, id, rightTab) {
            controller.dealDetail({id: id, rightTab: rightTab}, controller.show);
        });
        this.listenTo(vent, 'opportunities:detail:new', function(args) {
            controller.dealDetail({
                edit: true,
                organization: args.organization,
                contactModel: args.contactModel
            }, controller.show);
        });
        this.listenTo(vent, 'invalid_client:show', function() {
            controller.invalidClient();
        });
        this.listenTo(vent, 'marketing:show', function() {
            controller.marketing({}, controller.show);
        });
        this.listenTo(vent, 'appointments:show', function() {
            controller.appointments({}, controller.show);
        });
        this.listenTo(vent, 'reports:show', function() {
            controller.reports({}, controller.show);
        });
        this.listenTo(vent, 'portal:show', function() {
            controller.portal({}, controller.show);
        });
        this.listenTo(vent, 'quick:add:report', function(options) {
            app.showQuickAddReport(options);
        });
    },
    loginPasswordChange: function (wantsReset, user, cameFromOrToken) {
        var params = {
            wantsReset: wantsReset
        };

        if (wantsReset) {
            params.token = cameFromOrToken;
        }
        else {
            params.model = user;
            params.cameFrom = cameFromOrToken;
        }

        _.defer(function(){
            var passwordChangeView = new ForcedPasswordChangeView(params);
            app.appStacks.show(passwordChangeView);
            // .removeClass() not working! Supposed to remove ALL
            // classes. See SO issue:
            // http://stackoverflow.com/questions/14532327/odd-issue-
            //     with-jquery-removeclass-not-doing-anything
            app.appStacks.$el.removeAttr('class');
        });
    },
    browserVersionError: function (version) {
        _.defer(function(){
            var brView = new BrowserSupportView({
                    model: new Backbone.Model({browserVersion: version})
                });
            app.appStacks.show(brView);
            app.appStacks.$el.removeAttr('class');
        });
    },
    login: function (cameFrom) {
        _.defer(function(){
            var loginModel = new LoginModel(),
                loginView = new LoginView({
                    model: loginModel,
                    cameFrom: cameFrom
                });
            app.appStacks.show(loginView);
            // .removeClass() not working! Supposed to remove ALL
            // classes. See SO issue:
            // http://stackoverflow.com/questions/14532327/odd-issue-
            //     with-jquery-removeclass-not-doing-anything
            app.appStacks.$el.removeAttr('class');
        });
    },
    invalidClient: function() {
        _.defer(function(){
            var view = new InvalidClientView();
            app.appStacks.show(view);
        });
    },
    forgotten: function () {
        _.defer(function(){
            var forgottenView = new ForgottenView();
            app.appStacks.show(forgottenView);
            // .removeClass() not working! Supposed to remove ALL
            // classes. See SO issue:
            // http://stackoverflow.com/questions/14532327/odd-issue-
            //     with-jquery-removeclass-not-doing-anything
            app.appStacks.$el.removeAttr('class');
        });
    },
    logout: function(){
        api.logout(function() {
            appContent.clear({silent: false}); // Clear the stack on logout
            Backbone.history.stop();
            vent.trigger('login');
            // hard refresh to temporary fix for several problems
            window.location.reload(true);
        });
    },
    twoFactorAuth: function(next) {
        _.defer(function() {
            var twoFactorAuthView = new TwoFactorAuthView({
                next: next
            });
            app.appStacks.show(twoFactorAuthView);
            app.appStacks.$el.removeAttr('class');
        });
    }
});

export default AppController;

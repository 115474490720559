import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import htmlSanitizer from 'js/utils/html-sanitizer'

import vent from 'js/vent'
import campaignPreviewTemplate from 'app/content/campaigns/_components/campaign-preview/campaign-preview.handlebars'


export default Marionette.Layout.extend({
    className: 'campaign-preview-container',
    template: Handlebars.compile(campaignPreviewTemplate),
    templateHelpers: function () {
        return {
            isTextMessage: this.model.get('campaign_type') === 'message'
        };
    },
    ui: {
        preview: 'iframe',
        preview_message: '.message-content',
        preview_scroll_mask: '.scroll-dummy'
    },
    events: {
        'click .close': function() {
            this.trigger('close');
        }
    },
    onRender: function () {
        if (this.model.get('campaign_type') === 'message') {
            this.ui.preview_message.text(this.model.get('content'));
            this.ui.preview_message.removeClass('hide');
        } else {
            this.ui.preview.removeClass('hide');
            this.ui.preview.on('load', function () {
                this.setContent(this.model.get('content'));
            }.bind(this));
        }
    },
    onShow: function () {
        this.$el.parent().addClass('campaign-preview-modal');
        var self = this;
        // Link scrolling with mask
        if (this.ui.preview_scroll_mask) {
            this.ui.preview_scroll_mask.scroll(function () {
                self.ui.preview.contents().scrollTop($(this).scrollTop());
            });
        }

        // custom modal click closing, dirty model confirmation required for campaign edit view with whom region is shared
        this.$el.parent().prev('.modal-backdrop').on('click', function() {
            self.trigger('close');
        });
    },
    setContent: function (content) {
        var self = this;
        var fontFamily = this.$el.css('fontFamily');
        this.ui.preview.contents().find('html').html(htmlSanitizer.removeScripts(content)).css('fontFamily', fontFamily);
        this.ui.preview_scroll_mask.find('.scroll-dummy-inner')
            .css('height', this.ui.preview.contents().find('html')[0].scrollHeight);
        // Get new correct height after a while, when iframe is fully rendered.
        _.delay(function() {
            self.ui.preview_scroll_mask.find('.scroll-dummy-inner')
                .css('height', self.ui.preview.contents().find('html')[0].scrollHeight);
        }, 500);
    },
    getUrl: function() {
        return 'preview/' + this.model.get('id');
    },
    onClose: function() {
        _.defer(function() {
            vent.trigger('AppContent:contentChange');
        });
    }
});

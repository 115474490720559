import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import dateFormat from 'js/utils/date-format'
import AttachmentsView from 'js/views/activity/attachments-viewer'
import messageViewerTemplate from 'templates/activity/message-viewer.handlebars'


export default Marionette.Layout.extend({
    template: Handlebars.compile(messageViewerTemplate),
    className: 'message-viewer scrolling-modal-inner',
    events: {
        'click .close': function(ev) {
            ev.preventDefault();
            this.trigger('message-viewer:close');
        },
        'resize': 'scrollbar'
    },
    regions: {
        attachmentsRegion: '.attachments-container'
    },
    ui: {
        header: '.modal-header',
        display_from: '.display-from',
        display_date: '.display-date',
        message_body: '.message-body',
        scroll: '.content-container > .content'
    },
    initialize: function(options) {
        this.options = options;
    },
    onShow: function() {
        this.$el.parent().addClass('message-viewer-modal scrolling-modal');

        var date = dateFormat.shortFormatWithYear(new Date(this.options.model.get('created')));
        var params = this.model.get('params') || {};
        var author = params.from_user || this.model.get('individual_name');

        this.ui.display_from.text(author);
        this.ui.display_date.text(date);
        this.ui.message_body.text(this.options.model.get('note'));

        this.prepareAttachments();

        // Update scrollbar
        this.scrollbar();
        // Bind scrolling for header and footer shadows
        this.ui.scroll.scroll(this.scrollEvents.bind(this));

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);

        $(window).on('resize', this.resizeCallback);
    },
    prepareAttachments : function() {
        var params = this.options.model.get('params');

        if (params && params.attachments_ids) {
            var collection = new Backbone.Collection();

            for (var i = 0; i < params.attachments_ids.length; ++i) {
                collection.add(new Backbone.Model({
                    id: params.attachments_ids[i],
                    name: params.attachments_names[i]
                }));
            }

            var attachView = new AttachmentsView({ collection: collection });
            var view = this;

            this.listenTo(attachView, 'attachments:change-visualization', function() {
                view.scrollbar();
            });

            this.attachmentsRegion.show(attachView);
        }
    },
    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },
    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.floor(this.$el.height() - this.ui.header.height()),
            contentHeight = Math.ceil(container.find('.content-inner:first').outerHeight(true)),
            height = Math.min(availableHeight, contentHeight);

        // NOTE: Height needs to be hard-coded in CSS until we can update the lists without just rerendering the
        // whole view - which causes flickering before list is given height below...
        container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),
    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }
});
import $ from 'jquery';
import React from 'react';
import Backbone from 'backbone'

import DemographicMap from 'js/react_views/demographic-map/demographic-map'

export default class GroupDemographicMap extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onStartResizing() {
    }

    onEndResizing() {
    }

    show() {
        this.visible = true;

        this.props.setBarsWidth('100%');
    }

    hide() {
        this.visible = false;
    }

    handleShowEntity(entityId) {
    }

    render() {
        return (
            <div>DEMOGRAPHIC MAP</div>
            // <DemographicMap
            //     group={this.props.group}
            //     onShowEntity={this.handleShowEntity.bind(this)}
            // />
        );
    }
}

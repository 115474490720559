import React from 'react';
import _ from 'underscore';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import classnames from 'classnames';

import app from 'js/app'
import guid from 'js/utils/guid'
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import {
    TextField,
    TagsField,
    FunnelsField,
    PhotoField,
    CommunicationList,
    CheckboxField,
    SourceDropdownField,
    UserDropdownField,
    CustomFieldRenderer,
    CustomFieldsSection,
    OrganizationDropdownField,
    SocialField,
    IncrementalNumberField,
} from 'js/react_views/common_components/common';
import commonStyle from 'js/react_views/common_components/common.css';

const GroupDragHandle = SortableHandle(() => <i className={`icon-drag ${commonStyle.customFieldGroupHandle}`}/>);

const GroupSortableElement = SortableElement((props) =>
    <div className={classnames({[commonStyle.customFieldGroupSortableElement]: true, [commonStyle.hidden]: props.group.hidden})}>
        <Group
            key={props.group.id}
            group={props.group}
            groupsComponents={props.groupsComponents}
            elementRenderer={props.elementRenderer}
            cfRenderer={props.cfRenderer}
        />
    </div>
);

const GroupsList = SortableContainer((props) => {
    return (
        <div className={commonStyle.customFieldsList}>
            {props.groups.map((group, index) => (
                <GroupSortableElement
                    key={`group_${index}`}
                    index={index}
                    group={group}
                    groupsComponents={props.groupsComponents}
                    elementRenderer={props.elementRenderer}
                    cfRenderer={props.cfRenderer}
                />
            ))}
        </div>
    );
});

class Group extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.group.state !== 'expanded'
        };

        this.components = {};
        this.customFieldComponents = {};
    }

    getValues() {
        const values = {
            system: {},
            cf: {}
        };

        for (const components of [this.components, this.customFieldComponents]) {
            for (const key in components) {
                const component = components[key];

                if (component.hasChanged()) {
                    values[components === this.components ? 'system' : 'cf'][key] = component.getValue();
                }
            }
        }

        return values;
    }

    getFirstInvalidField() {
        for (const components of [this.components, this.customFieldComponents]) {
            for (const key in components) {
                const component = components[key];

                if (component.props.error) {
                    return component;
                }
            }
        }

        return null;
    }

    toggle() {
        const newState = this.state.collapsed ? 'expanded' : 'collapsed';
        let preferences = app.user.get('preferences') || {};

        preferences.flexGroups = preferences.flexGroups || {};
        preferences.flexGroups[this.props.group.id] = _.extend({}, preferences.flexGroups[this.props.group.id], { state: newState });
        $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

        this.setState({
            collapsed: newState !== 'expanded'
        });
    }

    onEntityRelatedDataChange(entityRelatedData) {
        for (const components of [this.components, this.customFieldComponents]) {
            for (const key in components) {
                const component = components[key];

                if (component.onEntityRelatedDataChange) {
                    component.onEntityRelatedDataChange(entityRelatedData);
                }
            }
        }
    }

    render() {
        this.props.groupsComponents.push(this);

        const toggleClasses = classnames({
            'icon-caret-right': this.state.collapsed,
            'icon-caret-down': !this.state.collapsed,
            [commonStyle.customFieldGroupToggle]: true
        });

        const groupClasses = classnames({
            [commonStyle.customFieldGroup]: true,
            [commonStyle.customFieldGroupCollapsed]: this.state.collapsed
        });

        const containerClasses = classnames({
            [commonStyle.customFieldGroupContainer]: true,
            [commonStyle.customFieldGroupContainerCollapsed]: this.state.collapsed
        });

        let fields = [];
        let cfRenderer = this.props.cfRenderer;
        let currentOnRefCreated = cfRenderer.options.onRefCreated;
        const self = this;

        cfRenderer.options.onRefCreated = function(item, el) {
            self.customFieldComponents[item.id] = el;
        };

        for (const field of this.props.group.elements) {
            fields.push(this.props.elementRenderer(field, cfRenderer, function(type, elemId, el) {
                self.components[elemId] = el;
            }));
        }

        cfRenderer.options.onRefCreated = currentOnRefCreated;

        if (fields.length === 0) {
            return null;
        }

        return (
            <div className={groupClasses}>
                <div className={commonStyle.customFieldGroupHeader}>
                    <i className={toggleClasses} onClick={this.toggle.bind(this)} />
                    {this.props.group.name}
                    <GroupDragHandle/>
                </div>
                <div className={containerClasses}>
                    {fields}
                </div>
            </div>
        )
    }
}

class GroupsSection extends React.Component {
    constructor(props) {
        super(props);

        const preferences = app.user.get('preferences') || {};
        const flexGroupsOrder = preferences.individualFlexGroupsOrder;

        let groups = [];

        // groups order
        if (flexGroupsOrder) {
            for (const groupId of flexGroupsOrder) {
                const group = props.groups.find(g => g.id === groupId);

                if (group) {
                    groups.push(group);
                }
            }

            // groups without ordering are added at the end of the array
            if (groups.length !== props.groups) {
                for (const group of props.groups) {
                    if (!groups.find(g => g.id === group.id)) {
                        groups.push(group);
                    }
                }
            }
        } else {
            groups = props.groups;
        }

        let elementsMap = {};

        for (const group of groups) {
            for (const element of group.elements) {
                if (element.type === 'customField') {
                    elementsMap[`cf.${element.cfInfo.id}`] = element;
                } else {
                    elementsMap[`system.${element.id}`] = element;
                }
            }
        }

        // groups state
        const flexGroups = preferences.flexGroups || {};

        for (let group of groups) {
            const groupInfo = flexGroups[group.id] || {};

            group.state = groupInfo.state || 'expanded';
            group.hidden = groupInfo.hidden || false;

            if (group.visible_if) {
                const element = elementsMap[group.visible_if.field_id];

                if (element) {
                    if (element.type === 'customField') {
                        if ('value_id' in element.cfInfo) {
                            group.hidden = element.cfInfo.value_id !== group.visible_if.value;
                        } else {
                            group.hidden = element.cfInfo.value !== group.visible_if.value;
                        }
                    } else {
                        group.hidden = this.props.modelData[element.id] !== group.visible_if.value;
                    }
                }
            }
        }

        this.state = {
            groups: groups,
            groupsComponents: []
        };
    }

    getValues() {
        var values = {
            system: {},
            cf: {}
        };

        for (const group of this.state.groupsComponents) {
            if (!group.props.group.hidden) {
                const groupValues = group.getValues();
                values.system = _.extend(values.system, groupValues.system);
                values.cf = _.extend(values.cf, groupValues.cf);
            }
        }

        return values;
    }

    getFirstInvalidField() {
        for (const group of this.state.groupsComponents) {
            const field = group.getFirstInvalidField();

            if (field) {
                return {
                    field: field,
                    group: group
                };
            }
        }

        return null;
    }

    onValueChange(fieldId, fieldValue) {
        let updateList = false;

        for (let group of this.state.groups) {
            if (group.visible_if) {
                let isHidden = fieldValue !== group.visible_if.value;

                if (isHidden !== group.hidden) {
                    group.hidden = isHidden;
                    updateList = true;
                }
            }
        }

        if (updateList) {
            this.setState({ groups: this.state.groups });
        }
    }

    onEntityRelatedDataChange(entityRelatedData) {
        for (const group of this.state.groupsComponents) {
            group.onEntityRelatedDataChange(entityRelatedData);
        }
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const groups = arrayMove(prevState.groups, oldIndex, newIndex);
            const preferences = app.user.get('preferences') || {};

            preferences.individualFlexGroupsOrder = groups.map(g => g.id)
            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

            return {
                groups: groups,
                groupsComponents: []
            };
        });
    }

    render() {
        return (
            <section className={commonStyle.customFieldsSection}>
                <GroupsList
                    ref={(el) => this.groupsList = el}
                    groups={this.state.groups}
                    groupsComponents={this.state.groupsComponents}
                    useDragHandle={true}
                    lockAxis={"y"}
                    elementRenderer={this.props.elementRenderer}
                    cfRenderer={this.props.cfRenderer}
                    entityRelatedData={this.entityRelatedData}
                    onSortEnd={this.onSortEnd.bind(this)}
                />
            </section>
        );
    }
}


export default class {
    constructor(options) {
        this.options = options;
        this.entityRelatedData = this.options.entityRelatedData || {};
    }

    onCustomFieldValueChange(customFieldId, customFieldValue) {
        if (this.groupsSection) {
            this.groupsSection.onValueChange(customFieldId, customFieldValue);
        }

        if (this.options.onValueChange) {
            this.options.onValueChange(customFieldId, customFieldValue);
        }
    }

    onCustomFieldValuePopulate(customFieldId, populateMapping) {
        let groupsComponents = [];

        if (this.customFieldsSection && this.customFieldsSection.customFieldsList) {
            groupsComponents = this.customFieldsSection.customFieldsList.props.groups || [];
        } else if (this.groupsSection && this.groupsSection.groupsList) {
            groupsComponents = this.groupsSection.groupsList.props.groupsComponents || [];
        }

        for (const group of groupsComponents) {
            const components = _.extend(group.components || {}, group.customFieldComponents || {});
            for (const k in components) {
                if (populateMapping[k] && components[k].setValue) {
                    components[k].setValue(populateMapping[k]);
                }
            }
        }
    }

    onEntityRelatedDataChange(field, value) {
        this.entityRelatedData[field] = value;

        if (this.groupsSection) {
            this.groupsSection.onEntityRelatedDataChange(this.entityRelatedData);
        } else if (this.customFieldsSection) {
            this.customFieldsSection.onEntityRelatedDataChange(this.entityRelatedData);
        }
    }

    render() {
        this.blockCounter = 0;

        const self = this;
        const cfRenderer = new CustomFieldRenderer({
            entityRelatedData: this.options.entityRelatedData,
            invalidFieldErrors: this.options.invalidFieldErrors,
            onValuePopulate: this.onCustomFieldValuePopulate.bind(this),
            onValueChange: this.onCustomFieldValueChange.bind(this),
            onRefCreated: function(item, el) {
                self.options.onRefCreated('cf', item.id, el);
            }
        });

        return this.renderLayout(this.options.layout, cfRenderer, function(type, itemId, el) {
            if (type === 'groupsSection') {
                self.groupsSection = el;
            } else if (type === 'customFieldsSection') {
                self.customFieldsSection = el;
            }

            self.options.onRefCreated(type, itemId, el);
        });
    }

    renderLayout(layout, cfRenderer, onRefCreated) {
        const fields = layout.map(element => {
            switch(element.type) {
                case 'groups_section':
                    if (this.options.customFieldGroups) {
                        return (
                            <GroupsSection
                                ref={(el) => onRefCreated('groupsSection', null, el)}
                                key='groups-section'
                                groups={element.elements}
                                modelData={this.options.modelData}
                                customFieldGroups={this.options.customFieldGroups}
                                elementRenderer={this.renderElement.bind(this)}
                                cfRenderer={cfRenderer}
                            />
                        );
                    }

                    return null;

                case 'block':
                    return this.renderBlock(element, cfRenderer, onRefCreated);

                default:
                    return this.renderElement(element, cfRenderer, onRefCreated);
            }
        });

        return fields;
    }

    renderBlock(block, cfRenderer, onRefCreated) {
        const fields = this.renderLayout(block.elements, cfRenderer, onRefCreated);

        return (
            <div
                style={block.style}
                key={`block_${this.blockCounter++}`}
            >
                {fields}
            </div>
        );
    }

    getProperFunnelData(funnels) {
        if (funnels && Array.isArray(funnels[0])) {
            funnels = funnels[0]
        }
        return funnels
    }

    renderElement(element, cfRenderer, onRefCreated) {
        switch(element.type) {
            case 'photo':
                return (
                    <PhotoField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.options.modelData[element.id] || ''}
                        placeholder={element.placeholder || ''}
                    />
                );

            case 'text':
                return (
                    <TextField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.options.modelData[element.id] || ''}
                        placeholder={element.placeholder || ''}
                        capitalizeValue={element.capitalizeValue}
                        required={element.id === 'last_name'}
                        error={this.options.invalidFieldErrors[element.id]}
                    />
                );

            case 'paragraph':
                return (
                    <TextField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        isParagraph='true'
                        value={this.options.modelData[element.id] || ''}
                        placeholder={element.placeholder || ''}
                    />
                );

            case 'social': {
                return (
                    <SocialField
                        ref={(el) => onRefCreated('social', element.id, el)}
                        key={element.id}
                        label={element.label}
                        socialId={element.id}
                        item={this.options.modelData.communication.find(i => i.medium === 'social' && i.name === element.id)}
                        placeholder={element.placeholder}
                    />
                );
            }

            case 'organization':
                return (
                    <OrganizationDropdownField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={element.value}
                        allowClear={true}
                        createIfNotExist={true}
                    />
                );

            case 'tags':
                return (
                    <TagsField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.options.modelData[element.id] || []}
                        placeholder={element.placeholder || ''}
                        forceValueChange={this.options.preloadedFields.tags}
                    />
                );

            case 'funnels':
                return AppConfig.getValue('individuals.enable_funnel_field', false) && (
                    <FunnelsField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.getProperFunnelData(this.options.modelData[element.id]) || app.user.getIndividualPreloadedFunnels() || []}
                        placeholder={element.placeholder || ''}
                        forceValueChange={this.options.preloadedFields.funnels}
                    />
                );

            case 'checkbox':
                return (
                    <CheckboxField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={element.invertValue ? !this.options.modelData[element.id] : this.options.modelData[element.id]}
                    />
                );

            case 'source': {
                // if we are editing an individual, the source field should be disabled if it already has a value
                const disabled = AppConfig.getValue('individuals.edit.source.disabled_with_value', false) && !!this.options.modelData[element.id];

                return (
                    <SourceDropdownField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        value={this.options.modelData[element.id]}
                        allowClear={!disabled}
                        disabled={disabled}
                        error={this.options.invalidFieldErrors[element.id]}
                    />
                );
            }

            case 'user':
                return (
                    <UserDropdownField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.options.modelData[element.id]}
                    />
                );

            case 'communicationList':
                const isEmail = element.medium === 'email';

                return (
                    <CommunicationList
                        ref={(el) => onRefCreated(`${element.medium}CommunicationList`, null, el)}
                        key={element.id}
                        medium={element.medium}
                        items={this.options.modelData.communication.filter(i => i.medium === element.medium)}
                        checkForDuplicates={isEmail}
                        checkEmailDomain={isEmail}
                        entityType='individuals'
                        error={this.options.invalidFieldErrors[element.id]}
                    />
                );

            case 'locations':
                return (
                    <CommunicationList
                        ref={(el) => onRefCreated('locationsList', null, el)}
                        key={element.id}
                        medium='location'
                        items={this.options.modelData.locations}
                    />
                );

            case 'customField':
                return cfRenderer.renderCustomField(element.cfInfo);

            case 'customFieldGroups':
                if (this.options.customFieldGroups) {
                    return (
                        <CustomFieldsSection
                            ref={(el) => onRefCreated('customFieldsSection', null, el)}
                            key={element.id}
                            processedCustomFields={this.options.customFieldGroups}
                            invalidFieldErrors={this.options.invalidFieldErrors}
                            ignoreCustomFields={this.options.outOfGroupCustomFields}
                            onValuePopulate={this.onCustomFieldValuePopulate.bind(this)}
                        />
                    );
                }

                return null;

            case 'vspace':
                return (
                    <div
                        key={`${element.id}_${guid()}`}
                        style={{height: element.value}}
                    />
                );

            case 'incrementalNumber':
                return (
                    <IncrementalNumberField
                        ref={(el) => onRefCreated('system', element.id, el)}
                        key={element.id}
                        label={element.label}
                        value={this.options.modelData[element.id] || ''}
                        //placeholder={element.placeholder || ''}
                        capitalizeValue={element.capitalizeValue}
                        error={this.options.invalidFieldErrors[element.id]}
                    />
                );
        }

        return null;
    }
}
import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import vent from 'js/vent'
import MessageBox from 'js/views/message_box'
import ActivityCreator from 'js/utils/activity_creator'
import ActivityFilterView from 'js/views/activity/filter'
import ActivityCollection from 'js/collections/activity'
import LoadingView from 'js/views/loading'
import dateFormat from 'js/utils/date-format'
import linkify from 'js/utils/linkify'
import htmlSanitizer from 'js/utils/html-sanitizer'
import TaskModel from 'js/models/task'
import template from 'templates/activity/feed.handlebars'
import autoActivityItemTemplate from 'templates/activity/auto-item.handlebars'
import listFooterTemplate from 'templates/base/list_footer.handlebars'
import AppConfig from 'app/app-config'
import ModalRegion from 'js/views/base/modal-region.js'
import individualInactiveModal from 'js/views/custom_code/individual_inactive_reasons_modal'
import TextManager from "../../../app/text-manager";

var FooterView, ActivityFeedView, FullFeed;

var emptyCollectionView = Marionette.ItemView.extend({
    template: Handlebars.compile("<div class='no-activity'>No activity for current selection</div>")
});

ActivityFeedView = Marionette.CollectionView.extend({
    tagName: 'tbody',
    emptyView: emptyCollectionView,
    getItemView: function(item) {
        return ActivityCreator.getActivityItem(item);
    },
    itemViewOptions: function() {
        return { parentModel: this.model };
    },
    initialize: function() {
        this.listenTo(this, 'itemview:deleteItem', this.deleteItem);
        this.listenTo(this, 'itemview:showTarget', this.showTarget);
        this.listenTo(this, 'itemview:togglePin', this.togglePin);
    },
    togglePin: function(childView) {
        var model = childView.model;

        model.save({
            activity_type: model.get('activity_type'),
            pinned: !model.get('pinned')
        }, {
            patch: true,
            success: function() {
                childView.updatePinView();
            }
        });
    },
    deleteItem: function(childView) {
        childView.model.destroy({
            wait: true,
            success: function() {
                vent.trigger('activity:delete');
            }
        });
    },
    appendHtml: function(collectionView, itemView, index){
        if ( index === 0 ) {
            collectionView.$el.prepend(itemView.el);
        }
        else {
            collectionView.$el.find('tr:nth-child(' + index + ')').after(itemView.el);
        }
    },
    showTarget: function(child, model) {
        this.trigger('showTarget', child, model);
    }
});

FooterView = Marionette.ItemView.extend({
    tagName: 'div',
    template: Handlebars.compile(listFooterTemplate),
    templateHelpers: function() {
        return {
            previous: this.options.previous,
            next: this.options.next,
            collectionStart: this.options.collectionStart,
            collectionEnd: this.options.collectionEnd,
            collectionTotal: this.options.collectionTotal
        };
    }
});

FullFeed = Marionette.Layout.extend({
    className: 'content-container',
    template: Handlebars.compile(template),
    ui: {
        addNoteContainer: '.add-note-container:first',
        addNoteField: '#field-add-note',
        addNoteFieldPlaceholder: '#field-add-note-placeholder',
        pastInput: '.past-input:first',
        dueDateInput: '.due-date-input:first',
        setPast: '.set-past:first',
        setDueDate: '.set-due-date:first',
        dueDateTool: '.due-date-tool:first',
        tagsTool: '.tags-tool',
        tagsInput: '.tags-input',
        funnelsTool: '.funnels-tool',
        funnelsInput: '.funnels-input',
    },
    events: {
        'click li.previous a': 'showPreviousPage',
        'click li.next a': 'showNextPage',

        'focus .field-add-note:first': function(ev) {
            var field = $(ev.currentTarget),
                container = field.parent().parent();

            container.addClass('focus');
        },
        'blur .field-add-note:first': function(ev) {
            var field = $(ev.currentTarget),
                container = field.parent().parent();

            container.removeClass('focus');
        },
        'paste .field-add-note': function(ev) {
            ev.stopPropagation();

            var self = this;

            _.defer(function() {
                self.showTiny(ev);
            });
        },
        'keydown .field-add-note:first': 'onAddNoteKeydown',
        'keyup .field-add-note:first': 'showTiny',
        'change .past-input:first': function(ev) {
            app.dirtyModelHandler.add(this.model.cid + '-note');

            var date = $(ev.currentTarget).val();
            this.pastDate = date;

            if (!date) {
                this.ui.addNoteContainer.removeClass('past-set');
            }
            else {
                this.ui.addNoteContainer.addClass('past-set');
                this.ui.setPast.focus();
            }

            this.updatePastTooltip();
        },
        'change .due-date-input:first': function(ev) {
            app.dirtyModelHandler.add(this.model.cid + '-note');

            var date = $(ev.currentTarget).val();

            if (!date) {
                this.ui.addNoteContainer.removeClass('due-date-set');
            }
            else {
                this.ui.addNoteContainer.addClass('due-date-set');
                this.ui.setDueDate.focus();
            }

            this.ui.setDueDate
                .tooltip('hide')
                .attr('data-original-title', 'Due ' + (date || 'date'))
                .tooltip('fixTitle');
        },
        'click .set-past:first': function(ev) {
            if (this.ui.addNoteContainer.hasClass('past-set')) {
                this.removeDate(ev, this.ui.pastInput);
            } else {
                this.toggleDatePicker(ev, this.ui.pastInput);
            }
        },
        'click .set-due-date:first': function(ev) {
            if (this.ui.addNoteContainer.hasClass('due-date-set')) {
                this.removeDate(ev, this.ui.dueDateInput);
                this.ui.setDueDate
                    .tooltip('hide')
                    .attr('data-original-title', 'Due date')
                    .tooltip('fixTitle');
            } else {
                this.toggleDatePicker(ev, this.ui.dueDateInput);
            }
        },
        'keydown .add-note-container:first .note-tool': function(ev) {
            this.onDateKeydown(ev, $(ev.currentTarget).closest('.tool').find('.date-input'));
        },
        'click .submit:first': 'postNote',
        'click .tab': function(ev) {
            this.$el.find('.tab').removeClass('active');
            $(ev.target).addClass('active');
            if (this.ui.addNoteField.is(':visible')) {
                this.ui.addNoteField.focus();
            }
            else {
                this.tinyEditor.focus();
            }
            this.ui.addNoteContainer.addClass('focus');
        },
        'click .note-tab': function() {
            this.ui.dueDateTool.show();
            this.noteActivityType = 'note';
            this.updatePastTooltip();
        },
        'click .call-tab': function() {
            this.ui.dueDateTool.hide();
            this.noteActivityType = 'call';
            this.updatePastTooltip();
        }
    },
    regions: {
        listRegion: '.notes-list',
        listSorterRegion: '.list-sorter-container',
        footerRegion: 'footer.pager',
        individualInactiveReasons: {
            selector: '.individual-inactive-reasons', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    initialize: function() {
        this.collectionPage = 1;
        this.prevOptions = {};
        this.noteActivityType = 'note';
        this.userIndividualPreloadedTags = app.user.getIndividualPreloadedTags();
        this.userIndividualPreloadedFunnels = app.user.getIndividualPreloadedFunnels();
    },
    showTarget: function(child, model) {
        if (!model || model.get('target_is_parent') === true) {
            return;
        }

        if (model.get('target_type') === 'individuals') {
            this.options.parent.trigger('replace-view:individual:show', {
                id: model.get('individual_id')
            });
        }
        else if (model.get('target_type') === 'organizations') {
            this.options.parent.trigger('replace-view:organization:show', {
                id: model.get('organization_id')
            });
        }
        else if (model.get('target_type') === 'opportunities') {
            this.options.parent.trigger('replace-view:deal:show', {
                id: model.get('opportunity_id')
            });
        }
    },
    onRender: function() {
        var view = this;

        this.listSorterRegion.show(new ActivityFilterView({
            parent: this,
            model: this.model,
            type: this.model.get('type')
        }));
        this.listenTo(this.listSorterRegion.currentView, 'filter', function(options) {
            this.collectionPage = 1;
            this.fetchActivity(options);
        });

        this.ui.pastInput.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            maxDate: 0,
            dateFormat: 'M dd, yy' // TODO: i18n
        });

        this.ui.dueDateInput.datepicker({
            numberOfMonths: 2,
            showButtonPanel: true,
            showAnim: 'fadeIn',
            dateFormat: 'M dd, yy' // TODO: i18n
        });

        _.defer(function() {
            view.tagsSelect = new backboneSelect2.TagView({
                view: view,
                $el: view.ui.tagsInput,
                id: 'id',
                text: 'name',
                url: '/tags',
                search: true,
                options: {
                    placeholder: 'Add Tag',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return 'Type to add a tag';
                    },
                    tokenSeparators: [',']
                }
            });
            view.tagsSelect.setValue(view.userIndividualPreloadedTags);

            view.funnelsSelect = new backboneSelect2.TagView({
                view: view,
                $el: view.ui.funnelsInput,
                id: 'id',
                text: 'name',
                url: '/funnels',
                search: true,
                options: {
                    placeholder: `Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`,
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'tag-select-popover popover select2-drop-wider',
                    multiple: true,
                    formatNoMatches: function() {
                        return `Type to add a ${TextManager.getText('ID_FUNNEL')}`;
                    },
                    tokenSeparators: [',']
                }
            });
            view.funnelsSelect.setValue(view.userIndividualPreloadedFunnels);
        });

        this.listenTo(vent, 'update:activity', function() {
            // Backbone Relational is hiding organizaton_id, but it doesn't know the
            // know the organization so its setting it to null, which means I can't get the
            // organization_id to check if the updated model is related or not.
            //if (is_related(model, view.model)) {
            view.fetchActivity();
            //}
        });

        this.$el.find('[data-toggle=tooltip]').tooltip();

        _.defer(function() {
            if (view.isClosed) {
                return;
            }
            //view.initTinyMCE(view);
        });
    },
    initTinyMCE: function(view) {
        view.ui.addNoteField.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor',
            statusbar: true,
            resize: true,
            relative_urls:false,
            remove_script_host:false,
            height: 0,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            oninit: function () {
                view.tinyEditor = this.activeEditor;
                view.tinyContainer = view.$el.find('.tox-tinymce:first');
                view.tinyContainer.css('height', '190px');
                view.ui.addNoteField.css({
                    'height': '32px',
                    'min-height': '32px',
                    'padding': '6px 10px 6px'
                });

                view.$el.find('.tox-tinymce').css('border-width', 0);
                view.$el.find('.tox-statusbar__text-container').css({
                    height: '0px',
                    visibility: 'hidden'
                });

                this.activeEditor.on('keyup', function (ev) {
                    if (view.tinyEditor.getContent().length === 0) {
                        view.ui.addNoteContainer.removeClass('has-value');
                        view.ui.addNoteField[0].value = '';
                        view.ui.addNoteField.show().focus();
                        view.ui.tagsTool.addClass('hide');
                        view.ui.funnelsTool.addClass('hide');
                        view.tinyContainer.hide();
                    }
                });

                this.activeEditor.on('focus', function () {
                    view.ui.addNoteContainer.addClass('focus');
                });

                this.activeEditor.on('blur', function () {
                    view.ui.addNoteContainer.removeClass('focus');
                });

                this.activeEditor.on('paste', function () {
                    _.defer(function() {
                        view.tinyEditor.setContent(htmlSanitizer.sanitize(view.tinyEditor.getContent(), true));
                    });
                });

                view.ui.addNoteFieldPlaceholder.hide();
                view.ui.addNoteField.show();
                view.ui.tagsTool.addClass('hide');
                view.ui.funnelsTool.addClass('hide');
                view.tinyContainer.hide();

                // safari has bug with scrolling when scrollable iframe is inside scrollable container
                // scrolling doesn't work right with trackpad
                // we remove scrolling while mouse over iframe
                if (app.safari) {
                    var frame = view.$el.find('iframe:first');
                    frame.mouseenter(function() {
                        view.$el.find('.content').addClass('no-scroll');
                    });
                    frame.mouseleave(function() {
                        view.$el.find('.content').removeClass('no-scroll');
                    });
                }
            },
            setup: function(ed) {
                // keydown should be in setup, otherwise it is called after carriage return
                ed.on('keydown', function (ev) {
                    if (ev.keyCode === 13 && ev.shiftKey === true) {
                        ev.preventDefault(); // Prevent creating a line-break on enter keydown
                        view.postNote();
                    }
                });

                ed.on('init', function() {
                    this.getDoc().body.style.fontSize = '14px';
                    this.getDoc().body.style.color = '#666666';
                    this.getDoc().body.style['font-family'] = '"proxima-nova","Helvetica","Arial","sans-serif"';
                });
            }
        });
    },
    updatePastTooltip: function() {
        var type = this.noteActivityType === 'call' ? 'Call' : 'Activity';
        this.ui.setPast
            .tooltip('hide')
            .attr('data-original-title', type +' date' + (this.pastDate ? ': ' + this.pastDate : ' (past)'))
            .tooltip('fixTitle');
    },
    onShow: function() {
        this.$el.nanoScroller();
    },
    showTiny: function(ev) {
        if (ev.target.value.trim().length > 0) {
            var value = ev.target.value.replace('\n', '<br>');
            this.ui.addNoteContainer.addClass('has-value');
            this.ui.addNoteField.hide();
            this.ui.tagsTool.removeClass('hide');
            this.ui.funnelsTool.removeClass('hide');
            this.tinyContainer.show();
            this.tinyEditor.execCommand('mceInsertContent', false, value);
            ev.target.value = '';
            this.$el.nanoScroller();
        }
    },
    onAddNoteKeydown: function() {
        app.dirtyModelHandler.add(this.model.cid + '-note');
    },
    toggleDatePicker: function(ev, input) {
        var visible = input.datepicker('widget').is(':visible');

        if (ev) {
            ev.preventDefault();
        }

        // Make button toggle datepicker visibility
        input.datepicker(visible ? 'hide' : 'show');
    },
    onDateKeydown: function(ev, input) {
        // If backspace/delete keypress on token focus, hide datepicker
        if (ev.keyCode && (ev.keyCode === 8 || ev.keyCode === 46)) {
            this.removeDate(ev, input);
        }
    },
    removeDate: function(ev, input) {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }

        if (input) {
            input.datepicker('hide');
            input.val('');
        }

        this.ui.pastInput.val('');

        this.pastDate = null;
        this.updatePastTooltip();

        this.ui.dueDateInput.val('');

        this.ui.setDueDate
            .tooltip('hide')
            .attr('data-original-title', 'Due date')
            .tooltip('fixTitle');


        this.ui.addNoteContainer
            .toggleClass('past-set', this.ui.pastInput.val().length > 0)
            .toggleClass('due-date-set', this.ui.dueDateInput.val() && this.ui.dueDateInput.val().length > 0);

        if (input) {
            input.next('.note-tool').focus();
        }
    },
    postNote: function(ev) {
        var view = this,
            note,
            pastDate,
            targetDate,
            tagIds,
            funnelIds;

        if (ev){
            ev.preventDefault();
        }

        pastDate = this.ui.pastInput.val();
        if (pastDate) {
            pastDate = dateFormat.ISODate($.datepicker.parseDate('M dd, yy', pastDate));
        }
        else {
            pastDate = null;
        }

        targetDate = this.ui.dueDateInput.val();

        if (targetDate) {
            targetDate = dateFormat.ISODate($.datepicker.parseDate('M dd, yy', targetDate));
        }
        else {
            targetDate = null;
        }

        tagIds = _.map(this.ui.tagsInput.select2('val'), function(tagId) {
            return {id: tagId};
        });

        funnelIds = _.map(this.ui.funnelsInput.select2('val'), function(funnelId) {
            return {id: funnelId};
        });

        note = ActivityCreator.createNote(
            htmlSanitizer.sanitize(this.ui.addNoteField.val().replace(/(\n)/gm,"")),
            pastDate,
            targetDate,
            this.model.get('type'),
            this.model.get('id'),
            this.noteActivityType,
            tagIds,
            funnelIds
        );

        const saveNote = () => {
            note.save({}, {
                success: function() {
                    view.fetchActivity();
                    vent.trigger('activity:save');
                    app.dirtyModelHandler.remove(view.model.cid + '-note');

                    if (AppConfig.getValue('on_activity_note_created.show_task_popup', false)) {
                        var entityType = view.model.get('type');
                        var content = {
                            message: 'Would you like to create a task?',
                            icon: 'icon-question',
                            cancel_button_text: AppConfig.getValue(`on_activity_note_created.task_popup.${entityType}.cancel_button_text`, 'No')
                        };

                        MessageBox.showYesNo(content, view,
                            function() { // yes
                                var typeMap = {
                                    'individuals': 'individual',
                                    'opportunities': 'opportunity',
                                    'organizations': 'organization'
                                };

                                var model = new TaskModel({
                                    related: {
                                        id: view.model.get('id'),
                                        related_type: typeMap[entityType],
                                        name: view.model.get('full_name') || view.model.get('name')
                                    }
                                });

                                vent.trigger('quick:add:task', model);
                            }, function() { // no

                                if (AppConfig.getValue(`on_activity_note_created.task_popup.${entityType}.show_inactive_reasons_popup`, false)) {
                                    var inactiveModal = new individualInactiveModal({
                                        model: view.model
                                    });

                                    view.individualInactiveReasons.show(inactiveModal);

                                    view.listenTo(inactiveModal, 'cancel', function () {
                                        view.individualInactiveReasons.reset();
                                    });
                                } else {
                                    AppConfig.getValue(`on_activity_note_created.task_popup.${entityType}.cancel_action`, null, view.model);
                                }
                            }
                        );
                    }
                }
            });

            this.resetForm();
        };

        if (AppConfig.getValue('activities.notes.on_creation.suggested_tags.check_condition', false, this.ui.tagsInput.select2('val'))) {
            const availableTags = AppConfig.getValue('activities.notes.on_creation.suggested_tags.tags_list');

            if (availableTags && availableTags.length > 0) {
                const self = this;

                MessageBox.showOk({
                    icon: 'icon-warning',
                    message: 'Please ensure one of these tags is added',
                    accept_button_text: 'Save'
                }, this, function(idsList) {
                    if (idsList.length > 0) {
                        const tags = [...note.get('tags'), ...idsList.map(id => { return { id: id } })];
                        note.set('tags', tags);
                    }

                    saveNote();
                }, {
                    type: 'tagSelect',
                    availableTags: availableTags
                }, null, true);
            } else {
                saveNote();
            }
        } else {
            saveNote();
        }

    },
    resetForm: function() {
        this.removeDate();
        this.ui.addNoteField[0].value = '';
        this.ui.addNoteField.val('').blur();
        this.ui.setPast.blur();
        this.ui.setDueDate.blur();
        this.ui.addNoteContainer.removeClass('focus has-value');
        this.ui.addNoteField.show();
        this.tagsSelect.setValue(this.userIndividualPreloadedTags);
        this.funnelsSelect.setValue(this.userIndividualPreloadedFunnels);
        this.ui.tagsTool.addClass('hide');
        this.ui.funnelsTool.addClass('hide');
        this.tinyContainer.hide();
    },
    fetchActivity: function(options) {
        var view = this;

        if (_.isUndefined(options)) {
            options = this.prevOptions;
        }

        options.page = this.collectionPage;

        this.prevOptions = options;

        this.listRegion.show(new LoadingView());
        this.footerRegion.close();

        if (options && options.emptyCollection) {
             view.onActivity(options.emptyCollection);
        }
        else {
            options.data.item_type = this.model.get('type');
            options.data.item_id = this.model.get('id');

            var activities = new ActivityCollection();
            activities.fetch(
                _.defaults(
                    {
                        success: function () {
                            if (!view.isClosed) {
                                view.onActivity(activities);
                            }
                        }
                    },
                    _.extend(options, {
                        sortOn: [{
                            attribute: 'pinned',
                            order: 'desc'
                        }, {
                            attribute: 'user_created',
                            order: 'desc'
                        }]
                    })
                )
            );
        }
    },
    onActivity: function(collection) {
        this.listRegion.show(new ActivityFeedView({
            model: this.model,
            collection: collection
        }));

        this.footerRegion.show(new FooterView({
            previous: collection.start > 0,
            next: collection.start + collection.length < collection.total,
            collectionStart: collection.start + 1,
            collectionEnd: collection.start + collection.length,
            collectionTotal: collection.total
        }));

        var that = this;

        this.listenTo(this.listRegion.currentView, 'showTarget', this.showTarget);
        this.listenTo(this.listRegion.currentView, 'filter', function(options) {
            that.collectionPage = 1;
            that.fetchActivity(options);
        });

        this.$el.nanoScroller();
    },
    showPreviousPage: function(ev) {
        ev.preventDefault();

        this.collectionPage = Math.max(this.collectionPage - 1, 1);
        this.fetchActivity();
    },
    showNextPage: function(ev) {
        ev.preventDefault();

        this.collectionPage++;
        this.fetchActivity();
    },
    onClose: function() {
        this.removeTinyMCE(this);
    },
    removeTinyMCE: function(view) {
        if (view.tinyEditor) {
            var contentLength = view.tinyEditor.getContent().length;
            view.tinyEditor.off(); // unbind all listeners
            view.tinyEditor.destroy();

            if (!view.isClosed) {
                // tinyMCE adds unnecessary &nbsp after destroy
                if (contentLength === 0) {
                    view.ui.addNoteField[0].value = '';
                }
            }
        }
    }
});

export default FullFeed;

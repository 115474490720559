import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app'
import PDFViewerView from 'js/views/pdf_viewer'
import Utilities from 'js/utils/utilities'
import yotiPreviewTemplate from 'templates/yoti_document_preview.handlebars'
import itemTemplate from 'templates/template_generator_template.handlebars'

var TemplateModel = Backbone.Model.extend({
});

var TemplateItemView = Marionette.ItemView.extend({
    tagName: 'li',
    className: 'template-item',
    template: Handlebars.compile(itemTemplate),

    events: {
        'click': function(ev) {
            ev.preventDefault();
            this.trigger('select');
        }
    },
});

var TemplatesListView = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'template-list',
    itemView: TemplateItemView,

    onRender: function() {
        this.listenTo(this, 'itemview:select', function(itemView) {
            this.trigger('templates-list:show-template', itemView);
        });
    }
});

var DefaultViewerView = Marionette.Layout.extend({
    template: Handlebars.compile('<div class="caption"></div>'),
    ui: {
        caption: '.caption'
    },
    setCaption: function(caption) {
        this.ui.caption.text(caption);
    }
});

// ...
export default Marionette.Layout.extend({
    template: Handlebars.compile(yotiPreviewTemplate),
    regions: {
        templatesListRegion: '.templates-region',
        yotiPreviewRegion: '.yoti-preview-region',
    },
    ui: {
        sendToYotiButton: '#send-to-yoti',
        cancelButton: '#cancel',
        headerTitle: '.title'
    },
    events: {
        'click #send-to-yoti': function(ev) {
            ev.preventDefault();
            // this.trigger('yoti-document-preview:close');
            this.onYoti(this.files, this.options.dealView)
        },
        'click #cancel': function(ev) {
            ev.preventDefault();
            this.trigger('yoti-document-preview:close');
        },
    },

    initialize: function(options) {
        this.options = options;
        this.files = Array.isArray(options.files) ? options.files : [options.files];
        this.onYoti = options.onYoti;
        this.dealView = options.dealView
        this.templateViewers = {};
        this.templateId = null;
        this.templateViewerRegionActive = null;
    },

    onShow: function() {
        this.$el.parent().addClass('template-modal');
        this.setButtonsLayout('init');

        this.fetchTemplates()
    },
    
    showTemplateRegion: function(show) {
        if (!show) {
            this.templatesListRegion.reset();
        }
        else {
            var templatesList = new TemplatesListView({collection: this.templatesCollection});
            var that = this;

            this.listenTo(templatesList, 'templates-list:show-template', function(itemView) {
                that.setActiveTemplate(itemView);
            });

            this.templatesListRegion.show(templatesList);
        }
    },

    setActiveTemplate: function(itemView) {
        if (itemView !== this.activeTemplate) {
            var model = itemView.model;

            if (this.activeTemplate) {
                this.activeTemplate.$el.removeClass('selected');
            }

            this.templateId = model.get('id');
            this.displayTemplate(model.get('ext'), this.templateId, {caption: model.get('name')});

            this.activeTemplate = itemView;
            this.activeTemplate.$el.addClass('selected');
        }
    },

    fetchTemplates: function() {
        var that = this;

        this.templatesCollection = new Backbone.Collection();

        for (var i = 0; i < this.files.length; ++i) {
            var file = this.files[i];
            var thumb = null;
            var icon = null;

            if (file.has_thumb) {
                thumb = app.options.apiUrl + '/content_files/' + file.id + '?thumb&client=' + app.user.get('client').short_id;
            }
            else {
                icon = Utilities.getTypeIcon(file.ext).icon;
            }

            var templateModel = new TemplateModel({
                'name': file.name,
                'id': file.id,
                'thumbnail': thumb,
                'icon': icon,
                'ext': file.ext
            });

            that.templatesCollection.add(templateModel);
        }

        // first template is active
        if (this.files.length > 0) {
            that.showTemplateRegion(true);
            that.setActiveTemplate(that.templatesListRegion.currentView.children.findByIndex(0));
        }
    },

    setButtonsLayout: function(layout) {
        if (layout === 'final' ) {
            this.ui.sendToYotiButton.addClass('button-muted disabled');
            this.ui.sendToYotiButton.removeClass('button-green');
            this.ui.headerTitle.addClass('invisible');
        }
        else {
            this.ui.sendToYotiButton.removeClass('button-muted disabled');
            this.ui.sendToYotiButton.addClass('button-green');
            this.ui.headerTitle.removeClass('invisible');
        }
    },

    getTemplateViewer: function(type) {
        var viewer;
        switch(type) {
            case 'pdf':
                if (this.templateViewers[type]) {
                    return this.templateViewers[type];
                }

                // ...
                viewer = new PDFViewerView();
                this.templateViewers[type] = viewer;

                this.listenTo(viewer, 'pdf-viewer:toggle-fullscreen', function() {
                    this.$el.parent().toggleClass('fullscreen');
                });

                this.yotiPreviewRegion.show(viewer);
                return viewer;

            default:
                if (this.templateViewers['default']) {
                    return this.templateViewers['default'];
                }

                // ...
                viewer = new DefaultViewerView();
                this.templateViewers['default'] = viewer;
                this.yotiPreviewRegion.show(viewer);
                return viewer;
        }
    },

    displayTemplate: function(fileExt, templateId, options) {
        options = options || {};

        var region = null;

        switch(fileExt) {
            case '.pdf':
                region = this.yotiPreviewRegion;
                this.getTemplateViewer('pdf').showPDF(templateId, options.resetView);
                break;

            default:
                region = this.defaultViewerRegion;
                this.getTemplateViewer('default').setCaption(options.caption);
                break;
        }

        if (this.templateViewerRegionActive !== region) {
            if (this.templateViewerRegionActive) {
                this.templateViewerRegionActive.$el.hide();
            }

            this.templateViewerRegionActive = region;
            this.templateViewerRegionActive.$el.show();
        }
    },

    getDisplayableExt: function(ext) {
        return ext.toUpperCase().substring(1);
    }
});

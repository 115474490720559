import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import Backbone from 'backbone'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import ForecastTableView from 'js/views/forecasts/table.js'
import ForecastTotalsView from 'js/views/forecasts/totals.js'
import ForecastSpreadOutView from 'js/views/forecasts/spread_out.js'
import base64 from 'js/utils/base64.js'
import ModalRegion from 'js/views/base/modal-region.js'
import ItemPermissionsView from 'js/views/item_permissions.js'
import dateFormat from 'js/utils/date-format.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'
import security from 'js/utils/security.js'
import appContent from 'js/views/appcontent.js'
import vent from 'js/vent.js'
import api from 'js/api.js'
import MessageBox from 'js/views/message_box.js'
import UserModel from 'js/models/user.js'
import ForecastOpportunityModel from 'js/models/forecast_deal.js'
import BucketsCollection from 'js/collections/buckets.js'
import PeriodsCollection from 'js/collections/periods.js'
import PhasesCollection from 'js/collections/phases.js'
import OpportunitiesCollection from 'js/collections/opportunities.js'
import ForecastOpportunityBucketCollection from 'js/collections/forecast_deal_buckets.js'
import ForecastOpportunityCollection from 'js/collections/forecast_deals.js'
import forecastViewTemplate from 'templates/forecasts/detail.handlebars'
import forecastOverviewTemplate from 'templates/forecasts/detail-overview.handlebars'
import addDealPopoverTemplate from 'templates/forecasts/add-deal-popover.handlebars'


var AddDealPopoverView, DetailViewController, ForecastView, OverviewView;

AddDealPopoverView = Marionette.ItemView.extend({
    className: 'add-deal-popover popover bottom',
    template: Handlebars.compile(addDealPopoverTemplate),
    ui: {
        deal_select: '.deal-select'
    },
    events: {
        'click .add-deal': function(e) {
            e.preventDefault();

            var view = this;
            var data = _.map(this.dealSelect.getData(), function(item) {
                return view.dealSelect.getItemId(item);
            });

            if ( data.length ) {
                this.parent.trigger('add-deal', data);

                this.dealSelect.setValue(new Backbone.Collection());
            }
        },
        'click .form-action': 'hideSelf'
    },
    initialize: function(options) {
        this.parent = options.parent;

        // Use model to pass arguments to template
        this.model = new Backbone.Model({
            title: options.title || TextManager.getText('ID_ADD_DEALS')
        });
    },
    onShow: function() {
        var view = this;

        this.dealSelect = new backboneSelect2.CollectionView({
            view: this,
            $el: this.ui.deal_select,
            url: '/opportunities',
            search: true,
            text: 'title',

            options: {
                placeholder: TextManager.getText('ID_SEARCH_FOR_A_DEAL'),
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover select2-drop-wider',
                minimumInputLength: 1
            }
        });

        this.ui.deal_select.select2('container').find('.select2-input').focus();

        $('body').on('mousedown.hide-popover', function(ev) {
            if ( !$(ev.target).is( view.$el.children() ) && !$(ev.target).closest(view.$el).length ) {
                view.hideSelf();
            }
        });

        this.$el.show();
    },
    hideSelf: function(ev) {
        if (ev) {
            ev.preventDefault();
        }

        this.$el.hide();
        $('body').off('mousedown.hide-popover');
    }
});

DetailViewController = Marionette.Controller.extend({
    initialize: function(options) {
        this.model = options.model;
        this.editing = options.editing || false;
        this.weighted = true;
        this.changes = {};
        this.redyToMakeDirty = false; // flag to when register view as dirty
    },
    setWeighted: function(value) {
        this.weighted = value;
        this.trigger('weighted', value);
    },
    isWeighted: function() {
        return this.weighted;
    },
    startEditing: function() {
        this.editing = true;
        this.trigger('editing:start');
        this.redyToMakeDirty = true;
    },
    endEditing: function() {
        this.editing = false;
        this.trigger('editing:end');
    },
    isEditing: function() {
        return this.editing;
    },
    addChange: function(key, value) {
        if (this.redyToMakeDirty) {
            app.dirtyModelHandler.add(this.model.cid);
        }
        this.changes[key] = value;
        this.trigger('editing:add:' + key, value);
    },
    removeChange: function(key) {
        delete this.changes[key];
        this.trigger('editing:remove:' + key);
    },
    saveEdition: function() {
        var controller = this;
        this.trigger('editing:save:before');
        if (_.keys(this.changes).length || this.model.isNew()) {
            this.model.save(this.changes, {
                validate: true,
                patch: true,
                success: function() {
                    controller.changes = {};
                    controller.trigger('editing:save:after');
                    controller.endEditing();
                    vent.trigger('forecast:save');
                    app.dirtyModelHandler.remove(controller.model.cid);
                }
            });
        }
        else {
            this.cancelEdition();
        }
    },
    cancelEdition: function() {
        app.dirtyModelHandler.confirm(this, function() {
            var controller = this;
            this.model.fetch({
                data: {'my_team': true},
                success: function () {
                    controller.changes = {};
                    controller.trigger('editing:cancel');
                    controller.endEditing();
                }
            });
        });
    },
    destroyModel: function() {
        this.model.destroy();
    }
});

ForecastView = Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-forecasts content-container',
    template: Handlebars.compile(forecastViewTemplate),
    regions: {
        overviewContainerRegion: '.overview-container',
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    events: {
        'change input[type="text"]': function(ev) {
            var input = $(ev.target);
            input.val($.trim(input.val()));
        },
        'click .edit.has-permission': function clickEdit() {
            this.controller.startEditing();
        },
        'click .save': function() {
            this.controller.saveEdition();
        },
        'click .cancel': function() {
            this.controller.cancelEdition();
        },
        'click .close-view': 'closeView'
    },
    initialize: function () {
        if ( !this.model.id ) {
            this.creatingNewItem = true;
            this.model.set("owner", { id: app.user.get('id'), name: app.user.get('name') });
        }
        this.editing = this.options.editing;

        this.controller = new DetailViewController({
            model: this.model
        });

        this.listenTo(this.controller, 'editing:start', this.onStartEditing);
        this.listenTo(this.controller, 'editing:end', this.onEndEditing);
        this.listenTo(this.model, 'sync', this.onModelSync);
        this.listenTo(this.model, 'destroy remove', this.onModelDestroy);
    },
    onRender: function () {
        var view = this,
            model = this.model,
            buckets,
            afterLoadingBuckets;

        // New item styling if no model.id is present
        this.$el.toggleClass('is-new', !model.id);

        afterLoadingBuckets = function() {
            if (model.id) {
                this.model.fetch({
                    success: function() {
                        if (!view.isClosed) {
                            view.onModel(model);
                        }
                    }
                });
            }
            else {
                var self = this,
                    periodsCollection = new PeriodsCollection();

                periodsCollection.fetch({
                    sortOn: [{
                        attribute: 'start_date',
                        order: 'asc'
                    }],
                    rows: -1,
                    success: function() {
                        if (!view.isClosed) {
                            // Render overview region
                            var overviewView = new OverviewView({
                                controller: self.controller,
                                parent: self,
                                model: model,
                                periods: periodsCollection
                            });
                            overviewView.buckets = buckets;

                            self.overviewContainerRegion.show(overviewView);
                        }
                    }
                });
            }
        };
        afterLoadingBuckets = _.bind(afterLoadingBuckets, this);

        buckets = new BucketsCollection();
        buckets.fetch({
            rows: -1,
            success: function() {
                if (!view.isClosed) {
                    view.buckets = buckets;
                    afterLoadingBuckets();
                }
            }
        });
    },
    onStartEditing: function() {
        this.$el.addClass('edit-mode');
        this.$el.find('.related-content').hide();
    },
    onEndEditing: function() {
        // Exit edit mode
        // OR
        // Cancel create new item
        if (this.model.id) {
            this.$el.removeClass('edit-mode');
            this.$el.find('.related-content').show();
        }
        else {
            appContent.pop({});
        }
    },
    onModelSync: function() {
        if (this.creatingNewItem) {
            this.creatingNewItem = false;
            this.editing = false;
            this.render();
            vent.trigger('AppContent:contentChange');
        }
    },
    onModelDestroy: function() {
        this.trigger('view:close', this);
    },
    onModel: function(model) {
        if (this.isClosed) {
            return;
        }

        var self = this,
            periodsCollection = new PeriodsCollection();

        periodsCollection.fetch({
            sortOn: [{
                attribute: 'start_date',
                order: 'asc'
            }],
            rows: -1,
            success: function() {
                var overviewView = new OverviewView({
                        controller: self.controller,
                        parent: self,
                        model: model,
                        periods: periodsCollection
                    });
                overviewView.buckets = self.buckets;

                self.overviewContainerRegion.show(overviewView);
            }
        });
    },
    showACL: function() {
        if (this.aclRegion && this.model.id) {
            var ipv = new ItemPermissionsView({model: this.model});
            this.aclRegion.show(ipv);
            this.listenTo( ipv, 'close', function() {
                this.aclRegion.reset();
            });
        }
    },
    closeView: function(ev) {
        if (ev) {
            ev.preventDefault();
        }
        this.trigger('view:close', this);
    },
    scrollBar: function() {
        // Override BaseShowView scrollBar method
        // Don't use nanoScroller until x-scrolling is supported
        this.scrollEvents();
    },
    scrollEvents: function() {
        var container = this.ui.forecastTableContainer,
            header = this.ui.header;

        $(container).scroll(function() {
            if (container.find('.dataTables_wrapper')
                .position().top < -1) {
                if (!header.hasClass('header-shadow')) {
                    header.addClass('header-shadow');
                }
            } else {
                header.removeClass('header-shadow');
            }
        });
    },
    getUrl: function() {
        var id = this.model.get('short_id') || this.model.id;

        return 'forecasts/' + (id ? id : 'new');
    }
});

/*
 * The main area of the detail page, with the header, communications,
 * locations, comments, related deals...
 */
OverviewView = Marionette.Layout.extend({
    tagName: 'div',
    className: 'overview',
    template: Handlebars.compile(forecastOverviewTemplate),
    ui: {
        display_name: '.detail-name',
        display_owner: '.detail-owner .owner',
        display_period: '.period',
        display_period_start: '.period-start',
        edit_owner: '.field-owner',
        edit_name: '.field-name',
        edit_period: '.field-period',
        edit_period_start: '.field-period-start',
        edit_published: '.field-published',
        include_deals_container: '.include-period-deals',
        edit_include_deals_switch: '.include-period-deals .switch',
        error_messages: '.error-message',
        header: '.detail-header',
        main_info: '.detail-main-info',
        nav: '.detail-nav',
        tooltips: '[data-toggle=tooltip]',
        csvLink: '.csv-download a',
        weighted_radio: '.weighted-radio-group',
        downloadForm: '.download-form',
        tabs: '.tabs li',
        detailContent: '.forecast-details-edit',
        detailTab: '.details-tab',
        tableTab: '.table-tab',
        monthlyTab: '.monthly-tab',
        totalsTab: '.totals-tab',
        addDealButton: '.add-deal-toggle'
    },
    events: {
        'click .delete-button': function() {
            app.dirtyModelHandler.confirm(this, function() {
                var self = this,
                    mbContent = {
                        accept_is_negative: true,
                        message: Handlebars.compile('Are you sure you want to <strong>permanently</strong> delete {{name}}?')({name: this.model.get('name')}),
                        icon: 'icon-trashcan'
                    };
                MessageBox.showYesNo(mbContent, this, function () {
                    self.controller.destroyModel();
                });
            });
        },
        'click .clone-item': function clickClone(ev) {
            ev.preventDefault();
            ev.stopPropagation();

            app.dirtyModelHandler.confirm(this, function() {
                var parent = this.options.parent;
                this.model.createClone({
                    success: function (model) {
                        vent.trigger('forecast:save');
                        parent.trigger('replace-view:forecast:show', {
                            model: model
                        });
                    }
                });
            });
        },
        'change .field-name': function changeName(ev) {
            this.controller.addChange('name', $(ev.target).val());
        },
        'change .field-period': function changePeriod(ev) {
            var period = $(ev.currentTarget).select2('data');

            if (period) {
                this.controller.addChange('period', { id: period.id });
                this.ui.display_period_start.text(this.periodString(period));

                // distinguish between initial change on init and actual change
                if (ev.val) {
                    this.fetchOpportunities(period);
                }
                this.ui.include_deals_container.show();
            }
        },
        'click .include-period-deals .switch': function includeDeals() {
            this.ui.edit_include_deals_switch.toggleClass('toggled');

            this.fetchOpportunities();
        },
        'click .edit-permissions.has-permission': function clickLockpad() {
            if (this.aclRegion && this.model.id) {
                var ipv = new ItemPermissionsView({model: this.model});
                this.aclRegion.show(ipv);
                this.listenTo( ipv, 'close', function() {
                    this.aclRegion.reset();
                });
            }
        },
        'click #weighted-on': function() {
            this.controller.setWeighted(true);

            this.ui.weighted_radio.removeClass('checked');
            this.ui.weighted_radio.find('#weighted-on').addClass('checked');
        },
        'click #weighted-off': function() {
            this.controller.setWeighted(false);

            this.ui.weighted_radio.removeClass('checked');
            this.ui.weighted_radio.find('#weighted-off').addClass('checked');
        },
        'click .csv-download a': function() {
            this.ui.downloadForm.submit();
        },
        'click .tabs li': function(ev) {
            ev.preventDefault();

            this.showTab($(ev.currentTarget));
        },
        'click .add-deal-toggle': function(ev) {
            ev.preventDefault();

            if (this.addDealPopoverRegion) {
                this.addDealPopover = new AddDealPopoverView( {
                    parent: this,
                    title: TextManager.getText('ID_ADD_DEALS_TO_FORECAST')
                });
                this.addDealPopoverRegion.show(this.addDealPopover);
            }
        }
    },
    regions: {
        tableRegion: '.forecast-table-pane',
        addDealPopoverRegion: '.add-deal-container',
        aclRegion: {
            selector: '.acl-region',
            regionType: ModalRegion
        }
    },
    templateHelpers: function () {
        var owner = this.model.get('owner');

        return {
            owner: owner ? { name: owner['name'] } : null,
            downloadAction: app.options.apiUrl + '/forecasts/' + this.model.get('id') + '?csv',
            weighted: this.controller.isWeighted(),
            published: this.model.get('published') !== 'default'
        };
    },
    initialize: function(options) {
        var view = this;
        this.controller = options.controller;
        this.forecastTableView = null;

        this.listenTo(this.model, 'sync', this.onModelSync);

        this.listenTo(this.controller, 'editing:cancel', function() {
            view.errorMessages_hide();
            view.forecastTableView = null;
        });

        this.listenTo(this.controller, 'editing:start', function() {
            this.errorMessages_unhide();

            this.ui.monthlyTab.hide();
            this.ui.totalsTab.hide();

            if (this.ui.monthlyTab.hasClass('active') || this.ui.totalsTab.hasClass('active')) {
                this.showTab(this.ui.tableTab);
            }

            // we save a snapshot of the currencies conversion table
            if (view.model.isNew()) {
                var conversions = app.user.get('client')['currencies'];

                if (conversions) {
                    var currConv = {};
                    $.extend(currConv, conversions);
                    currConv.currency = app.user.get('client')['default_currency'];
                    view.controller.addChange('currencies_conversion', currConv);
                }
            }
        });
        this.listenTo(this.controller, 'editing:save:after', this.errorMessages_remove);

        // If details tab is active, go back to table on editing:end
        this.listenTo(this.controller, 'editing:end', function() {
            if (!this.isClosed) {
                this.ui.monthlyTab.show();
                this.ui.totalsTab.show();

                if (this.ui.detailTab.hasClass('active')) {
                    this.showTab(this.ui.tableTab);
                }
            }
        });

        this.listenTo(this.controller, 'weighted', function(weighted) {
            this.setWeighted(weighted);
        });

        // this is native event called from model validate()
        this.listenTo(this.model, 'invalid', function(model, errors) {
            this.errorMessages_remove();

            var hasError = false;
            if (errors.missing_name) {
                view.ui.edit_name
                    .addClass('validation_error')
                    .nextAll('.error-message')
                    .text(errors.missing_name)
                    .addClass('invalid');

                hasError = true;
            }
            if (errors.missing_period) {
                view.ui.edit_period.select2('container')
                    .addClass('validation_error')
                    .parent()
                    .nextAll('.error-message')
                    .text(errors.missing_period)
                    .addClass('invalid');

                hasError = true;
            }

            if (hasError) {
                this.$el.find('.tabs .details-tab a').removeClass('has-error', function() {
                    $(this).addClass('has-error');
                });
            } else {
                this.$el.find('.tabs .details-tab a').removeClass('has-error');
            }
        });

        if (this.model.isNew()) {
            var periods = this.options.periods,
                period_id = periods.getCurrentPeriodId();
            this.model.set('period', periods.get(period_id).toJSON());
        }
    },
    showTab: function(tab) {
        if (tab.hasClass('active')) {
            return;
        }
        this.ui.tabs.not(tab).removeClass('active');
        tab.addClass('active');

        var tableView;
        if (tab.is(this.ui.tableTab)) {
            this.forecastTableView = new ForecastTableView({
                parent: this,
                controller: this.controller,
                model: this.model,
                buckets: this.buckets,
                editing: this.options.controller.editing ? true : false
            });
            tableView = this.forecastTableView;
        }
        else if (tab.is(this.ui.monthlyTab)) {
            tableView = new ForecastSpreadOutView({
                parent: this,
                controller: this.controller,
                model: this.model
            });
        }
        else if (tab.is(this.ui.totalsTab)) {
            tableView = new ForecastTotalsView({
                parent: this,
                controller: this.controller,
                model: this.model,
                buckets: this.buckets
            });
        }
        if (tableView) {
            this.ui.detailContent.hide();
            this.tableRegion.show(tableView);
            this.ui.addDealButton.show();
        } else {
            this.ui.detailContent.show();
            this.tableRegion.reset();
            this.ui.addDealButton.hide();
        }
    },
    onRender: function() {
        if (this.options.model && this.options.model.isNew()) {
            // include only period deals by default for new forecasts
            this.ui.edit_include_deals_switch.toggleClass('toggled');
            this.fetchOpportunities();
        }

        this.initTooltips();
        this.initSelect2();

        if (this.controller.isEditing()) {
            this.controller.trigger('editing:start');
        }

        if (this.options.model && this.options.model.isNew()) {
            this.showTab(this.ui.detailTab);
        }
        else {
            this.showTab(this.ui.tableTab);
        }

        if (!this.model.get('period')) {
            this.ui.include_deals_container.hide();
        }
    },
    onModelSync: function() {
        if (this.isClosed) {
            return;
        }

        var owner = this.model.get('owner'),
            owner_name = owner['name'],
            name = this.model.get('name'),
            period = this.model.get('period');

        this.ui.display_owner.text(owner_name);
        this.ui.display_name.text(name);

        if (period) {
            this.ui.display_period.text(period.name);
            this.ui.display_period_start.text(this.periodString(period));
        }

        this.ownerSelect.setValue({
            name: owner.name,
            id: owner.id
        });
        this.ui.edit_name.val(name);

        this.render();
    },
    initTooltips: function() {
        // Header tools
        this.ui.tooltips.tooltip({
            placement: 'bottom',
            container: 'body'
        });
    },
    onBeforeClose: function() {
        // remove tooltip when clicking on clone button, which will remove view
        $('body').find('.tooltip').remove();
    },
    initSelect2: function() {
        var period = this.model.get('period'),
            owner = this.model.get('owner');

        var periodSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_period,
            text: 'name',
            data: this.options.periods.toJSON(),
            value: period && period.id,

            options: {
                placeholder: 'Select a period',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'period-select-popover popover'
            }
        });
        this.ui.display_period_start.text(this.periodString(period));
        if (this.model.isNew()) {
            periodSelect.$el.trigger('change');
        }

        this.publishedSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_published,
            text: 'text',
            data: [
                {
                    id: 'default',
                    text: 'Off'
                },
                {
                    id: 'managers',
                    text: 'Publish to all line managers'
                },
                {
                    id: 'everyone',
                    text: 'Publish to everyone'
                }
            ],
            value: this.model.get('published'),

            options: {
                containerCssClass: 'select2-block',
                dropdownCssClass: 'period-select-popover popover'
            }
        });

        this.listenTo(this.publishedSelect, 'change', function(item) {
            this.controller.addChange('published', item.id);
        });

        this.ownerSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.edit_owner,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: {
                name: owner.name,
                id: owner.id
            },

            options: {
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.ownerSelect, 'change', this.change_owner);
        // Check for admin permissions
        if ( !security.checkPermission('change_ownership', this.model) ) {
            this.ownerSelect.$el.select2('readonly', true);
            this.ownerSelect.$el.select2('container').tooltip({
                title: "You don't have permission to edit owner",
                container: 'body'
            });
        }
    },
    change_owner: function(item) {
        var id = item && item.id;

        this.controller.addChange('owner_id', id);
    },
    errorMessages_remove: function() {
        this.$el.find('.validation_error').removeClass('validation_error');
        this.$el.find('.tabs .details-tab a').removeClass('has-error');
        this.ui.error_messages.empty().removeClass('invalid');
    },
    errorMessages_hide: function() {
        this.ui.error_messages.hide();
    },
    errorMessages_unhide: function() {
        this.ui.error_messages.show();
    },
    /**
     * Extend given forecast opportunity collection with new values generated from given opportunities.
     *
     * @param opportunityModels
     * @param FCOpportunities
     */
    extendFCOpportunities: function(opportunityModels, FCOpportunities) {
        var existingOpportunities = {};
        _.each(FCOpportunities, function(FCOpportunity) {
            existingOpportunities[FCOpportunity['opportunity_id']] = true;
        });
        _.each(opportunityModels, function(opportunityModel) {
            // we ignore duplicate opportunities
            if (!(opportunityModel.get('id') in existingOpportunities)) {
                var FCOpportunity = {
                    opportunity: opportunityModel.attributes,
                    opportunity_id: opportunityModel.get('id'),
                    forecast_opportunity_buckets: [],
                    status: opportunityModel.get('status'),
                    weight: opportunityModel.get('weight')
                };
                _.each(opportunityModel.get('buckets'), function (bucketObj) {
                    FCOpportunity['forecast_opportunity_buckets'].push({
                        bucket_id: bucketObj.id,
                        value: bucketObj.value
                    });
                });
                FCOpportunities.push(FCOpportunity);
            }
        });
    },
    periodString: function(period) {
        if (period) {
            var end_date = new Date(period.end_date);
                end_date.setDate(end_date.getDate()-1);

            return dateFormat.entityInformationFormat(new Date(period.start_date)) + ' - '
                + dateFormat.entityInformationFormat(end_date);
        }
        else {
            return false;
        }
    },
    /**
     * fetch opportunities according to period and include deals toggle.
     *
     * @param period    model     set period to fetch from, caches on first call
     */
    fetchOpportunities: function(period) {
        var self = this,
            opportunities = new OpportunitiesCollection(),
            FCOpportunities = [];

        // use provided value, use cached value, use from model if no change so far
        period = period || this.period || this.model.get('period');
        this.period = period;

        if (this.opportunityFetch) {
            this.opportunityFetch.abort();
        }

        this.opportunityFetch = opportunities.fetch({
            url: '/opportunities',
            data: {
                for_forecast: true,
                by_period_id: period.id,
                only_from_period: this.ui.edit_include_deals_switch.hasClass('toggled'),
                owner_id: app.user.get('id'),
                rows: -1
            },
            success: function () {
                self.extendFCOpportunities(opportunities.models, FCOpportunities);
                self.model.set('forecast_opportunities', FCOpportunities);
                self.controller.addChange('forecast_opportunities', FCOpportunities);
                self.controller.trigger('opportunities-from-period');
                if (self.options.parent.editing) {
                    self.controller.startEditing();
                }
            }
        });
    },
    setWeighted: function(weighted) {
        var form = this.ui.downloadForm.get(0),
            urlEl = document.createElement("a");

        urlEl.href = form.action;
        urlEl.search = "?csv&weighted=" + weighted;
        form.action = urlEl.href;
    }
});

export default ForecastView;

import React from 'react'
import Marionette from 'Backbone.Marionette'

import vent from 'js/vent'
import {Calendar, CalendarDateSelector} from 'js/react_views/calendar/calendar'
import AppConfig from 'app/app-config'

import style from './task_calendar.css'

export default class TaskCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.span = 'week';
        this.date = new Date();
        this.forceFetch = false;

        const self = this;

        this.props.widget.addEventListener('refresh', function() {
            self.forceFetch = true;
            self.calendar.fetchData();
        });

        this.props.widget.addEventListener('task:completed:change', function(params) {
            self.calendar.setTaskCompleted(params.taskId, params.completed);
        });

        this.props.widget.addEventListener('dirty', function() {
            _.defer(function() {
                self.calendar?.handleWindowResize();
            });
        });
    }

    componentDidMount() {
        this.calendar.fetchData();
    }

    fetchData(callback) {
        let tasks = [];
        let appointments = [];
        const entities = this.props.widget.params?.entities ? this.props.widget.params.entities : ['tasks'];
        let counter = 0;

        const checkReady = () => {
            --counter;

            if (counter === 0) {
                callback([...tasks, ...appointments]);
            }
        }

        if (entities.indexOf('tasks') !== -1) {
            ++counter;

            let args = {
                start: 0,
                rows: -1
            };

            if (this.props.widget.params?.query?.args) {
                _.extend(args, this.props.widget.params.query.args);
            } else {
                args.assigned_to_me = true;
            }

            this.props.widget.fetcher.get('/tasks', args, function(data) {
                tasks = new Backbone.Collection(data).models;
                checkReady();
            }, {force: this.forceFetch});
        }

        if (entities.indexOf('appointments') !== -1) {
            ++counter;

            let args = {
                start: 0,
                rows: -1
            };

            if (this.props.widget.params?.query?.args) {
                _.extend(args, this.props.widget.params.query.args);
            }

            this.props.widget.fetcher.get('/appointments', args, function(data) {
                appointments = new Backbone.Collection(data).models;
                checkReady();
            }, {force: this.forceFetch});
        }

        this.forceFetch = false;
    }

    handleCreateTask(dueDate) {
        let params = {
            preloadedFields: {
                due_date: dueDate
            }
        };

        params.preloadedFields = _.extend(params.preloadedFields, this.props.widget.params?.creation_params?.tasks?.preloadedFields || {});

        this.props.widget.triggerEvent('showQuickAddTaskView', params);
    }

    handleCreateAppointment(date) {
        let params = {
            startDate: date
        };

        params = _.extend(params, this.props.widget.params?.creation_params?.appointments?.preloadedFields || {});

        this.props.widget.triggerEvent('showQuickAddAppointmentView', params);
    }

    render() {
        const appointmentsAvailable = AppConfig.getValue('app_nav_items.appointments.visible');
        const entityType = appointmentsAvailable ? 'appointments' : 'tasks';

        return (
            <div className={style.taskCalendar}>
                <div className={style.tcContainer}>
                    <CalendarDateSelector
                        ref={(el) => this.selector = el}
                        visible={true}
                        date={this.date}
                        span={this.span}
                        showEntitySelector={appointmentsAvailable}
                        onDateChange={(date) => this.calendar.setCurrentDate(date)}
                        onSpanChange={(span) => this.calendar.setSpan(span)}
                        onDateSpanChange={(date, span) => this.calendar.setDateSpan(date, span)}
                        onEntityTypeChange={(entityType) => this.calendar.setEntityType(entityType)}
                    />

                    <div className={style.tkContainer}>
                        <Calendar
                            ref={(el) => this.calendar = el}
                            visible={true}
                            date={this.date}
                            span={this.span}
                            entityType={entityType}
                            fetchDataFunction={this.fetchData.bind(this)}
                            onDateSpanChange={(date, span) => this.selector.setDateSpan(date, span)}
                            onEditTask={(task) => vent.trigger('quick:edit:task', task)}
                            onEditAppointment={(appointment) => vent.trigger('quick:edit:appointment', appointment.get('id'))}
                            onCreateTask={this.handleCreateTask.bind(this)}
                            onCreateAppointment={this.handleCreateAppointment.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
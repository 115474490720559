import $ from 'jquery'
import React from 'react';
import Marionette from 'Backbone.Marionette'

import app from 'js/app'
import TextManager from 'app/text-manager'
import ActivityModel from 'js/models/activity'
import Tooltip from 'js/react_views/tooltip/tooltip'
import {NewSelect, TagSelect} from 'js/react_views/widgets/select';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator'
import Paginator from 'js/react_views/paginator/paginator'
import htmlSanitizer from 'js/utils/html-sanitizer'
import dateFormat from 'js/utils/date-format'
import Utils from 'js/utils/utilities'
import AppConfig from 'app/app-config'
import EmailViewerMarionette from 'js/views/activity/email-viewer'

import style from './activities-list.css';


class EmailViewer extends React.Component {
    componentDidMount() {
        const region = new Marionette.Region({
            el: this.element
        });

        const model = this.props.model;
        const params = model.get('params') || {};

        const modelOptions = {
            created: model.get('created'),
            individual_name: this.getAuthor(),
            note: params.content || '',
            params: params
        };

        const emailViewer = new EmailViewerMarionette({
            model: new ActivityModel(modelOptions)
        });

        const self = this;

        emailViewer.listenTo(emailViewer, 'email-viewer:close', function() {
            self.props.close();
        });

        region.show(emailViewer);
    }

    getAuthor() {
        const activity_type = this.props.model.get('activity_type');

        if ((activity_type === 'archive:mailshot_sent') || (activity_type === 'automation:archive:mailshot_sent')) {
            const creator = this.props.model.get('creator');
            let author = '';

            if (creator) {
                author = creator['name'] || creator['email_address'];
            } else {
                const params = this.props.model.get('params');

                if (params && params.from_name) {
                    author = params.from_name;
                } else {
                    author = app.user.get('name') || app.user.get('email_address');
                }
            }

            return author;
        }

        return this.props.model.get('individual_name');
    }

    render() {
        return (
            <div
                className={`${style.alEmailViewerBackdrop} activity-email-viewer`}
                onClick={this.props.close}
            >
                <div
                    ref={(el) => this.element = el}
                    className={style.evDialog}>
                </div>
            </div>
        );
    }
}


class Filters extends React.Component {
    constructor(props) {
        super(props);

        let filters = [{
            id: 'notes',
            title: 'Notes',
            icon: 'icon-note'
        }, {
            id: 'communications',
            title: 'Communication',
            icon: 'icon-comment'
        }, {
            id: 'contact_updates',
            title: 'Contact Updates',
            icon: 'icon-user'
        }, {
            id: 'deals',
            title: TextManager.getText('ID_ENTITY_ACTIVITY', ['${ID_DEAL, capitalize}']),
            icon: 'icon-filter'
        }, {
            id: 'campaign_emails',
            title: TextManager.parseText('${ID_CAMPAIGN, capitalize} Emails'),
            icon: 'icon-campaign'
        }, {
            id: 'automations',
            title: 'Automations',
            icon: 'icon-bolt'
        }, {
            id: 'web_activity',
            title: 'Web Activity',
            icon: 'icon-globe'
        }, {
            id: 'tasks',
            title: 'Tasks Notes',
            icon: 'icon-checkmark3',
        }];

        if (AppConfig.getValue('app_nav_items.appointments.visible')) {
            filters.push({
                id: 'appointments',
                title: 'Appointments Notes',
                icon: 'icon-calendar'
            });
        }

        this.filtersState = app.user.get('preferences')[props.filtersStateField] || {};

        for (const f of filters) {
            f.active = (f.id in this.filtersState) ? this.filtersState[f.id] : true;
        }

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-3868')) {
            const self = this;

            $.get('/integrations?status=authorized', function(integrations) {
                const availableIntegrations = [{
                    id: 'xero',
                    title: 'Xero',
                    icon: 'icon-xero-logo'
                }];

                let updateFilters = false;

                for (const integration of integrations) {
                    if (integration.id in availableIntegrations) {
                        const iinfo = availableIntegrations[integration.id];
                        const iid = `integration:${iinfo.id}`;

                        filters.push({
                            id: iid,
                            title: iinfo.title,
                            icon: iinfo.icon,
                            active: (iid in this.filtersState) ? this.filtersState[iid] : true
                        });

                        updateFilters = true;
                    }
                }

                if (updateFilters) {
                    self.setState({
                        filters: filters
                    });
                }
            });
        }

        this.creatorOrOwner = {
            id: 'any_user',
            title: 'Any User'
        };

        this.tags = [];

        const filterTags = (this.filtersState.tags || '').split(',');

        for (const tag of filterTags) {
            const tid = tag.trim();
            const tagInfo = app.globalData.tags.find(t => t.id === tid);

            if (tagInfo) {
                this.tags.push({
                    id: tagInfo.id,
                    name: tagInfo.name
                });
            }
        }

        this.state = {
            collapsed: true,
            filters: filters
        };

        _.extend(this.state, this.getFiltersAppliedInfo());
    }

    getFiltersAppliedInfo() {
        let numFiltersApplied = 0;
        let hasFiltersApplied = false;

        for (const f of this.state.filters) {
            if (!f.active) {
                ++numFiltersApplied;
            }
        }

        if (numFiltersApplied > 0) {
            hasFiltersApplied = true;
            numFiltersApplied = this.state.filters.length - numFiltersApplied;
        } else {
            numFiltersApplied = -1;
        }

        // tags & owner/creator
        hasFiltersApplied |= this.creatorOrOwner.id !== 'any_user' || this.tags.length > 0;

        return {
            hasFiltersApplied: hasFiltersApplied,
            numFiltersApplied: numFiltersApplied
        };
    }

    toggleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    buildOwnerOptions(callback) {
        const self = this;

        $.getJSON('/users', function(data) {
            var options = [{
                id: 'any_user',
                title: 'Any User'
            }, {
                id: app.user.get('id'),
                title: `${app.user.get('name')} (me)`
            }, {
                id: 'separator'
            }];

            for (const user of data) {
                if (user.id === app.user.get('id')) {
                    continue;
                }

                options.push({
                    id: user.id,
                    title: user.name
                });
            }

            if (self.filtersState.creator_or_owner_id) {
                const option = options.find(o => o.id === self.filtersState.creator_or_owner_id);

                if (option) {
                    self.creatorOrOwner = {
                        id: option.id,
                        title: option.title
                    };
                }
            }

            callback(options, self.creatorOrOwner.id);
        });
    }

    saveFiltersState() {
        let state = {};

        for (const f of this.state.filters) {
            state[f.id] = f.active;
        }

        if (this.creatorOrOwner.id !== 'any_user') {
            state.creator_or_owner_id = this.creatorOrOwner.id;
        }

        if (this.tags.length > 0) {
            state.tags = this.tags.map(t => t.id).join(',');
        }

        app.user.updatePreference(this.props.filtersStateField, state);
    }

    toggleFilterState(filter) {
        filter.active = !filter.active;

        let newState = {
            filters: this.state.filters
        };

        _.extend(newState, this.getFiltersAppliedInfo());

        this.setState(newState);
        this.saveFiltersState();
        this.props.reqFetchData();
    }

    handleTagChange(tags) {
        this.tags = tags;

        this.setState(this.getFiltersAppliedInfo());
        this.saveFiltersState();
        this.props.reqFetchData();
    }

    handleCreatorOrOwnerChange(item) {
        if (item[0].id === this.creatorOrOwner.id) {
            return;
        }

        this.creatorOrOwner = _.clone(item[0]);

        this.setState(this.getFiltersAppliedInfo());
        this.saveFiltersState();
        this.props.reqFetchData();
    }

    handleReset() {
        for (let f of this.state.filters) {
            f.active = true;
        }

        this.tags = [];
        this.creatorOrOwner = {
            id: 'any_user',
            title: 'Any User'
        };

        this.tagsSelector.clear([]);
        this.ownerDropdown.setValue(this.creatorOrOwner.id);

        let newState = {
            filters: this.state.filters
        };

        _.extend(newState, this.getFiltersAppliedInfo());

        this.setState(newState);
        this.saveFiltersState();
        this.props.reqFetchData();
    }

    render() {
        return (
            <div style={{padding: '0 20px'}}>
                <div className={style.alFilters}>
                    <div
                        className={style.fHeader}
                        onClick={this.toggleCollapse.bind(this)}
                    >
                        <div className={`
                            ${style.hTitle}
                            ${this.state.hasFiltersApplied ? style.tHasFiltersApplied : ''}
                        `}>
                            Filters
                        </div>

                        {this.state.numFiltersApplied > -1 &&
                            <div className={style.hBadge}>{this.state.numFiltersApplied}</div>
                        }

                        <div className={`
                            ${style.hArrow}
                            ${this.state.collapsed ? 'icon-caret-right' : 'icon-caret-down'}
                        `}/>
                    </div>

                    {!this.state.collapsed &&
                        <div className={style.fContent}>
                            <div className={style.cTitle}>Activity to Display:</div>

                            <div className={style.cFiltersList}>
                                {this.state.filters.map(filter => {
                                    return (
                                        <div
                                            key={filter.id}
                                            className={`
                                                ${style.flItem}
                                                ${filter.icon}
                                                ${filter.active ? style.iActive : ''}
                                            `}
                                            onClick={() => this.toggleFilterState.bind(this)(filter)}
                                        >
                                            <Tooltip
                                                title={filter.title}
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={style.cTitle}>Author or Owner:</div>

                            <div className={style.cOwnerDropdown}>
                                <NewSelect
                                    ref={(el) => this.ownerDropdown = el}
                                    width={200}
                                    data={this.buildOwnerOptions.bind(this)}
                                    value={this.creatorOrOwner}
                                    onSelect={this.handleCreatorOrOwnerChange.bind(this)}
                                />
                            </div>

                            <div className={style.cTitle}>Activity Tags:</div>

                            <div className={style.cTagsSelector}>
                                <TagSelect
                                    ref={(el) => this.tagsSelector = el}
                                    value={this.tags}
                                    url='/tags'
                                    text='name'
                                    width='250'
                                    placeholder=''
                                    onChange={this.handleTagChange.bind(this)}
                                />
                            </div>

                            <div
                                className={`
                                    ${style.cResetButton}
                                    ${this.state.hasFiltersApplied ? style.bActive : ''}
                                `}
                                onClick={this.handleReset.bind(this)}
                            >
                                    Reset
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}


class IconThumb extends React.Component {
    render() {
        return (
            <div className={style.iThumb}>
                <div
                    className={`
                        ${style.tIcon}
                        ${this.props.icon}
                    `}
                    style={{
                        color: this.props.color
                    }}
                />
            </div>
        );
    }
}


class UserThumb extends React.Component {
    render() {
        const userInitials = this.props.owner.name.split(' ').map(s => s.charAt(0)).join('');

        return (
            <div className={style.iThumb}>
                {this.props.owner.photo_url ? (
                    <div
                        className={style.tImage}
                        style={{
                            backgroundImage: `url('${this.props.owner.photo_url}')`
                        }}
                    />
                ) : (
                    <div className={style.tInitials}>
                        {userInitials}
                    </div>
                )}

                <Tooltip
                    title={this.props.owner.name}
                    align='right'
                />
            </div>
        );
    }
}

class TagsContainer extends React.Component {
    render() {
        const tags = this.props.tags;

        if (!tags || tags.length === 0) {
            return null;
        }

        return (
            <div className={style.iTagsContainer}>
                {tags.map(tag =>
                    <div
                        key={tag.id}
                        className={style.tcTag}
                    >
                        {tag.name}
                    </div>
                )}
            </div>
        );
    }
}

class Header extends React.Component {
    render() {
        let relatedName = '';

        if (this.props.related.type === 'appointments') {
            relatedName = 'Appointment';
        } else if (this.props.related.type === 'tasks') {
            relatedName = 'Task';
        } else {
            relatedName = this.props.related.name;
        }

        return (
            <div className={style.iHeader}>
                <div className={`${style.hBold} ${style.aDarker}`}>{this.props.owner.name}</div>
                <div className={`${style.hArrow} icon-caret-right`}/>
                <div className={style.iTargetName}>{relatedName}</div>
            </div>
        );
    }
}


class Footer extends React.Component {
    render() {
        const createdDate = this.props.data.created;

        if (!createdDate) {
            return null;
        }

        const realCreatedDate = this.props.data.real_created;
        const pastCreated = this.props.showPast && (createdDate !== realCreatedDate);
        const formattedDate = dateFormat.timelineFormat(dateFormat.parseDate(createdDate));
        const edited = this.props.showEdited && this.props.data.version > 0;
        const showRelated = this.props.data.related && this.props.showRelated;

        return (
            <div className={style.iFooter}>
                {showRelated &&
                    <div className={style.fRelated}>
                        <div style={{marginRight: '5px'}}>↳</div>
                        <div className={style.iTargetName}>{this.props.data.related.name}</div>
                    </div>
                }

                <div className={style.fContent}>
                    {showRelated &&
                        <div
                            style={{
                                marginLeft: '3px',
                                marginRight: '3px'
                            }}
                        >
                            –
                        </div>
                    }

                    {pastCreated &&
                        <div
                            className='icon-history'
                            style={{
                                marginRight: '5px'
                            }}
                        />
                    }

                    {formattedDate}

                    {pastCreated &&
                        <Tooltip
                            title={`Activity date: ${formattedDate}`}
                            align='top'
                        />
                    }
                </div>

                {edited &&
                    <div className={style.fContent}>
                        <div
                            style={{
                                marginLeft: '3px',
                                marginRight: '3px'
                            }}
                        >
                            –
                        </div>

                        <div>
                            Edited
                        </div>

                        <Tooltip
                            title={`Edited ${dateFormat.timelineFormat(dateFormat.parseDate(this.props.data.modified))}`}
                            align='top'
                        />
                    </div>
                }
            </div>
        );
    }
}


class Note extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <UserThumb
                    owner={this.props.data.owner}
                />

                <div className={style.iContent}>
                    <div className={style.iNote}>
                        <Header
                            owner={this.props.data.owner}
                            related={this.props.data.related}
                        />

                        <div
                            className={style.nBody}
                            dangerouslySetInnerHTML={{ __html: htmlSanitizer.sanitize(this.props.data.note, true)}}
                        />
                    </div>

                    <Footer
                        data={this.props.data}
                        showPast={true}
                        showEdited={true}
                    />

                    <TagsContainer
                        tags={this.props.data.tags}
                    />
                </div>
            </div>
        );
    }
}


class TaskCompleted extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-checkmark3'
                    color='#43d350'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner.name}</div>
                        {' completed task '}
                    </div>

                    {this.props.data.params.description}

                    <Footer
                        data={this.props.data}
                        showRelated={true}
                    />
                </div>
            </div>
        );
    }
}


class ArchiveEmail extends React.Component {
    handleViewClick(ev) {
        ev.stopPropagation();
        this.props.showEmail(this.props.data);
    }

    render() {
        const params = this.props.data.params || {};
        let createdFrom = null;

        if (params.created_from_data_capture === 'true') {
            createdFrom = 'data';
        } else if (params.created_from_loan_capture === 'true') {
            createdFrom = 'loan';
        }

        let header = null;
        let content = null;

        if (createdFrom) {
            const description = ` created by email ${createdFrom} capture from `;
            let attachments = null;

            if (params.attachments_ids) {
                const len = params.attachments_ids.length;
                attachments = ` – ${len + (len === 1 ? ' file' : ' files')}`;
            }

            header = (
                <div className={style.iHeader}>
                    <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>

                    {description}

                    <div className={style.hBold}>{params.from_email}</div>

                    {attachments}

                    {' – '}

                    <div
                        className={style.iLink}
                        onClick={this.handleViewClick.bind(this)}
                    >
                        view
                    </div>
                </div>
            );
        } else {
            header = (
                <div className={style.iHeader}>
                    <div className={style.hBold}>{this.props.data.creator?.name}</div>

                    {' emailed '}

                    <div className={style.iLink}>
                        {params?.subject}
                    </div>

                    {' to '}

                    <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                </div>
            );

            // ...
            let description = this.props.data.note;

            if (Utils.isHTML(description)) {
                description = Utils.getTextFromHTMLContent(description);
            }

            description = Utils.replaceAll(description, '<br>', '');
            description = Utils.replaceAll(description, '↵', '');

            const maxDescriptionLen = 200;

            if (description.length > maxDescriptionLen) {
                var ellipsis = '...';
                description = description.substring(0, maxDescriptionLen - ellipsis.length) + ellipsis;
            }

            content = (
                <div className={style.iArchiveEmail}>

                    {description}

                    <span style={{color: '#c1c4cd'}}>{' – '}</span>

                    <div
                        className={style.iLink}
                        onClick={this.handleViewClick.bind(this)}
                    >
                        view
                    </div>
                </div>
            )
        }

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    {header}

                    {content}

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}


class ArchiveMessage extends React.Component {
    handleViewClick(ev) {
        ev.stopPropagation();
        this.props.showEmail(this.props.data);
    }

    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-campaign-sms'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' replied message'}
                    </div>

                    <div className={style.iText}>
                        {this.props.data.note}

                        <span style={{color: '#c1c4cd'}}>{' – '}</span>

                        <div
                            className={style.iLink}
                            onClick={this.handleViewClick.bind(this)}
                        >
                            view
                        </div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoCall extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-phone'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' called '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoEmail extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' emailed '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoMessage extends React.Component {
    handleViewClick(ev) {
        ev.stopPropagation();
        this.props.showEmail(this.props.data);
    }

    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-campaign-sms'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>

                        {' messaged – '}

                        {this.props.data.note}

                        {' to '}

                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>

                        {' – '}

                        <div
                            className={style.iLink}
                            onClick={this.handleViewClick.bind(this)}
                        >
                            view
                        </div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoEntityMerge extends React.Component {
    render() {
        const params = this.props.data.params;
        const entities = JSON.parse(params.entities);
        let source = [];

        for (let i = 0; i < entities.length - 1; ++i) {
            source.push(<div key={`e_${i}`} className={style.hBold}>{entities[i]}</div>);

            if (i < entities.length - 2) {
                source.push(<span key={`ec_${i}`}>, </span>);
            }
        }

        source.push(<span key='e_and'> and </span>);
        source.push(<div key={`e_${entities.length - 1}`} className={style.hBold}>{entities[entities.length - 1]}</div>);

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-merge2'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' merged '}
                        {source}
                        {' into '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoEntityImport extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-plus'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' was imported '}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoEntityCreation extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-plus'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' created '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoOpportunityCreation extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-plus'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' created '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' in '}
                        <div className={style.hBold}>{this.props.data.params?.funnel}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoOpportunityMovement extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-pencil'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' moved '}
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' from '}
                        <div className={style.hBold}>{this.props.data.params?.from}</div>
                        {' to '}
                        <div className={style.hBold}>{this.props.data.params?.to}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoOrganizationOpportunityDeletion extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-trashcan'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' deleted '}
                        <div className={style.hBold}>{this.props.data.params?.name}</div>
                        {' from '}
                        <div className={style.hBold}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoNewArchiveIndividual extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' created by Email Archive'}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoIndividualNewLeadEmailSent extends React.Component {
    render() {
        const note = this.props.data.note;
        const str = 'email notification to';
        const idx = note.indexOf(str);
        const newLeadOwner = note.substring(idx + str.length).trim();

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-plus'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.owner?.name}</div>
                        {' sent a new lead email notification to '}
                        <div className={style.hBold}>{newLeadOwner}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class ArchiveMailshotSent extends React.Component {
    handleViewClick(ev) {
        ev.stopPropagation();
        this.props.showEmail(this.props.data);
    }

    handleCampaignClick(ev) {
        ev.stopPropagation();
        window.location = `/#campaigns/drafts/preview/${this.props.data.params.campaign_id}`;
    }

    render() {
        const params = this.props.data.params || {};
        const campaignName = params.campaign_name || params.mailshot_name;
        const campaignType = params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : 'Direct';
        const from = this.props.data.owner?.name || params.from_name || '';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{from}</div>
                        {' sent '}
                        <div
                            className={style.iLink}
                            onClick={this.handleCampaignClick.bind(this)}
                        >
                            {campaignName}
                        </div>
                        {` ${campaignType} Email to `}
                        <div className={`${style.hBold} ${style.iTargetName}`}>{this.props.data.related?.name}</div>
                        {' – '}
                        <div
                            className={style.iLink}
                            onClick={this.handleViewClick.bind(this)}
                        >
                            view
                        </div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class ArchiveMailshotOpened extends React.Component {
    handleCampaignClick(ev) {
        ev.stopPropagation();
        window.location = `/#campaigns/drafts/preview/${this.props.data.params.campaign_id}`;
    }

    render() {
        const params = this.props.data.params || {};
        const campaignName = params.campaign_name || params.mailshot_name;
        const campaignType = params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : 'Direct';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.related?.name}</div>
                        {' opened '}
                        <div
                            className={style.iLink}
                            onClick={this.handleCampaignClick.bind(this)}
                        >
                            {campaignName}
                        </div>
                        {` ${campaignType} Email`}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class ArchiveMailshotUrlClicked extends React.Component {
    handleCampaignClick(ev) {
        ev.stopPropagation();
        window.location = `/#campaigns/drafts/preview/${this.props.data.params.campaign_id}`;
    }

    handleUrlClick(ev) {
        ev.stopPropagation();
        window.open(this.props.data.params.url, '_blank');
    }

    render() {
        const params = this.props.data.params || {};
        const campaignName = params.campaign_name || params.mailshot_name;
        const campaignType = params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : 'Direct';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={style.hBold}>{this.props.data.related?.name}</div>
                        {' clicked this link '}
                        <div
                            className={style.iLink}
                            onClick={this.handleUrlClick.bind(this)}
                        >
                            {params.url}
                        </div>
                        {' in '}
                        <div
                            className={style.iLink}
                            onClick={this.handleCampaignClick.bind(this)}
                        >
                            {campaignName}
                        </div>
                        {` ${campaignType} Email`}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class ArchiveMailshotBounce extends React.Component {
    handleCampaignClick(ev) {
        ev.stopPropagation();
        window.location = `/#campaigns/drafts/preview/${this.props.data.params.campaign_id}`;
    }

    render() {
        const params = this.props.data.params || {};
        const campaignName = params.campaign_name || params.mailshot_name;
        const campaignType = params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : 'Direct';
        const bounceType = this.props.data.activity_type.indexOf('soft') !== -1 ? 'soft' : 'hard';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div
                            className={style.iLink}
                            onClick={this.handleCampaignClick.bind(this)}
                        >
                            {campaignName}
                        </div>
                        {` ${campaignType} Email ${bounceType} bounced from`}
                        <div className={style.hBold}>{this.props.data.related?.name}</div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class ArchiveMailshotSpam extends React.Component {
    handleCampaignClick(ev) {
        ev.stopPropagation();
        window.location = `/#campaigns/drafts/preview/${this.props.data.params.campaign_id}`;
    }

    render() {
        const params = this.props.data.params || {};
        const campaignName = params.campaign_name || params.mailshot_name;
        const campaignType = params.campaign_type === 'campaign' ? TextManager.parseText('${ID_CAMPAIGN, capitalize}') : 'Direct';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-email'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' marked '}
                        <div
                            className={style.iLink}
                            onClick={this.handleCampaignClick.bind(this)}
                        >
                            {campaignName}
                        </div>
                        {` ${campaignType} Email as spam`}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoMailingListSubscriptions extends React.Component {
    handleClick(ev, ml) {
        ev.stopPropagation();

        if (!ml) {
            ml = this.props.data.params.mailing_lists[0];
        }

        window.location = `/#contacts/group/individuals/${ml.id}`;
    }

    render() {
        const params = this.props.data.params || {};

        if (params.mailing_lists.length === 1) {
            const list = params.mailing_lists[0];
            const action = list.subscribed ? 'subscribed to' : 'unsubscribed from';

            return (
                <div
                    className={style.cItem}
                    onClick={this.props.onClick}
                >
                    <IconThumb
                        icon='icon-mailing-list'
                        color='#c1c4cd'
                    />

                    <div className={style.iContent}>
                        <div className={style.iHeader}>
                            <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                            {` ${action} `}
                            <div
                                className={style.iLink}
                                onClick={this.handleClick.bind(this)}
                            >
                                {list}
                            </div>
                        </div>

                        <Footer
                            data={this.props.data}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-mailing-list'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' has updated Subscription Preferences '}

                        {params.mailing_lists.map(ml => {
                            const action = ml.subscribed ? 'subscribed to' : 'unsubscribed from'

                            return (
                                <div>
                                    <div
                                        className={style.iLink}
                                        onClick={(ev) => this.handleClick.bind(this)(ev, ml)}
                                    >
                                        {`${action} ${ml.name}`}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoMailingListUnsubscribedAll extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-mailing-list'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' unsubscribed from all emails '}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoMessagesUnsubscribedAll extends React.Component {
    render() {
        const action = this.props.data.params.unsubscribed_all ? 'unsubscribed from all messages' : 'has resubscribed to messages';

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-campaign-sms'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {` ${action}`}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutomationDebug extends React.Component {
    handleViewClick(ev) {
        ev.stopPropagation();
        this.props.showEmail(this.props.data);
    }

    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-bolt'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iNote}>
                        <div className={style.iText}>
                            {this.props.data.params?.automation2_name}
                        </div>

                        <span style={{color: '#c1c4cd'}}>{' – '}</span>

                        <div
                            className={style.iLink}
                            onClick={this.handleViewClick.bind(this)}
                        >
                            view
                        </div>
                    </div>

                    <Footer
                        data={this.props.data}
                        showPast={false}
                    />
                </div>
            </div>
        );
    }
}

class AutoWebsiteRegister extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-globe'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' registered on the website'}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class AutoWebTracking extends React.Component {
    handleClick(ev) {
        ev.stopPropagation();
        window.open(this.props.data.params.url, '_blank');
    }

    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-globe'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{this.props.data.related?.name}</div>
                        {' visited '}
                        <div
                            className={style.iLink}
                            onClick={this.handleClick.bind(this)}
                        >
                            {`${this.props.data.params.normalized_url}`}
                        </div>
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}


class ConversationMessage extends React.Component {
    render() {
        const params = this.props.data.params;

        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-chat'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        <div className={`${style.iTargetName} ${style.hBold}`}>{params.created_by_name}</div>
                    </div>

                    <div
                        className={style.nBody}
                        dangerouslySetInnerHTML={{ __html: htmlSanitizer.sanitize(this.props.data.note, true)}}
                    />

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}

class NoCustomRenderer extends React.Component {
    render() {
        return (
            <div
                className={style.cItem}
                onClick={this.props.onClick}
            >
                <IconThumb
                    icon='icon-warning'
                    color='#c1c4cd'
                />

                <div className={style.iContent}>
                    <div className={style.iHeader}>
                        {`[Warning: no custom renderer]: ${this.props.data.note}`}
                    </div>

                    <Footer
                        data={this.props.data}
                    />
                </div>
            </div>
        );
    }
}


class ActivitiesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activities: [],
            paginationInfo: null,
            loading: false,
            showingEmailViewerModel: null
        };

        this.fetchStart = 0;
    }

    componentDidMount() {
        this.handleFetchData();
    }

    handleFetchData() {
        this.setState({
            loading: true
        });

        // todo: implement local fetcher
        if (this.props.fetchDataFunction) {
            const self = this;

            this.props.fetchDataFunction(this.fetchStart, function(data, paginationInfo) {
                self.setState({
                    loading: false,
                    activities: data,
                    paginationInfo: paginationInfo
                });
            });
        }
    }

    handlePaginatorChange(fetchStart) {
        this.fetchStart = fetchStart;
        this.handleFetchData();
    }

    handleActivityClick(ev, activity) {
        const target = ev.target;

        if (target.nodeName === 'A') { // links inside a note
            return;
        }

        if (this.props.onActivityClick) {
            this.props.onActivityClick(activity);
        }
    }

    handleShowEmail(activity) {
        this.setState({
            showingEmailViewerModel: new Backbone.Model(activity)
        });
    }

    buildActivityComponents() {
        let activities = [];

        for (const activity of this.state.activities) {
            let Component = null;

            switch (activity.activity_type) {
                case 'note':
                case 'appointment:note':
                case 'task:note':
                    Component = Note;
                    break;

                case 'task:completed':
                    Component = TaskCompleted;
                    break;

                case 'archive:email':
                case 'archive:opportunity':
                    Component = ArchiveEmail;
                    break;

                case 'archive:message':
                    Component = ArchiveMessage;
                    break;

                case 'auto:call':
                    Component = AutoCall;
                    break;

                case 'auto:email':
                    Component = AutoEmail;
                    break;

                case 'auto:message':
                    Component = AutoMessage;
                    break;

                case 'auto:individual_creation':
                case 'auto:organization_creation':
                    Component = AutoEntityCreation;
                    break;

                case 'auto:opportunity_creation':
                    Component = AutoOpportunityCreation;
                    break;

                case 'auto:opportunity_movement':
                    Component = AutoOpportunityMovement;
                    break;

                case 'auto:organization_opportunity_deletion':
                    Component = AutoOrganizationOpportunityDeletion;
                    break;

                case 'auto:individual_import':
                case 'auto:organization_import':
                case 'auto:opportunity_import':
                    Component = AutoEntityImport;
                    break;

                case 'auto:individual_merge':
                case 'auto:organization_merge':
                    Component = AutoEntityMerge;
                    break;

                case 'auto:new_archive_individual':
                    Component = AutoNewArchiveIndividual;
                    break;

                case 'auto:individual_new_lead_email_sent':
                    Component = AutoIndividualNewLeadEmailSent;
                    break;

                case 'archive:mailshot_sent':
                case 'automation:archive:mailshot_sent':
                    Component = ArchiveMailshotSent;
                    break;

                case 'archive:mailshot_opened':
                case 'automation:archive:mailshot_opened':
                    Component = ArchiveMailshotOpened;
                    break;

                case 'archive:mailshot_url_clicked':
                case 'automation:archive:mailshot_url_clicked':
                    Component = ArchiveMailshotUrlClicked;
                    break;

                case 'archive:mailshot_soft_bounce':
                case 'automation:archive:mailshot_soft_bounce':
                case 'archive:mailshot_hard_bounce':
                case 'automation:archive:mailshot_hard_bounce':
                    Component = ArchiveMailshotBounce;
                    break;

                case 'archive:mailshot_spam':
                case 'automation:archive:mailshot_spam':
                    Component = ArchiveMailshotSpam;
                    break;

                case 'auto:mailing_list_subscriptions':
                    Component = AutoMailingListSubscriptions;
                    break;

                case 'auto:mailing_list_unsubscribed_all':
                    Component = AutoMailingListUnsubscribedAll;
                    break;

                case 'auto:messages_unsubscribed_all':
                    Component = AutoMessagesUnsubscribedAll;
                    break;

                case 'automation:debug':
                    Component = AutomationDebug;
                    break;

                case 'auto:website_register':
                    Component = AutoWebsiteRegister;
                    break;

                case 'auto:web_tracking':
                    Component = AutoWebTracking;
                    break;

                case 'conversation:message':
                    Component = ConversationMessage;
                    break;

                default:
                    Component = NoCustomRenderer;
                    break;
            }

            if (Component) {
                activities.push(
                    <Component
                        key={activity.id}
                        data={activity}
                        onClick={(ev) => this.handleActivityClick.bind(this)(ev, activity)}
                        showEmail={this.handleShowEmail.bind(this)}
                    />
                );
            }
        }

        return activities;
    }

    render() {
        const activityComponents = this.buildActivityComponents();

        return (
            <div
                className={style.activitiesList}
            >
                <Filters
                    filtersStateField={this.props.filtersStateField || 'dashboard_activity_filter'}
                    reqFetchData={this.handleFetchData.bind(this)}
                />

                <div className={style.alContent}>
                    {this.state.loading &&
                        <LoadingIndicator/>
                    }

                    {!this.state.loading && this.state.activities.length > 0 && activityComponents}

                    {!this.state.loading && this.state.activities.length === 0 &&
                        <div className={style.cNoItemsMessage}>No activity for current selection</div>
                    }
                </div>

                {!this.state.loading && this.state.activities.length > 0 &&
                    <div style={{padding: '0 15px'}}>
                        <Paginator
                            numRows={this.props.numRows}
                            paginationInfo={this.state.paginationInfo}
                            onChange={this.handlePaginatorChange.bind(this)}
                        />
                    </div>
                }

                {this.state.showingEmailViewerModel &&
                    <EmailViewer
                        model={this.state.showingEmailViewerModel}
                        close={() => {this.setState({showingEmailViewerModel: null})}}
                    />
                }
            </div>
        );
    }
}

export default ActivitiesList;
import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import quickButtonTemplate from 'templates/filters/quick/button.handlebars'
import quickGroupTemplate from 'templates/filters/quick/group.handlebars'
import guickInlineGroupTemplate from 'templates/filters/quick/inline-group.handlebars'
import quickLabelTemplate from 'templates/filters/quick/label.handlebars'
import quickIconTemplate from 'templates/filters/quick/icon.handlebars'


var BaseItemMixin = {
    tagName: 'li',
    iconTemplate: Handlebars.compile(quickIconTemplate),
    templateHelpers: function() {
        return {
            name: this.getName(),
            icon: this.getIcon()
        };
    },
    events: {
        'click > a': 'onClick'
    },
    ui: {
        item: '.filter-list-item',
        name: '.title',
        icon: '.icon',
        expandedInfo: '.expanded-info'
    },
    initialize: function(options) {
        _.extend(this, options);

        var extendedClass = this.model.get('extendedClass');

        if (extendedClass) {
            this.$el.addClass(extendedClass);
        }
    },
    onRender: function() {
        this.onSearchChange();
        this.updateActive();

        this.listenTo(this.model, 'change:disabled', this.onDisabledChange);
        this.listenTo(this.model, 'change:hidden', this.onHiddenChange);
        this.listenTo(this.model, 'change:name', this.onNameChange);
        this.listenTo(this.model, 'change:icon', this.onIconChange);
        this.listenTo(this.model, 'change:expandedInfo', this.onExpandedInfoChange);
        this.listenTo(this.model, 'change:search', this.onSearchChange);

        if (this.collection) {
            this.listenTo(this.collection, 'add reset', this.onCollectionChange);
        }

        this.$el.tooltip();
    },
    isActive: function() {
        var rule = this.model.results('rules');
        var activeRules = this.root.rules.toJSON();
        return _.find(activeRules, function(activeRule) {
            return _.isEqual(rule, activeRule);
        });
    },
    updateActive: function() {
        if (this.isActive()) {
            this.$el.addClass('active');
        } else {
            this.$el.removeClass('active');
        }
    },
    onNameChange: function() {
        this.ui.name.html(this.getName());
    },
    onIconChange: function() {
        this.ui.icon.html(this.getIcon());
    },
    onExpandedInfoChange: function() {
        if (this.ui.expandedInfo) {
            var info = this.getExpandedInfo();

            if (info) {
                this.ui.expandedInfo.html(info);
                this.ui.item.addClass('has-expanded-info');
            }
            else {
                this.ui.expandedInfo.html('');
                this.ui.item.removeClass('has-expanded-info');
            }
        }
    },
    onHiddenChange: function() {
        var hidden = this.model.results('hidden');
        if (hidden) {
            this.$el.hide();
        } else {
            this.$el.show();
        }
    },
    isDisabled: function() {
        return this.model.results('disabled') || this.isActive();
    },
    onDisabledChange: function() {
        var disabled = this.isDisabled();
        if (disabled) {
            this.ui.item.addClass('disabled');
        } else {
            this.ui.item.removeClass('disabled');
        }
    },
    onSearchChange: function() {
        this.onHiddenChange();
        this.onDisabledChange();
        this.onNameChange();
        this.onIconChange();
        this.onExpandedInfoChange();
    },
    onCollectionChange: function() {
        this.trigger('filter-item:on-collection');

        var search = this.model.get('search');
        this.collection.each(function(model) {
            model.set('search', search);
        });
        this.onHiddenChange();
    },
    getName: function() {
        var name = this.model.results('name');
        if (name) {
            var search = this.model.get('search');
            var nameHtml = this.model.results('nameHtml');
            if (!nameHtml && search) {
                var regex = new RegExp('(' + search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + ')', "ig");
                name = name.replace(regex, '<em style="font-style: normal; font-weight: bold;">\$1</em>');
            }
        }
        return name;
    },
    getIcon: function() {
        var icon = this.model.results('icon');
        if (icon) {
            var iconHtml = this.model.results('iconHtml');
            if (!iconHtml) {
                icon = this.iconTemplate(icon);
            }
        }
        return icon;
    },
    getExpandedInfo: function() {
        return this.model.results('expandedInfo');
    },
    onClick: function(ev) {
        var click = this.model.get('click');
        if (click && !this.isDisabled()) {
            click.call(this.model, ev, this);
        }
    }
};

var ButtonView = Marionette.ItemView.extend(_.defaults({
    template: Handlebars.compile(quickButtonTemplate)
}, BaseItemMixin));

var AngleButtonView = ButtonView.extend({
    templateHelpers: function() {
        return {
            showAngle: true
        };
    }
});

var LabelView = Marionette.ItemView.extend(_.defaults({
    template: Handlebars.compile(quickLabelTemplate)
}, BaseItemMixin));

var GroupView = Marionette.CompositeView.extend(_.defaults({
    template: Handlebars.compile(guickInlineGroupTemplate),
    templateHelpers: function() {
        return {
            name: this.model.results('name'),
            parent: this.parent,
            inline: this.inline
        };
    },
    itemViewContainer: '.popover-list',
    itemViewOptions: function(model) {
        return {
            group: this,
            root: this.root
        };
    },
    getItemView: function(model) {
        return model && model.get('view');
    },
    ui: {
        name: '.title',
        icon: '.icon',
        seperator: '.basic-list-item-separator'
    },
    events: {
        'click > a': 'onClick',
        'click > ul > li > a.group-header': 'onHeaderClick'
    },
    initialize: function(options) {
        _.extend(this, options);

        this.inline = this.model.results('inline') || this.isRootModel();
        if (this.inline) {
            this.collection = this.model.results('items');

            _.defer(function() {
                this.trigger('filter-item:on-collection');
            }.bind(this));
        }
    },
    onShow: function() {
        this.updateSeperator();
    },
    onClick: function(ev) {
        ev.preventDefault();

        if (!this.inline) {
            var click = this.model.get('click');
            if (click) {
                click.call(this.model, ev, this);
            } else {
                this.root.setContent(this.model, this.root.contentModel);
                this.root.parent.ui.input.val(null);
                this.root.parent.ui.input.trigger('input');
            }
        }
    },
    onHeaderClick: function(ev) {
        ev.preventDefault();

        if (this.parent) {
            this.root.setContent(this.parent);
        }
    },
    onSearchChange: function() {
        if (this.collection) {
            var search = this.model.get('search');
            this.collection.each(function(model) {
                model.set('search', search);
            }.bind(this));
        }

        this.onHiddenChange();
        this.onNameChange();
        this.onIconChange();
        this.onExpandedInfoChange();
    },
    onHiddenChange: function() {
        var hidden;
        if (this.inline) {
            hidden = this.collection.every(function(model) {
                return model.results('hidden');
            }.bind(this));
        } else {
            hidden = this.model.results('hidden');
        }

        if (hidden) {
            this.$el.hide();
        } else {
            this.$el.show();
        }

        this.updateSeperator();
    },
    isRootModel: function() {
        return this.root.contentModel === this.model;
    },
    updateSeperator: function() {
        var collection = this.model.collection;
        var show = collection &&
                   !this.isRootModel() &&
                   this.children.length !== 0 &&
                   collection.indexOf(this.model) !== (collection.length - 1) ||
                   false;

        this.ui.seperator.toggle(show);
    }
}, BaseItemMixin));

var SeperatorView = Marionette.ItemView.extend(_.defaults({
    tagName: 'hr',
    className: 'basic-list-item-separator',
    template: Handlebars.compile('')
}, BaseItemMixin));

var EmptyGroupView = Marionette.ItemView.extend(_.defaults({
    tagName: 'li',
    className: 'empty-group',
    template: Handlebars.compile(`
        <div>Add up to 8 custom</div>
        <div>quick filters in</div>
        <div>App Settings</div>
    `)
}, BaseItemMixin));

export default {
    BaseItemMixin: BaseItemMixin,
    ButtonView: ButtonView,
    AngleButtonView: AngleButtonView,
    GroupView: GroupView,
    EmptyGroupView: EmptyGroupView,
    LabelView: LabelView,
    SeperatorView: SeperatorView
};

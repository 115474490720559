import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import d3 from 'd3'

import backboneSelect2 from 'js/widgets/backbone-select2'
import PhasesCollection from 'js/collections/phases'
import LoadingView from 'js/views/loading'
import TextManager from 'app/text-manager'
import D3LeadQualityChart from 'js/d3/leadqualitychart'
import leadQualityTemplate from 'templates/lead_quality.handlebars'


export default Marionette.Layout.extend({
    className: 'lead-quality-widget widget at-top',
    template: Handlebars.compile(leadQualityTemplate),
    ui: {
        xAxisMetric: '.xaxis-select',
        chartContainer: '.chart-container',
        header: '.widget-header',
        footer: '.widget-footer',
        scroll: '.content-container > .content',
        content: '.content-container > .content'
    },

    events: {
        'change .xaxis-select': function() {
            var sel = this.ui.xAxisMetric.select2('val');

            this.inWinLossMode = (sel === 'win-loss');
            this.fetchGroupsData();
        },

        'group_highlighted': function(w, g) {
            this.trigger('group_highlighted', this, g);
        },

        'group_selected': function(w, g) {
            this.trigger('group_selected', this, g);
        },

        'click .widget-header .expand': 'toggleExpand',

        'click .widget-header .back': 'toggleExpand',

        'resize': 'scrollbar'
    },

    regions: {
        loaderRegion: '.loader-container'
    },

    initialize: function(options) {
        this.chart = new D3LeadQualityChart();
        this.groups = options.groups;
        this.inWinLossMode = true;
        this.data = [];
    },

    renderWidget: function() {
        if (this.isClosed) {
            return;
        }

        this.chart.toggleLabels(true);

        if (this.expanding) {
            this.chart.expand(false);
        }
        else {
            this.chart.expand(this.expanded);
        }

        this.chart.width(this.ui.chartContainer.width());

        this.chart.data(this.data);

        d3.select(this.ui.chartContainer.get(0)).call(this.chart);

        this.scrollbar();
    },

    onRender: function() {
        this.showLoader();

        var view = this;

        // phases
        this.phases = new PhasesCollection();
        this.phases.fetch({
            success: function() {
                view.initPhasesSelectBox(view.phases);
                view.fetchGroupsData();
            }
        });

        this.ui.scroll
            .scroll(this.scrollEvents.bind(this))
            .scrollLock(); // Prevent scroll propagation

        // Resize chart on window resize
        this.resizeCallback = function () {
            if (view.data) {
                _.defer(function() {
                    view.renderWidget();
                }.bind(view));
            }
        };
        $(window).on('resize', this.resizeCallback);
    },

    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },

    onGroupsDataChanged: function(newGroups) {
        this.chart.empty();
        this.groups = newGroups;
        this.fetchGroupsData();
    },

    onGroupHighlighted: function(group) {
        if (this.data) {
            this.chart.highlightSingleBar(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    onGroupSelected: function(group) {
        if (this.data) {
            this.chart.selectSingleBar(d3.select(this.ui.chartContainer.get(0)), group);
        }
    },

    fetchGroupsData: function() {
        if (!this.groups) {
            return;
        }

        // ...
        var view = this;
        var data = {
            win_lost: this.inWinLossMode,
            groups: this.groups
        };

        if (!this.inWinLossMode) {
            data.phase_id = this.ui.xAxisMetric.select2('val');
        }

        $.post(
            '/widgets/lead_quality',
            JSON.stringify(data),
            function(data) {
                if (!view.isClosed) {
                    view.hideLoader();
                    view.onGroupSelected(null);
                    view.data = data.data;
                    view.renderWidget();
                }
            }
        );
    },

    initPhasesSelectBox: function(phases) {
        var data = phases.toJSON();
        var wonPhase = _.findWhere(data, {phase_type: 'won'});
        var fields = [
            {
                name: TextManager.getText('ID_CLOSED_DEALS'),
                description: TextManager.getText('ID_CLOSED_DEALS_DESCRIPTION', [wonPhase.name]),
                children: [
                    {
                        id: 'win-loss',
                        name: wonPhase.name
                    }
                ]
            },
            {
                name: TextManager.getText('ID_DEALS_IN_FUNNELS'),
                description: TextManager.getText('ID_DEALS_IN_FUNNELS_DESCRIPTION'),
                children: phases.getAsHierarchy()
            }
        ];

        function formatResult(item) {
            return Handlebars.compile(
                item.name +
                '<div class="description">' + (item.description || '') + '</div>'
            );
        }

        // ...
        this.xAxisMetricSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.xAxisMetric,
            data: fields,
            value: fields[0].children[0],
            options: {
                containerCssClass: 'select2-block select2-plain',
                dropdownCssClass: 'xaxis-select-popover popover has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },

    toggleExpand: function() {
        // Prevent double expanding - breaks chain of animation
        if (this.expanding) {
            return;
        }

        if (!this.expanded) {
            this.trigger('group_selected', this, null);
        }

        this.expanding = true;
        this.renderWidget();

        // Once all parent containers have animated
        function afterParentExpand() {
            this.expanding = false;
            this.expanded = !this.expanded;
            this.renderWidget();
        }

        this.trigger('expand', this, afterParentExpand.bind(this));
    },

    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            footerHeight = this.ui.footer.is(':visible') ? this.ui.footer.height() : 0,
            availableHeight = (this.$el.height() - this.ui.header.height() - footerHeight),
            innerHeight = container.find('.content-inner:first').height(),
            height = Math.min(innerHeight, availableHeight);

        container.css('maxHeight', height);
        this.$el.toggleClass('has-sticky-nav', (innerHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),

    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    },

    showLoader: function() {
        this.ui.content.hide();
        this.loaderRegion.show( new LoadingView() );
    },

    hideLoader: function() {
        this.ui.content.show();
        this.loaderRegion.reset();
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import guid from 'js/utils/guid'
import AppConfig from 'app/app-config'
import GroupModel from 'js/models/group'
import GroupsCollection from 'js/collections/groups'
import sidebarFolderViewTemplate from 'app/contacts/sidebar-folder.handlebars'


var FolderContainerView = Marionette.CompositeView.extend({
    template: Handlebars.compile(''),
    getItemView: function(model) {
        return this.options.itemView;
    },
    itemViewOptions: function(model) {
        return {
            parent: this.options.parent,
            sidebar: this.options.sidebar
        };
    },
});

var SidebarFolderView = Marionette.Layout.extend({
    className: 'folder draggable',
    template: Handlebars.compile(sidebarFolderViewTemplate),
    templateHelpers: function() {
        return {
            name: this.model.get('name')
        };
    },
    regions: {
        container: '.folder-container'
    },
    ui: {
        container: '.folder-container',
        folderHeader: '.folder-header',
        expandedIcon: '.icon-caret-right',
        displayName: '.display-name',
        title: '.title',
        editName: '.edit-name',
        sortableAreaTop: '.sortable-area.top',
        sortableAreaBottom: '.sortable-area.bottom'
    },
    events: {
        'click .folder-header': function(ev) {
            this.toggleFolderVisibility(true);
        },
        'click .edit-icon': function(ev) {
            ev.stopPropagation();
            this.gotoEditable(true);
        },
        'blur .edit-name': function(ev) {
            if (!this.model.get('name')) {
                this.model.set('name', 'Group Folder');

                if (this.options.clubbing.options.onFolderNameChanged) {
                    this.options.clubbing.options.onFolderNameChanged();
                }
            }

            this.gotoEditable(false);
        },
        'keydown .edit-name': function(ev) {
            if (ev.keyCode === 13) {
                ev.preventDefault();

                var input = $(ev.target);

                if (input.val() !== this.model.get('name')) {
                    this.model.set('name', input.val() || 'Group Folder');

                    if (this.options.clubbing.options.onFolderNameChanged) {
                        this.options.clubbing.options.onFolderNameChanged();
                    }
                }

                this.gotoEditable(false);
            }
        }
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    onRender: function() {
        var self = this;

        if (!self.model.get('disableDraggable')) {
            this.$el.draggable({
                handle: '.item-handle',
                axis: 'y',
                helper: 'clone',
                start: function(event, ui) {
                    // disable sortable areas and container in the helper element
                    ui.helper.find('.sortable-area.top').hide();
                    ui.helper.find('.sortable-area.bottom').hide();
                    ui.helper.find('.folder-container').hide();
                    ui.helper.find('.icon-caret-right').removeClass('expanded');

                    // disable sortable areas in groups inside folders
                    self.options.clubbing.EnableSortableAreasOnGroupsInsideFolders(false);

                    // close the folder
                    self.ui.expandedIcon.removeClass('expanded');
                    self.ui.container.hide();
                },
                stop: function(event, ui) {
                    self.options.clubbing.EnableSortableAreasOnGroupsInsideFolders(true);
                }
            });
        } else {
            this.$el.removeClass('draggable');
            this.$el.find('.item-handle').hide();
        }

        this.$el.find('.folder-droppable-area').droppable({
            accept: '.item',
            tolerance: 'pointer',
            over: function(event, ui) {
                self.options.clubbing.requestDroppableActivation(self.ui.folderHeader, true);
            },
            out: function(event, ui) {
                self.options.clubbing.droppableDeactivation(self.ui.folderHeader, true);
            },
            drop: function(event, ui) {
                if (self.options.clubbing.dropValid(self.ui.folderHeader, true)) {
                    self.options.clubbing.droppableDeactivationAll();
                    self.options.clubbing.insertToFolder(self.model.get('id'), ui.draggable.attr('id'), ui.draggable.attr('folder'));
                }
            }
        });

        // top area is only visible for the first element on root or folder
        if (this.options.parent.collection.indexOf(this.model) === 0) {
            this.options.clubbing.initSortableArea(this.ui.sortableAreaTop, this.model, self.options.clubbing, true);
        }
        else {
            this.ui.sortableAreaTop.hide();
        }

        this.options.clubbing.initSortableArea(this.ui.sortableAreaBottom, this.model, self.options.clubbing, false);

        // ...
        this.container.show(new FolderContainerView({
            collection: this.model.get('groups'),
            parent: this.options.parent,
            sidebar: this.options.sidebar,
            itemView: this.options.clubbing.options.itemView
        }));

        if (!this.model.get('open')) {
            this.toggleFolderVisibility();
        }

        if (!this.model.get('name')) {
            _.defer(function() {
                self.gotoEditable(true);
            });
        }

        this.$el.find('.folder-header').tooltip();
    },
    gotoEditable: function(editable) {
        if (editable) {
            this.ui.displayName.addClass('editing');
            this.ui.editName.addClass('editing');
            this.ui.folderHeader.addClass('editing');
            this.ui.editName.val(this.model.get('name'));
            this.ui.editName.focus();
        }
        else {
            this.ui.displayName.removeClass('editing');
            this.ui.editName.removeClass('editing');
            this.ui.folderHeader.removeClass('editing');
            this.ui.title.text(this.model.get('name'));
            this.$el.find('.folder-header').attr('title', this.model.get('name')).tooltip('fixTitle');
        }
    },
    toggleFolderVisibility: function(triggerEvent) {
        if (this.ui.folderHeader.hasClass('editing')) {
            return;
        }

        if (this.ui.expandedIcon.hasClass('expanded')) {
            this.ui.expandedIcon.removeClass('expanded');
            this.ui.container.hide();
            this.model.set('open', false);
        }
        else {
            this.ui.expandedIcon.addClass('expanded');
            this.model.set('open', true);
            this.ui.container.show();
        }

        if (triggerEvent && this.options.clubbing.options.onFolderStateChanged) {
            this.options.clubbing.options.onFolderStateChanged();
        }
    }
});

export default function(options) {
    this.options = options;
    this.SidebarFolderView = SidebarFolderView;
    this.activeDroppables = {};

    this.manageCollection = function(collection, blueprint) {
        this.options.rootCollection = this.decodeCollection(collection, blueprint);
        return this.options.rootCollection;
    };

    this.decodeCollection = function(collection, blueprint) {
        var self = this;
        var newCollection = new GroupsCollection();

        if (blueprint) {
            var modelGroups = {};

            _.each(blueprint, function(m) {
                if (m.name) { // if it has name it is a folder
                    var groups = [];

                    _.each(m.groups, function(g) {
                        var mg = collection.get(g);
                        var mgid = mg ? mg.get('id') : null;

                        if (mgid && !(mgid in modelGroups)) {
                            mg.set('folder', m.id);
                            groups.push(mg);
                            modelGroups[mgid] = mg;
                        }
                    });

                    if (groups.length > 0) {
                        var folder = new GroupModel({
                            id: m.id,
                            name: m.name,
                            special_type: 'folder',
                            element_type: self.options.elementType,
                            open: m.open,
                            disableDraggable: m.disableDraggable,
                            groups: new Backbone.Collection(groups)
                        });

                        newCollection.add(folder, {silent: true});
                    }
                }
                else {
                    var mc = collection.get(m);
                    var mcid = mc ? mc.get('id') : null;

                    if (mcid && !(mcid in modelGroups)) {
                        newCollection.add(mc, {silent: true});
                        modelGroups[mcid] = mc;
                    }
                }
            });

            // are there new groups that are not in the blueprint already?
            _.each(collection.models, function(m) {
                if (!modelGroups[m.get('id')]) {
                    newCollection.add(m, {at: 0, silent: true});
                }
            });
        }
        else {
            _.each(collection.models, function(m) {
                newCollection.add(m, {silent: true});
            });
        }

        this.sortCollection(newCollection);

        return newCollection;
    };

    this.sortCollection = function(collection) {
        const orderBy = AppConfig.getValue(`${this.options.elementType}.groups.order_by`);

        if (orderBy) {
            for (const model of collection.models) {
                if (model.isFolder()) {
                    let groups = model.get('groups');

                    groups.models = _.sortBy(groups.models, (m) => m.get(orderBy.field).toLowerCase());

                    if (orderBy.dir === 'desc') {
                        groups.models.reverse();
                    }
                }
            }

            collection.models = _.sortBy(collection.models, (model) => model.get(orderBy.field).toLowerCase());

            if (orderBy.dir === 'desc') {
                collection.models.reverse();
            }
        }

        // fix group order
        collection.models = _.sortBy(collection.models, (model) => model.get('disableDraggable') ? - 1 : 1);
    }

    this.encodeCollection = function() {
        var rdo = [];
        var collection = this.options.rootCollection;

        _.each(collection.models, function(m) {
            if (m.isFolder()) {
                var folder = {
                    id: m.get('id'),
                    name: m.get('name'),
                    open: m.get('open'),
                    groups: []
                };

                _.each(m.get('groups').models, function(g){
                    folder.groups.push(g.get('id'));
                });

                rdo.push(folder);
            }
            else {
                rdo.push(m.get('id'));
            }
        });

        return rdo
    };

    this.dropValid = function(droppable, folderCreation) {
        if (folderCreation) {
            return droppable.is(this.activeDroppables.folderCreation);
        }

        return !this.activeDroppables.folderCreation && droppable.is(this.activeDroppables.sort);
    };

    this.droppableDeactivationAll = function() {
        if (this.activeDroppables.folderCreation) {
            this.activeDroppables.folderCreation.removeClass('droppable-hover');
            this.activeDroppables.folderCreation = null;
        }

        if (this.activeDroppables.sort) {
            this.activeDroppables.sort.addClass('invisible');
            this.activeDroppables.sort = null;
        }
    };

    this.droppableDeactivation = function(droppable, folderCreation) {
        if (folderCreation) {
            if (droppable.is(this.activeDroppables.folderCreation)) {
                this.activeDroppables.folderCreation = null;
                droppable.removeClass('droppable-hover');

                if (this.activeDroppables.sort) {
                    this.activeDroppables.sort.removeClass('invisible');
                }
            }
        }
        else if (droppable.is(this.activeDroppables.sort)) {
            this.activeDroppables.sort = null;
            droppable.addClass('invisible');
        }
    };

    this.requestDroppableActivation = function(droppable, folderCreation) {
        // folder creation has more priority that sort
        if (folderCreation) {
            if (this.activeDroppables.sort) {
                this.activeDroppables.sort.addClass('invisible');
            }

            if (this.activeDroppables.folderCreation) {
                this.activeDroppables.folderCreation.removeClass('droppable-hover');
            }

            this.activeDroppables.folderCreation = droppable;
            droppable.addClass('droppable-hover');
        }
        else {
            if (this.activeDroppables.sort) {
                this.activeDroppables.sort.addClass('invisible');
            }

            this.activeDroppables.sort = droppable;

            if (!this.activeDroppables.folderCreation) {
                droppable.removeClass('invisible');
            }
        }
    };

    this.EnableSortableAreasOnGroupsInsideFolders = function(enable) {
        var self = this;

        _.each(this.options.rootCollection.models, function(m) {
            if (m.isFolder()) {
                _.each(m.get('groups').models, function(sb) {
                    var elem = $(self.options.view.$el.find('#' + sb.get('id')));
                    var areas = [elem.find('.sortable-area.top'), elem.find('.sortable-area.bottom')];

                    _.each(areas, function(a) {
                        if (enable) {
                            if (a.hasClass('disable-by-folder-movement')) {
                                a.removeClass('disable-by-folder-movement');
                                a.droppable('enable');
                            }
                        }
                        else {
                            if (a.is(':visible')) {
                                a.addClass('disable-by-folder-movement');
                                a.droppable('disable');
                            }
                        }
                    });
                });
            }
        });
    };

    this.initItem = function(item) {
        var self = this;

        item.$el.addClass('draggable');

        if (!item.ui) {
            item.ui = {};
        }

        item.ui.sortableAreaTop = item.$el.find('.sortable-area.top');
        item.ui.sortableAreaBottom = item.$el.find('.sortable-area.bottom');
        item.$el.attr('folder', item.model.get('folder'));

        if (!item.model.get('folder') || ['my_groups', 'other_groups'].includes(item.model.get('folder'))) {
            var element = item.$el.find('.basic-list-item');

            item.$el.find('.droppable-area').droppable({
                accept: '.item',
                tolerance: 'pointer',
                over: function(event, ui) {
                    item.parent.clubbing.requestDroppableActivation(element, true);
                },
                out: function(event, ui) {
                    item.parent.clubbing.droppableDeactivation(element, true);
                },
                drop: function(event, ui) {
                    if (item.parent.clubbing.dropValid(element, true)) {
                        item.parent.clubbing.droppableDeactivationAll();
                        self.createFolderByDrop(item.model.get('id'), ui.draggable.attr('id'), item.model.get('folder'), ui.draggable.attr('folder'));
                    }
                }
            });
        }

        // ...
        item.$el.draggable({
            handle: '.item-handle',
            helper: 'clone',
            axis: 'y',
            start: function(event, ui) {
                // disable sortable areas in the helper element
                ui.helper.find('.sortable-area.top').hide();
                ui.helper.find('.sortable-area.bottom').hide();
            }
        });

        // top area is only visible for the first element on root or folder
        var folderId = item.model.get('folder');
        var parentFolder = folderId ? item.parent.collection.get(folderId) : null;

        if ((item.parent.collection.indexOf(item.model) === 0) ||
            (parentFolder && (parentFolder.get('groups').at(0) === item.model))) {
            this.initSortableArea(item.ui.sortableAreaTop, item.model, this, true);
        }
        else {
            item.ui.sortableAreaTop.hide();
        }

        this.initSortableArea(item.ui.sortableAreaBottom, item.model, this, false);
    };

    this.initSortableArea = function(uiElement, model, clubbing, top) {
        var sortableItem = uiElement.find('.sortable-item-placeholder');

        uiElement.droppable({
            accept: '.draggable',
            hoverClass: 'droppable-hover',
            tolerance: 'touch',
            over: function(event, ui) {
                clubbing.requestDroppableActivation(sortableItem, false);
            },
            out: function(event, ui) {
                clubbing.droppableDeactivation(sortableItem, false);
            },
            drop: function(event, ui) {
                if (clubbing.dropValid(sortableItem, false)) {
                    clubbing.droppableDeactivationAll();
                    clubbing.moveItemTo(ui.draggable.attr('id'), ui.draggable.attr('folder'), model.get('id'), model.get('folder'), top);
                }
            }
        });
    };

    this.moveItemTo = function(itemId, itemFolderId, dropId, dropFolderId, onTop) {
        // Prevent drag-and-drop into "My Groups" and "Other Groups"
        if ((itemFolderId !== dropFolderId && ['my_groups', 'other_groups'].includes(dropFolderId)) ||
            (['my_groups', 'other_groups'].includes(itemFolderId) && !dropFolderId)) {
            return;
        }

        var fromCollection = itemFolderId ? this.options.rootCollection.get(itemFolderId).get('groups') : this.options.rootCollection;
        var toCollection = this.options.rootCollection;
        var itemModel = fromCollection.get(itemId);
        var activeItem = this.options.view.$el.find('.item.active');
        var activeItemId = activeItem ? activeItem.attr('id') : null;

        // the item is inside a folder?
        if (itemFolderId)
        {
            // move inside the folder
            if (itemFolderId === dropFolderId) {
                toCollection = fromCollection;
            }
            else if (dropFolderId) { // move to another folder
                toCollection = this.options.rootCollection.get(dropFolderId).get('groups');
                itemModel.set('folder', dropFolderId);
            }
            else { // move to root
                itemModel.set('folder', null);
            }
        }
        else if (dropFolderId) { // move inside a folder
            toCollection = this.options.rootCollection.get(dropFolderId).get('groups');
            itemModel.set('folder', dropFolderId);
        }

        // move the item to before or after (depends of onTop) drop area
        var idx = 0;

        for (var i = 0; i < toCollection.length; ++i) {
            var mid = toCollection.models[i].get('id');

            if (mid === dropId) {
                if (!onTop) {
                    ++idx;
                }
                break;
            }
            else if (mid !== itemId) {
                ++idx;
            }
        }

        fromCollection.remove(itemModel, {silent: true});
        toCollection.add(itemModel, {at: idx, silent: true});

        // the origin folder is empty?
        if (itemFolderId && fromCollection.length === 0) {
            this.options.rootCollection.remove(itemFolderId, { silent: true });
        }

        if (this.options.onItemMoved) {
            this.options.onItemMoved();
        }

        this.setItemActive(activeItemId);
    };

    this.createFolderByDrop = function(dropId, dragId, dropFolderId, dragFolderId) {
        var dragCollection = dragFolderId ? this.options.rootCollection.get(dragFolderId).get('groups') : this.options.rootCollection;
        var dropCollection = dropFolderId ? this.options.rootCollection.get(dropFolderId).get('groups') : this.options.rootCollection;
        var modelDrop = dropCollection.get(dropId);
        var modelDrag = dragCollection.get(dragId);
        var activeItem = this.options.view.$el.find('.item.active');
        var activeItemId = activeItem ? activeItem.attr('id') : null;

        var folder = new GroupModel({
            id: guid(),
            groups: new Backbone.Collection([modelDrag, modelDrop]),
            special_type: 'folder',
            element_type: this.options.elementType,
            open: true
        });

        modelDrop.set('folder', folder.id);
        modelDrag.set('folder', folder.id);

        var idx = 0;

        // insert folder in the destination (position for dropId)
        if (['my_groups', 'other_groups'].includes(dragFolderId)) {
            // Position the new folder either below "Other Groups" or "My Groups" (whichever is lower)
            idx = this.options.rootCollection.models.findIndex(item => item.get('id') === 'other_groups');
            if (idx === -1) {
                idx = this.options.rootCollection.models.findIndex(item => item.get('id') === 'my_groups');
            }
            idx++;
        } else {
            for (var i = 0; i < this.options.rootCollection.length; ++i) {
                if (this.options.rootCollection.models[i].get('id') === dropId) {
                    idx = i;
                    break;
                }
            }
        }

        this.options.rootCollection.add(folder, { at: idx, silent: true });
        dropCollection.remove(modelDrop, { silent: true });
        dragCollection.remove(modelDrag, { silent: true });

        // the origin folder is empty?
        if (dragFolderId && dragCollection.length === 0) {
            this.options.rootCollection.remove(dragFolderId, { silent: true });
        }

        if (this.options.onFolderCreated) {
            this.options.onFolderCreated();
        }

        this.setItemActive(activeItemId);
    };

    this.insertToFolder = function(dropId, dragId, dragFolderId)
    {
        // Prevent manual addition into "My Groups" and "Other Groups"
        if (['my_groups', 'other_groups'].includes(dropId)) {
            return;
        }

        var dragCollection = dragFolderId ? this.options.rootCollection.get(dragFolderId).get('groups') : this.options.rootCollection;
        var dropCollection = this.options.rootCollection.get(dropId).get('groups');
        var modelDrag = dragCollection.get(dragId);
        var activeItem = this.options.view.$el.find('.item.active');
        var activeItemId = activeItem ? activeItem.attr('id') : null;

        dragCollection.remove(modelDrag, {silent: true});

        if (dragFolderId && (dragCollection.length === 0)) {
            this.options.rootCollection.remove(dragFolderId, {silent: true});
        }

        modelDrag.set('folder', dropId);
        dropCollection.add(modelDrag, {at: 0, silent: true});

        if (this.options.onItemInsertedToFolder) {
            this.options.onItemInsertedToFolder();
        }

        this.setItemActive(activeItemId);
    };

    this.setItemActive = function(itemId) {
        if (!itemId || this.options.autoActivateDisabled) {
            return;
        }

        this.options.view.$el.find('#' + itemId).addClass('active');
    };
};

import $ from 'jquery';
import React from 'react';
import Backbone from 'backbone'

import vent from 'js/vent';
import {Calendar} from 'js/react_views/calendar/calendar';


export default class GroupCalendar extends React.Component {
    constructor(props) {
        super(props);

        this.dataDirty = true;
        this.filterId = props.sharedFilterManager.filterId;
        this.firstShow = true;

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData(callback) {
        this.dataDirty = false;

        let url = '/appointments';
        let args = {};

        this.filterId = this.props.sharedFilterManager.filterId;

        if (this.filterId) {
            args.filter_id = this.props.sharedFilterManager.filterId;
        }

        const fetchArgs = this.props.group.fetchArgs || {};
        for (const arg of ['status', 'tag_ids', 'funnel_ids', 'upcoming']) {
            if (arg in fetchArgs) {
                args[arg] = fetchArgs[arg];
            }
        }

        $.get(`${url}?${$.param(args)}`, function(result) {
            callback(result.map(i => new Backbone.Model(i)));
        });
    }

    handleEditAppointment(appointment) {
        vent.trigger('quick:edit:appointment', appointment.get('id'));
    }

    handleCreateAppointment(date) {
        vent.trigger('quick:add:appointment', {
            startDate: date
        });
    }

    setDataDirty() {
        this.dataDirty = true;

        if (this.state.visible) {
            this.calendar.fetchData();
        }
    }

    showPanel(panelId, showArgs) {
        switch (panelId) {
            case 'entity':
                this.props.showEntityPanel(showArgs);
                break;

            case 'advancedFilter':
                this.props.showAdvancedFilterPanel(this.props.entityType, this.props.filters, this.props.sharedFilterManager.getRules());
                break;
        }
    }

    setFilterRules(rules) {
        this.props.sharedFilterManager.setRules(rules);
        this.props.onFiltersChange(this.props.sharedFilterManager, [], this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.calendar.fetchData();

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    deleteFilterRule(filter) {
        this.props.sharedFilterManager.setRule(filter.id, filter.custom, null);
        this.props.onFiltersChange(this.props.sharedFilterManager, [], this.props.filters);

        const self = this;

        this.props.sharedFilterManager.createFilter(function(filterId) {
            self.calendar.fetchData();

            self.props.updateGroup(self.props.group, {
                filterId: filterId
            });
        });
    }

    deleteAllFiltersRules() {
        this.props.sharedFilterManager.setRules(null);
        this.props.onFiltersChange(this.props.sharedFilterManager, [], this.props.filters);

        this.calendar.fetchData();

        this.props.updateGroup(this.props.group, {
            filterId: null
        });
    }

    show() {
        this.setState({
            visible: true
        });

        if (this.firstShow) {
            this.firstShow = false;
            this.calendar.scrollToWorkingHour();
        }

        const dirty = this.dataDirty || (this.filterId !== this.props.sharedFilterManager.filterId);

        if (dirty) {
            this.calendar.fetchData();
        }
    }

    hide() {
        this.setState({
            visible: true
        });
    }

    setCalendarDate(date) {
        this.calendar.setCurrentDate(date);
    }

    setCalendarSpan(span) {
        this.calendar.setSpan(span);
    }

    setCalendarDateSpan(date, span) {
        this.calendar.setDateSpan(date, span);
    }

    render() {
        return (
            <div
                style={{
                    height: '100%',
                    position: 'relative',
                    display: this.state.visible ? 'block' : 'none'
                }}
            >
                <Calendar
                    ref={(el) => this.calendar = el}
                    visible={true}
                    date={this.props.date}
                    span={this.props.span}
                    entityType='appointments'
                    fetchDataFunction={this.fetchData.bind(this)}
                    onDateSpanChange={this.props.changeDateSpan}
                    onEditAppointment={this.handleEditAppointment.bind(this)}
                    onCreateAppointment={this.handleCreateAppointment.bind(this)}
                />
            </div>
        );
    }
}

import $ from 'jquery'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import UserModel from 'js/models/user'
import app from 'js/app'
import vent from 'js/vent'
import loginTemplate from 'templates/login_cognito.handlebars'
import api from 'js/api'
import Utilities from 'js/utils/utilities'

//Generate a Random String
const getRandomString = () => {
    const randomItems = new Uint32Array(28);
    crypto.getRandomValues(randomItems);
    const binaryStringItems = randomItems.map(dec => `0${dec.toString(16).substr(-2)}`)
    return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
}

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async str => {
    const PROTOCOL = 'SHA-256'
    const textEncoder = new TextEncoder();
    const encodedData = textEncoder.encode(str);
    return crypto.subtle.digest(PROTOCOL, encodedData);
}

//Convert Hash to Base64-URL
const hashToBase64url = arrayBuffer => {
    const items = new Uint8Array(arrayBuffer)
    const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
    const decodedHash = btoa(stringifiedArrayHash)

    const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64URL;
}

const createPopup = ({
    url, title, height, width,
}) => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    return window.open(
        url,
        title,
        `width=${width},height=${height},left=${left},top=${top}`,
    );
};

function delay(duration){
    return new Promise(function(resolve){
        setTimeout(resolve, duration);
    });
}

const retry = async (func, duration) => {
    const stop = await func();
    if (!stop) {
        delay(duration).then(() => retry(func, duration));
    }
}

const OauthPopup = ({
    title = '',
    width = 500,
    height = 500,
    url,
    redirectUrl,
}) => {
    const popup = createPopup({
        url, title, width, height,
    });
    return new Promise((resolve, reject) => {
        return retry(() => {
            if (popup.closed) {
                reject('Closed');
                return true;
            }

            try {
                const currentUrl = popup.location.origin + popup.location.pathname;
                if (currentUrl === redirectUrl) {
                    const params = new URLSearchParams(popup.location.search);
                    const code = params.get('code');
                    if (code) {
                        resolve(params);
                        return true;
                    }

                    const error = params.get('error');
                    if (error) {
                        reject(error);
                        return true;
                    }
                }
                return false;
            } catch (error) {
                return false;
            }
        }, 200);
    }).finally(() => {
        if (!popup.closed) {
            popup.close();
        }
    });
};

const CognitoLoginView = BaseView.extend({
    tagName: 'article',
    id: 'login-container',
    template: Handlebars.compile(loginTemplate),
    templateHelpers: function() {
        const logo = app.options.clientDetails.logo;
        let logoSrc = null;

        if (logo) {
            if (logo.ext === '.svg') {
                $.ajax({
                    url: logo.url,
                    type: 'GET',
                    async: false,
                    success: function (data) {
                        logoSrc = `data:image/svg+xml;base64,${btoa(data)}`;
                    }
                });
            }
            else {
                logoSrc = logo.url;
            }
        }

        return {
            error: this.error,
            logoSrc: logoSrc,
            formClass: logoSrc ? 'with-logo' : ''
        };
    },
    ui: {
        'name': 'legend h3 span',
    },
    events: {
        "click #login"      : "login",
    },
    initialize: function () {
        BaseView.prototype.initialize.apply(this, arguments);
    },
    onRender: function(){
        var view = this,
            options = app.options;

        if (options.clientDetails.name) {
            view.ui.name.text(options.clientDetails.name);
        }
    },
    login: async function(ev) {
        ev.preventDefault();
        
        const { domain, client_id, redirect_uri } = app.options.clientDetails.cognito;

        const next = this.options.cameFrom?.replace(/^\/+/, '');
        const state = getRandomString();
        const code_verifier = getRandomString();
        const code_challenge = hashToBase64url(await encryptStringWithSHA256(code_verifier));
        const result = await OauthPopup({
            url: `https://${domain}/oauth2/authorize?response_type=code&state=${encodeURIComponent(state)}&client_id=${encodeURIComponent(client_id)}&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=${encodeURIComponent('openid email profile')}&code_challenge_method=S256&code_challenge=${encodeURIComponent(code_challenge)}`,
            redirectUrl: new URL(redirect_uri).href, 
        });
        const error = result.get('error')
        if (error) {
            this.error = error;
            this.render();
            return;
        }

        const code = result.get('code');
        if (!code) {
            this.error = 'Something went wrong';
            this.render();
            return;
        } else if (result.get('state') !== state) {
            this.error = 'Something went wrong';
            this.render();
            return;
        }

        const response = await fetch(`https://${domain}/oauth2/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'authorization_code',
                client_id: client_id,
                code_verifier: code_verifier,
                redirect_uri: redirect_uri,
                code: code,
            }),
        });
        if (!response.ok) {
            this.error = 'Something went wrong';
            this.render();
            return;
        }
    
        const token = await response.json();
        localStorage.setItem('cognito_access_token', token.access_token);
        localStorage.setItem('cognito_id_token', token.id_token);
        localStorage.setItem('cognito_refresh_token', token.refresh_token);
        localStorage.setItem('cognito_expires_at', new Date().getTime() + (token.expires_in * 1000));

        Utilities.setCookie('salesseek', `cognito:${token.id_token}`, token.expires_in);

        api.getAuthenticatedUser(null, (user) => {
            if (user) {
                app.user = new UserModel(user);
            }

            if (user) {
                vent.trigger('login:success', next);
            } else {
                this.error = 'Unable to login';
                this.render();
            }
        });
    },
    getUrl: function () {
        var result = 'login';
        if (this.options.cameFrom) {
            result += (
                '?came_from=' +
                encodeURIComponent(this.options.cameFrom)
                );
        }
        return result;
    }
});

export default CognitoLoginView;
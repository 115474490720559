import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2'
import CustomFieldModel from 'js/models/custom_field'
import CustomFieldsCollection from 'js/collections/custom_fields'
import NoCollectionView from 'js/views/base/no_collection'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import Currency from 'js/utils/currency'
import CustomFieldsItemsView from 'js/views/custom_fields_items'
import viewTemplate from 'templates/custom_fields/view.handlebars'


var FieldItemModel = Backbone.Model.extend({});


var FieldsItemCollection = Backbone.Collection.extend( {
    model: FieldItemModel,

    comparator: function(model) {
        return model.get('order');
    }
} );


var CustomFieldsView = Marionette.CompositeView.extend( {
    template: Handlebars.compile( viewTemplate ),
    itemViewContainer: '.custom-fields-list',

    getItemView: function( item ) {
        var type = '';

        if ( item ) {
            type = item.get( 'type' );
        }

        return CustomFieldsItemsView.getItemView( type );
    },

    initialize: function() {
        this.wantEdit = false;
        this.collectionDefines = null;

        this.listenTo(this, 'after:item:added item:removed', function() {
            //this.$el.trigger('resize');
        });
    },

    onDataLoaded: function( options ) {
        var view = this;

        this.changes = {};
        this.model = options.model;
        this.collectionDefines = options.collectionDefines;
        this.collection  = this.model.isNew() ? this.getFieldsToEdit() : this.getFieldsToDisplay();

        this.listenTo( this, 'itemview:editing:valueChanged', function( ev, params ) {
            view.changes[ params.key ] = params.value;
            view.trigger('editing:valueChanged');
        } );

        if ( this.wantEdit || options.editing ) {
            this.onStartEditing();
        }

        this.render();
    },

    onStartEditing: function() {
        if ( this.collectionDefines ) {
            this.changes = {};
            this.collection = this.getFieldsToEdit();
            this.render();
            this.wantEdit = false;
        }
        else {
            this.wantEdit = true;
        }
    },

    onEndEditing: function() {
        if ( !this.wantEdit ) {
            this.collection = this.getFieldsToDisplay();
            this.render();
        }
    },

    clearEmptyFields: function() {
        var newDict = {};

        $.each( this.changes, function( key, value ) {
            if ( value ) {
                newDict[ key ] = value;
            }
        } );

        this.changes = newDict;
    },

    hasChanges: function() {
        return ( !$.isEmptyObject( this.changes ) );
    },

    getChanges: function() {
        if ( this.hasChanges() ) {
            // although only one value has changed we have to save all values as dictionary
            this.addPreviousValuesAsChanges();
            //this.clearEmptyFields(); // with the default values now is necessary send all values
        }

        return this.changes;
    },

    valuesAreValid: function() {
        for (var i = 0; i < this.collection.length; ++i ) {
            var item = this.children.findByModel(this.collection.models[i]);

            if (item.getErrorMsg()) {
                return false;
            }
        }

        return true;
    },

    showInvalidFieldsError: function() {
        for (var i = 0; i < this.collection.length; ++i ) {
            var item = this.children.findByModel(this.collection.models[i]);
            var msg = item.getErrorMsg();
            if (msg) {
                item.showError(msg);
            } else {
                item.hideError();
            }
        }
    },

    addPreviousValuesAsChanges: function() {
        for ( var i = 0; i < this.collection.length; ++i ) {
            var item = this.collection.models[ i ];
            var id = item.get( 'id' );
            var value = item.get( 'value' );

            if ( this.changes[ id ] || !value ) {
                continue;
            }

            this.changes[ id ] = value;
        }
    },

    fillExistingFields: function( define, values, fields, forEdit ) {
        if ( !values ) {
            return;
        }

        define.each(function(model) {
            var id = model.get('id');

            var value = values[id];
            if (value) {
                var type = model.get('type');
                var params = model.get('params') || {};

                if (!forEdit || (!('editable_from_ui' in params)) || params.editable_from_ui) {
                    // value is the value that user set to the custom field
                    // valueDef is the default value of the CF and/or the list of options if it is a dropdown
                    fields.add( new FieldItemModel( {
                        id: id,
                        name: model.get('name'),
                        type: type,
                        value: value,
                        valueDef: model.get('value'),
                        order: model.get('order'),
                        required: model.get('required'),
                        params: params
                    } ) );
                }
            }
        });
    },

    fillMissingFields: function( define, values, fields) {
        define.each(function(model) {
            var id = model.get('id');

            if (!values || !values[id]) {
                var params = model.get('params') || {};

                if ((!('editable_from_ui' in params)) || params.editable_from_ui) {
                    // value is the value that user set to the custom field
                    // valueDef is the default value of the CF and/or the list of options if it is a dropdown
                    fields.add( new FieldItemModel( {
                        id: id,
                        name: model.get('name'),
                        type: model.get('type'),
                        value: '',
                        valueDef: model.get('value'),
                        order: model.get('order'),
                        required: model.get('required'),
                        params: params
                    } ) );
                }
            }
        });
    },

    fillDefaultFields: function( define, fields) {
        fields.each(function(fieldModel) {
            var id = fieldModel.get('id');
            var defineModel = define.get(id);
            if (!defineModel) return;

            var value = defineModel.get('value');
            if (value && value['default']) {
                var def = value['default'];

                fieldModel.set('value', def);
                this.changes[id] = def;
            }
        }.bind(this));
    },

    getFieldsToDisplay: function() {
        // get the fields that are defined in database and filled them with the values from individual
        var fields = new FieldsItemCollection();
        this.fillExistingFields( this.collectionDefines, this.model.get( 'custom_fields' ), fields );

        return fields;
    },

    getFieldsToEdit: function() {
        // get the fields that are defined in database and filled them with the values from individual
        var cf = this.model.get( 'custom_fields' );
        var fields = new FieldsItemCollection();

        this.fillMissingFields( this.collectionDefines, cf, fields );

        if ( this.model.isNew() ) {
            this.fillDefaultFields( this.collectionDefines, fields );
        }
        else {
            this.fillExistingFields( this.collectionDefines, cf, fields, true );
        }

        return fields;
    }
} );

export default CustomFieldsView;

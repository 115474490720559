import filterOperators from 'js/views/filters/operators'

export default function() {
    var fields = [
        {
            'id': 'activity_created',
            'name': 'Created',
            'operators': filterOperators.date,
            'group': 'Activity'
        },
        {
            'id': 'activity_creator_id',
            'name': 'Creator',
            'operators': filterOperators.user,
            'group': 'Activity'
        },
        {
            'id': 'activity_text',
            'name': 'Text',
            'operators': filterOperators.string,
            'group': 'Activity'
        },
        {
            'id': 'activity_related_id',
            'name': 'Related To',
            'operators': filterOperators.related,
            'group': 'Activity'
        }
    ];

    return fields;
};

import React from 'react';
import classnames from 'classnames';

import style from './drag-and-drop-overlay.css';

class DragAndDropOverlay extends React.Component {
    render() {
        return (
            <div className={style.DragAndDropOverlay}>
                <div className={style.inner}>
                    <i className={classnames(style.icon, "icon-arrow-up")} />
                    <span className={style.text}>
                        Drop files anywhere<br />on this section
                    </span>
                    <span className={style.additionalInfo}>
                        They will appear<br />in Related Files
                    </span>
                </div>
            </div>
        );
    }
}

export default DragAndDropOverlay;

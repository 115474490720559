import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import ItemPermissionsView from 'js/views/item_permissions';
import CampaignPreview from 'app/content/campaigns/_components/campaign-preview/campaign-preview';
import CampaignModel from 'js/models/campaign';
import CampaignSummarySheet from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-summary';
import dateFormat from 'js/utils/date-format';
import vent from 'js/vent';
import SectionBase from 'app_v2/sections/base/section';
import campaignFilters from './filters';
import FinderContentManager from 'app_v2/sections/finder_content_manager';
import CampaignsFetcher from 'app_v2/sections/base/campaigns_fetcher';
import GroupPageFetcher from 'app_v2/sections/base/group_page_fetcher';
import MailingListsFetcher from 'app_v2/sections/base/mailing_lists_fetcher';
import PerformanceWidget from './performance_widget';
import CampaignCreation from 'app_v2/dialogs/campaign_creation/campaign_creation';
import { getAllColumns, getDefaultColumnsIds } from './table_columns';
import getIndividualFilters from 'app_v2/sections/individuals/filters';
import ModalRegion from 'js/views/base/modal-region';
import AppConfig from 'app/app-config';


class ComposeFetcher {
    constructor(allColumns, defaultColumns) {
        this.campaignsFetcher = new CampaignsFetcher(50, []);
        this.mailingListsFetcher = new MailingListsFetcher(allColumns.audiences.filter(c => defaultColumns.audiences.indexOf(c.id) !== -1));
        this.groupPageFetcher = new GroupPageFetcher('individuals', 50, defaultColumns.audienceGroup);

        this.mailingListsFetcher.setFetchArgs({
            include_on_fly_groups: false
        });

        this.setCampaignsFetcher();
    }

    setCampaignsFetcher() {
        this.activeFetcher = this.campaignsFetcher;
        this.activeFetcherStr = 'campaigns';
    }

    setMailingListsFetcher() {
        this.activeFetcher = this.mailingListsFetcher;
        this.activeFetcherStr = 'mailingLists';
    }

    setGroupPageFetcher() {
        this.activeFetcher = this.groupPageFetcher;
        this.activeFetcherStr = 'groupPage';
    }

    setColumns(columns) {
        this.activeFetcher.setColumns(columns);
    }

    searchBy(searchTerm) {
        this.activeFetcher.searchBy(searchTerm);
    }

    getSearchTerm() {
        return this.activeFetcher.getSearchTerm();
    }

    orderBy(columnId, sortDir) {
        this.activeFetcher.orderBy(columnId, sortDir);
    }

    getOrderBy() {
        return this.activeFetcher.getOrderBy();
    }

    filterBy(filterId) {
        this.activeFetcher.filterBy(filterId);
    }

    setSectionId(sectionId) {
        this.activeFetcher.setSectionId(sectionId);
    }

    setFetchArgs(fetchArgs) {
        this.activeFetcher.setFetchArgs(fetchArgs);
    }

    getFetchArgs() {
        return this.activeFetcher.getFetchArgs() || {}
    }

    deletePerformanceWidgetFilters() {
        const filtersKeys = ['filter_by_date_field', 'filter_by_begin_date', 'filter_by_end_date'];

        for (const fetcher of [this.campaignsFetcher, this.groupPageFetcher]) {
            let fetchArgs = fetcher.getFetchArgs() || {};
            const numKeys = Object.keys(fetchArgs).length;

            for (const f of filtersKeys) {
                delete fetchArgs[f];
            }

            if (Object.keys(fetchArgs) !== numKeys) {
                fetcher.setFetchArgs(fetchArgs);
            }
        }
    }

    fetch(page, callback) {
        const activeFetcherStr = this.activeFetcherStr;
        const self = this;

        this.activeFetcher.fetch(page, function(data) {
            if (activeFetcherStr === self.activeFetcherStr) {
                callback(data);
            }
        });
    }
}


export default class CampaignReactView extends React.Component {
    constructor(props) {
        super(props);

        this.allColumns = getAllColumns();
        this.defaultColumns = getDefaultColumnsIds();
        this.individualFilters = getIndividualFilters();
        this.campaignFilters = _.clone(campaignFilters);

        this.composeFetcher = new ComposeFetcher(this.allColumns, this.defaultColumns);
        this.finderContentManager = new FinderContentManager();
        this.fixedSelectedGroup = null;
        this.finderGroupsByType = {};
        this.newCampaignRegion = null;
        this.previewCampaignRegion = null;
        this.performanceWidgetCounter = 0;
    }

    componentDidMount() {
        this.mounted = true;
        this.createFinderStructure(this.props.initialGroupId);

        const self = this;

        if (this.props.initialSubGroupId) {
            switch (this.props.initialGroupId) {
                case 'audiences':
                    $.get(`/groups/${this.props.initialSubGroupId}`, function(group) {
                        const groupInfo = self.composeFetcher.mailingListsFetcher.createGroupInfo(group);
                        self.handleRowClick(groupInfo.id, groupInfo);
                    });
                    break;

                default:
                    self.initialSubGroupId = this.props.initialSubGroupId;
                    break;
            }
        }

        if (this.props.id) {
            // it can be editing/previewing the campaign
            if (this.props.initialGroupId === 'insights' || this.props.initialGroupId === 'automations') {
                this.showCampaignPreviewDialog(this.props.id);
            } else {
                this.handleEditCampaign(this.props.id);
            }
        }

        if (this.props.contactEmailTemplating || this.props.initialGroupId === 'new') {
            this.showCampaignCreationDialog(null, this.props.contactEmailTemplating);
        }

        // ...
        this.props.parent.listenTo(vent, 'campaign:created campaign:updated', function() {
            self.sectionComponent.refresh();
        });
    }

    componentWillUnmount() {
        this.mounted = false;
        this.newCampaignRegion?.reset();
        this.previewCampaignRegion?.reset();
    }

    createFinderStructure(activeGroupId) {
        this.finderContentManager.clear();

        let content = [
            this.finderContentManager.createSpecialGroup('dashboard', 'Dashboard', 'icon-gauge', {
                defaultView: 'campaignsDashboard'
            })
        ];

        if (AppConfig.getValue('campaigns.user_type', 'advance') === 'advance') {
            content.push(
                this.finderContentManager.createSpecialGroup('audiences', 'Audiences', 'icon-mailing-list', {
                    actions: {
                        new: true,
                        delete: true
                    },
                    toolbar: {
                        saveAs: false,
                        search: true,
                        advancedFilter: false,
                        editColumns: false
                    }
                })
            );
        }

        content.push(
            this.finderContentManager.createSpecialGroup('drafts', 'Drafts', 'icon-email', {
                fetchArgs: {
                    order_by: 'modified desc',
                    status: 'ready,draft,rejected',
                    campaign_subtype: 'campaign',
                    extended: true
                },
                actions: {
                    new: true
                }
            })
        );

        if (AppConfig.getValue('campaigns.user_type', 'advance') === 'advance') {
            content = content.concat([
                this.finderContentManager.createSpecialGroup('templates', 'Templates', 'icon-email', {
                    fetchArgs: {
                        order_by: 'modified desc',
                        status: 'ready,draft',
                        campaign_subtype: 'template',
                        extended: true
                    },
                    actions: {
                        new: true
                    }
                }),

                this.finderContentManager.createSpecialGroup('automations', 'Automation Emails', 'icon-automated-email', {
                    fetchArgs: {
                        order_by: 'modified desc',
                        campaign_subtype: 'automation',
                        extended: true
                    },
                    actions: {
                        new: true
                    }
                })
            ]);
        }

        if (app.user.get('is_helper')) {
            content.push(
                this.finderContentManager.createSpecialGroup('system', 'System Emails', 'icon-email', {
                    fetchArgs: {
                        order_by: 'modified desc',
                        campaign_subtype: 'system',
                        extended: true
                    },
                    actions: {
                        new: true
                    }
                })
            );
        }

        if (AppConfig.getValue('campaigns.step_approval.enabled')) {
            content.push(
                this.finderContentManager.createSpecialGroup('approvals', 'Approval Required', 'icon-campaign-approval-required', {
                    fetchArgs: {
                        order_by: 'modified desc',
                        status: 'waiting_for_approval',
                        extended: true
                    },
                    badge: function(callback) {
                        $.get('/campaigns?rows=0&status=waiting_for_approval', function(_, __, request) {
                            callback(parseInt(request.getResponseHeader('Records-Total')));
                        });
                    }
                })
            );
        }

        content = content.concat([
            this.finderContentManager.createSpecialGroup('scheduled', 'Scheduled', 'icon-clock', {
                fetchArgs: {
                    order_by: 'modified desc',
                    status: 'scheduled',
                    extended: true
                }
            }),
            this.finderContentManager.createSpecialGroup('insights', 'Campaign Insights', 'icon-stats', {
                fetchArgs: {
                    order_by: 'modified desc',
                    status: 'sent',
                    search_not: 'template',
                    extended: true
                }
            })
        ]);

        // initialize finder content manager
        let sections = [{
            id: 'campaigns',
            entityType: 'campaigns',
            name: 'Campaigns',
            content: content
        }];

        const finderExpanded = !activeGroupId;
        const section = sections[0];
        section.content = [...section.content];

        this.finderContentManager.addSections(sections, 0);

        // initial group (we should always display the dashboard)
        activeGroupId = activeGroupId || 'dashboard';

        const activeGroupExists = !!this.finderContentManager.getElement(activeGroupId);

        this.finderContentManager.setActiveGroupId(activeGroupExists ? activeGroupId : 'dashboard');
        this.sectionComponent.onContentManagerUpdated(finderExpanded);
    }

    handleGroupSave(groupId, group) {
        const activeGroup = this.finderContentManager.getActiveGroup();

        if (activeGroup.id === 'audiences') {
            const newGroup = this.finderContentManager.createGroup(group);
            newGroup.entityType = 'individuals';

            this.activeGroup = newGroup;
            this.sectionComponent.handleFinderGroupSelect(newGroup);
        } else {
            const self = this;

            this.showFinderGroups('campaigns', true, function() {
                const activeGroupId = `groups/${groupId}`;
                const activeGroupExists = !!self.finderContentManager.getElement(activeGroupId);

                self.finderContentManager.setActiveGroupId(activeGroupExists ? activeGroupId : 'dashboard');
                self.sectionComponent.onContentManagerUpdated();
            });
        }
    }

    handleGroupPreSelect(group) {
        this.composeFetcher.deletePerformanceWidgetFilters();

        if (group.id === 'audiences') {
            this.composeFetcher.setMailingListsFetcher();
        } else if (group.entityType === 'individuals') {
            this.composeFetcher.setGroupPageFetcher();
        } else {
            this.composeFetcher.setCampaignsFetcher();
        }

        /*
            the finder in campaigns works in a different way. In other sections, all the groups in the finder are more or less the same, some are
            'fixed' (or system) groups, others are groups created by the users. Each group has its own configuration. But in campaigns the 'regular' groups
            (the ones created by the users) depends of a 'fixed' group. For instance, if I click 'drafts', the 'regular' groups should use the
            same fetch arguments that 'drafts'. But if I click 'schedule' and then click in the same group of the example above, event it is the
            same group the result is different, because now its 'parent' is 'scheduled' instead 'drafts'
        */
        if (group.fixed) {
            this.fixedSelectedGroup = group;
        }

        this.selectedGroup = group;

        if (group.entityType === 'individuals') {
            let tableColumns = [];

            if (group.dynamic) {
                for (const dc of this.defaultColumns.audienceGroup) {
                    tableColumns.push(this.allColumns.audienceGroup.find(c => c.id === dc));
                }
            }

            this.sectionComponent.setTableColumnsInfo(tableColumns, this.allColumns.audienceGroup, this.defaultColumns.audienceGroup);
            this.sectionComponent.setFiltersInfo('individuals', this.individualFilters);
            this.sectionComponent.setTableSelectableRows(group.groupType === 'static');
            this.sectionComponent.setActionsBarInfo('individuals', {
                buttons: {
                    new: false,
                    delete: false,
                    edit: false,
                    merge: false,
                    add: group.groupType === 'static',
                    remove: group.groupType === 'static'
                },
                addEntitySearchArgs: null
            });

            if (!group.dynamic) {
                const showPermissions = app.user.get('client').permission_type !== 'rba';

                let menuItems = [{
                    id: 'editGroup',
                    title: 'Edit Group',
                    args: {
                        groupId: group.shortId,
                        entityType: 'individuals'
                    }
                }];

                if (showPermissions) {
                    menuItems.push({
                        id: 'permissionsGroup',
                        title: 'Permissions Group',
                        args: {
                            groupId: group.shortId,
                            entityType: 'individuals'
                        }
                    });
                }

                menuItems = menuItems.concat([{
                    id: 'separator'
                }, {
                    id: 'deleteGroup',
                    title: 'Delete Group',
                    args: {
                        groupId: group.shortId,
                        groupName: group.name
                    }
                }]);

                this.sectionComponent.setToolbarInfo({
                    moreOptionsItems: menuItems
                });
            }
        } else {
            // the columns for the table depends of the fixed selected group.
            let groupIdForColumns = group.fixed ? group.id : this.fixedSelectedGroup.id;

            if (groupIdForColumns in this.allColumns) {
                const allColumns = this.allColumns[groupIdForColumns];
                const defaultColumns = this.defaultColumns[groupIdForColumns];
                let tableColumns = [];

                for (const cid of defaultColumns) {
                    tableColumns.push(allColumns.find(c => c.id === cid));
                }

                this.composeFetcher.setColumns(tableColumns);
                this.sectionComponent.setTableColumnsInfo(tableColumns, allColumns, defaultColumns);
            }

            this.sectionComponent.setFiltersInfo('campaigns', this.campaignFilters);
            this.sectionComponent.setTableSelectableRows(false);
        }

        if (group.fixed) {
            this.sectionComponent.setToolbarInfo({
                moreOptionsItems: null
            });

            // the 'regular' groups visualization depends of the 'fixed' group (some 'fixed' groups don't show the 'regular' groups)
            switch (group.id) {
                case 'drafts':
                case 'scheduled':
                    this.showFinderGroups('campaigns');
                    this.sectionComponent.setFinderCreateOptions({
                        createGroups: true
                    });
                    break;

                case 'insights':
                    this.showFinderGroups('insights');
                    this.sectionComponent.setFinderCreateOptions({
                        createGroups: true
                    });
                    break;

                default:
                    this.hideFinderGroups();
                    this.sectionComponent.setFinderCreateOptions({
                        createGroups: false
                    });
                    break;
            }
        } else if (group.entityType !== 'individuals') {
            // the 'regular' group should use the same fetch arguments that its 'parent' fixed group
            if (group.id !== 'insights_automated' && group.id !== 'insights_templated') {
                group.fetchArgs = _.extend(group.fetchArgs || {}, _.clone(this.fixedSelectedGroup.fetchArgs));
            }

            let addEntitySearchArgs = null;

            switch (this.fixedSelectedGroup?.id) {
                case 'drafts':
                    addEntitySearchArgs = { status: 'ready,draft' };
                    break;

                case 'scheduled':
                    addEntitySearchArgs = { status: 'scheduled' };
                    break;

                case 'insights':
                    addEntitySearchArgs = { status: 'sent' };
                    break;
            }

            this.sectionComponent.setActionsBarInfo('campaigns', {
                buttons: {
                    new: false,
                    delete: false,
                    edit: false,
                    merge: false,
                    add: group.groupType === 'static',
                    remove: false
                },
                addEntitySearchArgs: addEntitySearchArgs
            });

            if (group.dynamic) {
                this.sectionComponent.setToolbarInfo({
                    moreOptionsItems: null
                });
            } else {
                this.sectionComponent.setToolbarInfo({
                    moreOptionsItems: [{
                        id: 'editGroup',
                        title: 'Edit Group',
                        args: {
                            groupId: group.shortId,
                            entityType: 'campaigns'
                        }
                    }, {
                        id: 'permissionsGroup',
                        title: 'Permissions Group',
                        args: {
                            groupId: group.shortId,
                            entityType: 'campaigns'
                        }
                    }, {
                        id: 'shareNowGroup',
                        title: 'Share Now',
                        args: {
                            groupId: group.shortId
                        }
                    }, {
                        id: 'separator'
                    }, {
                        id: 'deleteGroup',
                        title: 'Delete Group',
                        args: {
                            groupId: group.shortId,
                            groupName: group.name
                        }
                    }]
                });
            }
        }

        // some groups display a custom widget above the table
        let renderer = null;
        let height = 0;

        if (group.id === 'insights' || this.fixedSelectedGroup.id === 'insights') {
            renderer = this.performanceWidgetRenderer.bind(this);
            height = 217;
        }

        this.sectionComponent.setCustomRendererTop(renderer, height);
        this.sectionComponent.setGroupClosable(group.entityType === 'individuals');
    }

    showCampaignCreationDialog(campaign, contactEmailTemplating) {
        const regionType = ModalRegion.extend({ backdrop: 'static', keyboard: false });

        this.newCampaignRegion = this.props.parent.addRegion('newCampaign', { selector: 'new-campaign', regionType: regionType });
        this.newCampaignRegion.show(new CampaignCreation({
            campaignData: campaign,
            contactEmailTemplating: contactEmailTemplating,
            onCloseGoto: this.props.onCloseGoto
        }));

        vent.trigger('AppContent:contentChange');
    }

    showCampaignPreviewDialog(campaignId) {
        const self = this;

        $.get(`/campaigns/${campaignId}`, function(campaign) {
            if (!self.mounted) {
                return;
            }

            const regionType = ModalRegion.extend({ backdrop: 'static', keyboard: false });

            self.previewCampaignRegion = self.props.parent.addRegion('previewCampaign', { selector: 'preview-campaign', regionType: regionType });
            self.previewCampaignRegion.show(new CampaignPreview({
                model: new CampaignModel(campaign)
            }));

            vent.trigger('AppContent:contentChange');
        });
    }

    showCampaignPermissions(campaign) {
        const region = this.props.parent.addRegion('permissions', { selector: 'acl-region', regionType: ModalRegion });
        const ipv = new ItemPermissionsView({
            model: new CampaignModel({id: campaign.id, permissions: campaign.permissions})
        });

        region.show(ipv);
    }

    showCampaignSummary(campaignId) {
        const self = this;

        $.get(`/campaigns/${campaignId}`, function(campaign) {
            if (!self.mounted) {
                return;
            }

            const region = self.props.parent.addRegion('summary', { selector: 'campaign-summary', regionType: ModalRegion });
            const summaryView = new CampaignSummarySheet({
                campaign: new CampaignModel(campaign)
            });

            region.show(summaryView);
        });
    }

    duplicateCampaign(campaignId) {
        const self = this;

        $.get(`/campaigns/${campaignId}`, function(campaign) {
            if (!self.mounted) {
                return;
            }

            let name = campaign.name;
            const copyExists = name.indexOf('copy');

            if (copyExists !== -1) {
                name = name.substring(0, copyExists - 1);
            }

            name += ` copy ${dateFormat.formatDDMMYYYYhhmmssDate(new Date())}`;

            const model = new CampaignModel({
                ...campaign,
                id: null,
                name: name,
                owner: null,
                owner_id: null
            });

            model.duplicate(function() {
                self.sectionComponent.fetchTablePage(0);
            });
        });
    }

    deleteCampaign(campaignId) {
        const model = new CampaignModel({id: campaignId});
        const self = this;

        model.destroy({
            wait: true,
            success: function() {
                self.sectionComponent.fetchTablePage(0);
            }
        });
    }

    cancelCampaign(campaignId) {
        const self = this;

        $.get(`/campaigns/${campaignId}`, function(campaign) {
            if (!self.mounted) {
                return;
            }

            const model = new CampaignModel(campaign);

            model.cancel(function() {
                self.sectionComponent.fetchTablePage(0);
            });
        });
    }

    handleCreateCampaign(campaignSubType) {
        this.showCampaignCreationDialog({
            campaign_subtype: campaignSubType || 'campaign'
        });
    }

    handleEditCampaign(campaignId) {
        const self = this;

        $.get(`/campaigns/${campaignId}`, function(result) {
            self.showCampaignCreationDialog(result);
        });
    }

    handleRowClick(rowId, row) {
        this.activeGroup = this.finderContentManager.getActiveGroup();

        if (this.activeGroup.id === 'audiences') {
            if (row.group) {
                this.sectionComponent.handleFinderGroupSelect(row.group);
                this.activeGroup = row.group;
            } else {
                window.location.href = `/#individuals/${rowId}`;
            }
        } else {
            if (this.fixedSelectedGroup?.id === 'insights' || (this.fixedSelectedGroup?.id === 'automations' && row.automation)) {
                this.showCampaignPreviewDialog(rowId);
            } else {
                this.handleEditCampaign(rowId);
            }
        }
    }

    handleRowContext(row, itemId) {
        switch (itemId) {
            case 'edit':
                this.handleEditCampaign(row.id);
                break;

            case 'permissions':
                this.showCampaignPermissions(row);
                break;

            case 'preview':
                this.showCampaignPreviewDialog(row.id);
                break;

            case 'duplicate':
                this.duplicateCampaign(row.id);
                break;

            case 'delete':
                this.deleteCampaign(row.id);
                break;

            case 'cancel':
                this.cancelCampaign(row.id);
                break;

            case 'summary':
                this.showCampaignSummary(row.id);
                break;
        }
    }

    handleGroupClose() {
        const audiencesGroup = this.finderContentManager.getActiveGroup();

        this.sectionComponent.handleFinderGroupSelect(audiencesGroup);
        this.sectionComponent.setTableSelectableRows(false);
    }

    handleActionNew() {
        const activeGroup = this.finderContentManager.getActiveGroup();

        if (activeGroup.id === 'audiences') {
            this.sectionComponent.handleFinderNewGroup({
                entityType: 'individuals',
                extraFields: {
                    mailing_list: true
                }
            });
        } else {
            let campaignSubType = 'campaign';

            switch (activeGroup.id) {
                case 'templates':
                    campaignSubType = 'template';
                    break;

                case 'automations':
                    campaignSubType = 'automation';
                    break;

                case 'system':
                    campaignSubType = 'system';
                    break;
            }

            this.handleCreateCampaign(campaignSubType);
        }
    }

    handleActionAdd(entity) {
        if (!entity) {
            return;
        }

        const self = this;

        $.ajax({
            url: `/${this.selectedGroup.id}/items/${entity.id}`,
            method: 'PUT',
            success: function() {
                self.sectionComponent.fetchTablePage(0);
            }
        });
    }

    handleUpdateGroup(data) {
        if (this.activeGroup?.entityType === 'individuals') {
            let newData = {};

            if (data.columns) {
                newData.columns = data.columns;
            }

            if (data.orderBy) {
                newData.display_options = {
                    sort: {
                        direction: data.orderBy.sortDir === 'asc',
                        field: [data.orderBy.columnId]
                    }
                };
            }

            if (this.activeGroup.groupType === 'smart' && !_.isUndefined(data.filterId)) {
                newData.filter_id = data.filterId;
            }

            if (!_.isEmpty(newData)) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Saving group...',
                            timer: 3000,
                            classes: 'saving processing'
                        };
                    }
                });

                $.ajax({
                    type: 'PATCH',
                    url: `/groups/${this.activeGroup.shortId}`,
                    data: JSON.stringify(newData),
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function () {
                        vent.trigger('alert:show', {
                            type: function() {
                                return {
                                    message: 'Group saved',
                                    timer: 3000,
                                    classes: 'saved success'
                                };
                            }
                        });
                    }
                });
            }

            return true;
        }

        return false;
    }

    handleGroupDelete() {
        const self = this;

        this.showFinderGroups('campaigns', true, function() {
            self.finderContentManager.setActiveGroupId(self.fixedSelectedGroup.id);
            self.sectionComponent.onContentManagerUpdated();
        });
    }

    getSaveAsGroupArgs() {
        const activeGroup = this.finderContentManager.getActiveGroup();

        if (activeGroup.id === 'audiences') {
            return {
                entityType: 'individuals',
                populateFromGroupId: this.selectedGroup.shortId,
                extraFields: {
                    mailing_list: true
                }
            };
        }

        return {
            populateFromGroupId: activeGroup.shortId
        };
    }

    showFinderGroups(groupType, force, callback) {
        const setNewContent = (newContent) => {
            const activeSection = this.finderContentManager.getActiveSection();
            activeSection.content = [...activeSection.content.filter(e => !e.dynamic && e.fixed), ...newContent];

            const subGroupId = this.initialSubGroupId;
            this.initialSubGroupId = null;

            if (subGroupId) {
                const group = this.finderContentManager.getElement(`groups/${subGroupId}`);
                this.finderContentManager.setActiveGroupId(group ? group.id : 'dashboard');
                this.sectionComponent.onContentManagerUpdated(false);
            } else {
                this.sectionComponent.onContentManagerUpdated(false, true);
            }
        }

        if (groupType in this.finderGroupsByType && !force) {
            setNewContent(this.finderGroupsByType[groupType]);

            if (callback) {
                callback();
            }
        } else {
            const self = this;
            const entityType = groupType === 'insights' ? 'campaigns' : groupType;

            this.finderContentManager.fetchUserStructure(entityType, function(structure) {
                if (!self.mounted) {
                    return;
                }

                // dynamic groups based on tags
                if (AppConfig.getValue('campaigns.show_funnels_tags_adhoc_groups', false)) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    const tags = app.globalData.tags;
                    let validTags = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                        if (tag) {
                            validTags.push(tag);
                        }
                    }

                    if (validTags.length > 0) {
                        const folder = {
                            type: 'folder',
                            id: 'campaigns_by_development',
                            name: 'Campaigns by development',
                            fixed: true,
                            dynamic: true,
                            content: validTags.map(vt => {
                                return {
                                    type: 'group',
                                    id: `groups/${vt.id}`,
                                    shortId: vt.id,
                                    name: vt.name,
                                    icon: 'icon-list-dev',
                                    fixed: false,
                                    special: true,
                                    dynamic: true,
                                    fetchArgs: {
                                        tag_ids: vt.id
                                    }
                                };
                            })
                        }

                        structure.unshift(folder);
                    }
                }

                if (AppConfig.getValue('campaigns.show_funnels_adhoc_groups', false)) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    let validFunnels = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        if (funnel) {
                            validFunnels.push(funnel);
                        }
                    }

                    if (validFunnels.length > 0) {
                        const folder = {
                            type: 'folder',
                            id: 'campaigns_by_development',
                            name: 'Campaigns by development',
                            fixed: true,
                            dynamic: true,
                            content: validFunnels.map(vt => {
                                return {
                                    type: 'group',
                                    id: `groups/${vt.id}`,
                                    shortId: vt.id,
                                    name: vt.name,
                                    icon: 'icon-list-dev',
                                    fixed: false,
                                    special: true,
                                    dynamic: true,
                                    fetchArgs: {
                                        funnel_ids: vt.id
                                    }
                                };
                            })
                        }

                        structure.unshift(folder);
                    }
                }

                if (groupType === 'insights') {
                    structure = [...[{
                        type: 'group',
                        id: 'insights_templated',
                        name: 'Templated Campaigns',
                        icon: 'icon-template-email-sent',
                        fixed: false,
                        dynamic: true,
                        fetchArgs: {
                            order_by: 'modified desc',
                            search: 'template',
                            status: 'sent',
                            extended: true
                        }
                    }, {
                        type: 'group',
                        id: 'insights_automated',
                        name: 'Automated Campaigns',
                        icon: 'icon-automated-email',
                        fixed: false,
                        dynamic: true,
                        fetchArgs: {
                            order_by: 'modified desc',
                            for_automations: true,
                            extended: true
                        }
                    }], ...structure];
                }

                // ...
                setNewContent(structure);
                self.finderGroupsByType[groupType] = structure;

                if (callback) {
                    callback();
                }
            });
        }
    }

    hideFinderGroups() {
        const activeSection = this.finderContentManager.getActiveSection();

        activeSection.content = activeSection.content.filter(e => !e.dynamic && e.fixed);

        this.sectionComponent.onContentManagerUpdated(false, true);
    }

    handlePerformanceWidgetSpanChange(dateFilters) {
        this.composeFetcher.deletePerformanceWidgetFilters();

        let fetchArgs = this.composeFetcher.getFetchArgs();
        _.extend(fetchArgs, dateFilters);

        this.composeFetcher.setFetchArgs(fetchArgs);
        this.sectionComponent.fetchTablePage(0);
    }

    performanceWidgetRenderer() {
        return (
            <PerformanceWidget
                ref={(el) => this.performanceWidget = el}
                key={this.performanceWidgetCounter++}
                group={this.finderContentManager.getActiveGroup()}
                onSpanChanged={this.handlePerformanceWidgetSpanChange.bind(this)}
            />
        );
    }

    getUrl() {
        let url = '';

        if (this.selectedGroup?.entityType === 'individuals') {
            url = `campaigns/audiences/${this.selectedGroup.id}`;
        } else if (this.selectedGroup) {
            if (!this.selectedGroup.special || this.selectedGroup.dynamic) {
                url = `campaigns/${this.fixedSelectedGroup.id}/${this.selectedGroup.id}`;
            }
        }

        const workingCampaignData = this.newCampaignRegion?.currentView?.reactComponent.workingCampaignData;

        if (workingCampaignData?.contactEmailTemplating) {
            url = 'campaigns/new';
        } else if (workingCampaignData && !workingCampaignData.id) {
            url = 'campaigns/new';
        } else {
            if (!url) {
                url = this.sectionComponent.getUrl();
            }

            if (workingCampaignData?.id) {
                url += `/${workingCampaignData.id}`;
            } else if (this.previewCampaignRegion?.currentView?.options.model.get('id')) {
                url += `/${this.previewCampaignRegion.currentView.options.model.get('id')}`;
            }
        }

        return url;
    }

    getParams() {
        let params = {}

        return params;
    }

    render() {
        return (
            <SectionBase
                ref={(el) => this.sectionComponent = el}
                parent={this.props.parent}
                entityType='campaigns'
                filters={this.campaignFilters}
                activeView='campaignsDashboard'
                onRowClick={this.handleRowClick.bind(this)}
                onRowContext={this.handleRowContext.bind(this)}
                onCloseGroup={this.handleGroupClose.bind(this)}
                onGroupDelete={this.handleGroupDelete.bind(this)}
                updateGroup={this.handleUpdateGroup.bind(this)}
                finder={{
                    contentManager: this.finderContentManager,
                    createGroups: false,
                    createFolders: false,
                    pinGroups: false,
                    pinFolders:false,
                    onGroupPreSelected: this.handleGroupPreSelect.bind(this)
                }}
                toolbar={{
                    visible: false,
                    search: true,
                    saveAsGroup: true,
                    advancedFilter: true,
                    editColumns: true,
                    views: {
                        table: true
                    },
                    saveAsArgs: this.getSaveAsGroupArgs.bind(this)
                }}
                actions={{
                    new: false,
                    add: true,
                    remove: true,
                    onNew: this.handleActionNew.bind(this),
                    onAdd: this.handleActionAdd.bind(this)
                }}
                table={{
                    allColumns: [],
                    defaultColumnsIds: [],
                    columns: [],
                    fetcher: this.composeFetcher
                }}
                campaignsDashboard={{
                    onCreateCampaign: this.handleCreateCampaign.bind(this),
                    onEditCampaign: this.handleEditCampaign.bind(this)
                }}
                onGroupSaved={this.handleGroupSave.bind(this)}
            />
        );
    }
}

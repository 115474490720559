import React from 'react'
import { SketchPicker } from 'react-color'

import Utilities from 'js/utils/utilities'
import {NewSelect} from 'js/react_views/widgets/select'
import {Colors, BorderStyles} from './consts'

import style from './see.css'

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    const bbox = this.props.el.getBoundingClientRect();

    let top = bbox.top - 327;

    if (top < 0) {
      top = bbox.top + bbox.height;
    }

    this.dialogStyle = {
      position: 'absolute',
      top: top,
      left: bbox.left - (270 - bbox.width)
    };
  }

  render() {
    return (
      <div
        style={{position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 10}}
        onClick={this.props.onClose}
      >
        <div
          style={this.dialogStyle}
          onClick={(ev) => ev.stopPropagation()}
        >
          <SketchPicker
            presetColors={this.props.presetColors}
            width={250}
            disableAlpha={true}
            color={this.props.color}
            onChangeComplete={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

class ColorInputField extends React.Component {
  constructor(props) {
    super(props);

    const value = this.parseValue(props.value || '#000000');

    this.state = {
      nameValue: value.name,
      hexValue: value.hex
    };
  }

  onColorChange(color) {
    this.updateValue(color.hex);
  }

  onChangeText(event) {
    this.setState({nameValue: event.target.value});
  }

  onKeyDown(event) {
    if (event.key === 'Enter') {
      this.updateValue(this.state.nameValue);
    }
  }

  onBlurText() {
    this.updateValue(this.state.nameValue);
  }

  setValue(value) {
    const val = this.parseValue(value);

    this.setState({
      nameValue: val.name,
      hexValue: val.hex
    });
  }

  updateValue(value) {
    const val = this.parseValue(value);

    this.setState({
      nameValue: val.name,
      hexValue: val.hex
    });

    this.props.onChange(val.hex);
  }

  parseValue(value) {
    const lowerVal = value.toLowerCase();
    let hexValue = '';
    let nameValue = '';

    if (lowerVal in Colors) {
      hexValue = Colors[lowerVal];
      nameValue = lowerVal;
    } else if (Utilities.isValidHexColor(value)) {
      hexValue = value;
      nameValue = value;
    } else {
      hexValue = '#000000';
      nameValue = '#000000';
    }

    return {
      hex: hexValue,
      name: nameValue
    }
  }

  refresh() {
    const val = this.parseValue(this.props.value || '#000000');

    this.setState({
      nameValue: val.name,
      hexValue: val.hex
    });
  }

  render() {
    return (
      <div>
        <div
          ref={(el) => this.el = el}
          className={`${style.colorInputField} ${this.props.useShortStyle ? style.short : ''}`}
        >
          <input
            ref={(el) => this.textInput = el}
            type='text'
            value={this.state.nameValue}
            className={style.colorTextInput}
            onChange={this.onChangeText.bind(this)}
            onKeyDown={this.onKeyDown.bind(this)}
            onBlur={this.onBlurText.bind(this)}
          />
          <div
            style={{background: this.state.hexValue}}
            className={style.colorColorInput}
            onClick={() => this.props.editor.showColorPicker(this.el, this.props.value, this.onColorChange.bind(this))}
          />
        </div>
      </div>
    );
  }
}

class RoundedOptionsGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const optionStyle = this.props.optionStyle || {};

    return (
      <div className={style.roundOptionsGroup}>
        <div className={style.rogContainer}>
          {this.props.options.map(option => (
            <div
              key={`option_${option.id}`}
              className={`${style.rogOption} ${option.id === this.props.selected ? style.rogSelected : ''}`}
              style={optionStyle}
              onClick={() => this.props.onOptionSelected(option.id)}
            >
              {option.icon &&
                <div className={option.icon}/>
              }
              {option.html &&
                <div dangerouslySetInnerHTML={{__html: option.html}}/>
              }
            </div>
          ))}
        </div>
      </div>
    );
  }
}

class SavedMessage extends React.Component {
  constructor(props) {
    super(props);

    this.manageMessageVisibility = _.debounce(this.manageMessageVisibility, 1000);
  }

  render() {
    return (
      <div
        ref={(el) => this.message = el}
        className={style.savedMessage}
      >
        Saved
      </div>
    );
  }

  onContentChange() {
    const msg = $(this.message);
    msg.removeClass(style.savedMessageVisible);

    this.manageMessageVisibility();
  }

  manageMessageVisibility() {
    const msg = $(this.message);
    msg.addClass(style.savedMessageVisible);

    const self = this;

    _.delay(function() {
      msg.removeClass(style.savedMessageVisible);
    }, 3000);
  }
}

class RangeInput extends React.Component {
  handleFocus(ev) {
    this.valueOnFocus = this.props.value;
  }

  handleBlur() {
    if (this.props.onModify && (this.valueOnFocus !== this.props.value)) {
      this.props.onModify(this.valueOnFocus, this.props.value);
    }
  }

  render() {
    return (
      <input
        type='range'
        min={this.props.min || 0}
        max={this.props.max || 100}
        value={this.props.value}
        className={style.slider}
        onChange={this.props.onChange}
        onBlur={this.handleBlur.bind(this)}
        onFocus={this.handleFocus.bind(this)}
      />
    );
  }
}

class SimpleInput extends React.Component {
  handleFocus() {
    this.valueOnFocus = this.props.value;
  }

  handleBlur() {
    if (this.props.onModify && (this.valueOnFocus !== this.props.value)) {
      this.props.onModify(this.valueOnFocus, this.props.value);
    }
  }

  render() {
    return (
      <div className={style.simpleInput}>
        <div className={style.simpleLabel}>{this.props.label}</div>
        <input
          type='text'
          className={style.siInput}
          value={this.props.value || ''}
          placeholder={this.props.placeholder || ''}
          onChange={this.props.onChange}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
      </div>
    );
  }
}

class BorderSettings extends React.Component {
  handleWidthFocus() {
    this.widthOnFocus = this.props.width;
  }

  handleWidthBlur() {
    if (this.props.onWidthModify && (this.widthOnFocus !== this.props.width)) {
      this.props.onWidthModify(this.widthOnFocus, this.props.width);
    }
  }

  setStyle(styleId) {
    this.borderStyleEl.setValue(styleId);
  }

  setColor(color) {
    this.colorEl.setValue(color);
  }

  render() {
    return (
      <div className={style.spBorder}>
        <div className={style.spbColumn}>
          <div className={style.spbcTitle}>Width</div>
          <input
            className={style.spbcInput}
            type='text'
            value={this.props.width}
            onChange={this.props.onWidthChange}
            onFocus={this.handleWidthFocus.bind(this)}
            onBlur={this.handleWidthBlur.bind(this)}
          />
        </div>
        <div className={style.spbColumn}>
          <div className={style.spbcTitle}>Style</div>
          <div style={{width: '100px'}}>
            <NewSelect
              ref={(el) => this.borderStyleEl = el}
              data={BorderStyles}
              value={this.props.style}
              width={100}
              placeholder='Search'
              options={{minimumInputLength: -1}}
              titleStyle={{marginRight: '15px'}}
              boxStyle={{background: 'white', height: '30px'}}
              onSelect={this.props.onStyleChange}
            />
          </div>
        </div>
        <div className={style.spbColumn}>
          <div className={style.spbcTitle}>Color</div>
          <ColorInputField
            ref={(el) => this.colorEl = el}
            editor={this.props.editor}
            value={this.props.color}
            onChange={this.props.onColorChange}
            useShortStyle={true}
          />
        </div>
      </div>
    );
  }
}

class PaddingInput extends React.Component {
  constructor(props) {
    super(props);

    this.paddingValues = [];

    for (let i = 0; i <= 100; ++i) {
      this.paddingValues.push({
        id: i,
        title: `${i}px`
      });
    }
  }

  onSelectChange(padding, item) {
    this.props.onChange(padding, item.id)
  }

  setValue(padding, value) {
    switch (padding) {
      case 'top':
        this.paddingTopComponent.setValue(value, true);
        break;

      case 'right':
        this.paddingRightComponent.setValue(value, true);
        break;

      case 'bottom':
        this.paddingBottomComponent.setValue(value, true);
        break;

      case 'left':
        this.paddingLeftComponent.setValue(value, true);
        break;
    }
  }

  render() {
    return (
      <div>
        <div className={style.simpleRow}>
          <div style={{width: '100%'}}>
            <div className={style.simpleLabel}>Padding Top</div>
            <div
              style={{
                width: '155px',
                marginTop: '8px'
              }}
            >
              <NewSelect
                ref={(el) => this.paddingTopComponent = el}
                data={this.paddingValues}
                value={this.paddingValues.find(v => v.id === this.props.values[0])}
                width={155}
                options={{minimumInputLength: 0}}
                onSelect={(items) => this.onSelectChange.bind(this)('top', items[0])}
              />
            </div>
          </div>

          <div style={{width: '100%'}}>
            <div className={style.simpleLabel}>Padding Bottom</div>
            <div
              style={{
                width: '155px',
                marginTop: '8px'
              }}
            >
              <NewSelect
              ref={(el) => this.paddingBottomComponent = el}
                data={this.paddingValues}
                value={this.paddingValues.find(v => v.id === this.props.values[2])}
                width={155}
                options={{minimumInputLength: 0}}
                onSelect={(items) => this.onSelectChange.bind(this)('bottom', items[0])}
              />
            </div>
          </div>
        </div>

        <div
          className={style.simpleRow}
          style={{marginTop: '15px'}}
        >
          <div style={{width: '100%'}}>
            <div className={style.simpleLabel}>Padding Left</div>
            <div
              style={{
                width: '155px',
                marginTop: '8px'
              }}
            >
              <NewSelect
                ref={(el) => this.paddingLeftComponent = el}
                data={this.paddingValues}
                value={this.paddingValues.find(v => v.id === this.props.values[3])}
                width={155}
                options={{minimumInputLength: 0}}
                onSelect={(items) => this.onSelectChange.bind(this)('left', items[0])}
              />
            </div>
          </div>

          <div style={{width: '100%'}}>
            <div className={style.simpleLabel}>Padding Right</div>
            <div
              style={{
                width: '155px',
                marginTop: '8px'
              }}
            >
              <NewSelect
                ref={(el) => this.paddingRightComponent = el}
                data={this.paddingValues}
                value={this.paddingValues.find(v => v.id === this.props.values[1])}
                width={155}
                options={{minimumInputLength: 0}}
                onSelect={(items) => this.onSelectChange.bind(this)('right', items[0])}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ColumnLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLayout: props.active
    };
  }

  handleLayoutChange(layout) {
    this.setActive(layout);
    this.props.onChange(layout);
  }

  setActive(layout) {
    this.setState({
      activeLayout: layout
    });
  }

  render() {
    return (
      <div className={style.columnLayout}>
        <div className={style.clRow}>
          <div
            className={`
              ${style.clrItem}
              ${this.state.activeLayout === 'equal' ? style.clrActive : ''}
            `}
            onClick={() => this.handleLayoutChange.bind(this)('equal')}
          >
            <div className={style.clriBlocks}>
              <div className={style.clriBlock} style={{width: '50%'}}/>
              <div className={style.clriBlock} style={{width: '50%'}}/>
            </div>

            <div className={style.clriName}>EQUAL 2</div>
          </div>

          <div
            className={`
              ${style.clrItem}
              ${this.state.activeLayout === '2/3-1/3' ? style.clrActive : ''}
            `}
            onClick={() => this.handleLayoutChange.bind(this)('2/3-1/3')}
          >
            <div className={style.clriBlocks}>
              <div className={style.clriBlock} style={{width: '66.6%'}}/>
              <div className={style.clriBlock} style={{width: '33.3%'}}/>
            </div>

            <div className={style.clriName}>2/3 - 1/3</div>
          </div>

          <div
            className={`
              ${style.clrItem}
              ${this.state.activeLayout === '1/3-2/3' ? style.clrActive : ''}
            `}
            onClick={() => this.handleLayoutChange.bind(this)('1/3-2/3')}
          >
            <div className={style.clriBlocks}>
              <div className={style.clriBlock} style={{width: '33.3%'}}/>
              <div className={style.clriBlock} style={{width: '66.6%'}}/>
            </div>

            <div className={style.clriName}>1/3 - 2/3</div>
          </div>
        </div>

        <div className={style.clRow}>
          <div
            className={`
              ${style.clrItem}
              ${this.state.activeLayout === '3/4-1/4' ? style.clrActive : ''}
            `}
            onClick={() => this.handleLayoutChange.bind(this)('3/4-1/4')}
          >
            <div className={style.clriBlocks}>
              <div className={style.clriBlock} style={{width: '75%'}}/>
              <div className={style.clriBlock} style={{width: '25%'}}/>
            </div>

            <div className={style.clriName}>3/4 - 1/4</div>
          </div>

          <div
            className={`
              ${style.clrItem}
              ${this.state.activeLayout === '1/4-3/4' ? style.clrActive : ''}
            `}
            onClick={() => this.handleLayoutChange.bind(this)('1/4-3/4')}
          >
            <div className={style.clriBlocks}>
              <div className={style.clriBlock} style={{width: '25%'}}/>
              <div className={style.clriBlock} style={{width: '75%'}}/>
            </div>

            <div className={style.clriName}>1/4 - 3/4</div>
          </div>
        </div>
      </div>
    );
  }
}

export {
  SavedMessage,
  SimpleInput,
  RangeInput,
  RoundedOptionsGroup,
  ColorInputField,
  BorderSettings,
  ColorPicker,
  PaddingInput,
  ColumnLayout
}
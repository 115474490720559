import _ from 'underscore';
import React from 'react';

import {Button} from 'js/react_views/widgets/widgets';
import Popover from 'js/react_views/widgets/popover';
import {
    NewSelect,
    IndividualSelectItem
} from 'js/react_views/widgets/select';
import AppConfig from 'app/app-config';
import IndividualList from 'js/react_views/detail_view_components/individual-list';
import ListPager from 'js/react_views/detail_view_components/list-pager';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import TextManager from 'app/text-manager';

import style from './Individuals.css';

class Individuals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {showAddItemPopover: false};

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleAddItemPopoverClose = this.handleAddItemPopoverClose.bind(this);
        this.handleIndividualsAdd = this.handleIndividualsAdd.bind(this);
    }

    handleAddButtonClick() {
        this.setState({showAddItemPopover: true});
    }

    handleAddItemPopoverClose() {
        this.setState({showAddItemPopover: false});
    }

    handleIndividualsAdd(items) {
        this.props.onAdd(items);
        this.setState({showAddItemPopover: false});
    }

    render() {
        const {
            individuals,
            onClickNext,
            onClickPrev,
            onNavigate,
            onLinkClick,
            onNew
        } = this.props;

        const IndividualSelectItemComponent = AppConfig.getValue('individuals.new_select_widget.item_component', IndividualSelectItem);

        return (
            <div className={style.Individuals}>
                <header className={style.header}>
                    <div className={`${style.headerBtnBox} tool-button`}>
                        <Button
                            color="blue"
                            onClick={this.handleAddButtonClick}>
                            Add
                        </Button>
                    </div>
                    <div className={`${style.headerBtnBox} tool-button`}>
                        <Button
                            color="blue"
                            onClick={onNew}>
                            New
                        </Button>
                    </div>
                    <h5 className={style.heading}>{TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}')}</h5>
                    {this.state.showAddItemPopover &&
                        <Popover
                            title="Add an item"
                            wider={true}
                            onClose={this.handleAddItemPopoverClose}>
                            <NewSelect
                                url="/individuals"
                                itemView={IndividualSelectItemComponent}
                                onSelect={this.handleIndividualsAdd}
                                onCancel={this.handleAddItemPopoverClose}
                                placeholder="Search for someone"
                                width={325}
                                options={{minimumInputLength: 1, initiallyExpanded: true}}
                            />
                        </Popover>
                    }
                </header>
                {!individuals &&
                    <div className={style.loadingContainer}>
                        <LoadingIndicator />
                    </div>
                }
                {individuals && individuals.items.length > 0 &&
                    <div>
                        <IndividualList
                            individuals={individuals.items}
                            onNavigate={onNavigate}
                            onLinkClick={onLinkClick} />
                        <ListPager
                            start={individuals.start}
                            currentPageLength={individuals.items.length}
                            total={individuals.total}
                            onClickNext={onClickNext}
                            onClickPrev={onClickPrev} />
                    </div>
                }
                {individuals && individuals.items.length === 0 &&
                    <div className={style.listPlaceholder} />
                }
            </div>
        );
    }
}

export default Individuals;

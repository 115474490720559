import BaseModel from 'js/models/base'
import CommunicationModel from 'js/models/communication'


// TODO: Consider converting BaseContactModel to a mixin - like activities
var BaseContactModel = BaseModel.extend({

    // TODO: validation ...

    primaryEmail: function() {
        var i=0, communication = this.get('communication'), item;
        if (!communication) { return null; }
        for (i; i < communication.length; i += 1) {
            item = communication[i];
            if (item.medium === 'email') {
                item = new CommunicationModel(item);
                return {
                    value: item.get('value'),
                    valueHref: item.valueHref(item)
                };
            }
        }
        return null;
    },
    primaryPhone: function() {
        var i=0, communication = this.get('communication'), item;
        if (!communication) { return null; }
        for (i; i < communication.length; i += 1) {
            item = communication[i];
            if (item.medium === 'phone') {
                item = new CommunicationModel(item);
                return {
                    value: item.get('value'),
                    valueHref: item.valueHref(item)
                };
            }
        }
        return null;
    },
    primaryWebsite: function() {
        var i=0, communication = this.get('communication'), item;
        if (!communication) { return null; }
        for (i; i < communication.length; i += 1) {
            item = communication[i];
            if (item.medium === 'url') {
                item = new CommunicationModel(item);
                return {
                    value: item.get('value'),
                    valueHref: item.valueHref(item)
                };
            }
        }
        // fall back to LinkedIn
        for (i; i < communication.length; i += 1) {
            item = communication[i];
            if (item.medium === 'linkedin') {
                item = new CommunicationModel(item);
                return {
                    value: item.get('value'),
                    valueHref: item.valueHref(item)
                };
            }
        }
        return null;
    },
    primaryLocation: function() {
        var locations = this.get('locations');
        if (!locations || !locations.length) {
            return null;
        }
        return locations[0].address;
    }
});
export default BaseContactModel;


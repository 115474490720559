import React from 'react'

import builtInWidgets from './widgets/builtin_widgets'
import {adjustToGrid} from './defs'

import style from './editor.css'


export class EditingHelperRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zIndex: this.getZIndex(props.widget.parent)
        };
    }

    handleMouseDown(ev) {
        if (ev.button !== 0) {
            return;
        }

        this.props.widget.triggerEvent('editor:moveWidget', {
            widget: this.props.widget,
            mousePos: [ev.clientX, ev.clientY]
        });
    }

    handleResizeStart(ev, mulX, mulY) {
        ev.stopPropagation();

        if (ev.button !== 0) {
            return;
        }

        this.props.widget.triggerEvent('editor:resizeWidget', {
            widget: this.props.widget,
            mousePos: [ev.clientX, ev.clientY],
            mul: [mulX, mulY]
        });
    }

    getZIndex(parent) {
        // the z-index depends of the position in the hierarchy (godparent, parent, me)
        let zIndex = 0;

        if (parent) {
            zIndex = 1 + this.getZIndex(parent.parent);
        }

        return zIndex;
    }

    handleWidgetDelete() {
        this.props.widget.triggerEvent('editor:deleteWidget', {
            widget: this.props.widget
        });
    }

    render() {
        return (
            <div
                className={`
                    ${style.wEditingHelper}
                    ${this.props.selected ? style.selected : ''}
                    ${this.props.overlapped ? style.overlapped : ''}
                `}
                style={{
                    zIndex: this.state.zIndex
                }}
                onMouseDown={this.handleMouseDown.bind(this)}
            >
                <div
                    className={style.eToolbar}
                    onMouseDown={(ev) => ev.stopPropagation()}
                >
                    <div
                        className='icon-trashcan'
                        onClick={this.handleWidgetDelete.bind(this)}
                    />
                </div>

                <div
                    className={style.ecHorSide}
                    style={{
                        top: -3,
                        left: -3
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, 0, -1)}
                />

                <div
                    className={style.ecVerSide}
                    style={{
                        top: -3,
                        right: -3
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, 1, 0)}
                />

                <div
                    className={style.ecVerSide}
                    style={{
                        bottom: -3,
                        left: -3
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, -1, 0)}
                />

                <div
                    className={style.ecHorSide}
                    style={{
                        bottom: -3,
                        right: -3
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, 0, 1)}
                />

                <div
                    className={style.ecCorner}
                    style={{
                        top: -3,
                        left: -3,
                        cursor: 'nwse-resize'
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, -1, -1)}
                />

                <div
                    className={style.ecCorner}
                    style={{
                        top: -3,
                        right: -3,
                        cursor: 'nesw-resize'
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, 1, -1)}
                />

                <div
                    className={style.ecCorner}
                    style={{
                        bottom: -3,
                        left: -3,
                        cursor: 'nesw-resize'
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, -1, 1)}
                />

                <div
                    className={style.ecCorner}
                    style={{
                        bottom: -3,
                        right: -3,
                        cursor: 'nwse-resize'
                    }}
                    onMouseDown={(ev) => this.handleResizeStart(ev, 1, 1)}
                />
            </div>
        );
    }
}


export class DashboardEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        for (const w of this.draggableComponents) {
            const el = $(w);

            el.draggable({
                cursor: 'move',
                helper: 'clone',
                cursorAt: {top: 0, left: 0}
            });
        }

        // ...
        this.gridEl = $(document.getElementById('dashboard_editor_panel_grid_root'));

        const self = this;

        this.gridEl.droppable({
            accept: function(el) {
                return el.hasClass('ui-draggable');
            },
            drop(ev, ui) {
                // adjust coordinates to the root panel
                const bbox = self.gridEl[0].getBoundingClientRect();
                let position = [ev.clientX - bbox.x, ev.clientY - bbox.y];

                // get container where the widget has been dropped
                const container = self.props.root.getContainerAtScreenPos(position[0], position[1]);

                if (!container) {
                    return;
                }

                const widgetType = ui.draggable.attr('id');
                let widgetDef = _.clone(builtInWidgets[widgetType]);
                const editorInfo = widgetDef.editor || {};

                position = self.adjustPosition(position, container);
                widgetDef.position = [adjustToGrid(position[0]), adjustToGrid(position[1])];

                if (editorInfo.initial_size) {
                    widgetDef.size = _.clone(editorInfo.initial_size);
                } else {
                    widgetDef.size = [200, 140];
                }

                if (widgetDef.type === 'tab_control') {
                    widgetDef.tabs = [];

                    for (const tab of editorInfo.initial_tabs) {
                        widgetDef.tabs.push({
                            title: tab,
                            children: []
                        });
                    }
                }

                delete widgetDef.editor;

                const widget = container.addNewWidget(widgetDef);
                container.setDirty();

                widget.triggerEvent('editor:selectWidget', {
                    widget: widget,
                    checkOverlapping: true
                });
            }
        });
    }

    componentWillUnmount() {
        for (const w of this.draggableComponents) {
            $(w).draggable('destroy');
        }

        this.gridEl.droppable('destroy');
    }

    adjustPosition(position, parent) {
        position[0] -= parent.position[0];
        position[1] -= parent.position[1];

        if (parent.parent) {
            position = this.adjustPosition(position, parent.parent);
        }

        return position;
    }

    handleSave() {
        let method = 'POST';
        let url = '/dashboards';

        if (this.props.dashboard.id !== 'new-dashboard') {
            method = 'PATCH';
            url += `/${this.props.dashboard.id}`;
        }

        $.ajax({
            type: method,
            url: url,
            dataType: 'json',
            data: JSON.stringify({
                name: 'new dashboard', // todo: get the name
                content: {
                    size: _.clone(this.props.root.size),
                    children: this.props.root.getSaveInfo()
                }
            })
        });
    }

    render() {
        this.draggableComponents = [];

        return (
            <div className={style.dashboardEditor}>
                <div className={style.eHeader}>
                    <div className={style.hTitle}>Build Dashboard</div>
                    <div
                        style={{
                            marginLeft: 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={() => this.props.onClose()}
                    >
                        Close
                    </div>
                </div>

                <div className={style.eContent}>
                    <div className={style.cTitle}>Containers</div>
                    <div className={style.cDesc}>Drag + drop containers onto the canvas</div>

                    <div className={style.cRow}>
                        <div
                            ref={(el) => this.draggableComponents.push(el)}
                            id='panel'
                            className={style.cContainer}
                        >
                            <div className={style.cName}>Simple Container</div>
                        </div>

                        <div
                            className={style.cContainer}
                            ref={(el) => this.draggableComponents.push(el)}
                            id='tab_control'
                        >
                            <div className={style.cName}>Tab Container</div>
                        </div>
                    </div>

                    <div
                        className={style.cTitle}
                        style={{marginTop: 30}}
                    >
                        Widgets
                    </div>
                    <div className={style.cDesc}>Drag + drop widgets directly onto the dashboard</div>

                    <div className={style.cRow}>
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='new_leads_kpi'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/new_leads.png'
                            style={{width: 85}}
                        />
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='total_leads_kpi'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/total_leads.png'
                            style={{width: 85}}
                        />
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='total_tasks_kpi'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/total_tasks.png'
                            style={{width: 85}}
                        />
                    </div>

                    <div
                        className={style.cRow}
                        style={{marginTop: 12}}
                    >
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='overdue_tasks_kpi'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/overdue_tasks.png'
                            style={{width: 85}}
                        />
                    </div>

                    <div
                        className={style.cRow}
                        style={{marginTop: 20}}
                    >
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='phases_summary'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/phases_summary.png'
                        />
                    </div>

                    <div
                        className={style.cRow}
                        style={{marginTop: 20}}
                    >
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='site_map'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/site_map.png'
                            style={{width: 130}}
                        />
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='funnel'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/funnel.png'
                            style={{width: 130}}
                        />
                    </div>

                    <div
                        className={style.cRow}
                        style={{marginTop: 20}}
                    >
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='lead_source_insights'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/lead_sources_insight.png'
                            style={{width: 130}}
                        />
                    </div>

                    <div
                        className={style.cRow}
                        style={{marginTop: 20}}
                    >
                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='new_leads_list'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/new_leads_list.png'
                            style={{width: 130}}
                        />

                        <img
                            ref={(el) => this.draggableComponents.push(el)}
                            id='tasks_list'
                            className={style.cImageButton}
                            src='/img/dashboard-editor/new_tasks_list.png'
                            style={{width: 130}}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
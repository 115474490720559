import Handlebars from 'handlebars'
import Backbone from 'backbone'

import FilterQuickItems from 'js/views/filters/quick-items.js'
import FilterQuickOptionsBase from 'js/views/filters/quick-options-base.js'
import app from 'js/app.js'
import TextManager from 'app/text-manager'


var FilterModel = FilterQuickOptionsBase.FilterModel,
    FilterCollection = FilterQuickOptionsBase.FilterCollection,
    createAsyncItems = FilterQuickOptionsBase.createAsyncItems,
    createSearchableItems = FilterQuickOptionsBase.createSearchableItems,
    createGlobalSearchableItems = FilterQuickOptionsBase.createGlobalSearchableItems,
    createPhaseItems = FilterQuickOptionsBase.createPhaseItems;

export default function() {
    var options = [
        {
            view: FilterQuickItems.ButtonView,
            name: function() {
                return 'Search for "' + this.get('search') + '"';
            },
            nameHtml: true,
            hidden: function() {
                return !(this.get('search') && this.get('search').length);
            },
            icon: 'icon-search',
            rules: function() {
                return [{
                    field: 'individual_name',
                    operator: 'contains',
                    values: this.get('search')
                }];
            }
        },
        {
            view: FilterQuickItems.SeperatorView,
            hidden: function() {
                return !this.get('search') || !this.get('search').length;
            }
        },
        createGlobalSearchableItems(),
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'quick filters',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.ButtonView,
                    name: TextManager.getText('ID_MY_INDIVIDUALS'),
                    icon: function() {
                        var template = Handlebars.compile(
                            '<div class="item-icon thumb thumb-small"><div class="user-initials">{{initials}}</div></div>'
                        );

                        return template({
                            'initials':  app.user.get('name').split(' ').map(function(s) {
                                return s.charAt(0);
                            }).join('')
                        });
                    },
                    iconHtml: true,
                    rules: [{
                        field: 'individual_owned_by_me'
                    }]
                },
                {
                    view: FilterQuickItems.ButtonView,
                    name: 'New this week',
                    icon: 'icon-plus',
                    rules: [{
                        field: 'individual_created',
                        operator: 'within_last',
                        values: {
                            unit: 'w',
                            value: 1
                        }
                    }]
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            inline: true,
            name: 'Filter By',
            items: new FilterCollection([
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.parseText('${ID_SOURCE}'),
                    icon: 'icon-droplets',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/lead_sources',
                            'name',
                            'icon-droplets',
                            'individual_source'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Owner',
                    icon: 'icon-locked',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/users',
                            'name',
                            'icon-locked',
                            'owner_id'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                    icon: 'icon-user',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/individuals', 'full_name', function(item) {
                            return item.organization_name;
                        })
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                    icon: 'icon-home',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/organizations', 'name')
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Group',
                    icon: 'icon-list',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/groups', 'name')
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Tags',
                    icon: 'icon-tag',
                    click: false,
                    items: new FilterCollection([
                        createAsyncItems(
                            '/tags',
                            'name',
                            'icon-tag',
                            'individual_tags'
                        )
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Email',
                    icon: 'icon-email',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/communications?mediums=email&entity_types=individuals', 'value')
                    ])
                },
                {
                    view: FilterQuickItems.GroupView,
                    name: 'Phone',
                    icon: 'icon-phone',
                    click: false,
                    items: new FilterCollection([
                        createSearchableItems('/communications?mediums=phone&entity_types=individuals', 'value')
                    ])
                }
            ])
        },
        {
            view: FilterQuickItems.GroupView,
            name: 'All filters',
            icon: 'icon-filters-add',
            click: function(ev, view) {
                view.root.showAdvanced();
            }
        }
    ];

    var cfQuickFilters = app.globalData.customFieldsQuickFilters.individuals;
    var cfItems = new FilterCollection(cfQuickFilters.map(cf => {
        return {
            view: FilterQuickItems.AngleButtonView,
            name: cf.name,
            icon: 'icon-individuals',
            click: function(ev, view) {
                view.root.showAdvanced(new Backbone.Model({
                    items: new Backbone.Collection([{
                        field: 'individual_custom',
                        custom: cf.id
                    }])
                }));
            }
        };
    }));

    if (cfItems.length === 0) {
        cfItems.add(new FilterModel({
            view: FilterQuickItems.EmptyGroupView
        }));
    }

    var customFilters = [{
        view: FilterQuickItems.GroupView,
        inline: true,
        name: 'custom filter by',
        items: cfItems
    }];

    return new FilterModel({
        view: FilterQuickItems.GroupView,
        inline: true,
        items: new FilterCollection(options),
        customFilters: new FilterCollection(customFilters)
    });
};

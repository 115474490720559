import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import vent from 'js/vent';
import AppConfig from 'app/app-config';
import Section from 'app_v2/sections/base/nsection';
import Filters from './filters';
import FinderContentManager from 'app_v2/sections/finder_content_manager';
import { getAllColumns, getDefaultColumnsIds } from './table_columns';


export default class AppointmentsReactView extends React.Component {
    constructor(props) {
        super(props);

        this.allColumns = getAllColumns();
        this.defaultColumns = [];
        this.entityIdToShow = props.entityId;
        let filter = null;

        for (const cid of getDefaultColumnsIds()) {
            this.defaultColumns.push(this.allColumns.find(c => c.id === cid));
        }

        if (props.filter_id) {
            $.ajax({
                url: `/appointments/filters/${props.filter_id}`,
                type: 'GET',
                async: false,
                success: function (data) {
                    filter = data;
                }
            });
        }

        this.filter = filter;
        this.finderContentManager = new FinderContentManager();
    }

    componentDidMount() {
        this.mounted = true;

        const parent = this.props.parent;
        const self = this;

        this.props.parent.listenTo(vent, 'appointment:updated appointment:created', function() {
            self.sectionComponent.setDataDirty();
        });

        if (this.props.initialAppointmentId) {
            vent.trigger('quick:edit:appointment', this.props.initialAppointmentId);
        }

        this.createFinderStructure();
    }

    getAdhocGroupOptions() {
        return {
            section: {
                activeView: 'table',
                entityType: 'appointments',
                views: {
                    table: {
                        view: 'groupTable',
                        toolbar: {
                            searchable: true,
                            columnsEditable: true,
                            advancedFilters: true,
                            saveAsCsv: true
                        },
                        actions: {
                            new: true
                        },
                        content: {
                            allColumns: this.allColumns,
                            columns: this.defaultColumns,
                            filters: Filters
                        }
                    },
                    calendar: {
                        view: 'groupCalendar',
                        toolbar: {
                            advancedFilters: true,
                            calendar: {
                                date: new Date(),
                                span: 'week'
                            }
                        },
                        content: {
                            filters: Filters,
                            date: new Date(),
                            span: 'week'
                        }
                    }
                }
            }
        };
    }

    createFinderStructure(activeGroupId) {
        this.finderContentManager.clear();

        // initialize finder content manager
        let content = [
            this.finderContentManager.createAdhocGroup(
                'all',
                'All Appointments',
                'icon-globe',
                null,
                this.getAdhocGroupOptions()
            )
        ];

        if (AppConfig.getValue('appointments.show_system_groups', true)) {
            content = [...content, ...[
                this.finderContentManager.createAdhocGroup(
                    'upcoming',
                    'Upcoming Appointments',
                    'icon-calendar',
                    {upcoming: true},
                    this.getAdhocGroupOptions()
                ),
                this.finderContentManager.createAdhocGroup(
                    'confirmed',
                    'Confirmed Appointments',
                    'icon-checkmark3',
                    {status: 'confirmed'},
                    this.getAdhocGroupOptions()
                ),
                this.finderContentManager.createAdhocGroup(
                    'pending',
                    'Pending Appointments',
                    'icon-wait',
                    {status: 'pending'},
                    this.getAdhocGroupOptions()
                )
            ]];
        }

        let sections = [{
            id: 'appointments',
            entityType: 'appointments',
            name: 'Appointments',
            content: content
        }];

        this.fetchGroupsUserStructure(sections, activeGroupId);
    }

    fetchGroupsUserStructure(sections, activeGroupId) {
        const self = this;

        this.finderContentManager.fetchUserStructure('appointments', function(structure) {
            if (!self.mounted) {
                return;
            }

            // dynamic groups based on tags
            if (AppConfig.getValue('appointments.show_funnels_tags_adhoc_groups', false)) {
                // because old code the dynamic structure was saved in the DB. If it is the case we should ignore it.
                structure = structure.filter(i => i.id !== 'confirmed_appointments_by_development' && i.id !== 'pending_appointments_by_development');

                // ...
                const funnels = app.globalData.funnelsInfo.funnels;
                const tags = app.globalData.tags;
                let validTags = [];

                for (const funnel of funnels) {
                    if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                        continue;
                    }

                    const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                    if (tag) {
                        validTags.push(tag);
                    }
                }

                if (validTags.length > 0) {
                    const pendingFolder = {
                        type: 'folder',
                        id: 'pending_appointments_by_development',
                        name: 'Pending appointments',
                        fixed: true,
                        dynamic: true,
                        content: validTags.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/p:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    tag_ids: vt.id,
                                    status: 'pending'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const confirmedFolder = {
                        type: 'folder',
                        id: 'confirmed_appointments_by_development',
                        name: 'Confirmed appointments',
                        fixed: true,
                        dynamic: true,
                        content: validTags.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/c:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    tag_ids: vt.id,
                                    status: 'confirmed'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const cancelledFolder = {
                        type: 'folder',
                        id: 'cancelled_appointments_by_development',
                        name: 'Cancelled appointments',
                        fixed: true,
                        dynamic: true,
                        content: validTags.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/a:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    tag_ids: vt.id,
                                    status: 'cancelled'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const completedFolder = {
                        type: 'folder',
                        id: 'completed_appointments_by_development',
                        name: 'Completed appointments',
                        fixed: true,
                        dynamic: true,
                        content: validTags.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/o:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    tag_ids: vt.id,
                                    status: 'completed'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    structure.unshift(cancelledFolder);
                    structure.unshift(completedFolder);
                    structure.unshift(pendingFolder);
                    structure.unshift(confirmedFolder);
                }
            }

            if (AppConfig.getValue('appointments.show_funnels_adhoc_groups', false)) {
                // because old code the dynamic structure was saved in the DB. If it is the case we should ignore it.
                structure = structure.filter(i => i.id !== 'confirmed_appointments_by_development' && i.id !== 'pending_appointments_by_development');

                // ...
                const funnels = app.globalData.funnelsInfo.funnels;
                let validFunnels = [];

                for (const funnel of funnels) {
                    if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                        continue;
                    }

                    if (funnel) {
                        validFunnels.push(funnel);
                    }
                }

                if (validFunnels.length > 0) {
                    const pendingFolder = {
                        type: 'folder',
                        id: 'pending_appointments_by_development',
                        name: 'Pending appointments',
                        fixed: true,
                        dynamic: true,
                        content: validFunnels.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/p:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    funnel_ids: vt.id,
                                    status: 'pending'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const confirmedFolder = {
                        type: 'folder',
                        id: 'confirmed_appointments_by_development',
                        name: 'Confirmed appointments',
                        fixed: true,
                        dynamic: true,
                        content: validFunnels.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/c:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    funnel_ids: vt.id,
                                    status: 'confirmed'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const cancelledFolder = {
                        type: 'folder',
                        id: 'cancelled_appointments_by_development',
                        name: 'Cancelled appointments',
                        fixed: true,
                        dynamic: true,
                        content: validFunnels.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/a:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    funnel_ids: vt.id,
                                    status: 'cancelled'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    const completedFolder = {
                        type: 'folder',
                        id: 'completed_appointments_by_development',
                        name: 'Completed appointments',
                        fixed: true,
                        dynamic: true,
                        content: validFunnels.map(vt => {
                            return {
                                type: 'group',
                                id: `groups/o:${vt.id}`,
                                shortId: vt.id,
                                name: vt.name,
                                icon: 'icon-list-dev',
                                adhoc: true,
                                dynamic: true,
                                fetchArgs: {
                                    funnel_ids: vt.id,
                                    status: 'completed'
                                },
                                section: self.getAdhocGroupOptions().section
                            };
                        })
                    }

                    structure.unshift(cancelledFolder);
                    structure.unshift(completedFolder);
                    structure.unshift(pendingFolder);
                    structure.unshift(confirmedFolder);
                }
            }

            const section = sections[0];
            section.content = [...section.content, ...structure];

            self.finderContentManager.addSections(sections, 0);

            // initial group
            if (!activeGroupId) {
                if (self.props.initialGroupId) {
                    activeGroupId = self.props.initialGroupId;
                } else {
                    activeGroupId = app.user.get('preferences').initial_appointments_group;
                }
            }

            // does the group exist?
            const groupExists = !!self.finderContentManager.getElement(activeGroupId);

            if (!groupExists) { // get the first one in the list
                activeGroupId = self.finderContentManager.getFirstGroup().id;
            }

            self.finderContentManager.setActiveGroupId(activeGroupId);
            self.handleGroupSelect(self.finderContentManager.getActiveGroup(), true);
        });
    }

    handleGroupSelect(group, noRememberGroup) {
        const self = this;

        const processGroup = (grp) => {
            self.sectionComponent.setGroup(grp);
            vent.trigger('AppContent:contentChange');

            // remember group
            if (!noRememberGroup) {
                app.user.updatePreference('initial_appointments_group', grp.id);
            }

            if (self.entityIdToShow) {
                self.sectionComponent.showEntity(self.entityIdToShow);
                self.entityIdToShow = null;
            }
        }

        /*
            Not sure if here is the best solution to set the filter to null when changing groups
        */
        if (this.filter && noRememberGroup) {
            group.filter = this.filter;
        } else {
            this.filter = null;
        }

        if (group.adhoc) {
            processGroup(group);
        } else {
            $.get(`/${group.id}`, function(result) {
                group = _.clone(group);

                group.filter = result.filter || null;
                group.columns = result.columns;

                const sort = result.display_options?.sort;

                if (sort) {
                    group.orderBy = {
                        columnId: _.isArray(sort.field) ? sort.field[0] : sort.field,
                        direction: sort.direction ? 'asc' : 'desc'
                    };
                }

                // self.configureGroup(group); TODO: to implement
                processGroup(group);
            });
        }
    }

    getUrl() {
        let path = ['appointments'];
        const activeGroup = this.finderContentManager.getActiveGroup();

        if (activeGroup) {
            path.push(activeGroup.id);
        }

        const appointmentView = app.appPanel.currentView;

        if (appointmentView) {
            const appointmentId = appointmentView.getAppointmentId();

            if (appointmentId) {
                path.push(appointmentId);
            }
        }

        return path.join('/');
    }

    getParams() {
        let params = {}

        if (this.filter?.id) {
            params['filter_id'] = this.filter.id;
        }

        return params;
    }

    render() {
        return (
            <Section
                ref={(el) => this.sectionComponent = el}
                parent={this.props.parent}
                entityType='appointments'
                finder={{
                    contentManager: this.finderContentManager,
                    onGroupSelected: this.handleGroupSelect.bind(this)
                }}
                content={{
                    sharedFilters: {
                        columns: this.allColumns
                    },
                    sharedSearch: true
                }}
            />
        );
    }
}

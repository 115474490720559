import _ from 'underscore';
import $ from 'jquery';

import app from 'js/app';
import AppConfig from 'app/app-config';


export default class MailingListsFetcher {
    constructor(columns) {
        this.columns = columns;
        this.orderByStr = null;
        this.searchTerm = null;
        this.filterByGroupId = {};
    }

    setSectionId() {}
    filterBy() {}
    setFetchArgs() {}
    getFetchArgs() { return {}; }

    searchBy(searchTerm) {
        this.searchTerm = searchTerm;
    }

    getSearchTerm() {
        return this.searchTerm;
    }

    setColumns(columns) {
        this.columns = columns;
    }

    orderBy(columnId, sortDir) {
        this.orderByStr = `${columnId} ${sortDir}`;
    }

    getOrderBy() {
        if (!this.orderByStr) {
            return null;
        }

        const parts = this.orderByStr.split(' ');

        if (parts.length === 2) {
            return {
                columnId: parts[0],
                sortDir: parts[1]
            };
        }

        return null;
    }

    fetch(_, callback) {
        const args = this.getArgs();
        const self = this;

        $.get(`/mailing_lists?${$.param(args)}`, function(result, _, request) {
            const start = parseInt(request.getResponseHeader('Records-Start'));
            let rows = [];

            for (const row of result) {
                let item = self.createGroupInfo(row);

                for (const col of self.columns) {
                    let context = row;

                    for (const p of col.fieldPath || [col.id]) {
                        context = context[p];

                        if (!context) {
                            break;
                        }
                    }

                    item[col.id] = context || '';
                }

                rows.push(item);
            }

            rows = self.getFinalRows(rows);

            callback({
                rows: rows,
                total: rows.length,
                start: 0,
                numRows: rows.length
            });
        });
    }

    createGroupInfo(row) {
        return {
            id: row.id,
            permissions: row.permissions,
            group: {
                type: 'group',
                id: `groups/${row.id}`,
                shortId: row.id,
                name: row.name,
                groupType: row.group_type,
                entityType: 'individuals',
                fetchArgs: { group_id: row.id }
            }
        };
    }

    getArgs() {
        return {
            start: 0,
            rows: -1
        };
    }

    getFinalRows(rows) {
        let finalRows = rows;

        // dynamic groups based on tags
        if (AppConfig.getValue('individuals.groups.show_funnels_tags_adhoc_groups')) {
            const funnels = app.globalData.funnelsInfo.funnels;
            const tags = app.globalData.tags;
            let validTags = [];

            for (const funnel of funnels) {
                if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                    continue;
                }

                const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                if (tag) {
                    validTags.push(tag);
                }
            }

            const now = new Date();
            const self = this;

            for (const vt of validTags) {
                let item = {
                    id: vt.id,
                    permissions: [],
                    group: {
                        type: 'group',
                        id: `groups/${vt.id}`,
                        shortId: vt.id,
                        name: vt.name,
                        special: true,
                        dynamic: true,
                        entityType: 'individuals',
                        toolbar: {
                            saveAs: false
                        },
                        dynamicFilter: function(callback) {
                            if (self.filterByGroupId[vt.id]) {
                                callback(self.filterByGroupId[vt.id]);
                            } else {
                                $.post('/individuals/filters', JSON.stringify({
                                    rules: [[{
                                        field: 'individual_tags',
                                        operator: 'equal',
                                        values: {
                                            id: vt.id,
                                            name: vt.name
                                        }
                                    }]],
                                }), function(filter) {
                                    self.filterByGroupId[vt.id] = filter;
                                    callback(filter);
                                });
                            }
                        }
                    }
                };

                item.name = vt.name;
                item.created = now;
                item.owner = {
                    id: app.user.get('id'),
                    name: app.user.get('name')
                };

                finalRows.unshift(item);
            }
        }

        if (AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')) {
            const funnels = app.globalData.funnelsInfo.funnels;
            let validFunnels = [];

            for (const funnel of funnels) {
                if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                    continue;
                }

                if (funnel) {
                    validFunnels.push(funnel);
                }
            }

            const now = new Date();
            const self = this;

            for (const vt of validFunnels) {
                let item = {
                    id: vt.id,
                    permissions: [],
                    group: {
                        type: 'group',
                        id: `groups/${vt.id}`,
                        shortId: vt.id,
                        name: vt.name,
                        special: true,
                        dynamic: true,
                        entityType: 'individuals',
                        toolbar: {
                            saveAs: false
                        },
                        dynamicFilter: function(callback) {
                            if (self.filterByGroupId[vt.id]) {
                                callback(self.filterByGroupId[vt.id]);
                            } else {
                                $.post('/individuals/filters', JSON.stringify({
                                    rules: [[{
                                        field: 'individual_funnels',
                                        operator: 'equal',
                                        values: {
                                            id: vt.id,
                                            name: vt.name
                                        }
                                    }]],
                                }), function(filter) {
                                    self.filterByGroupId[vt.id] = filter;
                                    callback(filter);
                                });
                            }
                        }
                    }
                };

                item.name = vt.name;
                item.created = now;
                item.owner = {
                    id: app.user.get('id'),
                    name: app.user.get('name')
                };

                finalRows.unshift(item);
            }
        }

        // apply filtering and ordering
        if (this.searchTerm) {
            const stlc = this.searchTerm.toLowerCase();
            finalRows = finalRows.filter(r => r.name.toLowerCase().indexOf(stlc) !== -1);
        }

        const orderBy = this.getOrderBy();

        if (orderBy) {
            finalRows = _.sortBy(finalRows, orderBy.columnId);

            if (orderBy.sortDir !== 'asc') {
                finalRows = finalRows.reverse();
            }
        }

        return finalRows;
    }
}
import _ from 'underscore'
import Backbone from 'backbone'

import BaseContactModel from 'js/models/basecontact'
import LocationModel from 'js/models/location'
import LocationsCollection from 'js/collections/locations'
import CommunicationModel from 'js/models/communication'
import ActivityModel from 'js/models/activity'
import BaseCollection from 'js/collections/base'
import SocialItemModel from 'js/models/social_item'
import SocialItemsCollection from 'js/collections/social'
import UserModel from 'js/models/user'
import vent from 'js/vent'


var IndividualModel = BaseContactModel.extend({
    name: 'Individual',
    urlRoot: '/individuals',
    apiType: 'salesseek.contacts.models.individual.Individual',
    initialize: function() {
        BaseContactModel.prototype.initialize.apply(this, arguments);
        this.listenTo(this, 'sync', function(model, resp) {
            if (!resp) {
                vent.trigger('individual:delete', model);
            }
        });
    },
    validate: function(attrs) {
        var errors = {};

        if (this.isNew()) {
            if (_.isUndefined(attrs.last_name)) {
                errors.missing_last_name = 'Last name required';
            }
        }

        if (!_.isUndefined(attrs.last_name)){
            if (_.isNull(attrs.last_name)) {
                errors.null_attribute = true;
            }
            else if (attrs.last_name.length === 0) {
                errors.last_name_too_short = 'One character minimum required';
            }
        }

        // if location is defined
        if (_.isObject(attrs.locations) && attrs.locations.length !== 0) {
            // this is to trigger validation and stop storing of individual
            _.each(attrs.locations.models, function(location) {
                if ( !location.isValid() ) {
                    errors.missing_location = true;
                }
            });
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    // Join first_name and last_name
    toString: function() {
        return this.get('first_name') + " " + this.get('last_name');
    },
    getPhoto: function() {
        if (!this.get('photo_url')) {
            return 'img/default-profile-1.png'; // Default profile image
        } else {
            return this.get('photo_url');
        }
    }
});
IndividualModel.fromString = function (string) {
    var parts = string.split(' ', 1),
        first_name = parts[0],
        last_name = parts[1];
    return new IndividualModel({first_name: first_name, last_name: last_name});
};

export default IndividualModel;

import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app'
import AppConfig from 'app/app-config'
import MessageBox from 'js/views/message_box'
import htmlSanitizer from 'js/utils/html-sanitizer'
import Utilities from 'js/utils/utilities'
import dateFormat from 'js/utils/date-format'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import guid from 'js/utils/guid'

import style from './conversations.css'


const ITEM_TYPE = {
    opportunity: 'opportunities',
    individual: 'individuals'
};

const NEW_MESSAGES_FETCH_TIME = 60 * 1000; // 1 minute


class Conversation extends React.Component {
    constructor(props) {
        super(props);

        this.participantsColors = {};

        this.state = {
            messages: [],
            inputMessage: '',
            sendDisabled: false,
            attachments: [],
            loading: false
        };
    }

    hasChanges() {
        return (this.state.inputMessage || this.state.attachments.length > 0) && !this.state.sendDisabled;
    }

    componentDidMount() {
        this.mounted = true;

        this.setState({
            loading: true
        });

        const self = this;
        const editorReadOnly = !!this.props.conversation.id;

        const onMessagesLoaded = (messages) => {
            self.setState({
                messages: messages,
                loading: false
            });

            self.scrollToBottom();

            if (editorReadOnly) {
                self.editor.mode.set('design');
            }
        }

        const loadMessages = () => {
            if (self.props.conversation.id) {
                $.get(`/conversation_messages?conversation_id=${self.props.conversation.id}`, function(result) {
                    if (!self.mounted) {
                        return;
                    }

                    onMessagesLoaded(result);
                    self.checkNewMessages();

                    if (self.props.conversation.numNewMessages > 0) {
                        self.props.conversation.numNewMessages = 0;

                        // mark the conversation as read
                        $.post('/conversation_messages/read_by', JSON.stringify({
                            conversation_id: self.props.conversation.id,
                            reader_id: app.user.get('id')
                        }));
                    }
                });
            } else {
                _.defer(function() {
                    onMessagesLoaded([]);
                });
            }
        }

        $(this.inputEditor).tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor attachments',
            height: 200,
            resize: false,
            statusbar: false,
            readonly: editorReadOnly,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            setup: function(ed) {
                ed.ui.registry.addButton('attachments', {
                    text: 'Attach File',
                    onAction: function() {
                        self.fileSelector.click();
                    }
                });

                ed.on('keydown', function (ev) {
                    if (ev.keyCode === 13 && ev.shiftKey === true) {
                        ev.preventDefault();
                        self.handleSend();
                    }
                });
            },
            oninit: function() {
                self.editor = this.activeEditor;
                self.editor.focus();

                loadMessages();

                self.editor.on('Paste NodeChange Change input Undo Redo', function() {
                    self.setState({
                        inputMessage: self.editor.getContent()
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.editor) {
            this.editor.remove();
        }
    }

    checkNewMessages() {
        const self = this;

        setTimeout(function() {
            if (!self.mounted) {
                return;
            }

            const date = new Date(Date.now() - NEW_MESSAGES_FETCH_TIME * 2);

            $.get(`/conversation_messages?conversation_id=${self.props.conversation.id}&created_after=${date.toISOString()}`, function(result) {
                if (!self.mounted) {
                    return;
                }

                result = result.filter(m => {
                    return !self.state.messages.find(m2 => m2.id === m.id);
                });

                if  (result.length > 0) {
                    self.setState({
                        messages: [...self.state.messages, ...result]
                    });

                    self.scrollToBottom();

                    // mark the conversation as read
                    $.post('/conversation_messages/read_by', JSON.stringify({
                        conversation_id: self.props.conversation.id,
                        reader_id: app.user.get('id')
                    }));
                }

                self.checkNewMessages();
            });
        }, NEW_MESSAGES_FETCH_TIME);
    }

    scrollToBottom() {
        this.container.scrollTop = this.container.scrollHeight;
    }

    handleSend() {
        if (!this.hasChanges()) {
            return;
        }

        const self = this;
        let attachmentsInfo = [];

        const createMessage = (conversationId) => {
            let uuid = guid();

            let newMessage = {
                id: uuid,
                conversation_id: conversationId,
                creator_type: 'user',
                creator_id: app.user.get('id'),
                creator: app.user,
                message: self.state.inputMessage,
                sending: true
            };

            if (attachmentsInfo.length > 0) {
                newMessage.attachment_ids = attachmentsInfo.map(a => a.id);
            }

            self.setState({
                messages: [...self.state.messages, newMessage],
                attachments: [],
                inputMessage: ''
            });

            self.editor.setContent('');

            _.defer(function() {
                self.scrollToBottom();
                self.editor.focus();
            });

            $.post('/conversation_messages', JSON.stringify(newMessage), function(result) {
                const messages = [...self.state.messages.filter(m => !m.sending || m.id !== uuid), result];

                self.setState({
                    messages: messages
                });

                self.props.onConversationMessagesUpdated(conversationId, messages);
                self.sendNewMessageEmail(newMessage.message);
            });
        }

        const sendMessage = () => {
            // if the conversation doesnt exist yet we have to create it
            const conversation = self.props.conversation;

            if (conversation.id) {
                createMessage(conversation.id);
            } else {
                self.setState({
                    sendDisabled: true
                });

                $.post('/conversations', JSON.stringify({
                    fixed_id: conversation.fixedId,
                    category: AppConfig.getValue('conversations.default_category', 'message'),
                    creator_type: 'user',
                    creator_id: app.user.get('id'),
                    related_type: self.props.type,
                    related_id: self.props.model.get('id'),
                    creator_is_not_a_participant: true,
                    participants: conversation.participants.map(p => {
                        return {
                            participant_type: 'individual',
                            participant_id: p.id,
                            status: 'active'
                        };
                    })
                }), function(result) {
                    conversation.id = result.id;
                    createMessage(result.id);

                    self.setState({
                        sendDisabled: false
                    });
                });
            }
        }

        if (this.state.attachments.length > 0) {
            $.ajax({
                url: `/${ITEM_TYPE[this.props.type]}/${this.props.model.get('id')}/related_files`,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    files: this.state.attachments
                }),
                complete: function(data) {
                    if (data && data.responseJSON) {
                        attachmentsInfo = data.responseJSON.map(f => {return {id: f.id, name: f.name}});
                    }

                    sendMessage();
                }
            });
        } else {
            sendMessage();
        }
    }

    sendNewMessageEmail(message) {
        const preferences = app.user.get('client').preferences || {};
        const campaignId = preferences.conversations_new_message_email_template_id;

        if (!campaignId) {
            return;
        }

        const sendEmail = () => {
            const emailInfo = app.globalData.newMessageEmailTemplate;

            for (const p of this.props.conversation.participants) {
                if (p.id === 'aftercare') {
                    continue;
                }

                let urlArgs = '';

                if (this.props.type === 'opportunity') {
                    urlArgs = `?issue_id=${this.props.model.get('id')}&active_tab=messages&conversation_id=${this.props.conversation.id}`;
                } else {
                    urlArgs = `?conversation_id=${this.props.conversation.id}`;
                }

                let body = emailInfo.body;
                body = Utilities.replaceAll(body, '${message_body}', message);
                body = Utilities.replaceAll(body, '${aftercare_url_args}', urlArgs);

                $.post('/send_email', JSON.stringify({
                    trigger_id: p.id,
                    trigger_type: 'individual',
                    from_id: emailInfo.from,
                    from_type: 'user',
                    subject: emailInfo.subject,
                    body: body,
                    create_activity_log: false
                }));
            }
        }

        if (app.globalData.newMessageEmailTemplate) {
            sendEmail();
        } else {
            $.get(`/campaigns/${campaignId}`, function(response) {
                if (response) {
                    app.globalData.newMessageEmailTemplate = {
                        from: response.from_user_id,
                        subject: response.subject,
                        body: response.content,
                    };

                    sendEmail();
                }
            });
        }
    }

    handleAttachments(ev) {
        const files = [];
        const self = this;

        for (const file of this.fileSelector.files) {
            let image = {
              filename: file.name
            };

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function() {
                image.file = reader.result.substring(reader.result.indexOf(',') + 1) // remove file information header

                self.setState((prevState) => {
                    return {
                        attachments: [...prevState.attachments, image]
                    };
                });
            };
        }
    }

    deleteAttachment(attachment) {
        this.setState({
            attachments: this.state.attachments.filter(a => a.filename !== attachment.filename)
        });
    }

    downloadAttachment(attachment) {
        window.open(`/api/content_files/${attachment.id}?download`, '_blank');
    }

    render() {
        const participants = this.props.conversation.participants;
        const messages = this.state.messages;

        return (
            <div className={style.cConversation}>
                <div
                    ref={(el) => this.container = el}
                    className={style.cMessages}
                >
                    {this.state.loading &&
                        <LoadingIndicator/>
                    }

                    {!this.state.loading && messages.map((m, midx) => {
                        const writtenByMe = m.creator_id === app.user.get('id');
                        let sentIcon = writtenByMe ? 'icon-reply' : 'icon-quill';
                        let sentClass = '';

                        if (m.sending) {
                            sentIcon = 'icon-alarm';
                        } else if (m.error) {
                            sentIcon = 'icon-cross';
                            sentClass = style.sError;
                        }

                        const name = m.creator.name || m.creator.full_name || '';

                        if (!this.participantsColors[m.creator.id]) {
                            this.participantsColors[m.creator.id] = AppConfig.getValue(`conversations.participant_color_${this.props.type}`, '#777777', m.creator);
                        }

                        return (
                            <div
                                key={`m_${midx}`}
                                className={`
                                    ${style.mMessage}
                                    ${writtenByMe ? style.mWrittenByMe : ''}
                                `}
                            >
                                <div
                                    className={style.mBubble}
                                >
                                    {!writtenByMe &&
                                        <div
                                            className={style.bAuthor}
                                            style={{
                                                color: this.participantsColors[m.creator.id]
                                            }}
                                        >
                                            {name}
                                        </div>
                                    }

                                    <div
                                        dangerouslySetInnerHTML={{ __html: htmlSanitizer.sanitize(m.message)}}
                                    />

                                    {m.attachments &&
                                        <div className={style.bAttachments}>
                                            {m.attachments.map(a => {
                                                const ext = `.${a.name.split('.').pop()}`;
                                                const icon = Utilities.getTypeIcon(ext).icon;

                                                return (
                                                    <div
                                                        key={`a_${a.id}`}
                                                        className={style.bAttachment}
                                                        onClick={() => this.downloadAttachment.bind(this)(a)}
                                                    >
                                                        <div className={icon}/>
                                                        <div className={style.aName}>{a.name}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }

                                    <div className={`${style.bSent} ${sentClass}`}>
                                        <i className={sentIcon}/>
                                        {m.created && <div>{dateFormat.shortTimelapseFormat(dateFormat.parseDate(m.created))}</div>}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className={style.cInputContainer}>
                    <div ref={(el) => this.inputEditor = el}/>
                    <div className={style.iAttachmentsContainer}>
                        <div className={`icon-paperclip ${style.aIcon}`}/>

                        {this.state.attachments.map((a, aidx) => {
                            return (
                                <div
                                    key={`attach_${aidx}`}
                                    className={style.aAttachment}
                                >
                                    <div>{a.filename}</div>
                                    <div
                                        className={`icon-cross ${style.aDelete}`}
                                        onClick={() => this.deleteAttachment.bind(this)(a)}
                                    />
                                </div>
                            );
                        })}
                    </div>

                    <input
                        ref={(el) => this.fileSelector = el}
                        type='file'
                        multiple={true}
                        accept='image/*'
                        style={{
                            display: 'none'
                        }}
                        onChange={this.handleAttachments.bind(this)}
                    />
                </div>

                <div className={style.iFooter}>
                    <div className={style.fMessage}>Shift + Enter to post</div>
                    <div
                        className={`
                            ${style.fSend}
                            ${!this.hasChanges() ? style.sDisabled : ''}
                        `}
                        onClick={this.handleSend.bind(this)}
                    >
                        Send
                    </div>
                </div>
            </div>
        );
    }
}


class Conversations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conversations: [],
            activeConversation: null
        };
    }

    componentDidMount() {
        // fetch conversations info
        let conversationsInfo = AppConfig.getValue(`conversations.conversations_list_${this.props.type}`, null, this.props.model);

        if (conversationsInfo === null || (conversationsInfo.fixedConversations && conversationsInfo.conversations.length === 0)) {
            return;
        }

        const self = this;
        const conversations = conversationsInfo.conversations || [];
        let newMessages = false;

        $.get(`/conversations?related_type=${this.props.type}&related_id=${this.props.model.get('id')}&extra_info=true`, function(result) {
            for (const row of result) {
                if (!row.fixed_id) {
                    continue;
                }

                let conversation = conversations.find(c => c.fixedId === row.fixed_id);

                if (conversation) {
                    conversation.id = row.id;
                    conversation.participants = row.participants.map(p => {
                        return {
                            id: p.participant_id,
                            type: p.participant_type,
                            name: p.participant_name
                        };
                    });
                } else { // is it a manually created conversation?
                    const conversationFixedIdParts = row.fixed_id.split(':');

                    if (conversationFixedIdParts.length > 1) {
                        const baseConversation = conversations.find(c => c.fixedId === conversationFixedIdParts[0]);

                        if (baseConversation) {
                            conversations.push({
                                id: row.id,
                                fixedId: row.fixed_id,
                                name: baseConversation.name,
                                bubbles: baseConversation.bubbles,
                                participants: _.clone(baseConversation.participants)
                            });

                            conversation = conversations[conversations.length - 1];
                        } else if (row.id) {
                            conversations.push({
                                id: row.id,
                                fixedId: row.fixed_id,
                                name: row.participants[0]?.participant_name || '',
                                bubbles: [{
                                    icon: 'icon-user',
                                    background: '#FF8900'
                                }],
                                participants: row.participants.map(p => {
                                    return {
                                        id: p.participant_id,
                                        type: p.participant_type,
                                        name: p.participant_name
                                    };
                                })
                            });

                            conversation = conversations[conversations.length - 1];
                        }
                    }
                }

                if (conversation) {
                    conversation.numNewMessages = row.extra_info.unread_count;
                    conversation.lastMessage = row.extra_info.last_message;
                    conversation.numMessagesWrittenByMe = row.extra_info.my_messages_count; // todo: change this

                    if (conversation.numNewMessages > 0) {
                        newMessages = true;
                    }
                }
            }

            // get participants info
            let entitiesToFetch = [];

            for (let c of conversations) {
                for (let p of c.participants) {
                    if (!p.name && !entitiesToFetch.find(ptt => ptt.id === p.id)) {
                        entitiesToFetch.push({
                            id: p.id,
                            type: p.type
                        });
                    }
                }
            }

            let numEntitiesToFetch = entitiesToFetch.length;

            if (numEntitiesToFetch === 0) {
                self.props.badge.toggleClass('hidden', !newMessages);

                self.setState({
                    conversations: conversations,
                    activeConversation: conversations.find(c => c.id && self.props.conversationId && c.id === self.props.conversationId)
                });
            } else {
                for (const i of entitiesToFetch) {
                    $.get(`/${ITEM_TYPE[i.type]}/${i.id}`, function(entity) {
                        const name = entity.full_name || entity.name || '';

                        for (let c of conversations) {
                            for (let p of c.participants) {
                                if (p.id === entity.id) {
                                    p.name = name;
                                }
                            }
                        }

                        --numEntitiesToFetch;

                        if (numEntitiesToFetch === 0) {
                            self.props.badge.toggleClass('hidden', !newMessages);

                            self.setState({
                                conversations: conversations,
                                activeConversation: conversations.find(c => c.id && self.props.conversationId && c.id === self.props.conversationId)
                            });
                        }
                    });
                }
            }
        });
    }

    handleConversationClick(conversation) {
        this.setState({
            activeConversation: conversation
        });
    }

    handleCancelClick() {
        const self = this;

        const gotoBack = () => {
            self.setState({
                activeConversation: null
            });

            let badgeVisible = false;

            for (const c of self.state.conversations) {
                badgeVisible |= c.numNewMessages > 0;
            }

            self.props.badge.toggleClass('hidden', !badgeVisible);
        }

        if (this.conversationEl && this.conversationEl.hasChanges()) {
            const content = {
                message: 'Are you sure you want to navigate away from this page?',
                icon: 'icon-warning'
            };

            MessageBox.showYesNo(content, this.props.parent, function() {
                gotoBack();
            });
        } else {
            gotoBack();
        }
    }

    handleConversationMessagesUpdated(conversationId, messages) {
        let conversation = this.state.conversations.find(c => c.id === conversationId);

        if (conversation) {
            const lastMessage = messages[messages.length - 1];

            conversation.lastMessage = {
                message: lastMessage.message,
                created: lastMessage.created
            };
        }
    }

    render() {
        if (this.state.activeConversation) {
            const c = this.state.activeConversation;

            return (
                <div className={style.conversations}>
                    <div className={style.csHeader}>
                        {c.participants.length === 1 ? (
                            <div
                                className={style.chThumb}
                                style={{
                                    background: c.bubbles[0].background
                                }}
                            >
                                <div className={`${style.ctIcon} ${c.bubbles[0].icon}`}/>
                            </div>
                        ) : (
                            <div className={style.chThumbs}>
                                {c.bubbles.map((b, pidx) => {
                                    return (
                                        <div
                                            key={`b_${pidx}`}
                                            className={style.chThumb}
                                            style={{
                                                background: b.background
                                            }}
                                        >
                                            <div className={`${style.ctIcon} ${b.icon}`}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        <div className={style.chName}>
                            {c.name}
                        </div>

                        <div
                            className={`${style.chCancel} icon-cross`}
                            onClick={this.handleCancelClick.bind(this)}
                        />
                    </div>

                    <Conversation
                        ref={(el) => this.conversationEl = el}
                        conversation={this.state.activeConversation}
                        type={this.props.type}
                        model={this.props.model}
                        onConversationMessagesUpdated={this.handleConversationMessagesUpdated.bind(this)}
                    />
                </div>
            );
        }

        return (
            <div className={style.conversations}>
                {this.state.conversations.map((c, cidx) => {
                    let conversationClasses = [style.cConversationInfo];
                    let timestamp = null;
                    let lastMessage = 'No messages';
                    let buttonMessage = null;

                    if (!c.lastMessage) {
                        buttonMessage = 'Start Conversation';
                    } else {
                        lastMessage = Utilities.replaceAll(c.lastMessage.message.split('</p>')[0], '<p>', '');
                        timestamp = dateFormat.timelapseFormat(dateFormat.parseDate(c.lastMessage.created));

                        if (c.participants.length > 1 && c.numMessagesWrittenByMe === 0) {
                            buttonMessage = 'Join Conversation';
                        }
                    }

                    if (c.numNewMessages > 0) {
                        conversationClasses.push(style.cNewMessages)
                    }

                    return (
                        <div
                            key={`conversation_${cidx}`}
                            className={conversationClasses.join(' ')}
                            onClick={() => this.handleConversationClick.bind(this)(c)}
                        >
                            <div className={style.cHeader}>
                                {c.participants.length === 1 ? (
                                    <div
                                        className={style.hThumb}
                                        style={{
                                            background: c.bubbles[0].background
                                        }}
                                    >
                                        <div className={`${style.tIcon} ${c.bubbles[0].icon}`}/>
                                    </div>
                                ) : (
                                    <div className={style.hThumbs}>
                                        {c.bubbles.map((b, pidx) => {
                                            return (
                                                <div
                                                    key={`b_${pidx}`}
                                                    className={style.hThumb}
                                                    style={{
                                                        background: b.background
                                                    }}
                                                >
                                                   <div className={`${style.tSmallIcon} ${b.icon}`}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                                <div className={style.hName}>
                                    {c.name}
                                </div>

                                {timestamp &&
                                    <div className={style.hTimestamp}>
                                        {timestamp}
                                    </div>
                                }
                            </div>

                            <div className={style.cMessageContainer}>
                                <div
                                    className={style.mMessage}
                                    dangerouslySetInnerHTML={{ __html: htmlSanitizer.sanitize(lastMessage)}}
                                />

                                {buttonMessage &&
                                    <div className={style.mButton}>
                                        {buttonMessage}
                                    </div>
                                }
                            </div>

                            {c.numNewMessages > 0 &&
                                <div
                                    className={`
                                        ${style.cBadge}
                                        ${c.participants.length > 1 ? style.bInverted : ''}
                                    `}
                                >
                                    {c.numNewMessages}
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}

const ConversationsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        this.renderReact();
    },
    renderReact() {
        ReactDOM.render(
            <Conversations
                parent={this}
                type={this.options.type}
                model={this.options.model}
                badge={this.options.badge}
                conversationId={this.options.conversationId}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default ConversationsView;

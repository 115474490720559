import React from 'react';
import dateFormat from 'js/utils/date-format'

import style from './time-span.css';

const DefaultTimeSpans = [{id: 'all', name: 'All Time'}, {id: 'day', name: 'Day'}, {id: 'week', name: 'Week'}, {id: 'month', name: 'Month'},
                          {id: 'quarter', name: 'Quarter'}, {id: 'year', name: 'Year'}, {id: 'custom', name: 'Custom'}];


export default class extends React.Component {
    constructor(props) {
        super(props);

        this.components = {};
        this.timeSpans = DefaultTimeSpans;
        this.selectSpan = this.selectSpan.bind(this);
        this.onFocusRange = this.onFocusRange.bind(this);
        this.onChangeRange = this.onChangeRange.bind(this);

        this.state = {
            activeSpan: this.timeSpans[0].id,
            customRangeVisible: false,
            beginDate: '',
            endDate: ''
        }
    }

    selectSpan(spanId) {
        this.setState({
            activeSpan: spanId,
            customRangeVisible: spanId === 'custom',
            beginDate: '',
            endDate: ''
        });

        if (spanId !== 'custom') {
            this.props.onSpanChanged(spanId);
        }
    }

    onFocusRange(which) {
        const target = $(this.components[which]);

        target.datepicker({
            numberOfMonths: 2,
            dateFormat: 'M dd, yy',
            showAnim: 'fadeIn',
            showButtonPanel: true,
            changeYear: true,
            timezone: '+0000'
        });

        const self = this;

        target.on('change', function() {
            self.onChangeRange(which, target.val());
        });
    }

    onChangeRange(which, value) {
        this.setState({[which]: value});

        const self = this;

        _.defer(function() {
            if (self.state.beginDate && self.state.endDate) {
                self.props.onSpanChanged('custom', {
                    beginDate: dateFormat.formatYYYYMMDDDate(self.state.beginDate),
                    endDate: dateFormat.formatYYYYMMDDDate(self.state.endDate)
                });
            }
        });
    }

    render() {
        return (
            <div className={style.TimeSpan}>
                <div className={style.SpanContainer}>
                    {this.timeSpans.map(span => {
                        return (
                            <div
                                key={span.id}
                                className={`${style.Span} ${this.state.activeSpan === span.id ? style.Active : ''}`}
                                onClick={() => this.selectSpan(span.id)}
                            >
                                {span.name}
                            </div>
                        );
                    })}
                </div>
                {this.state.customRangeVisible &&
                    <div className={style.CustomRange}>
                        <input
                            ref={(el) => this.components['beginDate'] = el}
                            className={style.CustomValue}
                            type='text'
                            value={this.state.beginDate}
                            placeholder='Begin Date'
                            onFocus={() => this.onFocusRange('beginDate')}
                        />
                        <div className={style.Separator}>-</div>
                        <input
                            ref={(el) => this.components['endDate'] = el}
                            className={style.CustomValue}
                            type='text'
                            value={this.state.endDate}
                            placeholder='End Date'
                            onFocus={() => this.onFocusRange('endDate')}
                        />
                    </div>
                }
            </div>
        );
    }
}

import React from 'react';
import classnames from 'classnames';
import _ from 'underscore';

import style from './list-pager.css';

class ListPager extends React.Component {
    render() {
        const {
            start,
            currentPageLength,
            total,
            onClickNext,
            onClickPrev
        } = this.props;

        return (
            <div className={style.ListPager}>
                <div
                    className={classnames(
                        style.pageNav,
                        { [style.disabled]: start === 0 }
                    )}>
                    <a className={style.pageLink} onClick={onClickPrev}>Prev</a>
                </div>
                <div className={style.itemCount}>
                    {start + 1}–{start + currentPageLength} of {total}
                </div>
                <div
                    className={classnames(
                        style.pageNav,
                        { [style.disabled]: start + currentPageLength >= total }
                    )}>
                    <a className={style.pageLink} onClick={onClickNext}>Next</a>
                </div>
            </div>
        );
    }
}

export default ListPager;

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import React from 'react'
import ReactDOM from 'react-dom'

import FilterBarView from 'js/views/filters/bar'
import ActivityCreator from 'js/utils/activity_creator'
import GroupModel from 'js/models/group'
import ModalRegion from 'js/views/base/modal-region'
import TextManager from 'app/text-manager'
import app from 'js/app'
import vent from 'js/vent'
import security from 'js/utils/security'
import linkify from 'js/utils/linkify'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import ForecastModel from 'js/models/forecast'
import IndividualModel from 'js/models/contact'
import OrganizationModel from 'js/models/organization'
import OpportunityModel from 'js/models/opportunity'
import IndividualFilterModel from 'js/models/individual_filter'
import OrganizationFilterModel from 'js/models/organization_filter'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import ActivityFilterModel from 'js/models/activity_filter'
import TaskFilterModel from 'js/models/task_filter'
import TaskModel from 'js/models/task'
import CampaignFilterModel from 'js/models/campaign_filter'
import PeriodsCollection from 'js/collections/periods'
import PhasesCollection from 'js/collections/phases'
import ForecastsCollection from 'js/collections/forecasts'
import BulkEditView from 'js/views/bulk_edit'
import MergeView from 'js/views/merge'
import ColumnSelectView from 'js/views/base/column-select'
import CustomFieldsCollection from 'js/collections/custom_fields'
import BucketsCollection from 'js/collections/buckets'
import CampaignsCollection from 'js/collections/campaigns'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import DemographicMap from 'js/react_views/demographic-map/demographic-map'
import {Calendar, CalendarDateSelector} from 'js/react_views/calendar/calendar'
import GroupInsights from 'js/react_views/group-insights/group-insights'
import appContent from 'js/views/appcontent'
import IODListingBaseTemplate from 'app/_components/IOD-listing/IOD-listing-base.handlebars'
import IODListingOverviewBaseTemplate from 'app/_components/IOD-listing/IOD-listing-overview-base.handlebars'
import IODListingMenuTemplate from 'app/_components/IOD-listing/IOD-listing-menu.handlebars'
import IODListingDuplicateTemplate from 'app/_components/IOD-listing/IOD-listing-duplicate.handlebars'
import IODListingCFMenuTemplate from 'app/_components/IOD-listing/IOD-listing-fields-menu.handlebars'
import IODListingCFMenuItemTemplate from 'app/_components/IOD-listing/IOD-listing-field-menu.handlebars'
import { TagList } from 'js/react_views/detail_view_components/tag-list';
import backboneSelect2 from 'js/widgets/backbone-select2'
import AppConfig from 'app/app-config'


var specialGroups = {
    'individuals': 'all',
    'organizations': 'all',
    'myIndividuals': 'owned',
    'myOrganizations': 'owned',
    'favorites': 'favorites',
    'recentIndividuals': 'recents',
    'recentOrganizations': 'recents',
    'recentCreatedIndividuals': 'created',
    'recentCreatedOrganizations': 'created',
    'active': 'user_deals',
    'won': 'won_deals',
    'lost': 'lost_deals',
    'my': 'owned',
    'team': 'team_owned',
    'elephants': 'favorites'
};

// ------------------------------------------------
var GroupInsightsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'group-insights-modal');
    },
    onRender: function() {
        ReactDOM.render(
            <GroupInsights
                elementType={this.options.elementType}
                columnId={this.options.columnId}
                columnName={this.options.columnName}
                fetchOptions={this.options.fetchOptions}
                onClose={() => this.trigger('groups-insights:close')}
            />,
            this.$el.get(0)
        );
    },
});

// ------------------------------------------------
var MapView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <DemographicMap
                ref={(el) => this.component = el}
                group={this.options.group}
                onShowEntity={this.onShowEntity.bind(this)}
            />,
            this.$el.get(0)
        );
    },
    onShowEntity(entityId) {
        let model = null;

        switch (this.options.group.element_type) {
            case 'individuals':
                model = new IndividualModel({id: entityId});
                break;

            case 'organizations':
                model = new OrganizationModel({id: entityId});
                break;

            case 'opportunities':
                model = new OpportunityModel({id: entityId});
                break;
        }

        if (model) {
            this.trigger('showItem', model);
        }
    }
});

// ------------------------------------------------
var CalendarView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    handleDateSpanChange: function(date, span) {
        this.trigger('on-date-span-change', date, span);
    },
    handleEditTask: function(task) {
        vent.trigger('quick:edit:task', task);
    },
    handleCreateTask: function(dueDate) {
        vent.trigger('quick:add:task', new TaskModel({ 'due_date':  dueDate}));
    },
    onShow: function() {
        const self = this;

        this.listenTo(vent, 'section-sidebar:resize', function() {
            self.component.handleWindowResize();
        });
    },
    onRender: function() {
        ReactDOM.render(
            <Calendar
                ref={(el) => this.component = el}
                entityType='tasks'
                date={this.options.date}
                span={this.options.span}
                onDateSpanChange={this.handleDateSpanChange.bind(this)}
                onEditTask={this.handleEditTask.bind(this)}
                onCreateTask={this.handleCreateTask.bind(this)}
            />,
            this.$el.get(0)
        );
    }
});

// ------------------------------------------------
var CalendarDateSelectorView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    handleDateChange: function(date) {
        this.options.calendar.component.setCurrentDate(date);
    },
    handleSpanChange: function(span) {
        this.options.calendar.component.setSpan(span);
    },
    handleDateSpanChange: function(date, span) {
        this.options.calendar.component.setDateSpan(date, span);
    },
    onRender: function() {
        this.$el.css('height', '100%');

        ReactDOM.render(
            <CalendarDateSelector
                ref={(el) => this.component = el}
                date={this.options.date}
                span={this.options.span}
                onDateChange={this.handleDateChange.bind(this)}
                onSpanChange={this.handleSpanChange.bind(this)}
                onDateSpanChange={this.handleDateSpanChange.bind(this)}
            />,
            this.$el.get(0)
        );
    }
});

// ------------------------------------------------
var FieldItemView = Marionette.Layout.extend( {
    tagName: 'li',
    template: Handlebars.compile( IODListingCFMenuItemTemplate ),
    templateHelpers: function() {
        return {
            name: this.model.get('name'),
            type: this.getCFType(this.model.get('type')) };
    },
    ui: {
        name: '.name'
    },
    events: {
        'click .basic-list-item': function(ev) {
            ev.preventDefault();
            this.options.parent.trigger('create-static-group-from-field', this.model);

            this.close();
        }
    },
    getCFType: function(type){
        if (type === 'individual'){
            return TextManager.getText('ID_INDIVIDUAL');
        } else if (type === 'organization'){
            return TextManager.getText('ID_ORGANIZATION');
        } else if (type === 'opportunity'){
            return TextManager.getText('ID_DEAL');
        } else {
            return type
        }
    }
});

var FieldsList = Marionette.CollectionView.extend( {
    tagName: 'ul',
    className: 'basic-list popover-list',
    itemView: FieldItemView,
    initialize: function() {
        this.itemViewOptions = {parent: this};
    }
} );

var CFMenuView = Marionette.Layout.extend({
    template: Handlebars.compile(IODListingCFMenuTemplate),
    regions: {
        fieldsList: '.static-group-field-items',
    },
    templateHelpers: function() {
        return this.options;
    },
    events: {
    },
    initialize: function(options) {
        _.extend(this, options);

    },
    onRender: function() {
        var view = this;

        var filtered = new Backbone.Collection(this.fields.filter(function(model) {
            return model.get('type') === 'individual' || model.get('type') === 'organization' || model.get('type') === 'opportunity';
        }));

        var list = new FieldsList( { collection: filtered } );

        this.listenTo(list, 'create-static-group-from-field', function(cf) {
            this.parent.createStaticGroupFromField(cf);
            this.close();
        });

        this.fieldsList.show(list);

        // Close menu on mousedown
        $(document).on('mousedown.close-item-menu', function(ev) {
            if (!$.contains(ev.target, view.$el) && !$(ev.target).closest(view.$el).length && !$(ev.target).closest(view.parent).length) {
                view.close();
            }
        });
    },
    onClose: function() {
       $(document).off('mousedown.close-item-menu');
    }
});

var RenameGroupView = Marionette.Layout.extend({
    template: Handlebars.compile(IODListingDuplicateTemplate),
    templateHelpers: function() {
        return {
            title: this.title,
            name: this.name,
            placeholder: this.options.placeholder || 'Group name'
        };
    },
    ui: {
        name: '.group-name',
        tagsInput: '.tags-input',
    },
    events: {
        'click .save': function(ev) {
            ev.preventDefault();

            this.trigger('save', this.ui.name.val(), this.tagsSelect.getData());

            this.close();
        },
        'click .close': function(ev) {
            ev.preventDefault();

            this.close();
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onShow: function() {
        this.tagsSelect = new backboneSelect2.TagView({
            view: this,
            $el: this.ui.tagsInput,
            id: 'id',
            text: 'name',
            url: '/tags',
            search: true,
            createSearchChoice: true,
            options: {
                placeholder:'+ Add Tag',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'tag-select-popover popover',
                multiple: true,
                tokenSeparators: [',']
            }
        });
    },
});

var MenuView = Marionette.Layout.extend({
    template: Handlebars.compile(IODListingMenuTemplate),
    templateHelpers: function() {
        return this.options;
    },
    ui: {
        showPermissions: '.show-permissions'
    },
    events: {
        'click .edit-item:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('edit');

            this.close();
        },
        'click .show-permissions.has-permission': function(ev) {
            ev.preventDefault();

            this.trigger('permissions');

            this.close();
        },
        'click .edit-columns:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('edit-columns');

            this.close();
        },
        'click .download-item:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('download-item');

            this.close();
        },
        'click .download-templated-report:not(.disabled)': function(ev) {
            ev.preventDefault();
            this.trigger('download-templated-report');
            this.close();
        },
        'click .duplicate-static:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('duplicate-static');

            this.close();
        },
        'click .duplicate-static-from-field:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('duplicate-static-from-field');
        },
        'click .duplicate-smart:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('duplicate-smart');

            this.close();
        },
        'click .save-as-forecast:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('save-as-forecast');

            this.close();
        },
        'click .download-financial-report:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('download-financial-report');

            this.close();
        },
        'click .delete-item:not(.disabled)': function(ev) {
            ev.preventDefault();

            this.trigger('delete');

            this.close();
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        var view = this;

        this.$el.tooltip();

        // Close menu on mousedown
        $(document).on('mousedown.close-item-menu', function(ev) {
            if (!$.contains(ev.target, view.$el) && !$(ev.target).closest(view.$el).length && !$(ev.target).closest(view.parent).length) {
                view.close();
            }
        });

        // ...
        if (app.user.featureTierIsStarting()) {
            this.ui.showPermissions.tooltip({
                title: 'Upgrade account to access Permissions',
                container: 'body',
                placement: 'bottom'
            });
        }
    },
    onClose: function() {
       $(document).off('mousedown.close-item-menu');
    }
});

var OverviewView = Marionette.Layout.extend({
    className: 'overview',
    template: Handlebars.compile(IODListingOverviewBaseTemplate),
    ui: {
        header: '.detail-header',
        nav: '.detail-nav',
        title_menu: '.item-menu',
        filterBadge: '.filters-toggle .badge',
        filters_toggle: '.filters-toggle',
        filters_container: '.smart-filters-container',
        tooltips: '[data-toggle=tooltip]',
        downloadForm: '.download-form',
        downloadFormFinancialReport: '.download-form-financial-report',
        list_pane: '.group-content',
        table_pane: '.group-table',
        map_pane: '.group-map',
        calendar_pane: '.group-calendar',
        display_comments: '.info-toggle',
        tagsContainer: '.tags-container',
    },
    events: {
        'change .layout-mode .radios input[type=radio]': function(ev) {
            var radio = $(ev.currentTarget),
                info = this.$el.find('.info-toggle');

            ev.preventDefault();

            this.trigger('layout-mode-change');

            if (radio.is(':checked')) {
                if (info.hasClass('active')) {
                    info.trigger('click');
                }
                // Be specific because of conflicts with deals section
                this.$el.find('.info-pane.tab-pane, .group-content.tab-pane, .group-table.tab-pane, .group-map.tab-pane, .group-calendar.tab-pane').removeClass('active');
                this.$el.find('#' + radio.val()).addClass('active');

                if (radio.hasClass('map-mode-radio') && this.elementType !== 'opportunities') { // the opportunities view displays the map after the funnel animation
                    const self = this;

                    _.defer(function() {
                        self.mapRegion.currentView.component.show();
                    });
                } else if (this.mapRegion && this.mapRegion.currentView) {
                    this.mapRegion.currentView.component.hide();
                }

                if (radio.hasClass('calendar-mode-radio')) {
                    this.calendarRegion.currentView.component.show(this.filter, this.model ? this.model.get('id') : null);
                    this.titleWidgetsRegion.currentView.component.show();
                } else if (this.calendarRegion && this.calendarRegion.currentView) {
                    this.calendarRegion.currentView.component.hide();
                    this.titleWidgetsRegion.currentView.component.hide();
                }

                // ...
                if (this.model && security.checkPermission('edit', this.model)) {
                    var new_value = 'list';

                    if (radio.val().indexOf('table') !== -1) {
                        new_value = 'table';
                    } else if (radio.val().indexOf('map') !== -1) {
                        new_value = 'map';
                    } else if (radio.val().indexOf('calendar') !== -1) {
                        new_value = 'calendar';
                    }

                    if (new_value !== 'map' && new_value !== 'calendar') {
                        var display_options = this.model.get('display_options') || {};

                        if (display_options.show_as !== new_value) {
                            display_options.show_as = new_value;

                            this.model.set('display_options', display_options);
                            this.model.save({
                                display_options: display_options
                            }, {
                                patch: true
                            });
                        }
                    }
                }
            }
        },
        'focus .layout-mode input[type=radio]': function(ev) {
            $(ev.currentTarget).closest('.radios').addClass('has-focus');
        },
        'blur .layout-mode input[type=radio]': function(ev) {
            $(ev.currentTarget).closest('.radios').removeClass('has-focus');
        },
        'click .filters-toggle': function(ev) {
            var toggle = this.ui.filters_toggle,
                container = this.ui.filters_container;

            ev.preventDefault();

            toggle.toggleClass('active', container.is(':hidden'));

            // TODO: Animation logic should probably be moved into FilterBarView
            if (container.is(':hidden')) {
                container
                    .show(0, function() {
                        $(this).trigger('resize')
                            .find('.smart-rule-field')
                            .animate({ opacity: 1 }, 250);
                    })
                    .find(':focusable')
                    .focus();
            }
            else {
                container
                    .hide(0, function() {
                        $(this).trigger('resize');
                        $(this).find('.smart-rule-field').css('opacity', 0);
                    });
            }
        },
        'click .detail-nav .title-menu': function(ev) {
            ev.preventDefault();

            if (this.noTitleMenu) {
                return;
            }

            if (this.menuRegion.$el && this.menuRegion.$el.is(':visible')) {
                this.menuRegion.$el.hide();
            } else {
                this.menuRegion.show(new MenuView(_.extend({
                    parent: this.$el.find('.detail-nav .title-menu')
                }, this.getMenuOptions())));
            }
        },
        'click .share-group': function(ev) {
            ev.preventDefault();
            var self = this;

            MessageBox.showYesNo(
                {
                    icon: 'icon-user',
                    message: 'Which users would you like to share this group with?',
                    accept_button_text: 'Send Now',
                    cancel_button_text: 'Cancel'
                },
                self,
                function(userIdList) {
                    var groupModel = self.elementType === 'campaigns' ? self.group : self.model;

                    if (userIdList.length > 0) {
                        var data = {
                            receivers: userIdList
                        }

                        $.ajax({
                            type: 'POST',
                            url: `/groups/${groupModel.get('id')}/share`,
                            contentType: 'application/json',
                            dataType: 'json',
                            data: JSON.stringify(data),
                            success: function(response) {

                                vent.trigger('alert:show', {
                                    type: function() {
                                        return {
                                            message: 'Group successfully shared!',
                                            classes: 'success',
                                            timer: 3000
                                        };
                                    }
                                });
                            }
                        });
                    }
                },
                null,
                null,
                {
                    prompt: {
                        type: 'tagSelect'
                    }
                }
            );
        }
    },
    regions: {
        listRegion: '.group-content-list',
        tableRegion: '.group-content-table',
        mapRegion: '.group-content-map',
        calendarRegion: '.group-content-calendar',
        filterRegion: '.smart-filters-container',
        menuRegion: '.item-menu',
        cfMenuRegion: '.item-cf-menu',
        tableMenuRegion: '.table-item-menu',
        titleWidgetsRegion: '.title-widgets-area',
        renameRegion: {
            selector: '.rename-group-region',
            regionType: ModalRegion
        },
        bulkEditRegion: {
            selector: '.bulk-edit-region',
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        },
        mergeRegion: {
            selector: '.merge-region', // unused
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        },
        columnRegion: {
            selector: '.column-region',
            regionType: ModalRegion
        },
        groupInsightsRegion: {
            selector: '.insights-regioon',
            regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
        },
        individualInactiveReasons: {
            selector: '.individual-inactive-reasons', // selector it self not used in ModalRegion
            regionType: ModalRegion
        }
    },
    detail: null,
    filter: null,
    collection: null,
    fetchOptions: {},
    tools: {},
    selectedColumns: null,
    initialize: function(options) {
        _.extend(this, options);

        var model = this.model;
        var filter = model && model.get('filter');
        this.filter_id = this.filter_id || filter && filter.id;

        var customFields = app.globalData.customFieldsInfo[`${self.elementType}Array`];
        this.customFieldsMenu = new CustomFieldsCollection(customFields);
    },
    onRender: function() {
        this.listenTo(this.menuRegion, 'show', function(view) {
            var title = this.$el.find('.detail-nav .title-menu'),
                menu = this.menuRegion.$el,
                minLeft = title.offset().left - this.$el.offset().left + menu.width() / 2;

            view.$el.find('> .arrow').css('left', Math.min(minLeft, title.find('.text').width() + 20));

            menu.css({
                top: title.offset().top + title.height() - 70,
                left: Math.max(minLeft, title.find('.text').width() + 20)
            })
            .fadeIn(200)
            .show();

            this.listenTo(view, 'close', function() {
                this.menuRegion.$el.hide();
                title.removeClass('active');
            });

            this.listenTo(view, 'edit', this.startEditing);
            this.listenTo(view, 'permissions', this.permissions);
            this.listenTo(view, 'edit-columns', this.editColumns);
            this.listenTo(view, 'download-item', this.download);
            this.listenTo(view, 'download-templated-report', this.downloadTemplatedReport);
            this.listenTo(view, 'duplicate-static', this.duplicateStatic);
            this.listenTo(view, 'duplicate-static-from-field', this.duplicateStaticFromField);
            this.listenTo(view, 'duplicate-smart', this.duplicateSmart);
            this.listenTo(view, 'save-as-forecast', this.saveAsForecast);
            this.listenTo(view, 'download-financial-report', this.downloadFinancialReport);
            this.listenTo(view, 'delete', this['delete']);

            title.addClass('active');
        });

        this.listenTo(this.cfMenuRegion, 'show', function(view) {
            var cfMenu = this.cfMenuRegion.$el,
                menu = this.menuRegion.$el,
                cfMenuOption = this.$el.find('.duplicate-static-from-field')

            cfMenu.css({
                top: cfMenuOption.offset().top,
                left: menu.offset().left - (menu.width() / 2) - 4
            })
            .fadeIn(200)
            .show();

            this.listenTo(view, 'close', function() {
                this.cfMenuRegion.$el.hide();
            });
        });

        $(window).on('resize', { self: this }, this.updateListHeight);

        this.$el.find('.detail-name').tooltip();

        var comments = this.model ? this.model.get('comments') : '';
        this.ui.display_comments.attr("title", (comments ? linkify(comments) : 'No information to display'))
    },
    getMenuOptions: function() {
        return {
            canEdit: false,
            hasPermissions: false,
            canDelete: false,
            locked: this.model ? this.model.get('locked') : false,
            disableSave: !!this.disableSave,
            hasFieldGroup: this.parent.getElementType() === 'activities' || this.parent.getElementType() === 'tasks',
            canDownloadAsCSV: !AppConfig.getClientPreferenceValue('hideDownloadAsCsvOption') || app.user.get('is_admin')
        };
    },
    startEditing: function() {},
    permissions: function() {},
    editColumns: function() {
        var elType = this.options.elementType;

        if (true) { // elType !== 'campaigns' || _.contains(app.user.get('preferences').lab_flags, 'SAL-3826')) {
            var selectedColumns = this.getSelectedColumns();
            if (!selectedColumns || selectedColumns.length === 0) {
                selectedColumns = this.getDefaultColumns();
            }
            var columnView = new ColumnSelectView({
                columns: this.getAllColumns(),
                selectedColumns: selectedColumns
            });
            this.columnRegion.show(columnView);

            this.listenTo(columnView, 'save', function (collection) {
                var columns = collection.map(function (model) {
                    return model.get('id');
                });
                this.selectedColumns = columns;
                if (this.tableRegion.currentView.updateColumns) {
                    this.tableRegion.currentView.updateColumns(columns);
                }

                if (this.model && !this.model.doNotPersistColumns) {
                    this.model.save({
                        columns: columns
                    }, {
                        patch: true,
                        success: function () {
                            //this.trigger('update-columns', columns);
                            this.fetchCollection(null, null, null, null, { columnsIds: columns });
                        }.bind(this)
                    });
                }
                else {
                    //this.trigger('update-columns', columns);
                    this.fetchCollection(null, null, null, null, { columnsIds: columns });
                }
            });

            this.listenTo(columnView, 'close', function () {
                this.columnRegion.reset();
            });
        }
        else {
            this.trigger('edit-columns');
        }
    },
    download: function() {
        if(this.fetchOptions && this.fetchOptions.contact_preview){
            delete this.fetchOptions.contact_preview
        }
        var url = app.options.apiUrl + '/group_pages',
            columns = this.getSelectedColumns(),
            params = _.extend({
                csv: true,
                item_type: this.parent.getElementType()
            }, this.fetchOptions);

        if (columns && columns.length > 0) {
            params.columns = columns.join(',');
        }

        if (this.sortOptions && this.sortOptions.length > 0) {
            params.order_by = _.map(this.sortOptions, function(item) {
                return item.attribute + ' ' + item.order;
            }).join(',');
        }

        this.downloadForm(url, params);
    },
    downloadTemplatedReport: function() {
        const url = `${app.options.apiUrl}/templated_report`;
        let params = _.extend({
            element_type: this.parent.getElementType()
        }, this.fetchOptions);

        if (this.sortOptions && this.sortOptions.length > 0) {
            params.order_by = _.map(this.sortOptions, function(item) {
                return item.attribute + ' ' + item.order;
            }).join(',');
        }

        this.downloadForm(url, params);
    },
    downloadForm: function(url, params) {
        this.ui.downloadForm.empty();

        var self = this;

        _.each(params, function(value, key) {
            if (!_.isUndefined(value)) {
                $('<input>').attr('type', 'hidden').attr('name', key).val(value).appendTo(self.ui.downloadForm);
            }
        });

        this.ui.downloadForm.prop('action', url);
        this.ui.downloadForm.submit();
    },
    duplcate: function(attributes, params) {
        var view = this,
            url,
            newModel = new GroupModel();

        url = newModel.url();
        if (!_.isEmpty(params)) {
            params = _.clone(params);
            _.each(params, function(value, key) {
                if (_.isUndefined(value)) {
                    delete params[key];
                }
            });
            url += '?' + $.param(params);
        }

        newModel.save(attributes, {
            url: url,
            success: function() {
                view.parent.trigger('replace-view:group:show', newModel);

                vent.trigger('group:save', newModel);
            }
        });
    },
    duplicateStatic: function(groupId, groupComments, groupElementType) {
        var model = this.model,
            today = dateFormat.shortFormatWithYear(new Date()),
            name = model ? model.get('name') : this.collection.model.prototype.name;

        var group_id = groupId || (model && model.get('id'));
        var comments = groupComments || (model && model.get('comments')) || '';
        var elementType = groupElementType || this.parent.getElementType();

        var renameGroupView = new RenameGroupView({
            title: 'Save As Static Group',
            name: name + ' on ' + today
        });
        this.listenTo(renameGroupView, 'save', function(name, tags) {
            var filter = this.getFilter();

            if (!model) {
                group_id = specialGroups[this.options.type];
            }

            this.duplcate({
                name: name,
                comments: comments,
                owner_id: app.user.get('id'),
                element_type: elementType,
                group_type: 'static',
                columns: this.getSelectedColumns(),
                tags: tags
            }, {
                populate: true,
                from_filter_id: filter && filter.get('id') || void 0,
                from_group_id: group_id || void 0
            });
        });
        this.renameRegion.show(renameGroupView);
    },
    duplicateStaticFromField: function() {
        var self = this;

        if (this.noTitleMenu) {
            return;
        }

        this.cfMenuRegion.show(new CFMenuView(_.extend({
            parent: self,
            fields: self.customFieldsMenu
        }, this.getMenuOptions())));
    },
    createStaticGroupFromField: function(cf){
        var model = this.model,
            today = dateFormat.shortFormatWithYear(new Date()),
            name = model ? model.get('name') : this.collection.model.prototype.name;

        var renameGroupView = new RenameGroupView({
            title: `Save as static group from field - ${cf.get('name')}` ,
            name: name + ': ' + cf.get('name') + ' on ' + today
        });

        this.listenTo(renameGroupView, 'save', function(name, tags) {
            var comments = model ? model.get('comments') : void 0,
                element_type = this.parent.getElementType(),
                filter = this.getFilter();

            var group_id = model && model.get('id');

            if (!model) {
                group_id = specialGroups[this.options.type];
            }

            this.duplcate({
                name: name,
                comments: comments,
                owner_id: app.user.get('id'),
                element_type: this.mapElementType(cf.get('type')),
                from_element_type: element_type,
                group_type: 'static',
                columns: this.getSelectedColumns(),
                tags: tags
            }, {
                populate: true,
                from_filter_id: filter && filter.get('id') || void 0,
                from_group_id: group_id || void 0,
                from_field_id: cf.get('id')
            });
        });
        this.renameRegion.show(renameGroupView);
    },
    mapElementType: function(type){
        if (type === 'individual'){
            return 'individuals'
        } else if (type === 'organization'){
            return 'organizations'
        } else {
            return 'opportunities'
        }
    },
    duplicateSmart: function(groupId, groupComments, groupElementType) {
        var model = this.model,
            today = dateFormat.shortFormatWithYear(new Date()),
            name = model ? model.get('name') : this.collection.model.prototype.name;

        var group_id = groupId || (model && model.get('id'));
        var comments = groupComments || (model && model.get('comments')) || '';
        var elementType = groupElementType || this.parent.getElementType();

        var renameGroupView = new RenameGroupView({
            title: 'Save As Smart Group',
            name: name + ' on ' + today
        });
        this.listenTo(renameGroupView, 'save', function(name, tags) {
            var self = this;

            this.getFilterIdForSmartGroup(function(filterId, displayOptions) {
                self.duplcate({
                    name: name,
                    comments: comments,
                    owner_id: app.user.get('id'),
                    element_type: elementType,
                    group_type: 'smart',
                    filter_id: filterId,
                    group_id: group_id,
                    display_options: displayOptions,
                    columns: self.getSelectedColumns(),
                    tags: tags
                }, {
                    populate: true
                })
            });
        });
        this.renameRegion.show(renameGroupView);
    },
    getFilterIdForSmartGroup: function(callback) {
        var self = this;
        var model = this.group || this.model;
        var elementType = (model && model.get('element_type')) || this.elementType;
        var groupType = this.type;

        if (this.elementType === 'campaigns') {
            groupType = this.model.get('id');
        }

        var ready = function(rules, displayOptions) {
            if (rules) {
                var filter = null;

                if (self.filter && self.filter.get('rules')) {
                    var existingRules = _.clone(self.filter.get('rules'));

                    _.each(existingRules, function(rule) {
                        rules.push(rule);
                    });
                }

                switch(elementType) {
                    case 'opportunities':
                        filter = new OpportunityFilterModel();
                        break;

                    case 'individuals':
                        filter = new IndividualFilterModel();
                        break;

                    case 'organizations':
                        filter = new OrganizationFilterModel();
                        break;

                    case 'activities':
                        filter = new ActivityFilterModel();
                        break;

                    case 'tasks':
                        filter = new TaskFilterModel();
                        break;

                    case 'campaigns':
                        filter = new CampaignFilterModel();
                        break;
                }

                filter.save({rules: rules}, {alert: false, success: function(data) {
                    callback(data.id, displayOptions);
                }});
            }
            else {
                callback(self.filter && self.filter.get('id'), displayOptions);
            }
        };

        // is it a group?

        if (model && model.get('group_type')) {
            if (model.get('group_type') === 'static') {
                ready([[{
                    field: 'group_id',
                    operator: 'equal',
                    values: {
                        id: model.get('id'),
                        name: model.get('name')
                    }
                }]]);
            }
            else {
                ready();
            }
            return;
        }

        // ...
        switch(groupType) {
            case 'active':
            case 'won':
            case 'lost':
            case 'my':
            case 'team':
                var phases = new PhasesCollection();

                phases.fetch({
                    success: function () {
                        var wonLostPhases = phases.getWonLost();
                        var rules;

                        if (['active', 'my', 'team'].indexOf(groupType) !== -1) {
                            rules = [
                                [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    not: true,
                                    values: wonLostPhases[0]
                                }],
                                [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    not: true,
                                    values: wonLostPhases[1]
                                }]
                            ];
                        }
                        else if (groupType === 'won') {
                            rules = [
                                [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    values: wonLostPhases[0]
                                }]
                            ];
                        }
                        else if (groupType === 'lost') {
                            rules = [
                                [{
                                    field: 'opportunity_phase_id',
                                    operator: 'equal',
                                    values: wonLostPhases[1]
                                }]
                            ];
                        }

                        // ...
                        if (groupType === 'my') {
                            rules.push([{
                                field: 'opportunity_owner_id',
                                operator: 'equal',
                                values: {
                                    id: app.user.get('id'),
                                    name: app.user.get('name')
                                }
                            }]);
                        }

                        if (groupType === 'team') {
                            $.get('/users?managed_by_id=' + app.user.get('id'), function(users) {
                                var orRules = [];

                                _.each(users, function(user) {
                                    orRules.push({
                                        field: 'opportunity_owner_id',
                                        operator: 'equal',
                                        values: {
                                            id: user.id,
                                            name: user.name
                                        }
                                    });
                                });

                                rules.push(orRules);
                                ready(rules);
                            });
                        }
                        else {
                            ready(rules);
                        }
                    }
                });
                break;

            case 'elephants':
                ready([[{
                    field: 'is_opportunity_favorite'
                }]]);
                break;

            case 'myIndividuals':
            case 'myOrganizations':
                ready([[{
                    field: (groupType === 'myIndividuals') ? 'owner_id' : 'organization_owner_id',
                    operator: 'equal',
                    values: {
                        id: app.user.get('id'),
                        name: app.user.get('name')
                    }
                }]]);
                break;

            case 'favorite':
                ready([[{
                    field: 'is_individual_favorite'
                }]]);
                break;

            case 'recentCreatedIndividuals':
            case 'recentCreatedOrganizations':
                ready([[{
                    field: (groupType === 'recentCreatedIndividuals') ? 'owner_id' : 'organization_owner_id',
                    operator: 'equal',
                    values: {
                        id: app.user.get('id'),
                        name: app.user.get('name')
                    }
                }]], {
                    sort: {
                        field: 'created',
                        direction: false
                    }
                });
                break;

            case 'recentIndividuals':
            case 'recentOrganizations':
                ready([[{
                    field: (groupType === 'recentIndividuals') ? 'is_individual_recent' : 'is_organization_recent'
                }]], {
                    sort: {
                        field: 'last_viewed',
                        direction: false
                    }
                });
                break;

            case 'periods':
                var periods = new PeriodsCollection();

                periods.fetch({
                    success: function(data) {
                        var period = _.find(data.models, function(m) {
                            return m.get('id') === self.fetchOptions.closing_in_period_id
                        });

                        ready([[{
                            field: 'opportunity_period_id',
                            operator: 'equal',
                            values: {
                                id: period.get('id'),
                                name: period.get('name')
                            }
                        }]]);
                    }
                });
                break;

            case 'funnels': {
                const funnel = app.globalData.funnelsInfo.funnel.find(f => f.id === self.fetchOptions.funnel_id);

                ready([[{
                    field: 'opportunity_funnel_id',
                    operator: 'equal',
                    values: {
                        id: funnel.id,
                        name: funnel.name
                    }
                }]]);
            }
            break;

            case 'templated':
                ready([[{
                    field: 'campaign_name',
                    operator: 'contains',
                    values: 'template'
                }]]);
                break;

            default:
                ready();
                break;
        }
    },
    saveAsForecast: function() {
        var name = this.model ? this.model.get('name') : this.title;
        var view = this;

        var forecasts = new ForecastsCollection();
        forecasts.fetch({
            url: '/users/' + app.user.id + '/forecasts',
            data: {
                rows: -1
            },
            success: function() {
                var renameGroupView = new RenameGroupView({
                    title: 'Save As Forecast',
                    name: forecasts.getUniqueName(name),
                    placeholder: 'Forecast name'
                });

                view.listenTo(renameGroupView, 'save', function(name) {
                    var periods = new PeriodsCollection();

                    periods.fetch({
                        success: function() {
                            var params = {
                                name: name,
                                only_from_period: false,
                                published: 'default',
                                period: periods.get(periods.getCurrentPeriodId()).toJSON(),
                                owner: {
                                    id: app.user.get('id'),
                                    name: app.user.get('name')
                                }
                            };

                            if (view.model) {
                                params.from_group_id = view.model.get('id');
                            }
                            else {

                                if (view.options.type === 'periods') {
                                    params.from_period_id = view.options.id;
                                }
                                else {
                                    params.from_group_id = specialGroups[view.options.type];
                                }
                            }

                            if (view.filter) {
                                params.from_filter_id = view.filter.get('id');
                            }

                            var forecast = new ForecastModel(params);

                            forecast.save(null, {
                                success: function() {
                                    vent.trigger('forecasts:detail', forecast.get('id'));
                                }
                            });
                        }
                    });
                });

                view.renameRegion.show(renameGroupView);
            }
        });
    },
    downloadFinancialReport: function() {
        var url = app.options.apiUrl + '/download_financial_report',
            params = _.extend({
                csv: true,
                item_type: this.parent.getElementType()
            }, this.fetchOptions);

        if (this.sortOptions && this.sortOptions.length > 0) {
            params.order_by = _.map(this.sortOptions, function(item) {
                return item.attribute + ' ' + item.order;
            }).join(',');
        }

        this.downloadFormFinancialReport(url, params);
    },
    downloadFormFinancialReport: function(url, params) {
        var self = this;
        if (!_.isEmpty(params)) {
            params = _.clone(params);
            _.each(params, function(value, key) {
                if (_.isUndefined(value)) {
                    delete params[key];
                }
            });
            url += '?' + $.param(params);
        }

        $.ajax({
            url: url,
            method: 'GET',
        });

        MessageBox.showOk({
            icon: 'icon-info',
            message: `Financial Report request sent successfully! The report will be sent to your SalesSeek's account email mailbox.(${app.user.get('email_address')})`,
        }, self);
    },
    'delete': function() {
        this.model.destroy();
    },
    getFilter: function() {
        return this.filter;
    },
    onShow: function() {
        var self = this;
        var promises = [];

        if (this.elementType === 'opportunities') {
            this.buckets = new BucketsCollection(app.globalData.buckets);
        }
        else if (this.elementType === 'individuals') {
            this.campaigns = new CampaignsCollection();
            promises.push(this.campaigns.fetch({
                rows: -1,
                data: {
                    status: 'sent,automation',
                    search_not: 'template',
                    fields: 'id,name'
                }
            }));
        }

        var cfinfo = app.globalData.customFieldsInfo;
        this.customFields = new CustomFieldsCollection([...cfinfo.individualsArray, ...cfinfo.organizationsArray, ...cfinfo.opportunitiesArray]);

        $.when(...promises).done(function() {
            if (self.filter && self.filter.has('id') && !self.filter.fetched) {
                self.filter.fetch({
                    complete: function () {
                        if (!self.model || self.model.get('group_type') !== 'smart') {
                            _.extend(self.fetchOptions, {
                                filter_id: self.filter.get('id')
                            });
                        }
                        self.showFilterView();
                        self.showListView();
                        self.showTableView();
                        self.showDemographicMapView();
                        self.showCalendarView();
                    }
                });
            }
            else {
                self.showFilterView();
                self.showListView();
                self.showTableView();
                self.showDemographicMapView();
                self.showCalendarView();
            }
        });

        if (this.model && this.ui.tagsContainer.get(0)) {
            ReactDOM.render(
                <TagList
                    tags={this.model.get('tags')}
                />,
                this.ui.tagsContainer.get(0)
            );
        }
    },
    onClose: function() {
        $(window).off('resize', this.updateListHeight);
    },
    fetchCollection: function(options, page, rows, sortOptions, extraOptions) {
        this.fetchOptions = _.extend({}, this.fetchOptions, options);
        this.page = page || this.page;
        this.rows = rows || this.rows;
        this.sortOptions = sortOptions || this.sortOptions;

        this.beforeCollection(extraOptions);

        // ...
        var self = this;
        var selectedColumns = this.getSelectedColumns() || [];
        var columnsToFetch = (extraOptions && extraOptions.columnsIds) ? extraOptions.columnsIds.slice(0) : selectedColumns.slice(0);
        // add hoc data retrieval for source icon in image thumb
        if (_.find(columnsToFetch, function(column) { return column === 'photo_url'; })) {
            columnsToFetch.push('source');
        }
        // add hoc data retrieval for currency used in deal value
        if (_.find(columnsToFetch, function(column) { return column === 'buckets_total' || column === 'default_value'; })) {
            columnsToFetch.push('currency');
        }

        this.collection.fetch({
            page: this.page,
            rows: this.rows,
            sortOn: this.sortOptions,
            columnsIds: columnsToFetch || null,
            data: this.customParametrize ? this.customParametrize(this.fetchOptions) : this.fetchOptions,
            success: function(collection) {
                self.onCollection(collection, (extraOptions && extraOptions.fetchingFromTable))
            }
        });
    },
    beforeCollection: function(options) {
        if (this.listRegion && this.listRegion.currentView) {
            this.listRegion.currentView.beforeCollection(options);
        }
        if (this.tableRegion && this.tableRegion.currentView) {
            this.tableRegion.currentView.beforeCollection(options);
        }
    },
    onCollection: function(collection) {

        // ... fetch first page if we don't have results for current page but we have results
        //     (maybe the user has applied a filter being in the second page)...
        if ((collection.length === 0) && (collection.collectionPage > 1) && (collection.total > 0)) {
            this.fetchCollection({}, 1);
        }
        else {
            this.collection = collection;

            if (this.listRegion && this.listRegion.currentView) {
                this.listRegion.currentView.onCollection(collection);
            }
            if (this.tableRegion) {
                this.tableRegion.currentView.onCollection(collection);
            }

            if (this.options.showFirstRecord && (collection.length > 0)) {
                this.onSelectItem(collection.models[0]);
            }
        }
    },
    showFilterView: function() {
        if (!this.filterRegion || !this.getFilter()) {
            return;
        }

        var filterView = this.createFilterView();
        if (!filterView) {
            return;
        }

        this.listenTo(filterView, 'filter-update', this.onFilterUpdate);
        this.listenTo(filterView, 'update-height', this.updateListHeight);

        this.filterRegion.show(filterView);

        this.updateFilterBadge(this.filter);
    },
    createFilterView: function() {
        return new FilterBarView({
            parent: this,
            model: this.getFilter(),
            quickFilter: this.quickFilter,
            groupType: this.groupType,
            group: this.model
        });
    },
    onFilterUpdate: function(filter) {
        this.filter = filter;

        this.updateFilterBadge(filter);

        this.fetchCollection({
            filter_id: this.filter && this.filter.get('id')
        }, null, null, null, {byFilterUpdate: true});

        this.trigger('filter:update', filter);

        vent.trigger('AppContent:contentChange');

        if (this.mapRegion && this.mapRegion.currentView) {
            this.mapRegion.currentView.component.setFilter(filter);
        }

        if (this.calendarRegion && this.calendarRegion.currentView) {
            this.calendarRegion.currentView.component.setFilter(filter);
        }
    },
    updateFilterBadge: function(filter) {
        var rules = filter && filter.get('rules');

        this.ui.filterBadge.text(rules && rules.length || '');
    },
    createListView: function() {},
    showListView: function() {
        if (!this.listRegion) {
            return;
        }

        var listView = this.createListView();
        if (!listView) {
            return;
        }

        this.listenTo(listView, 'fetch-collection', this.fetchCollection);

        this.listenTo(listView, 'toolbar:new', this.newItem);
        this.listenTo(listView, 'toolbar:add-item', this.addItem);
        this.listenTo(listView, 'toolbar:delete-bulk', this.deleteItems);
        this.listenTo(listView, 'toolbar:remove', this.removeItems);
        this.listenTo(listView, 'toolbar:edit', this.editItems);

        this.listenTo(listView, 'showItem', this.onSelectItem);
        this.listenTo(listView, 'followLink', this.onFollowLink);

        this.listRegion.show(listView);
    },
    showGroupInsights: function(columnId, columnName) {
        let viewData = {
            elementType: this.options.elementType,
            columnId: columnId,
            columnName: columnName,
            fetchOptions: {}
        };

        let isSmartGroup = false;

        if (this.model) {
            viewData.fetchOptions.group_id = this.model.get('id');
            isSmartGroup = this.model.get('group_type') === 'smart';
        }
        else {
            viewData.fetchOptions.group_id = specialGroups[this.options.type];
        }

        if (this.options.fetchOptions) {
            viewData.fetchOptions.closing_in_period_id = this.options.fetchOptions.closing_in_period_id;
            viewData.fetchOptions.funnel_id = this.options.fetchOptions.funnel_id;
            viewData.fetchOptions.phase_types = this.options.fetchOptions.phase_types;
            viewData.fetchOptions.by_period_id = this.options.fetchOptions.by_period_id;
            viewData.fetchOptions.team = this.options.fetchOptions.team;
            viewData.fetchOptions.section_id = this.options.fetchOptions.section_id;
        }

        if (this.filter && !isSmartGroup) {
            viewData.fetchOptions.filter_id = this.filter.get('id');
        }

        const view = new GroupInsightsView(viewData);

        this.listenTo(view, 'groups-insights:close', function() {
            this.groupInsightsRegion.reset();
        });

        this.groupInsightsRegion.show(view);
    },
    createMapView() {
        if (!this.options.model && !this.options.demographicMap) {
            return;
        }

        var elementType = this.options.model ? this.options.model.get('element_type') : this.options.elementType;
        var clientPreferences = app.user.get('client').preferences || {};

        if (!clientPreferences.demographic_maps) {
            return;
        }

        if (clientPreferences.demographic_maps) {
            const mapInfo = JSON.parse(clientPreferences.demographic_maps)[elementType];

            if (!mapInfo || (!mapInfo.address_builder && !mapInfo.static_pins)) {
                return;
            }
        }

        var group;

        if (this.options.model) {
            group = this.options.model.toJSON();
        } else {
            const demographicMap = this.options.demographicMap;

            group = {
                id: null,
                element_type: elementType,
                filter_id: this.options.filter_id
            };

            if (demographicMap) {
                group.staticPins = demographicMap.staticPins

                if (elementType === 'opportunities') {
                    group.funnelId = demographicMap.funnelId;
                } else {
                    group.tagId = demographicMap.tagId;
                }
            }
        }

        return new MapView({
            group: group
        });
    },
    showDemographicMapView: function() {
        var mapView = this.createMapView();

        if (!mapView) {
            return;
        }

        this.listenTo(mapView, 'showItem', this.onSelectItem);

        this.mapRegion.show(mapView);
    },
    createCalendarView(dateInfo) {
        if (this.getElementType() !== 'tasks') {
            return null;
        }

        return new CalendarView(dateInfo);
    },
    showCalendarView: function() {
        var dateInfo = {
            date: new Date(),
            span: 'week'
        };

        var calendarView = this.createCalendarView(dateInfo);

        if (!calendarView) {
            return;
        }

        var calendarDateSelectorView = new CalendarDateSelectorView({calendar: calendarView, date: dateInfo.date, span: dateInfo.span});

        this.calendarRegion.show(calendarView);
        this.titleWidgetsRegion.show(calendarDateSelectorView);

        this.listenTo(calendarView, 'on-date-span-change', function(date, span) {
            calendarDateSelectorView.component.setDateSpan(date, span);
        });

        this.listenTo(vent, 'task:updated task:created', function() {
            if (calendarView.component.isVisible()) {
                calendarView.component.fetchData();
            }
        });
    },
    createTableView: function() {},
    showTableView: function() {
        var self = this;

        if (!this.tableRegion) {
            return;
        }

        var tableView = this.createTableView();
        if (!tableView) {
            return;
        }

        this.listenTo(tableView, 'fetch-collection', this.fetchCollection);
        this.listenTo(tableView, 'add-filter', function(filter) {
            this.filterRegion.currentView.addFilter(filter);
            this.onFilterUpdate(filter);
        });

        this.listenTo(tableView, 'toolbar:new', this.newItem);
        this.listenTo(tableView, 'toolbar:add-item', this.addItem);
        this.listenTo(tableView, 'toolbar:delete-bulk', function(options) {
            this.deleteItems(options, true);
        });
        this.listenTo(tableView, 'toolbar:remove', function(data) {
            this.removeItems(data, true);
        });
        this.listenTo(tableView, 'toolbar:edit', function(options) {
            this.editItems(options, true);
        });
        this.listenTo(tableView, 'toolbar:merge', function(options) {
            this.mergeItems(options);
        });
        this.listenTo(tableView, 'select', this.onSelectItem);

        this.listenTo(tableView, 'update-columns', function(columns) {
            this.selectedColumns = columns;

            this.$el.find('.table-mode-radio').click();
        });

        this.listenTo(tableView, 'group:showInsights', this.showGroupInsights);

        this.listenTo(tableView, 'campaign:preview', this.onCampaignPreview);
        this.listenTo(tableView, 'campaign:edit', this.onCampaignEdit);
        this.listenTo(tableView, 'campaign:permissions', this.onCampaignShowPermissions);
        this.listenTo(tableView, 'delete', this.onDelete);
        this.listenTo(tableView, 'campaign:summarySheet', this.onCampaignShowSummarySheet);

        this.listenTo(tableView, 'bulk-delete:completed bulk-remove:completed', function() {
            if (this.mapRegion && this.mapRegion.currentView) {
                this.mapRegion.currentView.component.setDataDirty();
            }
        });

        this.listenTo(vent, 'task:updated task:created', function() {
            self.fetchCollection();
        });

        this.tableRegion.show(tableView);

        if (this.model) {
            var display_options = this.model.get('display_options');

            if (display_options && display_options.show_as === 'table') {
                _.defer(function() {
                    self.$el.find('.table-mode-radio').click();
                });
            }
        }
        else { // individuals, organizations and deals: default view is table
            _.defer(function() {
                self.$el.find('.table-mode-radio').click();
            });
        }
    },
    getSelectedColumns: function() {
        if (this.model && this.model.get('columns')) {
            return this.model.get('columns');
        } else {
            var columns = this.selectedColumns;

            if (!columns && this.tableRegion && this.tableRegion.currentView) {
                columns = this.tableRegion.currentView.options.defaultColumns;
            }

            return columns;
        }
    },
    onSelectItem: function() {},
    onFollowLink: function(model, link) {
        app.followLink(model, link);
    },
    newItem: function() {},
    addItem: function(model) {
        var view = this;

        $.ajax({
            url: this.model.url() + '/items/' + model.get('id'),
            method: 'PUT',
            success: function() {
                view.fetchCollection(null, null, null, null, {byItemAdded: true});

                if (view.mapRegion && view.mapRegion.currentView) {
                    view.mapRegion.currentView.component.setDataDirty();
                }
            }
        });
    },
    deleteItems: function(options, fromTable) {
        var mbProgressContent;
        var mbContent;
        var deleteData;
        var canDelete = true;

        var collection = fromTable ? this.tableRegion.currentView.collection : this.listRegion.currentView.collection;

        if (options.allItemsSelected) {
            mbContent = {
                message: 'Are you sure you want to <strong>permanently</strong> delete all permitted items from ' + options.numItemsSelected + '?',
                icon: 'icon-trashcan',
                itemsLength: options.numItemsSelected,
                accept_is_negative: true
            };

            mbProgressContent = {
                icon: 'icon-trashcan',
                message: "Deleting permitted items" +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.model ? this.model.get('element_type') : this.elementType
            };

            var isSmartGroup = false;

            if (this.model) {
                deleteData.group_id = this.model.get('id');
                isSmartGroup = this.model.get('group_type') === 'smart';
            }
            else {
                deleteData.group_id = specialGroups[this.options.type];
            }

            // on smart groups the group id is enough
            if (this.filter && !isSmartGroup) {
                deleteData.filter_id = this.filter.get('id');
            }

            deleteData.section_id = options.section_id;
        }
        else {
            var models = _.map(options.selection, function (i) {
                return fromTable ? new Backbone.Model(i) : i.model;
            });

            var toDeleteCount  = models.length; // number of items I want to delete
            var permittedCount = 0; // number of items I have permission to delete
            var permittedIds = [];

            _.each(models, function(model) {
                if (security.checkPermission( 'delete', model ) ) {
                    ++permittedCount;
                    permittedIds.push(model.get('id'));
                }
            });

            // 3 cases:
            mbContent = {
                message: '',
                icon: 'icon-trashcan',
                itemsLength: permittedCount
            };

            if (permittedCount === 0) { // 1. can't delete anything
                canDelete = false;
                mbContent.message = "You don't have permission to delete selected item" + (toDeleteCount > 1 ? 's' : '');
                mbContent.icon = 'icon-blocked';
                mbContent.itemsLength = null;
                MessageBox.showOk( mbContent, this );
            }
            else if (permittedCount !== toDeleteCount) { // 2. can delete some items
                mbContent.accept_is_negative = true;
                mbContent.message = '<p>You have permission to delete ' + permittedCount +
                    ' of ' + toDeleteCount + ' selected items.</p>' +
                    '<strong class="cta">Do you want to continue?</strong>';
            }
            else { // 3. can delete all items
                mbContent.accept_is_negative = true;
                mbContent.message = 'Are you sure you want to <strong>permanently</strong> delete ' +
                    permittedCount + ' item' + (permittedCount > 1 ? 's' : '') + '?';
            }

            mbProgressContent = {
                icon: 'icon-trashcan',
                itemsLength: permittedCount,
                message: "Deleting " + permittedCount + " item" + (permittedCount > 1 ? "s" : "") +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.model ? this.model.get('element_type') : this.elementType,
                entity_ids: permittedIds
            };
        }

        if (canDelete) {
            var self = this;

            MessageBox.showYesNo(
                mbContent,
                this,
                function() { // Yes
                    var handle = MessageBox.showNoBtn(
                        mbProgressContent,
                        self,
                        {
                            staticRegion: true
                        }
                    );

                    var progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                    var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                    progressBar.width('0%');
                    completeMessage.text('0% complete');

                    $.ajax({
                        type: 'POST',
                        url: '/bulk_delete?queue',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify(deleteData),
                        success: function(data) {
                            var waitForCompletion = function() {
                                setTimeout(function() {
                                    if (self.isClosed) {
                                        return;
                                    }

                                    $.get('/bulk_delete/' + data.id, function(data) {
                                        if (data.status === 'finished') {
                                            handle.reset();

                                            if (options.allItemsSelected) {
                                                var removeModels = [];
                                                collection.each(function(model) {
                                                    if (security.checkPermission('delete', model)) {
                                                        removeModels.push(model);
                                                    }
                                                });
                                                collection.remove(removeModels);
                                            }
                                            else {
                                                _.each(models, function (model) {
                                                    if (security.checkPermission('delete', model)) {
                                                        collection.remove(model);
                                                    }
                                                });
                                            }

                                            if (self.mapRegion && self.mapRegion.currentView) {
                                                self.mapRegion.currentView.component.setDataDirty();
                                            }
                                        }
                                        else if (data.status === 'failed') {
                                            // nothing specified, but don't continue fetching
                                        }
                                        else {
                                            var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                            progressBar.width(pct + '%');
                                            completeMessage.text(Math.floor(pct) + '% complete');
                                            waitForCompletion();
                                        }
                                    });
                                }, 2000);
                            };
                            waitForCompletion();
                        },
                        error: function() {
                            handle.reset();

                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'There was an error processing this request',
                                        classes: 'load-error error',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            );
        }
    },
    removeItems: function(options, fromTable) {
        var itemsMessage = options.allItemsSelected ? ' all items' : (
                           options.numItemsSelected + ' item' +
                           (options.numItemsSelected > 1 ? 's' : ''));
        var content = {
                message: Handlebars.compile('Are you sure you want to remove {{itemsMessage}} from {{name}}?')({itemsMessage: itemsMessage, name: this.model.get('name')}),
                icon: 'icon-trashcan',
                itemsLength: options.numItemsSelected,
                accept_is_negative: true
            };
        var removeData = {
            group_id: this.model.get('id')
        };

        if (options.allItemsSelected) {
            if (this.filter) {
                removeData.filter_id = this.filter.get('id')
            }
        }
        else {
            removeData.entity_ids = _.map(options.items, function(i) {
                return (fromTable ? i.id : i.model.get('id'));
            });
        }

        var self = this;

        MessageBox.showYesNo(
            content,
            this,
            function() { // Yes
                var handle = MessageBox.showNoBtn({
                        icon: 'icon-trashcan',
                        message: 'Removing ' + itemsMessage +
                        "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                        "<div class='mb-wait-cont'>0% complete</div>"
                    },
                    self,
                    {
                        staticRegion: true
                    }
                );

                var progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                progressBar.width('0%');
                completeMessage.text('0% complete');

                $.ajax({
                    type: 'POST',
                    url: '/bulk_remove?queue',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(removeData),
                    success: function(data) {
                        var waitForCompletion = function() {
                            setTimeout(function() {
                                if (self.isClosed) {
                                    return;
                                }

                                $.get('/bulk_remove/' + data.id, function(data) {
                                    if (data.status === 'finished') {
                                        handle.reset();
                                        self.fetchCollection({}, null, null, null, {
                                            showLoadingView: true,
                                            fetchingFromTable: fromTable
                                        });
                                        options.success();

                                        if (self.mapRegion && self.mapRegion.currentView) {
                                            self.mapRegion.currentView.component.setDataDirty();
                                        }
                                    }
                                    else if (data.status !== 'failed') {
                                        var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                        progressBar.width(pct + '%');
                                        completeMessage.text(Math.floor(pct) + '% complete');
                                        waitForCompletion();
                                    }
                                });
                            }, 2000);
                        };

                        waitForCompletion();
                    }
                });
            }
        );
    },
    editItems: function(options, fromTable) {
        if (options.selection.length < 2) {
            var content = {
                message: 'Please select at least 2 records for bulk edit',
                icon: 'icon-warning'
            };

            MessageBox.showOk(content, this);
        }
        else {
            var data = {
                elementType: this.model ? this.model.get('element_type') : this.elementType,
                numItemsSelected: options.numItemsSelected
            };

            if (options.selection.length < options.numItemsSelected) {
                var isSmartGroup = false;

                if (this.model) {
                    data.groupId = this.model.get('id');
                    isSmartGroup = this.model.get('group_type') === 'smart';
                }
                else {
                    data.groupId = specialGroups[this.options.type];
                }

                // on smart groups the group id is enough
                if (this.filter && !isSmartGroup) {
                    data.filterId = this.filter.get('id');
                }

                if (this.section) {
                    data.section_id = this.section.id;
                }
            }
            else {
                var models = _.map(options.selection, function(i) {
                    return fromTable ? new Backbone.Model(i) : i.model;
                });

                data.selection = new Backbone.Collection(models);
            }

            var view = new BulkEditView(data);
            var self = this;

            this.listenTo(view, 'bulk-edit:done', function() {
                if (fromTable) {
                    self.tableRegion.currentView.clearSelection();
                }
                else {
                    self.listRegion.currentView.clearSelection();
                }

                self.fetchCollection();
            });

            this.bulkEditRegion.show(view);
        }
    },
    mergeItems: function(options) {
        var item_ids = _.map(options.selection, function(item) {
                return item.id;
            });

        if (item_ids.length < 2 || item_ids.length > 5) {
            MessageBox.showOk(
                {
                    message: 'You can merge between 2 and 5 contacts',
                    icon: 'icon-warning'
                },
                this
            );
            return;
        }

        var mergeView = new MergeView({
            elementType: options.elementType,
            item_ids: item_ids
        });
        this.listenTo(mergeView, 'merge:done', function() {
            var regions = appContent.contentRegions;

            // if any of the contacts is open in a detail view, close it
            _.each(regions, function(region) {
                var view = region.currentView;

                if (view && view.model && _.indexOf(item_ids, view.model.id) >= 0) {
                    view.closeView();
                }
            });

            this.fetchCollection({}, null, null, null, { showLoadingView: true });
        });
        this.mergeRegion.show(mergeView);
    },
    updateListHeight: function(e) {
        var self = e ? e.data.self : this;

        // we do this only when the view is visible (not hidden behind search view)
        if(!self.$el.is(':visible')) {
            return;
        }

        var filterHeight = self.ui.filters_container.is(':visible') ? self.ui.filters_container.height() : 0,
            fromTop = filterHeight + (self.ui.header.height() || 0) + (self.ui.nav.height() || 0);

        // NOTE: Use animation once we find a way to trigger a scrollbar update on the list
        // without bubbling up and triggering another 'resize' event
        if (self.ui.list_pane) {
            self.ui.list_pane.css('top', fromTop);
        }
        if (self.ui.table_pane) {
            self.ui.table_pane.css('top', fromTop);
        }
        if (self.ui.map_pane) {
            self.ui.map_pane.css('top', fromTop);
        }
        if (self.ui.calendar_pane) {
            self.ui.calendar_pane.css('top', fromTop);
        }
    },
    showTab: function(ev) {
        ev.preventDefault();
        $(ev.currentTarget).tab('show'); // Bootstrap tabs
    },
    getParams: function() {
        var model = this.model,
            filter = this.getFilter();

        var params = {};
        if (filter && filter.has('id') && !(model && model.isSmart())) {
            params.filter_id = this.filter.get('id');
        }
        return params;
    },
    getAllColumns: function() {
        var self = this;
        var elementType = self.getElementType();
        var columns = _.clone(TableBodyContainerView.prototype.getAllColumns(elementType));


        var typeMap = {
            organizations: 'organization'
        };
        var nameMap = {
            organizations: TextManager.parseText('${ID_ORGANIZATION, capitalize}')
        };

        if (this.tableRegion.currentView.options.availableColumns) {
            columns = _.filter(columns, function(col) {
                return col.id in self.tableRegion.currentView.options.availableColumns;
            });
        }

        // skip custom fields for some tables
        if (_.contains(['tasks', 'campaigns', 'sent_campaigns', 'activities', 'texts', 'messages'], elementType)) {
            return columns;
        }

        // custom field logic for individuals, organizations and deals
        const isPdcrm = AppConfig.getValue('is_pdcrm');

        this.customFields.each(function(model) {
            if (model.get('type') !== 'list') {
                var type = model.get('view');

                if (type === 'deals') {
                    type = 'opportunities';
                }

                var foreignCustom = type !== elementType;

                var fid = 'custom_fields.' + model.get('id');
                if (foreignCustom) {
                    fid = typeMap[type] + '.' + fid;
                }

                if (isPdcrm && (elementType === 'opportunities' && type === 'organizations')) {
                    return;
                }

                // use only same type custom fields and organization custom fields for all types
                if (elementType === type || type === 'organizations') {
                    columns.push({
                        id: fid,
                        name: foreignCustom ? nameMap[type] + ' ' + model.get('name') : model.get('name'),
                        sort: [(foreignCustom ? typeMap[type] + '.' : '') + 'custom_fields.' + model.get('id')]
                    });
                }
            }
        });

        if (this.buckets) {
            this.buckets.each(function(model) {
                var id = model.get('id');

                columns.push({
                    id: 'buckets.' + id,
                    name: model.get('name'),
                    sort: ['buckets.' + id]
                });
            });
        }

        if (this.campaigns) {
            this.campaigns.each(function(model) {
                var id = model.get('id');
                var name = model.get('name');

                columns.push({
                    id: `campaigns.${id}.state`,
                    name: `Campaign ${name} State`
                });

                columns.push({
                    id: `campaigns.${id}.open`,
                    name: `Campaign ${name} Num Time Opened`,
                    sort: [`campaigns.${id}.open`],
                });

                columns.push({
                    id: `campaigns.${id}.click`,
                    name: `Campaign ${name} Num Times Clicked`,
                    sort: [`campaigns.${id}.click`],
                });
            });
        }

        return columns;
    },
    getDefaultColumns: function() {
        if (this.tableRegion.currentView.options.defaultColumns) {
            return this.tableRegion.currentView.options.defaultColumns;
        }
        return TableBodyContainerView.prototype.getDefaultColumns(this.getElementType());
    },
    getElementType: function() {
        return this.options.parent.getElementType();
    },
    onBeforeClose: function() {
        if (this.model && this.ui.tagsContainer.get(0)) {
            ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
        }
    }
});

export default Marionette.Layout.extend({
    tagName: 'article',
    className: 'detail detail-groups content-container',
    template: Handlebars.compile(IODListingBaseTemplate),
    regions: {
        overviewContainerRegion: '.overview-container'
    },
    OverviewView: OverviewView,
    initialize: function(options) {
        _.extend(this, options);
    },
    onRender: function() {
        var view = this,
            model = this.model,
            onModel = function() {
                if (model.isSmart()) {
                    view.filter_id = void 0;
                    view.$el.addClass('smart-group');
                    view.$el.addClass('selection-enabled');
                }

                view.showOverviewView();

                if (view.editing) {
                    view.controller.startEditing();
                }
            };

        // New item styling if no model.id is present
        this.$el.toggleClass('is-new', this.model && !this.model.id || false);

        if (model && model.id && !model.local) {
            if (model.fetched) {
                onModel();
            }
            else {
                model.fetch({
                    error: function(_, response) {
                        view.trigger('fetch:fail', response);
                    }
                }).done(onModel);
            }
        } else {
            this.showOverviewView();
        }
    },
    showOverviewView: function() {
        if (!this.overviewContainerRegion) {
            return;
        }

        var overviewView = this.createOverviewView();
        if (overviewView) {
            this.overviewContainerRegion.show(overviewView);
        }
    },
    createOverviewView: function() {
        return new this.OverviewView(_.extend({
            parent: this,
            model: this.model
        }, this.options));
    },
    getElementType: function() {
        return null;
    },
    getUrl: function() {
        return this.getElementType();
    },
    getParams: function() {
        var overviewView = this.overviewContainerRegion && this.overviewContainerRegion.currentView;
        if (overviewView) {
            return overviewView.getParams();
        }
        // fallback when overview is not yet loaded
        else if (this.filter_id) {
            return { filter_id: this.filter_id };
        }
    }
});

import $ from 'jquery'
import _ from 'underscore'

import PreferencesModel from 'js/models/preferences'
import BaseModel from 'js/models/base'


var User = BaseModel.extend({
    name: 'User',
    apiType: 'salesseek.core.models.user.User',
    urlRoot: '/users',
    defaults: _.extend({}, BaseModel.prototype.defaults, {
        email_address: '',
        name: ''
    }),
    getTotalQuotaValue: function () {
        var value = 0;
        var quota = this.get( 'quota' );

        if ( quota ) {
            for ( var i = 0; i < quota.length; ++i ) {
                value += quota[ i ].value;
            }
        }

        return value;
    },
    updatePreference: function(key, value, onSuccess) {
        var preferences = {};

        preferences[key] = value;
        this.get('preferences')[key] = value;

        $.ajax({
            type: 'POST',
            url: this.url() + '/preferences',
            dataType: 'json',
            contentType: "application/json;charset=utf-8",
            data: JSON.stringify(preferences),
            success: function(data) {
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        });
    },
    updateMultiplePreferences: function(keyValue, onSuccess) {
        var preferences = {};

        Object.entries(keyValue).forEach(([key, value]) => {
            preferences[key] = value
            this.get('preferences')[key] = value;
        })

        $.ajax({
            type: 'POST',
            url: this.url() + '/preferences',
            dataType: 'json',
            contentType: "application/json;charset=utf-8",
            data: JSON.stringify(preferences),
            success: function(data) {
                if (onSuccess) {
                    onSuccess(data);
                }
            }
        });
    },
    validate: function(attrs) {
        var errors = {};

        if (_.isUndefined(attrs.name)){
            errors.name_undefined = 'Name is required';
        } else if (attrs.name.length === 0) {
            errors.name_too_short = 'One character minimum required';
        }
        if (_.isUndefined(attrs.email_address)) {
            errors.email_undefined = 'Email is required';
        } else if (attrs.email_address.indexOf('@') === -1) {
            errors.email_invalid = 'Invalid email address';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    },
    featureTierIsStarting: function() {
        return this.get('client').feature_tier === 'starting';
    },
    getIndividualPreloadedFunnels: function() {
        var userFunnels = [];
        var userPrefs = this.get('preferences');

        if (userPrefs.preloaded_fields && userPrefs.preloaded_fields.individuals) {
            var funnels = _.uniq(userPrefs.preloaded_fields.individuals.funnels);
            if (funnels && funnels.length > 0 && app.globalData.funnelsInfo.funnels) {
                var systemFunnels = app.globalData.funnelsInfo.funnels;

                for (var funnelId of funnels) {
                    var funnelInfo = systemFunnels.find(f => f.id === funnelId);

                    if (funnelInfo) {
                        userFunnels.push({
                            id: funnelInfo.id,
                            name: funnelInfo.name
                        });
                    }
                }
            }
        }

        return userFunnels;
    },

    getIndividualPreloadedTags: function() {
        var userTags = [];
        var userPrefs = this.get('preferences');

        if (userPrefs.preloaded_fields && userPrefs.preloaded_fields.individuals) {
            var tags = _.uniq(userPrefs.preloaded_fields.individuals.tags);

            if (tags && tags.length > 0 && app.globalData.tags) {
                var systemTags = app.globalData.tags;

                for (var tagId of tags) {
                    var tagInfo = systemTags.find(t => t.id === tagId);

                    if (tagInfo) {
                        userTags.push({
                            id: tagInfo.id,
                            name: tagInfo.name
                        });
                    }
                }
            }
        }

        return userTags;
    }
/*     getOrganizationPreloadedTags: function() {
        let userTags = [];
        let userPrefs = this.get('preferences');

        if (userPrefs.preloaded_fields && userPrefs.preloaded_fields.organization) {
            let tags = _.uniq(userPrefs.preloaded_fields.organization.tags);

            if (tags && tags.length > 0 && app.globalData.tags) {
                let systemTags = app.globalData.tags;

                for (let tagId of tags) {
                    let tagInfo = systemTags.find(t => t.id === tagId);

                    if (tagInfo) {
                        userTags.push({
                            id: tagInfo.id,
                            name: tagInfo.name
                        });
                    }
                }
            }
        }

        return userTags;
    },
    getOpportunityPreloadedTags: function() {
        let userTags = [];
        let userPrefs = this.get('preferences');

        if (userPrefs.preloaded_fields && userPrefs.preloaded_fields.opportunity) {
            let tags = _.uniq(userPrefs.preloaded_fields.opportunity.tags);

            if (tags && tags.length > 0 && app.globalData.tags) {
                let systemTags = app.globalData.tags;

                for (let tagId of tags) {
                    let tagInfo = systemTags.find(t => t.id === tagId);

                    if (tagInfo) {
                        userTags.push({
                            id: tagInfo.id,
                            name: tagInfo.name
                        });
                    }
                }
            }
        }

        return userTags;
    }, */
});

User.fromString = function (string) {
    return new User({email_address: string});
};

export default User;

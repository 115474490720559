import React from 'react'
import Squire from 'squire-rte'

import app from 'js/app'
import guid from 'js/utils/guid'
import AppConfig from 'app/app-config'
import Utilities from 'js/utils/utilities'
import InlineTextEditor from './ite'
import htmlBlocks from './html_blocks'
import {SelectBlockAction, AddBlockAction, DeleteBlockAction, CloneBlockAction, MoveBlockAction, LockBlockAction, UpdateITEAction} from './actions'
import {getBlockTypes} from './consts'

import style from './see.css'

const ContainersBlocksNotSupported = ['triColumnBlock', 'multiColumnBlock', 'singleColumnBlock'];

class LockIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locked: false
    };
  }

  handleClick() {
    this.props.reqLockChanged(!this.state.locked);
  }

  setLocked(locked) {
    this.setState({
      locked: locked
    });
  }

  render() {
    return (
      <div
        className={`
          ${style.sLockIcon}
          ${this.state.locked ? '' : style.liUnlocked}
        `}
        title={`${this.state.locked ? 'Unlock' : 'Lock'} ${this.props.type}`}
        onClick={this.handleClick.bind(this)}
      >
        <div className={`
          ${style.liBig}
          ${this.state.locked ? 'icon-locked' : 'icon-unlocked'}
        `}/>

        <div className={`
          ${style.liSmall}
          ${this.props.type === 'movement' ? 'icon-move' : 'icon-typ-list-single-vetical'}
        `}/>
      </div>
    );
  }
}

class Canvas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dndInfo: {
        headerStyle: {display: 'none'},
        bodyStyle: {display: 'none'},
        footerStyle: {display: 'none'}
      },
      inlineTextEditorInfo: {
        visible: false
      },
      selectedBlock: null,
      selectedBlockStyle: {},
      hoveredBlock: null,
      hoveredBlockStyle: {},
      insertBlockPositionMarkerInfo: {block: null},
      insertBlockPositionMarkerStyle: {},
      sortingBlock: null,
      sortingBlockStyle: {}
    };

    this.sortingStarted = false;

    this.sections = {
      header: {},
      body: {},
      footer: {}
    };

    this.htmlBlockByBlockType = {
      textBlock: htmlBlocks.textBlockHtml,
      imageBlock: htmlBlocks.imageBlockHtml,
      buttonBlock: htmlBlocks.buttonBlockHtml,
      spacerBlock: htmlBlocks.spacerBlockHtml,
      dividerBlock: htmlBlocks.dividerBlockHtml,
      socialsBlock: htmlBlocks.socialsBlockHtml,
      singleColumnBlock: htmlBlocks.singleColumnBlockHtml,
      multiColumnBlock: htmlBlocks.multiColumnBlockHtml,
      triColumnBlock: htmlBlocks.triColumnBlockHtml,
      footerBlock: htmlBlocks.footerBlockHtml,
      plotOffersBlock: htmlBlocks.plotOffersBlockHtml
    };

    this.cssRules = {};
    this.triColumnBlocksInfo = {};
    this.multiColumnBlocksInfo = {};
    this.singleColumnBlocksInfo = {};
  }

  componentDidMount() {
    const self = this;

    _.defer(function() {
      const iframe = document.getElementById('seeCanvas').contentWindow.document;

      let content = self.props.content || htmlBlocks.baseHtml;
      content = self.sanitizeHtml(content);

      iframe.open();
      iframe.write(content);
      iframe.close();

      const seeCanvas = $('#seeCanvas');
      self.head = $(seeCanvas.contents().find('head'));
      self.body = $(seeCanvas.contents().find('body'));
      self.bodyTable = $(self.body.find('#seeBodyTable'));

      // dropable areas
      const headerSection = $(self.body.find('#seeHeaderSection'));
      const bodySection = $(self.body.find('#seeBodySection'));
      const footerSection = $(self.body.find('#seeFooterSection'));

      let headerEmptyBlock = null;
      let bodyEmptyBlock = null;
      let footerEmptyBlock = null;

      if (self.props.content) {
        headerEmptyBlock = $(self.body.find('#headerEmptyBlock'));
        bodyEmptyBlock = $(self.body.find('#bodyEmptyBlock'));
        footerEmptyBlock = $(self.body.find('#footerEmptyBlock'));

        for (const block of self.body.find('.seeBlockContainer')) {
          const blockEl = $(block);

          switch (blockEl.attr('seeBlockType')) {
            case 'footerBlock':
            case 'textBlock': {
              const editableBlock = blockEl.find("div[contenteditable='true']");
              const blockContent = editableBlock[0].innerHTML;

              block.squireEditor = self.createSquireEditor(editableBlock[0]);
              block.squireEditor.setHTML(blockContent);
            }
            break;

            case 'triColumnBlock':
              self.cacheTriColumnBlockInfo(blockEl);
              break;

            case 'multiColumnBlock':
              self.cacheMultiColumnBlockInfo(blockEl);
              break;

            case 'singleColumnBlock':
              self.cacheSingleColumnBlockInfo(blockEl);
              break;
          }

          if (blockEl.attr('seeIsVibBlock') !== 'true') {
            self.addBlockMouseEvents(blockEl);
          }
        }

        // vib block
        const vib = self.body.find('#seeVibBlock');

        if (vib.length > 0) {
          self.vibBlock = $(vib);
          self.vibBlockContainer = self.vibBlock.find('.seeBlockContainer');
          self.addVibBlockMouseEvents();
        }
      }

      // manage ITE classes
      for (const sheet of iframe.styleSheets) {
        try {
          const rules = document.all ? sheet.rules : sheet.cssRules;

          for (const rule of rules) {
            if (rule.selectorText === '.seeBlockContainer p') {
              rule.style['font-size'] = `${self.props.inlineTextEditorDefaults.paragraph.size}px`;
              rule.style.color = self.props.inlineTextEditorDefaults.paragraph.color;
              rule.style['font-family'] = self.props.inlineTextEditorDefaults.paragraph.font;
              rule.style['font-weight'] = self.props.inlineTextEditorDefaults.paragraph.weight;
              rule.style['line-height'] = `${Math.floor(self.props.inlineTextEditorDefaults.paragraph.size * 1.5)}px`;

              self.cssRules['paragraph'] = rule;
            } else if (rule.selectorText === '.seeBlockContainer h1') {
              rule.style['font-size'] = `${self.props.inlineTextEditorDefaults.heading1.size}px`;
              rule.style.color = self.props.inlineTextEditorDefaults.heading1.color;
              rule.style['font-family'] = self.props.inlineTextEditorDefaults.heading1.font;
              rule.style['font-weight'] = self.props.inlineTextEditorDefaults.heading1.weight;
              rule.style['line-height'] = `${Math.floor(self.props.inlineTextEditorDefaults.heading1.size * 1.5)}px`;

              self.cssRules['heading1'] = rule;
            } else if (rule.selectorText === '.seeBlockContainer h2') {
              rule.style['font-size'] = `${self.props.inlineTextEditorDefaults.heading2.size}px`;
              rule.style.color = self.props.inlineTextEditorDefaults.heading2.color;
              rule.style['font-family'] = self.props.inlineTextEditorDefaults.heading2.font;
              rule.style['font-weight'] = self.props.inlineTextEditorDefaults.heading2.weight;
              rule.style['line-height'] = `${Math.floor(self.props.inlineTextEditorDefaults.heading2.size * 1.5)}px`;

              self.cssRules['heading2'] = rule;
            } else if (rule.selectorText === '.seeLink') {
              rule.style['color'] = self.props.linkColor;

              self.cssRules['link'] = rule;
            }
          }
        } catch (_) {
          // ignore the error. It's because it tries to read the css for the google fonts and it gets a CORS error.
        }
      }

      self.sections = {
        header: {
          container: headerSection,
          emptyBlock: headerEmptyBlock || self.addHtml(headerSection, htmlBlocks.emptyBlockMessageHtml('headerEmptyBlock', 'Header block'))
        },
        body: {
          container: bodySection,
          emptyBlock: bodyEmptyBlock || self.addHtml(bodySection, htmlBlocks.emptyBlockMessageHtml('bodyEmptyBlock', 'Body block'))
        },
        footer: {
          container: footerSection,
          emptyBlock: footerEmptyBlock || self.addHtml(footerSection, htmlBlocks.emptyBlockMessageHtml('footerEmptyBlock', 'Footer block'))
        }
      };

      self.initSection(self.sections.header);
      self.initSection(self.sections.body);
      self.initSection(self.sections.footer);

      // ...
      self.body.click(function() {
        if (self.state.inlineTextEditorInfo.visible) {
          self.hideInlineTextEditor();
        }

        self.selectBlock(null);
      });

      self.mounted = true;

      self.iframe.contentWindow.document.onmousemove = self.onMouseMove.bind(self);
      self.iframe.contentWindow.document.onmouseup = self.onMouseUp.bind(self);

      if (self.props.content) {
        self.manageEmptyBlocksVisibility();
      }

      self.manageToolsPosition();
    });
  }

  componentWillUnmount() {
    this.iframe.contentWindow.document.onmousemove = null;
    this.iframe.contentWindow.document.onmouseup = null;

    this.endSection(this.sections.header);
    this.endSection(this.sections.body);
    this.endSection(this.sections.footer);

    this.mounted = false;
  }

  sanitizeHtml(content) {
    // remove extensions scripts
    const scriptInitTag = '<script src="moz-extension://';
    const scriptEndTag = '</script>';
    let startIdx = content.indexOf(scriptInitTag);

    while(startIdx !== -1) {
      const endIdx = content.indexOf(scriptEndTag, startIdx + scriptInitTag.length);

      if (endIdx === -1) {
        break;
      }

      content = content.substring(0, startIdx) + content.substring(endIdx + scriptEndTag.length);
      startIdx = content.indexOf(scriptInitTag);
    }

    return content;
  }

  createSquireEditor(block) {
    return new Squire(block, {
      blockTag: 'P',
      preprocessHtml: function(html, isPaste) {
        if (!isPaste) {
          return html;
        }

        return Utilities.getTextFromHTMLContent(html);
      }
    });
  }

  endSection(section) {
    section.container.parent().droppable('destroy');
  }

  initSection(section) {
    const self = this;

    section.container.parent().droppable({
      accept: function(el) {
        return el.hasClass('ui-draggable');
      },
      over: function() {
        section.highlighter.addClass(style.hovered);
      },
      out: function() {
        section.highlighter.removeClass(style.hovered);
      },
      drop: function(ev, ui) {
        section.highlighter.removeClass(style.hovered);

        if (self.state.insertBlockPositionMarkerInfo.block) {
          const blockType = ui.draggable.attr('id');
          let html;

          if (blockType === 'footerBlock') {
            const clientPreferences = app.user.get('client').preferences || {};
            const globalDetails = clientPreferences.see_global_details ? JSON.parse(clientPreferences.see_global_details) : {};
            html = self.htmlBlockByBlockType[blockType](globalDetails.organization_name, globalDetails.organization_website, globalDetails.organization_address);
          } else {
            html = self.htmlBlockByBlockType[blockType]();
          }

          self.props.executeAction(new AddBlockAction({
            section: section,
            html: $(html),
            canvas: self,
            insertInfo: self.state.insertBlockPositionMarkerInfo
          }));

          self.manageEmptyBlocksVisibility();
        }
      }
    });
  }

  getSectionById(id) {
    switch (id) {
      case 'seeHeaderSection':
        return this.sections.header;

      case 'seeBodySection':
        return this.sections.body;

      case 'seeFooterSection':
        return this.sections.footer;
    }

    return null;
  }

  cacheSingleColumnBlockInfo(block) {
    this.singleColumnBlocksInfo[block.attr('id')] = {
      td: block.find('td.full'),
      container: block.find('td.seeColumn'),
      emptyBlock: block.find('.seeBlockEmptyMessage')
    };
  }

  cacheMultiColumnBlockInfo(block) {
    const columns = block.find('td.full');

    this.multiColumnBlocksInfo[block.attr('id')] = [
      {
        td: $(columns[0]),
        container: $(columns[0]).find('td.seeColumn'),
        emptyBlock: $(columns[0]).find('.seeBlockEmptyMessage')
      },
      {
        td: $(columns[1]),
        container: $(columns[1]).find('td.seeColumn'),
        emptyBlock: $(columns[1]).find('.seeBlockEmptyMessage')
      }
    ];
  }

  cacheTriColumnBlockInfo(block) {
    const columns = block.find('td.full');

    this.triColumnBlocksInfo[block.attr('id')] = [
      {
        td: $(columns[0]),
        container: $(columns[0]).find('td.seeColumn'),
        emptyBlock: $(columns[0]).find('.seeBlockEmptyMessage')
      },
      {
        td: $(columns[1]),
        container: $(columns[1]).find('td.seeColumn'),
        emptyBlock: $(columns[1]).find('.seeBlockEmptyMessage')
      },
      {
        td: $(columns[2]),
        container: $(columns[2]).find('td.seeColumn'),
        emptyBlock: $(columns[2]).find('.seeBlockEmptyMessage')
      }
    ];
  }

  manageToolsPosition() {
    if (!this.mounted) {
      return;
    }

    // inline text editor
    if (this.state.inlineTextEditorInfo.visible) {
      const bbox = this.state.inlineTextEditorInfo.blockContainer[0].getBoundingClientRect();
      const newTop = bbox.top - 50;

      if (newTop !== this.state.inlineTextEditorInfo.top) {
        let itei = this.state.inlineTextEditorInfo;
        itei.top = newTop;

        this.setState({inlineTextEditorInfo: itei});
      }
    }

    // selected block highlighter
    if (this.state.selectedBlock) {
      const bbox = this.state.selectedBlock[0].getBoundingClientRect();

      if (bbox.top !== (this.state.selectedBlockStyle.top + 3) || (bbox.height !== this.state.selectedBlockStyle.height - 6)) {
        this.setState({
          selectedBlockStyle: {
            top: bbox.top - 3,
            left: bbox.left - 3,
            width: bbox.width + 6,
            height: bbox.height + 6
          },
          blockToolsStyle: {
            top: bbox.top,
            left: bbox.right + 10
          }
        });
      }
    }

    // hovered block highlighter
    if (this.state.hoveredBlock) {
      const bbox = this.state.hoveredBlock[0].getBoundingClientRect();

      if (bbox.top !== (this.state.hoveredBlockStyle.top + 3) || (bbox.height !== this.state.hoveredBlockStyle.height - 6)) {
        this.setState({
          hoveredBlockStyle: {
            top: bbox.top - 3,
            left: bbox.left - 3,
            width: bbox.width + 6,
            height: bbox.height + 6
          }
        });
      }
    }

    _.delay(this.manageToolsPosition.bind(this), 5);
  }

  addHtml(parent, block, insertInfo) {
    const el = $(block);

    if (insertInfo) {
      if (insertInfo.before) {
        insertInfo.block.before(el);
      } else {
        insertInfo.block.after(el);
      }
    } else {
      if (_.isString(parent)) {
        parent = $(this.body.find(parent));
      }

      parent.append(el);
    }

    return el;
  }

  addVIBBlock() {
    this.vibBlock = $(htmlBlocks.vibBlockHtml(
      this.props.inlineTextEditorDefaults.paragraph.color,
      this.props.inlineTextEditorDefaults.paragraph.font
    ));

    this.vibBlockContainer = this.vibBlock.find('.seeBlockContainer');

    const parent = $(this.body.find('#seeTableTBody'));
    parent.prepend(this.vibBlock);

    const blockContainer = this.vibBlock.find('.seeBlockContainer');
    const editableBlock = blockContainer.find("div[contenteditable='true']");
    const blockContent = editableBlock[0].innerHTML;

    blockContainer[0].squireEditor = this.createSquireEditor(editableBlock[0]);
    blockContainer[0].squireEditor.setHTML(blockContent);

    this.addVibBlockMouseEvents();
    this.props.onContentChange();
  }

  removeVIBBlock() {
    if (this.vibBlock) {
      this.vibBlock.off();
      this.vibBlock.remove();
      this.vibBlock = null;
      this.vibBlockContainer = null;
      this.props.onContentChange();
    }
  }

  addVibBlockMouseEvents() {
    const self = this;
    const blockContainer = this.vibBlockContainer;

    this.vibBlock.on('selectstart', function(ev) {
      self.selectBlock(blockContainer);
    });

    this.vibBlock.on('click', function(ev) {
      ev.stopPropagation();
    });

    blockContainer.on('mouseover', function(ev) {
      self.setHoveredBlock(blockContainer);
    });

    blockContainer.on('mouseleave', function(ev) {
      self.setHoveredBlock(null);
    });
  }

  addBlockMouseEvents(block) {
    const self = this;

    if (block.attr('seeBlockType') === 'textBlock') {
      block.on('selectstart', function(ev) {
        self.selectBlock(block);
      });
      block.on('click', function(ev) {
        ev.stopPropagation();
      });
    } else {
      block.on('click', function(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        self.selectBlock(block);
      });
    }

    block.on('mouseover', function(ev) {
      ev.stopPropagation();
      self.setHoveredBlock(block);
    });

    block.on('mouseleave', function(ev) {
      ev.stopPropagation();
      self.setHoveredBlock(null);
    });
  }

  onStartDragging(blockType) {
    this.draggingBlockType = blockType;
    this.showDnDInfo();
  }

  onStopDragging() {
    this.hideDnDInfo();

    this.setState({
      insertBlockPositionMarkerInfo: {
        block: null
      }
    });
  }

  addBlock(section, block, insertInfo, params) {
    params = params || {};

    const el = this.addHtml(section.container, block, insertInfo);
    const self = this;
    let blockParams = {};

    this.addBlockMouseEvents(el);
    this.props.onContentChange();

    switch (block.attr('seeBlockType')) {
      case 'footerBlock':
      case 'textBlock': {
        const editableBlock = block.find("div[contenteditable='true']");
        const blockContent = editableBlock[0].innerHTML;

        block[0].squireEditor = this.createSquireEditor(editableBlock[0]);
        block[0].squireEditor.setHTML(blockContent);
        block[0].squireEditor.focus();
      }
      break;

      case 'triColumnBlock':
        this.cacheTriColumnBlockInfo(block);
        break;

      case 'multiColumnBlock':
        this.cacheMultiColumnBlockInfo(block);
        break;

      case 'singleColumnBlock':
        this.cacheSingleColumnBlockInfo(block);
        break;

      case 'imageBlock':
        if (!params.redoingAction) {
          blockParams = {openLibraryBrowser: true};
        }
        break;
    }

    this.manageEmptyBlocksVisibility();

    _.defer(function() {
      self.selectBlock(el, blockParams, true);
    });

    return el;
  }

  deleteBlock(blockId) {
    if (this.state.selectedBlock && (blockId === this.state.selectedBlock.attr('id'))) {
      this.selectBlock(null, null, true);
    }

    let block = this.getBlockById(blockId);

    block.off();
    block.remove();

    this.manageEmptyBlocksVisibility();
    this.props.onContentChange();
  }

  selectBlock(block, blockParams, noRedo) {
    const editor = this.props.editor;

    if (!editor.activeBlock && !block) {
      return;
    }

    if (editor.activeBlock && block && editor.activeBlock.attr('id') === block.attr('id')) {
      return;
    }

    this.props.executeAction(new SelectBlockAction({
      currentBlock: editor.activeBlock,
      newBlock: block,
      newBlockParams: blockParams,
      canvas: this
    }), noRedo);
  }

  cloneBlock(block) {
    const newBlock = block.clone();

    this.configureClonedBlock(newBlock);
    newBlock.insertAfter(block);
    this.props.onContentChange();

    const self = this;

    _.defer(function() {
      self.selectBlock(newBlock, null, true);
    });

    return newBlock;
  }

  configureClonedBlock(newBlock, noSetId) {
    if (!noSetId) {
      newBlock.attr('id', guid());
    }

    this.addBlockMouseEvents(newBlock);

    const blockType = newBlock.attr('seeBlockType');

    if (['triColumnBlock', 'multiColumnBlock', 'singleColumnBlock'].indexOf(blockType) !== -1) {
      for (const childBlock of newBlock.find('.seeBlockContainer')) {
        this.configureClonedBlock($(childBlock), noSetId);
      }
    }

    switch (blockType) {
      case 'triColumnBlock':
        this.cacheTriColumnBlockInfo(newBlock);
        break;

      case 'multiColumnBlock':
        this.cacheMultiColumnBlockInfo(newBlock);
        break;

      case 'singleColumnBlock':
        this.cacheSingleColumnBlockInfo(newBlock);
        break;

      case 'footerBlock':
      case 'textBlock': {
        const editableBlock = newBlock.find("div[contenteditable='true']");
        const blockContent = editableBlock[0].innerHTML;

        newBlock[0].squireEditor = this.createSquireEditor(editableBlock[0]);
        newBlock[0].squireEditor.setHTML(blockContent);
      }
      break;
    }
  }

  cloneSelectedBlock() {
    this.props.executeAction(new CloneBlockAction({
      block: this.state.selectedBlock,
      canvas: this
    }));
  }

  getBlockById(blockId) {
    if (!blockId) {
      return null;
    }

    const block = this.body.find(`.seeBlockContainer#${blockId}`);

    if (block.length === 0) {
      return null;
    }

    return $(block[0]);
  }

  getSingleColumnBlockInsertionInfo(scBlock, pointerX, pointerY) {
    let block = scBlock;
    let before = null;
    let columnParent = null;

    const blockInfo = this.singleColumnBlocksInfo[scBlock.attr('id')];
    const bbox = blockInfo.td[0].getBoundingClientRect();

    if (Utilities.pointInBoundingBox(pointerX, pointerY , bbox)) {
      const children = blockInfo.container.children();

      if (children.length === 1) {
        block = $(children[0]);
        before = true;
      } else {
        for (const c of children) {
          const b = $(c);

          if (!b.hasClass('seeBlockContainer')) {
            continue;
          }

          const cbbox = b[0].getBoundingClientRect();

          if (Utilities.pointInBoundingBox(pointerX, pointerY, cbbox)) {
            block = b;

            // all elements are inserted before, but they can be inserted after the last one
            const nextSibling = b.next();

            if (nextSibling.length === 0 || !nextSibling.hasClass('seeBlockContainer')) {
              before = (pointerY - cbbox.top < cbbox.bottom - pointerY);
            }

            break;
          }
        }
      }

      columnParent = blockInfo;
    }

    return {block, before, columnParent};
  }

  getMultiColumnBlockInsertionInfo(blocksInfo, mcBlock, pointerX, pointerY) {
    let block = mcBlock;
    let before = null;
    let columnParent = null;
    const blockInfo = blocksInfo[mcBlock.attr('id')];

    for (const column of blockInfo) {
      const bbox = column.td[0].getBoundingClientRect();

      if (Utilities.pointInBoundingBox(pointerX, pointerY , bbox)) {
        const children = column.container.children();

        if (children.length === 1) {
          block = $(children[0]);
          before = true;
        } else {
          for (const c of children) {
            const b = $(c);

            if (!b.hasClass('seeBlockContainer')) {
              continue;
            }

            const cbbox = b[0].getBoundingClientRect();

            if (Utilities.pointInBoundingBox(pointerX, pointerY, cbbox)) {
              block = b;

              // all elements are inserted before, but they can be inserted after the last one
              const nextSibling = b.next();

              if (nextSibling.length === 0 || !nextSibling.hasClass('seeBlockContainer')) {
                before = (pointerY - cbbox.top < cbbox.bottom - pointerY);
              }

              break;
            }
          }
        }

        columnParent = column;
        break;
      }
    }

    return {block, before, columnParent};
  }

  getBlockInsertionInfo(pointerX, pointerY) {
    let block = null;
    let before = true;
    let columnParent = null;

    for (const s in this.sections) {
      const section = this.sections[s];
      const bbox = section.container[0].getBoundingClientRect();

      if (Utilities.pointInBoundingBox(pointerX, pointerY, bbox)) {
        const children = section.container.children();

        if (children.length === 1) {
          block = section.emptyBlock;
        } else {
          for (const c of children) {
            const b = $(c);

            if (!b.hasClass('seeBlockContainer')) {
              continue;
            }

            const bbox = b[0].getBoundingClientRect();

            if (Utilities.pointInBoundingBox(pointerX, pointerY, bbox)) {
              const blockType = b.attr('seeBlockType');

              if (blockType === 'triColumnBlock' && ContainersBlocksNotSupported.indexOf(this.draggingBlockType) === -1) {
                const info = this.getMultiColumnBlockInsertionInfo(this.triColumnBlocksInfo, b, pointerX, pointerY);
                block = info.block;
                before = info.before;
                columnParent = info.columnParent;
              } else if (blockType === 'multiColumnBlock' && ContainersBlocksNotSupported.indexOf(this.draggingBlockType) === -1) {
                const info = this.getMultiColumnBlockInsertionInfo(this.multiColumnBlocksInfo, b, pointerX, pointerY);
                block = info.block;
                before = info.before;
                columnParent = info.columnParent;
              } else if (blockType === 'singleColumnBlock' && ContainersBlocksNotSupported.indexOf(this.draggingBlockType) === -1) {
                const info = this.getSingleColumnBlockInsertionInfo(b, pointerX, pointerY);
                block = info.block;
                before = info.before;
                columnParent = info.columnParent;
              } else {
                block = b;
                before = null;
              }

              if (before === null) {
                // all elements are inserted before, but they can be inserted after the last one
                const nextSibling = b.next();

                if (nextSibling.length === 0 || !nextSibling.hasClass('seeBlockContainer')) {
                  before = (pointerY - bbox.top < bbox.bottom - pointerY);
                } else {
                  before = true;
                }
              }

              break;
            }
          }
        }
        break;
      }
    }

    return {block, before, columnParent};
  }

  onDrag(ev, isSorting) {
    let pointerX = ev.clientX;
    let pointerY = ev.clientY;

    if (!isSorting) {
      const iframeBBox = this.iframe.getBoundingClientRect();

      pointerX -= iframeBBox.x;
      pointerY -= iframeBBox.y;
    }

    const {block, before, columnParent} = this.getBlockInsertionInfo(pointerX, pointerY);

    if (block) {
      const blockBBox = block[0].getBoundingClientRect();

      this.setState({
        insertBlockPositionMarkerInfo: {
          block: block,
          before: before,
          columnParent: columnParent
        },
        insertBlockPositionMarkerStyle: {
          top: before ? blockBBox.top : blockBBox.bottom,
          left: blockBBox.left - 10,
          width: blockBBox.width + 20
        }
      });
    } else {
      this.setState({
        insertBlockPositionMarkerInfo: {
          block: null
        }
      });
    }
  }

  showDnDInfo() {
    // display the drag and drop area around the sections
    const headerBBox = this.sections.header.container[0].getBoundingClientRect();
    const bodyBBox = this.sections.body.container[0].getBoundingClientRect();
    const footerBBox = this.sections.footer.container[0].getBoundingClientRect();

    this.setState({
      dndInfo: {
        headerStyle: {display: 'block', left: `${headerBBox.left}px`, top: `${headerBBox.top}px`, width: `${headerBBox.width}px`, height: `${headerBBox.height}px`},
        bodyStyle: {display: 'block', left: `${bodyBBox.left}px`, top: `${bodyBBox.top}px`, width: `${bodyBBox.width}px`, height: `${bodyBBox.height}px`},
        footerStyle: {display: 'block', left: `${footerBBox.left}px`, top: `${footerBBox.top}px`, width: `${footerBBox.width}px`, height: `${footerBBox.height}px`}
      }
    });
  }

  hideDnDInfo() {
    this.setState({
      dndInfo: {
        headerStyle: {display: 'none'},
        bodyStyle: {display: 'none'},
        footerStyle: {display: 'none'}
      }
    });
  }

  showInlineTextEditor(block) {
    const self = this;

    _.defer(function() {
      const bbox = block[0].getBoundingClientRect();
      const editableBlock = block.find("div[contenteditable='true']");
      const top = block.offset().top - 50;

      if (top < 0) {
        block.css('margin-top', `${-top}px`);
      }

      const iteBarWidth = 899;

      self.setState({
        inlineTextEditorInfo: {
          visible: true,
          top: top,
          left: Utilities.clamp(bbox.left - ((iteBarWidth - bbox.width) / 2), 0, 151),
          block: editableBlock,
          blockContainer: block,
          contentOnFocus: editableBlock.html()
        }
      });
    });
  }

  hideInlineTextEditor(fromAction) {
    if (!this.state.inlineTextEditorInfo.visible) {
      return;
    }

    this.state.inlineTextEditorInfo.blockContainer.css('margin-top', '');

    const currentContent = this.state.inlineTextEditorInfo.block.html();

    if (this.state.inlineTextEditorInfo.contentOnFocus !== currentContent) {
      this.props.editor.executeAction(new UpdateITEAction({
        canvas: this,
        oldValue: this.state.inlineTextEditorInfo.contentOnFocus,
        newValue: currentContent
      }), fromAction);
    }

    this.setState({
      inlineTextEditorInfo: {
        visible: false
      }
    });
  }

  setActiveITEHtml(html) {
    if (this.state.inlineTextEditorInfo.block) {
      this.state.inlineTextEditorInfo.block.html(html);
    }
  }

  setSelectedBlock(block) {
    if (this.state.selectedBlock) {
      this.state.selectedBlock.css('margin-bottom', '');
      this.state.selectedBlock.find('.seeBlockHandler').removeClass('active');
    }

    let newState = {
      selectedBlock: block
    };

    if (block) {
      const bbox = block[0].getBoundingClientRect();

      newState.selectedBlockStyle = {
        top: bbox.top - 3, // the width of the selection border
        left: bbox.left - 3,
        width: bbox.width + 6,
        height: bbox.height + 6
      };

      const blockOffset = block.offset().top;
      const tableEl = this.bodyTable[0];

      if (blockOffset + 120 > tableEl.scrollHeight) {
        block.css('margin-bottom', (blockOffset + 120) - tableEl.scrollHeight);
      }

      newState.blockToolsStyle = {
        top: bbox.top,
        left: bbox.right + 10
      };

      newState.hoveredBlock = null;

      block.find('.seeBlockHandler').addClass('active');

      const contentLocked = block.attr('seecontentlocked');
      const movementLocked = block.attr('seemovementlocked');
      const self = this;

      // is it content editable block?
      const contentEditableBlock = block.find('[contenteditable]');

      if (contentEditableBlock) {
        contentEditableBlock.attr('contenteditable', contentLocked !== 'true' || this.props.userType === 'advance');
      }

      _.defer(function() {
        self.lockContentComponent?.setLocked(contentLocked === 'true');
        self.lockMovementComponent?.setLocked(movementLocked === 'true');
      });
    }

    this.setState(newState);
  }

  setHoveredBlock(block) {
    if (this.sortingItemSection) {
      return;
    }

    if (block && block !== this.state.selectedBlock) {
      const bbox = block[0].getBoundingClientRect();

      this.setState({
        hoveredBlock: block,
        hoveredBlockStyle: {
          top: bbox.top - 3,
          left: bbox.left - 3,
          width: bbox.width + 6,
          height: bbox.height + 6
        }
      });
    } else {
      this.setState({
        hoveredBlock: null
      });
    }
  }

  lockSelectedBlock(type, locked) {
    const block = this.state.selectedBlock;

    this.props.executeAction(new LockBlockAction({
      block: block,
      type: type,
      locked: locked,
      component: type === 'movement' ? this.lockMovementComponent : this.lockContentComponent,
      canvas: this
    }));
  }

  deleteSelectedBlock() {
    const block = this.state.selectedBlock;

    this.selectBlock(null, null, true);

    this.props.executeAction(new DeleteBlockAction({
      block: block,
      canvas: this
    }));
  }

  clear() {
    this.body.find('.seeBlockContainer').remove();
    this.body.find('#seeVibBlock').remove();
    this.singleColumnBlocksInfo = {};
    this.multiColumnBlocksInfo = {};
    this.triColumnBlocksInfo = {};
    this.vibBlock = null;
    this.vibBlockContainer = null;
    this.manageEmptyBlocksVisibility();
  }

  startBlockSorting(ev) {
    this.sortingStarted = true;
  }

  onMouseMove(ev) {
    if (this.sortingStarted) {
      this.draggingBlockType = this.state.selectedBlock.attr('seeBlockType');

      this.setState({
        sortingBlock: this.state.selectedBlock,
        sortingBlockStyle: {
          top: ev.clientY,
          left: ev.clientX
        }
      });

      this.selectBlock(null);
      this.sortingStarted = false;
    } else if (this.state.sortingBlock) {
      this.setState({
        sortingBlockStyle: {
          top: ev.clientY,
          left: ev.clientX
        }
      });

      this.onDrag(ev, true);
    }
  }

  onMouseUp() {
    if (this.state.sortingBlock) {
      this.sortingStarted = false;

      const insertInfo = this.state.insertBlockPositionMarkerInfo;

      if (insertInfo.block.attr('id') !== this.state.sortingBlock.attr('id')) {
        this.props.executeAction(new MoveBlockAction({
          canvas: this,
          insertInfo: insertInfo,
          movingBlock: this.state.sortingBlock
        }));
      }

      this.setState({
        sortingBlock: null,
        insertBlockPositionMarkerInfo: {
          block: null
        }
      });
    }
  }
  manageEmptyBlocksVisibility() {
    for (const s in this.sections) {
      const section = this.sections[s];
      section.emptyBlock.toggleClass('hidden', section.container.children().length > 1);
    }

    for (const m in this.triColumnBlocksInfo) {
      const blockInfo = this.triColumnBlocksInfo[m];
      blockInfo[0].emptyBlock.toggleClass('hidden', blockInfo[0].container.children().length > 1);
      blockInfo[1].emptyBlock.toggleClass('hidden', blockInfo[1].container.children().length > 1);
      blockInfo[2].emptyBlock.toggleClass('hidden', blockInfo[2].container.children().length > 1);
    }

    for (const m in this.multiColumnBlocksInfo) {
      const blockInfo = this.multiColumnBlocksInfo[m];
      blockInfo[0].emptyBlock.toggleClass('hidden', blockInfo[0].container.children().length > 1);
      blockInfo[1].emptyBlock.toggleClass('hidden', blockInfo[1].container.children().length > 1);
    }

    for (const s in this.singleColumnBlocksInfo) {
      const blockInfo = this.singleColumnBlocksInfo[s];
      blockInfo.emptyBlock.toggleClass('hidden', blockInfo.container.children().length > 1);
    }
  }

  setVisualizationMode(mode) {
    switch (mode) {
      case 'desktop':
        this.bodyTable.attr('width', '100%');
        this.body.find('[seeMediaStyle="full"]').removeClass('mobileView');
        this.body.find('[seePlotOfferItem="true"]').removeClass('mobileView');
        break;

      case 'phone':
        this.bodyTable.attr('width', '320px');
        this.body.find('[seeMediaStyle="full"]').addClass('mobileView');
        this.body.find('[seePlotOfferItem="true"]').addClass('mobileView');
        break;
    }
  }

  isSelectedBlockLocked(lockType) {
    if (!this.state.selectedBlock) {
      return false;
    }

    return this.state.selectedBlock.attr(`see${lockType}locked`);
  }

  render() {
    // todo: add specific code for MSO
    let sortingBlockInfo = null;
    const blockTypes = getBlockTypes();

    if (this.state.sortingBlock) {
      sortingBlockInfo = blockTypes.plain.find(bt => bt.id === this.state.sortingBlock.attr('seeBlockType'));
    }

    const advanceUser = this.props.userType === 'advance';
    const showLockControls = AppConfig.getValue('campaigns.see.lock_controls.enabled');
    const isMovementLocked = !advanceUser && this.isSelectedBlockLocked('movement');
    const isContentLocked = !advanceUser && this.isSelectedBlockLocked('content');

    return (
      <div className={style.canvasContainer}>
        <iframe
          ref={(el) => this.iframe = el}
          id='seeCanvas'
          className={style.canvas}
        />
        <div
          className={style.canvasTools}
          style={this.state.toolsStyle}
        >
          <div ref={(el) => {this.sections.header.highlighter = $(el)}} className={style.sectionHighlighter} style={this.state.dndInfo.headerStyle}><div className={style.highlighterTitle}>Header</div></div>
          <div ref={(el) => {this.sections.body.highlighter = $(el)}} className={style.sectionHighlighter} style={this.state.dndInfo.bodyStyle}><div className={style.highlighterTitle}>Body</div></div>
          <div ref={(el) => {this.sections.footer.highlighter = $(el)}} className={style.sectionHighlighter} style={this.state.dndInfo.footerStyle}><div className={style.highlighterTitle}>Footer</div></div>

          {!this.state.sortingBlock && this.state.hoveredBlock &&
            <div className={style.hoveredBlockHighlighter} style={this.state.hoveredBlockStyle}/>
          }

          {this.state.insertBlockPositionMarkerInfo.block &&
            <div className={style.insertBlockPositionMarker} style={this.state.insertBlockPositionMarkerStyle}/>
          }

          {this.state.sortingBlock &&
            <div
              className={style.seeEditorBlock}
              style={{
                position: 'absolute',
                top: `${this.state.sortingBlockStyle.top}px`,
                left: `${this.state.sortingBlockStyle.left}px`,
              }}
            >
              <div className={`${style.seeEditorBlockIcon} ${sortingBlockInfo.icon}`}/>
              <div className={style.blockName}>{sortingBlockInfo.name}</div>
            </div>
          }

          {!this.state.sortingBlock && this.state.selectedBlock &&
            <div>
              <div className={style.selectedBlockHighlighter} style={this.state.selectedBlockStyle}/>

              {this.state.selectedBlock.attr('seeHasBlockTools') !== 'false' &&
                <div className={style.blockTools} style={this.state.blockToolsStyle}>
                  {!isMovementLocked &&
                    <div
                      className='icon-move'
                      onMouseDown={this.startBlockSorting.bind(this)}
                    />
                  }

                  {!isContentLocked && !isMovementLocked &&
                    <div
                      className='icon-clone'
                      onClick={this.cloneSelectedBlock.bind(this)}
                    />
                  }

                  {!isContentLocked && !isMovementLocked &&
                    <div
                      className='icon-trashcan'
                      onClick={this.deleteSelectedBlock.bind(this)}
                    />
                  }

                  {isMovementLocked &&
                    <div
                      className='icon-locked'
                      style={{cursor: 'default'}}
                    />
                  }

                  {showLockControls &&
                    <LockIcon
                      ref={(el) => this.lockMovementComponent = el}
                      type='movement'
                      reqLockChanged={(locked) => this.lockSelectedBlock.bind(this)('movement', locked)}
                    />
                  }

                  {showLockControls &&
                    <LockIcon
                      ref={(el) => this.lockContentComponent = el}
                      type='content'
                      reqLockChanged={(locked) => this.lockSelectedBlock.bind(this)('content', locked)}
                    />
                  }
                </div>
              }
            </div>
          }

          {this.state.inlineTextEditorInfo.visible && !isContentLocked &&
            <InlineTextEditor
              iframe={this.iframe}
              top={this.state.inlineTextEditorInfo.top}
              left={this.state.inlineTextEditorInfo.left}
              block={this.state.inlineTextEditorInfo.block}
              squireEditor={this.state.inlineTextEditorInfo.blockContainer[0].squireEditor}
              editor={this.props.editor}
              mergeTagItems={this.props.mergeTagItems}
              parent={this.props.parent}
              textDefaults={this.props.inlineTextEditorDefaults}
              onContentChange={this.props.onContentChange}
            />
          }
        </div>
      </div>
    );
  }
}

export default Canvas;
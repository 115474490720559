import operators from 'app_v2/filter_operators';
import TextManager from 'app/text-manager';
import { getAvailableFilters, createCustomFieldFilters } from 'app_v2/filter_aux';

const contactText = TextManager.parseText('${ID_INDIVIDUAL, capitalize}');
const dealText = TextManager.parseText('${ID_DEAL, capitalize}');

const BASIC_FILTERS = {
    [dealText]: [{
        id: 'opportunity_name',
        title: 'Name',
        operators: operators.textOperators
    }, {
        id: 'opportunity_funnel_id',
        title: TextManager.parseText('${ID_FUNNEL, capitalize}'),
        operators: operators.funnelOperators
    }, {
        id: 'opportunity_funnel_region_id',
        title: TextManager.parseText('${ID_FUNNEL, capitalize} Region'),
        operators: operators.regionOperators
    }, {
        id: 'funnel_cluster_id',
        title: TextManager.parseText('${ID_CLUSTER, capitalize}'),
        operators: operators.clusterOperators
    }, {
        id: 'opportunity_phase_id',
        title: 'Phase',
        operators: operators.phaseOperators
    }, {
        id: 'opportunity_status',
        title: 'Status',
        operators: operators.forecastStatusOperators
    }, {
        id: 'opportunity_closed_date',
        title: 'Close Date',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_comments',
        title: 'More Info',
        operators: operators.textOperators
    }, {
        id: 'opportunity_created',
        title: 'Created',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_modified',
        title: 'Modified',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_tags',
        title: 'Tag',
        operators: operators.tagOperators
    }, {
        id: 'is_opportunity_favorite',
        title: 'Is Elephant',
        notTitle: 'Is Not Elephant',
        operators: operators.boolOperators
    }, {
        id: 'opportunity_period_id',
        title: 'Period',
        operators: operators.periodOperators
    }, {
        id: 'opportunity_owner_id',
        title: 'Owner',
        operators: operators.userOperators
    }, {
        id: 'opportunity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'opportunity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        operators: operators.boolOperators
    }, {
        id: 'opportunity_automation2',
        title: 'Automation',
        operators: operators.automationOperators,
        isAvailable: function() {
            return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
        }
    }],
    [`${dealText} Bucket`]: [{
        id: 'opportunity_bucket_id',
        title: 'Bucket',
        operators: operators.opportunityBucketOperators
    }, {
        id: 'opportunity_bucket_value',
        title: 'Value',
        descTitle: 'Bucket Value',
        operators: operators.numberOperators
    }, {
        id: 'opportunity_bucket_name',
        title: 'Name',
        descTitle: 'Bucket Name',
        operators: operators.textOperators
    }, {
        id: 'opportunity_buckets_total',
        title: 'Total',
        descTitle: 'Bucket Total',
        operators: operators.numberOperators
    }],
    [`${dealText} Activity`]: [{
        id: 'opportunity_activity_note',
        title: 'Note',
        descTitle: 'Activity Note',
        operators: operators.textOperators
    }, {
        id: 'opportunity_activity_target_date',
        title: 'Reminder Date',
        descTitle: 'Activity Reminder Date',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_activity_modified',
        title: 'Note Created Date',
        descTitle: 'Activity Note Created Date',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_activity_type',
        title: 'Type',
        descTitle: 'Activity Type',
        operators: operators.activityTypeOperators
    }, {
        id: 'opportunity_activity_type_ex',
        title: 'Type Ex',
        descTitle: 'Activity Type Ex',
        operators: operators.activityTypeExOperators
    }, {
        id: 'opportunity_activity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'opportunity_activity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        operators: operators.boolOperators
    }],
    [`${dealText} Starred Activity`]: [{
        id: 'opportunity_pinned_activity_note',
        title: 'Note',
        descTitle: 'Starred Activity Note',
        operators: operators.textOperators
    }, {
        id: 'opportunity_pinned_activity_target_date',
        title: 'Reminder Date',
        descTitle: 'Starred Activity Reminder Date',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_pinned_activity_modified',
        title: 'Note Created Date',
        descTitle: 'Starred Activity Note Created Date',
        operators: operators.dateOperators
    }, {
        id: 'opportunity_pinned_activity_type',
        title: 'Type',
        descTitle: 'Starred Activity Type',
        operators: operators.activityTypeOperators
    }, {
        id: 'opportunity_pinned_activity_type_ex',
        title: 'Type Ex',
        descTitle: 'Starred Activity Type Ex',
        operators: operators.activityTypeExOperators
    }, {
        id: 'opportunity_pinned_activity_owned_by_me',
        title: 'Owned By Me',
        descTitle: 'Starred Owned By Me',
        notTitle: 'Not Owned By Me',
        descNotTitle: 'Starred Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'opportunity_pinned_activity_created_by_me',
        title: 'Created By Me',
        descTitle: 'Starred Created By Me',
        notTitle: 'Not Created By Me',
        descNotTitle: 'Starred Not Created By Me',
        operators: operators.boolOperators
    }],
    [`${dealText} Checklist`]: [{
        id: 'opportunity_checklist_id',
        title: 'Checklist',
        operators: operators.checklistOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }, {
        id: 'opportunity_checklist_item_assigned_to_me',
        title: 'Assigned To Me',
        descTitle: 'Checklist Assigned To Me',
        operators: operators.checklistItemOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }],
    [contactText]: [{
        id: 'individual_id',
        title: contactText,
        operators: operators.individualOperators
    }, {
        id: 'individual_name',
        title: 'Name',
        descTitle: `${contactText} Name`,
        operators: operators.textOperators
    }, {
        id: 'individual_first_name',
        title: 'First Name',
        descTitle: `${contactText} Fist Name`,
        operators: operators.textOperators
    }, {
        id: 'individual_last_name',
        title: 'Last Name',
        descTitle: `${contactText} Last Name`,
        operators: operators.textOperators
    }, {
        id: 'individual_comments',
        title: 'More Info',
        descTitle: `${contactText} More Info`,
        operators: operators.textOperators
    }, {
        id: 'individual_tags',
        title: 'Tag',
        descTitle: `${contactText} Tag`,
        operators: operators.tagOperators
    }, {
        id: 'individual_photo_url',
        title: 'Photo URL',
        descTitle: `${contactText} Photo URL`,
        operators: operators.textOperators
    }, {
        id: 'individual_created',
        title: 'Created',
        descTitle: `${contactText} Created`,
        operators: operators.dateOperators
    }, {
        id: 'individual_modified',
        title: 'Modified',
        descTitle: `${contactText} Modified`,
        operators: operators.dateOperators
    }, {
        id: 'individual_became_lead',
        title: 'Became Lead',
        descTitle: `${contactText} Became Lead`,
        operators: operators.dateOperators
    }, {
        id: 'individual_twitter',
        title: 'Twitter',
        descTitle: `${contactText} Twitter`,
        operators: operators.textOperators
    }, {
        id: 'individual_facebook',
        title: 'Facebook',
        descTitle: `${contactText} Facebook`,
        operators: operators.textOperators
    }, {
        id: 'individual_instagram',
        title: 'Instagram',
        descTitle: `${contactText} Instagram`,
        operators: operators.textOperators
    }, {
        id: 'individual_linkedin',
        title: 'Instagram',
        descTitle: `${contactText} LinkedIn`,
        operators: operators.textOperators
    }, {
        id: 'owner_id',
        title: 'Owner',
        descTitle: `${contactText} Owner`,
        operators: operators.userOperators
    }, {
        id: 'individual_role',
        title: 'Job Role',
        descTitle: `${contactText} Job Role`,
        operators: operators.textOperators
    }, {
        id: 'individual_source',
        title: 'Lead Source',
        descTitle: `${contactText} Lead Source`,
        operators: operators.leadSourceOperators
    }, {
        id: 'is_individual_favorite',
        title: 'Is Favorite',
        notTitle: 'Is Not Favorite',
        descTitle: `${contactText} Is Favorite`,
        descNotTitle: `${contactText} Is Not Favorite`,
        operators: operators.boolOperators
    }, {
        id: 'is_individual_recent',
        title: 'Is Recent',
        notTitle: 'Is Not Recent',
        descTitle: `${contactText} Is Recent`,
        descNotTitle: `${contactText} Is Not Recent`,
        operators: operators.boolOperators
    }, {
        id: 'is_individual_unsubscribed_all',
        title: 'Unsubscribed',
        notTitle: 'Not Unsubscribed',
        descTitle: `${contactText} Unsubscribed`,
        descNotTitle: `${contactText} Not Unsubscribed`,
        operators: operators.boolOperators
    }, {
        id: 'individual_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: `${contactText} Owned By Me`,
        descNotTitle: `${contactText} Not Owned By Me`,
        operators: operators.boolOperators
    }, {
        id: 'individual_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: `${contactText} Created By Me`,
        descNotTitle: `${contactText} Not Created By Me`,
        operators: operators.boolOperators
    }, {
        id: 'individual_automation2',
        title: 'Automation',
        descTitle: `${contactText} Automation`,
        operators: operators.automationOperators,
        isAvailable: function() {
            return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
        }
    }],
    [`${contactText} Location`]: [{
        id: 'location_name',
        title: 'Name',
        descTitle: `${contactText} Location Name`,
        operators: operators.textOperators
    }, {
        id: 'location_address',
        title: 'Address',
        descTitle: `${contactText} Location Address`,
        operators: operators.textOperators
    }, {
        id: 'location_comments',
        title: 'More Info',
        descTitle: `${contactText} Location More Info`,
        operators: operators.textOperators
    }],
    [`${contactText} Communication`]: [{
        id: 'communication_type',
        title: 'Communications',
        descTitle: `${contactText} Communications`,
        operators: operators.communicationOperators
    }, {
        id: 'communication_name',
        title: 'Name',
        descTitle: `${contactText} Communication Name`,
        operators: operators.textOperators
    }, {
        id: 'communication_address',
        title: 'Address',
        descTitle: `${contactText} Communication Address`,
        operators: operators.textOperators
    }, {
        id: 'communication_comments',
        title: 'More Info',
        descTitle: `${contactText} Communication More Info`,
        operators: operators.textOperators
    }],
    [`${contactText} Activity`]: [{
        id: 'activity_note',
        title: 'Note',
        descTitle: `${contactText} Activity Note`,
        operators: operators.textOperators
    }, {
        id: 'activity_target_date',
        title: 'Reminder Date',
        descTitle: `${contactText} Activity Reminder Date`,
        operators: operators.dateOperators
    }, {
        id: 'activity_modified',
        title: 'Created Date',
        descTitle: `${contactText} Activity Created Date`,
        operators: operators.dateOperators
    }, {
        id: 'individual_activity_type',
        title: 'Type',
        descTitle: `${contactText} Activity Type`,
        operators: operators.activityTypeOperators
    }, {
        id: 'individual_activity_type_ex',
        title: 'Type Ex',
        descTitle: `${contactText} Activity Type Ex`,
        operators: operators.activityTypeExOperators
    }, {
        id: 'individual_activity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: `${contactText} Activity Owned By Me`,
        descNotTitle: `${contactText} Activity Not Owned By Me`,
        operators: operators.boolOperators
    }, {
        id: 'individual_activity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: `${contactText} Activity Created By Me`,
        descNotTitle: `${contactText} Activity Not Created By Me`,
        operators: operators.boolOperators
    }],
    [`${contactText} Starred Activity`]: [{
        id: 'pinned_activity_note',
        title: 'Note',
        descTitle: `${contactText} Starred Activity Note`,
        operators: operators.textOperators
    }, {
        id: 'pinned_activity_target_date',
        title: 'Reminder Date',
        descTitle: `${contactText} Starred Activity Reminder Date`,
        operators: operators.dateOperators
    }, {
        id: 'pinned_activity_modified',
        title: 'Created Date',
        descTitle: `${contactText} Starred Activity Created Date`,
        operators: operators.dateOperators
    }, {
        id: 'individual_pinned_activity_type',
        title: 'Type',
        descTitle: `${contactText} Starred Activity Type`,
        operators: operators.activityTypeOperators
    }, {
        id: 'individual_pinned_activity_type_ex',
        title: 'Type Ex',
        descTitle: `${contactText} Starred Activity Type Ex`,
        operators: operators.activityTypeExOperators
    }, {
        id: 'individual_pinned_activity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: `${contactText} Starred Activity Owned By Me`,
        descNotTitle: `${contactText} Starred Activity Not Owned By Me`,
        operators: operators.boolOperators
    }, {
        id: 'individual_pinned_activity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: `${contactText} Starred Activity Created By Me`,
        descNotTitle: `${contactText} Starred Activity Not Created By Me`,
        operators: operators.boolOperators
    }],
    [`${contactText} Checklist`]: [{
        id: 'individual_checklist_id',
        title: 'Checklist',
        descTitle: `${contactText} Checklist`,
        operators: operators.checklistOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }, {
        id: 'individual_checklist_item_assigned_to_me',
        title: 'Assigned To Me',
        descTitle: `${contactText} Checklist Assigned To Me`,
        operators: operators.checklistItemOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }],
    [`${contactText} Web Activity`]: [{
        id: 'individual_activity_web_page_visited',
        title: 'Visited',
        descTitle: `${contactText} Web Activity Visited`,
        operators: operators.webPageVisitedOperators
    }, {
        id: 'individual_activity_web_page_registered',
        title: 'Registered',
        descTitle: `${contactText} Web Activity Registered`,
        operators: operators.dateOperators
    }],
    Organization: [{
        id: 'organization_id',
        title: 'Organization',
        operators: operators.organizationOperators
    }, {
        id: 'organization_name',
        title: 'Name',
        descTitle: 'Organization Name',
        operators: operators.textOperators
    }, {
        id: 'organization_website',
        title: 'Website',
        descTitle: 'Organization Website',
        operators: operators.textOperators
    }, {
        id: 'organization_comments',
        title: 'More Info',
        descTitle: 'Organization More Info',
        operators: operators.textOperators
    }, {
        id: 'organization_created',
        title: 'Created',
        descTitle: 'Organization Created',
        operators: operators.dateOperators
    }, {
        id: 'organization_modified',
        title: 'Modified',
        descTitle: 'Organization Modified',
        operators: operators.dateOperators
    }, {
        id: 'organization_owner_id',
        title: 'Owner',
        descTitle: 'Organization Owner',
        operators: operators.userOperators
    }, {
        id: 'organization_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: 'Organization Owned By Me',
        descNotTitle: 'Organization Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'organization_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: 'Organization Created By Me',
        descNotTitle: 'Organization Not Created By Me',
        operators: operators.boolOperators
    }, {
        id: 'organization_is_recent',
        title: 'Is Recent',
        notTitle: 'Is Not Recent',
        descTitle: 'Organization Is Recent',
        descNotTitle: 'Organization Is Not Recent',
        operators: operators.boolOperators
    }, {
        id: 'organization_tags',
        title: 'Tag',
        descTitle: 'Organization Tag',
        operators: operators.tagOperators
    }, {
        id: 'organization_automation2',
        title: 'Automation',
        descTitle: 'Organization Automation',
        operators: operators.automationOperators,
        isAvailable: function() {
            return !_.contains(app.user.get('preferences').lab_flags, 'SAL-4026');
        }
    }],
    'Organization Location': [{
        id: 'organization_name',
        title: 'Name',
        descTitle: 'Organization Location Name',
        operators: operators.textOperators
    }, {
        id: 'organization_address',
        title: 'Address',
        descTitle: 'Organization Location Address',
        operators: operators.textOperators
    }, {
        id: 'organization_comments',
        title: 'More Info',
        descTitle: 'Organization Location More Info',
        operators: operators.textOperators
    }],
    'Organization Communication': [{
        id: 'organization_communication_type',
        title: 'Communications',
        descTitle: 'Organization Communications',
        operators: operators.communicationOperators
    }, {
        id: 'organization_communication_name',
        title: 'Name',
        descTitle: 'Organization Communication Name',
        operators: operators.textOperators
    }, {
        id: 'organization_communication_address',
        title: 'Address',
        descTitle: 'Organization Communication Address',
        operators: operators.textOperators
    }, {
        id: 'organization_communication_comments',
        title: 'More Info',
        descTitle: 'Organization Communication More Info',
        operators: operators.textOperators
    }],
    'Organization Activity': [{
        id: 'organization_activity_note',
        title: 'Note',
        descTitle: 'Organization Activity Note',
        operators: operators.textOperators
    }, {
        id: 'organization_activity_target_date',
        title: 'Reminder Date',
        descTitle: 'Organization Activity Reminder Date',
        operators: operators.dateOperators
    }, {
        id: 'organization_activity_modified',
        title: 'Created Date',
        descTitle: 'Organization Activity Created Date',
        operators: operators.dateOperators
    }, {
        id: 'organization_activity_type',
        title: 'Type',
        descTitle: 'Organization Activity Type',
        operators: operators.activityTypeOperators
    }, {
        id: 'organization_activity_type_ex',
        title: 'Type Ex',
        descTitle: 'Organization Activity Type Ex',
        operators: operators.activityTypeExOperators
    }, {
        id: 'organization_activity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: 'Organization Activity Owned By Me',
        descNotTitle: 'Organization Activity Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'organization_activity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: 'Organization Activity Created By Me',
        descNotTitle: 'Organization Activity Not Created By Me',
        operators: operators.boolOperators
    }],
    'Organization Starred Activity': [{
        id: 'organization_pinned_activity_note',
        title: 'Note',
        descTitle: 'Organization Starred Activity Note',
        operators: operators.textOperators
    }, {
        id: 'organization_pinned_activity_target_date',
        title: 'Reminder Date',
        descTitle: 'Organization Starred Activity Reminder Date',
        operators: operators.dateOperators
    }, {
        id: 'organization_pinned_activity_modified',
        title: 'Created Date',
        descTitle: 'Organization Starred Activity Created Date',
        operators: operators.dateOperators
    }, {
        id: 'organization_pinned_activity_type',
        title: 'Type',
        descTitle: 'Organization Starred Activity Type',
        operators: operators.activityTypeOperators
    }, {
        id: 'organization_pinned_activity_type_ex',
        title: 'Type Ex',
        descTitle: 'Organization Starred Activity Type Ex',
        operators: operators.activityTypeExOperators
    }, {
        id: 'organization_pinned_activity_owned_by_me',
        title: 'Owned By Me',
        notTitle: 'Not Owned By Me',
        descTitle: 'Organization Starred Activity Owned By Me',
        descNotTitle: 'Organization Starred Activity Not Owned By Me',
        operators: operators.boolOperators
    }, {
        id: 'organization_pinned_activity_created_by_me',
        title: 'Created By Me',
        notTitle: 'Not Created By Me',
        descTitle: 'Organization Starred Activity Created By Me',
        descNotTitle: 'Organization Starred Activity Not Created By Me',
        operators: operators.boolOperators
    }],
    'Organization Checklist': [{
        id: 'organization_checklist_id',
        title: 'Checklist',
        descTitle: 'Organization Checklist',
        operators: operators.checklistOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }, {
        id: 'organization_checklist_item_assigned_to_me',
        title: 'Assigned To Me',
        descTitle: 'Organization Checklist Assigned To Me',
        operators: operators.checklistItemOperators,
        isAvailable: function() {
            return _.contains(app.user.get('preferences').lab_flags, 'SAL-4299');
        }
    }],
    Group: [{
        id: 'group_id',
        title: 'Group',
        operators: operators.opportunityGroupOperators
    }],
    Task: [{
        id: 'task_text',
        title: 'Text',
        descTitle: 'Task Text',
        operators: operators.textOperators
    }, {
        id: 'task_subject',
        title: 'Subject',
        descTitle: 'Task Subject',
        operators: operators.textOperators
    }],
    Campaigns: [{
        id: 'campaign_stats_send',
        title: 'Sent',
        descTitle: 'Campaigns Sent',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_delivered',
        title: 'Delivered',
        descTitle: 'Campaigns Delivered',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_open',
        title: 'Opened',
        descTitle: 'Campaigns Opened',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_click',
        title: 'Clicked',
        descTitle: 'Campaigns Clicked',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_unsubscribed',
        title: 'Unsubscribed',
        descTitle: 'Campaigns Unsubscribed',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_bounce',
        title: 'Bounced',
        descTitle: 'Campaigns Bounced',
        operators: operators.campaignOperators
    }, {
        id: 'campaign_stats_spam',
        title: 'Marked Spam',
        descTitle: 'Campaigns Maked Spam',
        operators: operators.campaignOperators
    }]
};

const getFilters = () => {
    let filters = getAvailableFilters(BASIC_FILTERS);

    filters[`${dealText} Custom`] = createCustomFieldFilters({
        entityType: 'opportunities',
        filterId: 'opportunity_custom',
        descPrefix: `${dealText} `
    });

    filters[`${contactText} Custom`] = createCustomFieldFilters({
        entityType: 'individuals',
        filterId: 'individual_custom',
        descPrefix: `${contactText} `
    });

    filters['Organization Custom'] = createCustomFieldFilters({
        entityType: 'organizations',
        filterId: 'organization_custom',
        descPrefix: 'Organization '
    });

    return filters;
}

export default getFilters;
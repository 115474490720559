import _ from 'underscore'
import Handlebars from 'handlebars'

import AppConfig from 'app/app-config'
import BaseCollection from 'js/collections/base'
import OrganizationModel from 'js/models/organization'
import OrganizationListingBaseView from 'app/_components/IOD-listing/IOD/organization-listing/organization-listing-base'
import OrganizationRecentModel from 'js/models/organizationRecent'
import OrganizationRecentCreatedModel from 'js/models/organizationRecentCreated'
import app from 'js/app'
import vent from 'js/vent'
import dateFormat from 'js/utils/date-format'
import MessageBox from 'js/views/message_box'
import security from 'js/utils/security'
import TableBodyContainerView from 'js/views/base/table-body-container-view'
import overviewTemplate from 'app/_components/IOD-listing/IOD/IOD-listing-overview.handlebars'


var BaseOverviewView = OrganizationListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    template: Handlebars.compile(overviewTemplate),
    templateHelpers: function() {
        return {
            hideInfo: true,
            disableLayoutSelection: true,
            showPermissions: security.checkPermission('edit', this.model) && app.user.get('client').permission_type !== 'rba'
        };
    },
    initialize: function() {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        if (this.options.byCreateDate) {
            this.fetchOptions = _.extend({
                group_id: 'created'
            }, this.fetchOptions);
        }
        else {
            this.fetchOptions = _.extend({
                group_id: 'recents'
            }, this.fetchOptions);
        }
    },
    createTableView: function() {
        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            selectedColumns: this.getSelectedColumns(),
            customFields: this.customFields,
            type: this.options.type,
            elementType: this.options.elementType,
            tableModel: this.model,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: true,
                addButton: true
            },
            defaultSort: this.options.byCreateDate ? { direction: false, field: ["created"] } : { direction: false, field: ["last_viewed"] }
        });
    },
    getSelectedColumns: function() {
        return this.model.get('columns');
    },
    onSelectItem: function(model) {
        this.options.parent.trigger('push-view:organization:show', {model: model});
    }
});

export default OrganizationListingBaseView.extend({
    OverviewView: OverviewView,
    initialize: function(options) {
        _.defaults(this, options);

        if (this.options.byCreateDate) {
            this.model = new OrganizationRecentCreatedModel();
        }
        else {
            this.model = new OrganizationRecentModel();
        }
    },
    onRender: function() {
        var view = this;

        this.model.fetch({
            success: function() {
                view.showOverviewView();
            }
        });
    },
    getElementType: function() {
        return 'organizations';
    },
    getUrl: function() {
        if (this.options.byCreateDate) {
            return 'recentCreatedOrganizations';
        }
        else {
            return 'recentOrganizations';
        }
    }
});

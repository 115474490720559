import $ from 'jquery'
import _ from 'underscore'
import React from 'react'

import app from 'js/app'
import vent from 'js/vent'
import GroupModel from 'js/models/group'
import {NewSelect, TagSelect} from 'js/react_views/widgets/select'

import style from './new-group.css'
import TextManager from "app/text-manager";

const GroupTypes = [{
    id: 'static',
    title: 'Static'
}, {
    id: 'smart',
    title: 'Smart'
}];

class NewGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            comments: '',
            owner: {
                id: app.user.get('id'),
                title: app.user.get('name')
            },
            groupType: GroupTypes[0],
            nameErrorMessage: null,
            tags: app.user.getIndividualPreloadedTags(),
            funnels: app.user.getIndividualPreloadedFunnels()
        }

        if (props.model) {
            this.state.name = props.model.name;
            this.state.comments = props.model.comments;
            this.state.owner = {
                id: props.model.owner.id,
                title: props.model.owner.name
            };
            this.state.groupType = GroupTypes.find(gt => gt.id === props.model.group_type);
            this.state.tags = props.model.tags;
            this.state.funnels = props.model.funnels;
        }
    }

    onSave() {
        if (this.state.name) {
            const self = this;
            const group = new GroupModel({id: this.props.model ? this.props.model.id : null});

            group.save({
                element_type: this.props.elementType,
                group_type: this.state.groupType.id,
                name: this.state.name,
                comments: this.state.comments,
                owner: {
                    id: this.state.owner.id,
                    name: this.state.owner.name
                },
                tags: this.state.tags,
                funnels: this.state.funnels
            }, {
                validate: true,
                patch: true,
                success: function() {
                    self.props.onSave(group);
                }
            });
        } else {
            this.setState({
                nameErrorMessage: 'Group name is required'
            });
        }
    }

    handleNameChange(ev) {
        this.setState({
            name: ev.target.value
        });
    }

    handleCommentsChange(ev) {
        this.setState({
            comments: ev.target.value
        });
    }

    handleOwnerChange(item) {
        const owner = item[0];

        this.setState({
            owner: {
                id: owner.id,
                title: owner.title
            }
        });
    }

    handleTypeChange(items) {
        this.setState({groupType: _.clone(items[0])});
    }

    handleTagChange(items){
        this.state.tags = _.map(items, function(item) { return { id: item.id }});
    }

    handleFunnelChange(items){
        this.state.funnels = _.map(items, function(item) { return { id: item.id }});
    }

    render() {
        const showPermissions = app.user.get('client').permission_type !== 'rba';
        return (
            <div className={style.NewGroup}>
                <div className={style.Toolbar}>
                    <div
                        className={style.Button}
                        onClick={this.props.onCancel}
                    >
                        Cancel
                    </div>
                    {this.props.model &&
                        <div className={style.IconButtons}>
                            <i
                                className={`${style.IconButton} ${style.Delete} icon-trashcan`}
                                title='Delete Item'
                                onClick={this.props.onDelete}
                            />
                            {showPermissions &&
                                <i
                                    className={`${style.IconButton} ${style.Permissions} icon-locked`}
                                    title='Show Permissions'
                                    onClick={this.props.onPermissions}
                                />
                            }
                        </div>
                    }
                    {!this.props.model &&
                        <div className={style.Title}>New Group</div>
                    }
                    <div
                        className={`${style.Button} ${style.Green}`}
                        onClick={this.onSave.bind(this)}
                    >
                        Save
                    </div>
                </div>
                <div className={style.Fields}>
                    <div className={style.Row}>
                        <div className={style.Label}>Group Name</div>
                        <div className={style.Value}>
                            <input
                                type='text'
                                value={this.state.name}
                                className={`${style.TextInput} ${this.state.nameErrorMessage ? style.Error : ''}`}
                                placeholder='Group Name'
                                maxLength='50'
                                onChange={this.handleNameChange.bind(this)}
                                autoFocus
                            />
                            {this.state.nameErrorMessage &&
                                <div className={style.FieldError}>{this.state.nameErrorMessage}</div>
                            }
                        </div>
                    </div>
                    <div className={style.Row}>
                        <div className={style.Label}>More Info</div>
                        <div className={style.Value}>
                            <textarea
                                className={style.TextInput}
                                value={this.state.comments}
                                placeholder='Write something about this group'
                                onChange={this.handleCommentsChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className={style.Row}>
                        <div className={style.Label}>Type</div>
                        <div className={style.Value}>
                            <div style={{ width: '260px' }}>
                                <NewSelect
                                    data={GroupTypes}
                                    value={this.state.groupType}
                                    onSelect={this.handleTypeChange.bind(this)}
                                    disabled={!!this.props.model}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.Row}>
                        <div className={style.Label}>Owner</div>
                        <div className={style.Value}>
                            <div style={{ width: '260px' }}>
                                <NewSelect
                                    url='/users'
                                    value={this.state.owner}
                                    options={{minimumInputLength: 1}}
                                    onSelect={this.handleOwnerChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.Row}>
                        <div className={style.Label}>Tags</div>
                        <div className={style.Value}>
                            <div style={{ width: '260px' }}>
                                <TagSelect
                                    url='/tags'
                                    text='name'
                                    width='250'
                                    value={this.state.tags}
                                    placeholder='+ Add Tag'
                                    onChange={this.handleTagChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={style.Row}>
                        <div className={style.Label}>{TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}</div>
                        <div className={style.Value}>
                            <div style={{ width: '260px' }}>
                                <TagSelect
                                    url='/funnels'
                                    text='name'
                                    width='250'
                                    value={this.state.funnels}
                                    placeholder={`+ Add ${TextManager.parseText('${ID_FUNNEL, capitalize}')}`}
                                    onChange={this.handleFunnelChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewGroup;
import React from 'react';

import {GroupSelectItem} from 'js/react_views/widgets/select';
import MultiSelect from 'js/react_views/widgets/multiselect';

const colors = {
    darkGrey: '#404040'
};

class GroupMembership extends React.Component {
    render() {
        return (
            <div>
                <header
                    style={{
                        color: colors.darkGrey,
                        fontSize: '18px',
                        lineHeight: '43px',
                        height: '42px',
                        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.07)'
                    }}>

                    Group Membership
                </header>
                <div
                    style={{
                        marginTop: 6,
                        border: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRadius: '3px'
                    }}>
                    <MultiSelect
                        items={this.props.items}
                        selectItem={GroupSelectItem}
                        onSearch={this.props.onSearch}
                        onAdd={this.props.onAdd}
                        onRemove={this.props.onRemove}
                        onItemClick={this.props.onGroupClick}
                        itemCustomCSS={{ background: '#addcff', color: 'white' }}/>
                </div>
            </div>
        );
    }
}

export default GroupMembership;

import AppConfig from 'app/app-config';

export const Fonts = [
  {id: 'arial',             title: 'Arial'},
  {id: 'arialBlack',        title: 'Arial Black'},
  {id: 'brushScriptMt',     title: 'Brush Script MT'},
  {id: 'comicSansMs',       title: 'Comic Sans MS'},
  {id: 'courierNew',        title: 'Courier New'},
  {id: 'georgia',           title: 'Georgia'},
  {id: 'helvetica',         title: 'Helvetica'},
  {id: 'impact',            title: 'Impact'},
  {id: 'lucidaSansUnicode', title: 'Lucida Sans Unicode'},
  {id: 'tahoma',            title: 'Tahoma'},
  {id: 'timesNewRoman',     title: 'Times New Roman'},
  {id: 'trebuchetMs',       title: 'Trebuchet MS'},
  {id: 'verdana',           title: 'Verdana'},

  // Google fonts
  {id: 'lato',              title: 'Lato',               fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'},
  {id: 'lora',              title: 'Lora',               fallback: 'Georgia, "Times New Roman", Times, serif',  link: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap'},
  {id: 'merriweather',      title: 'Merriweather',       fallback: 'Georgia, "Times New Roman", Times, serif',  link: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'},
  {id: 'merriweatherSans',  title: 'Merriweather Sans',  fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,700;0,800;1,300;1,400;1,700;1,800&display=swap'},
  {id: 'nunito',            title: 'Nunito',             fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'},
  {id: 'noticiaText',       title: 'Noticia Text',       fallback: 'Georgia, "Times New Roman", Times, serif',  link: 'https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap'},
  {id: 'openSans',          title: 'Open Sans',          fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap'},
  {id: 'playfairDisplay',   title: 'Playfair Display',   fallback: 'Georgia, "Times New Roman", Times, serif',  link: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'},
  {id: 'roboto',            title: 'Roboto',             fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'},
  {id: 'sourceSansPro',     title: 'Source Sans Pro',    fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'},
  {id: 'raleway',           title: 'Raleway',            fallback: 'Helvetica, Arial',                          link: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'}
];

export const FontWeights = [
  {id: 'thin',        title: 'Thin',         value: 100},
  {id: 'extraLight',  title: 'Extra-Light',  value: 200},
  {id: 'light',       title: 'Light',        value: 300},
  {id: 'normal',      title: 'Normal',       value: 400},
  {id: 'medium',      title: 'Medium',       value: 500},
  {id: 'semiBold',    title: 'Semi-Bold',    value: 600},
  {id: 'bold',        title: 'Bold',         value: 700},
  {id: 'extraBold',   title: 'Extra-Bold',   value: 800},
  {id: 'ultraBold',   title: 'Ultra-Bold',   value: 900}
];

export const FontSizes = [
  {id: 'common10',     title: '10',           value: '10px'},
  {id: 'common12',     title: '12',           value: '12px'},
  {id: 'common14',     title: '14',           value: '14px'},
  {id: 'common16',     title: '16',           value: '16px'},
  {id: 'common18',     title: '18',           value: '18px'},
  {id: 'common20',     title: '20',           value: '20px'},
  {id: 'common24',     title: '24',           value: '24px'},
  {id: 'common32',     title: '32',           value: '32px'},
  {id: 'common48',     title: '48',           value: '48px'},
  {id: 'common64',     title: '64',           value: '64px'},
  {id: 'other10',      title: '10',           value: '10px'},
  {id: 'other11',      title: '11',           value: '11px'},
  {id: 'other12',      title: '12',           value: '12px'},
  {id: 'other13',      title: '13',           value: '13px'},
  {id: 'other14',      title: '14',           value: '14px'},
  {id: 'other15',      title: '15',           value: '15px'},
  {id: 'other16',      title: '16',           value: '16px'},
  {id: 'other17',      title: '17',           value: '17px'},
  {id: 'other18',      title: '18',           value: '18px'},
  {id: 'other19',      title: '19',           value: '19px'},
  {id: 'other20',      title: '20',           value: '20px'},
  {id: 'other21',      title: '21',           value: '21px'},
  {id: 'other22',      title: '22',           value: '22px'},
  {id: 'other23',      title: '23',           value: '23px'},
  {id: 'other24',      title: '24',           value: '24px'},
  {id: 'other25',      title: '25',           value: '25px'},
  {id: 'other26',      title: '26',           value: '26px'},
  {id: 'other27',      title: '27',           value: '27px'},
  {id: 'other28',      title: '28',           value: '28px'},
  {id: 'other29',      title: '29',           value: '29px'},
  {id: 'other30',      title: '30',           value: '30px'},
  {id: 'other31',      title: '31',           value: '31px'},
  {id: 'other32',      title: '32',           value: '32px'},
  {id: 'other33',      title: '33',           value: '33px'},
  {id: 'other34',      title: '34',           value: '34px'},
  {id: 'other35',      title: '35',           value: '35px'},
  {id: 'other36',      title: '36',           value: '36px'},
  {id: 'other37',      title: '37',           value: '37px'},
  {id: 'other38',      title: '38',           value: '38px'},
  {id: 'other39',      title: '39',           value: '39px'},
  {id: 'other40',      title: '40',           value: '40px'},
  {id: 'other41',      title: '41',           value: '41px'},
  {id: 'other42',      title: '42',           value: '42px'},
  {id: 'other43',      title: '43',           value: '43px'},
  {id: 'other44',      title: '44',           value: '44px'},
  {id: 'other45',      title: '45',           value: '45px'},
  {id: 'other46',      title: '46',           value: '46px'},
  {id: 'other47',      title: '47',           value: '47px'},
  {id: 'other48',      title: '48',           value: '48px'},
  {id: 'other49',      title: '49',           value: '49px'},
  {id: 'other50',      title: '50',           value: '50px'},
  {id: 'other51',      title: '51',           value: '51px'},
  {id: 'other52',      title: '52',           value: '52px'},
  {id: 'other53',      title: '53',           value: '53px'},
  {id: 'other54',      title: '54',           value: '54px'},
  {id: 'other55',      title: '55',           value: '55px'},
  {id: 'other56',      title: '56',           value: '56px'},
  {id: 'other57',      title: '57',           value: '57px'},
  {id: 'other58',      title: '58',           value: '58px'},
  {id: 'other59',      title: '59',           value: '59px'},
  {id: 'other60',      title: '60',           value: '60px'},
  {id: 'other61',      title: '61',           value: '61px'},
  {id: 'other62',      title: '62',           value: '62px'},
  {id: 'other63',      title: '63',           value: '63px'},
  {id: 'other64',      title: '64',           value: '64px'},
];

export const BorderStyles = [
  {id: 'dashed',    title: 'Dashed'},
  {id: 'dotted',    title: 'Dotted'},
  {id: 'double',    title: 'Double'},
  {id: 'groove',    title: 'Groove'},
  {id: 'hidden',    title: 'Hidden'},
  {id: 'inset',     title: 'Inset'},
  {id: 'none',      title: 'None'},
  {id: 'outset',    title: 'Outset'},
  {id: 'solid',     title: 'Solid'}
];

export const VerticalAlignment = [
  {id: 'top',       title: 'Top'},
  {id: 'middle',    title: 'Middle'},
  {id: 'bottom',    title: 'Bottom'}
];

export const Colors = {
  black: '#000000',
  navy: '#000080',
  darkblue: '#00008b',
  mediumblue: '#0000cd',
  blue: '#0000ff',
  darkgreen: '#006400',
  green: '#008000',
  teal: '#008080',
  darkcyan: '#008b8b',
  deepskyblue: '#00bfff',
  darkturquoise: '#00ced1',
  mediumspringgreen: '#00fa9a',
  lime: '#00ff00',
  springgreen: '#00ff7f',
  aqua: '#00ffff',
  cyan: '#00ffff',
  midnightblue: '#191970',
  dodgerblue: '#1e90ff',
  lightseagreen: '#20b2aa',
  forestgreen: '#228b22',
  seagreen: '#2e8b57',
  darkslategray: '#2f4f4f',
  darkslategrey: '#2f4f4f',
  limegreen: '#32cd32',
  mediumseagreen: '#3cb371',
  turquoise: '#40e0d0',
  royalblue: '#4169e1',
  steelblue: '#4682b4',
  darkslateblue: '#483d8b',
  mediumturquoise: '#48d1cc',
  indigo: '#4b0082',
  darkolivegreen: '#556b2f',
  cadetblue: '#5f9ea0',
  cornflowerblue: '#6495ed',
  rebeccapurple: '#663399',
  mediumaquamarine: '#66cdaa',
  dimgray: '#696969',
  dimgrey: '#696969',
  slateblue: '#6a5acd',
  olivedrab: '#6b8e23',
  slategray: '#708090',
  slategrey: '#708090',
  lightslategray: '#778899',
  lightslategrey: '#778899',
  mediumslateblue: '#7b68ee',
  lawngreen: '#7cfc00',
  chartreuse: '#7fff00',
  aquamarine: '#7fffd4',
  maroon: '#800000',
  purple: '#800080',
  olive: '#808000',
  gray: '#808080',
  grey: '#808080',
  skyblue: '#87ceeb',
  lightskyblue: '#87cefa',
  blueviolet: '#8a2be2',
  darkred: '#8b0000',
  darkmagenta: '#8b008b',
  saddlebrown: '#8b4513',
  darkseagreen: '#8fbc8f',
  lightgreen: '#90ee90',
  mediumpurple: '#9370db',
  darkviolet: '#9400d3',
  palegreen: '#98fb98',
  darkorchid: '#9932cc',
  yellowgreen: '#9acd32',
  sienna: '#a0522d',
  brown: '#a52a2a',
  darkgray: '#a9a9a9',
  darkgrey: '#a9a9a9',
  lightblue: '#add8e6',
  greenyellow: '#adff2f',
  paleturquoise: '#afeeee',
  lightsteelblue: '#b0c4de',
  powderblue: '#b0e0e6',
  firebrick: '#b22222',
  darkgoldenrod: '#b8860b',
  mediumorchid: '#ba55d3',
  rosybrown: '#bc8f8f',
  darkkhaki: '#bdb76b',
  silver: '#c0c0c0',
  mediumvioletred: '#c71585',
  indianred: '#cd5c5c',
  peru: '#cd853f',
  chocolate: '#d2691e',
  tan: '#d2b48c',
  lightgray: '#d3d3d3',
  lightgrey: '#d3d3d3',
  thistle: '#d8bfd8',
  orchid: '#da70d6',
  goldenrod: '#daa520',
  palevioletred: '#db7093',
  crimson: '#dc143c',
  gainsboro: '#dcdcdc',
  plum: '#dda0dd',
  burlywood: '#deb887',
  lightcyan: '#e0ffff',
  lavender: '#e6e6fa',
  darksalmon: '#e9967a',
  violet: '#ee82ee',
  palegoldenrod: '#eee8aa',
  lightcoral: '#f08080',
  khaki: '#f0e68c',
  aliceblue: '#f0f8ff',
  honeydew: '#f0fff0',
  azure: '#f0ffff',
  sandybrown: '#f4a460',
  wheat: '#f5deb3',
  beige: '#f5f5dc',
  whitesmoke: '#f5f5f5',
  mintcream: '#f5fffa',
  ghostwhite: '#f8f8ff',
  salmon: '#fa8072',
  antiquewhite: '#faebd7',
  linen: '#faf0e6',
  lightgoldenrodyellow: '#fafad2',
  oldlace: '#fdf5e6',
  red: '#ff0000',
  fuchsia: '#ff00ff',
  magenta: '#ff00ff',
  deeppink: '#ff1493',
  orangered: '#ff4500',
  tomato: '#ff6347',
  hotpink: '#ff69b4',
  coral: '#ff7f50',
  darkorange: '#ff8c00',
  lightsalmon: '#ffa07a',
  orange: '#ffa500',
  lightpink: '#ffb6c1',
  pink: '#ffc0cb',
  gold: '#ffd700',
  peachpuff: '#ffdab9',
  navajowhite: '#ffdead',
  moccasin: '#ffe4b5',
  bisque: '#ffe4c4',
  mistyrose: '#ffe4e1',
  blanchedalmond: '#ffebcd',
  papayawhip: '#ffefd5',
  lavenderblush: '#fff0f5',
  seashell: '#fff5ee',
  cornsilk: '#fff8dc',
  lemonchiffon: '#fffacd',
  floralwhite: '#fffaf0',
  snow: '#fffafa',
  yellow: '#ffff00',
  lightyellow: '#ffffe0',
  ivory: '#fffff0',
  white: '#ffffff',
  transparent: 'transparent'
};

export const Alignments = [{
  id: 'left',
  icon: 'icon-img-align-left',
}, {
  id: 'center',
  icon: 'icon-img-align-center',
}, {
  id: 'right',
  icon: 'icon-img-align-right',
}]

export const getBlockTypes = (userType) => {
  let blockTypes = [];

  if (!userType || userType === 'advance') {
    blockTypes = [{
      title: 'Content',
      blocks: [{
        id: 'imageBlock',
        name: 'Image',
        icon: 'icon-link-image'
      }, {
        id: 'textBlock',
        name: 'Text',
        icon: 'icon-typ-justified'
      }, {
        id: 'buttonBlock',
        name: 'Button',
        icon: 'icon-ui-button'
      }, {
        id: 'socialsBlock',
        name: 'Socials',
        icon: 'icon-social-share'
      }]
    }, {
      title: 'Utility',
      blocks: [{
        id: 'dividerBlock',
        name: 'Divider',
        icon: 'icon-lay-reflect-vertical'
      }, {
        id: 'spacerBlock',
        name: 'Spacer',
        icon: 'icon-lay-rectangle-marquee'
      }, {
        id: 'footerBlock',
        name: 'Footer',
        icon: 'icon-footer-block'
      }, {
        id: 'singleColumnBlock',
        name: '1 Column',
        icon: 'icon-1-block',
        settingsTitle: '1 Column Container'
      }, {
        id: 'multiColumnBlock',
        name: '2 Column',
        icon: 'icon-2-block',
        settingsTitle: '2 Column Container'
      }, {
        id: 'triColumnBlock',
        name: '3 Column',
        icon: 'icon-3-block',
        settingsTitle: '3 Column Container'
      }]
    }];

    const allDynamicContentBlocks = [{
      id: 'plotOffersBlock',
      name: 'Plot Offers',
      icon: 'icon-plot-offer',
      settingsTitle: 'Plot Offers'
    }];

    const clientDynamicContentBlocks = AppConfig.getValue('campaigns.see.dynamic_content.blocks') || [];
    const dynamicContentBlocks = allDynamicContentBlocks.filter(b => clientDynamicContentBlocks.indexOf(b.id) !== -1);

    if (dynamicContentBlocks && dynamicContentBlocks.length > 0) {
      blockTypes.push({
        title: 'Dynamic Content',
        blocks: dynamicContentBlocks
      });
    }
  }

  // ...
  let plain = [];

  for (const c of blockTypes) {
    plain = [...plain, ...c.blocks];
  }

  return {
    byCategory: blockTypes,
    plain: plain
  };
}
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import SectionBase from 'app_v2/sections/base/section';
import getFilters from './filters';
import FinderContentManager from 'app_v2/sections/finder_content_manager';
import { getAllColumns, getDefaultColumnsIds } from './table_columns';


export default class MessagesReactView extends React.Component {
    constructor(props) {
        super(props);

        this.allColumns = getAllColumns();
        this.tableColumns = [];

        for (const cid of getDefaultColumnsIds()) {
            this.tableColumns.push(this.allColumns.find(c => c.id === cid));
        }

        this.finderContentManager = new FinderContentManager();
    }

    componentDidMount() {
        this.mounted = true;
        this.createFinderStructure(this.props.initialGroupId);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createFinderStructure(activeGroupId) {
        this.finderContentManager.clear();

        // initialize finder content manager
        let sections = [{
            id: 'messages',
            entityType: 'messages',
            name: 'Messages',
            content: [
                this.finderContentManager.createSpecialGroup('all', 'All Messages', 'icon-chat'),
                this.finderContentManager.createSpecialGroup('unread', 'Unread Messages', 'icon-email', {fetchArgs: {read_status: 'unread'}}),
                this.finderContentManager.createSpecialGroup('read', 'Read Messages', 'icon-campaign', {fetchArgs: {read_status: 'read'}}),
                // this.finderContentManager.createSpecialGroup('closed', 'Closed Messages', 'icon-locked', {fetchArgs: {status: 'closed'}})
            ]
        }];

        const self = this;

        this.finderContentManager.fetchUserStructure('conversations', function(structure) {
            if (!self.mounted) {
                return;
            }

            const finderExpanded = !activeGroupId;
            const section = sections[0];
            section.content = [...section.content, ...structure];

            self.finderContentManager.addSections(sections, 0);

            // initial group
            if (!activeGroupId) {
                activeGroupId = 'all';

                if (self.props.initialGroupId) {
                    activeGroupId = self.props.initialGroupId;
                } else {
                    activeGroupId = app.user.get('preferences').initial_conversations_group;
                }
            }

            const activeGroupExists = !!self.finderContentManager.getElement(activeGroupId);

            self.finderContentManager.setActiveGroupId(activeGroupExists ? activeGroupId : 'all');
            self.sectionComponent.onContentManagerUpdated(finderExpanded);
        });
    }

    handleGroupSave(groupId) {
        this.createFinderStructure(`groups/${groupId}`);
    }

    handleRowClick(rowId) {
        $.get(`/conversations/${rowId}`, function(data) {
            const commonUrl = `${data.related_id}?conversation_id=${rowId}`;

            switch (data.related_type) {
                case 'individual':
                    window.location = `/#individuals/${commonUrl}`;
                    break;

                case 'opportunity':
                    window.location = `/#deals/${commonUrl}`;
                    break;
            }
        });
    }

    getUrl() {
        return this.sectionComponent.getUrl();
    }

    getParams() {
        let params = {}

        return params;
    }

    render() {
        return (
            <SectionBase
                ref={(el) => this.sectionComponent = el}
                parent={this.props.parent}
                entityType='conversations'
                filters={getFilters()}
                activeView='table'
                onRowClick={this.handleRowClick.bind(this)}
                finder={{
                    contentManager: this.finderContentManager,
                    // createGroups: {
                    //     smart: true
                    // },
                    createGroups: false,
                    createFolders: false
                }}
                toolbar={{
                    search: true,
                    editColumns: true,
                    // saveAsGroup: {
                    //     smart: true
                    // },
                    saveAsGroup: false,
                    saveAsCsv: true,
                    advancedFilter: true,
                    views: {
                        table: true
                    }
                }}
                table={{
                    allColumns: this.allColumns,
                    defaultColumnsIds: getDefaultColumnsIds(),
                    columns: this.tableColumns
                }}
                onGroupSaved={this.handleGroupSave.bind(this)}
            />
        );
    }
}

import _ from 'underscore'

import BaseModel from 'js/models/base'

export default BaseModel.extend({
    name: 'Region',
    urlRoot: '/regions',
    validate: function(attrs) {
        var errors = {
        };

        if (_.isUndefined(attrs.name) || attrs.name === ''){
            errors.name_undefined = 'Region Name is required';
        }

        if (_.size(errors) > 0) {
            return errors;
        }
    }
});

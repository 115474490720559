import React from 'react'

import LoadingIndicator from 'js/react_views/widgets/loading-indicator'

import style from './kpi_simple.css'


export default class KpiSimple extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            fetchingData: false
        };

        const self = this;

        this.props.widget.addEventListener('refresh', function() {
            self.fetchData(true);
        });

        _.defer(function() {
            self.fetchData();
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchData(force) {
        if (!this.mounted) {
            return;
        }

        const query = this.props.widget.params.query;

        if (!query) {
            return;
        }

        // only displays the loader the first time
        if (this.state.value === null) {
            this.setState({
                fetchingData: true
            });
        }

        let options = _.clone(query.options || {});
        options.force = force;

        this.props.widget.fetcher.get(query.url, query.args, this.onData.bind(this), options);
    }

    onData(data) {
        if (!this.mounted) {
            return;
        }

        if (this.state.value !== null && this.state.value !== data && this.props.widget.params.on_data_change_event) {
            this.props.widget.triggerEvent(this.props.widget.params.on_data_change_event);
        }

        this.setState({
            value: data,
            fetchingData: false
        });
    }

    render() {
        const params = this.props.widget.params;
        const renderer = this.props.renderer;
        let iconContainerStyle = {};
        let secondRowStyle = {};
        let thirdRowTitleStyle = {};
        let thirdRowArrowStyle = {};
        
        if (renderer) {
            const bbox = renderer.getBoundingClientRect();
            const rectSize = Math.max(bbox.height / 3.2, 37);

            iconContainerStyle = {
                width: Math.max(bbox.height / 4.2, 22),
                height: Math.max(bbox.height / 4.2, 22),
                fontSize: Math.max(bbox.height / 7, 19)
            };

            secondRowStyle = {
                fontSize: Math.max(bbox.height / 4.3, 28)
            };

            thirdRowTitleStyle = {
                fontSize: Math.max(bbox.height / 12, 10)
            };

            thirdRowArrowStyle = {
                fontSize: Math.max(bbox.height / 7.5, 16)
            };
        }

        return (
            <div className={style.kpiSimple}>
                <div className={style.kFirstRow}>
                    <div
                        className={style.tTitle}
                        style={thirdRowTitleStyle}
                    >
                        {params.title}
                    </div>

                    <div
                        className={style.fIconContainer}
                        style={iconContainerStyle}
                    >
                        <div className={params.icon}/>
                    </div>
                </div>

                {this.state.fetchingData ? (
                    <div className={style.kSecondRow}>
                        <LoadingIndicator/>
                    </div>
                ) : (
                    <div
                        className={style.kSecondRow}
                        style={secondRowStyle}
                    >
                        {this.state.value}

                        {params.link?.event &&
                            <div
                                className={`icon-arrow-right ${style.tArrow}`}
                                style={thirdRowArrowStyle}
                                onClick={() => this.props.widget.triggerEvent(params.link.event, params.link.params)}
                            />
                        }
                    </div>
                )}
            </div>
        );
    }
}
import React from 'react'

import app from 'js/app'
import vent from 'js/vent';
import TextManager from 'app/text-manager'
import { NewSelect, TagSelect } from 'js/react_views/widgets/select';

import style from './quick_add_report.css'


export default class QuickAddReportReact extends React.Component {
    constructor(props) {
        super(props);

        this.reportType = null;
        this.funnelsSelected = [];
        this.reportTypes = [
            {
                id: 'progression_report',
                name: 'Progression Report',
            },
            {
                id: 'skipped_id_check_report',
                name: 'Skipped ID check report',
            },
        ];
    }

    componentWillMount() {
        const funnels = app.globalData.funnelsInfo.funnels;
        let funnelData = [];

        for (let funnel of funnels) {
            if (funnel.integration_data && funnel.integration_data.developmentReference) {
                funnelData.push(funnel);
            }
        }

        this.funnels = funnelData;
    }

    handleClose() {
        vent.trigger('close:quickRegion');
    }

    handleSave() {
        if (!this.reportType || this.funnelsSelected.length === 0) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'All fields are required',
                        classes: 'load-error error',
                        timer: 3000
                    };
                }
            });

            return;
        }

        const data = {
            report_type: this.reportType.id,
            funnels: this.funnelsSelected,
        };

        $.ajax({
            type: 'POST',
            url: '/yourkeys_generate_report',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(data),
            success: function(data) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Report requested successfully. Please expect an email notification',
                            timer: 3000,
                            classes: 'success'
                        };
                    }
                });
                vent.trigger('close:quickRegion');
            },
            error: function() {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'There was an error processing this request',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            }
        });
    }

    handleReportTypeFieldChange(items) {
        this.reportType = items[0];
    }

    handleFunnelsFieldChange(items) {
        let funnels = [];

        for (let item of items) {
            //check if funnel has integration data && integration data contains developmentReference (yourkeys GUID)
            if (item.integration_data && item.integration_data.developmentReference) {
                funnels.push(item.integration_data.developmentReference)
            }
        }

        this.funnelsSelected = funnels;
    }

    render() {
        var funnelText = TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}');

        return (
            <div className={style.reportViewer}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.handleClose.bind(this)}
                    >
                        Close
                    </div>

                    <div className={style.title}>Generate Report</div>
                    <a
                        className={style.saveButton}
                        onClick={this.handleSave.bind(this)}
                    >Request</a>
                </div>

                <div className={style.ReportBody}>
                    <div className={style.text}>
                        Select the developments to include in the progression report.
                        <br/>
                        Note it may take some time to generate.
                        <br />
                        We will notify you over email when that is completed.
                    </div>

                    <div className={style.inputField}>
                        <span
                            className={style.inputFieldLabel}
                            title="Report Type"
                        >
                            Report Type
                        </span>
                        <NewSelect
                            placeholder="Select Report Type"
                            width="312"
                            data={this.reportTypes}
                            text="name"
                            searchViewVisible={false}
                            onSelect={this.handleReportTypeFieldChange.bind(this)}
                        />
                    </div>

                    <div className={style.AppointmentBody}>
                        <div className={style.inputField}>
                            <span
                                className={style.inputFieldLabel}
                                title={funnelText}
                            >
                                {funnelText}
                            </span>
                            <TagSelect
                                placeholder={`Select ${funnelText}`}
                                width="312"
                                data={this.funnels}
                                text="name"
                                search={true}
                                onChange={this.handleFunnelsFieldChange.bind(this)}
                                options={{
                                    multiple: true
                                }}
                            />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import ModalRegion from 'js/views/base/modal-region'
import backboneSelect2 from 'js/widgets/backbone-select2'
import diilTemplate from 'templates/message_box.handlebars'


var MessageBox = Marionette.Layout.extend( {
    className: 'message-box',
    template: Handlebars.compile(diilTemplate),
    templateHelpers: function() {
        return {
            showInput: this.options.prompt && this.options.prompt.type === 'input',
            showTagSelect: this.options.prompt && this.options.prompt.type === 'tagSelect',
            contextClass: this.options.contextClass,
            showDropdown: this.options.prompt && this.options.prompt.type === 'dropdown'
        };
    },
    ui: {
        promptInput: '.prompt-input',
        promptTagSelect: '.prompt-tag-select',
        accept: '.mb-accept',
        promptDropdown: '.prompt-dropdown'
    },
    events: {
        'click .mb-accept': function( ev ) {
            ev.preventDefault();
            this.trigger( 'accept' );
        },
        'click .mb-cancel': function( ev ) {
            ev.preventDefault();
            this.trigger( 'cancel' );
        },
        'keyup .prompt-input': function(e) {
            if (this.options.prompt.conditional_accept_button_shown) {
                var val = this.ui.promptInput.val();

                if (val === this.options.prompt.conditional_accept_button_shown) {
                    this.ui.accept.show();
                } else {
                    this.ui.accept.hide();
                }

                if ((e.keyCode === 13) && (val === this.options.prompt.conditional_accept_button_shown)) {
                    this.ui.accept.trigger('click');
                }
            } else if (e.keyCode === 13) {
                this.ui.accept.trigger('click');
            }
        }
    },
    onRender: function() {
        var self = this;
        if (this.options.prompt && this.options.prompt.type === 'tagSelect') {
            if (this.options.prompt.availableTags) {
                self.promptTagSelectVal = [];

                self.promptTagSelect = new backboneSelect2.TagView({
                    view: self,
                    $el: self.ui.promptTagSelect,
                    value: self.options.prompt.value || [],
                    text: 'name',
                    data: this.options.prompt.availableTags,
                    createSearchChoice: true,
                    options: {
                        placeholder: 'Add',
                        containerCssClass: 'select2-block',
                        dropdownCssClass: 'role-select-popover popover select2-drop-message-box-tags',
                        multiple: true,
                        formatNoMatches: function () {
                            return 'Type to add';
                        },
                        tokenSeparators: [","]
                    }
                });

                self.promptTagSelect.$el.on("change", function(e) {
                    self.promptTagSelectVal = e.val;
                });
            } else {
                $.get("/users",[], function(data) {
                    self.promptTagSelectVal = [self.options.prompt.idValue] || [];

                    self.promptTagSelect = new backboneSelect2.TagView({
                        view: self,
                        $el: self.ui.promptTagSelect,
                        value: self.options.prompt.value || [],
                        text: 'name',
                        data: data,
                        createSearchChoice: true,
                        params: {
                        },
                        options: {
                            placeholder: 'Add user',
                            containerCssClass: 'select2-block',
                            dropdownCssClass: 'role-select-popover popover',
                            multiple: true,
                            formatNoMatches: function () {
                                return 'Type to add user';
                            },
                            tokenSeparators: [","]
                        }
                    });

                    self.promptTagSelect.$el.on("change", function(e) {
                        self.promptTagSelectVal = e.val;
                    });
                });
            }
        }

        if (this.options.prompt && this.options.prompt.type === 'dropdown') {
            self.promptDropdownSelectVal = [self.options.prompt.idValue] || [];

            self.promptDropdown = new backboneSelect2.SelectView({
                view: self,
                $el: self.ui.promptDropdown,
                value: self.options.prompt.value || [],
                text: 'name',
                data: this.options.prompt.options,
                createSearchChoice: true,
                options: {
                    placeholder: 'Select Option',
                    containerCssClass: 'select2-block',
                    dropdownCssClass: 'role-select-popover popover modal-dropdown',
  
                }
            });

            self.promptDropdown.$el.on("change", function(e) {
                self.promptDropdownSelectVal = e.val;
            });
        }

        if (this.options.prompt && this.options.prompt.type === 'input') {
            this.ui.promptInput.focus();
        }

        if (this.options.hideAccept) {
            this.ui.accept.hide();
        }
    },
    onShow: function() {
        // Currently the only way to add a class to the modal el of a ModalRegion...
        this.$el.parent('.modal').addClass('message-box-modal');
    },

    setContent: function( content ) {
        if ( content.icon ) {
            $( '.context-icon' ).addClass( content.icon )
                .parent().removeClass('hide');
        }

        if ( content.itemsLength ) {
            $( '.counter' ).text( content.itemsLength ).show();
        }

        $( '#message' ).html( content.message );

        if (content.initMessageActions) {
            content.initMessageActions($('#message'));
        }

        $( '#mb-accept' ).text( content.accept_button_text );

        if ( content.accept_is_negative ) {
            $( '#mb-accept' ).addClass('danger');
        } else if ( content.accept_is_positive ) {
            $( '#mb-accept' ).addClass('done');
        }

        if ( content.cancel_button_text ) {
            $( '#mb-cancel' ).text( content.cancel_button_text );
        }
        else {
            $( '#mb-cancel' ).hide();
        }

        if (this.options.prompt && this.options.prompt.type === 'input') {
            this.ui.promptInput.focus();

            if (this.options.prompt.conditional_accept_button_shown) {
                this.ui.accept.hide();
            }
        }
    }
},
{ // ... static methods ...
    showYesNo: function( content, parentView, yesCallback, noCallback, contextClass, options ) {
        var prompt = options && options.prompt;
        var regionType = (options && options.staticRegion) ?
            ModalRegion.extend({ backdrop: 'static', keyboard: false }) : ModalRegion;
        var region = parentView.addRegion( 'message_box', { selector: 'message-box', regionType: regionType }),
            mb = new MessageBox({ prompt: prompt, contextClass: contextClass }),
            callBackRunned = false;

        content.accept_button_text = content.accept_button_text || 'Yes';
        content.cancel_button_text = content.cancel_button_text || 'No';

        region.show( mb );
        mb.setContent( content );

        // ...
        parentView.listenTo( mb, 'accept', function() {
            callBackRunned = true;

            if ( yesCallback ) {
                var val;

                if (prompt) {
                    if (prompt.type === 'input') {
                        val = mb.ui.promptInput.val();
                    }
                    else if (prompt.type === 'tagSelect') {
                        val = mb.promptTagSelectVal;
                    }
                    else if (prompt.type === 'dropdown') {
                        val = mb.promptDropdownSelectVal;
                    }
                }

                region.reset();
                yesCallback(val);
            } else {
                region.reset();
            }
        } );

        parentView.listenTo( mb, 'cancel', function() {
            callBackRunned = true;
            region.reset();

            if ( noCallback ) {
                noCallback();
            }
        } );

        parentView.listenTo( region, 'hide', function() {
            // call noCallback on hide if not triggered by cancel or accept
            if (!callBackRunned && noCallback) {
                noCallback();
            }
        } );
    },
    showNoBtn: function( content, parentView, options) {
        var regionType = (options && options.staticRegion) ?
            ModalRegion.extend({ backdrop: 'static', keyboard: false }) : ModalRegion;
        var region = parentView.addRegion( 'message_box', { selector: 'message-box', regionType: regionType }),
            mb = new MessageBox({ hideAccept: true });

        region.show( mb );
        mb.setContent( content );

        return {
            messageBox: mb,
            reset: function() {
                region.reset();
            }
        };
    },
    showOk: function( content, parentView, okCallback, prompt, contextClass, staticRegion ) {
        var regionType = staticRegion ?
            ModalRegion.extend({ backdrop: 'static', keyboard: false }) : ModalRegion;
        var region = parentView.addRegion( 'message_box', { selector: 'message-box', regionType: regionType } );
        var mb     = new MessageBox({ prompt: prompt, contextClass: contextClass });

        content.accept_button_text = content.accept_button_text || 'OK';

        region.show( mb );
        mb.setContent( content );

        // ...
        parentView.listenTo( mb, 'accept', function() {
            var val;
            if (prompt) {
                if (prompt.type === 'input') {
                    val = mb.ui.promptInput.val();
                }
                else if (prompt.type === 'tagSelect'){
                    val = mb.promptTagSelectVal;
                }
            }

            region.reset();

            if ( okCallback ) {
                if (prompt) {
                    okCallback(val);
                }
                else {
                    okCallback();
                }
            }
        } );
    }
}
);

export default MessageBox;

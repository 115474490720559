import Handlebars from 'handlebars'
import _ from 'underscore'
import $ from 'jquery'

import BaseItemView from 'js/views/base/item.js'
import individualItemTemplate from 'templates/individuals/item.handlebars'


var IndividualItemView = BaseItemView.extend({
    className: 'row-is-target',
    template: Handlebars.compile(individualItemTemplate),
    ui: {
        thumbImage: '.table-list-thumb img'
    },
    templateHelpers: function () {
        var extraField,
            iconAddresses = {
                'facebook': '/img/lead_sources/lead-facebook.png',
                'twitter': '/img/lead_sources/lead-twitter.png',
                'web': '/img/lead_sources/lead-web.png'
            },
            defaultIconAddress = '/img/lead_sources/lead-default.png',
            sourceIconAddress;

        if (this.options.extraField) {
            extraField = this.model.get(this.options.extraField);
            if (_.isArray(extraField)) {
                extraField = _.map(extraField, function(el) { return el.title; }).join(', ');
            }
        }
        else {
            extraField = this.model.get('organization_name');
        }

        if (this.model.get('source')) {
            sourceIconAddress = iconAddresses[this.model.get('source').system_type] || defaultIconAddress;
        }

        return {
            primaryPhone: this.model.primaryPhone(),
            primaryEmail: this.model.primaryEmail(),
            getPhoto: this.model.getPhoto(),
            extraField: extraField,
            sourceIconAddress: sourceIconAddress,
            showCommunication: !this.options.hideCommunication
        };
    },
    initialize: function() {
        BaseItemView.prototype.initialize.apply( this, arguments );
        if (_.isNull(this.options.confirmOnClick)) {
            this.confirmOnClick = true;
        }
        else {
            this.confirmOnClick = this.options.confirmOnClick;
        }
    },
    onRender: function() {
        // if (this.ui.thumbImage.length > 0) {
        //     this.ui.thumbImage.error(function(){
        //         $(this).attr('src', 'img/default-profile-1.png');
        //     });
        // }
    },
    followLink: function(ev) {
        var row = ev.currentTarget,
        link = row.href;

        ev.stopPropagation();
        ev.preventDefault();

        this.trigger('followLink', this.model, link);
    }
});

export default IndividualItemView;

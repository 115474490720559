import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import vent from 'js/vent.js'
import CustomRegion from 'js/views/custom_region.js'
import app from 'js/app.js'
import appContentTemplate from 'templates/appcontent.handlebars'


var AppContent = Marionette.Layout.extend({
    id: 'app-content',
    template: Handlebars.compile(appContentTemplate),
    initialize: function() {
        this.on('change', function() {
            this.updateUrl();
        }, this);


        // List collapsing
        this.listenTo(vent, 'list:toggle-collapse',
            this.toggleCollapseRegion);
        // HACKY - remove .list-collapse class when collapsable region closes
        this.listenTo(vent, 'list:uncollapse',
            this.uncollapseRegion);

        // Temporary solution for expanding main list views
        this.listenTo(vent, 'list:expand', function() {
            this.pop({});
        });

        this.listenTo(vent, 'AppContent:contentChange', function() {
            this.trigger('change');
            //this.contentChange(action);
        });

        this.listenTo(this.regionManager, 'region:add', function() {
            // TODO: do this smarter
            app.appStacks.$el.removeClass(
                'regions0 regions1 regions2 regions3 regions4'
                );
            app.appStacks.$el.addClass(
                'regions' + this.regionManager.length.toString()
                );
        }, this);

        this.listenTo(this.regionManager, 'region:remove', function() {
            // Manually updating regionManager length - Marionette bug
            // marionettejs/backbone.marionette#568
            // When this bug is fixed, this 'region:remove' listener
            // can be combined with 'region:add'
            var regionCount = _.size(this.regionManager._regions);
            // TODO: do this smarter
            app.appStacks.$el.removeClass(
                'regions0 regions1 regions2 regions3 regions4'
                );
            app.appStacks.$el.addClass(
                'regions' + regionCount.toString()
                );
        }, this);
    },
    toggleCollapseRegion: function() {
        this.$el.toggleClass('list-collapse');
    },
    uncollapseRegion: function() {
        this.$el.removeClass('list-collapse');
    },
    regions: {
        // TODO: search region should be here...
    },
    contentRegions: [],
    maxRegions: 2,
    _createRegion: function (prepend) {
        var regionId = (Math.round(Math.random() * 10000)).toString(),
            regionName = 'contentRegion' + regionId,
            $regionEl = $('<section class="region"></section>'),
            options = {},
            region;
        $regionEl.attr('id', regionName);
        if (prepend) {
            this.$el.prepend($regionEl);
        } else {
            this.$el.append($regionEl);
        }
        options[regionName] = {
            regionType: CustomRegion,
            selector: '#' + regionName
        };
        region = this.addRegions(options)[regionName];
        return region;
    },
    _destroyRegion: function(region) {
        var $el = region.$el;
        // Remove region and view and unbind events etc.
        if (!$el) {
            return;
        }
        this.removeRegion($el.attr('id'));
        $el.remove(); // Remove DOM element
    },
    getCurrentUrl: function(n) {
        var lastContentRegion;
        var settings = app.appSettings.currentView;
        var usersettings = app.appUserSettings.currentView;

        n = n ? n : 0;
        if (n < this.contentRegions.length) {
            lastContentRegion = _.first(_.last(this.contentRegions, n + 1));

            if (lastContentRegion.currentView && lastContentRegion.currentView.getUrl) {
                var params = {};
                var context = this.getCurrentUrl(n + 1);
                if (context) {
                    params.c = context;
                }
                if (settings) {
                    params.s = settings.getUrl();
                }
                if (usersettings) {
                    params.u = usersettings.getUrl();
                }
                if (lastContentRegion.currentView.getParams) {
                    _.extend(params, lastContentRegion.currentView.getParams());
                }
                params = (_.isEmpty(params) ? '' : "?" + $.param(params) );

                return lastContentRegion.currentView.getUrl() + params;
            }
        }

        return '';
    },
    updateUrl: function() {
        var currentUrl = this.getCurrentUrl();
        var urlTo = decodeURIComponent(currentUrl);
        var idxTo = urlTo.indexOf('?c=');

        if (idxTo !== -1) {
            urlTo = urlTo.substr(0, idxTo);
        }

        var urlFrom = decodeURIComponent(Backbone.history.fragment);
        var idxFrom = urlFrom.indexOf('?c=');

        if (idxFrom !== -1) {
            urlFrom = urlFrom.substr(0, idxFrom);
        }

        if (urlTo !== urlFrom) {
            app.router.navigate(currentUrl);
        } else {
            app.dirtyModelHandler.userSettingsPopupStatus = 0;
            app.dirtyModelHandler.settingsPopupStatus = 0;
        }
    },
    // Routes
    push: function(options, view, args) {
        var oldRegion, newRegion = this._createRegion(),
            subOptions = _.extend({}, options, {silent: true});
            //appContent = this;
        while ((this.contentRegions.length + 1) > this.maxRegions) {
            oldRegion = this.shift(subOptions);
        }
        this.contentRegions.push(newRegion);
        newRegion.show(view, args);
        if (!options.silent) {
            this.trigger('change');
            // this.listenTo(view, 'render', function() {
            //     appContent.trigger('change');
            //     appContent.stopListening(view, 'render');
            // });
        }
        // Creates a new region
        // Adds the instantiated view's element to region
        // Pushes the region on the stack
        // Starts the view rendering process with the given 'args'
    },
    unshift: function(options, view, args) {
        var oldRegion, newRegion = this._createRegion();
            //appContent = this;
        while ((this.contentRegions.length + 1)> this.maxRegions) {
            oldRegion = this.pop();
        }
        this.contentRegions.unshift(newRegion);
        newRegion.show(view, args);
        if (!options.silent) {
            this.trigger('change');
            // this.listenTo(view, 'render', function() {
            //     appContent.trigger('change');
            //     appContent.stopListening(view, 'render');
            // });
        }
        // As .push but unshift
        // Add to the bottom of the stack rather than push
    },
    pop: function(options) {
        var err, region;
        if (!this.isEmpty()) {
            region = this.contentRegions.pop();
        } else {
            err = new Error(
                "Cannot perform .pop() - There are no regions on " +
                "the stack"
                );
            err.name = 'PopError';
            throw err;
        }
        this._destroyRegion(region);
        if (!options.silent) {
            this.trigger('change');
        }
        return region;
        // Pops the topmost region off the stack
        // If there are no regions on the stack, raises an error
    },
    shift: function(options) {
        var err, region;
        if (!this.isEmpty()) {
            region = this.contentRegions.shift();
        } else {
            err = new Error(
                "Cannot perform .shift() - There are no regions " +
                "on the stack"
                );
            err.name = 'ShiftError';
            throw err;
        }
        this._destroyRegion(region);
        if (!options.silent) {
            this.trigger('change');
        }
        return region;
        // As .pop but with the bottommost region
    },
    clear: function(options) {
        var that = this;
        _.each(this.contentRegions, function(region) {
            that._destroyRegion(region);
        });
        this.contentRegions.length = 0;
        if (!options.silent) {
            this.trigger('change');
        }
        // Empties the stack
    },
    replace: function(options, array) {
        var appContent = this,
            subOptions = _.extend({}, options, {silent: true});
            //rendered = 0;

        this.clear(subOptions); // Empty the stack

        _.each(array, function(viewConfig) {
            var view = viewConfig[0],
                renderArgs = viewConfig[1];
            // if (!options.silent) {
            //     this.trigger('change');
            //     appContent.listenTo(view, 'render', function() {
            //         rendered += 1;
            //         if (rendered === array.length) {
            //             appContent.trigger('change');
            //         }
            //         appContent.stopListening(view, 'render');
            //     });
            // }
            appContent.push(subOptions, view, renderArgs);
        });
        if (!options.silent) {
            this.trigger('change');
        }
        // Empties the stack
        // Replaces current regions with new ones created with
        // the given array of view and args
    },
    insertBefore: function(options, sourceView, newView, args) {
        var targetRegion = _.filter(this.contentRegions,
                function(region) {
                    return region.currentView === sourceView;
                }
            )[0],
            insertPosition = this.contentRegions.indexOf(targetRegion),
            newRegion = this._createRegion(true),
            err,
            subOptions = _.extend({}, options, {silent:true});
            //appContent = this;

        if (insertPosition >= this.maxRegions || insertPosition < 0) {
            err = new Error(
                "Cannot insert view at position " +
                insertPosition.toString()
                );
            err.name = 'ValueError';
            throw err;
        }

        while (this.contentRegions.length >= this.maxRegions) {
            this.shift(subOptions);
            insertPosition -= 1;
        }

        this.contentRegions.splice(insertPosition, 0, newRegion);
        newRegion.show(newView, args);
        if (!options.silent) {
            this.trigger('change');
            // this.listenTo(newView, 'render', function() {
            //     appContent.trigger('change');
            //     appContent.stopListening(newView, 'render');
            // });
        }
        // Finds the region closest to top of the stack containing
        // the first view argument
        // Creates a new region with newView and args
        // Inserts newView before first region, shifting any other
        // regions to make room on the stack, as necessary
    },
    insertAfter: function(options, sourceView, newView, args) {
        var sourceRegion = _.filter(this.contentRegions,
                function(region) {
                    return region.currentView === sourceView;
                }
            )[0],
            sourcePos = this.contentRegions.indexOf(sourceRegion),
            insertPosition = sourcePos + 1,
            newRegion,
            err,
            subOptions = _.extend({}, options, {silent: true});
            //appContent = this;

        if (insertPosition < 0) {
            err = new Error(
                "Cannot insert view at position " +
                insertPosition.toString()
                );
            err.name = 'ValueError';
            throw err;
        }

        if (insertPosition >= this.maxRegions) {
            this.push(subOptions, newView, args);
        } else {
            newRegion = this._createRegion();
            while (this.contentRegions.length >= this.maxRegions) {
                this.pop(subOptions);
            }
            this.contentRegions.splice(insertPosition, 0, newRegion);
            newRegion.show(newView, args);
        }
        if (!options.silent) {
            this.trigger('change');
            // this.listenTo(newView, 'render', function() {
            //     appContent.trigger('change');
            //     appContent.stopListening(newView, 'render');
            // });
        }
        // As .insertBefore, but regions are popped from the stack
        // as necessary
    },
    replaceView: function(options, sourceView, newView, args) {
        var targetRegion = _.filter(this.contentRegions,
                function(region) {
                    return region.currentView === sourceView;
                }
            )[0];
            //appContent = this;
        targetRegion.show(newView, args);
        if (!options.silent) {
            this.trigger('change');
            // this.listenTo(newView, 'render', function() {
            //     appContent.trigger('change');
            //     appContent.stopListening(newView, 'render');
            // });
        }
    },
    remove: function(options, view) {
        var targetRegion = _.filter(this.contentRegions,
                function(region) {
                    return region.currentView === view;
                }
            )[0];
        this.contentRegions.splice(
            this.contentRegions.indexOf(targetRegion), 1
            );
        this._destroyRegion(targetRegion);
        if (!options.silent) {
            this.trigger('change');
        }
    },
    size: function() {
        return this.contentRegions.length;
        // Returns the current stack size
    },
    isEmpty: function() {
        return this.contentRegions.length === 0;
        // Returns true if current stack size is 0
    },
    peek: function() {
        var err,
            topIndex = (
                Math.min(this.contentRegions.length,
                         this.maxRegions) - 1
                );
        if (this.isEmpty()) {
            err = new Error("The stack is currently empty");
            err.name = 'StackEmpty';
            throw err;
        } else {
            return this.contentRegions[topIndex];
        }
        // Returns the view of the top-most region on the stack
        // Raises an error if the stack is empty
    },
    // TODO ...
    renderRegions: function() {
        // Triggers render methods on any unrendered regions
        // and marks those regions as having had their render
        // methods called
        // Note: this may be intended to override/supplement
        // the built-in 'render' method - mitchell to clarify...
    }
});

export default new AppContent();

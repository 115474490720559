import $ from 'jquery'

import app from 'js/app'
import BaseView from 'js/views/base/base'
import vent from 'js/vent'


var BaseItemView = BaseView.extend({
    tagName: 'tr',
    onRender: function() {
        this.selected = false;
    },
    ui: {
        remove: '.table-list-remove',
        removeIcon: '.table-list-remove > i',
        favorite: '.table-list-favorite'
    },
    events: {
        'click': 'onClick',
        'click .communication-link.phone': 'followLink',
        'click .communication-link.email': 'followLink',
        'click .table-list-checkbox': 'clickCheckbox',
        'click .table-list-remove': 'markRemove',
        'click .table-list-favorite': 'toggleFavorite'
    },
    initialize: function( options ) {
        BaseView.prototype.initialize.apply( this, arguments );
        this.tools = options ? ( options.tools || {} ) : {};
    },
    onShow: function() {
        if ( this.tools && this.tools.hideCheckbox ) {
            this.$el.addClass('hide-checkbox');
        }

        // Initialize all tooltips
        this.$el.find('[data-toggle=tooltip]').tooltip({
            container: 'body',
            delay: {
                show: 500
            }
        });
    },
    onClick: function(ev) {
        // ignore links
        if ($(ev.target).hasClass('external-link')) {
            return;
        }
        ev.preventDefault();
        if (this.confirmOnClick) {
            app.dirtyModelHandler.confirm(this, function () {
                this.viewItem();
            });
        }
        else {
            this.viewItem();
        }
    },
    viewItem: function(edit, preview) {
        var row = this.$el,
            model = this.model,
            type = this.model.get('type');
        this.trigger('viewItem', row, model, type, edit, preview);
    },
    clickCheckbox: function(ev) {
        ev.stopPropagation();
        if (ev.shiftKey) {
            this.trigger('shift-select');
        } else {
            this.toggleSelect();
            this.trigger('set:prev-click');
        }
    },
    highlight: function() {
        if (this.selected) {
            this.$el.addClass('selected');
        } else {
            this.$el.removeClass('selected');
        }
    },
    selectItem: function() {
        this.selected = true;
        this.highlight();
    },
    deselectItem: function() {
        this.selected = false;
        this.highlight();
    },
    toggleSelect: function(options) {
        var prevState = this.selected;
        this.selected = options ? options.selecting : !this.selected;
        this.trigger('selected', prevState, this.selected);
        this.highlight();
    },
    // Override Marionette.ItemView default remove method
    remove: function(){
        if (this.selected) {
            this.$el.animate(
                {
                    height: '0',
                    opacity: '0'
                },
                400,
                function(){
                    $(this).remove();
                }
            );
        } else {
            this.$el.remove();
        }
    },
    followLink: function(ev) {
        var row = ev.currentTarget,
            link = $(row).attr('href');
        ev.preventDefault();
        ev.stopPropagation();

        window.location=link;
        return false; // Prevents error (browser crashing) ??
    },
    editItem: function(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        var model = this.model.id,
            type = this.model.get('type');
        // Publishes straight to controller - consider highlighting too?
        vent.trigger(type + ':edit', true, model);
    },
    markRemove: function(ev) {
        ev.stopPropagation(); // prevent normal 'onClick' handler firing.
        ev.preventDefault();
        this.$el.toggleClass('remove-item');
        if (this.model.get('remove')) {
            this.model.unset('remove', {silent: true});
        } else {
            this.model.set({remove: true});
        }
    },
    toggleFavorite: function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        this.ui.favorite.toggleClass('favorite');
        // Perform POST and DELETE requests as necessary
    }
});

export default BaseItemView;

import React from 'react';
import _ from 'underscore';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';
import classnames from 'classnames';

import dateFormat from 'js/utils/date-format';
import Currency from 'js/utils/currency';
import guid from 'js/utils/guid'
import app from 'js/app'
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import {TagList} from 'js/react_views/detail_view_components/tag-list';
import {
    TextField,
    CheckboxField,
    IndividualField,
    OrganizationField,
    OpportunityField,
    ImageField,
    UrlField,
    CommunicationField,
    LocationField,
    DocusignField,
    EmailTemplating,
    ChecklistsProgress,
    EntityListField,
    AppointmentField,
    CredasButton,
    TrustPilotButton,
} from 'js/react_views/detail_fields/detail_fields';

import commonStyle from 'js/react_views/common_components/common.css';
import styles from './styles.css';

const CustomFieldGroupDragHandle = SortableHandle(() => <i className={classnames({ "icon-drag": true, [styles.handle]: true })} />);

const CustomFieldGroupSortableElement = SortableElement(({data, ignoreCustomFields, cfRenderer}) =>
    <div className={classnames({[styles.SortableItem]: true, [styles.Hidden]: (data.hidden || data.hiddenInViewMode) })}><CustomFieldGroup key={data.id} data={data} ignoreCustomFields={ignoreCustomFields} cfRenderer={cfRenderer}/></div>
);

const CustomFieldGroupsList = SortableContainer(({items, ignoreCustomFields, cfRenderer}) => {
  return (
    <div className={styles.SortableContainer}>
      {items.map((value, index) => (
        <CustomFieldGroupSortableElement key={"item-" + index} index={index} data={value} ignoreCustomFields={ignoreCustomFields} cfRenderer={cfRenderer}/>
      ))}
    </div>
  );
});

class CustomFieldGroupsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const items = arrayMove(prevState.items, oldIndex, newIndex);
            items[0].updateOrder(items);
            return { items: items };
        });
    };

    render() {
        return <CustomFieldGroupsList cfRenderer={this.props.cfRenderer} items={this.state.items} ignoreCustomFields={this.props.ignoreCustomFields} onSortEnd={this.onSortEnd} useDragHandle={true} lockAxis={"y"} />;
    }
}

class CustomFieldGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.data.state !== 'expanded'
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const newState = this.state.collapsed ? "expanded" : "collapsed";
        this.props.data.update(this.props.data.id, { state: newState });
        this.setState((prevState) => {
            return { collapsed: !prevState.collapsed };
        });
    }

    render() {
        if (this.props.data.fields.length === 0) {
            return null;
        }

        let customFields = _.compact(_.map(this.props.data.fields, (item) => {
            if (this.props.ignoreCustomFields.indexOf(item.id) !== -1) {
                return null;
            }

            return this.props.cfRenderer.renderCustomField(item);
        }));

        if (customFields.length === 0) {
            return null;
        }

        const toggleClasses = classnames({
            "icon-caret-right": this.state.collapsed,
            "icon-caret-down": !this.state.collapsed,
            [styles.toggle]: true
        });

        return (
            <div className={styles.GroupContainer}>
                <div className={styles.CustomFieldGroup}>
                    <div className={styles.header}>
                        <i className={toggleClasses} onClick={this.toggle} />
                        {this.props.data.name}
                        <CustomFieldGroupDragHandle/>
                    </div>
                    {
                        !this.state.collapsed &&
                        <div className={styles.container}>
                            {customFields}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

class CustomFieldRenderer {
    renderCustomField(item) {
        if (!item.value && !item.originalValue && !_.isNumber(item.value) && !_.isBoolean(item.value)) {
            return null;
        }

        if (Array.isArray(item.value) && item.value.length === 0) {
            return null;
        }

        if (item.type === 'dropDown' && item.params && item.params.multiSelect) {
            let values = [];
            item.originalValue.split(',').map(valueId => {
                values.push(item.options.find(opt => opt.id === valueId).value || '');
            })
            return (
                <TextField
                    key={item.id}
                    label={item.label}
                    value={values.join(', ')} />
            );
        }

        if (item.type === 'date') {
            return (
                <TextField
                    key={item.id}
                    label={item.label}
                    value={dateFormat.entityInformationFormat(dateFormat.createDateIgnoringTZ(item.value))} />
            );
        }
        else if (item.type === 'checkbox') {
            if (item.value || AppConfig.getValue('custom_fields.overview.show_false_checkboxes')) {
                return <CheckboxField key={item.id} label={item.label} value={item.value} />;
            }

            return null;
        }
        else if (item.type === 'individual') {
            if (item.params && item.params.multiSelect) {
                return (
                    <EntityListField
                        key={item.id}
                        label={item.label}
                        value={item.value}
                    />
                );
            }

            return (
                <IndividualField
                    key={item.id}
                    label={item.label}
                    name={item.value}
                    itemId={item.itemId}
                    photoUrl={item.photoUrl}
                    organizationName={item.organizationName} />
            );
        }
        else if (item.type === 'organization') {
            if (item.params && item.params.multiSelect) {
                return (
                    <EntityListField
                        key={item.id}
                        label={item.label}
                        value={item.value}
                    />
                );
            }

            return (
                <OrganizationField
                    key={item.id}
                    label={item.label}
                    name={item.value}
                    itemId={item.itemId} />
            );
        }
        else if (item.type === 'opportunity') {
            if (item.params && item.params.multiSelect) {
                return (
                    <EntityListField
                        key={item.id}
                        label={item.label}
                        value={item.value}
                    />
                );
            }

            return (
                <OpportunityField
                    key={item.id}
                    label={item.label}
                    name={item.value}
                    itemId={item.itemId} />
            );
        }
        else if (item.type === 'urlImage') {
            return (
                <ImageField
                    key={item.id}
                    label={item.label}
                    url={item.value} />
            );
        }
        else if (item.type === 'url') {
            return (
                <UrlField
                    key={item.id}
                    label={item.label}
                    url={item.value}
                    params={item.params} />
            );
        }
        else if (item.type === 'paragraph') {
            return (
                <TextField key={item.id} label={item.label} isParagraph={true} value={item.value} />
            );
        }
        else if (item.type === 'list') {
            return (
                <EntityListField
                    key={item.id}
                    label={item.label}
                    value={item.value}
                />
            );
        }
        return (
            <TextField key={item.id} label={item.label} value={item.value} />
        );
    }
}

const GroupDragHandle = SortableHandle(() => <i className={`icon-drag ${commonStyle.customFieldGroupHandle}`}/>);

const GroupSortableElement = SortableElement((props) =>
    <div className={classnames({[commonStyle.customFieldGroupSortableElement]: true, [commonStyle.hidden]: props.group.hidden})}>
        <Group
            key={props.group.id}
            group={props.group}
            elementRenderer={props.elementRenderer}
            cfRenderer={props.cfRenderer}
        />
    </div>
);

const GroupsList = SortableContainer((props) => {
    return (
        <div className={commonStyle.customFieldsList}>
            {props.groups.map((group, index) => (
                <GroupSortableElement
                    key={`group_${index}`}
                    index={index}
                    group={group}
                    elementRenderer={props.elementRenderer}
                    cfRenderer={props.cfRenderer}
                />
            ))}
        </div>
    );
});

class Group extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.group.state !== 'expanded'
        };
    }

    toggle() {
        const newState = this.state.collapsed ? 'expanded' : 'collapsed';
        let preferences = app.user.get('preferences') || {};

        preferences.flexGroups = preferences.flexGroups || {};
        preferences.flexGroups[this.props.group.id] = _.extend({}, preferences.flexGroups[this.props.group.id], { state: newState });
        $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

        this.setState({
            collapsed: newState !== 'expanded'
        });
    }

    render() {
        const toggleClasses = classnames({
            'icon-caret-right': this.state.collapsed,
            'icon-caret-down': !this.state.collapsed,
            [commonStyle.customFieldGroupToggle]: true
        });

        const groupClasses = classnames({
            [commonStyle.customFieldGroup]: true,
            [commonStyle.customFieldGroupCollapsed]: this.state.collapsed
        });

        const containerClasses = classnames({
            [commonStyle.customFieldGroupContainer]: true,
            [commonStyle.customFieldGroupContainerCollapsed]: this.state.collapsed
        });

        let fields = [];

        for (const field of this.props.group.elements) {
            const el = this.props.elementRenderer(field, this.props.cfRenderer);

            if (el) {
                fields.push(el);
            }
        }

        if (fields.length === 0) {
            return null;
        }

        return (
            <div className={groupClasses}>
                <div className={commonStyle.customFieldGroupHeader}>
                    <i className={toggleClasses} onClick={this.toggle.bind(this)} />
                    {this.props.group.name}
                    <GroupDragHandle/>
                </div>
                <div className={containerClasses}>
                    {fields}
                </div>
            </div>
        )
    }
}

class GroupsSection extends React.Component {
    constructor(props) {
        super(props);

        const preferences = app.user.get('preferences') || {};
        const flexGroupsOrder = preferences.individualFlexGroupsOrder;

        let groups = [];

        // groups order
        if (flexGroupsOrder) {
            for (const groupId of flexGroupsOrder) {
                const group = props.groups.find(g => g.id === groupId);

                if (group) {
                    groups.push(group);
                }
            }

            // groups without ordering are added at the end of the array
            if (groups.length !== props.groups) {
                for (const group of props.groups) {
                    if (!groups.find(g => g.id === group.id)) {
                        groups.push(group);
                    }
                }
            }
        } else {
            groups = props.groups;
        }

        let elementsMap = {};

        for (const group of groups) {
            for (const element of group.elements) {
                if (element.type === 'customField') {
                    elementsMap[`cf.${element.cfInfo.id}`] = element;
                } else {
                    elementsMap[`system.${element.id}`] = element;
                }
            }
        }

        // groups state
        const flexGroups = preferences.flexGroups || {};

        for (let group of groups) {
            const groupInfo = flexGroups[group.id] || {};

            group.state = groupInfo.state || 'expanded';
            group.hidden = groupInfo.hidden || false;

            if (group.visible_if) {
                const element = elementsMap[group.visible_if.field_id];

                if (element) {
                    if (element.type === 'customField') {
                        if ('value_id' in element.cfInfo) {
                            group.hidden = element.cfInfo.value_id !== group.visible_if.value;
                        } else {
                            group.hidden = element.cfInfo.value !== group.visible_if.value;
                        }
                    } else {
                        group.hidden = this.props.modelData[element.id] !== group.visible_if.value;
                    }
                }
            }
        }

        this.state = {
            groups: groups
        };
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState((prevState) => {
            const groups = arrayMove(prevState.groups, oldIndex, newIndex);
            const preferences = app.user.get('preferences') || {};

            preferences.individualFlexGroupsOrder = groups.map(g => g.id)
            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

            return {
                groups: groups
            };
        });
    }

    render() {
        return (
            <section className={commonStyle.customFieldsSection}>
                <GroupsList
                    groups={this.state.groups}
                    useDragHandle={true}
                    lockAxis={"y"}
                    elementRenderer={this.props.elementRenderer}
                    cfRenderer={this.props.cfRenderer}
                    onSortEnd={this.onSortEnd.bind(this)}
                />
            </section>
        );
    }
}

class DetailContactPicture extends React.Component {
    render() {
        const imageBoxStyle = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: '50%',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        };

        const imageContainerStyle = {
            position: 'relative',
            margin: '0 auto',
            width: 100,
            height: 100
        };

        const imagePlaceholderStyle = _.extend(
            {
                border: '1px solid rgba(0, 0, 0, 0.2)',
                textAlign: 'center'
            },
            imageBoxStyle
        );

        const imagePlaceholderIconStyle = {
            lineHeight: '96px',
            fontSize: '24px',
            color: 'rgba(0, 0, 0, 0.2)'
        };

        return (

            <div style={imageContainerStyle}>
                <div style={imagePlaceholderStyle}>
                    <i className="icon-user" style={imagePlaceholderIconStyle} />
                </div>
                {this.props.imageUrl &&
                    <div style={_.extend({backgroundImage: 'url(' + this.props.imageUrl + ')'}, imageBoxStyle)} />
                }
            </div>

        );
    }
}

export default class {
    constructor(options) {
        this.options = options;
    }

    render() {
        const cfRenderer = new CustomFieldRenderer();

        return {
            headerFields: this.renderLayout(this.options.headerLayout.elements, cfRenderer),
            detailFields: this.renderLayout(this.options.detailsLayout.elements, cfRenderer)
        };
    }

    renderLayout(layout, cfRenderer) {
        const fields = layout.map(element => {
            switch(element.type) {
                case 'groups_section':
                    if (this.options.customFieldGroups) {
                        return (
                            <GroupsSection
                                key='groups-section'
                                groups={element.elements}
                                modelData={this.options.modelData}
                                customFieldGroups={this.options.customFieldGroups}
                                elementRenderer={this.renderElement.bind(this)}
                                cfRenderer={cfRenderer}
                            />
                        );
                    }

                    return null;

                case 'block':
                    return this.renderBlock(element, cfRenderer);

                default:
                    return this.renderElement(element, cfRenderer);
            }
        });

        return fields;
    }

    renderBlock(block, cfRenderer) {
        const fields = this.renderLayout(block.elements, cfRenderer);

        return (
            <div
                style={block.style}
                key={`block_${guid()}`}
            >
                {fields}
            </div>
        );
    }

    renderElement(element, cfRenderer) {
        switch(element.type) {
            case 'hspace':
                return (
                    <div
                        key={`${element.id}_${guid()}`}
                        style={{width: element.value}}
                    />
                );

            case 'vspace':
                return (
                    <div
                        key={`${element.id}_${guid()}`}
                        style={{height: element.value}}
                    />
                );

            case 'customField':
                if (element.args.indexOf('no_label') !== -1) {
                    return (
                        <div key={element.cfInfo.id}>
                            {element.cfInfo.value}
                        </div>
                    );
                }

                return cfRenderer.renderCustomField(element.cfInfo);

            case 'customFieldGroups':
                if (!this.options.customFieldGroups || this.options.customFieldGroups.length === 0) {
                    return null;
                }

                return (
                    <CustomFieldGroupsSection
                        key={element.id}
                        items={this.options.customFieldGroups}
                        ignoreCustomFields={this.options.headerLayout.outOfGroupCustomFields.concat(this.options.detailsLayout.outOfGroupCustomFields)}
                        cfRenderer={cfRenderer}
                    />
                );

            case 'communicationList': {
                const items = this.options.modelData.communication.filter(c => c.medium === element.medium);
                const enableContactEmailTemplating = element.medium === 'email' && AppConfig.getValue('enableContactEmailTemplating', false);

                return (
                    <div key={element.id}>
                        {items.map(item => {
                            let showEmailTemplatingSection = false;

                            if (enableContactEmailTemplating) {
                                showEmailTemplatingSection = true;
                            }

                            const greyOut = AppConfig.getValue(`communications.${item.medium}.grey_out`, false, this.options.modelData);

                            return (
                                <div key={item.id}>
                                    <CommunicationField
                                        medium={item.medium}
                                        name={item.name}
                                        value={item.value}
                                        comments={item.comments}
                                        href={item.href}
                                        smsActive={!this.options.modelData.unsubscribed_all_messages}
                                        greyOut={greyOut}
                                        onClick={(ev, action) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            this.options.onCommunicationClick(item.id, item.href, action);
                                        }}
                                    />
                                    {showEmailTemplatingSection &&
                                        <EmailTemplating
                                            entityName={this.options.modelData.full_name}
                                            entityId={this.options.modelData.id}
                                            value={item.value}
                                        />
                                    }
                                </div>
                            )
                        })}
                    </div>
                );
            }

            case 'locations':
                return (
                    <div key={element.id}>
                        {this.options.modelData.locations.map(item =>
                            <LocationField
                                key={item.id}
                                label={item.name}
                                location={item.address}
                                comments={item.comments}
                            />
                        )}
                    </div>
                );

            case 'source': {
                const source = this.options.modelData[element.id];

                if (!source) {
                    return null;
                }

                return (
                    <TextField
                        key={element.id}
                        label={TextManager.getText('ID_SOURCE')}
                        value={source.name}
                    />
                );
            }

            case 'social': {
                const item = this.options.modelData.communication.find(c => c.medium === 'social' && c.name === element.id);

                if (!item) {
                    return null;
                }

                return (
                    <CommunicationField
                        key={element.id}
                        medium='social'
                        name={item.name}
                        value={item.value}
                        comments={item.comments}
                        href={item.href}
                    />
                );
            }

            case 'checkbox':
                return (
                    <CheckboxField
                        key={element.id}
                        label={element.label}
                        value={element.invertValue ? !this.options.modelData[element.id] : this.options.modelData[element.id]}
                    />
                );

            case 'paragraph':
                if (!this.options.modelData[element.id]) {
                    return null;
                }

                return (
                    <TextField
                        key={element.id}
                        label={element.label}
                        isParagraph='true'
                        value={this.options.modelData[element.id]}
                    />
                );

            default:
                switch(element.id) {
                    case 'photo_url':
                        return (
                            <DetailContactPicture
                                key={element.id}
                                imageUrl={this.options.modelData[element.id]}
                            />
                        );

                    case 'organization_id':
                        if (!element.value) {
                            return null;
                        }

                        return (
                            <a
                                key={element.id}
                                href={`#organizations/${element.value.id}`}
                            >
                                {element.value.title}
                            </a>
                        );

                    case 'tags':
                        return (
                            <TagList
                                key={element.id}
                                tags={this.options.modelData[element.id]}
                            />
                        );

                    case 'funnels':
                        return (
                            <TagList
                                key={element.id}
                                tags={this.options.modelData[element.id]}
                                caseValue="funnels"
                            />
                        );

                    case 'book_appointment':
                        if ( ! AppConfig.getValue('is_pdcrm', false)) {
                            return null;
                        }

                        return (
                            <AppointmentField
                                key={element.id}
                                model={this.options.modelData}
                                onAppointmentClick={this.options.onAppointmentClick}
                            />
                        );

                    case 'checklists':
                        if (this.options.checklists.length === 0) {
                            return null;
                        }

                        return (
                            <ChecklistsProgress
                                key={element.id}
                                checklists={this.options.checklists}
                            />
                        );

                    case 'docusign':
                        if (!this.options.onShowDocusignView) {
                            return null;
                        }

                        return (
                            <DocusignField
                                key={element.id}
                                onShowDocusignView={this.options.onShowDocusignView}
                            />
                        );

                    case 'credas':
                        return (
                            <CredasButton
                                key={element.id}
                                model={this.options.modelData}
                                handleCredasAmlUpdate={this.options.handleCredasAmlUpdate}
                            />
                        );

                    case 'trust_pilot':
                        return (
                            <TrustPilotButton
                                key={element.id}
                                model={this.options.modelData}
                            />
                        );

                    case 'enquiry_count':
                        if (!this.options.modelData[element.id]) {
                            return null;
                        }
        
                        return (
                            <TextField
                                key={element.id}
                                label={element.label}
                                value={this.options.modelData[element.id]}
                            />
                        );

                    default:
                        return (
                            <div
                                key={element.id}
                                style={{textAlign: 'center'}}
                            >
                                {this.options.modelData[element.id]}
                            </div>
                        );
                }
                break
        }
    }
}
import $ from 'jquery'
import _ from 'underscore'
import React from 'react'
import ReactDOM from 'react-dom';
import vent from 'js/vent'

import ContentFileModel from 'js/models/content_file'
import dateFormat from 'js/utils/date-format'
import Utilities from 'js/utils/utilities'

import style from './preview.css'

const CONTROLS_HEIGHT = 30;


class NoPreview extends React.Component {
    render() {
        this.props.modal.addClass(style.noPreviewModal);

        return (
            <div className={style.noPreview}>
                <div className={style.name}>{this.props.file.name}</div>
                <div className={style.size}>{this.props.file.size} bytes</div>
                <div className={style.created}>Created on {dateFormat.timelineFormat(this.props.file.created)}</div>
            </div>
        );
    }
}


class ImagePreview extends React.Component {
    constructor(props) {
        super(props);

        const img = new Image;
        const self = this;

        this.props.modal.width(0);
        this.props.modal.height(0);

        img.onload = function() {
            let imageWidth = img.width;
            let imageHeight = img.height;

            const backdrop = self.props.modal.prev('.modal-backdrop');
            const backdropBB = backdrop[0].getBoundingClientRect();
            let aspectRatio = imageHeight / imageWidth;
            let dlgSize;

            const maxWidth = backdropBB.width * 0.8;
            const maxHeight = backdropBB.height * 0.8 - CONTROLS_HEIGHT;

            if (aspectRatio < 1) { // landscape
                if (maxWidth * aspectRatio <= backdropBB.height) {
                    dlgSize = {
                        width: maxWidth,
                        height: maxWidth * aspectRatio
                    };
                } else {
                    dlgSize = {
                        width: maxHeight,
                        height: maxHeight * aspectRatio
                    };
                }
            } else { // portrait
                const revAspectRatio = imageWidth / imageHeight;

                if (maxHeight * revAspectRatio <= backdropBB.width) {
                  dlgSize = {
                    width: maxHeight * revAspectRatio,
                    height: maxHeight
                  };
                } else {
                  dlgSize = {
                    width: maxWidth,
                    height: maxWidth * aspectRatio
                  };
                }
            }

            self.props.modal.width(`${dlgSize.width}px`);
            self.props.modal.height(`${dlgSize.height + CONTROLS_HEIGHT}px`);
            self.props.modal.css('margin-left', `-${dlgSize.width / 2}px`);
            self.props.modal.css('padding', '5px 15px 25px 15px');
        }

        img.src = this.props.file.url;
    }

    render() {
        return (
            <div className={style.imagePreview}>
                <img src={this.props.file.url}/>
            </div>
        );
    }
}


class ReactPreview extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        this.props.parent.$el.css('height', '100%');

        const modal = this.props.parent.$el.parent();
        let Component = null;

        const fileInfo = Utilities.getTypeIcon(this.props.file.ext);

        if (fileInfo.type === 'image-file') {
            Component = ImagePreview;
        } else {
            Component = NoPreview;
        }

        return (
            <div className={style.previewContainer}>
                <div className={style.controls}>
                    <div
                        className={style.close}
                        onClick={() => vent.trigger('file-preview:hide')}
                    >
                        Close
                    </div>
                </div>

                <Component
                    file={this.props.file}
                    modal={modal}
                />
            </div>
        );
    }
};

const Preview = Marionette.Layout.extend({
    id: 'file-preview',
    template: Handlebars.compile(''),
    onShow: function() {
        if (!_.isObject(this.options.file)) {
            const contentFile = new ContentFileModel({id: this.options.file});
            const self = this;

            contentFile.fetch({
                success: function(file) {
                    self.file = file.toJSON();
                    self.renderReact();
                }
            });
        } else {
            this.file = this.options.file;
            this.renderReact();
        }
    },
    renderReact: function() {
        ReactDOM.render(
            <ReactPreview
                file={this.file}
                parent={this}
            />,
            this.$el.get(0)
        );
    }
});

export default Preview;
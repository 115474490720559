import _ from 'underscore'

import AppConfig from 'app/app-config'
import IODListingBaseView from 'app/_components/IOD-listing/IOD-listing-base'
import ListingListView from 'app/_components/IOD-listing/_components/listing-list'
import GroupElementsCollection from 'js/collections/group_elements'
import OpportunityFilterModel from 'js/models/opportunity_filter'
import OpportunityItemView from 'js/views/opportunities/item'
import IssueItemView from 'js/views/opportunities/issue-item'
import FilterQuickOptionsDeals from 'js/views/filters/quick-options-deals'
import NoCollectionView from 'js/views/base/no_collection'
import TableBodyContainerView from 'js/views/base/table-body-container-view'


var BaseOverviewView = IODListingBaseView.prototype.OverviewView;

var OverviewView = BaseOverviewView.extend({
    templateHelpers: function() {
        return {
            id: this.parent.getElementType(),
            name: 'Deals',
            hideInfo: true
        };
    },
    tools: {
        newItem: AppConfig.getValue('deals.new_button.visible', true),
        addItem: false,
        deleteItem: true,
        selectAllPage: true,
        editColumns: true,
        selectAll: true,
        editItem: true
    },
    groupType: 'Deal',
    initialize: function(options) {
        BaseOverviewView.prototype.initialize.apply(this, arguments);

        this.filter = this.filter || new OpportunityFilterModel({id: this.filter_id});

        this.myOptions = options;

        if (!this.collection) {
            this.collection = new GroupElementsCollection(null, {elementType: 'opportunities'});
            this.collection.toBeLoaded = true;
        }
        this.quickFilter = this.quickFilter || new FilterQuickOptionsDeals();
    },
    createListView: function() {
        var tools = _.extend(_.clone(this.tools), {
            newLineSelectionMessage: true
        });

        var listItemView = OpportunityItemView;

        if (this.options && this.options.section) {
            if (this.options.section.new_item_url) {
                tools.newItem = true;
            }

            if (this.options.section.id === 'issues') {
                listItemView = IssueItemView;
            }
        }

        return new ListingListView({
            collection: this.collection,
            tools: tools,
            toolLabels: this.toolLabels,
            listViewOptions: {
                id: 'opportunities-list',
                listItemView: listItemView,
                listItemViewOptions: {
                    section: 'Opportunity',
                    url: 'deal',
                    tools: this.tools,
                    emptyView: NoCollectionView
                },
                collection: this.collection,
                fetchOnShow: true
            }
        });
    },
    createTableView: function() {
        // if there are no columns selected there should be null instead of empty array, as empty array implies
        // selecting zero columns and thus not showing any. TODO: fix app wide
        var selectedColumns = this.getSelectedColumns();
        if (selectedColumns && selectedColumns.length === 0) {
            selectedColumns = null;

        }

        return new TableBodyContainerView({
            parent: this,
            collection: this.collection,
            buckets: this.buckets,
            type: this.options.type,
            elementType: this.options.elementType,
            selectedColumns: selectedColumns,
            customFields: this.customFields,
            tableModel: this.model,
            buttons: {
                deleteButton: AppConfig.getValue('entitiesTableViewShowBulkDeleteButton'),
                editButton: true,
                mergeButton: false,
                addButton: this.model && !this.model.isSmart(),
                removeFromFavorites: this.model && this.model.isFavorites(),
                removeFromGroup: this.model && !this.model.isSmart()
            }
        });
    },
    onSelectItem: function(model) {
        this.myOptions.parent.trigger('push-view:deal:show', {model: model, section: this.section});
    },
    newItem: function() {
        // does the section have a custom url for the newItem event?
        if (this.section && this.section.new_item_url) {
            window.open(this.section.new_item_url, '_blank');
            return;
        }

        this.myOptions.parent.trigger('push-view:deal:new', {section: this.section});
    }
});

export default IODListingBaseView.extend({
    OverviewView: OverviewView,
    getElementType: function() {
        return 'opportunities';
    },
    getUrl: function() {
        return 'deals';
    }
});

import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Marionette from 'Backbone.Marionette'
import TextManager from 'app/text-manager'

import BaseImport from 'js/views/settings/import.js'
import MessageBox from 'js/views/message_box.js'


export default BaseImport.extend({
    className: 'settings-detail at-top has-sticky-nav import-contacts',
    templateHelpers: function() {
        return {
            name: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}'),
            text1: TextManager.getText('ID_APP_SETTINGS_IMPORT_INDIVIDUALS_UPDATING_POLICY'),
            text2: TextManager.parseText('${ID_INDIVIDUAL, plural, capitalize}')
        };
    },
    url: '/contacts_import',
    import_type: 'individuals',
    csv_template: 'resources/data_import_template_-_contacts.csv',
    base_fields:[
        {group: 'General', tag: 'general', children:
            [
                {text: 'First Name', tag: 'first_name'},
                {text: 'Last Name', tag: 'last_name', mandatory: true},
                {text: 'Photo Url', tag: 'photo_url'},
                {text: 'Owner email', tag: 'owner_email_address'},
                {text: TextManager.getText('ID_EMAIL_OPTED_IN'), tag: 'email_opted_in'}
            ]
        },
        {group: TextManager.parseText('${ID_ORGANIZATION, capitalize}'), tag: 'organization', children:
            [
                {text: 'Name', tag: 'name'},
                {text: TextManager.getText('ID_JOB_ROLE'), tag: 'role'} // , multiple: true } // Multiple not finished for single fields
            ]
        },
        {group: 'Phone', tag: 'phone', multiple: true, children:
            [
                {text: 'Number', tag: 'value'},
                {text: 'Label', tag:'name'}
            ]
        },
        {group: 'Email', tag: 'email_address', multiple: true, children:
            [
                {text: 'Address', tag:'value'},
                {text: 'Label', tag:'name'}
            ]
        },
        {group: 'Location', tag: 'location', multiple: true, children:
            [
                {text: 'Address', tag:'value', concatenated: true},
                {text: 'Label', tag:'name'}
            ]
        },
        {group: 'Social', tag: 'social', children:
            [
                {text: 'LinkedIn Url', tag: 'linkedin'},
                {text: 'Twitter Url',  tag: 'twitter'},
                {text: 'Facebook Url', tag: 'facebook'},
                /*{text: 'Google+ Url',  tag: 'googleplus'},*/
                {text: 'Instagram Url',  tag: 'instagram'}
            ]
        },
        {group: 'Other', tag: 'other', children:
            [
                {text: 'Lead Source', tag: 'lead_source'},
                {text: 'Group', tag: 'group'},
                {text: 'Created', tag: 'created_date'},
                {text: 'Modified', tag: 'modified_date'},
                {text: TextManager.getText('ID_MORE_INFO'), tag: 'more_info'}
            ]
        },
        {group: 'Task', tag: 'task', children:
            [
                {text: 'Subject', tag: 'subject'},
                {text: 'Content', tag: 'content'},
                {text: 'Due Date', tag: 'due_date'},
                {text: 'Assignee', tag: 'assignee'},
                {text: 'Creation Date', tag: 'created'},
                {text: 'Complete Date', tag: 'complete_date'}
            ]
        },
        {group: 'Activity', tag: 'activity', children:
            [
                {text: 'Note', tag: 'note'},
                {text: 'Creation Date', tag: 'date'},
                {text: 'Author', tag: 'author'}
            ]
        }
    ],
    showMapping: function() {
        if(typeof(this.customFields) === 'undefined' || !this.customFields.fetched) {
            return;
        }
        this.showMappingCommon();
    }
});

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react';
import ReactDOM from 'react-dom';

import FilterableCollection from 'js/utils/filterable_collection'
import Utilities from 'js/utils/utilities'
import GroupModel from 'js/models/group'
import GroupsCollection from 'js/collections/groups'
import IndividualListingBaseView from 'app/_components/IOD-listing/IOD/individual-listing/individual-listing-base'
import OrganizationListingBaseView from 'app/_components/IOD-listing/IOD/organization-listing/organization-listing-base'
import FavoriteIndividualListingView from 'app/_components/IOD-listing/IOD/individual-listing/favorite-individual-listing'
import RecentIndividualListingView from 'app/_components/IOD-listing/IOD/individual-listing/recent-individual-listing'
import RecentOrganizationListingView from 'app/_components/IOD-listing/IOD/organization-listing/recent-organization-listing'
import IODGroupListingView from 'app/_components/IOD-listing/IOD-group-listing/IOD-group-listing'
import LeadIndividualListingView from 'app/_components/IOD-listing/IOD/individual-listing/lead-individual-listing'
import RealtorsIndividualListingView from 'app/_components/IOD-listing/IOD/individual-listing/realtors-individual-listing'
import StakeholdersIndividualListingView from 'app/_components/IOD-listing/IOD/individual-listing/stakeholders-individual-listing'
import CustomFieldsCollection from 'js/collections/custom_fields'
import vent from 'js/vent'
import app from 'js/app'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import appContent from 'js/views/appcontent'
import GroupsClubbing from 'js/views/groups_clubbing'
import IndividualFilterModel from 'js/models/individual_filter'
import OrganizationFilterModel from 'js/models/organization_filter'
import MessageBox from 'js/views/message_box'
import sidebarViewTemplate from 'app/contacts/sidebar.handlebars'
import sidebarItemViewTemplate from 'app/contacts/sidebar-item.handlebars'
import staticSidebarItemViewTemplate from 'app/contacts/sidebar-static-item.handlebars'
import buildMyGroupsList from 'js/utils/build_my_groups_list.js'
import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';

var StaticSidebarItemView = Marionette.Layout.extend({
    className: 'item',
    template: Handlebars.compile(staticSidebarItemViewTemplate),
    templateHelpers: function() {
        if (this.model.get('is_adhoc')) {
            if (!this.model.get('icon')) {
                if (this.model.get('element_type') === 'stakeholders') {
                    return {
                        user_initials: this.model.get('name').split(' ').map(function (s) { return s.charAt(0); }).join('')
                    };
                } else {
                    return {
                        user_initials: app.user.get('name').split(' ').map(function (s) { return s.charAt(0); }).join(''),
                        user_photo: app.user.get('photo_url')
                    };
                }
            }
            else {
                return {
                    icon: this.model.get('icon')
                };
            }
        }

        // ...
        return {
            is_owner: (this.model.get('owner').id === app.user.get('id')),
            icon: 'icon-list'
        }
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            this.options.sidebar.showGroup(this.model);
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.$el.find('.basic-list-item').tooltip();
    }
});

var SidebarItemView = Marionette.Layout.extend({
    className: 'item',
    template: Handlebars.compile(sidebarItemViewTemplate),
    ui: {
        tagsContainer: '.tags-container',
    },
    templateHelpers: function() {
        if (this.model.get('is_adhoc')) {
            if (!this.model.get('icon')) {
                return {
                    user_initials: app.user.get('name').split(' ').map(function (s) { return s.charAt(0); }).join(''),
                    user_photo: app.user.get('photo_url')
                };
            }
            else {
                return {
                    icon: this.model.get('icon')
                };
            }
        }

        // ...
        var data = {
            is_owner: (this.model.get('owner').id === app.user.get('id')),
            icon: 'icon-list'
        }
        var systemType = this.model.get('system_type');

        if (systemType) {
            data.is_owner = false;
            data.icon = {
                'duplicates': 'icon-duplicates'
            }[systemType]
        } else {
            if (this.model.get('mailing_list') === true) {
                data.icon = 'icon-mailing-list';
            }
            else if (this.model.get('group_type') === 'smart') {
                data.icon = 'icon-smart-group';
            }
        }

        return data;
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            this.options.sidebar.showGroup(this.model);
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.parent.clubbing.initItem(this);
        this.$el.find('.basic-list-item').tooltip();

        if( !this.model.get('is_adhoc') ) {
            ReactDOM.render(
                <TagListSidebar
                    tags={this.model.get('tags')}
                />,
                this.ui.tagsContainer.get(0)
            );
        }
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
    }
});

var StaticSidebarCollectionView = Marionette.CollectionView.extend({
    template: Handlebars.compile(''),
    getItemView: function(model) {
        return StaticSidebarItemView;
    },
    itemViewOptions: function(model) {
        return {
            parent: this,
            sidebar: this.options.parent
        };
    },
    initialize: function() {
        this.filterableCollection = new FilterableCollection(this);
    },
    onRender: function() {
        this.options.parent.refreshNanoScroller();
    }
});

var SidebarCollectionView = Marionette.CollectionView.extend({
    template: Handlebars.compile(''),
    getItemView: function(model) {
        if (model && model.isFolder()) {
            return this.clubbing.SidebarFolderView;
        }

        return SidebarItemView;
    },
    itemViewOptions: function(model) {
        var options = {
            parent: this,
            sidebar: this.options.parent
        };

        if (model && model.isFolder()) {
            options.clubbing = this.clubbing;
        }

        return options;
    },
    initialize: function(options) {
        this.clubbing = this.createClubbing(options.type);

        let groups = app.user.get('preferences')[options.type + '_groups'];

        if (AppConfig.getValue(`${options.type}.groups.show_my_groups`)) {
            groups ??= options.collection.map(item => item.get('id'));
            groups = buildMyGroupsList(options.collection, groups);
        }

        this.collection = this.clubbing.manageCollection(options.collection, groups);
        this.filterableCollection = new FilterableCollection(this);
    },
    createClubbing: function(type) {
        var self = this;
        var clubbing = null;

        var onClubChange = function(render, save) {
            if (render) {
                var activeContent = null;

                self.options.parent.$el.find('.content').each(function() {
                    var c = $(this);

                    if (c.is(':visible')) {
                        activeContent = c;
                        return false;
                    }
                });

                // save and restore the scroll position after render
                var scrollPosition = activeContent.scrollTop();
                self.collection = clubbing.options.rootCollection;
                self.filterableCollection.setInitialCollection(self.collection);
                self.render();
                activeContent.scrollTop(scrollPosition);
                self.options.parent.refreshNanoScroller();
            }

            if (save) {
                app.user.updatePreference(type + '_groups', clubbing.encodeCollection());
            }
        };

        clubbing = new GroupsClubbing({
            view: this,
            itemView: SidebarItemView,
            elementType: type,
            onItemMoved: function() {
                onClubChange(true, true);
            },
            onFolderCreated: function() {
                onClubChange(true, false);
            },
            onItemInsertedToFolder: function() {
                onClubChange(true, true);
            },
            onFolderNameChanged: function() {
                onClubChange(false, true);
            },
            onFolderStateChanged: function() {
                self.options.parent.refreshNanoScroller();
                onClubChange(false, true)
            }
        });

        return clubbing;
    },
    onRender: function() {
        this.options.parent.refreshNanoScroller();
    }
});

var SidebarView = Marionette.Layout.extend({
    className: 'contacts-sidebar list-wrapper has-floating-header at-top',
    template: Handlebars.compile(sidebarViewTemplate),
    ui: {
        toggleInd: '.toggle-individual',
        toggleOrg: '.toggle-organization',
        toggleSta: '.toggle-stakeholder',
        individualList: '.individual-group-list',
        organizationList: '.organization-group-list',
        stakeholderList: '.stakeholder-group-list',
        addNewIndividualGroup: '.add-new-individual-group',
        allIndividuals: '.all-individuals',
        addNewOrganizationGroup: '.add-new-organization-group',
        allOrganizations: '.all-organizations',
        regularHeader: '.list-header-nav',
        searchHeader: '.list-header-nav-search',
        searchInput: '.search-input'
    },
    regions: {
        loadedIndList: '.loaded-individual-list',
        loadedOrgList: '.loaded-organization-list',
        loadedStaList: '.loaded-stakeholder-list',
        fixedIndFilterList: '.fixed-filter-individuals'
    },
    events: {
        'click .toggle-individual': function() {
            var arrow = this.ui.toggleInd.find('.toggle');
            if (arrow.hasClass('icon-caret-right')) {
                arrow.removeClass('icon-caret-right').addClass('icon-caret-down');
                this.ui.individualList.css('display', 'block');
                // hide other list
                this.ui.toggleOrg.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.toggleSta.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.organizationList.hide();
                this.ui.stakeholderList.hide();
            }
            else {
                arrow.removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.individualList.hide();
            }
            // update scrollbar after showing not visible item
            this.refreshNanoScroller();
        },
        'click .toggle-organization': function() {
            var arrow = this.ui.toggleOrg.find('.toggle');
            if (arrow.hasClass('icon-caret-right')) {
                arrow.removeClass('icon-caret-right').addClass('icon-caret-down');
                this.ui.organizationList.css('display', 'block');
                // hide other list
                this.ui.toggleInd.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.toggleSta.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.individualList.hide();
                this.ui.stakeholderList.hide();
            }
            else {
                arrow.removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.organizationList.hide();
            }
            // update scrollbar after showing not visible item
            this.refreshNanoScroller();
        },
        'click .toggle-stakeholder': function() {
            var arrow = this.ui.toggleSta.find('.toggle');
            if (arrow.hasClass('icon-caret-right')) {
                arrow.removeClass('icon-caret-right').addClass('icon-caret-down');
                this.ui.stakeholderList.css('display', 'block');
                // hide other list
                this.ui.toggleInd.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.toggleOrg.find('.toggle').removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.individualList.hide();
                this.ui.organizationList.hide();
            }
            else {
                arrow.removeClass('icon-caret-down').addClass('icon-caret-right');
                this.ui.stakeholderList.hide();
            }
            // update scrollbar after showing not visible item
            this.refreshNanoScroller();
        },
        'click .add-new-individual-group': function(ev) {
            ev.preventDefault();
            this.options.parent.showNewGroup('individuals');
        },
        'click .all-individuals': function(ev) {
            ev.preventDefault();
            this.options.parent.showIndividuals();
        },
        'click .add-new-organization-group': function(ev) {
            ev.preventDefault();
            this.options.parent.showNewGroup('organizations');
        },
        'click .all-organizations': function(ev) {
            ev.preventDefault();
            this.options.parent.showOrganizations();
        },
        'click .search': function() {
            this.ui.regularHeader.addClass('hidden');
            this.ui.searchHeader.removeClass('hidden');
            this.ui.searchInput.val('');
            this.ui.searchInput.focus();
        },
        'click .cancel-search': function() {
            this.ui.regularHeader.removeClass('hidden');
            this.ui.searchHeader.addClass('hidden');
            this.fixedIndFilterList.currentView.filterableCollection.resetFilter();
            this.loadedIndList.currentView.filterableCollection.resetFilter();
            this.loadedOrgList.currentView.filterableCollection.resetFilter();
            this.loadedStaList?.currentView?.filterableCollection.resetFilter();
        },
        'keydown .search-input': function(ev) {
            const self = this;

            _.defer(function() {
                var value = ev.target.value;
                self.fixedIndFilterList.currentView.filterableCollection.filter(value);
                self.loadedIndList.currentView.filterableCollection.filter(value);
                self.loadedOrgList.currentView.filterableCollection.filter(value);
                self.loadedStaList?.currentView?.filterableCollection.filter(value);
            });
        }
    },
    initialize: function() {
        this.collection = new GroupsCollection();

        this.listenTo(vent, 'group:save', this.fetchGroups);

        this.listenTo(this.options.parent, 'expand:organizationGroups', function() {
            this.ui.toggleOrg.trigger('click');
        }, this);

        this.listenTo(this.options.parent, 'expand:stakeholderGroups', function() {
            this.ui.toggleSta.trigger('click');
        }, this);

        // this is required for highlighting loaded groups
        this.listenTo(this.collection, 'sync', function() {
            if (this.selected) {
                this.highlightItem.apply(this, this.selected);
            }
        });
    },
    onRender: function() {
        this.fetchGroups();

        this.listenTo(this.options.parent, 'select', this.highlightItem);
        this.listenTo(this.options.parent, 'group:delete', this.fetchGroups);

        this.$el.find('[data-toggle="tooltip"]').tooltip();

        this.$el.find('.all-individuals a').tooltip();
        this.$el.find('.all-organizations a').tooltip();
    },
    fetchGroups: function(groupModelToSelect) {
        var self = this;

        this.collection.fetch({
            rows: -1,
            data: {
                element_type:'individuals,organizations'
            },
            success: function (data) {
                if (!self.loadedIndList || !self.loadedOrgList || !self.fixedIndFilterList) {
                    return;
                }

                var individualsCollection = new GroupsCollection();
                var organizationsCollection = new GroupsCollection();
                var fixedIndividualsCollection = new GroupsCollection();

                // add fixed groups
                if (AppConfig.getValue('hasIndividualSectionGroupFixed')) {
                    self.addAdHocGroup(fixedIndividualsCollection, 'my-realtors', 'My Realtors', null, 'individuals');
                    self.addAdHocGroup(fixedIndividualsCollection, 'my-individuals', TextManager.getText('ID_MY_INDIVIDUALS'), null, 'individuals');
                } else {
                    if (AppConfig.getValue('individuals.groups.my_individuals.visible', true)) {
                        self.addAdHocGroup(individualsCollection, 'my-individuals', TextManager.getText('ID_MY_INDIVIDUALS'), null, 'individuals');
                    }
                }
                
                // add ad hoc groups
                if (AppConfig.getValue('individuals.groups.recently_viewed.visible', true)) {
                    self.addAdHocGroup(individualsCollection, 'recent-viewed-individuals', 'Recently Viewed', 'icon-history', 'individuals');
                }

                if (AppConfig.getValue('individuals.groups.recently_added.visible', true)) {
                    self.addAdHocGroup(individualsCollection, 'recent-added-individuals', 'Recently Added', 'icon-recently-added', 'individuals');
                }

                if (AppConfig.getValue('individuals.groups.favorites.visible', true)) {
                    self.addAdHocGroup(individualsCollection, 'favorites', 'Favorites', 'icon-star', 'individuals');
                }

                if (AppConfig.getValue('individuals.groups.leads.visible', true)) {
                    self.addAdHocGroup(individualsCollection, 'lead-individuals', 'Leads', 'icon-droplets', 'individuals');
                }

                if (AppConfig.getValue('individuals.groups.show_funnels_tags_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    const tags = app.globalData.tags;
                    let validTags = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                        if (tag) {
                            validTags.push(tag);
                        }
                    }

                    for (const vt of validTags) {
                        self.addAdHocGroup(individualsCollection, vt.id, vt.name, 'icon-list-dev', 'individualFunnelTag');
                    }
                }

                if (AppConfig.getValue('individuals.groups.show_funnels_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    let validFunnels = []
                    
                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }
                        validFunnels.push(funnel)
                    }

                    for (const vf of validFunnels) {
                        self.addAdHocGroup(individualsCollection, vf.id, vf.name, 'icon-list-dev', 'individualFunnel');
                    }
                }

                if (AppConfig.getValue('organizations.groups.recently_viewed.visible', true)) {
                    self.addAdHocGroup(organizationsCollection, 'recent-viewed-organizations', 'Recently Viewed', 'icon-history', 'organizations');
                }

                if (AppConfig.getValue('organizations.groups.recently_added.visible', true)) {
                    self.addAdHocGroup(organizationsCollection, 'recent-added-organizations', 'Recently Added', 'icon-recently-added', 'organizations');
                }

                if (AppConfig.getValue('organizations.groups.my_organizations.visible', true)) {
                    self.addAdHocGroup(organizationsCollection, 'my-organizations', TextManager.getText('ID_MY_ORGANIZATIONS'), null, 'organizations');
                }

                if (AppConfig.getValue('organizations.groups.show_funnels_tags_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    const tags = app.globalData.tags;
                    let validTags = [];

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        const tag = tags.find(t => t.name.toLowerCase() === funnel.name.toLowerCase());

                        if (tag) {
                            validTags.push(tag);
                        }
                    }

                    for (const vt of validTags) {
                        self.addAdHocGroup(organizationsCollection, vt.id, vt.name, 'icon-list-dev', 'organizationFunnelTag');
                    }
                }

                if (AppConfig.getValue('organizations.groups.show_funnels_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;
                    let validFunnels = []
                    
                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }
                        validFunnels.push(funnel)
                    }

                    for (const vf of validFunnels) {
                        self.addAdHocGroup(organizationsCollection, vf.id, vf.name, 'icon-list-dev', 'organizationFunnel');
                    }
                }

                // ...
                const usingNewCampaignsSection = _.contains(app.user.get('preferences').lab_flags, 'SAL-5741');

                _.each(data.models, function(m) {
                    if (m.get('system_type') === 'duplicates' && AppConfig.getValue('disableDuplicatesGroup')) {
                        return;
                    }

                    if (m.get('element_type') === 'individuals') {
                        if ((m.get('system_type') !== 'duplicates') || !AppConfig.getValue('disableDuplicatesGroup')) {
                            if (!usingNewCampaignsSection || !m.get('mailing_list')) {
                                individualsCollection.add(m, {silent: true});
                            }
                        }
                    }
                    else if (m.get('element_type') === 'organizations') {
                        organizationsCollection.add(m, {silent: true});
                    }
                });

                var fixedIndFilterView = new StaticSidebarCollectionView({
                    collection: fixedIndividualsCollection,
                    type: 'individuals',
                    parent: self
                });

                var individualsView = new SidebarCollectionView({
                    collection: individualsCollection,
                    type: 'individuals',
                    parent: self
                });

                var organizationsView = new SidebarCollectionView({
                    collection: organizationsCollection,
                    type: 'organizations',
                    parent: self
                });

                self.fixedIndFilterList.show(fixedIndFilterView);
                self.loadedIndList.show(individualsView);
                self.loadedOrgList.show(organizationsView);

                if (AppConfig.getValue('contacts.stakeholders.visible')) {
                    var contactTypeCF = null;
                    var contactTypeCfId = null;
                    var cfs = app.globalData.customFieldsInfo.individuals;

                    for (const cfId in cfs) {
                        if (cfs[cfId].name.toLowerCase() === 'contact type') {
                            contactTypeCF = cfs[cfId];
                            contactTypeCfId = cfId;
                            break;
                        }
                    }

                    if (contactTypeCF) {
                        var stakeholdersCollection = new GroupsCollection();

                        for (const optionId in contactTypeCF.options) {
                            var optionValue = contactTypeCF.options[optionId];

                            if (['prospect', 'purchaser'].indexOf(optionValue.toLowerCase()) === -1) {
                                self.addAdHocGroup(stakeholdersCollection, optionId, optionValue, null, 'stakeholders', {customFieldId: contactTypeCfId});
                            }
                        }

                        var stakeholdersView = new StaticSidebarCollectionView({
                            collection: stakeholdersCollection,
                            type: 'stakeholders',
                            parent: self
                        });

                        self.loadedStaList.show(stakeholdersView);
                    }
                }

                // ...
                if (groupModelToSelect) {
                    var gid = groupModelToSelect.get('id');
                    var get = groupModelToSelect.get('element_type');

                    self.highlightItem('groups', gid, get);
                    self.options.parent.rememberGroup(get + 'Group', null, gid);
                }
            }
        });
    },
    addAdHocGroup: function(collection, id, name, icon, type, extraOptions) {
        extraOptions = extraOptions || {};
        collection.add(new GroupModel(_.extend({
            id: id,
            name: name,
            icon: icon,
            element_type: type,
            is_adhoc: true
        }, extraOptions)), {at: 0, silent: true});
    },
    onDomRefresh: function() {
        // show scrollbar initially
        this.refreshNanoScroller();
    },
    refreshNanoScroller: function() {
        this.$el.find('.content-container').nanoScroller();
    },
    showGroup: function(model) {
        this.options.parent.showGroup(model);
    },
    highlightItem: function(type, id, individualType) {
        this.selected = arguments;
        var activeEl,
            eventElementMap = {
                'individuals': this.ui.allIndividuals,
                'organizations': this.ui.allOrganizations
            };
        if (type === 'groups') {
            if (id) {
                activeEl = this.$el.find(".group-list div[id='" + id + "']");
            }
            else {
                if (individualType === 'individuals') {
                    activeEl = this.ui.addNewIndividualGroup;
                }
                else {
                    activeEl = this.ui.addNewOrganizationGroup;
                }
            }
        }
        else {
            activeEl = eventElementMap[type];
        }

        activeEl.addClass('active');
        this.$el.find('.item.active').not(activeEl).removeClass('active');

        if (this.fixedIndFilterList.currentView) {
            this.fixedIndFilterList.currentView.filterableCollection.findActiveModel();
            this.loadedIndList.currentView.filterableCollection.findActiveModel();
            this.loadedOrgList.currentView.filterableCollection.findActiveModel();
            this.loadedStaList?.currentView?.filterableCollection.findActiveModel();
        }
    }
});

// TODO: Apply 'details-visible' class to this view, and remove after detail view is closed
export default Marionette.Layout.extend({
    className: 'contacts-section',
    template: Handlebars.compile(['<article id="contacts-sidebar" class="sidebar"></article>',
        '<article id="contacts-view-pane"></article>'].join('')),
    regions: {
        'sidebarRegion': '#contacts-sidebar',
        'contentRegion': '#contacts-view-pane'
    },
    events: {
        'click .sidebar .collapse': function(ev) {
            var self = this;

            ev.preventDefault();

            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            this.$el.one(
                'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                function() {
                    _.defer(function() {
                        vent.trigger('sidebar:collapse');
                    });
                }
            );

            this.sidebarRegion.$el.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');
                self.$el.one(
                    'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
                    function() {
                        _.defer(function() {
                            vent.trigger('sidebar:collapse');
                        });
                    }
                );
            });
        }
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['contacts-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.sidebarRegion.$el?.width(sidebarWidth);
                self.contentRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    onRender: function() {
        this.sidebarRegion.show(new SidebarView({
            parent: this
        }));

        var type = this.type,
            id = this.id,
            options = this.options,
            initialGroup = app.user.get('preferences').initial_group;

        // if we come from direct URL
        if (type === 'individuals') {
            this.showIndividuals(options);
        }
        else if (type === 'myIndividuals') {
            options.owner_id = app.user.get('id');
            this.showIndividuals(options);
        }
        else if (type === 'my-realtors') {
            options.owner_id = app.user.get('id');
            this.showRealtors(options);
        }
        else if (type === 'leadIndividuals') {
            this.showLeadIndividuals(options);
        }
        else if (type === 'organizations') {
            this.showOrganizations(options);
            this.trigger('expand:organizationGroups');
        }
        else if (type === 'myOrganizations') {
            options.owner_id = app.user.get('id');
            this.showOrganizations(options);
            this.trigger('expand:organizationGroups');
        }
        else if (type === 'favorites') {
            this.showFavorites(options);
        }
        else if (type === 'recentIndividuals') {
            this.showRecentIndividuals(options);
        }
        else if (type === 'recentOrganizations') {
            this.showRecentOrganizations(options);
            this.trigger('expand:organizationGroups');
        }
        else if (type === 'recentCreatedIndividuals') {
            this.showRecentCreatedIndividuals(options);
        }
        else if (type === 'recentCreatedOrganizations') {
            this.showRecentCreatedOrganizations(options);
            this.trigger('expand:organizationGroups');
        }
        else if (type === 'groups') {
            this.showGroup(new GroupModel({ id: id, element_type: options.element_type }), options);

            if (options.element_type === 'organizations') {
                this.trigger('expand:organizationGroups');
            } else if (options.element_type === 'stakeholders') {
                this.trigger('expand:stakeholderGroups');
            }
        }
        else if (type === 'new') {
            this.showNewGroup(options.element_type);

            if (options.element_type === 'organizations') {
                this.trigger('expand:organizationGroups');
            }
        }
        // remembered group
        else if (initialGroup) {
            if (initialGroup.type === 'individuals') {
                this.showIndividuals(initialGroup.options);
            }
            else if (initialGroup.type === 'my-realtors') {
                this.showRealtors(initialGroup.options);
            }
            else if (initialGroup.type === 'leadIndividuals') {
                this.showLeadIndividuals(initialGroup.options);
            }
            else if (initialGroup.type === 'organizations') {
                this.showOrganizations(initialGroup.options);
                this.trigger('expand:organizationGroups');
            }
            else if (initialGroup.type === 'favorites') {
                this.showFavorites(initialGroup.options);
            }
            else if (initialGroup.type === 'recentIndividuals') {
                this.showRecentIndividuals(initialGroup.options);
            }
            else if (initialGroup.type === 'recentOrganizations') {
                this.showRecentOrganizations(initialGroup.options);
                this.trigger('expand:organizationGroups');
            }
            else if (initialGroup.type === 'recentCreatedIndividuals') {
                this.showRecentCreatedIndividuals(initialGroup.options);
            }
            else if (initialGroup.type === 'recentCreatedOrganizations') {
                this.showRecentCreatedOrganizations(initialGroup.options);
                this.trigger('expand:organizationGroups');
            }
            else if (initialGroup.type === 'individualsGroup') {
                this.showGroup(new GroupModel({id: initialGroup.id, element_type: 'individuals'}), initialGroup.options);
            }
            else if (initialGroup.type === 'organizationsGroup') {
                this.showGroup(new GroupModel({id: initialGroup.id, element_type: 'organizations'}), initialGroup.options);
                this.trigger('expand:organizationGroups');
            }
            else if (initialGroup.type === 'stakeholders') {
                this.showGroup(new GroupModel({id: initialGroup.id, element_type: 'stakeholders'}), initialGroup.options);
                this.trigger('expand:stakeholderGroups');
            }
        }
        else {
            if (AppConfig.getValue('selectMyIndividualsGroupAsDefaultOnContactsSection')) {
                options.owner_id = app.user.get('id');
            }
            this.showIndividuals(options);
        }

        this.$el.find('#contacts-view-pane').on(
            'webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
            function() {
                vent.trigger('contacts:transition:end');
            }
        );
    },
    showIndividuals: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.elementType = 'individuals';
            options.type = options.type || 'individuals';

            this.showContent(new IndividualListingBaseView(options));
            if (options && options.owner_id) {
                this.trigger('select', 'groups', 'my-individuals', 'individuals');
            }
            else {
                this.trigger('select', 'individuals');
            }
            this.rememberGroup('individuals', options);
        });
    },
    showIndividualFunnelTagGroup: function(model, options) {
        const tag = app.globalData.tags.find(t => t.id === model.get('id'));
        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.name.toLowerCase() === tag.name.toLowerCase());

        options = options || {};

        options.elementType = 'individuals';
        options.type = 'individuals';
        options.tag_ids = tag.id;

        if (funnel && funnel.location) {
            options.demographicMap = {
                tagId: tag.id,
                staticPins: {
                    [funnel.name]: {
                        address: funnel.location.address
                    }
                }
            };
        }

        this.showContent(new IndividualListingBaseView(options));
        this.trigger('select', 'groups', model.get('id'), 'individuals');
        this.rememberGroup('individuals', options);
    },
    showIndividualFunnelGroup: function(model, options) {
        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === model.get('id'));
        options = options || {};

        options.elementType = 'individuals';
        options.type = 'individuals';
        options.funnel_ids = funnel.id;

        if (funnel && funnel.location) {
            options.demographicMap = {
                funnelId: funnel.id,
                staticPins: {
                    [funnel.name]: {
                        address: funnel.location.address
                    }
                }
            };
        }
        this.showContent(new IndividualListingBaseView(options));
        this.trigger('select', 'groups', model.get('id'), 'individuals');
        this.rememberGroup('individuals', options);
    },
    showOrganizationFunnelTagGroup: function(model, options) {
        const tag = app.globalData.tags.find(t => t.id === model.get('id'));
        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.name.toLowerCase() === tag.name.toLowerCase());

        options = options || {};

        options.elementType = 'organizations';
        options.type = 'organizations';
        options.tag_ids = tag.id;

        if (funnel && funnel.location) {
            options.demographicMap = {
                tagId: tag.id,
                staticPins: {
                    [funnel.name]: {
                        address: funnel.location.address
                    }
                }
            };
        }

        this.showContent(new OrganizationListingBaseView(options));
        this.trigger('select', 'groups', model.get('id'), 'organizations');
        this.rememberGroup('organizations', options);
    },
    showOrganizationFunnelGroup: function(model, options) {
        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === model.get('id'));

        options = options || {};

        options.elementType = 'organizations';
        options.type = 'organizations';
        options.funnel_ids = funnel.id;

        if (funnel && funnel.location) {
            options.demographicMap = {
                funnelId: funnel.id,
                staticPins: {
                    [funnel.name]: {
                        address: funnel.location.address
                    }
                }
            };
        }

        this.showContent(new OrganizationListingBaseView(options));
        this.trigger('select', 'groups', model.get('id'), 'organizations');
        this.rememberGroup('organizations', options);
    },
    showStakeholdersGroup: function(model, options) {
        var self = this;

        app.dirtyModelHandler.confirm(this, function() {
            var showGroup = function(filterId) {
                var name = model.get('name');

                if (!name) { // the group has been loaded from a url address
                    var cfs = app.globalData.customFieldsInfo.individuals;

                    for (const cfId in cfs) {
                        var cf = cfs[cfId];

                        if (cf.name.toLowerCase() === 'contact type') {
                            name = cf.options[model.get('id')];
                            break;
                        }
                    }
                }

                var options = self.options || {};

                options.elementType = 'individuals';
                options.name = name,
                options.groupId = model.get('id');
                options.filter_id = filterId;

                self.showContent(new StakeholdersIndividualListingView(options));
                self.trigger('select', 'groups', model.get('id'), 'stakeholders');
                self.rememberGroup('stakeholders', options, model.get('id'));
            }

            if (options && options.filter_id) {
                showGroup(options.filter_id);
            } else {
                var rules = [
                    [{custom: model.get('customFieldId'), field: 'individual_custom', operator: 'equal', values: model.get('id')}]
                ];

                var filter = new IndividualFilterModel();

                filter.save({
                    rules:rules
                }, {
                    alert: false,
                    success: function(data) {
                        showGroup(data.id);
                    }
                });
            }
        });
    },
    showRealtors: function(options) {
        var self = this;
        app.dirtyModelHandler.confirm(this, function() {
            var showRealtors = function(rules) {
                // we create new filter every time we open lead list, repeated filters will be refactored later app wide
                rules.push([{field: "individual_owned_by_me"}])
                var filter = new IndividualFilterModel();
                filter.save({rules:rules},
                    {
                        alert: false,
                        success: function(data) {
                            var options = self.options || {};
                            options.elementType = 'individuals';
                            options.type = 'my-realtors';
                            options.filter_id = data.id;

                            self.showContent(new RealtorsIndividualListingView(options));
                            self.trigger('select', 'groups', 'my-realtors', 'individuals');
                            self.rememberGroup('my-realtors', options);
                        }
                    }
                );
            }

            var defaultRules = [];
            var realtorDefinition = AppConfig.getValue('realtorDefinition');

            if (realtorDefinition) {
                var customFields = new CustomFieldsCollection();

                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'individuals'
                    }],
                    success: function(data){
                        var rules = defaultRules;
                        var cf = _.find(data.models, function(item) { return item.get('name') === realtorDefinition[0] });

                        if (cf) {
                            var option = _.find(cf.get('options') || [], function(option) { return option.value === realtorDefinition[1] });

                            if (option) {
                                rules = [[{ custom: cf.get('id'), field: 'individual_custom', operator: 'equal', values: option.id }]];
                            }
                        }

                        showRealtors(rules);
                    },
                    error: function() {
                        showRealtors(defaultRules);
                    }
                });
            }
            else {
                showRealtors(defaultRules)
            }
        });
    },
    showLeadIndividuals: function(options) {
        var self = this;
        app.dirtyModelHandler.confirm(this, function() {
            var showLeads = function(rules) {
                // we create new filter every time we open lead list, repeated filters will be refactored later app wide
                var filter = new IndividualFilterModel();
                filter.save({rules:rules},
                    {
                        alert: false,
                        success: function(data) {
                            var options = options || {};
                            options.elementType = 'individuals';
                            options.type = 'leadIndividuals';
                            options.filter_id = data.id;

                            self.showContent(new LeadIndividualListingView(options));
                            self.trigger('select', 'groups', 'lead-individuals', 'individuals');
                            self.rememberGroup('leadIndividuals', options);
                        }
                    }
                );
            }

            var defaultRules = [[{ field: 'individual_source', operator: 'empty', not: true }]];
            var leadDefinition = AppConfig.getValue('leadDefinition');
            var rules = AppConfig.getValue('socialMediaLeadsRules', defaultRules);

            if (leadDefinition) {
                var customFields = new CustomFieldsCollection();

                customFields.fetch({
                    rows: -1,
                    filterBy: [{
                        attribute: 'view',
                        value: 'individuals'
                    }],
                    success: function(data){
                        var cf = _.find(data.models, function(item) { return item.get('name') === leadDefinition[0] });

                        if (cf) {
                            var option = _.find(cf.get('options') || [], function(option) { return option.value === leadDefinition[1] });

                            if (option) {
                                rules = [[{ custom: cf.get('id'), field: 'individual_custom', operator: 'equal', values: option.id }]];
                            }
                        }

                        showLeads(rules);
                    },
                    error: function() {
                        showLeads(defaultRules);
                    }
                });
            }
            else {
                showLeads(rules)
            }
        });
    },
    showOrganizations: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.elementType = 'organizations';
            options.type = options.type || 'organizations';

            this.showContent(new OrganizationListingBaseView(options));
            if (options && options.owner_id) {
                this.trigger('select', 'groups', 'my-organizations', 'organizations');
            }
            else {
                this.trigger('select', 'organizations');
            }
            this.rememberGroup('organizations', options);
        });
    },
    showFavorites: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.elementType = 'individuals';
            options.type = 'favorites';

            this.showContent(new FavoriteIndividualListingView(options));
            this.trigger('select', 'groups', 'favorites', 'individuals');
            this.rememberGroup('favorites', options);
        });
    },
    showRecentIndividuals: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.elementType = 'individuals';
            options.type = 'recentIndividuals';

            this.showContent(new RecentIndividualListingView(options));
            this.trigger('select', 'groups', 'recent-viewed-individuals', 'individuals');
            this.rememberGroup('recentIndividuals', options);
        });
    },
    showRecentOrganizations: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.elementType = 'organizations';
            options.type = 'recentOrganizations';

            this.showContent(new RecentOrganizationListingView(options));
            this.trigger('select', 'groups', 'recent-viewed-organizations', 'organizations');
            this.rememberGroup('recentOrganizations', options);
        });
    },
    showRecentCreatedIndividuals: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.byCreateDate = true;
            options.elementType = 'individuals';
            options.type = 'recentCreatedIndividuals';

            this.showContent(new RecentIndividualListingView(options));
            this.trigger('select', 'groups', 'recent-added-individuals', 'individuals');
            this.rememberGroup('recentCreatedIndividuals', options);
        });
    },
    showRecentCreatedOrganizations: function(options) {
        app.dirtyModelHandler.confirm(this, function() {
            options = options || {};
            options.byCreateDate = true;
            options.elementType = 'organizations';
            options.type = 'recentCreatedOrganizations';

            this.showContent(new RecentOrganizationListingView(options));
            this.trigger('select', 'groups', 'recent-added-organizations', 'organizations');
            this.rememberGroup('recentCreatedOrganizations', options);
        });
    },
    showNewGroup: function(preset_element_type) {
        this.selectedBeforeNewGroup = this.sidebarRegion.currentView.selected;

        this.showGroup(
            new GroupModel({ element_type: preset_element_type }),
            { editing: true, preset_element_type: preset_element_type }
        );
    },
    showGroup: function(model, options) {
        if (model.get('element_type') === 'stakeholders') {
            this.showStakeholdersGroup(model, options);
            return;
        }

        if (model.get('element_type') === 'individualFunnelTag') {
            this.showIndividualFunnelTagGroup(model, options);
            return;
        }

        if (model.get('element_type') === 'organizationFunnelTag') {
            this.showOrganizationFunnelTagGroup(model, options);
            return;
        }

        if (model.get('element_type') === 'individualFunnel') {
            this.showIndividualFunnelGroup(model, options);
            return;
        }

        if (model.get('element_type') === 'organizationFunnel') {
            this.showOrganizationFunnelGroup(model, options);
            return;
        }

        var view = this,
            showListingView = function() {
                view.showContent(new IODGroupListingView(_.defaults({
                    model: model,
                    parent: view
                }, options)));
                view.trigger('select', 'groups', model.get('id'), model.get('element_type'));
            };

        switch(model.get('id')) {
            case 'individuals':
                this.showIndividuals();
                break;

            case 'organizations':
                this.showOrganizations();
                break;

            case 'recent-viewed-individuals':
                this.showRecentIndividuals();
                break;

            case 'recent-added-individuals':
                this.showRecentCreatedIndividuals();
                break;

            case 'favorites':
                this.showFavorites();
                break;

            case 'my-individuals':
                this.showIndividuals({owner_id: app.user.get('id'), type: 'myIndividuals'});
                break;
            case 'my-realtors':
                this.showRealtors({owner_id: app.user.get('id'), type: 'my-realtors'});
                break;
            case 'lead-individuals':
                this.showLeadIndividuals();
                break;

            case 'my-organizations':
                this.showOrganizations({owner_id: app.user.get('id'), type: 'myOrganizations'});
                break;

            case 'recent-added-organizations':
                this.showRecentCreatedOrganizations();
                break;

            case 'recent-viewed-organizations':
                this.showRecentOrganizations();
                break;

            default:
                app.dirtyModelHandler.confirm(this, function() {
                    if (model.get('id')) {
                        model.fetch({
                            success: function() {
                                showListingView();
                                if (model.get('element_type') === 'individuals') {
                                    view.rememberGroup('individualsGroup', options, model.get('id'));
                                }
                                else {
                                    view.rememberGroup('organizationsGroup', options, model.get('id'));
                                }
                            },
                            error: function(_, response) {
                                if (model.get('element_type') === 'individuals') {
                                    view.showIndividuals(options);
                                }
                                else if (model.get('element_type') === 'organizations') {
                                    view.showOrganizations(options);
                                }

                                if (response && response.status === 403) { // do not have permissions
                                    MessageBox.showOk({
                                        icon: 'icon-warning',
                                        message: 'You do not have permissions to view this group'
                                    }, view);
                                }
                            }
                        });
                    }
                    else {
                        showListingView();
                    }
                });
                break;
        }
    },
    showContent: function(view) {
        if (appContent.contentRegions.length > 1 && this.contentRegion.currentView) {
            appContent.pop({});
        }

        this.listenTo(view, 'push-view:deal:new', function(model) {
            this.trigger('push-view:deal:new', model);
        });
        this.listenTo(view, 'push-view:deal:show', function(model) {
            this.trigger('push-view:deal:show', model);
        });

        this.listenTo(view, 'push-view:individual:new', function(model) {
            this.trigger('push-view:individual:new', model);
        });
        this.listenTo(view, 'push-view:individual:show', function(model) {
            this.trigger('push-view:individual:show', model);
        });

        this.listenTo(view, 'push-view:organization:new', function(model) {
            this.trigger('push-view:organization:new', model);
        });
        this.listenTo(view, 'push-view:organization:show', function(model) {
            this.trigger('push-view:organization:show', model);
        });

        this.listenTo(view, 'replace-view:group:show', function(model) {
            if(model.get('element_type') !== 'individuals' || model.get('element_type') !== 'organizations'){
                this.trigger('set-view:group:show', model);
            }else{
                this.showGroup(model);
            }
        });

        this.listenTo(view, 'group:delete', function(element_type) {
            if (element_type === "individuals") {
                this.showIndividuals();
            }
            else if (element_type === "organizations") {
                this.showOrganizations();
            }

            var self = this;
            _.defer(function() {
                self.trigger('group:delete');
            });
        });

        this.listenTo(view, 'show close', function() {
            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        });

        this.listenTo(view, 'edit:cancel', function(model) {
            if (model.isNew()) {
                var et = model.get('element_type');
                var doDefault = true;

                if (this.selectedBeforeNewGroup) {
                    var id = this.selectedBeforeNewGroup[1];
                    var type = this.selectedBeforeNewGroup[2];

                    if (id && (et === type)) {
                        this.showGroup(new GroupModel({ id: id, element_type: type }));
                        doDefault = false;
                    }

                    this.selectedBeforeNewGroup = null;
                }

                if (doDefault) {
                    if (et === 'individuals') {
                        this.showIndividuals();
                    }
                    else {
                        this.showOrganizations();
                    }
                }
            }
        });

        this.contentRegionView = view;

        _.defer(function() {
            if (this.contentRegion) {
                this.contentRegion.show(view);
            }

            this.initSidebarAndContentWidth();
        }.bind(this));
    },
    closeItem: function() {
        this.contentRegion.reset();
    },
    /**
     * store last visited individual group in DB
     *
     * @param type      string      group type
     * @param options   object      options
     * @param id        uuid        group id, if applicable
     */
    rememberGroup: function(type, options, id) {
        // sp for skip persistence
        if (!(options && 'sp' in options)) {
            app.user.updatePreference('initial_group', {
                type: type,
                options: options,
                id: id
            });
        }
    },
    getUrl: function() {
        var url = ['contacts'];

        var view = this.contentRegionView;
        if (view && !view.isClosed && view.getUrl) {
            url.push(view.getUrl());
        }
        return url.join('/');
    },
    getParams: function() {
        var view = this.contentRegionView;
        if (view && !view.isClosed && view.getParams) {
            return view.getParams();
        } else {
            return {};
        }
    }
});

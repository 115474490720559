import _ from 'underscore'
import Backbone from 'backbone'

import app from 'js/app'
import PaginatedListView from 'js/views/base/paginated_list'
import LeadItemView from 'app/_components/lead-item/lead-item'
import LeadsCollection from 'js/collections/leads'
import ActivitiesCollection from 'js/collections/activity'
import ListNavbarView from 'js/views/base/list_navbar'
import BaseToolbarView from 'js/views/base/toolbar'
import ActivityFilterView from 'js/views/activity/filter'
import TasksView from 'app/tasks/tasks'
import ActivityCreator from 'js/utils/activity_creator'
import vent from 'js/vent'


var NavView;

function createActivityListView(options) {
    return new PaginatedListView(_.extend({
        id: 'activity-list',
        listGetItemView: function(model){
            return ActivityCreator.getActivityItem(model);
        },
        listItemViewOptions: {
            section: 'Activities',
            url: 'activities'
        },
        collection: new ActivitiesCollection(),
        fetchOptions: {
            sortOn: [{
                attribute: 'created',
                order: 'desc'
            }]
        },
        fetchOnShow: false
    }, options));
}

function createLeadsListView(options) {
    return new PaginatedListView(_.extend({
        id: 'leads-list',
        listItemView: LeadItemView,
        listItemViewOptions: {
            section: 'Leads',
            url: 'leads',
            isDraggable: options.parent.options.itemsDraggable,
            showTags: options.parent.options.showTags
        },
        collection: new LeadsCollection(),
        fetchOptions: {
            sortOn: [{
                attribute: 'became_lead_date',
                order: 'desc'
            }],
            data: _.defaults(options.collectionData, {my_team: true})
        }
    }, options));
}

function createLeadsToolbarView() {
    return new BaseToolbarView({
        tools: {
            newItem: true,
            deleteItem: true,
            selectAllPage: true
        }
    });
}

NavView = ListNavbarView.extend({
    tabs: [
        {
            id: 'activities',
            title: 'Activity',
            class: 'list-nav-activity',
            view: createActivityListView,
            listFiltersView: function() {
                return new ActivityFilterView({ mainFilter: true });
            },
            filterable: true
        },
        {
            id: 'tasks',
            title: 'Tasks',
            showBadge: true,
            class: 'list-nav-tasks',
            view: function() {
                return new TasksView.SingleTaskListContainer({
                    filterModel: new Backbone.Model({
                        where: {
                            assigned_to_me: true
                        }
                    }),
                    defaultAssigneeSelf: true,
                    displayFilters: false
                });
            }
        },
        {
            id: 'leads',
            title: 'Leads',
            class: 'list-nav-leads',
            view: createLeadsListView,
            toolbarView: createLeadsToolbarView
        }
    ],
    initialize: function() {
        ListNavbarView.prototype.initialize.apply(this, arguments);

        if (this.options.preferencesKey) {
            this.initialTabId = app.user.get('preferences')[this.options.preferencesKey] || null;
        }

        this.listenTo(this, 'activities:showItem', function(model) {
            var target_type = model.get('target_type'),
                target_id;

            if (target_type === 'individuals') {
                target_id = model.get('individual_id');
            }
            else if (target_type === 'organizations') {
                target_id = model.get('organization_id');
            }
            else if (target_type === 'opportunities') {
                target_id = model.get('opportunity_id');
            }
            else {
                return;
            }

            this.trigger('selectActivityItem', target_type, target_id);
        });

        this.listenTo(this, 'activities:toolbar:delete', function(sel) {
            this.deleteItems( sel, true );
        });

        this.listenTo(this, 'leads:showItem', function(model) {
            this.trigger('push-view:individual:show', {model: model});
        });

        // Open a new-form when clicking on the toolbar '+' button
        this.listenTo(this, 'leads:toolbar:new', function() {
            this.trigger('push-view:individual:new', {
                leadSource: { system_type: 'unspecified' }
            });
        });

        this.listenTo(this, 'leads:toolbar:delete', function(sel) {
            this.deleteItems( sel, { url: '/individuals/' } );
        });

        this.listenTo(this, 'leads:followLink', function(model, link) {
            app.followLink(model, link);
        });
    },
    onShow: function() {
        var self = this;
        TasksView.get_task_count({ assigned_to_me: true }, function(count){
            self.$el.find('.task-counter').text(count || '');
        });
        ListNavbarView.prototype.onShow.apply(this, arguments);
    },
    getUrl: function() {
        return 'leads';
    }
});

export default NavView;

import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import vent from 'js/vent'
import backboneSelect2 from 'js/widgets/backbone-select2'


// time zones from: https://msdn.microsoft.com/en-us/library/ms912391(v=winembedded.11).aspx
// typos fixed
// ids from IANA standard
var timeZoneList = [
    {
        "id": "UTC",
        "name": "Current Timezone",
        "time": "Your Current Timezone"
    },
    {
        "id": "Etc/GMT+12",
        "name": "Dateline Standard Time",
        "time": "(GMT-12:00) International Date Line West"
    },
    {
        "id": "Pacific/Apia",
        "name": "Samoa Standard Time",
        "time": "(GMT-11:00) Midway Island, Samoa"
    },
    {
        "id": "Pacific/Honolulu",
        "name": "Hawaiian Standard Time",
        "time": "(GMT-10:00) Hawaii"
    },
    {
        "id": "America/Anchorage",
        "name": "Alaskan Standard Time",
        "time": "(GMT-09:00/-08:00) Alaska"
    },
    {
        "id": "America/Los_Angeles",
        "name": "Pacific Standard Time",
        "time": "(GMT-08:00/-07:00) Pacific Time (US and Canada); Tijuana"
    },
    {
        "id": "America/Denver",
        "name": "Mountain Standard Time",
        "time": "(GMT-07:00/-06:00) Mountain Time (US and Canada)"
    },
    {
        "id": "America/Mexico_City",
        "name": "Mexico Standard Time 2",
        "time": "(GMT-07:00/-06:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        "id": "America/Phoenix",
        "name": "U.S. Mountain Standard Time",
        "time": "(GMT-07:00) Arizona"
    },
    {
        "id": "America/Chicago",
        "name": "Central Standard Time",
        "time": "(GMT-06:00/-05:00) Central Time (US and Canada)"
    },
    {
        "id": "America/Regina",
        "name": "Canada Central Standard Time",
        "time": "(GMT-06:00/-05:00) Saskatchewan"
    },
    {
        "id": "America/Cancun",
        "name": "Mexico Standard Time",
        "time": "(GMT-06:00/-05:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        "id": "America/Guatemala",
        "name": "Central America Standard Time",
        "time": "(GMT-06:00) Central America"
    },
    {
        "id": "America/New_York",
        "name": "Eastern Standard Time",
        "time": "(GMT-05:00/-04:00) Eastern Time (US and Canada)"
    },
    {
        "id": "America/Indianapolis",
        "name": "U.S. Eastern Standard Time",
        "time": "(GMT-05:00/-04:00) Indiana (East)"
    },
    {
        "id": "America/Bogota",
        "name": "S.A. Pacific Standard Time",
        "time": "(GMT-05:00) Bogota, Lima, Quito"
    },
    {
        "id": "America/Halifax",
        "name": "Atlantic Standard Time",
        "time": "(GMT-04:00/-03:00) Atlantic Time (Canada)"
    },
    {
        "id": "America/La_Paz",
        "name": "S.A. Western Standard Time",
        "time": "(GMT-04:00) Caracas, La Paz"
    },
    {
        "id": "America/Santiago",
        "name": "Pacific S.A. Standard Time",
        "time": "(GMT-04:00) Santiago"
    },
    {
        "id": "America/St_Johns",
        "name": "Newfoundland and Labrador Standard Time",
        "time": "(GMT-03:30/-02:30) Newfoundland and Labrador"
    },
    {
        "id": "America/Sao_Paulo",
        "name": "E. South America Standard Time",
        "time": "(GMT-03:00) Brasilia"
    },
    {
        "id": "America/Cayenne",
        "name": "S.A. Eastern Standard Time",
        "time": "(GMT-03:00) Buenos Aires, Georgetown"
    },
    {
        "id": "America/Godthab",
        "name": "Greenland Standard Time",
        "time": "(GMT-03:00/-02:00) Greenland"
    },
    {
        "id": "America/Noronha",
        "name": "Mid-Atlantic Standard Time",
        "time": "(GMT-02:00) Mid-Atlantic"
    },
    {
        "id": "Atlantic/Azores",
        "name": "Azores Standard Time",
        "time": "(GMT-01:00/-00:00) Azores"
    },
    {
        "id": "Atlantic/Cape_Verde",
        "name": "Cape Verde Standard Time",
        "time": "(GMT-01:00) Cape Verde Islands"
    },
    {
        "id": "Europe/London",
        "name": "GMT Standard Time",
        "time": "(GMT+00:00/+01:00) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London"
    },
    {
        "id": "Atlantic/Reykjavik",
        "name": "Greenwich Standard Time",
        "time": "(GMT) Casablanca, Monrovia"
    },
    {
        "id": "Europe/Budapest",
        "name": "Central Europe Standard Time",
        "time": "(GMT+01:00/+02:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
        "id": "Europe/Warsaw",
        "name": "Central European Standard Time",
        "time": "(GMT+01:00/+02:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        "id": "Europe/Paris",
        "name": "Romance Standard Time",
        "time": "(GMT+01:00/+02:00) Brussels, Copenhagen, Madrid, Paris"
    },
    {
        "id": "Europe/Berlin",
        "name": "W. Europe Standard Time",
        "time": "(GMT+01:00/+02:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "id": "Africa/Lagos",
        "name": "W. Central Africa Standard Time",
        "time": "(GMT+01:00) West Central Africa"
    },
    {
        "id": "Europe/Chisinau",
        "name": "E. Europe Standard Time",
        "time": "(GMT+02:00/+03:00) Bucharest"
    },
    {
        "id": "Africa/Cairo",
        "name": "Egypt Standard Time",
        "time": "(GMT+02:00) Cairo"
    },
    {
        "id": "Europe/Kiev",
        "name": "FLE Standard Time",
        "time": "(GMT+02:00/+03:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius"
    },
    {
        "id": "Europe/Bucharest",
        "name": "GTB Standard Time",
        "time": "(GMT+02:00/+03:00) Athens, Istanbul, Minsk"
    },
    {
        "id": "Asia/Jerusalem",
        "name": "Israel Standard Time",
        "time": "(GMT+02:00/+03:00) Jerusalem"
    },
    {
        "id": "Africa/Johannesburg",
        "name": "South Africa Standard Time",
        "time": "(GMT+02:00) Harare, Pretoria"
    },
    {
        "id": "Europe/Moscow",
        "name": "Russian Standard Time",
        "time": "(GMT+03:00/+04:00) Moscow, St. Petersburg, Volgograd"
    },
    {
        "id": "Asia/Riyadh",
        "name": "Arab Standard Time",
        "time": "(GMT+03:00) Kuwait, Riyadh"
    },
    {
        "id": "Africa/Nairobi",
        "name": "E. Africa Standard Time",
        "time": "(GMT+03:00) Nairobi"
    },
    {
        "id": "Asia/Baghdad",
        "name": "Arabic Standard Time",
        "time": "(GMT+03:00) Baghdad"
    },
    {
        "id": "Asia/Tehran",
        "name": "Iran Standard Time",
        "time": "(GMT+03:30) Tehran"
    },
    {
        "id": "Asia/Dubai",
        "name": "Arabian Standard Time",
        "time": "(GMT+04:00) Abu Dhabi, Muscat"
    },
    {
        "id": "Asia/Yerevan",
        "name": "Caucasus Standard Time",
        "time": "(GMT+04:00) Baku, Tbilisi, Yerevan"
    },
    {
        "id": "Asia/Kabul",
        "name": "Afghanistan Standard Time",
        "time": "(GMT+04:30) Kabul"
    },
    {
        "id": "Asia/Yekaterinburg",
        "name": "Ekaterinburg Standard Time",
        "time": "(GMT+05:00) Ekaterinburg"
    },
    {
        "id": "Asia/Tashkent",
        "name": "West Asia Standard Time",
        "time": "(GMT+05:00) Islamabad, Karachi, Tashkent"
    },
    {
        "id": "Asia/Calcutta",
        "name": "India Standard Time",
        "time": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        "id": "Asia/Katmandu",
        "name": "Nepal Standard Time",
        "time": "(GMT+05:45) Kathmandu"
    },
    {
        "id": "Asia/Almaty",
        "name": "Central Asia Standard Time",
        "time": "(GMT+06:00) Astana, Dhaka"
    },
    {
        "id": "Asia/Colombo",
        "name": "Sri Lanka Standard Time",
        "time": "(GMT+06:00) Sri Jayawardenepura"
    },
    {
        "id": "Asia/Novosibirsk",
        "name": "N. Central Asia Standard Time",
        "time": "(GMT+06:00) Almaty, Novosibirsk"
    },
    {
        "id": "Asia/Rangoon",
        "name": "Myanmar Standard Time",
        "time": "(GMT+06:30) Yangon Rangoon"
    },
    {
        "id": "Asia/Bangkok",
        "name": "S.E. Asia Standard Time",
        "time": "(GMT+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
        "id": "Asia/Krasnoyarsk",
        "name": "North Asia Standard Time",
        "time": "(GMT+07:00) Krasnoyarsk"
    },
    {
        "id": "Asia/Shanghai",
        "name": "China Standard Time",
        "time": "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi"
    },
    {
        "id": "Asia/Singapore",
        "name": "Singapore Standard Time",
        "time": "(GMT+08:00) Kuala Lumpur, Singapore"
    },
    {
        "id": "Asia/Taipei",
        "name": "Taipei Standard Time",
        "time": "(GMT+08:00) Taipei"
    },
    {
        "id": "Australia/Perth",
        "name": "W. Australia Standard Time",
        "time": "(GMT+08:00) Perth"
    },
    {
        "id": "Asia/Irkutsk",
        "name": "North Asia East Standard Time",
        "time": "(GMT+08:00) Irkutsk, Ulaanbaatar"
    },
    {
        "id": "Asia/Seoul",
        "name": "Korea Standard Time",
        "time": "(GMT+09:00) Seoul"
    },
    {
        "id": "Asia/Tokyo",
        "name": "Tokyo Standard Time",
        "time": "(GMT+09:00) Osaka, Sapporo, Tokyo"
    },
    {
        "id": "Asia/Yakutsk",
        "name": "Yakutsk Standard Time",
        "time": "(GMT+09:00) Yakutsk"
    },
    {
        "id": "Australia/Darwin",
        "name": "A.U.S. Central Standard Time",
        "time": "(GMT+09:30) Darwin"
    },
    {
        "id": "Australia/Adelaide",
        "name": "Cen. Australia Standard Time",
        "time": "(GMT+09:30) Adelaide"
    },
    {
        "id": "Australia/Sydney",
        "name": "A.U.S. Eastern Standard Time",
        "time": "(GMT+10:00) Canberra, Melbourne, Sydney"
    },
    {
        "id": "Australia/Brisbane",
        "name": "E. Australia Standard Time",
        "time": "(GMT+10:00) Brisbane"
    },
    {
        "id": "Australia/Hobart",
        "name": "Tasmania Standard Time",
        "time": "(GMT+10:00) Hobart"
    },
    {
        "id": "Asia/Vladivostok",
        "name": "Vladivostok Standard Time",
        "time": "(GMT+10:00) Vladivostok"
    },
    {
        "id": "Pacific/Port_Moresby",
        "name": "West Pacific Standard Time",
        "time": "(GMT+10:00) Guam, Port Moresby"
    },
    {
        "id": "Pacific/Guadalcanal",
        "name": "Central Pacific Standard Time",
        "time": "(GMT+11:00) Magadan, Solomon Islands, New Caledonia"
    },
    {
        "id": "Pacific/Fiji",
        "name": "Fiji Islands Standard Time",
        "time": "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands"
    },
    {
        "id": "Pacific/Auckland",
        "name": "New Zealand Standard Time",
        "time": "(GMT+12:00) Auckland, Wellington"
    },
    {
        "id": "Pacific/Tongatapu",
        "name": "Tonga Standard Time",
        "time": "(GMT+13:00) Nuku'alofa"
    }
];

var TimeZoneSelectView =  Marionette.ItemView.extend({
    className: 'time-zone-selector',
    template: Handlebars.compile('<input class="time-zone-select">'),
    ui: {
        timeZoneSelect: '.time-zone-select'
    },
    onRender: function() {
        var self = this;

        this.timeZoneSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.timeZoneSelect,
            data: timeZoneList,
            value: _.find(timeZoneList, function(item) { return self.options.timeZoneId === item.id }),
            text: 'name',
            formatSelection: function(item) {
                return  item.name;
            },
            formatResult: function(item) {
                return item.name + '<div class="description">' + item.time + '</div>';
            },
            options: {
                placeholder: '',
                allowClear: false,
                containerCssClass: 'editable-field',
                dropdownCssClass: 'time-zone-select-popover popover has-description',
                matcher: function (term, text, item) {
                    return $.fn.select2.defaults.matcher(term, item.name) ||
                        $.fn.select2.defaults.matcher(term, item.time);
                }
            }
        });

        this.listenTo(this.timeZoneSelect, 'change', function(data) {
            self.trigger('change', data);
        })
    }
});

TimeZoneSelectView.getTZName = (function(){
    var TZMap = _.object(_.map(timeZoneList, function(item) {
       return [item.id, item]
    }));
    return function(id) {
        return TZMap[id].name;
    }
})();

export default TimeZoneSelectView;

import _ from 'underscore'
import $ from 'jquery'
import d3 from 'd3'

import D3WidgetsStyle from 'js/d3/widgets_style'
import D3Utilities from 'js/d3/utilities'
import Currency from 'js/utils/currency'
import app from 'js/app'


function revenuecampaignchart() {

    var y_axis_padding = 20;
    var row_height = 24;
    var row_padding = 0.2;
    var text_to_rect_x_offset = row_height/4;

    var x = 0,
        y = 0,
        padding = {top: 10, right: 20, bottom: 10, left: 20},
        width = 300 - padding.left - padding.right,
        height = 380 - padding.top - padding.bottom,
        data = {},
        initThings = true,
        maxValue = 0,
        showFullInfo = false,
        emptySVG = false,
        rectsHighlighted = [],
        rectsSelected = [],
        idSelected;

    var normalizeVal = function(d) {
        return (maxValue > 0) ? (d / maxValue) : 0;
    };

    var textEllipsis = function(text, maxWidth) {
        text.each(function() {
            D3Utilities.cropText(this, maxWidth, true, 'data-original-text');
        });
    };

    var highlightBars = function(svg, id, t, revenue, cost) {
        svg.classed('row-highlighted', id ? true : false);

        for (var i = 0; i < rectsHighlighted.length; ++i) {
            if (rectsHighlighted[i]) {
                rectsHighlighted[i].classed('highlighted', false);
            }
        }

        rectsHighlighted[0] = revenue;
        rectsHighlighted[1] = cost;

        for (i = 0; i < rectsHighlighted.length; ++i) {
            if (rectsHighlighted[i]) {
                rectsHighlighted[i].classed('highlighted', true);
            }
        }

        if (t) {
            t.trigger('group_highlighted', id);
        }
    };

    function chart(selection) {

        var barX = 0;

        var update = function() {

            height = (row_height * (data.length + 1));

            var barY = d3.scale.ordinal()
                .domain(data.map(function(d) { return d.group.id; }))
                .rangeRoundBands([0, row_height * data.length], row_padding, 0);

            var barWidth = d3.scale.linear()
                .domain([0, 1])
                .range([1, Math.max(width,0)]);

            var barHeight = function() {
                return Math.floor(barY.rangeBand() / 2);
            };

            var longBarWidth = function(d) {
                if (d.cost > d.revenue) {
                    return barWidth(normalizeVal(d.cost));
                }

                return barWidth(normalizeVal(d.revenue));
            };

            var yAxis = d3.svg.axis()
                .scale(barY)
                .orient('left')
                .tickPadding(y_axis_padding)
                .tickSize(0,0) // Remove black 'domain' bar
                .tickFormat(function(d,i) { return data[i].group.name; });

            var default_currency = app.user.get('client').default_currency;

            // ...
            var svg = selection.select('svg');

            if (emptySVG) {
                svg.select('*').remove();
                initThings = true;
                emptySVG = false;
            }

            // ...
            if (initThings) {
                svg
                    .append('g')
                        .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')')
                        .attr('class', 'barchart')
                        .append('g')
                            .attr('class', 'x-axis')
                            .attr('transform', 'translate(0,' + height + ')');

                svg.select('g')
                    .append('g')
                        .attr('class', 'y-axis');

                initThings = false;
            }

            svg
                .classed('expanded', showFullInfo)
                .attr('width', width + padding.left + padding.right)
                .attr('height', height + padding.top + padding.bottom);

            // Apply text ellipsis to long yaxis labels
            svg
                .select('g.y-axis')
                .call(yAxis)
                .selectAll(".tick text")
                .call(textEllipsis, padding.left - y_axis_padding*2);

            // ...
            var barchart = svg.select('g.barchart');

            var barsCost = barchart.selectAll('.cost-info')
                .data(data, function(d) {
                    return 'C-' + d.group.id;
                });

            var barsRevenue = barchart.selectAll('.revenue-info')
                .data(data, function(d) {
                    return 'R-' + d.group.id;
                });

            var barsControl = barchart.selectAll('.bars-control')
                .data(data, function(d) {
                    return 'BC-' + d.group.id;
                });

            barchart
                .transition()
                .attr('transform', 'translate(' + padding.left + ',' + padding.top + ')');

            // ...
            barsRevenue.enter().append('g')
                .attr('class', 'revenue-info row')
                .attr('id', function(d) { return 'R-' + d.group.id; })
                .call(function() {
                    this.append('rect')
                        .attr('class', 'revenue-rect bar')
                        .attr('x', barX)
                        .attr('y', function(d) { return barY('R-' + d.group.id); })
                        .attr('width', 0)
                        .attr('height', barHeight)
                        .attr('style', function(d, i) { return D3WidgetsStyle.groupStyle(i); });

                    this.append('text')
                        .attr('class', 'text-revenue')
                        .classed('loss', function(d) { return d.cost > d.revenue; })
                        .attr('text-anchor', 'start')
                        .attr('x', barX + text_to_rect_x_offset)
                        .attr('y', function(d) { return barY('R-' + d.group.id) + Math.floor(barHeight()/2); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');
                });

            barsRevenue.exit()
                .remove();

            // update bar values
            barsRevenue.select('.revenue-rect')
                .transition()
                .attr('x', barX)
                .attr('y', function(d) { return barY('R-' + d.group.id); })
                .attr('width', function(d) { return barWidth(normalizeVal(d.revenue)); })
                .attr('height', barHeight);

            barsRevenue.select('.text-revenue')
                .transition()
                .text(function(d) { return Currency.shortFormat(default_currency, d.revenue); })
                .attr('x', function(d) { return longBarWidth(d) + text_to_rect_x_offset; })
                .attr('y', function(d) { return barY('R-' + d.group.id) + Math.floor(barHeight()/2); });

            // ...
            barsCost.enter().append('g')
                .attr('class', 'cost-info row')
                .attr('id', function(d) { return 'C-' + d.group.id; })
                .call(function() {
                    this.append('rect')
                        .attr('class', 'cost-rect bar')
                        .attr('x', barX)
                        .attr('y', function(d) { return barY('C-' + d.group.id) + barHeight(); })
                        .attr('width', 0)
                        .attr('height', barHeight)
                        .attr('style', function(d, i) { return D3WidgetsStyle.groupStyleLight(i); });

                    this.append('text')
                        .attr('class', 'text-cost')
                        .attr('text-anchor', 'start')
                        .attr('x', barX + text_to_rect_x_offset)
                        .attr('y', function(d) { return barY('C-' + d.group.id) + Math.ceil(barHeight() * 1.5); })
                        .attr('dominant-baseline', 'mathematical')
                        .attr('alignment-baseline', 'mathematical');
                });

            barsCost.exit()
                .remove();

            // update bar values
            barsCost.select('.cost-rect')
                .transition()
                .attr('x', barX)
                .attr('y', function(d) { return barY('C-' + d.group.id) + barHeight(); })
                .attr('width', function(d) { return barWidth(normalizeVal(d.cost)); })
                .attr('height', barHeight);

            barsCost.select('.text-cost')
                .transition()
                .attr('x', function(d) { return longBarWidth(d) + text_to_rect_x_offset; })
                .attr('y', function(d) { return barY('C-' + d.group.id) + Math.ceil(barHeight() * 1.5); })
                .text(function(d) { return Currency.shortFormat(default_currency, d.cost); });

            // ...
            barsControl.enter().append('g')
                .attr('class', 'control')
                .attr('id', function(d) { return 'BC-' + d.group.id; })
                .append('rect')
                    .attr('id', function(d) { return d.group.id; })
                    .attr('class', 'control-rect')
                    .style('fill-opacity', 0);

            barsControl.exit()
                .remove();

            // control
            var rowPadding = (row_height * row_padding) + 1;

            barsControl.select('rect')
                .attr('x', -padding.left)
                .attr('y', function(d) { return barY('C-' + d.group.id) - (rowPadding * 0.5); })
                .attr('width', width + padding.left + padding.right)
                .attr('height', barHeight() * 2 + rowPadding)
                .on('mouseover', function() {
                    if (!showFullInfo) {
                        highlightBars(svg, this.id, $(this), svg.select('#R-' + this.id),
                            svg.select('#C-' + this.id));
                    }
                })
                .on('click', function() {
                    if (!showFullInfo) {
                        d3.event.stopPropagation();
                        $(this).trigger('group_selected', this.id);
                    }
                })
                .on('mouseout', function() {
                    if (!showFullInfo) {
                        highlightBars(svg, null, $(this));
                    }
                });


            selection
                .on('click', function() {
                    if (!showFullInfo) {
                        $(this).trigger('group_selected', null);
                    }
                });
        };

        update();
    }

    chart.expand = function(_) {
        if (!arguments.length || !_) {
            row_height = 24;
            showFullInfo = false;
        }
        else {
            row_height = 48;
            showFullInfo = true;
            padding.left = 220;
        }
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        if (showFullInfo) {
            // padding.left = Math.min(200, Math.round(_ * 0.3));
            padding.right = Math.max(60, Math.round(_ * 0.1)) + text_to_rect_x_offset + 20;
        }
        else {
            padding.left = 20;
            padding.right = 20;
        }
        width = _ - padding.left - padding.right;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _ - padding.top - padding.bottom;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;

        for (var i = 0; i < data.length; ++i) {
            if (data[i].cost > maxValue) {
                maxValue = data[i].cost;
            }

            if (data[i].revenue > maxValue) {
                maxValue = data[i].revenue;
            }
        }
        return chart;
    };

    chart.empty = function() {
        emptySVG = true;
    };

    chart.highlightSingleBar = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        // ...
        var svg = selection.select('svg');

        highlightBars(svg, id, null, svg.select('#R-' + id), svg.select('#C-' + id));
    };

    chart.selectSingleBar = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        // ...
        var svg = selection.select('svg');

        for (var i = 0; i < rectsSelected.length; ++i) {
            if (rectsSelected[i]) {
                rectsSelected[i].classed('active', false);
                rectsSelected[i] = null;
                svg.classed('row-active', false);
            }
        }

        if (id && (id !== idSelected)) {
            idSelected = id;

            rectsSelected[0] = svg.select('#R-' + id);
            rectsSelected[1] = svg.select('#C-' + id);

            for (i = 0; i < rectsSelected.length; ++i) {
                if (rectsSelected[i]) {
                    rectsSelected[i].classed('active', true);
                    svg.classed('row-active', true);
                }
            }
        }
        else {
            idSelected = null;
        }
    };


    return chart;
}

export default revenuecampaignchart;

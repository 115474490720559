import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import xhook from 'xhook'

import app from 'js/app'
import TextManager from 'app/text-manager'
import UserModel from 'js/models/user'
import ClientModel from 'js/models/client'
import IndividualModel from 'js/models/contact'
import ModalRegion from 'js/views/base/modal-region'
import api from 'js/api'
import vent from 'js/vent'
import AppConfig from 'app/app-config'
import StacksView from 'js/views/stacks'
import NewAppMenuView from 'app/app-menu/new-app-menu'
import AppToolbarView from 'app/app-toolbar/app-toolbar'
import SearchReactContainerView from 'app/search/search-react-container-view'
import MessageBox from 'js/views/message_box'
import appContent from 'js/views/appcontent'
import PreferencesModel from 'js/models/preferences'
import Currency from 'js/utils/currency'
import dateFormat from 'js/utils/date-format'
import security from 'js/utils/security'
import linkify from 'js/utils/linkify'
import ActivityCreator from 'js/utils/activity_creator'
import TimeZoneSelectView from 'app/widgets/time-zone-selector/time-zone-selector'
import EulaViewer from 'js/views/eula-viewer'
import Utilities from 'js/utils/utilities'
import CustomFieldModel from 'js/models/custom_field.js'
import TagsCollection from 'js/collections/tags';
import FunnelsCollection from 'js/collections/funnels'
import CustomFieldsCollection from 'js/collections/custom_fields'
import QuickAdd from 'js/views/quick_add'
import PhasesCollection from 'js/collections/phases'
import FilePreview from 'js/react_views/file-preview/preview'
import AppointmentPanel from 'app_v2/panels/appointment'
import TaskPanel from 'app_v2/panels/task'


class ShortTermCache {
    constructor(props) {
        this.cache = {};
        this.activeQueries = {};
    }

    get(url, args, callback) {
        const now = new Date().getTime();
        const query = `${url}?${$.param(args)}`;

        if (query in this.cache) {
            const cacheEntry = this.cache[query];

            if ((now - cacheEntry.timestamp) <= 10000) { // the data is valid only for 10 seconds
                callback(cacheEntry.data, cacheEntry.paginationInfo);
                return;
            }
        }

        if (query in this.activeQueries) {
            this.activeQueries[query].push(callback);
            return;
        }

        this.activeQueries[query] = [callback];

        const self = this;

        $.get(query, function(data, _, xhr) {
            let cacheData = {
                timestamp: now,
                data: data
            };

            let recordsTotal = xhr.getResponseHeader('Records-Total');

            if (recordsTotal) {
                cacheData.paginationInfo = {
                    start: parseInt(xhr.getResponseHeader('Records-Start')),
                    rows: parseInt(xhr.getResponseHeader('Records-Rows')),
                    total: parseInt(recordsTotal)
                };
            }

            self.cache[query] = cacheData;

            for (const cb of self.activeQueries[query]) {
                cb(cacheData.data, cacheData.paginationInfo);
            }

            delete self.activeQueries[query];
        });
    }
}


app.$el = $('#app-container');

// remove all tooltips on any keypress
$('body').on('keyup', function() { $(this).find('.tooltip').remove(); });

app.addRegions({
    appAlert: '#app-alert',
    appConfirmationContainer: '#confirmation-container',
    appSettings: {
        selector: '#app-settings', // selector it self not used in ModalRegion
        regionType: ModalRegion
    },
    appUserSettings: {
        selector: '#app-user-settings', // selector it self not used in ModalRegion
        regionType: ModalRegion
    },
    appStacks: '#app-stacks',
    appPanel: '#app-panel',
    eulaContainer: {
        selector: '#eula-container',
        regionType: ModalRegion.extend({ backdrop: 'static', keyboard: false })
    },
    quickAddRegion: {
        selector: '.quick-add-modal', // selector it self not used in ModalRegion
        regionType: ModalRegion
    },
    filePreviewRegion: {
        selector: '.file-preview',
        regionType: ModalRegion
    }
});

app.layout = new StacksView();
app.showSettings = function (SettingsView, id) {
    app.listenTo(this.appSettings, 'show close', this.onUpdateSettings);

    this.appSettings.show(new SettingsView({viewId: id}));
    this.listenTo(this.appSettings.currentView, 'close', this.hideSettings);
};
app.hideSettings = function () {
    this.stopListening(this.appSettings.currentView);
    this.appSettings.reset();
};
app.showUserSettings = function(View, id) {
    app.listenTo(this.appUserSettings, 'show close', this.onUpdateSettings);

    this.appUserSettings.show(new View({ viewId: id }));
    this.listenTo(this.appUserSettings.currentView, 'close', this.hideUserSettings);
},
app.hideUserSettings = function() {
    this.appUserSettings.reset();
},
app.showQuickAddTask = function(model, options) {
    var params = {};
    if (model) {
        params.id = model.get('id') || null;

        if (!params.id) {
            params.preloadedFields = model.toJSON();
        }
    }

    if (options) {
        params = _.extend(params, options);
    }

    const taskPanel = new TaskPanel(params);

    this.listenTo(taskPanel, 'panel:close', function() {
        this.appPanel.reset();
    });

    this.appPanel.show(taskPanel);
},
app.hideQuickAdd = function() {
    this.quickAddRegion.reset();
},
app.showQuickAddNote = function(params) {
    var options = _.extend(params || {});

    var quickAddNote = new QuickAdd.Note(options);
    this.quickAddRegion.show(quickAddNote);
},
app.showQuickCreateMessage = function(individualId, phoneNumber) {
    var quickCreateMessage = new QuickAdd.Message({ individualId: individualId, phoneNumber: phoneNumber });
    this.quickAddRegion.show(quickCreateMessage);
},
app.showQuickAddAppointment = function(appointmentId, options) {
    var params = {};

    if (appointmentId) {
        params.id = appointmentId;
    }

    if (options) {
        params = _.extend(params, options);
    }

    const appointmentPanel = new AppointmentPanel(params);

    this.listenTo(appointmentPanel, 'panel:close', function() {
        this.appPanel.reset();
    });

    this.appPanel.show(appointmentPanel);
},
app.showQuickAddReport = function(options) {
    var params = {};

    if (options) {
        params = _.extend(params, options);
    }

    var quickAddReport = new QuickAdd.Report(params);
    this.quickAddRegion.show(quickAddReport);
},
app.showFilePreview = function(file) {
    this.filePreviewRegion.show(new FilePreview({file: file}));
},
app.hideFilePreview = function(file) {
    this.filePreviewRegion.reset();
},
app.onUpdateSettings = function() {
    _.defer(function() {
        vent.trigger('AppContent:contentChange');
    });
};
app.showAlert = function(AlertView, options) {
    this.appAlert.show(new AlertView(options));
    this.appAlert.$el.appendTo('body')
        .stop(true,true)
        .show()
        .addClass('active');
};
app.hideAlert = function(now) {
    if (now) {
        app.appAlert.close();
        this.appAlert.$el.stop(true, true);
        this.appAlert.$el.hide()
            .removeClass('active')
            .appendTo(app.$el);
    } else if (this.appAlert.currentView) {
        this.appAlert.$el.fadeOut(800, function() {
            app.appAlert.close();
            app.appAlert.$el.removeClass('active')
                .appendTo(app.$el);
        });
    }
};

app.followLink = function(model, link) {
    var activity,
        comms = model.get('communication'),
        medium, value, selectedCommunication;

    // currently we immediately create activity only for calls
    if (link.indexOf('tel') === 0) {
        medium ='phone';
        value = link.match(/tel:(.*)/)[1];
    }

    _.each(comms, function(comm) {
        var valuetmp;
        if (selectedCommunication) {
            return false;
        }
        if (comm['medium'] === medium) {
            valuetmp = comm['value'].replace(' ','');
            if (valuetmp === value) {
                selectedCommunication = comm;
                return true;
            }
        }
    });

    if (selectedCommunication) {
        activity = ActivityCreator.createAutoCommunication(
            medium,
            selectedCommunication,
            model.get('type'),
            model.get('id')
        );
    }

    window.open(link);

    if (activity) {
        activity.save({}, {
            complete: function() {
                vent.trigger('update:activity');
            }
        });
    }
};

// After app initialized show public views or authenticate user
app.on('initialize:after', function() {
    var userAgent = navigator.userAgent;

    if (userAgent.indexOf('Firefox') !== -1) {
        app.firefox = true;
    }
    // chrome has both 'Safari' and 'Chrome' in userAgent, so it should be checked before safari
    else if(userAgent.indexOf('Chrome')!=-1) {
        app.chrome = true;
    }
    else if (userAgent.indexOf('Safari') !== -1) {
        app.safari = true;
    }

    const options = app.options;
    fetch(`${options.apiUrl}/client`).then(async (value) => {
        if (value.status === 200) {
            let next = window.location.hash;
            options.clientDetails = await value.json();

            api.getAuthenticatedUser(null, (user, otpPassed) => {
                // XXX: hash routing
                if (user) {
                    if (next.lastIndexOf('#reset?token=') === 0) {
                        next = null;
                    }

                    app.user = new UserModel(user);

                    if (otpPassed === 'false') {
                        vent.trigger('login:2fa_required', next);
                    } else {
                        vent.trigger('login:success', next);
                    }
                } else {
                    app.user = null;
                    if (next.lastIndexOf('#reset?token=') === 0) {
                        var parameters = app.getURLParams(next.slice(7));
                        vent.trigger('reset', parameters.token);
                    }
                    else {
                        vent.trigger('login', next);
                    }
                }
            });
        } else if (value.status === 502) {
            vent.trigger('bad_gateway');
        } else {
            vent.trigger('invalid_client:show');
        }
    });
});

//Process the url Get parameters
app.getURLParams = function (parametersString) {
    var array = parametersString.split("&"),
    parameters = {};
    _.each(array, function(parameter) {
        var value=parameter.split("=");
        parameters[value[0]]=value[1];
    });
    return parameters;
};

// Start Backbone.history after router has started
vent.on('routing:start', function(route) {
    if (Backbone.History.started) {
        Backbone.history.stop();
    }
    if (route) {
        window.location.hash = route;
    }


    var ignore = false;
    /**
     * Handle confirmation on back and forward buttons, which we can't handle otherwise. Here we wrap around
     * Backbone checkUrl function.
     *
     * @type {boolean}
     */
    Backbone.history.checkUrl = function(ev) {
            if (ignore) {
                ignore = false;
                return;
            }

            // settings are closed and URL changed
            if (app.dirtyModelHandler.settingsPopupStatus === 1) {
                app.dirtyModelHandler.settingsPopupStatus = 0;
                Backbone.History.prototype.checkUrl.call(Backbone.history);
                return;
            }

            // user settings are closed and URL changed
            if (app.dirtyModelHandler.userSettingsPopupStatus === 1) {
                app.dirtyModelHandler.userSettingsPopupStatus = 0;
                Backbone.History.prototype.checkUrl.call(Backbone.history);
                return;
            }

            app.dirtyModelHandler.confirm(
                this,
                function () {
                    // proxy checkUrl call if confirmed
                    Backbone.History.prototype.checkUrl.call(Backbone.history);
                },
                function() {
                    // undo URL change when hitting back or forward and ignore here changeUrl
                    ignore = true;
                    window.location.href = ev.originalEvent.oldURL;
                }
            );
    };

    Backbone.history.start();
});

// Show App Chrome - i.e. menu and searchbar
// Define menu and searchbar at app level for easier access
vent.on('chrome:show', function() {
    var layout = app.layout,
        appContentView = appContent;
    // Main Nav
    app.menu = new NewAppMenuView();
    layout.appMenu.show(app.menu);
    // Search (overlay)
    app.search = new SearchReactContainerView({ parent: app.layout });
    layout.appSearch.show(app.search);
    // App Content Container
    appContentView.render();
    // Toolbar
    app.toolbar = new AppToolbarView();
    layout.appToolbar.show(app.toolbar);
    vent.trigger('appContentView:render');
    layout.$el.find('#app-content-container').append(appContentView.el);

    startShortcuts();
});

function startShortcuts() {
    // TODO
    $(document).keydown(function(ev){
        if (ev.keyCode === 191) {
            // start search if no modal window is opened and not key modifier is pressed and no input/textarea field is focused
            var modal = $('div.modal.in');
            var focused = $(':focus');
            var hasModifiers = ev.ctrlKey || ev.altKey || ev.metaKey || ev.shiftKey;
            var invalidTypes = ['text', 'password', 'email', 'search', 'url'];

            if ((modal.length === 0) && !hasModifiers && (!focused.is('input') || !_.contains(invalidTypes, focused.attr('type'))) && !focused.is('textarea')) {
                app.toolbar.setFocusToSearchInputBox();
                ev.preventDefault();
            }
        }
        else if (ev.ctrlKey) {
            if (ev.keyCode === 'S'.charCodeAt(0)) {
                vent.trigger('shortcut:save', ev);
                ev.preventDefault();
            } else if (ev.keyCode === 'P'.charCodeAt(0)) {
                var modal = $('div.modal.in');

                if (modal.length === 0) {
                    ev.preventDefault();
                    vent.trigger('shortcut:palette');
                }
            }
        }
    });
}

function preInitializeApp(callback) {
    var numFetches = 0;

    var checkReady = function() {
        --numFetches;

        if (numFetches === 0) {
            callback();
        }
    }

    // update classes styles that can be configured in the preferences
    if (AppConfig.getValue('funnelPopup') === 'extended') {
        const clientPreferences = app.user.get('client').preferences;
        const snapshotPreferences = (clientPreferences && clientPreferences.loans_snapshot) ? JSON.parse(clientPreferences.loans_snapshot) : {};

        if (snapshotPreferences.colours) {
            var colorGo = snapshotPreferences.colours[0];
            var colorAssign = snapshotPreferences.colours[1];
            var colorIssue = snapshotPreferences.colours[2];

            // colorkey and bubbles classes colors
            for (const sheet of document.styleSheets) {
                if (sheet.href && sheet.href.indexOf('http') !== 0) { // this is to ignore the css loaded from extensions
                    continue;
                }

                const rules = document.all ? sheet.rules : sheet.cssRules;

                for (const rule of rules) {
                    if (rule.cssText.indexOf('abbreviation') !== -1) {
                        continue;
                    }

                    if (rule.cssText.indexOf('go-assign-issue-go') !== -1 && rule.style) {
                        rule.style.fill = colorGo;
                        rule.style.stroke = colorGo;
                        rule.style.background = colorGo;
                    } else if (rule.cssText.indexOf('go-assign-issue-assign') !== -1 && rule.style) {
                        rule.style.fill = colorAssign;
                        rule.style.stroke = colorAssign;
                        rule.style.background = colorAssign;
                    } else if (rule.cssText.indexOf('go-assign-issue-attention') !== -1 && rule.style) {
                        rule.style.fill = colorAssign;
                        rule.style.stroke = colorAssign;
                        rule.style.background = colorAssign;
                    } else if (rule.cssText.indexOf('go-assign-issue-issue') !== -1 && rule.style) {
                        rule.style.fill = colorIssue;
                        rule.style.stroke = colorIssue;
                        rule.style.background = colorIssue;
                    }
                }
            }
        }
    }

    if (AppConfig.getValue('customColorKeys')){
        const clientPreferences = app.user.get('client').preferences;
        const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ?
            JSON.parse(clientPreferences.custom_colorkeys) : [];

        app.globalData.colorkeysInfo = {}

        if (customColorkeys && customColorkeys.length > 0){
            customColorkeys.forEach(cfColorKeys => {
                if(!app.globalData.colorkeysInfo[cfColorKeys.dropdown_id] && !cfColorKeys.is_system_field){
                    const customField = new CustomFieldModel({id: cfColorKeys.dropdown_id});
                    ++numFetches;

                    customField.fetch({
                        success: function() {
                            app.globalData.colorkeysInfo[cfColorKeys.dropdown_id] = customField.attributes.options;
                            checkReady();
                        }
                    });
                }
            })
        }
    }

    // Check info intent client
    if (AppConfig.getValue('useInfoStyles')) {
        $("link[rel*='icon'][type='image/x-icon']").attr("href", "img/favicon-intent-info/favicon.ico");

        var appleTouchIcons = $("link[rel*='apple-touch-icon']");

        for (var i= 0; i < appleTouchIcons.length; i++) {
            var sizes = appleTouchIcons[i].sizes.value

            appleTouchIcons[i].href = `img/favicon-intent-info/apple-touch-icon-${sizes}.png`;
        }

        var favicons = $("link[rel*='icon']");
        for (var i= 0; i < favicons.length; i++) {
            var sizes = favicons[i].sizes.value

            if(sizes) {
                favicons[i].href = `img/favicon-intent-info/favicon-${sizes}.png`;
            }
        }
    }

    // integrations
    app.globalData.integrationsInfo = {
        all: [],
        authorized: []
    };

    ++numFetches;

    $.get('/integrations?rows=-1', function(data) {
        const authorized = data.filter(i => i.status === 'authorized');

        app.globalData.integrationsInfo = {
            all: data,
            authorized: authorized
        };

        for (const element of authorized) {
            if (element.id === "proposify") {
                app.globalData.proposifyInfo = element.extra_data;
            } else if (element.id === "yourkeys") {
                app.globalData.yourkeysExtraData = element.extra_data;
            }
        }

        // send sms enable
        var clientPreferences = app.user.get('client').preferences || {};

        if (clientPreferences.sms_provider) {
            var integrationData = _.findWhere(authorized, { id: clientPreferences.sms_provider });

            if (integrationData) {
                app.globalData.smsInfo = {
                    provider: clientPreferences.sms_provider,
                    originatorType: integrationData.extra_data.originator_type,
                    originatorValue: integrationData.extra_data.originator_value,
                    attachmentsAllowed: integrationData.extra_data.allow_attachments,
                    messageMaxLength: integrationData.extra_data.message_max_length || 1600
                };

                // is the logged user able to send sms?
                if (integrationData.extra_data.originator_type === 'user_phone') {
                    var twilioPhoneCfId = clientPreferences.twilio_phone_custom_field_id;

                    if (twilioPhoneCfId) {
                        var userPrefs = app.user.get('preferences');

                        if (userPrefs && userPrefs.sync_info && userPrefs.sync_info.target_id) {
                            var model = new IndividualModel({ id: userPrefs.sync_info.target_id });
                            ++numFetches;

                            model.fetch({
                                success: function(data) {
                                    if (data) {
                                        const customFields = data.get('custom_fields') || {};
                                        if (customFields[twilioPhoneCfId]) {
                                            app.user.set('can_send_sms', true);
                                        }
                                    }
                                    checkReady();
                                },
                                error: function() {
                                    checkReady();
                                }
                            });
                        }
                    }
                } else {
                    app.user.set('can_send_sms', true);
                }
            }
        }

        checkReady();
    });

    // tags
    ++numFetches;

    var tagsCollection = new TagsCollection();

    tagsCollection.fetch({
        rows: -1,
        success: function() {
            app.globalData.tags = tagsCollection.toJSON();
            checkReady();
        }
    });

    // funnels
    ++numFetches;

    var funnelsCollection = new FunnelsCollection();
    funnelsCollection.fetch({
        data: {
            permissioned: AppConfig.getValue('checkFunnelsPermissions', false)
        },
        sortOn: [{
            attribute: 'order',
            order: 'asc'
        }],
        rows: -1,
        success: function() {
            var fieldIds = [];
            var fieldsInUse = [];
            var mapsAvailable = false;

            for (var funnel of funnelsCollection.models) {
                const mapConfiguration = funnel.get('map_configuration');

                if (mapConfiguration) {
                    mapsAvailable = true;

                    let floors = [];

                    if (mapConfiguration.floors) {
                        floors = mapConfiguration.floors;
                    } else {
                        floors = [mapConfiguration];
                    }

                    for (const f of floors) {
                        if (fieldsInUse.indexOf(f.field_id) === -1) {
                            if (f.field_id.indexOf('custom_field.') === 0) {
                                fieldIds.push(f.field_id.replace('custom_field.', 'custom_fields.'));
                                fieldsInUse.push(f.field_id);
                            }
                        }
                    }
                }
            }

            app.globalData.funnelsInfo = {
                mapsAvailable: mapsAvailable,
                funnels: funnelsCollection.toJSON(),
                fieldIds: fieldIds
            };

            // TEMP until the regions endpoint checks permissions
            let regions = [];
            let regionsIds = [];
            let clusters = [];
            let clusterIds = [];

            for (const funnel of funnelsCollection.toJSON()) {
                if (funnel.region && regionsIds.indexOf(funnel.region.id) === -1) {
                    regions.push(funnel.region);
                    regionsIds.push(funnel.region.id);
                }

                if (funnel.cluster && clusterIds.indexOf(funnel.cluster.id) === -1) {
                    clusters.push(funnel.cluster);
                    clusterIds.push(funnel.cluster.id);
                }
            }

            app.globalData.regions = regions;
            app.globalData.clusters = clusters;

            // aftercare info
            if (AppConfig.getValue('deals.aftercare.visible')) {
                const aftercareFunnel = app.globalData.funnelsInfo.funnels.find(funnel => funnel.name.toLowerCase() === AppConfig.getValue('deals.aftercare.funnel_name').toLowerCase());

                if (aftercareFunnel) {
                    ++numFetches;

                    var phases = new PhasesCollection();

                    phases.fetch({
                        data: {
                            rows: -1,
                            funnel_id: aftercareFunnel.id
                        },
                        success: function(data) {
                            let aftercarePhases = {
                                user: []
                            };

                            for (const phase of data.models) {
                                switch (phase.get('phase_type')) {
                                    case 'user':
                                        aftercarePhases.user.push({
                                            id: phase.get('id'),
                                            name: phase.get('name')
                                        });
                                        break;

                                    case 'won':
                                        aftercarePhases.won = {
                                            id: phase.get('id'),
                                            name: phase.get('name')
                                        };
                                        break;

                                    case 'lost':
                                        aftercarePhases.lost = {
                                            id: phase.get('id'),
                                            name: phase.get('name')
                                        };
                                        break;
                                }
                            }

                            app.globalData.aftercare = {
                                phases: aftercarePhases
                            };

                            checkReady();
                        }
                    });
                }
            }

            checkReady();
        }
    });

    // custom fields
    ++numFetches;

    var customFieldsCollection = new CustomFieldsCollection();
    customFieldsCollection.fetch({
        rows: -1,
        success: function(results) {
            var customFields = {
                individuals: {},
                individualsArray: [],
                organizations: {},
                organizationsArray: [],
                deals: {},
                dealsArray: []
            };

            for (const m of results.models) {
                var cfInfo = {};
                var attrs = ['name', 'type', 'value', 'view', 'params', 'required', 'default_merge_tag', 'default'];

                for (const attr of attrs) {
                    cfInfo[attr] = m.get(attr);
                }

                if (cfInfo.type === 'dropDown') {
                    cfInfo.optionsArray = m.get('options');
                    cfInfo.options = {};

                    for (const o of m.get('options')) {
                        cfInfo.options[o.id] = o.value;
                    }
                }

                var id = m.get('id');
                var view = m.get('view');

                customFields[view][id] = cfInfo;
                customFields[`${view}Array`].push(_.extend(cfInfo, {
                    id: id
                }));
            }

            customFields.opportunitiesArray = customFields.dealsArray;
            customFields.opportunities = customFields.deals;

            app.globalData.customFieldsInfo = customFields;

            checkReady();
        }
    });

    // custom filters
    ++numFetches;

    $.get('/custom_fields_quick_filters', function(data) {
        app.globalData.customFieldsQuickFilters = data;
        checkReady();
    });

    // dashboards
    app.globalData.dashboards = [];
    ++numFetches;

    $.get('/dashboards', function(data) {
        app.globalData.dashboards = data;
        checkReady();
    });

    // users
    app.globalData.users = [];
    ++numFetches;

    $.get('/users?rows=-1', function(data) {
        app.globalData.users = data;
        checkReady();
    });

    // buckets
    app.globalData.buckets = [];
    ++numFetches;

    $.get('/buckets?rows=-1', function(data) {
        app.globalData.buckets = data;
        checkReady();
    });

    // periods
    app.globalData.periods = [];
    ++numFetches;

    $.get('/periods?rows=-1&order_by=modified%20desc', function(data) {
        app.globalData.periods = data;
        checkReady();
    });

    // phases
    ++numFetches;

    const phases = new PhasesCollection();

    phases.fetch({
        data: {
            rows: -1,
        },
        success(data) {
            let phasesInfo = {
                phases: phases.toJSON(),
                hierarchy: data.getWonLost().concat(phases.getAsHierarchy())
            };

            app.globalData.phasesInfo = phasesInfo;
            checkReady();
        }
    });

    // logged user roles information
    if (app.user.get('client').permission_type === 'rba') {
        ++numFetches;

        let numRoles = 0;
        let roles = [];

        for (const role of app.user.get('roles')) {
            ++numRoles;

            $.get(`/roles/${role.id}`, function(role) {
                roles.push(role);
                --numRoles;

                if (numRoles === 0) {
                    app.globalData.loggedUserRoles = roles;
                    checkReady();
                }
            });
        }
    }

    $.get({
        url: '/teams2?team_types=sales&children=true',
        success: function(response) {
            app.globalData.teams = response;
        }
    });
}

function startStacks() {
    // Show stacks layout
    app.appStacks.show(app.layout);
}

function showStartupMessage() {
    if (AppConfig.getValue('showEULA') && !app.user.get('preferences').eula_acceptance_date && !Utilities.getCookie('override_user_id')) {
        app.eulaContainer.show(new EulaViewer());
        return;
    }

    if (AppConfig.getValue('disableTrialNotices')) {
        return;
    }

    var deletionTime = app.user.get('client').pending_delete;

    if (deletionTime) {
        if (!_.isDate(deletionTime)) {
            deletionTime = new Date(deletionTime);
        }

        var endTime = new Date(
            deletionTime.getFullYear(),
            deletionTime.getMonth(),
            deletionTime.getDate()
        );

        if (Date.now() > endTime.getTime()) {
            var content = {
                message: 'This account has been disabled.<br>If you want to resume your usage or think this message is an error, please contact us urgently.',
                icon: 'icon-warning'
            };

            MessageBox.showNoBtn(content, app.layout, {staticRegion: true});
        }
        else {
            var message = [
                'This account is about to be disabled. You can still access and use the system for a short period.',
                'This normally happens at the end of the trial period or when a subscription is cancelled. If you need more time to evaluate SalesSeek, or assistance with setting up the payment details, please contact us at support@salesseek.net.'
            ].join('<br>');

            var content = {
                message: message,
                icon: 'icon-warning'
            };

            MessageBox.showOk(content, app.layout);
        }
    }
    else {
        showRemainingTrialTime();
    }
}

function showRemainingTrialTime() {
    var client = app.user.get('client');

    // we only show the message to admins as non-admins cannot access /payment_details
    if (!app.user.get('is_admin')) {
        return;
    }

    // we start to show the message one day after the registration
    var dayMillis = 60000 * 60 * 24;
    var clientCreated = dateFormat.parseDate(client.created);
    var startTime = new Date(
        clientCreated.getFullYear(),
        clientCreated.getMonth(),
        clientCreated.getDate()
    );
    var now = Date.now();

    if (now - startTime.getTime() < dayMillis) {
        return;
    }

    /*
     * Check has_off_channel_subscription in the general purpose client.preferences dictionary
     * - this is used for clients who pay by other means than braintree.
     */
    if (client.preferences && client.preferences.has_off_channel_subscription === 'true') {
        return;
    }

    $.ajax({
        method: 'GET',
        url: '/payment_details',
        dataType: 'json'
    }).done(function(data, status, xhr) {
        /*
         * Check if client has an active subscription by looking at has_subscription in /payment_details
         * response.
         */
        if (data.has_subscription) {
            return;
        }

        var endTime = new Date(startTime.getTime() + 15 * dayMillis),
            daysLeft,
            message;

        if (now > endTime.getTime()) {
            message = "Your trial period has ended";
        }
        else {
            daysLeft = Math.floor((endTime.getTime() - now) / dayMillis);
            message = "Your trial will end " + (daysLeft > 0 ? "in " + daysLeft + " day" : "tomorrow");
            if (daysLeft > 1) {
                message += "s";
            }
        }

        MessageBox.showYesNo(
            {
                message: message,
                accept_button_text: 'Upgrade',
                accept_is_negative: true,
                cancel_button_text: 'Close',
                icon: 'icon-warning'
            },
            app.layout,
            function () {
                vent.trigger('settings:show', 'editBillingAddressSettings');
            }
        );
    });
}

// Login events
vent.on('login:success', function(cameFrom) {
    // global dictionary to store data
    app.globalData = {};

    // short term cache
    app.shortTermCache = new ShortTermCache();

    // Store user and client model in app.state
    app.user.fetch({
        complete: function () {
            if (AppConfig.getValue("useFreshChat", false) && !app.user.get('is_helper')) {
                initiateCall();
            } else {
                // Intercom user authentication
                if ((AppConfig.getValue("showSupportWidget", true)) && !_.isUndefined(window.Intercom)) {
                    var client = app.user.get('client');

                    var settings = {
                        app_id: AppConfig.getValue('intercomId'),
                        name: app.user.get('name'),
                        email: app.user.get('email_address'),
                        created_at: app.user.get('created'),
                        company: {
                            id: client.short_id,
                            name: client.name
                        },
                        widget: {
                            activator: "#IntercomDefaultWidget"
                        }
                    };

                    // Do not track at all the helper account and demo client
                    if (app.user.get('email_address') !== 'helper@salesseek.net') {
                        window.Intercom('boot', settings);
                    }
                }
            }

            if ((AppConfig.getValue("showSupportWidget", true)) && AppConfig.getValue('useZendesk', false) && app.user.get('email_address') !== 'helper@salesseek.net') {
                // HIDE INTERCOM WIDGET, still working in the background to collect data
                if (!_.isUndefined(window.Intercom)) {
                    window.Intercom('update', {
                        "hide_default_launcher": true
                    });
                }

                initiateZenDesk();

                window.zESettings = {
                    webWidget: {
                        position: { horizontal: 'left', vertical: 'bottom' },
                        chat: {
                            departments: {
                                enabled: ['CRM Support'],
                                select: 'CRM Support'
                            }
                        },
                        contactForm: {
                            fields: [
                                {
                                    id: 'email',
                                    prefill: {'*': app.user.get('email_address')}
                                }
                            ]
                        }
                    }
                };
            }

            if (!app.user.get('preferences')) {
                app.user.set('preferences', new PreferencesModel());
            }

            // previous work before start the app (for instance, cache some data)
            preInitializeApp(function() {
                startStacks();
                vent.trigger('routing:start', cameFrom);

                if (AppConfig.getValue('logged_user.is_campaigns_only')) {
                    app.router.navigate('campaigns', {trigger: true});
                } else {
                    // goto to the first enabled nav item
                    for (var navItem of ['dashboard', 'tasks', 'activities', 'contacts', 'deals', 'campaigns', 'automations', 'content', 'forecasts', 'social', 'web']) {
                        if (AppConfig.getValue(`app_nav_items.${navItem}.visible`)) {
                            app.router.navigate(cameFrom || navItem, {trigger: true});
                            break;
                        }
                    }
                }

                showStartupMessage();
            });
        },
        success: function (model, response) {
            if (!model.get('client') && response.client) {
                // Because relational prefers associating by client_id
                // to creating a new model with the data we give it.
                model.set('client', new ClientModel(response.client));
            }
            app.state.set({
                user: model,
                client: model.get('client')
            });
        },
        // TODO: better error handling
        extraFields: [
            'client',
            'preferences'
        ]
    });
});

// 502 error
vent.on('bad_gateway', function() {
    window.location.href = 'https://salesseek.net/503/'
});

// 503 Error
vent.on('server_temporarily_unavailable', function() {
    window.location.reload(true);
});

// App initializer: instantiate the routers, controllers, and AppState
// model
app.addInitializer(function(options) {

    // Initialize the app state
    app.state = new options.appState();

    // Main App router
    var appController = new options.appController({
            // This may change for showing login/logout view
            appLayout: app.layout
        });
    _.each(options.controllers, function(Controller) {
        new Controller({appLayout: app.layout});
    });
    this.router = new options.appRouter({
        controller: appController
    });
    this.controller = appController;
});

// Random initializers - to be sorted...
app.addInitializer(function(options) {
    app.options = options;
    api.initialize({url: options.apiUrl});

    $.ajaxSetup({
        statusCode : {
            401: function() {
                // Check if the request url starts with our api
                if (this.url.indexOf(window.location.origin + '/api') === 0) {
                    // Session has expired. Redirect to login page
                    vent.trigger('login', window.location.hash);
                }
            }
        }
    });

    // Called before every ajax request. Be very careful with this
    xhook.before(async (request, callback) => {
        // Exit if we are not calling our api or cognito is not configured for the client
        if (!request.url.startsWith(`${app.options.apiUrl}`) || !app?.options?.clientDetails?.cognito) {
            return callback();
        }

        const refreshToken = localStorage.getItem('cognito_refresh_token');
        const expiresAt = parseInt(localStorage.getItem('cognito_expires_at')) - (60 * 1000);
        const now = new Date().getTime();
        // If the token isn't expired, don't do anything
        if (expiresAt > now) {
            return callback();
        }

        // Fetch the new token
        const { domain, client_id } = app.options.clientDetails.cognito;
        const response = await fetch(`https://${domain}/oauth2/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: client_id,
                refresh_token: refreshToken,
            }),
        });
        // If the response isn't successful... don't do anything
        if (!response.ok) {
            return callback();
        }

        const token = await response.json();
        localStorage.setItem('cognito_access_token', token.access_token);
        localStorage.setItem('cognito_id_token', token.id_token);
        localStorage.setItem('cognito_expires_at', new Date().getTime() + token.expires_in * 1000);

        Utilities.setCookie('salesseek', `cognito:${token.id_token}`, token.expires_in);

        return callback();
    });

    $.ajaxPrefilter(function (options) {
        /**
         * Set default root URL to API url, rather than local &
         * set credentials.
         */
        var url = options.url || '',
            parts = /^(?:(?:\w+)?:\/\/([^\/]+))?\/(.*)$/.exec(url);
        if (!parts[1]) {
            url = app.options.apiUrl + '/' + parts[2];
            options.url = url;
            options.xhrFields = _.extend({},
                (options.xhrFields || {}), {
                    withCredentials: true
                });
            security.addAPIRequestHeaders(options);
        }
        // Add caching
        options.cache = true;

        // Google Analytics
        if (!_.isUndefined(window.ga) ) {
            var url = options.url.replace(/.*\/\/[^\/]*/, '');
            var start = new Date().getTime();
            var previous_complete = options.complete;

            options.complete = function (jqXHR) {
                var end = new Date().getTime();
                var time = end - start;
                var user = app.user;
                var client_id = '';
                if (user) {
                    client_id = user.get('client').short_id;
                }

                window.ga('set', 'page', url);
                window.ga('send', 'pageview');
                window.ga('send', {
                    'hitType': 'timing',
                    'timingCategory': 'api',
                    'timingVar': client_id,
                    'timingValue': time,
                    'timingLabel': 'api',
                    'page': url
                });

                if (!_.isUndefined(previous_complete)) {
                    previous_complete.apply(this, arguments)
                }
            }
        }
    });


    Handlebars.registerHelper('hasPermission', function(permission, context, options) {
        if (options === undefined) {
            options = context;
            context = this;
        }
        function test() {
            return security.checkPermission(permission, context);
        }
        return Handlebars.helpers['if'].call(this, test, options);
    });

    Handlebars.registerHelper('uriPart', function(uriPart) {
        if (_.isFunction(uriPart)) {
            uriPart = uriPart.call(this);
        }
        return encodeURIComponent(uriPart);
    });

    Handlebars.registerHelper('formatNumber', function(value) {
        return value.toLocaleString();
    });

    Handlebars.registerHelper('formatCurrencyValue', function(currency, value) {
        return Currency.format(currency, value);
    });

    Handlebars.registerHelper('formatSystemCurrencyValue', function(value) {
        return Currency.format(app.user.get('client')['default_currency'], value);
    });

    Handlebars.registerHelper('formatShortDate', function(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        return dateFormat.shortFormat(date);
    });

    Handlebars.registerHelper('formatShortDateWithYear', function(date) {
        return dateFormat.shortFormatWithYear(date);
    });

    Handlebars.registerHelper('formatShortDateWithYearUTC', function(date) {
        return dateFormat.shortFormatWithYearUTC(date);
    });

    Handlebars.registerHelper('formatShortDateWithYearTime', function(date) {
        return dateFormat.shortFormatWithYearTime(date);
    });

    Handlebars.registerHelper('formatScheduledShortFormat', function(date) {
        return dateFormat.scheduledShortFormat(date);
    });

    Handlebars.registerHelper('scheduledFormat', function(date) {
        return dateFormat.scheduledFormat(date);
    });

    Handlebars.registerHelper('formatRawScheduledDate', function(date) {
        return dateFormat.formatRawScheduledDate(date);
    });

    Handlebars.registerHelper('formatDateTime', function(value) {
        if (!_.isDate(value)) {
            return value;
        }
        return value.toLocaleString();
    });

    Handlebars.registerHelper('formatEntityInformationDate', function(date) {
        return dateFormat.entityInformationFormat(date);
    });

    Handlebars.registerHelper('formatTimelineDate', function(date) {
        if (_.isNull(date)) {
            return '-';
        }
        return dateFormat.timelineFormat(date);
    });

    Handlebars.registerHelper('formatTZOffset', function(offsetMinutes) {
        var minutes = offsetMinutes % 60;
        offsetMinutes = offsetMinutes - minutes;
        minutes = Math.abs(minutes);
        if (minutes <= 9) {
            minutes = '0' + minutes;
        }
        return 'GMT' + (offsetMinutes >= 0 ? '+' : '') + offsetMinutes / 60 + ':' + minutes;
    });

    Handlebars.registerHelper('getTZName', function(id) {
        return TimeZoneSelectView.getTZName(id);
    });

    // TODO: each should be used instead of this helper objEach, and the view should convert the object to an array
    // for that. That way the view can enforce things such as order.
    Handlebars.registerHelper('objEach', function(context, options) {
        var ret = '';
        _.each(_.keys(context), function(key) {
            ret = ret + options.fn({key: key, value: context[key]});
        });
        return ret;
    });

    Handlebars.registerHelper('equal', function(lvalue, rvalue, options) {
        if (arguments.length < 3) {
            throw new Error("Handlebars Helper equal needs 2 parameters");
        }
        if (lvalue !== rvalue) {
            return options.inverse(this);
        } else {
            return options.fn(this);
        }
    });

    Handlebars.registerHelper('linkify', function(text) {
        return new Handlebars.SafeString(linkify(text));
    });

    Handlebars.registerHelper('pluralize', function(number, single, plural) {
        return (number === 1) ? single : plural;
    });

    Handlebars.registerHelper('getText', function(id, parameters) {
        var params = [];

        if (_.isString(parameters)) {
            params = parameters.split(';');
        }

        return TextManager.getText(id, params);
    });

    Handlebars.registerHelper('isAppConfigTrue', function(key, options) {
        function test() {
            return AppConfig.getValue(key, false);
        }

        return Handlebars.helpers['if'].call(this, test, options);
    });

    Handlebars.registerHelper('isAppConfigFalse', function(key, options) {
        function test() {
            return !AppConfig.getValue(key, false);
        }

        return Handlebars.helpers['if'].call(this, test, options);
    });

    Handlebars.registerHelper('getAppConfigValue', function(id) {
        return AppConfig.getValue(id);
    });

    Handlebars.registerHelper('parseText', function(text, parameters) {
        var params = [];

        if (_.isString(parameters)) {
            params = parameters.split(';');
        }

        return TextManager.parseText(text, params);
    });

    Handlebars.registerHelper('isLabFeatureEnabled', function(id, options) {
        function test() {
            return _.contains(app.user.get('preferences').lab_flags, id);
        }

        return Handlebars.helpers['if'].call(this, test, options);
    });

    Handlebars.registerHelper('isLabFeatureDisabled', function(id, options) {
        function test() {
            return !_.contains(app.user.get('preferences').lab_flags, id);
        }

        return Handlebars.helpers['if'].call(this, test, options);
    });

    Handlebars.registerHelper('featureTierIs', function(ids, options) {
        function test() {
            return _.contains(_.map((ids || '').split(','), function(o) {
                return o.trim();
            }), app.user.get('client').feature_tier);
        }

        return Handlebars.helpers['if'].call(this, test, options);
    });

    // app theme configuration
    var appTheme = AppConfig.getValue('appTheme');

    if (appTheme) {
        document.body.classList.add(appTheme);
    }

    // Tooltip defaults
    // This removes the animation to prevent double mouseover bug
    $.fn.tooltip.defaults.animation = false;
    $.fn.tooltip.defaults.delay = { show: 400, hide: 0 };
    $.fn.tooltip.defaults.container = 'body';
    // Place arrow above tooltip-inner - prevent box-shadow overlapping arrow
    $.fn.tooltip.defaults.template = [
        '<div class="tooltip">',
        '<div class="tooltip-inner"></div>',
        '<div class="tooltip-arrow"></div>',
        '</div>'
    ].join('');

    // Yo dawg! Scroll locking for scrolls within scrolls
    // From https://gist.github.com/theftprevention/5959411#file-jquery-scrolllock-js
    $.fn.scrollLock=function(){return $(this).on("DOMMouseScroll mousewheel",function(h){var g=$(this),f=this.scrollTop,d=this.scrollHeight,b=g.height(),i=h.originalEvent.wheelDelta,a=i>0,c=function(){h.stopPropagation();h.preventDefault();h.returnValue=false;return false};if(!a&&-i>d-b-f){g.scrollTop(d);return c()}else{if(a&&i>f){g.scrollTop(0);return c()}}})};
    $.fn.scrollRelease=function(){return $(this).off("DOMMouseScroll mousewheel")};
});

/**
 * Object which will handle dirty model management. Particularly when navigating away.
 */
app.dirtyModelHandler = (function() {
    var msgBoxShown = false,
        dirtyList = {},
        confirmationContainer = new (Marionette.Layout.extend({
            template: Handlebars.compile('')
        }))(),
        self;

    app.appConfirmationContainer.show(confirmationContainer);

    confirmationContainer.listenTo(vent, 'settings:on:show', function() {
        self.settingsPopupStatus = 2;
    });

    confirmationContainer.listenTo(vent, 'settings:on:close', function() {
        self.settingsPopupStatus = 1;
    });

    confirmationContainer.listenTo(vent, 'userSettings:on:show', function() {
        self.userSettingsPopupStatus = 2;
    });

    confirmationContainer.listenTo(vent, 'userSettings:on:close', function() {
        self.userSettingsPopupStatus = 1;
    });

    self = {
        /**
         * setting statuses
         * 2 - view open
         * 1 - view just closed, URL not changed
         * 0 - view closed and URL changed accordingly
         */
        settingsPopupStatus: 0,
        userSettingsPopupStatus: 0,
        /**
         * Run this function whenever some view is edited.
         *
         * @param cid   string  corresponding model id. Could be used other ids as well.
         */
        add: function(cid) {
            dirtyList[cid] = true;
        },
        /**
         * Run this function when view is saved.
         *
         * @param cid   string  corresponding model id. Could be used other ids as well.
         */
        remove: function(cid) {
            delete dirtyList[cid];
        },
        _removeAll: function() {
            dirtyList = {};
        },
        /**
         * Run this confirmation before any activity which would change UI to check whether there is unsaved
         * data and so these changes shouldn't be performed.
         *
         * @param scope     pointer     scope for success and cancel callback
         * @param success   function    success callback function
         * @param cancel    function    cancel callback function
         */
        confirm: function(scope, success, cancel) {
            var mbContent = {
                accept_is_negative: true,
                message: 'Unsaved data will be lost. Are you sure you want to navigate away from this page?',
                icon: 'icon-warning'
            };

            // skip confirmation on popups with URL change
            if (this.settingsPopupStatus > 0 || this.userSettingsPopupStatus > 0) {
                return;
            }

            if (_.size(dirtyList) > 0) {
                if (!msgBoxShown) { // fallback check to not show multiple confirmations
                    msgBoxShown = true;
                    MessageBox.showYesNo(
                        mbContent,
                        confirmationContainer,
                        function () {
                            success.call(scope);
                            app.dirtyModelHandler._removeAll();
                            msgBoxShown = false;
                        },
                        function() {
                            if (cancel) {
                                cancel.call(scope);
                            }
                            msgBoxShown = false;
                        }
                    );
                }
            }
            else {
                success.call(scope);
            }
        }
    };

    return self;
})();

export default app;
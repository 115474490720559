import React from 'react'
import classnames from 'classnames'
import _ from 'underscore'
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts'

import app from 'js/app'
import AppConfig from 'app/app-config'
import dateFormat from 'js/utils/date-format'
import GroupElementsCollection from 'js/collections/group_elements'
import PeriodsCollection from 'js/collections/periods'
import Currency from 'js/utils/currency'
import {NewSelect} from 'js/react_views/widgets/select';

import style from './intent.css'


class IconCell extends React.Component {
    render() {
        let icon = 'icon-list';

        switch(this.props.value) {
            case 'call':
                icon = 'icon-phone';
                break;

            case 'meeting':
                icon = 'icon-team-7';
                break;

            case 'email':
                icon = 'icon-email';
                break;
        }

        return (
            <div className={style.icon}><i className={`${icon}`}/></div>
        );
    }
}

class DateCell extends React.Component {
    render() {
        if (!this.props.value) {
            return null;
        }

        return (
            <span>{dateFormat.entityInformationFormat(this.props.value)}</span>
        );
    }
}

class TimeCell extends React.Component {
    render() {
        if (!this.props.value) {
            return null;
        }

        return (
            <span>{dateFormat.shortFormatTime(dateFormat.parseDate(this.props.value))}</span>
        );
    }
}

class BorrowerStatusCell extends React.Component {
    render() {
        return (
            <div className={style.borrowerStatus}>
                <span>{this.props.value}</span>
            </div>
        );
    }
}

class IndividualLinkCell extends React.Component {
    render() {
        const data = this.props.value;

        const showIndividual = function() {
            window.location.href = `/#individuals/${data.individualId}`;
        }

        return (
            <span className={style.link} onClick={showIndividual}>{data.value}</span>
        );
    }
}

class AssignToCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = { assignee: this.props.value.assignee };
    }

    handleAssignToChange(items) {
        if (items) {
            const self = this;

            $.ajax({
                type: 'PATCH',
                url: `/tasks/${this.props.value.taskId}`,
                data: JSON.stringify({ assignee_id: items[0].id }),
                dataType: 'json',
                success: function() {
                    self.setState({ assignee: items[0] });
                }
            });
        }
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <NewSelect
                    url='/users'
                    value={this.state.assignee}
                    options={{minimumInputLength: 1}}
                    onSelect={this.handleAssignToChange.bind(this)}
                />
            </div>
        );
    }
}

class CompletedCell extends React.Component {
    constructor(props) {
        super(props);
        this.state = { completed: this.props.value.completed };
    }

    handleClick(ev) {
        ev.stopPropagation();

        const completed = !this.state.completed;
        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/tasks/${this.props.value.taskId}`,
            data: JSON.stringify({ completed: completed }),
            dataType: 'json',
            success: function() {
                self.setState({ completed: completed });
            }
        });
    }

    render() {
        const classes = classnames({
            'icon-checkmark': this.state.completed,
            [style.active]: this.state.completed,
            'icon-checkmark2': !this.state.completed
        });

        return (
            <div
                className={style.completed}
                onClick={this.handleClick.bind(this)}
            >
                <i className={classes}/>
            </div>
        );
    }
}

class DefaultCell extends React.Component {
    render() {
        return (
            <span className={style.default}>{this.props.value}</span>
        );
    }
}

class Pager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePage: 0,
            numPages: 0
        };
    }

    reset(page, totalRows) {
        this.setState({
            activePage: page,
            numPages: Math.ceil(totalRows / this.props.rowsPerPage)
        });
    }

    render() {
        if (this.state.numPages === 0) {
            return null;
        }

        const firstPage = Math.max(this.state.activePage - 2, 1);
        const lastPage = Math.min(this.state.activePage + 2, this.state.numPages);
        let pages = [];

        for (let i = firstPage; i <= lastPage; ++i) {
            pages.push(i);
        }

        return (
            <div className={style.pager}>
                {this.state.activePage > 1 &&
                    <div>
                        <span
                            className={style.page}
                            style={{marginRight: '8px', fontSize: '12px'}}
                            onClick={() => this.props.gotoPage(1)}
                        >
                            &#9664;
                        </span>
                        <span
                            className={style.page}
                            style={{marginRight: '8px', fontSize: '16px'}}
                            onClick={() => this.props.gotoPage(this.state.activePage - 1)}
                        >
                            &lt;
                        </span>
                    </div>
                }
                <div className={style.pages}>
                    {pages.map((page) => {
                        const classes = classnames({
                            [style.page]: true,
                            [style.active]: page === this.state.activePage
                        });

                        return (
                            <span
                                className={classes}
                                key={`page_${page}`}
                                onClick={() => {
                                    if (this.state.activePage !== page) {
                                        this.props.gotoPage(page)
                                    }
                                }}
                            >
                                {page}
                            </span>
                        )
                    })}
                </div>
                {this.state.activePage < this.state.numPages &&
                    <div>
                        <span
                            className={style.page}
                            style={{marginRight: '8px', fontSize: '16px'}}
                            onClick={() => this.props.gotoPage(this.state.activePage + 1)}
                        >
                            &gt;
                        </span>
                        <span
                            className={style.page}
                            style={{fontSize: '12px'}}
                            onClick={() => this.props.gotoPage(this.state.numPages)}
                        >
                            &#9654;
                        </span>
                    </div>
                }
            </div>
        );
    }
}

const TABLE_NUM_ROWS = 5;

class TableWidget extends React.Component {
    constructor(props) {
        super(props);

        this.setActiveTab = this.setActiveTab.bind(this);
        this.activeTab = null;

        this.state = {
            totalRows: 0,
            rows: []
        };

        this.tabs = ['all', 'call', 'task', 'meeting', 'past due'];
        this.columns = [{
            id: 'type',
            name: 'Type',
            width: 60,
            component: IconCell
        }, {
            id: 'last_name',
            name: 'Last Name',
            width: 125,
            component: IndividualLinkCell
        }, {
            id: 'first_name',
            name: 'First Name',
            width: 125,
            component: IndividualLinkCell
        }, {
            id: 'subject',
            name: 'Subject',
            width: 210
        }, {
            id: 'borrower_status',
            name: 'Status',
            width: 175,
            component: BorrowerStatusCell
        }, {
            id: 'date',
            name: 'Date',
            width: 120,
            component: DateCell
        }, {
            id: 'time',
            name: 'Time',
            width: 90,
            component: TimeCell
        }, {
            id: 'assign_to',
            name: 'Assign To',
            width: 140,
            component: AssignToCell
        }, {
            id: 'completed',
            name: '',
            width: 60,
            component: CompletedCell
        }];
    }

    componentDidMount() {
        this.setActiveTab('all');
    }

    fetchData(page) {
        const self = this;
        const taskType = this.activeTab === 'past due' ? 'all' : this.activeTab;
        const pastDue = this.activeTab === 'past due' ? true : false;

        $.get(`/widgets/intent_tasks?task_type=${taskType}&rows=${TABLE_NUM_ROWS}&page=${page}&past_due=${pastDue}`, function(data) {
            var rows = [];
            var assignee = {
                id: app.user.get('id'),
                title: app.user.get('name')
            };

            _.forEach(data.rows, function(row) {
                rows.push({
                    items: [row.task_type, {individualId: row.individual_id, value: row.individual_last_name}, {individualId: row.individual_id, value: row.individual_first_name},
                            row.task_subject, row.individual_status, row.task_due_date, row.task_due_date, {taskId: row.task_id, assignee: assignee},
                            {taskId: row.task_id, completed: row.task_completed}]
                });
            });

            self.setState({
                rows: rows,
                totalRows: data.total
            });

            self.pager.reset(page, data.total);
        });
    }

    setActiveTab(tab) {
        if (tab === this.activeTab) {
            return;
        }

        this.activeTab = tab;
        this.fetchData(1);
    }

    gotoPage(page) {
        this.fetchData(page);
    }

    render() {
        return (
            <div className={style.tableWidget}>
                <div className={style.tabs}>
                    {this.tabs.map((tab,tabIndex) => { return (
                        <div
                            key={`tab_${tab}`}
                            className={classnames({[style.tab]: true, [style.active]: (this.activeTab === tab) || (!this.activeTab && tabIndex === 0)})}
                            onClick={() => this.setActiveTab(tab)}
                        >
                            <span className={style.tabName}>{tab}</span>
                        </div>
                    )})}
                </div>
                <div className={style.table}>
                    <div className={style.columns}>
                        {this.columns.map((column) => { return (
                            <div
                                key={`column_${column.id}`}
                                className={style.column}
                                style={{ width: column.width + 'px', minWidth: column.width + 'px' }}
                            >
                                <span className={style.name}>{column.name}</span>
                            </div>
                        )})}
                    </div>
                    <div>
                        {this.state.rows.length > 0 && this.state.rows.map((row, index) => { return (
                            <div key={`row_${index}`} className={style.row}>
                                {this.columns.map((column, cindex) => {
                                    const CellComponent = column.component || DefaultCell;
                                    return (
                                        <div
                                            key={`cell_${index}_${cindex}`}
                                            className={style.cell}
                                            style={{ width: column.width + 'px', minWidth: column.width + 'px' }}
                                        >
                                            <CellComponent value={row.items[cindex]}/>
                                        </div>
                                    )
                                })}
                            </div>
                        )})}
                        {this.state.rows.length === 0 &&
                            <div className={style.noRecordsRow}>No records found</div>
                        }
                    </div>
                    <div className={style.footer}>
                        <div className={style.counter}>{`${this.state.totalRows} total`}</div>
                        <Pager
                            ref={(el) => this.pager = el}
                            rowsPerPage={TABLE_NUM_ROWS}
                            gotoPage={this.gotoPage.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


class PieChartWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = { data: props.data || [] };
        this.selectedSpan = props.selectedSpan || props.dropdownOptions[0];

        const self = this;
        _.defer(function() {
            self.props.fetchData(self.selectedSpan.id);
        });
    }

    setData(data) {
        this.setState({ data: data });
    }

    handleSpanChange(items) {
        if (items) {
            this.props.fetchData(items[0].id)
        }
    }

    render() {
        let data = this.state.data;
        let colors = this.props.colors;
        const hasData = _.reduce(this.state.data, (accum, entry) => (accum + entry.value), 0) > 0;

        if (!hasData) {
            data = [{
                id: 'none',
                value: 1
            }];

            colors = ['#f8f8f8'];
        }

        return (
            <div className={style.pieChartWidget}>
                <div className={style.headerArea}>
                    <div className={style.description}>{this.props.description}</div>
                    <NewSelect
                        width={this.props.dropdownWidth || 75}
                        data={this.props.dropdownOptions}
                        value={this.selectedSpan}
                        editViewMod={true}
                        searchViewVisible={false}
                        options={{minimumInputLength: -1}}
                        onSelect={this.handleSpanChange.bind(this)}
                    />
                </div>
                <div className={style.chartArea}>
                    <PieChart
                        width={145}
                        height={152}
                    >
                        <Pie
                            data={data}
                            cx={60}
                            cy={78}
                            innerRadius={35}
                            outerRadius={65}
                            dataKey='value'
                        >
                        {data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>)}
                        </Pie>
                        {hasData && <Tooltip formatter={this.props.formatter}/>}
                    </PieChart>
                    <div className={style.legendList}>
                        {this.state.data.map((entry, index) => {return (
                            <div key={`legend-${index}`} className={style.legendItem}>
                                <div className={style.legendColor} style={{background: this.props.colors[index % this.props.colors.length]}}/>
                                <div className={style.legendName}>{entry.name}</div>
                            </div>
                        )})}
                    </div>
                </div>
            </div>
        );
    }
}


class ValueWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: props.value || '-' };
    }

    setValue(value) {
        this.setState({ value: value });
    }

    render() {
        return (
            <div className={style.valueWidget}>
                <div className={style.iconArea}>
                    <i className={this.props.icon}/>
                </div>
                <div className={style.dataArea}>
                    <div className={style.value}>{this.state.value}</div>
                    <div className={style.description}>{this.props.description}</div>
                </div>
            </div>
        );
    }
}


class YourProgressWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPeriod: '',
            progress: '',
            goal: Currency.format(app.user.get('client').default_currency, app.user.getTotalQuotaValue()),
            percentage: '',
            barWidth: '0%'
        };

        // get current period
        const periods = new PeriodsCollection();
        const self = this;

        periods.fetch({
            success: function() {
                const now = new Date();
                const areQuarters = periods.models.length === 8;

                for (const period of periods.models) {
                    if (new Date(period.get('end_date')) > now) {
                        const startDate = new Date(period.get('start_date') + 'T00:00:00');

                        if (areQuarters) {
                            const quarter =Math.floor(startDate.getMonth() / 3) + 1;
                            self.setState({currentPeriod: `Q${quarter}`});
                        } else {
                            self.setState({currentPeriod: `${dateFormat.shortFormatMonth(startDate.getMonth())}`});
                        }
                        break;
                    }
                }
            }
        });

        // get closed loans value
        let collection = new GroupElementsCollection(null, {elementType: 'opportunities'});

        collection.fetch({
            rows: -1,
            data: {
                owner_id: app.user.get('id'),
                closing_in_period_id: 'current',
                phase_types: 'won'
            },
            success: function(data) {
                let value = 0;

                for (const loan of data.models) {
                    value += loan.get('default_value');
                }

                const quotaValue = app.user.getTotalQuotaValue();
                const normPer = quotaValue > 0 ? (value / quotaValue) : 0;

                self.setState({
                    progress: Currency.format(app.user.get('client').default_currency, value),
                    percentage: `${(normPer * 100).toFixed(2)}%`,
                    barWidth: `${Math.min(normPer, 1) * 100}%`,
                });
            }
        });
    }

    render() {
        return (
            <div className={style.yourProgressWidget}>
                <div className={style.ypwRow} style={{fontSize: '15px'}}>
                    <div>{this.state.currentPeriod}</div>
                    <div style={{marginLeft: 'auto'}}>{this.state.percentage}</div>
                </div>
                <div className={style.ypwProgressBarContainer}>
                    <div className={style.ypwProgressBar} style={{width: this.state.barWidth}}/>
                </div>
                <div className={style.ypwRow} style={{fontSize: '13px'}}>
                    <div>{`Your Progress: ${this.state.progress}`}</div>
                    <div style={{marginLeft: 'auto'}}>{`Your Goal: ${this.state.goal}`}</div>
                </div>
            </div>
        );
    }
}

class TotalValueClosedWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null
        };

        const self = this;

        $.get('/widgets/intent_total_value_closed', function(data) {
            let newData = [
                { name: 'Q1', Past: data.last_Q1, Current: data.current_Q1},
                { name: 'Q2', Past: data.last_Q2, Current: data.current_Q2},
                { name: 'Q3', Past: data.last_Q3, Current: data.current_Q3},
                { name: 'Q4', Past: data.last_Q4, Current: data.current_Q4}
            ];

            self.setState({data: newData});
        });
    }

    render() {
        return (
            <div className={style.totalValueClosedWidget}>
                <div
                    className={style.totalValueClosedWidgetHeaderArea}
                >
                    <div className={style.totalValueClosedWidgetHeaderAreaDescription}>Total Value Closed</div>
                    <div className={style.legend}>
                        <div className={style.legendEntry}>
                            <div className={style.legendBadge} style={{background: '#f79700'}}/>
                            <div className={style.legendTitle}>Past</div>
                        </div>
                        <div className={style.legendEntry}>
                            <div className={style.legendBadge} style={{background: '#3a35d7'}}/>
                            <div className={style.legendTitle}>Current</div>
                        </div>
                    </div>
                </div>
                {this.state.data && <BarChart
                    style={{marginTop: '20px'}}
                    width={600}
                    height={200}
                    data={this.state.data}
                    barGap={10}
                    barCategoryGap={45}
                    barSize={30}
                >
                    <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Bar dataKey="Past" fill="#f79700"/>
                    <Bar dataKey="Current" fill="#3a35d7"/>
                </BarChart>}
            </div>
        );
    }
}

class IntentDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.widgets = {};
    }

    getSumValuesData() {
        const self = this;

        $.get('/widgets/intent_sum_values', function(data) {
            self.widgets.numNewLeads.setValue(data.num_new_leads);
            self.widgets.numOpportunities.setValue(data.num_opportunities);
            self.widgets.numTasks.setValue(data.num_tasks);
            self.widgets.numInProcess.setValue(data.num_in_process);
        });
    }

    getVolumeInStatus(quarter) {
        const self = this;

        $.get(`/widgets/intent_volume_in_status?quarter=${quarter}`, function(data) {
            self.widgets.volumeInStatus.setData([
                { name: 'Started', value: data.started},
                { name: 'Processing', value: data.processing},
                { name: 'Underwriting', value: data.underwriting},
                { name: 'Approval', value: data.approval},
                { name: 'Won', value: data.won},
            ]);
        });
    }

    getCountInStatus(quarter) {
        const self = this;

        $.get(`/widgets/intent_volume_in_status?quarter=${quarter}&count`, function(data) {
            self.widgets.countInStatus.setData([
                { name: 'Started', value: data.started },
                { name: 'Processing', value: data.processing },
                { name: 'Underwriting', value: data.underwriting },
                { name: 'Approval', value: data.approval },
                { name: 'Won', value: data.won },
            ]);
        });
    }

    getConversion(quarter) {
        const self = this;

        $.get(`/widgets/intent_conversion?quarter=${quarter}`, function(data) {
            self.widgets.conversion.setData([
                { name: 'Self Generated', value: data.self_generated },
                { name: 'Lead Generated', value: data.lead_generated },
                { name: 'Referral', value: data.referral }
            ]);
        });
    }

    getLeadTemperature(span) {
        const self = this;

        $.get(`/widgets/intent_lead_temperature?span_time=${span}`, function(data) {
            self.widgets.leadTemperature.setData([
                { name: 'Hot', value: data.hot },
                { name: 'Warm', value: data.warm },
                { name: 'Cold', value: data.cold }
            ]);
        });
    }

    render() {
        const self = this;

        _.defer(function() {
            self.getSumValuesData();
        });

        const statusColors = ['#4563ff', '#ff8402', '#e1143e', '#e619b9', '#06d6a0'];
        const temperatureColors = ['#f53f44', '#ff9902', '#0a38bf'];
        const quarterOptions = [
            {id: 'q1', title: 'Q1'},
            {id: 'q2', title: 'Q2'},
            {id: 'q3', title: 'Q3'},
            {id: 'q4', title: 'Q4'}
        ];
        const timePeriodOptions = [
            {id: 'today', title: 'Day'},
            {id: 'week', title: 'Week'},
            {id: 'month', title: 'Month'},
            {id: 'trimester', title: 'Trimester'},
            {id: 'year', title: 'Year'}
        ];

        const quarterActive = quarterOptions[Math.floor(new Date().getMonth() / 3)];

        return (
            <div className={style.dashboard}>
                <div className={style.dashboardContent}>
                    <div className={style.topWidgets}>
                        <ValueWidget
                            ref={(el) => this.widgets.numNewLeads = el}
                            description='New Leads'
                            icon='icon-paperplane'
                        />
                        <ValueWidget
                            ref={(el) => this.widgets.numOpportunities = el}
                            description='Opportunities'
                            icon='icon-funnel'
                        />
                        <ValueWidget
                            ref={(el) => this.widgets.numTasks = el}
                            description='Tasks'
                            icon='icon-checkmark3'
                        />
                        <ValueWidget
                            ref={(el) => this.widgets.numInProcess = el}
                            description='In-Process'
                            icon='icon-folder-open'
                        />
                    </div>
                    <YourProgressWidget/>
                    <TableWidget/>
                    <div className={style.chartWidgets}>
                        <PieChartWidget
                            ref={(el) => this.widgets.volumeInStatus = el}
                            description='Volume in Status'
                            dropdownOptions={quarterOptions}
                            selectedSpan={quarterActive}
                            colors={statusColors}
                            formatter={(value) => Currency.format(app.user.get('client').default_currency, value)}
                            fetchData={this.getVolumeInStatus.bind(this)}
                        />
                        <PieChartWidget
                            ref={(el) => this.widgets.countInStatus = el}
                            description='Count in Status'
                            dropdownOptions={quarterOptions}
                            selectedSpan={quarterActive}
                            colors={statusColors}
                            fetchData={this.getCountInStatus.bind(this)}
                        />
                        <PieChartWidget
                            ref={(el) => this.widgets.conversion = el}
                            description='Conversion'
                            dropdownOptions={quarterOptions}
                            selectedSpan={quarterActive}
                            colors={statusColors}
                            fetchData={this.getConversion.bind(this)}
                        />
                        <PieChartWidget
                            ref={(el) => this.widgets.leadTemperature = el}
                            description='Lead Temperature'
                            dropdownOptions={timePeriodOptions}
                            dropdownWidth={120}
                            colors={temperatureColors}
                            fetchData={this.getLeadTemperature.bind(this)}
                        />
                    </div>
                    <TotalValueClosedWidget/>
                </div>
            </div>
        );
    }
}

export default IntentDashboard;


import React from 'react';
import _ from 'underscore';
import $ from 'jquery';
import classnames from 'classnames';

import styles from './automation.css';

import Popover from 'js/react_views/popover/popover';
import security from 'js/utils/security'
import AutomationNodeCreateEditView from 'js/react_views/automation/automation-node-create-edit';
import { AutomationDeleteConfirmationView } from 'js/react_views/automation/automation-node-create-edit';
import IndividualFilterModel from 'js/models/individual_filter';
import OrganizationFilterModel from 'js/models/organization_filter';
import OpportunityFilterModel from 'js/models/opportunity_filter';

const stepMapping = {
    next_step_id: 'next_step',
    reject_step_id: 'reject_step'
};

const padding = 100;

const stepTypeMap = {
    event: {
        icon: "&#xe91a;",
        subTypeKey: "event_type",
        subTypeMapByEntity: {
            group_join: {
                icon: "&#xe91b;"
            },
            group_leave: {
                icon: "&#xe92a;"
            },
            filter_join: {
                icon: "&#xe91b;"
            },
            filter_leave: {
                icon: "&#xe92a;"
            },
            campaign_email_opened: {
                icon: "C"
            },
            campaign_email_url_clicked: {
                icon: "C"
            },
            web_registration: {
                icon: "&#xe659;"
            },
            phase_entered: {
                icon: "&#xe929;"
            },
            phase_leave: {
                icon: "&#xe926;"
            },
            individual_created: {
                icon: "&#xe929;"
            },
            individual_updated: {
                icon: "&#xe929;"
            },
            opportunity_created: {
                icon: "&#xe929;"
            },
            opportunity_updated: {
                icon: "&#xe929;"
            },
            organization_created: {
                icon: "&#xe929;"
            },
            organization_updated: {
                icon: "&#xe929;"
            },
            task_created: {
                icon: "&#xe60d;"
            },
            task_updated: {
                icon: "&#xe60d;"
            },
            checklist_completed: {
                icon: "&#xe60d;"
            },
            checklist_item_completed: {
                icon: "&#xe60d;"
            },
            related_individual_added: {
                icon: "&#xe929;"
            },
            related_individual_removed: {
                icon: "&#xe929;"
            },
        }
    },
    action: {
        icon: "&#xe916;",
        subTypeKey: "action_type",
        subTypeMapByEntity: {
            add_checklist: {
                icon: "&#xe60d;"
            },
            remove_checklist: {
                icon: "&#xe60d;"
            },
            remove_all_checklists: {
                icon: "&#xe60d;"
            },
            create_activity: {
                icon: "&#xe669;"
            },
            create_task: {
                icon: "&#xe60d;"
            },
            join_group: {
                icon: "&#xe928;"
            },
            leave_group: {
                icon: "&#xe927;"
            },
            update_individual: {
                icon: "&#xe635;" // update
            },
            create_individual: {
                icon: "&#xe606;" // update
            },
            update_organization: {
                icon: "&#xe635;" // update
            },
            create_organization: {
                icon: "&#xe606;" // update
            },
            update_opportunity: {
                icon: "&#xe635;" // update
            },
            update_related: {
                icon: "&#xe635;" // update
            },
            create_opportunity: {
                icon: "&#xe606;" // update
            },
            send_campaign: {
                icon: "&#xe66e;"
            },
            rest_post: {
                icon: "&#xe665;"
            }
        }
    },
    wait: {
        icon: "&#xe921;"
    },
    call: {
        icon: "&#xe918;"
    }
};

class AutomationGraphView extends React.Component {
    constructor(props) {
        super(props);

        // debugging handle
        window.automation = this;

        this.state = {
            scale: 1,
            SVGMarginLeft: 0,
            fullScreen: false,
            mergeNodeMap: {}
        };

        this.step = 10;

        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
        this.updateScale = this.updateScale.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.doMerge = this.doMerge.bind(this);

        // need to debounce to catch edge button MouseOver
        this.handleEdgeMouseLeave = _.debounce(this.handleEdgeMouseLeave.bind(this));
        this.handleEdgeButtonMouseLeave = _.debounce(this.handleEdgeButtonMouseLeave.bind(this));

        this.showNewAutomationNodePopup = this.showNewAutomationNodePopup.bind(this);
        this.hideNewAutomationNodePopup = this.hideNewAutomationNodePopup.bind(this);
        this.hideAutomationNodePopup = this.hideAutomationNodePopup.bind(this);
        this.updateAutomationNodeData = this.updateAutomationNodeData.bind(this);
        this.createAutomationNode = this.createAutomationNode.bind(this);
        this.updateAutomationNode = this.updateAutomationNode.bind(this);
        this.showAutomationDeleteConfirmationStep = this.showAutomationDeleteConfirmationStep.bind(this);
        this.removeAutomationNode = this.removeAutomationNode.bind(this);
        this.hideAutomationNodeDeleteConfirmPopup = this.hideAutomationNodeDeleteConfirmPopup.bind(this);
    }

    componentWillReceiveProps () {
        this.edgeButtonHovered = null;
        this.setState({
            hoveredId: null,
            hoverPoint: null,
            mergeNodeMap: {},
            selectedMergeNodeId: null,
            disabledLeaveMergeNodes: null
        });
    }

    showNewAutomationNodePopup (data) {
        this.newAutomationNodeContextData = data;
        this.setState({
            newAutomationNodePopupVisible: true
        });
    }

    hideNewAutomationNodePopup () {
        this.setState({
            newAutomationNodePopupVisible: false,
            errorField: null
        });
    }

    hideAutomationNodePopup () {
        this.setState({
            editingStep: false,
            automationEdited: false,
            errorField: null
        });
    }

    hideAutomationNodeDeleteConfirmPopup () {
        this.setState({
            deleteConfirmationStep: false
        });
    }

    updateAutomationNodeData(data) {
        this.automationNode = data;

        if (!_.isEmpty(data)) {
            this.setState({
                automationEdited: true
            });
        }
    }

    createAutomationNode() {
        const callback = (data) => {
            if (data && data.detail && data.detail.exception) {
                this.setState({
                    errorField: data.detail.key
                });
            }
            else {
                this.setState({
                    automationEdited: false,
                    newAutomationNodePopupVisible: false,
                    errorField: null
                });
            }
        };

        let insert;
        if (this.newAutomationNodeContextData.parentId === 'root') {
            insert = {
                id: this.props.automation.id,
                at: 'automation'
            }
        }
        else {
            insert = {
                id: this.newAutomationNodeContextData.parentId,
                at: stepMapping[this.newAutomationNodeContextData.parentStep]
            }
        }

        this.props.createAutomationNode($.extend(this.automationNode, { insert }), callback);
    }

    updateAutomationNode() {
        const callback = (data) => {
            if (data && data.detail && data.detail.exception) {
                this.setState({
                    errorField: data.detail.key
                });
            }
            else {
                this.setState({
                    automationEdited: false,
                    editingStep: false,
                    errorField: null
                });
            }
        };

        this.props.updateAutomationNode(this.automationNode, callback);
    }

    removeAutomationNode() {
        this.setState({
            deleteConfirmationStep: false,
            editingStep: false
        });

        this.props.removeAutomationNode(this.state.editingStep.id);
    }

    showAutomationDeleteConfirmationStep() {
        this.setState({
            deleteConfirmationStep: true
        });
    }

    removeNode(removeNode) {
        const idx = this.list.findIndex(node => {
            return removeNode.id === node.id;
        });
        this.list.splice(idx, 1);
        delete this.nodeMap[removeNode.id];
    }

    handleMouseMove(e) {
         if(this.curDown) {
             this.container.scrollLeft = this.container.scrollLeft + (this.curXPos - e.pageX);
             this.container.scrollTop = this.container.scrollTop + (this.curYPos - e.pageY);
             this.curYPos = e.pageY;
             this.curXPos = e.pageX;
         }
    }

    handleMouseDown(e) {
        this.curYPos = e.pageY;
        this.curXPos = e.pageX;
        this.curDown = true;
    }

    handleMouseUp() {
        this.curDown = false;
    }

    handleMouseLeave() {
        this.curDown = false;
    }

    handleWheel(ev) {
        if (ev.ctrlKey) {
            const deltaY = ev.deltaY;
            this.updateScale(deltaY);
            ev.preventDefault();
        }
    }

    zoomIn() {
        this.updateScale(-1);
    }

    zoomOut() {
        this.updateScale(1);
    }

    updateScale(direction) {
        let scale = this.state.scale + (direction > 0 ? -0.1 : 0.1);
        scale = Math.min(Math.max(scale, 0.3), 1); // clamp value

        let marginLeft = 0;

        if (this.container) {
            if (this.SVGWidth * scale < this.container.offsetWidth) {
                marginLeft = -this.SVGWidth / 2;
            }
            else {
                marginLeft = -this.container.offsetWidth / (2 * scale);
            }
        }

        this.setState({
            scale: scale,
            SVGMarginLeft: marginLeft
        });
    }

    handleEdgeMouseOver(ev, idx, i, targetNode) {
        if (!this.hasEditPermission) {
            return;
        }

        this.edgeHovered =  true;

        if (this.state.hoveredId && (this.state.hoveredId.idx !== idx || this.state.hoveredId.i !== i)) {
            this.edgeTransistion = true;
        }

        // don't redraw buttons when moving mouse inside edge
        if (!this.edgeButtonHovered || this.edgeTransistion) {
            if (targetNode.buttonNode) {
                this.setState({
                    hoveredId: {
                        idx: idx,
                        i: i
                    }
                });
            }
            else {
                let x = (ev.pageX - $(this.svg).offset().left) / this.state.scale; // !!! offsetX does not work in FF
                let y = (ev.pageY - $(this.svg).offset().top) / this.state.scale;
                let dist = 100;
                let closestLine = null;

                // find the closest line
                this.lineMap[idx + ' ' + i].forEach(line => {
                    // horizontal line
                    if (!_.isUndefined(line.x2)) {
                        // check if fits
                        if ((line.x2 - line.x1) > 7 * this.step) {
                            if (Math.abs(line.y - y) < dist) {
                                dist = Math.abs(line.y - y);
                                closestLine = line;
                            }
                        }
                    }
                    // vertical line
                    else {
                        // check if fits
                        if ((line.y2 - line.y1) > 4 * this.step) {
                            if (Math.abs(line.x - x) < dist) {
                                dist = Math.abs(line.x - x);
                                closestLine = line;
                            }
                        }
                    }
                });

                // align position according to line
                const edgePadding = 2 * this.step;
                const arrowEdgePadding = 3 * this.step;
                const buttonHeight = 2.4 * this.step;
                const buttonWidth = 5.4 * this.step;
                let doNotShowArrowLeft, doNotShowArrowRight;
                // horizontal line
                if (!_.isUndefined(closestLine.x2)) {
                    y = closestLine.y;
                    // to close to left side
                    if (x - closestLine.x1 < edgePadding) {
                        x = closestLine.x1 + edgePadding;
                    }
                    // to close to right side
                    else if (closestLine.x2 - x < edgePadding + buttonWidth - 2 * this.step) {
                        x = closestLine.x2 - edgePadding - buttonWidth + 2 * this.step;
                    }
                    // arrow does't fit on left
                    if (x - closestLine.x1 < arrowEdgePadding) {
                        doNotShowArrowLeft = true;
                    }
                    // arrow does't fit on right
                    else if (closestLine.x2 - x < arrowEdgePadding + buttonWidth - 2 * this.step) {
                        doNotShowArrowRight = true;
                    }
                }
                // vertical line
                else {
                    x = closestLine.x;
                    // to close to top
                    if (y - closestLine.y1 < edgePadding) {
                        y = closestLine.y1 + edgePadding;
                    }
                    // to close to bottom
                    else if (closestLine.y2 - y < edgePadding + buttonHeight - 2 * this.step) {
                        y = closestLine.y2 - edgePadding - buttonHeight + 2 * this.step;
                    }
                }

                this.setState({
                    hoveredId: {
                        idx: idx,
                        i: i
                    },
                    hoverPoint: {
                        x: x,
                        y: y
                    },
                    hoverLineLeftToRight: !doNotShowArrowRight && closestLine.leftToRight,
                    hoverLineRightToLeft: !doNotShowArrowLeft && closestLine.rightToLeft
                });
            }
        }
    }

    handleEdgeMouseLeave() {
        if (!this.hasEditPermission) {
            return;
        }

        if (this.edgeTransistion) {
            this.edgeTransistion = false;
        }
        else {
            this.edgeHovered = null;
            if (!this.edgeButtonHovered) {
                this.setState({
                    hoveredId: null,
                    hoverPoint: null
                });
            }
        }
    }

    handleEdgeButtonMouseOver() {
        if (!this.hasEditPermission) {
            return;
        }

        this.edgeButtonHovered = true;
    }

    handleEdgeButtonMouseLeave() {
        if (!this.hasEditPermission) {
            return;
        }

        if (this.edgeTransistion) {
            this.edgeTransistion = false;
        }

        this.edgeButtonHovered = null;
        if (!this.edgeHovered) {
            this.setState({
                hoveredId: null,
                hoverPoint: null
            });
        }
    }

    handleEdgeRemoveButtonMouseOver() {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState({ highlightRemoveEdge: true });
    }

    handleEdgeRemoveButtonMouseLeave() {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState({ highlightRemoveEdge: false });
    }

    handlePlusClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        this.showNewAutomationNodePopup({ parentId: node.parentId, parentStep: node.parentStep });
    }

    handleMergeClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        // do nothing if selected merge node is ancestor to clicked node
        if (this.state.disabledLeaveMergeNodes && (node.id in this.state.disabledLeaveMergeNodes)) {
            return;
        }

        this.setState(prevState => {
            const map = _.extend({}, prevState.mergeNodeMap);
            if (node.id in map) {
                delete map[node.id];
            }
            else {
                map[node.id] = { id: node.id,  parentStep: node.parentStep, parentId: node.parentId };
            }
            return { mergeNodeMap: map };
        });
    }

    handleMergeMergeClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState(prevState => {
            if (prevState.selectedMergeNodeId === node.id) {
                return {
                    selectedMergeNodeId: null,
                    disabledLeaveMergeNodes: null
                };
            }
            else {
                let disabled = {};
                let childUsed;
                const dfs = (node) => {
                    _.each(['next_step_id', 'reject_step_id', 'next_step_id'], step => {
                        const nextId = node[step];
                        if (nextId && !(nextId in disabled)) {
                            // do nothing if child is selected for merge
                            if (nextId in this.state.mergeNodeMap) {
                                childUsed = true;
                            }
                            disabled[nextId] = true;
                            dfs(this.nodeMap[nextId]);
                        }
                    });
                };
                dfs(node);

                if (childUsed) {
                    return;
                }

                return {
                    selectedMergeNodeId: node.id,
                    disabledLeaveMergeNodes: disabled
                };
            }
        });
    }

    handleBranchNodeClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState({
            editingStep: node
        });
    }

    handleMergeNodeClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState({
            editingStep: node
        });
    }

    handleActionNodeClick(ev, node) {
        if (!this.hasEditPermission) {
            return;
        }

        this.setState({
            editingStep: node
        });
    }

    handleEntitiesCounterClick(ev, node) {
        ev.stopPropagation();

        var automation = this.props.automation;
        var filterModel = null;
        var filterField = null;
        var url = null;

        switch(automation.entity_type) {
            case 'individuals':
                filterField = 'individual_automation2';
                filterModel = IndividualFilterModel;
                url = '#contacts/individuals';
                break;

            case 'organizations':
                filterField = 'organization_automation2';
                filterModel = OrganizationFilterModel;
                url = '#contacts/organizations';
                break;

            case 'opportunities':
                filterField = 'opportunity_automation2';
                filterModel = OpportunityFilterModel;
                url = '#deals/active';
                break;
        }

        if (filterModel) {
            var rules = [{
                field: filterField,
                operator: 'equal',
                values: {
                    automation2: {
                        id: automation.id,
                        name: automation.name,
                    },
                    automation2_step: {
                        id: node.id,
                        name: node.name
                    }
                }
            }];

            var filter = new filterModel();
            filter.save({
                rules: [rules]
            }, {
                alert: false,
                success: function(data) {
                    window.location = `${url}?filter_id=${data.id}`;
                }
            });
        }
    }

    doMerge() {
        const callback = () => {};

        if (this.state.selectedMergeNodeId) {
            const insert = {
                at: "child_steps",
                // list of parents ... I would have not called it like that
                child_steps: _.map(this.state.mergeNodeMap, node => ({ id: node.parentId, at: node.parentStep })).concat(this.nodeMap[this.state.selectedMergeNodeId].parents)
            };

            this.props.updateAutomationNode($.extend({
                id: this.state.selectedMergeNodeId
            },{ insert }), callback);
        }
        else {
            const insert = {
                at: "child_steps",
                // list of parents ... I would have not called it like that
                child_steps: _.map(this.state.mergeNodeMap, node => ({ id: node.parentId, at: node.parentStep }))
            };

            this.props.createAutomationNode($.extend({
                step_type: "merge",
                name: "merge"
            }, { insert }), callback);
        }
    }

    handleEdgePlusClick(ev, edge) {
        if (!this.hasEditPermission) {
            return;
        }

        this.showNewAutomationNodePopup({ parentId: edge.parentNode.id, parentStep: edge.step });
    }

    handleEdgeRemoveClick(edge) {
        if (!this.hasEditPermission) {
            return;
        }

        this.props.updateAutomationNode({ id: edge.parentNode.id, [edge.step]: null });
    }

    toggleFullScreen() {
        this.setState(prevState => {
            return {
                fullScreen: !prevState.fullScreen
            }
        })
    }

    componentDidMount() {
        this.hasEditPermission = security.checkPermission('edit', this.props.automation);
        // need to rerender for marginLeft calculation
        this.forceUpdate();
    }

    componentDidUpdate() {
        this.hasEditPermission = security.checkPermission('edit', this.props.automation);
    }

    adjustZoom() {
        let width = this.SVGWidth - (padding * 2);
        let scale = 1;

        while ((width > this.container.offsetWidth) && (scale > 0.3)) {
            scale -= 0.1;
            width = this.SVGWidth * scale;
        }

        this.setState({
            scale: scale,
            SVGMarginLeft: -(this.SVGWidth / 2)
        });
    }

    render() {
        const step = this.step;
        const nodeWidth = 10 * step;
        const nodeHeight = 13 * step;
        const lineWidthSpacing = 2 * step;
        const lineHeightSpacing = 5 * step;
        const lineExtraHeightSpacing = 2 * step;
        const lineAngleRadius = step;
        const extraEdgeEndShort = step;
        const extraEdgeEnd = 3 * step;
        const extraEdgeBeginning = 6 * step;
        const topPadding = -8 * step;

        let maxDepth = 0;

        const map = this.props.automation.stepMap;
        this.list = this.props.automation.steps || [];
        this.nodeMap = {};

        this.list.forEach(node => {
            node.depth = 0;
            this.nodeMap[node.id] = node;
        });

        const updateDepth = (id, depth) => {
            maxDepth = Math.max(maxDepth, depth);

            // safety for broken data
            if (!map[id]) {
                return;
            }

            if (map[id].depth < depth) {
               map[id].depth = depth;
            }

            if (!(map[id].step_type === 'branch')) {
                if (map[id].next_step_id) {
                    updateDepth(map[id].next_step_id, depth + 1);
                }
            }
            else {
                if (map[id].next_step_id) {
                    updateDepth(map[id].next_step_id, depth + 1);
                }
                if (map[id].reject_step_id) {
                    updateDepth(map[id].reject_step_id, depth + 1);
                }
            }
        };

        updateDepth("root", 0);

        let visitedMap = {};
        const countChild = (id, left, parentId) => {
            let counts;

            visitedMap[id] = true;

            map[id].left = 0;
            map[id].right = 0;

            map[id].structuralParentId = parentId;

            if (map[id].step_type !== 'branch') {
                if (map[id].next_step_id && !visitedMap[map[id].next_step_id]) {
                    if (map[map[id].next_step_id] && map[map[id].next_step_id].depth - map[id].depth === 1) {
                        counts = countChild(map[id].next_step_id, left, id);
                        map[id].left = counts.left;
                        map[id].right = counts.right;
                    }
                }
            }
            else {
                if (map[id].next_step_id && !visitedMap[map[id].next_step_id]) {
                    if (map[map[id].next_step_id] && map[map[id].next_step_id].depth - map[id].depth === 1) {
                        counts = countChild(map[id].next_step_id, left, id);
                        map[id].left = counts.left + counts.right + 1;
                    }
                }
                if (map[id].reject_step_id && !visitedMap[map[id].reject_step_id]) {
                    if (map[map[id].reject_step_id] && map[map[id].reject_step_id].depth - map[id].depth === 1) {
                        counts = countChild(map[id].reject_step_id, left + map[id].left + 1, id);
                        map[id].right = counts.left + counts.right + 1;
                    }
                }
            }

            map[id].absLeft = left + map[id].left;

            return {
                left: map[id].left,
                right: map[id].right
            }
        };

        countChild("root", 0);

        // count horizontal lines between levels
        let spacingMapList = [], targetNode;
        this.list.forEach(node => {
            if (node.next_step_id && map[node.next_step_id] && (node.step_type === 'branch')) {
                targetNode = map[node.next_step_id];
                if (!spacingMapList[targetNode.depth]) {
                    spacingMapList[targetNode.depth] = {};
                }

                if (targetNode.parentId === node.id) {
                    // no bottom horizontal line for direct edge
                }
                else {
                    if (!spacingMapList[targetNode.depth][targetNode.id]) {
                        spacingMapList[targetNode.depth][targetNode.id] = _.size(spacingMapList[targetNode.depth]) + 1;
                    }
                }
            }
            if (node.reject_step_id && map[node.reject_step_id]) {
                targetNode = map[node.reject_step_id];

                if (!spacingMapList[targetNode.depth]) {
                    spacingMapList[targetNode.depth] = {};
                }

                if (targetNode.parentId === node.id) {
                    // no bottom horizontal line for direct edge
                }
                else {
                    if (!spacingMapList[targetNode.depth][targetNode.id]) {
                        spacingMapList[targetNode.depth][targetNode.id] = _.size(spacingMapList[targetNode.depth]) + 1;
                    }
                }
            }
            if (node.next_step_id && map[node.next_step_id] && !(node.step_type === 'branch')) {
                targetNode = map[node.next_step_id];

                if (!spacingMapList[targetNode.depth]) {
                    spacingMapList[targetNode.depth] = {};
                }

                if (targetNode.parentId === node.id) {
                    // no bottom horizontal line for direct edge
                }
                else {
                    if (!spacingMapList[targetNode.depth][targetNode.id]) {
                        spacingMapList[targetNode.depth][targetNode.id] = _.size(spacingMapList[targetNode.depth]) + 1;
                    }
                }
            }
        });

        let accumulativeSpacingList = [0], total = 0;
        spacingMapList.forEach((map, idx) => {
            const size = _.size(map);
            total += size > 1 ? size - 1 : 0;
            accumulativeSpacingList[idx] = total;
        });

        let verticalLines = [],
            horizontalLines = [],
            crossings = [],
            edges = [],
            mappedCrossings = {},
            parentCount = {};

        const width = (map["root"].left + map["root"].right) * (nodeWidth + lineWidthSpacing) + nodeWidth + 2 * padding;
        this.SVGWidth = width;
        const height = (maxDepth + 1) * nodeHeight + maxDepth * lineHeightSpacing + accumulativeSpacingList[maxDepth] * lineExtraHeightSpacing;

        // build nodes
        let nodesToWrap = [];

        const nodeObjs = this.list.map((node, idx) => {
            const x = node.absLeft * (nodeWidth + lineWidthSpacing) + padding;
            const y = node.depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[node.depth] * lineExtraHeightSpacing + topPadding;
            let nodeSVG;

            // root node
            if (node.id === 'root') {
                nodeSVG = <g>
                            <text className={styles.start} x={5 * step} y={10 * step + 20} textAnchor="middle">Start</text>
                        </g>;
            }
            // branch node
            else if (node.step_type === 'branch') {
                nodeSVG = <g className={styles.branchNode} onClick={ev => { this.handleBranchNodeClick(ev, node); }}>
                        <circle cx={5 * step} cy={5 * step} r={1.2 * step} fill="#8da7c1" />
                        <text className={styles.icon} x={-5 * step} y={-5 * step + 7} textAnchor="middle" transform="rotate(180)" fill="white">&#xe628;</text>
                        {this.props.enableTitles && <text x={2 * step} y={2 * step + 5} textAnchor="middle" fill="red"> {node.id} </text>}
                        <text x={2 * step} y={5 * step - 5 } textAnchor="start" fill="#00d554"> Y </text>
                        <text x={8 * step} y={5 * step - 5 } textAnchor="end" fill="#ff203b"> N </text>
                        <text ref={(el) => {if (el) {nodesToWrap.push({el: el, maxWidth: 200})}}} className={styles.nodeTitle} y={10 * step} textAnchor="middle" fill="#404040">
                            <title id="title">{node.name}</title>
                            <tspan id="first-line" x={5 * step}></tspan>
                            <tspan id="second-line" x={5 * step} dy="17"></tspan>
                        </text>
                    </g>;
            }
            // merge node
            else if (node.step_type === 'merge') {
                if (this.state.selectedMergeNodeId === node.id) {
                    nodeSVG = <g className={styles.buttonContainerMerging}>
                        <g className={styles.mergeButton} onClick={ev => { this.handleMergeMergeClick(ev, node); }}>
                            <circle cx={5 * step} cy={5 * step} r={1.2 * step} className={styles.highlight} />
                            <text className={styles.icon} x={5 * step} y={5 * step + 7} textAnchor="middle"
                                  fill="white">&#xe925;</text>
                        </g>
                    </g>
                }
                else {
                    nodeSVG = <g className={styles.buttonContainer}>
                        <rect className={styles.narrowRect} x={3.8 * step} y={3.8 * step} width={2.4 * step}
                              height={2.4 * step}/>
                        <rect className={styles.wideRect} x={3.8 * step} y={3.8 * step} width={5.4 * step}
                              height={2.4 * step}/>
                        <g className={styles.mergeNode} onClick={ev => { this.handleMergeNodeClick(ev, node); }}>
                            <circle cx={5 * step} cy={5 * step} r={1.2 * step} />
                            <text className={styles.icon} x={-5 * step} y={-5 * step + 5} textAnchor="middle" transform="rotate(180)" fill="white">&#xe627;</text>
                        </g>;
                        <g className={styles.mergeButton} onClick={ev => { this.handleMergeMergeClick(ev, node); }}>
                            <circle cx={8 * step} cy={5 * step} r={1.2 * step} fill="#c7c7cc"/>
                            <text className={styles.icon} x={8 * step} y={5 * step + 7} textAnchor="middle"
                                  fill="white">&#xe925;</text>
                        </g>
                    </g>
                }
            }
            // button node
            else if (node.buttonNode) {
                if ((_.size(this.state.mergeNodeMap) + !!this.state.selectedMergeNodeId) > 0) {
                    const highlight = node.id in this.state.mergeNodeMap;
                    nodeSVG = <g className={styles.buttonContainerMerging}>
                        <g className={styles.mergeButton} onClick={ev => { this.handleMergeClick(ev, node); }}>
                            <circle cx={5 * step} cy={5 * step} r={1.2 * step} className={highlight ? ' ' + styles.highlight : ''} />
                            <text className={styles.icon} x={5 * step} y={5 * step + 7} textAnchor="middle"
                                  fill="white">&#xe925;</text>
                        </g>
                    </g>;
                }
                else {
                    nodeSVG = <g className={styles.buttonContainer}>
                        <rect className={styles.narrowRect} x={3.8 * step} y={3.8 * step} width={2.4 * step}
                              height={2.4 * step}/>
                        <rect className={styles.wideRect} x={3.8 * step} y={3.8 * step} width={5.4 * step}
                              height={2.4 * step}/>
                        <g className={styles.plusButton} onClick={(ev) => {this.handlePlusClick(ev, node)}}>
                            <circle cx={5 * step} cy={5 * step} r={1.2 * step}/>
                            <text className={styles.icon} x={5 * step + 0.5} y={5 * step + 6}
                                  textAnchor="middle">&#xe606;</text>
                        </g>
                        <g className={styles.mergeButton} onClick={ev => { this.handleMergeClick(ev, node); }}>
                            <circle cx={8 * step} cy={5 * step} r={1.2 * step} fill="#c7c7cc"/>
                            <text className={styles.icon} x={8 * step} y={5 * step + 7} textAnchor="middle"
                                  fill="white">&#xe925;</text>
                        </g>
                    </g>;
                }
            }
            // action node
            else {
                const stepInfo = this.props.automation.steps.find(step => step.id === node.id);
                let entitiesCount = stepInfo ? stepInfo.count : null;

                if (entitiesCount > 99) {
                    entitiesCount = '99+';
                }

                nodeSVG = <g className={styles.actionNode} onClick={ev => { this.handleActionNodeClick(ev, node); }}>
                        <circle className={styles.largeCircle} cx={5 * step} cy={5 * step} r={3 * step} />
                        <text className={styles.largeIcon} x={5 * step} y={5 * step + 12} textAnchor="middle" fontSize="200%"
                            dangerouslySetInnerHTML={{ __html: stepTypeMap[node.step_type].icon }} />
                        {
                            stepTypeMap[node.step_type].subTypeMapByEntity &&
                            <g>
                                <circle className={styles.smallCircle} cx={7 * step} cy={7 * step} r={1.2 * step} />
                                <text className={styles.smallIcon} x={7 * step + 1} y={7 * step + 6} textAnchor="middle"
                                    dangerouslySetInnerHTML={{ __html: stepTypeMap[node.step_type].subTypeMapByEntity[node[stepTypeMap[node.step_type].subTypeKey]].icon }} />
                            </g>
                        }
                        <text ref={(el) => {if (el) {nodesToWrap.push({el: el, maxWidth: 100})}}} className={styles.nodeTitle} y={10 * step} textAnchor="middle" fill="#404040">
                            <title id="title">{node.name}</title>
                            <tspan id="first-line" x={5 * step}></tspan>
                            <tspan id="second-line" x={5 * step} dy="17"></tspan>
                        </text>
                        {entitiesCount &&
                            <g onClick={ev => { this.handleEntitiesCounterClick(ev, node); }}>
                                <circle cx={7 * step} cy={3 * step} r={1.2 * step} fill="#ff0000"/>
                                <text x={7 * step + 0} y={3 * step + 5} textAnchor="middle" fill="white">{entitiesCount}</text>
                            </g>
                        }
                    </g>;
            }

            return <svg className={styles.nodeContainer} key={"node" + idx} x={x} y={y} width={nodeWidth + 'px'} height={nodeHeight + 'px'}>
                    {nodeSVG}
                    {this.props.enableTitles && <text x={2 * step} y={2 * step + 5} textAnchor="middle" fill="red"> {node.id} </text>}
                </svg>;
        });

        const self = this;

        _.defer(function() {
            self.wrapNodesText(nodesToWrap);
        });

        // build edges
        const edgeObjs = this.list.map((node, idx) => {
            const x = node.absLeft * (nodeWidth + lineWidthSpacing) + padding;
            const y = node.depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[node.depth] * lineExtraHeightSpacing + topPadding;

            let points = [], e = [];
            if (node.next_step_id && (node.step_type === 'branch') && map[node.next_step_id]) {
                targetNode = map[node.next_step_id];

                parentCount[targetNode.id] ? parentCount[targetNode.id]++ : parentCount[targetNode.id] = 1;

                const targetX = targetNode.absLeft * (nodeWidth + lineWidthSpacing) + padding;
                const targetY = targetNode.depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[targetNode.depth] * lineExtraHeightSpacing + topPadding;

                let extraEnd = 0;
                if (targetNode.step_type === 'branch' || targetNode.step_type === 'merge' || targetNode.buttonNode) {
                    extraEnd = extraEdgeEnd;
                }
                else {
                    extraEnd = extraEdgeEndShort;
                }

                // if (node.absLeft - targetNode.absLeft === 1)
                if (targetNode.structuralParentId === node.id && node.absLeft - targetNode.absLeft > 0) {
                    points[1] = { x: x + extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: targetX + nodeWidth/2 + lineAngleRadius, y: points[1].y };
                    points[3] = { x: targetX + nodeWidth/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 1 });
                    horizontalLines.push({ y: points[1].y, x1: points[2].x, x2: points[1].x, idx: idx, i: 1, rightToLeft: true });

                    e[1] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y,
                        arrowPosition: points[5]
                    };
                }
                else if (node.absLeft < targetNode.absLeft) {
                    points[1] = { x: x + extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: x - lineWidthSpacing/2 + lineAngleRadius, y: points[1].y };
                    points[3] = { x: x - lineWidthSpacing/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[6] = { x: points[5].x, y: targetY - lineHeightSpacing/2 - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[7] = { x: x, y: points[6].y };
                    points[8] = { x: targetX + nodeWidth/2 - lineAngleRadius, y: points[7].y };
                    points[9] = { x: targetX + nodeWidth/2, y: points[8].y };
                    points[10] = { x: points[9].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[11] = { x: points[10].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 1 });
                    verticalLines.push({ x: points[9].x, y1: points[10].y, y2: points[11].y, idx: idx, i: 1 });
                    horizontalLines.push({ y: points[6].y, x1: points[7].x, x2: points[8].x, idx: idx, i: 1, leftToRight: true });

                    e[1] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y +
                        " S " + points[9].x + ", " + points[9].y + "," + points[10].x + "," + points[10].y +
                        " L " + points[11].x + ", " + points[11].y,
                        arrowPosition: points[11]
                    };
                }
                else {
                    points[1] = { x: x + extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: x - lineWidthSpacing/2 + lineAngleRadius, y: points[1].y };
                    points[3] = { x: x - lineWidthSpacing/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[6] = { x: points[5].x, y: targetY - lineHeightSpacing/2 - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1)};
                    points[7] = { x: x - lineWidthSpacing/2 - lineAngleRadius, y: points[6].y };
                    points[8] = { x: targetX + nodeWidth/2 + lineAngleRadius, y: points[7].y };
                    points[9] = { x: targetX + nodeWidth/2, y: points[8].y };
                    points[10] = { x: points[9].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[11] = { x: points[10].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 1 });
                    verticalLines.push({ x: points[9].x, y1: points[10].y, y2: points[11].y, idx: idx, i: 1 });
                    horizontalLines.push({ y: points[6].y, x1: points[8].x, x2: points[7].x, idx: idx, i: 1, rightToLeft: true });

                    e[1] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y +
                        " S " + points[9].x + ", " + points[9].y + "," + points[10].x + "," + points[10].y +
                        " L " + points[11].x + ", " + points[11].y,
                        arrowPosition: points[11]
                    };
                }
            }

            if (node.reject_step_id && map[node.reject_step_id]) {
                targetNode = map[node.reject_step_id];

                parentCount[targetNode.id] ? parentCount[targetNode.id]++ : parentCount[targetNode.id] = 1;

                const targetX = targetNode.absLeft * (nodeWidth + lineWidthSpacing) + padding;
                const targetY = targetNode.depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[targetNode.depth] * lineExtraHeightSpacing + topPadding;

                let extraEnd = 0;
                if (targetNode.step_type === 'branch' || targetNode.step_type === 'merge' || targetNode.buttonNode) {
                    extraEnd = extraEdgeEnd;
                }
                else {
                    extraEnd = extraEdgeEndShort;
                }

                // if (targetNode.absLeft - node.absLeft === 1)
                if (targetNode.structuralParentId === node.id && targetNode.absLeft - node.absLeft > 0) {
                    points[1] = { x: x + nodeWidth - extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: targetX + nodeWidth/2 - lineAngleRadius, y: points[1].y };
                    points[3] = { x: targetX + nodeWidth/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 2 });
                    horizontalLines.push({ y: points[1].y, x1: points[1].x, x2: points[2].x, idx: idx, i: 2, leftToRight: true });

                    e[2] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'reject_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y,
                        arrowPosition: points[5]
                    };
                }
                else if (node.absLeft < targetNode.absLeft) {
                    points[1] = { x: x + nodeWidth - extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: x + nodeWidth + lineWidthSpacing/2 - lineAngleRadius, y: points[1].y };
                    points[3] = { x: x + nodeWidth + lineWidthSpacing/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[6] = { x: points[5].x, y: targetY - lineHeightSpacing/2 - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[7] = { x: x + nodeWidth + lineWidthSpacing/2 + lineAngleRadius, y: points[6].y };
                    points[8] = { x: targetX + nodeWidth/2 - lineAngleRadius, y: points[7].y };
                    points[9] = { x: targetX + nodeWidth/2, y: points[8].y };
                    points[10] = { x: points[9].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[11] = { x: points[10].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 2 });
                    verticalLines.push({ x: points[9].x, y1: points[10].y, y2: points[11].y, idx: idx, i: 2 });
                    horizontalLines.push({ y: points[6].y, x1: points[7].x, x2: points[8].x, idx: idx, i: 2, leftToRight: true });

                    e[2] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'reject_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y +
                        " S " + points[9].x + ", " + points[9].y + "," + points[10].x + "," + points[10].y +
                        " L " + points[11].x + ", " + points[11].y,
                        arrowPosition: points[11]
                    };

                }
                else {
                    points[1] = { x: x + nodeWidth - extraEdgeEnd, y: y + 5 * step };
                    points[2] = { x: x + nodeWidth + lineWidthSpacing/2 - lineAngleRadius, y: points[1].y };
                    points[3] = { x: x + nodeWidth + lineWidthSpacing/2, y: points[2].y };
                    points[4] = { x: points[3].x, y: y + 5 * step + lineAngleRadius };
                    points[5] = { x: points[4].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[6] = { x: points[5].x, y: targetY - lineHeightSpacing/2 - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[7] = { x: x + nodeWidth + lineWidthSpacing/2 - lineAngleRadius, y: points[6].y };
                    points[8] = { x: targetX + nodeWidth/2 + lineAngleRadius, y: points[7].y };
                    points[9] = { x: targetX + nodeWidth/2, y: points[8].y };
                    points[10] = { x: points[9].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[11] = { x: points[10].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[3].x, y1: points[4].y, y2: points[5].y, idx: idx, i: 2 });
                    verticalLines.push({ x: points[9].x, y1: points[10].y, y2: points[11].y, idx: idx, i: 2 });
                    horizontalLines.push({ y: points[6].y, x1: points[8].x, x2: points[7].x, idx: idx, i: 2, rightToLeft: true });

                    e[2] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'reject_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y +
                        " S " + points[9].x + ", " + points[9].y + "," + points[10].x + "," + points[10].y +
                        " L " + points[11].x + ", " + points[11].y,
                        arrowPosition: points[11]
                    };
                }
            }

            if (node.next_step_id && !(node.step_type === 'branch') && map[node.next_step_id]) {
                targetNode = map[node.next_step_id];

                parentCount[targetNode.id] ? parentCount[targetNode.id]++ : parentCount[targetNode.id] = 1;

                const targetX = targetNode.absLeft * (nodeWidth + lineWidthSpacing) + padding;
                const targetY = targetNode.depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[targetNode.depth] * lineExtraHeightSpacing + topPadding;

                let extraEnd = 0;
                if (targetNode.step_type === 'branch' || targetNode.step_type === 'merge' || targetNode.buttonNode) {
                    extraEnd = extraEdgeEnd;
                }
                else {
                    extraEnd = extraEdgeEndShort;
                }

                let extraBeginning = 0;
                if (node.step_type === 'merge') {
                    extraBeginning = extraEdgeBeginning;
                }

                if (targetNode.absLeft === node.absLeft) {
                    points[1] = { x: x + nodeWidth/2, y: y + nodeHeight - extraBeginning };
                    points[2] = { x: points[1].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[1].x, y1: points[1].y, y2: points[2].y, idx: idx, i: 3 });

                    e[3] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y,
                        arrowPosition: points[2]
                    };
                }
                else if (node.absLeft < targetNode.absLeft) {
                    points[1] = { x: x + nodeWidth/2, y: y + nodeHeight - extraBeginning };
                    points[2] = { x: points[1].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius };
                    points[3] = { x: points[2].x, y: targetY - lineHeightSpacing/2 - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[4] = { x: x + nodeWidth/2 + lineAngleRadius, y: points[3].y };
                    points[5] = { x: targetX + nodeWidth/2 - lineAngleRadius, y: points[4].y };
                    points[6] = { x: targetX + nodeWidth/2, y: points[5].y };
                    points[7] = { x: points[6].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius - lineExtraHeightSpacing * (spacingMapList[targetNode.depth][targetNode.id] - 1) };
                    points[8] = { x: points[7].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[1].x, y1: points[1].y, y2: points[2].y, idx: idx, i: 3 });
                    verticalLines.push({ x: points[6].x, y1: points[7].y, y2: points[8].y, idx: idx, i: 3 });
                    horizontalLines.push({ y: points[3].y, x1: points[4].x, x2: points[5].x, idx: idx, i: 3, leftToRight: true });

                    e[3] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " + points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y,
                        arrowPosition: points[8]
                    };
                }
                else {
                    points[1] = { x: x + nodeWidth/2, y: y + nodeHeight - extraBeginning };
                    points[2] = { x: points[1].x, y: targetY - lineHeightSpacing/2 - lineAngleRadius };
                    points[3] = { x: points[2].x, y: targetY - lineHeightSpacing/2 };
                    points[4] = { x: x + nodeWidth/2 - lineAngleRadius, y: points[3].y };
                    points[5] = { x: targetX + nodeWidth/2 + lineAngleRadius, y: points[4].y };
                    points[6] = { x: targetX + nodeWidth/2, y: points[5].y };
                    points[7] = { x: points[6].x, y: targetY - lineHeightSpacing/2 + lineAngleRadius };
                    points[8] = { x: points[7].x, y: targetY + extraEnd };

                    verticalLines.push({ x: points[1].x, y1: points[1].y, y2: points[2].y, idx: idx, i: 3 });
                    verticalLines.push({ x: points[6].x, y1: points[7].y, y2: points[8].y, idx: idx, i: 3 });
                    horizontalLines.push({ y: points[3].y, x1: points[5].x, x2: points[4].x, idx: idx, i: 3, rightToLeft: true });

                    e[3] = {
                        parentNode: node,
                        targetNode: targetNode,
                        step: 'next_step_id',
                        d: "M " +points[1].x + ", " + points[1].y +
                        " L " + points[2].x + ", " + points[2].y +
                        " S " + points[3].x + ", " + points[3].y + "," + points[4].x + "," + points[4].y +
                        " L " + points[5].x + ", " + points[5].y +
                        " S " + points[6].x + ", " + points[6].y + "," + points[7].x + "," + points[7].y +
                        " L " + points[8].x + ", " + points[8].y,
                        arrowPosition: points[8]
                    }
                }
            }

            edges.push([0,e[1],e[2],e[3]]);

            return <g key={"edges" + idx}>
                    {
                        e[1] &&
                        <g className={styles.edge}>
                            <path className={styles.hoverPart} d={e[1].d}
                                  onMouseOver={ev => { this.handleEdgeMouseOver(ev, idx, 1, e[1].targetNode) }}
                                  onMouseLeave={this.handleEdgeMouseLeave.bind(this)}/>
                            <path className={styles.core} d={e[1].d}/>
                            <text className={styles.icon + ' ' + styles.arrow} x={e[1].arrowPosition.x} y={e[1].arrowPosition.y} textAnchor="middle" >&#xe656;</text>
                        </g>
                    }
                    {
                        e[2] &&
                        <g className={styles.edge}>
                            <path className={styles.hoverPart} d={e[2].d}
                                  onMouseOver={ev => { this.handleEdgeMouseOver(ev, idx, 2, e[2].targetNode) }}
                                  onMouseLeave={this.handleEdgeMouseLeave.bind(this)}/>
                            <path className={styles.core} d={e[2].d}/>
                            <text className={styles.icon + ' ' + styles.arrow} x={e[2].arrowPosition.x} y={e[2].arrowPosition.y} textAnchor="middle" >&#xe656;</text>
                        </g>
                    }
                    {
                        e[3] &&
                        <g className={styles.edge}>
                            <path className={styles.hoverPart} d={e[3].d}
                                  onMouseOver={ev => { this.handleEdgeMouseOver(ev, idx, 3, e[3].targetNode) }}
                                  onMouseLeave={this.handleEdgeMouseLeave.bind(this)}/>
                            <path className={styles.core} d={e[3].d}/>
                            <text className={styles.icon + ' ' + styles.arrow} x={e[3].arrowPosition.x} y={e[3].arrowPosition.y} textAnchor="middle" >&#xe656;</text>
                        </g>
                    }
                </g>
        });

        // build edge line map
        this.lineMap = {};
        horizontalLines.forEach(hLine => {
            const id = hLine.idx + ' ' + hLine.i;
            if (!this.lineMap[id]) {
                this.lineMap[id] = [];
            }
            this.lineMap[id].push(hLine);
        });
        verticalLines.forEach(vLine => {
            const id = vLine.idx + ' ' + vLine.i;
            if (!this.lineMap[id]) {
                this.lineMap[id] = [];
            }
            this.lineMap[id].push(vLine);
        });

        // build crossings
        horizontalLines.forEach(hLine => {
            const id = hLine.idx + ' ' + hLine.i;
            mappedCrossings[id] = [];
            verticalLines.forEach(vLine => {
                if (vLine.x > hLine.x1 && vLine.x < hLine.x2 && hLine.y > vLine.y1 && hLine.y < vLine.y2) {
                    crossings.push({ x: vLine.x, y: hLine.y });
                    mappedCrossings[id].push({ x: vLine.x, y: hLine.y });
                }
            })
        });
        const crossingObjs = crossings.map((crossing, idx) => {
            return <svg className={styles.crossing} key={"crossing" + idx} pointerEvents="none">
                    <circle cx={crossing.x} cy={crossing.y} r={step} fill="white" />
                    <path d={"M " + crossing.x + ", " + (crossing.y-step) + " L " + crossing.x + ", " + (crossing.y+step)} />
                    <path d={"M " + (crossing.x - step) + " " + crossing.y + " A " + step + " " + step + " 0 0 1 " + (crossing.x + step) + " " + crossing.y} fill="none" strokeDasharray="4,5" />
                </svg>
        });

        let hoveredEdgeObj;
        if (this.state.hoveredId) {
            const hoveredEdge = edges[this.state.hoveredId.idx][this.state.hoveredId.i];
            hoveredEdgeObj = <g className={styles.highlightEdge + (this.state.highlightRemoveEdge ? (' ' + styles.highlightRemoveEdge) : '')}>
                <path className={styles.core} d={hoveredEdge.d} />
                <text className={styles.icon + ' ' + styles.arrow} x={hoveredEdge.arrowPosition.x} y={hoveredEdge.arrowPosition.y} textAnchor="middle" >&#xe656;</text>
                {
                    Object.keys(mappedCrossings[this.state.hoveredId.idx + ' ' + this.state.hoveredId.i] || {}).map((key, idx) => {
                        const crossing = mappedCrossings[this.state.hoveredId.idx + ' ' + this.state.hoveredId.i][key];
                        return <svg className={styles.crossing} key={"crossing" + idx} pointerEvents="none">
                                <circle cx={crossing.x} cy={crossing.y} r={step} fill="white" />
                                <path d={"M " + crossing.x + ", " + (crossing.y-step) + " L " + crossing.x + ", " + (crossing.y+step)} />
                                <path className={styles.arc} d={"M " + (crossing.x - step) + " " + crossing.y + " A " + step + " " + step + " 0 0 1 " + (crossing.x + step) + " " + crossing.y} fill="none" strokeDasharray="4,5" />
                            </svg>
                    })
                }
                {
                    this.state.hoverPoint &&
                    <svg x={this.state.hoverPoint.x - 3 * step} y={this.state.hoverPoint.y - 2 * step}
                         className={styles.buttonContainer}
                         onMouseOver={ev => { this.handleEdgeButtonMouseOver(ev) }}
                         onMouseLeave={this.handleEdgeButtonMouseLeave.bind(this)}>
                        <rect className={styles.narrowRect} x={1.8 * step} y={0.8 * step} width={2.4 * step}
                              height={2.4 * step}/>
                        <rect className={styles.wideRect} x={1.8 * step} y={0.8 * step} width={5.4 * step}
                              height={2.4 * step}/>
                        <g className={styles.plusButton} onClick={ev => { this.handleEdgePlusClick(ev, hoveredEdge); }}>
                            <circle cx={3 * step} cy={2 * step} r={1.2 * step}/>
                            <text className={styles.icon} x={3 * step + 0.5} y={2 * step + 6}
                                  textAnchor="middle">&#xe606;</text>
                        </g>
                        {this.state.hoverLineRightToLeft && <text className={styles.icon + ' ' + styles.arrow} x={6} y={2 * step + 6} textAnchor="middle" >&#xe658;</text>}
                        {this.state.hoverLineLeftToRight && <text className={styles.icon + ' ' + styles.arrow} x={8 * step} y={2 * step + 6} textAnchor="middle" >&#xe654;</text>}
                        {
                            (parentCount[hoveredEdge.targetNode.id] > 1) &&
                            <g className={styles.removeButton}
                               onClick={this.handleEdgeRemoveClick.bind(this, hoveredEdge)}
                               onMouseOver={this.handleEdgeRemoveButtonMouseOver.bind(this)}
                               onMouseLeave={this.handleEdgeRemoveButtonMouseLeave.bind(this)}>
                                <circle cx={6 * step} cy={2 * step} r={1.2 * step} fill="#c7c7cc"/>
                                <text className={styles.icon} x={6 * step} y={2 * step + 6} textAnchor="middle">&#xe634;</text>
                            </g>
                        }
                    </svg>
                }
            </g>;
        }

        let horizontalLineObjs, verticalLineObjs, fineHorizontalLineObjs, fineVerticalLineObjs;
        if (this.props.enableGrid) {
            horizontalLineObjs = _.range(maxDepth + 1).map(depth => {
                const y = depth * (nodeHeight + lineHeightSpacing) + accumulativeSpacingList[depth] * lineExtraHeightSpacing  + topPadding;
                return <g key={"horizontal" + y}>
                    <line className={styles.gridLine} x1="0" y1={y + 1} x2={width} y2={y + 1}/>
                    <line className={styles.gridLine} x1="0" y1={y + nodeHeight} x2={width}
                          y2={y + nodeHeight}/>
                </g>
            });
            verticalLineObjs = _.range(map["root"].left + map["root"].right + 1).map(position => {
                const x = position * (nodeWidth + lineWidthSpacing) + padding;
                return <g key={"vertical" + x}>
                    <line className={styles.gridLine} x1={x} y1="0" x2={x} y2={height} />
                    <line className={styles.gridLine} x1={x + nodeWidth} y1="0" x2={x + nodeWidth} y2={height} />
                </g>
            });

            fineHorizontalLineObjs = _.range(Math.round(height/step)).map(j => {
                const y = j * step + topPadding;
                return <g key={"fHorizontal" + y}>
                    <line className={styles.fineGridLine} x1="0" y1={y} x2={width} y2={y}/>
                </g>
            });
            fineVerticalLineObjs = _.range(Math.round(width/step)).map(j => {
                const x = j * step;
                return <g key={"fVertical" + x}>
                    <line className={styles.fineGridLine} x1={x} y1="0" x2={x} y2={height} />
                </g>
            });
        }

        const fullScreenIcon = this.state.fullScreen ? <i className="icon-resize-shrink"></i> : <i className="icon-resize-enlarge"></i>;

        const graphClasses = classnames({
            [styles.AutomationGraphView]: true,
            [styles.fullScreen]: this.state.fullScreen,
            [styles.disabled]: !security.checkPermission('edit', this.props.automation)
        });

        return (
            <div className={graphClasses}
                 onWheel={ev => { this.handleWheel(ev) }}>
                <div style={{ width: "100%", height: "100%", overflow: "auto" }} ref={(input) => { this.container = input; }}>
                    <div style={{ width: "0", height: "0", overflow: "visible", margin: "0 auto" }}>
                        <div style={{ transformOrigin: "top left", transform: "scale(" + this.state.scale + ")" }}>
                            <svg ref={(input) => { this.svg = input; }} style={{ marginLeft: this.state.SVGMarginLeft + "px" }}
                                 onMouseMove={this.handleMouseMove}
                                 onMouseDown={this.handleMouseDown}
                                 onMouseUp={this.handleMouseUp}
                                 onMouseLeave={this.handleMouseLeave}
                                 width={width}
                                 height={height}
                                >

                                { fineHorizontalLineObjs }
                                { fineVerticalLineObjs }
                                { horizontalLineObjs }
                                { verticalLineObjs }

                                { nodeObjs }
                                { edgeObjs }
                                { crossingObjs }
                                { hoveredEdgeObj }
                            </svg>
                        </div>
                    </div>
                </div>

                <div className={styles.actionPanel}>
                    <div className={styles.fullScreenButton} onClick={this.toggleFullScreen}>
                        { fullScreenIcon }
                    </div>
                    <div className={styles.zoomContainer}>
                        <div className={styles.zoomIn} onClick={this.zoomIn}>
                            <i className="icon-zoom-in"></i>
                        </div>
                        <div className={styles.zoomOut} onClick={this.zoomOut}>
                            <i className="icon-zoom-out"></i>
                        </div>
                    </div>
                    {
                        (_.size(this.state.mergeNodeMap) + !!this.state.selectedMergeNodeId) > 1 &&
                        <div className={styles.mainMergeButton} onClick={this.doMerge}>
                            Merge
                        </div>
                    }
                </div>

                {
                    this.state.newAutomationNodePopupVisible &&
                    <Popover
                            width={600}
                            header={{
                                title: "New Node",
                                onClose: this.hideNewAutomationNodePopup,
                                onSave: this.createAutomationNode
                            }}
                        >
                        <AutomationNodeCreateEditView
                            filterFields={this.props.filterFields}
                            createUpdateFieldsAll={this.props.createUpdateFieldsAll}
                            updateAutomationNodeData={this.updateAutomationNodeData}
                            showFilter={this.props.showFilter}
                            removeFilterRule={this.props.removeFilterRule}
                            showDateTime={this.props.showDateTime}
                            showDate={this.props.showDate}
                            entityType={this.props.automation.entity_type}
                            DDSearch={this.props.DDSearch}
                            errorField={this.state.errorField}
                        />
                    </Popover>
                }

                {
                    this.state.editingStep && !this.state.deleteConfirmationStep &&
                    <Popover
                            width={600}
                            header={{
                                onClose: this.hideAutomationNodePopup,
                                onSave: this.state.automationEdited && this.updateAutomationNode,
                                onDeleteIcon: this.showAutomationDeleteConfirmationStep
                            }}
                        >
                        <AutomationNodeCreateEditView
                                step={this.state.editingStep}
                                filterFields={this.props.filterFields}
                                createUpdateFieldsAll={this.props.createUpdateFieldsAll}
                                updateAutomationNodeData={this.updateAutomationNodeData}
                                showFilter={this.props.showFilter}
                                removeFilterRule={this.props.removeFilterRule}
                                showDateTime={this.props.showDateTime}
                                showDate={this.props.showDate}
                                entityType={this.props.automation.entity_type}
                                DDSearch={this.props.DDSearch}
                                errorField={this.state.errorField}
                            />
                    </Popover>
                }
                {
                    this.state.deleteConfirmationStep &&
                    <Popover
                            width={600}
                            height={300}
                            header={{
                                onClose: this.hideAutomationNodeDeleteConfirmPopup,
                                onDelete: !(this.state.editingStep.parents && (_.size(this.state.editingStep.parents) > 1)) && this.removeAutomationNode
                            }}
                        >
                        <AutomationDeleteConfirmationView cannotDelete={this.state.editingStep.parents && (_.size(this.state.editingStep.parents) > 1)}/>
                    </Popover>
                }
            </div>
        )
    }

    getComputedTextLength(element) {
        if (document.body.contains(element)) {
            return element.getComputedTextLength();
        }

        return 0;
    }

    wrapNodesText(nodesToWrap) {
        if (_.isEmpty(nodesToWrap)) {
            return;
        }

        let aux = $(nodesToWrap[0].el).find('#first-line');
        aux.text('...');

        const ellipsisWidth = this.getComputedTextLength(aux[0]);
        const self = this;

        var lineWithEllipsis = function(line, text, availableWidth) {
            for (var c = 0; c < text.length; ++c) {
                const prevText = line.text();

                line.text(line.text() + text.charAt(c));
                let textWidth = self.getComputedTextLength(line[0]);

                if (textWidth > (availableWidth - ellipsisWidth)) {
                    line.text(prevText + '...');
                    break;
                }
            }
        };

        _.forEach(nodesToWrap, function(node, idx) {
            let text = $(node.el);
            let firstLine = text.find('#first-line');
            let secondLine = text.find('#second-line');
            const caption = text.find('#title').text();
            const availableWidth = node.maxWidth;

            firstLine.text(caption);
            secondLine.text('');

            const captionWidth = self.getComputedTextLength(firstLine[0]);

            if (captionWidth > availableWidth) {
                let words = caption.split(' ').filter(Boolean);
                let wordsWidth = [];

                _.forEach(words, function(word) {
                    firstLine.text(word);
                    wordsWidth.push(self.getComputedTextLength(firstLine[0]));
                });

                firstLine.text('');

                if (wordsWidth[0] > availableWidth) {
                    lineWithEllipsis(firstLine, words[0], availableWidth);
                } else {
                    // first line
                    for (var w = 0; w < words.length; ++w) {
                        const prevText = firstLine.text();

                        firstLine.text(prevText + (w > 0 ? ' ' : '') + words[w]);
                        let textWidth = self.getComputedTextLength(firstLine[0]);

                        if (textWidth > availableWidth) {
                            firstLine.text(prevText);
                            break;
                        }
                    }

                    // second line
                    const secondLineText = words.slice(w).join(' ');
                    secondLine.text(secondLineText);

                    if (self.getComputedTextLength(secondLine[0]) > availableWidth) {
                        secondLine.text('');
                        lineWithEllipsis(secondLine, secondLineText, availableWidth);
                    }
                }
            }
        });
    }
}

export default AutomationGraphView;
import React from 'react';
import ReactDOM from 'react-dom';

import app from 'js/app';
import MessageBox from 'js/views/message_box';
import Table from 'app_v2/components/table/table';
import {DropdownField, NumberField, TextField, CheckboxField, ButtonField} from 'js/react_views/common_components/common';
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import currencyFormat from 'js/utils/currency_format';
import pdfGenerator from './pdf_generator';

import style from './choices.css';


const REQUESTED_CHOICES_TABLE_COLUMNS = [{
    id: 'image',
    type: 'image',
    width: 40,
    clickable: true
}, {
    id: 'category',
    title: 'Category',
    type: 'text',
    width: 220,
}, {
    id: 'option',
    title: 'Option Name',
    type: 'text',
    width: 320,
}, {
    id: 'quantity',
    title: 'Quantity',
    type: 'text',
    width: 80
}, {
    id: 'price',
    title: 'Price',
    type: 'text',
    width: 100,
    editable: (row) => {
        return row.isPOA && !row.foc;
    },
    initialEditValue: (row) => {
        return row.priceAsNumber || '';
    },
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'total',
    title: 'Total',
    type: 'text',
    width: 100,
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'foc',
    title: 'FOC',
    type: 'bool',
    width: 50,
    editable: true,
    visible: (row) => {
        return !row.summaryInfo;
    }
}, {
    id: 'notes',
    title: 'Notes',
    type: 'comment'
}, {
    id: 'actions',
    type: 'actions',
    width: 55,
    actions: {
        edit: true,
        delete: true
    }
}];

const REQUESTED_CHOICES_TABLE_COLUMNS_POA_ITEMS = [{
    id: 'image',
    type: 'image',
    width: 40,
    clickable: true
}, {
    id: 'category',
    title: 'Category',
    type: 'text',
    width: 220,
}, {
    id: 'option',
    title: 'Option Name',
    type: 'text',
    width: 320,
}, {
    id: 'quantity',
    title: 'Quantity',
    type: 'text',
    width: 80
}, {
    id: 'price',
    title: 'Price',
    type: 'text',
    width: 100,
    editable: (row) => {
        return row.isPOA
    },
    initialEditValue: (row) => {
        return row.priceAsNumber || '';
    }
}, {
    id: 'total',
    title: 'Total',
    type: 'text',
    width: 100
}, {
    id: 'notes',
    title: 'Notes',
    type: 'comment'
}];

const REQUESTED_CHOICES_TABLE_COLUMNS_REQUESTED_ITEMS = [{
    id: 'image',
    type: 'image',
    width: 40,
    clickable: true
}, {
    id: 'category',
    title: 'Category',
    type: 'text',
    width: 220,
}, {
    id: 'option',
    title: 'Option Name',
    type: 'text',
    width: 320,
}, {
    id: 'quantity',
    title: 'Quantity',
    type: 'text',
    width: 80
}, {
    id: 'price',
    title: 'Price',
    type: 'text',
    width: 100,
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'total',
    title: 'Total',
    type: 'text',
    width: 100,
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'foc',
    title: 'FOC',
    type: 'bool',
    width: 50,
    visible: (row) => {
        return !row.summaryInfo;
    }
}, {
    id: 'notes',
    title: 'Notes',
    type: 'comment'
}, {
    id: 'actions',
    type: 'actions',
    width: 55,
    actions: {
        edit: true
    }
}];

const ORDERS_PLACED_TABLE_COLUMNS = [{
    id: 'image',
    type: 'image',
    width: 40,
    clickable: true
}, {
    id: 'category',
    title: 'Category',
    type: 'text',
    width: 190,
}, {
    id: 'option',
    title: 'Option Name',
    type: 'text',
    width: 300,
}, {
    id: 'quantity',
    title: 'Quantity',
    type: 'text',
    width: 80
}, {
    id: 'price',
    title: 'Price',
    type: 'text',
    width: 100,
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'total',
    title: 'Total',
    type: 'text',
    width: 100,
    style: (row) => {
        return { textDecoration: row.foc ? 'line-through' : 'none' };
    }
}, {
    id: 'foc',
    title: 'FOC',
    type: 'bool',
    width: 50,
    visible: (row) => {
        return !row.summaryInfo && !row.isPOA;
    }
}, {
    id: 'submitted',
    title: 'Last Updated',
    type: 'date',
    width: 120,
}, {
    id: 'notes',
    title: 'Notes',
    type: 'comment'
}];


const getChoiceImageUrl = (choiceId) => {
    // return `https://public.salesseek.net/${app.user.get('client').short_id}/Choices%20Images/${choiceId}.jpg`;
    return `https://public.salesseek.net/persimmonhomes/Choices%20Images/${choiceId}.jpg`;
}


const updateSubtotal = (room) => {
    let summaryRow = room.items.find(row => row.summaryInfo);
    let subTotal = 0;

    for (const item of room.items) {
        if (item.foc) {
            continue;
        }

        subTotal += item.totalAsNumber || 0;
    }

    if (summaryRow) {
        if (room.items.length === 1) { // the only row is the summary row
            room.items = [];
        } else {
            summaryRow.total = currencyFormat('GBP', subTotal);
        }
    } else if (room.items.length > 0) {
        room.items.push({
            summaryInfo: {
                titleCellId: 'category',
                valueCellId: 'total'
            },
            category: 'Category Sub-Total',
            total: currencyFormat('GBP', subTotal)
        });
    }
}


export class SubmitPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {}
        };
    }

    componentDidMount() {
        this.last4Digits.focus();
    }

    handleSubmit() {
        let errors = {};

        if (!this.last4Digits.getValue()) {
            errors.last4Digits = 'This field is required';
        }

        const amountPaid = this.amountPaid.getValue();

        if (!_.isNumber(amountPaid)) {
            errors.amountPaid = 'This field is required';
        }

        this.setState({
            errors: errors
        });

        if (_.isEmpty(errors)) {
            this.props.onClose({
                last_4_digits: this.last4Digits.getValue(),
                amount_paid: amountPaid,
                notes: this.notesField.getValue() || ''
            });
        }
    }

    render() {
        return (
            <div className={style.popoverBackdrop}>
                <div
                    className={style.popover}
                    style={{width: '530px'}}
                >
                    <div className={style.pHeader}>
                        <div
                            className={style.pButton}
                            onClick={() => this.props.onClose(null)}
                        >
                            Close
                        </div>

                        <div className={style.pTitle}>Submit to COINS</div>
                    </div>

                    <div
                        className={style.pContent}
                        style={{padding: '20px'}}
                    >
                        <TextField
                            ref={(el) => this.last4Digits = el}
                            label='Enter last 4 digits bank card'
                            fullInputBox={true}
                            error={this.state.errors.last4Digits}
                        />

                        <NumberField
                            ref={(el) => this.amountPaid = el}
                            label='Amount paid'
                            fullInputBox={true}
                            error={this.state.errors.amountPaid}
                        />

                        <TextField
                            ref={(el) => this.notesField = el}
                            label='Notes'
                            placeholder='Write something here'
                            fullInputBox={true}
                        />

                        <div className={style.pControls}>
                            <div
                                className={style.pSave}
                                onClick={this.handleSubmit.bind(this)}
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class RejectPopover extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reason: ''
        };
    }

    componentDidMount() {
        this.textarea.focus();
    }

    handleRejection() {
        this.props.onClose(this.state.reason);
    }

    handleReasonChange(ev) {
        this.setState({
            reason: ev.target.value
        });
    }

    render() {
        return (
            <div className={style.popoverBackdrop}>
                <div className={style.rejectPopover}>
                    <div className={style.poHeader}>
                        <div
                            className={style.poButton}
                            onClick={() => this.props.onClose(null)}
                        >
                            Close
                        </div>

                        <div className={style.poTitle}>
                            Reject Wishlist
                        </div>
                    </div>

                    <div style={{padding: '15px'}}>
                        <div className={style.poContent}>
                            <div className={style.poText}>Reason for rejection</div>

                            <textarea
                                ref={(el) => this.textarea = el}
                                className={style.poTextarea}
                                rows='5'
                                value={this.state.reason}
                                onChange={this.handleReasonChange.bind(this)}
                            />

                            <div
                                style={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#E8EBED',
                                    margin: '15px 0'
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: '0 15px'
                                }}
                            >
                                <div
                                    className={style.poButton}
                                    onClick={this.handleRejection.bind(this)}
                                >
                                    Reject Wishlist
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class ChoicePopover extends React.Component {
    constructor(props) {
        super(props);

        let activeCategory = null;
        let activeOption = null;
        let notes = '';
        let quantity = 0;
        let options = [];

        if (this.props.itemId) {
            const item = this.props.room.items.find(r => r.id === this.props.itemId);

            activeCategory = this.props.room.categories.find(c => c.id === item.categoryId);

            if (props.submitted) {
                activeOption = activeCategory.items.find(i => i.id === item.choiceId);
            } else {
                activeOption = activeCategory.items.find(i => i.id === item.id);
            }

            notes = item.notes;
            quantity = item.quantityAsNumber;
            options = activeCategory.items.filter(i => !props.room.items.find(ei => ei.id === i.id) || i.id === props.itemId);
        }

        this.activeCategory = activeCategory;
        this.notes = notes;
        this.quantity = quantity;
        this.options = options;

        this.state = {
            activeOption: activeOption,
            errors: {}
        };
    }

    handleCategoryChange(_, itemId) {
        const category = this.props.room.categories.find(c => c.id === itemId);

        this.activeCategory = category;
        this.optionDropdown.setValue(null);

        let items = category.items;

        // the options for already created items are not available (unless we are editing an existing item)
        items = items.filter(i => !this.props.room.items.find(ei => ei.id === i.id) || i.id === this.props.itemId);

        this.optionDropdown.setData(items, null);
    }

    handleOptionChange(_, itemId) {
        let option = null;

        if (itemId) {
            const category = this.props.room.categories.find(c => c.id === this.activeCategory.id);
            option = category.items.find(i => i.id === itemId);
        }

        this.setState({
            activeOption: option
        });
    }

    handleSave() {
        let errors = {};

        if (!this.activeCategory) {
            errors.category = 'Category is required';
        }

        if (!this.state.activeOption) {
            errors.option = 'Option is required';
        }

        if (this.state.activeOption?.quantity_based && !this.quantityField.getValue()) {
            errors.quantity = 'Quantity is required';
        }

        this.setState({
            errors: errors
        });

        if (!_.isEmpty(errors)) {
            return;
        }

        // ...
        const goOn = () => {
            let data = {
                category: this.activeCategory,
                option: this.state.activeOption,
                notes: this.notesField.getValue() || ''
            };

            if (this.state.activeOption.quantity_based) {
                const quantity = this.quantityField.getValue();
                data.quantity = _.isNumber(quantity) ? quantity : 1;
            } else {
                data.quantity = 1;
            }

            this.props.onClose(this.props.itemId, data);
        }

        if ((this.props.buildProgress > parseInt(this.state.activeOption.cutoff_stage)) && !this.props.itemId) {
            const mbContent = {
                message: 'The item has passed the cutoff stage. Are you sure you want to proceed?',
                icon: 'icon-warning'
            };

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                goOn();
            });
        } else {
            goOn();
        }
    }

    render() {
        const title = `${this.props.model ? 'Edit' : 'Create'} Choice (${this.props.room.name})`;
        let quantityLabel = null;

        if (this.state.activeOption) {
            if (this.state.activeOption.quantity_based) {
                quantityLabel = `Quantity`; // (${this.state.activeOption.unit})`;
            }
        }

        return (
            <div className={style.popoverBackdrop}>
                <div className={style.popover}>
                    <div className={style.pHeader}>
                        <div
                            className={style.pButton}
                            onClick={() => this.props.onClose()}
                        >
                            Close
                        </div>

                        <div className={style.pTitle}>{title}</div>
                    </div>

                    <div className={style.pContent}>
                        {!this.props.submitted &&
                            <DropdownField
                                label='Category'
                                placeholder='Select Category'
                                fullInputBox={true}
                                options={this.props.room.categories}
                                value={this.activeCategory}
                                text='description'
                                error={this.state.errors.category}
                                dropdownWidth={455}
                                disabled={this.props.readonly}
                                onValueChange={this.handleCategoryChange.bind(this)}
                            />
                        }

                        {!this.props.submitted &&
                            <DropdownField
                                ref={(el) => this.optionDropdown = el}
                                label='Option'
                                placeholder='Select Option'
                                fullInputBox={true}
                                options={this.options}
                                value={this.state.activeOption}
                                text='description'
                                dropdownWidth={455}
                                disabled={this.props.readonly}
                                error={this.state.errors.option}
                                onValueChange={this.handleOptionChange.bind(this)}
                            />
                        }

                        {quantityLabel &&
                            <NumberField
                                ref={(el) => this.quantityField = el}
                                label={quantityLabel}
                                value={this.quantity}
                                placeholder='Select Quantity'
                                disabled={this.props.readonly}
                                fullInputBox={true}
                                error={this.state.errors.quantity}
                            />
                        }

                        <TextField
                            ref={(el) => this.notesField = el}
                            label='Notes'
                            placeholder='Write something here'
                            value={this.notes}
                            fullInputBox={true}
                        />

                        <div className={style.pControls}>
                            <div
                                className={style.pSave}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.expandable ? !!this.props.expanded : true
        };
    }

    handleNewItemClick(ev) {
        ev.stopPropagation();
        this.props.onNewItem();
    }

    handleHeaderClick() {
        if (this.props.expandable) {
            this.setState({
                expanded: !this.state.expanded
            });
        }
    }

    handleAction(rowId, actionId) {
        if (actionId === 'delete') {
            const mbContent = {
                message: 'Are you sure you want to delete this selection?',
                icon: 'icon-trashcan',
                accept_is_negative: true
            };

            const self = this;

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                self.props.onDeleteItem(rowId);
            });
        } else if (actionId === 'edit') {
            this.props.onEditItem(rowId);
        }
    }

    setData(items) {
        this.tableComponent.setData(items);
    }

    render() {
        return (
            <div className={style.accordion}>
                <div
                    className={`
                        ${style.aHeader}
                        ${this.props.expandable ? style.aExpandable : ''}
                    `}
                    onClick={this.handleHeaderClick.bind(this)}
                >
                    <div className={style.aTitle}>{this.props.title}</div>
                    {this.props.expandable &&
                        <div className={`
                            ${style.aToggler}
                            ${this.state.expanded ? 'icon-caret-down' : 'icon-caret-right'}
                        `}/>
                    }
                </div>

                {this.state.expanded &&
                    <div className={style.aContent}>
                        <div className={style.aToolbar}>
                            {this.props.onNewItem && this.props.showNewItemButton &&
                                <div
                                    className={style.aButton}
                                    onClick={this.handleNewItemClick.bind(this)}
                                    style={{marginLeft: 'auto'}}
                                >
                                    Add new item
                                </div>
                            }
                        </div>

                        <div className={style.aTable}>
                            <Table
                                ref={(el) => this.tableComponent = el}
                                columns={this.props.columns}
                                rows={this.props.items}
                                rowsClickables={false}
                                onRowAction={this.handleAction.bind(this)}
                                onCellValueEdited={this.props.onCellValueEdited}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}


class OrdersPlacedTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popover: null,
            loading: false,
            rooms: [],
            totalValue: 0,
            totalDiscount: 0
        };

        this.coinsUpdates = [];
    }

    componentDidMount() {
        this.mounted = true;
        this.loadOrdersPlaced();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadOrdersPlaced() {
        this.setState({
            loading: true
        });

        this.initialQuantities = {};

        let counter = 0;
        let totalDiscount = 0;
        const wishlistsInfo = {};
        const self = this;

        const checkReady = () => {
            --counter;

            if (counter > 0) {
                return;
            }

            let rooms = JSON.parse(JSON.stringify(self.props.rooms));

            for (let room of rooms) {
                room.items = [];
            }

            for (const k in wishlistsInfo) {
                for (const choice of wishlistsInfo[k].items) {
                    let room = rooms.find(r => r.name === choice.room_id);

                    if (!room) {
                        continue;
                    }

                    // if a submitted item is edited increasing the quantity (e.g. from 1 to 2) there will be 2 entries in the list returned by the endpoint
                    // but those entries will have the same `coins_choices` field
                    if (room.items.find(i => i.id === choice.id)) {
                        continue;
                    }

                    const category = room.categories.find(c => c.id === choice.category_id);
                    const option = category.items.find(i => i.id === choice.option_id);
                    let total = choice.price * choice.quantity;

                    self.initialQuantities[choice.id] = choice.quantity;

                    room.items.push({
                        id: choice.id,
                        choiceId: choice.option_id,
                        image: getChoiceImageUrl(choice.option_id),
                        category: category.description,
                        categoryId: category.id,
                        option: choice.specification || option.description,
                        quantity: `${choice.quantity || 1}`, // ${option.unit || 'x'}`,
                        quantityAsNumber: choice.quantity,
                        submitted: wishlistsInfo[k].created,
                        price: currencyFormat('GBP', choice.price),
                        total: currencyFormat('GBP', total),
                        priceAsNumber: choice.price,
                        totalAsNumber: total,
                        notes: choice.notes,
                        cost: choice.cost || 0,
                        foc: choice.foc || false
                    });
                }
            }

            for (let room of rooms) {
                updateSubtotal(room);
            }

            self.setState({
                loading: false,
                rooms: rooms,
                totalDiscount: totalDiscount
            });

            _.defer(function() {
                self.updateTotal();
            });
        }

        $.get(`/coins_choices_submits?opportunity_id=${this.props.deal.id}`, (result) => {
            if (!self.mounted) {
                return;
            }

            if (result.length > 0) {
                for (const row of result) {
                    if (!row.wishlist_id) {
                        continue;
                    }

                    ++counter;

                    $.get(`/coins_choices_wishlists/${row.wishlist_id}`, (wishlist) => {
                        totalDiscount += wishlist.discount;
                        checkReady();
                    });

                    wishlistsInfo[row.wishlist_id] = {
                        created: row.created
                    };

                    ++counter;

                    $.get(`/coins_choices?wishlist_id=${row.wishlist_id}`, (items) => {
                        wishlistsInfo[row.wishlist_id].items = items;
                        checkReady();
                    });
                }

                if (counter === 0) {
                    checkReady();
                }
            } else {
                checkReady();
            }
        });
    }

    updateTotal() {
        let totalValue = 0;

        for (const room of this.state.rooms) {
            for (const item of room.items) {
                if (item.foc) {
                    continue;
                }

                totalValue += item.totalAsNumber || 0;
            }
        }

        this.setState({
            totalValue: totalValue
        });
    }

    render() {
        this.accordionComponent = {};

        let isEmpty = true;

        for (const room of this.state.rooms) {
            if (room.items && room.items.length > 0) {
                isEmpty = false;
                break;
            }
        }

        return (
            <div>
                <div
                    className={`
                        ${style.content}
                        ${isEmpty ? style.empty : ''}
                    `}
                >
                    {this.state.loading ? (
                        <LoadingIndicator/>
                    ) : (
                        <div>
                            {!isEmpty ? (
                                <div>
                                    {this.state.rooms.map((room, ridx) => {
                                        const items = room.items || [];

                                        if (items.length === 0) {
                                            return null;
                                        }

                                        return (
                                            <Accordion
                                                ref={(el) => this.accordionComponent[room.name] = el}
                                                key={`r_${ridx}`}
                                                id={room.id}
                                                expandable={true}
                                                expanded={items.length > 0}
                                                title={room.name}
                                                items={items}
                                                columns={ORDERS_PLACED_TABLE_COLUMNS}
                                                parent={this.props.parent}
                                                onDeleteItem={(itemId) => this.handleDeleteItem.bind(this)(room, itemId)}
                                                onEditItem={(itemId) => this.handleEditItem.bind(this)(room, itemId)}
                                                showNewItemButton={false}
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <div>
                                    No items to display
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className={style.footer}>
                    <div
                        className={`${style.aButton} ${style.bTextNeutral}`}
                        onClick={this.props.onClose}
                    >
                        Close
                    </div>

                    <div className={style.fDiscount}>
                        <div className={style.dRow}>
                            <div className={style.rTitle}>Sub-total</div>
                            <div className={style.rValue}>{currencyFormat('GBP', this.state.totalValue)}</div>
                        </div>

                        <div className={style.dRow}>
                            <div className={style.rSmallTitle}>Discount</div>
                            <div className={style.rSmallValue}>-{currencyFormat('GBP', this.state.totalDiscount)}</div>
                        </div>
                    </div>

                    <div className={style.fTotal}>
                        <div className={style.fTitle}>Order Total</div>
                        <div className={style.fValue}>{currencyFormat('GBP', Math.max(this.state.totalValue - this.state.totalDiscount, 0))}</div>
                    </div>
                </div>
            </div>
        );
    }
}

class DiscountPopover extends React.Component {
    componentDidMount() {
        this.discountComponent.focus();
    }

    handleKeyDown(key) {
        if (key === 'Escape') {
            this.props.onClose(null);
        } else if (key === 'Enter') {
            this.props.onClose(parseFloat(this.discountComponent.getValue()));
        }
    }

    render() {
        const left = this.props.left - 25;
        const top = this.props.top - 55;

        return (
            <div
                className={style.popoverBackdrop}
                onClick={() => this.props.onClose(null)}
            >
                <div
                    className={style.discountPopover}
                    style={{
                        left: left,
                        top: top
                    }}
                >
                    <NumberField
                        ref={(el) => this.discountComponent = el}
                        value={this.props.value}
                        label='Voucher Amount'
                        labelWidth={125}
                        fullInputBox={true}
                        onKeyDown={this.handleKeyDown.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

class RequestedChoicesTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popover: null,
            discountPopover: null,
            rejectPopover: null,
            submitPopover: null,
            loading: false,
            rooms: [],
            totalValue: 0,
            discount: props.wishlist?.discount || 0,
            wishlist: props.wishlist,
            hasPOAs: false
        };

        this.beSavedItems = null;
    }

    componentDidMount() {
        this.mounted = true;
        this.loadEntityChoices();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    loadEntityChoices(callback) {
        const self = this;
        let rooms = JSON.parse(JSON.stringify(this.props.rooms));

        for (let room of rooms) {
            room.items = [];
        }

        this.beSavedItems = [];

        const goOn = () => {
            self.setState({
                rooms: rooms,
                loading: false
            });

            if (callback) {
                callback();
            }

            _.defer(function() {
                if (self.mounted) {
                    self.updateTotal();
                    self.updateHasPOAs();
                }
            });
        }

        if (!this.state.wishlist) {
            goOn();
        } else {
            this.setState({
                loading: true
            });

            $.get(`/coins_choices?wishlist_id=${this.state.wishlist.id}`, (items) => {
                if (!self.mounted) {
                    return;
                }

                for (const item of items) {
                    self.beSavedItems.push({
                        entityId: item.id,
                        roomId: item.room_id,
                        categoryId: item.category_id,
                        optionId: item.option_id,
                        quantity: item.quantity,
                        notes: item.notes,
                        price: item.price,
                        foc: item.foc || false,
                        description: item.specification,
                        cost: item.cost || 0,
                    });
                }

                for (const item of self.beSavedItems) {
                    let room = rooms.find(r => r.name === item.roomId);

                    if (!room) {
                        continue;
                    }

                    const category = room.categories.find(c => c.id === item.categoryId);
                    const option = category.items.find(i => i.id === item.optionId);
                    const price = item.price || option.price;
                    let total = price * item.quantity;

                    if (!this.props.managePOAs || (option.price === 0 && !item.foc)) {
                        const isPOA = option.price === 0;
                        const showAsPOA = isPOA && !item.price;

                        room.items.push({
                            entityId: item.entityId,
                            id: item.optionId,
                            image: getChoiceImageUrl(item.optionId),
                            category: category.description,
                            categoryId: category.id,
                            option:  item.description || option.description,
                            quantity: `${item.quantity || 1}`, // ${option.unit || 'x'}`,
                            quantityAsNumber: item.quantity,
                            price: showAsPOA ? 'POA' : currencyFormat('GBP', price),
                            total: showAsPOA ? 'POA' : currencyFormat('GBP', total),
                            isPOA: isPOA,
                            priceAsNumber: price,
                            totalAsNumber: total,
                            notes: item.notes,
                            foc: item.foc || false,
                            cost: item.cost || 0,
                        });
                    }
                }

                for (let room of rooms) {
                    updateSubtotal(room);
                }

                goOn();
            });
        }
    }

    getPdfData(currentAmountPaid, callback) {
        let amountPaid = currentAmountPaid || 0;
        let rooms = null;

        const checkReady = () => {
            if (amountPaid === null || rooms === null) {
                return;
            }

            const discount = this.state.discount || 0;

            callback({
                rooms: rooms,
                orderTotal: currencyFormat('GBP', this.state.totalValue - discount),
                discount: currencyFormat('GBP', discount),
                amountPaid: currencyFormat('GBP', amountPaid),
                balance: currencyFormat('GBP', this.state.totalValue - discount - amountPaid)
            });
        }

        // ...
        const tempRooms = [];

        for (const room of this.state.rooms) {
            if (room.items.length > 0) {
                const items = room.items.filter(i => !!i.id);

                tempRooms.push({
                    name: room.name,
                    items: items.map(i => {
                        return {
                            category: i.category,
                            option: i.option,
                            quantity: i.quantity,
                            price: i.price,
                            total: i.total,
                            foc: i.foc
                        };
                    }),
                    subTotal: currencyFormat('GBP', items.reduce((total, i) => total += (i.foc ? 0 : i.totalAsNumber), 0))
                });
            }
        }

        rooms = tempRooms;
        checkReady();
    }

    handleNewItem(room) {
        this.setState({
            popover: {
                room: room
            }
        });
    }

    handleDeleteItem(room, itemId) {
        room.items = room.items.filter(i => i.id !== itemId);
        updateSubtotal(room);
        this.updateTotal();
        this.updateHasPOAs();
        this.accordionComponent[room.name].setData(room.items);
    }

    handleEditItem(room, itemId) {
        this.setState({
            popover: {
                room: room,
                itemId: itemId
            }
        });
    }

    handlePopoverClose(itemId, data) {
        if (data) {
            let room = this.state.popover.room;

            if (!room.items) {
                room.items = [];
            }

            const price = data.option.price;
            let total = price;

            if (data.quantity) {
                total *= data.quantity;
            }

            let item = {};

            if (itemId) {
                item = room.items.find(i => i.id === itemId);
            }

            const isPOA = price === 0;

            _.extend(item, {
                id: data.option.id,
                image: getChoiceImageUrl(data.option.id),
                category: data.category.description,
                categoryId: data.category.id,
                optionTitle: data.option.title,
                option: data.option.description,
                quantity: `${data.quantity}`, // ${data.option.unit || 'x'}`,
                quantityAsNumber: data.quantity,
                price: isPOA ? 'POA' : currencyFormat('GBP', price),
                total: isPOA ? 'POA' : currencyFormat('GBP', total),
                isPOA: isPOA,
                priceAsNumber: price,
                totalAsNumber: total,
                notes: data.notes,
                foc: item?.foc || false,
                cost: data.option.cost || 0,
            });

            if (!itemId) {
                room.items.unshift(item);
            }

            updateSubtotal(room);
            this.updateTotal();
            this.updateHasPOAs();
            this.accordionComponent[room.name].setData(room.items);
        }

        this.setState({
            popover: null
        });
    }

    handleSaveDraft(callback) {
        let itemsToAdd = [];
        let itemsToDelete = [];
        let itemsToUpdate = [];
        let existingItemsEntityId = [];

        for (const room of this.state.rooms) {
            if (!room.items) {
                continue;
            }

            for (const item of room.items) {
                if (item.summaryInfo) {
                    continue;
                }

                if (item.entityId) {
                    existingItemsEntityId.push(item.entityId);

                    const existingItem = this.beSavedItems.find(i => i.entityId === item.entityId);

                    if (existingItem.roomId !== room.name || existingItem.optionId !== item.id ||
                        existingItem.categoryId !== item.categoryId || existingItem.notes !== item.notes ||
                        existingItem.quantity !== item.quantityAsNumber || existingItem.price !== item.priceAsNumber ||
                        existingItem.foc !== item.foc) {

                        itemsToUpdate.push({
                            entityId: item.entityId,
                            roomId: room.name,
                            optionId: item.id,
                            categoryId: item.categoryId,
                            notes: item.notes || '',
                            quantity: item.quantityAsNumber,
                            price: item.priceAsNumber,
                            foc: item.foc
                        });
                    }
                } else {
                    itemsToAdd.push({
                        roomId: room.name,
                        optionId: item.id,
                        categoryId: item.categoryId,
                        notes: item.notes || '',
                        price: item.priceAsNumber,
                        quantity: item.quantityAsNumber,
                        foc: item.foc,
                        specification: item.option,
                        description: item.optionTitle,
                        cost: item.cost
                    });
                }
            }
        }

        for (const item of this.beSavedItems) {
            if (existingItemsEntityId.indexOf(item.entityId) === -1) {
                itemsToDelete.push(item.entityId);
            }
        }

        // ...
        let wishlistId = this.state.wishlist?.id;

        const goOn = () => {
            let numItemsActions = 0;

            const checkReady = () => {
                if (numItemsActions === 0) {
                    self.loadEntityChoices(callback);
                }
            }

            const self = this;

            for (const item of itemsToAdd) {
                ++numItemsActions;

                $.post('/coins_choices', JSON.stringify({
                    wishlist_id: wishlistId,
                    room_id: item.roomId,
                    category_id: item.categoryId,
                    option_id: item.optionId,
                    quantity: item.quantity,
                    price: item.price,
                    notes: item.notes,
                    foc: item.foc,
                    description: item.description,
                    specification: item.specification,
                    cost: item.cost
                }), () => {
                    --numItemsActions;
                    checkReady();
                });
            }

            for (const item of itemsToUpdate) {
                const existingItem = this.beSavedItems.find(i => i.entityId === item.entityId);
                let newData = {};

                if (existingItem.roomId !== item.roomId) {
                    newData.room_id = item.roomId;
                }

                if (existingItem.optionId !== item.optionId) {
                    newData.option_id = item.optionId;
                }

                if (existingItem.categoryId !== item.categoryId) {
                    newData.category_id = item.categoryId;
                }

                if (existingItem.notes !== item.notes) {
                    newData.notes = item.notes;
                }

                if (existingItem.quantity !== item.quantity) {
                    newData.quantity = item.quantity;
                }

                if (existingItem.price !== item.price) {
                    newData.price = item.price;
                }

                if (existingItem.foc !== item.foc) {
                    newData.foc = item.foc;
                }

                if (!_.isEmpty(newData)) {
                    ++numItemsActions;

                    $.ajax({
                        type: 'PATCH',
                        url: `/coins_choices/${item.entityId}`,
                        data: JSON.stringify(newData),
                        contentType: 'application/json',
                        dataType: 'json',
                        success: function () {
                            --numItemsActions;
                            checkReady();
                        }
                    });
                }
            }

            if (!this.props.managePOAs) {
                for (const id of itemsToDelete) {
                    ++numItemsActions;

                    $.ajax({
                        type: 'DELETE',
                        url: `/coins_choices/${id}`,
                        success: function() {
                            --numItemsActions;
                            checkReady();
                        }
                    });
                }
            }

            checkReady();
        }

        const self = this;

        if (!wishlistId) {
            $.post('/coins_choices_wishlists', JSON.stringify({
                opportunity_id: this.props.deal.id,
                status: 'requestingItems',
                discount: this.state.discount || 0
            }), (result) => {
                wishlistId = result.id;

                self.setState({
                    wishlist: result
                });

                self.props.onWishlistCreated(result);

                goOn();
            });
        } else {
            if (this.props.wishlist.discount !== this.state.discount) {
                $.ajax({
                    type: 'PATCH',
                    url: `/coins_choices_wishlists/${this.props.wishlist.id}`,
                    data: JSON.stringify({
                        discount: this.state.discount || 0
                    }),
                    contentType: 'application/json',
                    dataType: 'json',
                    success: function(result) {
                        self.props.onWishlistUpdated(result);
                        goOn();
                    }
                });
            } else {
                goOn();
            }
        }
    }

    handleCellValueEdited(room, itemId, columnId, value) {
        const item = room.items.find(i => i.id === itemId);

        item[columnId] = value;

        if (columnId === 'price') {
            if (value === '') {
                item.priceAsNumber = 0;
                item.totalAsNumber = 0;
                item.price = 'POA';
                item.total = 'POA';
            } else {
                item.priceAsNumber = _.isNaN(value) ? item.priceAsNumber : parseFloat(value);
                item.totalAsNumber = item.quantityAsNumber * item.priceAsNumber;
                item.price = currencyFormat('GBP', item.priceAsNumber);
                item.total = currencyFormat('GBP', item.totalAsNumber);
            }

            this.updateHasPOAs();
        } else if (columnId === 'foc') {
            this.updateHasPOAs();
        }

        updateSubtotal(room);
        this.updateTotal();
    }

    updateHasPOAs() {
        let hasPOAs = false;

        for (const room of this.state.rooms) {
            hasPOAs = !!room.items.find(i => i.price === 'POA' && !i.foc);

            if (hasPOAs) {
                break;
            }
        }

        if (hasPOAs !== this.state.hasPOAs) {
            this.setState({
                hasPOAs: hasPOAs
            });
        }
    }

    updateTotal() {
        let totalValue = 0;

        for (const room of this.state.rooms) {
            for (const item of room.items) {
                if (item.foc) {
                    continue;
                }

                totalValue += item.totalAsNumber || 0;
            }
        }

        this.setState({
            totalValue: totalValue
        });
    }

    handleCtaButtonClick(button) {
        const goOn = () => {
            if (button.generatePdf) {
                const self = this;

                this.props.getPdfData(0, (pdfData) => {
                    const style = self.props.deal.funnel?.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';

                    pdfGenerator(pdfData, style, (pdf) => {
                        self.props.onCtaButtonClicked(button.id, pdf);
                    });
                });
            } else {
                this.props.onCtaButtonClicked(button.id);
            }
        }

        if (button.saveDraft) {
            this.handleSaveDraft(() => {
                goOn();
            });
        } else {
            goOn();
        }
    }

    handleCloseClick() {
        // check there are not unsaved changes
        let unsavedChanges = this.props.wishlist && (this.props.wishlist.discount !== this.state.discount);
        const existingItemsEntityId = [];

        if (!unsavedChanges) {
            for (const room of this.state.rooms) {
                if (!room.items) {
                    continue;
                }

                for (const item of room.items) {
                    if (item.summaryInfo) {
                        continue;
                    }

                    if (item.entityId) {
                        existingItemsEntityId.push(item.entityId);

                        const existingItem = this.beSavedItems.find(i => i.entityId === item.entityId);

                        if (existingItem.roomId !== room.name || existingItem.optionId !== item.id ||
                            existingItem.categoryId !== item.categoryId || existingItem.notes !== item.notes ||
                            existingItem.quantity !== item.quantityAsNumber || existingItem.price !== item.priceAsNumber ||
                            existingItem.foc !== item.foc
                        ) {
                            unsavedChanges = true;
                            break;
                        }
                    } else {
                        unsavedChanges = true;
                        break;
                    }
                }

                if (unsavedChanges) {
                    break;
                }
            }
        }

        if (!unsavedChanges && !this.props.managePOAs) {
            for (const item of this.beSavedItems) {
                if (existingItemsEntityId.indexOf(item.entityId) === -1) {
                    unsavedChanges = true;
                    break;
                }
            }
        }

        if (unsavedChanges) {
            const mbContent = {
                message: 'Unsaved data will be lost. Are you sure you want to continue?',
                icon: 'icon-trashcan',
                accept_is_negative: true
            };

            const self = this;

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                self.props.onClose();
            });
        } else {
            this.props.onClose();
        }
    }

    handleDiscountPopoverClose(discount) {
        this.setState({
            discountPopover: null,
            discount: discount !== null ? (discount || 0) : this.state.discount
        });
    }

    handleRejectPopoverClose(reason) {
        if (reason !== null) {
            const self = this;

            this.handleSaveDraft(() => {
                self.props.onCtaButtonClicked('rejectWishlist', reason);
            });
        }

        this.setState({
            rejectPopover: null
        });
    }

    showRejectPopover() {
        this.setState({
            rejectPopover: true
        });
    }

    handleSubmitPopoverClose(data) {
        if (data) {
            const self = this;

            this.handleSaveDraft(() => {
                self.props.getPdfData(data.amount_paid, (pdfData) => {
                    const style = self.props.deal.funnel?.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';

                    pdfGenerator(pdfData, style, (pdf) => {
                        data.pdf = pdf;
                        self.props.onCtaButtonClicked('submitToCoins', data);
                    });
                });
            });
        }

        this.setState({
            submitPopover: null
        });
    }

    showSubmitPopover() {
        this.setState({
            submitPopover: true
        });
    }

    showAddDiscountPopover() {
        const bbox = this.addDiscountButtonComponent.getBoundingClientRect();

        this.setState({
            discountPopover: {
                left: bbox.left,
                top: bbox.top
            }
        });
    }

    renderFooter() {
        const wishlistStatus = this.state.wishlist?.status || 'requestingItems';
        let ctaButton = null;

        switch (wishlistStatus) {
            case 'salesAdvisorReview':
                if (this.state.hasPOAs) {
                    ctaButton = {
                        id: 'sendPOAsToCommercial',
                        title: 'Send POAs to Commercial',
                        saveDraft: true
                    };
                } else {
                    if (this.props.hasBuyer) {
                        ctaButton = {
                            id: 'sendForHOApproval',
                            title: 'Send for Homeowner Approval',
                            saveDraft: true,
                            generatePdf: true,
                            style: style.bGreen
                        };
                    } else {
                        ctaButton = {
                            id: 'progressToSubmit',
                            title: 'Progress to Submit',
                            saveDraft: true,
                            style: style.bGreen
                        };
                    }
                }
                break;

            case 'commercialTeamReview':
                if (!this.state.hasPOAs) {
                    ctaButton = {
                        id: 'confirmPOAsPrices',
                        title: 'Confirm POAs Prices',
                        saveDraft: true,
                        style: style.bGreen
                    };
                }
                break;


            case 'sendHomeownerApproval':
                if (this.state.hasPOAs) {
                    ctaButton = {
                        id: 'sendPOAsToCommercial',
                        title: 'Send POAs to Commercial',
                        saveDraft: true
                    };
                } else {
                    ctaButton = {
                        id: 'sendForHOApproval',
                        title: 'Send for Homeowner Approval',
                        saveDraft: true,
                        generatePdf: true,
                        style: style.bGreen
                    };
                }
                break;
        };

        switch (wishlistStatus) {
            case 'requestingItems':
            case 'salesAdvisorReview':
            case 'commercialTeamReview':
            case 'sendHomeownerApproval':
                return (
                    <div className={style.footer}>
                        <div
                            className={`${style.aButton} ${style.bTextNeutral}`}
                            onClick={this.handleCloseClick.bind(this)}
                        >
                            Close
                        </div>

                        {!this.props.managePOAs &&
                            <div
                                ref={(el) => this.addDiscountButtonComponent = el}
                                className={`${style.aButton} ${style.bNeutral}`}
                                style={{
                                    marginLeft: '10px'
                                }}
                                onClick={this.showAddDiscountPopover.bind(this)}
                            >
                                Add Voucher
                            </div>
                        }

                        <div className={style.fDiscount}>
                            <div className={style.dRow}>
                                <div className={style.rTitle}>Sub-total</div>
                                <div className={style.rValue}>{currencyFormat('GBP', this.state.totalValue)}</div>
                            </div>

                            <div className={style.dRow}>
                                <div className={style.rSmallTitle}>Discount</div>
                                <div className={style.rSmallValue}>-{currencyFormat('GBP', this.state.discount)}</div>
                            </div>
                        </div>

                        <div className={style.fTotal}>
                            <div className={style.fTitle}>Order Total</div>
                            <div className={style.fValue}>{currencyFormat('GBP', Math.max(this.state.totalValue - this.state.discount, 0))}</div>
                        </div>

                        {!this.state.loading &&
                            <div className={style.fRightArea}>
                                {ctaButton &&
                                    <div
                                        className={`${style.aButton} ${ctaButton.style || ''}`}
                                        onClick={() => this.handleCtaButtonClick.bind(this)(ctaButton)}
                                    >
                                        {ctaButton.title}
                                    </div>
                                }

                                {wishlistStatus === 'commercialTeamReview' &&
                                    <div
                                        className={`
                                            ${style.aButton}
                                            ${style.bCancel}
                                        `}
                                        onClick={this.showRejectPopover.bind(this)}
                                    >
                                        Cancel POA Approval
                                    </div>
                                }

                                <div
                                    className={`
                                        ${style.aButton}
                                        ${(ctaButton || wishlistStatus === 'commercialTeamReview') ? style.bNeutral : ''}
                                    `}
                                    onClick={() => this.handleSaveDraft.bind(this)()}
                                >
                                    Save Draft
                                </div>
                            </div>
                        }
                    </div>
                );

            case 'paymentAndSubmit':
                return (
                    <div className={style.footer}>
                        <div
                            className={`${style.aButton} ${style.bTextNeutral}`}
                            onClick={this.handleCloseClick.bind(this)}
                        >
                            Close
                        </div>

                       <div
                            ref={(el) => this.addDiscountButtonComponent = el}
                            className={`${style.aButton} ${style.bNeutral}`}
                            style={{
                                marginLeft: '10px'
                            }}
                            onClick={this.showAddDiscountPopover.bind(this)}
                        >
                            Add Voucher
                        </div>

                        <div className={style.fDiscount}>
                            <div className={style.dRow}>
                                <div className={style.rTitle}>Sub-total</div>
                                <div className={style.rValue}>{currencyFormat('GBP', this.state.totalValue)}</div>
                            </div>

                            <div className={style.dRow}>
                                <div className={style.rSmallTitle}>Discount</div>
                                <div className={style.rSmallValue}>-{currencyFormat('GBP', this.state.discount)}</div>
                            </div>
                        </div>

                        <div className={style.fTotal}>
                            <div className={style.fTitle}>Order Total</div>
                            <div className={style.fValue}>{currencyFormat('GBP', Math.max(this.state.totalValue - this.state.discount, 0))}</div>
                        </div>

                        {!this.state.loading &&
                            <div className={style.fRightArea}>
                                <div
                                    className={`
                                        ${style.aButton}
                                        ${style.bGreen}
                                    `}
                                    onClick={this.showSubmitPopover.bind(this)}
                                >
                                    Log Payment + Submit to COINS
                                </div>

                               <div
                                    className={`
                                        ${style.aButton}
                                        ${style.bNeutral}
                                    `}
                                    onClick={() => this.handleSaveDraft.bind(this)()}
                                >
                                    Save Draft
                                </div>
                            </div>
                        }
                    </div>
                );
        }

        return null;
    }

    render() {
        this.accordionComponent = {};

        let columns = REQUESTED_CHOICES_TABLE_COLUMNS;
        const paymentAndSubmit = this.props.wishlist?.status === 'paymentAndSubmit';

        if (this.props.managePOAs) {
            columns = REQUESTED_CHOICES_TABLE_COLUMNS_POA_ITEMS;
        } else if (paymentAndSubmit) {
            columns = REQUESTED_CHOICES_TABLE_COLUMNS_REQUESTED_ITEMS;
        }

        return (
            <div>
                <div className={style.content}>
                    {this.state.loading ? (
                        <LoadingIndicator/>
                    ) : (
                        <div>
                            {this.state.rooms.map((room, ridx) => {
                                const items = room.items || [];

                                if ((this.props.managePOAs || paymentAndSubmit) && items.length === 0) {
                                    return null;
                                }

                                return (
                                    <Accordion
                                        ref={(el) => this.accordionComponent[room.name] = el}
                                        key={`r_${ridx}`}
                                        id={room.id}
                                        expandable={true}
                                        expanded={items.length > 0}
                                        title={room.name}
                                        items={items}
                                        columns={columns}
                                        parent={this.props.parent}
                                        showNewItemButton={!(this.props.managePOAs || paymentAndSubmit)}
                                        onNewItem={() => this.handleNewItem.bind(this)(room)}
                                        onDeleteItem={(itemId) => this.handleDeleteItem.bind(this)(room, itemId)}
                                        onEditItem={(itemId) => this.handleEditItem.bind(this)(room, itemId)}
                                        onCellValueEdited={(itemId, columnId, value) => this.handleCellValueEdited.bind(this)(room, itemId, columnId, value)}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>

                {this.renderFooter()}

                {this.state.popover &&
                    <ChoicePopover
                        room={this.state.popover.room}
                        itemId={this.state.popover.itemId}
                        readonly={paymentAndSubmit}
                        buildProgress={this.props.buildProgress}
                        parent={this.props.parent}
                        onClose={this.handlePopoverClose.bind(this)}
                    />
                }

                {this.state.discountPopover &&
                    <DiscountPopover
                        value={this.state.discount}
                        top={this.state.discountPopover.top}
                        left={this.state.discountPopover.left}
                        onClose={this.handleDiscountPopoverClose.bind(this)}
                    />
                }

                {this.state.rejectPopover &&
                    <RejectPopover
                        value={this.state.discount}
                        onClose={this.handleRejectPopoverClose.bind(this)}
                    />
                }

                {this.state.submitPopover &&
                    <SubmitPopover
                        onClose={this.handleSubmitPopoverClose.bind(this)}
                    />
                }
            </div>
        );
    }
}


class Choices extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            activeView: props.view,
            wishlist: props.wishlist || null
        };

        this.tabs = [];

        if (props.view === 'requested') {
            this.tabs.push('Requested selections');
        } else {
            this.tabs.push('Orders Placed');
        }

        this.rooms = [];
    }

    componentDidMount() {
        this.mounted = true;

        // ...
        const funnel = this.props.deal.funnel;
        const region = funnel.region?.integration_data?.region_id;

        if (!region) {
            return;
        }

        this.setState({
            loading: true
        });

        // is the data cached?
        let cachedChoices = app.globalData.choices || {};

        if (region in cachedChoices) {
            this.rooms = JSON.parse(JSON.stringify(cachedChoices[region]));
            this.setState({
                loading: false
            });
        } else {
            const self = this;

            $.get(`/coins_choices_items?region=${region}`, function(result) {
                if (!self.mounted) {
                    return;
                }

                let rooms = [];

                for (const k in result.rooms) {
                    const room = result.rooms[k];
                    let categories = [];

                    for (const category of room) {
                        if (result.categories[category]) {
                            categories.push(result.categories[category]);
                        }
                    }

                    rooms.push({
                        name: k,
                        categories: categories
                    });
                }

                self.rooms = rooms;

                if (!app.globalData.choices) {
                    app.globalData.choices = {};
                }

                app.globalData.choices[region] = JSON.parse(JSON.stringify(rooms));

                self.setState({
                    loading: false
                });
            });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getPdfData(currentAmountPaid, callback) {
        const wishlist = this.state.wishlist;
        const deal = this.props.deal;

        const getCfValue = (cfName) => {
            const cf = app.globalData.customFieldsInfo.dealsArray.find(cf => cf.name.toLowerCase() === cfName.toLowerCase());
            return deal[`custom_field.${cf?.id}`] || '';
        }

        const data = {
            development: deal.funnel.name,
            plot: getCfValue('Unit NO.'),
            houseType: getCfValue('House Type'),
            buildStage: getCfValue('Coins Current Build Stage'),
            created: wishlist?.created || '',
            lastModified: wishlist?.modified || '',
            salesAdvisor: app.globalData.users.find(u => u.id === wishlist?.last_modified_by_id)?.name || ''
        };

        // ...
        let counter = 0;

        const checkReady = () => {
            --counter;

            if (counter > 0) {
                return;
            }

            callback(data);
        }

        // ...
        const purchaserId = getCfValue('Primary Buyer');

        if (purchaserId) {
            ++counter;

            $.get(`/individuals/${purchaserId}`, (result) => {
                data.purchaser = result.full_name;
                data.contactNo = result.communication.find(comm => comm.medium === 'phone' && comm.value)?.value;

                checkReady();
            });
        }

        // ...
        if (wishlist?.approved_by_id) {
            ++counter;

            $.get(`/individuals/${wishlist.approved_by_id}`, (result) => {
                data.purchaserApproved = result.full_name;
                data.approvalDate = wishlist.approval_date;

                checkReady();
            });
        }

        // ...
        ++counter;

        this.activeContentComponent.getPdfData(currentAmountPaid, (result) => {
            _.extend(data, result);
            checkReady();
        });
    }

    handleDownloadAsPdf() {
        const style = this.props.deal.funnel?.integration_data?.brandCode === 'C' ? 'charlesChurch' : 'persimmon';

        this.getPdfData(0, (data) => {
            pdfGenerator(data, style);
        });
    }

    handleWishlistCreate(wishlist) {
        this.setState({
            wishlist: wishlist
        });

        this.props.onWishlistCreated(wishlist);
    }

    handleWishlistUpdate(wishlist) {
        this.setState({
            wishlist: wishlist
        });

        this.props.onWishlistUpdated(wishlist);
    }

    render() {
        return (
            <div className={style.choices}>
                <div className={style.toolbar}>
                    <div className={`${style.tIcon} icon-branch`}/>
                    <div className={style.tTitle}>{`${this.props.deal.name} - Selections`}</div>

                    {this.state.activeView === 'requested' &&
                        <div className={style.tRightArea}>
                            <div
                                className={`
                                    ${style.tButton}
                                    icon-file-pdf
                                `}
                                onClick={this.handleDownloadAsPdf.bind(this)}
                            />
                        </div>
                    }
                </div>

                <div className={style.cTabControl}>
                    {this.tabs.map((tab, tidx) => {
                        return (
                            <div
                                key={`tab_${tidx}`}
                                className={`
                                    ${style.cTab}
                                    ${style.tActive}
                                `}
                            >
                                {tab}
                            </div>
                        );
                    })}
                </div>

                {this.state.loading ? (
                    <div className={style.loading}>
                        <LoadingIndicator/>
                    </div>
                ) : (
                    <div>
                        {this.state.activeView === 'requested' &&
                            <RequestedChoicesTab
                                ref={(el) => this.activeContentComponent = el}
                                rooms={this.rooms}
                                wishlist={this.state.wishlist}
                                hasBuyer={this.props.hasBuyer}
                                managePOAs={this.props.managePOAs}
                                buildProgress={this.props.buildProgress}
                                deal={this.props.deal}
                                parent={this.props.parent}
                                onClose={this.props.onClose}
                                onWishlistCreated={this.handleWishlistCreate.bind(this)}
                                onWishlistUpdated={this.handleWishlistUpdate.bind(this)}
                                onCtaButtonClicked={this.props.onCtaButtonClicked}
                                getPdfData={this.getPdfData.bind(this)}
                            />
                        }

                        {this.state.activeView === 'ordersPlaced' &&
                            <OrdersPlacedTab
                                ref={(el) => this.activeContentComponent = el}
                                rooms={this.rooms}
                                deal={this.props.deal}
                                parent={this.props.parent}
                                onClose={this.props.onClose}
                            />
                        }
                    </div>
                )}
            </div>
        );
    }
}

export const ChoicesDialog = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.parent().attr('id', 'choices-modal');
        this.$el.css('height', '100%');
    },
    handleCtaButtonClick(ctaButtonId, contextData) {
        this.trigger('ctaButton:clicked', ctaButtonId, contextData);
        this.trigger('close');
    },
    onRender: function() {
        ReactDOM.render(
            <Choices
                {...this.options}
                onClose={() => this.trigger('close')}
                onWishlistCreated={(wishlist) => this.trigger('wishlist:created', wishlist)}
                onWishlistUpdated={(wishlist) => this.trigger('wishlist:updated', wishlist)}
                onCtaButtonClicked={this.handleCtaButtonClick.bind(this)}
                parent={this}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});
import React from 'react'

import MessageBox from 'js/views/message_box'
import Utilities from 'js/utils/utilities'
import {NewSelect} from 'js/react_views/widgets/select'
import {Fonts, FontSizes} from './consts'

import style from './see.css'


class InlineTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.textStyles = [{
      id: 'paragraph',
      title: 'Paragraph',
      tag: 'p'
    }, {
      id: 'heading1',
      title: 'Heading 1',
      tag: 'h1'
    }, {
      id: 'heading2',
      title: 'Heading 2',
      tag: 'h2'
    }];

    this.state = {
      textModifiers: {
        boldActive: this.props.textDefaults.paragraph.weight !== 'normal',
        italicActive: false,
        underlineActive: false,
        unorderedListActive: false,
        orderedListActive: false,
        linkActive: false
      },
      activeTextAlign: 'align-left',
      activeLink: null,
      activeColor: this.props.textDefaults.paragraph.color,
      activeLetterSpacing: null,
      activeTextTransform: 'none',
      popupVisible: null
    };

    this.editor = props.squireEditor;
    this.activeStyle = this.textStyles[0];
    this.handlePathChange = this.handlePathChange.bind(this);
  }

  componentDidMount() {
    this.editor.addEventListener('pathChange', this.handlePathChange);
    this.editor.addEventListener('input', this.props.onContentChange);

    this.handlePathChange({path: this.editor.getPath()});
    this.setTextAlign(this.props.block.css('text-align'));
  }

  componentWillUnmount() {
    this.editor.removeEventListener('pathChange', this.handlePathChange);
    this.editor.removeEventListener('input', this.props.onContentChange);
  }

  handlePathChange(ev) {
    let newUiState = {
      boldActive: false,
      italicActive: false,
      underlineActive: false,
      unorderedListActive: false,
      orderedListActive: false,
      linkActive: false
    };

    let activeStyle = this.textStyles[0];

    if (ev.path === '(selection)') {
      newUiState.boldActive = this.editor.hasFormat('b');
      newUiState.italicActive = this.editor.hasFormat('i');
      newUiState.underlineActive = this.editor.hasFormat('u');
      newUiState.unorderedListActive = this.editor.hasFormat('ul');
      newUiState.orderedListActive = this.editor.hasFormat('ol');
      newUiState.linkActive = false;
    } else {
      const parts = ev.path.toLowerCase().split('>');

      for (const part of parts) {
        switch(part) {
          case 'b':
            newUiState.boldActive = true;
            break;

          case 'i':
            newUiState.italicActive = true;
            break;

          case 'u':
            newUiState.underlineActive = true;
            break;

          case 'ul':
            newUiState.unorderedListActive = true;
            break;

          case 'ol':
            newUiState.orderedListActive = true;
            break;

          case 'a.seelink':
            newUiState.linkActive = true;
            break;

          case 'h1':
            activeStyle = this.textStyles[1];
            break;

          case 'h2':
            activeStyle = this.textStyles[2];
            break;
        }
      }

      this.updateFontSelectors();
    }

    if (newUiState.linkActive) {
      const range = this.editor.getSelection();
      const parent = range.startContainer.parentNode;

      if (parent.nodeName === 'A') {
        // does it contain a merge tag?
        const pathname = decodeURIComponent(parent.pathname);

        if (pathname.indexOf('/${') === 0) {
          this.setState({activeLink: pathname.substring(1)});
        } else {
          this.setState({activeLink: parent.href});
        }
      }
    } else {
      this.setState({activeLink: ''});
    }

    this.setState({textModifiers: newUiState});

    this.activeStyle = activeStyle;
    this.textStyleSelector.setValue(activeStyle.id);
  }

  toggleBold() {
    if (this.state.textModifiers.boldActive) {
      this.editor.removeBold();
    } else {
      this.editor.bold();
    }
  }

  toggleItalic() {
    if (this.state.textModifiers.italicActive) {
      this.editor.removeItalic();
    } else {
      this.editor.italic();
    }
  }

  toggleUnderline() {
    if (this.state.textModifiers.underlineActive) {
      this.editor.removeUnderline();
    } else {
      this.editor.underline();
    }
  }

  handleTextStyle(items) {
    const newStyle = items[0];

    if (newStyle.id !== this.activeStyle.id) {
      this.editor.modifyBlocks(frag => {
        let output = this.editor._doc.createDocumentFragment();
        let block = Squire.getNextBlock(frag);

        while (block) {
          output.appendChild(this.editor.createElement(newStyle.tag, [Squire.empty(block)]));
          block = Squire.getNextBlock(block);
        }

        return output;
      });

      const textNode = this.getActiveTextNode();

      this.editor.focus();
      this.activeStyle = newStyle;
    }
  }

  handleFontFace(items) {
    const activeFontFace = this.getActiveFontFace();
    const newFontFace = items[0];

    if (newFontFace.id !== activeFontFace.id) {
      let fallback = '';

      if (newFontFace.fallback) {
        fallback = `, ${newFontFace.fallback}`;
      }

      this.editor.setFontFace(`${newFontFace.title}${fallback}`);
      this.props.editor.onFontChange(activeFontFace, newFontFace);
    }
  }

  getActiveTextNode() {
    const range = this.editor.getSelection();
    const textTags = ['P', 'H1', 'H2'];
    let toTest = range.startContainer;

    while (toTest) {
      if (textTags.indexOf(toTest.nodeName) !== -1) {
        return $(toTest);
        break;
      } else if (toTest.nodeName === 'DIV') {
        break;
      }

      toTest = toTest.parentElement;
    }

    return null;
  }

  getParentList(root) {
    if (['UL', 'OL'].indexOf(root.nodeName) !== -1) {
      return root;
    }

    if (root.parentElement) {
      return this.getParentList(root.parentElement);
    }

    return null;
  }

  handleFontSize(items) {
    const activeFontSize = this.getActiveFontSize();
    const newFontSize = items[0];

    if (newFontSize.id !== activeFontSize.id) {
      const textNode = this.getActiveTextNode();

      if (textNode) {
        textNode.css('font-size', newFontSize.value);
        textNode.css('line-height', `${Math.floor(newFontSize.title * 1.5)}px`);
        textNode.addClass('size'); // this is to trigger pathChange on specific situations on Squire

        const list = this.getParentList(textNode[0]);

        if (list) {
          $(list).css('font-size', newFontSize.value);
        }
      }
    }

    this.editor.focus();
  }

  handleTextColor(color) {
    this.setState({activeColor: color.hex});
    this.editor.setTextColour(color.hex);

    const textNode = this.getActiveTextNode();

    if (textNode) {
      const list = this.getParentList(textNode[0]);

      if (list) {
        $(list).css('color', color.hex);
      }

      const anchor = textNode.find('a.seeLink');

      if (anchor) {
        $(anchor).css('color', color.hex);
      }
    }
  }

  handleInsertTag(items) {
    if (!items) {
      return;
    }

    const tag = items[0];

    if (tag.customInsertTag) {
      this.editor.insertHTML(tag.tag);
      this.editor.focus();
    } else {
      const self = this;

      MessageBox.showOk(
        {
          icon: 'icon-warning',
          message: `Leave blank or choose alternative when ${tag.name} is missing`,
          accept_button_text: 'Continue'
        },
        this.props.parent,
        function(val) {
          let finalTag;

          if (val.toString().length > 0) {
            finalTag = `${tag.tag.slice(0, -1)},default=${val}}`;
          }
          else {
            finalTag = tag.tag;
          }

          self.editor.insertHTML(finalTag);
          self.editor.focus();
        },
        {
          type: 'input'
        }
      );
    }

    this.mergeTagSelector.setValue(null);
  }

  toggleTextTransformPopup() {
    this.setState({popupVisible: (this.state.popupVisible === 'textTransform') ? null : 'textTransform'});
  }

  toggleTextAlignPopup() {
    this.setState({popupVisible: (this.state.popupVisible === 'align') ? null : 'align'});
  }

  toggleTextLetterSpacingPopup() {
    this.setState({popupVisible: (this.state.popupVisible === 'letterSpacing') ? null : 'letterSpacing'});
  }

  toggleLinkPopup() {
    this.setState({popupVisible: (this.state.popupVisible === 'link') ? null : 'link'});
  }

  toggleUnorderedList() {
    if (this.state.textModifiers.unorderedListActive) {
      this.editor.removeList();
    } else {
      this.editor.makeListAttributes = {style: `font-size: ${this.getActiveFontSize().value}; color: ${this.state.activeColor};`};
      this.editor.makeUnorderedList();
      this.editor.makeListAttributes = null;
    }
  }

  toggleOrderedList() {
    if (this.state.textModifiers.orderedListActive) {
      this.editor.removeList();
    } else {
      this.editor.makeListAttributes = {style: `font-size: ${this.getActiveFontSize().value}; color: ${this.state.activeColor};`};
      this.editor.makeOrderedList();
      this.editor.makeListAttributes = null;
    }
  }

  setLink() {
    const value = this.state.activeLink;

    if (value) {
      this.editor.removeLink();
      this.editor.makeLink(value, {class: 'seeLink'});
      this.setState({activeLink: value});
    } else {
      this.setState({activeLink: null});
      this.editor.removeLink();
    }
  }

  updateFontSelectors() {
    this.fontFaceSelector.setValue(this.getActiveFontFace().id);
    this.fontSizeSelector.setValue(this.getActiveFontSize().id);

    const fontInfo = this.editor.getFontInfo();

    if (fontInfo.color) {
      this.setState({activeColor: Utilities.rgb2hex(fontInfo.color)});
    } else {
      const parts = this.editor.getPath().toLowerCase().split('>');
      let color = this.props.textDefaults.paragraph.color;

      for (const part of parts) {
        switch (part) {
          case 'p':
            color = this.props.textDefaults.paragraph.color;
            break;

          case 'h1':
            color = this.props.textDefaults.heading1.color;
            break;

          case 'h2':
            color = this.props.textDefaults.heading2.color;
            break;
        }
      }

      this.setState({activeColor: color});
    }

    if (fontInfo.letterSpacing) {
      this.setState({activeLetterSpacing: parseFloat(fontInfo.letterSpacing) || 0});
    } else {
      this.setState({activeLetterSpacing: 0});
    }

    if (fontInfo.textTransform) {
      this.setState({activeTextTransform: fontInfo.textTransform});
    } else {
      this.setState({activeTextTransform: 'none'})
    }
  }

  getActiveFontSize() {
    const fontInfo = this.editor.getFontInfo();

    if (!fontInfo.size) {
      const parts = this.editor.getPath().toLowerCase().split('>');
      let size = null;

      for (const part of parts) {
        switch (part) {
          case 'p':
            size = FontSizes.find(s => s.title === this.props.textDefaults.paragraph.size);
            break;

          case 'h1':
            size = FontSizes.find(s => s.title === this.props.textDefaults.heading1.size);
            break;

          case 'h2':
            size = FontSizes.find(s => s.title === this.props.textDefaults.heading2.size);
            break;
        }
      }

      return size || FontSizes[0];
    }

    return FontSizes.find(s => s.value === fontInfo.size) || FontSizes[0];
  }

  getActiveFontFace() {
    const fontInfo = this.editor.getFontInfo();

    if (!fontInfo.family) {
      const parts = this.editor.getPath().toLowerCase().split('>');
      let font = null;

      for (const part of parts) {
        switch (part) {
          case 'p':
            font = Fonts.find(s => s.id === Utilities.replaceAll(this.props.textDefaults.paragraph.font, '"', ''));
            break;

          case 'h1':
            font = Fonts.find(s => s.id === Utilities.replaceAll(this.props.textDefaults.heading1.font, '"', ''));
            break;

          case 'h2':
            font = Fonts.find(s => s.id === Utilities.replaceAll(this.props.textDefaults.heading2.font, '"', ''));
            break;
        }
      }

      return font || Fonts[0];
    }

    const fontFaces = fontInfo.family.split(',').map(f => {
      let nf = f.trim().toLowerCase();

      if (nf[0] === '"') {
        nf = nf.substr(1, nf.length);
      }
      if (nf[nf.length - 1] === '"') {
        nf = nf.substr(0, nf.length - 1);
      }

      return nf;
    });

    return Fonts.find(f => f.title.toLowerCase() === fontFaces[0]) || Fonts[0];
  }

  setTextAlign(align) {
    if (align === 'start') {
      align = 'left';
    }

    this.props.block.css('text-align', align);
    this.setState({activeTextAlign: `align-${align}`});
  }

  setLetterSpacing(keepFocus) {
    const value = parseFloat(this.state.activeLetterSpacing) || 0;

    this.editor.setTextLetterSpacing(value);
    this.setState({
      activeLetterSpacing: value
    });

    if (keepFocus) {
      const component = this.letterSpacingInputComponent;

      _.defer(function() {
        component.focus();
      });
    }
  }

  setTextTransform(transform) {
    this.editor.setTextTransform(transform);

    this.setState({
      activeTextTransform: transform
    });
  }

  render() {
    return (
      <div
        ref={(el) => this.inlineTextEditor = el}
        className={style.inlineTextEditor}
        style={{top: this.props.top, left: this.props.left}}
      >
        <div style={{width: '130px'}}>
          <NewSelect
            ref={(el) => this.textStyleSelector = el}
            data={this.textStyles}
            value={this.activeStyle}
            width={130}
            titleStyle={{marginRight: '15px', color: 'black', fontSize: '16px'}}
            selectArrowStyle={{color: 'black', fontSize: '10px'}}
            boxStyle={{border: 'none'}}
            options={{minimumInputLength: -1}}
            onSelect={this.handleTextStyle.bind(this)}
          />
        </div>
        <div style={{width: '140px'}}>
          <NewSelect
            ref={(el) => this.fontFaceSelector = el}
            data={Fonts}
            value={null}
            width={200}
            options={{minimumInputLength: -1}}
            titleStyle={{marginRight: '20px', color: 'black', fontSize: '16px'}}
            selectArrowStyle={{color: 'black', fontSize: '10px'}}
            boxStyle={{border: 'none'}}
            onSelect={this.handleFontFace.bind(this)}
          />
        </div>
        <div style={{width: '70px'}}>
          <NewSelect
            ref={(el) => this.fontSizeSelector = el}
            data={FontSizes}
            value={null}
            width={70}
            options={{minimumInputLength: -1}}
            titleStyle={{marginRight: '15px', color: 'black', fontSize: '16px'}}
            selectArrowStyle={{color: 'black', fontSize: '10px'}}
            boxStyle={{border: 'none'}}
            onSelect={this.handleFontSize.bind(this)}
          />
        </div>

        <div className={`${style.iteSection} ${style.isRightBorder}`}>
          <div
            className={`${style.iteItem} ${style.iteBold} ${this.state.textModifiers.boldActive ? style.iteItemActive : ''}`}
            onClick={this.toggleBold.bind(this)}
          >
            b
          </div>
          <div
            className={`${style.iteItem} ${style.iteItalic} ${this.state.textModifiers.italicActive ? style.iteItemActive : ''}`}
            onClick={this.toggleItalic.bind(this)}
          >
            i
          </div>
          <div
            className={`${style.iteItem} ${style.iteUnderline} ${this.state.textModifiers.underlineActive ? style.iteItemActive : ''}`}
            onClick={this.toggleUnderline.bind(this)}
          >
            u
          </div>
        </div>

        <div className={`${style.iteSection} ${style.isRightBorder}`}>
          <div style={{
            paddingRight: '5px'
          }}>
            <div
              className={`${style.iteItem} icon-${this.state.activeTextTransform === 'none' ? 'capitalize' : this.state.activeTextTransform}`}
              style={{
                width: '36px',
                display: 'flex',
                justifyContent: 'center',
                padding: 0
              }}
              onClick={this.toggleTextTransformPopup.bind(this)}
            />
            {this.state.popupVisible === 'textTransform' &&
              <div>
                <div
                  className={style.iteBackdrop}
                  onClick={() => this.setState({popupVisible: null})}
                />
                <div className={style.iteTextTransformPopup}>
                  <div
                    className={`${style.iteItem} icon-capitalize`}
                    onClick={() => this.setTextTransform('none')}
                  />
                  <div
                    className={`${style.iteItem} icon-lowercase`}
                    title='Lowercase'
                    onClick={() => this.setTextTransform('lowercase')}
                  />
                  <div
                    className={`${style.iteItem} icon-uppercase`}
                    title='Uppercase'
                    onClick={() => this.setTextTransform('uppercase')}
                  />
                </div>
              </div>
            }
          </div>

          <div
            ref={(el) => this.fontColorEl = el}
            className={style.iteItem}
            style={{textAlign: 'center'}}
            onClick={() => this.props.editor.showColorPicker(this.fontColorEl, this.state.activeColor, this.handleTextColor.bind(this))}
          >
            <div style={{marginTop: '-1px', paddingRight: '0px'}}>A</div>
            <div className={style.iteColorBar} style={{backgroundColor: this.state.activeColor}}/>
          </div>
          <div>
            <div
              className={`${style.iteItem} icon-link ${this.state.textModifiers.linkActive ? style.iteItemActive : ''}`}
              onClick={this.toggleLinkPopup.bind(this)}
            />
            {this.state.popupVisible === 'link' &&
              <div>
                <div
                  className={style.iteBackdrop}
                  onClick={() => this.setState({popupVisible: null})}
                />
                <div className={style.iteLinkPopup}>
                  <input
                    type='text'
                    value={this.state.activeLink || ''}
                    placeholder='https://example.com'
                    onChange={(ev) => this.setState({activeLink: ev.target.value})}
                    onKeyPress={(ev) => { if (ev.key === 'Enter') this.setLink.bind(this)() }}
                  />
                </div>
              </div>
            }
          </div>
        </div>

        <div className={`${style.iteSection} ${style.isRightBorder}`}>
          <div
            className={`${style.iteItem} icon-list-ol ${this.state.textModifiers.orderedListActive ? style.iteItemActive : ''}`}
            onClick={this.toggleOrderedList.bind(this)}
          />

          <div
            className={`${style.iteItem} icon-list-ul ${this.state.textModifiers.unorderedListActive ? style.iteItemActive : ''}`}
            onClick={this.toggleUnorderedList.bind(this)}
          />

          <div>
            <div
              className={`${style.iteItem} icon-${this.state.activeTextAlign}`}
              onClick={this.toggleTextAlignPopup.bind(this)}
            />
            {this.state.popupVisible === 'align' &&
              <div>
                <div
                  className={style.iteBackdrop}
                  onClick={() => this.setState({popupVisible: null})}
                />
                <div className={style.iteAlignPopup}>
                  <div
                    className={`${style.iteItem} icon-align-left`}
                    onClick={() => this.setTextAlign('left')}
                  />
                  <div
                    className={`${style.iteItem} icon-align-center`}
                    onClick={() => this.setTextAlign('center')}
                  />
                  <div
                    className={`${style.iteItem} icon-align-right`}
                    onClick={() => this.setTextAlign('right')}
                  />
                  <div
                    className={`${style.iteItem} icon-align-justify`}
                    onClick={() => this.setTextAlign('justify')}
                  />
                </div>
              </div>
            }
          </div>
        </div>

        <div
          className={style.iteSection}
          style={{paddingRight: 0}}
        >
          <div
            style={{
              width: '52px'
            }}
          >
              <div
                className={`${style.iteItem} ${style.iteLetterSpacing}`}
                onClick={this.toggleTextLetterSpacingPopup.bind(this)}
              >
                <div>Ab</div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '-7px',
                    fontSize: '13px'
                }}
                >
                  <div
                    className='icon-arrow-left'
                    style={{
                      padding: 0,
                      margin: '0 -3px 0 1px'
                    }}
                  />
                  <div className='icon-arrow-right'/>
                </div>
              </div>
            </div>

            {this.state.popupVisible === 'letterSpacing' &&
              <div>
                <div
                  className={style.iteBackdrop}
                  onClick={() => this.setState({popupVisible: null})}
                />

                <div className={style.iteLetterSpacingPopup}>
                    <input
                      ref={(el) => this.letterSpacingInputComponent = el}
                      type='text'
                      value={this.state.activeLetterSpacing || ''}
                      placeholder='0'
                      onChange={(ev) => this.setState({activeLetterSpacing: ev.target.value})}
                      onBlur={this.setLetterSpacing.bind(this)}
                      onKeyPress={(ev) => { if (ev.key === 'Enter') this.setLetterSpacing(true) }}
                    />

                    <div>px</div>
                </div>
              </div>
            }
        </div>

        <div style={{width: '80px'}}>
          <NewSelect
            ref={(el) => this.mergeTagSelector = el}
            data={this.props.mergeTagItems}
            value={null}
            width={270}
            text='name'
            placeholder='Insert'
            options={{minimumInputLength: -1}}
            titleStyle={{marginRight: '10px', color: 'black', fontSize: '16px'}}
            selectArrowStyle={{color: 'black', fontSize: '10px'}}
            boxStyle={{border: 'none'}}
            onSelect={this.handleInsertTag.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default InlineTextEditor;
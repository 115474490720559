import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react';
import ReactDOM from 'react-dom';

import FilterableCollection from 'js/utils/filterable_collection'
import GroupModel from 'js/models/group'
import GroupsCollection from 'js/collections/groups'
import OpportunitiesCollection from 'js/collections/opportunities'
import PeriodsCollection from 'js/collections/periods'
import DealListingView from 'app/_components/IOD-listing/IOD/deal-listing/deal-listing'
import IODGroupListingView from 'app/_components/IOD-listing/IOD-group-listing/IOD-group-listing'
import PriceListsView from 'app_v2/dialogs/price_lists/price_lists'
import dateFormat from 'js/utils/date-format'
import vent from 'js/vent'
import app from 'js/app'
import TextManager from 'app/text-manager'
import AppConfig from 'app/app-config'
import appContent from 'js/views/appcontent'
import GroupsClubbing from 'js/views/groups_clubbing'
import {SnapshotMarionette} from 'js/react_views/loans-snapshot/snapshot'
import MessageBox from 'js/views/message_box'
import ModalRegion from 'js/views/base/modal-region'
import sidebarViewTemplate from 'app/deals/sidebar.handlebars'
import sidebarItemViewTemplate from 'app/deals/sidebar-item.handlebars'
import buildMyGroupsList from 'js/utils/build_my_groups_list.js'

import { TagListSidebar } from 'js/react_views/detail_view_components/tag-list';

var SidebarItemView = Marionette.Layout.extend({
    className: 'item',
    template: Handlebars.compile(sidebarItemViewTemplate),
    ui: {
        tagsContainer: '.tags-container',
    },
    templateHelpers: function() {
        if (this.model.get('is_adhoc')) {
            if (!this.model.get('icon')) {
                return {
                    user_initials: app.user.get('name').split(' ').map(function (s) { return s.charAt(0); }).join(''),
                    user_photo: app.user.get('photo_url')
                };
            }
            else {
                return {
                    icon: this.model.get('icon')
                };
            }
        }

        return {
            is_smart: (this.model.get('group_type') === 'smart'),
            is_owner: (this.model.get('owner').id === app.user.get('id'))
        };
    },
    attributes: function() {
        return { id: this.model.get('id') };
    },
    events: {
        'click a': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.showGroup(this.model);
            });
        }
    },
    initialize: function(options) {
        this.parent = options.parent;
    },
    onRender: function() {
        this.parent.clubbing.initItem(this);
        this.$el.find('.basic-list-item').tooltip();

        let tags = this.model.get('tags')

        if (tags) {
            ReactDOM.render(
                <TagListSidebar
                    tags={this.model.get('tags', [])}
                />,
                this.ui.tagsContainer.get(0)
            );
        }
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.ui.tagsContainer.get(0));
    }
});

var SidebarView = Marionette.CompositeView.extend({
    className: 'opportunities-sidebar list-wrapper has-floating-header at-top',
    template: Handlebars.compile(sidebarViewTemplate),
    templateHelpers: function() {
        var section = this.parent.section;

        return {
            initials: app.user.get('name').split(' ').map(function(s) {
                return s.charAt(0);
            }).join(''),
            title: section ? section.name : TextManager.parseText('${ID_DEAL, plural, capitalize}'),
            activeDealsTitle: section ? `Active ${section.name}` : TextManager.getText('ID_ACTIVE_DEALS'),
            allDealsTitle: section ? `All ${section.name}` : TextManager.getText('ID_ALL_DEALS'),
        };
    },
    getItemView: function(model) {
        if (model && model.isFolder()) {
            return this.clubbing.SidebarFolderView;
        }

        return SidebarItemView;
    },
    itemViewOptions: function(model) {
        var options = {
            model: model,
            parent: this
        };

        if (model && model.isFolder()) {
            options.clubbing = this.clubbing;
        }

        return options;
    },
    ui: {
        loadedOppList: '.loaded-opportunity-list',
        dealsList: '.opportunity-group-list',
        active: '.active-deals',
        all: '.all-deals',
        snapshot: '.snapshot',
        newGroup: '.add-new-opportunity-group',
        regularHeader: '.list-header-nav',
        searchHeader: '.list-header-nav-search',
        searchInput: '.search-input'
    },
    events: {
        'click .add-new-opportunity-group': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.options.parent.showNewGroup();
            });
        },
        'click .active-deals': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.showActiveDeals();
            });
        },
        'click .all-deals': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.showAllDeals();
            });
        },
        'click .snapshot': function(ev) {
            ev.preventDefault();
            app.dirtyModelHandler.confirm(this, function() {
                this.parent.showSnapshot();
            });
        },
        'click .price-lists': function(ev) {
            ev.preventDefault();
            this.parent.showPriceLists();
        },
        'click .search': function() {
            this.ui.regularHeader.addClass('hidden');
            this.ui.searchHeader.removeClass('hidden');
            this.ui.searchInput.val('');
            this.ui.searchInput.focus();
        },
        'click .cancel-search': function() {
            this.ui.regularHeader.removeClass('hidden');
            this.ui.searchHeader.addClass('hidden');
            this.filterableCollection.resetFilter();
        },
        'keydown .search-input': function(ev) {
            const self = this;

            _.defer(function() {
                self.filterableCollection.filter(ev.target.value);
            });
        }
    },
    initialize: function(options) {
        _.extend(this, options);

        this.collection = new GroupsCollection();
        this.clubbing = this.createClubbing();
        this.filterableCollection = new FilterableCollection(this);
        this.fetchGroups();

        this.listenTo(vent, 'group:save', this.fetchGroups);

        // this is required for highlighting loaded groups
        this.listenTo(this.collection, 'sync', function() {
            if (this.selected) {
                this.highlightItem.apply(this, this.selected);
            }
        });
    },
    createClubbing: function() {
        var self = this;
        var clubbing = null;

        var onClubChange = function(render, save) {
            if (render) {
                // save and restore the scroll position after render
                var scrollPosition = self.$el.find('.content').scrollTop();

                self.collection.reset(clubbing.options.rootCollection.models);
                self.filterableCollection.setInitialCollection(self.collection);

                _.defer(function() {
                    self.$el.find('.content').scrollTop(scrollPosition);
                    self.$el.find('.content-container').nanoScroller();
                });
            }

            if (save) {
                app.user.updatePreference('opportunities_groups', clubbing.encodeCollection());
            }
        };

        clubbing = new GroupsClubbing({
            view: this,
            itemView: SidebarItemView,
            elementType: 'opportunities',
            onItemMoved: function() {
                onClubChange(true, true);
            },
            onFolderCreated: function() {
                onClubChange(true, false);
            },
            onItemInsertedToFolder: function() {
                onClubChange(true, true);
            },
            onFolderNameChanged: function() {
                onClubChange(false, true);
            },
            onFolderStateChanged: function() {
                self.$el.find('.content-container').nanoScroller();
                onClubChange(false, true)
            }
        });

        return clubbing;
    },
    fetchGroups: function(groupModelToSelect) {
        var self = this;

        this.collection.fetch({
            rows: -1,
            data: {
                element_type: 'opportunities'
            },
            success: function () {
                const section = self.parent.section;

                // add ad hoc groups
                if (AppConfig.getValue('deals.groups.show_system_adhoc_groups', true)) {
                    if (AppConfig.getValue('enableReportingPeriodGroups', true)) {
                        for (var i = self.periods.models.length - 1; i >= 0; --i) {
                            var period = self.periods.models[i];
                            self.addAdHocGroup(period.get('id'), period.get('name'), 'icon-calendar', 'periods');
                        }
                    }

                    var dashboard = app.user.get('preferences').default_dashboard;
                    if ((dashboard === 'ceo' || dashboard === 'sales_manager') && !AppConfig.getValue('disableDealElephants')) {
                        self.addAdHocGroup('elephants', 'Elephants', 'icon-elephant');
                    }

                    let lostTitle = TextManager.getText('ID_LOST_DEALS');
                    let wonTitle = TextManager.getText('ID_WON_DEALS');

                    if (section) {
                        if (section.lost_title) {
                            lostTitle = `${section.lost_title} ${section.name}`;
                        } else {
                            lostTitle = `Lost ${section.name}`;
                        }

                        if (section.won_title) {
                            wonTitle = `${section.won_title} ${section.name}`;
                        } else {
                            wonTitle = `Won ${section.name}`;
                        }
                    }

                    self.addAdHocGroup('team', section ? `Team ${section.name}` : TextManager.getText('ID_TEAM_DEALS'), 'icon-users');
                    self.addAdHocGroup('my', section ? `My ${section.name}` : TextManager.getText('ID_MY_DEALS'));
                    self.addAdHocGroup('lost', lostTitle, 'icon-funnels');
                    self.addAdHocGroup('won', wonTitle, 'icon-funnels');
                }

                // ...
                if (AppConfig.getValue('deals.groups.show_funnels_adhoc_groups')) {
                    const funnels = app.globalData.funnelsInfo.funnels;

                    for (const funnel of funnels) {
                        if (!AppConfig.getValue('funnel.is_enabled', true, funnel)) {
                            continue;
                        }

                        self.addAdHocGroup(funnel.id, funnel.name, 'icon-funnel-sm', 'funnels');
                    }
                }

                //Filter groups based on deals section
                var filteredCollection = self.collection;
                if (section && section.id !== 'deals'){
                    filteredCollection = new GroupsCollection(self.collection.filter(model => model.get('is_adhoc') || model.get('name').toLowerCase().includes(section.name.toLowerCase())))
                }

                if (section && section.id === 'deals') {
                    var preferences = app.user.get('client').preferences;

                    if (preferences && preferences.deals_sections) {
                        const dealsSections = JSON.parse(preferences.deals_sections);

                        dealsSections.forEach(section => {
                            filteredCollection = new GroupsCollection(filteredCollection.filter(model => model.get('is_adhoc') || !model.get('name').toLowerCase().includes(section.name.toLowerCase())))
                        })
                    }
                }

                const clientCustomFilter = AppConfig.getValue('deals.groups.filter_function');

                if (clientCustomFilter) {
                    filteredCollection = new GroupsCollection(filteredCollection.filter(clientCustomFilter));
                }

                let opportunityGroups = app.user.get('preferences').opportunities_groups;

                if (AppConfig.getValue('deals.groups.show_my_groups')) {
                    opportunityGroups ??= filteredCollection.map(item => item.get('id'));
                    opportunityGroups = buildMyGroupsList(filteredCollection, opportunityGroups);
                }

                // update collection after group modification and filter
                self.collection.reset(self.clubbing.manageCollection(filteredCollection, opportunityGroups).models);
                // update scrollbar after group modification
                self.$el.find('.content-container').nanoScroller();

                // ...
                if (groupModelToSelect) {
                    var gid = groupModelToSelect.get('id');

                    self.highlightItem('groups', gid);
                    self.options.parent.rememberGroup('groups', null, gid);
                }

                self.filterableCollection.setInitialCollection(self.collection);
            }
        });
    },
    addAdHocGroup: function(id, name, icon, type) {
        this.collection.add(new GroupModel({
            id: id,
            name: name,
            icon: icon,
            element_type: 'opportunities',
            type: type,
            is_adhoc: true
        }), {at: 0, silent: true});
    },
    onRender: function() {
        this.listenTo(this.parent, 'select', this.highlightItem);
        this.listenTo(this.parent, 'group:delete', this.fetchGroups);

        this.$el.find('[data-toggle="tooltip"]').tooltip();
        this.$el.find('.active-deals a').tooltip();
    },
    onDomRefresh: function() {
        // show scrollbar initially
        this.$el.find('.content-container').nanoScroller();
    },
    appendHtml: function(collectionView, itemView){
        collectionView.ui.loadedOppList.append(itemView.el);
    },
    showGroup: function(model) {
        this.parent.showGroup(model);
    },
    highlightItem: function(type, id) {
        this.selected = arguments;

        var activeEl;
        if ((type === 'groups') || (type === 'periods') || (type === 'funnels')) {
            activeEl = this.$el.find(".group-list div[id='" + id + "']");
        }
        else {
            activeEl = this.ui[type];
        }

        activeEl.addClass('active');
        this.$el.find('.item.active').not(activeEl).removeClass('active');

        this.filterableCollection.findActiveModel();
    }
});

// TODO: Apply 'details-visible' class to this view, and remove after detail view is closed
export default Marionette.Layout.extend({
    className: 'deals-section',
    template: Handlebars.compile([
        '<article id="deals-sidebar" class="sidebar"></article>',
        '<article id="deals-view-pane"></article>'
    ].join('')),
    regions: {
        'sidebarRegion': '#deals-sidebar',
        'contentRegion': '#deals-view-pane',
        'priceListsRegion': {
            selector: '.price-lists-region',
            regionType: ModalRegion.extend({backdrop: 'static', keyboard: false})
        }
    },
    events: {
        'click .sidebar .collapse': function(ev) {
            var self = this;

            ev.preventDefault();

            $(ev.currentTarget).tooltip('hide');

            this.$el.addClass('sidebar-collapsed');

            this.contentRegion.$el.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(e) {
                if ($(e.target).attr('id') === self.contentRegion.$el.attr('id')) {
                    vent.trigger('deals:sidebarCollapsed');
                    vent.trigger('sidebar:collapse');
                }
            });

            this.sidebarRegion.$el.one('click', function() {
                self.$el.removeClass('sidebar-collapsed');

                self.contentRegion.$el.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(e) {
                    if ($(e.target).attr('id') === self.contentRegion.$el.attr('id')) {
                        vent.trigger('deals:sidebarUncollapsed');
                        vent.trigger('sidebar:collapse');
                    }
                });
            });
        }
    },
    initialize: function(options) {
        _.extend(this, options);

        if (options.sectionId) {
            var preferences = app.user.get('client').preferences;

            if (preferences && preferences.deals_sections) {
                const dealsSections = JSON.parse(preferences.deals_sections);
                this.section = dealsSections.find(s => s.id === options.sectionId);
            }
        }
    },
    onRender: function() {
        var view = this,
            type = this.type,
            options = this.options;

        var initialGroupKey = 'initial_deal_group';

        if (this.section) {
            initialGroupKey += `_${this.section.id}`;
        }

        var initialDealGroup = app.user.get('preferences')[initialGroupKey];
        var periods = new PeriodsCollection(app.globalData.periods);

        view.periods = view.parsePeriods(periods);

        view.sidebarRegion.show(new SidebarView({
            parent: view,
            periods: view.periods
        }));

        // if we come from direct URL
        if (type === 'active') {
            view.showActiveDeals(options);
        }
        else if (type === 'all') {
            view.showAllDeals(options);
        }
        else if (type === 'won') {
            view.showWonDeals(options);
        }
        else if (type === 'lost') {
            view.showLostDeals(options);
        }
        else if (type === 'my') {
            view.showMyDeals(options);
        }
        else if (type === 'team') {
            view.showTeamDeals(options);
        }
        else if (type === 'favorites') {
            view.showElephants(options);
        }
        else if (type === 'periods' && view.id) {
            view.showPeriodDeals(view.id, options);
        }
        else if (type === 'funnels' && view.id) {
            view.showFunnelDeals(view.id, options);
        }
        else if (type === 'groups' && view.id) {
            view.showGroup(new GroupModel({id: view.id}));
        }
        else if (type === 'new') {
            view.showNewGroup();
        }
        else if (type === 'snapshot') {
            view.showSnapshot();
        }
        // remembered group
        else if (initialDealGroup) {
            if (initialDealGroup.type === 'active') {
                view.showActiveDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'all') {
                view.showAllDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'won') {
                view.showWonDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'lost') {
                view.showLostDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'my') {
                view.showMyDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'team') {
                view.showTeamDeals(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'favorites') {
                view.showElephants(initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'periods') {
                view.showPeriodDeals(initialDealGroup.id, initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'funnels') {
                view.showFunnelDeals(initialDealGroup.id, initialDealGroup.options);
            }
            else if (initialDealGroup.type === 'groups') {
                view.showGroup(new GroupModel({id: initialDealGroup.id}));
            }
            else if (initialDealGroup.type === 'snapshot') {
                view.showSnapshot();
            }
            else {
                view.showActiveDeals(options);
            }
        }
        else {
            view.showActiveDeals(options);
        }
    },
    initSidebarAndContentWidth: function() {
        const sidebarWidth = ((app.user.get('preferences') || {}).groups_sidebar_width || {})['deals-sidebar'];

        if (sidebarWidth) {
            const self = this;

            _.defer(function() {
                self.sidebarRegion.$el?.width(sidebarWidth);
                self.contentRegion.$el?.css('left', `${sidebarWidth}px`);
            });
        }
    },
    parsePeriods: function(periods) {
        var models = periods.models,
            now = new Date(),
            parsedPeriods;

        var periodsToShow = _.first(_.filter(models, function(model, idx) {
            model.quarter = (idx %= 4) + 1
            return (new Date(model.get('end_date'))) > now;
        }), 4);

        if (this.isQuarterPeriod(periods)) {
            parsedPeriods = _.map(periodsToShow, function(model) {
                var date = new Date(model.get('start_date')+'T00:00:00'),
                    quarter = model.quarter || Math.floor(date.getMonth() / 3) + 1,
                    year = String(date.getFullYear());

                return {
                    id: model.get('id'),
                    name: 'Q' + quarter + " '" + year.substring(year.length, 2) + '  ' + TextManager.parseText('${ID_DEAL, plural, capitalize}')
                };
            });
        }
        else {
            parsedPeriods = _.map(periodsToShow, function(model) {
                var date = new Date(model.get('start_date')),
                    month = dateFormat.shortFormatMonth(date.getMonth()),
                    year = String(date.getFullYear());

                return {
                    id: model.get('id'),
                    name: month + " '" + year.substring(year.length, 2) + '  ' + TextManager.parseText('${ID_DEAL, plural, capitalize}')
                };
            });
        }

        return new Backbone.Collection(parsedPeriods);
    },
    isQuarterPeriod: function(periods) {
        var collection = periods;
        var lastEndDate = new Date(collection.models[collection.length - 1].get('end_date'));
        var yearBeforeDate = new Date(lastEndDate.getFullYear() - 1, lastEndDate.getMonth(), lastEndDate.getDate());

        var lastYearCollection = collection.filter(function(model) { return new Date(model.get('end_date')) > yearBeforeDate });

        return lastYearCollection.length <= 8;
    },
    showAllDeals: function(options) {
        this.showContent(new DealListingView(_.extend({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'all',
            title: this.section ? `All ${this.section.name}` : TextManager.getText('ID_ALL_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            section: this.section
        }, options)));
        this.trigger('select', 'all');
        this.rememberGroup('all', options);
    },
    showActiveDeals: function(options) {
        this.showContent(new DealListingView(_.extend({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'active',
            title: this.section ? `Active ${this.section.name}` : TextManager.getText('ID_ACTIVE_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                phase_types: ['user']
            },
            section: this.section
        }, options)));
        this.trigger('select', 'active');
        this.rememberGroup('active', options);
    },
    showWonDeals: function(options) {
        this.showContent(new DealListingView(_.extend({
            initInTableMode: true,
            type: 'won',
            title: this.section ? `Won ${this.section.name}` : TextManager.getText('ID_WON_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                phase_types: ['won']
            },
            section: this.section
        }, options)));
        this.trigger('select', 'groups', 'won');
        this.rememberGroup('won', options);
    },
    showLostDeals: function(options) {
        this.showContent(new DealListingView(_.extend({
            initInTableMode: true,
            type: 'lost',
            title: this.section ? `Lost ${this.section.name}` : TextManager.getText('ID_LOST_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                phase_types: ['lost']
            },
            section: this.section
        }, options)));
        this.trigger('select', 'groups', 'lost');
        this.rememberGroup('lost', options);
    },
    showMyDeals: function(options) {
        var fetchOptions = {
            owner_id: app.user.get('id'),
            by_period_id: 'current'
        };

        if (AppConfig.getValue('dontFetchClosedDealsInMyDealsGroup')) {
            fetchOptions.phase_types = ['user']
        }

        this.showContent(new DealListingView(_.extend({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'my',
            title: this.section ? `My ${this.section.name}` : TextManager.getText('ID_MY_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: fetchOptions,
            section: this.section
        }, options)));
        this.trigger('select', 'groups', 'my');
        this.rememberGroup('my', options);
    },
    showTeamDeals: function(options) {
        this.showContent(new DealListingView(_.extend({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'team',
            title: this.section ? `Team ${this.section.name}` : TextManager.getText('ID_TEAM_DEALS'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                team: true,
                by_period_id: 'current'
            },
            section: this.section
        }, options)));
        this.trigger('select', 'groups', 'team');
        this.rememberGroup('team', options);
    },
    showPeriodDeals: function(id, options) {
        const period = this.periods.get(id);

        if (!period) {
            this.showAllDeals(options);
            return;
        }

        this.showContent(new DealListingView({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'periods',
            title: period.get('name'),
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                closing_in_period_id: id
            },
            section: this.section
        }));
        this.trigger('select', 'periods', id);
        this.rememberGroup('periods', options, id);
    },
    showFunnelDeals: function(id, options) {
        const funnel = app.globalData.funnelsInfo.funnels.find(f => f.id === id);
        let demographicMap = null;

        if (funnel && funnel.location) {
            demographicMap = {
                funnelId: funnel.id,
                staticPins: {
                    [funnel.name]: {
                        address: funnel.location.address
                    }
                }
            };
        }

        this.showContent(new DealListingView({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'funnels',
            title: app.globalData.funnelsInfo.funnels.find(f => f.id === id).name,
            rows: -1,
            elementType: 'opportunities',
            fetchOptions: {
                funnel_id: id
            },
            section: this.section,
            demographicMap: demographicMap
        }));
        this.trigger('select', 'funnels', id);
        this.rememberGroup('funnels', options, id);
    },
    showNewGroup: function() {
        this.selectedBeforeNewGroup = this.sidebarRegion.currentView.selected;

        var model = new GroupModel();
        this.showContent(new IODGroupListingView(_.defaults({
            model: model,
            parent: this
        }, { editing: true, preset_element_type: 'opportunities' })));
        this.trigger('select', 'newGroup');
    },
    showSnapshot: function() {
        this.showContent(new SnapshotMarionette());

        this.trigger('select', 'snapshot');
        this.rememberGroup('snapshot');
    },
    showPriceLists: function() {
        const priceListsView = new PriceListsView();
        this.priceListsRegion.show(priceListsView);
    },
    /*
     *  We use only editing part from view until refactoring of edit views.
     */
    editGroup: function(model) {
        var view = new IODGroupListingView(_.defaults({
            model: model,
            parent: this,
            disableCancel: true,
            editing: true
        }));
        this.showContent(view);
        //view.$el.addClass('edit-mode');
        this.listenTo(view, 'edit:end', function() {
            this.showGroup(model);
        }, this);
    },
    showGroup: function(model, options) {
        var view = this;
        var type = model.get('type');

        if (type === 'periods') {
            this.showPeriodDeals(model.get('id'));
        }
        else if (type === 'funnels') {
            this.showFunnelDeals(model.get('id'));
        }
        else if (type === 'active') {
            this.showActiveDeals();
        }
        else if (type === 'all') {
            this.showAllDeals();
        }
        else if (type === 'snapshot') {
            this.showSnapshot();
        }
        else {
            switch(model.get('id')) {
                case 'won':
                    this.showWonDeals();
                    break;

                case 'lost':
                    this.showLostDeals();
                    break;

                case 'my':
                    this.showMyDeals();
                    break;

                case 'elephants':
                    this.showElephants();
                    break;

                case 'team':
                    this.showTeamDeals();
                    break;

                default:
                    model.fetch({
                        success: function() {
                            view.showContent(new DealListingView(_.defaults({
                                initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
                                type: 'groups',
                                model: model,
                                rows: -1,
                                elementType: 'opportunities',
                                fetchOptions: {
                                    group_id: model.get('id')
                                },
                                section: view.section
                            }, options)));
                            view.trigger('select', 'groups', model.get('id'));
                            view.rememberGroup('groups', options, model.get('id'));
                        },
                        error: function(_, response) {
                            view.showActiveDeals(options);

                            if (response && response.status === 403) { // do not have permissions
                                MessageBox.showOk({
                                    icon: 'icon-warning',
                                    message: 'You do not have permissions to view this group'
                                }, view);
                            }
                        }
                    });
                    break;
            }
        }
    },
    showElephants: function(options) {
        this.showContent(new DealListingView(_.defaults({
            initInTableMode: AppConfig.getValue('deals.groups.open_in_table_view', false),
            type: 'elephants',
            title: "Elephants",
            elementType: 'opportunities',
            fetchOptions: {
                group_id: 'favorites'
            },
            section: this.section
        }, options)));
        this.trigger('select', 'groups', 'elephants');
        this.rememberGroup('favorites', options);
    },
    showContent: function(view) {
        if (appContent.contentRegions.length > 1 && this.contentRegion.currentView) {
            appContent.pop({});
        }

        this.listenTo(view, 'push-view:deal:new', function(model) {
            this.trigger('push-view:deal:new', model);
        });
        this.listenTo(view, 'push-view:deal:show', function(model) {
            this.trigger('push-view:deal:show', model);
        });

        this.listenTo(view, 'push-view:individual:new', function(model) {
            this.trigger('push-view:individual:new', model);
        });
        this.listenTo(view, 'push-view:individual:show', function(model) {
            this.trigger('push-view:individual:show', model);
        });

        this.listenTo(view, 'push-view:organization:new', function(model) {
            this.trigger('push-view:organization:new', model);
        });
        this.listenTo(view, 'push-view:organization:show', function(model) {
            this.trigger('push-view:organization:show', model);
        });

        this.listenTo(view, 'replace-view:group:show', function(model) {
            if(model.get('element_type') !== 'opportunities'){
                this.trigger('set-view:group:show', model);
            }else{
                this.showGroup(model);
            }
        });

        this.listenTo(view, 'group:edit', function(model) {
            this.editGroup(model);
        });

        this.listenTo(view, 'group:delete', function() {
            this.showActiveDeals();

            var self = this;
            _.defer(function() {
                self.trigger('group:delete');
            });
        });

        this.listenTo(view, 'show close', function() {
            _.defer(function() {
                vent.trigger('AppContent:contentChange');
            });
        });

        this.listenTo(view, 'funnel:expanded', function() {
            view.$el.addClass('allow-overflow');
        });

        this.listenTo(view, 'funnel:shrinked', function() {
            view.$el.removeClass('allow-overflow');
        });

        this.listenTo(view, 'edit:cancel', function(model) {
            if (model.isNew()) {
                if (this.selectedBeforeNewGroup && this.selectedBeforeNewGroup[1]) {
                    this.showGroup(new GroupModel({
                        type: this.selectedBeforeNewGroup[0],
                        id: this.selectedBeforeNewGroup[1]
                    }));
                }
                else {
                    this.showActiveDeals();
                }
            }
        });

        _.defer(function() {
            if (this.isClosed) {
                return;
            }
            this.contentRegion.show(view);
            this.initSidebarAndContentWidth();
        }.bind(this));
    },
    closeItem: function() {
        this.contentRegion.reset();
    },
    /**
     * store last visited deal group in DB
     *
     * @param type      string      group type
     * @param options   object      options
     * @param id        uuid        group id, if applicable
     */
    rememberGroup: function(type, options, id) {
        let prefKey = 'initial_deal_group';

        if (this.section) {
            prefKey += `_${this.section.id}`;
        }

        app.user.updatePreference(prefKey, {
            type: type,
            options: options,
            id: id
        });
    },
    getUrl: function() {
        var url = ['deals'];

        if (this.section) {
            url.push(`section/${this.section.id}`);
        }

        var view = this.contentRegion && this.contentRegion.currentView;
        if (view && !view.isClosed && view.getUrl) {
            var model = view.options.model;

            if (model) {
                if (view.options.editing && view.options.model.isNew()) {
                    url.push('groups/new');
                }
                else {
                    url.push('groups/' + (model.get('short_id') || model.get('id')));
                }
            }
            else {
                url.push(view.getUrl());
            }
        }
        return url.join('/');
    },
    getParams: function() {
        var view = this.contentRegion && this.contentRegion.currentView;
        if (view && !view.isClosed && view.getParams) {
            return view.getParams();
        } else {
            return {};
        }
    }
});

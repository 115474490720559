import React from 'react';
import _ from 'underscore';

import {Select} from 'js/react_views/widgets/select';

import style from './multiselect.css';

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);

        this.handleRemove = this.handleRemove.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleBodyClick = this.handleBodyClick.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleRemove(ev, item) {
        ev.preventDefault();
        this.props.onRemove(item);
    }

    handleAdd(items) {
        this.props.onAdd(items[0]);
    }

    handleBodyClick() {
        // TODO: ideally the code using a Select shouldn't need to know
        // that it's wrapped in a HOC and it needs to use getInstance
        // to get the underlying component
        this.select.getInstance().open();
    }

    handleItemClick(ev) {
        ev.stopPropagation();
    }

    render() {
        const items = this.props.items || [];
        const disabledIds = _.object(_.map(items, item => [item.id, true]));

        return (
            <div
                className={style.MultiSelect}
                onClick={this.handleBodyClick}>

                {
                    items.map((item) =>
                        <div
                            key={item.id}
                            className={style.option}
                            style={_.extend(this.props.itemCustomCSS, {
                                padding: '3px 47px 3px 11px',
                                display: 'inline-block',
                                borderRadius: '3px',
                                position: 'relative',
                                margin: '3px 0 0 5px',
                                maxWidth: 'calc(100% - 10px)',
                                wordBreak: 'break-word'
                            })}
                            onClick={this.handleItemClick}>

                            <span
                                className={style.name}
                                onClick={() => this.props.onItemClick(item)}>

                                {item.name}
                            </span>
                            <a
                                className={style.removeOption}
                                href="#"
                                onClick={(ev) => this.handleRemove(ev, item)} />
                        </div>
                    )
                }
                <Select inlineInput={true}
                        onSearch={this.props.onSearch}
                        itemView={this.props.selectItem}
                        onSelect={this.handleAdd}
                        disabledIds={disabledIds}
                        ref={(select) => { this.select = select; }} />
            </div>
        );
    }
}

export default MultiSelect;

import _ from 'underscore'
import $ from 'jquery'
import d3 from 'd3'
import Handlebars from 'handlebars'

import D3WidgetsStyle from 'js/d3/widgets_style'
import D3Utilities from 'js/d3/utilities'
import Currency from 'js/utils/currency'
import dateFormat from 'js/utils/date-format'
import app from 'js/app'


function campaigncontributionchart() {
    var x = 0,
        y = 0,
        padding = {top: 70, right: 0, bottom: 70, left: 0},
        width = 600 - padding.left - padding.right,
        height = 380 - padding.top - padding.bottom,
        data = {},
        initThings = true;
    var clipRect;
    var clippingActive = false;
    var activePath;
    var activePathIdx;
    // var highlightedPath;
    var y_axis_padding = 60;
    var swatch_size = 10;
    var row_height = 24;
    var row_padding = 0.2;
    var showFullInfo = false;
    var emptySVG = false;
    var svg;
    var pastWavesChart;
    var futureWavesChart;
    var controlWavesChart;
    var pastWaves;
    var futureWaves;
    var controlWaves;
    var selectedPath = -1;
    var currency = app.user.get('client').default_currency;

    var clearSVG = function(svg) {
        var tooltipSelector = svg.select('.tooltip-selector');

        if (tooltipSelector[0][0]) {
            $(tooltipSelector).tooltip('destroy');
        }

        svg.select('*').remove();
        activePath = null;
        activePathIdx = -1;
        initThings = true;
        emptySVG = false;
    };

    var color = function(i) {
        return D3WidgetsStyle.groupColor(data.group_data.length - i - 1);
    };

    var colorLight = function(i) {
        return D3WidgetsStyle.groupColorLight(data.group_data.length - i - 1);
    };

    var deactivateClipping = function() {
        if (!clippingActive) {
            return;
        }

        svg
            .select('.vertical-line')
            .attr('y2', 0);

        pastWavesChart.selectAll('path')
            .attr('clip-path', null);

        $(svg.select('.tooltip-selector')[0]).tooltip('hide');

        clippingActive = false;
    };

    var activatePath = function(newPath, i) {
        // all graphs turn grey except the active one
        pastWaves
            .classed('inactive', function(d, j) {
                return i !== j;
            })
            .classed('active', function(d, j) {
                return i === j;
            });

        futureWaves
            .classed('inactive', function(d, j) {
                return i !== j;
            })
            .classed('active', function(d, j) {
                return i === j;
            });

        activePath = newPath;
        activePathIdx = i;
    };

    var deactivatePath = function() {
        if (!pastWaves) {
            return;
        }

        // ...
        pastWaves
            .classed('inactive active', false)
            .style('fill', function(d, j) {
                return color(j);
            });

        futureWaves
            .classed('inactive active', false);

        activePath = null;
        activePathIdx = -1;
        deactivateClipping();
    };

    var selectPath = function(idx, selected) {
        var past = pastWavesChart.select('#path_' + idx);
        var future = futureWavesChart.select('#path_' + idx);

        past.classed('inactive', !selected);
        past.classed('active', selected);

        future.classed('inactive', !selected);
        future.classed('active', selected);
    };

    function chart(selection) {

        if (data.group_data.length === 0) {
            clearSVG(selection.select('svg'));
            return;
        }

        var n = data.group_data.length, // number of layers
            m = data.group_data[0].values.length, // number of samples per layer
            stack = d3.layout.stack().offset("wiggle");

        var layers = stack(d3.range(n).map(function(i) {
            return data.group_data[data.group_data.length - i - 1].values;
        } ));

        var x = d3.scale.linear()
            .domain([0, m - 1])
            .range([0, width]);

        var y = d3.scale.linear()
            .domain([0, d3.max(layers, function(layer) {
                return d3.max(layer, function(d) { return d.y0 + d.y; });
            })])
            .range([height, 0]);

        var area = d3.svg.area()
            .x(function(d) { return x(d.x); })
            .y0(function(d) { return y(d.y0); })
            .y1(function(d) { return y(d.y0 + d.y); })
            .interpolate('basis');

        var setPopoverInfo = function(w, mouse) {
            var ap = activePath;
            var api = activePathIdx;

            if (selectedPath !== -1) {
                ap = pastWavesChart.select('#path_' + selectedPath);
                api = selectedPath;
            }

            var isActive = ap ? true : false;
            var color = isActive ? $(ap[0]).css('fill') : '';
            var x = Math.max(mouse[0], 0);
            var idx = Math.floor(x / ((width + padding.left + padding.right) / data.time_points.length));
            var date = data.time_points[idx];
            var dg;
            var barHeight = 0;
            var dataIsRevenue = data.type === 'revenue';

            if (isActive) {
                dg = data.group_data[data.group_data.length - api - 1];
                barHeight = ((dg.values[idx].y / data.totals[idx]) * 100) + '%';
            }

            var ti = {
                date: dateFormat.shortFormat(new Date(date)),
                name: isActive && dg.group.name,
                value: isActive &&
                    (dataIsRevenue ? Currency.shortFormat(currency, dg.values[idx].y) : dg.values[idx].y),
                total: dataIsRevenue ? Currency.shortFormat(currency, data.totals[idx]) : data.totals[idx],
                info: 'No data available',
                color: isActive && color,
                active: isActive
            };

            var title = dataIsRevenue ? 'Revenue' : 'Count';

            var html = ['<div class="date">{{date}}</div>',
                '<div class="title">{{#if name}}{{name}}{{else}}' + title + '{{/if}}</div>',
                '<div class="bar-chart">',
                '<div class="bar" style="background:' + color + '; height:' + barHeight + ';"></div>',
                '</div>',
                '<div class="values">',
                '<span class="value" style="color:' + color + ';">',
                '{{#if active}}{{value}}{{else}}{{total}}{{/if}}</span>',
                '<span class="total">{{#if active}} / {{total}}{{/if}}</span>',
                '</div>'].join('');
            var template = Handlebars.compile(html)(ti);

            $(w).tooltip('destroy');

            $(w)
                .tooltip({
                    trigger: 'hover',
                    html: true,
                    title: template,
                    placement: 'left'
                })
                .data('tooltip')
                .tip()
                .addClass('campaign-contribution-tooltip');


            // TODO: Update existing tooltip if already exists
            // NOTE: This was causing issues with weird zombie tooltips
            // if ($(w).data('tooltip')) {
            //     $(w)
            //         .attr('title', template)
            //         .tooltip('fixTitle');

            $(w)
                .data('tooltip')
                .tip()
                .toggleClass('active-tooltip', isActive);

            $(w).tooltip('show');
        };

        var textEllipsis = function(text, maxWidth) {
            text.each(function() {
                D3Utilities.cropText(this, maxWidth, true, 'data-original-text');
            });
        };

        var update = function() {
            var barY = d3.scale.ordinal()
                .domain(data.group_data.map(function(d) { return d.group.id; }))
                .rangeRoundBands([0, row_height * data.group_data.length], row_padding, 0);

            var yAxis = d3.svg.axis()
                .scale(barY)
                .orient('left')
                .tickPadding(y_axis_padding)
                .tickSize(0,0) // Remove black 'domain' bar
                .tickFormat(function(d,i) { return data.group_data[i].group.name; });

            // ...
            svg = selection.select('svg');

            if (emptySVG) {
                clearSVG(svg);
            }

            // ...
            if (initThings) {
                svg
                    .append('g')
                        .attr('transform', 'translate(' + padding.left + ',0)')
                        .attr('class', 'stream-graph-container')
                        .append('g')
                            .attr('transform', 'translate(0,' + padding.top + ')')
                            .attr('class', 'y-axis')
                            .call(yAxis)
                            .selectAll('.tick')
                                .append('rect')
                                    .attr('class', 'color-swatch')
                                    .attr('x', -y_axis_padding/2 - swatch_size/2)
                                    .attr('y', -swatch_size/2)
                                    .attr('height', swatch_size)
                                    .attr('width', swatch_size);

                svg.select('g')
                    .append('g')
                        .attr('class', 'stream-graph');

                svg.select('.stream-graph')
                    .append('g')
                        .attr('class', 'futurewaveschart');

                svg.select('.stream-graph')
                    .append('g')
                        .attr('class', 'pastwaveschart');

                svg.select('.stream-graph')
                    .append('g')
                        .attr('class', 'past-future-separator')
                        .append('line')
                            .attr('class', 'vertical-line')
                            .attr('x1', 0)
                            .attr('y1', 0)
                            .attr('x2', 0)
                            .attr('y2', 0);

                svg.select('.past-future-separator')
                    .append('rect')
                        .attr('class', 'tooltip-selector')
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr('width', 1)
                        .attr('height', 2)
                        .style('fill-opacity', 0);

                // this two objects are only to manage the mouse input
                svg.select('.stream-graph')
                    .append('g')
                        .attr('class', 'controlchart')
                        .append('rect')
                            .attr('class', 'controlchart-rect')
                            .attr('x', 0)
                            .attr('y', 0)
                            .attr('width', width + padding.left + padding.right)
                            .attr('height', height + padding.top + padding.bottom)
                            .style('fill-opacity', 0);

                svg.select('.stream-graph')
                    .append('g')
                        .attr('class', 'controlwaveschart')
                        .style('fill-opacity', 0);


                svg.select('.stream-graph')
                    .append('svg:clipPath')
                        .attr('id', 'clip')
                        .append('svg:rect')
                        .attr('id', 'clip-rect')
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr('width', 0)
                        .attr('height', height + padding.top + padding.left);

                clipRect = svg.select('#clip-rect');

                initThings = false;
            }

            svg
                .classed('expanded', showFullInfo)
                .attr('width', width + padding.left + padding.right)
                .attr('height', height + padding.top + padding.bottom);

            // Apply text ellipsis to long yaxis labels
            svg
                .select('g.y-axis')
                .call(yAxis)
                .selectAll(".tick text")
                .call(textEllipsis, padding.left - y_axis_padding - Math.floor(y_axis_padding/2));


            svg
                .select('g.y-axis')
                    .selectAll('.color-swatch')
                    .style('fill', function(d, i) { return D3WidgetsStyle.groupColor(i); });

            // ...
            pastWavesChart = svg.select('g.pastwaveschart');
            futureWavesChart = svg.select('g.futurewaveschart');
            controlWavesChart = svg.select('g.controlwaveschart');

            pastWaves = pastWavesChart.selectAll('path')
                .data(layers);

            futureWaves = futureWavesChart.selectAll('path')
                .data(layers);

            controlWaves = controlWavesChart.selectAll('path')
                .data(layers);

            // Update positioning
            svg.select('.stream-graph-container')
                // .transition()
                .attr('transform', 'translate(' + padding.left + ',0)');
            svg.select('.stream-graph')
                .attr('transform', 'translate(0,0)');
            futureWavesChart
                .attr('transform', 'translate(0,' + padding.top + ')');
            pastWavesChart
                .attr('transform', 'translate(0,' + padding.top + ')');
            controlWavesChart
                .attr('transform', 'translate(0,' + padding.top + ')');
            svg.select('.past-future-separator')
                .attr('transform', 'translate(0,0)');

            var updateClipping = function(element, w) {
                var x = Math.max(w[0], 0),
                    y = Math.max(w[1], 0);

                clipRect.attr('width', x);

                svg
                    .select('.vertical-line')
                    .attr('x1', x)
                    .attr('x2', x)
                    .attr('y2', height + padding.top + padding.bottom );

                svg
                    .select('.tooltip-selector')
                    .attr('x', x)
                    .attr('y', y + (element === controlWaves ? padding.bottom : 0));

                setPopoverInfo(svg.select('.tooltip-selector')[0], w);

                // ...
                if (!clippingActive) {
                    pastWavesChart.selectAll('path')
                        .attr('clip-path', 'url(#clip)');

                    clippingActive = true;
                }
            };

            var onMouseMove = function(element, mouse)
            {
                var x = mouse[0];

                if ((x < 0) || (x > width)){
                    deactivateClipping();
                }
                else {
                    updateClipping(element, mouse);
                }
            };

            var onMouseClick = function(element, mouse) {
                d3.event.preventDefault();

                if (activePath) {
                    deactivatePath();
                    onMouseMove(element, mouse);
                }
            };

            // future charts
            futureWaves.enter()
                .append('path')
                .attr('class', 'stream stream-future')
                .attr('id', function(d, i) { return 'path_' + i; })
                .style('fill', function(d, i) { return colorLight(i); });

            futureWaves.exit()
                .remove();

            futureWaves
                .attr('d', area);

            // past
            pastWaves.enter()
                .append('path')
                .attr('class', 'stream stream-past')
                .attr('id', function(d, i) { return 'path_' + i; })
                .style('fill', function(d, i) { return color(i); });

            pastWaves.exit()
                .remove();

            // update
            pastWaves
                .attr('d', area);

            // control waves
            controlWaves.enter()
                .append('path')
                // .attr('class', 'stream stream-hidden')
                .attr('id', function(d, i) { return 'path_' + i; });

            controlWaves.exit()
                .remove();

            controlWaves
                .attr('d', area)
                .on('mouseover', function() {
                    // highlightedPath = this;
                    onMouseMove(controlWaves, d3.mouse(this));
                })
                .on('mousemove', function() {
                    onMouseMove(controlWaves, d3.mouse(this));
                })
                .on('mouseout', function() {
                    // $(this).tooltip('destroy');
                    // highlightedPath = null;
                    deactivateClipping();
                })
                .on('click', function(d, i) {
                    d3.event.stopPropagation();
                    d3.event.preventDefault();

                    if (activePath) {
                        onMouseClick(controlWaves, d3.mouse(this));

                        if (!showFullInfo) {
                            $(this).trigger('group_selected', null);
                            selectedPath = -1;
                        }

                        return;
                    }

                    activatePath(pastWavesChart.select('#' + this.id), i);

                    if (!showFullInfo) {
                        $(this).trigger('group_selected', data.group_data[data.group_data.length - i - 1].group.id);
                    }
                });

            var controlChart = svg.select('.controlchart');
            controlChart
                .on('mouseenter', function() {
                    onMouseMove(controlChart, d3.mouse(this));
                })
                .on('mousemove', function() {
                    onMouseMove(controlChart, d3.mouse(this));
                })
                .on('mouseout', function() {
                    deactivateClipping();
                })
                .on('click', function() {
                    onMouseClick(controlChart, d3.mouse(this));

                    if (!showFullInfo) {
                        $(this).trigger('group_selected', null);
                        selectedPath = -1;
                    }
                });

            svg.select('.controlchart-rect')
                .attr('width', width + padding.right)
                .attr('height', height + padding.top + padding.bottom);

            clipRect
                .attr('width', width + padding.right)
                .attr('height', height + padding.top + padding.bottom);
        };

        update();
    }

    chart.expand = function(_) {
        if (!arguments.length || !_) {
            // row_height = 24;
            showFullInfo = false;
        }
        else {
            // row_height = 48;
            showFullInfo = true;
            padding.left = 220;
        }
        return chart;
    };

    chart.x = function(_) {
        if (!arguments.length) { return x; }
        x = _;
        return chart;
    };

    chart.y = function(_) {
        if (!arguments.length) { return y; }
        y = _;
        return chart;
    };

    chart.width = function(_) {
        if (!arguments.length) { return width; }
        if (!showFullInfo) {
            padding.left = 0;
        }
        width = _ - padding.left - padding.right;
        return chart;
    };

    chart.height = function(_) {
        if (!arguments.length) { return height; }
        height = _ - padding.top - padding.bottom;
        return chart;
    };

    chart.data = function(_) {
        if (!arguments.length) { return data; }
        data = _;
        return chart;
    };

    chart.close = function(selection) {
        var tooltipSelector = selection.select('svg').select('.tooltip-selector');
        $(tooltipSelector).tooltip('destroy');
    };

    chart.empty = function() {
        emptySVG = true;
    };

    chart.highlightPath = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        var idx = -1;

        if (id) {
            for (var i = 0; i < data.group_data.length; ++i ) {
                var d = data.group_data[i];

                if (d.group.id === id) {
                    idx = i;
                    break;
                }
            }

            // ...
            if (idx !== -1) {
                idx = data.group_data.length - idx - 1;
            }
        }

        deactivatePath();

        if (idx !== -1) {
            activatePath(controlWavesChart.select('#path_' + idx), idx);
        }

        if (selectedPath !== -1) {
            if (idx === -1) {
                activatePath(controlWavesChart.select('#path_' + selectedPath), selectedPath);
            }
            else {
                selectPath(selectedPath, true);
            }
        }
    };

    chart.selectPath = function(selection, id) {
        if (showFullInfo) {
            return;
        }

        var idx = -1;

        if (id) {
            for (var i = 0; i < data.group_data.length; ++i ) {
                var d = data.group_data[i];

                if (d.group.id === id) {
                    idx = i;
                    break;
                }
            }

            // ...
            if (idx !== -1) {
                idx = data.group_data.length - idx - 1;
            }
        }

        if (selectedPath !== -1) {
            selectPath(selectedPath, false);
        }

        if ((idx !== -1) && (idx !== selectedPath)) {
            selectedPath = idx;
            activatePath(controlWavesChart.select('#path_' + idx), idx);
        }
        else {
            if (data.group_data.length) {
                deactivatePath();
            }

            selectedPath = -1;
        }
    };

    return chart;
}

export default campaigncontributionchart;

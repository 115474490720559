import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import timespanHeaderTemplate from 'templates/web/timespan-header.handlebars'


var TimespanHeader = Marionette.Layout.extend({
    className: 'timespan-header',
    template: Handlebars.compile(timespanHeaderTemplate),
    events: {
        'click .header .day' : 'filterLastDay',
        'click .header .week' : 'filterLastWeek',
        'click .header .month' : 'filterLastMonth',
        'click .header .quarter' : 'filterLastQuarter',
        'click .header .year' : 'filterLastYear'
    },
    ui: {
        activableTabs: '.header .time'
    },
    filterLastPeriod: function(period) {
        var filter = {
            type: 'lastPeriod',
            period: period
        };
        this.trigger('setFilter', 'timespan', [filter]);
    },
    filterLastDay: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('day');
    },
    filterLastWeek: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('week');
    },
    filterLastMonth: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('month');
    },
    filterLastQuarter: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('quarter');
    },
    filterLastYear: function(ev) {
        ev.preventDefault();
        this.filterLastPeriod('year');
    },
    updateFilters: function(filters) {
        if (this.isClosed) {
            return;
        }

        var timespanFilters = filters.timespan,
            timespanFilter = (timespanFilters && timespanFilters.length) ?
                                timespanFilters[0] : null;

        this.ui.activableTabs.removeClass('active');

        if ( timespanFilter && timespanFilter.type === 'lastPeriod' ) {
            this.ui.activableTabs.filter('.' + timespanFilter.period)
                .addClass('active');
        }
    }
});

export default TimespanHeader;

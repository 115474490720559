import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

import BaseModel from 'js/models/base'
import vent from 'js/vent'


function handleItem(collection, item, options) {
    var method = item[0], model = item[1], request = null, attrs = [],
        changed, success = options.success;
    switch (method) {
    case 'add':
        options.url = options.url || collection.url().split('?', 1)[0];
        options.type = 'POST';
        // as far as I understood this is necessary to override default model endpoint
        if (collection.model.save) {
            request = collection.model.save(model, options);
        }
        // this is an old code which should run, when model not overrode
        else {
            request = model.save({}, options);
        }
        break;
    case 'save':
        changed = model.changedAttributes();
        if (changed) {
            options = _.clone(options);
            options.patch = true;
            attrs = _.keys(changed);
            request = model.save(attrs, options);
        }
        break;
    case 'remove':
        var ids=[];
        _.each(model, function(m) {
            ids.push({id: m.get('id')});
        });
        attrs = ids;
        options.url = collection.url().split('?', 1)[0];
        options.type = 'DELETE';

        options.success = function () {
            // TODO: destroy model if completely destroyed on back-end
            success.apply(this, arguments);
        };

        var ajaxOptions = {
            success: options.success,
            dataType: 'json',
            contentType: 'application/json',
            type: options.type,
            data: JSON.stringify(attrs),
            url: options.url
        };
        return $.ajax(ajaxOptions);
    }
    return request;
}
function fetchStarted(collection, xhr) {
    if (collection._fetching.indexOf(xhr) === -1) {
        collection._fetching.push(xhr);
    }
}
function fetchFinished(collection, xhr) {
    var pos;
    while ((pos = collection._fetching.indexOf(xhr)) !== -1) {
        collection._fetching.splice(pos, 1);
    }
}
function isFetching(collection) {
    return !!collection._fetching.length;
}
var BaseCollection = Backbone.Collection.extend({
    model: BaseModel,
    defaultStart: 0,
    defaultRows: 50,
    start: 0,
    rows: 0,
    defaultSortOn: [{
        attribute: 'modified',
        order: 'desc'
    }],
    sortOn: [],
    filterBy: [],
    defaultFilterBy: [],
    initialize: function () {
        var super_init = Backbone.Collection.prototype.initialize;
        this.createdSinceSync = [];
        this.addedSinceSync = [];
        this.updatedSinceSync = [];
        this.destroyedSinceSync = [];
        this.removedSinceSync = [];
        this._fetching = [];
        this.sortOn = this.defaultSortOn;
        this.filterBy = this.defaultFilterBy;
        return super_init.apply(this, arguments);
    },
    createPermission: function () {
        var result = this.model.createPermission;
        if (_.isFunction(result)) {
            result = result.call(this.model, this.model);
        }
        return result;
    },
    urlRoot: function () {
        return _.result(this.model.prototype, 'urlRoot');
    },

    url: function(models, start, rows, sortOn, filterBy, fields,
                  extraFields, extraArgs) {
        /**
         * Handle start / rows paramenters and relational parent.
         * This only works because children are retrieved using the same
         * URL root as they are on their own, just appended to their
         * parent's URL.
         *
         * e.g. To get all visible individuals, access /individuals. To
         *      get all individuals that are members of organization 1,
         *      access /organizations/1/individuals.
         */
        var url = this.urlRoot(), sortParam, collection = this;
        url += (
            (models && models.length) ?
            ('/;' + _.pluck( models, 'id' ).join(';')) :
            '');
        if (!start && start !== 0) {
            start = this.defaultStart;
        }
        if (!rows || rows < -1) {
            rows = this.defaultRows;
        }
        url += '?';
        if(extraArgs) {
            var args='';
            _.each(extraArgs, function (arg) {
                if(typeof arg === 'object') {
                    args += '&'+arg.attribute+'='+arg.value;
                } else {
                    args += '&'+arg;
                }
            });
            url += args.substr(1) + '&';
        }
        url += 'start=' + encodeURIComponent(start.toString()) + '&' +
        'rows=' + encodeURIComponent(rows.toString());
        if (fields && fields.length) {
            url += '&fields=';
            url += _.map(fields,
                         encodeURIComponent).join('&fields=');
        }
        if (extraFields && extraFields.length) {
            url += '&extra_fields=';
            url += _.map(extraFields,
                         encodeURIComponent).join('&extra_fields=');
        }
        if (sortOn === undefined) {
            sortOn = this.defaultSortOn;
        }
        if (filterBy === undefined) {
            filterBy = this.defaultFilterBy;
        }
        sortParam = _.map(sortOn, function (sort_item) {
            var result;
            if (_.isObject(sort_item)) {
                result = sort_item.attribute;
                if (sort_item.order) {
                    result += ' ' + sort_item.order;
                }
            } else {
                result = sort_item;
            }
            return result;
        }).join(',');
        if (sortParam) {
            url += '&order_by=' + encodeURIComponent(sortParam);
        }
        _.each(filterBy, function (filter) {
            var value = filter.value;
            if (_.isFunction(value)) {
                value = value(collection);
            }
            if (value === undefined) {
                return;
            }
            url += (
                    '&filter=' +
                    encodeURIComponent(
                        filter.attribute +
                        (filter.op || '=') +
                        value
                        )
                   );
        });
        if (this.parent!==undefined) {
            url = this.parent.url() + url;
        }

        if (this.versionUrl) {
            url = this.versionUrl + url;
        }

        return url;
    },
    fetch: function(options) {
        var success, error, xhr, fetchExisting = [];
        options = options ? _.clone(options) : {};

        // https://github.com/PaulUithol/Backbone-relational#q-and-a
        // "After a fetch, I don't get add:<key> events for nested
        // relations."
        if (options.add === undefined) {
            options.add = true;
        }

        if (options.page && !options.start && options.start !== 0) {
            // Only use the page number if we've not explicitly set a
            // start
            options.rows = options.rows || this.defaultRows;
            options.start = (options.page - 1) * options.rows;
        }
        if (options.at) {
            options.headers = (
                options.headers ? _.clone(options.headers) : {}
                );
            options.headers['Accept-Datetime'] = options.at.toISOString();
        }

        if (!options.add) {
            fetchExisting = this.models;
        }

        if (!options.url) {
            options.url = this.url(fetchExisting, options.start,
                                   options.rows, options.sortOn,
                                   options.filterBy, options.fields,
                                   options.extraFields, options.extraArgs);
        }

        var alert = options.alert;
        var c = this;

        var beforeSend = options.beforeSend;
        var beforeSendWrapper = function(xhr) {
            if (alert) {
                vent.trigger("alert:show", { type: 'load', model: c.model.prototype, xhr: xhr});
            }

            if (beforeSend) {
                beforeSend.apply(this, arguments);
            }
        };
        options.beforeSend = beforeSendWrapper;

        success = options.success;
        function successWrapper(collection, response, options) {
            if (alert) {
                vent.trigger("alert:show", { type: 'load', model: c.model.prototype, xhr: options.xhr });
            }

            var result,
                header = function(name) {
                    return xhr.getResponseHeader(name);
                };
            collection.total = parseInt(header('Records-Total'), 10);
            collection.start = parseInt(header('Records-Start'), 10);
            collection.rows = parseInt(header('Records-Rows'), 10);
            collection.sortOn = options.sortOn;
            collection.filterBy = options.filterBy;
            try {
                if (success) {
                    result = success(collection, response, options);
                }
            } finally {
                fetchFinished(collection, xhr);
            }
            return result;
        }
        options.success = successWrapper;

        error = options.error;
        function errorWrapper(collection, response, options) {
            if (alert) {
                vent.trigger("alert:show", { type: 'load', model: c.model.prototype, xhr: options.xhr });
            }

            var result;
            try {
                if (error) {
                    result = error(collection, response, options);
                }
            } finally {
                fetchFinished(collection, xhr);
            }
            return result;
        }
        options.error = errorWrapper;

        /* Keep an XHR reference in the local scope so we may reference it
         * from our callback.
         */
        xhr = Backbone.Collection.prototype.fetch.call(this, options);
        fetchStarted(this, xhr);

        return xhr;
    },
    create: function () {
        var super_create = Backbone.Collection.prototype.create,
            model = super_create.apply(this, arguments);
        if (!isFetching(this)) {
            this.createdSinceSync.push(model);
        }
        return model;
    },
    add: function (models, options) {
        var i=0, collection = this, model,
            super_add = Backbone.Collection.prototype.add;
        models = _.isArray(models) ? models.slice() : [models];
        options = options || {};
        function makeDestructionListener(model) {
            return function destructionListener() {
                collection.destroyedSinceSync.push(model);
            };
        }
        for (i; i < models.length; i += 1) {
            model = models[i] = this._prepareModel(models[i], options);
            if ((!options.silent && !isFetching(this)) ||
                options.trackChanges) {

                if (this.get(model) && options.merge) {
                    this.updatedSinceSync.push(model);
                } else {
                    this.addedSinceSync.push(model);
                }
            }
            this.listenTo(model, 'destroy',
                          makeDestructionListener(model));
        }
        return super_add.call(this, models, options);
    },
    remove: function (models, options) {
        var i = 0,
            super_remove = Backbone.Collection.prototype.remove;
        options = options || {};
        if (!options.silent && !isFetching(this)) {
            models = _.isArray(models) ? models.slice() : [models];
            for (i; i < models.length; i += 1) {
                if (this.get(models[i])) {
                    this.removedSinceSync.push(models[i]);
                }
            }
        }

        this.total -= models.length;

        return super_remove.apply(this, arguments);
    },
    reset: function (newModels, options) {
        var collection = this, currentModels = this.models,
            super_reset = Backbone.Collection.prototype.reset;
        newModels = newModels || [];
        options = options || {};
        if (options.parse) {
            newModels = this.parse(newModels, options);
        }
        if (!options.silent && !isFetching(this)) {
            _.each(currentModels, function(currentModel) {
                if(newModels.indexOf(currentModel) === -1) {
                    collection.removedSinceSync.push(currentModel);
                }
            });
            _.each(newModels, function(newModel) {
                if(currentModels.indexOf(newModel) === -1) {
                    collection.addedSinceSync.push(newModel);
                }
            });
        }
        return super_reset.apply(this, arguments);
    },
    sync: function (method, model, options) {
        options = options || {};

        // Handle 403 errors
        var error = options.error;
        options.error = function(collection, response, settings) {
            if (settings.xhr.status === 502){
                vent.trigger('bad_gateway');
            }
            if (settings.xhr.status === 503){
                vent.trigger('server_temporarily_unavailable');
            }

            if (error) {
                error.apply(this, arguments);
            }
        };

        // since this may only be part of a large collection on the
        // server, be smart about syncing.
        options = (options || {});
        var subOptions = _.clone(options), request, successWrapper,
            success, requests = [], chain = [], i=0, collection=this,
            removed, added, updated,
            super_sync = Backbone.Collection.prototype.sync;
        switch (method) {
        case 'create':
        case 'read':
        case 'update':
            return super_sync.call(this, method, model, options);
        case 'patch':
            // should only actually be called by our code, but may be used
            // in future by Backbone.
            success = options.success;
            successWrapper = function() {
                collection.createdSinceSync = [];
                collection.addedSinceSync = [];
                collection.updatedSinceSync = [];
                collection.destroyedSinceSync = [];
                collection.removedSinceSync = [];
                if (success) {
                    return success.apply(this, arguments);
                }
            };
            removed = _.filter(this.removedSinceSync, function (removed) {
                return (
                    collection.destroyedSinceSync.indexOf(removed) === -1
                    );
            });
            if (removed.length) {
                chain.push(['remove', removed]);
            }
            for (i = 0; i < this.addedSinceSync.length; i += 1) {
                added = this.addedSinceSync[i];
                if (this.createdSinceSync.indexOf(added) === -1) {
                    chain.push(['add', added]);
                }
            }
            for (i = 0; i < this.updatedSinceSync.length; i += 1) {
                // this.removedSinceSync includes this.destroyedSinceSync
                updated = this.updatedSinceSync[i];
                if (this.removedSinceSync.indexOf(updated) === -1) {
                    chain.push(['save', updated]);
                }
            }
            subOptions.success = function (resp, options) {
                if (chain.length) {
                    request = handleItem(collection, chain.pop(),
                                         subOptions);
                    if (request) {
                        requests.push(request);
                    }
                }
                successWrapper(collection, resp, options);
            };
            if (chain.length) {
                request = handleItem(this, chain.pop(), subOptions);
                if (request) {
                    requests.push(request);
                }
            }
            successWrapper(this, null, options);
            return requests;
        default:
            throw "Unknown sync method " + method;
        }
    },
    save: function(options) {
        return this.sync('patch', this, options);
    },
    nextBatch: function(options) {
        options = options ? _.clone(options) : {};
        options.rows = options.rows || this.rows || this.defaultRows;
        options.start = (
            (options.start || this.start || this.defaultStart) +
            options.rows
            );
        return this.fetch(options);
    },
    previousBatch: function(options) {
        options = options ? _.clone(options) : {};
        options.rows = options.rows || this.rows || this.defaultRows;
        options.start = (
            (options.start || this.start || this.defaultStart) -
            options.rows
            );
        return this.fetch(options);
    },
    comparator: function() {
        return 0;
    },
    sort: function() {
        // No-op: keep back-end sorted version
    },
    createAggregate: function(options) {
        // TODO: This is forecast specifc code that shouldn't be in the generic class
        var success = options.success;
        var newModel = new this.model();
        var successWrapper = function(data) {
            newModel.set(data, {parse:true, silent:true});
            if (success) {
                success(newModel, data, options);
            }
        };
        var data = {
            new_forecast: options.data,
            aggregate_from: _.map(this.models, function(d) {
                return {id: d.id};
            })
        };
        var ajaxOptions = _.extend(options, {
            success: successWrapper,
            dataType: 'json',
            contentType: 'application/json',
            type: 'POST',
            data: JSON.stringify(data),
            url: '/forecasts?aggregate'
        });
        return $.ajax(ajaxOptions);
    }
});
export default BaseCollection;

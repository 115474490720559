
var groupColors = [
    [0, 161, 53], [255, 107, 232], [0, 210, 255], [255, 36, 93],
    [183, 236, 0], [108, 0, 199], [255, 144, 0]
];

var groupColorsLight = [
    [171, 223, 194], [248, 207, 248], [171, 238, 255], [248, 185, 206],
    [226, 246, 178], [204, 175, 238], [248, 218, 178]
];

var colorModificationFactor = 0.8;

export default {
    clamp: function(val, min, max) {
        return Math.min(Math.max(val, min), max);
    },

    groupStyle: function(i) {
        return 'fill:' + this.groupColor(i);
    },

    groupStyleLight: function(i) {
        return 'fill:' + this.groupColorLight(i);
    },

    groupColor: function(i) {
        var c = this.AdjustColorByIndex(groupColors, i);
        return 'rgb(' + c[0] + ',' + c[1] + ',' + c[2] + ')';
    },

    groupColorLight: function(i) {
        var c = this.AdjustColorByIndex(groupColorsLight, i);
        return 'rgb(' + c[0] + ',' + c[1] + ',' + c[2] + ')';
    },

    AdjustColorByIndex: function(colors, idx) {
        var color = colors[idx % colors.length];

        if (idx >= colors.length) {
            // alternate the original color to darker and lighter
            var dec = Math.floor(idx/colors.length);
            var factor = dec * (((dec % 2) === 0) ? colorModificationFactor : -colorModificationFactor);
            color = this.correctColor(color, factor);
        }

        return color;
    },

    correctColor: function(color, factor) {
        var r = this.clamp(Math.floor(color[0] + ((255 - color[0]) * factor)), 0, 255);
        var g = this.clamp(Math.floor(color[1] + ((255 - color[1]) * factor)), 0, 255);
        var b = this.clamp(Math.floor(color[2] + ((255 - color[2]) * factor)), 0, 255);

        return [r, g, b];
    }
};

import React from 'react';
import ReactDOM from 'react-dom';

import vent from 'js/vent';
import app from 'js/app';
import AppConfig from 'app/app-config';
import dateFormat from 'js/utils/date-format';
import DateTimePicker from 'js/widgets/date-time-picker';
import { NewSelect, TagSelect } from 'js/react_views/widgets/select'
import LoadingIndicator from 'js/react_views/widgets/loading-indicator';
import htmlSanitizer from 'js/utils/html-sanitizer';
import MessageBox from 'js/views/message_box';
import { Subject, Notes } from './common';

import style from './appointment.css';
import commonStyle from './common.css';
import TextManager from "app/text-manager";


const ANIMATION_TIME = 400;
const PANEL_WIDTH = 463;
const M15_MINUTES = 60000 * 15;

const TYPE_MAPPING = {
    individual: 'individual',
    individuals: 'individual',
    user: 'user',
    users: 'user',
    opportunities: 'opportunity',
    organizations: 'organization'
};

const STATUS_TYPES = [{
    id: 'pending',
    title: 'Pending'
}, {
    id: 'confirmed',
    title: 'Confirmed'
}, {
    id: 'completed',
    title: 'Completed'
}, {
    id: 'cancelled',
    title: 'Cancelled'
}];

const STATUS_COLOR_BY_ID = {
    pending: '#F5A427',
    confirmed: '#43D350',
    completed: '#43D350',
    cancelled: '#E34B4A'
};

class DateTime extends React.Component {
    constructor(props) {
        super(props);

        this.isRelative = !!props.relativeDate;
        this.relativeDate = this.adjustTime(props.relativeDate);

        this.state = {
            date: this.adjustTime(props.date),
            dateFocused: false,
            timeFocused: false
        };
    }

    componentDidMount() {
        this.computeTimeOptions();

        const self = this;

        _.defer(function() {
            self.props.onDateChange(self.state.date); // because adjustTime modify the date
        });
    }

    adjustTime(date) {
        if (!date) {
            return null;
        }

        let newDate = new Date(date.getTime());

        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        // adjust the minutes to a valid lapse (every 15m)
        const minutes = newDate.getMinutes();

        for (const l of [45, 30, 15, 0]) {
            if (minutes >= l) {
                newDate.setMinutes(l);
                break;
            }
        }

        return newDate;
    }

    computeTimeOptions() {
        this.timeOptions = [];

        let numOptions = (24 * 4); // 24 hours * 4 options per hour (every 15 minutes);
        let start;
        let tmpDate;
        let showLapse = false;
        let sameDate = false;

        if (this.isRelative) {
            sameDate = (this.state.date.getDate() === this.relativeDate.getDate() &&
                        this.state.date.getMonth() === this.relativeDate.getMonth() &&
                        this.state.date.getFullYear() === this.relativeDate.getFullYear());
        }

        if (this.isRelative && sameDate) {
            start = (this.relativeDate.getHours() * 4) + Math.floor(this.relativeDate.getMinutes() / 15);
            numOptions += start - 1;
            tmpDate = new Date(this.relativeDate.getTime());
            showLapse = true;
        } else {
            start = 0;
            tmpDate = new Date(this.state.date.getTime());

            tmpDate.setHours(0);
            tmpDate.setMinutes(0);
        }

        for (let c = start; c < numOptions; ++c) {
            let title = dateFormat.shortFormatTime(tmpDate);

            if (showLapse) {
                const diff = (tmpDate - this.relativeDate) / 60000; // difference in minutes

                if (diff < 60) {
                    title += ` (${diff} mins)`;
                } else {
                    const hours = diff / 60;
                    title += ` (${diff / 60} ${hours === 1 ? 'hr' : 'hrs'})`;
                }
            }

            this.timeOptions.push({
                id: `to_${c}`,
                title: title,
                date: new Date(tmpDate.getTime())
            });

            tmpDate.setMinutes(tmpDate.getMinutes() + 15);
        }

        this.timeDropdownComponent.setData(this.timeOptions);
    }

    formatDate(date) {
        const f = dateFormat;
        const d = date;

        return `${f.dayToShortText(d.getDay())} ${d.getDate()}, ${f.shortFormatMonth(d.getMonth())} ${f.smartFormatYear(d)}`;
    }

    setDate(date) {
        this.setState({
            date: this.adjustTime(date)
        });

        const self = this;

        _.defer(function() {
            self.computeTimeOptions();
        });
    }

    setRelativeDate(date) {
        if (!this.isRelative) {
            return;
        }

        this.relativeDate = date;
        this.computeTimeOptions();
    }

    handleDateClick(ev) {
        const target = $(this.inputComponent);
        const self = this;

        this.setState({
            dateFocused: true
        });

        target.datepicker({
            defaultDate: this.state.date,
            showButtonPanel: true,
            dateFormat: '@',
            onClose: function(nd) {
                let newState = {
                    dateFocused: false
                };

                if (nd) {
                    const timestamp = parseInt(nd);

                    if (!_.isNaN(timestamp)) {
                        const newDate = new Date(timestamp);

                        newDate.setHours(self.state.date.getHours());
                        newDate.setMinutes(self.state.date.getMinutes());

                        newState.date = newDate;

                        self.props.onDateChange(newDate);
                    }
                }

                self.setState(newState);

                _.defer(function() {
                    self.computeTimeOptions();
                })
            }
        });

        target.datepicker('setDate', this.state.date);
        target.datepicker('show');
    }

    handleTimeClick() {
        const h = this.state.date.getHours();
        const m = this.state.date.getMinutes();
        const activeOption = this.timeOptions.find(to => to.date.getHours() === h && to.date.getMinutes() === m);

        this.timeDropdownComponent.setValue(activeOption?.id, true);
        this.timeDropdownComponent.toggleExpanded(true);

        this.setState({
            timeFocused: true
        });
    }

    handleTimeSelect(items) {
        const item = items[0];

        const newDate = new Date(item.date.getTime());

        this.setState({
            date: newDate
        });

        this.props.onDateChange(newDate);

        if (this.isRelative) {
            const self = this;

            _.defer(function() {
                self.computeTimeOptions();
            });
        }
    }

    render() {
        const dateFormatted = this.formatDate(this.state.date);
        const showWarning = this.state.date < new Date();

        return (
            <div className={style.cDateTime}>
                <div className={style.dtLabel}>{this.props.label}</div>

                <div
                    className={`
                        ${style.dtContent}
                        ${this.state.dateFocused ? commonStyle.cFocused : ''}
                    `}
                    onClick={this.handleDateClick.bind(this)}
                >
                    <div className={`${style.dtcIcon} icon-calendar`}/>

                    <input
                        className={style.dtcInput}
                        ref={(el) => this.inputComponent = el}
                        value={dateFormatted}
                        readOnly={true}
                    />

                    <div
                        className={`${style.dtcWarning} icon-warning`}
                        style={{visibility: showWarning ? 'visible' : 'hidden'}}
                        title='Date is in the past'
                    />
                </div>

                <div
                    className={`
                        ${style.dtContent}
                        ${style.dtcTime}
                        ${this.state.timeFocused ? commonStyle.cFocused : ''}
                    `}
                    onClick={this.handleTimeClick.bind(this)}
                >
                    <div className={`${style.dtcIcon} icon-clock`}/>

                    {dateFormat.shortFormatTime(this.state.date)}

                    <span
                        style={{
                            marginLeft: 'auto',
                            fontSize: 10
                        }}
                    >
                        {'\u25bc'}
                    </span>

                    <div className={style.dtcDropdown}>
                        <NewSelect
                            ref={(el) => this.timeDropdownComponent = el}
                            data={[]}
                            searchViewVisible={false}
                            options={{minimumInputLength: -1}}
                            width={150}
                            boxStyle={{
                                visibility: 'hidden'
                            }}
                            onSelect={this.handleTimeSelect.bind(this)}
                            onClose={() => this.setState({ timeFocused: false })}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class EditAppointment extends React.Component {
    constructor(props) {
        super(props);

        const appointment = props.appointment || {};

        this.isNew = !appointment.id;
        this.availableStatuses = STATUS_TYPES;

        if (appointment.status !== 'cancelled') { // this option is only visible if the appointment is already cancelled
            this.availableStatuses = this.availableStatuses.filter(s => s.id !== 'cancelled');
        }

        this.appointmentTypes = AppConfig.getValue('appointments.types_list', []);
        this.appointmentType = this.appointmentTypes.find(at => at.title === appointment.appointment_type) || this.appointmentTypes[0];
        this.subject = appointment.title || '';
        this.tags = appointment.tags || (props.preloaded ? app.user.getIndividualPreloadedTags() || null : []);
        this.funnels = appointment.funnels || (props.preloaded ? app.user.getIndividualPreloadedFunnels() || null : null);
        this.startDate = appointment.start_date ? dateFormat.parseDate(appointment.start_date) : new Date();
        this.endDate = appointment.end_date ? dateFormat.parseDate(appointment.end_date) : new Date(this.startDate.getTime() + M15_MINUTES);
        this.status = this.availableStatuses.find(st => st.id === appointment.status) || this.availableStatuses[0];
        this.related = appointment.related ? {
            id: appointment.related.id,
            type: TYPE_MAPPING[appointment.related.type],
            title: appointment.related.full_name || appointment.related.name
        } : null;

        const assignee = appointment.guests?.find(g => g.role === 'assignee') || appointment.guests?.find(g => g.guest_type === 'user');
        if (assignee) {
            this.assignee = {
                id: assignee.guest_id,
                title: assignee.guest_name
            };
        } else {
            this.assignee = {
                id: app.user.get('id'),
                title: app.user.get('name')
            };
        }

        const primaryAttendee = appointment.guests?.find(g => g.role === 'primary') || appointment.guests?.find(g => g.guest_type === 'individual');

        this.primaryAttendee = null;

        if (primaryAttendee) {
            this.primaryAttendee = {
                id: primaryAttendee.guest_id,
                title: primaryAttendee.guest_name
            };
        }

        const additionalAttendees = appointment.guests?.filter(g => (g.guest_id !== assignee?.guest_id) && (g.guest_id !== primaryAttendee?.guest_id));

        this.additionalAttendees = [];

        if (additionalAttendees) {
            this.additionalAttendees = additionalAttendees.map(g => {
                return {
                    id: g.guest_id,
                    title: g.guest_name,
                    type: g.guest_type,
                    status: g.status
                };
            });
        }

        this.state = {
            description: appointment.description || '',
            descriptionFocused: false
        };
    }

    componentDidMount() {
        const self = this;

        _.defer(function() {
            self.initialData = {
                appointmentTypeId: self.appointmentType.id,
                subject: self.subject,
                tags: _.clone(self.tags),
                funnels: _.clone(self.funnels),
                additionalAttendees: _.clone(self.additionalAttendees),
                startDate: new Date(self.startDate.getTime()),
                endDate: new Date(self.endDate.getTime()),
                statusId: self.status.id,
                related: _.clone(self.related),
                primaryAttendee: _.clone(self.primaryAttendee),
                assignee: _.clone(self.assignee),
                description: self.state.description
            };
        }, 100); // wait until all the dropdowns and datetime components are initialized
    }

    dataHasChanged() {
        const hasChanged =
            (this.appointmentType.id !== this.initialData.appointmentTypeId) ||
            (this.subject !== this.initialData.subject) ||
            (JSON.stringify(this.tags) !== JSON.stringify(this.initialData.tags)) ||
            (JSON.stringify(this.additionalAttendees) !== JSON.stringify(this.initialData.additionalAttendees)) ||
            (this.startDate.getTime() !== this.initialData.startDate.getTime()) ||
            (this.endDate.getTime() !== this.initialData.endDate.getTime()) ||
            (this.status.id !== this.initialData.statusId) ||
            (this.related?.id !== this.initialData.related?.id) ||
            (this.primaryAttendee?.id !== this.initialData.primaryAttendee?.id) ||
            (this.assignee?.id !== this.initialData.assignee?.id) ||
            (this.state.description !== this.initialData.description) || 
            (JSON.stringify(this.funnels) !== JSON.stringify(this.initialData.funnels));

        return hasChanged;
    }

    getData() {
        let hasErrors = false;

        if (!this.primaryAttendee) {
            this.primaryAttendeeComponent.setError(true);
            hasErrors = true;
        }   
        if (!this.funnels || this.funnels.length == 0) {
            this.developmentComponent.setError(true)
            hasErrors = true;
        }

        if (hasErrors) {
            return null;
        }

        let data = {
            appointment_type: this.appointmentType.title,
            title: this.subject,
            description: this.state.description,
            start_date: this.startDate.toISOString(),
            end_date: this.endDate.toISOString(),
            status: this.status.id
        };

        if (this.related) {
            data.related_id = this.related.id;
            data.related_type = this.related.type;
        }

        let attendees = [{
            guest_type: 'user',
            guest_id: this.assignee.id,
            status: app.user.get('id') === this.assignee.id ? 'accepted' : 'pending',
            role: 'assignee'
        }, {
            guest_type: 'individual',
            guest_id: this.primaryAttendee.id,
            status: 'pending',
            role: 'primary'
        }];

        attendees = attendees.concat(this.additionalAttendees.map(a => {
            return {
                guest_type: a.type,
                guest_id: a.id,
                status: a.status
            };
        }));

        attendees = _.uniq(attendees, false, a => a.guest_id);

        if (attendees.length > 0) {
            data.guests = attendees;
        }

        if (this.tags) {
            data.tags = this.tags.map(tag => {
                return {
                    id: tag.id,
                    name: tag.name
                };
            });
        }

        if (this.funnels) {
            data.funnels = this.funnels.map(funnel => {
                return {
                    id: funnel.id,
                    name: funnel.name
                };
            });
        }

        if (this.isNew) {
            data = _.extend(data, {
                creator_is_not_a_guest: true,
                creator_type: 'user',
                creator_id: app.user.get('id'),
                location: ''
            });
        }

        return data;
    }

    handleDescriptionChange(ev) {
        this.setState({
            description: ev.target.value
        });
    }

    handleAdditionalAttendeeChange(items) {
        this.additionalAttendees = items.map(i => {
            return {
                id: i.id,
                title: i.title,
                type: TYPE_MAPPING[i.type],
                status: i.status || (app.user.get('id') === i.id ? 'accepted' : 'pending')
            };
        });
    }

    handleAdditionalAttendeeData(items) {
        return items.filter(i => i.id !== this.primaryAttendee?.id && i.id !== this.assignee?.id);
    }

    handleTagsChange(items) {
        this.tags = items.map(i => {
            return {
                id: i.id,
                name: i.name
            };
        });
    }

    handleFunnelsChange(items) {
        this.funnels = items.map(i => {
            return {
                id: i.id,
                name: i.name
            };
        });
    }

    handleStartDateChange(date) {
        this.startDate = date;

        // manage endTime never be before starTime
        if (this.endDate.getTime() <= this.startDate.getTime()) {
            this.endDate = new Date(this.startDate.getTime() + M15_MINUTES);
            this.endDateComponent.setDate(this.endDate);
        }

        this.endDateComponent.setRelativeDate(date);
    }

    handleEndDateChange(date) {
        this.endDate = date;

        // manage endTime never be before starTime
        if (this.endDate.getTime() <= this.startDate.getTime()) {
            this.startDate = new Date(this.endDate.getTime() - M15_MINUTES);
            this.startDateComponent.setDate(this.startDate);
            this.endDateComponent.setRelativeDate(this.startDate);
        }
    }

    handleAssigneeChange(items) {
        this.assignee = {
            id: items[0].id,
            title: items[0].title
        };
    }

    handleAssigneeData(items) {
        return items.filter(i => !this.additionalAttendees.find(a => a.id === i.id));
    }

    handlePrimaryAttendeeChange(items) {
        this.primaryAttendee = {
            id: items[0].id,
            title: items[0].title
        };

        this.primaryAttendeeComponent.setError(false);
    }

    handlePrimaryAttendeeData(items) {
        return items.filter(i => !this.additionalAttendees.find(a => a.id === i.id));
    }

    handleSubjectChange(subject) {
        this.subject = subject;
    }

    handleAppointmentTypeSelect(items) {
        this.appointmentType = items[0];
    }

    handleStatusSelect(items) {
        this.status = items[0];
    }

    handleRelatedChange(items) {
        if (!items) {
            this.related = null;
        } else {
            const item = items[0].item;

            this.related = {
                id: item.id,
                type: TYPE_MAPPING[item.type]
            };
        }
    }

    handleDevelopmentChange(items) {  
        this.relatedComponent.setValue(null);

        this.relatedComponent.updateSearchParameters({
            funnel_id: items ? items[0].id : null
        });

        if (!items) {
            this.funnels = []
            return
        }   

        this.funnels = items.map(i => {
            return {
                id: i.id,
                name: i.name
            };
        });
    }

    render() {
        return (
            <div className={style.cEdit}>
                <div className={style.eContent}>
                    <div className={commonStyle.cTitle}>
                        Subject
                    </div>

                    <div style={{marginTop: 5}}>
                        <Subject
                            value={this.subject}
                            suggestions={AppConfig.getValue('appointments.subject_suggestions', null)}
                            onChange={this.handleSubjectChange.bind(this)}
                        />
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{width: '50%', marginRight: 5}}
                        >
                            Appointment Type
                        </div>

                        <div
                            className={commonStyle.cTitle}
                            style={{width: '50%', marginLeft: 5}}
                        >
                            Status
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                data={this.appointmentTypes}
                                value={this.appointmentType}
                                text='title'
                                width={240}
                                onSelect={this.handleAppointmentTypeSelect.bind(this)}
                            />
                        </div>

                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                data={this.availableStatuses}
                                value={this.status}
                                text='title'
                                onSelect={this.handleStatusSelect.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div className={commonStyle.cTitle}>
                            Appointment Date & Time
                        </div>

                        <div
                            style={{marginTop: 5}}
                        >
                            <DateTime
                                ref={(el) => this.startDateComponent = el}
                                label='Start'
                                date={this.startDate}
                                onDateChange={this.handleStartDateChange.bind(this)}
                            />

                            <DateTime
                                ref={(el) => this.endDateComponent = el}
                                label='End'
                                date={this.endDate}
                                relativeDate={this.startDate}
                                onDateChange={this.handleEndDateChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div className={commonStyle.cTitle}>
                            Description
                        </div>

                        <textarea
                            className={`
                                ${style.cDescription}
                                ${this.state.descriptionFocused ? commonStyle.cFocused : ''}
                            `}
                            style={{marginTop: 5}}
                            placeholder='Description'
                            value={this.state.description}
                            onChange={this.handleDescriptionChange.bind(this)}
                            onFocus={() => this.setState({ descriptionFocused: true })}
                            onBlur={() => this.setState({ descriptionFocused: false })}
                        />
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginRight: 5
                            }}
                        >
                            Assigned to
                        </div>

                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginLeft: 5
                            }}
                        >
                            Primary Attendee

                            <span className={commonStyle.tRequired}>
                                &#x2733;
                            </span>
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                url='/users'
                                value={this.assignee}
                                options={{minimumInputLength: 1}}
                                onSelect={this.handleAssigneeChange.bind(this)}
                                processData={this.handleAssigneeData.bind(this)}
                            />
                        </div>

                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                ref={(el) => this.primaryAttendeeComponent = el}
                                url='/individuals'
                                value={this.primaryAttendee}
                                options={{minimumInputLength: 1}}
                                onSelect={this.handlePrimaryAttendeeChange.bind(this)}
                                processData={this.handlePrimaryAttendeeData.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginRight: 5
                            }}
                        >
                            {TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}

                            <span className={commonStyle.tRequired}>
                                &#x2733;
                            </span>
                        </div>

                        <div
                            className={commonStyle.cTitle}
                            style={{
                                width: '50%',
                                marginLeft: 5
                            }}
                        >
                            {TextManager.parseText('${ID_APPOINTMENT_RELATED_DEALS_TEXT, capitalize}')}
                        </div>
                    </div>

                    <div
                        className={commonStyle.cRow}
                        style={{marginTop: 5}}
                    >
                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                ref={(el) => this.developmentComponent = el}
                                data={app.globalData.funnelsInfo.funnels}
                                value={this.funnels[0] || app.user.getIndividualPreloadedFunnels()[0] || null}
                                text='name'
                                options={{minimumInputLength: 0, allowClear: true}}
                                onSelect={this.handleDevelopmentChange.bind(this)}
                            />
                        </div>

                        <div style={{width: 'calc(50% - 5px)'}}>
                            <NewSelect
                                ref={(el) => this.relatedComponent = el}
                                url='/opportunities'
                                value={this.related}
                                options={{
                                    minimumInputLength: 1, allowClear: true}}
                                onSelect={this.handleRelatedChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div className={commonStyle.cTitle}>
                            Additional Attendees
                        </div>

                        <div
                            style={{marginTop: 5}}
                        >
                            <TagSelect
                                value={this.additionalAttendees}
                                url='/v1/search'
                                text='title'
                                placeholder='Add Attendee'
                                options={{
                                    search_parameters: {
                                        types: 'individuals,users'
                                    }
                                }}
                                processData={this.handleAdditionalAttendeeData.bind(this)}
                                onChange={this.handleAdditionalAttendeeChange.bind(this)}
                            />
                        </div>
                    </div>

                    <div
                        style={{marginTop: 5}}
                    >
                        <div
                            className={commonStyle.cTitle}
                        >
                            Tags
                        </div>

                        <div
                            style={{marginTop: 5}}
                        >
                            <TagSelect
                                url='/tags'
                                value={this.tags}
                                text='name'
                                width={400}
                                placeholder='Add Tags'
                                onChange={this.handleTagsChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class ViewAppointment extends React.Component {
    getDateTimeString(date) {
        return `${dateFormat.shortFormatDay(date.getDay())} ${date.getDate()}, ${dateFormat.shortFormatMonth(date.getMonth())} | ${dateFormat.shortFormatTime(date)}`;
    }

    handlePrimaryAttendeeClick(primaryAttendee) {
        if (!primaryAttendee) {
            return;
        }

        window.location = `/#individuals/${primaryAttendee.guest_id}`;
        this.props.onClose();
    }

    handleRelatedClick() {
        const related = this.props.appointment.related;

        if (!related) {
            this.props.onEdit();
        } else {
            const url = related.type === 'opportunities' ? 'deals' : related.type;

            window.location = `/#${url}/${related.id}`;

            this.props.onClose();
        }
    }

    render() {
        const data = this.props.appointment;
        const sdate = dateFormat.parseDate(data.start_date);
        const edate = dateFormat.parseDate(data.end_date);
        const title = `${data.title} | ${data.appointment_type}`;
        const startDate = this.getDateTimeString(sdate);
        const fullDate = `Starts: ${startDate}\nEnds: ${this.getDateTimeString(edate)}`;
        let duration = '';

        const assignee = data.guests.find(g => g.role === 'assignee') || data.guests.find(g => g.guest_type === 'user');
        const primaryAttendee = data.guests.find(g => g.role === 'primary') || data.guests.find(g => g.guest_type === 'individual');
        const additionalAttendees = data.guests.filter(g => (g.guest_id !== assignee?.guest_id) && (g.guest_id !== primaryAttendee?.guest_id));

        const diff = (edate - sdate) / 60000; // difference in minutes

        if (diff < 60) {
            duration = `${diff} mins`;
        } else {
            const hours = diff / 60;
            duration = `${diff / 60} ${hours === 1 ? 'hr' : 'hrs'}`;
        }

        let related = '';

        if (data.related) {
            related = data.related.full_name || data.related.name;
        }

        return (
            <div className={style.cView}>
                <div className={style.vContent}>
                    <div className={style.cHeader}>
                        <div
                            className={style.hPart}
                            title={title}
                        >
                            <div className={`${style.pIcon} icon-calendar`}/>
                            <div className={style.pText}>
                                {title}
                            </div>
                        </div>

                        <div
                            className={style.hPart}
                            title={fullDate}
                        >
                            <div className={`${style.pIcon} icon-clock`}/>
                            <div className={style.pText}>
                                {startDate}
                            </div>
                        </div>
                    </div>

                    <div className={style.vDescription}>
                        {data.description}
                    </div>

                    <div className={style.vInfo}>
                        <div className={style.iRow}>
                            <div className={`${style.rCell} ${style.c3Columns}`}>
                                <div className={commonStyle.cTitle}>
                                    Status
                                </div>

                                <div
                                    className={style.cValue}
                                    style={{color: STATUS_COLOR_BY_ID[data.status]}}
                                >
                                    {STATUS_TYPES.find(s => s.id === data.status)?.title}
                                </div>
                            </div>

                            <div className={`${style.rCell} ${style.c3Columns}`}>
                                <div className={commonStyle.cTitle}>
                                    Duration
                                </div>

                                <div
                                    className={style.cValue}
                                    title={duration}
                                >
                                    {duration}
                                </div>
                            </div>

                            <div className={`${style.rCell} ${style.c3Columns}`}>
                                <div className={commonStyle.cTitle}>
                                    Assigned to
                                </div>

                                <div
                                    className={style.cValue}
                                    style={{
                                        fontWeight: assignee ? '600' : '500',
                                        cursor: assignee ? 'default' : 'pointer'
                                    }}
                                    title={assignee?.guest_name}
                                    onClick={() => { if (!assignee) { this.props.onEdit() } }}
                                >
                                    {assignee ? assignee.guest_name : 'Add assignee'}
                                </div>
                            </div>
                        </div>

                        <div className={style.iRow}>
                            <div className={`${style.rCell} ${style.c2Columns}`}>
                                <div className={commonStyle.cTitle}>
                                    Primary Attendee
                                </div>

                                <div
                                    className={`${style.cValue} ${style.vLink}`}
                                    title={primaryAttendee?.guest_name}
                                    onClick={() => this.handlePrimaryAttendeeClick.bind(this)(primaryAttendee)}
                                >
                                    {primaryAttendee?.guest_name}
                                </div>
                            </div>

                            <div className={`${style.rCell} ${style.c2Columns}`}>
                                <div className={commonStyle.cTitle}>
                                    {TextManager.parseText('${ID_APPOINTMENT_RELATED_DEALS_TEXT, capitalize}')}
                                </div>

                                <div
                                    className={`${style.cValue} ${style.vLink}`}
                                    style={{fontWeight: related ? '600' : '500'}}
                                    onClick={this.handleRelatedClick.bind(this)}
                                >
                                    {related ? related : 'Add a ' +  TextManager.parseText('${ID_APPOINTMENT_RELATED_DEALS_TEXT}')}
                                </div>
                            </div>
                        </div>
                    </div>

                    {(additionalAttendees.length > 0 || data.tags.length > 0) &&
                        <div className={style.vTagSelect}>
                            {additionalAttendees.length > 0 &&
                                <div className={style.tElement}>
                                    <div className={style.tTitle}>
                                        Additional Attendees
                                    </div>

                                    <div className={style.tValue}>
                                        {additionalAttendees.map(a => {
                                            return (
                                                <div
                                                    key={`aa_${a.guest_id}`}
                                                    className={style.vTag}
                                                    title={a.guest_name}
                                                >
                                                    {a.guest_name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }

                            {data.tags.length > 0 &&
                                <div className={style.tElement}>
                                    <div className={style.tTitle}>
                                        Tags
                                    </div>

                                    <div className={style.tValue}>
                                        {data.tags.map(t => {
                                            return (
                                                <div
                                                    key={`t_${t.id}`}
                                                    className={style.vTag}
                                                    title={t.name}
                                                >
                                                    {t.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }

                            {data.funnels !== undefined && data.funnels.length > 0 &&
                                <div className={style.tElement}>
                                    <div className={style.tTitle}>
                                        {TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}')}
                                    </div>

                                    <div className={style.tValue}>
                                        {data.funnels.map(f => {
                                            return (
                                                <div
                                                    key={`f_${f.id}`}
                                                    className={style.vTag}
                                                    title={f.name}
                                                >
                                                    {f.name}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    <div style={{margin: '30px 0'}}>
                        <Notes
                            entityType='appointments'
                            entityId={data.id}
                            parent={this.props.parent}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class AppointmentPanelReact extends React.Component {
    constructor(props) {
        super(props);

        const activeView = props.id ? 'view' : 'edit';
        let appointment = {};

        if (!props.id) {
            if (props.related) {
                appointment.related = props.related;
            }

            if (props.startDate) {
                appointment.start_date = props.startDate;
            }

            if (props.tags) {
                appointment.tags = props.tags;
            }

            if (props.funnels) {
                appointment.funnels = props.funnels;
            }

            if (props.primaryAttendee) {
                appointment.guests = [{
                    role: 'primary',
                    guest_type: 'individual',
                    guest_id: props.primaryAttendee.id,
                    guest_name: props.primaryAttendee.full_name
                }];
            }
        }

        this.state = {
            right: -PANEL_WIDTH,
            activeView: activeView,
            loading: activeView === 'view',
            appointment: _.isEmpty(appointment) ? null : appointment
        };
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        if (this.props.id) {
            $.get(`/appointments/${this.props.id}`, function(result) {
                self.setState({
                    appointment: result,
                    loading: false
                });

                vent.trigger('AppContent:contentChange');
            });
        }

        _.defer(function() {
            self.setState({
                right: 0
            });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    patchAppointment(data, callback) {
        const self = this;

        $.ajax({
            type: 'PATCH',
            url: `/appointments/${this.props.id}`,
            data: JSON.stringify(data),
            success: function(result) {
                vent.trigger('appointment:updated');
                callback(result);
            }
        });
    }

    handleSave() {
        const data = this.component.getData();

        if (data) {
            this.setState({
                loading: true
            });

            const self = this;

            if (!this.props.id) {
                $.post('/appointments', JSON.stringify(data), function(result) {
                    vent.trigger('appointment:created');
                    vent.trigger('alert:show', {
                        type: function() {
                            return {
                                message: 'Appointment saved',
                                classes: 'success',
                                timer: 3000
                            };
                        }
                    });

                    self.handleClose();
                });
            } else {
                self.patchAppointment(data, function(appointment) {
                    self.setState({
                        loading: false,
                        appointment: appointment,
                        activeView: 'view'
                    });
                });
            }
        }
    }

    handleEdit() {
        this.setState({
            activeView: 'edit'
        });
    }

    handleCancel() {
        const mbContent = {
            accept_is_negative: true,
            message: 'Are you sure you want to cancel the appointment?',
            icon: 'icon-warning'
        };

        const self = this;

        MessageBox.showYesNo(mbContent, this.props.parent, function() {
            self.setState({
                loading: true
            });

            self.patchAppointment({
                status: 'cancelled'
            }, function(appointment) {
                self.setState({
                    loading: false,
                    appointment: appointment,
                    activeView: 'view'
                });
            });
        });
    }

    handleConfirm() {
        this.setState({
            loading: true
        });

        const self = this;

        this.patchAppointment({
            status: 'confirmed'
        }, function(appointment) {
            self.setState({
                loading: false,
                appointment: appointment,
                activeView: 'view'
            });
        });
    }

    handleClose(checkUnsavedChanges, backdropClick) {
        const close = () => {
            if (this.state.activeView === 'edit' && this.state.appointment?.id && !backdropClick) { // editing an existing appointment. Going back to the 'view' view
                this.setState({
                    activeView: 'view'
                });
            } else {
                const self = this;

                this.setState({
                    right: -PANEL_WIDTH
                });

                _.delay(function() {
                    self.props.onClose();
                    vent.trigger('AppContent:contentChange');
                }, ANIMATION_TIME);
            }
        }

        if (this.state.activeView === 'edit' && checkUnsavedChanges && this.component.dataHasChanged()) {
            const mbContent = {
                accept_is_negative: true,
                message: 'Unsaved data will be lost. Are you sure you want to continue?',
                icon: 'icon-warning'
            };

            MessageBox.showYesNo(mbContent, this.props.parent, function() {
                close();
            });
        } else {
            close();
        }
    }

    render() {
        let Component = null;
        let title = 'Appointment';
        let preloadedFields = this.props.id ? false : true;

        if (this.state.activeView === 'edit') {
            title = this.props.id ? 'Edit Appointment' : 'Create Appointment';
            Component = EditAppointment;
        } else if (this.state.appointment) {
            Component = ViewAppointment;
        }

        return (
            <div className={style.appointment}>
                <div
                    className={commonStyle.aTopBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aLeftBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aBackdrop}
                    onClick={() => this.handleClose.bind(this)(true, true)}
                />

                <div
                    className={commonStyle.aDialog}
                    style={{
                        right: this.state.right
                    }}
                >
                    <div className={commonStyle.dHeader}>
                        <div
                            className={commonStyle.hClose}
                            onClick={() => this.handleClose.bind(this)(true)}
                        >
                            {(this.state.activeView === 'edit' && this.state.appointment?.id) ? 'Back' : 'Close'}
                        </div>

                        <div className={commonStyle.hTitle}>
                            {title}
                        </div>
                    </div>

                    {Component &&
                        <div className={commonStyle.dContent}>
                            <Component
                                ref={(el) => this.component = el}
                                appointment={this.state.appointment}
                                parent={this.props.parent}
                                onClose={this.handleClose.bind(this)}
                                onEdit={this.handleEdit.bind(this)}
                                preloaded={preloadedFields}
                            />
                        </div>
                    }

                    {this.state.activeView === 'edit' &&
                        <div className={commonStyle.dFooter}>
                            {this.state.appointment?.id && this.state.appointment.status !== 'cancelled' &&
                                <div
                                    className={`${commonStyle.fButton} ${commonStyle.bRed}`}
                                    onClick={this.handleCancel.bind(this)}
                                >
                                    Cancel Appointment
                                </div>
                            }

                            <div
                                className={`${commonStyle.fButton} ${commonStyle.bGreen}`}
                                style={{marginLeft: 'auto'}}
                                onClick={this.handleSave.bind(this)}
                            >
                                Save
                            </div>
                        </div>
                    }

                    {this.state.activeView === 'view' &&
                        <div className={commonStyle.dFooter}>
                            {this.state.appointment?.id && this.state.appointment.status !== 'cancelled' &&
                                <div
                                    className={`${commonStyle.fButton} ${commonStyle.bRed}`}
                                    onClick={this.handleCancel.bind(this)}
                                >
                                    Cancel Appointment
                                </div>
                            }

                            <div
                                className={`${commonStyle.fButton} ${commonStyle.bBlue}`}
                                style={{marginLeft: 'auto'}}
                                onClick={this.handleEdit.bind(this)}
                            >
                                Edit
                            </div>

                            {this.state.appointment?.id && this.state.appointment.status === 'pending' &&
                                <div
                                    className={`${commonStyle.fButton} ${commonStyle.bGreen}`}
                                    style={{marginLeft: '10px'}}
                                    onClick={this.handleConfirm.bind(this)}
                                >
                                    Confirm
                                </div>
                            }
                        </div>
                    }

                    {this.state.loading &&
                        <div className={commonStyle.dLoading}>
                            <LoadingIndicator/>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const AppointmentPanel = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <AppointmentPanelReact
                parent={this}
                {...this.options || {}}
                onClose={() => this.trigger('panel:close')}
            />,
            this.$el.get(0)
        );
    },
    getAppointmentId: function() {
        return this.options.id || null;
    }
});

export default AppointmentPanel;
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import dateFormat from 'js/utils/date-format'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const headerLineOnly = {
    hLineWidth: (i) => {
        return i === 0 ? 2 : 0;
    },
    vLineWidth: () => 0,
    hLineColor: () => '#ededed'
};

pdfMake.fonts = {
    proximaNova: {
        normal: 'https://use.typekit.net/af/3333ef/00000000000000003b9ad1b5/27/l?subset_id=1&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/3333ef/00000000000000003b9ad1b5/27/d?subset_id=1&fvd=n3&v=3',
        bold: 'https://use.typekit.net/af/04b81b/00000000000000003b9ad1bb/27/l?subset_id=1&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/04b81b/00000000000000003b9ad1bb/27/d?subset_id=1&fvd=n6&v=3'
    }
};

const parseDate = (date) => {
    if (!date) {
        return '';
    }

    return `${dateFormat.formatDDMMYYYYDate(date)} ${dateFormat.shortFormatTime(date)}`;
}

const getRoomDefinition = (room, style, isLastRoom) => {
    let definition = [{
        text: room.name,
        style: `roomTitle_${style}`
    }];

    let itemsDefinition = [];

    for (let i = 0; i < room.items.length; ++i) {
        const item = room.items[i];
        const row = [];

        for (const field of ['category', 'option', 'quantity', 'price', 'total']) {
            let value = item[field] || '';
            const entry = {
                text: value,
                style: `tableRow_${style}`,
                margin: [0, 5, 0, 0]
            };


            switch (field) {
                case 'category':
                    entry.margin[0] = 10;
                    break;

                case 'price':
                    entry.alignment = 'right';

                    if (item.foc) {
                        entry.decoration = 'lineThrough';
                    }
                    break;

                case 'total':
                    entry.margin[2] = 10;
                    entry.alignment = 'right';

                    if (item.foc) {
                        entry.decoration = 'lineThrough';
                    }
                    break;
            }

            if (i === 0) {
                entry.margin[1] = 15;
            }

            row.push(entry);
        }

        itemsDefinition.push(row);
    }

    definition.push({
        layout: 'noBorders',
        table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [100, '*', 50, 50, 80],
            body: [
                [{
                    text: 'CATEGORY',
                    style: `tableHeader_${style}`,
                    margin: [10, 5, 0, 5]
                }, {
                    text: 'OPTION NAME',
                    style: `tableHeader_${style}`
                }, {
                    text: 'QUANTITY',
                    style: `tableHeader_${style}`
                }, {
                    text: 'PRICE',
                    style: `tableHeader_${style}`,
                    alignment: 'right'
                }, {
                    text: 'TOTAL PRICE',
                    style: `tableHeader_${style}`,
                    alignment: 'right',
                    margin: [0, 5, 10, 5]
                }],
                ...itemsDefinition
            ]
        }
    });

    // summary row
    definition.push({
        layout: 'noBorders',
        margin: [0, 10, 0, isLastRoom ? 10 : 30],
        table: {
            dontBreakRows: true,
            widths: ['*', 80],
            body: [[{
                text: 'SUB-TOTAL',
                alignment: 'right',
                fontSize: 11,
                bold: true
            }, {
                text: room.subTotal,
                fontSize: 11,
                alignment: 'right',
                margin: [0, 0, 10, 0]
            }]]
        }
    });

    return definition;
}

export default (data, style, getDataUrlCallback) => {
    let contentDefinition = [{
        layout: 'noBorders',
        table: {
            widths: ['*', 250],
            body: [[{
                image: `logo_${style}`,
                width: style === 'persimmon' ? 156 : 156,
                height: style === 'persimmon' ? 36 : 57
            }, {
                stack: [{
                    text: style === 'persimmon' ? 'Finishing Touches' : 'Select Options',
                    bold: true,
                    fontSize: 20,
                    color: '#1a1919',
                    alignment: 'right'
                }, {
                    text: data.purchaserApproved ? 'APPROVED' : 'DRAFT',
                    bold: true,
                    fontSize: 20,
                    margin: [0, -4, 0, 0],
                    color: data.purchaserApproved ? '#224547' : '#ec674d',
                    alignment: 'right'
                }]
            }]]
        }
    }, {
        text: style === 'persimmon' ?
            'The below Finishing Touches are made subject to availability and whilst every effort is made to ensure compliance with any request, Persimmon regrets it is unable to accept responsibility for Finishing Touches that are subsequently discontinued or for minor changes in colour or style. Please note that any Finishing Touch items are subject to availability, authorisation and any relevant permissions which may be required. In certain circumstances alternative items or products may be offered to meet the build programme.' :
            'The below Select Options are made subject to availability and whilst every effort is made to ensure compliance with any request, Charles Church regrets it is unable to accept responsibility for Select Options that are subsequently discontinued or for minor changes in colour or style. Please note that any Select Option items are subject to availability, authorisation and any relevant permissions which may be required. In certain circumstances alternative items or products may be offered to meet the build programme.',
        style: `textHeader_${style}`,
        margin: [0, 30, 0, 0]
    }, {
        layout: 'noBorders',
        margin: [0, 20, 0, 30],
        table: {
            widths: ['*', '*'],
            body: [[{
                text: [{
                    text: 'DEVELOPMENT: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.development,
                    style: `infoTableValue_${style}`
                }]
            }, {
                text: [{
                    text: 'PLOT: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.plot,
                    style: `infoTableValue_${style}`
                }]
            }], [{
                text: [{
                    text: 'PURCHASER: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.purchaser || '',
                    style: `infoTableValue_${style}`
                }]
            }, {
                text: [{
                    text: 'HOUSE TYPE: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.houseType,
                    style: `infoTableValue_${style}`
                }]
            }], [{
                text: [{
                    text: 'CONTACT NO: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.contactNo || '',
                    style: `infoTableValue_${style}`
                }]
            }, {
                text: [{
                    text: 'BUILD STAGE: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: data.buildStage,
                    style: `infoTableValue_${style}`
                }]
            }], [{
                text: [{
                    text: 'CREATED: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: parseDate(data.created),
                    style: `infoTableValue_${style}`
                }]
            }, {
                text: [{
                    text: 'LAST MODIFIED: ',
                    style: `infoTableTitle_${style}`
                }, {
                    text: parseDate(data.lastModified),
                    style: `infoTableValue_${style}`
                }]
            }]]
        }
    }];

    for (let r = 0; r < data.rooms.length; ++r) {
        contentDefinition = [contentDefinition, ...getRoomDefinition(data.rooms[r], style, r === data.rooms.length - 1)];
    }

    // discount & order total rows
    contentDefinition.push({
        layout: 'noBorders',
        margin: [0, 10, 0, 30],
        table: {
            dontBreakRows: true,
            widths: ['*', 80],
            body: [[{
                text: 'DISCOUNT',
                alignment: 'right',
                fontSize: 11,
                bold: true
            }, {
                text: `-${data.discount}`,
                fontSize: 11,
                alignment: 'right',
                color: '#70d061',
                margin: [0, 0, 10, 0]
            }], [{
                text: 'ORDER TOTAL',
                alignment: 'right',
                fontSize: 11,
                bold: true
            }, {
                text: data.orderTotal,
                fontSize: 11,
                alignment: 'right',
                margin: [0, 0, 10, 0]
            }]]
        }
    });


    // order summary
    contentDefinition.push({
        margin: [0, 10, 0, 30],
        layout: headerLineOnly,
        table: {
            dontBreakRows: true,
            widths: ['*', 75, 80],
            body: [[{
                text: style === 'persimmon' ?
                    'Any monies paid are non refundable if the purchase of the property does not proceed to completion for whatever reason (as your Finishing Touches will be ordered and installed as soon as possible).' :
                    'Any monies paid are non refundable if the purchase of the property does not proceed to completion for whatever reason (as your Select Options will be ordered and installed as soon as possible).',
                style: 'textSummary_persimmon',
                margin: [0, 15, 0, 0]
            }, {
                text: 'AMOUNT PAID',
                alignment: 'right',
                fontSize: 11,
                bold: true,
                margin: [0, 15, 0, 0]
            }, {
                text: data.amountPaid,
                fontSize: 11,
                alignment: 'right',
                margin: [0, 15, 10, 0]
            }], [{
                text: 'The balance owing will be payable on legal completion of the property.',
                style: 'textSummary_persimmon',
                margin: [0, 10, 0, 0]
            }, {
                text: 'BALANCE',
                alignment: 'right',
                fontSize: 11,
                bold: true,
                margin: [0, 10, 0, 0]
            }, {
                text: data.balance,
                fontSize: 11,
                alignment: 'right',
                margin: [0, 10, 10, 0]
            }]]
        }
    });

    // final info table
    let finalInfoDefinition = [[{
        text: [{
            text: 'SALES ADVISOR: ',
            style: `infoTableTitle_${style}`
        }, {
            text: data.salesAdvisor,
            style: `infoTableValue_${style}`
        }]
    }]];

    if (data.purchaserApproved) {
        finalInfoDefinition.unshift([{
            text: [{
                text: 'APPROVAL DATE + TIME: ',
                style: `infoTableTitle_${style}`
            }, {
                text: parseDate(data.approvalDate),
                style: `infoTableValue_${style}`
            }]
        }]);

        finalInfoDefinition.unshift([{
            text: [{
                text: 'PURCHASER APPROVED: ',
                style: `infoTableTitle_${style}`
            }, {
                text: data.purchaserApproved,
                style: `infoTableValue_${style}`
            }]
        }]);
    }

    contentDefinition.push({
        margin: [0, 10, 0, 0],
        layout: 'noBorders',
        table: {
            widths: ['*'],
            dontBreakRows: true,
            body: finalInfoDefinition
        }
    });

    let docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 40, 40, 40],
        content: contentDefinition,
        defaultStyle: { font: 'proximaNova' },
        styles: {
            roomTitle_persimmon: {
                margin: [10, 0, 0, 10],
                color: '#1a1919',
                bold: true
            },
            roomTitle_charlesChurch: {
                margin: [10, 0, 0, 10],
                color: '#1a1919',
                bold: true
            },
            tableHeader_persimmon: {
                fontSize: 10,
                fillColor: '#f9f7f7',
                color: '#1a1919',
                margin: [0, 5, 0, 5],
                bold: true
            },
            tableHeader_charlesChurch: {
                fontSize: 10,
                fillColor: '#f9f7f7',
                color: '#1a1919',
                margin: [0, 5, 0, 5],
                bold: true
            },
            tableRow_persimmon: {
                color: '#1a1919',
                fontSize: 9
            },
            tableRow_charlesChurch: {
                color: '#1a1919',
                fontSize: 9
            },
            textHeader_persimmon: {
                fontSize: 10,
                color: '#1a1919'
            },
            textHeader_charlesChurch: {
                fontSize: 10,
                color: '#1a1919'
            },
            textSummary_persimmon: {
                fontSize: 10,
                color: '#1a1919'
            },
            textSummary_charlesChurch: {
                fontSize: 10,
                color: '#1a1919'
            },
            infoTableTitle_persimmon: {
                color: '#1a1919',
                bold: true,
                fontSize: 11
            },
            infoTableTitle_charlesChurch: {
                color: '#1a1919',
                bold: true,
                fontSize: 11
            },
            infoTableValue_persimmon: {
                color: '#1a1919',
                fontSize: 11
            },
            infoTableValue_charlesChurch: {
                color: '#1a1919',
                fontSize: 11
            }
        },
        images: {
            logo_persimmon: `${app.options.appUrl}/img/choices/ph-logo.png`,
            logo_charlesChurch: `${app.options.appUrl}/img/choices/cc-logo.png`
        }
    };


    const doc = pdfMake.createPdf(docDefinition);

    if (getDataUrlCallback) {
        doc.getDataUrl(getDataUrlCallback);
    } else {
        doc.open();
    }
}
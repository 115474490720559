import React from 'react';
import classnames from 'classnames';

import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';

import {TagList} from 'js/react_views/detail_view_components/tag-list';

import style from './Header.css';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
    }

    renderLogo() {
        const { name, abbreviation } = this.props;
        const bubbleText = AppConfig.getClientPreferenceValue('showDealIdOnBubble') ? abbreviation : name;
        const renderBubble = !AppConfig.getValue('disableBubbleOnDeals', false);

        if (renderBubble) {
            return (
                <div className={style.logo}>
                    <div className={style.logoCircle}>
                        <span className={style.logoText}>
                            {bubbleText || TextManager.parseText('${ID_DEAL, capitalize}')}
                        </span>
                    </div>
                </div>
            );
        }
    }

    renderName() {
        return (
            <h3 className={style.name}>
                {this.props.name}
            </h3>
        );
    }

    handleOrganizationClick(ev) {
        ev.preventDefault();
        this.props.onOrganizationClick();
    }

    renderOrganizationLink() {
        const {
            organizationId,
            organizationName
        } = this.props;

        const renderOrganization = !AppConfig.getValue('disableOrganizationOnDeals', false);

        if (organizationId && organizationName && renderOrganization) {
            return (
                <a
                    className=''
                    href={'#organizations/' + organizationId}
                    onClick={this.handleOrganizationClick}>
                    {organizationName}
                </a>
            );
        }
    }

    renderCoinsCode() {
        const { funnel } = this.props;

        if (funnel.integration_data && funnel.integration_data.coinsCode) {
            return (
                <div className={style.coinsCode}>
                    Coins Code: {funnel.integration_data.coinsCode}
                </div>
            )
        }

        return null
    }

    renderBrandName() {
        const displayBrandName = AppConfig.getValue('dealViewDisplayBrandName', false);
        if ( ! displayBrandName ) {
            return null;
        }

        const { funnel } = this.props;

        if (funnel.title.toLowerCase() === 'reservations') {
            return null;
        }

        const brandName = funnel?.integration_data?.brandCode === 'C' ? 'Charles Church' : 'Persimmon Homes';

        return (
            <div className={style.brandName}>
                Brand: {brandName}
            </div>
        )
    }

    renderTags() {
        const { tags } = this.props;

        const renderTags = !AppConfig.getValue('disableTagsOnDeals', false);

        if (renderTags) {
            return (<TagList tags={tags} />)
        }
    }

    render() {
        return (
            <section className={style.Header}>
                {this.renderLogo()}
                {this.renderName()}
                {this.renderCoinsCode()}
                {this.renderBrandName()}
                {this.renderOrganizationLink()}
                {this.renderTags()}
            </section>
        )
    }
};

export default Header;

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import SectionBase from 'app_v2/sections/base/section';
import FinderContentManager from 'app_v2/sections/finder_content_manager';
import { getAllColumns, getDefaultColumnsIds } from './table_columns';

class ContentFetcher {
    constructor() {
        this.folderId = null;
        this.urlByFileId = {};
    }

    fetch(page, callback) {
        this.urlByFileId = {};

        const self = this;

        $.get(`/content_folders/${this.folderId}`, function(result) {
            let rows = [];

            for (const file of result.child_files || []) {
                rows.push({
                    id: file.id,
                    name: file.name,
                    created: file.created
                });

                self.urlByFileId[file.id] = file.url;
            }

            rows = _.sortBy(rows, r => r.created).reverse();

            callback({
                rows: rows,
                total: rows.length,
                start: 0,
                numRows: rows.length
            });
        });
    }

    setFetchArgs(args) {
        this.folderId = args.folderId;
    }

    filterBy() {}
}


export default class ReportsReactView extends React.Component {
    constructor(props) {
        super(props);

        this.allColumns = getAllColumns();
        this.tableColumns = [];

        for (const cid of getDefaultColumnsIds()) {
            this.tableColumns.push(this.allColumns.find(c => c.id === cid));
        }

        this.finderContentManager = new FinderContentManager();
        this.fetcher = new ContentFetcher();
    }

    componentDidMount() {
        this.mounted = true;
        this.createFinderStructure();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    createFinderStructure(activeGroupId) {
        const reportFolderId = 'reports';
        const self = this;

        $.get(`/content_folders/${reportFolderId}`, function(data) {
            if (!self.mounted) {
                return;
            }

            const groups = [];

            for (const group of data.child_folders) {
                groups.push(self.finderContentManager.createSpecialGroup(group.id, group.name, 'icon-file', { fetchArgs: { folderId: group.id } }));
            }

            let sections = [{
                id: 'reports',
                entityType: 'reports',
                name: 'Reports',
                content: groups
            }];

            self.finderContentManager.addSections(sections, 0);
            self.finderContentManager.setActiveGroupId(groups[0].id);
            self.sectionComponent.onContentManagerUpdated();
        });
    }

    handleRowClick(rowId) {
        window.open(this.fetcher.urlByFileId[rowId], '_blank');
    }

    getUrl() {
        return 'reports';
    }

    getParams() {
        let params = {}

        return params;
    }

    render() {
        return (
            <SectionBase
                ref={(el) => this.sectionComponent = el}
                parent={this.props.parent}
                entityType='reports'
                activeView='table'
                onRowClick={this.handleRowClick.bind(this)}
                finder={{
                    contentManager: this.finderContentManager
                }}
                toolbar={{
                    views: {
                        table: true
                    }
                }}
                table={{
                    fetcher: this.fetcher,
                    allColumns: this.allColumns,
                    defaultColumnsIds: getDefaultColumnsIds(),
                    columns: this.tableColumns
                }}
            />
        );
    }
}

import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react'
import ReactDOM from 'react-dom'

import backboneSelect2 from 'js/widgets/backbone-select2'
import app from 'js/app'
import AppConfig from 'app/app-config'
import TextManager from 'app/text-manager'
import vent from 'js/vent'
import FunnelView from 'js/views/funnel'
import FunnelModel from 'js/models/funnel'
import PhaseModel from 'js/models/phase'
import LoadingView from 'js/views/loading'
import funnelExTemplate from 'templates/funnelEx.handlebars'
import OpportunitiesCollection from 'js/collections/opportunities'
import CustomFieldModel from 'js/models/custom_field.js'
import DealsCalendar from 'js/react_views/deals-calendar/deals-calendar'
import FunnelMap from 'js/react_views/funnel-map/funnel-map'


const MAP_AVAILABLE_ACTIONS = [{
    id: 'download-pdf',
    title: 'Download as PDF'
}, {
    id: 'table-view',
    title: 'Table View'
}];

const CALENDAR_AVAILABLE_ACTIONS = [{
    id: 'download-pdf',
    title: 'Download as PDF'
}];


class ReactCustomLegendItemView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cf_options: []
        }

        this.initializeStateCfOptions = this.initializeStateCfOptions.bind(this);
    }

    componentDidMount(){
        const {legendItem} = this.props
        const self = this;

        if(app.globalData.colorkeysInfo && app.globalData.colorkeysInfo[legendItem.dropdown_id]){
            this.initializeStateCfOptions();

        } else{
            if (legendItem.is_system_field) {
                app.globalData.colorkeysInfo[legendItem.dropdown_id] = {};
                this.initializeStateCfOptions();
            } else {
                const customField = new CustomFieldModel(app.globalData.customFieldsInfo.opportunitiesArray.find(cf => cf.id === legendItem.dropdown_id));

                if (!app.globalData.colorkeysInfo){
                    app.globalData.colorkeysInfo = {}
                }

                app.globalData.colorkeysInfo[legendItem.dropdown_id] = customField.attributes.options;
                self.initializeStateCfOptions();
            }
        }
    }

    initializeStateCfOptions(){
        const {legendItem} = this.props;

        let cf_options = []

        if (legendItem.colours) {
            legendItem.colours.forEach((colour) => {
                const cf_option = {
                    optionId: colour.option_id,
                    hex: colour.hex,
                    font_hex: colour.font_hex,
                    optionName: app.globalData.colorkeysInfo[legendItem.dropdown_id].find(item => item.id === colour.option_id).value || ''
                }

                cf_options.push(cf_option);
            });
        } else if (legendItem.colours_ex) {
            legendItem.colours_ex.forEach((colour) => {
                const cf_option = {
                    optionId: colour.id,
                    hex: colour.hex,
                    font_hex: colour.font_hex,
                    optionName: colour.name
                }

                cf_options.push(cf_option);
            });
        }

        this.setState({cf_options: cf_options})
    }

    render() {
        const {cf_options} = this.state;
        return (
            <ul style={{display: "flex"}}>
                {cf_options && cf_options.length > 0 &&
                cf_options.map(option => (
                    <li key={option.optionId} style={{backgroundColor: option.hex, flexGrow: 1}} className={`custom-color-${option.hex}`}>
                        <span className="value" style={{color: option.font_hex || "#000"}}>{option.optionName}</span>
                    </li>
                ))}
            </ul>
        );
    }
}

var CustomLegendItemView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <ReactCustomLegendItemView parent={this}  legendItem={this.options.legendItem}/>,
            this.$el.get(0)
        );
    },
});

var CalendarReact = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <DealsCalendar
                onShowDeal={(dealId) => this.options.parent.showDeal(dealId, true)}
                actionsView={this.options.actionsView}
            />,
            this.$el.get(0)
        );
    }
});

var MapReact = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        const funneld3 = this.options.parent.funnelView.funnel;
        const activeFunnelId = funneld3.activeFunnel();
        const deals = funneld3.deals().filter(d => (d.get('funnel') || {}).id === activeFunnelId);

        ReactDOM.render(
            <FunnelMap
                ref={(el) => this.component = el}
                colorScheme={funneld3.colorScheme}
                funnelId={activeFunnelId}
                deals={deals}
                actionsView={this.options.actionsView}
                onShowDeal={(dealId) => this.options.parent.showDeal(dealId, true)}
            />,
            this.$el.get(0)
        );
    }
});

var ActionsView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        ReactDOM.render(
            <div className='view-actions-container'>
                {this.options.actions.map(action => {
                    return (
                        <div
                            id={action.id}
                            key={`${action.title}_${action.id}`}
                            className='view-action'
                            onClick={() => { if (this.clickCallback) this.clickCallback(action.id) }}
                        >
                            {action.title}
                        </div>
                    );
                })}
            </div>,
            this.$el.get(0)
        );
    }
});

var FunnelViewEx = Marionette.Layout.extend( {
    template: Handlebars.compile( funnelExTemplate ),
    regions: {
        funnelRegion: '.funnel-container',
        loaderRegion: '.loader-container',
        customLegendRegion: '.custom-legend-container',
        calendarRegion: '#deals-calendar-container',
        mapRegion: '#funnel-map-container',
        actionsRegion: '.funnel-active-view-actions'
    },
    ui: {
        deals: '.deals-select',
        colorkey: '.color-key-select',
        funnelName: '#funnel-name',
        spanText: '.span-text',
        widget: '.funnel-widget',
        leftZone: '.left-zone',
        rightZone: '.right-zone',
        fullscreenIcon: '.fullscreen-button > .icon',
        footer: '.footer',
        dealsCalendar: '#deals-calendar-container',
        funnelMap: '#funnel-map-container'
    },
    events: {
        'click .funnel-type-nav a' : 'toggleVisualizationType',
        'change .color-key-select': 'onColorKeySelect',
        'click #time-span .3w-ago': 'select3wAgo',
        'click #time-span .2w-ago': 'select2wAgo',
        'click #time-span .1w-ago': 'select1wAgo',
        'click #time-span .today': 'selectToday',
        'click .manage-funnels': 'manageFunnels',
        'click .fullscreen-button': function() {
            if (this.ui.widget.hasClass('expanded')) {
                this.shrink();
            }
            else {
                this.fullscreen();
            }
        },
        'change .deals-select': function() {
            this.fetchDealsData();
        },
        'show-deal': function(ev, id) {
            this.showDeal(id);
        },
        'deal-phase-change': function(_, data) {
            if (this.options.triggerAllEvents) {
                this.trigger('deal-phase-change', data);
            }
        },
        'change-funnel': function(_, funnelId) {
            if (this.options.triggerAllEvents) {
                this.trigger('change-funnel', funnelId);
            }
        }
    },

    templateHelpers: function() {
        return {
            showEditFunnelsButton: app.user.get('is_admin') && AppConfig.getValue('funnel.colorkey_widget.edit_funnels.visible', true),
            extendedColorKey: AppConfig.getValue('funnel.colorkey_widget.extended', false),
            showDealsSelect: AppConfig.getValue('showAllOrMineDealsSelect'),
            mapsAvailable: app.globalData.funnelsInfo.mapsAvailable,
            showDealsCalendar: AppConfig.getClientPreferenceValue('showDealsCalendar'),
            hideColorkeyDropdown: !!AppConfig.getValue('funnel.colorkey_widget.fixed_value')
        };
    },

    initialize: function( viewParams ) {
        this.funnelView = new FunnelView({
            section: viewParams.section
        });

        var preferences = app.user.get('preferences');

        // ...
        this.allOrMyDeals = preferences.allOrMyDeals || {name: TextManager.getText('ID_ALL_DEALS'), id: 'all_deals'};
        this.activeVisualizationType = null;
        this.initialColorKey = AppConfig.getValue('funnel.colorkey_widget.fixed_value') || preferences.funnel_color || viewParams.funnelColor || 'activity';
        this.funnelView.setColorKey(this.initialColorKey);
        this.initialVisualizationType = preferences.funnel_visualization_type || 'funnel';

        // calendar and map views are shonw in fullscreen
        if (['calendar', 'map'].indexOf(this.initialVisualizationType) !== -1) {
            this.initialVisualizationType = 'funnel';
        }

        // ... view params ...
        this.viewParams = viewParams;
    },

    onRender: function() {
        this.initSelect2();
        this.initDealsSelectBox();
        this.initTooltips();

        if (this.initialVisualizationType) {
            this.setActiveType(this.initialVisualizationType);
            this.initialVisualizationType = null;
        }

        if (this.initialColorKey.indexOf('custom_colorkey') !== -1) {
            const clientPreferences = app.user.get('client').preferences;
            const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ?
                JSON.parse(clientPreferences.custom_colorkeys) : [];

            this.customLegendRegion.show(new CustomLegendItemView({
                legendItem: customColorkeys.find(cfColorKeys => `custom_colorkey_${cfColorKeys.dropdown_id}` === this.initialColorKey) || {}
            }));
        }

        this.listenTo(vent, 'deal:view:closed', function() {
            if (['calendar', 'map'].indexOf(this.activeVisualizationType) !== -1) {
                if (!this.ui.widget.hasClass('expanded')) {
                    this.fullscreen();
                }
            }
        });

        // ...
        this.renderFunnel();
    },

    showDeal(dealId, triggerEvent) {
        if (this.ui.widget.hasClass('expanded')) {
            this.shrink();
        }

        if (this.options.triggerAllEvents || triggerEvent) {
            this.trigger('show-deal', dealId);
        }
    },

    fetchDealsData: function() {
        if (this.allOrMyDeals === this.ui.deals.select2('data')) {
            return;
        }

        var preferences = app.user.get('preferences');

        this.renderFunnel();

        this.allOrMyDeals = this.ui.deals.select2('data');
        preferences.allOrMyDeals = this.allOrMyDeals;
        $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
        this.fetchDealsCollectionByOwner();
    },

    initDealsSelectBox: function() {
        var data = [
            {name: TextManager.getText('ID_ALL_DEALS'), id: 'all_deals'},
            {name: TextManager.getText('ID_MY_DEALS'), id: 'my_deals'}];
        //
        this.dealsSelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.deals,
            text: 'name',
            data: data,
            value: this.allOrMyDeals,
            options: {
                containerCssClass: 'select2-plain select2-block title',
                dropdownCssClass: 'funnel-deal-popover popover',
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });
    },

    renderFunnel: function() {
        if (['calendar'].indexOf(this.activeVisualizationType) === -1) {
            this.funnelView.renderFunnel();
        }
    },

    initTooltips: function() {
        this.$el.find('[data-toggle=tooltip]').tooltip({
            container: 'body'
        });
    },
    initSelect2: function() {
        var view = this;

        function formatResult(item, container) {

            $(container).attr('data-target', item.target);

            return Handlebars.compile(
                item.text +
                '<div class="description">' + item.description + '</div>'
            );
        }
        var colorKeyData = [
            {
                id: 'none',
                text: 'None',
                description: 'Remove all color from funnel.',
                target: '#colorkey1'
            },
            {
                id: 'closedate',
                text: 'Close',
                description: 'Color by close date, relative to the current period end.',
                target: '#colorkey2'
            },
            {
                id: 'activity',
                text: 'Activity',
                description: TextManager.getText('ID_FUNNEL_COLOR_BY_DEAL_ACTIVITY'),
                target: '#colorkey3'
            }
        ];

        if (!AppConfig.getValue('disableForecastOptionFromFunnelColorWidget')) {
            colorKeyData.push({
                id: 'forecast',
                text: 'Forecast',
                description: TextManager.getText('ID_FUNNEL_COLOR_BY_FORECAST_STATUS'),
                target: '#colorkey4'
            });
        }

        if (AppConfig.getValue('enableGoAssignIssueOptionFromFunnelColorWidget')) {
            colorKeyData.push({
                id: 'goAssignIssue',
                text: 'Go-Assign-Issue',
                description: 'Color priority status.',
                target: '#colorkey5'
            });
        }

        if (AppConfig.getValue('customColorKeys')){
            const clientPreferences = app.user.get('client').preferences;
            const customColorkeys = (clientPreferences && clientPreferences.custom_colorkeys) ?
                JSON.parse(clientPreferences.custom_colorkeys) : [];

            if (customColorkeys && customColorkeys.length > 0){
                customColorkeys.forEach(cfColorKeys => {
                    colorKeyData.push({
                        id: `custom_colorkey_${cfColorKeys.dropdown_id}`,
                        dropdown_id: cfColorKeys.dropdown_id,
                        is_system_field: cfColorKeys.is_system_field,
                        text: cfColorKeys.name,
                        description: cfColorKeys.description,
                        colours: cfColorKeys.colours,
                        colours_ex: cfColorKeys.colours_ex
                    });

                    if (cfColorKeys.colours) {
                        cfColorKeys.colours.forEach(colour => {
                            const rules = document.all ? document.styleSheets[0].rules : document.styleSheets[0].cssRules;
                            var foundStyle = false;
                            for(const rule of rules) {
                                if(rule.cssText.indexOf(`custom-color-${colour.option_id}`) !== -1 && rule.style) {
                                    foundStyle = true
                                }
                            }
                            if(!foundStyle){
                                this.addCSSRule(document.styleSheets[0], `.custom-color-${colour.option_id} circle.visible`, `fill: ${colour.hex}!important; stroke: ${colour.hex}!important; background: ${colour.hex}!important`, 1);
                                this.addCSSRule(document.styleSheets[0], `.funnelMapPoint.custom-color-${colour.option_id}`, `background: ${colour.hex}!important`, 1);

                                if(colour.font_hex){
                                    this.addCSSRule(document.styleSheets[0], `.custom-color-${colour.option_id} .abbreviation`, `fill: ${colour.font_hex}!important;`, 1);
                                    this.addCSSRule(document.styleSheets[0], `.funnelMapPoint.custom-color-${colour.option_id}`, `color: ${colour.font_hex}!important;`, 1);
                                }
                            }
                        })
                    } else if (cfColorKeys.colours_ex) {
                        cfColorKeys.colours_ex.forEach(colour => {
                            const rules = document.all ? document.styleSheets[0].rules : document.styleSheets[0].cssRules;
                            var foundStyle = false;
                            for(const rule of rules) {
                                if(rule.cssText.indexOf(`custom-color-${colour.id}`) !== -1 && rule.style) {
                                    foundStyle = true
                                }
                            }
                            if(!foundStyle){
                                this.addCSSRule(document.styleSheets[0], `.custom-color-${colour.id} circle.visible`, `fill: ${colour.hex}!important; stroke: ${colour.hex}!important; background: ${colour.hex}!important`, 1);
                                this.addCSSRule(document.styleSheets[0], `.funnelMapPoint.custom-color-${colour.id}`, `background: ${colour.hex}!important`, 1);

                                if(colour.font_hex){
                                    this.addCSSRule(document.styleSheets[0], `.custom-color-${colour.id} .abbreviation`, `fill: ${colour.font_hex}!important;`, 1);
                                    this.addCSSRule(document.styleSheets[0], `.funnelMapPoint.custom-color-${colour.id}`, `color: ${colour.font_hex}!important;`, 1);
                                }
                            }
                        });
                    }
                })
            }
        }

        this.colorKeySelect = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.colorkey,
            text: 'text',
            data: colorKeyData,
            value: this.initialColorKey,

            options: {
                containerCssClass: 'select2-plain',
                dropdownCssClass: 'colorkey-select-popover popover has-description',
                formatResult: formatResult,
                minimumResultsForSearch: -1 // Hide searchbox
            }
        });

        // Show colorkey tab once everything has loaded - doesn't work without defer for some reason???
        _.defer(function() {
            for (var i = 0; i < colorKeyData.length; ++i) {
                if (colorKeyData[i].id === view.initialColorKey) {
                    view.showColorKeyTab(colorKeyData[i]);
                    break;
                }
            }

            view.initialColorKey = null;
        });
    },
    addCSSRule: function(sheet, selector, rules, index) {
        if("insertRule" in sheet) {
            sheet.insertRule(selector + "{" + rules + "}", index);
        }
        else if("addRule" in sheet) {
            sheet.addRule(selector, rules, index);
        }
    },
    onColorKeySelect: function( ev ) {
        var activeVal = ev.added.id;

        var preferences = app.user.get('preferences');
        preferences.funnel_color = activeVal;
        $.post(app.user.url() + '/preferences', JSON.stringify(preferences));

        if(ev.added.id.indexOf('custom_colorkey') !== -1){
            this.activeTarget.tab("show").hide();
            this.customLegendRegion.show( new CustomLegendItemView({
                legendItem: ev.added
            }));
        } else {
            this.showColorKeyTab(ev.added);
            this.customLegendRegion.reset();
        }

        this.funnelView.setColorKey( activeVal );

        this.renderFunnel();

        if (this.activeVisualizationType === 'map') {
            this.mapRegion.currentView.component.updateDealsColors();
        }
    },
    showColorKeyTab: function(item) {
        if(this.activeTarget){
            this.activeTarget.tab("show").hide();
        }
        var activeTarget = this.$el.find(`[data-target="${item.target}"]`);
        this.activeTarget = activeTarget;
        $(activeTarget).tab('show').show();
    },
    setActiveType: function(type) {
        if (type === this.activeVisualizationType) {
            return;
        }

        this.activeVisualizationType = type;

        switch(type) {
            case 'calendar': {
                const actionsView = new ActionsView({actions: CALENDAR_AVAILABLE_ACTIONS});
                const calendarView = new CalendarReact({parent: this, actionsView: actionsView});

                this.ui.dealsCalendar.show();
                this.funnelView.hide();
                this.ui.footer.hide();
                this.ui.funnelMap.hide();

                this.calendarRegion.show(calendarView);
                this.actionsRegion.show(actionsView);
                this.fullscreen();
            }
            break;

            case 'map': {
                const actionsView = new ActionsView({actions: MAP_AVAILABLE_ACTIONS});
                const mapView = new MapReact({parent: this, actionsView: actionsView});

                this.funnelView.show();
                this.setFunnelType('none');
                this.funnelView.renderFunnel();

                this.ui.dealsCalendar.hide();
                this.ui.footer.show();
                this.ui.funnelMap.show();

                this.mapRegion.show(mapView);
                this.actionsRegion.show(actionsView);
                this.fullscreen();
            }
            break;

            default:
                this.ui.dealsCalendar.hide();
                this.funnelView.show();
                this.ui.footer.show();
                this.ui.funnelMap.hide();
                this.setFunnelType(type);
                this.actionsRegion.close();
                break;
        }

        this.$el.find('.funnel-type-nav .active').removeClass('active');
        this.$el.find('.type-' + type).addClass('active');
    },

    toggleVisualizationType: function(ev) {
        var newType = $(ev.currentTarget).data('type');
        ev.preventDefault();

        if (['calendar', 'map'].indexOf(newType) === -1) {
            var preferences = app.user.get('preferences');
            preferences.funnel_visualization_type = newType;
            $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
        }

        this.setActiveType(newType);
        this.renderFunnel(true);
    },

    select3wAgo: function( ev ) {
        this.selectTime( ev, 3 );
    },

    select2wAgo: function(ev) {
        ev.preventDefault();
        this.selectTime( ev, 2 );
    },

    select1wAgo: function(ev) {
        ev.preventDefault();
        this.selectTime( ev, 1 );
    },

    selectToday: function(ev) {
        ev.preventDefault();
        this.selectTime( ev, 0 );
    },

    manageFunnels: function(ev) {
        ev.preventDefault();
        vent.trigger('settings:show', 'editFunnelPhases');
    },

    selectTime: function( ev, weeksDelta ) {
        ev.preventDefault();

        // Update 'active' class
        $( '#time-span li' ).removeClass( 'active' );
        $( ev.currentTarget ).addClass( 'active' );

        this.funnelView.setDragEnabled( weeksDelta === 0 );
        this.renderFunnel();

        this.trigger( 'funnel:timeChange', weeksDelta );
    },
    onShow: function() {
        this.funnelRegion.show( this.funnelView );

        // ... viewparams ...
        if ( this.viewParams.hideWeeks ) {
            this.$el.find( '.3w-ago' ).hide();
            this.$el.find( '.2w-ago' ).hide();
            this.$el.find( '.1w-ago' ).hide();
            this.$el.find( '.today' ).hide();
            this.ui.spanText.hide();
        }

        if ( this.viewParams.hideDealsSelect ) {
            this.$el.find( '.deals-select' ).hide();
        }

        if ( this.viewParams.dashboardView ) {
            this.$el.find( '.footer' ).hide();

            if ( this.viewParams.dashboardView === 'pdcrm' ) {
                this.$el.find( '.header' ).hide();
            } else {
                this.$el.find( '.header-inner' ).hide();
                this.$el.find( '.right-zone' ).hide();
            }

            this.funnelView.dashboardView( this.viewParams.dashboardView );
        }
    },

    setFunnelType: function( type ) {
        this.funnelView.setType( type );
    },

    setFunnels: function( funnels, activeFunnel ) {
        var singleFunnel = funnels.length < 2;

        this.ui.leftZone.toggleClass('wider', singleFunnel);
        this.ui.rightZone.toggleClass('narrow', singleFunnel);

        // ...
        this.funnelView.setFunnels( funnels, activeFunnel );

        if (this.isClosed) {
            return;
        }

        var activeFunnelObj = null;

        for ( var i = 0; i < funnels.length; ++i ) {
            var f = funnels[ i ];

            if ( f.get( 'id' ) !== activeFunnel ) {
                continue;
            }

            activeFunnelObj = f.toJSON();
            this.ui.funnelName.text(f.get( 'name' ));
            break;
        }

        if (this.activeVisualizationType === 'map') {
            this.mapRegion.currentView.component.setFunnel(activeFunnelObj);
        }
    },

    setDeals: function( deals, time ) {
        this.funnelView.setDeals( deals );
        this.funnelView.setDateTime( time ? time : new Date() );

        if (this.activeVisualizationType === 'map') {
            this.mapRegion.currentView.component.setDeals(deals);
        }
    },

    setDealsStatus: function( status ) {
        this.funnelView.setDealsStatus(status);
    },

    setPhases: function( phases ) {
        this.funnelView.setPhases( phases );
    },

    setPeriods: function( periods ) {
        this.funnelView.setPeriods( periods );
    },

    close: function() {
        Marionette.Layout.prototype.close.apply(this, arguments);
        this.funnelView.close();
    },

    highlight: function( id ) {
        this.funnelView.highlight( id );
    },

    fullscreen: function() {
        this.trigger('funnel:expanded');
        this.ui.widget.addClass('expanded');
        this.ui.fullscreenIcon.addClass('icon-resize-shrink');

        if (this.activeVisualizationType === 'map') {
            this.mapRegion.currentView.component.onFunnelTransitionBegin(true);
        }

        var view = this;

        _.delay(function() {
            view.renderFunnel();

            if (view.activeVisualizationType === 'map') {
                view.mapRegion.currentView.component.onFunnelTransitionEnd(true);
            }
        }, 500);
    },

    shrink: function() {
        this.trigger('funnel:shrinked');
        this.ui.widget.removeClass('expanded');
        this.ui.fullscreenIcon.removeClass('icon-resize-shrink');

        if (this.activeVisualizationType === 'map') {
            this.mapRegion.currentView.component.onFunnelTransitionBegin(false);
        }

        var view = this;

        _.delay(function() {
            view.renderFunnel();

            if (view.activeVisualizationType === 'map') {
                _.delay(function() {
                    view.mapRegion.currentView.component.onFunnelTransitionEnd(false);
                }, 200);
            }
        }, 500);
    },
    showLoader: function() {
        this.loaderRegion.show( new LoadingView() );
    },
    hideLoader: function() {
        this.loaderRegion.reset();
    },
    fetchDealsCollectionByOwner: function() {
        var view = this;
        var deals = new OpportunitiesCollection();
        var fetchOptions = _.extend({}, this.fetchOptions, {
            owner_id: this.allOrMyDeals && this.allOrMyDeals.id === 'my_deals' ? app.user.get('id') : undefined
        });

        // endpoint /opportunities use the old name for the group_id
        fetchOptions.by_group_id = fetchOptions.group_id;

        this.showLoader();

        deals.fetch({
            rows: -1,
            data: fetchOptions,
            success: function(collection) {
                view.hideLoader();
                view.setDeals(collection.models);
                view.renderFunnel();
            }
        });
    }
} );

export default FunnelViewEx;

import React from 'react';
import Marionette from 'Backbone.Marionette';

import dateFormat from 'js/utils/date-format';

import style from './date.css';

export default class DateField extends React.Component {
    constructor(props) {
        super(props);

        this.style = {};

        if (this.props.width) {
            this.style.width = this.props.width;
        }

        if (this.props.height) {
            this.style.height = this.props.height;
        }

        if (this.props.padding) {
            this.style.paddingLeft = this.props.padding;
            this.style.paddingRight = this.props.padding;
        }

        this.state = {
            value: props.value ? dateFormat.shortFormatWithYear(props.value) : 'Click to select date'
        };
    }

    handleFocus(ev) {
        const self = this;

        $(ev.currentTarget).datepicker({
            defaultDate: this.state.value,
            numberOfMonths: 2,
            dateFormat: 'M d, yy',
            timezone: "+0000",
            showButtonPanel: true,
            yearRange: '-99:+10',
            changeYear: true,
            onSelect: function(dateStr) {
                self.props.onSelect(new Date(dateStr));
            }
        });
    }

    render() {
        return (
            <input
                className={style.dateField}
                style={this.style}
                value={this.state.value}
                onFocus={this.handleFocus.bind(this)}
                onChange={() => {}} // to remove the warning
            />
        );
    }
}
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'


var BaseView = Marionette.Layout.extend({
    initialize: function(){
        // Set default speed for jQuery animations
        $.fx.speeds._default = 250;
    }
});

export default BaseView;

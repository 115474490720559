import $ from 'jquery'
import _ from 'underscore'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import app from 'js/app.js'
import Currency from 'js/utils/currency.js'
import TextManager from 'app/text-manager'
import tableTemplate from 'templates/forecasts/totals.handlebars'


var ForecastTotalsView;

ForecastTotalsView = Marionette.ItemView.extend({
    className: 'forecast-totals',
    template: Handlebars.compile(tableTemplate),
    ui: {
        table: '.table-data',
        weighted_radio: '.weighted-radio-group'
    },
    events: {
        'mouseover .hoverable': function(ev) {
            var allCells = this.$el.find('td, th'),
                el = $(ev.currentTarget),
                pos = el.index();

            // el.parent().find('th, td').addClass('hover'); // Apply class to hovered row cells too
            allCells.filter(':nth-child(' + (pos+1) + ')').addClass('hover');
        },
        'mouseout .hoverable': function() {
            this.ui.table.find('.hover').removeClass('hover');
        }
    },
    templateHelpers: function() {
        var totals = this.getTotals();
        return {
            buckets: totals.buckets,
            matrix: totals.matrix,
            total: totals.total,
            currency: app.user.get('client').default_currency
        };
    },
    initialize: function(options) {
        var view = this;

        this.parent = options.parent;
        this.controller = options.controller;

        this.listenTo(this.controller, 'weighted', function() {
            if (view.isClosed) {
                return;
            }
            view.render();
        });
    },
    /**
     * Returns total values combined by statuses and buckets as a matrix.
     *
     * {
     *      matrix      2D array with total values with totals for statuses
     *      buckets     array of bucket objects with totals
     *      total       grand total
     * }
     *
     * @param   bool    weighted        should weight be taken into calculation
     * return   obj
     */
    getTotals: function() {
        var self = this,
            map = {},
            matrix = [],
            buckets = [],
            statusTotals = {},
            bucketTotals = {},
            total = 0,
            statuses = [
                { id: 'none', name: TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}') },
                { id: 'none_upside', name: TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}') },
                { id: 'committed_downside', name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}') },
                { id: 'committed', name: TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}') }
            ],
            weighted = this.controller.isWeighted();

        // initialize bucket list and bucketTotal dictionary
        self.options.buckets.each(function(bucket) {
            var bucketId = bucket.get('id');
            buckets.push({ id: bucketId, name: bucket.get('name') });
            bucketTotals[bucketId] = 0;
        });
        // initialize statuses list, statusTotals dictionary and totals map
        _.each(statuses, function(status) {
            statusTotals[status.id] = 0;
            _.each(buckets, function(bucket) {
                if (!map[status.id]) {
                    map[status.id] = {};
                }
                map[status.id][bucket.id] = 0;
            });
        });
        // fill totals map, statusTotals and bucketTotals with values
        var conversionTable = this.model.get('currencies_conversion');

        _.each(this.model.get('forecast_opportunities'), function(forecastOpportunity){
            var statusId = forecastOpportunity.status,
                fcOpBuckets = forecastOpportunity.forecast_opportunity_buckets,
                weight = 1;

            if (weighted) {
                weight = forecastOpportunity.weight;
            }

            _.each(fcOpBuckets, function(fcOpBucket) {
                var bucketId = fcOpBucket.bucket_id;
                var value = Currency.convertToDefaultCurrency(
                    fcOpBucket.value,
                    forecastOpportunity.opportunity.currency,
                    conversionTable).value;

                map[statusId][bucketId] += value * weight;
                statusTotals[statusId] += value * weight;
                bucketTotals[bucketId] += value * weight;
                total += value * weight;
            });
        });
        // build totals matrix to preserve correct order
        _.each(statuses, function(status) {
            var row = [];
            _.each(buckets, function(bucket) {
                row.push(map[status.id][bucket.id]);
            });
            matrix.push({ name: status.name, row: row, phaseTotal: statusTotals[status.id] });
        });
        // extend buckets with total values
        _.each(buckets, function(bucket) {
            bucket.total = bucketTotals[bucket.id];
        });

        return {
            matrix: matrix,
            buckets: buckets,
            total: total
        };
    }
});

export default ForecastTotalsView;

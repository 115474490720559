import _  from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'

import backboneSelect2 from 'js/widgets/backbone-select2.js'
import BaseView from 'js/views/base/base.js'
import UserModel from 'js/models/user.js'
import api from 'js/api.js'
import assignTemplate from 'templates/social/assign.handlebars'


var AssignView = BaseView.extend({
    className: 'assign-container popover',
    template: Handlebars.compile(assignTemplate),
    templateHelpers: function() {
        return {
            multiple_assign: (this.multipleAssign)
        };
    },
    ui: {
        assignInput: '.assign-input'
    },
    events: {
        'click .remove-selected-assignees': 'removeSelectedAssignees'
    },
    initialize: function(options) {
        if (options && options.assignee) {
            this.assignee = options.assignee;
        }
        if (options && options.multiple_assign) {
            this.multipleAssign = options.multiple_assign;
        }
    },
    onRender: function() {
        var value;
        if (this.assignee && this.assignee.id) {
            value = {
                name: this.assignee.name,
                id: this.assignee.id
            };
        }

        this.select = new backboneSelect2.SelectView({
            view: this,
            $el: this.ui.assignInput,
            url: '/users',
            params: {
                rows: -1
            },
            text: 'name',
            value: value,

            options: {
                allowClear: !this.multipleAssign,
                placeholder: 'Search for a user',
                containerCssClass: 'select2-block',
                dropdownCssClass: 'deal-select-popover popover'
            }
        });
        this.listenTo(this.select, 'change', this.assignUser);
    },
    toggleShow: function() {
        this.$el.toggle();
        this.focusInput();
    },
    assignUser: function(item) {
        if (item) {
            var view = this;
            var user = new UserModel({id: item.id});
            user.fetch({
                success: function (user) {
                    view.trigger('assign-user', user);
                }
            });
        } else {
            this.trigger('unassign-user');
        }
    },
    removeSelectedAssignees: function() {
        this.trigger('unassign-selected');
    },
    onRemoveSuccess: function() {
        this.ui.assignInput.select2('data', null);
    },
    focusInput: function() {
        this.ui.assignInput.select2('open');
    }
});

export default AssignView;

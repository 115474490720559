import operators from 'app_v2/filter_operators';
import AppConfig from 'app/app-config';

export default function() {
    const defaultFilters = [{
        id: 'conversation_last_message_created',
        title: 'Date Received',
        operators: operators.dateOperators
    }, {
        id: 'conversation_creator_name',
        title: 'Sent From',
        operators: operators.textOperators
    }, {
        id: 'conversation_related_tags',
        title: AppConfig.getValue('group_pages.messages.filters_names.related_tags', 'Related Tag'),
        operators: operators.tagOperators
    }, {
        id: 'conversation_last_message_text',
        title: 'Message',
        operators: operators.textOperators
    }]

    let clientFilters = AppConfig.getValue('group_pages.messages.filters', []);

    for (const f of clientFilters) {
        f.operators = operators[f.operators];
    }

    return {
        Message: [...defaultFilters, ...clientFilters]
    }
};

import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette';
import Handlebars from 'handlebars';
import React from 'react';
import ReactDOM from 'react-dom';

import IODTableReactView from 'js/react_views/iod-table/iod-table';
import ContactPreview from 'js/react_views/contact-preview/contact-preview';
import CustomFieldsCollection from 'js/collections/custom_fields';
import security from 'js/utils/security'
import MessageBox from 'js/views/message_box'
import BulkEdit from 'js/views/bulk_edit'
import MergeView from 'js/views/merge'
import appContent from 'js/views/appcontent'
import vent from 'js/vent'
import AddItemView from 'js/views/base/add-item'
import dateFormat from 'js/utils/date-format';
import CommunicationModel from 'js/models/communication';
import TaskModel from 'js/models/task'
import ActivityModel from 'js/models/activity'
import OrganizationModel from 'js/models/organization'
import CampaignModel from 'js/models/campaign'
import IndividualFilterModel from 'js/models/individual_filter'
import app from 'js/app';
import TextManager from 'app/text-manager';
import AppConfig from 'app/app-config';
import Utilities from 'js/utils/utilities';
import htmlSanitizer from 'js/utils/html-sanitizer';
import Currency from 'js/utils/currency.js'
import TimeZoneSelectView from 'app/widgets/time-zone-selector/time-zone-selector'
import CampaignMenuPopover from 'app/_components/IOD-listing/IOD/campaign-listing/campaign-menu-popover'
import individualInactiveModal from 'js/views/custom_code/individual_inactive_reasons_modal'

var campaignText = TextManager.parseText('${ID_CAMPAIGN, capitalize}');
const funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

var dateContextMenu = {
    items: [{
        id: 'sort_modifier_anniversary',
        name: 'Sort on anniversary',
        type: 'bool'
    }]
};

var dropdownContextMenu = {
    items: [{
        id: 'show_insights',
        name: 'Field insights',
        type: 'button'
    }]
};

var PDCRMCOLUMNS = {
    individuals: {
        defaultColumns: [
            'photo_url',
            'first_name',
            'last_name',
            'organization.name',
            'email',
            'phone'
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'photo_url',
                name: 'Photo',
                sort: ['photo_url']
            },
            {
                id: 'first_name',
                name: 'First Name',
                sort: ['first_name']
            },
            {
                id: 'last_name',
                name: 'Last Name',
                sort: ['last_name']
            },
            {
                id: 'sortable_name',
                name: 'Sortable Name',
                sort: ['sortable_name']
            },
            {
                id: 'full_name',
                name: 'Full Name',
                sort: ['full_name']
            },
            {
                id: 'created',
                name: 'System Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'user_created',
                name: 'Created',
                sort: ['user_created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'unsubscribed_all',
                name: TextManager.getText('ID_EMAIL_OPTED_IN'),
                sort: ['unsubscribed_all']
            },
            {
                id: 'unsubscribed_all_messages',
                name: TextManager.getText('ID_TEXTING_OPTED_IN'),
                sort: ['unsubscribed_all_messages']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'email',
                name: 'Email',
                sort: ['email']
            },
            {
                id: 'phone',
                name: 'Phone',
                sort: false
            },
            {
                id: 'linkedin',
                name: 'Linkedin',
                sort: false
            },
            {
                id: 'twitter',
                name: 'Twitter',
                sort: false
            },
            {
                id: 'facebook',
                name: 'Facebook',
                sort: false
            },
            {
                id: 'googleplus',
                name: 'Google+',
                sort: false
            },
            {
                id: 'instagram',
                name: 'Instagram',
                sort: false
            },
            {
                id: 'role',
                name: TextManager.getText('ID_JOB_ROLE'),
                sort: false
            },
            {
                id: 'locations',
                name: 'Locations',
                sort: false
            },
            {
                id: 'source.name',
                name: 'Source',
                sort: ['source.name'],
                contextMenu: dropdownContextMenu
            },
            {
                id: 'became_lead_date',
                name: 'Became Lead Date',
                sort: ['became_lead_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'last_viewed',
                name: 'Last Viewed',
                sort: ['last_viewed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },
            {
                id: 'next_direct_task.text',
                name: 'Next Task Description',
                sort: ['next_direct_task.text']
            },
            {
                id: 'next_direct_task.due_date',
                name: 'Next Task Date',
                sort: ['next_direct_task.due_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'next_direct_task.subject',
                name: 'Next Task Subject',
                sort: ['next_direct_task.subject']
            },
            
            // ORGANIZATION COLUMNS
            {
                id: 'organization.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                sort: ['organization.name']
            },
            {
                id: 'organization.website',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Website'),
                sort: ['organization.website']
            },
            {
                id: 'organization.comments',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} ${ID_MORE_INFO}'),
                sort: ['organization.comments']
            },
            {
                id: 'organization.locations',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Locations'),
                sort: false
            },
            {
                id: 'organization.email',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Email'),
                sort: ['organization.email']
            },
            {
                id: 'organization.phone',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Phone'),
                sort: false
            },
            {
                id: 'organization.linkedin',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Linkedin'),
                sort: false
            },
            {
                id: 'organization.twitter',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Twitter'),
                sort: false
            },
            {
                id: 'organization.facebook',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Facebook'),
                sort: false
            },
            {
                id: 'organization.googleplus',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Google+'),
                sort: false
            },
            {
                id: 'organization.instagram',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Instagram'),
                sort: false
            },
            {
                id: 'organization.owner.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Owner'),
                sort: false
            },
            {
                id: 'organization.last_direct_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity')
            },
            {
                id: 'organization.last_direct_activity.modified',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity Date'),
                sort: ['organization.last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'organization.pinned_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Starred Activity')
            },
            {
                id: 'organization.next_direct_task.text',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Next Task'),
                sort: ['organization.next_direct_task.text']
            },
            {
                id: 'organization.next_direct_task.due_date',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Next Task Date'),
                sort: ['organization.next_direct_task.due_date'],
                contextMenu: dateContextMenu
            }
        ]
    },
    opportunities: {
        defaultColumns: [
            'name',
            'funnel.name',
            'phase.name',
            'expected_close_date',
            'owner.name',
            'status',
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'name',
                name: 'Name',
                sort: ['name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'abbreviation',
                name: TextManager.getText('ID_DEAL_ID'),
                sort: ['abbreviation']
            },
            {
                id: 'expected_close_date',
                name: TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize} Date'),
                sort: ['expected_close_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'currency',
                name: 'Currency',
                sort: ['currency']
            },
            {
                id: 'status',
                name: TextManager.parseText('${ID_STATUS, capitalize}'),
                sort: ['status']
            },
            {
                id: 'latest_activity_in_days',
                name: 'Last activity (days)',
                sort: ['latest_activity_in_days']
            },
            {
                id: 'funnel.name',
                name: funnelText,
                sort: ['funnel.name']
            },
            {
                id: 'funnel.region_name',
                name: `${funnelText} Region`,
                sort: ['funnel.region_name']
            },
            {
                id: 'phase.name',
                name: TextManager.parseText('${ID_PHASE, capitalize}'),
                sort: ['phase.name']
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'phase.default_weight',
                name: 'Phase Default Weight',
                sort: ['phase.default_weight']
            },
            {
                id: 'phase_last_changed',
                name: 'Phase Last Changed',
                sort: ['phase_last_changed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },
        ]
    },
    activities: {
        defaultColumns: [
            'created',
            'type',
            'owner.name',
            'related',
            'note',
            'tags'
        ],
        allColumns: [
            {
                id: 'type',
                name: 'Type',
                sort: ['type']
            },
            {
                id: 'note',
                name: 'Note',
                sort: ['note']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'target_date',
                name: 'Due date',
                sort: ['target_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id: 'related',
                name: 'Related',
                sort: ['related.name']
            },
            {
                id: 'related_type',
                name: 'Related Type',
                sort: ['related_type']
            },
            {
                id: 'related.short_id',
                name: 'Related Id',
                sort: ['related.short_id']
            }
        ]
    },
    texts: {
        defaultColumns: [
            'dealt',
            'created',
            'time',
            'related',
            'note',
            'reply'
        ],
        allColumns: [
            {
                id: 'dealt',
                name: '?',
                sort: ['dealt']
            },
            {
                id: 'note',
                name: 'Text',
                sort: ['note']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'created',
                name: 'Date',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'time',
                name: 'Time'
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id: 'related',
                name: 'From',
                sort: ['related.name']
            }
        ]
    },
    messages: {
        defaultColumns: [
            'created',
            'from_name',
            'note'
        ],
        allColumns: [
            {
                id: 'created',
                name: 'Sent',
                sort: ['created'],
                formatter: dateFormat.scheduledShortFormat
            },
            {
                id: 'from_name',
                name: 'From'
            },
            {
                id: 'note',
                name: 'Message Preview',
                sort: ['note']
            }
        ]
    },
    tasks: {
        defaultColumns: AppConfig.getValue('group_pages.tasks.default_columns', [
            'completed',
            'text',
            'tags',
            'assignee.name',
            'related',
            'due_date'
        ]),
        allColumns: [
            {
                id: 'completed',
                name: 'Completed',
                sort: ['completed']
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject']
            },
            {
                id: 'text',
                name: 'Text',
                sort: ['text']
            },
            {
                id: 'assignee.name',
                name: 'Assignee name',
                sort: ['assignee.name']
            },
            {
                id: 'related',
                name: 'Related',
                sort: ['related.name']
            },
            {
                id: 'due_date',
                name: 'Due date',
                sort: ['due_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id:'complete_date',
                name: 'Complete Date',
                sort: ['complete_date']
            },
            {
                id: 'assignee.name',
                name: 'Assignee',
                sort: ['assignee.name']
            },
            {
                id: 'related_type',
                name: 'Related Type',
                sort: ['related_type']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'related.short_id',
                name: 'Related Id',
                sort: ['related.short_id']
            },
            {
                id: 'task_type',
                name: 'Type',
                sort: ['task_type']
            }
        ]
    },
    organizations: {
        defaultColumns: [
            'name',
            'email',
            'phone'
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'name',
                name: 'Name',
                sort: ['name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'website',
                name: 'Website',
                sort: ['website']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'email',
                name: 'Email',
                sort: ['email']
            },
            {
                id: 'phone',
                name: 'Phone',
                sort: false
            },
            {
                id: 'linkedin',
                name: 'Linkedin',
                sort: false
            },
            {
                id: 'twitter',
                name: 'Twitter',
                sort: false
            },
            {
                id: 'facebook',
                name: 'Facebook',
                sort: false
            },
            {
                id: 'googleplus',
                name: 'Google+',
                sort: false
            },
            {
                id: 'instagram',
                name: 'Instagram',
                sort: false
            },
            {
                id: 'locations',
                name: 'Locations',
                sort: false
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'last_viewed',
                name: 'Last Viewed',
                sort: ['last_viewed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },
        ]
    },
    campaigns: {
        defaultColumns: [
            'name',
            'to_group',
            'from_user',
            'created',
            'modified',
            'subject',
            'tags',
            'funnels',
            'regions'
        ],
        allColumns: [
            {
                id: 'name',
                name: campaignText,
                sort: ['name']
            },
            {
                id: 'to_group',
                name: 'Send To',
                sort: ['to_group.name']
            },
            {
                id: 'to_group_sent',
                name: 'Sent To',
                sort: ['to_group.name']
            },
            {
                id: 'from_user',
                name: 'Send From',
                sort: ['from.name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Updated',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'sent',
                name: 'Sent Date',
                sort: ['sent'],
                contextMenu: dateContextMenu
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject']
            },
            {
                id: 'campaign_schedule',
                name: 'Scheduled For',
                sort: ['campaign_schedule.when_utc']
            },
            {
                id: 'email_event_stats_send',
                name: 'Sent',
                sort: ['email_event_stats.send']
            },
            {
                id: 'email_event_stats_delivered',
                name: 'Delivered',
                sort: ['email_event_stats.delivered']
            },
            {
                id: 'email_event_stats_open',
                name: 'Opened',
                sort: ['email_event_stats.open']
            },
            {
                id: 'email_event_stats_click',
                name: 'Clicked',
                sort: ['email_event_stats.click']
            },
            {
                id: 'email_event_stats_unsubscribed',
                name: 'Unsub',
                sort: ['email_event_stats.unsubscribed']
            },
            {
                id: 'email_event_stats_spam',
                name: 'Spam',
                sort: ['email_event_stats.spam']
            },
            {
                id: 'email_event_stats_bounce',
                name: 'Bounce',
                sort: ['email_event_stats.bounce']
            },
            {
                id: 'email_event_stats_open_rate',
                name: 'Open Rate',
                sort: ['email_event_stats.open_rate']
            },
            {
                id: 'email_event_stats_click_rate',
                name: 'Click Rate',
                sort: ['email_event_stats.click_rate']
            },
            {
                id: 'email_event_stats_delivered_rate',
                name: 'Delivered Rate',
                sort: ['email_event_stats.delivered_rate']
            },
            {
                id: 'email_event_stats_hard_bounce',
                name: 'Hard Bounce',
                sort: ['email_event_stats.hard_bounce']
            },
            {
                id: 'email_event_stats_soft_bounce',
                name: 'Soft Bounce',
                sort: ['email_event_stats.soft_bounce']
            },
            {
                id: 'email_event_stats_unsubscribed_all',
                name: 'All Emails Unsubscribed',
                sort: ['email_event_stats.unsubscribed_all']
            },
            {
                id: 'email_event_stats_unsubscribed_list',
                name: 'List Only Unsubscribed',
                sort: ['email_event_stats.unsubscribed_list']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
        ]
    },
    sent_campaigns: {
        allColumns: [
            {
                id: 'name',
                name: campaignText,
                sort: ['name'],
                minWidth: 200
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject']
            },
            {
                id: 'to_group_sent',
                name: 'Sent To',
                sort: ['to_group.name'],
                maxWidth: 200
            },
            {
                id: 'from_user_sent',
                name: 'Sent From',
                sort: ['from.name']
            },
            {
                id: 'sent',
                name: 'Sent Date',
                sort: ['sent'],
                maxWidth: 140,
                contextMenu: dateContextMenu
            },
            {
                id: 'last_sent',
                name: 'Last Sent',
                sort: ['last_sent']
            },
            {
                id: 'modified',
                name: 'Last Updated',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'automation',
                name: 'Automation',
                sort: ['automation.name']
            },
            {
                id: 'campaign_automation_status',
                name: 'Status',
                sort: ['campaign_automation_status']
            },
            {
                id: 'email_event_stats_send',
                name: 'Sent',
                sort: ['email_event_stats.send'],
                maxWidth: 72,
                style: { color: "#c1c4cd" },
                smartGroupName: 'campaign_stats_send'
            },
            {
                id: 'email_event_stats_delivered',
                name: 'Delivered',
                sort: ['email_event_stats.delivered'],
                maxWidth: 110,
                style: { color: "lightgray" },
                smartGroupName: 'campaign_stats_delivered'
            },
            {
                id: 'email_event_stats_open',
                name: 'Opened',
                sort: ['email_event_stats.open'],
                maxWidth: 91,
                style: { color: "lightgreen" },
                smartGroupName: 'campaign_stats_open'
            },
            {
                id: 'email_event_stats_click',
                name: 'Clicked',
                sort: ['email_event_stats.click'],
                maxWidth: 94,
                style: { color: "#72E28A" },
                smartGroupName: 'campaign_stats_click'
            },
            {
                id: 'email_event_stats_unsubscribed',
                name: 'Unsub',
                sort: ['email_event_stats.unsubscribed'],
                maxWidth: 83,
                style: { color: "brown" },
                smartGroupName: 'campaign_stats_unsubscribed'
            },
            {
                id: 'email_event_stats_spam',
                name: 'Spam',
                sort: ['email_event_stats.spam'],
                maxWidth: 73,
                style: { color: "#F28F5A" },
                smartGroupName: 'campaign_stats_spam'
            },
            {
                id: 'email_event_stats_bounce',
                name: 'Bounce',
                sort: ['email_event_stats.bounce'],
                maxWidth: 93,
                style: { color: "#F0D34D" },
                smartGroupName: 'campaign_stats_bounce'
            },
            {
                id: 'email_event_stats_open_rate',
                name: 'Open Rate',
                sort: ['email_event_stats.open_rate']
            },
            {
                id: 'email_event_stats_click_rate',
                name: 'Click Rate',
                sort: ['email_event_stats.click_rate']
            },
            {
                id: 'email_event_stats_delivered_rate',
                name: 'Delivery Rate',
                sort: ['email_event_stats.delivered_rate']
            },
            {
                id: 'email_event_stats_hard_bounce',
                name: 'Hard Bounce',
                sort: ['email_event_stats.hard_bounce']
            },
            {
                id: 'email_event_stats_soft_bounce',
                name: 'Soft Bounce',
                sort: ['email_event_stats.soft_bounce']
            },
            {
                id: 'email_event_stats_unsubscribed_all',
                name: 'All Emails Unsubscribed',
                sort: ['email_event_stats.unsubscribed_all']
            },
            {
                id: 'email_event_stats_unsubscribed_list',
                name: 'List Only Unsubscribed',
                sort: ['email_event_stats.unsubscribed_list']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
        ]
    }
};

var COLUMNS = {
    individuals: {
        defaultColumns: [
            'photo_url',
            'first_name',
            'last_name',
            'organization.name',
            'email',
            'phone',
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'photo_url',
                name: 'Photo',
                sort: ['photo_url']
            },
            {
                id: 'first_name',
                name: 'First Name',
                sort: ['first_name']
            },
            {
                id: 'last_name',
                name: 'Last Name',
                sort: ['last_name']
            },
            {
                id: 'sortable_name',
                name: 'Sortable Name',
                sort: ['sortable_name']
            },
            {
                id: 'full_name',
                name: 'Full Name',
                sort: ['full_name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'unsubscribed_all',
                name: TextManager.getText('ID_EMAIL_OPTED_IN'),
                sort: ['unsubscribed_all']
            },
            /* TODO: Enable again when texting is available for all clients: enableTextMessaging
            {
                id: 'unsubscribed_all_messages',
                name: TextManager.getText('ID_TEXTING_OPTED_IN'),
                sort: ['unsubscribed_all_messages']
            },*/
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'email',
                name: 'Email',
                sort: ['email']
            },
            {
                id: 'phone',
                name: 'Phone',
                sort: false
            },
            {
                id: 'linkedin',
                name: 'Linkedin',
                sort: false
            },
            {
                id: 'twitter',
                name: 'Twitter',
                sort: false
            },
            {
                id: 'facebook',
                name: 'Facebook',
                sort: false
            },
            {
                id: 'googleplus',
                name: 'Google+',
                sort: false
            },
            {
                id: 'instagram',
                name: 'Instagram',
                sort: false
            },
            {
                id: 'role',
                name: TextManager.getText('ID_JOB_ROLE'),
                sort: false
            },
            {
                id: 'locations',
                name: 'Locations',
                sort: false
            },
            {
                id: 'source.name',
                name: 'Source',
                sort: ['source.name'],
                contextMenu: dropdownContextMenu
            },
            {
                id: 'became_lead_date',
                name: 'Became Lead Date',
                sort: ['became_lead_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'last_viewed',
                name: 'Last Viewed',
                sort: ['last_viewed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },
            {
                id: 'next_direct_task.text',
                name: 'Next Task Description',
                sort: ['next_direct_task.text']
            },
            {
                id: 'next_direct_task.due_date',
                name: 'Next Task Date',
                sort: ['next_direct_task.due_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'next_direct_task.subject',
                name: 'Next Task Subject',
                sort: ['next_direct_task.subject']
            },
            
            // ORGANIZATION COLUMNS
            {
                id: 'organization.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                sort: ['organization.name']
            },
            {
                id: 'organization.website',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Website'),
                sort: ['organization.website']
            },
            {
                id: 'organization.comments',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} ${ID_MORE_INFO}'),
                sort: ['organization.comments']
            },
            {
                id: 'organization.locations',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Locations'),
                sort: false
            },
            {
                id: 'organization.email',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Email'),
                sort: ['organization.email']
            },
            {
                id: 'organization.phone',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Phone'),
                sort: false
            },
            {
                id: 'organization.linkedin',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Linkedin'),
                sort: false
            },
            {
                id: 'organization.twitter',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Twitter'),
                sort: false
            },
            {
                id: 'organization.facebook',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Facebook'),
                sort: false
            },
            {
                id: 'organization.googleplus',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Google+'),
                sort: false
            },
            {
                id: 'organization.instagram',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Instagram'),
                sort: false
            },
            {
                id: 'organization.owner.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Owner'),
                sort: false
            },
            {
                id: 'organization.last_direct_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity')
            },
            {
                id: 'organization.last_direct_activity.modified',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity Date'),
                sort: ['organization.last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'organization.pinned_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Starred Activity')
            },
            {
                id: 'organization.next_direct_task.text',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Next Task'),
                sort: ['organization.next_direct_task.text']
            },
            {
                id: 'organization.next_direct_task.due_date',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Next Task Date'),
                sort: ['organization.next_direct_task.due_date'],
                contextMenu: dateContextMenu
            }
        ]
    },
    opportunities: {
        defaultColumns: [
            'name',
            'organization.name',
            'funnel.name',
            'phase.name',
            'expected_close_date',
            'owner.name',
            'default_value',
            'status',
            'weight'
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'name',
                name: 'Name',
                sort: ['name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'abbreviation',
                name: TextManager.getText('ID_DEAL_ID'),
                sort: ['abbreviation']
            },
            {
                id: 'expected_close_date',
                name: TextManager.parseText('${ID_DEAL_CLOSE_DATE, capitalize} Date'),
                sort: ['expected_close_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'currency',
                name: 'Currency',
                sort: ['currency']
            },
            {
                id: 'default_value',
                name: function() {
                    return app.user.get('client')['default_currency'] + ' Value';
                },
                sort: ['default_value']
            },
            {
                id: 'weighted_value',
                name: function() {
                    return 'Weighted ' + app.user.get('client')['default_currency'] + ' Value';
                },
                sort: ['weighted_value']
            },
            {
                id: 'status',
                name: TextManager.parseText('${ID_STATUS, capitalize}'),
                sort: ['status']
            },
            {
                id: 'weight',
                name: 'Weight',
                sort: ['weight']
            },
            {
                id: 'buckets_total',
                name: TextManager.getText('ID_DEAL_VALUE'),
                sort: ['buckets_total']
            },
            {
                id: 'latest_activity_in_days',
                name: 'Last activity (days)',
                sort: ['latest_activity_in_days']
            },
            {
                id: 'funnel.name',
                name: funnelText,
                sort: ['funnel.name']
            },
            {
                id: 'funnel.region_name',
                name: `${funnelText} Region`,
                sort: ['funnel.region_name']
            },
            {
                id: 'phase.name',
                name: TextManager.parseText('${ID_PHASE, capitalize}'),
                sort: ['phase.name']
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'phase.default_weight',
                name: 'Phase Default Weight',
                sort: ['phase.default_weight']
            },
            {
                id: 'phase_last_changed',
                name: 'Phase Last Changed',
                sort: ['phase_last_changed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },

            // ORGANIZATION COLUMNS
            {
                id: 'organization.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                sort: ['organization.name']
            },
            {
                id: 'organization.website',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Website'),
                sort: ['organization.website']
            },
            {
                id: 'organization.comments',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} ${ID_MORE_INFO}'),
                sort: ['organization.comments']
            },
            {
                id: 'organization.locations',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Locations'),
                sort: false
            },
            {
                id: 'organization.email',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Email'),
                sort: ['organization.email']
            },
            {
                id: 'organization.phone',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Phone'),
                sort: false
            },
            {
                id: 'organization.linkedin',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Linkedin'),
                sort: false
            },
            {
                id: 'organization.twitter',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Twitter'),
                sort: false
            },
            {
                id: 'organization.facebook',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Facebook'),
                sort: false
            },
            {
                id: 'organization.googleplus',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Google+'),
                sort: false
            },
            {
                id: 'organization.instagram',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Instagram'),
                sort: false
            },
            {
                id: 'organization.owner.name',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Owner'),
                sort: false
            },
            {
                id: 'organization.last_direct_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity')
            },
            {
                id: 'organization.last_direct_activity.modified',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Last Activity Date'),
                sort: ['organization.last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'organization.pinned_activity',
                name: TextManager.parseText('${ID_ORGANIZATION, capitalize} Starred Activity')
            },
        ]
    },
    activities: {
        defaultColumns: [
            'created',
            'type',
            'owner.name',
            'related',
            'note',
            'tags'
        ],
        allColumns: [
            {
                id: 'type',
                name: 'Type',
                sort: ['type']
            },
            {
                id: 'note',
                name: 'Note',
                sort: ['note']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'target_date',
                name: 'Due date',
                sort: ['target_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id: 'related',
                name: 'Related',
                sort: ['related.name']
            },
            {
                id: 'related_type',
                name: 'Related Type',
                sort: ['related_type']
            },
            {
                id: 'related.short_id',
                name: 'Related Id',
                sort: ['related.short_id']
            }
        ]
    },
    texts: {
        defaultColumns: [
            'dealt',
            'created',
            'time',
            'related',
            'note',
            'reply'
        ],
        allColumns: [
            {
                id: 'dealt',
                name: '?',
                sort: ['dealt']
            },
            {
                id: 'note',
                name: 'Text',
                sort: ['note']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'created',
                name: 'Date',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'time',
                name: 'Time'
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id: 'related',
                name: 'From',
                sort: ['related.name']
            }
        ]
    },
    tasks: {
        defaultColumns: [
            'completed',
            'text',
            'tags',
            'assignee.name',
            'related',
            'due_date'
        ],
        allColumns: [
            {
                id: 'completed',
                name: 'Completed',
                sort: ['completed']
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject']
            },
            {
                id: 'text',
                name: 'Text',
                sort: ['text']
            },
            {
                id: 'assignee.name',
                name: 'Assignee name',
                sort: ['assignee.name']
            },
            {
                id: 'related',
                name: 'Related',
                sort: ['related.name']
            },
            {
                id: 'due_date',
                name: 'Due date',
                sort: ['due_date'],
                contextMenu: dateContextMenu
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'owner.name',
                name: 'Creator',
                sort: ['owner.name']
            },
            {
                id:'complete_date',
                name: 'Complete Date',
                sort: ['complete_date']
            },
            {
                id: 'assignee.name',
                name: 'Assignee',
                sort: ['assignee.name']
            },
            {
                id: 'related_type',
                name: 'Related Type',
                sort: ['related_type']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'related.short_id',
                name: 'Related Id',
                sort: ['related.short_id']
            }
        ]
    },
    organizations: {
        defaultColumns: [
            'name',
            'email',
            'phone'
        ],
        allColumns: [
            {
                id: 'index',
                name: '#'
            },
            {
                id: 'name',
                name: 'Name',
                sort: ['name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Modified',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'comments',
                name: TextManager.getText('ID_MORE_INFO'),
                sort: ['comments']
            },
            {
                id: 'website',
                name: 'Website',
                sort: ['website']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
            {
                id: 'email',
                name: 'Email',
                sort: ['email']
            },
            {
                id: 'phone',
                name: 'Phone',
                sort: false
            },
            {
                id: 'linkedin',
                name: 'Linkedin',
                sort: false
            },
            {
                id: 'twitter',
                name: 'Twitter',
                sort: false
            },
            {
                id: 'facebook',
                name: 'Facebook',
                sort: false
            },
            {
                id: 'googleplus',
                name: 'Google+',
                sort: false
            },
            {
                id: 'instagram',
                name: 'Instagram',
                sort: false
            },
            {
                id: 'locations',
                name: 'Locations',
                sort: false
            },
            {
                id: 'owner.name',
                name: 'Owner',
                sort: ['owner.name']
            },
            {
                id: 'last_viewed',
                name: 'Last Viewed',
                sort: ['last_viewed']
            },
            {
                id: 'last_direct_activity',
                name: 'Last Activity'
            },
            {
                id: 'last_direct_activity.modified',
                name: 'Last Activity Date',
                sort: ['last_direct_activity.modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'pinned_activity',
                name: 'Starred Activity'
            },
        ]
    },
    campaigns: {
        defaultColumns: [
            'name',
            'to_group',
            'from_user',
            'created',
            'modified',
            'subject',
            'tags',
            'funnels',
            'regions'
        ],
        allColumns: [
            {
                id: 'name',
                name: campaignText,
                sort: ['name']
            },
            {
                id: 'to_group',
                name: 'Send To',
                sort: ['to_group.name']
            },
            {
                id: 'to_group_sent',
                name: 'Sent To',
                sort: ['to_group.name']
            },
            {
                id: 'from_user',
                name: 'Send From',
                sort: ['from.name']
            },
            {
                id: 'created',
                name: 'Created',
                sort: ['created'],
                contextMenu: dateContextMenu
            },
            {
                id: 'modified',
                name: 'Updated',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'sent',
                name: 'Sent Date',
                sort: ['sent'],
                contextMenu: dateContextMenu
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject']
            },
            {
                id: 'campaign_schedule',
                name: 'Scheduled For',
                sort: ['campaign_schedule.when_utc']
            },
            {
                id: 'email_event_stats_send',
                name: 'Sent',
                sort: ['email_event_stats.send']
            },
            {
                id: 'email_event_stats_delivered',
                name: 'Delivered',
                sort: ['email_event_stats.delivered']
            },
            {
                id: 'email_event_stats_open',
                name: 'Opened',
                sort: ['email_event_stats.open']
            },
            {
                id: 'email_event_stats_click',
                name: 'Clicked',
                sort: ['email_event_stats.click']
            },
            {
                id: 'email_event_stats_unsubscribed',
                name: 'Unsub',
                sort: ['email_event_stats.unsubscribed']
            },
            {
                id: 'email_event_stats_spam',
                name: 'Spam',
                sort: ['email_event_stats.spam']
            },
            {
                id: 'email_event_stats_bounce',
                name: 'Bounce',
                sort: ['email_event_stats.bounce']
            },
            {
                id: 'email_event_stats_open_rate',
                name: 'Open Rate',
                sort: ['email_event_stats.open_rate']
            },
            {
                id: 'email_event_stats_click_rate',
                name: 'Click Rate',
                sort: ['email_event_stats.click_rate']
            },
            {
                id: 'email_event_stats_delivered_rate',
                name: 'Delivered Rate',
                sort: ['email_event_stats.delivered_rate']
            },
            {
                id: 'email_event_stats_hard_bounce',
                name: 'Hard Bounce',
                sort: ['email_event_stats.hard_bounce']
            },
            {
                id: 'email_event_stats_soft_bounce',
                name: 'Soft Bounce',
                sort: ['email_event_stats.soft_bounce']
            },
            {
                id: 'email_event_stats_unsubscribed_all',
                name: 'All Emails Unsubscribed',
                sort: ['email_event_stats.unsubscribed_all']
            },
            {
                id: 'email_event_stats_unsubscribed_list',
                name: 'List Only Unsubscribed',
                sort: ['email_event_stats.unsubscribed_list']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
        ]
    },
    sent_campaigns: {
        allColumns: [
            {
                id: 'name',
                name: campaignText,
                sort: ['name'],
                minWidth: 200
            },
            {
                id: 'subject',
                name: 'Subject',
                sort: ['subject'],
                minWidth: 100
            },
            {
                id: 'to_group_sent',
                name: 'Sent To',
                sort: ['to_group.name'],
                maxWidth: 200
            },
            {
                id: 'from_user_sent',
                name: 'Sent From',
                sort: ['from.name']
            },
            {
                id: 'sent',
                name: 'Sent Date',
                sort: ['sent'],
                maxWidth: 140,
                contextMenu: dateContextMenu
            },
            {
                id: 'last_sent',
                name: 'Last Sent',
                sort: ['last_sent']
            },
            {
                id: 'modified',
                name: 'Last Updated',
                sort: ['modified'],
                contextMenu: dateContextMenu
            },
            {
                id: 'automation',
                name: 'Automation',
                sort: ['automation.name']
            },
            {
                id: 'campaign_automation_status',
                name: 'Status',
                sort: ['campaign_automation_status']
            },
            {
                id: 'email_event_stats_send',
                name: 'Sent',
                sort: ['email_event_stats.send'],
                maxWidth: 72,
                style: { color: "#c1c4cd" },
                smartGroupName: 'campaign_stats_send'
            },
            {
                id: 'email_event_stats_delivered',
                name: 'Delivered',
                sort: ['email_event_stats.delivered'],
                maxWidth: 110,
                style: { color: "lightgray" },
                smartGroupName: 'campaign_stats_delivered'
            },
            {
                id: 'email_event_stats_open',
                name: 'Opened',
                sort: ['email_event_stats.open'],
                maxWidth: 91,
                style: { color: "lightgreen" },
                smartGroupName: 'campaign_stats_open'
            },
            {
                id: 'email_event_stats_click',
                name: 'Clicked',
                sort: ['email_event_stats.click'],
                maxWidth: 94,
                style: { color: "#72E28A" },
                smartGroupName: 'campaign_stats_click'
            },
            {
                id: 'email_event_stats_unsubscribed',
                name: 'Unsub',
                sort: ['email_event_stats.unsubscribed'],
                maxWidth: 83,
                style: { color: "brown" },
                smartGroupName: 'campaign_stats_unsubscribed'
            },
            {
                id: 'email_event_stats_spam',
                name: 'Spam',
                sort: ['email_event_stats.spam'],
                maxWidth: 73,
                style: { color: "#F28F5A" },
                smartGroupName: 'campaign_stats_spam'
            },
            {
                id: 'email_event_stats_bounce',
                name: 'Bounce',
                sort: ['email_event_stats.bounce'],
                maxWidth: 93,
                style: { color: "#F0D34D" },
                smartGroupName: 'campaign_stats_bounce'
            },
            {
                id: 'email_event_stats_open_rate',
                name: 'Open Rate',
                sort: ['email_event_stats.open_rate']
            },
            {
                id: 'email_event_stats_click_rate',
                name: 'Click Rate',
                sort: ['email_event_stats.click_rate']
            },
            {
                id: 'email_event_stats_delivered_rate',
                name: 'Delivery Rate',
                sort: ['email_event_stats.delivered_rate']
            },
            {
                id: 'email_event_stats_hard_bounce',
                name: 'Hard Bounce',
                sort: ['email_event_stats.hard_bounce']
            },
            {
                id: 'email_event_stats_soft_bounce',
                name: 'Soft Bounce',
                sort: ['email_event_stats.soft_bounce']
            },
            {
                id: 'email_event_stats_unsubscribed_all',
                name: 'All Emails Unsubscribed',
                sort: ['email_event_stats.unsubscribed_all']
            },
            {
                id: 'email_event_stats_unsubscribed_list',
                name: 'List Only Unsubscribed',
                sort: ['email_event_stats.unsubscribed_list']
            },
            {
                id: 'tags',
                name: 'Tags',
                sort: ['tags']
            },
            {
                id: 'funnels',
                name: TextManager.parseText('${ID_FUNNEL, capitalize, pluralize}'),
                sort: ['funnels']
            },
            {
                id: 'regions',
                name: 'Regions',
                sort: ['regions']
            },
        ]
    }
};

var specialGroups = {
        'individuals': 'all',
        'organizations': 'all',
        'myIndividuals': 'owned',
        'myOrganizations': 'owned',
        'favorites': 'favorites',
        'recentIndividuals': 'recents',
        'recentOrganizations': 'recents',
        'recentCreatedIndividuals': 'created',
        'recentCreatedOrganizations': 'created',
        'active': 'user_deals',
        'won': 'won_deals',
        'lost': 'lost_deals',
        'my': 'owned',
        'team': 'team_owned',
        'elephants': 'favorites'
    };

export function getDefaultColumns(type) {
    if (AppConfig.getValue('is_pdcrm')) {
        return PDCRMCOLUMNS[type].defaultColumns;
    }

    return COLUMNS[type].defaultColumns;
}

var TableBodyReactView = Marionette.ItemView.extend({
    initialize: function() {
        var self = this;

        this.loader = true;

        this.getSortParamsAndOptions();

        this.columnsModifiers = {};
        this.activeSort = {};

        if (this.sortParams) {
            this.activeSort = {
                field: this.sortParams.field,
                direction: this.sortParams.direction ? 'asc' : 'desc'
            };
        }

        if (this.sortParams && this.sortParams.modifier && this.sortParams.field) {
            if (this.sortParams.modifier === 'anniversary') {
                var field = _.isArray(this.sortParams.field) ? this.sortParams.field[0] : this.sortParams.field;
                this.columnsModifiers[field] = { sort_modifier_anniversary: true };
            }
        }

        var extraOptions = this.options.defaultColumns ? {
            columnsIds: this.options.defaultColumns
        } : null;

        const fetchCollection = () => {
            var fetchOptions = null;

            this.getSortParamsAndOptions();

            if (AppConfig.getValue('persistTablePagination', true)) {
                var tableId = this.options.tableModel ? this.options.tableModel.get('id') : this.options.type;
                var stateName = this.options.elementType + '_' + tableId + '_table_state';
                var preferences = app.user.get('preferences')[stateName] || {};

                if (preferences.page) {
                    this.initialPage = preferences.page;
                }
            }

            var numRows = 50;

            switch(this.options.elementType) {
                case 'individuals':
                    if (AppConfig.getValue('showIndividualPreview', false)) {
                        fetchOptions = {contact_preview: true};
                    }
                    numRows = AppConfig.getValue('group_pages.individuals.rows', numRows);
                    break;

                case 'opportunities':
                    if (AppConfig.getValue('funnelPopup') === 'extended') {
                        fetchOptions = {snapshot: true};
                    }
                    break;
            }

            this.options.parent.fetchCollection(fetchOptions, Math.max(this.initialPage, 1), numRows, this.sortOptions, extraOptions);
        };

        fetchCollection();

        // throttling is required due to incorrect implementation in individual view, which results in several change
        // events being raised on individual view opening
        this.throthledRender = _.throttle(self.render, 500);
        this.listenTo(this.options.collection, 'all', function() {
            self.throthledRender();
        });

        this.listenTo(vent, 'contact:save', fetchCollection);

        // contains selected rows
        this.selectionMap = {};
        this.selectAllChecked = false;
        this.allSelected = false;
        this.previewContactId = null;
        this.rowClicked = false;

        // convert to dictionary for better performance
        if (this.options.availableColumns) {
            this.options.availableColumns = _.object(_.map(this.options.availableColumns, function (name) {
                return [name, true]
            }));
        }
    },
    getSortParamsAndOptions: function() {
        var displayOptions = this.options.tableModel && this.options.tableModel.get('display_options');
        this.sortParams = this.options.defaultSort || (displayOptions ? displayOptions.sort : null); // { direction: true, field: "first_name" };
        this.sortOptions = this.sortParams ? [{ attribute: this.sortParams.field, order: (this.sortParams.direction ? 'asc' : 'desc'), modifier: this.sortParams.modifier }] : null;
    },
    handleRowClick: function(id) {
        if (AppConfig.getValue('showIndividualPreview') && this.options.elementType === 'individuals') {
            // manage click / double click
            if (!this.rowClicked) {
                this.rowClicked = true;
                const self = this;

                _.delay(function() {
                    if (self.rowClicked) {
                        self.rowClicked = false;
                        self.previewContactId = id;
                        self.render();
                    }
                }, 300);
            } else {
                this.trigger('select', this.options.collection.get(id));
                this.previewContactId = null;
                this.rowClicked = false;
            }
        } else {
            this.trigger('select', this.options.collection.get(id));
        }
    },
    handleCBClick: function(select, id) {
        this.allSelected = false;

        if (select) {
            this.selectionMap[id] = true;
        }
        else {
            delete this.selectionMap[id];
            this.selectAllChecked = false;
        }

        this.render();
    },
    handleHeaderCBClick: function(select) {
        var self = this;

        this.allSelected = this.options.collection.length === this.options.collection.total;

        this.selectAllChecked = select;

        if (!select) {
            this.selectionMap = {};
        }
        else {
            this.options.collection.each(function(item) {
                self.selectionMap[item.id] = true;
            });
        }

        this.render();
    },
    handleAllSelect: function() {
        this.allSelected = true;

        this.render();
    },
    handleColumnModifierChange: function(columnId, itemId, value) {
        if (!(columnId in this.columnsModifiers)) {
            this.columnsModifiers[columnId] = {};
        }

        this.columnsModifiers[columnId][itemId] = value;

        var activeField = _.isArray(this.activeSort.field) ? this.activeSort.field[0] : this.activeSort.field;

        if (activeField === columnId) {
            this.handleSort(this.activeSort.field, this.activeSort.direction);
        } else {
            this.render();
        }
    },
    handleColumnContextMenuClick: function(columndId, columnName, itemId) {
        switch (itemId) {
            case 'show_insights':
                this.trigger('group:showInsights', columndId, columnName);
                break;
        }
    },
    handleSort: function(sortField, direction) {
        this.render({ loader: true });

        var modifier = null;
        var field = _.isArray(sortField) ? sortField[0] : sortField;

        if ((this.columnsModifiers[field] || {}).sort_modifier_anniversary) {
            modifier = 'anniversary';
        }

        this.activeSort = {
            field: sortField,
            direction: direction
        };

        this.options.parent.fetchCollection(null, null, null, [{ attribute: sortField, order: direction, modifier: modifier }]);
        this.options.parent.trigger('sort:change', sortField, direction);

        var tableModel = this.options.tableModel;
        if (tableModel && !tableModel.doNotPersistSort) {
            var display_options = tableModel.get('display_options') || {};

            display_options.sort = {
                field: sortField,
                direction: direction === 'asc',
                modifier: modifier
            };

            tableModel.save({
                display_options: display_options
            }, {
                patch: true
            });
        }
    },
    handleCampaignStatClick: function(field, campaignId, campaignName, parent) {
        // templated emails don't have statistic (the email was sent to just 1 person)
        if (this.options.tableModel.get('id') === 'templated') {
            return;
        }

        if (_.contains(app.user.get('preferences').lab_flags, 'SAL-5309')) {
            // convert field name to the one supported in the BE
            const lastUnderscore = field.lastIndexOf('_');
            const havingField = `email_event_stats.${field.substring(lastUnderscore + 1)}`;

            const handler = MessageBox.showNoBtn({
                message: 'Creating static group...'
            }, parent, { staticRegion: true });

            $.post(`/campaigns/${campaignId}/individuals`, JSON.stringify({
                name: `${campaignName} ${field} ${dateFormat.formatDDMMYYYYDate(new Date())}`,
                having: havingField
            }), function(groupId) {
                handler.reset();
                window.location.href = `/#contacts/group/individuals/${groupId}`;
            });
        } else {
            var filterModel = new IndividualFilterModel();

            filterModel.save({
                rules: [[{
                    field: field,
                    operator: 'any',
                    values: {
                        campaign: {
                            id: campaignId,
                            name: campaignName
                        }
                    }
                }]]
            },
            {
                alert: false,
                success: function() {
                    var columns = [];

                    for (var k of ['state', 'open', 'click']) {
                        columns.push(`campaigns.${campaignId}.${k}`);
                    }

                    // sp for skip persistence
                    window.location = `#contacts/individuals?filter_id=${filterModel.get('id')}&extra_columns=${columns.join(',')}&sp=`;
                }
            });
        }
    },
    handleCampaignMenuClick: function(ev, id) {
        var self = this;
        var model = this.options.collection.get({id: id});
        var hasEditPermission = security.checkPermission('edit', model);

        var menuItems;
        switch (model.get('status')) {
            case 'draft':
            case 'ready':
                menuItems = [
                    {
                        name: 'edit',
                        text: 'Edit',
                        disabled: function() {
                            return !hasEditPermission;
                        }
                    },
                    {
                        name: 'permissions',
                        text: 'Set Permissions',
                        disabled: function() {
                            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
                        }
                    },
                    {
                        name: 'preview',
                        text: 'Preview'
                    },
                    {
                        name: 'duplicate',
                        text: 'Duplicate'
                    },
                    {
                        name: 'delete',
                        text: 'Delete',
                        navigation: true,
                        disabled: function() {
                            return !hasEditPermission;
                        }
                    }
                ];
                break;
            case 'scheduled':
                menuItems = [
                    {
                        name: 'edit-schedule',
                        text: 'Edit Schedule',
                        disabled: function() {
                            return !hasEditPermission;
                        }
                    },
                    {
                        name: 'permissions',
                        text: 'Set Permissions',
                        disabled: function() {
                            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
                        }
                    },
                    {
                        name: 'cancel-campaign',
                        text: TextManager.parseText('Cancel ${ID_CAMPAIGN, capitalize}'),
                        navigation: true,
                        disabled: function() {
                            return !hasEditPermission;
                        }
                    },
                    {
                        name: 'preview',
                        text: 'Preview'
                    },
                    {
                        name: 'duplicate',
                        text: 'Duplicate'
                    }
                ];
                break;
            case 'sent':
                menuItems = [
                    {
                        name: 'summarySheet',
                        text: 'Summary Sheet'
                    },
                    {
                        name: 'preview',
                        text: 'Preview'
                    },
                    {
                        name: 'duplicate',
                        text: 'Duplicate'
                    },
                    {
                        name: 'permissions',
                        text: 'Set Permissions',
                        disabled: function() {
                            return !hasEditPermission || app.user.get('client').permission_type === 'rba';
                        }
                    }
                ];
                break;
        }

        if (model.get('automation') || model.get('automation2')) {
            menuItems = [
                {
                    name: 'summarySheet',
                    text: 'Summary Sheet'
                },
                {
                    name: 'preview',
                    text: 'Preview'
                },
                {
                    name: 'duplicate',
                    text: 'Duplicate'
                }
            ]
        }

        var popoverContainerView = new CampaignMenuPopover({
            model: model,
            collection: new Backbone.Collection(menuItems)
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:preview', function() {
            popoverContainerView.close();

            self.trigger('campaign:preview', model);
        });
        popoverContainerView.listenTo(popoverContainerView, 'popover:click:edit', function() {
            popoverContainerView.close();

            self.trigger('campaign:edit', model, self.collection);
        });
        popoverContainerView.listenTo(popoverContainerView, 'popover:click:delete-confirm', function() {
            popoverContainerView.close();

            self.trigger('delete', model);
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:edit-schedule', function() {
            popoverContainerView.close();

            self.trigger('select', model);
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:cancel-confirm', function() {
            popoverContainerView.close();

            model.cancel(function() {
                self.collection.remove(model);
            }, { silent: true });
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:duplicate', function() {
            popoverContainerView.close();

            let name = model.get('name')
            let copyExists = name.indexOf('copy')
            if (copyExists !== -1) {
                name = name.substring(0, copyExists - 1)
            }
            name += ` copy ${dateFormat.formatDDMMYYYYhhmmssDate(new Date())}` 

            var newModel = new CampaignModel({
                ...model.attributes,
                id: null,
                name: name,
                owner: null,
                owner_id: null,
            });
            newModel.duplicate(function() {
                if (self.options.parent.onDuplicateItem) {
                    self.options.parent.onDuplicateItem(newModel);
                } else {
                    self.options.parent.fetchCollection(true);
                }
            });
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:permissions', function() {
            popoverContainerView.close();
            self.trigger('campaign:permissions', model);
        });

        popoverContainerView.listenTo(popoverContainerView, 'popover:click:summarySheet', function() {
            popoverContainerView.close();
            self.trigger('campaign:summarySheet', model);
        });

        this.options.parent.tableMenuRegion.show(popoverContainerView);
        popoverContainerView.$el.show();
        popoverContainerView.onShow();

        var explicitContainer = this.$el.find('.iod-table-rows-container');
        var target = $(ev.currentTarget);

        Utilities.positionMenu(popoverContainerView.$el, target);

        _.defer(function() {
            explicitContainer.one('scroll', function () {
                popoverContainerView.close();
            });
        });
    },
    handlePrevClick: function(ev) {
        ev.preventDefault();

        this.selectionMap = {};
        this.previewContactId = null;

        this.render({ loader: true });

        this.options.collection.collectionPage--;
        this.options.parent.fetchCollection(null, this.options.collection.collectionPage);
        this.trigger('page:change', this.options.collection.collectionPage);
    },
    handleNextClick: function(ev) {
        ev.preventDefault();

        this.selectionMap = {};
        this.previewContactId = null;

        this.render({ loader: true });

        this.options.collection.collectionPage = this.options.collection.collectionPage || 1;
        this.options.collection.collectionPage++;
        this.options.parent.fetchCollection(null, this.options.collection.collectionPage);
        this.trigger('page:change', this.options.collection.collectionPage);
    },
    handleBulkDelete: function() {
        var self = this;
        var mbProgressContent;
        var mbContent;
        var deleteData;
        var canDelete = true;

        var collection = this.options.collection;

        if (this.allSelected) {
            mbContent = {
                message: 'Are you sure you want to <strong>permanently</strong> delete all permited items from ' + this.options.collection.total + '?',
                icon: 'icon-trashcan',
                itemsLength: collection.total,
                accept_is_negative: true
            };

            mbProgressContent = {
                icon: 'icon-trashcan',
                message: "Deleting permitted items" +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.getElementType()
            };

            var isSmartGroup = false;

            if (this.options.tableModel) {
                deleteData.group_id = this.options.tableModel.get('id');
                isSmartGroup = this.options.tableModel.get('group_type') === 'smart';
            }
            else {
                deleteData.group_id = specialGroups[this.options.type];
            }

            // on smart groups the group id is enough
            if (this.options.parent.filter && !isSmartGroup) {
                deleteData.filter_id = this.options.parent.filter.get('id');
            }

            if (this.options.parent.section) {
                deleteData.section_id = this.options.parent.section.id;
            }
        }
        else {
            const canBulkDelete = AppConfig.getValue(`${this.options.elementType}.can_bulk_delete`, true, {
                columns: this.options.collection.columns,
                this: this.options.parent,
            });
    
            if ( ! canBulkDelete ) return;

            var checkPermissions = _.contains(['individuals', 'organizations', 'opportunities'], this.options.elementType);
            var models = _.map(this.selectionMap, function (val, id) {
                return self.options.collection.get(id);
            });

            var toDeleteCount  = models.length; // number of items I want to delete
            var permittedCount = 0; // number of items I have permission to delete
            var permittedIds = [];

            _.each(models, function(model) {
                if (!checkPermissions || security.checkPermission('delete', model)) {
                    const canDelete = AppConfig.getValue(`${self.options.elementType}.bulk_delete`, true, model);

                    if (canDelete) {
                        permittedCount++;
                        permittedIds.push(model.get('id'));
                    }
                }
            });

            // 3 cases:
            mbContent = {
                message: '',
                icon: 'icon-trashcan',
                itemsLength: permittedCount
            };

            if (permittedCount === 0) { // 1. can't delete anything
                canDelete = false;
                mbContent.message = "You don't have permission to delete selected item" + (toDeleteCount > 1 ? 's' : '');
                mbContent.icon = 'icon-blocked';
                mbContent.itemsLength = null;
                MessageBox.showOk( mbContent, this.options.parent );
            }
            else if (permittedCount !== toDeleteCount) { // 2. can delete some items
                mbContent.accept_is_negative = true;
                mbContent.message = '<p>You have permission to delete ' + permittedCount +
                    ' of ' + toDeleteCount + ' selected items.</p>' +
                    '<strong class="cta">Do you want to continue?</strong>';
            }
            else { // 3. can delete all items
                mbContent.accept_is_negative = true;
                mbContent.message = 'Are you sure you want to <strong>permanently</strong> delete ' +
                    permittedCount + ' item' + (permittedCount > 1 ? 's' : '') + '?';
            }

            mbProgressContent = {
                icon: 'icon-trashcan',
                itemsLength: permittedCount,
                message: "Deleting " + permittedCount + " item" + (permittedCount > 1 ? "s" : "") +
                    "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                    "<div class='mb-wait-cont'>0% complete</div>"
            };

            deleteData = {
                entity_type: this.getElementType(),
                entity_ids: permittedIds
            };
        }

        if (canDelete) {

            MessageBox.showYesNo(
                mbContent,
                this.options.parent,
                function() { // Yes
                    var handle = MessageBox.showNoBtn(
                        mbProgressContent,
                        self.options.parent,
                        {
                            staticRegion: true
                        }
                    );

                    var progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                    var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                    progressBar.width('0%');
                    completeMessage.text('0% complete');

                    $.ajax({
                        type: 'POST',
                        url: '/bulk_delete?queue',
                        contentType: 'application/json',
                        dataType: 'json',
                        data: JSON.stringify(deleteData),
                        success: function(data) {
                            var waitForCompletion = function() {
                                setTimeout(function() {
                                    if (self.isClosed) {
                                        return;
                                    }

                                    $.get('/bulk_delete/' + data.id, function(data) {
                                        if (data.status === 'finished') {
                                            handle.reset();

                                            if (self.allSelected) {
                                                var removeModels = [];
                                                collection.each(function(model) {
                                                    if (!checkPermissions || security.checkPermission('delete', model)) {
                                                        removeModels.push(model);
                                                        delete self.selectionMap[model.get('id')];
                                                    }
                                                });
                                                collection.remove(removeModels);
                                            }
                                            else {
                                                _.each(models, function (model) {
                                                    if (!checkPermissions || security.checkPermission('delete', model)) {
                                                        delete self.selectionMap[model.get('id')];
                                                        collection.remove(model);
                                                    }
                                                });
                                            }

                                            self.trigger('bulk-delete:completed');
                                        }
                                        else if (data.status === 'failed') {
                                            // nothing specified, but don't continue fetching
                                        }
                                        else {
                                            var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                            progressBar.width(pct + '%');
                                            completeMessage.text(Math.floor(pct) + '% complete');
                                            waitForCompletion();
                                        }
                                    });
                                }, 2000);
                            };
                            waitForCompletion();
                        },
                        error: function() {
                            handle.reset();

                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: 'There was an error processing this request',
                                        classes: 'load-error error',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            );
        }
    },
    handleBulkEdit: function() {
        var self = this;

        if (_.size(this.selectionMap) < 2) {
            var content = {
                message: 'Please select at least 2 records for bulk edit',
                icon: 'icon-warning'
            };

            MessageBox.showOk(content, this.options.parent);
        }
        else {
            var data = {
                elementType: this.getElementType()
            };

            if (this.allSelected) {
                var isSmartGroup = false;

                data.numItemsSelected = this.options.collection.total;

                if (this.options.tableModel) {
                    data.groupId = this.options.tableModel.get('id');
                    isSmartGroup = this.options.tableModel.get('group_type') === 'smart';
                }
                else {
                    data.groupId = specialGroups[this.options.type];
                }

                // on smart groups the group id is enough
                if (this.options.parent.filter && !isSmartGroup) {
                    data.filterId = this.options.parent.filter.get('id');
                }

                if (this.options.parent.section) {
                    data.sectionId = this.options.parent.section.id;
                }
            }
            else {
                data.numItemsSelected = _.size(this.selectionMap);

                var models = _.map(this.selectionMap, function(val, id) {
                    return self.options.collection.get(id);
                });

                data.selection = new Backbone.Collection(models);
            }

            var view = new BulkEdit(data);

            this.listenTo(view, 'bulk-edit:done', function() {
                self.selectionMap = {};
                self.previewContactId = null;
                self.selectAllChecked = false;

                self.render({ loader: true });

                self.options.parent.fetchCollection();
            });

            this.options.parent.bulkEditRegion.show(view);
        }
    },
    handleMergeItems: function() {
        var self = this;
        var itemCount = _.size(this.selectionMap);

        if (itemCount < 2 || itemCount > 5) {
            MessageBox.showOk(
                {
                    message: 'You can merge between 2 and 5 contacts',
                    icon: 'icon-warning'
                },
                this.options.parent
            );
            return;
        }

        var mergeView = new MergeView({
            elementType: this.getElementType().slice(0, -1), // no 's' at the end!
            item_ids: _.map(this.selectionMap, function(val, id) { return id; })
        });
        this.listenTo(mergeView, 'merge:done', function(data) {
            var regions = appContent.contentRegions;

            // if any of the contacts is open in a detail view, close it
            _.each(regions, function(region) {
                var view = region.currentView;

                if (view && view.model && (view.model.id in self.selectionMap)) {
                    view.closeView();
                }
            });

            self.selectionMap = {};
            self.previewContactId = null;
            self.selectionMap[data.id] = true;

            self.render({ loader: true });

            self.options.parent.fetchCollection();
        });
        this.options.parent.mergeRegion.show(mergeView);
    },
    handleAddNew: function() {
        var self = this;
        this.options.parent.newItem();

        if (this.previewContactId) {
            this.previewContactId = null;
            this.render();
        }

        vent.once('contact:save', function(){
            self.render({ loader: true });

            self.options.parent.fetchCollection();
        });
    },
    handleAddExisting: function() {
        var self = this;

        var options = {
            ItemModel: this.options.collection.model
        };

        if (this.options.addItemSelectPlaceholder) {
            options.selectPlaceholder = this.options.addItemSelectPlaceholder;
        }

        if (this.options.addItemSelectText) {
            options.selectText = this.options.addItemSelectText;
        }

        if (this.options.addItemSelectUrl) {
            options.selectUrl = this.options.addItemSelectUrl;
        }

        this.addItemView = new AddItemView(options);

        this.addItemView.listenTo(this.addItemView, 'add-item', function(model) {
            if(self.options.addItemNoCheckPermissions || security.checkPermission('edit', model)) {
                self.trigger('toolbar:add-item', model);
            }
            else {
                self.addItemView.showPermissionAlert(model);
            }
        });

        $('body').on('mousedown.close-tools', function(ev) {
            if(!$(ev.target).is('.add-item-container > *') &&
               !$(ev.target).closest('.sub-tools-container > div').length
            ) {
                self.addItemView.remove();
                $('body').off('mousedown.close-tools');
            }
        });

        this.addItemView.render();

        $(this.$el).append(this.addItemView.$el);
        this.addItemView.$el.show();

        if (this.addItemView.select) {
            this.addItemView.select.$el.select2('open');
        }
    },
    handleRemoveFrom: function() {
        var self = this;
        var count = _.size(this.selectionMap);
        var countStr;

        if (this.allSelected) {
            countStr = 'all items';
        }
        else {
            countStr = count + ' item' + (count > 1 ? 's' : '');
        }

        var content = {
                message: Handlebars.compile('Are you sure you want to remove {{count}} from {{name}}?')({count: countStr, name: this.options.tableModel.get('name')}),
                icon: 'icon-trashcan',
                itemsLength: this.allSelected ? this.collection.total : count,
                accept_is_negative: true
            };

        var removeData = { };

        if (this.allSelected) {
            if (this.options.tableModel) {
                removeData.group_id = this.options.tableModel.get('id');
            }
            else {
                removeData.group_id = specialGroups[this.options.type];
            }

            if (this.options.parent.filter) {
                removeData.filter_id = this.options.parent.filter.get('id');
            }

            if (this.options.parent.section) {
                removeData.section_id = this.options.parent.section.id;
            }
        }
        else {
            removeData.group_id = this.options.tableModel.get('id');
            removeData.entity_ids = _.map(self.selectionMap, function(val, id) {
                return id;
            })
        }

        MessageBox.showYesNo(
            content,
            this.options.parent,
            function() { // Yes
                var handle = MessageBox.showNoBtn({
                        icon: 'icon-trashcan',
                        message: 'Removing ' + countStr +
                        "<div class='mb-progress-bar-parent'><div class='mb-progress-bar'></div></div>" +
                        "<div class='mb-wait-cont'>0% complete</div>"
                    },
                    self.options.parent,
                    {
                        staticRegion: true
                    }
                );

                var progressBar = handle.messageBox.$el.find('.mb-progress-bar');
                var completeMessage = handle.messageBox.$el.find('.mb-wait-cont');

                progressBar.width('0%');
                completeMessage.text('0% complete');

                $.ajax({
                    type: 'POST',
                    url: '/bulk_remove?queue',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(removeData),
                    success: function(data) {
                        var waitForCompletion = function() {
                            setTimeout(function() {
                                if (self.isClosed) {
                                    return;
                                }

                                $.get('/bulk_remove/' + data.id, function(data) {
                                    if (data.status === 'finished') {
                                        handle.reset();

                                        self.selectionMap = {};
                                        self.previewContactId = null;

                                        self.render({ loader: true });
                                        self.options.parent.fetchCollection();
                                        self.trigger('bulk-remove:completed');
                                    }
                                    else if (data.status !== 'failed') {
                                        var pct = ((data.results.failed + data.results.processed) / Math.max(data.results.total, 1)) * 100;
                                        progressBar.width(pct + '%');
                                        completeMessage.text(Math.floor(pct) + '% complete');
                                        waitForCompletion();
                                    }
                                });
                            }, 2000);
                        };

                        waitForCompletion();
                    }
                });
            }
        );
    },
    handleRemoveFromFavorites: function() {
        this.handleRemoveFrom();
    },
    handleRemoveFromGroup: function() {
        this.handleRemoveFrom();
    },
    parseRow: function(row, customFieldColumnMap) {
        var self = this;

        var statusMap = {
            'none': TextManager.parseText('${ID_FORECAST_STATUS_NONE, capitalize}'),
            'none_upside': TextManager.parseText('${ID_FORECAST_STATUS_NONE_UPSIDE, capitalize}'),
            'committed_downside': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED_DOWNSIDE, capitalize}'),
            'committed': TextManager.parseText('${ID_FORECAST_STATUS_COMMITTED, capitalize}')
        };

        var taskTypes = AppConfig.getValue('quick_add_task.types_list', []);

        function parseComment(value, truncateText) {
            let trimmedValue = htmlSanitizer.sanitize(value, false, false, true);

            if(Utilities.isHTML(value)){
                trimmedValue = value

                trimmedValue = Utilities.getTextFromHTMLContent(trimmedValue);

                trimmedValue = Utilities.replaceAll(trimmedValue, '<br>', '');
                trimmedValue = Utilities.replaceAll(trimmedValue, '↵', '');
            }

            if (truncateText && trimmedValue.length > 75) {
                trimmedValue = trimmedValue.substring(0, 75) + ' ...';
            }

            const sanitizedValue = htmlSanitizer.sanitize(value, true, true);

            return {
                value: value,
                trimmedValue: trimmedValue,
                sanitizedValue: sanitizedValue
            };
        }

        function parseActivity(value) {
            if (!value) {
                value = '';
            }
            else if (value.activity_type === "note") {
                value = value.note;
            }
            else {
                value = value.note;
            }

            let trimmedActivityData = htmlSanitizer.sanitize(value, false, false, true);
            trimmedActivityData = trimmedActivityData.split('&nbsp;').join(' ');

            if (trimmedActivityData.length > 75) {
                trimmedActivityData = trimmedActivityData.substring(0, 75) + ' ...';
            }

            const sanitizedValue = htmlSanitizer.sanitize(value, true, true);

            return {
                value: value,
                trimmedActivityData: trimmedActivityData,
                sanitizedValue: sanitizedValue
            };
        }

        function parseGroupActivities(activityList) {
            const value = (activityList || []).map(activity => activity.note || "").join(" | ");

            let trimmedActivityData = htmlSanitizer.sanitize(value, false, false, true);
            trimmedActivityData = trimmedActivityData.split('&nbsp;').join(' ');

            if (trimmedActivityData.length > 75) {
                trimmedActivityData = trimmedActivityData.substring(0, 75) + ' ...';
            }

            const sanitizedValue = htmlSanitizer.sanitize(value, true, true);

            return {
                value: value,
                trimmedActivityData: trimmedActivityData,
                sanitizedValue: sanitizedValue
            };
        }

        function parseCommunications(value, field) {
            return {
                communications: value || [],
                clickCallback: function(ev) {
                    var val = $(ev.target).text();
                    var comm = new CommunicationModel({
                        medium: field,
                        value: val
                    });

                    if (!this.props.row.type) {
                        this.props.row.type = self.options.type; // rewiev
                    }

                    app.followLink(new Backbone.Model(this.props.row), comm.valueHref());
                }
            };
        }

        function parseCompleted(value) {
            return {
                completed: value,
                clickCallback: function(id, completed, related) {
                    var task = new TaskModel({
                        id: id
                    });

                    task.save({
                        completed: completed
                    },{
                        patch: true,
                        success: function() {
                            self.options.collection.get({id: id}).set('completed', completed);
                            self.render();

                            if (completed && related) {
                                if (AppConfig.getValue('on_task_completation.show_activity_note_popup', false) ||
                                    AppConfig.getValue('on_task_completation.show_task_popup', false)) {
                                    vent.trigger('quick:edit:task', task, {
                                        showNextSteps: true
                                    });
                                }
                            }
                        },
                        error: function(model, response) {
                            let errorMsg = ''
                            if (response.status === 400){
                                errorMsg = 'You are not authorized to complete this task.'
                            } else {
                                errorMsg = 'There was an error processing this request.'
                            }
                            vent.trigger('alert:show', {
                                type: function() {
                                    return {
                                        message: errorMsg,
                                        classes: 'load-error error',
                                        timer: 3000
                                    };
                                }
                            });
                        }
                    });
                }
            };
        }

        function parseDealt(value) {
            return {
                dealt: value,
                clickCallback: function(id, params) {
                    var activity = new ActivityModel({ id: id });
                    activity.save({
                        activity_type: 'archive:message',
                        params: params
                    }, {
                        patch: true,
                        success: function() {
                            vent.trigger('texts:badget:sync');
                            self.options.collection.get({ id: id }).set('params', params);
                            self.render();
                        }
                    });
                }
            };
        }

        function parseRelated(value) {
            if (!value) {
                return;
            }

            var a, type;
            var related = _.clone(value);

            switch (value.type) {
                case 'individuals':
                    a = 'individuals';
                    type = 'individual';
                    break;
                case 'organizations':
                    a = 'organizations';
                    type = 'organization';
                    break;
                case 'opportunities':
                    a = 'deals';
                    type = 'opportunity'
                    break;
                case 'appointments':
                    a = 'appointments/all';
                    type = 'appointment';
                    related.name = 'Appointment';
                    break;
            }

            return {
                related: related,
                url: "/#" + a + "/" + value.id,
                clickCallback: function() {
                    if (type === 'appointment') {
                        vent.trigger('quick:edit:appointment', value.id);
                    } else {
                        var model = new OrganizationModel({
                            id: value.id
                        });

                        self.options.parent.trigger('show:related', model, type);
                    }
                }
            }
        }

        function parseCurrency(value, currency) {
            currency = currency || app.user.get('client')['default_currency'];
            return Currency.format(currency, value);
        }

        if (this.options.elementType === 'activities') {
            if ('note' in row) {
                row['note'] = parseComment(row['note'], true);
            }
            if ('created' in row) {
                row['created'] = row['created'] ? dateFormat.entityInformationFormat(row['created']) : '';
            }
            if ('target_date' in row) {
                if (row['target_date']) {
                    row['due_date'] = dateFormat.parseDate(row['target_date']);
                    row['due_date_formatted'] = dateFormat.dueItemFormat(row['target_date']);
                } else {
                    row['due_date'] = '';
                }
            }
            if ('related' in row) {
                row['related'] = parseRelated(row['related']);
            }
            if ('related_type' in row) {
                var typeMap = {
                    individuals: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                    organizations: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                    opportunities: TextManager.parseText('${ID_DEAL, capitalize}'),
                    appointments: 'Appointment'
                };
                row['related_type'] = typeMap[row['related_type']];
            }
        }
        else if (this.options.elementType === 'texts') {
            if ('note' in row) {
                row['note'] = parseComment(row['note'], true);
            }
            if ('created' in row) {
                row['created'] = row['created'] ? dateFormat.entityInformationFormat(row['created']) : '';
            }
            if ('related' in row) {
                row['related'] = parseRelated(row['related']);
            }
            if ('params' in row) {
                row['dealt'] = parseDealt(row.params.dealt || false);
            }
        }
        else if (this.options.elementType === 'messages') {
            if ('note' in row) {
                row['note'] = parseComment(row['note'], true);
            }
        }
        else if (this.options.elementType === 'tasks') {
            if ('completed' in row) {
                row['completed'] = parseCompleted(row['completed']);
            }
            if ('text' in row) {
                row['text'] = parseComment(row['text'] || '', true);
            }
            if ('related' in row) {
                row['related'] = parseRelated(row['related']);
            }
            if ('due_date' in row) {
                if (row['due_date']) {
                    row['due_date'] = dateFormat.parseDate(row['due_date']);
                    row['due_date_formatted'] = dateFormat.dueItemFormat(row['due_date']);
                } else {
                    row['due_date'] = '';
                }
            }
            if ('created' in row) {
                row['created'] = row['created'] ? dateFormat.entityInformationFormat(row['created']) : '';
            }
            if ('modified' in row) {
                row['modified'] = row['modified'] ? dateFormat.entityInformationFormat(row['modified']) : '';
            }
            if ('complete_date' in row) {
                row['complete_date'] = row['complete_date'] ? dateFormat.entityInformationFormat(row['complete_date']) : '';
            }
            if ('related_type' in row) {
                var typeMap = {
                    individual: TextManager.parseText('${ID_INDIVIDUAL, capitalize}'),
                    organization: TextManager.parseText('${ID_ORGANIZATION, capitalize}'),
                    opportunity: TextManager.parseText('${ID_DEAL, capitalize}')
                };
                row['related_type'] = typeMap[row['related_type']];
            }
            if ('task_type' in row) {
                row['task_type'] = taskTypes.find(tt => tt.id === row['task_type'])?.title || '';
            }
        }
        else if (this.options.elementType === 'campaigns') {
            if ('to_group' in row) {
                // used for column using same data
                row.to_group_sent = row.to_group;
            }
            if ('from_user' in row) {
                row.from_user = row.from_user ? row.from_user.name : '';
                // used for column using same data
                row.from_user_sent = row.from_user;
            }

            if ('automation' in row) {
                row.automation = row.automation ? row.automation.name : '';
            }

            if (('automation2' in row) && !row.automation) {
                row.automation = row.automation2 ? row.automation2.name : '';
            }

            if ('created' in row) {
                row['created'] = row['created'] ? dateFormat.entityInformationFormat(row['created']) : '';
            }
            if ('modified' in row) {
                row['modified'] = row['modified'] ? dateFormat.entityInformationFormat(row['modified']) : '';
            }
            if ('sent' in row) {
                row['sent'] = row['sent'] ? dateFormat.entityInformationFormat(row['sent']) : '';
            }
            if ('campaign_schedule' in row && row['campaign_schedule']) {
                var campaignSchedule = row['campaign_schedule'];
                row['campaign_schedule'] = campaignSchedule ? dateFormat.formatRawScheduledDate(campaignSchedule.when) : '';
                if (campaignSchedule.timezone) {
                    row['campaign_schedule'] += " (" + TimeZoneSelectView.getTZName(campaignSchedule.timezone) + ")";
                }
            }
            if ('email_event_stats' in row) {
                row['email_event_stats_send'] = row['email_event_stats'].send;
                row['email_event_stats_delivered'] = row['email_event_stats'].delivered;

                if (row.campaign_type === 'message') {
                    row['email_event_stats_open'] = '-';
                    row['email_event_stats_click'] = '-';
                    row['email_event_stats_unsubscribed'] = '-';
                    row['email_event_stats_spam'] = '-';
                    row['email_event_stats_bounce'] = '-';
                    row['email_event_stats_open_rate'] = '-';
                    row['email_event_stats_click_rate'] = '-';
                    row['email_event_stats_delivered_rate'] = '-';
                    row['email_event_stats_hard_bounce'] = '-';
                    row['email_event_stats_soft_bounce'] = '-';
                    row['email_event_stats_unsubscribed_all'] = '-';
                    row['email_event_stats_unsubscribed_list'] = '-';
                } else {
                    row['email_event_stats_open'] = row['email_event_stats'].open;
                    row['email_event_stats_click'] = row['email_event_stats'].click;
                    row['email_event_stats_unsubscribed'] = row['email_event_stats'].unsubscribed;
                    row['email_event_stats_spam'] = row['email_event_stats'].spam;
                    row['email_event_stats_bounce'] = row['email_event_stats'].bounce;
                    row['email_event_stats_open_rate'] = row['email_event_stats'].open_rate;
                    row['email_event_stats_click_rate'] = row['email_event_stats'].click_rate;
                    row['email_event_stats_delivered_rate'] = row['email_event_stats'].delivered_rate;
                    row['email_event_stats_hard_bounce'] = row['email_event_stats'].hard_bounce;
                    row['email_event_stats_soft_bounce'] = row['email_event_stats'].soft_bounce;
                    row['email_event_stats_unsubscribed_all'] = row['email_event_stats'].unsubscribed_all;
                    row['email_event_stats_unsubscribed_list'] = row['email_event_stats'].unsubscribed_list;
                }
            }
        }
        else {
            if ('created' in row) {
                row.created = row.created ? dateFormat.entityInformationFormat(row.created) : '';
            }
            if ('user_created' in row) {
                row.user_created = row.user_created ? dateFormat.entityInformationFormat(row.user_created) : '';
            }
            if ('modified' in row) {
                row.modified = row.modified ? dateFormat.entityInformationFormat(row.modified) : '';
            }
            if ('last_viewed' in row) {
                row.last_viewed = row.last_viewed ? dateFormat.entityInformationFormat(row.last_viewed) : '';
            }
            if ('expected_close_date' in row) {
                row.expected_close_date = row.expected_close_date ? dateFormat.entityInformationFormat(row.expected_close_date) : '';
            }
            if ('phase_last_changed' in row) {
                row.phase_last_changed = row.phase_last_changed ? dateFormat.entityInformationFormat(row.phase_last_changed) : '';
            }
            if ('became_lead_date' in row) {
                row.became_lead_date = row.became_lead_date ? dateFormat.entityInformationFormat(row.became_lead_date) : '';
            }
            if ('last_direct_activity.modified' in row) {
                row['last_direct_activity.modified'] = row['last_direct_activity.modified'] ? dateFormat.entityInformationFormat(row['last_direct_activity.modified']) : '';
            }
            if ('organization.last_direct_activity.modified' in row) {
                row['organization.last_direct_activity.modified'] = row['organization.last_direct_activity.modified'] ? dateFormat.entityInformationFormat(row['organization.last_direct_activity.modified']) : '';
            }
            if ('comments' in row) {
                row['comments'] = parseComment(row['comments'] || '', true);
            }
            if ('organization.comments' in row) {
                row['organization.comments'] = parseComment(row['organization.comments'] || '', true);
            }
            if ('last_direct_activity' in row) {
                row.last_direct_activity = parseActivity(row['last_direct_activity']);
            }
            if ('organization.last_direct_activity' in row) {
                row['organization.last_direct_activity'] = parseActivity(row['organization.last_direct_activity']);
            }
            if ('pinned_activity' in row) {
                row['pinned_activity'] = parseGroupActivities(row['pinned_activity']);
            }
            if ('organization.pinned_activity' in row) {
                row['organization.pinned_activity'] = parseGroupActivities(row['organization.pinned_activity']);
            }
            if ('email' in row) {
                row.email = parseCommunications(row['email'], 'email');
            }
            if ('phone' in row) {
                row.phone = parseCommunications(row['phone'], 'phone');
            }
            if ('organization.email' in row) {
                row['organization.email'] = parseCommunications(row['organization.email'], 'organization.email');
            }
            if ('organization.phone' in row) {
                row['organization.phone'] = parseCommunications(row['organization.phone'], 'organization.phone');
            }
            if ('default_value' in row) {
                row['default_value'] = parseCurrency(row['default_value']);
            }
            if ('weighted_value' in row) {
                row['weighted_value'] = parseCurrency(row['weighted_value']);
            }
            if ('buckets_total' in row) {
                row['buckets_total'] = parseCurrency(row['buckets_total'], row['currency']);
            }
            if ('status' in row) {
                row['status'] = statusMap[row['status']];
            }

            if (self.options.buckets) {
                self.options.buckets.each(function(bucket) {
                    let id = 'buckets.' + bucket.get('id');
                    if (id in row) {
                        row[id] = parseCurrency(row[id], row['currency']);
                    }
                });
            }

            if (customFieldColumnMap) {
                _.each(row, function (val, customFieldId) {
                    if (customFieldId in customFieldColumnMap) {
                        var column = customFieldColumnMap[customFieldId];
                        var type = column.type;
                        if (type === 'organization') {
                            if (Array.isArray(row[customFieldId])) {
                                let names = [];

                                for (const org of row[customFieldId]) {
                                    names.push(org.name ? org.name : '');
                                }

                                row[customFieldId] = names.join(', ');
                            } else {
                                row[customFieldId] = row[customFieldId] ? row[customFieldId].name : '';
                            }
                        }
                        else if (type === 'opportunity') {
                            if (Array.isArray(row[customFieldId])) {
                                let names = [];

                                for (const deal of row[customFieldId]) {
                                    names.push(deal.name ? deal.name : '');
                                }

                                row[customFieldId] = names.join(', ');
                            } else {
                                row[customFieldId] = row[customFieldId] ? row[customFieldId].name : '';
                            }
                        }
                        else if (type === 'individual') {
                            if (Array.isArray(row[customFieldId])) {
                                let names = [];

                                for (const indv of row[customFieldId]) {
                                    names.push(indv.full_name ? indv.full_name : '');
                                }

                                row[customFieldId] = names.join(', ');
                            } else {
                                row[customFieldId] = row[customFieldId] ? row[customFieldId].full_name : '';
                            }
                        }
                        else if (type === 'user') {
                            row[customFieldId] = row[customFieldId] ? row[customFieldId].name : '';
                        }
                        else if ((type === 'number') || (type === 'product')) {
                            let value = row[customFieldId];

                            if (_.isString(value)) {
                                value = parseFloat(value);
                            }

                            if (_.isNumber(value)) {
                                if (AppConfig.getValue('group_pages.round_numeric_custom_field_columns', false)) {
                                    value = Math.round(value);
                                }

                                row[customFieldId] = Utilities.numberWithCommas(value);
                            } else {
                                row[customFieldId] = '';
                            }
                        }
                        else if (type === 'date') {
                            row[customFieldId] = row[customFieldId] ? dateFormat.entityInformationFormatUTC(row[customFieldId]) : '';
                        }
                        else if (type === 'paragraph') {
                            row[customFieldId] = parseComment(row[customFieldId] || '', true);
                        }
                    }
                });
            }
        }
    },
    render: function(options) {
        options = options || {};
        var self = this;

        var loader = this.loader || options.loader;
        this.loader = false;

        var columns = this.columns;

        if (!columns) {
            columns = this.options.selectedColumns;
        }
        if ((!columns || columns.length === 0) && this.options.defaultColumns) {
            columns = this.options.defaultColumns;
        }
        if (!columns || columns.length === 0) {
            columns = this.getDefaultColumns(this.getElementType());
        }

        var index = this.options.collection.start;

        columns = _.clone(columns);

        var fixedTable;
        if (this.options.fixedWhenDefaultColumns && _.isEqual(this.options.defaultColumns, columns)) {
            fixedTable = true;
        }

        // creating map for performance purposes
        var allColumnsMap = _.groupBy(this.getAllColumns(this.getElementType()), 'id');
        var customFieldColumnMap = {};
        var removedCustomFieldIdxs = [];

        _.each(columns, function(column, idx) {
            // custom field columns
            if (column.indexOf('custom_fields.') !== -1) {
                let parts = column.split('.');
                var prepend = '';
                if (parts[0] === 'organization') {
                    prepend = TextManager.parseText('${ID_ORGANIZATION, uppercase}');
                }
                let id = parts[parts.length - 1];

                var cf = self.options.customFields.get(id);
                if (cf) {
                    columns[idx] = {
                        type: cf.get('type'),
                        name: prepend + cf.get('name'),
                        id: column,
                        sort: [column]
                    };

                    switch (cf.get('type')) {
                        case 'date':
                            columns[idx].contextMenu = dateContextMenu;
                            break;

                        case 'dropDown':
                            columns[idx].contextMenu = dropdownContextMenu;
                            break;
                    }

                    customFieldColumnMap[column] = columns[idx];
                }
                // custom field was deleted from system
                else {
                    removedCustomFieldIdxs.push(idx);
                }
            }
            // bucket columns
            else if (column.indexOf('buckets.') === 0) {
                let parts = column.split('.');
                let id = parts[1];
                columns[idx] = {
                    id: 'buckets.' + id,
                    sort: ['buckets.' + id],
                    headerText: self.options.buckets.get(id).get('name')
                };
            }
            // campaign columns
            else if (column.indexOf('campaigns.') === 0) {
                const parts = column.split('.');

                columns[idx] = {
                    id: column,
                    sort: [column],
                    headerText: `Campaign ${self.options.campaigns.get(parts[1]).get('name')} ${parts[2]}`,
                    maxWidth: 250
                };
            }
            // defined columns
            else {
                columns[idx] = {
                    id: column,
                    sort: allColumnsMap[column] && allColumnsMap[column][0].sort,
                    contextMenu: allColumnsMap[column] && allColumnsMap[column][0].contextMenu,
                    formatter: allColumnsMap[column] && allColumnsMap[column][0].formatter,
                    headerText: allColumnsMap[column] && allColumnsMap[column][0].name,
                    minWidth: allColumnsMap[column] && allColumnsMap[column][0].minWidth,
                    maxWidth: allColumnsMap[column] && allColumnsMap[column][0].maxWidth,
                    style: allColumnsMap[column] && allColumnsMap[column][0].style,
                    smartGroupName: allColumnsMap[column] && allColumnsMap[column][0].smartGroupName
                };
            }
        });

        _.each(removedCustomFieldIdxs.reverse(), function(idx) {
            columns.splice(idx, 1);
        });

        // add empty column for "Grand Totals" string, if its taken by aggregate value
        var firstCol = columns[0];
        if (firstCol.id === 'default_value' || firstCol.id === 'buckets_total' || 'bucket' in firstCol) {
            columns.unshift({
                id: 'empty_column',
                headerText: ' '
            });
        }

        var totals = {};
        var currencies = {};

        var rows = this.options.collection.map(function(item) {
            var row = _.clone(item.attributes);

            // totals
            if ('default_value' in row) {
                if (!('default_value' in totals)) {
                    totals['default_value'] = 0;
                }
                totals['default_value'] += row['default_value'];
            }
            if ('weighted_value' in row) {
                if (!('weighted_value' in totals)) {
                    totals['weighted_value'] = 0;
                }
                totals['weighted_value'] += row['weighted_value'];
            }
            if ('buckets_total' in row) {
                if (!('buckets_total' in totals)) {
                    totals['buckets_total'] = 0;
                }
                totals['buckets_total'] += row['buckets_total'];

                // buckets_total guarantees currency existence
                currencies[row['currency']] = true;
            }
            if (self.options.buckets) {
                self.options.buckets.each(function(bucket) {
                    let id = 'buckets.' + bucket.get('id');
                    if (id in row) {
                        if (!(id in totals)) {
                            totals[id] = 0;
                        }
                        totals[id] += row[id];
                    }
                });
            }

            self.parseRow(row, customFieldColumnMap);

            index = index + 1;
            row.index = index;

            row.selected = row.id in self.selectionMap;

            return row;
        });

        if (_.size(currencies) === 1) {
            totals['currency'] = _.keys(currencies)[0];
        }
        else if (_.size(currencies) > 1) {
            delete totals['buckets_total'];

            if (self.options.buckets) {
                self.options.buckets.each(function(bucket) {
                    var id = 'buckets.' + bucket.get('id');
                    if (id in totals) {
                        delete totals[id];
                    }
                });
            }
        }

        self.parseRow(totals);

        var start = this.options.collection.start + 1;
        var stop = this.options.collection.start + this.options.collection.length;
        var total = this.options.collection.total;
        var contactPreviewVisible = !!this.previewContactId;

        ReactDOM.render(
            <div>
                {contactPreviewVisible && <ContactPreview
                    individualId={this.previewContactId}
                    individual={this.options.collection.get(this.previewContactId)}
                    columns={this.options.collection.columns}
                />}
                <IODTableReactView
                    fixedTable={fixedTable}
                    firefox={app.firefox}
                    elementType={this.options.elementType}
                    showBarRow={this.options.showBarRow}
                    showCampaignSpecialColumns={this.options.elementType === 'campaigns'}
                    loader={loader}
                    columns={columns}
                    columnsModifiers={this.columnsModifiers}
                    rows={rows}
                    totals={totals}
                    selectAllChecked={this.selectAllChecked}
                    selectionCount={_.size(this.selectionMap)}
                    totalCount={this.options.collection.total}
                    allSelected={this.allSelected}
                    sortParams={this.sortParams} // { direction: true, field: "first_name" }}
                    pagerValues={{ start: start, stop: stop, total: total }}
                    buttons={this.options.buttons}
                    disableRadioSelect={this.options.disableRadioSelect}
                    onRowClick={this.handleRowClick.bind(this)}
                    handleCampaignMenuClick={this.handleCampaignMenuClick.bind(this)}
                    handleCampaignStatClick={(field, campaignId, campaignName) => this.handleCampaignStatClick.bind(this)(field, campaignId, campaignName, this.options.parent)}
                    handleCBClick={this.handleCBClick.bind(this)}
                    handleHeaderCBClick={this.handleHeaderCBClick.bind(this)}
                    handleAllSelect={this.handleAllSelect.bind(this)}
                    onHeaderClick={this.handleSort.bind(this)}
                    onHeaderContextMenuItemChange={this.handleColumnModifierChange.bind(this)}
                    onHeaderContextMenuItemClick={this.handleColumnContextMenuClick.bind(this)}
                    handlePrevClick={this.handlePrevClick.bind(this)}
                    handleNextClick={this.handleNextClick.bind(this)}
                    handleBulkDelete={this.handleBulkDelete.bind(this)}
                    handleBulkEdit={this.handleBulkEdit.bind(this)}
                    handleMergeItems={this.handleMergeItems.bind(this)}
                    handleAddNew={this.handleAddNew.bind(this)}
                    handleAddExisting={this.handleAddExisting.bind(this)}
                    handleRemoveFromFavorites={this.handleRemoveFromFavorites.bind(this)}
                    handleRemoveFromGroup={this.handleRemoveFromGroup.bind(this)}
                    componentDidMountCallback={this.componentDidMountCallback.bind(this)}
                    componentWillUnmountCallback={this.componentWillUnmountCallback.bind(this)}
                    previewVisible={contactPreviewVisible}
                    activeRowId={this.previewContactId}
                />
            </div>,
            this.$el.get(0)
        );
    },
    onClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    },
    // getter for retrieving all static columns
    getAllColumns: function(type) {
        if (AppConfig.getValue('is_pdcrm')) {
            return PDCRMCOLUMNS[type].allColumns;
        }

        return COLUMNS[type].allColumns;
    },
    getDefaultColumns: function(type) {
        if (AppConfig.getValue('is_pdcrm')) {
            return PDCRMCOLUMNS[type].defaultColumns;
        }

        return COLUMNS[type].defaultColumns;
    },
    getSelectedColumns: function() {
        return this.columns || this.options.defaultColumns || this.getDefaultColumns(this.options.type);
    },
    updateColumns: function(columns) {
        this.options.parent.trigger('columns:update', columns);
        this.columns = columns;
        this.render();
    },
    savePaginationState: function(page) {
        if (AppConfig.getValue('persistTablePagination', true)) {
            var tableId = this.options.tableModel ? this.options.tableModel.get('id') : this.options.type;
            var stateName = this.options.elementType + '_' + tableId + '_table_state';
            var preferences = app.user.get('preferences');

            preferences[stateName] = preferences[stateName] || {};

            if (preferences[stateName].page !== page) {
                preferences[stateName].page = page;
                $.post(app.user.url() + '/preferences', JSON.stringify(preferences));
            }
        }
    },
    beforeCollection: function() {
    },
    onCollection: function() {
        if (this.initialPage) {
            if (this.options.collection.start >= this.options.collection.total) {
                this.initialPage = null;
                this.options.parent.fetchCollection(null, 1);
                return;
            }

            this.options.collection.collectionPage = this.initialPage;
            this.initialPage = null;
        } else {
            if (this.options.collection.collectionPage > 1 && this.options.collection.start === 0) { // this situation can happen if we are in page 3 for instance, and apply a filter
                this.options.collection.collectionPage = 1;
            }

            this.savePaginationState(this.options.collection.collectionPage);
        }

        // check if the selected entities are in the collection
        if (this.selectionMap) {
            let newSelectionMap = {};

            for (const entityId in this.selectionMap) {
                if (this.selectionMap[entityId]) {
                    if (this.options.collection.find(m => m.get('id') === entityId)) {
                        newSelectionMap[entityId] = true;
                    }
                }
            }

            this.selectionMap = newSelectionMap;
        }

        this.render();
    },
    componentDidMountCallback: function(reactTable) {
        this.adjustHeaderCallback = reactTable.adjustHeaderWidth.bind(reactTable);
        $(window).on('resize', this.adjustHeaderCallback);
        vent.on('sidebar:collapse', this.adjustHeaderCallback);
        vent.on('contacts:transition:end', this.adjustHeaderCallback);

        let table = $(reactTable.refs.tableContainer);
        let headerTable = $(reactTable.refs.headerContainer);
        let totalsTable = $(reactTable.refs.totalsContainer);
        $(table).on('scroll', function() {
            var sl = $(this).scrollLeft();
            headerTable.scrollLeft(sl);
            totalsTable.scrollLeft(sl);
        });
    },
    componentWillUnmountCallback: function(reactTable) {
        $(window).off('resize', this.adjustHeaderCallback);
        vent.off('sidebar:collapse', this.adjustHeaderCallback);
        vent.off('contacts:transition:end', this.adjustHeaderCallback);
    },
    getElementType: function() {
        return this.options.parent.parent.getElementType();
    }
});

export default TableBodyReactView;

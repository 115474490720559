import _ from 'underscore'
import $ from 'jquery'
import Handlebars from 'handlebars'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'

import Utilities from 'js/utils/utilities'
import FilterQuickView from 'js/views/filters/quick'
import FilterAdvancedView from 'js/views/filters/advanced'
import FilterFields from 'js/views/filters/fields'
import TaskFilterFields from 'js/views/filters/task-fields'
import ActivityFilterFields from 'js/views/filters/activity-fields'
import TextFilterFields from 'js/views/filters/text-fields'
import MessageFilterFields from 'js/views/filters/message-fields'
import CampaignFilterFields from 'js/views/filters/campaign-fields'
import filterOperators from 'js/views/filters/operators'
import CustomFieldsCollection from 'js/collections/custom_fields'
import app from 'js/app'
import TextManager from 'app/text-manager'
import filterBarTemplate from 'templates/filters/bar.handlebars'
import filterBarOverviewTemplate from 'templates/filters/bar-overview.handlebars'
import filterBarOuterRuleTemplate from 'templates/filters/bar-outer-rule.handlebars'
import filterBarRuleTemplate from 'templates/filters/bar-rule.handlebars'


var FilterCollection = Backbone.Collection.extend({
    toJSON: function(options) {
        return this.map(function(model){
            return model.get('items').toJSON(options);
        });
    }
});

var InnerRuleView = Marionette.ItemView.extend({
    template: Handlebars.compile(filterBarRuleTemplate),
    templateHelpers: function() {
        var model = this.model;

        var field_id = model.get('field');
        var custom_id = model.get('custom');
        var operator_id = model.get('operator');
        var value = model.get('values');
        var not = model.get('not');

        var field = _.find(this.fields, function(item) {
            var info = item.id.split('#');
            return info[0] === field_id && info[1] === custom_id;
        });

        var operators = field && field.operators || [];
        var operator = _.find(operators, function(item) {
            return item.id === operator_id;
        });
        var post_field_text;

        var text;
        if (field && field.composeText) {
            text = field.composeText(value, operator);
        } else {
            if (operator) {
                if (operator.postFieldText) {
                    post_field_text = operator.postFieldText(value);
                }

                if (operator.text) {
                    value = operator.text(value);
                }
            }

            var field_value;
            if (field) {
                if (!field.group || field.name.indexOf(field.group) !== -1 || field.group.indexOf(field.name) !== -1 || field.group === this.groupType) {
                    field_value = field.name;
                } else {
                    field_value = field.group + ' ' + field.name;
                }

                if (field.value_def && field.value_def.options) {
                    for (var i = 0; i < field.value_def.options.length; ++i) {
                        if (field.value_def.options[i].id === value) {
                            value = field.value_def.options[i].value;
                            break;
                        }
                    }
                }
            } else {
                field_value = field_id;
            }

            text = [
                field_value,
                post_field_text,
                operator && operator.name || operator_id,
                value
            ].join(' ');
        }

        return {
            not: not,
            text: text
        };
    },
    tagName: 'li',
    initialize: function(options) {
        _.extend(this, options);
    }
});

var OuterRuleView = Marionette.CompositeView.extend({
    template: Handlebars.compile(filterBarOuterRuleTemplate),
    tagName: 'li',
    className: 'select2-search-choice bounce-in',
    attributes: function() {
        return {
            style: 'display: inline-block;',
            'data-id': this.model.cid
        };
    },
    itemView: InnerRuleView,
    itemViewOptions: function(model) {
        return {
            fields: this.fields,
            groupType: this.groupType
        };
    },
    itemViewContainer: 'ul',
    events: {
        'mousedown': function(ev) {
            ev.preventDefault();
        },
        'click': function(ev) {
            ev.preventDefault();

            this.trigger('active', this.model);
        },
        'click .edit-icon': function(ev) {
            ev.preventDefault();

            this.trigger('edit', this.model);
        },
        'click .select2-search-choice-close': function(ev) {
            ev.preventDefault();

            this.trigger('delete', this.model);
        }
    },
    initialize: function(options) {
        _.extend(this, options);

        this.collection = this.model.get('items');
    }
});

var FilterBarView = Marionette.CompositeRegionView.extend({
    template: Handlebars.compile(filterBarTemplate),
    templateHelpers: function() {
        return {
            locked: this.locked
        };
    },
    itemView: OuterRuleView,
    itemViewOptions: function(model) {
        return {
            parent: this,
            fields: this.fields,
            groupType: this.groupType
        };
    },
    itemViewContainer: '.select2-choices',
    ui: {
        addFilter: '.add-filter',
        clearFilter: '.clear-filter',
        input: '.add-filter input[type=text]',
        choices: '.select2-search-choice'
    },
    events: {
        'click .select2-editable-tokens': function(ev) {
            ev.preventDefault();

            this.ui.input.focus();
        },
        'focus input[type=text]': function(ev) {
            ev.stopPropagation();

            this.onInputFocus();
        },
        'blur input[type=text]': function(ev) {
            this.onInputBlur();
        },
        'input input[type=text]': function() {
            this.trigger('quick-filter:search', _.escape(this.ui.input.val()));
        },
        'keydown input[type=text]': function(ev) {
            var key = {
                9: 'tab',
                8: 'delete',
                13: 'enter',
                27: 'clear',
                37: 'left',
                38: 'up',
                39: 'right',
                40: 'down'
            }[ev.keyCode];

            if (key && !this.hasAdvancedFilterView()) {
                this.trigger('quick-filter:' + key, ev);
            }
            this.trigger('update:height');
        },
        'click .clear-filter a': function(ev) {
            ev.preventDefault();

            this.collection.reset();
        }
    },
    regions: {
        popoverRegion: '.popover-region'
    },
    initialize: function(options) {
        _.extend(this, options);
    },
    appendHtml: function(collectionView, itemView, index){
        collectionView.$el.find('.add-filter').before(itemView.el);
    },
    onRender: function() {
        this.listenTo(this.popoverRegion, 'reset', function() {
            this.popoverRegion.$el
                .hide()
                .removeClass('bounce-in');
        });
        this.listenTo(this.popoverRegion, 'show', function() {
            this.popoverRegion.$el.stop().show(0, function() {
                $(this).addClass('bounce-in');
            });
        });
        this.listenTo(this, 'itemview:active', function(child, model) {
            this.setActiveRule(model);
        });
        this.listenTo(this, 'itemview:edit', function(child, model) {
            this.showAdvancedFilterView(model);
        });
        this.listenTo(this, 'itemview:delete', function(child, model) {
            this.deleteRule(model);
        });

        this.listenTo(this, 'quick-filter:clear', this.onInputClear);
        this.listenTo(this, 'quick-filter:enter', this.onInputEnter);
        this.listenTo(this, 'quick-filter:search', this.onInputChange);
        this.listenTo(this, 'quick-filter:left', this.onInputLeft);
        this.listenTo(this, 'quick-filter:right', this.onInputRight);
        this.listenTo(this, 'quick-filter:tab', this.onInputTab);
        this.listenTo(this, 'quick-filter:delete', this.onInputDelete);
        this.listenTo(this, 'quick-filter:up', this.onInputUp);
        this.listenTo(this, 'quick-filter:down', this.onInputDown);

        this.ui.input.autosizeInput();
    },
    showQuickFilterView: function() {
        if (this.hasQuickFilterView()) {
            return;
        }

        var popoverView = new FilterQuickView({
            parent: this,
            model: this.quickFilter,
            rules: this.collection,
            search: this.ui.input.val()
        });
        this.listenTo(popoverView, 'advanced', function(model) {
            this.showAdvancedFilterView(model);
        });
        this.listenTo(popoverView, 'clear-search', function(model) {
            this.ui.input.val(null);
            this.trigger('quick-filter:search', null);
        });
        this.popoverRegion.show(popoverView);
    },
    hasQuickFilterView: function() {
        var popoverView = this.popoverRegion.currentView;
        return popoverView && !popoverView.isClosed && popoverView instanceof FilterQuickView;
    },
    showAdvancedFilterView: function(model) {
        model = model || new Backbone.Model({
            items: new Backbone.Collection()
        });

        var title;
        if (this.collection.contains(model)) {
            title = 'Edit Rule';
        } else {
            title = 'Add Rule';
        }
        var collection = model.get('items');
        var callback = function() {
            if (this.collection.contains(model)) {
                this.collection.trigger('update');
            } else {
                this.collection.add(model);
            }
        };
        this.listenTo(collection, 'reset', callback);
        var popoverView = new FilterAdvancedView({
            title: title,
            collection: collection,
            fields: this.fields
        });
        this.listenTo(popoverView, 'close', function() {
            this.stopListening(collection, 'reset', callback);
            this.ui.input.focus();
        });
        this.popoverRegion.show(popoverView);

        this.ui.input.blur();
    },
    hasAdvancedFilterView: function() {
        var popoverView = this.popoverRegion.currentView;
        return popoverView && !popoverView.isClosed && popoverView instanceof FilterAdvancedView;
    },
    getRules: function() {
        return this.$el.find('.select2-search-choice');
    },
    isActiveRule: function(element) {
        return element && element.hasClass('select2-search-choice-focus');
    },
    setActiveRule: function(element) {
        if (element instanceof Backbone.Model) {
            element = this.children.findByModel(element).$el;
        }
        if (element && element.length) {
            element.addClass('select2-search-choice-focus');
            if (this.hasQuickFilterView()) {
                this.popoverRegion.reset();
            }
        } else if (this.ui.input.is(":focus")) {
            this.showQuickFilterView();
        } else if (this.hasQuickFilterView()) {
            this.popoverRegion.reset();
        }
        this.getRules().not(element).removeClass('select2-search-choice-focus');
    },
    getActiveRule: function() {
        return this.getRules().filter('.select2-search-choice-focus');
    },
    getNextRule: function(element) {
        return element && element.length ? element.next('.select2-search-choice') : this.getRules().first();
    },
    getPreviousRule: function(element) {
        return element && element.length ? element.prev('.select2-search-choice') : this.getRules().last();
    },
    getRuleModel: function(element) {
        return this.collection.get({cid: element.data('id')});
    },
    deleteRule: function(element) {
        var model;
        if (element instanceof Backbone.Model) {
            model = element;
            element = this.children.findByModel(model).$el;
        } else {
            model = this.getRuleModel(element);
        }
        if (this.isActiveRule(element)) {
            var new_element = this.getPreviousRule(element);
            if (!new_element || !new_element.length) {
                new_element = this.getNextRule(element);
            }
            this.setActiveRule(new_element);
        }
        if (model) {
            this.collection.remove(model);
        }
        if (this.hasAdvancedFilterView()) {
            this.popoverView.reset();
        }
    },
    onInputFocus: function() {
        var active = this.getActiveRule();
        if (!(active && active.length) && !this.hasAdvancedFilterView()) {
            this.showQuickFilterView();
        }
        this.trigger('update:height');
    },
    onInputBlur: function() {
        if (!this.hasAdvancedFilterView()) {
            this.setActiveRule(null);
        }
    },
    onInputClear: function() {
        var active = this.getActiveRule();
        if (active.length) {
            this.setActiveRule(null);
        } else {
            var search = this.ui.input.val();
            if (search) {
                this.ui.input.val(null);
                this.ui.input.trigger('input');
            } else {
                this.popoverRegion.reset();
            }
        }
    },
    onInputChange: function() {
        this.setActiveRule(null);
    },
    onInputLeft: function() {
        if (this.ui.input.prop('selectionStart') !== 0) {
            return;
        }

        var active = this.getActiveRule();
        var element = this.getPreviousRule(active);
        if (element && element.length) {
            this.setActiveRule(element);
        } else if (!active || !active.length) {
            this.showQuickFilterView();
        }
    },
    onInputRight: function(ev) {
        if (this.ui.input.prop('selectionStart') !== 0) {
            return;
        }

        var active = this.getActiveRule();
        if (!active || !active.length) {
            this.showQuickFilterView();
            return;
        }

        var element = this.getNextRule(active);
        this.setActiveRule(element);
        if (active && active.length || element && element.length) {
            ev.preventDefault();
        }
    },
    onInputUp: function(ev) {
        ev.preventDefault();

        this.showQuickFilterView();
    },
    onInputDown: function(ev) {
        ev.preventDefault();

        this.showQuickFilterView();
    },
    onInputTab: function(ev) {
        var active = this.getActiveRule(),
            element;

        if (ev.shiftKey) {
            element = this.getPreviousRule(active);
            if (element && element.length) {
                ev.preventDefault();
                this.setActiveRule(element);
            } else if (!active || !active.length) {
                ev.preventDefault();
                this.showQuickFilterView();
            }
        } else {
            if (!active || !active.length) {
                this.showQuickFilterView();
                return;
            }

            element = this.getNextRule(active);
            this.setActiveRule(element);
            if (active && active.length || element && element.length) {
                ev.preventDefault();
            }
        }
    },
    onInputDelete: function(ev) {
        var active = this.getActiveRule();
        if (active && active.length) {
            ev.preventDefault();
            this.deleteRule(this.getActiveRule());
        } else if (this.ui.input.prop('selectionStart') === 0 && !ev.originalEvent.repeat) {
            this.setActiveRule(this.getRules().last());
        }
    },
    onInputEnter: function(ev) {
        var active = this.getActiveRule();
        if (active && active.length) {
            ev.preventDefault();

            this.showAdvancedFilterView(this.getRuleModel(active));
        }
    }
});

var filter_customfields = {
    'individual_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_INDIVIDUAL, capitalize}']),
        'view': 'individuals'
    },
    'organization_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_ORGANIZATION, capitalize}']),
        'view': 'organizations'
    },
    'opportunity_custom': {
        'name': TextManager.getText('ID_ENTITY_CUSTOM', ['${ID_DEAL, capitalize}']),
        'view': 'deals'
    }
};

export default Marionette.Layout.extend({
    className: 'header-inner',
    template: Handlebars.compile(filterBarOverviewTemplate),
    regions: {
        filterRegion: '.filter-region'
    },
    initialize: function(options) {
        _.extend(this, options);

        this.collection = new FilterCollection();

        this.addFilter(this.model);

        this.listenTo(this.collection, 'add remove reset update', this.onModelUpdate);
    },
    onRender: function() {
        this.customfields = {};

        var view = this;

        _.each(filter_customfields, function(item, id) {
            view.customfields[id] = new CustomFieldsCollection(app.globalData.customFieldsInfo[`${item.view}Array`].filter(m => m.type !== 'list'));
        });

        this.onCustomFields();
    },
    onCustomFields: function() {
        if (this.isClosed) {
            return;
        }

        var fields;

        var groupType = this.groupType;

        if (groupType === "Task") {
            fields = _.clone(TaskFilterFields());
        } else if (groupType === "Activities") {
            fields = _.clone(ActivityFilterFields());
        }
        else if (groupType === 'Texts') {
            fields = _.clone(TextFilterFields());
        }
        else if (groupType === 'Messages') {
            fields = _.clone(MessageFilterFields());
        }
        else if (groupType === 'Campaigns') {
            fields = _.clone(CampaignFilterFields());
        }
        else {
            var ffields = FilterFields();
            fields = _.clone(ffields);

            if (groupType && groupType.length) {
                fields.sort(function (item1, item2) {
                    var index1 = Utilities.findIndex(ffields, function(item) {
                        return item.id === item1.id;
                    });
                    var index2 = Utilities.findIndex(ffields, function(item) {
                        return item.id === item2.id;
                    });
                    var groupType1 = item1.group.indexOf(groupType) === 0;
                    var groupType2 = item2.group.indexOf(groupType) === 0;

                    if (groupType1 && groupType2) {
                        return index1 - index2;
                    } else if (groupType1) {
                        return -1;
                    } else if (groupType2) {
                        return 1;
                    } else {
                        return index1 - index2;
                    }
                });
            }
            _.each(this.customfields, function (collection, id) {
                collection.each(function (model) {
                    fields.push({
                        'id': id + '#' + model.get('id'),
                        'name': model.get('name'),
                        'operators': filterOperators.custom[model.get('type')],
                        'group': filter_customfields[id].name,
                        'value_def': model.get('value')
                    });
                });
            });
        }

        var group = this.group;
        this.filterRegion.show(new FilterBarView({
            collection: this.collection,
            customfields: this.collections,
            fields: fields,
            quickFilter: this.quickFilter,
            groupType: this.groupType,
            locked: group && group.get('locked')
        }));
    },
    addFilter: function(filter) {
        var self = this;

        _.each(filter.get('rules'), function(item) {
            self.collection.add({ items: new Backbone.Collection(item) });
        });
    },
    onModelUpdate: _.debounce(function() {
        this.trigger('update-height');

        this.model.clear();
        if (this.collection.length === 0) {
            this.triggerUpdate();
        } else {
            this.model.save({
                rules: this.collection
            }, {
                alert: false,
                success: this.triggerUpdate.bind(this)
            });
        }
    }, 100),
    triggerUpdate: function() {
        this.trigger('filter-update', this.model);
    }
});

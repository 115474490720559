import _ from 'underscore'
import Backbone from 'backbone'
import Handlebars from 'handlebars'

import BaseView from 'js/views/base/base'
import UserModel from 'js/models/user'
import api from 'js/api'
import app from 'js/app'
import vent from 'js/vent'
import errorTemplate from 'templates/base/error.handlebars'


var ErrorView = BaseView.extend({
    templateHelpers: function () {
        return this.options;
    },
    template: Handlebars.compile(errorTemplate),
    render: function (jqXHR, textStatus, errorThrown
                      /*If called by Backbone.fetch#options */
                      /*model, response, options*/) {
        var model = jqXHR,
            options = errorThrown,
            view = this,
            super_render = BaseView.prototype.render,
            args = arguments,
            render = function () {
                super_render.apply(view, args);
            };

        if (model instanceof Backbone.Model ||
            model instanceof Backbone.Collection) {
            jqXHR = options.xhr;
        }

        switch (jqXHR.status) {
        case 401:
        case 403:
            api.getAuthenticatedUser(null, function(user) {
                user = new UserModel(user);
                if (!user) {
                    // XXX: hash routing
                    vent.trigger('login', window.location.hash);
                } else if (user.get('password_reset_required')) {
                    vent.trigger('login:password_change', user,
                                 window.location.hash);
                } else {
                    view.options = _.defaults(view.options, {
                        title: 'Unauthorized',
                        message: 'You don\'t have permission to do that.'
                    });
                    render();
                }
            });
            return;
        case 404:
            view.options = _.defaults(view.options, {
                title: 'Not found',
                message: 'Cannot find the item you\'re trying to view.'
            });
            break;
        }
        render();
    }
});
export default ErrorView;

import React from 'react'
import { PieChart, Pie, Cell, BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import Tooltip from 'js/react_views/tooltip/tooltip'

import style from './insights.css'


// todo: estos colores son los que sse quieren?
const COLORS = ['#00dba8', '#9200ff', '#ff01ff', '#00d56e', '#03bff2', '#4060f5'];
const NO_VALUE_COLOR = '#636363';

class BarChartComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            size: _.clone(props.size),
            data: props.data || []
        };
    }

    setSize(size) {
        this.setState({
            size: _.clone(size)
        });
    }

    setData(data) {
        this.setState({
            data: data
        });
    }

    render() {
        const renderLabel = (label) => {
            const x = label.x + label.width + 10;
            const y = label.y + label.height - 24;

            return (
                <text
                    x={x}
                    y={y}
                    height={label.height}
                    fontSize={20}
                    fill='#4e5367'
                    className="recharts-text recharts-label"
                    textAnchor='start'
                >
                    <tspan
                    >
                        {label.value}
                    </tspan>
                </text>
            );
        }

        return (
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={this.state.size[0]}
                    height={this.state.size[1]}
                    data={this.state.data}
                    layout='vertical'
                >
                    <XAxis type='number' hide={true}/>
                    <YAxis
                        type='category'
                        dataKey='name'
                        width={70}
                        tickLine={false}
                        axisLine={{
                            stroke: '#ededed'
                        }}
                    />

                    <Bar
                        dataKey='total'
                        isAnimationActive={false}
                        label={{position: 'right', content: renderLabel}}
                        style={{cursor: 'pointer'}}
                        onClick={(bar) => this.props.onItemClick(bar)}
                    >
                        {this.state.data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={this.props.getColor(entry, index)}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

class PieChartComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            size: _.clone(props.size),
            data: props.data || []
        };
    }

    setSize(size) {
        this.setState({
            size: _.clone(size)
        });
    }

    setData(data) {
        this.setState({
            data: data
        });
    }

    render() {
        return (
            <PieChart
                width={this.state.size[0]}
                height={this.state.size[1]}
            >
                <Pie
                    data={this.state.data}
                    cx='50%'
                    cy='50%'
                    innerRadius='70%'
                    outerRadius='100%'
                    fill='#8884d8'
                    dataKey='total'
                    isAnimationActive={false}
                    onMouseEnter={(pie) => {this.props.onMouseEnter(pie)}}
                    onMouseLeave={() => this.props.onMouseLeave()}
                    onClick={(pie) => this.props.onItemClick(pie)}
                    style={{cursor: 'pointer'}}
                >
                    {this.state.data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={this.props.getColor(entry, index)}
                        />
                    ))}
                </Pie>
            </PieChart>
        );
    }
}

export default class Insights extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            graphSize: null,
            data: [],
            fetchingData: false,
            activePie: null,
            pieInfoBB: null
        };

        this.chartType = props.widget.params.chart_type || 'pie';

        const self = this;

        this.props.widget.addEventListener('dirty', function() {
            _.defer(function() {
                self.onDirty();
            });
        });

        this.props.widget.addEventListener('refresh', function() {
            self.fetchData(true);
        });

        _.defer(function() {
            self.fetchData();
        });
    }

    componentDidMount() {
        this.onDirty();
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onDirty() {
        if (!this.content) {
            return;
        }

        const bbox = this.content.getBoundingClientRect();
        const padding = parseInt($(this.content).css('padding-top')) || 0;
        const padding2 = padding * 2;
        let graphSize = [];

        if (this.chartType === 'pie') {
            graphSize = [Math.floor((bbox.width - padding2) / 2), Math.floor(bbox.height - padding2)];
        } else {
            graphSize = [Math.floor(bbox.width - padding2), Math.floor(bbox.height - padding2)];
        }

        this.setState({
            graphSize: graphSize,
            pieInfoBB: null
        });

        if (this.chart) {
            this.chart.setSize(graphSize);
        }

        const self = this;

        _.defer(function() {
            const gbbox = self.graph.getBoundingClientRect();

            self.setState({
                pieInfoBB: {
                    left: 0,
                    top: 0,
                    width: gbbox.width,
                    height: gbbox.height
                }
            });
        });
    }

    fetchData(force) {
        if (!this.mounted) {
            return;
        }

        const query = this.props.widget.params.query;

        if (!query) {
            return;
        }

        // only displays the loader where there is not data
        if (this.state.data.length === 0) {
            this.setState({
                fetchingData: true
            });
        }

        let options = _.clone(query.options || {});
        options.force = force;

        this.props.widget.fetcher.get(query.url, query.args, this.onData.bind(this), options);
    }

    onData(data) {
        if (!this.mounted) {
            return;
        }

        this.total = 0;

        for (const item of data) {
            this.total += item.total;
        }

        this.setState({
            data: data,
            fetchingData: false
        });

        if (this.chart) {
            this.chart.setData(data);
        }
    }

    getColor(entry, index) {
        if (entry.isNoValueGroup) {
            return NO_VALUE_COLOR;
        }

        const params = this.props.widget.params;

        if (params.colors) {
            return params.colors[index % params.colors.length];
        }

        if (params.color_by_type) {
            if (entry.name in params.color_by_type) {
                return params.color_by_type[entry.name];
            }

            if (params.color_by_type.__default__) {
                return params.color_by_type.__default__;
            }

            return NO_VALUE_COLOR;
        }


        return COLORS[index % COLORS.length];
    }

    onItemClick(item) {
        const params = this.props.widget.params;

        if (!params.link && !params.link_by_type) {
            return;
        }

        if (params.link) {
            let linkParams = _.clone(params.link.params);

            linkParams.item = item;

            this.props.widget.triggerEvent(params.link.event, linkParams);
        } else {
            const linkByType = params.link_by_type[item.name];

            if (linkByType) {
                this.props.widget.triggerEvent(linkByType.event, linkByType.params);
            }
        }
    }

    render() {
        const params = this.props.widget.params;
        // todo: add loading indicator

        return (
            <div
                className={style.insights}
            >
                <div className={style.title}>
                    <div className={style.tContainer}>
                        <div>{params.title}</div>
                    </div>
                </div>

                <div
                    ref={(el) => this.content = el}
                    className={style.content}
                >
                    <div
                        ref={(el) => this.graph = el}
                        className={`
                            ${style.cGraph}
                            ${this.chartType === 'bar' ? style.fullSize : ''}
                        `}
                    >
                        {this.state.graphSize && this.chartType === 'pie' &&
                            <PieChartComponent
                                ref={(el) => this.chart = el}
                                size={this.state.graphSize}
                                getColor={this.getColor.bind(this)}
                                onItemClick={this.onItemClick.bind(this)}
                                onMouseEnter={(pie) => {this.setState({activePie: {name: pie.name, value: pie.total}})}}
                                onMouseLeave={() => this.setState({activePie: null})}
                            />
                        }

                        {this.state.graphSize && this.chartType === 'bar' &&
                            <BarChartComponent
                                ref={(el) => this.chart = el}
                                size={this.state.graphSize}
                                getColor={this.getColor.bind(this)}
                                onItemClick={this.onItemClick.bind(this)}
                            />
                        }

                        {this.chartType === 'pie' && this.state.activePie && this.state.pieInfoBB &&
                            <div
                                className={style.cActivePieInfo}
                                style={this.state.pieInfoBB}
                            >
                                <div
                                    style={{fontSize: this.state.pieInfoBB.height / 8}}
                                >
                                    {this.state.activePie.value}
                                </div>

                                <div
                                    style={{fontSize: this.state.pieInfoBB.height / 14}}
                                >
                                    {this.state.activePie.name}
                                </div>
                            </div>
                        }
                    </div>

                    {this.chartType === 'pie' &&
                        <div className={style.cList}>
                            {this.state.data.map((item, iidx) => {
                                return (
                                    <div
                                        className={style.lItem}
                                        key={`item_${iidx}`}
                                        onClick={() => this.onItemClick.bind(this)(item)}
                                        onMouseEnter={() => {this.setState({activePie: {name: item.name, value: item.total}})}}
                                        onMouseLeave={() => this.setState({activePie: null})}
                                    >
                                        <div
                                            className={style.iColorkey}
                                            style={{background: this.getColor(item, iidx)}}
                                        />
                                        <div
                                            className={style.iName}
                                        >
                                            <Tooltip
                                                title={item.name}
                                            />
                                            {item.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}
import $ from 'jquery'
import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'
import React from 'react';
import ReactDOM from 'react-dom';

import style from './campaign-finder.css'


class ReactFinder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            byEmail: true,
            bySms: true
        };

        this.doSearch = _.debounce(this.doSearch, 300);
    }

    handleSearchTermChange(ev) {
        this.setState({
            searchTerm: ev.target.value
        });

        this.doSearch(ev.target.value);
    }

    handleByEmailChange(ev) {
        this.setState({
            byEmail: ev.target.checked
        });

        this.props.onChange(this.state.searchTerm, ev.target.checked, this.state.bySms);
    }

    handleBySmsChange(ev) {
        this.setState({
            bySms: ev.target.checked
        });

        this.props.onChange(this.state.searchTerm, this.state.byEmail, ev.target.checked);
    }

    doSearch(term) {
        this.props.onChange(term, this.state.byEmail, this.state.bySms);
    }

    render() {
        return (
            <div className={style.campaignFinder}>
                <input
                    className={style.textInput}
                    type='text'
                    placeholder='Type to search...'
                    value={this.state.searchTerm}
                    onChange={this.handleSearchTermChange.bind(this)}
                />
                <label htmlFor='byEmail' className={style.checkBoxLabel}>
                    <div className={style.checkBoxContainer}>
                        <input
                            type='checkbox'
                            name='byEmail'
                            id='byEmail'
                            checked={this.state.byEmail}
                            onChange={this.handleByEmailChange.bind(this)}
                        />
                        <div className={style.checkBox}>
                            <div className={style.slider}/>
                        </div>
                    </div>
                    Email
                </label>
               <label htmlFor='bySms' className={style.checkBoxLabel} style={{marginLeft: '30px'}}>
                    <div className={style.checkBoxContainer}>
                        <input
                            type='checkbox'
                            name='bySms'
                            id='bySms'
                            checked={this.state.bySms}
                            onChange={this.handleBySmsChange.bind(this)}
                        />
                        <div className={style.checkBox}>
                            <div className={style.slider}/>
                        </div>
                    </div>
                    SMS
                </label>
            </div>
        );
    }
}


export default Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onRender: function() {
        this.renderReact();
    },
    handleFilterChange: function(searchTerm, byEmail, bySms) {
        var filterOptions = {
            filter_by_email: byEmail,
            filter_by_sms: bySms
        };

        // special case: if both filters are false, we set then to true
        if (!filterOptions.filter_by_email && !filterOptions.filter_by_sms) {
            filterOptions.filter_by_email = true;
            filterOptions.filter_by_sms = true;
        }

        if (searchTerm) {
            filterOptions.filter_by_text = searchTerm;
        }

        this.trigger('update-filters', filterOptions);
    },
    renderReact: function() {
        ReactDOM.render(
            <ReactFinder
                onChange={this.handleFilterChange.bind(this)}
            />
            , this.$el.get(0)
        );
    }
});
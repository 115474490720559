import _ from 'underscore'
import $ from 'jquery'
import Marionette from 'Backbone.Marionette'
import Handlebars from 'handlebars'

import template from 'templates/groups_selector.handlebars'


export default Marionette.Layout.extend({
    className: 'campaign-selection-modal scrolling-modal-inner at-top',
    template: Handlebars.compile(template),

    ui: {
        header: '.modal-header',
        subheader: '.modal-nav',
        footer: '.modal-footer',
        scroll: '.modal-scroll'
    },

    events: {
        'click .add-group': function(ev) {
            ev.preventDefault();
            var id = ev.currentTarget.id;

            if (_.contains(this.selectedGroups, id)) {
                return;
            }

            this.selectedGroups.push(id);
            this.buildGroupsInfo();
            this.render();
        },

        'click .select-all': function(ev) {
            ev.preventDefault();

            if (this.groups.length === this.selectedGroups.length) {
                return;
            }

            for (var i = 0; i < this.groups.length; ++i) {
                var g = this.groups[i];

                if (g.available) {
                    this.selectedGroups.push(g.id);
                }
            }

            this.buildGroupsInfo();
            this.render();
        },

        'click .remove-group': function(ev) {
            ev.preventDefault();
            var idx = $.inArray(ev.currentTarget.id, this.selectedGroups);

            this.selectedGroups.splice(idx, 1);
            this.buildGroupsInfo();
            this.render();
        },

        'click .remove-all': function(ev) {
            ev.preventDefault();

            if (!this.selectedGroups.length) {
                return;
            }

            this.selectedGroups = [];
            this.buildGroupsInfo();
            this.render();
        },

        'click .cancel': function(ev) {
            ev.preventDefault();
            this.trigger('close');
        },

        'click .save': function(ev) {
            ev.preventDefault();
            this.trigger('save', this.selectedGroups);
        }
    },

    templateHelpers: function() {
        return {
            groups: this.groups
        };
    },

    initialize: function() {
        this.groups = [];
        this.allGroups = {};
        this.selectedGroups = [];
    },

    onRender: function() {
        // Init scrollbar
        this.scrollbar();
        // Kill binding because view gets rerendered
        this.ui.scroll.off('scroll', this.scrollEvents.bind(this));
        this.ui.scroll.scroll(this.scrollEvents.bind(this));
    },

    onShow: function() {
        // Currently, the only way to target the parent .modal element - region.$el
        this.$el.parent().addClass('scrolling-modal');

        // Update scrollbar
        this.scrollbar();

        this.resizeCallback = function () {
            this.scrollbar();
        }.bind(this);
        $(window).on('resize', this.resizeCallback);
    },

    onBeforeClose: function () {
        $(window).off('resize', this.resizeCallback);
    },

    setGroups: function(allGroups, selectedGroups) {
        this.allGroups = _.extend({}, allGroups);
        this.selectedGroups = selectedGroups.slice(0);

        this.buildGroupsInfo();
    },

    buildGroupsInfo: function() {
        this.groups = [];
        var keys = _.keys(this.allGroups);
        var c = 0;

        for (var i = 0; i < keys.length; ++i) {
            var k = keys[i];

            var d = {
                name: this.allGroups[k].name,
                id: k,
                available: $.inArray(k, this.selectedGroups) === -1
            };

            if (c < this.selectedGroups.length) {
                var kc = this.selectedGroups[c];

                d.selected_available = true;
                d.selected_name = this.allGroups[kc].name;
                d.selected_id = kc;
            }

            this.groups.push(d);

            ++c;
        }
    },

    scrollbar: _.debounce(function() {
        if (this.isClosed || !this.ui.scroll.length) {
            return;
        }

        var container = this.ui.scroll.parent('.content-container'),
            availableHeight = Math.round(this.$el.height() - (this.ui.header.height()*2) - this.ui.footer.height()),
            contentHeight = Math.ceil(container.find('.content-inner:first').outerHeight(true));
            // height = Math.min(availableHeight, contentHeight);

        // container.find('.content-inner:first').css('minHeight', contentHeight);

        // NOTE: Height needs to be hard-coded in CSS until we can update the lists without just rerendering the
        // whole view - which causes flickering before list is given height below...
        // container.css('height', height);
        this.$el.toggleClass('has-sticky-nav', (contentHeight > availableHeight));

        container.nanoScroller();
        this.scrollEvents();
    }, 100),

    scrollEvents: function() {
        var container = this.ui.scroll;

        if (container.scrollTop() <= 0) {
            this.$el.addClass('at-top');
        }
        else if (container.scrollTop() + container.innerHeight() >= container.prop('scrollHeight')) {
            this.$el.addClass('at-bottom');
        }
        else {
            this.$el.removeClass('at-top at-bottom');
        }
    }

});

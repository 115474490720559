import React from 'react'
import ReactDOM from 'react-dom'

import app from 'js/app';
import vent from 'js/vent';
import TextManager from 'app/text-manager'
import OpportunitiesCollection from 'js/collections/opportunities'
import OpportunityFilterModel from 'js/models/opportunity_filter'

import style from './yourkeys-iframe.css'

class YourkeysIframeData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: null,
        }

        this.eventListenerAdded = false;
    }

    componentDidMount() {
        const self = this;
        const userPreferences = app.user.get('preferences') || {};
        const sellerReference = userPreferences.yourkeys_seller_reference;

        if ( !sellerReference ) {
            vent.trigger('alert:show', {
                type: function() {
                    return {
                        message: 'Missing seller reference. Please contact support',
                        classes: 'load-error error',
                        timer: 3000
                    };
                }
            });

            return;
        }

        this.loadPlotOrDevelopmentSettingsUrl(sellerReference, function(getUrl) {
            $.get(getUrl,
            function(response) {
                self.setState({ url: response.url })
            })
            .fail(function(error) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Unable to get yourkeys data. Please contact support.',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });
            });
        });

        this.addEventListener();
    }

    loadPlotOrDevelopmentSettingsUrl(sellerReference, callback) {
        let url = '';

        if (this.props.loadDevelopment) {
            const funnelIntegrationData = this.props.model.get('integration_data') || {};
            const developmentReference = funnelIntegrationData.developmentReference;

            url = `/yourkeys_iframe_data?seller_reference=${sellerReference}&development_reference=${developmentReference}`;

            callback(url);
            return;
        } else {
            const dealCustomFields = app.globalData.customFieldsInfo.deals;
            let plotCfId = null;
            let plotReference = null;

            for (var cfId in dealCustomFields) {
                if (dealCustomFields[cfId].name.toLowerCase() === 'plotid') {
                    plotCfId = cfId;

                    break;
                }
            }

            if ( !plotCfId ) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Missing Custom Field PlotId.',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });

                callback(null);
                return;
            }

            if ( !this.props.model.get('custom_fields')[plotCfId] ) {
                vent.trigger('alert:show', {
                    type: function() {
                        return {
                            message: 'Missing Plot Id.',
                            classes: 'load-error error',
                            timer: 3000
                        };
                    }
                });

                callback(null);
                return;
            } else {
                plotReference = this.props.model.get('custom_fields')[plotCfId];
            }

            url = `/yourkeys_iframe_data?seller_reference=${sellerReference}&plot_reference=${plotReference}`;
        }

        if (['reserved', 'exchanged', 'completed'].indexOf(this.props.model.get('phase_name').toLowerCase()) !== -1) {
            const reservationCf = app.globalData.customFieldsInfo.dealsArray.find(d => d.name.toLowerCase() === 'reservation');
            const reservationId = this.props.model.get('custom_fields')[reservationCf.id];

            if (reservationId) {
                const purchaseReferenceCf = app.globalData.customFieldsInfo.dealsArray.find(d => d.name.toLowerCase() === 'purchase reference');

                $.get(`/opportunities/${reservationId}`, function(reservation) {
                    const purchaseReference = reservation.custom_fields[purchaseReferenceCf.id];

                    if (purchaseReference) {
                        url += `&purchase_reference=${purchaseReference}`;
                    }

                    callback(url);
                });
            } else {
                callback(null);
            }
        } else {
            callback(url);
        }
    }

    addEventListener() {
        const yourkeysExtraData = app.globalData.yourkeysExtraData || {};

        if ( ! yourkeysExtraData.yourkeys_client_domain_name) {
            return;
        }

        var self = this;
        this.eventListenerAdded = true;

        /*
            Declaring the function here was the only workaround
            to be able to use self and call functions from the react component.

            It is declared using this.listenerFunction -> so we can 
            later remove the event listener in componentWillUnmount()
        */
        this.listenerFunction = function (event) {
            const yourkeysExtraData = app.globalData.yourkeysExtraData || {};
            const url = `https://${yourkeysExtraData.yourkeys_client_domain_name}`;

            if (event.origin !== url) {
                // Message not from yourkeys
                return;
            }

            var data = JSON.parse(event.data);

            if (data.action === 'cancelReservation') {
                self.handleCloseButtonClick();
            } else if (data.action === 'plotTransfer') {
                self.redirectOpportunityAfterPlotTransfer(data.payload.newPlotId);
            }
        }

        window.addEventListener("message", this.listenerFunction);
    }

    redirectOpportunityAfterPlotTransfer(plotId) {
        const self = this;
        const cfs = app.globalData.customFieldsInfo.deals;
        let plotIdCfId = null;

        for (const cfId in cfs) {
            if (cfs[cfId].name.toLowerCase() === 'plotid') {
                plotIdCfId = cfId;
                break;
            }
        }

        var opportunityFilterModel = new OpportunityFilterModel();
        opportunityFilterModel.save(
            {
                rules: [[{
                    field: 'opportunity_custom',
                    operator: 'equal',
                    custom: plotIdCfId,
                    values: plotId
                }]]
            },
            {
                alert: false,
                success: function (data) {
                    const filterId = data.id;
                    let opportunities = new OpportunitiesCollection();

                    opportunities.fetch({
                        rows: 1,
                        data: {
                            filter_id: filterId
                        },
                        success: function(collection) {
                            const models = collection.models;
                            if (models.length === 0) {
                                self.props.onClose();
                                return;
                            }
                            const modelId = collection.models[0].get('id');

                            window.location = `/#deals/${modelId}`;
                        }
                    });
                }
            }
        );
    }

    componentWillUnmount() {
        if (this.eventListenerAdded) {
            window.removeEventListener('message', this.listenerFunction);
        }
    }

    handleCloseButtonClick() {
        if (this.props.onCloseButtonClick) {
            this.props.onCloseButtonClick();
        } else {
            this.props.onClose();
        }
    }

    getModalTitle() {
        var title = '';

        if (this.props.loadDevelopment) {
            var funnelText = TextManager.parseText('${ID_FUNNEL, capitalize}');

            title = `${funnelText} settings: ${this.props.model.get('name')}`;
        } else {
            title = `Sales progression: ${this.props.model.get('name')}`;
        }

        return title;
    }

    render() {
        const { url } = this.state;
        const title = this.getModalTitle();
        let displayIFrame = false;

        if (url) {
            displayIFrame = true;
        }

        const iFrameProperties = {
            src: url,
            class: style.Iframe,
            allow: 'camera; microphone'
        };

        let propertyString = '';

        for (let prop in iFrameProperties) {
            if(Object.prototype.hasOwnProperty.call(iFrameProperties, prop)) {
                propertyString += ` ${prop} = "${iFrameProperties[prop]}"`;
            }
        }

        const iFrameHtml = {__html: `<iframe ${propertyString}></iframe>`};

        return (
            <div className={style.yourkeysIframeData}>
                <div className={style.header}>
                    <div
                        className={style.closeButton}
                        onClick={this.handleCloseButtonClick.bind(this)}
                        >
                        Close
                    </div>

                    <div className={style.title}>{title}</div>
                </div>

                {displayIFrame &&
                    <div
                        style={{height: 'calc(100% - 25px)'}}
                        dangerouslySetInnerHTML={iFrameHtml}
                    />
                }
            </div>
        )
    }
}

const YourkeysIframeDataView = Marionette.Layout.extend({
    template: Handlebars.compile(''),
    onShow: function() {
        this.$el.css('height', '100%');
        this.$el.parent().attr('id', 'yourkeys-iframe');
    },
    onRender: function() {
        /*
            this modal window has 2 use cases:
                1. Open from plot/deal/opportunity
                2. Open from funnel/development
            
            if 1. this.options.loadDevelopment = false and this.options.model is OpportunityModel
            if 2. this.options.loadDevelopment = true and this.options.model is FunnelModel
        */
        ReactDOM.render(
            <YourkeysIframeData
                model={this.options.model}
                onClose={() => this.trigger('close')}
                onCloseButtonClick={this.options.onCloseButtonClick}
                loadDevelopment={this.options.loadDevelopment}
            />,
            this.$el.get(0)
        );
    },
    onBeforeClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.get(0));
    }
});

export default YourkeysIframeDataView;
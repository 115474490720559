import React from 'react';
import ReactDOM from 'react-dom';
import BaseView from 'js/views/base/base';
import { Section, SidebarList } from 'js/react_views/section/section';
import { NewSelect } from 'js/react_views/widgets/select';
import IndividualModel from 'js/models/contact';
import MessageBox from 'js/views/message_box';
import guid from 'js/utils/guid';
import dateFormat from 'js/utils/date-format';
import DateTimePicker from 'js/widgets/date-time-picker'
import Utilities from 'js/utils/utilities';
import LibraryBrowser from 'js/react_views/library-browser/browser';

import style from './portal.css';


const SIDEBAR_OPTIONS = [{
    id: 'banner',
    title: 'Banner Message',
    icon: 'icon-list'
}, {
    id: 'contacts',
    title: 'Useful Contacts',
    icon: 'icon-list'
}, {
    id: 'documents',
    title: 'Document Management',
    icon: 'icon-list'
}];


class ContentHeader extends React.Component {
    render() {
        return (
            <div className={style.cHeader}>
                {this.props.onBack &&
                    <div
                        className={`${style.hBack} icon-arrow-left`}
                        onClick={this.props.onBack}
                    />
                }

                <div className={style.hTitle}>{this.props.title}</div>
            </div>
        );
    }
}

class SectionHeader extends React.Component {
    render() {
        return (
            <div className={style.sectionHeader}>
                <div className={style.shTitle}>{this.props.title}</div>

                {this.props.onNewButtonClicked &&
                    <div
                        className={style.shButton}
                        onClick={this.props.onNewButtonClicked}
                    >
                        {this.props.newButtonText || 'New'}
                    </div>
                }
            </div>
        );
    }
}

class Bulletin extends React.Component {
    render() {
        return (
            <div className={style.bulletin}>
                {this.props.title &&
                    <div className={style.bTitle}>
                        {this.props.title}
                    </div>
                }

                <div className={style.bDesc}>{this.props.description}</div>
            </div>
        );
    }
}

class SelectField extends React.Component {
    render() {
        return (
            <div
                className={`
                    ${style.selectField}
                    ${this.props.error ? style.sfError : ''}
                `}
            >
                <NewSelect
                    url={this.props.url}
                    value={this.props.value}
                    options={this.props.options || {}}
                    onSelect={(items) => this.props.onChange(items[0])}
                />

                {this.props.error &&
                    <div className={style.tfErrorMessage}>{this.props.error}</div>
                }
            </div>
        );
    }
}

class DatetimeField extends React.Component {
    handleFocus() {
        const el = $(this.input);

        let date = '';
        let time = '11:00';

        if (this.props.value) {
            date = dateFormat.parseDate(this.props.value);
            time = `${date.getHours()}:${date.getMinutes()}`;
        }

        const dateTimePicker = new DateTimePicker({
            altField: el,
            date: date,
            time: time,
            noClearOnExit: true,
            css: {
                left: el.offset().left - 325,
                top: el.offset().top + el.height() + 10
            }
        });

        dateTimePicker.showPicker();
    }

    render() {
        const date = this.props.value ? dateFormat.shortFormatWithYearTime(dateFormat.parseDate(this.props.value)) : '';

        return (
            <div className={style.datetimeField}>
                <input
                    ref={(el) => this.input = el}
                    placeholder={this.props.placeholder || ''}
                    value={date}
                    onFocus={this.handleFocus.bind(this)}
                    onChange={() => {}} // this is to avoid a warning
                />
            </div>
        );
    }
}

class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || ''
        };
    }

    handleChange(ev) {
        this.setState({
            value: ev.target.value
        });

        this.props.onChange(ev.target.value);
    }

    render() {
        return (
            <div
                className={`
                    ${style.textField}
                    ${this.props.error ? style.tfError : ''}
                `}
            >
                <input
                    placeholder={this.props.placeholder || ''}
                    value={this.state.value}
                    onChange={this.handleChange.bind(this)}
                />

                {this.props.error &&
                    <div className={style.tfErrorMessage}>{this.props.error}</div>
                }
            </div>
        );
    }
}

class RichTextField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        const editor = $(this.editor);
        const self = this;

        editor.tinymce({
            promotion: false,
            branding: false,
            license_key: 'gpl',
            skin: 'oxide',
            plugins: 'link lists',
            menubar: '',
            toolbar: 'bold italic underline bullist numlist link forecolor',
            height: 200,
            resize: false,
            statusbar: false,
            content_style: 'p { margin: 0; } body { font-family: "proxima-nova","Helvetica","Arial",sans-serif !important; font-size: 14px !important;  color: #666666 !important; }',
            setup: function(editor) {
                editor.on('init', function() {
                    editor.getContainer().className += ` ${style.tmcEditor}`;
                    editor.setContent(self.props.value || '');
                    self.setState({visible: true});
                });
            },
            oninit: function() {
                this.activeEditor.on('change', function() {
                    self.props.onChange(this.getContent());
                });
            }
        });
    }

    render() {
        return (
            <div className={style.richTextField}>
                <div
                    ref={(el) => this.parent = el}
                    className={`
                        ${style.rtfContainer}
                        ${this.props.error ? style.rtfError : ''}
                        ${this.state.visible ? style.rtfVisible : ''}
                    `}
                >
                    <div ref={(el) => this.editor = el}/>
                </div>

                {this.props.error &&
                    <div className={style.rtfErrorMessage}>{this.props.error}</div>
                }
            </div>
        );
    }
}

class FileUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dragOver: false
        };
    }

    handleFileDrop(ev) {
        ev.stopPropagation();
        ev.preventDefault();

        this.props.onFiles(ev.dataTransfer.files);

        this.setState({
            dragOver: false
        });
    }

    handleDragLeave(ev) {
        if (ev.currentTarget.contains(ev.relatedTarget)) {
            return;
        }

        this.setState({
            dragOver: false
        });
    }

    handleUploadFileClick() {
        $(this.fileSelectInput).click();
    }

    handleFileSelectDialog() {
        this.props.onFiles(this.fileSelectInput.files);
    }

    render() {
        return (
            <div
                className={style.fileUpload}
                onDragEnter={() => this.setState({dragOver: true})}
                onDragLeave={this.handleDragLeave.bind(this)}
                onDragOver={(ev) => ev.preventDefault()}
                onDrop={this.handleFileDrop.bind(this)}
            >
                <div>Drag & drop files here</div>
                <div style={{color: 'grey', margin: '0 12px'}}>or</div>
                <div
                    className={style.fuLink}
                    onClick={this.handleUploadFileClick.bind(this)}
                >
                    Upload from your Computer
                </div>

                <input
                    ref={(input) => {this.fileSelectInput = input}}
                    onChange={this.handleFileSelectDialog.bind(this)}
                    style={{display: 'none'}}
                    type='file'
                    multiple
                />

                {this.state.dragOver &&
                    <div className={style.fuOverlay}/>
                }
            </div>
        );
    }
}

class EditUsefulContactModal extends React.Component {
    constructor(props) {
        super(props);

        this.title = this.props.title || '';
        this.contact = this.props.contact || null;
        this.description = this.props.description || '';
        this.isNew = !this.title && !this.contact && !this.description;

        this.state = {
            errors: {}
        };
    }

    handleTitle(value) {
        this.title = value;
    }

    handleContact(contact) {
        this.contact = contact;
    }

    handleDescription(value) {
        this.description = value;
    }

    handleSave() {
        let errors = {};

        if (!this.title) {
            errors.title = 'Please enter a title';
        }

        if (!this.contact) {
            errors.contact = 'Please select a contact';
        }

        this.setState({
            errors: errors
        });

        if (_.isEmpty(errors)) {
            this.props.onSave(this.title, this.contact, this.description);
        }
    }

    handleClose() {
        this.props.onClose();
    }

    render() {
        return (
            <div className={style.dialog}>
                <div className={style.dHeader}>
                    <div
                        className={style.dhClose}
                        onClick={this.handleClose.bind(this)}
                    >
                        Close
                    </div>
                    <div className={style.dhTitle}>{`${this.isNew ? 'New' : 'Edit'} Useful Contact`}</div>
                </div>

                <div className={style.dContent}>
                    <div className={style.dcRow}>
                        <div className={style.rTitle}>Title</div>
                        <div className={style.rField}>
                            <TextField
                                placeholder='Type contact title'
                                value={this.title}
                                error={this.state.errors.title}
                                onChange={this.handleTitle.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={style.dcRow}>
                        <div className={style.rTitle}>Useful contact</div>
                        <div className={style.rField}>
                            <SelectField
                                url='/individuals'
                                value={this.contact}
                                error={this.state.errors.contact}
                                options={{minimumInputLength: 1}}
                                onChange={this.handleContact.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={style.dcRow}>
                        <div className={style.rTitle}>Description</div>
                        <div className={style.rField}>
                            <TextField
                                placeholder='Type description'
                                value={this.description}
                                onChange={this.handleDescription.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={style.dcFooter}>
                        <div
                            className={style.fButton}
                            onClick={this.handleSave.bind(this)}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={style.sidebar}>
                <div className={style.header}>
                    <div className={style.title}>Admin</div>
                </div>

                <div className={style.info}>
                    <div className={style.iconContainer}>
                        <div className='icon-globe'/>
                    </div>

                    <div className={style.iName}>{this.props.funnelName}</div>

                    <div className={`${style.iStatus} ${style.live}`}>Live</div>
                </div>

                <div className={style.manage}>
                    <div className={style.mTitle}>Manage</div>

                    <div style={{marginTop: '10px'}}>
                        <SidebarList
                            items={this.props.items}
                            itemSelected={this.props.itemSelected}
                            onItemSelected={this.props.onItemSelected}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

class Button extends React.Component {
    render() {
        return (
            <div
                className={style.button}
                onClick={this.props.onClick}
            >
                {this.props.title}
            </div>
        );
    }
}

class BannerStatus extends React.Component {
    render() {
        let status = this.props.status === 'published' ? 'Published' : 'Unpublished';

        return (
            <div
                className={`
                    ${style.bannerStatus}
                    ${this.props.status === 'published' ? style.bsPublished : ''}
                `}
            >
                {status}
            </div>
        );
    }
}

class Table extends React.Component {
    constructor(props) {
        super(props);

        this.columns = _.clone(this.props.columns);

        if (this.props.sortable) {
            this.columns.unshift({
                id: 'handler',
                type: 'handler',
                width: '10px'
            });
        }
    }

    componentDidMount() {
        if (!this.props.sortable) {
            return;
        }

        const self = this;

        _.defer(function() {
            const el = $(self.rowsEl);

            el.sortable({
                axis: 'y',
                containment: el,
                scroll: false,
                tolerance: 'pointer',
                handle: `.${style.cHandler}`,
                start: function(ev, ui) {
                    $(ui.item).addClass(style.rDragged);
                },
                stop: function(ev, ui) {
                    $(ui.item).removeClass(style.rDragged);

                    let itemsSorted = [];

                    for (const item of el.children()) {
                        const id = $(item).attr('id');
                        itemsSorted.push(self.props.rows.find(i => i.id === id));
                    }

                    self.props.onItemsSorted(itemsSorted);
                }
            });
        });
    }

    componentWillUnmount() {
        if (!this.props.sortable) {
            return;
        }

        $(this.rowsEl).sortable('destroy');
    }

    getCellContent(column, row, isStaticRow) {
        switch (column.type) {
            case 'icon':
                return (
                    <div className={`
                        ${style.cIcon}
                        ${row[column.id]}
                    `}/>
                );

            case 'text':
                return (
                    <div className={style.cText}>{row[column.id]}</div>
                );

            case 'htmlText':
                return (
                    <div className={style.cText}>{Utilities.getTextFromHTMLContent(row[column.id])}</div>
                );

            case 'date':
                return (
                    <div className={style.cText}>{row[column.id] ? dateFormat.shortFormatWithYear(row[column.id]) : ''}</div>
                );

            case 'bannerStatus':
                return (
                    <BannerStatus
                        status={row[column.id]}
                    />
                );

            case 'link': {
                const url = row[`${column.id}Link`];

                return (
                    <div
                        className={style.cLink}
                        onClick={() => {if (url) {window.open(url, '_blank')}}}
                    >
                        {row[column.id]}
                    </div>
                );
            }

            case 'handler':
                if (isStaticRow) {
                    return null;
                }

                return (
                    <div className={style.cHandler}/>
                );

            case 'image': {
                const url = row[column.id];

                if (url) {
                    return (
                        <div
                            className={style.cThumbnail}
                            style={{
                                backgroundImage: "url('" + url + "')"
                            }}
                        />
                    );
                }

                return (
                    <div className={style.cEmptyThumbnail}>
                        <div className={`${style.cetIcon} icon-user`}/>
                    </div>
                );
            };

            case 'actions': {
                if (isStaticRow) {
                    return null;
                }

                return (
                    <div className={style.cActions}>
                        {(!column.actions || column.actions.edit) &&
                            <div
                                className={`${style.aItem} ${style.aIcon} icon-pencil`}
                                onClick={(ev) => {ev.stopPropagation(); this.props.onAction('edit', row)}}
                            />
                        }

                        {(!column.actions || column.actions.delete) &&
                            <div
                                className={`${style.aItem} ${style.aDelete}`}
                                onClick={(ev) => {ev.stopPropagation(); this.props.onAction('delete', row)}}
                            >
                                <div/>
                            </div>
                        }
                    </div>
                );
            };
        }

        return null;
    }

    getCellClass(column) {
        if (column.type === 'image') {
            return style.cCentered;
        }

        if (column.align === 'right') {
            return style.cRightAligned;
        }

        return '';
    }

    handleRowClick(row) {
        if (this.props.rowsClickables) {
            this.props.onAction('click', row);
        }
    }

    render() {
        return (
            <div className={style.table}>
                <div className={style.tHeader}>
                    {this.columns.map((column, cidx) => {
                        return (
                            <div
                                className={`
                                    ${style.hColumn}
                                    ${column.align === 'right' ? style.cRightAligned : ''}
                                `}
                                key={`col_${cidx}`}
                                style={{width: column.width}}
                            >
                                {column.title &&
                                    <div className={style.cTitle}>{column.title}</div>
                                }
                            </div>
                        );
                    })}
                </div>

                {this.props.staticRows &&
                    <div className={style.tStaticRows}>
                        {this.props.staticRows.map((row, ridx) => {
                            return (
                                <div
                                    className={`
                                        ${style.rRow}
                                        ${this.props.rowsClickables ? style.rClickable : ''}
                                    `}
                                    key={`static_row_${ridx}`}
                                    onClick={() => this.handleRowClick.bind(this)(row)}
                                >
                                    {this.columns.map((column, cidx) => {
                                        return (
                                            <div
                                                className={`${style.rCell} ${this.getCellClass(column)}`}
                                                key={`static_cell_${ridx}_${cidx}`}
                                                style={{width: column.width}}
                                            >
                                                {this.getCellContent(column, row, true)}
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        })}
                    </div>
                }

                <div
                    className={style.tRows}
                    ref={(el) => this.rowsEl = el}
                >
                    {this.props.rows.map((row, ridx) => {
                        return (
                            <div
                                className={`
                                    ${style.rRow}
                                    ${this.props.rowsClickables ? style.rClickable : ''}
                                `}
                                id={row.id}
                                key={`row_${ridx}`}
                                onClick={() => this.handleRowClick.bind(this)(row)}
                            >
                                {this.columns.map((column, cidx) => {
                                    return (
                                        <div
                                            className={`${style.rCell} ${this.getCellClass(column)}`}
                                            key={`cell_${ridx}_${cidx}`}
                                            style={{width: column.width}}
                                        >
                                            {this.getCellContent(column, row)}
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

class EditBannerMessage extends React.Component {
    constructor(props) {
        super(props);

        this.statusItems = [{
            id: 'unpublished',
            title: 'Unpublished'
        }, {
            id: 'published',
            title: 'Published'
        }];

        const message = this.props.message || {};

        this.title = message.title || '';
        this.content = message.content || '';
        this.status = this.statusItems.find(i => i.id === message.status) || this.statusItems[0];
        this.startDate = message.startDate || '';
        this.endDate = message.endDate || '';

        this.state = {
            errors: {}
        };
    }

    handleTitle(value) {
        this.title = value;
    }

    handleContent(content) {
        this.content = content;
    }

    handleSave() {
        let errors = {};

        if (!this.title) {
            errors.title = 'Please enter a title';
        }

        if (!this.content) {
            errors.content = 'Please enter content';
        }

        this.setState({
            errors: errors
        });

        if (_.isEmpty(errors)) {
            this.props.onSave({
                title: this.title,
                content: this.content,
                status: this.status.id,
                startDate: this.startDateComponent.input.value ? new Date(this.startDateComponent.input.value) : null,
                endDate: this.endDateComponent.input.value ? new Date(this.endDateComponent.input.value) : null
            });
        }
    }

    render() {
        return (
            <div className={style.content}>
                <ContentHeader
                    title='New Banner Message'
                    onBack={this.props.onBack}
                />

                <div className={style.newBanner}>
                    <div className={style.nbLeft}>
                        <div
                            className={style.innerTitle}
                            style={{marginTop: '30px'}}
                        >
                            Banner Title (not visible)
                        </div>

                        <TextField
                            placeholder='Add a title'
                            value={this.title}
                            error={this.state.errors.title}
                            onChange={this.handleTitle.bind(this)}
                        />

                        <div
                            className={style.innerTitle}
                            style={{marginTop: '30px'}}
                        >
                            Banner Content
                        </div>

                        <RichTextField
                            value={this.content}
                            error={this.state.errors.content}
                            onChange={this.handleContent.bind(this)}
                        />
                    </div>

                    <div className={style.nbRight}>
                        <div className={`${style.innerTitle} ${style.itUnderline}`}>Publish</div>

                        <div className={style.nbFields}>
                            <div className={style.nbRow}>
                                <div className={style.nbTitle}>Status</div>

                                <div className={style.nbValue}>
                                    <NewSelect
                                        data={this.statusItems}
                                        value={this.status}
                                        onSelect={(items) => this.status = items[0]}
                                        width={150}
                                        options={{
                                            minimumInputLength: -1
                                        }}
                                    />
                                </div>
                            </div>

                            <div className={style.nbRow}>
                                <div className={style.nbTitle}>Start</div>
                                <DatetimeField
                                    ref={(el) => this.startDateComponent = el}
                                    value={this.startDate}
                                />
                            </div>

                            <div className={style.nbRow}>
                                <div className={style.nbTitle}>End</div>
                                <DatetimeField
                                    ref={(el) => this.endDateComponent = el}
                                    value={this.endDate}
                                />
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px'
                        }}>
                            <Button
                                title='Save'
                                onClick={this.handleSave.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class BannerMessageList extends React.Component {
    constructor(props) {
        super(props);

        this.tableColumns = [{
            id: 'title',
            type: 'text',
            title: 'Banner Title',
            width: '20%'
        }, {
            id: 'startDate',
            type: 'date',
            title: 'Start Date',
            width: '16%'
        }, {
            id: 'endDate',
            type: 'date',
            title: 'End Date',
            width: '16%'
        }, {
            id: 'content',
            type: 'htmlText',
            title: 'Message',
            width: '33%'
        }, {
            id: 'status',
            type: 'bannerStatus',
            title: 'Status',
            width: '15%',
            align: 'right'
        }, {
            id: 'actions',
            type: 'actions',
            width: '100px',
            align: 'right'
        }];

        this.state = {
            messages: this.props.messages
        };
    }

    handleTableAction(action, row) {
        if (action === 'edit') {
            this.props.onEditBanner(row);
        } else if (action === 'delete') {
            this.props.onDeleteBanner(row);
        }
    }

    setMessages(messages) {
        this.setState({messages: messages || []});
    }

    render() {
        return (
            <div className={style.content}>
                <ContentHeader
                    title='Banner Message'
                />

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '20px',
                    marginBottom: '30px',
                }}>
                    <Button
                        title='New'
                        onClick={this.props.onNewBanner}
                    />
                </div>

                <Table
                    columns={this.tableColumns}
                    rows={this.state.messages}
                    onAction={this.handleTableAction.bind(this)}
                />
            </div>
        );
    }
}

class BannerMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'list',
            messages: [],
            messageToEdit: null
        };
    }

    componentDidMount() {
        const self = this;

        $.get(`/funnels/${this.props.funnelId}`, function(result) {
            const portal = result.portal || {};
            self.setState({messages: portal.banner_messages});
            self.messagesList.setMessages(portal.banner_messages);
        });
    }

    handleNewBanner() {
        this.setState({
            view: 'edit'
        });
    }

    handleSaveBanner(data) {
        let postData = {
            path: 'banner_messages',
            data: data
        };

        // todo: show saving message?
        if (this.state.messageToEdit) {
            postData.method = 'update';
            postData.id = this.state.messageToEdit.id;
            _.extend(postData.data, {
                id: this.state.messageToEdit.id
            });
        } else {
            postData.method = 'add';
            postData.data = _.extend(postData.data, {
                id: guid()
            });
        }

        const self = this;

        $.post(`/funnels/${this.props.funnelId}/portal`, JSON.stringify(postData), function(result) {
            self.setState({
                view: 'list',
                messages: result.banner_messages,
                messageToEdit: null
            });
        });
    }

    handleEditBanner(banner) {
        this.setState({
            view: 'edit',
            messageToEdit: banner
        });
    }

    handleBackBanner(banner) {
        this.setState({
            view: 'list',
            messageToEdit: null
        });
    }

    handleDeleteBanner(banner) {
        const self = this;
        const mbContent = {
            icon: 'icon-trashcan',
            accept_is_negative: true,
            message: 'Are you sure you want to <strong>permanently</strong> delete the banner?'
        };

        MessageBox.showYesNo(mbContent, this.props.parent,
            function() { // Yes
                $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
                    path: 'banner_messages',
                    method: 'delete',
                    id: banner.id
                }), function(result) {
                    self.setState({messages: result.banner_messages});
                    self.messagesList.setMessages(result.banner_messages);
                });
            }
        );
    }

    render() {
        if (this.state.view === 'list') {
            return (
                <BannerMessageList
                    ref={(el) => this.messagesList = el}
                    funnelId={this.props.funnelId}
                    messages={this.state.messages}
                    onNewBanner={this.handleNewBanner.bind(this)}
                    onEditBanner={this.handleEditBanner.bind(this)}
                    onDeleteBanner={this.handleDeleteBanner.bind(this)}
                />
            )
        }

        return (
            <EditBannerMessage
                message={this.state.messageToEdit}
                onBack={this.handleBackBanner.bind(this)}
                onSave={this.handleSaveBanner.bind(this)}
            />
        );
    }
}


class UsefulContacts extends React.Component {
    constructor(props) {
        super(props);

        this.tableColumns = [{
            id: 'image',
            type: 'image',
            width: '90px'
        }, {
            id: 'title',
            type: 'text',
            title: 'Title',
            width: '20%'
        }, {
            id: 'name',
            type: 'link',
            title: 'Contact Name',
            width: '30%'
        }, {
            id: 'description',
            type: 'text',
            title: 'Description',
            width: '50%'
        }, {
            id: 'actions',
            type: 'actions',
            width: '100px'
        }];

        this.mounted = false;

        this.state = {
            contacts: []
        };
    }

    componentDidMount() {
        this.mounted = true;

        const self = this;

        $.get(`/funnels/${this.props.funnelId}`, function(result) {
            const portal = result.portal || {};
            self.addContacts(portal.useful_contacts)
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    addContacts(contacts) {
        contacts = contacts || [];

        let counter = 0;
        // todo: display loading dots to the table?

        this.setState({
            contacts: []
        });

        let newContacts = [];
        const self = this;

        const checkReady = () => {
            --counter;

            if (counter === 0) {
                if (!self.mounted) {
                    return;
                }

                self.setState({
                    contacts: newContacts
                });
            }
        }

        for (const contact of contacts) {
            const individual = new IndividualModel({id: contact.individual_id});

            ++counter;

            individual.fetch({
                success: function() {
                    contact.name = individual.get('full_name');
                    contact.nameLink = `#individuals/${individual.id}`;
                    contact.nameId = individual.id;
                    contact.image = individual.get('photo_url');

                    newContacts.push(contact);
                    checkReady();
                },
                error: function() {
                    checkReady();
                }
            });
        }
    }

    handleNewContact() {
        this.props.showDialog(
            <EditUsefulContactModal
                onClose={this.props.closeDialog}
                onSave={this.createContact.bind(this)}
            />
        );
    }

    updateContact(contact, title, individual, description) {
        this.props.closeDialog();

        if (contact.title !== title || contact.nameId !== individual.id || contact.description !== description) {
            const self = this;

            $.post(`/funnels/${this.props.funnelId}/portal`, JSON.stringify({
                path: 'useful_contacts',
                method: 'update',
                id: contact.id,
                data: {
                    id: contact.id,
                    title: title,
                    individual_id: individual.id,
                    description: description
                }
            }), function(result) {
                self.addContacts(result.useful_contacts);
            });
        }
    }

    createContact(title, individual, description) {
        const self = this;

        this.props.closeDialog();

        $.post(`/funnels/${this.props.funnelId}/portal`, JSON.stringify({
            path: 'useful_contacts',
            method: 'add',
            data: {
                id: guid(),
                title: title,
                individual_id: individual.id,
                description: description
            }
        }), function(result) {
            self.addContacts(result.useful_contacts);
        });
    }

    editContact(contact) {
        this.props.showDialog(
            <EditUsefulContactModal
                title={contact.title}
                contact={{
                    id: contact.nameId,
                    title: contact.name
                }}
                description={contact.description}
                onClose={this.props.closeDialog}
                onSave={(title, individual, description) => this.updateContact.bind(this)(contact, title, individual, description)}
            />
        );
    }

    deleteContact(contact) {
        const self = this;
        const mbContent = {
            icon: 'icon-trashcan',
            accept_is_negative: true,
            message: 'Are you sure you want to <strong>permanently</strong> delete the contact?'
        };

        MessageBox.showYesNo(mbContent, this.props.parent,
            function() { // Yes
                $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
                    path: 'useful_contacts',
                    method: 'delete',
                    id: contact.id
                }), function(result) {
                    self.addContacts(result.useful_contacts);
                });
            }
        );
    }

    handleTableAction(action, row) {
        if (action === 'edit') {
            this.editContact(row);
        } else if (action === 'delete') {
            this.deleteContact(row);
        }
    }

    handleTableSort(rowsSorted) {
        const self = this;

        $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
            path: 'useful_contacts',
            method: 'update_order',
            data: {
                ids: rowsSorted.map(r => r.id)
            }
        }));
    }

    render() {
        return (
            <div className={style.content}>
                <ContentHeader
                    title='Useful Contacts'
                />

                <div style={{
                    margin: '25px'
                }}>
                    <Bulletin
                        title='Add and remove Useful Contacts from the portal'
                        description='In this section, you can add and remove key Useful Contacts using the controls below. Use the handles to update the order of the contacts.'
                    />
                </div>

                <SectionHeader
                    title='Development Useful Contacts'
                    onNewButtonClicked={this.handleNewContact.bind(this)}
                />

                <Table
                    columns={this.tableColumns}
                    rows={this.state.contacts}
                    sortable={true}
                    onAction={this.handleTableAction.bind(this)}
                    onItemsSorted={this.handleTableSort.bind(this)}
                />

                <div style={{marginBottom: '20px'}}/>
            </div>
        );
    }
}

class DocumentManagement extends React.Component {
    constructor(props) {
        super(props);

        this.tableColumns = [{
            id: 'icon',
            type: 'icon',
            width: '80px'
        }, {
            id: 'name',
            type: 'text',
            title: 'Name',
            width: '100%'
        }, {
            id: 'actions',
            type: 'actions',
            width: '100px',
            align: 'right',
            actions: {
                delete: true
            }
        }];

        this.folders = [{
            id: 'development_access',
            icon: 'icon-folder',
            name: 'Development Access',
            isFolder: true
        }, {
            id: 'home_user_guides',
            icon: 'icon-folder',
            name: 'Home user guides',
            isFolder: true
        }, {
            id: 'warranties_and_certificates',
            icon: 'icon-folder',
            name: 'Warranties & Certificates',
            isFolder: true
        }, {
            id: 'development_brochure',
            icon: 'icon-folder',
            name: 'Development Brochure',
            isFolder: true
        }];

        this.previousFolder = {
            id: 'previous',
            icon: 'icon-reply',
            name: '[Previous Folder]',
            isFolder: true
        };

        this.currentFolder = null;

        this.state = {
            staticItems: [],
            items: [],
            showLibraryBrowser: false
        };
    }

    componentDidMount() {
        const self = this;

        $.get(`/funnels/${this.props.funnelId}`, function(result) {
            const portal = result.portal || {};
            self.processDocuments(portal.documents);
            self.showFolder(null);
        });
    }

    processDocuments(documents) {
        this.documents = _.clone(documents || {});

        for (const k in this.documents) {
            for (const doc of this.documents[k]) {
                const dotPos = doc.name.lastIndexOf('.');
                const ext = (dotPos !== -1 ? doc.name.substr(dotPos) : '').toLowerCase();
                doc.icon = Utilities.getTypeIcon(ext).icon;
            }
        }
    }

    showFolder(folderId) {
        this.currentFolder = folderId;

        if (folderId === null) { // root
            this.setState({
                staticItems: this.folders,
                items: []
            })
        } else {
            this.setState({
                staticItems: [this.previousFolder],
                items: this.documents[folderId] || []
            });
        }
    }

    handleTableAction(action, row) {
        if (action === 'click') {
            if (row.isFolder) {
                this.showFolder(row.id === 'previous' ? null : row.id);
            }
        } else if (action === 'delete') {
            const self = this;
            const mbContent = {
                icon: 'icon-trashcan',
                accept_is_negative: true,
                message: 'Are you sure you want to delete the file?'
            };

            MessageBox.showYesNo(mbContent, this.props.parent,
                function() { // Yes
                    $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
                        path: `documents.${self.currentFolder}`,
                        method: 'delete_file',
                        id: row.id,
                    }), function(result) {
                        self.processDocuments(result.documents);
                        self.showFolder(self.currentFolder);
                    });
                }
            );
        }
    }

    handleFilesUpload(fileList) {
        let files = [];
        const self = this;

        for (const file of fileList) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function() {
                files.push({
                    id: guid(),
                    name: file.name,
                    content: reader.result.substring(reader.result.indexOf(',') + 1) // remove file information header
                });

                if (files.length === fileList.length) {
                    $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
                        path: `documents.${self.currentFolder}`,
                        method: 'add_files',
                        data: {
                            files: files
                        }
                    }), function(result) {
                        self.processDocuments(result.documents);
                        self.showFolder(self.currentFolder);
                    });
                }
            }
        }
    }

    handleFromLibrary(file) {
        const self = this;

        $.post(`/funnels/${this.props.funnelId}/portal`, JSON.stringify({
            path: `documents.${this.currentFolder}`,
            method: 'add_files',
            data: {
                files: [{
                    id: guid(),
                    name: file.name,
                    file_id: file.id
                }]
            }
        }), function(result) {
            self.processDocuments(result.documents);
            self.showFolder(self.currentFolder);
            self.setState({showLibraryBrowser: false});
        });

    }

    handleTableSort(rowsSorted) {
        const self = this;

        $.post(`/funnels/${self.props.funnelId}/portal`, JSON.stringify({
            path: `documents.${this.currentFolder}`,
            method: 'update_order',
            data: {
                ids: rowsSorted.map(r => r.id)
            }
        }));
    }

    render() {
        return (
            <div className={style.content}>
                <ContentHeader
                    title='Document Management'
                />

                <div style={{
                    margin: '25px'
                }}>
                    <Bulletin
                        title='Add your development-wide documents'
                        description='Any files uploaded to this page will be visible in the buyer portal. You can add, remove and adjust the order of the files using the buttons below. To add files to on an individual basis, navigate to the desired record and upload files there.'
                    />
                </div>

                {this.currentFolder ? (
                    <SectionHeader
                        title='Portal Files (Public)'
                        newButtonText='Add from Library'
                        onNewButtonClicked={() => this.setState({showLibraryBrowser: true})}
                    />
                ) : (
                    <SectionHeader
                        title='Portal Files (Public)'
                    />
                )}

                <Table
                    columns={this.tableColumns}
                    staticRows={this.state.staticItems}
                    rows={this.state.items}
                    sortable={true}
                    rowsClickables={true}
                    onAction={this.handleTableAction.bind(this)}
                    onItemsSorted={this.handleTableSort.bind(this)}
                />

                <div style={{marginBottom: '20px'}}/>

                {this.currentFolder &&
                    <div style={{marginBottom: '20px'}}>
                        <FileUpload
                            onFiles={this.handleFilesUpload.bind(this)}
                        />
                    </div>
                }

                {this.state.showLibraryBrowser &&
                    <LibraryBrowser
                        folderId='root'
                        parent={this.props.parent}
                        onCancel={() => this.setState({showLibraryBrowser: false})}
                        onFileSelected={this.handleFromLibrary.bind(this)}
                    />
                }
            </div>
        );
    }
}

class MainContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItemId: SIDEBAR_OPTIONS[0].id
        };
    }

    getContent(itemId) {
        switch (itemId) {
            case 'banner':
                return (
                    <BannerMessage
                        funnelId={this.props.funnelId}
                        parent={this.props.parent}
                    />
                );

            case 'contacts':
                return (
                    <UsefulContacts
                        funnelId={this.props.funnelId}
                        parent={this.props.parent}
                        showDialog={this.props.showDialog}
                        closeDialog={this.props.closeDialog}
                    />
                );

            case 'documents':
                return (
                    <DocumentManagement
                        funnelId={this.props.funnelId}
                        parent={this.props.parent}
                    />
                );
        }

        return null;
    }

    handleItemSelected(itemId) {
        this.setState({
            activeItemId: itemId
        });
    }

    render() {
        const content = this.getContent(this.state.activeItemId);

        return (
            <div className={style.mainContent}>
                <Sidebar
                    funnelName={this.funnelName}
                    items={SIDEBAR_OPTIONS}
                    itemSelected={this.state.activeItemId}
                    onItemSelected={this.handleItemSelected.bind(this)}
                />

                {content}
            </div>
        );
    }
}


class PortalAdminReact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: null,
            dialog: null
        };

        this.counter = 0;

        this.sidebarItems = app.globalData.funnelsInfo.funnels.map(f => {
            return {
                id: f.id,
                title: f.name,
                icon: 'icon-globe'
            }
        });
    }

    componentDidMount() {
        this.selectFunnel(this.sidebarItems[0].id);
    }

    showDialog(dialog) {
        this.setState({
            dialog: dialog
        });
    }

    closeDialog() {
        this.setState({
            dialog: null
        });
    }

    selectFunnel(funnelId) {
        ++this.counter;

        this.setState({
            content: (
                <MainContent
                    key={`content_${this.counter}`}
                    funnelId={funnelId}
                    parent={this.props.parent}
                    showDialog={this.showDialog.bind(this)}
                    closeDialog={this.closeDialog.bind(this)}
                />
            )
        });
    }

    render() {
        return (
            <div className={style.portal}>
                <Section
                    id='portal'
                    title='Portal'
                    items={this.sidebarItems}
                    onItemSelected={this.selectFunnel.bind(this)}
                    content={this.state.content}
                />

                {this.state.dialog &&
                    <div className={style.dialogBackdrop}>
                        {this.state.dialog}
                    </div>
                }
            </div>
        );
    }
}

export default BaseView.extend({
    id: 'portal-admin-section',
    tagName: 'article',
    template: Handlebars.compile('<div class="react-container"/>'),
    ui: {
        react: '.react-container'
    },
    onRender: function() {
        ReactDOM.render(
            <PortalAdminReact
                parent={this}
            />,
            this.ui.react.get(0)
        );
    },
    onClose: function() {
        ReactDOM.unmountComponentAtNode(this.$el.find('.react-container').get(0));
    },
    getUrl: function() {
        return 'portal';
    }
});

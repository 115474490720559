import $ from 'jquery'
import Marionette from 'Backbone.Marionette'


var ModalRegion;

ModalRegion = Marionette.Region.extend({
    open: function(view) {
        var region = this;

        // Create the modal on 'body', and append the view in it
        var modalParams = {};

        if (this.backdrop) {
            modalParams.backdrop = this.backdrop;
        }

        if (this.keyboard !== null) {
            modalParams.keyboard = this.keyboard;
        }

        this.removeModalEl();
        this.$modalEl = $('<div class="modal">');
        this.$modalEl.append(view.$el);
        this.$modalEl.modal(modalParams);
        // $('body').append(this.$modalEl);

        this.listenTo(view, 'close', function() {
            // Remove backdrop when closed programmatically
            region.$modalEl.modal('hide');
        });

        // If the modal is dismissed, close the view and supporting modal
        this.$modalEl.on('hidden', function(ev) {
            // Ugly fix for preventing tooltips from closing modal
            // See github issue for more info: https://github.com/twbs/bootstrap/issues/6942
            if (ev.currentTarget !== ev.target) {
                return;
            }
            region.reset();
            region.removeModalEl();
            region.trigger('hide');
        });
    },
    onClose: function() {
        this.removeModalEl();
    },
    removeModalEl: function() {
        if (this.$modalEl) {
            // Remove modal itself - the region.$el
            this.$modalEl.remove();
            this.$modalEl = null;
        }
    }
});

export default ModalRegion;

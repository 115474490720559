import _ from 'underscore'
import Backbone from 'backbone'
import Marionette from 'Backbone.Marionette'
import IndividualFilterModel from 'js/models/individual_filter'

import vent from 'js/vent.js'

// 
// enable passing all named params in query
//

Backbone.Router.namedParameters = true;

var AppRouter;

AppRouter = Marionette.AppRouter.extend({
    routes: {
        ''                                      : 'dashboard',
        'dashboard'                             : 'dashboard',
        'dashboard/:type'                       : 'dashboard',
        'contacts'                              : 'individualList',
        'contacts/favorites'                    : 'favorites',
        'contacts/recentIndividuals'            : 'recentIndividuals',
        'contacts/recentCreatedIndividuals'     : 'recentCreatedIndividuals',
        'contacts/recentOrganizations'          : 'recentOrganizations',
        'contacts/recentCreatedOrganizations'   : 'recentCreatedOrganizations',
        'contacts/elephants'                    : 'elephants',
        'contacts/individuals'                  : 'individualList',
        'contacts/individualsByPhone/:phone'    : 'individualListByPhone',
        'contacts/myIndividuals'                : 'myIndividualList',
        'contacts/leadIndividuals'              : 'leadIndividualList',
        'contacts/organizations'                : 'organizationList',
        'contacts/myOrganizations'              : 'myOrganizationList',
        'contacts/deals'                        : 'dealList',
        'contacts/group/individuals/new'        : 'newIndividualGroup',
        'contacts/group/organizations/new'      : 'newOrganizationGroup',
        'contacts/group/individuals/:id'        : 'individualGroupDetail',
        'contacts/group/organizations/:id'      : 'organizationGroupDetail',
        'contacts/group/stakeholders/:id'       : 'stakeholderGroupDetail',
        'individuals/new'                       : 'individualNew',
        'individuals/:id'                       : 'individualDetail',
        'organizations/new'                     : 'organizationNew',
        'organizations/:id'                     : 'organizationDetail',
        'social'                                : 'socialList',
        'web'                                   : 'web',
        'deals'                                 : 'dealFunnelActive',
        'deals/snapshot'                        : 'dealFunnelSnapshot',
        'deals/active'                          : 'dealFunnelActive',
        'deals/all'                             : 'dealFunnelAll',
        'deals/won'                             : 'dealFunnelWon',
        'deals/lost'                            : 'dealFunnelLost',
        'deals/my'                              : 'dealFunnelMy',
        'deals/team'                            : 'dealFunnelTeam',
        'deals/periods'                         : 'dealFunnelPeriods',
        'deals/periods/:id'                     : 'dealFunnelPeriods',
        'deals/funnels/:id'                     : 'dealFunnels',
        'deals/groups'                          : 'dealFunnelGroups',
        'deals/groups/new'                      : 'newDealGroup',
        'deals/groups/:id'                      : 'dealFunnelGroups',
        'deals/section/:sectionId/snapshot'     : 'dealFunnelSnapshot',
        'deals/section/:sectionId/active'       : 'dealFunnelActive',
        'deals/section/:sectionId/all'          : 'dealFunnelAll',
        'deals/section/:sectionId/won'          : 'dealFunnelWon',
        'deals/section/:sectionId/lost'         : 'dealFunnelLost',
        'deals/section/:sectionId/my'           : 'dealFunnelMy',
        'deals/section/:sectionId/team'         : 'dealFunnelTeam',
        'deals/section/:sectionId/periods'      : 'dealFunnelPeriods',
        'deals/section/:sectionId/periods/:id'  : 'dealFunnelPeriods',
        'deals/section/:sectionId/funnels/:id'  : 'dealFunnels',
        'deals/section/:sectionId/groups'       : 'dealFunnelGroups',
        'deals/section/:sectionId/groups/new'   : 'newDealGroup',
        'deals/section/:sectionId/groups/:id'   : 'dealFunnelGroups',
        'deals/new'                             : 'dealNew',
        'deals/:id'                             : 'dealDetail',
        'deals/section/:sectionId/new'          : 'dealNew',
        'deals/section/:sectionId/:id'          : 'dealDetail',
        'forecasts'                             : 'forecastList',
        'forecasts/new'                         : 'forecastNew',
        'forecasts/:id'                         : 'forecastDetail',
        'tasks'                                 : 'tasksAll',
        'tasks/:id'                             : 'taskDetail',
        'tasks/groups/:group_id'                : 'tasksGroup',
        'activities'                            : 'activitiesAll',
        'activities/all'                        : 'activitiesAll',
        'activities/groups/:group_id'           : 'activitiesGroupDetail',
        'texts'                                 : 'texts',
        'messages'                              : 'messages',
        'messages/all'                          : 'messagesAll',
        'messages/unread'                       : 'messagesUnread',
        'messages/read'                         : 'messagesRead',
        'messages/closed'                       : 'messagesClosed',
        'messages/groups/:group_id'             : 'messages',
        'content'                               : 'content',
        'content/:type'                         : 'content',
        'content/:type/:id'                     : 'content',
        'campaigns'                             : 'campaigns',
        'campaigns/:type'                       : 'campaigns',
        'campaigns/:type/new'                   : 'campaignsNew',
        'campaigns/:type/:id'                   : 'campaigns',
        'campaigns/:type/groups/:gid'           : 'campaigns',
        'campaigns/:type/groups/:gid/:id'       : 'campaigns',
        'campaigns/:type/preview/:id'           : 'campaignsPreview',
        'campaigns/:type/groups/:gid/preview/:id' : 'campaignsPreview',
        'leads'                                 : 'leads',
        'automations'                           : 'automations',
        'automations/new'                       : 'automationsNew',
        'automations/:id'                       : 'automationsDetail',
        'marketing'                             : 'marketing',
        'appointments'                          : 'appointments',
        'appointments/all'                      : 'appointmentsAll',
        'appointments/upcoming'                 : 'appointmentsUpcoming',
        'appointments/confirmed'                : 'appointmentsConfirmed',
        'appointments/pending'                  : 'appointmentsPending',
        'appointments/groups/:group_id'         : 'appointments',
        'appointments/all/:id'                  : 'appointmentsAll',
        'appointments/upcoming/:id'             : 'appointmentsUpcoming',
        'appointments/confirmed/:id'            : 'appointmentsConfirmed',
        'appointments/pending/:id'              : 'appointmentsPending',
        'appointments/groups/:group_id/:id'     : 'appointments',
        'reports'                               : 'reports',
        'portal'                                : 'portal'
    },
    initialize: function(options) {
        this.controller = (options || {}).controller;
    },
    getFragmentRoute: function(fragment) {
        var result;
        _.any(_.keys(this.routes), function(route) {
            var regex = this._routeToRegExp(route);
            if (regex.test(fragment)) {
                result = route;
                return true;
            }
        }, this);
        return result;
    },
    showWithContext: function(options, viewCallback) {
        var router = this,
            controller = this.controller,
            fragment = options && options.c,
            route = fragment && this.getFragmentRoute(fragment),
            settings = options && options.s,
            usersettings = options && options.u;

        options.context = options.c;
        delete options.c;
        if (route) {
            var routeValue = this.routes[route],
                routeView = routeValue && controller[routeValue];

            if (routeView) {
                var routeOpt = _.first(this._extractParameters(this._routeToRegExp(route), fragment));

                routeView.call(controller, routeOpt, function(contextOptions, contextView) {
                    options.contextView = contextView;
                    options.replace = true;
                    viewCallback.call(router, options);
                });
                return;
            // } else {
                // console.log(routeValue + " not found in controller");
            }
        // } else {
            // console.log(fragment + " not found in router");
        }
        if (settings) {
            vent.trigger('settings:show', settings);
        }
        if (usersettings) {
            vent.trigger('userSettings:show', usersettings);
        }

        // fallback
        // console.log("no context", options);
        viewCallback.call(router, options);
    },
    dashboard: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.dashboard(options, this.controller.show);
        });
    },
    elephants: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.elephants(options, this.controller.show);
        });
    },
    favorites: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.favorites(options, this.controller.show);
        });
    },
    recentIndividuals: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.recentIndividuals(options, this.controller.show);
        });
    },
    recentOrganizations: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.recentOrganizations(options, this.controller.show);
        });
    },
    recentCreatedIndividuals: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.recentCreatedIndividuals(options, this.controller.show);
        });
    },
    recentCreatedOrganizations: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.recentCreatedOrganizations(options, this.controller.show);
        });
    },
    newIndividualGroup: function(options) {
        this.showWithContext(options, function(options) {
            options.element_type = 'individuals';
            this.controller.groupNew(options, this.controller.show);
        });
    },
    newOrganizationGroup: function(options) {
        this.showWithContext(options, function(options) {
            options.element_type = 'organizations';
            this.controller.groupNew(options, this.controller.show);
        });
    },
    individualGroupDetail: function(options) {
        this.showWithContext(options, function(options) {
            options.element_type = 'individuals';
            this.controller.groupDetail(options, this.controller.show);
        });
    },
    stakeholderGroupDetail: function(options) {
        this.showWithContext(options, function(options) {
            options.element_type = 'stakeholders';
            this.controller.groupDetail(options, this.controller.show);
        });
    },
    organizationGroupDetail: function(options) {
        this.showWithContext(options, function(options) {
            options.element_type = 'organizations';
            this.controller.groupDetail(options, this.controller.show);
        });
    },
    individualList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.individualList(options, this.controller.show);
        });
    },
    individualListByPhone: function(options) {
        var self = this;
        var filter = new IndividualFilterModel();

        filter.save({
            rules: [[{
                field: 'communication_value',
                operator: 'equal',
                values: options.phone
            }]]
            },
            {
                alert: false,
                success: function(data) {
                    options.filter_id = data.id;
                    self.showWithContext(options, function(options) {
                        options.showFirstRecord = true;
                        self.controller.individualList(options, self.controller.show);
                    });
                }
            }
        );
    },
    myIndividualList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.individualList(options, this.controller.show, 'myIndividuals');
        });
    },
    leadIndividualList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.individualList(options, this.controller.show, 'leadIndividuals');
        });
    },
    individualNew: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.individualNew(options, this.controller.show);
        });
    },
    individualDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.individualDetail(options, this.controller.show);
        });
    },
    organizationList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.organizationList(options, this.controller.show);
        });
    },
    myOrganizationList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.organizationList(options, this.controller.show, 'myOrganizations');
        });
    },
    organizationNew: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.organizationNew(options, this.controller.show);
        });
    },
    organizationDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.organizationDetail(options, this.controller.show);
        });
    },
    socialList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.socialList(options, this.controller.show);
        });
    },
    web: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.web(options, this.controller.show);
        });
    },
    dealFunnel: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.dealFunnel(options, this.controller.show);
        });
    },
    dealFunnelSnapshot: function(options) {
        this.dealFunnel(_.extend({
            type: 'snapshot'
        }, options));
    },
    dealFunnelActive: function(options) {
        this.dealFunnel(_.extend({
            type: 'active'
        }, options));
    },
    dealFunnelAll: function(options) {
        this.dealFunnel(_.extend({
            type: 'all'
        }, options));
    },
    dealFunnelWon: function(options) {
        this.dealFunnel(_.extend({
            type: 'won'
        }, options));
    },
    dealFunnelLost: function(options) {
        this.dealFunnel(_.extend({
            type: 'lost'
        }, options));
    },
    dealFunnelMy: function(options) {
        this.dealFunnel(_.extend({
            type: 'my'
        }, options));
    },
    dealFunnelTeam: function(options) {
        this.dealFunnel(_.extend({
            type: 'team'
        }, options));
    },
    dealFunnelPeriods: function(options) {
        this.dealFunnel(_.extend({
            type: 'periods'
        }, options));
    },
    dealFunnels: function(options) {
        this.dealFunnel(_.extend({
            type: 'funnels'
        }, options));
    },
    dealFunnelGroups: function(options) {
        this.dealFunnel(_.extend({
            type: 'groups'
        }, options));
    },
    newDealGroup: function(options) {
        this.dealFunnel(_.extend({
            type: 'new'
        }, options));
    },
    dealList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.dealList(options, this.controller.show);
        });
    },
    dealNew: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.dealNew(options, this.controller.show);
        });
    },
    dealDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.dealDetail(options, this.controller.show);
        });
    },
    forecastList: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.forecastList(options, this.controller.show);
        });
    },
    forecastNew: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.forecastNew(options, this.controller.show);
        });
    },
    forecastDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.forecastDetail(options, this.controller.show);
        });
    },
    taskDetail: function(options) {
        vent.trigger('quick:edit:task', new Backbone.Model({id: options.id}));
    },
    tasksGroup: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.tasks(_.extend({
                type: 'groups'
            }, options), this.controller.show);
        });
    },
    activitiesAll: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.activities(_.extend({
                type: 'all'
            }, options), this.controller.show);
        });
    },
    activitiesGroupDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.activities(_.extend({
                type: 'groups'
            }, options), this.controller.show);
        });
    },
    messagesAll: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.messages(_.extend({
                group_id: 'all'
            }, options), this.controller.show);
        });
    },
    messagesRead: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.messages(_.extend({
                group_id: 'read'
            }, options), this.controller.show);
        });
    },
    messagesUnread: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.messages(_.extend({
                group_id: 'unread'
            }, options), this.controller.show);
        });
    },
    messagesClosed: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.messages(_.extend({
                group_id: 'closed'
            }, options), this.controller.show);
        });
    },
    messages: function(options) {
        this.showWithContext(options, function(options) {
            if (options.group_id) {
                options.group_id = `groups/${options.group_id}`;
            }

            this.controller.messages(options, this.controller.show);
        });
    },
    content: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.content(options, this.controller.show);
        });
    },
    texts: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.texts(options, this.controller.show);
        });
    },
    campaigns: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.campaigns(options, this.controller.show);
        });
    },
    campaignsPreview: function(options) {
        this.showWithContext(options, function(options) {
            options.preview = true;
            this.controller.campaigns(options, this.controller.show);
        });
    },
    campaignsNew: function(options) {
        this.showWithContext(options, function(options) {
            options.new = true;
            this.controller.campaigns(options, this.controller.show);
        });
    },
    leads: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.leads(options, this.controller.show);
        });
    },
    automations: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.automations(options, this.controller.show);
        });
    },
    automationsNew: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.automationsNew(options, this.controller.show);
        });
    },
    automationsDetail: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.automationsDetail(options, this.controller.show);
        });
    },
    marketing: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.marketing(options, this.controller.show);
        });
    },
    appointmentsAll: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.appointments(_.extend({
                group_id: 'all',
                appointment_id: options.id
            }, options), this.controller.show);
        });
    },
    appointmentsUpcoming: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.appointments(_.extend({
                group_id: 'upcoming',
                appointment_id: options.id
            }, options), this.controller.show);
        });
    },
    appointmentsConfirmed: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.appointments(_.extend({
                group_id: 'confirmed',
                appointment_id: options.id
            }, options), this.controller.show);
        });
    },
    appointmentsPending: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.appointments(_.extend({
                group_id: 'pending',
                appointment_id: options.id
            }, options), this.controller.show);
        });
    },
    appointments: function(options) {
        this.showWithContext(options, function(options) {
            if (options.group_id) {
                options.group_id = `groups/${options.group_id}`;
            }

            options.appointment_id = options.id;

            this.controller.appointments(options, this.controller.show);
        });
    },
    reports: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.reports(options, this.controller.show);
        });
    },
    portal: function(options) {
        this.showWithContext(options, function(options) {
            this.controller.portal(options, this.controller.show);
        });
    }
});

export default AppRouter;

import ColumnsCreator  from 'app_v2/sections/table_columns_creator';
import AppConfig from 'app/app-config';


export function getAllColumns() {
    const columnsCreator = new ColumnsCreator('conversations');

    let columns = [
        columnsCreator.date('last_message.created', 'Date Sent', { filterId: 'conversation_last_message_created' }),
        columnsCreator.text('last_message.creator_name', 'Sent from', { filterable: true, filterId: 'conversation_last_message_creator_name' }),
        columnsCreator.conversationParticipants('participants', 'Participants'),
        columnsCreator.comment('last_message.message', 'Message Preview', { sortable: true, filterable: true, filterId: 'conversation_last_message_text', filterType: 'text' }),
    ];

    const clientColumnsDef = AppConfig.getValue('group_pages.messages.columns', []);

    for (const cd of clientColumnsDef) {
        columns.push(columnsCreator[cd[0]](cd[1], cd[2], cd[3]));
    }

    return columns;
};

export function getDefaultColumnsIds() {
    const clientColumns = AppConfig.getValue('group_pages.messages.default_columns');

    if (clientColumns) {
        return clientColumns;
    }

    return [
        'last_message.created',
        'last_message.creator_name',
        'last_message.message'
    ];
};